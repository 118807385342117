import { buscarColoresTexto, buscarMezcladosTexto, buscarVariedadesTexto, LABEL_COLORES, LABEL_PRODUCTOS, LABEL_VARIEDADES, TIPO_CANTIDAD, TIPO_PESO } from "../constants/ProductosYVariedades.const"

const diccTipoCalidadInicial = {
    [TIPO_CANTIDAD]: "Cantidad",
    [TIPO_PESO]: "Peso",
    "": ""
}
const diccProductosYVariedadesTabs = {
    [LABEL_PRODUCTOS]: "productos",
    [LABEL_VARIEDADES]: "variedades",
    [LABEL_COLORES]: "colores",
    "": ""
}
const diccTextoBotonCrear = {
    [LABEL_PRODUCTOS]: "Crear nuevo producto",
    [LABEL_VARIEDADES]: "Crear nueva variedad",
    [LABEL_COLORES]: "Crear nuevo color",
    "": ""
}
const diccTextoBuscar = {
    [LABEL_PRODUCTOS]: buscarMezcladosTexto,
    [LABEL_VARIEDADES]: buscarVariedadesTexto,
    [LABEL_COLORES]: buscarColoresTexto,
    "": ""
}

//funciones
export const getTipoCalidadProductoNombre = (tipo = "") => {
    return diccTipoCalidadInicial[tipo];
}
export const getTextoBuscar = (tipo = "") => {
    return diccTextoBuscar[tipo];
}

export const getNombrePantalla = (activeTab = "") => {
    return `Administrar ${diccProductosYVariedadesTabs[activeTab]}`;
}
//As above  ^  or below  v? para el texto 'crear nuevo'
export const getTextoBoton = (activeTab = "") => {
    return diccTextoBotonCrear[activeTab];
}