import React, { useState, useGlobal, useEffect } from 'reactn';
import {Card} from 'primereact/card';
import { withRouter } from 'react-router-dom';
import { dosDecimales } from '../../util/functions';

function InvernaderoCard(props){
  const [invernadero,setInvernadero] = useGlobal("invernadero");
  const [aviso,setAviso] = useState(true);
  var [usuario, ] = useGlobal("usuario");
  var [, setPresetImportador] = useGlobal("presetImportador");
  
  const puedeConsultar = (params) => {
    return (usuario && ["Administrador", "Personal Administrativo", "Auxiliar Embarques","Administrador Embarques","agente aduanal","Finanzas"].includes(usuario.rol) )
}
  const consultar = ()=>{
    //
    //
    if(puedeConsultar()){
      setPresetImportador(props.cliente.id);        
      props.history.push("/embarques/consultar");
    }
    
    
    
    
  }
   
    const header = <span className="card-header">
      <div className={`card-id icon-general icon-id  bg-${props.cliente.color}`}>
        <img className="" alt={props.cliente.nombre}  title={props.cliente.nombre} src = {props.cliente.imagen}/>
      </div>
      <div><p className="card-up">{props.cliente.nombre} </p>
      <p className="card-down"></p></div>
      
      </span>

    function makeCssClass(claseBonita) {
        return claseBonita
            .toLowerCase()
            .replace(/ /g, '-')
            .replace(/[\s.,:()\/]/g, '')
        ;
    }
    return  <div className="p-col-12 p-md-6 p-sm-6 p-lg-6" onClick = {consultar}>
              <Card header = {header} className={`htg-card embarque-card bor-${props.cliente.nombre?makeCssClass(props.cliente.nombre):""}`} >   
                <div className="p-grid p-justify-center text-center">
                  
                  <div className="p-col p-sm-2 p-md-4">
                    <div className="card-block">
                      <p className="value"> {isNaN(props.cliente.aTiempo)?"-":Math.round( 100 * props.cliente.aTiempo)}<span className="label-unidad">%</span> </p>
                      <p className="label">Camiones a tiempo</p>
                    </div>
                    
                  </div>
                  <div className="p-col p-sm-2 p-md-4">
                    <div className="card-block">
                      <p className="value"> {isNaN(props.cliente.retrasados)?"-":Math.round( 100 * props.cliente.retrasados)}<span className="label-unidad">%</span> </p>
                      <p className="label">Camiones retrasados</p>
                    </div>
                    
                  </div>
                  <div className="p-col p-sm-2 p-md-4">
                    <div className="card-block">
                      <p className="value"> {isNaN(props.cliente.onHold)?"-":(  props.cliente.onHold)}  </p>
                      <p className="label">Camiones on hold</p>
                    </div>
                    
                  </div>
               
                  <div className="p-col p-sm-2 p-md-4">
                    <div className="card-block">
                      <p className="value"> {isNaN(props.cliente.promedioPallets)?"-": dosDecimales (props.cliente.promedioPallets)} <span className="label-unidad">Pallets</span></p>
                      <p className="label">Promedio por camión </p>
                    </div>
                  </div>
                  <div className="p-col p-sm-2 p-md-4">
                    <div className="card-block">
                      <p className="value"> {isNaN(props.cliente.promedioPalletsSinConsolidado)?"-": dosDecimales (props.cliente.promedioPalletsSinConsolidado)} <span className="label-unidad">Pallets</span></p>
                      <p className="label">Promedio por camión sin consolidados</p>
                    </div>
                  </div>
                  <div className="p-col p-sm-2 p-md-4">
                    <div className="card-block">
                      <p className="value"> {isNaN(props.cliente.promedioPalletsSoloConsolidado)?"-": dosDecimales (props.cliente.promedioPalletsSoloConsolidado)} <span className="label-unidad">Pallets</span></p>
                      <p className="label">Promedio por camión solo consolidados</p>
                    </div>
                  </div>
                  <div className="p-col p-sm-2 p-md-4">
                    <div className="card-block">
                      <p className="value"> { isNaN(props.cliente.unidadesCargadasAtongo) ? "-" : (props.cliente.unidadesCargadasAtongo) } </p>
                      <p className="label">Unidades cargadas <span className="label-hl">Atongo</span></p>
                    </div>
                  </div>
                  <div className="p-col p-sm-2 p-md-4">
                    <div className="card-block">
                      <p className="value"> { isNaN(props.cliente.unidadesCargadasAgropark) ? "-" : (props.cliente.unidadesCargadasAgropark) } </p>
                      <p className="label">Unidades cargadas <span className="label-hl">Agropark</span></p>
                    </div>
                  </div>
                  <div className="p-col p-sm-2 p-md-4">
                    <div className="card-block">
                      <p className="value"> { isNaN(props.cliente.unidadesCargadasAtongoAgropark) ? "-" : (props.cliente.unidadesCargadasAtongoAgropark) } </p>
                      <p className="label">Unidades cargadas <span className="label-hl">Atongo/Agropark</span></p>
                    </div>
                  </div>
                </div>
            </Card>
          </div>
}

export default withRouter(InvernaderoCard);