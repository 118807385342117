import React from 'react'
import { estamosEnProduccion } from '../../constants'

const handleClickDev = (props = {}) => estamosEnProduccion ? null : props.onClickDev || null;

const ContenedorHeaderClickableDev = (props) =>
    <div className={` ${props.col ? `${props.col}` : `p-col-12`}  title-container`} onClick={handleClickDev(props)}>
        {props.atras ? <span className="back-btn" onClick={props.atras}></span> : null}
        <span className={`icon-general ${props.iconos} text-center`} style={props.style}>{props.invernaderoNombre || ""}</span>

        <span className="titles-group">
            <h1 className="header-title">
                {props.titulo}
            </h1>
            {props.subtitulo && <p className="header-subtitle">{props.subtitulo}</p>}
            {props.subtituloGrande && <h3 className="header-subtitle-bigger">{props.subtituloGrande}</h3>}
            {props.subtituloInv && <h3 className="header-subtitle">{props.subtituloInv}</h3>}
        </span>
        <div>
        </div>
    </div>;
export default ContenedorHeaderClickableDev;