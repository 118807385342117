import React, { useEffect, useState, useGlobal } from 'reactn';
import { Dialog } from "primereact/dialog";
import { Button } from 'primereact/button';
import { getListaDeSemanas, obtenerDiasDeSemana } from '../../../service/fechas';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { DeepClone, getNombrePorID, getObjetoPorID, getProductoYVariedadStringDiagonal } from '../../../util/functions';
import { Message } from 'primereact/message';
import SeccionFiltros from '../../common/SeccionFiltros';
import ContenedorFiltro from '../../common/ContenedorFiltro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { getFirestoreID } from '../../../service/Mantenimiento';
import * as firebase from "firebase/app";
import { getDistribucionMedleysQEURY } from 'service/repositorios/DistribucionMedleys.queries';

const ModalRedistribucionMedleys = (props) => {
    const [, setCargando] = useGlobal("cargando");
    const [ciclo] = useGlobal("ciclo");
    const [clientes] = useGlobal("clientesDelCiclo");
    const [productosYVariedadesTodos] = useGlobal("productosYVariedadesTodos");
    const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
    const [weeks] = useGlobal("weeks");
    const [reloadMed, setReloadMed] = useGlobal("reloadMed");
    const [allVariedades] = useGlobal("allVariedades");
    const [proveedores, setProveedores] = useGlobal("proveedores");
    const [variedades, setVariedades] = useState([])

    const [cliente, setCliente] = useState({});
    const [invernadero, setInvernadero] = useState(null);
    const [distribucionDatos, setDistribucionDatos] = useState(null);
    const [producto, setProducto] = useState(null);
    const [week, setWeek] = useState(null);
    const [distribucionID, setDistribucionID] = useState(null);

    const [origenes, setOrigenes] = useState([{}]);
    const [errores, setErrores] = useState({});

    useEffect(() => {
        setErrores({})
    }, [reloadMed])

    useEffect(() => {
        if (props.visible) {
            setReloadMed(false)
            //console.log(props.distribucionDatos)
            setDistribucionDatos(props.distribucionDatos);
            const cliente = getObjetoPorID(clientes, props.distribucionDatos.cliente_ref);
            const invernaderoOBJ = getObjetoPorID(invernaderosVirtuales, props.distribucionDatos.invernadero_virtual_ref);
            const producto = getObjetoPorID(productosYVariedadesTodos, invernaderoOBJ.producto_ref);
            const week = weeks.find(we => we.time === props.distribucionDatos.semana);
            const distribucionID = props.distribucionDatos.id || getFirestoreID()
            setCliente(cliente);
            setInvernadero(invernaderoOBJ);
            setProducto(producto);
            setWeek(week);
            setOrigenes(props.distribucionDatos.origenes);
            setDistribucionID(distribucionID)
        }
        else {
            setDistribucionDatos(null);
            setCliente(null);
            setInvernadero(null);
            setProducto(null);
            setWeek(null);
            setOrigenes([{}]);
            setErrores({});
        }
    }, [props.visible])

    useEffect(() => {
        setErrores({})
    }, [origenes])

    const guardarPrestamo = async () => {
        if (validarErrores()) {
            setCargando(true)
            const datos = { cliente_ref: cliente.id, invernadero_virtual_ref: invernadero.id, semana: week.time };
            datos.origenes = origenes
            if (props.distribucionDatos.id == null) { datos.fecha_prestado = Date.now(); }
            else { datos.fecha_actualizado = Date.now(); }

            if (origenes.length > 0) { await firebase.firestore().collection("ciclos").doc(ciclo).collection("distribucion_medleys").doc(distribucionID).set(datos, { merge: true }); }
            else { await firebase.firestore().collection("ciclos").doc(ciclo).collection("distribucion_medleys").doc(distribucionID).delete(); }

            const distribucionUpdated = await getDistribucionMedleysQEURY(ciclo);
            props.setDistribucionMedleysDatos(distribucionUpdated);
            setCargando(false)
            setReloadMed(true)
            props.onHide();
        }
    }
    const validarErrores = () => {
        let errores = {}
        origenes.forEach((origen, index) => {
            if (!origen.origen_ref) { errores[`origen-${index}`] = true; }
            if (!origen.variedad_ref) { errores[`variedad-${index}`] = true; }
            if (!origen.kilos_prestado) { errores[`prestado-${index}`] = true; }

            const origenInvernadero = getObjetoPorID(invernaderosVirtuales, origen.origen_ref)
            if (origenInvernadero.es_externo && !origen.proveedor_ref) { errores[`proveedor-${index}`] = true; }
        })
        setErrores(errores);
        return Object.keys(errores).length === 0;
    }

    const handleChangeOrigenes = (event) => {
        const { name, value, id: index } = event.target;
        const copiaOrigenes = DeepClone(origenes);
        copiaOrigenes[index][name] = value;

        if (name === "origen_ref") {
            const origenInvernadero = getObjetoPorID(invernaderosVirtuales, value)
            if (!origenInvernadero.mezcla) { copiaOrigenes[index].variedad_ref = origenInvernadero.variedad_ref; }
            else { copiaOrigenes[index].variedad_ref = ""; }
        }

        setOrigenes(copiaOrigenes)
    }
    const eliminarItem = (index) => {
        const copiaOrigenes = DeepClone(origenes);
        copiaOrigenes.splice(index, 1);
        setOrigenes(copiaOrigenes);
    }
    const agregarOrigen = () => {
        const copiaOrigenes = DeepClone(origenes);
        copiaOrigenes.push({})
        setOrigenes(copiaOrigenes)
    }

    const filtrarOrigenes = () => {
        //console.log("PROD ", producto);
        const mezclaVariedadIDs = producto?.variedades?.map(el => el.id) || [];
        const mezclaProductoIDs = producto?.variedades?.map(el => el.producto_ref) || [];

        //const origenesUnicos = invernaderosVirtuales?.filter(inv => !inv.mezcla &&  mezclaVariedadIDs.includes(inv.variedad_ref)) || [];
        const origenesUnicos = invernaderosVirtuales?.filter(inv => !inv.mezcla && mezclaProductoIDs.includes(inv.producto_ref)) || [];
        const origenesMezclas = invernaderosVirtuales?.filter(inv => inv.mezcla) || [];
        return [...origenesUnicos, ...origenesMezclas];
    }

    const invernaderosDestinosOpciones = () => invernaderosVirtuales?.map(el => ({ ...el, nombre: `${el.nombre} - ${getNombrePorID(productosYVariedadesTodos, el.producto_ref)}` })) || [];

    const invernaderoItemBody = (rowData) => {
        return <label> {rowData?.nombre} <label className="producto-text-small">({getProductoYVariedadStringDiagonal(rowData)})</label></label>
    }

    const getVariedadesOpciones = (invernadero_origen_ref) => {
        const invernadero = getObjetoPorID(invernaderosVirtuales, invernadero_origen_ref)
        if (invernadero.producto_ref && productosYVariedadesTodos?.length > 0) {
            //console.log("GET VAREDAD OPCIONES distribucion medleys: ", invernadero)
            let producto = productosYVariedadesTodos.find(el => el.id === invernadero.producto_ref)
            if (producto?.mezcla) {
                return producto.variedades;
            } else if (invernadero.MEDLEYS_VIEJO) {
                let variedad = producto?.variedades.find(el => el.nombre === invernadero.MEDLEYS_VIEJO);
                return [variedad];
            } else {
                let variedad = producto?.variedades.find(el => el.id === invernadero.variedad_ref);
                return [variedad];
            }
        }
        else return [];
    }

    return (
        <Dialog header={`Completar kilos de ${cliente?.nombre} - ${week?.nombre}`} visible={props.visible} style={{ width: '120vw', overflow: "scroll" }} onHide={props.onHide}>
            <div className="p-grid ">
                <span className="titles-group">
                    <h1 className="header-title">

                    </h1>
                </span>
            </div>

            <SeccionFiltros extra="col">
                <ContenedorFiltro label="Destino" sinFiltrarTexto>
                    <Dropdown name="destino" id="medl_destino" value={invernadero?.id} options={invernaderosDestinosOpciones()}
                        optionLabel="nombre" optionValue="id" placeholder="Seleccionar" itemTemplate={invernaderoItemBody} disabled />
                </ContenedorFiltro>
                <ContenedorFiltro label="Cliente" sinFiltrarTexto>
                    <Dropdown name="cliente" id="medl_cli" value={cliente?.id} options={clientes}
                        optionLabel="nombre" optionValue="id" placeholder="Seleccionar" disabled />
                </ContenedorFiltro>
            </SeccionFiltros>

            {origenes.map((origen, index) => {
                return (
                    <div key={`or${index}`} className="dashed-border-bottom">
                        <SeccionFiltros extra="p-0 position-initial">
                            <ContenedorFiltro col="3" label="Invernadero origen" sinFiltrarTexto>
                                <Dropdown key={`med-orig-${index}`} id={`${index}`} name="origen_ref" value={origen.origen_ref} options={filtrarOrigenes()}
                                    onChange={handleChangeOrigenes} optionLabel="nombre" optionValue="id" placeholder="Seleccionar" itemTemplate={invernaderoItemBody} />
                                {errores[`origen-${index}`] && <Message severity="error" text={"Este campo es requerido."} />}
                            </ContenedorFiltro>

                            <ContenedorFiltro col="3" label="Variedad" sinFiltrarTexto>
                                <Dropdown name="variedad_ref" id={`${index}`} value={origen.variedad_ref} optionValue="id" optionLabel="nombre"
                                    onChange={handleChangeOrigenes} options={getVariedadesOpciones(origen.origen_ref)} disabled={!getObjetoPorID(invernaderosVirtuales, origen.origen_ref).mezcla} />
                                {errores[`variedad-${index}`] && <Message severity="error" text={"Este campo es requerido."} />}
                            </ContenedorFiltro>

                            {getObjetoPorID(invernaderosVirtuales, origen.origen_ref).es_externo &&
                                <ContenedorFiltro col="3" label="Proveedor externo" sinFiltrarTexto>
                                    <Dropdown key={`med-origpr-${index}`} id={`${index}`} name="proveedor_ref" value={origen.proveedor_ref} options={proveedores}
                                        onChange={handleChangeOrigenes} optionLabel="nombre" optionValue="id" placeholder="Seleccionar" filter={true} />
                                    {errores[`proveedor-${index}`] && <Message severity="error" text={"Este campo es requerido."} />}
                                </ContenedorFiltro>}

                            <ContenedorFiltro col="3" extra="pr-0" label="Kilos a prestar" sinFiltrarTexto >
                                <InputText name="kilos_prestado" id={`${index}`} value={origen.kilos_prestado} onChange={handleChangeOrigenes} keyfilter="pnum" placeholder="Kg a prestar" />
                                {errores[`prestado-${index}`] && <Message severity="error" text={"Este campo es requerido."} />}
                            </ContenedorFiltro>

                            <ContenedorFiltro extra="cont-button-distr-trash" col="1" sinLabel >
                                <button className="p-button-distr-trash" onClick={() => eliminarItem(index)}>
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </button>
                            </ContenedorFiltro>
                        </SeccionFiltros>
                    </div>)
            })}
            <Button disabled={filtrarOrigenes().length === origenes.length} onClick={agregarOrigen} label="+ Agregar prestamo" className="p-button w-100 " />

            <div className="p-col-12">
                <div className="p-grid p-justify-end btn-row">
                    <Button label="Guardar" onClick={guardarPrestamo} />
                </div>
            </div>
        </Dialog >)
}
export default ModalRedistribucionMedleys;