import React from 'react'

const ContenedorFiltro = (props) => {

    return <div  className={`${props.extra ? props.extra : ""}  form-group p-col p-col-${props.col} p-md-${props.md} p-sm-${props.sm}`} style={props.style}>
        {props.label && props.filtrolabel && props.filtrolabel === "no" ?
            <label title={`${props.label}`} >{props.label}</label>
            :
            props.sinLabel ? null : <label title={`Filtrar  ${props.label} `} >{props.sinFiltrarTexto ? "" : "Filtrar"} {props.label}</label>
        }

        {props.children}
    </div>
}
export default ContenedorFiltro;
