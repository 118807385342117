import React, { useState, useEffect } from 'react';
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { useGlobal } from 'reactn';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { obtenerClientes, obtenerClientesCiclo } from '../../service/Clientes';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { obtenerEmpaques, obtenerEmpaquesCiclo } from '../../service/Empaques';
import { actualizarIncidencias, obtenerResumenNominaEmpaqueMultiproducto } from '../../service/NominaEmpaque';
import { obtenerPresentaciones } from '../../service/Presentaciones';
import ContenedorPrincipal from '../common/ContenedorPrincipal';
import SeccionFiltros from '../common/SeccionFiltros';
import ContenedorFiltro from '../common/ContenedorFiltro';
import ContenedorTabla from '../common/ContenedorTabla';
import ContenedorHeader from '../common/ContenedorHeader';

import ModalAusencias from './components/ModalAusencias';
import { DeepClone, dosDecimales, separadoComas } from '../../util/functions';
import { AUSENCIA_FALTA, AUSENCIA_NINGUNA, AUSENCIA_PERMISO, AUSENCIA_VACACIONES } from '../../util/constants';
import { nombresDiasSemana, nombresDiasSemanaPlanos } from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { obtenerResumenNominaEmpaqueMultiproductoCapturado } from '../../service/NominaEmpaqueQueries';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

const abreviacionesDias = ["L", "M", "X", "J", "V", "S"];
const Consultar = (props) => {
    const [ciclo, setCiclo] = useGlobal("ciclo");
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector();
    const [weekGlobal, setWeekGlobal] = useGlobal("weekGlobal");
    const [empleados, setEmpleados] = useState([]);
    const [filtroProductos, setFiltroProductos] = useState([]);
    const [filtroClientes, setFiltroClientes] = useState([]);
    const [empaques, setEmpaques] = useState([]);
    const [filtroEmpaques, setFiltroEmpaques] = useState();
    const [productosBases] = useGlobal("productosBases");
    const [medleysGeneral] = useGlobal("medleysGeneral");
    const [productosMezclados] = useGlobal("productosMezclados");

    const [clientes, setClientes] = useState([]);
    const [presetProducto, setPresetProducto] = useGlobal("presetProducto");
    const [presetEmpaque, setPresetEmpaque] = useGlobal("presetEmpaque");
    const [edicionIncidencias, setEdicionIncidencias] = useGlobal("edicionIncidencias");
    const [presentaciones, setPresentaciones] = useState([]);
    const [presentacionesOrig, setPresentacionesOrig] = useState([]);
    const [mostrarModalIncidencias, setMostrarModalIncidencias] = useState(false);
    const [cargando, setCargando] = useGlobal("cargando");
    const [triggerUpdate, setTriggerUpdate] = useState(false);
    const [totales, setTotales] = useState([]);
    const [esCapturado, setEsCapturado] = useState(false)
    const [footerTotales, setFooterTotales] = useState([])

    useEffect(() => {
        setNombreModulo("Consulta de nómina");
    }, [])
    useEffect(() => {
        if (presetProducto && productosBases.length > 0) { setFiltroProductos(productosBases.filter(el => el.id === presetProducto)); }
    }, [presetProducto, productosBases])
    useEffect(() => {
        if (presetEmpaque && empaques.length > 0) { setFiltroEmpaques(empaques.find(el => el.id === presetEmpaque)); }
    }, [presetEmpaque, empaques])

    useEffect(() => {
        if (ciclo && week.nombre !== "Cargando" && filtroEmpaques && filtroProductos.length > 0 && presentacionesOrig.length > 0) {
            obtenerDatosDeNomina();
        }
    }, [ciclo, week, filtroEmpaques, filtroProductos, presentacionesOrig])
    useEffect(() => {
        if (triggerUpdate) {
            setTriggerUpdate(false);
            obtenerDatosDeNomina();
        }
    }, [triggerUpdate]);

    useEffect(() => {
        if (filtroProductos.length > 0 && filtroClientes && presentacionesOrig.length > 0) {
            let clientesIds = filtroClientes.map(el => el.id);
            let ids = productosBases?.map(el => el.id) || []
            let presentaciones = presentacionesOrig.filter(el => ids.includes(el.producto_ref) && clientesIds.includes(el.cliente_ref));
            setPresentaciones(presentaciones);
        }
    }, [filtroProductos, filtroClientes, presentacionesOrig])

    useEffect(() => {
        if (filtroProductos.length > 0 && filtroClientes && presentacionesOrig.length > 0) {
            setEmpleadoTotales()
        }
    }, [filtroClientes])
    const setEmpleadoTotales = () => {

    }

    useEffect(() => {
        if (ciclo) { obtenerDatosIniciales(); }
    }, [ciclo])
    const obtenerDatosIniciales = async () => {
        try {
            setCargando(true);
            let [clientesCiclo, clientes, empaques, presentaciones]
                = await Promise.all([obtenerClientesCiclo(ciclo), obtenerClientes(), obtenerEmpaquesCiclo(ciclo), obtenerPresentaciones()]);
            const ids = clientesCiclo.map(el => el.cliente_ref);
            clientes = clientes.filter(el => ids.includes(el.id));

            setClientes(clientes);
            setEmpaques(empaques);
            if (!presetEmpaque) setPresetEmpaque(empaques?.[0].id || "");
            if (!presetProducto) setPresetProducto(medleysGeneral.id);
            setPresentacionesOrig(presentaciones);
            setPresentaciones(presentaciones.slice(1, 3));
        } catch (error) {
            console.log("ERROR", error);
        }
        setCargando(false);
    }

    const obtenerDatosDeNomina = async (params) => {
        try {
            setCargando(true);
            let resumenCapturado = await obtenerResumenNominaEmpaqueMultiproductoCapturado(ciclo, week.time, filtroEmpaques);
            setCargando(true);
            let resumen;
            if (resumenCapturado.empty) {
                resumen = await obtenerResumenNominaEmpaqueMultiproducto(ciclo, week.time, filtroEmpaques, filtroProductos, productosBases);
                setCargando(true);
                setEsCapturado(false);
            } else {
                resumen = resumenCapturado.datos;
                setEsCapturado(true);
            }
            let totales = obtenerTotalesNominaEmpaque(resumen);
            setCargando(true);
            setTotales(totales);
            resumen = resumen.map(el => ({ ...el, empacadora: el.empacadora?.toUpperCase() }))

            setEmpleados(resumen);
        } catch (error) {
            console.log("ERRO RESUMEN NOMINA: ", error);
        } finally {
            setCargando(false);
        }
    }

    const templatePresentacion = (rowData, column) => {
        let datosPresentacion = rowData.bonos[column.field];
        if (datosPresentacion?.cantidadEmpacada > 0) {
            return <div><span className="inner-data-line">{datosPresentacion.cantidadEmpacada}<span class="label-unidad">Cajas</span></span><span className="inner-data-line"><span class="label-unidad left">$</span>{dosDecimales(datosPresentacion.bonoTotal)}</span></div>
        }
    }
    const inocuidadTemplate = (rowData, column) => {
        return <div onClick={() => editarIncidencias(rowData)}> {rowData["incidencias"]["cumple_inocuidad"] ? <span className="inocuidad-ok "><FontAwesomeIcon icon={faCheck} />Cumple</span> : <span className="inocuidad-no"><FontAwesomeIcon icon={faTimes} />No cumple</span>}</div>
    }
    function faltaTemplate(rowData, column) {
        let dias = rowData.incidencias.dias;
        let claves = nombresDiasSemanaPlanos;
        return (
            <div className={'ausencias-week'} onClick={() => editarIncidencias(rowData)}>
                {claves.map((clave, index) => {
                    return (
                        <span key={clave} className={makeNombreFaltaCSSClass(dias[clave])} title={makeNombreFaltaCSSClass(dias[clave])}>
                            {abreviacionesDias[index]}
                        </span>
                    );
                })}
            </div>
        );
    }
    function makeNombreFaltaCSSClass(dia) {
        if (dia === "falta") return AUSENCIA_FALTA
        if (dia === "permiso") return AUSENCIA_PERMISO
        if (dia === "vacaciones") return AUSENCIA_VACACIONES
        return AUSENCIA_NINGUNA
    }
    //const ausenciasTemplate = (rowData, column) => <div onClick={() => editarIncidencias(rowData)}>AUSENCIAS</div>;
    const totalTemplate = (rowData, column) => {
        let bonos = rowData.bonos, bonoTotal = 0, cuentaTotal = 0

        Object.keys(bonos).forEach(key => {
            const presentacionFound = presentaciones?.find(pres => pres.id === key)
            const enFiltro = filtroClientes?.some(cli => cli.id === presentacionFound?.cliente_ref)
            if (presentacionFound && enFiltro) {
                bonoTotal += parseFloat(bonos[key].bonoTotal)
                cuentaTotal += parseFloat(bonos[key].cantidadEmpacada)
            }
        })
        if (!bonoTotal && bonos.bono_total !== "NA") bonoTotal = bonos.bono_total 
        return (
            <div>
                {cuentaTotal > 0 &&
                    <span className="inner-data-line">{cuentaTotal}
                        <span class="label-unidad">Cajas globales</span>
                    </span>}
                <span className="inner-data-line">
                    <span class="label-unidad left">$</span>{separadoComas(dosDecimales(bonoTotal))}
                </span>
            </div>)
    }
    const ponderadoCalidadTemplate = (rowData, column) => {
        let ponderado = rowData["bonos"]["ponderado"];
        return isNaN(ponderado) ? "N/A" : `${Math.round(100 * ponderado)} % `
    }

    const editarIncidencias = (rowData, column) => {
        setEdicionIncidencias(rowData);
        setMostrarModalIncidencias(true);
    }

    const obtenerColumnas = (params) => {
        let cols = [];
        try {
            cols.push(<Column field="nombreCompleto" header="Nombre" sortable />);
            cols.push(<Column field="empacadora" header="Codigo empacadora o Rol" sortable />);
            // insertar datos de presentaciones
            cols.push(<Column field="ponderado_calidad" header="Ponderado calidad" body={ponderadoCalidadTemplate} sortable />);

            presentaciones.forEach(presentacion => {
                let datosPresentacion = totales[0]?.["bonos"]?.[presentacion?.["id"]];
                if (datosPresentacion?.["bonoTotal"] > 0) {
                    cols.push(<Column field={presentacion.id} header={presentacion.presentacion} body={templatePresentacion} sortable />);
                }
            })
            cols.push(<Column header="Inocuidad" body={inocuidadTemplate} />)
            cols.push(<Column header="Asistencia" body={faltaTemplate} />)
            cols.push(<Column header="Totales" body={totalTemplate} sortable />)
            return cols;
        }
        catch (error) {
            console.log("obtenerColumnas ERROR", error)
            props.history.replace("/nomina_empaque")
        }
    }
    const obtenerFooter = () => {
        let cols = [], totalCajas = 0, totalPrecio = 0
        try {
            return (
                <ColumnGroup>
                    <Row>
                        <Column footer="Total" colSpan={1} />
                        <Column footer={<span></span>} colSpan={2} />
                        {presentaciones.map((presentacion, ind) => {
                            let datosPresentacion = totales[0]?.["bonos"]?.[presentacion?.["id"]];
                            totalCajas += parseInt(datosPresentacion?.["cantidadEmpacada"] || "0")
                            totalPrecio += parseFloat(datosPresentacion?.["bonoTotal"] || "0")
                            if (datosPresentacion && datosPresentacion?.["bonoTotal"] > 0) {
                                return <Column footer={<div><span className="inner-data-line">{datosPresentacion?.["cantidadEmpacada"]}<span class="label-unidad">Cajas</span></span><span className="inner-data-line"><span class="label-unidad left">$</span>{dosDecimales(datosPresentacion?.["bonoTotal"])}</span></div>}
                                    body={<div><span className="inner-data-line">{datosPresentacion?.["cantidadEmpacada"]}<span class="label-unidad">Cajas</span></span><span className="inner-data-line"><span class="label-unidad left">$</span>{dosDecimales(datosPresentacion?.["bonoTotal"])}</span></div>} field={presentacion.id} header={presentacion.presentacion} sortable />
                            }
                        })}
                        <Column footer={<span></span>} colSpan={2} />
                        <Column footer={<div><span className="inner-data-line">{totalCajas}<span class="label-unidad">Cajas</span></span><span className="inner-data-line"><span class="label-unidad left">$</span>{dosDecimales(totalPrecio)}</span></div>
                            //<div><span className="inner-data-line">{datosPresentacion.cantidadEmpacada}<span class="label-unidad">Cajas</span></span><span className="inner-data-line"><span class="label-unidad left">$</span>{dosDecimales(datosPresentacion["bonoTotal"])}</span></div>
                        } colSpan={1} />
                    </Row>
                </ColumnGroup>)
        }
        catch (error) {
            console.log("obtenerFooter ERROR", error)
            props.history.replace("/nomina_empaque")
        }
    }

    const obtenerTotalesNominaEmpaque = (resumen) => {
        let result = {};
        result["nombreCompleto"] = "Total";
        result["empacadora"] = "N/A";
        result["bonos"] = {};
        result["bonos"]["cuenta_total"] = 10;
        result["bonos"]["bono_total"] = 20;
        resumen.forEach(empleado => {
            presentacionesOrig.forEach(presentacion => {
                if (result["bonos"][presentacion.id]) {
                    if (empleado["bonos"][presentacion.id]) {
                        result["bonos"][presentacion.id]["bonoTotal"] += empleado["bonos"][presentacion.id]["bonoTotal"]
                        result["bonos"][presentacion.id]["cantidadEmpacada"] += empleado["bonos"][presentacion.id]["cantidadEmpacada"]
                    }
                } else {
                    if (empleado["bonos"][presentacion.id]) {
                        result["bonos"][presentacion.id] = { ...empleado["bonos"][presentacion.id] }
                    }
                }
            })
            result["bonos"]["cuenta_total"] += empleado["bonos"]["cuenta_total"] || 0;
            result["bonos"]["bono_total"] += empleado["bonos"]["bono_total"] || 0;
        })
        return [result];
    }

    const guardarIncidencias = async (empleadoId, datos) => {
        try {
            setCargando(true);
            cerrarModalAusencias();
            let result = await actualizarIncidencias(ciclo, presetEmpaque, week.time, empleadoId, datos);
            setTriggerUpdate(true);
        } catch (error) {
            console.log("ERROR", error);
        } finally {
            setCargando(false);
        }
    }

    const cerrarModalAusencias = (params) => {
        setEdicionIncidencias(null);
        setMostrarModalIncidencias(false);
    }

    return (
        <ContenedorPrincipal>
            <ContenedorHeader iconos={"boxes-icon"} atras={props.history.goBack} titulo="Consulta de nómina" />

            <SeccionFiltros >
                <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />

                <ContenedorFiltro label="Empaques" sinFiltrarTexto={true}>
                    <Dropdown value={filtroEmpaques} options={empaques} optionLabel="nombre" dataKey="id" onChange={e => setFiltroEmpaques(e.value)} />
                </ContenedorFiltro>
                <ContenedorFiltro label="Producto" sinFiltrarTexto={true}>
                    <MultiSelect value={filtroProductos} options={productosBases} optionLabel="nombre" dataKey="id" onChange={e => setFiltroProductos(e.value)}
                        selectedItemsLabel={`${filtroProductos.length} productos seleccionados`} />
                </ContenedorFiltro>
                <ContenedorFiltro label="Cliente" sinFiltrarTexto={true}>
                    <MultiSelect value={filtroClientes} options={clientes} optionLabel="nombre" dataKey="id" onChange={e => setFiltroClientes(e.value)}
                        selectedItemsLabel={`${filtroClientes.length} clientes seleccionados`} />
                </ContenedorFiltro>
            </SeccionFiltros>

            {esCapturado ? "ES DATO CAPTURADO" : ""}
            <ContenedorTabla>
                <DataTable value={empleados} footerColumnGroup={obtenerFooter()}>
                    {obtenerColumnas()}
                </DataTable>
            </ContenedorTabla>

            <ModalAusencias visible={mostrarModalIncidencias} onHide={cerrarModalAusencias} cancelar={cerrarModalAusencias} guardar={guardarIncidencias} />
        </ContenedorPrincipal>)
}
export default Consultar;