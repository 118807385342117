import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, {useEffect, useState} from 'react'
import { obtenerCiclos } from '../../../service/Ciclos';
import { obtenerEmpaques, obtenerEmpaquesCiclo, obtenerRolesEmpaque, registrarEmpaque, updateHabilitacion, actualizarEmpaque, obtenerEmpaquesDisponiblesTransferencia } from '../../../service/Empaques';
import ContenedorInput from '../../common/ContenedorInput';
import SeccionFormulario from '../../common/SeccionFormulario';
import {InputSwitch} from 'primereact/inputswitch';
import { MultiSelect } from 'primereact/multiselect';
import SeccionBotones from '../../common/SeccionBotones';
import { Button } from 'primereact/button';
import {  useGlobal} from 'reactn';
import {Message  } from 'primereact/message';
import { setPlaceholder } from '../../../util/functions'

const FormularioEmpaque = (props) => {
    const [empaque, setEmpaque] = useState();
    const [empaquesBase, setEmpaquesBase] = useState([]);
    const [noEmpleados, setNoEmpleados] = useState("");
    const [hayAutomatica, setHayAutomatica] = useState(false);
    const [productos] = useGlobal("productosBases");
    const [productosRelacionados, setProductosRelacionados] = useState([]);
    const [ciclos, setCiclos] = useState([]);
    const [ciclo, setCiclo] = useState();
    const [presetNombre, setPresetNombre] = useState();
    const [habilitado, setHabilitado] = useState(true);
    const [roles, setRoles] = useState([]);
    const [presetEmpaque, setPresetEmpaque] = useGlobal("presetEmpaque");
    const [cargando, setCargando] = useGlobal("cargando");
    const [editId, setEditId] = useState("");
    const [empaquesDisponibles, setEmpaquesDisponibles] = useState([]);
    const [empaquesPorTransferir, setEmpaquesPorTransferir] = useState([]);
    var [, setModalConfirmacion] = useGlobal("modalConfirmacion");
    let [,setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    useEffect(() => {
        setHabilitadoSelectorCiclo(false);
      return () => {
        setHabilitadoSelectorCiclo(true);
      }
    }, [])
    const [errores, setErrores] = useState([]);
    const atras = (params) => {
        props.history.goBack()
    }
    useEffect(() => {
        obtenerDatosIniciales();        
    }, [])
    useEffect(() => {
        if(presetEmpaque && roles.length > 0 && ciclos.length > 0 ){
            
                        
            preprocesarEdicion(presetEmpaque);
            setEditId(presetEmpaque.id);
            setEditing(true);
            setPresetEmpaque(null);
        }
    }, [presetEmpaque, roles, ciclos,])
    const preprocesarEdicion = async (presetEmpaque) => {        
        let ciclo = ciclos.find(el=>el.id == presetEmpaque.ciclo_ref);
        setCiclo(ciclo);
        setPresetNombre(presetEmpaque.empaque_ref);
        setNoEmpleados(presetEmpaque.empleados);
        setHayAutomatica(presetEmpaque.empacadora_automatica);
        setProductosRelacionados(presetEmpaque.productos_relacionados);
        setHabilitado(presetEmpaque.habilitado);  
        let copiaRoles = roles.slice();
        copiaRoles.forEach(rol => {            
            rol.valor = parseFloat(presetEmpaque.bonos_fijos[rol.id]);
        })
        
        
        setRoles(copiaRoles);
    }
    
    const obtenerDatosIniciales = async (params) => {
        try {
            setCargando(true);
            let ciclosPromise = obtenerCiclos(); 
            let rolesPromise = obtenerRolesEmpaque();
            const [ciclos,roles] = await Promise.all([ciclosPromise,rolesPromise])
            setCiclos(ciclos);       
            roles.forEach(rol => {
                rol.valor = "";
            })
            setRoles(roles);    
        } catch (error) {
            console.log("HUBO UN ERROR");
            console.log(error);    
        }finally{
            setCargando(false);
        }
                

    }
    useEffect(() => {
        if(ciclo){
            actualizarEmpaquesDisponibles(ciclo);
        }
    }, [ciclo])

    const actualizarEmpaquesDisponibles = async () => {
        try {
            let empaquesPromise = obtenerEmpaques(); 
            let empaquesCicloPromise = obtenerEmpaquesCiclo(ciclo.id);
            let empaquesDisponiblesTransferenciaPromise = obtenerEmpaquesDisponiblesTransferencia(ciclo.id);
            let [empaques,empaquesCiclo,empaquesDisponiblesTransferencia] = await Promise.all([empaquesPromise,empaquesCicloPromise,empaquesDisponiblesTransferenciaPromise])

            if(editing){
             setEmpaquesBase(empaques);   
            }else{
                let idsUsados = empaquesCiclo.map(el=>el.empaque_ref);
                empaques = empaques.filter(el=>!idsUsados.includes(el.id));
                
                setEmpaquesBase(empaques);
                setEmpaquesDisponibles(empaquesDisponiblesTransferencia);
            }
        } catch (error) {
            console.log("error aed")    
            console.log(error);
        }
        
    }    
    useEffect(() => {
        if(empaquesBase.length>0 && presetNombre){
            
            
            
            let empaque = empaquesBase.find(el=>el.id === presetNombre);
            setEmpaque(empaque);
            setPresetNombre(null);
        }
    }, [empaquesBase, presetNombre])
    const handleRolChange = ( idRol, valor) => {
        let copia = roles.slice();
        let rol = copia.find(el=>el.id == idRol);
        rol.valor = valor;
        setRoles(copia);
    }
    const guardar = async (params) => {
        let errores = validar();
        
        
        if(errores.length > 0){
            setErrores(errores);
            return;     
        }
        if(editing){
            let empaque = procesarPreguardado();
            let result = await actualizarEmpaque(ciclo.id,editId, empaque);
            props.history.goBack();
        }else{
            try {
                let empaque = procesarPreguardado();
                let result = await registrarEmpaque(ciclo.id,empaque, empaquesPorTransferir);
                props.history.goBack();
            } catch (error) {
                console.log("OCURRIO UN ERROR al registrar");
                console.log(error);    
            }            
        }
    }
    const clearErrores = (params) => {
        setErrores([]);
    }
    
    const validar = (params) => {
        let errores = [];
        if(!empaque){
            errores.push("empaque");
        }
        if(!ciclo){
            errores.push("ciclo");
        }
        if(!noEmpleados || noEmpleados=== ""){
            errores.push("noEmpleados")
        }
        return errores;
    }
    

    const procesarPreguardado = (params) => {
        let obj = {}        
        obj["empaque_ref"] = empaque.id;
        obj["empacadora_automatica"] = hayAutomatica;
        obj["empleados"] = noEmpleados;
        obj["productos_relacionados"] = productosRelacionados;
        obj["bonos_fijos"] ={};
         roles.forEach(el=>{
             obj["bonos_fijos"][el.id] = el.valor;
         })
        return obj;  
    }
    
    const handleToggleHabilitacion = (params) => {
        activarModalConfirmacionHabilitacion();
    }
    useEffect(() => {
        clearErrores();
    }, [ciclo, empaque, noEmpleados])
    const toggleHabilitacion = async (params) => {
        let result = await updateHabilitacion(ciclo.id, editId,!habilitado);
        setHabilitado(!habilitado);
    }
    const activarModalConfirmacionHabilitacion = (params) => {
        let mensaje = habilitado ?
          "¿Estás seguro que deseas deshabilitar este empaque?" :
          "¿Estás seguro que deseas habilitar este empaque?";
        let titulo = "Confirmación de acción";
        let aceptar = () => { toggleHabilitacion() };
        let cancelar = () => { }
        setModalConfirmacion({ mensaje, titulo, aceptar, cancelar })
      }
    
    const [editing, setEditing] = useState(false);
    return <div className="p-grid p-justify-center">   
        <div className="p-col-12 title-container">    
          <span className="back-btn" onClick = {atras}></span>      
          {editing?
            <React.Fragment>
              <span className="icon-general activity-icon"></span>
              <span className="titles-group">
                <h1 className="header-title">Modificar empaque</h1>
              </span>
            </React.Fragment>
          :
            <React.Fragment>
              <span className="icon-general activity-icon"></span>
              <span className="titles-group">
                <h1 className="header-title">Crear empaque</h1>
              </span>
            </React.Fragment>
          }

        </div>     
        <div className="p-col-10">
          <div className="p-grid p-fluid ">
          <SeccionFormulario titulo = {"Empaque"}>
              <ContenedorInput col = "12" md = "4" sm = "6" label = "Ciclo" inputClass = "">
                <Dropdown value = {ciclo} dataKey="id" optionLabel="nombre" options ={ciclos} onChange={(e)=>{setCiclo(e.value)}} disabled={editing}/>  
                {errores.includes("ciclo")?<Message severity="error" text = "Este campo es requerido"></Message>:null}              
              </ContenedorInput>                             
             <ContenedorInput col = "12" md = "4" sm = "6" label = "Nombre" inputClass = "" disabled={editing}>
                <Dropdown value = {empaque} dataKey="id" optionLabel="nombre" options ={empaquesBase} onChange={(e)=>{setEmpaque(e.value)}} disabled={editing}/>                
                {errores.includes("empaque")?<Message severity="error" text = "Este campo es requerido"></Message>:null}
              </ContenedorInput>
              <ContenedorInput col = "12" md = "4" sm = "6" label = "# Empleados" inputClass = "" >
                <InputText value = {noEmpleados}  onChange= {(e)=>setNoEmpleados(e.target.value)} keyfilter="int" />
                {errores.includes("noEmpleados")?<Message severity="error" text = "Este campo es requerido"></Message>:null}
              </ContenedorInput>
              <ContenedorInput col = "12" md = "4" sm = "6" label = "Tiene automatica" inputClass = "">
                  <InputSwitch checked={hayAutomatica} onChange={(e) => setHayAutomatica(e.value)} />
              </ContenedorInput>
              <ContenedorInput col = "12" md = "4" sm = "6" label = "Productos relacionados" inputClass = "">
                <MultiSelect options  =  {productos} value = {productosRelacionados} onChange={(e)=>setProductosRelacionados(e.value)} optionLabel="nombre" optionValue="id" selectedItemsLabel={`${productosRelacionados.length} productos seleccionados`}/>
              </ContenedorInput>              

          </SeccionFormulario>
          <SeccionFormulario titulo = "Asignación de empleados">

              <div className="card p-col-12">
                <div className="p-grid">
                  <div className="form-group p-col-12">
                      <label >Empaques disponibles</label>                      
                      <MultiSelect options = {empaquesDisponibles} value = {empaquesPorTransferir} onChange = {(e)=>setEmpaquesPorTransferir(e.value)}
                      optionLabel="nombre" dataKey="id" selectedItemsLabel={`${empaquesPorTransferir.length} empaques seleccionados`}/>
                  </div>                  
                </div>
              </div>

          </SeccionFormulario>
          <SeccionFormulario titulo="Bonos Fijos">
              {roles.map((rol,index)=>{
                  return <ContenedorInput col = "12" md = "4" sm = "6" label = {rol.nombre} inputClass = "" key = {index}>
                    <InputText value = {rol.valor}  onChange= {(e)=>handleRolChange(rol.id, e.target.value)} keyfilter="num" />
                  </ContenedorInput>
              })}
            
          </SeccionFormulario>
          </div>
        </div>
        <div className="p-col-12">
            <SeccionBotones>
                
                <Button label = "Guardar" onClick={guardar} />
                {editing?<Button label = {habilitado?"Deshabilitar": "Habilitar"} onClick={handleToggleHabilitacion} />:null}
                <Button label = "Cancelar" onClick={atras} />
            </SeccionBotones>
        </div>
    </div>
}

export default FormularioEmpaque;
