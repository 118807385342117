import { setGlobal, } from "reactn"
import * as firebase from "firebase/app";
import "firebase/firestore";
import { miliSegundosSemana, ROL_AGENTE, segundosSemana } from "../constants";
import { getEmpresas } from "./Empresas";
import { getLocaciones } from "./Locaciones";
import { uploadStatistics } from './Estadisticas'
import readXlsxFile from 'read-excel-file'
import { getInicioSemanaDia } from "./fechas";
import { getObjetoPorID, naturalCompareSort, sumarNumerosArrayPorCampo } from "../util/functions";
import { obtenerEmpaques } from "./Empaques";

function registrarInvocaciones(count) {
    uploadStatistics(count)
}

export const getActividadesMantenimiento = async () => {
    let response = await firebase.firestore().collection("actividades_mantenimiento").orderBy("indice", "asc").get().catch(error => { console.log(`Error al obtener actividades mantenimiento: ${JSON.stringify(error)}`) })
    registrarInvocaciones(response.size)
    let actividades = response.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return naturalCompareSort(actividades, "indice")
}

export const getActividadPleaneada = async (cicloID, actividadID) => {
    let actividades = await firebase.firestore().collection("ciclos").doc(cicloID).collection("planeacion_actividades").doc(actividadID).get().catch(error => { console.log(`Error al obtener actividad de requisicion: ${JSON.stringify(error)}`) })
    registrarInvocaciones()
    return actividades.docs.map(doc => ({ id: doc.id, ...doc.data() }));
}
export const getActividadDeRequisicion = async (cicloID, requisicionID) => {
    let response = await firebase.firestore().collection("ciclos").doc(cicloID).collection("planeacion_actividades").where("requisicion_ref", "==", requisicionID).get().catch(error => { console.log(`Error al obtener actividad de requisicion: ${JSON.stringify(error)}`) })
    registrarInvocaciones()
    let actividad = response.docs?.[0]
    return actividad ? { id: actividad.id, ...actividad.data() } : false
}

export const filtrarAgentes = (agentes, empresas, locaciones) => {
    const empresasIDs = empresas?.map(empresa => empresa.id);
    const locacionesIDs = locaciones?.map(locacion => locacion.id);
    return agentes.filter(agente => {
        let condicionEmpresa = agente.empresas?.some(empresa => empresasIDs.includes(empresa));
        let condicionLocacion = locacionesIDs.includes(agente.locacion_ref);
        return (condicionEmpresa || agente.empresas?.length === 0) && condicionLocacion;
    });
}

export const getDatosMantenimiento = async () => {
    //let agente = agenteID ? await getAgente
    let empresas = await getEmpresas();
    let locaciones = await getLocaciones();
    let actividadesMantenimiento = await getActividadesMantenimiento();
    //let agentes = await getAgentes()

    let categorias = await getCategoriasActividadesMantenimiento();
    categorias = procesarCategorias(categorias);

    let sitios = await obtenerEmpaques();
    const sitiosHTF = sitios.map(el => {
        if (el.nombre !== "Empaque Agropark") return el;
        else return { ...el, nombre: "Agropark" }
    })
    const empresasSinLort = empresas.filter((empresa) => empresa.nombre !== "LORT")
    setGlobal({
        actividadesMantenimiento, empresas, empresasFiltrosMantenimiento: empresasSinLort, empresasSinLort, categorias,
        locaciones, locacionesFiltrosMantenimiento: locaciones, sitiosHTF, datosCargadosMantenimiento: true,
    })
    return { empresas, locaciones, actividadesMantenimiento }
}

export const getAgentes = async () => {
    let agentes = await firebase.firestore().collection("usuarios").where("rol", "==", ROL_AGENTE).get().catch(error => { console.log(`Error al obtener agentes: ${JSON.stringify(error)}`) })
    registrarInvocaciones(agentes?.size)
    return agentes.docs.map(doc => ({ id: doc.id, ...doc.data() }));
}


export const obtenerActividadesAgenteSemana = async (cicloID, semana, agente, agenteID = agente.id) => {
    semana = Math.round(semana / 1000)/*TEMPORARY*/
    let response = await firebase.firestore().collection("ciclos").doc(cicloID).collection("planeacion_actividades").where("agente_ref", "==", agenteID)
        .where("dia", ">=", semana).where("dia", "<", (semana + segundosSemana)).get().catch(error => { console.log(`Error al obtener actividades agente semana: ${JSON.stringify(error)}`) })
    registrarInvocaciones(response.size)
    let actividadesAgente = response.docs.map(doc => ({ id: doc.id, ...doc.data(), dia: doc.data().dia * 1000 }));/*TEMPORARY*/

    //let filtered = actividadesAgente.filter(el => el.is_emergencia)
    //filtered.forEach(el => {window.alert(el.nombreTarea)})

    return procesarDatosActividadesAgentes(actividadesAgente, agente)
}
export const obtenerDiaActividadAgenteMasDelFuturo = async (cicloID, agente) => {
    let response = await firebase.firestore().collection("ciclos").doc(cicloID).collection("planeacion_actividades").where("agente_ref", "==", agente.id)
        .orderBy("dia", "desc").limit(1).get().catch(error => { console.log(`Error al obtener dia del actividad futuro: ${JSON.stringify(error)}`, error) })

    registrarInvocaciones(1)
    return response.empty ? Date.now() : response.docs[0].data().dia * 1000
}
export const obtenerDiaActividadTodosMasDelFuturo = async (cicloID) => {
    let response = await firebase.firestore().collection("ciclos").doc(cicloID).collection("planeacion_actividades").orderBy("dia", "desc")
        .limit(1).get().catch(error => { console.log(`Error al obtener dia del actividad futuro: ${JSON.stringify(error)}`, error) })

    registrarInvocaciones(1)
    return response.empty ? Date.now() : response.docs[0].data().dia * 1000
}
export const adelantarSemanasMantenimiento = async (ciclo, asignarListaSemana, agente) => {
    let semanasAdelantado = 0;
    let registroMasEnElFuture = agente
        ? await obtenerDiaActividadAgenteMasDelFuturo(ciclo, agente)
        : await obtenerDiaActividadTodosMasDelFuturo(ciclo);
    //console.log("REG: "+registroMasEnElFuture)

    registroMasEnElFuture = getInicioSemanaDia(registroMasEnElFuture)
    const siguienteSemana = getInicioSemanaDia(Date.now() + miliSegundosSemana)
    if (registroMasEnElFuture < siguienteSemana)
        semanasAdelantado = 1
    else {
        for (let index = Date.now(); index < registroMasEnElFuture; index += miliSegundosSemana) {
            //console.log(index)
            semanasAdelantado++
        }
    }
    asignarListaSemana(semanasAdelantado)
}

export const procesarDatosActividadesAgentes = (tareas, agente) => {
    let empresas = new Set()
    tareas.forEach(tarea => { empresas.add(tarea.empresa_ref) });
    return { ...agente, tareas, empresas: Array.from(empresas) }
}

export const guardarActividad = async (cicloID, data, actividadID = false) => {
    let dataMILISEGUNDOS = { ...data }
    dataMILISEGUNDOS.dia = Math.round(data.dia / 1000)/*TEMPORARY*/
    if (!actividadID) actividadID = firebase.firestore().collection("ciclos").doc(cicloID).collection("planeacion_actividades").doc().id
    await firebase.firestore().collection("ciclos").doc(cicloID).collection("planeacion_actividades").doc(actividadID).set(dataMILISEGUNDOS, { merge: true }).catch(error => { console.log(`Error al guardar actividad mantenimiento: ${JSON.stringify(error)}`) })
    return { id: actividadID, ...data };
}
export const actualizarActividad = async (cicloID, actividadID, data) => await guardarActividad(cicloID, data, actividadID)
export const guardarActividadEmergencia = async (cicloID, data) => await guardarActividad(cicloID, data)
export const guardarActividadRequisicion = async (cicloID, data, tareaID) => await guardarActividad(cicloID, data, tareaID)

export const eliminarActividad = async (cicloID, actividadID) => {
    await firebase.firestore().collection("ciclos").doc(cicloID).collection("planeacion_actividades").doc(actividadID).delete();
    return true;
}
export const eliminarActividadMant = async (docID) => {
    await firebase.firestore().collection("actividades_mantenimiento").doc(docID).delete();
    return true;
}
export const eliminarCatMant = async (docID) => {
    await firebase.firestore().collection("categorias_actividades_mantenimiento").doc(docID).delete();
    return true;
}

export const procesarCategorias = (categorias) => {
    //obtener categorias con tipo categoria
    const categoriasTop = categorias.filter(categoria => categoria.tipo === 'categoria');
    // agregar categorias tipo subcategoria a las categorias padre
    categoriasTop.forEach(categoria => {
        const subcategorias = categorias.filter(subcategoria => subcategoria.tipo === 'subcategoria' && subcategoria.categoria_ref === categoria.id);
        categoria.subcategorias = subcategorias;
    });
    return categoriasTop;
}
export const guardarActividades = async (cicloID, actividades, agente_ref, dia, empresa_ref, sitioHTF, tareaID = false) => {
    let promises = actividades.map(actividad => guardarActividad(cicloID, { actividad_ref: actividad.id, agente_ref, dia, empresa_ref, sitioHTF }, tareaID))
    let activities = await Promise.all(promises);
    return activities;
}
export const getCategoriasActividadesMantenimiento = async () => {
    let response = await firebase.firestore().collection("categorias_actividades_mantenimiento").orderBy("indice", "asc").get();
    registrarInvocaciones(response.size)
    let categorias = response.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    return naturalCompareSort(categorias, "indice")

}

export const getFirestoreID = () => firebase.firestore().collection("getNewID").doc().id;

export const guardarActividadesMantenimiento = async (actividadID = getFirestoreID(), data) => {
    if (data.tipo) await firebase.firestore().collection("categorias_actividades_mantenimiento").doc(actividadID).set(data, { merge: true });
    else await firebase.firestore().collection("actividades_mantenimiento").doc(actividadID).set(data, { merge: true });
    return true
}

export const copiarTareaAFecha = async (ciclo, tarea, newTareaID) => {
    await firebase.firestore().collection("ciclos").doc(ciclo).collection("planeacion_actividades").doc(newTareaID).set(tarea);
}
export const transferirTareaAFecha = async (ciclo, tarea, tareaID) => copiarTareaAFecha(ciclo, tarea, tareaID);

/////////////////////////SUMAR TIEMPOS DE TAREAS PLANEADAS/////////////////////////
export const getSumaTotalTiempoEstimado = (actividades = [], tareas = []) => {
    let suma = tareas.reduce((suma, tarea) => suma + getTiempoEstimadoDeTarea(actividades, tarea), 0)//option 1
    return suma;
}

//A. Si es tarea_requisición, guardamos tiempo_estimado (de input modal requisiciones)
//B. Si hay cambio de input estimado, guardamos tiempo_estimado (modal de tareas planeadas)
export const getTiempoEstimadoDeTarea = (actividades = [], tarea) => {
    const actividad = getObjetoPorID(actividades, tarea.actividad_ref);
    //console.log("ACTS: ", { actividad, tarea, actividades,  })
    
    if (!actividad.id || !tarea || tarea.realizada) { return 0; }

    //1. Si hay tiempo_estimado en la tarea planeada, utiliza eso.
    if (tarea.tiempo_estimado) { return tarea.tiempo_estimado; }

    //---Si NO hay tiempo_estimado en la tarea planeada--
    const tiempoPorLocacion = getTiempoEstimadoPorEmpresaYLocacion(actividad, tarea);
    //console.log("TIEMPO POR: ", {actividad, tarea, tiempoPorLocacion})
    //2. Si hay tiempo por locación seleccionado, regresamos el valor del objeto "tiempos_estimados" de la actividad original.
    //3. Tomar tiempo de defecto de la actividad original
    return tiempoPorLocacion || actividad.tiempo_estimado;
}
const getTiempoEstimadoPorEmpresaYLocacion = (actividad, tarea) => {
    const { empresa_ref, empresa, sitioHTF } = tarea;
    const tiempoPorLocacion = actividad?.tiempos_estimados?.[empresa_ref || empresa]?.[sitioHTF] || false;
    return tiempoPorLocacion;
}
/////////////////////////////////////END SUMAR/////////////////////////////////////


export const getEmpaquesIndexed = (empaques) => {
    const empaquesIndexed = empaques?.reduce((acc, empaque) => {
        acc[empaque.nombre.split(" ").join("_")] = empaque
        return acc
    }, {})
    return empaquesIndexed || {}
}
export const getEmpresasIndexed = (empresas) => {
    const empresasIndexed = empresas.reduce((acc, empresa) => {
        acc[empresa.nombre] = empresa
        return acc
    }, {})
    return empresasIndexed
}
export const sumarTiemposTomadosParciales = (tiempos_tomados = []) => sumarNumerosArrayPorCampo(tiempos_tomados, "tiempo_tomado");

//----------------------------------------
//Refactor de tiempos estimados
/*
A. Si es tarea_requisición, tomar tiempo_estimado (de input modal requisiciones)
B. Si hay cambio de input estimado, tomar tiempo_estimado (modal de tareas planeadas)
^Gaurdar tiempo_estimado como campo de la tarea asignada


1. Si hay tiempo_estimado en la tarea planeada, utiliza eso
---Si NO hay tiempo_estimado en la tarea planeada--
2. Si hay tiempo por locación seleccionado, jalamos del objeto "tiempos_estimados" de la actividad original
3. Tomar tiempo de defecto de la actividad original
^NO guardar tiempo_estimado




//Refactor de tiempos tomados
1. Si es tarea parcíal, calcular tiempo tomado.
2. Utilizar tiempo_tomado



Pasos
1. Lista de cada componente que utiliza tiempo estimado
    Admin
        Card dia: suma
        Card tarea
        Modal de tarea creada
        

*/
