import * as firebase from "firebase/app";
import "firebase/firestore";
import { getGlobal } from 'reactn'
import { obtenerPesoCajaParaSemana } from './Producto';
import { obtenerRegistrosSemana, obtenerReporteCosecha, obtenerValoresReales, obtenerValoresRealesParaCumplimiento } from './Cosecha'
import { obtenerPackoutSemanal, obtenerkgm2semanal } from './ReportesProducto';
import { obtenerInvernaderosVirtualesHabilitados, obtenerInvernaderosVirtualesHabilitadosIncluidosExternos } from './Invernaderos';
import { obtenerClientesCiclo } from './Clientes';
import { obtenerOrdenesSemana as oos } from './QueriesOrdenes';
import {
    obtenerInicioDia, obtenerDiasDeSemana,
    obtenerIndiceDiaSemana, obtenerHora, obtenerListaDeSemanas
} from './fechas';
import { obtenerOrdenesSemana, obtenerKilosOrdenadosDia, obtenerSobranteSemanaPasada } from './Ordenes';
import { obtenerPresentaciones } from './Presentaciones';
import { horaCorte } from '../constants'
import { obtenerSobrantesPorSemana } from "./Queries/QueriesCumplimiento";
import { obtenerCiclo } from "./Ciclos";
import { obtenerRegistrosCompraExternaSemana, obtenerRegistrosCompraExternaSemanaPorInvernadero } from "./Queries/CompraExterna";
import { getProducto } from "./ProductosYVariedades";
import { dosDecimales, getObjetoPorID } from "../util/functions";
import { getEsInvernaderoExternoPorID } from "./Invernaderos";

export const obtenerResumenCumplimiento = async (ciclo, semana, invernaderosVirtuales = [], presentaciones = [], clientes = [], ordenesDeSemana) => {
    try {
        const results = [], dias = obtenerDiasDeSemana(semana);
        //const presentacionesClientes = presentaciones.filter(pres => clientes.some(cliente => cliente.id === pres.cliente_ref));
        const ordenes = ordenesDeSemana || await obtenerOrdenesSemana(ciclo, semana);
        invernaderosVirtuales.forEach(invernadero => {

            const invernaderosDelTab = invernaderosVirtuales.filter(inv => inv.producto_ref === invernadero.producto_ref || (inv.mezcla && invernadero.mezcla))
            clientes.forEach(cliente => {
                presentaciones.forEach(presentacion => {
                    dias.forEach(dia => {
                        const data = obtenerCumplimientoDia(invernadero, cliente, presentacion, ordenes, dia, invernaderosVirtuales, invernaderosDelTab.length);
                        //if(data.actualizado){console.log("data.actualizado : ",data.actualizado)}
                        results.push(data);
                    })

                })
            })
        })
        // console.log("CUMPLIMIENTO RESULT>>>>", results)
        let result = await obtenerProyectadoYFinalParaCumplimiento(ciclo, invernaderosVirtuales, results, semana);
        //console.log("obtenerProyectadoYFinalParaCumplimiento : ", result)
        return result;

    } catch (err) {
        throw err;
    }
}

export const obtenerResumenCumplimientoDePresentacion = (presentacion, invernaderosVirtuales = [], ordenesDeSemana) => {
    try {
        const datosOrdenesDeVenta = [];
        ordenesDeSemana.forEach(orden => {
            let suma_cajas_total = 0, suma_distribuidos = 0, invernaderos = [];
            const invernaderosDeItems = orden.items.map(item => {
                if (item.es_externo) {
                    return { ...getObjetoPorID(invernaderosVirtuales, item.invernadero_ref), empaque_ref: item.empaque_ref }
                }
                else return getObjetoPorID(invernaderosVirtuales, item.invernadero_ref)
            });
            //const invernaderosDelOrden = [...new Set(invernaderosDeItems)];//Se puede utilizar esto en ves de Array.from(). Mejor asi en ves de hacer .add() cada loop?
            const invernaderosDelOrden = invernaderosDeItems
            //console.log("invernaderosDelOrden OOOO ::: ", invernaderosDelOrden)

            orden.items.forEach(item => {
                let suma_cajas = 0, empaque_ref = "", itemID = "";
                //console.log(`inv emp: ${invernadero.empaque_ref}; item emp: ${item.empaque_ref}`)
                //const checarExterno = (!invernadero.es_externo && item.empaque_ref === invernadero.empaque_ref) || invernadero.es_externo;

                if (item.presentacion_ref === presentacion.id && !isNaN(item.cajas_orden)) {
                    const cajasDistribuidos = item.distribuidos ? parseFloat(item.distribuidos) : 0;
                    suma_cajas = dosDecimales(item.cajas_orden);
                    suma_cajas_total += dosDecimales(item.cajas_orden);
                    suma_distribuidos += cajasDistribuidos;
                    empaque_ref = item.empaque_ref;
                    itemID = item.itemID;
                }
                if (suma_cajas > 0) {
                    const invernaderoFound = getObjetoPorID(invernaderosVirtuales, item.invernadero_ref)
                    invernaderos.push({ ...invernaderoFound, itemID, presentacion, suma_cajas, empaque_ref });
                }
            })
            //console.log("suma_distribuidos ... ", suma_distribuidos)
            if (suma_cajas_total > 0) { datosOrdenesDeVenta.push({ ...orden, peso_neto_caja: presentacion.peso_neto_caja, invernaderos, suma_cajas_total, suma_distribuidos }); }
        })
        //console.log("obtenerResumenCumplimientoDePresentacion PRES>>>>", datosOrdenesDeVenta[0].datosCumplimiento)
        return datosOrdenesDeVenta;
    } catch (error) {
        console.log("ERROR obtenerResumenCumplimientoDePresentacion: ", error);
    }
}

const obtenerCumplimientoDia = (invernadero = {}, cliente, presentacion, ordenes, dia, invernaderosVirtuales, numeroInvernaderosDelTab = 1, presentaciones = []) => {
    const ordenesDia = ordenes.filter(el => parseInt(el.fecha_envio) === dia);
    let suma = 0, suma_cajas = 0, distribuido = false, suma_distribuidos = 0;
    let actualizado = false;

    ordenesDia.forEach(orden => {
        orden.items.forEach(item => {

            const esExterno = getEsInvernaderoExternoPorID(invernaderosVirtuales, item.invernadero_ref);
            item.es_externo = esExterno || false;

            const mismoCliente = orden.cliente_ref == cliente.cliente_ref;
            const mismoPresentacion = item.presentacion_ref == presentacion.id;
            const mismoInvernadero = item.invernadero_ref == invernadero.id;
            const presentacionDelItem = item.presentacion || {};
            const invernaderoDelItem = getObjetoPorID(invernaderosVirtuales, item.invernadero_ref);

            const invernaderoDelItemEsMedleys = invernaderoDelItem.mezcla;
            const mostrandoDatosTablaMedleys = invernadero.mezcla, mostrandoDatosTablaUnico = !invernadero.mezcla;
            const presentacionEsUnico = !presentacionDelItem.mezcla;

            const mostrandoDatosUnicoEnTablaMedleys = presentacionEsUnico && mostrandoDatosTablaMedleys;
            const mostrandoDatosUnicoDeInvMedleysEnTablaUnico = presentacionEsUnico && invernaderoDelItemEsMedleys && mostrandoDatosTablaUnico;
            const productoPresEsMismoProductoTabla = presentacionDelItem.producto_ref === invernadero.producto_ref

            //Mismo invernadero, presentacion y cliente
            if (mismoInvernadero && mismoPresentacion && mismoCliente) {

                const cajasOrden = parseFloat(item.cajas_orden) * (mostrandoDatosUnicoEnTablaMedleys ? -1 : 1);
                const pesoNetoCaja = parseFloat(item.presentacion.peso_neto_caja);
                const valor = cajasOrden * pesoNetoCaja;

                if (!isNaN(valor)) { suma += valor; }
                if (!isNaN(cajasOrden)) { suma_cajas += cajasOrden; }
            }
            //Estas en tabla unico, presentacion es unico, mismo presentacion,  mismo cliente, y mismo producto de pres. unico que la tabla
            else if (!mismoInvernadero && mismoPresentacion && mismoCliente && mostrandoDatosUnicoDeInvMedleysEnTablaUnico  && productoPresEsMismoProductoTabla) {

                const cajasOrden = parseFloat(item.cajas_orden)/numeroInvernaderosDelTab;//Para no contar las mismas cajas varias vecez
                const pesoNetoCaja = parseFloat(item.presentacion.peso_neto_caja);
                const valor = cajasOrden * pesoNetoCaja;

                if (!isNaN(valor)) { suma += valor; }
                if (!isNaN(cajasOrden)) { suma_cajas += cajasOrden; }
                //if (invernadero.nombre === "LRT_GRAPE") console.log("INFO: ", {cajasOrden,suma_cajas, invernadero_del_item: invernaderoDelItem.nombre, invernadero_a_mostrar_tabla: invernadero.nombre, cajas_orden: item.cajas_orden, pres: presentacionDelItem.presentacion })
            }

            if (item.presentacion_ref == presentacion.id && orden.cliente_ref == cliente.cliente_ref) {
                const distribuidos = parseFloat(item?.distribuidos || 0)
                if (!isNaN(distribuidos)) { suma_distribuidos += distribuidos; }
            }
        })
        orden.distribuido = orden.fecha_distribuido;
        orden.actualizado = orden.fecha_actualizado;
        distribuido = orden.fecha_distribuido;
        actualizado = orden.fecha_actualizado;
        // if (distribuido) console.log("distribuido!! ",orden);
        // if (actualizado) console.log("actualizado!! " + orden.fecha_actualizado);
    })
    //if(actualizado)console.log("suma   ---- ",suma)
    //if (actualizado) console.log("actualizado!! ",suma);
    //console.log("suma_distribuidos ... ", suma_distribuidos)
    return { invernadero, cliente, presentacion, dia, suma, peso_neto_caja: presentacion.peso_neto_caja, suma_cajas, actualizado, distribuido, suma_distribuidos };
}
const obtenerProyectadoYFinalParaCumplimiento = async (ciclo, invernaderos, resumen, semana) => {
    const proyectado = await obtenerProyectado(ciclo, invernaderos, semana, resumen);
    const incumplidos = await obtenerIncumplidos(ciclo, invernaderos, semana, proyectado);
    const final = obtenerFinal(resumen, proyectado, semana, invernaderos, incumplidos);
    console.log("1111obtenerProyectadoYFinalParaCumplimiento - ", { proyectado, final, resumen, incumplidos })
    return { proyectado, final, resumen, incumplidos };
}
export const encontrarIncumplidosInvernadero = (ciclo, invernadero, semana, proyectado) => {

    return new Promise((resolve, reject) => {
        let dias = obtenerDiasDeSemana(semana);


        let incumplidos = [[], [], [], [], [], []];
        oos(ciclo, semana).then((ordenes) => {

            let np = proyectado.map(el => el);
            let proyectadoInv = proyectado.find(proy => proy.invernadero.id === invernadero.id);
            let proyeccion = proyectadoInv.proyeccion;
            ordenes = ordenes.sort((a, b) => {
                return parseInt(a.fecha_envio) - parseInt(b.fecha_envio);
            })
            let esCumplida = true;
            let restante = proyectadoInv.sobrante.sobrante;
            dias.forEach((dia, index) => {
                restante += proyeccion[index]["valor"];

                ordenes.forEach(orden => {

                    if (parseInt(orden.fecha_envio) !== dia) {
                        return;
                    }
                    orden.items.forEach(item => {
                        if (item.invernadero_ref == invernadero.id) {
                            if (esCumplida) {
                                restante -= parseFloat(item.cajas_orden) * parseFloat(item.presentacion.peso_neto_caja);

                                if (restante < 0) {


                                    esCumplida = false;
                                    incumplidos[index].push(item.presentacion_ref);
                                }
                            }
                            else {
                                incumplidos[index].push(item.presentacion_ref);
                            }
                        }
                    })
                })
            })
            resolve({ invernadero, incumplidos })
        }).catch((err) => {
            reject(err);
        });
    })
}

const obtenerProyectado = (ciclo, invernaderos, semana) => {
    return new Promise(async (resolve, reject) => {
        let promises = [];

        getGlobal().invernaderosVirtuales.forEach(invernadero => {
            promises.push(obtenerProyectadoInvernadero(ciclo, invernadero, semana));
        })
        Promise.all(promises).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    })

}

const obtenerIncumplidos = (ciclo, invernaderos, semana, proyectado) => {
    return new Promise((resolve, reject) => {
        let promises = [];
        invernaderos.forEach(invernadero => {

            promises.push(encontrarIncumplidosInvernadero(ciclo, invernadero, semana, proyectado));
        })
        Promise.all(promises).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    })

}

const obtenerFinal = (resumen, proyectado, semana, invernaderos) => {
    let results = [];
    let dias = obtenerDiasDeSemana(semana);
    invernaderos.forEach(invernadero => {
        let proyectadoInv = proyectado.find(el => el.invernadero.id === invernadero.id || (el.invernadero.MEDLEYS_VIEJO && invernadero.MEDLEYS_VIEJO));
        let proyeccion = proyectadoInv.proyeccion;
        let sobranteAnterior = proyectadoInv.sobrante.sobrante;
        let totales = []
        dias.forEach((dia, index) => {
            let ordenados = obtenerKilosOrdenadosDia(resumen, invernadero, dia);
            let proyectadoDia = proyeccion[index];
            let total = proyectadoDia["valor"] - ordenados + sobranteAnterior;

            sobranteAnterior = total;
            totales.push(total);
        })
        results.push({ final: totales, invernadero: invernadero })
    })
    return (results);
}

const obtenerProyectadoInvernadero = (ciclo, invernadero, semana) => {

    return new Promise(async (resolve, reject) => {
        try {
            const db = firebase.firestore();
            // obtener lista de semanas
            let dias = obtenerDiasDeSemana(semana);
            let results = [];
            let pesoCaja = await obtenerPesoCajaParaSemana(ciclo, invernadero.id, semana, parseFloat(invernadero.peso_caja_cosecha));
            let registros = []
            if (invernadero?.es_externo) {
                let registrosFlat = await obtenerRegistrosCompraExternaSemanaPorInvernadero(ciclo, invernadero.id, semana)
                registros = dias.map(dia => registrosFlat.filter(el => el.dia === dia))
                console.log("ES EXTER<:",{registrosFlat, registros, dias})
            } else {

                registros = await obtenerRegistrosSemana(ciclo, invernadero.id, semana);
            }
            let reales = await obtenerValoresRealesParaCumplimiento(ciclo, invernadero.id, semana)
            let kgm2semanal = await obtenerkgm2semanal(ciclo, invernadero.id, semana - 7 * 24 * 60 * 60);
            let packout = await obtenerPackoutSemanal(ciclo, invernadero, semana - 7 * 24 * 60 * 60, kgm2semanal);
            if (isNaN(packout)) {
                packout = 1;
            }
            let producto = await getProducto(invernadero.producto_ref);
            packout = (producto.mezcla || producto.MEDLEYS_GENERAL) ? getGlobal().medleysGeneral?.packout_respaldo : producto.packout_respaldo;
            let sobrante = await obtenerSobranteSemanaPasada(ciclo, invernadero.id, semana);
            dias.forEach(dia => {
                if (invernadero?.es_externo) {
                    results.push(obtenerProyectadoExternoDia(registros, reales, dia, pesoCaja, packout))
                } else {

                    results.push(obtenerProyectadoDia(registros, reales, dia, pesoCaja, packout))
                }
            })

            resolve({ proyeccion: results, invernadero: invernadero, sobrante: sobrante });
        } catch (err) {
            reject(err);
        }
    })
}

const obtenerProyectadoDia = (registros, reales, dia, pesoCaja, packoutSemanaPasada) => {
    let indice = obtenerIndiceDiaSemana(dia);
    let hoy = obtenerInicioDia(Math.round(Date.now() / 1000));
    let hora = obtenerHora(hoy);
    let registro = registros.find(el => el && el.dia === dia);
    if (dia === hoy) {
        if (hora < horaCorte) {
            if (registro && !isNaN(parseFloat(registro["diaria"]))) {
                return { esReal: false, valor: parseFloat(registro["diaria"]) * pesoCaja, tipo: "diario" };
            } else {
                return { esReal: false, valor: 0, tipo: "diario" };
            }
        } else {
            return { esReal: true, valor: packoutSemanaPasada * reales[indice], packout: packoutSemanaPasada, real: reales[indice], tipo: "real" };
        }
    } else if (dia > hoy) {
        if (registro) {
            let diario = parseFloat(registro["diaria"]);

            if (!isNaN(diario)) {
                return { esReal: false, valor: diario * pesoCaja, tipo: "diario" };
            } else if (!isNaN(parseFloat(registro["semanal"]))) {
                return { esReal: false, valor: parseFloat(registro["semanal"]) * pesoCaja, tipo: "semanal" };
            } else {
                return { esReal: false, valor: 0, tipo: "semanal" };
            }
        } else {
            return { esReal: false, valor: 0, tipo: "semanal" };
        }
    } else {
        return { esReal: true, valor: packoutSemanaPasada * reales[indice], packout: packoutSemanaPasada, real: reales[indice], tipo: "real" };;
    }
}
const obtenerProyectadoDiaSemanal = (registros, reales, dia, pesoCaja, packoutSemanaPasada) => {console.log("Cumpli cards registros ",registros)
    let indice = obtenerIndiceDiaSemana(dia);
    let hoy = obtenerInicioDia(Math.round(Date.now() / 1000));
    let hora = obtenerHora(hoy);
    let registro = registros.find(el => el && el.dia === dia);
    console.log("Cumpli norm semanales ", registros)
    if (!registro?.semanal || isNaN(parseFloat(registro?.["semanal"] || 0))) { return { esReal: false, valor: 0, tipo: "semanal" }; }
    else if(registro?.semanal && !isNaN(parseFloat(registro?.["semanal"] || 0)))return { esReal: false, valor: parseFloat(registro?.["semanal"] || 0) * pesoCaja, tipo: "semanal" };
    else return { esReal: false, valor: 0, tipo: "semanal" };
    
}
const obtenerProyectadoExternoDia = (registros, reales, dia, pesoCaja, packoutSemanaPasada) => {
    let indice = obtenerIndiceDiaSemana(dia);
    let hoy = obtenerInicioDia(Math.round(Date.now() / 1000));
    let hora = obtenerHora(hoy);

    let registrosDia = registros[obtenerIndiceDiaSemana(dia)];
    let result = { valor: 0, tipo: "diario" }
    if (hora < horaCorte) {
        result["esReal"] = false
    } else {
        result["esReal"] = true

    }






    //si el dia ya paso se toma el real, no es necesario iterar en registros diarios de cosecha
    if ((dia === hoy && hora > horaCorte) || hoy > dia) {
        return { esReal: true, valor: packoutSemanaPasada * reales[indice], packout: packoutSemanaPasada, real: reales[indice], tipo: "real" };
    }
    registrosDia.forEach(registro => {



        if (dia === hoy) {
            if (hora < horaCorte) {
                if (registro && !isNaN(parseFloat(registro["datoHTG"]))) {
                    result["valor"] += parseFloat(registro["datoHTG"]);
                    return { esReal: false, valor: parseFloat(registro["diaria"]), tipo: "diario" };
                } else {
                    return { esReal: false, valor: 0, tipo: "diario" };
                }
            } else {
                result["valor"] += (packoutSemanaPasada * reales[indice]);
                return { esReal: true, valor: packoutSemanaPasada * reales[indice], packout: packoutSemanaPasada, real: reales[indice], tipo: "real" };
            }
        } else if (dia > hoy) {
            if (registro) {
                let diario = parseFloat(registro["datoHTG"]);
                if (!isNaN(diario)) {
                    result["valor"] += diario;
                    return { esReal: false, valor: diario, tipo: "diario" };
                } else if (!isNaN(parseFloat(registro["semanal"]))) {
                    result["valor"] += parseFloat(registro["semanal"]);
                    return { esReal: false, valor: parseFloat(registro["semanal"]), tipo: "semanal" };
                } else {
                    return { esReal: false, valor: 0, tipo: "semanal" };
                }
            } else {
                return { esReal: false, valor: 0, tipo: "semanal" };
            }
        } else {
            result["valor"] += packoutSemanaPasada * reales[indice];
            return { esReal: true, valor: packoutSemanaPasada * reales[indice], packout: packoutSemanaPasada, real: reales[indice], tipo: "real" };;
        }

    })
    return result;
}

export const obtenerMatrizComparativa = async (cicloId, invernaderos, semana, ordenesSemana) => {
    let ordenes = ordenesSemana || await obtenerOrdenesSemana(cicloId, semana);
    let presentaciones = await obtenerPresentaciones();
    let reportesCosecha = await obtenerReporteCosecha(cicloId, semana);
    let result = [], sumas = {};

    invernaderos.forEach(invernadero => {
        sumas[invernadero.id] = 0;
    })
    ordenes.forEach(orden => {
        orden.items.forEach(item => {
            let presentacion = presentaciones.find(el => el.id === item.presentacion_ref);
            sumas[item.invernadero_ref] += parseFloat(item.cajas_orden) * parseFloat(presentacion.peso_neto_caja);

        })
    })

    invernaderos.forEach(invernadero => {
        let row = { ...invernadero, invernadero };
        let reporteCosecha = reportesCosecha.find(reporte => reporte[0]["id"] === invernadero.id);
        let kilosRealRow = reporteCosecha[3]["real"];

        invernaderos.forEach(colInvernadero => {
            let reporteCosechaCol = reportesCosecha.find(reporte => reporte[0]["id"] === colInvernadero.id);
            let kilosRealCol = reporteCosechaCol[3]["real"];

            row[colInvernadero["id"]] = {
                renglonReal: kilosRealRow,
                renglonFinal: sumas[invernadero.id],
                columnaFinal: sumas[colInvernadero.id],
                columnaReal: kilosRealCol
            }
        })
        result.push(row);
    })
    return result;
}
export const obtenerSobrantePorSemanaCiclo = async (cicloId, invernaderos) => {


    let sobrantes = await obtenerSobrantesPorSemana(cicloId);
    let cicloObj = await obtenerCiclo(cicloId);
    let semanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
    let result = invernaderos.map(invernadero => {
        let sobrantesInvernadero = obtenerSobrantePorSemanaInvernadero(invernadero.id, sobrantes, semanas);
        return {
            idInvernadero: invernadero.id,
            nombre: invernadero.nombre,
            sobrantes: sobrantesInvernadero,
            color: invernadero.color
        }
    })
    return result;
}
const obtenerSobrantePorSemanaInvernadero = (invernaderoId, sobrantes, semanas) => {
    let result = semanas.map(semana => {
        let obj = { semana: semana.time, sobrante: 0 }
        let sobrante = sobrantes.find(el => el.invernadero_ref === invernaderoId && el.semana === semana.time);
        if (sobrante) {
            obj["sobrante"] = sobrante["sobrante"]
        }
        return obj
    })
    return result;
}
export const convertirCajasPresentacionAPallets = (cajas = 0, presentacion = {}) => {
    const numCajasPorPallet = presentacion.numero_cajas_pallet;
    if (!numCajasPorPallet || isNaN(numCajasPorPallet)) { return 0; }

    return dosDecimales(cajas / numCajasPorPallet);
}
//454
