import React from "react";
import CardOrden from "./CardOrden";
const ColumnaDia = (props) => {
    return (
        <div className="p-col-12 p-md-2 p-sm-4">
            <div className="week-day">
                {props.infoDia.nombre}
            </div>
            {props.infoDia?.ordenes?.map((orden) => <CardOrden {...props} key={orden.no_orden} orden={orden} />)}
        </div>);
}
export default ColumnaDia;