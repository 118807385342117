import React, { useState, useEffect } from "react";
import { useGlobal } from 'reactn';
import Chart from "react-apexcharts";
import { obtenerPresentaciones } from "../../service/Presentaciones";
import { obtenerOrdenesCiclo } from '../../service/Ordenes';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { obtenerClientes, obtenerClientesCiclo } from "../../service/Clientes";
import { dosDecimales, getObjetoPorID, paletaColores, separadoComas } from "../../util/functions";
import { obtenerDiasDeSemana, obtenerListaDeSemanas } from "../../service/fechas";
import { paletaClientes, paletaProductos } from "../../constants";
import { hayMedleysViejo } from "../../service/ProductosYVariedades";
const optionsOrig = {

  colors: paletaColores,
  plotOptions: {
    pie: {
      dataLabels: {
        minAngleToShowLabel: 0,
      }
    },
  },
  chart: {
    width: 190,
    type: 'pie',
  },
  labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
  tooltip: {
    y: {

      formatter: function (val) {
        return `${separadoComas(dosDecimales(val))} kilos`
      },
      title: {
        formatter: function (seriesName) {
          return seriesName
        }
      }
    }
  },
  responsive: [{
    breakpoint: 480,
    options: {
      chart: {
        width: 100
      },
      legend: {
        position: 'bottom'
      }
    }
  }],

}
const series = [13, 12, 50]
const DistribucionPresentacionesCiclo = (props) => {
  const [invernaderos, setInvernaderos] = useState([]);
  const [productosConVariedades, setProductosConVariedades] = useState([]);
  const [variedadesOpciones, setVariedadesOpciones] = useState([]);
  const [filtroProducto, setFiltroProducto] = useState([]);
  const [filtroVariedad, setFiltroVariedad] = useState([]);
  const [filtroInvernadero, setFiltroInvernadero] = useState([]);
  const [presentaciones, setPresentaciones] = useState([]);
  const [ordenes, setOrdenes] = useState([]);
  const [, setOrdenesOrig] = useState([]);
  const [clientesOrig, setClientesOrig] = useState([]);
  const [filtroCliente, setFiltroCliente] = useState([]);
  const [data, setData] = useState([]);
  const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
  const [productosYVariedades] = useGlobal("productosYVariedades")
  const [productosBases] = useGlobal("productosBases");
  const [productosMezclados] = useGlobal("productosMezclados");
  const [allVariedades] = useGlobal("allVariedades");
  const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
  const [, setNombreModulo] = useGlobal("nombreModulo");
  const [showFiltroMultiClientes, setShowFiltroMultiClientes] = useState(false)
  const [showFiltroMultiProductos, setShowFiltroMultiProductos] = useState(false)
  const [optionsGrafica, setOptionsGrafica] = useState({})

  const [ciclo,] = useGlobal("ciclo");
  const [cicloObj,] = useGlobal("cicloObj");
  const [, setCargando] = useGlobal("cargando");
  const [hayDatosAMostrar, setHayDatosAMostrar] = useState(false)

  useEffect(() => {
    setHabilitadoSelectorCiclo(true)
    setNombreModulo("Homepage Gráficas")
  }, [])

  useEffect(() => {
    if (ciclo) {
      obtenerDatosIniciales();
    }
  }, [ciclo])
  useEffect(() => {//Procesar 1 producto con varios clientes
    if (filtroProducto?.length > 0) { setFiltroInvernadero(obtenerOpcionesInvernaderos()); }
    else setFiltroInvernadero([]);
  }, [filtroProducto])

  useEffect(() => {
    if (productosYVariedades) {
      const varsOpciones = obtenerOpcionesVariedades();
      setVariedadesOpciones(varsOpciones);
      setFiltroVariedad(varsOpciones);
    }
  }, [filtroInvernadero])

  useEffect(() => {
    if (filtroVariedad.length > 0 && filtroCliente.length > 0 && filtroProducto && filtroVariedad.length > 0) {
      let procesado = null
      if (filtroProducto?.length > 1 && filtroCliente?.length <= 1) {
        procesado = procesarDatoPorCliente();//Procesar 1 cliente con varios productos
        console.log("1 LENG: ", procesado)
        setData(procesado);
      }
      else {
        procesado = procesarDatoPorProducto();//Procesar 1 producto con varios clientes
        console.log("2 LENG: ", procesado)
        setData(procesado);
      }
      const series = getSeries(procesado || []);
      setHayDatosAMostrar(series?.length > 0);
    }
    else { setData([]); setHayDatosAMostrar(false); }
  }, [filtroCliente, filtroProducto, filtroInvernadero, filtroVariedad])
  //console.log({data})
  const obtenerDatosIniciales = async (params) => {
    try {
      setCargando(true);
      let productosConVariedades = productosYVariedades;
      let presentacionesPromise = obtenerPresentaciones();
      let clientesPromise = obtenerClientes();

      let ordenesPromise = obtenerOrdenesCiclo(ciclo);
      let clientesCicloPromise = obtenerClientesCiclo(ciclo);

      let [presentaciones, clientes, ordenes, clientesCiclo] =
        await Promise.all([presentacionesPromise, clientesPromise, ordenesPromise, clientesCicloPromise]);

      let idsClientesCiclo = clientesCiclo.map(el => el.cliente_ref);
      clientes = clientes.filter(el => idsClientesCiclo.includes(el.id))
      setInvernaderos(invernaderosVirtuales);
      setFiltroInvernadero([]);
      setProductosConVariedades(productosConVariedades);
      setClientesOrig(clientes);
      setFiltroVariedad([]);
      setPresentaciones(presentaciones);
      setOrdenesOrig(ordenes);
      setOrdenes(ordenes);
    } catch (error) {
      console.log("OCURRIO UN ERROR EN CARGA DE DATOS INICIALES");
      console.log(error);
    } finally {
      setCargando(false)
    }
  }
  useEffect(() => {
    const newOptions = obtenerOptions()
    let tituloDeLaGrafica = ""
    if (filtroProducto.length > 1 && filtroCliente.length <= 1) {
      tituloDeLaGrafica = "Distribución de productos"
    }
    if (filtroProducto.length <= 1 && filtroCliente.length > 1) {
      tituloDeLaGrafica = "Distribución de clientes"
    }
    newOptions.key = `${Date.now()}-dis-ciclo`
    setOptionsGrafica({ ...newOptions, title: { text: tituloDeLaGrafica, align: "left" } })
  }, [data])

  useEffect(() => {
    if (filtroProducto.length > 1) {
      setShowFiltroMultiClientes(false)
    }
    if (filtroProducto.length <= 1) {
      setShowFiltroMultiClientes(true)
    }
  }, [filtroProducto])

  useEffect(() => {
    if (filtroCliente.length > 1) {
      setShowFiltroMultiProductos(false)
    }
    if (filtroCliente.length <= 1) {
      setShowFiltroMultiProductos(true)
    }
  }, [filtroCliente])

  const obtenerOpcionesInvernaderos = () => {
    let invsFiltradosPorCliYProd = [];
    filtroProducto.forEach((prod) => {
      const invs = invernaderos?.filter(inv => prod.id === inv.producto_ref || (prod.MEDLEYS_GENERAL && inv.mezcla)) || [];
      if (invs.length > 0) invsFiltradosPorCliYProd = [...invs, ...invsFiltradosPorCliYProd]
    })
    return invsFiltradosPorCliYProd.length > 0 ? invsFiltradosPorCliYProd : invernaderos
  }

  const obtenerOpcionesVariedades = () => {
    if (filtroCliente?.length === 0 || filtroInvernadero?.length === 0 || filtroProducto.length === 0) return []
    if (filtroProducto.MEDLEYS_GENERAL && !hayMedleysViejo(filtroInvernadero)) return productosMezclados || []
    const variedades = filtroProducto.map(prod => prod?.variedades)
    return variedades.flat()
  }
  const procesarDatoPorCliente = (params) => {
    //console.log("Run the product process")
    // obtener ids de productos, invernaderos y variedades, en cascada
    let clientesIds = filtroCliente.map(el => el.id);
    let invernaderos = filtroInvernadero.slice();
    let invernaderosIds = invernaderos.map(el => el.id);

    let variedades = filtroVariedad.slice();
    //variedades.push(...productosMezclados)
    let variedadesIds = variedades.map(el => el.id);

    let listaSemanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
    /* let filtradas = presentaciones.filter(el => el.producto_ref === filtroProducto.id); */
    let filtradas = []
    filtroProducto.forEach((prod) => {
      const presFiltradas = presentaciones.filter(pres => prod.id === pres.producto_ref)
      if (presFiltradas.length > 0) filtradas = [...presFiltradas, ...filtradas]
    })
    filtradas = filtradas.filter(el => clientesIds.includes(el.cliente_ref));

    let result = filtroProducto.map(producto => {
      let sumaProducto = 0;
      let data = listaSemanas.map(semana => {
        let dias = obtenerDiasDeSemana(semana.time);
        let suma = 0;
        ordenes.forEach(orden => {
          const itemsToProcess = orden?.items?.filter(item => {
            return item.producto_ref === producto.id || (getObjetoPorID(productosMezclados, item.producto_ref)?.id && producto.MEDLEYS_GENERAL)
          }) || [];
          itemsToProcess.forEach(item => {
            if (parseInt(orden.fecha_envio) >= dias[0] && parseInt(orden.fecha_envio) <= dias[5]) {
              let presentacion = filtradas.find(el => el.id === item.presentacion_ref);
              if (presentacion) {
                let invernadero = invernaderos?.find(el => el.id === item.invernadero_ref) || {};
                //if(invernadero?.mezcla)console.log("KNOW::::", { item, invernadero, variedades,invernaderos,variedadesIds, producto, filtroProducto, presentacion });
                if (invernaderosIds.includes(invernadero.id)
                  || (variedadesIds.includes(invernadero.variedad_ref) || variedadesIds.includes(invernadero.producto_ref)
                    || (invernadero.mezcla && producto.MEDLEYS_GENERAL))) {

                  let peso_caja = parseFloat(presentacion.peso_neto_caja);
                  let cajas_orden = parseFloat(item.cajas_orden)
                  suma += peso_caja * cajas_orden;
                  sumaProducto += peso_caja * cajas_orden;
                }
              }
            }
          })

        })
        return suma;
      })
      return {
        name: producto.nombre,
        shortname: producto.nombre,
        data: data,
        sumaPresentacion: sumaProducto
      }
    })
    console.log("RESULT 1 CLIENTE + n PRODUCTOS: ", { variedades, invernaderos, filtroProducto, result });
    return result
  }
  const procesarDatoPorProducto = (params) => {
    //console.log("Run the client process")
    // obtener ids de productos, invernaderos y variedades, en cascada
    let clientesIds = filtroCliente.map(el => el.id);
    let invernaderos = filtroInvernadero.slice();
    let invernaderosIds = invernaderos.map(el => el.id);

    let variedades = filtroVariedad.slice();
    //variedades.push(...productosMezclados)
    let variedadesIds = variedades.map(el => el.id);

    let listaSemanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
    /* let filtradas = presentaciones.filter(el => el.producto_ref === filtroProducto.id); */
    let filtradas = []
    filtroProducto.forEach((prod) => {
      const presFiltradas = presentaciones.filter(pres => prod.id === pres.producto_ref)
      if (presFiltradas.length > 0) filtradas = [...presFiltradas, ...filtradas]
    })
    filtradas = filtradas.filter(el => clientesIds.includes(el.cliente_ref));

    let result = filtroCliente.map(cliente => {
      let sumaCliente = 0;
      let data = listaSemanas.map(semana => {
        let dias = obtenerDiasDeSemana(semana.time);
        let suma = 0;
        ordenes.forEach(orden => {
          if (orden.cliente_ref === cliente.id) {
            orden.items.forEach(item => {

              if (parseInt(orden.fecha_envio) >= dias[0] && parseInt(orden.fecha_envio) <= dias[5]) {
                let presentacion = filtradas.find(el => el.id === item.presentacion_ref);
                if (presentacion) {
                  let invernadero = invernaderos?.find(el => el.id === item.invernadero_ref) || {};
                  if ((invernaderosIds.includes(invernadero.id) && (variedadesIds.includes(invernadero.variedad_ref))
                    || (invernadero.mezcla && filtroProducto?.some(el => el.MEDLEYS_GENERAL))
                    || variedadesIds.includes(invernadero.producto_ref))) {
                    let peso_caja = parseFloat(presentacion.peso_neto_caja);
                    let cajas_orden = parseFloat(item.cajas_orden)
                    suma += peso_caja * cajas_orden;
                    sumaCliente += peso_caja * cajas_orden;
                  }
                }
              }
            })
          }

        })
        return suma;
      })
      return {
        name: cliente.nombre,
        shortname: cliente.nombre_comun,
        data: data,
        sumaPresentacion: sumaCliente
      }
    })
    console.log("RESULT 1 PRODUCTO + n CLIENTES: ", { variedades, invernaderos, filtroProducto, result });
    return result
  }

  function obtenerOptions(params) {
    let clientesIds = filtroCliente.map(el => el.id);
    let invernaderos = filtroInvernadero.slice();
    let invernaderosIds = invernaderos.map(el => el.id);
    let variedades = filtroVariedad.slice();
    let variedadesIds = variedades.map(el => el.id);

    let filtradas = presentaciones.filter(el => el.producto_ref === filtroProducto.id);
    filtradas = filtradas.filter(el => clientesIds.includes(el.cliente_ref));
    let copia = { ...optionsOrig };
    copia["labels"] = [];
    copia["colors"] = [];

    filtradas.forEach(presentacion => {
      let suma = 0;
      let pesoCaja = presentacion.peso_neto_caja;
      ordenes.forEach(orden => {
        orden.items.forEach(currentItem => {
          if (currentItem.presentacion_ref === presentacion.id) {
            let invernadero = invernaderos.find(el => el.id === currentItem.invernadero_ref);
            if (invernadero && invernaderosIds.includes(invernadero.id) && (variedadesIds.includes(invernadero.variedad_ref) || variedadesIds.includes(invernadero.producto_ref))) {
              let pesoItem = parseFloat(currentItem.cajas_orden) * pesoCaja;
              suma += pesoItem;
            }
          }
        })
      });

    });

    if (data) {
      data.forEach(serie => {
        if (serie["sumaPresentacion"] > 0) {
          copia["labels"].push(serie.name);
          if (filtroProducto.length > 1) {
            copia["colors"].push(paletaProductos[serie.name])
          } else {
            copia["colors"].push(paletaClientes[serie.shortname])
          }
        }
      })
    }

    return copia;
  }

  const atras = (params) => {
    props.history.goBack();
  }

  const mostrarVariedadesInput = () => {
    const medleysGeneralSeleccionado = filtroProducto.MEDLEYS_GENERAL;
    if (!medleysGeneralSeleccionado) { return true; }

    if (hayMedleysViejo(filtroInvernadero)) { return true; }
    else { return false; }
  }

  const getSeries = (procesado) => {
    const datosFiltrados = procesado?.filter(el => el.sumaPresentacion > 0) || [];
    const series = datosFiltrados.map(el => el.sumaPresentacion) || [];
    return series;
  }
  return <div className="p-grid chart-page">
    <div className="p-col  p-col-auto title-container">
      <span className="back-btn" onClick={atras} ></span>
      <span className={`icon-general chart-icon `}></span>
      <span className="titles-group">
        <h1 className="header-title">Distribución en kilos de productos y clientes en el ciclo</h1>
      </span>
    </div>

    <div className="p-col-12">
      <div className="p-grid filtros">
        <div className="form-group p-col p-col-3">
          <label htmlFor="filtroCliente">Clientes</label>
          {
            showFiltroMultiClientes ? <MultiSelect
              options={clientesOrig}
              value={filtroCliente}
              onChange={e => setFiltroCliente(e.value)}
              selectedItemsLabel={`${filtroCliente.length} clientes seleccionados`}
              dataKey="id"
              optionLabel="nombre" />
              : <Dropdown
                options={clientesOrig}
                value={filtroCliente[0]}
                onChange={e => setFiltroCliente([e.value])}
                selectedItemsLabel={`${filtroCliente.length} clientes seleccionados`}
                dataKey="id"
                optionLabel="nombre" />
          }

        </div>
        <div className="form-group p-col p-col-3">
          <label htmlFor="filtroProducto">Productos a graficar</label>
          {
            showFiltroMultiProductos ? <MultiSelect
              options={productosBases}
              value={filtroProducto}
              onChange={e => setFiltroProducto(e.value)}
              selectedItemsLabel={`${filtroProducto.length} productos seleccionados`}
              dataKey="id"
              optionLabel="nombre" />
              : <Dropdown
                options={productosBases}
                value={filtroProducto[0]}
                onChange={e => setFiltroProducto([e.value])}
                dataKey="id"
                optionLabel="nombre" />
          }

        </div>
        <div className="form-group p-col p-col-3">
          <label htmlFor="filtroInvernadero">Invernaderos a graficar</label>
          <MultiSelect options={obtenerOpcionesInvernaderos()} value={filtroInvernadero} onChange={e => setFiltroInvernadero(e.value)}
            selectedItemsLabel={`${filtroInvernadero.length} invernaderos seleccionados`} dataKey="id" optionLabel="nombre" />
        </div>
        {mostrarVariedadesInput() &&
          <div className="form-group p-col p-col-3">
            <label htmlFor="filtroVariedad">Variedades a graficar</label>
            <MultiSelect options={variedadesOpciones} value={filtroVariedad} onChange={e => setFiltroVariedad(e.value)}
              selectedItemsLabel={`${filtroVariedad.length} variedades seleccionadas`} dataKey="id" optionLabel="nombre" />
          </div>}
      </div>
    </div>
    {hayDatosAMostrar
      ? <div className="p-col-12 chart-container-filters">
        <Chart options={optionsGrafica} series={getSeries(data)} type="donut" height="100%" />
      </div>
      : <label>No hay datos registrados</label>}
  </div>
}

export default DistribucionPresentacionesCiclo;//440