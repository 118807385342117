import React from 'react';
import { useEffect, useGlobal, useState } from 'reactn';

import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import moment from "moment";
import 'moment/locale/es';
import { dosDecimales } from '../../util/functions';
moment.locale("es");

const CardsInvernaderosFisicos = (props) => {
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [usuario,] = useGlobal("usuario");
    const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
    const [invernaderosFisicos] = useGlobal("invernaderosFisicos");
    const [, setCargando] = useGlobal("cargando")
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");

    const puedeEditar = () =>
        ["Administrador", "Auxiliar Embarques", "Personal Administrativo", "agente aduanal", "cliente", "Finanzas"].includes(usuario.rol)

    useEffect(() => {
        setNombreModulo("Invernaderos Físicos")
        setHabilitadoSelectorCiclo(false)
    }, [])
    useEffect(() => {
        if (invernaderosVirtuales?.length > 0) setCargando(false)
    }, [invernaderosVirtuales])

    const countVirtuales = (invernaderoID) =>
        invernaderosVirtuales?.filter(inv => inv.invernadero_fisico_ref === invernaderoID)?.length || 0;

    const irCrearFisico = () => {
        if (puedeEditar()) { props.history.push("/administrar/invernadero_fisico/crear"); }
    }
    const irEditarFisico = (invernadero) => {
        if (puedeEditar()) { props.history.push({ pathname: `/administrar/invernadero_fisico/modificar/${invernadero.id}` }); }
    }

    const CardHeader = (props) =>
        <span className="card-header htg-card--fisico">
            <div className={`card-id icon-general icon-id bor-${props.invernadero.color} bg-${props.invernadero?.color}`}>
                {props.invernadero?.nombre}
            </div>
            <div>
                <p className="card-up"></p>
                <p className="card-down">{props.invernadero?.nombre}</p>
            </div>
        </span>;

    return (
        <div className="p-grid p-justify-center">
            {!usuario.isRolFinanzas &&
                <div className="p-col p-col-auto">
                    <div className="btn-row">
                        <Button disabled={!puedeEditar()} label="Crear nuevo invernadero fisico" onClick={() => { irCrearFisico() }} />
                    </div>
                </div>}
            <div className="p-col-12">
                <div className="p-grid">
                    {invernaderosFisicos?.map((inv, index) =>
                        <div key={`inv-fis-card-${index}`} className="p-col-12 p-md-4 p-sm-6" onClick={() => irEditarFisico(inv)}>
                            <Card header={<CardHeader invernadero={inv} />} className={`htg-card bor-${inv.color} }`} >
                                <div className="p-grid p-justify-center text-center">
                                    <div className="p-col">
                                        <div className="card-block">
                                            <p className="value">{dosDecimales(inv.area).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                            <p className="label">Area m2</p>
                                        </div>
                                    </div>
                                    <div className="p-col">
                                        <div className="card-block">
                                            <p className="value">{countVirtuales(inv.id)} </p>
                                            <p className="label">Invernaderos virtuales</p>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>)}
                </div>
            </div>
        </div>);
}
export default CardsInvernaderosFisicos;