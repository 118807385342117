import React, { useState, useEffect, useGlobal } from "reactn"
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import ContenedorFiltro from "../../common/ContenedorFiltro";
import SeccionFiltros from "../../common/SeccionFiltros";
import { UnidadBase } from "../../common/templates_unidades/UnidadBase.atomo";
import { DeepClone, getNombrePorID, getObjetoPorID, getProductoYVariedadStringDiagonal, ordenarObjetosPorNombre, sumarNumerosArrayPorCampo } from "../../../util/functions";
import { Button } from "primereact/button";
import { getEsInvernaderoExternoPorID, getEmpaquePorInvernaderoVirtualID } from "../../../service/Invernaderos";
import icono from '../../../images/icons/external-icon.svg';
import { getFirestoreID } from "../../../service/Mantenimiento";
import { convertirCajasPresentacionAPallets } from "../../../service/Cumplimiento";
import { getSumaCajasOriginal } from "../../../service/CentroDeDistribucion";

const CardItemOrigen = (props) => {
    const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
    const [invernaderosFisicos] = useGlobal("invernaderosFisicos");
    const [productosYVariedades] = useGlobal("productosYVariedades");
    const [sitiosEmpaques] = useGlobal("sitiosEmpaqueCicloActual");
    const [invernaderoOrigen, setInvernaderoOrigen] = useState(null);
    const [itemOrigen, setItemOrigen] = useState(props.itemOrigen);
    const [itemsDestino, setItemsDestino] = useState(props.itemsDestino);
    //const [sumaCajasOriginal] = useState(getSumaCajasOriginal(itemOrigen, itemsDestino));//option 1
    const sumaCajasOriginal = sumarNumerosArrayPorCampo([itemOrigen, ...itemsDestino], "cajas_orden");//option 2
    const ERROR_MESSAGE_SUMA = "Las suma de cajas es mas que el distribución original";

    useEffect(() => {//set invernadero origen y suma de cajas originales
        const invernadero = getObjetoPorID(invernaderosVirtuales, props.itemOrigen.invernadero_ref);
        setInvernaderoOrigen(invernadero);
    }, [])

    useEffect(() => {//Actualizar datos de distribucion
        if (invernaderoOrigen) { props.editarDistribucionData(itemOrigen, itemsDestino, checarSiHayErrores()); }//Al actualizar datos al iniciar el componente, pasa tan rapido que sobreescriben los datos
    }, [itemOrigen, itemsDestino])

    const checarSiHayErrores = () => itemsDestino.some((itemDestino) => erroresDelItem(itemDestino));

    const handleChangeOrigenEmpaque = (event) => {
        const itemOrigenCopy = DeepClone(itemOrigen);
        itemOrigenCopy.empaque_ref = event.target.value;
        setItemOrigen(itemOrigenCopy);
    }

    /**
    * Reglas
    * 1. Si es inv. con producto medleys, puedes transferir a un inv. medleys el mismo cliente
    * 2. Si es inv. con producto unico, puedes transferir al inv. con el mismo producto o medleys con ese variedad 
    */
    const filtrarOpcionesInvernaderosDestinos = (itemDestino) => {
        if (!invernaderoOrigen) { return []; }

        let opcionesInvsDestinos = [];
        if (invernaderoOrigen.mezcla) {//si origen es medleys, filtrar destinos invs. medleys
            opcionesInvsDestinos = invernaderosVirtuales.filter(invDestino => invDestino.mezcla /* && invernaderoOrigen.cliente_ref === invDestino.cliente_ref */);
        }
        else {//si origen es unico, filtrar destinos invs. unicos con el mismo producto
            opcionesInvsDestinos = invernaderosVirtuales.filter(invDestino => invDestino.producto_ref === invernaderoOrigen.producto_ref/*  && invDestino.variedad_ref === invernaderoOrigen.variedad_ref */);
        }
        ordenarObjetosPorNombre(opcionesInvsDestinos);
        const opcionesFiltrados = removerOpcionesRepetidos(opcionesInvsDestinos, itemDestino);
        return opcionesFiltrados;
    }
    const removerOpcionesRepetidos = (opcionesInvsDestinos, itemDestino) => {
        const filtrados = opcionesInvsDestinos.filter(invernaderoDestino => {
            const noEsOrigen = invernaderoDestino.id !== invernaderoOrigen.id || (invernaderoOrigen.es_externo && invernaderoDestino.es_externo)

            const numeroSeleccionadosEsteDestino = itemsDestino.filter(destino => destino.invernadero_ref === invernaderoDestino.id).length
            return noEsOrigen && (numeroSeleccionadosEsteDestino === 0 || itemDestino.invernadero_ref === invernaderoDestino.id || invernaderoDestino.es_externo);
        });
        return filtrados;
    }
    const filtrarOpcionesEmpaque = (itemDestino, destinoEsExterno) => {
        if (!destinoEsExterno) { return sitiosEmpaques; }
        const empaquesFiltrados = sitiosEmpaques.filter(opcionSitio => {
            //const noEsOrigen = opcionSitio.id !== itemDestino.empaque_ref
            const numeroSeleccionadosEsteDestino = itemsDestino.filter(destino => destino.empaque_ref === opcionSitio.id).length
            return (numeroSeleccionadosEsteDestino === 0 || itemDestino.empaque_ref === opcionSitio.id);
        });
        return empaquesFiltrados;
    }

    const erroresDelItem = (itemDestino) => {
        let camposVacios = [], errorMessage = "";
        if (!itemDestino.invernadero_ref) { camposVacios.push("invernadero virtual"); }
        if (!itemDestino.cajas_orden) { camposVacios.push("cajas totales"); }
        if (!itemDestino.empaque_ref) { camposVacios.push("sitio de empaque"); }

        if (camposVacios.length !== 0) {
            const listaCamposVacios = camposVacios.join(", ");
            errorMessage = camposVacios.length > 1
                ? `Tienes que llenar los campos de ${listaCamposVacios}`
                : `Hay que llenar el campo de ${camposVacios[0]}`;
        }
        else if (parseInt(itemOrigen.cajas_orden) < 0) { errorMessage = ERROR_MESSAGE_SUMA; }
        return errorMessage;
    }

    const handleChangeItemDestino = (event) => {
        const { name, value, id: index } = event.target;
        const copiaDestinos = DeepClone(itemsDestino);
        copiaDestinos[index][name] = value;

        if (name === "cajas_orden") {
            actualizarCajasOrigen(copiaDestinos)
            copiaDestinos[index].pallets_orden = convertirCajasPresentacionAPallets(copiaDestinos[index].cajas_orden, props.presentacion);
        }

        //Manejar asignacion de sitios de empaque automaticamente + nombre invernadero
        if (name === "invernadero_ref") {
            copiaDestinos[index].empaque_ref = getEmpaquePorInvernaderoVirtualID(invernaderosFisicos, invernaderosVirtuales, value);
            copiaDestinos[index].nombre_invernadero = getNombrePorID(invernaderosVirtuales, value)
        }
        setItemsDestino(copiaDestinos)
    }
    const actualizarCajasOrigen = (destinos) => {
        const sumaCajasDestinos = sumarNumerosArrayPorCampo(destinos, "cajas_orden");
        const cajas_orden = `${sumaCajasOriginal - sumaCajasDestinos}`;
        const pallets_orden = convertirCajasPresentacionAPallets(cajas_orden, props.presentacion);

        setItemOrigen({ ...itemOrigen, cajas_orden, pallets_orden });
    }
    const agregarItem = () => {
        const { invernadero_ref, nombre_invernadero, empaque_ref, itemID, ...origenDatos } = DeepClone(itemOrigen);
        const nuevoDestino = { ...origenDatos, itemID: getFirestoreID(), origen: itemID, cajas_orden: 0, pallets_orden: 0, fecha_distribuido: Date.now() };
        setItemsDestino([...itemsDestino, nuevoDestino])
    }
    const eliminarItem = (index) => {
        const copiaDestinos = DeepClone(itemsDestino);
        copiaDestinos.splice(index, 1);
        actualizarCajasOrigen(copiaDestinos);
        setItemsDestino(copiaDestinos);
    }

    const invernaderoItemBody = (rowData) => {
        return <label >{rowData?.nombre} <label className="producto-text-small">({getProductoYVariedadStringDiagonal(rowData)})</label></label>
    }
    const getDeshabilitadoBotonAgregar = () => {
        if (itemsDestino.length === 0) { return false; }
        const lastItem = itemsDestino[itemsDestino.length - 1];
        const opcionesDeDestino = filtrarOpcionesInvernaderosDestinos(lastItem);
        return opcionesDeDestino.length <= 0// 1;
    }

    return (
        <div className="card-mtto ">
            <div className="card-mtto-header">
                <div className="card-mtto-general">
                    <div className="row-distribucion">
                        <span className={`icon-general icon-id bor-${invernaderoOrigen?.color} bg-${invernaderoOrigen?.color}`}>
                            {invernaderoOrigen?.es_externo ? <img width="60" alt="icono" src={icono} /> : invernaderoOrigen?.nombre}
                        </span>
                        <div className="card-mtto-empresa ml-05">{getNombrePorID(productosYVariedades, invernaderoOrigen?.producto_ref)}</div>
                    </div>
                    {/*  --------Origen--------  */}
                    <div className="dashed-border-bottom">
                        <SeccionFiltros extra="p-0 position-sticky">
                            <ContenedorFiltro col="3" label="Origen" sinFiltrarTexto>
                                <InputText disabled={true} value={invernaderoOrigen?.nombre} />
                            </ContenedorFiltro>

                            <ContenedorFiltro col="4" label="Cajas asignadas" sinFiltrarTexto>
                                <UnidadBase claseExtra="unidad-cajas-distribucion" valor={`${itemOrigen.cajas_orden} / ${sumaCajasOriginal}`} unidad="cajas" />
                            </ContenedorFiltro>

                            <ContenedorFiltro col="5" extra="pr-0" label="Sitio empaque" sinFiltrarTexto >
                                <Dropdown value={itemOrigen.empaque_ref} options={sitiosEmpaques} onChange={handleChangeOrigenEmpaque}
                                    optionLabel="nombre" optionValue="id" placeholder="Sin asignar" disabled={!invernaderoOrigen?.es_externo} />
                            </ContenedorFiltro>
                        </SeccionFiltros>
                    </div>

                    {/*  --------Destinos--------  */}
                    {itemsDestino.map((itemDestino, index) => {
                        const destinoEsExterno = getEsInvernaderoExternoPorID(invernaderosVirtuales, itemDestino.invernadero_ref);
                        const invernaderosDestinos = filtrarOpcionesInvernaderosDestinos(itemDestino);
                        const sitiosEmpaqueOpciones = filtrarOpcionesEmpaque(itemDestino, destinoEsExterno);
                        const erroresMensaje = erroresDelItem(itemDestino, index);

                        //console.log("itemDestino: ", itemDestino);
                        return (
                            <SeccionFiltros key={`it-des-${itemDestino.id}-${index}`} extra="p-0 position-initial">
                                <ContenedorFiltro col="3" label={`Destino ${index + 1}`} sinFiltrarTexto>
                                    <Dropdown name="invernadero_ref" id={`${index}`} value={itemDestino.invernadero_ref} options={invernaderosDestinos}
                                        onChange={handleChangeItemDestino} optionLabel="nombre" optionValue="id" placeholder="Seleccionar" itemTemplate={invernaderoItemBody} />
                                </ContenedorFiltro>

                                <ContenedorFiltro col="4" extra="p-hor-0" label="Cajas asignadas" sinFiltrarTexto>
                                    <div className="row-distribucion">
                                        <InputText name="cajas_orden" id={`${index}`} value={itemDestino.cajas_orden} onChange={handleChangeItemDestino} keyfilter="pnum" placeholder="Cajas a redistribuir" />
                                        <UnidadBase claseExtra="unidad-cajas-distribucion" valor={`/${sumaCajasOriginal}`} unidad="cajas" />
                                    </div>
                                </ContenedorFiltro>

                                <ContenedorFiltro col="4" label="Sitio empaque" sinFiltrarTexto >
                                    <Dropdown name="empaque_ref" id={`${index}`} value={itemDestino.empaque_ref} options={sitiosEmpaqueOpciones} onChange={handleChangeItemDestino}
                                        optionLabel="nombre" optionValue="id" placeholder="Seleccionar" disabled={!destinoEsExterno && !invernaderoOrigen?.es_externo} />
                                </ContenedorFiltro>

                                <ContenedorFiltro extra="cont-button-distr-trash" col="1" sinLabel >
                                    <button className="p-button-distr-trash" onClick={() => eliminarItem(index)}>
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                    </button>
                                </ContenedorFiltro>
                                {erroresMensaje && <Message severity="error" text={erroresMensaje} />}
                            </SeccionFiltros>)
                    })}

                    <Button onClick={agregarItem} label="+ Agregar invernadero" className="p-button w-100 position-initial" disabled={getDeshabilitadoBotonAgregar()} />
                </div>
            </div>
        </div>)
}
export default CardItemOrigen;//298