const NAVEGACION = {
    RECHAZOS: {
        root: "/rechazos",
        manifiestoValidacionPrecios: "/rechazos/manifestos_validacion_precios",
        formValidacionInvoiceCreateInvoice: "/rechazos/form_validacion_invoice",
        formValidacionInvoiceEditInvoice: "/rechazos/form_validacion_invoice/:invoiceId",
        tablaInvoices: "/rechazos/tabla_invoices",
        reporteRechazos: "/rechazos/reporte"
    }
}
export default NAVEGACION;