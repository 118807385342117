import React, { useEffect } from 'reactn';
import { useGlobal } from "reactn";
import InvernaderoCard from "./InvernaderoCard";
import { obtenerCuentaEmpleadosHabilitadosInvernadero, obtenerPorcentajeHorasTrabajadas, obtenerActividadesSemana } from "../../service/RhService";
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import ContenedorPrincipal from '../common/ContenedorPrincipal';
import ContenedorBotonesTop from '../common/ContenedorBotonesTop';
import RowBotonesTop from '../common/RowBotonesTop';
import SeccionFiltros from '../common/SeccionFiltros';
import SeccionCards from '../common/SeccionCards';

function Invernaderos(props) {
  const [invernaderos, setInvernaderos] = useGlobal("invernaderos");
  const [ciclo,] = useGlobal("ciclo");
  const [, setNombreModulo] = useGlobal("nombreModulo");
  const [weeks, week, handleWeekChange] = useWeekSelector();
  const [, setCargando] = useGlobal("cargando");
  const [, setMostrarModalPermiso] = useGlobal("mostrarModalPermiso");
  const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
  const [invernaderosFisicos] = useGlobal("invernaderosFisicos");
  const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");

  useEffect(() => {
    setHabilitadoSelectorCiclo(true);
    setNombreModulo("Producción");
  }, [])

  useEffect(() => {
    if (invernaderosVirtuales?.length > 0 && week.nombre !== "Cargando") {
      const promises = [];
      const invernaderos = invernaderosVirtuales.filter(inv => !inv.es_externo);
      for (let x = 0; x < invernaderos.length; x++) {
        promises.push(obtenerResumenActividades(invernaderos[x]));
      };

      setCargando(true);
      Promise.all(promises).then(values => {
        let copia = invernaderos.map(el => Object.assign({}, el));
        for (let x = 0; x < values.length; x++) {
          let [invId, cuentaEmpleados, invFisico, horasTrabajadas, porcActividades, variedad, producto] = values[x];
          let inv = copia.find(el => el.id === invId);
          inv.cuentaEmpleados = cuentaEmpleados;
          inv.invernaderoFisico = invFisico;
          inv.horasTrabajadas = horasTrabajadas;
          inv.porcActividades = porcActividades;
          inv.producto = producto;
          inv.variedad = variedad;
        }
        setInvernaderos(copia);
      }).catch(err => {
        console.log(err);
      }).finally(el => {
        setCargando(false);
      })
    }
  }, [ciclo, invernaderosVirtuales, week])
  function obtenerResumenActividades(invernadero) {
    return new Promise(async (resolve, reject) => {
      try {
        const { time: semana } = week, promises = [];
        const respEmpleados = await obtenerCuentaEmpleadosHabilitadosInvernadero(ciclo, invernadero.id);

        promises.push(invernaderosFisicos.find(invF => invF.id === invernadero.invernadero_fisico_ref));
        promises.push(obtenerPorcentajeHorasTrabajadas(semana, respEmpleados, ciclo, invernadero.id));
        promises.push(obtenerActividadesSemana(semana, respEmpleados, ciclo, invernadero.id));

        const resumenActividades = await Promise.all(promises);
        const [invernaderoFisico, respHorasTrabajadas, respActividadesSemana] = resumenActividades;
        resolve([invernadero.id, respEmpleados, invernaderoFisico, respHorasTrabajadas, respActividadesSemana])
      } catch (err) {
        if (err?.code == "permission-denied") {
          setMostrarModalPermiso(true);
          reject(err);
        }
      }
    })
  }

  const irHorasActividades = () => {
    props.history.push("/nomina/graficas/horas_actividades");
  }

  return (
    <ContenedorPrincipal>
      <ContenedorBotonesTop>
        <RowBotonesTop>
          <Button className="reactbtn-icon" label="Horas trabajadas vs actividades" onClick={irHorasActividades}>
            <FontAwesomeIcon icon={faChartBar} />
          </Button>
        </RowBotonesTop>
      </ContenedorBotonesTop>

      <SeccionFiltros>
        <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
      </SeccionFiltros>

      <SeccionCards>
        {invernaderos?.map(invernadero => <InvernaderoCard key={invernadero.id} invernadero={invernadero} />)}
      </SeccionCards>
    </ContenedorPrincipal>);
}
export default Invernaderos;