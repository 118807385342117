import * as firebase from "firebase/app";
export const notificarOrdenesVenta = async (estadoAnterior, estadoNuevo, usuario, fecha, cicloId) => {
    const functions = firebase.functions();
    const notificar = functions.httpsCallable("notificarOrdenesVenta");
    let datos = { estadoAnterior, estadoNuevo, usuario, fecha, cicloId };
    let result = await notificar(datos, cicloId);

    return [];
}
export const notificarOrdenCerrada = async (solicitante, orden_num, comentarioCerrado, modulo) => {
    const functions = firebase.functions();
    const notificar = functions.httpsCallable("notificarOrdenCerrada");
    let datos = { solicitante: solicitante, orden_num: orden_num, comentarioCerrado: comentarioCerrado, modulo };
    let result = await notificar(datos);
    return [];
}
export const notificarOrdenCompraCreada = async (data) => {
    const functions = firebase.functions();
    const notificar = functions.httpsCallable("notificarOrdenCompraCreada");
    let result = await notificar(data);

    return [];
}
export const notificarOrdenSistemaCreada = async (data) => {
    const functions = firebase.functions();
    const notificar = functions.httpsCallable("notificarOrdenSistemaCreada");
    let result = await notificar(data);

    return [];
}
export const notificarOrdenMantenimientoCreada = async (data) => {
    const functions = firebase.functions();
    const notificar = functions.httpsCallable("notificarOrdenMantenimientoCreada");
    let result = await notificar(data);

    return [];
}

export const notificarEstatusRequisicion = async (usuario, orden_num, estatus, modulo) => {
    const functions = firebase.functions();
    const notificar = functions.httpsCallable("notificarEstatusRequisicion");
    let datos = { usuario: usuario, orden_num: orden_num, estatus: estatus, modulo };
    let result = await notificar(datos);

    return [];
}