import React from 'react';
import { Dialog } from "primereact/dialog";
import { Button } from 'primereact/button';



const MensajeTransferisteModal = (props) => {

    const footer = () => {
        return <div>
            <Button label="Cerrar" onClick={props.onHide} />
        </div>
    }

    return <Dialog header={"Transferencia de kilos disponibles"} visible={props.visible} style={{ width: '50vw' }} onHide={props.onHide} footer={footer()}>
        <div className="p-grid ">
            <div className="form-group p-col-12">
                <span htmlFor="status-nuevo" className="modal-pre-line">{props.hasTransferredText}</span>
            </div>
        </div>
    </Dialog >
}
export default MensajeTransferisteModal