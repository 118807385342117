import React, { useState, useGlobal, useEffect } from "reactn";
import { obtenerPromediosHistoricos, obtenerOutputsHistoricos, obtenerMedidasCultivo } from "../../service/Cultivo";
import { obtenerCiclo } from "../../service/Ciclos";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { dosDecimales, getObjetoPorID, getProductoYVariedadString, separadoComas } from "../../util/functions";
import { obtenerNombreSemana, obtenerSemanaAnterior } from "../../service/fechas";
import { TabMenu } from 'primereact/tabmenu';
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { getProductoBaseDeInvernadero } from "service/ProductosYVariedades";

const mapeoTabs = {
    "Clima Exterior": "exterior",
    "Clima Interior": "interior",
    "Riego": "riego",
    "Cultivo": "cultivo"
}
export default function Reporte(props) {
    const [ciclo] = useGlobal("ciclo");
    const [usuario] = useGlobal("usuario");

    const [nombreModulo, setNombreModulo] = useGlobal("nombreModulo");
    const [medidasCultivo, setMedidasCultivo] = useState([]);
    const [tabs] = useState([
        { label: "Clima Exterior" },
        { label: "Clima Interior" },
        { label: "Riego" },
        { label: "Cultivo" },])
    const [promedios, setPromedios] = useState([])
    const [activeTab, setActiveTab] = useState(null);
    const [invernadero, setInvernadero] = useGlobal("invernadero");
    const [rows, setRows] = useState([]);
    const [, setCargando] = useGlobal("cargando");
    const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector();
    const [outputs, setOutputs] = useState([0, 0, 0])
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [productosBases] = useGlobal("productosBases");
    const [medleysGeneral] = useGlobal("medleysGeneral");


    const atras = (params) => {
        props.history.goBack();
    }
    useEffect(() => {
        if (ciclo) {
            updateCicloWeekSelector(ciclo);
        }
    }, [ciclo])
    const procesarPromediosHistoricos = (promedios) => {
        const tipos = ["exterior", "interior", "riego", "cultivo"]
        let rows = [];

        promedios[0].forEach((medida, index) => {
            rows.push({
                nombre: medida["nombre"], antepasada: promedios[0][index]["promedio"],
                pasada: promedios[1][index]["promedio"], actual: promedios[2][index]["promedio"], unidades: medida["unidades"],
                tipo: medida["tipo"], anteantepasada: promedios[3][index]["promedio"], anteanteantepasada: promedios[4][index]["promedio"]
            })
        });

        return rows;
    }
    const irInvernadero = (id) => {
        setInvernadero(id);
        props.history.push("/cultivo/historico")
    }
    useEffect(() => {
        if (promedios && promedios.length > 0 && activeTab) {
            let rows = promedios.filter(el => el.tipo === mapeoTabs[activeTab["label"]]);
            setRows(rows);
        }
    }, [promedios, activeTab])
    useEffect(() => {
        if (tabs) setActiveTab(tabs[0])
    }, [tabs])

    useEffect(() => {
        setNombreModulo("Empaque");
        setHabilitadoSelectorCiclo(false);
    }, [])
    useEffect(() => {
        if (activeTab && promedios) {
            let filtrados = promedios.filter(el => el.tipo === mapeoTabs[activeTab.label])
            setRows(filtrados)
        }
    }, [activeTab, promedios])
    useEffect(() => {
        if (ciclo && invernadero) {
            setCargando(true);
            obtenerPromediosHistoricos(ciclo, invernadero.id, week.time)
                .then((resp) => {
                    let procesados = procesarPromediosHistoricos(resp);
                    setPromedios(procesados);
                    setCargando(false);
                }).catch(err => {
                    console.log("ERROR OPC");
                    console.log(err);
                })
            obtenerOutputsHistoricos(ciclo, invernadero, week.time).then((result) => {
                let outputs = {
                    antepasada: result[0],
                    pasada: result[1],
                    actual: result[2],
                    anteantepasada: result[3],
                    anteanteantepasada: result[4]
                }
                setOutputs(outputs);
            }).catch((err) => {

            });
        }

    }, [ciclo, invernadero, week])
    useEffect(() => {
        obtenerMedidasCultivo().then((result) => {
            setMedidasCultivo(result);
        }).catch((err) => {

        });
    }, [])

    function unidadTemplate(rowData, column) {
        let iconoIndicador = null;
        let medida = medidasCultivo.find(el => el.nombre === rowData["nombre"]);
        if (medida && medida.tooltips) {
            const producto = getProductoBaseDeInvernadero(productosBases, medleysGeneral, invernadero)
            let max = parseFloat(medida?.tooltips?.[producto?.id]?.max || "0")
            let min = parseFloat(medida?.tooltips?.[producto?.id]?.min || "0")
            let textoTooltip = `Valores recomendados: ${min} - ${max}`;

            if((!max && !min) || isNaN(max)||  isNaN(max)) textoTooltip = "No hay valores recomendados para este invernadero"

            let dato = parseFloat(rowData[column["field"]]);
            let ttip = <Button className="btn-line btn-help  ml-1" type="button" label="?" tooltip={textoTooltip} />

            let desviacion = "bien";
            if (dato > max) {
                desviacion = "alta"
            } else if (dato < min) {
                desviacion = "media"
            }
            if (desviacion === "media") {
                iconoIndicador = <FontAwesomeIcon icon={faCaretDown} />;
            }
            if (desviacion === "alta") {
                iconoIndicador = <FontAwesomeIcon icon={faCaretUp} />;
            }

            return <div className={desviacion}>{iconoIndicador}<span> {separadoComas(dosDecimales(rowData[column["field"]]))}</span><span className="label-unidad">{rowData["unidades"]}</span>
                {desviacion === "ninguna" ? null : ttip}</div>;

        } else {
            return <div>{separadoComas(dosDecimales(rowData[column["field"]]))}<span className="label-unidad">{rowData["unidades"]}</span> </div>;
        }
    }

    function obtenerColumnas() {
        let cols = [<Column key={`culti-sem-nombre`} field="nombre" className="col__label" header="Nombre" ></Column>];
        let semanas = ["anteanteantepasada", "anteantepasada", "antepasada", "pasada", "actual"];
        let semanaActual = week.time;
        let semanaAnterior = obtenerSemanaAnterior(semanaActual);
        let semanaAntepasada = obtenerSemanaAnterior(semanaAnterior);
        let semanaAnteAntepasada = obtenerSemanaAnterior(semanaAntepasada);
        let semanaAnteAnteAntepasada = obtenerSemanaAnterior(semanaAnteAntepasada);
        let headers = [obtenerNombreSemana(semanaAnteAnteAntepasada), obtenerNombreSemana(semanaAnteAntepasada), obtenerNombreSemana(semanaAntepasada), obtenerNombreSemana(semanaAnterior), obtenerNombreSemana(week.time),];
        semanas.map((el, index) => {
            cols.push(<Column key={`culti-sem-${index}`} body={unidadTemplate} field={el} header={headers[index]} />);
        })
        return cols;
    }
    const obtenerFooter = (params) => {
        if (activeTab) {
            let tipo = mapeoTabs[activeTab["label"]];
            if (tipo === "cultivo") {
                return <ColumnGroup>
                    <Row>
                        <Column footer="Peso Promedio de Fruta" colSpan={1} />
                        <Column footer={<div>{isNaN(outputs["anteanteantepasada"][0]) ? "-" : dosDecimales(1000 * outputs["anteanteantepasada"][0])}<span className="label-unidad">gr</span></div>} colSpan={1} />
                        <Column footer={<div>{isNaN(outputs["anteantepasada"][0]) ? "-" : dosDecimales(1000 * outputs["anteantepasada"][0])}<span className="label-unidad">gr</span></div>} colSpan={1} />
                        <Column footer={<div>{isNaN(outputs["antepasada"][0]) ? "-" : dosDecimales(1000 * outputs["antepasada"][0])}<span className="label-unidad">gr</span></div>} colSpan={1} />
                        <Column footer={<div>{isNaN(outputs["pasada"][0]) ? "-" : dosDecimales(1000 * outputs["pasada"][0])}<span className="label-unidad">gr</span></div>} colSpan={1} />
                        <Column footer={<div>{isNaN(outputs["actual"][0]) ? "-" : dosDecimales(1000 * outputs["actual"][0])}<span className="label-unidad">gr</span></div>} colSpan={1} />
                    </Row>
                    <Row>
                        <Column footer="Producción semanal" colSpan={1} />
                        <Column footer={<div>{dosDecimales(outputs["anteanteantepasada"][1])}<span className="label-unidad">kg/m²</span></div>} colSpan={1} />
                        <Column footer={<div>{dosDecimales(outputs["anteantepasada"][1])}<span className="label-unidad">kg/m²</span></div>} colSpan={1} />
                        <Column footer={<div>{dosDecimales(outputs["antepasada"][1])}<span className="label-unidad">kg/m²</span></div>} colSpan={1} />
                        <Column footer={<div>{dosDecimales(outputs["pasada"][1])}<span className="label-unidad">kg/m²</span></div>} colSpan={1} />
                        <Column footer={<div>{dosDecimales(outputs["actual"][1])}<span className="label-unidad">kg/m²</span></div>} colSpan={1} />
                    </Row>

                </ColumnGroup>;
            }


        }
        return null;
    }

    const getUltimos3Semanas = () => {
        if (!weeks || weeks?.length === 0) return weeks
        let indexLast = weeks.length - 1
        return [weeks[indexLast - 2], weeks[indexLast - 1], weeks[indexLast]]
    }

    return (
        <div className="p-grid p-justify-center">

            <div className="p-col-12 title-container">
                <span className="back-btn" onClick={atras}></span>
                <React.Fragment>
                    <span className={`icon-general icon-id bor-${invernadero?.color} bg-${invernadero?.color} ${invernadero?.habilitado ? "" : "Deshabilitado"}`}>{invernadero?.nombre}</span>
                </React.Fragment>

                <span className="titles-group">
                    <h1 className="header-title">
                        Reporte histórico de cultivo
                    </h1>
                    {invernadero &&
                        <p className="header-subtitle">
                            {getProductoYVariedadString(invernadero)}
                        </p>}

                </span>
            </div>
            <div className="p-col-12">
                <div className="p-grid filtros">
                    <WeekSelector weeks={usuario.isRolFinanzas ? getUltimos3Semanas() : weeks} handleWeekChange={handleWeekChange} week={week} />
                </div>
            </div>
            <div className="p-col-12">
                <TabMenu model={tabs} activeItem={activeTab} onTabChange={(e) => setActiveTab(e.value)} />


                <div className="card p-0">
                    <DataTable value={rows} footerColumnGroup={obtenerFooter()} >
                        {obtenerColumnas()}
                    </DataTable>
                </div>

            </div>
        </div>)
}//269