import React, { useState } from 'react';
import {Card} from 'primereact/card';

function InvernaderoCard(props){
    
    const header = <span className="card-header"><div className={`card-id icon-general icon-id ${props.invernadero.status?"color-":"bg-"}${props.invernadero.color}  bor-${props.invernadero.color}`}>{props.invernadero.nombre}</div><div><p className="card-up">{props.invernadero.status}</p><p className="card-down">{props.invernadero.invernadero}</p></div></span>
    
    return  <div className="p-col-12 p-md-4 p-sm-6">
              <Card header = {header} className={`htg-card bor-${props.invernadero.color} ${props.invernadero.status}`}   >   
                <div className="p-grid p-justify-center text-center">
                  <div className="p-col">
                    <div className="card-block">
                      <p className="value">{props.invernadero.demo}</p>
                      <p className="label">LABEL</p>
                    </div>
                    <div className="card-block">
                      <p className="value">{props.invernadero.demo}</p>
                      <p className="label">LABEL</p>
                    </div>
                  </div>
                  <div className="p-col">
                    <div className="card-block">
                      <p className="value">{props.invernadero.demo}</p>
                      <p className="label">LABEL</p>
                    </div>
                    <div className="card-block">                   
                      <p className="value">{props.invernadero.demo}</p>
                      <p className="label">LABEL</p>
                    </div>
                  </div>
                </div>
            </Card>
          </div>
}

export default InvernaderoCard;