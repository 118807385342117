import { segundosSemana } from "../constants";
import { DeepClone, dosDecimales } from "../util/functions";
import { obtenerCiclo } from "./Ciclos";
import { obtenerReporteCosechaInvernaderoExternoSemana, obtenerReporteCosechaInvernaderoSemana } from "./Cosecha";
import { obtenerInicioSemanaDia } from "./fechas";
import { obtenerAreaInvernaderoSync, obtenerInvernadero, obtenerInvernaderosVirtuales, obtenerInvernaderosVirtualesInternos } from "./Invernaderos";
import { obtenerPesoDesde, obtenerPesoProveedorDesde, obtenerPesoSemana, obtenerPesoSemanaProveedor } from "./Producto";
import { obtenerCapturaPrevia, obtenerCapturaPreviaProveedorExterno } from "./Queries/Pronostico"
import { getGlobal, setGlobal } from 'reactn';
import { obtenerReporteCompraExternaProveedorSemana } from "./CompraExterna";
import { getProveedoresInvernadero, obtenerProveedoresInvernadero } from "./Queries/Proveedores";

export const obtenerPronosticoYProyeccion = async (cicloId, invernadero, semanaTime, area) => {

    let reporteCosecha = await obtenerReporteCosechaInvernaderoSemana(cicloId, invernadero, semanaTime)
    let kgm2Reporte = reporteCosecha[4];

    let invernaderoId = invernadero.id;
    let [captura5, captura4, captura3, captura2, captura]
        = await Promise.all([obtenerCapturaPrevia(cicloId, invernaderoId, semanaTime - (4 * segundosSemana)), obtenerCapturaPrevia(cicloId, invernaderoId, - (3 * segundosSemana)), obtenerCapturaPrevia(cicloId, invernaderoId, semanaTime - (2 * segundosSemana)),
        obtenerCapturaPrevia(cicloId, invernaderoId, semanaTime - (1 * segundosSemana)), obtenerCapturaPrevia(cicloId, invernaderoId, semanaTime)]);
    let proyeccion = await obtenerProyeccionPronostico(cicloId, invernadero, semanaTime, captura5, captura4, captura3, captura2, captura)
    let hace3semanas = obtenerInicioSemanaDia(semanaTime - 3 * segundosSemana).unix();
    let hace5semanas = obtenerInicioSemanaDia(semanaTime - 5 * segundosSemana).unix();
    let datos3semanas = await obtenerCapturaPrevia(cicloId, invernaderoId, hace3semanas)
    let datos5semanas = await obtenerCapturaPrevia(cicloId, invernaderoId, hace5semanas)
    let pesoEmpacado = await obtenerPesoSemana(cicloId, invernaderoId, semanaTime);
    let semanaTres = pesoEmpacado > 0 ? ((!datos3semanas.isEmpty && datos3semanas["pronosticos"][2]) ? kgm2Reporte.real / datos3semanas["pronosticos"][2] : "-") : "-";
    let semanaCinco = pesoEmpacado > 0 ? ((!datos5semanas.isEmpty && datos5semanas["pronosticos"][4]) ? kgm2Reporte.real / datos5semanas["pronosticos"][4] : "-") : "-";

    let semanaTresDiario = (!datos3semanas.isEmpty && kgm2Reporte?.diario > 0) ? (kgm2Reporte.diario / (datos3semanas["pronosticos"][2])) : "-";
    let semanaCincoDiario = (!datos5semanas.isEmpty && kgm2Reporte?.diario > 0) ? (kgm2Reporte.diario / (datos5semanas["pronosticos"][4])) : "-";
    let semanaTresSemanal = (!datos3semanas.isEmpty && kgm2Reporte?.semanal > 0) ? (kgm2Reporte.semanal / (datos3semanas["pronosticos"][2])) : "-";
    let semanaCincoSemanal = (!datos5semanas.isEmpty && kgm2Reporte?.semanal > 0) ? (kgm2Reporte.semanal / (datos5semanas["pronosticos"][4])) : "-";
    //window.alert(semanaCincoSemanal + " -- " + semanaTresSemanal)
    
    return { semanaUno: pesoEmpacado, semanaTres, semanaCinco, proyeccion, ...kgm2Reporte, semanaTresDiario, semanaCincoDiario, semanaTresSemanal, semanaCincoSemanal }
}
////////////

export const obtenerPronosticoYProyeccionProveedorExterna = async (cicloId, invernadero, semanaTime, proveedor) => {
    const checarSiHayDatos = await checarDatosPronosticos(cicloId, invernadero.id, semanaTime, proveedor)
    if (!checarSiHayDatos && !proveedor.habilitado) { return false; }

    let reporteCosecha = await obtenerReporteCompraExternaProveedorSemana(cicloId, proveedor, semanaTime)
    let kgm2Reporte = reporteCosecha[3];

    //if(proveedor.nombre === "Adam Dodgen prov1")console.log("reporteCosecha: ",reporteCosecha)
    let invernaderoId = invernadero.id;

    let [captura5, captura4, captura3, captura2, captura]
        = await Promise.all([obtenerCapturaPreviaProveedorExterno(cicloId, invernaderoId, semanaTime - (4 * segundosSemana), proveedor.id),
        obtenerCapturaPreviaProveedorExterno(cicloId, invernaderoId, - (3 * segundosSemana), proveedor.id),
        obtenerCapturaPreviaProveedorExterno(cicloId, invernaderoId, semanaTime - (2 * segundosSemana), proveedor.id),
        obtenerCapturaPreviaProveedorExterno(cicloId, invernaderoId, semanaTime - (1 * segundosSemana), proveedor.id),
        obtenerCapturaPreviaProveedorExterno(cicloId, invernaderoId, semanaTime, proveedor.id)]);

    //if(proveedor.nombre === "Adam Dodgen prov1")console.log("captura: ",captura)
    //if(proveedor.nombre === "Adam Dodgen prov1")console.log("captura5: ",captura5)

    let proyeccion = await obtenerProyeccionPronostico(cicloId, invernadero, semanaTime, captura5, captura4, captura3, captura2, captura, proveedor)

    let hace3semanas = obtenerInicioSemanaDia(semanaTime - 3 * segundosSemana).unix();
    let hace5semanas = obtenerInicioSemanaDia(semanaTime - 5 * segundosSemana).unix();
    let datos3semanas = await obtenerCapturaPreviaProveedorExterno(cicloId, invernaderoId, hace3semanas, proveedor.id)
    //if(proveedor.nombre === "Adam Dodgen prov1")console.log("datos3semanas, ",datos3semanas)
    let datos5semanas = await obtenerCapturaPreviaProveedorExterno(cicloId, invernaderoId, hace5semanas, proveedor.id)
    let pesoEmpacado = await obtenerPesoSemanaProveedor(cicloId, invernaderoId, proveedor, semanaTime);
    let semanaTres = pesoEmpacado > 0 ? ((!datos3semanas.isEmpty && datos3semanas["pronosticos"][2]) ? kgm2Reporte.real / datos3semanas["pronosticos"][2] : "-") : "-";
    let semanaCinco = pesoEmpacado > 0 ? ((!datos5semanas.isEmpty && datos5semanas["pronosticos"][4]) ? kgm2Reporte.real / datos5semanas["pronosticos"][4] : "-") : "-";

    let semanaTresDiario = (!datos3semanas.isEmpty && kgm2Reporte?.diario > 0) ? (kgm2Reporte.diario / (datos3semanas["pronosticos"][2])) : "-";
    let semanaCincoDiario = (!datos5semanas.isEmpty && kgm2Reporte?.diario > 0) ? (kgm2Reporte.diario / (datos5semanas["pronosticos"][4])) : "-";
    let semanaTresSemanal = (!datos3semanas.isEmpty && kgm2Reporte?.semanal > 0) ? (kgm2Reporte.semanal / (datos3semanas["pronosticos"][2])) : "-";
    let semanaCincoSemanal = (!datos5semanas.isEmpty && kgm2Reporte?.semanal > 0) ? (kgm2Reporte.semanal / (datos5semanas["pronosticos"][4])) : "-";

    //if(proveedor.nombre === "Adam Dodgen prov1")console.log("PROYECCION, ",proyeccion)
    //  console.log("obtenerDatos", { semanaUno: pesoEmpacado, semanaTres, semanaCinco, proyeccion, ...kgm2Reporte, semanaTresDiario, semanaCincoDiario, semanaTresSemanal, semanaCincoSemanal })
    return { semanaUno: pesoEmpacado, semanaTres, semanaCinco, proyeccion, ...kgm2Reporte, semanaTresDiario, semanaCincoDiario, semanaTresSemanal, semanaCincoSemanal, captura }
}
const checarDatosPronosticos = async (cicloId, invernaderoId, semanaTime, proveedor) => {
    const pronosticoProveedor = await obtenerCapturaPreviaProveedorExterno(cicloId, invernaderoId, semanaTime, proveedor.id);
    if (pronosticoProveedor.isEmpty) { return false; }
    else {
        const hayValores = pronosticoProveedor.pronosticos?.some(valor => {
            const numeroVal = parseFloat(valor);
            if (isNaN(numeroVal)) { return false; }
            else { return numeroVal > 0; }
            //return isNaN(numeroVal) ? false : (numeroVal > 0)
        })
        return hayValores;
    }
}


////////////
export const getInicialRealInteligente = async (cicloId, invernadero, semanaTime) => {
    let reporteCosecha = await obtenerReporteCosechaInvernaderoSemana(cicloId, invernadero, semanaTime)
    return reporteCosecha[4].real
}

const obtenerProyeccionPronostico = async (cicloId, invernadero, semanaTime, captura5, captura4, captura3, captura2, captura, proveedor) => {
    //console.log("PROBLEM... ", {cicloId, invernadero, semanaTime, captura5, captura4, captura3, captura2, captura, proveedor})
    if(!invernadero.budget?.estimacion_semanal?.[0]?.time)return 0
    //console.log("ERRRR: ", {cicloId, invernadero, semanaTime, captura5, captura4, captura3, captura2, captura, proveedor})
    let suma = 0, numSemanaFuturo = 5
    let ciclo = getGlobal().cicloObj || await obtenerCiclo(cicloId);

    let area = proveedor ? parseInt(proveedor?.area_invernadero) : obtenerAreaInvernaderoSync(invernadero)
    let budget = proveedor ? { estimacion_semanal: proveedor?.budget || [] } : invernadero.budget
    //1.
    let pesoEmpacado = proveedor
        ? await obtenerPesoProveedorDesde(cicloId, invernadero.id, budget?.estimacion_semanal?.[0]?.time, semanaTime + segundosSemana, proveedor)
        : await obtenerPesoDesde(cicloId, invernadero.id, budget?.estimacion_semanal?.[0]?.time, semanaTime + segundosSemana);

    if (proveedor?.nombre === "Adam Dodgen prov1") console.log("PESO EMPACADO: ", pesoEmpacado)


    suma += pesoEmpacado / area;
    if (!captura.isEmpty) {
        //2.
        let pronosticos5Semanas = DeepClone(captura.pronosticos)
        if (captura.pronosticos.length > 5) pronosticos5Semanas = DeepClone(captura.pronosticos.filter((_, index) => index < 5))

        pronosticos5Semanas.forEach((pronostico, index) => {
            if (!pronostico || isNaN(parseFloat(pronostico)) || parseFloat(pronostico) == 0) {////If its empty or 0? or only if empty
                let useBudget = false

                if (index === 3) {
                    if (captura2?.pronosticos?.[4] && !isNaN(parseFloat(captura2?.pronosticos?.[4])) && parseFloat(captura2?.pronosticos?.[4]) != 0)
                        suma += parseFloat(captura2?.pronosticos?.[4])
                    else useBudget = true
                }
                else if (index === 2) {
                    if (captura2?.pronosticos?.[3] && !isNaN(parseFloat(captura2?.pronosticos?.[3])) && parseFloat(captura2?.pronosticos?.[3]) != 0)
                        suma += parseFloat(captura2?.pronosticos?.[3])
                    else if (captura3?.pronosticos?.[4] && !isNaN(parseFloat(captura3?.pronosticos?.[4])) && parseFloat(captura3?.pronosticos?.[4]) != 0)
                        suma += parseFloat(captura3?.pronosticos?.[4])
                    else useBudget = true
                }
                else if (index === 1) {
                    if (captura2?.pronosticos?.[2] && !isNaN(parseFloat(captura2?.pronosticos?.[2])) && parseFloat(captura2?.pronosticos?.[2]) != 0)
                        suma += parseFloat(captura2?.pronosticos?.[2])
                    else if (captura3?.pronosticos?.[3] && !isNaN(parseFloat(captura3?.pronosticos?.[3])) && parseFloat(captura3?.pronosticos?.[3]) != 0)
                        suma += parseFloat(captura3?.pronosticos?.[3])
                    else if (captura4?.pronosticos?.[4] && !isNaN(parseFloat(captura4?.pronosticos?.[4])) && parseFloat(captura4?.pronosticos?.[4]) != 0)
                        suma += parseFloat(captura4?.pronosticos?.[4])
                    else useBudget = true
                }
                else if (index === 0) {
                    if (captura2?.pronosticos?.[1] && !isNaN(parseFloat(captura2?.pronosticos?.[1])) && parseFloat(captura2?.pronosticos?.[1]) != 0)
                        suma += parseFloat(captura2?.pronosticos?.[1])
                    else if (captura3?.pronosticos?.[2] && !isNaN(parseFloat(captura3?.pronosticos?.[2])) && parseFloat(captura3?.pronosticos?.[2]) != 0)
                        suma += parseFloat(captura3?.pronosticos?.[2])
                    else if (captura4?.pronosticos?.[3] && !isNaN(parseFloat(captura4?.pronosticos?.[3])) && parseFloat(captura4?.pronosticos?.[3]) != 0)
                        suma += parseFloat(captura4?.pronosticos?.[3])
                    else if (captura5?.pronosticos?.[4] && !isNaN(parseFloat(captura5?.pronosticos?.[4])) && parseFloat(captura5?.pronosticos?.[4]) != 0)
                        suma += parseFloat(captura5?.pronosticos?.[4])
                    else useBudget = true
                }

                //3.
                if (semanaTime < budget?.estimacion_semanal?.[index]?.time) {
                    useBudget = false
                    numSemanaFuturo = 0
                }
                if (budget?.estimacion_semanal && useBudget) {
                    let budgetFiltered = budget?.estimacion_semanal.filter(budgetSemanal => budgetSemanal.time > semanaTime)
                    suma += parseFloat(budgetFiltered?.[index]?.value || "0")
                }

                //4.
            } else if (semanaTime >= budget?.estimacion_semanal?.[index]?.time) suma += parseFloat(pronostico)
        })
    }
    else numSemanaFuturo = 0

    if (budget) {
        budget.estimacion_semanal.forEach(budgetSemanal => {

            if (budgetSemanal.time > semanaTime + (numSemanaFuturo * segundosSemana)) {
                if (proveedor?.nombre === "Adam Dodgen prov1") console.log("BUDGET: ", { suma, nombre: budgetSemanal.nombre })
                suma += parseFloat(budgetSemanal.value)
            }
        })
    }
    //1. obtener captura real hasta semana pasada
    //2. obtener pronosticos de proximas 5 semanas
    //3. obtener budget de siguientes 5 semanas
    //4. sumar
    return dosDecimales(suma);
}
export const obtenerReportePronosticos = async (cicloId, weekTime, invernaderos = []) => {
    let promCapturas = invernaderos.map(invernadero => obtenerCapturaPrevia(cicloId, invernadero.id, weekTime))
    let capturas = await Promise.all(promCapturas);

    let promisesPronosticoProyeccion = invernaderos.map((invernadero, index) => {
        let area = obtenerAreaInvernaderoSync(invernadero);
        return obtenerPronosticoYProyeccion(cicloId, invernadero, weekTime, area)
    })
    let pronosticosProyecciones = await Promise.all(promisesPronosticoProyeccion)
    let result = invernaderos.map((invernadero, index) => {
        let obj = {
            invernadero, capturas: capturas[index],
            pronosticosProyecciones: pronosticosProyecciones[index]
        }
        return obj
    })
    return result;

}


////////////////pron ext REPORTE/////////////
export const obtenerReportePronosticosExternos = async (cicloId, weekTime, invernaderos = []) => {
    let promCapturas = invernaderos.map(invernadero => obtenerCapturaPrevia(cicloId, invernadero.id, weekTime))
    let capturas = await Promise.all(promCapturas);
    console.log("CAPTURAS obtenerReportePronosticosExternos: ", capturas);

    let promisesPronosticoProyeccion = []

    for (const index in invernaderos) {
        const invernadero = invernaderos[index];
        const proveedoresExternos = await obtenerProveedoresInvernadero(cicloId, invernadero.id);
        let resultsExternos = []
        for (const indexExt in proveedoresExternos) {
            const proveedor = proveedoresExternos[indexExt];
            resultsExternos = await obtenerPronosticoYProyeccionProveedorExterna(cicloId, invernadero, weekTime, proveedor)
        }

        console.log("PROVEEDORes: ", { invernadero, resultsExternos })
    }

    let pronosticosProyecciones = await Promise.all(promisesPronosticoProyeccion)
    let result = invernaderos.map((invernadero, index) => {
        let obj = {
            invernadero, capturas: capturas[index],
            pronosticosProyecciones: pronosticosProyecciones[index]
        }
        return obj
    })
    console.log("obtenerReportePronosticosExternos - ", result)
    return result;

}
/////////////////////OLD///////////////////


///////////////////////////////////////////
////////////////pron ext cards/////////////
export const obtenerDatosCardsExternos = async (invernaderosExternos) => {
    const arrayExternos = [];
    for (const index in invernaderosExternos) {
        //1. Pasar el invernadero externo al funcion obtenerDatosCardsProveedores(invernaderosExternos[index])
        const proveedoresDeInvernadero = await obtenerDatosCardsProveedores(invernaderosExternos[index]);
        //console.log("zzzz222; ", proveedoresDeInvernadero);

        /* const length = proveedoresDeInvernadero?.length || 0; */
        const lengths = proveedoresDeInvernadero.reduce((acc, prov) => {
            if (prov.semanaUno > 0) acc.semanaUnoLength = acc.semanaUnoLength + 1
            if (prov.semanaTres > 0) acc.semanaTresLength = acc.semanaTresLength + 1
            if (prov.semanaCinco > 0) acc.semanaCincoLength = acc.semanaCincoLength + 1
            return acc
        }, { semanaUnoLength: 0, semanaTresLength: 0, semanaCincoLength: 0 })


        //2. Return arregla de datos cards de proveedores
        const promedioProveedoresInvernadero = proveedoresDeInvernadero.reduce((acc, proveedor) => {
            const { semanaUno, semanaTres, semanaCinco, proyeccion } = proveedor
            acc = {
                semanaUno: acc.semanaUno + semanaUno,
                semanaTres: acc.semanaTres === "-" ? acc.semanaTres : acc.semanaTres + (semanaTres == "-" ? 0 : semanaTres),
                semanaCinco: acc.semanaCinco === "-" ? acc.semanaCinco : acc.semanaCinco + (semanaCinco == "-" ? 0 : semanaCinco),
                proyeccion: acc.proyeccion + proyeccion
            }
            return acc
        }, { semanaUno: 0, semanaTres: 0, semanaCinco: 0, proyeccion: 0 })

        const { semanaUno, semanaTres, semanaCinco, proyeccion } = promedioProveedoresInvernadero;
        const { semanaUnoLength, semanaTresLength, semanaCincoLength } = lengths;

        const arrayProveedoresProyeccionDatos = proveedoresDeInvernadero.map(el => el.datosPronosticoProyeccion)

        arrayExternos.push({
            semanaUno: dosDecimales(semanaUnoLength > 0 ? (semanaUno / semanaUnoLength) : 0),
            semanaTres: semanaTresLength > 0 ? (semanaTres / semanaTresLength) : 0,
            semanaCinco: semanaCincoLength > 0 ? (semanaCinco / semanaCincoLength) : 0,
            proyeccion: dosDecimales(semanaUnoLength > 0 ? (proyeccion / semanaUnoLength) : 0),
            invernadero: invernaderosExternos[index],
            arrayProveedoresProyeccionDatos
        })
        //3. Filtrar proveedores que no tienen datos (hacer console log, checar campos, si hay todo en 0 hay que filtrar). Esto es para no tener datos en 0 que va afectar el promedio total del invernadero externo
        //4. Generar promedio de los 4 campos
        //Tener cuidad que no hay valores en strings, utiliza parseFloat() cuando es necesario.
        //aqui////
    }
    console.log("arrayExternos zzzz333; ", arrayExternos);

    return arrayExternos;
    //setInvernaderosExternosPromedios(arrayExternos)
}

const obtenerDatosCardsProveedores = async (invernadero) => {
    try {
        let listaProveedores = await getProveedoresInvernadero(getGlobal().ciclo, invernadero.id)
        /* setProveedores(listaProveedores) */
        //console.log("PROVVVVV: ",listaProveedores)
        let promises = listaProveedores.map(proveedor => obtenerResumenExterno(proveedor, invernadero))
        let proveedoresCardsData = await Promise.all(promises)
        let proveedoraCopia = DeepClone(listaProveedores)
        //console.log("zzzz-1-1-1; ", proveedoresCardsData[0])
        for (let x = 0; x < proveedoresCardsData.length; x++) {
            if (!proveedoresCardsData[x]) proveedoraCopia[x] = false;
            else {
                let [invId, producto, variedad, semanaUno, semanaTres, semanaCinco, proyeccion, proveedor, datosPronosticoProyeccion] = proveedoresCardsData[x];
                let proveedorCard = proveedoraCopia.find(el => el.id === proveedor.id);

                proveedorCard.variedad = variedad;
                proveedorCard.producto = producto;
                proveedorCard.semanaUno = semanaUno;
                proveedorCard.semanaTres = semanaTres;
                proveedorCard.semanaCinco = semanaCinco;
                proveedorCard.proyeccion = proyeccion;
                proveedorCard.proveedor = proveedor
                proveedorCard.datosPronosticoProyeccion = datosPronosticoProyeccion
            }
        }
        //console.log("zzzz111; ", proveedoraCopia)
        proveedoraCopia = proveedoraCopia.filter(proveedorData => !!proveedorData); //Quitar los datos que tienen 'false'
        return proveedoraCopia
    } catch (err) {
        console.log(err)
        if (err.code && err.code === "permission-denied") {
            console.log(err.code);
            //setMostrarModalPermiso(true);
        }
    }
}
const obtenerResumenExterno = async (proveedor, invernadero) => {
    let pronosticoProyeccion = await obtenerPronosticoYProyeccionProveedorExterna(getGlobal().ciclo, invernadero, getGlobal().week.time, proveedor);
    if (!pronosticoProyeccion) { return false; }//checar si hay datos, y esconder si no hay
    //if (pronosticoProyeccion?.diario > 0 || pronosticoProyeccion?.semanal > 0) console.log("obtPron zzzz000; ", pronosticoProyeccion)
    let producto = getGlobal().productosYVariedades.find(prod => prod.id === invernadero.producto_ref)
    let variedad = producto?.variedades.find(vari => vari.id === invernadero.variedad_ref) || {}
    let semanaUno = dosDecimales(pronosticoProyeccion["semanaUno"] / parseInt(proveedor.area_invernadero))
    return [invernadero.id, producto, variedad, semanaUno, pronosticoProyeccion.semanaTres, pronosticoProyeccion.semanaCinco, pronosticoProyeccion.proyeccion, proveedor, pronosticoProyeccion];
}/* linea 345 */