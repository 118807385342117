import React,{useEffect,useGlobal,useState} from "reactn";
import { obtenerListaDeSemanas } from "../../service/fechas";
import {InputText} from "primereact/inputtext";
import {SelectButton} from 'primereact/selectbutton';
import {InputSwitch} from 'primereact/inputswitch';
import {Calendar} from 'primereact/calendar';
import {Dropdown} from 'primereact/dropdown';
import {Button} from 'primereact/button';
import { obtenerCiclo } from "../../service/Ciclos";
import {guardarBudget} from "../../service/Budget";
import moment from "moment";
import {Message} from "primereact/message";
export default  function Budget(props){
    const [ciclo,setCiclo] = useGlobal("ciclo");
    const [cicloObj,setCicloObj] = useState(null);

    const [inicio,setInicio] = useState();
    const [final,setFinal] = useState();
    const [options,setOptions] = useState([]);
    const [budget,setBudget] = useState([]);
    const [invernadero,setInvernadero] = useGlobal("invernadero");
    const [nombreModulo,setNombreModulo] = useGlobal("nombreModulo");
    const [error,setError] = useState(false);
    let [habilitadoSelectorCiclo,setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    useEffect(() => {
      setHabilitadoSelectorCiclo(false);
      return () => {
        setHabilitadoSelectorCiclo(true);
      }
    }, [])
    const atras = (params) => {
        props.history.goBack();
    }
    
    function guardar(){
        
        
        
        
        
        for(let x = 0;x<budget.length;x++){
            if(budget[x].value === ""){
                setError(true);
                return;
            }
        }
        let obj = {}
        if(inicio){
             obj = {
                semana_inicio_real:inicio.time,
                estimacion_semanal: budget
            }
        }
        else{
            
            
             obj = {
                semana_inicio_real:options[0].time,
                estimacion_semanal: budget
            }
        }
        
        guardarBudget(ciclo,invernadero.id,obj).then(resp=>{
            props.history.goBack();
            let copia = {...invernadero};
            copia["budget"] = obj;
            setInvernadero(copia);
                        
        }).catch(err=>{
            console.log("OCURRIO UN ERROR AL GUARDAR BUDGET");
            console.log(err);
        })

    }
    function cancelar(){
        props.history.goBack();
       }
    function handleInicio(ev){        
        setInicio(ev.value);
    }
    function handleFinal(ev){        
        setFinal(ev.value);
    }
    function handleBudget(ev,index){
        let copia = budget.slice();
        copia[index].value = ev.target.value;
        setBudget(copia); 
    }
    
    
    useEffect(()=>{
        if(ciclo    ){
            
            
             obtenerCiclo(ciclo).then(cicloObj=>{
                setCicloObj(cicloObj);    
                setBudget(obtenerListaDeSemanas(cicloObj.semana_inicio,cicloObj.semana_cierre).map(week=>{
                    return {
                        time:week.time,
                        nombre: week.nombre,
                        value:""
                    }
                }));
                setOptions(obtenerListaDeSemanas(cicloObj.semana_inicio,cicloObj.semana_cierre));
            })  
            
            //
            
        }
        
        


    },[ciclo])
    useEffect(()=>{
        
        
        
        if(ciclo  && inicio ){
            if(final){
                obtenerCiclo(ciclo).then(cicloObj=>{
                    setCicloObj(cicloObj);        
                    setBudget(obtenerListaDeSemanas(inicio.time,final.time+24*7*60*60).map(week=>{
                        return {
                            time:week.time,
                            nombre: week.nombre,
                            value:""
                        }
                    }));
                  
                })  
            }else{
                obtenerCiclo(ciclo).then(cicloObj=>{
                    setCicloObj(cicloObj);        
                    setBudget(obtenerListaDeSemanas(inicio.time,cicloObj.semana_cierre).map(week=>{
                        return {
                            time:week.time,
                            nombre: week.nombre,
                            value:""
                        }
                    }));
                  
                })  
            }
            
            
            
            
            //
            
        }else{
            if(ciclo&&final){                
                obtenerCiclo(ciclo).then(cicloObj=>{
                    setCicloObj(cicloObj);        
                    setBudget(obtenerListaDeSemanas(cicloObj.semana_inicio,final.time+24*7*60*60).map(week=>{
                        return {
                            time:week.time,
                            nombre: week.nombre,
                            value:""
                        }
                    }));
                  
                })  
            }
        }
    },[inicio,final])
    useEffect(()=>{
        setNombreModulo("Empaque");
      },[])
    return  <div className="p-grid p-justify-center">    

    <div className="p-col-12 title-container">
        <span className="back-btn" onClick = {atras}></span>
        <span className={`icon-general icon-id bor-${invernadero.color} bg-${invernadero.color} ${invernadero.habilitado?"":"Deshabilitado"}`}>{invernadero.nombre}</span>
        
        <span className="titles-group">
            
            
            <h1 className="header-title">
                Budget del ciclo
            </h1>
                    <p className="header-subtitle">
                        {invernadero.producto.nombre}-{invernadero.variedad.nombre}
                    </p>
            
        </span>
    </div>
    <div className="p-col-10">
        <div className="p-grid p-fluid ">
            <div className="p-col-12">
                
                <div className="card">
                    <div className="p-grid">
                        <div className="form-group p-col-12">
                            <label htmlFor="inicio">Semana de inicio del budget</label>
                            <Dropdown id="inicio" placeholder="Seleccionar"
                            options={options} optionLabel = "nombre"
                            dataKey = "time" onChange = {handleInicio}
                            value = {inicio}
                            />
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="p-grid">
                        <div className="form-group p-col-12">
                            <label htmlFor="inicio">Semana final del budget</label>
                            <Dropdown id="inicio" placeholder="Seleccionar"
                            options={options} optionLabel = "nombre"
                            dataKey = "time" onChange = {handleFinal}
                            value = {final}
                            />
                        </div>
                    </div>
                </div>
                <p className="section-title">Budget semanal</p>
                <div className="card">
                    {budget.map((week,index)=>{
                    return <div key = {index} className="form-group p-grid p-fluid pallet-dailyweight">
                        <div className="p-col-auto p-justify-end p-grid p-align-center m-0">
                            <label htmlFor={`budget_${week.time}`}>Semana {week.nombre}</label>
                        </div>
                        <div className="p-col">
                            <InputText id={week.time} value = { budget[index].value} onChange = {(ev)=>handleBudget(ev,index)}/>
                        </div>
                        <div className="p-col-auto p-justify-end p-grid p-align-center m-0">
                            <label >Kg/m²</label>
                        </div>
                        
                    </div>
                    })}
                    {error?<Message severity="error" text="Deben llenarse todos los campos" />:null}
   
                </div>
            </div>
        </div>
        <div className="p-grid p-justify-end btn-row">
            <Button label="Cancelar" className="p-button-secondary" onClick = {cancelar} />
            <Button label="Guardar"  onClick = {guardar}/>
        </div>
    </div>
</div>
}//250