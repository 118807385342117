import * as firebase from "firebase/app";
import "firebase/firestore";
import {uploadStatistics} from './Estadisticas'

function registrarInvocaciones(count){
    uploadStatistics(count)
}

export const obtenerLocaciones = async (params) => {
    const db = firebase.firestore();
    let locacionesSnap  = await db.collection("locaciones").get();
    registrarInvocaciones(locacionesSnap.size)
    return locacionesSnap.docs.map(el=>({...el.data(), id: el.id}))
}
export const getLocaciones = async () => {
    let locaciones  = await firebase.firestore().collection("locaciones").get().catch(error => {console.log(`Error al obtener locaciones: ${JSON.stringify(error)}`)})
    registrarInvocaciones(locaciones.size)
    return locaciones.docs.map(el=>({...el.data(), id: el.id}))
}
export const obtenerLocacionPorNombre = async (nombre) => {
    const db = firebase.firestore();
    let locacionesSnap  = await db.collection("locaciones").where("nombre","==",nombre).get();
    registrarInvocaciones(locacionesSnap.size)
    let doc =  locacionesSnap.docs[0];
    return {id:doc.id, ...doc.data()};
}
