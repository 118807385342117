import React, { useState, useEffect } from "react";
import Column from "components/common/cards/Column";
import { DataTable } from "primereact/datatable";
import { dosDeciComas, separadoComas } from "util/functions";
import { getMomentFormatted, getObjetoPorID, parseFloatHTG, sumarNumerosArrayPorCampo } from "../../../util/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const TablaExpanderManifiestos = (props) => {
  const { manifiestosData, manifiestosTodos, presentaciones, } = props;
  const [totalDeCajas, setTotalDeCajas] = useState(0);
  const [expandedRows, setExpandedRows] = useState(null)
  const [rowsItems, setRowsItems] = useState()

  useEffect(() => {
    const newTotalDeCajas = sumarNumerosArrayPorCampo(manifiestosData, "cajas_orden");
    const rows = procesarRowsPresentaciones();
    setRowsItems(rows);
    setTotalDeCajas(newTotalDeCajas);
  }, [manifiestosData]);

  const procesarRowsPresentaciones = () => {
    const presentacionIds = manifiestosData.map(el => el.presentacion_ref);
    const presentacionesUnicos = [...new Set(presentacionIds)];

    const rows = presentacionesUnicos?.map(presentacionId => {
      const presentacionObj = getObjetoPorID(presentaciones, presentacionId);
      const itemsDeEstePresentacion = manifiestosData?.filter(el => el.presentacion_ref === presentacionId) || [];
      const rowsItems = itemsDeEstePresentacion?.map(el => {
        const manifiestoObj = getObjetoPorID(manifiestosTodos, el.manifiestoId)
        return { ...el, fecha_envio: parseFloatHTG(manifiestoObj.fecha_envio), cajas_orden: dosDeciComas(el.cajas_orden) }
      })
      const cajasTotales = sumarNumerosArrayPorCampo(itemsDeEstePresentacion, "cajas_orden");
      return { ...presentacionObj, cajasTotales, rowsItems };
    }) || [];
    //console.log("ROWS::: ", { manifiestosData, rows, presentacionIds })
    return rows;
  }

  const rowExpansionTemplate = (datos) => {
    // console.log("datos expand ", datos)
    return (
      <DataTable value={datos.rowsItems}>
        {obtenerColumnasExpander()}
      </DataTable>)
  }
  const obtenerColumnasExpander = () => {
    const columnas = [
      <Column className="col__label rpd-expander-col-width" headerClassName="col__label invoice-expander-col-width" /* footer={null} */ />,
      <Column field="manifiesto" header="Manifiesto" footer={null} sortable={true} />,
      <Column field="fecha_envio" header="Fecha de envío" body={fechaFormattedBody} footer={null} sortable={true} />,
      <Column field="cajas_orden" header="Cajas De manifiesto" footer={null} sortable={true} />,
      <Column field="nombre_invernadero" header="Invernadero" footer={null} sortable={true} />,
      <Column field="id" body={bodyAcciones} className="body-acciones-col" headerClassName="body-acciones-col-header" footerClassName="h-100" />];
    return columnas;
  }
  const bodyAcciones = (rowData) => {
    return (
      <div className="body-acciones-icono">
        <button className="table-btn" onClick={() => props.setModalDesvincularItem(rowData)}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      </div>);
  }

  const fechaFormattedBody = (rowData, column) => <label>{getMomentFormatted(rowData[column.field] * 1000, "DD/MM/YYYY")}</label>;
  const footerBody = () => <span>Total de cajas: {separadoComas(totalDeCajas)}</span>;
  return (
    <DataTable value={rowsItems} className="" footer={footerBody()} rowExpansionTemplate={rowExpansionTemplate}
      expandedRows={expandedRows} onRowToggle={e => { setExpandedRows(e.data); }}  >
      <Column expander header="" className="col__label rpd-expander-col-width" headerClassName="col__label invoice-expander-col-width" footer={null} />
      <Column field="presentacion" header="Presentación" footer={null} />
      {/* <Column field="nombre_invernadero" header="Invernadero" footer={null} /> */}
      <Column field="cajasTotales" header="Cajas totales" footer={null} body={(row, col) => (col?.field && row[col.field]) ? dosDeciComas(row[col.field]) : 0} />
      {/*  <Column field="manifiesto" header="Manifiesto" footer={null} /> */}
    </DataTable>
  );
};
export default TablaExpanderManifiestos;