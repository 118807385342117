import React from 'react';
import { useState, useEffect, useGlobal, setGlobal } from 'reactn';
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import {
    obtenerFiltrosVentasNacionalesTodos, obtenerRegistrosEntradasPorSemanaYInverandero,
    getEntradaskgTotales, cambiarCiclo
} from "../../service/VentaNacional";

import ContenedorFiltro from '../common/ContenedorFiltro'
import ContenedorFiltroBusqueda from '../common/ContenedorFiltroBusqueda'
import SeccionFiltros from '../common/SeccionFiltros'
import ContenedorHeader from '../common/ContenedorHeader'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getMomentFormatted, getProductoYVariedadString } from '../../util/functions';
import { noHaSidoClausurado, esCicloActual } from '../../service/Ciclos';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import icono from '../../images/icons/external-icon.svg';

const TablaRegistrosEntradas = (props) => {
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [, setCargando] = useGlobal("cargando")
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [ciclo,] = useGlobal("ciclo");
    const [ciclos] = useGlobal("ciclos");
    const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector();
    const [datosCargados,] = useGlobal("datosCargadosVentaNacional")

    const [lineas, setLineas] = useState([])
    const [entradasSemanas, setEntradasSemanas] = useState([])

    //opciones y filtros
    const [invernaderosOpciones] = useGlobal("invernaderosVirtuales");
    const [productosYVariedades] = useGlobal("productosYVariedades")
    const [invernadero, setInvernadero] = useGlobal("invernadero");


    useEffect(() => {
        setNombreModulo("Ventas Nacionales");
        setHabilitadoSelectorCiclo(true)
    }, [])
    useEffect(() => {
        if (invernaderosOpciones)
            updateCicloWeekSelector(ciclo);
    }, [invernaderosOpciones])
    useEffect(() => {
        setCargando(true)

        if (!datosCargados && week.nombre != "Cargando")
            obtenerFiltrosVentasNacionalesTodos(ciclo)
        else if (datosCargados && week.nombre != "Cargando")
            obtenerDatosIniciales()
    }, [datosCargados, week, invernadero])

    const obtenerDatosIniciales = async () => {
        let entradasDeSemana = entradasSemanas.find(el => (el.week.time === week.time && el.id === invernadero.id))
        if (!entradasDeSemana) {
            let entradas = await obtenerRegistrosEntradasPorSemanaYInverandero(ciclo, week, invernadero.id)
            entradasDeSemana = { week, id: invernadero.id, entradas }
            setEntradasSemanas([...entradasSemanas, entradasDeSemana])
        }

        let lineas = procesarDatosTabla(entradasDeSemana)
        setLineas(lineas)
        setCargando(false)
    }
    const procesarDatosTabla = (entradasDeSemana) => {
        return entradasDeSemana.entradas.map(entrada => {
            let kgTotales = getEntradaskgTotales([entrada])

            let tipos = new Set(entrada.pallets.map(pallet => pallet.tipo))
            tipos = Array.from(tipos).sort((a, b) => a > b ? -1 : 1) //Utilizar sort para siempre mostrar "linea + arrastre", 
            let tipoString = tipos.join(" + ")

            return {
                id: entrada.id,
                fecha: entrada.dia.time,
                cantidad: entrada.pallets.length,
                kgTotales,
                tipoString
            }
        })
    }

    const puedeRegistrar = () => invernadero && (invernadero.habilitado || invernadero.es_externo) && noHaSidoClausurado(ciclo, ciclos)
    const irRegestroEntrada = (ev) => props.history.push(`/ventas_nacionales/registro_entrada/${invernadero.id}/modificar/${ev.data.id}`)
    return (
        <div className="p-grid p-justify-center">
            <ContenedorHeader col="p-col p-col-auto" titulo="Reporte de entrada de ventas nacionales" subtituloInv={getProductoYVariedadString(invernadero)}
                invernaderoNombre={invernadero?.es_externo ? <img width="60" alt="icono" src={icono} /> : invernadero?.nombre} atras={props.history.goBack}
                iconos={`icon-general icon-lort icon-id bor-${invernadero?.color} bg-${invernadero?.color} ${invernadero?.habilitado ? "" : "Deshabilitado"}`} />

            <div className="p-col p-col-auto">
                <div className="btn-row">
                    {puedeRegistrar() && <Button className="reactbtn-icon" onClick={() => props.history.push("/ventas_nacionales/registro_entrada/crear")} label="Crear nuevo registro de entrada" />}
                </div>
            </div>

            <SeccionFiltros >
                <ContenedorFiltro label="invernadero">
                    <Dropdown id="filtroInvernadero" placeholder="Seleccionar"
                        dataKey="id" optionLabel="nombre"
                        options={invernaderosOpciones} value={invernadero}
                        onChange={(e) => setInvernadero(e.target.value)} />
                </ContenedorFiltro>

                <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
            </SeccionFiltros>

            <div className="p-col-12">
                <div className="p-grid p-fluid ">
                    <div className="p-col-12">
                        <div className="p-col p-col-auto">
                            <div className="card p-0">
                                <DataTable selectionMode="single" value={lineas} onRowClick={irRegestroEntrada} emptyMessage="No hay resultados" sortable={true}>
                                    <Column field="fecha" header="Fecha" sortable={true} body={(rowData) => <div>{getMomentFormatted(rowData.fecha * 1000, "DD/MM/YYYY")}</div>} />
                                    <Column field="cantidad" header="Cantidad de pallets pesados" sortable={true} />
                                    <Column field="kgTotales" header="Total KGS Registrados" sortable={true} />
                                    <Column field="tipoString" header="Línea/arrastre" sortable={true} />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}
export default TablaRegistrosEntradas;//143