
import { Card } from 'primereact/card';
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { obtenerEmpaquesCiclo } from '../../../service/Empaques';
import { dosDecimales, separadoComas } from '../../../util/functions';
import DatoCard from '../../common/cards/DatoCard';
import HTGCard from '../../common/cards/HTGCard';
import Column from '../../common/cards/Column';




const EmpaqueCard = (props) => {
  const [presetEmpaque, setPresetEmpaque] = useGlobal("presetEmpaque");
  const [ciclo, setCiclo] = useGlobal("ciclo");
  const consultarEmpaque = (params) => {
    props.empaque.ciclo_ref = ciclo;
    setPresetEmpaque(props.empaque);
    props.history.push("/admin/empaques/crear")
  }
  const header = <span className="card-header">
    <div className={`card-id icon-general icon-id  `}>{props.empaque.nombre}</div>
    <div><p className="card-up">{props.empaque.habilitado ? "" : "Deshabilitado"}</p></div>
  </span>
  let className = `htg-card bor- ${props.empaque.habilitado ? "Habilitado" : "Deshabilitado"}`;
  return <HTGCard headerClassName={className} header={header} onClick={consultarEmpaque}>
    <Column>
      <DatoCard label="Cantidad de empleados"
        valor={separadoComas(Math.round(props.empaque.empleados))} />
      <DatoCard label="Productos relacionados"
        valor={dosDecimales(props.empaque.productos_relacionados.length)} />
    </Column>
    <Column>
      <DatoCard label="Tiene empacadora automática?"
        valor={props.empaque.empacadora_automatica ? "Sí" : "No"} />
    </Column>
  </HTGCard>
}
export default withRouter(EmpaqueCard);
