import { setGlobal, getGlobal } from "reactn"
import * as firebase from "firebase/app";
import "firebase/firestore";
import { uploadStatistics } from './Estadisticas'
import { getObjetoPorID } from "../util/functions";
import { LORT } from "../constants";

function registrarInvocaciones(count) {
    uploadStatistics(count)
}

export function obtenerEmpresas() {
    var db = firebase.firestore();

    return new Promise((resolve, reject) => {
        let results = [];
        db.collection("empresas").get().then(snap => {
            registrarInvocaciones(snap.size)
            snap.forEach(doc => {
                let data = doc.data();
                data.id = doc.id;
                results.push(data);
            });
            resolve(results);
        }).catch(err => {
            reject(err);
        });
    })
}
export const getEmpresas = async () => {
    let response = await firebase.firestore().collection("empresas").get().catch(error => { console.log(`Error al obtener empresas: ${JSON.stringify(error)}`) })
    registrarInvocaciones(response.size)
    let empresas = (getGlobal().empresas && getGlobal().empresas.length > 0) ? getGlobal().empresas : response.docs.map(el => ({ ...el.data(), id: el.id }))
    setGlobal({ empresas })
    return empresas
}
export const getEmpresasInternos = async () => {
    let empresas = await getEmpresas()
    return empresas.filter(empresa => empresa.nombre !== "LORT")
}
export const filtrarEmpresasInternos = (empresasLista = []) => {
    return empresasLista.filter(empresa => empresa.nombre !== "LORT")
}
export function obtenerEmpresa(id) {
    var db = firebase.firestore();
    return new Promise((resolve, reject) => {
        db.collection("empresas").doc(id).get().then(snap => {
            registrarInvocaciones(1)
            let data = snap.data();
            data.id = snap.id;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })

}
export const getEmpresa = async (empresaID) => {
    let empresa = await firebase.firestore().collection("empresas").doc(empresaID).get().catch(error => { console.log(`Error al obtener empresa doc ${empresaID}: ${JSON.stringify(error)}`) })
    registrarInvocaciones(1)
    return empresa.exists ? empresa.data() : {}
}

export const getNombreEmpresaPorInvernaderoVirtual = async (invernadero) => {
    if (!invernadero) { return ""; }
    else if (invernadero.es_externo) { return LORT }

    const { invernaderosFisicos } = getGlobal();
    const invernaderoFisico = getObjetoPorID(invernaderosFisicos, invernadero.invernadero_fisico_ref);
    const empresa = await obtenerEmpresa(invernaderoFisico.empresa_ref);
    return empresa?.nombre || "";
}