import React, { useGlobal } from "reactn";
import { usePrimeReactTable } from "../common/tableHandler"; import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { nombresDiasSemanaExtendida } from "../../constants";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { dosDecimales, separadoComas } from '../../util/functions';
import { Button } from 'primereact/button';
import { hanPasadoHoras, obtenerDiasDeSemana, proximoLunes } from '../../service/fechas';
import { esCicloActual, noHaSidoClausurado } from "../../service/Ciclos";
export default function TablaCultivo(props) {
    let [usuario, setUsuario] = useGlobal("usuario");
    let [ciclo,] = useGlobal("ciclo");
    let [ciclos,] = useGlobal("ciclos");
    let [invernadero,] = useGlobal("invernadero");
    const [editorGeneratorCultivo, currentAsignacion] = usePrimeReactTable(props.inputs, () => { });
    function unidadTemplate(rowData, column) {

        return <div>{separadoComas(rowData[column["field"]])}<span className="label-unidad">{rowData["unidades"]}</span> </div>;

    }
    const puedeEditar = (params) => {
        return invernadero && invernadero.habilitado &&
            ["Administrador", "Personal Administrativo", "Grower", "Auxiliar Grower"].includes(usuario.rol)
    }

    const templateNombre = (rowData, column) => {
        //console.log("TOOLTIP REGISTRO: ", rowData["tooltip_registro"])
        if (rowData["tooltip_registro"] === "") {
            return <div>{rowData["nombre"]} </div>;
        } else {
            return <div>{rowData["nombre"]} <Button className="btn-line btn-help" type="button" label="?" tooltip={rowData["tooltip_registro"]} /></div>;
        }

    }
    const esFechaValida = (dia) => {
        let indexDia = dia;
        let diasSemana = obtenerDiasDeSemana(props.semana);
        dia = diasSemana[dia];
        let actual = Math.round(Date.now() / 1000);

        return noHaSidoClausurado(ciclo, ciclos) && ((usuario && !usuario.bloqueado) ||
            (indexDia != 5 && !hanPasadoHoras(dia, actual, 2) || indexDia === 5 && !hanPasadoHoras(dia, actual, 3)));
    }

    const obtenerColumnas = (params) => {
        // 
        // 
        let cols = [];
        cols.push(<Column key={props.invernadero?.id || "und" + Date.now()} field="nombre" header="Medición" className="col__label" body={templateNombre} />);
        let dias = nombresDiasSemanaExtendida;

        if (props.tipo === "cultivo") {
            dias = dias.slice(0, 1);
        }
        
        dias.map((dia, index) => {
            //  
            //  
            //  
            cols.push(<Column key={index + 1} field={dia} header={dias.length === 1 ? "Semanal" : dia} editor={(props) => { return (esFechaValida(dias.indexOf(dia)) && puedeEditar()) ? editorGeneratorCultivo(props, dia) : <div>No editable</div> }}
                body={unidadTemplate} />)
        })
        return cols;
    }
    const obtenerFooter = () => {
        if (props.tipo === "cultivo") {
            let footerGroup = <ColumnGroup>
                <Row>
                    <Column footer="Peso Promedio de Fruta" colSpan={1} />
                    <Column footer={<div>{isNaN(props.outputs[0]) ? "-" : dosDecimales(1000 * props.outputs[0])}<span className="label-unidad">gr</span></div>} colSpan={1} />
                </Row>
                <Row>
                    <Column footer="Producción semanal" colSpan={1} />
                    <Column footer={<div>{dosDecimales(props.outputs[1])}<span className="label-unidad">kg/m²</span></div>} colSpan={1} />
                </Row>
                <Row>
                    <Column footer="Producción acumulado" colSpan={1} />
                    <Column footer={<div>{dosDecimales(props.outputs[2])}<span className="label-unidad">kg/m²</span></div>} colSpan={1} />
                </Row>
            </ColumnGroup>;
            return footerGroup;
        } else if (props.tipo === "riego") {

            let riego = props.inputs;
            let dias = nombresDiasSemanaExtendida;
            let drenajes = riego.find(el => el.nombre === "% Drenaje")
            let dosiss = riego.find(el => el.nombre === "Dosis");


            let radiacionAcumulada = props.datosCompletos["exterior"].find(el => el.nombre === "Radiación acumulada -24 horas");
            let proporcionRiego = dias.map(dia => {
                let radiacionAcumuladaDia = parseFloat(radiacionAcumulada[dia]);
                let dosis = parseFloat(dosiss[dia]);

                if (!isNaN(radiacionAcumuladaDia) && radiacionAcumuladaDia > 0 && !isNaN(dosis)) {
                    return (1000 * dosis) / radiacionAcumuladaDia
                } else {
                    return 0
                }
            })
            let consumo = dias.map(dia => {
                let drenaje = parseFloat(drenajes[dia]);
                let dosis = parseFloat(dosiss[dia]);
                if (!isNaN(drenaje) && !isNaN(dosis)) {
                    return dosis * (1 - drenaje / 100.0)
                } else {
                    return 0
                }
            })

            let footerGroup = <ColumnGroup>
                {!usuario.isRolFinanzas &&
                    <Row>
                        <Column footer="Proporción riego" colSpan={1} />
                        <Column footer={<div>{dosDecimales(proporcionRiego[0])}<span className="label-unidad">ml/J</span></div>} colSpan={1} />
                        <Column footer={<div>{dosDecimales(proporcionRiego[1])}<span className="label-unidad">ml/J</span></div>} colSpan={1} />
                        <Column footer={<div>{dosDecimales(proporcionRiego[2])}<span className="label-unidad">ml/J</span></div>} colSpan={1} />
                        <Column footer={<div>{dosDecimales(proporcionRiego[3])}<span className="label-unidad">ml/J</span></div>} colSpan={1} />
                        <Column footer={<div>{dosDecimales(proporcionRiego[4])}<span className="label-unidad">ml/J</span></div>} colSpan={1} />
                        <Column footer={<div>{dosDecimales(proporcionRiego[5])}<span className="label-unidad">ml/J</span></div>} colSpan={1} />
                        <Column footer={<div>{dosDecimales(proporcionRiego[6])}<span className="label-unidad">ml/J</span></div>} colSpan={1} />
                    </Row>}
                <Row>
                    <Column footer="Consumo" colSpan={1} />
                    <Column footer={<div>{dosDecimales(consumo[0])}<span className="label-unidad">l/m2</span></div>} colSpan={1} />
                    <Column footer={<div>{dosDecimales(consumo[1])}<span className="label-unidad">l/m2</span></div>} colSpan={1} />
                    <Column footer={<div>{dosDecimales(consumo[2])}<span className="label-unidad">l/m2</span></div>} colSpan={1} />
                    <Column footer={<div>{dosDecimales(consumo[3])}<span className="label-unidad">l/m2</span></div>} colSpan={1} />
                    <Column footer={<div>{dosDecimales(consumo[4])}<span className="label-unidad">l/m2</span></div>} colSpan={1} />
                    <Column footer={<div>{dosDecimales(consumo[5])}<span className="label-unidad">l/m2</span></div>} colSpan={1} />
                    <Column footer={<div>{dosDecimales(consumo[6])}<span className="label-unidad">l/m2</span></div>} colSpan={1} />
                </Row>

            </ColumnGroup>;
            return footerGroup;
        } else {
            return null;
        }


    }
    return <React.Fragment>
        <DataTable value={props.inputs} footerColumnGroup={obtenerFooter()}>
            {obtenerColumnas()}
        </DataTable>
    </React.Fragment>
}