import React,{useEffect, useGlobal, useState} from "reactn";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {Button} from 'primereact/button';
import {MultiSelect} from "primereact/multiselect";
import { obtenerSnapshotPrevio, obtenerSnapshotPrevioNuevo } from "../../service/nomina";
import {obtenerActividades } from "../../service/actividades";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faChartBar} from '@fortawesome/free-solid-svg-icons';
import {separadoComas,currency, dosDecimales} from "../../util/functions";
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import ContenedorPrincipal from "../common/ContenedorPrincipal";
import ContenedorHeaderIconoInvernadero from "./ContenedorHeaderIcono";
import ContenedorBotonesTop from "../common/ContenedorBotonesTop";
import RowBotonesTop from "../common/RowBotonesTop";
import SeccionFiltros from "../common/SeccionFiltros";
import ContenedorFiltro from "../common/ContenedorFiltro";
import ContenedorTablaAdmin from "../common/ContenedorTablaAdmin";
import { obtenerDatosBase } from "./ConsultarCtrl";
import { updateDatosScript } from "./SnapshotsScripts";


function Consultar(props) {

    const [,setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [,setNombreModulo] = useGlobal("nombreModulo");
    const [, setCargando] = useGlobal("cargando");
    const [actividades,setActividades] = useState([]);
    const [,setCuentaEmpleados] = useGlobal("cuentaEmpleados");
    const [empleadosSemana, setEmpleadosSemana] = React.useState([])
    const [invernaderos] = React.useGlobal("invernaderosInternos");
    const [invernadero,setInvernadero] = useGlobal("invernadero");
    const [ciclo] = useGlobal("ciclo");
    const [weeks,week,handleWeekChange] = useWeekSelector();
    
    const [globalFilter,setGlobalFilter]  = useState("");
    const [actividadesSemana, setActividadesSemana] = useState([])
    const [variedad,setVariedad] = useState({});
    const [filtroActividades, setFiltroActividades] = useState([]);
    const [footer, setFooter] = useState([]);

    const [esCapturado, setEsCapturado] = useState(false)

    useEffect(() => {
        setNombreModulo("Producción")
        setHabilitadoSelectorCiclo(true);
    }, [])

    useEffect(() => {
        if(ciclo && invernadero){ 
            getInitialData(); 
            //updateCicloWeekSelector(ciclo);
        }
        else props.history.push("/nomina");
    }, [ciclo])    
    

    useEffect( ()=>{        
        if(ciclo && invernadero && actividades.length > 0){ updateDatos(); }
    },[ciclo, invernadero, week, actividades])

    useEffect(() => {
        if(actividadesSemana) { 
            const actsFiltrados = obtenerActividadesFiltro()
            setFiltroActividades(actsFiltrados); 
        }
    }, [actividadesSemana])

    const getInitialData = ()=> { 
        setCargando(true);
        obtenerActividades().then(actividades=>{
            setActividades(actividades);                        
        }).catch(err=>{
            console.log("ERROR GID: ", err);
        })
        setCargando(false);       
    }
    const updateDatos = async (params) => {
            setEsCapturado(false);
            setCargando(true);

            let snapshotPrevio = await obtenerSnapshotPrevioNuevo(ciclo, invernadero.id, week.time);
            
            if(snapshotPrevio?.exists){
                setEsCapturado(true);
                setEmpleadosSemana(snapshotPrevio.empleadosSemana);
                setFooter(snapshotPrevio.footer);
                setActividadesSemana(snapshotPrevio.actividadesSemana);
            }else{
                let {cuentaEmpleados,empleadosSemana, footer, variedad, actividadesSemana} = await obtenerDatosBase(ciclo, invernadero, week.time, actividades);
                setVariedad(variedad);
                setActividadesSemana(actividadesSemana);
                setCuentaEmpleados(cuentaEmpleados);                            
                setEmpleadosSemana(empleadosSemana);
                setFooter(footer);
            }    
            setCargando(false);
    }
    
    const filtrarColumnas = () => {
        let filtradas = actividadesSemana.filter(actividad=>{
            const found = filtroActividades?.find(el=>el.id === actividad);
            return (found || actividad === "empleado")
        })
        return filtradas;
    }
    
    function obtenerColumnas(){
        let columnas = filtrarColumnas();

        columnas.push("total");
        columnas.push("bono");
        let colExtras =["empleado","bono","total"]
        let headers = {
            total:"Bono semanal",
            bono:"Total + Bono%"
        }
        return columnas.map((actividad,i)=>{ 
            let header = actividad;
            if(headers[actividad]){ header = headers[actividad]; }                                                                  
            return <Column key ={i} field = {actividad} 
                header = {(actividades&& actividad!=="empleado" && actividad !=="total" && actividad !=="bono")?actividades.find(el=>actividad===el.id).nombre:actividad==="empleado"?"Empleado":header} 
                sortable = {true} sortField = {colExtras.includes(actividad)?actividad:`orig_${actividad}`}body={formatCell}/>
        })
    }
    function obtenerColumnasFooter(){
        let columnas = filtrarColumnas();
        
        columnas.push("total");
        columnas.push("bono");
        let headers = {
            total:"Bono semanal",
            bono:"Total + Bono%"
        }

        return columnas.map((actividad,i)=>{      
            let header = actividad;
            if(headers[actividad]){ header = headers[actividad]; }                                                                                                                                    
            return <Column key ={i} field = {actividad} 
                header = {(actividades && actividad!=="empleado" && actividad !=="total" && actividad !=="bono")?actividades.find(el=>actividad===el.id).nombre:actividad==="empleado"?"":header} 
                sortable = {false} body={formatCell}/>
        })
    }
 
      function formatCell(rowData, column){
          if(rowData?.tipo === "porcentaje"){
            if(rowData[column.field] === "Porcentaje"){ return rowData[column.field]; }
            if(rowData[column.field] > 100){ return <div className = "excedido"> {`${rowData?.[column?.field] == null ? "-" : rowData[column.field]}%`}</div>; }
            else{return `${rowData?.[column?.field] == null ? "0" : rowData[column.field]}%`}
          }
          if(rowData[column.field] === 0 ||  !rowData[column.field]){ return ""; }
          if(column.field === "empleado"){
            const field = rowData.novato ? <span>{rowData.empleado} <span className='novato-label'> (novato)</span> </span> : rowData.empleado;
            return field;
          }
          else{
            let data = rowData[column.field];
            
            if(typeof data === "string"){
                if(column.field === "bono" || column.field === "total"){ return currency(dosDecimales(data)); }

                let [units,curr] = data.split("/")
                units = separadoComas(parseFloat(units));
                curr = currency(curr);
                return `${units} / ${curr}`;
            }
            else{ return currency(data); }
          }
          //return rowData[column.field];
      }

      const obtenerActividadesFiltro = () => {
          return actividades?.filter(actividad=>{
              const found = actividadesSemana.find(el=>el===actividad.id);
              return found ? true : false;
          }) || []
      }

    const calcularLineasTotales = (invernadero) => {
        let asignacion_lineas = invernadero["asignacion_lineas"];
        let total = asignacion_lineas.reduce((acc,curr) => {
          let dato = curr["totales"].split(" ")[0]
          if(dato){
            dato = parseInt(dato);
            if(!isNaN(dato)){
              return acc+dato;
            }else{
              return acc;
            }
          }
        },0)
    
         return total
     }

     const irIncidencias = () => { props.history.push("/nomina/incidencias"); }
     const irGraficaPorActividad = () => { props.history.push("/nomina/graficas/empleados_actividad"); }
     const irGraficaDesglose = () => { props.history.push("/nomina/graficas/desglose_porcentaje_actividades"); }
     const irGraficaBonosHoras = () => { props.history.push("/nomina/graficas/bono_horas"); }
     
    return (
        <ContenedorPrincipal extras = "p-justify-center">
            {/* Título */}
            <ContenedorHeaderIconoInvernadero atras = {props.history.goBack}  editing = {true} invernadero = {invernadero} identificador = "Producción semanal" col="p-col p-col-auto"/>
            <ContenedorBotonesTop>            
                <RowBotonesTop>                  
                  <Button onClick = {irIncidencias} label="Resumen de incidencias" />
                  <Button className="reactbtn-icon" onClick = {irGraficaPorActividad} label="Actividades por empleado">
                    <FontAwesomeIcon icon={faChartBar} />
                  </Button>
                  <Button className="reactbtn-icon" onClick = {irGraficaDesglose} label="Desglose de actividades">
                    <FontAwesomeIcon icon={faChartBar} />
                  </Button>
                  <Button className="reactbtn-icon" onClick = {irGraficaBonosHoras} label="Bonos por horas">
                    <FontAwesomeIcon icon={faChartBar} />
                  </Button>
                </RowBotonesTop>
            </ContenedorBotonesTop>

        <SeccionFiltros>            
            <div className="search-field form-group p-col">
            <FontAwesomeIcon icon={faSearch} /> 
            <InputText type="text" value = {globalFilter} onChange = {(e=>{setGlobalFilter(e.target.value)})}  placeholder="Búsqueda por empleado" />
            </div>
            <ContenedorFiltro label = "Invernadero">                      
                <Dropdown id="filtroInvernadero" placeholder="Seleccionar"
                dataKey="id" optionLabel="nombre"
                options={invernaderos} value={invernadero}
                onChange={(e) => { setInvernadero(e.target.value) }} />
            </ContenedorFiltro>
            <WeekSelector weeks = {weeks} handleWeekChange = {handleWeekChange} week = {week}/>                       
            <ContenedorFiltro label = "Actividades" >                        
                <MultiSelect value={filtroActividades} options={obtenerActividadesFiltro()} onChange={(e) => setFiltroActividades(e.value)} 
                placeholder = {`${filtroActividades?.length}/${obtenerActividadesFiltro().length} actividades seleccionadas`}
                fixedPlaceholder={ true } optionLabel="nombre" dataKey="id" />
            </ContenedorFiltro>
        </SeccionFiltros>
           
        {/* Tabla de consulta */}
        {esCapturado ? "ES DATO CAPTURADO" : ""}
        <ContenedorTablaAdmin>
            {(actividadesSemana &&(actividadesSemana?.length > 1))?
                <React.Fragment>
                    <div className="card p-0 card-table-pag">
                        <p className="section-title">Totales de producción semanal</p>
                            <DataTable value = {footer}  className="tabla-nomina-totales" >
                                {obtenerColumnasFooter()}
                            </DataTable>
                    </div>

                    <div className="card p-0 card-table-pag">
                        <DataTable value = {empleadosSemana} globalFilter={globalFilter} className="tabla-empleados"
                            rowClassName = {(rowData) => {                                            
                                return {'ausente' : rowData.falta, "inactivo":!rowData.activo, "novato":rowData.novato }
                            }}
                            scrollable={true} scrollHeight="400px">
                         {obtenerColumnas()}
                        </DataTable>
                    </div>
                </React.Fragment>
            :
                <div className="card text-center">
                    <p>No hay actividades planeadas esta semana</p>
                </div>
            }        
                        
            </ContenedorTablaAdmin>    
        </ContenedorPrincipal>
    );
}
export default Consultar;