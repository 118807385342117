import React from 'react'

export default function ContenedorTablaAdmin(props) {
    return (
        <div className="p-col-12">
            <div className="p-grid p-fluid ">
                <div className="p-col-12">
                    {props.titulo && <p className="section-title">{props.titulo}</p>}
                    {props.children}
                </div>
            </div>
        </div>
    )
}