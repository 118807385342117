import React, { useEffect } from 'react';
import { useGlobal, useState } from "reactn";

import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import {
    getPuntosDeCarga_orderBy_nombre, getEmpresas_orderBy_nombre, getClientes_from_Ciclo_orderBy_nombreComun,
    getTransportistas_orderBy_nombre, getFronteras_orderBy_nombre, getDestinos_orderBy_nombre,
    getEstadosEmbarque_orderBy_orden, getRegistroEmbarques_forFilters, getRegistroEmbarques_FinalizedFiltered, getFechaEstimadaDeLlegadaDeEmbarque, getFechaDespachado,
    getFechaEstimadaDeLlegadaDeEmbarqueUnix, timerIniciado, isOnHold, aplicanRetrasos,
    getAgentesAduanales_tipoMexicano_orderBy_nombre, getAgentesAduanales_tipoAmericano_orderBy_nombre
} from '../../service/embarques';
import { dateToUnixTimeAsNumber } from '../../util/functions';
import { obtenerNombreFechaCompactoDate } from '../../service/fechas';
import { CALENDAR_LOCALE_ES_PRIME_TRADUCTION, MODULO_EMBARQUES_NOMBRE } from '../../util/constants';
import { obtenerClientes } from '../../service/Clientes';
import { obtenerManifiestosEmbarque } from '../../service/QueriesManifiestos';
import ContenedorHeader from '../common/ContenedorHeader';


function ConsultarEmbarques(props) {

    // datos iniciales
    const [cargando, setCargando] = useGlobal("cargando")
    const [filtroImportadores, setFiltroImportadores] = useState([]);
    const [cicloId, setCicloId] = useGlobal("ciclo")
    const [usuario, setUsuario] = useGlobal("usuario")
    const [presetImportador, setPresetImportador] = useGlobal("presetImportador")
    const [nombreModulo, setNombreModulo] = useGlobal("nombreModulo");
    const [puntosCarga, setpuntosCarga] = React.useState([])
    const [productores, setProductores] = React.useState([])
    const [importadores, setImportadores] = React.useState([])
    const [estadosEmbarque, setEstadosEmbarque] = React.useState([])
    const [transportistas, setTransportistas] = React.useState([])
    const [fronteras, setFronteras] = React.useState([])
    const [destinos, setDestinos] = React.useState([])
    const [agentesAduanalesMexicanos, setAgentesAduanalesMexicanos] = React.useState([])
    const [agentesAduanalesAmericanos, setAgentesAduanalesAmericanos] = React.useState([])

    // filtros
    const [estadoEmbarqueSeleccionado, setEstadoEmbarqueSeleccionado] = React.useState({})
    const [estadosEmbarqueSeleccionado, setEstadosEmbarqueSeleccionado] = React.useState([])
    const [finalized, setFinalized] = React.useState(false)
    const [rangoFecha, setRangoFecha] = React.useState([new Date(Date.now() - 1209600000), new Date()])
    const [fechaDespacho, setFechaDespacho] = React.useState("")
    const [fechaLlegada, setFechaLlegada] = React.useState("")
    const [datosTablaFiltrados, setDatosTablaFiltrados] = React.useState([])

    // tabla
    var [globalFilter, setGlobalFilter] = React.useState("")
    var [datosTabla, setDatosTabla] = React.useState([])

    // timers
    var [currentUnixTimeDate, setCurrentUnixTimeDate] = React.useState(0)

    useEffect(() => {

        if (presetImportador && importadores.length > 0) {
            // 
            // 
            // 

            let filtro = [];
            if (presetImportador === "OTROS") {
                filtro = importadores.filter(importador => !importador.mayoritario);
            } else {
                filtro = importadores.filter(importador => importador.id === presetImportador);
            }
            // 
            setPresetImportador(null);
            setFiltroImportadores(filtro);
        }
    }, [presetImportador, importadores]);

    React.useEffect(() => {
        setNombreModulo(MODULO_EMBARQUES_NOMBRE)
        if (typeof cicloId === 'undefined') return
        getInitialData()
    }, [cicloId]);

    React.useEffect(() => {
        if (productores.length === 0
            || importadores.length === 0
            || estadosEmbarque.length === 0
            || fronteras.length === 0
            || destinos.length === 0
            || !estadoEmbarqueSeleccionado.id
            || agentesAduanalesMexicanos.length === 0
            || agentesAduanalesAmericanos.length === 0
        ) {
            return
        }

        generarTabla()
    }, [productores, importadores, estadosEmbarque, fronteras, destinos, estadoEmbarqueSeleccionado, estadosEmbarqueSeleccionado, agentesAduanalesMexicanos, agentesAduanalesAmericanos]);

    React.useEffect(() => {
        let includesFinalized = false
        estadosEmbarqueSeleccionado.forEach(estado => {
            if (estado.nombre === "Finalizado") {
                includesFinalized = true;
                setFechaLlegada("");
            }
        })
        setFinalized(includesFinalized)
    }, [estadosEmbarqueSeleccionado]);

    React.useEffect(() => {
        if (finalized && rangoFecha[0] && rangoFecha[1]) {
            //window.alert(dateToUnixTimeAsNumber(rangoFecha[0] || new Date()) + " -- " + dateToUnixTimeAsNumber(rangoFecha[1] || new Date()))
            generarTabla()
        }
    }, [rangoFecha]);

    React.useEffect(() => {
        if (typeof cicloId === 'undefined') return
        // 
    }, [currentUnixTimeDate]);

    React.useEffect(() => {
        if (typeof cicloId === 'undefined') return
        if (typeof datosTabla === 'undefined') return
        if (!datosTabla) return;
        let filtradoFechas = aplicarFiltrosDeFechas();
        // 
        // 
        let filtradoImportadores = aplicarFiltroImportadores(filtradoFechas);
        setDatosTablaFiltrados(filtradoImportadores);
    }, [fechaDespacho, fechaLlegada, datosTabla, filtroImportadores]);


    async function getInitialData() {
        setCargando(true)
        iniciarTimersEmbarques()
        var estadosEmbarqueIniciales = []
        var productoresIniciales = []

        let [
            puntosCarga,
            empresas,
            clientesEnCiclo,
            estadosEmbarque,
            transportistas,
            fronteras,
            destinos,
            agentesAduanalesMexicanos,
            agentesAduanalesAmericanos
        ] = await Promise.all([
            getPuntosDeCarga_orderBy_nombre(),
            getEmpresas_orderBy_nombre(),
            getClientes_from_Ciclo_orderBy_nombreComun(cicloId),
            getEstadosEmbarque_orderBy_orden(),
            getTransportistas_orderBy_nombre(),
            getFronteras_orderBy_nombre(),
            getDestinos_orderBy_nombre(),
            getAgentesAduanales_tipoMexicano_orderBy_nombre(),
            getAgentesAduanales_tipoAmericano_orderBy_nombre()
        ]);

        setpuntosCarga(puntosCarga)
        productoresIniciales = empresas; setProductores(empresas)
        setImportadores(clientesEnCiclo); setFiltroImportadores(clientesEnCiclo)
        estadosEmbarqueIniciales = estadosEmbarque; setEstadosEmbarque(estadosEmbarque)
        setEstadosEmbarqueSeleccionado(estadosEmbarque.filter(estado => estado.nombre !== "Finalizado"))

        setTransportistas(transportistas)
        setFronteras(fronteras)
        setDestinos(destinos)
        setAgentesAduanalesMexicanos(agentesAduanalesMexicanos)
        setAgentesAduanalesAmericanos(agentesAduanalesAmericanos)

        if (estadosEmbarqueIniciales.length > 0) setEstadoEmbarqueSeleccionado(estadosEmbarqueIniciales[0]);
        setCargando(false)
    }

    async function generarTabla() {
        try {
            setCargando(true)
            let registrosEmbarques = []
            for (let x = 0; x < estadosEmbarqueSeleccionado.length; x++) {
                let registrosEmbarquesDocs = undefined
                if (estadosEmbarqueSeleccionado[x].nombre === "Finalizado" && rangoFecha[0] && rangoFecha[1])
                    registrosEmbarquesDocs = await getRegistroEmbarques_FinalizedFiltered(estadosEmbarqueSeleccionado[x].id, cicloId, dateToUnixTimeAsNumber(rangoFecha[0]), dateToUnixTimeAsNumber(rangoFecha[1]) + 86400)
                else
                    registrosEmbarquesDocs = await getRegistroEmbarques_forFilters(estadosEmbarqueSeleccionado[x].id, cicloId)

                if (registrosEmbarquesDocs)
                    registrosEmbarques.push(...registrosEmbarquesDocs)
            }
            formatearRegistroEmbarquesParaTabla(registrosEmbarques)
        } catch (error) {
            console.log("ERROR GENERAR TABLA");
            console.log(error);
        } finally {
            setCargando(false);
        }

    }
    /*
        async function getRegistroEmbarquesFinalizedFiltered() {
    
        }*/

    async function formatearRegistroEmbarquesParaTabla(registroEmbarques) {
        var datosTabla = []
        let promises = registroEmbarques.map((registroEmbarque) => {
            return formatearRegistroEmbarqueParaTabla(registroEmbarque);
        });
        datosTabla = await Promise.all(promises);
        datosTabla = aplicarFiltroDeAgentesAduanales(datosTabla)
        setDatosTabla(datosTabla)
        setCargando(false)
    }

    const formatearRegistroEmbarqueParaTabla = async (registroEmbarque) => {
        let manifiestos = await obtenerManifiestosEmbarque(cicloId, registroEmbarque.id);

        //
        let productores = extraerProductores(manifiestos);
        let numerosEmbarque = extraerNumerosDeEmbarque(manifiestos, registroEmbarque);
        let importador = importadores.find((importador) => importador.id === registroEmbarque.importador_ref) || ""
        const fechaDespacho = getFechaDespachado(registroEmbarque.cambios_estado_map, fronteras, registroEmbarque.frontera_ref, estadosEmbarque);
        const fechaLlegada = getFechaEstimadaDeLlegadaDeEmbarque(registroEmbarque.cambios_estado_map, fronteras, registroEmbarque.frontera_ref, estadosEmbarque);
        return {
            embarqueId: registroEmbarque.id,
            numeroCaja: registroEmbarque.numero_de_caja || '',
            numeroEmbarque: numerosEmbarque,
            productor: productores,
            importador: importador.nombre || "",
            importadorColor: importador.color || "",
            importador_ref: registroEmbarque.importador_ref,
            estatus: estadosEmbarque.find((estadoEmbarque) => estadoEmbarque.id === registroEmbarque.estado_ref).nombre + (isOnHold(registroEmbarque.estado_ref, registroEmbarque.cambios_estado_map) ? " (on hold)" : "") || "",
            frontera: fronteras.find((frontera) => frontera.id === registroEmbarque.frontera_ref).nombre || "",
            destino: destinos.find((destino) => destino.id === registroEmbarque.destino_ref).nombre || "",
            fechaDespacho,
            fechaDespachoUnix: fechaStringAUnix(fechaDespacho),
            fechaLlegada,
            fechaLlegadaUnix: fechaStringAUnix(fechaLlegada),
            estadoActualId: estadosEmbarque.find((estadoEmbarque) => estadoEmbarque.id === registroEmbarque.estado_ref).id || "",
            fechaIdealLlegadaUnix: getFechaEstimadaDeLlegadaDeEmbarqueUnix(registroEmbarque.cambios_estado_map, fronteras, registroEmbarque.frontera_ref, estadosEmbarque),
            despachado: timerIniciado(registroEmbarque.cambios_estado_map),
            aplicanRetrasos: aplicanRetrasos(registroEmbarque.frontera_ref, fronteras),
            aaMexicanoUid: agentesAduanalesMexicanos.find((aam) => aam.id === registroEmbarque.agente_aduanal_mexicano_ref)?.usuario_aa_uid || "N/A",
            aaAmericanoUid: agentesAduanalesAmericanos.find((aaa) => aaa.id === registroEmbarque.agente_aduanal_americano_ref)?.usuario_aa_uid || "N/A",
            transportista_ref: registroEmbarque.transportista_ref
        }
    }
    const fechaStringAUnix = (fechaString) => {
        const [day, month, year] = fechaString?.split("-")
        var datum = new Date(Date.UTC(year, month - 1, day, 0, 0, 0));
        //window.alert(datum.getTime())
        return datum.getTime()
    }

    function aplicarFiltroDeAgentesAduanales(datosTabla) {
        return datosTabla.filter((datosEmbarque) => {
            return usuario.rol === 'Administrador'
                || usuario.rol === 'Administrador Embarques'
                || usuario.rol === 'Auxiliar Embarques'
                || usuario.rol === 'Personal Administrativo'
                || (usuario.rol === 'agente aduanal' && (datosEmbarque.aaMexicanoUid === usuario.uid || datosEmbarque.aaAmericanoUid === usuario.uid))
                || (usuario.rol === 'Transportista' && datosEmbarque.transportista_ref === usuario.transportista)
                || usuario.rol === 'Finanzas'
                ;
        });
    }
    const aplicarFiltroImportadores = (datosSinFiltrar) => {
        //     
        // 

        let idsImportadores = filtroImportadores.map(el => el.id);
        let datosFiltrados = datosSinFiltrar.filter(el => idsImportadores.includes(el.importador_ref));
        // 
        // 
        // 
        return datosFiltrados
        //setDatosTablaFiltrados(datosFiltrados);
    }



    function aplicarFiltrosDeFechas() {
        // setCargando(true)

        var datosTablaSinFiltros = Array.from(datosTabla)
        if (datosTablaSinFiltros.length === 0) {
            // setDatosTablaFiltrados(datosTablaSinFiltros)
            // setCargando(false)
            return datosTablaSinFiltros;
        }

        if (fechaDespacho === "" && fechaLlegada === "") {
            // setDatosTablaFiltrados(datosTablaSinFiltros)
            // setCargando(false)
            return datosTablaSinFiltros;
        }

        const fechaDespachoString = fechaDespacho !== "" ? obtenerNombreFechaCompactoDate(fechaDespacho) : ""
        const fechaLlegadaString = fechaLlegada !== "" ? obtenerNombreFechaCompactoDate(fechaLlegada) : ""
        var datosTablaConFiltros = []
        if (fechaDespacho !== "" && fechaLlegada !== "") {
            datosTablaSinFiltros.forEach((tableRow) => {
                if (tableRow.fechaDespacho === fechaDespachoString && tableRow.fechaLlegada === fechaLlegadaString) datosTablaConFiltros.push(tableRow)
            });
        } else if (fechaDespacho !== "" && fechaLlegada === "") {
            datosTablaSinFiltros.forEach((tableRow) => {
                if (tableRow.fechaDespacho === fechaDespachoString) datosTablaConFiltros.push(tableRow)
            });
        } else if (fechaDespacho === "" && fechaLlegada !== "") {
            datosTablaSinFiltros.forEach((tableRow) => {
                if (tableRow.fechaLlegada === fechaLlegadaString) datosTablaConFiltros.push(tableRow)
            });
        }
        return datosTablaConFiltros;
        // setDatosTablaFiltrados(datosTablaConFiltros)
        // setCargando(false)
    }

    function iniciarTimersEmbarques() {
        // setInterval(() => {
        const currentDate = new Date()
        const currentUnixTimeDate = dateToUnixTimeAsNumber(currentDate)
        setCurrentUnixTimeDate(currentUnixTimeDate)
        // }, 2000);
    }

    const esEmbarqueAtrasado = (estadoActualId, fechaIdealLlegadaUnix, embarqueDespachado, aplicanRetrasos) =>
        !esEmbarqueFinalizado(estadoActualId) && yaPasoFechaIdealLlegada(fechaIdealLlegadaUnix) && embarqueDespachado && aplicanRetrasos;

    function esEmbarqueFinalizado(estadoActualId) {
        const estadoEmbarqueFinalizadoId = estadosEmbarque[estadosEmbarque.length - 1].id
        return estadoActualId === estadoEmbarqueFinalizadoId
    }

    const yaPasoFechaIdealLlegada = (fechaIdealLlegadaUnix) => currentUnixTimeDate > fechaIdealLlegadaUnix

    const fechaLlegadaHTML = (rowData) =>
        <div className={`${esEmbarqueAtrasado(rowData.estadoActualId, rowData.fechaIdealLlegadaUnix, rowData.despachado, rowData.aplicanRetrasos) ? 'fecha-atrasada' : ''} ${esEmbarqueFinalizado(rowData.estadoActualId) ? 'finalizado' : ''}`}>
            {rowData.fechaLlegada}
        </div>

    const importadorHTML = (rowData) => <div className={`${rowData.importadorColor} `}>{rowData.importador}</div>;
    const makeCssClass = (nombreStatus) => nombreStatus.toLowerCase().replace(/ /g, '-').replace(/[\s.,:()\/]/g, '');
    const statusHTML = (rowData) =>
        <div className={`embarque-status ${makeCssClass(rowData.estatus)} ${esEmbarqueAtrasado(rowData.estadoActualId, rowData.fechaIdealLlegadaUnix, rowData.despachado, rowData.aplicanRetrasos) ? 'atrasado' : ''} `}>
            <span></span>
            <div>
                {rowData.estatus}
                {esEmbarqueAtrasado(rowData.estadoActualId, rowData.fechaIdealLlegadaUnix, rowData.despachado, rowData.aplicanRetrasos) ? <div className="late-status">(Atrasado)</div> : ''}
            </div>
        </div>;

    const extraerNumerosDeEmbarque = (manifiestos, registroEmbarque) => manifiestos.map(el => el.numeroEmbarque).join(", ");

    function extraerProductores(manifiestos) {
        let productoresEmbarque = new Set();
        manifiestos.forEach(manifiesto => {
            productoresEmbarque.add(manifiesto.productor_ref);
        })
        productoresEmbarque = Array.from(productoresEmbarque);
        let result = productoresEmbarque.map(productorEmbarque => {
            let productor = productores.find(el => el.id === productorEmbarque)
            return productor?.nombre || ""
        })
        return result.join(", ");
    }

    const handleOnEmbarqueClicked = (embarque) => { props.history.push("/embarques/detalles_embarque/" + embarque.embarqueId) }
    const gotoRegistroEmbarque = () => { props.history.push("/embarques/registrar") }

    return (
        <div className="p-grid p-justify-center">
            <ContenedorHeader col="p-col p-col-auto" titulo="Embarques" atras={props.history.goBack} iconos="icon-general truck-icon" />

            <div className="p-col-12 ">
                <div className="p-grid filtros">
                    <div className="search-field form-group p-col">
                        <FontAwesomeIcon icon={faSearch} />
                        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar" />
                    </div>
                    <div className="form-group p-col">
                        <label htmlFor="filtroImportador">Importadores</label>
                        <MultiSelect id="filtroImportador" placeholder="Seleccionar"
                            dataKey="id" optionLabel="nombre"
                            options={importadores} value={filtroImportadores}
                            onChange={(e) => { setFiltroImportadores(e.value) }}
                            selectedItemsLabel={`${filtroImportadores.length} importadores seleccionados`} />
                    </div>

                    <div className="form-group p-col">
                        <label htmlFor="fechaDespacho">Fecha de despacho</label>
                        <div className="fecha-filtro">
                            <Calendar id="fechaDespacho" readOnlyInput={true} dateFormat="dd-mm-yy" locale={CALENDAR_LOCALE_ES_PRIME_TRADUCTION}
                                value={fechaDespacho} monthNavigator={true} yearNavigator={true} yearRange="1900:2030"
                                showButtonBar={true} onClearButtonClick={() => { setFechaDespacho("") }}
                                onChange={e => setFechaDespacho(e.value)} />
                        </div>
                    </div>

                    {!finalized && <div className="form-group p-col">
                        <label htmlFor="fechaLlegada">Fecha de llegada</label>
                        <div className="fecha-filtro">
                            <Calendar id="fechaLlegada" readOnlyInput={true} dateFormat="dd-mm-yy" locale={CALENDAR_LOCALE_ES_PRIME_TRADUCTION}
                                value={fechaLlegada} monthNavigator={true} yearNavigator={true} yearRange="1900:2030"
                                showButtonBar={true} onClearButtonClick={() => { setFechaLlegada("") }}
                                onChange={e => setFechaLlegada(e.value)} />
                        </div>
                    </div>}
                    {finalized && <div className="form-group p-col">
                        <label htmlFor="fechaLlegada">Rango de Fecha de llegada</label>
                        <div className="fecha-filtro">
                            <Calendar id="range" value={rangoFecha} onChange={(e) => setRangoFecha(e.value)} dateFormat="dd-mm-yy" selectionMode="range" readOnlyInput locale={CALENDAR_LOCALE_ES_PRIME_TRADUCTION} />
                        </div>
                    </div>}

                    <div className="form-group p-col">
                        <label htmlFor="filtroEstado">Estatus</label>
                        <MultiSelect id="filterStatuses" selectedItemsLabel={`${estadosEmbarqueSeleccionado.length} estatus seleccionados`}
                            filter={false} dataKey="id" optionLabel="nombre"
                            options={estadosEmbarque} value={estadosEmbarqueSeleccionado}
                            onChange={(e) => { setEstadosEmbarqueSeleccionado(e.value) }} />
                    </div>
                </div>
            </div>

            <div className="p-col-12">
                <DataTable selectionMode="single" autoLayout={true} value={datosTablaFiltrados} onRowClick={(event) => { handleOnEmbarqueClicked(event.data) }}
                    paginator={false} rows={0} globalFilter={globalFilter} emptyMessage="No hay resultados" >
                    <Column header="No. Embarque" field="numeroEmbarque" sortable={false} style={{ textAlign: 'center' }} />
                    <Column header="No. Caja" field="numeroCaja" sortable={true} style={{ textAlign: 'center' }} />
                    <Column header="Productor" field="productor" sortable={true} style={{ textAlign: 'center' }} />
                    <Column header="Importador" field="importador" sortable={true} style={{ textAlign: 'center' }} body={importadorHTML} />
                    <Column header="Frontera" field="frontera" sortable={true} style={{ textAlign: 'center' }} />
                    <Column header="Destino" field="destino" sortable={true} style={{ textAlign: 'center' }} />
                    <Column header="Fecha de despacho" field="fechaDespacho" sortable={true} sortField={"fechaDespachoUnix"} style={{ textAlign: 'center' }} />
                    <Column header="Fecha de llegada" field="fechaLlegada" sortable={true} sortField={"fechaLlegadaUnix"} style={{ textAlign: 'center' }} body={fechaLlegadaHTML} />
                    <Column header="Estatus" field="estatus" sortable={true} style={{ textAlign: 'center' }} body={statusHTML} />
                </DataTable>
            </div>
        </div>
    );

}
export default ConsultarEmbarques;//511