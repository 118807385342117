import * as firebase from "firebase/app";
import "firebase/firestore";
import { segundosSemana } from "../constants";
import {
    obtenerDiasDeSemana, obtenerListaDeSemanas,
    obtenerInicioSemanaDia, obtenerDiaActual,
    obtenerHora,
    flexDate
} from "./fechas";
import { obtenerCapturaPrevia } from "./Queries/Pronostico";
import { obtenerProveedoresInvernadero } from "./Queries/Proveedores";
import { uploadStatistics } from './Estadisticas'

function registrarInvocaciones(count) {
    uploadStatistics(count)
}

export function guardarBudget(ciclo, invernadero, budgetObj) {
    let db = firebase.firestore();
    return db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero).update({
        budget: budgetObj
    })
}

export function obtenerProyeccionDesde(ciclo, invernadero, inicio, fin) {
    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
        inicio = obtenerInicioSemanaDia(inicio).unix();
        fin = obtenerInicioSemanaDia(fin).unix();
        db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero).get()
            .then(resp => {
                registrarInvocaciones(resp.size)
                if (resp.exists && resp.data().budget && resp.data().budget.estimacion_semanal) {
                    let total = resp.data().budget.estimacion_semanal.reduce((acc, curr) => {
                        if (curr.time >= inicio && curr.time <= fin) {
                            let cant = parseFloat(curr.value);

                            if (isNaN(cant)) {
                                return acc;
                            } else {
                                return acc + cant;
                            }
                        } else {
                            return acc;
                        }

                    }, 0);
                    resolve(total);
                } else { resolve(0) }

            }).catch(err => {
                reject(err);
            })
    });
}
export async function obtenerProyeccionConPronosticoDesde(ciclo, invernadero, inicio, fin, semanaTime) {
    const db = firebase.firestore();
    let pronosticos = await obtenerCapturaPrevia(ciclo, invernadero, semanaTime);

    inicio = obtenerInicioSemanaDia(inicio).unix();
    fin = obtenerInicioSemanaDia(fin).unix();
    let resp = await db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero).get()
    registrarInvocaciones(resp.size)
    if (resp.exists && resp.data().budget && resp.data().budget.estimacion_semanal) {
        let total = resp.data().budget.estimacion_semanal.reduce((acc, curr) => {
            if (curr.time >= inicio && curr.time <= fin) {
                let indicePronostico = obtenerIndicePronostico(pronosticos, curr.time);
                if (!pronosticos.isEmpty && indicePronostico >= 0) {
                    let pronostico = parseFloat(pronosticos["pronosticos"][indicePronostico]);
                    return acc + pronostico;

                }
                let cant = parseFloat(curr.value);

                if (isNaN(cant)) {
                    return acc;
                } else {
                    return acc + cant;
                }
            } else {
                return acc;
            }

        }, 0);
        return total;
    } else {
        return 0;
    }
}
export const getProyeccionConPronosticoDesde = async (ciclo, invernadero, inicio, fin, semanaTime) => {
    const pronosticos = await obtenerCapturaPrevia(ciclo, invernadero.id, semanaTime)
    inicio = obtenerInicioSemanaDia(inicio).unix();
    fin = obtenerInicioSemanaDia(fin).unix();

    if (invernadero.budget?.estimacion_semanal) {
        let total = invernadero.budget.estimacion_semanal.reduce((acc, curr) => {
            if (curr.time >= inicio && curr.time <= fin) {
                let indicePronostico = obtenerIndicePronostico(pronosticos, curr.time);
                if (!pronosticos.isEmpty && indicePronostico >= 0) {
                    let pronostico = parseFloat(pronosticos.pronosticos[indicePronostico]);
                    return acc + pronostico;
                }
                let cant = parseFloat(curr.value);
                if (isNaN(cant)) return acc;
                else return acc + cant;
            } else return acc;
        }, 0);
        return total;
    } else return 0;
}
const obtenerIndicePronostico = (pronosticos, semana) => {

    if (semana - segundosSemana >= pronosticos["semana"] && semana - segundosSemana <= pronosticos["semana"] + 4 * segundosSemana) {
        let indice = Math.floor((semana - pronosticos["semana"]) / segundosSemana) - 1;
        return pronosticos["pronosticos"][indice] ? indice : -1;
    } else {
        return -1;
    }
}

export function obtenerProyeccionDesdeExterno(ciclo, invernadero, inicio, fin) {
    return new Promise((resolve, reject) => {
 
        inicio = obtenerInicioSemanaDia(inicio).unix();
        fin = obtenerInicioSemanaDia(fin).unix();
        obtenerProveedoresInvernadero(ciclo, invernadero)
            .then(resp => {
                let totalProveedores = 0;
                resp.forEach(proveedor => {
                    if (proveedor.budget && proveedor.budget) {
                        // 
                        let total = proveedor.budget.reduce((acc, curr) => {
                            if (curr.time >= inicio && curr.time <= fin) {
                                let cant = parseFloat(curr.value);

                                if (isNaN(cant)) {
                                    return acc;
                                } else {
                                    return acc + cant;
                                }
                            } else {
                                return acc;
                            }

                        }, 0);
                        totalProveedores += total;
                    } else { }
                })
                resolve(totalProveedores);
            }).catch(err => {
                reject(err);
            })
    });
}
export function obtenerProyeccionSemana(ciclo, invernadero, inicio) {
    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
        // 
        //         
        inicio = obtenerInicioSemanaDia(inicio).unix();
        db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero).get()
            .then(resp => {
                registrarInvocaciones(resp.size)
                // 
                // 
                // 
                // 
                if (resp.exists && resp.data().budget && resp.data().budget.estimacion_semanal) {
                    // 
                    let total = resp.data().budget.estimacion_semanal.reduce((acc, curr) => {
                        if (curr.time == inicio) {
                            let cant = parseFloat(curr.value);

                            if (isNaN(cant)) {
                                return acc;
                            } else {
                                return acc + cant;
                            }
                        } else {
                            return acc;
                        }

                    }, 0);
                    // 
                    // 
                    // 
                    resolve(total);
                } else { resolve(0) }

            }).catch(err => {
                reject(err);
            })
    });
}
export async function obtenerProyeccionSemanaConsiderarPronostico(ciclo, invernadero, inicio, semanaActual) {
    const db = firebase.firestore();

    inicio = obtenerInicioSemanaDia(inicio).unix();
    let resp = await db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero).get()
    registrarInvocaciones(resp.size)
    let pronosticos = { isEmpty: true }
    let indice = 0;
    if (inicio >= semanaActual && inicio < semanaActual + 5 * segundosSemana) {
        pronosticos = await obtenerCapturaPrevia(ciclo, invernadero, semanaActual);
        indice = (inicio - semanaActual) / segundosSemana
    }

    // 
    // 
    // 
    if (!pronosticos || pronosticos.isEmpty || !pronosticos["pronosticos"][indice]) {

        if (resp.exists && resp.data().budget && resp.data().budget.estimacion_semanal) {
            // 
            let total = resp.data().budget.estimacion_semanal.reduce((acc, curr) => {
                const timestamps = flexDate(curr.time);
                if (timestamps.inicio <= inicio && timestamps.fin >= inicio) {
                    let cant = parseFloat(curr.value);

                    if (isNaN(cant)) {
                        return acc;
                    } else {
                        return acc + cant;
                    }
                } else {
                    return acc;
                }

            }, 0);
            // 
            // 
            // 
            return total;
        } else { return 0 }
    } else {
        // 
        // 
        // 
        // 
        return pronosticos["pronosticos"][indice]
    }
    // 
    // 
    // 



}
export function obtenerTotalBudget(ciclo, invernadero) {
    return new Promise((resolve, reject) => {
        const db = firebase.firestore();
        db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero).get()
            .then(resp => {
                registrarInvocaciones(resp.size)
                if (resp.exists && resp.data().budget && resp.data().budget.estimacion_semanal) {
                    let total = resp.data().budget.estimacion_semanal.reduce((acc, curr) => {
                        let cant = parseFloat(curr.value);

                        if (isNaN(cant)) {
                            return acc;
                        } else {
                            return acc + cant;
                        }

                    }, 0);
                    resolve(total);
                }
                else {
                    resolve(0);
                }
            }).catch(err => { reject(err) })
    })
}
export const getTotalBudget = (invernadero) => {
    if (invernadero.budget?.estimacion_semanal) {

        let total = invernadero.budget.estimacion_semanal.reduce((acc, curr) => {
            let cant = parseFloat(curr.value);
            if (isNaN(cant)) return acc;
            else return acc + cant;
        }, 0);
        return total;
    } else { return 0; }
}
export function obtenerTotalBudgetExterno(ciclo, invernadero) {
    return new Promise((resolve, reject) => {

        obtenerProveedoresInvernadero(ciclo, invernadero)
            .then(resp => {
                registrarInvocaciones(resp.size)
                let totalProveedores = 0;
                resp.forEach(proveedor => {
                    if (proveedor.budget && proveedor.budget) {
                        let total = proveedor.budget.reduce((acc, curr) => {
                            let cant = parseFloat(curr.value);
                            if (isNaN(cant)) {
                                return acc;
                            } else {
                                return acc + cant;
                            }

                        }, 0);
                        totalProveedores += total;
                    }
                    else {

                    }

                })
                resolve(totalProveedores);
            }).catch(err => { reject(err) })
    })
}//316