import React, { useEffect, useState } from 'react'
import { useGlobal } from 'reactn';
import ContenedorHeader from '../common/ContenedorHeader';
import WeekSelector, { useWeekSelector } from '../common/WeekSelectorNEW';
import ContenedorPrincipal from "../common/ContenedorPrincipal";
import ContenedorFiltro from '../common/ContenedorFiltro';
import SeccionFiltros from '../common/SeccionFiltros';
import { MultiSelect } from 'primereact/multiselect';
import { getDiasDeSemanaConDetalles } from '../../service/fechas';
import SeccionDias from './SeccionDias';
import CardDia from './CardDia';
import { adelantarSemanasMantenimiento, getAgentes, getDatosMantenimiento, obtenerActividadesAgenteSemana } from '../../service/Mantenimiento';

const PlaneacionActividades = (props) => {
    const [ciclo] = useGlobal("ciclo");
    const [, setNombreModulo,] = useGlobal("nombreModulo");
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [datosCargados] = useGlobal("datosCargadosMantenimiento");

    const [cargando, setCargando] = useGlobal("cargando");
    const [agente, setAgente] = useGlobal("agente");
    const [state, setState] = useState({});
    const [weeks, week, handleWeekChange, asignarListaSemanas] = useWeekSelector(true);
    const [weekGlobal, setWeekGlobal] = useGlobal("weekGlobal");
    const [locaciones, setLocaciones] = useGlobal("locaciones");
    const [empresas, setEmpresas] = useGlobal("empresas");
    const [empresasSinLort, setEmpresasSinLort] = useGlobal("empresasSinLort");
    const [empresasFiltros, setEmpresasFiltros] = useGlobal("empresasFiltrosMantenimiento");
    const [locacion, setLocacion] = useState();
    const [agenteID, setAgenteID] = useState(props.match.params?.agenteID)
    const [dias, setDias] = useState([])
    const [filtroDias, setFiltroDias] = useState([])
    const [categorias, setCategorias] = useGlobal("categorias");

    useEffect(() => {
        setNombreModulo("Mantenimiento")
        setHabilitadoSelectorCiclo(true)
        setCargando(true);
    }, [])

    useEffect(() => {
        if (agente?.tareas?.length > 0 && weeks?.length > 0) adelantarSemanasMantenimiento(ciclo, asignarListaSemanas, agente)
    }, [agente, weeks])

    useEffect(() => {
        if (!datosCargados && week) getDatosMantenimiento(agenteID)
        else if (datosCargados && week) getDatosIniciales();
    }, [datosCargados, week])

    const getDatosIniciales = async () => {
        try {
            setCargando(true);
            let allAgentes = await getAgentes()
            let agenteData = agente?.id ? agente : allAgentes.find(el => el.id === agenteID)
            if (!agenteData) agenteData = allAgentes[0]
            agenteData = await obtenerActividadesAgenteSemana(ciclo, week.time, agenteData)
            setAgente(agenteData)

            let locacion = locaciones.find(loc => loc.id == agenteData.locacion_ref);
            setLocacion(locacion);
            actualizarOpcionesSemana()
        } catch (error) {
            console.log(error);
        }
        setCargando(false);
    }

    const actualizarOpcionesSemana = () => {
        let diasDeSemana = getDiasDeSemanaConDetalles(week.time)
        setDias(diasDeSemana);
        setFiltroDias(diasDeSemana.sort((a, b) => a.unixTime - b.unixTime))
    }
    const handleDiasChange = (e) => {
        let sorted = e.value.sort((a, b) => a.unixTime - b.unixTime);
        setFiltroDias(sorted);
    }

    const titulo = (agente && locacion) ? `${agente.displayName}` : "";
    const subtitulo = locacion ? `${locacion.nombre}` : "";
    
    return (
        <ContenedorPrincipal extra="p-grid p-justify-center">
            <ContenedorHeader titulo={titulo} subtitulo={subtitulo} atras={props.history.goBack} iconos="mtto-icon" />

            <SeccionFiltros >
                <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
                <ContenedorFiltro label="Empresa">
                    <MultiSelect options={empresasSinLort} value={empresasFiltros}
                        onChange={e => setEmpresasFiltros(e.value)}
                        dataKey='id' optionLabel='nombre'
                        selectedItemsLabel={empresasFiltros && `${empresasFiltros.length} empresas seleccionadas`} />
                </ContenedorFiltro>
                <ContenedorFiltro label="Día">
                    <MultiSelect options={dias} value={filtroDias}
                        onChange={handleDiasChange}
                        dataKey='unixTime' optionLabel='nombre'
                        selectedItemsLabel={`${filtroDias.length} días seleccionados`} />
                </ContenedorFiltro>
            </SeccionFiltros>

            <SeccionDias>
                {agente?.tareas && filtroDias?.map((dia, index) => {
                    return <CardDia key={index} dia={dia} tareas={agente.tareas} empresas={empresasFiltros} categorias={categorias} />
                })}
            </SeccionDias>
        </ContenedorPrincipal >
    )
}
export default PlaneacionActividades//119