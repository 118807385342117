const { paletaColores } = require("util/functions")

export const obtainColorsFromSeries = (series)=> {
    const resultColors = series.reduce((acc, serie, index, series) => {
        if(acc.lineP === 16) acc.lineP = 0
        if(acc.lineB === 17) acc.lineB = 0
        if(!serie.name.includes("Budget")){
          acc.colors.push(paletaColores[acc.lineP])
          acc.lineP = acc.lineP + 2
          if(!series[index+1]?.name.includes("Budget")){
            acc.lineB = acc.lineB + 2
          }
        }
        if(serie.name.includes("Budget")){
          acc.colors.push(paletaColores[acc.lineB])
          acc.lineB = acc.lineB + 2
        }
        return acc
      },{lineP:0, lineB:1, colors: []})
    return resultColors.colors
}

export const obtainDashArrayFromSeries = (series)=> {
    return series.map((serie) => {
        if(serie.name.includes("Budget")){
        return 4
        }
        return 0
    })
}