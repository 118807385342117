import React, { useState, useEffect } from 'reactn'
import CardOrdenVenta from './CardOrdenVenta.mole'
import BaseCard from '../../common/cards/BaseCard';
import SeccionDatosCenter from '../../common/SeccionDatosCenter';
import DatoCard from '../../common/cards/DatoCard';
import CardItemOrigen from './CardItemOrigen.mole';
import { obtenerNombreDiaSemana } from '../../../service/fechas';
import { filtrarOrdenItemsOrigenes, getTotalesOrdenesDeVenta } from '../../../service/CentroDeDistribucion';

const CardDia = (props) => {
    const [resumenTotales, setResumenTotales] = useState({ cajas: 0, pallets: 0, cajasDistribuidas: 0 });

    useEffect(() => {//esto solo va llamar una ves porque NO estamos cloneando en PantallaRedistribucionInvernadero: editarDistribucionData()
        const resumenTotales = getTotalesOrdenesDeVenta(props.ordenesVentaDelDia, props.presentacion);
        setResumenTotales(resumenTotales);
    }, [props.ordenesVentaDelDia])

    const getItemsDestinoDelOrigen = (ordenVentaID = "", origenItemID = "") => {
        const itemDestinos = props.distribucionData[ordenVentaID]?.[origenItemID];
        return itemDestinos || [];
    }

    return (props.visible ?
        <React.Fragment>
            <BaseCard col="12" md="4" lg="4" tituloCard={obtenerNombreDiaSemana(props.dia)}>
                <SeccionDatosCenter>
                    <DatoCard label="Totales" valor={`${resumenTotales.cajas} cajas`} />
                    <DatoCard label="Totales" valor={`${resumenTotales.pallets} pallets`} />
                    {/*<DatoCard label="Redistribuidos" valor={`${sumaRedistribuidos} redistribuidos`} />*/}
                </SeccionDatosCenter>

                {props.ordenesVentaDelDia?.map((ordenVenta, index) => {
                    const itemsOrigenes = filtrarOrdenItemsOrigenes(ordenVenta, props.presentacion);
                    return (
                        <CardOrdenVenta {...props} key={ordenVenta.no_orden} indexOrden={index + 1} ordenVenta={ordenVenta}>
                            {itemsOrigenes.map(origen => {
                                /*Decorator*/
                                const editarDistribucionData = (origen, destinos, errores) => props.editarDistribucionData(ordenVenta, origen, destinos, errores);
                                const destinoDelOrigen = getItemsDestinoDelOrigen(ordenVenta.id, origen.itemID);
                                return <CardItemOrigen key={origen.itemID} itemOrigen={origen} itemsDestino={destinoDelOrigen}
                                    editarDistribucionData={editarDistribucionData} presentacion={props.presentacion} />;
                            })}
                        </CardOrdenVenta>)
                })}
            </BaseCard>
        </React.Fragment>
        : null)
}
export default CardDia;//96