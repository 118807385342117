
import React, { useState, useGlobal } from 'reactn'
import { getNombrePorID } from '../../../util/functions';
import SeccionFormularioCard from '../../common/SeccionFormularioCard';


const CardDetallesRequisicion = (props) => {
    const [empresas] = useGlobal("empresas")
    const [ordenesRefs] = useGlobal("ordenesRefsMant")
    const [urgencias] = useGlobal("urgenciasMant")
    const [requerimientos] = useGlobal("requerimientosMant")
    const [locacionesPlanMant] = useGlobal("locacionesPlanMant")
    const [locaciones] = useGlobal("locacionesMant")

    return (
        <SeccionFormularioCard col="12" titulo="Detalles de requisición">
        <div className="p-grid ">

            <div className="w-100">
                <div className="p-col-12">
                    <div className="p-grid">
                        <span className="titles-group">
                            <h1 className="header-title">
                                {props.nombreTarea}
                            </h1>
                            <p className="header-subtitle">
                                {getNombrePorID(empresas || [], props.empresa_ref)} - {getNombrePorID(locacionesPlanMant || [], props.locacion)} |  Urgencia: {getNombrePorID(urgencias, props.urgencia)}
                            </p>
                            <p className="compras-detalles-text">
                                {getNombrePorID(ordenesRefs, props.orden_ref)} - {getNombrePorID(requerimientos, props.requerimiento)}
                            </p>
                            <p className="compras-detalles-text"> {props.detalle} </p>
                        </span>

                    </div>
                </div>
            </div>
        </div>

    </SeccionFormularioCard>)
}
export default CardDetallesRequisicion;