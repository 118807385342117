import React from 'react'
import ContenedorHeader from './ContenedorHeader'

export default function HeaderGrafica(props) {
    return (
        <ContenedorHeader iconos="chart-icon" titulo = {props.titulo} atras = {props.atras}>

        </ContenedorHeader>
    )
}
