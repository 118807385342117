import React from 'react'
import IngresosNetos from './IngresosNetos'
import IngresosBrutos from './IngresosBrutos'
import Materiales from './Materiales'
import Precios from './Precios'
import BudgetEmpaque from '../BudgetEmpaque'
import { useEffect, useGlobal, useState } from 'reactn'
import Chart from "react-apexcharts";
import ContenedorPrincipal from '../../common/ContenedorPrincipal'
import SeccionFiltros from '../../common/SeccionFiltros'
import ContenedorFiltro from '../../common/ContenedorFiltro'
import {Dropdown} from 'primereact/dropdown'
import { obtenerInvernaderosVirtuales } from '../../../service/Invernaderos'
import { obtenerReporteCostosMaterialesSemanal, obtenerReporteIngresosBrutosInvernaderos, obtenerReporteIngresosNetosInvernaderos, obtenerReportePreciosInvernadero } from '../../../service/AnalisisCostos'
import { obtenerPresentaciones } from '../../../service/Presentaciones'
import { cuatroDecimales, dosDecimales } from '../../../util/functions'
import { obtenerDetalleReporte } from '../../../service/ReportesProducto'
import { obtenerCiclo } from '../../../service/Ciclos'
import { obtenerInicioSemanaDia, obtenerListaDeSemanas } from '../../../service/fechas'
import ContenedorHeader from '../../common/ContenedorHeader'

const optionsOrig = {
    chart: {
      type: 'line',
      zoom: {
        enabled: true
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    title: {
      text: 'Titulo',
      align: 'left'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
     xaxis: {
       categories: [],
     },
     
  }
export default function Dashboard(props) {
    const [options, setOptions] = useState({...optionsOrig})
    const [ciclo, setCiclo] = useGlobal("ciclo")
    const [cargando, setCargando] = useGlobal("cargando")
    const [seriesPrecios, setSeriesPrecios] = useState([])
    const [seriesIngresosNetos, setSeriesIngresosNetos] = useState([])
    const [seriesIngresosBrutos, setSeriesIngresosBrutos] = useState([])
    const [seriesMateriales, setSeriesMateriales] = useState([])    
    const [seriesEmpaque, setSeriesEmpaque] = useState([])
    const [invernadero, setInvernadero] = useGlobal("invernadero")
    const [invernaderos, setInvernaderos] = useState([])
    const [cicloObj, setCicloObj] = useState()
    const atras = (params) => {
      props.history.goBack();
    }
    
    useEffect(() => {
        if(ciclo){
            obtenerDatosIniciales();
        }
    }, [ciclo])
    useEffect(() => {
        if(ciclo && invernadero){
            actualizarSeries();
        }
    }, [invernadero])
    const actualizarSeries = async () => {
      setCargando(true)
        let presentaciones = await obtenerPresentaciones();
        let reportePrecios = await obtenerReportePreciosInvernadero(ciclo,[invernadero], presentaciones);
        let seriesPrecios = obtenerSeriesPrecios(reportePrecios);
        let reporteIngresosNetos = await obtenerReporteIngresosNetosInvernaderos(ciclo, [invernadero],presentaciones)
        let seriesIngresosNetos= obtenerSeriesIngresosNetos(reporteIngresosNetos);
        let reporteIngresosBrutos = await obtenerReporteIngresosBrutosInvernaderos(ciclo, [invernadero],presentaciones);
        let seriesIngresosBrutos = obtenerSeriesIngresosBrutos(reporteIngresosBrutos);
        let reporteMateriales = await obtenerReporteCostosMaterialesSemanal(ciclo, invernadero);
        let seriesMateriales =  obtenerSeriesMateriales(reporteMateriales);
        let cicloObj = await obtenerCiclo(ciclo);
        let reporteBudget = await obtenerDetalleReporte(cicloObj,    invernadero.id);
        let seriesBudget = obtenerSeriesBudget(reporteBudget);
        setCicloObj(cicloObj)
        setSeriesPrecios(seriesPrecios);
        setSeriesIngresosNetos(seriesIngresosNetos);
        setSeriesIngresosBrutos(seriesIngresosBrutos);
        setSeriesMateriales(seriesMateriales);
        setSeriesEmpaque(seriesBudget);
        setCargando(false)
    }
    const obtenerSeriesBudget = (reporte) => {
        
        
        
        let isAcumulado = false;
        let result = [];
        let ahora = Math.round(Date.now()/1000)
        let inicioSemana = obtenerInicioSemanaDia(ahora).unix();     
        [invernadero].forEach((invernadero, index)=>{
            
            let dataInv = reporte; 
            
            
            let baseBudget = {name:`Budget ${invernadero.nombre}`, label:`Budget ${invernadero.nombre}`,type:"line",data:[], idInvernadero:invernadero.id, 
              tipo:"budget", index:0, color: invernadero.color};
            let baseFinal = {name:`Final ${invernadero.nombre}`, label:`Final ${invernadero.nombre}`,type:"line",data:[], idInvernadero:invernadero.id,
              tipo:"kgm2", index:1, color:invernadero.color};
            let sumaBudget = 0;
            let sumaFinal = 0;
            let currentReal = 0;
            let currentBudget = 0;
            dataInv.forEach(semana => {

              if(isAcumulado){
                currentBudget+= dosDecimales(semana["budget"])
                // currentReal+= dosDecimales(semana["kgm2OrdenesSemana"])
                if(semana["semana"]> inicioSemana){
                  currentReal+= dosDecimales(semana["budget"])
                }else{
                  currentReal+= dosDecimales(semana["kgm2OrdenesSemana"])
      
                }
              }else{
                currentBudget =dosDecimales(semana["budget"])
                if(semana["semana"]> inicioSemana){
                  currentReal= dosDecimales(semana["budget"])
                }else{
                  currentReal= dosDecimales(semana["kgm2OrdenesSemana"])
      
                }
              }
                //sumaBudget+=semana["budget"];
                //baseBudget["data"].push({x:semana.nombreCompacto,y:sumaBudget});                
                baseBudget["data"].push(currentBudget);
                //sumaFinal+=semana["kgm2OrdenesSemana"];
                //baseFinal["data"].push({x:semana.nombreCompacto, y:sumaFinal});
                baseFinal["data"].push(currentReal);
            })
            
            result.push(baseBudget);
            result.push(baseFinal);
            
        });
        return result;
    }
    const obtenerSeriesMateriales = (reporte) => {   
      let ahora = Math.round(Date.now()/1000)
      let inicioSemana = obtenerInicioSemanaDia(ahora).unix();     
        let budget = {name:`Budget ${invernadero.nombre}`, label:"Budget",
            type:"line", data:[], id:"budget", index:1,
            tipo:"budget",
            color:invernadero.color};
        let real = {name:`Real ${invernadero.nombre}`, label:"real",
        type:"line", data:[], id:"real", index:0,
        tipo:"kgm2",
        color:invernadero.color};
        reporte.forEach(datoSemana => {
            
            budget.data.push(dosDecimales(datoSemana["total_budget"]));
            if(datoSemana["semana"]["time"]> inicioSemana){
              real.data.push(dosDecimales(datoSemana["total_budget"]));
            }else{
              real.data.push(dosDecimales(datoSemana["total"]));
  
            }
        })
        return [real, budget]
    }
    const obtenerSeriesIngresosBrutos = (reporte) => {
        let isAcumulado = false;
        let ahora = Math.round(Date.now()/1000);
        let inicioSemana = obtenerInicioSemanaDia(ahora).unix();
        
        
        let result = reporte.map(el=>{
          
          
          let invernadero = invernaderos.find(invernadero=>invernadero.id === el.invernaderoId);
          let objReal = {name:`Real ${invernadero?invernadero.nombre:""}`, label :`Real ${invernadero?invernadero.nombre:""}`, index: 0,
            color: invernadero?invernadero.color:"gray",tipo:"real", data: [] }
          let objBudget = {name:`Budget ${invernadero?invernadero.nombre:""}`, label : `Budget ${invernadero?invernadero.nombre:""}`, index: 1,
            color: invernadero?invernadero.color:"gray",tipo:"budget", data:[] }
            let currentReal = 0;
            let currentBudget  = 0;
          el.datos.forEach(semana => {
            if(isAcumulado){
              currentBudget+= semana["ingresoBudget"];
              if( inicioSemana< semana.semana){                
                currentReal+= dosDecimales(semana["ingresoBudget"])
              }else{
                currentReal+= dosDecimales(semana["ingreso"])

              }
            }else{
              currentBudget= semana["ingresoBudget"];
              if( inicioSemana< semana.semana){                
                currentReal= dosDecimales(semana["ingresoBudget"])
              }else{
                currentReal= dosDecimales(semana["ingreso"])

              }
            }
            objReal.data.push(currentReal )
            objBudget.data.push(currentBudget);
          })
          return [objReal, objBudget];  
        })
        return result.flat();
      }
    const obtenerSeriesIngresosNetos= (reporte) => {
      let isAcumulado=false;
      let ahora = Math.round(Date.now()/1000);
        let inicioSemana = obtenerInicioSemanaDia(ahora).unix();
        
        
        let result = reporte.map(el=>{
          
          
          let invernadero = invernaderos.find(invernadero=>invernadero.id === el.invernaderoId);
          let objReal = {name:`Real ${invernadero?invernadero.nombre:""}`, label :`Real ${invernadero?invernadero.nombre:""}`, index: 0,
            color: invernadero?invernadero.color:"gray",tipo:"real", data: [] }
          let objBudget = {name:`Budget ${invernadero?invernadero.nombre:""}`, label : `Budget ${invernadero?invernadero.nombre:""}`, index: 1,
            color: invernadero?invernadero.color:"gray",tipo:"budget", data:[] }
          let currentReal = 0;
          let currentBudget  = 0;
          el.datos.forEach(semana => {
            if(isAcumulado){
              currentBudget+= semana["ingresoBudget"];
              if( inicioSemana< semana.semana){                
                currentReal+= dosDecimales(semana["ingresoBudget"])
              }else{
                currentReal+= dosDecimales(semana["ingreso"])

              }
            }else{
              currentBudget= semana["ingresoBudget"];
              if( inicioSemana< semana.semana){                
                currentReal= dosDecimales(semana["ingresoBudget"])
              }else{
                currentReal= dosDecimales(semana["ingreso"])

              }
            objReal.data.push(currentReal )
            objBudget.data.push(currentBudget);
            }
          })
          return [objReal, objBudget];  
        })
        return result.flat();
      }
    const obtenerSeriesPrecios = (reporte) => {
        
        
        let result = reporte.map(el=>{
          
          
          let invernadero = invernaderos.find(invernadero=>invernadero.id === el.invernaderoId);
          let objReal = {name:`Real ${el.nombreInvernadero}`, label : `Budget ${el.nombreInvernadero}`, index: 0,
            color: invernadero?invernadero.color:"gray",tipo:"real", data: [] }
          let objBudget = {name:`Budget ${el.nombreInvernadero}`, label :`Budget ${el.nombreInvernadero}`, index: 1,
            color: invernadero?invernadero.color:"gray",tipo:"budget", data:[] }
          el.datos.forEach(semana => {
            let libras = semana.sumaLibras;
            let precio = semana.precioTotal;
            objReal.data.push(libras>0? cuatroDecimales( precio / libras): 0 )
            objBudget.data.push(cuatroDecimales(semana.datoBudget));
          })
          return [objReal, objBudget];  
        })
        return result.flat();
      }
    
    
    const obtenerDatosIniciales = async (params) => {
        let invernaderos = await obtenerInvernaderosVirtuales(ciclo);
        setInvernaderos(invernaderos);
    }
    const obtenerOptions = (titulo) => {
      let copia = {...optionsOrig}
      copia["chart"] = {  
        type: 'line',
        group: 'social',
        id:titulo,
        zoom: {
          enabled: true
        }
      }
      
      // if(titulo==="Ingresos Netos" && cicloObj){
      //   copia["xaxis"] = {};
      //   
      //   
      //   let semanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
      //   semanas = semanas.map(el=>el.nombreCompacto)
      //   
      //   copia["xaxis"]["categories"] = semanas;
      // }
      copia["title"] = {};
      copia["title"]["text"] = titulo;
      copia["title"]["align"] = "left";
      
      return copia;
    }
    
    const irGraficaPrecios = (params) => {
      props.history.push("/analisis_costos/precios")
    }
    const irGraficaMateriales = (params) => {
      props.history.push("/analisis_costos/costos_empaque")
    }
    const irGraficaIngresosBrutos = (params) => {
      props.history.push("/analisis_costos/ingresos_brutos")
    }
    const irGraficaIngresosNetos = (params) => {
      props.history.push("/analisis_costos/ingresos_netos")
    }
    const irGraficaBudget = (params) => {
      props.history.push("/empaque/graficas/budget")
    }
    return (
        <ContenedorPrincipal extra="chart-page">
            <ContenedorHeader titulo = "Análisis de precios" iconos = {"costos-icon"} atras = {atras}/>

            <SeccionFiltros>
                <ContenedorFiltro label = "Invernadero">
                    <Dropdown value = {invernadero} options={invernaderos} onChange={e=>setInvernadero(e.value)}
                        optionLabel="nombre" dataKey="id" />
                </ContenedorFiltro>
            </SeccionFiltros>
            <div className = "p-col-12 chart-container-main">

            <Chart series = {seriesIngresosNetos} options ={obtenerOptions("Ingresos Netos")} 
              onClick = {irGraficaIngresosNetos} height="100%"/>
            </div>

            <div className = "p-col-12">
            <ContenedorPrincipal extra=" chart-container-secondary" >
            <div className = "p-col-3 ">
            <Chart series = {seriesPrecios} options ={obtenerOptions("Precios")} 
              onClick = {irGraficaPrecios} height="200" />
            </div>
            <div className = "p-col-3 ">
            <Chart series = {seriesIngresosBrutos} options ={obtenerOptions("Ingresos Brutos")} 
              onClick = {irGraficaIngresosBrutos} height="200" />
            </div>
            <div className = "p-col-3 ">
            <Chart series = {seriesMateriales} options ={obtenerOptions("Materiales")} 
              onClick = {irGraficaMateriales} height="200" />
            </div>
            <div className = "p-col-3 ">
            <Chart series = {seriesEmpaque} options ={obtenerOptions("Detalle Empaque")} 
              onClick = {irGraficaBudget} height="200" />
            </div>
            </ContenedorPrincipal>
            </div>
        </ContenedorPrincipal>

            
        
    )
}
