import React, { useState, useGlobal, useEffect } from "reactn";
import { obtenerPromediosCultivo } from "../../service/Cultivo";
import { obtenerCiclo } from "../../service/Ciclos";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { dosDecimales, getProductoYVariedadString, separadoComas } from "../../util/functions";
import { TabMenu } from 'primereact/tabmenu';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';

const mapeoTabs = {
    "Clima Exterior": "exterior",
    "Clima Interior": "interior",
    "Riego": "riego",
    "Cultivo": "cultivo"
}
export default function Reporte(props) {
    const [ciclo, setCiclo] = useGlobal("ciclo");

    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [invernaderos, setInvernaderos] = useState([]);
    const [filtroInvernaderos, setFiltroInvernaderos] = useState([])
    const [tabs,] = useState([
        { label: "Clima Exterior" },
        { label: "Clima Interior" },
        { label: "Riego" },
        { label: "Cultivo" },])
    const [promedios, setPromedios] = useState([])
    const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector();
    const [, setWeekGlobal] = useGlobal("weekGlobal");
    const [activeTab, setActiveTab] = useState(null);
    const [, setInvernadero] = useGlobal("invernadero");
    const [rows, setRows] = useState([]);
    const [, setCargando] = useGlobal("cargando");
    const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");

    const procesarPromediosCultivo = (promedios) => {
        const tipos = ["exterior", "interior", "riego", "cultivo"]
    }

    const irInvernadero = (inv) => {
        setInvernadero(inv);
        props.history.push("/cultivo/historico")
    }

    const atras = (params) => {
        props.history.goBack();
    }

    useEffect(() => {
        if (ciclo) {
            updateCicloWeekSelector(ciclo);
        }
    }, [ciclo])

    useEffect(() => {
        if (week.nombre != "Cargando")
            setWeekGlobal(week);
    }, [week]);

    useEffect(() => {
        if (tabs) {
            setActiveTab(tabs[0])
        }
    }, [tabs])

    useEffect(() => {
        setNombreModulo("Cultivo");
        setHabilitadoSelectorCiclo(true)
    }, [])

    useEffect((params) => {
        if (activeTab && promedios) {
            let filtrados = promedios.filter(el => el.tipo === mapeoTabs[activeTab.label])
            setRows(filtrados)
        }
    }, [activeTab, promedios])

    useEffect(() => {
        setCargando(true)
        if (invernaderosVirtuales) {
            getDatosInvernaderos();
            getPromedios();
        }
    }, [invernaderosVirtuales, week])

    useEffect(() => {
        if (invernaderos && invernaderos.length > 0) {
            setFiltroInvernaderos(invernaderos);
        }
    }, [invernaderos])

    const getPromedios = async () => {
        const promedios = await obtenerPromediosCultivo(ciclo, week.time)
        procesarPromediosCultivo(promedios);
        setCargando(false);
        setPromedios(promedios);
    }

    const getDatosInvernaderos = async () => {
        const invernaderos = invernaderosVirtuales.filter(inv => !inv.es_externo)
        setInvernaderos(invernaderos);
    }

    const unidadTemplate = (rowData, column) => {
        let iconoIndicador = null;

        let ttip = <Button className="btn-line btn-help  ml-1" type="button" label="?" tooltip={rowData[`tooltip_${column["field"]}`]} />
        let desviacion = rowData[`desviacion_${column["field"]}`];

        if (desviacion === "media") {
            iconoIndicador = <FontAwesomeIcon icon={faCaretDown} />;
        }
        if (desviacion === "alta") {
            iconoIndicador = <FontAwesomeIcon icon={faCaretUp} />;
        }

        return <div className={rowData[`desviacion_${column["field"]}`]}>{iconoIndicador}<span> {separadoComas(dosDecimales(rowData[column["field"]]))}</span><span className="label-unidad">{rowData["unidades"]}</span>
            {desviacion === "ninguna" ? null : ttip}</div>;
    }

    const templateNombre = (rowData, column) => {
        return <div>{rowData["nombre"]}  </div>;
    }

    const obtenerColumnas = () => {
        let cols = [<Column key={`culti-rep-nombre`} field="nombre" className="col__label" header="Nombre" body={templateNombre} ></Column>]
        filtroInvernaderos.map((el, index) => {
            cols.push(<Column key={`culti-rep-${index}`} body={unidadTemplate} field={el.id} />)
        })
        return cols;
    }


    const obtenerHeader = () => {
        let columns = [<Column key={`culti-rep-head-blank`} header="" rowSpan={1} className="col__label"></Column>]
        filtroInvernaderos.forEach((invernadero, index) => {
            columns.push(<Column key={`culti-rep-head-${index}`} rowSpan={1} className="col__label"
                header={
                    <Button className="invisible-button" tooltip={getProductoYVariedadString(invernadero)} onClick={() => irInvernadero(invernadero)}>
                        <div className={`icon-general icon-mini icon-lort icon-click bor-${invernadero?.color} bg-${invernadero?.color}`}>
                            {invernadero?.nombre}
                        </div>
                    </Button>
                } >

            </Column>)
        });
        let headerGroup = <ColumnGroup>
            <Row>
                {columns}
            </Row>
        </ColumnGroup>;
        return headerGroup;
    }

    const irDetalles = (params) => {
        props.history.push("/cultivo/detalles");
    }

    return <div className="p-grid p-justify-center">

        <div className="p-col-12 title-container">
            <span className="back-btn" onClick={atras}></span>
            <span className="icon-general plant-icon"></span>

            <span className="titles-group">

                <h1 className="header-title">
                    Reporte general de cultivo
                </h1>

            </span>
        </div>

        <div className="p-col-12">
            <div className="p-grid filtros">
                <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
                <div className="form-group p-col">
                    <label htmlFor="cinco">Invernaderos</label>
                    <MultiSelect value={filtroInvernaderos} options={invernaderos} onChange={(e) => setFiltroInvernaderos(e.value)}
                        optionLabel="nombre" dataKey="id" selectedItemsLabel={`${filtroInvernaderos.length} invernaderos seleccionados`} />
                </div>
            </div>
        </div>
        <div className="p-col-12">
            <TabMenu model={tabs} activeItem={activeTab} onTabChange={(e) => setActiveTab(e.value)} />

            <div className="card p-0">
                <DataTable value={rows} headerColumnGroup={obtenerHeader()}>
                    {obtenerColumnas()}
                </DataTable>
            </div>

        </div>
    </div>
}//212