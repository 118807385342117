import React from 'react'

export default function BaseCard(props) {
    return (
        <div className={` p-col  ${props.extra ? props.extra : ""}  ${props.col ? `p-col-${props.col}` : ""}  ${props.md ? `p-md-${props.md}` : ""}  ${props.sm ? `p-sm-${props.sm}` : ""}  ${props.lg ? `p-lg-${props.lg}` : ""} `} onClick={props.onClick}>
            {props.tituloCard ?
                <p className="section-title">{props.tituloCard}</p>
                : null
            }
            <div className='card'>
                {props.children}
            </div>
        </div>
    )
}
