import React, { useGlobal, useEffect, setGlobal } from "reactn";
import { Dropdown } from 'primereact/dropdown';
import { obtenerListaDeSemanas, obtenerInicioSemanaDia, getNombreDiaSemana } from "../../service/fechas";
import { estamosEnProduccion, segundosSemana } from "../../constants";
import { checarHoyEsDomingo } from "util/functions";

setGlobal({ weeks: [], week: { nombre: "Cargando", time: Math.round(Date.now() / 1000) } })
const SEMANAS_SHIFT = 0;//El int representa el # de semanas de atraso

export const useWeekSelector = (adelantado, adelantarDomingo) => {
    const [weekGlobal, setWeekGlobal] = useGlobal("weekGlobal")
    const [weeks, setWeeks] = useGlobal("weeks")
    const [cicloObj] = useGlobal("cicloObj")
    const [ciclo] = useGlobal("ciclo")
    const [week, setWeek] = useGlobal("week")

    useEffect(() => {
        if (ciclo) { asignarListaSemanas(adelantado); }
    }, [ciclo])

    useEffect(() => {
        if (weeks?.length > 0) {
            const shiftSemanasAtras = estamosEnProduccion ? 0 : (segundosSemana * SEMANAS_SHIFT);
                        
            let beginningOfWeek = obtenerInicioSemanaDia(Math.round(Date.now() / 1000));
            let weekNew = weeks.find(el => el.time === beginningOfWeek.unix() - shiftSemanasAtras)
            let selectedWeek = weekGlobal ? (weeks.find(el => el.time === weekGlobal.time) || weekNew) : weekNew;
            setWeek(selectedWeek)
        }
    }, [weeks])

    useEffect(() => {
        if (week.nombre !== "Cargando") setWeekGlobal(week)
    }, [week])

    const handleWeekChange = (ev) => { setWeek(ev.target.value); }
    const updateCiclo = (ciclo) => { }

    const asignarListaSemanas = (isAdelantado) => {
        let tiempoAdelantado = isAdelantado ? (isNaN(JSON.stringify(isAdelantado)) ? 1 : parseInt(isAdelantado)) : 0;
        if (adelantarDomingo && checarHoyEsDomingo()) { tiempoAdelantado++; }//Si hoy es Domingo, adelantar la lista de semanas por 2

        const cierre = Math.round(Date.now() / 1000) + (segundosSemana * tiempoAdelantado);
        const listaSemanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cierre);
        setWeeks(listaSemanas);
    }

    return [weeks, week, handleWeekChange, updateCiclo, setWeek, asignarListaSemanas]
};

const WeekSelector = (props) => {
    return (
        <div className={props.estilo || "form-group p-col"}>
            <label htmlFor="cinco">Semana</label>
            <Dropdown disabled={props.disabled || false} id="filtroSemanas" options={props.weeks} value={props.week} onChange={props.handleWeekChange}
                disabled={props.disabled} placeholder="Seleccionar" dataKey="time" optionLabel="nombre" filter={true} />
        </div>);
}
export default WeekSelector;