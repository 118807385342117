import React from "react"
import TransformWrapper from "../../common/mapa_invernadero/TransformWrapper"
import { POLO_NORTE } from '../../../constants';

const generarKey = (props) => {
    let key = [];
    ["capillaIndex", "poloIndex", "lineaIndex", "claroIndex"].forEach(indexType => {
        if (props[indexType] || props[indexType] === 0)
            key.push(`${indexType}[${props[indexType]}]`)
    })
    return key.join(", ")
}

const TablaBody = (props) =>
    <table className={props.className}>
        <tbody>
            {props.children}
        </tbody>
    </table>

const NombreLinea = (props) =>
    <td key={`no-claro-${props.lineaIndex.toString()}`}>
        <span className="num-col">{props.lineaIndex + 1}</span>
    </td>

const ClaroRegistro = (props) =>
    <td key={generarKey(props)}
        onDoubleClick={() => { props.abrirResumenDeClaro(props.claro) }}>
        <div className={`${props.registrosImportantes(props.claro.enfermedades, props.claro.enfermedadesPasadas)} ${props.cssPrioridad(props.claro.enfermedades, props.claro.enfermedadesPasadas)} mapa-invernadero-claro`}>
            {props.claro.enfermedades.map((enfermedad, enfermedadIndex) => {
                return (<span key={`[${props.capillaIndex}],[${props.poloIndex}],[${props.lineaIndex}],[${props.claroIndex}],[${enfermedadIndex}]`} className={`icon-enfermedad actual cenicilla-leveillula-t-erysiphe-spp`}></span>);
            })}
            {props.claro.enfermedadesPasadas.map((enfermedadPasada, enfermedadPasadaIndex) => {
                return (<span key={`p[${props.capillaIndex}],[${props.poloIndex}],[${props.lineaIndex}],[${props.claroIndex}],[${enfermedadPasadaIndex}]`} className={`icon-enfermedad pasada cenicilla-leveillula-t-erysiphe-spp`}></span>);
            })}
        </div>
    </td>

const ClaroNivelIncidencia = (props) =>
    <td key={generarKey(props)} onDoubleClick={() => props.abrirResumenDeClaro(props.claro)}>
        <div className={`mapa-invernadero-claro${props.claro.enfermedad?.nivelIncidencia ? ` nivel-incidencia-cal-actual-${props.claro.enfermedad.nivelIncidencia}` : ''}${props.claro.enfermedadPasada?.nivelIncidencia ? ` nivel-incidencia-cal-pasado-${props.claro.enfermedadPasada.nivelIncidencia}` : ''}`}></div>
    </td>

const FilaClaros = (props) => {
    let claroFound = props.linea.find(claro => claro.enfermedad?.nivelIncidencia !== undefined)
    return (
        <tr key={generarKey(props)}>
            <td>
                {props.polo.nombre === POLO_NORTE
                    ? <span className="num-fila">{props.polo.contenido.length - props.lineaIndex}</span>
                    : <span className="num-fila">{props.lineaIndex + 1}</span>
                }
            </td>
            {props.linea.map((claro, claroIndex) => {
                return (props.modoEnfermedades
                    ? <ClaroRegistro claro={claro} claroIndex={claroIndex} {...props} /> // claro para enfermedades/plagas/trabajos/etc.

                    : <ClaroNivelIncidencia claro={claroFound || claro}  {...props} /> // claro para niveles de incidencia
                )
            })}
        </tr>
    );
}
const Polo = (props) => {//
    return (
        <tr key={generarKey(props)} className={`polo-${props.polo.nombre}`}>
            <td>
                <div className="capilla-titles">
                    <span className="capilla-polo-title">{`${props.capillaIndex + 1} ${props.polo.nombre}`}</span>
                </div>
                <TablaBody className={"mapa-polo"}>
                    <tr>
                        <td>
                            <TablaBody className={"mapa-capilla-polo"}>
                                {props.children}
                            </TablaBody>
                        </td>
                    </tr>
                </TablaBody>
            </td>
        </tr>
    );
}
const Capilla = (props) => {
    return (
        <td key={generarKey(props)}>
            <TablaBody className={'mapa-capilla'}>
                {props.children}
            </TablaBody>
        </td>
    )
}

const MapaInvernadero = (props) => {
    return (
        <TransformWrapper >
            <TablaBody className={`mapa-invernadero modo-consulta-${props.nombreModoConsulta}${props.modoEnfermedades ? '' : ` enfermedad-seleccionada-prioridad-${props.nombrePrioridad}`}`}>
                <tr className={'capillas'}>

                    {props.mapa.map((capilla, capillaIndex) =>
                        <Capilla capilla={capilla} capillaIndex={capillaIndex} {...props} >

                            {capilla.map((polo, poloIndex) =>
                                <Polo polo={polo} poloIndex={poloIndex} capillaIndex={capillaIndex} {...props}>

                                    <tr>
                                        <td></td>
                                        {polo.contenido[0].map((linea, lineaIndex) =>
                                            <NombreLinea lineaIndex={lineaIndex} />
                                        )}
                                    </tr>
                                    {polo.contenido.map((linea, lineaIndex) =>
                                        <FilaClaros linea={linea} lineaIndex={lineaIndex} polo={polo} {...props} />
                                    )}

                                </Polo>
                            )}

                        </Capilla>
                    )}

                </tr>
            </TablaBody>
        </TransformWrapper>
    )
}
export default MapaInvernadero