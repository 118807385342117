import { firestore, storage } from "firebase";

import getNombresManfiestosUnicos from "components/rechazos/utils/getNombresManfiestosUnicos";
import {
  actualizarManifiesto,
  obtenerManifiestoPorNombre,
} from "./QueriesManifiestos";
import { INVOICE_STATUSES } from "./constants/Rechazos.const";
import { segundosSemana } from "../constants";

export const guardarDatosInvoice = async (cicloId, invoiceData, invoiceId) => {
  if (!cicloId || !invoiceData) { return { error: true, message: "Falta informacion" }; }
  try {
    const invoiceCreated = await firestore().collection("ciclos").doc(cicloId).collection("invoices").doc(invoiceId).set(invoiceData, { merge: true });
    return invoiceCreated;
  } catch (error) {
    console.log("ERROR AL CREAR INVOICE: ", error);
  }
};

export const validarItems = async (cicloId, items, infoRechazos) => {
  const { VALIDADO } = INVOICE_STATUSES;
  const { precioPorLibra, precioPorLibraReal } = infoRechazos;
  const manifiestosUnicos = getNombresManfiestosUnicos(items);
  const manifiestosAActualizarPromises = manifiestosUnicos.map((nombreManifiesto) => { return obtenerManifiestoPorNombre(cicloId, nombreManifiesto); });

  try {
    const manifiestosAActualizar = await Promise.all(manifiestosAActualizarPromises);
    console.log("VALIDAR: ", { items, infoRechazos, manifiestosUnicos, manifiestosAActualizar });

    const manifiestosConItemsActualizados = manifiestosAActualizar.map((manfiesto) => {
      const manifiestoItems = [...manfiesto.items];
      const itemsAModificar = items.filter((item) => item.manifiestoId === manfiesto.id );


      itemsAModificar.forEach((itemAModificar) => {
        const itemAModificarIndex = manifiestoItems.findIndex((itemManifiesto) => (itemManifiesto.itemID === itemAModificar.itemID) || (itemManifiesto.itemID === itemAModificar.itemId));
      
        if(manfiesto.id === "XvVWbAYkJK1YHqe3VQUJ"){
          console.log("TRYING: ",{itemsAModificar,manifiestoItems ,itemAModificarIndex})
        }
        manifiestoItems[itemAModificarIndex].status = VALIDADO;
        manifiestoItems[itemAModificarIndex].precio_libra = Number(precioPorLibra);
        manifiestoItems[itemAModificarIndex].precio_libra_real = Number(precioPorLibraReal);
      });
      return { ...manfiesto, items: manifiestoItems };
    });
    console.log("ACTUALIZAR: ", manifiestosConItemsActualizados)
    const actulizarManifiestosPromises = manifiestosConItemsActualizados.map((manifiesto) => actualizarManifiesto(cicloId, manifiesto.id, manifiesto));
    await Promise.all(actulizarManifiestosPromises);
  } catch (error) {
    console.log("ERROR AL ACTUALIZAR ITEMS DE INVOICE:", error);
  }
};

export const desplazarItemsSiguienteSemana = async (cicloId, items) => {
  const manifiestosUnicos = getNombresManfiestosUnicos(items);
  const manifiestosAActualizarPromises = manifiestosUnicos.map(
    (nombreManifiesto) => {
      return obtenerManifiestoPorNombre(cicloId, nombreManifiesto);
    }
  );
  try {
    const manifiestosAActualizar = await Promise.all(
      manifiestosAActualizarPromises
    );
    const manifiestosConItemsActualizados = manifiestosAActualizar.map((manfiesto) => {
      const { fecha_envio } = manfiesto
      const manifiestoItems = [...manfiesto.items];
      const itemsAModificar = items.filter(
        (item) => item.manifiestoId === manfiesto.id
      );
      itemsAModificar.forEach((itemAModificar) => {
        const itemAModificarIndex = manifiestoItems.findIndex(
          (itemManifiesto) => itemManifiesto.itemID === itemAModificar.itemID
        );
        const fecha_desplazada = manifiestoItems[itemAModificarIndex].fecha_desplazada
        if (fecha_desplazada) {
          manifiestoItems[itemAModificarIndex].fecha_desplazada = Number(fecha_desplazada) + segundosSemana
        } else {
          manifiestoItems[itemAModificarIndex].fecha_desplazada = Number(fecha_envio) + segundosSemana
        }

      });
      return { ...manfiesto, items: manifiestoItems };
    }
    );
    const actulizarManifiestosPromises = manifiestosConItemsActualizados.map(
      (manifiesto) => actualizarManifiesto(cicloId, manifiesto.id, manifiesto)
    );
    await Promise.all(actulizarManifiestosPromises);
  } catch (error) {
    console.log("ERROR AL ACTUALIZAR ITEMS DE INVOICE:", error);
  }
};

export const subirArchivosDeInvoice = async (invoiceFiles) => {
  const fecha = Date.now();
  const storageRef = storage().ref();
  const uploadFilesPromises = Array.from(invoiceFiles).map((file) => {
    const archivoRef = storageRef.child(`invoices/${fecha}_${file.name}`);
    return archivoRef.put(file);
  });
  let result = { error: false, response: [] };
  try {
    const response = await Promise.all(uploadFilesPromises);
    result.response = response;
  } catch (error) {
    console.log("ERROR AL SUBIR ARCHIVOS INVOICE: ", error);
    result.error = true;
  }
  return result;
};

export const getInfoFiles = async (response) => {
  const dataFilesPromises = response.map((fileRef) => getInfoFile(fileRef));
  let result = { error: false, response: [] };
  try {
    const response = await Promise.all(dataFilesPromises);
    result.response = response;
  } catch (error) {
    result.error = true;
    console.log("ERROR AL OBTENER INFO FILES:", error);
  }
  return result;
};

const getInfoFile = async (fileRef) => {
  const name = fileRef.ref.name;
  const url = await fileRef.ref.getDownloadURL();
  const metaData = await fileRef.ref.getMetadata();
  const creationDate = metaData.timeCreated;
  return { name, url, creationDate, metaData };
};
