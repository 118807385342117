import * as firebase from "firebase/app";
import "firebase/firestore";
import { uploadStatistics } from './Estadisticas'
import { flexDate } from "./fechas";

function registrarInvocaciones(count) {
    uploadStatistics(count)
}
export const obtenerRegistroActividadesDia = async (ciclo, invernadero, dia) => {
    let db = firebase.firestore();
    const timestamps = flexDate(dia)
    const result = await db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero).collection("registro_actividades").where("dia", ">=", timestamps.inicio).where("dia", "<=", timestamps.fin).get()
    registrarInvocaciones(result.size)

    return result
}
export const getRegistroActividadesDia = async (ciclo, invernadero, dia) => {
    const timestamps = flexDate(dia)
    const result = await firebase.firestore().collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero).collection("registro_actividades").where("dia", ">=", timestamps.inicio).where("dia", "<=", timestamps.fin).get()
    registrarInvocaciones(result.size)
    const registros = result.docs.map(el => ({ id: el.id, ...el.data() }))
    return registros
}
export const obtenerRegistroActividadesSemana = async (ciclo, invernadero, semana) => {
    let db = firebase.firestore();
    const result = await db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero)
        .collection("registro_actividades").where("dia", ">=", semana-25200).where("dia", "<=", semana + 561600).get();
    registrarInvocaciones(result.size)
    return result
}
export const getRegistroActividadesSemana = async (ciclo, invernadero, semana) => {
    const result = await firebase.firestore().collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero)
        .collection("registro_actividades").where("dia", ">=", semana).where("dia", "<=", semana + 561600).get();
    registrarInvocaciones(result.size)
    const registros = result.docs.map(el => ({ id: el.id, ...el.data() }))
    return registros
}
export const getRegistroActividadesConRealizadasSemana = async (ciclo, invernadero, semana, fin) => {
    const result = await firebase.firestore().collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero)
        .collection("registro_actividades").where("dia", ">=", semana).where("dia", "<=", fin || (semana + 561600)).get();
    registrarInvocaciones(result.size)
    const registros = result.docs.map(el => ({ id: el.id, ...el.data(), realizadas: [] }))

    let promises = registros.map(el => getActividadesRealizadasDesdeRegistroActividades(ciclo, invernadero, el.id));
    const response = await Promise.all(promises)

    for (const index in registros) {
        if (!registros[index].id) console.log("!!")
        registros[index].realizadas = response[index]
    }

    return registros
}
export const obtenerActividadesRealizadasDesdeRegistroActividades = async (pathRegistroActividades) => {
    let db = firebase.firestore();
    const result = await db.doc(pathRegistroActividades).collection("actividades_realizadas").get()
    registrarInvocaciones(result.size)
    return result
}
export const getActividadesRealizadasDesdeRegistroActividadesPath = async (pathRegistroActividades) => {
    const response = await firebase.firestore().doc(pathRegistroActividades).collection("actividades_realizadas").get();
    registrarInvocaciones(response.size);
    const activades = response.docs.map(el => ({ id: el.id, ...el.data() }));
    return activades;
}
export const getActividadesRealizadasDesdeRegistroActividades = async (ciclo, invernaderoId, registrosId) => {
    // console.log(JSON.stringify({ciclo, invernaderoId, registrosId}))
    //  if(!ciclo || !invernaderoId || !registrosId)console.log(JSON.stringify({ciclo, invernaderoId, registrosId}))
    const response = await firebase.firestore().collection("ciclos").doc(ciclo).collection("invernaderos_virtuales")
        .doc(invernaderoId).collection("registro_actividades").doc(registrosId).collection("actividades_realizadas").get();
    registrarInvocaciones(response.size)
    const activades = response.docs.map(el => ({ id: el.id, ...el.data() }))
    return activades;
}

export const obtenerRegistroActividadesImportantesRealizadasEmpleadoSemana = async (ciclo, invernaderoRef, semana, empleado, actividadesImportantes) => {
    try {
        const db = firebase.firestore();
        //obtener registros actividades
        // 
        // 
        // 
        // 
        let resultRegistro = await db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernaderoRef).
            collection("registro_actividades").where("dia", ">=", semana.time).where("dia", "<=", semana.time + 561600).get();
        registrarInvocaciones(resultRegistro.size)
        let promisesActividadesRealizadas = [];

        resultRegistro.forEach(result => {

            promisesActividadesRealizadas.push(
                db.doc(result.ref.path).collection("actividades_realizadas").where("empleado_ref", "==", empleado).get()
            )
        })

        let values = await Promise.all(promisesActividadesRealizadas);

        registrarInvocaciones(values.length)
        // 
        // 
        let result = values.map(snap => {
            if (snap.empty) {
                return { empty: true };
            } else {

                let first = snap.docs[0];

                let data = first.data();

                data.id = first.id;
                return data;
            }
            /*
            return snap.docs.map(el => {
                let data = el.data();
                data.id = el.id;
                return data;
            })*/
        })
        //
        return result;

    } catch (error) {
        console.log("ERROR OBTENER REGISTROS DE ACTIVIDADES IMPORTANTES")
        console.log(error);
        throw (error);
    }

}

export const obtenerReferenciaInvernaderoAsignadoEmpleado = async (ciclo, empleado) => {
    try {
        const db = firebase.firestore();
        let snap = await db.collection("empleados_invernadero_virtual_de_ciclo").where("ciclo_ref", "==", ciclo).where("empleado_ref", "==", empleado).get();
        registrarInvocaciones(snap.size)
        if (!snap.empty) {


            return snap.docs[0].data().invernadero_virtual_ref;
        } else {
            return null;
        }
    } catch (error) {
        throw (error);
    }
}
//101