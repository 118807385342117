import * as firebase from "firebase/app";
import "firebase/firestore";
import {getGlobal} from 'reactn'
import {  nombresDiasSemanaExtendida} from '../constants';
import {obtenerDiasDeSemanaExtendidos, obtenerSemanaAnterior, obtenerListaDeSemanas} from "./fechas";
import {  obtenerInvernaderosVirtualesHabilitados} from './Invernaderos';
import { obtenerPesoPromedioFruto } from './ReporteCalidad'
import { obtenerPesoRegistradoCicloHastaSemana, obtenerPesoRegistradoSemana, obtenerPesoSemana } from "./Producto";
import {uploadStatistics} from './Estadisticas'

function registrarInvocaciones(count){
    uploadStatistics(count)
}

const aliasConductividad = "EC Drenaje";
const aliasTemperatura = "Prom 24 Hrs T";
const aliasRadiacion = "Rad Sum";  
const aliasPh = "pH Gotero";

export function registrarConfiguracionCultivo(ciclo,invernadero, estado){
    const db = firebase.firestore();
    return db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero)
        .update({
            configuracion_inicial_cultivo:estado
        })
}
export function guardarConfiguracionCultivo(){

}

export function registrarCambioConfiguracionCultivo(){

}

export const obtenerMedidasCultivo = () => {
    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
        db.collection("medidas_cultivo").orderBy("orden").get().then((resp) => {
            registrarInvocaciones(resp.size)
            let result = [];
            resp.forEach(item => {
                let data = item.data();
                data.id = item.id;
                result.push(data);
            });
            resolve(result);
        }).catch((err) => {
            reject(err);
        })    
    });
    
};  
export const guardarRegistroCultivo = (ciclo,invernadero,semana,datos) => {
    // 
    // 
    // 
    // 

    
    return new Promise((resolve, reject) => {
        const db = firebase.firestore();
        const listaDias = obtenerDiasDeSemanaExtendidos(semana);
        listaDias.forEach((dia,index)=>{
            db.collection("ciclos").doc(ciclo)
                .collection("invernaderos_virtuales").doc(invernadero)
                .collection("cultivo").where("dia","==",dia).get().then((resp) => {
                    registrarInvocaciones(resp.size)
                    if(resp.empty){
                        db.collection("ciclos").doc(ciclo)
                        .collection("invernaderos_virtuales").doc(invernadero)
                        .collection("cultivo").add({
                            dia:dia,
                            medidas:procesarFormulario(datos,index)
                        }).then(resolve(true)).catch(err=>reject(err))
                    }
                    else {
                        db.doc(resp.docs[0].ref.path).update(
                            {
                                medidas:procesarFormulario(datos,index)
                            }
                        ).then((result) => {
                          resolve(true)  
                        }).catch((err) => {
                            reject(err)
                        });
                    }
                })
        })
    });
};

export function procesarFormulario(datos,index){
    // 
    // 
    // 
    const dias = nombresDiasSemanaExtendida;
    const dia  = dias[index];
    const secciones = ["exterior","interior","riego","cultivo"];
    let data = [];
    // 
    secciones.forEach(seccion => {        
        
        let arrSeccion = datos[seccion];
        
        arrSeccion.forEach(row=>{
            let temp = {medicion: row[dia], medicion_ref: row["id"], tipo: seccion}
            data.push(temp)
        })
        
        
        
    });
    // 
    // 
    return data;
}

export function obtenerRegistrosSemanalesCultivo(ciclo,invernadero,semana){
    return new Promise((resolve, reject) => {
        const db = firebase.firestore();
        let diasSemana = obtenerDiasDeSemanaExtendidos(semana);
        let result = [];
        db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero)
            .collection("cultivo").where("dia",">=", diasSemana[0]).where("dia","<=",diasSemana[6])
            .get().then(resp=>{
                registrarInvocaciones(resp.size)
                resp.forEach(item => {
                    let data = item.data();
                    data.id = item.id;
                    result.push(data);
                });
                resolve(result)
            }).catch(err=>{
                reject(err);
            })    
            });
    
}
const obtenerResumenMedida =(ciclo,invernadero,semana,aliasMedida)=>{
    const db = firebase.firestore();
    const diasSemana = obtenerDiasDeSemanaExtendidos(semana);
    return new Promise((resolve, reject) => {
        
        obtenerIdMedida(aliasMedida).then((idMedida) => {
            
            db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero)
                .collection("cultivo").where("dia",">=",diasSemana[0]).where("dia","<=", diasSemana[6]).get()
                .then((result) => {
                    registrarInvocaciones(result.size)
                    
                    //
                    let mediciones = extraerMedicionesDocs(result,idMedida);
                    let promedio = obtenerPromedio(mediciones);
                    resolve(promedio);
                }).catch((err) => {
                    reject(err);
                });
        }).catch((err) => {
            reject(err);
        });
    });
}

export const obtenerResumenMedidas = (ciclo, invernadero, semana) => {
    let promises = [];
    //return new Promise((resolve, reject) => {
        promises.push(obtenerResumenRadiacion(ciclo, invernadero, semana))
        promises.push(obtenerResumenTemperatura(ciclo, invernadero, semana))
        promises.push(obtenerResumenPh(ciclo, invernadero, semana))
        promises.push(obtenerResumenConductividad(ciclo, invernadero, semana))
      return Promise.all(promises); 
    //});
};
const obtenerResumenRadiacion = (ciclo, invernadero, semana) => {
    
    return obtenerResumenMedida(ciclo, invernadero, semana,aliasRadiacion);
}

const obtenerResumenTemperatura = (ciclo, invernadero, semana) => {
    return obtenerResumenMedida(ciclo, invernadero, semana,aliasTemperatura);
}

const obtenerResumenPh = (ciclo, invernadero, semana) => {
    return obtenerResumenMedida(ciclo, invernadero, semana,aliasPh);
}
const obtenerResumenConductividad = (ciclo, invernadero, semana) => {
    return obtenerResumenMedida(ciclo, invernadero, semana,aliasConductividad);
}
const obtenerIdMedida = (alias)=>{
    
    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
        db.collection("medidas_cultivo").where("abreviacion","==",alias).get().then((resp) => {
            registrarInvocaciones(resp.size)
            if(resp.empty){
                resolve(false)
            }else{
                resolve(resp.docs[0].id)
            }
        }).catch((err) => {
            reject(err);
        });
    });
}

const extraerMedicionesDocs = (resp,id) => {
    let result = [];
    //
    resp.forEach(item => {
        let data = item.data();        
        let obj = data["medidas"].find(el=>el["medicion_ref"] === id);
        if(obj){
            // 
            // 
            //         
            // 
            let medicion = obj["medicion"];
            result.push(medicion);
        }
    });
    return result;
}
const obtenerPromedio = (medidas) => {
    let cuentaRegistros = 0;
    let suma = 0;
    medidas.forEach(item => {
        let parsed = parseFloat(item);
        if(!isNaN(parsed)){
            cuentaRegistros+=1;
            suma +=parsed;
        }
    });
    if(cuentaRegistros === 0){
        return 0;
    }else{
        return suma/cuentaRegistros;
    }
}

export const obtenerPromediosCultivo = async (ciclo,semana) => {
    const medidas = await obtenerMedidasCultivo()
    const invernaderos = getGlobal().invernaderosVirtuales.filter(inv=>!inv.es_externo && inv.habilitado) || await obtenerInvernaderosVirtualesHabilitados(ciclo)
    const promedios = await obtenerPromediosCultivoInvernaderos(medidas,ciclo,invernaderos,semana)
    return procesarPromedios(medidas,promedios)
};

const procesarPromedios = (medidas,invernaderos) => {
    let resultMedidas =  medidas.map(medida => {
        let obj =  {nombre:medida.nombre, id: medida.id,tipo:medida.tipo, unidades:medida.unidades}
        invernaderos.forEach(invernadero => {
            let tooltipsVariedad = medida["tooltips"]?medida["tooltips"][invernadero.producto_ref]:"";
            let min = "";
            let max ="";
            if(tooltipsVariedad){
                min = tooltipsVariedad["min"];
                max = tooltipsVariedad["max"];
            }
            min = parseFloat(min);
            max = parseFloat(max);
            if(isNaN(min)){
                if(isNaN(max)){
                    obj[`tooltip_${invernadero.invernadero}`] = ""
                }else{
                    
                    obj[`tooltip_${invernadero.invernadero}`] = `<div class = 'tooltip-datas'>
                    <div class = 'tooltip-data'> Valor máximo recomendado: ${max} ${medida["unidades"]}</div></div>`
                }
            }else{
                if(isNaN(max)){
                    obj[`tooltip_${invernadero.invernadero}`] = `
                    <p class='tooltip-title'>Valores recomendados</p>
                    <div class = 'tooltip-datas'>
                      <div class = 'tooltip-data'>
                        ${min} ${medida["unidades"]} 
                        <p class='tooltip-label'>Mínimo</p>
                      </div>
                    </div>`
                }else{
                    
                    obj[`tooltip_${invernadero.invernadero}`] = `
                    <p class='tooltip-title'>Valores recomendados</p>
                    <div class = 'tooltip-datas'>
                      <div class = 'tooltip-data'>
                        ${min} ${medida["unidades"]} 
                        <p class='tooltip-label'>Mínimo</p>
                      </div>
                      <div class = 'tooltip-data'> 
                        ${max} ${medida["unidades"]}
                        <p class='tooltip-label'>Máximo</p>
                      </div>
                  </div>`
                }
            }
            let found = invernadero.promedios.find(el=>el.medidaId=== medida.id);
            if(found ){
                let desviacion = "ninguna";
               
                if(!isNaN(max) && found.promedio > max){
                    desviacion = "alta"
                }else if(!isNaN(min) && found.promedio < min){
                    desviacion= "media"
                }else{
                    desviacion = "ninguna"
                }
                obj[invernadero.invernadero] = found.promedio;
                obj[`desviacion_${invernadero.invernadero}`] = desviacion;
            }            
            else{
                obj[invernadero.invernadero] = 0;
            }   
        });
        return obj;
        
    });
    let pesoPromedio = {nombre:"Peso promedio de fruta", tipo:"cultivo", tooltip:"", id:"peso_promedio_fruta", };
    let produccionSemanal = {nombre:"Producción semanal", tipo:"cultivo", tooltip:"", id:"produccion_semanal", };
    let produccionAcumulado = {nombre:"Producción acumulado", tipo:"cultivo", tooltip:"", id:"produccion_acumulado", };
    invernaderos.forEach(invernadero => {
        pesoPromedio[invernadero.invernadero] = isNaN(invernadero["outputs"][0])?0:invernadero["outputs"][0];        
        produccionSemanal[invernadero.invernadero] = invernadero["outputs"][1];
        produccionAcumulado[invernadero.invernadero] = invernadero["outputs"][2];
    })
    let resultOutputs = [pesoPromedio, produccionSemanal, produccionAcumulado];
    return resultMedidas.concat(resultOutputs);
}

const obtenerPromediosCultivoInvernaderos = (medidas,ciclo, invernaderos,semana) => {
    const db = firebase.firestore();
    let promises = [];
    const diasSemana = obtenerDiasDeSemanaExtendidos(semana);
    
    invernaderos.forEach(invernadero => {
        promises.push(new Promise(async(resolve, reject) => {
        let listaPromedios = await obtenerPromediosCultivoPorInvernadero(ciclo,invernadero.id,semana,medidas);
        let outputs = await obtenerOutputsRegistroCultivo(ciclo,invernadero,semana);
                    // 
                    // 
                    // 
                resolve({invernadero:invernadero.id,promedios:listaPromedios, producto_ref: invernadero.producto_ref,outputs:outputs})    
                
           
        })); 
    });
    return Promise.all(promises);
    
}
const obtenerPromediosCultivoPorInvernadero = (ciclo,invernadero,semana,medidas) => {
    
    const db = firebase.firestore();
    const diasSemana = obtenerDiasDeSemanaExtendidos(semana);
    return new Promise((resolve, reject) => {
        db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero)
        .collection("cultivo").where("dia",">=",diasSemana[0]).where("dia","<=", diasSemana[6]).get()
            .then((dias) => {
                registrarInvocaciones(dias.size)
                
                    let listaPromedios = [];
                    medidas.forEach(medida => {
                        let mediciones = extraerMedicionesDocs(dias,medida.id)    
                        let promedio = obtenerPromedio(mediciones)
                        listaPromedios.push({medidaId: medida.id, promedio:promedio});
                    });
                resolve(listaPromedios)    
            }).catch((err) => {
                reject(err); 
            });
    });
}
export const obtenerPromediosHistoricos =(ciclo,invernadero,semana)=>{
    return new Promise((resolve, reject) => {
        obtenerMedidasCultivo().then((medidas) => {
            let pasada = obtenerSemanaAnterior(semana);
            let antepasada = obtenerSemanaAnterior(pasada);
            let anteantepasada = obtenerSemanaAnterior(antepasada);
            let anteanteantepasada = obtenerSemanaAnterior(anteantepasada);
            
            let semanas = [antepasada, pasada, semana,anteantepasada, anteanteantepasada] ;
            // 
            // 
            let promises = [];
            semanas.forEach(currSemana => {
                promises.push(obtenerPromediosCultivoPorInvernadero(ciclo,invernadero, currSemana, medidas))    
            });
            Promise.all(promises).then((semanasPromedios) => {
                let result = semanasPromedios.map(currPromedios => {
                    return agregarDetallesActividades(medidas,currPromedios);
                });
                resolve(result);
            }).catch((err) => {
                reject(err)    
            });
        });
        
    });
}
export const obtenerReportesSemanalesCiclo = (ciclo,invernadero,medidas) => {
    let listaSemanas = obtenerListaDeSemanas(ciclo.semana_inicio, ciclo.semana_cierre);
    let promises = listaSemanas.map(el=>obtenerPromediosCultivoPorInvernadero( ciclo.id,invernadero.id, el.time,medidas));
    return Promise.all(promises);
}

const agregarDetallesActividades = (medidas,currPromedios) => {
    return currPromedios.map(promedio => {
        let medida = medidas.find(el=>el.id=== promedio.medidaId)
        let copia = Object.assign({},promedio);
        copia["nombre"] = medida.nombre;
        copia["alias"] = medida.abreviacion;
        copia["unidades"] = medida.unidades;
        copia["tipo"] = medida.tipo;
        return copia;
    });
}
const configInicial  = {
    semana_plantacion:"",
    anio_plantacion:"",
    portainjerto:"",
    tipo_plastico:"",
    anio_plastico:"",
    marca_sustrato:"",
    tipo_sustrato:""

}
export const obtenerDetallesInvernaderos = (ciclo) => {
    return new Promise((resolve, reject) => {
        const db = firebase.firestore();
        db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").get()
            .then((result) => {
                registrarInvocaciones(result.size)
                let arr = [];
                // 
                result.forEach(item => {
                    let data = item.data();
                    
                    let config = data.configuracion_inicial_cultivo
                    if(config){
                        config["nombre"] = data.nombre;
                        config["id"] = item.id;
                        arr.push(config);
                    }else{
                        config  = configInicial;
                        config["nombre"] = data.nombre;
                        config["id"] = item.id;
                        arr.push(config);
                    }
                    
                });
                resolve(arr);
            }).catch((err) => {
                reject(err);
        });    
    });
    
}
export const obtenerOutputsRegistroCultivo = (ciclo,invernaderoObj, semana) => {
    return new Promise(async (resolve, reject) => {
        try {
            
            let detArea = invernaderoObj.detalles_fisicos.find(el=>el.detalle ==="Area (m²)")
            let area =  parseInt(detArea["norte"]) + parseInt(detArea["sur"])
            let pesoFruto = await obtenerPesoPromedioFruto(ciclo,invernaderoObj.id,semana);            
            let produccionSemana = await obtenerPesoSemana(ciclo,invernaderoObj.id, semana);
            let produccionCiclo = await obtenerPesoRegistradoCicloHastaSemana(ciclo,invernaderoObj.id,semana);
            produccionCiclo = produccionCiclo/area;
            resolve([pesoFruto, produccionSemana/area, produccionCiclo])
        } catch (error) {
            reject(error);
        }
    })
    

    //obtener produccion de la semana
    //obtener produccion acumulada
}
export const obtenerOutputsHistoricos = async (cicloId, invernadero, semana) => {
    try {        
        let pasada = obtenerSemanaAnterior(semana);
        let antepasada = obtenerSemanaAnterior(pasada);
        let anteAntepasada = obtenerSemanaAnterior(antepasada);
        let anteAnteAntepasada = obtenerSemanaAnterior(anteAntepasada);
        
        let semanas = [antepasada, pasada, semana, anteAnteAntepasada, anteAnteAntepasada];
        let promises = semanas.map(el=>obtenerOutputsRegistroCultivo(cicloId, invernadero,el))
        let result = await Promise.all(promises);
        return result
    } catch (error) {
        console.log("ERROR OOH");
        console.log(error);
        throw error;
    }
}

export async function obtenerResumenCardCultivo(ciclo,invernadero,semana){    
    let copia = {...invernadero};
    
    let [respRadiacion, respTemperatura,respPh,respConductividad] = await obtenerResumenMedidas(ciclo,invernadero.id,semana);
    copia["radiacion"] = respRadiacion;
    copia["temperatura"] = respTemperatura;
    copia["ph"] = respPh;
    copia["conductividad"] = respConductividad;
    return copia;
}

export const identificarInvernaderosConRegistro = async (cicloId,semana, invernaderos) => {
    
    let promises = invernaderos.map(invernadero=>identificarSiTieneRegistro(cicloId, semana, invernadero))
    let result =  await Promise.all(promises);
    return invernaderos.map((el,index)=>({id:el.id, tiene_registro:result[index]}))
}
const identificarSiTieneRegistro = async (cicloId, semana, invernadero) => {
    try {        
        const db = firebase.firestore();
        let snap = await db.collection("ciclos").doc(cicloId).collection("invernaderos_virtuales").doc(invernadero.id).
            collection("cultivo").
            where("dia",">=", semana).where("dia","<", semana + 7 * 24 * 60 * 60).get();
        registrarInvocaciones(snap.size)
        return !snap.empty;
    } catch (error) {
        console.log("ERROR ISTR");
        console.log(error);
        throw error;
    }
}//528