import React from "react"
const ContenedorCardFormulario = (props) => {
    return (
        <div className="p-col-12">
            <div className="card">
                {props.titulo && <p className="section-title">{props.titulo}</p>}
                {props.children}
            </div>
        </div>
    )
}
export default ContenedorCardFormulario;