import React, { useState, useEffect, useGlobal } from 'reactn';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { obtenerActividades } from '../../../service/actividades';
import { Button } from 'primereact/button';
import ContenedorPrincipal from '../../common/ContenedorPrincipal';
import ContenedorHeader from '../../common/ContenedorHeader';
import RowBotonesTop from '../../common/RowBotonesTop';
import ContenedorBotonesTop from '../../common/ContenedorBotonesTop';
import ContenedorTablaAdmin from '../../common/ContenedorTablaAdmin';

const Presentaciones = (props) => {
  const [usuario] = useGlobal("usuario");
  const [, setNombreModulo] = useGlobal("nombreModulo");
  const [actividades, setActividades] = useState("actividades");

  useEffect(() => {
    setNombreModulo("Actividades")
    obtenerActividades().then(actividades => {
      setActividades(actividades);
    });
  }, [])

  const puedeCrear = () => usuario.rol === "Administrador";
  const crearActividad = () => { props.history.push("/admin/actividades/crear"); }
  const irActividad = (event) => { props.history.push(`/admin/actividades/modificar/${event.data.id}`); }
  const bodyImportante = (rowData, column) => {
    let field = rowData[column["field"]];
    return field ? "Sí" : "No";
  }
  return (
    <ContenedorPrincipal>
      <ContenedorHeader titulo="Administrar actividades" iconos="activity-icon" col="p-col p-col-auto"></ContenedorHeader>

      {puedeCrear() &&
        <ContenedorBotonesTop>
          <RowBotonesTop>
            <Button label="Crear nueva" onClick={crearActividad} />
          </RowBotonesTop>
        </ContenedorBotonesTop>}

      <ContenedorTablaAdmin>
        <DataTable selectionMode="single" className="tabla-actividades" value={actividades} onRowClick={irActividad} scrollable={true} >
          <Column field="nombre" header="Nombre de actividad" sortable={true} />
          <Column field="importante" header="Importante" body={bodyImportante} sortable={true} />
          <Column field="unidades" header="Unidad de medida" sortable={true} />
        </DataTable>
      </ContenedorTablaAdmin>
    </ContenedorPrincipal>);
}
export default Presentaciones;//85