import React, { useState, useEffect, useGlobal } from 'reactn';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';

import { obtenerClientes } from "../../../service/Clientes";
import { registrarPresentacion, guardarPresentacion, obtenerPresentaciones, getCategoria, guardarCategoria, getCategorias } from '../../../service/Presentaciones';

import { usePrimeReactTable } from "../../common/tableHandler";
import SeccionFormulario from '../../common/SeccionFormulario';
import ContenedorInput from '../../common/ContenedorInput';
import ColumnContenedor from '../../common/ColumnContenedor';
import PreviewModal from '../../common/PreviewModal'
import { factorLbKg } from '../../../constants';
import { MultiSelect } from 'primereact/multiselect';
import RowBotonesTop from '../../common/RowBotonesTop';
import { DeepClone } from '../../../util/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';


let inicial = {
    nombre: "",
    presentaciones: [],
    rangos_bono_empaque: [
        { orden: 1, min: "0", max: "", bono: "" },
    ]
}
const FormularioCategoria = (props) => {
    let fileInput = React.useRef(null);
    const categoriaID = props.match.params.categoriaID
    const editando = props.match.params.categoriaID ? true : false;
    const [cargando, setCargando] = useGlobal("cargando")
    const [, setMostrarModalPermisos] = useGlobal("mostrarModalPermiso")
    const [, setModal] = useGlobal("modal");
    const [usuario,] = useGlobal("usuario");
    const [presentaciones, setPresentaciones] = useGlobal("presentaciones");
    const [presentacionesFiltrados, setPresentacionesFiltrados] = useState([])
    const [, setModalConfirmacion] = useGlobal("modalConfirmacion");
    const [ciclo, setCiclo] = useGlobal("ciclo");

    const [presentacion, setPresentacion] = useState();
    const [editing, setEditing] = useState(false);
    const [clientes, setClientes] = useState([]);
    const [productos, setProductos] = useState([]);
    const [archivos, setArchivos] = useState([]);
    const [fotosPorEliminar, setFotosPorEliminar] = useState([]);
    const [errores, setErrores] = useState([]);
    const [previewDialog, setPreviewDialog] = useState("")
    const [state, setState] = useState(inicial)

    const puedeEditar = (campo) => ["Administrador"].includes(usuario.rol);

    const atras = (params) => {
        setPresentacion(null);
        props.history.goBack()
        //props.history.replace("/admin/bono_empaque/tabla");
    }
    useEffect(() => {
        setErrores([])
    }, [state])

    const [nombreModulo, setNombreModulo] = useGlobal("nombreModulo");
    const guardar = async () => {
        let errores = validar();
        if (errores.length > 0) {
            setErrores(errores);
            return;
        }
        else {
            setCargando(true)
            let data = { ...state }
            await guardarCategoria(data, categoriaID)
        }

        setCargando(false)
        atras()
    }
    const validar = () => {
        let errores = [];

        const requeridos = ["nombre"];
        for (let x = 0; x < requeridos.length; x++) {
            if (!state[requeridos[x]]) errores.push(requeridos[x]);
        }
        if (state.presentaciones.length === 0) errores.push("presentaciones");

        state.rangos_bono_empaque.forEach(el => {
            if (!el.max || !el.bono) errores.push("bono");
        })

        return errores;
    }
    const cancelar = () => {
        setPresentacion(null);
        setEditing(false);
        props.history.goBack()
    }

    useEffect(() => {
        setNombreModulo("Categorias");
        if (editando) setEditing(true);
        else setEditing(false)
        obtenerDatosIniciales()
    }, [])

    const obtenerDatosIniciales = async () => {
        setCargando(true)
        let presentacionesAsignados = []

        let allCategoriasPromise = getCategorias();
        let presesPromise = obtenerPresentaciones();

        let [allCategorias, preses] = await Promise.all([allCategoriasPromise, presesPromise])

        allCategorias.forEach(el => {
            presentacionesAsignados.push(...el.presentaciones)
        });


        if (editando && categoriaID) {
            let categoriaDatos = await getCategoria(categoriaID)
            preses = preses.filter(pres => !presentacionesAsignados.includes(pres.id) || categoriaDatos.presentaciones.includes(pres.id))
            setPresentacionesFiltrados(preses)
            setState(categoriaDatos)
        } else preses = preses.filter(pres => !presentacionesAsignados.includes(pres.id))

        setPresentacionesFiltrados(preses)
        setCargando(false)
    }

    const handleInputChange = (ev) => {
        setErrores([]);
        const stateCopy = Object.assign({}, state);
        let value = ev.target.value;
        stateCopy[ev.target.name] = value;
        setState(stateCopy);
    }
    const handleRangoBonoChange = (index, tipo, valor) => {
        let copia = { ...state };
        copia["rangos_bono_empaque"][index][tipo] = valor;
        setState(copia);
    }
    const agregarRango = () => {
        let rangos_bono_empaque = state.rangos_bono_empaque
        rangos_bono_empaque.push({ orden: rangos_bono_empaque.length, min: "", max: "", bono: "" })
        setState({ ...state, rangos_bono_empaque })
    }

    const eliminarItem = (index) => {
        let stateCopy = DeepClone(state)
        stateCopy.rangos_bono_empaque.splice(index, 1)
        setState(stateCopy)
    }

    const calcMin = (index) => {
        if (index === 0) return 0
        else return parseInt(state.rangos_bono_empaque[index - 1]?.max || 0) + 1
    }

    return <div className="p-grid p-justify-center">
        <div className="p-col-12 title-container">
            <span className="back-btn" onClick={atras}></span>
            {editing ?
                <React.Fragment>
                    <span className="icon-general presentacion-icon"></span>
                    <span className="titles-group">
                        <h1 className="header-title">Modificar categoria</h1>
                    </span>
                </React.Fragment>
                :
                <React.Fragment>
                    <span className="icon-general presentacion-icon"></span>
                    <span className="titles-group">
                        <h1 className="header-title">Crear categoria</h1>
                    </span>
                </React.Fragment>
            }

        </div>
        <div className="p-col-10">
            <div className="p-grid p-fluid ">
                <div className="content-section implementation">

                </div>
                <div className="p-col-12">
                    <p className="section-title">Información general</p>
                    <div className="card">
                        <div className="p-grid">

                            <div className="form-group p-col-12 p-md-6 p-sm-6">
                                <label >Nombre de categoria</label>
                                <InputText name="nombre" value={state.nombre} onChange={handleInputChange} />
                                {errores.includes("nombre") && <Message severity="error" text="Este campo es requerido" />}
                            </div>

                            <div className="form-group p-col-12 p-md-6 p-sm-6">
                                <label >Asociar presentaciones</label>
                                <MultiSelect name="presentaciones" value={state.presentaciones} options={presentacionesFiltrados} onChange={handleInputChange} filter={true}
                                    optionLabel="presentacion" optionValue="id" selectedItemsLabel={`${state.presentaciones.length} presentaciones seleccionadas`} />
                                {errores.includes("presentaciones") && <Message severity="error" text="Este campo es requerido" />}
                            </div>

                        </div>
                    </div>
                </div>

                <SeccionFormulario titulo="Bonos empaque" >
                    {state.rangos_bono_empaque.map((el, index) => {
                        return <div key={index} className="form-group p-grid p-fluid pallet-dailyweight">

                            <div className="p-col-auto">
                                <p className="pallet-id">Rango {index + 1}</p>
                            </div>
                            <div className="p-col p-grid ">
                                <div className="p-col-auto p-justify-end p-grid p-align-center m-0">
                                    <label >Min</label>
                                </div>
                                <div className="p-col">
                                    <InputText disabled={true} id={index} name="min" placeholder="min" value={calcMin(index)} onChange={(e) => { handleRangoBonoChange(index, "min", e.target.value) }} />
                                </div>
                            </div>
                            <div className="p-col p-grid ">
                                <div className="p-col-auto p-justify-end p-grid p-align-center m-0">
                                    <label >Max</label>
                                </div>
                                <div className="p-col">
                                    <InputText id={index} name="max" placeholder="max" value={el.max} onChange={(e) => { handleRangoBonoChange(index, "max", e.target.value) }} />
                                </div>
                            </div>
                            <div className="p-col p-grid ">
                                <div className="p-col-auto p-justify-end p-grid p-align-center m-0">
                                    <label >Valor</label>
                                </div>
                                <div className="p-col">
                                    <InputText id={index} name="valor" placeholder="valor" value={el.bono} onChange={(e) => { handleRangoBonoChange(index, "bono", e.target.value) }} />
                                </div>
                            </div>

                            {state.rangos_bono_empaque.length > 1 &&
                                <div className="p-col-auto">
                                    <button className="p-button" onClick={() => eliminarItem(index)}>
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                    </button>
                                </div>}

                        </div>
                    })}
                    <div className="p-col-12 p-justify-end btn-row">
                        <Button label="+ Agregar rango" onClick={agregarRango} />
                    </div>
                    {errores.includes("bono") && <Message severity="error" text="Debes llenar todos los campos" />}

                </SeccionFormulario>
            </div>
        </div>

        <div className="p-col-12">
            <div className="p-grid p-justify-end btn-row">
                <Button label="Cancelar" className="p-button-secondary" onClick={cancelar} />
                <Button label="Guardar" onClick={guardar} />
            </div>
        </div>
    </div>
}
export default FormularioCategoria;