import { Departamento, Empaque, Genero, Empleado } from "../model/RhModels";
import { InvernaderoVirtual } from "../model/InvernaderoModels";
import { flexDate, obtenerDiasDeSemana, obtenerListaDeSemanas, obtenerNombreSemana } from "./fechas";
import { nombreActividadCosecha } from './nomina'
import { obtenerRegistroActividadesDia, obtenerActividadesRealizadasDesdeRegistroActividades, obtenerRegistroActividadesSemana, getActividadesRealizadasDesdeRegistroActividades, getActividadesRealizadasDesdeRegistroActividadesPath } from "./QueriesRH";
import { obtenerActividadesImportantes } from "./actividades";
import { obtenerEmpleadoEmpaqueCiclo, obtenerEmpleadosEmpaqueCiclo } from './NominaEmpaque';
import { obtenerEmpaqueCiclo } from "./Empaques";
import { uploadStatistics } from './Estadisticas'

function registrarInvocaciones(count) {
    uploadStatistics(count)
}
const numActividadesPrincipales = 5;
const firebase = require("firebase");
require("firebase/firestore");

export const nombreActividadBajado = "Bajado (1.5 vueltas)";
export const validarUnicoCodigoEmpleado = async (codigoEmpleado) => {
    const db = firebase.firestore();
    let snap = await db.collection("empleados").where("codigo_empleado", "==", codigoEmpleado).get();
    registrarInvocaciones(snap.size)
    return snap.empty;

}

export class RhService {

    constructor(enforcer) {
        throw new Error('Cannot construct singleton');
    }

    static async getDepartamentos() {
        var departamentos = []
        const firestore = firebase.firestore()
        await firestore.collection('departamentos').orderBy('nombre', 'asc').get().then((snapshot) => {
            registrarInvocaciones(snapshot.size)
            snapshot.forEach(document => {
                let departamento = new Departamento(document.id, document.data().nombre)
                departamentos.push(departamento)
            });
        })
        return departamentos
    }

    static async getDepartamentosConSubdepartamentos_and_Otros() {
        var departamentos = []
        const firestore = firebase.firestore()
        await firestore.collection('departamentos')
            .where('con_subdepartamentos', '==', true)
            .get()
            .then((snapshot) => {
                registrarInvocaciones(snapshot.size)
                snapshot.forEach(document => {
                    let departamento = new Departamento(document.id, document.data().nombre)
                    departamentos.push(departamento)
                });
            })
        const departamentoOtros = new Departamento('id_otros', 'Otros')
        departamentos.push(departamentoOtros)
        return departamentos
    }

    /**
     * Un empque se refiere al departamento de empaque, el cual puede estar sub-dividido
     * como 'Empaqeu 1', 'Empaque 2', ..., 'Empaque n'.
     * No confundir con el tipo de empaque de un producto (presentación de producto).
     */
    static async getEmpaques() {
        var empaques = []
        const firestore = firebase.firestore()
        await firestore.collection('empaques').orderBy('nombre', 'asc').get().then((snapshot) => {
            registrarInvocaciones(snapshot.size)
            snapshot.forEach(document => {
                let empaque = new Empaque(document.id, document.data().nombre,
                    document.data().locacion_ref)
                empaques.push(empaque)
            });
        })
        return empaques
    }
    static async getEmpaquesRefactor() {
        let response = await firebase.firestore().collection('empaques').orderBy('nombre', 'asc').get()
        registrarInvocaciones(response.size)
        let empaques = response.docs.map(el => ({ id: el.id, ...el.data() }))
        return empaques
    }

    /**
     * Se refire al genero de una persona (masculino o femenino).
     */
    static async getGeneros() {
        var generos = []
        const firestore = firebase.firestore()
        await firestore.collection('generos').orderBy('nombre', 'asc').get().then((snapshot) => {
            registrarInvocaciones(snapshot.size)
            snapshot.forEach(document => {
                let genero = new Genero(document.id, document.data().nombre)
                generos.push(genero)
            });
        })
        return generos
    }

    static async guardarEmpleado(
        cicloId,
        nombre, apellidos,
        generoSeleccionadoId,
        fechaIngreso,
        fechaNacimiento,
        numeroSeguroSocial,
        curp,
        codigoEmpleado,
        departamentoSeleccionadoId,
        invernaderoVirtualSeleccionadoId,
        empaqueSeleccionadoId,
        departamentoSeleccionadoNombre,
        salarioDiario,
        diasVacaciones, notas, rolEmpaque, empacadora, rolInvernaderoSeleccionado) {
        var empleadoGuardadoExitosamente = false
        const firestore = firebase.firestore()
        var empleadoId = ""
        if (departamentoSeleccionadoNombre === "Invernadero") {
            let empleadoInvernadero = {
                nombre: nombre,
                apellidos: apellidos,
                genero_ref: generoSeleccionadoId,
                fecha_ingreso: fechaIngreso,
                fecha_nacimiento: fechaNacimiento,
                numero_seguro_social: numeroSeguroSocial,
                curp: curp,
                codigo_empleado: codigoEmpleado,
                salario_diario: salarioDiario,
                dias_vacaciones: diasVacaciones,
                departamento_ref: departamentoSeleccionadoId,
                subdepartamento_ref: invernaderoVirtualSeleccionadoId, activo: true,
                rol_invernadero_ref: rolInvernaderoSeleccionado.id,
                notas: notas
            }
            await firestore.collection("empleados").add(empleadoInvernadero).then(empleadoRef => {
                empleadoId = empleadoRef.id
            });
            let empleadosInvernaderoVirtualDeCiclo = {
                ciclo_ref: cicloId,
                empleado_ref: empleadoId,
                invernadero_virtual_ref: invernaderoVirtualSeleccionadoId
            }
            await firestore.collection("empleados_invernadero_virtual_de_ciclo").add(empleadosInvernaderoVirtualDeCiclo).then(() => {
                empleadoGuardadoExitosamente = true
            });
            return empleadoGuardadoExitosamente
        } else if (departamentoSeleccionadoNombre === "Empaque") {
            let empleadoEmpaque = {
                nombre: nombre,
                apellidos: apellidos,
                genero_ref: generoSeleccionadoId,
                fecha_ingreso: fechaIngreso,
                fecha_nacimiento: fechaNacimiento,
                numero_seguro_social: numeroSeguroSocial,
                curp: curp,
                codigo_empleado: codigoEmpleado,
                salario_diario: salarioDiario,
                dias_vacaciones: diasVacaciones,
                departamento_ref: departamentoSeleccionadoId,
                subdepartamento_ref: empaqueSeleccionadoId, activo: true,
                notas: notas
            }
            await firestore.collection("empleados").add(empleadoEmpaque).then(() => {
                empleadoGuardadoExitosamente = true
            });
            return empleadoGuardadoExitosamente
        } else if (departamentoSeleccionadoNombre === "Administración") {
            let empleado = {
                nombre: nombre,
                apellidos: apellidos,
                genero_ref: generoSeleccionadoId,
                fecha_ingreso: fechaIngreso,
                fecha_nacimiento: fechaNacimiento,
                numero_seguro_social: numeroSeguroSocial,
                curp: curp,
                codigo_empleado: codigoEmpleado,
                dias_vacaciones: diasVacaciones,
                departamento_ref: departamentoSeleccionadoId,
                subdepartamento_ref: '', activo: true,
                notas: notas
            }
            await firestore.collection("empleados").add(empleado).then(() => {
                empleadoGuardadoExitosamente = true
            });
            return empleadoGuardadoExitosamente
        } else {
            let empleado = {
                nombre: nombre,
                apellidos: apellidos,
                genero_ref: generoSeleccionadoId,
                fecha_ingreso: fechaIngreso,
                fecha_nacimiento: fechaNacimiento,
                numero_seguro_social: numeroSeguroSocial,
                curp: curp,
                codigo_empleado: codigoEmpleado,
                salario_diario: salarioDiario,
                dias_vacaciones: diasVacaciones,
                departamento_ref: departamentoSeleccionadoId,
                subdepartamento_ref: '', activo: true,
                notas: notas
            }
            await firestore.collection("empleados").add(empleado).then(() => {
                empleadoGuardadoExitosamente = true
            });
            return empleadoGuardadoExitosamente
        }
    }

    static async getEmpleados() {
        var empleados = []
        const firestore = firebase.firestore()
        await firestore.collection('empleados').orderBy('nombre', 'asc').get().then((snapshot) => {
            registrarInvocaciones(snapshot.size)
            snapshot.forEach(document => {
                let documentData = document.data();
                let empleado = new Empleado(
                    document.id, documentData.nombre, documentData.apellidos, documentData.genero_ref,
                    documentData.fecha_ingreso, documentData.fecha_nacimiento, documentData.numero_seguro_social,
                    documentData.curp, documentData.codigo_empleado, documentData.salario_diario, documentData.dias_vacaciones,
                    documentData.departamento_ref, documentData.subdepartamento_ref, documentData.activo)
                empleados.push(empleado)
            });
        });
        return empleados
    }

    /**
     * Con 'departamentos simples' nos referimos a los departamentos que no tienen subdepartamentos.
     * Util para opción 'Otros' en el filtro de departamento de la pantalla de consulta de empleados.
    */
    static async getEmpleadosDeDepartamentosSimples() { }

    static async getEmpleados_deSubdepartamento(departamento, empaque, invernaderoVirtual, ciclo) {

        var empleados = []
        const firestore = firebase.firestore()

        if (departamento.nombre == 'Empaque') {
            empleados = await obtenerEmpleadosEmpaqueCiclo(ciclo, empaque.empaque_ref);
        } else if (departamento.nombre == 'Invernadero') {
            //     
            // 
            let empleadosInvernaderos = await obtener_empleados_invernadero(ciclo, invernaderoVirtual.id);
            let promesasEmpleados = empleadosInvernaderos.map(empleado => obtenerEmpleadoDeReferencia(empleado.empleado_ref));
            let empleadosRaw = await Promise.all(promesasEmpleados);
            // 
            // 
            // 
            empleadosRaw.forEach(document => {
                //let documentData = document.data();
                let documentData = document;
                let empleado = new Empleado(
                    document.id, documentData.nombre, documentData.apellidos, documentData.genero_ref,
                    documentData.fecha_ingreso, documentData.fecha_nacimiento, documentData.numero_seguro_social,
                    documentData.curp, documentData.codigo_empleado, documentData.salario_diario, documentData.dias_vacaciones,
                    documentData.departamento_ref, documentData.subdepartamento_ref, documentData.activo,
                    documentData.notas, documentData.rol_invernadero_ref)
                empleados.push(empleado)
            });

        } else {
            await firestore.collection('empleados')
                .where('subdepartamento_ref', '==', '')
                .orderBy('nombre', 'asc')
                .get()
                .then((snapshot) => {
                    registrarInvocaciones(snapshot.size)
                    snapshot.forEach(document => {
                        let documentData = document.data();
                        let empleado = new Empleado(
                            document.id, documentData.nombre, documentData.apellidos, documentData.genero_ref,
                            documentData.fecha_ingreso, documentData.fecha_nacimiento, documentData.numero_seguro_social,
                            documentData.curp, documentData.codigo_empleado, documentData.salario_diario, documentData.dias_vacaciones,
                            documentData.departamento_ref, documentData.subdepartamento_ref, documentData.activo,
                            documentData.notas)
                        empleados.push(empleado)
                    });
                });
        }

        return empleados
    }

    static async getGenero(generoId) {
        var genero = {}
        const firestore = firebase.firestore()
        await firestore.collection('generos').doc(generoId).get().then((document) => {
            registrarInvocaciones(document.size)
            genero = new Genero(document.id, document.data().nombre)
        })
        return genero
    }

    static async getDepartamento(departamentoId) {
        var departamento = {}
        const firestore = firebase.firestore()
        await firestore.collection('departamentos').doc(departamentoId).get().then((document) => {
            registrarInvocaciones(document.size)
            departamento = new Departamento(document.id, document.data().nombre)
        })
        return departamento
    }

    static async getInvernaderoVirtualDeEmpleado(cicloId, empleadoId) {
        var invernaderoVirtual = {}
        var invernaderoVirtualId = ""
        const firestore = firebase.firestore()
        await firestore.collection('empleados_invernadero_virtual_de_ciclo')
            .where('ciclo_ref', '==', cicloId)
            .where('empleado_ref', '==', empleadoId)
            .limit(1)
            .get()
            .then((snapshot) => {
                registrarInvocaciones(snapshot.size)
                if (!snapshot.docs) return invernaderoVirtual
                invernaderoVirtualId = snapshot.docs[0].data().invernadero_virtual_ref
            });
        await firestore.collection('ciclos')
            .doc(cicloId)
            .collection('invernaderos_virtuales')
            .doc(invernaderoVirtualId)
            .get()
            .then(document => {
                registrarInvocaciones(1)
                invernaderoVirtual = new InvernaderoVirtual(document.id, document.data().nombre, document.data().color, document.data().habilitado)
            });
        return invernaderoVirtual
    }

    static async getEmpaque(empaqueId) {
        var empaque = {}
        const firestore = firebase.firestore()
        await firestore.collection('empaques').doc(empaqueId).get().then((document) => {
            registrarInvocaciones(1)
            empaque = new Empaque(document.id, document.data().nombre, document.data().locacion_ref)
        })
        return empaque
    }

    static async modificarEmpleado(
        empleadoId,
        nombre, apellidos, generoSeleccionadoId,
        fechaIngreso, fechaNacimiento,
        numeroSeguroSocial, curp, codigoEmpleado,
        salarioDiario, diasVacaciones,
        nombreDepartamento, invernaderoVirtualId, empacadoraId, cicloId,
        notas, departamentoId, rol_invernadero_ref) {
        var empleadoGuardadoExitosamente = false
        const firestore = firebase.firestore()
        var empleado = {}

        if (nombreDepartamento === "Invernadero") {
            var empleadoInvernaderoVirtualDeCicloId = ""
            await firestore.collection('empleados_invernadero_virtual_de_ciclo')
                .where('ciclo_ref', '==', cicloId)
                .where('empleado_ref', '==', empleadoId)
                .limit(1)
                .get()
                .then((snapshot) => {
                    registrarInvocaciones(snapshot.size)
                    if (!snapshot.docs) return empleadoGuardadoExitosamente
                    empleadoInvernaderoVirtualDeCicloId = snapshot.docs[0].id
                }).catch(() => {
                    return empleadoGuardadoExitosamente
                });
            empleado = {
                nombre: nombre,
                apellidos: apellidos,
                genero_ref: generoSeleccionadoId,
                fecha_ingreso: fechaIngreso,
                fecha_nacimiento: fechaNacimiento,
                numero_seguro_social: numeroSeguroSocial,
                curp: curp,
                codigo_empleado: codigoEmpleado,
                salario_diario: salarioDiario,
                dias_vacaciones: diasVacaciones,
                subdepartamento_ref: invernaderoVirtualId,
                rol_invernadero_ref: rol_invernadero_ref || "",
                notas: notas,
            }
            await firestore.collection("empleados").doc(empleadoId).update(empleado).then().catch(() => { return empleadoGuardadoExitosamente });
            await firestore.collection("empleados_invernadero_virtual_de_ciclo")
                .doc(empleadoInvernaderoVirtualDeCicloId)
                .update({ invernadero_virtual_ref: invernaderoVirtualId }).then(() => {
                    empleadoGuardadoExitosamente = true
                });

        } else if (nombreDepartamento === "Empaque") {
            empleado = {
                nombre: nombre,
                apellidos: apellidos,
                genero_ref: generoSeleccionadoId,
                fecha_ingreso: fechaIngreso,
                fecha_nacimiento: fechaNacimiento,
                numero_seguro_social: numeroSeguroSocial,
                curp: curp,
                codigo_empleado: codigoEmpleado,
                salario_diario: salarioDiario,
                dias_vacaciones: diasVacaciones,
                subdepartamento_ref: empacadoraId,
                notas: notas
            }
            if (departamentoId) {
                empleado["departamento_ref"] = departamentoId
            }
            await firestore.collection("empleados").doc(empleadoId).update(empleado).then(() => {
                empleadoGuardadoExitosamente = true
            }).catch(err => {
                console.log("ERROR ACTUALIZAR EMPLEADO CICLO");
                console.log(err);
            });

        } else if (nombreDepartamento === "Administración") {
            empleado = {
                nombre: nombre,
                apellidos: apellidos,
                genero_ref: generoSeleccionadoId,
                fecha_ingreso: fechaIngreso,
                fecha_nacimiento: fechaNacimiento,
                numero_seguro_social: numeroSeguroSocial,
                curp: curp,
                codigo_empleado: codigoEmpleado,
                dias_vacaciones: diasVacaciones,
                notas: notas
            }
            await firestore.collection("empleados").doc(empleadoId).update(empleado).then(() => {
                empleadoGuardadoExitosamente = true
            });

        } else {
            empleado = {
                nombre: nombre,
                apellidos: apellidos,
                genero_ref: generoSeleccionadoId,
                fecha_ingreso: fechaIngreso,
                fecha_nacimiento: fechaNacimiento,
                numero_seguro_social: numeroSeguroSocial,
                curp: curp,
                codigo_empleado: codigoEmpleado,
                salario_diario: salarioDiario,
                dias_vacaciones: diasVacaciones,
                notas: notas
            }
            await firestore.collection("empleados").doc(empleadoId).update(empleado).then(() => {
                empleadoGuardadoExitosamente = true
            });
        }

        return empleadoGuardadoExitosamente
    }
    static async modificarEmpleadoEmpaqueAInvernadero(
        empleadoId,
        nombre,
        apellidos,
        generoSeleccionadoId,
        fechaIngreso,
        fechaNacimiento,
        numeroSeguroSocial,
        curp,
        codigoEmpleado,
        salarioDiario,
        diasVacaciones,
        nombreDepartamento,
        invernaderoVirtualId,
        empacadoraId,
        cicloId,
        notas,
        departamentoId, rol_invernadero_ref) {
        // 
        // 
        // 
        // 
        console.log({rol_invernadero_ref})
        var empleadoGuardadoExitosamente = false
        const firestore = firebase.firestore()
        let objEmpleado = await obtenerEmpleadoEmpaqueCiclo(cicloId, empacadoraId, empleadoId);
        let relacion = await asegurarRelacionEmpleadoInvernadero(cicloId, objEmpleado.empleado_ref, invernaderoVirtualId)
        var empleado = {}

        if (nombreDepartamento === "Invernadero") {
            // 
            var empleadoInvernaderoVirtualDeCicloId = ""
            await firestore.collection('empleados_invernadero_virtual_de_ciclo')
                .where('ciclo_ref', '==', cicloId)
                .where('empleado_ref', '==', objEmpleado.empleado_ref)
                .limit(1)
                .get()
                .then((snapshot) => {
                    registrarInvocaciones(snapshot.size)
                    if (!snapshot.docs) {
                        return empleadoGuardadoExitosamente
                    }
                    empleadoInvernaderoVirtualDeCicloId = snapshot.docs[0].id
                }).catch(() => {
                    return empleadoGuardadoExitosamente
                });
            // 
            empleado = {
                nombre: nombre,
                apellidos: apellidos,
                genero_ref: generoSeleccionadoId,
                fecha_ingreso: fechaIngreso,
                fecha_nacimiento: fechaNacimiento,
                numero_seguro_social: numeroSeguroSocial,
                curp: curp,
                codigo_empleado: codigoEmpleado,
                salario_diario: salarioDiario,
                dias_vacaciones: diasVacaciones,
                subdepartamento_ref: invernaderoVirtualId,
                departamento_ref: departamentoId,
                rol_invernadero_ref: rol_invernadero_ref || "",
                notas: notas
            }
            await firestore.collection("empleados").doc(objEmpleado.empleado_ref).update(empleado).then().catch(() => { return empleadoGuardadoExitosamente });
            // console.log("mdeai iii")
            // await firestore.collection("empleados_invernadero_virtual_de_ciclo")
            //         .doc(empleadoInvernaderoVirtualDeCicloId)
            //         .update({ invernadero_virtual_ref: invernaderoVirtualId }).then(() => {
            //     empleadoGuardadoExitosamente = true
            // });
            // 

        } else if (nombreDepartamento === "Empaque") {
            empleado = {
                nombre: nombre,
                apellidos: apellidos,
                genero_ref: generoSeleccionadoId,
                fecha_ingreso: fechaIngreso,
                fecha_nacimiento: fechaNacimiento,
                numero_seguro_social: numeroSeguroSocial,
                curp: curp,
                codigo_empleado: codigoEmpleado,
                salario_diario: salarioDiario,
                dias_vacaciones: diasVacaciones,
                subdepartamento_ref: empacadoraId,
                notas: notas
            }
            await firestore.collection("empleados").doc(empleadoId).update(empleado).then(() => {
                empleadoGuardadoExitosamente = true
            }).catch(err => {
                console.log("ERROR ACTUALIZAR EMPLEADO CICLO");
                console.log(err);
            });

        } else if (nombreDepartamento === "Administración") {
            empleado = {
                nombre: nombre,
                apellidos: apellidos,
                genero_ref: generoSeleccionadoId,
                fecha_ingreso: fechaIngreso,
                fecha_nacimiento: fechaNacimiento,
                numero_seguro_social: numeroSeguroSocial,
                curp: curp,
                codigo_empleado: codigoEmpleado,
                dias_vacaciones: diasVacaciones,
                notas: notas
            }
            await firestore.collection("empleados").doc(empleadoId).update(empleado).then(() => {
                empleadoGuardadoExitosamente = true
            });

        } else {
            empleado = {
                nombre: nombre,
                apellidos: apellidos,
                genero_ref: generoSeleccionadoId,
                fecha_ingreso: fechaIngreso,
                fecha_nacimiento: fechaNacimiento,
                numero_seguro_social: numeroSeguroSocial,
                curp: curp,
                codigo_empleado: codigoEmpleado,
                salario_diario: salarioDiario,
                dias_vacaciones: diasVacaciones,
                notas: notas
            }
            await firestore.collection("empleados").doc(empleadoId).update(empleado).then(() => {
                empleadoGuardadoExitosamente = true
            });
        }

        return empleadoGuardadoExitosamente
    }

    static async desactivarEmpleado(empleadoId) {
        try {


            var empleadoGuardadoExitosamente = false
            const firestore = firebase.firestore()
            let empleado = { activo: false }
            await firestore.collection("empleados").doc(empleadoId).update(empleado).then(() => {
                empleadoGuardadoExitosamente = true
            });
            return empleadoGuardadoExitosamente
        } catch (error) {
            throw error;
        }
    }

    static async activarEmpleado(empleadoId) {
        var empleadoGuardadoExitosamente = false
        const firestore = firebase.firestore()
        let empleado = { activo: true }
        await firestore.collection("empleados").doc(empleadoId).update(empleado).then(() => {
            empleadoGuardadoExitosamente = true
        });
        return empleadoGuardadoExitosamente
    }

    static async getNumeroDeEmpleadosEnSubdepartamento(subdepartamentoId) {
        var numeroEmpleados = 0
        const firestore = firebase.firestore()
        await firestore.collection('empleados')
            .where('subdepartamento_ref', '==', subdepartamentoId)
            .get()
            .then((snapshot) => {
                registrarInvocaciones(snapshot.size)
                numeroEmpleados = snapshot.size
            });
        return numeroEmpleados
    }

    static async getNumeroDeEmpleadosActivosEnSubdepartamento(subdepartmentoId) {
        var numeroEmpleados = 0
        const firestore = firebase.firestore()
        await firestore.collection('empleados')
            .where('subdepartamento_ref', '==', subdepartmentoId)
            .where('activo', '==', true)
            .get()
            .then((snapshot) => {
                registrarInvocaciones(snapshot.size)
                numeroEmpleados = snapshot.size
            });
        return numeroEmpleados
    }

    static async getNumeroDeEmpleadosSinSubdepartamento() {
        var numeroEmpleados = 0
        const firestore = firebase.firestore()
        await firestore.collection('empleados')
            .where('subdepartamento_ref', '==', '')
            .get()
            .then((snapshot) => {
                registrarInvocaciones(snapshot.size)
                numeroEmpleados = snapshot.size
            });
        return numeroEmpleados
    }

}
export function obtener_empleados_invernadero(ciclo, invernadero) {
    // 
    // 
    var db = firebase.firestore();
    return new Promise((resolve, reject) => {
        let result = [];
        db.collection("empleados_invernadero_virtual_de_ciclo").where("ciclo_ref", "==", ciclo)
            .where("invernadero_virtual_ref", "==", invernadero).get().then(resp => {
                registrarInvocaciones(resp.size)

                resp.forEach(doc => {
                    let data = doc.data();
                    data.id = doc.id;
                    result.push(data);
                })
                resolve(result);
            }).catch(err => {
                reject(err);
            });
    })
}
export function obtener_empleados_ciclo_actual(ciclo) {
    // 
    // 
    var db = firebase.firestore();
    return new Promise((resolve, reject) => {
        let result = [];
        db.collection("empleados_invernadero_virtual_de_ciclo")
            .where("ciclo_ref", "==", ciclo).get().then(resp => {
                registrarInvocaciones(resp.size)

                resp.forEach(doc => {
                    let data = doc.data();
                    data.id = doc.id;
                    result.push(data);
                })
                resolve(result);
            }).catch(err => {
                reject(err);
            });
    })
}
export const getEmpleadosInvernaderosTodos = async () => {
    const response = await firebase.firestore().collection("empleados_invernadero_virtual_de_ciclo").get()
    const empleados = response.docs.map(el => ({ id: el.id, ...el.data() }))
    return empleados;
}

export function obtenerCuentaEmpleadosInvernadero(ciclo, invernadero) {
    // 
    // 
    return new Promise((resolve, reject) => {
        obtener_empleados_invernadero(ciclo, invernadero).then(resp => {
            resolve(resp.length);
        }).catch(err => {
            reject(err);
        })
    })

}
export function obtenerCuentaEmpleadosHabilitadosInvernadero(ciclo, invernadero) {
    // 
    // 
    let inicio = Date.now();
    let final = 0;
    let dif = 0;
    return new Promise((resolve, reject) => {
        obtener_empleados_invernadero(ciclo, invernadero).then(async resp => {
            let cuenta = 0;
            let todosEmpleados = await obtenerEmpleados();
            //let promises = resp.map(data=>obtenerEmpleadoDeReferencia(data.empleado_ref));
            //let respProm = await Promise.all(promises);
            for (let x = 0; x < resp.length; x++) {
                let data = todosEmpleados.find(el => el.id === resp[x]["empleado_ref"]);
                let empleadoData = data;
                if (empleadoData && empleadoData.activo) {
                    cuenta++;
                }
            }
            // 
            final = Date.now();
            dif = (final - inicio) / 1000;
            //     
            resolve(cuenta);
        }).catch(err => {
            reject(err);
        })
    })
}
export const getTotalEmpleadosActivosInvernadero = async (ciclo, invernadero) => {
    const promises = [obtener_empleados_invernadero(ciclo, invernadero), obtenerEmpleados()];
    const [empleadosInvernadero, todosEmpleados] = await Promise.all(promises);

    const empleadosFiltrados = todosEmpleados.filter(empleado => empleadosInvernadero.some(empInv => empleado.id === empInv.empleado_ref));
    const empleadosActivos = empleadosFiltrados.filter(empleado => empleado.activo)
    return { activos: empleadosActivos?.length, originales: empleadosInvernadero?.length }
}

export function obtenerFaltas(dia, ciclo, invernadero) {

    let cuenta = 0;
    return new Promise((resolve, reject) => {
        obtenerRegistroActividadesDia(ciclo, invernadero, dia).
            then(respDia => {
                let data = [];
                if (respDia.empty) {
                    //
                    resolve("all");
                } else {

                    respDia.forEach(doc => {

                        data.push(doc);
                    })

                    obtenerActividadesRealizadasDesdeRegistroActividades(data[0].ref.path).then(resp => {
                        if (resp.empty) {
                            //
                            resolve("all");
                        } else {
                            //
                            resp.forEach(doc => {
                                let data = doc.data();
                                if (data.tuvo_ausencia || data.tuvo_permiso || data.tuvo_vacaciones) {
                                    //
                                    //
                                    cuenta++
                                }
                            })
                            resolve(cuenta);
                        }

                    }).catch(err => {
                        reject(err);
                    });
                }

            }).catch(err => {
                console.log("ERROR OBTENER ofaltas")
                reject(err);
            })
    })

}
export function getFaltas(dia, actividadesRegistradas) {
    const respDia = actividadesRegistradas.filter(el => el.dia === dia)
    if (respDia.length === 0) return "all";

    let dataDia = respDia[0].realizadas;
    //console.log(JSON.stringify(dataDia)); console.log("---\n\n")
    const cuenta = dataDia.filter(el => el.tuvo_ausencia || el.tuvo_permiso || el.tuvo_vacaciones)
    return cuenta.length;
}
export const obtenerPorcentajeHorasTrabajadasEnCiclo = async (ciclo, registrosActividadesInv, cuentaEmpleados, listaSemanas) => {
    // 
    // let inicio = Date.now();
    //         let fin = 0;
    //         let dif = 0;
    try {
        
        let promises = [];
        // 
        // 
        // 
        listaSemanas.forEach(semana => {
            promises.push(obtenerPorcentajeHorasTrabajadasOptimizado(semana.time, cuentaEmpleados, registrosActividadesInv));
        })
        // 
        let values = await Promise.all(promises);
        // 
        // 
        let result = listaSemanas.map((semana, index) => {
            return { semana: semana, porcentaje: values[index] }
        })
        //  
        //  
        // 
        //     fin= Date.now(0);
        //     dif = fin-inicio;
        //      
        return result;
    } catch (error) {
        throw (error);
    }


}
export function obtenerPorcentajeHorasTrabajadasOptimizado(time, cuenta, registros) {


    let denominador = cuenta * 44;

    return new Promise(async (resolve, reject) => {
        try {
            if (denominador == 0) {
                resolve(0);
            } else {
                let dias = obtenerDiasDeSemana(time);
                let promises = [];
                promises.push(obtenerFaltasSync(dias[0], registros));
                promises.push(obtenerFaltasSync(dias[1], registros));
                promises.push(obtenerFaltasSync(dias[2], registros));
                promises.push(obtenerFaltasSync(dias[3], registros));
                promises.push(obtenerFaltasSync(dias[4], registros));
                promises.push(obtenerFaltasSync(dias[5], registros));

                let [lunes, martes, miercoles, jueves, viernes, sabado] = await Promise.all(promises);


                lunes = lunes === "all" ? cuenta : lunes;
                martes = martes === "all" ? cuenta : martes;
                miercoles = miercoles === "all" ? cuenta : miercoles;
                jueves = jueves === "all" ? cuenta : jueves;
                viernes = viernes === "all" ? cuenta : viernes;
                sabado = sabado === "all" ? cuenta : sabado;
                resolve(Math.round(100 * (denominador - lunes * 8 - martes * 8 - miercoles * 8 - jueves * 8 - viernes * 8 - sabado * 4) / denominador));
            }

        } catch (err) {
            reject(err);
        }

    })
}
export function obtenerFaltasSync(dia, registros) {

    let cuenta = 0;
    return new Promise((resolve, reject) => {
        let respDia = registros.find(el => el.dia === dia);
        let data = [];
        if (!respDia) {
            //
            resolve("all");
        } else {
            data = respDia;

            obtenerActividadesRealizadasDesdeRegistroActividades(data.path).then(resp => {
                if (resp.empty) {
                    //
                    resolve("all");
                } else {
                    //
                    resp.forEach(doc => {
                        let data = doc.data();
                        if (data.tuvo_ausencia || data.tuvo_permiso || data.tuvo_vacaciones) {
                            //
                            //
                            cuenta++
                        }
                    })
                    resolve(cuenta);
                }

            }).catch(err => {
                reject(err);
            });
        }


    })

}
export function obtenerPorcentajeHorasTrabajadas(time, cuenta, ciclo, invernadero) {
    let denominador = cuenta * 44;
    return new Promise(async (resolve, reject) => {
        try {
            if (denominador == 0) {
                resolve(0);
            } else {
                let dias = obtenerDiasDeSemana(time);
                let promises = [];
                promises.push(obtenerFaltas(dias[0], ciclo, invernadero));
                promises.push(obtenerFaltas(dias[1], ciclo, invernadero));
                promises.push(obtenerFaltas(dias[2], ciclo, invernadero));
                promises.push(obtenerFaltas(dias[3], ciclo, invernadero));
                promises.push(obtenerFaltas(dias[4], ciclo, invernadero));
                promises.push(obtenerFaltas(dias[5], ciclo, invernadero));

                let [lunes, martes, miercoles, jueves, viernes, sabado] = await Promise.all(promises);
                lunes = lunes === "all" ? cuenta : lunes;
                martes = martes === "all" ? cuenta : martes;
                miercoles = miercoles === "all" ? cuenta : miercoles;
                jueves = jueves === "all" ? cuenta : jueves;
                viernes = viernes === "all" ? cuenta : viernes;
                sabado = sabado === "all" ? cuenta : sabado;

                resolve(Math.round(100 * (denominador - lunes * 8 - martes * 8 - miercoles * 8 - jueves * 8 - viernes * 8 - sabado * 4) / denominador));
            }

        } catch (err) {
            reject(err);
        }

    })
}

export function getPorcentajeHorasTrabajadas(time, cuenta, ciclo, invernadero, actividadesRegistradas) {
    let denominador = cuenta * 44, all = "all";
    try {
        if (denominador == 0)
            return 0;
        else {
            let dias = obtenerDiasDeSemana(time);
            let lunes = getFaltas(dias[0], actividadesRegistradas);
            let martes = getFaltas(dias[1], actividadesRegistradas);
            let miercoles = getFaltas(dias[2], actividadesRegistradas);
            let jueves = getFaltas(dias[3], actividadesRegistradas);
            let viernes = getFaltas(dias[4], actividadesRegistradas);
            let sabado = getFaltas(dias[5], actividadesRegistradas);

            lunes = lunes === all ? cuenta : lunes;
            martes = martes === all ? cuenta : martes;
            miercoles = miercoles === all ? cuenta : miercoles;
            jueves = jueves === all ? cuenta : jueves;
            viernes = viernes === all ? cuenta : viernes;
            sabado = sabado === all ? cuenta : sabado;

            return Math.round(100 * (denominador - lunes * 8 - martes * 8 - miercoles * 8 - jueves * 8 - viernes * 8 - sabado * 4) / denominador);
        }

    } catch (err) {
        console.log(err.message)
    }
}


export function obtenerNombreYStatusEmpleado(id) {
    let db = firebase.firestore();
    return new Promise((resolve, reject) => {
        db.collection("empleados").doc(id).get().then(resp => {
            registrarInvocaciones(1)
            let data = resp?.data() || {};
            const datos = { nombre: `${data.nombre} ${data.apellidos}`, activo: data.activo, fecha_ingreso: parseInt(data.fecha_ingreso), id: id }
            if(data.rol_invernadero_ref){ datos.rol_invernadero_ref = data.rol_invernadero_ref;}
            resolve(datos);
        }).catch(err => {
            reject(err);
        })
    })

}
export const obtenerActividadesEnCicloPorc = async (ciclo, invernadero, cuentaEmpleados) => {
    try {
        let listaSemanas = obtenerListaDeSemanas(ciclo.semana_inicio, ciclo.semana_cierre);
        let promises = [];
        let registros = await obtenerTodasActividadesInvernadero(ciclo.id, invernadero.id);
        listaSemanas.forEach(semana => {
            promises.push(obtenerActividadesSemanaOptimizado(semana.time,null,null,null, registros));
        })
        let values = await Promise.all(promises);
        let result = listaSemanas.map((semana, index) => {
            return { semana: semana, actividades: values[index] }
        })

        return result;
    } catch (error) {
        console.log("ERROR obtenerActividadesEnCicloPorc",error);
        throw (error);
    }
}
export const obtenerActividadesEnCiclo = async (ciclo, registrosActividadesInv, actividadesImportantesOrig, listaSemanas) => {
    // let inicio = Date.now();
    //         let fin = 0;
    //         let dif = 0;
    try {
        let promises = [];
        listaSemanas.forEach(semana => {
            promises.push(obtenerActividadesSemanaOptimizado(semana.time, registrosActividadesInv, actividadesImportantesOrig));
        })
        // 
        let values = await Promise.all(promises);
        //console.log("RES: ",{registros, listaSemanas})
        // 
        let result = listaSemanas.map((semana, index) => {
            return { semana: semana, actividades: values[index] }
        })
        //  
        // 
        // fin= Date.now(0);
        // dif = fin-inicio;
        //  
        //  
        
        return result;
    } catch (error) {
        console.log("ERROR OAEC");
        console.log(error);
        throw (error);
    }
}
///////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////
//////////////////////////////Grafica Actividades//////////////////
export const obtenerActividadesEnCicloGrafica = async (listaSemanas = [], registrosActividades = [], actividadesRealizadas = [], actividadesImportantes = []) => {
    try {
        const promises = listaSemanas.map((semana, index) => obtenerActividadesSemanaOptimizadoGrafica(semana.time, registrosActividades, actividadesRealizadas[index], actividadesImportantes))
        const values = await Promise.all(promises);

        const porcentaje = listaSemanas.map((semana, index) => ({ semana, actividades: values[index].cuentaActividades }))
        const actividadesRealizadasPorSemana = values.map(value => value.actividadesRealizadas)
        return { porcentaje, actividadesRealizadasPorSemana };
    } catch (error) {
        console.log("ERROR obtenerActividadesEnCiclo ", error);
        throw (error);
    }
}
export const obtenerActividadesSemanaOptimizadoGrafica = async (semana, registros, actividadesRealizadasPorSemana = [], actividadesImportantes = []) => {
    try {
        const actividadesRealizadas = actividadesRealizadasPorSemana?.length > 0
            ? actividadesRealizadasPorSemana
            : await obtenerTodasActividadesRealizadasDiaSyncGrafica(registros, semana);

        const cuentaActividades = contarActividadesGrafica(actividadesImportantes, actividadesRealizadas);
        return { actividadesRealizadas, cuentaActividades }
    } catch (error) {
        console.log("ERROR obtenerActividadesSemanaOptimizado", error)
        throw error;
    }
}

const obtenerTodasActividadesRealizadasDiaSyncGrafica = async (actividades, dia) => {
    try {
        const dias = obtenerDiasDeSemana(dia);
        const promises = dias.map(dia => {
            const found = actividades?.find(el => el.dia === dia)
            return found ? procesarYObtenerActividadesGrafica(found) : null;
        })
        return Promise.all(promises);
    } catch (error) {
        throw (error);
    }
}
const procesarYObtenerActividadesGrafica = async (found = {}) => {
    const realizadas = await getActividadesRealizadasDesdeRegistroActividadesPath(found.path);
    return realizadas.length === 0 ? null : { ...found, realizadas };
}
const contarActividadesGrafica = (respImportante, values) => {
    let principales = [], idBajado = "";
    respImportante.forEach(el => {
        let data = el.data();
        data.id = el.id;
        if (data.nombre !== nombreActividadCosecha) { principales.push(el.id); }
        if (data.nombre === nombreActividadBajado) { idBajado = data.id; }
    })
    let lineasTotales = values.reduce((acc, curr) => {
        let sumaDia = 0;
        if (curr && curr.realizadas) {
            curr.realizadas.forEach(el => {
                if (el.actividades) {
                    principales.forEach(key => {
                        const cantidad = parseInt(el.actividades[key]);
                        if (key === idBajado) { sumaDia += cantidad ? 1 * cantidad : 0; }
                        else { sumaDia += cantidad ? cantidad : 0; }
                    })
                }
            })
            return acc + sumaDia;
        }
        else if (curr) { return acc + 0; }
        else return acc + 0;
    }, 0)
    return (lineasTotales / numActividadesPrincipales);
}
//////////////////////////////Grafica horas//////////////////////////////
export const obtenerPorcentajeHorasTrabajadasEnCicloGrafica = async (ciclo, invernadero, cuentaEmpleados, registrosActividades, actividadesRealizadas = []) => {
    try {
        let listaSemanas = obtenerListaDeSemanas(ciclo.semana_inicio, ciclo.semana_cierre);
        let promises = listaSemanas.map((semana, index) => obtenerPorcentajeHorasTrabajadasOptimizadoGrafica(semana.time, cuentaEmpleados, registrosActividades, actividadesRealizadas[index]))
        let values = await Promise.all(promises);

        let porcentaje = listaSemanas.map((semana, index) => ({ semana: semana, porcentaje: values[index].horas }))
        const actividadesRealizadasPorSemana = values.map(value => value.actividadesRealizadas)
        return { porcentaje, actividadesRealizadasPorSemana };
    } catch (error) {
        throw (error);
    }
}
export function obtenerPorcentajeHorasTrabajadasOptimizadoGrafica(time, cuenta, registros, actividadesRealizadasPorSemana) {
    let denominador = cuenta * 44;
    return new Promise(async (resolve, reject) => {
        try {
            const actividadesRealizadas = actividadesRealizadasPorSemana?.length > 0
                ? actividadesRealizadasPorSemana
                : await obtenerTodasActividadesRealizadasDiaSyncGrafica(registros, time);
            console.log("actividadesRealizadas: ", { actividadesRealizadas, actividadesRealizadasPorSemana })
            if (denominador == 0) { resolve(0); }
            else {
                let dias = obtenerDiasDeSemana(time), valuesDias = [];
                valuesDias.push(obtenerFaltasSyncGrafica(dias[0], registros, actividadesRealizadas));
                valuesDias.push(obtenerFaltasSyncGrafica(dias[1], registros, actividadesRealizadas));
                valuesDias.push(obtenerFaltasSyncGrafica(dias[2], registros, actividadesRealizadas));
                valuesDias.push(obtenerFaltasSyncGrafica(dias[3], registros, actividadesRealizadas));
                valuesDias.push(obtenerFaltasSyncGrafica(dias[4], registros, actividadesRealizadas));
                valuesDias.push(obtenerFaltasSyncGrafica(dias[5], registros, actividadesRealizadas));
                let [lunes, martes, miercoles, jueves, viernes, sabado] = valuesDias;

                lunes = lunes === "all" ? cuenta : lunes;
                martes = martes === "all" ? cuenta : martes;
                miercoles = miercoles === "all" ? cuenta : miercoles;
                jueves = jueves === "all" ? cuenta : jueves;
                viernes = viernes === "all" ? cuenta : viernes;
                sabado = sabado === "all" ? cuenta : sabado;
                const horas = Math.round(100 * (denominador - lunes * 8 - martes * 8 - miercoles * 8 - jueves * 8 - viernes * 8 - sabado * 4) / denominador);
                resolve({ actividadesRealizadas, horas });
            }
        } catch (err) {
            reject(err);
        }
    })
}

export function obtenerFaltasSyncGrafica(dia, registros, actividadesRealizadas) {
    let cuenta = 0;
    let respDia = registros.find(el => el.dia === dia);
    if (!respDia) { return "all"; }
    else {
        console.log("actividadesRealizadas ", actividadesRealizadas)
        if (actividadesRealizadas?.length === 0) { return "all"; }
        else {
            actividadesRealizadas.forEach(real => {
                //console.log("REAL: ", real)
                if (real?.realizadas && real.dia == dia) {
                    real.realizadas.forEach(data => {
                        if ( data?.tuvo_ausencia || data?.tuvo_permiso || data?.tuvo_vacaciones) { cuenta++ }
                    })
                }
            })
            return cuenta
        }
    }
}

export const obtenerCuentaEmpleadosHabilitadosInvernaderoGrafica = async (ciclo, invernadero, todosEmpleados = []) => {
    const resp = await getEmpleadosInvernadero(ciclo, invernadero);
    let cuenta = 0;
    for (let x = 0; x < resp.length; x++) {
        let found = todosEmpleados.find(el => el.id === resp[x].empleado_ref);
        if (found?.activo) { cuenta++; }
    }
    return cuenta
}
export const getEmpleadosInvernadero = async (ciclo, invernadero) => {
    const response = await firebase.firestore().collection("empleados_invernadero_virtual_de_ciclo")
        .where("ciclo_ref", "==", ciclo).where("invernadero_virtual_ref", "==", invernadero).get();

    registrarInvocaciones(response.size);
    const empleados = response.docs.map(el => ({ id: el.id, ...el.data() }));
    return empleados;
}
///////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////


const contarActividades = (respImportante, values) => {
    let principales = [];
    let idBajado = "";
    respImportante.forEach(data => {
        if (data.nombre !== nombreActividadCosecha) {
            principales.push(data.id);
        }

        if (data.nombre === nombreActividadBajado) {
            //
            //
            idBajado = data.id;
        }

    })
    let lineasTotales = values.reduce((acc, curr) => {
        let sumaDia = 0;
        if (curr) {
            if (curr.realizadas) {
                curr.realizadas.map(el => {
                    if (el.actividades) {
                        principales.forEach(key => {

                            let cantidad = parseInt(el.actividades[key]);
                            if (key === idBajado) {
                                sumaDia += cantidad ? 1 * cantidad : 0;
                            } else {
                                sumaDia += cantidad ? cantidad : 0;
                            }

                        })
                    }
                })
                return (acc + sumaDia);
            }
            else {
                return acc + 0;
            }
        } else {
            return acc + 0;
        }
    }, 0)
    return (lineasTotales / numActividadesPrincipales);
}

const obtenerTodasActividadesRealizadasDiaSync = async (actividades, dia) => {

    try {
        let dias = obtenerDiasDeSemana(dia);
        let promises = [];
        dias.map(dia => {
            //obtener los dias        
            promises.push(new Promise((resolve, reject) => {
                let resp = actividades?.find(el => el.dia === dia)
                if (!resp) {
                    //
                    resolve(null);
                } else {
                    //
                    let result = resp
                    obtenerActividadesRealizadasDesdeRegistroActividades(resp.path).then(respRealizadas => {
                        if (respRealizadas.empty) {
                            //
                            resolve(null);
                        } else {
                            let realizadas = [];
                            respRealizadas.forEach(doc => {
                                let data = doc.data();
                                data.id = doc.id;
                                realizadas.push(data);
                            });
                            result.realizadas = realizadas;
                            resolve(result);
                        }
                    }).catch(err => {
                        reject(err);
                    })
                }
            }))


        });
        return Promise.all(promises);
    } catch (error) {
        throw (error);
    }
}

export async function obtenerActividadesSemanaOptimizado(time, registrosActividadesInv, actividadesImportantesOrig) {
    try {
        let lineasTotales = 0;
        let values = await obtenerTodasActividadesRealizadasDiaSync(registrosActividadesInv, time)
        return contarActividades(actividadesImportantesOrig, values);



    } catch (error) {
        console.log("ERROR AC")
        console.log(error);
        throw error;
    }
}


export const obtenerTodasActividadesInvernadero = async (ciclo, invernadero) => {
    let db = firebase.firestore();
    let snap = await db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero).collection("registro_actividades").get()
    registrarInvocaciones(snap.size)
    return snap.docs.map(el => ({ id: el.id, path: el.ref.path, ...el.data() }))
}

export function obtenerActividadesSemana(time, cuenta, ciclo, invernadero) {
    var db = firebase.firestore();
    return new Promise((resolve, reject) => {

        let lineasTotales = 0;

        obtenerTodasActividadesRealizadasDia(ciclo, invernadero, time).then(values => {
            obtenerActividadesImportantes().then(respImportante => {
                resolve(contarActividades(respImportante, values));
            }).catch(err => {
                reject(err);
            })

        }).catch(err => {
            reject(err);
        })
    })
}
export function getActividadesCuentaSemana(actividadesRegistradas, actividadesImportantes) {
    return getCuentaActividades(actividadesImportantes, actividadesRegistradas);
}
const getCuentaActividades = (respImportante, values) => {
    let principales = [], idBajado = "";
    respImportante.forEach(data => {
        if (data.nombre !== nombreActividadCosecha) principales.push(data.id);
        if (data.nombre === nombreActividadBajado) idBajado = data.id;
    })
    let lineasTotales = values.reduce((acc, curr) => {
        let sumaDia = 0;
        if (curr) {
            if (curr.realizadas) {
                curr.realizadas.map(el => {
                    if (el.actividades) {
                        principales.forEach(key => {
                            let cantidad = parseInt(el.actividades[key]);
                            if (key === idBajado) sumaDia += cantidad ? 1 * cantidad : 0;
                            else sumaDia += cantidad ? cantidad : 0;
                        })
                    }
                })
                return (acc + sumaDia);
            } else return acc + 0;
        } else return acc + 0;
    }, 0)
    return (lineasTotales / numActividadesPrincipales);
}

const obtenerTodasActividadesRealizadasDia = async (ciclo, invernadero, dia) => {
    try {
        let dias = obtenerDiasDeSemana(dia);
        let promises = [];
        dias.map(dia => {
            //obtener los dias        
            promises.push(new Promise((resolve, reject) => {
                obtenerRegistroActividadesDia(ciclo, invernadero, dia).then(resp => {

                    if (resp.empty) {
                        //
                        resolve(null);
                    } else {
                        //

                        resp.forEach(el => {
                            resp = el;
                        })
                        let result = resp.data();
                        result.id = resp.id;
                        obtenerActividadesRealizadasDesdeRegistroActividades(resp.ref.path).then(respRealizadas => {
                            if (respRealizadas.empty) {
                                //
                                resolve(null);
                            } else {
                                let realizadas = [];
                                respRealizadas.forEach(doc => {
                                    let data = doc.data();
                                    data.id = doc.id;
                                    realizadas.push(data);
                                });
                                result.realizadas = realizadas;
                                resolve(result);
                            }
                        }).catch(err => {
                            reject(err);
                        })
                    }
                }).catch(err => {
                    reject(err);
                })
            }))


        });
        return Promise.all(promises);
    } catch (error) {
        throw (error);
    }
}

RhService.type = 'SingletonNoInstance';
export default RhService;


export async function getRegistroActividadesDeLaSemana(cicloId, invernaderoVirtualId, semanaUnixTimestamp) {
    // 
    let diasDeLaSemana = obtenerDiasDeSemana(semanaUnixTimestamp);
    if (!diasDeLaSemana.length || diasDeLaSemana.length === 0) return
    let inicioSemanaUnixTimeStamp = flexDate(diasDeLaSemana[0])
    let finSemanaUnixTimestamp = flexDate(diasDeLaSemana[diasDeLaSemana.length - 1])


    var registroActividades = []
    const firestore = firebase.firestore()
    await firestore.collection('ciclos')
        .doc(cicloId)
        .collection('invernaderos_virtuales')
        .doc(invernaderoVirtualId)
        .collection('registro_actividades')
        .where('dia', '>=', inicioSemanaUnixTimeStamp.inicio)
        .where('dia', '<=', finSemanaUnixTimestamp.fin)
        .get()
        .then((snapshot) => {
            registrarInvocaciones(snapshot.size)
            snapshot.forEach(registroActividadesSnapshot => {
                registroActividades.push({ id: registroActividadesSnapshot.id, ...registroActividadesSnapshot.data() })
            });
        });

    for (let i = 0; i < registroActividades.length; i++) {
        registroActividades[i]['actividades_realizadas'] = []
        await firestore.collection('ciclos')
            .doc(cicloId)
            .collection('invernaderos_virtuales')
            .doc(invernaderoVirtualId)
            .collection('registro_actividades')
            .doc(registroActividades[i].id)
            .collection('actividades_realizadas')
            .get()
            .then((snapshot) => {
                registrarInvocaciones(snapshot.size)
                snapshot.forEach(actividadesRealizadasSnapshot => {
                    registroActividades[i]['actividades_realizadas'].push({ id: actividadesRealizadasSnapshot.id, ...actividadesRealizadasSnapshot.data() })
                });
            });
    }

    return registroActividades
}

export const obtenerEmpleado = async (referencia) => {
    const db = firebase.firestore();
    let snap = await db.collection("empleados").doc(referencia).get();
    registrarInvocaciones(1)

    return { ...snap.data(), id: snap.id };
}
const obtenerEmpleadoDeReferencia = async (referencia) => {
    //  
    const db = firebase.firestore();
    let snap = await db.collection("empleados").doc(referencia).get();
    registrarInvocaciones(1)
    return { ...snap.data(), id: snap.id }
}
export const getNumeroDeEmpleadosEnInvernadero = async (ciclo, invernadero) => {

    let empleados = await obtener_empleados_invernadero(ciclo, invernadero)
    return empleados.length;
}
export const getNumeroDeEmpleadosActivosEnInvernadero = async (ciclo, invernadero) => {
    let empleados = await obtener_empleados_invernadero(ciclo, invernadero);
    let empleadosId = empleados.map(el => el.empleado_ref);
    let todosEmpleados = await obtenerEmpleados();
    // let promesas = empleados.map(empleado=>obtenerEmpleadoDeReferencia(empleado.empleado_ref));
    // let result = await Promise.all(promesas);
    return todosEmpleados.filter(el => empleadosId.includes(el.id) && el.activo).length;
}

export const obtenerEmpleados = async (params) => {
    const db = firebase.firestore();
    let snapEmpleados = await db.collection("empleados").get();
    registrarInvocaciones(snapEmpleados.size)
    return snapEmpleados.docs.map(el => ({ id: el.id, ...el.data() }))
}
export const obtenerEmpleadosActivos = async (params) => {
    const db = firebase.firestore();
    let snapEmpleados = await db.collection("empleados").where("activo", "==", true).get();
    registrarInvocaciones(snapEmpleados.size);
    return snapEmpleados.docs.map(el => ({ id: el.id, ...el.data() }));
}
export const obtenerEmpleadosActivosDelCicloActual = async (ciclo) => {
    const db = firebase.firestore();
    let snapEmpleados = await db.collection("empleados").where("activo", "==", true).where("").get();
    registrarInvocaciones(snapEmpleados.size);
    return snapEmpleados.docs.map(el => ({ id: el.id, ...el.data() }));
}

export const obtenerEmpleadosEmpaque = async (empaqueId) => {
    const db = firebase.firestore();
    let snap = await db.collection("empleados").where("subdepartamento_ref", "==", empaqueId).get();
    registrarInvocaciones(snap.size)
    return snap.docs.map(el => ({ id: el.id, ...el.data() }))
}
export const obtenerEmpleadosOtros = async (params) => {
    const db = firebase.firestore();
    let snap = await db.collection("empleados").where("subdepartamento_ref", "==", "").get();
    registrarInvocaciones(snap.size)
    return snap.docs.map(el => ({ id: el.id, ...el.data() }))
}


export const obtenerResumenCardRH = async (ciclo, invernadero, semana) => {
    try {

        let maximoEmpleados = invernadero.numero_maximo_trabajadores
        let numeroEmpleados = await getNumeroDeEmpleadosActivosEnInvernadero(ciclo, invernadero.id)
        let rotacion = await obtenerRotacionEmpleados(ciclo, invernadero.id, semana)
        let interseccion = await obtenerInterseccionEmpleadosActivos(ciclo, invernadero.id, semana)

        let copia = { ...invernadero };
        copia.empleados = `${numeroEmpleados}/${maximoEmpleados}`;
        copia.activos = `${Math.round(100 * numeroEmpleados / maximoEmpleados)} %`;
        copia.rotacion = rotacion
        copia.interseccion = interseccion
        return copia;
    } catch (error) {
        throw error;
    }
}
export const obtenerResumenCardNomina = async (ciclo, invernadero, semana) => {
    try {
        let maximoEmpleados = invernadero.numero_maximo_trabajadores
        let numeroEmpleados = await getNumeroDeEmpleadosActivosEnInvernadero(ciclo, invernadero.id)
        let actividades = await obtenerActividadesSemana(semana, numeroEmpleados, ciclo, invernadero.id);
        let porcentaje = await obtenerPorcentajeHorasTrabajadas(semana, numeroEmpleados, ciclo, invernadero.id);
        let copia = { ...invernadero };
        copia.horasTrabajadas = porcentaje;
        copia.porcActividades = actividades;
        copia["cuentaEmpleados"] = numeroEmpleados;
        copia["activos"] = `${Math.round(100 * numeroEmpleados / maximoEmpleados)} %`;
        return copia;
    } catch (error) {
        throw error;
    }
}
export const obtenerListaEmpleadosInvernadero = async (ciclo, invernadero) => {
    try {
        const db = firebase.firestore();
        let empleadosInvernadero = await obtener_empleados_invernadero(ciclo, invernadero.id);
        let refsEmpleados = empleadosInvernadero.map(el => el.empleado_ref);
        let empleados = await obtenerEmpleados();
        let listaEmpleados = empleados.filter(el => refsEmpleados.includes(el.id));
        return listaEmpleados;
    } catch (error) {
        throw error;
    }
}


export const obtenerRotacionEmpleados = async (cicloId, invernaderoId, semana) => {
    try {
        let activosSemanaPasada = await obtenerCuentaEmpleadosActivos(cicloId, invernaderoId, semana - 7 * 24 * 60 * 60);
        let activosSemanaActual = await obtenerCuentaEmpleadosActivos(cicloId, invernaderoId, semana);
        let rotacion = activosSemanaPasada > 0 ? (activosSemanaActual / activosSemanaPasada) : 0;
        return rotacion;
    } catch (error) {
        throw error;
    }
}
export const getRotacionEmpleados = (cicloId, invernaderoId, semana, allEmpeadosInvs, allRegistrosActividades, allRegistrosActividadesPasada) => {
    try {
        let empleados = allEmpeadosInvs.filter(empleado => empleado.invernadero_virtual_ref === invernaderoId)
        let activosSemanaPasada = getCuentaEmpleadosActivos(cicloId, invernaderoId, semana - 7 * 24 * 60 * 60, empleados, allRegistrosActividadesPasada);
        let activosSemanaActual = getCuentaEmpleadosActivos(cicloId, invernaderoId, semana, empleados, allRegistrosActividades);
        let rotacion = activosSemanaPasada > 0 ? (activosSemanaActual / activosSemanaPasada) : 0;
        return rotacion;
    } catch (error) {
        throw error;
    }
}
export const obtenerInterseccionEmpleadosActivos = async (cicloId, invernaderoId, semana) => {

    try {
        let activosSemanaPasada = await obtenerListaEmpleadosActivos(cicloId, invernaderoId, semana - 7 * 24 * 60 * 60);
        let activosSemanaActual = await obtenerListaEmpleadosActivos(cicloId, invernaderoId, semana);
        let idsActivosSemanaPasada = Array.from(activosSemanaPasada);
        let idsActivosSemanaActual = Array.from(activosSemanaActual);
        let idsInterseccion = idsActivosSemanaActual.filter(el => idsActivosSemanaPasada.includes(el));
        let ratio = idsActivosSemanaPasada.length === 0 ? 0 : idsInterseccion.length / idsActivosSemanaPasada.length;
        return ratio;
    } catch (error) {
        throw error;
    }
}
//get list of active employees on last week
export const obtenerListaEmpleadosActivos = async (cicloId, invernaderoId, semana) => {
    try {
        let empleados = await obtener_empleados_invernadero(cicloId, invernaderoId);
        let registros = await obtenerRegistroActividadesSemana(cicloId, invernaderoId, semana);
        // obtener actividades realizadas por cada dia

        let promisesActividades = registros.docs.map(el => obtenerActividadesRealizadasDesdeRegistroActividades(el.ref.path));
        let actividades = await Promise.all(promisesActividades);
        actividades = actividades.map(act => {

            return act.docs.map(el => ({ id: el.id, ...el.data() }))
        })
        let activos = new Set();
        // por cada empleado, en cada dia, detectar si hubo un dia en el que hizo alguna tarea o pidio permiso, vacaciones
        empleados.forEach(empleado => {
            actividades.forEach(actividadesDia => {
                let actEmpleado = actividadesDia.find(actividadEmpleado => actividadEmpleado.empleado_ref === empleado.empleado_ref);
                if (actEmpleado) {
                    if (actEmpleado.tuvo_permiso || actEmpleado.tuvo_vacaciones) {
                        activos.add(empleado.empleado_ref);
                    } else {
                        let listaActividades = Object.keys(actEmpleado.actividades);
                        listaActividades.forEach(act => {
                            let cuenta = parseFloat(actEmpleado["actividades"][act]);
                            if (cuenta > 0) {
                                activos.add(empleado.empleado_ref);
                            }
                        })

                    }

                }
            })

        })

        return activos
        //de ser asi, agregar a lista de empleados con actividades


    } catch (error) {
        throw error;
    }

}

export const obtenerRotacionAcumulada = async (ciclo, invernaderoId) => {
    try {
        let listaSemanas = await obtenerListaDeSemanas(ciclo.semana_inicio, ciclo.semana_cierre);
        let promises = listaSemanas.map(semana => obtenerCuentaEmpleadosActivos(ciclo.id, invernaderoId, semana.time));
        let rotaciones = await Promise.all(promises);
        let result = rotaciones.reduce((acc, curr) => acc + curr, 0) / rotaciones.length;
        return result;
    } catch (error) {
        throw error;
    }
}
export const obtenerCuentaEmpleadosActivos = async (cicloId, invernaderoId, semana) => {
    try {
        let empleados = await obtener_empleados_invernadero(cicloId, invernaderoId);
        let registros = await obtenerRegistroActividadesSemana(cicloId, invernaderoId, semana);
        // obtener actividades realizadas por cada dia

        let promisesActividades = registros.docs.map(el => obtenerActividadesRealizadasDesdeRegistroActividades(el.ref.path));
        let actividades = await Promise.all(promisesActividades);
        actividades = actividades.map(act => {

            return act.docs.map(el => ({ id: el.id, ...el.data() }))
        })
        let activos = new Set();
        // por cada empleado, en cada dia, detectar si hubo un dia en el que hizo alguna tarea o pidio permiso, vacaciones
        empleados.forEach(empleado => {
            actividades.forEach(actividadesDia => {
                let actEmpleado = actividadesDia.find(actividadEmpleado => actividadEmpleado.empleado_ref === empleado.empleado_ref);
                if (actEmpleado) {
                    if (actEmpleado.tuvo_permiso || actEmpleado.tuvo_vacaciones) {
                        activos.add(empleado.empleado_ref);
                    } else {
                        let listaActividades = Object.keys(actEmpleado.actividades);
                        listaActividades.forEach(act => {
                            let cuenta = parseFloat(actEmpleado["actividades"][act]);
                            if (cuenta > 0) {
                                activos.add(empleado.empleado_ref);
                            }
                        })

                    }

                }
            })
        })
        return activos.size;
        //de ser asi, agregar a lista de empleados con actividades
    } catch (error) {
        throw error;
    }
}

export const obtenerCuentaEmpleadosActivosConAusencias = async (cicloId, invernaderoId, semana) => {
    try {
        let empleados = await obtener_empleados_invernadero(cicloId, invernaderoId);
        let registros = await obtenerRegistroActividadesSemana(cicloId, invernaderoId, semana);
        // obtener actividades realizadas por cada dia

        let promisesActividades = registros.docs.map(el => obtenerActividadesRealizadasDesdeRegistroActividades(el.ref.path));
        let actividades = await Promise.all(promisesActividades);
        actividades = actividades.map(act => {

            return act.docs.map(el => ({ id: el.id, ...el.data() }))
        })
        let activos = new Set();
        // por cada empleado, en cada dia, detectar si hubo un dia en el que hizo alguna tarea o pidio permiso, vacaciones
        empleados.forEach(empleado => {
            actividades.forEach(actividadesDia => {
                let actEmpleado = actividadesDia.find(actividadEmpleado => actividadEmpleado.empleado_ref === empleado.empleado_ref);
                if (actEmpleado) {
                    if (actEmpleado.tuvo_permiso || actEmpleado.tuvo_vacaciones || actEmpleado.tuvo_ausencia) {
                        activos.add(empleado.empleado_ref);
                    } else {
                        let listaActividades = Object.keys(actEmpleado.actividades);
                        listaActividades.forEach(act => {
                            let cuenta = parseFloat(actEmpleado["actividades"][act]);
                            if (cuenta > 0) {
                                activos.add(empleado.empleado_ref);
                            }
                        })

                    }

                }
            })
        })
        console.log(activos, `empleados que tienen algun registro en las actividades de la semana ${semana}`)
        return activos.size;
        //de ser asi, agregar a lista de empleados con actividades
    } catch (error) {
        throw error;
    }
}

export const getCuentaEmpleadosActivos = (cicloId, invernaderoId, semana, empleados, registros) => {
    try {
        let activos = new Set();
        // por cada empleado, en cada dia, detectar si hubo un dia en el que hizo alguna tarea o pidio permiso, vacaciones
        empleados.forEach(empleado => {
            registros.forEach(actividadesDia => {
                let actEmpleado = actividadesDia.realizadas.find(actividadEmpleado => actividadEmpleado.empleado_ref === empleado.empleado_ref);
                if (actEmpleado) {
                    if (actEmpleado.tuvo_permiso || actEmpleado.tuvo_vacaciones) {
                        activos.add(empleado.empleado_ref);
                    } else {
                        let listaActividades = Object.keys(actEmpleado.actividades);
                        listaActividades.forEach(act => {
                            let cuenta = parseFloat(actEmpleado["actividades"][act]);
                            if (cuenta > 0) {
                                activos.add(empleado.empleado_ref);
                            }
                        })

                    }

                }
            })
        })
        return activos.size;
        //de ser asi, agregar a lista de empleados con actividades
    } catch (error) {
        throw error;
    }
}

export const guardarEmpleadoEmpaque = async (cicloId,
    nombre, apellidos,
    generoSeleccionadoId,
    fechaIngreso,
    fechaNacimiento,
    numeroSeguroSocial,
    curp,
    codigoEmpleado,
    departamentoSeleccionadoId,
    invernaderoVirtualSeleccionadoId,
    empaqueSeleccionadoId,
    departamentoSeleccionadoNombre,
    salarioDiario,
    diasVacaciones, notas,
    rolEmpaque,
    empacadora) => {
    try {
        let empleadoBase = await RhService.guardarEmpleado(cicloId,
            nombre, apellidos,
            generoSeleccionadoId,
            fechaIngreso,
            fechaNacimiento,
            numeroSeguroSocial,
            curp,
            codigoEmpleado,
            departamentoSeleccionadoId,
            invernaderoVirtualSeleccionadoId,
            empaqueSeleccionadoId,
            departamentoSeleccionadoNombre,
            salarioDiario,
            diasVacaciones, notas);
        if (empleadoBase) {


            let registroEmpaque = await actualizarRegistroEmpaque(cicloId, empaqueSeleccionadoId, codigoEmpleado, rolEmpaque, empacadora);
        }
        return empleadoBase;
    } catch (error) {
        console.log("ERROR guardar EMPLEADO BASE");
        console.log(error);
        throw error;
    }
    //despues editar

}
export const modificarEmpleadoEmpaque = async (empleadoId,
    nombre, apellidos, generoSeleccionadoId,
    fechaIngreso, fechaNacimiento,
    numeroSeguroSocial, curp, codigoEmpleado,
    salarioDiario, diasVacaciones,
    nombreDepartamento, invernaderoVirtualId, empacadoraId, cicloId,
    notas, rolEmpaque, empacadora,) => {
    try {
        let empleado = await obtenerEmpleadoEmpaqueCiclo(cicloId, empacadoraId, empleadoId);
        let empleadoBase = await RhService.modificarEmpleado(empleado.empleado_ref,
            nombre, apellidos, generoSeleccionadoId,
            fechaIngreso, fechaNacimiento,
            numeroSeguroSocial, curp, codigoEmpleado,
            salarioDiario, diasVacaciones,
            nombreDepartamento, invernaderoVirtualId, empacadoraId, cicloId,
            notas);
        //despues editar
        if (empleadoBase) {


            let registroEmpaque = await actualizarRegistroEmpaque(cicloId, empacadoraId, codigoEmpleado, rolEmpaque, empacadora);
            return true;
        }
        return true;
    } catch (error) {
        console.log("ERROR MODIFICAR EMPLEADO BASE");
        console.log(error);
        throw error;
    }
}
export const modificarEmpleadoInvernaderoAEmpaque = async (empleadoId,
    nombre, apellidos, generoSeleccionadoId,
    fechaIngreso, fechaNacimiento,
    numeroSeguroSocial, curp, codigoEmpleado,
    salarioDiario, diasVacaciones,
    nombreDepartamento, invernaderoVirtualId, empacadoraId, cicloId,
    notas, rolEmpaque, empacadora, departamentoId) => {
    try {
        //ver si existe empleado previo, si no crearlo
        let empleadoBase = await RhService.modificarEmpleado(empleadoId,
            nombre, apellidos, generoSeleccionadoId,
            fechaIngreso, fechaNacimiento,
            numeroSeguroSocial, curp, codigoEmpleado,
            salarioDiario, diasVacaciones,
            nombreDepartamento, invernaderoVirtualId, empacadoraId, cicloId,
            notas, departamentoId);
        //despues editar
        if (empleadoBase) {


            let registroEmpaque = await actualizarRegistroEmpaque(cicloId, empacadoraId, codigoEmpleado, rolEmpaque, empacadora);
            return true;
        }
        return true;
    } catch (error) {
        console.log("ERROR MODIFICAR EMPLEADO BASE transf");
        console.log(error);
        throw error;
    }
}


const actualizarRegistroEmpaque = async (cicloId, empaqueId, codigoEmpleado, rolEmpaque, empacadora) => {
    const db = firebase.firestore();
    let empleado = await obtenerEmpleadoPorCodigo(codigoEmpleado);
    let empaque = await obtenerEmpaqueCiclo(cicloId, empaqueId);
    let empleadoEmpaque = await obtenerEmpleadoEmpaque(cicloId, empaque.id, empleado.id);











    let obj = { empleado_ref: empleado.id }
    if (rolEmpaque) {
        obj["rol_ref"] = rolEmpaque.id
        if (rolEmpaque.nombre === "Empacador" && empacadora) {
            obj["codigo_empacadora"] = empacadora.id;
        }
    }
    if (empleadoEmpaque.existe) {
        let result = await db.collection("ciclos").doc(cicloId).collection("empaques").doc(empaque.id).
            collection("empleados").doc(empleadoEmpaque.id).update(obj);

    } else {
        let result = await db.collection("ciclos").doc(cicloId).collection("empaques").doc(empaque.id).
            collection("empleados").add(obj);
    }
    return true
}

const obtenerEmpleadoEmpaque = async (cicloId, empaqueId, empleadoId) => {
    try {
        const db = firebase.firestore();
        let snap = await db.collection("ciclos").doc(cicloId).collection("empaques").doc(empaqueId).collection("empleados").
            where("empleado_ref", "==", empleadoId).get();
        registrarInvocaciones(snap.size)
        if (snap.empty) {
            return { existe: false }
        } else {
            let doc = snap.docs[0];
            return { existe: true, id: doc.id, ...doc.data() }
        }
    } catch (error) {
        console.log("ERROR OEE");
        console.log(error);
        throw error;
    }
}

const obtenerEmpleadoPorCodigo = async (codigo) => {
    const db = firebase.firestore();
    let snap = await db.collection("empleados").where("codigo_empleado", "==", codigo).get();
    registrarInvocaciones(snap.size)
    let doc = snap.docs[0];
    return { id: doc.id, ...doc.data() }
}
const asegurarRelacionEmpleadoInvernadero = async (cicloId, empleadoId, invernaderoId) => {
    // 
    // 
    // 
    // 
    const db = firebase.firestore();
    let snap = await db.collection("empleados_invernadero_virtual_de_ciclo").where("ciclo_ref", "==", cicloId).
        where("empleado_ref", "==", empleadoId).get();
    registrarInvocaciones(snap.size)
    // 
    if (snap.empty) {
        // 
        let doc = await db.collection("empleados_invernadero_virtual_de_ciclo").add(
            {
                ciclo_ref: cicloId,
                empleado_ref: empleadoId,
                invernadero_virtual_ref: invernaderoId
            }
        )
        return true;
    } else {
        let doc = snap.docs[0];
        // 
        // 
        let result = await db.doc(doc.ref.path).update({
            invernadero_virtual_ref: invernaderoId
        })
        return true;
    }
}
//1607