
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import React, { useState, useEffect } from 'react';
import { useGlobal } from 'reactn'
import { obtenerClientes, obtenerClientesCiclo } from '../../service/Clientes';
import { obtenerInicioDia, obtenerNombreFecha } from '../../service/fechas';
import { obtenerBudgetGrupoPresentaciones, obtenerGruposPresentaciones } from '../../service/QueriesGruposPresentaciones';
import { actualizarItemManifiesto, obtenerResumenPreciosManifiesto, desplazarItemManifiesto, NO_APROBADO } from '../../service/ReportePrecios';
import ContenedorFiltro from '../common/ContenedorFiltro';
import ContenedorFiltroBusqueda from '../common/ContenedorFiltroBusqueda';
import ContenedorHeader from '../common/ContenedorHeader'
import ContenedorPrincipal from '../common/ContenedorPrincipal'
import ContenedorTabla from '../common/ContenedorTabla';
import SeccionFiltros from '../common/SeccionFiltros'
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import ModalFormulario from './ModalFormulario';

const fecha_dummy = obtenerInicioDia(1606923450);
const dummy = [
    { presentacion: "DM1", manifiesto: "M1", fecha_envio: fecha_dummy, cajas: 10, status: "Sin" },
    { presentacion: "DM2", manifiesto: "M2", fecha_envio: fecha_dummy, cajas: 20, status: "Sin" },
    { presentacion: "DM3", manifiesto: "M3", fecha_envio: fecha_dummy, cajas: 30, status: "No aprobado" },
    { presentacion: "DM4", manifiesto: "M4", fecha_envio: fecha_dummy, cajas: 30, status: "Aprobado" },
    { presentacion: "DM5", manifiesto: "M5", fecha_envio: fecha_dummy, cajas: 30, status: "Reproceso" },
    { presentacion: "DM6", manifiesto: "M6", fecha_envio: fecha_dummy, cajas: 30, status: "Rechazo" },
]
const Consulta = (props) => {
    const [ciclo, setCiclo] = useGlobal("ciclo");
    const [usuario, setUsuario] = useGlobal("usuario");
    const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
    const [productosBases] = useGlobal("productosBases");
    const [medleysGeneral] = useGlobal("medleysGeneral");
    const [cargando, setCargando] = useGlobal("cargando");
    const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector();
    const [mostrarFormulario, setMostrarFormulario] = useState(false);
    const [manifiestos, setManifiestos] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [filtroCliente, setFiltroCliente] = useState();
    const [edicionFormulario, setEdicionFormulario] = useGlobal("edicionVerificacionPrecios");
    const [triggerUpdate, setTriggerUpdate] = useState(false);
    const [mensajeError, setMensajeError] = useState("");
    const [modalConfirmacion, setModalConfirmacion] = useGlobal("modalConfirmacion");
    const [budgets, setBudgets] = useState([])
    const [gruposPresentaciones, setGruposPresentaciones] = useState([])
    const [invernaderos, setInvernaderos] = useState([])
    const [cajaBusqueda, setCajaBusqueda] = useState("")
    const [filtroProducto, setFiltroProducto] = useState([])
    const [filtroInvernadero, setFiltroInvernadero] = useState([])
    const [productos, setProductos] = useState([])
    const [manifiestosOrig, setManifiestosOrig] = useState([])
    const [nombreModulo, setNombreModulo] = useGlobal("nombreModulo");
    const [globalFilter, setGlobalFilter] = useGlobal("globalFilter");

    useEffect(() => {
        setNombreModulo("Finanzas");
    }, [])
    useEffect(() => {
        if (invernaderosVirtuales) obtenerDatosIniciales();
    }, [invernaderosVirtuales]);
    useEffect(() => {
        if (ciclo && filtroCliente && week.nombre !== "Cargando") updateInfo();
    }, [ciclo, filtroCliente, week])
    useEffect(() => {
        if (triggerUpdate) {
            updateInfo();
            setTriggerUpdate(false);
        }
    }, [triggerUpdate])

    //Fix de filtros/////
    useEffect(() => {
        setFiltroProducto(productosBases || [])
        setProductos(productosBases)
        setFiltroInvernadero(invernaderosVirtuales || [])
        setInvernaderos(invernaderosVirtuales || [])
    }, [filtroCliente])



    useEffect(() => {
        let productosIds = filtroProducto?.map(el => el.id) || []
        let invernaderosIds = filtroInvernadero?.map(el => el.id) || []
        let filtrados = manifiestosOrig.filter(el => (productosIds?.includes(el.producto_ref) || (productosIds?.includes(medleysGeneral?.id) && el.producto.mezcla)) && invernaderosIds?.includes(el.invernadero_ref))
        setManifiestos(filtrados)
    }, [filtroProducto, filtroInvernadero, manifiestosOrig])
    const updateInfo = async (params) => {
        let resumen = await obtenerResumenPreciosManifiesto(ciclo, week.time, filtroCliente.id)
        setManifiestosOrig(resumen);
        setManifiestos(resumen);
    }

    const activarModalConfirmacionDesplazamiento = (item, original) => {
        let mensaje = "¿Estás seguro que deseas desplazar este item a la siguiente semana?"
        let titulo = "Confirmación de acción";
        let aceptar = () => { desplazar(item, original) };
        let cancelar = () => { }
        setModalConfirmacion({ mensaje, titulo, aceptar, cancelar })
    }

    const obtenerDatosIniciales = async (params) => {
        setCargando(true)
        updateCicloWeekSelector(ciclo);
        let clientesPromise = obtenerClientes();
        let clientesCicloPromise = obtenerClientesCiclo(ciclo);
        let [clientes, clientesCiclo] = await Promise.all([clientesPromise, clientesCicloPromise]);
        let idsClientes = clientesCiclo.map(el => el.cliente_ref);
        clientes = clientes.filter(el => idsClientes.includes((el.id)))

        setClientes(clientes);
        setFiltroCliente(clientes[0])

        //    setFiltroInvernadero(invernaderosVirtuales.filter(el => el.cliente_ref === clientes[0].id))
        //   setFiltroProducto(productosBases)

        let gruposPresentaciones = await obtenerGruposPresentaciones()
        let promisesBudgets = gruposPresentaciones.map(el => obtenerBudgetGrupoPresentaciones(ciclo, el.id))
        let budgets = await Promise.all(promisesBudgets);
        setGruposPresentaciones(gruposPresentaciones);
        setBudgets(budgets);
        setCargando(false)
    }
    const templateFecha = (rowData, column) => {
        let fecha = obtenerNombreFecha(rowData["fecha_envio"]);
        return fecha;
    }
    const templatePrecio = (rowData, column) => {
        if (rowData["precio_libra"] && rowData["status"] === "aprobado") {
            return `$${rowData["precio_libra"]}`;
        }
        else if (rowData["status"] === "reproceso") {
            return `$${rowData?.["precio_con_reproceso"] || 0}`;
        }
        else if (rowData["status"] === "rechazo") {
            return `$${rowData?.["precio_con_rechazo"] || 0}`;
        }
        return "N/A";
    }

    const templateStatus = (rowData, column) => {
        let status = "";
        if (rowData["status"] === "aprobado") {
            return <p className="precio-status aprobado"><span></span>Aprobado</p>
        } else if (rowData["status"] === "reproceso") {
            return <p className="precio-status reproceso"><span></span>Reproceso</p>
        }
        else if (rowData["status"] === "no_aprobado") {
            return <p className="precio-status pendiente"><span></span>Pendiente</p>
        }
        else if (rowData["status"] === "rechazo") {
            return <p className="precio-status rechazo"><span></span>Rechazo</p>
        }
    }
    const activarVerificacion = (rowData) => {
        setEdicionFormulario(rowData);
        setMostrarFormulario(true);
    }

    const templateBotonFormulario = (rowData, column) => <button className="p-button p-component p-button-text-only" onClick={() => activarVerificacion(rowData)} > Verificar </button>;

    const obtenerColumnas = (params) => {
        let listaColumnas = ["presentacion", "manifiesto", "fecha_envio", "cajas_orden", "status", "precio_libra", "invernadero"]
        let columns = listaColumnas.map(el => {
            if (el === "fecha_envio") {
                return <Column key={el} field={el} header={"Fecha envio"} body={templateFecha} sortable />
            } else if (el === "status") {
                return <Column key={el} field={el} header={el} body={templateStatus} sortable />
            }
            else if (el === "cajas_orden") {
                return <Column key={el} field={el} header={"Cajas"} sortable body={templateCajas} />

            } else if (el === "invernadero") {
                return <Column key={el} field={"nombre_invernadero"} header={"Invernadero"} sortable />
            }
            else if (el === "precio_libra") {
                return <Column key={el} field={el} header={"Precio"} sortable body={templatePrecio} />
            }
            else {
                return <Column key={el} field={el} header={el} sortable />
            }
        });
        /* 
        NOTA: Se quito la columna Acciones para convertir en pantalla solo de consulta de items antiguos 
              antes de validacion rechazos.
        if (usuario?.rol !== "Personal Administrativo") {
            columns.push(<Column key="acciones" field="acciones" header="Acciones" body={templateBotonFormulario} sortable />);
        } */
        if (usuario?.rol !== "Personal Administrativo") {
            columns.push(<Column key="acciones" field="acciones" header="Acciones" body={templateBotonFormulario} sortable />);
        } 
        return columns;
    }

    const templateCajas = (rowData) => {
        return <div>{rowData.cajas_orden || 0}<span className="label-unidad">cajas</span> </div>
    }
    const hideFormulario = (params) => {
        setMostrarFormulario(false);
    }
    const handleButtonClick = (params) => {
        setMostrarFormulario(true);
    }
    const guardarManifiesto = async (item, original) => {
        if (original.status === NO_APROBADO) {
            setMensajeError("NO SE PUEDE CAMBIAR STATUS");
            return;
        }
        setCargando(true);
        let ahora = Math.round(Date.now() / 1000);
        item.fecha_asignacion = ahora;
        try {
            console.log("DONE: ",{ciclo, item, original, manifiestosOrig})
            let x = await actualizarItemManifiesto(ciclo, item, original, manifiestosOrig);
            setTriggerUpdate(true);
            hideFormulario();
        } catch (error) {
            console.log("ERROR GM: ", error);
        } finally {
            setCargando(false);
        }
    }
    const desplazar = async (item, original) => {
        try {
            let x = await desplazarItemManifiesto(ciclo, item, original);
            setTriggerUpdate(true);
            hideFormulario();

        } catch (error) {
            console.log("ERROR DESPLAZAR: ", error)
        }
    }

    const handleDesplazar = async (item, original) => {
        activarModalConfirmacionDesplazamiento(item, original);
    }

    return (
        <ContenedorPrincipal>
            <ContenedorHeader titulo="Reporte de validación de precios" iconos={"reporte-empaque-icon"} />
            <SeccionFiltros>
                <ContenedorFiltroBusqueda label="caja de busqueda" for="search">
                    <FontAwesomeIcon icon={faSearch} />
                    <InputText type="search" value={cajaBusqueda} onChange={e => setCajaBusqueda(e.target.value)} placeholder="Buscar" />
                </ContenedorFiltroBusqueda>
                <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />

                <ContenedorFiltro label="Clientes" for="clientes">
                    <Dropdown optionLabel="nombre" dataKey="id" options={clientes} value={filtroCliente} onChange={e => setFiltroCliente(e.value)} />
                </ContenedorFiltro>
                <ContenedorFiltro label="Invernaderos" for="invernaderos">
                    <MultiSelect optionLabel="nombre" dataKey="id" options={invernaderos} value={filtroInvernadero} onChange={e => setFiltroInvernadero(e.value)}
                        selectedItemsLabel={`${filtroInvernadero.length} invernaderos seleccionados`} />
                </ContenedorFiltro>
                <ContenedorFiltro label="Producto" for="producto">
                    <MultiSelect optionLabel="nombre" dataKey="id" options={productos} value={filtroProducto} onChange={e => setFiltroProducto(e.value)}
                        selectedItemsLabel={`${filtroProducto.length} productos seleccionados`} />
                </ContenedorFiltro>
            </SeccionFiltros>

            <ContenedorTabla>
                <DataTable value={manifiestos} globalFilter={cajaBusqueda}>
                    {obtenerColumnas()}
                </DataTable>
            </ContenedorTabla>

            <ModalFormulario show={mostrarFormulario} onHide={hideFormulario} guardar={guardarManifiesto} mensajeError={mensajeError}
                desplazar={desplazar} budgets={budgets} gruposPresentaciones={gruposPresentaciones} semana={week.time} />
        </ContenedorPrincipal>
    )
}
export default Consulta//310