import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { MultiSelect } from 'primereact/multiselect'
import React from 'react'
import { useEffect, useGlobal, useState } from 'reactn'
import { getPresentaciones, obtenerPresentacionesNoAsignadasAGrupo } from '../../../service/Presentaciones'
import { registrarGrupoPresentaciones, actualizarGrupoPresentaciones, obtenerGruposPresentacion, obtenerGruposPresentaciones } from '../../../service/QueriesGruposPresentaciones'
import ContenedorHeader from '../../common/ContenedorHeader'
import ContenedorInput from '../../common/ContenedorInput'
import ContenedorPrincipal from '../../common/ContenedorPrincipal'
import SeccionBotones from '../../common/SeccionBotones'
import SeccionFormulario from '../../common/SeccionFormulario'
import { obtenerClientes } from '../../../service/Clientes';
import { Dropdown } from 'primereact/dropdown';
import { Message } from 'primereact/message'

const FormularioGrupoPresentaciones = (props) => {
    const { grupoPresentacionID } = props.match.params
    const editando = grupoPresentacionID ? true : false;
    const [cicloId] = useGlobal("ciclo")
    const [, setCargando] = useGlobal("cargando")

    const [nombre, setNombre] = useState("");
    const [clientes, setClientes] = useState([]);
    const [cliente, setCliente] = useState();
    const [productos] = useGlobal("productosBases")
    const [gruposPresentaciones, setGruposPresentaciones] = useGlobal("gruposPresentaciones")
    const [producto, setProducto] = useState({});
    const [time, setTime] = useState(0);
    const [presentaciones, setPresentaciones] = useState([])
    const [presentacionesFiltrados, setPresentacionesFiltrados] = useState([])
    const [presentacionesPorAsociar, setPresentacionesPorAsociar] = useState([])
    const [grupoPresentaciones, setGrupoPresentaciones] = useState()
    const [errores, setErrores] = useState({});

    useEffect(() => {
        if (cicloId) obtenerDatosIniciales();
    }, [cicloId])
    useEffect(() => {
        setErrores({});
    }, [nombre, cliente, producto, presentacionesPorAsociar])

    useEffect(() => {
        setCargando(true)
        let presentacionesFil = [];
        if (grupoPresentaciones && time <= 2 && cliente) {
            if (cliente?.id && producto.id) {
                presentaciones.forEach(pres => {
                    if (pres.cliente_ref === cliente?.id && (pres.producto_ref === producto.id || (pres.mezcla && producto.MEDLEYS_GENERAL))) { presentacionesFil.push(pres); }
                })
            }
            else if (cliente?.id) {
                presentaciones.forEach(pres => {
                    if (pres.cliente_ref === cliente?.id) { presentacionesFil.push(pres); }
                })
            }
            else if (producto.id) {
                presentaciones.forEach(pres => {
                    if (pres.producto_ref === producto.id || (pres.mezcla && producto.MEDLEYS_GENERAL)) { presentacionesFil.push(pres); }
                })
            }
            setTime(time + 1)
        }
        else {
            setTime(time + 1)
            if (cliente?.id && producto.id) {
                presentaciones.forEach(pres => {
                    if (pres.cliente_ref === cliente?.id && (pres.producto_ref === producto.id || (pres.mezcla && producto.MEDLEYS_GENERAL))) { presentacionesFil.push(pres); }
                })
                //  setPresentacionesPorAsociar([]);
            }
            else if (cliente?.id) {
                presentaciones.forEach(pres => {
                    if (pres.cliente_ref === cliente?.id) { presentacionesFil.push(pres); }
                })
                //  setPresentacionesPorAsociar([]);
            }
            else if (producto.id) {
                presentaciones.forEach(pres => {
                    if (pres.producto_ref === producto.id || (pres.mezcla && producto.MEDLEYS_GENERAL)) { presentacionesFil.push(pres); }
                })
            }
        }

        const presentacionesSeleccionadas = [];
        const presentacionesDisponibles = presentacionesFil?.filter(presFiltrado => {
            const esEnOtroGrupo = gruposPresentaciones?.some(grupo => grupo.presentaciones_refs?.includes(presFiltrado.id));
            const estaEnGrupoActual = presentacionesPorAsociar?.some(presAsociado => presAsociado.id === presFiltrado.id);
            if (estaEnGrupoActual) { presentacionesSeleccionadas.push(presFiltrado); }
            //console.log("PRES: ", {presFiltrado, esEnOtroGrupo, estaEnGrupoActual})
            return !esEnOtroGrupo || estaEnGrupoActual;
        })
        setPresentacionesFiltrados(presentacionesDisponibles);
        setPresentacionesPorAsociar(presentacionesSeleccionadas);
        setCargando(false);
    }, [cliente, producto])


    const obtenerDatosIniciales = async () => {
        setCargando(true)
        if (editando) {
            const grupoPres = await obtenerGruposPresentacion(grupoPresentacionID)
            setGrupoPresentaciones(grupoPres)
            obtenerComboBoxDatosIniciales(grupoPres)
        } else obtenerComboBoxDatosIniciales({})
    }

    const obtenerComboBoxDatosIniciales = async (grupoPres) => {
        const [presentacionesResp, clientesResp, gruposResp] = await Promise.all([getPresentaciones(), obtenerClientes(), obtenerGruposPresentaciones()])
        const presentaciones = presentacionesResp.sort((a, b) => a.presentacion > b.presentacion ? 1 : -1);

        setGruposPresentaciones(gruposResp)
        setPresentacionesFiltrados(presentaciones);
        setClientes(clientesResp);
        setPresentaciones(presentaciones);

        if (editando) {
            setNombre(grupoPres.nombre)
            const presentacionesPorAsociar = presentaciones.filter(el => grupoPres.presentaciones_refs.includes(el.id))
            setPresentacionesPorAsociar(presentacionesPorAsociar);
            setProducto(grupoPres.producto_ref ? productos.find(prod => prod.id === grupoPres.producto_ref) : {})
            setCliente(grupoPres.cliente_ref ? clientesResp.find(cli => cli.id === grupoPres.cliente_ref) : {})
        }
        setCargando(false)
    }


    const guardar = async () => {
        if (validarErrores()) {
            let idsPresentaciones = presentacionesPorAsociar.map(el => el.id);
            if (editando) await actualizarGrupoPresentaciones(grupoPresentaciones.id, nombre, idsPresentaciones, cliente?.id, producto.id, producto.MEDLEYS_GENERAL);
            else await registrarGrupoPresentaciones(nombre, idsPresentaciones, cliente?.id, producto.id, producto.MEDLEYS_GENERAL);
            props.history.goBack();
        }
    }
    const validarErrores = () => {
        let errores = {};
        if (!nombre) errores.nombre = true;
        if (!cliente?.id) errores.cliente = true;
        if (!producto.id) errores.producto = true;
        if (presentacionesPorAsociar.length === 0) errores.presentacionesPorAsociar = true;

        setErrores(errores)
        return Object.keys(errores).length === 0
    }
    const puedeEditar = () => true;

    return (
        <ContenedorPrincipal>
            <ContenedorHeader titulo={editando ? "Modificar grupo presentaciones" : "Crear grupo presentaciones"} iconos="presentacion-icon" atras={props.history.goBack} />

            <SeccionFormulario titulo="General">
                <ContenedorInput label="Nombre">
                    <InputText value={nombre} onChange={(e) => setNombre(e.target.value)} />
                    {errores.nombre && <Message severity="error" text="Este campo es requerido" />}
                </ContenedorInput>
                <ContenedorInput label="Clientes">
                    <Dropdown id="cliente" placeholder="Seleccionar" dataKey="id" optionLabel="nombre"
                        options={clientes} value={cliente} onChange={(e) => { setCliente(e.target.value) }} />
                    {errores.cliente && <Message severity="error" text="Este campo es requerido" />}
                </ContenedorInput>
                <ContenedorInput label="Productos">
                    <Dropdown id="producto" placeholder="Seleccionar" dataKey="id" optionLabel="nombre"
                        options={productos} value={producto} onChange={(e) => { setProducto(e.target.value) }} />
                    {errores.producto && <Message severity="error" text="Este campo es requerido" />}
                </ContenedorInput>
                <ContenedorInput label="Presentaciones asociadas">
                    <MultiSelect value={presentacionesPorAsociar} options={presentacionesFiltrados} optionLabel="presentacion" dataKey="id" filter={true}
                        onChange={e => setPresentacionesPorAsociar(e.value)} selectedItemsLabel={`${presentacionesPorAsociar.length} presentaciones seleccionadas`} />
                    {errores.presentacionesPorAsociar && <Message severity="error" text="Este campo es requerido" />}
                </ContenedorInput>
            </SeccionFormulario>
            <SeccionBotones>

                <Button label="Cancelar" className="p-button-secondary" onClick={props.history.goBack} />
                {puedeEditar() && <Button label={"Guardar"} onClick={guardar} />}
            </SeccionBotones>
        </ContenedorPrincipal>
    )
}
export default FormularioGrupoPresentaciones;//225