import React, { useState } from 'reactn';
//import {Chart} from "primereact/chart";
import { MultiSelect } from 'primereact/multiselect';
import Chart from "react-apexcharts";
import SeccionFiltros from './SeccionFiltros';
import ContenedorFiltro from './ContenedorFiltro';
const StackedChart = (props) => {
    const [valoresFiltro, setValoresFiltro] = useState([]);
    const handleFilterChange = (e) => {
        setValoresFiltro(e.value);
    }
    const obtenerStackedOptions = (params) => {
        let tooltips = {
            mode: 'index',
            intersect: false
        }
        if (props.tooltipFunction) {
            tooltips = {
                callbacks: {
                    label: props.tooltipFunction
                }
            }
        }
        const stackedOptions = {
            tooltips: tooltips,
            responsive: true,
            scales: {
                xAxes: [{
                    stacked: true,
                }],
                yAxes: [{
                    stacked: true
                }]
            }
        };
        return stackedOptions
    }

    const obtenerData = () => {
        
        let copia = { ...props.data }
        if (valoresFiltro.length > 0) {
            
            
            let idsFiltros = valoresFiltro.map(el => el.id);
            
            let datasets = props.data.datasets.filter(el => {
                return idsFiltros.includes(el.datasetId)
            })
            


            copia.datasets = datasets;
            
            return copia.datasets;
        } else {
            
            
            return copia.datasets || [];
        }
    }
    
    
    return <React.Fragment>
        <div className="p-col-12">
            {props.filtrar ?
                <SeccionFiltros>
                    <ContenedorFiltro col="2" label="Actividades a graficar" filtrolabel="no">

                        <MultiSelect options={props.filtrosOrig} value={valoresFiltro} onChange={handleFilterChange} optionLabel="nombre" dataKey="id" 
                            selectedItemsLabel={`${valoresFiltro.length} invernaderos seleccionados`} />
                    </ContenedorFiltro>
                </SeccionFiltros> : null}
        </div>
        <div className={`p-col-12 ${props.filtrar ? "chart-container-filters" : "chart-container-filters"}`}>

            {props.options && props.options.xaxis.categories.length > 0 ? <Chart type="bar" height="100%" series={obtenerData()} options={props.options} /> : null}
        </div>
    </React.Fragment>
}
export default StackedChart;