import React from 'reactn';
import FormularioItem from '../FormularioItem';
import { Dialog } from 'primereact/dialog';

const ModalFormularioItem = (props) => {
    return (
        <Dialog header={props.header} visible={props.visible} modal={true} onHide={props.onHide}>
            <FormularioItem visible={props.visible} agregarItem={props.agregarItem} agItem={props.agItem} cancelar={props.cancelar} setItemsActualizados={props.setItemsActualizados} />
        </Dialog>
    )
}
export default ModalFormularioItem;