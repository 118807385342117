import { dateToUnixTime } from "../util/functions";
import { crearInvernaderoProveedorProducto, obtenerInvernaderoProveedorProducto } from "./Invernaderos";
import { registrarProveedor, guardarProveedor } from "./Queries/Proveedores";

export const crearProveedor = async (cicloId, state, usuarioAsignado, fechaInicio, fechaCierre, producto, variedad, budget) => {
 
    try {
        let invernadero = await obtenerInvernaderoProveedorProducto(cicloId, producto.id);
        if(invernadero.empty){            
            invernadero = await crearInvernaderoProveedorProducto(cicloId, producto.id, producto.nombre);
        }

        let proveedor = adaptarProveedor(state, usuarioAsignado, fechaInicio, fechaCierre, producto, variedad, invernadero, budget);        
        let result = registrarProveedor(cicloId, {...proveedor, habilitado: true});
        return result        
    } catch (error) {
        throw error
    }

}
export const actualizarProveedor = async (cicloId,proveedorId, state, usuarioAsignado, fechaInicio, fechaCierre, budget) => {
    
       try {           
           let proveedor = adaptarProveedorActualizacion(state, usuarioAsignado, fechaInicio, fechaCierre, budget);                
           let result = guardarProveedor(cicloId, proveedorId, proveedor);
           return result        
       } catch (error) {
           throw error
       }
   
   }
const adaptarProveedor = (state, usuarioAsignado, fechaInicio, fechaCierre, producto, variedad, invernadero, budget) => {   
    let proveedor = {...state}
    proveedor["usuario_ref"] = usuarioAsignado.id;
    proveedor["fechaInicio"] = fechaInicio.time;
    proveedor["fechaCierre"] = fechaCierre.time;
    proveedor["producto_ref"] = producto.id;
    proveedor["variedad_ref"] = variedad.id;
    proveedor["invernadero_ref"] = invernadero.id;
    proveedor["budget"] = budget;
    return proveedor;
}
const adaptarProveedorActualizacion = (state, usuarioAsignado, fechaInicio, fechaCierre, budget) => {    
    
    
    let proveedor = {...state}
    proveedor["usuario_ref"] = usuarioAsignado.id;
    proveedor["fechaInicio"] = fechaInicio.time;
    proveedor["fechaCierre"] = fechaCierre.time;    
    proveedor["budget"] = budget;
    return proveedor;
}//59