import React, { useEffect } from 'react';
import { useState } from 'react';
import { useGlobal } from 'reactn';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from "primereact/dialog";
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar'
import { guardarDatosRequisicion, ordenCerrado} from '../../../service/Requisiciones';
import { notificarOrdenCerrada, notificarEstatusRequisicion } from '../../../service/notificaciones';
import { monedas } from '../../../constants'
import {dateToUnixTimeAsNumber13, getCalendarDate, getNombrePorID} from '../../../util/functions';

import 'moment/locale/es';
import InputTimeSelector from 'components/common/InputTimeSelector';

const CambiarEstatusModal = (props) => {
    const [saving, setSaving] = useState(false)
    const [cargando, setCargando] = useGlobal("cargando")
    const [ciclo,] = useGlobal("ciclo");
    const [nuevoEstatus, setNuevoEstatus] = useState(props.estatus)
    const [modalState, setModalState] = useState(props.state)

    useEffect(() => {
        //setSaving(false)
    }, [nuevoEstatus])
    useEffect(() => {
        setModalState(props.state)
        setNuevoEstatus(props.estatus)
    }, [props.state, props.estatus])
    const handleInputChange = (event, ) => {
        let modalStateCopy = Object.assign({}, modalState)
        modalStateCopy[event.target.name] = event.target.value
        setModalState(modalStateCopy)
    }
    const handleCalendarInputChange = (event, itemEdit = false) => {
        handleInputChange({ target: { name: event.target.name, value: dateToUnixTimeAsNumber13(event.target.value) } }, itemEdit)
    }

    const getEstatusesDisponibles = () => {
        let currEstatus = props.estatuses.find(el => el.id === props.estatus)
        let estatusesDisponibles = []
        let noautorizadoEstatus = props.estatuses.find(el => el.nombre === "No autorizado")

        props.estatuses.forEach(el => {
            if (currEstatus && (( el.orden >= currEstatus.orden && currEstatus.orden >= noautorizadoEstatus.orden)
                    || (currEstatus.orden < noautorizadoEstatus.orden)))
                estatusesDisponibles.push(el)
        })

        return estatusesDisponibles
    }

    const guardarEstatus = async () => {
        setCargando(true)
        props.closeModal()
        let data = { estatus: nuevoEstatus }

        if (ordenCerrado(props.estatuses, nuevoEstatus)) {
            data.tiempoRealizacion = modalState.tiempoRealizacion || ""
            data.comentarioCerrado = modalState.comentarioCerrado
            data.fechaCerrado = Date.now()
        }

        //await guardarDatosRequisicion(ciclo, "requisiciones_mantenimiento", props.requisicionID, data)
        try {
            if (!ordenCerrado(props.estatuses, nuevoEstatus)){
                console.log("asd")
                await notificarEstatusRequisicion(props.state.solicitante, props.state.orden_num, getNombrePorID(props.estatuses, nuevoEstatus), "mantenimiento");
            }else {
                console.log("2")
                console.log({
                    solicitante: props.state.solicitante, orden_num: props.state.orden_num, comentarioCerrado: modalState.comentarioCerrado || "", delfinal: "mantenimiento"
                })
                await notificarOrdenCerrada(props.state.solicitante, props.state.orden_num, modalState.comentarioCerrado || "", "mantenimiento");
            }
        } catch (error) {
            console.log("ERROR: ", error)
        }
        
        props.setFormularioState(data)
        setCargando(false)
    }

    const footer = (params) => {
        return <div>
            <Button label="Cancelar" onClick={() => props.closeModal()} />
            <Button label="Guardar" disabled={nuevoEstatus === props.state.estatus} onClick={() => guardarEstatus()} />
        </div>
    }

    const showErrorMessage = (field) => saving && !field

    return <Dialog header={"Administración de la orden"} visible={props.visible} style={{ width: '50vw' }} onHide={() => props.closeModal()} footer={footer()}>
        <div className="p-grid ">
            <div className="form-group p-col-12">
                <label htmlFor="status-nuevo">Estatus nuevo </label>
                <Dropdown id="status-nuevo" optionLabel="nombre" optionValue="id" options={getEstatusesDisponibles()} value={nuevoEstatus} onChange={e => setNuevoEstatus(e.target.value)} />
            </div>


            {ordenCerrado(props.estatuses, nuevoEstatus) && <>
                <InputTimeSelector 
                    col='6' 
                    label='Tiempo de realización' 
                    name="tiempoRealizacion"
                    valueMode='hours:minutes' 
                    value={props.tiempoRealizacion || modalState.tiempoRealizacion} 
                    onChange={handleInputChange}
                />
                <div className="form-group p-col-12 p-md-6">
                    <label htmlFor="comentarioCerrado">Comentario para enviar </label>
                    <InputTextarea name="comentarioCerrado" rows={3} cols={30} id="comentarioCerrado" value={props.comentarioCerrado} onChange={handleInputChange} />
                    {showErrorMessage(modalState.comentarioCerrado) && <Message severity="error" text="Este campo es requerido" />}
                </div>
            </>}

        </div>
    </Dialog >
}

export default CambiarEstatusModal; //111