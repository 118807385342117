import React from 'react';
import { useState, useEffect, useGlobal, setGlobal } from 'reactn';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from "primereact/inputtext";
import { Calendar } from 'primereact/calendar';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { noHaSidoClausurado } from '../../../service/Ciclos';
import ContenedorFiltro from '../../common/ContenedorFiltro'
import ContenedorFiltroBusqueda from '../../common/ContenedorFiltroBusqueda'
import SeccionFiltros from '../../common/SeccionFiltros'
import { dateToUnixTimeAsNumber13, getNombrePorID, getMomentFormatted, getNombreUsuarioPorID, getObjetoPorID } from '../../../util/functions';
import { miliSegundosDia, numeroMaximo } from '../../../constants'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { obtenerFiltrosSistemasTodos, obtenerOrdenesSistemaAciclico, getListaEmpresasString, isAdminRequisiciones, getListaEmpresasIDs, isAdminModulo, filtrarRequisicionesDeAuxiliarPorAdmins, obtenerOrdenesSistemasBridge, getListaSitiosIDs, getListaSitiosString } from '../../../service/Requisiciones';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from "moment";
import 'moment/locale/es';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import ModalDetalles from './ModalDetalles';
import ContenedorTabla from '../../common/ContenedorTabla';
import ContenedorPrincipal from '../../common/ContenedorPrincipal';
import ContenedorBotonesTop from '../../common/ContenedorBotonesTop';
import RowBotonesTop from '../../common/RowBotonesTop';
import ContenedorHeader from '../../common/ContenedorHeader';
import { ROLES_ADMIN_Y_AUXILIAR } from '../../../util/diccionarios';
moment.locale("es");

setGlobal({
    urgenciasFiltrosSistemas: [], estatusesFiltrosSistemas: [], urgenciasFiltrosSistemas: [], estatusesFiltrosSistemas: [],
    departamentosFiltrosSistemas: [], empresasFiltrosSistemas: [], datosCargadosSistemas: false, sitiosFiltrosSistemas: [],
})
const TablaSistemas = (props) => {
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [, setCargando] = useGlobal("cargando")
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [ciclo] = useGlobal("ciclo");
    const [cicloObj] = useGlobal("cicloObj");
    const [ciclos] = useGlobal("ciclos");
    const [usuario] = useGlobal("usuario");
    const [usuarios] = useGlobal("usuarios")
    const [ordenLineas, setOrdenLineas] = useState([])
    const [ordenLineasOriginales, setOrdenLineasOriginales] = useState([])
    const [datosCargados] = useGlobal("datosCargadosSistemas")

    //opciones de filtros
    const [ordenesRefsOpciones] = useGlobal("ordenesRefsSistemas")
    const [urgenciasOpciones] = useGlobal("urgenciasSistemas")
    const [requerimientosOpciones] = useGlobal("requerimientosSistemas")
    const [estatusesOpciones] = useGlobal("estatusesSistemas")
    const [empresasOpciones] = useGlobal("empresas")
    const [departamentosOpciones] = useGlobal("departamentos")
    const [sitios] = useGlobal("sitios")

    //variables de filtros
    const [globalFilter, setGlobalFilter] = useGlobal("globalFilterSistemas")
    const [fechaFiltroInicio, setFechaFiltroInicio] = useGlobal("fechaFiltroInicioSistemas")
    const [fechaFiltroFinal, setFechaFiltroFinal] = useGlobal("fechaFiltroFinalSistemas")
    const [urgenciasFiltros, setUrgenciasFiltros] = useGlobal("urgenciasFiltrosSistemas")
    const [estatusesFiltros, setEstatusesFiltros] = useGlobal("estatusesFiltrosSistemas")
    const [departamentosFiltros, setDepartamentosFiltros] = useGlobal("departamentosFiltrosSistemas")
    const [empresasFiltros, setEmpresasFiltros] = useGlobal("empresasFiltrosSistemas")
    const [sitiosFiltros, setSitiosFiltros] = useGlobal("sitiosFiltrosSistemas")

    const [showDetallesModal, setShowDetallesModal] = useState(false)

    useEffect(() => {
        if (usuario.rol === "agente aduanal")
            props.history.push("/")
        setNombreModulo("Requisiciones de sistema")
        setHabilitadoSelectorCiclo(true)
    }, [])
    useEffect(() => {
        setCargando(true)
        if (ciclo && !datosCargados)
            obtenerFiltrosSistemasTodos(usuario)
        else if (ciclo && datosCargados)
            obtenerDatosInicio()

    }, [ciclo, datosCargados])

    useEffect(() => {
        //Reconsile Filters
        let ordenLineasFiltrados = ordenLineasOriginales.filter(orden => checarCondicionesDeIncluirOrden(orden))
        setOrdenLineas(ordenLineasFiltrados)

    }, [ordenLineasOriginales, fechaFiltroInicio, fechaFiltroFinal, urgenciasFiltros, estatusesFiltros, empresasFiltros, departamentosFiltros, sitiosFiltros]);

    const checarCondicionesDeIncluirOrden = (orden) => {
        let selectedFechaInicio = !fechaFiltroInicio ? 1 : dateToUnixTimeAsNumber13(fechaFiltroInicio)
        let selectedFechaFinal = !fechaFiltroFinal ? numeroMaximo : (dateToUnixTimeAsNumber13(fechaFiltroFinal) + miliSegundosDia)

        return orden.fecha_creacion >= selectedFechaInicio && orden.fecha_creacion <= selectedFechaFinal
            && orden.empresaLista.some(empresa => empresasFiltros.includes(empresa))
            && urgenciasFiltros.includes(orden.allData.urgencia) && estatusesFiltros.includes(orden.allData.estatus)
            && departamentosFiltros.includes(orden.allData.departamento)
            && orden.sitioLista.some(sitio => {
                const tieneSitio = sitiosFiltros.includes(sitio);
                const noHaySitio = (!sitio && sitiosFiltros.includes("Sin asignar"))
                const esAgroSin = (!sitio && (orden.empresaLista.includes("NdGaet757tbBgU5o0YpE") || orden.empresaLista.includes("ghFyLI8MQcidygXbTpuG")) && sitiosFiltros.includes("SCkIi2SnCvdAw3Xm4PLi"))
                return tieneSitio || noHaySitio || esAgroSin;
            })
    }

    const obtenerDatosInicio = async () => {

        try {
            let ordenes = await obtenerOrdenesSistemasBridge(cicloObj, usuario, isAdminModulo())
            ordenes = filtrarRequisicionesDeAuxiliarPorAdmins(ordenes);

            let lineasSistemas = ordenes.map(orden => ({
                orden_num: orden.orden_num,
                solicitante: usuarios ? getNombreUsuarioPorID(usuarios, orden.solicitante) : usuario.displayName,
                fecha_creacion: orden.fecha_creacion,
                ordenRef: getNombrePorID(ordenesRefsOpciones, orden.orden_ref),

                empresas: getListaEmpresasString(orden.items, empresasOpciones),
                sitios: getListaSitiosString(orden.items, sitios),

                estatus: getNombrePorID(estatusesOpciones, orden.estatus),
                requerimiento: getNombrePorID(requerimientosOpciones, orden.requerimiento),
                empresaLista: getListaEmpresasIDs(orden),
                sitioLista: getListaSitiosIDs(orden),

                allData: { ...orden },
            }))

            setOrdenLineasOriginales(lineasSistemas)
        } catch (error) {
            console.log(error)
        }
        setCargando(false)
    }
    const verDetalles = (event, rowData) => {
        event.stopPropagation()
        setShowDetallesModal(rowData.allData)
    }

    const buttonBody = (rowData) => <Button label="Ver items" onClick={(ev) => { verDetalles(ev, rowData) }} />;
    const irOrdenSistema = (event) => {
        props.history.push({ pathname: `/requisiciones/sistemas/modificar/${event.data.allData.id}`, data: event.data.allData })
    }
    const fechaFormattedBody = (rowData) => <label >{getMomentFormatted(rowData.fecha_creacion, "DD/MM/YYYY")}</label>
    const obtenerFooter = () =>
        <ColumnGroup>
            <Row>
                <Column className="text-align-right" footer="Total:" colSpan={3} />
                <Column className="text-align-left" footer={<span>{ordenLineas?.length || "0"}<span className="label-unidad">requisiciones</span> </span>} colSpan={1} />)
                <Column footer={<span></span>} colSpan={2} />
            </Row>
        </ColumnGroup>;

    const getSitiosFiltrosOpciones = () => {
        let sinAsignar = { nombre: "Sin asignar", id: "Sin asignar" }
        if (!sitios) { return [sinAsignar]; }
        let sitiosCopy = [...sitios]
        sitiosCopy[0].nombre = "Todos (Atongo)";
        return [sinAsignar, ...sitiosCopy]
    }

    return (
        <ContenedorPrincipal>
            <ContenedorHeader col="p-col p-col-auto" titulo="Requisiciones de sistema" iconos="it-icon" />

            <ContenedorBotonesTop>
                <RowBotonesTop>
                    {noHaSidoClausurado(ciclo, ciclos) && <Button label="Nueva requisición de sistema" onClick={() => props.history.push('/requisiciones/sistemas/crear')} />}
                    {isAdminRequisiciones(usuario.rol, "Sistemas") && <Button className="reactbtn-icon" onClick={() => props.history.push('/requisiciones/grafica_sistemas')} label="Gráfica de sistemas">
                        <FontAwesomeIcon icon={faChartBar} />
                    </Button>}
                </RowBotonesTop>
            </ContenedorBotonesTop>

            <SeccionFiltros >
                <ContenedorFiltroBusqueda>
                    <FontAwesomeIcon icon={faSearch} />
                    <InputText type="search" value={globalFilter} onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar" />
                </ContenedorFiltroBusqueda>

                <ContenedorFiltro col="2" label="Fecha Inicio">
                    <Calendar id="fechaInicio" readOnlyInput={true} dateFormat="dd-mm-yy" //locale={ CALENDAR_LOCALE_ES_PRIME_TRADUCTION }
                        value={fechaFiltroInicio} monthNavigator={true} yearNavigator={true} yearRange="1900:2030"
                        showButtonBar={true} onClearButtonClick={() => { setFechaFiltroInicio("") }}
                        onChange={e => setFechaFiltroInicio(e.value)} />
                </ContenedorFiltro>
                <ContenedorFiltro col="2" label="Fecha Final">
                    <Calendar id="fechaFinal" readOnlyInput={true} dateFormat="dd-mm-yy" //locale={ CALENDAR_LOCALE_ES_PRIME_TRADUCTION }
                        value={fechaFiltroFinal} monthNavigator={true} yearNavigator={true} yearRange="1900:2030"
                        showButtonBar={true} onClearButtonClick={() => { setFechaFiltroFinal("") }}
                        onChange={e => setFechaFiltroFinal(e.value)} />
                </ContenedorFiltro>

                <ContenedorFiltro col="2" label="Estatus">
                    <MultiSelect id="estatusesFiltros" optionLabel="nombre" optionValue="id"
                        filter={false} selectedItemsLabel={`${estatusesFiltros.length} estatuses seleccionados`}
                        options={estatusesOpciones} value={estatusesFiltros}
                        onChange={(e) => setEstatusesFiltros(e.value)} />
                </ContenedorFiltro>
                <ContenedorFiltro col="2" label="Urgencia">
                    <MultiSelect id="urgenciasFiltros" optionLabel="nombre" optionValue="id"
                        filter={false} selectedItemsLabel={`${urgenciasFiltros.length} urgencias seleccionadas`}
                        options={urgenciasOpciones} value={urgenciasFiltros}
                        onChange={(e) => setUrgenciasFiltros(e.value)} />
                </ContenedorFiltro>
                <ContenedorFiltro col="2" label="Empresa">
                    <MultiSelect id="empresasFiltros" optionLabel="nombre" optionValue="id"
                        filter={false} selectedItemsLabel={`${empresasFiltros.length} empresas seleccionadas`}
                        options={empresasOpciones} value={empresasFiltros}
                        onChange={(e) => setEmpresasFiltros(e.value)} />
                </ContenedorFiltro>
                <ContenedorFiltro col="2" label="Departementos">
                    <MultiSelect id="departementos" optionLabel="nombre" optionValue="id"
                        filter={false} selectedItemsLabel={`${departamentosFiltros.length} departamentos seleccionados`}
                        options={departamentosOpciones} value={departamentosFiltros}
                        onChange={(e) => setDepartamentosFiltros(e.value)} />
                </ContenedorFiltro>

                <ContenedorFiltro col="2" label="Sitio">
                        <MultiSelect id="sitio" optionLabel="nombre" optionValue="id"
                            filter={false} selectedItemsLabel={`${sitiosFiltros.length} sitios seleccionados`}
                            options={getSitiosFiltrosOpciones()} value={sitiosFiltros}
                            onChange={(e) => setSitiosFiltros(e.value)} />
                    </ContenedorFiltro>
            </SeccionFiltros>

            <ContenedorTabla>
                <DataTable selectionMode="single" value={ordenLineas} className="" onRowClick={irOrdenSistema} footerColumnGroup={obtenerFooter()}
                    globalFilter={globalFilter} emptyMessage="No hay resultados" sortable={true} className="table-footer-no-border__total">
                    <Column field="fecha_creacion" header="Fecha creación" sortable={true} body={fechaFormattedBody} />
                    <Column field="orden_num" header="REQUISICIÓN #" sortable={true} />
                    <Column field="ordenRef" header="Orden de Referencia" sortable={true} />
                    <Column field="solicitante" header="Nombre Solicitante" sortable={true} />
                    <Column field="empresas" header="Empresa(s)" sortable={true} />
                    <Column field="estatus" header="Estatus" sortable={true} />
                    <Column field="mas" header="Detalles" sortable={true} body={buttonBody}  />
                </DataTable>
            </ContenedorTabla>

            <ModalDetalles hide={() => setShowDetallesModal(false)} detalles={showDetallesModal} visible={showDetallesModal}></ModalDetalles>
        </ContenedorPrincipal>
    );
}
export default TablaSistemas//216