import React, {  useEffect } from 'reactn';
import { useGlobal } from "reactn";
import {  obtenerSemanasTranscurridasBuddget } from "../../service/Ciclos";
import { obtenerInvernaderoFisicoRelacionado, obtenerInvernaderosVirtualesInternos } from "../../service/Invernaderos";
import InvernaderoCard from "./InvernaderoCard";
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { obtenerPesoProyectado } from "../../service/Producto";
import { obtenerTotalBudget } from '../../service/Budget'

function Invernaderos(props) {
  const [invernadero, setInvernadero] = useGlobal("invernadero");
  const irInvernadero = (invernadero) => {
    props.history.push("/invernadero_virtual/crear");
  }
  const crearInvernadero = () => {
    setInvernadero(null);
    props.history.push("/invernadero_virtual/crear");
  }
  const [invernaderos, setInvernaderos] = useGlobal("invernaderos");

  const [ciclos, setCiclos] = useGlobal("ciclos");
  const [ciclo, setCiclo] = useGlobal("ciclo");
  const [nombreModulo, setNombreModulo] = useGlobal("nombreModulo");
  const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector();
  const [weekGlobal, setWeekGlobal] = useGlobal("weekGlobal");
  var [usuario,] = useGlobal("usuario");
  const [, setCargando] = useGlobal("cargando");
  const [, setMostrarModalPermiso] = useGlobal("mostrarModalPermiso");

  useEffect(() => {
    setNombreModulo("Análisis Costos");
  }, [])
  useEffect(() => {
    if (ciclo) {
      updateCicloWeekSelector(ciclo);
    }
  }, [ciclo])
  useEffect(() => {
    if (week.nombre != "Cargando")
      setWeekGlobal(week);
  }, [week]);
  useEffect(() => {
    if (ciclo && week) {
      let promises = [];
      obtenerInvernaderosVirtualesInternos(ciclo).then(invernaderos => {
        for (let x = 0; x < invernaderos.length; x++) {
          let invernadero = invernaderos[x];
          promises.push(obtenerResumen(invernadero));
        }
        setCargando(true);
        Promise.all(promises).then(values => {
          let copia = invernaderos.map(el => Object.assign({}, el));
          for (let x = 0; x < values.length; x++) {
            let [invId, invFisico,
              proyectado, total,
              semanasTranscurridas, semanasTotales] = values[x];
            let inv = copia.find(el => el.id === invId);
            inv.invernaderoFisico = invFisico;
            inv.proyectado = proyectado;
            inv.total = total;
            inv.semanasTranscurridas = semanasTranscurridas;
            inv.semanasTotales = semanasTotales;

          }
          setInvernaderos(copia);
        }).catch(err => {
          if (err.code && err.code === "permission-denied") {
            console.log(err.code);
            setMostrarModalPermiso(true);
          }
        }).finally(el => {
          setCargando(false);
        });

      }).catch(err => {
        console.log(err);
      })
    }

  }, [ciclo, week])

  async function obtenerResumen(invernadero) {
    try {
      let semana = week.time;
      let respInvernadero = await obtenerInvernaderoFisicoRelacionado(invernadero.invernadero_fisico_ref);
      let invernaderoFisico = respInvernadero;

      let semanasTranscurridasPromise = obtenerSemanasTranscurridasBuddget(ciclo, invernadero.id);
      let proyectadoPromise = obtenerPesoProyectado(ciclo, invernadero.id, semana);
      let totalBudgePromiset = obtenerTotalBudget(ciclo, invernadero.id);

      const [[transcurridas, semanasTotales], proyectado, totalBudget] = await Promise.all([semanasTranscurridasPromise, proyectadoPromise, totalBudgePromiset]);
      return [invernadero.id, invernaderoFisico, proyectado, totalBudget, transcurridas, semanasTotales];
    } catch (error) {
      console.log("ERROR OR");
      console.log(error);
    }
  }

  const puedeConsultar = (params) => {
    return (usuario && ["Administrador", "Administrador Calidad", "Auxiliar Calidad", "Auxiliar Grower", "Administrador Inocuidad",
      "Administrador Empaque", "Grower"].includes(usuario.rol))
  }
  return <div className="p-grid">


    <div className="p-col-12">


    </div>
    <div className="p-col-12">
      <div className="p-grid filtros">
        <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
      </div>
    </div>
    <div className="p-col-12">
      <div className="p-grid">

        {invernaderos ? invernaderos.map(invernadero => <InvernaderoCard key={invernadero.id} invernadero={invernadero} />) : null}
      </div>
    </div>

  </div>
}

export default Invernaderos;