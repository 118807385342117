import React from 'reactn';
import { Dialog } from 'primereact/dialog';

const ResumenDeEnfermedadesModal = (props) => {
    return (
        <Dialog header="Actividades en registrados" modal={true}
            visible={props.visible} onHide={props.onHide}>
            {props.claroSeleccionado.enfermedades.length > 0 &&
                <span className="modal-subtitle">{`Semana ${props.week.numero}:`}</span>
            }
            {props.detallesEnfermedades.actuales.map((detallesEnfermedad) => {
                return (
                    <li className="enfermedades-item" key={detallesEnfermedad.id}>
                        <span className={`icon-list actual ${props.makeCssClass(detallesEnfermedad.nombre)}`}></span>
                        <span>{`${detallesEnfermedad.nombre}`}</span> {`(${detallesEnfermedad.fechaRegistro})`}
                    </li>
                )
            })}
            {props.claroSeleccionado.enfermedadesPasadas.length > 0 &&
                <span className="modal-subtitle">{`Semana ${props.semanaPasadaSeleccionada.numero}:`}</span>
            }
            {props.detallesEnfermedades.pasadas.map((detallesEnfermedad) => {
                return (
                    <li className="enfermedades-item" key={detallesEnfermedad.id}>
                        <span className={`icon-list pasada ${props.makeCssClass(detallesEnfermedad.nombre)}`}></span>
                        <span>{`${detallesEnfermedad.nombre}`}</span> {`(${detallesEnfermedad.fechaRegistro})`}
                    </li>
                )
            })}
        </Dialog>
    );
}
export default ResumenDeEnfermedadesModal;