import React, { useState, useEffect, useRef } from "react";
import { useGlobal } from "reactn";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { Button } from "primereact/button";

import ContenedorHeader from "components/common/ContenedorHeader";
import ContenedorPrincipal from "components/common/ContenedorPrincipal";
import SeccionFormulario from "components/common/SeccionFormulario";
import ContenedorInput from "components/common/ContenedorInput";
import ContenedorGenerico from "components/common/ContenedorGenerico";
import SeccionBotones from "components/common/SeccionBotones";
import DatoFormOutput from "components/common/outputs_components/DatoFormOutput";
import SeccionFormTabla from "components/common/SeccionFormTabla";

import TablaDetallesItems from "./components/TablaDetallesItems.componente";
import { getInfoFiles, guardarDatosInvoice, subirArchivosDeInvoice, validarItems } from "../../service/Rechazos";
import { obtenerPresentaciones } from "service/Presentaciones";
import formatMoneySign from "./utils/formatMoneySign";
import getCalculosRechazos from "./utils/getCalculosRechazos";
import sanitizeDataInvoice from "./utils/sanitizeDataInvoice";
import rejectionStateAnalysis from "./utils/rejectionStateAnalysis";
import getMapFilesInfoToSave from "./utils/getMapFilesInfoToSave";
import { getInvoiceQUERY } from "../../service/repositorios/Finanzas.queries";
import { guardarManifiesto, obtenerManifiestosCiclo } from "../../service/QueriesManifiestos";
import { DeepClone, formatoDinero, getObjetoPorID } from "../../util/functions";
import TablaExpanderManifiestos from "./components/TablaExpanderManifiestos.componente";
import { getFirestoreID } from "../../service/Mantenimiento";
import RowBotonesTop from "components/common/RowBotonesTop";
import ModalConfirmacion from "components/common/ModalConfirmacion";
import { valoresValidacionManifiesto } from "service/constants/Rechazos.const";

const defaultStateOutputs = { descuentoCajasTotal: "$0", dineroTotalRechazado: "$0", };
const defaultStateInputsForm = { po: "", invoice: "", precioPorLibra: "", };
const defaultStateInputsRechazos = { numeroCajasRechazadas: "", precioPorCajaRechazada: "", costoDeMateriales: "", descuentoManoDeObra: "", };
const FormValidacionInvoice = (props) => {
  const { history, location, match, } = props
  const archivosInvoicesInputRef = useRef();
  const [editando] = useState(!!match?.params?.invoiceId)
  const [invoiceId] = useState(match?.params?.invoiceId || null);

  const [ciclo] = useGlobal("ciclo");
  const [, setNombreModulo] = useGlobal("nombreModulo");
  const [, setCargando] = useGlobal("cargando");
  const [clientes] = useGlobal("clientes");
  const [productosTodos] = useGlobal("productosYVariedadesTodos");

  const [presentaciones, setPresentaciones] = useState([]);
  const [producto, setProducto] = useState({});
  const [cliente, setCliente] = useState({});
  const [tableDetallesItemsData, setTableDetallesItemsData] = useState([]);
  const [manifiestosTodos, setManifiestosTodos] = useState([]);
  const [modalDesvincularItem, setModalDesvincularItem] = useState(false);

  const [stateOutputs, setStateOutputs] = useState({ ...defaultStateOutputs });
  const [stateInputsForm, setStateInputsForm] = useState({ ...defaultStateInputsForm, });
  const [stateInputsRechazos, setStateInputsRechazos] = useState({ ...defaultStateInputsRechazos, });
  const [invoiceFiles, setInvoiceFiles] = useState([]);

  const [stateFiles, setStateFiles] = useState([])
  const [dineroTotalRechazado, setDineroTotalRechazado] = useState(0);
  const [descuentoCajasTotal, setDescuentoCajasTotal] = useState(0);
  const [erroresState, setErroresState] = useState([]);

  useEffect(() => {
    setNombreModulo("Finanzas")
  }, []);
  useEffect(() => {
    if (ciclo) { obtenerDatosIniciales(); }
  }, [ciclo]);
  useEffect(() => {
    //console.log(location.state)
    if (!location.state) {
      getInvoiceData();
      return;
    }
    const { producto, cliente, manifiestosSeleccionadosData } = location.state;
    let manifObjs = location.state?.manifiestosSeleccionadosData?.map((manif) => manif.manifiesto)
    if (manifObjs) {
      let uniqueItemsInvoice = [...new Set(manifObjs)].join(' - ')
      setStateInputsForm({...defaultStateInputsForm, invoice: uniqueItemsInvoice})
    }
    setTableDetallesItemsData(manifiestosSeleccionadosData);
    setProducto(producto);
    setCliente(cliente);
  }, []);
  //console.log({ stateOutputs, cliente, producto })
  useEffect(() => {
    const { numeroCajasRechazadas, precioPorCajaRechazada } = stateInputsRechazos;
    const newDescuentoPorCajasTotal = Number(numeroCajasRechazadas || 0) * Number(precioPorCajaRechazada || 0);
    setDescuentoCajasTotal(newDescuentoPorCajasTotal);
    const descuentoCajasTotalFormated = formatMoneySign(newDescuentoPorCajasTotal);
    setStateOutputs({ ...stateOutputs, descuentoCajasTotal: descuentoCajasTotalFormated });
  }, [stateInputsRechazos.numeroCajasRechazadas, stateInputsRechazos.precioPorCajaRechazada]);

  useEffect(() => {
    const { costoDeMateriales, descuentoManoDeObra } = stateInputsRechazos
    const newDineroTotalRechazado = descuentoCajasTotal + Number(costoDeMateriales || 0) + Number(descuentoManoDeObra || 0);
    const dineroTotalRechazadoFlormated = formatMoneySign(newDineroTotalRechazado);
    setDineroTotalRechazado(newDineroTotalRechazado);
    setStateOutputs({ ...stateOutputs, dineroTotalRechazado: dineroTotalRechazadoFlormated, });
  }, [descuentoCajasTotal, stateInputsRechazos.costoDeMateriales, stateInputsRechazos.descuentoManoDeObra]);

  const obtenerDatosIniciales = async () => {
    setCargando(true);
    if (editando && invoiceId) {
      try {
        const promisePresentaciones = presentaciones?.length > 0 ? presentaciones : obtenerPresentaciones();
        const [presentacionesResp, invoiceResp, manifiestosResp] =
          await Promise.all([promisePresentaciones, getInvoiceQUERY(ciclo, invoiceId), obtenerManifiestosCiclo(ciclo)]);

        const manifiestosObjs = invoiceResp.items?.map(item => {
          const manifiesto = getObjetoPorID(manifiestosResp, item.manifiestoId);
          const { presentacion, producto, ...itemFound } = manifiesto?.items?.find(el => el.itemID === item.itemId) || {};
          return itemFound ? { ...item, ...manifiesto, ...itemFound, presentacion: presentacion?.presentacion } : {};
        }) || []

        //console.log("obtenerDatosIniciales - EDITANDO: ", { manifiestosObjs, presentacionesResp, invoiceResp, manifiestosResp });
        console.log("obtenerDatosIniciales - EDITANDO - manifiestosObjs: ", manifiestosObjs);
        setPresentaciones(presentacionesResp);

        setCliente(getObjetoPorID(clientes, invoiceResp.cliente_ref));
        setProducto(getObjetoPorID(productosTodos, invoiceResp.producto_ref))
        setTableDetallesItemsData(manifiestosObjs);
        setManifiestosTodos(manifiestosResp);
        let manifObjs = manifiestosObjs.map((manif) => manif.manifiesto)
        let uniqueItemsInvoice = [...new Set(manifObjs)].join(' - ')
        setStateOutputs({ descuentoCajasTotal: formatMoneySign(invoiceResp.descuentoCajasTotal), dineroTotalRechazado: invoiceResp.dineroTotalRechazado });
        setStateInputsForm({ po: invoiceResp.po, invoice: uniqueItemsInvoice, precioPorLibra: invoiceResp.precioPorLibra });
        setStateInputsRechazos({
          numeroCajasRechazadas: invoiceResp.numeroCajasRechazadas, precioPorCajaRechazada: invoiceResp.precioPorCajaRechazada,
          costoDeMateriales: invoiceResp.costoDeMateriales, descuentoManoDeObra: invoiceResp.descuentoManoDeObra
        });
        setInvoiceFiles(invoiceResp?.invoiceFiles || []);
      } catch (error) { console.log("ERROR obtenerDatosIniciales - ", error) }
    } else if (!presentaciones?.length) {
      const presentacionesResp = await obtenerPresentaciones();
      setPresentaciones(presentacionesResp);
    }
    setCargando(false);
  }

  const eliminarManifiesto = () => {
  }

  const getInvoiceData = async () => { };

  const validarFormulario = () => {
    const { po, invoice, precioPorLibra } = stateInputsForm;
    const errores = [];

    if (!po || !invoice || !precioPorLibra) {
      errores.push("campos_requeridos");
    }
    const stateInputsRechazosValues = Object.values(stateInputsRechazos);

    const { allEmpty, allFull } = rejectionStateAnalysis(
      stateInputsRechazosValues
    );

    if (!allEmpty) {
      if (!allFull) {
        errores.push("campos_requeridos_rechazos");
      }
    }

    setErroresState(errores);
    return errores.length === 0;
  };

  const handleGuardar = async (e) => {
    if (!validarFormulario()) return;
    if (tableDetallesItemsData.length === 0) return
    setCargando(true);

    const rechazos = getCalculosRechazos(tableDetallesItemsData, presentaciones, { precioPorLibra: stateInputsForm.precioPorLibra, dineroTotalRechazado });
    const items = tableDetallesItemsData.map((item) => ({ itemId: item.itemID, nombreManifiesto: item.manifiesto, manifiestoId: item.manifiestoId }));

    const responseFiles = await subirArchivosDeInvoice(stateFiles)
    const responseInfoFiles = await getInfoFiles(responseFiles.response)
    const { error, response } = responseInfoFiles

    const invoiceFiles = getMapFilesInfoToSave(error ? [] : response)
    const invoiceDataToSave = sanitizeDataInvoice({
      ...stateInputsForm, ...stateInputsRechazos, cliente_ref: cliente.id, producto_ref: producto.id, infoRechazos: { ...rechazos },
      fechaCreacion: Date.now(), descuentoCajasTotal, dineroTotalRechazado, items, invoiceFiles
    });
    if (editando) delete invoiceDataToSave.fechaCreacion
    console.log("invoiceDataToSave invoiceDataToSave - ", invoiceDataToSave)

    const id = invoiceId || getFirestoreID();
    await validarItems(ciclo, tableDetallesItemsData, { precioPorLibra: stateInputsForm.precioPorLibra, precioPorLibraReal: rechazos.precioPorLibraReal })
    //console.log("INVOICE SAVE: ",{ciclo, invoiceDataToSave, invoiceId, id})

    await guardarDatosInvoice(ciclo, invoiceDataToSave, id);
    setCargando(false);
    history.push("/rechazos/tabla_invoices")
  };

  const handleCancelar = (e) => { history.goBack() };

  const handleChangeInputs = (e) => {
    const { name, value } = e.target;
    setStateInputsForm({ ...stateInputsForm, [name]: value, });
  };

  const handleChangeInputsRechazos = (e) => {
    const { name, value } = e.target;
    setStateInputsRechazos({ ...stateInputsRechazos, [name]: value, });
  };
  const handleSubirArchivos = async (e) => {
    setStateFiles(e.target.files)
  }
  const getTitulo = () => editando ? `Editar invoice ${""}` : "Formulario validación de invoice";

  const desvincularItem = async () => {
    const { manifiestoId, itemId, items } = modalDesvincularItem;
    const indexItem = items?.findIndex(el => el.itemID === itemId)

    if (manifiestoId && itemId && indexItem >= 0) {
      const itemsDetallesNuevos = borrarItemInvoice(itemId)
      console.log("GUARDAR ITEMS 1 : ", { indexItem, items, modalDesvincularItem, itemsDetallesNuevos, tableDetallesItemsData })

      const manifiestoEditado = borrarValidacionPropiedades({ items }, indexItem);
      console.log("GUARDAR ITEMS 2 : ", { manifiestoEditado, itemsDetallesNuevos, tableDetallesItemsData })

      await guardarManifiesto(ciclo, manifiestoId, manifiestoEditado);
      const itemsNuevos = itemsDetallesNuevos.map((item) => ({ itemId: item.itemID, nombreManifiesto: item.manifiesto, manifiestoId: item.manifiestoId }));
      console.log("GUARDAR ITEMS 3 : ", itemsNuevos);

      await guardarDatosInvoice(ciclo, { items: itemsNuevos }, invoiceId);
    }
  }
  const borrarItemInvoice = (itemId) => {
    const indexItem = tableDetallesItemsData.findIndex(el => el.itemId === itemId)
    const itemsCopia = DeepClone(tableDetallesItemsData);
    itemsCopia.splice(indexItem, 1);
    setTableDetallesItemsData(itemsCopia);
    return itemsCopia;
  }
  const borrarValidacionPropiedades = (manifiestoObj, indexItem) => {
    valoresValidacionManifiesto.forEach(llave => {
      delete manifiestoObj.items[indexItem][llave];
    })
    manifiestoObj.items[indexItem].status = "pendiente"
    return manifiestoObj;
  }
  return (
    <ContenedorPrincipal>
      <ContenedorHeader col="p-col p-col-auto" titulo={getTitulo()} iconos="reporte-empaque-icon" atras={history.goBack} />

      <SeccionFormulario titulo="Información general">
        <ContenedorGenerico col="6" md="6">
          <DatoFormOutput label="Cliente: " fact={cliente.nombre} />
        </ContenedorGenerico>
        <ContenedorGenerico col="6" md="6">
          <DatoFormOutput label="Producto: " fact={producto.nombre} />
        </ContenedorGenerico>
        <ContenedorGenerico col="12" md="4" sm="6">
          <DatoFormOutput label="Invoice: " fact={stateInputsForm.invoice} />
        </ContenedorGenerico>
        <ContenedorInput col="12" md="4" sm="6" label="PO">
          <InputText placeholder="Ingresa un po" name="po" value={stateInputsForm.po} onChange={handleChangeInputs} />
        </ContenedorInput>
        <ContenedorInput col="12" md="4" sm="6" label="Precio por libra">
          <InputText type="number" keyfilter="pnum" placeholder="Ingresa un precio por libra" name="precioPorLibra" value={stateInputsForm.precioPorLibra} onChange={handleChangeInputs} />
        </ContenedorInput>
        {erroresState.includes("campos_requeridos") && (<Message severity="error" text="Todos los campos son requeridos" />)}
      </SeccionFormulario>

      <SeccionFormTabla titulo="Items de manifiestos relacionados">
        {editando
          ? <TablaExpanderManifiestos manifiestosData={DeepClone(tableDetallesItemsData)} manifiestosTodos={manifiestosTodos} presentaciones={presentaciones}
            setModalDesvincularItem={setModalDesvincularItem} />
          : <TablaDetallesItems manifiestosData={DeepClone(tableDetallesItemsData)} />}

        {editando &&
          <RowBotonesTop>
            <Button label="Agregar items de manifiestos" onClick={() => props.history.push(`/rechazos/form_validacion_invoice/${invoiceId}/agregar/manifiestos`)} />
          </RowBotonesTop>}
      </SeccionFormTabla>


      <SeccionFormulario titulo="Registro de rechazo de cajas">
        <ContenedorInput col="12" md="4" sm="6" label="Número de cajas rechazadas" >
          <InputText type="number" keyfilter="pnum" placeholder="Ingresa número de cajas rechazadas" name="numeroCajasRechazadas"
            value={stateInputsRechazos.numeroCajasRechazadas} onChange={handleChangeInputsRechazos} />
        </ContenedorInput>
        <ContenedorInput col="12" md="4" sm="6" label="Precio por caja rechazada" >
          <InputText type="number" keyfilter="pnum" placeholder="Ingresa un precio por caja rechazada" name="precioPorCajaRechazada"
            value={stateInputsRechazos.precioPorCajaRechazada} onChange={handleChangeInputsRechazos} />
        </ContenedorInput>
        <ContenedorGenerico col="12" md="4" sm="6">
          <DatoFormOutput label="Descuento por cajas total:" fact={stateOutputs.descuentoCajasTotal} />
        </ContenedorGenerico>
        <ContenedorInput col="12" md="4" sm="6" label="Costo de materiales">
          <InputText name="costoDeMateriales" value={stateInputsRechazos.costoDeMateriales} onChange={handleChangeInputsRechazos} type="number"
            keyfilter="pnum" placeholder="Ingresa un costo de materiales" />
        </ContenedorInput>
        <ContenedorInput type="number" keyfilter="pnum" col="12" md="4" sm="6" label="Descuento de mano de obra" >
          <InputText type="number" keyfilter="pnum" placeholder="Ingresa un descuento de mano de obra"
            name="descuentoManoDeObra" value={stateInputsRechazos.descuentoManoDeObra} onChange={handleChangeInputsRechazos} />
        </ContenedorInput>

        <ContenedorGenerico col="12" md="4" sm="6">
          <DatoFormOutput label="Monto total rechazado:" fact={stateOutputs.dineroTotalRechazado} />
        </ContenedorGenerico>
        {erroresState.includes("campos_requeridos_rechazos") && <Message severity="error" text="Si hay rechazos todos los campos son requeridos" />}
      </SeccionFormulario>

      <SeccionFormulario titulo="Archivos de invoices" >
        <ContenedorInput col="12">
          <input ref={archivosInvoicesInputRef} type="file" multiple={true} onChange={handleSubirArchivos} />
        </ContenedorInput>
        {invoiceFiles?.length > 0 &&
          <ContenedorInput col="12" label="Archivos cargados" extra="mt-1">
            <ul className="ul-padding-inline1">
              {invoiceFiles.map(file => {
                return (<li><a target="_blank" href={file.url}>{file.name}</a>  </li>)
              })}
            </ul>
          </ContenedorInput>}
      </SeccionFormulario>

      <SeccionBotones>
        <Button className="p-button-outlined p-button-secondary" label="Cancelar" onClick={handleCancelar} />
        <Button label="Guardar" onClick={handleGuardar} />
      </SeccionBotones>

      <ModalConfirmacion data={{ acceptLabel: "Desvincular", aceptar: desvincularItem, cancelar: () => { setModalDesvincularItem(false); } }} show={modalDesvincularItem}
        header="Confirmación de acción" mensaje="¿Seguro que desea quitar estas cajas del invoice?" onHide={() => { setModalDesvincularItem(false); }} />

    </ContenedorPrincipal>);
};
export default FormValidacionInvoice;