import { getGlobal } from "reactn"
import { obtenerPorcentajeHorasTrabajadasEnCiclo, obtenerPorcentajeHorasTrabajadas, obtenerCuentaEmpleadosInvernadero, obtenerActividadesSemana, getNumeroDeEmpleadosEnInvernadero, getNumeroDeEmpleadosActivosEnInvernadero, getRotacionEmpleados, obtenerRotacionEmpleados, getPorcentajeHorasTrabajadas, getActividadesCuentaSemana } from '../../service/RhService';
import { getPackoutSemanal, obtenerkgm2semanal, getkgm2semanalTotal } from '../../service/ReportesProducto';
import { getPesoProyectado, getPesoProyectadoHome, obtenerPesoProyectado } from '../../service/Producto';
import { getTotalBudget, obtenerTotalBudget } from '../../service/Budget';
import { obtenerDefectos, obtenerDefectosDistintos, obtenerRegistrosCalidadInicialSemana } from '../../service/Calidad';
import { obtenerClarosSemana, getClarosSemana } from '../../service/biocontrol';
import { getIncidencia } from '../../service/biocontrol';
import { obtenerEmbarquesSemana, esOnHold, obtenerIdEnCruce } from '../../service/embarques';
import { obtenerSobranteSemanaPasada } from '../../service/Ordenes';
import { dosDecimales } from '../../util/functions';
import { obtenerPromediosDefectos, obtenerPromediosDefectosPeso } from '../../service/ReporteCalidad';
export const getPorcentajeHorasCiclo = (ciclo, invernaderos, semana, cuentaEmpleados, actividadesRegistradas) => {
    if (invernaderos.length == 0) return 0;
    let porcentajeHorasInvernaderos = invernaderos.map((invernadero, index) => getPorcentajeHorasTrabajadas(semana, cuentaEmpleados, ciclo, invernadero.id, actividadesRegistradas[index]));

    let total = porcentajeHorasInvernaderos.reduce((acc, curr) => acc + curr, 0)
    return total / invernaderos.length;
}
export const getPorcentajeActividadesCiclo = (invernaderos, actividadesRegistradas, actividadesImportantes) => {
    if (invernaderos.length == 0) return 0;
    let arrLineasTotales = invernaderos.map(el => obtenerLineasTotales(el));
    let porcentajeHorasInvernaderos = invernaderos.map((_, index) => getActividadesCuentaSemana(actividadesRegistradas[index], actividadesImportantes));

    let total = porcentajeHorasInvernaderos.reduce((acc, curr, index) => acc + (curr / arrLineasTotales[index]), 0)
    return Math.round(100 * total / invernaderos.length);
}
export const getNumeroDeEmpleadosEnInvernaderoCiclo = (invernaderos, allEmpeadosInvs) => {
    if (invernaderos.length == 0) return 0;
    let numeroEmpleadosInvs = invernaderos.map(invernadero => {
        let empleadosInvFiltrados = allEmpeadosInvs.filter(emp => emp.invernadero_virtual_ref === invernadero.id);
        return empleadosInvFiltrados.length;
    });

    let total = numeroEmpleadosInvs.reduce((acc, curr) => acc + curr, 0)
    return total;
}
export const getNumeroDeEmpleadosActivosEnInvernaderoCiclo = (invernaderos, allEmpleados, allEmpeadosInvs) => {
    if (invernaderos.length == 0) return 0;
    //let arrLineasTotales = invernaderos.map(el => obtenerLineasTotales(el));
    let numeroEmpleadosActivos = invernaderos.map(invernadero => {
        let empleadosInvFiltrados = allEmpeadosInvs.filter(emp => emp.invernadero_virtual_ref === invernadero.id);
        let empleadosIds = empleadosInvFiltrados.map(el => el.empleado_ref);
        let allEpleadosFiltrados = allEmpleados.filter(el => empleadosIds.includes(el.id) && el.activo);
        return allEpleadosFiltrados.length;
    });
    let total = numeroEmpleadosActivos.reduce((acc, curr) => acc + curr, 0);
    return total;
}
export const getRotacionEmpleadosCiclo = (ciclo, invernaderos, inicioSemana, allEmpeadosInvs, allRegistrosActividades, allRegistrosActividadesPasada) => {
    if (invernaderos.length == 0) return 0;
    let datosRotacionInvernaderos = invernaderos.map((inv, index) => getRotacionEmpleados(ciclo, inv.id, inicioSemana, allEmpeadosInvs, allRegistrosActividades[index], allRegistrosActividadesPasada[index]));

    let total = datosRotacionInvernaderos.reduce((acc, curr) => acc + curr, 0)
    return invernaderos.length > 0 ? (total / invernaderos.length) : 0;
}
export const obtenerLineasTotales = (invernadero) => {
    let asignacion_lineas = invernadero["asignacion_lineas"];
    let total = asignacion_lineas.reduce((acc, curr) => {
        let dato = curr["totales"].split(" ")[0]
        if (dato) {
            dato = parseInt(dato);
            if (!isNaN(dato)) {
                return acc + dato;
            } else {
                return acc;
            }
        }
    }, 0)
    let lineasTotales = total;
    return lineasTotales;
}
export const getPorcentajeBudgetCiclo = async (ciclo, invernaderos, semana) => {
    if (invernaderos.length === 0) return 0;
    let promises = invernaderos.map(invernadero => getPorcentajeBudgetInvernadero(ciclo, invernadero, semana));
    let result = await Promise.all(promises);
    let total = result.reduce((acc, curr) => acc + curr, 0)
    return total / invernaderos.length;
}
const getPorcentajeBudgetInvernadero = async (ciclo, invernadero, semana) => {
    const [proyectado, totalBudget] = await Promise.all([getPesoProyectadoHome(ciclo, invernadero, semana), getTotalBudget(invernadero)])
    return totalBudget ? proyectado / totalBudget : 0;
}

export const getPackoutPromedioCiclo = async (ciclo, invernaderos, semana) => {
    if (invernaderos.length === 0) return 0;
    let promises = invernaderos.map((invernadero, index) => getPackoutSemanalInvernadero(ciclo, invernadero, semana));
    let result = await Promise.all(promises);
    let areaTotal = 0, sumaRealTotal = 0, sumaSemanalTotal = 0;
    result.forEach(registro => {
        areaTotal += registro.area;
        sumaRealTotal += registro.suma;
        sumaSemanalTotal += registro.sumaSemanal;
    })
    //let total = result.reduce((acc, curr) => acc + curr, 0)
    return (sumaRealTotal / areaTotal) / (sumaSemanalTotal / areaTotal)
}
const getPackoutSemanalInvernadero = async (ciclo, invernadero, semana) => {
    const [sumaSemanal, { area, suma }] = await Promise.all([
        getkgm2semanalTotal(ciclo, invernadero, semana),
        getPackoutSemanal(ciclo, invernadero, semana,/* kgm2semanal*/)])
    return { area, suma, sumaSemanal } //kgm2Ordenes / kgm2semanal;
}


export const getDatosDefectosPromedioCiclo = async (ciclo, invernaderos, semana) => {
    if (invernaderos.length === 0) return 0;
    let promises = invernaderos.map(inv => getDefectosDatosIniciales(ciclo, inv, semana));
    let result = await Promise.all(promises);

    let superset = new Set();
    const defectos = result.map(el => el.defectosDistintos).flat()
    defectos.forEach(defecto => { superset.add(defecto); });
    let totalDistintos = superset.size

    let totalPorcentaje = result.reduce((acc, curr) => acc + curr.total, 0);
    return [totalPorcentaje / invernaderos.length, totalDistintos]
}
export const getDefectosDatosIniciales = async (ciclo, invernadero, semana) => {
    const registrosCalidadInicial = await obtenerRegistrosCalidadInicialSemana(ciclo, invernadero.id, semana);

    const producto = getGlobal().productosYVariedades.find(el => el.id === invernadero.producto_ref);
    const tipoCalidad = producto.tipo_calidad_inicial;
    const promediosInicial = tipoCalidad === "PESO" ? obtenerPromediosDefectosPeso(registrosCalidadInicial) : obtenerPromediosDefectos(registrosCalidadInicial);

    let defectosDistintos = new Set();
    registrosCalidadInicial.forEach(registro => {
        registro.defectos.forEach(defecto => {
            if (parseFloat(defecto.valor) > 0)
                defectosDistintos.add(defecto.defecto_ref);
        })
    })
    defectosDistintos = Array.from(defectosDistintos)

    let keys = Object.keys(promediosInicial), total = 0;
    keys.forEach(k => {
        let data = promediosInicial[k]?.cociente;
        if (!isNaN(data)) total += data;
    })
    return { total, defectosDistintos }
}

export const obtenerNumeroDefectosCiclo = async (ciclo, invernaderos, semana) => {
    if (invernaderos.length === 0) return 0;
    let promises = invernaderos.map((invernadero, index) => obtenerDefectosDistintos(ciclo, invernadero.id, semana));
    let result = await Promise.all(promises);
    let superset = new Set();

    let total = result.forEach(current => {
        let arr = Array.from(current);

        arr.forEach(defecto => {
            superset.add(defecto);
        })
    })
    return superset.size;
}

export const getPorcentajeIncidenciasCiclo = async (ciclo, semana, invernaderosFisicos) => {
    if (invernaderosFisicos.length === 0) return 0;

    let claros = await getClarosSemana(ciclo, semana);
    if (claros.isEmpty) return 0;

    let totalClaros = 0, clarosEnfermos = 0, enfermedades = new Set();
    invernaderosFisicos.forEach(inv => {
        const found = claros.find(el => !!el[inv.id])
        if (found) {
            const dataIncidencia = getIncidencia(found[inv.id], inv);
            totalClaros += dataIncidencia.totalClaros;
            clarosEnfermos += dataIncidencia.clarosEnfermos;
            dataIncidencia.enfermedades.forEach(el => { enfermedades.add(el) })
        }
    });

    return [dosDecimales((100 * clarosEnfermos) / totalClaros), enfermedades.size];
}
export const obtenerNumeroEnfermedadesCiclo = async (ciclo, semana, invernaderosFisicos) => {
    if (invernaderosFisicos.length === 0)
        return 0;
    let promises = invernaderosFisicos.map((invernadero, index) => obtenerEnfermedadesDistintasInvernadero(ciclo, invernadero.id, semana));
    let result = await Promise.all(promises);
    let superset = new Set();

    let total = result.forEach(current => {
        let arr = Array.from(current);

        arr.forEach(defecto => {
            superset.add(defecto);
        })
    })
    return superset.size;

}
export const obtenerEnfermedadesDistintasInvernadero = async (ciclo, invernaderoId, semana) => {
    let claros = await obtenerClarosSemana(ciclo, invernaderoId, semana);
    let enfermedades = new Set();
    if (claros.isEmpty) {
        return enfermedades;
    }
    claros.forEach(claro => {
        claro.enfermedades.forEach(enfermedad => {
            enfermedades.add(enfermedad["enfermedad_ref"]);
        })
    })

    return enfermedades;
}
export const obtenerSobranteCicloSemanaPasada = async (ciclo, invernaderos, semana) => {
    if (invernaderos.length === 0)
        return 0;
    let promises = invernaderos.map((invernadero, index) => obtenerSobranteSemanaPasada(ciclo, invernadero.id, semana));
    let result = await Promise.all(promises);


    return result.reduce((acc, curr) => { return isNaN(curr["sobrante"]) || curr["sobrante"] === null ? acc : acc + curr["sobrante"] }, 0)
}
//263