import React, { useEffect, useState, } from 'react';
import { useGlobal, setGlobal } from 'reactn';
import { obtenerLocaciones } from '../../service/Locaciones';
import { obtenerEmpleados, getEmpleadosInvernaderosTodos } from '../../service/RhService';
import { obtenerInicioSemanaDia } from '../../service/fechas';
import ClipLoader from 'react-spinners/ClipLoader';

import { Card } from 'primereact/card';
import {
    getPorcentajeHorasCiclo, getPorcentajeActividadesCiclo,
    getPorcentajeBudgetCiclo, getPackoutPromedioCiclo,
    getDatosDefectosPromedioCiclo, getPorcentajeIncidenciasCiclo,
    obtenerSobranteCicloSemanaPasada,
    getNumeroDeEmpleadosActivosEnInvernaderoCiclo,
    getNumeroDeEmpleadosEnInvernaderoCiclo,
    getRotacionEmpleadosCiclo
} from './GeneralService'
import { obtenerResumenCumplimiento } from '../../service/Cumplimiento';
import { obtenerSobranteSemanaPasada } from '../../service/Ordenes';
import { DeepClone, dosDecimales, getObjetoPorID } from '../../util/functions';
import { getRegistroActividadesConRealizadasSemana, getRegistroActividadesSemana } from '../../service/QueriesRH';
import { segundosSemana } from '../../constants';
import { getActividadesImportantes, obtenerActividadesImportantes } from '../../service/actividades';
import { esOnHold, obtenerEmbarquesSemana, obtenerIdEnCruce } from '../../service/embarques';
import { withRouter } from 'react-router-dom';
import firebase from 'firebase';

setGlobal({
    cardHomeRecursosHumanos: {}, cardHomeProduccion: {}, cardHomeBiocontrol: {}, cardHomeCalidad: {},
    cardHomeEmpaque: {}, cardHomeCumplimiento: {}, cardHomeEmbarques: {}
})
const General = (props) => {
    const [ciclo] = useGlobal("ciclo");
    const [cargando, setCargando] = useGlobal("cargando");
    const [cargandoGlobal] = useGlobal("cargandoDatosGlobales");
    const [cargandoHome, setCargandoHome] = useGlobal("cargandoHome");

    const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
    const [invernaderosFisicos] = useGlobal("invernaderosFisicos");

    const [cardRecursosHumanos, setCardRecursosHumanos] = useGlobal("cardHomeRecursosHumanos");
    const [cardProduccion, setCardProduccion] = useGlobal("cardHomeProduccion");
    const [cardBiocontrol, setCardBiocontrol] = useGlobal("cardHomeBiocontrol");
    const [cardCalidad, setCardCalidad] = useGlobal("cardHomeCalidad");
    const [cardEmpaque, setCardEmpaque] = useGlobal("cardHomeEmpaque");
    const [cardCumplimiento, setCardCumplimiento] = useGlobal("cardHomeCumplimiento");
    const [cardEmbarques, setCardEmbarques] = useGlobal("cardHomeEmbarques");
    const [inicioSemana, setInicioSemana] = useState({})
    const [inicioSemanaPasada, setInicioSemanaPasada] = useState()

    //////////////////////////////////////////////////////////////////
    ////////////////REMEMBER A WEEK BEHIND!!!!//////////////////////////////
    //////////////////////////////////////////////////////////////////
    useEffect(() => {
        const ahora = Math.round(Date.now() / 1000);
        const [semanaPasada, semanaAntePasada] = [ahora- segundosSemana, ahora - segundosSemana- segundosSemana];
        const inicioSem = obtenerInicioSemanaDia(semanaPasada).unix();
        const inicioSemPas = obtenerInicioSemanaDia(semanaAntePasada).unix();
        setInicioSemana(inicioSem)
        setInicioSemanaPasada(inicioSemPas)
    }, []);
    useEffect(() => {
        if (ciclo && inicioSemanaPasada) obtenerDatosIniciales();
    }, [ciclo, inicioSemanaPasada])

    const obtenerDatosIniciales = async () => {
        setCargandoHome(true)
        const aa = Date.now()
        const invernaderos = invernaderosVirtuales.filter(el => !el.es_externo).map(inv => ({ ...inv, invernaderoFisico: getObjetoPorID(invernaderosFisicos, inv.invernadero_fisico_ref) }))
        setCargando(false)


let vista = await  firebase.firestore().collection("ciclos").doc("lK3HBhcbVw9u49jlYA68").collection("vistas").doc("dashboard").get()
vista = vista.data()
setCardProduccion(vista.produccion);
setCardRecursosHumanos(vista.RH);
setCardEmpaque(vista.empaque)
setCardCalidad(vista.calidad)
setCardEmbarques(vista.embarques)
setCardCumplimiento(vista.cumplimiento)
setCardBiocontrol(vista.biocontrol)

/****************  TEMPORARY ****************/
        /*
await Promise.all([
    obtenerResumenGeneral_RH_Prod(invernaderos),
    obtenerResumenGeneralBiocontrol(ciclo,inicioSemana, invernaderosFisicos),
    obtenerResumenGeneralEmpaque(ciclo, inicioSemana, invernaderos),
    obtenerResumenGeneralCalidad(ciclo, inicioSemana, invernaderos, invernaderosFisicos),
    obtenerResumenGeneralEmbarques(ciclo, inicioSemana, invernaderos, invernaderosFisicos),
    obtenerResumenGeneralCumplimiento(ciclo, inicioSemana, invernaderos, invernaderosFisicos)
])
*/
/****************  TEMPORARY ****************/


        console.log((Date.now() - aa) / 1000)
        setCargandoHome(false)
    }
    const obtenerResumenGeneral_RH_Prod = async (invernaderos) => {
        if (Object.keys(cardRecursosHumanos).length === 0 || Object.keys(cardProduccion).length === 0) {
            // to do: filtrar invernaderos por locacion y agregar cuenta empleados
            const allRegistrosSemana = invernaderos.map(el => getRegistroActividadesConRealizadasSemana(ciclo, el.id, inicioSemana));
            const allRegistrosSemanaPasada = invernaderos.map(el => getRegistroActividadesConRealizadasSemana(ciclo, el.id, inicioSemanaPasada));

            const promises = [
                obtenerLocaciones(),
                obtenerEmpleados(),
                getEmpleadosInvernaderosTodos(),
                getActividadesImportantes(),
                Promise.all(allRegistrosSemana),
                Promise.all(allRegistrosSemanaPasada)];

            const [allLocaciones, allEmpleados, allEmpeadosInvs, actividadesImportantes, allRegistrosActividades, allRegistrosActividadesPasada] = await Promise.all(promises);

            let resultRH = {}, resultProd = {};
            allLocaciones.forEach(loc => {
                let actividadesFiltrados = [], actividadesFiltradosPasados = [];
                const invernaderosFiltrados = invernaderos.filter((el, ind) => {
                    let filt = el.invernaderoFisico?.locacion_ref === loc.id
                    if (filt) actividadesFiltrados.push(allRegistrosActividades[ind]);
                    if (filt) actividadesFiltradosPasados.push(allRegistrosActividadesPasada[ind]);
                    return filt;
                });

                resultRH[loc.id] = { nombreLocacion: loc.nombre };
                resultProd[loc.id] = { nombreLocacion: loc.nombre };

                //RH
                resultRH[loc.id].numeroEmpleados = getNumeroDeEmpleadosEnInvernaderoCiclo(invernaderosFiltrados, allEmpeadosInvs)
                resultRH[loc.id].numeroEmpleadosActivos = getNumeroDeEmpleadosActivosEnInvernaderoCiclo(invernaderosFiltrados, allEmpleados, allEmpeadosInvs)
                resultProd[loc.id].rotacion = getRotacionEmpleadosCiclo(ciclo, invernaderosFiltrados, inicioSemana, allEmpeadosInvs, actividadesFiltrados, actividadesFiltradosPasados)
                //Prod
                resultProd[loc.id].horas = getPorcentajeHorasCiclo(ciclo, invernaderosFiltrados, inicioSemana, resultRH[loc.id].numeroEmpleados, actividadesFiltrados)
                resultProd[loc.id].actividades = getPorcentajeActividadesCiclo(invernaderosFiltrados, actividadesFiltrados, actividadesImportantes)
            })
            firebase.firestore().collection("ciclos").doc("lK3HBhcbVw9u49jlYA68").collection("vistas").doc("dashboard").set({
                RH: resultRH,
                produccion: resultProd
            },{merge:true})
            setCardProduccion(resultProd);
            setCardRecursosHumanos(resultRH);
        }
    }
    const obtenerResumenGeneralBiocontrol = async (ciclo, semana, invernaderosFisicos) => {
        if (Object.keys(cardBiocontrol).length === 0) {
            //console.log("BIO: ",Object.keys(cardBiocontrol).length);
            // to do: filtrar invernaderos por locacion y agregar cuenta empleados  
            const [porcentajeIncidencias, numeroEnfermedades] = await getPorcentajeIncidenciasCiclo(ciclo, semana, invernaderosFisicos)
            firebase.firestore().collection("ciclos").doc("lK3HBhcbVw9u49jlYA68").collection("vistas").doc("dashboard").set({
                biocontrol: { porcentajeIncidencias, numeroEnfermedades }
            },{merge:true})
            setCardBiocontrol({ porcentajeIncidencias, numeroEnfermedades })
        }
    }
    const obtenerResumenGeneralEmpaque = async (ciclo, semana, invernaderos) => {
        if (Object.keys(cardEmpaque).length === 0) {
            const promisesEmpaque = [getPorcentajeBudgetCiclo(ciclo, invernaderos, semana), getPackoutPromedioCiclo(ciclo, invernaderos, semana)]
            const [porcentajeBudget, packoutPromedio] = await Promise.all(promisesEmpaque)
            const [porcentajeIncidencias, numeroEnfermedades] = await getPorcentajeIncidenciasCiclo(ciclo, semana, invernaderosFisicos)
            firebase.firestore().collection("ciclos").doc("lK3HBhcbVw9u49jlYA68").collection("vistas").doc("dashboard").set({
                empaque: { porcentajeBudget, packoutPromedio }
            },{merge:true})
            setCardEmpaque({ porcentajeBudget, packoutPromedio })
        }
    }
    const obtenerResumenGeneralCalidad = async (ciclo, semana, invernaderos) => {
        if (Object.keys(cardCalidad).length === 0) {
            const [porcentajeDefectos, numeroDefectos] = await getDatosDefectosPromedioCiclo(ciclo, invernaderos, semana);
            firebase.firestore().collection("ciclos").doc("lK3HBhcbVw9u49jlYA68").collection("vistas").doc("dashboard").set({
                calidad: { porcentajeDefectos, numeroDefectos }
            },{merge:true})
            setCardCalidad({ porcentajeDefectos, numeroDefectos })
        }
    }
    const obtenerResumenGeneralEmbarques = async (ciclo, semana) => {
        if (Object.keys(cardEmbarques).length === 0) {
            const [embarques, idEnCruce] = await Promise.all([obtenerEmbarquesSemana(ciclo, semana), obtenerIdEnCruce()]);
            const embarquesOnHold = embarques.filter(el => esOnHold(el, idEnCruce))
            firebase.firestore().collection("ciclos").doc("lK3HBhcbVw9u49jlYA68").collection("vistas").doc("dashboard").set({
                embarques: { camiones: embarques.length, camionesOnHold: embarquesOnHold.length }
            },{merge:true})
            setCardEmbarques({ camiones: embarques.length, camionesOnHold: embarquesOnHold.length })
        }
    }
    const obtenerResumenGeneralCumplimiento = async (ciclo, semana, invernaderos) => {
        if (Object.keys(cardCumplimiento).length === 0) {
            let resumen = await obtenerResumenCumplimiento(ciclo, semana);
            let finales = procesarFinal(resumen.final);
            let sobrante = await obtenerSobranteCicloSemanaPasada(ciclo, invernaderos, semana);
            firebase.firestore().collection("ciclos").doc("lK3HBhcbVw9u49jlYA68").collection("vistas").doc("dashboard").set({
                cumplimiento: { sobrante, actualizado: finales[5] }
            },{merge:true})
            setCardCumplimiento({ sobrante, actualizado: finales[5] })
        }
    }
    const procesarFinal = (finales) => {
        let result = [0, 0, 0, 0, 0, 0];
        finales.forEach((inv) => {
            inv.final.forEach((dia, index) => {
                let dato = parseFloat(dia);
                if (!isNaN(dato)) result[index] += dato;
            })
        })
        return result;
    }
    const empleadosheader = () => cardHeader("user", "Recursos humanos")
    const produccionheader = () => cardHeader("activity", "Producción")
    const bioheader = () => cardHeader("bio", "Biocontrol")
    const empaqueheader = () => cardHeader("boxes", "Empaque")
    const cumplimientoheader = () => cardHeader("cumplimiento", "Cumplimiento")
    const calidadheader = () => cardHeader("quality", "Calidad")
    const embarquesheader = () => cardHeader("truck", "Embarques")
    const cardHeader = (icon, modulo) =>
        <span className="card-header">
            <div className={`card-id icon-general ${icon}-icon bg-white  bor-gray`}></div>
            <div><p className="card-up"></p><p className="card-down">{modulo}</p></div>
        </span>;

    const irPantalla = (ev) => { if (!cargandoHome) props.history.push(ev.currentTarget.id) }
    //////////////////////////////////////////////////////////////////
    ////////////////REMEMBER A WEEK BEHIND!!!!//////////////////////////////
    //////////////////////////////////////////////////////////////////
    return cargando || cargandoGlobal ? null : (
        <div className="p-grid">
            <div id="/cards_rh" className="p-col-12 p-md-6  p-sm-6" onClick={irPantalla}>
                <Card header={empleadosheader()} className={`htg-card bor-gray-superlight`} >
                    {Object.keys(cardRecursosHumanos).length === 0
                        ? <div className="card-spinner-centered">
                            <ClipLoader sizeUnit="px" size={125} color="#003E66" loading={true} />
                        </div>
                        : <div className="p-grid p-justify-center text-center">
                            {Object.keys(cardRecursosHumanos)?.map((locacion, index) => {
                                const datos = cardRecursosHumanos[locacion];
                                return (
                                    <React.Fragment key={`empleados_${index}`} >
                                        <p className="title-card-inner">{datos.nombreLocacion}</p>
                                        <div className="p-col-4">
                                            <div className="card-block">
                                                <p className="value">{`${datos.numeroEmpleadosActivos} / ${datos.numeroEmpleados}`}</p>
                                                <p className="label">Empleados</p>
                                            </div>
                                        </div>
                                        <div className="p-col-4">
                                            <div className="card-block">
                                                <p className="value">{datos.numeroEmpleadosActivos > 0 ? Math.round(100 * datos.numeroEmpleadosActivos / datos.numeroEmpleados) : "-"}<span className="label-unidad">%</span></p>
                                                <p className="label">Empleados</p>
                                            </div>
                                        </div>
                                        <div className="p-col-4">
                                            <div className="card-block">
                                                {cardProduccion[locacion] && cardProduccion[locacion].rotacion == null
                                                    ? <ClipLoader sizeUnit="px" size={30} color="#003E66" loading={true} />
                                                    : <p className="value">{Math.round(100 * cardProduccion[locacion].rotacion)}<span className="label-unidad">%</span></p>}
                                                <p className="label">Cociente Actividad</p>
                                            </div>
                                        </div>
                                    </React.Fragment>);
                            })}
                        </div>}
                </Card>
            </div>

            <div id="/nomina" className="p-col-12 p-md-6  p-sm-6" onClick={irPantalla}>
                <Card header={produccionheader()} className={`htg-card bor-gray-superlight`} >
                    {Object.keys(cardRecursosHumanos).length === 0
                        ? <div className="card-spinner-centered">
                            <ClipLoader sizeUnit="px" size={125} color="#003E66" loading={true} />
                        </div>
                        : <div className="p-grid p-justify-center text-center">
                            {Object.keys(cardProduccion)?.map((locacion, index) => {
                                const datos = cardProduccion[locacion];
                                return (
                                    <React.Fragment key={`empleados_${index}`} >
                                        <p className="title-card-inner">{datos.nombreLocacion}</p>
                                        <div className="p-col-6">
                                            <div className="card-block">
                                                {datos.horas == null
                                                    ? <ClipLoader sizeUnit="px" size={30} color="#003E66" loading={true} />
                                                    : <p className="value">{Math.round(datos.horas)}<span className="label-unidad">%</span></p>}
                                                <p className="label">Horas trabajadas</p>
                                            </div>
                                        </div>
                                        <div className="p-col-4">
                                            <div className="card-block">
                                                {datos.actividades == null
                                                    ? <ClipLoader sizeUnit="px" size={30} color="#003E66" loading={true} />
                                                    : <p className="value">{isNaN(datos.actividades) ? "-" : datos.actividades}<span className="label-unidad">%</span></p>}
                                                <p className="label">Cierre de actividades</p>
                                            </div>
                                        </div>
                                    </React.Fragment>);
                            })}
                        </div>}
                </Card>
            </div>

            <div id="/biocontrol/cards" className="p-col-12 p-md-6  p-sm-6" onClick={irPantalla}>
                <Card key={`biocontrol`} header={bioheader()} className={`htg-card bor-gray-superlight`} >
                    {Object.keys(cardBiocontrol).length === 0
                        ? <div className="card-spinner-centered">
                            <ClipLoader sizeUnit="px" size={65} color="#003E66" loading={true} />
                        </div>
                        : <div className="p-grid p-justify-center text-center">
                            <div className="p-col">
                                <div className="card-block">
                                    {cardBiocontrol.porcentajeIncidencias == null
                                        ? <ClipLoader sizeUnit="px" size={30} color="#003E66" loading={true} />
                                        : <p className="value">{cardBiocontrol.porcentajeIncidencias}<span className="label-unidad">%</span> </p>}
                                    <p className="label">Claros con enfermedades</p>
                                </div>
                            </div>
                            <div className="p-col">
                                <div className="card-block">
                                    {cardBiocontrol.numeroEnfermedades == null
                                        ? <ClipLoader sizeUnit="px" size={30} color="#003E66" loading={true} />
                                        : <p className="value">{cardBiocontrol.numeroEnfermedades} </p>}
                                    <p className="label">Enfermedades totales</p>
                                </div>
                            </div>
                        </div>}
                </Card>
            </div>

            <div id="/calidad" className="p-col-12 p-md-6  p-sm-6" onClick={irPantalla}>
                <Card key={`produccion`} header={calidadheader()} className={`htg-card bor-gray-superlight`} >
                    {Object.keys(cardCalidad).length === 0
                        ? <div className="card-spinner-centered">
                            <ClipLoader sizeUnit="px" size={65} color="#003E66" loading={true} />
                        </div>
                        : <div className="p-grid p-justify-center text-center">
                            <div className="p-col">
                                <div className="card-block">
                                    {cardCalidad.porcentajeDefectos == null
                                        ? <ClipLoader sizeUnit="px" size={30} color="#003E66" loading={true} />
                                        : <p className="value">{isNaN(cardCalidad.porcentajeDefectos) ? "-" : Math.round(cardCalidad.porcentajeDefectos)}<span className="label-unidad">%</span></p>}
                                    <p className="label">Defectos</p>
                                </div>
                            </div>
                            <div className="p-col">
                                <div className="card-block">
                                    {cardCalidad.numeroDefectos == null
                                        ? <ClipLoader sizeUnit="px" size={30} color="#003E66" loading={true} />
                                        : <p className="value">{cardCalidad.numeroDefectos} </p>}
                                    <p className="label">Cantidad de defectos</p>
                                </div>
                            </div>
                        </div>}
                </Card>
            </div>

            <div id="/producto" className="p-col-12 p-md-4  p-sm-4" onClick={irPantalla}>
                <Card key={`empaque`} header={empaqueheader()} className={`htg-card bor-gray-superlight`} >
                    {Object.keys(cardEmpaque).length === 0
                        ? <div className="card-spinner-centered">
                            <ClipLoader sizeUnit="px" size={65} color="#003E66" loading={true} />
                        </div>
                        : <div className="p-grid p-justify-center text-center">
                            <div className="p-col">
                                <div className="card-block">
                                    {cardEmpaque.porcentajeBudget == null
                                        ? <ClipLoader sizeUnit="px" size={30} color="#003E66" loading={true} />
                                        : <p className="value">{Math.round(100 * cardEmpaque.porcentajeBudget)}<span className="label-unidad">%</span> </p>}
                                    <p className="label">Producción / budget</p>
                                </div>
                            </div>
                            <div className="p-col">
                                <div className="card-block">
                                    {cardEmpaque.porcentajeBudget == null
                                        ? <ClipLoader sizeUnit="px" size={30} color="#003E66" loading={true} />
                                        : <p className="value">{(isNaN(cardEmpaque.packoutPromedio) || cardEmpaque.packoutPromedio === Infinity) ? "-" : Math.round(100 * cardEmpaque.packoutPromedio)}<span className="label-unidad">%</span> </p>}
                                    <p className="label">Packout promedio</p>
                                </div>
                            </div>
                        </div>}
                </Card>
            </div>

            <div id="/cumplimiento/cards" className="p-col-12 p-md-4  p-sm-4" onClick={irPantalla}>
                <Card key={`cumplimiento`} header={cumplimientoheader()} className={`htg-card bor-gray-superlight`} >
                    {Object.keys(cardCumplimiento).length === 0
                        ? <div className="card-spinner-centered">
                            <ClipLoader sizeUnit="px" size={65} color="#003E66" loading={true} />
                        </div>
                        : <div className="p-grid p-justify-center text-center">
                            <div className="p-col">
                                <div className="card-block">
                                    {cardCumplimiento.sobrante == null
                                        ? <ClipLoader sizeUnit="px" size={30} color="#003E66" loading={true} />
                                        : <p className="value">{cardCumplimiento.sobrante}<span className="label-unidad">Kgs</span></p>}
                                    <p className="label">Sobrante calculado</p>
                                </div>
                            </div>
                            <div className="p-col">
                                <div className="card-block">
                                    {cardCumplimiento.actualizado == null
                                        ? <ClipLoader sizeUnit="px" size={30} color="#003E66" loading={true} />
                                        : <p className="value">{dosDecimales(cardCumplimiento.actualizado)}<span className="label-unidad">Kgs</span></p>}
                                    <p className="label">Sobrante actualizado</p>
                                </div>
                            </div>
                        </div>}
                </Card>
            </div>

            <div id="/embarques" className="p-col-12 p-md-4  p-sm-4" onClick={irPantalla}>
                <Card key={`embarques`} header={embarquesheader()} className={`htg-card bor-gray-superlight`} >
                    {Object.keys(cardEmbarques).length === 0
                        ? <div className="card-spinner-centered ">
                            <ClipLoader sizeUnit="px" size={65} color="#003E66" loading={true} />
                        </div>
                        : <div className="p-grid p-justify-center text-center">
                            <div className="p-col">
                                <div className="card-block">
                                    {cardEmbarques.camiones == null
                                        ? <ClipLoader sizeUnit="px" size={30} color="#003E66" loading={true} />
                                        : <p className="value">{cardEmbarques.camiones}  </p>}
                                    <p className="label">Camiones totales</p>
                                </div>
                            </div>
                            <div className="p-col">
                                <div className="card-block">
                                    {cardEmbarques.camionesOnHold == null
                                        ? <ClipLoader sizeUnit="px" size={30} color="#003E66" loading={true} />
                                        : <p className="value">{cardEmbarques.camionesOnHold} </p>}
                                    <p className="label">Camiones on hold</p>
                                </div>
                            </div>
                        </div>}
                </Card>
            </div>

        </div>)
}
export default withRouter(General);//415