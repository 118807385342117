import React from 'reactn';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { numMaxRangoCajas } from "../../constants";
import { DeepClone } from '../../util/functions';
import ContenedorTabla from '../common/ContenedorTabla';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const ULTIMO_RANGO_CAJAS = "En adelante";
const TablaRangosCajasNomina = (props) => {
    const handleRangoInputChange = (event) => {
        const { id: rowIndex, value, name } = event.target;
        const copiaRangos = DeepClone(props.rangosCajasCosecha)
        copiaRangos[rowIndex][name] = value;
        props.setRangosCajasCosecha(copiaRangos);
    }

    const agregarRango = () => {
        const copiaRangos = DeepClone(props.rangosCajasCosecha)
        copiaRangos.push({ orden: props.rangosCajasCosecha.length, min: "", max: numMaxRangoCajas, bono: "" });

        //Re-asignar numero max de rango penultimo
        const penultimoRangoIndex = copiaRangos.length - 2;
        copiaRangos[penultimoRangoIndex].max = "";

        props.setRangosCajasCosecha(copiaRangos)
    }
    const eliminarRango = (index) => {
        const copiaRangos = DeepClone(props.rangosCajasCosecha);
        copiaRangos.splice(index, 1);

        //Re-asignar numero max de rango final
        const ultimoRangoIndex = copiaRangos.length - 1;
        copiaRangos[ultimoRangoIndex].max = numMaxRangoCajas;

        props.setRangosCajasCosecha(copiaRangos);
    }

    /* Bodies de tabla */
    const TablaBodyMin = (_, column) => `${props.calcMin(column.rowIndex)} cajas`;
    const TableBodyMax = (rowData, column) => {
        const esUltimoRango = column.rowIndex === props.rangosCajasCosecha.length - 1;
        return esUltimoRango ? ULTIMO_RANGO_CAJAS : `${rowData[column.field] || "-"} cajas`;
    }
    const TableBodyBono = (rowData, column) => { return `$${rowData[column.field] || 0}`; }
    const TableBodyBonoOtros = () => `$${props.precioBonoCosechaRolOtros || 0}`;

    /* Celdas editables para valores de los rangos */
    const editorTableBodyMax = ({ rowIndex, rowData, field }) => {
        const esUltimoRango = rowIndex === props.rangosCajasCosecha.length - 1;
        return esUltimoRango ? <div>No editable</div> : <InputText name={field} id={rowIndex} value={rowData[field]} onChange={handleRangoInputChange} keyfilter="pnum" placeholder="valor" />
    }
    const editorTableBodyBono = ({ rowIndex, rowData, field }) => {
        return <InputText name={field} id={rowIndex} value={rowData[field]} onChange={handleRangoInputChange} keyfilter="pnum" placeholder="valor" />
    }
    const editorTableBodyBonoOtros = () => {
        return <InputText value={props.precioBonoCosechaRolOtros} onChange={(e) => { props.setPrecioBonoCosechaRolOtros(e.target.value) }} keyfilter="pnum" placeholder="valor" />
    }

    const bodyAcciones = (_, { rowIndex }) => {
        const disabled = props.rangosCajasCosecha?.length <= 1;
        return (
            <button disabled={disabled} className={`table-btn ${disabled ? "button-disabled" : ""}`} onClick={() => eliminarRango(rowIndex)}>
                <FontAwesomeIcon icon={faTrashAlt} className={disabled ? "button-disabled" : ""} />
            </button>)
    }

    return (
        <React.Fragment>
            <ContenedorTabla col="10" titulo="Rangos de bonos para nómina de cosecha" error={props.error} message={props.error}>
                <DataTable value={props.rangosCajasCosecha.map(el => ({ ...el }))} className="" rowGroupMode="rowspan" groupRowsBy="group" sortField="group">
                    {/* <Column field="rango" header="" body={(_, column) => `Rango ${column.rowIndex + 1}`} /> */}
                    <Column field="min" header="Min" body={TablaBodyMin} editor={() => <div>No editable</div>} />
                    <Column field="max" header="Max" body={TableBodyMax} editor={editorTableBodyMax} />
                    <Column field="bono" header="Bono" body={TableBodyBono} editor={editorTableBodyBono} />
                    <Column field="group" header="Bono no cosechadores" className="col__total" body={TableBodyBonoOtros} editor={editorTableBodyBonoOtros} />
                    <Column field="acciones" className="col__label" body={bodyAcciones} />
                </DataTable>
            </ContenedorTabla>

            <div className="p-col-10 pb-0">
                <div className="p-grid" >
                    <div className="p-col pb-0 p-col-auto">
                        <div className="btn-row">
                            <Button onClick={() => { agregarRango() }} label="Agregar rango" />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>)
}
export default TablaRangosCajasNomina;