import { getGlobal } from "reactn";
import {
    obtenerRegistrosCalidadInicialSemana, obtenerRegistrosCalidadFinalSemana,
    obtenerRegistrosCalidadPesosSemana, obtenerRegistrosCalidadPesosDia, obtenerResumenPesos, obtenerResumenGeneralPesosDiarios, obtenerRegistrosCalidadPesosCiclo,
} from './Calidad';
import { obtenerEmpacadoras } from "./Empacadoras";
import { obtenerNombreSemana, obtenerDiasDeSemana, obtenerDiasDeSemanaConDetalles, obtenerListaDeSemanas } from "./fechas";
import { estamosEnProduccion, nombresDiasSemana, nombresDiasSemanaPlanos, segundosDia, segundosSemana } from "../constants";
import { obtenerCiclo } from "./Ciclos";
import { convertirArregloAObjetoPorId, DeepClone, getObjetoPorID, ordenarArreglaDeObjetosPorLlave, sumarNumerosArrayPorCampo } from "util/functions";
export const obtenerReporteDefectos = (ciclo, invernadero, semana, tipoCalidadInicial, subVariedad = false, proveedor = false) => {

    return new Promise(async (resolve, reject) => {
        try {
            let registrosCalidadInicial = await obtenerRegistrosCalidadInicialSemana(ciclo, invernadero, semana);
            let registrosCalidadFinal = await obtenerRegistrosCalidadFinalSemana(ciclo, invernadero, semana);

            if (subVariedad) {
                registrosCalidadInicial = registrosCalidadInicial.filter(registro => registro.subVariedad_ref === subVariedad)
                registrosCalidadFinal = registrosCalidadFinal.filter(registro => registro.subVariedad_ref === subVariedad)
            }
            if (proveedor && proveedor !== "Todos") {
                registrosCalidadInicial = registrosCalidadInicial.filter(registro => registro.proveedor === proveedor)
                registrosCalidadFinal = registrosCalidadFinal.filter(registro => registro.proveedor === proveedor)
            }

            let promediosInicial = tipoCalidadInicial === "PESO" ? obtenerPromediosDefectosPeso(registrosCalidadInicial) : obtenerPromediosDefectos(registrosCalidadInicial);
            let promediosFinal = tipoCalidadInicial === "PESO" ? obtenerPromediosDefectosPeso(registrosCalidadFinal) : obtenerPromediosDefectos(registrosCalidadFinal);

            let comentarios = extraerComentarios([registrosCalidadFinal, registrosCalidadInicial]);
            resolve({ promediosInicial, promediosFinal, comentarios });
        } catch (error) {
            reject(error);
        }
    })
}
export const obtenerReporteDefectosDiario = (ciclo, invernadero, semana, tipoCalidadInicial, subVariedad = false, proveedor = false) => {
    return new Promise(async (resolve, reject) => {
        try {
            let registrosCalidadInicial = await obtenerRegistrosCalidadInicialSemana(ciclo, invernadero, semana, proveedor);
            let registrosCalidadFinal = await obtenerRegistrosCalidadFinalSemana(ciclo, invernadero, semana, proveedor);

            if (subVariedad) {
                registrosCalidadInicial = registrosCalidadInicial.filter(registro => registro.subVariedad_ref === subVariedad)
                registrosCalidadFinal = registrosCalidadFinal.filter(registro => registro.subVariedad_ref === subVariedad)
            }
            if (proveedor && proveedor !== "Todos") {
                registrosCalidadInicial = registrosCalidadInicial.filter(registro => registro.proveedor === proveedor)
                registrosCalidadFinal = registrosCalidadFinal.filter(registro => registro.proveedor === proveedor)
            }

            let promediosInicial = tipoCalidadInicial === "PESO" ? obtenerPromediosDefectosPesoDiario(registrosCalidadInicial, semana) : obtenerPromediosDefectosDiario(registrosCalidadInicial, semana);
            let promediosFinal = tipoCalidadInicial === "PESO" ? obtenerPromediosDefectosPesoDiario(registrosCalidadFinal, semana) : obtenerPromediosDefectosDiario(registrosCalidadFinal, semana);
            let comentarios = extraerComentarios([registrosCalidadFinal, registrosCalidadInicial]);
            resolve({ promediosInicial, promediosFinal, comentarios });
        } catch (error) {
            reject(error);
        }
    })
}

export const obtenerReporteGeneralDefectos = (ciclo, semana, invernaderos, productos) => {
    return new Promise(async (resolve, reject) => {
        try {
            let comentarios = [];
            let rows = [];
            let promisesInicial = [];
            let promisesFinal = [];
            let promediosInicial = [];
            let promediosFinal = [];
            invernaderos.forEach(inv => {
                promisesInicial.push(obtenerRegistrosCalidadInicialSemana(ciclo, inv.id, semana))
                promisesFinal.push(obtenerRegistrosCalidadFinalSemana(ciclo, inv.id, semana))
            })
            let arrayInicial = await Promise.all(promisesInicial);
            let arrayFinal = await Promise.all(promisesFinal)

            invernaderos.forEach((inv, index) => {
                let producto = productos.find(el => el.id === inv.producto_ref);
                if (producto["tipo_calidad_inicial"] === "PESO") {
                    promediosInicial.push(obtenerPromediosDefectosPeso(arrayInicial[index]));
                } else {
                    promediosInicial.push(obtenerPromediosDefectos(arrayInicial[index]));
                }

                promediosFinal.push(obtenerPromediosDefectos(arrayFinal[index]));
                comentarios.push(extraerComentarios([arrayInicial[index], arrayFinal[index]]));
            });

            resolve({ invernaderos, promediosInicial, promediosFinal, comentarios });
        } catch (error) {
            reject(error);
        }
    })
}

export const obtenerReporteGeneralPesosDiariosAccumulados = async (ciclo, semana, invernaderos = [], presentaciones = []) => {
    try {
        const promises = invernaderos.map(invernadero => obtenerRegistrosCalidadPesosSemana(ciclo, invernadero.id, semana));
        const registrosPesosSemanaInvernaderos = await Promise.all(promises);

        const rowsProcesados = procesarRowsPesosDiarios(registrosPesosSemanaInvernaderos, invernaderos, DeepClone(presentaciones));
        const rows = rowsProcesados?.filter(row => row.accumuladoPromedio > 0);
        const presentacionesPresentes = rows.map(row => row.presentacion);
        ordenarArreglaDeObjetosPorLlave(presentacionesPresentes, "presentacion");

        const comentarios = extraerComentarios(registrosPesosSemanaInvernaderos);
        ordenarArreglaDeObjetosPorLlave(comentarios, "momento");

        //console.log("obtenerReporteGeneralPesosDiariosAccumulados DATOS - rowsProcesados - ", { comentarios, rows, presentacionesPresentes });
        return { comentarios, rows, presentacionesPresentes };
    } catch (error) {
        console.log("ERROR : ", error);
    }
}
const procesarRowsPesosDiarios = (registrosInvernaderos = [], invernaderos, presentaciones) => {
    const tiempoInicio = Date.now();
    let count = 1;
    //console.log("datos: ",{invernaderos, presentaciones})
    const rowsProcesados = presentaciones.map((presentacion, indexPres) => {
        const row = {
            presentacion, presentacion_ref: presentacion.id, nombrePresentacion: presentacion.presentacion,
            accumuladoPesos: 0, accumuladoMuestras: 0, accumuladoPromedio: 0, indexPresentacion: indexPres
        };

        //Generar objeto inicial de la fila
        invernaderos.forEach(invernadero => {
            row[invernadero.id] = { sumaPesos: 0, sumaMuestras: 0, promedio: 0 };
        })

        //Sumar pesos ycantidad de muestras por presentación (fila)
        registrosInvernaderos.forEach((invernaderoDatos, indexInv) => {
            const invernadero = invernaderos[indexInv];

            invernaderoDatos.forEach(registroDatos => {
                const { presentacion_ref = "", pesos = [] } = registroDatos;
                if (presentacion_ref === presentacion.id) {
                    pesos.forEach(pesosObj => {
                        const pesoKg = parseFloat(pesosObj.peso || 0);

                        if (!isNaN(pesoKg) && pesoKg) {
                            row[invernadero.id].sumaPesos += pesoKg;
                            row[invernadero.id].sumaMuestras++;

                            row.accumuladoPesos += pesoKg;
                            row.accumuladoMuestras++;
                        }
                    })
                }
            })
            row[invernadero.id].promedio = row[invernadero.id].sumaMuestras ? row[invernadero.id].sumaPesos / row[invernadero.id].sumaMuestras : 0;
        })
        row.accumuladoPromedio = row.accumuladoMuestras ? row.accumuladoPesos / row.accumuladoMuestras : 0

        if (row.accumuladoMuestras > 0) {
            //console.log("ROW: ", row);
            row.index = count;
            count++;
        }
        return row;
    })

    console.log("TIEMPO A PROCESAR: ", (Date.now() - tiempoInicio) / 1000)
    return rowsProcesados;
}

export const obtenerReporteColoracion = (ciclo, invernadero, semana, tipoCalidadInicial, subVariedad = false, proveedor = false) => {
    return new Promise(async (resolve, reject) => {
        try {
            let registrosCalidadInicial = await obtenerRegistrosCalidadInicialSemana(ciclo, invernadero, semana);
            let registrosCalidadFinal = await obtenerRegistrosCalidadFinalSemana(ciclo, invernadero, semana);

            if (subVariedad) {
                //console.log('subVariedad')
                registrosCalidadInicial = registrosCalidadInicial.filter(registro => registro.subVariedad_ref === subVariedad)
                registrosCalidadFinal = registrosCalidadFinal.filter(registro => registro.subVariedad_ref === subVariedad)
            }
            if (proveedor && proveedor !== "Todos") {
                registrosCalidadInicial = registrosCalidadInicial.filter(registro => registro.proveedor === proveedor)
                registrosCalidadFinal = registrosCalidadFinal.filter(registro => registro.proveedor === proveedor)
                //console.log('proveedor')
            }

            let inicial = tipoCalidadInicial === "PESO" ? obtenerPorcentajesColoracionPeso(registrosCalidadInicial) : obtenerPorcentajesColoracion(registrosCalidadInicial);
            let final = obtenerPorcentajesColoracionFinal(registrosCalidadFinal);
            let comentarios = extraerComentarios([registrosCalidadFinal, registrosCalidadInicial]);
            resolve({ inicial, final, comentarios });
        } catch (error) {
            reject(error);
        }
    })
}
export const obtenerReporteColoracionDiario = (ciclo, invernadero, semana, tipoCalidadInicial, subVariedad = false, proveedor = false) => {
    return new Promise(async (resolve, reject) => {
        try {

            let registrosCalidadInicial = await obtenerRegistrosCalidadInicialSemana(ciclo, invernadero, semana);
            let registrosCalidadFinal = await obtenerRegistrosCalidadFinalSemana(ciclo, invernadero, semana);

            if (subVariedad) {
                registrosCalidadInicial = registrosCalidadInicial.filter(registro => registro.subVariedad_ref === subVariedad)
                registrosCalidadFinal = registrosCalidadFinal.filter(registro => registro.subVariedad_ref === subVariedad)
            }
            if (proveedor && proveedor !== "Todos") {
                registrosCalidadInicial = registrosCalidadInicial.filter(registro => registro.proveedor === proveedor)
                registrosCalidadFinal = registrosCalidadFinal.filter(registro => registro.proveedor === proveedor)
            }

            let inicial = tipoCalidadInicial === "PESO" ? obtenerPorcentajesColoracionPesoDiario(registrosCalidadInicial, semana) : obtenerPorcentajesColoracionDiario(registrosCalidadInicial, semana);
            let final = obtenerPorcentajesColoracionFinalDiario(registrosCalidadFinal, semana);

            let comentarios = extraerComentarios([registrosCalidadFinal, registrosCalidadInicial]);
            resolve({ inicial, final, comentarios });
        } catch (error) {
            reject(error);
        }
    })
}

export const obtenerReporteGradosBrix = (ciclo, invernadero, semana, subVariedad = false, proveedor = false) => {
    return new Promise(async (resolve, reject) => {
        try {

            let registrosCalidadInicial = await obtenerRegistrosCalidadInicialSemana(ciclo, invernadero, semana);

            if (subVariedad)
                registrosCalidadInicial = registrosCalidadInicial.filter(registro => registro.subVariedad_ref === subVariedad)
            if (proveedor && proveedor !== "Todos")
                registrosCalidadInicial = registrosCalidadInicial.filter(registro => registro.proveedor === proveedor)

            let inicial = obtenerPromediosGradosBrix(registrosCalidadInicial);

            let comentarios = extraerComentarios([registrosCalidadInicial]);
            resolve({ inicial, comentarios });
        } catch (error) {
            reject(error);
        }
    })
}

export const obtenerReportePesos = (ciclo, invernadero, semana, presentaciones, empacadorasPassed, registrosCalidadPassed) => {
    return new Promise(async (resolve, reject) => {
        try {
            //obtener registros de calidad inicial de la semana
            let registros = registrosCalidadPassed || await obtenerRegistrosCalidadPesosSemana(ciclo, invernadero, semana);
            let empacadoras = empacadorasPassed || await obtenerEmpacadoras();
            console.log("obtenerReportePesos: ",{empacadorasPassed, registros, registrosCalidadPassed})

            let desviacionesEmpacadoras = [];
            // 
            empacadoras.forEach(empacadora => {
                let registrosFiltrados = registros.filter(el => el.tipo_empaque === "manual" && el.empacadora_ref === empacadora.id)
                let promedios = obtenerDesviacionesPesos(registrosFiltrados, presentaciones);
                // 
                promedios["nombre"] = empacadora["nombre"];
                desviacionesEmpacadoras.push(promedios);
            })

            let registrosManual = registros.filter(el => el.tipo_empaque === "manual");
            let registrosAuto = registros.filter(el => el.tipo_empaque === "auto");
            //obtener registros de calidad final de la semana            
            //
            let desviacionesManual = obtenerDesviacionesPesos(registrosManual, presentaciones);
            let desviacionesAuto = obtenerDesviacionesPesos(registrosAuto, presentaciones);
            let desviacionesTotal = obtenerDesviacionesPesos(registros, presentaciones)











            let comentarios = extraerComentarios([registros]);
            resolve({ desviacionesTotal, desviacionesManual, desviacionesAuto, comentarios, desviacionesEmpacadoras });
        } catch (error) {
            reject(error);
        }
    })
}
export const obtenerReportePesosCiclo = (cicloObj, invernadero, semana, presentaciones) => {

    return new Promise(async (resolve, reject) => {
        try {
            //obtener registros de calidad inicial de la semana
            const cicloDatos = estamosEnProduccion ? cicloObj : { ...cicloObj, semana_inicio: cicloObj?.semana_inicio + (segundosSemana * 29) }
            let registros = await obtenerRegistrosCalidadPesosCiclo(cicloObj, invernadero, semana);
            let empacadoras = await obtenerEmpacadoras();
            let desviacionesEmpacadoras = [];
            empacadoras.forEach(empacadora => {
                let registrosFiltrados = registros.filter(el => el.tipo_empaque === "manual" && el.empacadora_ref === empacadora.id)
                let promedios = obtenerDesviacionesPesos(registrosFiltrados, presentaciones);
                promedios["nombre"] = empacadora["nombre"];
                desviacionesEmpacadoras.push(promedios);
            })

            let registrosManual = registros.filter(el => el.tipo_empaque === "manual");
            let registrosAuto = registros.filter(el => el.tipo_empaque === "auto");
            //obtener registros de calidad final de la semana            
            let desviacionesManual = obtenerDesviacionesPesos(registrosManual, presentaciones);
            let desviacionesAuto = obtenerDesviacionesPesos(registrosAuto, presentaciones);
            let desviacionesTotal = obtenerDesviacionesPesos(registros, presentaciones)
            let comentarios = extraerComentarios([registros]);
            resolve({ desviacionesTotal, desviacionesManual, desviacionesAuto, comentarios, desviacionesEmpacadoras });
        } catch (error) {
            reject(error);
        }
    })
}
export const obtenerReportePesosDesglosadoDia = (ciclo, invernadero, semana, presentaciones, unixDia) => {

    return new Promise(async (resolve, reject) => {
        try {
            //obtener registros de calidad inicial de la semana
            let registros = await obtenerRegistrosCalidadPesosDia(ciclo, invernadero, unixDia);
            let empacadoras = await obtenerEmpacadoras();
            let desviacionesEmpacadoras = [];
            // 
            empacadoras.forEach(empacadora => {
                let registrosFiltrados = registros.filter(el => el.tipo_empaque === "manual" && el.empacadora_ref === empacadora.id)
                let promedios = obtenerDesviacionesPesos(registrosFiltrados, presentaciones);
                // 
                promedios["nombre"] = empacadora["nombre"];
                desviacionesEmpacadoras.push(promedios);
            })

            let registrosManual = registros.filter(el => el.tipo_empaque === "manual");
            let registrosAuto = registros.filter(el => el.tipo_empaque === "auto");
            //obtener registros de calidad final de la semana            




            //
            let desviacionesManual = obtenerDesviacionesPesos(registrosManual, presentaciones);
            let desviacionesAuto = obtenerDesviacionesPesos(registrosAuto, presentaciones);
            let desviacionesTotal = obtenerDesviacionesPesos(registros, presentaciones)



            let comentarios = extraerComentarios([registros]);
            resolve({ desviacionesTotal, desviacionesManual, desviacionesAuto, comentarios, desviacionesEmpacadoras });
        } catch (error) {
            reject(error);
        }
    })
}
export const obtenerDesviacionesPesos = (registros, presentacionesCol) => {
    let presentaciones = [];
    registros.forEach(registro => {
        presentaciones.push(registro.presentacion_ref);
    })
    let set = new Set(presentaciones);
    presentaciones = Array.from(set);
    let result = {};
    presentaciones.forEach(presentacion_ref => {
        result[presentacion_ref] = { muestras: 0, altos: 0, bajos: 0, normales: 0, sumaPesoAltos: 0, sumaPesoBajos: 0, sumaPesoNormales: 0 }
    })
    // 
    let totalMuestras = 0;

    registros.forEach(registro => {
        let presentacion = presentacionesCol.find(el => el.id === registro.presentacion_ref);
        if (presentacion) {


            let peso_maximo = parseFloat(presentacion.peso_maximo);
            let peso_minimo = parseFloat(presentacion.peso_minimo);
            registro.pesos.forEach(peso => {
                let parsed = parseFloat(peso.peso);
                if (!isNaN(peso_maximo) && parsed > peso_maximo) {
                    result[registro.presentacion_ref]["altos"]++;
                    result[registro.presentacion_ref]["sumaPesoAltos"] += parsed;
                } else if (!isNaN(peso_minimo) && parsed < peso_minimo) {
                    result[registro.presentacion_ref]["bajos"]++;
                    result[registro.presentacion_ref]["sumaPesoBajos"] += parsed;
                } else {
                    result[registro.presentacion_ref]["normales"]++;
                    result[registro.presentacion_ref]["sumaPesoNormales"] += parsed;
                }
                result[registro.presentacion_ref]["muestras"]++
            })
        }

    })
    //Object.keys(result).forEach(k => {
    //    result[k]["cociente"] = result[k]["defectuosos"] / result[k]["muestras"];
    //})
    return (result);
}


export const obtenerPromediosDefectosPeso = (registros) => {
    // 
    // 
    let defectos = [];
    registros.forEach(registro => {
        registro.defectos.forEach(defecto => {
            defectos.push(defecto.defecto_ref);
        })
    })
    let set = new Set(defectos);
    defectos = Array.from(set);
    let result = {};
    defectos.forEach(defecto_ref => {
        result[defecto_ref] = { muestras: 0, kilos: 0, defectuosos: 0 }
    })
    let totalPeso = 0;

    registros.forEach(registro => {
        // 
        // 
        // 
        let pesoCaja = parseFloat(registro.peso_real_caja);
        totalPeso += pesoCaja === 0 ? 0 : pesoCaja;
        // 
        registro.defectos.forEach(defecto => {
            result[defecto.defecto_ref]["defectuosos"] += parseFloat(defecto.valor);
            result[defecto.defecto_ref]["muestras"] += pesoCaja == 0 ? 0 : Math.ceil(parseFloat(defecto.valor) / pesoCaja);
        })
    })
    // 
    // 
    // 
    Object.keys(result).forEach(k => {

        result[k]["cociente"] = 100.0 * result[k]["defectuosos"] / totalPeso;
    })
    return (result);
}
const obtenerPromediosDefectosPesoDiario = (registros, semana) => {


    //
    let diasUnix = obtenerDiasDeSemana(semana);
    let nombresDias = nombresDiasSemanaPlanos;
    let results = {}
    let defectos = [];
    diasUnix.forEach((diaUnix, index) => {
        let filtrados = registros.filter(el => {
            return el.momento >= diaUnix && el.momento < diaUnix + 24 * 60 * 60;
        })

        filtrados.forEach(registro => {
            registro.defectos.forEach(defecto => {
                defectos.push(defecto.defecto_ref);
            })
        })
        let set = new Set(defectos);
        defectos = Array.from(set);
        let result = {};
        defectos.forEach(defecto_ref => {
            result[defecto_ref] = { kilos: 0, defectuosos: 0 }
        })
        let totalPeso = 0;

        filtrados.forEach(registro => {
            // 
            // 
            // 
            let pesoCaja = parseFloat(registro.peso_real_caja);
            totalPeso += pesoCaja === 0 ? 0 : pesoCaja;

            registro.defectos.forEach(defecto => {
                result[defecto.defecto_ref]["defectuosos"] += parseFloat(defecto.valor);
            })
        })
        // 
        // 
        // 
        Object.keys(result).forEach(k => {
            result[k]["cociente"] = 100.0 * result[k]["defectuosos"] / totalPeso;
        })
        results[nombresDias[index]] = result;
        return (result);
    })

    return results;

}
export const obtenerPromediosDefectos = (registros) => {
    // 
    // 
    let defectos = [];
    registros.forEach(registro => {
        registro.defectos.forEach(defecto => {
            defectos.push(defecto.defecto_ref);
        })
    })
    let set = new Set(defectos);
    defectos = Array.from(set);
    let result = {};
    defectos.forEach(defecto_ref => {
        result[defecto_ref] = { muestras: 0, defectuosos: 0 }
    })
    let totalFrutos = 0;

    registros.forEach(registro => {
        let pesoCaja = parseFloat(registro.peso_real_caja);
        let pesoFruto = parseFloat(registro.peso_promedio_fruto);

        let dato = pesoFruto === 0 ? 0 : Math.ceil(pesoCaja / pesoFruto);
        totalFrutos += dato;
        // 

        registro.defectos.forEach(defecto => {
            result[defecto.defecto_ref]["defectuosos"] += parseFloat(defecto.valor);
        })
    })
    // 
    // 
    // 
    Object.keys(result).forEach(k => {
        result[k]["cociente"] = 100.0 * result[k]["defectuosos"] / totalFrutos;
        result[k]["muestras"] += totalFrutos;
    })
    return (result);
}
const obtenerPromediosDefectosDiario = (registros, semana) => {


    let defectos = [];

    let diasUnix = obtenerDiasDeSemana(semana);
    let nombresDias = nombresDiasSemanaPlanos;
    let results = {}


    diasUnix.forEach((diaUnix, index) => {
        // 
        // 
        let filtrados = registros.filter(el => {
            return el.momento >= diaUnix && el.momento < diaUnix + 24 * 60 * 60;
        })
        //  
        filtrados.forEach(registro => {
            registro.defectos.forEach(defecto => {
                defectos.push(defecto.defecto_ref);
            })
        })
        let set = new Set(defectos);
        defectos = Array.from(set);
        let result = {};
        defectos.forEach(defecto_ref => {
            result[defecto_ref] = { muestras: 0, defectuosos: 0 }
        })
        let totalFrutos = 0;

        filtrados.forEach(registro => {


            let pesoCaja = parseFloat(registro.peso_real_caja);
            let pesoFruto = parseFloat(registro.peso_promedio_fruto);
            totalFrutos += pesoCaja === 0 ? 0 : Math.ceil(pesoCaja / pesoFruto);

            registro.defectos.forEach(defecto => {
                result[defecto.defecto_ref]["defectuosos"] += parseFloat(defecto.valor);
            })
        })



        Object.keys(result).forEach(k => {
            result[k]["cociente"] = 100.0 * result[k]["defectuosos"] / totalFrutos;
        })

        results[nombresDias[index]] = result;
        return (result);
    })


    return results;

}

const obtenerPorcentajesColoracionDiario = (registros, semana) => {

    let diasUnix = obtenerDiasDeSemana(semana);
    let nombresDias = nombresDiasSemanaPlanos;
    let colores = ["color1", "color2", "color3", "color4", "color5", "color6"];
    let results = {};

    diasUnix.forEach((diaUnix, index) => {
        let result = {};
        let filtrados = registros.filter(el => {
            return el.momento >= diaUnix && el.momento < diaUnix + 24 * 60 * 60;
        })
        colores.forEach(color => {
            result[color] = { muestras: 0, defectuosos: 0 }
        })
        // 
        // 
        let totalFrutos = 0;
        filtrados.forEach(registro => {
            // 
            // 
            let pesoCaja = parseFloat(registro.peso_real_caja);
            let pesoFruto = parseFloat(registro.peso_promedio_fruto);
            totalFrutos += Math.ceil(pesoCaja / pesoFruto);
            colores.forEach(color => {
                result[color]["defectuosos"] += parseFloat(registro.coloracion[0][color]);
            })
            if (registro.peso_promedio_fruto) console.log(registro.id)
            console.log("-------------")
            console.log(pesoCaja / pesoFruto)
            console.log(pesoCaja + "caj  " + pesoFruto + "fru  " + " --- TOT: " + totalFrutos)

        })
        colores.forEach(k => {
            result[k]["cociente"] = totalFrutos === 0 ? 0 : 100 * result[k]["defectuosos"] / totalFrutos;
        })
        results[nombresDias[index]] = result;
    });

    return results;

}
const obtenerPorcentajesColoracion = (registros) => {
    let colores = ["color1", "color2", "color3", "color4", "color5", "color6"];
    let result = {};
    colores.forEach(color => {
        result[color] = { muestras: 0, defectuosos: 0 }
    })
    // 
    // 
    let totalFrutos = 0;

    registros.forEach(registro => {
        // 
        // 
        let pesoCaja = parseFloat(registro.peso_real_caja);
        let pesoFruto = parseFloat(registro.peso_promedio_fruto);
        totalFrutos += Math.ceil(pesoCaja / pesoFruto);
        colores.forEach(color => {
            result[color]["defectuosos"] += parseFloat(registro.coloracion[0][color]);
        })
    })
    // 
    // 
    colores.forEach(k => {
        result[k]["cociente"] = totalFrutos === 0 ? 0 : 100 * result[k]["defectuosos"] / totalFrutos;
    })
    return (result);
}

const obtenerPorcentajesColoracionPeso = (registros) => {
    let colores = ["color1", "color2", "color3", "color4", "color5", "color6"];
    let result = {};
    colores.forEach(color => {
        result[color] = { muestras: 0, defectuosos: 0 }
    })
    // 
    // 
    let totalPeso = 0;

    registros.forEach(registro => {
        // 
        // 
        let pesoCaja = parseFloat(registro.peso_real_caja);
        totalPeso += pesoCaja;
        colores.forEach(color => {
            result[color]["defectuosos"] += parseFloat(registro.coloracion[0][color]);
        })
    })
    // 
    // 
    colores.forEach(k => {
        result[k]["cociente"] = totalPeso === 0 ? 0 : 100 * result[k]["defectuosos"] / totalPeso;
    })
    return (result);
}
const obtenerPorcentajesColoracionPesoDiario = (registros, semana) => {
    let diasUnix = obtenerDiasDeSemana(semana);
    let nombresDias = nombresDiasSemanaPlanos;
    let colores = ["color1", "color2", "color3", "color4", "color5", "color6"];
    let results = {};

    diasUnix.forEach((diaUnix, index) => {
        let result = {};
        let filtrados = registros.filter(el => {
            return el.momento >= diaUnix && el.momento < diaUnix + 24 * 60 * 60;
        })


        colores.forEach(color => {
            result[color] = { muestras: 0, defectuosos: 0 }
        })
        // 
        // 
        let totalPeso = 0;

        filtrados.forEach(registro => {
            // 
            // 
            let pesoCaja = parseFloat(registro.peso_real_caja);
            totalPeso += pesoCaja;
            colores.forEach(color => {
                result[color]["defectuosos"] += parseFloat(registro.coloracion[0][color]);
            })
        })
        colores.forEach(k => {
            result[k]["cociente"] = totalPeso === 0 ? 0 : 100 * result[k]["defectuosos"] / totalPeso;
        })
        results[nombresDias[index]] = result;
    })


    // 
    // 

    return (results);
}

const obtenerPorcentajesGradosBrix = (registros) => {
    let colores = ["color1", "color2", "color3", "color4", "color5", "color6"];
    let result = {};
    colores.forEach(color => {
        result[color] = { muestras: 0, defectuosos: 0 }
    })
    // 
    // 
    let totalFrutos = 0;

    registros.forEach(registro => {
        // 
        // 
        let pesoCaja = parseFloat(registro.peso_real_caja);
        let pesoFruto = parseFloat(registro.peso_promedio_fruto);
        totalFrutos += Math.ceil(pesoCaja / pesoFruto);
        colores.forEach(color => {
            let valor = parseFloat(registro.brix[0][color]);
            if (!isNaN(valor)) {
                result[color]["defectuosos"] += parseFloat(registro.brix[0][color]);
                result[color]["muestras"] += totalFrutos;
            }

        })
    })
    // 
    // 
    colores.forEach(k => {
        result[k]["cociente"] = result[k]["muestras"] === 0 ? 0 : 100 * result[k]["defectuosos"] / result[k]["muestras"];
    })
    return (result);
}
const obtenerPromediosGradosBrix = (registros) => {
    let colores = ["color1", "color2", "color3", "color4", "color5", "color6"];
    let result = {};
    colores.forEach(color => {
        result[color] = { muestras: 0, sumaPeso: 0 }
    })

    registros.forEach(registro => {
        colores.forEach(color => {
            let valor = parseFloat(registro.brix[0][color]);
            if (!isNaN(valor) && parseInt(valor) !== 0) {
                result[color].sumaPeso += parseFloat(registro.brix[0][color]);
                result[color].muestras++;
            }
        })
    })

    colores.forEach(k => {
        result[k]["cociente"] = result[k].muestras === 0 ? 0 : result[k].sumaPeso / result[k].muestras;
    })
    return (result);
}
const obtenerPorcentajesColoracionFinal = (registros) => {
    let colores = ["color1", "color2", "color3", "color4", "color5", "color6"];
    let result = {};
    colores.forEach(color => {
        result[color] = { muestras: 0, defectuosos: 0 }
    })
    // 
    // 
    let totalFrutos = 0;

    registros.forEach(registro => {
        // 
        // 
        let pesoCaja = parseFloat(registro.peso_real_caja);
        let pesoFruto = parseFloat(registro.peso_promedio_fruto);
        totalFrutos = Math.ceil(pesoCaja / pesoFruto);
        colores.forEach(color => {
            let valor = parseFloat(registro?.coloracion?.[0]?.[color]);
            if (!isNaN(valor)) {
                result[color]["defectuosos"] += valor;
                result[color]["muestras"] += totalFrutos;
            }

        })
    })
    // 

    // 
    // 
    colores.forEach(k => {
        result[k]["cociente"] = result[k]["muestras"] === 0 ? 0 : result[k]["defectuosos"] / result[k]["muestras"];
    })
    return (result);
}

const obtenerPorcentajesColoracionFinalDiario = (registros, semana) => {
    let diasUnix = obtenerDiasDeSemana(semana);
    let nombresDias = nombresDiasSemanaPlanos;
    let colores = ["color1", "color2", "color3", "color4", "color5", "color6"];
    let results = {};
    diasUnix.forEach((diaUnix, index) => {
        let filtrados = registros.filter(el => {
            return el.momento >= diaUnix && el.momento < diaUnix + 24 * 60 * 60;
        })
        let result = {};
        colores.forEach(color => {
            result[color] = { muestras: 0, defectuosos: 0 }
        })
        // 
        // 
        let totalFrutos = 0;

        filtrados.forEach(registro => {
            // 
            // 
            let pesoCaja = parseFloat(registro.peso_real_caja);
            let pesoFruto = parseFloat(registro.peso_promedio_fruto);
            totalFrutos = Math.ceil(pesoCaja / pesoFruto);
            colores.forEach(color => {
                let valor = parseFloat(registro?.coloracion?.[0]?.[color]);
                if (!isNaN(valor)) {
                    result[color]["defectuosos"] += valor;
                    result[color]["muestras"] += totalFrutos;
                }

            })
        })
        // 

        // 
        // 
        colores.forEach(k => {
            result[k]["cociente"] = result[k]["muestras"] === 0 ? 0 : result[k]["defectuosos"] / result[k]["muestras"];
        })
        results[nombresDias[index]] = result;
        return (result);
    })
    return results
}

const obtenerPromediosPesos = (registros) => {
    let presentaciones = [];
    registros.forEach(registro => {
        presentaciones.push(registro.presentacion_ref);
    })
    let set = new Set(presentaciones);
    presentaciones = Array.from(set);
    let result = {};
    presentaciones.forEach(presentacion_ref => {
        result[presentacion_ref] = { muestras: 0, defectuosos: 0 }
    })
    // 
    let totalMuestras = 0;

    registros.forEach(registro => {
        //                 
        // 
        registro.pesos.forEach(peso => {

            result[registro.presentacion_ref]["defectuosos"] += parseFloat(peso.peso);
            result[registro.presentacion_ref]["muestras"]++;
        })
    })
    // 
    // 
    Object.keys(result).forEach(k => {
        result[k]["cociente"] = result[k]["defectuosos"] / result[k]["muestras"];
    })
    return (result);
}

const extraerComentarios = (listaRegistros) => {
    let comentarios = [];
    listaRegistros.forEach(registros => {
        registros.forEach(registro => {
            if (registro.comentario !== "") {
                comentarios.push({ momento: registro.momento, comentario: registro.comentario });
            }
        })
    })
    return comentarios;
}

export const obtenerPesoPromedioFruto = (ciclo, invernadero, semana) => {
    return new Promise(async (resolve, reject) => {
        try {
            let registrosCalidadInicial = await obtenerRegistrosCalidadInicialSemana(ciclo, invernadero, semana);


            let pesoTotal = 0;
            registrosCalidadInicial.forEach(registro => {
                pesoTotal += parseFloat(registro.peso_promedio_fruto)
            })
            resolve(pesoTotal / registrosCalidadInicial.length);
        } catch (error) {
            reject(error);
        }
    })

}
export const obtenerReporteCavidad = async (cicloId, invernaderoId, semana) => {
    return new Promise(async (resolve, reject) => {
        try {

            //obtener registros de calidad inicial de la semana
            let registrosCalidadInicial = await obtenerRegistrosCalidadInicialSemana(cicloId, invernaderoId, semana);

            let inicial = obtenerPromediosCavidad(registrosCalidadInicial, semana);
            // 
            // 
            // 
            // 
            // 
            // 
            // 
            // 
            // let comentarios = extraerComentarios([registrosCalidadFinal, registrosCalidadInicial]);
            resolve({ inicial });
        } catch (error) {
            reject(error);
        }
    })
}
const obtenerPromediosCavidad = (registros, semana) => {
    let diasUnix = obtenerDiasDeSemana(semana);
    let nombresDias = nombresDiasSemanaPlanos;

    let results = [];

    diasUnix.forEach((diaUnix, index) => {
        let result = {};
        let filtrados = registros.filter(el => {
            return el.momento >= diaUnix && el.momento < diaUnix + 24 * 60 * 60;
        })

        result = { nombre: nombresDiasSemana[index], muestras: 0, suma: 0 }

        // 
        // 
        let totalFrutos = 0;

        filtrados.forEach(registro => {
            // 
            //             
            let cavidad = registro["cavidad"] ? parseFloat(registro.cavidad) : 0;
            if (!isNaN(cavidad)) {
                result["suma"] += parseFloat(cavidad);

            }
            result["muestras"]++;
        })

        // 
        // 

        result["cociente"] = result["muestras"] > 0 ? result["suma"] / result["muestras"] : 0;

        results.push(result);
    });
    let totales = { nombre: "Semana", suma: 0, muestras: 0, cociente: 0 };

    results.forEach(result => {
        totales["suma"] += result["suma"];
        totales["muestras"] += result["muestras"];
    })
    totales["cociente"] = totales["muestras"] > 0 ? totales["suma"] / totales["muestras"] : 0;
    results.push(totales);
    return results;
}
export const obtenerResumenSemanalPresentacionPesosCalidad = async (ciclo, invernaderoId, presentaciones, empacadoras, registrosCalidadInv) => {
    const timeA = Date.now()
    let semanas = obtenerListaDeSemanas(ciclo.semana_inicio, ciclo.semana_cierre);
    let promises = semanas.map((semana, i) => obtenerReportePesos(ciclo.id, invernaderoId, semana.time, presentaciones, empacadoras, registrosCalidadInv[i]));
    let result = await Promise.all(promises);


    let procesado = result.map((resultSemana, index) => {
        let obj = {
            semana: semanas[index].nombreCompacto,
            altos: 0,
            bajos: 0,
            normales: 0,
            muestras: 0,
            sumaPesoBajos: 0,
            sumaPesoNormales: 0,
            sumaPesoAltos: 0,
        }
        let keys = Object.keys(resultSemana.desviacionesTotal)
        keys.forEach(k => {
            obj["altos"] += resultSemana.desviacionesTotal[k]["altos"]
            obj["bajos"] += resultSemana.desviacionesTotal[k]["bajos"]
            obj["normales"] += resultSemana.desviacionesTotal[k]["normales"]
            obj["muestras"] += resultSemana.desviacionesTotal[k]["muestras"]
            obj["sumaPesoBajos"] += resultSemana.desviacionesTotal[k]["sumaPesoBajos"]
            obj["sumaPesoNormales"] += resultSemana.desviacionesTotal[k]["sumaPesoNormales"]
            obj["sumaPesoAltos"] += resultSemana.desviacionesTotal[k]["sumaPesoAltos"]
        })
        return obj;
    })

console.log("LOAD TIME -- ", (Date.now() - timeA)/1000)
    return procesado;
}
export const obtenerAcumuladoPesosCalidadTodosInvernaderos = async (cicloObj, invernaderos = [], presentaciones, currSemana) => {
    const promisesAcumuladosTodos = invernaderos?.map(invernadero => obtenerAcumuladoPresentacionPesosCalidad(cicloObj, invernadero.id, presentaciones, currSemana));
    const datosAcumuladosTodos = await Promise.all(promisesAcumuladosTodos);
    const datosAcumuladosTodosObj = convertirArregloAObjetoPorId(datosAcumuladosTodos, invernaderos);
    return datosAcumuladosTodosObj;
}
export const obtenerAcumuladoPresentacionPesosCalidad = async (cicloId, invernaderoId, presentaciones, currSemana) => {
    const { ciclos = [] } = getGlobal();
    const cicloObj = getObjetoPorID(ciclos, cicloId);

    const semanas = obtenerListaDeSemanas(cicloObj?.semana_inicio, cicloObj?.semana_cierre);
    const semanasFiltrados = semanas?.filter(semana => semana.time <= currSemana) || [];
    const promises = semanasFiltrados.map(semana => obtenerReportePesos(cicloObj.id, invernaderoId, semana.time, presentaciones));
    return await Promise.all(promises);
}/* 973 */