import React, { useEffect, useGlobal, useState } from "reactn";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { MultiSelect } from "primereact/multiselect";
import { obtenerSnapshotPrevioNuevo } from "../../service/nomina";
import { obtenerActividades } from "../../service/actividades";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faSearch } from '@fortawesome/free-solid-svg-icons';
import { separadoComas, currency, dosDecimales, getProductoYVariedadString, getObjetoPorID, getNombrePorID, capitalizeFirstLetter } from "../../util/functions";
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import ContenedorPrincipal from "../common/ContenedorPrincipal";
import ContenedorHeader from "../common/ContenedorHeader";
import ContenedorBotonesTop from "../common/ContenedorBotonesTop";
import RowBotonesTop from "../common/RowBotonesTop";
import SeccionFiltros from "../common/SeccionFiltros";
import ContenedorFiltro from "../common/ContenedorFiltro";
import ContenedorTablaAdmin from "../common/ContenedorTablaAdmin";
import { obtenerDatosBase } from "./ConsultarCtrlConNuevoBonoCosecha";
import { estamosEnProduccion } from "../../constants";
import { obtenerRolesInvernadero } from "service/Empaques";
import ContenedorFiltroBusqueda from "components/common/ContenedorFiltroBusqueda";
import { updateDatosScriptNuevoBono } from "./SnapshotsScripts";

const Consultar = (props) => {
    const [invernaderos] = React.useGlobal("invernaderosInternos");
    const [invernadero, setInvernadero] = useGlobal("invernadero");
    const [ciclo] = useGlobal("ciclo");
    var [, setCuentaEmpleados] = useGlobal("cuentaEmpleados");
    const [, setCargando] = useGlobal("cargando");
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    
    const [weeks, week, handleWeekChange] = useWeekSelector();
    var [actividades, setActividades] = useState([]);
    var [empleadosSemana, setEmpleadosSemana] = React.useState([])
    var [empleadosSemanaFiltrados, setEmpleadosSemanaFiltrados] = React.useState([])
    const [precioBonoCosechaRolOtrosCaptura, setPrecioBonoCosechaRolOtrosCaptura] = useState(null)
    const [rangosCajasCosechaCaptura, setRangosCajasCosechaCaptura] = useState(null)

    const [globalFilter, setGlobalFilter] = useState("");
    const [actividadesSemana, setActividadesSemana] = useState([])
    const [, setVariedad] = useState({});
    const [filtroActividades, setFiltroActividades] = useState([]);
    const [footer, setFooter] = useState([]);
    const [esCapturado, setEsCapturado] = useState(false);
    const [rolsInvernadero, setRolsInvernadero] = useState([]);
    const [rolsInvernaderoFiltro, setRolsInvernaderoFiltro] = useState([]);

    useEffect(() => {
        setNombreModulo("Producción");
        setHabilitadoSelectorCiclo(true);
    }, [])
    useEffect(() => {
        if (ciclo && invernadero) { getInitialData(); }
    }, [ciclo])

    /* Filtrar empleados por tipo de rol de invernadero */
    useEffect(() => {
        if (empleadosSemana?.length > 0) {
            const empleadosFiltrados = empleadosSemana?.filter(checarIncluirEmpleado) || [];
            setEmpleadosSemanaFiltrados(empleadosFiltrados);
        }
    }, [empleadosSemana, rolsInvernaderoFiltro]);

    useEffect(() => {
        if (actividadesSemana) {
            const actividadesAFiltrar = estamosEnProduccion ? obtenerActividadesFiltro() : obtenerActividadesFiltro()//.filter((el,index) => index < 6);
            setFiltroActividades(actividadesAFiltrar);
        }
    }, [actividadesSemana])

    const checarIncluirEmpleado = (empleado) => {
        //console.log("EEE: " , {rolsInvernaderoFiltro, empleadosSemana, empleado});
        const inlcuyeRol = rolsInvernaderoFiltro?.some(rol => empleado.rol_invernadero_ref === rol.id);
        const incluyeSinAsignar = !empleado.rol_invernadero_ref && rolsInvernaderoFiltro?.some(rol => rol.id === "Sin rol");
        return inlcuyeRol || incluyeSinAsignar;
    }

    const getInitialData = async () => {
        try {
            setCargando(true);
            const [actividadesResp, rolsInvernaderoResp] = await Promise.all([obtenerActividades(), obtenerRolesInvernadero()])
            setRolsInvernadero([{ nombre: "Sin rol", id: "Sin rol" }, ...rolsInvernaderoResp]);
            setRolsInvernaderoFiltro([{ nombre: "Sin rol", id: "Sin rol" }, ...rolsInvernaderoResp])
            setActividades(actividadesResp);
        } catch (error) {
            console.log("ERROR getInitialData ", error);
        }
    }
    useEffect(() => {
        if (invernadero && ciclo && actividades.length > 0) { updateDatos(); }
    }, [ciclo, invernadero, week, actividades])
    const updateDatos = async (params) => {
        setEsCapturado(false);
        setCargando(true)

        let snapshotPrevio = await obtenerSnapshotPrevioNuevo(ciclo, invernadero.id, week.time);
        if (snapshotPrevio.exists) {
            setEsCapturado(true);
            if(snapshotPrevio?.invernaderoVirtual) {
                setPrecioBonoCosechaRolOtrosCaptura(snapshotPrevio.invernaderoVirtual?.precioBonoCosechaRolOtros)
                setRangosCajasCosechaCaptura(snapshotPrevio.invernaderoVirtual?.rangosCajasCosecha)
            }
            setEmpleadosSemana(snapshotPrevio.empleadosSemana);
            setFooter(snapshotPrevio.footer);
            setActividadesSemana(snapshotPrevio.actividadesSemana);
        } else {
            let { cuentaEmpleados, empleadosSemana, footer, variedad, actividadesSemana } = await obtenerDatosBase(ciclo, invernadero, week, actividades, rolsInvernadero);
            setVariedad(variedad);
            setActividadesSemana(actividadesSemana);
            setCuentaEmpleados(cuentaEmpleados);
            setEmpleadosSemana(empleadosSemana);
            setFooter(footer);
        }
        setCargando(false);
    }

    const filtrarColumnas = () => {
        let filtradas = actividadesSemana.filter(actividad => {
            let found = filtroActividades.find(el => el.id === actividad);
            if (found || actividad === "empleado") { return true; }
            else return false
        })
        return filtradas;
    }

    const obtenerColumnas = () => {
        let columnas = filtrarColumnas();
        columnas.push("total", "bono");
        let colExtras = ["empleado", "bono", "total"]
        let headers = { total: "Bono semanal", bono: "Total + Bono%" }

        return columnas.map((actividad, i) => {
            const esCosechaActividad = getNombrePorID(actividades, actividad) === "Cosecha";

            let mayoriaCosechoElMinimo = esCosechaActividad ? obtenerPorcentageCosechadoRango1(actividad) : false;
            let bonoMinimo = (invernadero?.rangosCajasCosecha?.length > 1) ? invernadero.rangosCajasCosecha[1]?.bono : 0;
            //console.log("BONO MINIMO: ", bonoMinimo)


            let header = actividad;
            if (headers[actividad]) { header = headers[actividad]; }
            return <Column key={i} field={actividad} sortable={true} sortField={colExtras.includes(actividad) ? actividad : `orig_${actividad}`}
                body={(rowData, column) => formatCell(rowData, column, mayoriaCosechoElMinimo, bonoMinimo)}
                header={(actividades && actividad !== "empleado" && actividad !== "total" && actividad !== "bono") ? actividades.find(el => actividad === el.id).nombre : actividad === "empleado" ? "Empleado" : header} />
        })
    }
    const obtenerPorcentageCosechadoRango1 = (actividad,) => {
        const cosechadoresTotales = empleadosSemanaFiltrados?.filter(empleado => empleado.esRolCosechador)?.length || 0;
        let cosechadoresPresentes = 0;
        const ratioMinimoBonoCosecha = .5; //50%
        let totalRango1 = 0

        empleadosSemanaFiltrados.forEach(empleado => {
            if (empleado.esRolCosechador && empleado[actividad] !== 0) {
                let [cantidadCajas, currBono] = empleado[actividad]?.split("/") || [];
                const { rangoValor } = obtenerDatosRangoCosecha(cantidadCajas);
                if (rangoValor === 1) totalRango1++;
                if (rangoValor >= 1) cosechadoresPresentes++;
            }
        })
        const ratioCompletadoElMinimo = cosechadoresTotales > 0 ? totalRango1 / cosechadoresTotales : 0;
        const mayoriaCosechoElMinimo = ratioCompletadoElMinimo >= ratioMinimoBonoCosecha;

        //console.log("obtenerPorcentageCosechadoRango1: ", { MAYORIA_LLEGO_RANGO1: mayoriaCosechoElMinimo, MINIMO: ratioMinimoBonoCosecha, ratioCompletadoElMinimo, totalRango1, cosechadoresPresentes, cosechadoresTotales })
        return mayoriaCosechoElMinimo;
    }

    const obtenerColumnasFooter = () => {
        let columnas = filtrarColumnas();
        columnas.push("total", "bono");
        let headers = { total: "Bono semanal", bono: "Total + Bono%" }
        return columnas.map((actividad, i) => {
            let header = actividad;
            if (headers[actividad]) { header = headers[actividad]; }

            return <Column key={i} field={actividad} sortable={false} body={(rowData, column) => formatCell(rowData, column)}
                header={(actividades && actividad !== "empleado" && actividad !== "total" && actividad !== "bono") ? getObjetoPorID(actividades, actividad)?.nombre : actividad === "empleado" ? "" : header} />
        })
    }

    const cosechaTooltipCelda = (textoCelda, cantidadCajas, valorFinal, rowData) => {
        const bonoOtros = precioBonoCosechaRolOtrosCaptura || invernadero.precioBonoCosechaRolOtros;

        const { min, max, bono, rango } = rowData.esRolCosechador
            ? obtenerDatosRangoCosecha(cantidadCajas, rowData)
            : { min: "0", max: "En adelante", bono: bonoOtros, rango: "Rango 'no cosechador'" };
        //console.log("RANGO: ",rango)
        const tooltip = `<div  class='tooltip-datas tooltip-separator'> 
        <div class='tooltip-data'>${rowData.rolInvernaderoNombre}
          <p class='tooltip-label'>Rol invernadero</p> 
        </div>
        <div class='tooltip-data'>${rango}
          <p class='tooltip-label'>Nómina cajas</p>
        </div>
        <div class='tooltip-data'>${min}
          <p class='tooltip-label'>Min</p>
        </div>
        <div class='tooltip-data'>${max}
          <p class='tooltip-label'>Max</p>
        </div>
      </div>
      <div  class='tooltip-datas tooltip-separator'> 
        <div class='tooltip-data'>${cantidadCajas}
          <p class='tooltip-label'>Cajas cosechadas</p>
        </div>
        <div class='tooltip-data'>$${rowData.asignarRangoMinimo ? bono : bono}
          <p class='tooltip-label'>Bono precio</p>
        </div>
        <div class='tooltip-data'>${valorFinal}
          <p class='tooltip-label'>Calculo final</p>
        </div>
      </div>`;

        return <div>{textoCelda} <Button className="btn-line btn-help ml-1" type="button" label="?" tooltip={tooltip} /></div>
    }
    const obtenerDatosRangoCosecha = (cajasCosechadas = 0, empleado = {}) => {
        const rangosCosechaInv = rangosCajasCosechaCaptura || invernadero?.rangosCajasCosecha || [];
        let rangoCajasCosecha = { min: "-", max: "-", bono: "-", rango: "-", rangoValor: 1 };

        //console.log("CAJAS: ", { cajasCosechadas, rangosCosechaInv })
        rangosCosechaInv.forEach((rangoNomina, index) => {
            if (cajasCosechadas >= rangoNomina.min && cajasCosechadas <= rangoNomina.max) {
                rangoCajasCosecha = rangoNomina;
                rangoCajasCosecha.rango = `Rango ${index + 1}`;
                rangoCajasCosecha.rangoValor = index + 1;

                if (index === rangosCosechaInv.length - 1) { rangoCajasCosecha.max = "En adelante" }
                else if (empleado.asignarRangoMinimo) {
                    rangoCajasCosecha.rango = `Rango ${index + 1}`;
                    rangoCajasCosecha.bono = rangosCosechaInv[index + 1].bono
                    rangoCajasCosecha.rangoValor = index + 1;
                }
            }
        })

        if (isNaN(rangoCajasCosecha?.bono) || !rangoCajasCosecha?.bono) { rangoCajasCosecha.bono = 0; }
        return rangoCajasCosecha;
    }

    const formatCell = (rowData, column, mayoriaCosechoElMinimo, bonoMinimo) => {
        const dataNomina = rowData[column.field];
        if (rowData?.tipo === "porcentaje" && dataNomina === "Porcentaje") { return dataNomina; }
        else if (rowData?.tipo === "porcentaje" && dataNomina > 100) { return <div className="excedido"> {`${dataNomina}%`}</div>; }
        else if (rowData?.tipo === "porcentaje") { return dataNomina != null ? `${dataNomina}%`: "0%"; }
        else if (rowData[column.field] === 0 ||  !rowData[column.field] || dataNomina === 0) { return ""; }
        else if (column.field === "empleado") {
            //if(column.rowIndex === 0)console.log("ROW::: ", rowData)
            const sinRolTexto = (column.rowIndex === 0 && rowData?.empleado === "Totales") ? "" : "(Sin rol)";
            const rolInvernadero = rowData.esRolCosechador ? "(Cosechador)" : (rowData.esRolCosechaOtros ? `(${capitalizeFirstLetter(rowData.rolInvernaderoNombre)})` : sinRolTexto)
            return <span>{rowData.empleado} <span className='novato-label'>{rolInvernadero.replace(/ /g, "\u00A0")} {rowData.novato ? "(novato)" : ""}</span> </span>;
        }
        else if (typeof dataNomina === "string" && column.field === "bono" || column.field === "total") { return currency(dosDecimales(dataNomina)); }
        else if (typeof dataNomina === "string") {
            let [units, curr] = dataNomina?.split("/") || [];
            units = separadoComas(parseFloat(units));
            curr = isNaN(curr) ? 0 : currency(curr);
            const textoCelda = `${units} / ${curr}`;
            const actividadCelda = actividades?.find(el => el.id === column.field);

            const celdaEsCosecha = actividadCelda?.nombre === "Cosecha";
            const celdaEsEmpleado = rowData.empleado !== "Totales";
            return (celdaEsCosecha && celdaEsEmpleado) ? cosechaTooltipCelda(textoCelda, units, curr, rowData) : textoCelda;
        }
        else return isNaN(dataNomina) ? 0 : currency(dataNomina);
    }
    const obtenerActividadesFiltro = () => {
        let filtradas = actividades.filter(actividad => {
            let found = actividadesSemana.find(el => el === actividad.id);
            if (found) { return true; }
            else return false;
        })
        return filtradas;
    }
    const irGraficaPorActividad = () => { props.history.push({ pathname: "/nomina/graficas/empleados_actividad", data: { invernadero: invernadero } }); }
    const irGraficaDesglose = () => { props.history.push({ pathname: "/nomina/graficas/desglose_porcentaje_actividades", data: { invernadero: invernadero } }); }
    const irGraficaBonosHoras = () => { props.history.push({ pathname: "/nomina/graficas/bono_horas", data: { invernadero: invernadero } }); }
    const irResumenIncidencias = () => { props.history.push("/nomina/incidencias") }
    /* const calcularLineasTotales = (invernadero) => {
        const asignacion_lineas = invernadero["asignacion_lineas"];
        const total = asignacion_lineas.reduce((acc, curr) => {
            const dato = curr.totales.split(" ")[0];
            return (dato && !isNaN(parseInt(dato))) ? (acc + parseInt(dato)) : acc;
        }, 0);
        return total;
    } */

    return (
        <ContenedorPrincipal extras="p-justify-center">
            <ContenedorHeader col="p-col p-col-auto" titulo={`Producción semanal`} subtitulo={getProductoYVariedadString(invernadero)} 
            invernaderoNombre={invernadero?.nombre || ""} iconos={`icon-id bor-${invernadero?.color} bg-${invernadero?.color} ${invernadero?.habilitado ? "" : "Deshabilitado"}`} atras={props.history.goBack} />

            <ContenedorBotonesTop>
                <RowBotonesTop>
                    <Button onClick={irResumenIncidencias} label="Resumen de incidencias" />
                    <Button className="reactbtn-icon" onClick={irGraficaPorActividad} label="Actividades por empleado">
                        <FontAwesomeIcon icon={faChartBar} />
                    </Button>
                    <Button className="reactbtn-icon" onClick={irGraficaDesglose} label="Desglose de actividades">
                        <FontAwesomeIcon icon={faChartBar} />
                    </Button>
                    <Button className="reactbtn-icon" onClick={irGraficaBonosHoras} label="Bonos por horas">
                        <FontAwesomeIcon icon={faChartBar} />
                    </Button>
                </RowBotonesTop>
            </ContenedorBotonesTop>

            <SeccionFiltros>
                <ContenedorFiltroBusqueda>
                    <FontAwesomeIcon icon={faSearch} />
                    <InputText type="search" value={globalFilter} onChange={(e => { setGlobalFilter(e.target.value) })} placeholder="Búsqueda por empleado" />
                </ContenedorFiltroBusqueda>
                <ContenedorFiltro label="Invernadero">
                    <Dropdown id="filtroInvernadero" placeholder="Seleccionar" dataKey="id" optionLabel="nombre"
                        options={invernaderos} value={invernadero} value={invernadero} onChange={(e) => { setInvernadero(e.target.value) }} />
                </ContenedorFiltro>
                <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
                <ContenedorFiltro label="Actividades" >
                    <MultiSelect value={filtroActividades} options={obtenerActividadesFiltro()} onChange={(e) => setFiltroActividades(e.value)} fixedPlaceholder={true}
                        placeholder={`${filtroActividades.length}/${obtenerActividadesFiltro().length} actividades seleccionadas`} optionLabel="nombre" dataKey="id" />
                </ContenedorFiltro>
                <ContenedorFiltro label="rols de invernadero" >
                    <MultiSelect value={rolsInvernaderoFiltro} options={rolsInvernadero} onChange={(e) => setRolsInvernaderoFiltro(e.value)} fixedPlaceholder={true}
                        placeholder={`${rolsInvernaderoFiltro.length}/${rolsInvernadero.length} rols seleccionados`} optionLabel="nombre" dataKey="id" />
                </ContenedorFiltro>
            </SeccionFiltros>

            {/* Tabla de consulta */}
            {esCapturado ? "ES DATO CAPTURADO" : ""}
            <ContenedorTablaAdmin>
                {(actividadesSemana && (actividadesSemana.length > 1))
                    ? <React.Fragment>
                        <div className="card p-0 card-table-pag">
                            <p className="section-title">Totales de producción semanal</p>
                            <DataTable value={footer} className="tabla-nomina-totales" >
                                {obtenerColumnasFooter()}
                            </DataTable>
                        </div>
                        <div className="card p-0 card-table-pag">
                            <DataTable value={empleadosSemanaFiltrados} globalFilter={globalFilter} className="tabla-empleados" scrollable={true} scrollHeight="400px"
                                rowClassName={(rowData) => { return { 'ausente': rowData.falta, "inactivo": !rowData.activo, "novato": rowData.novato } }}>
                                {obtenerColumnas()}
                            </DataTable>
                        </div>
                    </React.Fragment>
                    : <div className="card text-center">
                        <p>No hay actividades planeadas esta semana</p>
                    </div>}
            </ContenedorTablaAdmin>
        </ContenedorPrincipal>);
}
export default Consultar;//345