import React, { useGlobal, useEffect } from 'reactn';
import { Dropdown } from 'primereact/dropdown';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
const AppTopbar = (props) => {
    const [ciclos] = useGlobal("ciclos");
    const [cicloObj, setCicloObj] = useGlobal("cicloObj");
    const [habilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [nombreModulo] = useGlobal("nombreModulo");
    const [ultimaCarga, setUltimaCarga] = useGlobal("ultimaFechaCargaCumplimiento");
    const [, setShowPantallaDistribucion] = useGlobal("showPantallaDistribucion");
    const [showGuardadoToast, setShowGuardadoToast] = useGlobal("showGuardadoToast");

    useEffect(() => {//Obtener datos de resumen una vez que he hecho distribución en la pantalla de distribución de cajas
        const urlPartes = window.location.href.split("/")
        if (!urlPartes.includes("cumplimiento") || !urlPartes.includes("consultar") && ultimaCarga) {
            setUltimaCarga(null);
            setShowPantallaDistribucion(false)
        }
    }, [window.location.href])

    useEffect(() => {//Mostrar toast al guardar un item, si no hay milisegundos entonces por defecto se muestra para 3 segundos
        if (showGuardadoToast) {
            const timeoutMS = showGuardadoToast === true ? 3000 : showGuardadoToast
            setTimeout(() => {
                setShowGuardadoToast(false)
            }, timeoutMS);
        }
    }, [showGuardadoToast])

    return (
        <div className={`layout-topbar clearfix ${cicloObj?.activo ? "" : "pasado"} `}>
            <button className="p-link layout-menu-button" onClick={props.onToggleMenu}>
                <span className="pi pi-bars" />
            </button>
            <div className="layout-topbar-title">
                <span>{nombreModulo}
                    {!cicloObj?.activo &&
                        <React.Fragment>
                            <span> {`(Ciclo finalizado${cicloObj?.clausurado ? " + clausurado" : ""})`}</span>
                            <Button type='button' label='?' className="btn-line btn-help" tooltip={`Estás consultando un ciclo finalizado${cicloObj?.clausurado ? " y clausurado" : ""}`} tooltipOptions={{ position: 'bottom' }} />
                        </React.Fragment>}
                </span>
            </div>
            <Dropdown options={ciclos} value={cicloObj} onChange={(ev) => { setCicloObj(ev.target.value) }}
                optionLabel="nombre" dataKey="id" disabled={!habilitadoSelectorCiclo} placeholder="Seleccione un ciclo" />
            {!cicloObj?.activo && <Message className="alerta-ciclo" severity="warn" text="Recuerda que estás consultando un ciclo inactivo" />}

            {showGuardadoToast &&
                <div className="p-col-12 p-md-3">
                    <Message className="alerta-ciclo" severity="success" text="Información guardada con éxito" />
                </div>}
        </div>)
}
export default AppTopbar;