import React from 'react';
import { useState, useEffect, useGlobal, setGlobal } from 'reactn';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from "primereact/inputtext";
import { ToggleButton } from "primereact/togglebutton";
import {
    obtenerFiltrosVentasNacionalesTodos, obtenerRegistrosEntradasPorSemanaTodos, obtenerRegistrosSalidas,
    getEntradaskgTotales, getSalidaskgTotales
} from "../../service/VentaNacional";
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { Dialog } from "primereact/dialog";
import MensajeTransferisteModal from "./modals/MensajeTransferisteModal"
import TransferirKilosRestantesModal from "./modals/TransferirKilosRestantesModal"
import ContenedorFiltro from '../common/ContenedorFiltro'
import SeccionFiltros from '../common/SeccionFiltros'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row'
import { getProductoYVariedadString, sumarNumerosArrayPorCampo, separadoComas } from '../../util/functions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import ContenedorFiltroBusqueda from '../common/ContenedorFiltroBusqueda'
import ContenedorHeader from '../common/ContenedorHeader'
import { noHaSidoClausurado } from '../../service/Ciclos';

import icono from '../../images/icons/external-icon.svg';
import { flexDate } from 'service/fechas';


setGlobal({ registrosSalidas: false, invernaderosFiltrosVentaNacional: [], datosCargadosVentaNacional: false })
const TablaResumenEntradasSalidas = (props) => {
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [, setCargando] = useGlobal("cargando")
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [ciclo,] = useGlobal("ciclo");
    const [ciclos] = useGlobal("ciclos");
    const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector();
    const [currentWeek, setCurrentWeek] = useState({});
    const [datosCargados] = useGlobal("datosCargadosVentaNacional")

    const [registrosSalidas, setRegistrosSalidas] = useGlobal("registrosSalidas")
    const [registrosSemanas, setRegistrosSemanas] = useState([])
    const [lineas, setLineas] = useState([])
    const [lineasFiltrados, setLineasFiltrados] = useState([])

    //opciones de filtros
    const [invernaderosOpciones] = useGlobal("invernaderosVirtuales")
    const [productosYVariedades] = useGlobal("productosYVariedades")
    //variables de filtros
    const [invernaderosFiltros, setInvernaderosFiltros] = useState([]);
    const [, setInvernadero] = useGlobal("invernadero")

    ///Datos de transferir
    const [transferirModalOpen, setTransferirModalOpen] = useState(false)
    const [hasTransferredModalOpen, setHasTransferredModalOpen] = useState(false)
    const [hasTransferredText, setHasTransferredText] = useState("")
    const [totalCicloToggle, setTotalCicloToggle] = useState(false)

    useEffect(() => {
        setNombreModulo("Ventas Nacionales");
        setHabilitadoSelectorCiclo(true)
    }, [])
    useEffect(() => {//Esto se va llamar despues de cambiar el ciclo
        if (invernaderosOpciones) {
            setRegistrosSalidas(undefined)
            setInvernaderosFiltros(invernaderosOpciones.map(el => el.id))
            updateCicloWeekSelector(ciclo);
        }
    }, [invernaderosOpciones])

    useEffect(() => {
        if (weeks.length > 0)
            setCurrentWeek(weeks[weeks.length - 1])
    }, [weeks])
    useEffect(() => {
        setCargando(true)

        if (!datosCargados && week.nombre != "Cargando")
            obtenerFiltrosVentasNacionalesTodos()
        else if (datosCargados && week.nombre != "Cargando")
            obtenerDatosIniciales()
    }, [datosCargados, week])
    useEffect(() => {
        if (!hasTransferredModalOpen)
            setHasTransferredText("")
    }, [hasTransferredModalOpen])

    useEffect(() => {//Filtrar lineas de la tabla por invernaderos seleccionados en el multiselect
        let filtered = lineas.filter(el => invernaderosFiltros.includes(el.invernadero.id))
        setLineasFiltrados(filtered)
    }, [lineas, invernaderosFiltros]);

    const registroEsDeSemanaYCiclo = (registro, week) => (registro.week.time === week.time && registro.ciclo === ciclo)
    const obtenerDatosIniciales = async () => {
        let registrosDeSemana = registrosSemanas.find(el => registroEsDeSemanaYCiclo(el, week))
        let allSalidas = registrosSalidas

        if (!registrosDeSemana) {

            if (!registrosSalidas) {
                allSalidas = await obtenerRegistrosSalidas(ciclo)
                setRegistrosSalidas(allSalidas)
            }

            const timestamps = flexDate(week.time)
            let salidas = allSalidas.filter(salida => salida.week.time >= timestamps.inicio && salida.week.time <= timestamps.fin)
            let entradas = await obtenerRegistrosEntradasPorSemanaTodos(ciclo, week, invernaderosOpciones)

            registrosDeSemana = { ciclo, week, entradas, salidas, allSalidas }
            setRegistrosSemanas([...registrosSemanas, registrosDeSemana])
        }
        let tablaLineas = procesarDatosTabla(registrosDeSemana)

        setCargando(false)
        setLineas(tablaLineas)
    }
    const procesarDatosTabla = (registros) => {
        return invernaderosOpciones.map((invernadero, index) => {
            let entradasTotalSemana = getEntradaskgTotales(registros.entradas[index])
            let salidasTotalSemana = getSalidaskgTotales(registros.salidas, invernadero.id)
            let salidasTotalCiclo = getSalidaskgTotales(registros.allSalidas, invernadero.id)

            return {
                invernadero,
                entradasTotalSemana,
                salidasTotalSemana,
                kilosRestantes: entradasTotalSemana - salidasTotalSemana,
                salidasTotalCiclo
            }
        })
    }

    const refreshSemanaActual = () => {
        let registrosPasados = registrosSemanas.filter(registro => !registroEsDeSemanaYCiclo(registro, currentWeek))

        setRegistrosSemanas(registrosPasados)
        setCargando(false)
        setHasTransferredModalOpen(true)
    }

    const footerBody = (data) => <span>{separadoComas(data)}<span className="label-unidad">Kg</span></span>
    const footerTablaTotales = () => {
        let entradasTotal = sumarNumerosArrayPorCampo(lineasFiltrados, "entradasTotalSemana")
        let salidasTotal = sumarNumerosArrayPorCampo(lineasFiltrados, "salidasTotalSemana")
        let kilosRestantesTotal = sumarNumerosArrayPorCampo(lineasFiltrados, "kilosRestantes")
        let salidasCicloTotal = sumarNumerosArrayPorCampo(lineasFiltrados, "salidasTotalCiclo")

        return (<ColumnGroup>
            <Row>
                <Column footer="Kg totales" colSpan={1} />
                <Column footer={footerBody(entradasTotal)} colSpan={1} />
                <Column footer={footerBody(salidasTotal)} colSpan={1} />
                <Column footer={footerBody(kilosRestantesTotal)} colSpan={1} />
                {totalCicloToggle && <Column footer={footerBody(salidasCicloTotal)} colSpan={1} />}
            </Row>
        </ColumnGroup>)
    }

    const invernaderoBodyHTML = (rowData) => {
        let inv = rowData.invernadero
        return (datosCargados && inv) ?
            <Button className="invisible-button" tooltip={getProductoYVariedadString(inv)}>
                <div className={`icon-general icon-mini icon-lort icon-click bor-${inv.color} bg-${inv.color}`}>
                    {inv.es_externo ? <img width="30" alt="icono" src={icono} /> : inv.nombre}
                </div>
            </Button>
            : <div />
    }
    const unidadBodyHTML = (rowData, column) => <div>{separadoComas(rowData[column.field])}<span className="label-unidad">kg</span></div>
    const irRegistroEntrada = (event) => {
        setInvernadero(event.data.invernadero)
        props.history.push("/ventas_nacionales/tabla_registros_entradas")
    }

    return (
        <div className="p-grid p-justify-center">
            <ContenedorHeader col="p-col-auto p-col" titulo="Resumen de ventas nacionales" subtitulo="Inventario de entradas y salidas" iconos={"mexico-icon"} />

            <div className="p-col p-col-auto">
                <div className="btn-row">
                    {weeks.length > 0 && week.time !== currentWeek.time && <Button className="reactbtn-icon" onClick={() => setTransferirModalOpen(true)} label="Transferir Entradas"></Button>}
                    {noHaSidoClausurado(ciclo, ciclos) && <Button className="reactbtn-icon" onClick={() => props.history.push(`/ventas_nacionales/tabla_registros_salidas`)} label="Realizar Venta"></Button>}
                </div>
            </div>

            <SeccionFiltros >
                <ContenedorFiltro label="invernadero">
                    <MultiSelect id="filtroInvernadero" optionLabel="nombre" optionValue="id"
                        filter={false} selectedItemsLabel={`${invernaderosFiltros.length} invernaderos seleccionados`}
                        options={invernaderosOpciones} value={invernaderosFiltros}
                        onChange={(e) => setInvernaderosFiltros(e.target.value)} />
                </ContenedorFiltro>
                <ContenedorFiltro label="datos en resumen">
                    <ToggleButton className="reactbtn-icon" onLabel="Ver total de ciclo" offLabel="Ver total de semana" checked={totalCicloToggle} onChange={(ev) => setTotalCicloToggle(totalCicloToggle ? false : true)} ></ToggleButton>
                </ContenedorFiltro>

                <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
            </SeccionFiltros>

            <div className="p-col-12">
                <div className="p-grid ">
                    <div className="p-col-12">
                        <div className="p-col p-col-auto">
                            <div className="card p-0">
                                <DataTable selectionMode="single" value={lineasFiltrados} onRowClick={irRegistroEntrada} footerColumnGroup={footerTablaTotales()} emptyMessage="No hay resultados" sortable={true} >
                                    <Column field="invernadero" header="Invernadero" className="col__label" sortable={true} body={invernaderoBodyHTML} />
                                    <Column field="entradasTotalSemana" header="Inventario de entrada" sortable={true} body={unidadBodyHTML} />
                                    <Column field="salidasTotalSemana" header="Inventario de salida" sortable={true} body={unidadBodyHTML} />
                                    <Column field="kilosRestantes" header="Kilos disponibles de la semana" sortable={true} body={unidadBodyHTML} />
                                    {totalCicloToggle && <Column field="salidasTotalCiclo" header="Acumulado de salidas en el ciclo" sortable={true} body={unidadBodyHTML} />}
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <TransferirKilosRestantesModal visible={transferirModalOpen} onHide={() => setTransferirModalOpen(false)} setHasTransferredText={(texto) => setHasTransferredText(texto)} setHasTransferredModalOpen={(val) => setHasTransferredModalOpen(val)} refreshDatos={refreshSemanaActual} lineas={lineas} week={week} currentWeek={currentWeek} />
            <MensajeTransferisteModal visible={hasTransferredModalOpen} onHide={() => setHasTransferredModalOpen(false)} hasTransferredText={hasTransferredText} />
        </div >
    );
}
export default TablaResumenEntradasSalidas //231