import React from 'react';
import { useState, useEffect, useGlobal, setGlobal } from 'reactn';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from "primereact/inputtext";
import { Calendar } from 'primereact/calendar';
import { ToggleButton } from "primereact/togglebutton";
import { Dropdown } from 'primereact/dropdown';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row'
import ContenedorFiltro from '../common/ContenedorFiltro'
import SeccionFiltros from '../common/SeccionFiltros'
import { obtenerFiltrosVentasNacionalesTodos, obtenerRegistrosSalidas, getSalidasPrecioTotal, getSalidaskgTotales, getSalidaPrecio, getVariedadDeMedleysOExterno } from "../../service/VentaNacional";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { miliSegundosDia, numeroMaximo } from '../../constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import ContenedorHeader from '../common/ContenedorHeader'
import { separadoComas, separadoComasDinero, getMomentFormatted, dateToUnixTimeAsNumber13, getNombrePorID, getObjetoPorID } from "../../util/functions";
import ContenedorPrincipal from '../common/ContenedorPrincipal';
import RowBotonesTop from '../common/RowBotonesTop';
import ContenedorBotonesTop from '../common/ContenedorBotonesTop';
import { flexDate } from '../../service/fechas'

setGlobal({ productosFiltros: [], clientesVentaNacional: [], invernaderosFisicosFiltrosVentaNacional: [], })
const TablaRegistrosSalidas = (props) => {
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [, setCargando] = useGlobal("cargando")
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [ciclo] = useGlobal("ciclo");
    const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector();
    const [datosCargados] = useGlobal("datosCargadosVentaNacional")
    const [toggleSemanas, setToggleSemanas] = useState(true);
    const [, setRegistrosSalidas] = useGlobal("registrosSalidas")
    const [salidaLineas, setSalidaLineas] = useState([])
    const [salidaLineasFiltrados, setSalidaLineasFiltrados] = useState([])

    //opciones de filtros
    const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales")
    const [productosOpciones] = useGlobal("productosVariedadesOpciones");
    const [invernaderosFisicos] = useGlobal("invernaderosFisicos");
    const [invernaderosFisicosOpciones, setInvernaderosFisicosOpciones] = useState([])
    const [clientes] = useGlobal("clientesVentaNacional")

    //variables de filtros
    const [globalFilter, setGlobalFilter] = useState("")
    const [fechaFiltroInicio, setFechaFiltroInicio] = useState("")
    const [fechaFiltroFinal, setFechaFiltroFinal] = useState("")
    const [productosVariedadesFiltros, setProductosVariedadesFiltros] = useGlobal("productosFiltros");
    const [invernaderosFisicosFiltros, setInvernaderosFisicosFiltros] = useGlobal("invernaderosFisicosFiltrosVentaNacional");
    const [clienteFiltro, setClienteFiltro] = useGlobal("clienteFiltroVentaNacional")

    useEffect(() => {
        setNombreModulo("Ventas Nacionales");
        setHabilitadoSelectorCiclo(true)
    }, [])
    useEffect(() => {
        if (invernaderosVirtuales?.length > 0) {
            let invernaderosExternos = invernaderosVirtuales.filter(el => el.es_externo) || [];
            setInvernaderosFisicosOpciones([...invernaderosFisicos, ...invernaderosExternos]);

            let invernaderosFisicosFiltroados = invernaderosFisicos.map(el => el.id) || [];
            invernaderosExternos = invernaderosExternos.map(el => el.id);
            setInvernaderosFisicosFiltros([...invernaderosFisicosFiltroados, ...invernaderosExternos]);
        }
    }, [invernaderosVirtuales])
    useEffect(() => {
        if (ciclo) updateCicloWeekSelector(ciclo);
    }, [ciclo])
    useEffect(() => {
        setCargando(true)

        if (!datosCargados && invernaderosVirtuales)
            obtenerFiltrosVentasNacionalesTodos(ciclo)
        else if (datosCargados)
            obtenerDatosIniciales()

    }, [datosCargados, invernaderosVirtuales])

    useEffect(() => {
        if (week.nombre !== "Cargando") {
            let lineasParaFiltrar = toggleSemanas ? salidaLineas.filter(salida => {
                const timestamps = flexDate(salida.allData.week.time)
                return timestamps.inicio <= week.time && timestamps.fin >= week.time}) 
                : salidaLineas
            let lineasFiltrados = lineasParaFiltrar.filter(linea => checarCondicionesDeIncluirSalida(linea))
            setSalidaLineasFiltrados(lineasFiltrados)
        }
    }, [week, toggleSemanas, salidaLineas, productosVariedadesFiltros, fechaFiltroInicio, fechaFiltroFinal, invernaderosFisicosFiltros, clienteFiltro]);

    const checarCondicionesDeIncluirSalida = (salida) => {
        let selectedFechaInicio = (!fechaFiltroInicio || toggleSemanas) ? 1 : dateToUnixTimeAsNumber13(fechaFiltroInicio)
        let selectedFechaFinal = (!fechaFiltroFinal || toggleSemanas) ? numeroMaximo : (dateToUnixTimeAsNumber13(fechaFiltroFinal) + miliSegundosDia)

        let itemInvernaderos = salida.items.map(item => getObjetoPorID(invernaderosVirtuales, item.invernaderoVirtual))
        let itemVariedes = itemInvernaderos.map(inv => (inv.es_externo || inv.mezcla) ? getVariedadDeMedleysOExterno(productosOpciones, inv.producto_ref) : inv.variedad_ref)

        let itemInvFisicos = salida.items.map(item => getObjetoPorID(invernaderosVirtuales, item.invernaderoVirtual))
        itemInvFisicos = itemInvFisicos.map(inv => inv.es_externo ? inv.id : inv.invernadero_fisico_ref)

        return ((salida.allData.cliente === clienteFiltro || clienteFiltro === "Todos")
            && (itemVariedes.some(item => productosVariedadesFiltros.includes(item)))
            && (itemInvFisicos.some(item => invernaderosFisicosFiltros.includes(item)))
            && salida.fecha >= selectedFechaInicio && salida.fecha <= selectedFechaFinal)
    }
    const obtenerDatosIniciales = async () => {
        let registrosDeSalida = await obtenerRegistrosSalidas(ciclo)
        let tablaLineas = procesarDatosTabla(registrosDeSalida)

        setCargando(false)
        setSalidaLineas(tablaLineas)
        setRegistrosSalidas(registrosDeSalida)
    }
    const procesarDatosTabla = (registrosDeSalida) => {
        return registrosDeSalida.map(salida => ({
            orden_num: salida.orden_num,
            fecha: salida.dia.time * 1000,
            cliente: getNombrePorID(clientes, salida.cliente),
            tipo: salida.tipo,
            items: salida.items,
            precioTotal: "$" + separadoComasDinero(getSalidaPrecio(salida.items)),
            estatus: salida.estatus,
            allData: { ...salida }
        }))
    }
    const getVariedadInvernaderoExterno = (producto_ref) => {
        let variedad = productosOpciones.find(el => el.producto_ref === producto_ref) || {};
        return variedad.id || ""
    }

    const obtenerFooter = () => {
        let precioTotal = getSalidasPrecioTotal(salidaLineasFiltrados)
        let kgsTotal = getSalidaskgTotales(salidaLineasFiltrados)

        return (<ColumnGroup>
            <Row>
                <Column footer="Totales" colSpan={1} />
                <Column footer={<span></span>} colSpan={1} />
                <Column footer={<span></span>} colSpan={1} />
                <Column footer={<span></span>} colSpan={1} />
                <Column footer={<span>{separadoComas(kgsTotal)}<span className="label-unidad">kg</span></span>} colSpan={1} />
                <Column footer={<span>${separadoComasDinero(precioTotal)}</span>} colSpan={1} />
                <Column footer={<span></span>} colSpan={1} />
            </Row>
        </ColumnGroup>)
    }

    const itemsResumenHTML = (rowData) => {
        return rowData.items.map(item => {
            let currInvernadero = getNombrePorID(invernaderosVirtuales || [], item.invernaderoVirtual)
            return <ul className="titles-group">
                <li>{currInvernadero} - {item.kilosVender}kg{parseFloat(item.precio) > 0.00 && ` - $${item.precio}`}</li>
            </ul>
        })
    }

    return (
        <ContenedorPrincipal>
            <ContenedorHeader col="p-col-auto" titulo="Reporte de salidas de ventas nacionales" iconos={"mexico-icon"} atras={() => props.history.goBack()} />

            <ContenedorBotonesTop>
                <RowBotonesTop>
                    <Button onClick={() => props.history.push("/ventas_nacionales/registro_salida/crear")} label="Registro de salida"></Button>
                </RowBotonesTop>
            </ContenedorBotonesTop>
            <SeccionFiltros >
                <div className="search-field form-group p-col">
                    <FontAwesomeIcon icon={faSearch} />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar" />
                </div>

                <ContenedorFiltro col="2"  label="invernadero Fisico">
                    <MultiSelect id="filtroInv" optionValue="id" optionLabel="nombre"
                        filter={false} selectedItemsLabel={`${invernaderosFisicosFiltros.length} invernaderos seleccionados`}
                        options={invernaderosFisicosOpciones} value={invernaderosFisicosFiltros}
                        onChange={(e) => setInvernaderosFisicosFiltros(e.target.value)} />
                </ContenedorFiltro>

                <ContenedorFiltro col="2"  label="producto">
                    <MultiSelect id="filtroProductos" optionValue="id" optionLabel="nombre" filter={true}
                        selectedItemsLabel={`${productosVariedadesFiltros.length} productos seleccionados`}
                        options={productosOpciones} value={productosVariedadesFiltros}
                        onChange={(e) => setProductosVariedadesFiltros(e.target.value)} />
                </ContenedorFiltro>

                <ContenedorFiltro col="2"  label="cliente">
                    <Dropdown id="filtroInvernadero" placeholder="Seleccionar"
                        optionValue="id" optionLabel="nombre" filter={true}
                        options={[...clientes, { id: "Todos", nombre: "Todos" }]} value={clienteFiltro}
                        onChange={(e) => setClienteFiltro(e.target.value)} />
                </ContenedorFiltro>
                <ContenedorFiltro col="2"  label="por semana o ciclo">
                    <ToggleButton className="reactbtn-icon" onLabel="Semanas" offLabel="Rango de Fechas" checked={toggleSemanas} onChange={(ev) => setToggleSemanas(ev.value)} ></ToggleButton>
                </ContenedorFiltro>
                {toggleSemanas ?
                    <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
                    :
                    <>
                        <ContenedorFiltro col="2" label="Fecha Inicio">
                            <Calendar id="fechaInicio" readOnlyInput={true} dateFormat="dd-mm-yy" //locale={ CALENDAR_LOCALE_ES_PRIME_TRADUCTION }
                                value={fechaFiltroInicio} monthNavigator={true} yearNavigator={true} yearRange="1900:2030"
                                showButtonBar={true} onClearButtonClick={() => { setFechaFiltroInicio("") }}
                                onChange={e => setFechaFiltroInicio(e.value)} //e.value.toDateString() + " 00:00:00 " + "CST-0600 (Central Standard Time)"
                            />
                        </ContenedorFiltro>
                        <ContenedorFiltro col="2" label="Fecha Final">
                            <Calendar id="fechaFinal" readOnlyInput={true} dateFormat="dd-mm-yy" //locale={ CALENDAR_LOCALE_ES_PRIME_TRADUCTION }
                                value={fechaFiltroFinal} monthNavigator={true} yearNavigator={true} yearRange="1900:2030"
                                showButtonBar={true} onClearButtonClick={() => { setFechaFiltroFinal("") }}
                                onChange={e => setFechaFiltroFinal(e.value)} />
                        </ContenedorFiltro>
                    </>}
            </SeccionFiltros>

            <div className="p-col-12">
                <div className="p-grid p-fluid ">
                    <div className="p-col-12">
                        <div className="p-col p-col-auto">
                            <div className="card p-0">
                                <DataTable selectionMode="single" value={salidaLineasFiltrados} className="" onRowClick={(event) => props.history.push(`/ventas_nacionales/registro_salida/modificar/${event.data.allData.id}`)}
                                    globalFilter={globalFilter} emptyMessage="No hay resultados" footerColumnGroup={obtenerFooter()} sortable={true}>
                                    <Column field="orden_num" header="# de Venta" sortable={true} />
                                    <Column field="fecha" header="Fecha" sortable={true} body={(rowData) => <div>{getMomentFormatted(rowData.fecha, "DD/MM/YYYY")}</div>} />
                                    <Column field="cliente" header="Cliente" sortable={true} />
                                    <Column field="tipo" header="Tipo" sortable={true} />
                                    <Column field="items" header="Items" sortable={true} body={itemsResumenHTML} />
                                    <Column field="precioTotal" header="Precio total" sortable={true} />
                                    <Column field="estatus" header="Status del orden" sortable={true} />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </ContenedorPrincipal>
    );
}
export default TablaRegistrosSalidas