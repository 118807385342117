import React, { useState, useEffect, useGlobal, setGlobal } from "reactn";
import WeekSelector, { useWeekSelector } from "../common/WeekSelector";
import { Button } from "primereact/button";
import { obtenerClientes, obtenerClientesCiclo } from "../../service/Clientes";
import { MultiSelect } from "primereact/multiselect";
import ColumnaDia from "./ColumnaDia";
import { obtenerDiasDeSemana, obtenerNombreDiaSemana } from "../../service/fechas";
import { obtenerOrdenesSemana } from "../../service/Ordenes";
import { obtenerPresentaciones } from "../../service/Presentaciones";
import ContenedorHeader from "../common/ContenedorHeader";
import { DeepClone, getObjetoPorID } from "../../util/functions";
import SeccionFiltros from "components/common/SeccionFiltros";
import ContenedorFiltro from "components/common/ContenedorFiltro";
import moment from "moment";

const Cards = (props) => {
    const [ciclo] = useGlobal("ciclo");
    const [usuario] = useGlobal("usuario");
    const [, setCargando] = useGlobal("cargando");
    const [, setMostrarModalPermiso] = useGlobal("mostrarModalPermiso");
    const [invernaderos] = useGlobal("invernaderosVirtuales");
    const [clientes] = useGlobal("clientes");
    const [presentaciones, setPresentaciones] = useGlobal("presentaciones");

    const [weeks, week, handleWeekChange] = useWeekSelector(true, true);
    const [invernaderosFiltro, setInvernaderosFiltro] = useState([])
    const [clientesCiclo, setClientesCiclo] = useState()
    const [clientesFiltro, setClientesFiltro] = useState()
    const [columnasDias, setColumnasDias] = useState([])
    const [allOrdenesSemanas, setAllOrdenesSemanas] = useState({});

    const puedeEditar = () => usuario && ["Administrador", "Administrador Empaque", "Administrador Embarques"].includes(usuario.rol);

    useEffect(() => {
        setGlobal({ habilitadoSelectorCiclo: true, nombreModulo: "Órdenes de Venta", ordenVenta: null, camionesOnHold: null });
    }, [])
    useEffect(() => {
        if (ciclo) { obtenerDatosIniciales(true); }
    }, [ciclo, obtenerDatosIniciales])
    useEffect(() => {
        if (ciclo && week?.nombre !== "Cargando" && clientesCiclo) { obtenerDatosSemana(); }
    }, [week, clientesCiclo, ciclo, obtenerDatosSemana])

    const obtenerDatosIniciales = async () => {
        setCargando(true);
        const clientesCiclo = await obtenerClientesCiclo(ciclo);
        const clientesOpciones = clientesCiclo.map(el => getObjetoPorID(clientes, el.cliente_ref));
        const presentacionesResp = presentaciones || await obtenerPresentaciones();
        setClientesCiclo(clientesOpciones);
        setPresentaciones(presentacionesResp);
    }
    const obtenerDatosSemana = async () => {
        setCargando(true);
        setClientesFiltro(clientesCiclo.map(el => el.id));
        setInvernaderosFiltro(invernaderos.map(el => el.id));

        await obtenerDiasSemanasCards();
        setCargando(false);
    }

    const obtenerDiasSemanasCards = async () => {
        try {
            const allOrdenesCopia = DeepClone(allOrdenesSemanas);
            const ordenesSemana = allOrdenesCopia[`${ciclo}-${week.time}`] || await obtenerOrdenesSemana(ciclo, week.time);
            allOrdenesCopia[`${ciclo}-${week.time}`] = ordenesSemana;

            const diasOrdenes = procesarOrdenes(ordenesSemana);
            setAllOrdenesSemanas(allOrdenesCopia);
            setColumnasDias(diasOrdenes);
        } catch (err) {
            if (err?.code === "permission-denied") { setMostrarModalPermiso(true); }
            console.log(`ERROR OBTENER ORDENES SEMANA: code - ${err.code}; msg - ${err.message}; error-full: `, err);
        }
    }

    const isSameDay = (timestamp1, timestamp2) => {
        const date1 = moment(timestamp1*1000)
        const date2 = moment(timestamp2*1000)
        let diff = date2.diff(date1, 'hours')
        if(diff <= 1 && diff >= -1){
            return true
        }else{
            return false
        }
    }

    const procesarOrdenes = (ordenes = []) => {
        const diasSemana = obtenerDiasDeSemana(week.time);
        const diasOrdenes = diasSemana.map((dia) => ({ dia, ordenes: [], nombre: obtenerNombreDiaSemana(dia) }));
        ordenes.forEach(orden => {
            let dia = diasOrdenes.find(dia => isSameDay(dia.dia,(parseInt(orden.fecha_envio))));
            if (dia) { dia.ordenes.push(orden); }
        });
        return diasOrdenes;
    }

    const clienteTemplate = (option) => {
        return (
            <div>
                <img className="cliente-image cliente-image--dropdown" alt={option?.label} src={option?.imagen} />
                <span className="cliente-name--dropdown">{option?.nombre}</span>
            </div>);
    }

    const atras = props.history.location?.data?.cumplimiento && props.history.goBack;
    return (
        <div className="p-grid">
            <ContenedorHeader titulo="Órdenes de venta" subtituloInv="Órdenes de la semana" iconos="boxes-icon" atras={atras} col="p-col-auto p-col" />

            <div className="p-col p-col-auto">
                <div className="btn-row">
                    <Button label="Cumplimiento de la semana" onClick={() => { props.history.push("/cumplimiento/consultar") }} />
                    {puedeEditar() && <Button label="Agregar nueva orden de venta" onClick={() => { props.history.push("/ordenes/registrar") }} />}
                </div>
            </div>

            <SeccionFiltros >
                <ContenedorFiltro label="clientes">
                    <MultiSelect options={clientesCiclo} value={clientesFiltro} onChange={(e) => { setClientesFiltro(e.value); }} optionValue="id" optionLabel="nombre"
                        placeholder={`${clientesFiltro?.length}/${clientesCiclo?.length} clientes seleccionadas`} itemTemplate={clienteTemplate} fixedPlaceholder={true} />
                </ContenedorFiltro>
                <ContenedorFiltro label="invernaderos">
                    <MultiSelect options={invernaderos} value={invernaderosFiltro} onChange={(e) => { setInvernaderosFiltro(e.target.value); }} optionValue="id" optionLabel="nombre"
                        placeholder={`${invernaderosFiltro?.length}/${invernaderos?.length} invernaderos seleccionados`} fixedPlaceholder={true} />
                </ContenedorFiltro>

                <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
            </SeccionFiltros>

            <div className="p-col-12">
                <div className="p-grid">
                    {columnasDias?.map((infoDia, i) => <ColumnaDia key={`dia-${i}`} infoDia={infoDia} invernaderosFiltro={invernaderosFiltro} clientesFiltro={clientesFiltro} />)}
                </div>
            </div>
        </div>)
}
export default Cards;//163