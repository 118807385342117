import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import excelLogo from '../../images/excel_logo.png'

const ArchivoPreview = (props) => {
    const { URL, tipo } = props.archivo
    const isPDF = tipo == "pdf"
    const isExcel = tipo == "excel"

    return <div className="presentacion-img pos-relative" onClick={props.verArchivo} >
        {isPDF ? <iframe className="w-100 h-100" src={URL} /> :
            <img src={isExcel ? excelLogo : URL} className="w-100 h-100" />}
        {props.puedeEditar && <button className="p-button button-trash" onClick={props.deleteArchivo} >
            <FontAwesomeIcon icon={faTrashAlt} />
        </button>}
        {props.isPDF && <div className="w-100 h-100 iframe-click" />}
    </div>
}
export default ArchivoPreview;
