import React, { useGlobal } from 'reactn';
import { withRouter } from 'react-router-dom';
import { getProductoYVariedadStringDiagonal, separadoComas } from '../../util/functions';
import HTGCard from '../common/cards/HTGCard';
import DatoCard from '../common/cards/DatoCard';
import Column from '../common/cards/Column';

const InvernaderoCard = (props) => {
  //const [invernadero, setInvernadero] = useGlobal("invernadero");
  const { esExterno, color, invernaderoFisico, nombre, habilitado, id: invernaderoID } = props.invernadero
  const consultar = () => {
    props.history.push(`/administrar/invernadero_virtual/modificar/${invernaderoID}`);
  }

  const header = esExterno
    ? <span className="card-header"><div className={`card-id icon-general icon-id bor-${color} bg-${color}`}>{nombre}</div><div></div></span>
    : <span className="card-header"><div className={`card-id icon-general icon-id bor-${color} bg-${color}`}>{nombre}</div><div><p className="card-up">{habilitado ? "" : "Deshabilitado"}</p>
      <p className="card-down">{invernaderoFisico ? invernaderoFisico.nombre : ""}</p></div></span>;

  let dims = esExterno ? "" : props.invernadero.detalles_fisicos.find(detalle => detalle.detalle === "Area (m²)")["totales"];
  dims = esExterno ? "" : separadoComas(dims.split(" ")[0]) + " " + dims.split(" ")[1];
  let className = `htg-card bor-${color} ${habilitado ? "Habilitado" : "Deshabilitado"}`;

  return (
    <HTGCard header={header} onClick={consultar} headerClassName={className}>
      <Column>
        <DatoCard label="DIMENSIONES" valor={dims} />
        <DatoCard label="EMPLEADOS" valor={props.invernadero.numero_maximo_trabajadores} />
      </Column>
      <Column>
        <DatoCard label="CLIENTE" valor={props.invernadero.cliente ? props.invernadero.cliente.nombre : ""} />
        <DatoCard label="PRODUCTO/VARIEDAD" valor={getProductoYVariedadStringDiagonal(props.invernadero)} />
      </Column>
    </HTGCard>);
}
export default withRouter(InvernaderoCard); //48