import React, { useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import { useGlobal } from 'reactn';
import { withRouter } from 'react-router-dom';
import { dosDecimales, separadoComas } from '../../../util/functions';
import HTGCard from '../../common/cards/HTGCard';
import DatoCard from '../../common/cards/DatoCard';
import icono from '../../../images/icons/external-icon.svg';

const CardGrupo = (props) => {
    const [tipoGrupoProveedor, setTipoGrupoProveedor] = useGlobal("tipoGrupoProveedor");
    const consultar = () => {
        let path = props.path || "/cosecha/compra_externa/cards_detalles";
        setTipoGrupoProveedor(props.tipo);
        props.history.push(path);
    }

    const header = <span className="card-header">
        <div className={`card-id icon-general icon-lort icon-id bg-main-blue bor-main-blue `} >
            <img width="30" alt="icono" src={icono} />
        </div>
        <p className="card-up">{props.titulo}</p>
        <div>
        </div>
    </span>;
    const realvssemanal = props.datos?.realvssemanal
    const realvsdiario = props.datos?.realvsdiario
    return (
        <HTGCard headerClassName={`htg-card bor-main-blue`} onClick={consultar} header={header}>
            <DatoCard label="kg/m2 Semanal"
                valor={`${separadoComas(dosDecimales(props.datos["semanalkgm2"]))} kg`} />
            <DatoCard label="kg/m2 Real"
                valor={`${separadoComas(dosDecimales(props.datos["realkgm2"]))} kg`} />
            <DatoCard label="Real vs. Semanal"
                valor={`${realvssemanal === Infinity || realvssemanal===0 ? "-" : separadoComas(dosDecimales(realvssemanal))} %`} />
            <DatoCard label="Real vs. Diario"
                valor={`${realvsdiario === Infinity || realvsdiario===0 ? "-" : separadoComas(dosDecimales(realvsdiario))} %`} />
        </HTGCard>)
}
export default withRouter(CardGrupo);