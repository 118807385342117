import * as firebase from 'firebase/app';
import "firebase/firestore";
import {uploadStatistics} from './Estadisticas'


export const obtenerDefectos = (params) => {
   const db = firebase.firestore();
    return new Promise((resolve, reject) => {
        db.collection("defectos").orderBy("nombre").get().then((result) => {
            uploadStatistics(result.size)
            let rows = [];
            result.forEach(item => {
                let data = item.data();
                data.id = item.id;
                rows.push(data);
            });
           resolve(rows) 
        }).catch((err) => {
            reject(err)
        });    
    });
    
}

