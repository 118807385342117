import * as firebase from 'firebase/app'

export const log = (data) => {
    try {
        var logMessage = firebase.functions().httpsCallable('logMessage');
        logMessage({data:data}).then((result) => {
            "loggeado"
        }).catch((err) => {
                console.log("ERROR LOG");
        });;
    } catch (error) {
        console.log("error log");   
    }
    
}
