import React from "react"
import DropdownAgrupadoBase from "./DropdownAgrupadoBase.comp";

const DropdownPresentacionesHTG = (props) => {
    const templateClienteGrupo = ({ children, data }) => {
        return (
            <div>
                <img className="cliente-image cliente-image--dropdown ml-05" alt={children} src={data?.cliente?.imagen} />
                <span className="cliente-name--dropdown group-title">{children}</span>
            </div>);
      }
    return (<DropdownAgrupadoBase {...props} GroupHeading={templateClienteGrupo} value={props.value}/>);
}
export default DropdownPresentacionesHTG;