import React, { useState, useEffect, useGlobal } from 'reactn';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import FormularioItem from './FormularioItem';
import { obtenerClientesCiclo } from '../../service/Clientes';
import { obtenerDestinos } from '../../service/Destinos';
import { obtenerFronteras } from '../../service/Fronteras';
import { guardarOrdenVenta, agregarHistorico, manejarNotificacion } from '../../service/Ordenes';
import { getCalendarDate, dateToUnixTimeAsNumber, DeepClone, ordenarArreglaDeObjetosPorLlave } from '../../util/functions';
import { Message } from "primereact/message";
import TablaItems from "./TablaItems";
import { Dialog } from "primereact/dialog";
import { log } from '../../model/Logging';
import { getPresentaciones } from 'service/Presentaciones';
import { obtenerEmpaques } from 'service/Empaques';
import { getFirestoreID } from 'service/Mantenimiento';
import moment from 'moment';

const Registrar = (props) => {
  const [ciclo] = useGlobal("ciclo");
  const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
  const [, setNombreModulo] = useGlobal("nombreModulo");
  const [, setCargando] = useGlobal("cargando");
  const [usuario] = useGlobal("usuario");
  const [presentaciones, setPresentaciones] = useGlobal("presentaciones");
  const [empaques, setEmpaques] = useGlobal("empaques");
  const [clientesOpciones] = useGlobal("clientes");
  const [, setModal] = useGlobal("modal");
  const [orden, setOrden] = useGlobal("ordenVenta");

  const [cliente, setCliente] = useGlobal("clienteSeleccionado");
  const [no_orden, setNoOrden] = useState("");
  const [fecha_envio, setFechaEnvio] = useState("");
  const [destino, setDestino] = useState("");
  const [frontera, setFrontera] = useState("");
  const [items, setItems] = useState([]);

  const [original, setOriginal] = useState({});
  const [clientes, setClientes] = useState([]);
  const [destinos, setDestinos] = useState([]);
  const [fronteras, setFronteras] = useState([]);
  const [modificandoItem, setModificandoItem] = useState(null);
  const [editing, setEditing] = useState(false);
  const [modalItem, setModalItem] = useState(false);
  const [itemsActualizados, setItemsActualizados] = useState(false);
  const [errores, setErrores] = useState({});

  useEffect(() => {
    setHabilitadoSelectorCiclo(false);
    setNombreModulo("Órdenes de Venta");
    setEditing(!!orden);
    if (!!orden) { setOriginal(DeepClone(orden)); }
  }, [])
  useEffect(() => {
    if (ciclo) obtenerDatosIniciales();
  }, [ciclo])
  useEffect(() => {
    if (orden && editing) {
      const { no_orden, fecha_envio, cliente, destino, frontera, items } = orden
      setNoOrden(no_orden);
      setFechaEnvio(fecha_envio);
      setCliente(cliente);
      setDestino(destino);
      setFrontera(frontera);
      setItems(items);
    }
  }, [editing, fronteras])

  useEffect(() => {
    if (!modalItem) { setModificandoItem(null); }
  }, [modalItem])
  useEffect(() => {
    setErrores({})
  }, [no_orden, fecha_envio, cliente, destino, frontera, items])

  const obtenerDatosIniciales = async () => {
    if (!presentaciones || !empaques || !destinos?.length || !fronteras?.length) {
      try {
        const [presentacionesResult, empaquesResult, destinosResult, fronterasResult] =
          await Promise.all([getPresentaciones(), obtenerEmpaques(), obtenerDestinos(), obtenerFronteras()]);
        const clientesCiclo = await obtenerClientesCiclo(ciclo);
        const filtrados = clientesOpciones.filter(cliente => clientesCiclo.some(el => el.cliente_ref === cliente.id));

        setClientes(filtrados);
        setPresentaciones(presentacionesResult);
        setEmpaques(empaquesResult);
        setDestinos(destinosResult);
        setFronteras(fronterasResult);
      } catch (error) {
        console.log("ERROR obtenerDatosIniciales ", error);
      }
    }
  }

  const guardar = async () => {
    if (validarErrores()) {
      setCargando(true);
      const datos = { no_orden, fecha_envio, cliente, destino, frontera, items };
      const ordenId = editing ? orden.id : getFirestoreID();

      try {
        const promises = [guardarOrdenVenta(ciclo, datos, ordenId, editing)];
        if (editing) { promises.push(manejarNotificacion(original, datos, usuario.uid, ciclo), agregarHistorico(original, datos, usuario.uid)); }
        await Promise.all(promises);

        if (editing) props.history.push("/ordenes");
        else props.history.goBack();
      } catch (error) {
        console.log("ERROR AL GUARDAR ", error);
        setModal({ titulo: "ERROR GUARDAR", msj: error.message, hide: () => setModal(false) });
        log({ data: { mensaje: error.message || "", error: error, estado: datos, funcion: "registrar/guardarOrdenVenta" } })
      }

      setOrden(null);
      setCargando(false);
    }
  }

  const agregarItem = (item) => {
    let itemsCopy = DeepClone(items);
    itemsCopy = itemsCopy.slice();
    itemsCopy.push(item);
    ordenarArreglaDeObjetosPorLlave(itemsCopy, "nombre");

    setItems(itemsCopy)
    setModalItem(false);
  }
  const eliminarItemByID = (itemID) => {
    const itemsCopy = DeepClone(items);
    const indexItem = itemsCopy?.findIndex(it => it.itemID === itemID);
    if (indexItem > -1) itemsCopy.splice(indexItem, 1);
    setItems(itemsCopy);
    setItemsActualizados(Date.now());
  }
  const modificarItemByID = (itemID) => {
    const itemsCopy = DeepClone(items);
    const indexItem = itemsCopy?.findIndex(it => it.itemID === itemID);
    const item = itemsCopy[indexItem]
    setModalItem(true);
    setModificandoItem([indexItem, item]);
  }
  const actualizarItem = (rowIndex, item) => {
    const itemsCopy = DeepClone(items);
    itemsCopy[rowIndex] = item;
    setItems(itemsCopy);
    setModalItem(false);
    setModificandoItem(null);
  }

  const validarErrores = () => {
    const requeridos = [{ propiedad: no_orden, nombre: "no_orden" }, { propiedad: fecha_envio, nombre: "fecha_envio" }, { propiedad: cliente, nombre: "cliente" },
    { propiedad: destino, nombre: "destino" }, { propiedad: frontera, nombre: "frontera" }]
    const errores = {};

    requeridos.forEach(({ propiedad, nombre }) => {
      if (!propiedad) errores[nombre] = true;
    })
    setErrores(errores);
    return Object.keys(errores)?.length === 0;
  }

  const handleFechaEnvioChange = (event) => {
    const { name, value } = event.target;
    const fecha = (name === "fecha_envio") ? moment(moment(value).utcOffset("-0600").hour(0).toLocaleString()).unix() : value;
    setFechaEnvio(fecha);
  }

  const puedeEditarCliente = () => {
    const puedeEditarCliente = editing ? (orden?.items?.length === 0) : true;
    return puedeEditarCliente;
  }

  return (
    <div className="p-grid p-justify-center">
      <div className="p-col-12  title-container">
        <span className="back-btn" onClick={props.history.goBack}></span>
        <span className="icon-general boxes-icon"></span>
        <span className="titles-group">
          <h1 className="header-title">{editing ? "Consultar" : "Nueva"} orden de venta</h1>
        </span>
      </div>

      <div className="p-col-12">
        <div className="card">
          <div className="p-grid">
            <div className="form-group p-col-12 p-md-6">
              <label htmlFor="no_orden"># de orden</label>
              <InputText id="no_orden" name="no_orden" value={no_orden} onChange={e => { setNoOrden(e.target.value) }} />
              {errores.no_orden && <Message severity="error" text="Este campo es requerido" />}
            </div>

            <div className="form-group p-col-12 p-md-6">
              <label htmlFor="cliente">Cliente</label>
              <Dropdown id="cliente" placeholder="Seleccionar cliente" optionLabel="nombre" dataKey="id"
                name="cliente" value={cliente || ""} options={clientes} onChange={e => { setCliente(e.target.value) }} disabled={!puedeEditarCliente()} />
              {errores.cliente && <Message severity="error" text="Este campo es requerido" />}
            </div>
            <div className="form-group p-col-12 p-md-6">
              <label htmlFor="destino">Destino</label>
              <Dropdown id="destino" placeholder="Seleccionar destino" filter={true} filterPlaceholder="Buscar destino"
                name="destino" options={destinos} value={destino} optionLabel="nombre" dataKey="id" onChange={e => { setDestino(e.target.value) }} />
              {errores.destino && <Message severity="error" text="Este campo es requerido" />}
            </div>
            <div className="form-group p-col-12 p-md-6">
              <label htmlFor="frontera">Frontera</label>
              <Dropdown id="frontera" name="frontera" onChange={e => { setFrontera(e.target.value) }} value={frontera}
                options={fronteras} optionLabel="nombre" dataKey="id" placeholder="Seleccionar frontera" />
              {errores.frontera && <Message severity="error" text="Este campo es requerido" />}
            </div>

            <div className="form-group p-col-12 p-md-6">
              <label htmlFor="fecha_envio">Fecha de envío</label>
              <Calendar value={getCalendarDate(fecha_envio * 1000)} name="fecha_envio" onChange={handleFechaEnvioChange} dateFormat="dd/mm/yy"></Calendar>
              {errores.fecha_envio && <Message severity="error" text="Este campo es requerido" />}
            </div>
          </div>
        </div>
      </div>

      <Dialog header={modificandoItem ? "Modificar item de orden de venta" : "Agregar item a orden de venta"} visible={modalItem} modal={true} onHide={() => setModalItem(false)}>
        <FormularioItem visible={modalItem} modificandoItem={modificandoItem} agregarItem={agregarItem} actualizarItem={actualizarItem} cancelar={() => { setModalItem(false); }} setItemsActualizados={setItemsActualizados} />
      </Dialog>

      {!editing &&
        <React.Fragment>
          <div className="p-col-12 pb-0">
            <div className="p-grid ">
              <div className="p-col pb-0 p-col-auto title-container">
                <p className="section-title">
                  Items de orden de venta
                </p>
              </div>
              <div className="p-col pb-0 p-col-auto">
                <div className="btn-row">
                  <Button onClick={() => { setModalItem(true) }} label="Agregar Item" />
                </div>
              </div>
            </div>
          </div>
          <TablaItems items={items} eliminarItemByID={eliminarItemByID} modificarItemByID={modificarItemByID} clienteSeleccionado={cliente} />
        </React.Fragment>}

      <div className="p-col-12">
        <div className="p-grid p-justify-end btn-row">
          <Button label="Cancelar" onClick={props.history.goBack} className="p-button-secondary" />
          <Button label="Guardar" onClick={guardar} />
        </div>
      </div>
    </div>)
}
export default Registrar//344//309