import React, { useEffect, useGlobal, useState } from 'reactn';
import ContenedorFiltro from '../common/ContenedorFiltro'
import { ToggleButton } from 'primereact/togglebutton';
import * as firebase from "firebase/app";
import "firebase/firestore";

import { useWeekSelector } from '../common/WeekSelector';
import { obtenerInvernaderosVirtuales } from '../../service/Invernaderos';
import { MultiSelect } from 'primereact/multiselect';
import { obtenerDefectos } from '../../service/Defectos';
import { obtenerDiasDeSemanaConDetalles } from '../../service/fechas';
import Chart from "react-apexcharts";
import { obtenerCiclo } from '../../service/Ciclos';
import { obtenerListaDeSemanas } from '../../service/fechas';
import { getProductoYVariedadString } from '../../util/functions';

const optionsOrig = {
    chart: {

        type: 'line',
        stacked: false
    },
    stroke: {
        //width: [10,2,10,2,10,2],
        curve: 'smooth',
      },
      //colors: ['#118a31', '#178a31', '#718a31'],

    dataLabels: {
        enabled: false
    },
    title: {
        text: 'Valores de Colores Grados brix',
        align: 'left',

    },
    xaxis: {
        categories: ["john", "michael", "brillan"]
    },
    yaxis: [
        {
            seriesName: 'Revenue',
            axisTicks: {
                show: true,
            },
            axisBorder: {
                show: true,
                color: '#FEB019'
            },
            labels: {
                style: {
                    colors: '#FEB019',
                },
            },
            title: {
                text: "Grados Brix",
                style: {
                    color: '#FEB019',
                }
            },
            decimalsInFloat: 2
        }
    ],
    tooltip: {
        enabled:true,
        followCursor:true,
    },
    items:{
        display:"grid"
    },
    
    legend: {
        horizontalAlign: 'center',
        offsetX: 40
    }
}


const GraficaGradosBrix = (props) => {
    const [, setCargando] = useGlobal("cargando");
    const [invernadero, setInvernadero] = useGlobal("invernadero");
    var [invernaderos, setInvernaderos] = useGlobal("invernaderos");
    const [filtrosInvernaderos, setFiltrosInvernaderos] = useState([])

    const [weeks, week, _, updateCicloWeekSelector] = useWeekSelector();
    const [ciclo,] = useGlobal("ciclo");
    const [ciclos,] = useGlobal("ciclos");
    const [arregloInvsVirtuales,setArregloInvsVirtuales] = useGlobal("arregloInvsVirtuales");
    const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
    const [productosYVariedades] = useGlobal("productosYVariedades");

    const [, setDefectos] = useState([])
    const [, setDiasSemana] = useState([])

    const [multiCiclo, setMultiCiclo] = useState(false)
    const [ciclosConInvernaderos, setCiclosConInvernaderos] = useState([])

    const [filtrosCiclos, setFiltrosCiclos] = useState([])

    const [options, setOptions] = useState({ ...optionsOrig })
    const [series, setSeries] = useState([])

    const [filtrosColores, setFiltrosColores] = useState([])

    useEffect(() => {
        let dias = obtenerDiasDeSemanaConDetalles(week.time);
        dias.push({ unixTime: 0, abreviacion: 'T', nombre: 'semana', nombrePlano: "semana" })
        setDiasSemana(dias);
    }, [week.time])

    useEffect(() => {
        setFiltrosInvernaderos([])
        setSeries([])
    }, [ciclo])

    useEffect(() => {
        if (invernaderosVirtuales) {
            updateCicloWeekSelector(ciclo);
            obtenerDatosUniCiclo();
            obtenerCiclo(ciclo).then(cicloObj => {
                let optionsCopy = Object.assign({}, optionsOrig)
                let semanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
                let labels = semanas.map(el => el.nombreCompacto);
                optionsCopy["xaxis"] = { categories: labels };
                setOptions(optionsCopy)
            })
            obtenerDatosMultiCiclo()
        }

    }, [invernaderosVirtuales])

    const obtenerDatosUniCiclo = async () =>{
        let invernaderos = invernaderosVirtuales
        let promises = [];

        invernaderos.forEach((invernadero, index) => {
            invernadero["producto"] = productosYVariedades[index]?.nombre;
            invernadero["variedad"] = productosYVariedades?.[index]?.["variedad"] || "";
        })
        setInvernaderos(invernaderos);
        obtenerDefectos().then((defectos) => {
            setDefectos(defectos);
        }).catch((err) => {

        });
    }

    const obtenerDatosMultiCiclo = async () => {
        setCargando(true)
        let ciclosCopy = ciclos.map(cic => ({ ...cic }))
        for (let x = 0; x < ciclos.length; x++) {
            const allInvernaderos = arregloInvsVirtuales[ciclosCopy[x].id] || await obtenerInvernaderosVirtuales(ciclosCopy[x].id)
            ciclosCopy[x].invernaderos = allInvernaderos

            if(!arregloInvsVirtuales[ciclosCopy[x].id]){
                const copiaArreglo = arregloInvsVirtuales
                copiaArreglo[ciclosCopy[x].id] = allInvernaderos
                setArregloInvsVirtuales(copiaArreglo)
            }
        }
        //let currentCiclo = ciclosCopy.find(cic => cic.id === cicloId)
        //setFiltrosCiclos([currentCiclo])
        setCiclosConInvernaderos(ciclosCopy)
        setCargando(false)

    }

    useEffect((params) => {
        if (invernaderos && invernaderos.length > 0 && !invernadero) {
            setInvernadero(invernaderos[0]);
        }
    }, invernaderos)

    const atras = (params) => {
        props.history.goBack();
    }

    useEffect(() => {
        if (invernadero && ciclo) {
            let allSeries = []
            let index = 0
            for (let x = 0; x < filtrosInvernaderos.length; x++) {
                obtenerSeries(weeks.map(el => el.time), filtrosInvernaderos[x].id).then(data => {
                    allSeries.push(...data)
                    index++
                    if (index === filtrosInvernaderos.length) {
                        setSeries(allSeries)

                    }
                })


            }
        }
    }, [filtrosInvernaderos, ])

    useEffect(() => {
        if (filtrosCiclos.length > 0 && multiCiclo) {
            obtenerSeriesCiclos(weeks.map(el => el.time), filtrosCiclos)
        }
    }, [filtrosCiclos])

    useEffect((params) => {
        if (multiCiclo && filtrosCiclos.length > 0) {
            obtenerSeriesCiclos(weeks.map(el => el.time), filtrosCiclos)
        }
        else if(!multiCiclo && filtrosInvernaderos.length > 0){
            let allSeries = []
            let index = 0
            for (let x = 0; x < filtrosInvernaderos.length; x++) {
                obtenerSeries(weeks.map(el => el.time), filtrosInvernaderos[x].id).then(data => {
                    allSeries.push(...data)
                    index++
                    if (index === filtrosInvernaderos.length) {
                        setSeries(allSeries)

                    }
                })


            }
        }
    }, [filtrosColores])


    const obtenerSeries = async (allSemanas, invId) => {
        setCargando(true)

        let seriesResumenes = []
        let coll = await firebase.firestore().collection("ciclos").doc(ciclo).collection("graficas").where("nombreGrafica", "==", "Brix").get()
        coll.docs[0].data().allSeries.forEach((serie, index) => {

            if (invId === serie.id) {

                let colores = filtrosColores.map(col => col.id)
                serie.seriesData.forEach((se, ind) => {
                    if (colores.includes(se.id))
                        seriesResumenes.push({ data: se.data, name: serie.nombre + " - " + se.name })

                })

            }
        })
        setCargando(false)
        return seriesResumenes
    }

    const obtenerSeriesInd = async (allSemanas, invId, cicloPassed) => {
        setCargando(true)

        let seriesResumenes = []
        let coll = await firebase.firestore().collection("ciclos").doc(cicloPassed.id).collection("graficas").where("nombreGrafica", "==", "Brix").get()
        coll.docs[0].data().allSeries.forEach((serie, index) => {

            if (invId === serie.id) {

                let colores = filtrosColores.map(col => col.id)
                serie.seriesData.forEach((se, ind) => {
                    if (colores.includes(se.id))
                        seriesResumenes.push({ data: se.data, name: serie.nombre + " - " + se.name + " - " + cicloPassed.nombre })

                })

            }
        })
        setCargando(false)
        return seriesResumenes
    }

    const obtenerSeriesCiclos = async (allSemanas, filtrosCiclosPassed) => {
        setCargando(true)
        let allSeries = []


        for (let x = 0; x < filtrosCiclosPassed.length; x++) {
            let seriesResumenes = []
            
            if (filtrosCiclosPassed[x].filtrosInvernaderos) {
                //window.alert(filtrosCiclosPassed[x].filtrosInvernaderos.length + " - -" + filtrosCiclosPassed[x].id)
                for (let y = 0; y < filtrosCiclosPassed[x].filtrosInvernaderos.length; y++) {
                    let series = await obtenerSeriesInd(allSemanas, filtrosCiclosPassed[x].filtrosInvernaderos[y].id, filtrosCiclosPassed[x])
                    allSeries.push(...series)
                }
            }
        }


        setSeries(allSeries)
        setCargando(false)
    }

    return <div className="p-grid p-justify-center">
        <div className="p-col-12 title-container">
            <span className="back-btn" onClick={atras}></span>
            <span className={`icon-general chart-icon `}></span>
            <span className="titles-group">
                <h1 className="header-title">Gráfica de grados brix </h1>

            </span>
        </div>
        <div className="p-col-12">
            <div className="p-grid filtros">

                {!multiCiclo && <ContenedorFiltro label="Invernadero">
                    < MultiSelect options={invernaderos} value={filtrosInvernaderos} onChange={(e) => setFiltrosInvernaderos(e.value)}
                        optionLabel="nombre" dataKey="id"
                        selectedItemsLabel={`${filtrosInvernaderos.length} invernaderos seleccionados`}  />
                </ContenedorFiltro>}

                {multiCiclo && <ContenedorFiltro label="Ciclos">
                    < MultiSelect options={ciclosConInvernaderos} value={filtrosCiclos} onChange={(e) => {
                        setFiltrosCiclos(e.target.value)
                    }}
                        optionLabel="nombre" dataKey="id" selectedItemsLabel={`${filtrosCiclos.length} ciclos seleccionados`} 
                        selectedItemsLabel={`${filtrosCiclos.length} ciclos seleccionados`} />
                </ContenedorFiltro>}
                {
                    multiCiclo && filtrosCiclos.map((cic, index) => {
                        return (
                            <ContenedorFiltro label={`invernaderos de: ${cic.nombre}`}>
                                < MultiSelect options={cic.invernaderos} value={cic.filtrosInvernaderos || []} onChange={(e) => {
                                    let filtrosInvernaderosCopy = [...filtrosCiclos]
                                    filtrosInvernaderosCopy[index].filtrosInvernaderos = e.target.value
                                    setFiltrosCiclos(filtrosInvernaderosCopy)
                                }}
                                    optionLabel="nombre" dataKey="id"
                                    selectedItemsLabel={cic.filtrosInvernaderos && `${cic.filtrosInvernaderos.length} invernaderos seleccionados`} />
                            </ContenedorFiltro>
                        )
                    })
                }


                <div className="form-group p-col">
                    <label htmlFor="cinco">Colores</label>
                    < MultiSelect options={[{ name: "Color 1", id: "color1", data: [] }, { name: "Color 2", id: "color2", data: [] },
                    { name: "Color 3", id: "color3", data: [] }, { name: "Color 4", id: "color4", data: [] },
                    { name: "Color 5", id: "color5", data: [] }, { name: "Color 6", id: "color6", data: [] }]} value={filtrosColores} onChange={(e) => {
                        setFiltrosColores(e.target.value)}}
                        optionLabel="name" dataKey="id" 
                        selectedItemsLabel={`${filtrosColores.length} colores seleccionados`} />
                </div>
                <ContenedorFiltro label="Multiciclo">
                    <ToggleButton className="reactbtn-icon" onLabel="Multiciclo" offLabel="Multiciclo" checked={multiCiclo} onChange={(ev) => setMultiCiclo(ev.value)} ></ToggleButton>
                </ContenedorFiltro>

            </div>
        </div>

        <div style={{ height: 600 }} className="p-col-12 chart-container-filters">
            <Chart height="100%" options={options} series={series} type="line"  />
        </div>
    </div>
}

export default GraficaGradosBrix;
