import { getGlobal } from "reactn"
import * as firebase from "firebase/app";
import "firebase/firestore";
import moment from "moment";
import tz from "moment-timezone";
import 'moment/locale/es';
import { obtenerDiferenciaSemanas, obtenerInicioSemanaDia } from "./fechas";
import { obtenerInvernadero } from "./Invernaderos";
import { uploadStatistics } from './Estadisticas'
moment.locale("es");

function registrarInvocaciones(count) {
    uploadStatistics(count)
}

export const obtenerCiclo = async (cicloID) => {
    let ciclo = await firebase.firestore().collection("ciclos").doc(cicloID).get().catch(error => { console.log(`Error al obtener ciclo doc ${cicloID}: ${JSON.stringify(error)}`) })
    registrarInvocaciones(ciclo.size)
    return ciclo.exists ? { id: ciclo.id, ...ciclo.data() } : {}
}
export function obtenerCiclos() {
    var db = firebase.firestore();

    return new Promise((resolve, reject) => {
        let results = [];
        db.collection("ciclos").get().then(snap => {
            registrarInvocaciones(snap.size)
            snap.forEach(doc => {
                let data = doc.data();
                data.id = doc.id;
                data.nombre = getNombre(data);
                results.push(data);
            })
            resolve(results);
        }).catch(err => {
            reject(err);
        });
    })

}
export const getCiclos = async () => {
    try {
        let ahora = Math.round(Date.now() / 1000);
        let response = await firebase.firestore().collection("ciclos").orderBy("semana_cierre", "asc").get()
        let ciclos = response.docs.map(el => ({
            ...el.data(), id: el.id, nombre: getNombre(el.data()),
            activo: (el.data().semana_inicio <= ahora && el.data().semana_cierre > ahora)
        }))
        registrarInvocaciones(response.size);
        return ciclos;
    } catch (err) { return [] };
}

export function obtenerCiclosActivos() {
    const now = Math.round(Date.now() / 1000);
    var db = firebase.firestore();
    return new Promise((resolve, reject) => {
        let results = [];
        db.collection("ciclos").where("semana_cierre", ">=", now).get().then(snap => {
            registrarInvocaciones(snap.size)
            snap.forEach(doc => {
                let data = doc.data();
                data.id = doc.id;
                data.nombre = getNombre(data);
                results.push(data);
            })
            resolve(results);
        }).catch(err => {
            reject(err);
        });
    })

}

export function getNombre(ciclo) {
    const inicio = moment(ciclo.semana_inicio * 1000).utcOffset("-0600").format("YYYY");
    const final = moment(ciclo.semana_cierre * 1000).utcOffset("-0600").format("YYYY");
    return `Ciclo ${inicio} - ${final}`;
}
export function getNombreCompacto(ciclo = {}) {
    const inicio = moment(ciclo.semana_inicio * 1000).utcOffset("-0600").format("YYYY");
    const final = moment(ciclo.semana_cierre * 1000).utcOffset("-0600").format("YYYY");
    return `${inicio} - ${final}`;
}

export const obtenerCicloAnterior = async (ciclo) => {
    // 
    const db = firebase.firestore();
    let ciclos = await db.collection("ciclos").where("semana_cierre", "<", ciclo.semana_cierre).orderBy("semana_cierre").get();
    registrarInvocaciones(ciclos.size)
    // 
    // 
    if (ciclos.empty) {
        return null
    } else {
        let ultimoCicloId = ciclos.docs[ciclos.docs.length - 1].id;
        if (ultimoCicloId === ciclo.id) {
            if (ciclos.docs.length > 2) {
                ultimoCicloId = ciclos.docs[ciclos.docs.length - 2].id;
            } else {
                return null
            }
        } else {
            return ultimoCicloId;
        }
    }
}
export const esCicloActual = (cicloActual, ciclos) => {
    if (ciclos && ciclos.length > 0 && cicloActual) {
        let cicloObj = ciclos.find(el => el.value === cicloActual);
        if (cicloObj) {
            return cicloObj.semana_cierre > Math.round(Date.now() / 1000);
        } else {
            return false;
        }
    } else {
        return true;
    }
}
export const noHaSidoClausurado = (ciclo = getGlobal().ciclo, ciclos = getGlobal().ciclos) => {
    if (ciclos?.length > 0 && ciclo) {
        let cicloObj = ciclos.find(el => el.id === ciclo);
        if (cicloObj) return !cicloObj.clausurado
        else return false;
    }
    else return true;
}
export const haSidoClausurado = (ciclo = getGlobal().ciclo, ciclos = getGlobal().ciclos) => { return !noHaSidoClausurado(ciclo, ciclos) }

export const obtenerSemanasTranscurridas = async (cicloId) => {
    let cicloObj = getGlobal().ciclos.find(el => el.id === cicloId) || await obtenerCiclo(cicloId);
    let inicio = cicloObj.semana_inicio;
    let final = cicloObj.semana_cierre;
    let ahora = Math.round(Date.now() / 1000);
    let inicioSemana = obtenerInicioSemanaDia(ahora).unix();
    let semanasTranscurridas = obtenerDiferenciaSemanas(inicio, ahora);
    let semanasTotales = obtenerDiferenciaSemanas(inicio, final);
    return [semanasTranscurridas, semanasTotales]
}
export const obtenerSemanasTranscurridasBuddget = async (cicloId, invernaderoId) => {
    let cicloObj = getGlobal().ciclos.find(el => el.id === cicloId) || await obtenerCiclo(cicloId);
    let invernadero = getGlobal().invernaderosVirtuales.find(el => el.id === invernaderoId) || await obtenerInvernadero(cicloId, invernaderoId);
    let budget = invernadero.budget;
    if (budget) {
        let estimacion = budget.estimacion_semanal;
        //  
        //  
        //  
        // // 
        let inicio = estimacion[0].time;
        let final = estimacion[estimacion.length - 1].time;
        let ahora = Math.round(Date.now() / 1000);
        let inicioSemana = obtenerInicioSemanaDia(ahora).unix();
        let semanasTranscurridas = obtenerDiferenciaSemanas(inicio, ahora);
        let semanasTotales = obtenerDiferenciaSemanas(inicio, final);
        return [semanasTranscurridas, semanasTotales]

    } else {
        return [0, 0]
    }
}

export const actualizarDatoPrincipal = async (cicloID, ciclos, principal = false) => {
    const db = firebase.firestore();
    let promises = ciclos.map(el => {
        const princ = cicloID === el.id ? principal : false
        return db.collection('ciclos').doc(el.id).set({ currentCiclo: princ }, { merge: true })
    })
    let response = await Promise.all(promises);
    return response;
}
export const actualizarClientesCiclo = async (cicloId, clientesOrig, clientesActual) => {
    const db = firebase.firestore();
    let nuevos = clientesActual.filter(el => !clientesOrig.includes(el));
    let retirados = clientesOrig.filter(el => !clientesActual.includes(el));
    let promisesNuevos = nuevos.map(el => db.collection('ciclos').doc(cicloId).collection('clientes').add({ cliente_ref: el }))
    let promisesRetirados = retirados.map(el => eliminarClienteCiclo(cicloId, el))
    let resultNuevos = await Promise.all(promisesNuevos);
    let resultRetirados = await Promise.all(promisesRetirados);
    return true;
}
const eliminarClienteCiclo = async (cicloId, clienteId) => {
    const db = firebase.firestore();
    let snap = await db.collection("ciclos").doc(cicloId).collection("clientes").where("cliente_ref", "==", clienteId).get();
    registrarInvocaciones(snap.size)
    let doc = snap.docs[0];
    let result = await db.doc(doc.ref.path).delete();
    return result
}
export const clausurar = async (cicloId) => {
    const db = firebase.firestore();

    let result = await db.collection("ciclos").doc(cicloId).update({
        clausurado: true,
        semana_clausura: moment().startOf('week').unix()
    });
    return result;
}

export const incrementarCampoDelCiclo = async (cicloId, nombreCampo) => {
    firebase.firestore().collection("ciclos").doc(cicloId).update({ [nombreCampo]: firebase.firestore.FieldValue.increment(1) }).catch(error => { console.log("ERROR incrementarCampoDelCiclo: ", error) })
}//191