//react/reactn
import React, { useState, useEffect, useGlobal, setGlobal } from "reactn";
//Funciones internos
import { getColeccionesProductosYVariedadesTodos, guardarProducto } from "../../../service/ProductosYVariedades";
import { DeepClone } from "../../../util/functions";
//Componentes third party
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
//Componentes internos
import ContenedorPrincipal from "../../common/ContenedorPrincipal";
import ContenedorHeader from "../../common/ContenedorHeader";
import ContenedorInput from "../../common/ContenedorInput";
import SeccionFormulario from "../../common/SeccionFormulario";
import SeccionBotones from "../../common/SeccionBotones";
import { Message } from "primereact/message";

const AdministrarPackout = (props) => {
    const [ciclo] = useGlobal("ciclo");
    const [, setCargando] = useGlobal("cargando");
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [productosBases] = useGlobal("productosBases");
    const [errores, setErrores] = useState({});
    const [productos, setProductos] = useState([]);//este dato es necesario para no cambiar 'productosBases' al editar los valores

    useEffect(() => {
        setNombreModulo("Packout");
        setHabilitadoSelectorCiclo(true)
    }, [productosBases])

    useEffect(() => {
        if (productosBases) { setProductos(productosBases); }
    }, [productosBases])

    useEffect(() => {
        setErrores({})
    }, [productos])

    const guardarPackout = async () => {
        if (validarErrores()) {
            setCargando(true);
            const promises = []
            for (const index in productos) {
                const producto = productos[index], productoOrig = productosBases[index];
                if (producto.packout_respaldo != productoOrig.packout_respaldo) {
                    const datos = { packout_respaldo: producto.packout_respaldo };
                    promises.push(guardarProducto(producto.id, datos));
                    recargarDatos()
                }
            }
            await Promise.all(promises)
            setCargando(false);
        }
    }
    const validarErrores = () => {
        const errores = {};
        productos.forEach((producto, index) => {
            const packoutValor = parseFloat(producto?.packout_respaldo || 0)
            
            if (!producto.packout_respaldo || isNaN(packoutValor))
                errores.packout = { ...errores.packout, [index]: "Este campo es requerido" };
            else if (packoutValor > 1 || packoutValor <= 0)
                errores.packout = { ...errores.packout, [index]: "El valor de packout requiere un valor mas de 0 y no mas de 1" };
        })

        setErrores(errores)
        return Object.keys(errores).length === 0;
    }
    const recargarDatos = async () => {//pasar esto a carpeta /service?
        const datosColeccionesProductos = await getColeccionesProductosYVariedadesTodos(ciclo)
        setGlobal({ ...datosColeccionesProductos });
    }

    const handleInputChange = (event) => {
        const { id: index, name, value } = event.target;
        const copia = DeepClone(productos);
        const producto = copia[index];
        producto[name] = value;
        setProductos(copia);
    }

    return (
        <ContenedorPrincipal extra="p-grid p-justify-center">
            <ContenedorHeader titulo="Packout de productos" iconos="icon-general user-icon" col="p-col p-col-auto" atras={props.history.goBack} />

            <SeccionFormulario titulo="Asignación de packout respaldo">
                {productos.map((producto, index) => {
                    return (
                        <ContenedorInput col="6" key={`prod-mez-${index}`} label={producto.nombre}>
                            <InputText id={index} name="packout_respaldo" value={producto.packout_respaldo} onChange={ev => handleInputChange(ev)} type="number" step=".01" />
                            {errores.packout?.[index] && <Message severity="error" text={errores.packout?.[index]} />}
                        </ContenedorInput>)
                })}
            </SeccionFormulario>

            <SeccionBotones>
                <Button onClick={guardarPackout} label="Guardar" />
            </SeccionBotones>
        </ContenedorPrincipal>)
}
export default AdministrarPackout;