const sanitizeDataInvoice = (invoiceData) => {
  const {
    po,
    invoice,
    precioPorLibra,
    cliente_ref,
    producto_ref,
    infoRechazos,
    numeroCajasRechazadas,
    precioPorCajaRechazada,
    costoDeMateriales,
    descuentoManoDeObra,
    fechaCreacion,
    descuentoCajasTotal,
    dineroTotalRechazado,
    items,
    invoiceFiles,
  } = invoiceData;
  const infoRechazosToSave = infoRechazos || null;
  return {
    po,
    invoice,
    precioPorLibra: Number(precioPorLibra),
    cliente_ref,
    producto_ref,
    infoRechazos: infoRechazosToSave,
    numeroCajasRechazadas: Number(numeroCajasRechazadas),
    precioPorCajaRechazada: Number(precioPorCajaRechazada),
    costoDeMateriales: Number(costoDeMateriales),
    descuentoManoDeObra: Number(descuentoManoDeObra),
    fechaCreacion,
    descuentoCajasTotal: Number(descuentoCajasTotal),
    dineroTotalRechazado: Number(dineroTotalRechazado),
    items,
    invoiceFiles,
  };
};

export default sanitizeDataInvoice;
