import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react'
import { useGlobal } from 'reactn';
import { segundosSemana } from '../../constants';
import { obtenerNombreSemanaCompacto } from '../../service/fechas';
import { obtenerCapturaPrevia, registrarPronostico, actualizarPronostico } from '../../service/Queries/Pronostico';
import ContenedorFiltro from '../common/ContenedorFiltro';
import ContenedorInput from '../common/ContenedorInput';
import ContenedorPrincipal from "../common/ContenedorPrincipal"
import SeccionBotones from '../common/SeccionBotones';
import SeccionFiltros from '../common/SeccionFiltros'
import SeccionFormulario from '../common/SeccionFormulario'
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';

export default function Registro(props) {
    const [invernaderos, setInvernaderos] = useState([])
    const [invernadero, setInvernadero] = useGlobal("invernadero")
    const [cicloId] = useGlobal("ciclo")
    const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector();
    const [, setWeekGlobal] = useGlobal("weekGlobal");
    const [state, setState] = useState(["", "", "", "", "", "", ""])
    const [editing, setEditing] = useState(false)
    const [capturaId, setCapturaId] = useState()
    const [, setCargando] = useGlobal("cargando")
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");


    useEffect(() => {
        setNombreModulo("Pronósticos")
        setHabilitadoSelectorCiclo(true)
    }, [])

    useEffect(() => {
        if (cicloId) {
            updateCicloWeekSelector(cicloId);
        }
    }, [cicloId])

    useEffect(() => {
        if (week.nombre != "Cargando")
            setWeekGlobal(week);
    }, [week]);

    useEffect(() => {
        setCargando(true)
        if (invernaderosVirtuales) {
            obtenerDatosIniciales();
        }
    }, [invernaderosVirtuales])

    useEffect(() => {
        if (invernadero) {
            cargarDatosPrevios()
        }
    }, [invernadero, week.time])

    const cargarDatosPrevios = async (params) => {
        let datos = await obtenerCapturaPrevia(cicloId, invernadero.id, week.time);
        if (!datos.isEmpty) {
            setState(datos.pronosticos)
            setCapturaId(datos.id)
            setEditing(true);
        } else {
            setState(["", "", "", "", ""])
            setEditing(false);
        }
        setCargando(false);
    }

    const obtenerDatosIniciales = async (params) => {
        let invernaderos = invernaderosVirtuales.filter(inv => !inv.es_externo)
        setInvernaderos(invernaderos);
    }

    const atras = (params) => {
        props.history.goBack();
    }

    const handleChange = (index, value) => {
        let copia = state.slice();
        copia[index] = value;
        setState(copia);
    }

    const guardar = async (params) => {
        setCargando(true);
        if (editing) {
            let result = await actualizarPronostico(cicloId, invernadero.id, capturaId, state)
        } else {
            let result = await registrarPronostico(cicloId, invernadero.id, week.time, state)
        }
        setCargando(false);
    }

    const obtenerNombreSemana = (index) => {
        let time = week.time + (1 + index) * segundosSemana + 3600;
        let nombre = obtenerNombreSemanaCompacto(time);
        return `Semana ${nombre}`;
    }

    return (
        <ContenedorPrincipal>
            <div className="p-col-12 title-container">
                <span className="back-btn" onClick={atras}></span>

                <span className={`icon-general icon-id bor-${invernadero?.color} bg-${invernadero?.color} ${invernadero?.habilitado ? "" : "Deshabilitado"}`}>{invernadero?.nombre}</span>
                        <span className="titles-group">
                            <h1 className="header-title">{editing ? "Capturar pronóstico" : "Capturar pronóstico"}</h1>
                        </span>
                <div>
                </div>
            </div>
            <SeccionFiltros>
                <ContenedorFiltro label="Invernadero ">
                    <Dropdown options={invernaderos} value={invernadero} optionLabel="nombre" dataKey="id"
                        onChange={e => setInvernadero(e.value)} />
                </ContenedorFiltro>
                <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
            </SeccionFiltros>
            <SeccionFormulario titulo={"Captura"}>
                {state.map((el, index) =>
                    <ContenedorInput key={index} label={obtenerNombreSemana(index)} >
                        <InputText value={state[index]} onChange={(e) => handleChange(index, e.target.value)} placeholder="kg/m2" />
                    </ContenedorInput>)}
            </SeccionFormulario>
            <SeccionBotones>
                <Button label="Guardar" onClick={guardar} />
                <Button label="Cancelar" onClick={atras} />
            </SeccionBotones>
        </ContenedorPrincipal>
    )
}//148