import { SELECTED_PROFILE, PROFILE_DEV, PROFILE_TEST, PROFILE_HTGROUP } from "../constants";

  const firebaseConfigTest = {
    apiKey: "AIzaSyDkyDzkJ18HHCisaJKiAXcyNg2HZp6Ti-Y",
    authDomain: "htg-test-5cf9f.firebaseapp.com",
    databaseURL: "https://htg-test-5cf9f.firebaseio.com",
    projectId: "htg-test-5cf9f",
    storageBucket: "htg-test-5cf9f.appspot.com",
    appId: "1:1096273662853:web:f3fbb2ffc4e847e6baaaec",
    measurementId: "G-HS45LKYTN0"
  };

  const firebaseConfigDev = {
    apiKey: "AIzaSyBAdalAOYQ_8p_JX4bHeJJsfkSQk7m7svE",
    authDomain: "htg-dev.firebaseapp.com",
    databaseURL: "https://htg-dev.firebaseio.com",
    projectId: "htg-dev",
    storageBucket: "htg-dev.appspot.com",
    appId: "1:662664816272:web:cc429250d31645a1deda15",
    measurementId: "G-E46BZTCBC1"
  };
  
  const firebaseConfigHTGroup = {
    apiKey: "AIzaSyBNDglX_Xv2Hck8ZZGl9rfjbgmk9f0JrP0",
    authDomain: "htgroup-77af6.firebaseapp.com",
    databaseURL: "https://htgroup-77af6.firebaseio.com",
    projectId: "htgroup-77af6",
    storageBucket: "htgroup-77af6.appspot.com",
    appId: "1:236058847987:web:cedf652351c270307c6586",
    measurementId: "G-ECWKCDPKDR"
  };


  export function getFirebaseConfig() {
    switch (SELECTED_PROFILE) {
        case PROFILE_DEV:
            return firebaseConfigDev
        case PROFILE_TEST:
            return firebaseConfigTest
        case PROFILE_HTGROUP:
            return firebaseConfigHTGroup
        default:
            return firebaseConfigTest
    }
  }
