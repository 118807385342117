import React from 'react';
import {useGlobal} from 'reactn';
import { formatearMinutosAHoras } from '../../util/functions';
import { faCircle, faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getTiempoEstimadoDeTarea } from 'service/Mantenimiento';
import Tooltip from 'components/common/Tooltip';

export default function TareaPlaneacionAgente(props) {
  const { comentario } = props.tarea
  return (
    <li className={`task-item ${props.realizada ? "task-done" : "task-pending"}`}>
      {props.realizada ?
        <FontAwesomeIcon icon={faCheckCircle} />
        :
        <FontAwesomeIcon icon={faCircle} />
      }
      {props.realizada ?
        <React.Fragment >
          <a onClick={props.onClick}>
            {props.actividad.indice} - {props.actividad.nombre}
          </a> - {formatearMinutosAHoras(parseInt(props.tarea.tiempo_tomado||0))} (Realizado) 
          {comentario && <Tooltip 
                            className='ml-05' 
                            title='Comentario del agente' 
                            content={comentario}
                          />
          }
        </React.Fragment>
        :
        <React.Fragment >
          <a onClick={props.onClick}>
            {props.actividad.indice} - {props.actividad.nombre}
          </a> {props.tarea.is_emergencia ? "" : ` - ${formatearMinutosAHoras(getTiempoEstimadoDeTarea(props.actividades, props.tarea))}`}
        </React.Fragment>}
    </li>
  )
}