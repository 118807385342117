import React, { useState, useGlobal, useEffect } from 'reactn';
import { Card } from 'primereact/card';
import { withRouter } from 'react-router-dom';
import CardHeader from '../common/cards/CardHeader';

function InvernaderoCard(props) {
  const [, setInvernadero] = useGlobal("invernadero");
  const [, setAviso] = useState(true);
  var [usuario,] = useGlobal("usuario");
  useEffect(() => {
    if (props.invernadero.budget?.estimacion_semanal?.length > 0) { setAviso(false); }
  }, [])
  const puedeConsultar = () => {
    return ["Administrador", "Administrador Calidad", "Auxiliar Calidad", "Auxiliar Grower", "Administrador Inocuidad", "Grower", "Administrador Empaque", "Grower"].includes(usuario?.rol);
  }
  const consultar = () => {
    if (puedeConsultar()) {
      setInvernadero(props.invernadero);
      props.history.push("/analisis_costos/dashboard");
    }
  }

  const header = <CardHeader
    invernaderoVirtual={props.invernadero}
    habilitadoTexto={props.invernadero?.habilitado ? "" : "Deshabilitado"}
    nombreInvernaderoFisico={props.invernadero?.invernaderoFisico?.nombre || ""} />
  //let dims = props.invernadero.detalles_fisicos.find(detalle => detalle.detalle === "Area (m²)")["totales"];
  return (
    <div className="p-col-12 p-md-4 p-sm-6" onClick={consultar}>
      <Card header={header} className={`htg-card bor-${props.invernadero.color} ${props.invernadero.habilitado ? "Habilitado" : "Deshabilitado"}`} >
        <div className="p-grid p-justify-center text-center">
          <div className="p-col">
            <div className="card-block">
              <p className="value"> {props.invernadero.total > 0 ? Math.round(100 * props.invernadero.proyectado / props.invernadero.total) : 0}%</p>
              <p className="label">Avance budget</p>
            </div>
          </div>
          <div className="p-col">
            <div className="card-block">
              <p className="value"> {props.invernadero.semanasTotales > 0 ? `${Math.round(100 * props.invernadero.semanasTranscurridas / props.invernadero.semanasTotales)}` : "-"} %</p>
              <p className="label">Avance ciclo</p>
            </div>
          </div>

        </div>
      </Card>
    </div>);
}
export default withRouter(InvernaderoCard);