import React from "react";
import { Dialog } from "primereact/dialog";
import { getMomentFormatted } from "util/functions";

const ModalDetalleConsultas = ({ show = false, data = [], hide }) => {
  const { nombreDelEmpleado, codigoDelEmpelado, consultas } = data;
  //console.log({data})
  return (
    <Dialog
      header={`Consultas de: ${nombreDelEmpleado} #${codigoDelEmpelado}`}
      visible={show}
      style={{ width: "50vw", overflow: "scroll" }}
      onHide={hide}
    >
      <div className="p-grid ">
        {consultas?.map((consulta, index) => {
            const { procedimiento_consulta, diagnostico, hora: horaTemplate, fecha } = consulta
            const fechaTemplate = getMomentFormatted(fecha * 1000, "DD/MM/YYYY")
            const procedimientoTemplate = procedimiento_consulta || "No es consulta"
            const diagnosticoTemplate = diagnostico?.nombre || "N/A"
          return (
            <div
              key={`item-modal-${index}`}
              className="card item-compras w-100"
            >
              <div className="p-col-12">
                <div className="p-grid">
                  <span className="titles-group">
                    <h1 className="header-title">
                      {diagnosticoTemplate} ({procedimientoTemplate})
                    </h1>
                    <p className="header-subtitle">
                      {fechaTemplate} {horaTemplate}
                    </p>
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Dialog>
  );
};

export default ModalDetalleConsultas;
