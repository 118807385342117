import React from "reactn";
import { Dialog } from "primereact/dialog";
import { Button } from 'primereact/button';

const ModalConfirmacion = ({ data, show, header, onHide=()=>{}, mensaje, closable=true}) => {
    const aceptar = () => { data.aceptar(); onHide(); };
    const cancelar = () => { data.cancelar(); onHide(); }
    const footer = () => {
        return (
            <div>
                <Button label={data.acceptLabel || "Aceptar"} onClick={aceptar} />
                <Button label={data.cancelLabel || "Cancelar"} onClick={cancelar} />
            </div>);
    }

    return show ?
        <Dialog header={data.titulo || header} visible={!!show} className="modal-25" onHide={onHide} footer={footer()} closable={closable}>
            {data.mensaje || mensaje}
        </Dialog> : null
}
export default ModalConfirmacion;