import React, { useEffect, useState } from 'react'
import ContenedorHeader from '../../common/ContenedorHeader'
import ContenedorPrincipal from './ContenedorPrincipal'
import { useGlobal, setGlobal } from "reactn";
import { Button } from 'primereact/button';
import { getInicioDia, obtenerNombreFecha } from '../../../service/fechas';
import SeccionBotones from '../../common/SeccionBotones';
import CardCategoria from '../CardCategoria';
import { formatearMinutosAHoras } from '../../../util/functions';
import SeccionDatosCenter from '../../common/SeccionDatosCenter'
import Column from '../../common/cards/Column'
import DatoCard from './DatoCard'
import BaseCard from '../../common/cards/BaseCard';
import WeekSelector, { useWeekSelector } from '../../common/WeekSelector';
import { obtenerActividadesAgenteSemana } from '../../../service/Queries/Agentes';
import CardDia from './CardDia';
import { segundosDia } from '../../../constants';
import { sumarTiemposTomadosParciales } from '../../../service/Mantenimiento';

export default function ConsultaDia(props) {
    const [cicloId, setCicloId] = useGlobal("ciclo")
    const [agente, setAgente] = useGlobal("agente")
    const [usuario, setUsuario] = useGlobal("usuario")
    const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector(true);
    const [, setCargando] = useGlobal("cargando")

    const [dia, setDia] = useGlobal("dia")
    const [tareas, setTareas] = useGlobal("tareas")
    const [empresas, setEmpresas] = useGlobal("empresas")
    const [categorias, setCategorias] = useGlobal("categorias")
    const [actividadesMantenimiento,] = useGlobal("actividadesMantenimiento")
    const [resumen, setResumen] = useState({ estimacion: 0, completadas: 0 })
    
    const registrarEmergencia = (params) => {
        props.history.push("/planeacion_mantenimiento/captura_agentes/emergencia");
    }
    ////


    useEffect(() => {
        if (cicloId && dia?.unixTime) actualizarDatosSemana()
    }, [cicloId, dia])

    const actualizarDatosSemana = async (params) => {//swindow.alert(2)
        setCargando(true);
        const agenteId = usuario.uid;
        let actividadesAgente = await obtenerActividadesAgenteSemana(cicloId, dia?.unixTime, agenteId);
        setTiempoEstimadoTareasParciales(actividadesAgente)
        setTareas(actividadesAgente);
        let resumen = obtenerResumen(dia?.unixTime, actividadesAgente);
        setResumen(resumen);
        setCargando(false);
    }
    const setTiempoEstimadoTareasParciales = (actividadesAgente) => {
        actividadesAgente.forEach(tarea => {
            if (tarea.tareaParcial) { tarea.tiempo_tomado = sumarTiemposTomadosParciales(tarea.tiempos_tomados); }
        })
    }
    ///
    const obtenerResumen = (diaTime, tareasPassed) => {

        let tareasDia = tareasPassed ? tareasPassed.filter(tarea => {
            return tarea.dia === diaTime;
        }) : [];

        let result = { estimacion: 0, completadas: "" };
        let emergencias = 0, emergeSum = 0, actSum = 0, completadas = 0


        result.estimacion = tareasDia.reduce((acc, tarea) => {
            if (actividadesMantenimiento) {
                let actividad = actividadesMantenimiento.find(actividad => actividad.id === tarea.actividad_ref);
                if((tarea.tiempo_estimado || tarea.is_requisicion )&& !tarea.realizada){
                    return acc + parseInt(tarea.tiempo_estimado);
                }
                if (actividad && !tarea.realizada) {
                    if(tarea.is_requisicion || tarea.is_tiempo_estimado_locacion || tarea.is_tiempo_estimado_input){
                        return acc + parseInt(tarea.tiempo_estimado);
                    } 
                    return acc + parseInt(actividad.tiempo_estimado);
                }else if (actividad && tarea.realizada) {
                    if (tarea.is_emergencia) {
                        emergeSum += parseInt(tarea.tiempo_tomado)
                        emergencias++
                    }
                    else if (tarea.tareaParcial) {
                        actSum += sumarTiemposTomadosParciales(tarea.tiempos_tomados);
                        tarea.tiempos_tomado = 88
                    }
                    else actSum += parseInt(tarea.tiempo_tomado)
                    completadas++
                    return acc;
                } else {
                    return acc;
                }
            } else {
                return acc;
            }
        }, 0);

        result.completadas = `${completadas}/${tareasDia.length}`;
        if (emergencias > 0) result.emergencias = emergencias

        result.tiempoEmergencias = emergeSum || false
        result.tiempoRealizada = actSum || false
        return result;
    }

    useEffect(() => {
        if (!actividadesMantenimiento) {
            props.history.replace("/mantenimiento/agente/consulta_semana")
        }
    }, [actividadesMantenimiento])

    const esDiaPasado = () => dia?.unixTime < getInicioDia(Date.now());

    return (
        <ContenedorPrincipal>
            <ContenedorHeader titulo="Actividades registradas" iconos="mtto-icon" subtitulo={obtenerNombreFecha(dia?.unixTime)} atras={props.history.goBack} />


            <BaseCard col="12" tituloCard={`Resumen  ${dia?.nombre}`}>
                <SeccionDatosCenter>
                    <DatoCard label="Planeación estimada"
                        valor={formatearMinutosAHoras(resumen.estimacion)} />

                    {resumen.tiempoRealizada &&
                        <DatoCard label="Tareas realizadas"
                            valor={formatearMinutosAHoras(resumen.tiempoRealizada)} />}
                    {resumen.tiempoEmergencias &&
                        <DatoCard label="Emergencias realizadas"
                            valor={formatearMinutosAHoras(resumen.tiempoEmergencias)} />}
                    {resumen.emergencias &&
                        <DatoCard label="Emergencias"
                            valor={resumen.emergencias} />}

                    <DatoCard label="Tareas completadas"
                        valor={resumen.completadas} />

                </SeccionDatosCenter>
            </BaseCard>
            <BaseCard col="12" tituloCard="Actividades">

                {empresas.map((empresa, indexE) => {
                    return categorias.map((categoria, indexC) => {
                        const tareasFiltradas = tareas.filter(tarea => {
                            let actividad = actividadesMantenimiento.find(actividad => {
                                return actividad.id === tarea.actividad_ref;
                            });
                            return dia?.unixTime === tarea.dia && actividad.categoria_ref === categoria.id && tarea.empresa_ref === empresa.id
                        })
                        return tareasFiltradas.length > 0 ? (
                            <div key={indexE + "-" + indexC}>
                                <CardCategoria categoria={categoria} tareas={tareasFiltradas} empresa={empresa} tipo={"captura"} />
                            </div>
                        ) : null;
                    })
                })}

            </BaseCard>
            <SeccionBotones>
                <Button disabled={esDiaPasado()} label="Agregar tarea de emergencia" onClick={registrarEmergencia} />
            </SeccionBotones>
        </ContenedorPrincipal>
    )
}//119