import React,{useState, useEffect, useGlobal} from "reactn";
import {Dialog} from "primereact/dialog";
import { Button } from 'primereact/button';


const ModalPermiso = (props)=>{
    const footer = (params) => {
        return <Button label = "Aceptar" onClick = {props.onHide}/> 
     }
    return <Dialog  header={"ERROR DE PERMISOS"} visible={props.show} style={{width: '50vw'}}   onHide = {()=>{props.onHide()}} footer = {footer()}>
        No tienes los permisos requeridos
    </Dialog>  

}
export default ModalPermiso;