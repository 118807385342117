import moment from "moment";
import 'moment/locale/es';
import tz from "moment-timezone";
import { ExportBundleInfo } from "firebase-functions/lib/providers/analytics";
import { obtenerRegistrosSemanaAnterior } from "../components/cosecha/ConsultarCtrl";
import { miliSegundosSemana } from "../constants";
import { dateToUnixTimeAsNumber13 } from "../util/functions";
moment.locale("es");

const DIA_LUNES_INDEX = 0;
const DIA_MARTES_INDEX = 1;
const DIA_MIERCOLES_INDEX = 2;
const DIA_JUEVES_INDEX = 3;
const DIA_VIERNES_INDEX = 4;
const DIA_SABADO_INDEX = 5;

const momentToMilisegundos = (momentDate) => momentDate?.unix?.() * 1000

export const getMinutesByTimestamp = (milliseconds = 0) => {
	const newDate = new Date(milliseconds)
    const minutes = newDate.getMinutes()
    const hours = newDate.getHours() * 60
    return minutes + hours
}
export const getTimestampFromMinutes = (minutos = 0) => {
    const milisegundos = minutos * 60 * 1000;
	const timestampHoy = getInicioDia(Date.now()) * 1000;
    //console.log("TIEMPO ESTIMADO INICIAL: ",minutos)
    //console.log("TIEMPO ESTIMADO INICIAL tmstmp: ",timestampHoy + milisegundos)
    return timestampHoy + milisegundos
}
export const transformISOToCalendarFormat = (value) => {
    const newDate = new Date()
    if(!value) {
        newDate.setHours(0)
        newDate.setMinutes(0)
        newDate.setSeconds(0)
        return newDate.getTime()
    }
    const splited = value?.split(":")
    newDate.setHours(splited[0], splited[1] || 0, 0)
    const time = newDate.getTime()
    return time
}

export const getFormatCalendar = (minutes) => {
    const newDate = new Date()
    if(!minutes){
        newDate.setHours(0)
        newDate.setMinutes(0)
        newDate.setSeconds(0)
        return newDate
    }
    const hours = Math.floor(minutes / 60)
    const minutesRemaining = minutes % 60
    
    newDate.setHours(hours)
    newDate.setMinutes(minutesRemaining)
    newDate.setSeconds(0)
    return newDate
  }

export function obtenerSemana(timestamp) {
    let dayBeginningOfYear = moment(timestamp * 1000).utcOffset("-0600").startOf("year").day() - 1;

    if (dayBeginningOfYear < 0) {
        dayBeginningOfYear = 7;
    }
    let beginningOfYear = moment(timestamp * 1000).utcOffset("-0600").startOf("year").dayOfYear();

    beginningOfYear -= dayBeginningOfYear;

    let now = moment(timestamp * 1000).utcOffset("-0600").dayOfYear();
    let difference = now - beginningOfYear;
    let result = Math.floor(difference / 7) + 1;

    return result;
}

export function obtenerInicioSemana() {
    let dia = moment().utcOffset("-0600").startOf("week").day();

    let start = moment().utcOffset("-0600").startOf("week");
    if (dia === 0) {
        start = start.subtract(6, "days");
    }
    else {
        start = start.add(0, "days");
    }
    return start;
}
export function obtenerInicioSemanaDia(fecha) {
    let dia = moment(fecha * 1000).utcOffset("-0600").startOf("week").day();

    let start = moment(fecha * 1000).utcOffset("-0600").startOf("week");
    if (dia === 0) {
        start = start.subtract(6, "days");
    }
    else {
        start = start.add(0, "days");
    }
    return start;
}
export function getInicioMes(fecha) {
    let mes = moment(fecha).utcOffset("-0600").startOf("month");
    return mes.valueOf()
}
export function getFinalDeMes(fecha) {
    let mes = moment(fecha).utcOffset("-0600").startOf("month");
    mes = mes.add(1, "month")
    return mes.valueOf()
}
export function getInicioSemanaDia(fecha) {
    let dia = moment(fecha).utcOffset("-0600").startOf("week").day();
    let semana = moment(fecha).utcOffset("-0600").startOf("week");
    if (dia === 0) semana = semana.subtract(6, "days");
    return semana
}

export function obtenerNombreFecha(fecha) {
    let m = moment(fecha * 1000).utcOffset("-0600");
    return m.format("LL");
};

export function obtenerNombreFechaCompacto(fecha) {
    let m = moment(fecha * 1000).utcOffset("-0600");
    return m.format("DD-MM-YYYY");
};
export function getNombreFechaCompacto(fecha) {
    let m = moment(fecha).utcOffset("-0600");
    return m.format("DD-MM-YYYY");
};

export function obtenerNombreFechaCompactoDate(fecha) {
    let m = moment(fecha).utcOffset("-0600");
    return m.format("DD-MM-YYYY");
};

export function obtener_horaMinutos(fecha) {
    let m = moment(fecha * 1000);
    return m.format("hh:mm a");
};

export function obtenerNombreSemana(fecha) {
    let anio = moment(fecha * 1000).utcOffset("-0600").year();

    let week = moment(fecha * 1000).utcOffset("-0600").week();
    let month = moment(fecha * 1000).utcOffset("-0600").month();
    if (week === 53 && month === 0) {
        anio -= 1;
    }
    if (week === 1 && month === 11) {
        anio += 1;
    }


    return (`${week.toString().padStart(2, "0")} - ${anio}`);
}
export function obtenerNombreSemanaCompacto(fecha) {
    let anio = moment(fecha * 1000).utcOffset("-0600").year();
    let week = moment(fecha * 1000).utcOffset("-0600").week();
    let month = moment(fecha * 1000).utcOffset("-0600").month();
    if (week === 53 && month === 0) {
        anio -= 1;
    }
    if (week === 1 && month === 11) {
        anio += 1;
    }

    anio = anio.toString().substring(2);
    return (`${week.toString().padStart(2, "0")} - ${anio}`);
}

////
export function getDetallesSemana(fecha) {
    let year = moment(fecha).utcOffset("-0600").year();
    let month = moment(fecha).utcOffset("-0600").month();
    let week = moment(fecha).utcOffset("-0600").week();

    if (week === 53 && month === 0) year -= 1;
    else if (week === 1 && month === 11) year += 1;
    let numero = `${week.toString().padStart(2, "0")}`
    let nombre = `${numero} - ${year}`
    let nombreCompacto = `${week.toString().padStart(2, "0")} - ${year}`
    return { time: fecha, numero, nombre, nombreCompacto }
}

export function getNombreSemanaCompacto(fecha) {
    let anio = moment(fecha).utcOffset("-0600").year();
    let week = moment(fecha).utcOffset("-0600").week();
    let month = moment(fecha).utcOffset("-0600").month();

    if (week === 53 && month === 0) anio -= 1;
    else if (week === 1 && month === 11) anio += 1;
    anio = anio.toString().substring(2);
    return (`${week.toString().padStart(2, "0")} - ${anio}`);
}

export function obtenerNumeroSemana(fecha) {
    let week = moment(fecha * 1000).utcOffset("-0600").week();
    return (`${week.toString().padStart(2, "0")}`);
}
////

export function obtenerListaDeSemanas(inicio, fin) {
    let semanas = [];
    let holder = obtenerInicioSemanaDia(inicio);
    while (holder.unix() < fin) {
        let semana = {
            time: holder.unix(),
            nombre: obtenerNombreSemana(holder.unix()),
            numero: obtenerNumeroSemana(holder.unix()),
            nombreCompacto: obtenerNombreSemanaCompacto(holder.unix())
        }
        semanas.push(semana);
        holder.add(7, "days");
    }
    return semanas;
}

export function getListaDeSemanas(inicio, fin) {
    let holder = getInicioSemanaDia(inicio)
    let semanas = [], time = momentToMilisegundos(holder)

    while (time < fin) {
        semanas.push(getDetallesSemana(time));
        holder.add(7, "days");
        time = momentToMilisegundos(holder)
    }
    return semanas;
}

export function obtenerNombreDiaSemana(fecha) {
    let dia = moment(fecha * 1000).utcOffset("-0600");
    return dia.format("dddd");
}
export function getNombreDiaSemana(fecha) {
    let dia = moment(fecha).utcOffset("-0600");
    return dia.format("dddd");
}
export function getNombreDiaSemanaHoy() {
    let dia = moment().utcOffset("-0600");
    return dia.format("dddd");
}

export function obtenerDiasDeSemana(fecha) {

    let result = [];
    let inicioSemana = obtenerInicioSemanaDia(fecha);

    for (let x = 0; x < 6; x++) {
        result.push(inicioSemana.unix());
        inicioSemana.add(1, "days");
    }
    return result;
}

export function obtenerSemanaInicioYFinal(fecha) {
    let inicio = moment(fecha*1000).utcOffset("-0600").startOf("week").subtract(12, "hours")
    let final =  moment(fecha*1000).utcOffset("-0600").startOf("week").add(5, "days").set({hour: 23});

    return { inicio: inicio.unix(), final: final.unix() };
}

export function isSameDay(timestamp1, timestamp2) {
    const date1 = moment(timestamp1*1000)
    const date2 = moment(timestamp2*1000)
    let diff = date2.diff(date1, 'hours')
    if(diff <= 1 && diff >= -1){
        return true
    }else{
        return false
    }
}

export function getDiasDeSemana(fecha) {
    let result = []
    let inicioSemana = getInicioSemanaDia(fecha);
    for (let x = 0; x < 6; x++) {
        result.push(momentToMilisegundos(inicioSemana));
        inicioSemana.add(1, "days");
    }
    return result;
}

export function obtenerSemanaAnterior(fecha) {
    let dia = moment(fecha * 1000).utcOffset("-0600");
    dia = dia.subtract(1, "week");
    return dia.unix();
}
export const hanPasadoHoras = (target, actual, horas) => {
    let diff = actual - target;
    return (diff >= horas * 24 * 60 * 60)
}

export function obtenerDiasDeSemanaConDetalles(inicioSemanaUnixTime) {
    var diasSemana = obtenerDiasDeSemana(inicioSemanaUnixTime)
    diasSemana[DIA_LUNES_INDEX] = { unixTime: diasSemana[DIA_LUNES_INDEX], abreviacion: 'L', nombre: 'lunes', nombrePlano: "lunes" }
    diasSemana[DIA_MARTES_INDEX] = { unixTime: diasSemana[DIA_MARTES_INDEX], abreviacion: 'M', nombre: 'martes', nombrePlano: "martes" }
    diasSemana[DIA_MIERCOLES_INDEX] = { unixTime: diasSemana[DIA_MIERCOLES_INDEX], abreviacion: 'M', nombre: 'miércoles', nombrePlano: "miercoles" }
    diasSemana[DIA_JUEVES_INDEX] = { unixTime: diasSemana[DIA_JUEVES_INDEX], abreviacion: 'J', nombre: 'jueves', nombrePlano: "jueves" }
    diasSemana[DIA_VIERNES_INDEX] = { unixTime: diasSemana[DIA_VIERNES_INDEX], abreviacion: 'V', nombre: 'viernes', nombrePlano: "viernes" }
    diasSemana[DIA_SABADO_INDEX] = { unixTime: diasSemana[DIA_SABADO_INDEX], abreviacion: 'S', nombre: 'sábado', nombrePlano: "sabado" }
    return diasSemana
}
export function getDiasDeSemanaConDetalles(inicioSemanaUnixTime) {
    var diasSemana = getDiasDeSemana(inicioSemanaUnixTime)
    diasSemana[DIA_LUNES_INDEX] = { unixTime: diasSemana[DIA_LUNES_INDEX], abreviacion: 'L', nombre: 'lunes', nombrePlano: "lunes" }
    diasSemana[DIA_MARTES_INDEX] = { unixTime: diasSemana[DIA_MARTES_INDEX], abreviacion: 'M', nombre: 'martes', nombrePlano: "martes" }
    diasSemana[DIA_MIERCOLES_INDEX] = { unixTime: diasSemana[DIA_MIERCOLES_INDEX], abreviacion: 'M', nombre: 'miércoles', nombrePlano: "miercoles" }
    diasSemana[DIA_JUEVES_INDEX] = { unixTime: diasSemana[DIA_JUEVES_INDEX], abreviacion: 'J', nombre: 'jueves', nombrePlano: "jueves" }
    diasSemana[DIA_VIERNES_INDEX] = { unixTime: diasSemana[DIA_VIERNES_INDEX], abreviacion: 'V', nombre: 'viernes', nombrePlano: "viernes" }
    diasSemana[DIA_SABADO_INDEX] = { unixTime: diasSemana[DIA_SABADO_INDEX], abreviacion: 'S', nombre: 'sábado', nombrePlano: "sabado" }
    return diasSemana
}

export function obtenerDiasDeSemanaExtendidos(fecha) {

    let result = [];
    let inicioSemana = obtenerInicioSemanaDia(fecha);

    for (let x = 0; x < 7; x++) {
        result.push(inicioSemana.unix());
        inicioSemana.add(1, "days");
    }
    return result;
}
export function obtenerIndiceDiaSemana(fecha) {
    let dia = moment(fecha * 1000).utc();
    let indice = (dia.day() + 6) % 7;
    return indice;
}
export function getIndiceDiaSemana(fecha) {
    let dia = moment(fecha).utcOffset("-0600");
    let indice = (dia.day() + 6) % 7;
    return indice;
}

export const obtenerInicioDia = (fecha) => {
    return moment(fecha * 1000).utcOffset("-0600").startOf("day").unix();
};
export const getInicioDia = (fecha) => moment(fecha).utcOffset("-0600").startOf("day").unix()
export const obtenerDiaActual = () => {
    return obtenerInicioDia(moment().utc().unix());
}

export const obtenerHora = (fecha) => {
    let dia = moment(fecha * 1000).utcOffset("-0600");
    return dia.hour();
}
export const obtenerHoraRegistro = (fecha) => {
    let dia = moment(fecha * 1000).utcOffset("-0600");
    return dia.format("LT")
}

export const flexDate = (fecha, isTimeStamp = false) => {
    let timestamp = 1000
    if(isTimeStamp){
        timestamp = 1
    }
    let inicio = moment(fecha * timestamp).utc().subtract({hours: 6})
    let fin = moment(fecha * timestamp).utc().add({hours: 2})
    return {inicio: inicio.unix(), fin: fin.unix()}
}




export function sumarHoras(fecha, horas) {
    const fechaInicial = moment(fecha * 1000).utcOffset("-0600")
    const fechaFinal = fechaInicial.add(horas, 'hours')
    return fechaFinal.unix()
}

export const proximoLunes = (fecha) => {
    let inicioSemana = obtenerInicioSemanaDia(fecha);
    inicioSemana.add(1, "week");

    return inicioSemana.unix();
}

export function regulateDate (date) { 
    let dateComplete = moment.unix(date)
    // check diference betwenn dateComplete hour and midnight
    let diference = dateComplete.hour()
    console.log(diference, dateComplete.hour())
    console.log(12 - diference)
    if(diference >= 0 && diference < 2){
        dateComplete.subtract(diference, 'hours')
    }
    else{
        dateComplete.subtract(diference - 12, 'hours')
    }
    console.log(dateComplete, "final")
    return dateComplete.format("DD/MM/YYYY")
}

export const obtenerDiferenciaSemanas = (inicio, final) => {
    let resta = final - inicio;
    return Math.floor(resta / (7 * 24 * 60 * 60));
}//217