import React, { useEffect } from 'react';
import { useState } from 'react';
import { useGlobal } from 'reactn';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from "primereact/dialog";
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar'
import { MultiSelect } from 'primereact/multiselect';
import { guardarDatosRequisicion, ordenEsperaEntrega, ordenCerrado, ordenCerradoParcial, guardarRequisicionAciclico, isAdminAlmacen } from '../../../service/Requisiciones';
import { monedas } from '../../../constants'
import {dateToUnixTimeAsNumber13, getCalendarDate, getNombrePorID} from '../../../util/functions';
import { notificarOrdenCerrada, notificarEstatusRequisicion } from '../../../service/notificaciones';

import 'moment/locale/es';

const CambiarEstatusModal = (props) => {
    const [saving, setSaving] = useState(false)
    const [errores, setErrores] = useState({})

    const [cargando, setCargando] = useGlobal("cargando")
    const [ciclo,] = useGlobal("ciclo");
    const [usuario] = useGlobal("usuario");
    const [nuevoEstatus, setNuevoEstatus] = useState(props.estatus)
    const [modalState, setModalState] = useState(props.state)

    useEffect(() => {
        //setSaving(false)
    }, [nuevoEstatus])
    useEffect(() => {
        setErrores({})
    }, [modalState, nuevoEstatus, props.otroProveedor])
    useEffect(() => {
        setModalState(props.state)
        setNuevoEstatus(props.estatus)
        setErrores({})
    }, [props.state, props.estatus])
    const handleInputChange = (event, ) => {
        let modalStateCopy = Object.assign({}, modalState)
        modalStateCopy[event.target.name] = event.target.value
        setModalState(modalStateCopy)
    }
    const handleCalendarInputChange = (event, itemEdit = false) => {
        handleInputChange({ target: { name: event.target.name, value: dateToUnixTimeAsNumber13(event.target.value) } }, itemEdit)
    }

    const getEstatusesDisponibles = () => {
        let currEstatus = props.estatuses.find(el => el.id === props.estatus)
        let estatusesDisponibles = []

        props.estatuses.forEach(el => {
            const isCurrentStatus = currEstatus && el.orden === currEstatus.orden;
            if(el.nombre === "Cerrado" && usuario.rol === "Administrador Compras"){
                //Do nothing
            }
            else if((el.nombre === "Cerrado" || el.nombre === "Cerrado - Parcialmente" || isCurrentStatus) && isAdminAlmacen(usuario.rol))
                estatusesDisponibles.push(el)
            else if (currEstatus && el.orden >= currEstatus.orden && !isAdminAlmacen(usuario.rol))
                estatusesDisponibles.push(el)
        })

        return estatusesDisponibles
    }

    const validar = () => Object.keys(getErroresFormulario()).length === 0;
    const getErroresFormulario = () => {
        let camposRequeridosEsperaEntrega = ["moneda", "precioFinal", "fechaEstimadaEntrega", "numero_agrosmart"]
        let camposRequeridosCerrado = ["fechaEntregaMaterial"]
        let errores = {}

        if(ordenEsperaEntrega(props.estatuses, nuevoEstatus)){
            camposRequeridosEsperaEntrega.forEach(campo => {
                if (!modalState[campo] || parseInt(modalState[campo]) === 0) errores[campo] = true
            })
        }
        else if(ordenCerrado(props.estatuses, nuevoEstatus)){
            camposRequeridosCerrado.forEach(campo => {
                if (!modalState[campo] || parseInt(modalState[campo]) === 0) errores[campo] = true
                if(modalState.proveedor === "Otro" && !props.otroProveedor) errores.otroProveedor = true
            })
        }

        setErrores(errores)
        return errores
    }
    const guardarEstatus = async () => {
        if(validar())
        {
            setCargando(true)
            props.closeModal()
            let data = { estatus: nuevoEstatus }

            if (ordenEsperaEntrega(props.estatuses, nuevoEstatus)){
                data.fechaEstimadaEntrega = modalState.fechaEstimadaEntrega
                data.precioFinal = modalState?.precioFinal || ""
                data.moneda = modalState?.moneda || ""
                data.numero_agrosmart = modalState.numero_agrosmart
            }
            else if (ordenCerradoParcial(props.estatuses, nuevoEstatus)) {
                data.listaItemsParcial = modalState.listaItemsParcial
                data.fechaEntregaParcial = modalState.fechaEntregaParcial
            }
            else if (ordenCerrado(props.estatuses, nuevoEstatus)) {
                data.fechaEntregaMaterial = modalState.fechaEntregaMaterial || ""
                data.proveedor = props.otroProveedor ? await props.subirOtroProveedor() : (modalState?.proveedor || "");
                data.listaItemsParcial = modalState.listaItemsParcial
                data.comentarioCerrado = modalState?.comentarioCerrado || "";
                data.fechaCerrado = Date.now()
            }

            try{

                await guardarRequisicionAciclico("compra", props.requisicionID, data)
                if (!ordenCerrado(props.estatuses, nuevoEstatus))
                    await notificarEstatusRequisicion(props.state.solicitante, props.state.orden_num, getNombrePorID(props.estatuses, nuevoEstatus), "compras");
                else {
                    await notificarOrdenCerrada(props.state.solicitante, props.state.orden_num, modalState?.comentarioCerrado || "", "compras");
                }
            }
            catch(err){
                console.log("ERROR: ",err)
            }
            props.setFormularioState(data)
            setCargando(false)
        }
    }

    const footer = (params) => {
        return <div>
            <Button label="Cancelar" onClick={() => props.closeModal()} />
            <Button label="Guardar"  onClick={() => guardarEstatus()} />
        </div>
    }

    const showErrorMessage = (field) => saving && !field
    const showErrorMessageValidateNum = (field) => saving && !field && !isNaN(field) && field > 0


    return <Dialog header={"Administración de la orden"} visible={props.visible} style={{ width: '50vw' }} onHide={() => props.closeModal()} footer={footer()}>
        <div className="p-grid ">
            <div className="form-group p-col-12">
                <label htmlFor="status-nuevo">Estatus nuevo </label>
                <Dropdown id="status-nuevo" optionLabel="nombre" optionValue="id" options={getEstatusesDisponibles()} value={nuevoEstatus} onChange={e => setNuevoEstatus(e.target.value)} />
            </div>

            {ordenEsperaEntrega(props.estatuses, nuevoEstatus) && <>
            <div className="form-group p-col-12 ">
                <label htmlFor="fechaEstimadaEntrega">Fecha estimada de entrega</label>
                <Calendar id="fechaEstimadaEntrega" name="fechaEstimadaEntrega" value={getCalendarDate(modalState.fechaEstimadaEntrega)} onChange={handleCalendarInputChange} 
                 dateFormat="dd/mm/yy" disabled={isAdminAlmacen(usuario?.rol)} />
                {errores.fechaEstimadaEntrega && <Message severity="error" text="Este campo es requerido" />}
                </div>
                
                <div className="form-group p-col-12 p-md-6">
                    <label htmlFor="precioFinal">Precio final</label>
                    <InputText id="precioFinal" name="precioFinal" value={modalState.precioFinal} onChange={handleInputChange} disabled={isAdminAlmacen(usuario?.rol)} />
                    {errores.precioFinal && <Message severity="error" text="Este campo es requerido" />}
                </div>
                <div className="form-group p-col-12 p-md-6">
                    <label htmlFor="moneda">Moneda </label>
                    <Dropdown id="moneda" name="moneda" optionLabel="nombre" optionValue="id" options={monedas} value={modalState.moneda} onChange={handleInputChange} 
                     placeholder="Seleccionar" disabled={isAdminAlmacen(usuario?.rol)} />
                    {errores.moneda && <Message severity="error" text="Este campo es requerido" />}
                </div>

                <div className="form-group p-col-12 p-md-6">
                    <label htmlFor="numero_agrosmart">Número Agrosmart</label>
                    <InputText id="numero_agrosmart" name="numero_agrosmart" value={modalState.numero_agrosmart} onChange={handleInputChange}/>
                    {errores.numero_agrosmart && <Message severity="error" text="Este campo es requerido" />}
                </div>
            </>}

            {ordenCerrado(props.estatuses, nuevoEstatus) && <>
                <div className="form-group p-col-12 p-md-6">
                    <label htmlFor="fechaEntregaMaterial">Fecha Entrega Material</label>
                    <Calendar id="fechaEntregaMaterial" name="fechaEntregaMaterial" value={getCalendarDate(modalState.fechaEntregaMaterial)} onChange={handleCalendarInputChange} dateFormat="dd/mm/yy" />
                    {errores.fechaEntregaMaterial && <Message severity="error" text="Este campo es requerido" />}
                </div>
                
                <div className="form-group p-col-12 p-md-6">
                    <label htmlFor="proveedor">Proveedor</label>
                    <Dropdown filter={true} filterPlaceholder="Proveedor" filterBy="nombre" id="proveedor" name="proveedor" optionLabel="nombre" optionValue="id" options={[...props.proveedores,{ nombre: "Otro", id: "Otro" }]} value={modalState.proveedor} onChange={handleInputChange} placeholder={'Seleccionar'} />
                </div>

                {modalState.proveedor === "Otro" && <div className="form-group p-col-12 p-md-6">
                    <label htmlFor="otro-proveedor">Otro </label>
                    <InputText id="otro-proveedor" value={props.otroProveedor} onChange={props.setOtroProveedor} />
                    {errores.otroProveedor && <Message severity="error" text="Este campo es requerido" />}
                </div>}
                <div className="form-group p-col-12 p-md-6">
                    <label htmlFor="comentarioCerrado">Comentario para enviar </label>
                    <InputTextarea name="comentarioCerrado" rows={3} cols={30} id="comentarioCerrado" value={props.comentarioCerrado} onChange={handleInputChange} />
                </div>
            </>}

            {ordenCerradoParcial(props.estatuses, nuevoEstatus) && <div className="form-group p-col-12 p-md-6">
                <label htmlFor="fechaEntregaParcial">Fecha De Entrega Parcial</label>
                <Calendar id="fechaEntregaParcial" name="fechaEntregaParcial" value={getCalendarDate(modalState.fechaEntregaParcial)} onChange={handleCalendarInputChange} dateFormat="dd/mm/yy" />
            </div>}
            {ordenCerradoParcial(props.estatuses, nuevoEstatus) && <div className="form-group p-col-12 p-md-6">
                <label htmlFor="listaItemsParcial">Lista items</label>
                <MultiSelect id="listaItemsParcial" name="listaItemsParcial" filter={false} name="listaItemsParcial" optionLabel="nombre" optionValue="id" options={modalState.items.map((item, index) => ({ nombre: item.solicitud, id: index }))} value={modalState.listaItemsParcial} onChange={handleInputChange}
                    filter={false} selectedItemsLabel={`${modalState.listaItemsParcial.length} objetos seleccionados`} />
                {showErrorMessage(modalState.listaItemsParcial.length > 0 ? true : false) && <Message severity="error" text="Este campo es requerido" />}

            </div>}

        </div>
    </Dialog >
}

export default CambiarEstatusModal;