import React from 'react'

const FirmaAplicador = () => {
  return (
    <div className='firma-aplicador p-col-6 p-justify-center show-print'>
        <div className='p-flex p-flex-direction-column p-align-items-center'>
            <div className='linea-firma-aplicador'></div>
            <h2 className='mb-4 text-aplicador'>Aplicador</h2>
        </div>
    </div>
  )
}

export default FirmaAplicador