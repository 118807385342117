import React, { useState, useGlobal, useEffect } from 'reactn'
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import Select from "react-select"
import { obtenerPresentacionesHabilitadas } from '../../service/Presentaciones';
import { Message } from "primereact/message";
import { DeepClone, dosDecimales, getObjetoPorID, getProductoYVariedadString } from '../../util/functions';
import { getFirestoreID } from 'service/Mantenimiento';
import { mapDeDataDropdownDePresentaciones } from "../calidad/utils/registroFinal.utils"
import { mapeoOpcionesInvsPorProductoYVariedad } from './utils/formularioItem.utils';
import DropdownPresentacionesHTG from 'components/common/input_components/DropdownPresentacionesHTG.comp';
import DropdownInvernaderosHTG from 'components/common/input_components/DropdownInvernaderosHTG.comp';
const inicial = {
  po: "",
  invernadero: "",
  producto: "",
  presentacion: "",
  presentacion_item: "",
  cajas_orden: "",
  pallets_orden: ""
}
export default function FormularioItem(props) {
  const [state, setState] = useState(Object.assign({}, inicial));
  const [clienteSeleccionado,] = useGlobal("clienteSeleccionado");
  const [clientes,] = useGlobal("clientes");

  const [invernaderosOrig, setInvernaderosOrig] = useState([])
  const [invernaderos, setInvernaderos] = useState([])
  const [presentaciones, setPresentaciones] = useState([]);
  const [presentacionesOpciones, setPresentacionesOpciones] = useState([]);
  const [presentacionesOrig, setPresentacionesOrig] = useState([]);
  const [error, setError] = useState(false);
  const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
  const [productos] = useGlobal("productosYVariedades");
  const [productosTodos] = useGlobal("productosYVariedadesTodos");
  const [productosBases] = useGlobal("productosBases");

  const sitiosEmpaques = [
    {
      id: "CUq9lwDbplIRVuVjeOiL",
      locacion_ref: "DDCtYSYz7u2qEVI5RyRx",
      nombre: "Sitio A",
    },
    {
      id: "KSAYGvBTFfoTrVjzpJHd",
      locacion_ref: "DDCtYSYz7u2qEVI5RyRx",
      nombre: "Sitio T",
    },
    {
      id: "SCkIi2SnCvdAw3Xm4PLi",
      locacion_ref: "f9QKk2UuLMf5FkB4IfMH",
      nombre: "Empaque Agropark",
    }
  ];

  useEffect(() => {
    if (!props.visible) { setState({ ...inicial }) }
  }, [props.visible])

  const handleInputChange = ({ target }) => {
    setError(false);
    //console.log("TARGET: ", target)
    const stateCopy = DeepClone(state);
    const { name, value } = target;
    stateCopy[name] = value;

    if (name === "invernadero") { stateCopy.producto = getObjetoPorID(productos, value.producto_ref); }
    setState(stateCopy);
  }

  const guardarItem = () => {
    if (!validar()) {
      setError(true);
      return;
    }
    state.invernadero_ref = state.invernadero.id;
    state.nombre_invernadero = state.invernadero.nombre;
    state.presentacion_ref = state.presentacion.id;
    state.presentacion_id = state.presentacion.id_presentacion;
    state.nombre_producto = state.producto.nombre;
    state.producto_ref = state.producto.id;
    state.descripcion = state.presentacion.descripcion;
    //state.empaque_ref = state.empaque_ref;
    state.itemID = getFirestoreID();
    delete state.invernadero
    //console.log("STATE: ",state)
    if (props.modificandoItem) {
      let [index, item] = props.modificandoItem
      props.actualizarItem(index, state)
    }
    else { props.agregarItem(state); }

    props.setItemsActualizados(Date.now());
    setState(Object.assign({}, inicial));
  }
  useEffect(() => {
    if (invernaderosVirtuales) { setInvernaderosOrig(invernaderosVirtuales); }
  }, [invernaderosVirtuales])

  useEffect(() => {
    //Si es una presentación tipo medleys, solo filtrar invernaderos tipo medleys
    //Si es una presentación unica,  filtrar invernaderos medleys que contengan la misma variedad + invernaderos unicos de ese producto
    const invernaderosFiltrados = getInvernaderosOpciones()
    const invAgrupados = mapeoOpcionesInvsPorProductoYVariedad(invernaderosFiltrados, productosBases, clientes)
    setInvernaderos(invAgrupados);
  }, [state.presentacion, invernaderosVirtuales])
  useEffect(() => {
    if (!invernaderos?.some(grupoDatos => grupoDatos?.options?.some(inv => inv.id === state?.invernadero?.id))) { setState({ ...state, invernadero: "" }); }
  }, [invernaderos, state.invernadero])
  useEffect(() => {
    if (!state?.invernadero?.id && state?.invernadero_ref && invernaderosVirtuales?.length > 0) {
      const invernadero = getObjetoPorID(invernaderosVirtuales, state.invernadero_ref);
      console.log("DONE - GOT INVERNADERO FROM EDIT! --- ", { ...state, invernadero })
      setState({ ...state, invernadero })
    }
  }, [state.invernadero_ref, invernaderosVirtuales])
  useEffect(() => {
    if (state && state.presentacion && state.cajas_orden) {
      let cajas = parseFloat(state.cajas_orden);
      if (!isNaN(cajas)) {
        let pallets_orden = dosDecimales(cajas / state.presentacion.numero_cajas_pallet);
        const stateCopy = Object.assign({}, state);
        stateCopy["pallets_orden"] = pallets_orden;
        setState(stateCopy);
      }
    } else {
      const stateCopy = Object.assign({}, state);
      stateCopy["pallets_orden"] = "";
      setState(stateCopy);
    }
  }, [state.presentacion, state.cajas_orden])

  useEffect(() => {
    obtenerPresentacionesHabilitadas().then((resp) => {
      setPresentacionesOrig(resp);
    }).catch((err) => {
      console.log("HUBO UN ERROR AL OBTENER PRESENTACIONES ", err)
    });
  }, [])
  useEffect(() => {

    let cliente = clienteSeleccionado;
    if (cliente && presentacionesOrig.length > 0) {
      let copia = presentacionesOrig.filter(el => {
        return el.cliente_ref === cliente.id;
      })

      const newOptions = mapDeDataDropdownDePresentaciones(copia, [clienteSeleccionado], productosTodos)
      setPresentacionesOpciones(newOptions)
      //setInvernaderosOpciones(invernaderosOpciones)
      setPresentaciones(copia);
    }
  }, [clienteSeleccionado, presentacionesOrig])
  useEffect(() => {
    if (props.modificandoItem) {
      let [index, item] = props.modificandoItem;
      let copia = { ...item }
      copia["invernadero"] = invernaderosVirtuales.find(inv => inv.id === item.invernadero_ref);
      setState(copia);
    }
  }, [props.modificandoItem])

  const ordenarInvernaderosOpciones = (invernaderosOpciones, presentacion) => {
    if (!presentacion) { return invernaderosOpciones; }

    if (presentacion.mezcla) {
      const invernaderosDelCliente = invernaderosOpciones.filter(inv => inv.cliente_ref === state.presentacion?.cliente_ref);
      const invernaderosOtrosClientes = invernaderosOpciones.filter(inv => inv.cliente_ref !== state.presentacion?.cliente_ref);
      return [...invernaderosDelCliente, ...invernaderosOtrosClientes];
    }
    else {
      const invernaderosUnicosDelCliente = invernaderosOpciones.filter(inv => !inv.mezcla && inv.cliente_ref === state.presentacion?.cliente_ref);
      const invernaderosUnicosOtrosClientes = invernaderosOpciones.filter(inv => !inv.mezcla && inv.cliente_ref !== state.presentacion?.cliente_ref);
      const invernaderosMedleysDelCliente = invernaderosOpciones.filter(inv => inv.mezcla && inv.cliente_ref === state.presentacion?.cliente_ref);
      const invernaderosMedleysOtrosClientes = invernaderosOpciones.filter(inv => inv.mezcla && inv.cliente_ref !== state.presentacion?.cliente_ref);
      return [...invernaderosUnicosDelCliente, ...invernaderosMedleysDelCliente, ...invernaderosUnicosOtrosClientes, ...invernaderosMedleysOtrosClientes];
    }
  }

  const cancelar = () => {
    props.cancelar()
    setState(Object.assign({}, inicial));
  }
  const validar = () => {
    //let requeridos = ["invernadero","po","presentacion","cajas_orden","pallets_orden"]
    let requeridos = ["invernadero", "presentacion", "cajas_orden"];
    for (let index = 0; index < requeridos.length; index++) {
      const element = requeridos[index];
      if (state[element] && state[element] !== "") { }
      else { return false; }
    }
    //para invernaderos externos
    if (state?.invernadero?.es_externo && !state.empaque_ref) { return false; }
    return true;
  }

  const getInvernaderosOpciones = () => {
    if (!state?.presentacion?.id) { return []; }

    let invernaderosOpciones = invernaderosVirtuales?.slice();
    if (invernaderosVirtuales && state?.presentacion?.mezcla) {
      invernaderosOpciones = invernaderosOpciones.filter(inv => inv.mezcla);
    }
    else if (invernaderosVirtuales && !state?.presentacion?.mezcla) {
      invernaderosOpciones = invernaderosOpciones.filter(inv => {
        if (inv.mezcla) {
          const productoDelInv = getObjetoPorID(productosTodos, inv.producto_ref)
          return productoDelInv.variedades.some(mezclaVar => {
            return mezclaVar.producto_ref === state?.presentacion?.producto_ref;
          });
        }
        else { return state?.presentacion?.producto_ref === inv.producto_ref; }
      });
    }
    if (!state?.presentacion?.id) { return invernaderosOpciones; }

    if (state?.presentacion?.mezcla) {
      const invernaderosDelCliente = invernaderosOpciones.filter(inv => inv.cliente_ref === state?.presentacion?.cliente_ref);
      const invernaderosOtrosClientes = invernaderosOpciones.filter(inv => inv.cliente_ref !== state?.presentacion?.cliente_ref);
      return [...invernaderosDelCliente, ...invernaderosOtrosClientes];
    }
    else {
      const invernaderosUnicosDelCliente = invernaderosOpciones.filter(inv => !inv.mezcla && inv.cliente_ref === state?.presentacion?.cliente_ref);
      const invernaderosUnicosOtrosClientes = invernaderosOpciones.filter(inv => !inv.mezcla && inv.cliente_ref !== state?.presentacion?.cliente_ref);
      const invernaderosMedleysDelCliente = invernaderosOpciones.filter(inv => inv.mezcla && inv.cliente_ref === state?.presentacion?.cliente_ref);
      const invernaderosMedleysOtrosClientes = invernaderosOpciones.filter(inv => inv.mezcla && inv.cliente_ref !== state?.presentacion?.cliente_ref);
      return [...invernaderosUnicosDelCliente, ...invernaderosMedleysDelCliente, ...invernaderosUnicosOtrosClientes, ...invernaderosMedleysOtrosClientes];
    }
  }

  return (
    <div className="p-grid">
      <div className="p-col-12 pb-0">
        <p className="section-title m-0">Información general</p>
      </div>
      <div className="form-group p-col-12 p-md-4 ">
        <label htmlFor="po">PO</label>
        <InputText id="po" onChange={handleInputChange} name="po" value={state.po} />
      </div>
      <div className="form-group p-col-12 p-md-4">

        <label htmlFor="cajas_orden">Cajas en la orden</label>
        <InputText id="cajas_orden" onChange={handleInputChange} name="cajas_orden" value={state.cajas_orden} />
      </div>
      <div className="form-group p-col-12 p-md-4">
        <label htmlFor="pallets_orden">Pallets en la orden</label>
        <p className="form-output">{state.pallets_orden}</p>
      </div>
      <div className="p-col-12 pb-0">
        <p className="section-title m-0">Presentación y origen</p>
      </div>

      <DropdownPresentacionesHTG name="presentacion" options={presentacionesOpciones} placeholder="Seleccionar una presentación"
        onChange={handleInputChange} value={state?.presentacion} dataKey="id" optionLabel="id_presentacion" label="ID de presentación" md={6} col={12} />

      <DropdownInvernaderosHTG name="invernadero" options={invernaderos} placeholder="Seleccionar un invernadero"
        onChange={handleInputChange} value={state?.invernadero || getObjetoPorID(invernaderosVirtuales, state.invernadero_ref)} dataKey="id" optionLabel="nombre" label="Invernadero" md={6} col={12} />

      {state.invernadero?.es_externo &&
        <div className="form-group p-md-6 p-col-12">
          <label htmlFor="invernadero">Sitio de empaque</label>
          <Dropdown id="empaque_ref" placeholder="Seleccionar invernadero"
            onChange={handleInputChange}
            value={state.empaque_ref}
            optionLabel="nombre" optionValue="id"
            options={sitiosEmpaques}
            name="empaque_ref"
          />
        </div>}
      {state.presentacion && state.presentacion["id_presentacion"] ?
        <div className="form-group p-col p-col-auto">
          <div>Item</div>
          <p className="form-output">{state.presentacion["item"]}</p>
        </div>
        :
        null
      }
      {state.presentacion && state.presentacion["id_presentacion"] ?
        <div className="form-group p-col p-col-auto">
          <div>Descripción de presentación</div>
          <p className="form-output">{state.presentacion["descripcion"]}</p>
        </div>
        :
        null
      }
      {state.presentacion && state.presentacion["id_presentacion"] ?
        <div className="form-group p-col p-col-auto">
          <div>Descripción</div>
          <p className="form-output">{state.presentacion["presentacion"]}</p>
        </div>
        :
        null
      }
      {error ? <Message severity="error" text="Los campos de invernadero, presentación y cajas son requeridos" /> : null}
      <div className="p-col-12 p-justify-end btn-row pb-0">
        <div className="p-grid ">
          <div className="p-col-12 py-0">
            <Button label={props.agItem ? "Agregar item" : "Guardar"} onClick={guardarItem} />
            <Button label="CANCELAR" onClick={cancelar} />
          </div>
        </div>
      </div>
    </div>)
}//304