
import { horaCorte, nombresDiasSemanaPlanos, segundosSemana } from "../constants";
import { obtenerCiclo } from "./Ciclos";
import { flexDate, obtenerDiaActual, obtenerDiasDeSemana, obtenerHora, obtenerInicioSemanaDia } from "./fechas";
import { obtenerInvernaderoProveedorProducto } from "./Invernaderos";
import { obtenerRegistrosCompraExternaSemana, registrarCompraExternaDia } from "./Queries/CompraExterna";
import { obtenerProveedores, obtenerProveedoresHabilitados, obtenerProveedorPorUsuario } from "./Queries/Proveedores";
import * as firebase from "firebase/app";
import "firebase/firestore";

import { uploadStatistics } from './Estadisticas'
import { getFirestoreID } from "./Mantenimiento";

function registrarInvocaciones(count) {
    uploadStatistics(count)
}

export const obtenerDatosCompraExterna = async (cicloId, semana, usuarioId, setCosechaIdsCallback) => {
    try {
        let proveedor = await obtenerProveedorPorUsuario(cicloId, usuarioId)

        let datos = await obtenerRegistrosCompraExternaSemana(cicloId, proveedor.id, semana)
        let procesado = procesarCompraExternaConsulta(datos, semana, setCosechaIdsCallback);
        return procesado;
    } catch (error) {
        console.log("ERROR ODCE");
        console.log(error);
    }
}
const procesarCompraExternaConsulta = (datos, semana, setCosechaIdsCallback) => {

    let dias = obtenerDiasDeSemana(semana);
    const cosechaIds = []

    let diaria = { label: "Diario" }
    let semanal = { label: "Semanal" };
    let datoHtg = { label: "DatoHTG" };
    dias.forEach((dia, index) => {
        const timestamps = flexDate(dia);
        let datoDia = datos.find(el => el.dia >= timestamps.inicio && el.dia < timestamps.fin);
        cosechaIds.push(datoDia?.id || getFirestoreID())

        diaria[dia] = datoDia ? datoDia.diaria : 0;
        semanal[dia] = datoDia ? datoDia.semanal : 0;
        datoHtg[dia] = datoDia ? datoDia.datoHTG : 0;
    })
    setCosechaIdsCallback(cosechaIds)
    return [semanal, diaria, datoHtg];
}

export function guardarCapturaCompraExterna(ciclo, proveedorId, semana, tabla, cosechaFirestoreIds) {
    return new Promise((resolve, reject) => {
        let timesDias = obtenerDiasDeSemana(semana);
        let promises = [];
        //por cada dia
        let dias = nombresDiasSemanaPlanos;
        for (let x = 0; x < dias.length; x++) {
            console.log(tabla[2][timesDias[x]])
            promises.push(registrarCompraExternaDia(ciclo, proveedorId, timesDias[x], tabla[0][timesDias[x]], tabla[1][timesDias[x]], tabla[2][timesDias[x]], cosechaFirestoreIds[x]));
        }
        Promise.all(promises).then(values => {
            resolve(values);
        }).catch(err => {
            reject(err);
        })
    })
}
export function obtenerReporteCompraExterna(ciclo, semana) {

    let inicio = Date.now();
    let fin = 0;
    let dif = 0;
    return new Promise((resolve, reject) => {

        obtenerCiclo(ciclo).then(objCiclo => {
            // 
            // fin = Date.now();
            // dif = fin -inicio;
            // 
            obtenerProveedoresHabilitados(ciclo).then(proveedores => {
                // 
                // fin = Date.now();
                // dif = fin -inicio;
                // 
                let promesasResumen = [];
                proveedores.forEach(proveedor => {
                    promesasResumen.push(obtenerReporteCompraExternaProveedorSemana(ciclo, proveedor, semana))
                })
                Promise.all(promesasResumen).then(values => {


                    //     fin = Date.now();
                    // dif = fin -inicio;
                    // 
                    resolve(values);
                });
            })
        })

    })
}
export function obtenerReporteCompraExternaTodos(ciclo, semana, proveedores) {
    return new Promise((resolve, reject) => {

        obtenerCiclo(ciclo).then(objCiclo => {
            let promesasResumen = [];
            proveedores.forEach(proveedor => {
                promesasResumen.push(obtenerReporteCompraExternaProveedorSemana(ciclo, proveedor, semana))
            })
            Promise.all(promesasResumen).then(values => {
                resolve(values);
            });
        })

    })
}

export const obtenerReporteCompraExternaProveedorSemana = async (ciclo, proveedor, semana) => {
    try {
        let inicio = Date.now();
        let fin = 0;
        let dif = 0;
        const db = firebase.firestore();

        //obtener proyecciones
        const dias = obtenerDiasDeSemana(semana);
        let diarioRaw = 0;
        let semanalRaw = 0;
        let realRaw = 0;
        let area = 1;


        area = parseFloat(proveedor.area_invernadero);


        let proyecciones = await db.collection("ciclos").doc(ciclo).collection("proveedores_cosecha").doc(proveedor.id)
            .collection("compra_externa").where("dia", ">=", dias[0]).where("dia", "<=", dias[5]).get()
        registrarInvocaciones(proyecciones.size)
        fin = Date.now();
        dif = fin - inicio;

        let registros = [];
        proyecciones.forEach(el => {
            let data = el.data();
            data.id = el.id;
            registros.push(data);
        })
        let empaque = {kilos: 0}
        try {
            empaque = await obtenerPesoSemanaInteligenteReporteCompraExterna(ciclo, proveedor.id, semana, registros, proveedor.producto_ref);
        } catch (error) {
            console.log("ERRRO: ", error)
        }

        [diarioRaw, semanalRaw, realRaw] = obtenerDiarioSemanalReal(empaque, registros);
        return (procesarReporteSemanal(proveedor, semana, diarioRaw, semanalRaw, realRaw, area));
    } catch (error) {
        throw error
    }

}
export async function obtenerPesoSemanaInteligenteReporteCompraExterna(ciclo, proveedor, semana, proyecciones, productoId) {
    const db = firebase.firestore();
    let hoy = obtenerDiaActual();

    let hora = obtenerHora(Math.round(Date.now() / 1000));

    let kilos = 0;

    semana = obtenerInicioSemanaDia(semana).unix();
    //obtener empaque de este proveedor        
    let invernadero = await obtenerInvernaderoProveedorProducto(ciclo, productoId);

    let respEmpaques = await db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero.id).collection("empaques")
        .where("dia", ">=", semana).where("dia", "<", semana + 7 * 24 * 60 * 60).get()
    registrarInvocaciones(respEmpaques.size)
    let listaDias = obtenerDiasDeSemana(semana);
    let empaques = [];
    respEmpaques.forEach(empaque => {
        let data = empaque.data();
        empaques.push(data);
    })

    listaDias.forEach(dia => {
        const timestamp = flexDate(dia)
        let empaque = empaques.find(el => timestamp.inicio <= el.dia && timestamp.fin >= el.dia);
        if (dia > hoy) {
            let cosecha = proyecciones.find(el => el && timestamp.inicio <= el.dia && timestamp.fin >= el.dia);
            if (cosecha) {
                let diario = parseFloat(cosecha["datoHTG"]);
                let dato = parseFloat(cosecha["semanal"]);
                if (diario && !isNaN(diario)) {
                    dato = diario
                }
                if (!isNaN(dato)) {
                    kilos += dato;

                }
            }

        } else if (dia === hoy && hora < horaCorte) {
            let cosecha = proyecciones.find(el => el && timestamp.inicio <= el.dia && timestamp.fin >= el.dia);
            if (cosecha) {
                let dato = parseFloat(cosecha["datoHTG"]);

                if (!isNaN(dato)) {
                    kilos += dato;

                }
            }

        }
        else if (empaque && empaque.pallets) {
            let sumaKilos = empaque.pallets.reduce((acc, curr) => {
                let cant = parseFloat(curr.cantidad);
                if (isNaN(cant) || !(curr.proveedor_ref === proveedor)) {
                    return acc;
                } else {
                    return acc + cant;
                }

            }, 0);

            kilos += sumaKilos;
        }
    });
    
    return { kilos };
}
export const obtenerDiarioSemanalReal = (empaque, proyecciones) => {

    let hoy = obtenerDiaActual();
    let diarioRaw = 0;
    let semanalRaw = 0;

    let realRaw = empaque;
    proyecciones.forEach(proyeccion => {
        let data = proyeccion
        let diaria = parseFloat(data.datoHTG);
        let semanal = parseFloat(data.semanal);

        // si dia despues de hoy, buscar en semanal
        if (data.dia > hoy && !isNaN(semanal)) {
            if (!isNaN(diaria)) {
                diarioRaw += diaria;
            } else {
                diarioRaw += semanal;
            }

        }
        else if (!isNaN(diaria)) {
            diarioRaw += diaria;
        }
        if (!isNaN(semanal)) {
            semanalRaw += semanal;
        }
    })

    return [diarioRaw, semanalRaw, realRaw]
}
function procesarReporteSemanal(proveedor, semana, diarioRaw, semanalRaw, realRaw, area) {

    let kilos = {
        diario: diarioRaw,
        semanal: semanalRaw,
        real: realRaw["kilos"]
    }
    let kilos_m2 = {
        diario: Math.round(100 * kilos["diario"] / area) / 100,
        semanal: Math.round(100 * kilos["semanal"] / area) / 100,
        real: realRaw["kilos"] / area
    }


    return ([proveedor, semana, kilos, kilos_m2])
}
export const obtenerRealesProveedor = async (cicloId, proveedorId, invernaderoId, semana) => {
    const db = firebase.firestore();
    let snap = await db.collection("ciclos").doc(cicloId).collection("invernaderos_virtuales").doc(invernaderoId)
        .collection("empaques").where("dia", ">=", semana).where("dia", "<", semana + segundosSemana).get();
    registrarInvocaciones(snap.size)
    let data = snap.docs.map(el => ({ id: el.id, ...el.data() }));
    data.forEach(datoDia => {
        datoDia["pallets"] = datoDia["pallets"].filter(el => el.proveedor_ref === proveedorId);
    })
    return data;

}//307