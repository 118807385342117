import React, { useState, useEffect, useGlobal } from 'reactn'
import ContenedorPrincipal from '../common/ContenedorPrincipal'
import ContenedorHeader from '../common/ContenedorHeader'
import SeccionFiltros from '../common/SeccionFiltros'
import ContenedorFiltro from '../common/ContenedorFiltro'
import ChartContainer from '../common/ChartContainer'
import { Dropdown } from 'primereact/dropdown'
import { MultiSelect } from 'primereact/multiselect'
import { obtenerClientes, obtenerClientesCiclo } from '../../service/Clientes'
import { obtenerDistribucionGruposPresentacionesCiclo, obtenerDistribucionPresentacionesCiclo, obtenerRegistrosCalidadPesosSemana } from '../../service/Calidad'
import Chart from "react-apexcharts";
import { DeepClone, dosDeciComas, dosDecimales, getObjetoPorID, ordenarArreglaDeObjetosPorLlave, ordenarObjetosPorNombre, paletaColores, paletaSemaforo, separadoComas } from '../../util/functions'
import { obtenerPresentaciones } from '../../service/Presentaciones'
import { obtenerResumenSemanalPresentacionPesosCalidad } from '../../service/ReporteCalidad'
import { obtenerGruposPresentaciones } from '../../service/QueriesGruposPresentaciones'
import { ToggleButton } from 'primereact/togglebutton';
import ReportePesosTotal from '../calidad/ReportePesosTotal'
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { obtenerDiasDeSemanaConDetalles, obtenerListaDeSemanas } from '../../service/fechas';
import { ASCENDIENTE, DESCENDIENTE, TIPO_GRUPO, TIPO_PRES } from '../../constants'
import { UnidadKilosBg } from 'components/common/templates_unidades/UnidadKg.atomo'
import { obtenerEmpacadoras } from 'service/Empacadoras'
import { obtenerOrdenesCiclo } from 'service/Ordenes'

const EmpaqueEmpacadora = (props) => {
    let tooltipFunction = (datos) => {
        return (value, { series, seriesIndex, dataPointIndex, w }) => {
            let average = datos[seriesIndex]["averages"][dataPointIndex]

            return average
        }

    }
    let optionsOrig = {
        tooltip: {
            y: {
                formatter: tooltipFunction
            }
        },
        colors: paletaSemaforo,
        chart: {
            height: 350,
            stacked: true,
            type: "bar",
            stackType: "100%",
            animations: {
                enabled: false
            }
        },


        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: 'bottom',
                    offsetX: -10,
                    offsetY: 0,
                    colors: paletaColores
                }
            }
        }],
        xaxis: {
            categories: [],
        },
        yaxis: {
            decimalsInFloat: 2
        },
        plotOptions: {
            bar: {
                horizontal: true,
            }
        },
        fill: {
            opacity: 1
        },
        legend: {
            colors: paletaColores,
            position: 'right',
            offsetX: 0,
            offsetY: 400,
        },
    }

    const [presentaciones, setPresentaciones] = useState([])
    const [cicloId] = useGlobal("ciclo")
    const [cicloObj] = useGlobal("cicloObj")
    const [options, setOptions] = useState({ ...optionsOrig })
    const [invernaderos, setInvernaderos] = useState([])
    const [filtroInvernadero, setFiltroInvernadero] = useState(props.location?.data?.invernadero);
    const [, setCargando] = useGlobal("cargando")
    const [, setNombreModulo] = useGlobal("nombreModulo")
    const [clientes, setClientes] = useState([])
    const [filtroClientes, setFiltroClientes] = useState([])
    const [presentacionesOrig, setPresentacionesOrig] = useState([])
    const [gruposFiltro, setGruposFiltro] = useState(true)
    const [datos, setDatos] = useState([])
    const [gruposPresentacionesOriginales, setGruposPresentacionesOriginales] = useState([])
    const [gruposPresentaciones, setGruposPresentaciones] = useState([])
    const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector();
    const [diasSemana, setDiasSemana] = useState([])
    const [filtroDias, setFiltroDias] = useState()
    const [filtroPresentaciones, setFiltroPresentaciones] = useState([])
    const [filtroGruposPresentaciones, setFiltroGruposPresentaciones] = useState([]);
    const [distribucionGrupos, setDistribucionGrupos] = useState({})
    const [distribucion, setDistribucion] = useState({})
    const [productosYVariedades] = useGlobal("productosYVariedades")
    const [productosBases] = useGlobal("productosBases")
    const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales")
    const [registrosCalidad, setRegistrosCalidad] = useState({})
    const [empacadoras, setEmpacadoras] = useState()
    const [ordenesDelCiclo, setOrdenesDelCiclo] = useState()

    useEffect(() => {
        if (!gruposFiltro) {
            const presentacionesDelGrupo = presentaciones?.filter(pres => filtroGruposPresentaciones?.some(grupo => grupo.presentaciones_refs?.includes(pres.id))) || [];
            setFiltroPresentaciones(presentacionesDelGrupo)
        }
        else {
            const grupoDePrimerPresentacion = gruposPresentaciones?.find(grupo => grupo.presentaciones_refs?.includes(filtroPresentaciones[0]?.id));
            if (grupoDePrimerPresentacion) setFiltroGruposPresentaciones([grupoDePrimerPresentacion]);
            //else setFiltroGruposPresentaciones([]);
        }
    }, [gruposFiltro])

    useEffect(() => {
        let dias = obtenerDiasDeSemanaConDetalles(week.time);
        dias.push({ unixTime: 0, abreviacion: 'T', nombre: 'semana', nombrePlano: "semana" })
        setDiasSemana(dias);
        setFiltroDias("semana");
    }, [week.time])

    useEffect(() => {
        setNombreModulo("Calidad")
        if (cicloId) { obtenerDatosIniciales(); }
    }, [cicloId])
    useEffect(() => {//Seleccionar cliente por defecto
        if (filtroInvernadero?.cliente_ref && clientes?.length > 0) {
            const clienteDelInvernadero = getObjetoPorID(clientes, filtroInvernadero.cliente_ref);
            const copiaClienteDelInvernadero = DeepClone(clienteDelInvernadero);
            setFiltroClientes([copiaClienteDelInvernadero])
        }
        //else if(filtroInvernadero?.cliente_ref && clientes?.length > 0)
    }, [filtroInvernadero, clientes])
    //Este codigo es un pequeno truco para forzar a apexcharts a reconocer el cambio del tooltip
    useEffect(() => {
        let f = options["tooltip"]["y"]["formatter"]
        let copia = { ...options }
        if (typeof (f) === "object") {
            copia["tooltip"] = {};
            copia["tooltip"]["y"] = {};
            copia["tooltip"]["y"]["formatter"] = tooltipFunction(datos.slice());

            setOptions(copia)
        }
    }, [datos])

    useEffect(() => {
        if (gruposPresentacionesOriginales) { actualizarDatosDeGruposYPresentaciones() }
    }, [filtroClientes, gruposPresentacionesOriginales])
    useEffect(() => {
        if (gruposFiltro && filtroInvernadero && filtroGruposPresentaciones?.length > 0 && registrosCalidad) { obtenerDatosResumen(filtroGruposPresentaciones, TIPO_GRUPO); }
        else if (!gruposFiltro && filtroInvernadero && filtroPresentaciones?.length > 0 && registrosCalidad) { obtenerDatosResumen(filtroPresentaciones, TIPO_PRES); }
        else { setDatos([]); }
    }, [filtroGruposPresentaciones, filtroPresentaciones])
    useEffect(() => {
        if (filtroInvernadero?.id && cicloId) { getDatosRegistrosCalidad() }
    }, [filtroInvernadero, cicloId])
    useEffect(() => {

    }, [registrosCalidad])
    const getDatosRegistrosCalidad = async () => {
        let semanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
        let promises = semanas.map(semana => obtenerRegistrosCalidadPesosSemana(cicloId, filtroInvernadero?.id, semana.time));
        let result = await Promise.all(promises);
        const registrosCopia = DeepClone(registrosCalidad);
        registrosCopia[filtroInvernadero?.id] = result;

        console.log("REGISTROS CALIDAD ", { result, filtroInvernadero, registrosCopia });
        setRegistrosCalidad(registrosCopia)
    }

    const obtenerDatosIniciales = async () => {
        const [presentaciones, gruposPresentaciones, clientesCiclo, todosClientes, empacadoras, ordenesCiclo]
            = await Promise.all([obtenerPresentaciones(), obtenerGruposPresentaciones(), obtenerClientesCiclo(cicloId), obtenerClientes(), obtenerEmpacadoras(), obtenerOrdenesCiclo(cicloId)]);
        const clientes = clientesCiclo.map(el => getObjetoPorID(todosClientes, el.cliente_ref));

        setOrdenesDelCiclo(ordenesCiclo);
        setClientes(clientes);
        setInvernaderos(invernaderosVirtuales);
        setPresentacionesOrig(presentaciones);
        setGruposPresentacionesOriginales(gruposPresentaciones);
        setEmpacadoras(empacadoras);
    }

    ////////////////filtrar grupos y presentaciones////////////////
    const actualizarDatosDeGruposYPresentaciones = async () => {
        setCargando(true);
        const clientesSumaKg = {};
        clientes.forEach(cli => { clientesSumaKg[cli.id] = { sumaKgPresentaciones: 0, sumaKgGrupos: 0 }; })

        let gruposSeleccionadosFiltrados = [], gruposOpcionesFiltrados = [], presentacionesSeleccionadosFiltrados = [], presentacionesOpcionesFiltrados = [];
        if (filtroClientes?.length > 0) {
            const { distribucionGruposResp, distribucionResp } = await actualizarDistribucionGruposYPresentaciones();
            [gruposSeleccionadosFiltrados, gruposOpcionesFiltrados] = filtrarGruposOPresentaciones(filtroInvernadero, filtroClientes, filtroGruposPresentaciones, gruposPresentacionesOriginales, distribucionGruposResp);//filtrar grupos
            [presentacionesSeleccionadosFiltrados, presentacionesOpcionesFiltrados] = filtrarGruposOPresentaciones(filtroInvernadero, filtroClientes, filtroPresentaciones, presentacionesOrig, distribucionResp);//filtrar grupos
            console.log("ACTUALIZANDO: ", { clientesSumaKg, distribucionGruposResp, distribucionResp, gruposOpcionesFiltrados, presentacionesOpcionesFiltrados })

            Object.entries(distribucionGruposResp).forEach(([field, kilos]) => {
                const grupoObj = getObjetoPorID(gruposPresentacionesOriginales, field)
                //console.log("CL GRUPOS: ", { grupoObj, field, kilos })
                if (clientesSumaKg[grupoObj.cliente_ref]) { clientesSumaKg[grupoObj.cliente_ref].sumaKgGrupos += isNaN(parseFloat(kilos)) ? 0 : parseFloat(kilos); }
            })
            Object.entries(distribucionResp).forEach(([field, kilos]) => {
                const presentacionObj = getObjetoPorID(presentacionesOrig, field)
                //console.log("CL PRES: ", { presentacionObj, field, kilos })
                if (clientesSumaKg[presentacionObj.cliente_ref]) { clientesSumaKg[presentacionObj.cliente_ref].sumaKgPresentaciones += isNaN(parseFloat(kilos)) ? 0 : parseFloat(kilos); }
            })
        }

        //distribucionGrupos?.[grupo?.id || 0] || 0
        setGruposPresentaciones(gruposOpcionesFiltrados);
        setPresentaciones(presentacionesOpcionesFiltrados);
        setFiltroGruposPresentaciones(gruposSeleccionadosFiltrados);
        setFiltroPresentaciones(presentacionesSeleccionadosFiltrados);
        setCargando(false);
    }

    const actualizarDistribucionGruposYPresentaciones = async () => {
        const clientesSeleccionadosIDs = filtroClientes?.map(cli => cli.id) || [];
        let distribucionGruposResp = await obtenerDistribucionGruposPresentacionesCiclo(cicloId, gruposPresentacionesOriginales, clientesSeleccionadosIDs, invernaderos, ordenesDelCiclo, presentacionesOrig);
        let distribucionResp = await obtenerDistribucionPresentacionesCiclo(cicloId, presentacionesOrig, clientesSeleccionadosIDs, invernaderos, ordenesDelCiclo);
        setDistribucionGrupos(distribucionGruposResp);
        setDistribucion(distribucionResp);
        return { distribucionGruposResp, distribucionResp };
    }
    const filtrarGruposOPresentaciones = (filtroInv, filtroCli, filtroPresOGrupo, opcionesOriginales, distribucionTipoResp) => {
        let opcionesFiltrados = [];
        let clienteIds = filtroCli.map(cli => cli.id);
        const filtroInvProducto = getObjetoPorID(productosYVariedades, filtroInv?.producto_ref);
        const opcionesDeProductoDelInvernadero = opcionesOriginales.filter(el =>  clienteIds.includes(el.cliente_ref) &&
            (el.producto_ref === filtroInv?.producto_ref || (getObjetoPorID(productosBases, el.producto_ref).MEDLEYS_GENERAL && filtroInv?.mezcla)));

        if (filtroInvProducto?.mezcla) { opcionesFiltrados = opcionesDeProductoDelInvernadero.filter(el => el.cliente_ref === filtroInv.cliente_ref && el.mezcla) }
        else if (filtroInv && filtroCli.length > 0) { opcionesFiltrados = opcionesDeProductoDelInvernadero.filter(el => clienteIds.includes(el.cliente_ref)) }
        else if (filtroInv && filtroCli.length === 0) { opcionesFiltrados = opcionesDeProductoDelInvernadero.filter(el => el.cliente_ref === filtroInv.cliente_ref) }

        const isPresentacion = opcionesFiltrados?.[0]?.presentacion
        const keyAComparar = isPresentacion ? "presentacion" : "nombre"
        let newOpcionesFiltrados = ordenarArreglaDeObjetosPorLlave(opcionesFiltrados, keyAComparar, DESCENDIENTE, true);

        const opcionesSeleccionadosFiltrados = filtroPresOGrupo.filter(pres => newOpcionesFiltrados.some(el => el.id === pres.id))
        newOpcionesFiltrados = ordenarGruposOPresentacionesPorInvernaderoYDistribucion(opcionesDeProductoDelInvernadero, distribucionTipoResp);
        return [opcionesSeleccionadosFiltrados, newOpcionesFiltrados];
    }
    const ordenarGruposOPresentacionesPorInvernaderoYDistribucion = (opciones, distribucionTipoResp) => {
        let opcionesCopia = DeepClone(opciones);
        const clientesCopia = DeepClone(filtroClientes);
        const clienteSeleccionado = getObjetoPorID(clientes, filtroInvernadero.cliente_ref);
        const clientesNoSeleccionadosOrdenados = clientesCopia.filter(el => el.id !== filtroInvernadero.cliente_ref);

        const isNombreCliente = clientesNoSeleccionadosOrdenados?.[0]?.nombreCliente
        const keyAComparar = isNombreCliente ? "nombreCliente" : "nombre"
        const newClientesNoSeleccionadosOrdenados = ordenarArreglaDeObjetosPorLlave(clientesNoSeleccionadosOrdenados, keyAComparar, ASCENDIENTE, true);

        const clientesOrdenados = [clienteSeleccionado, ...newClientesNoSeleccionadosOrdenados];

        //2D array separado por cliente
        const gruposOpcionesPorCliente = clientesOrdenados.map(cli => opcionesCopia.filter(opcion => cli.id === opcion.cliente_ref && distribucionTipoResp[opcion.id] > 0));//No mostrar opciones sin kg vendidos
        gruposOpcionesPorCliente.forEach(grupo => {
            grupo.sort((a, b) => {
                const distribucionKGa = distribucionTipoResp[a.id] || 0;
                const distribucionKGb = distribucionTipoResp[b.id] || 0;
                return (distribucionKGa < distribucionKGb) ? 1 : -1
            });
        })
        return gruposOpcionesPorCliente.flat();
    };

    ////////////////////////end filtrar////////////////////////


    const obtenerDatosResumen = async (filtrosPres, tipoGrupo) => {
        try {
            setCargando(true);
            let idsPresentaciones = [];
            if (tipoGrupo) {
                idsPresentaciones = filtrosPres.map(grupo => grupo.presentaciones_refs);
                idsPresentaciones = idsPresentaciones.flat();
            }
            else { idsPresentaciones = filtrosPres.map(pres => pres.id); }
            let filtroPresentaciones = presentacionesOrig.filter(el => idsPresentaciones.includes(el.id));
            let resumen = await obtenerResumenSemanalPresentacionPesosCalidad(cicloObj, filtroInvernadero.id, filtroPresentaciones, empacadoras, registrosCalidad[filtroInvernadero?.id]);
            let procesado = procesarResumen(resumen, filtroGruposPresentaciones, presentacionesOrig, tipoGrupo);

            const copiaOptions = { ...options, xaxis: { categories: resumen.map(el => el.semana) }, tooltip: { y: { formatter: {} } } };
            setOptions(copiaOptions);
            setDatos(procesado)
        } catch (error) {
            throw error
        }
        setCargando(false);
    }

    const procesarResumen = (resumen, filtroGruposPresentaciones, presentaciones, tipoGrupo) => {
        let serieAlto = { name: "Alto", data: [], averages: [] }
        let serieNormal = { name: "Normal", data: [], averages: [] }
        let serieBajo = { name: "Bajo", data: [], averages: [] }
        let sumaPesoAlto = 0, sumaPesoNormal = 0, sumaPesoBajo = 0, totalAlto = 0, totalBajo = 0, totalNormal = 0;

        resumen.forEach(semana => {
            serieAlto["data"].push(semana["altos"])
            serieBajo["data"].push(semana["bajos"])
            serieNormal["data"].push(semana["normales"])
            sumaPesoAlto += semana["sumaPesoAltos"]
            sumaPesoNormal += semana["sumaPesoNormales"]
            sumaPesoBajo += semana["sumaPesoBajos"]
            totalAlto += semana["altos"]
            totalNormal += semana["normales"]
            totalBajo += semana["bajos"]
            let avgAlto = totalAlto > 0 ? sumaPesoAlto / totalAlto : 0;
            let avgNormal = totalNormal > 0 ? sumaPesoNormal / totalNormal : 0;
            let avgBajo = totalBajo > 0 ? sumaPesoBajo / totalBajo : 0;
            if (filtroGruposPresentaciones.length === 1 && tipoGrupo) {
                let primeraPresentacionRef = filtroGruposPresentaciones[0].presentaciones_refs[0];
                let primeraPresentacion = presentaciones.find(el => el.id === primeraPresentacionRef);

                let pesoIdeal = parseFloat(primeraPresentacion.peso_neto_presentacion);
                avgAlto = semana["altos"] > 0 ? semana["sumaPesoAltos"] / semana["altos"] : 0
                avgNormal = semana["normales"] > 0 ? semana["sumaPesoNormales"] / semana["normales"] : 0
                avgBajo = semana["bajos"] > 0 ? semana["sumaPesoBajos"] / semana["bajos"] : 0

                serieAlto["averages"].push(dosDecimales(100 * (avgAlto - pesoIdeal) / pesoIdeal) + " %")
                serieNormal["averages"].push(dosDecimales(100 * (avgNormal - pesoIdeal) / pesoIdeal) + " %")
                serieBajo["averages"].push(dosDecimales(100 * (avgBajo - pesoIdeal) / pesoIdeal) + " %")
            } else {
                serieAlto["averages"].push(dosDecimales(avgAlto) + " kg")
                serieNormal["averages"].push(dosDecimales(avgNormal) + " kg")
                serieBajo["averages"].push(dosDecimales(avgBajo) + " kg")
            }
        })
        return [serieBajo, serieNormal, serieAlto]
    }

    const itemTemplateGrupos = (itemDatos) => { return (<label> <strong>{itemDatos.nombre}</strong> - <UnidadKilosBg valor={dosDeciComas(distribucionGrupos?.[itemDatos?.id || 0] || 0)} /> </label>); }
    const itemTemplatePresentaciones = (itemDatos) => { return (<label > <strong>{itemDatos.presentacion}</strong>  - <UnidadKilosBg valor={dosDeciComas(distribucion?.[itemDatos?.id || 0] || 0)} /> </label>); }

    const filtroClientesValue = filtroClientes[0];
    const filtroGruposPresentacionesValue = filtroGruposPresentaciones[0];
    const filtroPresentacionesValue = filtroPresentaciones[0];
    return (
        <ContenedorPrincipal extra="chart-page">
            <ContenedorHeader titulo="Gráfica de calidad por presentaciones" iconos="chart-icon" atras={() => props.history.goBack()} />

            <SeccionFiltros>
                <ContenedorFiltro label="Invernadero">
                    <Dropdown value={filtroInvernadero} options={invernaderos} optionLabel="nombre" dataKey="id" onChange={e => setFiltroInvernadero(e.value)} />
                </ContenedorFiltro>
                <ContenedorFiltro label="Cliente">
                    <Dropdown options={clientes} value={filtroClientesValue} optionLabel="nombre" dataKey="id" onChange={e => setFiltroClientes([e.value])}
                        selectedItemsLabel={`${filtroClientes.length} clientes seleccionados`} />
                </ContenedorFiltro>
                <ContenedorFiltro label={gruposFiltro ? "grupos presentaciones" : "presentación"}>
                    {gruposFiltro
                        ? <Dropdown key="pres" value={filtroGruposPresentacionesValue} options={gruposPresentaciones} optionLabel="nombre" dataKey="id"
                            onChange={e => setFiltroGruposPresentaciones([e.value])} itemTemplate={itemTemplateGrupos} filter={true}
                            selectedItemsLabel={`${filtroGruposPresentaciones.length} grupos de presentaciones seleccionadas`} />
                        : <Dropdown key="grup" value={filtroPresentacionesValue} options={presentaciones} optionLabel="presentacion" dataKey="id"
                            onChange={e => setFiltroPresentaciones([e.value])} itemTemplate={itemTemplatePresentaciones} filter={true}
                            selectedItemsLabel={`${filtroPresentaciones.length} presentaciones seleccionadas`} />}
                </ContenedorFiltro>
                <ContenedorFiltro label="tipos">
                    <ToggleButton className="reactbtn-icon" onLabel="Grupos presentaciones" offLabel="Presentaciones" checked={gruposFiltro} onChange={(ev) => setGruposFiltro(ev.value)} ></ToggleButton>
                </ContenedorFiltro>
            </SeccionFiltros>

            <ChartContainer type="large">
                <Chart options={options} series={datos} type="bar" height="100%" width="100%" />
            </ChartContainer>

            <div className="p-col-12">
                <span className="titles-group">
                    <h1 className="header-title">Reporte total de calidad</h1>
                </span>
                <div className="p-grid filtros">
                    <div className="form-group p-col">
                        <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
                    </div>
                </div>
            </div>
            <ReportePesosTotal semana={week.time} filtroDias={filtroDias} dias={diasSemana} />
        </ContenedorPrincipal>)
}
export default EmpaqueEmpacadora;//410