import React, { useState, useEffect } from 'react'
import { obtenerCiclo } from '../../service/Ciclos';
import { obtenerListaDeSemanas } from '../../service/fechas';
import { obtenerReporteDefectos } from '../../service/ReporteCalidad';
import { useGlobal } from 'reactn';
import { obtenerDefectos } from '../../service/Defectos';
import Chart from "react-apexcharts";
import { SelectButton } from 'primereact/selectbutton';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { obtenerProveedores } from '../../service/Queries/Proveedores';

import { obtenerInvernaderosVirtuales } from '../../service/Invernaderos';
import { ToggleButton } from 'primereact/togglebutton';
import ContenedorFiltro from '../common/ContenedorFiltro'
import SeccionFiltros from '../common/SeccionFiltros'

import { dosDecimales, stringToColor } from '../../util/functions';

const selectData = [{ label: "Inicial", value: "dataInicial" }, { label: "Final", value: "dataFinal" }];
const optionsOrig = {
    plotOptions: {
        heatmap: {
          colorScale: {
            ranges: [{
                from: 0.001,
                to: 4,
                color: '#44A026',
                name: 'Bajo',
              },
              {
                from: 4.001,
                to: 8,
                color: '#d8b90d',
                name: 'Medio',
              },
              {
                from: 8.001,
                to: 12,
                color: '#E67E00',
                name: 'Alto',
              },
              {
                from: 12.001,
                to: 24,
                color: '#FF3C3C',
                name: 'Grave',
              }
            ]
          }
        }
      },
    chart: {
        height: 350,
        type: 'heatmap',
    },
    dataLabels: {
        enabled: false
    },
    colors: ["#008FFB"],
    title: {
        text: 'Porcentajes de defectos en Calidad'
    },
    tooltip: {
        y: {

            formatter: function (val) {
                return `${dosDecimales(val)} %`
            },
        }
    },
}
const HeatmapCalidad = (props) => {
    const [ciclo, setCiclo] = useGlobal("ciclo");
    const [cicloObj] = useGlobal("cicloObj");
    const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
    const [productosYVariedades] = useGlobal("productosYVariedadesTodos");
    const [ciclos,] = useGlobal("ciclos");
    const [invernadero, setInvernadero] = useState(props.location?.data?.invernadero);
    const [invernaderos, setInvernaderos] = useState([])
    const [cargando, setCargando] = useGlobal("cargando");

    const [dataInicialOrig, setDataInicialOrig] = useState([]);
    const [dataInicial, setDataInicial] = useState([]);
    const [dataFinalOrig, setDataFinalOrig] = useState([]);
    const [dataFinal, setDataFinal] = useState([]);

    const [options, setOptions] = useState({ ...optionsOrig });
    const [optionsCiclo1, setOptionsCiclo1] = useState({ ...optionsOrig });
    const [optionsCiclo2, setOptionsCiclo2] = useState({ ...optionsOrig });
    const [dataSrc, setDataSrc] = useState(selectData[0]["value"]);
    const [productos, setProductos] = useState([]);
    const [multiCiclo, setMultiCiclo] = useState(false)

    const [defectosFiltros, setDefectosFiltros] = useState([]);
    const [defectosFiltrosCiclo1, setDefectosFiltrosCiclo1] = useState([]);
    const [defectosFiltrosCiclo2, setDefectosFiltrosCiclo2] = useState([]);
    const [defectosListaInicial, setDefectosListaInicial] = useState([]);
    const [defectosListaFinal, setDefectosListaFinal] = useState([]);
    const [defectosListaInicialCiclo1, setDefectosListaInicialCiclo1] = useState([]);
    const [defectosListaFinalCiclo1, setDefectosListaFinalCiclo1] = useState([]);
    const [defectosListaInicialCiclo2, setDefectosListaInicialCiclo2] = useState([]);
    const [defectosListaFinalCiclo2, setDefectosListaFinalCiclo2] = useState([]);

    const [allCiclos, setAllCiclos] = useState([]);
    const [ciclo1, setCiclo1] = useState()
    const [ciclo2, setCiclo2] = useState()
    const [ciclo1Invernadero, setCiclo1Invernadero] = useState("")
    const [ciclo2Invernadero, setCiclo2Invernadero] = useState("")

    const [dataInicialCiclo1Orig, setDataInicialCiclo1Orig] = useState([]);
    const [dataInicialCiclo1, setDataInicialCiclo1] = useState([]);
    const [dataFinalCiclo1Orig, setDataFinalCiclo1Orig] = useState([]);
    const [dataFinalCiclo1, setDataFinalCiclo1] = useState([]);

    const [dataInicialCiclo2Orig, setDataInicialCiclo2Orig] = useState([]);
    const [dataInicialCiclo2, setDataInicialCiclo2] = useState([]);
    const [dataFinalCiclo2Orig, setDataFinalCiclo2Orig] = useState([]);
    const [dataFinalCiclo2, setDataFinalCiclo2] = useState([]);

    const [variedades, setVariedades] = useState([]);
    const [variedad, setVariedad] = useState(false);
    const [globalToggle, setGlobalToggle] = useState(false)

    const [mezclaVariedadesCiclo1, setMezclaVariedadesCiclo1] = useState([]);
    const [variedadCiclo1, setVariedadCiclo1] = useState(false);
    const [globalToggleCiclo1, setGlobalToggleCiclo1] = useState(false)
    const [mezclaVariedadesCiclo2, setMezclaVariedadesCiclo2] = useState([]);
    const [variedadCiclo2, setVariedadCiclo2] = useState(false);
    const [globalToggleCiclo2, setGlobalToggleCiclo2] = useState(false)

    const [proveedorExterno, setProveedorExterno] = useState("Todos");
    const [proveedoresOpciones, setProveedoresOpciones] = useState([]);

    useEffect(() => {
        if (ciclo) {
            obtenerProveedores(ciclo).then(proveedores => {
                setProveedoresOpciones(proveedores)
            }).catch((err) => {

            });
            setInvernaderos(invernaderosVirtuales);
        }

    }, [ciclo])
    useEffect(() => {
        if (cicloObj && invernadero && productosYVariedades) {
            obtenerDatosIniciales()
            setInvernaderoMezclaVariedades()
        }

    }, [cicloObj, invernadero, productosYVariedades])
    useEffect(() => {
        if (ciclos?.length > 0) { obtenerCiclosConInvernaderos(ciclos) }
    }, [ciclos])

    const procesarReportesSemanales = (reportes, listaSemanas, defectos, ciclo) => {
        
        
        //setDefectosLista(defectos)
        let resultInicial = defectos.map(defecto => {
            let serieInicial = {};
            serieInicial["name"] = defecto.nombre;
            serieInicial["sumaIncidencia"] = 0;
            serieInicial["maximaIncidencia"] = 0;
            serieInicial["totalIncidencia"] = 0;
            serieInicial["data"] = listaSemanas.map((semana, index) => {
                let datosSemana = reportes[index];
                let promediosInicial = datosSemana["promediosInicial"];
                let datoDefecto = promediosInicial[defecto.id];
                if (datoDefecto) {
                    if (!isNaN(datoDefecto["cociente"])) {
                        serieInicial["sumaIncidencia"] += datoDefecto["cociente"];
                        if (datoDefecto["cociente"] > serieInicial["maximaIncidencia"]) {
                            serieInicial["maximaIncidencia"] = datoDefecto["cociente"]
                        }
                        if (datoDefecto["cociente"] > 0) {
                            serieInicial["totalIncidencia"]++;
                        }
                    }

                    return isNaN(datoDefecto["cociente"]) ? 0 : datoDefecto["cociente"];
                } else {
                    return 0
                }
            })
            serieInicial["porcentajeIncidencia"] = 100 * serieInicial["totalIncidencia"] / listaSemanas.length;
            return serieInicial;
        })
        let resultFinal = defectos.map(defecto => {
            let serieFinal = {};
            serieFinal["name"] = defecto.nombre;
            serieFinal["sumaIncidencia"] = 0;
            serieFinal["maximaIncidencia"] = 0;
            serieFinal["totalIncidencia"] = 0;
            serieFinal["data"] = listaSemanas.map((semana, index) => {
                let datosSemana = reportes[index];
                let promediosFinal = datosSemana["promediosFinal"];
                let datoDefecto = promediosFinal[defecto.id];
                if (datoDefecto) {
                    if (!isNaN(datoDefecto["cociente"])) {
                        serieFinal["sumaIncidencia"] += datoDefecto["cociente"];
                        if (datoDefecto["cociente"] > serieFinal["maximaIncidencia"]) {
                            serieFinal["maximaIncidencia"] = datoDefecto["cociente"]
                        }
                        if (datoDefecto["cociente"] > 0) {
                            serieFinal["totalIncidencia"]++;
                        }
                    }
                    return isNaN(datoDefecto["cociente"]) ? 0 : datoDefecto["cociente"];
                } else {
                    return 0
                }
            })
            serieFinal["porcentajeIncidencia"] = 100 * serieFinal["totalIncidencia"] / listaSemanas.length;
            return serieFinal;
        })

        
        
        

        let inicialFiltered = resultInicial.filter(el => (el["maximaIncidencia"] > 1 && el["porcentajeIncidencia"] > 1))
        let finFiltered = resultFinal.filter(el => (el["maximaIncidencia"] > 1 && el["porcentajeIncidencia"] > 1))
        let list1 = inicialFiltered.map(def => ({ nombre: def.name, id: def.name, }))
        let list2 = finFiltered.map(def => ({ nombre: def.name, id: def.name, }))

        if (ciclo === 0) {
            setDefectosListaInicial(list1)
            setDefectosListaFinal(list2)
            setDefectosFiltros(dataSrc === "dataInicial" ? list1 : list2)

            setDataInicialOrig(inicialFiltered);
            setDataInicial(inicialFiltered);
            setDataFinalOrig(finFiltered)
            setDataFinal(finFiltered);
        }
        if (ciclo === 1) {
            setDefectosListaInicialCiclo1(list1)
            setDefectosListaFinalCiclo1(list2)
            setDefectosFiltrosCiclo1(dataSrc === "dataInicial" ? list1 : list2)

            setDataInicialCiclo1Orig(inicialFiltered)
            setDataInicialCiclo1(inicialFiltered);
            setDataFinalCiclo1Orig(finFiltered)
            setDataFinalCiclo1(finFiltered);
        }
        if (ciclo === 2) {
            setDefectosListaInicialCiclo2(list1)
            setDefectosListaFinalCiclo2(list2)
            setDefectosFiltrosCiclo2(dataSrc === "dataInicial" ? list1 : list2)

            setDataInicialCiclo2Orig(inicialFiltered)
            setDataInicialCiclo2(inicialFiltered);
            setDataFinalCiclo2Orig(finFiltered)
            setDataFinalCiclo2(finFiltered);
        }
    }

    const obtenerDatosIniciales = async (params) => {
        try {
            setCargando(true);
            let productos = productosYVariedades;
            setProductos(productos);
            setInvernaderos(invernaderosVirtuales);
            let listaSemanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
            let labels = listaSemanas.map(semana => semana.nombreCompacto);
            let copiaOptions = { ...options };
            copiaOptions["xaxis"] = {};
            copiaOptions["xaxis"]["categories"] = labels;
            copiaOptions["title"]["text"] = "Porcentajes de defectos en Calidad";

            copiaOptions["colors"] = [stringToColor(invernadero.color || "gray")]
            setOptions(copiaOptions);
            let defectos = await obtenerDefectos();
            let producto = productos.find(el => el.id === invernadero.producto_ref)
            let promises = listaSemanas.map(semana => obtenerReporteDefectos(cicloObj.id, invernadero.id, semana.time, producto.tipo_calidad_inicial, variedad, proveedorExterno));
            let reportesSemanales = await Promise.all(promises);
            procesarReportesSemanales(reportesSemanales, listaSemanas, defectos, 0);
            
            
        } catch (error) {
            console.log("HUBO UN ERROR AL OBTENER REPORTES DE DEFECTOS");
            console.log(error);
        } finally {
            setCargando(false);
        }
    }
    const obtenerDatosInvernadero1 = async (params) => {
        //window.alert(variedadCiclo1)
        try {
            setCargando(true);
            
            let cicloObj = await obtenerCiclo(ciclo1.id);
            let invernaderos = ciclo1.invernaderos
            let productos = productosYVariedades;
            setProductos(productos);
            setInvernaderos(invernaderos);

            let listaSemanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
            let labels = listaSemanas.map(semana => semana.nombreCompacto);
            let copiaOptions = { ...options };
            copiaOptions["xaxis"] = {};
            copiaOptions["xaxis"]["categories"] = labels;
            copiaOptions["title"]["text"] = "Porcentajes de defectos en Calidad " + ciclo1.nombre;
            copiaOptions["colors"] = [stringToColor(ciclo1Invernadero.color || "gray")]
            setOptionsCiclo1(copiaOptions);
            let defectos = await obtenerDefectos();
            let producto = productos.find(el => el.id === ciclo1Invernadero.producto_ref)
            let promises = listaSemanas.map(semana => obtenerReporteDefectos(cicloObj.id, ciclo1Invernadero.id, semana.time, producto.tipo_calidad_inicial, variedadCiclo1, proveedorExterno));
            let reportesSemanales = await Promise.all(promises);
            procesarReportesSemanales(reportesSemanales, listaSemanas, defectos, 1);
            
            
        } catch (error) {
            console.log("HUBO UN ERROR AL OBTENER REPORTES DE DEFECTOS");
            console.log(error);
        } finally {
            setCargando(false);
        }
    }
    const obtenerDatosInvernadero2 = async (params) => {
        try {
            setCargando(true);
            
            let cicloObj = await obtenerCiclo(ciclo2.id);
            let invernaderos = ciclo2.invernaderos
            let productos = productosYVariedades;
            setProductos(productos);
            setInvernaderos(invernaderos);

            let listaSemanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
            let labels = listaSemanas.map(semana => semana.nombreCompacto);
            let copiaOptions = { ...options };
            copiaOptions["xaxis"] = {};
            copiaOptions["xaxis"]["categories"] = labels;
            copiaOptions["title"]["text"] = "Porcentajes de defectos en Calidad " + ciclo2.nombre;
            copiaOptions["colors"] = [stringToColor(ciclo2Invernadero.color || "gray")]
            setOptionsCiclo2(copiaOptions);
            let defectos = await obtenerDefectos();
            let producto = productos.find(el => el.id === ciclo2Invernadero.producto_ref)
            let promises = listaSemanas.map(semana => obtenerReporteDefectos(cicloObj.id, ciclo2Invernadero.id, semana.time, producto.tipo_calidad_inicial, variedadCiclo2, proveedorExterno));
            let reportesSemanales = await Promise.all(promises);
            procesarReportesSemanales(reportesSemanales, listaSemanas, defectos, 2);
            
            
        } catch (error) {
            console.log("HUBO UN ERROR AL OBTENER REPORTES DE DEFECTOS");
            console.log(error);
        } finally {
            setCargando(false);
        }
    }
    const obtenerCiclosConInvernaderos = async (ciclosList) => {
        let allCiclosObj = []

        for (let x = 0; x < ciclosList.length; x++) {
            let cicloObj = { ...ciclosList[x] }
            let cicloInvernaderos = await obtenerInvernaderosVirtuales(cicloObj.id)
            cicloObj.invernaderos = cicloInvernaderos

            allCiclosObj.push(cicloObj)
        }

        setAllCiclos(allCiclosObj)
    }
    const atras = (params) => {
        props.history.goBack();
    }

    useEffect(() => {
        if (ciclo1Invernadero !== "" && ciclo1) {
            obtenerDatosInvernadero1();
            setInvernaderoMezclaVariedadesCiclo1()
        }
    }, [ciclo1Invernadero, variedadCiclo1,  ciclo1])
    useEffect(() => {
        if (ciclo2Invernadero !== "" && ciclo2) {
            obtenerDatosInvernadero2();
            setInvernaderoMezclaVariedadesCiclo2()
        }
    }, [ciclo2Invernadero, variedadCiclo2, ciclo2])
    useEffect(() => {
        if (!multiCiclo) {
            setCiclo1("")
            setCiclo2("")
            setCiclo1Invernadero("")
            setCiclo2Invernadero("")
            setDataInicialCiclo1([]);
            setDataFinalCiclo1([]);
            setDataInicialCiclo2([]);
            setDataFinalCiclo2([]);
            setOptionsCiclo1({ ...optionsOrig });
            setOptionsCiclo2({ ...optionsOrig });
            setVariedadCiclo1(false)
            setVariedadCiclo2(false)
            setMezclaVariedadesCiclo1([])
            setMezclaVariedadesCiclo2([])
        }

    }, [multiCiclo])

    useEffect(() => {
        if (!globalToggle) {
            setVariedad(false)
        }
        if (!globalToggleCiclo1) {
            setVariedadCiclo1(false)
        }
        if (!globalToggleCiclo2) {
            setVariedadCiclo2(false)
        }

    }, [globalToggle, globalToggleCiclo1, globalToggleCiclo2])


    const setInvernaderoMezclaVariedades = async () => {
        const producto = productosYVariedades.find(prod => prod.id === invernadero.producto_ref)
        //const variedad = producto.variedades.find(vari => vari.id === invernadero.variedad_ref)

        if (producto?.mezcla) {
            setVariedades(producto.variedades)
        }
        else {
            setVariedades([])
            setVariedad(false)
            setGlobalToggle(false)
        }
    }
    const setInvernaderoMezclaVariedadesCiclo1 = async () => {

        const producto = productosYVariedades.find(prod => prod.id === ciclo1Invernadero.producto_ref)
        //const variedad = producto.variedades.find(vari => vari.id === ciclo1Invernadero.variedad_ref)

        if (producto?.mezcla) {
            setMezclaVariedadesCiclo1(producto.variedades)
        }
        else {
            setMezclaVariedadesCiclo1([])
            setVariedadCiclo1(false)
            setGlobalToggleCiclo1(false)
        }
    }
    const setInvernaderoMezclaVariedadesCiclo2 = async () => {

        const producto = productosYVariedades.find(prod => prod.id === ciclo2Invernadero.producto_ref)
        //const variedad = producto.variedades.find(vari => vari.id === ciclo2Invernadero.variedad_ref)

        if (producto?.mezcla) {
            setMezclaVariedadesCiclo2(producto.variedades)
        }
        else {
            setMezclaVariedadesCiclo2([])
            setVariedadCiclo2(false)
            setGlobalToggleCiclo2(false)
        }
    }

    useEffect(() => {
        if (defectosFiltros.length > 0) {
            if (dataSrc === "dataInicial") {
                let filt = []
                let temp = defectosFiltros.map(de => de.nombre)
                dataInicialOrig.forEach(def => {

                    if (temp.includes(def.name)) {
                        filt.push(def)
                    }
                })
                //window.alert(filt.length)

                setDataInicial(filt)
            }
            else {
                let filt = []
                let temp = defectosFiltros.map(de => de.nombre)
                dataFinalOrig.forEach(def => {
                    if (temp.includes(def.name))
                        filt.push(def)
                })
                setDataFinal(filt)
            }
        }
        else {
            if (dataSrc === "dataInicial") {
                setDataInicial([])
            }
            else {
                setDataFinal([])
            }
        }
    }, [defectosFiltros])

    useEffect(() => {
        if (multiCiclo && ciclo1 !== "") {
            if (defectosFiltrosCiclo1.length > 0) {
                if (dataSrc === "dataInicial") {
                    let filt = []
                    let temp = defectosFiltrosCiclo1.map(de => de.nombre)
                    dataInicialCiclo1Orig.forEach(def => {

                        if (temp.includes(def.name)) {
                            filt.push(def)
                        }
                    })

                    setDataInicialCiclo1(filt)
                }
                else {
                    let filt = []
                    let temp = defectosFiltrosCiclo1.map(de => de.nombre)
                    dataFinalCiclo1Orig.forEach(def => {
                        if (temp.includes(def.name))
                            filt.push(def)
                    })
                    setDataFinalCiclo1(filt)
                }
            }
            else {
                if (dataSrc === "dataInicial") {
                    setDataInicialCiclo1([])
                }
                else {
                    setDataFinalCiclo1([])
                }
            }
        }
    }, [defectosFiltrosCiclo1])
    useEffect(() => {
        if (multiCiclo && ciclo2 !== "") {
            if (defectosFiltrosCiclo2.length > 0) {
                if (dataSrc === "dataInicial") {
                    let filt = []
                    let temp = defectosFiltrosCiclo2.map(de => de.nombre)
                    dataInicialCiclo2Orig.forEach(def => {

                        if (temp.includes(def.name)) {
                            filt.push(def)
                        }
                    })

                    setDataInicialCiclo2(filt)
                }
                else {
                    let filt = []
                    let temp = defectosFiltrosCiclo2.map(de => de.nombre)
                    dataFinalCiclo2Orig.forEach(def => {
                        if (temp.includes(def.name))
                            filt.push(def)
                    })
                    setDataFinalCiclo2(filt)
                }
            }
            else {
                if (dataSrc === "dataInicial") {
                    setDataInicialCiclo2([])
                }
                else {
                    setDataFinalCiclo2([])
                }
            }
        }
    }, [defectosFiltrosCiclo2])

    useEffect(() => {
        if (dataSrc === "dataInicial") {
            setDefectosFiltros(defectosListaInicial)
            setDefectosFiltrosCiclo1(defectosListaInicialCiclo1)
            setDefectosFiltrosCiclo2(defectosListaInicialCiclo2)
        }
        else {
            setDefectosFiltros(defectosListaFinal)
            setDefectosFiltrosCiclo1(defectosListaFinalCiclo1)
            setDefectosFiltrosCiclo2(defectosListaFinalCiclo2)
        }
    }, [dataSrc])
    useEffect(() => {
        if(productosYVariedades){obtenerDatosIniciales()}
    }, [variedad,  productosYVariedades])
    return <div className="p-grid chart-page">
        {invernadero ? <div className="p-col  p-col-auto title-container">
            <span className="back-btn" onClick={atras}></span>
            <span className={` icon-general chart-icon  bg-${invernadero.color}  bor-${invernadero.color}`}></span>
            <span className="titles-group">
                <h1 className="header-title">Gráfica de calidad</h1>
                <p className={`header-subtitle `}>Invernadero {invernadero.nombre}</p>
            </span>
        </div>
            :
            <div className="p-col  p-col-auto title-container">
                <span className="back-btn" onClick={atras}></span>
                <span className={` icon-general chart-icon  `}></span>
                <span className="titles-group">
                    <h1 className="header-title">Gráfica de calidad</h1>
                </span>
            </div>

        }
        <div className="p-col-12">

            <SeccionFiltros>
                <ContenedorFiltro label="Calidad a gráficar" filtrolabel="no" >
                    <SelectButton value={dataSrc} options={selectData} onChange={(ev) => setDataSrc(ev.value)} />
                </ContenedorFiltro>

                <ContenedorFiltro filtrolabel="no" label="Tipo de gráfica">
                    <ToggleButton onLabel={"Multiciclo"} offLabel={"Por ciclo actual"} checked={multiCiclo} onChange={(ev) => setMultiCiclo(ev.value)} ></ToggleButton>
                </ContenedorFiltro>

                {!multiCiclo && <ContenedorFiltro label="Invernadero">
                    <Dropdown value={invernadero} options={invernaderos} onChange={(e) => setInvernadero(e.value)} dataKey="id" optionLabel="nombre" />
                </ContenedorFiltro>}
                {!multiCiclo && <ContenedorFiltro label="defectos">
                    <MultiSelect value={defectosFiltros} options={dataSrc === "dataInicial" ? defectosListaInicial : defectosListaFinal} onChange={(e) => setDefectosFiltros(e.value)} dataKey="id" optionLabel="nombre" 
                        selectedItemsLabel={`${defectosFiltros.length} defectos seleccionados`}
                    />
                </ContenedorFiltro>}

                {!multiCiclo && invernadero && invernadero.es_externo
                    && <ContenedorFiltro label="Proveedor externo">
                        <Dropdown options={[{ nombre: "Todos", id: "Todos" }, ...proveedoresOpciones]} value={proveedorExterno} onChange={(e) => setProveedorExterno(e.target.value)}
                            optionLabel="nombre" optionValue="id" />
                    </ContenedorFiltro>}

                {multiCiclo && <ContenedorFiltro filtrolabel="no" label="Primer ciclo">
                    <Dropdown value={ciclo1} options={allCiclos} onChange={(e) => setCiclo1(e.target.value)} dataKey="id" optionLabel="nombre" />
                </ContenedorFiltro>}
                {multiCiclo && ciclo1 !== "" && <ContenedorFiltro label={"invernadero " + ciclo1.nombre}>
                    <Dropdown value={ciclo1Invernadero} options={ciclo1.invernaderos} onChange={(e) => setCiclo1Invernadero(e.target.value)} dataKey="id" optionLabel="nombre" />
                </ContenedorFiltro>}
                {multiCiclo && ciclo1 !== "" && <ContenedorFiltro label="defectos">
                    <MultiSelect value={defectosFiltrosCiclo1} options={dataSrc === "dataInicial" ? defectosListaInicialCiclo1 : defectosListaFinalCiclo1} onChange={(e) => setDefectosFiltrosCiclo1(e.value)} dataKey="id" optionLabel="nombre" 
                        selectedItemsLabel={`${defectosFiltrosCiclo1.length} defectos seleccionados`}
                    />
                </ContenedorFiltro>}
                {mezclaVariedadesCiclo1.length > 0 && multiCiclo && globalToggleCiclo1 && ciclo1 !== "" && <ContenedorFiltro label={"variedad " + ciclo1.nombre}>
                    <Dropdown options={mezclaVariedadesCiclo1} value={variedadCiclo1} onChange={(e) => setVariedadCiclo1(e.target.value)}
                        optionLabel="nombre" optionValue="id" />
                </ContenedorFiltro>}
                {mezclaVariedadesCiclo1.length > 0 && multiCiclo && ciclo1 !== "" && <ContenedorFiltro label="tipo">
                    <ToggleButton className="reactbtn-icon" onLabel="Variedades" offLabel="Global" checked={globalToggleCiclo1} onChange={(ev) => setGlobalToggleCiclo1(ev.value)} ></ToggleButton>
                </ContenedorFiltro>}

                {multiCiclo && <ContenedorFiltro filtrolabel="no" label="Segundo ciclo">
                    <Dropdown value={ciclo2} options={allCiclos} onChange={(e) => setCiclo2(e.target.value)} dataKey="id" optionLabel="nombre" />
                </ContenedorFiltro>}
                {multiCiclo && ciclo2 !== "" && <ContenedorFiltro label={"invernadero " + ciclo2.nombre}>
                    <Dropdown value={ciclo2Invernadero} options={ciclo2.invernaderos} onChange={(e) => setCiclo2Invernadero(e.target.value)} dataKey="id" optionLabel="nombre" />
                </ContenedorFiltro>}
                {multiCiclo && ciclo2 !== "" && <ContenedorFiltro label="defectos">
                    <MultiSelect value={defectosFiltrosCiclo2} options={dataSrc === "dataInicial" ? defectosListaInicialCiclo2 : defectosListaFinalCiclo2} onChange={(e) => setDefectosFiltrosCiclo2(e.value)} dataKey="id" optionLabel="nombre" 
                        selectedItemsLabel={`${defectosFiltrosCiclo2.length} defectos seleccionados`}
                    />
                </ContenedorFiltro>}
                {mezclaVariedadesCiclo2.length > 0 && multiCiclo && globalToggleCiclo2 && ciclo2 !== "" && <ContenedorFiltro label={"variedad " + ciclo2.nombre}>
                    <Dropdown options={mezclaVariedadesCiclo2} value={variedadCiclo2} onChange={(e) => setVariedadCiclo2(e.target.value)}
                        optionLabel="nombre" optionValue="id" />
                </ContenedorFiltro>}
                {mezclaVariedadesCiclo2.length > 0 && multiCiclo && ciclo2 !== "" && <ContenedorFiltro label="tipo">
                    <ToggleButton className="reactbtn-icon" onLabel="Variedades" offLabel="Global" checked={globalToggleCiclo2} onChange={(ev) => setGlobalToggleCiclo2(ev.value)} ></ToggleButton>
                </ContenedorFiltro>}

                {variedades.length > 0 && !multiCiclo && globalToggle && <ContenedorFiltro label=" variedad">
                    <Dropdown options={variedades} value={variedad} onChange={(e) => setVariedad(e.target.value)}
                        optionLabel="nombre" optionValue="id" />
                </ContenedorFiltro>}
                {variedades.length > 0 && !multiCiclo && <ContenedorFiltro label="tipo">
                    <ToggleButton className="reactbtn-icon" onLabel="Variedades" offLabel="Global" checked={globalToggle} onChange={(ev) => setGlobalToggle(ev.value)} ></ToggleButton>
                </ContenedorFiltro>}
            </SeccionFiltros>
        </div>
        {!multiCiclo && <div className="p-col-12 chart-container-filters">
            <Chart options={options} series={dataSrc === "dataInicial" ? dataInicial : dataFinal} type="heatmap"
                height={dataSrc === "dataInicial" ? (dataInicial.length * 25) + 112 : (dataFinal.length * 25) + 112} />
        </div>}
        <div className={`p-col-12 chart-container-filters    `}>

            {multiCiclo && ciclo1 !== "" &&
                <Chart options={optionsCiclo1} series={dataSrc === "dataInicial" ? dataInicialCiclo1 : dataFinalCiclo1} type="heatmap"
                    height={dataSrc === "dataInicial" ? (dataInicialCiclo1.length * 25) + 112 : (dataFinalCiclo1.length * 25) + 112} />
            }
            {multiCiclo && ciclo2 !== "" &&
                <Chart options={optionsCiclo2} series={dataSrc === "dataInicial" ? dataInicialCiclo2 : dataFinalCiclo2} type="heatmap"
                    height={dataSrc === "dataInicial" ? (dataInicialCiclo2.length * 25) + 112 : (dataFinalCiclo2.length * 25) + 112} />
            }
        </div>
    </div>
}

export default HeatmapCalidad;
