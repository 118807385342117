import * as firebase from 'firebase/app'
import { getMomentFormatted } from '../util/functions';
import { uploadStatistics } from './Estadisticas'

function registrarInvocaciones(count) {
    uploadStatistics(count)
}

export const obtenerArchivosCargados = async (cicloId, semanaTimestamp, empaqueRef) => {

    try {
        const db = firebase.firestore();
        let snap = await db.collection("ciclos").doc(cicloId).collection("cargas_nomina_empaque").where("dia", ">=", semanaTimestamp)
            .where("dia", "<", semanaTimestamp + 7 * 24 * 60 * 60).where("empaque_ref", "==", empaqueRef).get();
        registrarInvocaciones(snap.size)

        let data = snap.docs.map(el => {
            //window.alert(JSON.stringify(el.data().datos))
            /*    let datos = {}
    
                for (const presentacion in el.data().datos) {
                    let allEmpleadoCajas = { ...el.data().datos[presentacion] }
    
                    datos[presentacion] = {}
    
                    for (const codigoEmpleado in allEmpleadoCajas) {
                        
                        let numCajas = allEmpleadoCajas[codigoEmpleado]
                        datos[presentacion][codigoEmpleado.toUpperCase()] = numCajas || 0
                        datos[presentacion][codigoEmpleado] = numCajas || 0
                    }
                    console.log(JSON.stringify(datos))
    
                }*/
            return { id: el.id, fechaFormatted: getMomentFormatted(el.data().dia * 1000, "DD/MM/YYYY"), ...el.data() }
        });
        return data
    } catch (error) {
        console.log("ERROR OBTENER ARCHIVOS CARGADOS");
        throw error;
    }
}

export const eliminarArchivoCargado = async (cicloId, archivoId) => {
    try {
        const db = firebase.firestore();
        let result = await db.collection("ciclos").doc(cicloId).collection("cargas_nomina_empaque").doc(archivoId).delete();
        return { success: true };
    } catch (error) {
        throw error;
    }
}
export const obtenerResumenNominaEmpaqueMultiproductoCapturado = async (cicloId, semana, empaque) => {
    try {
        const db = firebase.firestore();
        let result = await db.collection("ciclos").doc(cicloId).collection("snapshots_nomina_empaque").where("semana", "==", semana).
            where("empaque_ref", "==", empaque.id).get();
        registrarInvocaciones(result.size)
        if (result.empty) {
            return { empty: true }
        } else {
            let doc = result.docs[0];
            return { id: doc.id, ...doc.data() }
        }
    } catch (error) {
        throw error;
    }
}
