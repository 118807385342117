import React, { useState, useEffect } from 'react';
import { useGlobal } from 'reactn';
import ContenedorPrincipal from '../../common/ContenedorPrincipal'
import ContenedorHeader from '../../common/ContenedorHeader';
import SeccionFiltros from '../../common/SeccionFiltros';
import { MultiSelect } from 'primereact/multiselect';
import Chart from "react-apexcharts";
import { SelectButton } from 'primereact/selectbutton';
import { obtenerCiclos } from '../../../service/Ciclos';
import { obtenerInvernaderosVirtuales } from '../../../service/Invernaderos';
import { obtenerInicioSemanaDia, obtenerListaDeSemanas } from '../../../service/fechas';
import ContenedorFiltro from '../../common/ContenedorFiltro';
import { obtenerPresentaciones } from '../../../service/Presentaciones';
import { obtenerClientes, obtenerClientesCiclo } from '../../../service/Clientes';
import { obtenerReportePreciosInvernadero } from '../../../service/AnalisisCostos';
import { cuatroDecimales, DeepClone, dosDecimales, getObjetoPorID, paletaColores, stringToColor } from '../../../util/functions';
import { ToggleButton } from 'primereact/togglebutton';
import { obtenerBudgetsGruposPresentacionesCiclo, obtenerGruposPresentaciones } from '../../../service/QueriesGruposPresentaciones';
import { Dropdown } from 'primereact/dropdown';
import { obtenerManifiestosCiclo } from 'service/QueriesManifiestos';
import { generateSeries, generateSeriesGrupoPresentaciones, gettingItemsFromManifiestos, gettingItemsIndexedByPres, gettingManifiestosIndexedById } from './utils/manifiestos.utils';
import { obtenerProveedores } from 'service/Queries/Proveedores';
import { generateTooltip } from './utils/tooltips.utils';
import { obtainColorsFromSeries, obtainDashArrayFromSeries } from './utils/options.utils';
const tipos = [{ name: "Budget", code: "budget" }, { name: "Real", code: "real" }]

export default function BudgetEmpaque(props) {

  const tooltipFunction = ({ series, seriesIndex, dataPointIndex, w}, presentaciones) => {
    let seriesCompletas = w.config.series;
    let labels = [];
    if (esMulticiclo) {
      invernaderosMulticiclo.forEach(ciclo => {
        ciclo.filtro.forEach(invernadero => {
          labels.push(`<div class="p-tooltip-text">`)
          labels.push(`<div class="tooltip-title">${invernadero?.nombre} ${ciclo["nombreCiclo"]}</div>`)
          labels.push(`<div class="tooltip-datas">`)
          if (tipo.includes("real")) {
            let serie = seriesCompletas.find(el => el.name === `Real ${invernadero?.nombre} ${ciclo["nombreCiclo"]}`)
            labels.push(`<div class="tooltip-data">${serie?.data?.[dataPointIndex]} <p class="tooltip-label">Real</p></div>`)
          }
          if (tipo.includes("budget")) {
            let serie = seriesCompletas.find(el => el.name === `Budget ${invernadero?.nombre} ${ciclo["nombreCiclo"]}`)
            labels.push(`<div class="tooltip-data">${serie?.data?.[dataPointIndex]} <p class="tooltip-label">Budget</p></div>`)
          }
          if (tipo.includes("budget") && tipo.includes("real")) {
            let serieReal = seriesCompletas.find(el => el.name === `Real ${invernadero?.nombre} ${ciclo["nombreCiclo"]}`)
            let serieBudget = seriesCompletas.find(el => el.name === `Budget ${invernadero?.nombre} ${ciclo["nombreCiclo"]}`)
            labels.push(`<div class="tooltip-data">${dosDecimales(serieReal?.data?.[dataPointIndex] - serieBudget?.data?.[dataPointIndex])} <p class="tooltip-label">Diferencia</p></div>`)
          }
          labels.push(`</div></div>`)
        })

      })

    } else {
      

       labels = seriesCompletas.map(serie => {
          let template = ""
          const { name, data } = serie
          let dataTooltip = []
          if(name.includes("Budget")){
            const renderTooltipBudget = data[0].renderTooltipBudget
            const budget = data[dataPointIndex].y
            if(renderTooltipBudget){
              dataTooltip.push({label: "Budget", value: budget})
              template = generateTooltip(name, "$", dataTooltip)
            }
            return template
          }
          
          const renderFactBudget = data[0].renderFactBudget
          const real = data[dataPointIndex].y
          const budget = data[dataPointIndex].budget
          let diferencia = real - budget
          dataTooltip.push({label: "Real", value: real})
          if(renderFactBudget){
            dataTooltip.push({label: "Budget", value: budget})
            dataTooltip.push({label: "Diferencia", value: diferencia})
          }
          template = generateTooltip(name, "$", dataTooltip)

          return template
       })

    }
    labels = labels.join("")
    return `<div class="chart-label">${labels}</div>`

  }
  const optionsOrig = {
    height:"100%",
    legend: {
      showForSingleSeries: true,
      formatter: (seriesName, opts)  => {
        const isBudget = seriesName.includes("Budget")
        const typeLine = isBudget ? "dashed-line" : "solid-line"
        return `<div class="chart-label"><span class="dash-type"><span class="${typeLine}"></span></span> ${seriesName}</div>`;
      },
      position: 'right',
      offsetX: 0,
      offsetY: 400,
    },
    chart: {

      type: 'line',
      zoom: {
        enabled: true
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0,
          colors: paletaColores,
        }
      }
    }],

    dataLabels: {
      enabled: false
    },
    stroke: { curve: 'straight'},
    title: {
      text: 'Precios de presentaciones en el ciclo',
      align: 'left'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    xaxis: {
      type: "category"
    },
    yaxis: {
      decimalsInFloat: 2
    },
    tooltip: {
      enabled: true,
      followCursor: true,
      shared: true,
      intersect: false,
      y: {
        formatter: function (val, opts) {
          if (parseInt(val) > 0) return val
        }
      },
      custom: tooltipFunction
    }
  }
  
  const [nombreModulo, setNombreModulo] = useGlobal("nombreModulo");
  const [filtroPresentaciones, setFiltroPresentaciones] = useState([])
  const [presentacionesOpciones, setPresentacionesOpciones] = useState([])
  
  const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
  const [productosYVariedades] = useGlobal("productosYVariedades");
  const [clientes] = useGlobal("clientes")
  const [productosBases] = useGlobal("productosBases");
  const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
  const [cicloId] = useGlobal("ciclo");
  const [ciclos] = useGlobal("ciclos");
  const [, setCargando] = useGlobal("cargando");
  const [invernaderos, setInvernaderos] = useState([]);
  const [invernaderosOrig, setInvernaderosOrig] = useState([]);
  
  const [productos, setProductos] = useState([]);
  
  const [options, setOptions] = useState({ ...optionsOrig });
  const [series, setSeries] = useState([]);
  
  const [tipo, setTipo] = useState([tipos[1].code])
  
  const [presentacionesOrig, setPresentacionesOrig] = useState([])
  const [presentacionesConDatos, setPresentacionesConDatos] = useState([])
  
  const [gruposPresentacionesOpciones, setGruposPresentacionesOpciones] = useState([])
  const [gruposPresentacionesOrig, setGruposPresentacionesOrig] = useState([])
  const [gruposPresentacionesConDatos, setGruposPresentacionesConDatos] = useState([])
  
  
  const [invernaderosMulticiclo, setInvernaderosMulticiclo] = useState([])

  const [filtroInvernaderos, setFiltroInvernaderos] = useState([]);

  const [filtroClientes, setFiltroClientes] = useState([])

  const [filtroGrupoPresentaciones, setFiltroGrupoPresentaciones] = useState([])

  const [clientesOrig, setClientesOrig] = useState([])
  const [clientesOpciones, setClientesOpciones] = useState([])

  const [filtroProducto, setFiltroProducto] = useState([]);

  const [presenOGrupPresenFiltro, setPresenOGrupPresenFiltro] = useState(false)
  const [isAcumulado, setIsAcumulado] = useState(false);

  const [esMulticiclo, setEsMulticiclo] = useState(false)

  const [filtroCiclos, setFiltroCiclos] = useState([])

  const [manifiestosOrig, setManifiestosOrig] = useState([])
  const [manifiestosIndexed, setManifiestosIndexed] = useState([])
  const [itemsAprobadosIndexedByPres, setItemsAprobadosIndexedByPres] = useState([])

  const [budgetsGrupoPresentaciones, setBudgetsGrupoPresentaciones] = useState([])

  useEffect(() => {
    setNombreModulo("Gráfica de precios");
    setHabilitadoSelectorCiclo(true);
  }, [])

  useEffect(() => {
    if (!invernaderosVirtuales) return

    obtenerDatosIniciales() 
  }, [invernaderosVirtuales])

  /* Filtros en cascada */
  /* Filtro invernaderos preselecciona los clientes */
  useEffect(() => {
    if(filtroInvernaderos.length === 0) {
      setClientesOpciones(clientesOrig)
      return
    }
    const newFiltroClientes = filtroClientes.filter((cliente) => filtroInvernaderos.some((inv) => inv.cliente_ref === cliente.id))
    const newClientesOpciones = clientesOrig.filter((cliente) => filtroInvernaderos.some((inv) => inv.cliente_ref === cliente.id))
    setFiltroClientes(newFiltroClientes)
    setClientesOpciones(newClientesOpciones)
  },[filtroInvernaderos])
  /* Filtro de cliente y producto debe filtrar presentaciones y grupo de presentaciones*/
  useEffect(() => {
    const newFiltroPresentacines = filtroPresentaciones.filter((pres) => {
      let cliente = true
      if(filtroClientes.length > 0){
        cliente = filtroClientes.some((cliente) => cliente.id === pres.cliente_ref)
      }
      return filtroProducto.id === pres.producto_ref && cliente
    })
    const newPresentacinesOpciones = presentacionesConDatos.filter((pres) => {
      let cliente = true
      if(filtroClientes.length > 0){
        cliente = filtroClientes.some((cliente) => cliente.id === pres.cliente_ref)
      }
      return filtroProducto.id === pres.producto_ref && cliente
    })
    const newFiltroGrupoPresentaciones = filtroGrupoPresentaciones.filter((grupP) => {
      let cliente = true
      if(filtroClientes.length > 0){
        cliente = filtroClientes.some((cliente) => cliente.id === grupP.cliente_ref)
      }
      return filtroProducto.id === grupP.producto_ref && cliente
    })
    const newGrupoPresentacinesOpciones = gruposPresentacionesConDatos.filter((grupP) => {
      let cliente = true
      if(filtroClientes.length > 0){
        cliente = filtroClientes.some((cliente) => cliente.id === grupP.cliente_ref)
      }
      return filtroProducto.id === grupP.producto_ref && cliente
    })
    setFiltroPresentaciones(newFiltroPresentacines)
    setPresentacionesOpciones(newPresentacinesOpciones)
    setFiltroGrupoPresentaciones(newFiltroGrupoPresentaciones)
    setGruposPresentacionesOpciones(newGrupoPresentacinesOpciones)
  },[filtroClientes, filtroProducto])
  useEffect(() => {
    setFiltroPresentaciones([])
    setFiltroGrupoPresentaciones([])
    setSeries([])
  },[presenOGrupPresenFiltro])
  useEffect(() => {
    if(manifiestosOrig.length === 0) return

    const items = gettingItemsFromManifiestos(manifiestosOrig)
    
    const itemsIndexedByPres  = gettingItemsIndexedByPres(items)

    const manifiestosIndexed  = gettingManifiestosIndexedById(manifiestosOrig)
    
    setManifiestosIndexed(manifiestosIndexed)
    setItemsAprobadosIndexedByPres(itemsIndexedByPres)
    
  }, [manifiestosOrig])
  useEffect(()=> {
    if(manifiestosOrig.length === 0) return
    if(presentacionesOrig.length === 0) return
    const newPresentacinesOpciones = presentacionesOrig.filter(pres => itemsAprobadosIndexedByPres[pres.id])
    const newGruposPresentacionesOpciones = gruposPresentacionesOrig.filter(grupP => newPresentacinesOpciones.some(pres =>  grupP.presentaciones_refs.includes(pres.id)))
    setPresentacionesConDatos(newPresentacinesOpciones)
    setPresentacionesOpciones(newPresentacinesOpciones)
    setGruposPresentacionesConDatos(newGruposPresentacionesOpciones)
    setGruposPresentacionesOpciones(newGruposPresentacionesOpciones)
  },[presentacionesOrig, itemsAprobadosIndexedByPres])
  
  useEffect(() => {
    if(manifiestosOrig.length === 0) return
    let result = {}

    if(presenOGrupPresenFiltro){
      result = generateSeriesGrupoPresentaciones(
        manifiestosOrig, 
        filtroGrupoPresentaciones, 
        itemsAprobadosIndexedByPres, 
        manifiestosIndexed,
        budgetsGrupoPresentaciones,
        gruposPresentacionesOrig,
        { tipo, isAcumulado }
      )
    }else{
      result = generateSeries(
        manifiestosOrig, 
        filtroPresentaciones, 
        itemsAprobadosIndexedByPres, 
        manifiestosIndexed,
        budgetsGrupoPresentaciones,
        gruposPresentacionesOrig,
        { tipo, isAcumulado }
      )
    }
    const colors = obtainColorsFromSeries(result.series)

    const dashArray = obtainDashArrayFromSeries(result.series)

    setOptions({...optionsOrig, stroke: { curve: 'straight', dashArray}, colors: [...colors]})
    setSeries(result.series)

  }, [filtroPresentaciones, filtroGrupoPresentaciones, tipo, isAcumulado])
  useEffect(() => {
    setCargando(false)
  },[series])
  const obtenerDatosIniciales = async (params) => {
    try {
      setCargando(true);
      const [gruposPresentaciones, presentaciones, clientesCiclo, budgetsGrupoPresentaciones ] = await Promise.all([
            obtenerGruposPresentaciones(), 
            obtenerPresentaciones(), 
            obtenerClientesCiclo(cicloId), 
            obtenerBudgetsGruposPresentacionesCiclo(cicloId)
      ]);
      const manifiestosCiclo = await obtenerManifiestosCiclo(cicloId)
      const ids = clientesCiclo.map(el => el.cliente_ref);
      const clientesDelCiclo = clientes.filter(el => ids.includes(el.id));

      setManifiestosOrig(manifiestosCiclo)
      setBudgetsGrupoPresentaciones(budgetsGrupoPresentaciones)
      
      setGruposPresentacionesOrig(gruposPresentaciones);
      setGruposPresentacionesOpciones(gruposPresentaciones)

      setProductos(productosYVariedades);
      setInvernaderosOrig(invernaderosVirtuales);
      setInvernaderos(invernaderosVirtuales)

      setPresentacionesOrig(presentaciones);
      setPresentacionesOpciones(presentaciones);
      
      setClientesOrig(clientesDelCiclo);
      setClientesOpciones(clientesDelCiclo)
      setCargando(false);
    } catch (error) {
      console.log("ERROR obtenerDatosIniciales: ", error);
    }
  }

  const handleFiltroMulticiclo = (cicloId, value) => {
    let copia = invernaderosMulticiclo.map(el => ({ ...el }))
    let current = copia.find(el => el.cicloId === cicloId);
    current.filtro = value;
    setInvernaderosMulticiclo(copia);
  }
  const handleFiltroPres = (e)=> {
    if(e.value.length > 8) return
    setCargando(true)
    setFiltroPresentaciones(e.value)
  }
  const handleFiltroGrupoPres = (e)=> {
    if(e.value.length > 8) return
    setCargando(true)
    setFiltroGrupoPresentaciones(e.value)
  }
  const filtroInvernadero = () => {
    return (
      <>
        {esMulticiclo 
            ? <React.Fragment>
                {invernaderosMulticiclo.map((el, index) =>
                  <ContenedorFiltro key={index} label={`${el.nombreCiclo}`}>
                    <MultiSelect value={el.filtro} options={el.opcionesInvernaderos} dataKey="id" optionLabel="nombre"
                      onChange={(e) => handleFiltroMulticiclo(el.cicloId, e.value)}
                      selectedItemsLabel={`${el.opcionesInvernaderos.length} invernaderos seleccionados`} />
                  </ContenedorFiltro>)}
              </React.Fragment>
            : <ContenedorFiltro label="Invernaderos">
                <MultiSelect options={invernaderos} value={filtroInvernaderos} optionLabel="nombre" dataKey="id"
                  onChange={(e) => setFiltroInvernaderos(e.value)}
                  selectedItemsLabel={`${filtroInvernaderos.length} invernaderos seleccionados`} />
              </ContenedorFiltro>
        }
      </>
    )
      
  }
  
  const toggleButtonGrupoPresOPres = () => {
    return presenOGrupPresenFiltro ? <ContenedorFiltro label="Grupos de presentaciones">
                                        <MultiSelect 
                                          options={gruposPresentacionesOpciones} 
                                          value={filtroGrupoPresentaciones} 
                                          optionLabel="nombre" 
                                          dataKey="id"
                                          onChange={handleFiltroGrupoPres} 
                                          filter={true}
                                          maxSelectedLabels={1}
                                          selectedItemsLabel={`${filtroGrupoPresentaciones.length} presentaciones seleccionadas`} />
                                      </ContenedorFiltro>
                                   : <ContenedorFiltro label="Presentaciones">
                                        <MultiSelect 
                                          options={presentacionesOpciones} 
                                          value={filtroPresentaciones} 
                                          optionLabel="presentacion" 
                                          dataKey="id"
                                          onChange={handleFiltroPres} 
                                          filter={true}
                                          maxSelectedLabels={1}
                                          selectedItemsLabel={`${filtroPresentaciones.length} presentaciones seleccionadas`} />
                                      </ContenedorFiltro>
  }

  const atras = props.location?.data?.backArrow && props.history.goBack;
  return (
    <ContenedorPrincipal extra="chart-page chart-container-filters cosecha-chart">
      <ContenedorHeader titulo={"Gráfica de precios"} iconos={"costos-icon"} atras={atras} />

      <SeccionFiltros>
        <div className="p-grid p-col-12">

          {
            filtroInvernadero()
          }

          <ContenedorFiltro label="Clientes">
            <MultiSelect options={clientesOpciones} value={filtroClientes} optionLabel="nombre" dataKey="id"
              onChange={e => setFiltroClientes(e.value)}
              maxSelectedLabels={2}
              selectedItemsLabel={`${filtroClientes.length} clientes seleccionados`} />
          </ContenedorFiltro>
          <ContenedorFiltro label="Productos">
            <Dropdown options={productosBases} value={filtroProducto} optionLabel="nombre" dataKey="id"
              onChange={(e) => setFiltroProducto(e.value)}/>
          </ContenedorFiltro>

          {
            toggleButtonGrupoPresOPres()
          }
          
          <ContenedorFiltro label="tipo">
              <ToggleButton className="reactbtn-icon" onLabel="Grupos presentaciones" offLabel="Presentaciones" checked={presenOGrupPresenFiltro} onChange={(ev) => setPresenOGrupPresenFiltro(ev.value)} ></ToggleButton>
          </ContenedorFiltro>
          <ContenedorFiltro label="datos">
            <SelectButton optionLabel="name" optionValue="code" value={tipo} options={tipos} onChange={e => setTipo(e.value)} multiple={true} />
          </ContenedorFiltro>
          <ContenedorFiltro label="Acumulado">
            <ToggleButton checked={isAcumulado} onChange={e => setIsAcumulado(e.value)} onLabel="Si" offLabel="No" />
          </ContenedorFiltro>
        </div>

        <div className="p-grid p-col-12">
          <ContenedorFiltro filtrolabel="no" label="Tipo de gráfica">
            <ToggleButton checked={esMulticiclo} onChange={e => setEsMulticiclo(e.value)} onLabel={"Multiciclo"} offLabel={"Por ciclo actual"} />
          </ContenedorFiltro>
          {esMulticiclo &&
            <ContenedorFiltro label="Ciclos a comparar" filtrolabel="no">
              <MultiSelect options={ciclos} value={filtroCiclos}
                onChange={e => setFiltroCiclos(e.value)} dataKey="id" optionLabel="nombre"
                selectedItemsLabel={`${filtroCiclos.length} ciclos seleccionados`} />
            </ContenedorFiltro>}
        </div>
      </SeccionFiltros>

      <div className="p-col-12 chart-container-filters">
        <Chart series={series} options={options} height="100%" />
      </div>
    </ContenedorPrincipal>
  )
}//666