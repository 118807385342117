import React, { useEffect, useState, useGlobal } from 'reactn';
import { Button } from 'primereact/button';
import { obtenerDiasDeSemana, obtenerDiasDeSemanaConDetalles } from '../../../service/fechas';
import { DeepClone, getObjetoPorID, objetosSonIgual, stringToColor, sumarNumerosArrayPorCampo } from '../../../util/functions';
import { checarSiHuboDistribuciones, filtrarOrdenesPorDia, filtrarOrdenesPorPresentacion, filtrarOrdenItemsDeVentas, filtrarOrdenItemsOriginalesDeVentas, getEmpaqueDeVentaItem, getTotalesOrdenesDeVenta, guardarDistribucionData, optionsGraficaBarraDistribucion } from '../../../service/CentroDeDistribucion';

import CardDia from './CardDia.mole';
import SeccionFiltros from '../../common/SeccionFiltros';
import ContenedorHeader from '../../common/ContenedorHeader';
import SeccionDatosCenter from '../../common/SeccionDatosCenter';
import ContenedorCardFormulario from '../../common/ContenedorCardFormulario';
import BaseCard from '../../common/cards/BaseCard';
import DatoCard from '../../common/cards/DatoCard';
import ContenedorFiltro from '../../common/ContenedorFiltro';
import { SelectButton } from 'primereact/selectbutton';
import Chart from "react-apexcharts";
import { getFirestoreID } from '../../../service/Mantenimiento';

const PantallaRedistribucionInvernadero = (props) => {
  const [, setCargando] = useGlobal("cargando");
  const [ciclo] = useGlobal("ciclo");
  const [week] = useGlobal("week");
  const [invernaderosFisicos] = useGlobal("invernaderosFisicos");
  const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
  const [optionsSwitchFiltro, setOptionsSwitchFiltro] = useState([]);
  const [diasSwitchFiltro, setDiasSwitchFiltro] = useState([]);

  const [ordenesVentaDistribucionDataOrig, setOrdenesVentaDistribucionDataOrig] = useState(null);
  const [ordenesVentaDistribucionData, setOrdenesVentaDistribucionData] = useState(null);
  const [presentacion, setPresentacion] = useState(null);
  const [resumenTotales, setResumenTotales] = useState({ cajas: 0, pallets: 0, cajasDistribuidas: 0 });// Mejor esto?
  const [huboDistribuciones, setHuboDistribuciones] = useState(false);
  const [bloquearGuardar, setBloquearGuardar] = useState(false);

  useEffect(() => {//Si ABREN el centro de distribución, procesar datos de ordenes de ventas; si lo CIERRAN se borra esos datos
    if (props.visible && !presentacion) { setPresentacion(props.presentacion) }
    else if (props.visible && presentacion) {generarDistribucionDatosOrdenesVenta(); }
    else if (!props.visible) {
      setOrdenesVentaDistribucionDataOrig(null);
      setOrdenesVentaDistribucionData(null);
      setPresentacion(null);
      setResumenTotales({ cajas: 0, pallets: 0, cajasDistribuidas: 0 });
      setHuboDistribuciones(false)
    }
  }, [props.visible, presentacion])

  useEffect(() => {//CONSOLE LOG
    //console.log("CHANGE ITEMS: ",ordenesVentaDistribucionData)
  }, [ordenesVentaDistribucionData])

  const generarDistribucionDatosOrdenesVenta = () => {//Procesar y guardar datos de ordenes de venta de este semana y la misma presentación a distribuir
    const diasSemana = obtenerDiasDeSemanaConDetalles(week?.time), ordenesVentaDistribucionData = { ordenesDeSemana: [] };

    diasSemana.forEach(dia => {
      const ordenesVentaDeLaPresentacion = filtrarOrdenesPorPresentacion(props.ordenesSemana, presentacion.id)
      const ordenesVentaDelDia = filtrarOrdenesPorDia(ordenesVentaDeLaPresentacion, dia.unixTime);
      const ordenesDeVenta = procesarOrdenItemDatos(ordenesVentaDelDia);//Mejora con let y regresar datos? O hacer const y no dejar procesarOrdenItemDatos() regresar algo. //O regresar otro const. seria demasiado variables declarados?

      ordenesVentaDistribucionData[dia.unixTime] = ordenesDeVenta;//Mejora con let y regresar datos? O hacer const y no dejar procesarOrdenItemDatos() regresar algo. //O regresar otro const. seria demasiado variables declarados?
      ordenesVentaDistribucionData.ordenesDeSemana.push(...ordenesDeVenta)
    });

    const resumenTotales = getTotalesOrdenesDeVenta(ordenesVentaDistribucionData.ordenesDeSemana, presentacion);
    const huboDistribuciones = checarSiHuboDistribuciones(ordenesVentaDistribucionData.ordenesDeSemana, presentacion);
    setResumenTotales(resumenTotales);//Set ordenes de venta en un solo variable
    setHuboDistribuciones(huboDistribuciones);//Checar si en una de las ordenes de este semana ya han tenido un distribucion de cajas

    preseleccionarDiasFiltroSwitch(ordenesVentaDistribucionData)
    setOrdenesVentaDistribucionDataOrig(ordenesVentaDistribucionData);
    setOrdenesVentaDistribucionData(DeepClone(ordenesVentaDistribucionData));
    console.log("setOrdenesVentaDistribucionData: ", ordenesVentaDistribucionData)
  }

  const preseleccionarDiasFiltroSwitch = (ordenesVentaData) => {//Datos de select-buttons del filtro de dias
    const valoresDias = obtenerDiasDeSemana(week?.time);

    //preseleccionar dias que tienen cajas de ordenes de venta
    const valoresDiasPreseleccionados = valoresDias.filter(dia => {
      const ordenesVentaDelDia = ordenesVentaData[dia] || [];
      return ordenesVentaDelDia.length > 0;
    });

    const diasSemana = obtenerDiasDeSemanaConDetalles(week?.time);
    setOptionsSwitchFiltro(diasSemana);
    setDiasSwitchFiltro(valoresDiasPreseleccionados);
  }
  //Checar si un item de las ordenes de venta es de la misma presentación que el seleccionado actualmente para distribuir
  const procesarOrdenItemDatos = (ordenesDeVenta = []) => {//asignar Firestore ID's y sitios de empaque a los items de las ventas
    return ordenesDeVenta?.map(venta => {
      venta.items.forEach(item => {
        item.itemID = item.itemID || getFirestoreID();
        item.empaque_ref = item.empaque_ref || getEmpaqueDeVentaItem(invernaderosFisicos, invernaderosVirtuales, item.invernadero_ref);//Asignar a variable?
      })
      return venta;
    }) || [];
  }

  const editarDistribucionData = (ordenVenta, itemOrigen, itemsDestino = [], hayErrores) => {
    const copiaDistribucionData = { ...ordenesVentaDistribucionData }, diaDeVenta = parseInt(ordenVenta.fecha_envio);//importante para linea 13 de CardDia.js
    const ordenVentaFound = getObjetoPorID(copiaDistribucionData[diaDeVenta], ordenVenta.id);

    const itemOrigenIndex = ordenVentaFound.items?.findIndex(item => item.itemID === itemOrigen.itemID);

    if (itemOrigenIndex > -1) { ordenVentaFound.items[itemOrigenIndex] = itemOrigen; }//editar item origen
    const datosDelOrden = copiaDistribucionData[ordenVentaFound.id];
    //editar datos de items destinos y errores
    if (!!datosDelOrden) { copiaDistribucionData[ordenVentaFound.id] = { ...datosDelOrden, [itemOrigen.itemID]: itemsDestino, hayErrores } }
    else { copiaDistribucionData[ordenVentaFound.id] = { [itemOrigen.itemID]: itemsDestino, hayErrores }; }

    //console.log("EDIT: ", {itemOrigen, itemOrigenIndex, copiaDistribucionData})
    validarBloquearGuardar(copiaDistribucionData);
    setOrdenesVentaDistribucionData(copiaDistribucionData);//Actualizar datos de lo que ha distribuido
  }
  const validarBloquearGuardar = (distribucionData) => {//Checar si hay errores en algun card en la pantalla, para deshabilitar el botón de guardar.
    const llavesDeDistribucionDataObj = Object.keys(distribucionData);
    const bloquearGuardar = llavesDeDistribucionDataObj?.some(llave => !!distribucionData[llave]?.hayErrores)
    setBloquearGuardar(bloquearGuardar);
  }

  const handleChangeDias = (event) => {//Seleccionar dias en el filtro de select-buttons de los dias de la semana
    const diasSeleccionados = DeepClone(event.value);
    const hayOrdenesEnCadaDia = diasSeleccionados.every(dia => ordenesVentaDistribucionData[dia]?.length > 0);//Deshabilitar la habilidar de seleccionar un día si no hay ordenes de venta para ese dia 

    const hayMinimoUnDia = diasSeleccionados.length > 0;
    if (hayMinimoUnDia && hayOrdenesEnCadaDia) { setDiasSwitchFiltro(diasSeleccionados); }
  }

  const getOptionsGrafica = () => {
    const colores = invernaderosVirtuales.map(el => stringToColor(el.color))
    const categories = huboDistribuciones ? ["Distribución original", "Distribución actual"] : ["Distribución original"]
    const options = optionsGraficaBarraDistribucion(colores, categories);
    return options;
  }

  const getSeries = () => {
    const series = invernaderosVirtuales.map(inv => {
      const itemsDeVenta = filtrarOrdenItemsDeVentas(ordenesVentaDistribucionData?.ordenesDeSemana, presentacion);

      let itemsOrig = huboDistribuciones
        ? filtrarOrdenItemsOriginalesDeVentas(ordenesVentaDistribucionData?.ordenesDeSemana, presentacion)
        : itemsDeVenta;
      itemsOrig = filtrarItemsPorInvernadero(itemsOrig, inv.id)
      const itemsDis = filtrarItemsPorInvernadero(itemsDeVenta, inv.id)

      const sumaOrig = sumarNumerosArrayPorCampo(itemsOrig, "cajas_orden");
      const sumaDis = sumarNumerosArrayPorCampo(itemsDis, "cajas_orden");
      const data = huboDistribuciones ? [sumaOrig, sumaDis] : [sumaOrig];
      return { name: inv.nombre, data, color: stringToColor(inv.color) };
    })
    return series
  }
  const filtrarItemsPorInvernadero = (items, invernaderoID) => items.filter(item => item.invernadero_ref === invernaderoID);

  const guardarDistribucionFinal = async () => {
    setCargando(true);
    //console.log("distribucion data guardar: ", { ORIG: ordenesVentaDistribucionDataOrig, DIS: ordenesVentaDistribucionData });
    if (objetosSonIgual(ordenesVentaDistribucionData, ordenesVentaDistribucionDataOrig)) { console.log("NO CHANGE"); return props.hide(); }

    //Obtener ordenes de venta con datos editados
    const guardarOrdenesPromises = generarOrdenDatosAGuardar();
    const ordenesGuardados = await Promise.all(guardarOrdenesPromises);

    //Guardar y actualizar valor de ordenes de venta local para tener los distribuciones reflejados sin recargar en la seccion de cumplimiento
    actualizarDatosLocales(ordenesGuardados);
    setCargando(false);
    props.hide();
  }
  const generarOrdenDatosAGuardar = () => {
    const guardarOrdenesPromises = [];
    optionsSwitchFiltro.forEach(dia => {
      const ordenesDeVentaDelDia = ordenesVentaDistribucionData[dia.unixTime];

      //Solo guardar si hay cajas distribuidos (hay items de destino guardado)
      ordenesDeVentaDelDia.forEach((ordenVenta, index) => {

        const ordenDatos = ordenesVentaDistribucionData[ordenVenta.id], itemsDestinoAGuardar = [];
        for (const llave in ordenDatos) {
          const itemDestinos = ordenDatos[llave];
          if (itemDestinos?.length > 0) { itemsDestinoAGuardar.push(...itemDestinos); }
        }

        const ordenOriginal = ordenesVentaDistribucionDataOrig[dia.unixTime][index];
        if (itemsDestinoAGuardar.length > 0 || objetosSonIgual(ordenVenta, ordenOriginal)) { //Checar por si cambió un sitio de empaque en LORT
          //console.log("DATOS A GUARDAR: ",{itemsDestinoAGuardar, ordenVenta})
          //Generar promesas por cada orden de venta
          const guardarPromise = guardarDistribucionData(ciclo, ordenVenta, itemsDestinoAGuardar, presentacion, props.ordenesSemana, props.setOrdenesSemana)
          guardarOrdenesPromises.push(guardarPromise);
        }
      })
    })
    return guardarOrdenesPromises;
  }
  const actualizarDatosLocales = (ordenesGuardados) => {
    const ordenesSemanaActualizados = DeepClone(props.ordenesSemana);
    ordenesGuardados.forEach(ordenGuardado => {
      const indexOrdenOriginal = props.ordenesSemana.findIndex(orden => orden.id === ordenGuardado.id);
      ordenesSemanaActualizados[indexOrdenOriginal] = ordenGuardado;
    })
    props.setOrdenesSemana(ordenesSemanaActualizados);
  }

  return (props.visible && ordenesVentaDistribucionData ?
    <div className="p-grid ">
      <ContenedorHeader col="p-col p-col-auto" iconos="boxes-icon" atras={props.hide} titulo={"Redistribución de producto a invernaderos"} subtituloGrande={props.rowData?.nombrePresentacion || ""} col="p-col p-col-auto" />

      <BaseCard col="12" tituloCard={"Resumen de la semana " + week.nombre}>
        <SeccionDatosCenter>
          <DatoCard label="Totales" valor={`${resumenTotales.cajas} cajas`} />
          <DatoCard label="Totales" valor={`${resumenTotales.pallets} pallets`} />
          <DatoCard label="Redistribuidas" valor={`${resumenTotales.cajasDistribuidas} cajas` || "0 cajas"} />
        </SeccionDatosCenter>
      </BaseCard>

      <ContenedorCardFormulario titulo={"Distribución de cajas"}>
        <Chart options={getOptionsGrafica()} series={getSeries()} type="bar" height={huboDistribuciones ? 120 : 90} />
      </ContenedorCardFormulario>

      <SeccionFiltros >
        <ContenedorFiltro value="tipo" sinFiltrarTexto>
          <SelectButton options={optionsSwitchFiltro} value={diasSwitchFiltro} onChange={handleChangeDias} optionLabel="nombre" optionValue="unixTime" multiple={true} />
        </ContenedorFiltro>
      </SeccionFiltros>

      {ordenesVentaDistribucionData && optionsSwitchFiltro.map(diaDetalles => {//Preguntar sobre utilizando funcion para popular arregla de componentes
        const dia = diaDetalles.unixTime, visible = diasSwitchFiltro.some(diaFiltro => dia === diaFiltro);
        const ordenesVentaDelDia = ordenesVentaDistribucionData[dia];

        //console.log("ordenesVentaDelDia::::: ", ordenesVentaDelDia)
        return <CardDia key={`${dia}`} visible={visible} dia={dia} ordenesVentaDelDia={ordenesVentaDelDia} presentacion={presentacion}
          distribucionData={ordenesVentaDistribucionData} editarDistribucionData={editarDistribucionData} />;
      })}

      <div className="p-col-12">
        <div className="p-grid p-justify-end btn-row">
          <Button label="Guardar" disabled={bloquearGuardar} onClick={guardarDistribucionFinal} />
        </div>
      </div>
    </div>
    : null)
}
export default PantallaRedistribucionInvernadero;//310