import React, {useState, useGlobal, useEffect} from "reactn";
import {obtenerDetallesInvernaderos} from "../../service/Cultivo";
import {obtenerCiclo} from "../../service/Ciclos";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import { getProductoYVariedadString } from "../../util/functions";
import { Button } from "primereact/button";
const mapeoFieldsNombres = {
    anio_plantacion:"Año de plantación",
    anio_plastico:"Año de plástico",
    portainjerto:"Portainjerto",
    semana_plantacion:"Semana de plantación",
    tipo_plastico:"Tipo de plástico",
    densidad_planta:"Densidad de planta(# plantas/m²) ",
    marca_sustrato:"Marca de sustrato",
    tipo_sustrato: "Tipo de sustrato",
    tamano_sustrato:"Tamaño sustrato"

}
export default function Reporte(props){
    const [ciclo,setCiclo] = useGlobal("ciclo");
   
    const [nombreModulo,setNombreModulo] = useGlobal("nombreModulo");
    const [error,setError] = useState(false);   
    const [tabla, setTabla] = useState([]);
    const [invernaderos,setInvernaderos ] = useState([]);
    const [,setInvernadero] = useGlobal("invernadero");
    const [invernaderosVirtuales] = useGlobal("invernaderosInternos");
    const [rows,setRows] = useState([]);

    const atras = (params) => {
        props.history.goBack();
    }
    
    const irInvernadero = (id) => {
            setInvernadero(id);
        props.history.push("/cultivo/reporte_historico")
    }
    useEffect(()=>{        
        if(invernaderosVirtuales){  
            setInvernaderos(invernaderosVirtuales);
        }        
    },[invernaderosVirtuales])
    useEffect(()=>{
        setNombreModulo("Cultivo");
      },[])
      
    useEffect(()=>{
        if(ciclo ){

            obtenerDetallesInvernaderos(ciclo)
            .then((resp)=>{
                procesarDetalles(resp);
                // 
                // 
                
            }).catch(err=>{
                console.log("ERROR OPC");
                console.log(err);
            })

        }
    },[ciclo])
    

    function unidadTemplate(rowData, column) {
        // 
        // 
        // 
        let fieldValue = rowData[column["field"]];
        // 
        if(column["field"] === "nombre"){
            fieldValue =    mapeoFieldsNombres[fieldValue];

        }else{
            
        }
        if(fieldValue){

        }else{
            fieldValue  = "";
        }
        
        return <div>{fieldValue}<span className="label-unidad">{rowData["unidad"]}</span> </div>;
    }
    const procesarDetalles = (invs) => {
        let rows = [];
        let invernaderos = [];
        invs.forEach(inv=> {            
            let invTemp = {};
            invTemp[inv["id"]] = invTemp[inv["nombre"]];
            
            invernaderos.push(invTemp);
        });
        Object.keys(invs[0]).forEach(key => {
            if(!(key === "id" || key === "nombre")){
                let obj = {};            
                obj["nombre"] = key;            
                invs.forEach(inv => {
                    obj[inv["id"]] = inv[key];    
                });            
                rows.push(obj);                
            }

            
        });
        
        // 
        // 
        setRows(rows);
    }
    
    function obtenerColumnas(){
        // 
        let cols = [<Column field = "nombre" body={unidadTemplate} className="col__label"  header = "Nombre" ></Column>]
        invernaderos.map(el=>{
            // 
            // 
            cols.push( <Column body={unidadTemplate} field={el.id} header = {el.nombre}/>)
        })
        return cols;
    }
    const obtenerHeader = () => {      
        let columns = [<Column header = "Nombre" rowSpan = {1} className = "col__label"></Column>]  
        invernaderos.forEach(invernadero => {
            columns.push(<Column  rowSpan = {1} className = "col__label"
                header={
                    <Button className="invisible-button" tooltip={getProductoYVariedadString(invernadero)}>
                        <div className={`icon-general icon-mini bor-${invernadero.color} bg-${invernadero.color}`} title={getProductoYVariedadString(invernadero)} >
                            {invernadero?.nombre}
                        </div>
                    </Button>} >
                   
                </Column>)
        });
        let headerGroup = <ColumnGroup>
            <Row>
                {columns}                
            </Row>
        </ColumnGroup>;
        return headerGroup;
    }
    return  <div className="p-grid p-justify-center">    

    <div className="p-col-12 title-container">
        <span className="back-btn" onClick = {atras}></span>
        <span className="icon-general plant-icon"></span>
        <span className="titles-group">
            <h1 className="header-title">
                Detalles de cultivos
            </h1>
        </span>
    </div>
    
    
    <div className="p-col-12">                            

                
                <div className="card p-0">
                    {invernaderos && invernaderos.length >0?
                        <DataTable value = {rows} headerColumnGroup = {obtenerHeader()} >                    
                            {obtenerColumnas()}
                        </DataTable>
                    :
                        <div className="card text-center">
                            <p>No hay actividades planeadas esta semana</p>
                        </div>
                    }
                    
                </div>
                  
    </div>
</div>
}