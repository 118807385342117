import React, {useEffect, useState} from 'react'
import {useGlobal} from 'reactn'
import { obtenerCiclo } from '../../service/Ciclos';
import { obtenerMedidasCultivo, obtenerReportesSemanalesCiclo, obtenerOutputsRegistroCultivo } from '../../service/Cultivo';
import { obtenerListaDeSemanas } from '../../service/fechas';
import Chart from "react-apexcharts";
import { colorAleatorio, stringToColor } from '../../util/functions';
import {MultiSelect} from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { mapeoTabsCultivoInvertido } from '../../constants';

const optionsOrig = {
    chart: {
    
      type: 'line',
      stacked: false
    },
    dataLabels: {
      enabled: false
    },
    title: {
      text: 'Mediciones de cultivo durante el ciclo',
      align: 'left',
      
    },
    xaxis: {
      categories: [],
    },
    yaxis: [
        {
            seriesName: 'Revenue',
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#FEB019'
            },
            labels: {
              style: {
                colors: '#FEB019',
              },
            },
            title: {
              text: "Revenue (thousand crores)",
              style: {
                color: '#FEB019',
              }
            }
          }
      ],
    tooltip: {
      enabled:true,
      followCursor:true,
    },
    legend: {
      horizontalAlign: 'center',
      offsetX: 40
    }
  }



const Cultivo = (props) => {
    const [ciclo,] = useGlobal("ciclo");
    const [cicloObj,] = useGlobal("cicloObj");
    const [invernaderosOrig, setInvernaderosOrig] = useState([]);
    const [,setCargando] = useGlobal("cargando");
    const [data, setData] = useState([]);
    const [options, setOptions] = useState({...optionsOrig})
    const [medidasOrig, setMedidasOrig] = useState([]);
    const [invernaderos, setInvernaderos] = useState([]);
    const [filtros, setFiltros] = useState();
    const [labels, setLabels] = useState([]);
    const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");

    useEffect(()=>{
      setHabilitadoSelectorCiclo(true)
    },[])

    useEffect(() => {
      if(invernaderosVirtuales){
        obtenerDatosIniciales();
      }
    }, [invernaderosVirtuales])
   
    const obtenerSeries = (params) => {
        if(filtros && data.length > 0){
            let reporteMedida = data.find(el=>el.idMedida === filtros.id);
            let series = reporteMedida.series;
            let ids = invernaderos.map(el=>el.id);
            series = series.filter(el=>ids.includes(el.idSerie));
            return series;
        }else{
            return [];
        }
         
    }
    
    const obtenerOpciones = (params) => {
        let copiaOptions = {...options};   
        copiaOptions["colors"] = [];
        
        let ids = invernaderos.map(el=>el.id);
        invernaderosOrig.forEach(el => {
          if(ids.includes(el.id)){
            copiaOptions["colors"].push(stringToColor(el.color));
          }
        })
        
        if (filtros){
            copiaOptions["yaxis"] =  {
                seriesName: filtros.nombre,
                decimalsInFloat:2,
                opposite:true,
                axisTicks:{show:true,color: colorAleatorio()},
                axisBorder: {
                    show: true,
                    color: '#00E396'
                },
                labels: {
                    style: {
                    colors: '#00E396',
                    }
                },
                title: {
                    text: filtros.nombre,
                    style: {
                    color: '#00E396',
                    }
                }
            }
        }
        
        
        copiaOptions["xaxis"]["categories"] = labels;
        return copiaOptions;
    }
    const atras = (params) => {
      props.history.goBack();
  }
    const obtenerDatosIniciales = async (params) => {
      try {
        setCargando(true);
        let medidas = await obtenerMedidasCultivo();
        let medidasPlus = medidas.slice();
        medidasPlus.push({nombre:"Peso promedio de fruta", id: "peso_promedio_fruta", tipo:"cultivo"});
        medidasPlus.push({nombre:"Producción semanal ", id: "produccion_semanal", tipo:"cultivo"});
        medidasPlus.push({nombre:"Producción acumulado", id: "produccion_acumulado", tipo:"cultivo"});
        medidasPlus = medidasPlus.sort((a,b)=>{
          if(a.tipo >b.tipo){
            return 1
          }else{
            return -1
          }
        })
        console.log("Invernaderos")
        let invernaderos = invernaderosVirtuales.filter(inv=>!inv.es_externo);
        //setInvernaderos(invernaderos);
        setInvernaderosOrig(invernaderos);
        setMedidasOrig(medidasPlus);
        console.log("Medidas")
        /* if(!filtros && medidasPlus.length > 0){
          setFiltros(medidasPlus[0]);
        } */
        let promesasReportes = invernaderos.map(invernadero=>obtenerReportesSemanalesCiclo(cicloObj, invernadero, medidas))
        let reportesInvernaderos = await Promise.all(promesasReportes);
        
        
        let procesado = await procesar(cicloObj,medidas,reportesInvernaderos, invernaderos);
        console.log("data")
        setData(procesado);
      } catch (error) {
        console.log("ERROR OBTENER DATOS INICIALES");
        console.log(error);
      }finally{
        setCargando(false);
      }
        
    }
    
    const itemTemplate = (item) => {
      let nombreTab = mapeoTabsCultivoInvertido[item["tipo"]]
      return `${nombreTab} - ${item.nombre}`
    }
    const procesar = async (cicloObj,medidas, reportes,invernaderos) => {
        let semanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
        let copiaOptions = {...options};
        let labels = semanas.map(el=>el.nombreCompacto);
        setLabels(labels);
        copiaOptions["xaxis"]["categories"] = labels;
        //copiaOptions["xaxis"] = {
        //          categories:labels,
        //convertedCatToNumeric:true,
        //}
        // 
        // copiaOptions["yaxis"] = medidas.map(medida=>{
        //         return {
        //             seriesName: medida.nombre,
        //             opposite:true,
        //             axisTicks:{show:true,color: colorAleatorio()},
        //             axisBorder: {
        //                 show: true,
        //                 color: '#00E396'
        //               },
        //               labels: {
        //                 style: {
        //                   colors: '#00E396',
        //                 }
        //               },
        //               title: {
        //                 text: medida.nombre,
        //                 style: {
        //                   color: '#00E396',
        //                 }
        //               }
        //         }
        // })
        setOptions(copiaOptions);

        let aSaltar = ["peso_promedio_fruta", "produccion_semanal","produccion_acumulado"];
        //let filtradas = medidas.filter(el=>!aSaltar.includes(el.id));
        let result = medidas.map(medida=>{
            let invs = reportes.map((invernadero,index) => {
                let promediosMedida = [];
                invernadero.forEach(reporteSemana => {
                    let found = reporteSemana.find(el=>el.medidaId === medida.id);
                    if(found){
                        promediosMedida.push(found.promedio);
                    }else{
                        promediosMedida.push(0);
                    }
                })
                let invernaderoObj = invernaderos[index];
                return {
                    idSerie: invernaderoObj.id,
                    name: invernaderoObj.nombre,
                    type:"line",
                    data: promediosMedida
                }
            })
            return {idMedida: medida.id, series: invs}
        })
        let outputs = await obtenerOutputsInvernaderos(invernaderos);
        
        
        result = result.concat(outputs);
        return result;
    }
    const obtenerOutputsInvernaderos = async (invernaderos) => {
      let promises = invernaderos.map(invernadero=>obtenerOutputs(invernadero));
      
      let results = await Promise.all(promises);
      let procesado = [{idMedida:"peso_promedio_fruta"},{idMedida:"produccion_semanal"},{idMedida:"produccion_acumulado"}]
      procesado.forEach(medida => {
        medida["series"] = [];
        invernaderos.forEach((invernadero, index) => {
          let invData = results[index][medida["idMedida"]];
          invData["idSerie"] = invernadero.id;
          invData["name"] = invernadero.nombre;
          medida["series"].push(invData);
        })
        
      })
      
      return procesado;
            
    }
    
    const obtenerOutputs = async (invernadero) => {
     let listaSemanas = await obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
     let promises = listaSemanas.map(el=> obtenerOutputsRegistroCultivo(ciclo, invernadero,el.time))
     let outputs = await Promise.all(promises);
     let seriePesoPromedioFruta = {idSerie:"peso_promedio_fruta", name:"Peso promedio fruta",type:"line",data:[]}
     let serieProduccionSemanal = {idSerie:"produccion_semanal", name:"Produccion Semanal",type:"line",data:[]}
     let serieProduccionAcumulado = {idSerie:"produccion_acumulado", name:"Produccion Acumulado",type:"line",data:[]}

     outputs.forEach(([peso, prodSem, prodAc]) => {
       seriePesoPromedioFruta.data.push(isNaN(peso)?0:peso);
       serieProduccionSemanal.data.push(prodSem);
       serieProduccionAcumulado.data.push(prodAc);

     })
     
     return {peso_promedio_fruta: seriePesoPromedioFruta, produccion_semanal:serieProduccionSemanal, produccion_acumulado: serieProduccionAcumulado};
    }
    
      let opts = obtenerOpciones();
      let series = obtenerSeries();
      
        return <div className="p-grid chart-page">
            <div className="p-col  p-col-auto title-container">
                  <span className="back-btn" onClick={atras} ></span>
                  <span className={`icon-general chart-icon `}></span>
                  <span className="titles-group">
                    <h1 className="header-title">Mediciones de cultivo comparación de invernaderos</h1>
                    
                  </span>
            </div>
            <div className = "p-col-12">
              <div className="p-grid filtros">
                <div className="form-group p-col">
                  <label htmlFor="filtroCliente">Mediciones de cultivo a graficar</label>
                  <Dropdown value={filtros} options={medidasOrig} onChange={(e) => setFiltros(e.value)} dataKey="id" optionLabel="nombre" 
                    itemTemplate = {itemTemplate}/>
                </div>
                <div className="form-group p-col">
                  <label htmlFor="filtroInvernadero">Invernaderos</label>
                  <MultiSelect value={invernaderos} options={invernaderosOrig} onChange={(e) => setInvernaderos(e.value)} dataKey="id" optionLabel="nombre" 
                    selectedItemsLabel={`${invernaderos.length} invernaderos seleccionados`}
                  />
                </div>
                
              </div>
            </div>
            <div className = "p-col-12 chart-container-filters">
            {options["xaxis"]["categories"].length >0?<Chart height="100%" options={obtenerOpciones()} series={obtenerSeries()} type="line" />:null}
            </div>
        </div>
}

export default Cultivo;
