import React, { useState, useEffect, useGlobal, useRef } from 'reactn';
import { Button } from 'primereact/button';
import { actualizarOrdenVenta, agregarHistorico, asignarOrdenItemIDs, eliminarOrden, getEmbarquesDeOrdenDeVentaPorManifiestos, manejarNotificacion, obtenerFotoUrlsdeOrdenDeVenta, obtenerHistorico, obtenerTotalPallets, ordenTieneHistorico } from '../../service/Ordenes';
import TablaItems from "./TablaItems";
import { unixToString, dosDecimales, DeepClone, getObjetoPorID } from '../../util/functions';
import { faMapMarkerAlt, faTh, faPallet, faUser, faCalendarDay, faFlagUsa, faTruck } from '@fortawesome/free-solid-svg-icons';
import { obtenerUsuarios } from '../../service/Usuarios';
import { noHaSidoClausurado } from '../../service/Ciclos';
import { obtenerPresentaciones } from '../../service/Presentaciones';
import ComponenteManifiestos from './manifiestos/ComponenteManifiestos';
import ContenedorBotonesRow from '..//common/ContenedorBotonesRow';
import RowBotonesTop from 'components/common/RowBotonesTop';
import { getFirestoreID } from 'service/Mantenimiento';
import { obtenerEmpaques } from 'service/Empaques';
import { obtenerManifiestosOrden } from '../../service/QueriesManifiestos';
import ConsultarCardDato from './componentes/ConsultarCardDato.comp';
import ContenedorHeader from '../common/ContenedorHeader';
import SeccionBotones from '../common/SeccionBotones';
import ModalConfirmacion from '../common/ModalConfirmacion';
import ModalFormularioItem from './componentes/ModalFormularioItem.comp';
import { getEmbarquesCliente, getEmpresas_orderBy_nombre, obtenerEmbarquesATiempo } from 'service/embarques';
import moment from 'moment';

const Consultar = (props) => {
  const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
  const [ciclo,] = useGlobal("ciclo");
  const [ciclos,] = useGlobal("ciclos");
  const [, setCargando] = useGlobal("cargando");
  const [usuario] = useGlobal("usuario");
  const [datosCargados, setDatosCargados] = useGlobal("datosCargadosVentas");
  const [clientes,] = useGlobal("clientes");
  const [cliente, setCliente] = useGlobal("clienteSeleccionado");
  const [invernaderos] = useGlobal("invernaderosVirtuales");
  const [, setPresentaciones] = useGlobal("presentaciones");
  const [, setEmpaques] = useGlobal("empaques");
  const [, setProductores] = useGlobal("productoresOrdenes");
  const [usuarios, setUsuarios] = useGlobal("usuariosOrdenes");
  const [embarquesTodos, setEmbarquesTodos] = useState([]);

  const [orden, setOrden] = useGlobal("ordenVenta");
  const [camiones] = useGlobal("camionesOnHold"); //Viene de la componente de manifiestos

  const [manifiestos, setManifiestos] = useState([]);
  const [embarques, setEmbarques] = useState(null);
  const [historico, setHistorico] = useState([]);
  const [fotosPresentaciones, setFotosPresentaciones] = useState([]);
  const [agItem, setAgItem] = useState(false);
  const [modalItem, setModalItem] = useState(false);
  const [itemsActualizados, setItemsActualizados] = useState(false);
  const [original, setOriginal] = useState({});
  const [modalEliminarOrden, setModalEliminarOrden] = useState(false);

  const [errores, setErrores] = useState({});
  const [, setShowGuardadoToast] = useGlobal("showGuardadoToast");

  const puedeEditar = () => noHaSidoClausurado(ciclo, ciclos) && (usuario && ["Administrador", "Administrador Empaque", "Administrador Embarques"].includes(usuario.rol));

  useEffect(() => {
    setHabilitadoSelectorCiclo(false);
  }, [])
  useEffect(() => {
    if (ciclo && orden) { obtenerDatosIniciales(); }
    else if (ciclo) { props.history.push("/ordenes"); }
  }, [ciclo])
  useEffect(() => {
    setAgItem(modalItem ? true : false);
  }, [modalItem])

  const obtenerDatosIniciales = async () => {
    setCargando(true);
    const ordenDeVenta = asignarOrdenItemIDs(orden);
    const cliente = getObjetoPorID(clientes, ordenDeVenta.cliente_ref);
    //console.log("CLIENTE: ", cliente)

    const promises = [obtenerManifiestosOrden(ciclo, ordenDeVenta.id), obtenerHistorico(ordenDeVenta.id)];
    if (!datosCargados) { promises.push(obtenerPresentaciones(), obtenerUsuarios(), obtenerEmpaques(), getEmpresas_orderBy_nombre()) }
    const [resultManifiestos, resultHistorico, resultPresentaciones, resultUsuarios, resultEmpaques, resultProductores] = await Promise.all(promises);
    const resultFotos = obtenerFotoUrlsdeOrdenDeVenta(ordenDeVenta, resultPresentaciones);

    setCliente(cliente);
    setManifiestos(resultManifiestos);
    setHistorico(resultHistorico);
    setFotosPresentaciones(resultFotos);

    if (!datosCargados) {
      setPresentaciones(resultPresentaciones);
      setUsuarios(resultUsuarios);
      setEmpaques(resultEmpaques);
      setProductores(resultProductores);
    }

    const [resultEmbarques, embarquesTodosResp] = await Promise.all([getEmbarquesDeOrdenDeVentaPorManifiestos(resultManifiestos, ciclo), getEmbarquesCliente(ciclo, ordenDeVenta.cliente_ref)]) ;
    //console.log("INFORMACION: ", { ordenDeVenta, resultEmbarques, resultManifiestos, ordenDeVenta,embarquesTodosResp });
    setEmbarquesTodos(embarquesTodosResp);
    setEmbarques(resultEmbarques);
    setOriginal(DeepClone(ordenDeVenta));//SET ORIGINAL!
    setOrden(ordenDeVenta);//SET ORDEN!

    setDatosCargados(true);
    setCargando(false);
  }

  const eliminarOrdenDeVenta = async () => {
    setCargando(true);
    try {
      setModalEliminarOrden(false);
      await eliminarOrden(ciclo, orden.id);
      props.history.goBack();
    } catch (error) {
      console.log("ERROR eliminarOrdenDeVenta: ", error);
    }
    setCargando(false);
  }

  const eliminarItemByID = (itemID, invernadero_ref, presentacion_ref, guardarRealTime) => {
    const ordenCopia = Object.assign({}, orden);
    const indexItem = ordenCopia.items?.findIndex(it => it.itemID === itemID);
    if (indexItem > -1) { ordenCopia.items.splice(indexItem, 1); }
    //console.log("DATOS ELIMINAR: ", { itemID, indexItem })

    childManifiestosRef.current.eliminarItemManifiesto(itemID, invernadero_ref, presentacion_ref);
    setOrden(ordenCopia);
    setOrden({ ...orden, items: DeepClone(ordenCopia.items) })
    setItemsActualizados(Date.now());

    if (guardarRealTime === true) {
      console.log("GUARDAR REAL TIME - eliminarItemByID : ", { ordenCopia });
      handleGuardadoGlobalItemsYManifiestos(ordenCopia, ordenCopia.items)
    }
  }
  const agregarItem = (item) => {
    if (!item.itemID) { item.itemID = getFirestoreID(); /* console.log("ADDED ITEMID! ", item.itemID);*/ }
    //console.log("ITEM - agregarItem: ", item);

    const ordenCopia = DeepClone(orden);
    ordenCopia.items.push(item);
    ordenCopia.items = ordenCopia.items.sort((a, b) => {
      const invA = getObjetoPorID(invernaderos, a.invernadero_ref), invB = getObjetoPorID(invernaderos, b.invernadero_ref);
      if (invA && invB) { return invA.nombre > invB.nombre ? 1 : -1; }
      else return 0;
    })

    setOrden(ordenCopia);
    setModalItem(false);
    setOrden({ ...orden, items: DeepClone(ordenCopia.items) })
    setItemsActualizados(Date.now());

    //Guardar real time
    handleGuardadoGlobalAgregarItem(ordenCopia, ordenCopia.items)
  }
  const actualizarItemManifiesto = (itemID, datosActualizados, invernadero_ref, presentacion_ref, new_invernadero_ref, new_presentacion_ref,) => {
    childManifiestosRef.current.actualizarItemManifiesto(itemID, datosActualizados, invernadero_ref, presentacion_ref, new_invernadero_ref, new_presentacion_ref,);
    setShowGuardadoToast(1500);
  }

  const childManifiestosRef = useRef();
  const setTablaItems = (items, guardarRealTime = false) => {
    setOrden({ ...orden, items: DeepClone(items) })
    setModalItem(null);

    if (guardarRealTime === true) {
      const stateCopia = { ...orden, items: DeepClone(items) };
      handleGuardadoGlobalItemsYManifiestos(stateCopia, stateCopia.items);
    }
  }
  const handleGuardadoGlobalItemsYManifiestos = async (stateAActualizar, itemsActualizadosAGuardar) => {
    //Guardar items cambiados de la tabla
    //setCargando(true);
    console.log("handleGuardadoGlobalItemsYManifiestos - ", { itemsActualizadosAGuardar, ciclo, stateAActualizar, camiones, original })
    try {
      await actualizarOrdenVenta(ciclo, stateAActualizar, itemsActualizadosAGuardar, camiones);
      //manejarNotificacion(original, stateAActualizar, usuario.uid, ciclo);
      //agregarHistorico(original, stateAActualizar, usuario.uid);
      setShowGuardadoToast(2500);
    }
    catch (error) {
      console.log("GUARDAR ERROR - ERROR AL ACTUALIZAR: ", error)
      //setModal({ titulo: "ERROR ACTUALIZAR", msj: error.message, hide: () => setModal(false) });
      const datos = { mensaje: error.message || "", error: error, estado: stateAActualizar, funcion: "registrar/actualizarOrdenVenta" };
      //log({ data: datos });
    }

    childManifiestosRef.current.borrarDatosCamionesNoEliminados();
    //setCargando(false);
  }
  const handleGuardadoGlobalAgregarItem = async (stateAActualizar, itemsAActualizar) => {
    //Guardar items cambiados de la tabla
    //setCargando(true);
    console.log("handleGuardadoGlobalItems - ", { itemsAActualizar, ciclo, stateAActualizar, camiones, original })
    try {
      await actualizarOrdenVenta(ciclo, stateAActualizar, itemsAActualizar, camiones);
      //manejarNotificacion(original, stateAActualizar, usuario.uid, ciclo);
      //agregarHistorico(original, stateAActualizar, usuario.uid);
      setShowGuardadoToast(2500);
    }
    catch (error) {
      console.log("GUARDAR ERROR - ERROR AL ACTUALIZAR: ", error)
      //setModal({ titulo: "ERROR ACTUALIZAR", msj: error.message, hide: () => setModal(false) });
      const datos = { mensaje: error.message || "", error: error, estado: stateAActualizar, funcion: "registrar/actualizarOrdenVenta" };
      //log({ data: datos });
    }

    //setCargando(false);
  }
  const handleGuardadoGlobalItems = async () => {
    //Guardar items cambiados de la tabla
    //setCargando(true);
    console.log("handleGuardadoGlobalItems - ", { itemsActualizados, ciclo, orden, camiones, original })
    try {
      await actualizarOrdenVenta(ciclo, orden, itemsActualizados);

      try { console.log("1 - manejarNotificacion"); manejarNotificacion(original, orden, usuario.uid, ciclo); } catch (err) { console.log("1 error manejarNotificacion: ", err) }
      try { console.log("2 - agregarHistorico"); agregarHistorico(original, orden, usuario.uid); } catch (err) { console.log("2 error agregarHistorico: ", err) }

      setShowGuardadoToast(2500);
      childManifiestosRef.current.borrarDatosCamionesNoEliminados();
    }
    catch (error) {
      console.log("GUARDAR ERROR - ERROR AL ACTUALIZAR: ", error)
      //setModal({ titulo: "ERROR ACTUALIZAR", msj: error.message, hide: () => setModal(false) });
      const datos = { mensaje: error.message || "", error: error, estado: orden, funcion: "registrar/actualizarOrdenVenta" };
      //log({ data: datos });
    }
    //setCargando(false);
  }

  if (!orden) { return null; }
  else return (
    <div className="p-grid p-justify-center ">
      <ContenedorHeader col="p-col p-col-auto" iconos="icon-image" titulo={`Orden de venta #${orden?.no_orden}`} atras={props.history.goBack} style={{ backgroundImage: `url(${cliente?.imagen})` }} />
      {puedeEditar() && /* PROBAR */
        <ContenedorBotonesRow >
          <RowBotonesTop>
            <Button label="Modificar orden" onClick={() => { props.history.push("/ordenes/registrar"); }} />
            <Button label="Eliminar orden" onClick={() => { setModalEliminarOrden(true); }} />
          </RowBotonesTop>
        </ContenedorBotonesRow>}

      <div className="card p-col-12">
        <div className=" p-col-12 p-grid order-details">
          <ConsultarCardDato icono={faMapMarkerAlt} texto="Destino" valor={orden?.destino.nombre} />
          <ConsultarCardDato icono={faFlagUsa} texto="Frontera" valor={orden?.frontera.nombre} />
          <ConsultarCardDato icono={faCalendarDay} texto="Fecha de envío" valor={moment.unix(orden?.fecha_envio).utc().format("DD/MM/YYYY")} />
          <ConsultarCardDato icono={faUser} texto="Cliente" valor={cliente?.nombre} />

          <div className=" p-col-12 p-grid order-details pb-0">
            {ordenTieneHistorico(orden) && <ConsultarCardDato icono={faTh} texto="Consolidado" valor={orden?.consolidado ? "Si" : "No"} />}
            <ConsultarCardDato icono={faPallet} texto="Pallets" valor={dosDecimales(obtenerTotalPallets(orden))} />
            <ConsultarCardDato icono={faTruck} texto={camiones?.length > 1 ? "Camiones" : "Camión"} valor={camiones?.length || 0} />
          </div>
        </div>
      </div>

      <TablaItems manifiestos={manifiestos} editing={true} items={orden.items} itemsActualizados={itemsActualizados} historico={historico} usuarios={usuarios || []}
        fotosPresentaciones={fotosPresentaciones} setTablaItems={setTablaItems} eliminarItemByID={eliminarItemByID} actualizarItemManifiesto={actualizarItemManifiesto}
        handleGuardadoGlobalItems={handleGuardadoGlobalItems} />

      <SeccionBotones >
        <ContenedorBotonesRow >
          <Button onClick={() => { setModalItem(true); }} label="Agregar Item" disabled={!puedeEditar()} />
        </ContenedorBotonesRow>
      </SeccionBotones>

      <ModalConfirmacion data={{ acceptLabel: "Eliminar", aceptar: eliminarOrdenDeVenta, cancelar: () => { setModalEliminarOrden(false) } }} show={modalEliminarOrden}
        header="Confirmación de acción" mensaje="¿Seguro que desea eliminar esta orden?" onHide={() => { setModalEliminarOrden(false); }} />
      <ModalFormularioItem header="Agregar item a orden de venta" visible={modalItem} onHide={() => { setModalItem(false) }} agregarItem={agregarItem} agItem={agItem}
        cancelar={() => setModalItem(false)} setItemsActualizados={setItemsActualizados} />

      <ComponenteManifiestos history={props.history} orden={orden} items={orden.items} ref={childManifiestosRef} embarques={embarques} embarquesTodos={embarquesTodos}
        setEmbarques={setEmbarques} manifiestos={manifiestos} setManifiestos={setManifiestos} errores={errores} setErrores={val => setErrores(val)} />
    </div>)
}
export default Consultar;
