import { segundosSemana } from "../constants";
import { dosDecimales } from "../util/functions";
import { obtenerCiclo } from "./Ciclos";
import { obtenerInicioSemana, obtenerInicioSemanaDia, obtenerListaDeSemanas, obtenerNombreSemana } from "./fechas";
import { obtenerPresentaciones } from "./Presentaciones";
import { obtenerManifiestosClienteYEmpresa } from "./Queries/QueriesCobranza";
import { RECHAZO, REPROCESO } from "./ReportePrecios";

export const obtenerResumenCobranzaTotalCliente = async (cicloId, cliente, empresaId,semana) => {
    let semanas = [];
    for (let index = 0; index < cliente.desfase_cobranza; index++) {
        const temp = obtenerInicioSemanaDia( semana + index * segundosSemana).unix();
        semanas.push(temp);
    }
    let result = {nombre:cliente.nombre, idCliente: cliente.id, };
    semanas.forEach(semana => {
        result[semana] = ""
    })
    let manifiestos = await obtenerManifiestosClienteYEmpresa(cicloId, cliente.id, empresaId)
    
    let ciclo = await obtenerCiclo(cicloId);
    let presentaciones = await obtenerPresentaciones();
    if(manifiestos.length > 0){
    }
    let reporte = procesarResumenCobranza( ciclo,manifiestos, presentaciones);
    
    return {...result, ... reporte};
}
export const procesarResumenCobranza = ( ciclo, manifiestos, presentaciones ) => {
    let semanas = obtenerListaDeSemanas(ciclo.semana_inicio, ciclo.semana_cierre);
    let result = {};
    semanas.forEach(semana=> {
      result[semana.time] = 0;
      manifiestos.forEach(manifiesto => {
          if(manifiesto["fecha_envio"] >= semana.time && manifiesto["fecha_envio"]< semana.time + segundosSemana){
            manifiesto.items.forEach(item => {
                if(item.status === "aprobado" ){
                    let presentacion = presentaciones.find(el=>item.presentacion_ref ===el.id);
                    let libras = parseFloat(presentacion.peso_neto_caja_libras) * parseFloat(item.precio_libra) * parseFloat(item.cajas_orden);
                    result[semana.time] += libras;
                }
                if(item.status === RECHAZO){
                        let presentacion = presentaciones.find(el=>item.presentacion_ref ===el.id);
                        let libras = parseFloat(presentacion.peso_neto_caja_libras) * parseFloat(item.precio_con_rechazo) * parseFloat(item.cajas_orden);
                        result[semana.time] += libras;
                }
                if(item.status === REPROCESO){
                    let presentacion = presentaciones.find(el=>item.presentacion_ref ===el.id);
                    let libras = parseFloat(presentacion.peso_neto_caja_libras) * parseFloat(item.precio_con_reproceso) * parseFloat(item.cajas_orden);
                    result[semana.time] += libras;
                }
            })
          }
      })
      result[semana.time] = dosDecimales(result[semana.time]);
    })
    return result;
}
//82