import React, { useEffect, useGlobal, useState } from 'reactn';
import { Dropdown } from 'primereact/dropdown';
import { TabMenu } from 'primereact/tabmenu';
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import ReporteDefectos from './ReporteDefectos';
import ReporteColoracion from './ReporteColoracion';
import ReportePesos from './ReportePesos';
import ReporteGradosBrix from './ReporteGradosBrix';
import ReporteCavidad from './ReporteCavidad';
import { MultiSelect } from 'primereact/multiselect';
import ContenedorFiltro from '../common/ContenedorFiltro';
import { obtenerDefectos } from '../../service/Defectos';
import { obtenerDiasDeSemanaConDetalles } from '../../service/fechas';
import { getProductoYVariedadString, getNombrePorID, getObjetoPorID } from '../../util/functions';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { obtenerProveedoresInvernadero } from '../../service/Queries/Proveedores';
import ContenedorHeader from '../common/ContenedorHeader';
import icono from '../../images/icons/external-icon.svg';
import { obtenerResumenFinal, obtenerResumenInicial } from 'service/Calidad';
import { SelectButton } from 'primereact/selectbutton';
import { estamosEnProduccion } from '../../constants';

const INICIAL = "Inicial", FINAL = "Final";
const selectButtonOptions = [{ name: INICIAL, }, { name: FINAL }];

const ReporteCalidad = (props) => {
    const [invernadero, setInvernadero] = useGlobal("invernadero");
    const [invernaderos, setInvernaderos] = useGlobal("invernaderos");
    const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
    const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector();
    const [ciclo,] = useGlobal("ciclo");
    const [filtroDefectos, setFiltroDefectos] = useState([]);
    const [tabs, setTabs] = useState([
        { label: "Defectos" },
        { label: "Coloración" },
        { label: "Grados Brix" },
        { label: "Pesos diarios" },
        { label: "Cavidad" }])
    const [activeTab, setActiveTab] = useState({ label: props.location?.data?.reporte || "Defectos" });
    const [defectos, setDefectos] = useState([])
    const [diasSemana, setDiasSemana] = useState([])
    const [filtroDias, setFiltroDias] = useState()
    const [globalToggle, setGlobalToggle] = useState(false)
    const [productosYVariedades] = useGlobal("productosYVariedades")
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");

    const [variedadesOpciones, setVariedadesOpciones] = useState(null);
    const [mezclaVariedades, setMezclaVariedades] = useState([]);
    const [subVariedad, setSubvariedad] = useState(false);
    const [proveedorExterno, setProveedorExterno] = useState("Todos");
    const [proveedoresOpciones, setProveedoresOpciones] = useState([]);
    const [selectButtonDatoDefectos, setSelectButtonDatoDefectos] = useState([...selectButtonOptions])
    const [presentacionesPesosDiariosOpciones] = useGlobal("presentacionesPesosDiariosOpciones");
    const [presentacionesPesosDiariosFiltro, setPresentacionesPesosDiariosFiltro] = useGlobal("presentacionesPesosDiariosFiltro");
    const indexTabDefault = estamosEnProduccion ? 0 : 3;

    useEffect(() => {
        setHabilitadoSelectorCiclo(true);
    }, [])
    useEffect(() => {//Seleccionar tab por defecto o seleccionar una derivada del path de una pantalla anterior
        const tabSeleccionado = tabs.find(el => el.label === props.location?.data?.reporte);
        const activeTab = tabSeleccionado ? tabSeleccionado : tabs[indexTabDefault];
        setActiveTab(activeTab);
    }, [tabs])
    useEffect(() => {
        if (invernadero && productosYVariedades) {
            let nombre = getNombrePorID(productosYVariedades, invernadero.producto_ref)
            if (nombre !== "BEEF") {
                setTabs([
                    { label: "Defectos" },
                    { label: "Coloración" },
                    { label: "Grados Brix" },
                    { label: "Pesos diarios" }])
            }
            else {
                setTabs([
                    { label: "Defectos" },
                    { label: "Coloración" },
                    { label: "Grados Brix" },
                    { label: "Pesos diarios" },
                    { label: "Cavidad" }])
            }
        }
    }, [invernadero, productosYVariedades])

    useEffect(() => {
        let dias = obtenerDiasDeSemanaConDetalles(week.time);
        const newDias = Object.keys(dias).map(idx => {
            const dia = dias[idx]
            dia.nombre = dia.nombre.charAt(0).toUpperCase() + dia.nombre.slice(1)
            return dia
        })
        newDias.push({ unixTime: 0, abreviacion: 'T', nombre: 'Semanal', nombrePlano: "semana" })
        setDiasSemana(newDias);
        setFiltroDias("semana");
    }, [week.time])

    useEffect(() => {
        if (invernaderosVirtuales && productosYVariedades && invernadero && week.nombre !== "Cargando") {
            obtenerDatosIniciales()
        }
    }, [invernaderosVirtuales, invernadero, week, productosYVariedades])

    useEffect(() => {
        if (invernadero && productosYVariedades) { setInvernaderoMezclaVariedades(); }
        setProveedorExterno("Todos")
    }, [invernadero, productosYVariedades])

    useEffect(() => {
        if (!globalToggle) { setSubvariedad(false); }
    }, [globalToggle])

    const actualizarDefectosDisponibles = (defectos) => {
        setDefectos(defectos)
        let ids = defectos.map(el => el.id);
        setFiltroDefectos(ids);
    }

    const obtenerDatosIniciales = async () => {
        let proveedores = await obtenerProveedoresInvernadero(ciclo, invernadero.id)

        //filtrar si no hay registros
        let proveedoresFiltrados = [], promises = [];
        for (const index in proveedores) {
            const prov = proveedores[index];
            if (prov.habilitado) {
                proveedoresFiltrados.push(prov);
                promises.push(Promise.resolve([[], [], []]))
            }
            else {
                const inicialPromise = obtenerResumenInicial(ciclo, invernadero.id, week.time, invernadero.es_externo, prov.id)
                const finalPromise = obtenerResumenFinal(ciclo, invernadero.id, week.time, invernadero.es_externo, prov.id)
                promises.push(Promise.all([inicialPromise, finalPromise]))
            }
        }

        const datosProveedores = await Promise.all(promises)
        for (const index in proveedores) {
            const prov = proveedores[index];
            const [ini, fin, pes] = datosProveedores[index]

            if (ini?.length > 0 || fin?.length > 0) {
                console.log("FF: ", [ini, fin, pes])
                proveedoresFiltrados.push(prov)
            }
        }

        setProveedoresOpciones(proveedoresFiltrados)

        updateCicloWeekSelector(ciclo);
        invernaderosVirtuales.forEach(invernadero => {
            const producto = productosYVariedades.find(prod => prod.id === invernadero.producto_ref)
            const variedad = producto?.variedades?.find(vari => vari.id === invernadero.variedad_ref)
            promises.push(invernadero.es_externo ? producto : { producto: producto, variedad: variedad });
        })
        let productosVariedades = await Promise.all(promises);
        invernaderosVirtuales.forEach((invernadero, index) => {
            invernadero["producto"] = invernadero.es_externo ? productosVariedades[index] : productosVariedades[index]["producto"];
            invernadero["variedad"] = invernadero.es_externo ? "" : productosVariedades[index]["variedad"];
        })
        setInvernaderos(invernaderosVirtuales);
        obtenerDefectos().then((defectos) => {
            setDefectos(defectos);
        }).catch((err) => {

        });
    }

    const setInvernaderoMezclaVariedades = async () => {
        const producto = getObjetoPorID(productosYVariedades, invernadero.producto_ref);
        const variedadesOpciones = producto.mezcla ? [{ id: "Todos", nombre: "Todos" }, ...producto.variedades] : null;
        setVariedadesOpciones(variedadesOpciones);
        setSubvariedad("Todos");

        if (producto.mezcla) { setMezclaVariedades(producto.variedades); }
        else { setMezclaVariedades([]); }
    }

    const irGrafica = (params) => {
        if (activeTab.label === "Pesos diarios")
            props.history.push({ pathname: "/calidad/graficas/reporte_pesos_calidad", data: { invernadero } });
        else if (activeTab.label === "Grados Brix")
            props.history.push("/calidad/grados_brix");
        else
            props.history.push({ pathname: "/calidad/graficas/heatmap", data: { invernadero } });
    }
    const handleSelectButton = (e) => {
        if (e.value.length === 0) return
        setSelectButtonDatoDefectos(e.value)
    }

    const getVariedadOpcion = () => subVariedad === "Todos" ? false : subVariedad;

    return (
        <div className="p-grid p-justify-center">
            <ContenedorHeader col="p-col p-col-auto" titulo="Reporte de calidad" subtituloInv={getProductoYVariedadString(invernadero)}
                invernaderoNombre={invernadero?.es_externo ? <img width="60" alt="icono" src={icono} /> : invernadero?.nombre}
                iconos={`icon-general icon-id bor-${invernadero?.color} bg-${invernadero?.color} ${invernadero?.habilitado ? "" : "Deshabilitado"}`} atras={props.history.goBack} />

            <div className="p-col p-col-auto">
                <div className="btn-row">
                    <Button className="reactbtn-icon" onClick={irGrafica} label="Ver gráfica">
                        <FontAwesomeIcon icon={faChartBar} />
                    </Button>
                </div>
            </div>

            <div className="p-col-12">
                <div className="p-grid filtros">
                    <div className="form-group p-col">
                        <label htmlFor="cinco">Invernadero</label>
                        <Dropdown id="filtroInvernadero" placeholder="Seleccionar"
                            dataKey="id" optionLabel="nombre"
                            options={invernaderosVirtuales} value={invernadero}
                            onChange={(e) => { setInvernadero(e.target.value) }} />
                    </div>
                    <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />

                    {invernadero && invernadero.es_externo && (activeTab?.label === "Defectos" || activeTab?.label === "Coloración" || activeTab?.label === "Grados Brix")
                        && <ContenedorFiltro label="Proveedor externo">
                            <Dropdown options={[{ nombre: "Todos", id: "Todos" }, ...proveedoresOpciones]} value={proveedorExterno} onChange={(e) => setProveedorExterno(e.target.value)}
                                optionLabel="nombre" optionValue="id" />
                        </ContenedorFiltro>}

                    {activeTab?.label === "Defectos" && <ContenedorFiltro label="Defecto">
                        <MultiSelect options={defectos} value={filtroDefectos} onChange={(e) => setFiltroDefectos(e.value)}
                            optionLabel="nombre" optionValue="id" selectedItemsLabel={`${filtroDefectos.length} defectos seleccionados`} />
                    </ContenedorFiltro>}
                    {activeTab?.label === "Pesos diarios" && <ContenedorFiltro label="por día de la semana">
                        <Dropdown options={diasSemana} value={filtroDias} onChange={(e) => setFiltroDias(e.value)}
                            optionLabel="nombre" optionValue="nombrePlano" />
                    </ContenedorFiltro>}

                    {variedadesOpciones?.length > 0 && (activeTab?.label === "Defectos" || activeTab?.label === "Coloración" || (activeTab?.label === "Grados Brix" && !variedadesOpciones?.length))
                        && <ContenedorFiltro label="variedad">
                            <Dropdown options={variedadesOpciones} value={subVariedad} onChange={(e) => setSubvariedad(e.target.value)}
                                optionLabel="nombre" optionValue="id" />
                        </ContenedorFiltro>}

                    {activeTab.label === tabs[0].label && <ContenedorFiltro label="dato">
                        <SelectButton options={selectButtonOptions} optionLabel="name" value={selectButtonDatoDefectos} onChange={handleSelectButton} multiple={true} />
                    </ContenedorFiltro>}
                    {activeTab?.label === "Pesos diarios" && <ContenedorFiltro label="presentaciones">
                        <MultiSelect options={presentacionesPesosDiariosOpciones} value={presentacionesPesosDiariosFiltro} onChange={(e) => setPresentacionesPesosDiariosFiltro(e.value)}
                            optionLabel="presentacion" dataKey="id" selectedItemsLabel={`${presentacionesPesosDiariosFiltro?.length} presentaciones seleccionadas`} />
                    </ContenedorFiltro>}

                </div>
            </div>
            <div className="p-col-12">
                <TabMenu model={tabs} activeItem={activeTab} onTabChange={(e) => { setActiveTab(e.value); }} />

                {activeTab.label === "Defectos"
                    && <ReporteDefectos semana={week.time} actualizarDefectosDisponibles={actualizarDefectosDisponibles} defectos={filtroDefectos} subVariedad={getVariedadOpcion()} proveedorExterno={proveedorExterno} datosAMostrar={selectButtonDatoDefectos} />}
                {activeTab.label === "Coloración"
                    && <ReporteColoracion semana={week.time} subVariedad={getVariedadOpcion()} proveedorExterno={proveedorExterno} />}
                {activeTab.label === "Grados Brix"
                    && <ReporteGradosBrix mezclaVariedades={mezclaVariedades} semana={week.time} proveedorExterno={proveedorExterno} />}
                {activeTab.label === "Pesos diarios"
                    && <ReportePesos semana={week.time} filtroDias={filtroDias} dias={diasSemana} />}
                {activeTab.label === "Cavidad"
                    && <ReporteCavidad semana={week.time} />}
            </div>
        </div>)
}
export default ReporteCalidad;