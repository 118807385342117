import React from 'react';
import {Card} from 'primereact/card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function OtroCard(props){
    const header = <span className="card-header"><div className={`card-id icon-general icon-id bg-${props.otro.color}  bor-${props.otro.color}`}>{props.otro.icono ? <FontAwesomeIcon icon={props.otro.icono} /> : null}</div><div><p className="card-up"></p><p className="card-down">{props.otro.nombre}</p></div></span>
    
    return  <div className="p-col-12 p-md-4 p-sm-6" onClick={ props.onClick }>
              <Card header = {header} className={`htg-card bor-${props.otro.color} ${props.otro.status}`}   >   
                <div className="p-grid p-justify-center text-center">
                  <div className="p-col">
                    <div className="card-block">
                      <p className="value">{props.otro.empleados}</p>
                      <p className="label">EMPLEADOS</p>
                    </div>
                  </div>
                  
                </div>
            </Card>
          </div>
}

export default OtroCard;