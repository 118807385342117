const getMapFilesInfoToSave = (infoFiles) => {
    const infoMapped = infoFiles.map(file=>{
        const { name, url, creationDate, metaData } = file
        return { 
            name, 
            url, 
            creationDate, 
            typeFile: metaData.contentType
        }
    })
    return infoMapped
}

export default getMapFilesInfoToSave