import React, { useState, useEffect, setGlobal, forwardRef, useImperativeHandle } from 'reactn'
import { Button } from 'primereact/button';
import SeccionBotones from '../../common/SeccionBotones';
import SeccionFormulario from '../../common/SeccionFormulario';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { eliminarEmbarque, getEmbarque, getEmpresas_orderBy_nombre, guardarConsolidadoEmbarque, obtenerEmbarquesSemana } from '../../../service/embarques';
import ContenedorInput from '../../common/ContenedorInput';
import { useGlobal } from 'reactn';
import { guardarManifiesto, actualizarManifiestosEmbarque, obtenerManifiestosOrden, actualizarManifiestosExistentes, buscarCoincidenciasManifiestos, eliminarManifiesto, actualizarManifiesto } from '../../../service/QueriesManifiestos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck, faPallet, faWeightHanging, faTh } from '@fortawesome/free-solid-svg-icons';
import ModalAsignacionItems from './ModalAsignacionItems';
import { obtenerInvernaderosFisicos } from '../../../service/Invernaderos';
import ModalConfirmacion from '../../common/ModalConfirmacion';
import BaseCard from 'components/common/cards/BaseCard';
import { DeepClone, dosDeciComas, dosDecimales, getObjetoPorID } from 'util/functions';
import { ToggleButton } from 'primereact/togglebutton'
import { Message } from 'primereact/message';
import SeccionDatosCenter from 'components/common/SeccionDatosCenter';
import { BOTONES_CANCELAR, BOTONES_GUARDAR, estamosEnProduccion } from '../../../constants';
import { getTotalKilosManifiestos, obtenerDivsCamion, obtenerNoCamiones, obtenerTotalPalletsManifiestos } from 'service/Ordenes';
import ConsultarCamionCardDato from '../componentes/ConsultarCamionCardDato.comp';
import { getFirestoreID } from 'service/Mantenimiento';
import { obtenerPallets } from 'service/Producto';

const ComponenteManifiestos = forwardRef((props, ref) => {
    const [ciclo] = useGlobal("ciclo");
    const [, setCargando] = useGlobal("cargando");
    const [productores] = useGlobal("productoresOrdenes");
    const [presentaciones] = useGlobal("presentaciones")
    const [invernaderos] = useGlobal("invernaderosVirtuales");
    const [, setShowGuardadoToast] = useGlobal("showGuardadoToast");

    const [, setCurrentCamion] = useGlobal("currentCamion")
    const [camiones, setCamiones] = useGlobal("camionesOnHold")
    const [orden] = useGlobal("ordenVenta");
    const [manifiestosOriginales, setManifiestosOriginales] = useState([]);
    const [manifiestoSeleccionado, setManifiestoSeleccionado] = useState();
    const [noCamiones, setNoCamiones] = useState(null)
    const [errores, setErrores] = useState("");
    const [showEliminarModal, setShowEliminarModal] = useState();

    useEffect(() => {
        //console.log("EDITED");
        if (ciclo && orden?.id && props.embarques) { actualizarCamionesDatos() }
    }, [JSON.stringify(orden), JSON.stringify(props.manifiestos), JSON.stringify(props.embarques)])

    const actualizarCamionesDatos = () => {
        const totalCamiones = obtenerNoCamiones(orden, invernaderos);
        const camionesResp = obtenerCamionesDatos(totalCamiones);
        setNoCamiones(totalCamiones);
        setCamiones(camionesResp);
    }
    const obtenerCamionesDatos = (totalCamiones = 0) => {
        const allCamiones = [], manisOriginales = [];
        for (let i = 0; i < totalCamiones; i++) {
            const ordenCamion = (i + 1);
            const manifiestosFiltrados = props.manifiestos?.filter(el => el.orden_camion === ordenCamion) || [];
            const manifiestos = manifiestosFiltrados?.map(mani => ({ ...mani, productor: getObjetoPorID(productores, mani.productor_ref) }));
            const existeManifiestoCamion = manifiestos?.length > 0;
            manisOriginales.push(...manifiestos);

            const { embarque_ref } = manifiestos?.find(mani => mani.embarque_ref) || {};
            let embarque = props.embarques?.find(embarque => embarque.id === embarque_ref);
            
            const embarqueEliminado = props.embarquesTodos?.find(el => el.orden_venta_ref === orden.id && el.indexCamion === i);
            if (!embarque?.id && embarqueEliminado?.id) { embarque = embarqueEliminado }

            const embarqueId = embarque?.id;
            const existeEmbarque = !!embarqueId;
            const esConsolidado = embarque?.consolidado ? true : false;
            //console.log("CAMION DATOS: ", { embarques: props.embarques, manifiestos })

            const totalPallets = obtenerTotalPalletsManifiestos(manifiestos);
            const totalKilos = getTotalKilosManifiestos(manifiestos, presentaciones);
            const camionDatos = { orden: ordenCamion, manifiestos, existeManifiestoCamion, embarque, embarqueId, existeEmbarque, esConsolidado, totalPallets, totalKilos };
            allCamiones.push(camionDatos);
        }
        //console.log("ALL CAMIONES: ", {allCamiones, embarques: props.embarques});
        if (!manifiestosOriginales?.length) { setManifiestosOriginales(manisOriginales); }
        return allCamiones;
    }
    //const existeManifiesto = (ordenCamion) => manifiestos.some(manifiesto => manifiesto.orden_camion === ordenCamion);

    useImperativeHandle(ref, () => ({
        actualizarItemManifiesto(itemID, datosActualizados, invernadero_ref, presentacion_ref, new_invernadero_ref, new_presentacion_ref) {
            //check manifiestos existentes
            const manifiestosCopia = DeepClone(props.manifiestos);
            const camionesCopias = DeepClone(camiones);
            const manifiestosAEditar = new Set();

            props.manifiestos.forEach((manifiesto, index) => {
                manifiesto.items.forEach((item, indexItem) => {
                    if (item.itemID === itemID || (item.invernadero_ref === invernadero_ref && item.presentacion_ref === presentacion_ref)) {
                        console.log("DATOS actualizarItemManifiesto MANIFIESTO :: ", { manifiestos: props.manifiestos, index, indexItem })
                        manifiestosCopia[index].items[indexItem] = { ...props.manifiestos[index].items[indexItem], ...datosActualizados };
                        manifiestosAEditar.add(manifiesto.id);
                    }
                })
            })

            //check manifiestos de camiones
            camiones.forEach((camion, index) => {
                camion.manifiestos.forEach((manifiesto, indexMan) => {
                    manifiesto.items.forEach((item, indexItem) => {
                        if (item.itemID === itemID || (item.invernadero_ref === invernadero_ref && item.presentacion_ref === presentacion_ref)) {
                            console.log("DATOS actualizarItemManifiesto CAMION :: ", { camiones, index, indexMan, indexItem })
                            camionesCopias[index].manifiestos[indexMan].items[indexItem] = { ...camiones[index].manifiestos[indexMan].items[indexItem], ...datosActualizados };
                        }
                    })
                })
            })
            setCamiones(camionesCopias);
            props.setManifiestos(manifiestosCopia);

            console.log("3 b4 async - actualizarItemManifiesto: ",);
            const manifiestoIds = [...manifiestosAEditar];
            const promises = manifiestoIds?.map(manifiestoId => actualizarManifiesto(ciclo, manifiestoId, getObjetoPorID(manifiestosCopia, manifiestoId))) || [];
            Promise.allSettled(promises).then(() => { setCargando(false); });
            console.log("4 DONE - actualizarItemManifiesto");
        },
        eliminarItemManifiesto(itemID, invernadero_ref, presentacion_ref) {
            //check manifiestos existentes
            setCargando(true);
            console.log("1 - eliminarItemManifiesto");
            const manifiestosCopia = DeepClone(props.manifiestos);
            const camionesCopias = DeepClone(camiones);
            const manifiestosAEditar = new Set();

            props.manifiestos.forEach((manifiesto, index) => {
                manifiesto.items.forEach((item, indexItem) => {
                    if (item.itemID === itemID || (item.invernadero_ref === invernadero_ref && item.presentacion_ref === presentacion_ref)) {
                        console.log("DATOS eliminarItemManifiesto MANIFIESTO :: ", { manifiestos: props.manifiestos, index, indexItem })
                        manifiestosCopia[index].items.splice(indexItem, 1);
                        manifiestosAEditar.add(manifiesto.id);
                    }
                })
            })

            //check manifiestos de camiones
            console.log("2 - eliminarItemManifiesto")
            camiones.forEach((camion, index) => {
                camion.manifiestos.forEach((manifiesto, indexMan) => {
                    manifiesto.items.forEach((item, indexItem) => {
                        if (item.itemID === itemID || (item.invernadero_ref === invernadero_ref && item.presentacion_ref === presentacion_ref)) {
                            console.log("DATOS eliminarItemManifiesto CAMION :: ", { camiones, index, indexMan, indexItem })

                            if (camionesCopias[index]?.manifiestos?.[indexMan]?.items)
                                camionesCopias[index].manifiestos[indexMan].items.splice(indexItem, 1);
                        }
                    })
                })
            })
            setCamiones(camionesCopias);
            props.setManifiestos(manifiestosCopia);

            console.log("3 b4 async - eliminarItemManifiesto: ",);
            const manifiestoIds = [...manifiestosAEditar];
            const promises = manifiestoIds?.map(manifiestoId => actualizarManifiesto(ciclo, manifiestoId, getObjetoPorID(manifiestosCopia, manifiestoId))) || [];
            Promise.allSettled(promises).then(() => { setCargando(false); });
            console.log("4 DONE - eliminarItemManifiesto");
        },
        borrarDatosCamionesNoEliminados() {
            console.log("000 - borrarDatosCamionesNoEliminados!");
            const manifiestosCopia = DeepClone(props.manifiestos);
            //const embarquesCopia = DeepClone(props.embarques);

            console.log("1A - borrarDatosCamionesNoEliminados");
            const manifiestossBorrar = manifiestosCopia?.filter(manifiesto => parseInt(manifiesto.orden_camion) > noCamiones) || [];
            const manifiestosAQuedar = manifiestosCopia?.filter(manifiesto => parseInt(manifiesto.orden_camion) <= noCamiones) || [];
            props.setManifiestos(manifiestosAQuedar);
            console.log("1B - borrarDatosCamionesNoEliminados manifiestos set: ", { manifiestossBorrar, manifiestosAQuedar });

            //const embarquesABorrar = embarquesCopia?.filter(embarque => manifiestossBorrar.some(manifiesto => manifiesto.embarque_ref === embarque.id))
            //const embarquesAQuedar = embarquesCopia?.filter(embarque => manifiestosAQuedar.some(manifiesto => manifiesto.embarque_ref === embarque.id))
            //props.setEmbarques(embarquesAQuedar);
            console.log("1C - borrarDatosCamionesNoEliminados embarques set: ", { manifiestossBorrar, manifiestosAQuedar });

            const promisesManifiestos = manifiestossBorrar.map(manifiesto => eliminarManifiesto(ciclo, manifiesto.id));
            //const promisesEmbarques = manifiestossBorrar.map(manifiesto => eliminarEmbarque(ciclo, manifiesto.embarque_ref));
            console.log("2 - borrarDatosCamionesNoEliminados");
            Promise.all([...promisesManifiestos]).then(() => { console.log("FINALIZADO BORRADOS MANIFIESTOS: ", manifiestossBorrar) });
            console.log("3 done - borrarDatosCamionesNoEliminados");
        }
    }))//END useImperativeHandle

    const handleChangeManifiesto = async (event) => {
        //console.log({ manifiestos: props.manifiestos, id: event.target.id })
        const { id, name, value } = event.target;
        const copiaManifiestos = DeepClone(props.manifiestos);

        const manifiestoIndex = copiaManifiestos?.findIndex(manifiesto => manifiesto.id === id);
        copiaManifiestos[manifiestoIndex][name] = value;
        props.setManifiestos(copiaManifiestos)

        if (name === "productor_ref") {
            await guardarManifiesto(ciclo, id, copiaManifiestos[manifiestoIndex]);
            setShowGuardadoToast(2500);
        }
    }
    const onKeyDownChange = async (keyPress, manifiesto) => {
        if (keyPress.key === "Enter") { await handleBlurEdit(manifiesto); }
        else if (BOTONES_CANCELAR.includes(keyPress.key) && hayCambiosManifiestos()) { props.setManifiestos(manifiestosOriginales); }//Cancelar y regresar datos como antes
    }
    const handleBlurEdit = async (manifiesto) => {
        console.log("handleBlurEdit!")
        if (manifiesto.manifiesto || manifiesto.numeroEmbarque) {
            const hayCoincidencias = buscarCoincidenciasManifiestos(manifiesto);
            if (hayCoincidencias) { setErrores(`${manifiesto.manifiesto} - ${manifiesto.numeroEmbarque}`); }
            else await guardarDatosManifiesto(manifiesto);
        }
    }
    const buscarCoincidenciasManifiestos = (manifiestoPassed) => {
        const { id, manifiesto, numeroEmbarque } = manifiestoPassed;
        const manifiestosFiltrados = props.manifiestos.filter(el => el.id !== id);//Para no comparar contra este misma manifiesto
        const hayCoincidencias = manifiestosFiltrados.some(el => el.manifiesto === manifiesto || el.numeroEmbarque === numeroEmbarque);
        return hayCoincidencias;
    }
    const guardarDatosManifiesto = async (manifiesto) => {
        const copiaManifiestosOriginales = DeepClone(manifiestosOriginales);
        const maniOrigIndex = copiaManifiestosOriginales?.findIndex(mani => mani.id === manifiesto?.id);
        if (hayCambiosManifiestos()) {
            console.log("GUARDAR guardarDatosManifiesto - DATOS: ", { ciclo, manifiesto });
            copiaManifiestosOriginales[maniOrigIndex] = DeepClone(manifiesto);
            setManifiestosOriginales(copiaManifiestosOriginales);

            await guardarManifiesto(ciclo, manifiesto.id, manifiesto);
            setShowGuardadoToast(2500);
        }
    }
    const hayCambiosManifiestos = () => JSON.stringify(props.manifiestos) !== JSON.stringify(manifiestosOriginales);

    const itemTemplate = (item, key) => {
        const presentacion = getObjetoPorID(presentaciones, item.presentacion_ref);
        const invernadero = getObjetoPorID(invernaderos, item.invernadero_ref);
        const datosItem = (presentacion && invernadero) ? `${presentacion.presentacion} - ${item.cajas_orden || 0} - ${invernadero.nombre}` : "-";
        return <li key={key}><div>{datosItem}</div></li>;
    }

    const handleToggleConsolidado = async (event, camion) => {
        try {
            const { value } = event.target;
            await guardarConsolidadoEmbarque(ciclo, camion?.embarque?.id, value);
            setShowGuardadoToast(2500);

            const embarqueIndex = props.embarques?.findIndex(el => el.id === camion?.embarque?.id);
            const copiaEmbarques = DeepClone(props.embarques || []);

            copiaEmbarques[embarqueIndex].consolidado = value;
            props.setEmbarques(copiaEmbarques);
        } catch (error) {
            console.log("ERROR handleToggleConsolidado", error);
        }
    }
    const handleAgregarManifiesto = (orden_camion) => {
        const copiaManifiestos = DeepClone(props.manifiestos);
        const { id: orden_ref, cliente_ref, fecha_envio } = orden;
        let embarque_ref = "";
        if (camiones[orden_camion - 1].existeEmbarque) {embarque_ref = camiones[orden_camion - 1].embarqueId; }
        copiaManifiestos.push({ id: getFirestoreID(), orden_ref, embarque_ref, productor: "", numeroEmbarque: "", manifiesto: "", items: [], cliente_ref, fecha_envio, orden_camion });
        props.setManifiestos(copiaManifiestos);
    }

    const botonCrearEmbarque = async (camionDatos) => {
        const camion = camiones.find(el => el.orden === camionDatos.orden);
        const copiaCamion = DeepClone(camion);
        setCurrentCamion(copiaCamion);
        copiaCamion.orden_venta_ref = orden.id;
        copiaCamion.indexCamion = parseInt(copiaCamion.orden) - 1;
        //console.log("copiaCamion",copiaCamion)
        props.history.push("/embarques/registrar");
    }

    const irEmbarqueDeCamion = (camion) => {
        if (camion.embarque?.id) { props.history.push(`/embarques/detalles_embarque/${camion.embarque.id}`) };
    }
    const borrarManifiesto = async (manifiestoObj) => {
        if (manifiestoObj?.id) {
            setCargando(true);
            await eliminarManifiesto(ciclo, manifiestoObj?.id);

            const manifiestosCopia = DeepClone(props.manifiestos);
            const indexManifiestoEliminado = manifiestosCopia?.findIndex(el => (el.id === manifiestoObj?.id || (el.numeroEmbarque === manifiestoObj.numeroEmbarque && el.manifiesto === manifiestoObj.manifiesto)))
            if (indexManifiestoEliminado > -1) {
                //console.log("borrarManifiesto datos: ", { indexManifiestoEliminado, MANIFIESTOS: props.manifiestos, manifiestoObj })
                manifiestosCopia.splice(indexManifiestoEliminado, 1);
            }
            props.setManifiestos(manifiestosCopia);
            setShowEliminarModal(null);
            setCargando(false);
        }
    }
    return (
        <div className="p-col-12 p-grid ml-0 mr-0 pl-0 pr-0">
            {camiones?.map((camion, cIndex) => {
                return (
                    <div key={`cam-${cIndex}`} className="p-col p-card-body-ordenes">
                        <div className="p-card p-component htg-card bor-gray-dark bg-transparent">
                            <div className="p-card-header">
                                <span className="card-header">
                                    <div className="card-id icon-general icon-id bg-gray-dark  bor-gray-dark">
                                        <FontAwesomeIcon icon={faTruck} />
                                    </div>
                                    <div>
                                        <p className="card-up"></p>
                                        <p className="card-down">Camión {camion.orden}</p>
                                    </div>
                                </span>
                            </div>

                            <div className="p-card-body">
                                <div className="p-card-content">
                                    <BaseCard tituloCard="Detalles de camión" extra="padding-card-manifiestos">
                                        <div className='p-grid p-justify-center mt-1' >
                                            <ConsultarCamionCardDato valor={camion.totalPallets} texto="Total de pallets" icono={faPallet} />
                                            <ConsultarCamionCardDato valor={camion.totalKilos} texto="Total de kilos" icono={faWeightHanging} />
                                            <ConsultarCamionCardDato valor={camion.esConsolidado ? "Si" : "No"} texto="Consolidado" icono={faTh} />
                                            {/* Figuras de camiones reales */}
                                            {camion?.totalPallets > 0 && obtenerDivsCamion(camion, invernaderos)}
                                        </div>
                                    </BaseCard>

                                    {camion.manifiestos?.map((manifiesto, mIndex) => {
                                        return (
                                            <SeccionFormulario key={`man-${cIndex}-${mIndex}`} titulo="Manifiesto nuevo">
                                                <ContenedorInput col="12" md="4" sm="12" label="Productor">
                                                    <Dropdown id={manifiesto.id} name="productor_ref" value={manifiesto.productor_ref} options={productores}
                                                        optionLabel="nombre" optionValue="id" onChange={handleChangeManifiesto} />
                                                </ContenedorInput>
                                                <ContenedorInput col="12" md="4" sm="12" label="No. de Embarque">
                                                    <InputText id={manifiesto.id} name="numeroEmbarque" value={manifiesto.numeroEmbarque} onChange={handleChangeManifiesto}
                                                        onBlur={() => handleBlurEdit(manifiesto)} onKeyDown={keyPress => onKeyDownChange(keyPress, manifiesto)} />
                                                </ContenedorInput>
                                                <ContenedorInput col="12" md="4" sm="12" label="Manifiesto">
                                                    <InputText id={manifiesto.id} name="manifiesto" value={manifiesto.manifiesto} onChange={handleChangeManifiesto}
                                                        onBlur={() => handleBlurEdit(manifiesto)} onKeyDown={keyPress => onKeyDownChange(keyPress, manifiesto)} />
                                                </ContenedorInput>

                                                <div className="p-col-12">
                                                    <p>Items asignados a manifiesto </p>
                                                    <ul>{manifiesto.items.map((item, itIndex) => itemTemplate(item, `itm-${cIndex}-${mIndex}-${itIndex}`))}</ul>
                                                </div>
                                                <div className={"p-col-12 p-hor-05"}>
                                                    <div className="p-grid p-justify-end btn-row p-hor-05">
                                                        <Button label="Asignar Items" onClick={() => setManifiestoSeleccionado(manifiesto)} />
                                                        <Button className="btn-manifiesto" label="Eliminar manifiesto" onClick={() => setShowEliminarModal(manifiesto)} />
                                                    </div>
                                                </div>
                                            </SeccionFormulario>)
                                    })}
                                    {props.errores?.sinEmbarques?.includes(cIndex) && <Message severity="error" text="Necesitas crear un embarque para poder guardar." />}

                                    <SeccionBotones extra="btn-row-man">
                                        <Button className="btn-camion" label="Agregar manifiesto" onClick={() => handleAgregarManifiesto(camion.orden)} />
                                        {!camion.existeEmbarque
                                            ? <Button className="btn-camion" disabled={!camion.existeManifiestoCamion} label="Crear embarque" onClick={() => botonCrearEmbarque(camion)} />
                                            : <Button className="btn-camion" label="Consultar embarque" onClick={() => irEmbarqueDeCamion(camion)} />}
                                        {camion.existeEmbarque &&
                                            <ToggleButton id={cIndex} className="btn-consolidar" onLabel="Quitar consolidado" offLabel="Consolidar" checked={camion.esConsolidado} onChange={(ev) => handleToggleConsolidado(ev, camion)} />}
                                    </SeccionBotones>
                                </div>
                            </div>
                        </div>
                    </div>)
            })}

            <ModalAsignacionItems visible={!!manifiestoSeleccionado} manifiestos={props.manifiestos || []} manifiesto={manifiestoSeleccionado || {}}
                setManifiestos={props.setManifiestos} onHide={() => setManifiestoSeleccionado(null)} />
            <ModalConfirmacion show={!!errores} data={{ mensaje: `Hay duplicados con el manifiesto ${errores}`, aceptar: () => setErrores(""), cancelar: () => setErrores("") }}
                onHide={() => setErrores("")} />
            <ModalConfirmacion data={{ acceptLabel: "Eliminar", aceptar: () => borrarManifiesto(showEliminarModal), cancelar: () => { setShowEliminarModal(null) } }}
                show={!!showEliminarModal} header="Confirmación de acción" mensaje="¿Seguro que desea eliminar este manifiesto?" onHide={() => { setShowEliminarModal(null); }} />

        </div >)
})
export default ComponenteManifiestos;//785