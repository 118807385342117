import React, { useState, useEffect } from 'react';
import { useGlobal } from 'reactn';
import { obtenerDesglosePorcentajeActividadesImportantesCiclo, nombreActividadCosecha } from '../../service/nomina';
import { obtenerPorcentajeHorasTrabajadasEnCiclo, obtenerCuentaEmpleadosInvernadero } from '../../service/RhService';
import { obtenerCiclo } from '../../service/Ciclos';
import { obtenerActividadesImportantes } from '../../service/actividades';
import Chart from "react-apexcharts";
import { obtenerListaDeSemanas } from '../../service/fechas';
import { MultiSelect } from 'primereact/multiselect';
import { nombreActividadEnrede, nombreActividadEnredePlus, nombreActividadClipeo, nombreActividadClipeoPlus } from '../../constants';
import { Dropdown } from 'primereact/dropdown';



const paletaColores = [
  "#BAC3C3",
  "#44A026",
  "#62D60A",
  "#047bda",
  "#45abec",
  "#7C08C4",
  "#BA3FEB",
  "#FC66B7",
  "#D60074",
  "#FF3C3C",
  "#DB000E",
  "#E67E00",
  "#FCAD5A",
  "#FDE450",
  "#EBCA12",
  "#94A5A5",
]
const options = {
  colors: paletaColores,
  chart: {

    type: 'line',
  },
  title: {
    text: 'Desglose de porcentaje de actividades'
  },
  dataLabels: {

  },
  labels: [],


}
const DesglosePorcentajeActividades = (props) => {
  const [invernadero, setInvernadero] = useState(props.location?.data?.invernadero);
  const [invernaderos, setInvernaderos] = useState([])
  const [ciclo,] = useGlobal("ciclo");
  const [cicloObj,] = useGlobal("cicloObj");
  const [datasets, setDatasets] = useState([]);
  const [labels, setLabels] = useState([]);
  const [filtrosOrig, setFiltrosOrig] = useState([]);
  const [valoresFiltro, setValoresFiltro] = useState([]);
  const [, setCargando] = useGlobal("cargando");
  const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
  const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");

  useEffect(()=>{
    setHabilitadoSelectorCiclo(true)
  },[])

  useEffect(() => {
    if(invernaderosVirtuales){
      let invernaderos = invernaderosVirtuales.filter(inv=>!inv.es_externo)
      setInvernaderos(invernaderos);
    }
    if (invernaderosVirtuales && invernadero) {
      obtenerInfo();
    }
  }, [invernaderosVirtuales, invernadero]);

  const generarOptions = (params) => {
    let copia = { ...options };
    copia["labels"] = labels;
    return copia;
  }

  const handleFilterChange = (e) => {
    setValoresFiltro(e.value);
  }

  const obtenerData = () => {
    if (valoresFiltro.length > 0) {
      let idsFiltros = valoresFiltro.map(el => el.id);
      let result = datasets.filter(el => {
        return idsFiltros.includes(el.datasetId) || el.datasetId === "noId"
      })
      return result;
    } else {
      return []
    }
  }

  const obtenerInfo = async (params) => {
    try {
      setCargando(true);
      let cuentaEmpleados = await obtenerCuentaEmpleadosInvernadero(ciclo, invernadero.id);
      let promises = [];
      promises.push(obtenerDesglosePorcentajeActividadesImportantesCiclo(ciclo, invernadero));
      promises.push(obtenerActividadesImportantes());
      promises.push(obtenerPorcentajeHorasTrabajadasEnCiclo(cicloObj, invernadero, cuentaEmpleados));
      let [desglosado, actividadesImportantes, porcentajeHoras] = await Promise.all(promises);
      let semanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
      actividadesImportantes = actividadesImportantes.docs.map(el => ({ id: el.id, ...el.data() })).filter(el => el.nombre !== nombreActividadCosecha)
      setFiltrosOrig(actividadesImportantes);
      setLabels(semanas.map(el => el.nombreCompacto));
      procesar(desglosado, porcentajeHoras, actividadesImportantes);
    } catch (error) {
      console.log("hubo un error");
      console.log(error);
    } finally {
      setCargando(false);
    }


  }
  const procesar = (desglosado, porcentajeHoras, actividadesImportantes) => {
    let lineasTotales = calcularLineasTotales(invernadero);
    //Insertar porcentaje de horas manualmente a dataset
    let datasets = [];
    datasets.push({
      datasetId: "noId",
      name: "Porcentaje de horas",
      type: "column",
      data: porcentajeHoras.map(el => el.porcentaje)
    })
    actividadesImportantes.forEach(actividad => {
      let data = desglosado.map(semanal => {
        let found = semanal.find(el => el.idActividad === actividad.id);
        if (found) {
          if (lineasTotales > 0) {

            return Math.round(100 * found.suma / lineasTotales);
          }
          else {
            return 0;
          }
        }
      })
      datasets.push({
        datasetId: actividad.id,
        name: actividad.nombre,
        data: data,
        type: "line"
      })
    })
    let antes = datasets.slice();
    datasets = unificarActividadesPlus(datasets, actividadesImportantes);
    setDatasets(datasets);
  }
  const unificarActividadesPlus = (datasets, actividadesImportantes) => {
    let actividadEnrede = actividadesImportantes.find(el => el.nombre === nombreActividadEnrede);
    let actividadEnredePlus = actividadesImportantes.find(el => el.nombre === nombreActividadEnredePlus);
    let datasetEnrede = datasets.find(el => el.datasetId === actividadEnrede.id);
    let datasetEnredePlus = datasets.find(el => el.datasetId === actividadEnredePlus.id);
    datasetEnrede.data = datasetEnrede.data.map((currentItem, index) => {
      return currentItem + datasetEnredePlus.data[index];
    })

    let actividadClipeo = actividadesImportantes.find(el => el.nombre === nombreActividadClipeo);
    let actividadClipeoPlus = actividadesImportantes.find(el => el.nombre === nombreActividadClipeoPlus);
    let datasetClipeo = datasets.find(el => el.datasetId === actividadClipeo.id);
    let datasetClipeoPlus = datasets.find(el => el.datasetId === actividadClipeoPlus.id);
    datasetClipeo.data = datasetClipeo.data.map((currentItem, index) => {
      return (currentItem + datasetClipeoPlus.data[index]) / 2;
    })
    let result = datasets.slice();
    result = result.filter(el => el.datasetId != actividadEnredePlus.id);
    result = result.filter(el => el.datasetId != actividadClipeoPlus.id);


    return result;
  }

  const calcularLineasTotales = (invernadero) => {
    let asignacion_lineas = invernadero["asignacion_lineas"];
    let total = asignacion_lineas.reduce((acc, curr) => {
      let dato = curr["totales"].split(" ")[0]
      if (dato) {
        dato = parseInt(dato);
        if (!isNaN(dato)) {
          return acc + dato;
        } else {
          return acc;
        }
      }
    }, 0)

    return total
  }

  return <div className="p-grid chart-page">
    {invernadero ? <div className="p-col  p-col-auto title-container">
      <span className="back-btn" onClick={() => { props.history.goBack() }}></span>
      <span className={` icon-general chart-icon  bg-${invernadero.color}  bor-${invernadero.color}`}></span>
      <span className="titles-group">
        <h1 className="header-title">Desglose de actividades</h1>
        <p className={`header-subtitle `}>Invernadero {invernadero.nombre}</p>
      </span>
    </div>
      :
      <div className="p-col  p-col-auto title-container">
        <span className="back-btn" onClick={() => { props.history.goBack() }}></span>
        <span className={` icon-general chart-icon  `}></span>
        <span className="titles-group">
          <h1 className="header-title">Desglose de actividades</h1>
        </span>
      </div>

    }
    <div className="p-col-12">
      <div className="p-grid filtros">
        <div className="form-group p-col">
          <label htmlFor="filtroInvernadero">Invernadero</label>
          <Dropdown options={invernaderos} value={invernadero} onChange={e => setInvernadero(e.value)} optionLabel="nombre" dataKey="id" />
        </div>
        <div className="form-group p-col">
          <label htmlFor="filtroCliente">Actividades a graficar</label>
          <MultiSelect options={filtrosOrig} value={valoresFiltro} onChange={handleFilterChange} optionLabel="nombre" dataKey="id" 
            selectedItemsLabel={`${valoresFiltro.length} actividades seleccionados`} />
        </div>
      </div>
    </div>
    <div className="p-col-12 chart-container-filters">
      <Chart series={obtenerData()} options={generarOptions()} height="100%" type="line" />
    </div>
  </div>
}

export default DesglosePorcentajeActividades;