import React from "react"
import Select from "react-select"

const DropdownAgrupadoBase = (props) => {
    const { onChange, label, md, col, optionLabel, optionValue, dataKey, GroupHeading, name, value: valuePassedIn } = props;

    const adaptar = () => {//Por si es dataKey o optionValue (objeto vs. id para el valor)
        if (optionValue) {
            let valueObj = null;
            props.options.forEach(el => {
                if (!valueObj) valueObj = el.options?.find(el => el[optionValue] === valuePassedIn);
            });
            //console.log("ADAPTER: ", { valueObj, VALUE: props.value, oo: props.options })
            return valueObj;
        }
        else return valuePassedIn;
    }
    const adaptarDatosChange = (datosNuevos) => {
        const value = optionValue ? datosNuevos[optionValue] : datosNuevos;
        onChange({ target: { value, name } });
    }

    const getOptionLabel = (option) => { return option[optionLabel]; }
    const getOptionValue = (option) => { return option[dataKey || optionValue]; }

    return (
        <div className={`form-group p-md-${md || "6"} p-col-${col || "12"}`}>
            <label>{label}</label>
            <Select getOptionLabel={getOptionLabel} getOptionValue={getOptionValue} components={GroupHeading ? { GroupHeading } : null}  {...props}
                onChange={adaptarDatosChange} value={adaptar()} />
        </div>);
}
export default DropdownAgrupadoBase;