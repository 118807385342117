import { getGlobal } from "reactn";
import {
    obtenerDiasDeSemana, obtenerListaDeSemanas,
    obtenerDiaActual,
    obtenerSemanaInicioYFinal,
    flexDate
} from "./fechas";
import * as firebase from "firebase/app";
import "firebase/firestore";
import { obtenerPesoDia, obtenerPesoSemana, obtenerPesoCajaParaSemana, obtenerRegistrosSemana as obtenerRegistrosSemanaEmpaque } from "./Producto";
import { obtenerPesoSemanaInteligente, obtenerPesoYCajasSemanaInteligenteReporteCosecha } from './ReportesProducto'
import { obtenerInvernadero, obtenerInvernaderosVirtualesActivos, obtenerAreaInvernaderoSync, obtenerInvernaderosVirtualesInternos, obtenerInvernaderosExternos, obtenerAreaInvernaderoExterno } from './Invernaderos';
import { obtenerCiclo } from "./Ciclos";
import { obtenerReporteCompraExternaProveedorSemana } from "./CompraExterna";
import { obtenerProveedores } from "./Queries/Proveedores";
import { nombresDiasSemana, nombresDiasSemanaPlanos, segundosSemana } from "../constants";
import { obtenerRealesVariedadMezcla, procesarDetalles } from "../components/cosecha/ConsultarCtrl";
import { uploadStatistics } from './Estadisticas'
import { getFirestoreID } from "./Mantenimiento";
import { getObjetoPorID } from "../util/functions";

function registrarInvocaciones(count) {
    uploadStatistics(count)
}

const dias = ["lunes", "martes", "miercoles", "jueves", "viernes", "sabado"];
const pesoCajaExternos = 19;

export async function guardarRegistroCosecha(ciclo, invernadero, semana, tabla, cosechaFirestoreIds) {
    try {
        let timesDias = obtenerDiasDeSemana(semana);
        //por cada dia
        //agregar registro en coleccion de cosecha

        const promises = dias.map((dia, index) => registrarCosechaDia(ciclo, invernadero, timesDias[index], tabla[0][dias[index]], tabla[1][dias[index]], null, cosechaFirestoreIds[index]));
        /*let promises = [];
          for (let x = 0; x < dias.length; x++) {
              promises.push(registrarCosechaDia(ciclo, invernadero, timesDias[x], tabla[0][dias[x]], tabla[1][dias[x]]), detalle);
          }*/
        const values = await Promise.all(promises);

        return values
    } catch (error) {
        console.log("err ", error)
    }
}
async function registrarCosechaDia(ciclo, invernadero, dia, semanal, diaria, detalle, registroID = getFirestoreID()) {
    try {
        let obj = { dia: dia, semanal: semanal, diaria: diaria, };
        if (detalle) obj.detalle = detalle;

        await firebase.firestore().collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero).collection("cosecha").doc(registroID).set(obj, { merge: true })
        return true
    } catch (error) {
        console.log("err registrar ", error)
    }
}

export function guardarRegistroCosechaSubcultivo(ciclo, invernadero, semana, detalle, cosechaFirestoreIds) {
    let tabla = procesarDetalleSubcultivo(detalle);
    return new Promise((resolve, reject) => {
        let timesDias = obtenerDiasDeSemana(semana);
        let promises = [];
        //por cada dia
        //agregar registro en coleccion de cosecha
        for (let x = 0; x < dias.length; x++) {
            let detalleDia = obtenerDetalleDia(dias[x], detalle)
            promises.push(registrarCosechaDia(ciclo, invernadero, timesDias[x], tabla[0][dias[x]], tabla[1][dias[x]], detalleDia, cosechaFirestoreIds[x]));
        }
        Promise.all(promises).then(values => {
            resolve(values);
        }).catch(err => {
            reject(err);
        })
    })


}
const obtenerDetalleDia = (dia, detalle) => {
    let keys = Object.keys(detalle);
    let detalleDia = {};
    keys.forEach(k => {

        detalleDia[k] = {};
        detalleDia[k]["diario"] = detalle[k][1][dia] || ""
        detalleDia[k]["semanal"] = detalle[k][0][dia] || ""
    })
    return detalleDia;
}

const procesarDetalleSubcultivo = (detalle) => {
    let keys = Object.keys(detalle);
    // 
    let tablaSemanal = {
        lunes: "",
        martes: "",
        miercoles: "",
        jueves: "",
        viernes: "",
        sabado: "",
    };
    let tablaDiaria = {
        lunes: "",
        martes: "",
        miercoles: "",
        jueves: "",
        viernes: "",
        sabado: "",
    };
    keys.forEach(k => {
        let current = detalle[k];
        nombresDiasSemanaPlanos.forEach(nombre => {
            if (tablaSemanal[nombre] == "") {

                tablaSemanal[nombre] = parseFloat(current[0][nombre]) || ""
            } else {
                tablaSemanal[nombre] += parseFloat(current[0][nombre]) || 0
            }
            if (tablaDiaria[nombre] == "") {

                tablaDiaria[nombre] = parseFloat(current[1][nombre]) || ""
            } else {
                tablaDiaria[nombre] += parseFloat(current[1][nombre]) || 0
            }
        })

    })
    return [tablaSemanal, tablaDiaria]
}

export function obtenerRegistrosSemana(ciclo, invernadero, semana) {

    return new Promise((resolve, reject) => {
        let rangoDeSemana = obtenerSemanaInicioYFinal(semana)

        obtenerCochechas(ciclo, invernadero, rangoDeSemana.inicio, rangoDeSemana.final).then(values => {
            console.log(values, "values")
            resolve(values);
        }).catch(err => {
            reject(err);
        })

    })
}
function obtenerCosecha(ciclo, invernadero, dia) {
    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
        const timestamp = flexDate(dia)
        db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero)
            .collection("cosecha").where("dia", ">=", timestamp.inicio).where("dia", "<=", timestamp.fin).get()
            .then(res => {
                if (!res.empty) {
                    let data = res.docs[0].data();
                    data.id = res.docs[0].id;
                    resolve(data)
                } else {
                    resolve(null);
                }
            }).catch(err => {
                reject(err);
            })
    })

}

function obtenerCochechas(ciclo, invernadero, inicioDeSemana, finDeSemana){
    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
        db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero)
            .collection("cosecha").where("dia", ">=", inicioDeSemana).where("dia", "<=", finDeSemana).get()
            .then(res => {
                if (!res.empty) {
                    resolve(res.docs.map(doc => {
                        let data = doc.data();
                        data.id = doc.id;
                        return data;
                    }))
                } else {
                    resolve([]);
                }
            }).catch(err => {
                reject(err);
            })
    })
}

export async function obtenerValoresReales(ciclo, invernadero, semana) {

    let promises = [];
    let diasTimes = obtenerDiasDeSemana(semana);
    let invernaderoObj = await obtenerInvernadero(ciclo, invernadero);


    // obtener registros empaque semana
    let registros = await obtenerRegistrosSemanaEmpaque(ciclo, invernadero, semana);
    //obtener cajas semana, si no hay cajas
    let esViableUsarCajas = determinarViabilidadUsarCajas(registros);
    let result = [];
    if (esViableUsarCajas) {
        result = obtenerCajasDiasSemana(diasTimes, registros);
    } else {
        let peso = await obtenerPesoCajaParaSemana(ciclo, invernadero, semana, parseFloat(invernaderoObj.peso_caja_cosecha));
        result = calcularCajasDiasSemanaBasePeso(diasTimes, registros, peso);
    }
    // 
    // 
    return result;


}

export async function obtenerValoresRealesParaCumplimiento(ciclo, invernadero, semana) {

    let promises = [];
    let diasTimes = obtenerDiasDeSemana(semana);
    let invernaderoObj = await obtenerInvernadero(ciclo, invernadero);


    // obtener registros empaque semana
    let registros = await obtenerRegistrosSemanaEmpaque(ciclo, invernadero, semana);
    //obtener cajas semana, si no hay cajas
    let esViableUsarCajas = determinarViabilidadUsarCajas(registros);
    let result = [];


    result = obtenerKilosDiasSemana(diasTimes, registros);
    if (invernadero === "BNJaI7zq9HsVX4csfoS2") {



    }
    return result;


}
const determinarViabilidadUsarCajas = (registros) => {
    let flag = true;
    registros.forEach(current => {
        current.pallets.forEach(pallet => {
            if (!(pallet.cajas && !isNaN(parseFloat(pallet.cajas)))) {
                flag = false
            }
        })
    })
    return flag;
}
const obtenerCajasDiasSemana = (dias, registros) => {
    let results = [];
    dias.forEach(dia => {
        const timestamp = flexDate(dia);
        let suma = 0;
        registros.forEach(registro => {
            if (registro.dia >= timestamp.inicio && registro.dia <= timestamp.fin) {
                registro.pallets.forEach(pallet => {
                    suma += parseFloat(pallet.cajas);
                })
            }
        });
        results.push(suma);
    });

    return results;
}
const obtenerKilosDiasSemana = (dias, registros) => {
    let results = [];
    dias.forEach(dia => {
        let suma = 0;
        registros.forEach(registro => {
            const timestamp = flexDate(dia);
            if (registro.dia >= timestamp.inicio && registro.dia <= timestamp.fin) {
                registro.pallets.forEach(pallet => {
                    let cantidad = parseFloat(pallet.cantidad);
                    suma += isNaN(cantidad) ? 0 : cantidad
                })
            }
        });
        results.push(suma);
    });

    return results;
}
const calcularCajasDiasSemanaBasePeso = (dias, registros, peso) => {
    // 
    // 
    // 
    // 
    let results = [];
    dias.forEach(dia => {
        let suma = 0;
        registros.forEach(registro => {
            const timestamp = flexDate(dia);
            if (registro.dia >= timestamp.inicio && registro.dia <= timestamp.fin) {
                registro.pallets.forEach(pallet => {
                    let peso = 0;
                    if (pallet.cantidad && !isNaN(parseFloat(pallet.cantidad))) {
                        peso = parseFloat(pallet.cantidad);
                    }
                    suma += peso;
                })
            }
        });
        results.push(suma / peso);
    });
    return results;
}


function obtenerCosechaRealDia(ciclo, invernadero, dia, peso) {

    return new Promise((resolve, reject) => {
        obtenerPesoDia(ciclo, invernadero, dia).then(pesoReal => {
            let real = pesoReal / peso
            resolve(real);
        }).catch(err => {
            reject(err);
        })
    })


}

export async function obtenerCosechaSemanal(ciclo, invernadero, semana) {
    try {
        let promises = [];
        let diasTimes = obtenerDiasDeSemana(semana);
        diasTimes.forEach(dia => {
            promises.push(obtenerCosecha(ciclo, invernadero, dia));
        })
        let result = await obtenerInvernadero(ciclo, invernadero);
        let peso = await obtenerPesoCajaParaSemana(ciclo, invernadero, semana, parseFloat(result.peso_caja_cosecha))
        let values = await Promise.all(promises);
        let total = 0;
        values.forEach(el => {
            if (el) {
                let value = parseFloat(el.semanal);
                if (!isNaN(value)) {
                    total += value;
                }
            }
        })
        let area = obtenerAreaInvernaderoSync(result);

        return (total * peso / area);
    } catch (error) {
        throw error
    }
}
export async function obtenerCosechaSemanalReal(ciclo, invernaderoObj, semana) {
    try {
        let proyecciones = await obtenerProyeccionesSemana(ciclo, invernaderoObj, semana);
        let peso = await obtenerPesoCajaParaSemana(ciclo, invernaderoObj.id, semana, parseFloat(invernaderoObj.peso_caja_cosecha))
        let respInv = await obtenerInvernadero(ciclo, invernaderoObj.id);
        let resp = await obtenerPesoSemanaInteligente(ciclo, invernaderoObj.id, semana, proyecciones, peso)
        let area = obtenerAreaInvernaderoSync(respInv);
        return (resp / area);
    } catch (err) {
        throw err;
    }
}
export function obtenerResumenCajasSemanales(ciclo, invernadero, semana) {
    return new Promise((resolve, reject) => {
        let promises = [];
        let diasTimes = obtenerDiasDeSemana(semana);
        diasTimes.forEach(dia => {
            promises.push(obtenerCosecha(ciclo, invernadero, dia));
        })
        Promise.all(promises).then(values => {
            let total = 0;
            values.forEach(el => {
                if (el) {
                    let value = parseFloat(el.semanal);
                    if (!isNaN(value)) {
                        total += value;
                    }
                }
            })
            resolve(total);
        }).catch(err => {
            reject(err);
        });
    });
}
export function obtenerResumenCajasReales(ciclo, invernadero, semana) {
    return new Promise(async (resolve, reject) => {
        let invernaderoObj = await obtenerInvernadero(ciclo, invernadero);
        obtenerPesoCajaParaSemana(ciclo, invernadero, semana, parseFloat(invernaderoObj.peso_caja_cosecha)).then((peso) => {
            obtenerPesoSemana(ciclo, invernadero, semana, peso).then(resp => {
                resolve(resp / peso);
            })
        }).catch((err) => {
            reject(err);
        });

    });
}

export function obtenerResumenCajasDiarias(ciclo, invernadero, semana) {
    return new Promise((resolve, reject) => {
        let promises = [];
        let diasTimes = obtenerDiasDeSemana(semana);
        diasTimes.forEach(dia => {
            promises.push(obtenerCosecha(ciclo, invernadero, dia));
        })
        Promise.all(promises).then(values => {
            let total = 0;
            values.forEach(el => {
                if (el) {
                    let value = parseFloat(el.diaria);
                    if (!isNaN(value)) {
                        total += value;
                    }
                }
            })
            resolve(total);
        }).catch(err => {
            reject(err);
        });
    });

}

export async function obtenerReporteCosecha(ciclo, semana) {
    let invernaderos = getGlobal().invernaderosVirtuales?.filter(el => !el.es_externo) || []
    let invernaderosExternos = getGlobal().invernaderosVirtuales?.filter(el => el.es_externo) || []
    let promesasResumen = [];
    invernaderos.forEach(invernadero => {
        if (!invernadero.es_externo)
            promesasResumen.push(obtenerReporteCosechaInvernaderoSemana(ciclo, invernadero, semana))
    })
    invernaderosExternos.forEach(invernadero => {
        if (invernadero.es_externo)
            promesasResumen.push(obtenerReporteCosechaInvernaderoExternoSemana(ciclo, invernadero, semana))
    })
    let valuesInternos = await Promise.all(promesasResumen)
    return valuesInternos
}
export async function getReporteCosecha(ciclo, semana) {
    let invernaderos = getGlobal().invernaderosVirtuales?.filter(el => !el.es_externo) || []
    let invernaderosExternos = getGlobal().invernaderosVirtuales?.filter(el => el.es_externo) || []
    let promesasResumen = [];
    invernaderos.forEach(invernadero => {
        if (!invernadero.es_externo) promesasResumen.push(obtenerReporteCosechaInvernaderoSemana(ciclo, invernadero, semana))
    })
    invernaderosExternos.forEach(invernadero => {
        if (invernadero.es_externo) promesasResumen.push(obtenerReporteCosechaInvernaderoExternoSemana(ciclo, invernadero, semana))
    })
    let valuesInternos = await Promise.all(promesasResumen)
    return valuesInternos;
}

export async function obtenerReporteCosechaInvernaderoSemana(ciclo, invernadero, semana) {
    try {
        let inicio = Date.now();
        let fin = 0;
        let dif = 0;
        const db = firebase.firestore();

        const dias = obtenerDiasDeSemana(semana);
        let diarioRaw = 0;
        let semanalRaw = 0;
        let realRaw = 0;
        let area = 1;
        let pesoCaja = 1;
        const timestamps = flexDate(dias[0]);
        const timestamps2 = flexDate(dias[5]);
        pesoCaja = await obtenerPesoCajaParaSemana(ciclo, invernadero.id, semana, parseFloat(invernadero.peso_caja_cosecha));
        area = obtenerAreaInvernaderoSync(invernadero);
        let proyecciones = await db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero.id)
            .collection("cosecha").where("dia", ">=", timestamps.inicio).where("dia", "<=", timestamps2.fin).get()

        registrarInvocaciones(proyecciones.size)
        // 
        fin = Date.now();
        dif = fin - inicio;
        //
        let registros = [];
        proyecciones.forEach(el => {
            let data = el.data();
            data.id = el.id;
            registros.push(data);
        })
        let empaque = await obtenerPesoYCajasSemanaInteligenteReporteCosecha(ciclo, invernadero.id, semana, registros, pesoCaja);
        // 
        // fin = Date.now();
        // dif = fin -inicio;
        // 
        // 
        // 
        // 
        // 
        // 
        [diarioRaw, semanalRaw, realRaw] = obtenerDiarioSemanalReal(empaque, registros);
        return (procesarReporteSemanal(invernadero, semana, diarioRaw, semanalRaw, realRaw, pesoCaja, area));
    } catch (error) {
        throw error
    }
}
export async function obtenerReporteCosechaInvernaderoExternoSemana(ciclo, invernadero, semana) {
    try {
        // 
        let inicio = Date.now();
        let fin = 0;
        let dif = 0;
        const db = firebase.firestore();
        //obtener proyecciones
        const dias = obtenerDiasDeSemana(semana);
        let diarioRaw = 0;
        let semanalRaw = 0;
        let realRaw = 0;

        let pesoCaja = pesoCajaExternos;
        let proveedores = await obtenerProveedores(ciclo);
        proveedores = proveedores.filter(el => el.producto_ref === invernadero.producto_ref);
        let area = 0;
        proveedores.forEach(proveedor => {
            area += parseFloat(proveedor.area_invernadero);
        })
        let promises = [];
        proveedores.forEach(proveedor => {
            // regresa [proveedor, semana, kilos y kgm2 ]
            promises.push(obtenerReporteCompraExternaProveedorSemana(ciclo, proveedor, semana))

        })

        let values = await Promise.all(promises)
        let kilos = { diario: 0, semanal: 0, real: 0 };
        let cajas = { diario: 0, semanal: 0, real: 0 };
        let kgm2 = { diario: 0, semanal: 0, real: 0 };
        values.forEach(reporteProveedor => {
            kilos["diario"] += reporteProveedor[2]["diario"]
            kilos["semanal"] += reporteProveedor[2]["semanal"]
            kilos["real"] += reporteProveedor[2]["real"]
            cajas["diario"] += reporteProveedor[2]["diario"] / pesoCaja
            cajas["semanal"] += reporteProveedor[2]["semanal"] / pesoCaja
            cajas["real"] += reporteProveedor[2]["real"] / pesoCaja
        })
        kgm2["diario"] = area > 0 ? kilos["diario"] / area : 0;
        kgm2["semanal"] = area > 0 ? kilos["semanal"] / area : 0;
        kgm2["real"] = area > 0 ? kilos["real"] / area : 0;
        // 
        // 
        let resultado = [invernadero, semana, cajas, kilos, kgm2]
        // 
        // let proyecciones = await db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero.id)
        //     .collection("cosecha").where("dia",">=",dias[0]).where("dia","<=",dias[5]).get()
        //     // 
        //     fin = Date.now();
        //     dif = fin -inicio;
        //     //
        // let registros = [];
        //     proyecciones.forEach(el=>{
        //     let data = el.data();
        //     data.id = el.id;
        //     registros.push(data);
        // })                        
        //let empaque = await obtenerPesoYCajasSemanaInteligenteReporteCosecha(ciclo,invernadero.id,semana, registros, pesoCaja);
        // 
        // fin = Date.now();
        // dif = fin -inicio;
        // 
        // 
        // 
        // 
        // 
        // 

        return resultado
        //invernadero, semana, cajas, kilos, kgm2
    } catch (error) {
        throw error
    }
}

export const obtenerDiarioSemanalReal = (empaque, proyecciones) => {

    let hoy = obtenerDiaActual();
    let diarioRaw = 0;
    let semanalRaw = 0;

    let realRaw = empaque;
    proyecciones.forEach(proyeccion => {
        let data = proyeccion
        let diaria = parseFloat(data.diaria);
        let semanal = parseFloat(data.semanal);

        // si dia despues de hoy, buscar en semanal
        if (data.dia > hoy && !isNaN(semanal)) {
            if (!isNaN(diaria)) {
                diarioRaw += diaria;
            } else {
                diarioRaw += semanal;
            }

        }
        else if (!isNaN(diaria)) {
            diarioRaw += diaria;
        }
        if (!isNaN(semanal)) {
            semanalRaw += semanal;
        }
    })

    return [diarioRaw, semanalRaw, realRaw]
}


function procesarReporteSemanal(invernadero, semana, diarioRaw, semanalRaw, realRaw, pesoCaja, area) {
    let cajas = {
        diario: diarioRaw,
        semanal: semanalRaw,
        real: Math.round(100 * realRaw["cajas"]) / 100
    }
    let kilos = {
        diario: diarioRaw * pesoCaja,
        semanal: semanalRaw * pesoCaja,
        real: realRaw["kilos"]
    }
    let kilos_m2 = {
        diario: Math.round(100 * kilos["diario"] / area) / 100,
        semanal: Math.round(100 * kilos["semanal"] / area) / 100,
        real: realRaw["kilos"] / area
    }
    //    
    //    
    return ([invernadero, semana, cajas, kilos, kilos_m2])
}

export const obtenerProyeccionesSemana = async (ciclo, invernadero, semana) => {
    const db = firebase.firestore();
    const response = await db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero.id)
        .collection("cosecha").where("dia", ">=", semana).where("dia", "<=", semana + 24 * 7 * 60 * 60).get()
    registrarInvocaciones(response.size)
    return response
}

export const obtenerReportesCosechaCiclo = async (ciclo, invernadero) => {
    let listaSemanas = obtenerListaDeSemanas(ciclo.semana_inicio, ciclo.semana_cierre);
    let promises = listaSemanas.map(semana => obtenerReporteCosecha(ciclo.id, semana.time));
    let reportes = await Promise.all(promises);
    return reportes
}

export const obtenerResumenCardCosecha = async (ciclo, invernadero, semana) => {
    try {
        let [, , cajas, kilos, kg_m2, resumenEmpaque] = await obtenerReporteCosechaInvernaderoSemana(ciclo, invernadero, semana);
        let copia = { ...invernadero };
        copia.semanal = kg_m2.semanal;
        copia.semanalReal = kg_m2.real;
        copia.realvssemanal = cajas.real / cajas.semanal;
        copia.diariovssemanal = cajas.diario / cajas.semanal;
        copia.realvsdiario = cajas.real / cajas.diario;
        return copia;
    } catch (error) {
        throw error;
    }
}
const obtenerRegistrosCosechaSemanaFormatoOptimizado = async (cicloId, invernaderoId, semana) => {
    const db = firebase.firestore();
    let snap = await db.collection("ciclos").doc(cicloId).collection("invernaderos_virtuales").doc(invernaderoId)
        .collection("cosecha").where("dia", ">=", semana).where("dia", "<", semana + segundosSemana).get()
    let registros = snap.docs.map(el => ({ semana: semana, id: el.id, ...el.data() }))
    registrarInvocaciones(snap.size)
    return { semana, registros }
}

export const obtenerDistribucionSemanaVariedadesMezclas = async (cicloId, invernadero) => {
    const { productosYVariedades } = getGlobal();
    let producto = getObjetoPorID(productosYVariedades, invernadero.producto_ref);
    let variedadesMezcla = producto.variedades;

    let cicloObj = await obtenerCiclo(cicloId);
    let semanas = await obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
    let promisesRegistrosSemanales = semanas.map(semana =>
        obtenerRegistrosCosechaSemanaFormatoOptimizado(cicloId, invernadero.id, semana.time))

    let registrosSemanales = await Promise.all(promisesRegistrosSemanales);
    let reales = await obtenerRealesVariedadesMezclaCiclo(cicloId, invernadero.id, variedadesMezcla, semanas, registrosSemanales);

    let result = variedadesMezcla.map(variedad => {
        let realesVariedadesMezcla = reales.find(el => el.variedad.id === variedad.id);
        let data = semanas.map((semana, index) => {
            let registrosSemana = registrosSemanales.find(el => el.semana === semana.time);
            let sumaReales = realesVariedadesMezcla?.sumas?.[index] || 0;
            let sumaDiario = 0;
            let sumaSemanal = 0;
            registrosSemana.registros.forEach(registro => {



                let detalleVariedad = registro.detalle?.[variedad?.id]
                let diario = parseFloat(detalleVariedad?.diario || "0")
                let semanal = parseFloat(detalleVariedad?.semanal || "0")
                sumaDiario += isNaN(diario) ? 0 : diario;
                sumaSemanal += isNaN(semanal) ? 0 : semanal;
            })
            return { semana: semana.time, diario: sumaDiario, semanal: sumaSemanal, real: sumaReales }
        });
        return { variedad, data }
    })
    return result;
}
const obtenerRealesVariedadesMezclaCiclo = async (cicloId, invernadero, variedadesMezcla = [], listaSemanas, datosCosecha) => {
    let promises = variedadesMezcla?.map(variedad => obtenerRealesVariedadMezclaCiclo(cicloId, invernadero, variedad, listaSemanas, datosCosecha))
    let result = await Promise.all(promises);
    return result;
}
const obtenerRealesVariedadMezclaCiclo = async (cicloId, invernadero, variedad, listaSemanas, datosCosecha) => {
    let promises = listaSemanas.map(semana => {
        //encontrar registros de cosecha de la semana
        let registros = datosCosecha.find(el => el.semana === semana.time).registros;
        let detalles = procesarDetalles(registros, [variedad.id], semana.time)

        return obtenerRealesVariedadMezcla(cicloId, invernadero, semana.time, variedad, detalles)
    });
    let result = await Promise.all(promises);
    //result es un array de arrys
    // convertir cada  elemento de result a suma de su contenido 
    let sumas = result.map(el => {
        let suma = 0;
        el.forEach(el => {
            suma += el;
        })
        return suma;
    })
    return { variedad, sumas };
}//752