import React, { useEffect, useState } from 'react';
import { useGlobal, setGlobal } from 'reactn';
import * as firebase from "firebase/app";
import "firebase/auth";

import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';

import ContenedorInput from '../../common/ContenedorInput'
import PreviewModal from '../../common/PreviewModal'
import ArchivoPreview from '../../common/ArchivoPreview'
import ContenedorHeader from '../../common/ContenedorHeader';

import { notificarOrdenMantenimientoCreada } from '../../../service/notificaciones';
import { noHaSidoClausurado } from '../../../service/Ciclos';
import { obtenerFiltrosMantenimientoTodos, isAdminRequisiciones, locacionIsAtongo, empresaIsHTF, guardarRequisicionAciclico, obtenerOrdenMantenimientoAciclico, obtenerOrdenMantenimientoBridge } from '../../../service/Requisiciones';

import CambiarEstatusModal from './CambiarEstatusModal'
import {
    dateToUnixTimeAsNumber13, getCalendarDate, DeepClone,
    getNombrePorID, getObjetoPorID, getArchivoTipo, getNombreUsuarioPorID, locacionIsAgropark
} from '../../../util/functions'

import moment from "moment";
import 'moment/locale/es';
import ContenedorPrincipal from '../../planeacion_mantenimiento/CapturaAgentes/ContenedorPrincipal';
import RowBotonesTop from '../../common/RowBotonesTop';
import ModalPlaneacionAgente from './ModalPlaneacionAgente';
import ContenedorBotonesRow from '../../common/ContenedorBotonesRow';
import { getActividadDeRequisicion, getActividadesMantenimiento, getAgentes, getFirestoreID } from '../../../service/Mantenimiento';
import { log } from '../../../model/Logging';

moment.locale("es");

const archivoInicial = { URL: "", tipo: "" }
const itemInicial = { solicitud: "", cantidad: "", detalle: "", empresa: "", locacionHTF: "", locacion: "", archivo: { ...archivoInicial }, precio: "", fechaEntrega: "" }
const inicial = {
    orden_num: "",
    fecha_creacion: "",
    fechaPreferidaEntrega: "",
    urgencia: "",
    solicitante: "",
    departamento: "",
    orden_ref: "",
    requerimiento: "",

    items: [{ ...itemInicial }],
    archivosAdmin: [],
    comentarioAdmin: "",

    fechaEntregaMaterial: "",
    fechaEntregaParcial: "",
    precioFinal: "",
    moneda: "",
    comentarioCerrado: "",
    tiempoRealizacion: "",

    estatus: "",
    fechaCerrado: ""
}
setGlobal({ estatusesMant: [], locacionesMant: [], locacionesAtongoMant: [], empresas: [], datosCargadosMant: false })
const FormularioMantenimiento = (props) => {
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [ciclo] = useGlobal("ciclo");
    const [cicloObj] = useGlobal("ciclo");
    const [ciclos] = useGlobal("ciclos");
    const [, setCargando] = useGlobal("cargando")
    const [editando] = useState(props.match.params.requisicionID ? true : false)
    const [requisicionID] = useState(props.match.params.requisicionID || getFirestoreID())
    const [usuario] = useGlobal("usuario");
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [previewDialogURL, setPreviewDialogURL] = useState("")
    const [cambiarEstatusModalOpen, setCambiarEstatusModalOpen] = useState(false)
    const [datosCargados,] = useGlobal("datosCargadosMant")
    const [showModalPlaneacionAgente, setShowModalPlaneacionAgente] = useState(false)
    const [actividadPlaneada, setActividadPlaneada] = useState(false)

    //opciones de filtros
    const [ordenesRefs] = useGlobal("ordenesRefsMant")
    const [urgencias] = useGlobal("urgenciasMant")
    const [requerimientos] = useGlobal("requerimientosMant")
    const [estatuses] = useGlobal("estatusesMant")
    const [locaciones] = useGlobal("locacionesMant")
    const [locacionesAtongo] = useGlobal("locacionesAtongoMant")
    const [locacionesHTF] = useGlobal("locacionesHTF")
    const [empresas] = useGlobal("empresas")
    const [departamentos] = useGlobal("departamentos")
    const [usuarios] = useGlobal("usuarios")
    const [invernaderosFisicos] = useGlobal("invernaderosFisicos")
    const [sitios] = useGlobal("sitios")

    const [nombreSolicitante, setNombreSolicitante] = useState("")
    const [departamentoSolicitante, setDepartamentoSolicitante] = useState("")
    const [, setSaving] = useState(false)
    const [errores, setErrores] = useState([])
    const [state, setState] = useState(DeepClone(inicial))
    const [, setModal] = useGlobal("modal");

    useEffect(() => {
        if (usuario.rol === "agente aduanal")
            props.history.push("/")
        setNombreModulo("Requisiciones de mantenimiento")
        setHabilitadoSelectorCiclo(true)
    }, [])
    useEffect(() => {
        if (!noHaSidoClausurado(ciclo, ciclos) && !editando)
            props.history.replace("/requisiciones/mantenimiento")
    }, [ciclo, ciclos])
    useEffect(() => {
        setCargando(true)

        if (invernaderosFisicos && !datosCargados)
            obtenerFiltrosMantenimientoTodos(usuario)
        else if (invernaderosFisicos && datosCargados)
            obtenerDatosInicio()
    }, [invernaderosFisicos, datosCargados])
    useEffect(() => {
        setSaving(false)
        setErrores([])
    }, [state])

    const puedeEditar = () => editando ? isAdminRequisiciones(usuario.rol, "Mantenimiento") && noHaSidoClausurado(ciclo, ciclos) : !["Agente"].includes(usuario?.rol);
    const puedeEditarEstatus = () => editando ? isAdminRequisiciones(usuario.rol, "Mantenimiento") && noHaSidoClausurado(ciclo, ciclos) : false

    const obtenerDatosInicio = async () => {
        if (editando) {
            try {
                var orden = props.location.data || await obtenerOrdenMantenimientoBridge(cicloObj, requisicionID)
                delete orden.id

                const nombreSol = usuarios ? getNombreUsuarioPorID(usuarios, orden.solicitante) : usuario.displayName
                setNombreSolicitante(nombreSol)
                setDepartamentoSolicitante(getNombrePorID(departamentos, orden.departamento))
                setState({ ...state, ...orden })
            } catch (error) {
                console.log("ERROR obtener mantenimento", error)
                props.history.push('/requisiciones/mantenimiento')
            }
        }
        else {
            setState({ ...state, estatus: estatuses[0].id })//Set to initial state "Pendiente de autorizar"
        }
        setCargando(false)
    }

    const guardarOrden = async () => {
        setSaving(true)

        if (validar()) {
            try {
                setCargando(true)

                let itemsCopy = await subirArchivosItems()
                let archivosAdminCopy = await subirArchivosAdmin()

                let ordenes_mantenimento = await firebase.firestore().collection('requisiciones').doc("mantenimiento").collection("requisiciones_mantenimiento").orderBy("orden_num", "desc").limit(1).get()
                const ordenMasReciente = ordenes_mantenimento?.docs?.[0]?.data();
                if (isNaN(ordenMasReciente?.orden_num) || ordenMasReciente?.orden_num == null) { throw new Error }
                log({ data: { mensaje: "1. logging ordenes_mantenimiento" || "", ordenMasReciente, funcion: "guardarOrden", ciclo, usuario } })

                let data = { ...state, archivosAdmin: archivosAdminCopy, items: itemsCopy }
                if (!editando) {
                    data.orden_num = ordenMasReciente.orden_num + 1
                    log({ data: { mensaje: "2. logging ordenes_mantenimiento" || "", ordenMasReciente, funcion: "guardarOrden", ciclo, usuario } })
                    data.fecha_creacion = Date.now()
                    data.solicitante = usuario.uid
                    data.departamento = usuario.departamento
                }

                //Guardar datos de requisición
                await guardarRequisicionAciclico("mantenimiento", requisicionID, data)
                if (!editando)
                    await notificarOrdenMantenimientoCreada({ orden_num: data.orden_num, usuario: usuario.displayName, departamento: getNombrePorID(departamentos, data.departamento), urgencia: getNombrePorID(urgencias, data.urgencia) });

                setCargando(false)
                props.history.goBack()
            }
            catch (error) {
                console.log("ERROR guardar: ", error);
                log({ data: { mensaje: "3. logging ordenes_mantenimiento" || "", funcion: "guardarOrden", ciclo, usuario } })
                setModal({ titulo: "Error de registro de datos", msj: "Hubo un problema al guardar su requisición, intentelo nuevamente." });
                setCargando(false);
            }
        }
    }

    const validar = () => getErroresFormulario().length === 0
    const getErroresFormulario = () => {
        let camposRequeridosLista = ["urgencia", "fechaPreferidaEntrega", "orden_ref", "requerimiento"]
        let camposRequeridosItemsLista = ["solicitud", "cantidad", "detalle", "empresa", "locacion"]
        let errores = []

        camposRequeridosLista.forEach(campo => {
            if (!state[campo])
                errores.push(campo)
        })
        state.items.forEach((item, index) => {
            camposRequeridosItemsLista.forEach(campo => {
                if (!item[campo])
                    errores.push(`${campo}${index}`)
            })

            if (empresaIsHTF(empresas, item.empresa) && !item.locacionHTF)
                errores.push(`locacionHTF${index}`);
            if (empresaIsHTF(empresas, item.empresa) && item.locacionHTF && !item.sitio)
                errores.push(`sitio${index}`)
        })

        setErrores(errores)
        return errores
    }

    const subirArchivosItems = async () => {
        let itemsCopy = DeepClone(state.items)

        for (let i = 0; i < itemsCopy.length; i++) {
            let archivoCopy = itemsCopy[i].archivo

            if (archivoCopy.archivo) {
                let archivoNombre = `item${i + 1}_${Date.now()}_${archivoCopy.archivo.name}`
                let storagePath = `ordenes_mantenimento/${requisicionID}/${archivoNombre}`
                const ref = firebase.storage().ref().child(storagePath)
                let fileSnapshot = await ref.put(archivoCopy.archivo)
                let url = await fileSnapshot.ref.getDownloadURL()

                itemsCopy[i].archivo = { URL: url, tipo: archivoCopy.tipo }
            }
        }
        return itemsCopy
    }

    const subirArchivosAdmin = async () => {
        let archivosAdminCopy = DeepClone(state.archivosAdmin)

        try {

            for (let x = 0; x < archivosAdminCopy.length; x++) {
                let url = archivosAdminCopy[x].URL || ""
                if (archivosAdminCopy[x].archivo) {
                    let storagePath = `ordenes_mantenimento/${Date.now()}_${archivosAdminCopy[x].archivo.name}`
                    const ref = firebase.storage().ref().child(storagePath)
                    let fileSnapshot = await ref.put(archivosAdminCopy[x].archivo)
                    url = await fileSnapshot.ref.getDownloadURL()
                }
                archivosAdminCopy[x] = { URL: url, tipo: archivosAdminCopy[x].tipo }
            }
        } catch (error) {
            console.log("Error subiendo archivo admin: " + error)
        }
        return archivosAdminCopy
    }

    const getItemLocacionOpciones = (empresa, locacionHTF, sitio) => {
        let opciones = locaciones.map(el => ({ ...el }))

        if (invernaderosFisicos && empresaIsHTF(empresas, empresa)) {
            if (locacionIsAtongo(locacionesHTF, locacionHTF)) {
                const locacionesAtongoFiltrados = locacionesAtongo.filter(loc => loc.sitio === sitio || (sitio === "Todos" && loc.todos))
                opciones.push(...locacionesAtongoFiltrados.map(el => ({ ...el })))
            }

            opciones.push(...invernaderosFisicos.filter(inv => inv.empaque_ref === sitio && inv.empresa_ref === empresa))
        }
        else if (invernaderosFisicos)
            opciones.push(...invernaderosFisicos.filter(inv => inv.empresa_ref === empresa))

        return opciones
    }
    const handleInputChange = (event, index = -1) => {
        let stateCopy = DeepClone(state)
        let name = event.target.name

        if (index >= 0) {
            let locacionesIDs = locaciones.map(el => el.id)
            stateCopy.items[index][name] = event.target.value

            if (name === "empresa" && !locacionesIDs.includes(stateCopy.items[index].locacion)) {
                stateCopy.items[index].locacion = "";
                stateCopy.items[index].sitio = "";
            }
            if (name === "empresa" && !empresaIsHTF(empresas, event.target.value)) {
                stateCopy.items[index].locacionHTF = "";
                stateCopy.items[index].sitio = getSitioAgropark().id
            }
            if (name === "locacionHTF") {
                stateCopy.items[index].sitio = locacionIsAgropark(locacionesHTF, event.target.value) ? getSitioAgropark().id : ""
            }
        }
        else
            stateCopy[name] = event.target.value

        setState(stateCopy)
    }

    const handleCalendarInputChange = (event, index) => {
        handleInputChange({ target: { name: event.target.name, value: dateToUnixTimeAsNumber13(event.target.value) } }, index)
    }

    const ordenCerrado = () => {
        const result = getObjetoPorID(estatuses, state.estatus)
        return ["No autorizado", "Cerrado", "Cancelado"].includes(result.nombre)
    }
    const deleteArchivoAdmin = (index, e) => {
        //Para bloquear que el parent hace click (vista previa)
        e.stopPropagation();

        let archivosAdminCopy = DeepClone(state.archivosAdmin)
        archivosAdminCopy.splice(index, 1)
        handleInputChange({ target: { name: "archivosAdmin", value: archivosAdminCopy } })
    }
    const deleteArchivoItem = (e, index) => {
        //Para bloquear que el parent hace click (vista previa)
        e.stopPropagation();
        handleInputChange({ target: { name: "archivo", value: { ...archivoInicial } } }, index)
    }

    const handleArchivosAdminChange = (files) => {
        let archivosAdminCopy = state.archivosAdmin.length === 0 ? [] : DeepClone(state.archivosAdmin)

        for (let x = 0; x < files.length; x++) {
            let newArchivo = {}
            let url = URL.createObjectURL(files[x])
            newArchivo.URL = url
            newArchivo.archivo = files[x]
            newArchivo.tipo = getArchivoTipo(files[x])
            archivosAdminCopy.push(newArchivo)
        }
        handleInputChange({ target: { name: "archivosAdmin", value: archivosAdminCopy } })
    }

    const handleItemArchivoChange = (event, index) => {
        if (event.target.files[0]) {
            let localURL = URL.createObjectURL(event.target.files[0])
            let tipoArchivo = getArchivoTipo(event.target.files[0])
            handleInputChange({ target: { name: event.target.name, value: { URL: localURL, archivo: event.target.files[0], tipo: tipoArchivo } } }, index)
        }
    }

    const verArchivoFuncion = (archivo) => { archivo.tipo === "pdf" || archivo.tipo === "excel" ? window.open(archivo.URL) : setPreviewDialogURL(archivo.URL) }
    const getSitioAgropark = () => sitios.find(sitio => sitio.nombre === "Agropark");
    const getSitiosAtongo = () => sitios.filter(sitio => sitio.nombre !== "Agropark");
    const disableItemSitio = (item) => !puedeEditar() || locacionIsAgropark(locacionesHTF, item.locacionHTF);
    const getSitioOpciones = (item) => !item.locacionHTF ? [] : locacionIsAgropark(locacionesHTF, item.locacionHTF) ? [getSitioAgropark()] : getSitiosAtongo();

    return (
        <ContenedorPrincipal extra="p-justify-center">
            <ContenedorHeader titulo={"Requerimientos de orden " + (editando ? `#${state.orden_num}` : "")} iconos="mtto-icon"
                subtitulo={editando && "Estatus: " + getNombrePorID(estatuses, state.estatus)} atras={props.history.goBack} col="p-col p-col-auto" />

            {editando && isAdminRequisiciones(usuario.rol, "Mantenimiento") &&
                <ContenedorBotonesRow>
                    <Button label={actividadPlaneada ? "Modificar asignación al agente" : "Asignar al agente"} disabled={ordenCerrado()} onClick={() => { setShowModalPlaneacionAgente(true) }} />
                </ContenedorBotonesRow>}

            <div className="p-col-12">
                <p className="section-title">Información general</p>
                <div className="card">
                    <div className="p-grid ">
                        {editando && <>
                            <div className="form-group p-col-12 p-md-4">
                                <label htmlFor="numero-orden">Orden #</label>
                                <InputText disabled id="numero-orden" value={state.orden_num} />
                            </div>
                            <div className="form-group p-col-12 p-md-4">
                                <label htmlFor="fecha-creacion">Fecha de creación</label>
                                <InputText disabled id="fecha-creacion" value={moment(state.fecha_creacion).utcOffset("-0600").format("DD/MM/YYYY")} />
                            </div>
                        </>}
                        <ContenedorInput label="Urgencia" col={editando ? 12 : false} md={editando ? 4 : false}>
                            <Dropdown disabled={!puedeEditar()} id="urgencia" name="urgencia" optionLabel="nombre" optionValue="id" options={urgencias} value={state.urgencia} placeholder="Seleccionar" onChange={handleInputChange} />
                            {errores.includes("urgencia") && <Message severity="error" text="Este campo es requerido" />}
                        </ContenedorInput>
                        {state.urgencia && <ContenedorInput label="Fecha preferida de entrega">
                            <Calendar disabled={!puedeEditar()} name="fechaPreferidaEntrega" value={getCalendarDate(state.fechaPreferidaEntrega)} onChange={handleCalendarInputChange} dateFormat="dd/mm/yy" />
                            {errores.includes("fechaPreferidaEntrega") && <Message severity="error" text="Este campo es requerido" />}
                        </ContenedorInput>}
                    </div>
                </div>
            </div>

            <div className="p-col-12">
                <p className="section-title">Detalle de orden</p>
                <div className="card">
                    <div className="p-grid ">
                        {editando && <>
                            <div className="form-group p-col-12 p-md-6">
                                <label htmlFor="solicitante">Solicitante (Nombre)</label>
                                <InputText disabled id="solicitante" value={nombreSolicitante} />
                            </div>
                            <div className="form-group p-col-12 p-md-6">
                                <label htmlFor="departamento">Departamento</label>
                                <InputText disabled id="departamento" value={departamentoSolicitante} />
                            </div>
                        </>}
                        <div className="form-group p-col-12 p-md-6">
                            <label htmlFor="orden-ref">Orden Referencia</label>
                            <Dropdown disabled={!puedeEditar()} optionLabel="nombre" optionValue="id" id="orden_ref" name="orden_ref" options={ordenesRefs} value={state.orden_ref} onChange={handleInputChange} placeholder="Seleccionar"
                                filter={true} filterPlaceholder="Buscar orden de referencia" />
                            {errores.includes("orden_ref") && <Message severity="error" text="Este campo es requerido" />}
                        </div>
                        <div className="form-group p-col-12 p-md-6">
                            <label htmlFor="requerimiento">Requerimiento</label>
                            <Dropdown disabled={!puedeEditar()} id="requerimiento" optionLabel="nombre" optionValue="id" name="requerimiento" options={requerimientos} value={state.requerimiento} onChange={handleInputChange} placeholder="Seleccionar"
                                filter={true} filterPlaceholder="Buscar requerimiento" />
                            {errores.includes("requerimiento") && <Message severity="error" text="Este campo es requerido" />}
                        </div>
                    </div>
                </div>
            </div>

            <div className="p-col-12">
                <p className="section-title">Item de solicitud</p>
                {state.items.map((item, index) => {
                    return <div className="card item-compras">
                        <div className="p-grid ">
                            <div className="p-col-12 p-md-7">
                                <div className="p-grid ">
                                    <div className="form-group p-col-12 p-md-12">
                                        <label htmlFor="solicitud">Solicitud</label>
                                        <InputText disabled={!puedeEditar()} id="solicitud" name={"solicitud"} value={item.solicitud} onChange={(e) => handleInputChange(e, index)} />
                                        {errores.includes(`solicitud${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                    </div>
                                    <div className="form-group p-col-6 p-md-3">
                                        <label htmlFor="cantidad">Cantidad</label>
                                        <InputText disabled={!puedeEditar()} keyfilter={"pnum"} id="cantidad" name={"cantidad"} value={item.cantidad} onChange={(e) => handleInputChange(e, index)} />
                                        {errores.includes(`cantidad${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                    </div>
                                    <div className="form-group p-col-12 p-md-4">
                                        <label htmlFor="empresa">Empresa</label>
                                        <Dropdown disabled={!puedeEditar()} optionLabel="nombre" optionValue="id" id="empresa" name={"empresa"} options={empresas} value={item.empresa} onChange={(e) => handleInputChange(e, index)} placeholder="Seleccionar" />
                                        {errores.includes(`empresa${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                    </div>

                                    {empresaIsHTF(empresas, item.empresa) && <div className="form-group p-col-12 p-md-4">
                                        <label htmlFor="locacionHTF">Locación </label>
                                        <Dropdown disabled={!puedeEditar()} optionLabel="nombre" optionValue="id" id="locacionHTF" name={"locacionHTF"} options={locacionesHTF} value={item.locacionHTF} onChange={(e) => handleInputChange(e, index)} placeholder="Seleccionar" />
                                        {errores.includes(`locacionHTF${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                    </div>}

                                    {empresaIsHTF(empresas, item.empresa) &&
                                        <div className="form-group p-col-12 p-md-4">
                                            <label htmlFor="sitio">Sitio </label>
                                            <Dropdown disabled={disableItemSitio(item)} optionLabel="nombre" optionValue="id" id="sitio" name={"sitio"} options={getSitioOpciones(item)} value={item.sitio || ""} onChange={(e) => handleInputChange(e, index)} placeholder="Seleccionar" />
                                            {errores.includes(`sitio${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                        </div>}


                                    <div className="form-group p-col-12 p-md-4">
                                        <label htmlFor="locacion">Ubicación</label>
                                        <Dropdown disabled={!puedeEditar()} optionLabel="nombre" optionValue="id" id="locacion" name={"locacion"} options={getItemLocacionOpciones(item.empresa, item.locacionHTF, item.sitio)} value={item.locacion} placeholder="Seleccionar" onChange={(e) => handleInputChange(e, index)} placeholder="Seleccionar" />
                                        {errores.includes(`locacion${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                    </div>

                                    <div className="form-group p-col-12 p-md-4">
                                        <label htmlFor="archivo">Subir archivo (imagen/pdf/excel)</label>
                                        {item.archivo && item.archivo.URL && <ArchivoPreview archivo={item.archivo} verArchivo={() => verArchivoFuncion(item.archivo)} puedeEditar={puedeEditar()} deleteArchivo={(e) => deleteArchivoItem(e, index)} />}
                                        <input disabled={!puedeEditar()} name={"archivo"} id="archivo" onChange={(e) => handleItemArchivoChange(e, index)} type="file" className="form-control-file" accept="image/jpeg,image/jpg,image/png, application/pdf, .xls,.xlsx" />
                                    </div>

                                    {ordenCerrado() && <>
                                        {puedeEditar() && <div className="form-group p-col-12 p-md-6">
                                            <label htmlFor="precio">Precio final</label>
                                            <InputText id="precio" name={"precio"} value={item.precio} onChange={(e) => handleInputChange(e, index)} />
                                        </div>}
                                        <div className="form-group p-col-12 p-md-6">
                                            <label htmlFor="itemFecha">Fecha de entrega</label>
                                            <Calendar disabled={!puedeEditar()} name={"fechaEntrega"} value={getCalendarDate(item.fechaEntrega)} onChange={(e) => handleCalendarInputChange(e, index)} dateFormat="dd/mm/yy" />
                                        </div>
                                    </>}
                                </div>
                            </div>

                            <div className="p-col-12 p-md-5">
                                <div className="p-grid ">
                                    <div className="form-group p-col-12 p-md-12">
                                        <label htmlFor="detalle">Detalle de Solicitud</label>
                                        <InputTextarea disabled={!puedeEditar()} rows={3} cols={30} autoResize={true} id="detalle" name={"detalle"} value={item.detalle} onChange={(e) => handleInputChange(e, index)} placeholder={"Favor de especificar toda la información adicional que permita identificar con más precisión el artículo (ejemplo: dimensiones, colores, marca, tipo de material, preferencia por proveedor, etc); así como de justificar la solicitud al describir el uso que se le dará al material solicitado."} />
                                        {errores.includes(`detalle${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                })}
            </div>

            {editando && <div className="p-col-12">
                <p className="section-title">Administración de la orden</p>
                <div className="card">
                    <div className="p-grid ">
                        <div className="form-group p-col-12 p-md-6">
                            <label htmlFor="estatus-nombre">Estatus </label>
                            <InputText disabled id="estatus-nombre" value={getNombrePorID(estatuses, state.estatus)} />
                        </div>
                        <div className="form-group p-col-12">
                            <label htmlFor="imagenItem">Subir archivo (imagen/pdf/excel)</label>
                            {state.archivosAdmin.map((archivo, index) => {
                                return <ArchivoPreview archivo={archivo} verArchivo={() => verArchivoFuncion(archivo)} puedeEditar={puedeEditar()} deleteArchivo={(e) => deleteArchivoAdmin(index, e)} />
                            })}
                            {puedeEditar() && <input multiple={true} name="archivosAdmin" onChange={(event) => handleArchivosAdminChange(event.target.files)} type="file" className="form-control-file" accept="image/jpeg,image/jpg,image/png,application/pdf, .xls,.xlsx" />}
                        </div>
                        {(puedeEditar() || state.comentarioAdmin) && <div className="form-group p-col-12 p-md-12">
                            <label htmlFor="detalle">Comentario administrador de mantenimento</label>
                            <InputTextarea disabled={!puedeEditar()} rows={3} cols={30} autoResize={true} id="comentarioAdmin" name="comentarioAdmin" value={state.comentarioAdmin} onChange={handleInputChange} placeholder={"Comentario administrador de mantenimento"} />
                        </div>}

                        {ordenCerrado() && puedeEditar() &&
                            <div className="form-group p-col-12 p-md-6">
                                <label htmlFor="tiempoRealizacion">Tiempo de realización</label>
                                <InputText name="tiempoRealizacion" id="tiempoRealizacion" value={state.tiempoRealizacion} onChange={handleInputChange} />
                            </div>}
                        {ordenCerrado() && state.comentarioCerrado && <div className="form-group p-col-12 p-md-6">
                            <label htmlFor="comentario-cerrado-admin">Comentario enviado </label>
                            <InputTextarea disabled rows={3} cols={30} id="comentario-cerrado-admin" value={state.comentarioCerrado} />
                        </div>}
                        {puedeEditar() && state.fechaCerrado &&
                            <div className="form-group p-col-12 p-md-6">
                                <label htmlFor="status-nuevo">Fecha de cierre </label>
                                <InputText disabled id="status-nuevo" value={moment(state.fechaCerrado).tz("America/Mexico_City").format("DD/MM/YYYY HH:mm")} />
                            </div>}

                    </div>
                </div>
            </div>
            }

            <PreviewModal imageURL={previewDialogURL} closePreviewModal={() => setPreviewDialogURL("")} />
            <CambiarEstatusModal visible={cambiarEstatusModalOpen} setFormularioState={(e) => setState({ ...state, ...e })} estatus={state.estatus} estatuses={estatuses} state={state} handleInputChange={handleInputChange} closeModal={() => setCambiarEstatusModalOpen(false)} requisicionID={requisicionID} />
            {editando && isAdminRequisiciones(usuario.rol, "Mantenimiento") && <ModalPlaneacionAgente visible={showModalPlaneacionAgente} closeModal={() => setShowModalPlaneacionAgente(false)} datosRequisicion={state} requisicionID={requisicionID} setActividadPlaneada={setActividadPlaneada} setFormularioState={items => { setState({ ...state, items }) }} />}

            <div className="p-col-12">
                <div className="p-grid p-justify-end btn-row">
                    <Button label="Cancelar" onClick={() => { props.history.goBack() }} />
                    {puedeEditarEstatus() && <Button disabled={ordenCerrado()} label="Cambiar estatus" onClick={() => setCambiarEstatusModalOpen(true)} />}
                    <Button disabled={!puedeEditar()} label="Guardar" onClick={guardarOrden} />
                </div>
            </div>

        </ContenedorPrincipal>
    )
}
export default FormularioMantenimiento; //495
