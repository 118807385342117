import React, { useEffect } from 'react';
import { useState } from 'react';
import { useGlobal } from 'reactn';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from "primereact/dialog";
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar'
import { dateToUnixTimeAsNumber13, DeepClone, getCalendarDate } from '../../../util/functions';
import 'moment/locale/es';
import WeekSelector, { useWeekSelector } from '../../common/WeekSelectorNEW';
import { getActividadDeRequisicion, getAgentes, getDatosMantenimiento, getFirestoreID, guardarActividadRequisicion } from '../../../service/Mantenimiento';
import { getDiasDeSemana, getIndiceDiaSemana, getInicioDia, getMinutesByTimestamp, getTimestampFromMinutes } from '../../../service/fechas';

const initialState = { agente: "", dia: "", tiempoEstimado: "" }
const ModalPlaneacionAgente = (props) => {
    const [usuario] = useGlobal("usuario")
    const [, setCargando] = useGlobal("cargando")
    const [ciclo] = useGlobal("ciclo")
    const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector(true);
    const [state, setState] = useState({ ...initialState })
    const [datosCargados] = useGlobal("datosCargadosMantenimiento");
    const [agentes, setAgentes] = useState([]);
    const [actividadDeRequisicion, setActividadDeRequisicion] = useState(false)

    /*const [datosRequisicion, setDatosRequisicion] = useState(props.datosRequisicion)
    useEffect(() => {
          setDatosRequisicion(props.datosRequisicion)
    }, [props.datosRequisicion])*/

    useEffect(() => {
        if (ciclo && !datosCargados) getDatosMantenimiento()
        else if (ciclo && datosCargados) getDatosIniciales()
    }, [ciclo, datosCargados])
    useEffect(() => {
        if (props.visible && actividadDeRequisicion) setState({ agente: actividadDeRequisicion.agente_ref, dia: actividadDeRequisicion.dia, tiempoEstimado: getTimestampFromMinutes(actividadDeRequisicion.tiempo_estimado || 0) })
    }, [props.visible])

    const getDatosIniciales = async () => {
        updateCicloWeekSelector(ciclo);
        const agentesData = await getAgentes()
        setAgentes(agentesData)
        const actividadDeRequisicion = await getActividadDeRequisicion(ciclo, props.requisicionID)
        if (!!actividadDeRequisicion) {
            props.setActividadPlaneada(true)
            setActividadDeRequisicion({ ...actividadDeRequisicion, dia: actividadDeRequisicion.dia * 1000 })
            setState({ agente: actividadDeRequisicion.agente_ref, dia: actividadDeRequisicion.dia * 1000, tiempoEstimado: getTimestampFromMinutes(actividadDeRequisicion.tiempo_estimado || 0) })
        }
    }

    const handleCalendarInputChange = (event, index) => {
        let time = dateToUnixTimeAsNumber13(event.target.value)
        handleInputChange({ target: { name: event.target.name, value: usuario.displayName === "Adam Torres" ? time + 10800000 : time } }, index)
    }
    const handleInputChange = (event) => {
        let stateCopy = { ...state }
        stateCopy[event.target.name] = event.target.value
        setState(stateCopy)
    }
    const handleChangeInputEstimatedTime = (event) => {
        const stateCopy = DeepClone(state)
        const nativeValue = event.originalEvent.target.value
        const calendarValue = event.target.value
        if (nativeValue) { stateCopy.tiempoEstimado = getNativeValue(nativeValue); }
        else { stateCopy.tiempoEstimado = calendarValue ? dateToUnixTimeAsNumber13(calendarValue) : 0; }
        setState(stateCopy)
    }
    const getNativeValue = (nativeValue) => {
        console.log(nativeValue)
        const newDate = new Date()
        const splited = nativeValue?.split(":")
        newDate.setHours(splited[0], splited[1] || 0, 0)
        const time = newDate.getTime()
        return time === NaN ? 0 : time
    }
    const asignarTarea = async () => {
        setCargando(true)
        props.closeModal()
        let req = DeepClone(props.datosRequisicion)
        let data = {
            urgencia: req.urgencia,
            solicitante: req.solicitante,
            orden_ref: req.orden_ref,
            requerimiento: req.requerimiento,
            locacion: req.items[0].locacion,
            detalle: req.items[0].detalle,
            cantidad: req.items[0].cantidad,
            requisicion_ref: props.requisicionID,

            categoria: "cat_other_requisicion", categoria_ref: "cat_other_requisicion",
            subcategoria_ref: "sub_other_requisicion", subcategoria: "sub_other_requisicion",
            actividad_ref: "other_requisicion", comentario: "",

            nombreTarea: req.items[0].solicitud,
            empresa_ref: req.items[0].empresa,
            empresa: req.items[0].empresa,

            tiempo_tomado: "",

            dia: asignarDia(),
            agente_ref: state.agente,
            tiempo_estimado: getMinutesByTimestamp(state.tiempoEstimado),
            is_requisicion: true,
        }
        let tareaID = actividadDeRequisicion.id || getFirestoreID()
        await guardarActividadRequisicion(ciclo, data, tareaID)
        setActividadDeRequisicion(data)

        props.setActividadPlaneada(true)

        setCargando(false)
    }

    const asignarDia = () => {
        let diasDeSemana = getDiasDeSemana(state.dia)
        let indice = getIndiceDiaSemana(state.dia)
        return diasDeSemana[indice]
    }
    const getTiempoValor = (tiempo) => {console.log("getTiempoValor: ",tiempo)
        if (!tiempo || tiempo === 1){ console.log("SIN")
            return getCalendarDate(getInicioDia(Date.now()) * 1000)}
        else return getCalendarDate(tiempo)
    }
    const filtrarWeeks = () => weeks?.length > 0 ? [weeks[weeks.length - 2], weeks[weeks.length - 1]] : []
    const puedeGuardar = () => !!state.agente && !!state.dia && !!state.tiempoEstimado
    const footer = () =>
        <div>
            <Button label="Cancelar" onClick={props.closeModal} />
            <Button label="Asignar tareas" disabled={!puedeGuardar()} onClick={asignarTarea} />
        </div>

    return (
        <Dialog header="Asignar tareas al agente" visible={props.visible} style={{ width: '50vw' }} onHide={props.closeModal} footer={footer()}>
            <div className="p-grid ">
                <div className="form-group p-col-12">
                    <label htmlFor="status-nuevo">Agente </label>
                    <Dropdown name="agente" optionLabel="displayName" optionValue="id" options={agentes} value={state.agente} onChange={handleInputChange} />
                </div>

                <div className="form-group p-col-12">
                    <label htmlFor="cinco">Fecha de asignación</label>
                    <Calendar minDate={getCalendarDate(Date.now())} disabledDays={[0]} name="dia" value={getCalendarDate(usuario.displayName === "Adam Torres" && state.dia ? state.dia + 10800000 : state.dia)} onChange={handleCalendarInputChange} dateFormat="dd/mm/yy" />
                </div>
                <div className="form-group p-col-12">
                    <label htmlFor="cinco">Tiempo estimado</label>
                    <Calendar id="tiempo_tomado" readOnlyInput={false} timeOnly={true} hourFormat="24"
                        name="tiempo_tomado" value={getTiempoValor(state.tiempoEstimado)} onChange={handleChangeInputEstimatedTime} />
                </div>

            </div>
        </Dialog >)
}
export default ModalPlaneacionAgente;