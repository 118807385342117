import React, { useState, useGlobal } from 'reactn';
import { Card } from 'primereact/card';
import { withRouter } from 'react-router-dom';
import { separadoComas } from '../../util/functions';
import DatoCard from '../common/cards/DatoCard';
import Column from '../common/cards/Column';
import HTGCard from '../common/cards/HTGCard';
import CardHeader from '../common/cards/CardHeader';

function InvernaderoCard(props) {
  const [invernadero, setInvernadero] = useGlobal("invernadero");

  const consultar = () => {


    setInvernadero(props.invernadero);
    props.history.push("/pronosticos/registro");


  }


  let esExterno = props.invernadero.es_externo
  const header = esExterno ?
    <span className="card-header"><div className={`card-id icon-general icon-id bor-${props.invernadero.color} bg-${props.invernadero.color}`}>{props.invernadero.nombre}</div><div>
    </div></span>
    :
    <CardHeader
      invernaderoVirtual={props.invernadero}
      habilitadoTexto={props.invernadero?.habilitado ? "" : "Deshabilitado"}
      nombreInvernaderoFisico={props.invernadero?.invernaderoFisico?.nombre || ""} />
  let dims = esExterno ? "" : props.invernadero.detalles_fisicos.find(detalle => detalle.detalle === "Area (m²)")["totales"];
  dims = esExterno ? "" : separadoComas(dims.split(" ")[0]) + " " + dims.split(" ")[1];
  let className = `htg-card bor-${props.invernadero.color} ${props.invernadero.habilitado ? "Habilitado" : "Deshabilitado"}`
  return <HTGCard
    header={header}
    headerClassName={className}
    onClick={consultar}>
    <Column>
      <DatoCard label="REAL ACTUAL"
        valor={`${props.invernadero.semanaUno} kg/m2`} />
      <DatoCard label="ACCURRACY 3 WEEKS"
        valor={`${props.invernadero.semanaTres === "-" ? "-" : Math.round(100 * props.invernadero.semanaTres)} %`} />
    </Column>
    <Column>
      <DatoCard label="ACCURRACY 5 WEEKS"
        valor={`${props.invernadero.semanaCinco === "-" ? "-" : Math.round(100 * props.invernadero.semanaCinco)} %`} />
      <DatoCard label="PROYECCION ACUMULADA"
        valor={`${isNaN(props.invernadero.proyeccion) ? "-" : props.invernadero.proyeccion} kg/m2`} />
    </Column>
  </HTGCard>
}

export default withRouter(InvernaderoCard);