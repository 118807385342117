import { getGlobal } from 'reactn'
import { getFirestoreID } from "./Mantenimiento";
import {
    getProductosUnicosQUERY, getProductosBasesQUERY, getProductoQUERY, getProductosMezcladosPorCicloQUERY, getVariedadesDeProductoQUERY,
    getColoresVariedadesQUERY, guardarVariedadQUERY, guardarColorVariedadQUERY, guardarProductoQUERY, guardarProductoMezcladoQUERY
} from "./repositorios/Productos.queries";
import { getObjetoPorID, ordenarObjetosPorNombre } from '../util/functions';
import { CON_VARIEDADES, SOLO_MEDLEYS } from './constants/ProductosYVariedades.const';

///////////////////////////////////////////////////////////////////////////
//Funciones para jalar datos globales, si no hay datos globales o si 'force = true' llama los Firestore queries
export const getProductosBases = async (force = false) => {
    const { productosBases } = getGlobal();
    const queryDatosDeFirestore = !productosBases || force;
    return queryDatosDeFirestore ? await getProductosBasesQUERY() : productosBases;
}
export const getProductosUnicos = async (force = false) => {
    const { productosUnicos } = getGlobal();
    const queryDatosDeFirestore = !productosUnicos || force;
    return queryDatosDeFirestore ? await getProductosUnicosQUERY() : productosUnicos;
}

export const getProducto = async (productoID, force = false) => {
    const { productosYVariedadesTodos } = getGlobal();
    const queryDatosDeFirestore = !productosYVariedadesTodos || force;
    return queryDatosDeFirestore ? await getProductoQUERY(productoID) : getObjetoPorID(productosYVariedadesTodos, productoID);
}

export const getColoresVariedades = async (force = false) => {
    const { coloresVariedades } = getGlobal();
    const queryDatosDeFirestore = !coloresVariedades || force;
    return queryDatosDeFirestore ? await getColoresVariedadesQUERY() : coloresVariedades;
}

export const getProductosMezcladosDeCiclo = async (ciclo) => {
    const { allVariedades } = getGlobal();
    let mezclados = await getProductosMezcladosPorCicloQUERY(ciclo)
    mezclados = getProductosMezcladosConVariedades(mezclados, allVariedades)
    return mezclados;
}

///////////////////////////////////////////////////////////////////////////
///////////////////////////////Guardar datos///////////////////////////////
export const guardarProducto = async (productoID, datos) => {
    if (!productoID) { productoID = getFirestoreID(); }
    return await guardarProductoQUERY(productoID, datos);
}
export const guardarVariedad = async (productoID, variedadID, datos) => {
    if (!variedadID) { variedadID = getFirestoreID(); }
    return await guardarVariedadQUERY(productoID, variedadID, datos);
}
export const guardarColorVariedad = async (colorID, datos) => {
    if (!colorID) { colorID = getFirestoreID(); }
    return await guardarColorVariedadQUERY(colorID, datos);
}

export const guardarProductoMezclado = async (cicloID, productoID, datos) => {
    if (!productoID) { productoID = getFirestoreID(); }
    return await guardarProductoMezcladoQUERY(cicloID, productoID, datos);
}


///////////////////////////////////////////////////////////////////////////
/*
Obtener productos y variedes con todas los colecciones globales. 
Se llama al entrar a la pantalla y al recargar datos después de administrar
*/
export const getColeccionesProductosYVariedadesTodos = async (cicloID) => {
    const promises = [getProductosBasesQUERY(), getProductosMezcladosPorCicloQUERY(cicloID)];
    const [productosBasesSinVariedades, productosMezcladosSinVariedades] = await Promise.all(promises);
    const allVariedades = await getAllVariedades(productosBasesSinVariedades);

    const productosBases = getProductosBasesConVariedades(productosBasesSinVariedades, allVariedades);
    const productosMezclados = getProductosMezcladosConVariedades(productosMezcladosSinVariedades, allVariedades);
    const productosUnicos = productosBases.filter(el => el.unico);
    const medleysGeneral = productosBases.find(el => el.MEDLEYS_GENERAL);

    const productosYVariedades = [...productosUnicos, ...productosMezclados];
    const productosYVariedadesTodos = [...productosBases, ...productosMezclados];
    ordenarObjetosPorNombre(productosYVariedades);
    ordenarObjetosPorNombre(productosYVariedadesTodos);

    return { productosYVariedades, productosUnicos, productosMezclados, productosBases, medleysGeneral, allVariedades, productosYVariedadesTodos };
}
export const getAllVariedades = async (productos) => {
    const promises = productos.map(producto => getVariedadesDeProductoQUERY(producto.id))
    let allVariedades = await Promise.all(promises);
    allVariedades = allVariedades.flat();
    return ordenarObjetosPorNombre(allVariedades);
}

const getProductosBasesConVariedades = (productos, allVariedades) => {
    productos.forEach(producto => {//hacer asi en ves de tener .map()?
        producto.variedades = allVariedades.filter(el => el.producto_ref === producto.id)
    })
    return productos;
}
const getProductosMezcladosConVariedades = (productos, allVariedades) => {
    productos.forEach(producto => {
        const variedadesOBJs = producto.variedades.map(variedadID => getObjetoPorID(allVariedades, variedadID))
        producto.variedades = variedadesOBJs;
    })
    return productos;
}


///////////////////////////////////////////////////////////////////////////
//////////////////FILTRANDO PRESENTACIONES PARA DROPDOWNS/////////////////
export const getPresentacionesFiltradosYOrdenadosPorInvernadero = (presentaciones = [], invernadero = {}, orderBy = "id_presentacion", productosYVariedadesTodos = getGlobal().productosYVariedadesTodos) => {
    if (!invernadero.producto_ref || presentaciones.length === 0) { return []; }
    const producto = getObjetoPorID(productosYVariedadesTodos, invernadero.producto_ref);//Obtener producto del invernadero virtual

    if (producto.mezcla) { return getPresentacionesDeMezclaPorInvernadero(presentaciones, producto, invernadero, orderBy); }
    if (producto.MEDLEYS_GENERAL && invernadero.MEDLEYS_VIEJO) { return getPresentacionesMedleysDeCliente(presentaciones, invernadero, orderBy); }
    else { return getPresentacionesPorInvernaderoUnico(presentaciones, invernadero, orderBy); }
}

//Filtrar medleys
const getPresentacionesDeMezclaPorInvernadero = (presentaciones = [], productoMezclado = {}, invernadero = {}, orderBy = "id_presentacion") => {
    const presentacionesFiltrados = [];
    const presentacionesMezcladas = filtrarPresentacionesMezcladosPorCliente(presentaciones, invernadero.cliente_ref, orderBy);
    const presentacionesUnicos = filtrarPresentacionesUnicosPorMezclaYCliente(presentaciones, productoMezclado, orderBy);

    const presentacionesUnicosDelCliente = presentacionesUnicos.filter(presentacion => presentacion.cliente_ref === invernadero.cliente_ref);
    const presentacionesUnicosOtros = presentacionesUnicos.filter(presentacion => presentacion.cliente_ref !== invernadero.cliente_ref);

    //Presentaciones medleys del cliente, y presentaciones unicos del cliente
    presentacionesFiltrados.push(...presentacionesMezcladas, ...presentacionesUnicosDelCliente);

    const clientesDelCicloTodos = presentacionesUnicosOtros.map(presentacion => presentacion.cliente_ref);
    const clientesDelCiclo = [...new Set(clientesDelCicloTodos)];
    clientesDelCiclo.forEach(clienteOtro => {
        const presentacionesMedleysOtroCliente = presentaciones.filter(presentacion => presentacion.cliente_ref === clienteOtro && presentacion.mezcla);
        const presentacionesUnicosOtroCliente = presentacionesUnicosOtros.filter(presentacion => presentacion.cliente_ref === clienteOtro);
        presentacionesFiltrados.push(...presentacionesMedleysOtroCliente, ...presentacionesUnicosOtroCliente);
    })

    //presentacionesFiltrados.push(...presentacionesUnicosOtros);
    return presentacionesFiltrados;
}
const filtrarPresentacionesMezcladosPorCliente = (presentaciones = [], clienteID, orderBy) => {
    const presentacionesDeMezclaYCliente = presentaciones.filter(pres => pres.mezcla && pres.cliente_ref === clienteID);
    return presentacionesDeMezclaYCliente.sort((a, b) => a[orderBy] > b[orderBy] ? 1 : -1);
}
const filtrarPresentacionesUnicosPorMezclaYCliente = (presentaciones = [], producto = {}, orderBy) => {
    const productosUnicosIDs = producto.variedades.map(variedad => variedad.producto_ref) || [];

    const presentacionesUnicosDeLaMezcla = presentaciones.filter(pres => {
        const esAsignadoAProductoDeLaMezcla = productosUnicosIDs.includes(pres.producto_ref);
        return esAsignadoAProductoDeLaMezcla;
    })
    return presentacionesUnicosDeLaMezcla.sort((a, b) => a[orderBy] > b[orderBy] ? 1 : -1);
}

//Filtrar medleys viejo
const getPresentacionesMedleysDeCliente = (presentaciones = [], invernadero = {}, orderBy = "id_presentacion") => {
    const presentacionesDelCliente = presentaciones.filter(pres => pres.producto_ref === invernadero.producto_ref && invernadero.cliente_ref === pres.cliente_ref);
    const presentacionesDelClienteOrdenados = presentacionesDelCliente.sort((a, b) => a[orderBy] > b[orderBy] ? 1 : -1);
    return presentacionesDelClienteOrdenados;
}

//filtrar unico
const getPresentacionesPorInvernaderoUnico = (presentaciones = [], invernadero = {}, orderBy = "id_presentacion") => {
    const presentacionesDelCliente = presentaciones.filter(pres => pres.producto_ref === invernadero.producto_ref && invernadero.cliente_ref === pres.cliente_ref);
    const presentacionesOtros = presentaciones.filter(pres => pres.producto_ref === invernadero.producto_ref && invernadero.cliente_ref !== pres.cliente_ref);

    //LRT_GRAPE no se va ordenar como los demas porque no hay el campo de 'cliente_ref' 
    const presentacionesDelClienteOrdenados = presentacionesDelCliente.sort((a, b) => a[orderBy] > b[orderBy] ? 1 : -1);
    const presentacionesOtrosOrdenados = presentacionesOtros.sort((a, b) => a[orderBy] > b[orderBy] ? 1 : -1);
    return [...presentacionesDelClienteOrdenados, ...presentacionesOtrosOrdenados];
}

/*
export const getPresentacionesFiltradosYOrdenadosPorInvernaderoSoloMedleys = (presentaciones = [], invernadero = {}, orderBy = "id_presentacion") => {
    return getPresentacionesFiltradosYOrdenadosPorInvernadero(presentaciones, invernadero, SOLO_MEDLEYS, orderBy);
}
export const getPresentacionesFiltradosYOrdenadosPorInvernaderoConVariedades = (presentaciones = [], invernadero = {}, orderBy = "id_presentacion") => {
    return getPresentacionesFiltradosYOrdenadosPorInvernadero(presentaciones, invernadero, CON_VARIEDADES, orderBy);
}
*/
////////////////END FILTRAR PRESENTACIONES PARA DROPDOWNS///////////////
////////////////////////////////////////////////////////////////////////


//////////////////OTRO SYNC FUNCIONES/////////////////
export const hayMedleysViejo = (invernaderosLista = []) => invernaderosLista?.some(inv => inv.MEDLEYS_VIEJO);
export const filtrarVariedadMedleys = (allVariedades) => allVariedades.filter(el => !el.MEDLEYS_GENERAL);

export const obtenerProductosDelCiclo = (invernaderosVirtuales = [], productos = []) => {
    const productosDelCiclo = productos?.filter(prod => {
        const tieneProducto = !!invernaderosVirtuales.some(inv => prod.id === inv.producto_ref);
        const tieneMedleys = !!prod.MEDLEYS_GENERAL && !!invernaderosVirtuales.some(inv => inv.mezcla)
        return tieneProducto || tieneMedleys;
    })
    return productosDelCiclo || [];
}

export const getProductoBaseDeInvernadero = (productosBases = [], medleysGeneral, invernadero) => {
    if (invernadero.mezcla) { return medleysGeneral; }
    else return getObjetoPorID(productosBases, invernadero.producto_ref);
}