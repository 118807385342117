import React from 'react';
import { useState, useEffect, useGlobal } from 'reactn';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { Message } from 'primereact/message';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { obtenerEmpresas } from "../../service/Empresas";

import * as firebase from "firebase/app";
import { obtenerLocaciones } from '../../service/Locaciones';
import { obtenerInvernaderoFisicoRelacionado } from "../../service/Invernaderos";
import moment from "moment";
import 'moment/locale/es';
import { obtenerEmpaques } from '../../service/Empaques';
import { getNombrePorID } from '../../util/functions';
import { AGROPARK, ATONGO, EMPAQUE_SITIO_AGROPARK, HTF } from '../../constants';
moment.locale("es");

const EditarFisico = (props) => {
    const [usuario,] = useGlobal("usuario");
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [, setCargando] = useGlobal("cargando");
    const [sitiosEmpaque, setSitiosEmpaque] = useGlobal("sitiosEmpaque");
    const [locaciones, setLocaciones] = useGlobal("locaciones")
    const [empresas, setEmpresas] = useGlobal("empresas")

    //const [editorGeneratorDetalles, currentDetalles] = usePrimeReactTable(lineas2, "")
    const [state, setState] = useState({
        invernaderoID: props.match.params.invernaderoID, fisico: { capillas: [{ lineas_norte: 0, lineas_sur: 0 }] }, capillas: [],
        lineas: [
            { detalle: "Área (m2)", norte: 0, sur: 0, totales: 0, display: "" },
            { detalle: "# de Capillas", norte: 0, sur: 0, totales: 0, },
            { detalle: "Claros por linea", norte: 0, sur: 0, totales: 0 }
        ],
        capillas: [{ capilla: 1, norte: 0, sur: 0, totales: 0, }]
    });

    useEffect(() => {
        setNombreModulo("Invernaderos Físicos")
        obtenerDatosInicio();
    }, []);
    useEffect(() => {
        if (otraEmpresaSeleccionada()) {
            const locacionAgropark = locaciones?.find(loc => loc.nombre === AGROPARK) || {};
            setState({ ...state, fisico: { ...state.fisico, locacion_ref: locacionAgropark.id } })
        }
    }, [state.fisico.empresa_ref])
    useEffect(() => {
        if (locacionAgroparkSeleccionado()) {
            const sitioAgropark = sitiosEmpaque?.find(sitio => sitio.nombre === EMPAQUE_SITIO_AGROPARK) || {};

            setState({ ...state, fisico: { ...state.fisico, empaque_ref: sitioAgropark.id } })
        }
        else if (locacionAtongoSeleccionado() && sitioEmpaqueAgroparkSeleccionado()) {
            setState({ ...state, fisico: { ...state.fisico, empaque_ref: "" } })
        }
    }, [state.fisico.locacion_ref])

    const obtenerDatosInicio = async () => {
        setCargando(true)

        if (!state.invernaderoID) props.history.replace("/administrar/invernadero_fisico/crear");
        else {
            const [fisico, respEmp, respLocs, respEmpaque] = await Promise.all([obtenerInvernaderoFisicoRelacionado(state.invernaderoID), obtenerEmpresas(), obtenerLocaciones(), obtenerEmpaques()])

            let capillas = fisico.capillas.map(ca => ({
                capilla: ca.nombre, norte: ca.lineas_norte, sur: ca.lineas_sur, totales: parseInt(ca.lineas_norte) + parseInt(ca.lineas_sur),
                display: (parseInt(ca.lineas_norte || 0) + parseInt(ca.lineas_sur || 0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }))

            setEmpresas(respEmp)
            setLocaciones(respLocs);
            setSitiosEmpaque(respEmpaque);
            setState({
                ...state, fisico, capillas,
                lineas: [
                    { detalle: "Área (m2)", norte: fisico.area_norte || "-", sur: fisico.area_sur || "-", totales: fisico?.area || 0, display: (fisico?.area || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") },
                    { detalle: "# de Capillas", norte: fisico.capillas_norte, sur: fisico.capillas_sur, totales: parseInt(fisico.capillas_norte || 0) + parseInt(fisico.capillas_sur || 0), display: parseInt(fisico.capillas_norte || 0) + parseInt(fisico.capillas_sur || 0)  },
                    { detalle: "Claros por linea", norte: fisico.claros_por_linea_norte, sur: fisico.claros_por_linea_sur, totales: parseInt(fisico.claros_por_linea_norte || 0) + parseInt(fisico.claros_por_linea_sur || 0), display: parseInt(fisico.claros_por_linea_norte || 0) + parseInt(fisico.claros_por_linea_sur || 0), },
                ]
            })
        }
        setCargando(false)
    }

    const colors = [
        { label: "Verde oscuro", value: "green-dark" },
        { label: "Verde", value: "green" },
        { label: "Azul oscuro", value: "blue-dark" },
        { label: "Azul", value: "blue" },
        { label: "Morado oscuro", value: "purple-dark" },
        { label: "Morado", value: "purple" },
        { label: "Rosado oscuro", value: "pink-dark" },
        { label: "Rosado", value: "pink" },
        { label: "Rojo", value: "red" },
        { label: "Rojo oscuro", value: "red-dark" },
        { label: "Naranja oscuro", value: "orange-dark" },
        { label: "Naranja", value: "orange" },
        { label: "Amarillo oscuro", value: "yellow-dark" },
        { label: "Amarillo", value: "yellow" },
        { label: "Gris oscuro", value: "gray-dark" },
        { label: "Gris", value: "gray" }

    ]

    const guardarFisico = async () => {
        setState({ ...state, save: true })
        const { nombre, color, empresa_ref, locacion_ref, empaque_ref } = state.fisico;

        if (nombre && color && empresa_ref && locacion_ref && empaque_ref) {
            setCargando(true)
            let capillas = state.capillas.map(cap => ({ nombre: cap.capilla, lineas_norte: cap.norte, lineas_sur: cap.sur }));

            await firebase.firestore().collection('invernaderos_fisicos').doc(state.invernaderoID).set({
                nombre: state.fisico.nombre,
                color: state.fisico.color,
                locacion_ref: state.fisico.locacion_ref,
                empresa_ref: state.fisico.empresa_ref,
                empaque_ref: state.fisico.empaque_ref,
                area: state.lineas[0].totales,
                area_norte: state.lineas[0].norte,
                area_sur: state.lineas[0].sur,
                capillas_norte: state.lineas[1].norte,
                capillas_sur: state.lineas[1].sur,
                claros_por_linea_norte: state.lineas[2].norte,
                claros_por_linea_sur: state.lineas[2].sur,
                capillas: capillas
            }, { merge: true })

            setCargando(false)
            props.history.push("/administrar/invernaderos_fisicos");
        }
    }

    const setArea = (value, props) => {
        if (props.rowData.detalle === "Área (m2)") {
            if (props.field === "norte") {
                setState({
                    ...state, lineas: [
                        { detalle: state.lineas[0].detalle, norte: value, sur: state.lineas[0].sur, totales: parseInt(value || 0) + parseInt(state.lineas[0]?.sur || 0), display: (parseInt(value || 0) + parseInt(state.lineas[0]?.sur || 0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") },
                        { detalle: state.lineas[1].detalle, norte: state.lineas[1].norte, sur: state.lineas[1].sur, totales: state.lineas[1]?.totales || 0, display: state.lineas[1]?.totales || 0 },
                        { detalle: state.lineas[2].detalle, norte: state.lineas[2].norte, sur: state.lineas[2].sur, totales: state.lineas[2]?.totales || 0, display: state.lineas[2]?.totales || 0 },
                    ]
                })
            }
            else if (props.field === "sur") {
                setState({
                    ...state, lineas: [
                        { detalle: state.lineas[0].detalle, norte: state.lineas[0].norte, sur: value, totales: parseInt(value || 0) + parseInt(state.lineas[0]?.norte || 0), display: (parseInt(value || 0) + parseInt(state.lineas[0]?.norte || 0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") },
                        { detalle: state.lineas[1].detalle, norte: state.lineas[1].norte, sur: state.lineas[1].sur, totales: state.lineas[1]?.totales || 0, display: state.lineas[1]?.totales || 0},
                        { detalle: state.lineas[2].detalle, norte: state.lineas[2].norte, sur: state.lineas[2].sur, totales: state.lineas[2]?.totales || 0, display: state.lineas[2]?.totales || 0},
                    ]
                })
            }
        }
    }

    const HTFSeleccionado = () => getNombrePorID(empresas, state.fisico.empresa_ref) === HTF;
    const otraEmpresaSeleccionada = () => !!state.fisico.empresa_ref && getNombrePorID(empresas, state.fisico.empresa_ref) !== HTF;
    const locacionAgroparkSeleccionado = () => getNombrePorID(locaciones, state.fisico.locacion_ref) === AGROPARK;
    const locacionAtongoSeleccionado = () => getNombrePorID(locaciones, state.fisico.locacion_ref) === ATONGO;
    const sitioEmpaqueAgroparkSeleccionado = () => getNombrePorID(sitiosEmpaque, state.fisico.empaque_ref) === EMPAQUE_SITIO_AGROPARK;

    const handleInputChangeLocacion = (event) => {
        const { value } = event.target;
        setState({ ...state, fisico: { ...state.fisico, locacion_ref: value } })
    }
    const handleInputChangeEmpaque = (event) => {
        const { value } = event.target;
        setState({ ...state, fisico: { ...state.fisico, empaque_ref: value } })
    }
    const getOpcionesSitiosEmpaque = () => {
        if (locacionAgroparkSeleccionado()) { return sitiosEmpaque; }
        else { return sitiosEmpaque?.filter(sitio => sitio.nombre !== EMPAQUE_SITIO_AGROPARK) }
    }

    return (
        <div className="p-grid p-justify-center">
            <div className="p-col p-col-auto title-container">
                <span className="back-btn" onClick={() => { props.history.goBack() }}></span>
                <span className="icon-general inv-icon"></span>
                <span className="titles-group">
                    <h1 className="header-title">{usuario.isRolFinanzas ? "Consultar" : "Modificar"} invernadero {state.fisico.nombre}</h1>
                    <p className="header-subtitle"></p>
                </span>
            </div>

            <div className="p-col-10">
                <div className="p-grid p-fluid ">
                    <div className="p-col-12">
                        <p className="section-title">Características</p>
                        <div className="card">
                            {state.fisico &&
                                <div className="p-grid">
                                    <div className="form-group p-col-12 p-md-6">
                                        <label htmlFor="nombre">Nombre</label>
                                        <InputText id="nombre" value={state.fisico?.nombre} onChange={e => setState({ ...state, fisico: { ...state.fisico, nombre: e.target.value } })} disabled={usuario.isRolFinanzas} />
                                        {(state.save === true && state.fisico?.nombre === "") ? <Message severity="error" text="Este campo es requerido"></Message> : null}

                                    </div>
                                    <div className="form-group p-col-12 p-md-6">
                                        <label htmlFor="colores">Color</label>
                                        <Dropdown id="colores" options={colors} value={state.fisico?.color} onChange={e => setState({ ...state, fisico: { ...state.fisico, color: e.target.value } })} placeholder="Seleccione un color" disabled={usuario.isRolFinanzas} />
                                        {(state.save === true && state.fisico?.color === "") ? <Message severity="error" text="Este campo es requerido"></Message> : null}
                                    </div>
                                    <div className="form-group p-col-12 p-md-6">
                                        <label htmlFor="empresas">Empresa</label>
                                        <Dropdown id="empresas" options={empresas} placeholder="Seleccione empresa_ref" optionLabel="nombre" optionValue="id"
                                            value={state.fisico?.empresa_ref} onChange={e => setState({ ...state, fisico: { ...state.fisico, empresa_ref: e.target.value } })} placeholder="Seleccione una empresa" disabled={usuario.isRolFinanzas} />
                                        {(state.save === true && state.fisico?.empresa_ref === "") ? <Message severity="error" text="Este campo es requerido"></Message> : null}
                                    </div>
                                    <div className="form-group p-col-12 p-md-6">
                                        <label htmlFor="location">Locación</label>
                                        <Dropdown id="locacion_ref" options={locaciones} value={state.fisico.locacion_ref} optionLabel="nombre" optionValue="id" disabled={!HTFSeleccionado()}
                                            onChange={handleInputChangeLocacion} placeholder="Seleccione un locación" />
                                        {(state.save === true && state.fisico.locacion_ref === "") ? <Message severity="error" text="Este campo es requerido"></Message> : null}
                                    </div>
                                    <div className="form-group p-col-12 p-md-6">
                                        <label htmlFor="empaque_ref">Sitio de empaque</label>
                                        <Dropdown id="empaque_ref" options={getOpcionesSitiosEmpaque()} value={state.fisico.empaque_ref} optionLabel="nombre" optionValue="id" disabled={locacionAgroparkSeleccionado()}
                                            onChange={handleInputChangeEmpaque} placeholder="Seleccione un locación" />
                                        {(state.save === true && state.fisico.empaque_ref === "") ? <Message severity="error" text="Este campo es requerido"></Message> : null}
                                    </div>
                                </div>}
                        </div>

                        <div className="p-col p-col-auto">
                            <p className="section-title">Detalles físicos</p>
                            <div className="card p-0">
                                <DataTable value={state.lineas} className="">
                                    <Column field="detalle" header="Detalle" className="col__label" />
                                    <Column field="norte" header="Norte" editor={(props) => { return props.rowData.detalle === "Área (m2)" ? <InputText keyfilter={"pnum"} id="norte" onChange={e => setArea(e.target.value, props)} /> : <div>No editable</div> }} />
                                    <Column field="sur" header="Sur" editor={(props) => { return props.rowData.detalle === "Área (m2)" ? <InputText keyfilter={"pnum"} id="norte" onChange={e => setArea(e.target.value, props)} /> : <div>No editable</div> }} />
                                    <Column field="display" header="Display" className="col__total" />
                                </DataTable>
                            </div>
                        </div>

                        <div className="p-col p-col-auto">
                            <p className="section-title">Asignación de lineas</p>
                            <div className="card p-0">
                                <DataTable value={state.capillas} className="">
                                    <Column field="capilla" header="# Capilla" className="col__label" />
                                    <Column field="norte" header="Norte" editor={(props) => { return <div>No editable</div> }} />
                                    <Column field="sur" header="Sur" editor={(props) => { return <div>No editable</div> }} />
                                    <Column field="totales" header="Totales" className="col__total" />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-col-12">
                <div className="p-grid p-justify-end btn-row">
                    <Button label="Cancelar" className="p-button-secondary" onClick={() => { props.history.goBack() }} />
                    <Button label="Guardar" disabled={usuario.isRolFinanzas} onClick={guardarFisico} />
                </div>
            </div>
        </div>);
}
export default EditarFisico;