import React, {useState, useEffect} from 'react';
import { useGlobal  } from 'reactn';
import ContenedorPrincipal from '../../common/ContenedorPrincipal'
import ContenedorHeader from '../../common/ContenedorHeader';
import SeccionFiltros from '../../common/SeccionFiltros';
import { MultiSelect } from 'primereact/multiselect';
import Chart from "react-apexcharts";
import { SelectButton } from 'primereact/selectbutton';
import { obtenerCiclos } from '../../../service/Ciclos';
import { obtenerInvernaderosVirtuales } from '../../../service/Invernaderos';
import { obtenerInicioSemanaDia, obtenerListaDeSemanas } from '../../../service/fechas';
import ContenedorFiltro from '../../common/ContenedorFiltro';
import { obtenerPresentaciones } from '../../../service/Presentaciones';
import { obtenerClientes, obtenerClientesCiclo } from '../../../service/Clientes';
import { obtenerReporteIngresosBrutosInvernaderos } from '../../../service/AnalisisCostos';
import { cuatroDecimales, dosDecimales, stringToColor } from '../../../util/functions';
import { ToggleButton } from 'primereact/togglebutton';

const tipos = [{name:"Budget",code:"budget"},{name:"Real", code:"real"}]
const optionsOrig = {
    chart: {
      type: 'line',
      zoom: {
        enabled: true
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    title: {
      text: 'Titulo',
      align: 'left'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
     xaxis: {
       categories: [],
     }
  }
export default function Ingresos(props) {
  const atras = (params) => {
    props.history.goBack();
  }
  const tooltipFunction = ({series, seriesIndex, dataPointIndex,w}) => {
    let seriesCompletas = w.config.series;
    
    let labels =[];
    if(esMulticiclo){
      invernaderosMulticiclo.forEach(ciclo => {
        ciclo.filtro.forEach(invernadero => {
          labels.push( `<div class="p-tooltip-text">`)
          labels.push( `<div class="tooltip-title">${invernadero.nombre} ${ciclo["nombreCiclo"]}</div>`)
          labels.push( `<div class="tooltip-datas">`)
           if(tipo.includes("real")){
             let serie = seriesCompletas.find(el=>el.name === `Real ${invernadero.nombre} ${ciclo["nombreCiclo"]}`)
            labels.push( `<div class="tooltip-data">${dosDecimales(serie.data[dataPointIndex])} <p class="tooltip-label">$/m² Real</p></div>`)
           }
           if(tipo.includes("budget")){
            let serie = seriesCompletas.find(el=>el.name === `Budget ${invernadero.nombre} ${ciclo["nombreCiclo"]}`)
            labels.push( `<div class="tooltip-data">${dosDecimales(serie.data[dataPointIndex])} <p class="tooltip-label">$/m² Budget</p></div>`)
           }
           if(tipo.includes("budget") && tipo.includes("real")){
            let serieReal = seriesCompletas.find(el=>el.name === `Real ${invernadero.nombre} ${ciclo["nombreCiclo"]}`)
            let serieBudget = seriesCompletas.find(el=>el.name === `Budget ${invernadero.nombre} ${ciclo["nombreCiclo"]}`)
            labels.push( `<div class="tooltip-data">${dosDecimales(serieReal.data[dataPointIndex] - dosDecimales(serieBudget.data[dataPointIndex]))} <p class="tooltip-label">$/m² Diferencia</p></div>`)      
           }
           labels.push( `</div></div>`)
        })
        
      })
      
    }else{
      filtroInvernaderos.forEach(invernadero=>{
        labels.push( `<div class="p-tooltip-text">`)
        labels.push( `<div class="tooltip-title">${invernadero.nombre}</div>`)
        labels.push( `<div class="tooltip-datas">`)
         if(tipo.includes("real")){
           let serie = seriesCompletas.find(el=>el.name === `Real ${invernadero.nombre}`)
          labels.push( `<div class="tooltip-data">${dosDecimales(serie.data[dataPointIndex])} <p class="tooltip-label">$/m² Real</p></div>`)
         }
         if(tipo.includes("budget")){
          let serie = seriesCompletas.find(el=>el.name === `Budget ${invernadero.nombre}`)
          labels.push( `<div class="tooltip-data">${dosDecimales(serie.data[dataPointIndex])} <p class="tooltip-label">$/m² Budget</p></div>`)
         }
         if(tipo.includes("budget") && tipo.includes("real")){
          let serieReal = seriesCompletas.find(el=>el.name === `Real ${invernadero.nombre}`)
          let serieBudget = seriesCompletas.find(el=>el.name === `Budget ${invernadero.nombre}`)
          labels.push( `<div class="tooltip-data">${dosDecimales(serieReal.data[dataPointIndex] - dosDecimales(serieBudget.data[dataPointIndex]))} <p class="tooltip-label">$/m² Diferencia</p></div>`)
  
    
         }
         labels.push( `</div></div>`)
    
      
      })

    }
    labels = labels.join("")
    return `<div class = "chart-label-container"> ${labels}</div>`
     
  }
  const optionsOrig = {
    chart: {
      type: 'line',
      zoom: {
        enabled: true
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    title: {
      text: 'Ingresos brutos',
      align: 'left'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
     xaxis: {
       categories: [],
     },
     tooltip:{
      custom:  tooltipFunction
    }
  }
    const [invernaderos, setInvernaderos] = useState([]);
    const [invernaderosOrig, setInvernaderosOrig] = useState([]);
    const [filtroInvernaderos, setFiltroInvernaderos] = useState([]);
    const [productos, setProductos] = useState([]);
    const [filtroProductos, setFiltroProductos] = useState([]);
    const [cicloId, setCicloId] = useGlobal("ciclo");
    const [cargando, setCargando] = useGlobal("cargando");
    const [detalles, setDetalles] = useState([]);
    const [options, setOptions] = useState({...optionsOrig});
    const [seriesFiltradas, setSeriesFiltradas] = useState([]);
    const [variedades, setVariedades] = useState([]);
    const [filtroVariedades, setFiltroVariedades] = useState([]);
    const [tipo, setTipo] = useState([])
    const [semanas, setSemanas] = useState([])
    const [clientes, setClientes] = useState([])
    const [filtroClientes, setFiltroClientes] = useState([])
    const [presentacionesOrig, setPresentacionesOrig] = useState([])
    const [presentaciones, setPresentaciones] = useState([])
    const [filtroPresentaciones, setFiltroPresentaciones] = useState([])
    const [data, setData] = useState([]);
    const [isAcumulado, setIsAcumulado] = useState(true);
    const [reporte, setReporte] = useState([])
    const [esMulticiclo, setEsMulticiclo] = useState(false)
    const [filtroCiclos, setFiltroCiclos] = useState([])
    const [ciclos, setCiclos] = useState([])
    const [invernaderosMulticiclo, setInvernaderosMulticiclo] = useState([])

    const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
    const [productosYVariedades] = useGlobal("productosYVariedades");
    const [arregloInvsVirtuales,setArregloInvsVirtuales] = useGlobal("arregloInvsVirtuales");
    const [cicloOb] = useGlobal("cicloObj");
    const [,setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [,setNombreModulo] = useGlobal("nombreModulo");

    useEffect(()=>{
      setHabilitadoSelectorCiclo(true)
      setNombreModulo("Análisis de costos")
    },[])

    useEffect(() => {
      
      
      actualizarInvernaderosMulticiclo();
    }, [filtroCiclos])
    
    const actualizarInvernaderosMulticiclo =  async (params) => {
      let copia = invernaderosMulticiclo.map(el=>({...el}))
      let porAgregar = [];
      filtroCiclos.forEach(ciclo => {
        let existeCiclo = copia.find(el=>el.cicloId === ciclo.id);
        if(existeCiclo){}else{
          porAgregar.push(ciclo.id)
        }
      })
      let promises = porAgregar.map(ciclo=>{
        return obtenerInvernaderosVirtuales(ciclo);
      })
      let values = await Promise.all(promises);
      values.forEach((value,index) => {
        let idsProductos= filtroProductos.map(el=>el.id);
        let opciones = value.filter(el=>idsProductos.includes( el.producto_ref))
        let ciclo = ciclos.find(el=>el.id === porAgregar[index]);
        copia.push({
          cicloId: porAgregar[index],
          nombreCiclo: ciclo["nombre"],
          invernaderosOrig: value,
          opcionesInvernaderos: opciones,
          filtro:[]
        })
      })
      
      
      
      setInvernaderosMulticiclo(copia);
    }
    useEffect(() => {
        if(invernaderosVirtuales){
            obtenerDatosIniciales();
        }
    }, [invernaderosVirtuales])
    useEffect(() => {
      let procesado = actualizarSeries()
      setData(procesado);
    }, [isAcumulado])
    const obtenerDatosIniciales = async (params) => {
        try {            
            setCargando(true);
            let ciclos = await obtenerCiclos();            
            
            
            setCiclos(ciclos);
            let ciclo = cicloOb;
            let semanas =  obtenerListaDeSemanas(ciclo.semana_inicio, ciclo.semana_cierre);  
            setSemanas(semanas)
            let productos = productosYVariedades;            
            setProductos(productos);        
            let invernaderos = invernaderosVirtuales;
            setInvernaderosOrig(invernaderos);
            let presentaciones = await obtenerPresentaciones();
            setPresentacionesOrig(presentaciones);
            let clientesCiclo = await obtenerClientesCiclo(cicloId);
            let ids = clientesCiclo.map(el=>el.cliente_ref);
            let clientes = await obtenerClientes();
            clientes = clientes.filter(el=>ids.includes(el.id))
            setClientes(clientes);
            // let promises = invernaderos.map(el=>obtenerDetalleReporte(ciclo,el.id ));
            // let detalles = await Promise.all(promises);
            // let series = extraerSeries(invernaderos, detalles);
        } catch (error) {
            console.log("ERROR");
            console.log(error);
        }finally{
            setCargando(false);
        }
    }
    const actualizarOptions = (series) => {
      let copiaOptions = {...optionsOrig}  
      copiaOptions["xaxis"] = {}
      copiaOptions["xaxis"]["categories"] = semanas.map(el=>el.nombreCompacto);
      let ahora = Math.round(Date.now()/1000);
       let timeSemanaActual = obtenerInicioSemanaDia(ahora).unix();
      let semana = semanas.find(el=>el.time === timeSemanaActual);
      let colors = [];
      let width = [];
      let dashArray = [];
      series.forEach(serie => {
        
            width.push(5*(serie.index+1));
            dashArray.push(3*serie.index);
            colors.push(serie.color);
        
    }) 
    copiaOptions["stroke"]["width"] = width;
    copiaOptions["stroke"]["dashArray"] = dashArray;
    copiaOptions["colors"] = colors;
      
            
            if(semana){
              copiaOptions["annotations"] = {
                xaxis: [{
                  x:semana.nombreCompacto,
                  strokeDashArray: 0,
                  borderColor: '#775DD0',
                  label: {
                    borderColor: '#775DD0',
                    style: {
                      color: '#fff',
                      background: '#775DD0',
                    },
                    text: 'Actual',
                  }
                }]
              }
        
            }
      setOptions(copiaOptions);
    }
    useEffect(() => {
      if(cicloId){

        actualizarReporteInvernaderos()
      }
    }, [filtroInvernaderos, cicloId])
    
    useEffect(() => {
      let variedades = [];
      filtroProductos.forEach(producto => {
        variedades.push(producto.variedades);
      })
      variedades = variedades.flat();
      let idsVariedades = variedades.map(el=>el.id);
      let copiaFiltro = filtroVariedades.slice();
      copiaFiltro= copiaFiltro.filter(el=>idsVariedades.includes(el.id));
      setFiltroVariedades(copiaFiltro);
      setVariedades(variedades);
    }, [filtroProductos])
    // const extraerSeries = (invernaderos,detalles) => {
    //     let result = [];
    //      invernaderos.forEach((invernadero, index)=>{
    //         let dataInv = detalles[index]; 
            
    //         let baseBudget = {name:`Budget ${invernadero.nombre}`, label:`Budget ${invernadero.nombre}`,type:"line",data:[], idInvernadero:invernadero.id, 
    //           tipo:"budget", index:0, color: invernadero.color};
    //         let baseFinal = {name:`Final ${invernadero.nombre}`, label:`Final ${invernadero.nombre}`,type:"line",data:[], idInvernadero:invernadero.id,
    //           tipo:"kgm2", index:1, color:invernadero.color};
    //         let sumaBudget = 0;
    //         let sumaFinal = 0;
    //         dataInv.forEach(semana => {
    //             sumaBudget+=semana["budget"];
    //             //baseBudget["data"].push({x:semana.nombreCompacto,y:sumaBudget});                
    //             baseBudget["data"].push(dosDecimales(sumaBudget));
    //             sumaFinal+=semana["kgm2OrdenesSemana"];
    //             //baseFinal["data"].push({x:semana.nombreCompacto, y:sumaFinal});
    //             baseFinal["data"].push(dosDecimales(sumaFinal));
    //         })
            
    //         result.push(baseBudget);
    //         result.push(baseFinal);
            
    //     });
    //     return result;
    // }
    useEffect(() => {

        
        let filtradas = data.filter(el=>tipo.includes(el.tipo))
        
        
        
        
        actualizarOptions(filtradas)
        setSeriesFiltradas(filtradas);
    }, [ tipo, data])
    useEffect(() => {
        let ids = filtroProductos.map(el=>el.id);        
        let filtrados = invernaderosOrig.filter(el=>ids.includes(el.producto_ref));
        if(esMulticiclo){
          let copia = invernaderosMulticiclo.map(el=>({...el}))
          copia.forEach(datosCiclo => {
            datosCiclo.opcionesInvernaderos = datosCiclo.invernaderosOrig.filter(el=>ids.includes(el.producto_ref));
            datosCiclo.filtro =datosCiclo.filtro.filter(el=>ids.includes(el.producto_ref)) 
          })
        setInvernaderosMulticiclo(copia);
        }
        setInvernaderos(filtrados);        
        setFiltroInvernaderos(filtroInvernaderos.filter(el=>ids.includes(el.producto_ref)));
    }, [filtroProductos])
    useEffect(() => {
        let copia = presentacionesOrig.slice();
        let idsClientes = filtroClientes.map(el=>el.id);
        let idsProductos = filtroProductos.map(el=>el.id);
        copia = copia.filter(el=>idsClientes.includes(el.cliente_ref) && idsProductos.includes(el.producto_ref));
        let copiaFiltro  = filtroPresentaciones.filter(el=>idsClientes.includes(el.cliente_ref) && idsProductos.includes(el.producto_ref));
        setPresentaciones(copia);
        setFiltroPresentaciones(copiaFiltro);
    }, [filtroClientes, filtroProductos])
    const actualizarReporteInvernaderos = async (params) => {
      try {
        setCargando(true);
        let reporte = await obtenerReporteIngresosBrutosInvernaderos(cicloId, filtroInvernaderos, filtroPresentaciones);
         let procesado = obtenerSeries(reporte);
         setReporte(reporte);
        // 
        // 
        // 
         setData(procesado);
        
      } catch (error) {
        console.log("ERROR ORIBI");
        console.log(error);
      }finally{
        setCargando(false)
      }
    }
    useEffect(() => {
      actualizarReportes()
     
   }, [invernaderosMulticiclo])
   const actualizarReportes = async (params) => {
    setCargando(true);
    let nuevos = []
    let invernaderos = []
    let promises = invernaderosMulticiclo.map(datosCiclo => {
      invernaderos = invernaderos.concat(datosCiclo.filtro);
      
        // if(!cache.includes(invernadero.id)){
          
          let ciclo = ciclos.find(ciclo=>ciclo.id === datosCiclo.cicloId);
          return obtenerReporteIngresosBrutosInvernaderos(ciclo.id,datosCiclo.filtro, filtroPresentaciones)          
        // }
      
    })    
    
    let nuevosDetalles = await Promise.all(promises);
        
    
    
    //let copiaReporte = reporte.map(el=>({...el}))
    //copiaReporte = copiaReporte.concat(nuevosDetalles);    
    nuevosDetalles = nuevosDetalles.flat();
    let series = obtenerSeriesMulticiclo( nuevosDetalles);
    // let copiaCache = cache.slice();
    //copiaCache = copiaCache.concat(nuevos.map(el=>el.invernaderoId));

    setReporte(nuevosDetalles);
    setData(series);
    setCargando(false);
    //setCache(copiaCache);
  }
    const obtenerSeries = (reporte) => {
      
      
      
      let ahora = Math.round(Date.now()/1000);
      let inicioSemana = obtenerInicioSemanaDia(ahora).unix();
      
      let result = reporte.map(el=>{
        
        
        let invernadero = invernaderosOrig.find(invernadero=>invernadero.id === el.invernaderoId);
        let objReal = {name:`Real ${invernadero.nombre}`, label :`Real ${invernadero.nombre}`, index: 0,
          color: stringToColor(invernadero.color),tipo:"real", data: [] }
        let objBudget = {name:`Budget ${invernadero.nombre}`, label : `Budget ${invernadero.nombre}`, index: 1,
          color: stringToColor(invernadero.color),tipo:"budget", data:[] }
          let currentReal = 0;
          let currentBudget = 0;
          
        el.datos.forEach(semana => {
          let datoReal = cuatroDecimales(semana["ingreso"]);
          let datoBudget = cuatroDecimales(semana["ingresoBudget"]); 
          if( inicioSemana< semana.semana){
            datoReal = cuatroDecimales(semana["ingresoBudget"]); 
          }
          if(isAcumulado){
            currentReal+= isNaN(datoReal)?0:datoReal
            currentBudget+= isNaN(datoBudget)?0:datoBudget
          }else{
            currentReal = isNaN(datoReal)?0:datoReal
            currentBudget = isNaN(datoBudget)?0:datoBudget
          }
          objReal.data.push(dosDecimales(currentReal));
          objBudget.data.push(dosDecimales(currentBudget));
        })
        return [objReal, objBudget];  
      })
      return result.flat();
    }
    const actualizarSeries = () => {
      
      
      let ahora = Math.round(Date.now()/1000);
      let inicioSemana = obtenerInicioSemanaDia(ahora).unix();
      let result = reporte.map(el=>{
        
        
        let invernadero = invernaderosOrig.find(invernadero=>invernadero.id === el.invernaderoId);
        let objReal = {name:`Real ${invernadero.nombre}`, label :`Real ${invernadero.nombre}`, index: 0,
            color: stringToColor(invernadero.color),tipo:"real", data: [] }
          let objBudget = {name:`Budget ${invernadero.nombre}`, label : `Budget ${invernadero.nombre}`, index: 1,
            color: stringToColor(invernadero.color),tipo:"budget", data:[] }
            let currentReal = 0;
            let currentBudget = 0;
        if(esMulticiclo){
          let ciclo = "";
        
          let cicloFound = invernaderosMulticiclo.find(ciclo=>{
            let inv = ciclo.filtro.find(el=>el.id === invernadero.id);
            if(inv){
              return true;
            }else{
              return false;
            }
          }) 
            ciclo = cicloFound?cicloFound.nombreCiclo:"";          
          objReal = {name:`Real ${invernadero.nombre} ${ciclo}`, label :`Real ${invernadero.nombre} ${ciclo}`, index: 0,
            color: invernadero.color,tipo:"real", data: [] }
          objBudget = {name:`Budget ${invernadero.nombre} ${ciclo}`, label : `Budget ${invernadero.nombre} ${ciclo}`, index: 1,
            color: invernadero.color,tipo:"budget", data:[] }
            
            
        }else{

          
        }
        el.datos.forEach(semana => {
          let datoReal = cuatroDecimales(semana["ingreso"]);
          let datoBudget = cuatroDecimales(semana["ingresoBudget"]); 
          if( inicioSemana< semana.semana){
            datoReal = cuatroDecimales(semana["ingresoBudget"]); 
          }
          if(isAcumulado){
            currentReal+= isNaN(datoReal)?0:datoReal
            currentBudget+= isNaN(datoBudget)?0:datoBudget

          }else{
            currentReal =isNaN(datoReal)?0:datoReal
            currentBudget= isNaN(datoBudget)?0:datoBudget
          }
          objReal.data.push(dosDecimales(currentReal) )
          objBudget.data.push(dosDecimales(currentBudget));
        })
        return [objReal, objBudget];  
      })
      return result.flat();
    }
    const obtenerSeriesMulticiclo = (reportes) => {
      
      
      
      let ahora = Math.round(Date.now()/1000);
      let inicioSemana = obtenerInicioSemanaDia(ahora).unix();
      let result =[];
       invernaderosMulticiclo.forEach(datosCiclo=>{
        datosCiclo.filtro.forEach(invernadero => {
          let reporte = reportes.find(el=>el.invernaderoId === invernadero.id)
          
          let objReal = {name:`Real ${invernadero.nombre} ${datosCiclo["nombreCiclo"]}`, label :`Real ${invernadero.nombre} ${datosCiclo["nombreCiclo"]}`, index: 0,
            color: stringToColor(invernadero.color),tipo:"real", data: [] }
          let objBudget = {name:`Budget ${invernadero.nombre} ${datosCiclo["nombreCiclo"]}`, label : `Budget ${invernadero.nombre} ${datosCiclo["nombreCiclo"]}`, index: 1,
            color: stringToColor(invernadero.color),tipo:"budget", data:[] }
          reporte.datos.forEach(semana => {   
            let datoReal = cuatroDecimales(semana["ingreso"]);
            let datoBudget = cuatroDecimales(semana["ingresoBudget"]); 
            if( inicioSemana< semana.semana){
              datoReal = cuatroDecimales(semana["ingresoBudget"]); 
            }
            objReal.data.push(isNaN(datoReal)?0:dosDecimales(datoReal))
            objBudget.data.push(isNaN(datoBudget)?0:dosDecimales(datoBudget));
          })
          result.push(objReal);
          result.push(objBudget);
        })
        
      })
      return result
    }
    const handleFiltroMulticiclo = (cicloId, value) => {
      let copia = invernaderosMulticiclo.map(el=>({...el}))
      let current = copia.find(el=>el.cicloId === cicloId);
      current.filtro= value;
      setInvernaderosMulticiclo(copia);
    }
    
    return (
        <ContenedorPrincipal extra="chart-page">
            <ContenedorHeader titulo = {"Ingresos brutos"} iconos = {"costos-icon"}  atras = {atras}/>
            <SeccionFiltros>                
              <div className="p-grid p-col-12">    
                <ContenedorFiltro label = "Productos">
                  <MultiSelect options ={productos} value = {filtroProductos} optionLabel="nombre" dataKey="id" 
                    onChange = {(e)=>setFiltroProductos(e.value)} 
                    selectedItemsLabel={`${filtroProductos.length} productos seleccionados`}/>
                </ContenedorFiltro>   
                <ContenedorFiltro label = "datos">
                  <SelectButton optionLabel="name" optionValue="code" value = {tipo} options = {tipos} onChange={e=>setTipo(e.value)} multiple={true} />
                </ContenedorFiltro>
                <ContenedorFiltro label = "Acumulado">
                  <ToggleButton checked = {isAcumulado} onChange= {e=>setIsAcumulado(e.value)} onLabel = "Si" offLabel="No"/>
                </ContenedorFiltro>
              </div>
              <div className="p-grid p-col-12">   
              <ContenedorFiltro  filtrolabel = "no"  label = "Tipo de gráfica">
                <ToggleButton checked = {esMulticiclo} onChange= {e=>setEsMulticiclo(e.value)} onLabel= {"Multiciclo"} offLabel= {"Por ciclo actual"} />
                </ContenedorFiltro>  
                {esMulticiclo?
                  <ContenedorFiltro  label = "Ciclos a comparar" filtrolabel = "no"   >
                    <MultiSelect options = {ciclos} value = {filtroCiclos} 
                    onChange={e=>setFiltroCiclos(e.value)}  dataKey="id" optionLabel="nombre"
                    selectedItemsLabel={`${filtroCiclos.length} ciclos seleccionados`}/> 
                  </ContenedorFiltro>
                  :
                  null
                }  
                {esMulticiclo?
                  <React.Fragment>
                    {invernaderosMulticiclo.map(el=>{
                        return <ContenedorFiltro label ={`Ciclo ${el.nombreCiclo}`}>
                      <MultiSelect value = {el.filtro} options = {el.opcionesInvernaderos} dataKey="id" optionLabel="nombre"
                        onChange={(e)=>handleFiltroMulticiclo(el.cicloId, e.value)} 
                        selectedItemsLabel={`${el.opcionesInvernaderos.length} invernaderos seleccionados`}/>
                        </ContenedorFiltro>
                    })}
                  </React.Fragment>
                  :
                  <ContenedorFiltro label = "Invernaderos">
                    <MultiSelect options ={invernaderos} value = {filtroInvernaderos} optionLabel="nombre" dataKey="id" 
                      onChange = {(e)=>setFiltroInvernaderos(e.value)} 
                      selectedItemsLabel={`${filtroInvernaderos.length} invernaderos seleccionados`}/>
                  </ContenedorFiltro>
                }
              </div>

            </SeccionFiltros>
            <div className = "p-col-12 chart-container-filters">

              <Chart series = {seriesFiltradas} options ={options} height="100%"/>
            
            </div>
        </ContenedorPrincipal>
    )
}
