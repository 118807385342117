import React from 'react';
import {InputText} from "primereact/inputtext";
import {SelectButton} from 'primereact/selectbutton';
import {InputSwitch} from 'primereact/inputswitch';
import {Calendar} from 'primereact/calendar';
import {Dropdown} from 'primereact/dropdown';
import {Button} from 'primereact/button';
import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';


function RegistrarForm() {

    
    const pantalla = {edition: false};

    const options2 = [
        {label: 'Opción', value: '1'},
        {label: 'Opción', value: '2'},
    ];
    const options3 = [
        {label: 'Opción', value: '1'},
        {label: 'Opción', value: '2'},
        {label: 'Opción', value: '3'},
    ];
   const options4 = [
        {label: 'Opción', value: '1'},
        {label: 'Opción', value: '2'},
        {label: 'Opción', value: '3'},
        {label: 'Opción', value: '4'},
    ];

    const state= {
       
        detalles:[
            {dato:"12345",dato2:"ABC",dato3:"Nombre Apellido", dato4: "Habilitado",dato5:"15-10-19"},
            {dato:"12345",dato2:"ABC",dato3:"Nombre Apellido", dato4: "Habilitado",dato5:"15-10-19"},
            {dato:"12345",dato2:"ABC",dato3:"Nombre Apellido", dato4: "Habilitado",dato5:"15-10-19"},
            {dato:"12345",dato2:"ABC",dato3:"Nombre Apellido", dato4: "Habilitado",dato5:"15-10-19"},
            {dato:"12345",dato2:"ABC",dato3:"Nombre Apellido", dato4: "Habilitado",dato5:"15-10-19"},

        ]

    };

    return (
      <div className="p-grid p-justify-center">
        <div className="p-col-12 title-container">
          <span className="icon-general "></span>
          <span className="titles-group">
            {pantalla.edition ?
              <React.Fragment>
                <h1 className="header-title">
                  Modificar elemento
                </h1>
                <p className="header-subtitle">Elemento deshabilitado</p>
              </React.Fragment>
            :
              <h1 className="header-title">
                Crear elemento
              </h1>
            }

          </span>
        </div>
        <div className="p-col-10">
          <div className="p-grid p-fluid ">
            <div className="p-col-12">
              <p className="section-title">Título de sección</p>

              <div className="card">
                <div className="p-grid">
                  <div className="form-group p-col-12 p-md-6">
                      <label htmlFor="uno">Etiqueta</label>
                      <InputText id="uno"/>
                  </div>
                  
                  <div className="form-group p-col-12 p-md-6 select-2">
                      <label htmlFor="dos">Etiqueta</label>
                      <SelectButton id="dos" options={options2}/>
                  </div>
                  <div className="form-group p-col-12 p-md-6 select-3">
                      <label htmlFor="tres">Etiqueta</label>
                      <SelectButton id="tres" options={options3}/>
                  </div>
                  <div className="form-group p-col-12 p-md-6 select-4">
                      <label htmlFor="cuatro">Etiqueta</label>
                      <SelectButton id="cuatro" options={options4}/>
                  </div>
                  <div className="form-group p-col-12 p-md-6 ">
                      <label htmlFor="fecha">Fecha</label>
                      <Calendar id="fecha" />
                  </div>
                  <div className="form-group p-col-12 p-md-6">
                      <label htmlFor="cinco">Etiqueta</label>
                      <Dropdown id="cinco" placeholder="Seleccionar"
                        options={options4}
                        />
                  </div>
                  <div className="form-group p-col-12 p-md-6">
                      <label htmlFor="seis">Etiqueta</label>
                      <div className="switch-container">
                        <InputSwitch onLabel = "" offLabel = "" name = "seis"/>
                        <span>etiqueta</span>
                      </div>
                  </div>

                  
                </div>
              </div>
            </div>
            <div className="p-col-12">
              <p className="section-title">Tabla de columna de etiquetas y total</p>
              <div className="card p-0">
                <DataTable value = {state.detalles} className="">
                  <Column field = "dato" header = "Número" className="col__label"/>
                  <Column field = "dato2" header = "Letras" />
                  <Column field = "dato3" header = "Texto" />
                  <Column field = "dato4" header = "Status" />
                  <Column field = "dato5" header = "Fecha" className="col__total"/>
                
                </DataTable>
              </div>
            </div>
            <div className="p-col-12">
              <p className="section-title">Tabla con fila de total</p>
              <div className="card p-0">
                <DataTable value = {state.detalles} className="table-total-1">
                  <Column field = "dato" header = "Número" />
                  <Column field = "dato2" header = "Letras" />
                  <Column field = "dato3" header = "Texto" />
                  <Column field = "dato4" header = "Status" />
                  <Column field = "dato5" header = "Fecha" />
                
                </DataTable>
              </div>
            </div>
            <div className="p-col-12">
              <p className="section-title">Tabla con 2 filas de total</p>
              <div className="card p-0">
                <DataTable value = {state.detalles} className="table-total-2">
                  <Column field = "dato" header = "Número" />
                  <Column field = "dato2" header = "Letras" />
                  <Column field = "dato3" header = "Texto" />
                  <Column field = "dato4" header = "Status" />
                  <Column field = "dato5" header = "Fecha" />
                
                </DataTable>
              </div>
            </div>
            <div className="p-col-12">
              <p className="section-title">Tabla con 3 filas de total</p>
              <div className="card p-0">
                <DataTable value = {state.detalles} className="table-total-3">
                  <Column field = "dato" header = "Número" />
                  <Column field = "dato2" header = "Letras" />
                  <Column field = "dato3" header = "Texto" />
                  <Column field = "dato4" header = "Status" />
                  <Column field = "dato5" header = "Fecha" />
                
                </DataTable>
              </div>
            </div>
          </div>

          <div className="p-grid p-justify-end btn-row">
                    <Button label="Cancelar" className="p-button-secondary"  />
                    {pantalla.edition ?
                      <Button label="Deshabilitar" />
                      :
                      null
                    }
                    <Button label="Guardar" />
          </div>
        </div>

      </div>
    );
}


export default RegistrarForm;
