import React from 'react'
import { Button } from 'primereact/button';

const Tooltip = ({ title = "", content = "", tooltipLabel = "?", className = "" }) => {
  const tooltipInfo = `${title && `<p class='tooltip-title'>${title}</p>`}
                     <p class='tooltip-def'></p> 
                     <div>${content}</div>`;
  return (
    <div style={{ display: "inline" }} className={className}>
      <Button type="button" label={tooltipLabel} className="btn-line btn-help" tooltip={tooltipInfo} />
    </div>)
}
export default Tooltip;