import React, { useState, useEffect, useGlobal } from 'reactn';
import InvernaderoExternoCard from "./InvernaderoExternoCard";
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { DeepClone, dosDecimales } from '../../util/functions';
import { getProveedoresInvernadero, } from '../../service/Queries/Proveedores';
import { obtenerPronosticoYProyeccionProveedorExterna } from '../../service/Pronosticos';
import lortIncon from "../../images/icons/external-icon.svg"

const ProveedoresExternosCards = (props) => {
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [productosYVariedades] = useGlobal("productosYVariedades")
    const [ciclo,] = useGlobal("ciclo");
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [, setCargando] = useGlobal("cargando");
    const [, setMostrarModalPermiso] = useGlobal("mostrarModalPermiso");

    const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector();
    const [, setProveedores] = useGlobal("proveedores");
    const [invernadero] = useGlobal("invernadero");
    const [proveedoresCardsInfo, setProveedoresCardsInfo] = useState([])
    useEffect(() => {
        if (!invernadero) props.history.goBack()
        setNombreModulo("Pronósticos proveedores externos");
        setHabilitadoSelectorCiclo(true)
    }, [])
    useEffect(() => {
        setCargando(true)
        if (ciclo && week?.nombre != "Cargando" && invernadero)
            obtenerDatosCards();
    }, [ciclo, week, invernadero])

    const obtenerDatosCards = async () => {
        try {
            let listaProveedores = await getProveedoresInvernadero(ciclo, invernadero.id)
            setProveedores(listaProveedores)

            let promises = listaProveedores.map(proveedor => obtenerResumenExterno(proveedor))
            let proveedoresCardsData = await Promise.all(promises)
            let proveedoraCopia = DeepClone(listaProveedores)

            for (let x = 0; x < proveedoresCardsData.length; x++) {
                if (!proveedoresCardsData[x]) proveedoraCopia[x] = false;
                else {
                    let [invId, producto, variedad, semanaUno, semanaTres, semanaCinco, proyeccion, proveedor] = proveedoresCardsData[x];
                    let proveedorCard = proveedoraCopia.find(el => el.id === proveedor.id);

                    proveedorCard.variedad = variedad;
                    proveedorCard.producto = producto;
                    proveedorCard.semanaUno = semanaUno;
                    proveedorCard.semanaTres = semanaTres;
                    proveedorCard.semanaCinco = semanaCinco;
                    proveedorCard.proyeccion = proyeccion;
                    proveedorCard.proveedor = proveedor
                }
            }
            proveedoraCopia = proveedoraCopia.filter(proveedorData => !!proveedorData); //Quitar los datos que tienen 'false'
            setProveedoresCardsInfo(proveedoraCopia)
        } catch (err) {
            console.log(err)
            if (err.code && err.code === "permission-denied") {
                console.log(err.code);
                setMostrarModalPermiso(true);
            }
        }

        setCargando(false);
    }
    const obtenerResumenExterno = async (proveedor) => {
        let pronosticoProyeccion = await obtenerPronosticoYProyeccionProveedorExterna(ciclo, invernadero, week.time, proveedor);
        if (!pronosticoProyeccion) { return false; }//checar si hay datos, y esconder si no hay

        let producto = productosYVariedades.find(prod => prod.id === invernadero.producto_ref)
        let variedad = producto?.variedades.find(vari => vari.id === invernadero.variedad_ref) || {}
        let semanaUno = dosDecimales(pronosticoProyeccion["semanaUno"] / parseInt(proveedor.area_invernadero))
        return [invernadero.id, producto, variedad, semanaUno, pronosticoProyeccion.semanaTres, pronosticoProyeccion.semanaCinco, pronosticoProyeccion.proyeccion, proveedor];
    }

    return (
        <div className="p-grid">
            <div className="p-col-12 title-container">
                <span className="back-btn" onClick={props.history.goBack}></span>
                <span className="icon-id icon-general external-icon bor-main-blue bg-main-blue">
                    <img width="60" alt="icono" src={lortIncon} /> 
                </span>
                <span className="titles-group">
                    <h1 className="header-title">Pronósticos proveedores externos</h1>
                </span>
            </div>
            <div className="p-col-12">
                <div className="p-grid filtros">
                    <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
                </div>
            </div>

            <div className="p-col-12">
                <div className="p-grid">
                    {proveedoresCardsInfo?.length > 0
                        ? proveedoresCardsInfo.map(info => {
                            return (<InvernaderoExternoCard key={info.proveedor.id} info={info} invernadero={invernadero} proveedor={info.proveedor} />)
                        })
                        : <p className="p-col-12 section-title">No hay registros de pronóstico para esta semana</p>}
                </div>
            </div>
        </div>)
}
export default ProveedoresExternosCards;