import React, { useState, useEffect, useGlobal } from 'reactn';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { obtenerMatrizComparativa } from '../../../service/Cumplimiento';
import { dosDecimales, getProductoYVariedadString, separadoComas } from '../../../util/functions';
import SeccionFiltros from "../../common/SeccionFiltros";
import ContenedorFiltro from "../../common/ContenedorFiltro";
import { SelectButton } from 'primereact/selectbutton';
import ContenedorTabla from '../../common/ContenedorTabla';
import { Button } from 'primereact/button';
import icono from '../../../images/icons/external-icon.svg';
import { UnidadPorcentajeComponente } from '../../common/templates_unidades/UnidadPorcentaje.atomo';
import { UnidadKg } from '../../common/templates_unidades/UnidadKg.atomo';

const switchOptions = [{ label: "Final", value: "final" }, { label: "Real", value: "real" }];
const MatrizComparativa = (props) => {
    const [matriz, setMatriz] = useState([]);
    const [tipoSwitch, setTipoSwitch] = useState(switchOptions[0].value);

    useEffect(() => {//filtrar datos de la matriz comparativa
        if (props.invernaderos && props.matrizDelProducto) { filtrarDatosMatriz(); }
    }, [props.invernaderos, props.matrizDelProducto])

    const filtrarDatosMatriz = async () => {
        const matrizFiltrado = props.matrizDelProducto.filter(invernadero => props.invernaderos.some(el => el.id === invernadero.id));
        setMatriz(matrizFiltrado);
    }

    ///////////////////MATRIZ COMPARATIVA//////////////////////////
    const obtenerColumnasMatriz = () => {
        const columnaNombre = <Column key={`mat-nombre`} field="nombre" className="col__label" body={templateIconoInverandero} style={{ width: '15%' }} />;
        const columnasInvernaderos = props.invernaderos?.map((inv, ind) => <Column key={`mat-${ind}`} field={inv.id} header={templateIconoInverandero(inv)} body={templateCelda} />) || []
        return [columnaNombre, ...columnasInvernaderos];
    }
    const templateIconoInverandero = (invernadero) => {
        return (
            <Button className="invisible-button" tooltip={getProductoYVariedadString(invernadero)}>
                <div className={`icon-general icon-mini icon-lort icon-click bor-${invernadero.color} bg-${invernadero.color}`}>
                    {invernadero.es_externo ? <img width="30" alt="icono" src={icono} /> : invernadero.nombre}
                </div>
            </Button>);
    }
    const templateCelda = (rowData, column) => {//Mover a carpeta de templates? O dejar aqui, porque solo se use en este lugar
        const data = rowData?.[column.field] || {};
        const tipoRenglon = tipoSwitch === "real" ? "renglonReal" : "renglonFinal";
        const tipoColumna = tipoSwitch === "real" ? "columnaReal" : "columnaFinal";
        const dataRenglon = data?.[tipoRenglon] || 0, dataColumna = data?.[tipoColumna] || 0;

        if (data && rowData?.id !== column?.field) {//no mostrar comparación para los mismos invernaderos (ej. no comparar F1 con F1)
            const total = dataRenglon + dataColumna;
            const porcentajeRenglon = total === 0 ? 0 : Math.round(100 * dataRenglon / total)
            const porcentajeColumna = total === 0 ? 0 : Math.round(100 * dataColumna / total)
            return (
                <div className="matriz-comparativa">
                    <div className="matriz-dato">
                        <p className="value m-0"><UnidadKg valor={separadoComas(dosDecimales(dataColumna))} /></p>
                        <p className="value m-0"><UnidadPorcentajeComponente valor={porcentajeColumna} /></p>
                    </div>
                    <div className="matriz-dato">
                        <p className="value m-0"><UnidadPorcentajeComponente valor={porcentajeRenglon} /></p>
                        <p className="value m-0"><UnidadKg valor={separadoComas(dosDecimales(dataRenglon))} /></p>
                    </div>
                </div>)
        } else return "";
    }
    ///////////////END MATRIZ COMPARATIVA ///////////////////////

    return (
        <React.Fragment>
            <SeccionFiltros>
                <ContenedorFiltro value="tipo">
                    <SelectButton options={switchOptions} value={tipoSwitch} onChange={e => setTipoSwitch(e.value)} />
                </ContenedorFiltro>
            </SeccionFiltros>

            <ContenedorTabla titulo="Matriz comparativa">
                <DataTable value={matriz}>
                    {obtenerColumnasMatriz()}
                </DataTable>
            </ContenedorTabla>
        </React.Fragment>)
}
export default MatrizComparativa;