import React, { useEffect, useState } from 'react';
import { useGlobal, setGlobal, getGlobal } from 'reactn';
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { obtenerResumenCardRH, obtenerResumenCardNomina } from '../../service/RhService';
import CardRH from '../rh/InvernaderoCard';
import CardProduccion from '../nomina/InvernaderoCard';
import CardCultivo from '../cultivo/InvernaderoCard';
import CardEmpaque from '../producto/InvernaderoCard';
import CardCosecha from '../cosecha/InvernaderoCard';
import CardCalidad from '../calidad/InvernaderoCard';
import CardBiocontrol from '../biocontrol/InvernaderoBiocontrolCard';

import { obtenerResumenCardCultivo } from '../../service/Cultivo';
import { obtenerResumenCardEmpaque } from '../../service/ReportesProducto';
import { obtenerResumenCardCosecha } from '../../service/Cosecha';
import { obtenerResumenCardCalidad } from '../../service/Calidad';
import { obtenerResumenEnfermedadesCardBiocontrol } from '../../service/biocontrol';
import { withRouter } from 'react-router-dom';
import { getProductoYVariedadString } from '../../util/functions';

setGlobal({ datosHomeInvernaderos: {} })
const PorInvernadero = (props) => {
  const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector();
  const [ciclo] = useGlobal("ciclo");
  const [cargandoHome, setCargandoHome] = useGlobal("cargandoHome");
  const [, setDatosHomeInvernaderos] = useGlobal("datosHomeInvernaderos");
  const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");

  const [dataCardRH, setDataCardRH] = useState();
  const [dataCardProduccion, setDataCardProduccion] = useState();
  const [dataCardEmpaque, setDataCardEmpaque] = useState();
  const [dataCardCultivo, setDataCardCultivo] = useState();
  const [dataCardCalidad, setDataCardCalidad] = useState();
  const [dataCardCosecha, setDataCardCosecha] = useState();
  const [dataCardBiocontrol, setDataCardBiocontrol] = useState();
  const [invernadero, setInvernadero] = useState();
  useEffect(() => {
    setHabilitadoSelectorCiclo(false)
  }, [])
  useEffect(() => {
    setInvernadero(props.invernadero)
  }, [props.invernadero])
  useEffect(() => {
    if (invernadero && week.nombre !== "Cargando") obtenerResumenes()
  }, [ciclo, invernadero, week])

/*  useEffect(() => {
    console.log(invernadero.nombre)
    if (dataCardRH?.cargando && dataCardProduccion?.cargando && dataCardEmpaque?.cargando &&dataCardCultivo?.cargando&& dataCardCalidad?.cargando && dataCardCosecha?.cargando && dataCardBiocontrol.cargando)
      obtenerResumenes();
  }, [dataCardRH, dataCardProduccion, dataCardEmpaque, dataCardCultivo, dataCardCalidad, dataCardCosecha, dataCardBiocontrol])
*/
  const obtenerResumenes = async () => {
    console.log(invernadero.nombre)
    setCargandoHome(true)

    setDataCardRH({ ...invernadero, cargando:true });
    setDataCardProduccion({ ...invernadero, cargando:true });
    setDataCardEmpaque({ ...invernadero, cargando:true });
    setDataCardCultivo({ ...invernadero, cargando:true });
    setDataCardCalidad({ invernadero, cargando:true });
    setDataCardCosecha({ ...invernadero, cargando:true });
    setDataCardBiocontrol({ ...invernadero, cargando:true });

    await Promise.all([
      obtenerResumenHomeCardRH(),
      obtenerResumenHomeCardNomina(),
      obtenerResumenHomeCardEmpaque(),
      obtenerResumenHomeCardCultivo(),
      obtenerResumenHomeCardCosecha(),
      obtenerResumenHomeCardCalidad(),
      obtenerResumenEnfermedadesHomeCardBiocontrol(),
    ]);
    setCargandoHome(false)
  }

  const getDatosGlobalesInvernadero = (nombreModulo) => getGlobal().datosHomeInvernaderos?.[ciclo]?.[invernadero.id]?.[week.time]?.[nombreModulo];

  const setDatosGlobalesInvernadero = (nombreModulo, datosModulo) => {
    const datosHomeInvernaderos = getGlobal().datosHomeInvernaderos || {};
    const invId = invernadero.id, time = week.time;

    const datos = { ...datosHomeInvernaderos };
    datos[ciclo] = { ...datos[ciclo] };
    datos[ciclo][invId] = { ...datos[ciclo][invId] };
    datos[ciclo][invId][time] = { ...datos[ciclo][invId][time] };
    datos[ciclo][invId][time][nombreModulo] = { ...datosModulo };

    setDatosHomeInvernaderos(datos);
  }

  const obtenerResumenHomeCardRH = async () => {
    const datosGuardados = getDatosGlobalesInvernadero("datosCardRH");
    const result = datosGuardados || await obtenerResumenCardRH(ciclo, invernadero, week.time);
    setDataCardRH(result);
    if (!datosGuardados) setDatosGlobalesInvernadero("datosCardRH", result);
  }

  const obtenerResumenHomeCardNomina = async () => {
    const datosGuardados = getDatosGlobalesInvernadero("datosCardNomina");
    const result = datosGuardados || await obtenerResumenCardNomina(ciclo, invernadero, week.time);

    setDataCardProduccion(result);
    if (!datosGuardados) setDatosGlobalesInvernadero("datosCardNomina", result);
  }

  const obtenerResumenHomeCardEmpaque = async () => {
    const datosGuardados = getDatosGlobalesInvernadero("datosCardEmpaque");
    const result = datosGuardados || await obtenerResumenCardEmpaque(ciclo, invernadero, week.time);

    setDataCardEmpaque(result);
    if (!datosGuardados) setDatosGlobalesInvernadero("datosCardEmpaque", result);
  }

  const obtenerResumenHomeCardCultivo = async () => {
    const datosGuardados = getDatosGlobalesInvernadero("datosCardCultivo");
    const result = datosGuardados || await obtenerResumenCardCultivo(ciclo, invernadero, week.time)

    setDataCardCultivo(result);
    if (!datosGuardados) setDatosGlobalesInvernadero("datosCardCultivo", result);
  }

  const obtenerResumenHomeCardCalidad = async () => {
    const datosGuardados = getDatosGlobalesInvernadero("datosCardCalidad");
    const { pesoCaja, pesoFruto, defectos } = datosGuardados || await obtenerResumenCardCalidad(ciclo, invernadero, week.time);
    const productoYVariedadString = getProductoYVariedadString(invernadero);
    const result = { invernadero, pesoCaja, pesoFruto, defectos, productoYVariedadString }

    setDataCardCalidad(result);
    if (!datosGuardados) setDatosGlobalesInvernadero("datosCardCalidad", result);
  }

  const obtenerResumenHomeCardCosecha = async () => {
    const datosGuardados = getDatosGlobalesInvernadero("datosCardCosecha");
    const result = datosGuardados || await obtenerResumenCardCosecha(ciclo, invernadero, week.time)

    setDataCardCosecha(result);
    if (!datosGuardados) setDatosGlobalesInvernadero("datosCardCosecha", result);
  }
  const obtenerResumenEnfermedadesHomeCardBiocontrol = async () => {
    const datosGuardados = getDatosGlobalesInvernadero("datosCardBiocontrol");
    const result = datosGuardados || await obtenerResumenEnfermedadesCardBiocontrol(ciclo, invernadero, week.time)

    setDataCardBiocontrol(result);
    if (!datosGuardados) setDatosGlobalesInvernadero("datosCardBiocontrol", result);
  }

  const irCard = (data) => {
    let mapeo = {
      RH: "/consultar_empleados",
      PRODUCCION: "/consultar_nomina",
      CULTIVO: "/cultivo/registro",
      BIOCONTROL: "/biocontrol/cards",
      COSECHA: "/cosecha/consultar",
      CALIDAD: "/calidad/semanal",
      EMPAQUE: "/producto/registrar_empaque"
    }
    props.history.push(mapeo[data.modulo])
  }

  return (
    <div className="p-grid home-invernadero">
      <div className="p-col-12">
        <div className="p-grid filtros">
          <WeekSelector disabled={cargandoHome} weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
        </div>
      </div>
      <div className="p-col-12 card-invernadero p-md-6 no-header ">
        <div className="card-modulo-header">
          <div className={`icon-general user-icon bor-${invernadero?.color} `}></div>
          <div><p className="card-up"></p><p className="card-down">Recursos humanos</p></div>
        </div>
        <CardRH home cargandoHome={dataCardRH?.cargando} invernadero={dataCardRH} onClick={() => irCard({ modulo: "RH", invernadero: dataCardRH.id })} />
      </div>

      <div className="p-col-12 card-invernadero p-md-6 no-header ">
        <div className="card-modulo-header">
          <div className={`icon-general activity-icon bor-${invernadero?.color} `}></div>
          <div><p className="card-up"></p><p className="card-down">Producción</p></div>
        </div>
        <CardProduccion home cargandoHome={dataCardProduccion?.cargando} invernadero={dataCardProduccion} onClick={() => irCard({ modulo: "PRODUCCION", invernadero: dataCardRH.id })} />
      </div>

      <div className="p-col-12 card-invernadero p-md-6 no-header ">
        <div className="card-modulo-header">
          <div className={`icon-general boxes-icon bor-${invernadero?.color} `}></div>
          <div><p className="card-up"></p><p className="card-down">Empaque</p></div>
        </div>
        <CardEmpaque home cargandoHome={dataCardEmpaque?.cargando} invernadero={dataCardEmpaque} onClick={() => irCard({ modulo: "EMPAQUE", invernadero: dataCardRH.id })} />
      </div>

      <div className="p-col-12 card-invernadero p-md-6 no-header ">
        <div className="card-modulo-header">
          <div className={`icon-general plant-icon bor-${invernadero?.color} `}></div>
          <div><p className="card-up"></p><p className="card-down">Cultivo</p></div>
        </div>
        <CardCultivo home cargandoHome={dataCardCultivo?.cargando} invernadero={dataCardCultivo} onClick={() => irCard({ modulo: "CULTIVO", invernadero: dataCardRH.id })} />
      </div>

      <div className="p-col-12 card-invernadero p-md-6 no-header ">
        <div className="card-modulo-header">
          <div className={`icon-general quality-icon bor-${invernadero?.color} `}></div>
          <div><p className="card-up"></p><p className="card-down">Calidad</p></div>
        </div>
        <CardCalidad key={dataCardCalidad?.invernadero?.nombre + "-home"} home cargandoHome={dataCardCalidad?.cargando} info={dataCardCalidad} invernadero={dataCardCalidad?.invernadero} onClick={() => irCard({ modulo: "CALIDAD", invernadero: dataCardRH.id })} />
      </div>

      <div className="p-col-12 card-invernadero p-md-6 no-header ">
        <div className="card-modulo-header">
          <div className={`icon-general product-icon bor-${invernadero?.color} `}></div>
          <div><p className="card-up"></p><p className="card-down">Cosecha</p></div>
        </div>
        <CardCosecha home cargandoHome={dataCardCosecha?.cargando} invernadero={dataCardCosecha} onClick={() => irCard({ modulo: "COSECHA", invernadero: dataCardRH.id })} />
      </div>

      <div className="p-col-12 card-invernadero p-md-6  ">
        <div className="card-modulo-header">
          <div className={`icon-general no-icon `}></div>
          <div><p className="card-up"></p><p className="card-down">Biocontrol</p></div>
        </div>
        <CardBiocontrol home cargandoHome={dataCardBiocontrol?.cargando} info={dataCardBiocontrol} invernadero={dataCardBiocontrol?.invernadero} onClick={() => irCard({ modulo: "BIOCONTROL", invernadero: dataCardRH.id })} />
      </div>
    </div>);
}
export default withRouter(PorInvernadero)//216