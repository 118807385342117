import * as firebase from 'firebase/app'
import { getGlobal } from 'reactn';
import { nombresDiasSemanaPlanos } from '../constants';
import { obtenerEmpacadoras } from './Empacadoras';
import { obtenerEmpaqueCiclo, obtenerRolesEmpaque } from './Empaques';
import { obtenerInvernaderosVirtuales } from './Invernaderos';

import { obtenerArchivosCargados } from './NominaEmpaqueQueries';
import { getCategorias, obtenerPresentaciones } from './Presentaciones';
import { obtenerEmpleados } from './RhService';
import { obtenerDesviacionesPesos } from './ReporteCalidad';
import { uploadStatistics } from './Estadisticas'

function registrarInvocaciones(count) {
    uploadStatistics(count)
}

export const PONDERADO_MINIMO_CALIDAD = 0.5;
const nombreCodigoRolEmpacador = "empacador";
export const cargarExcel = (ciclo, dia, nombreArchivo, archivo, empaque) => {
    return new Promise(async (resolve, reject) => {
        const cargarExcelFunc = firebase.functions().httpsCallable("cargarExcel");

        const reader = new FileReader();
        reader.readAsDataURL(archivo);
        reader.onload = async () => {
            let result = await cargarExcelFunc({ archivo: reader.result, empaque, ciclo, dia, nombreArchivo }).catch(err => { resolve({ success: false }) });
            resolve({ ...result, success: true });
        }
        reader.onerror = () => {
            resolve({ success: false })
        }
    })
}

export const obtenerResumenNominaEmpaqueMultiproducto = async (cicloId, semanaTime, empaqueCiclo, productos, todosProductos) => {
    let empacadoras = await obtenerEmpacadoras();
    // let promises = todosProductos.map(producto=>obtenerArchivosCargados(cicloId, semanaTime,empaqueCiclo.id, producto.id));
    let archivos = await obtenerArchivosCargados(cicloId, semanaTime, empaqueCiclo.id)
    archivos = archivos.flat();
    let empleados = await obtenerEmpleadosEmpaqueConIncidencias(cicloId, empaqueCiclo.empaque_ref, semanaTime, empacadoras);
    let presentaciones = await obtenerPresentaciones();
    presentaciones = presentaciones.filter(el => empaqueCiclo.productos_relacionados.includes(el.producto_ref))
    //deben filtrarse solo empleados de tipo empacador
    let roles = await obtenerRolesEmpaque();

    let resultado = await procesarArchivosNominaEmpaqueMultiproducto(empleados, presentaciones, archivos, roles, empaqueCiclo["bonos_fijos"], empacadoras, cicloId, semanaTime, productos, todosProductos);
    return resultado;
}
const procesarArchivosNominaEmpaqueMultiproducto = async (empleados, presentaciones, archivos, roles, bonosFijos, empacadoras, cicloId, semanaTime, productos, todosProductos) => {
    let copiaEmpleados = empleados.slice();
    if (productos.length === 0) { return empleados; }

    let promises = productos.map((producto, index) => {
        return new Promise(async (resolve, reject) => {
            let presentacionesFiltradas = presentaciones.filter(el => el.producto_ref === producto.id);
            let resultado = await procesarArchivosNominaEmpaque(empleados, presentacionesFiltradas, archivos, roles, bonosFijos, empacadoras, cicloId, semanaTime, archivos, todosProductos);
            resolve(resultado);
        })
    })

    let resultados = await Promise.all(promises);
    copiaEmpleados.forEach((empleado) => {
        let bonoTotal = 0, cuentaTotal = 0;
        let rol = roles.find(el => el.id === empleado["rol_ref"]);
        let rolFijo = false;

        if (rol) {
            if (rol["nombre"] !== "Empacador") {
                rolFijo = true;
                empleado["empacadora"] = rol["nombre"];
            }

            resultados.forEach((resumen, index) => {
                let empleadoEncontrado = resumen.find(el => el.id === empleado.id);
                if ((rolFijo && index === 0) || !rolFijo) {
                    bonoTotal += empleadoEncontrado["bonos"]["bono_total"]
                    cuentaTotal += empleadoEncontrado["bonos"]["cuenta_total"];//No se usa este dato al momento
                }
                empleado["bonos"] = { ...empleado["bonos"], ...empleadoEncontrado["bonos"] }
            })

            empleado["bonos"]["bono_total"] = bonoTotal;
            empleado["bonos"]["cuenta_total"] = cuentaTotal;

        } else {
            empleado["bonos"] = {};
            empleado["bonos"]["bono_total"] = "NA";
            empleado["bonos"]["cuenta_total"] = "NA";
            empleado["empacadora"] = "SIN";
        }
    })
    return copiaEmpleados
}


export const obtenerResumenNominaEmpaque = async (cicloId, semanaTime, empaqueCiclo, productoId) => {
    let empacadoras = await obtenerEmpacadoras();
    let archivos = await obtenerArchivosCargados(cicloId, semanaTime, empaqueCiclo.id, productoId);
    let empleados = await obtenerEmpleadosEmpaqueConIncidencias(cicloId, empaqueCiclo.empaque_ref, semanaTime, empacadoras);
    let presentaciones = await obtenerPresentaciones();
    //deben filtrarse solo empleados de tipo empacador
    let roles = await obtenerRolesEmpaque();
    presentaciones = presentaciones.filter(el => el.producto_ref === productoId);
    let resultado = await procesarArchivosNominaEmpaque(empleados, presentaciones, archivos, roles, empaqueCiclo["bonos_fijos"], empacadoras, cicloId, semanaTime);
    return resultado;
}
export const obtenerEmpleadosEmpaqueConIncidencias = async (cicloId, empaqueId, semanaTime, empacadoras) => {
    try {
        let empleados = await obtenerEmpleadosEmpaqueCiclo(cicloId, empaqueId);
        let empaqueCiclo = await obtenerEmpaqueCiclo(cicloId, empaqueId);
        let incidenciasPromises = empleados.map(async empleado => {
            return obtenerIncidenciasSemana(cicloId, empaqueCiclo.id, empleado.id, semanaTime);
        })
        let incidencias = await Promise.all(incidenciasPromises);
        empleados.forEach((empleado, index) => {
            let empacadora = empacadoras.find(el => el.id === empleado["codigo_empacadora"]);
            if (empacadora) {
                empleado["empacadora"] = empacadora["nombre"];
            }
            empleado["incidencias"] = incidencias[index];
        })
        return empleados;
    } catch (error) {
        throw error;
    }

}
const obtenerIncidenciasSemana = async (cicloId, empaqueId, empleadoId, semana) => {

    const db = firebase.firestore();
    let snap = await db.collection("ciclos").doc(cicloId).collection("empaques").doc(empaqueId).
        collection("empleados").doc(empleadoId).collection("incidencias").where("semana", "==", semana).get();
    registrarInvocaciones(snap.size)
    // (semana);
    if (snap.empty) {
        return {
            cumple_inocuidad: true, dias: {
                lunes: "asistencia",
                martes: "asistencia",
                miercoles: "asistencia",
                jueves: "asistencia",
                viernes: "asistencia",
                sabado: "asistencia",
            }
        }
    } else {
        let data = snap.docs[0].data();
        return data;
    }
}

export const obtenerEmpleadosEmpaqueCiclo = async (cicloId, empaqueId) => {
    const db = firebase.firestore();
    let empaque = await obtenerEmpaqueCiclo(cicloId, empaqueId);
    if (!empaque?.id) { return [] }
    let empleados = await obtenerEmpleados();
    let empleadosRefsSnap = await db.collection("ciclos").doc(cicloId).collection("empaques").doc(empaque.id).collection("empleados").get();
    registrarInvocaciones(empleadosRefsSnap.size)
    let empleadosRefs = empleadosRefsSnap.docs.map(el => ({ id: el.id, ...el.data() }));
    empleadosRefs.forEach(empleadoRef => {
        let empleadoData = empleados.find(el => el.id === empleadoRef.empleado_ref);
        if (empleadoData?.id) { delete empleadoData.id; }
        Object.assign(empleadoRef, empleadoData);
        empleadoRef.nombreCompleto = `${empleadoData?.nombre} ${empleadoData?.apellidos}`;
    })
    return empleadosRefs;

}
export const obtenerHorasTrabajadasEmpaque = async (empaqueObj) => {
    return 0;
}

const procesarArchivosNominaEmpaque = async (empleados, presentaciones, archivos, roles, bonosRoles, empacadoras, cicloId, semana) => {
    let result = empleados.map(el => ({ ...el }));

    let promises = empleados.map(empleado => {
        return calcularBonosEmpleado(empleado, presentaciones, archivos, roles, bonosRoles, empacadoras, cicloId, semana);
    })
    let values = await Promise.all(promises);
    values.forEach((value, index) => {
        result[index]["bonos"] = value;

    })
    values.forEach((value, index) => {
        let rol = roles.find(el => el.id === result[index]["rol_ref"]);
        if (rol && rol["nombre"] !== "Empacador") {
            result[index]["empacadora"] = rol["nombre"]
        }

    })
    return result;
}
const calcularBonosEmpleado = async (empleado, presentaciones, archivos, roles, bonosRoles, empacadoras, cicloId, semana) => {
    let rol = roles.find(el => el.id === empleado["rol_ref"]);
    if (rol) {
        if (rol["nombre"] === "Empacador") {
            let bono = await calcularBonoEmpacador(empleado, presentaciones, archivos, empacadoras, cicloId, semana)
            return bono;
        } else {
            return calcularBonoNoEmpacador(empleado, bonosRoles)
        }

    }
}

const getCajasPorCategoria = (categoriasEmpaque = [], cajasEmpacadas, presentacion) => {
    const cajasPorCategoria = {};
    const { id, rangos_bono_empaque = [] } = categoriasEmpaque.find(categoria => categoria.presentaciones?.includes(presentacion?.id)) || {}

    if (id && cajasPorCategoria[id] == null) {
        cajasPorCategoria[id] = { cajasEmpacadas };
        cajasPorCategoria[id].rangos_bono_empaque = rangos_bono_empaque;
    }
    else if (id && !isNaN(parseFloat(cajasPorCategoria[id]?.cajasEmpacadas))) {
        cajasPorCategoria[id].cajasEmpacadas += cajasEmpacadas;
        cajasPorCategoria[id].rangos_bono_empaque = rangos_bono_empaque;
    }
    else if (id) { cajasPorCategoria[id].cajasEmpacadas = 0; }

    return cajasPorCategoria;
}
//const getCuentaCajasPorEmpleadoTotal = (archivos, codigoEmpleado, presentacion) => {}

const calcularBonoEmpacador = async (empleado, presentaciones, archivos, empacadoras, cicloId, semana) => {
    const categoriasEmpaque = await getCategorias();

    let empacadora = empacadoras?.find(el => el.id === empleado.codigo_empacadora) || {};
    let ponderadoCalidad = await obtenerPonderadoCalidad(empleado["codigo_empacadora"], cicloId, semana);
    let cuentaCajasTotal = 0, cajasPorCategoria = {};

    presentaciones.map(presentacion => {
        archivos.forEach(archivo => {
            let cajasEmpacadasLista = archivo.datos?.[presentacion.presentacion];
            if (cajasEmpacadasLista) {
                Object.keys(cajasEmpacadasLista).forEach(codigoEmpleado => {
                    const cajasEmpacadas = isNaN(parseFloat(cajasEmpacadasLista[codigoEmpleado])) ? 0 : parseFloat(cajasEmpacadasLista[codigoEmpleado]);

                    if (codigoEmpleado.toUpperCase() === empacadora.nombre?.toUpperCase()) {
                        //cajasPorCategoria = getCajasPorCategoria(categoriasEmpaque, cajasEmpacadas, presentacion);
                        cuentaCajasTotal += cajasEmpacadas;
                    }
                })
            }
        })
    })
    //let bonoEmpacadoTotal = obtenerBonoEmpacadoPorRangoCategoria(cuentaCajasTotal, cajasPorCategoria);
    let result = {
        bono_total: 0,
        cuenta_total: cuentaCajasTotal
    };
    presentaciones.map(presentacion => {
        let cuentaCajas = 0;
        archivos.forEach(archivo => {
            let cajasEmpacadasLista = archivo.datos?.[presentacion.presentacion];
            if (cajasEmpacadasLista) {
                Object.keys(cajasEmpacadasLista).forEach(codigoEmpleado => {
                    const cajasEmpacadas = isNaN(parseFloat(cajasEmpacadasLista[codigoEmpleado])) ? 0 : parseFloat(cajasEmpacadasLista[codigoEmpleado]);
                    if (codigoEmpleado.toUpperCase() === empacadora.nombre?.toUpperCase()) { cuentaCajas += cajasEmpacadas }
                })
            }
        })

        let bonoMultiplicador = 0;
        if (cuentaCajas > 0) {
            const { rangos_bono_empaque = [] } = categoriasEmpaque?.find(categoria => categoria.presentaciones?.includes(presentacion.id)) || {}
            bonoMultiplicador = obtenerBonoMultiplicador(cuentaCajasTotal, rangos_bono_empaque)
            //console.log("CUENTA TOTAL: " + cuentaCajasTotal + " --- " + bonoMultiplicador)
        }
        let bono = 0, bonoParcial = 0;
        let factorAsistencia = obtenerFactorAsistencia(empleado["incidencias"]);
        bonoParcial = factorAsistencia * (cuentaCajas * bonoMultiplicador);

        if (cumpleInocuidad(empleado["incidencias"])) {
            bono += (0.5 * bonoParcial)
        }
        if (cumpleCalidad(empleado["incidencias"], ponderadoCalidad)) {
            bono += (0.5 * bonoParcial)
        }
        if (!isNaN(bono)) {
            result["bono_total"] += bono;
        }
        result[presentacion.id] = {
            nombrePresentacion: presentacion.presentacion,
            bonoTotal: bono,
            cantidadEmpacada: cuentaCajas
        }

    })
    result["ponderado"] = ponderadoCalidad;
    return result;
}

const calcularBonoNoEmpacador = (empleado, bonosRoles) => {
    let result = {
    };

    let bonoEmpacado = bonosRoles[empleado["rol_ref"]]
    let bono = 0;
    let factorAsistencia = obtenerFactorAsistencia(empleado["incidencias"]);

    bono = factorAsistencia * bonoEmpacado
    if (!cumpleInocuidad(empleado["incidencias"])) {
        bono = (0.5 * bonoEmpacado)
    }
    result = {
        nombrePresentacion: empleado["rol_ref"],
        bono_total: bono,
        cuenta_total: 0
    }

    return result
}
const obtenerFactorAsistencia = (incidencias) => {
    if (!incidencias || !incidencias["dias"]) {
        return 1;
    }
    let faltas = 0;
    nombresDiasSemanaPlanos.forEach(dia => {
        if (incidencias["dias"][dia] === "falta" || incidencias["dias"][dia] === "permiso") {
            faltas++;
        }
    })

    if (faltas == 0) {
        return 1;
    }
    if (faltas == 1) {
        return 0.5;
    }
    if (faltas > 1) {
        return 0;
    }

}
const cumpleInocuidad = (incidencias) => {
    return !incidencias || incidencias["cumple_inocuidad"];
}
const cumpleCalidad = (incidencias, ponderado) => {
    if (incidencias["cumple_calidad"] === undefined) {
        return ponderado > PONDERADO_MINIMO_CALIDAD
    } else {
        return incidencias["cumple_calidad"];
    }
}


const obtenerBonoEmpacadoPorRangoCategoria = (cuentaCajasTotal, cajasPorCategoria = {}) => {
    let bonoFinal = 0;
    for (const categoriaID in cajasPorCategoria) {
        const { rangos_bono_empaque, cajasEmpacadas } = cajasPorCategoria[categoriaID];

        for (const index in rangos_bono_empaque) {
            const { bono, max, min } = rangos_bono_empaque[index];
            if (cuentaCajasTotal > min && cuentaCajasTotal <= max) {
                bonoFinal += (cajasEmpacadas * bono);
                //console.log(`cajas: ${cajasEmpacadas}, bonoX: ${bono}, FINAL: ${bonoFinal}`)
            }
        }
    }
    return bonoFinal
}

const obtenerBonoMultiplicador = (cuenta, rangos) => {
    let bonoMultiplicador = 0;
    for (const index in rangos) {
        const { bono, max, min } = rangos[index];
        if (cuenta > parseFloat(min) && cuenta <= parseFloat(max)) { bonoMultiplicador = bono; }
    }
    return bonoMultiplicador
}

export const actualizarIncidencias = async (cicloId, empaqueId, semana, empleadoId, datos) => {
    try {


        const db = firebase.firestore();
        const empleadoDoc = db.collection("ciclos").doc(cicloId).collection("empaques").doc(empaqueId).collection("empleados").doc(empleadoId);
        const registroSnap = await empleadoDoc.collection("incidencias").where("semana", "==", semana).get();
        registrarInvocaciones(registroSnap.size)

        if (registroSnap.empty) {
            return empleadoDoc.collection("incidencias").add({
                semana: semana,
                ...datos
            })
        } else {
            let doc = registroSnap.docs[0];

            return db.doc(doc.ref.path).update({
                ...datos
            })
        }
    } catch (error) {
        throw error;
    }
}
export const obtenerEmpleadoEmpaqueCiclo = async (cicloId, empaqueId, empleadoId) => {
    try {
        const db = firebase.firestore();
        let empaque = await obtenerEmpaqueCiclo(cicloId, empaqueId);
        let doc = await db.collection("ciclos").doc(cicloId).collection("empaques").doc(empaque.id).collection("empleados").doc(empleadoId).get();
        registrarInvocaciones(doc.size)
        let data = { id: doc.id, ...doc.data() };
        return data
    } catch (error) {
        throw error;
    }
}
export const obtenerPonderadoCalidad = async (empacadoraId, cicloId, semana) => {
    try {

        let invernaderos = await obtenerInvernaderosVirtuales(cicloId);
        let presentaciones = await obtenerPresentaciones();
        let promises = invernaderos.map(el => obtenerRegistrosPesosEmpacadora(cicloId, el.id, semana, empacadoraId || "na"));

        let registrosInvernaderos = await Promise.all(promises);
        let registros = registrosInvernaderos.flat();
        let desviaciones = obtenerDesviacionesPesos(registros, presentaciones);
        let resultado = resumirDesviaciones(desviaciones);

        return resultado;
    } catch (error) {
        throw error;
    }
}
const obtenerRegistrosPesosEmpacadora = async (cicloId, invernaderoId, semana, empacadoraId) => {
    try {
        const db = firebase.firestore();
        let snap = await db.collection("ciclos").doc(cicloId).collection("invernaderos_virtuales").doc(invernaderoId).
            collection("calidad_pesos").where("tipo_empaque", "==", "manual").where("empacadora_ref", "==", empacadoraId).
            where("momento", ">=", semana).where("momento", "<", semana + 7 * 24 * 60 * 60).get();
        registrarInvocaciones(snap.size)
        return snap.docs.map(el => ({ id: el.id, ...el.data() }))

    } catch (error) {
        throw error;
    }
}
const resumirDesviaciones = (desviaciones) => {
    let keys = Object.keys(desviaciones);
    if (keys.length === 0) {
        return 1;
    }
    let totalMuestras = 0;
    let totalNormales = 0;

    keys.forEach(currentKey => {
        let dato = desviaciones[currentKey];
        totalMuestras += dato["muestras"];
        totalNormales += dato["normales"];
    })
    let cociente = totalNormales / totalMuestras;
    return cociente;
}

export const obtenerProductividadEmpaque = async (empaques, productos) => {
    return productos.map(producto => ({ producto_id: producto.id, valor: 0 }))
}
export const validarUnicoCodigoEmpacadora = async (ciclo, codigoEmpacadora) => {
    //obtener todos empaques de ciclo
    if (codigoEmpacadora === 0) {
        return true;
    }
    const db = firebase.firestore();
    const empaqueSnap = await db.collection("ciclos").doc(ciclo).collection("empaques").get();
    registrarInvocaciones(empaqueSnap.size)
    const empaqueDocs = empaqueSnap.docs;
    let roles = await obtenerRolesEmpaque();
    let rol = roles.find(el => el.codigo === nombreCodigoRolEmpacador);
    let promises = empaqueDocs.map(el => {
        return db.doc(el.ref.path).collection("empleados")
            .where("rol_ref", "==", rol.id)
            .where("codigo_empacadora", "==", codigoEmpacadora).get()
    });
    let empacadoras = await obtenerEmpacadoras();
    let empacadora = empacadoras.find(el => el.id === codigoEmpacadora);
    if (empacadora.es_capacitacion) {
        return true
    } else {
        let result = await Promise.all(promises);
        registrarInvocaciones(result.length)
        let x = result.reduce((acc, curr) => {
            return curr.empty && acc
        }, true)
        return x;

    }
}

export const obtenerCajasPorEmpleado = (archivos, productoMedleysGeneral = {}, productosRelacionados) => {
    const empleadoPresentacionesDatos = {}, empleadoProductosDatos = {};
    const presentaciones = getGlobal().presentaciones;

    archivos.forEach(archivo => {
        for (const presentacionNombre in archivo.datos) {
            const presentacionOBJ = presentaciones?.find(pres => pres.presentacion === presentacionNombre) || {};
            if (presentacionOBJ.mezcla && productosRelacionados.includes(presentacionOBJ.producto_ref)) { presentacionOBJ.producto_ref = productoMedleysGeneral.id || ""; }
            const productoID = presentacionOBJ.producto_ref || "";

            empleadoPresentacionesDatos[presentacionNombre] = empleadoPresentacionesDatos[presentacionNombre] || {};
            empleadoProductosDatos[productoID] = empleadoProductosDatos[productoID] || {};

            const empaqueDatos = archivo.datos[presentacionNombre];
            for (let empleadoCodigo in empaqueDatos) {
                const cajasEmpacadas = empaqueDatos[empleadoCodigo];
                empleadoCodigo = empleadoCodigo.toLowerCase();

                const cajasEmpacadasPorPresentacion = empleadoPresentacionesDatos[presentacionNombre][empleadoCodigo] || 0;
                const cajasEmpacadasPorProducto = empleadoProductosDatos[productoID][empleadoCodigo] || 0;

                empleadoPresentacionesDatos[presentacionNombre][empleadoCodigo] = cajasEmpacadasPorPresentacion + cajasEmpacadas;
                empleadoProductosDatos[productoID][empleadoCodigo] = cajasEmpacadasPorProducto + cajasEmpacadas;
            }

        }
    })
    return { presentacionesDatos: empleadoPresentacionesDatos, productosDatos: empleadoProductosDatos };
}