import { AA_TIPO_MEXICANO, AA_TIPO_AMERICANO, PALLETS_POR_CAMION, PUNTO_CARGA_ATONGO_AGROPARK_NOMBRE, PUNTO_CARGA_AGROPARK_NOMBRE, PUNTO_CARGA_ATONGO_NOMBRE } from "../util/constants";
import { dateToUnixTime, unixTimeToDate, sumarNumerosArrayPorCampo, procesarColeccionFirestore } from '../util/functions';
import { obtenerNombreFechaCompacto, sumarHoras, obtenerDiasDeSemana } from "./fechas";
import { obtenerClientePorNombre, obtenerClientesMinoritarios } from "./Clientes";
import { faIgloo } from "@fortawesome/free-solid-svg-icons";
import { obtenerCiclo } from "./Ciclos";
import { obtenerPresentaciones } from "./Presentaciones";
import { crearEmbarque } from "./QueriesEmbarques";
import { actualizarManifiesto, crearManifiesto, guardarManifiesto, obtenerManifiestosEmbarque, obtenerManifiestosOrden } from "./QueriesManifiestos";
import {uploadStatistics} from './Estadisticas'
import { filtrarManifiestosMismoEmbarque,filtrarEmbarqueIdsUnicosDeManifiestos1, filtrarEmbarqueIdsUnicosDeManifiestos2 } from "./utils/embarques.utils";

function registrarInvocaciones(count){
    uploadStatistics(count)
}


const firebase = require("firebase");
require("firebase/firestore");

const PRIMER_ESTADO_EMBARQUE_ORDEN = 1
const ESTADO_DESDE_QUE_SE_CUENTA_EL_TIMER_ORDEN = 3
const ULTIMO_ESTADO_EMBARQUE_ORDEN = 5
const NO_APLICA = "N/A"


export async function getPuntosDeCarga_orderBy_nombre() {
    var puntosCarga = []
    const firestore = firebase.firestore()
    await firestore.collection('puntos_carga')
            .orderBy('nombre', 'asc')
            .get()
            .then((snapshot) => {
                registrarInvocaciones(snapshot.size)
        snapshot.forEach(document => {
            puntosCarga.push({ id: document.id, ...document.data() })
        })
    }).catch(err=>{
        throw('getPuntosDeCarga_orderBy_nombre() | ' + err);
    });
    return puntosCarga
}

export async function getEmpresas_orderBy_nombre() {
    var empresas = []
    const firestore = firebase.firestore()
    await firestore.collection('empresas')
            .orderBy('nombre', 'asc')
            .get()
            .then((snapshot) => {
                registrarInvocaciones(snapshot.size)
        snapshot.forEach(document => {
            empresas.push({ id: document.id, ...document.data(), numeroDeEmbarque: '', manifiesto: '' })
        });
    }).catch(err=>{
        //('error en getEmpresas_orderBy_nombre()')
        throw('getEmpresas_orderBy_nombre() | ' + err);
    })
    return empresas
}


export async function getClientes_from_Ciclo_orderBy_nombreComun(cicloId) {
    try {
        var clientesEnCiclo = []
        const catalogoClientes = await getClientes_orderBy_nombreComun()
        const clientesEnCicloRefsDocs = await getClientesRefs_from_ciclo(cicloId)
        catalogoClientes.forEach((cliente) => {
            const clienteEnCicloIndex = clientesEnCicloRefsDocs.findIndex(clienteRefDoc => cliente.id === clienteRefDoc.cliente_ref)
            if (clienteEnCicloIndex !== -1) clientesEnCiclo.push(cliente)
        });
        return clientesEnCiclo    
    } catch (error) { console.log(error)
        throw('getClientes_from_Ciclo_orderBy_nombreComun() | ' + error);
    }
    
}

async function getClientes_orderBy_nombreComun() {
    var clientes = []
    const firestore = firebase.firestore()
    await firestore.collection('clientes')
            .orderBy('nombre_comun', 'asc')
            .get()
            .then((snapshot) => {
                registrarInvocaciones(snapshot.size)
        snapshot.forEach(document => {
            clientes.push({ id: document.id, ...document.data() })
        });
    })
    return clientes
}

async function getClientesRefs_from_ciclo(cicloId) {
    var clientesRefs = []
    const firestore = firebase.firestore()
    await firestore.collection('ciclos')
            .doc(cicloId)
            .collection('clientes')
            .get()
            .then((snapshot) => {
                registrarInvocaciones(snapshot.size)
        snapshot.forEach(document => {
            clientesRefs.push({ id: document.id, ...document.data() })
        });
    })
    return clientesRefs
}


export async function getTransportistas_orderBy_nombre() {
    try {
        var transportistas = []
        const firestore = firebase.firestore()
        await firestore.collection('transportistas')
                .orderBy('nombre', 'asc')
                .get()
                .then((snapshot) => {
                    registrarInvocaciones(snapshot.size)
            snapshot.forEach(document => {
                transportistas.push({ id: document.id, ...document.data() })
            });
        })
        return transportistas    
    } catch (error) { console.log(error)
        throw('getTransportistas_orderBy_nombre() | ' + error);
    }
    
}

export async function getFronteras_orderBy_nombre() {
    try {
        var fronteras = []
        const firestore = firebase.firestore()
        await firestore.collection('fronteras')
                .orderBy('nombre', 'asc')
                .get()
                .then((snapshot) => {
                    registrarInvocaciones(snapshot.size)
            snapshot.forEach(document => {
                fronteras.push({ id: document.id, ...document.data() })
            });
        })
        return fronteras    
    } catch (error) { console.log(error)
        throw('getFronteras_orderBy_nombre() | ' + error);
    }
    
}

export async function getDestinos_orderBy_nombre() {
    try {
        var destinos = []
        const firestore = firebase.firestore()
        await firestore.collection('destinos')
                .orderBy('nombre', 'asc')
                .get()
                .then((snapshot) => {
                    registrarInvocaciones(snapshot.size)
            snapshot.forEach(document => {
                destinos.push({ id: document.id, ...document.data() })
            });
        })
        return destinos    
    } catch (error) { console.log(error)
        throw('getDestinos_orderBy_nombre() | ' + error);
    }
    
}

export async function getAgentesAduanales_tipoMexicano_orderBy_nombre() {
    try {
        var agentesAduanales = []
        const firestore = firebase.firestore()
        await firestore.collection('agentes_aduanales')
                .where('tipo', '==', AA_TIPO_MEXICANO)
                .orderBy('nombre', 'asc')
                .get()
                .then((snapshot) => {
                    registrarInvocaciones(snapshot.size)
            snapshot.forEach(document => {
                agentesAduanales.push({ id: document.id, ...document.data() })
            });
        })
        return agentesAduanales    
    } catch (error) { console.log(error)
        throw('getAgentesAduanales_tipoMexicano_orderBy_nombre() | ' + error);
    }
    
}

export async function getAgentesAduanales_tipoAmericano_orderBy_nombre() {
    try {
        var agentesAduanales = []
        const firestore = firebase.firestore()
        await firestore.collection('agentes_aduanales')
                .where('tipo', '==', AA_TIPO_AMERICANO)
                .orderBy('nombre', 'asc')
                .get()
                .then((snapshot) => {
                    registrarInvocaciones(snapshot.size)
            snapshot.forEach(document => {
                agentesAduanales.push({ id: document.id, ...document.data() })
            });
        })
        return agentesAduanales    
    } catch (error) { console.log(error)
        throw('getAgentesAduanales_tipoAmericano_orderBy_nombre() | ' + error);
    }
    
}

export async function registrarEmbarque(ciclo_ref, fecha_registro, puntos_de_carga,
        importador_ref, transportista_ref, frontera_ref, destino_ref, agente_aduanal_mexicano_ref,
        agente_aduanal_americano_ref, consolidado, numero_de_caja, currentCamion) {

    try {
        var registroExitoso = false
        
        const primerEstadoEmbarque = await obtenerPrimerEstadoDeEmbarque()

        if (primerEstadoEmbarque.id === "") return registroExitoso

        const fechaRegistroUnix = dateToUnixTime(fecha_registro)
        const fecha_registro_unix_timestamp = fechaRegistroUnix
        const fecha_modificacion_unix_timestamp = ""
        const estado_ref = primerEstadoEmbarque.id
        const cambios_estado_map = {
            [estado_ref]: {
                "fecha_inicial_unix_timestamp": fechaRegistroUnix,
                "cambios_on_hold_list": []
            }
        }

        const embarque = {
            fecha_registro_unix_timestamp, fecha_modificacion_unix_timestamp,
            estado_ref, cambios_estado_map,
            ciclo_ref, puntos_de_carga,
            importador_ref, transportista_ref, frontera_ref, destino_ref, agente_aduanal_mexicano_ref,
            agente_aduanal_americano_ref, consolidado, numero_de_caja
        }
        
        //console.log("CURRENTCAMION: ",currentCamion)
        if(currentCamion?.orden_venta_ref){ 
            embarque.orden_venta_ref = currentCamion.orden_venta_ref;
            embarque.indexCamion = currentCamion.indexCamion;
        }
        
        let result = await crearEmbarque(ciclo_ref, embarque);
        registroExitoso = true;
        let manifiestos = await guardarManifiestos(ciclo_ref,result,currentCamion);
        //
        return registroExitoso    
    } catch (error) { console.log(error)
        //(`... SERVICIO EMBARQUES ERROR | registrarEmbarque() | ${ JSON.stringify(error) } `)
    }
}
const guardarManifiestos = async (cicloId, embarqueObj, currentCamion) => {
    try {
        const promises = currentCamion.manifiestos.map(manifiesto=>{
            const {id: manifiestoId, ...manifiestoDatos} = manifiesto;
            manifiestoDatos.embarque_ref = embarqueObj.id; // agregar embarque_ref al manifiesto
            manifiestoDatos.items.forEach(item => { item.status = "sin"; });
            delete manifiestoDatos.productor;
            return guardarManifiesto(cicloId, manifiestoId, manifiestoDatos);
        })
        const result = await Promise.all(promises);
        return result;    
    } catch (error) {
        console.log("ERROR guardarManifiestos: ",{currentCamion, error});
    }
}

async function obtenerPrimerEstadoDeEmbarque() {
    const firestore = firebase.firestore()
    var primerEstadoEmbarque = {id: ""}

    await firestore.collection('estados_embarque')
            .where('orden', '==', PRIMER_ESTADO_EMBARQUE_ORDEN)
            .get()
            .then((snapshot) => {
                registrarInvocaciones(snapshot.size)
        if (snapshot.size !== 0) primerEstadoEmbarque = {id: snapshot.docs[0].id, ...snapshot.docs[0].data()}
    }).catch((error) => {
        //(`... SERVICIO EMBARQUES ERROR | obtenerPrimerEstadoDeEmbarque() | ${ JSON.stringify(error) } `)
    });

    return primerEstadoEmbarque
}


export async function getEstadosEmbarque_orderBy_orden() {
    try {
        var estadosEmbarque = []
        const firestore = firebase.firestore()
        await firestore.collection('estados_embarque')
                .orderBy('orden', 'asc')
                .get()
                .then((snapshot) => {
                    registrarInvocaciones(snapshot.size)
            snapshot.forEach(document => {
                estadosEmbarque.push({ id: document.id, ...document.data() })
            });
        })
        return estadosEmbarque    
    } catch (error) { console.log(error)
        throw('getEstadosEmbarque_orderBy_orden() | ' + error);
    }
    
}

export async function getEmbarque(embarqueId, cicloId) {
    var embarque = {}
    const firestore = firebase.firestore()
    await firestore.collection('ciclos')
            .doc(cicloId)
            .collection('embarques')
            .doc(embarqueId)
            .get()
            .then((doc) => {
                registrarInvocaciones(doc.size)
        embarque = { id: doc.id, ...doc.data() }
    })
    return embarque
}
export const getEmbarquesCliente = async( cicloId, clienteId) =>{
   const response =  await firebase.firestore().collection('ciclos').doc(cicloId) .collection('embarques').where("importador_ref","==",clienteId).get();
   const embarques = procesarColeccionFirestore(response);
   //registrarInvocaciones(doc.size);
   return embarques;
}

export async function actualizarEmbarque(cicloId, fecha_registro, embarqueId, puntos_de_carga,
        importador_ref, transportista_ref, frontera_ref, destino_ref, agente_aduanal_mexicano_ref,
        agente_aduanal_americano_ref, consolidado, numero_de_caja) {
    var modificacionExitosa = false
    const firestore = firebase.firestore()
    const fecha_modificacion_unix_timestamp = dateToUnixTime(new Date())
    const fecha_registro_unix_timestamp = dateToUnixTime(fecha_registro)

    const embarque = {
        fecha_registro_unix_timestamp,
        fecha_modificacion_unix_timestamp,
        puntos_de_carga,
        importador_ref, transportista_ref, frontera_ref, destino_ref, agente_aduanal_mexicano_ref,
        agente_aduanal_americano_ref, consolidado, numero_de_caja
    }

    await firestore.collection("ciclos")
            .doc(cicloId)
            .collection("embarques")
            .doc(embarqueId)
            .update(embarque)
            .then(() => {
        modificacionExitosa = true
    }).catch((error) => {
        //(`... SERVICIO EMBARQUES ERROR | modificarEmbarque() | ${ JSON.stringify(error) } `)
    });

    return modificacionExitosa
}

export const guardarDatosEmbarque = async (cicloId, embarqueId, datos) => {
    await firebase.firestore().collection("ciclos").doc(cicloId).collection("embarques").doc(embarqueId).set(datos, {merge: true});
    return true;
}


export async function eliminarEmbarque(cicloId, embarqueId) {
    let response = { operacionExitosa: false, mensaje: '' }
    if (cicloId === '' || embarqueId === '') {
        response.mensaje = 'parámetros no permitidos'
        return response
    }

    const manifiestosEmbarqueResponse = await obtenerManifiestosDeEmbarque(cicloId, embarqueId)
    if (manifiestosEmbarqueResponse.operacionExitosa === false) {
        response.mensaje = manifiestosEmbarqueResponse.mensaje
        return response
    }
    
    const firestore = firebase.firestore()
    const batch = firestore.batch()
    const embarquePorEliminar = firestore.collection('ciclos')
        .doc(cicloId)
        .collection('embarques')
        .doc(embarqueId);
    batch.delete(embarquePorEliminar);
    for (let manifiestoIndex=0; manifiestoIndex<manifiestosEmbarqueResponse.manifiestosEmbarque.length; manifiestoIndex++) {
        const manifiestoId = manifiestosEmbarqueResponse.manifiestosEmbarque[manifiestoIndex].id
        const manifiestoPorEliminar = firestore.collection('ciclos')
            .doc(cicloId)
            .collection('manifiestos')
            .doc(manifiestoId);
        batch.delete(manifiestoPorEliminar);
    }

    // aplicar cambios
    await batch.commit().then(() => {
        response.operacionExitosa = true
    }).catch((error) => {
        response.mensaje = error
    });

    return response
}

async function obtenerManifiestosDeEmbarque(cicloId, embarqueId) {
    let response = { operacionExitosa: false, mensaje: '', manifiestosEmbarque: [] }
    const firestore = firebase.firestore()
    await firestore.collection('ciclos')
            .doc(cicloId)
            .collection('manifiestos')
            .where('embarque_ref', '==', embarqueId)
            .get()
            .then((snapshot) => {
                registrarInvocaciones(snapshot.size)
        snapshot.forEach(document => {
            response.manifiestosEmbarque.push({ id: document.id, ...document.data() });
        });
    }).catch((error) => {
        response.mensaje = error
    });
    if (response.mensaje === '') response.operacionExitosa = true
    return response
}



export async function cambiarEstadoEmbarqueAlSiguiente(cicloId, embarqueId, estadoActual, estadosEmbarque, cambiosEstadoMap) {
    let response = { operacionExitosa: false, mensaje: '', nuevoEstado: null }
    if (cicloId === '' || embarqueId === '' || !estadoActual || !estadosEmbarque) {
        response.mensaje = 'parámetros no permitidos'
        return response
    }

    let estadoSiguiente = obtenerEstadoSiguiente(estadoActual, estadosEmbarque)
    const firestore = firebase.firestore()
    const fecha_cambio_estado_unix_timestamp = dateToUnixTime(new Date())

    cambiosEstadoMap[estadoSiguiente.id] = {
        "fecha_inicial_unix_timestamp": fecha_cambio_estado_unix_timestamp,
        "cambios_on_hold_list": [] }

    let datos = estadoSiguiente?.nombre === "Finalizado" ? { "cambios_estado_map": cambiosEstadoMap, "estado_ref": estadoSiguiente.id, fecha_de_llegada_unix_timestamp: parseInt(fecha_cambio_estado_unix_timestamp)} : { "cambios_estado_map": cambiosEstadoMap, "estado_ref": estadoSiguiente.id }
    await firestore.collection("ciclos")
            .doc(cicloId)
            .collection("embarques")
            .doc(embarqueId)
            .update(datos)
            .then(() => {
        response.nuevoEstado = estadoSiguiente
        response.operacionExitosa = true
    }).catch((error) => {
        response.mensaje = error
        //(`... SERVICIO EMBARQUES ERROR | cambiarEstadoEmbarqueAlSiguiente() | ${ JSON.stringify(error) }`)
    });

    return response
}

export function obtenerEstadoSiguiente(estadoActual, estadosEmbarque) {
    if (!estadoActual || !estadosEmbarque) return null
    if (estadoActual.orden === ULTIMO_ESTADO_EMBARQUE_ORDEN) return null
    let estadoActualIndex = estadosEmbarque.findIndex((embarque) => embarque.id === estadoActual.id)
    if (estadoActualIndex === -1) return null
    let estadoSiguiente = estadosEmbarque[estadoActualIndex+1]
    return estadoSiguiente
}

export async function pausar_o_despausar_embarque(cicloId, embarqueId, estadoActual, cambiosEstadoMap) {
    let response = { operacionExitosa: false, mensaje: '' }
    if (cicloId === '' || embarqueId === '' || !estadoActual) {
        response.mensaje = 'parámetros no permitidos'
        return response
    }

    const firestore = firebase.firestore()
    const fecha_cambio_estado_unix_timestamp = dateToUnixTime(new Date())
    var cambiosOnHoldList = cambiosEstadoMap[estadoActual.id]["cambios_on_hold_list"]
    if (seraNuevoOnHold(cambiosOnHoldList)) {
        cambiosOnHoldList.push({
            "fecha_inicial_unix_timestamp": fecha_cambio_estado_unix_timestamp,
            "fecha_final_unix_timestamp": ""
        });
    } else if (estaOnHold(cambiosOnHoldList)) {
        cambiosOnHoldList[cambiosOnHoldList.length - 1].fecha_final_unix_timestamp = fecha_cambio_estado_unix_timestamp
    }
    cambiosEstadoMap[estadoActual.id]["cambios_on_hold_list"] = cambiosOnHoldList

    await firestore.collection("ciclos")
            .doc(cicloId)
            .collection("embarques")
            .doc(embarqueId)
            .update({ "cambios_estado_map": cambiosEstadoMap })
            .then(() => {
        response.operacionExitosa = true
    }).catch((error) => {
        response.mensaje = error
        //(`... SERVICIO EMBARQUES ERROR | cambiarEstadoEmbarqueAlSiguiente() | ${ JSON.stringify(error) }`)
    });

    return response
}

function seraNuevoOnHold(cambiosOnHoldList) {
    if (cambiosOnHoldList.length === 0) return true
    var registroOnHold = cambiosOnHoldList[cambiosOnHoldList.length - 1]
    return registroOnHold.fecha_inicial_unix_timestamp !== "" && registroOnHold.fecha_final_unix_timestamp !== ""
}

function estaOnHold(cambiosOnHoldList) {
    if (cambiosOnHoldList.length === 0) return false
    const registroOnHold = cambiosOnHoldList[cambiosOnHoldList.length - 1]
    return registroOnHold.fecha_inicial_unix_timestamp !== "" && registroOnHold.fecha_final_unix_timestamp === ""
}

export async function notificarAgentesRelacionados(cicloId, embarqueId, embarqueUrl, aaMexicanoUserUid, aaAmericanoUserUid, numerosEmbarques, fecha) {
    const cloudFunctions = firebase.functions()
    const notificarEmbarqueDespachado = cloudFunctions.httpsCallable("notificarEmbarqueDespachado")
    const params = { cicloId, embarqueId, embarqueUrl, aaMexicanoUserUid, aaAmericanoUserUid, numerosEmbarques, fecha }
    const response = await notificarEmbarqueDespachado(params)
    return response
}


export async function getRegistroEmbarques_forFilters(estadoEmbarqueId, cicloRef) {
    var registroEmbarques = []
    const firestore = firebase.firestore()
    await firestore.collection('ciclos')
            .doc(cicloRef)
            .collection('embarques')
            .where('estado_ref', '==', estadoEmbarqueId)
            .get()
            .then((snapshot) => {
                registrarInvocaciones(snapshot.size)
        snapshot.forEach(document => {
            registroEmbarques.push({ id: document.id, ...document.data() })
        });
    })

    return registroEmbarques
}

export async function getRegistroEmbarques_FinalizedFiltered(estadoEmbarqueId, cicloRef, rangoFechaInicio, rangoFechaFinal){
    var registroEmbarques = []
    const firestore = firebase.firestore()

    await firestore.collection('ciclos')
            .doc(cicloRef)
            .collection('embarques')
            .where('estado_ref', '==', estadoEmbarqueId)
            .where('fecha_de_llegada_unix_timestamp', '>=', rangoFechaInicio)
            .where('fecha_de_llegada_unix_timestamp', '<=', rangoFechaFinal)
            .get()
            .then((snapshot) => {
                registrarInvocaciones(snapshot.size)
        snapshot.forEach(document => {
            //window.alert((document.data().fecha_de_llegada_unix_timestamp >= rangoFechaInicio) + document.data().fecha_de_llegada_unix_timestamp + " --- " + rangoFechaInicio)
            registroEmbarques.push({ id: document.id, ...document.data() })
        });
    })

    return registroEmbarques

}

export async function subirArchivo(embarqueId, file) {
    var nuevoArchivo = {}
    const storageRef = firebase.storage().ref()
    const archivoRef = storageRef.child(`embarques/${ embarqueId }/${ file.name }`)
    const newRef = await archivoRef.put(file)

    if (!newRef) return nuevoArchivo
    const nombre = newRef.ref.name
    const linkDescarga = await newRef.ref.getDownloadURL()
    const metadatos = await newRef.ref.getMetadata()
    const fecha = obtenerNombreFechaCompacto(dateToUnixTime(new Date(metadatos.updated)))
    nuevoArchivo = { nombre, signedUrl: linkDescarga, fecha }

    return nuevoArchivo
}

export async function obtenerArchivosEmbarque(embarqueId) {
    var archivosEmbarque = []
    const storageRef = firebase.storage().ref()
    const archivosEmbarqueRef = storageRef.child(`embarques/${ embarqueId }`)
    const res = await archivosEmbarqueRef.listAll()
    const fileItems = res.items
    if (fileItems === 0) return archivosEmbarque

    for (let i=0; i<fileItems.length; i++) {
        const nombre = fileItems[i].name
        let [
            linkDescarga,
            metadatos
        ] = await Promise.all([
            fileItems[i].getDownloadURL(),
            fileItems[i].getMetadata()
        ]);
        const fecha = obtenerNombreFechaCompacto(dateToUnixTime(new Date(metadatos.updated)))
        archivosEmbarque.push({ nombre, linkDescarga, fecha, metadatos })
    }

    archivosEmbarque.sort((a, b) => (a.metadatos.updated < b.metadatos.updated) ? -1 : ((a.metadatos.updated > b.metadatos.updated) ? 1 : 0))
    return archivosEmbarque
}

export async function eliminarArchivo(embarqueId, nombreArchivo) {
    var operacionExitosa = false
    const storageRef = firebase.storage().ref()
    const archivoRef = storageRef.child(`embarques/${ embarqueId }/${ nombreArchivo }`)
    await archivoRef.delete().then(() => {
        operacionExitosa = true
    }).catch(() => {
        operacionExitosa = false
    });
    return operacionExitosa
}

export async function obtenerArchivosEmbarque_withSignedUrl(embarqueId) {
    try{
        const cloudFunctions = firebase.functions()
        const obtenerArchivosAdjuntosEmbarque_withSignedUrl = cloudFunctions.httpsCallable("obtenerArchivosAdjuntosEmbarque_withSignedUrl")
        const params = { embarqueId }
        const response = await obtenerArchivosAdjuntosEmbarque_withSignedUrl(params)
        return response
    }
    catch(error){
        return []
    }
}


export function getFechaDespachado(cambiosEstadoMap, fronteras, fronteraId, estados) {
    const frontera = fronteras.find((frontera) => frontera.id === fronteraId)
    if (!frontera) return NO_APLICA
    if (frontera.horas_timer === 0) return NO_APLICA
    if (!cambiosEstadoMap) return NO_APLICA
    const ordenDeEstadActual = Object.keys(cambiosEstadoMap).length
    if (ordenDeEstadActual < ESTADO_DESDE_QUE_SE_CUENTA_EL_TIMER_ORDEN) return NO_APLICA

    var estadoDesdeQueSeCuentaElTimerUnixTime = ""
    for (const [estadoId, cambiosMap] of Object.entries(cambiosEstadoMap)) {
        const estado = estados.find(estado => estado.id === estadoId)
        if (!estado) continue
        if (estado.orden === ESTADO_DESDE_QUE_SE_CUENTA_EL_TIMER_ORDEN) {
            estadoDesdeQueSeCuentaElTimerUnixTime = cambiosMap.fecha_inicial_unix_timestamp
        }
    }

    if (estadoDesdeQueSeCuentaElTimerUnixTime === "") return NO_APLICA
    const nombreFechaCompacto = obtenerNombreFechaCompacto(estadoDesdeQueSeCuentaElTimerUnixTime)
    return nombreFechaCompacto
}


export function getFechaEstimadaDeLlegadaDeEmbarque(cambiosEstadoMap, fronteras, fronteraId, estados) {
    const frontera = fronteras.find((frontera) => frontera.id === fronteraId)
    if (!frontera) return NO_APLICA
    if (!cambiosEstadoMap) return NO_APLICA
    const ordenDeEstadActual = Object.keys(cambiosEstadoMap).length
    if (ordenDeEstadActual < ESTADO_DESDE_QUE_SE_CUENTA_EL_TIMER_ORDEN) return NO_APLICA
    const horasEstimadasDeLlegada = frontera.horas_timer
    if (horasEstimadasDeLlegada === 0) return NO_APLICA
    const ordenEstadoFinal = estados[estados.length-1].orden

    var estadoDesdeQueSeCuentaElTimerUnixTime = ""
    for (const [estadoId, cambiosMap] of Object.entries(cambiosEstadoMap)) {
        const estado = estados.find(estado => estado.id === estadoId)
        if (!estado) continue
        if (estado.orden === ordenEstadoFinal) {
            const fechaFinalizadoUnix = cambiosMap.fecha_inicial_unix_timestamp
            return obtenerNombreFechaCompacto(fechaFinalizadoUnix)
        } else if (estado.orden === ESTADO_DESDE_QUE_SE_CUENTA_EL_TIMER_ORDEN) {
            estadoDesdeQueSeCuentaElTimerUnixTime = cambiosMap.fecha_inicial_unix_timestamp
        }
    }

    if (estadoDesdeQueSeCuentaElTimerUnixTime === "") return NO_APLICA
    const fechaEstimadaLlegada = sumarHoras(estadoDesdeQueSeCuentaElTimerUnixTime, horasEstimadasDeLlegada)
    const nombreFechaCompacto = obtenerNombreFechaCompacto(fechaEstimadaLlegada)
    return nombreFechaCompacto
}

export function getFechaEstimadaDeLlegadaDeEmbarqueUnix(cambiosEstadoMap, fronteras, fronteraId, estados) {
    // //
    // //
    // //
    // //
    // //
    const frontera = fronteras.find((frontera) => frontera.id === fronteraId)
    if (!frontera) return 0
    if (!cambiosEstadoMap) return 0
    const ordenDeEstadActual = Object.keys(cambiosEstadoMap).length
    if (ordenDeEstadActual < ESTADO_DESDE_QUE_SE_CUENTA_EL_TIMER_ORDEN) return 0
    const horasEstimadasDeLlegada = frontera.horas_timer
    if (horasEstimadasDeLlegada === 0) return 0

    var estadoDesdeQueSeCuentaElTimerUnixTime = 0
    for (const [estadoId, cambiosMap] of Object.entries(cambiosEstadoMap)) {
        const estado = estados.find(estado => estado.id === estadoId)
        if (!estado) continue
        if (estado.orden === ESTADO_DESDE_QUE_SE_CUENTA_EL_TIMER_ORDEN) {
            estadoDesdeQueSeCuentaElTimerUnixTime = cambiosMap.fecha_inicial_unix_timestamp
        }
    }

    if (estadoDesdeQueSeCuentaElTimerUnixTime === "") return 0
    const fechaEstimadaLlegada = sumarHoras(estadoDesdeQueSeCuentaElTimerUnixTime, horasEstimadasDeLlegada)
    return fechaEstimadaLlegada
}

export function timerIniciado(cambiosEstadoMap) {
    if (!cambiosEstadoMap) return 0
    const ordenDeEstadActual = Object.keys(cambiosEstadoMap).length
    return ordenDeEstadActual >= ESTADO_DESDE_QUE_SE_CUENTA_EL_TIMER_ORDEN
}


export function isOnHold(estadoActualId, cambiosEstadoMap) {
    if (!cambiosEstadoMap) return false
    const cambiosEstadoActual = cambiosEstadoMap[estadoActualId]
    if (!cambiosEstadoActual) return false
    const cambiosOnHoldList = cambiosEstadoActual.cambios_on_hold_list
    if (!cambiosOnHoldList) return false
    if (cambiosOnHoldList.length === 0) return false
    return cambiosOnHoldList[cambiosOnHoldList.length-1].fecha_final_unix_timestamp === ""
}


export function aplicanRetrasos(fronteraId, fronteras) {
    const frontera = fronteras.find((frontera) => frontera.id === fronteraId)
    if (!frontera) return false
    return frontera.horas_timer > 0
}


export async function guardarMensajeChatPublico(cicloId, embarqueId, mensaje, mensajesAnteriores) {
    var mensajeEnviadoConExito = false
    const firestore = firebase.firestore()
    var mensajesNuevos = Array.from(mensajesAnteriores)
    mensajesNuevos.push(mensaje)
    const datosEmbarque = {mensajes_chat_publico: mensajesNuevos}

    await firestore.collection("ciclos")
            .doc(cicloId)
            .collection("embarques")
            .doc(embarqueId)
            .update(datosEmbarque)
            .then(() => {
        mensajeEnviadoConExito = true
    }).catch((error) => {
        //(`... SERVICIO EMBARQUES ERROR | guardarMensajeChatPublico() | ${ JSON.stringify(error) } `)
    });

    return mensajeEnviadoConExito
}

export async function guardarMensajeChatPrivado(cicloId, embarqueId, mensaje, mensajesAnteriores) {
    var mensajeEnviadoConExito = false
    const firestore = firebase.firestore()
    var mensajesNuevos = Array.from(mensajesAnteriores)
    mensajesNuevos.push(mensaje)
    const datosEmbarque = {mensajes_chat_privado: mensajesNuevos}

    await firestore.collection("ciclos")
            .doc(cicloId)
            .collection("embarques")
            .doc(embarqueId)
            .update(datosEmbarque)
            .then(() => {
        mensajeEnviadoConExito = true
    }).catch((error) => {
        //(`... SERVICIO EMBARQUES ERROR | guardarMensajeChatPrivado() | ${ JSON.stringify(error) } `)
    });

    return mensajeEnviadoConExito
}

export async function guardarConsolidadoEmbarque(cicloId, embarqueId, consolidado = false) {
    await firebase.firestore().collection("ciclos").doc(cicloId).collection("embarques").doc(embarqueId).update({consolidado});
    return true;
}

export function generarMensajeEmbarque(contenido, userUid) {
    const fecha_enviado_unix_timestamp = dateToUnixTime(new Date())
    const mensaje = {
        enviado_unix_timestamp: fecha_enviado_unix_timestamp,
        user_uid: userUid,
        contenido: contenido
    }
    return mensaje
}


export const obtenerEmbarquesSemana = async (ciclo, semana) => {
    const db = firebase.firestore();
    let dias = obtenerDiasDeSemana(semana);
    let embarquesSnap = await db.collection("ciclos")
        .doc(ciclo)
        .collection("embarques")
        .where("fecha_registro_unix_timestamp",">=", semana.toString())
        .where("fecha_registro_unix_timestamp","<", (semana+7 * 24 * 60 * 60).toString())
        .get();
    registrarInvocaciones(embarquesSnap.size)
    let embarques = embarquesSnap.docs.map(el=>({...el.data(), id: el.id}));
    return embarques;
}
export const esOnHold = (embarque,idEnCruce) => {
    return embarque["cambios_estado_map"] && embarque["cambios_estado_map"][idEnCruce] &&embarque["cambios_estado_map"][idEnCruce]["cambios_on_hold_list"].length > 0
}
export const obtenerIdEnCruce = async () => {
    const db = firebase.firestore();
    let snap = await db.collection("estados_embarque").where("nombre","==","En cruce").get();
    registrarInvocaciones(snap.size)
    let data = snap.docs[0].data();
    return snap.docs[0].id;
}

export const obtenerPalletsPorCamionCiclo = async (ciclo,semanaLimite, cliente) => {
    let clientes = [];
    if(cliente?.nombre === "Otros"){
         clientes = await obtenerClientesMinoritarios();
    }else{        
        cliente =await obtenerClientePorNombre(cliente?.nombre);
        clientes = [cliente];
    }
    
    let ordenes = await obtenerOrdenesClientesCicloHastaSemana(ciclo,clientes,semanaLimite);
    let presentaciones = await obtenerPresentaciones();

    //
    //
    //
    //
    //
    let camiones = obtenerCuentaCamiones(ordenes,presentaciones);
    let pallets = obtenerCuentaPallets(ordenes, presentaciones);
    return pallets/camiones;
}

export const obtenerEmbarquePorId = async (cicloId, embarqueId) => {
    const db = firebase.firestore()
    const cicloRef = db.collection("ciclos").doc(cicloId)
    const embarqueRef = cicloRef.collection("embarques").doc(embarqueId || "sin")

    const embarqueSnap = await embarqueRef.get().catch(error => { 
        console.log(`Error al obtener ciclo doc ${cicloId}: ${JSON.stringify(error)}`) 
    })

    registrarInvocaciones(embarqueSnap.size)
    const embarqueData =  { id: embarqueSnap.id, ...embarqueSnap.data() }
    return embarqueData
}

export const obtenerPromediosPalletsConsolidados = async (ciclo,semanaLimite, cliente) => {
    /* if(cliente.nombre === "Mastronardi"){ */
    let clientes = [];
    //Generar arreglo de clientes para datos de cards
    
    if(cliente?.nombre === "Otros"){
         clientes = await obtenerClientesMinoritarios();
    }else{        
        cliente = await obtenerClientePorNombre(cliente?.nombre);
        clientes = [cliente];
    }
    
    //Obtener ordenes de venta para este card
    let ordenes = await obtenerOrdenesClientesCicloHastaSemana(ciclo,clientes,semanaLimite);
    //Obtener manifiestos asociados a ordenes
    const promesasManifiestos = ordenes.map((orden) => {
        return obtenerManifiestosOrden(ciclo, orden?.id)
    })
    let manifiestosDeOrdenes = await Promise.all(promesasManifiestos);
    manifiestosDeOrdenes = manifiestosDeOrdenes.flat()
    const embarqueIds = filtrarEmbarqueIdsUnicosDeManifiestos2(manifiestosDeOrdenes);

    const promesasEmbarques = embarqueIds.map((embarque_ref) => {
        return obtenerEmbarquePorId(ciclo, embarque_ref)
    })

    const totalEmbarques = await Promise.all(promesasEmbarques)
    //Hasta este linea, tenemos todos los ordenes, manifiestos, y embarques de este card
    /////////////////////////////////////////////////////////////////////////////////


    //Filtrar los camiones/embarques entre 2 grupos. Los cambiones que son consolidados y los que no son consolidados
    const camionesConsolidados = totalEmbarques?.filter(embarque => embarque.consolidado);
    const camionesNoConsolidados = totalEmbarques.filter((embarque) => !embarque.consolidado );

    const manifiestosConsolidados = manifiestosDeOrdenes.filter(manifiesto => hayCamionAsociadoConEsteManifiesto(camionesConsolidados, manifiesto));
    const manifiestosNoConsolidados = manifiestosDeOrdenes.filter(manifiesto => hayCamionAsociadoConEsteManifiesto(camionesNoConsolidados, manifiesto));

    const palletsConsolidadosTotales = manifiestosConsolidados.reduce((acc, manifiesto) => {
        const sumaItems = manifiesto.items.reduce((acc, item) => {
            acc = acc+parseFloat(item.cajas_orden/item.presentacion.numero_cajas_pallet)
            return acc
        }, 0)
        acc = acc+sumaItems
        return acc
    }, 0)

    const palletsNoConsolidadosTotales = manifiestosNoConsolidados.reduce((acc, manifiesto) => {
        const sumaItems = manifiesto.items.reduce((acc, item) => {
            acc = acc+parseFloat(item.cajas_orden/item.presentacion.numero_cajas_pallet)
            return acc
        }, 0)
        acc = acc+sumaItems
        return acc
    }, 0)

    return {
        promedioPalletsConsolidados: palletsConsolidadosTotales/camionesConsolidados.length,
        promedioPalletsSinConsolidar: palletsNoConsolidadosTotales/camionesNoConsolidados.length
    };// pallets/camiones;
/* }
else return {
    promedioPalletsConsolidados: 122,
    promedioPalletsSinConsolidar: 22,
} */
}
const hayCamionAsociadoConEsteManifiesto = (camiones, manifiesto) => {
    return camiones.some(el => el.id === manifiesto.embarque_ref)
}


export const obtenerPalletsPorCamionCicloSinConsolidados = async (ciclo,semanaLimite, cliente) => {
    let clientes = [];
    if(cliente?.nombre === "Otros"){
         clientes = await obtenerClientesMinoritarios();
    }else{        
        cliente =await obtenerClientePorNombre(cliente?.nombre);
        clientes = [cliente];
    }
    
    
    let ordenes = await obtenerOrdenesClientesCicloHastaSemana(ciclo,clientes,semanaLimite);
    
    const promesasManifiestos = ordenes.map((orden) => {
        return obtenerManifiestosOrden(ciclo, orden?.id)
    })
    const totalDeManifiestos = await Promise.all([...promesasManifiestos])

    const newTotalDeManifiestos = filtrarManifiestosMismoEmbarque(totalDeManifiestos)

    console.log({[cliente.nombre]:totalDeManifiestos})

    const promesasEmbarques = []
    newTotalDeManifiestos.map((manifiesto) => {
        const { embarque_ref }  = manifiesto
        promesasEmbarques.push(obtenerEmbarquePorId(ciclo, embarque_ref))
    })

    const totalEmbarques = await Promise.all([...promesasEmbarques])

    console.log({totalEmbarques})

    let presentaciones = await obtenerPresentaciones();
    console.log({presentaciones})
    //
    //
    //
    //
    //
    let camiones = obtenerCuentaCamiones(ordenes,presentaciones);
    let pallets = obtenerCuentaPallets(ordenes, presentaciones);
    return pallets/camiones;
}
export const obtenerPalletsPorCamionCicloSoloConsolidados = async (ciclo,semanaLimite, cliente) => {
    let clientes = [];
    if(cliente?.nombre === "Otros"){
         clientes = await obtenerClientesMinoritarios();
    }else{        
        cliente =await obtenerClientePorNombre(cliente?.nombre);
        clientes = [cliente];
    }
    
    let ordenes = await obtenerOrdenesClientesCicloHastaSemana(ciclo,clientes,semanaLimite);
    ordenes = ordenes.filter(el=>el.consolidado)
    let presentaciones = await obtenerPresentaciones();

    //
    //
    //
    //
    //
    let camiones = obtenerCuentaCamiones(ordenes,presentaciones);
    let pallets = obtenerCuentaPallets(ordenes, presentaciones);
    return pallets/camiones;
}
const obtenerOrdenesClientesCicloHastaSemana = async (ciclo,clientes,semana) => {
    try {
        let ids = clientes.map(el=>el.id);
        const db = firebase.firestore();    
        let snaps = await db.collection("ciclos").doc(ciclo).collection("ordenes_venta").where("cliente_ref","in",ids).where("fecha_envio","<",semana.toString()).get();
        registrarInvocaciones(snaps.size)
        
        let registros = snaps.docs.map(el=>({id:el.id, ...el.data()}));
        //
        //
        //
        //
        return registros;
    } catch (error) { console.log(error)
        throw error;
    }
    
}
const obtenerCuentaPallets = (ordenes,presentaciones) => {
    let pallets = 0;
    ordenes.forEach(orden => {
        orden.items.forEach(item => {
            let presentacion = presentaciones.find(el=>el.id === item.presentacion_ref);
            if(presentacion){
                let palletsItem = Math.round(item.cajas_orden/presentacion.numero_cajas_pallet);
                pallets += palletsItem;
            }
            
        })
        
    })
    return pallets;
}
const obtenerCuentaCamiones = (ordenes, presentaciones) => {
    let camiones = 0;
    ordenes.forEach(orden => {
        let palletsOrden = 0;
        orden.items.forEach(item => {
            let presentacion = presentaciones.find(el=>el.id === item.presentacion_ref);
            if(presentacion){
                let palletsItem = Math.round(item.cajas_orden/presentacion["numero_cajas_pallet"]);
                palletsOrden += palletsItem;
            }
            
            
        })
        let camionesOrden = Math.ceil(palletsOrden/PALLETS_POR_CAMION );
        camiones+= camionesOrden;
    })
    return camiones;
}

export const obtenerUnidadesCargadas = async (ciclo, semana, locacion, cliente) => {
    try {
        if (locacion === PUNTO_CARGA_ATONGO_AGROPARK_NOMBRE) {
            let registros = await obtenerEmbarquesCicloCliente(ciclo, cliente);
            registros = await filtrarEmbarquesDespachadosSemana(registros, semana); // no se incluirán embarques que aún no han sido despachados
            registros = filtrarEmbarquesPorPuntoCarga(registros, {id: "", nombre: PUNTO_CARGA_ATONGO_AGROPARK_NOMBRE});
            return registros.length;
        } else {
            let puntoCarga = await obtenerPuntoCargaNombre(locacion);
            let registros = await obtenerEmbarquesCicloCliente(ciclo, cliente);
            registros = await filtrarEmbarquesDespachadosSemana(registros, semana); // no se incluirán embarques que aún no han sido despachados
            registros = filtrarEmbarquesPorPuntoCarga(registros, puntoCarga);
            return registros.length;
        }
    } catch (error) { console.log(error)
        throw error;
    }
}

const obtenerPuntoCargaNombre = async(nombre) => {
    const db = firebase.firestore();
    let snap = await    db.collection("puntos_carga").where("nombre","==",nombre).get();
    registrarInvocaciones(snap.size)
    let doc = snap.docs[0];
    let registro = {id:doc.id, ...doc.data()};
    return registro;
}


const obtenerEmbarquesCicloCliente = async (cicloId, cliente) => {
    let clientes = [];
    if(cliente?.nombre === "Otros"){
        //
        clientes = await obtenerClientesMinoritarios();
   }else{        
       
       cliente =await obtenerClientePorNombre(cliente?.nombre);
    //    //
    //    //
       clientes = [cliente];
    //    //
   }
   let embarquesCiclo = await obtenerEmbarquesCicloClientes(cicloId,clientes);
   return embarquesCiclo;
}
const obtenerEmbarquesCicloClientes = async (cicloId,clientes) => {
     
    try {
        let cicloObj = await obtenerCiclo(cicloId);
        const db = firebase.firestore();
        let ids = clientes.map(el=>el.id);
    //     //
    // // //
    //  //
    //  //
        let snaps = await db.collection("ciclos")
            .doc(cicloId)
            .collection("embarques")
            .where("importador_ref","in",ids).get();
        registrarInvocaciones(snaps.size)
        let registros = snaps.docs.map(el=>({id:el.id, ...el.data()}));
        return registros;    
    } catch (error) { console.log(error)
        throw error;
    }
    
}
const filtrarEmbarquesDespachadosSemana = async (embarques,semana) => {
    try {
        let estadoDespachado = await obtenerEstadoDespachado();
        
        let result = embarques.filter(embarque=>{
            let mapaCambios = embarque.cambios_estado_map;
            let despacho = mapaCambios[estadoDespachado.id];
            if(despacho){
                //
                //
                //
                let fecha =  parseInt(despacho["fecha_inicial_unix_timestamp"]);
                //
                return fecha >= semana && fecha < semana + 7 * 24 * 60 * 60;
            }else{
                return false;
            }
        })
        return result;    
    } catch (error) { console.log(error)
        throw error;
    }
    
}
const obtenerEstadoDespachado = async (params) => {
    const db = firebase.firestore();
    let snap = await db.collection("estados_embarque").where("nombre","==","Despachado").get();
    registrarInvocaciones(snap.size)
    let registro = {id:snap.docs[0].id,... snap.docs[0].data()};
    return registro;
}
const obtenerEstadoCruce = async (params) => {
    const db = firebase.firestore();
    let snap = await db.collection("estados_embarque").where("nombre","==","En cruce").get();
    registrarInvocaciones(snap.size)
    let registro = {id:snap.docs[0].id,... snap.docs[0].data()};
    return registro;
}
const obtenerEstadoFinalizado = async (params) => {
    const db = firebase.firestore();
    let snap = await db.collection("estados_embarque").where("nombre","==","Finalizado").get();
    registrarInvocaciones(snap.size)
    let registro = {id:snap.docs[0].id,... snap.docs[0].data()};
    return registro;
}

const filtrarEmbarquesPorPuntoCarga = (embarques, puntoCarga) => {
    const embarquesFiltrados = embarques.filter((embarque) => {
        if (embarque.puntos_de_carga.length === 1
                && (puntoCarga?.nombre === PUNTO_CARGA_ATONGO_NOMBRE || puntoCarga?.nombre === PUNTO_CARGA_AGROPARK_NOMBRE)) {
            const puntosCarga = embarque.puntos_de_carga;
            const incluir = puntosCarga.findIndex(pc => pc === puntoCarga.id);
            return incluir;
        } else if (embarque.puntos_de_carga.length === 2
                && puntoCarga?.nombre === PUNTO_CARGA_ATONGO_AGROPARK_NOMBRE) {
            return true;
        } else {
            return false;
        }
    });
    return embarquesFiltrados
}



export const obtenerEmbarquesATiempo = async (ciclo,semana,cliente) => {
    let registrosOrig = await obtenerEmbarquesCicloCliente(ciclo,cliente);
    let registros = await filtrarEmbarquesATiempo(registrosOrig,semana);
    return registros.length/registrosOrig.length;
}
const filtrarEmbarquesATiempo = async (registros,semana) => {
    let fronteras = await getFronteras_orderBy_nombre()
    let estados = await getEstadosEmbarque_orderBy_orden();
    let promisesAtrasados = registros.map(registro=> {
        let fechaEstimadaLLegada = getFechaEstimadaDeLlegadaDeEmbarqueUnix(registro.cambios_estado_map, fronteras, registro.frontera_ref, estados);    
        let despachado =timerIniciado(registro.cambios_estado_map);
        return esEmbarqueAtrasado(registro.estado_ref,fechaEstimadaLLegada,despachado,aplicanRetrasos(registro.frontera_ref, fronteras))} 
    )
    let atrasados = await Promise.all(promisesAtrasados);
    return registros.filter((registro,index)=>!atrasados[index] );
}

export const obtenerEmbarquesAtrasados = async (ciclo,semana,cliente) => {
    let registrosOrig = await obtenerEmbarquesCicloCliente(ciclo,cliente);
    //
    //
    //
    let registros = await filtrarEmbarquesAtrasados(registrosOrig,semana);
    //
    //
    //
    return registros.length/registrosOrig.length;
}
const filtrarEmbarquesAtrasados = async(registros,semana) => {
    let fronteras = await getFronteras_orderBy_nombre()
    let estados = await getEstadosEmbarque_orderBy_orden();
    let promisesAtrasados = registros.map(registro=> {
        let fechaEstimadaLLegada = getFechaEstimadaDeLlegadaDeEmbarqueUnix(registro.cambios_estado_map, fronteras, registro.frontera_ref, estados);    
        let despachado =timerIniciado(registro.cambios_estado_map);
        //
        //
        //
        //
        let result = esEmbarqueAtrasado(registro.estado_ref,fechaEstimadaLLegada,despachado,aplicanRetrasos(registro.frontera_ref, fronteras));
        return result} 
    )
    
    let atrasados = await Promise.all(promisesAtrasados);
    //
    //
    return registros.filter((registro,index)=>atrasados[index] );
}

export const obtenerEmbarquesOnHold = async (ciclo,cliente) => {
    let registros = await obtenerEmbarquesCicloCliente(ciclo,cliente);
    
    registros = await filtrarEmbarquesOnHold(registros);
    //
    //
    //
    return registros.length;
}
const filtrarEmbarquesOnHold = async (embarques) => {
    let estadoCruce = await obtenerEstadoCruce();
    let result = embarques.filter(embarque=>{
        let mapaCambios = embarque.cambios_estado_map;
        let enCruce = mapaCambios[estadoCruce.id];
        if(enCruce){
            let cambiosHold = enCruce["cambios_on_hold_list"];
            if(cambiosHold.length > 0){
                //
                //
                //
                if(cambiosHold[cambiosHold.length - 1]["fecha_final_unix_timestamp"] === ""){
                    return true;
                }else{
                    return false;
                }
            }else{
                return false
            }
        }else{
            return false;
        }
    })
    return result;    
}
export async function esEmbarqueAtrasado(estadoActualId, fechaIdealLlegadaUnix, embarqueDespachado, aplicanRetrasos) {
    let esFinalizado = await esEmbarqueFinalizado(estadoActualId);
    return !esFinalizado && yaPasoFechaIdealLlegada(fechaIdealLlegadaUnix) && embarqueDespachado && aplicanRetrasos
}
//falta obtener estado finalizado
async function esEmbarqueFinalizado(estadoActualId) {
    let estadoFinalizado = await obtenerEstadoFinalizado();
    const estadoEmbarqueFinalizadoId = estadoFinalizado.id;
    return estadoActualId === estadoEmbarqueFinalizadoId
}

function yaPasoFechaIdealLlegada( fechaIdealLlegadaUnix) {
    let currentUnixTimeDate = Math.round(Date.now()/1000)
    return currentUnixTimeDate > fechaIdealLlegadaUnix
}

