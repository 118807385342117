import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { recuperarPassword } from '../../service/account';
import { Message } from 'primereact/message';
import Logo from '../../images/logo.svg';
import LogoDev from '../../images/logo-sisu.png';
import { estamosEnProduccion } from '../../constants';

function RecuperarPassword() {
  const [email, setEmail] = useState("")
  const [exito, setExito] = useState(false)
  
  const recuperar = async (params) => {
    try {
      await recuperarPassword(email);
      setExito(true)
    } catch (error) {
      console.log(`ERROR: ${JSON.stringify(error)}`);
    }
  }

  return (
    <div className="p-grid p-align-center p-justify-center mh-100">
      <div className="p-col-12 p-md-6 p-col-align-center">
        <img alt={estamosEnProduccion ? "Logo" : "LogoDev"} src={estamosEnProduccion ? Logo : LogoDev} />

        <h1 className="">Recuperar Contraseña</h1>
        <div className="form-group">
          <label >Correo electrónico</label>
          <InputText value={email} onChange={e => setEmail(e.target.value)} />
          {exito && <Message severity="info" text="Te hemos enviado un email de recuperación de contraseña" />}
        </div>

        <Button className="btn-block" label="Recuperar" onClick={recuperar} />
      </div>
    </div>);
}
export default RecuperarPassword;//48