import React, { useEffect } from 'react';
import { useState } from 'react';
import { useGlobal } from 'reactn';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from "primereact/dialog";
import { Button } from 'primereact/button';
import { guardarDatosRequisicion, guardarRequisicionAciclico, } from '../../../service/Requisiciones';
import { getNombrePorID } from '../../../util/functions'
import {  notificarEstatusRequisicion } from '../../../service/notificaciones';
import 'moment/locale/es';

const CambiarEstatusModal = (props) => {
    const [nuevoEstatus, setNuevoEstatus] = useState(props.state.estatus)
    const [cargando, setCargando] = useGlobal("cargando")
    const [ciclo,] = useGlobal("ciclo");

    useEffect(() => {
        setNuevoEstatus(props.estatus)
    }, [props.estatus])

    const getEstatusesDisponibles = () => {
        let currEstatus = props.estatuses.find(el => el.id === props.estatus)
        let estatusesDisponibles = []

        props.estatuses.forEach(el => {
            if (currEstatus && el.orden >= currEstatus.orden)
                estatusesDisponibles.push(el)
        })

        return estatusesDisponibles
    }

    const guardarEstatus = async () => {
        setCargando(true)
        let data = { estatus: nuevoEstatus }
        props.closeModal()

        if (getNombrePorID(props.estatuses, nuevoEstatus) === "Finalizado")
            data.fechaFinalizado = Date.now()

        props.handleInputChange({ target: { name: "estatus", value: nuevoEstatus } })
        await guardarRequisicionAciclico("sistema", props.requisicionID, data)
        await notificarEstatusRequisicion(props.state.solicitante, props.state.orden_num, getNombrePorID(props.estatuses, nuevoEstatus), "sistemas");

        props.setFormularioState(data)
        setCargando(false)
    }

    const footer = (params) => {
        return <div>
            <Button label="Cancelar" onClick={() => props.closeModal()} />
            <Button label="Guardar" disabled={nuevoEstatus === props.state.estatus} onClick={() => guardarEstatus()} />
        </div>
    }

    return <Dialog header={"Administración de la orden"} visible={props.visible} style={{ width: '50vw' }} onHide={() => props.closeModal()} footer={footer()}>
        <div className="p-grid ">
            <div className="form-group p-col-12">
                <label htmlFor="status-nuevo">Estatus nuevo </label>
                <Dropdown id="status-nuevo" optionLabel="nombre" optionValue="id" options={getEstatusesDisponibles()} value={nuevoEstatus} onChange={e => setNuevoEstatus(e.target.value)} />
            </div>
        </div>
    </Dialog >
}

export default CambiarEstatusModal;
