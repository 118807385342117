import React, { useState, useGlobal, useEffect } from "reactn";

import { obtenerDetalleReporte } from "../../service/ReportesProducto";
import { obtenerCiclo } from "../../service/Ciclos";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { dosDecimales, getObjetoPorID, getProductoYVariedadString } from "../../util/functions";
import { Button } from "primereact/button";
import { obtenerListaDeSemanas } from "../../service/fechas";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { getInicialRealInteligente } from "../../service/Pronosticos";

const columnas = ["budget", "realInteligente", "kgm2OrdenesSemana", "packout"];
const mapeoHeaders = {
    "budget": "Budget",
    "realInteligente": "Kg/m² inicial",
    "kgm2OrdenesSemana": "Kg/m² final",
    "packout": "Packout %"
}
export default function Reporte(props) {
    const [ciclo,] = useGlobal("ciclo");
    const [tabla, setTabla] = useState([]);
    const [totales, setTotales] = useState(["-", "-", "-", "-",]);
    const [weekGlobal, setWeekGlobal] = useGlobal("weekGlobal");
    const [invernadero, setInvernadero] = useGlobal("invernadero")
    const [invernaderos, setInvernaderos] = useGlobal("invernaderosVirtuales")
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [, setCargando] = useGlobal("cargando");
    let [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    useEffect(() => {
        setHabilitadoSelectorCiclo(false);
        return () => {
            setHabilitadoSelectorCiclo(true);
        }
    }, [])
    const atras = (params) => {
        props.history.goBack();
    }

    useEffect(() => {
        if (invernaderos && !invernadero) {
            let externo = invernaderos.find(inv => inv.es_externo)
            if (!externo) props.history.push("/producto");
            else setInvernadero(externo)
        }
    }, [invernaderos])

    useEffect(() => {
        if (ciclo && invernadero) {

            obtenerCiclo(ciclo).then(resp => {
                if (invernadero === undefined) props.history.push("/producto");

                setCargando(true);
                obtenerDetalleReporte(resp, invernadero.id).then((result) => {

                    obtenerTotales(result);
                    setTabla(result);
                }).catch((err) => {
                    console.log("OCURRIO UN ERROR ");
                    console.log(err);
                }).finally(el => setCargando(false));
            })
        }
    }, [ciclo, invernadero])
    useEffect(() => {
        setNombreModulo("Empaque");
    }, [])
    function unidadTemplate(rowData, column) {
        let cant = rowData[column["field"]];
        if (isNaN(cant) || cant === Infinity) {
            cant = "-"
        } else if (column["field"] === "packout") {
            cant = Math.round(100 * cant);
        }
        if (column["field"] === "packout") {
            let defectos = isNaN(rowData["defectos"]) ? "-" : dosDecimales(rowData["defectos"]);
            cant = isNaN(cant) ? "-" : cant;

            return <div><Button type="button" label="?" className="btn-line btn-help" onClick={() => irCalidad(rowData["semana"])} tooltip={`<div class='tooltip-datas'><div class='tooltip-data'>${defectos}%<p class='tooltip-label'>Defectos</p></div></div>`}></Button> {cant}<span className="label-unidad">%</span> </div>;
        }
        return <div>{dosDecimales(cant)}<span className="label-unidad">kg/m²</span> </div>;
    }
    const irCalidad = async (semana) => {
        //obtener lista de semanas

        let cicloObj = await obtenerCiclo(ciclo);
        let listaSemanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
        let week = listaSemanas.find(el => el.time === semana);
        setWeekGlobal(week);
        props.history.push("/calidad/reporte")
    }

    const obtenerTotales = async (rows) => {
        let budget = 0, inicial = 0, final = 0
        for (const index in rows) {
            let row = rows[index]
            budget += parseFloat(row.budget);
            //inicial += parseFloat(row.kgm2EmpaqueSemanal);
            inicial += parseFloat(row.realInteligente);
            final += parseFloat(row.kgm2OrdenesSemana);
        }
        let empaque = Math.round(100 * final / inicial);
        setTotales([budget, inicial, final, empaque]);

    }
    function obtenerColumnas() {
        let cols = [<Column field="nombre" className="col__label" header="Semana" ></Column>]
        columnas.map(el => {
            cols.push(<Column body={unidadTemplate} field={el} header={mapeoHeaders[el]} />)
        })
        return cols;
    }
    const irRegistrar = (params) => {
        //setInvernadero(invernadero);

        if (invernadero.budget && invernadero.budget.estimacion_semanal.length > 0) {
            props.history.push("/producto/registrar_empaque");
        }
        else {
            props.history.push("/producto/crear_budget");
        }
    }
    const irGrafica = (params) => {
        props.history.push({pathname: "/empaque/graficas/distribucion_presentaciones_semana", state: {invernadero}});
    }

    function obtenerFooter() {
        let footerGroup = <ColumnGroup>
            <Row>
                <Column footer="Total" colSpan={1} />
                <Column footer={<div>{dosDecimales(totales[0])}<span className="label-unidad">kg/m²</span></div>} colSpan={1} />
                <Column footer={<div>{dosDecimales(totales[1])}<span className="label-unidad">kg/m²</span></div>} colSpan={1} />
                <Column footer={<div>{dosDecimales(totales[2])}<span className="label-unidad">kg/m²</span></div>} colSpan={1} />
                <Column footer={<div>{(isNaN(totales[4] || totales[4] == Infinity) ? "-" : dosDecimales(totales[3]))}<span className="label-unidad">%</span></div>} colSpan={1} />

            </Row>
        </ColumnGroup>;
        return footerGroup;
    }
    return <div className="p-grid p-justify-center">

        <div className="p-col p-col-auto title-container">
            <span className="back-btn" onClick={atras}></span>

            {invernadero && <span className={`icon-general icon-id bor-${invernadero.color} bg-${invernadero.color} `}>{invernadero.nombre}</span>}

            <span className="titles-group">
                <h1 className="header-title">Reporte detallado de empaque </h1>
                {invernadero &&
                    <p className="header-subtitle">
                        {getProductoYVariedadString(invernadero)}
                    </p>}
            </span>
        </div>
        <div className="p-col p-col-auto">
            <div className="btn-row">
                <Button className="reactbtn-icon" onClick={irGrafica} label="Presentaciones por semana">
                    <FontAwesomeIcon icon={faChartBar} />
                </Button>
                <Button label="Registrar pesos diarios" onClick={irRegistrar} />
            </div>
        </div>


        <div className="p-col-12">
            <p className="section-title">Reporte de empaque</p>

            <div className="card p-0">
                <DataTable value={tabla} footerColumnGroup={obtenerFooter()}
                    scrollable={true} className="tabla-empaque-reporte-detalle">
                    {obtenerColumnas()}
                </DataTable>
            </div>
        </div>
    </div>
}