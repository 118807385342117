import React, { useState, useGlobal } from 'reactn';
import ContenedorPrincipal from '../planeacion_mantenimiento/CapturaAgentes/ContenedorPrincipal';
import SeccionCumplimiento from './componentes/SeccionCumplimiento.organ';
import PantallaRedistribucionInvernadero from './componentes/PantallaRedistribucionInvernadero.organ';

const ConsultarCumplimiento = (props) => {
  const [showPantallaDistribucion, setShowPantallaDistribucion] = useGlobal("showPantallaDistribucion");
  const [ordenesSemana, setOrdenesSemana] = useState();

  return (
    <ContenedorPrincipal >

      {/*Pantala de cumplimiento original*/}
      <SeccionCumplimiento {...props} visible={!showPantallaDistribucion} showPantallaDistribucion={showPantallaDistribucion}
        ordenesSemana={ordenesSemana} setOrdenesSemana={setOrdenesSemana} />

      {/*Pantalla de distribución de cajas de invernaderos para ordenes de venta*/}
      <PantallaRedistribucionInvernadero {...props} visible={showPantallaDistribucion} hide={() => { setShowPantallaDistribucion(false) }} ordenesSemana={ordenesSemana}
        setOrdenesSemana={setOrdenesSemana} rowData={showPantallaDistribucion} presentacion={showPantallaDistribucion?.presentacion} />

    </ContenedorPrincipal>)
}
export default ConsultarCumplimiento;