import React, { useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from "primereact/button";
import { useGlobal } from "reactn";
import { Dropdown } from 'primereact/dropdown';
import ContenedorInput from '../../common/ContenedorInput';
import { MultiSelect } from 'primereact/multiselect';
import { getNombreFechaCompacto } from '../../../service/fechas';
import { formatearMinutosAHoras, getNombrePorID, getLocacionAgraparkID, empresaIsHTF, DeepClone } from '../../../util/functions';
import DatoCard from '../../common/cards/DatoCard';
import SeccionDatosCenter from '../../common/SeccionDatosCenter';
import { Message } from 'primereact/message';
import { getSumaTotalTiempoEstimado, guardarActividad, guardarActividadEmergencia, guardarActividades } from '../../../service/Mantenimiento';
import { InputText } from 'primereact/inputtext';
import { filtrarEmpresasInternos } from '../../../service/Empresas';
const estadoInicial = {
    empresa: "",
    categoria: "",
    subcategoria: "",
    actividades: []
}
export default function ModalNuevaTarea(props) {
    const [empresas, setEmpresas] = useGlobal("empresas");
    const [cicloId, setCicloId] = useGlobal("ciclo");
    const [actividades, setActividades] = useGlobal("actividadesMantenimiento");
    const [categorias, setCategorias] = useGlobal("categorias");
    const [errores, setErrores] = useState({})
    const [cargando, setCargando] = useGlobal("cargando");
    const [agente, setAgente] = useGlobal("agente");
    const [, setTriggerReload] = useGlobal("triggerReload");
    const [state, setState] = useState({ ...estadoInicial })
    const [locaciones] = useGlobal("sitiosHTF");
    const [sumaTiempoEstimadoSeleccionado, setSumaTiempoEstimadoSeleccionado] = useState(0)
    const [actividadesOptions, setActividadesOptions] = useState([])
    const [subcategoriasOptions, setSubcategoriasOptions] = useState([])
    const [preseleccionEnProceso, setPreseleccionEnProceso] = useState(false)

    useEffect(() => {
        if(state.actividades.length === 0) return
        if(state.categoria && state.subcategoria) return
        if(actividades.length===0) return
        if(categorias.length===0) return

        const actividad = actividadesOptions.find(act => act.id === state.actividades[0].id)
        const categoria = categorias.find(cate=>cate.id === actividad.categoria_ref)
        const subcategoria = categoria.subcategorias.find(subc=>subc.id === actividad.subcategoria_ref)
        const newSubcategoriasOptions = getSubcategoriasOpciones(categoria.id)
        const newActividadesOptions = getActividadesOpciones(subcategoria.id)

        setPreseleccionEnProceso(true)
        setSubcategoriasOptions(newSubcategoriasOptions)
        setActividadesOptions(newActividadesOptions)
        setState({...state, categoria: categoria.id, subcategoria: subcategoria.id})
    },[state.actividades])

    useEffect(() => {
        if(state.categoria) return
        if(subcategoriasOptions.length===0) return
        if(!state.subcategoria) return

        const subcategoria = subcategoriasOptions.find(subc=>subc.id === state.subcategoria)
        const cloneState = DeepClone(state)

        setPreseleccionEnProceso(true)
        setState({...cloneState, categoria: subcategoria.categoria_ref})
    },[state.subcategoria])

    useEffect(() => {
        if(!state.subcategoria) return
        const actividadesOptions = getActividadesOpciones(state.subcategoria)
        setActividadesOptions(actividadesOptions)
    },[actividades, state])

    useEffect(() => {
        const subcategoriasOptions = getSubcategoriasOpciones(state.categoria)
        const newActividadesOptions = getActividadesOpcionesPorCategoriaId(state.categoria, state.subcategoria)
        setSubcategoriasOptions(subcategoriasOptions)
        setActividadesOptions(newActividadesOptions)
    },[categorias, state.categoria])
    
    useEffect(() => {
        setErrores({})
    }, [state, props.show])

    useEffect(() => {
        if (props.registrarEmergencia) {
            setState({
                actividades: [], empresa: "", locacion: "", empresa_ref: "", nombreTarea: "", categoria: "category_emergency", categoria_ref: "category_emergency",
                subcategoria_ref: "subcategory_emergency", subcategoria: "subcategory_emergency", actividad_ref: "emergencia",
                is_emergencia: true, agente_ref: agente.id, dia: props.dia.unixTime
            })
        }
    }, [props.registrarEmergencia])

    useEffect(() => {
        if (!props.registrarEmergencia) setState({ ...estadoInicial })
    }, [props.show])

    useEffect(() => {
        if (state.actividades && state.empresa && state.locacion) {
            const tareasConDatos = state.actividades.map(act => ({ actividad_ref: act.id, empresa_ref: state.empresa, sitioHTF: state.locacion }));
            const sumaTiempo = getSumaTotalTiempoEstimado(actividades, tareasConDatos);
            setSumaTiempoEstimadoSeleccionado(sumaTiempo);
        }
    }, [state.actividades, state.empresa, state.locacion])

    useEffect(() => {
        if(preseleccionEnProceso) return setPreseleccionEnProceso(false)
        if (!props.registrarEmergencia) { 
            setState({ ...state, subcategoria: "", actividades: [] }) 
        }
    }, [state.categoria])

    useEffect(() => {
        if(preseleccionEnProceso) return setPreseleccionEnProceso(false)
        if(!state.categoria) return
        setState({ ...state, actividades: [] })
    }, [state.subcategoria])

    const procesarTiemposPorLocacion = () => {
        const actividadesCopia = DeepClone(state.actividades)
        const { empresa, locacion } = state
        actividadesCopia.forEach((actividad) => {
            const tiempoPorLocacion = actividad?.tiempos_estimados?.[empresa]?.[locacion];

            if (tiempoPorLocacion) {
                actividad.tiempo_estimado = tiempoPorLocacion
                actividad.is_tiempo_estimado_locacion = true
            }
            else {
                delete actividad.is_tiempo_estimado_locacion
            }
        })
        return actividadesCopia
    }

    const handleGuardar = async (_, seguir) => {
        if (validarErrores()) {
            if (!seguir) props.hide()
            setCargando(true);
            let nuevasTareas = []
            let newActivities = procesarTiemposPorLocacion()
            // let { actividades, categoria, subcategoria, ...allData } = state
            if (props.registrarEmergencia) {
                const datosEmergencia = { ...state, empresa_ref: state.empresa, sitioHTF: state.locacion };
                nuevasTareas = [await guardarActividadEmergencia(cicloId, datosEmergencia)]
            }
            else nuevasTareas = await guardarActividades(cicloId, newActivities, agente.id, props.dia.unixTime, state.empresa, state.locacion);

            let tareas = [...agente.tareas, ...nuevasTareas]
            setAgente({ ...agente, tareas })
            setState({ ...state, actividades: [] })
            setCargando(false);
        }
    }
    const validarErrores = () => {
        let errores = {}, required = ["empresa", "categoria", "subcategoria"]
        required.forEach(field => {
            if (!state[field]) errores[field] = true
        })
        if (state["actividades"].length === 0 && !props.registrarEmergencia) errores["actividades"] = true
        if (!state["nombreTarea"] && props.registrarEmergencia) errores["nombreTarea"] = true
        if (getNombrePorID(empresas, state.empresa) === "HTF" && !state.locacion) errores["locacion"] = true

        setErrores(errores)
        return Object.keys(errores).length === 0
    }

    const handleChange = (event) => {
        let stateCopy = { ...state }, { name, value } = event.target;
        stateCopy[name] = value;
        if(name === "empresa" && !empresaIsHTF(empresas, value)) { stateCopy.locacion = getLocacionAgraparkID(locaciones); }
        if(name === "actividades" && state.actividades.length > 0 && value.length === 0 && state.categoria && state.subcategoria) {
            return setState({ ...stateCopy, categoria: "", subcategoria: "", actividades: [] }) 
        }
        setState(stateCopy);
    }
    const getCategoriaOpciones = () => props.registrarEmergencia ? categorias.filter(el => el.classname === "urgente" && el.classname !== "other") : categorias.filter(el => el.classname !== "urgente" && el.classname !== "other")
    const getSubcategoriasOpciones = (categoriaId) => {
        const subcatsTodos = categorias.map(el => el.subcategorias).flat();
        const subcats = subcatsTodos.map(el => ({ ...el, nombre: `${el?.indice} - ${el?.nombre}` }));
        if(!categoriaId) return subcats
        return subcats.filter(el => !el.sinMostrar && el.categoria_ref === categoriaId)
    }
    const getActividadesOpciones = (subcategoriaId) => {
        if (actividades?.length > 0) {
            const filtered = actividades.filter(a => !a.sinMostrar)
            const opcionesActs = filtered.map(el => ({ ...el, nombre: `${el.indice} - ${el.nombre}` }))
            if(!subcategoriaId) return opcionesActs
            return opcionesActs.filter(a => a.subcategoria_ref === subcategoriaId)
        } else return []
    }
    const getActividadesOpcionesPorCategoriaId = (categoriaId, subcategoriaIdId) => {
        if (actividades?.length > 0) {
            const filtered = actividades.filter(a => !a.sinMostrar)
            const opcionesActs = filtered.map(el => ({ ...el, nombre: `${el.indice} - ${el.nombre}` }))
            if(!categoriaId) return opcionesActs
            return opcionesActs.filter(a => a.categoria_ref === categoriaId && (!subcategoriaIdId || a.subcategoria_ref === subcategoriaIdId))
        } else return []
    }

    let nombreDia = getNombreFechaCompacto(props.dia.unixTime)
    const footer = <div>
        <Button type="button" className="btn btn-secondary" onClick={props.hide} label="Cancelar" />
        {!props.registrarEmergencia &&
            <Button type="button" className="btn btn-secondary" onClick={handleGuardar.bind(this, null, true)} label="Guardar y seguir agregando" />}
        <Button type="button" className="btn btn-secondary" onClick={handleGuardar} label={props.registrarEmergencia ? "Registrar emergencia" : "Guardar actividad"} />
    </div>
    const disableSelectAll = !Boolean(state.subcategoria)
    return (
        <Dialog className="modal-35" header={`${props.registrarEmergencia ? "Registrar emergencia el" : "Agregar actividad a"} ${nombreDia}`} footer={footer} visible={!!props.show} onHide={props.hide} >
            <ContenedorInput label="Empresa">
                <Dropdown name="empresa" value={state.empresa} options={filtrarEmpresasInternos(empresas)} onChange={handleChange} optionLabel="nombre" optionValue="id" />
                {errores.empresa && <Message severity="error" text="Este campo es requerido" />}
            </ContenedorInput>

            {empresaIsHTF(empresas, state.empresa) &&
                <ContenedorInput label="Locación">
                    <Dropdown name="locacion" value={state.locacion} options={locaciones} onChange={handleChange} optionLabel="nombre" optionValue="id" />
                    {errores.locacion && <Message severity="error" text="Este campo es requerido" />}
                </ContenedorInput>}

            <ContenedorInput label="Categoria">
                <Dropdown name="categoria" disabled={props.registrarEmergencia} value={state.categoria} options={getCategoriaOpciones()} onChange={handleChange} optionLabel="nombre" optionValue="id" />
                {errores.categoria && <Message severity="error" text="Este campo es requerido" />}
            </ContenedorInput>
            {!props.registrarEmergencia &&
                <ContenedorInput label="Subcategoria">
                    <Dropdown name="subcategoria" value={state.subcategoria} options={subcategoriasOptions} onChange={handleChange}
                        filter={true} filterPlaceholder="Buscar subcategorias" optionLabel="nombre" optionValue="id" />
                    {errores.subcategoria && <Message severity="error" text="Este campo es requerido" />}
                </ContenedorInput>}
            {!props.registrarEmergencia &&
                <ContenedorInput label="Actividades" extra={`${disableSelectAll ? "disable-selectAll": ""}`}>
                    <MultiSelect name="actividades" value={state.actividades} options={actividadesOptions} onChange={handleChange} optionLabel="nombre" dataKey="id"
                        filter={true} filterPlaceholder="Buscar actividades" selectedItemsLabel={`${state.actividades.length} actividades seleccionadas`} />
                    {errores.actividades && <Message severity="error" text="Este campo es requerido" />}
                </ContenedorInput>}
            {props.registrarEmergencia &&
                <ContenedorInput label="Nombre tarea">
                    <InputText name="nombreTarea" value={state.nombreTarea || ""} onChange={handleChange} />
                </ContenedorInput>}
            {errores.nombreTarea && <Message severity="error" text="Este campo es requerido" />}

            {!props.registrarEmergencia &&
                <SeccionDatosCenter>
                    <DatoCard label="Horas totales estimadas" valor={formatearMinutosAHoras(props.tiempo_estimado)} />
                    <DatoCard label="Horas totales seleccionadas" valor={formatearMinutosAHoras(sumaTiempoEstimadoSeleccionado)} />
                </SeccionDatosCenter>}
        </Dialog>
    )
}//137