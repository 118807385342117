import React from 'react';

import InvernaderoCard from "./InvernaderoCard";
import OtroCard from "./OtroCard";

import { faUsers,faBoxes } from '@fortawesome/free-solid-svg-icons';

const invernaderosMuestra = [
    { empleados:"25/26", activos:"96%", color:"green",invernadero:"Invernadero 1",nombre:"H1A"},
    { empleados:"25/26", activos:"96%", color:"green-dark",invernadero:"Invernadero 1",nombre:"H1B" },
    { empleados:"25/26", activos:"96%", color:"blue",invernadero:"Invernadero 2",nombre:"H2" },
    { empleados:"25/26", activos:"96%", color:"blue-dark",invernadero:"Invernadero 3",nombre:"H3" },
    { empleados:"25/26", activos:"96%", color:"orange",invernadero:"Invernadero 4",nombre:"F1" },
    { empleados:"25/26", activos:"96%", color:"orange-dark",invernadero:"Invernadero 5",nombre:"F2" },
    { empleados:"25/26", activos:"96%", color:"red",invernadero:"Invernadero 6",nombre:"I1" },
    { empleados:"25/26", activos:"96%", color:"red-dark",invernadero:"Invernadero 7",nombre:"H2A" },
    { empleados:"25/26", activos:"96%", color:"yellow",invernadero:"Invernadero 8",nombre:"H2B" },
    { empleados:"25/26", activos:"96%", color:"yellow-dark",invernadero:"Invernadero 8",nombre:"H2C" },
]
const otroMuestra = [
    { empleados:"25", color:"purple", nombre:"Agropark", icono:faBoxes },
    { empleados:"25", color:"purple-dark", nombre:"Atongo", icono:faBoxes },
    { empleados:"25", color:"pink", nombre:"Otros departamentos", icono:faUsers },
]
function InvernaderosEmpleados(){
    return <div className="p-grid">
      
      <div className="p-col-12">
        <div className="p-grid">
        {invernaderosMuestra.map(invernadero=><InvernaderoCard invernadero = {invernadero}/>)}
        {otroMuestra.map(otro=><OtroCard otro = {otro}/>)}
        </div>
      </div>

    </div>
}

export default InvernaderosEmpleados;