import React from 'react';
import { Card } from 'primereact/card';
import ClipLoader from 'react-spinners/ClipLoader';

const InvernaderoBiocontrolCard = (props) => {
    const header = <span className="card-header">
        <div className={`card-id icon-general icon-id bg-${props.invernadero?.color}  bor-${props.invernadero?.color}`}>
            {props.invernadero?.nombre}
        </div>
    </span>;

    return (
        <div className="p-col-12 p-md-4 p-sm-6" onClick={props.onClick}>
            <Card header={header} className={`htg-card htg-card--fisico bor-${props.invernadero?.color}`}>
                {props.home && props.cargandoHome
                    ? <div className="card-spinner-centered">
                        <ClipLoader sizeUnit="px" size={100} color="#003E66" loading={true} />
                    </div>
                    : <div className="p-grid p-justify-center text-center">
                        <div className="p-col-6">
                            <div className="card-block">
                                <p className="value">{props.info?.enfermedades}</p>
                                <p className="label">Enfermedades</p>
                            </div>
                        </div>
                        <div className="p-col-6">
                            <div className="card-block">
                                <p className="value">{props.info?.incidencia}%</p>
                                <p className="label">Afectación porcentual</p>
                            </div>
                        </div>

                        <div className="p-col-3">
                            <div className="card-block">
                                <p className="value">{props.info?.cancer}%</p>
                                <p className="label">Cancer bacteriano</p>
                            </div>
                        </div>
                        <div className="p-col-3">
                            <div className="card-block">
                                <p className="value">{props.info?.cancer ? "Sí" : "No"}</p>
                                <p className="label">Cancer bacteriano</p>
                            </div>
                        </div>

                        <div className="p-col-3">
                            <div className="card-block">
                                <p className="value">{props.info?.virus_mosaico}%</p>
                                <p className="label">Virus del mosaico</p>
                            </div>
                        </div>
                        <div className="p-col-3">
                            <div className="card-block">
                                <p className="value">{props.info?.virus_mosaico ? "Sí" : "No"}</p>
                                <p className="label">Virus del mosaico</p>
                            </div>
                        </div>

                    </div>}
            </Card>
        </div>
    );
}
export default InvernaderoBiocontrolCard;//60