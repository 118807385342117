import React, { useEffect, useGlobal, useState } from 'reactn';
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { getPresentaciones } from '../../service/Presentaciones';
import { obtenerReporteGeneralPesosDiariosAccumulados, obtenerReportePesos, obtenerReportePesosCiclo } from '../../service/ReporteCalidad';
import { getProductoYVariedadString, selectedItemPresentacionesMultiselect, selectedItemNombresMultiselect, ordenarArreglaDeObjetosPorLlave, dosDecimales, getObjetoPorID, DeepClone, separadoComas, dosDeciComas, sumarNumerosArrayPorCampo, getObjetoPorLlave, ordenarArreglaDeObjetosPor2Llaves, ordenarArreglaDeObjetosPorLlaveFloat } from '../../util/functions';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { MultiSelect } from 'primereact/multiselect';
import ContenedorFiltro from '../common/ContenedorFiltro';
import { Button } from 'primereact/button';
import icono from '../../images/icons/external-icon.svg';
import ContenedorHeader from 'components/common/ContenedorHeader';
import { obtenerNombreFecha } from 'service/fechas';
import ContenedorCardFormulario from 'components/common/ContenedorCardFormulario';
import { ASCENDIENTE, DESCENDIENTE, segundosSemana } from '../../constants';
import { procesarAcumulado, procesarReporte } from 'service/Calidad';
import { obtenerEmpacadoras } from 'service/Empacadoras';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleDown, faArrowCircleUp, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { ToggleButton } from 'primereact/togglebutton';
import { obtenerClientesCiclo } from 'service/Clientes';
import { obtenerGruposPresentaciones } from 'service/QueriesGruposPresentaciones';
import { SelectButton } from 'primereact/selectbutton';
import { UnidadKilos, UnidadKilosBg } from 'components/common/templates_unidades/UnidadKg.atomo';
import { calcularMuestraPorcentajeDesviaciones, calcularMuestraPromedios, getRegistrosCalidadPesosCiclo, getResumenReporteGeneralPesosDiarios } from 'service/helpers/Calidad.helper';
import { ordenarInvernaderosVirtualesPorIndexFisicoYNombre } from 'service/Invernaderos';

const opcionesEmpaque = [
    { label: "Manual", value: "manual" },
    { label: "Automática", value: "auto" },
    { label: "Total", value: "total" }];
const opcionesAcumulado = [{ label: "Semana", value: "semana" }, { label: "Ciclo", value: "ciclo" }];
const ReporteGeneralPesosDiarios = (props) => {
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [, setCargando] = useGlobal("cargando");
    const [ciclo,] = useGlobal("ciclo");
    const [cicloObj] = useGlobal("cicloObj");
    const [invernaderos] = useGlobal("invernaderosVirtuales");
    const [, setInvernadero] = useGlobal("invernadero");
    const [presentaciones, setPresentaciones] = useGlobal("presentaciones");
    const [gruposPresentaciones, setGruposPresentaciones] = useGlobal("gruposPresentaciones");
    const [productosBases] = useGlobal("productosBases");
    const [clientesOrig] = useGlobal("clientes");
    const [weeks, week, handleWeekChange] = useWeekSelector();

    const [registrosPesosTodosCiclo, setRegistrosPesosTodosCiclo] = useGlobal("registrosPesosTodosCiclo");
    ///rows///
    const [rowsPresentacionesFiltradasSemana, setRowsPresentacionesFiltradasSemana] = useState([]);
    const [rowsPresentacionesSemanaOrig, setRowsPresentacionesSemanaOrig] = useState();

    const [rowsGruposFiltradosSemana, setRowsGruposFiltradosSemana] = useState([]);
    const [rowsGruposSemanaOrig, setRowsGruposSemanaOrig] = useState();
    //////////

    const [presentacionesPresentes, setPresentacionesPresentes] = useState([]);
    const [gruposPresentes, setGruposPresentes] = useState([]);

    const [filtroPresentaciones, setFiltroPresentaciones] = useState([]);
    const [filtroPresentacionesCascada, setFiltroPresentacionesCascada] = useState([]);
    const [filtroGrupos, setFiltroGrupos] = useState([]);
    const [filtroGruposCascada, setFiltroGruposCascada] = useState([]);

    const [filtroInvernaderos, setFiltroInvernaderos] = useState();
    const [filtroInvernaderosCascada, setFiltroInvernaderosCascada] = useState();
    const [filtroProductos, setFiltroProductos] = useState();
    const [filtroProductosCascada, setFiltroProductosCascada] = useState();
    const [filtroClientes, setFiltroClientes] = useState();
    const [filtroClientesCascada, setFiltroClientesCascada] = useState();

    const [showGruposToggle, setShowGruposToggle] = useState(false);
    const [showAcumuladoToggle, setShowAcumuladoToggle] = useState([opcionesAcumulado[0].value, opcionesAcumulado[1].value]);
    const [tipoEmpaque, setTipoEmpaque] = useState(opcionesEmpaque[2].value);

    const [clientes, setClientes] = useState([]);
    const [comentarios, setComentarios] = useState([]);
    const [ordenNombreColumna, setOrdenNombreColumna] = useState(null);

    useEffect(() => {
        setNombreModulo("Calidad");
        setHabilitadoSelectorCiclo(true);
    }, [])
    useEffect(() => {
        if (ciclo) { obtenerDatosIniciales(); }//Utilizar orden de booleanos para revisar tema: (acumuladoCiclo || primeraCarga) vs. (primeraCarga || acumuladoCiclo)
    }, [ciclo])
    useEffect(() => {//Filtrar todos los registros por la semana seleccionada
        if (registrosPesosTodosCiclo && week.nombre !== "Cargando") {
            const filtrados = registrosPesosTodosCiclo?.filter(el => el.momento > week.time && el.momento < (week.time + segundosSemana)) || [];
            console.log("REGISTROS FILTRADOS SEMANA!! ", filtrados);
            procesarResumen(filtrados);
        }
    }, [registrosPesosTodosCiclo, week])

    const obtenerDatosIniciales = async () => {
        setCargando(true);
        console.log("1 BEGIN - obtenerDatosIniciales");
        const [clientesCicloResp, presentacionesResp, gruposPresentacionesResp] =
            await Promise.all([obtenerClientesCiclo(ciclo), getPresentaciones(), obtenerGruposPresentaciones()]);
        const clientesOpciones = clientesCicloResp.map(el => getObjetoPorID(clientesOrig, el.cliente_ref));
        setClientes(clientesOpciones);
        setPresentaciones(presentacionesResp);
        setGruposPresentaciones(gruposPresentacionesResp);

        console.log("2 GET REGISTROS DE CALIDAD TODOS DEL CICLO ");
        const registrosCalidadTodos = [];
        const promisesPesos = invernaderos.map(invernadero => getRegistrosCalidadPesosCiclo(cicloObj, invernadero.id, week.time));
        const registros = await Promise.all(promisesPesos);

        console.log("3 PROCESAR REGISTROS: ", registros);
        invernaderos.forEach((invernadero, index) => {
            const procesados = registros[index]?.map((datos, _) => ({ ...datos, invernadero_ref: invernadero.id })) || [];
            registrosCalidadTodos.push(...procesados);
        })
        setRegistrosPesosTodosCiclo(registrosCalidadTodos);
        setCargando(false);
    }

    ///////////FILTRANDO EN CASCADA///////////////
    useEffect(() => {//CLIENTE y PRODUCTO - cascada con presentaciones/grupos e invernaderos, pero no cascada entre ellos dos
        if (filtroClientesCascada && filtroProductosCascada && rowsPresentacionesSemanaOrig && rowsGruposSemanaOrig) {
            const rowsPresentacionesCopia = DeepClone(rowsPresentacionesSemanaOrig), rowsGruposCopia = DeepClone(rowsGruposSemanaOrig);
            const rowsFiltradosClienteYProductoGrupos = rowsGruposCopia.filter(row => checarIncluirRow(row, "grupo"));
            const rowsFiltradosClienteYProductoPresentaciones = rowsPresentacionesCopia.filter(row => checarIncluirRow(row, "presentacion"));

            //console.log("FILTRAR - CLIENTE Y PRODUCTO: ", rowsFiltradosClienteYProductoPresentaciones)
            const invernaderosFiltroNuevo = invernaderos?.filter(inv => rowsFiltradosClienteYProductoPresentaciones.some(row => row.invernaderoDatos?.[inv.id]?.resumenTotal?.muestras))
            const presentacionesFiltroNuevo = presentacionesPresentes?.filter(pres => rowsFiltradosClienteYProductoPresentaciones.some(row => row.presentacion?.id === pres.id))
            const gruposFiltroNuevo = gruposPresentes?.filter(grupo => rowsFiltradosClienteYProductoGrupos.some(row => row.grupo?.id === grupo.id))

            ordenarArreglaDeObjetosPor2Llaves(rowsFiltradosClienteYProductoGrupos, "sumaKg", "nombre", DESCENDIENTE);
            ordenarArreglaDeObjetosPor2Llaves(rowsFiltradosClienteYProductoPresentaciones, "sumaKg", "nombre", DESCENDIENTE);
            const rowGrupo = filtrarPorInv(rowsFiltradosClienteYProductoGrupos, invernaderosFiltroNuevo);
            const rowPres = filtrarPorInv(rowsFiltradosClienteYProductoPresentaciones, invernaderosFiltroNuevo)
            setRowsGruposFiltradosSemana(rowGrupo);
            setRowsPresentacionesFiltradasSemana(rowPres);

            setFiltroClientes(filtroClientesCascada);
            setFiltroProductos(filtroProductosCascada);

            ordenarInvernaderosVirtualesPorIndexFisicoYNombre(invernaderosFiltroNuevo)
            setFiltroInvernaderos(invernaderosFiltroNuevo);
            setFiltroPresentaciones(presentacionesFiltroNuevo);
            setFiltroGrupos(gruposFiltroNuevo);
        }
    }, [filtroClientesCascada, filtroProductosCascada])
    useEffect(() => {//PRESENTACIONES/GRUPOS - cascada con TODO
        if (filtroPresentacionesCascada && filtroGruposCascada && rowsPresentacionesSemanaOrig && rowsGruposSemanaOrig) {
            const rowsPresentacionesCopia = DeepClone(rowsPresentacionesSemanaOrig), rowsGruposCopia = DeepClone(rowsGruposSemanaOrig);
            const rowsFiltradosClienteYProductoGrupos = rowsGruposCopia.filter(row => filtroGruposCascada?.some(grupo => grupo.id === row.grupo.id));
            const rowsFiltradosClienteYProductoPresentaciones = rowsPresentacionesCopia.filter(row => filtroPresentacionesCascada?.some(pres => pres.id === row.presentacion.id));

            //console.log("FILTRAR - PRESENTACIONES/GRUPOS: ", rowsFiltradosClienteYProductoPresentaciones);
            const clienteFiltroNuevo = clientes?.filter(cliente => rowsFiltradosClienteYProductoPresentaciones.some(row => row.presentacion.cliente_ref === cliente.id));
            const productoFiltroNuevo = productosBases?.filter(producto => rowsFiltradosClienteYProductoPresentaciones.some(row => row.presentacion.producto_ref === producto.id));
            const invernaderosFiltroNuevo = invernaderos?.filter(inv => rowsFiltradosClienteYProductoPresentaciones.some(row => row.invernaderoDatos?.[inv.id]?.resumenTotal?.muestras))

            ordenarArreglaDeObjetosPor2Llaves(rowsFiltradosClienteYProductoGrupos, "sumaKg", "nombre", DESCENDIENTE);
            ordenarArreglaDeObjetosPor2Llaves(rowsFiltradosClienteYProductoPresentaciones, "sumaKg", "nombre", DESCENDIENTE);
            const rowGrupo = filtrarPorInv(rowsFiltradosClienteYProductoGrupos, invernaderosFiltroNuevo);
            const rowPres = filtrarPorInv(rowsFiltradosClienteYProductoPresentaciones, invernaderosFiltroNuevo)
            setRowsGruposFiltradosSemana(rowGrupo);
            setRowsPresentacionesFiltradasSemana(rowPres);

            setFiltroClientes(clienteFiltroNuevo);
            setFiltroProductos(productoFiltroNuevo);
            setFiltroInvernaderos(invernaderosFiltroNuevo);

            setFiltroPresentaciones(filtroPresentacionesCascada);
            setFiltroGrupos(filtroGruposCascada);
        }
    }, [filtroPresentacionesCascada, filtroGruposCascada])
    useEffect(() => {//INVERNADERO - cascada con TODO
        if (filtroInvernaderosCascada && rowsPresentacionesSemanaOrig && rowsGruposSemanaOrig) {
            const rowsPresentacionesCopia = DeepClone(rowsPresentacionesSemanaOrig), rowsGruposCopia = DeepClone(rowsGruposSemanaOrig);
            const rowsFiltradosClienteYProductoGrupos = rowsGruposCopia.filter(row => filtroInvernaderosCascada?.some(inv => row.invernaderoDatos?.[inv.id]?.resumenTotal?.muestras));
            const rowsFiltradosClienteYProductoPresentaciones = rowsPresentacionesCopia.filter(row => filtroInvernaderosCascada?.some(inv => row.invernaderoDatos?.[inv.id]?.resumenTotal?.muestras));

            const clienteFiltroNuevo = clientes?.filter(cliente => rowsFiltradosClienteYProductoPresentaciones.some(row => row.presentacion.cliente_ref === cliente.id));
            const productoFiltroNuevo = productosBases?.filter(producto => rowsFiltradosClienteYProductoPresentaciones.some(row => row.presentacion.producto_ref === producto.id));
            const presentacionesFiltroNuevo = presentacionesPresentes?.filter(pres => rowsFiltradosClienteYProductoPresentaciones.some(row => row.presentacion?.id === pres.id))
            const gruposFiltroNuevo = gruposPresentes?.filter(grupo => rowsFiltradosClienteYProductoGrupos.some(row => row.grupo?.id === grupo.id))

            //console.log("FILTRAR - PRESENTACIONES/GRUPOS: ", rowsFiltradosClienteYProductoPresentaciones);
            ordenarArreglaDeObjetosPor2Llaves(rowsFiltradosClienteYProductoGrupos, "sumaKg", "nombre", DESCENDIENTE);
            ordenarArreglaDeObjetosPor2Llaves(rowsFiltradosClienteYProductoPresentaciones, "sumaKg", "nombre", DESCENDIENTE);

            const rowGrupo = filtrarPorInv(rowsFiltradosClienteYProductoGrupos, filtroInvernaderosCascada);
            const rowPres = filtrarPorInv(rowsFiltradosClienteYProductoPresentaciones, filtroInvernaderosCascada)
            setRowsGruposFiltradosSemana(rowGrupo);
            setRowsPresentacionesFiltradasSemana(rowPres);

            setFiltroClientes(clienteFiltroNuevo);
            setFiltroProductos(productoFiltroNuevo);

            setFiltroPresentaciones(presentacionesFiltroNuevo);
            setFiltroGrupos(gruposFiltroNuevo);

            setFiltroInvernaderos(filtroInvernaderosCascada);
        }
    }, [filtroInvernaderosCascada])

    const checarIncluirRow = (row, llaveTipo) => {
        const porCliente = filtroClientesCascada?.some(el => el.id === row[llaveTipo].cliente_ref);
        const porProducto = filtroProductosCascada?.some(el => el.id === row[llaveTipo].producto_ref);
        return porCliente && porProducto;
    }
    /////////////////////////////////////////////

    const procesarResumen = /* async */ (registrosSemana) => {
        const registrosCicloFiltrados = registrosPesosTodosCiclo?.filter(el => el.momento < (week.time + segundosSemana));//Filtrar registros hasta la semana seleccionada
        const { invernaderosPresentes, comentarios, resumenCalidadPesosPresentacionesCiclo, resumenCalidadPesosPresentacionesSemana, resumenCalidadPesosGruposCiclo, resumenCalidadPesosGruposSemana }
            = getResumenReporteGeneralPesosDiarios(registrosCicloFiltrados, registrosSemana, invernaderos, presentaciones, gruposPresentaciones);
        //console.log("1 procesarResumen - GET RESUMENES", { presentacionesPresentes, invernaderosPresentes, resumenCalidadPesosPresentacionesCiclo, resumenCalidadPesosPresentacionesSemana });

        const rowsPresentacionesSemana = procesarRowsDeResumen(resumenCalidadPesosPresentacionesSemana, resumenCalidadPesosPresentacionesCiclo);
        const rowsGruposSemana = procesarRowsGruposDeResumen(resumenCalidadPesosGruposSemana, resumenCalidadPesosGruposCiclo);
        //console.log("2 procesarResumen - rowsPresentaciones - procesarRowsDeResumen", { rowsPresentacionesSemana, });

        const gruposPresentes = rowsGruposSemana.map(row => row.grupo);
        const presentacionesPresentes = rowsPresentacionesSemana.map(row => row.presentacion);
        setPresentacionesPresentes(presentacionesPresentes);
        setGruposPresentes(gruposPresentes);
        setComentarios(comentarios);

        setFiltroPresentaciones(presentacionesPresentes);
        setFiltroGrupos(gruposPresentes);
        setFiltroInvernaderos(invernaderosPresentes);

        setRowsPresentacionesSemanaOrig(rowsPresentacionesSemana);
        setRowsGruposSemanaOrig(rowsGruposSemana);


        //FILTROS
        setFiltroClientesCascada([clientes[1]]);//Al entrar a la pantalla solo filtrar un cliente
        setFiltroProductosCascada(productosBases);
    }
    const filtrarPorInv = (rows = [], invernaderosFiltroNuevo = []) => {
        const rowsCopia = DeepClone(rows);
        rowsCopia.forEach(datosPres => {
            if (datosPres.invernaderoDatos) {
                filtrarDatosPorInv(datosPres, invernaderosFiltroNuevo);
            }
            if (datosPres.acumuladoCicloDatos?.invernaderoDatos) {
                filtrarDatosPorInv(datosPres.acumuladoCicloDatos, invernaderosFiltroNuevo);
            }
        })
        console.log("--- filtrarPorInv ----- RESUMEN COPIA: ", rowsCopia)
        return rowsCopia;
    }
    const filtrarDatosPorInv = (datosPres, invernaderosFiltroNuevo = []) => {

        const invernaderoIds = Object.keys(datosPres?.invernaderoDatos || {})
        //if (datosPres.nombre === "WST-GRP-12X2LB-TS-USA-FT") {console.log("0 TESTING::::: ", { datosPres }); }

        invernaderoIds.forEach(invId => {
            if (!invernaderosFiltroNuevo?.some(el => el.id === invId)) { delete datosPres?.invernaderoDatos[invId]; }
        })

        const resumenInicial = {
            muestras: 0, normales: 0, altos: 0, bajos: 0,//Totales de muestras de cada tipo de calidad
            muestrasSumaKilos: 0, normalesSumaKilos: 0, altosSumaKilos: 0, bajosSumaKilos: 0,//Suma de kilos de las muestras de cada tipo
        }
        //Asignar a 0
        datosPres.resumenAuto = { ...resumenInicial }
        datosPres.resumenManual = { ...resumenInicial }
        datosPres.resumenTotal = { ...resumenInicial }

        //SUMAR DE NUEVO
        Object.entries(datosPres.invernaderoDatos).forEach(([invId, datosInv]) => {
            Object.entries(datosInv).forEach(([llaveEmpaque, datosEmpaque]) => {
                Object.entries(datosEmpaque).forEach(([llave, valor]) => {
                    datosPres[llaveEmpaque][llave] += valor
                })
            })
        })
        datosPres.sumaKg = datosPres.resumenTotal.muestrasSumaKilos;
        console.log("--- filtrarDatosPorInv ----- datosPres: ", datosPres)
    }

    const procesarRowsGruposDeResumen = (resumenCalidad, rowsCiclo) => procesarRowsDeResumen(resumenCalidad, rowsCiclo, "grupo");
    const procesarRowsDeResumen = (resumenCalidad = {}, resumenCiclo = {}, llaveTipo = "presentacion") => {
        let rowsProcesados = Object.entries(resumenCalidad).map(([_, datos]) => {
            const acumuladoCicloDatos = Object.entries(resumenCiclo)?.find(([_, datosCiclo]) => datos[llaveTipo].id === datosCiclo[llaveTipo].id)?.[1]
                || { resumenAuto: {}, resumenManual: {}, resumenCiclo: {} }
            return { ...datos, acumuladoCicloDatos };
        });
        ordenarArreglaDeObjetosPor2Llaves(rowsProcesados, "sumaKg", "nombre", DESCENDIENTE);

        return rowsProcesados;
    }

    const nombrePresTemplate = (rowData) => {
        return (rowData.sumaKg
            ? <div>
                <Button className="calidad-tooltip-pesos" tooltip={`${dosDeciComas(rowData.sumaKg)} kg`} />
                <label>{rowData?.nombre}</label>
            </div>
            : <label>{rowData?.nombre}</label>);
    }
    const obtenerColumnas = () => {
        //console.log("1 obtenerColumnas: ")
        return (filtroInvernaderos?.map(inv =>
            <Column key={`c${inv.id}${Date.now()}`} field={inv.id} body={templateBody} className="rgps-celda-reporte" headerClassName="col__label rgps-celda-reporte" />) || []);
    }
    const obtenerHeader = () => {
        const columns = filtroInvernaderos?.map((inv, i) =>
            <Column key={`h${inv.id}-${i}`} className="col__label rgps-celda-header" headerClassName="rgps-celda-header" header={getHeaderIconoInvernadero(inv)} />);
        return (
            <ColumnGroup>
                <Row>{columns}</Row>
            </ColumnGroup>);
    }
    const getHeaderIconoInvernadero = (invernadero) => {
        return (
            <Button className="invisible-button" tooltip={getProductoYVariedadString(invernadero)} onClick={() => irReporte(invernadero)}>
                <div className={`icon-general icon-mini bor-${invernadero?.color} bg-${invernadero?.color}`} title={getProductoYVariedadString(invernadero)} >
                    {invernadero?.es_externo ? <img width="30" alt="icono" src={icono} /> : invernadero.nombre}
                </div>
            </Button>);
    }

    const templateBody = (rowData, column) => {
        const esAcumulado = column.field === "accumuladoPromedio", esCiclo = (esAcumulado && column.header === "Ciclo");
        const invernaderoId = column.field, isAuto = tipoEmpaque === "auto", isManual = tipoEmpaque === "manual";
        let datosResumen = rowData;

        if (!esAcumulado) {
            if (!rowData.invernaderoDatos?.[column.field]?.resumenTotal?.muestras) { return null; }
            if (!column?.field || !rowData?.invernaderoDatos?.[column?.field]) { return null; }
            datosResumen = rowData.invernaderoDatos[invernaderoId] || {};
        } else if (esCiclo) { datosResumen = rowData.acumuladoCicloDatos || {}; }

        //Seleccionar por tipo de empaque
        let { resumenAuto, resumenManual, resumenTotal } = datosResumen;
        if (!resumenTotal?.muestras) { return null; }


        resumenManual = calcularMuestraPromedios(resumenManual);
        resumenAuto = calcularMuestraPromedios(resumenAuto);
        resumenTotal = calcularMuestraPromedios(resumenTotal);
        const presentacionId = showGruposToggle ? getObjetoPorID(gruposPresentaciones, rowData.grupo.id).presentaciones_refs[0] : rowData.presentacion.id
        const presentacionBase = getObjetoPorID(presentaciones, presentacionId);
        resumenManual = calcularMuestraPorcentajeDesviaciones(resumenManual, presentacionBase, filtroInvernaderos);
        resumenAuto = calcularMuestraPorcentajeDesviaciones(resumenAuto, presentacionBase, filtroInvernaderos);
        resumenTotal = calcularMuestraPorcentajeDesviaciones(resumenTotal, presentacionBase);

        const datosFiltradosPorEmpaque = isAuto ? resumenAuto : (isManual ? resumenManual : resumenTotal);
        const { muestras, porcententajeNormales, porcententajeAltos, porcententajeBajos, porcentajeDesviacionNormales,
            porcentajeDesviacionAltos, porcentajeDesviacionBajos, porcentajeDesviacionMuestras } = datosFiltradosPorEmpaque;

        if (isAuto && !muestras && resumenTotal.muestras > 0) { return <span className="text-lighter">Solo hay registros manuales</span>; }
        else if (isManual && !muestras && resumenTotal.muestras > 0) { return <span className="text-lighter">Solo hay registros automáticos</span>; }
        else return (
            <div >
                <div className="muestras-totales" title="Muestras realizadas" >
                    {separadoComas(muestras)}<span className="label-unidad">Muestras/</span>
                    {porcentajeDesviacionMuestras}%<span className="label-unidad">Promedio</span>
                </div>
                <div className="pesos-porcentajes">
                    <div className="calidad-etiquetas" className="d-none" ></div>
                    <div className="datos-pesos peso-correcto">
                        <FontAwesomeIcon icon={faCheckCircle} title="Peso correcto" />
                        <div>
                            <div className="muestra" title="Desviación peso correcto">
                                {porcententajeNormales}<span className="label-unidad">%</span>
                            </div>
                            <div className="muestra" title="Peso promedio correcto">
                                {porcentajeDesviacionNormales}<span className="label-unidad">%</span>
                            </div>
                        </div>
                    </div>

                    <div className="datos-pesos sobre-maximo">
                        <FontAwesomeIcon icon={faArrowCircleUp} title="Peso excedido" />
                        <div>
                            <div className="muestra" title="Desviación peso excedido">
                                {porcententajeAltos}<span className="label-unidad">%</span>
                            </div>
                            <div className="muestra" title="Peso promedio excedido">
                                {porcentajeDesviacionAltos}<span className="label-unidad">%</span>
                            </div>
                        </div>
                    </div>

                    <div className="datos-pesos bajo-minimo">
                        <FontAwesomeIcon icon={faArrowCircleDown} title="Peso bajo" />
                        <div>
                            <div className="muestra" title="Desviación peso bajo">
                                {porcententajeBajos}<span className="label-unidad">%</span>
                            </div>
                            <div className="muestra" title="Peso promedio bajo">
                                {porcentajeDesviacionBajos}<span className="label-unidad">%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
    }

    const irReporte = (invernadero) => {
        setInvernadero(invernadero);
        props.history.push({ pathname: "/calidad/reporte", data: { reporte: "Pesos diarios" } });
    }
    const itemTemplateGrupos = (itemDatos) => {//return <strong>{itemDatos.nombre}</strong>;
        const datosGrupo = rowsGruposSemanaOrig?.find(row => row.grupo.id === itemDatos.id) || {};
        const { sumaKg = 0 } = datosGrupo;
        return (<label> <strong>{itemDatos.nombre}</strong> - {dosDeciComas(sumaKg)} kg </label>);
    }
    const itemTemplatePresentaciones = (itemDatos) => {
        const datosPres = rowsPresentacionesSemanaOrig?.find(row => row.presentacion.id === itemDatos.id) || {};
        const { sumaKg = 0 } = datosPres;
        return (<label > <strong>{itemDatos.presentacion}</strong>  - <UnidadKilosBg valor={dosDeciComas(sumaKg)} /> </label>);
    }
    const sortNombresPresentaciones = () => {
        /*         const [rowsPresentacionesCopia, rowsGruposCopia, rowsFiltradosCopia] = [DeepClone(rows), DeepClone(rowsGrupos), DeepClone(rowsFiltrados)];
                let sortOrder = !ordenNombreColumna ? 0 : (ordenNombreColumna * -1);
        
                if (sortOrder >= 0) {
                    sortOrder = sortOrder === 0 ? -1 : 1;
                    const direccionAOrdenar = sortOrder === 1 ? DESCENDIENTE : ASCENDIENTE;
                    ordenarArreglaDeObjetosPorLlave(rowsPresentacionesCopia, "nombrePresentacion", direccionAOrdenar, true);
                    ordenarArreglaDeObjetosPorLlave(rowsGruposCopia, "nombrePresentacion", direccionAOrdenar, true);
                    ordenarArreglaDeObjetosPorLlave(rowsFiltradosCopia, "nombrePresentacion", direccionAOrdenar, true);
                } else if (sortOrder == -1) {//Ordenar como originalmente, por kg DESC
                    sortOrder = 0;
                    ordenarArreglaDeObjetosPor2Llaves(rowsPresentacionesCopia, "sumaKg", "presentacion", DESCENDIENTE);
                    ordenarArreglaDeObjetosPor2Llaves(rowsGruposCopia, "sumaKg", "nombrePresentacion", DESCENDIENTE);
                    ordenarArreglaDeObjetosPor2Llaves(rowsFiltradosCopia, "sumaKg", "nombrePresentacion", DESCENDIENTE);
                }
                setOrdenNombreColumna(sortOrder);
                setRows(rowsPresentacionesCopia);
                setRowsGrupos(rowsGruposCopia)
                setRowsFiltrados(rowsFiltradosCopia); */
    }

    return (
        <div className="p-grid p-justify-center">
            <ContenedorHeader titulo="Reporte general de pesos diarios" iconos="quality-icon" atras={props.history.goBack} />

            <div className="p-col-12">
                <div className="p-grid filtros">
                    <WeekSelector estilo="form-group p-col p-col-3" weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
                    <ContenedorFiltro col="3" label="clientes de presentaciones">
                        <MultiSelect options={clientes} value={filtroClientes} optionLabel="nombre" dataKey="id" placeholder="Seleccione un cliente"
                            onChange={(e) => setFiltroClientesCascada(e.value)} selectedItemsLabel={`${filtroClientes?.length} cliente seleccionados`}
                            selectedItemTemplate={item => selectedItemNombresMultiselect(item, filtroClientes, clientes)} />
                    </ContenedorFiltro>
                    <ContenedorFiltro col="3" label="productos de presentaciones">
                        <MultiSelect options={productosBases} value={filtroProductos} optionLabel="nombre" dataKey="id" placeholder="Seleccione un producto"
                            onChange={(e) => setFiltroProductosCascada(e.value)} selectedItemsLabel={`${filtroProductos?.length} productos seleccionados`}
                            selectedItemTemplate={item => selectedItemNombresMultiselect(item, filtroProductos, productosBases)} />
                    </ContenedorFiltro>


                    <ContenedorFiltro col="3" label="invernaderos">
                        <MultiSelect options={invernaderos} value={filtroInvernaderos} optionLabel="nombre" dataKey="id" placeholder="Seleccione un invernadero"
                            onChange={(e) => setFiltroInvernaderosCascada(e.value)} selectedItemsLabel={`${filtroInvernaderos?.length} invernaderos seleccionados`}
                            selectedItemTemplate={item => selectedItemNombresMultiselect(item, filtroInvernaderos, invernaderos)} />
                    </ContenedorFiltro>

                    <ContenedorFiltro col="3" label={`${showGruposToggle ? "grupos " : ""}presentaciones`}>
                        {showGruposToggle
                            ? <MultiSelect options={gruposPresentes} value={filtroGrupos} optionLabel="nombre" dataKey="id" placeholder="Seleccione un grupo"
                                onChange={(e) => setFiltroGruposCascada(e.value)} selectedItemsLabel={`${filtroGrupos?.length} grupos seleccionados`}
                                selectedItemTemplate={item => selectedItemNombresMultiselect(item, filtroGrupos, gruposPresentes)} filter={true} itemTemplate={itemTemplateGrupos} />

                            : <MultiSelect options={presentacionesPresentes} value={filtroPresentaciones} optionLabel="presentacion" dataKey="id" placeholder="Seleccione una presentación"
                                onChange={(e) => setFiltroPresentacionesCascada(e.value)} selectedItemsLabel={`${filtroPresentaciones?.length} presentaciones seleccionadas`}
                                selectedItemTemplate={item => selectedItemPresentacionesMultiselect(item, filtroPresentaciones, presentacionesPresentes)} filter={true} itemTemplate={itemTemplatePresentaciones} />}
                    </ContenedorFiltro>
                    <ContenedorFiltro col="2" label="tipo">
                        <ToggleButton className="reactbtn-icon" onLabel="Grupos presentaciones" offLabel="Presentaciones" checked={showGruposToggle} onChange={(ev) => setShowGruposToggle(!!ev.target.value)} />
                    </ContenedorFiltro>

                    <ContenedorFiltro col="4" label="tipo empaque">
                        <SelectButton multiple={false} value={tipoEmpaque} options={opcionesEmpaque} onChange={e => { setTipoEmpaque(e.target.value || tipoEmpaque) }} name="tipo_empaque" />
                    </ContenedorFiltro>
                    <ContenedorFiltro col="3" label="Mostrar acumulado" sinFiltrarTexto>
                        <SelectButton multiple value={showAcumuladoToggle} options={opcionesAcumulado} onChange={e => { setShowAcumuladoToggle(e.target.value); }} name="tipo_empaque" />
                    </ContenedorFiltro>
                </div>
            </div>

            <React.Fragment>
                <div className="reporte-pesos-diarios-contenedor" >
                    <div className="card styles-tabla-pres-general" >
                        <DataTable className="" value={showGruposToggle ? rowsGruposFiltradosSemana : rowsPresentacionesFiltradasSemana} onSort={sortNombresPresentaciones} >
                            <Column sortable={true} sortField="nombre" key="pres" field="nombre" className={`col__label rgps-celda-reporte-pres rpd-pres-col-width`}
                                header="Nombre" headerClassName={`col__label rgps-celda-header-pres rpd-pres-col-width  ${ordenNombreColumna ? "p-highlight" : ""}`} body={nombrePresTemplate} />
                        </DataTable>
                    </div>

                    <div className="card styles-tabla-reporte-pesos">
                        <DataTable className="" value={showGruposToggle ? rowsGruposFiltradosSemana : rowsPresentacionesFiltradasSemana} headerColumnGroup={obtenerHeader()} >
                            {obtenerColumnas()}
                        </DataTable>
                    </div>

                    {showAcumuladoToggle?.length > 0 &&
                        <div className={`card styles-tabla-pres-fijos ${showAcumuladoToggle?.length > 1 ? "rpd-acc-double-col-width" : "rpd-acc-col-width"}`} >
                            <DataTable className="" value={showGruposToggle ? rowsGruposFiltradosSemana : rowsPresentacionesFiltradasSemana} >
                                {showAcumuladoToggle?.includes(opcionesAcumulado[0].value) &&
                                    <Column frozen key="oc-se" className="col__label rgps-celda-reporte" headerClassName="col__label rgps-celda-header" field="accumuladoPromedio" header="Semana" body={templateBody} />}
                                {showAcumuladoToggle?.includes(opcionesAcumulado[1].value) &&
                                    <Column frozen key="oc-ci" className="col__label rgps-celda-reporte" headerClassName="col__label rgps-celda-header" field="accumuladoPromedio" header="Ciclo" body={templateBody} />}
                            </DataTable>
                        </div>}
                </div>
            </React.Fragment>

            <ContenedorCardFormulario titulo="Comentarios/hallazgos">
                <div className="p-col-12 max-6">
                    {comentarios.map((comentario, ind) => { return <div key={`com-${ind}`}> {obtenerNombreFecha(comentario.momento)} - {comentario.comentario} </div> })}
                </div>
            </ContenedorCardFormulario>
        </div >);
}
export default ReporteGeneralPesosDiarios;//225//602//729