import React, { useState, useEffect, useGlobal } from "reactn";
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';

import RhService, { getRegistroActividadesDeLaSemana } from '../../service/RhService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faChartBar } from '@fortawesome/free-solid-svg-icons';
import { InputText } from 'primereact/inputtext';
import { flexDate, obtenerDiasDeSemanaConDetalles } from '../../service/fechas';
import { obtenerActividades } from '../../service/actividades';
import {
    DETALLES_DEPARTAMENTO_EMPAQUE, DETALLES_DEPARTAMENTO_OTROS,
    DEPARTAMENTO_INVERNADERO_NOMBRE, DEPARTAMENTO_EMPAQUE_NOMBRE, DEPARTAMENTO_OTROS_NOMBRE,
    AUSENCIA_FALTA, AUSENCIA_PERMISO, AUSENCIA_VACACIONES, AUSENCIA_NINGUNA
} from '../../util/constants';
import { obtenerPresupuestoCosecha, obtenerIdCosecha, obtenerActividadCosechaDias, obtenerSnapshotPrevioNuevo } from '../../service/nomina';
import { separadoComas, dosDecimales, getProductoYVariedadString, getObjetoPorID, getNombrePorID } from '../../util/functions';
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { obtenerEmpaquesCiclo, obtenerRolesEmpaque, obtenerRolesInvernadero } from '../../service/Empaques';
import { obtenerEmpacadoras } from '../../service/Empacadoras';
import { obtenerDatosBase } from "components/nomina/ConsultarCtrlConNuevoBonoCosecha";
const CODIGO_ROL_EMPAQUE = "empacador";

function ConsultarEmpleados(props) {

    // daatos iniciales
    const [, setCargando] = useGlobal("cargando")
    var [cicloId] = useGlobal("ciclo")
    var [usuario] = useGlobal("usuario")
    const [, setMostrarModalPermiso] = useGlobal("mostrarModalPermiso");
    var [departamentos, setDepartamentos] = useState([])
    var [departamentosOrig, setDepartamentosOrig] = useState([])
    var [empaques, setEmpaques] = useState([])
    var [invernaderosVirtuales, setInvernaderosVirtuales] = useState([])
    const [actividades, setActividades] = useState([]);
    // filtros
    var [departamentoSeleccionado, setDepartamentoSeleccionado] = useState({})
    var [empaqueSeleccionado, setEmpaqueSeleccionado] = useState({})
    var [invernaderoVirtualSeleccionado, setInvernaderoVirtualSeleccionado] = useState({})
    const [weeks, semanaSeleccionada, handleWeekChange, updateCicloWeekSelector] = useWeekSelector();
    var [globalFilter, setGlobalFilter] = useState("")

    // datos de consulta
    var [datosEmpleados, setDatosEmpleados] = useState([])
    const [, setEmpleado] = useGlobal("empleado");
    const [rolesEmpaque, setRolesEmpaque] = useState([])
    const [rolsInvernadero, setRolsInvernadero] = useState([])
    const [empacadoras, setEmpacadoras] = useState([])
    const [datosInicialesCargados, setDatosInicialesCargados] = useState(false)


    var [nombreDepartamento] = useGlobal("nombreDepartamento")
    var [invernaderoId] = useGlobal("invernaderoId")
    var [empaqueId] = useGlobal("empaqueId")
    var [, setInvernadero] = useGlobal("invernadero")
    var [invernaderosInternos] = useGlobal("invernaderosInternos")
    const [medleysGeneral] = useGlobal("medleysGeneral");

    useEffect(() => {
        if (cicloId) { obtenerDatosIniciales() }
    }, [cicloId])

    useEffect(() => {
        if (departamentoSeleccionado.id && departamentoSeleccionado.nombre
            && ((departamentoSeleccionado.nombre === DEPARTAMENTO_EMPAQUE_NOMBRE && empaqueSeleccionado.id && empaqueSeleccionado.nombre) ||
                (invernaderoVirtualSeleccionado.id && invernaderoVirtualSeleccionado.nombre && departamentoSeleccionado.nombre === DEPARTAMENTO_INVERNADERO_NOMBRE) ||
                departamentoSeleccionado.nombre === DEPARTAMENTO_OTROS_NOMBRE)
            && semanaSeleccionada.nombre != "Cargando") {
            filtrarEmpleados();
        }
    }, [departamentoSeleccionado, empaqueSeleccionado, invernaderoVirtualSeleccionado, semanaSeleccionada, datosInicialesCargados])

    async function obtenerDatosIniciales() {
        try {
            setCargando(true)
            //es necesario obtenerlas aqui? todos los empleados jalan de actividades
            let [empacadorasResp, rolesEmpaqueResp, rolsInvernaderoResp, catalogoActividades, deptos, departamentosResp, empaquesResp] =
                await Promise.all([obtenerEmpacadoras(), obtenerRolesEmpaque(), obtenerRolesInvernadero(), obtenerActividades(),
                RhService.getDepartamentos(), RhService.getDepartamentosConSubdepartamentos_and_Otros(), obtenerEmpaquesCiclo(cicloId)])
            setEmpacadoras(empacadorasResp);
            setRolesEmpaque(rolesEmpaqueResp);
            setRolsInvernadero(rolsInvernaderoResp);
            setActividades(Array.from(catalogoActividades));
            setDepartamentosOrig(deptos);
            setDepartamentosAndFirtsDepartamento(departamentosResp);
            setEmpaquesAndFirstEmpaque(empaquesResp);

            setInvernaderosVirtualesAndFirstInvernadero(invernaderosInternos);
            setDatosInicialesCargados(true);
        } catch (error) {
            if (error.code && error.code === "permission-denied") {
                setMostrarModalPermiso(true);
            }
        } finally {
            setCargando(false);
        }
    }

    async function filtrarEmpleados() {
        setCargando(true)
        let datosEmpleados = []
        let empleados = await RhService.getEmpleados_deSubdepartamento(departamentoSeleccionado, empaqueSeleccionado, invernaderoVirtualSeleccionado, cicloId)
        let { empleadosSemana } = semanaSeleccionada.time > 1679248800 ? await obtenerDatosBase(cicloId, invernaderoVirtualSeleccionado, semanaSeleccionada, actividades, rolsInvernadero) : await obtenerSnapshotPrevioNuevo(cicloId, invernaderoVirtualSeleccionado.id, semanaSeleccionada.time)
        console.log(empleadosSemana)
        console.log((await obtenerDatosBase(cicloId, invernaderoVirtualSeleccionado, semanaSeleccionada, actividades, rolsInvernadero)), "OTROS")
        var datos = [];
        if (isDepartamentoOtrosSeleccionado()) { empleados = empleados.filter(el => !["UTZqlolGoKpgRqpZSNmS", "W1vIr510e74omoTUXbRL"].includes(el.departamento_ref)); }
        else empleados = empleados.filter(el => el.departamento_ref === departamentoSeleccionado.id);
        empleados.forEach((empleado) => {
            let datosEmpleado = {
                id: empleado.codigo_empleado,
                empleado: `${empleado.nombre} ${empleado.apellidos}`.trim(),
                faltas: 'NA',
                salarioDiario: empleado.salario_diario ? parseInt(empleado.salario_diario) : 0,
                salarioDiarioF: empleado.salario_diario ? `$${empleado.salario_diario}` : 'NA',
                bonoSemanal: 'NA',
                totalConBono: 'NA',
                departamento: 'NA',
                estatus: empleado.activo ? 'activo' : 'inactivo',
                empleadoRef: empleado,
            }
            if (isDepartamentoInvernaderoSeleccionado()) {
                //if(datosEmpleado.empleado.includes("29"))console.log("DATOS EMP: ",{rolsInvernadero,invRef:  empleado.rol_invernadero_ref, empleado})
                datosEmpleado.rolInv = empleado.rol_invernadero_ref ? getNombrePorID(rolsInvernadero, empleado.rol_invernadero_ref) : "";
            }

            if (empleado.rol_ref) {
                let rol = rolesEmpaque.find(el => empleado.rol_ref === el.id);
                datosEmpleado["rol"] = rol.nombre;

                if (rol.codigo === CODIGO_ROL_EMPAQUE && empleado.codigo_empacadora) {
                    let empacadora = empacadoras.find(el => empleado.codigo_empacadora === el.id);
                    datosEmpleado["empacadora"] = empacadora.nombre;
                }
            }
            datos.push(datosEmpleado);
        });

        datos.sort((a, b) => a.empleado < b.empleado ? 1 : -1)
        datos.sort((a, b) => a.estatus > b.estatus ? 1 : -1)
        datosEmpleados = Array.from(datos)

        datosEmpleados.forEach((datosEmpleado) => {
            departamentosOrig.forEach((departamento) => {
                if (datosEmpleado.empleadoRef.departamento_ref === departamento.id) {
                    datosEmpleado.departamento = departamento.nombre
                }
            });
        });

        if (departamentoSeleccionado.nombre !== 'Invernadero') {
            setDatosEmpleados(datosEmpleados);
            setCargando(false);
            return;
        }
        let promises = [];
        try {
            promises.push(getRegistroActividadesDeLaSemana(cicloId, invernaderoVirtualSeleccionado.id, semanaSeleccionada.time));
            promises.push(obtenerPresupuestoCosecha(cicloId, invernaderoVirtualSeleccionado.id));
            promises.push(obtenerIdCosecha());
            promises.push(obtenerActividadCosechaDias(cicloId, invernaderoVirtualSeleccionado.id, semanaSeleccionada.time));
            let [registroSemana, presupuestoCosecha, idCosecha, diasCosecha] = await Promise.all(promises);
            setCargando(false)

            const metrosPorLinea = parseInt(invernaderoVirtualSeleccionado.detalles_fisicos.find(el => el.detalle === "Metros por línea")["norte"]);
            let totalCosecha = diasCosecha.reduce((acc, curr) => (acc + curr), 0)
            var diasSemana = obtenerDiasDeSemanaConDetalles(semanaSeleccionada.time)
            var datosEmpleadosInvernadero = Array.from(datosEmpleados)

            datosEmpleadosInvernadero.forEach((datosEmpleado) => {

                let ausenciasEmpleado = []
                var bonoActividades = 0
                var mereceBonoPorAsistencia = true

                diasSemana.forEach((dia, index) => {

                    const diaSemana = dia;
                    let registroDia = registroSemana.find(el => {
                        const timestamps = flexDate(el.dia)
                        return timestamps.inicio <= diaSemana.unixTime && timestamps.fin >= diaSemana.unixTime
                    });

                    const diaLimpio = {
                        dia: diaSemana.unixTime,
                        abreviacion: diaSemana.abreviacion,
                        tuvoAusencia: false,
                        tuvoPermiso: false,
                        tuvoVacaciones: false
                    }

                    if (!registroDia || !registroDia.actividades_realizadas || registroDia.actividades_realizadas.length === 0) {
                        ausenciasEmpleado.push(diaLimpio);
                        return
                    }

                    for (let i = 0; i < registroDia.actividades_realizadas.length; i++) {
                        if (datosEmpleado.empleadoRef.id == registroDia.actividades_realizadas[i].empleado_ref) {
                            const tuvoAusencia = registroDia.actividades_realizadas[i].tuvo_ausencia || false
                            const tuvoPermiso = registroDia.actividades_realizadas[i].tuvo_permiso || false
                            const tuvoVacaciones = registroDia.actividades_realizadas[i].tuvo_vacaciones || false

                            const objDia = { dia: diaSemana.unixTime, abreviacion: diaSemana.abreviacion, tuvoAusencia, tuvoPermiso, tuvoVacaciones }
                            ausenciasEmpleado.push(objDia);
                            if (tuvoAusencia) mereceBonoPorAsistencia = false
                        }
                        if(semanaSeleccionada.time <= 1679248800){
                            if (!registroDia.actividades_realizadas[i].actividades) { return }
                            for (let actividadRef in registroDia.actividades_realizadas[i].actividades) {
                                if (datosEmpleado.empleadoRef.id == registroDia.actividades_realizadas[i].empleado_ref) {
                                    const esfuerzoRealizado = registroDia.actividades_realizadas[i].actividades[actividadRef];
                                    const { producto_ref, mezcla } = invernaderoVirtualSeleccionado;
                                    const actividadProductoRef = mezcla ? medleysGeneral.id : producto_ref;
                                    actividades.forEach((actividad) => {
    
                                        if (actividadRef === actividad.id) {
                                            if (actividadRef === idCosecha) {
                                                let factorCosecha = presupuestoCosecha / totalCosecha;
                                                if (esfuerzoRealizado) { bonoActividades += factorCosecha * parseFloat(esfuerzoRealizado); }
                                            }
                                            else if (actividadRef === actividad.id && esfuerzoRealizado) {
                                                const multiplicador = actividad.unidades === "día" ? 1 : metrosPorLinea;
                                                bonoActividades += actividad.bonos_por_unidad[actividadProductoRef] * parseFloat(esfuerzoRealizado) * multiplicador;
                                            }
                                        }
                                    });
    
                                }
                            }
                        }
                    }
                    if (ausenciasEmpleado.length === index) {
                        ausenciasEmpleado.push(diaLimpio);
                        return
                    }
                });
                datosEmpleado.faltas = ausenciasEmpleado
                if(semanaSeleccionada.time > 1679248800){
                    datosEmpleado.bonoSemanal = `$${separadoComas(dosDecimales( empleadosSemana.find(el => el.id === datosEmpleado.empleadoRef.id )?.total || 0))}`
                    datosEmpleado.totalConBono = `$${separadoComas(dosDecimales( empleadosSemana.find(el => el.id === datosEmpleado.empleadoRef.id )?.bono || 0))}`
                } else if (empleadosSemana){
                    datosEmpleado.bonoSemanal = `$${separadoComas(dosDecimales( empleadosSemana.find(el => el.empleado.trim() === datosEmpleado.empleado )?.total || 0))}`
                    datosEmpleado.totalConBono = `$${separadoComas(dosDecimales( empleadosSemana.find(el => el.empleado.trim() === datosEmpleado.empleado )?.bono || 0))}`
                    if(datosEmpleado.bonoSemanal === '$0'){

                        datosEmpleado.bonoSemanal = `$${separadoComas(dosDecimales(bonoActividades))}`
                        datosEmpleado.totalConBono = `$${mereceBonoPorAsistencia ? separadoComas(dosDecimales(bonoActividades * 1.20)) : separadoComas(dosDecimales(bonoActividades))}`
                    }
                } 
                else {
                    datosEmpleado.bonoSemanal = `$${separadoComas(dosDecimales(bonoActividades))}`
                    datosEmpleado.totalConBono = `$${mereceBonoPorAsistencia ? separadoComas(dosDecimales(bonoActividades * 1.20)) : separadoComas(dosDecimales(bonoActividades))}`
                }

            });
            setDatosEmpleados(datosEmpleadosInvernadero)

        } catch (error) {
            console.log("ERROR FILTRAR EMPLEADOS");
            console.log(error);
            throw error;
        } finally {
            // setCargando(false);
        }

    }

    function faltaTemplate(rowData) {
        if (rowData.faltas === 'NA') return
        return (
            <div className={'ausencias-week'}>
                {rowData.faltas.map((dia) => {
                    return (
                        <span key={dia.dia} className={makeNombreFaltaCSSClass(dia)} title={makeNombreFaltaCSSClass(dia)}>
                            {dia.abreviacion}
                        </span>
                    );
                })}
            </div>
        );
    }
    const bonoTemplate = (rowData, column) => {
        return <div onClick={irNomina}> {rowData[column["field"]]}</div>
    }
    const irNomina = (e) => {
        e.stopPropagation();
        props.history.push("consultar_nomina");
    }


    const graficaTemplate = (rowData, column) => {

        return <div onClick={(e) => {
            e.stopPropagation();
            irGraficaEmpleado(rowData["empleadoRef"]);
        }} className="btn-line chart-btn" >
            <FontAwesomeIcon icon={faChartBar} />
        </div>
    }
    const irGraficaEmpleado = (empleado) => {
        setEmpleado(empleado);
        setInvernadero(invernaderoVirtualSeleccionado);
        props.history.push("/nomina/graficas/actividades_empleado");
    }

    function makeNombreFaltaCSSClass(dia) {
        if (dia.tuvoAusencia) return AUSENCIA_FALTA
        if (dia.tuvoPermiso) return AUSENCIA_PERMISO
        if (dia.tuvoVacaciones) return AUSENCIA_VACACIONES
        return AUSENCIA_NINGUNA
    }

    function setDepartamentosAndFirtsDepartamento(departamentos) {
        setDepartamentos(departamentos)
        let departamentoIndex = departamentos.findIndex((departamento) => {
            return departamento.nombre === nombreDepartamento
        });

        if (departamentoIndex !== -1) {
            setDepartamentoSeleccionado(departamentos[departamentoIndex])
        } else {
            if (departamentos.length > 0) setDepartamentoSeleccionado(departamentos[0])
        }
    }

    function setEmpaquesAndFirstEmpaque(empaques) {
        setEmpaques(empaques)
        let empaqueIndex = empaques.findIndex((empaque) => {
            return empaque.id === empaqueId
        });

        if (empaqueIndex !== -1) {
            setEmpaqueSeleccionado(empaques[empaqueIndex])
        } else {
            if (empaques.length > 0) setEmpaqueSeleccionado(empaques[0])
        }

    }

    function setInvernaderosVirtualesAndFirstInvernadero(invernaderosVirtuales) {
        setInvernaderosVirtuales(invernaderosVirtuales)
        let invernaderoIndex = invernaderosVirtuales.findIndex((invernadero) => {
            return invernadero.id === invernaderoId
        });

        if (invernaderoIndex !== -1) {
            setInvernaderoVirtualSeleccionado(invernaderosVirtuales[invernaderoIndex])
            setInvernadero(invernaderosVirtuales[invernaderoIndex]);
        } else {
            if (invernaderosVirtuales.length > 0) setInvernaderoVirtualSeleccionado(invernaderosVirtuales[0])
        }
    }

    function handleOnRowClick(datosEmpleado) {
        props.history.push({ pathname: `modificar_empleado/${cicloId}`, state: { empleado: datosEmpleado.empleadoRef } })
    }

    function registrarEmpleado() {
        props.history.push(`registrar_empleado/${cicloId}`)
    }

    var isDepartamentoInvernaderoSeleccionado = () => { return departamentoSeleccionado.nombre === DEPARTAMENTO_INVERNADERO_NOMBRE }
    var isDepartamentoEmpaqueSeleccionado = () => { return departamentoSeleccionado.nombre === DEPARTAMENTO_EMPAQUE_NOMBRE }
    var isDepartamentoOtrosSeleccionado = () => { return departamentoSeleccionado.nombre === DEPARTAMENTO_OTROS_NOMBRE }

    const puedeRegistrar = (params) => {
        const rolsABloquear = ["Personal Administrativo"];
        if (rolsABloquear.includes(usuario?.rol)) { return false; }
        return (!isDepartamentoInvernaderoSeleccionado() || (invernaderoVirtualSeleccionado.habilitado));
    }

    return (
        <div className="p-grid p-justify-center">

            <div className="p-col  p-col-auto title-container">
                <span className="back-btn" onClick={props.history.goBack}></span>
                {isDepartamentoInvernaderoSeleccionado() &&
                    <React.Fragment>
                        <span className={`icon-general icon-id bor-${invernaderoVirtualSeleccionado.color} bg-${invernaderoVirtualSeleccionado.color}`}>
                            {invernaderoVirtualSeleccionado.nombre}
                        </span>
                    </React.Fragment>}
                {isDepartamentoEmpaqueSeleccionado() &&
                    <span className={`icon-general icon-id bg-${DETALLES_DEPARTAMENTO_EMPAQUE.color}  bor-${DETALLES_DEPARTAMENTO_EMPAQUE.color}`}>
                        <FontAwesomeIcon icon={DETALLES_DEPARTAMENTO_EMPAQUE.icono} />
                    </span>}
                {isDepartamentoOtrosSeleccionado() &&
                    <span className={`icon-general icon-id bg-${DETALLES_DEPARTAMENTO_OTROS.color}  bor-${DETALLES_DEPARTAMENTO_OTROS.color}`}>
                        <FontAwesomeIcon icon={DETALLES_DEPARTAMENTO_OTROS.icono} />
                    </span>}

                <span className="titles-group">
                    <h1 className="header-title">Consultar Empleados</h1>
                    <p className="header-subtitle">
                        {isDepartamentoInvernaderoSeleccionado() &&
                            <>{getProductoYVariedadString(invernaderoVirtualSeleccionado)}</>}
                    </p>
                </span>
            </div>

            <div className="p-col p-col-auto">
                {puedeRegistrar() &&
                    <div className="btn-row">
                        <Button label="Crear empleado" onClick={() => { registrarEmpleado() }} />
                    </div>}
            </div>

            {/* Filtros */}
            <div className="p-col-12 ">
                <div className="p-grid filtros">
                    <div className="search-field form-group p-col">
                        <FontAwesomeIcon icon={faSearch} />
                        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Búsqueda por empleado" />
                    </div>



                    <div className="form-group p-col">
                        <label htmlFor="filtroDepartamento">Departamento</label>
                        <Dropdown id="filtroDepartamento" placeholder="Seleccionar"
                            dataKey="id" optionLabel="nombre"
                            options={departamentos} value={departamentoSeleccionado}
                            onChange={(e) => { setDepartamentoSeleccionado(e.value) }} />
                    </div>


                    {isDepartamentoEmpaqueSeleccionado() &&
                        <div className="form-group p-col">
                            <label htmlFor="filtroEmpaque">Empacadora</label>
                            <Dropdown id="filtroEmpaque" placeholder="Seleccionar"
                                dataKey="id" optionLabel="nombre"
                                options={empaques} value={empaqueSeleccionado}
                                onChange={(e) => { setEmpaqueSeleccionado(e.value) }} />
                        </div>
                    }

                    {isDepartamentoInvernaderoSeleccionado() &&
                        <div className="form-group p-col">
                            <label htmlFor="filtroInvernadero">Invernadero</label>
                            <Dropdown id="filtroInvernadero" placeholder="Seleccionar"
                                dataKey="id" optionLabel="nombre"
                                options={invernaderosVirtuales} value={invernaderoVirtualSeleccionado}
                                onChange={(e) => { setInvernaderoVirtualSeleccionado(e.value) }} />
                        </div>
                    }
                    <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={semanaSeleccionada} />
                </div>
            </div>

            {/* Tabla de consulta */}
            <div className="p-col-12">
                <div className="p-grid p-fluid ">
                    <div className="p-col-12">
                        <div className="card p-0 card-table-pag">
                            {isDepartamentoInvernaderoSeleccionado() &&
                                <DataTable selectionMode="single" autoLayout={true}
                                    value={datosEmpleados} onRowClick={(event) => { handleOnRowClick(event.data) }}
                                    globalFilter={globalFilter} emptyMessage="No hay resultados" scrollable={true}>
                                    <Column header="ID" field="id" sortable={false} style={{ textAlign: 'center' }} />
                                    <Column header="Empleado" field="empleado" sortable={true} />
                                    <Column header="Faltas" field="faltas" sortable={true} style={{ textAlign: 'center' }} body={faltaTemplate} />
                                    <Column header="Salario diario" field="salarioDiarioF" sortable={true} style={{ textAlign: 'center' }} sortField="salarioDiario" />
                                    <Column header="Bono semanal" field="bonoSemanal" sortable={true} style={{ textAlign: 'center' }} body={bonoTemplate} />
                                    <Column header="Total + Bono %" field="totalConBono" sortable={true} style={{ textAlign: 'center' }} body={bonoTemplate} />
                                    <Column header="Departamento" field="departamento" sortable={true} style={{ textAlign: 'center' }} />
                                    <Column header="Rol" field="rolInv" sortable={true} style={{ textAlign: 'center' }} />
                                    <Column header="Estatus" field="estatus" sortable={true} style={{ textAlign: 'center' }} />
                                    <Column header="Grafica" field="" body={graficaTemplate} style={{ textAlign: 'center' }} />
                                </DataTable>}

                            {isDepartamentoOtrosSeleccionado() &&
                                <DataTable selectionMode="single" autoLayout={true}
                                    value={datosEmpleados}
                                    onRowClick={(event) => { handleOnRowClick(event.data) }}
                                    globalFilter={globalFilter} emptyMessage="No hay resultados"
                                    scrollable={true} scrollHeight="400px">
                                    <Column header="ID" field="id" sortable={false} style={{ textAlign: 'center' }} />
                                    <Column header="Empleado" field="empleado" sortable={true} />
                                    <Column header="Departamento" field="departamento" sortable={true} style={{ textAlign: 'center' }} />
                                    <Column header="Estatus" field="estatus" sortable={true} style={{ textAlign: 'center' }} />
                                </DataTable>
                            }
                            {isDepartamentoEmpaqueSeleccionado() &&
                                <DataTable selectionMode="single" autoLayout={true}
                                    value={datosEmpleados}
                                    onRowClick={(event) => { handleOnRowClick(event.data) }}
                                    globalFilter={globalFilter} emptyMessage="No hay resultados"
                                    scrollable={true} scrollHeight="400px">
                                    <Column header="ID" field="id" sortable={false} style={{ textAlign: 'center' }} />
                                    <Column header="Empleado" field="empleado" sortable={true} />
                                    <Column header="Departamento" field="departamento" sortable={true} style={{ textAlign: 'center' }} />
                                    <Column header="Rol" field="rol" sortable={true} style={{ textAlign: 'center' }} />
                                    <Column header="Código Empaque" field="empacadora" sortable={true} style={{ textAlign: 'center' }} />
                                    <Column header="Estatus" field="estatus" sortable={true} style={{ textAlign: 'center' }} />

                                </DataTable>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}
export default ConsultarEmpleados;//651