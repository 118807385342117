import React, {useState, useGlobal, useEffect} from "reactn";
import {obtenerReporteEmpaque} from "../../service/ReportesProducto";
import {obtenerCiclo} from "../../service/Ciclos";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import { dosDecimales, getProductoYVariedadString } from "../../util/functions";
import { obtenerListaDeSemanas } from "../../service/fechas";
import {MultiSelect} from "primereact/multiselect";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row"; 
import { SelectButton } from 'primereact/selectbutton';
import { Button } from "primereact/button";

import * as firebase from "firebase/app";
import { getAreaInvernaderoSync } from "../../service/Invernaderos";

const optionsButton = [{label:"INICIAL", value :"inicial"},{label:"FINAL", value:"final"},{label:"FINAL DISTRIBUIDO", value:"complemento", title:"ffff"}];
export default function Reporte(props){
    const [ciclo,setCiclo] = useGlobal("ciclo");
    
    const [cicloObj] = useGlobal("cicloObj")
    const [error,setError] = useState(false);   
    const [tabla, setTabla] = useState([]);
    const [invernadero, setInvernadero] = useGlobal("invernadero")
    const [invernaderosInternos] = useGlobal("invernaderosInternos")
    const [tablaTotales, setTablaTotales] = useState([]);
    const [invernaderos,setInvernaderos ] = useState([]);        
    const [nombreModulo,setNombreModulo] = useGlobal("nombreModulo");    
    const [, setCargando] = useGlobal("cargando");
    const [inicialFinal, setInicialFinal] = useState(optionsButton[1].value);
    const [distribucionDatos, setDistribucionDatos] = useState(null);

    const atras = (params) => {
        props.history.goBack();
    }
    

    const [filtroInvernaderos, setFiltroInvernaderos] = useState([]);
    useEffect(() => {
        if(invernaderos && invernaderos.length > 0){
            setFiltroInvernaderos(invernaderos);
        }        
    }, [invernaderos])

    useEffect(()=>{
        if(ciclo){ obtenerDatosIniciales(); }        
    },[ciclo])
    useEffect(()=>{
        setNombreModulo("Empaque");
      },[])
    useEffect(()=>{
        if(ciclo && inicialFinal === "complemento"){

        }
        else if(ciclo && inicialFinal ){
            setCargando(true);
            obtenerReporteEmpaque(cicloObj, inicialFinal, invernaderosInternos).then(resp=>{
                procesarReporte(resp)
            })
            .catch(err=>{
                console.log("OCURRIO UN ERROR AL OBTENER REPORTE");
                console.log(err);
            }).finally(el=>{
                setCargando(false);
            })

        }
    },[ciclo, inicialFinal])

    const obtenerDatosIniciales = async () => {
        const response = await firebase.firestore().collection("ciclos").doc(ciclo).collection("distribucion_medleys").get();
        const distribucionDatos = response.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setDistribucionDatos(distribucionDatos);
    }
    
    const irInvernadero = (id) => {
        let invernaderoSeleccionado = invernaderos.find(el=>el.id === id);  
        setInvernadero(invernaderoSeleccionado);
        props.history.push("/producto/reporte/detalles")
    }
    function procesarReporte(values){
        obtenerCiclo(ciclo).then(async resp=>{
            let data = []
            let invernaderos = [];
            let semanas = obtenerListaDeSemanas(resp.semana_inicio, resp.semana_cierre);
            semanas.forEach(semana=>{
                let row = {};
                row["semana"] = semana.nombre;
                row["semanaTime"] = semana.time;
                values.forEach(invernadero=>{                                        
                    let matchSem = invernadero.find(sem=>{
                        let [inv,semanaVal,cant] = sem;
                        
                        return semanaVal.time === semana.time
                    })
                    
                    if(matchSem){
                        
                        let [inv,semanaVal,cant] = matchSem;
                        let found = invernaderos.find(el=>el.id === inv.id);
                        if(!found){
                            invernaderos.push({...inv});
                        }
                        
                        row[inv.nombre] = cant;
                    }else{
                        row[invernadero.nombre] = 0;
                    }
                    
                })
                data.push(row);
            })
        
            setInvernaderos(invernaderos);
            let row ={};
               row["total_kilos"] = "Total del ciclo";
            values.forEach(invernadero=>{
                invernadero.forEach(sem=>{
                    let [inv,semanaVal,cant] = sem;
                    if(row[inv.nombre]){
                        row[inv.nombre] += cant;
                    }else{
                        row[inv.nombre] = cant;
                    }
                })

            })
            setTablaTotales([row]);
            setTabla(data);
        })
        
    }
    
   
    function unidadTemplate(rowData, column) {
        const nombreInvernadero = column.field, kgM2 = rowData[nombreInvernadero];
        if(inicialFinal === "complemento"){
            //console.log("dato rowData: ", rowData);
            const invernadero = invernaderos?.find(inv => inv.nombre === nombreInvernadero);
            const areaInvernadero = getAreaInvernaderoSync(invernadero);
            const kgFinalTotal = kgM2 * areaInvernadero;
            //console.log("dato kgFinalTotal: ", kgFinalTotal);
            let totalPrestado = 0;

            const distribucionesFiltrados = getDistribucionDatosTODOS(invernadero.id, rowData.semanaTime);
            distribucionesFiltrados.forEach(dis => {
                let suma = 0;
                const esDestino = dis.invernadero_virtual_ref === invernadero.id;
                if (esDestino) { suma = sumDistribucion(dis); }
                else {
                    suma = restaDistribucion(dis, invernadero.id)
                    if(nombreInvernadero === "F1" && rowData.semana === "27 - 2022")  console.log("sum resta ",dis)
                }
                totalPrestado += suma
              
            })

            const kgComplementoTotal = kgFinalTotal + totalPrestado;
            const kgM2ComplementoTotal = kgComplementoTotal/areaInvernadero;
            return <div>{dosDecimales(kgM2ComplementoTotal)}<span className="label-unidad">kg/m²</span> </div>;
        }
        else return <div>{dosDecimales(kgM2)}<span className="label-unidad">kg/m²</span> </div>;
    }
    const getDistribucionDatosTODOS = (invernadero_virtual_ref, semanaTime) => {
        return distribucionDatos?.filter(el => {
            const esDestino = el.invernadero_virtual_ref === invernadero_virtual_ref;
            const esOrigen = el.origenes?.some(orig => orig.origen_ref === invernadero_virtual_ref);
            return (esDestino || esOrigen) && el.semana === semanaTime;
        });
    }
    const sumDistribucion = (dis = { origenes: [] }) => {
        let sum = 0;
        if(!dis?.origines?.length){ return sum; }
        
        dis.origenes.forEach(el => {
            sum += parseInt(el.kilos_prestado);
        })
        return sum
    }
    const restaDistribucion = (dis = { origenes: [] }, invernadero_virtual_ref) => {
        let resta = 0;
        if(!dis?.origines?.length){ return resta; }

        dis.origenes.forEach(el => {
            if (el.origen_ref === invernadero_virtual_ref) {
                resta -= parseInt(el.kilos_prestado);
            }//restar kilos de variedades prestado de este invernadero a otro tipo mezcla
        })
        return resta
    }

    function obtenerColumnas(){
        
        let cols = [<Column field = "semana" className="col__label"  header = "Semana" ></Column>]
        filtroInvernaderos.map(el=>{
            
            
            cols.push( <Column body={unidadTemplate} field={el.nombre} header ={<div onClick = {()=>irInvernadero(el.id)}>{el.nombre}</div>}/>)
        })
        return cols;
    }
    function obtenerColumnasTotales(){
        
        let cols = [<Column field = "total_kilos" className="col__label"  header = "" ></Column>]
        filtroInvernaderos.map(el=>{
            
            
            cols.push( <Column body={unidadTemplate}  field={el.nombre} header ={<div onClick = {()=>irInvernadero(el.id)}>{el.nombre}</div>}/>)
        })
        return cols;
    }
    const obtenerHeader = () => {
        let columns = [<Column header = "" rowSpan = {1} className = "col__label"></Column>]  
        filtroInvernaderos.forEach(invernadero => {
            columns.push(<Column  rowSpan = {1} className = "col__label"
                header={
                    <Button className="invisible-button" tooltip={getProductoYVariedadString(invernadero)} onClick = {()=>{irInvernadero(invernadero.id)}}>
                        <div className={`icon-general icon-mini bor-${invernadero.color} bg-${invernadero.color}`} title={getProductoYVariedadString(invernadero)} >
                            {invernadero?.nombre}
                        </div>
                    </Button>} >
                    
                </Column>)
        });
        let headerGroup = <ColumnGroup>
            <Row>
                {columns}                
            </Row>
        </ColumnGroup>;
        return headerGroup;
    }
    return  <div className="p-grid p-justify-center">    

    <div className="p-col-12 title-container">
        <span className="back-btn" onClick = {atras}></span>
        <span className="icon-general boxes-icon"></span>

        <span className="titles-group">

            <h1 className="header-title">
                Reporte general de empaque
            </h1>
            
        </span>
    </div>
    <div className="p-col-12">
        <div className="p-grid filtros">
            <div className="form-group p-col">
                    <label htmlFor="cinco">Invernaderos</label>
                    <MultiSelect value={filtroInvernaderos} options={invernaderos} onChange={(e) => setFiltroInvernaderos(e.value)} 
                        optionLabel="nombre" dataKey="id" selectedItemsLabel={`${filtroInvernaderos.length} invernaderos seleccionados`}/>
                    </div>
            <div className="form-group p-col">
                <label htmlFor="inicialFinal">kg/m²</label>
                <SelectButton value={inicialFinal} options={optionsButton} onChange={(e) => setInicialFinal(e.value)} 
                />
            </div>
        </div>
    </div>
    <div className="p-col-12">
        <p className="section-title">Reporte general</p>
        <div className="card p-0">
            <DataTable value = {tablaTotales} headerColumnGroup = {obtenerHeader()}>
                {obtenerColumnasTotales()}
            </DataTable>
        </div>
    
        <p className="section-title">Reporte semanal</p>

        
        <div className="card p-0">
            <DataTable className="tabla-empaque-reporte" value = {tabla} scrollable={true} headerColumnGroup = {obtenerHeader()}>
                {obtenerColumnas()}
            </DataTable>
        </div>
                  
    </div>
</div>
}