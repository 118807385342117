import React from 'react'

const ContenedorFiltroBusqueda = (props) => {

    return <div className={`${props.extra?`${props.extra}`:``}  search-field form-group p-col p-col-${props.col} p-md-${props.md} p-sm-${props.sm}`}>
        
        {props.children}
    </div>
}
export default ContenedorFiltroBusqueda;
