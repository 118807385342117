import React from 'react'
import { Card } from 'primereact/card';
import ContenedorCards from "./ContenedorCards";
import ContenedorDatosCards from "./ContenedorDatosCards";

export default function HTGCard(props) {
  return (
    <ContenedorCards key={props.key || ""} onClick={props.onClick}>
      <Card header={props.header} className={props.headerClassName}   >
        <ContenedorDatosCards>
          {props.children}
        </ContenedorDatosCards>
      </Card>
    </ContenedorCards>);
}