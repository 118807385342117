import { InvernaderoVirtual } from "../model/InvernaderoModels";
import {uploadStatistics} from './Estadisticas'

function registrarInvocaciones(count){
    uploadStatistics(count)
}

const firebase = require("firebase");
require("firebase/firestore");


class InvernaderoService {

    constructor(enforcer) {
      throw new Error('Cannot construct singleton');
    }

    static async getInvernaderosVirtualesDeCiclo(cicloId) {
        var invernaderos = []
        const firestore = firebase.firestore()
        await firestore.collection('ciclos').doc(cicloId).collection('invernaderos_virtuales')
            .orderBy('nombre', 'asc').get().then((snapshot) => {
                registrarInvocaciones(snapshot.size)
                snapshot.forEach(document => {
                    let invernadero = new InvernaderoVirtual(document.id, document.data().nombre, document.data().color)
                    invernaderos.push(invernadero)
                });
            })
        return invernaderos
    }

    static async getInvernaderosVirtualesActivosDeCiclo(cicloId) {
        var invernaderos = []
        const firestore = firebase.firestore()
        await firestore.collection('ciclos').doc(cicloId).collection('invernaderos_virtuales')
            .where('habilitado', '==', true)
            .orderBy('nombre', 'asc').get().then((snapshot) => {
                registrarInvocaciones(snapshot.size)
                snapshot.forEach(document => {
                    invernaderos.push({ id: document.id, ...document.data() })
                });
        })
        return invernaderos
    }

}

InvernaderoService.type = 'SingletonNoInstance';
export default InvernaderoService;
