import React, { useEffect, useState } from 'react';
import { useGlobal, setGlobal } from 'reactn';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Message } from 'primereact/message';
import ContenedorInput from '../../common/ContenedorInput'
import { notificarOrdenMantenimientoCreada, notificarOrdenSistemaCreada } from '../../../service/notificaciones';
import { noHaSidoClausurado } from '../../../service/Ciclos';
import ArchivoPreview from '../../common/ArchivoPreview'
import { locacionIsAtongo, empresaIsHTF, obtenerFiltrosSistemasTodos, isAdminRequisiciones, guardarRequisicionAciclico, obtenerOrdenSistemaAciclico, obtenerOrdenSistemasBridge } from '../../../service/Requisiciones';

import { Button } from 'primereact/button';
import { dateToUnixTimeAsNumber13, getCalendarDate, DeepClone, getArchivoTipo, getNombrePorID, getObjetoPorID, getNombreUsuarioPorID, locacionIsAgropark } from '../../../util/functions'
import CambiarEstatusModal from './CambiarEstatusModal'
import PreviewModal from '../../common/PreviewModal'

import * as firebase from "firebase/app";
import "firebase/auth";

import moment from "moment";
import 'moment/locale/es';
import { Calendar } from 'primereact/calendar';
import { getFirestoreID } from 'service/Mantenimiento';
import { log } from '../../../model/Logging';
moment.locale("es");

const archivoInicial = { URL: "", tipo: "" }
const itemInicial = { solicitud: "", cantidad: "", detalle: "", empresa: "", locacionHTF: "",sitio:"", locacion: "", telefono: "", anydesk: "", archivo: { ...archivoInicial } }
const inicial = {
    orden_num: "",
    fecha_creacion: "",
    fechaPreferidaEntrega: "",
    urgencia: "",
    solicitante: "",
    departamento: "",
    orden_ref: "",
    requerimiento: "",
    estatus: "",

    items: [{ ...itemInicial }],
    comentarioAdmin: "",

    fechaFinalizado: "",
}
setGlobal({ estatusesSistemas: [], locacionesSistemas: [], locacionesAtongoSistemas: [], empresas: [], datosCargadosSistemas: false })
const FormularioSistema = (props) => {
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [ciclo] = useGlobal("ciclo");
    const [cicloObj] = useGlobal("cicloObj");
    const [ciclos] = useGlobal("ciclos");
    const [, setCargando] = useGlobal("cargando")
    const [editing] = useState(props.match.params.requisicionID ? true : false)
    const [requisicionID] = useState(props.match.params.requisicionID || getFirestoreID())
    const [usuario] = useGlobal("usuario");
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [previewDialogURL, setPreviewDialogURL] = useState("")
    const [cambiarEstatusModalOpen, setCambiarEstatusModalOpen] = useState(false)
    const [datosCargados,] = useGlobal("datosCargadosSistemas")

    //opciones de filtros
    const [ordenesRefs] = useGlobal("ordenesRefsSistemas")
    const [urgencias] = useGlobal("urgenciasSistemas")
    const [requerimientos] = useGlobal("requerimientosSistemas")
    const [estatuses] = useGlobal("estatusesSistemas")
    const [locaciones] = useGlobal("locacionesSistemas")
    const [locacionesAtongo] = useGlobal("locacionesAtongoSistemas")
    const [locacionesHTF] = useGlobal("locacionesHTF")
    const [empresas] = useGlobal("empresas")
    const [departamentos] = useGlobal("departamentos")
    const [usuarios] = useGlobal("usuarios")
    const [invernaderosFisicos] = useGlobal("invernaderosFisicos")
    const [sitios] = useGlobal("sitios")

    const [nombreSolicitante, setNombreSolicitante] = useState("")
    const [departamentoSolicitante, setDepartamentoSolicitante] = useState("")
    const [, setSaving] = useState(false)
    const [errores, setErrores] = useState([])
    const [state, setState] = useState(DeepClone(inicial))
    const [, setModal] = useGlobal("modal");

    useEffect(() => {
        if (usuario.rol === "agente aduanal")
            props.history.push("/")
        setNombreModulo("Requisiciones de sistema")
        setHabilitadoSelectorCiclo(true)
    }, [])
    useEffect(() => {
        if (!noHaSidoClausurado(ciclo, ciclos) && !editing)
            props.history.replace("/requisiciones/sistemas")
    }, [ciclo, ciclos])
    useEffect(() => {
        setCargando(true)

        if (invernaderosFisicos && !datosCargados)
            obtenerFiltrosSistemasTodos(usuario)
        else if (datosCargados)
            obtenerDatosInicio()
    }, [invernaderosFisicos, datosCargados])
    useEffect(() => {
        setSaving(false)
        setErrores([])
    }, [state])

    const puedeEditar = () => editing ? isAdminRequisiciones(usuario.rol, "Sistemas") && noHaSidoClausurado(ciclo, ciclos) : !["Agente"].includes(usuario?.rol);
    const puedeEditarEstatus = () => editing ? isAdminRequisiciones(usuario.rol, "Sistemas") && noHaSidoClausurado(ciclo, ciclos) : false

    const obtenerDatosInicio = async () => {
        if (editing) {
            try {
                var orden = props.location.data || await obtenerOrdenSistemasBridge(cicloObj, requisicionID)
                delete orden.id

                const nombreSol = usuarios ? getNombreUsuarioPorID(usuarios, orden.solicitante) : usuario.displayName
                setNombreSolicitante(nombreSol)
                setDepartamentoSolicitante(getNombrePorID(departamentos, orden.departamento))
                setState({ ...state, ...orden })
            } catch (error) {
                console.log("ERROR obtener compra", error)
                props.history.push('/requisiciones/compras')
            }
        }
        else {
            setState({ ...state, estatus: estatuses[0].id })//Set to initial state "Pendiente de autorizar"
        }
        setCargando(false)
    }

    const guardarOrden = async () => {
        setSaving(true)

        if (validar()) {
            try {
                setCargando(true)
                let itemsCopy = await subirArchivosItems()

                let ordenes_sistema = await firebase.firestore().collection('requisiciones').doc("sistemas").collection("requisiciones_sistema").orderBy("orden_num", "desc").limit(1).get()
                const ordenMasReciente = ordenes_sistema?.docs?.[0]?.data();
                if (isNaN(ordenMasReciente?.orden_num) || ordenMasReciente?.orden_num == null) { throw new Error }
                log({ data: { mensaje: "1. logging ordenes_sistema" || "", ordenMasReciente, funcion: "guardarOrden", ciclo, usuario } })

                let data = { ...state, items: itemsCopy }
                if (!editing) {
                    data.orden_num = ordenMasReciente.orden_num + 1
                    log({ data: { mensaje: "2. logging ordenes_sistema" || "", ordenMasReciente, funcion: "guardarOrden", ciclo, usuario } })
                    data.fecha_creacion = Date.now()
                    data.solicitante = usuario.uid
                    data.departamento = usuario.departamento
                }

                //Guardar datos de requisición
                await guardarRequisicionAciclico("sistema", requisicionID, data)
                if (!editing)
                    await notificarOrdenSistemaCreada({ orden_num: data.orden_num, usuario: usuario.displayName, departamento: getNombrePorID(departamentos, data.departamento), urgencia: getNombrePorID(urgencias, data.urgencia) });

                setCargando(false)
                props.history.goBack()
            }
            catch (error) {
                console.log("ERROR guardar: ", error);
                log({ data: { mensaje: "3. logging ordenes_sistema" || "", funcion: "guardarOrden", ciclo, usuario } })
                setModal({ titulo: "Error de registro de datos", msj: "Hubo un problema al guardar su requisición, intentelo nuevamente." });
                setCargando(false);
            }
        }
    }

    const validar = () => getErroresFormulario().length === 0
    const getErroresFormulario = () => {
        let camposRequeridosLista = ["urgencia", "fechaPreferidaEntrega", "orden_ref", "requerimiento"]
        let camposRequeridosItemsLista = ["solicitud", "cantidad", "detalle", "empresa", "locacion", "telefono"]
        let errores = []

        camposRequeridosLista.forEach(campo => {
            if (!state[campo])
                errores.push(campo)
        })
        state.items.forEach((item, index) => {
            camposRequeridosItemsLista.forEach(campo => {
                if (!item[campo])
                    errores.push(`${campo}${index}`)
            })

            if (empresaIsHTF(empresas, item.empresa) && !item.locacionHTF)
                errores.push(`locacionHTF${index}`);
            if (empresaIsHTF(empresas, item.empresa) && item.locacionHTF && !item.sitio)
                errores.push(`sitio${index}`)
        })

        setErrores(errores)
        return errores
    }

    const subirArchivosItems = async () => {
        let itemsCopy = DeepClone(state.items)

        for (let i = 0; i < itemsCopy.length; i++) {
            let archivoCopy = itemsCopy[i].archivo

            if (archivoCopy.archivo) {
                let archivoNombre = `item${i + 1}_${Date.now()}_${archivoCopy.archivo.name}`
                let storagePath = `ordenes_sistema/${requisicionID}/${archivoNombre}`
                const ref = firebase.storage().ref().child(storagePath)
                let fileSnapshot = await ref.put(archivoCopy.archivo)
                let url = await fileSnapshot.ref.getDownloadURL()

                itemsCopy[i].archivo = { URL: url, tipo: archivoCopy.tipo }
            }
        }
        return itemsCopy
    }

    const getItemLocacionOpciones = (empresa, locacionHTF, sitio) => {
        let opciones = locaciones.map(el => ({ ...el }))

        if (invernaderosFisicos && empresaIsHTF(empresas, empresa)) {
            if (locacionIsAtongo(locacionesHTF, locacionHTF)) { 
                const locacionesAtongoFiltrados = locacionesAtongo.filter(loc => loc.sitio === sitio || (sitio === "Todos" && loc.todos))
                opciones.push(...locacionesAtongoFiltrados.map(el => ({ ...el }))) 
            }
            
            opciones.push(...invernaderosFisicos.filter(inv => inv.empaque_ref === sitio && inv.empresa_ref === empresa))
        }
        else if (invernaderosFisicos)
            opciones.push(...invernaderosFisicos.filter(inv => inv.empresa_ref === empresa))

        return opciones
    }
    const handleInputChange = (event, index = -1) => {
        let stateCopy = DeepClone(state)
        let name = event.target.name

        if (index >= 0) {
            let locacionesIDs = locaciones.map(el => el.id)
            stateCopy.items[index][name] = event.target.value

            if (name === "empresa" && !locacionesIDs.includes(stateCopy.items[index].locacion)) {
                stateCopy.items[index].locacion = "";
                stateCopy.items[index].sitio = "";
            }
            if (name === "empresa" && !empresaIsHTF(empresas, event.target.value)) {
                stateCopy.items[index].locacionHTF = "";
                stateCopy.items[index].sitio = getSitioAgropark().id
            }
            if (name === "locacionHTF") {
                stateCopy.items[index].sitio = locacionIsAgropark(locacionesHTF, event.target.value) ? getSitioAgropark().id : ""
            }
        }
        else
            stateCopy[name] = event.target.value

        setState(stateCopy)
    }

    const handleItemArchivoChange = (event, index) => {
        if (event.target.files[0]) {
            let localURL = URL.createObjectURL(event.target.files[0])
            let tipoArchivo = getArchivoTipo(event.target.files[0])
            handleInputChange({ target: { name: event.target.name, value: { URL: localURL, archivo: event.target.files[0], tipo: tipoArchivo } } }, index)
        }
    }
    const deleteArchivoItem = (e, index) => {
        //Para bloquear que el parent hace click (vista previa)
        e.stopPropagation();
        handleInputChange({ target: { name: "archivo", value: { ...archivoInicial } } }, index)
    }

    const handleCalendarInputChange = (event, index) => {
        handleInputChange({ target: { name: event.target.name, value: dateToUnixTimeAsNumber13(event.target.value) } }, index)
    }

    const agregarItem = () => {
        let stateCopy = DeepClone(state)
        let item = { ...itemInicial }
        let previousItem = stateCopy.items[stateCopy.items.length - 1]

        item.empresa = previousItem.empresa
        item.locacion = previousItem.locacion
        item.locacionHTF = previousItem.locacionHTF
        item.sitio = previousItem.sitio

        stateCopy.items.push(item)
        setState(stateCopy)
    }
    const eliminarItem = (index) => {
        let stateCopy = DeepClone(state)
        state.items = stateCopy.items.splice(index, 1)

        setState(stateCopy)
    }

    const verArchivoFuncion = (archivo) => { archivo.tipo === "pdf" || archivo.tipo === "excel" ? window.open(archivo.URL) : setPreviewDialogURL(archivo.URL) }
    const ordenFinalizado = () => getObjetoPorID(estatuses, state.estatus).finalizado
    const getSitioAgropark = () => sitios.find(sitio => sitio.nombre === "Agropark");
    const getSitiosAtongo = () => sitios.filter(sitio => sitio.nombre !== "Agropark");
    const disableItemSitio = (item) => !puedeEditar() || locacionIsAgropark(locacionesHTF, item.locacionHTF);
    const getSitioOpciones = (item) => !item.locacionHTF ? [] : locacionIsAgropark(locacionesHTF, item.locacionHTF) ? [getSitioAgropark()] : getSitiosAtongo();

    
    return <div className="p-grid p-justify-center">

        <div className="p-col p-col-auto title-container">
            <span className="back-btn" onClick={() => { props.history.goBack() }}></span>
            <span className="icon-general it-icon"></span>
            <span className="titles-group">
                <h1 className="header-title">Requerimientos de orden {editing && `#${state.orden_num}`}</h1>
                {editing && <p className="header-subtitle">Estatus: {getNombrePorID(estatuses, state.estatus)}</p>}
            </span>
        </div>


        <div className="p-col-12">
            <p className="section-title">Información general</p>
            <div className="card">
                <div className="p-grid ">
                    {editing && <>
                        <div className="form-group p-col-12 p-md-4">
                            <label htmlFor="numero-orden">Orden #</label>
                            <InputText disabled id="numero-orden" value={state.orden_num} />
                        </div>
                        <div className="form-group p-col-12 p-md-4">
                            <label htmlFor="fecha-creacion">Fecha de creación</label>
                            <InputText disabled id="fecha-creacion" value={moment(state.fecha_creacion).utcOffset("-0600").format("DD/MM/YYYY")} />
                        </div>
                    </>}
                    <ContenedorInput label="Urgencia" col={editing ? 12 : false} md={editing ? 4 : false}>
                        <Dropdown disabled={!puedeEditar()} id="urgencia" name="urgencia" optionLabel="nombre" optionValue="id" options={urgencias} value={state.urgencia} placeholder="Seleccionar" onChange={handleInputChange} />
                        {errores.includes("urgencia") && <Message severity="error" text="Este campo es requerido" />}
                    </ContenedorInput>
                    {state.urgencia && <ContenedorInput label="Fecha preferida de entrega">
                        <Calendar disabled={!puedeEditar()} name="fechaPreferidaEntrega" value={getCalendarDate(state.fechaPreferidaEntrega)} onChange={handleCalendarInputChange} dateFormat="dd/mm/yy" />
                        {errores.includes("fechaPreferidaEntrega") && <Message severity="error" text="Este campo es requerido" />}
                    </ContenedorInput>}
                </div>
            </div>
        </div>


        <div className="p-col-12">
            <p className="section-title">Detalle de orden</p>
            <div className="card">
                <div className="p-grid ">
                    {editing && <>
                        <div className="form-group p-col-12 p-md-6">
                            <label htmlFor="solicitante">Solicitante (Nombre)</label>
                            <InputText disabled id="solicitante" value={nombreSolicitante} />
                        </div>
                        <div className="form-group p-col-12 p-md-6">
                            <label htmlFor="departamento">Departamento</label>
                            <InputText disabled id="departamento" value={departamentoSolicitante} />
                        </div>
                    </>}
                    <div className="form-group p-col-12 p-md-6">
                        <label htmlFor="orden-ref">Orden Referencia</label>
                        <Dropdown disabled={!puedeEditar()} optionLabel="nombre" optionValue="id" id="orden_ref" name="orden_ref" options={ordenesRefs} value={state.orden_ref} onChange={handleInputChange} placeholder="Seleccionar"
                            filter={true} filterPlaceholder="Buscar orden de referencia" />
                        {errores.includes("orden_ref") && <Message severity="error" text="Este campo es requerido" />}
                    </div>
                    <div className="form-group p-col-12 p-md-6">
                        <label htmlFor="requerimiento">Requerimiento</label>
                        <Dropdown disabled={!puedeEditar()} id="requerimiento" optionLabel="nombre" optionValue="id" name="requerimiento" options={requerimientos} value={state.requerimiento} onChange={handleInputChange} placeholder="Seleccionar"
                            filter={true} filterPlaceholder="Buscar requerimiento" />
                        {errores.includes("requerimiento") && <Message severity="error" text="Este campo es requerido" />}
                    </div>
                </div>
            </div>
        </div>

        <div className="p-col-12">
            <p className="section-title">Items de solicitud</p>
            {state.items.map((item, index) => {
                return <div className="card item-compras">
                    <div className="p-grid ">
                        <div className="p-col-12 p-md-7">
                            <div className="p-grid ">
                                <div className="form-group p-col-12 p-md-12">
                                    <label htmlFor="solicitud">Solicitud</label>
                                    <InputText disabled={!puedeEditar()} id="solicitud" name={"solicitud"} value={item.solicitud} onChange={(e) => handleInputChange(e, index)} />
                                    {errores.includes(`solicitud${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                </div>
                                <div className="form-group p-col p-col-md-3">
                                    <label htmlFor="cantidad">Cantidad</label>
                                    <InputText disabled={!puedeEditar()} keyfilter={"pnum"} id="cantidad" name={"cantidad"} value={item.cantidad} onChange={(e) => handleInputChange(e, index)} />
                                    {errores.includes(`cantidad${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                </div>
                                <div className="form-group p-col p-col-md-3">
                                    <label htmlFor="empresa">Empresa</label>
                                    <Dropdown disabled={!puedeEditar()} optionLabel="nombre" optionValue="id" id="empresa" name={"empresa"} options={empresas} value={item.empresa} onChange={(e) => handleInputChange(e, index)} placeholder="Seleccionar" />
                                    {errores.includes(`empresa${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                </div>
                                {empresaIsHTF(empresas, item.empresa) && <div className="form-group p-col-12 p-md-4">
                                    <label htmlFor="locacionHTF">Locación </label>
                                    <Dropdown disabled={!puedeEditar()} optionLabel="nombre" optionValue="id" id="locacionHTF" name={"locacionHTF"} options={locacionesHTF} value={item.locacionHTF} onChange={(e) => handleInputChange(e, index)} placeholder="Seleccionar" />
                                    {errores.includes(`locacionHTF${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                </div>}

                                {empresaIsHTF(empresas, item.empresa) &&
                                    <div className="form-group p-col-12 p-md-4">
                                        <label htmlFor="sitio">Sitio </label>
                                        <Dropdown disabled={disableItemSitio(item)} optionLabel="nombre" optionValue="id" id="sitio" name={"sitio"} options={getSitioOpciones(item)} value={item.sitio || ""} onChange={(e) => handleInputChange(e, index)} placeholder="Seleccionar" />
                                        {errores.includes(`sitio${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                    </div>}


                                <div className="form-group p-col-12 p-md-4">
                                    <label htmlFor="locacion">Ubicación</label>
                                    <Dropdown disabled={!puedeEditar()} optionLabel="nombre" optionValue="id" id="locacion" name={"locacion"} options={getItemLocacionOpciones(item.empresa, item.locacionHTF, item.sitio)} value={item.locacion} placeholder="Seleccionar" onChange={(e) => handleInputChange(e, index)} placeholder="Seleccionar" />
                                    {errores.includes(`locacion${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                </div>

                                <div className="form-group p-col p-col-md-3">
                                    <label htmlFor="telefono">Teléfono</label>
                                    <InputText disabled={!puedeEditar()} keyfilter={"pnum"} id="telefono" name={"telefono"} value={item.telefono} onChange={(e) => handleInputChange(e, index)} />
                                    {errores.includes(`telefono${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                </div>
                                <div className="form-group p-col p-col-md-3">
                                    <label htmlFor="anydesk">Anydesk</label>
                                    <InputText disabled={!puedeEditar()} keyfilter={"pnum"} id="anydesk" name={"anydesk"} value={item.anydesk} onChange={(e) => handleInputChange(e, index)} />
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-md-5">
                            <div className="p-grid ">
                                <div className="form-group p-col-12 p-md-12">
                                    <label htmlFor="detalle">Detalle de Solicitud</label>
                                    <InputTextarea disabled={!puedeEditar()} rows={3} cols={30} autoResize={true} id="detalle" name={"detalle"} value={item.detalle} onChange={(e) => handleInputChange(e, index)} placeholder={"Favor de especificar toda la información adicional que permita identificar con más precisión el artículo (ejemplo: dimensiones, colores, marca, tipo de material, preferencia por proveedor, etc); así como de justificar la solicitud al describir el uso que se le dará al material solicitado."} />
                                    {errores.includes(`detalle${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 ">
                            <div className="p-grid ">
                                <div className="form-group p-col-12 ">
                                    <label htmlFor="archivo">Subir archivo (imagen/pdf/excel)</label>
                                    {item.archivo && item.archivo.URL && <ArchivoPreview archivo={item.archivo} verArchivo={() => verArchivoFuncion(item.archivo)} puedeEditar={puedeEditar()} deleteArchivo={(e) => deleteArchivoItem(e, index)} />}
                                    <input disabled={!puedeEditar()} name={"archivo"} id="archivo" onChange={(e) => handleItemArchivoChange(e, index)} type="file" className="form-control-file" accept="image/jpeg,image/jpg,image/png, application/pdf, .xlsx,.xls" />
                                </div>
                            </div>
                        </div>

                        {state.items.length !== 1 && !editing &&
                            <div className="p-col-12 p-justify-end btn-row">
                                <Button label="Eliminar Item" onClick={() => eliminarItem(index)} />
                            </div>
                        }

                    </div>
                </div>
            })}
            {!editing && <div className="p-grid p-fluid ">
                <Button label="+ Agregar nuevo item" onClick={agregarItem} />
            </div>}
        </div>


        {editing && <div className="p-col-12">
            <p className="section-title">Administración de la orden</p>
            <div className="card">
                <div className="p-grid ">
                    <div className="form-group p-col-12 p-md-6">
                        <label htmlFor="estatus-nombre">Estatus </label>
                        <InputText disabled id="estatus-nombre" value={getNombrePorID(estatuses, state.estatus)} />
                    </div>

                    {(puedeEditar() || state.comentarioAdmin !== "") && <div className="form-group p-col-12 p-md-12">
                        <label htmlFor="detalle">Comentario administrador de sistemas</label>
                        <InputTextarea disabled={!puedeEditar()} rows={3} cols={30} autoResize={true} id="comentarioAdmin" name="comentarioAdmin" value={state.comentarioAdmin} onChange={handleInputChange} placeholder={"Comentario administrador de sistemas"} />
                    </div>}
                    {puedeEditar() && state.fechaFinalizado &&
                        <div className="form-group p-col-12 p-md-6">
                            <label htmlFor="status-nuevo">Fecha de finalización </label>
                            <InputText disabled id="status-nuevo" value={moment(state.fechaFinalizado).utcOffset("-0600").format("DD/MM/YYYY HH:mm")} />
                        </div>}
                </div>
            </div>
        </div>}


        <PreviewModal imageURL={previewDialogURL} closePreviewModal={() => setPreviewDialogURL("")} />
        <CambiarEstatusModal visible={cambiarEstatusModalOpen} setFormularioState={(e) => setState({ ...state, ...e })} estatus={state.estatus} estatuses={estatuses} state={state} handleInputChange={handleInputChange} closeModal={() => setCambiarEstatusModalOpen(false)} requisicionID={requisicionID} />

        <div className="p-col-12">
            <div className="p-grid p-justify-end btn-row">
                <Button label="Cancelar" onClick={() => { props.history.goBack() }} />
                {puedeEditarEstatus() && <Button disabled={ordenFinalizado()} label="Cambiar estatus" onClick={() => setCambiarEstatusModalOpen(true)} />}
                <Button disabled={!puedeEditar()} label="Guardar" onClick={guardarOrden} />
            </div>
        </div>


    </div>
}
export default FormularioSistema; //448
