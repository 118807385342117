import React, { useState, useGlobal, useEffect } from 'reactn';
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { obtenerDefectos } from '../../service/Defectos';
import { MultiSelect } from 'primereact/multiselect';

const ModalSeleccionarDefectos = (props) => {
    return (
        <Dialog header="Defecto en muestra" className="modal-50" visible={props.visible} onHide={props.onHide}>
            <div className="p-grid">
                <div className="form-group p-col-12">
                    <MultiSelect filter={true} optionLabel="nombre" dataKey="id" 
                        selectedItemsLabel={`${props.defectosSeleccionados.length} defectos seleccionados`}
                        onChange={props.setDefectosSeleccionados} value={props.defectosSeleccionados} options={props.defectos} placeholder="Sin defectos seleccionados" />
                </div>
            </div>
            <div className="p-col-12">
                <div className="p-grid p-justify-end btn-row">
                    <Button label="Aceptar" onClick={props.onHide} />
                </div>
            </div>
        </Dialog>
    )
}
export default ModalSeleccionarDefectos