import React from 'reactn';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { withRouter } from 'react-router-dom'
import { getNombrePorID, getObjetoPorID, tresDecimales } from '../../../util/functions';
const TablaRegistros = (props) => {
    const bodyTemplate = (rowData, column) => {
        if (column.field === "presentacion_ref") { return getObjetoPorID(props.presentaciones, rowData.presentacion_ref)?.presentacion || ""; }
        else if (column.field === "producto_de_presentacion") {
            const presentacion = getObjetoPorID(props.presentaciones, rowData.presentacion_ref);
            return presentacion.mezcla ? "Medleys" : getNombrePorID(props.productos, presentacion.producto_ref);
        }
        else if (column.field === "subVariedad_ref" && rowData.subVariedad_ref) {
            const variedad = getObjetoPorID(props.variedades, rowData.subVariedad_ref);
            const color = getObjetoPorID(props.colores, variedad.color);
            return color.nombre ? `${variedad.nombre} - ${color.nombre}` : variedad.nombre;
        }
        else if (column.field === "proveedor") {
            const proveedor = getObjetoPorID(props.proveedores, rowData.proveedor);
            return proveedor.nombre || "";
        }
        else if (column.field == "peso_promedio_cajas") { return tresDecimales(rowData.peso_promedio_cajas); }
        else return rowData[column.field];
    }

    return (
        <DataTable value={props.registros} emptyMessage="No hay registros" onRowClick={row => { props.history.push(`${props.rutaEdicion}/${row.data.id}`); }} rowHover={true}>
            {props.columnas.map(col => { return <Column header={col.nombre} field={col.field} body={bodyTemplate} /> })}
        </DataTable>);
}
export default withRouter(TablaRegistros);