import React from 'react'
import actividadesEmpleados from './../../../images/graficas/actividadesempleados.png';
import actividadesEmpleadosold from './../../../images/graficas/actividadesempleados_old.png';
import bonohoras from './../../../images/graficas/bonohoras.png';
import desgloseActividades from './../../../images/graficas/desgloseactividades.png';
import horasActividad from './../../../images/graficas/horasactividad.png';
import { Card } from 'primereact/card';
import consultasmedicas from './../../../images/graficas/consultasmedicas.png';

export default function HomeRecursosHumanos(props) {
    return (
        <React.Fragment>
            <div className="titulo-seccion-graficas">
                <span className="icon-general user-icon"></span>
                Recursos humanos
            </div>
            <div className="p-col-12 p-md-3  grafica-card" onClick={() => props.ir("/nomina/graficas/horas_actividades")}>
                <Card className={`htg-card bor-gray-superlight`}  >
                    <div className="card-title">Horas trabajadas vs actividad</div>
                    <img src={horasActividad} />
                </Card>
            </div>
            <div className="p-col-12 p-md-3  grafica-card" onClick={() => props.ir("/nomina/graficas/empleados_actividad")}>
                <Card className={`htg-card bor-gray-superlight`}  >
                    <div className="card-title">Actividades por empleado</div>
                    <img src={actividadesEmpleados} />
                </Card>
            </div>
            <div className="p-col-12 p-md-3  grafica-card" onClick={() => props.ir("/nomina/graficas/actividades_empleado")}>
                <Card className={`htg-card bor-gray-superlight`}  >
                    <div className="card-title">Producción de empleado</div>
                    <img src={actividadesEmpleadosold} />
                </Card>
            </div>
            <div className="p-col-12 p-md-3  grafica-card" onClick={() => props.ir("/nomina/graficas/desglose_porcentaje_actividades")}>
                <Card className={`htg-card bor-gray-superlight`}  >
                    <div className="card-title">Desglose de actividades</div>
                    <img src={desgloseActividades} />
                </Card>
            </div>
            <div className="p-col-12 p-md-3  grafica-card" onClick={() => props.ir("/nomina/graficas/bono_horas")}>
                <Card className={`htg-card bor-gray-superlight`}  >
                    <div className="card-title">Bono por horas trabajadas</div>
                    <img src={bonohoras} />
                </Card>
            </div>
            <div className="p-col-12 p-md-3  grafica-card" onClick={() => props.ir("/reporte_medico/grafica")}>
                <Card className={`htg-card bor-gray-superlight`}  >
                    <div className="card-title">Gráfica de consultas médicas</div>
                    <img src={consultasmedicas} />
                </Card>
            </div>
        </React.Fragment>)
}//60