import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { TabMenu } from 'primereact/tabmenu'
import React from 'react'
import { useEffect, useGlobal, useState } from 'reactn'
import { segundosSemana } from '../../constants'
import { obtenerCiclo } from '../../service/Ciclos'
import { obtenerClientes, obtenerClientesCiclo } from '../../service/Clientes'
import { obtenerEmpresas } from '../../service/Empresas'
import { getDetallesSemana, obtenerInicioSemana, obtenerInicioSemanaDia, obtenerListaDeSemanas, obtenerNombreSemanaCompacto } from '../../service/fechas'
import { obtenerPresentaciones } from '../../service/Presentaciones'
import { obtenerDesfaseCobranzaMaximo, obtenerReporteDeCobranzaTotal } from '../../service/ReporteCobranza'
import ContenedorHeader from '../common/ContenedorHeader'
import ContenedorPrincipal from '../common/ContenedorPrincipal'
import ContenedorTabla from '../common/ContenedorTabla'
import SeccionFiltros from '../common/SeccionFiltros'
import ContenedorFiltro from '../common/ContenedorFiltro'
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { cuatroDecimales, dosDeciComas, separadoComas, sumarNumerosArrayPorCampo } from '../../util/functions'


const semanasDesfase = 3;

export default function ReporteCobranza(props) {
    const [tabs, setTabs] = useState([]);
    const [, setCargando] = useGlobal("cargando");
    const [clientesCiclo, setClientesCiclo] = useGlobal("clientesDelCiclo");
    const [activeTab, setActiveTab] = useState()
    const [presentaciones, setPresentaciones] = useState([]);
    const [clientes] = useState([]);
    const [resumenTotal, setResumenTotal] = useState([]);
    const [resumenDesglose, setResumenDesglose] = useState([]);
    const [semanasDesfaseMaximo, setSemanasDesfaseMaximo] = useState(semanasDesfase);
    const [semanas, setSemanas] = useState([]);
    const [cicloId] = useGlobal("ciclo");
    const [ciclo] = useGlobal("cicloObj");
    const [weeks, week, handleWeekChange] = useWeekSelector();
    const [clientesOrig, setClientesOrig] = useState([])
    const [nombreModulo, setNombreModulo] = useGlobal("nombreModulo");
    useEffect(() => {
        setNombreModulo("Reporte de Cobranza");
    }, [])
    useEffect(() => {
        if (cicloId) {
            obtenerDatosIniciales(cicloId);
            obtenerSemanas();
        }
    }, [cicloId])

    useEffect(() => {
        if (clientesOrig?.length > 0 && cicloId && week.nombre !== "Cargando") updateDatosReporte();
    }, [clientesOrig, cicloId, week])

    const obtenerSemanas = async (params) => {
        let semanas = obtenerListaDeSemanas(ciclo.semana_inicio, ciclo.semana_cierre);
        setSemanas(semanas);
    }

    const updateDatosReporte = async () => {
        setCargando(true)
        let clientesDelCiclo = clientesCiclo || await obtenerClientesCiclo(cicloId);
        let idsClientes = clientesDelCiclo.map(el => el.cliente_ref);
        let clientes = clientesOrig.filter(el => idsClientes.includes((el.id)))
        setClientesCiclo(clientesDelCiclo);

        const promisesEmpresas = tabs.map(empresa => procesarDatosEmpresa(clientes, empresa.id))
        const resumenEmpresas = await Promise.all(promisesEmpresas)
        const resumenEmpresasObj = {};
        tabs.forEach((empresa, i) => { resumenEmpresasObj[empresa.id] = resumenEmpresas[i] })
        setResumenTotal(resumenEmpresasObj);
        console.log("updateDatosReporte ", {resumenEmpresasObj, resumenEmpresas})
        setCargando(false)
    }
    const procesarDatosEmpresa = async (clientesPassed, empresaId) => {
        let resumenCobranzaTotal = await obtenerReporteDeCobranzaTotal(cicloId, empresaId, week.time);
        let procesado = asignarSemanasDesfasadas(clientesPassed, resumenCobranzaTotal);
        console.log("procesarDatosEmpresa ", {empresaId, clientesPassed, procesado, resumenCobranzaTotal})
        return procesado;
    }
    const asignarSemanasDesfasadas = (clientes, resumen) => {
        let result = [];
        clientes.forEach((cliente, index) => {
            let resumenCliente = resumen.find(el => el["idCliente"] === cliente.id);
            let resumenDesfasado = { idCliente: cliente.id, nombre: cliente.nombre };
            let semanasDesfasadas = obtenerListaDeSemanas(ciclo.semana_inicio + cliente.desfase_cobranza * segundosSemana,
                ciclo.semana_cierre + cliente.desfase_cobranza * segundosSemana)

            semanasDesfasadas.forEach((semana, index) => {
                resumenDesfasado[semana.time] = isNaN(resumenCliente[semanas[index].time]) ? 0 : resumenCliente[semanas[index].time];
            })
            result.push(resumenDesfasado);
        })
        return result;
    }

    useEffect(() => {
        if (clientes.length > 0 && presentaciones.length > 0) {
            let result = {};
            clientes.forEach(cliente => {
                result[cliente.id] = [];
                let presentacionesFiltradas = presentaciones.filter(el => el.cliente_ref === cliente.id);
                presentacionesFiltradas.forEach(presentacion => {
                    let data = { presentacion: presentacion.presentacion }
                    for (let index = 0; index < 18; index++) {
                        data[`semana${index}`] = 0;

                    }
                    result[cliente.id].push(data);
                })

            })
            setResumenDesglose(result);
        }
    }, [clientes, presentaciones])
    const obtenerDatosIniciales = async (params) => {
        setCargando(true)
        let empresas = await obtenerEmpresas();
        empresas.forEach(curr => {
            curr["label"] = curr["nombre"];
        })
        setTabs(empresas);
        setActiveTab(empresas[0])

        let desfase = await obtenerDesfaseCobranzaMaximo();
        setSemanasDesfaseMaximo(desfase);

        let presentaciones = await obtenerPresentaciones();
        setPresentaciones(presentaciones);
        let clientes = await obtenerClientes();
        setClientesOrig(clientes);


    }
    const templateDatos = (rowData, column) => {
        let data = rowData[column["field"]]
        return data ? `$${separadoComas(cuatroDecimales(rowData[column["field"]]))} ` : "";
    }

    const obtenerColumnasTotal = (params) => {
        let cols = [<Column key="nombre" field="nombre" header="Cliente" footer={"Total:"} />];
        let semanaInicio = week.time;
        let semanas = [];
        for (let index = 0; index <= semanasDesfaseMaximo; index++) {
            semanas.push(obtenerInicioSemanaDia(semanaInicio + index * segundosSemana).unix());
        }

        for (let index = 0; index < semanasDesfaseMaximo; index++) {
            const semanaTimestamp = semanas[index]
            const sumaDolares = sumarNumerosArrayPorCampo(resumenTotal?.[activeTab?.id], `${semanaTimestamp}`);
            const sumaFormateado = (isNaN(parseFloat(sumaDolares)) || !sumaDolares) ? "" : dosDeciComas(sumaDolares);
            const tieneDecimales = sumaFormateado.includes(".");
            const moneySplit = sumaFormateado.split(".");

            //console.log("obtenerColumnasTotal: ", {sumaDolares, sumaFormateado, moneySplit, tieneDecimales})
            if (tieneDecimales && moneySplit?.length === 2 && moneySplit[1]?.length === 1) sumaFormateado = `${sumaFormateado}0`
            const footerTotal = sumaFormateado ? `$${sumaFormateado}` : "";
            cols.push(
                <Column key={index} field={`${semanas[index]}`} header={`Semana ${obtenerNombreSemanaCompacto(semanas[index])}`}
                    body={templateDatos} footer={footerTotal} />
            )
        }
        return cols;
    }


    return (
        <ContenedorPrincipal>
            <ContenedorHeader titulo="Reporte de Cobranza" iconos={"reporte-empaque-icon"} />

            <SeccionFiltros>
                <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
            </SeccionFiltros>
            <div className="p-col-12">
                <TabMenu model={tabs} id="id" activeItem={activeTab} onTabChange={(e) => setActiveTab(e.value)} />
                <div className="card p-0">
                    {<DataTable value={resumenTotal?.[activeTab?.id]} >
                        {obtenerColumnasTotal()}
                    </DataTable>}
                </div>
            </div>

            <div className="p-col-12">
                <p className="section-title">Notas</p>
                <div className="card">
                    <p className="mt-0">Cada cliente tiene un desfase en la cobranza </p>
                    <p className="mt-0">WINDSET tiene un desfase de 7 semanas </p>
                    <p className="mt-0">Los demás clientes tienen un desfase de 3 semanas </p>
                </div>
            </div>
        </ContenedorPrincipal>)
}
//233