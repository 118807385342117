import React, { useState, useEffect, useGlobal } from 'reactn';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from "primereact/dialog";

import { obtenerClientes } from '../../../service/Clientes';
import { obtenerPresentaciones } from '../../../service/Presentaciones';

import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import readXlsxFile from 'read-excel-file'
import * as firebase from "firebase/app";
import "firebase/firestore";

import ContenedorPrincipal from '../../common/ContenedorPrincipal';
import ContenedorHeader from '../../common/ContenedorHeader';
import ContenedorBotonesTop from '../../common/ContenedorBotonesTop';
import RowBotonesTop from '../../common/RowBotonesTop';
import SeccionFiltros from '../../common/SeccionFiltros';
import ContenedorFiltro from '../../common/ContenedorFiltro';
import ContenedorTablaAdmin from '../../common/ContenedorTablaAdmin';
import { getFirestoreID, guardarActividadesMantenimiento } from '../../../service/Mantenimiento';

const factorLbKg = 2.2;
const Presentaciones = (props) => {
    const [presentaciones, setPresentaciones] = useGlobal("presentaciones");
    const [presentacion, setPresentacion] = useGlobal("presentacion");
    const [nombreModulo, setNombreModulo] = useGlobal("nombreModulo");
    const [, setCargando] = useGlobal("cargando");
    const [usuario,] = useGlobal("usuario");

    const [presentacionesOrig, setPresentacionesOrig] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [productos, setProductos] = useState([]);
    const [filtroCliente, setFiltroCliente] = useState();
    const [filtroProducto, setFiltroProducto] = useState();
    const [globalFilter, setGlobalFilter] = useState("");
    const [excelModalOpen, setExcelModalOpen] = useState(false);
    const [presentacionesFichasDatos, setPresentacionesFichasDatos] = useState([]);
    const [productosBases] = useGlobal("productosBases")

    useEffect(() => {
        setNombreModulo('Presentaciones')
        if(productosBases){ getDatosIniciales(); }
    }, [productosBases])

    useEffect(() => {
        
        
        
        
        let presentaciones = presentacionesOrig.filter(el => {
            
            
            if (filtroCliente) {
                if (filtroProducto) {
                    //if(el.mezcla && filtroProducto.MEDLEYS_GENERAL)console.log("TRUE")
                    return el.cliente_ref === filtroCliente.id && (el.producto_ref === filtroProducto.id || (el.mezcla && filtroProducto.MEDLEYS_GENERAL));
                } else {
                    return el.cliente_ref === filtroCliente.id
                }
            } else {
                if (filtroProducto) {
                    return el.producto_ref === filtroProducto.id || (el.mezcla && filtroProducto.MEDLEYS_GENERAL);
                } else {
                    return true;
                }
            }

        })
        

        
        setPresentaciones(presentaciones);
    }, [filtroCliente, filtroProducto])

    const getDatosIniciales = () => {
        let presentaciones = obtenerPresentaciones().then(presentaciones => {
            
            
            setCargando(true);
            obtenerClientes().then(clientes => {
                    setClientes(clientes);
                    setProductos(productosBases);
                    presentaciones.forEach(presentacion => {
                        let cliente = clientes.find(cl => cl.id === presentacion.cliente_ref);
                        let producto = productosBases.find(prod => prod.id === presentacion.producto_ref || (presentacion.mezcla && prod.MEDLEYS_GENERAL));
                        presentacion["nombreCliente"] = cliente.nombre;
                        presentacion["nombreProducto"] = producto.nombre;
                    })
                    
                    setCargando(false);
                    presentaciones = presentaciones.map(el => {
                        el["peso_neto_caja_libras"] = (parseFloat(el["peso_neto_caja"]) * factorLbKg).toFixed(2);
                        return el;
                    })
                    setPresentaciones(presentaciones);
                    setPresentacionesOrig(presentaciones.slice());

                    if (props.location.data) {
                        setFiltroCliente(props.location.data.filters.filtroCliente)
                        setFiltroProducto(props.location.data.filters.filtroProducto)
                        setGlobalFilter(props.location.data.filters.globalFilter)
                    }

            }).catch(err => {
                setCargando(false);
            }).finally(el => {

            })

        });
    }

    const irAdminCategorias = () => { props.history.push("/admin/bono_empaque/tabla"); }
    const crearPresentacion = (params) => {
        props.history.push({ pathname: "/admin/presentaciones/crear", data: { filters: { filtroCliente, filtroProducto, globalFilter } } });
    }
    const irPresentacion = (event) => {
        
        
        if (["Administrador", "Administrador Embarques", "Administrador Empaque"].includes(usuario.rol)) {
            setPresentacion(event.data);
            props.history.push({ pathname: `/admin/presentaciones/modificar/${event.data.id}`, data: { filters: { filtroCliente, filtroProducto, globalFilter } } });
        }

    }


    const costoTemplate = (rowData, column) => {
        
        
        if (rowData[column["field"]]) {
            return `$ ${rowData[column["field"]]}`;
        } else {
            return ""
        }

    }

    const excelFooter = (params) => {
        return <div>
            <Button label="Cancelar" onClick={() => {
                setExcelModalOpen(false)
            }} />
            <Button label="Actualizar" onClick={() => actualizarFichasPresentaciones()} />
        </div>
    }

    const actualizarFichasPresentaciones = async () => {
        setCargando(true)
        try {
            for (let x = 0; x < presentacionesFichasDatos.length; x++) {
                let doc = presentaciones.find(pres => presentacionesFichasDatos[x].presentacion === pres.presentacion)
                if (doc)
                    await firebase.firestore().collection("presentaciones").doc(doc.id).update(presentacionesFichasDatos[x])
            }

            getDatosIniciales()
        }
        catch (error) {
            setExcelModalOpen(false)
            setCargando(false)
        }
        setExcelModalOpen(false)

    }

    const readFile = (excel) => {
        let fichasDatos = []
        readXlsxFile(excel).then((rows) => {
            rows.forEach((row, index) => {
                if (index !== 0) {
                    fichasDatos.push({
                        presentacion: row[0],
                        tipo_clamshell: row[1],
                        clamshell_caja: row[2],
                        tipo_caja: row[3],
                        tipo_tarima: row[4],
                        tipo_embalaje: row[5],
                        tipo_film: row[6],
                        tipo_etiqueta: row[7],
                        costo_material_lb: row[8],
                    })
                }
            })
            setPresentacionesFichasDatos(fichasDatos)
            setExcelModalOpen(true)
        })
    }


    const readFileMant = (excel) => {
        let cats = [], catsN = [], subcats = [], subcatsN = [], acts = [], promises = []
        readXlsxFile(excel).then((rows) => {
            //window.alert(rows.length)
            rows.forEach((row, index) => {
                if (index !== 0) {

                    if (!catsN.includes(row[0])) {
                        catsN.push(row[0])
                        cats.push({ id: getFirestoreID(), nombre: row[0], tipo: "categoria", classname: "" })
                        let { id, ...dataa } = cats[cats.length - 1]
                        promises.push(guardarActividadesMantenimiento(id, dataa))
                    }
                    if (!subcatsN.includes(row[1])) {
                        subcatsN.push(row[1])
                        subcats.push({ id: getFirestoreID(), nombre: row[1], tipo: "subcategoria", categoria_ref: cats[cats.length - 1].id })
                        let { id, ...dataa } = subcats[subcats.length - 1]
                        promises.push(guardarActividadesMantenimiento(id, dataa))
                    }
                    acts.push({ id: getFirestoreID(), nombre: row[2], subcategoria_ref: subcats[subcats.length - 1].id, categoria_ref: cats[cats.length - 1].id, tiempo_estimado: 1 })
                    let { id, ...dataa } = acts[acts.length - 1]
                    promises.push(guardarActividadesMantenimiento(id, dataa))
                }
            })
            Promise.all(promises)
        })
    }

    const hiddenFileInput = React.useRef(null);

    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    return <ContenedorPrincipal>
        <ContenedorHeader titulo="Administrar presentaciones de producto"
            iconos="presentacion-icon" col="p-col p-col-auto" />

        <ContenedorBotonesTop>

            <RowBotonesTop>
                {["Administrador"].includes(usuario.rol) ? <Button label="Categorias bono empaque" onClick={irAdminCategorias} /> : null}

                {!usuario.isRolFinanzas && <Button label="Subir Excel de fichas" onClick={handleClick} />}
                <input type="file" ref={hiddenFileInput} onChange={(e) => readFile(e.target.files[0])} style={{ display: 'none' }} />

                {["Administrador", "Administrador Embarques"].includes(usuario.rol) ? <Button label="Crear nueva" onClick={crearPresentacion} /> : null}
            </RowBotonesTop>
        </ContenedorBotonesTop>



        <SeccionFiltros>

            <div className="search-field form-group p-col">
                <FontAwesomeIcon icon={faSearch} />
                <InputText value={globalFilter} onChange={e => setGlobalFilter(e.target.value)} />
            </div>
            <ContenedorFiltro label="Cliente">
                <Dropdown optionLabel="nombre" value={filtroCliente} options={clientes} datakey="id" onChange={(e) => { setFiltroCliente(e.value) }} />
            </ContenedorFiltro>
            <ContenedorFiltro label="Producto">
                <Dropdown optionLabel="nombre" value={filtroProducto} options={productos} datakey="id" onChange={(e) => { setFiltroProducto(e.value) }} />
            </ContenedorFiltro>

        </SeccionFiltros>

        <ContenedorTablaAdmin>
            {["Administrador", "Administrador Embarques"].includes(usuario.rol) ?
                <DataTable selectionMode="single" className="tabla-presentaciones" value={presentaciones} globalFilter={globalFilter} onRowClick={irPresentacion}
                    scrollable={true} >
                    <Column field="id_presentacion" header="ID" sortable={true} />
                    <Column field="presentacion" header="Presentación HTG" sortable={true} />
                    <Column field="descripcion" header="Presentación Cliente" sortable={true} />
                    <Column field="costo_material_lb" header="Costo Material" body={costoTemplate} sortable={true} />
                    <Column field="numero_cajas_pallet" header="Número Cajas por Pallet" sortable={true} />
                    <Column field="peso_neto_caja" header="Peso Neto de Caja en Kg" sortable={true} />
                    <Column field="peso_neto_caja_libras" header="Peso Neto de Caja en Lb" sortable={true} />

                </DataTable> :
                <DataTable selectionMode="single" className="tabla-presentaciones" value={presentaciones} globalFilter={globalFilter} onRowClick={irPresentacion}
                    scrollable={true} >
                    <Column field="id_presentacion" header="ID" sortable={true} />
                    <Column field="presentacion" header="Presentación HTG" sortable={true} />
                    <Column field="descripcion" header="Presentación Cliente" sortable={true} />
                    <Column field="numero_cajas_pallet" header="Número Cajas por Pallet" sortable={true} />
                    <Column field="peso_neto_caja" header="Peso Neto de Caja en Kg" sortable={true} />
                    <Column field="peso_neto_caja_libras" header="Peso Neto de Caja en Lb" sortable={true} />

                </DataTable>
            }

        </ContenedorTablaAdmin>

        <Dialog header={"Aplicar cambios"} visible={excelModalOpen} style={{ width: '50vw' }} onHide={() => setExcelModalOpen(false)} footer={excelFooter()}>
            <div className="p-grid ">
                <div className="form-group p-col-12">
                    <label htmlFor="status-nuevo">Vas a actualizar {presentacionesFichasDatos.length} presentaciones:</label>
                    <ul>
                        {presentacionesFichasDatos.map(pres => <li>{pres.presentacion}</li>)}
                    </ul>
                </div>
            </div>
        </Dialog >

    </ContenedorPrincipal>
}
export default Presentaciones;//313