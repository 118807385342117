import React, { useEffect, useState } from 'react'
import ContenedorInput from '../../common/ContenedorInput'
import ContenedorPrincipal from './ContenedorPrincipal'
import { InputText } from 'primereact/inputtext';
import SeccionBotones from '../../common/SeccionBotones';
import { Button } from 'primereact/button';
import { useGlobal } from 'reactn';
import { guardarCapturaAgente } from './CapturaAgenteCtl';
import { Checkbox } from 'primereact/checkbox';
import ContenedorHeader from '../../common/ContenedorHeader';
import SeccionFormulario from '../../common/SeccionFormulario';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { getEmpresas } from '../../../service/Empresas';
import { segundosDia } from '../../../constants';
import { getInicioDia, obtenerInicioDia } from '../../../service/fechas';
import { dateToUnixTimeAsNumber13, DeepClone, formatearMinutosAHoras, getCalendarDate, getNombrePorID, padTo2Digits } from '../../../util/functions';
import { obtenerEmpaques } from '../../../service/Empaques';
import { Calendar } from 'primereact/calendar';
import { Message } from 'primereact/message';
import CardDetallesRequisicion from './CardDetallesRequisicion';
import SeccionFormularioCard from '../../common/SeccionFormularioCard';
import { InputSwitch } from 'primereact/inputswitch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faChartBar } from '@fortawesome/free-solid-svg-icons';

const initialState = {
    nombreTarea: "",
    tiempo_tomado: "",
    tiempos_tomados: [{ timestamp: null }],
    idTarea: "",
    comentario: ""
}


export default function CapturaTarea(props) {

    const [state, setState] = useState(initialState)
    const [tareaSeleccionada, setTareaSeleccionada] = useGlobal("tareaSeleccionada");
    const [actividadesMantenimiento,] = useGlobal("actividadesMantenimiento");
    const [, setNombreModulo] = useGlobal("nombreModulo")
    const [, setCargando] = useGlobal("cargando")
    const [cicloId,] = useGlobal("ciclo");
    const [empresas] = useGlobal("empresas")
    const [sitiosHTF, setSitiosHTF] = useState([])
    const [dia] = useGlobal("dia")
    const [usuario] = useGlobal("usuario")

    //opciones de filtros
    const [ordenesRefs] = useGlobal("ordenesRefsMant")
    const [urgencias] = useGlobal("urgenciasMant")
    const [requerimientos] = useGlobal("requerimientosMant")
    const [locacionesPlanMant] = useGlobal("locacionesPlanMant")
    const [locaciones] = useGlobal("locacionesMant")
    const [tiempoTomado, setTiempoTomado] = useState("")
    const [errores, setErrores] = useState({})

    useEffect(() => {
        setNombreModulo("Mantenimiento")
        getEmpresas()
        obtenerEmpaques().then(sitios => {
            const sitHTF = sitios.map(el => {
                if (el.nombre !== "Empaque Agropark") return el;
                else return { ...el, nombre: "Agropark" }
            })
            setSitiosHTF(sitHTF)
        });

    }, [])
    useEffect(() => {
        setErrores({})
    }, [state, tiempoTomado])

    useEffect(() => {

    }, [state.tareaParcial])

    useEffect(() => {
        if (tareaSeleccionada) {
            setTareaSeleccionada(null);
            let actividad = actividadesMantenimiento.find(actividad => actividad.id === tareaSeleccionada.actividad_ref)

            let newState = {
                ...tareaSeleccionada,
                nombreTarea: tareaSeleccionada.nombreTarea || actividad.nombre,
                idTarea: tareaSeleccionada.id,
                tiempo_tomado: "",
                tiempos_tomados: tareaSeleccionada.tiempos_tomados || [{ timestamp: null }],
                tiempo_tomado_timestamp: tareaSeleccionada.tiempo_tomado_timestamp || 1,
                is_emergencia: tareaSeleccionada.is_emergencia || false,
                is_requisicion: tareaSeleccionada.is_requisicion || false,
            }
            if (tareaSeleccionada.tiempo_tomado) {
                newState.tiempo_tomado = tareaSeleccionada.tiempo_tomado
                setTiempoTomado(formatearMinutosAHoras(tareaSeleccionada.tiempo_tomado));
            }

            setState(newState)
        } else if (!actividadesMantenimiento) {
            props.history.replace("/mantenimiento/agente/consulta_semana")
        }

    }, [tareaSeleccionada])
    const handleChange = (event) => {
        let copia = { ...state };
        copia[event.target.name] = event.target.value;
        setState(copia);
    }
    const cancelar = (params) => {
        props.history.goBack();
    }
    const handleGuardar = async () => {
        setCargando(true)
        if (validarErrores()) {
            await guardarCapturaAgente(cicloId, { ...state });
            props.history.goBack();
        }
        setCargando(false)
    }
    const handleGuardarParcial = async () => {
        setCargando(true)
        const noFinalizar = true
        await guardarCapturaAgente(cicloId, { ...state }, noFinalizar);
        props.history.goBack();
        setCargando(false)
    }
    const validarErrores = () => {
        const errores = {}, valoresRequeridos = ["comentario", "tiempo_tomado"];
        valoresRequeridos.forEach(field => {
            if (!state[field]) { errores[field] = "Este campo es requerido" }
            if (field === "tiempo_tomado" && (!state.tiempo_tomado || state.tiempo_tomado == "0")) { errores[field] = "Debes poner un valor mayor a 0" }
        })

        setErrores(errores)
        return Object.keys(errores).length === 0;
    }

    const getMinutes = (tiempo = "0:0") => {
        const [hrs, mins] = tiempo.split(":")
        const minutos = (parseInt(hrs) * 60) + parseInt(mins)
        return `${minutos}`
    }
    const handleCalendarInputChange = (event) => {
        const stateCopy = DeepClone(state);
        const { name, value, id: index } = event.target;

        const timestamp = dateToUnixTimeAsNumber13(value);
        const date = new Date(value);
        const hoursAndMinutes = padTo2Digits(date.getHours()) + ':' + padTo2Digits(date.getMinutes());

        if (name === "tiempo_tomado_parcial") {
            stateCopy.tiempos_tomados[parseInt(index)] = { timestamp, tiempo_tomado: getMinutes(hoursAndMinutes) };
            let tomado = 0;
            stateCopy.tiempos_tomados.forEach(tiempo => {
                tomado += parseInt(tiempo.tiempo_tomado)
            })
            stateCopy.tiempo_tomado = `${tomado}`;
        }
        else {
            stateCopy.tiempo_tomado_timestamp = timestamp
            stateCopy.tiempo_tomado = getMinutes(hoursAndMinutes)
        }
        console.log("TOMADO: ", stateCopy.tiempo_tomado)
        setState(stateCopy)
    }

    const esDiaPasado = () => dia?.unixTime < getInicioDia(Date.now());
    const getTiempoValor = (tiempo) => {
        if (!tiempo || tiempo === 1) return getCalendarDate(getInicioDia(Date.now()) * 1000)
        else return getCalendarDate(tiempo)
    }

    const agregarItem = () => {
        const stateCopy = DeepClone(state)
        stateCopy.tiempos_tomados.push({ timestamp: null })
        console.log("B4 ", stateCopy.tiempos_tomados)
        setState(stateCopy)
    }
    const eliminarItem = (index) => {
        let stateCopy = DeepClone(state)

        stateCopy.tiempos_tomados.splice(index, 1)
        console.log("AF ", stateCopy.tiempos_tomados)
        setState(stateCopy)
    }

    return (
        <ContenedorPrincipal>
            <Encabezado props={props} realizada={state.realizada} />

            {state.is_requisicion && <CardDetallesRequisicion {...state} />}

            <SeccionFormulario titulo={state.is_requisicion ? "Detalles de la actividad" : ""}>
                {(state.is_emergencia || getNombrePorID(empresas, state.empresa_ref) === "HTF") &&
                    <ContenedorInput label="Empresa">
                        <Dropdown name="empresa_ref" disabled value={state.empresa_ref} options={empresas} optionLabel="nombre" optionValue="id" />
                    </ContenedorInput>}

                {getNombrePorID(empresas, state.empresa_ref) === "HTF" && state.sitioHTF &&
                    <ContenedorInput col="12" label="Locación">
                        <InputText value={getNombrePorID(sitiosHTF || [], state.sitioHTF)} disabled />
                    </ContenedorInput>}

                {!state.is_requisicion &&
                    <ContenedorInput col="12" label="Tarea a completar">
                        <InputText value={state.nombreTarea} disabled />
                    </ContenedorInput>}

                {state.tareaParcial ?
                    <div className="p-col-12">
                        <div className="card">
                            {state.tiempos_tomados?.map((tiempo, index) => {
                                return (
                                    <div key={`${index + 1}`} className="form-group p-grid p-fluid pallet-dailyweight">

                                        <div className="p-col-auto">
                                            <p className="pallet-id">Tiempo {index + 1}</p>
                                        </div>
                                        <div className="p-col p-grid ">
                                            <div className="p-col">
                                                <Calendar key={`tt-${index}`} id={`${index}`} disabled={state.realizada} readOnlyInput={false} timeOnly={true} hourFormat="24"
                                                    name="tiempo_tomado_parcial" value={getTiempoValor(tiempo.timestamp)} onChange={handleCalendarInputChange} />
                                            </div>
                                        </div>

                                        {state.tiempos_tomados.length > 1 && !state.realizada &&
                                            <div className="p-col-auto">
                                                <button className="p-button" onClick={() => eliminarItem(index)}>
                                                    <FontAwesomeIcon icon={faTrashAlt} />
                                                </button>
                                            </div>}
                                    </div>)
                            })}
                            {!state.realizada &&

                                <Button label="+ Agregar nuevo item" onClick={agregarItem} />
                            }
                        </div></div>
                    :
                    <React.Fragment>
                        <ContenedorInput col="12" label="Tiempo ocupado">
                            <Calendar id="tiempo_tomado" disabled={state.realizada} readOnlyInput={false} timeOnly={true} hourFormat="24"
                                name="tiempo_tomado" value={getTiempoValor(state.tiempo_tomado_timestamp)} onChange={handleCalendarInputChange} />
                        </ContenedorInput>
                        {errores.tiempo_tomado && <Message severity="error" text={errores.tiempo_tomado} />}
                    </React.Fragment>}

                <ContenedorInput col="12" label="Tarea parcial?">
                    <div className="switch-container">
                        <InputSwitch onLabel="Si" offLabel="No" checked={state.tareaParcial} onChange={handleChange} name="tareaParcial" disabled={state.realizada} />
                        <span>{state.tareaParcial ? "Si" : "No"}</span>
                    </div>
                </ContenedorInput>

                <ContenedorInput col="12" label="Comentario">
                    <InputTextarea value={state.comentario} disabled={state.realizada} onChange={handleChange} name="comentario" />
                </ContenedorInput>
                {errores.comentario && <Message severity="error" text={errores.comentario} />}
            </SeccionFormulario>
            <SeccionBotones>
                <Button label="Cancelar" onClick={cancelar} />
                {(state.tareaParcial && !state.realizada)
                    ? <React.Fragment>
                        <Button label="Guardar" onClick={handleGuardarParcial} />
                        <Button label="Finalizar" onClick={handleGuardar} />
                    </React.Fragment>
                    : <Button label="Finalizar" disabled={state.realizada || esDiaPasado()} onClick={handleGuardar} />}
            </SeccionBotones>
        </ContenedorPrincipal>
    )
}
const Encabezado = (params) => {
    let { props } = params
    return <ContenedorHeader titulo={params.realizada ? "Tarea Concluida" : "Concluir tarea"} atras={props.history.goBack} iconos="mtto-icon" />
}//109