import React from 'reactn';
import { Button } from 'primereact/button';
import { getProductoYVariedadString } from '../../../util/functions';
import iconoExterno from '../../../images/icons/external-icon.svg';

const TemplateIconoInveranderoTabla = ({ invernadero }) => {
    return (
        <span className={`icon-general icon-mini icon-lort icon-click bor-${invernadero.color} bg-${invernadero.color}`}>
            <Button className="invisible-button2" tooltip={getProductoYVariedadString(invernadero)}>
                <div style={{}} >
                    {invernadero.es_externo ? <img width="30" alt="icono" src={iconoExterno} /> : invernadero.nombre}
                </div>
            </Button>
        </span>)
}
export default TemplateIconoInveranderoTabla;