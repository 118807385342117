//react/reactn
import React, { useGlobal, useEffect, setGlobal } from 'reactn'
//Funciones internas
import { getTextoBuscar } from '../../../../service/diccionarios/ProductosVariedades.dicc';
import { noHaSidoClausurado } from '../../../../service/Ciclos';
//Componentes third party
import { TabMenu } from 'primereact/tabmenu';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
//Componentes internos
import SeccionFiltros from '../../../planeacion_mantenimiento/CapturaAgentes/SeccionFiltros';
import ContenedorFiltroBusqueda from '../../../common/ContenedorFiltroBusqueda';
import RowBotonesTopThin from '../../../common/RowBotonesTopThin';
import BotonCrear from './BotonCrear.atomo';
//Constantes
import { LABEL_COLORES, LABEL_PRODUCTOS, LABEL_VARIEDADES } from '../../../../service/constants/ProductosYVariedades.const';

const tabs = [{ label: LABEL_PRODUCTOS }, { label: LABEL_VARIEDADES }, { label: LABEL_COLORES }];//mover a los archivos de constantes? 
setGlobal({ activeTabProductosYVariedades: tabs[0] });

const ContenedorTabMenu = (props) => {
    const [usuario] = useGlobal("usuario");
    const [activeTab, setActiveTab] = useGlobal("activeTabProductosYVariedades");
    const [globalFilter, setGlobalFilter] = useGlobal("globalFilterProductosYVariedades");

    useEffect(() => {
        setGlobalFilter("")
    }, [activeTab])

    const irAdministrarPackout = () => { props.history.push("/admin/packout_fijo"); }
    const puedeCrear = () => { return !usuario?.isRolFinanzas && noHaSidoClausurado(); }
    return (
        <React.Fragment>
            <RowBotonesTopThin>
                <Button onClick={irAdministrarPackout} label="Administrar packout" />
                {puedeCrear() && <BotonCrear history={props.history} /> /*Poner puedeCrear() aqui? o adentro del botón y regresar null */}
            </RowBotonesTopThin>

            <SeccionFiltros >
                <ContenedorFiltroBusqueda>
                    <FontAwesomeIcon icon={faSearch} />
                    <InputText type="search" value={globalFilter} onInput={e => setGlobalFilter(e.target.value)} placeholder={getTextoBuscar(activeTab?.label)} />
                </ContenedorFiltroBusqueda>
            </SeccionFiltros>

            <div className="p-col-12">
                <TabMenu data-testid="tabMenu" model={tabs} onTabChange={e => setActiveTab(e.value)} activeItem={activeTab || tabs[0]/*Preguntar sobre ahi (si funcionaría) o setGlobal?*/} />
                {props.children}
            </div>
        </React.Fragment>)
}
export default ContenedorTabMenu;