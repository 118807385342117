import * as firebase from "firebase/app";
import "firebase/firestore";

export const crearEmbarque = async (ciclo_ref,embarque) => {
    try {        
        const db = firebase.firestore();
        let result = await db.collection('ciclos')
        .doc(ciclo_ref)
        .collection("embarques")
        .add(embarque)
        return result;
    } catch (error) {
        console.log(`... SERVICIO EMBARQUES ERROR | registrarEmbarque() | ${ JSON.stringify(error) } `)
        throw error;
    }
}
