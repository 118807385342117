import React, { useEffect, useState } from 'react';
import { useGlobal } from "reactn";
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { SelectButton } from 'primereact/selectbutton';
import { obtenerNombreFechaCompacto } from '../../service/fechas';
import { obtenerActividades, obtenerActividadesCalidadDeTrabajo } from '../../service/actividades'
import { obtenerRegistrosCalidadInvernaderoSemana } from '../../service/CalidadTrabajo';
import { POLOS_INVERNADERO, POLO_NORTE } from '../../constants';
import ResumenDeEnfermedadesModal from "./modals/ResumenDeEnfermedadesModal"
import ResumenDeIncidenciasModal from "./modals/ResumenDeIncidenciasModal"
import MapaInvernadero from "./mapa_invernadero/MapaInvernadero"
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import ContenedorFiltro from '../common/ContenedorFiltro'
import SeccionFiltros from '../common/SeccionFiltros'
import { Button } from 'primereact/button';
import ContenedorHeader from '../common/ContenedorHeader';

function MapaInvernaderoCalidad(props) {
    // constantes
    const modosConsultaOpciones = [
        { id: "enfermedades-plagas", nombre: "Actividades de trabajo" },
        { id: "nivel-incidencia", nombre: "Motivo de evaluación" }
    ]
    const nivelesIncidencia = [
        { id: 'ni-p', nombre: 'Perfecto', nivel: 1 },
        { id: 'ni-a', nombre: 'Bajo', nivel: 2 },
        { id: 'ni-b', nombre: 'Medio', nivel: 3 },
        { id: 'ni-c', nombre: 'Alto', nivel: 4 },
        { id: 'ni-d', nombre: 'Grave', nivel: 5 },
    ]

    // datos iniciales
    const [, setCargando] = useGlobal("cargando")
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [ciclos] = useGlobal("ciclos")
    const [ciclo] = useGlobal("ciclo")
    const [invernaderos] = useGlobal("invernaderosFisicos")
    const [scale] = useGlobal("mapaBiocontrolTransformScale")
    const [enfermedades, setEnfermedades] = useGlobal("enfermedadesYPlagas")
    const [enfermedadesDeLaSemana, setEnfermedadesDeLaSemana] = useState([])
    const [mostrarResumenDeEnfermedades, setMostrarResumenDeEnfermedades] = useState(false)
    const [mostrarResumenDeIncidencias, setMostrarResumenDeIncidencias] = useState(false)
    const [claroSeleccionado, setClaroSeleccionado] = useState({ enfermedades: [], enfermedadesPasadas: [] })

    // filtros - generales
    const [invernaderoSeleccionado, setInvernaderoSeleccionado] = useState({})
    const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector()
    const [semanaPasadaSeleccionada, setSemanaPasadaSeleccionada] = useState({})

    // filtros - modo
    const [modoConsulta, setModoConsulta] = useState(modosConsultaOpciones[0].id)

    // filtros - enfermedades
    const [enfermedadesSeleccionadas, setEnfermedadesSeleccionadas] = useState([])
    const [registroSemana, setRegistroSemana] = useState([])
    const [registroSemanaPasada, setRegistroSemanaPasada] = useState([])
    const [cargaInicialConcluida, setCargaInicialConcluida] = useState(false)
    // filtros - porcentaje incidencia
    const [enfermedadSeleccionada, setEnfermedadSeleccionada] = useState({ id: "Todos", nombre: "Todos", importante: false, prioridad: 3 })
    const [nivelesIncidenciaSeleccionados, setNivelesIncidenciaSeleccionados] = useState(nivelesIncidencia)

    // mapa
    const [mapa, setMapa] = useState([])
    const [datosMapas, setDatosMapas] = useState([])

    useEffect(() => {
        setNombreModulo("Calidad de trabajo")
        setHabilitadoSelectorCiclo(false);
    }, [])
    useEffect(() => {
        setCargando(true)
        if (ciclo)
            updateCicloWeekSelector(ciclo);
    }, [ciclo])

    useEffect(() => {
        if (invernaderos)
            obtenerDatosIniciales()
    }, [invernaderos]);

    useEffect(() => {
        if (week.nombre !== "Cargando" && invernaderoSeleccionado.id && week.time && weeks.length !== 0)
            filtrarMapa()
    }, [invernaderoSeleccionado, week, weeks]);

    useEffect(() => {
        if (modoConsulta === "enfermedades-plagas") setEnfermedadesSeleccionadas(enfermedadesDeLaSemana)
    }, [modoConsulta]);

    useEffect(() => {
        if (ciclo && invernaderoSeleccionado.id && week.time && weeks.length !== 0 && cargaInicialConcluida)
            setMapa(generateInvernaderoMap(registroSemana, registroSemanaPasada, enfermedadesSeleccionadas))
    }, [enfermedadesSeleccionadas, enfermedadSeleccionada, nivelesIncidenciaSeleccionados, cargaInicialConcluida]);

    const modoConsultaActividades = () => modoConsulta === "enfermedades-plagas"
    const obtenerDatosIniciales = async () => {
        setCargando(true)


        let enfermedadesPlagas = enfermedades || await obtenerActividadesCalidadDeTrabajo()
        enfermedadesPlagas = enfermedadesPlagas.map(el => ({ ...el, prioridad: el.importante ? 1 : 3 }))
        setEnfermedades(enfermedadesPlagas)

        let invernaderoSeleccionado = props.location.data?.invernadero || invernaderos[0]
        setInvernaderoSeleccionado(invernaderoSeleccionado)
    }

    function makeCssClassForPrioridadEnfermedades(enfermedades, enfermedadesPasadas) {
        let priordades = [], priordadesPasadas = [], cssClass = '';
        (enfermedadesPasadas || []).forEach((enfermedad) => { priordades.push(`prioridad-${enfermedad.prioridad}`) });
        (enfermedadesPasadas || []).forEach((enfermedad) => { priordadesPasadas.push(`prioridad-${enfermedad.prioridad}`) });
        let priordadesSet = new Set(priordades);
        let priordadesPasadasSet = new Set(priordadesPasadas);
        (Array.from(priordadesSet) || []).forEach((prioridad) => {
            cssClass += `actual-${prioridad} `
        });
        (Array.from(priordadesPasadasSet) || []).forEach((prioridad) => {
            cssClass += `pasada-${prioridad} `
        });
        return cssClass.substring(0, cssClass.length - 1)
    }

    const makeCssClassForNombreEnfermedad = (nombreEnfermedad) => nombreEnfermedad
        ? nombreEnfermedad.toLowerCase().replace(/ /g, '-').replace(/[\s.,:()\/]/g, '') : ''

    const makeCssClassForEnfermedadesImportantes = (enfermedadesActuales, enfermedadesPasadas) => {
        var enfermedadesImportantesActualesCounter = 0
        enfermedadesImportantesActualesCounter = enfermedadesActuales.filter((enfermedad) => enfermedad.prioridad === 1 || enfermedad.prioridad === 2).length
        var enfermedadesImportantesPasadasCounter = 0
        enfermedadesImportantesPasadasCounter = enfermedadesPasadas.filter((enfermedad) => enfermedad.prioridad === 1 || enfermedad.prioridad === 2).length
        if (enfermedadesImportantesActualesCounter === 0 && enfermedadesImportantesPasadasCounter === 0) return ''
        return `${enfermedadesImportantesActualesCounter !== 0 ? 'enfermedades-importantes-' + enfermedadesImportantesActualesCounter : ''} ${enfermedadesImportantesPasadasCounter !== 0 ? 'enfermedades-pasadas-importantes-' + enfermedadesImportantesPasadasCounter : ''}`
    }

    const makeNombrePrioridadEnfermedad = (enfermedad) => {
        if (!enfermedad) return ""
        if (!enfermedad.prioridad) return ""
        return enfermedad.prioridad === 1 ? "importante" : "normal"
    }

    const generarDetallesDeEnfermedadesEnClaroSeleccionado = () => {
        let actuales = claroSeleccionado.enfermedades.map(registro => ({
            ...registro, fechaRegistro: obtenerNombreFechaCompacto(registro.fechaRegistro),
        }))
        let pasadas = claroSeleccionado.enfermedadesPasadas.map(registro => ({
            ...registro, fechaRegistro: obtenerNombreFechaCompacto(registro.fechaRegistro),
        }))
        return { actuales, pasadas }
    }

    const semanaSinRegistroDeEnfermedades = () => enfermedadesDeLaSemana.length === 0
    const claroSinRegistros = (claro) => (!claro.enfermedades && !claro.enfermedadesPasadas) ? true : (claro.enfermedades.length === 0 && claro.enfermedadesPasadas.length === 0)

    const generateInvernaderoMap = (registroEnfermedades, registroEnfermedadesSemanaPasada, enfermedadesPorVisualizar) => {
        setCargando(true)
        let mapaInv = [];

        (invernaderoSeleccionado?.capillas || []).forEach((capilla, capillaIndex) => {

            var capillaCompleta = [];
            POLOS_INVERNADERO.forEach((polo) => {
                let clarosPorLinea = polo.toLowerCase() == POLO_NORTE ? invernaderoSeleccionado.claros_por_linea_norte : invernaderoSeleccionado.claros_por_linea_sur
                var lineasPorCapilla = polo.toLowerCase() == POLO_NORTE ? capilla.lineas_norte : capilla.lineas_sur

                var zonaCapilla = []
                for (let lineaIndex = 0; lineaIndex < lineasPorCapilla; lineaIndex++) {
                    var linea = []
                    for (let claroIndex = 0; claroIndex < clarosPorLinea; claroIndex++) {

                        linea.push({
                            coordenada: { cPolo: polo.toLowerCase(), cCapilla: capillaIndex, cLinea: lineaIndex, cClaro: claroIndex },
                            enfermedades: obtenerEnfermedadesDeClaro(capillaIndex, polo, lineaIndex, claroIndex, registroEnfermedades, enfermedadesPorVisualizar),
                            enfermedadesPasadas: obtenerEnfermedadesDeClaro(capillaIndex, polo, lineaIndex, claroIndex, registroEnfermedadesSemanaPasada, enfermedadesPorVisualizar),
                            enfermedad: obtenerNivelesIncidenciaDeClaro(capillaIndex, polo, lineaIndex, claroIndex, registroEnfermedades, enfermedadSeleccionada),
                            enfermedadPasada: obtenerNivelesIncidenciaDeClaro(capillaIndex, polo, lineaIndex, claroIndex, registroEnfermedadesSemanaPasada, enfermedadSeleccionada)
                        })
                    }
                    zonaCapilla.push(linea)
                }

                var zonaCapillaTranspuesta = []
                for (let i = 0; i < zonaCapilla[0].length; i++) {
                    var linea = []
                    for (let j = 0; j < zonaCapilla.length; j++) {
                        linea.push({ ...zonaCapilla[j][i] })
                    }
                    zonaCapillaTranspuesta.push(linea);
                }

                capillaCompleta.push({ nombre: polo, contenido: zonaCapillaTranspuesta })
            });

            mapaInv.push(capillaCompleta)
        });

        setDatosMapas([...datosMapas, { invernaderoID: invernaderoSeleccionado.id, semana: week.time, mapa: mapaInv }])
        setCargando(false)
        return mapaInv
    }

    const obtenerEnfermedadesDeClaro = (capillaIndex, polo, lineaIndex, claroIndex, registroEnfermedades, enfermedadesPorVisualizar) => {
        let enfermedadesDeClaro = [];

        (registroEnfermedades || []).forEach((registroClaro) => {
            if (capillaIndex == registroClaro.c_capilla
                && polo == registroClaro.c_polo
                && lineaIndex == registroClaro.c_linea
                && claroIndex == registroClaro.c_claro) {

                let enfermedadesRefs = registroClaro.actividades;
                (enfermedadesRefs || []).forEach((enfermedadRef) => {
                    let enfermedadId = enfermedadRef.actividad_ref
                    let enfermedad = enfermedadesPorVisualizar.find(enfermedad => enfermedad.id == enfermedadId)
                    let nivelIncidencia = enfermedadRef.nivel_incidencia
                    if (enfermedad) enfermedadesDeClaro.push({ fechaRegistro: enfermedadRef.fecha_registro, ...enfermedad, nivelIncidencia })
                });

            }
        });

        return enfermedadesDeClaro
    }

    const obtenerNivelesIncidenciaDeClaro = (capillaIndex, polo, lineaIndex, claroIndex, registroEnfermedades, enfermedadPorVisualizar) => {
        if (!enfermedadPorVisualizar) return;
        let enfermedadDeClaro = {};
        (registroEnfermedades || []).forEach((registroClaro) => {
            if (capillaIndex == registroClaro.c_capilla
                && polo == registroClaro.c_polo
                && lineaIndex == registroClaro.c_linea
                && claroIndex == registroClaro.c_claro) {

               let nivelIncidenciaMasAlto = 1;
                let enfermedadesRefs = registroClaro.actividades
                enfermedadesRefs.forEach((enfermedadRef) => {
                    let enfermedadId = enfermedadRef.actividad_ref
                    let enfermedad = enfermedadPorVisualizar.id == enfermedadId || enfermedadPorVisualizar.id == "Todos"
                    let nivelIncidencia = enfermedadRef.nivel_incidencia

                    if (nivelIncidencia > nivelIncidenciaMasAlto)
                        nivelIncidenciaMasAlto = nivelIncidencia

                    if (enfermedad && filterNivelesIncidencia(nivelIncidencia)) { 
                        enfermedadDeClaro.nivelIncidencia = enfermedadPorVisualizar.id == "Todos"? nivelIncidenciaMasAlto :nivelIncidencia
                    }
                });

            }
        });

        return enfermedadDeClaro
    }
    const filterNivelesIncidencia = (nivelIncidencia) => nivelesIncidenciaSeleccionados.some(ni => nivelIncidencia === ni.nivel)

    const abrirResumenDeClaro = (claro) => {
        if (claroSinRegistros(claro)) return

        setClaroSeleccionado(claro)
        setMostrarResumenDeEnfermedades(modoConsultaActividades())
        setMostrarResumenDeIncidencias(!modoConsultaActividades())
    }

    const filtrarMapa = async () => {
        let found = datosMapas.find(el => (el.invernaderoID === invernaderoSeleccionado.id && el.semana === week.time))
        if (!found) {
            setCargaInicialConcluida(false)
            setCargando(true)

            // descargar detos para los filtros
            const invernaderoId = invernaderoSeleccionado.id
            const semanaUnixTimestamp = week.time
            var registroEnfermedadesSemanaActual = []
            let promises = []
            promises.push(obtenerRegistrosCalidadInvernaderoSemana(ciclo, invernaderoId, semanaUnixTimestamp))
            promises.push(obtenerRegistrosCalidadInvernaderoSemana(ciclo, invernaderoId, semanaUnixTimestamp - 24 * 7 * 60 * 60))
            let [registroEnfermedades, registroEnfermedadesPasadas] = await Promise.all(promises);
            setRegistroSemana(registroEnfermedades)
            setRegistroSemanaPasada(registroEnfermedadesPasadas)
            //window.alert(registroEnfermedades.length + " - "+registroEnfermedadesPasadas.length)
            // emfermedades a incluir en los filtros
            registroEnfermedadesSemanaActual = registroEnfermedades
            let enfermedadesEnRegistro = new Set()
            let nivelIncidenciaMasAlto = 0;
            (registroEnfermedades || []).forEach((registroClaro) => {
                let enfermedadesRefs = registroClaro.actividades;
                (enfermedadesRefs || []).forEach((enfermedadRef) => {
                    const nivelIncidencia = enfermedadRef.nivel_incidencia
                    const enfermedadId = enfermedadRef.actividad_ref
                    if (nivelIncidencia > nivelIncidenciaMasAlto) {
                        nivelIncidenciaMasAlto = nivelIncidencia
                    }
                    let enfermedad = enfermedades.find(enfermedad => enfermedad.id == enfermedadId)
                    if (enfermedad) enfermedadesEnRegistro.add(enfermedad)
                });
            });
            (registroEnfermedadesPasadas || []).forEach((registroClaro) => {
                let enfermedadesRefs = registroClaro.actividades;
                (enfermedadesRefs || []).forEach((enfermedadRef) => {
                    let enfermedadId = enfermedadRef.actividad_ref
                    let enfermedad = enfermedades.find(enfermedad => enfermedad.id == enfermedadId)
                    if (enfermedad) enfermedadesEnRegistro.add(enfermedad)
                });
            });
            var enfermedadesSemana = []
            enfermedadesSemana = Array.from(enfermedadesEnRegistro)
            setEnfermedadesDeLaSemana(enfermedadesSemana)
            setEnfermedadesSeleccionadas(enfermedadesSemana)

            // verificar que no se intente usar información de semana inexistente
            let semanaSeleccionadaIndex = weeks.findIndex(semana => semana.time == semanaUnixTimestamp)
            if (semanaSeleccionadaIndex < 1)
                setCargaInicialConcluida(true)
            else {
                setSemanaPasadaSeleccionada(weeks[semanaSeleccionadaIndex - 1])
                const semanaPasadaUnixTimestamp = weeks[semanaSeleccionadaIndex - 1].time
                obtenerRegistrosCalidadInvernaderoSemana(ciclo, invernaderoId, semanaPasadaUnixTimestamp).then((registroEnfermedadesSemanaPasada) => {
                    console.log(registroEnfermedadesSemanaPasada.length + " --- reg. semana pasada length")
                    setRegistroSemanaPasada(registroEnfermedadesSemanaPasada)
                    setCargaInicialConcluida(true)
                });
            }
        }
        else
            setMapa(found.mapa)
    }

    return (
        <div className="p-grid p-justify-center">

            {invernaderoSeleccionado.id && <ContenedorHeader col="p-col p-col-auto" titulo="Mapa de calidad de actividades" subtituloInv={""} invernaderoNombre={invernaderoSeleccionado.nombre}
                iconos={`icon-general icon-id bor-${invernaderoSeleccionado.color} bg-${invernaderoSeleccionado.color} `} atras={() => props.history.goBack()} />}


            <SeccionFiltros >
                <ContenedorFiltro label="Modo de consulta" filtrolabel="no">
                    <SelectButton id="modo-consulta" optionValue="id" optionLabel="nombre"
                        options={modosConsultaOpciones} value={modoConsulta} onChange={(e) => setModoConsulta(e.value)} />
                </ContenedorFiltro>

                <ContenedorFiltro label="Invernadero" filtrolabel="no">
                    <Dropdown id="filtroInvernadero" placeholder="Seleccionar" dataKey="id" optionLabel="nombre"
                        options={invernaderos} value={invernaderoSeleccionado} onChange={(e) => { setInvernaderoSeleccionado(e.value) }} />
                </ContenedorFiltro>

                <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />

                <ContenedorFiltro label="Actividades" filtrolabel="no">
                    {modoConsultaActividades()
                        ? <MultiSelect id="filtroEnfermedades" disabled={semanaSinRegistroDeEnfermedades()} dataKey="id" optionLabel="nombre" fixedPlaceholder={true} filter={true}
                            placeholder={semanaSinRegistroDeEnfermedades() ? 'N/A (sin registro)' : `${enfermedadesSeleccionadas.length}/${enfermedadesDeLaSemana.length} actividades`}
                            options={enfermedadesDeLaSemana} value={enfermedadesSeleccionadas} onChange={(e) => { setEnfermedadesSeleccionadas(e.value) }} />
                        : <Dropdown id="filtroEnfermedad" disabled={semanaSinRegistroDeEnfermedades()} dataKey="id" optionLabel="nombre"
                            placeholder={semanaSinRegistroDeEnfermedades() ? 'N/A (sin registro)' : 'Seleccionar'}
                            options={[{ id: "Todos", nombre: "Todos", importante: false, prioridad: 3 }, ...enfermedadesDeLaSemana]} value={enfermedadSeleccionada} onChange={(e) => { setEnfermedadSeleccionada(e.value) }} />}
                </ContenedorFiltro>

                {!modoConsultaActividades() &&
                    <ContenedorFiltro label="Motivo de evaluación" filtrolabel="no">
                        <MultiSelect id="filtroNivelIncidencia" disabled={semanaSinRegistroDeEnfermedades()} dataKey="id" optionLabel="nombre" fixedPlaceholder={true} filter={false}
                            placeholder={semanaSinRegistroDeEnfermedades() ? 'N/A (sin registro)' : `${nivelesIncidenciaSeleccionados.length} seleccionados`}
                            options={nivelesIncidencia} value={nivelesIncidenciaSeleccionados} onChange={(e) => { setNivelesIncidenciaSeleccionados(e.value) }} />
                    </ContenedorFiltro>}
            </SeccionFiltros >


            {mapa.length > 0 && <MapaInvernadero mapa={mapa} nombreModoConsulta={modoConsulta} modoEnfermedades={modoConsultaActividades()}
                nombrePrioridad={makeNombrePrioridadEnfermedad(enfermedadSeleccionada)} cssPrioridad={makeCssClassForPrioridadEnfermedades}
                abrirResumenDeClaro={(claro) => abrirResumenDeClaro(claro)} registrosImportantes={makeCssClassForEnfermedadesImportantes}
                nombreRegistro={makeCssClassForNombreEnfermedad} />}


            {/**/}
            <div className={`zoomLevel-${scale} p-col-12`}>
                <ResumenDeEnfermedadesModal scale={scale} visible={mostrarResumenDeEnfermedades} onHide={() => setMostrarResumenDeEnfermedades(false)}
                    detallesEnfermedades={generarDetallesDeEnfermedadesEnClaroSeleccionado()} week={week}
                    semanaPasadaSeleccionada={semanaPasadaSeleccionada} claroSeleccionado={claroSeleccionado} makeCssClass={makeCssClassForNombreEnfermedad} />

                <ResumenDeIncidenciasModal scale={scale} visible={mostrarResumenDeIncidencias} onHide={() => setMostrarResumenDeIncidencias(false)}
                    detallesEnfermedades={generarDetallesDeEnfermedadesEnClaroSeleccionado()} week={week}
                    semanaPasadaSeleccionada={semanaPasadaSeleccionada} claroSeleccionado={claroSeleccionado} makeNombre={makeNombrePrioridadEnfermedad} />
            </div>
        </div>
    );

}
export default MapaInvernaderoCalidad;