import React, {useEffect, useState, useGlobal} from 'reactn';
import { obtenerPorcentajeHorasTrabajadasEnCiclo, obtenerCuentaEmpleadosInvernadero } from '../../service/RhService';
import LineChart from '../common/LineChart';
import { stringToColor } from '../../util/functions';

const optionsOrig = {
    chart: {
      height: 50 ,
      type: 'line',
      zoom: {
        enabled: true
      }
       
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    title: {
      text: 'Porcentaje de actividades de los empleados durante el ciclo',
      align: 'left'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    xaxis: {
      categories: []
    }
}

const PorcentajeActividades = (props) => {
    const [invernaderos, setInvernaderos] = useState([]);
    const [invernaderosOrig, setInvernaderosOrig] = useState([]);
    const [ciclo,] = useGlobal("ciclo");
    const [options,setOptions] = useState({...optionsOrig})
    const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
    const [cicloObj] = useGlobal("cicloObj");
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");

    useEffect(()=>{
      setHabilitadoSelectorCiclo(true)
    },[])
    
    useEffect(() => {
        if(invernaderosVirtuales){
          obtenerDatosInvernaderos()
        }
        
    }, [invernaderosVirtuales])

    const obtenerDatosInvernaderos = async() =>{
      let promises = [];
      const invernaderos = invernaderosVirtuales.filter(inv=> !inv.es_externo)
      invernaderos.forEach(async invernadero => {
        promises.push(obtenerPorcentajeLocal(cicloObj,invernadero));  
      })     

      setInvernaderosOrig(invernaderos);
      let values = await Promise.all(promises);
      setInvernaderos(procesar(invernaderos, values));
    }

    const procesar = (invernaderos, resultPorc) => {
        let labels = resultPorc[0].map(el=>{
            return el.semana.nombreCompacto;
        })
        let copiaOptions ={...options};
        copiaOptions["xaxis"]["categories"] = labels;
        let colores = invernaderos.map(el=>stringToColor(el.color));
        copiaOptions["colors"] = colores;
        setOptions(copiaOptions);
        let datasets = invernaderos.map((el,index)=>{
            let color = stringToColor(el.color);
            return {
                name:el.nombre,
                datasetId:el.id,
                label: el.nombre,
                data: resultPorc[index].map(el=>{
                    return el.porcentaje
                }),
                fill:false,
                borderColor: color,
                backgroundColor: color
            }
        });
        return {labels, datasets}
    }

    const obtenerPorcentajeLocal = async (ciclo,invernadero) => {
        try {
            let cuenta = await obtenerCuentaEmpleadosInvernadero(ciclo.id, invernadero.id);
            let porcentaje = await obtenerPorcentajeHorasTrabajadasEnCiclo(ciclo,invernadero, cuenta);    
            return porcentaje;
        } catch (error) {
            throw(error);
        }
    }
    
    return <div className="p-grid chart-page">
            <div className="p-col  p-col-auto title-container">
                  <span className="back-btn" onClick={() => { props.history.goBack() }}></span>
                  <span className={`icon-general chart-icon `}></span>
                  <span className="titles-group">
                    <h1 className="header-title">Porcentaje de actividades</h1>
                  </span>
            </div>
        
          <LineChart data = {invernaderos} filtrosOrig = {invernaderosOrig} options = {options} />
        
    </div>
}

export default PorcentajeActividades;
