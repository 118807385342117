import * as firebase from 'firebase/app';
import { setGlobal } from "reactn"
import "firebase/firestore";
import {
    obtenerInicioSemanaDia, obtenerDiasDeSemana,
    obtenerInicioDia,
    obtenerListaDeSemanas
} from './fechas';
import { nombresDiasSemanaPlanos, segundosDia, segundosSemana } from '../constants';
import { getPresentaciones, obtenerPresentaciones, obtenerPresentacionesHabilitadas } from './Presentaciones';
import { obtenerReporteDefectos } from './ReporteCalidad';
import { obtenerInvernaderosVirtualesActivos } from './Invernaderos';
import { obtenerOrdenesSemana } from './QueriesOrdenes';
import { obtenerOrdenesCiclo } from './Ordenes';
import { sumarNumerosArrayPorCampo, getObjetoPorID, ordenarArreglaDeObjetosPorLlave } from '../util/functions';
import { obtenerDefectos as obtenerDefectosColeccion } from './Defectos';
import { obtenerProveedores } from './Queries/Proveedores';
import { obtenerEmpacadoras } from './Empacadoras';
import { uploadStatistics } from './Estadisticas'
import { obtenerPesoPromedioCajaSemana } from './Producto';

function registrarInvocaciones(count) {
    uploadStatistics(count)
}

export const todos = { id: "todos", nombre: "Todos" }
export const obtenerFiltrosCalidadTodos = async (ciclo) => {
    try {
        let defectos = await obtenerDefectosColeccion()
        let proveedores = await obtenerProveedores(ciclo)
        let empacadoras = await obtenerEmpacadoras()
        let presentaciones = await obtenerPresentacionesHabilitadas()

        setGlobal({ defectos, proveedores, empacadoras, presentaciones, proveedorExternoCalidad: todos, datosCargadosCalidad: true })
    }
    catch (error) { console.log(error) }
}

export const guardarCalidadInicial = (ciclo, invernadero, datos, registroID) => guardarRegistroCalidad(ciclo, invernadero, datos, registroID, "inicial")
export const guardarCalidadFinal = (ciclo, invernadero, datos, registroID) => guardarRegistroCalidad(ciclo, invernadero, datos, registroID, "final")
export const guardarCalidadPesos = (ciclo, invernadero, datos, registroID) => guardarRegistroCalidad(ciclo, invernadero, datos, registroID, "pesos")
export const guardarRegistroCalidad = (ciclo, invernadero, datos, registroID, tipoRegistro) => {

    let docID = registroID || firebase.firestore().collection("ciclos").doc().collection("invernaderos_virtuales").doc().collection(`calidad_${tipoRegistro}`).doc().id
    return new Promise((resolve, reject) => {
        firebase.firestore().collection("ciclos").doc(ciclo).collection("invernaderos_virtuales")
            .doc(invernadero).collection(`calidad_${tipoRegistro}`).doc(docID).set(datos).then(result => {
                resolve(result)
            }).catch((err) => {
                reject(err);
            })
    })
}

export const obtenerRegistrosCalidadInicialDia = (ciclo, invernadero, dia) => obtenerRegistrosCalidad(ciclo, invernadero, dia, "inicial", segundosDia)
export const obtenerRegistrosCalidadFinalDia = (ciclo, invernadero, dia) => obtenerRegistrosCalidad(ciclo, invernadero, dia, "final", segundosDia)
export const obtenerRegistrosCalidadPesosDia = (ciclo, invernadero, dia) => obtenerRegistrosCalidad(ciclo, invernadero, dia, "pesos", segundosDia)
export const obtenerRegistrosCalidadInicialSemana = (ciclo, invernadero, semana) => obtenerRegistrosCalidad(ciclo, invernadero, semana, "inicial", segundosSemana)
export const obtenerRegistrosCalidadFinalSemana = (ciclo, invernadero, semana) => obtenerRegistrosCalidad(ciclo, invernadero, semana, "final", segundosSemana)
export const obtenerRegistrosCalidadPesosSemana = (ciclo, invernadero, semana) => obtenerRegistrosCalidad(ciclo, invernadero, semana, "pesos", segundosSemana)
export const obtenerRegistrosCalidadPesosCiclo = (cicloObj, invernadero, semana) =>
    obtenerRegistrosCalidad(cicloObj.id, invernadero, cicloObj.semana_inicio, "pesos", ((semana - cicloObj.semana_inicio) + segundosSemana))

export const obtenerRegistrosCalidad = (ciclo, invernadero, momento, tipoRegistro, periodoTiempo) => {
    return new Promise((resolve, reject) => {
        firebase.firestore().collection("ciclos").doc(ciclo)
            .collection("invernaderos_virtuales").doc(invernadero)
            .collection(`calidad_${tipoRegistro}`).where("momento", ">=", momento)
            .where("momento", "<=", (momento + periodoTiempo)).get()
            .then((result) => {
                registrarInvocaciones(result.size)
                let registros = result.docs.map(el => ({ id: el.id, ...el.data() }))
                resolve(registros)
            }).catch((err) => {
                reject(err);
            });
    })
}
/*
export const obtenerRegistrosCalidadInicialSemanaExterno = (ciclo, invernadero, semana, proveedor) => obtenerRegistrosCalidad(ciclo, invernadero, semana, "inicial", segundosSemana, proveedor)
export const obtenerRegistrosCalidadFinalSemanaExterno = (ciclo, invernadero, semana, proveedor) => obtenerRegistrosCalidad(ciclo, invernadero, semana, "final", segundosSemana, proveedor)
export const obtenerRegistrosCalidadPesosSemanaExterno = (ciclo, invernadero, semana, proveedor) => obtenerRegistrosCalidad(ciclo, invernadero, semana, "pesos", segundosSemana, proveedor)
export const obtenerRegistrosCalidadProveedorExterno = (ciclo, invernadero, momento, tipoRegistro, periodoTiempo) => {
    return new Promise((resolve, reject) => {
        firebase.firestore().collection("ciclos").doc(ciclo)
            .collection("invernaderos_virtuales").doc(invernadero)
            .collection(`calidad_${tipoRegistro}`).where("momento", ">=", momento)
            .where("momento", "<=", (momento + periodoTiempo)).where("").get()
            .then((result) => {
                registrarInvocaciones(result.size)
                let registros = result.docs.map(el => ({ id: el.id, ...el.data() }))
                resolve(registros)
            }).catch((err) => {
                reject(err);
            });
    })
}
*/
export const obtenerRegistroInicial = (ciclo, invernadero, registroID) => obtenerRegistro(ciclo, invernadero, registroID, "inicial")
export const obtenerRegistroFinal = (ciclo, invernadero, registroID) => obtenerRegistro(ciclo, invernadero, registroID, "final")
export const obtenerRegistroPesos = (ciclo, invernadero, registroID) => obtenerRegistro(ciclo, invernadero, registroID, "pesos")
export const obtenerRegistro = (ciclo, invernadero, registroID, tipoRegistro) => {
    return new Promise((resolve, reject) => {
        firebase.firestore().collection("ciclos").doc(ciclo).collection("invernaderos_virtuales")
            .doc(invernadero).collection(`calidad_${tipoRegistro}`).doc(registroID).get().then((result) => {
                registrarInvocaciones(result.size)
                return result.exists ? resolve({ id: result.id, ...result.data() }) : resolve(undefined)
            }).catch((err) => {
                reject(err);
            });
    })
}

export const obtenerPesoCaja = (ciclo, invernadero, semana) => {

    return new Promise(async (resolve, reject) => {
        try {
            semana = obtenerInicioSemanaDia(semana).unix();
            let registrosB = await obtenerRegistrosCalidadInicialSemana(ciclo, invernadero, semana);
            let registros = await obtenerRegistrosCalidadPesosSemana(ciclo, invernadero, semana);
            let muestras = 0;
            let result = registrosB.reduce((accRegistro, currRegistro) => {
                muestras++;
                return accRegistro + parseFloat(currRegistro.peso_real_caja);
            }, 0)


            resolve(result / muestras);
        } catch (error) {
            reject(error);
        }

    })
}
export const obtenerPesoFruto = (ciclo, invernadero, semana, proveedor = false) => {
    return new Promise(async (resolve, reject) => {
        try {
            semana = obtenerInicioSemanaDia(semana).unix();
            let registros = await obtenerRegistrosCalidadInicialSemana(ciclo, invernadero, semana);
            if (proveedor) registros = registros.filter(el => el.proveedor === proveedor)
            let result = registros.reduce((acc, curr) => acc + parseFloat(curr.peso_promedio_fruto), 0)

            resolve(result / registros.length);
        } catch (error) {
            reject(error);
        }
    })
}
export const obtenerPesoFrutoFinal = (ciclo, invernadero, semana, proveedor = false) => {
    return new Promise(async (resolve, reject) => {
        try {
            semana = obtenerInicioSemanaDia(semana).unix();
            let registros = await obtenerRegistrosCalidadFinalSemana(ciclo, invernadero, semana);
            if (proveedor) registros = registros.filter(el => el.proveedor === proveedor)
            let result = registros.reduce((acc, curr) => acc + parseFloat(curr.peso_promedio_fruto), 0)

            resolve(result / registros.length);
        } catch (error) {
            reject(error);
        }
    })
}
export const obtenerDefectos = (ciclo, invernadero, semana, tipoCalidadInicial, proveedor) => {
    return new Promise(async (resolve, reject) => {
        try {
            semana = obtenerInicioSemanaDia(semana).unix();
            let reporte = await obtenerReporteDefectos(ciclo, invernadero, semana, tipoCalidadInicial, false, proveedor);

            let inicial = reporte["promediosInicial"];
            let keys = Object.keys(inicial);

            let total = 0;
            keys.forEach(k => {
                let data = inicial[k]["cociente"];
                if (data && !isNaN(data)) {
                    total += data;
                }
            })
            resolve(total);
        } catch (error) {
            reject(error);
        }

    })
}
export const obtenerDefectosFinal = (ciclo, invernadero, semana, tipoCalidadInicial, proveedor) => {
    return new Promise(async (resolve, reject) => {
        try {
            semana = obtenerInicioSemanaDia(semana).unix();
            let reporte = await obtenerReporteDefectos(ciclo, invernadero, semana, tipoCalidadInicial, false, proveedor);

            let final = reporte["promediosFinal"];
            let keys = Object.keys(final);

            let total = 0;
            keys.forEach(k => {
                let data = final[k]["cociente"];
                if (data && !isNaN(data)) {
                    total += data;
                }
            })
            resolve(total);
        } catch (error) {
            reject(error);
        }

    })
}
export const obtenerDefectosDistintos = async (ciclo, invernaderoId, semana) => {

    try {
        semana = obtenerInicioSemanaDia(semana).unix();
        let registros = await obtenerRegistrosCalidadInicialSemana(ciclo, invernaderoId, semana);
        let setDefectos = new Set();
        registros.forEach(registro => {
            registro["defectos"].forEach(defecto => {
                if (parseFloat(defecto["valor"]) > 0) {
                    setDefectos.add(defecto["defecto_ref"]);
                }
            })

        })
        return setDefectos;
    } catch (err) {
        throw err;
    }
}

export const obtenerResumenInicial = (ciclo, invernadero, semana, es_externo, proveedor) => {
    return new Promise(async (resolve, reject) => {
        try {
            let registros = await obtenerRegistrosCalidadInicialSemana(ciclo, invernadero, semana)
            if (es_externo && proveedor !== "todos") registros = registros.filter(el => el.proveedor === proveedor);
            registros = procesarResumenInicialYFinal(registros, semana);

            resolve(registros);
        } catch (err) {
            reject(err);
        }
    })
}
export const obtenerResumenFinal = (ciclo, invernadero, semana, es_externo = false, proveedor = "") => {
    return new Promise(async (resolve, reject) => {
        try {
            let registros = await obtenerRegistrosCalidadFinalSemana(ciclo, invernadero, semana)
            if (es_externo && proveedor !== "todos") registros = registros.filter(el => el.proveedor === proveedor);
            let presentaciones = await obtenerPresentaciones();
            registros = procesarResumenFinal(registros, semana, presentaciones);

            resolve(registros);
        } catch (err) {
            reject(err);
        }
    })
}
export const obtenerResumenPesos = (ciclo, invernadero, semana) => {
    return new Promise(async (resolve, reject) => {
        try {
            let presentaciones = await getPresentaciones();
            let registros = await obtenerRegistrosCalidadPesosSemana(ciclo, invernadero, semana);
            let procesados = procesarResumenPesos(registros, presentaciones, semana)

            resolve(procesados);
        } catch (error) {
            reject(error);
        }
    })
}
export const obtenerResumenGeneralPesosDiarios = async (ciclo, invernaderoId, semana, presentaciones) => {
    try {
        const registros = await obtenerRegistrosCalidadPesosSemana(ciclo, invernaderoId, semana);
        const procesados = procesarResumenPesos(registros, presentaciones, semana);
        const comentarios = registros.map(({ momento, comentario }) => ({ momento, comentario }))
        return { procesados, comentarios };
    } catch (error) {
        console.log("ERROR obtenerResumenGeneralPesosDiarios - ", error)
    }
}

const procesarResumenInicialYFinal = (registros, semana) => {
    let dias = obtenerDiasDeSemana(semana);
    let hasDatos = false;
    let columns = dias.map(dia => {
        let registrosDelDia = registros.filter(item => (item.momento >= dia && item.momento < (dia + segundosDia) && item.peso_real_caja))
        let pesos = registrosDelDia.map(item => parseFloat(item.peso_real_caja));

        let muestras = registrosDelDia.length
        let sumaPesos = pesos.length == 0 ? 0 : pesos.reduce((acc, curr) => acc + curr)
        if (muestras !== 0 || sumaPesos !== 0) hasDatos = true;
        return { dia, muestras, pesoPromedio: muestras === 0 ? 0 : (sumaPesos / muestras) };
    })
    return hasDatos ? [columns] : [];
}
const procesarResumenFinal = (registros, semana, presentaciones) => {
    let presentacionesPresentes = registros.map(item => item.presentacion_ref)
    let set = new Set(presentacionesPresentes);
    presentacionesPresentes = Array.from(set);

    let rows = presentacionesPresentes.map(presentacion_id => {
        let nombrePresentacion = getObjetoPorID(presentaciones, presentacion_id).presentacion
        let columns = obtenerColumnasResumenFinal(registros, semana, presentacion_id)
        return { nombrePresentacion, columns };
    })

    return rows;
}
const obtenerColumnasResumenFinal = (registros, semana, presentacion_id) => {
    let dias = obtenerDiasDeSemana(semana);
    let columns = dias.map(dia => {
        let registrosDelDia = registros.filter(item => (item.presentacion_ref === presentacion_id && item.momento >= dia && item.momento < (dia + segundosDia)))
        let pesos = registrosDelDia.map(item => parseFloat(item.peso_real_caja));
        let muestras = registrosDelDia.length
        let sumaPesos = pesos.length == 0 ? 0 : pesos.reduce((acc, curr) => acc + curr)

        return { dia, muestras, pesoPromedio: muestras === 0 ? 0 : (sumaPesos / muestras) };
    })
    return columns
}

const procesarResumenPesos = (registros, presentaciones, semana) => {
    let presentacionesPresentes = registros.map(item => item.presentacion_ref)
    let set = new Set(presentacionesPresentes);
    presentacionesPresentes = Array.from(set);

    let rows = presentacionesPresentes.map(presentacion_id => {
        let nombrePresentacion = getObjetoPorID(presentaciones, presentacion_id).presentacion
        let columns = obtenerColumnasResumenPesos(registros, semana, presentacion_id)
        return { nombrePresentacion, columns };
    })

    return rows;
}
const obtenerColumnasResumenPesos = (registros, semana, presentacion_id) => {
    let dias = obtenerDiasDeSemana(semana);
    let columns = dias.map(dia => {
        let muestras = 0
        let sumaPesos = 0
        let registrosDelDia = registros.filter(item => (item.presentacion_ref === presentacion_id && item.momento >= dia && item.momento < (dia + segundosDia)))
        registrosDelDia.forEach(item => {
            muestras += item.pesos.length
            sumaPesos += item.pesos.reduce((acc, curr) => acc + parseFloat(curr.peso), 0)
        })

        return { dia, muestras, pesoPromedio: muestras === 0 ? 0 : (sumaPesos / muestras) };
    })
    return columns
}

export async function obtenerResumenCardCalidad(ciclo, invernadero, semana) {
    try {
        //let pesoCaja = await obtenerPesoCaja(ciclo, invernadero.id, semana)
        let pesoCaja = await obtenerPesoPromedioCajaSemana(ciclo, invernadero.id, semana)//This one??
        // 
        let pesoFruto = await obtenerPesoFruto(ciclo, invernadero.id, semana);
        // 
        let defectos = await obtenerDefectos(ciclo, invernadero.id, semana);
        let copia = { invernadero, pesoCaja, pesoFruto, defectos };
        return copia;
    } catch (error) {
        throw error;
    }
}
export const obtenerResumenCalidadEmpacadora = (cicloObj, empacadora, clientes, filtroPresentaciones = [], registros, preses, grupos) => {
    let semanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
    let result = semanas.map((semana, index) => obtenerResumenCalidadEmpacadoraSemana(semana.time, filtroPresentaciones, empacadora.id, registros[index], preses, grupos))
    return result;
}
const obtenerResumenCalidadEmpacadoraSemana = (semana, presentaciones, empacadoraId, registros, preses = [], grupos = []) => {
    let sumasPresentaciones = {}, sumasGrupos = {};
    preses.forEach(pres => { sumasPresentaciones[pres.id] = { id: pres.id, sumaKg: 0 } });
    grupos.forEach(grupo => { sumasGrupos[grupo.id] = { id: grupo.id, sumaKg: 0 } });

    let idsPresentaciones = presentaciones.map(el => el.id);
    registros = registros?.filter(el => idsPresentaciones.includes(el.presentacion_ref) && el.empacadora_ref === empacadoraId) || [];
    let altos = 0, normales = 0, bajos = 0, sumaKg = 0, sumaKgPorPres = 0;
    registros.forEach(registro => {
        let presentacion = presentaciones.find(el => el.id === registro.presentacion_ref);
        if (presentacion) {
            let peso_maximo = parseFloat(presentacion.peso_maximo);
            let peso_minimo = parseFloat(presentacion.peso_minimo);
            registro.pesos.forEach(peso => {
                let parsed = parseFloat(peso.peso);
                if (!isNaN(peso_maximo) && parsed > peso_maximo) { altos++; }
                else if (!isNaN(peso_minimo) && parsed < peso_minimo) { bajos++; }
                else normales++;
                sumaKg += isNaN(parsed) ? 0 : parsed;

                const grupoFound = grupos?.find(grupo => grupo.presentaciones_refs?.includes(presentacion.id));
                if (grupoFound?.id) { sumasGrupos[grupoFound.id].sumaKg += isNaN(parsed) ? 0 : parsed; }
                sumasPresentaciones[presentacion?.id].sumaKg += isNaN(parsed) ? 0 : parsed;
                
            }) 
        }
    })
    let muestras = altos + normales + bajos
    return { altos, normales, bajos, muestras, time: semana, sumaKg, sumasPresentaciones, sumasGrupos }

}
export const obtenerRegistrosCalidadPesosSemanaTodosInvernaderos = async (cicloId, semana, invs = undefined) => {
    let invernaderos = invs || await obtenerInvernaderosVirtualesActivos(cicloId);
    let promises = invernaderos.map(el => obtenerRegistrosCalidadPesosSemana(cicloId, el.id, semana))
    let result = await Promise.all(promises);
    return result.flat()
}

export const obtenerPresentacionesPresentesPesosCalidad = async (cicloId, invernaderoId) => {
    let registros = await obtenerRegistrosPesosCiclo(cicloId, invernaderoId);
    let presentaciones = new Set();
    registros.forEach(registro => {
        presentaciones.add(registro.presentacion_ref);
    })
    return Array.from(presentaciones);

}
export const obtenerRegistrosPesosCiclo = (ciclo, invernadero) => {
    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
        db.collection("ciclos").doc(ciclo)
            .collection("invernaderos_virtuales").doc(invernadero)
            .collection("calidad_pesos").get()
            .then((result) => {
                registrarInvocaciones(result.size)
                let rows = [];
                result.forEach(item => {
                    let obj = item.data();
                    obj.id = item.id;
                    rows.push(obj)
                })
                resolve(rows);
            }).catch((err) => {
                reject(err);
            });
    })


}
export const obtenerDistribucionPresentacionesCiclo = async (cicloId, presentaciones, clientesSeleccionadosIDs, invernaderos, ordenesCiclo) => {
    let ordenes = ordenesCiclo || await obtenerOrdenesCiclo(cicloId);
    let result = {};
    presentaciones.forEach(presentacion => {
        result[presentacion["id"]] = 0;
    })

    //Para filtrar cliente de los ordenes de venta
    //const ordenesFiltrados = ordenes.filter(orden => clientesSeleccionadosIDs.includes(orden.cliente_ref));
    ordenes.forEach(orden => {
        orden.items.forEach(item => {
            //Para filtrar cliente de los invernaderos de origin de cada item de los ordenes de venta
            const invernaderoDelItem = getObjetoPorID(invernaderos, item.invernadero_ref);
            const clientedDelInvernaderoDelItemID = invernaderoDelItem.cliente_ref;
            const tieneCliente = clientesSeleccionadosIDs.includes(clientedDelInvernaderoDelItemID);/**/

            if (!isNaN(result[item.presentacion_ref]) && tieneCliente) {
                let presentacion = presentaciones.find(el => el.id === item.presentacion_ref);
                let kilos = parseFloat(presentacion.peso_neto_caja) * parseFloat(item.cajas_orden);
                result[item.presentacion_ref] += kilos
            }
        })

    })

    return result;

}

export const obtenerDistribucionGruposPresentacionesCiclo = async (cicloId, gruposPresentaciones, clientesSeleccionadosIDs, invernaderos, ordenesCiclo, presentaciones) => {
    const ordenes = ordenesCiclo || await obtenerOrdenesCiclo(cicloId);
    const result = {};
    gruposPresentaciones.forEach(grupo => {
        result[grupo["id"]] = 0;
    })

    //Para filtrar cliente de los ordenes de venta
    //const ordenesFiltrados = ordenes.filter(orden => clientesSeleccionadosIDs.includes(orden.cliente_ref));
    ordenes.forEach(orden => {
        orden.items.forEach(item => {
            //Para filtrar cliente de los invernaderos de origin de cada item de los ordenes de venta
            const invernaderoDelItem = getObjetoPorID(invernaderos, item.invernadero_ref);
            const clientedDelInvernaderoDelItemID = invernaderoDelItem.cliente_ref;
            const tieneCliente = clientesSeleccionadosIDs.includes(clientedDelInvernaderoDelItemID);/**/

            const grupo = gruposPresentaciones.find(el => el.presentaciones_refs.includes(item.presentacion_ref));
            if (grupo && tieneCliente) {
                const presentacion = presentaciones.find(el => el.id === item.presentacion_ref);
                const kilos = parseFloat(presentacion.peso_neto_caja) * parseFloat(item.cajas_orden);
                result[grupo.id] += kilos
            }
        })

    })

    return result;
}

////////////////////////////REPORTE PESOS DIARIOS////////////////////////////

export const procesarAcumulado = (empacadoras, acumulado, presentaciones) => {
    let totalManual = { nombre: "TOTAL MANUAL", empacadora: "TOTAL MANUAL" };
    let totalAutomatico = { nombre: "AUTOMÁTICA", empacadora: "AUTOMÁTICA" };
    let superTotal = { nombre: "TOTAL", empacadora: "TOTAL" };
    presentaciones.forEach(presentacion => {
        totalManual[presentacion.id] = {
            altos: 0, bajos: 0, normales: 0, muestras: 0,
            sumaPesoAltos: 0, sumaPesoBajos: 0, sumaPesoNormales: 0,
        }
        totalAutomatico[presentacion.id] = {
            altos: 0, bajos: 0, normales: 0, muestras: 0,
            sumaPesoAltos: 0, sumaPesoBajos: 0, sumaPesoNormales: 0,
        }
        superTotal[presentacion.id] = {
            altos: 0, bajos: 0, normales: 0, muestras: 0,
            sumaPesoAltos: 0, sumaPesoBajos: 0, sumaPesoNormales: 0,
        }
    })
    let result = empacadoras.map(empacadora => {
        let obj = {}
        presentaciones.forEach(presentacion => {
            obj[presentacion.id] = {
                altos: 0, bajos: 0, normales: 0, muestras: 0,
                sumaPesoAltos: 0, sumaPesoBajos: 0, sumaPesoNormales: 0,
            };
            obj.empacadora = empacadora.nombre;
        })

        acumulado.forEach(currSemana => {
            let desviaciones = currSemana.desviacionesEmpacadoras.find(el => el.nombre === empacadora.nombre);
            if (desviaciones) {
                presentaciones.forEach(presentacion => {
                    let desviacionesPresentacion = desviaciones[presentacion.id];
                    if (desviacionesPresentacion) {
                        obj[presentacion.id].altos += desviacionesPresentacion.altos;
                        obj[presentacion.id].normales += desviacionesPresentacion.normales;
                        obj[presentacion.id].bajos += desviacionesPresentacion.bajos;
                        obj[presentacion.id].muestras += desviacionesPresentacion.muestras;
                        obj[presentacion.id].sumaPesoAltos += desviacionesPresentacion.sumaPesoAltos;
                        obj[presentacion.id].sumaPesoBajos += desviacionesPresentacion.sumaPesoBajos;
                        obj[presentacion.id].sumaPesoNormales += desviacionesPresentacion.sumaPesoNormales;

                        totalManual[presentacion.id].altos += desviacionesPresentacion.altos;
                        totalManual[presentacion.id].normales += desviacionesPresentacion.normales;
                        totalManual[presentacion.id].bajos += desviacionesPresentacion.bajos;
                        totalManual[presentacion.id].muestras += desviacionesPresentacion.muestras;
                        totalManual[presentacion.id].sumaPesoAltos += desviacionesPresentacion.sumaPesoAltos;
                        totalManual[presentacion.id].sumaPesoBajos += desviacionesPresentacion.sumaPesoBajos;
                        totalManual[presentacion.id].sumaPesoNormales += desviacionesPresentacion.sumaPesoNormales;

                        superTotal[presentacion.id].altos += desviacionesPresentacion.altos;
                        superTotal[presentacion.id].normales += desviacionesPresentacion.normales;
                        superTotal[presentacion.id].bajos += desviacionesPresentacion.bajos;
                        superTotal[presentacion.id].muestras += desviacionesPresentacion.muestras;
                        superTotal[presentacion.id].sumaPesoAltos += desviacionesPresentacion.sumaPesoAltos;
                        superTotal[presentacion.id].sumaPesoBajos += desviacionesPresentacion.sumaPesoBajos;
                        superTotal[presentacion.id].sumaPesoNormales += desviacionesPresentacion.sumaPesoNormales;
                    }
                })
            }
        })
        return obj
    })

    acumulado.forEach(currSemana => {
        let desviaciones = currSemana.desviacionesAuto;
        if (desviaciones) {
            presentaciones.forEach(presentacion => {
                let desviacionesPresentacion = desviaciones[presentacion.id];
                if (desviacionesPresentacion) {
                    totalAutomatico[presentacion.id].altos += desviacionesPresentacion.altos;
                    totalAutomatico[presentacion.id].normales += desviacionesPresentacion.normales;
                    totalAutomatico[presentacion.id].bajos += desviacionesPresentacion.bajos;
                    totalAutomatico[presentacion.id].muestras += desviacionesPresentacion.muestras;
                    totalAutomatico[presentacion.id].sumaPesoAltos += desviacionesPresentacion.sumaPesoAltos;
                    totalAutomatico[presentacion.id].sumaPesoBajos += desviacionesPresentacion.sumaPesoBajos;
                    totalAutomatico[presentacion.id].sumaPesoNormales += desviacionesPresentacion.sumaPesoNormales;

                    superTotal[presentacion.id].altos += desviacionesPresentacion.altos;
                    superTotal[presentacion.id].normales += desviacionesPresentacion.normales;
                    superTotal[presentacion.id].bajos += desviacionesPresentacion.bajos;
                    superTotal[presentacion.id].muestras += desviacionesPresentacion.muestras;
                    superTotal[presentacion.id].sumaPesoAltos += desviacionesPresentacion.sumaPesoAltos;
                    superTotal[presentacion.id].sumaPesoBajos += desviacionesPresentacion.sumaPesoBajos;
                    superTotal[presentacion.id].sumaPesoNormales += desviacionesPresentacion.sumaPesoNormales;
                }
            })

        }
    })

    result.push(superTotal);
    result.push(totalAutomatico);
    result.push(totalManual);
    return result;
}

export const procesarReporte = (result, presentaciones) => {
    const presentacionIds = Object.keys(result.desviacionesTotal);
    result.desviacionesTotal.nombre = "TOTAL";
    result.desviacionesAuto.nombre = "AUTOMÁTICA";
    result.desviacionesManual.nombre = "TOTAL MANUAL";

    let renglonesTablaPorEmpacadora = result.desviacionesEmpacadoras.concat([result.desviacionesManual, result.desviacionesAuto, result.desviacionesTotal])
        .filter((row) => {
            // conservar únicamente los renglones con registros, por defecto todos los renglones; cuentan con el key 'nombre'
            if (!row.nombre) return false;
            if (['TOTAL', 'AUTOMÁTICA', 'TOTAL MANUAL'].includes(row.nombre)) return true;
            return Object.keys(row).length > 1;
        });

    const listaPresentaciones = presentacionIds.map(presentacionRef => presentaciones.find(el => el.id === presentacionRef));
    ordenarArreglaDeObjetosPorLlave(listaPresentaciones, "presentacion");

    setGlobal({ presentacionesPesosDiariosOpciones: listaPresentaciones, presentacionesPesosDiariosFiltro: listaPresentaciones })//Para el filtro multiselect de presentaciones en ReporteCalidad.js
    return { rows: renglonesTablaPorEmpacadora, presentacionesPresentes: listaPresentaciones, comentarios: result.comentarios }
}
//////////////////////////////////////////////////////////////////////////////