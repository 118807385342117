import React from 'react';
import { useState, useEffect, useGlobal, setGlobal } from 'reactn';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from "primereact/inputtext";
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';

import { dateToUnixTimeAsNumber13, getNombrePorID, getMomentFormatted, getNombreUsuarioPorID, getObjetoPorID, DeepClone } from '../../../util/functions';
import { miliSegundosDia, numeroMaximo } from '../../../constants'

import { obtenerOrdenesMantenimientoAciclico, obtenerFiltrosMantenimientoTodos, getListaEmpresasString, isAdminRequisiciones, getListaEmpresasIDs, isAdminModulo, filtrarRequisicionesDeAuxiliarPorAdmins, RESPONSABLE_OPTIONS, guardarDatosRequisicion, guardarRequisicionAciclico, obtenerOrdenesMantenimientoBridge, getListaSitiosString, getListaSitiosIDs } from '../../../service/Requisiciones';
import { noHaSidoClausurado } from '../../../service/Ciclos';
import ContenedorHeader from '../../common/ContenedorHeader'
import ContenedorBotonesRow from '../../common/ContenedorBotonesRow';
import ContenedorTablaAdmin from '../../common/ContenedorTablaAdmin';
import ContenedorFiltro from '../../common/ContenedorFiltro'
import ContenedorFiltroBusqueda from '../../common/ContenedorFiltroBusqueda'
import SeccionFiltros from '../../common/SeccionFiltros'

import moment from "moment";
import 'moment/locale/es';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import ModalDetalles from './ModalDetalles';
import ContenedorPrincipal from '../../common/ContenedorPrincipal';
import ModalConfirmacion from '../../common/ModalConfirmacion';

moment.locale("es");


setGlobal({
    urgenciasFiltrosMant: [], estatusesFiltrosMant: [], urgenciasFiltrosMant: [], requerimientosFiltrosMant: [], ordenesRefsFiltrosMant: [], sitiosFiltrosMant: [],
    responsablesFiltrosMant: [], estatusesFiltrosMant: [], departamentosFiltrosMant: [], empresasFiltrosMant: [], datosCargadosMant: false, responsablesMant: []
})
const TablaMantenimientos = (props) => {
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [, setCargando] = useGlobal("cargando")
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [ciclo] = useGlobal("ciclo");
    const [cicloObj] = useGlobal("cicloObj");
    const [ciclos] = useGlobal("ciclos");
    const [usuario] = useGlobal("usuario");
    const [usuarios] = useGlobal("usuarios")
    const [ordenLineas, setOrdenLineas] = useState([])
    const [ordenLineasOriginales, setOrdenLineasOriginales] = useState([])
    const [datosCargados] = useGlobal("datosCargadosMant")
    const [locacionesHTF] = useGlobal("locacionesHTFMant")

    //opciones de filtros
    const [ordenesRefsOpciones] = useGlobal("ordenesRefsMant")
    const [urgenciasOpciones] = useGlobal("urgenciasMant")
    const [requerimientosOpciones] = useGlobal("requerimientosMant")
    const [estatusesOpciones] = useGlobal("estatusesMant")
    const [empresasOpciones] = useGlobal("empresas")
    const [departamentosOpciones] = useGlobal("departamentos")
    const [responsablesMant] = useGlobal("responsablesMant")
    const [responsablesMantOpciones] = useGlobal("responsablesMant")
    const [sitios] = useGlobal("sitios")

    //variables de filtros
    const [filtroGlobal, setFiltroGlobal] = useGlobal("filtroGlobalMantenimiento")
    const [fechaFiltroInicio, setFechaFiltroInicio] = useGlobal("fechaFiltroInicioMant")
    const [fechaFiltroFinal, setFechaFiltroFinal] = useGlobal("fechaFiltroFinalMant")
    const [urgenciasFiltros, setUrgenciasFiltros] = useGlobal("urgenciasFiltrosMant")
    const [requerimientosFiltros, setRequerimientosFiltros] = useGlobal("requerimientosFiltrosMant")
    const [estatusesFiltros, setEstatusesFiltros] = useGlobal("estatusesFiltrosMant")
    const [departamentosFiltros, setDepartamentosFiltros] = useGlobal("departamentosFiltrosMant")
    const [empresasFiltros, setEmpresasFiltros] = useGlobal("empresasFiltrosMant")
    const [ordenesRefsFiltros, setOrdenesRefsFiltros] = useGlobal("ordenesRefsFiltrosMant")
    const [sitiosFiltros, setSitiosFiltros] = useGlobal("sitiosFiltrosMant")
    const [responsablesFiltrosMant, setResponsablesFiltrosMant] = useGlobal("responsablesFiltrosMant")

    const [showDetallesModal, setShowDetallesModal] = useState(false)
    const [confirmationModalVisibility, setConfirmationModalVisibility] = useState(false)

    useEffect(() => {
        if (usuario.rol === "agente aduanal")
            props.history.push("/")
        setNombreModulo("Requisiciones de mantenimiento")
        setHabilitadoSelectorCiclo(true)
    }, [])
    useEffect(() => {
        setCargando(true)
        if (ciclo && !datosCargados)
            obtenerFiltrosMantenimientoTodos(usuario)
        else if (ciclo && datosCargados)
            obtenerDatosInicio()

    }, [ciclo, datosCargados])

    useEffect(() => {
        //Reconsile Filters
        let ordenLineasFiltrados = ordenLineasOriginales.filter(orden => checarCondicionesDeIncluirOrden(orden))
        setOrdenLineas(ordenLineasFiltrados)

    }, [ordenLineasOriginales, fechaFiltroInicio, fechaFiltroFinal, urgenciasFiltros, estatusesFiltros, requerimientosFiltros, empresasFiltros, departamentosFiltros, ordenesRefsFiltros, responsablesFiltrosMant, sitiosFiltros]);

    const checarCondicionesDeIncluirOrden = (orden) => {
        let selectedFechaInicio = !fechaFiltroInicio ? 1 : dateToUnixTimeAsNumber13(fechaFiltroInicio)
        let selectedFechaFinal = !fechaFiltroFinal ? numeroMaximo : (dateToUnixTimeAsNumber13(fechaFiltroFinal) + miliSegundosDia)

        return orden.fecha_creacion >= selectedFechaInicio && orden.fecha_creacion <= selectedFechaFinal
            && orden.empresaLista.some(empresa => empresasFiltros.includes(empresa))
            && urgenciasFiltros.includes(orden.allData.urgencia) && estatusesFiltros.includes(orden.allData.estatus)
            && requerimientosFiltros.includes(orden.allData.requerimiento) && departamentosFiltros.includes(orden.allData.departamento)
            && ordenesRefsFiltros.includes(orden.allData.orden_ref)
            && responsablesFiltrosMant.some((res) => res.id === orden.responsable || (res.id === "sin" && !orden.responsable))
            && orden.sitioLista.some(sitio => {
                const tieneSitio = sitiosFiltros.includes(sitio);
                const noHaySitio = (!sitio && sitiosFiltros.includes("Sin asignar"))
                const esAgroSin = (!sitio && (orden.empresaLista.includes("NdGaet757tbBgU5o0YpE") || orden.empresaLista.includes("ghFyLI8MQcidygXbTpuG")) && sitiosFiltros.includes("SCkIi2SnCvdAw3Xm4PLi"))
                return tieneSitio || noHaySitio || esAgroSin;
            });
    }

    const obtenerDatosInicio = async () => {
        try {
            let ordenes = await obtenerOrdenesMantenimientoBridge(cicloObj, usuario, isAdminModulo())
            ordenes = filtrarRequisicionesDeAuxiliarPorAdmins(ordenes);

            let lineasMant = ordenes.map((orden, index) => ({
                responsable: orden.responsable || "",
                orden_num: orden.orden_num,
                solicitante: usuarios ? getNombreUsuarioPorID(usuarios, orden.solicitante) : usuario.displayName,
                fecha_creacion: orden.fecha_creacion,
                ordenRef: getNombrePorID(ordenesRefsOpciones, orden.orden_ref),

                empresas: getListaEmpresasString(orden.items, empresasOpciones),
                sitios: getListaSitiosString(orden.items, sitios),

                estatus: getNombrePorID(estatusesOpciones, orden.estatus),
                requerimiento: getNombrePorID(requerimientosOpciones, orden.requerimiento),
                empresaLista: getListaEmpresasIDs(orden),
                sitioLista: getListaSitiosIDs(orden),

                allData: { ...orden, index },
            }))

            setOrdenLineasOriginales(lineasMant)
        } catch (error) {
            console.log(error)
        }
        setCargando(false)
    }
    const verDetalles = (event, rowData) => {
        event.stopPropagation()
        setShowDetallesModal(rowData.allData)
    }

    const buttonBody = (rowData) => <Button label="Ver items" onClick={(ev) => { verDetalles(ev, rowData) }} />;
    const responsableBody = (rowData) => {
        return <div onClick={e => e.stopPropagation()}>
            <Dropdown disabled={!isAdminRequisiciones(usuario.rol, "Mantenimiento")} value={getObjetoPorID(responsablesMantOpciones, rowData.responsable)} onChange={(e) => setConfirmationModalVisibility({ responsable: e.target.value, allData: rowData.allData })} className='position-overlap' options={responsablesMantOpciones} placeholder="Selecciona un responsable" optionLabel="nombre" dataKey="id" />
        </div>
    }
    const irOrdenMant = (event) => { props.history.push({ pathname: `/requisiciones/mantenimiento/modificar/${event.data.allData.id}`, data: event.data.allData }) }
    const fechaFormattedBody = (rowData) => <label>{getMomentFormatted(rowData.fecha_creacion, "DD/MM/YYYY")}</label>
    const obtenerFooter = () => {
        return <ColumnGroup>
            <Row>
                <Column colSpan={3} />
                <Column className="text-align-left" footer={<span>Total: {ordenLineas?.length || "0"}<span className="label-unidad">requisiciones</span> </span>} colSpan={3} />)
                <Column colSpan={1} />
            </Row>
        </ColumnGroup>
    }

    const getSitiosFiltrosOpciones = () => {
        let sinAsignar = { nombre: "Sin asignar", id: "Sin asignar" }
        if (!sitios) { return [sinAsignar]; }
        let sitiosCopy = [...sitios]
        sitiosCopy[0].nombre = "Todos (Atongo)";
        return [sinAsignar, ...sitiosCopy]
    }

    const confirmationModalData = ({ responsable, allData }) => ({
        mensaje: `Se asignara esta requisición a '${responsable?.nombre}' ¿Está seguro de esta asignación?`,
        titulo: "Asignación de orden de requisición",
        acceptLabel: "Asignar",
        cancelLabel: "Cancelar",
        aceptar: () => { asignarResponsable(responsable, allData); },
        cancelar: () => { setConfirmationModalVisibility(false) }
    })
    const asignarResponsable = async (responsable, allData) => {
        actualizarRequisiciones(responsable, allData.index)
        setCargando(true)
        await guardarRequisicionAciclico("mantenimiento", allData.id, { responsable: responsable.id })
        setCargando(false)
    }
    const actualizarRequisiciones = (responsable, index) => {
        const ordenLineasCopia = DeepClone(ordenLineasOriginales);
        ordenLineasCopia[index].responsable = responsable.id;
        setOrdenLineasOriginales(ordenLineasCopia);
    }

    return (
        <ContenedorPrincipal>
            <ContenedorHeader col="p-col p-col-auto" titulo="Requisiciones de mantenimiento" iconos="mtto-icon" />

            <ContenedorBotonesRow>
                {noHaSidoClausurado(ciclo, ciclos) &&
                    <Button label="Nueva requisición de mantenimiento" onClick={() => props.history.push('/requisiciones/mantenimiento/crear')} />}
                {isAdminRequisiciones(usuario.rol, "Mantenimiento") &&
                    <Button className="reactbtn-icon" onClick={() => props.history.push('/requisiciones/grafica_mantenimiento')} label="Gráfica de mantenimiento">
                        <FontAwesomeIcon icon={faChartBar} />
                    </Button>}
            </ContenedorBotonesRow>

            <SeccionFiltros >
                <ContenedorFiltroBusqueda>
                    <FontAwesomeIcon icon={faSearch} />
                    <InputText type="search" value={filtroGlobal} onInput={(e) => setFiltroGlobal(e.target.value)} placeholder="Buscar" />
                </ContenedorFiltroBusqueda>

                <ContenedorFiltro col="2" label="Fecha Inicio">
                    <Calendar id="fechaInicio" readOnlyInput={true} dateFormat="dd-mm-yy" //locale={ CALENDAR_LOCALE_ES_PRIME_TRADUCTION }
                        value={fechaFiltroInicio} monthNavigator={true} yearNavigator={true} yearRange="1900:2030"
                        showButtonBar={true} onClearButtonClick={() => { setFechaFiltroInicio("") }}
                        onChange={e => setFechaFiltroInicio(e.value)} />
                </ContenedorFiltro>
                <ContenedorFiltro col="2" label="Fecha Final">
                    <Calendar id="fechaFinal" readOnlyInput={true} dateFormat="dd-mm-yy" //locale={ CALENDAR_LOCALE_ES_PRIME_TRADUCTION }
                        value={fechaFiltroFinal} monthNavigator={true} yearNavigator={true} yearRange="1900:2030"
                        showButtonBar={true} onClearButtonClick={() => { setFechaFiltroFinal("") }}
                        onChange={e => setFechaFiltroFinal(e.value)} />
                </ContenedorFiltro>

                <ContenedorFiltro col="2" label="Estatus">
                    <MultiSelect id="estatusesFiltros" optionLabel="nombre" optionValue="id"
                        filter={false} selectedItemsLabel={`${estatusesFiltros.length} estatuses seleccionados`}
                        options={estatusesOpciones} value={estatusesFiltros}
                        onChange={(e) => setEstatusesFiltros(e.value)} />
                </ContenedorFiltro>
                <ContenedorFiltro col="2" label="Urgencia">
                    <MultiSelect id="urgenciasFiltros" optionLabel="nombre" optionValue="id"
                        filter={false} selectedItemsLabel={`${urgenciasFiltros.length} urgencias seleccionadas`}
                        options={urgenciasOpciones} value={urgenciasFiltros}
                        onChange={(e) => setUrgenciasFiltros(e.value)} />
                </ContenedorFiltro>
                <ContenedorFiltro col="2" label="requerimientos">
                    <MultiSelect id="requerimientosFiltros" optionLabel="nombre" optionValue="id"
                        filter={false} selectedItemsLabel={`${requerimientosFiltros.length} requerimientos seleccionados`}
                        options={requerimientosOpciones} value={requerimientosFiltros}
                        onChange={(e) => setRequerimientosFiltros(e.value)} />
                </ContenedorFiltro>
                <ContenedorFiltro col="2" label="Empresa">
                    <MultiSelect id="empresasFiltros" optionLabel="nombre" optionValue="id"
                        filter={false} selectedItemsLabel={`${empresasFiltros.length} empresas seleccionadas`}
                        options={empresasOpciones} value={empresasFiltros}
                        onChange={(e) => setEmpresasFiltros(e.value)} />
                </ContenedorFiltro>

                <ContenedorFiltro col="2" label="Departementos">
                    <MultiSelect id="departementos" optionLabel="nombre" optionValue="id"
                        filter={false} selectedItemsLabel={`${departamentosFiltros.length} departamentos seleccionados`}
                        options={departamentosOpciones} value={departamentosFiltros}
                        onChange={(e) => setDepartamentosFiltros(e.value)} />
                </ContenedorFiltro>
                <ContenedorFiltro col="2" label="Ordenes de referencias">
                    <MultiSelect id="ordenesRefs" optionLabel="nombre" optionValue="id"
                        filter={false} selectedItemsLabel={`${ordenesRefsFiltros.length} referencias seleccionados`}
                        options={ordenesRefsOpciones} value={ordenesRefsFiltros}
                        onChange={(e) => setOrdenesRefsFiltros(e.value)} />
                </ContenedorFiltro>
                <ContenedorFiltro col="2" label="Responsables">
                    <MultiSelect id="responsables" optionLabel="nombre" dataKey="id"
                        filter={false} selectedItemsLabel={`${responsablesFiltrosMant.length} responsables seleccionados`}
                        options={[{ nombre: "Sin responsable", id: "sin" }, ...responsablesMantOpciones]} value={responsablesFiltrosMant}
                        onChange={(e) => setResponsablesFiltrosMant(e.value)} />
                </ContenedorFiltro>
                <ContenedorFiltro col="2" label="Sitio">
                    <MultiSelect id="sitio" optionLabel="nombre" optionValue="id"
                        filter={false} selectedItemsLabel={`${sitiosFiltros.length} sitios seleccionados`}
                        options={getSitiosFiltrosOpciones()} value={sitiosFiltros}
                        onChange={(e) => setSitiosFiltros(e.value)} />
                </ContenedorFiltro>
            </SeccionFiltros>

            <ContenedorTablaAdmin>
                <DataTable selectionMode="single" value={ordenLineas} onRowClick={irOrdenMant} footerColumnGroup={obtenerFooter()}
                    globalFilter={filtroGlobal} emptyMessage="No hay resultados" sortable={true} className="table-footer-no-border__total">
                    <Column field="fecha_creacion" header="Fecha creación" sortable={true} body={fechaFormattedBody} />
                    <Column field="orden_num" header="REQUISICIÓN #" sortable={true} />
                    <Column field="ordenRef" header="Orden de Referencia" sortable={true} />
                    <Column field="solicitante" header="Nombre Solicitante" sortable={true} />
                    <Column field="empresas" header="Empresa(s)" sortable={true} />
                    <Column field="estatus" header="Estatus" sortable={true} />
                    <Column field="requerimiento" header="Requerimiento" sortable={true} />
                    <Column field="mas" header="Detalles" sortable={true} body={buttonBody} />
                    <Column field="responsable" className="cell-overflow" header="Responsable" sortable={true} body={responsableBody} />
                </DataTable>
            </ContenedorTablaAdmin>

            <ModalDetalles hide={() => setShowDetallesModal(false)} detalles={showDetallesModal} visible={showDetallesModal}></ModalDetalles>
            <ModalConfirmacion onHide={() => setConfirmationModalVisibility(false)} show={confirmationModalVisibility} data={confirmationModalData(confirmationModalVisibility)} />
        </ContenedorPrincipal>
    );
}
export default TablaMantenimientos //221