//react/reactn
import React, { useGlobal } from 'reactn';
//Funciones internas
import { getNombreCompacto } from '../../../../service/Ciclos';
import { getTipoCalidadProductoNombre } from '../../../../service/diccionarios/ProductosVariedades.dicc';
//Componentes internos
import TablaProductos from './TablaProductos.mole';

const SeccionProductosTablas = (props) => {
    const [usuario] = useGlobal("usuario");
    const [cicloOBJ] = useGlobal("cicloObj");
    const [productosUnicos] = useGlobal("productosUnicos");
    const [productosMezclados] = useGlobal("productosMezclados");
    const [medleysGeneral] = useGlobal("medleysGeneral")

    const [globalFilter] = useGlobal("globalFilterProductosYVariedades")
    const [, setProductoMezclado] = useGlobal("productoMezclado");
    const tituloTablaMezclas = `Productos mezclados de ${getNombreCompacto(cicloOBJ)}`;

    const irProductoMezclado = (event) => {
        if (puedeEditar()) {
            setProductoMezclado(event.data);
            props.history.push({ pathname: "/admin/productos/consultar", });
        }
    }

    const procesarDatosProductos = (productos = []) => {
        const productosProcesados = productos.map(producto => {
            let packoutRespaldo = producto.mezcla ? medleysGeneral.packout_respaldo : producto.packout_respaldo;
            packoutRespaldo *= 100;

            const tipoCalidadInicial = getTipoCalidadProductoNombre(producto.tipo_calidad_inicial);
            const numVariedades = producto.variedades?.length || 0;

            return { ...producto, packoutRespaldo, tipoCalidadInicial, numVariedades }
        })
        return productosProcesados;
    }

    const puedeEditar = () => { return ["Administrador"].includes(usuario.rol); }

    return (props.visible ?
        <React.Fragment >
            <TablaProductos titulo="Productos únicos" value={procesarDatosProductos(productosUnicos)} colClass="col__label" mostrarUnicos={true}/>
            <TablaProductos titulo={tituloTablaMezclas} value={procesarDatosProductos(productosMezclados)} onRowClick={irProductoMezclado} globalFilter={globalFilter} />
        </React.Fragment>
        : null)
}
export default SeccionProductosTablas;