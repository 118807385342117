import React, { useEffect, useState, useGlobal } from 'reactn'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import ContenedorInput from '../../common/ContenedorInput';
import { getNombreFechaCompacto, obtenerIndiceDiaSemana, obtenerInicioDia } from '../../../service/fechas';
import { MultiSelect } from 'primereact/multiselect';
import { dateToUnixTimeAsNumber, DeepClone, getCalendarDate } from '../../../util/functions';
import { Calendar } from 'primereact/calendar';
import { copiarTareaAFecha, getAgentes, getFirestoreID, obtenerActividadesAgenteSemana, transferirTareaAFecha } from '../../../service/Mantenimiento';
import { miliSegundosDia } from '../../../constants';
import { SelectButton } from 'primereact/selectbutton';
import { Dropdown } from 'primereact/dropdown';

const opcionesMovimiento = [
    { label: "Copiar", value: "copiar" },
    { label: "Transferir", value: "transferir" },
]
export default function ModalCopiarTareas(props) {
    const [ciclo] = useGlobal("ciclo")
    const [usuario] = useGlobal("usuario")
    const [week] = useGlobal("week")
    const [, setCargando] = useGlobal("cargando")
    const [tareasACopiar, setTareasACopiar] = useState([])
    const [fechasACopiar, setFechasACopiar] = useState("")
    const [fechaTransferir, setFechaTransferir] = useState("")
    const [agente, setAgente] = useGlobal("agente");
    const [tipoMovimiento, setTipoMovimiento] = useState(opcionesMovimiento[0].value)
    const [agentes, setAgentes] = useGlobal("agentes");
    const [agenteAAsignar, setAgenteAAsignar] = useState()
    useEffect(() => {
        if (!agentes) { getDatosIniciales(); }
    }, [agentes])
    useEffect(() => {
        if (!props.show) { clear() }
        else if (agente) { setAgenteAAsignar(agente); }
    }, [props.show])
    useEffect(() => {
        setFechasACopiar([])
        setFechaTransferir("")
    }, [tipoMovimiento])

    const getDatosIniciales = async () => {
        try {
            setCargando(true);
            let agentesResp = await getAgentes()
            let promisesAgentes = agentesResp.map(agente => obtenerActividadesAgenteSemana(ciclo, week.time, agente));
            let agentes = await Promise.all(promisesAgentes);

            setAgentes(agentes);
        } catch (error) {
            console.log(error);
        }
        setCargando(false);
    }


    const clear = () => {
        setTareasACopiar([]);
        setFechasACopiar([])
        setAgenteAAsignar(null)
        setFechaTransferir("")
    }

    const handleGuardar = async () => {
        setCargando(true)
        try {
            const promises = [], agenteData = DeepClone(agente), agentesCopia = DeepClone(agentes), nuevasTareas = [];
            for (const indexT in tareasACopiar) {
                const { id, realizada, tareaParcial, tiempo_tomado, tiempo_tomado_timestamp, ...tarea } = tareasACopiar[indexT];//No queremos copiar datos de tareas completadas
                tarea = DeepClone(tarea);
                if (agenteAAsignar) { tarea.agente_ref = agenteAAsignar.id; }

                if (tipoCopiar()) {//COPIAR tareas
                    for (const indexF in fechasACopiar) {
                        const fecha = fechasACopiar[indexF], newTareaID = getFirestoreID()

                        tarea.dia = dateToUnixTimeAsNumber(fecha);
                        tarea.dia = obtenerInicioDia(tarea.dia)


                        promises.push(copiarTareaAFecha(ciclo, tarea, newTareaID))
                        if (agenteAAsignar && agenteAAsignar.id !== agente.id) {//Copiar a otro agente, empujar a su lista de tareas
                            const indexEditing = agentesCopia.findIndex(ag => ag.id === agenteAAsignar.id);
                            agentesCopia[indexEditing].tareas.push({ ...tarea, dia: tarea.dia * 1000, id: newTareaID });
                        }
                        else { nuevasTareas.push({ ...tarea, dia: tarea.dia * 1000, id: newTareaID }); }//Copiar a este mismo agente, empujar a su lista de tareas
                    }
                } else {//TRANSFERIR tareas
                    tarea.dia = dateToUnixTimeAsNumber(fechaTransferir);
                    tarea.dia = obtenerInicioDia(tarea.dia);
                    promises.push(transferirTareaAFecha(ciclo, tarea, id));
                    let indexEditing = agenteData.tareas.findIndex(tarea => tarea.id === id);
                    if (agenteAAsignar && agenteAAsignar.id !== agente.id) {//Transferir a otro agente, quitar de este agentes tareas
                        agenteData.tareas.splice(indexEditing, 1)
                    }
                    else { agenteData.tareas[indexEditing] = { ...tarea, dia: tarea.dia * 1000, id }; }//Cambiar dia de la tarea de este mismo agente


                }
            }
            //console.log("DATOS DE AGENTE ", { agenteData, agentesCopia })
            let tareas = [...agenteData.tareas, ...nuevasTareas]
            setAgente({ ...agenteData, tareas, empresas: agenteData.empresas })
            setAgentes(agentesCopia);

            props.hide();
            await Promise.all(promises)
        } catch (error) {
            console.log("Error copiando actividades: ", error)
        }
        setCargando(false)
    }

    const getTareas = () => {
        const filtrados = tipoCopiar()
            ? props.tareas?.filter(el => !el.is_emergencia && props.dia?.unixTime === el.dia)
            : props.tareas?.filter(el => !el.is_emergencia && props.dia?.unixTime === el.dia && (!el.tiempo_tomado && !el.realizada));

        return filtrados.map(tarea => {
            const actividad = props.actividadesMantenimiento?.find(actividad => actividad.id === tarea.actividad_ref) || {};
            return tarea.is_requisicion ? { ...actividad, ...tarea, nombre: tarea.nombreTarea, tiempo_estimado: tarea.tiempo_estimado } : { ...actividad, ...tarea };
        })
    }
    const tipoCopiar = () => tipoMovimiento === "copiar";

    const footer = <div>
        <Button type="button" className="btn btn-secondary" onClick={props.hide} label="Cancelar" />
        <Button type="button" className="btn btn-secondary" onClick={handleGuardar} label={`${tipoCopiar() ? "Copiar" : "Transferir"} y guardar`} />
    </div>

    return (
        <Dialog className="modal-35" header={`${tipoCopiar() ? "Copiar" : "Transferir"} actividades de ${getNombreFechaCompacto(props.dia?.unixTime)}`} footer={footer} visible={!!props.show} onHide={props.hide} >
            <ContenedorInput label="Tipo de movimiento" col={6}>
                <SelectButton value={tipoMovimiento} options={opcionesMovimiento} onChange={e => { if (e.value) setTipoMovimiento(e.value) }} />
            </ContenedorInput>

            <ContenedorInput label={"Agente a asignar"}>
                <Dropdown name="agente" optionLabel="displayName" dataKey="id" options={agentes} value={agenteAAsignar} onChange={e => setAgenteAAsignar(e.target.value)} />
            </ContenedorInput>

            <ContenedorInput label={`Actividades a ${tipoCopiar() ? "copiar" : "transferir"}`}>
                <MultiSelect name="Tareas" value={tareasACopiar} options={getTareas()} onChange={e => { setTareasACopiar(e.target.value) }} optionLabel="nombre" dataKey="id" placeholder="Seleccionar actividades" />
            </ContenedorInput>
            <ContenedorInput label={tipoCopiar() ? "Fecha(s) a copiar" : "Fecha a transferir"}>
                {tipoCopiar()
                    ? <Calendar key="transf-mult" minDate={getCalendarDate(Date.now())} selectionMode={"multiple"} value={fechasACopiar} onChange={e => { setFechasACopiar(e.target.value) }} dateFormat="dd/mm/yy" />
                    : <Calendar key="copia-sing" minDate={getCalendarDate(Date.now())} selectionMode={"single"} value={fechaTransferir} onChange={e => { setFechaTransferir(e.target.value) }} dateFormat="dd/mm/yy" />}
            </ContenedorInput>
        </Dialog>)
}