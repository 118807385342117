import React from "react"
import DropdownAgrupadoBase from "./DropdownAgrupadoBase.comp";
import icono from '../../../images/icons/external-icon.svg';
import { estamosEnProduccion } from "../../../constants";
import { getProductoYVariedadString } from "util/functions";

const DropdownInvernaderosHTG = (props) => {
    const getOptionLabel = (invernadero) => {
        if (!invernadero?.[props.dataKey]) return null;
        const invernaderoNombre = invernadero?.es_externo ? <img width="34" alt="icono" src={icono} /> : invernadero[props.optionLabel] || "";
        return (
            <div className="">
                <span className={`icon-general ${invernadero?.es_externo ? "external-icon" : ""} icon-inv-sm icon-id bor-${invernadero?.color} bg-${invernadero?.color} text-center`}>
                    {invernaderoNombre}
                </span>
            </div>);
    }
    const getOptionLabelConProducto = (invernadero) => {
        const nombreCliente = invernadero?.cliente?.nombre_comun ? ` - ${invernadero.cliente.nombre_comun} ` : "";
        if (!invernadero?.[props.dataKey]) return null;
        else if (invernadero?.es_externo) { return <label>{invernadero[props.optionLabel]}</label>; }
        else if (invernadero) { return <label>{invernadero[props.optionLabel]}  <label className="producto-text-small"> {nombreCliente} ({getProductoYVariedadString(invernadero)?.split(" - ")?.[1] || getProductoYVariedadString(invernadero)})</label></label>; }
        else return null
    }

    const bodyTemplate = (option) => {
        if (props.bodyTemplate) { return props.bodyTemplate; }
        else return estamosEnProduccion ? getOptionLabelConProducto(option) : getOptionLabelConProducto(option);
    }

    return (<DropdownAgrupadoBase {...props} getOptionLabel={bodyTemplate} value={props.value} />);
}
export default DropdownInvernaderosHTG;