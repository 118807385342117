import { getGlobal } from "reactn"
import { flexDate, obtenerDiasDeSemana, obtenerListaDeSemanas } from "./fechas"
import * as firebase from "firebase/app";
import "firebase/firestore";
import { obtener_empleados_invernadero } from "./RhService";
import {
    obtenerRegistroActividadesImportantesRealizadasEmpleadoSemana, obtenerReferenciaInvernaderoAsignadoEmpleado,
    obtenerRegistroActividadesSemana, obtenerActividadesRealizadasDesdeRegistroActividades
} from "./QueriesRH";
import { obtenerIndiceDiaSemana } from "./fechas";
import { obtenerInvernadero } from "./Invernaderos";
import { factorBono } from "../constants";
import { obtenerCiclo } from "./Ciclos";
import { obtenerActividadesImportantes, obtenerActividades } from "./actividades";
import { uploadStatistics } from './Estadisticas'
import { procesarColeccionFirestore } from "util/functions";

function registrarInvocaciones(count) {
    uploadStatistics(count)
}

export const nombreActividadCosecha = "Cosecha";
export function obtenerPresupuestoCosecha(ciclo, invernadero) {
    return new Promise((resolve, reject) => {
        const db = firebase.firestore();
        db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero).get().then(resp => {
            registrarInvocaciones(resp.size)
            if (!resp.exists) return 0;

            let inv = resp.data();
            db.collection("actividades").where("nombre", "==", nombreActividadCosecha).get().then(cosecha => {
                registrarInvocaciones(cosecha.size)

                let { bonos_por_unidad } = cosecha.docs[0].data();
                let detArea = inv.detalles_fisicos.find(el => el.detalle === "Area (m²)");
                let area = parseFloat(detArea["sur"]) + parseFloat(detArea["norte"]);

                const bonoCosecha = inv.mezcla ? bonos_por_unidad[getGlobal().medleysGeneral?.id] : bonos_por_unidad[inv.producto_ref];
                let presupuesto = parseFloat(bonoCosecha) * area;
                resolve(presupuesto);

            }).catch(err => {
                reject(err);
            })
        }).catch(err => {
            reject(err);
        })
    })
}
export function obtenerInfoNomina(fecha, invernadero, ciclo) {
    const cosechaBonos = [3, 3, 3, 3, 3, 3];
    return new Promise(async (resolve, reject) => {
        let dias = obtenerDiasDeSemana(fecha);
        obtenerActividadesRealizadasSemana(ciclo, invernadero, dias).then(values => {
            obtenerActividadCosechaDias(ciclo, invernadero, fecha).then(diasCosecha => {


                let totalCosecha = diasCosecha.reduce((acc, curr) => (acc + curr), 0)
                obtenerPresupuestoCosecha(ciclo, invernadero).then(presupuesto => {
                    obtenerIdCosecha().then(cosechaId => {

                        let actividades = [];

                        values.map(dia => {
                            if (dia) {
                                actividades.push(dia.actividades_planeadas);
                            }

                        })
                        actividades = actividades.flat();
                        let set = new Set(actividades);
                        actividades = Array.from(set).flat();

                        obtener_empleados_invernadero(ciclo, invernadero).then(empleados => {

                            let resultEmpleados = [];
                            if (!empleados.empty) {
                                resultEmpleados = obtenerBonosEmpleados(empleados, actividades, values, cosechaId, presupuesto, totalCosecha);
                            }
                            resolve([actividades, resultEmpleados])
                        }).catch(err => {
                            reject(err);
                        })
                    })

                }).catch(err => {
                    reject(err);
                })
            })

        });

    })
}
export const getInfoNomina = async (fecha, invernadero, ciclo, empleados) => {
    let dias = obtenerDiasDeSemana(fecha);

    let [values, diasCosecha, presupuesto, cosechaId] =
        await Promise.all([obtenerActividadesRealizadasSemana(ciclo, invernadero, dias),
        obtenerActividadCosechaDias(ciclo, invernadero, fecha),
        obtenerPresupuestoCosecha(ciclo, invernadero),
        obtenerIdCosecha()])

    let totalCosecha = diasCosecha.reduce((acc, curr) => (acc + curr), 0)

    let actividades = [];
    values.forEach(dia => {
        if (dia) { actividades.push(dia.actividades_planeadas); }
    })
    actividades = actividades.flat();
    let set = new Set(actividades);
    actividades = Array.from(set).flat();

    let resultEmpleados = [];
    if (!empleados.empty) {
        resultEmpleados = obtenerBonosEmpleados(empleados, actividades, values, cosechaId, presupuesto, totalCosecha);
    }
    return [actividades, resultEmpleados]
}
export const obtenerBonosEmpleados = (empleados, actividades, registrosActividades, cosechaId, presupuesto, totalCosecha) => {
    let resultEmpleados = [];
    empleados.forEach(empleado => {
        let obj = {}
        obj["falta"] = false;
        obj["ausencia"] = false;
        obj["extra_cosecha"] = 0;
        actividades.map(actividad => {
            obj[actividad] = 0;
        })
        registrosActividades.map(dia => {
            obj["empleado"] = empleado.empleado_ref;
            actividades.map(actividad => {
                if (dia) {
                    let indiceDia = obtenerIndiceDiaSemana(dia.dia);
                    let subRealizadas = dia.realizadas;
                    let actividadEmpleado = dia.realizadas.find(el => el.empleado_ref === empleado.empleado_ref);
                    if (actividadEmpleado) {
                        if (actividadEmpleado.tuvo_permiso || actividadEmpleado.tuvo_ausencia || actividadEmpleado.tuvo_vacaciones) {

                            obj["ausencia"] = true;
                            if (actividadEmpleado.tuvo_ausencia) {
                                obj["falta"] = true;
                            }
                        }
                        else {
                            if (actividadEmpleado.actividades[actividad]) {
                                if (actividad === cosechaId) {
                                    obj["extra_cosecha"] += (presupuesto / totalCosecha) * parseFloat(actividadEmpleado.actividades[actividad]);
                                    //obj["extra_cosecha"] += cosechaBonos[indiceDia]*parseFloat(actividadEmpleado.actividades[actividad]);
                                    obj[actividad] += parseFloat(actividadEmpleado.actividades[actividad]);
                                } else {
                                    obj[actividad] += parseFloat(actividadEmpleado.actividades[actividad]);
                                }

                            }
                        }
                    }
                }


            })

        })
        resultEmpleados.push(obj);
    })
    return resultEmpleados;
}

export function obtenerIncidenciasSemana(fecha, ciclo, invernadero) {


    return new Promise((resolve, reject) => {
        let dias = obtenerDiasDeSemana(fecha);

        let datos = {
            0: {
                permisos: 0,
                vacaciones: 0,
                ausencias: 0
            },
            1: {
                permisos: 0,
                vacaciones: 0,
                ausencias: 0
            },
            2: {
                permisos: 0,
                vacaciones: 0,
                ausencias: 0
            },
            3: {
                permisos: 0,
                vacaciones: 0,
                ausencias: 0
            },
            4: {
                permisos: 0,
                vacaciones: 0,
                ausencias: 0
            },
            5: {
                permisos: 0,
                vacaciones: 0,
                ausencias: 0
            }
        }
        let db = firebase.firestore();
        db.collection("ciclos").doc(ciclo).
            collection("invernaderos_virtuales").doc(invernadero).get().
            then(respInv => {
                registrarInvocaciones(respInv.size)

                let promises = [];
                for (let x = 0; x < dias.length; x++) {
                    let promise = new Promise((resolve, reject) => {
                        const timestamp = flexDate(dias[x])
                        db.doc(respInv.ref.path).collection("registro_actividades").where("dia", ">=", timestamp.inicio).where("dia", "<=", timestamp.fin).get().then(resp => {
                            registrarInvocaciones(resp.size)

                            if (resp.empty) {
                                resolve(null)
                            } else {
                                db.doc(resp.docs[0].ref.path).collection("actividades_realizadas").get().then(respAct => {
                                    registrarInvocaciones(respAct.size)

                                    return resolve(respAct)
                                });
                            }
                        }).catch(err => {
                            reject(err);
                        })
                    })

                    promises.push(promise);
                }
                Promise.all(promises).then(values => {

                    for (let x = 0; x < values.length; x++) {
                        if (values[x]) {

                            values[x].forEach(actividadDia => {


                                let data = actividadDia.data();
                                if (data.tuvo_ausencia) {
                                    datos[x].ausencias++;
                                }
                                if (data.tuvo_vacaciones) {
                                    datos[x].vacaciones++;
                                }
                                if (data.tuvo_permiso) {
                                    datos[x].permisos++;
                                }
                            })
                        }
                    }

                    resolve(datos);
                })
            }).catch(err => {
                reject(err);
            })
    })

}
export function obtenerActividadCosechaDias(ciclo, invernadero, semana) {
    const db = firebase.firestore();

    return new Promise((resolve, reject) => {
        let promises = [];
        let dias = obtenerDiasDeSemana(semana);
        db.collection("actividades").where("nombre", "==", nombreActividadCosecha).get().then(snapCosecha => {
            registrarInvocaciones(snapCosecha.size)

            if (snapCosecha.empty) {
                resolve([0, 0, 0, 0, 0, 0])
            } else {

                let cosechaId = snapCosecha.docs[0].id;
                dias.forEach(dia => {

                    promises.push(new Promise((resolve, reject) => {
                        let total = 0;
                        const timestamp = flexDate(dia)
                        db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero)
                            .collection("registro_actividades").where("dia", ">=", timestamp.inicio).where("dia", "<=", timestamp.fin).get()
                            .then(snapRegistro => {
                                registrarInvocaciones(snapRegistro.size)

                                if (!snapRegistro.empty) {
                                    let registroDoc = snapRegistro.docs[0];
                                    db.doc(registroDoc.ref.path).collection("actividades_realizadas").get().then(snapActividades => {
                                        registrarInvocaciones(snapActividades.size)

                                        snapActividades.forEach(actividadDoc => {
                                            let data = actividadDoc.data();
                                            if (data["actividades"][cosechaId]) {
                                                let cosecha = parseFloat(data["actividades"][cosechaId]);
                                                if (!isNaN(cosecha)) {
                                                    total += cosecha;
                                                }
                                            }
                                        })
                                        resolve(total);
                                    }).catch(err => {
                                        reject(err);
                                    })
                                } else {
                                    resolve(total);
                                }


                            }).catch(err => {
                                reject(err);
                            })
                    }))
                })
                Promise.all(promises).then(values => {
                    resolve(values)
                }).catch(err => {
                    reject(err);
                })
            }
        })

    })
}

export function obtenerIdCosecha() {
    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
        db.collection("actividades").where("nombre", "==", nombreActividadCosecha).get().then(resp => {
            registrarInvocaciones(resp.size)

            if (resp.empty) {
                resolve(null);
            }
            else {
                resolve(resp.docs[0].id);
            }
        }).catch(err => {
            reject(err);
        })
    })

}

const obtenerActividadesRealizadasSemana = (ciclo, invernadero, dias) => {
    const db = firebase.firestore();
    let promises = [];

    dias.map(dia => { 
        //obtener los dias
        promises.push(new Promise((resolve, reject) => {
            const timestamps = flexDate(dia)
           
            db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero).
                collection("registro_actividades").where("dia", ">=", timestamps.inicio).where("dia", "<=", timestamps.fin).get().then(resp => {
                    registrarInvocaciones(resp.size)

                    if (resp.empty) {
                        resolve(null);
                    } else {
                        resp.forEach(el => {
                            resp = el;
                        })
                        let result = resp.data();
                        result.id = resp.id;

                        //obtener actividades realizadas
                        db.doc(resp.ref.path).collection("actividades_realizadas").get().then(respRealizadas => {
                            registrarInvocaciones(respRealizadas.size)

                            if (respRealizadas.empty) {
                                resolve(null);
                            } else {
                                let realizadas = [];
                                respRealizadas.forEach(doc => {
                                    let data = doc.data();
                                    data.id = doc.id;
                                    realizadas.push(data);
                                });
                                result.realizadas = realizadas;
                                resolve(result);
                            }
                        })
                    }
                })
        }))
    })
    return Promise.all(promises)
}

export const obtenerActividadesImportantesEmpleadoCiclo = async (ciclo, empleado, actividadesImportantes, actividades) => {
    try {
        let refInvernaderoEmpleado = await obtenerReferenciaInvernaderoAsignadoEmpleado(ciclo.id, empleado.id);
        let invernadero = await obtenerInvernadero(ciclo.id, refInvernaderoEmpleado);
        let semanas = obtenerListaDeSemanas(ciclo.semana_inicio, ciclo.semana_cierre);
        let idActividadCosecha = obtenerIdCosecha();
        let promises = [];
        semanas.forEach(semana => {
            promises.push(obtenerActividadesImportantesEmpleadoCicloSemana(ciclo, semana, empleado, actividadesImportantes, invernadero, actividades));
        })
        let result = await Promise.all(promises);

        return result;
    } catch (error) {
        console.log("ERROR OAIEC");
        throw (error);
    }

}
export const obtenerDesglosePorcentajeActividadesImportantesCiclo = async (cicloId, invernadero) => {
    let ciclo = await obtenerCiclo(cicloId);
    let semanas = await obtenerListaDeSemanas(ciclo.semana_inicio, ciclo.semana_cierre);
    let promesas = semanas.map(semana => {
        return obtenerDesglosePorcentajeActividadesImportantesSemana(cicloId, invernadero, semana)
    })
    let resultados = await Promise.all(promesas);
    return resultados;
}

const obtenerDesglosePorcentajeActividadesImportantesSemana = async (cicloId, invernadero, semana) => {
    let dias = obtenerDiasDeSemana(semana.time);
    let actividadesRealizadas = await obtenerActividadesRealizadasSemana(cicloId, invernadero.id, dias);
    let actividadesImportantesSnaps = await obtenerActividadesImportantes();
    let actividadesImportantes = actividadesImportantesSnaps.docs.map(el => ({ ...el.data(), id: el.id }))
    let porcentajes = obtenerSumaDesglosadaActividades(invernadero, actividadesRealizadas, actividadesImportantes);
    // 
    // 
    // 
    return porcentajes;
}
const obtenerSumaDesglosadaActividades = (invernadero, actividadesRealizadas, actividadesImportantes) => {
    let resultado = actividadesImportantes.map(el => {
        let nombreActividad = el.nombre;
        let idActividad = el.id;
        let suma = 0;
        actividadesRealizadas.forEach(actividad => {
            if (actividad) {
                if (actividad.realizadas) {
                    actividad.realizadas.forEach(realizada => {
                        if (realizada["actividades"][idActividad]) {
                            let cantidad = parseFloat(realizada["actividades"][idActividad]);
                            if (!isNaN(cantidad)) {
                                suma += cantidad;
                            }
                        }
                    })

                }
            }

        })
        return { nombreActividad, idActividad, suma };
    })
    return resultado;
}


const obtenerActividadesImportantesEmpleadoCicloSemana = async (ciclo, semana, empleado, actividadesImportantes, invernadero, actividades) => {

    try {
        let ini = Date.now();
        let fin = 0;
        let dif = 0;
        let promises = [];
        promises.push(obtenerRegistroActividadesImportantesRealizadasEmpleadoSemana(ciclo.id, invernadero.id, semana, empleado.id, actividadesImportantes));
        promises.push(obtenerPresupuestoCosecha(ciclo.id, invernadero.id));
        promises.push(obtenerActividadCosechaSemana(ciclo.id, invernadero.id, semana.time));
        let [registros, presupuestoCosecha, cosechaTotal] = await Promise.all(promises);

        fin = Date.now();
        dif = fin - ini;


        fin = Date.now();
        dif = fin - ini;


        fin = Date.now();
        dif = fin - ini;

        // 
        // 
        let result = procesarRegistrosActividadesEmpleadoSemana(registros, actividadesImportantes, semana, presupuestoCosecha, cosechaTotal, invernadero, actividades);
        return result
    } catch (error) {
        console.log("ERROR CASI CASI");
        console.log(error);
        throw (error);
    }

}
export const procesarRegistrosActividadesEmpleadoSemana = (registros, actividadesImportantes, semana, presupuestoCosecha, cosechaTotal, invernadero, actividades) => {
    let result = {};
    let bonoSemana = 0;
    let tuvo_ausencia = false;
    actividades.forEach(actividad => {
        registros.forEach(registro => {
            if (registro["empty"]) {
                return;
            }
            if (!result[actividad.id]) {
                result[actividad.id] = 0
            }
            if (registro["tuvo_ausencia"]) {
                //
                tuvo_ausencia = true;
            }
            if (actividad.importante) {
                if (registro["actividades"][actividad.id]) {
                    bonoSemana += obtenerBonoDeActividad(actividad.id, registro["actividades"][actividad.id], actividades, invernadero, presupuestoCosecha, cosechaTotal)
                    result[actividad.id] += parseFloat(registro["actividades"][actividad.id]);
                }
            } else {
                if (registro["actividades"][actividad.id]) {
                    bonoSemana += obtenerBonoDeActividad(actividad.id, registro["actividades"][actividad.id], actividades, invernadero, presupuestoCosecha, cosechaTotal)
                }
            }
        })

    })
    result.semana = semana.time;
    result.bono = tuvo_ausencia ? bonoSemana : bonoSemana * factorBono;
    //  
    //  
    return result;
}
export async function obtenerActividadCosechaSemana(ciclo, invernadero, semana) {
    try {
        const db = firebase.firestore();
        let snapCosecha = await obtenerIdCosecha()
        if (snapCosecha.empty) {
            return 0
        } else {
            let cosechaId = snapCosecha;
            let total = 0;
            let snapRegistro = await obtenerRegistroActividadesSemana(ciclo, invernadero, semana)
            if (!snapRegistro.empty) {
                for (let x = 0; x < snapRegistro.docs.length; x++) {
                    let registroDoc = snapRegistro.docs[x];
                    let snapActividades = await db.doc(registroDoc.ref.path).collection("actividades_realizadas").get();
                    registrarInvocaciones(snapActividades.size)

                    snapActividades.forEach(actividadDoc => {
                        let data = actividadDoc.data();
                        if (data["actividades"][cosechaId]) {
                            let cosecha = parseFloat(data["actividades"][cosechaId]);
                            if (!isNaN(cosecha)) {
                                total += cosecha;
                            }
                        }
                    })
                }
                return total;
            } else {
                return 0;
            }

        }
    } catch (error) {
        throw (error);
    }
}

const obtenerBonoDeActividad = (idActividad, cantidad, actividades, invernadero, presupuestoCosecha, cosechaTotal) => {
    let actividad = actividades.find(el => { return el.id === idActividad });
    const variedadOMezclaRef = invernadero.mezcla ? getGlobal().medleysGeneral?.id : invernadero.variedad_ref;
    const metrosPorLinea = parseInt(invernadero?.detalles_fisicos?.find(el => el.detalle === "Metros por línea")?.norte || "1");

    if (actividad.nombre === nombreActividadCosecha) {
        return presupuestoCosecha * parseFloat(cantidad) / cosechaTotal;
    } else {
        if (actividad.unidades === "líneas") {
            return parseFloat(cantidad) * actividad["bonos_por_unidad"][variedadOMezclaRef] * metrosPorLinea;
        } else {
            return parseFloat(cantidad) * actividad["bonos_por_unidad"][variedadOMezclaRef];
        }
    }
}




export const obtenerResumenGraficaEmpleados = async (cicloObj, invernadero, empleados, listaSemanas) => {
    try {
        let promises = listaSemanas.map(semana => getInfoNomina(semana.time, invernadero, cicloObj.id, empleados));
        let infoNominaSemanas = await Promise.all(promises);
        return infoNominaSemanas;
    } catch (error) {
        console.log("ERROR ORGE");
        console.log(error);
        throw (error);
    }

}
export const obtenerBonosSemanalesCiclo = async (ciclo, invernadero, actividades, listaSemanas) => {
    let promesas = listaSemanas.map(semana => obtenerBonoTotalSemana(ciclo, invernadero, semana, actividades));
    let resultados = await Promise.all(promesas);
    return resultados;
}

const obtenerBonoTotalSemana = async (ciclo, invernadero, semana, actividades) => {

    try {
        //obtener bonos de cada actividad
        const promises = [];
        promises.push(obtenerPresupuestoCosecha(ciclo.id, invernadero.id));
        promises.push(obtenerActividadCosechaSemana(ciclo.id, invernadero.id, semana.time));
        promises.push(obtenerRegistroActividadesSemana(ciclo.id, invernadero.id, semana.time));
        let [presupuestoCosecha, totalCosecha, snapActividades] = await Promise.all(promises);

        //obtener todas actividades hechas
        let bonoTotal = 0;
        let registros = snapActividades.docs.map(snap => {
            let data = snap.data();
            data.id = snap.id;
            data.path = snap.ref.path;
            return data;
        })
        let dias = obtenerDiasDeSemana(semana.time);

        let promesasActividades = dias.map(dia => obtenerRegistrosActividadesDia(dia, registros))
        let registrosActividades = await Promise.all(promesasActividades);
        let faltasEmpleados = buscarEmpleadosConFaltas(registros, semana.time, registrosActividades);

        const variedadOMezclaRef = invernadero.mezcla ? getGlobal().medleysGeneral?.id : invernadero.variedad_ref;
        const metrosPorLinea = parseInt(invernadero?.detalles_fisicos?.find(el => el.detalle === "Metros por línea").norte || "1");
        let promesasRealizadas = dias.map(dia => calcularBonoTotalDia(dia, registros, actividades, presupuestoCosecha, totalCosecha, variedadOMezclaRef, metrosPorLinea, faltasEmpleados, registrosActividades));

        let resultDias = promesasRealizadas;
        bonoTotal = resultDias.reduce((acc, curr) => acc + curr, 0);
        return bonoTotal;
    } catch (err) {
        console.log("HUBO UN ERROR AL OBTENER BONO total DE SEMANA");
        console.log(err);
        throw (err);
    }

}
const obtenerRegistrosActividadesDia = async (dia, registros) => {
    let actividad = registros.find(registro => {
        return registro.dia === dia;
    });
    if (actividad) {
        let x = await obtenerActividadesRealizadasDesdeRegistroActividades(actividad.path);
        return { dia: dia, valor: x };
    } else {
        return { dia: dia, valor: null, isEmpty: true };
    }
}

const calcularBonoTotalDia = (dia, registros, actividades, presupuestoCosecha, totalCosecha, variedadRef, metrosPorLinea, faltasEmpleados, registrosActividades) => {
    let bonoTotal = 0;

    let actividad = registrosActividades.find(registro => {
        return registro.dia === dia;
    })
    if (!actividad.isEmpty) {
        let realizadas = actividad.valor;
        realizadas.docs.map(el => {
            // 
            // 
            //         
            let data = el.data();
            let factorBono = 1.2;
            // if(data.tuvo_ausencia){
            //     //ausencias.add(data.empleado_ref);
            //     factorBono = 1;
            // }
            if (faltasEmpleados.includes(data.empleado_ref)) {
                factorBono = 1;
            }
            // 
            let actividadesReg = Object.keys(data.actividades);
            actividadesReg.forEach(actKey => {
                //
                //
                //
                let actividadActual = actividades.find(el => el.id === actKey);

                if (actividadActual.nombre !== nombreActividadCosecha) {

                    // 
                    let valorBono = actividadActual["bonos_por_unidad"][variedadRef];
                    let cuenta = parseFloat(data.actividades[actKey]);
                    // 
                    // 
                    if (!isNaN(cuenta)) {
                        let bonoAct = factorBono * cuenta * valorBono;
                        if (actividadActual["unidades"] === "líneas") {
                            bonoAct *= metrosPorLinea;
                        }


                        bonoTotal += bonoAct;
                        // 
                        // 
                    }
                } else {
                    // 
                    let cuenta = parseFloat(data.actividades[actKey]);
                    // 
                    if (!isNaN(cuenta) && totalCosecha !== 0) {
                        let bonoAct = factorBono * presupuestoCosecha * cuenta / totalCosecha;
                        // 
                        bonoTotal += bonoAct;
                        // 
                    }
                }
            })
        });
    }
    return bonoTotal;
}


const buscarEmpleadosConFaltas = (registros, semanaTime, registrosActividades) => {

    let dias = obtenerDiasDeSemana(semanaTime);

    let ausencias = new Set();
    for (let x = 0; x < dias.length; x++) {
        let actividad = registrosActividades.find(registro => {
            return registro["dia"] === dias[x];
        })
        if (!actividad.isEmpty) {
            let realizadas = actividad.valor;
            realizadas.docs.map(el => {
                // 
                let data = el.data();
                // 
                if (data.tuvo_ausencia) {
                    ausencias.add(data.empleado_ref);
                }
            })
            // 
            if (actividad && actividad.tuvo_ausencia) {
                ausencias.add(actividad.empleado_ref);
            }
        }

    }

    let result = Array.from(ausencias);
    if (result.length > 0) {
        // 
        // 
    }
    return result;
}

export const guardarSnapShotNomina = async (cicloId, invernaderoId, semana, empleados, footer, actividadesSemana) => {
    try {

        const db = firebase.firestore();
        let result = await db.collection("ciclos").doc(cicloId).collection("snapshotsNomina").add({
            semana: semana,
            empleados: empleados,
            footer: footer,
            invernadero_ref: invernaderoId,
            actividadesSemana
        })
        return result;
    } catch (error) {
        throw error;
    }
}
export const obtenerSnapshotsNominaTodos = async (cicloId) => {
    try {
        const db = firebase.firestore();
        let result = await db.collection("ciclos").doc(cicloId).collection("snapshotsNomina").get();
        return procesarColeccionFirestore(result)
    } catch (error) {
        console.log("ERROR obtenerSnapshotsNominaTodos: ", error)
    }
}
export const obtenerSnapshots_nominaTodos = async (cicloId) => {
    try {
        const db = firebase.firestore();
        let result = await db.collection("ciclos").doc(cicloId).collection("snapshots_nomina").get();
        return procesarColeccionFirestore(result)
    } catch (error) {
        console.log("ERROR obtenerSnapshotsNominaTodos: ", error)
    }
}
export const obtenerSnapshotPrevio = async (cicloId, invernaderoId, semana) => {
    try {
        let { inicio } = flexDate(semana)
        const db = firebase.firestore();
        let result = await db.collection("ciclos").doc(cicloId).collection("snapshotsNomina").where("invernadero_ref", "==", invernaderoId).
            where("semana", ">=", inicio).where("semana", "<", inicio + 7 * 24 * 60 * 60).get();
        registrarInvocaciones(result.size)

        if (result.empty) {
            return { exists: false };
        } else {
            let doc = result.docs[0];
            return { id: doc.id, ...doc.data(), exists: true }
        }
    } catch (error) {
        throw error;
    }

}
export const obtenerSnapshotPrevioNuevo = async (cicloId, invernaderoId, semana) => {
    try {
        const db = firebase.firestore();
        let result = await db.collection("ciclos").doc(cicloId).collection("snapshots_nomina").where("invernadero_ref", "==", invernaderoId).
            where("semana", ">=", semana).where("semana", "<", semana + 7 * 24 * 60 * 60).get();
        registrarInvocaciones(result.size)

        if (result.empty) {
            return { exists: false };
        } else {
            let doc = result.docs[0];
            return { id: doc.id, ...doc.data(), exists: true }
        }
    } catch (error) {
        throw error;
    }

}
export function roughSizeOfObject( object ) {
    var objectList = [];
    var stack = [ object ];
    var bytes = 0;

    while ( stack.length ) {
        var value = stack.pop();

        if ( typeof value === 'boolean' ) {
            bytes += 4;
        }
        else if ( typeof value === 'string' ) {
            bytes += value.length * 2;
        }
        else if ( typeof value === 'number' ) {
            bytes += 8;
        }
        else if
        (
            typeof value === 'object'
            && objectList.indexOf( value ) === -1
        )
        {
            objectList.push( value );

            for( var i in value ) {
                stack.push( value[ i ] );
            }
        }
    }
    return bytes;
}