import React from 'reactn';
import { Dialog } from 'primereact/dialog';
import { DETALLES_TIPO_INCIDENCIA } from '../../../constants';

const ResumenDeIncidenciasModal = (props) => {
    const detallesEnfermedadesActualesConDatos = props?.detallesEnfermedades?.actuales?.filter(enf => enf.id);
    const detallesEnfermedadesPasadasConDatos = props?.detallesEnfermedades?.pasadas?.filter(enf => enf.id);

    if (!detallesEnfermedadesActualesConDatos?.length && !detallesEnfermedadesPasadasConDatos?.length) { return null; }
    else return (
        <Dialog header="Incidencias en claro" modal={true} visible={props.visible} onHide={props.hide}>
            {props?.claroSeleccionado?.enfermedades.length > 0 && detallesEnfermedadesActualesConDatos.length > 0 &&
                <span className="modal-subtitle">{`Semana ${props.week.numero}:`}</span>}
            {detallesEnfermedadesActualesConDatos.map((detallesEnfermedad) => {
                return (
                    <li className="enfermedades-item" key={`${detallesEnfermedad.id}-actuales`}>
                        <span className={`enfermedad-name actual prioridad-${props.makeNombre(detallesEnfermedad)} nivel-incidencia-${detallesEnfermedad.nivelIncidencia}`}>
                            {`${detallesEnfermedad.nombre}`} </span>
                        <span className={`icon-incidencia prioridad-${props.makeNombre(detallesEnfermedad)} nivel-incidencia-${detallesEnfermedad.nivelIncidencia}`}></span>
                        {`${DETALLES_TIPO_INCIDENCIA[detallesEnfermedad.tipo_incidencia][detallesEnfermedad.nivelIncidencia].textoConsulta}`}
                    </li>)
            })}
            {props?.claroSeleccionado?.enfermedadesPasadas.length > 0 && detallesEnfermedadesPasadasConDatos.length > 0 &&
                <span className="modal-subtitle">{`Semana ${props?.semanaPasadaSeleccionada?.numero}:`}</span>}
            {detallesEnfermedadesPasadasConDatos.map((detallesEnfermedad) => {
                return (
                    <li className="enfermedades-item" key={`${detallesEnfermedad.id}-pasadas`}>
                        <span className={`enfermedad-name pasada prioridad-${props?.makeNombre(detallesEnfermedad)} nivel-incidencia-${detallesEnfermedad?.nivelIncidencia}}`}>
                            {`${detallesEnfermedad.nombre}`} </span>
                        <span className={`icon-incidencia prioridad-${props?.makeNombre(detallesEnfermedad)} nivel-incidencia-${detallesEnfermedad?.nivelIncidencia}`}></span>
                        {`${DETALLES_TIPO_INCIDENCIA[detallesEnfermedad.tipo_incidencia][detallesEnfermedad.nivelIncidencia].textoConsulta}`}
                    </li>)
            })}
        </Dialog>);
}
export default ResumenDeIncidenciasModal;