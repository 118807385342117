import React, { useState, useGlobal, useEffect } from 'reactn';
import { withRouter } from 'react-router-dom';
import { dosDecimales, getNombrePorID } from '../../util/functions';
import DatoCard from '../common/cards/DatoCard';
import HTGCard from '../common/cards/HTGCard';
import Column from '../common/cards/Column';
import ClipLoader from 'react-spinners/ClipLoader';
import CardHeader from '../common/cards/CardHeader';

function InvernaderoCard(props) {
  const [invernadero, setInvernadero] = useGlobal("invernadero");
  const [invernaderosF, setInvernaderosF] = useGlobal("invernaderosFisicos");

  const [aviso, setAviso] = useState(true);
  var [usuario, setUsuario] = useGlobal("usuario");
  useEffect(() => {
    if (props.invernadero?.budget && props.invernadero?.budget.estimacion_semanal.length > 0) {
      setAviso(false);
    }
  }, [])
  const puedeConsultar = (params) => {
    return (usuario && ["Administrador", "Administrador Empaque", "Auxiliar Empaque", "Administrador Calidad"].includes(usuario.rol))
  }
  const consultar = () => {
    if (puedeConsultar()) {
      setInvernadero(props.invernadero);
      // if(props.invernadero?.budget && props.invernadero?.budget.estimacion_semanal.length > 0)
      //   props.history.push("producto/registrar_empaque");
      // else props.history.push("producto/crear_budget");
      props.history.push("/producto/reporte/detalles");
    }
  }
  const header = <CardHeader
    invernaderoVirtual={props.invernadero}
    habilitadoTexto={props.invernadero?.habilitado ? "" : "Deshabilitado"}
    nombreInvernaderoFisico={getNombrePorID(invernaderosF, props.invernadero?.invernadero_fisico_ref)} />
  //let dims = props.invernadero?.detalles_fisicos?.find(detalle => detalle.detalle === "Area (m²)")["totales"];
  let className = `htg-card bor-${props.invernadero?.color} ${props.invernadero?.habilitado ? "Habilitado" : "Deshabilitado"}`
  return (
    <HTGCard header={header} headerClassName={className} onClick={consultar}  >
      {props.home && props.cargandoHome
        ? <div className="card-spinner-centered">
          <ClipLoader sizeUnit="px" size={100} color="#003E66" loading={true} />
        </div>
        : <>
          <Column>
            <DatoCard label="Kg/m² Final" valor={`${dosDecimales(props.invernadero?.final)}`} />
            <DatoCard label="Kg/m² Acumulado" valor={`${dosDecimales(props.invernadero?.kgCiclo)}`} />
          </Column>
          <Column>
            <DatoCard label="Kg/m² Proyectado" valor={`${dosDecimales(props.invernadero?.proyectado)}`} />
            <DatoCard label="Porcentaje de budget" valor={`${dosDecimales(props.invernadero?.totalBudget > 0 ? Math.round(100 * props.invernadero?.proyectado / props.invernadero?.totalBudget) : 0)} %`} />
          </Column>
          <Column>
            <DatoCard label="% packout real" valor={`${(isNaN(props.invernadero?.packoutSemanal) || props.invernadero?.packoutSemanal === Infinity) ? "-" : dosDecimales(100 * props.invernadero?.packoutSemanal)} %`} />
            <DatoCard label="% packout acumulado" valor={`${(isNaN(props.invernadero?.packoutAcumulado) || props.invernadero?.packoutAcumulado === Infinity) ? "-" : dosDecimales(100 * props.invernadero?.packoutAcumulado)} %`} />
          </Column>
          <Column>
            <DatoCard label="Semanas transcurridas" valor={`${Math.round(100 * props.invernadero?.semanasTranscurridas / props.invernadero?.semanasTotales) || 0} %`} />
          </Column>
        </>}
    </HTGCard>);
}
export default withRouter(InvernaderoCard);//68