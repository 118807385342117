import React, { useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from "primereact/button";
import { useGlobal, setGlobal, getGlobal } from "reactn";
import { Dropdown } from 'primereact/dropdown';
import ContenedorInput from '../../common/ContenedorInput';
import { DeepClone, formatearMinutosAHoras, getNombrePorID } from '../../../util/functions';
import { eliminarActividad, eliminarActividadMant, eliminarCatMant, getEmpaquesIndexed, getEmpresasIndexed, getFirestoreID, guardarActividadesMantenimiento } from '../../../service/Mantenimiento';
import { InputText } from 'primereact/inputtext';
import { periodicidadMantOpciones } from '../../../util/constants';
import HTGGrid from '../../common/HTGGrid';
import InputTimeSelector from 'components/common/InputTimeSelector';

const estadoCategoria = { nombre: "", classname: "NEW", tipo: "categoria", indice: "" }
const estadoSubcategoria = { nombre: "", classname: "", tipo: "subcategoria", categoria_ref: "", indice: "" }
const estadoActividad = { nombre: "", tipo: "actividad", categoria_ref: "", subcategoria_ref: "", tiempo_estimado: "", indice: "" }
const allEstados = [estadoCategoria, estadoSubcategoria, estadoActividad]
const ModalAdministrarActividad = (props) => {

    const [, setCargando] = useGlobal("cargando");
    const [categorias] = useGlobal("categoriasMantenimiento");
    const [empresas] = useGlobal("empresas");
    const [empaques] = useGlobal("empaques");
    const [subcategorias] = useGlobal("subcategoriasMantenimiento");
    const [actividad] = useGlobal("actividadMantenimientoSeleccionado");
    
    const [state, setState] = useState({ ...estadoCategoria })
    const [subcategoriasOpciones, setSubcategoriasOpciones] = useState([])
    
    const empresasIndexed = getEmpresasIndexed(empresas)
    const empaquesIndexed = getEmpaquesIndexed(empaques)

    const { tiempos_estimados } = state
    const { HTG, ISER, HTF } = empresasIndexed
    const { Empaque_Agropark, Sitio_A, Sitio_T } = empaquesIndexed

    useEffect(() => {
        let tipo = ""
        if (props.show === 1) tipo = "categoria"
        else if (props.show === 2) tipo = "subcategoria"
        else if (props.show === 3) tipo = "actividad"

        if (actividad) setState({ ...actividad, tipo })
        else setState({ ...allEstados[props.show - 1] })
    }, [props.show])
    useEffect(() => {
        if (state.categoria_ref) {
            let subcategoriasFiltered = subcategorias.filter(sub => sub.categoria_ref === state.categoria_ref)
            setSubcategoriasOpciones(subcategoriasFiltered)
        }
    }, [state.categoria_ref])

    const getNewStateInsertEmpresaYSitio = (state, empresaId, sitioId, value) => {
        const newState = {
            ...state,
            tiempos_estimados: {
                ...state?.tiempos_estimados,
                [empresaId]: {
                    ...state?.tiempos_estimados?.[empresaId],
                    [sitioId]: parseInt(value || 0)
                }
            }
        }
        return newState
    }
    const handleChange = (event) => {
        let stateCopy = { ...state }
        let { name, value } = event.target
        
        if(name === "tiempo_estimado"){
            stateCopy.tiempo_estimado = parseInt(value || 0)
        }
        if(name === "tiempo_estimado_htg"){
            stateCopy = getNewStateInsertEmpresaYSitio(stateCopy, HTG.id, Empaque_Agropark.id, value)
        }
        if(name === "tiempo_estimado_iser"){
            stateCopy = getNewStateInsertEmpresaYSitio(stateCopy, ISER.id, Empaque_Agropark.id, value)
        }
        if(name === "tiempo_estimado_htf_agropark"){
            stateCopy = getNewStateInsertEmpresaYSitio(stateCopy, HTF.id, Empaque_Agropark.id, value)
        }
        if(name === "tiempo_estimado_htf_sitioA"){
            stateCopy = getNewStateInsertEmpresaYSitio(stateCopy, HTF.id, Sitio_A.id, value)
        }
        if(name === "tiempo_estimado_htf_sitioT"){
            stateCopy = getNewStateInsertEmpresaYSitio(stateCopy, HTF.id, Sitio_T.id, value)
        }
        if(!name.startsWith("tiempo_estimado")){
            stateCopy[name] = value
        }

        setState(stateCopy)
    }

    const handleGuardar = async () => {
        setCargando(true)
        props.hide()
        let { id = getFirestoreID(), numActividades, numSubCategorias, nombreCategoria, nombreSubategoria, ...data } = DeepClone(state)
        if (state.tipo === "actividad" && !state.empresa) {
            data.tiempo_estimado = parseInt(data.tiempo_estimado);
        }
        else if (state.tipo === "subcategoria" || state.tipo === "actividad") delete data.classname
        delete data.numSubCategorias
        delete data.numActividades

        if(state.tipo === "actividad"){
            delete data.tipo;
        }
        await guardarActividadesMantenimiento(id, data)
        setGlobal({ reloadMant: true })
    }
    const handleEliminar = async () => {
        setCargando(true);

        if (isActividad())
            await eliminarActividadMant(state.id)
        else if (isSubcategoria() || isCategoria())
            await eliminarCatMant(state.id)
        setGlobal({ reloadMant: true })
        props.hide()
    }
    const puedeGuardar = () => {
        if (!state.nombre) return false
        else if (isSubcategoria() && !state.categoria_ref) return false
        else if (isActividad() && (!state.categoria_ref || !state.subcategoria_ref || !state.tiempo_estimado)) return false
        else return true
    }
    const isCategoria = () => props.show === 1
    const isSubcategoria = () => props.show === 2
    const isActividad = () => props.show === 3

    const footer = <div>
        <Button type="button" className="btn btn-secondary" onClick={props.hide} label="Cancelar" />
        {state.id &&<Button type="button" className="btn btn-secondary" onClick={handleEliminar} label="Eliminar" />}
        <Button type="button" disabled={!puedeGuardar()} className="btn btn-secondary" onClick={handleGuardar} label="Guardar" />
    </div>
    

    

    const valueTiempoEstimadoHTG = tiempos_estimados?.[HTG.id]?.[Empaque_Agropark?.id] || 0
    const valueTiempoEstimadoISER = tiempos_estimados?.[ISER.id]?.[Empaque_Agropark?.id] || 0
    const valueTiempoEstimadoHTFAgropark = tiempos_estimados?.[HTF.id]?.[Empaque_Agropark?.id] || 0
    const valueTiempoEstimadoHTFSitioA = tiempos_estimados?.[HTF.id]?.[Sitio_A?.id] || 0
    const valueTiempoEstimadoHTFSitioT = tiempos_estimados?.[HTF.id]?.[Sitio_T?.id] || 0
    
    return (
        <Dialog className="modal-50" header={`Administrar ${state.actividad ? "" : actividad ? "" : "nueva"} ${state.tipo}`} footer={footer} visible={!!props.show} onHide={props.hide} >
            <HTGGrid>
            <ContenedorInput label="Nombre" col="8">
                <InputText name="nombre" value={state.nombre} onChange={handleChange} autoComplete="off" />
            </ContenedorInput>

            <ContenedorInput label="Índice" col="4">
                <InputText name="indice" value={state.indice} onChange={handleChange} />
            </ContenedorInput>

            {false && //For testing purposes
                <ContenedorInput label="Classname">
                    <InputText name="classname" value={state.classname} onChange={handleChange} />
                </ContenedorInput>}

            {(isSubcategoria() || isActividad()) &&
                <ContenedorInput label="Categoría referencia" col="6">
                    <Dropdown name="categoria_ref" value={state.categoria_ref} options={categorias} onChange={handleChange} optionLabel="nombre" optionValue="id" />
                </ContenedorInput>}
            {isActividad() &&
                <React.Fragment>
                    <ContenedorInput label="Subcategoría referencia" col="6">
                        <Dropdown name="subcategoria_ref" value={state.subcategoria_ref} options={subcategoriasOpciones} onChange={handleChange} optionLabel="nombre" optionValue="id" />
                    </ContenedorInput>
                    <InputTimeSelector 
                        label='Tiempo estimado por defecto' 
                        name="tiempo_estimado" 
                        value={state.tiempo_estimado} 
                        onChange={handleChange}
                    />
                    <InputTimeSelector 
                        label='Tiempo estimado - HTG' 
                        name="tiempo_estimado_htg" 
                        value={valueTiempoEstimadoHTG} 
                        onChange={handleChange}
                    />
                    <InputTimeSelector 
                        label='Tiempo estimado - ISER' 
                        name="tiempo_estimado_iser" 
                        value={valueTiempoEstimadoISER} 
                        onChange={handleChange}
                    />
                    <InputTimeSelector 
                        label='Tiempo estimado - HTF - Empaque Agropark' 
                        name="tiempo_estimado_htf_agropark" 
                        value={valueTiempoEstimadoHTFAgropark} 
                        onChange={handleChange}
                    />
                    <InputTimeSelector 
                        label='Tiempo estimado - HTF - Sitio A' 
                        name="tiempo_estimado_htf_sitioA" 
                        value={valueTiempoEstimadoHTFSitioA} 
                        onChange={handleChange}
                    />
                    <InputTimeSelector 
                        label='Tiempo estimado - HTF - Sitio T' 
                        name="tiempo_estimado_htf_sitioT" 
                        value={valueTiempoEstimadoHTFSitioT} 
                        onChange={handleChange}
                    />
                    <ContenedorInput label="Periodicidad" col="12">
                        <Dropdown name="periodicidad" value={state.periodicidad || ""} options={periodicidadMantOpciones} onChange={handleChange} />
                    </ContenedorInput>
                    
                </React.Fragment>}
            </HTGGrid>
        </Dialog>
    )
}
export default ModalAdministrarActividad