import React from 'reactn';
import { Dialog } from 'primereact/dialog';

const ResumenDeEnfermedadesModal = (props) => {
    const detallesEnfermedadesActualesConDatos = props?.detallesEnfermedades?.actuales?.filter(enf => enf.id);
    const detallesEnfermedadesPasadasConDatos = props?.detallesEnfermedades?.pasadas?.filter(enf => enf.id);

    if (!detallesEnfermedadesActualesConDatos?.length && !detallesEnfermedadesPasadasConDatos?.length) { return null; }
    else return (
        <Dialog header="Enfermedades en claro" modal={true}
            visible={props.visible} onHide={props.hide}>
            {props?.claroSeleccionado?.enfermedades.length > 0 && detallesEnfermedadesActualesConDatos.length > 0 &&
                <span className="modal-subtitle">{`Semana ${props.week.numero}:`}</span>}
            {detallesEnfermedadesActualesConDatos.map((detallesEnfermedad) => {
                return (
                    <li className="enfermedades-item" key={detallesEnfermedad.id}>
                        <span className={`icon-list actual ${props.makeCssClass(detallesEnfermedad.nombre)}`}></span>
                        <span>{`${detallesEnfermedad.nombre}`}</span> {`(${detallesEnfermedad.fechaRegistro})`}
                    </li>)
            })}
            {props?.claroSeleccionado?.enfermedadesPasadas.length > 0 && detallesEnfermedadesPasadasConDatos.length > 0 &&
                <span className="modal-subtitle">{`Semana ${props.semanaPasadaSeleccionada.numero}:`}</span>}
            {detallesEnfermedadesPasadasConDatos.map((detallesEnfermedad) => {
                return (
                    <li className="enfermedades-item" key={detallesEnfermedad.id}>
                        <span className={`icon-list pasada ${props.makeCssClass(detallesEnfermedad.nombre)}`}></span>
                        <span>{`${detallesEnfermedad.nombre}`}</span> {`(${detallesEnfermedad.fechaRegistro})`}
                    </li>)
            })}
        </Dialog>);
}
export default ResumenDeEnfermedadesModal;