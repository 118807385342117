import { obtenerClientes } from "./Clientes";
import { obtenerResumenCobranzaTotalCliente } from "./ReporteCobranzaCliente";

export const obtenerReporteDeCobranzaTotal = async (cicloId, empresaId, semana) => {
    let clientes = await obtenerClientes();

    let promises = clientes.map(cliente=>obtenerResumenCobranzaTotalCliente(cicloId, cliente,empresaId,semana));    
    let result = await Promise.all(promises);
    return result;
}

export const obtenerDesfaseCobranzaMaximo = async (params) => {
    try {
        let clientes = await obtenerClientes();
        if(clientes.length === 0){
            return 0;
        }
        let desfases = clientes.map(el=>el.desfase_cobranza);
        return Math.max(...desfases);        
    } catch (error) {
        throw error;
    }
}

