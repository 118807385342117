import React, { useState, useGlobal, useEffect } from 'reactn';
import { Card } from 'primereact/card';
import { withRouter } from 'react-router-dom';
import { dosDecimales, getProductoYVariedadString } from '../../util/functions';
import icono from '../../images/icons/external-icon.svg';

function InvernaderoExternoCard(props) {
  const [, setInvernadero] = useGlobal("invernaderoExterno");
  const [, setAviso] = useState(true);
  var [usuario] = useGlobal("usuario");
  useEffect(() => {
    if (props.invernadero.budget && props.invernadero.budget.estimacion_semanal.length > 0) {
      setAviso(false);
    }
  }, [])
  const puedeConsultar = (params) => {
    return (usuario && ["Administrador", "Administrador Empaque", "Auxiliar Empaque", "Administrador Calidad"].includes(usuario.rol))
  }
  const consultar = () => {
    if (puedeConsultar()) {
      setInvernadero(props.invernadero);
      props.history.push("/empaque/reporte_externo");
    }
  }
  const header = <span className="card-header"><div className={`card-id icon-general icon-lort icon-id bor-gray bor-${props.invernadero.color} bg-gray bg-${props.invernadero.color}`}><img width="30" alt="icono" src={icono} /></div><div>
    <p className="card-up">Externo - {props.invernadero.nombre}</p>
    <p className="card-down">{props.invernadero.invernaderoFisico ? `${props.invernadero.invernaderoFisico.nombre} - ${getProductoYVariedadString(props.invernadero)}` : null}</p></div></span>

  return <div className="p-col-12 p-md-4 p-sm-6" onClick={consultar}>
    <Card header={header} className={`htg-card bor-${props.invernadero.color} `} >

      <div className="p-grid p-justify-center text-center">
        <div className="p-col">
          <div className="card-block">
            <p className="value"> {dosDecimales(props.invernadero.final)}</p>
            <p className="label">Kg/m² Final</p>
          </div>
          <div className="card-block">
            <p className="value"> {dosDecimales(props.invernadero.kgCiclo)}</p>
            <p className="label">Kg/m² Acumulado</p>
          </div>
        </div>
        <div className="p-col">
          <div className="card-block">
            <p className="value"> {dosDecimales(props.invernadero.proyectado)}</p>
            <p className="label">Kg/m² Proyectado</p>
          </div>
          <div className="card-block">
            <p className="value"> {props.invernadero.totalBudget > 0 ? Math.round(100 * props.invernadero.proyectado / props.invernadero.totalBudget) : 0}%</p>
            <p className="label">Porcentaje de budget</p>
          </div>

        </div>
        <div className="p-col">
          <div className="card-block">
            <p className="value"> {(isNaN(props.invernadero.packoutSemanal) || props.invernadero.packoutSemanal === Infinity) ? "-" : dosDecimales(100 * props.invernadero.packoutSemanal)}%</p>
            <p className="label">% packout real</p>
          </div>
          <div className="card-block">
            <p className="value"> {(isNaN(props.invernadero.packoutAcumulado) || props.invernadero.packoutAcumulado === Infinity) ? "-" : dosDecimales(100 * props.invernadero.packoutAcumulado)}%</p>
            <p className="label">% packout acumulado</p>
          </div>

        </div>

      </div>


    </Card>
  </div>
}

export default withRouter(InvernaderoExternoCard);//86