import React from 'react'

const SeccionFormTabla = ({col="p-col-12", children, titulo=""}) => {
  return (
    <div className={col}>
        <p className="section-title">{titulo}</p>
        {children}
    </div>
  )
}

export default SeccionFormTabla