import React, { useGlobal, useState, useEffect, setGlobal } from 'reactn';
import { obtenerDiasDeSemana, obtenerNombreDiaSemana } from "../../service/fechas";
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { noHaSidoClausurado } from "../../service/Ciclos";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { nombresDiasSemanaPlanos } from '../../constants'
import { obtenerResumenInicial, obtenerResumenFinal, obtenerResumenPesos, obtenerFiltrosCalidadTodos, todos } from '../../service/Calidad';
import { tresDecimales, getProductoYVariedadString } from '../../util/functions';
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import ContenedorHeader from '../common/ContenedorHeader';
import ContenedorFiltro from '../common/ContenedorFiltro';
import SeccionFiltros from '../common/SeccionFiltros';
import icono from '../../images/icons/external-icon.svg';
import ContenedorPrincipal from '../planeacion_mantenimiento/CapturaAgentes/ContenedorPrincipal';
import ContenedorTabla from '../common/ContenedorTabla';
import ContenedorBotonesRow from '../common/ContenedorBotonesRow';
import RowBotonesTopThin from '../common/RowBotonesTopThin';
import { obtenerProveedores, obtenerProveedoresInvernadero } from '../../service/Queries/Proveedores';
import { getColoresVariedades } from 'service/ProductosYVariedades';

setGlobal({ datosResumenes: [], proveedores: [] })
const Semanal = (props) => {
  const [, setNombreModulo] = useGlobal("nombreModulo");
  const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
  const [usuario,] = useGlobal("usuario");
  const [productosYVariedades] = useGlobal("productosYVariedades")
  const [, setColores] = useGlobal("coloresVariedades");
  const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector();
  const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
  const [invernadero, setInvernadero] = useGlobal("invernadero");
  const [, setCargando] = useGlobal("cargando");
  const [ciclo,] = useGlobal("ciclo");
  const [ciclos,] = useGlobal("ciclos");
  const [rowsInicial, setRowsInicial] = useState([]);
  const [rowsFinal, setRowsFinal] = useState([]);
  const [rowsPesos, setRowsPesos] = useState([]);
  const [, setDiaConsultado] = useGlobal("diaConsultado");
  const [datosCargados] = useGlobal("datosCargadosCalidad");
  const [proveedores, setProveedores] = useGlobal("proveedores");
  const [proveedor, setProveedor] = useGlobal("proveedorExternoCalidad");
  const [datosResumenes, setDatosResumenes] = useState([]);

  useEffect(() => {
    setNombreModulo("Calidad");
    setHabilitadoSelectorCiclo(true)
  }, [])
  useEffect(() => {
    if (ciclo && !datosCargados) obtenerFiltrosCalidadTodos(ciclo)
  }, [ciclo, datosCargados])

  useEffect(() => {
    setCargando(true)
    if ((invernadero && !invernadero.es_externo))
      setProveedor(todos);
    if (datosCargados && week.nombre !== "Cargando" && invernadero?.id)
      obtenerDatosIniciales();
  }, [datosCargados, week, invernadero, proveedor])

  const puedeEditar = () => noHaSidoClausurado(ciclo, ciclos) && usuario && invernadero && (!invernadero.es_externo || proveedor?.habilitado) && (invernadero.habilitado || invernadero.es_externo) && ["Administrador", "Administrador Calidad", "Auxiliar Calidad"].includes(usuario.rol)
  const obtenerDatosIniciales = async () => {
    try {
      let [listaProveedores, listaColores] = await Promise.all([obtenerProveedoresInvernadero(ciclo, invernadero.id), getColoresVariedades()])
      let { inicial, final, pesos, sinCargar } = datosResumenes.find(el => el.semana === week.time && el.id === invernadero.id) || { sinCargar: true }
      if (sinCargar || invernadero.es_externo) {

        const inicialPromise = obtenerResumenInicial(ciclo, invernadero.id, week.time, invernadero.es_externo, proveedor.id)
        const finalPromise = obtenerResumenFinal(ciclo, invernadero.id, week.time, invernadero.es_externo, proveedor.id)
        const pesosPromise = obtenerResumenPesos(ciclo, invernadero.id, week.time, invernadero.es_externo);
        [inicial, final, pesos] = await Promise.all([inicialPromise, finalPromise, pesosPromise])
        let datoResumenDeSemana = { semana: week.time, id: invernadero.id, inicial, final, pesos }
        setDatosResumenes([...datosResumenes, datoResumenDeSemana])

      }

      //filtrar si no hay registros
      let proveedoresFiltrados = [], promises = [];
      for (const index in listaProveedores) {
        const prov = listaProveedores[index];
        if (prov.habilitado) {
          proveedoresFiltrados.push(prov);
          promises.push(Promise.resolve([[], [], []]))
        }
        else {
          const inicialPromise = obtenerResumenInicial(ciclo, invernadero.id, week.time, invernadero.es_externo, prov.id)
          const finalPromise = obtenerResumenFinal(ciclo, invernadero.id, week.time, invernadero.es_externo, prov.id)
          promises.push(Promise.all([inicialPromise, finalPromise]))
        }
      }

      const datosProveedores = await Promise.all(promises)
      for (const index in listaProveedores) {
        const prov = listaProveedores[index];
        const [ini, fin, pes] = datosProveedores[index]
        //console.log("DATOS CAL: ", [ini, fin, pes])
        if (ini?.length > 0 || fin?.length > 0) { proveedoresFiltrados.push(prov) }
      }

      setProveedores(proveedoresFiltrados)
      setColores(listaColores)
      setRowsInicial(inicial);
      setRowsFinal(final);
      setRowsPesos(pesos);
    }
    catch (error) {
      console.log("ERROR OBTENER DATOS: " + error)
    }

    setCargando(false)
  }

  const templateBody = (rowData, column) => {
    let data = rowData.nombrePresentacion ? rowData.columns[column.field] : rowData[column.field];
    return <div>{tresDecimales(data?.pesoPromedio || 0)}<span className="label-unidad">kg</span> <br />{data?.muestras}<span className="label-unidad">muestras</span> </div>
  }

  const getHeader = (nombreDia, tipoTabla) => {
    let hasDatos = true
    let dias = obtenerDiasDeSemana(week.time);
    let index = nombresDiasSemanaPlanos.indexOf(nombreDia);
    let dia = dias[index];
    let nombre = obtenerNombreDiaSemana(dia);

    if (tipoTabla === "inicial" && rowsInicial.length === 0) hasDatos = false
    else if (tipoTabla === "final" && rowsFinal.length === 0) hasDatos = false
    else if (tipoTabla === "pesos" && rowsPesos.length === 0) hasDatos = false

    return <div onClick={() => { if (hasDatos) consultarDia(dia, tipoTabla); }}>{nombre} </div>
  }
  const consultarDia = (dia, tipoTabla) => {
    setDiaConsultado(dia);
    props.history.push(`/calidad/tabla/${tipoTabla}`);
  }

  const irInicial = () => { props.history.push("/calidad/registro_inicial/crear") }
  const irFinal = () => { props.history.push("/calidad/registro_final/crear") }
  const irPesos = () => { props.history.push("/calidad/registro_pesos/crear") }
  const irReporte = () => { props.history.push("/calidad/reporte") }
  const irHeatmap = () => { props.history.push({pathname: "/calidad/graficas/heatmap", data: {invernadero}}) }

  return (
    <ContenedorPrincipal>
      <ContenedorHeader col="p-col p-col-auto" titulo="Calidad semanal" subtituloInv={getProductoYVariedadString(invernadero)}
        invernaderoNombre={invernadero?.es_externo ? <img width="60" alt="icono" src={icono} /> : invernadero?.nombre}
        iconos={`icon-general icon-id bor-${invernadero?.color} bg-${invernadero?.color} ${invernadero?.habilitado ? "" : "Deshabilitado"}`} atras={props.history.goBack} />

      <RowBotonesTopThin>
        <Button label="Reporte de calidad" onClick={irReporte} />
        <Button className="reactbtn-icon" onClick={irHeatmap} label="Ver gráfica">
          <FontAwesomeIcon icon={faChartBar} />
        </Button>
      </RowBotonesTopThin>

      <SeccionFiltros >
        <ContenedorFiltro label="Invernadero" sinFiltrarTexto>
          <Dropdown id="filtroInvernadero" placeholder="Seleccionar"
            dataKey="id" optionLabel="nombre"
            options={invernaderosVirtuales} value={invernadero}
            onChange={(e) => { setInvernadero(e.target.value) }} />
        </ContenedorFiltro>
        {invernadero?.es_externo &&
          <ContenedorFiltro label="proveedor" >
            <Dropdown id="filtroProveedor" placeholder="Seleccionar"
              dataKey="id" optionLabel="nombre"
              options={[todos, ...proveedores,]} value={proveedor}
              onChange={(e) => { setProveedor(e.target.value) }} />
          </ContenedorFiltro>}
        <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
      </SeccionFiltros>

      <ContenedorTabla titulo="Calidad inicial" >
        <DataTable value={rowsInicial} emptyMessage="No hay registros" className="header-hover">
          <Column header={getHeader("lunes", "inicial")} field={0} body={templateBody} />
          <Column header={getHeader("martes", "inicial")} field={1} body={templateBody} />
          <Column header={getHeader("miercoles", "inicial")} field={2} body={templateBody} />
          <Column header={getHeader("jueves", "inicial")} field={3} body={templateBody} />
          <Column header={getHeader("viernes", "inicial")} field={4} body={templateBody} />
          <Column header={getHeader("sabado", "inicial")} field={5} body={templateBody} />
        </DataTable>
      </ContenedorTabla>
      {puedeEditar() &&
        <ContenedorBotonesRow>
          <Button label="AGREGAR NUEVO REGISTRO INICIAL" onClick={irInicial} />
        </ContenedorBotonesRow>}

      <ContenedorTabla titulo="Calidad final">
        <DataTable value={rowsFinal} emptyMessage="No hay registros" className="header-hover">
          <Column header="" field="nombrePresentacion" />
          <Column header={getHeader("lunes", "final")} field={0} body={templateBody} />
          <Column header={getHeader("martes", "final")} field={1} body={templateBody} />
          <Column header={getHeader("miercoles", "final")} field={2} body={templateBody} />
          <Column header={getHeader("jueves", "final")} field={3} body={templateBody} />
          <Column header={getHeader("viernes", "final")} field={4} body={templateBody} />
          <Column header={getHeader("sabado", "final")} field={5} body={templateBody} />
        </DataTable>
      </ContenedorTabla>
      {puedeEditar() &&
        <ContenedorBotonesRow>
          <Button label="AGREGAR NUEVO REGISTRO FINAL" onClick={irFinal} />
        </ContenedorBotonesRow>}


      <ContenedorTabla titulo={invernadero?.es_externo ? "Pesos diarios de todos los proveedores" : "Pesos diarios"} >
        <DataTable value={rowsPesos} emptyMessage="No hay registros" className="header-hover">
          <Column header="" field="nombrePresentacion" />
          <Column header={getHeader("lunes", "pesos")} field={0} body={templateBody} />
          <Column header={getHeader("martes", "pesos")} field={1} body={templateBody} />
          <Column header={getHeader("miercoles", "pesos")} field={2} body={templateBody} />
          <Column header={getHeader("jueves", "pesos")} field={3} body={templateBody} />
          <Column header={getHeader("viernes", "pesos")} field={4} body={templateBody} />
          <Column header={getHeader("sabado", "pesos")} field={5} body={templateBody} />
        </DataTable>
      </ContenedorTabla>
      {puedeEditar() &&
        <ContenedorBotonesRow>
          <Button label="AGREGAR NUEVO REGISTRO DE PESOS" onClick={irPesos} />
        </ContenedorBotonesRow>}
    </ContenedorPrincipal >);
}
export default Semanal;//188