import { setGlobal, getGlobal } from 'reactn'
import * as firebase from "firebase/app";
import "firebase/firestore";
import { uploadStatistics } from './Estadisticas'

function registrarInvocaciones(count) {
    uploadStatistics(count)
}

export const entradaColl = "registros_entradas"
export const salidaColl = "registros_salidas"
export const tiposEntradas = ["Línea", "Arrastre"]
export const tiposOrdenesSalida = ["Venta", "Donación", "Desperdicio"];

export const obtenerFiltrosVentasNacionalesTodos = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const clientesVentaNacional = await obtenerClientesVentaNacional()

            let productosVariedadesOpciones = [], productosFiltros = [];
            const productosYVariedades = getGlobal().productosYVariedades;
            productosYVariedades.forEach(producto => {
                if (producto.unico) {
                    producto.variedades.forEach(variedad => {
                        const nombre = `${producto.nombre} - ${variedad.nombre}`
                        productosVariedadesOpciones.push({ id: variedad.id, nombre, producto_ref: producto.id });
                        productosFiltros.push(variedad.id);
                    })
                }
                else if (producto.mezcla) {
                    productosVariedadesOpciones.push({ id: producto.id, nombre: producto.nombre, producto_ref: producto.id });
                    productosFiltros.push(producto.id);
                }
            })

            productosVariedadesOpciones = productosVariedadesOpciones.sort((a, b) => a.nombre > b.nombre ? 1 : -1)
            setGlobal({ clientesVentaNacional, productosVariedadesOpciones, productosFiltros, clienteFiltroVentaNacional: "Todos", datosCargadosVentaNacional: true })
        } catch (error) {
            console.log("ERROR obteniendo datos de ventas nacionales")
            reject(error)
        }
    });
}

///////////Deprecated///////////
export const obtenerInvernaderoRegistrosEntrada = async (ciclo, invernaderoId) => {
    let response = await firebase.firestore().collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernaderoId).collection(entradaColl).get()
    registrarInvocaciones(response.size)
    let entradas = []
    if (response.docs.length === 0)
        return []
    response.docs.forEach(doc => {
        if (!doc.data().totalRestado)
            entradas.push({ id: doc.id, ...doc.data() })
    })
    return entradas
}
export const obtenerRegistrosEntradasCiclo = async (ciclo, invernaderos) => {
    return new Promise(async (resolve, reject) => {
        let promises = invernaderos.map(invernadero => obtenerInvernaderoRegistrosEntrada(ciclo, invernadero.id))
        let registrosEntradas = await Promise.all(promises).catch(err => reject(err))

        resolve(registrosEntradas)
    })
}
///////////////////////////////

///Obtener registros salidas///
export const obtenerRegistroSalida = async (ciclo, salidaID) => {
    return new Promise(async (resolve, reject) => {
        let response = await firebase.firestore().collection("ciclos").doc(ciclo).collection(salidaColl).doc(salidaID).get()
        registrarInvocaciones(response.size)
        return response.exists ? resolve({ id: response.id, ...response.data() }) : resolve(null)
    })
}
export const obtenerRegistrosSalidas = async (ciclo) => {
    return new Promise(async (resolve, reject) => {
        let response = await firebase.firestore().collection("ciclos").doc(ciclo).collection(salidaColl).orderBy('fecha_creacion', 'asc').get()
        registrarInvocaciones(response.size)
        return response.empty ? resolve([]) : resolve(response.docs.map(el => ({ id: el.id, ...el.data() })))
    })
}

///Obtener registros entradas///
export const obtenerRegistroEntrada = async (ciclo, invernadero, entradaID) => {
    return new Promise(async (resolve, reject) => {
        let response = await firebase.firestore().collection("ciclos").doc(ciclo).collection("invernaderos_virtuales")
            .doc(invernadero).collection(entradaColl).doc(entradaID).get().catch(err => reject(err))
        registrarInvocaciones(response.size)

        return response.exists ? resolve({ id: response.id, ...response.data() }) : resolve(null)
    })
}
export const obtenerRegistrosEntradasPorSemanaYInverandero = async (ciclo, week, invernadero) => {
    return new Promise(async (resolve, reject) => {
        let response = await firebase.firestore().collection("ciclos").doc(ciclo).collection("invernaderos_virtuales")
            .doc(invernadero).collection(entradaColl).where("week.time", "==", week.time).get()
        registrarInvocaciones(response.size)
        return response.empty ? resolve([]) : resolve(response.docs.map(el => ({ id: el.id, ...el.data() })))
    })
}
export const obtenerRegistrosEntradasPorSemanaTodos = async (ciclo, week, invernaderos) => {
    return new Promise(async (resolve, reject) => {
        let promises = invernaderos.map(invernadero => obtenerRegistrosEntradasPorSemanaYInverandero(ciclo, week, invernadero.id))
        let registrosEntradas = await Promise.all(promises).catch(err => reject(err))

        resolve(registrosEntradas)
    })
}
///////end obtener registros///////

export const obtenerClientesVentaNacional = () => {
    return new Promise((resolve, reject) => {
        let results = [];
        firebase.firestore().collection("clientes_venta_nacional").orderBy("nombre").get().then(clientes => {
            registrarInvocaciones(clientes.size)
            clientes.docs.forEach(doc => {

                let data = doc.data();
                data.id = doc.id;
                results.push(data);
            })
            resolve(results);
        }).catch(err => {
            reject(err);
        });
    })
}

export const getEntradaskgTotales = (entradas) => {
    let kgTotales = 0
    entradas.forEach(entrada => {
        entrada.pallets.forEach(pallet => {
            kgTotales += parseInt(pallet.cantidad) || 0
        })
    })
    return kgTotales
}
export const getSalidakg = (items) => {
    let kgTotales = 0
    items.forEach(item => {
        kgTotales += parseInt(item.kilosVender) || 0
    })
    return kgTotales
}
export const getSalidaskgTotales = (salidas, invernaderoID = undefined) => {
    let kgTotales = 0
    salidas.forEach(salida => {
        salida.items.forEach(item => {
            if (item.invernaderoVirtual === invernaderoID || !invernaderoID)
                kgTotales += parseInt(item.kilosVender) || 0
        })
    })
    return kgTotales
}
export const getSalidaPrecio = (items) => {
    let precio = 0
    items.forEach(item => {
        precio += (item.precio * item.kilosVender)
    })
    return precio.toFixed(2)
}
export const getSalidasPrecioTotal = (salidas) => {
    let precioTotal = 0
    salidas.forEach(salida => {
        salida.items.forEach(item => {
            precioTotal += (item.precio * item.kilosVender)
        })
    })

    return precioTotal
}

export const guardarDatosEntrada = (ciclo, invernadero, datos, entradaID) => {
    return new Promise(async (resolve, reject) => {
        if (!entradaID)
            entradaID = firebase.firestore().collection("ciclos").doc().collection("invernaderos_virtuales").doc().collection("registros_entradas").doc().id

        await firebase.firestore().collection("ciclos").doc(ciclo).collection("invernaderos_virtuales")
            .doc(invernadero).collection(entradaColl).doc(entradaID).set({ ...datos }, { merge: true }).catch(err => reject(err))

        resolve(true)
    })
}
export const guardarDatosSalida = (ciclo, datos, salidaID) => {
    return new Promise(async (resolve, reject) => {
        if (!salidaID)
            salidaID = firebase.firestore().collection("ciclos").doc().collection(salidaColl).doc().id
        console.log(datos)
        await firebase.firestore().collection("ciclos").doc(ciclo).collection(salidaColl).doc(salidaID).set({ ...datos }, { merge: true }).catch(err => {
            console.log(err)
            reject(err)})
        console.log("salida guardada")
        resolve(true)
    })
}

export const getVariedadDeMedleysOExterno = (productosOpciones = [], producto_ref) => {
    let variedad = productosOpciones.find(el => el.producto_ref === producto_ref) || { id: "" };
    return variedad.id;
}