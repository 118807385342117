import React from "react"
const SeccionFormularioCard1 = (props) =>
    <div className={`p-col-${props.col || "10"}`}>
        <p className="section-title">{props.titulo}</p>
        <div className="p-grid p-fluid ">
            <div className="p-col-12">
                <div className="card">
                    {props.children}
                </div>
            </div>
        </div>
    </div>;
const SeccionFormularioCard2 = (props) =>
    <div className={`p-col-${props.col || "10"}`}>
        <p className="section-title">{props.titulo}</p>
        <div className="p-grid p-fluid ">
            <div className="p-col-12">
                <div className="card">
                    <div className="form-group p-grid p-fluid">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    </div>;
const SeccionFormularioCard = SeccionFormularioCard2
export default SeccionFormularioCard;