import * as firebase from "firebase/app";
import "firebase/firestore";
import {uploadStatistics} from './Estadisticas'

function registrarInvocaciones(count){
    uploadStatistics(count)
}
export const obtenerPresentacionesClientes = (clienteIds) => {
    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
        db.collection("presentaciones").where("cliente_ref","in",clienteIds).orderBy("orden").get().then((result) => {
            registrarInvocaciones(result.size)
            let rows = [];
            result.forEach(item => {
                let data = item.data();
                data.id = item.id;
                rows.push(data);
            });
           resolve(rows) 
        }).catch((err) => {
            console.log("ERROR OBTENER PRESENTACIONES");
            reject(err)
        });    
    });
}
