import React, { useEffect, useState } from 'react'
import ContenedorInput from '../../common/ContenedorInput'
import ContenedorPrincipal from './ContenedorPrincipal'
import { InputText } from 'primereact/inputtext';
import SeccionBotones from '../../common/SeccionBotones';
import { Button } from 'primereact/button';
import { useGlobal } from 'reactn';
import { guardarActividadEmergencia } from '../../../service/Mantenimiento';
import ContenedorHeader from '../../common/ContenedorHeader';
import SeccionFormulario from '../../common/SeccionFormulario';
import { InputTextarea } from 'primereact/inputtextarea';
import { getEmpresas } from '../../../service/Empresas';
import { Dropdown } from 'primereact/dropdown';
import { dateToUnixTimeAsNumber13, DeepClone, getCalendarDate, padTo2Digits } from '../../../util/functions';
import { getInicioDia } from '../../../service/fechas';
import { Message } from 'primereact/message';
import { Calendar } from 'primereact/calendar';

const initialState = {
    nombreTarea: "",
    tiempo_tomado: "",
    comentario: "",
    realizada: true,
    is_emergencia: true,
    categoria_ref: "category_emergency",
    subcategoria_ref: "subcategory_emergency",
    actividad_ref: "emergencia",
    empresa_ref: "",
}


export default function CapturaEmergencia(props) {
    const [state, setState] = useState(initialState)
    const [cicloId,] = useGlobal("ciclo");
    const [dia] = useGlobal("dia")
    const [, setNombreModulo] = useGlobal("nombreModulo")
    const [usuario] = useGlobal("usuario")
    const [empresas] = useGlobal("empresas")
    const [errores, setErrores] = useState({})
    const [, setCargando] = useGlobal("cargando")

    useEffect(() => {
        setNombreModulo("Mantenimiento")
        getEmpresas()
    }, [])
    useEffect(() => {
        if (dia) {
            let copia = { ...state };
            copia.dia = dia.unixTime;
            copia.agente_ref = usuario.uid;
            setState(copia);
        }
    }, [dia])
    useEffect(() => {
        setErrores({})
    }, [state])
    const handleChange = (event) => {
        let copia = { ...state };
        copia[event.target.name] = event.target.value;
        setState(copia);
    }
    const handleGuardar = async () => {
        setCargando(true)
        if (validarErrores()) {
            let data = { ...state, tiempo_tomado: parseInt(state.tiempo_tomado), dia: dia.unixTime * 1000, tiempo_estimado: 0 }
            await guardarActividadEmergencia(cicloId, data);
            props.history.goBack();
        }
        setCargando(false)
    }
    const validarErrores = () => {
        const errores = {}, valoresRequeridos = ["nombreTarea", "tiempo_tomado", "comentario", "empresa_ref"];
        valoresRequeridos.forEach(field => {
            if (!state[field]) { errores[field] = "Este campo es requerido" }
        })
        if (!state?.tiempo_tomado || parseInt(state?.tiempo_tomado) === 0) { errores.tiempo_tomado = "Debes poner un valor mayor a 0" }

        setErrores(errores)
        return Object.keys(errores).length === 0;
    }

    const getMinutes = (tiempo = "0:0") => {
        const [hrs, mins] = tiempo.split(":")
        const minutos = (parseInt(hrs) * 60) + parseInt(mins)
        return `${minutos}`
    }
    const handleCalendarInputChange = (event) => {
        const stateCopy = DeepClone(state)
        stateCopy.tiempo_tomado_timestamp = dateToUnixTimeAsNumber13(event.target.value)
        const date = new Date(event.target.value);
        const hoursAndMinutes = padTo2Digits(date.getHours()) + ':' + padTo2Digits(date.getMinutes());
        stateCopy.tiempo_tomado = getMinutes(hoursAndMinutes)
        setState(stateCopy)
    }

    const esDiaPasado = () => dia?.unixTime < getInicioDia(Date.now());
    const getTiempoValor = (tiempo) => {
        if (!tiempo || tiempo === 1) return getCalendarDate(getInicioDia(Date.now()) * 1000)
        else return getCalendarDate(tiempo)
    }

    return (
        <ContenedorPrincipal>
            <ContenedorHeader titulo="Tarea de emergencia" atras={props.history.goBack} iconos="mtto-icon" />

            <SeccionFormulario>
                <ContenedorInput col="12" label="Empresa">
                    <Dropdown name="empresa_ref" value={state.empresa_ref} options={empresas} onChange={handleChange} optionLabel="nombre" optionValue="id" />
                </ContenedorInput>
                {errores.empresa_ref && <Message severity="error" text={errores.empresa_ref} />}

                <ContenedorInput col="12" label={"Tareas realizadas"}>
                    <InputText value={state.nombreTarea} onChange={handleChange} name="nombreTarea" />
                </ContenedorInput>
                {errores.nombreTarea && <Message severity="error" text={errores.nombreTarea} />}

                <ContenedorInput col="12" label="Tiempo ocupado">
                    <Calendar id="tiempo_tomado" readOnlyInput={false} timeOnly={true} hourFormat="24"
                        name="tiempo_tomado" value={getTiempoValor(state.tiempo_tomado_timestamp)} onChange={handleCalendarInputChange} />
                </ContenedorInput>
                {errores.tiempo_tomado && <Message severity="error" text={errores.tiempo_tomado} />}

                <ContenedorInput col="12" label="Comentario">
                    <InputTextarea value={state.comentario} onChange={handleChange} name="comentario" />
                </ContenedorInput>
                {errores.comentario && <Message severity="error" text={errores.comentario} />}
            </SeccionFormulario>

            <SeccionBotones>
                <Button label="Cancelar" onClick={props.history.goBack} />
                <Button label="Guardar" onClick={handleGuardar} />
            </SeccionBotones>
        </ContenedorPrincipal>
    )
}