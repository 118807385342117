import React,{useState} from 'reactn';
//import {Chart} from "primereact/chart";
import Chart from "react-apexcharts";
const optionsDefault = {
    chart: {
      
      type: 'line',
      zoom: {
        enabled: true,
        type: "x",
        autoScaleYAxis: true
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    title: {
      text: 'Porcentaje de actividades de empleado',
      align: 'left'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    xaxis: {
      categories: [],
    }
}
const LineChart = (props) => {
    const [data, setData] = useState({});
    const [filtrosOrig, setFiltrosOrig] = useState([]);
    

    useState(()=>{
      //window.alert(props.valoresFiltro)
    },[props.valoresFiltro])


    const dummy = (limit) => {
        let result = []
        for (let index = 0; index < limit; index++) {
            result.push(Math.round(100 * Math.random()))
        }
        return result;
    }
    const obtenerDataDummy = () => {
      
      return [{name:"uno",data: dummy(10)},{name:"dos",data: dummy(10)}];

    }
    const obtenerData = () => {
        
        let copia = {...props.data}
        if(props.valoresfiltro && props.valoresfiltro.length> 0){
            
            
            let idsFiltros = props.valoresfiltro.map(el=>el.id);
            
            let datasets = props.data.datasets.filter(el=>{
                return idsFiltros.includes(el.datasetId )
            })
            
            
            
            copia.datasets = datasets;
            
            
            return copia.datasets;
        }else{
            
            
            
            
            return copia.datasets||[];
        }
    }
    
    return <React.Fragment> 
          <div className = "p-col-12">
          </div>
          <div className = {`p-col-12 ${props.filtrar?"chart-container-filters":"chart-container"}`}>
            {//<Chart type = "line" data = {obtenerDataDummy()} />
            } 
            {props.options && props.options.xaxis.categories.length >0?<Chart type = "line" height="100%" series = {obtenerData()} options = {props.options}/>:null}
            
          </div>
        
    </React.Fragment>
}

export default LineChart;//96