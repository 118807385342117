import React, { useGlobal, useState, useEffect } from 'reactn';
import { Button } from 'primereact/button';
import TablaRegistros from "./TablaRegistros";
import { obtenerRegistrosCalidadFinalDia, columnasTablaFinal } from '../../../service/Calidad';
import { obtenerHoraRegistro, obtenerNombreDiaSemana, obtenerNumeroSemana } from '../../../service/fechas';
import { noHaSidoClausurado } from '../../../service/Ciclos';
import { getProductoYVariedadString, getNombrePorID } from '../../../util/functions';
import ContenedorHeader from '../../common/ContenedorHeader';
import icono from '../../../images/icons/external-icon.svg';

const TablaFinal = (props) => {
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [invernadero] = useGlobal("invernadero");
    const [registros, setRegistros] = useState([]);
    const [, setCargando] = useGlobal("cargando");
    const [defectos] = useGlobal("defectos");
    const [ciclo,] = useGlobal("ciclo")
    const [ciclos,] = useGlobal("ciclos")
    const [diaConsultado,] = useGlobal("diaConsultado");
    const [proveedores] = useGlobal("proveedores");
    const [proveedor] = useGlobal("proveedorExternoCalidad");
    const [presentaciones] = useGlobal("presentaciones");
    const [productosYVariedades] = useGlobal("productosYVariedades");
    const [allVariedades] = useGlobal("allVariedades");
    const [colores] = useGlobal("coloresVariedades");

    useEffect(() => {
        setHabilitadoSelectorCiclo(false)
    }, [])
    useEffect(() => {
        setCargando(true)
        if (!diaConsultado){props.history.replace("/calidad/semanal");}
        else if (invernadero){  obtenerDatosIniciales();}
    }, [invernadero])

    const puedeEditar = () => noHaSidoClausurado(ciclo, ciclos) && invernadero && (!invernadero?.es_externo || proveedor?.habilitado) && (invernadero?.habilitado || invernadero?.es_externo);
    const obtenerDatosIniciales = async () => {
        let registros = await obtenerRegistrosCalidadFinalDia(ciclo, invernadero?.id, diaConsultado);
        if (invernadero?.es_externo && proveedor && proveedor.id !== "todos") registros = registros.filter(el => el.proveedor === proveedor.id);

        let procesados = registros.map((registro) => ({
            ...registro,
            id: registro.id,
            momento: obtenerHoraRegistro(registro.momento),
            peso_real_caja: registro.peso_real_caja,
            defectos: registro.defectos.map(el => getNombrePorID(defectos, el.defecto_ref)).join(", ")
        }))
        setRegistros(procesados);
        setCargando(false);
    }

    const getTitulo = () => `Calidad final del dia - ${obtenerNombreDiaSemana(diaConsultado)} - semana ${obtenerNumeroSemana(diaConsultado)}`;
    const columnasTablaFinal = () => {
        const columnas = [
            { nombre: "HORA DE REGISTRO", field: "momento" },
            { nombre: "Presentación", field: "presentacion_ref" },
            { nombre: "PESO CAJA (kg)", field: "peso_real_caja" },
            { nombre: "DEFECTOS", field: "defectos" },
        ];
        if (invernadero.es_externo) { columnas.push({ nombre: "Proveedor", field: "proveedor" }); }
        if (invernadero.mezcla) {
            columnas.push({ nombre: "Producto de presentación", field: "producto_de_presentacion" });
            columnas.push({ nombre: "Variedad", field: "subVariedad_ref" });
        }
        return columnas;
    }
    return (
        <div className="p-grid p-justify-center">
            <ContenedorHeader col="p-col p-col-auto" titulo={getTitulo()} subtituloInv={getProductoYVariedadString(invernadero)}
                invernaderoNombre={invernadero?.es_externo ? <img width="60" alt="icono" src={icono} /> : invernadero?.nombre}
                iconos={`icon-general icon-id bor-${invernadero?.color} bg-${invernadero?.color} ${invernadero?.habilitado ? "" : "Deshabilitado"}`} atras={props.history.goBack} />

            <div className="p-col p-col-auto">
                <div className="btn-row">
                    {puedeEditar() && <Button label="Agregar nuevo registro" onClick={() => props.history.push("/calidad/registro_final/crear")} />}
                </div>
            </div>
            <div className="p-col-12">
                {invernadero &&
                    <TablaRegistros registros={registros} columnas={columnasTablaFinal()} rutaEdicion={`/calidad/registro_final/${invernadero?.id}/modificar`}
                        presentaciones={presentaciones} proveedores={proveedores} productos={productosYVariedades} variedades={allVariedades} colores={colores}/>}
            </div>
        </div>);
}
export default TablaFinal;