import React, { useGlobal, useState, useEffect } from "reactn";
import { noHaSidoClausurado } from "../../service/Ciclos";
import { obtenerInicioSemanaDia, obtenerDiasDeSemana, obtenerIndiceDiaSemana } from "../../service/fechas";
import { Dropdown } from 'primereact/dropdown';
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { registrarEmpaque, obtenerPallets, obtenerConsecutivoPallets } from "../../service/Producto";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { dosDecimales, getProductoYVariedadString } from "../../util/functions";
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { MEDLEYS } from "../../service/constants/ProductosYVariedades.const";

const nombresDias = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];


export default function RegistroEmpaque(props) {
  const [invernadero, setInvernadero] = useGlobal("invernadero");
  const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector();
  const [weekGlobal, setWeekGlobal] = useGlobal("weekGlobal");
  const [invernaderos, setInvernaderos] = useGlobal("invernaderos");
  const [ciclo,] = useGlobal("ciclo");
  const [ciclos,] = useGlobal("ciclos");
  const [dias, setDias] = useState([]);
  const [dia, setDia] = useState();
  const [total, setTotal] = useState(0);
  const [pesoPromedio, setPesoPromedio] = useState(0);
  const [pallets, setPallets] = useState([]);
  const [consecutivo, setConsecutivo] = useState(1);
  const [, setNombreModulo] = useGlobal("nombreModulo");
  const [, setMostrarModalPermisos] = useGlobal("mostrarModalPermiso");
  const [usuario,] = useGlobal("usuario");
  const [, setCargando] = useGlobal("cargando");
  const [variedades, setVariedades] = useState([])
  const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
  const [productosYVariedades] = useGlobal("productosYVariedades");
  const [productosYVariedadesTodos] = useGlobal("productosYVariedadesTodos");
  const [allVariedades] = useGlobal("allVariedades");

  const [cicloOb] = useGlobal("cicloObj");
  const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");

  useEffect(() => {
    setNombreModulo("Empaque");
    setHabilitadoSelectorCiclo(false);
    return () => {
      setHabilitadoSelectorCiclo(true);
    }
  }, [])

  useEffect(() => {
    if (productosYVariedades) {
      obtenerDatosInvernaderos()
    }
  }, [productosYVariedades])

  useEffect(() => {
    if (ciclo) {
      updateCicloWeekSelector(ciclo);
    }
  }, [ciclo])

  useEffect(() => {
    if (week.nombre != "Cargando")
      setWeekGlobal(week);
  }, [week]);

  useEffect(() => {
    let copia = pallets.slice();
    let total = copia.reduce((acc, curr) => {
      let numero = parseInt(curr.cantidad);
      if (isNaN(numero)) {
        return acc;
      } else {
        return acc + numero;
      }
    }, 0);
    setTotal(total);
    let totalCajas = copia.reduce((acc, curr) => {
      let numero = parseInt(curr.cajas);
      if (isNaN(numero)) {
        return acc;
      } else {
        return acc + numero;
      }
    }, 0);
    setPesoPromedio(total / totalCajas);
  }, [pallets])

  useEffect(() => {
    if (dias && dias.length > 0) {
      let today = Math.round(Date.now() / 1000);
      let indice = obtenerIndiceDiaSemana(today);
      setDia(dias[indice]);
    }
  }, [dias])

  useEffect(() => {
    if (invernadero && dia && dias && dias.length > 0) {
      setCargando(true);
      if (invernadero.budget) {
        obtenerPallets(ciclo, invernadero.id, dia.time).then(resp => {
          setPallets(resp.map(el => {
            el.tipo = "REGISTRADO";
            return el;
          }));
        }).catch(err => {
          console.log("NO SE PUDIERON OBTENER EMPAQUES");
        }).finally(el => {
          setCargando(false);
        })
        obtenerConsecutivoPallets(ciclo, invernadero.id).then(resp => {
          setConsecutivo(resp);
        }).catch(err => {
          console.log(err);
        })
      } else {

      }

    }
  }, [dias, dia, invernadero])

  useEffect(() => {
    setDias(
      obtenerDiasDeSemana(week.time).map((dia, index) => {
        return {
          nombre: nombresDias[index],
          time: dia
        }
      })
    )
  }, [week])

  useEffect(() => {
    if (invernadero && productosYVariedadesTodos?.length > 0) {

      let producto = productosYVariedadesTodos.find(el => el.id === invernadero.producto_ref)
      if (producto?.mezcla) {
        //window.alert(JSON.stringify(producto?.variedades) + " -- ")
        setVariedades(producto.variedades)
      }else if(invernadero.MEDLEYS_VIEJO){
        let variedad = producto?.variedades.find(el => el.nombre === MEDLEYS)
        setVariedades([variedad])
      }else {
        let variedad = producto?.variedades.find(el => el.id === invernadero.variedad_ref)
        setVariedades([variedad])
      }
    }
  }, [invernadero, productosYVariedadesTodos])

  const obtenerDatosInvernaderos = async () => {
    const invernaderos = invernaderosVirtuales.filter(inv => !inv.es_externo)
    let productosVariedades = [];

    invernaderos.forEach(invernadero => {
      const producto = productosYVariedadesTodos.find(prod => prod.id === invernadero.producto_ref)
      const variedad = producto?.variedades?.find(vari => vari.id === invernadero.variedad_ref)
      productosVariedades.push({ producto: producto, variedad: variedad });
    })

    invernaderos.forEach((invernadero, index) => {
      invernadero["producto"] = productosVariedades[index]["producto"];
      invernadero["variedad"] = productosVariedades[index]["variedad"];
    })
    setInvernaderos(invernaderos);
    if (!invernadero) {
      setInvernadero(invernaderos[0]);
    }
    if (invernadero === undefined) {
      props.history.push("/producto");
    }
    setDias(
      obtenerDiasDeSemana(week.time).map((dia, index) => {
        return {
          nombre: nombresDias[index],
          time: dia
        }
      })
    )
  }

  const agregarPallet = () => {
    let copia = pallets.slice();
    let cuentaRegistrar = pallets.filter(el => el.tipo === "POR_REGISTRAR").length + 1;
    copia.push({
      id: consecutivo + cuentaRegistrar,
      cantidad: "",
      tipo: "POR_REGISTRAR",
      id_hex: `${invernadero.nombre}_${(consecutivo + cuentaRegistrar)}`

    });
    setPallets(copia);
  }

  const handlePallet = (ev) => {
    let copia = pallets.slice();
    let el = copia.find(pallet => pallet.id == ev.target.id);
    el.cantidad = ev.target.value;


    setPallets(copia);
  }

  const handleCajas = (ev) => {
    let copia = pallets.slice();
    let id = ev.target.id.split("_")[1];
    let el = copia.find(pallet => pallet.id == id);
    el.cajas = ev.target.value;
    setPallets(copia);
  }

  const handleVariedad = (ev) => {
    let copia = pallets.slice();
    let id = ev.target.id.split("_")[1];
    let el = copia.find(pallet => pallet.id == id);
    el.variedad = ev.value;
    setPallets(copia);
  }

  const irEditarBudget = (params) => {
    props.history.push("/producto/editar_budget");
  }

  const cancelar = () => {
    props.history.goBack();
  }

  const atras = (params) => {
    props.history.goBack();
  }

  const eliminarPallet = (id) => {
    let el = pallets.find(el => el.id === id);
    let copia = pallets.filter(el => el.id !== id);
    if (el.tipo === "POR_REGISTRAR") {
      copia.forEach(el => {
        if (el.id > id) {
          el.id--;
        }
      })
    }
    setPallets(copia);
  }

  const guardar = () => {
    setCargando(true)
    registrarEmpaque(ciclo, invernadero.id, dia.time, pallets, consecutivo).then(resp => {
      let inicioSemana = obtenerInicioSemanaDia(dia.time).unix();
      props.history.goBack();
    }).catch(err => {
      if (err.code && err.code === "permission-denied") {
        setMostrarModalPermisos(true);
      }



    }).finally(err => {
      setCargando(false);
    })
  }

  const puedeEditar = (params) => {
    let ahora = Math.round(Date.now() / 1000);
    return noHaSidoClausurado(ciclo, ciclos) && (usuario && (!usuario.bloqueado || dia && dia.time + 7 * 24 * 60 * 60 > ahora)) && invernadero && invernadero.habilitado;
  }

  return (
    <div className="p-grid p-justify-center">
      <div className="p-col p-col-auto title-container">
        <span className="back-btn" onClick={atras}></span>
        {invernadero ?
          <React.Fragment>
            <span className={`icon-general icon-id bor-${invernadero.color} bg-${invernadero.color} ${invernadero.habilitado ? "" : "Deshabilitado"}`}>{invernadero.nombre}</span>

          </React.Fragment>
          : null}
        <span className="titles-group">
          <h1 className="header-title">Registro de pesos diarios </h1>
          <p className="header-subtitle">
            {getProductoYVariedadString(invernadero)}
          </p>
        </span>
      </div>
      <div className="p-col p-col-auto">
        <div className="btn-row">
          {puedeEditar() ? <Button className="reactbtn-icon" onClick={irEditarBudget} label="Editar Budget">
          </Button> : null}
        </div>
      </div>
      <div className="p-col-12">
        <div className="p-grid filtros">
          <div className="form-group p-col">
            <label htmlFor="cinco">Invernadero</label>
            <Dropdown id="filtroInvernadero" placeholder="Seleccionar"
              dataKey="id" optionLabel="nombre"
              options={invernaderos} value={invernadero}
              onChange={(e) => { setInvernadero(e.target.value) }} />
          </div>
          <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
          <div className="form-group p-col">
            <label htmlFor="cinco">Día</label>
            <Dropdown id="filtroDias" placeholder="Seleccionar"
              dataKey="time" optionLabel="nombre"
              options={dias} value={dia}
              onChange={(e) => { setDia(e.target.value) }} />
          </div>
        </div>
      </div>
      <div className="p-col-10">
        <div className="p-grid p-fluid ">
          <div className="p-col-12">
            <p className="section-title">Semana {week.nombre}</p>

            {invernadero && invernadero.budget ? <div className="card">
              {pallets.map(pallet => {
                return <div key={pallet.id} className="form-group p-grid p-fluid pallet-dailyweight">
                  <div className="p-col-auto">
                    <p className="pallet-id">Pallet {pallet.id_hex}</p>
                  </div>
                  <div className="p-col p-grid ">

                    <div className="p-col-auto p-justify-end p-grid p-align-center m-0">
                      <label htmlFor={pallet.id}>Kgs</label>
                    </div>
                    <div className="p-col">
                      <InputText id={pallet.id} placeholder="Kgs en pallet" value={pallet.cantidad} onChange={handlePallet} disabled={!puedeEditar()} />
                    </div>
                  </div>
                  <div className="p-col p-grid ">
                    <div className="p-col-auto p-justify-end p-grid p-align-center m-0">
                      <label htmlFor={`$cajas_{pallet.id}`} >Cajas </label>
                    </div>

                    <div className="p-col">
                      <InputText placeholder="Cantidad de cajas en pallet" id={`cajas_${pallet.id}`} value={pallet.cajas} onChange={handleCajas} disabled={!puedeEditar()} />
                    </div>
                  </div>
                  <div className="p-col p-grid ">
                    <div className="p-col-auto p-justify-end p-grid p-align-center m-0">
                      <label htmlFor={`$cajas_{pallet.id}`} >Variedad </label>
                    </div>

                    <div className="p-col">
                      {variedades.length > 1 ?
                        <Dropdown id={`subvariedad_${pallet.id}`} value={pallet.variedad}
                          onChange={handleVariedad} options={variedades} disabled={!puedeEditar()}
                          dataKey="id" optionLabel="nombre" />
                        : <InputText disabled value={invernadero?.variedad?.nombre} />}
                    </div>
                  </div>

                  {pallet.cajas && <div className="p-col p-grid ">
                    <div className="p-col-auto p-justify-end p-grid p-align-center m-0">
                      <p className="pallet-caja-prom">{dosDecimales(pallet.cantidad / pallet.cajas)}Kg</p>
                      Promedio / caja
                    </div>
                  </div>}

                  {puedeEditar() && <div className="p-col-auto">
                    <button className="p-button" onClick={() => { eliminarPallet(pallet.id) }}>
                      <FontAwesomeIcon icon={faTrashAlt} onClick={() => { eliminarPallet(pallet.id) }} />
                    </button>
                  </div>}

                </div>
              })}
              {puedeEditar() && <Button onClick={agregarPallet} label="+ Agregar nuevo pallet" className="p-button-secondary" />}
              <div className="pallet-totalweight">
                Total: {total} kg - Peso promedio caja: {isNaN(pesoPromedio) || !isFinite(pesoPromedio) ? "-" : dosDecimales(pesoPromedio)}
              </div>
            </div> :
              <div>Este invernadero aún no tiene budget </div>}
          </div>
        </div>

        {puedeEditar() ? <div className="p-grid p-justify-end btn-row">
          <Button label="Cancelar" className="p-button-secondary" onClick={cancelar} />
          <Button label="Guardar" onClick={guardar} />
        </div> : null}
      </div>

    </div>
  );
}//398