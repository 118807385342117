export const tooltipCustomHorasVsActividades = ({ series, seriesIndex, dataPointIndex, w , filtroInvernaderos, selectButtonHorasActividades}) => {
    let seriesCompletas = w.config.series;
    let labels = [];
    filtroInvernaderos.forEach(invernadero => {
        labels.push(`<div class="p-tooltip-text">`)
        labels.push(`<div class="tooltip-title">${invernadero?.nombre}</div>`)
        labels.push(`<div class="tooltip-datas">`)
        if (selectButtonHorasActividades.find((filtro) => filtro.name === "Actividades")) {
            let serie = seriesCompletas.find(el => el.name === `Actividades - ${invernadero?.nombre}`)
            labels.push(`<div class="tooltip-data">${serie?.data?.[dataPointIndex]}%<p class="tooltip-label">Actividades</p></div>`)
        }
        if (selectButtonHorasActividades.find((filtro) => filtro.name === "Horas")) {
            let serie = seriesCompletas.find(el => el.name === `Horas - ${invernadero?.nombre}`)
            labels.push(`<div class="tooltip-data">${serie?.data?.[dataPointIndex]}%<p class="tooltip-label">Horas</p></div>`)
        }
        labels.push(`</div></div>`)
    })
    labels = labels.join("")
    return `<div class = "chart-label"> ${labels}</div>`
}
export const tooltipCustomGraficaCosecha = ({ series, seriesIndex, dataPointIndex, w , filtroInvernaderos, selectButtonTipoDato}) => {
    let seriesCompletas = w.config.series;
    let labels = [];
    filtroInvernaderos.forEach(invernadero => {
        labels.push(`<div class="p-tooltip-text">`)
        labels.push(`<div class="tooltip-title">${invernadero?.nombre}</div>`)
        labels.push(`<div class="tooltip-datas">`)
        if (selectButtonTipoDato.find((filtro) => filtro.name === "Real")) {
            let serie = seriesCompletas.find(el => el.name === `Cajas Real ${invernadero?.nombre}`)
            labels.push(`<div class="tooltip-data">${serie?.data?.[dataPointIndex].y}<p class="tooltip-label">Real</p></div>`)
        }
        if (selectButtonTipoDato.find((filtro) => filtro.name === "Diario")) {
            let serie = seriesCompletas.find(el => el.name === `Cajas Diario ${invernadero?.nombre}`)
            labels.push(`<div class="tooltip-data">${serie?.data?.[dataPointIndex].y}<p class="tooltip-label">Diario</p></div>`)
        }
        if (selectButtonTipoDato.find((filtro) => filtro.name === "Semanal")) {
            let serie = seriesCompletas.find(el => el.name === `Cajas Semanal ${invernadero?.nombre}`)
            labels.push(`<div class="tooltip-data">${serie?.data?.[dataPointIndex].y}<p class="tooltip-label">Semanal</p></div>`)
        }
        if (selectButtonTipoDato.find((filtro) => filtro.name === "RVS")) {
            let serie = seriesCompletas.find(el => el.name === `Real vs Semanal ${invernadero?.nombre}`)
            labels.push(`<div class="tooltip-data">${serie?.data?.[dataPointIndex].y}<p class="tooltip-label">Real vs Semanal</p></div>`)
        }
        if (selectButtonTipoDato.find((filtro) => filtro.name === "DVS")) {
            let serie = seriesCompletas.find(el => el.name === `Diario vs Semanal ${invernadero?.nombre}`)
            labels.push(`<div class="tooltip-data">${serie?.data?.[dataPointIndex].y}<p class="tooltip-label">Diario vs Semanal</p></div>`)
        }
        if (selectButtonTipoDato.find((filtro) => filtro.name === "RVD")) {
            let serie = seriesCompletas.find(el => el.name === `Real vs Diario ${invernadero?.nombre}`)
            labels.push(`<div class="tooltip-data">${serie?.data?.[dataPointIndex].y}<p class="tooltip-label">Real vs Diario</p></div>`)
        }
        labels.push(`</div></div>`)
    })
    labels = labels.join("")
    return `<div class = "chart-label"> ${labels}</div>`
  }