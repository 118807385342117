import { extraccionSimple } from "./dbhelper";
import * as firebase from 'firebase/app';
import "firebase/firestore";
import "firebase/storage";
import { obtenerGruposPresentaciones } from "./QueriesGruposPresentaciones";
import { getFirestoreID } from "./Mantenimiento";
import { uploadStatistics } from './Estadisticas'
import { getGlobal, setGlobal } from "reactn"

function registrarInvocaciones(count) {
    uploadStatistics(count)
}

export function obtenerPresentaciones() {
    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
        db.collection("presentaciones").orderBy("orden").get().then((result) => {
            registrarInvocaciones(result.size)
            let rows = [];
            result.forEach(item => {
                let data = item.data();
                data.id = item.id;
                rows.push(data);
            });
            resolve(rows)
        }).catch((err) => {
            console.log("ERROR OBTENER PRESENTACIONES");
            reject(err)
        });
    });
}
export async function getPresentaciones() {
    if (getGlobal().presentaciones) return getGlobal().presentaciones
    let result = await firebase.firestore().collection("presentaciones").orderBy("orden").get()

    registrarInvocaciones(result.size)
    let presentaciones = result.docs.map(el => ({ id: el.id, ...el.data() }))

    setGlobal({ presentaciones })
    return presentaciones

}
export function obtenerPresentacionesHabilitadas() {
    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
        db.collection("presentaciones").where("habilitado", "==", true).orderBy("orden").get().then((result) => {
            registrarInvocaciones(result.size)
            let rows = [];
            result.forEach(item => {
                let data = item.data();
                data.id = item.id;
                rows.push(data);
            });
            resolve(rows)
        }).catch((err) => {
            console.log(err)
            reject(err)
        });
    });
}
export async function obtenerPresentacionesNoAsignadasAGrupo(presentacionesGrupoActual) {
    const db = firebase.firestore();

    let result = await db.collection("presentaciones").where("habilitado", "==", true).orderBy("orden").get()
    registrarInvocaciones(result.size)
    let gruposPresentaciones = await obtenerGruposPresentaciones();
    let presentacionesAsignadas = [];
    let rows = [];
    result.forEach(item => {
        let data = item.data();
        data.id = item.id;
        rows.push(data);
    });
    gruposPresentaciones.forEach(grupo => {
        presentacionesAsignadas.push(grupo.presentaciones_refs);
    })
    presentacionesAsignadas = presentacionesAsignadas.flat();
    presentacionesAsignadas = presentacionesAsignadas.filter(el => !presentacionesGrupoActual.includes(el))
    let presentaciones = rows.filter(el => !presentacionesAsignadas.includes(el.id))
    return presentaciones

}
export const guardarPresentacion = async (datos, numActualPresentaciones, archivos) => {
    try {
        const db = firebase.firestore();
        let id = datos.id;



        // guardar fotos y recuperar urls

        //agregar campo de orden

        let links = [];
        if (archivos.length > 0) {
            links = await guardarArchivos(archivos);
        }

        if (datos.producto.MEDLEYS_GENERAL) { datos.mezcla = true }
        if (!datos.producto.MEDLEYS_GENERAL && datos.mezcla) { datos.mezcla = false }
        datos["cliente_ref"] = datos["cliente"]["id"];
        datos["producto_ref"] = datos["producto"]["id"];
        datos["orden"] = numActualPresentaciones + 1;
        delete datos["id"];
        delete datos["cliente"];
        delete datos["producto"];
        if (datos["fotos"]) {
            datos["fotos"] = datos["fotos"].concat(links);
        } else {
            datos["fotos"] = links;
        }

        datos["numero_cajas_pallet"] = parseFloat(datos["numero_cajas_pallet"]);
        datos["peso_neto_caja"] = parseFloat(datos["peso_neto_caja"]);
        await db.collection("presentaciones").doc(id).update(datos);
        return true;
        // cambiar datos para solo guardar referencias
        //guardar    
    } catch (error) {
        throw (error);
    }
}
export const registrarPresentacion = async (datos, numActualPresentaciones, archivos) => {
    try {
        const db = firebase.firestore();


        // guardar fotos y recuperar urls

        //agregar campo de orden
        let links = await guardarArchivos(archivos);

        if (datos.producto.MEDLEYS_GENERAL) { datos.mezcla = true }
        else { datos.mezcla = false }

        datos["cliente_ref"] = datos["cliente"]["id"];
        datos["producto_ref"] = datos["producto"]["id"];
        datos["orden"] = numActualPresentaciones + 1;
        delete datos["cliente"];
        delete datos["producto"];
        datos["fotos"] = links;
        datos["numero_cajas_pallet"] = parseFloat(datos["numero_cajas_pallet"]);
        datos["peso_neto_caja"] = parseFloat(datos["peso_neto_caja"]);
        await db.collection("presentaciones").add(datos);
        return true;
        // cambiar datos para solo guardar referencias
        //guardar    
    } catch (error) {
        throw (error);
    }

}
const guardarArchivos = async (archivos) => {
    try {


        const storage = firebase.storage();
        const storageRef = storage.ref();
        var presentacionesRef = storageRef.child("presentaciones");
        let results = [];
        for (let x = 0; x < archivos.length; x++) {


            let arc = archivos[x];
            let name = `${Math.round(Date.now() / 1000)}_${Math.random()}_${arc.name}`;
            const ref = storageRef.child(`presentaciones/${name}`);
            let res = await ref.put(arc);


            results.push(name);
        }
        return results;
    } catch (error) {
        console.log("HUBO UN ERROR AL SUBIR LOS ARCHIVOS");
        console.log(error);
        throw (error);
    }

}
export const obtenerUrlFoto = async (foto) => {


    const storage = firebase.storage();
    const storageRef = storage.ref();
    let url = await storageRef.child(`presentaciones/${foto}`).getDownloadURL();

    return url;
}
export const toggleHabilitacion = (id, habilitado) => {
    const db = firebase.firestore();
    return db.collection("presentaciones").doc(id).update({
        habilitado: !habilitado
    })
}

export const obtenerPresentacion = async (presentacionId) => {
    const db = firebase.firestore();
    const obj = await db.collection("presentaciones").doc(presentacionId).get();
    registrarInvocaciones(obj.size)
    const data = { id: obj.id, ...obj.data() };
    return data
}

export function getCategorias() {
    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
        db.collection("categorias_bono_empaque").get().then((result) => {
            registrarInvocaciones(result.size)
            let rows = [];
            result.forEach(item => {
                let data = item.data();
                data.id = item.id;
                rows.push(data);
            });
            resolve(rows)
        }).catch((err) => {
            console.log("ERROR OBTENER CATEGORIA BONO EMPAQUE");
            reject(err)
        });
    });
}
export async function getCategoriaPresentacion(presentacionID) {
    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
        db.collection("categorias_bono_empaque").where("presentaciones", "array-contains", presentacionID).get().then((result) => {
            let rows = [];
            result.forEach(item => {
                let data = item.data();
                data.id = item.id;
                rows.push(data);
            });
            resolve(result.docs.length > 0 ? rows[0] : {})
        }).catch((err) => {
            console.log("ERROR OBTENER CATEGORIA BONO EMPAQUE");
            reject(err)
        });
    });
}
export function getCategoria(categoriaID) {
    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
        db.collection("categorias_bono_empaque").doc(categoriaID).get().then((result) => {
            registrarInvocaciones(result.size)
            resolve(result.exists ? { id: result.id, ...result.data() } : {})
        }).catch((err) => {
            console.log("ERROR OBTENER CATEGORIA BONO EMPAQUE");
            reject(err)
        });
    });
}

export function guardarCategoria(data, categoriaID = getFirestoreID()) {
    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
        db.collection("categorias_bono_empaque").doc(categoriaID).set(data, { merge: true }).then((result) => {
            resolve(true)
        }).catch((err) => {
            console.log("ERROR OBTENER CATEGORIA BONO EMPAQUE");
            reject(err)
        });
    });
}