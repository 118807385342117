import React,{useEffect, useState} from "react";
import { useGlobal } from 'reactn';
import Chart from "react-apexcharts";
import { obtenerInvernaderosVirtualesHabilitados } from "../../service/Invernaderos";
import { obtenerReportesCosechaCiclo } from '../../service/Cosecha';
import { MultiSelect } from "primereact/multiselect";
import { obtenerCiclo } from "../../service/Ciclos";
import { dosDecimales, stringToColor } from "../../util/functions";
import { obtenerListaDeSemanas } from "../../service/fechas";
const optionsOrig = {
    chart: {
      type: 'area',
      height: 350
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    
    title: {
      text: 'Comparaciones de cosecha en invernaderos',
      align: 'left',
      style: {
        fontSize: '14px'
      }
    },
    xaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      tickAmount: 4,
      floating: false,
      title:{
        text:"Porcentajes - 100 %"
      },
      labels: {
        style: {
          colors: '#8e8da4',
        },
        offsetY: -7,
        offsetX: 0,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false
      }
    },
    fill: {
      opacity: 0.5
    },
    tooltip: {
      x: {
        format: "yyyy",
      },
      fixed: {
        enabled: false,
        position: 'topRight'
      }
    },
    grid: {
      yaxis: {
        lines: {
          offsetX: -30
        }
      },
      padding: {
        left: 20
      }
    }
  }
const CosechaFiltroInvernadero = (props) => {
    const [ciclo, ] = useGlobal("ciclo");
    const [filtros, setFiltros] = useState([]);
    const [data, setData] = useState([]);
    const [filtrosOrig, setFiltrosOrig] = useState([{label:"Real vs Semanal", id:"sr"},{label:" Diario vs Semanal",id:"sd"},{label:" Real vs Diario",id:"dr"}]);
    const [options,setOptions] = useState({...optionsOrig});
    const [invernaderos, setInvernaderos] = useState([]);
    useEffect(() => {
        if(ciclo){
            obtenerDatosIniciales();
        }
        
        
    }, [ciclo]);
    const obtenerDatosIniciales = async (params) => {
        try {
            // obtener invernaderos
        let invernaderos = await obtenerInvernaderosVirtualesHabilitados(ciclo);
        setInvernaderos(invernaderos);
        // setear filtros con invernaderos
        //setFiltros(invernaderos);
        //setFiltrosOrig(invernaderos);
        // obtener porcentajes de cada semana en ciclo por cada invernadero
        let cicloObj = await obtenerCiclo(ciclo);
        let reportesCosechaCiclo = await obtenerReportesCosechaCiclo(cicloObj);
        
        
        

        setData(procesar(reportesCosechaCiclo,invernaderos,cicloObj));    
        } catch (error) {
            console.log("ERROR OBTENER DATOS INICIALES");
            console.log(error);
        }
        
    }    
    
    const procesar = (reportes,invernaderos, cicloObj) => {
        let semanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
        let labels = semanas.map(el=>{
            return el.nombre;
        })
        let copiaOptions ={...options};
        let colors = [];
        
            invernaderos.forEach(inv => {
                for (let index = 0; index < 3; index++) {
                    colors.push(stringToColor(inv.color));
                }
            })                
        
        
        
        
        copiaOptions["colors"] = colors;
        setOptions(copiaOptions);
        let data = [];
        invernaderos.forEach(invernadero => {
            let semanalReal = {idTipo:"sr",idInvernadero: invernadero.id, name: `Real vs Semanal ${invernadero.nombre}`, data:[]};
            let semanalDiario = {idTipo:"sd",idInvernadero: invernadero.id, name: ` Diario vs Semanal ${invernadero.nombre}`, data:[]};
            let diarioReal = {idTipo:"dr",idInvernadero: invernadero.id, name: `Real vs Diario${invernadero.nombre}`, data: []};

            reportes.forEach((semana,index) => {
                    let el = semana.find(inv=>invernadero.id === inv[0].id);
                    if(el){
                        let [invernadero,semana, cajas,kilos,kg_m2,resumenEmpaque]= el;
                        let temp = {};
                        temp["porcentaje_semanal_real"] = cajas["semanal"] == 0?0: dosDecimales((100 *  cajas["real"]/cajas["semanal"])- 100) ;
                        temp["porcentaje_semanal_diario"] = cajas["semanal"] == 0?0: dosDecimales((100 *  cajas["diario"]/cajas["semanal"]) - 100 ) ;
                        temp["porcentaje_diario_real"] = cajas["diario"] == 0?0: dosDecimales( (100 *  cajas["real"]/cajas["diario"]) - 100  ) ;
                        semanalReal["data"].push({x:labels[index], y: temp["porcentaje_semanal_real"]});
                        semanalDiario["data"].push({x:labels[index], y: temp["porcentaje_semanal_diario"]});
                        diarioReal["data"].push({x:labels[index], y: temp["porcentaje_diario_real"]})
                    }else{
                        
                        

                        semanalReal["data"].push({x:labels[index], y: 0});
                        semanalDiario["data"].push({x:labels[index], y: 0});
                        diarioReal["data"].push({x:labels[index], y: 0})
                    }
                    
            });
            data.push(semanalReal);
            data.push(semanalDiario);
            data.push(diarioReal);
        })
        
        
        
        
        return data;
    }
    const obtenerOptions = (params) => {
        let copia = {...optionsOrig};
        let colors = [];
        if(props.filtros.length === 0){
             colors = invernaderos.map(inv=>{
                return props.filtrosOrig.map(filtro=>{
                    return stringToColor(inv.color);
                })
            }).flat();

        }else{
             colors = invernaderos.map(inv=>{
                return props.filtros.map(filtro=>{
                    return stringToColor(inv.color);
                })
            }).flat();
        }
        
        
        
        
        
        copia.colors = colors;
        return copia;
    }
    
        const obtenerData = () => {
            let filtrados = data.slice();
            if(props.filtros.length===0){
                
                
                return [];
            }

            
            let idsFiltros = props.filtros.map(el=>el.id);
            
            
            
                filtrados = filtrados.filter(el=>idsFiltros.includes(el.idTipo));
            
            
            
            return filtrados;
        }
        
        return <Chart options = {obtenerOptions()} series = {obtenerData()} type = "area" height="100%"/>
        
}
export default CosechaFiltroInvernadero;