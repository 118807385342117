import React from 'react'
import ContenedorHeader from '../common/ContenedorHeader'
import ContenedorPrincipal from '../common/ContenedorPrincipal'
import SeccionFiltros from '../common/SeccionFiltros'
import ContenedorFiltro from '../common/ContenedorFiltro'
import { Dropdown } from 'primereact/dropdown'
import { useEffect, useGlobal, useState } from 'reactn'
import { obtenerGruposPresentaciones, obtenerBudgetGrupoPresentaciones, guardarBudgetPresentaciones } from '../../service/QueriesGruposPresentaciones'
import SeccionFormulario from '../common/SeccionFormulario'
import ContenedorInput from '../common/ContenedorInput'
import SeccionBotones from '../common/SeccionBotones'
import { obtenerCiclo } from '../../service/Ciclos'
import { obtenerListaDeSemanas } from '../../service/fechas'
import { InputText } from 'primereact/inputtext'
import { Message } from 'primereact/message'
import { Button } from 'primereact/button'

export default function BudgetPresentaciones(props) {
    const [cicloId, setCicloId] = useGlobal("ciclo");
    const [cicloObj] = useGlobal("cicloObj");
    const [usuario] = useGlobal("usuario");
    const [gruposPresentaciones, setGruposPresentaciones] = useState([])
    const [grupoPresentaciones, setGrupoPresentaciones] = useState()
    const [semanasCiclo, setSemanasCiclo] = useState([]);
    const [inicio, setInicio] = useState()
    const [final, setFinal] = useState()
    const [budget, setBudget] = useState([]);
    const [error, setError] = useState(false);
    const [nombreModulo, setNombreModulo] = useGlobal("nombreModulo");
    useEffect(() => {
        setNombreModulo("Formulario Budget Presentaciones");
    }, [])
    useEffect(() => {
        obtenerDatosIniciales();

    }, [])
    useEffect(() => {
        if (cicloObj) {
            obtenerDatosInicialesCiclo();
        }
    }, [cicloObj])
    useEffect(() => {
        if (grupoPresentaciones) {
            updateBudget()
        }
    }, [grupoPresentaciones])
    const updateBudget = async (params) => {
        let budget = await obtenerBudgetGrupoPresentaciones(cicloId, grupoPresentaciones.id);


        if (!budget.empty) {
            let semanaInicio = semanasCiclo.find(el => el.time === budget.inicial)
            let semanaFinal = semanasCiclo.find(el => el.time === budget.final)
            setBudget(budget.budget);
            setInicio(semanaInicio);
            setFinal(semanaFinal);
        }
    }

    const obtenerDatosInicialesCiclo = async (params) => {
        let semanas = await obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
        setBudget(semanas.map(week => {
            return {
                time: week.time,
                nombre: week.nombre,
                value: ""
            }
        }));
        setSemanasCiclo(semanas);
    }

    const obtenerDatosIniciales = async (params) => {
        let gruposPresentaciones = await obtenerGruposPresentaciones();
        setGruposPresentaciones(gruposPresentaciones);
    }
    const guardar = async () => {
        try {
            let result = await guardarBudgetPresentaciones(cicloId, grupoPresentaciones.id, inicio, final, budget);
            props.history.goBack()
        } catch (error) {
            throw error;
        }
    }


    function handleInicio(ev) {
        setInicio(ev.value);
    }
    function handleFinal(ev) {
        setFinal(ev.value);
    }
    function handleBudget(ev, index) {
        let copia = budget.slice();
        copia[index].value = ev.target.value;
        setBudget(copia);
    }
    useEffect(() => {



        if (cicloId && inicio) {
            if (final) {

                setBudget(semanasCiclo.filter(el => inicio.time <= el.time && final.time >= el.time).map(week => {
                    let semana = budget.find(el => el.time === week.time);
                    if (semana && semana.value !== "") {
                        return semana
                    } else {
                        return {
                            time: week.time,
                            nombre: week.nombre,
                            value: ""
                        }
                    }

                }));


            } else {


                setBudget(semanasCiclo.filter(el => inicio.time <= el.time).map(week => {
                    let semana = budget.find(el => el.time === week.time);
                    if (semana && semana.value !== "") {
                        return semana
                    } else {
                        return {
                            time: week.time,
                            nombre: week.nombre,
                            value: ""
                        }
                    }
                }));


            }




            //

        } else {
            if (cicloId && final) {


                setBudget(semanasCiclo.filter(el => final.time >= el.time).map(week => {
                    let semana = budget.find(el => el.time === week.time);
                    if (semana && semana.value !== "") {
                        return semana
                    } else {
                        return {
                            time: week.time,
                            nombre: week.nombre,
                            value: ""
                        }
                    }
                }));


            }
        }
    }, [inicio, final])

    const puedeEditar = () => {
        if (!["Personal Administrativo"].includes(usuario?.rol)) { return true; }
        else return false;
    }
    return (
        <ContenedorPrincipal >
            <ContenedorHeader titulo="Budget de presentaciones" iconos="presentacion-icon" />
            <SeccionFiltros extra="p-justify-center">
                <SeccionFormulario col="p-col-10">
                    <ContenedorFiltro label="Grupo de Presentaciones" col="12" sinFiltrarTexto={true}>
                        <Dropdown options={gruposPresentaciones} value={grupoPresentaciones} onChange={e => setGrupoPresentaciones(e.value)}
                            optionLabel="nombre" dataKey="id" filter={true} />
                    </ContenedorFiltro>
                    <ContenedorInput label="Semana de inicio">
                        <Dropdown id="inicio" placeholder="Seleccionar"
                            options={semanasCiclo} optionLabel="nombre"
                            dataKey="time" onChange={handleInicio}
                            value={inicio} disabled={!puedeEditar()}/>
                    </ContenedorInput>
                    <ContenedorInput label="Semana de cierre">
                        <Dropdown id="inicio" placeholder="Seleccionar"
                            options={semanasCiclo} optionLabel="nombre"
                            dataKey="time" onChange={handleFinal}
                            value={final} disabled={!puedeEditar()}/>
                    </ContenedorInput>
                </SeccionFormulario>
                <SeccionFormulario titulo="Budget" col="p-col-10">
                    {budget.map((week, index) => {
                        return <div key={index} className="form-group p-grid p-fluid pallet-dailyweight p-col-12">
                            <div className="p-col-auto p-justify-end p-grid p-align-center m-0">
                                <label htmlFor={`budget_${week.time}`}>Semana {week.nombre}</label>
                            </div>
                            <div className="p-col">
                                <InputText id={week.time} value={budget[index].value} onChange={(ev) => handleBudget(ev, index)} disabled={!puedeEditar()}/>
                            </div>
                            <div className="p-col-auto p-justify-end p-grid p-align-center m-0">
                                <label >$/lb</label>
                            </div>
                        </div>
                    })}
                    {error ? <Message severity="error" text="Deben llenarse todos los campos" /> : null}
                </SeccionFormulario>
            </SeccionFiltros>
            <SeccionBotones col="p-col-11">
                <Button label="Guardar" onClick={guardar} disabled={!puedeEditar()}/>
            </SeccionBotones>
        </ContenedorPrincipal>
    )
}
//217