import React from 'react'
const SeccionFormulario = (props) =>
    <div className={props.col ? props.col : "p-col-12"}>
        <p className="section-title">{props.titulo}</p>
        <div className="card">
            <div className="p-grid ">
                {props.children}
            </div>
        </div>
    </div>;
export default SeccionFormulario;