import React, { useState, useGlobal, useEffect } from 'reactn';
import { dosDecimales } from '../../util/functions';
import HTGCard from '../common/cards/HTGCard';
import DatoCard from '../common/cards/DatoCard';

const InvernaderoCard = (props) => {
    const header = <span className="card-header">
        <div className={`card-id icon-general icon-id bor-${props.invernadero.color} bg-${props.invernadero.color}`}>{props.invernadero.nombre}</div>
        <div><p className="card-up">{""}</p>
            <p className="card-down">{""}</p></div></span>

    const obtenerTotalClaros = (invernadero) => {
        let totalClaros = 0
        invernadero.capillas.forEach(capilla => {
            const clarosNorte = capilla.lineas_norte * invernadero.claros_por_linea_norte
            const clarosSur = capilla.lineas_sur * invernadero.claros_por_linea_sur
            totalClaros += (clarosNorte + clarosSur)
        })
        return totalClaros
    }
    return (
        <HTGCard headerClassName={`htg-card bor-${props.invernadero.color} Habilitado`} header={header} onClick={props.onClick}>
            <DatoCard col="3" label="Claros evaluados" valor={`${(isNaN(props.info.totalClarosEvaluados) || !props.info?.totalClarosEvaluados) ? "0" : (dosDecimales(props.info.totalClarosEvaluados) + "/" + obtenerTotalClaros(props.invernadero))}`} />
            <DatoCard col="3" label="Evaluado" valor={`${isNaN(props.info.totalClarosEvaluados) ? "0" : dosDecimales((props.info.totalClarosEvaluados) / obtenerTotalClaros(props.invernadero) * 100)}%`} />
            <DatoCard col="3" label="Actividades" valor={`${isNaN(props.info.actividadesUnicos) ? "0" : dosDecimales(props.info.actividadesUnicos)}`} />
            <DatoCard col="3" label="Pefecto" valor={`${isNaN(props.info.perfecto) ? "-" : dosDecimales(props.info.perfecto)}%`} />
            <DatoCard col="3" label="Bajo" valor={`${isNaN(props.info.bajo) ? "-" : dosDecimales(props.info.bajo)}%`} />
            <DatoCard col="3" label="Medio" valor={`${isNaN(props.info.medio) ? "-" : dosDecimales(props.info.medio)}%`} />
            <DatoCard col="3" label="Alto" valor={`${isNaN(props.info.alto) ? "-" : dosDecimales(props.info.alto)}%`} />
            <DatoCard col="3" label="Grave" valor={`${isNaN(props.info.grave) ? "-" : dosDecimales(props.info.grave)}%`} />
        </HTGCard>);
}
export default InvernaderoCard;