import React, { useState, useEffect } from 'reactn';
import { useGlobal } from "reactn";
import { Button } from 'primereact/button';
import InvernaderoCard from "./InvernaderoCard";
import InvernaderoExternoCard from "./InvernaderoExternoCard";
import { obtenerPesoFrutoFinal, obtenerDefectosFinal, obtenerFiltrosCalidadTodos } from '../../service/Calidad';
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { obtenerPesoPromedioCajaSemana } from '../../service/Producto';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { getObjetoPorID, DeepClone, getProductoYVariedadString } from '../../util/functions';
import ContenedorHeader from 'components/common/ContenedorHeader';
import RowBotonesTopThin from 'components/common/RowBotonesTopThin';

const Invernaderos = (props) => {
  const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
  const [productosYVariedades] = useGlobal("productosYVariedades")
  const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
  const [ciclo,] = useGlobal("ciclo");
  const [, setNombreModulo] = useGlobal("nombreModulo");
  const [usuario,] = useGlobal("usuario");
  const [, setCargando] = useGlobal("cargando");
  const [, setMostrarModalPermiso] = useGlobal("mostrarModalPermiso");
  const [datosCargados] = useGlobal("datosCargadosCalidad");
  const [invernaderosFisicos] = useGlobal("invernaderosFisicos");
  const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector();
  const [invernaderosCardsInfo, setInvernaderosCardsInfo] = useState([]);

  useEffect(() => {
    setNombreModulo("Calidad");
    setHabilitadoSelectorCiclo(true)
  }, [])
  useEffect(() => {
    if (invernaderosVirtuales) updateCicloWeekSelector(ciclo);
  }, [invernaderosVirtuales])
  useEffect(() => {
    setCargando(true)
    if (!datosCargados && week.nombre != "Cargando") obtenerFiltrosCalidadTodos(ciclo)
    else if (datosCargados) obtenerDatosCards();
  }, [week, datosCargados])

  const obtenerDatosCards = async () => {
    try {
      let promises = invernaderosVirtuales.map(invernadero => obtenerResumen(DeepClone(invernadero)))
      let invernaderosCardsInfo = await Promise.all(promises)

      setInvernaderosCardsInfo(invernaderosCardsInfo)
    } catch (err) {
      setMostrarModalPermiso(err?.code === "permission-denied")
    }
    setCargando(false);
  }

  const obtenerResumen = async (inv) => {
    let fisicoRef = inv.invernadero_fisico_ref
    let invernaderoFisico = inv.es_externo ? {} : getObjetoPorID(invernaderosFisicos, fisicoRef)

    let productoYVariedadString = getProductoYVariedadString(inv)
    let producto = getObjetoPorID(productosYVariedades, inv.producto_ref);
    let invernadero = DeepClone(inv)
    let promises = [obtenerPesoPromedioCajaSemana(ciclo, inv.id, week.time), obtenerPesoFrutoFinal(ciclo, inv.id, week.time), obtenerDefectosFinal(ciclo, inv.id, week.time, producto.tipo_calidad_inicial)]

    const [pesoCaja, pesoFruto, defectos] = await Promise.all(promises)
    return { invernadero, invernaderoFisico, pesoCaja, pesoFruto, defectos, productoYVariedadString }
  }

  const puedeConsultar = () => ["Administrador", "Administrador Calidad", "Auxiliar Calidad", "Auxiliar Grower", "Administrador Inocuidad", "Administrador Empaque", "Grower"].includes(usuario.rol)
  const irReportePesosDiarios = () => { props.history.push("/calidad/reporte_general_pesos_diarios") }
  const irReporteDefectos = () => { props.history.push("/calidad/reporte_general_defectos") }
  const irGraficaPesos = () => { props.history.push("/calidad/graficas/reporte_pesos_calidad") }
  const irGraficaEmpacadoras = () => { props.history.push("/nomina_empaque/graficas/empaque_empacadora") }

  return (//102
    <div className="p-grid">
      <ContenedorHeader col="p-col p-col-auto" titulo="Resumen de calidad final" iconos="quality-icon" />

      {puedeConsultar() &&
        <RowBotonesTopThin>
          <Button label="Reporte de defectos" onClick={irReporteDefectos} />
          <Button label="Reporte de pesos diarios" onClick={irReportePesosDiarios} />
          <Button label="Ver gráfica pesos" onClick={irGraficaPesos} className="reactbtn-icon">
            <FontAwesomeIcon icon={faChartBar} />
          </Button>
          <Button onClick={irGraficaEmpacadoras} label="Ver gráfica empacadoras" className="reactbtn-icon">
            <FontAwesomeIcon icon={faChartBar} />
          </Button>
        </RowBotonesTopThin>}

      <div className="p-col-12">
        <div className="p-grid filtros">
          <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
        </div>
      </div>

      <div className="p-col-12">
        <div className="p-grid">
          {invernaderosCardsInfo.map(info => info.invernadero.es_externo
            ? <InvernaderoExternoCard goToCards={true} key={info.invernadero.id} info={info} invernadero={info.invernadero} />
            : <InvernaderoCard key={info.invernadero.id + "-calidad"} info={info} invernadero={info.invernadero} />
          )}
        </div>
      </div>
    </div>)
}
export default Invernaderos;//124