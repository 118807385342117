import React from 'react';
import DatoCard from '../common/cards/DatoCard';
import HTGCard from '../common/cards/HTGCard';
import CardHeader from '../common/cards/CardHeader';
import ClipLoader from 'react-spinners/ClipLoader';

function InvernaderoCard(props) {
  //Creo que aun se puede generalizar el header para que vaya incluido en htgcard
  const header = <CardHeader
    invernaderoVirtual={props.invernadero}
    nombreInvernaderoFisico={props.invernadero?.invernaderoFisico || ""} />
  return (
    <HTGCard header={header} headerClassName={`htg-card bor-${props.invernadero?.color} ${props.invernadero?.status}`} onClick={props.onClick}>
      {props.home && props.cargandoHome
        ? <div className="card-spinner-centered">
          <ClipLoader sizeUnit="px" size={100} color="#003E66" loading={true} />
        </div>
        : <>
          <DatoCard valor={props.invernadero?.empleados} label="EMPLEADOS" />
          <DatoCard valor={props.invernadero?.activos} label="ACTIVOS" />
          <DatoCard valor={`${Math.round(100 * props.invernadero?.rotacion)} %`} label="ACTIVOS VS SEMANA ANTERIOR" />
          <DatoCard valor={`${Math.round(100 * props.invernadero?.interseccion)} %`} label="% ESTABILIDAD VS SEMANA ANTERIOR" />
        </>}
    </HTGCard>);
}
export default InvernaderoCard;