import React, { useState, useEffect } from 'react';
import { useGlobal } from 'reactn';
import { withRouter } from 'react-router-dom';
import { dosDecimales, getNombrePorID, getObjetoPorID, makeCssClass, separadoComas } from '../../../util/functions';
import { Button } from 'primereact/button';
import CardProducto from './CardProducto';
import { cargarExcel, obtenerCajasPorEmpleado, obtenerEmpleadosEmpaqueConIncidencias } from '../../../service/NominaEmpaque';
import ModalMensaje from '../../common/ModalMensaje';
import ModalArchivos from './ModalArchivos';
import { obtenerArchivosCargados } from '../../../service/NominaEmpaqueQueries';
import { obtenerEmpacadoras } from '../../../service/Empacadoras';

const CardSitioEmpaque = (props) => {
    const [ciclo] = useGlobal("ciclo");
    const [week] = useGlobal("week")
    const [usuario] = useGlobal("usuario");
    const [, setCargando] = useGlobal("cargando")
    const [, setPresetProducto] = useGlobal("presetProducto");
    const [empaques, setEmpaques] = useGlobal("empaques")
    const [presentaciones] = useGlobal("presentaciones")
    const [productosBases] = useGlobal("productosBases")
    const [medleysGeneral] = useGlobal("medleysGeneral")

    const [showModalArchivoCargado, setShowModalArchivoCargado] = useState("");
    const [showModalVerArchivos, setShowModalVerArchivos] = useState(false);
    const [archivos, setArchivos] = useState(null)
    const [sitioNombre, setSitioNombre] = useState([])
    const [empleadoCajasDatos, setEmpleadoCajasDatos] = useState({})
    const [, setEmpacadoras] = useGlobal("empacadoras")
    const [, setEmpleados] = useGlobal("empacadoras")
    const [porcentajeHorasTrabajadas, setPorcentajeHorasTrabajadas] = useState(0)
    const hiddenFileInput = React.useRef(null);

    useEffect(() => {
        if (week.nombre !== "Cargando" && presentaciones?.length > 0 && props.empaque?.empaque_ref) { obtenerDatosIniciales(); }
    }, [week, presentaciones, props.empaque])

    const puedeEditar = () => {
        const rolsABloquear = ["Personal Administrativo"];
        if (rolsABloquear.includes(usuario?.rol)) { return false; }
        else return true;
    }

    const obtenerDatosIniciales = async () => {
        setCargando(true)
        const empacadoras = await obtenerEmpacadoras();
        let empleados = await obtenerEmpleadosEmpaqueConIncidencias(ciclo, props.empaque?.empaque_ref, week.time, empacadoras);
        //console.log("EMPLEADOS: ",empleados)

        setEmpacadoras(empacadoras)
        setEmpleados(empleados)

        obtenerHorasTrabajadas(empleados)
        await obtenerDatosArchivos();
    }

    const obtenerHorasTrabajadas = (empleadosConIncidencia) => {
        let totalHorasTrabajadas = 0, totalHorasSinFaltas = 0;
        const horasDelDia = 8;
        for (const index in empleadosConIncidencia) {
            const empleado = empleadosConIncidencia[index];

            for (const dia in empleado.incidencias.dias) {
                const tipoDia = empleado.incidencias.dias[dia]
                if (tipoDia !== "falta") totalHorasSinFaltas += horasDelDia;
                totalHorasTrabajadas += horasDelDia
            }
        }
        const porcentajeSinFalta = (totalHorasSinFaltas / totalHorasTrabajadas) * 100;
        //console.log("Horas trabajadas datos: ",{totalHorasSinFaltas, totalHorasTrabajadas, porcentajeSinFalta})
        const porcentajeHoras = isNaN(porcentajeSinFalta) ? "-" : dosDecimales(porcentajeSinFalta);
        setPorcentajeHorasTrabajadas(porcentajeHoras);
    }

    const obtenerDatosArchivos = async () => {
        setCargando(true)
        const empaqueID = props.empaque?.id
        const empaqueNombre = getNombrePorID(empaques, empaqueID)
        const archivosRes = await obtenerArchivosCargados(ciclo, week.time, empaqueID);

        setSitioNombre(empaqueNombre.includes("Agropark") ? "Agropark" : empaqueNombre);
        setArchivos(archivosRes);
        const cajasPorEmpleado = obtenerCajasPorEmpleado(archivosRes, medleysGeneral, props.empaque.productos_relacionados);
        setEmpleadoCajasDatos(cajasPorEmpleado);
        setCargando(false)
    }

    const irArchivos = (e, empaque, producto) => {
        e.stopPropagation();
        setShowModalVerArchivos(true)
    }

    const handleClick = () => { hiddenFileInput.current.click(); }
    const readFile = async (excel) => {
        if (excel?.name) {
            setCargando(true);
            const result = await cargarExcel(ciclo, week.time, excel.name, excel, props.empaque?.id);

            if (result?.success) setShowModalArchivoCargado("El archivo se cargó con éxito.");
            else setShowModalArchivoCargado("Hubo un error, por favor intente de nuevo.");
            obtenerDatosArchivos()
            setCargando(false);
        }
    }

    return (
        <div className="p-col-12">
            <div className={`p-card p-component  htg-card bor-${makeCssClass(props.empaque.nombre)} `}>

                <div className="pr-2 pl-2">
                    <div className="p-grid  text-center">
                        <div className="p-col-3">
                            <div className="card-block">
                                <p className="empaque-name">{props.empaque.nombre}</p>
                            </div>
                        </div>
                        <div className="p-col-3">
                            <div className="card-block">
                                <p className="value">{separadoComas(dosDecimales(props.empaque.empleados))}</p>
                                <p className="label">EMPLEADOS</p>
                            </div>
                        </div>
                        <div className="p-col-3">
                            <div className="card-block">
                                <p className="value">{porcentajeHorasTrabajadas} %</p>
                                <p className="label">% HORAS TRABAJADAS</p>
                            </div>
                        </div>
                    </div>

                    <div className="p-grid">
                        {(props.empaque.productos_relacionados && props.productos.length > 0) ? props.empaque.productos_relacionados.map(productoR => {
                            const producto = props.productos?.find(el => el.id === productoR) || {};
                            return <CardProducto key={`${producto?.id}-${props.empaque?.id}`} producto={producto} empaque={props.empaque} empleadoCajasDatos={empleadoCajasDatos} />
                        }) : "No hay productos relacionados"}
                    </div>

                    <div className="p-grid  text-center">
                        <div className="card-block d-block">
                            <div className="btn-row ">
                                <Button className="mr-05" label="Ver archivos cargados" onClick={(e) => { irArchivos(e, props.empaque, props.producto) }} />
                                <Button className="ml-05" disabled={!puedeEditar()} label="Agregar cajas de empleados" onClick={handleClick} />
                                <input type="file" ref={hiddenFileInput} onChange={(e) => readFile(e.target.files[0])} style={{ display: 'none' }} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <ModalMensaje header={"Cajas de empleados"} mensaje={showModalArchivoCargado} show={!!showModalArchivoCargado} onHide={() => { setShowModalArchivoCargado("") }} />
            <ModalArchivos sitioNombre={sitioNombre} visible={showModalVerArchivos} archivos={archivos} obtenerDatosArchivos={() => obtenerDatosArchivos(props.empaque?.id)} onShow={() => { setShowModalVerArchivos(true) }} onHide={() => { setShowModalVerArchivos(false) }} />
        </div>)
}
export default withRouter(CardSitioEmpaque);