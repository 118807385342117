import React from 'react'

const ContenedorGenerico = ({className="", col, md="4", sm="6", children}) => {
    const colClass = col ? `p-col-${col}` : "p-col"
  return (
    <div className={`${colClass} p-md-${md} p-sm-${sm} ${className}`}>
        {children}
    </div>
  )
}

export default ContenedorGenerico