import React, { useState, useEffect, useGlobal } from 'reactn'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { MultiSelect } from 'primereact/multiselect';
import ContenedorInputNoCard from '../../common/ContenedorInputNoCard';
import { DeepClone, getObjetoPorID, isThereGreenhouseInTheProducer } from 'util/functions';
import { guardarManifiesto } from 'service/QueriesManifiestos';

const ModalAsignacionItems = (props) => {
    const [ciclo] = useGlobal("ciclo");
    const [, setCargando] = useGlobal("cargando");
    const [orden] = useGlobal("ordenVenta");
    const [presentaciones] = useGlobal("presentaciones")
    const [invernaderos] = useGlobal("invernaderosVirtuales")
    const [invernaderosFisicos] = useGlobal("invernaderosFisicos");
    const [empresas] = useGlobal("empresas")
    const [itemsAsignados, setItemsAsignados] = useState([]);
    const [itemsOriginales, setItemsOriginales] = useState([]);
    const [errores, setErrores] = useState({});
    const [, setShowGuardadoToast] = useGlobal("showGuardadoToast");

    useEffect(() => {
        if (orden.items && invernaderos.length > 0 && props.manifiesto) { getItemsOriginales(); }
    }, [orden.items, invernaderosFisicos, invernaderos, props.manifiesto])
    useEffect(() => {
        if (props.manifiesto && props.manifiesto.items) { setItemsAsignados(props.manifiesto.items); }
    }, [props.manifiesto])
    useEffect(() => {
        setErrores({});
    }, [props.manifiesto, itemsAsignados, orden.items])

    const getItemsOriginales = () => {
        let copiaItems = [], ids = new Set();
        orden.items.forEach((currentItem) => {
            const id = `${currentItem.invernadero_ref}-${currentItem.presentacion_ref}`;
            const temp = { ...currentItem };
            temp.id = id;
            temp.cajas_orden = 0;
            if (!ids.has(id)) {
                copiaItems.push(temp);
                ids.add(id);
            }
        })
        copiaItems = copiaItems.sort((a, b) => {
            const invernaderoA = invernaderos.find(el => el.id === a.invernadero_ref);
            const invernaderoB = invernaderos.find(el => el.id === b.invernadero_ref);
            if (invernaderoA.es_externo) { return invernaderoB.es_externo ? 0 : 1; }
            else if (invernaderoB.es_externo) { return 1; }
            else {
                const invernaderoFisicoA = invernaderosFisicos.find(el => el.id === invernaderoA.invernadero_fisico_ref);
                const invernaderoFisicoB = invernaderosFisicos.find(el => el.id === invernaderoB.invernadero_fisico_ref);
                return invernaderoFisicoA.empresa_ref === props.manifiesto.productor_ref ? -1 : invernaderoFisicoB.empresa_ref - invernaderoFisicoA.empresa_ref;
            }
        })
        const productorId = props.manifiesto.productor_ref
        
        const newItemsFiltradosPorEmpresaInterna = copiaItems.filter(item => {
            const empresa = isThereGreenhouseInTheProducer(item.invernadero_ref, invernaderosFisicos, invernaderos, empresas)
            return empresa.id === productorId
        })

        setItemsOriginales(newItemsFiltradosPorEmpresaInterna);
    }

    const handleItemsAsignados = (newValue) => {
        newValue.forEach((curr) => {
            let found = itemsAsignados.find(el => el.presentacion_ref === curr.presentacion_ref);
            if (!found) curr.cajas_orden = calcularDisponibles(curr.presentacion_ref);
        })
        setItemsAsignados(newValue)
    }
    const calcularDisponibles = (presentacionId) => {
        let suma = 0;
        if (orden?.items) {
            orden.items.forEach(item => {
                if (item.presentacion_ref === presentacionId && item.invernadero_ref) { suma += parseInt(item.cajas_orden || 0); }
            })
            props.manifiestos.forEach(manifiesto => {
                manifiesto.items.forEach(item => {
                    const noEsMismoManifiesto = props.manifiesto.id !== manifiesto.id;
                    if (item.presentacion_ref === presentacionId && item.invernadero_ref && noEsMismoManifiesto) { suma -= parseInt(item.cajas_orden || 0); }
                })
            })
            itemsAsignados.forEach(item => {
                if (item.presentacion_ref === presentacionId && item.invernadero_ref) { suma -= parseInt(item.cajas_orden || 0); }
            })
        }
        return suma;
    }

    const handleInputChange = (value, index) => {
        const copiaItems = DeepClone(itemsAsignados);
        copiaItems[index].cajas_orden = value;
        setItemsAsignados(copiaItems);
    }

    const itemTemplate = (item) => {
        const presentacion = getObjetoPorID(presentaciones, item.presentacion_ref);
        const invernadero = getObjetoPorID(invernaderos, item.invernadero_ref);
        return <div>{(presentacion && invernadero) ? `${presentacion.presentacion} - ${invernadero.nombre}` : "-"}</div>;
    }
    const guardarItemsManifiesto = () => {
        if (validarErrores()) { actualizarItemsModal(props.manifiesto.id, itemsAsignados); }
    }
    const validarErrores = () => {
        const errores = {};
        itemsAsignados.forEach((el, index) => {
            if (!parseInt(el.cajas_orden || 0)) { errores[`cajas${index}`] = true; }
        })
        setErrores(errores);
        return Object.keys(errores)?.length === 0;
    }

    const actualizarItemsModal = async (manifiestoId, itemsAGuardar) => {
        const copiaManifiestos = DeepClone(props.manifiestos);
        const manfiestoIndex = copiaManifiestos.findIndex(el => el.id === manifiestoId);
        setCargando(true);
        props.onHide();

        if (manfiestoIndex > -1) {
            copiaManifiestos[manfiestoIndex].items = itemsAGuardar;
            await guardarManifiesto(ciclo, manifiestoId, copiaManifiestos[manfiestoIndex]);
            setShowGuardadoToast(2500);
            props.setManifiestos(copiaManifiestos);
        }
        setCargando(false);
    }
    return (
        <Dialog header={`Asignacion de items ${props.manifiesto?.manifiesto || ""}`} className="modal-50" visible={props.visible} onHide={props.onHide}>
            <div className="p-grid">
                <div className="form-group p-col-12">
                    <MultiSelect options={itemsOriginales} placeholder="Sin items seleccionados" value={itemsAsignados} optionLabel="presentacion_ref" dataKey="id"
                        onChange={(e) => { handleItemsAsignados(e.value) }} filter={true} filterBy="presentacion.presentacion,nombre_invernadero"
                        selectedItemsLabel={`${itemsAsignados.length} items seleccionados`} itemTemplate={itemTemplate} maxSelectedLabels={0} />
                </div>

                {itemsAsignados.map((el, index) => {
                    const disponibles = calcularDisponibles(el.presentacion_ref);
                    const presentacion = getObjetoPorID(presentaciones, el.presentacion_ref);
                    const invernadero = getObjetoPorID(invernaderos, el.invernadero_ref);
                    return (
                        <ContenedorInputNoCard key={`it-${index}`} col="6" label={`${presentacion?.presentacion} - ${invernadero?.nombre}`}>
                            <InputText value={el.cajas_orden} onChange={(e) => handleInputChange(e.target.value, index)} type="number" step="1" keyfilter="pnum" />
                            <p className="form-output">Cajas disponibles: {disponibles}</p>
                            {errores?.[`cajas${index}`] && <Message severity="error" text="Este campo es requerido" />}
                        </ContenedorInputNoCard>)
                })}
            </div>
            <div className="p-col-12">
                <div className="p-grid p-justify-end btn-row">
                    <Button label="Aceptar" onClick={guardarItemsManifiesto} />
                </div>
            </div>
        </Dialog>);
}
export default ModalAsignacionItems;