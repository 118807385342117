import React, { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { getProductosBases } from '../../service/ProductosYVariedades';
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { obtenerEmpaquesCiclo } from '../../service/Empaques';
import SeccionFiltros from '../common/SeccionFiltros';
import ContenedorPrincipal from '../common/ContenedorPrincipal';
import SeccionCards from '../common/SeccionCards';
import { obtenerHorasTrabajadasEmpaque, obtenerProductividadEmpaque } from '../../service/NominaEmpaque';
import ContenedorHeader from '../common/ContenedorHeader';
import CardSitioEmpaque from './components/CardSitioEmpaque';
import { obtenerPresentaciones } from '../../service/Presentaciones';

const ResumenNominaEmpaque = (props) => {
  const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
  const [, setNombreModulo] = useGlobal("nombreModulo");
  const [, setCargando] = useGlobal("cargando")
  const [ciclo] = useGlobal("ciclo");
  const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector();
  const [productos, setProductos] = useState([]);
  const [empaques, setEmpaques] = useGlobal("empaques")
  const [, setPresentaciones] = useGlobal("presentaciones")

  useEffect(() => {
    setNombreModulo("Nómina de empaque");
    setHabilitadoSelectorCiclo(true)
  }, [])

  useEffect(() => {
    if (ciclo && week.nombre != "Cargando") { obtenerDatosIniciales(); }
  }, [ciclo, week])

  const obtenerDatosIniciales = async (params) => {
    setCargando(true);
    try {
      const [empaques = [], productos, presentaciones] = await Promise.all([obtenerEmpaquesCiclo(ciclo), getProductosBases(), obtenerPresentaciones()])
      let promesasHorasTrabajadas = empaques.map(empaque => obtenerHorasTrabajadasEmpaque(empaque));
      let promesasProductividad = empaques.map(empaque => obtenerProductividadEmpaque(empaque, productos));

      const promiseAllPromises = [Promise.all(promesasHorasTrabajadas), Promise.all(promesasProductividad)]
      const [resumenHorasTrabajadas, resumenProductividad] = await Promise.all(promiseAllPromises);

      empaques.forEach((empaque, index) => {
        empaque.horasTrabajadas = resumenHorasTrabajadas[index];
        empaque.productividad = resumenProductividad[index];
      })
      setProductos(productos);
      setEmpaques(empaques);
      setPresentaciones(presentaciones)
      if (empaques.length === 0) { setCargando(false); }
    } catch (error) {
      console.log("ERROR: ", error);
    }
  }

  return (
    <ContenedorPrincipal>
      <ContenedorHeader titulo="Nómina de empaque" subtitulo="Resumen de sitios" iconos="boxes-icon" />

      <SeccionFiltros>
        <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
      </SeccionFiltros>

      <SeccionCards extra="cards-empaque">
        {empaques?.map((empaque) => <CardSitioEmpaque key={empaque.id} empaque={empaque} productos={productos} />)}
      </SeccionCards>
    </ContenedorPrincipal>)
}
export default ResumenNominaEmpaque;//159