import React, { useState, useEffect, useGlobal } from "reactn";
import { obtenerReporteGradosBrix } from '../../service/ReporteCalidad';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { obtenerNombreFecha } from "../../service/fechas";
import { dosDecimales } from '../../util/functions';
import { rolProveedorExterno } from "../../constants";

const ReporteDefectos = (props) => {
    const [rows, setRows] = useState([]);
    const [invernadero] = useGlobal("invernadero");
    const [ciclo,] = useGlobal("ciclo");
    const [comentarios, setComentarios] = useState([]);
    const [, setMostrarModalPermiso] = useGlobal("mostrarModalPermiso");
    const [, setCargando] = useGlobal("cargando");
    const [usuario] = useGlobal("usuario");
    const [productosYVariedades] = useGlobal("productosYVariedades");
    const [variedades, setVariedades] = useState(false);

    useEffect(() => {
        if (ciclo && invernadero && props.semana) { setCargando(true); }
        procesarRowsVariedades(props.proveedorExterno)
    }, [variedades, props.proveedorExterno, invernadero, props.semana])
    useEffect(() => {
        setVariedades(props.mezclaVariedades || [])
    }, [props.mezclaVariedades])

    const procesarRowsVariedades = async (proveedorExterno) => {
        let rowsTodos = []
        for (let x = 0; x < variedades.length; x++) {
            const variedad = variedades[x];
            let result = await obtenerReporteGradosBrix(ciclo, invernadero.id, props.semana, variedad.id, proveedorExterno)
                .catch((err) => {
                    if (err.code && err.code === "permission-denied") {
                        setMostrarModalPermiso(true);
                        console.log(err.code);
                    }
                    console.log("ERROR REPORTE DEFECTOS");
                    console.log(err);
                })
            let row = procesarReporteVariedad(result, variedad)
            rowsTodos.push(row)
        }
        let resultPromedio = await obtenerReporteGradosBrix(ciclo, invernadero.id, props.semana, false, proveedorExterno)
        let rowPromedio = procesarReportePromedio(resultPromedio)
        rowsTodos.push(rowPromedio)
        setRows(rowsTodos)
        setCargando(false)
    }
    const procesarReporteVariedad = (result, variedad) => {
        result["inicial"]["nombre"] = variedad.nombre;

        //agregar nombres a defectos
        // agregar columna total
        return result["inicial"]
    }
    const procesarReportePromedio = (result) => {



        result["inicial"]["nombre"] = "INICIAL (PROMEDIO)";
        setComentarios(result["comentarios"]);
        return result["inicial"]
    }
    const procesarReporte = (result) => {



        result["inicial"]["nombre"] = "INICIAL (PROMEDIO)";
        //result["final"]["nombre"] = "FINAL";
        setRows([result["inicial"]]);
        //agregar nombres a defectos
        // agregar columna total
        setComentarios(result["comentarios"]);

    }
    const templateBody = (rowData, column) => {





        if (rowData[column["field"]]) {

            return `${dosDecimales(rowData[column["field"]]["cociente"])} `;
        } else {
            return "0";
        }

    }

    const templateHeader = (rowData, column) => {


        return rowData["nombre"];
    }


    return <React.Fragment>

        <div className="card p-0">
            <DataTable value={rows}>
                <Column header="" field="nombre" body={templateHeader} />
                <Column header="COLOR 1" field="color1" body={templateBody} />
                <Column header="COLOR 2" field="color2" body={templateBody} />
                <Column header="COLOR 3" field="color3" body={templateBody} />
                <Column header="COLOR 4" field="color4" body={templateBody} />
                <Column header="COLOR 5" field="color5" body={templateBody} />
                <Column header="COLOR 6" field="color6" body={templateBody} />

            </DataTable>
        </div>
        {usuario.rol !== rolProveedorExterno && <p className="section-title">Comentarios/hallazgos</p>}
        {usuario.rol !== rolProveedorExterno && <div className="card">
            <div className="p-col-12 max-6">

                {comentarios.map(comentario => {
                    return <div> {obtenerNombreFecha(comentario.momento)} - {comentario.comentario} </div>
                })}
            </div>
        </div>}

    </React.Fragment>
}

export default ReporteDefectos;