//react/reactn
import React, { useState, useEffect, useGlobal } from "reactn"
//Componentes third party
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { filtrarVariedadMedleys } from "../../../../service/ProductosYVariedades";

const TablaVariedades = (props) => {
    const [usuario,] = useGlobal("usuario");
    const [allVariedades] = useGlobal("allVariedades");
    const [coloresVariedades] = useGlobal("coloresVariedades");
    const [globalFilter] = useGlobal("globalFilterProductosYVariedades");
    const [, setVariedad] = useGlobal("variedad");
    const [allVariedadesSinMedleys, setAllVariedadesSinMedleys] = useState([]);

    useEffect(() => {
        if (allVariedades) {
            const allVariedadesSinMedleys = filtrarVariedadMedleys(allVariedades);
            setAllVariedadesSinMedleys(allVariedadesSinMedleys);
        }
    }, [allVariedades])

    const irVariedad = (event) => {
        if (puedeEditar()) {
            setVariedad(event.data);
            props.history.push("/admin/variedades/crear");
        }
    }
    const nombreColorTemplate = (row, column) => {
        const color = coloresVariedades?.find(el => el.id === row?.color) || {};
        return color.nombre || "";
    }

    const puedeEditar = () => ["Administrador"].includes(usuario.rol);
    return (props.visible ?
        <DataTable globalFilter={globalFilter} value={allVariedadesSinMedleys} onRowClick={irVariedad}>
            <Column field="nombre" header="Nombre" sortable={true} />
            <Column field="color" header="Color" sortable={true} body={nombreColorTemplate} />
        </DataTable>
        : null)
}
export default TablaVariedades;