import React from 'react';
import { useState, useEffect, useGlobal } from 'reactn';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { obtenerEmpresas } from "../../service/Empresas";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Message } from 'primereact/message';
import moment from "moment";
import 'moment/locale/es';

import { obtenerLocaciones } from '../../service/Locaciones';
import * as firebase from "firebase/app";
import { getNombrePorID, getObjetoPorID } from '../../util/functions';
import { AGROPARK, ATONGO, EMPAQUE_SITIO_AGROPARK, HTF } from '../../constants';
import { obtenerEmpaques } from '../../service/Empaques';
moment.locale("es");

const CrearFisico = (props) => {
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [invernaderosFisicos, setInvernaderosFisicos] = useGlobal("invernaderosFisicos");
    const [, setCargando] = useGlobal("cargando")
    const [empresas, setEmpresas] = useGlobal("empresas")
    const [locaciones, setLocaciones] = useGlobal("locaciones")
    const [sitiosEmpaque, setSitiosEmpaque] = useGlobal("sitiosEmpaque")

    const [state, setState] = useState({
        save: false,
        invernaderoID: props.match.params.invernaderoID,
        fisico: { capillas: [{ lineas_norte: 0, lineas_sur: 0 }], color: "", nombre: "", locacion_ref: "", empresa_ref: "", empaque_ref: "" }, capillas: [],
        lineas: [
            { detalle: "Área (m2)", norte: 0, sur: 0, totales: 0, display: "" },
            { detalle: "# de Capillas", norte: 0, sur: 0, totales: 0, },
            { detalle: "Claros por linea", norte: 0, sur: 0, totales: 0 },
        ],
        capillas: []
    });

    useEffect(() => {
        setNombreModulo("Invernaderos Físicos")
        obtenerDatosInicio()
    }, [])

    useEffect(() => {//Esconder mensajes de validación al editar el formulario
        setState({ ...state, save: false });
    }, [state.fisico])
    useEffect(() => {
        if (otraEmpresaSeleccionada()) {
            const locacionAgropark = locaciones?.find(loc => loc.nombre === AGROPARK) || {};
            setState({ ...state, fisico: { ...state.fisico, locacion_ref: locacionAgropark.id } })
        }
    }, [state.fisico.empresa_ref])
    useEffect(() => {
        if (locacionAgroparkSeleccionado()) {
            const sitioAgropark = sitiosEmpaque?.find(sitio => sitio.nombre === EMPAQUE_SITIO_AGROPARK) || {};
            setState({ ...state, fisico: { ...state.fisico, empaque_ref: sitioAgropark.id } })
        }
        else if (locacionAtongoSeleccionado()) { setState({ ...state, fisico: { ...state.fisico, empaque_ref: "" } }) }
    }, [state.fisico.locacion_ref])

    const obtenerDatosInicio = async () => {
        setCargando(true)
        const [respEmp, respLoc, respEmpaque] = await Promise.all([obtenerEmpresas(), obtenerLocaciones(), obtenerEmpaques()])
        setEmpresas(respEmp)
        setLocaciones(respLoc)
        setSitiosEmpaque(respEmpaque)
        setCargando(false)
    }

    const setDetails = (value, props) => {
        let capillas = []

        if (props.rowData.detalle === "Área (m2)") {
            if (props.field === "norte") {
                setState({
                    ...state, lineas: [
                        { detalle: state.lineas[0].detalle, norte: value, sur: state.lineas[0].sur, totales: parseInt(value || 0) + parseInt(state.lineas[0]?.sur || 0), display: (parseInt(value || 0) + parseInt(state.lineas[0]?.sur || 0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") },
                        { detalle: state.lineas[1].detalle, norte: state.lineas[1].norte, sur: state.lineas[1].sur, totales: state.lineas[1].totales, display: state.lineas[1]?.totales || 0 },
                        { detalle: state.lineas[2].detalle, norte: state.lineas[2].norte, sur: state.lineas[2].sur, totales: state.lineas[2].totales, display: state.lineas[2]?.totales || 0 },
                    ]
                })
            }
            else if (props.field === "sur") {
                setState({
                    ...state, lineas: [
                        { detalle: state.lineas[0].detalle, norte: state.lineas[0].norte, sur: value, totales: parseInt(value || 0) + parseInt(state.lineas[0]?.norte || 0), display: (parseInt(value || 0) + parseInt(state.lineas[0]?.norte || 0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") },
                        { detalle: state.lineas[1].detalle, norte: state.lineas[1].norte, sur: state.lineas[1].sur, totales: state.lineas[1].totales, display: state.lineas[1]?.totales || 0 },
                        { detalle: state.lineas[2].detalle, norte: state.lineas[2].norte, sur: state.lineas[2].sur, totales: state.lineas[2].totales, display: state.lineas[2]?.totales || 0 },
                    ]
                })
            }
        }
        else if (props.rowData.detalle === "# de Capillas") {
            if (props.field === "norte") {
                for (var j = 0; j < parseInt(value) + parseInt(state.lineas[1].sur); j++) {

                    capillas.push({ capilla: j + 1, norte: 0, sur: 0, totales: 0, })
                }
                setState({
                    ...state, capillas: capillas, lineas: [
                        { detalle: state.lineas[0].detalle, norte: state.lineas[0].norte, sur: state.lineas[0].sur, totales: state.lineas[0].totales, display: state.lineas[0].totales.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") },
                        { detalle: state.lineas[1].detalle, norte: value, sur: state.lineas[1].sur, totales: parseInt(value || 0) + parseInt(state.lineas[1]?.sur || 0), display: parseInt(value || 0) + parseInt(state.lineas[1]?.sur || 0) },
                        { detalle: state.lineas[2].detalle, norte: state.lineas[2].norte, sur: state.lineas[2].sur, totales: state.lineas[2].totales, display: state.lineas[2]?.totales || 0 },
                    ]
                })

            }
            else if (props.field === "sur") {
                for (var j = 0; j < parseInt(value) + parseInt(state.lineas[1].norte); j++) {

                    capillas.push({ capilla: j + 1, norte: 0, sur: 0, totales: 0, })
                }
                setState({
                    ...state, capillas: capillas, lineas: [
                        { detalle: state.lineas[0].detalle, norte: state.lineas[0].norte, sur: state.lineas[0].sur, totales: state.lineas[0].totales, display: state.lineas[0].totales.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") },
                        { detalle: state.lineas[1].detalle, norte: state.lineas[1].norte, sur: value, totales: parseInt(value || 0) + parseInt(state.lineas[1]?.norte || 0), display: parseInt(value || 0) + parseInt(state.lineas[1]?.norte || 0) },
                        { detalle: state.lineas[2].detalle, norte: state.lineas[2].norte, sur: state.lineas[2].sur, totales: state.lineas[2].totales, display: state.lineas[2]?.totales || 0 },
                    ]
                })
            }
        }
        else if (props.rowData.detalle === "Claros por linea") {
            if (props.field === "norte") {
                setState({
                    ...state, lineas: [
                        { detalle: state.lineas[0].detalle, norte: state.lineas[0].norte, sur: state.lineas[0].sur, totales: state.lineas[0].totales, display: state.lineas[0].totales.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") },
                        { detalle: state.lineas[1].detalle, norte: state.lineas[1].norte, sur: state.lineas[1].sur, totales: state.lineas[1].totales, display: state.lineas[1]?.totales || 0 },
                        { detalle: state.lineas[2].detalle, norte: value, sur: state.lineas[2].sur, totales: parseInt(value || 0) + parseInt(state.lineas[2]?.sur || 0), display:  parseInt(value || 0) + parseInt(state.lineas[2]?.sur || 0) },
                    ]
                })
            }
            else if (props.field === "sur") {
                setState({
                    ...state, lineas: [
                        { detalle: state.lineas[0].detalle, norte: state.lineas[0].norte, sur: state.lineas[0].sur, totales: state.lineas[0].totales, display: state.lineas[0].totales.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") },
                        { detalle: state.lineas[1].detalle, norte: state.lineas[1].norte, sur: state.lineas[1].sur, totales: state.lineas[1].totales, display: state.lineas[1]?.totales || 0 },
                        { detalle: state.lineas[2].detalle, norte: state.lineas[2].norte, sur: value, totales: parseInt(value) + parseInt(state.lineas[2].norte), display:  parseInt(value || 0) + parseInt(state.lineas[2]?.norte || 0) },
                    ]
                })
            }
        }

    }

    const setLineas = (value, props) => {
        let capillas = state.capillas
        if (props.field === "norte") {
            capillas[parseInt(props.rowData.capilla) - 1] = { capilla: props.rowData.capilla, norte: value, sur: parseInt(props.rowData.sur), totales: parseInt(props.rowData.sur) + parseInt(value) }
        }
        else if (props.field === "sur") {
            capillas[parseInt(props.rowData.capilla) - 1] = { capilla: props.rowData.capilla, norte: parseInt(props.rowData.norte), sur: value, totales: parseInt(props.rowData.norte) + parseInt(value) }

        }
        setState({ ...state, capillas: capillas })
    }

    const colors = [
        { label: "Verde oscuro", value: "green-dark" },
        { label: "Verde", value: "green" },
        { label: "Azul oscuro", value: "blue-dark" },
        { label: "Azul", value: "blue" },
        { label: "Morado oscuro", value: "purple-dark" },
        { label: "Morado", value: "purple" },
        { label: "Rosado oscuro", value: "pink-dark" },
        { label: "Rosado", value: "pink" },
        { label: "Rojo", value: "red" },
        { label: "Rojo oscuro", value: "red-dark" },
        { label: "Naranja oscuro", value: "orange-dark" },
        { label: "Naranja", value: "orange" },
        { label: "Amarillo oscuro", value: "yellow-dark" },
        { label: "Amarillo", value: "yellow" },
        { label: "Gris oscuro", value: "gray-dark" },
        { label: "Gris", value: "gray" }
    ];

    const guardarFisico = async () => {
        setState({ ...state, save: true })

        if (state.fisico?.nombre && state.fisico?.color) {
            setCargando(true)
            const { capillas: caps = [], lineas, fisico } = state
            let capillas = caps?.map(cap => ({ nombre: cap.capilla, lineas_norte: cap.norte, lineas_sur: cap.sur }));
            const { nombre, index = invernaderosFisicos?.length + 1, color, locacion_ref, empresa_ref, empaque_ref } = fisico;

            const { totales: area, norte: area_norte, sur: area_sur } = lineas[0];
            const { norte: capillas_norte, sur: capillas_sur } = lineas[1];
            const { norte: claros_por_linea_norte, sur: claros_por_linea_sur } = lineas[2];

            const invernaderoData = {
                nombre, index, color, locacion_ref, empresa_ref, empaque_ref, area, area_norte, area_sur,
                capillas, capillas_norte, capillas_sur, claros_por_linea_norte, claros_por_linea_sur,
            };
            let newDoc = await firebase.firestore().collection('invernaderos_fisicos').add(invernaderoData);
            invernaderoData.id = newDoc.id;
            setInvernaderosFisicos([...invernaderosFisicos, invernaderoData])
            setCargando(false)
            props.history.goBack();
        }
    }

    const HTFSeleccionado = () => getNombrePorID(empresas, state.fisico.empresa_ref) === HTF;
    const otraEmpresaSeleccionada = () => !!state.fisico.empresa_ref && getNombrePorID(empresas, state.fisico.empresa_ref) !== HTF;
    const locacionAgroparkSeleccionado = () => getNombrePorID(locaciones, state.fisico.locacion_ref) === AGROPARK;
    const locacionAtongoSeleccionado = () => getNombrePorID(locaciones, state.fisico.locacion_ref) === ATONGO;

    const handleInputChangeLocacion = (event) => {
        const { value } = event.target;
        setState({ ...state, fisico: { ...state.fisico, locacion_ref: value } })
    }
    const handleInputChangeEmpaque = (event) => {
        const { value } = event.target;
        setState({ ...state, fisico: { ...state.fisico, empaque_ref: value } })
    }
    const getOpcionesSitiosEmpaque = () => {
        if (locacionAgroparkSeleccionado()) { return sitiosEmpaque; }
        else { return sitiosEmpaque?.filter(sitio => sitio.nombre !== EMPAQUE_SITIO_AGROPARK) }
    }

    return (
        <div className="p-grid p-justify-center">
            <div className="p-col p-col-auto title-container">
                <span className="back-btn" onClick={() => { props.history.goBack() }}></span>
                <span className="icon-general inv-icon"></span>
                <span className="titles-group">
                    <h1 className="header-title">Crear invernadero físico</h1>
                </span>
            </div>

            <div className="p-col-10">
                <div className="p-grid p-fluid ">
                    <div className="p-col-12">
                        <p className="section-title">Características</p>
                        <div className="card">
                            <div className="p-grid">
                                <div className="form-group p-col-12 p-md-6">
                                    <label htmlFor="nombre">Nombre</label>
                                    <InputText id="nombre" value={state.fisico.nombre} onChange={e => setState({ ...state, fisico: { ...state.fisico, nombre: e.target.value } })} />
                                    {(state.save === true && state.fisico.nombre === "") ? <Message severity="error" text="Este campo es requerido"></Message> : null}
                                </div>
                                <div className="form-group p-col-12 p-md-6">
                                    <label htmlFor="colores">Color</label>
                                    <Dropdown id="colores" options={colors} value={state.fisico.color} onChange={e => setState({ ...state, fisico: { ...state.fisico, color: e.target.value } })} placeholder="Seleccione un color"
                                        filter={true} filterPlaceholder="Buscar color" />
                                    {(state.save === true && state.fisico.color === "") ? <Message severity="error" text="Este campo es requerido"></Message> : null}
                                </div>
                                <div className="form-group p-col-12 p-md-6">
                                    <label htmlFor="empresas">Empresa</label>
                                    <Dropdown id="empresas" options={empresas} optionLabel="nombre" optionValue="id" placeholder="Seleccione una empresa"
                                        value={state.fisico.empresa_ref} onChange={e => setState({ ...state, fisico: { ...state.fisico, empresa_ref: e.target.value } })} />
                                    {(state.save === true && state.fisico.empresa_ref === "") ? <Message severity="error" text="Este campo es requerido"></Message> : null}
                                </div>
                                <div className="form-group p-col-12 p-md-6">
                                    <label htmlFor="location">Locación</label>
                                    <Dropdown id="locacion_ref" options={locaciones} value={state.fisico.locacion_ref} optionLabel="nombre" optionValue="id" disabled={!HTFSeleccionado()}
                                        onChange={handleInputChangeLocacion} placeholder="Seleccione un locación" />
                                    {(state.save === true && state.fisico.locacion_ref === "") ? <Message severity="error" text="Este campo es requerido"></Message> : null}
                                </div>
                                {HTFSeleccionado() && <div className="form-group p-col-12 p-md-6">
                                    <label htmlFor="empaque_ref">Sitio de empaque</label>
                                    <Dropdown id="empaque_ref" options={getOpcionesSitiosEmpaque()} value={state.fisico.empaque_ref} optionLabel="nombre" optionValue="id" disabled={locacionAgroparkSeleccionado()}
                                        onChange={handleInputChangeEmpaque} placeholder="Seleccione un locación" />
                                    {(state.save === true && state.fisico.empaque_ref === "") ? <Message severity="error" text="Este campo es requerido"></Message> : null}
                                </div>}
                            </div>
                        </div>

                        <div className="p-col p-col-auto">
                            <p className="section-title">Detalles físicos</p>
                            <div className="card p-0">
                                <DataTable value={state.lineas} className="">
                                    <Column field="detalle" header="Detalle" className="col__label" />
                                    <Column field="norte" header="Norte" editor={(props) => { return <InputText keyfilter={"pnum"} id="norte" onChange={e => setDetails(e.target.value, props)} /> }} />
                                    <Column field="sur" header="Sur" editor={(props) => { return <InputText keyfilter={"pnum"} id="sur" onChange={e => setDetails(e.target.value, props)} /> }} />
                                    <Column field="display" header="Display" className="col__total" />
                                </DataTable>
                            </div>
                        </div>
                        <div className="p-col p-col-auto">
                            <p className="section-title">Asignación de lineas</p>
                            <div className="card p-0">
                                <DataTable value={state.capillas} className="">
                                    <Column field="capilla" header="# Capilla" className="col__label" />
                                    <Column field="norte" header="Norte" editor={(props) => { return <InputText keyfilter={"pnum"} id="norte" onChange={e => setLineas(e.target.value, props)} /> }} />
                                    <Column field="sur" header="Sur" editor={(props) => { return <InputText keyfilter={"pnum"} id="sur" onChange={e => setLineas(e.target.value, props)} /> }} />
                                    <Column field="totales" header="Totales" className="col__total" />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12">
                    <div className="p-grid p-justify-end btn-row">
                        <Button label="Cancelar" className="p-button-secondary" onClick={() => { props.history.goBack() }} />
                        <Button label="Guardar" onClick={guardarFisico} />
                    </div>
                </div>
            </div>


        </div>
    );

}
export default CrearFisico;