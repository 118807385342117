import React from 'react'
import { useGlobal } from 'reactn';
import calidades from './../../../images/graficas/calidades.png';
import calidadempacadora from './../../../images/graficas/calidadempacadora.png';
import { Card } from 'primereact/card';
import calidadpresentaciones from './../../../images/graficas/calidadpresentaciones.png';
import grados_brix from './../../../images/graficas/gradosbrix.png';

export default function HomeCalidad(props) {

    return (
        <React.Fragment>

            <div className="titulo-seccion-graficas">
                <span className="icon-general quality-icon"></span>
                Calidad
            </div>
            <div className="p-col-12 p-md-3  grafica-card" onClick={() => props.ir("/calidad/graficas/heatmap")}>
                <Card className={`htg-card bor-gray-superlight`}  >


                    <div className="card-title">Gráfica de calidad</div>
                    <img src={calidades} />

                </Card>
            </div>
            <div className="p-col-12 p-md-3  grafica-card" onClick={() => props.ir("/nomina_empaque/graficas/empaque_empacadora")}>
                <Card className={`htg-card bor-gray-superlight`}  >


                    <div className="card-title">Gráfica por empacadora</div>
                    <img src={calidadempacadora} />

                </Card>
            </div>
            <div className="p-col-12 p-md-3  grafica-card" onClick={() => props.ir("/calidad/graficas/reporte_pesos_calidad")}>
                <Card className={`htg-card bor-gray-superlight`}  >


                    <div className="card-title">Gráfica por presentaciones</div>
                    <img src={calidadpresentaciones} />

                </Card>
            </div>
            <div className="p-col-12 p-md-3  grafica-card" onClick={() => props.ir("/calidad/grados_brix")}>
                <Card className={`htg-card bor-gray-superlight`}  >


                    <div className="card-title">Gráfica de grados brix</div>
                    <img src={grados_brix} />

                </Card>
            </div>
        </React.Fragment>


    )
}
