import { getObjetoPorID } from "util/functions";

export const mapeoOpcionesInvsPorProductoYVariedad = (invsFiltrados = [], productosBases = [], clientes = []) => {
    const obtencionDeGrupos = invsFiltrados.reduce((acc, invernadero) => {
        const { id, producto_ref } = invernadero;
        const producto = productosBases.find(prod => prod.id === producto_ref || (invernadero.mezcla && prod.MEDLEYS_GENERAL));
        if(!invernadero?.mezcla)  invernadero.cliente = getObjetoPorID(clientes, invernadero.cliente_ref );
//console.log("CLIENTE: ",{acc, invernadero})
        const key = producto.nombre;
        if (acc[key]) { acc[key].push(invernadero); }
        if (!acc[key]) { acc[key] = [invernadero]; }
        return acc;
    }, {})
    const opcionesAgrupadas = Object.entries(obtencionDeGrupos)?.map(group => {
        return { label: group[0], options: group[1] };
    })
    return opcionesAgrupadas;
}