import React from 'react';
import { useState, useEffect, useGlobal, setGlobal } from 'reactn';
import Chart from "react-apexcharts";
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';

import { obtenerOrdenesMantenimientoAciclico, obtenerFiltrosMantenimientoTodos, opcionesGraficaPie, getListaEmpresasIDs, graficaDatosOpciones } from '../../../service/Requisiciones';
import { dateToUnixTimeAsNumber13, getNombrePorID, DeepClone } from '../../../util/functions';
import { miliSegundosDia, numeroMaximo } from '../../../constants'
import ContenedorHeader from '../../common/ContenedorHeader';
import ContenedorFiltro from '../../common/ContenedorFiltro'
import SeccionFiltros from '../../common/SeccionFiltros'


setGlobal({
    urgenciasFiltrosMant: [], datosFiltrosMant: [], ordenesRefsMant: [], requerimientosMant: [],
    empresasMant: [], departamentosMant: [], urgenciasMant: [], datoParaFiltrarMant: "orden_ref"
})
const GraficaMantenimiento = (props) => {
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [, setCargando] = useGlobal("cargando")
    const [ciclo,] = useGlobal("ciclo");
    const [usuario] = useGlobal("usuario");
    const [ordenesOriginales, setSeriesOriginales] = useState([])
    const [series, setSeries] = useState([])
    const [options, setOptions] = useState(DeepClone(opcionesGraficaPie))//Deep clone
    const [datosCargados] = useGlobal("datosCargadosMant")

    const [datoParaFiltrar, setDatoParaFiltrar] = useGlobal("datoParaFiltrarMant")
    const [datosOpciones, setDatosOpciones] = useGlobal("datosOpcionesMant")
    const [datosFiltros, setDatosFiltros] = useGlobal("datosFiltrosMant")

    //opciones de filtros
    const [ordenesRefsOpciones] = useGlobal("ordenesRefsMant")
    const [requerimientosOpciones] = useGlobal("requerimientosMant")
    const [empresasOpciones] = useGlobal("empresas")
    const [departamentosOpciones] = useGlobal("departamentos")
    const [urgenciasOpciones] = useGlobal("urgenciasMant")

    //variables de filtros
    const [fechaFiltroInicio, setFechaFiltroInicio] = useGlobal("fechaFiltroInicioMant")
    const [fechaFiltroFinal, setFechaFiltroFinal] = useGlobal("fechaFiltroFinalMant")
    const [urgenciasFiltros, setUrgenciasFiltros] = useGlobal("urgenciasFiltrosMant")


    useEffect(() => {
        if (usuario.rol === "agente aduanal")
            props.history.push("/")
        setNombreModulo("Requisiciones de mantenimiento")
    }, [])
    useEffect(() => {
        setCargando(true)
        if (ciclo && !datosCargados)
            obtenerFiltrosMantenimientoTodos(usuario)
        else if (ciclo && datosCargados)
            obtenerDatosInicio()

    }, [ciclo, datosCargados])

    const obtenerDatosInicio = async () => {
        let ordenes = await obtenerOrdenesMantenimientoAciclico(usuario)
        setSeriesOriginales(ordenes)
        setCargando(false)
    }

    useEffect(() => {
        let allDatosOpciones = {
            orden_ref: ordenesRefsOpciones, empresa: empresasOpciones,
            departamento: departamentosOpciones, requerimiento: requerimientosOpciones
        }
        let datosOpciones = allDatosOpciones[datoParaFiltrar]

        setDatosOpciones(datosOpciones)
        setDatosFiltros(datosOpciones.map(doc => doc.id))
        setOptions({ ...options, labels: datosOpciones.map(doc => doc.nombre) })
    }, [datoParaFiltrar, ordenesOriginales])

    useEffect(() => {
        let series = []

        let ordenesFiltrados = []
        datosFiltros.forEach(filtro => {
            if (datoParaFiltrar == "empresa")
                ordenesFiltrados = ordenesOriginales.filter(el => getListaEmpresasIDs(el).includes(filtro) && validarUrgenciasYFechas(el))
            else
                ordenesFiltrados = ordenesOriginales.filter(el => el[datoParaFiltrar] === filtro && validarUrgenciasYFechas(el))

            series.push(ordenesFiltrados.length)
        })

        setOptions({ ...options, labels: datosFiltros.map(el => getNombrePorID(datosOpciones, el)) })
        setSeries(series)

    }, [datosFiltros, urgenciasFiltros, fechaFiltroInicio, fechaFiltroFinal])
    const validarUrgenciasYFechas = (el) => {
        let selectedFechaInicio = !fechaFiltroInicio ? 1 : dateToUnixTimeAsNumber13(fechaFiltroInicio)
        let selectedFechaFinal = !fechaFiltroFinal ? numeroMaximo : (dateToUnixTimeAsNumber13(fechaFiltroFinal) + miliSegundosDia)
        return urgenciasFiltros.includes(el.urgencia) && el.fecha_creacion >= selectedFechaInicio && el.fecha_creacion <= selectedFechaFinal
    }

    return (
        <div className="p-grid chart-page">
            <ContenedorHeader iconos="mtto-icon" atras={props.history.goBack} titulo="Gráfica requisiciones de mantenimiento" 
                col="p-col p-col-auto"/>

            <SeccionFiltros >
                <ContenedorFiltro col="2" label="Fecha Inicio">
                    <Calendar id="fechaInicio" readOnlyInput={true} dateFormat="dd-mm-yy"
                        value={fechaFiltroInicio} monthNavigator={true} yearNavigator={true} yearRange="1900:2030"
                        showButtonBar={true} onClearButtonClick={() => { setFechaFiltroInicio("") }}
                        onChange={e => setFechaFiltroInicio(e.value)} />
                </ContenedorFiltro>
                <ContenedorFiltro col="2" label="Fecha Final">
                    <Calendar id="fechaFinal" readOnlyInput={true} dateFormat="dd-mm-yy"
                        value={fechaFiltroFinal} monthNavigator={true} yearNavigator={true} yearRange="1900:2030"
                        showButtonBar={true} onClearButtonClick={() => { setFechaFiltroFinal("") }}
                        onChange={e => setFechaFiltroFinal(e.value)} />
                </ContenedorFiltro>

                <ContenedorFiltro label="por dato">
                    <Dropdown id="dato" options={graficaDatosOpciones} value={datoParaFiltrar} optionLabel="nombre" optionValue="id"
                        onChange={(e) => setDatoParaFiltrar(e.value)} placehold="Seleccionar" />
                </ContenedorFiltro>
                <ContenedorFiltro label={getNombrePorID(graficaDatosOpciones, datoParaFiltrar)}>
                    <MultiSelect id="filtroDatos" filter={false} optionLabel="nombre" optionValue="id"
                        options={datosOpciones} value={datosFiltros}
                        onChange={(e) => setDatosFiltros(e.value)}
                        selectedItemsLabel={`${datosFiltros.length} filtros seleccionados`} />
                </ContenedorFiltro>
                <ContenedorFiltro label="urgencia">
                    <MultiSelect id="urgencias" optionLabel="nombre" optionValue="id"
                        filter={false} selectedItemsLabel={`${urgenciasFiltros.length} urgencias seleccionadas`}
                        options={urgenciasOpciones} value={urgenciasFiltros}
                        onChange={(e) => setUrgenciasFiltros(e.value)} />
                </ContenedorFiltro>
            </SeccionFiltros>

            {ordenesOriginales.length > 0 &&
                <div className="p-col-12 chart-container-filters">
                    <Chart options={options}
                        series={series} type="donut" height="100%" />
                </div>
            }

        </div>
    );

}
export default GraficaMantenimiento //154