import { getGlobal } from "reactn"
import { nombreActividadCosecha, obtenerInfoNomina, } from "../../service/nomina";
import { nombreActividadBajado, obtenerNombreYStatusEmpleado, obtener_empleados_invernadero } from "../../service/RhService";
import { dosDecimales } from "../../util/functions";

export const obtenerDatosBase = async (ciclo,invernadero, semana, listaActividades) => {
    
    let [actividades,empleadosSemana ] = await obtenerInfoNomina(semana,invernadero.id,ciclo)
    let ahora = Math.round(Date.now()/1000);
    //  
    //  final = Date.now();
    //  dif = (final-inicial)/1000;
    //  
    //  
    //  
        // 
    actividades.splice(0,0,"empleado");
    
    let variedad = invernadero.variedad_ref
    let actividadesSemana = actividades;
    
    let empleados = await obtener_empleados_invernadero(ciclo,invernadero.id)
    let promises = [];
    empleados.map(empleado=>{
        promises.push(obtenerNombreYStatusEmpleado(empleado.empleado_ref));
    })
            
    let values = await Promise.all(promises)
    empleados = empleados.map((empleado,i)=>{                     
        empleado.activo = values[i]["activo"];
    //  
    //  
    //  
    //  
    //  
        empleado["novato"]= (ahora - values[i]["fecha_ingreso"]) < 4 * 7 * 24 * 60 * 60;
    //  
        empleado.fecha_ingreso = values[i]["fecha_ingreso"];
        empleado.nombre =values[i]["nombre"];
        //                  empleado.nombre = empleado["novato"]? <span>{values[i]["nombre"]} <span className='novato-label'> (novato)</span> </span> : values[i]["nombre"];
        return empleado;
    })
    //
    if(empleados && empleados.length >0){
        // 
        empleadosSemana = empleadosSemana.map(empleado=>{
            let empleadoCopia =    Object.assign({},empleado);
            // 
            // 
            // 
            let result = empleados.find(el=>el.empleado_ref=== empleado.empleado);
            if(result){
                empleadoCopia.empleado=result.nombre;
                empleadoCopia.activo = result.activo;
                empleadoCopia.fecha_ingreso = result.fecha_ingreso;
                empleadoCopia.novato = result.novato;
            }
            // 
            // 
            return empleadoCopia;
        });
        
    }
        let cuentaEmpleados = empleadosSemana.length;
        empleadosSemana = procesar(empleadosSemana,invernadero.variedad_ref,invernadero,
            listaActividades);
        
              
        let footer = setValuesFooter(empleadosSemana,invernadero,listaActividades);
        return { cuentaEmpleados,empleadosSemana, footer, variedad,
            actividadesSemana}
}
function procesar(empleados,variedad,invernadero,actividades){
    let inicial = Date.now();
       let final = 0;
       let dif = 0;
   let copia = empleados.map(empleado=>{

    const bonoRef = invernadero.mezcla ? getGlobal().medleysGeneral?.id :  variedad
       
       let falta = empleado["falta"];
       
       
       let total =0;
       const metrosPorLinea = parseInt(invernadero.detalles_fisicos.find(el=>el.detalle==="Metros por línea")["norte"]);
       
       for(let x = 0; x< actividades.length; x++){
           let actividadActual = actividades[x];
           let bono = actividadActual["nombre"].includes(nombreActividadCosecha)? 1:
               actividadActual["unidades"] === "líneas"? metrosPorLinea* actividadActual.bonos_por_unidad[bonoRef]:actividadActual.bonos_por_unidad[bonoRef];

           if(empleado[actividadActual.id]>0){
               
               empleado[`orig_${actividadActual.id}`] = empleado[actividadActual.id];                
               empleado[`bono_${actividadActual.id}`] = bono * empleado[actividadActual.id];
               
               if(actividadActual["nombre"].includes(nombreActividadCosecha)){
                   //
                   total += empleado["extra_cosecha"] * bono;
                   //
                   empleado[actividadActual.id] = `${empleado[actividadActual.id]} / ${empleado["extra_cosecha"]}`;
                   //empleado[`bono_${actividadActual.id}`] = bono * empleado["extra_cosecha"];
               }else{
                   //
                   total += empleado[actividadActual.id] * bono;
                   //
                   empleado[actividadActual.id] = `${empleado[actividadActual.id]} / ${empleado[actividadActual.id]*bono}`;
               }
           }

           else{
               //
               empleado[`orig_${actividadActual.id}`]=0;   
               //
           }
       }
       //
       //
       empleado.total = `${total}`;
       empleado.bono = `${falta?total:1.2 * total}`;
       return empleado;
   })
   return copia.sort((a,b)=>{
       return a.activo>b.activo? -1: a.activo<b.activo?1: a.empleado > b.empleado? 1:-1});
}
function setValuesFooter(empleadosSemana, invernadero, actividades){
    
    let holder = {}
    let porcentajes = {};
    holder["empleado"]  = "Totales";                                
    porcentajes["empleado"] = "Porcentaje";
    porcentajes["total"] = "-";
    porcentajes["bono"] = "-";
    porcentajes["tipo"] = "porcentaje";
        holder["total"] = 0;
        holder["bono"] = 0;
        
        
        let lineasInv = calcularLineasTotales(invernadero) ;
        for(let x = 0; x< actividades.length; x++){
            let actividadActual = actividades[x];
            
            holder[actividadActual.id] = 0;
            holder[`unit_${actividadActual.id}`] = 0;
            holder[`valor_${actividadActual.id}`] = 0;
            porcentajes[actividadActual.id]=0;
            empleadosSemana.forEach(empleado=>{
                

            //let bono  = actividadActual.bonos_por_unidad[variedad];
            
            // 
            // 
            // 
            // 
            // 
            
            
            if(empleado[`orig_${actividadActual.id}`]){
                let totalUnidades = empleado[`orig_${actividadActual.id}`];
                let field = `bono_${actividadActual.id}`;
                if(actividadActual.nombre===nombreActividadCosecha){
                    field = `extra_cosecha`;
                }
                let totalValor = empleado[field];
                
                holder[`unit_${actividadActual.id}`]+=totalUnidades;
                holder[`valor_${actividadActual.id}`]+=totalValor;
                holder[`total`]+=totalValor;
                
                
                holder[actividadActual.id] = `${holder[`unit_${actividadActual.id}`]} / ${holder[`valor_${actividadActual.id}`]}`;
                    if(holder[`unit_${actividadActual.id}`]){
                        porcentajes[actividadActual.id] = Math.round(100 * holder[`unit_${actividadActual.id}`]/ lineasInv);

                    if(actividadActual.nombre === nombreActividadCosecha){
                        porcentajes[actividadActual.id] = "-";
                    }
                    if(actividadActual.nombre === nombreActividadBajado){
                        porcentajes[actividadActual.id] = 1 * porcentajes[actividadActual.id];
                    }
                    if(actividadActual.unidades != "líneas"){
                        porcentajes[actividadActual.id] = "-";
                    }
                }
                
            }

            else{

                
            }
            })
            
        }
    //
    //  
    empleadosSemana.forEach(empleado=>{
        //
        //
        holder["bono"] += dosDecimales(parseFloat(empleado["bono"]))
    })
    //holder["bono"] = "-"//holder["total"] * 1.2;   
    holder["bono"] = dosDecimales(holder["bono"]);         
    holder["total"] = dosDecimales(holder["total"]);
    return [holder,porcentajes];
}
const calcularLineasTotales = (invernadero) => {
    let asignacion_lineas = invernadero["asignacion_lineas"];
    let total = asignacion_lineas.reduce((acc,curr) => {
      let dato = curr["totales"].split(" ")[0]
      if(dato){
        dato = parseInt(dato);
        if(!isNaN(dato)){
          return acc+dato;
        }else{
          return acc;
        }
      }
    },0)

     return total
 }