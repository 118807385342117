import React, { useState, useEffect } from 'reactn';
import { useGlobal } from "reactn";
import { Button } from 'primereact/button';
import { obtenerReporteCosechaInvernaderoExternoSemana, obtenerReporteCosechaInvernaderoSemana } from "../../service/Cosecha";
import InvernaderoCard from "./InvernaderoCard";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import CardsAgrupados from './CompraExternaFolder/CardsAgrupados';
import InvernaderoExternoCard from './InvernaderoExternoCard';

const InvernaderosCosecha = (props) => {
  const [usuario,] = useGlobal("usuario");
  const [ciclo,] = useGlobal("ciclo");
  const [, setNombreModulo] = useGlobal("nombreModulo");
  const [weeks, week, handleWeekChange] = useWeekSelector();
  const [, setCargando] = useGlobal("cargando");
  const [, setMostrarModalPermiso] = useGlobal("mostrarModalPermiso");

  const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
  const [invernaderosFisicos] = useGlobal("invernaderosFisicos");
  const [invernaderos, setInvernaderos] = useState();
  const [invernaderosExternosResumen, setInvernaderosExternosResumen] = useState([])

  useEffect(() => {
    setNombreModulo("Cosecha");
  }, [])

  useEffect(() => {
    setCargando(true);
    if (invernaderosVirtuales && week?.nombre !== "Cargando") { obtenerDatosInvernaderos(invernaderosVirtuales); }
  }, [invernaderosVirtuales, week])

  const obtenerDatosInvernaderos = (invernaderos=[]) => {
    let promises = [], invernaderosData = invernaderos
    const promisesInvExt = []
    for (let x = 0; x < invernaderosData.length; x++) {
      let invernadero = invernaderosData[x];
      if(invernadero.es_externo) {
        promisesInvExt.push(obtenerResumenInvernaderoExterno(invernadero, x));
      }else{
        promises.push(obtenerResumen(invernadero, x));
      }
    }

    Promise.all(promises).then(values => {
      let copia = invernaderosData?.map(el => Object.assign({}, el));

      for (let x = 0; x < values.length; x++) {
        let [invId, invFisico, reporte] = values[x];
        let [invernadero, semana, cajas, kilos, kg_m2, resumenEmpaque] = reporte;
        let inv = copia.find(el => el.id === invId);
        inv.invernaderoFisico = invFisico;
        inv.semanal = kg_m2["semanal"];
        inv.semanalReal = kg_m2["real"];
        inv.realvssemanal = cajas["real"] / cajas["semanal"]
        inv.diariovssemanal = cajas["diario"] / cajas["semanal"]
        inv.realvsdiario = cajas["real"] / cajas["diario"]
      }
      setCargando(false);
      setInvernaderos(copia);
    }).catch(err => {
      console.log("HUBO UN ERROR AL OBTENER RESUMEN: " + err.message);
      if (err && err.code == "permission-denied") {
        setMostrarModalPermiso(true);
      }
    }).finally(el => {
      setCargando(false);
    })
    Promise.all(promisesInvExt).then(values => {
      const valuesDatosCard = values.map((value) => {
        const copiaInvernaderos = invernaderosData?.map(el => Object.assign({}, el));

        const [invId, invFisico, reporte] = value;
        const [invernadero, semana, cajas, kilos, kg_m2, resumenEmpaque] = reporte;
        const invernaderoExterno = copiaInvernaderos.find(el => el.id === invId)
        const inv = {
          ...invernaderoExterno,
          invernaderoFisico: invFisico,
          semanal: kg_m2.semanal,
          semanalReal: kg_m2.real,
          realvssemanal: cajas.real / cajas.semanal,
          diariovssemanal: cajas.diario / cajas.semanal,
          realvsdiario: cajas.real / cajas.diario
        }
        return inv
      })
      setInvernaderosExternosResumen(valuesDatosCard)
    })
  }

  const obtenerResumen = (invernadero) => {
    return new Promise(async (resolve, reject) => {
      let invernaderoFisico = invernaderosFisicos.find(invF => invF.id === invernadero.invernadero_fisico_ref) || {};
      const reporte = await obtenerReporteCosechaInvernaderoSemana(ciclo, invernadero, week.time).catch((error) => { console.log("ERROR OBTENER REPORTE COSECHA DE INVERNADERO: ", error); });
      resolve([invernadero.id, invernaderoFisico, reporte]);
    });
  }
  const obtenerResumenInvernaderoExterno = async(invernadero) => {
      const reporte = await obtenerReporteCosechaInvernaderoExternoSemana(ciclo, invernadero, week.time).catch((error) => { console.log("ERROR OBTENER REPORTE COSECHA DE INVERNADERO: ", error); });
      return [invernadero.id, invernadero, reporte];
  }

  const irGrafica = () => { props.history.push("/cosecha/graficas/porcentajes"); }
  const puedeConsultarReporte = (params) => usuario &&
    ["Administrador", "Personal Administrativo", "Grower", "Auxiliar Grower", "Administrador RH",
      "Auxiliar RH", "Administrador Empaque", "Auxiliar Empaque", "Administrador Embarques", "Auxiliar Embarques",
      "Administrador Calidad", "Auxiliar Calidad", "Auxiliar Biocontrol", "Administrador Seguridad", "Auxiliar Seguridad", "Finanzas"].includes(usuario.rol);
  return (
    <div className="p-grid">
      <div className="p-col-12">
        {puedeConsultarReporte() &&
          <div className="btn-row">
            <Button label="Reporte de cosecha" onClick={() => props.history.push("/cosecha/reporte")} />
            <Button className="reactbtn-icon" label="Gráfica porcentajes de cosecha" onClick={irGrafica}>
              <FontAwesomeIcon icon={faChartBar} />
            </Button>
          </div>}
      </div>

      <div className="p-col-12">
        <div className="p-grid filtros">
          <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
        </div>
      </div>

      <div className="p-col-12">
        <div className="p-grid">
          {invernaderos?.map(invernadero => {
            if(!invernadero.es_externo){
              return <InvernaderoCard key={invernadero.id} invernadero={invernadero} />}
            }
          )}
          {
            invernaderosExternosResumen.map(inv => <InvernaderoExternoCard key={inv.id} invernadero={inv}/>)
          }
        </div>
      </div>
    </div>)
}
export default InvernaderosCosecha;