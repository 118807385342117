import React, { useGlobal, useState, useEffect } from "reactn"
import { withRouter } from "react-router-dom";
import { Panel } from "primereact/panel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTh } from "@fortawesome/free-solid-svg-icons";
import icono from "../../images/icons/external-icon.svg";
import { dosDeciComas, getObjetoPorID, makeCssClass, separadoComas, sumarNumerosArrayPorCampo } from "../../util/functions";

const CardOrden = (props) => {
  const [usuario] = useGlobal("usuario");
  const [clientes] = useGlobal("clientes");
  const [invernaderos] = useGlobal("invernaderosVirtuales");
  const [, setOrden] = useGlobal("ordenVenta");
  const [collapsed, setCollapsed] = useState(true);
  const [sumaCajas, setSumaCajas] = useState("");
  const [sumaPallets, setSumaPallets] = useState("");
  const [items, setItems] = useState([]);

  const puedeConsultar = () => usuario && ["Administrador", "Administrador Empaque", "Administrador Embarques", "Auxiliar Empaque", "Auxiliar Embarques", "Administrador Calidad"].includes(usuario.rol);

  useEffect(() => {
    obtenerDatosItems();
  }, [items])

  useEffect(() => {//Filtrar items por invernadero de origen
    if (props.invernaderosFiltro) {
      const itemsFiltrados = props.orden.items?.filter(item => checarIncluirItem(item)) || [];
      setItems(itemsFiltrados);
    }
  }, [props.invernaderosFiltro, props.orden.items])

  const checarIncluirItem = (item) => {
    return props.invernaderosFiltro?.includes(item.invernadero_ref);
  }

  const obtenerDatosItems = () => {
    let sumaCajas = sumarNumerosArrayPorCampo(items, "cajas_orden");
    let sumaPallets = sumarNumerosArrayPorCampo(items, "pallets_orden");
    sumaCajas = dosDeciComas(sumaCajas);
    sumaPallets = dosDeciComas(sumaPallets);

    setSumaCajas(sumaCajas);
    setSumaPallets(sumaPallets);
  }

  const irOrdenVenta = () => {
    if (puedeConsultar()) {
      setOrden(props.orden);
      props.history.push("/ordenes/consultar");
    }
  }

  const toggleDetalles = (e) => {
    e.originalEvent.stopPropagation();//Bloquear hacer click en card e ir a pantalla de consulta
    setCollapsed(!collapsed)
  }

  const { cliente, frontera, destino, no_orden, consolidado, cliente_ref } = props.orden;
  const mostrarOrdenCard = () => props.clientesFiltro.includes(cliente_ref);
  const getClienteLogo = () => getObjetoPorID(clientes, cliente_ref)?.imagen || "";

  return (mostrarOrdenCard() ?
    <div className="card order-card" onClick={irOrdenVenta}>

      <div className={`order-border ${makeCssClass(cliente?.nombre)} ${makeCssClass(frontera?.nombre)}`}>
        <div className="order-destinycolor">
          {cliente && <img className="cliente-image " alt={cliente.nombre} title={cliente.nombre} src={getClienteLogo()} />}
          <div className="order-bordercolor">{frontera.nombre}</div>
        </div>

        <div className="order-destinyname">{destino.nombre} </div>
      </div>

      <div className="order-number">Orden #{no_orden}</div>
      {consolidado &&
        <div className=" p-grid">
          <div className="p-col-auto">
            <FontAwesomeIcon icon={faTh} />
          </div>
          <div className="p-col">
            <div className="order-consolid">Consolidado</div>
          </div>
        </div>}

      <div className="order-amounts p-grid">
        <div className="p-col-6 order-pallets">
          <div className="order-data-number"> {sumaPallets} </div>
          <div className="order-label">Pallets</div>
        </div>
        <div className="p-col-6 order-boxes">
          <div className="order-data-number"> {sumaCajas} </div>
          <div className="order-label">Cajas </div>
        </div>
      </div>

      <Panel header="Detalle" className="order-detail" toggleable={true} collapsed={collapsed} onToggle={toggleDetalles}>
        {items.map((item, i) => {
          const invernadero = getObjetoPorID(invernaderos, item.invernadero_ref)
          return (
            <div className={`order-item bor-${invernadero.color}`} key={`${no_orden}-${i}`}>
              <span className={`icon-general icon-mini  bor-${invernadero.color} bg-${invernadero.color}`}>
                {invernadero.es_externo ? <img width="30" alt="icono" src={icono} /> : invernadero.nombre}
              </span>

              <div>{item.nombre_producto} </div>
              <div>{item.descripcion} </div>
              <div>{separadoComas(item.cajas_orden)} Cajas</div>
            </div>)
        })}
      </Panel>
    </div>
    : null)
};
export default withRouter(CardOrden);