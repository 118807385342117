import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { uploadStatistics } from './Estadisticas'
function registrarInvocaciones(count) {
    uploadStatistics(count)
}
export const login = (email, password) => firebase.auth().signInWithEmailAndPassword(email, password);
export const logout = () => firebase.auth().signOut()
export const jalarUsuario = async (uid) => {
    registrarInvocaciones(1);
    let doc = await firebase.firestore().collection("usuarios").doc(uid).get();
    let usuario = doc.exists ? { ...doc.data(), uid, isRolFinanzas: doc.data().rol === "Finanzas" } : "OFF";
    return usuario;
}
let unsuscribe = null;
export const startListener = (uid, callback) => {
    unsuscribe = firebase.firestore().collection("usuarios").doc(uid).onSnapshot((doc) => {
        let data = { ...doc.data(), uid, isRolFinanzas: doc.data().rol === "Finanzas" };
        callback(data);
    })
}
export const stopListener = () => { unsuscribe() }
export const recuperarPassword = (email) => firebase.auth().sendPasswordResetEmail(email);
//36