import React, { useState, useGlobal, useEffect } from 'reactn';
import { withRouter } from 'react-router-dom';
import { dosDecimales, separadoComas } from '../../util/functions';
import { UNIDADES_RADIACION, UNIDADES_CONDUCTIVIDAD, UNIDADES_TEMPERATURA, UNIDADES_PH } from '../../util/constants';
import HTGCard from '../common/cards/HTGCard';
import DatoCard from '../common/cards/DatoCard';
import Column from '../common/cards/Column';
import ClipLoader from 'react-spinners/ClipLoader';
import CardHeader from '../common/cards/CardHeader';

function InvernaderoCard(props) {
  const [invernadero, setInvernadero] = useGlobal("invernadero");
  const [aviso, setAviso] = useState(true);
  var [usuario, setUsuario] = useGlobal("usuario");
  useEffect(() => {
    if (props.invernadero?.configuracion_inicial_cultivo) {
      setAviso(false);
    }


  }, [])
  useEffect((params) => {
    if (props.invernadero) {
      if (props.invernadero?.configuracion_inicial_cultivo) 
        setAviso(false);
    }
  }, [props.invernadero])
  const puedeConsultar = (params) => {
    return (usuario && ["Administrador", "Personal Administrativo", "Grower", "Auxiliar Grower",
      "Administrador Seguridad","Auxiliar Seguridad", "Finanzas"
    ].includes(usuario.rol))
  }
  const consultar = () => {
    if (puedeConsultar()) {
      setInvernadero(props.invernadero);
      if (props.invernadero?.configuracion_inicial_cultivo) {
        props.history.push("/cultivo/registro");
      } else {
        props.history.push("/cultivo/configuracion");
      }
    }

  }
  const header = <CardHeader
    invernaderoVirtual={props.invernadero}
    habilitadoTexto={props.invernadero?.habilitado ? "" : "Deshabilitado"}
    nombreInvernaderoFisico={props.invernadero?.invernaderoFisico?.nombre || ""} />
  let className = `htg-card bor-${props.invernadero?.color} ${props.invernadero?.habilitado ? "Habilitado" : "Deshabilitado"}`
  return (
    <HTGCard header={header} headerClassName={className} onClick={consultar}>
      {props.home && props.cargandoHome
        ? <div className="card-spinner-centered">
          <ClipLoader sizeUnit="px" size={100} color="#003E66" loading={true} />
        </div>
        :
        <>
          {aviso
            ? <div className="p-col">Realizar configuración inicial de cultivo</div>
            : <React.Fragment>
              <Column>
                <DatoCard label="Suma de radiación" valor={`${separadoComas(Math.round(props.invernadero?.radiacion))} ${UNIDADES_RADIACION}`} />
                <DatoCard label="Temperatura 24h interior" valor={`${dosDecimales(props.invernadero?.temperatura)} ${UNIDADES_TEMPERATURA}`} />
              </Column>
              <Column>
                <DatoCard label="pH gotero" valor={`${Math.round(props.invernadero?.ph)} ${UNIDADES_PH}`} />
                <DatoCard label="Conductividad eléctrica drenaje" valor={`${Math.round(props.invernadero?.conductividad)} ${UNIDADES_CONDUCTIVIDAD}`} />

              </Column>
            </React.Fragment>}
        </>}
    </HTGCard >);
}
export default withRouter(InvernaderoCard);//76