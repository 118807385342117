import React, { useGlobal } from 'reactn';
import { TransformWrapper as TransformWrapperClass, TransformComponent } from "react-zoom-pan-pinch";

const TransformWrapper = (props) => {
    const wrapperOptions = { limitToBounds: true, limitToWrapper: true, maxScale: 4 }
    const wrapperPan = {
        disabled: false, lockAxisX: false,
        lockAxisY: false, velocityEqualToMove: true, velocity: true,
    }
    const wrapperWheel = { wheelEnabled: true, touchPadEnabled: true, limitsOnWheel: false, step: 100, }
    const [bioScale, setBioScale] = useGlobal("mapaBiocontrolTransformScale")
    const [calScale, setCalScale] = useGlobal("mapaCalidadTransformScale")

    return (
        <TransformWrapperClass
            options={wrapperOptions}
            pan={wrapperPan}
            pinch={{ disabled: false }}
            doubleClick={{ disabled: true }}
            wheel={wrapperWheel}
        >
            {({ zoomIn, zoomOut, resetTransform, setDefaultState,
                positionX, positionY, scale, previousScale,
                options: { limitToBounds, transformEnabled, disabled },
                ...rest
            }) => {
                if (bioScale !== parseInt(scale)) setBioScale(parseInt(scale))
                if (calScale !== parseInt(scale)) setCalScale(parseInt(scale))
                return (
                    <div className={`zoomLevel-${scale} p-col-12`}>
                        <TransformComponent>

                            {props.children}

                        </TransformComponent>
                    </div>
                )
            }}
        </TransformWrapperClass>
    );
}
export default TransformWrapper;