//react/reactn
import React, { useGlobal } from 'reactn';
//Funciones internas
import { getListaNombresDeObjetos, getNombrePorID, } from '../../../../util/functions';
//Componentes third party
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
//Componentes internos
import ContenedorTabCard from '../../../common/ContenedorTabCard';
import { UnidadPorcentaje } from '../../../common/templates_unidades/UnidadPorcentaje.atomo';
import { UnidadTextoConTooltip } from '../../../common/templates_unidades/UnidadTextoConTooltip.atomo';

const TablaProductos = (props) => {
    const [productosYVariedadesTodos] = useGlobal("productosYVariedadesTodos");

    const textoTemplate = (rowData, column) => {//Uppercase camelcase?
        const listaItems = props.mostrarUnicos ? getListaNombresDeObjetos(rowData.variedades) : getProductoYVarString(rowData)
        return (<UnidadTextoConTooltip rowData={rowData} column={column} texto="variedades" listaItems={listaItems} />);
    }

    const getProductoYVarString = (rowData) => {
        let lista = [];
        rowData.variedades.forEach(variedad => {
            lista.push(`${getNombrePorID(productosYVariedadesTodos, variedad.producto_ref)} - ${variedad.nombre}`);
        })
        return lista;
    }

    return (
        <ContenedorTabCard titulo={props.titulo} >
            <DataTable globalFilter={props.globalFilter} value={props.value} onRowClick={props.onRowClick} emptyMessage="No hay resultados">
                <Column field="nombre" header="Nombre" sortable={true} className={props.colClass} />
                <Column field="numVariedades" header="# Variedades" sortable={true} className={props.colClass} body={textoTemplate} />
                <Column field="packoutRespaldo" header="Packout respaldo" sortable={true} className={props.colClass} body={UnidadPorcentaje} />
                <Column field="tipoCalidadInicial" header="Medida de calidad inicial" sortable={true} className={props.colClass} />
            </DataTable>
        </ContenedorTabCard>
    )
}
export default TablaProductos;