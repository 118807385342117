import { Message } from 'primereact/message';
import React from 'react'
const ContenedorTabla = (props) =>
    <div className={`p-col-${props.col || "12"} ${props.extra || ""}`} style={props.style || {}}>
        {props.titulo && <p className="section-title">{props.titulo}</p>}
        {props.subtitulo && <p>{props.subtitulo}</p>}
        <div className="card p-0">
            {props.children}
        </div>
        {props.error && <Message severity="error" text={props.message || "Debes llenar todos los campos"} />}
    </div>;
export default ContenedorTabla;