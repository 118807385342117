import { INVOICE_STATUSES } from "service/constants/Rechazos.const"
import { obtenerInicioSemanaDia, obtenerListaDeSemanas } from "service/fechas"
import { APROBADO } from "service/ReportePrecios"
import { dosDecimales, obtainWeightedAverage, porcentaje } from "util/functions"
const { VALIDADO } = INVOICE_STATUSES

export const gettingItemsFromManifiestos = (manifiestos) => {
    const allItemsOfManifiestos = manifiestos.reduce((acc, manf) => {
      const { id: manifiestoId, items } = manf
      if(!items) return acc
      if(items.length === 0) return acc
      const itemsAprobados = items.filter(item => item.status === APROBADO|| item.status === VALIDADO)
      const itemsAprobadosConManfId = itemsAprobados.map(item => ({...item, manifiestoId }))
      return [...acc, ...itemsAprobadosConManfId]
    },[])
    return allItemsOfManifiestos
}

export const gettingItemsIndexedByPres = (items = []) => {
  const itemsIndexedByGrupoPres = items.reduce((acc, item) => {
    const { presentacion_ref } = item
    if(acc[presentacion_ref]){
      acc[presentacion_ref].push(item)
    }else{
      acc[presentacion_ref] = [item]
    }
    return acc
  },{})
  return itemsIndexedByGrupoPres
}

export const gettingManifiestosIndexedById = (manifiestos = []) => {
  const manfiestosIndexedById = manifiestos.reduce((acc, manf) => {
    const { id } = manf
    acc[id] = manf
    return acc
  },{})
  return manfiestosIndexedById
}

export const generateSeries = (manifiestosOrig, presentaciones, itemsAprobadosIndexedByPres, manifiestosIndexed, budgetsGrupoPresentaciones, gruposPresentacionesOrig, config) => {
    const { tipo, isAcumulado } = config
    let mapReal = true
    let mapBudget = true

    if(tipo){
      mapReal = tipo.includes("real")
      mapBudget = tipo.includes("budget")
    }

    const manifiestosOrdenadosPorFecha = manifiestosOrig.sort((manf, manf2) => Number(manf.fecha_envio) - Number(manf2.fecha_envio))
  
    const manifiestoMasAntiguo = manifiestosOrdenadosPorFecha[0]
    const manifiestoMasReciente = manifiestosOrdenadosPorFecha[manifiestosOrdenadosPorFecha.length-1]

    const inicioSemanaManfAntiguo = obtenerInicioSemanaDia(Number(manifiestoMasAntiguo.fecha_envio)).unix()
    const inicioSemanaManfMasReciente = obtenerInicioSemanaDia(Number(manifiestoMasReciente.fecha_envio)).unix()

    const semanas = obtenerListaDeSemanas(inicioSemanaManfAntiguo, inicioSemanaManfMasReciente);
    const series = presentaciones.reduce((acc, pres) => {
        const { id: presentacionId, presentacion } = pres
        const grupoDePresentacion = gruposPresentacionesOrig.find((grupP) => grupP.presentaciones_refs.includes(presentacionId))
        if(!grupoDePresentacion) return acc
        const budgetDeGrupoPresentaciones = budgetsGrupoPresentaciones.find((budgetsGrup) => budgetsGrup.grupo_presentaciones_ref === grupoDePresentacion.id)
        const itemsPres = itemsAprobadosIndexedByPres[presentacionId]
        const itemsIndexedPorFecha = { }

        if(!itemsPres) return acc

        itemsPres.forEach((item) => {
          const { manifiestoId } = item
          const manifiesto = manifiestosIndexed[manifiestoId]
          const fechaEnvio = Number(manifiesto.fecha_envio)
          const inicioSemanaFechaEnvio = obtenerInicioSemanaDia(fechaEnvio).unix()

          if(itemsIndexedPorFecha[inicioSemanaFechaEnvio]){
            itemsIndexedPorFecha[inicioSemanaFechaEnvio].push(item)
          }else{
            itemsIndexedPorFecha[inicioSemanaFechaEnvio] = [item]
          }

        })

        if(mapReal) {
          const data = semanas.map((semana, index) => {

            let budget = {value: 0}
            if(budgetDeGrupoPresentaciones){
              const budgetObtained = budgetDeGrupoPresentaciones.budget.find((bud) => bud.time === semana.time)
              if(budgetObtained) budget = budgetObtained
            }
            
  
            if(!itemsIndexedPorFecha[semana.time]) {
              if(index === 0) return {
                y: 0, 
                x: semana.nombreCompacto, 
                budget: Number(budget.value), 
                renderFactBudget: mapBudget
              }
              return {y: 0, x: semana.nombreCompacto, budget: Number(budget.value)}
            }
            
            const items = itemsIndexedPorFecha[semana.time]
            const resultLibrasTotales = items.reduce((acc, item) => {
              const { cajas_orden, presentacion, precio_libra } = item
              const { peso_neto_caja_libras } = presentacion
  
              const librasTotalesItem = Number(cajas_orden) * Number(peso_neto_caja_libras)
              acc.librasTotales = acc.librasTotales + librasTotalesItem

              acc.librasTotalesPorItem.push(librasTotalesItem)
              acc.preciosPorLibraPorItem.push(Number(precio_libra))
              return acc
            },{librasTotales: 0, librasTotalesPorItem: [], preciosPorLibraPorItem: []})
            const { librasTotales, librasTotalesPorItem, preciosPorLibraPorItem }  = resultLibrasTotales

            const porcentajesItems = librasTotalesPorItem.map((librasTotalesItem) => Number(porcentaje(librasTotalesItem, librasTotales)))
            const dineroPonderado = obtainWeightedAverage(preciosPorLibraPorItem, porcentajesItems)            
            if(index === 0) return {
              y: dineroPonderado, 
              x: semana.nombreCompacto, 
              budget: Number(budget.value), 
              renderFactBudget: mapBudget
            }
            return {y: dineroPonderado, x: semana.nombreCompacto, budget: Number(budget.value)}
          })
          let dataAcumulado = []
          if(isAcumulado){
            dataAcumulado = data.reduce((acc, value, index) => {
              if(index === 0){
                acc.push(value)
                return acc
              }
              const lastValue = acc[index-1]
              const lastY = Number(lastValue.y)
              const lastBudget = Number(lastValue.budget)

              const currentY = Number(value.y)
              const currentBudget = Number(value.budget)
              const currentX = value.x

              const newY = dosDecimales(lastY + currentY)
              const newBudget = dosDecimales(lastBudget + currentBudget)

              acc.push({...lastValue, x: currentX, y: newY, budget: newBudget })
              return acc
            },[])
          }
          acc.push({ name: presentacion, data: isAcumulado ? dataAcumulado : data})
        }
        
        if(mapBudget && budgetDeGrupoPresentaciones){
          const renderTooptipBudget = !tipo.includes("real")

          const dataBudget = semanas.map((semana, index) => {
            let budget = {value: 0}
            const budgetObtained = budgetDeGrupoPresentaciones.budget.find((bud) => bud.time === semana.time)
            if(budgetObtained) budget = budgetObtained

            if(index === 0) return {
              y: Number(budget.value), 
              x: semana.nombreCompacto, 
              renderTooltipBudget: renderTooptipBudget 
            }

            return {y: Number(budget.value), x: semana.nombreCompacto}
          })
          
          let dataAcumuladoBudget = []
          if(isAcumulado){
            dataAcumuladoBudget = dataBudget.reduce((acc, value, index) => {
              if(index === 0){
                acc.push(value)
                return acc
              }
              const lastValue = acc[index-1]

              const lastY = Number(lastValue.y)
              const currentY = Number(value.y)
              
              const currentX = value.x

              const newY = dosDecimales(lastY + currentY)

              acc.push({...lastValue, x: currentX, y: newY })
              return acc
            },[])
          }
          acc.push({ name: `Budget ${presentacion}`, data: isAcumulado ? dataAcumuladoBudget : dataBudget})
        }

        return acc
    },[])

    return {series, inicioSemanaManfAntiguo, inicioSemanaManfMasReciente, semanas}
}

export const generateSeriesGrupoPresentaciones = (manifiestosOrig, gruposPresentaciones, itemsAprobadosIndexedByPres, manifiestosIndexed, budgetsGrupoPresentaciones, gruposPresentacionesOrig, config) => {
  const { tipo, isAcumulado } = config
  let mapReal = true
  let mapBudget = true

  if(tipo){
    mapReal = tipo.includes("real")
    mapBudget = tipo.includes("budget")
  }

  const manifiestosOrdenadosPorFecha = manifiestosOrig.sort((manf, manf2) => Number(manf.fecha_envio) - Number(manf2.fecha_envio))

  const manifiestoMasAntiguo = manifiestosOrdenadosPorFecha[0]
  const manifiestoMasReciente = manifiestosOrdenadosPorFecha[manifiestosOrdenadosPorFecha.length-1]

  const inicioSemanaManfAntiguo = obtenerInicioSemanaDia(Number(manifiestoMasAntiguo.fecha_envio)).unix()
  const inicioSemanaManfMasReciente = obtenerInicioSemanaDia(Number(manifiestoMasReciente.fecha_envio)).unix()

  const semanas = obtenerListaDeSemanas(inicioSemanaManfAntiguo, inicioSemanaManfMasReciente);
  const series = gruposPresentaciones.reduce((acc, grupP) => {
      const { id: grupoPId, presentaciones_refs, nombre: nombreGrupoPres } = grupP
      const budgetDeGrupoPresentaciones = budgetsGrupoPresentaciones.find((budgetsGrup) => budgetsGrup.grupo_presentaciones_ref === grupoPId)
      const itemsPres =  presentaciones_refs.reduce((acc, presentacionId) => {
        const itemsPres = itemsAprobadosIndexedByPres[presentacionId]
        if(!itemsPres) return acc
        return [...acc, ...itemsPres]
      },[])
      const itemsIndexedPorFecha = { }

      if(!itemsPres) return acc

      itemsPres.forEach((item) => {
        const { manifiestoId } = item
        const manifiesto = manifiestosIndexed[manifiestoId]
        const fechaEnvio = Number(manifiesto.fecha_envio)
        const inicioSemanaFechaEnvio = obtenerInicioSemanaDia(fechaEnvio).unix()

        if(itemsIndexedPorFecha[inicioSemanaFechaEnvio]){
          itemsIndexedPorFecha[inicioSemanaFechaEnvio].push(item)
        }else{
          itemsIndexedPorFecha[inicioSemanaFechaEnvio] = [item]
        }

      })

      if(mapReal) {
        const data = semanas.map((semana, index) => {

          let budget = {value: 0}
          if(budgetDeGrupoPresentaciones){
            const budgetObtained = budgetDeGrupoPresentaciones.budget.find((bud) => bud.time === semana.time)
            if(budgetObtained) budget = budgetObtained
          }
          

          if(!itemsIndexedPorFecha[semana.time]) {
            if(index === 0) return {
              y: 0, 
              x: semana.nombreCompacto, 
              budget: Number(budget.value), 
              renderFactBudget: mapBudget
            }
            return {y: 0, x: semana.nombreCompacto, budget: Number(budget.value)}
          }
          
          const items = itemsIndexedPorFecha[semana.time]
          const resultLibrasTotales = items.reduce((acc, item) => {
            const { cajas_orden, presentacion, precio_libra } = item
            const { peso_neto_caja_libras } = presentacion

            const librasTotalesItem = Number(cajas_orden) * Number(peso_neto_caja_libras)
            acc.librasTotales = acc.librasTotales + librasTotalesItem

            acc.librasTotalesPorItem.push(librasTotalesItem)
            acc.preciosPorLibraPorItem.push(Number(precio_libra))
            return acc
          },{librasTotales: 0, librasTotalesPorItem: [], preciosPorLibraPorItem: []})
          const { librasTotales, librasTotalesPorItem, preciosPorLibraPorItem }  = resultLibrasTotales

          const porcentajesItems = librasTotalesPorItem.map((librasTotalesItem) => Number(porcentaje(librasTotalesItem, librasTotales)))
          
          const dineroPonderado = obtainWeightedAverage(preciosPorLibraPorItem, porcentajesItems)            
          if(index === 0) return {
            y: dineroPonderado, 
            x: semana.nombreCompacto, 
            budget: Number(budget.value), 
            renderFactBudget: mapBudget
          }
          return {y: dineroPonderado, x: semana.nombreCompacto, budget: Number(budget.value)}
        })
        let dataAcumulado = []
        if(isAcumulado){
          dataAcumulado = data.reduce((acc, value, index) => {
            if(index === 0){
              acc.push(value)
              return acc
            }
            const lastValue = acc[index-1]
            const lastY = Number(lastValue.y)
            const lastBudget = Number(lastValue.budget)

            const currentY = Number(value.y)
            const currentBudget = Number(value.budget)
            const currentX = value.x

            const newY = dosDecimales(lastY + currentY)
            const newBudget = dosDecimales(lastBudget + currentBudget)

            acc.push({...lastValue, x: currentX, y: newY, budget: newBudget })
            return acc
          },[])
        }
        acc.push({ name: nombreGrupoPres, data: isAcumulado ? dataAcumulado : data})
      }
      
      if(mapBudget && budgetDeGrupoPresentaciones){
        const renderTooptipBudget = !tipo.includes("real")

        const dataBudget = semanas.map((semana, index) => {
          let budget = {value: 0}
          const budgetObtained = budgetDeGrupoPresentaciones.budget.find((bud) => bud.time === semana.time)
          if(budgetObtained) budget = budgetObtained

          if(index === 0) return {
            y: Number(budget.value), 
            x: semana.nombreCompacto, 
            renderTooltipBudget: renderTooptipBudget 
          }

          return {y: Number(budget.value), x: semana.nombreCompacto}
        })
        
        let dataAcumuladoBudget = []
        if(isAcumulado){
          dataAcumuladoBudget = dataBudget.reduce((acc, value, index) => {
            if(index === 0){
              acc.push(value)
              return acc
            }
            const lastValue = acc[index-1]

            const lastY = Number(lastValue.y)
            const currentY = Number(value.y)
            
            const currentX = value.x

            const newY = dosDecimales(lastY + currentY)

            acc.push({...lastValue, x: currentX, y: newY })
            return acc
          },[])
        }
        acc.push({ name: `Budget ${nombreGrupoPres}`, data: isAcumulado ? dataAcumuladoBudget : dataBudget})
      }

      return acc
  },[])

  return {series, inicioSemanaManfAntiguo, inicioSemanaManfMasReciente, semanas}
}
