import { extraccionSimple } from './dbhelper';
import * as firebase from 'firebase/app';
import "firebase/firestore";
import { getFirestoreID } from './Mantenimiento';

export const obtenerUsuarios = (params) => {
    return extraccionSimple("usuarios");
}
export const togglearBloqueo = (usuarios, id) => {
    const db = firebase.firestore();
    let usuario = usuarios.find(el => el.id === id);
    let siguienteEstado = usuario.bloqueado ? false : true;
    return db.collection("usuarios").doc(id).update({ bloqueado: siguienteEstado })
}

export const obtenerUsuariosFunctions = async () => {
    const functions = firebase.functions();
    const obtenerUsuarios = functions.httpsCallable("obtenerUsuarios");
    let obj = await obtenerUsuarios();
    return obj.data;
}
export const crearUsuarioFunctions = async (datos) => {
    const functions = firebase.functions();
    const crearUsuario = functions.httpsCallable("crearUsuario");
    console.log("DATOS crearUsuarioFunctions 2 ", datos)
    let obj = await crearUsuario({ datos: datos });
    return obj.data;
}
export const modificarUsuarioFunctions = async (datos) => {
    const functions = firebase.functions();
    const modificarUsuario = functions.httpsCallable("modificarUsuario");
    console.log("DATOS modificarUsuarioFunctions 2 ", datos)
    let obj = await modificarUsuario({ datos: datos });
    return obj.data;
}
export const toggleHabilitacionFunctions = async (id) => {
    const functions = firebase.functions();
    const toggleHabilitacion = functions.httpsCallable("toggleHabilitacion");
    let obj = await toggleHabilitacion({ id: id });
    return obj.data;
}
export const toggleBloqueo = (id, bloqueado) => {
    const db = firebase.firestore();
    return db.collection("usuarios").doc(id).update({ bloqueado: !bloqueado });
}

export const obtenerUsuario = async (usuarioId) => {
    const functions = firebase.functions();
    const obtenerUsuarios = functions.httpsCallable("obtenerUsuarios");
    let obj = await obtenerUsuarios();
    console.log(obj)
    let userObj = obj.data.find(usuario => usuario.id === usuarioId)
    return userObj;
}

export const getUsuarios = async () => {
    const response = await firebase.firestore().collection("usuarios").get()
    const usuarios = response.docs.map(doc => ({ id: doc.id, ...doc.data() }))
    return usuarios;
}

export const guardarAgenteAduanal = async (datos, agenteID) => {
    await firebase.firestore().collection("agentes_aduanales").doc(agenteID).set(datos);
    return true;
}