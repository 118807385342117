import React, { useState, useEffect } from 'react';
import { useGlobal } from 'reactn';
import Chart from "react-apexcharts";
import { obtenerPresentaciones } from "../../service/Presentaciones";
import { obtenerOrdenesCiclo } from '../../service/Ordenes';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { obtenerCiclo } from '../../service/Ciclos';
import { obtenerListaDeSemanas, obtenerDiasDeSemana } from '../../service/fechas';
import { SelectButton } from 'primereact/selectbutton';
import ContenedorFiltro from "../common/ContenedorFiltro";
import ChartContainer from '../common/ChartContainer'

import { obtenerClientes, obtenerClientesCiclo } from '../../service/Clientes';
import { dosDecimales, getObjetoPorID, paletaColores, separadoComas } from '../../util/functions';
import { obtenerGruposPresentaciones } from '../../service/QueriesGruposPresentaciones';
import { hayMedleysViejo } from '../../service/ProductosYVariedades';
const tipos = [{ name: "Grupos de presentaciones", code: "grupos" }, { name: "Presentaciones", code: "presentaciones" }]
const tiposGraficas = [

  {
    label: "Absoluto", value: "absoluto"
  },
  {
    label: " 100 %", value: "cien"
  },

]
let optionsOrig = {
  colors: paletaColores,
  chart: {
    type: 'bar',
    height: 350,
    stacked: true,
    stackType: "normal",
    animations: {
      enabled: false
    }
  },
  dataLabels: {
    colors: paletaColores,
    enabled: true,
    formatter: (val, opt) => {
      val = separadoComas(Math.round(val));
      if (opt["w"]["config"]["chart"]["stackType"] === "100%") {
        val = `${val} %`
      }
      return val;
    }
  },
  responsive: [{
    breakpoint: 480,
    options: {
      legend: {
        position: 'bottom',
        offsetX: -10,
        offsetY: 0,
        colors: paletaColores
      }
    }
  }],
  xaxis: {
    categories: [],
  },
  yaxis: {
    decimalsInFloat: 2
  },
  plotOptions: {
    bar: {
      horizontal: true,
    }
  },
  fill: {
    opacity: 1
  },
  legend: {
    colors: paletaColores,
    position: 'right',
    offsetX: 0,
    offsetY: 400,
  },
}
const DistribucionPresentacionesPorSemana = (props) => {
  const invPreseleccionado = props.location?.state?.invernadero

  const [ciclo, setCiclo] = useGlobal("ciclo");
  const [invernadero, setInvernadero] = useGlobal("invernadero");
  const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
  const [productosYVariedades] = useGlobal("productosYVariedades");
  const [productosMezclados] = useGlobal("productosMezclados");
  const [productosBases] = useGlobal("productosBases");
  const [medleysGeneral] = useGlobal("medleysGeneral");
  const [cicloObj] = useGlobal("cicloObj")
  const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
  const [, setNombreModulo] = useGlobal("nombreModulo");
  const [data, setData] = useState([]);
  const [invernaderos, setInvernaderos] = useState([]);
  const [invernaderosProducto, setInvernaderosProducto] = useState([]);
  const defaultValueFiltroProducto = invPreseleccionado ? {...invPreseleccionado.producto} : []
  const defaultValueFiltroClientes = invPreseleccionado ? [{id: invPreseleccionado.cliente_ref}] : []

  const [filtroProducto, setFiltroProducto] = useState(defaultValueFiltroProducto);
  const [productos, setProductos] = useState([]);
  const [clientesOrig, setClientesOrig] = useState([]);
  const [filtroCliente, setFiltroCliente] = useState(defaultValueFiltroClientes);
  const [filtroVariedad, setFiltroVariedad] = useState([]);
  const [filtroInvernadero, setFiltroInvernadero] = useState([]);
  const [ordenesOrig, setOrdenesOrig] = useState([]);
  const [presentaciones, setPresentaciones] = useState([]);
  const [, setCargando] = useGlobal("cargando");
  const [tipoGrafica, setTipoGrafica] = useState(tiposGraficas[0]["value"])
  const [gruposPresentaciones, setGruposPresentaciones] = useState([])
  const [tipo, setTipo] = useState(tipos[1]["code"])
  const [options] = useState({ ...optionsOrig });
  const [preselectedInv, setPreselectedInv] = useState(false)
  useEffect(() => {
    setHabilitadoSelectorCiclo(true)
    setNombreModulo("Homepage Gráficas")
  }, [])

  useEffect(() => {
    setCargando(true)
    if (invernaderosVirtuales && productosYVariedades) {
      obtenerDatosIniciales();
    }
  }, [invernaderosVirtuales, productosYVariedades])

  useEffect(() => {
    if (filtroVariedad.length > 0 &&
      filtroCliente.length > 0 &&
      filtroProducto &&
      filtroVariedad.length > 0) {
      let procesado = procesar();
      setData(procesado);
    } else {
      setData([]);
    }
  }, [filtroVariedad, filtroCliente, filtroProducto, filtroInvernadero, tipo])
  useEffect(() => {
    setFiltroVariedad([])
  },[filtroCliente])
  useEffect(() => {
    let copiaData = [...data];
    setData(copiaData)
  }, [options])

  useEffect(() => {
    const opcionesInvernaderos = obtenerOpcionesInvernaderos();
    setInvernaderosProducto(opcionesInvernaderos)
    if(preselectedInv){
      setCargando(false);
      setPreselectedInv(false)
      return
    }
    setFiltroInvernadero([])
    
  }, [filtroProducto])

  useEffect(() => {
    setFiltroVariedad([]);
  }, [filtroInvernadero])
  useEffect(() => {
    if(clientesOrig.length > 0 && productosBases.length > 0 && invernaderos.length > 0 && invPreseleccionado && filtroInvernadero.length === 0){
      let producto = productosBases.find(prod => prod.id ===invPreseleccionado.producto_ref)
      if(!producto) {
        producto = medleysGeneral
      }
      setFiltroProducto({...producto})
      setFiltroCliente([clientesOrig.find(cli=>cli.id === invPreseleccionado.cliente_ref)])
      setPreselectedInv(true)
      setFiltroInvernadero([invernaderos.find(inv=>inv.id === invPreseleccionado.id)])
    }
  },[clientesOrig, productosBases])
  const obtenerDatosIniciales = async (params) => {
    try {
      let invernaderos = invernaderosVirtuales;
      let productosConVariedades = productosYVariedades;

      let clientesPromise = obtenerClientes();
      let gruposPresentacionesPromise = obtenerGruposPresentaciones();
      let presentacionesPromise = obtenerPresentaciones();
      let ordenesPromise = obtenerOrdenesCiclo(ciclo);
      let clientesCicloPromise = obtenerClientesCiclo(ciclo);

      let [clientes, gruposPresentaciones, presentaciones, ordenes, clientesCiclo] =
        await Promise.all([clientesPromise, gruposPresentacionesPromise
          , presentacionesPromise, ordenesPromise, clientesCicloPromise]);

      setGruposPresentaciones(gruposPresentaciones);
      setPresentaciones(presentaciones);
      let idsClientesCiclo = clientesCiclo.map(el => el.cliente_ref);
      clientes = clientes.filter(el => idsClientesCiclo.includes(el.id))
      setInvernaderos(invernaderos);

      setProductos(productosConVariedades.map(el => ({ nombre: el.nombre, id: el.id })))
      const invsProducto = obtenerOpcionesInvernaderos()
      setInvernaderosProducto(invsProducto)
      setOrdenesOrig(ordenes);
      setClientesOrig(clientes);
      if(!invPreseleccionado){
        setCargando(false);
      }
    } catch (error) {
      console.log("ERROR OBTENER DATOS INICIALES");
      console.log(error);
    }
  }

  const obtenerOpcionesInvernaderos = () => {
    if (!filtroProducto.id) { return invernaderos; }
    else { return invernaderos.filter(inv => filtroProducto.id === inv.producto_ref || (filtroProducto.MEDLEYS_GENERAL && inv.mezcla)); }
  }

  const obtenerOpcionesVariedades = () => {
    if (filtroCliente?.length === 0 || filtroInvernadero?.length === 0 || !filtroProducto) { return []; }
    else if (filtroProducto.MEDLEYS_GENERAL && !hayMedleysViejo(filtroInvernadero)) { return productosMezclados || []; }
    else { return filtroProducto?.variedades || []; }
  }
  const procesar = () => {
    if (tipo === "presentaciones") {
      return procesarPorPresentaciones()
    } else {
      return procesarPorGrupoPresentaciones()
    }
  }

  const procesarPorGrupoPresentaciones = () => {
    try {
      if (cicloObj) {
        let ordenes = ordenesOrig;

        let clientesIds = filtroCliente.map(el => el.id);
        let invernaderos = filtroInvernadero.slice();
        let invernaderosIds = filtroInvernadero.map(el => el.id);

        let variedades = filtroVariedad.slice();
        let variedadesIds = variedades.map(el => el.id);

        let gruposSet = new Set();
        let gruposFiltrados = [];
        gruposPresentaciones.forEach(grupo => {

          grupo.presentaciones_refs.forEach(presentacion_ref => {
            let presentacion = presentaciones.find(el => el.id === presentacion_ref);
            if (presentacion.producto_ref === filtroProducto.id && clientesIds.includes(presentacion.cliente_ref)) {
              gruposSet.add(grupo.id);
            }
          })
        })
        let gruposIds = Array.from(gruposSet);
        gruposFiltrados = gruposIds.map(grupoId => {
          let grupo = gruposPresentaciones.find(el => el.id === grupoId)
          return grupo;
        })


        let filtradas = gruposFiltrados

        let listaSemanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);

        let result = filtradas.map(grupoPresentacion => {
          let primerPresentacion = presentaciones.find(el => grupoPresentacion.presentaciones_refs[0] === el.id);
          let sumaPresentacion = 0;
          let data = listaSemanas.map(semana => {
            let dias = obtenerDiasDeSemana(semana.time);
            let suma = 0;
            grupoPresentacion.presentaciones_refs.forEach(presentacion_ref => {
              let presentacion = presentaciones.find(el => el.id === presentacion_ref);
              ordenes.forEach(orden => {
                orden.items.forEach(item => {
                  if (item.presentacion_ref === presentacion.id
                    && parseInt(orden.fecha_envio) >= dias[0] && parseInt(orden.fecha_envio) <= dias[5]) {
                    let invernadero = invernaderos.find(el => el.id === item.invernadero_ref);
                    if (invernadero && invernaderosIds.includes(invernadero.id) && (variedadesIds.includes(invernadero.variedad_ref) || variedadesIds.includes(invernadero.producto_ref))) {
                      let peso_caja = parseFloat(presentacion.peso_neto_caja);
                      let cajas_orden = parseFloat(item.cajas_orden)
                      suma += peso_caja * cajas_orden;
                      sumaPresentacion += peso_caja * cajas_orden;
                    }
                  }
                })

              })
            })

            return suma;
          })
          let cliente = clientesOrig.find(el => el.id === primerPresentacion.cliente_ref);
          return {
            name: grupoPresentacion.nombre,
            data: data,
            nombreCliente: cliente.nombre,
            sumaPresentacion: sumaPresentacion
          }
        })
        //setOrdenesOrig(result);
        return result.filter(el => el.sumaPresentacion > 0).sort((a, b) => a.nombreCliente > b.nombreCliente ? 1 : -1);
      } else {
        return [];
      }


    } catch (error) {
      console.log("ERROR AL PROCESAR ORDENES");
      console.log(error);
    }


  }
  const procesarPorPresentaciones = () => {
    try {
      if (cicloObj) {
        let ordenes = ordenesOrig;

        let clientesIds = filtroCliente.map(el => el.id);
        let invernaderos = filtroInvernadero.slice();
        let invernaderosIds = filtroInvernadero.map(el => el.id);

        let variedades = filtroVariedad.slice();
        let variedadesIds = variedades.map(el => el.id);
        let filtradas = presentaciones.filter(el => el.producto_ref === filtroProducto.id);
        filtradas = filtradas.filter(el => clientesIds.includes(el.cliente_ref));

        let listaSemanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);

        let result = filtradas.map(presentacion => {
          let sumaPresentacion = 0;
          let data = listaSemanas.map(semana => {
            let dias = obtenerDiasDeSemana(semana.time);
            let suma = 0;
            ordenes.forEach(orden => {
              orden.items.forEach(item => {
                if (item.presentacion_ref === presentacion.id
                  && parseInt(orden.fecha_envio) >= dias[0] && parseInt(orden.fecha_envio) <= dias[5]) {
                  let invernadero = invernaderos.find(el => el.id === item.invernadero_ref);
                  if (invernadero && invernaderosIds.includes(invernadero.id) && (variedadesIds.includes(invernadero.variedad_ref) || variedadesIds.includes(invernadero.producto_ref))) {
                    let peso_caja = parseFloat(presentacion.peso_neto_caja);
                    let cajas_orden = parseFloat(item.cajas_orden)
                    suma += peso_caja * cajas_orden;
                    sumaPresentacion += peso_caja * cajas_orden;
                  }
                }
              })

            })
            return suma;
          })
          let cliente = clientesOrig.find(el => el.id === presentacion.cliente_ref);
          return {
            name: presentacion.presentacion,
            data: data,
            nombreCliente: cliente.nombre,
            sumaPresentacion: sumaPresentacion
          }
        })
        //setOrdenesOrig(result);
        return result.filter(el => el.sumaPresentacion > 0).sort((a, b) => a.nombreCliente > b.nombreCliente ? 1 : -1);
      } else {
        return [];
      }


    } catch (error) {
      console.log("ERROR AL PROCESAR ORDENES");
      console.log(error);
    }


  }

  const obtenerOptions = (params) => {
    if (cicloObj) {
      let copia = { ...options };
      copia["chart"] = { ...options["chart"] };
      copia["xaxis"] = { ...copia["xaxis"] };
      copia["chart"]["stackType"] = "normal"; //tipoGrafica === "cien" ? "100%" : "normal";
      copia["dataLabels"]["enabled"] = true;
      let listaSemanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
      copia["xaxis"]["categories"] = listaSemanas.map(el => el.nombreCompacto);
      //copia["xaxis"]["tickPlacement"] = "between";
      return copia;
    } else {
      return { ...options };
    }

  }
  const obtenerOptionsP = (params) => {
    if (cicloObj) {
      let copia = { ...options };
      copia["chart"] = { ...options["chart"] };
      copia["xaxis"] = { ...copia["xaxis"] };
      copia["chart"]["stackType"] = "100%";
      copia["dataLabels"]["enabled"] = true;
      let listaSemanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
      copia["xaxis"]["categories"] = listaSemanas.map(el => el.nombreCompacto);
      //copia["xaxis"]["tickPlacement"] = "between";
      return copia;
    } else {
      return { ...options };
    }

  }

  // let opts = obtenerOptions();
  const atras = (params) => {
    props.history.goBack();
  }
  const GraficaNormal = (props) => {
    return <Chart options={obtenerOptions()} series={data} type="bar" height="100%" width="100%" />;

  }

  const Grafica100 = (props) => {
    return <Chart options={obtenerOptionsP()} series={data} type="bar" height="100%" width="100%" />;

  }
  return <div className="p-grid chart-page">
    <div className="p-col  p-col-auto title-container">
      <span className="back-btn" onClick={atras} ></span>
      <span className={`icon-general chart-icon `}></span>
      <span className="titles-group">
        <h1 className="header-title">Distribución de presentaciones por semana (kg)</h1>
      </span>
    </div>

    <div className="p-col-12">

      <div className="p-grid filtros">
        <div className="form-group p-col p-col-auto">
          <label>Tipo de gráfica</label>
          <SelectButton options={tiposGraficas} value={tipoGrafica} onChange={e => setTipoGrafica(e.value)} />
        </div>
        <div className="form-group p-col p-col-auto">
          <label htmlFor="filtroCliente">Clientes</label>
          <MultiSelect options={clientesOrig} value={filtroCliente} onChange={e => setFiltroCliente(e.value)}
            selectedItemsLabel={`${filtroCliente.length} clientes seleccionados`}
            dataKey="id" optionLabel="nombre" />
        </div>
        <div className="form-group p-col p-col-auto">
          <label htmlFor="filtroProducto">Productos</label>
          <Dropdown options={productosBases} value={filtroProducto} onChange={e => setFiltroProducto(e.value)}
            dataKey="id" optionLabel="nombre" />
        </div>
        <div className="form-group p-col p-col-auto">
          <label htmlFor="filtroInvernadero">Invernaderos</label>
          <MultiSelect options={invernaderosProducto} value={filtroInvernadero} onChange={e => setFiltroInvernadero(e.value)}
            selectedItemsLabel={`${filtroInvernadero.length} invernaderos seleccionados`}
            dataKey="id" optionLabel="nombre" />
        </div>
        <div className="form-group p-col p-col-auto">
          <label htmlFor="filtroVariedad">Variedades</label>
          <MultiSelect options={obtenerOpcionesVariedades()} value={filtroVariedad} onChange={e => setFiltroVariedad(e.value)}
            selectedItemsLabel={`${filtroVariedad.length} variedades seleccionadas`}
            dataKey="id" optionLabel="nombre" />
        </div>

        <ContenedorFiltro label="Tipo">
          <SelectButton optionLabel="name" value={tipo} optionValue="code" options={tipos} onChange={e => setTipo(e.value)} />
        </ContenedorFiltro>
      </div>
    </div>
    <div className="p-col-12 ">
      <ChartContainer type="large">

        {tipoGrafica === "cien" ?
          <Grafica100 /> :
          <GraficaNormal />}



      </ChartContainer>
    </div>

  </div>

}



export default DistribucionPresentacionesPorSemana;

