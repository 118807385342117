const defaultResponse = { 
  librasTotales: 0, 
  totalDeIngreso: 0, 
  ingresoRealInvoice: 0, 
  precioPorLibraReal: 0
}
const getCalculosRechazos = (itemsData = [], presentaciones, data) => {
    if(!itemsData.length === 0) return defaultResponse
    const { precioPorLibra, dineroTotalRechazado } = data
    let peso_neto_caja_libras_default = 0
    const librasTotales = itemsData.reduce((acc, item) => {
        const presentacion = presentaciones.find(pres=>pres.id === item.presentacion_ref) 
        const libras = parseFloat(item.cajas_orden) * parseFloat(presentacion.peso_neto_caja_libras || peso_neto_caja_libras_default)
        peso_neto_caja_libras_default = presentacion.peso_neto_caja_libras ? presentacion.peso_neto_caja_libras
                                                                           : peso_neto_caja_libras_default
        return acc + libras
      },0)
    const totalDeIngreso = librasTotales * precioPorLibra

    const ingresoRealInvoice = totalDeIngreso - dineroTotalRechazado

    const precioPorLibraReal = ingresoRealInvoice / librasTotales 
    return { librasTotales, totalDeIngreso, ingresoRealInvoice, precioPorLibraReal}
}

export default getCalculosRechazos