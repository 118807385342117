import React, { useState, useEffect } from 'reactn';
import { useGlobal } from "reactn";
import { Button } from 'primereact/button';
import InvernaderoCard from "./InvernaderoCard";
import { obtenerCliente } from "../../service/Clientes";
import ContenedorPrincipal from '../common/ContenedorPrincipal';
import RowBotonesTop from '../common/RowBotonesTop';
import ContenedorBotonesTop from '../common/ContenedorBotonesTop';
import SeccionCards from '../common/SeccionCards';
import { getObjetoPorID } from '../../util/functions';

const Invernaderos = (props) => {
  const [, setInvernadero] = useGlobal("invernadero");
  const [usuario,] = useGlobal("usuario");
  const [, setNombreModulo] = useGlobal("nombreModulo");
  const [invernaderos, setInvernaderos] = useState([]);
  const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
  const [productosYVariedades] = useGlobal("productosYVariedades");
  const [invernaderosFisicos] = useGlobal("invernaderosFisicos");
  const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
  const [, setCargando] = useGlobal("cargando")
  useEffect(() => {
    setNombreModulo("Invernaderos");
    setHabilitadoSelectorCiclo(true);
  }, [])

  useEffect(() => {
    setCargando(true)
    if (invernaderosVirtuales && invernaderosFisicos) {
      let promises = [];
      const invernaderos = invernaderosVirtuales.filter(inv => !inv.es_externo);
      for (let x = 0; x < invernaderos.length; x++) {
        let invernadero = invernaderos[x];
        if (!invernadero.es_externo) promises.push(obtenerEmpresaYCliente(invernadero));
      }
      Promise.all(promises).then(values => {
        let copia = invernaderos.map(el => Object.assign({}, el));
        for (let x = 0; x < values.length; x++) {
          let [invId, cliente, invFisico] = values[x];
          let inv = copia.find(el => el.id === invId);
          inv.cliente = cliente;
          inv.invernaderoFisico = invFisico;
        }
        setInvernaderos(copia);
        setCargando(false)
      }).catch(err => {
        console.log(err);
      });
    }
  }, [invernaderosVirtuales, invernaderosFisicos])

  const puedeConsultar = () => ["Administrador"].includes(usuario?.rol);

  const crearInvernadero = () => {
    setInvernadero(null);
    props.history.push("/administrar/invernadero_virtual/crear");
  }

  async function obtenerEmpresaYCliente(invernadero) {
    try {
      const producto = getObjetoPorID(productosYVariedades, invernadero.producto_ref)
      let cliente = { nombre: "Sin Cliente" }, invernaderoFisico = { nombre: "" }, variedad = "";
      if (!invernadero.es_externo) {
        variedad = getObjetoPorID(producto.variedades, invernadero?.variedad_ref).nombre
        cliente = await obtenerCliente(invernadero.cliente_ref)
        invernaderoFisico = getObjetoPorID(invernaderosFisicos, invernadero.invernadero_fisico_ref)
      }
      return [invernadero.id, cliente, invernaderoFisico]
    } catch (error) {
      throw error
    }
  }

  return (
    <ContenedorPrincipal>
      <ContenedorBotonesTop>
        {puedeConsultar() &&
          <RowBotonesTop>
            <Button label="Crear nuevo" onClick={crearInvernadero} />
          </RowBotonesTop>}
      </ContenedorBotonesTop>
      <SeccionCards>
        {invernaderos.map(invernadero => <InvernaderoCard key={invernadero.id} invernadero={invernadero} />)}
      </SeccionCards>
    </ContenedorPrincipal>);
}

export default Invernaderos;//100