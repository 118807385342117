import React, { useState, useEffect, useGlobal } from 'reactn';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import * as firebase from 'firebase/app';
import "firebase/firestore";
import { Dropdown } from 'primereact/dropdown';
import { Password } from 'primereact/password';
import { obtenerRoles } from '../../../service/Roles';
import { obtenerDepartamentos } from '../../../service/Departamentos';
import { crearUsuarioFunctions, modificarUsuarioFunctions, toggleBloqueo as toggleBloqueoFunction, toggleHabilitacionFunctions, obtenerUsuario, guardarAgenteAduanal } from '../../../service/Usuarios';
import { getTransportistas_orderBy_nombre } from '../../../service/embarques';
import { obtenerLocaciones } from '../../../service/Locaciones';
import { ROL_AGENTE_ADUANAL, ROL_AGENTE_ADUANAL_MAYUSCULA, ROL_AGENTE_MANTENIMIENTO, factorLbKg, paises, ROL_AGENTE } from '../../../constants';
import { DeepClone, ordenarArreglaDeObjetosPorLlave, ordenarObjetosPorNombre } from 'util/functions';
import { getFirestoreID } from 'service/Mantenimiento';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

let inicial = {
  displayName: "", rol: null, departamento: null, email: "", password: "", habilitado: true, bloqueado: true, firmaURL: "",
  firmaFile: undefined, nacionalidad: "", agenteAduanalID: ""
}
const FormularioUsuario = (props) => {
  const usuarioId = props.match.params.usuarioID
  const [, setCargando] = useGlobal("cargando");
  const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo")
  const [, setMostrarModalPermisos] = useGlobal("mostrarModalPermiso");
  const [, setModal] = useGlobal("modal");
  const [, setModalConfirmacion] = useGlobal("modalConfirmacion");
  const [, setNombreModulo] = useGlobal("nombreModulo");

  const [editing, setEditing] = useState(false);
  const [usuario, setUsuario] = useState();
  const [errores, setErrores] = useState([]);
  const [roles, setRoles] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [transportistas, setTransportistas] = useState([]);
  const [locaciones, setLocaciones] = useState([]);
  const [extra_emails, setExtraEmails] = useState([]);
  const [state, setState] = useState(inicial)

  useEffect(() => {
    setNombreModulo("Administración");
    setHabilitadoSelectorCiclo(false)
    obtenerDatosIniciales();
  }, [])
  useEffect(() => {
    setErrores([]);
  }, [state, extra_emails])

  const obtenerDatosIniciales = async () => {
    setCargando(true);
    try {
      const promises = [getTransportistas_orderBy_nombre(), obtenerLocaciones(), obtenerRoles(), obtenerDepartamentos()]
      if (usuarioId) { promises.push(obtenerUsuario(usuarioId)); }

      let [transportistas, locaciones, roles, departamentos, usuarioResp] = await Promise.all(promises);

      roles = roles.filter(rol => !!rol.nombre);
      ordenarObjetosPorNombre(roles);
      ordenarArreglaDeObjetosPorLlave(departamentos, "nombre");
      setTransportistas(transportistas);
      setLocaciones(locaciones);
      setRoles(roles);
      setDepartamentos(departamentos);

      ////console.log({usuarioId, usuarioResp})
      if (usuarioResp) {
        setUsuario(usuarioResp);
        let { extra_emails, ...adaptacion } = adaptar(usuarioResp, roles);
        setExtraEmails(extra_emails);
        setState(adaptacion);
        setEditing(true);
      }
      else setEditing(false);

    } catch (error) {
      //console.log("ERROR obtenerDatosIniciales: ", error);
    }
    setCargando(false);
  }

  const puedeEditar = () => { return true; }

  const atras = () => {
    setUsuario(null);
    props.history.goBack();
  }

  const guardar = async () => {
    let errores = validar();
    if (errores.length === 0) {
      setCargando(true);
      let copia = { ...state }, usuarioUID = usuarioId || "";
      copia.rol = copia.rol["nombre"];
      copia.departamento = copia.departamento;

      if (copia.rol !== ROL_AGENTE_ADUANAL) {
        delete copia.nacionalidad;
        delete copia.agenteAduanalID;
        delete copia.extra_emails;
      }
      else if (copia.rol === ROL_AGENTE_ADUANAL && !editing) { copia.agenteAduanalID = getFirestoreID(); }
      if (copia.rol === ROL_AGENTE_ADUANAL) { copia.extra_emails = extra_emails || []; }

      if (copia["firmaFile"]) {
        let storagePath = `firmas/${Date.now()}_${copia["firmaFile"].name}`
        const ref = firebase.storage().ref().child(storagePath)
        let fileSnapshot = await ref.put(copia["firmaFile"])
        let firma = await fileSnapshot.ref.getDownloadURL()
        copia["firma"] = firma
      }
      else copia["firma"] = "";


      if (!editing) {
        try {
          //console.log("DATOS A GUARDAR crearUsuarioFunctions: ", copia);
          const usuarioCreadoUID = await crearUsuarioFunctions(copia);
          usuarioUID = usuarioCreadoUID;
          //console.log("RESP CREATED: ", usuarioCreadoUID)

          if (usuarioCreadoUID) {
            await guardarAgenteAduanalDoc(copia, usuarioUID);
            props.history.push("/admin/usuarios");
          }
        } catch (error) {
          //console.log("ERROR AL GUARDAR ", error);
          setModal({ titulo: "ERROR", msj: "HUBO UN ERROR AL CREAR USUARIO", hide: () => setModal(false) });
        }
      }
      else {
        try {
          //console.log("DATOS A GUARDAR modificarUsuarioFunctions: ", copia);
          const res = await modificarUsuarioFunctions(copia);
          if (res) {
            await guardarAgenteAduanalDoc(copia, usuarioUID);
            props.history.push("/admin/usuarios");
          }
          else setModal({ titulo: "ERROR", msj: "HUBO UN ERROR AL MODIFICAR EL USUARIO", hide: () => setModal(false) });
        } catch (error) {
          //console.log("ERROR AL EDITAR ", error);
          if (error?.code === "permission-denied") { setMostrarModalPermisos(true); }
        }
      }

      setCargando(false)
    }
    else { setErrores(errores); }
  }
  const guardarAgenteAduanalDoc = async(copiaDatos, usuarioUID) => {
    if (copiaDatos.rol === ROL_AGENTE_ADUANAL && usuarioUID) {//Guardar agente aduanal en otra colección
      const datosAgenteAduanal = { nombre: copiaDatos.displayName, tipo: copiaDatos.nacionalidad, usuario_aa_uid: usuarioUID };
      //console.log("AGENTE ADUANAL ", { datosAgenteAduanal, usuarioGuardado: copia });
      await guardarAgenteAduanal(datosAgenteAduanal, copiaDatos.agenteAduanalID);
    }
  }

  const validar = () => {
    let errores = [];
    const requeridos = ["displayName", "rol", "departamento", "email", "password"];
    for (let x = 0; x < requeridos.length; x++) {
      if (state[requeridos[x]] === "" || state[requeridos[x]] === null) { errores.push(requeridos[x]); }
    }
    if (state["password"] && state["password"].length < 6) { errores.push("password"); }
    if (!validateEmail(state["email"])) { errores.push("formatoEmail"); }
    if (state.rol?.nombre === "Transportista" && !state.transportista) { errores.push("transportista") }
    if (state.rol?.nombre === "Agente" && !state.locacion_ref) { errores.push("locacion_ref") }
    if (state.rol?.nombre === ROL_AGENTE_ADUANAL && !state.nacionalidad) { errores.push("nacionalidad") }

    //validar extra emails
    const extraEmailsAValidar = extra_emails || [];
    extraEmailsAValidar.forEach((email, index) => {
      if (!validateEmail(email)) { errores.push(`extraEmailFormato${index}`); }
    })

    return errores;
  }
  const cancelar = () => {
    setUsuario(null);
    setEditing(false);
    props.history.goBack();
  }

  const handleInputChange = (ev) => {
    const stateCopy = Object.assign({}, state);
    let value = ev.target.value;
    stateCopy[ev.target.name] = value;
    setState(stateCopy);
  }
  const handleRolChange = (ev) => {
    const stateCopy = Object.assign({}, state);
    let value = ev.value;

    stateCopy.rol = value;
    if (stateCopy.rol !== ROL_AGENTE) { delete stateCopy.nacionalidad; }
    if (ev.value.nombre !== "Grower") {
      stateCopy["firmaURL"] = "";
      stateCopy["firmaFile"] = undefined;
    }
    setState(stateCopy);
  }
  const handleDepartamentoChange = (ev) => {
    const stateCopy = DeepClone(state);
    let value = ev.value;
    stateCopy.departamento = value.id;

    setState(stateCopy);
  }
  const adaptar = (usuarioDatos, roles) => {
    ////console.log(usuario)
    let adaptado = DeepClone(usuarioDatos);
    let rol = roles.find(el => el.nombre === adaptado.rol)
    adaptado.rol = rol;
    adaptado.firmaURL = adaptado.firma
    return adaptado;
  }

  const toggleHabilitacion = async () => {
    await toggleHabilitacionFunctions(state.id);
    props.history.goBack();
  }
  const toggleBloqueo = async () => {
    await toggleBloqueoFunction(state.id, state.bloqueado);
    props.history.goBack();
  }
  const activarModalConfirmacionBloqueo = () => {
    let mensaje = `¿Estás seguro que deseas ${state.bloqueado ? "desbloquear" : "bloquear"} este usuario?`;
    let titulo = "Confirmación de acción";
    setModalConfirmacion({ mensaje, titulo, aceptar: () => { toggleBloqueo() }, cancelar: () => { } })
  }
  const activarModalConfirmacionHabilitacion = () => {
    let mensaje = `¿Estás seguro que deseas ${state.habilitado ? "deshabilitar" : "habilitar"} este usuario?`;
    let titulo = "Confirmación de acción";
    setModalConfirmacion({ mensaje, titulo, aceptar: () => { toggleHabilitacion() }, cancelar: () => { } })
  }
  const validateEmail = (str) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(str)) { return true; }
    return false;
  }
  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file 
  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    let url = URL.createObjectURL(event.target.files[0])
    const stateCopy = Object.assign({}, state);
    stateCopy.firmaFile = fileUploaded
    stateCopy.firmaURL = url
    setState(stateCopy)
  };

  const rolesItemsTemplate = (rolItem) => {
    ////console.log("ROL ITEM: ", item)
    if (rolItem.nombre === ROL_AGENTE_ADUANAL) { return ROL_AGENTE_ADUANAL_MAYUSCULA; }
    else return rolItem.nombre;
  }

  const eliminarExtraEmails = (index) => {
    let emailsCopia = DeepClone(extra_emails)
    emailsCopia.splice(index, 1)
    setExtraEmails(emailsCopia);
  }
  const handleExtraEmailsChange = (event, index) => {
    let emailsCopia = DeepClone(extra_emails);
    emailsCopia[index] = event.target.value;
    setExtraEmails(emailsCopia);
  }

  return (
    <div className="p-grid p-justify-center">
      <div className="p-col-12 title-container">
        <span className="back-btn" onClick={atras}></span>
        {editing ?
          <React.Fragment>
            <span className="icon-general user-icon"></span>
            <span className="titles-group">
              <h1 className="header-title">Modificar usuario</h1>
            </span>
          </React.Fragment>
          :
          <React.Fragment>
            <span className="icon-general user-icon"></span>
            <span className="titles-group">
              <h1 className="header-title">Crear usuario</h1>
            </span>
          </React.Fragment>}

      </div>
      <div className="p-col-10">
        <div className="p-grid p-fluid ">
          <div className="content-section implementation"> </div>
          <div className="p-col-12">
            <p className="section-title">Información general</p>
            <div className="card">
              <div className="p-grid">
                <div className="form-group p-col-12 p-md-6 p-sm-6">
                  <label >Nombre</label>
                  <InputText value={state["displayName"]} name="displayName"
                    onChange={handleInputChange} />
                  {errores.includes("displayName") && <Message severity="error" text="Este campo es requerido"></Message>}
                </div>
                <div className="form-group p-col-12 p-md-6 p-sm-6">
                  <label>Rol</label>
                  <Dropdown options={roles} value={state.rol} onChange={handleRolChange} optionLabel="nombre" dataKey="id"
                    filter={true} filterPlaceholder="Buscar rol" itemTemplate={rolesItemsTemplate} />
                  {errores.includes("rol") && <Message severity="error" text="Este campo es requerido"></Message>}
                </div>

                {state.rol?.nombre === ROL_AGENTE_ADUANAL &&
                  <div className="form-group p-col-12 p-md-6 p-sm-6">
                    <label>Nacionalidad agente aduanal</label>
                    <Dropdown name="nacionalidad" options={paises} value={state.nacionalidad} onChange={handleInputChange} optionLabel="nombre" optionValue="nacionalidad" />
                    {errores.includes("nacionalidad") && <Message severity="error" text="Este campo es requerido"></Message>}
                  </div>}

                {state.rol?.nombre === "Transportista" &&
                  <div className="form-group p-col-12 p-md-6 p-sm-6">
                    <label>Linea transportista</label>
                    <Dropdown name="transportista" options={transportistas} value={state.transportista} onChange={handleInputChange} optionLabel="nombre" optionValue="id" />
                    {errores.includes("transportista") && <Message severity="error" text="Este campo es requerido"></Message>}
                  </div>}
                {state.rol?.nombre === ROL_AGENTE_MANTENIMIENTO &&
                  <div className="form-group p-col-12 p-md-6 p-sm-6">
                    <label>Locación</label>
                    <Dropdown name="locacion_ref" options={locaciones} value={state.locacion_ref} onChange={handleInputChange} optionLabel="nombre" optionValue="id" />
                    {errores.includes("locacion_ref") && <Message severity="error" text="Este campo es requerido"></Message>}
                  </div>}
                <div className="form-group p-col-12 p-md-6 p-sm-6">
                  <label>Departamento</label>
                  <Dropdown options={departamentos} value={{ id: state.departamento }} onChange={handleDepartamentoChange} optionLabel="nombre" dataKey="id"
                    filter={true} filterPlaceholder="Buscar departamento" />
                  {errores.includes("departamento") && <Message severity="error" text="Este campo es requerido"></Message>}
                </div>
                <div className="form-group p-col-12 p-md-6 p-sm-6">
                  <label>Correo Electrónico</label>
                  <InputText value={state["email"]} name="email" onChange={handleInputChange} keyfilter="email" auto />
                  {errores.includes("email") && <Message severity="error" text="Este campo es requerido"></Message>}
                  {state["email"].length > 0 && errores.includes("formatoEmail") && <Message severity="error" text="Email inválido"></Message>}
                </div>
                <div className="form-group p-col-12 p-md-6 p-sm-6">
                  <label>Password</label>
                  <Password value={state["password"]} name="password" autoComplete="off" onChange={handleInputChange} feedback={false} />
                  {errores.includes("password") && <Message severity="error" text="Este campo es requerido y debe tener al menos 6 caracteres"></Message>}
                </div>
              </div>
            </div>

            {state.rol?.nombre === "Grower" &&
              <><p className="section-title">Firma de usuario</p>
                <div className="card text-center">
                  <div className="p-grid">
                    <div className="p-col-12">
                      <img className="" width={150} src={state.firmaURL} />
                      <Button label="Subir firma" onClick={handleClick} />
                      <input type="file" ref={hiddenFileInput} onChange={handleChange} style={{ display: 'none' }} />
                    </div>
                  </div>
                </div></>}

            {state.rol?.nombre === ROL_AGENTE_ADUANAL &&
              <><p className="section-title">Direcciones a mandar notificaciones de embarques</p>
                <div className="card text-center">
                  <div className="p-grid">
                    <div className="p-col-12">
                      {extra_emails?.map((extraEmail, index) => {
                        return (
                          <div key={`email-${index}`} className="form-group p-grid p-fluid item-dailyweight">
                            <div className="p-col p-grid ">
                              <div className="p-col-auto p-justify-end p-grid p-align-center m-0">
                                <label >Correo eléctronico {index + 1}</label>
                              </div>
                              <div className="p-col">
                                <InputText placeholder="correo eléctronico" value={extraEmail} onChange={e => { handleExtraEmailsChange(e, index) }} />
                                {errores.includes(`extraEmailFormato${index}`) && <Message severity="error" text="Email inválido"></Message>}
                              </div>
                            </div>
                            <div className="p-col-auto">
                              <button className="p-button" onClick={() => { eliminarExtraEmails(index) }}>
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </button>
                            </div>
                          </div>)
                      })}
                      <Button onClick={() => setExtraEmails([...(extra_emails || []), ""])} label="+ Agregar correo" className="p-button-secondary" />
                    </div>
                  </div>
                </div></>}
          </div>
        </div>
      </div>


      <div className="p-col-12">
        <div className="p-grid p-justify-end btn-row">
          <Button label="Cancelar" className="p-button-secondary" onClick={cancelar} />
          {editing &&
            <><Button label={state["habilitado"] ? "Deshabilitar" : "Habilitar"} onClick={activarModalConfirmacionHabilitacion} />
              <Button label={state["bloqueado"] ? "Desbloquear" : "Bloquear"} onClick={activarModalConfirmacionBloqueo} /></>}
          {puedeEditar("guardar") && <Button label="Guardar" onClick={guardar} />}
        </div>
      </div>
    </div>);
}
export default FormularioUsuario;//488