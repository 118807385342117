import React from 'reactn';
import {useGlobal} from "reactn";
import {Button} from 'primereact/button';

import InvernaderoCard from "./InvernaderoCard";
const invernaderosMuestra = [
    { color:"green", demo:"123",invernadero:"Invernadero 1", status:"",nombre:"H1A"},
    { color:"green-dark", demo:"123",invernadero:"Invernadero 1", status:"",nombre:"H1A"},
    { color:"blue", demo:"123",invernadero:"Invernadero 2", status:"",nombre:"H2" },
    { color:"blue-dark", demo:"123",invernadero:"Invernadero 2", status:"",nombre:"H2" },
    { color:"purple", demo:"123",invernadero:"Invernadero 3", status:"",nombre:"H3" },
    { color:"purple-dark", demo:"123",invernadero:"Invernadero 4", status:"",nombre:"F1" },
    { color:"pink", demo:"123",invernadero:"Invernadero 5", status:"",nombre:"F2" },
    { color:"pink-dark", demo:"123",invernadero:"Invernadero 5", status:"",nombre:"F2" },
    { color:"red", demo:"123",invernadero:"Invernadero 6", status:"",nombre:"I1" },
    { color:"red-dark", demo:"123",invernadero:"Invernadero 6", status:"",nombre:"I1" },
    { color:"orange", demo:"123",invernadero:"Invernadero 7", status:"",nombre:"H2A" },
    { color:"orange-dark", demo:"123",invernadero:"Invernadero 7", status:"",nombre:"H2A" },
    { color:"yellow", demo:"123",invernadero:"Invernadero 8", status:"",nombre:"H2B" },
    { color:"yellow-dark", demo:"123",invernadero:"Invernadero 8", status:"",nombre:"H2B" },
    { color:"gray", demo:"123",invernadero:"Invernadero 8", status:"",nombre:"H2C" },
    { color:"gray-dark", demo:"123",invernadero:"Invernadero 8", status:"",nombre:"H2C" },
]
function Cards(props){
        
    return <div className="p-grid">
      <div className="p-col-12">
        <div className="btn-row">
            <Button label="Crear nuevo" />
        </div>
      </div>
      <div className="p-col-12">
        <div className="p-grid">
        {invernaderosMuestra.map(invernadero=><InvernaderoCard invernadero = {invernadero}/>)}
        </div>
      </div>
    </div>

}

export default Cards;