import React, { useGlobal, useState, useEffect } from "reactn";
import { obtenerDiasDeSemana, obtenerIndiceDiaSemana } from "../../service/fechas";
import { Dropdown } from 'primereact/dropdown';
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faChartBar } from '@fortawesome/free-solid-svg-icons';
import { nombresDiasSemana, miliSegundosDia } from "../../constants";
import { DeepClone, getMomentFormatted, getNombrePorID, getObjetoPorID, getProductoYVariedadString, separadoComasDinero, separadoComas } from "../../util/functions";
import {
    obtenerRegistrosSalidas, guardarDatosSalida, obtenerRegistrosEntradasPorSemanaTodos, getSalidakg, getSalidaPrecio,
    obtenerFiltrosVentasNacionalesTodos, tiposOrdenesSalida, getSalidaskgTotales, salidaColl, obtenerRegistroSalida, getEntradaskgTotales
} from "../../service/VentaNacional";
import { getNombreEmpresaPorInvernaderoVirtual, obtenerEmpresa } from "../../service/Empresas";
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { Message } from 'primereact/message';
import { jsPDF } from "jspdf";
import Firma from '../common/Firma'
import recibo from "../../images/recibos/registro_salida_recibo.png"
import FirmaMaritza from "../../images/recibos/Firma_Maritza_Nieto.png"
import ContenedorHeader from '../common/ContenedorHeader'
import ContenedorFiltro from '../common/ContenedorFiltro'
import SeccionFiltros from '../common/SeccionFiltros'
import { obtenerCiclos, incrementarCampoDelCiclo } from "../../service/Ciclos";
import NuevoClienteModal from "./modals/NuevoClienteModal"
import FinalizarSalidaModal from "./modals/FinalizarSalidaModal"
import moment from "moment";

const itemInicial = { invernaderoVirtual: "", kilosDisponibles: "", kilosVender: "", precio: "", precioSugerido: "" }
const inicial = {
    cliente: "",
    dia: {},
    estatus: "Iniciado",
    fecha_creacion: "",

    items: [{ ...itemInicial }],
    orden_num: "",
    tipo: "",
    week: {},
    comentarioAdmin: "",
    solicitante: ""
}
const RegistroSalida = (props) => {

    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [, setCargando] = useGlobal("cargando")
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [ciclo] = useGlobal("ciclo");
    const [usuario] = useGlobal("usuario");
    const [weeks, week, handleWeekChange, updateCicloWeekSelector, setWeek] = useWeekSelector();
    const [datosCargados] = useGlobal("datosCargadosVentaNacional")
    const [editing] = useState(props.match.params.salidaID ? true : false);
    const [salidaID] = useState(props.match.params.salidaID)

    //opciones y datos
    const [registrosSalidas, setRegistrosSalidas] = useGlobal("registrosSalidas")
    const [invernaderos] = useGlobal("invernaderosVirtuales")
    const [invernaderosFisicos] = useGlobal("invernaderosFisicos")
    const [dias, setDias] = useState([]);
    const [dia, setDia] = useState(undefined);
    const [clientes] = useGlobal("clientesVentaNacional")
    const [kilosArray, setKilosArray] = useState([]);

    const [datosCargadosSalida, setDatosCargadosSalida] = useState(false);
    const [state, setState] = useState(DeepClone(inicial));
    const [errores, setErrores] = useState([]);
    const [clienteModalOpen, setClienteModalOpen] = useState(false);
    const [estatusModalOpen, setEstatusModalOpen] = useState(false);

    useEffect(() => {
        setNombreModulo("Ventas Nacionales");
        setHabilitadoSelectorCiclo(false)
    }, [])
    useEffect(() => {
        setCargando(true)
        if (ciclo)
            updateCicloWeekSelector(ciclo);
    }, [ciclo])
    useEffect(() => {
        if (week.nombre != "Cargando") {
            if (!datosCargados)
                obtenerFiltrosVentasNacionalesTodos(ciclo)
            else if (!datosCargadosSalida)
                obtenerDatosIniciales()
            else
                asignarDias(week, dia)

            if (datosCargadosSalida && !editing)
                getKilosDisponibles()
        }
    }, [datosCargados, datosCargadosSalida, week])

    useEffect(() => {
        if (state.estatus === "Iniciado") {
            let stateCopy = DeepClone(state)
            stateCopy.items = state.items.map((item, index) => getItemKiloPrecioInfo(item))
            setState(stateCopy)
        }
    }, [kilosArray])

    useEffect(() => {
        if (state.cliente === "otro")
            setClienteModalOpen(true)
    }, [state.cliente])
    useEffect(() => {
        setErrores([])
    }, [state, week, dia])

    const asignarDias = (weekPassed, diaPassed) => {
        let diasDeSemana = obtenerDiasDeSemana(weekPassed.time).map((dia, index) => ({ nombre: nombresDiasSemana[index], time: dia }))
        let indice = diaPassed ? obtenerIndiceDiaSemana(diaPassed.time) : obtenerIndiceDiaSemana(Math.round(Date.now() / 1000))

        setDias(diasDeSemana)
        setDia(diasDeSemana[indice])
    }

    const obtenerDatosIniciales = async () => {
        let weekSelected = { ...week }

        if (editing) {
            let salida = await obtenerRegistroSalida(ciclo, salidaID)

            if (!salida)
                props.history.push("/ventas_nacionales/tabla_registros_salidas")
            else {
                delete salida.id
                setState({ ...state, ...salida })
                asignarDias(salida.week, salida.dia)
                weekSelected = salida.week
            }
        }

        setWeek(weekSelected)
        setDatosCargadosSalida(true)
        setCargando(false)
    }
    const guardar = async () => {
        if (validar()) {
            try {
                setCargando(true)

                let data = { ...state, dia, week }
                if (!editing) {
                    data.orden_num = await getOrdenNum()
                    data.fecha_creacion = moment(moment().utcOffset("-0600").toLocaleString()).unix().valueOf()
                    data.solicitante = usuario.uid
                    data.items.forEach(item => { if (!item.precioSugerido) item.precioSugerido = "0" })
                }
                if(typeof data.fecha_creacion === "object"){
                    data.fecha_creacion = data.fecha_creacion.seconds
                }
                await guardarDatosSalida(ciclo, data, salidaID)
                setCargando(false)
                props.history.goBack()
            } catch (err) {
                console.log(err)
                setCargando(false)
            }
        }
    }
    const validar = () => getErroresFormulario().length === 0
    const getErroresFormulario = () => {
        let erroresNombres = ["tipo", "cliente"]
        let errores = []
        state.items.forEach((item, index) => {
            if (!item.invernaderoVirtual)
                errores.push(`invernaderoVirtual${index}`)
            if (!item.kilosVender)
                errores.push(`kilosVender${index}`)
            if (item.precio == "" || (parseFloat(item.precio) == 0.00 && state.tipo !== "Donación"))
                errores.push(`precio${index}`)
        })
        erroresNombres.forEach(nombre => {
            if (!state[nombre])
                errores.push(nombre)
        })

        setErrores(errores)
        return errores
    }

    const generarRecibo = async () => {
        let image = new Image
        image.src = recibo
        const doc = new jsPDF({
            orientation: "landscape",
            unit: "px",
            format: [1280, 720]
        });
        doc.addImage(image, "PNG", 0, 0, 1280, 720);

        //agregar textos (orden_num, fecha, tipo, nombre cliente, direccion cliente, semana)
        doc.setFontSize(18)
        doc.text(state.orden_num + "", 1126, 70, 'left');
        doc.text(getMomentFormatted(state.dia.time * 1000, "DD/MM/YYYY"), 1100, 119, 'left');
        doc.text(state.tipo, 710, 60, 'left');
        doc.text(getNombrePorID(clientes, state.cliente), 134, 162, 'left');
        doc.text(getObjetoPorID(clientes, state.cliente).direccion || "", 150, 190, 'left');
        doc.text(state.week.nombre, 992, 166, 'left');

        let empresasNombres = []
        for (let x = 0; x < state.items.length; x++) {
            //cantidad, unidad, invernadero + producto/variedad, precio, total(cantidad * precio),
            doc.text(separadoComas(state.items[x].kilosVender) + "", 60, 350 + (x * 20), 'left');
            doc.text("KG", 286, 350 + (x * 20), 'left');

            let invernadero = getObjetoPorID(invernaderos, state.items[x].invernaderoVirtual)
            doc.text(`${invernadero.nombre} (${getProductoYVariedadString(invernadero)})`, 516, 350 + (x * 20), 'left');

            doc.text(state.items[x].precio + "", 750, 350 + (x * 20), 'left');
            let precioTotal = state.items[x].kilosVender * state.items[x].precio
            doc.text("$" + separadoComasDinero(precioTotal) + "", 980, 350 + (x * 20), 'left')

            const nombreEmpresa = await getNombreEmpresaPorInvernaderoVirtual(invernadero);
            if (!empresasNombres.includes(nombreEmpresa)) { empresasNombres.push(nombreEmpresa); }
        }

        //empresas, precio total, comentario admin
        doc.text(empresasNombres.join(", "), 742, 40, 'left');
        let precioSalidaTotal = getSalidaPrecio(state.items)
        doc.text("$" + separadoComasDinero(precioSalidaTotal), 980, 580, 'left');
        doc.text(state.comentarioAdmin, 58, 578, 'left');

        image.src = recibo
        doc.addImage(FirmaMaritza, "PNG", 370, 606, 212, 72);

        doc.save(`Recibo.pdf`);
    }

    const puedeEditar = () => state.estatus === "Iniciado"
    const puedeCambiarEstatus = () => state.estatus === "Iniciado" && editing && ["Finanzas", "Administrador"].includes(usuario.rol);

    const getKilosDisponibles = async () => {
        let kilosArray = []
        let entradas = await obtenerRegistrosEntradasPorSemanaTodos(ciclo, week, invernaderos)
        let allSalidas = registrosSalidas;

        if (!allSalidas) {
            allSalidas = await obtenerRegistrosSalidas(ciclo)
            setRegistrosSalidas(allSalidas)
        }
        allSalidas = DeepClone(allSalidas).sort((a, b) => b.orden_num - a.orden_num)
        invernaderos.forEach((invernadero, index) => {
            let entradasTotalSemana = getEntradaskgTotales(entradas[index])
            let salidasTotalSemana = getSalidaskgTotales(allSalidas.filter(el => el.week.time === week.time), invernadero.id)
            let precioSugerido = getPrecioSugerido(invernadero.id, allSalidas)

            kilosArray.push({ id: invernadero.id, kilosDisponibles: entradasTotalSemana - salidasTotalSemana, precioSugerido })
        })

        setKilosArray(kilosArray)
    }

    const getOrdenNum = async () => {
        let allCiclos = await obtenerCiclos()

        let numeroOrdenes = 1
        for (let index in allCiclos) {
            numeroOrdenes += allCiclos[index].total_registro_salidas_VN || 0
        }

        await incrementarCampoDelCiclo(ciclo, "total_registro_salidas_VN").catch(error => console.log("Error incrementar: ", error))
        return numeroOrdenes
    }

    const getPrecioSugerido = (invernaderoId, salidas) => {
        let precioSugerido = 0, searching = true;
        salidas.forEach(salida => {
            salida.items.forEach(item => {
                if (item.invernaderoVirtual === invernaderoId && parseInt(item.precio) !== 0 && searching) {
                    precioSugerido = item.precio
                    searching = false
                }
            })
        })
        return precioSugerido
    }

    const handleInputChange = (event, index = -1) => {
        let stateCopy = DeepClone(state)
        let name = event.target.name

        if (index >= 0) {
            stateCopy.items[index][name] = event.target.value
            if (name === "invernaderoVirtual") getKilosDisponibles()
        }
        else
            stateCopy[name] = event.target.value

        setState(stateCopy)
    }

    const getItemKiloPrecioInfo = (item) => {
        let found = kilosArray.find(el => el.id === item.invernaderoVirtual) || {}
        item.kilosDisponibles = found.kilosDisponibles || 0
        item.precioSugerido = found.precioSugerido === 0 ? "N/A" : found.precioSugerido
        return item
    }

    const agregarItem = () => {
        let copiaItems = DeepClone(state.items)
        copiaItems.push({ ...itemInicial })
        setState({ ...state, items: copiaItems });
        getKilosDisponibles()
    }
    const eliminarItem = (index) => {
        let copiaItems = DeepClone(state.items)
        copiaItems.splice(index, 1)
        setState({ ...state, items: copiaItems });
    }

    return (
        <div className="p-grid p-justify-center">
            <ContenedorHeader titulo="Ventas nacionales: Registro de salida" subtitulo={state.orden_num ? `Orden # ${state.orden_num}` : ""} iconos={"mexico-icon"} atras={() => props.history.goBack()} />

            <SeccionFiltros >
                <ContenedorFiltro label="clientes">
                    <Dropdown id="clientes" placeholder="Seleccionar"
                        optionValue="id" optionLabel="nombre" name="cliente"
                        options={[...clientes, { nombre: "Otro", id: "otro" }]} value={state.cliente}
                        onChange={handleInputChange} disabled={!puedeEditar()}
                        filter={true} filterPlaceholder="Buscar cliente" />
                    {errores.includes("cliente") && <Message severity="error" text="Este campo es requerido" />}
                </ContenedorFiltro>
                <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} disabled={editing} />
                <ContenedorFiltro label="día">
                    <Dropdown id="filtroDias" placeholder="Seleccionar"
                        dataKey="time" optionLabel="nombre"
                        options={dias} value={dia} disabled={!puedeEditar()}
                        onChange={(e) => { setDia(e.target.value) }} />
                </ContenedorFiltro>
                <ContenedorFiltro label="tipo de orden">
                    <Dropdown id="tipo" placeholder="Seleccionar"
                        options={tiposOrdenesSalida} value={state.tipo} name="tipo"
                        onChange={handleInputChange} disabled={!puedeEditar()} />
                    {errores.includes("tipo") && <Message severity="error" text="Este campo es requerido" />}
                </ContenedorFiltro>
            </SeccionFiltros >

            <div className="p-col-10">
                <div className="p-grid p-fluid ">
                    <div className="p-col-12">
                        <p className="section-title">Detalle de la venta</p>
                        <div className="card">
                            {state.items.map((item, index) => {

                                return <div key={item.id} className="form-group p-grid p-fluid item-dailyweight">
                                    <div className="p-col p-grid ">
                                        <div className="p-col-auto p-justify-end p-grid p-align-center m-0">
                                            <label >Invernadero</label>
                                        </div>
                                        <div className="p-col">
                                            <Dropdown name="invernaderoVirtual" placeholder="Seleccionar" optionValue="id" optionLabel="nombre" options={invernaderos} value={item.invernaderoVirtual} onChange={(e) => handleInputChange(e, index)} disabled={!puedeEditar()} />
                                            {errores.includes(`invernaderoVirtual${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                        </div>
                                    </div>
                                    <div className="p-col p-grid ">
                                        <div className="p-col-auto p-justify-end p-grid p-align-center m-0">
                                            <label >Kilos disponibles</label>
                                        </div>
                                        <div className="p-col">
                                            <InputText placeholder="Seleccionar" value={item.kilosDisponibles} disabled />
                                        </div>
                                    </div>
                                    <div className="p-col p-grid ">
                                        <div className="p-col-auto p-justify-end p-grid p-align-center m-0">
                                            <label >Kilos a vender</label>
                                        </div>
                                        <div className="p-col">
                                            <InputText name="kilosVender" placeholder="Seleccionar" value={item.kilosVender} onChange={(e) => handleInputChange(e, index)} disabled={!puedeEditar()} />
                                            {errores.includes(`kilosVender${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                        </div>
                                    </div>
                                    <div className="p-col p-grid ">
                                        <div className="p-col-auto p-justify-end p-grid p-align-center m-0">
                                            <label >Precio por kilo</label>
                                        </div>
                                        <div className="p-col">
                                            <InputText name="precio" type="number" step=".01" placeholder="Seleccionar" value={item.precio} onChange={(e) => handleInputChange(e, index)} disabled={!puedeEditar()} />
                                            {errores.includes(`precio${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                        </div>
                                    </div>
                                    <div className="p-col p-grid ">
                                        <div className="p-col-auto p-justify-end p-grid p-align-center m-0">
                                            <label >Precio sugerido</label>
                                        </div>
                                        <div className="p-col">
                                            <InputText placeholder="Precio sugerido" value={item.precioSugerido} disabled />
                                        </div>
                                    </div>

                                    {puedeEditar() && state.items.length > 1 && <div className="p-col-auto">
                                        <button className="p-button" onClick={() => { eliminarItem(index) }}>
                                            <FontAwesomeIcon icon={faTrashAlt} />
                                        </button>
                                    </div>}

                                </div>
                            })}
                            {puedeEditar() && <Button onClick={agregarItem} label="+ Agregar item a orden" className="p-button-secondary" />}
                            <div className="item-totalweight">
                                Total: {separadoComasDinero(getSalidakg(state.items))} kg
                            </div>
                            <div className="item-totalweight">
                                Precio total: ${separadoComasDinero(getSalidaPrecio(state.items))}
                            </div>
                        </div>
                    </div>

                    <div className="p-col-12">
                        <p className="section-title">Administración de la venta</p>
                        <div className="card">
                            <div className="p-grid ">
                                <label htmlFor="detalle">Comentario (Opcional)</label>
                                <InputTextarea disabled={!puedeEditar()} name="comentarioAdmin" rows={3} cols={30} autoResize={true} id="comentarioAdmin" value={state.comentarioAdmin}
                                    onChange={handleInputChange} placeholder={"Comentario administrador de ventas nacionales"} />
                            </div>
                        </div>
                    </div>
                </div>

                <FinalizarSalidaModal onHide={() => setEstatusModalOpen(false)} goBack={() => props.history.goBack()} state={state} estatusModalOpen={estatusModalOpen} generarRecibo={generarRecibo} salidaID={salidaID} />
                <NuevoClienteModal onHide={() => setClienteModalOpen(false)} clienteModalOpen={clienteModalOpen} handleInputChange={handleInputChange} />

                {/* Firma Administrador */}
                {state.estatus === "Finalizado" && <Firma imagen={FirmaMaritza} nombre={state.firmaAdminNombre} rol={state.firmaAdminRol} />}

                {state.estatus === "Finalizado" &&
                    <div className="p-grid p-justify-end btn-row">
                        <Button label="Descargar recibo" onClick={generarRecibo} />
                    </div>}

                {puedeEditar() && <div className="p-grid p-justify-end btn-row">
                    {puedeCambiarEstatus() && <Button label="Finalizar" className="p-button-secondary" onClick={() => setEstatusModalOpen(true)} />}
                    <Button label="Cancelar" className="p-button-secondary" onClick={() => props.history.goBack()} />
                    <Button label="Guardar" onClick={guardar} />
                </div>}
            </div>

        </div >
    );
}
export default RegistroSalida