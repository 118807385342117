import * as firebase from "firebase/app";
import "firebase/firestore";
import moment from "moment";
import tz from "moment-timezone";
import 'moment/locale/es';
import { flexDate, obtenerInicioSemana } from "./fechas";
import { uploadStatistics } from './Estadisticas'

function registrarInvocaciones(count) {
    uploadStatistics(count)
}

moment.locale("es");
export function obtenerActividades() {
    var db = firebase.firestore();
    return new Promise((resolve, reject) => {
        let results = [];
        db.collection("actividades").orderBy("importante", "desc").orderBy("nombre", "asc").get().then(snap => {
            registrarInvocaciones(snap.size)
            snap.forEach(doc => {
                let data = doc.data();
                data.id = doc.id;
                results.push(data);
            })
            resolve(results);
        }).catch(err => {
            reject(err);
        });
    })

}
export const obtenerActividadesCalidadDeTrabajo = async () => {
    const response = await firebase.firestore().collection("actividades").orderBy("importante", "desc").orderBy("nombre", "asc").get();
    const actividadesCalidadDeTrabajo = response.docs.map(el => ({ id: el.id, ...el.data() }))
    return actividadesCalidadDeTrabajo;
}

export function guardarActividadesDiarias(dia, ciclo, invernadero, actividades, borradas) {




    var db = firebase.firestore();
    dia = moment(dia * 1000).startOf("day").unix();
    //encontrar 
    return new Promise((resolve, reject) => {
        const timestamp = flexDate(dia)
        db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero).collection("registro_actividades").where("dia", ">=", timestamp.inicio).where("dia", "<=", timestamp.fin).get().then(resp => {



            let id = null;
            resp.forEach(doc => {

                id = doc.id;
            })
            if (!resp.empty) {

                return db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero).collection("registro_actividades").doc(id).
                    update({
                        actividades_planeadas: actividades
                    }).then(resp => {
                        borrarActividadesDeProduccion(ciclo, invernadero, id, borradas).then(resp)
                        resolve(resp)
                    }).then(resp => resolve(resp))
                    .catch(err => {
                        reject(err);
                    });
            }
            else {

                return db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero).collection("registro_actividades").
                    add({
                        dia: dia,
                        actividades_planeadas: actividades
                    }).then(resp => resolve(resp)).catch(err => {
                        reject(err);
                    });
            }
        })
    })

}
function borrarActividadesDeProduccion(ciclo, invernadero, registro, aBorrar) {
    var db = firebase.firestore();
    let promises = [];
    return new Promise((resolve, reject) => {
        db.collection(ciclo).doc("ciclos").collection("invernaderos_virtuales").doc(invernadero).collection("registro_actividades").doc(registro).
            collection("produccion").get().then(resp => {
                resp.forEach(doc => {
                    let subpromises = [];
                    promises.push(new Promise((resolve, reject) => {
                        db.doc(doc.ref.path).get().then(resp => {
                            let data = resp.data();
                            let actividades = data.actividades;


                            for (let x = 0; x < actividades; x++) {
                                delete actividades[aBorrar[x]];
                            }


                            subpromises.push(db.doc(doc.ref.path).update({ actividades: actividades }))
                        })
                        Promise.all(subpromises).then(r => {
                            resolve(r);
                        })
                    }))

                })
                Promise.all(promises).then(r => {
                    resolve(r);
                })
            });
    })
}
export function buscarRegistroDia(ciclo, invernadero, dia) {
    dia = moment(dia * 1000).startOf("day").unix();
    var db = firebase.firestore();
    return new Promise((resolve, reject) => {

        const timestamp = flexDate(dia);
        db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero).
            collection("registro_actividades").where("dia", ">=", timestamp.inicio).where("dia", "<=", timestamp.fin).get().then(resp => {
                registrarInvocaciones(resp.size)
                let result = [];


                if (resp.empty) {
                    resolve(null);
                } else {
                    resp.forEach((doc) => {
                        let data = doc.data();
                        data.id = doc.id;
                        result.push(data);
                    });
                    resolve(result[0]);
                }

            }).catch(err => {
                reject(err);
            });
    });
}

export function obtenerActividadesDia(ciclo, invernadero, dia) {

    dia = moment(dia * 1000).startOf("day").unix();
    var db = firebase.firestore();
    return new Promise((resolve, reject) => {
        let result = [];
        const timestamp = flexDate(dia);
        db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero).
            collection("registro_actividades").where("dia", ">=", timestamp.inicio).where("dia", "<=", timestamp.fin).get().then(resp => {
                registrarInvocaciones(resp.size)
                if (resp.empty) {


                    resolve({});
                }
                resp.forEach((doc) => {


                    result.push(doc)

                })
                return db.doc(result[0].ref.path).collection("produccion").get().then(async resp => {

                    let result = [];
                    if (resp.size == 0) {
                        resolve([]);
                    }
                    else {
                        let actividades = await db.collection("actividades").get();
                        let actividadesData = [];
                        actividades.forEach(el => {


                            let data = el.data();

                            data.id = el.id;
                            actividadesData.push(data)
                        });



                        resp.forEach(doc => {

                            let actividades = Object.keys(doc.data().actividades).map(el => {
                                return actividadesData.find(act => act.id === el).nombre
                            });

                            result.push(actividades);
                        });


                        result = result.flat();
                        var set = new Set(result);
                        resolve(Array.from(set));
                    }
                }).catch(err => {
                    console.log("ERROR");
                    console.log(err);
                });
            })


    })

}

export const registrarActividad = (actividad, productos, medleysGeneral) => {
    const db = firebase.firestore();
    let bpu = {};
    productos.forEach(prod => {
        prod.variedades.forEach(currVar => {
            bpu[currVar.id] = parseFloat(prod.costo);
        })
        bpu[prod.id] = parseFloat(prod.costo);
        if (prod.mezcla || prod.MEDLEYS_GENERAL || prod.id === medleysGeneral?.id && medleysGeneral?.id) { bpu[medleysGeneral.id] = parseFloat(prod.costo); }
    })
    actividad.bonos_por_unidad = bpu;


    return db.collection("actividades").add(actividad);
}
export const guardarActividad = (actividad, productos, medleysGeneral) => {

    const db = firebase.firestore();
    let id = actividad.id;
    delete actividad.id;
    let bpu = {};
    productos.forEach(prod => {
        prod.variedades.forEach(currVar => {
            bpu[currVar.id] = parseFloat(prod.costo);
        })
        bpu[prod.id] = parseFloat(prod.costo);
        if (prod.mezcla || prod.MEDLEYS_GENERAL || prod.id === medleysGeneral?.id && medleysGeneral?.id) { bpu[medleysGeneral.id] = parseFloat(prod.costo); }
    })
    actividad.bonos_por_unidad = bpu;


    return db.collection("actividades").doc(id).update(actividad);
}
export const registrarHistorico = (original, nuevo) => {

    const db = firebase.firestore();
    let bonosOriginales = original.bonos_por_unidad;
    let bonosNuevos = nuevo.bonos_por_unidad;
    let cambios = {};
    let semana = obtenerInicioSemana().unix();
    let data = {
        semana: semana,

    };

    Object.keys(bonosOriginales).forEach(k => {
        if (bonosOriginales[k] != bonosNuevos[k]) {
            cambios[k] = bonosOriginales[k];
        }

    })
    if (Object.keys(cambios).length > 0) {


        data["bonos"] = cambios;
        db.collection("historico_actividades").add(data);
    } else {

    }
}

export const obtenerActividadesImportantes = async (params) => {
    const db = firebase.firestore();
    const response = await db.collection("actividades").where("importante", "==", true).get()

    registrarInvocaciones(response.size)
    return response
}
export const getActividadesImportantes = async (params) => {
    const response = await firebase.firestore().collection("actividades").where("importante", "==", true).get();
    const actividades = response.docs.map(el => ({ ...el.data(), id: el.id }));
    registrarInvocaciones(response.size)
    return actividades
}

export const obtenerActividadesGrafica = async () => {
    const db = firebase.firestore();
    let nombresActividades = ["Cosecha", "Enrede", "Enrede + 1  Hoja", "Bajado (1.5 vueltas)", "Raleo", "Clipeo", "Clipeo + 1  Hoja"];
    let snap = await db.collection("actividades").where("nombre", "in", nombresActividades).get();
    registrarInvocaciones(snap.size)

    return snap.docs.map(el => ({ ...el.data(), id: el.id }));
}

export const obtenerActividad = async (actividadId) => {
    const db = firebase.firestore();
    const obj = await db.collection("actividades").doc(actividadId).get();
    registrarInvocaciones(obj.size)
    const data = { id: obj.id, ...obj.data() };
    return data
} //263