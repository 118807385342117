import React from 'react'
import ContenedorPrincipal from '../common/ContenedorPrincipal'
import ContenedorHeader from '../common/ContenedorHeader'
import SeccionFiltros from '../common/SeccionFiltros'
import ContenedorTabla from '../common/ContenedorTabla'
import ContenedorFiltro from '../common/ContenedorFiltro'
import { useEffect, useGlobal, useState } from 'reactn'
import { Dropdown } from 'primereact/dropdown'
import { MultiSelect } from 'primereact/multiselect'
import { obtenerInvernaderosVirtuales, obtenerInvernaderosVirtualesInternos } from "../../service/Invernaderos";
import { obtenerResumenPesosSemana } from '../../service/Producto';
import ReactApexChart from 'react-apexcharts'
import { obtenerCiclo } from '../../service/Ciclos'
import { obtenerListaDeSemanas } from '../../service/fechas'
import { Button } from 'primereact/button';
import { ToggleButton } from 'primereact/togglebutton';
import { paletaColores, getColores } from 'util/functions'

const optionsOrig = {
  colors: paletaColores,
  chart: {
    height: 350,
    type: 'line',
    zoom: {
      enabled: true
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'straight'
  },
  title: {
    text: 'Peso de caja por semana',
    align: 'left'
  },
  grid: {
    row: {
      colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
      opacity: 0.5
    },
  },
  //colors: ["#d1d138","#d1d138","#37b354"],
  tooltip: {
    onDatasetHover: {
        highlightDataSeries: true,
    },
    enabled:true,
    followCursor:true,
    
    y:{
      decimalsInFloat: 2,
      formatter: function(val, opts) {
        return val && val.toFixed(2) + " kg"
      }
    }
    
  },
  legend: {
    horizontalAlign: 'center',
    offsetX: 40
  },
  yaxis: [
    {
      seriesName: 'Revenue',
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: '#FEB019'
      },
      labels: {
        style: {
          colors: '#FEB019',
        },
        formatter: function(val, opts) {
          return val && val.toFixed(2) + " kg"
        }
      },
      title: {
        text: "Kilos",
        style: {
          color: '#FEB019',
        }
      },
      decimalsInFloat: 2,
    }
  ],



}
export default function PesosSemanaCosecha(props) {
  const atras = (params) => {
    props.history.goBack();
  }
  const [invernaderos, setInvernaderos] = useState([])
  const [filtrosInvernaderos, setFiltrosInvernaderos] = useState([])

  const [cicloId, setCicloId] = useGlobal("ciclo")
  const [cicloObj] = useGlobal("cicloObj")
  const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
  const [cargando, setCargando] = useGlobal("cargando")

  const [ciclos, setCiclos] = useGlobal("ciclos")
  const [ciclosConInvernaderos, setCiclosConInvernaderos] = useState([])

  const [filtrosCiclos, setFiltrosCiclos] = useState([])
  const [filtrosCiclosConInvernaderos, setFiltrosCiclosConInvernaderos] = useState([])

  const [multiCiclo, setMultiCiclo] = useState(false)
  const [series, setSeries] = useState([])
  const [options, setOptions] = useState({ ...optionsOrig })
  useEffect(() => {
    if (cicloId && ciclos.length > 0) {
      obtenerDatosIniciales();
    }
  }, [cicloId, ciclos])

  useEffect(() => {
    if (filtrosInvernaderos.length > 0 && !multiCiclo) {
      obtenerResumenes();
    }
  }, [filtrosInvernaderos])

  useEffect(() => {
    if (filtrosCiclos.length > 0 && multiCiclo) {
      obtenerResumenesCiclos(filtrosCiclos)
    }
  }, [filtrosCiclos])

  useEffect(() => {
    //window.alert("ciclosConInvernaderos[0].filtrosInvernaderos")
  }, [ciclosConInvernaderos])

  useEffect(() => {
    if (multiCiclo && filtrosCiclos.length > 0) {
      let filtrosCopy = [...filtrosCiclos]
      let index = -1
      filtrosCopy.forEach((cic, ind) => {
        if (cic.id === cicloId)
          index = ind
      })
      if (index > -1) {
        //window.alert(filtrosInvernaderos.length)
        filtrosCopy[index].filtrosInvernaderos = [...filtrosInvernaderos]
        setFiltrosCiclos(filtrosCopy)
        obtenerResumenesCiclos(filtrosCopy)
      }
    }
    else if (!multiCiclo && filtrosInvernaderos.length > 0) {
      setFiltrosCiclos([])
      obtenerResumenes();
    }
  }, [multiCiclo])

  const obtenerDatosIniciales = async () => {

    let invernaderos = await obtenerInvernaderosVirtualesInternos(cicloId);
    setInvernaderos(invernaderos);

    if (filtrosCiclos.length === 0) {
      let ciclosCopy = ciclos.map(cic => ({ ...cic }))
      for (let x = 0; x < ciclos.length; x++) {
        console.log(ciclosCopy[x]?.id)
        await obtenerInvernaderosVirtuales(ciclosCopy[x]?.id).then(allInvernaderos => {
          ciclosCopy[x].invernaderos = allInvernaderos
        })
      }
      let currentCiclo = ciclosCopy.find(cic => cic.id === cicloId)
      setFiltrosCiclos([currentCiclo])
      setCiclosConInvernaderos(ciclosCopy)
      setOptions({...options, colors: getColores(invernaderosVirtuales)})
    }
  }

  const obtenerResumenes = async (params) => {
    setCargando(true)
    let seriesResumenes = []
    for (let x = 0; x < filtrosInvernaderos.length; x++) {
      let pesosCosecha = await obtenerResumenPesosSemana(cicloId, filtrosInvernaderos[x].id);
      seriesResumenes.push({
        name: filtrosInvernaderos[x].nombre, data: pesosCosecha,id: filtrosInvernaderos[x].id, options: {
          colors: ["#008000", "#FF0000"]
        }
      })
      
      
    }

    setOptions({...options, colors: getColores(filtrosInvernaderos)})
    setSeries(seriesResumenes)
    setCargando(false)
  }

  const obtenerResumenesCiclos = async (filtrosCiclosPassed) => {
    setCargando(true)
    let allSeries = []


    for (let y = 0; y < filtrosCiclosPassed.length; y++) {
      let seriesResumenes = []

      if (filtrosCiclosPassed[y].filtrosInvernaderos) {
        for (let x = 0; x < filtrosCiclosPassed[y].filtrosInvernaderos.length; x++) {
          let pesosCosecha = await obtenerResumenPesosSemana(filtrosCiclosPassed[y].id, filtrosCiclosPassed[y].filtrosInvernaderos[x].id);
          seriesResumenes.push({
            name: filtrosCiclosPassed[y].filtrosInvernaderos[x].nombre + " - " + filtrosCiclosPassed[y].nombre, data: pesosCosecha, options: {
              colors: ["#008000", "#FF0000"]
            }
          })
          
          
        }
      }
      allSeries.push(...seriesResumenes)
    }

    let semanas = await obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
    setSeries(allSeries)
    setCargando(false)
  }

  return (
    <ContenedorPrincipal extra="chart-page">
      <ContenedorHeader titulo="Gráfica peso de caja de cosecha" iconos={"chart-icon"} atras={atras} />
      <SeccionFiltros>
        <ContenedorFiltro filtrolabel="no" label="Tipo de gráfica">
          <ToggleButton onLabel={"Multiciclo"} offLabel={"Por ciclo actual"} checked={multiCiclo} onChange={(ev) => setMultiCiclo(ev.value)} ></ToggleButton>
        </ContenedorFiltro>
        {!multiCiclo && <ContenedorFiltro label="Invernadero">
          < MultiSelect options={invernaderos} value={filtrosInvernaderos} onChange={(e) => setFiltrosInvernaderos(e.value)}
            selectedItemsLabel={`${filtrosInvernaderos.length} invernaderos seleccionados`}
            optionLabel="nombre" dataKey="id" />
        </ContenedorFiltro>}

        {multiCiclo && <ContenedorFiltro label="Ciclos a comparar" filtrolabel="no"   >

          < MultiSelect options={ciclosConInvernaderos} value={filtrosCiclos} 
            selectedItemsLabel={`${filtrosCiclos.length} invernaderos seleccionados`}
            onChange={(e) => {
            setFiltrosCiclos(e.target.value)
          }}
            optionLabel="nombre" dataKey="id" selectedItemsLabel={`${filtrosCiclos.length} ciclos seleccionados`} />
        </ContenedorFiltro>}
        {
          multiCiclo && filtrosCiclos.map((cic, index) => {
            return (
              <ContenedorFiltro label={`${cic.nombre}`}>
                < MultiSelect options={cic.invernaderos} value={cic.filtrosInvernaderos || []}
                  selectedItemsLabel={cic.filtrosInvernaderos && `${cic.filtrosInvernaderos.length} invernaderos seleccionados`} onChange={(e) => {
                  let filtrosInvernaderosCopy = [...filtrosCiclos]
                  filtrosInvernaderosCopy[index].filtrosInvernaderos = e.target.value
                  setFiltrosCiclos(filtrosInvernaderosCopy)
                }}
                  optionLabel="nombre" dataKey="id" />
              </ContenedorFiltro>
            )
          })
        }


      </SeccionFiltros>


      <div className="p-col-12 chart-container-filters">
        <ReactApexChart options={options} series={series} type="line" height="100%" />
      </div>
    </ContenedorPrincipal>
  )
}
