import React, { useState, useEffect } from 'react';
import { useGlobal } from "reactn";
import { obtenerActividadesGrafica } from '../../service/actividades';
import { obtener_empleados_invernadero, obtenerEmpleado } from '../../service/RhService';
import { obtenerResumenGraficaEmpleados } from '../../service/nomina';
import { obtenerListaDeSemanas } from '../../service/fechas';
import LineChart from '../common/LineChart';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { nombreActividadClipeoPlus, nombreActividadEnrede, nombreActividadEnredePlus, nombreActividadClipeo } from '../../constants';
import { DeepClone, ordenarArreglaDeObjetosPorLlave } from 'util/functions';
import ContenedorHeader from 'components/common/ContenedorHeader';

const tooltipFunctionApex = (value) => Math.round(value);
const optionsOrig = {
  chart: {
    height: 350,
    type: 'area',
    zoom: {
      enabled: true,
      type: "x",
      autoScaleYAxis: true
    }
  },
  dataLabels: { enabled: false },
  title: {
    text: 'Actividad realizada por empleados desglosada por actividad',
    align: 'left'
  },
  grid: {
    row: {
      colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
      opacity: 0.5
    },
  },
  tooltip: {
    y: { formatter: tooltipFunctionApex },
    x: { formatter: tooltipFunctionApex }
  },
  xaxis: { categories: [] },
  yaxis: [{ title: { text: "Cantidad de actividad realizada (líneas/cajas)" } }]
}

const EmpleadosActividad = (props) => {
  const [ciclo,] = useGlobal("ciclo");
  const [cicloObj,] = useGlobal("cicloObj");
  const [invernadero, setInvernadero] = useState(props.location?.data?.invernadero);
  const [, setCargando] = useGlobal("cargando");
  const [actividad, setActividad] = useState(null);
  const [actividades, setActividades] = useState();
  const [actividadesFiltrados, setActividadesFiltrados] = useState();
  const [empleados, setEmpleados] = useState([]);
  const [empleadosOpciones, setEmpleadosOpciones] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [options, setOptions] = useState({ ...optionsOrig })
  const [empleadosFiltro, setEmpleadosFiltro] = useState([])
  const [invernaderosVirtuales] = useGlobal("invernaderosInternos");
  const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
  const [, setNombreModulo] = useGlobal("nombreModulo");

  useEffect(() => {
    setHabilitadoSelectorCiclo(true);
    setNombreModulo("Gráficas");
  }, [])
  useEffect(() => {
    if (cicloObj && invernadero) { cargaInicialDeDatos(); }
  }, [cicloObj, invernadero])

  useEffect(() => {
    if (actividad?.id && empleados?.length > 0 && datasets?.length > 0) {
      const datosActividad = datasets?.find(el => el.actividadId === actividad?.id);
      if (datosActividad) {
        const datasetsQueTienenRegistros = datosActividad.dataset?.filter(el => el.sumaData > 0) || [];
        const empleadoIdsQueTienenRegistros = datasetsQueTienenRegistros?.map(el => el.datasetId) || [];
        const empleadosQueTienenRegistros = empleados?.filter(empleado => empleadoIdsQueTienenRegistros.includes(empleado.id)) || [];
        const empeladosOpcionesFormeatados =
          empleadosQueTienenRegistros?.map(empleado => ({ ...empleado, nombreCompacto: `${empleado.nombre.trim()} ${empleado.apellidos.trim()}` }))
        setEmpleadosOpciones(empeladosOpcionesFormeatados);
        setEmpleadosFiltro(empeladosOpcionesFormeatados);
      }
    }
  }, [datasets, actividad])

  const cargaInicialDeDatos = async (params) => {
    setCargando(true);
    const actividadesResp = actividades || await obtenerActividadesGrafica();
    const semanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
    const copiaOptions = DeepClone(options);
    copiaOptions.xaxis.categories = semanas.map(el => el.nombreCompacto);
    setOptions(copiaOptions);

    const empleadosInvernaderoCiclo = await obtener_empleados_invernadero(ciclo, invernadero.id);
    const empleadosRefs = empleadosInvernaderoCiclo.map(el => el.empleado_ref);
    const promesasEmpleados = empleadosRefs.map(el => obtenerEmpleado(el));
    const empleadosResp = await Promise.all(promesasEmpleados);
    const empleadosFormeatados = empleadosResp?.map(empleado => ({ ...empleado, nombreCompacto: `${empleado.nombre.trim()} ${empleado.apellidos.trim()}` }))
    ordenarArreglaDeObjetosPorLlave(empleadosFormeatados, "nombreCompacto");
    setEmpleados(empleadosFormeatados);
    setEmpleadosOpciones(empleadosFormeatados)

    const resumen = await obtenerResumenGraficaEmpleados(cicloObj, invernadero.id, empleadosInvernaderoCiclo, semanas);
    const procesado = procesar(resumen, empleadosFormeatados, actividadesResp);
    const actividadesFiltrados = actividadesResp?.filter(el => el.nombre !== nombreActividadClipeoPlus && el.nombre !== nombreActividadEnredePlus) || [];
    setActividades(actividadesResp);
    setActividadesFiltrados(actividadesFiltrados);
    setDatasets(procesado);
    setCargando(false);
  }
  const procesar = (resumen, empleados, actividades) => {
    const actividadesProcesadas = actividades.map(actividad => {
      const empleadosDatasets = empleados.map(empleado => {
        const dataActividad = resumen.map((semana) => {
          const regEmpleado = semana[1]?.find(reg => reg.empleado === empleado.id);
          return regEmpleado?.[actividad?.id] ? parseFloat(regEmpleado[actividad.id]) : 0;
        })
        return {
          datasetId: empleado.id,
          name: empleado.nombre,
          data: dataActividad,
          sumaData: dataActividad.reduce((acc, curr) => acc + curr, 0)
        }
      })
      return { actividadId: actividad.id, dataset: empleadosDatasets }
    })
    const actividadesUnificadas = unificarActividadesPlus(actividades, actividadesProcesadas);
    return actividadesUnificadas;
  }
  const unificarActividadesPlus = (actividades, actividadesProcesadas) => {
    let actEnrede = actividades?.find(el => el.nombre === nombreActividadEnrede);
    let actEnredePlus = actividades?.find(el => el.nombre === nombreActividadEnredePlus);
    let datasetEnrede = actividadesProcesadas?.find(el => el.actividadId === actEnrede?.id);
    let datasetEnredePlus = actividadesProcesadas?.find(el => el.actividadId === actEnredePlus?.id);
    datasetEnrede.dataset.forEach(dataEmpleado => {
      let dataEmpleadoPlus = datasetEnredePlus?.dataset?.find(el => el.datasetId === dataEmpleado.datasetId);
      dataEmpleado.sumaData += dataEmpleadoPlus?.sumaData;
      let data = dataEmpleado.data.map((currentItem, index) => {
        return currentItem + dataEmpleadoPlus?.data?.[index];
      })
      dataEmpleado.data = data;
    })

    let actClipeo = actividades?.find(el => el.nombre === nombreActividadClipeo);
    let actClipeoPlus = actividades?.find(el => el.nombre === nombreActividadClipeoPlus);
    let datasetClipeo = actividadesProcesadas?.find(el => el.actividadId === actClipeo?.id);
    let datasetClipeoPlus = actividadesProcesadas?.find(el => el.actividadId === actClipeoPlus?.id);
    datasetClipeo.dataset.forEach(dataEmpleado => {
      let dataEmpleadoPlus = datasetClipeoPlus?.dataset?.find(el => el.datasetId === dataEmpleado.datasetId);
      dataEmpleado.sumaData += dataEmpleadoPlus?.sumaData;
      let data = dataEmpleado.data.map((currentItem, index) => {
        return currentItem + dataEmpleadoPlus?.data?.[index];
      })
      dataEmpleado.data = data;
    })
    return actividadesProcesadas;
  }

  const obtenerDataSeleccion = () => {
    const datosActividad = datasets?.find(el => el.actividadId === actividad?.id);
    if (datosActividad) {
      return datosActividad.dataset?.filter(datosEmpleado => {
        const empleadoId = datosEmpleado.datasetId;
        const filtrarEmpleado = empleadosFiltro?.some(emp => emp.id === empleadoId);
        return filtrarEmpleado && datosEmpleado.sumaData > 0;
      }) || [];
    }
    else return [];
  }

  return (
    <div className="p-grid chart-page">
      <ContenedorHeader titulo="Actividades por empleado" subtitulo={invernadero ? `Invernadero ${invernadero.nombre}` : ""} col="p-col p-col-auto"
        iconos={`chart-icon ${invernadero ? `bg-${invernadero.color}  bor-${invernadero.color}` : ""} `} atras={props.history.goBack} />

      <div className="p-col-12">
        <div className="p-grid filtros">
          <div className="form-group p-col p-col-4">
            <label htmlFor="filtroCliente">Invernadero</label>
            <Dropdown optionLabel="nombre" dataKey="id" value={invernadero} options={invernaderosVirtuales} onChange={e => setInvernadero(e.value)} placeholder="Seleccione invernadero" />
          </div>
          <div className="form-group p-col p-col-4">
            <label htmlFor="filtroCliente">Actividades a graficar</label>
            <Dropdown optionLabel="nombre" dataKey="id" value={actividad} options={actividadesFiltrados} onChange={e => setActividad(e.value)} placeholder="Seleccione actividad" />
          </div>
          <div className="form-group p-col p-col-4">
            <label htmlFor="filtroCliente">Empleados a graficar</label>
            <MultiSelect options={empleadosOpciones} value={empleadosFiltro} onChange={(e) => setEmpleadosFiltro(e.value)} optionLabel="nombreCompacto" dataKey="id"
              selectedItemsLabel={`${empleadosFiltro.length} empleados seleccionados`} />
          </div>
        </div>
      </div>

      <LineChart filtrar={true} data={{ datasets: obtenerDataSeleccion() }} options={options} />
    </div>)
}
export default EmpleadosActividad;//241