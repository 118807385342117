import { horaCorte, horaCorteDiario } from "../../constants";
import { dosDecimales } from "../../util/functions";

export const extraProcessingDiario = (updatedState,dias,area,hoy,hora) => {
    let results = dias.map((dia,index)=>{
        let el = parseFloat(updatedState[1][dia]);            
        if(el){
           return el; 
        }
        if(hoy<dias[index] || (hoy === dias[index] && hora < horaCorteDiario)){// no ha llegado dia
            el = parseFloat(updatedState[0][dia])//tomar semanal
            if(isNaN(el)){
                el = 0;
            }
        }else if(isNaN(el)){
            el = 0;
        }
        
        return el;
    });
    let total_kg = results.reduce((acc,curr)=>acc+curr,0);
    let total_m2 = total_kg  / area;        
    
    
    
    
    
    updatedState[1]["total_kg"] = total_m2;
    
}
export const extraProcessingSemanal = (updatedState,dias,area) => {
    let results = dias.map((dia,index)=>{
        let el = parseFloat(updatedState[0][dia]);            
        if(isNaN(el) ){                
            el = 0;
        }
        return el;
    });
    let total_kg = results.reduce((acc,curr)=>acc+curr,0);
    // 
    let total_m2 = total_kg / area;
    
    // 
    
    updatedState[0]["total_kg"] = total_m2;
    
    
    
    
}
export const extraProcessingReal = (updatedState,dias,area,hoy,hora, reales, callback) => {
    // 
    // 
    // 
    // 
    // 
    let results = reales.map((dia,index)=>{
        if(index>5  ){
            return 0;
        }
        let el = parseFloat(dia);  
        let diario = parseFloat(updatedState[1][dias[index]])
        if(hoy<dias[index]){//hay que tomar semanal?

            
            
            
            el = parseFloat(updatedState[0][dias[index]]);
            
            if(isNaN(el)){
                el = 0;
            }
            if(diario){// dar prioridad diario
                el = diario
            }
        }
        else if(hoy === dias[index] && hora < horaCorte){
            // 
            // 
            // 
            // 
            el = parseFloat(updatedState[1][dias[index]]);
            if(isNaN(el)){
                el = 0;
            }
        }          
        else if(isNaN(el) ){                
                el = 0;
        }
        return el;
    });
    // 
    let total_kg = results.reduce((acc,curr)=>acc+curr,0);
    let total_m2 = total_kg  / area;        
    let copia = reales.slice();
    
    copia[6] = dosDecimales(total_m2);
    callback(copia);   
}


const obtenerTotalSemanal = (datos,timestamps) => {
    let row = datos[0];
    let ks = Object.keys(row);
    let total = 0;
    timestamps.forEach(k => {
        let dato =  parseFloat(row[k]);
        if(dato && !isNaN(dato)){
            total+= dato;
        }
    })
    return total;
}