import React, {useState, useEffect} from 'react'
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import {  useGlobal} from 'reactn';
import { Column }  from 'primereact/column';
import { DataTable  } from 'primereact/datatable';
import { obtenerPresentacionesHabilitadas } from '../../service/Presentaciones';
import { obtenerDiasDeSemana, obtenerNombreDiaSemana } from '../../service/fechas';
import { nombresDiasSemana } from '../../constants';
import { usePrimeReactTable } from '../common/tableHandler';
import { Button } from 'primereact/button';
import { actualizarCargaMaquinas, guardarCargaMaquinas, obtenerRegistroMaquinasEmpaque } from '../../service/Empaques';

const CargaMaquinas = (props) => {
    
    const [archivos, setArchivos] = useState([]);
    const [presetProducto, setPresetProducto] = useGlobal("presetProducto");
    const [presetEmpaque] = useGlobal("presetEmpaque");
    const [weekGlobal, setWeekGlobal] = useGlobal("weekGlobal");
    const [ciclo, ] = useGlobal("ciclo");
    const [cargando, setCargando] = useGlobal("cargando");
    const [dias, setDias] = useState([]);
    const [weeks,week,handleWeekChange,updateCicloWeekSelector] = useWeekSelector();
    const [presentaciones, setPresentaciones] = useState([]);
    
    const [editing, setEditing] = useState(false);
    const [editId, setEditId] = useState(false);
    const extraProcessing = (params) => {
        
    }
    const [editorGenerator,currentValues] = usePrimeReactTable(dias,(extraProcessing));        
    useEffect(() => {
        if(ciclo){
            updateCicloWeekSelector(ciclo);
        }
    },[ciclo])
    useEffect(() => {
        if(week.nombre !="Cargando")
        setWeekGlobal(week);
    },[week]);
    const atras = (params) => {
        props.history.goBack();
    }
    useEffect(() => {
        if(ciclo && week && presetProducto){
            obtenerDatosIniciales();
        }        
    }, [week,presetEmpaque, presetProducto])
    const obtenerDatosIniciales = async(params) => {
        try {
            setCargando(true);
            let presentaciones = await obtenerPresentacionesHabilitadas();
            presentaciones  = presentaciones.filter(el=>el.producto_ref === presetProducto );
            setPresentaciones(presentaciones);
            
            let dias = await obtenerDiasCargaMaquinas(presentaciones);
            
            
            setDias(dias);
        } catch (error) {
            console.log("ERROR");
            console.log(error);    
        }finally{
            setCargando(false);
        }
    }
    
    const obtenerDiasCargaMaquinas = async (presentaciones) => {
        let datos = await obtenerRegistroMaquinasEmpaque(ciclo, week.time, presetEmpaque, presetProducto);
        if(datos.empty){
            let dias = obtenerDiasDeSemana(week.time);
            dias = dias.map((el,index)=>{
                let data = {
                fecha: el, 
                nombreDia: nombresDiasSemana[index]
            }
            presentaciones.forEach(presentacion => {
                data[presentacion.id] = "";
            })
                return data;
            })
            setEditing(false);
            return dias;
        }else{
            
            
            setEditing(true);
            setEditId(datos.id);
            return datos.registro;
            //setDias(datos.registro);
        }
        
    }
    
    const puedeEditar = (params) => {
        return true;
    }
    
    const obtenerColumnas = (params) => {
        let cols = [<Column field = "nombreDia" header = "Día" />];
        presentaciones.forEach(presentacion => {
            cols.push(<Column field = {presentacion.id} header = {presentacion.presentacion} 
                editor = {(props)=>{return puedeEditar()? editorGenerator(props,presentacion.id):<div>No editable</div>}}/>)
        })
        
        return cols
    }
    
    const guardar = async (params) => {
        try {
            if(editing){
                let result = await actualizarCargaMaquinas(ciclo, presetEmpaque, editId, dias);
            }else{
                let result = await guardarCargaMaquinas(ciclo, presetEmpaque, presetProducto,week.time, dias);
                
            }
            
            
            props.history.goBack();            
        } catch (error) {
            console.log("HUBO UN ERROR AL GUARDAR");
            console.log(error);
        }
    }
    const cancelar = (params) => {
        atras();
    }
    
    return <div className="p-grid p-justify-center">
        <div className="p-col-12 title-container">
            <span className="back-btn" onClick = {atras}></span>
            <span className="icon-general boxes-icon"></span>
            
            <span className="titles-group">

                <h1 className="header-title">
                    Registro de empaque de máquinas
                </h1>
                
            </span>
            <div>
            <span>Empaque {presetEmpaque}</span>
            <span>Producto {presetProducto}</span>
            </div>
        </div>
        <div className="p-col-12">
            <div className="p-grid filtros">              
            <WeekSelector weeks = {weeks} handleWeekChange = {handleWeekChange} week = {week}/>                
            </div>
        </div>
            <div className="p-col-12">
                    
                        <div className="card p-0">
                            <DataTable value = {dias}
                                >
                                {obtenerColumnas()}
                            </DataTable>
                        </div>
                        
            </div>
            <div className="p-col-12">
         <div className="p-grid p-justify-end btn-row">
            <Button label="Cancelar" className="p-button-secondary"  onClick = {cancelar}/>                    
                {puedeEditar("guardar")?<Button label="Guardar" onClick = {guardar}/>:null}              
          </div>
       </div>
        </div>
}

export default CargaMaquinas;
