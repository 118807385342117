import React, { useState, useEffect, useGlobal, setGlobal } from "reactn";
import { obtenerAcumuladoPresentacionPesosCalidad, obtenerReportePesos, obtenerReportePesosDesglosadoDia } from '../../service/ReporteCalidad';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { obtenerNombreFecha } from "../../service/fechas";
import { obtenerPresentaciones } from '../../service/Presentaciones';
import { DeepClone, dosDecimales, ordenarArreglaDeObjetosPorLlave } from '../../util/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp, faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { obtenerEmpacadoras } from "../../service/Empacadoras";
import { procesarAcumulado } from "service/Calidad";

const ReporteDefectos = (props) => {
    const [rows, setRows] = useState([]);
    const [presentaciones, setPresentaciones] = useState([]);
    const [invernadero] = useGlobal("invernadero");
    const [, setCargando] = useGlobal("cargando");
    const [ciclo,] = useGlobal("ciclo");
    const [presentacionesPresentes, setPresentacionesPresentes] = useState([]);
    const [hayDatosPresentes, setHayDatosPresentes] = useState(false);
    const [comentarios, setComentarios] = useState([]);
    const [, setMostrarModalPermiso] = useGlobal("mostrarModalPermiso");
    const [expandedRows, setExpandedRows] = useState(null)
    const [acumulados, setAcumulados] = useState([]);
    const [presentacionesPesosDiariosFiltro] = useGlobal("presentacionesPesosDiariosFiltro");

    useEffect(() => {
        obtenerPresentaciones().then((result) => { setPresentaciones(result); })
    }, [])
    useEffect(() => {//Checar si hay datos registrados este semana
        const hayDatosRegistrados = presentacionesPresentes?.length > 0;
        setHayDatosPresentes(hayDatosRegistrados);
    }, [presentacionesPresentes])
    useEffect(() => {
        actualizarDatos();
    }, [invernadero, props.semana, presentaciones, props.filtroDias])
    useEffect(() => {//Ordenar presentaciones filtrados
        if (presentacionesPesosDiariosFiltro) {
            const presentacionesCopia = DeepClone(presentacionesPesosDiariosFiltro);
            ordenarArreglaDeObjetosPorLlave(presentacionesCopia, "presentacion");
            setPresentacionesPresentes(presentacionesCopia);
        }
    }, [presentacionesPesosDiariosFiltro])
    const actualizarDatos = async () => {
        try {
            if (ciclo && invernadero && props.semana && presentaciones?.length > 0 && props.filtroDias) {
                setCargando(true)
                let result = 0;
                if (props.filtroDias === "semana") { result = await obtenerReportePesos(ciclo, invernadero.id, props.semana, presentaciones); }
                else {
                    const dia = props.dias.find(el => props.filtroDias === el.nombrePlano);
                    result = await obtenerReportePesosDesglosadoDia(ciclo, invernadero.id, props.semana, presentaciones, dia.unixTime)
                }
                procesarReporte(result);
                const [empacadoras, acumulado] =
                    await Promise.all([obtenerEmpacadoras(), obtenerAcumuladoPresentacionPesosCalidad(ciclo, invernadero.id, presentaciones, props.semana)])
                const procesado = procesarAcumulado(empacadoras, acumulado, presentaciones);
                setAcumulados(procesado);
            }
        } catch (err) {
            console.log("ERROR REPORTE DEFECTOS: ", err);
            if (err?.code === "permission-denied") { setMostrarModalPermiso(true); }
        }
        setCargando(false);
    }

    const procesarReporte = (result) => {
        const presentacionIds = Object.keys(result.desviacionesTotal);
        result.desviacionesTotal.nombre = "TOTAL";
        result.desviacionesAuto.nombre = "AUTOMÁTICA";
        result.desviacionesManual.nombre = "TOTAL MANUAL";

        let renglonesTablaPorEmpacadora = result.desviacionesEmpacadoras.concat([result.desviacionesManual, result.desviacionesAuto, result.desviacionesTotal]
        ).filter((row) => {
            // conservar únicamente los renglones con registros, por defecto todos los renglones; cuentan con el key 'nombre'
            if (!row.nombre) return false;
            if (['TOTAL', 'AUTOMÁTICA', 'TOTAL MANUAL'].includes(row.nombre)) return true;
            return Object.keys(row).length > 1;
        });
        setRows(renglonesTablaPorEmpacadora);

        const listaPresentaciones = presentacionIds.map(presentacionRef => presentaciones.find(el => el.id === presentacionRef));
        ordenarArreglaDeObjetosPorLlave(listaPresentaciones, "presentacion");
        setPresentacionesPresentes(listaPresentaciones);
        setGlobal({ presentacionesPesosDiariosOpciones: listaPresentaciones, presentacionesPesosDiariosFiltro: listaPresentaciones })//Para el filtro multiselect de presentaciones en ReporteCalidad.js
        setComentarios(result.comentarios);
    }
    const templateBody = (rowData, column) => {
        const data = rowData?.[column?.field];
        if (data?.muestras > 0) {
            let presentacion = presentaciones.find(el => el.id === column.field);
            let datoAlto = dosDecimales(data.altos / data.muestras);
            let datoNormal = dosDecimales(data.normales / data.muestras);
            let datoBajo = dosDecimales(data.bajos / data.muestras);
            let pesoNetoPresentacion = presentacion.peso_neto_presentacion
            let promedioPesosNormales = data.normales === 0 ? 0 : (data.sumaPesoNormales / data.normales)
            let promedioPesosAltos = data.altos === 0 ? 0 : (data.sumaPesoAltos / data.altos)
            let promedioPesosBajos = data.bajos === 0 ? 0 : (data.sumaPesoBajos / data.bajos)
            let porcentajePesosNormales = dosDecimales(100 * (promedioPesosNormales - pesoNetoPresentacion) / pesoNetoPresentacion)
            let porcentajePesosAltos = dosDecimales(100 * (promedioPesosAltos - pesoNetoPresentacion) / pesoNetoPresentacion)
            let porcentajePesosBajos = dosDecimales(100 * (promedioPesosBajos - pesoNetoPresentacion) / pesoNetoPresentacion)
            let porcentajePesosNormalesFormateado = porcentajePesosNormales === -100 ? '-' : porcentajePesosNormales
            let porcentajePesosAltosFormateado = porcentajePesosAltos === -100 ? '-' : porcentajePesosAltos
            let porcentajePesosBajosFormateado = porcentajePesosBajos === -100 ? '-' : porcentajePesosBajos

            let datoTotalMuestras = dosDecimales(data.normales + data.altos + data.bajos);
            let promedioTodos = (data.sumaPesoNormales + data.sumaPesoAltos + data.sumaPesoBajos) / data.muestras
            let desviacionTodos = promedioTodos - pesoNetoPresentacion
            let promedioPorcentaje = dosDecimales((desviacionTodos / pesoNetoPresentacion) * 100)
            if (isNaN(promedioPorcentaje)) { promedioPorcentaje = 0; }

            return (
                <React.Fragment>
                    <div className="muestras-totales">
                        {datoTotalMuestras}<span className="label-unidad">Muestras/</span>
                        {promedioPorcentaje + "%"}<span className="label-unidad">Promedio</span>
                    </div>
                    <div className="pesos-porcentajes">
                        <div className="calidad-etiquetas"></div>
                        <div className="datos-pesos peso-correcto">
                            <FontAwesomeIcon icon={faCheckCircle} />
                            <div>
                                <div className="muestra-porcentaje">
                                    {Math.round(100 * datoNormal)}<span className="label-unidad">%</span>
                                </div>
                                <div className="muestra-desviación">
                                    {porcentajePesosNormalesFormateado}<span className="label-unidad">%</span>
                                </div>
                            </div>
                        </div>

                        <div className="datos-pesos sobre-maximo">
                            <FontAwesomeIcon icon={faArrowCircleUp} />
                            <div>
                                <div className="muestra-porcentaje">
                                    {Math.round(100 * datoAlto)}<span className="label-unidad">%</span>
                                </div>
                                <div className="muestra-desviación">
                                    {porcentajePesosAltosFormateado}<span className="label-unidad">%</span>
                                </div>
                            </div>
                        </div>

                        <div className="datos-pesos bajo-minimo">
                            <FontAwesomeIcon icon={faArrowCircleDown} />
                            <div>
                                <div className="muestra-porcentaje">
                                    {Math.round(100 * datoBajo)}<span className="label-unidad">%</span>
                                </div>
                                <div className="muestra-desviación">
                                    {porcentajePesosBajosFormateado}<span className="label-unidad">%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>)
        }
        else return "";
    }

    const rowExpansionTemplateLeft = (data) => rowExpansionTemplate(data, true);
    const rowExpansionTemplate = (data, tablaIzquierda = false) => {
        const dato = acumulados.find(el => el.empacadora === data.nombre);
        return (
            <DataTable value={[dato]}>
                {obtenerColumnasExpander(tablaIzquierda)}
            </DataTable>)
    }

    const obtenerColumnas = (tablaIzquierda = false) => {
        const columnas = (!tablaIzquierda)
            ? [] //NO PONER DATOS EN TABLA IZQUIERDA
            : [<Column key="col-exp" field="acc" expander className="col__label rpd-expander-col-width" headerClassName="col__label rpd-expander-col-width" />,
            <Column key="col-nombre" field="nombre" header="Empacadora" className="col__label rpd-nombre-col-width" headerClassName="col__label rpd-nombre-col-width" />]

        const key = (presId) => `columna-${tablaIzquierda ? "L" : "R"}-${presId}`;
        const columnasPresentaciones = presentacionesPresentes?.map(({ id, presentacion }) =>
            <Column key={key(id)} className="rpd-celda-datos" headerClassName="rpd-celda-header" field={id} header={presentacion} body={templateBody} />) || [];

        return [...columnas, ...columnasPresentaciones];
    }
    const obtenerColumnasExpander = (tablaIzquierda = false) => {
        const tablaLleno = tablaIzquierda && hayDatosPresentes; //Fix de problemas derivado de tener 2 tablas (y una sin expander/nombre columna) al quitar datos
        const columnas = (!tablaLleno)
            ? [] //NO PONER DATOS EN TABLA IZQUIERDA
            : [<Column key="col-acc" field="acc" header="Acumulado ciclo" className="rpd-celda-acc-datos" headerClassName="rpd-celda-acc-header" />];

        const key = (presId) => `expander-${tablaIzquierda ? "L" : "R"}-${presId}`;
        const columnasPresentaciones = presentacionesPresentes?.map(({ id, presentacion }) =>
            <Column key={key(id)} className="rpd-celda-datos" headerClassName="rpd-celda-header" field={id} header={presentacion} body={templateBody} />) || [];

        return [...columnas, ...columnasPresentaciones];
    }

    return (
        <React.Fragment>
            <div className="reporte-pesos-diarios-contenedor" >
                <div className={`card ${hayDatosPresentes ? "styles-tabla-expander" : "tabla-expander-empty"}`} >
                    <DataTable className="table-total-3" value={rows} expandedRows={expandedRows} onRowToggle={e => { setExpandedRows(e.data); }} rowExpansionTemplate={rowExpansionTemplateLeft}  >
                        {obtenerColumnas(true)}
                    </DataTable>
                </div>

                {hayDatosPresentes &&
                    <div className="card styles-tabla-reporte-pesos">
                        <DataTable className="table-total-3" value={rows} expandedRows={expandedRows} onRowToggle={e => { setExpandedRows(e.data); }} rowExpansionTemplate={rowExpansionTemplate} >
                            {obtenerColumnas(false)}
                        </DataTable>
                    </div>}
            </div>

            <p className="section-title">Comentarios/hallazgos</p>
            <div className="card">
                <div className="p-col-12 max-6">
                    {comentarios.map((comentario, i) => { return <div key={`com-${i}`}> {obtenerNombreFecha(comentario.momento)} - {comentario.comentario} </div> })}
                </div>
            </div>
        </React.Fragment>);
}
export default ReporteDefectos;//487