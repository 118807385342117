import React, { useEffect, useGlobal } from 'reactn';
import HomeRecursosHumanos from './homes/HomeRecursosHumanos';
import HomeCultivo from './homes/HomeCultivo';
import HomeCosecha from './homes/HomeCosecha';
import HomeCalidad from './homes/HomeCalidad';
import HomePresentaciones from './homes/HomePresentaciones';
import HomeRequisiciones from './homes/HomeRequisiciones';
import HomeClientes from './homes/HomeClientes';
import HomeFinanzas from './homes/HomeFinanzas';
import HomeBiocontrol from './homes/HomeBiocontrol';

const Home = (props) => {
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [usuario] = useGlobal("usuario");

    useEffect(() => {
        setNombreModulo("Homepage Gráficas");
        setHabilitadoSelectorCiclo(true);
    }, [])

    const ir = (path) => props.history.push(path);
    return (
        <div className="p-grid">
            <div className="p-col  p-col-auto title-container">
                <span className={`icon-general chart-icon `}></span>
                <span className="titles-group">
                    <h1 className="header-title">Dashboard de gráficas</h1>
                </span>
            </div>
            <div className="p-col-12 ">
                <div className="p-grid text-center m-0 ml-02">
                    {["Administrador", "Personal Administrativo", "Grower", "Auxiliar Grower", "Administrador RH", "Auxiliar RH"].includes(usuario.rol)
                        && <HomeRecursosHumanos ir={ir} />}

                    {["Administrador", "Personal Administrativo", "Grower", "Auxiliar Grower", "Administrador Calidad",
                        "Auxiliar Calidad", "Administrador Biocontrol", "Auxiliar Biocontrol"].includes(usuario.rol)
                        && <HomeCultivo ir={ir} />}

                    {["Administrador", "Personal Administrativo", "Grower", "Auxiliar Grower", "Administrador Calidad",
                        "Auxiliar Calidad", "Administrador Biocontrol", "Auxiliar Biocontrol"].includes(usuario.rol)
                        && <HomeBiocontrol ir={ir} />}

                    {["Administrador", "Personal Administrativo", "Grower", "Auxiliar Grower", "Administrador RH", "Auxiliar RH",
                        "Administrador Calidad", "Auxiliar Calidad", "Administrador Empaque", "Auxiliar Empaque"].includes(usuario.rol)
                        && <HomeCosecha ir={ir} />}

                    {["Administrador", "Administrador Calidad", "Auxiliar Calidad", "AdministradorEmpaque", "Auxiliar Empaque",
                        "Administrador Embarques", "Auxiliar Embarques", "Administrador Biocontrol", "Grower", "Auxiliar Grower"].includes(usuario.rol)
                        && <HomeCalidad ir={ir} />}

                    {["Administrador", "Administrador Empaque", "Auxiliar Empaque", "Administrador Calidad", "Auxiliar Calidad",
                        "Administrador Embarques", "Auxiliar Embarques", "Personal Administrativo"].includes(usuario.rol)
                        && <HomePresentaciones ir={ir} />}

                    {["Administrador", "Finanzas", "Personal Administrativo"].includes(usuario.rol)
                        && <HomeFinanzas ir={ir} />}

                    {["Administrador", "Administrador Compras", "Administrador Sistemas", "Administrador Mantenimiento"].includes(usuario.rol)
                        && <HomeRequisiciones ir={ir} tipoAdmin={usuario.rol} />}

                    {["Administrador", "Administrador Embarques", "Auxiliar Embarques"].includes(usuario.rol)
                        && <HomeClientes ir={ir} />}
                </div>
            </div>
        </div>);
}
export default Home//81