import React, { useState, useGlobal, useEffect } from 'reactn';
import { withRouter } from 'react-router-dom';
import { dosDeciComas, dosDecimales } from '../../util/functions';
import HTGCard from '../common/cards/HTGCard';
import DatoCard from '../common/cards/DatoCard';
import ClipLoader from 'react-spinners/ClipLoader';

const InvernaderoCard = (props) => {
  const [, setInvernadero] = useGlobal("invernadero");
  const [usuario] = useGlobal("usuario");
  const [, setAviso] = useState(true);

  useEffect(() => {
    if (props.invernadero?.budget?.estimacion_semanal?.length > 0) { setAviso(false); }
  }, [])
  const puedeConsultar = () => {
    return ["Administrador", "Administrador Calidad", "Auxiliar Calidad", "Auxiliar Grower", "Administrador Inocuidad", "Grower",
      "Administrador Empaque", "Grower", "Auxiliar Empaque"].includes(usuario?.rol);
  }
  const consultar = () => {
    if (puedeConsultar()) {
      setInvernadero(props.invernadero);
      props.history.push("/calidad/semanal");
    }
  }
  const header = <span className="card-header">
    <div className={`card-id icon-general icon-id bor-${props.invernadero?.color} bg-${props.invernadero?.color}`}>{props.invernadero?.nombre}</div>
    <div>
      <p className="card-up">{props.invernadero?.habilitado ? "" : "Deshabilitado"}</p>
      <p className="card-down">{props.info?.invernaderoFisico ? `${props.info?.invernaderoFisico?.nombre} - ${props.info?.productoYVariedadString}` : ""}</p>
    </div>
  </span>;
  const className = `htg-card bor-${props.invernadero?.color} ${props.invernadero?.habilitado ? "Habilitado" : "Deshabilitado"}`;

  return (
    <HTGCard key={props.key} headerClassName={className} header={header} onClick={consultar}>
      {props.home && props.cargandoHome
        ? <div className="card-spinner-centered">
          <ClipLoader sizeUnit="px" size={100} color="#003E66" loading={true} />
        </div>
        : <>
          <DatoCard label="Peso caja" valor={`${isNaN(props.info?.pesoCaja) ? "-" : dosDecimales(props.info?.pesoCaja)} kg`} />
          <DatoCard label="Peso fruto" valor={`${isNaN(props.info?.pesoFruto) ? "-" : dosDeciComas(Math.round(100 * 1000 * props.info?.pesoFruto) / 100)} gr`} />
          <DatoCard label="Defectos" valor={`${isNaN(props.info?.defectos) ? "-" : dosDecimales(props.info?.defectos)} %`} />
        </>}
    </HTGCard>
  )
}
export default withRouter(InvernaderoCard);