import React, { useState, useEffect, useGlobal } from "reactn";
import { getInicioSemanaDia, obtenerDiasDeSemana, obtenerNombreDiaSemana } from "../../../service/fechas";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { DeepClone, dosDeciComas, dosDecimales, getMomentFormatted, getNombrePorID, getObjetoPorID, ordenarArreglaDeObjetosPorLlave, separadoComas } from "../../../util/functions"
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row"
import ModalRedistribucionMedleys from "./ModalRedistribucionMedleys.mole";
import { UnidadCajas } from "components/common/templates_unidades/UnidadCajas.atomo";
import { UnidadKilos } from "components/common/templates_unidades/UnidadKg.atomo";
import { convertirCajasPresentacionAKilos, procesarFinal, procesarProyectado } from "service/Cumplimiento";


const TablaCumplimientoTotal = (props) => {
    const [presentaciones] = useGlobal("presentaciones")
    const [ultimaCarga] = useGlobal("ultimaFechaCargaCumplimiento");
    const [, setShowPantallaDistribucion] = useGlobal("showPantallaDistribucion");
    const [week] = useGlobal("week");
    const [activeProducto, setActiveProducto] = useGlobal("activeProductoCumplimiento");
    const [clientes, setClientes] = useGlobal("clientesDelCiclo");

    const [rowsOriginales, setRowsOriginales] = useState([]);
    const [rows, setRows] = useState([]);
    const [proyectado, setProyectado] = useState([]);
    const [final, setFinal] = useState([]);
    const [totalesDias, setTotalesDias] = useState({});
    const [sobranteActualTotal, setSobranteActualTotal] = useState(0);
    const [sobrantePasadaTotal, setSobrantePasadaTotal] = useState(0);
    const [complementoTotal, setComplementoTotal] = useState(0);
    
    const [totalSinRestar, setTotalSinRestar] = useState({});

    const [showModalMedleys, setShowModalMedleys] = useState(false);

    useEffect(() => {
        if (props.ordenesSemana && props.cumplimientoProductoDatos?.totalesCajas && props.invernaderos && activeProducto?.id) { procesarDatos(); }
    }, [props.ordenesSemana, props.cumplimientoProductoDatos])

    const procesarDatos = () => {
        const { totalesCajas = 0, totalesKilos = 0, sobrantePasadaTotal = 0,
                totalesCajasSinRestar = 0, totalesKilosSinRestar = 0, ...cumplimientoDatos } = props.cumplimientoProductoDatos;

        const rows = filtrarFilasTabla(cumplimientoDatos);
        const proyectado = procesarProyectado(props.proyectado, props.invernaderos);
        //console.log("pppp---- ", { proyectado, totalesKilos, sobrantePasadaTotal })
        const final = procesarFinal(totalesKilos, proyectado, sobrantePasadaTotal);

        //Datos de filas de la tabla
        setRowsOriginales(rows);
        setRows(rows);
        setTotalesDias({ totalesCajas, totalesKilos });
        setTotalSinRestar({ totalesCajas: totalesCajasSinRestar, totalesKilos: totalesKilosSinRestar });
        setProyectado(proyectado);
        setFinal(final);

        //Datos de headers
        const complementoTotal = getComplementoTotal(props.sumaTotalPrestado);
        setSobrantePasadaTotal(sobrantePasadaTotal);
        setSobranteActualTotal(final[5]);
        setComplementoTotal(complementoTotal);
    }
    const filtrarFilasTabla = (cumplimientoDatos) => {
        const filas = Object.keys(cumplimientoDatos).map(presentacionID => cumplimientoDatos[presentacionID])//generar filas de la tabla
        ordenarArreglaDeObjetosPorLlave(filas, "nombrePresentacion");
        return filas;
    }
    const getComplementoTotal = (sumaTotalPrestado) => {
        let numTotal = 0;
        for (const invernaderoID in sumaTotalPrestado) {
            const complemento = sumaTotalPrestado[invernaderoID];
            numTotal += parseFloat(complemento);
        }
        return numTotal
    }

    const diasBodyTemplate = (rowData, column) => {
        const { sumaCajas = 0, sumaKilos = 0, sumaDistribuidos = 0 } = rowData?.[column?.field || ""] || {};
        if (!sumaCajas) { return null; }
        const texto = props.filtrarKilos ? "kilos distribuidos" : "cajas distribuidas";
        const valorDistribuidos = props.filtrarKilos ? convertirCajasPresentacionAKilos(sumaDistribuidos || 0, rowData.presentacion) : sumaDistribuidos;
        return <>
            {sumaDistribuidos > 0 && <Button className="btn-line btn-help ml-1" type="button" label="!" tooltip={`${dosDeciComas(valorDistribuidos)} ${texto}`} />}
            {props.filtrarKilos ? <UnidadKilos valor={dosDeciComas(sumaKilos)} /> : <UnidadCajas valor={dosDeciComas(sumaCajas)} />}
        </>;
    }
    const totalBodyTemplate = (rowData) => {
        const texto = props.filtrarKilos ? "kilos distribuidos" : "cajas distribuidas";
        const valorDistribuidos = props.filtrarKilos ? convertirCajasPresentacionAKilos(rowData.totales.sumaDistribuidos || 0, rowData.presentacion) : rowData.totales.sumaDistribuidos;
        const valorTotal = props.filtrarKilos ? rowData.totales?.sumaKilos : rowData.totales?.sumaCajas;
        return <>
            {valorDistribuidos > 0 ? <Button className="btn-line btn-help ml-1" type="button" label="!" tooltip={`${dosDeciComas(valorDistribuidos)} ${texto}`} /> : null}
            {props.filtrarKilos && !isNaN(parseFloat(valorTotal)) && <UnidadKilos valor={dosDeciComas(valorTotal)} />}
            {!props.filtrarKilos && !isNaN(parseFloat(valorTotal)) && <UnidadCajas valor={dosDeciComas(valorTotal)} />}
        </>
    }
    const redistribuirBodyTemplate = (rowData) => {
        const inicioLunesOrden = getInicioSemanaDia(parseFloat(rowData.fecha_envio)).unix() * 1000;
        let classNameDistribuir = "boton-redistribuir", textoTooltip = "Redistribuir cajas de este presentación";
        if (rowData?.fecha_distribuido && rowData?.fecha_distribuido > inicioLunesOrden) {
            classNameDistribuir = "boton-redistribuido";
            textoTooltip = "Volver a distribuir ordenes";
            if (rowData?.fecha_actualizado > rowData?.fecha_distribuido) {
                textoTooltip = "Las ordenes de origen fueron modificadas";
                classNameDistribuir = "boton-redistribuido-editado";
            }
        } else if (rowData?.fecha_actualizado && rowData?.fecha_actualizado > inicioLunesOrden) {
            textoTooltip = "Las ordenes de origen fueron modificadas sin distribución";
            classNameDistribuir = "boton-redistribuido-no-editado";
        }

        return <Button className={classNameDistribuir} tooltip={textoTooltip} type="button" label="Redistribuir" onClick={() => { console.log("ROWDATA: ", rowData); setShowPantallaDistribucion(rowData) }} />
    }

    const obtenerFooter = () => {
        const totalesMostrar = props.filtrarKilos ? totalesDias.totalesKilos : totalesDias.totalesCajas;
        const totalesSinRestarMostrar = props.filtrarKilos ? totalSinRestar.totalesKilos : totalSinRestar.totalesCajas;
        let footerGroup = <ColumnGroup>
            {/* <Row>
                <Column footer="Total" colSpan={2} />
                {totalesMostrar?.map((total, index) => {
                    const bodyAMostrar = props.filtrarKilos ? <UnidadKilos valor={dosDeciComas(total)} /> : <UnidadCajas valor={dosDeciComas(total)} />;
                    return <Column key={getKey("t", index)} footer={bodyAMostrar} colspan={1} />;
                })}
                <Column footer="" colSpan={1} />
            </Row> */}
            <Row>
                <Column footer="Total" colSpan={2} />
                {totalesSinRestarMostrar?.map((total, index) => {
                    const bodyAMostrar = props.filtrarKilos ? <UnidadKilos valor={dosDeciComas(total)} /> : <UnidadCajas valor={dosDeciComas(total)} />;
                    return <Column key={getKey("tsr", index)} footer={bodyAMostrar} colspan={1} />;
                })}
                <Column footer="" colSpan={1} />
            </Row>
            <Row>
                <Column footer="Disponible proyectado" colSpan={2} />
                {proyectado?.map((proyeccion, index) => <Column key={getKey("p", index)} footer={<UnidadKilos valor={dosDeciComas(proyeccion || 0)} />} colSpan={1} />)}
                <Column footer="" colSpan={2} />
            </Row>
            <Row>
                <Column footer="Disponible final" colSpan={2} />
                {final?.map((fin, index) => <Column key={getKey("f", index)} footer={<UnidadKilos valor={dosDeciComas(fin || 0)} />} colSpan={1} />)}
                <Column footer="" colSpan={2} />
            </Row>
        </ColumnGroup>;
        return footerGroup;
    }
    const getKey = (llave, index) => `${llave}-${index}-${props.productoId || ""}-${week.time}`;

    return (
        <div className="p-col-12 mt-0 pt-0">
            <div className="card ">
                <div className="cumplimiento">
                    <p className="table-title">Total de los invernaderos</p>
                    <span className={`icon-general icon-mini inv-icon bor-gray-light bg-gray-lighter`} />

                    <span className="sobrante-semana">
                        <div className="sobrante-data">
                            {dosDeciComas(sobrantePasadaTotal)} kg
                            <p className="sobrante-label">Sobrante de la semana pasada</p>
                        </div>
                    </span>
                    <span className="sobrante-semana">
                        <div className="sobrante-data">
                            {dosDeciComas(sobranteActualTotal)} kg
                            <p className="sobrante-label">Sobrante actual</p>
                        </div>
                    </span>

                    {Object.keys(props.sumaTotalPrestado).length > 0 && complementoTotal > 0 &&
                        <span className="ml-3 sobrante-semana">
                            <div className="sobrante-data">
                                {dosDeciComas(sobranteActualTotal + complementoTotal)} kg
                                <p className="sobrante-label">Sobrante con complemento</p>
                            </div>
                        </span>}
                    <span className="sobrante-semana">
                        <p className="">Aviso de fecha de última carga en órdenes de venta de esta semana: {getMomentFormatted(ultimaCarga, "DD/MM hh:mm")}</p>
                    </span>

                    <div className="tabla-cumplimiento bor-main-blue">
                        <DataTable value={rows} footerColumnGroup={obtenerFooter()} >
                            <Column field="presentacion.cliente_ref" header="Cliente de presentación" body={rowData => getNombrePorID(clientes, rowData.presentacion.cliente_ref)}/>
                            <Column field="presentacion.presentacion" header="Presentación" />
                            {obtenerDiasDeSemana(week.time)?.map(dia => {
                                return <Column key={`col${dia}`} body={diasBodyTemplate} field={`${dia}`} header={obtenerNombreDiaSemana(dia)} />;
                            })}
                            <Column body={totalBodyTemplate} header="Total" />
                            <Column className="columna-redistribuir" body={redistribuirBodyTemplate} field="redistribuir" header="" />
                        </DataTable>
                    </div>
                </div>
            </div>

            <ModalRedistribucionMedleys visible={showModalMedleys} onHide={() => { setShowModalMedleys(false) }} rowData={showModalMedleys} filtroKilos={props.filtrarKilos} />
        </div>)
}
export default TablaCumplimientoTotal;