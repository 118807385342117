import React, { useState, useEffect, useGlobal } from "reactn";
import { obtenerDiasDeSemana, obtenerNombreDiaSemana } from '../../../service/fechas';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { dosDecimales, dosDeciComas, getObjetoPorID, getProductoYVariedadString, separadoComas, getNombrePorID, ordenarArreglaDeObjetosPorLlave, DeepClone, sumarNumerosArrayPorCampo } from '../../../util/functions'
import { ColumnGroup } from 'primereact/columngroup';
import { Row, row } from 'primereact/row'
import { Rowspan } from 'primereact/row'
import * as firebase from "firebase/app";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import icono from '../../../images/icons/external-icon.svg';
import { UnidadKilos } from "../../common/templates_unidades/UnidadKg.atomo";
import { UnidadCajas, UnidadCj } from "../../common/templates_unidades/UnidadCajas.atomo";

import { keyTabla, ordenarPorNombrePresYFecha, procesarDatosOrdenesVenta, procesarFinal, procesarProyectado } from "../../../service/Cumplimiento";
import TemplateIconoInvernaderoTabla from "../../common/templates_unidades/TemplateIconoInveranderoTabla"
import { getEmpaquePorInvernaderoVirtualID, getSitioEmpaqueInvernaderoVirtualObj } from "service/Invernaderos";

const TablaCumplimientoInv = (props) => {
    /*  const [ciclo] = useGlobal("ciclo");
       const [reloadMed] = useGlobal("reloadMed");
       const [clientesDeLaTabla, setClientesDeLaTabla] = useState([]);*/
    const [clientes] = useGlobal("clientesDelCiclo");
    const [invernaderosFisicos] = useGlobal("invernaderosFisicos");
    const [invernaderosUnicoMedleys] = useGlobal("invernaderosUnicoMedleys");
    const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
    const [allVariedades] = useGlobal("allVariedades");

    const [week] = useGlobal("week");
    const [rows, setRows] = useState([])
    const [sobrantePasada, setSobrantePasada] = useState(0);
    const [sobranteActual, setSobranteActual] = useState();

    const [totalesDias, setTotalesDias] = useState([]);
    const [proyectado, setProyectado] = useState([]);
    const [final, setFinal] = useState([]);
    const [incumplidos, setIncumplidos] = useState([])
    const [finalConComplemento, setFinalConComplemento] = useState(0);

    const [esInvMedleys, setEsInvMedleys] = useState(false);
    const [kilosComplementadosTotal, setKilosComplementadosTotal] = useState(0);
    const [kilosRestadosTotal, setKilosRestadosTotal] = useState(0);

    const [sumaTotalPrestado, setSumaTotalPrestado] = useState(0);
    const [sumaTotalDestino, setSumaTotalDestino] = useState(0);
    const [sumaTotalPrestadoTool, setSumaTotalPrestadoTool] = useState([]);

    const [totalSinRestar, setTotalSinRestar] = useState({});

    useEffect(() => {
        if (props.cumplimientoProductoDatos) { obtenerDatosIniciales(); }
    }, [props.cumplimientoProductoDatos, props.sobrantePasada, props.distribucionMedleysDatos, props.producto])

    const obtenerDatosIniciales = async () => {
        const { totalesCajas, totalesKilos, totalesCajasSinRestar = 0, totalesKilosSinRestar = 0, ...cumplimientoDatos } = props.cumplimientoProductoDatos;
        const rows = generarFilasTabla();
        //console.log(`inclumplidosVentas - ${props.invernadero.nombre}: ` ,props.incumplidos)

        const filtrarEmpaque = props.empaquesFiltros?.some(el => el.id === getSitioEmpaqueInvernaderoVirtualObj(invernaderosFisicos, props.invernadero))
        const proyectadoProcesado = filtrarEmpaque ? procesarProyectado(props.proyectado, [props.invernadero]) : [0, 0, 0, 0, 0, 0];


        const final = procesarFinal(totalesKilos, proyectadoProcesado, props.sobrantePasada)
        const proyProc = filtrarEmpaque
            ? props?.proyectado?.find(proy => proy.invernadero?.id === props.invernadero?.id)
            : { proyeccion: [{ esReal: false, packout: 0, packoutRow: 0, valor: 0, real: 0, tipo: null }, { esReal: false, packout: 0, packoutRow: 0, valor: 0, real: 0, tipo: null }, { esReal: false, packout: 0, packoutRow: 0, valor: 0, real: 0, tipo: null }, { esReal: false, packout: 0, packoutRow: 0, valor: 0, real: 0, tipo: null }, { esReal: false, packout: 0, packoutRow: 0, valor: 0, real: 0, tipo: null }, { esReal: false, packout: 0, packoutRow: 0, valor: 0, real: 0, tipo: null }] }


        setProyectado(proyProc);
        setFinal(final);
        setIncumplidos(props.incumplidos);
        setRows(rows);

        const [sumaTotal, restaTotal] = generarResumenComplemento();
        setKilosComplementadosTotal(sumaTotal - restaTotal);
        setKilosRestadosTotal(restaTotal);

        let [dataTooltip, totalPrestado, sumaTotalDestino] = generarTooltipResumenPrestado()

        setSumaTotalPrestadoTool(dataTooltip)
        setSumaTotalPrestado(totalPrestado)
        setSumaTotalDestino(sumaTotalDestino)
/*         const sumaTotal = props.sumaTotalPrestado;
        sumaTotal[props.invernadero?.id] = totalPrestado;
        props.setSumaTotalPrestado({ ...sumaTotal }); */


        setEsInvMedleys(!!props.invernadero?.mezcla);
        setSobrantePasada(props.sobrantePasada);
        setSobranteActual(final[5]);
        setFinalConComplemento(final[5] + (sumaTotal - restaTotal));
    }


    const generarResumenComplemento = () => {
        if (sobrantePasada === "-") { return [0, 0] }
        const distribucionesFiltrados = props.distribucionMedleysDatos?.filter(el => el.semana == week.time);
        let sumaTotal = 0, restaTotal = 0, prestaTotalFinal = 0;

        distribucionesFiltrados.forEach(dis => {
            const esDestino = dis.invernadero_virtual_ref === props.invernadero?.id;
            if (esDestino) { sumaTotal += sumarNumerosArrayPorCampo(dis.origenes, "kilos_prestado"); }

            const origenesFiltrados = dis.origenes.filter(presta => presta.origen_ref === props.invernadero?.id)
            restaTotal += sumarNumerosArrayPorCampo(origenesFiltrados, "kilos_prestado");
        })
        return [sumaTotal, restaTotal, ]
    }
    

    const getDistribucionDatosCliente = (clienteID) => props.distribucionMedleysDatos?.find(el => el.invernadero_virtual_ref === props.invernadero?.id && el.semana === week.time && el.cliente_ref === clienteID) || {};

    const generarFilasTabla = () => {
        const { totalesCajas, totalesKilos,totalesCajasSinRestar =[], totalesKilosSinRestar =[],  ...cumplimientoDatos } = props.cumplimientoProductoDatos;
        setTotalSinRestar({ totalesCajas: totalesCajasSinRestar, totalesKilos: totalesKilosSinRestar });
        setTotalesDias({ totalesCajas, totalesKilos });

        let filas = Object.keys(cumplimientoDatos).map(presentacionID => { 
            //const distribucionFound = getDistribucionDatosCliente(cumplimientoDatos[presentacionID]?.presentacion?.cliente_ref || "");
            const [sumaTotal, restaTotal] = generarResumenComplemento(false, cumplimientoDatos[presentacionID]?.presentacion?.cliente_ref || "");
            const kilos_prestado = sumaTotal - restaTotal

            if(kilos_prestado != 0 && props.invernadero.nombre === "T1") console.log(`INV: ${props.invernadero.nombre} - kilos: ${kilos_prestado}`)
            return {...cumplimientoDatos[presentacionID], kilos_prestado};
        })//generar filas de la tabla
        ordenarPorNombrePresYFecha(filas);
        
        //console.log("DATOS UM INV: ", { totalesCajas, totalesKilos })
        return filas;
    }

    const generarTooltipResumenPrestado = () => {
        const distribucionesFiltrados = getDistribucionDatosTODOS();
        let dataTooltip = [], totalPrestado = 0, sumaTotalDestino = 0;
        distribucionesFiltrados.forEach(dis => {
            let suma = 0;

            const esDestino = dis.invernadero_virtual_ref === props.invernadero?.id;
            if (esDestino) {
                suma = sumDistribucion(dis);
                sumaTotalDestino += suma
                //dataTooltip.push({ suma, strs: `${parseInt(el.kilos_prestado)}kg sumado de ${getNombrePorID(invernaderosVirtuales, dis.invernadero_virtual_ref)}`, dataTooltipObj })
            }
            else {
                suma = restaDistribucion(dis).suma
                dataTooltip.push(...restaDistribucion(dis).dataTooltipObj)
            }
            totalPrestado += suma
        })
        return [dataTooltip, totalPrestado, sumaTotalDestino]
    }
    const getDistribucionDatosTODOS = () => {
        return props.distribucionMedleysDatos?.filter(el => {
            const esDestino = el.invernadero_virtual_ref === props.invernadero?.id;
            const esOrigen = el.origenes?.some(orig => orig.origen_ref === props.invernadero?.id);
            return (esDestino || esOrigen) && el.semana === week.time;
        });
    }
    const sumDistribucion = (dis = { origenes: [] }) => {
        let sum = 0;
        if(!dis?.origenes){ return sum; }
        dis.origenes.forEach(el => {
            sum += parseInt(el.kilos_prestado);
        })
        return sum
    }
    const restaDistribucion = (dis = { origenes: [] }) => {
        let resta = 0;
        if(!dis?.origenes){ return resta; }
        let dataTooltip = [], dataTooltipObj = []
        dis.origenes.forEach((el, ind) => {
            if (el.origen_ref === props.invernadero?.id) {
                //console.log("INDD ", ind)
                const invOBJ = getObjetoPorID(invernaderosVirtuales, el.origen_ref)
                const invOBJDestino = getObjetoPorID(invernaderosVirtuales, dis.invernadero_virtual_ref)
                const varOBJ = getObjetoPorID(allVariedades, el.variedad_ref)
                dataTooltip.push(`${parseInt(el.kilos_prestado)}kg restados de la variedad ${varOBJ.nombre}`)
                dataTooltipObj.push({ destino: invOBJDestino.nombre, restado: parseInt(el.kilos_prestado), variedad: varOBJ.nombre })
                resta -= parseInt(el.kilos_prestado);
            }//restar kilos de variedades prestado de este invernadero a otro tipo mezcla
        })
        //if(dataTooltip.length > 1){window.alert(dataTooltip.length)}
        return { suma: resta, strs: dataTooltip, dataTooltipObj }
    }

    const bodyTemplateDia = (rowData, column) => {
        let data = rowData[column.field];
        if (!data?.sumaCajas) { return null; }

        data = props.filtrarKilos
            ? <UnidadKilos valor={dosDeciComas(data.sumaKilos)} />
            : <UnidadCj valor={dosDeciComas(data.sumaCajas)} />

        let index = parseInt(column.columnKey);
        let iconoIndicador = <FontAwesomeIcon icon={faCaretUp} />;
        let incumplidosDia = incumplidos?.[index] || [];
        if (incumplidosDia.includes(rowData.presentacion.id)) {
            iconoIndicador = <FontAwesomeIcon icon={faCaretDown} />;
        }
        return <div className="cumplimiento-result">{iconoIndicador} {data}</div>;
    }

    const elementoProyectado = (rowProyectado = {}) => {
        const { esReal, packout: packoutRow, valor, real, tipo } = rowProyectado;
        if (esReal) {
            const packout = isNaN(packoutRow) ? 1 : packoutRow;
            let st = `<p class='tooltip-title'>Real calculado</p><p class='tooltip-def'> Kg reales * Packout semana pasada</p> <div class='tooltip-datas'> <div class='tooltip-data'>${dosDecimales(real)} <p class='tooltip-label'>Reales</p></div><div class='tooltip-data'>${Math.round(100 * packout)} % <p class='tooltip-label'>Packout</p></div></div>`;
            return <div>{dosDeciComas(valor)}<span className="label-unidad">Kg</span> <Button className={`${packout < 0.9 ? "packout-warning" : ""} btn-cumplimento`} type="button" label="Real" tooltip={st} /> </div>;
        }
        if (!valor) { return <div>0<span className="label-unidad">Kg</span></div>; }
        return <div>{dosDeciComas(valor)}<span className="label-unidad">Kg</span> <Button className="btn-cumplimento" type="button" label={tipo} tooltip={`Proyección ${tipo}`} /> </div>;
    }

    const obtenerFooter = () => {
        const totalesMostrar = props.filtrarKilos ? totalesDias.totalesKilos : totalesDias.totalesCajas;
        const totalesSinRestarMostrar = props.filtrarKilos ? totalSinRestar.totalesKilos : totalSinRestar.totalesCajas;

        if (props.unicoOrigenMedleys) {
            return <ColumnGroup>
                <Row>
                    <Column footer="Total" colSpan={2} />
                    {totalesMostrar?.map((total, index) => {
                        const bodyAMostrar = props.filtrarKilos ? <UnidadKilos valor={dosDeciComas(total)} /> : <UnidadCajas valor={dosDeciComas(total)} />;
                        return <Column key={getKey("t", index)} footer={bodyAMostrar} colspan={1} />;
                    })}
                </Row>
            </ColumnGroup>
        };
        if (proyectado.length === 0 || !week.time || !totalesDias?.totalesCajas || final?.length == 0) return null;
        const finalConComplementoFooter = (kilosComplementadosTotal || kilosRestadosTotal) ? <UnidadKilos valor={dosDeciComas(finalConComplemento)} /> : null;

        const filaDatosDeTotales = esInvMedleys ? totalesSinRestarMostrar : totalesMostrar;
        let footerGroup = <ColumnGroup>
            <Row>
                <Column footer="Total" colSpan={2} />
                {filaDatosDeTotales?.map((total, index) => {
                    const bodyAMostrar = props.filtrarKilos ? <UnidadKilos valor={dosDeciComas(total)} /> : <UnidadCajas valor={dosDeciComas(total)} />;
                    return <Column key={getKey("t", index)} footer={bodyAMostrar} colspan={1} />;
                })}
                {esInvMedleys 
                    ? <Column footer="Total prestado" footer={getFooterComplementoTotal()} />
                    : <Column footer="Total prestado" footer={<UnidadKilos valor={dosDeciComas(kilosComplementadosTotal)} />} colspan={1} />}
                {esInvMedleys && <Column footer="" colSpan={1} />}
            </Row>
            <Row>
                <Column footer="Disponible proyectado" colSpan={2} />
                {proyectado?.proyeccion?.map((proy, index) => {
                    return <Column key={keyTabla("t", index, props.producto, week)} footer={elementoProyectado(proy)} colSpan={1} />;
                })}
                <Column footer="" colSpan={esInvMedleys ? 3 : 2} />
            </Row>
            <Row>
                <Column footer="Disponible final" colSpan={2} />
                {final?.map((fin, index) => <Column key={getKey("f", index)} footer={<UnidadKilos valor={dosDeciComas(fin || 0)} />} colSpan={1} />)}
                <Column footer="" colSpan={esInvMedleys ? 0 : 1} />
                <Column footer={finalConComplementoFooter} colSpan={1} />
                {esInvMedleys && <Column footer="" colSpan={1} />}
            </Row>
        </ColumnGroup>;
        return footerGroup;
    }
    const getKey = (llave, index) => `${llave}-${index}-${props.productoId || ""}-${week.time}`;

    const getClassBoton = (clienteID) => {
        const distribucionFound = getDistribucionDatosCliente(clienteID);
        if (Object.keys(distribucionFound)?.length > 0) { return "boton-redistribuido-med"; }
        else { return "boton-redistribuir-med"; }
    }
    const abrirModalMedleys = (cliente_ref) => {
        const distribucionFound = getDistribucionDatosCliente(cliente_ref);
        const hayDatos = Object.keys(distribucionFound)?.length > 0;
        const datos = hayDatos ? distribucionFound : { id: null, cliente_ref, invernadero_virtual_ref: props.invernadero.id, semana: week.time, origenes: [{}] };
        props.setShowModalMedleys(datos);
    }
    const getFooterComplementoTotal = () => {
        const tooltipCalculoComplemento = obtenerTooltip();
        const sumaTotalTemplate = <div className="complemento-total-text">{sumaTotalPrestado} <span className="label-unidad">kg</span></div>;
        return <Button className="invisible-button c-black" tooltip={tooltipCalculoComplemento}>{sumaTotalTemplate}</Button>
    }
    const obtenerTooltip = () => {
        if (sumaTotalPrestadoTool.length === 0) return ""
        const body = sumaTotalPrestadoTool.map((prestado, index) => {
            return `<div key={${index}} class='tooltip-datas tooltip-separator'> 
                        <div class='tooltip-data'>
                          <p class='tooltip-title'>${prestado.destino}</p>
                          <p class='tooltip-date'>${"Destino"}</p> 
                        </div>
                        <div class='tooltip-data'>${prestado.restado}
                          <p class='tooltip-label'>kg restado</p>
                        </div>
                        <div class='tooltip-data'>${prestado.variedad}
                          <p class='tooltip-label'>Variedad</p>
                        </div>
                      </div>`
        }).join(" ")
        let htmlInline = `<label>Iniciando con el complemento de: ${sumaTotalDestino}kg</label> ${body}`
        return htmlInline
    }

    return (
        <div className="cumplimiento">
            <TemplateIconoInvernaderoTabla invernadero={props.invernadero} />

            {!props.unicoOrigenMedleys &&
                <React.Fragment >
                    <span className="sobrante-semana">
                        <div className='sobrante-data'>
                            {dosDeciComas(sobrantePasada)} kg
                            <p className='sobrante-label'>
                                Sobrante de la semana pasada
                            </p>
                        </div>
                    </span>
                    <span className="sobrante-semana">
                        <div className='sobrante-data'>
                            {dosDeciComas(sobranteActual)} kg
                            <p className='sobrante-label'>
                                Sobrante actual
                            </p>
                        </div>
                    </span>
                    {finalConComplemento > 0 &&
                        <span className="ml-3 sobrante-semana">
                            <div className='sobrante-data'>
                                {dosDeciComas(finalConComplemento)} kg
                                <p className='sobrante-label'>
                                    Sobrante con complemento
                                </p>
                            </div>
                        </span>}
                </React.Fragment >}

            <div className={`tabla-cumplimiento bor-${props.invernadero.color}`}>
                {(sobrantePasada >= 0 && rows?.length > 0 && !isNaN(sobrantePasada) || props.unicoOrigenMedleys)
                    ? <DataTable value={rows} footerColumnGroup={obtenerFooter()} rowGroupMode="rowspan" groupRowsBy="presentacion.cliente_ref" sortField="presentacion.cliente_ref" >
                        <Column field="presentacion.cliente_ref" header="Cliente de presentación" body={rowData => getNombrePorID(clientes, rowData.presentacion.cliente_ref)} />
                        <Column field={"presentacion.presentacion"} header={"Presentación"} />

                        {obtenerDiasDeSemana(week?.time)?.map((dia, index) => {
                            const key = `cump-${dia}-${props.invernadero}`;
                            return <Column key={key} columnKey={`${index}`} body={bodyTemplateDia} field={`${dia}`} header={obtenerNombreDiaSemana(dia)} />;
                        })}
                        <Column key={"total"} body={rowData => props.filtrarKilos ? <UnidadKilos valor={dosDeciComas(rowData.totales.sumaKilos)} /> : <UnidadCj valor={dosDeciComas(rowData.totales.sumaCajas)} />} field={"Total"} header={"total"} />

                        {!props.unicoOrigenMedleys && 
                            <Column key={"Complemento"} className="text-center" field={"presentacion.cliente_ref"} header={"Complemento"} body={rowData => <UnidadKilos valor={rowData.kilos_prestado} />} />}
                        {!props.unicoOrigenMedleys && esInvMedleys && 
                            <Column key={"prestar"} field={"presentacion.cliente_ref"} header={"prestar"} body={(rowData) => <Button name={rowData.cliente.id} type="button" className={getClassBoton(rowData.cliente.id)} label={"Complementar"} onClick={() => abrirModalMedleys(rowData.cliente.id)} />} />}
                    </DataTable>
                    : <div className="p-col"> No se ha ingresado el sobrante de la semana pasada</div>}
            </div>

        </div>)
}

export default TablaCumplimientoInv;//330