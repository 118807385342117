import React, { useState, useEffect, useGlobal } from "reactn";
import { obtenerDefectos } from '../../service/Defectos';
import { obtenerReporteDefectosDiario, obtenerReporteDefectos } from '../../service/ReporteCalidad';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { obtenerNombreFecha } from "../../service/fechas";
import { dosDecimales, getObjetoPorID } from '../../util/functions';
import Firma from "../common/Firma";
import { nombresDiasSemanaPlanos, nombresDiasSemana, rolProveedorExterno, JEFA_CALIDAD_FIRMA } from "../../constants";

const INICIAL = "Inicial", FINAL = "Final";
const ReporteDefectos = (props) => {
    const [rows, setRows] = useState([]);
    const [defectos, setDefectos] = useState([]);
    const [invernadero, setInvernadero] = useGlobal("invernadero");
    const [cargando, setCargando] = useGlobal("cargando");
    const [invernaderos, setInvernaderos] = useGlobal("invernaderos");
    const [ciclo,] = useGlobal("ciclo");
    const [defectosPresentes, setDefectosPresentes] = useState([]);
    const [comentarios, setComentarios] = useState([]);
    const [, setMostrarModalPermiso] = useGlobal("mostrarModalPermiso");
    const [usuario] = useGlobal("usuario");
    const [productosYVariedadesTodos] = useGlobal("productosYVariedadesTodos");

    useEffect(() => {
        obtenerDefectos().then((result) => {
            setDefectos(result);
        }).catch((err) => {
            console.log("ERROR OBTENER DEFECTOS");
        });

    }, [])

    useEffect(() => {
        if (ciclo && invernadero && props.semana && defectos.length > 0) {
            obtenerDatosIniciales(props.subVariedad || false, props.proveedorExterno);
        }
    }, [invernadero, props.semana, defectos, props.subVariedad, props.proveedorExterno])
    useEffect(() => {
        //window.alert(props.proveedorExterno)
    }, [props.proveedorExterno])
    const obtenerDatosIniciales = async (subVariedad, proveedorExterno) => {
        try {
            setCargando(true);
            let producto = getObjetoPorID(productosYVariedadesTodos, invernadero.producto_ref);
            let resultDiarioPromise = obtenerReporteDefectosDiario(ciclo, invernadero.id, props.semana, producto.tipo_calidad_inicial, subVariedad, proveedorExterno)
            let resultSemanalPromise = obtenerReporteDefectos(ciclo, invernadero.id, props.semana, producto.tipo_calidad_inicial, subVariedad, proveedorExterno)
            const [resultDiario, resultSemanal] = await Promise.all([resultDiarioPromise, resultSemanalPromise])
            procesarReporte(resultDiario, resultSemanal);
        }
        catch (error) {
            console.log("ERROR REPORTE DEFECTOS: ", error);
            if (error.code && error.code === "permission-denied") { setMostrarModalPermiso(true); }
        }
        setCargando(false);
    }

    const procesarReporte = (result, resultSemanal) => {
        let dias = nombresDiasSemanaPlanos;
        let keysInicial = nombresDiasSemanaPlanos.reduce((acc, nombreDia) => {
            let keys = Object.keys(result["promediosInicial"][nombreDia]);
            return acc.concat(keys);
        }, [])
        let setInicial = new Set(keysInicial);
        keysInicial = Array.from(setInicial);

        dias.map(dia => {
            result["promediosInicial"][dia]["total"] = {};
            result["promediosInicial"][dia]["total"]["cociente"] = 0;
            keysInicial.forEach(k => {
                let dato = result["promediosInicial"][dia][k] ? result["promediosInicial"][dia][k]["cociente"] : 0;
                result["promediosInicial"][dia]["total"]["cociente"] += isNaN(dato) ? 0 : dato;
            })
        })


        let keysFinal = nombresDiasSemanaPlanos.reduce((acc, nombreDia) => {
            let keys = Object.keys(result["promediosFinal"][nombreDia]);
            return acc.concat(keys);
        }, [])
        let setFinal = new Set(keysFinal);
        keysFinal = Array.from(setFinal);
        dias.map(dia => {
            result["promediosFinal"][dia]["total"] = {};
            result["promediosFinal"][dia]["total"]["cociente"] = 0;
            keysFinal.forEach(k => {
                let dato = result["promediosFinal"][dia][k] ? result["promediosFinal"][dia][k]["cociente"] : 0;
                result["promediosFinal"][dia]["total"]["cociente"] += isNaN(dato) ? 0 : dato;
            })
        })

        result["promediosFinal"]["total"] = dosDecimales(result["promediosFinal"]["total"]);
        result["promediosInicial"]["total"] = dosDecimales(result["promediosInicial"]["total"]);
        let listaDefectos = keysInicial.concat(keysFinal);
        result["promediosInicial"]["nombre"] = "INICIAL";
        result["promediosFinal"]["nombre"] = "FINAL";
        result["promediosInicial"]["semanal"] = resultSemanal["promediosInicial"];
        result["promediosFinal"]["semanal"] = resultSemanal["promediosFinal"];

        result["promediosInicial"]["semanal"]["total"] = {};
        result["promediosInicial"]["semanal"]["total"]["cociente"] = 0;
        result["promediosFinal"]["semanal"]["total"] = {};
        result["promediosFinal"]["semanal"]["total"]["cociente"] = 0;
        keysInicial.forEach(k => {
            let dato = result["promediosInicial"]["semanal"][k] ? result["promediosInicial"]["semanal"][k]["cociente"] : 0;
            result["promediosInicial"]["semanal"]["total"]["cociente"] += isNaN(dato) ? 0 : dato;

        })
        keysFinal.forEach(k => {
            let dato = result["promediosFinal"]["semanal"][k] ? result["promediosFinal"]["semanal"][k]["cociente"] : 0;
            result["promediosFinal"]["semanal"]["total"]["cociente"] += isNaN(dato) ? 0 : dato;
        })

        let rows = dias.map((diaSemana, index) => {
            return { nombre: nombresDiasSemana[index], inicial: result["promediosInicial"][diaSemana], final: result["promediosFinal"][diaSemana] }
        });
        rows.push({ nombre: "Semanal", inicial: result["promediosInicial"]["semanal"], final: result["promediosFinal"]["semanal"] })
        setRows(rows);

        let set = new Set(listaDefectos);
        listaDefectos = Array.from(set);

        listaDefectos = listaDefectos.map(defectoRef => defectos.find(el => el.id === defectoRef))

        setDefectosPresentes(listaDefectos);
        props.actualizarDefectosDisponibles(listaDefectos);

        setComentarios(result["comentarios"]);
    }
    const filtrarIrrelevantes = (rows, defectos) => {
        const minimo = 5;
        let filtrados = defectos.filter(defecto => {
            let cumple = false;
            rows.forEach(row => {
                if (row.nombre !== "Semanal") {
                    let inicial = row["inicial"][defecto.id]
                    let final = row["final"][defecto.id]
                    if ((inicial && inicial["cociente"] > minimo) || (final && final["cociente"] > minimo)) {
                        cumple = true;
                    }
                    return true
                }
            })
            return cumple
        })
        return filtrados;
    }

    const templateBody = (rowData, column) => {
        const isInicial = props.datosAMostrar.find((dato) => dato.name === INICIAL);
        const isFinal = props.datosAMostrar.find((dato) => dato.name === FINAL);
        //console.log({ isInicial, isFinal })
        let inicial = rowData["inicial"][column["field"]] ? rowData["inicial"][column["field"]]["cociente"] : 0;
        let final = rowData["final"][column["field"]] ? rowData["final"][column["field"]]["cociente"] : 0;
        inicial = isNaN(inicial) ? 0 : inicial;
        final = isNaN(final) ? 0 : final;

        let showFinal = isFinal;
        if (props.reporteExterno) { showFinal = false; }
        return (
            <div>
                {isInicial && <span className="calidad-data">
                    <span className="calidad-value">{dosDecimales(inicial)}
                        <span className="label-unidad">%</span>
                    </span>
                    <span className="label-unidad">INICIAL</span>
                </span>}

                {showFinal && <span className="calidad-data">
                    <span className="calidad-value">{dosDecimales(final)}
                        <span className="label-unidad">%</span>
                    </span>
                    <span className="label-unidad">FINAL</span>
                </span>}
            </div>)
    }

    const obtenerColumnas = (params) => {
        let columnas = [<Column key="nombre" field="nombre" header="" />];
        props.defectos.forEach(defectoId => {
            let defecto = defectosPresentes.find(el => el.id === defectoId);
            if (defecto) { columnas.push(<Column key={`${defecto?.id}`} field={defecto.id} header={defecto.nombre} body={templateBody} />); }
        })
        columnas.push(<Column key="total" field="total" header="Total" body={templateBody} className="col__label"/>)
        return columnas;
    }

    return (
        <React.Fragment>
            <div className="card p-0">
                <DataTable value={rows}>
                    {obtenerColumnas()}
                </DataTable>
            </div>

            {usuario.rol !== rolProveedorExterno && <p className="section-title">Comentarios/hallazgos</p>}
            {usuario.rol !== rolProveedorExterno && <div className="card">
                <div className="p-col-12 max-6">
                    {comentarios.map(comentario => {
                        return <div> {obtenerNombreFecha(comentario.momento)} - {comentario.comentario} </div>
                    })}
                </div>
        </div>}

        {!props.reporteExterno &&
            <div className="p-col-12">
                <Firma {...JEFA_CALIDAD_FIRMA}/>
            </div>}
        </React.Fragment>)
}
export default ReporteDefectos;//224