import React, { useEffect, useGlobal } from "reactn";
import { obtenerListaDeSemanas } from "../../../service/fechas";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { segundosSemana } from "../../../constants";

const Budget = (props) => {
    const [ciclo] = useGlobal("ciclo");
    const [cicloObj] = useGlobal("cicloObj");
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    //const [, setNombreModulo] = useGlobal("nombreModulo");

    useEffect(() => {
        setHabilitadoSelectorCiclo(false);
        //setNombreModulo("Empaque");
    }, [])
    useEffect(() => {
        if (ciclo && (props.inicio || props.final)) {
            const fechaInicio = props.inicio?.time || cicloObj.semana_inicio;
            const fechaFinal = props.final?.time ? (props.final?.time + segundosSemana) : cicloObj.semana_cierre;

            const listaSemanas = obtenerListaDeSemanas(fechaInicio, fechaFinal)
            const budgetSemanas = listaSemanas.map(week => {
                let budgetSelected = { time: week.time, nombre: week.nombre, value: "" };
                let est = props.budget.find(el => el.time === week.time);
                return est || budgetSelected;
            })
            props.setBudget(budgetSemanas)
        }
    }, [props.inicio, props.final, props.stringBudget, ciclo])

    const handleBudget = (ev, index) => {
        let copia = props.budget.slice();
        copia[index].value = ev.target.value;
        props.setBudget(copia);
    }
    return (
        <div className="p-grid p-justify-center">
            <div className="p-col-10">
                <div className="p-grid p-fluid ">
                    <div className="p-col-12">
                        <p className="section-title">Budget semanal</p>
                        <div className="card">
                            {props.budget.map((week, index) =>
                                <div key={index} className="form-group p-grid p-fluid pallet-dailyweight">
                                    <div className="p-col-auto p-justify-end p-grid p-align-center m-0">
                                        <label htmlFor={`budget_${week.time}`}>Semana {week.nombre}</label>
                                    </div>
                                    <div className="p-col">
                                        <InputText id={week.time} value={props.budget[index].value} onChange={(ev) => handleBudget(ev, index)} />
                                    </div>
                                    <div className="p-col-auto p-justify-end p-grid p-align-center m-0">
                                        <label >Kg/m²</label>
                                    </div>
                                </div>)}
                            {props.errores?.budget && <Message severity="error" text="Deben llenarse todos los campos" />}
                        </div>
                    </div>
                </div>

            </div>
        </div>)
}
export default Budget;//181