import { faChartBar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { MultiSelect } from 'primereact/multiselect'
import { SelectButton } from 'primereact/selectbutton'
import React, { useEffect } from 'react'
import { useState, useGlobal } from 'reactn'
import { obtenerCiclo } from '../../service/Ciclos'
import { obtenerClientes, obtenerClientesCiclo } from '../../service/Clientes'
import { obtenerListaDeSemanas } from '../../service/fechas'
import { obtenerPresentaciones } from '../../service/Presentaciones'
import { obtenerGruposPresentaciones } from '../../service/QueriesGruposPresentaciones'
import { obtenerReportePrecios } from '../../service/ReportePrecios'
import { cuatroDecimales, dosDecimales, getObjetoPorID } from '../../util/functions'
import ContenedorFiltro from '../common/ContenedorFiltro'
import ContenedorHeader from '../common/ContenedorHeader'
import ContenedorPrincipal from '../common/ContenedorPrincipal'
import ContenedorTabla from '../common/ContenedorTabla'
import SeccionFiltros from '../common/SeccionFiltros'
const tipos = [{ name: "Grupos de presentaciones", code: "grupos" }, { name: "Presentaciones", code: "presentaciones" }]
const datosOpciones = [{ name: "Real", code: "real" }, { name: "Budget", code: "budget" }]
function Reporte(props) {
    const [clientes, setClientes] = useState([]);
    const [filtroClientes, setFiltroClientes] = useState([]);
    const [presentaciones, setPresentaciones] = useState([]);
    const [opcionesPresentaciones, setOpcionesPresentaciones] = useState([]);
    const [filtroPresentaciones, setFiltroPresentaciones] = useState([]);
    const [productos, setProductos] = useState([]);
    const [productosBases] = useGlobal("productosBases");
    const [filtroProductos, setFiltroProductos] = useState([]);
    const [ciclo] = useGlobal("ciclo");
    const [, setCargando] = useGlobal("cargando");
    const [semanas, setSemanas] = useState([]);
    const [datos, setDatos] = useState([]);
    const [tipo, setTipo] = useState(tipos[0])
    const [datoSeleccionado, setDatoSeleccionado] = useState([datosOpciones[0]])
    const [gruposPresentaciones, setGruposPresentaciones] = useState([])
    const [filtroGrupoPresentaciones, setFiltroGrupoPresentaciones] = useState([])
    const [opcionesGruposPresentaciones, setOpcionesGruposPresentaciones] = useState([])
    const [clientesOrig, setClientesOrig] = useState([])
    const [nombreModulo, setNombreModulo] = useGlobal("nombreModulo");
    useEffect(() => {
        setNombreModulo("Reporte de precios");
    }, [])
    useEffect(() => {
        if (ciclo) { obtenerDatosIniciales(); }
    }, [ciclo])
    const obtenerDatosIniciales = async (params) => {
        let gruposPresentaciones = await obtenerGruposPresentaciones()
        setGruposPresentaciones(gruposPresentaciones);
        let presentaciones = await obtenerPresentaciones();
        setPresentaciones(presentaciones);
        let clientes = await obtenerClientes();
        setClientesOrig(clientes);
        setProductos(productosBases?.filter(prod => prod.unico || prod.MEDLEYS_GENERAL))
    }
    console.log({ datos })
    useEffect(() => {
        if (ciclo) {
            actualizarDatos();
            asignarSemanasCiclo();
        }
    }, [ciclo, clientesOrig])
    const actualizarDatos = async (params) => {

        let clientesCiclo = await obtenerClientesCiclo(ciclo);

        let idsClientes = clientesCiclo.map(el => el.cliente_ref);
        let clientes = clientesOrig.filter(el => idsClientes.includes((el.id)))
        setClientes(clientes);
    }

    const procesarResumen = (resumen) => {
        let procesado = semanas.map(semana => {
            let row = {
                nombreSemana: semana.nombre
            }
            filtroPresentaciones.forEach(presentacion => {
                row[presentacion.id] = { nombrePresentacion: presentacion.presentacion, valorReal: 0, valorBudget: 0 }
            })
            return row
        })
        return procesado
    }

    const asignarSemanasCiclo = async (params) => {

        let cicloObj = await obtenerCiclo(ciclo);
        let semanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
        setSemanas(semanas);
    }
    const updateFiltroPresentaciones = (data) => {
        if (filtroClientes.length > 0 && filtroProductos.length > 0) {
            let idsClientes = filtroClientes.map(el => el.id);
            let idsProductos = filtroProductos.map(el => el.id);
            const filtroIncluyeMedleys = filtroProductos.some(prod => prod.MEDLEYS_GENERAL)
            let opciones = [];
            if (tipo["code"] == "presentaciones") {
                opciones = presentaciones.filter(el => idsClientes.includes(el.cliente_ref) && (idsProductos.includes(el.producto_ref) || el.mezcla && filtroIncluyeMedleys));
                let filtrados = filtroPresentaciones.filter(el => idsClientes.includes(el.cliente_ref) && idsProductos.includes(el.producto_ref));
                const newOptions = []
                opciones.forEach((pres) => {
                    let hasData = false
                    data.forEach((semanas) => {
                        const presentacion = semanas[pres.id]
                        console.log({ presentacion })
                        if (presentacion) {
                            if (presentacion.valorReal > 0 || presentacion.valorBudget > 0) {
                                hasData = true
                            }
                        }
                    })
                    if (hasData) newOptions.push(pres)
                })
                console.log({ opciones, filtrados, presentaciones })
                setOpcionesPresentaciones(newOptions);
                setFiltroPresentaciones(filtrados);
            } else {
                console.log("SE ejecuta")
                opciones = gruposPresentaciones.filter(el => idsClientes.includes(el.cliente_ref) && idsProductos.includes(el.producto_ref));
                const filtrados = filtroGrupoPresentaciones.filter(el => idsClientes.includes(el.cliente_ref) && idsProductos.includes(el.producto_ref));
                const newOptions = []
                opciones.forEach((pres) => {
                    let hasData = false
                    data.forEach((semanas) => {
                        const presentacion = semanas[pres.id]
                        console.log({ presentacion })
                        if (presentacion) {
                            if (presentacion.valorReal > 0 || presentacion.valorBudget > 0) {
                                hasData = true
                            }
                        }
                    })
                    if (hasData) newOptions.push(pres)
                })
                /* console.log("NUEVOS FILTROS", newOptions) */
                console.log({ opciones, filtrados, newOptions, gruposPresentaciones })
                setOpcionesGruposPresentaciones(newOptions);
                setFiltroGrupoPresentaciones(filtrados);
            }
        }

    }
    useEffect(() => {
        updateFiltroPresentaciones(datos)

    }, [filtroClientes, filtroProductos, tipo, datos])

    useEffect(() => {
        setFiltroGrupoPresentaciones([])
        setFiltroPresentaciones([])
        actualizarReportePrecios()
    }, [filtroClientes, tipo])

    const actualizarReportePrecios = async (params) => {
        setCargando(true);
        let resumen = await obtenerReportePrecios(ciclo, filtroClientes.map(el => el.id));
        if (tipo["code"] !== "presentaciones") {
            resumen = procesarGruposReportePrecios(resumen);
        }
        //console.log({ resumen })
        setDatos(resumen);
        setCargando(false);
    }
    const procesarGruposReportePrecios = (resumen) => {
        // 
        // 
        // 
        let result = resumen.map(datoSemana => {
            let obj = {
                semana: datoSemana.semana,
                nombreSemana: datoSemana.nombreSemana
            };
            gruposPresentaciones.forEach(grupo => {
                let sumaLibras = 0;
                let result = {
                    valorReal: 0,
                    valorBudget: 0
                }
                grupo.presentaciones_refs.forEach(presentacionRef => {
                    let dato = datoSemana[presentacionRef];
                    if (dato) {
                        result["valorReal"] += !isNaN(dato["precioTotalReal"]) ? dato["precioTotalReal"] : 0
                        sumaLibras += !isNaN(dato["sumaLibrasReal"]) ? dato["sumaLibrasReal"] : 0
                        result["valorBudget"] += !isNaN(dato["valorBudget"]) ? dato["valorBudget"] : 0
                    }
                })
                result["valorReal"] /= sumaLibras > 0 ? sumaLibras : 1;
                result["valorBudget"] /= grupo.presentaciones_refs.length;
                obj[grupo.id] = result;
            })
            return obj;
        })
        // 
        // 
        return result;
    }
    const RealTemplate = ({ valorReal }) => <div> ${valorReal}<span className="label-unidad">Real</span></div>
    const BudgetTemplate = ({ valorBudget }) => <div> ${valorBudget}<span className="label-unidad">Budget</span></div>
    const RealAndBudgetTemplate = ({ valorReal, valorBudget }) => <div> ${valorReal}<span className="label-unidad">Real</span> / ${valorBudget}<span className="label-unidad">Budget</span> </div>

    const templatePresentacion = (rowData, column) => {
        let dato = rowData[column["field"]];
        if (dato) {
            let valorReal = cuatroDecimales(dato["valorReal"]) || "-";
            let valorBudget = cuatroDecimales(dato["valorBudget"]) || "-";
            if (datoSeleccionado.length === 1 && datoSeleccionado[0].code === "real") {
                return valorReal === "-" ? "$-" : <RealTemplate valorReal={valorReal} />
            }
            if (datoSeleccionado.length === 1 && datoSeleccionado[0].code === "budget") {
                return valorBudget === "-" ? "$-" : <BudgetTemplate valorBudget={valorBudget} />
            }
            return <RealAndBudgetTemplate valorReal={valorReal} valorBudget={valorBudget} />
        } else {
            return ""
        }

    }
    const templateGrupoPresentacion = (rowData, column) => {
        let dato = rowData[column["field"]];
        if (dato) {
            let valorReal = cuatroDecimales(dato["valorReal"]) || "-";
            let valorBudget = cuatroDecimales(dato["valorBudget"]) || "-";
            if (datoSeleccionado.length === 1 && datoSeleccionado[0].code === "real") {
                return valorReal === "-" ? "$-" : <RealTemplate valorReal={valorReal} />
            }
            if (datoSeleccionado.length === 1 && datoSeleccionado[0].code === "budget") {
                return valorBudget === "-" ? "$-" : <BudgetTemplate valorBudget={valorBudget} />
            }
            return <RealAndBudgetTemplate valorReal={valorReal} valorBudget={valorBudget} />
        } else {
            return ""
        }

    }

    const obtenerColumnas = (params) => {
        let cols = [<Column field="nombreSemana" header="Semana" />];

        if (tipo["code"] === "presentaciones") {
            filtroPresentaciones.forEach(presentacion => {
                cols.push(<Column field={presentacion.id} header={presentacion.presentacion} body={templatePresentacion} />);
            })
        } else {
            filtroGrupoPresentaciones.forEach(presentacion => {
                cols.push(<Column field={presentacion.id} header={presentacion.nombre} body={templateGrupoPresentacion} />);
            })
        }

        return cols;
    }
    const handleTipoChange = (e) => {
        if (e.value) {
            setTipo(e.value)
        }
    }
    const handleDatoChange = (e) => {
        if (e.value.length > 0) {
            setDatoSeleccionado(e.value)
        }
    }
    const irGraficaPrecios = () => props.history.push({ pathname: "/analisis_costos/precios", data: { reportePrecios: true } })
    return (
        <ContenedorPrincipal>
            <ContenedorHeader col="p-col-6" iconos={"reporte-empaque-icon"} titulo="Reporte de precios" />
            <div className="p-col-6">
                <div className="btn-row">
                    <Button label="Gráfica de precios" onClick={irGraficaPrecios} className="reactbtn-icon">
                        <FontAwesomeIcon icon={faChartBar} />
                    </Button>
                </div>
            </div>
            <SeccionFiltros>
                <ContenedorFiltro label="Cliente">
                    <MultiSelect value={filtroClientes} options={clientes} optionLabel="nombre" dataKey="id"
                        onChange={e => setFiltroClientes(e.value)}
                        selectedItemsLabel={`${clientes.length} clientes seleccionados`} />
                </ContenedorFiltro>
                <ContenedorFiltro label="Productos">
                    <MultiSelect value={filtroProductos} options={productos} optionLabel="nombre" dataKey="id"
                        onChange={e => setFiltroProductos(e.value)}
                        selectedItemsLabel={`${filtroProductos.length} productos seleccionados`} />
                </ContenedorFiltro>
                <ContenedorFiltro label="Tipo" >
                    <SelectButton value={tipo} options={tipos} onChange={handleTipoChange} optionLabel="name" dataKey="code" unselectable={true} />
                </ContenedorFiltro>
                <ContenedorFiltro label="Datos" >
                    <SelectButton value={datoSeleccionado} options={datosOpciones} onChange={handleDatoChange} optionLabel="name" dataKey="code" multiple={true} />
                </ContenedorFiltro>
                {tipo["code"] === "presentaciones" ?
                    <ContenedorFiltro label="Presentaciones">
                        <MultiSelect key="pres" value={filtroPresentaciones} options={opcionesPresentaciones} optionLabel="presentacion" dataKey="id"
                            onChange={e => setFiltroPresentaciones(e.value)} filter={true}
                            selectedItemsLabel={`${filtroPresentaciones.length} presentaciones seleccionadas`} />
                    </ContenedorFiltro> :
                    <ContenedorFiltro label="Grupo Presentaciones">
                        <MultiSelect key="grup" value={filtroGrupoPresentaciones} options={opcionesGruposPresentaciones} optionLabel="nombre" dataKey="id"
                            onChange={e => setFiltroGrupoPresentaciones(e.value)} filter={true}
                            selectedItemsLabel={`${filtroGrupoPresentaciones.length} grupos de presentaciones seleccionadas`} />
                    </ContenedorFiltro>}
            </SeccionFiltros>

            <ContenedorTabla>
                <DataTable value={datos}>
                    {obtenerColumnas()}
                </DataTable>
            </ContenedorTabla>
        </ContenedorPrincipal>
    )
}
export default Reporte//258