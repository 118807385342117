import React, { useState, useGlobal, useEffect } from "reactn";
import { getReporteCosecha } from "../../service/Cosecha";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { dosDecimales, getProductoYVariedadString, separadoComas } from "../../util/functions";
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { Button } from "primereact/button";
import icono from '../../images/icons/external-icon.svg';

const Reporte = (props) => {
    const [ciclo,] = useGlobal("ciclo");
    const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector(true);
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [, setInvernadero] = useGlobal("invernadero");
    const [invernaderos, setInvernaderos] = useState([]);
    const [tabla, setTabla] = useState([]);
    const [, setCargando] = useGlobal("cargando");
    const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");

    useEffect(() => {
        setCargando(true);
        if (invernaderosVirtuales){setInvernaderos(invernaderosVirtuales);}
    }, [invernaderosVirtuales])

    useEffect(() => {
        setNombreModulo("Cosecha");
    }, [])

    useEffect(() => {
        if (ciclo && week.nombre !== "Cargando" && invernaderos?.length > 0) {
            getReporteCosecha(ciclo, week.time).then(resp => {
                procesarReporte(resp);
                setCargando(false);
            }).catch(err => {
                console.log("OCURRIO UN ERROR AL OBTENER REPORTE: " + err.message);
            })
        }
    }, [ciclo, week, invernaderos])

    const procesarReporte = (resp) => {
        let data = [], invernaderos = [];

        resp.forEach(el => {
            let [invernadero, semana, cajas, kilos, kg_m2, resumenEmpaque] = el;
            let temp = {}
            temp["invernadero_id"] = invernadero.id;
            temp["invernadero"] = invernadero.nombre;
            temp["cajas_diario"] = <div>{separadoComas(dosDecimales(cajas["diario"]))}<span className="label-unidad">cajas</span> <br />
                {separadoComas(dosDecimales(kilos["diario"]))}<span className="label-unidad">kg</span></div>;
            temp["cajas_semanal"] = <div>{separadoComas(dosDecimales(cajas["semanal"]))}<span className="label-unidad">cajas</span> <br />
                {separadoComas(dosDecimales(kilos["semanal"]))}<span className="label-unidad">kg</span></div>;
            temp["cajas_real"] = <div>{separadoComas(dosDecimales(cajas["real"]))}<span className="label-unidad">cajas</span> <br />
                {separadoComas(dosDecimales(kilos["real"]))}<span className="label-unidad">kg</span></div>;

            temp["kilos_diario"] = separadoComas(dosDecimales(kilos["diario"]));
            temp["kilos_semanal"] = separadoComas(dosDecimales(kilos["semanal"]));
            temp["kilos_real"] = separadoComas(dosDecimales(kilos["real"]));

            temp["kg_m2_diario"] = <div>{separadoComas(dosDecimales(kg_m2["diario"]))}<span className="label-unidad">kg/m²</span></div>;
            temp["kg_m2_semanal"] = <div>{separadoComas(dosDecimales(kg_m2["semanal"]))}<span className="label-unidad">kg/m²</span></div>;
            temp["kg_m2_real"] = <div>{separadoComas(dosDecimales(kg_m2["real"]))}<span className="label-unidad">kg/m²</span></div>;
            let porc_sem_real = dosDecimales(100 * cajas["real"] / cajas["semanal"]);
            let porc_sem_diario = dosDecimales(100 * cajas["diario"] / cajas["semanal"]);
            let porc_diario_real = dosDecimales(100 * cajas["real"] / cajas["diario"]);
            if (cajas["semanal"] !== 0) {
                if (porc_sem_real > 115) {
                    temp["porcentaje_semanal_real"] = <div className="arrow-alert alto"><FontAwesomeIcon icon={faCaretUp} />{porc_sem_real}<span className="label-unidad">%</span></div>;
                } else if (porc_sem_real < 85) {
                    temp["porcentaje_semanal_real"] = <div className="arrow-alert bajo"><FontAwesomeIcon icon={faCaretDown} />{porc_sem_real}<span className="label-unidad">%</span></div>;
                }
                else {
                    temp["porcentaje_semanal_real"] = <div > {porc_sem_real}<span className="label-unidad">%</span></div>;
                }
                if (porc_sem_diario > 115) {
                    temp["porcentaje_semanal_diario"] = <div className="arrow-alert alto"><FontAwesomeIcon icon={faCaretUp} />{porc_sem_diario}<span className="label-unidad">%</span></div>;
                } else if (porc_sem_diario < 85) {
                    temp["porcentaje_semanal_diario"] = <div className="arrow-alert bajo"><FontAwesomeIcon icon={faCaretDown} />{porc_sem_diario}<span className="label-unidad">%</span></div>;
                } else {
                    temp["porcentaje_semanal_diario"] = <div>{porc_sem_diario}<span className="label-unidad">%</span></div>;
                }
            } else {
                temp["porcentaje_semanal_real"] = <div >-<span className="label-unidad">%</span></div>;
                temp["porcentaje_semanal_diario"] = <div>-<span className="label-unidad">%</span></div>;
            }
            if (cajas["diario"] !== 0) {
                if (porc_diario_real > 115) {
                    temp["porcentaje_diario_real"] = <div className="arrow-alert alto"><FontAwesomeIcon icon={faCaretUp} />{cajas["diario"] == 0 ? "-" : porc_diario_real}<span className="label-unidad">%</span></div>;
                } else if (porc_diario_real < 85) {
                    temp["porcentaje_diario_real"] = <div className="arrow-alert bajo"><FontAwesomeIcon icon={faCaretDown} />{cajas["diario"] == 0 ? "-" : porc_diario_real}<span className="label-unidad">%</span></div>;
                } else {
                    temp["porcentaje_diario_real"] = <div>{cajas["diario"] == 0 ? "-" : porc_diario_real}<span className="label-unidad">%</span></div>;
                }
            } else {
                temp["porcentaje_diario_real"] = <div>-<span className="label-unidad">%</span></div>;
            }


            data.push(temp);
        })
        let set = new Set(invernaderos);
        invernaderos = Array.from(set);

        setTabla(data);
    }

    const irInvernadero = (inv) => {
        let objetivo = invernaderos.find(el => el.id === inv.id);
        setInvernadero(objetivo);
        if (inv.es_externo) props.history.push("/cosecha/compra_externa");
        else props.history.push("/cosecha/consultar");
    }

    const invernaderoBody = (rowData, column) => {
        if (invernaderos) {
            let invernadero = invernaderos.find(el => el.id === rowData?.invernadero_id);
            return (
                <Button className="invisible-button" label=" " tooltip={getProductoYVariedadString(invernadero)}>
                    <div className={`icon-general icon-mini icon-click bor-${invernadero?.color} bg-${invernadero?.color}`} onClick={() => { irInvernadero(invernadero) }}>
                        {rowData["invernadero"] === "LRT_GRAPE"
                            ? <img width="30" alt="icono" src={icono} />
                            : rowData["invernadero"]}
                    </div>
                </Button>)
        } else return <div >{rowData["invernadero"]}</div>;

    }

    let headerGroup = <ColumnGroup>
        <Row>
            <Column header="Invernadero" rowSpan={2} className="col__label" />
            <Column header="Total de producto (cajas / kg)" colSpan={3} />
            <Column header="Total de rendimiento (kg/m²)" colSpan={3} />
            <Column header="Porcentaje" colSpan={3} className="th-small  col__final" />
        </Row>
        <Row>

            <Column header="Semanal" colSpan={1} className="th-small" />
            <Column header="Diario" colSpan={1} className="th-small" />
            <Column header="Real" colSpan={1} className="th-small" />
            <Column header="Semanal" colSpan={1} className="th-small" />
            <Column header="Diario" colSpan={1} className="th-small" />
            <Column header="Real" colSpan={1} className="th-small" />
            <Column header="Real vs Semanal " colSpan={1} className="th-small  col__final" />
            <Column header="Diario vs Semanal " colSpan={1} className="th-small  col__final" />
            <Column header="Real vs Diario   " colSpan={1} className="th-small  col__final" />
        </Row>

    </ColumnGroup>;

    return <div className="p-grid p-justify-center">

        <div className="p-col-12 title-container">
            <span className="back-btn" onClick={props.history.goBack}></span>
            <span className="icon-general product-icon"></span>

            <span className="titles-group">
                <h1 className="header-title">
                    Reporte de cosecha
                </h1>

            </span>
        </div>
        <div className="p-col-12">
            <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
        </div>

        <div className="p-col-12">

            <div className="card p-0">
                <DataTable value={tabla} headerColumnGroup={headerGroup}>
                    <Column field="invernadero" className="col__label" body={invernaderoBody} />
                    <Column field="cajas_semanal" />
                    <Column field="cajas_diario" />
                    <Column field="cajas_real" />
                    <Column field="kg_m2_semanal" />
                    <Column field="kg_m2_diario" />
                    <Column field="kg_m2_real" />
                    <Column field="porcentaje_semanal_real" className="col__final" />
                    <Column field="porcentaje_semanal_diario" className="col__final" />
                    <Column field="porcentaje_diario_real" className="col__final" />
                </DataTable>
            </div>
        </div>
        <div className="p-col-12">
            <p className="section-title">Notas</p>
            <div className="card">
                <p className="mt-0">Los valores "Diario" se calculan con los datos "Diario" de la semana que se hayan ingresado hasta el día actual + los datos ingresados en "Semanal" para los días futuros de la semana</p>
                <p className="mb-0">Los valores "Real" se calculan con todos los datos "Real" de la semana que se hayan ingresado hasta la última actualización despues de las 5pm + los datos ingresados en "Diario" (o en su defecto "Semanal") para los días futuros de la semana</p>
            </div>
        </div>
    </div>
}
export default Reporte;