import React, { useEffect, useState } from 'react'
import { obtenerDiasDeSemanaConDetalles, obtenerNombreFechaCompacto } from '../../../service/fechas';
import CardDia from './CardDia';
import { useGlobal, setGlobal } from 'reactn';
import { withRouter } from 'react-router-dom';
import { sumarTiemposTomadosParciales } from '../../../service/Mantenimiento';

function Dias(props) {
    const [actividades, setActividades] = useGlobal("actividadesMantenimiento")
    const [dia, setDia] = useGlobal("dia")
    const [dias, setDias] = useState([]);

    useEffect(() => {
        let dias = obtenerDiasDeSemanaConDetalles(props.week.time);
        setDias(dias);
    }, [props.week])
    const consultar = (dia) => {
        setDia(dia);
        props.history.push("/mantenimiento/agente/consulta_dia");
    }

    const obtenerResumen = (diaTime) => {
        let tareasDia = props.tareas ? props.tareas.filter(tarea => tarea.dia === diaTime) : [];
        let result = { estimacion: 0, completadas: "" };
        let cuentaTareasDia = 0, emergeSum = 0, actSum = 0, completadas = 0


        result.estimacion = tareasDia.reduce(
            (acc, tarea) => {
                if (actividades) {
                    let actividad = actividades.find(actividad => actividad.id === tarea.actividad_ref);
                    if((tarea.tiempo_estimado || tarea.is_requisicion ) && !tarea.realizada){
                        return acc + parseInt(tarea.tiempo_estimado);
                    }
                    if (actividad && !tarea.realizada) {
                        if(tarea.is_requisicion || tarea.is_tiempo_estimado_locacion){
                            return acc + parseInt(tarea.tiempo_estimado);
                        } 
                        return acc + parseInt(actividad.tiempo_estimado);
                    } else if (actividad && tarea.realizada) {
                        if (tarea.is_emergencia) emergeSum += parseInt(tarea.tiempo_tomado)
                        else if(tarea.tareaParcial){ actSum += sumarTiemposTomadosParciales(tarea.tiempos_tomados); }
                        else { actSum += parseInt(tarea.tiempo_tomado);}
                        completadas++
                        return acc;
                    } else {
                        return acc;
                    }
                } else {
                    return acc;
                }
            }, 0);

        result.completadas = `${completadas}/${tareasDia.length}`;

        result.tiempoEmergencias = emergeSum || false
        result.tiempoRealizada = actSum || false
        return result;
    }

    return (
        <React.Fragment>
            {dias.map((dia, index) => {
                return <CardDia dia={dia}
                    resumenDia={obtenerResumen(dia.unixTime)}
                    onClick={() => consultar(dia)} />
            })
            }
        </React.Fragment>
    )
}

export default withRouter(Dias);