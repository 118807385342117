import React from 'react'
import clientessemanas from './../../../images/graficas/clientes-semanas.png';
import clientesciclo from './../../../images/graficas/clientes-ciclo.png';
import { Card } from 'primereact/card';

const HomeClientes = (props) => {
    return (
        <React.Fragment>
            <div className="titulo-seccion-graficas">
                <span className="icon-general user-icon"></span>
                Clientes
            </div>

            <div className="p-col-12 p-md-3  grafica-card" onClick={() => props.ir("/empaque/graficas/distribucion_clientes_semana")}>
                <Card className={`htg-card bor-gray-superlight`}  >
                    <div className="card-title">Clientes por semana - Distribución</div>
                    <img src={clientessemanas} />
                </Card>
            </div>
            <div className="p-col-12 p-md-3  grafica-card" onClick={() => props.ir("/empaque/graficas/distribucion_clientes_ciclo")}>
                <Card className={`htg-card bor-gray-superlight`}  >
                    <div className="card-title">Productos y clientes durante el ciclo - Distribución</div>
                    <img src={clientesciclo} />
                </Card>
            </div>
        </React.Fragment>)
}
export default HomeClientes;//37