import React from 'react'
import { useGlobal } from 'reactn';
import cosecha from './../../../images/graficas/cosecha.png';
import { Card } from 'primereact/card';
import GraficaCompras from './../../../images/graficas/compra.png';
import requisicionescompra from './../../../images/graficas/requisiciones-compra.png';
import requisicionesmantenimiento from './../../../images/graficas/requisiciones-mantenimiento.png';
import requisicionessistema from './../../../images/graficas/requisiciones-sistema.png';

export default function HomeRequisiciones(props) {

    return (
        <React.Fragment>
            <div className="titulo-seccion-graficas">
                <span className="icon-general compras-icon"></span>
                Requisiciones
            </div>
            {(props.tipoAdmin === "Administrador" || props.tipoAdmin === "Administrador Compras") &&
                <div className="p-col-12 p-md-3  grafica-card" onClick={() => props.ir("/requisiciones/grafica_compras")}>
                    <Card className={`htg-card bor-gray-superlight`}  >

                        <div className="card-title">Datos de requisiciones de compra</div>
                        <img src={requisicionescompra} />

                    </Card>
                </div>}
            {(props.tipoAdmin === "Administrador" || props.tipoAdmin === "Administrador Sistemas") &&
                <div className="p-col-12 p-md-3  grafica-card" onClick={() => props.ir("/requisiciones/grafica_sistemas")}>
                    <Card className={`htg-card bor-gray-superlight`}  >

                        <div className="card-title">Datos de requisiciones de sistema</div>
                        <img src={requisicionessistema} />

                    </Card>
                </div>}
            {(props.tipoAdmin === "Administrador" || props.tipoAdmin === "Administrador Mantenimiento") &&
                <div className="p-col-12 p-md-3  grafica-card" onClick={() => props.ir("/requisiciones/grafica_mantenimiento")}>
                    <Card className={`htg-card bor-gray-superlight`}  >

                        <div className="card-title">Datos de requisiciones de mantenimiento</div>
                        <img src={requisicionesmantenimiento} />

                    </Card>
                </div>}
        </React.Fragment>
    )
}