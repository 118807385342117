import { getFirebaseConfig } from "./util/config";

export const PROFILE_DEV = "DEV";
export const PROFILE_TEST = "TEST";
export const PROFILE_HTGROUP = "HTGROUP";

export const SELECTED_PROFILE = PROFILE_HTGROUP;
export const mapeoTabsCultivo = {
    "Clima Exterior": "exterior",
    "Clima Interior": "interior",
    "Riego": "riego",
    "Cultivo": "cultivo"
}

export const estamosEnProduccion = SELECTED_PROFILE === PROFILE_HTGROUP;
export const estamosEnTest = SELECTED_PROFILE === PROFILE_TEST;
export const estamosEnDev = SELECTED_PROFILE === PROFILE_DEV;

export const BASE_URL = `${window.location.href.split("/")?.[0]}/#` || "";

export const mapeoTabsCultivoInvertido = {
    "exterior": "Clima Exterior",
    "interior": "Clima Interior",
    "riego": "Riego",
    "cultivo": "Cultivo"
}
export const factorLbKg = 2.2;
// firebase
export const firebaseConfig = getFirebaseConfig()

// Roles de usuario
export const ROL_AGENTE_ADUANAL = "agente aduanal";
export const ROL_AGENTE_ADUANAL_MAYUSCULA = "Agente Aduanal";//Fix :/
export const ROL_CLIENTE = "cliente";
export const ROL_AGENTE_MANTENIMIENTO = "Agente";
export const ROL_ADMINISTRADOR = "Administrador"

export const horaCorte = 19;
export const factorBono = 1.2;
export const horaCorteDiario = 9;
export const colores = [
    { label: "Verde oscuro", value: "green-dark" },
    { label: "Verde", value: "green" },
    { label: "Azul oscuro", value: "blue-dark" },
    { label: "Azul", value: "blue" },
    { label: "Morado oscuro", value: "purple-dark" },
    { label: "Morado", value: "purple" },
    { label: "Rosado oscuro", value: "pink-dark" },
    { label: "Rosado", value: "pink" },
    { label: "Rojo", value: "red" },
    { label: "Rojo oscuro", value: "red-dark" },
    { label: "Naranja oscuro", value: "orange-dark" },
    { label: "Naranja", value: "orange" },
    { label: "Amarillo oscuro", value: "yellow-dark" },
    { label: "Amarillo", value: "yellow" },
    { label: "Gris oscuro", value: "gray-dark" },
    { label: "Gris", value: "gray" }

]
export const paletaClientes = {
    "WINDSET": "#eb0006",//"#DB000E",
    "BCHH": "#c5a15e",//"#047bda",
    "DEL FRESCO": "#05adea",//"#7C08C4",
    "KINOVA": "#e14e54",//"#D60074",
    "NATURE": "#b51015",//"#EBCA12",
    "NATUREFRESH": "#8ec63f",//"#44A026",
    "PURE": "#1c4389",//"#94A5A5",
    "SUNSET": "#f8982a",//"#FCAD5A",
    "AGROS": "#194f33",//"#62D60A",
}
export const paletaProductos = {
    "BEEF": "#62D60A",
    "CHERRY": "#45abec",
    "COCKTAIL": "#FF3C3C",
    "GRAPE": "#FCAD5A",
    "MEDLEYS": "#FDE450",
    "TOV": "#BAC3C3"
}
export const nombresDiasSemana = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
export const nombresDiasSemanaPlanos = ["lunes", "martes", "miercoles", "jueves", "viernes", "sabado"];
export const nombresDiasSemanaExtendida = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"];

//Monedas
export const monedas = [
    { nombre: "MX", id: "MX" },
    { nombre: "USD", id: "USD" },
    { nombre: "EUR", id: "EUR" }
]

export const empresasFiltros = [
    { label: "HTG", value: "HTG" },
    { label: "HTF", value: "HTF" },
    { label: "ISER", value: "ISER" },
    { label: "LORT", value: "LORT" }]

// Usadas en MÓDULO de BIOCONTROL
export const TIPO_ENFERMEDAD = "Enfermedad"
export const TIPO_PLAGA = "Plaga"
export const POLO_NORTE = "norte"
export const POLO_SUR = "sur"
export const POLOS_INVERNADERO = [POLO_NORTE, POLO_SUR]
export const NIVEL_INCIDENCIA_POR_DEFECTO = 2
export const METROS_CUADRADOS_POR_CLARO = 4
export const METROS_CUADRADOS_POR_HECTAREA = 10000

export const DETALLES_TIPO_INCIDENCIA = {
    "foco": {
        '1': {
            textoConsulta: "Bajo (A: 1-30 focos)"
        }, '2': {
            textoConsulta: "Medio (B: 31-60 focos)"
        }, '3': {
            textoConsulta: "Alto (C: 61-90 focos)"
        }, '4': {
            textoConsulta: "Grave (D: >90 focos)"
        }
    }, "porcentaje": {
        '1': {
            textoConsulta: "Bajo (A: 0-25% de incidencia)"
        }, '2': {
            textoConsulta: "Medio (B: 26-50% de incidencia)"
        }, '3': {
            textoConsulta: "Alto (C: 51-75% de incidencia)"
        }, '4': {
            textoConsulta: "Grave (D: 76-100% de incidencia)"
        }
    }
}

export const DETALLES_TIPO_INCIDENCIA_CALIDAD = {
    "porcentaje": {
        '1': {
            textoConsulta: "Perfecto (sin incidencia)"
        }, '2': {
            textoConsulta: "Bajo (A: 0-25% de incidencia)"
        }, '3': {
            textoConsulta: "Medio (B: 26-50% de incidencia)"
        }, '4': {
            textoConsulta: "Alto (C: 51-75% de incidencia)"
        }, '5': {
            textoConsulta: "Grave (D: 76-100% de incidencia)"
        }
    }
}
export const coloresCosecha = ["Verde", "Amarillo/Naranja", "Rojo", "Otros"]
export const nombreActividadEnrede = "Enrede";
export const nombreActividadEnredePlus = "Enrede + 1  Hoja";

export const nombreActividadClipeo = "Clipeo";
export const nombreActividadClipeoPlus = "Clipeo + 1  Hoja";
export const actividadesEnrede = [nombreActividadEnrede, nombreActividadEnredePlus];
export const actividadesClipeo = [nombreActividadClipeo, nombreActividadClipeoPlus];

export const segundosDia = 1 * 24 * 60 * 60;
export const miliSegundosDia = segundosDia * 1000;
export const segundosSemana = 7 * segundosDia;
export const miliSegundosSemana = 7 * miliSegundosDia;

export const rolProveedorExterno = "Proveedor Externo";
export const corteProveedorMayoritario = 10000;
export const CORTE_PROV_MAYORITARIO_DOS_HECTAREAS = 20000;
export const numeroMaximo = Number.MAX_SAFE_INTEGER;
export const numMaxRangoCajas = 999999999;
export const ROL_AGENTE = "Agente";

export const FORCE = true;
export const NOT_FORCE = false;

export const UNICO = "unico";
export const MEZCLADA = "mezclada";
export const MEDLEYS = "medleys";

export const ASCENDIENTE = true;
export const DESCENDIENTE = false

export const TIPO_GRUPO = true;
export const TIPO_PRES = false;



//Locaciones
export const ATONGO = "Atongo";
export const AGROPARK = "Agropark";

//empresas
export const HTF = "HTF";
export const HTG = "HTG";
export const ISER = "ISER";
export const LORT = "LORT";

export const MASTRONARDI = "Mastronardi"

//sitios empaque
export const EMPAQUE_SITIO_A = "Sitio A";
export const EMPAQUE_SITIO_T = "Sitio T";
export const EMPAQUE_SITIO_AGROPARK = "Empaque Agropark";

//fechas
export const UNIX_TIME = "unixTime";

//Mensajes de error
export const ERROR_MESSAGE_CAMPOS = "Debes llenar todos los campos";
export const ERROR_MESSAGE_BONO_OTROS = "Necesitas llenar el campo de bono no cosechadores";

//Paises
export const paises = [
    { id: "mexico", nombre: "México", nacionalidad: "mexicano" },
    { id: "america", nombre: "Estados Unidos ", nacionalidad: "americano" }
]

//teclado
export const BOTONES_GUARDAR = ["Tab", "Enter"];
export const BOTONES_CANCELAR = ["Escape"];

//Firmas
export const JEFA_CALIDAD_FIRMA = {
    nombre: "Emma Arteaga Ramirez",
    rol: "Jefa de Calidad",
    imagen: "https://firebasestorage.googleapis.com/v0/b/htgroup-77af6.appspot.com/o/estaticos%2Ffirma-calidad.jpg?alt=media&token=cb8f2574-5b7d-4377-a981-813022df26c6"
}