import React, { useState, useGlobal, useEffect } from 'reactn';
import { withRouter } from 'react-router-dom';
import { dosDecimales } from '../../util/functions';
import icono from '../../images/icons/external-icon.svg';
import DatoCard from '../common/cards/DatoCard';
import HTGCard from '../common/cards/HTGCard';

const ProveedorCard = (props) => {
  const [usuario,] = useGlobal("usuario");
  const [, setProveedor] = useGlobal("proveedor");
  const puedeConsultarRegistro = (params) => ["Administrador", "Personal Administrativo", "Auxiliar Grower", "Grower", "Administrador Empaque",
    "Auxiliar Empaque", "Administrador Calidad", "Administrador Embarques", "Auxiliar Embarques",].includes(usuario?.rol);

  const consultar = () => {
    if (puedeConsultarRegistro()) {
      setProveedor(props.proveedor);
      props.history.push("/cosecha/compra_externa/consultar");
    }
  }
  let producto = props.productos.find(el => el.id === props.proveedor.producto_ref);
  let variedad = producto ? producto.variedades.find(el => el.id === props.proveedor.variedad_ref) : null;

  const header = () =>
    <span className="card-header">
      <div className={`card-id icon-general icon-lort icon-id bg-main-blue bor-main-blue habilitado`} title="Lort">
        <img width="30" alt="icono" src={icono} />
      </div>
      <div>
        <p className="card-up">{props.proveedor?.nombre}</p>
        <p className="card-down">{`${producto ? producto.nombre : ""} - ${variedad ? variedad.nombre : ""}`}</p>
      </div>
    </span>;
  let className = `htg-card bor-main-blue Habilitado`;
  const realVsSemanalValor = Math.round(100 * props.reporte?.[2]?.["real"] / props.reporte?.[2]?.["semanal"]) || 0
  const realVsSemanalFormated = isNaN(realVsSemanalValor) || realVsSemanalValor === Infinity || realVsSemanalValor === 0 ? "-" : realVsSemanalValor
  const realVsDiarioValor = Math.round(100 * props.reporte?.[2]?.["real"] / props.reporte?.[2]?.["diario"]) || 0
  const realVsDiarioFormated = isNaN(realVsDiarioValor) || realVsDiarioValor === Infinity || realVsDiarioValor === 0 ? "-" : realVsDiarioValor

  const kgm2Semanal = `${dosDecimales(props.reporte?.[3]?.["semanal"] || 0)} kg`
  const kgm2Real = `${dosDecimales(props.reporte?.[3]?.["real"] || 0)} kg`

  return (
    <HTGCard headerClassName={className} header={header()} onClick={consultar}>
      <DatoCard label="kg/m2 Semanal" valor={kgm2Semanal} />
      <DatoCard label="kg/m2 Real" valor={kgm2Real} />
      <DatoCard label="Real vs. Semanal" valor={`${realVsSemanalFormated} %`} />
      <DatoCard label="Real vs. Diario" valor={`${realVsDiarioFormated} %`} />
    </HTGCard>);
}
export default withRouter(ProveedorCard);//52