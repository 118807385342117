import React, { useEffect, useState, useGlobal } from 'reactn';
import { obtenerActividadesEnCicloGrafica, obtenerCuentaEmpleadosHabilitadosInvernaderoGrafica, obtenerEmpleados, obtenerTodasActividadesInvernadero } from '../../service/RhService';
import { chunkArray, DeepClone, stringToColor } from '../../util/functions';
import { obtenerPorcentajeHorasTrabajadasEnCicloGrafica } from '../../service/RhService';
import SeccionFiltros from '../common/SeccionFiltros';
import ContenedorFiltro from '../common/ContenedorFiltro';
import { MultiSelect } from 'primereact/multiselect';
import { obtenerListaDeSemanas } from '../../service/fechas';
import Chart from "react-apexcharts";
import { SelectButton } from 'primereact/selectbutton';
import { tooltipCustomHorasVsActividades } from './utils/customTooltips.utils';
import { obtenerActividadesImportantes } from 'service/actividades';


const optionsOrig = {
    legend: {
        showForSingleSeries: true,
        formatter: function (seriesName, opts) {
            const lineaClassname = seriesName.includes("Horas") ? "dashed-line" : "solid-line";
            return `<div class = "chart-label"><span class = "dash-type"><span class="${lineaClassname}"></span></span> ${seriesName}</div>`;
        }
    },
    chart: {
        height: 350,
        type: 'area',
        zoom: { enabled: true, type: "x", autoScaleYAxis: true }
    },
    dataLabels: { enabled: false },
    stroke: { curve: 'straight', dashArray: [0, 1, 2, 3, 4, 5, 6, 7] },
    title: { text: 'Porcentaje de actividades vs porcentaje de horas', align: 'left' },
    grid: {
        row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
        },
    },
    xaxis: { categories: [] },
    tooltip: {
        y: {
            title: {
                formatter: function (seriesName, opts) {
                    if (parseInt(seriesName) > 0) { return seriesName; }
                }
            }
        },
        custom: ({ series, seriesIndex, dataPointIndex, w }) => tooltipCustomHorasVsActividades(
            { series, seriesIndex, dataPointIndex, w }
        ),
        fixed: { enabled: false, position: 'topRight' }
    },
}
const ACTIVIDADES = "Actividades", HORAS = "Horas";
const selectButtonOptions = [{ name: ACTIVIDADES, }, { name: HORAS }];

const HorasVsActividades = (props) => {
    const [ciclo] = useGlobal("ciclo");
    const [cicloObj] = useGlobal("cicloObj");
    const [, setCargando] = useGlobal("cargando");
    const [invernaderosVirtuales] = useGlobal("invernaderosInternos");
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");

    const [invernaderos, setInvernaderos] = useState([]);
    const [invernaderosOrig, setInvernaderosOrig] = useState([]);

    const [actividadesImportantes, setActividadesImportantes] = useState([]);
    const [actividadesEnCiclo, setActividadesEnCiclo] = useState({});
    const [empleadosEnCiclo, setEmpleadosEnCiclo] = useState({});
    const [actividadesRealizadas, setActividadesRealizadas] = useState({});

    const [options, setOptions] = useState({ ...optionsOrig });
    const [series, setSeries] = useState([]);
    const [optionsFiltros, setOptionsFiltros] = useState([]);
    const [filtroInvernaderos, setFiltroInvernaderos] = useState([]);
    const [selectButtonHorasActividades, setSelectButtonHorasActividades] = useState([selectButtonOptions[0]]);

    useEffect(() => {
        setHabilitadoSelectorCiclo(true)
    }, [])

    useEffect(() => {
        if (ciclo) { obtenerDatosIniciales(); }
    }, [ciclo])

    useEffect(() => {
        const newOptions = { ...options }
        newOptions.tooltip.custom = ({ series, seriesIndex, dataPointIndex, w }) => tooltipCustomHorasVsActividades(
            { series, seriesIndex, dataPointIndex, w, filtroInvernaderos, selectButtonHorasActividades }
        )
        setOptions(newOptions)
    }, [filtroInvernaderos, selectButtonHorasActividades])

    useEffect(() => {
        const [firstOption, secondOption] = selectButtonHorasActividades;
        if (ciclo && !empleadosEnCiclo && (firstOption?.name === HORAS || secondOption?.name === HORAS)) { obtenerDatosEmpleados(); }
    }, [empleadosEnCiclo, selectButtonHorasActividades])
    useEffect(() => {
        const [firstOption, secondOption] = selectButtonHorasActividades;
        if (ciclo && filtroInvernaderos.length === 0) { clearGrafica(); }
        else if (ciclo && filtroInvernaderos?.length > 0 &&
            (firstOption?.name === ACTIVIDADES || secondOption?.name === ACTIVIDADES) &&
            (firstOption?.name !== HORAS && secondOption?.name !== HORAS)) { actualizarDatasets(); }
        else if (ciclo && filtroInvernaderos?.length > 0 && empleadosEnCiclo && (firstOption?.name === HORAS || secondOption?.name === HORAS)) { actualizarDatasets(); }
    }, [filtroInvernaderos, selectButtonHorasActividades, empleadosEnCiclo])

    const obtenerDatosIniciales = async () => {
        setCargando(true);
        const promisesActividades = invernaderosVirtuales.map(inv => obtenerTodasActividadesInvernadero(ciclo, inv.id));
        const promises = [obtenerActividadesImportantes(), Promise.all(promisesActividades)];
        const [actividadesImportantes, registrosActividadesTodas] = await Promise.all(promises);
        const options = DeepClone(invernaderosVirtuales);

        const actividadesEnCicloCopia = {};
        invernaderosVirtuales.forEach((invernadero, index) => {
            actividadesEnCicloCopia[invernadero.id] = registrosActividadesTodas[index];
        })

        setActividadesImportantes(actividadesImportantes);
        setActividadesEnCiclo(actividadesEnCicloCopia);
        setEmpleadosEnCiclo(null);

        setOptionsFiltros(options);
        setFiltroInvernaderos([]);
        setCargando(false);
    }
    const obtenerDatosEmpleados = async () => {
        setCargando(true);
        let todosEmpleados = await obtenerEmpleados();
        const promisesEmpleados = invernaderosVirtuales.map(inv => obtenerCuentaEmpleadosHabilitadosInvernaderoGrafica(ciclo, inv.id, todosEmpleados));
        const cuentaEmpleadosTodos = await Promise.all(promisesEmpleados);

        const empleadosEnCicloCopia = {};
        invernaderosVirtuales.forEach((invernadero, index) => {
            empleadosEnCicloCopia[invernadero.id] = cuentaEmpleadosTodos[index];
        })
        setEmpleadosEnCiclo(empleadosEnCicloCopia);
        setCargando(false);
    }

    const clearGrafica = () => {
        setOptions(DeepClone(optionsOrig));
        setSeries([]);
    }


    const actualizarDatasets = async () => {
        setCargando(true);
        const ACTIVIDADES = "Actividades", HORAS = "Horas", promisesActividades = [], promisesHoras = [], invAct = [], invHoras = [];
        const semanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre).map(el => el.nombreCompacto);
        const actividadesRealizadasCopia = DeepClone(actividadesRealizadas);
        const valuesActividadesPorcentajes = [], valuesHorasPorcentajes = [];
        let chunkedFiltros = [filtroInvernaderos];

        if (filtroInvernaderos?.length > 3 && filtroInvernaderos?.length === invernaderosVirtuales?.length) {
            const chunkAmount = 4;
            chunkedFiltros = chunkArray(filtroInvernaderos, chunkAmount);
        }

        //Obtener actividades sola una vez si ya estan guardados en el estado
        for (const indexChunk in chunkedFiltros) {
            const invernaderosChunk = chunkedFiltros[indexChunk];

            for (const index in invernaderosChunk) {
                const invernadero = invernaderosChunk[index];
                const [firstOption, secondOption] = selectButtonHorasActividades;

                const registrosActividades = actividadesEnCiclo[invernadero.id];
                const actsRealizadasInv = actividadesRealizadas[invernadero.id];

                if (firstOption?.name === ACTIVIDADES || secondOption?.name === ACTIVIDADES) {
                    promisesActividades.push(obtenerPorcentajeActividades(cicloObj, registrosActividades, actsRealizadasInv));
                    invAct.push(invernadero);
                }
                if (firstOption?.name === HORAS || secondOption?.name === HORAS) {
                    promisesHoras.push(obtenerPorcentajeHorasLocal(cicloObj, invernadero, registrosActividades, actsRealizadasInv));
                    invHoras.push(invernadero);
                }
            }

            const valuesActividades = await Promise.all(promisesActividades), valuesHoras = await Promise.all(promisesHoras);
            valuesActividadesPorcentajes.push(...valuesActividades.map(el => el.porcentaje));
            valuesHorasPorcentajes.push(...valuesHoras.map(el => el.porcentaje));

            valuesActividades.forEach((value, index) => {
                const invernadero = filtroInvernaderos[index];
                actividadesRealizadasCopia[invernadero.id] = value.actividadesRealizadasPorSemana;
            })
            valuesHoras.forEach((value, index) => {
                const invernadero = filtroInvernaderos[index];
                actividadesRealizadasCopia[invernadero.id] = value.actividadesRealizadasPorSemana;
            })
        }

        const procesadoActividades = procesarActividades(invAct, valuesActividadesPorcentajes);
        const procesadoHoras = procesarHoras(invHoras, valuesHorasPorcentajes);
        const merged = mergeDatasets(procesadoActividades, procesadoHoras);
        const newOptions = actualizarOptions(merged, semanas)

        setActividadesRealizadas(actividadesRealizadasCopia);
        setSeries([...merged.datasets]);
        setOptions(newOptions);
        setCargando(false);
    }

    const obtenerPorcentajeActividades = async (cicloObj, registrosActividades = [], actsRealizadasInv) => {
        try {
            const listaSemanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
            const { porcentaje, actividadesRealizadasPorSemana }
                = await obtenerActividadesEnCicloGrafica(listaSemanas, registrosActividades, actsRealizadasInv, actividadesImportantes);
            return { porcentaje, actividadesRealizadasPorSemana };
        } catch (error) {
            console.log("ERROR - obtenerPorcentajeActividades", error)
            throw (error);
        }
    }
    const obtenerPorcentajeHorasLocal = async (ciclo, invernadero, registrosActividades, actsRealizadasInv) => {
        try {
            const cuentaEmpleados = empleadosEnCiclo[invernadero.id];
            let porcentaje = await obtenerPorcentajeHorasTrabajadasEnCicloGrafica(ciclo, invernadero, cuentaEmpleados, registrosActividades, actsRealizadasInv);
            return porcentaje;
        } catch (error) {
            throw (error);
        }
    }


    const procesarActividades = (series, resultAct) => {
        if (series.length === 0 || resultAct.length == 0) { return { labels: [], datasets: [] }; }
        let labels = resultAct[0].map(el => el.semana.nombreCompacto)
        let datasets = series.map((el, index) => {
            const asignacion_lineas = el.asignacion_lineas;
            const lineasTotales = asignacion_lineas?.reduce((acc, curr) => {
                let dato = curr.totales.split(" ")[0];
                dato = parseInt(dato);
                if (dato && !isNaN(dato)) { return acc + dato; }
                else if (dato) { return acc; }
            }, 0)
            const color = stringToColor(el.color);
            const name = `Actividades - ${el.nombre}`;
            const data = resultAct[index].map(el => Math.round(100 * el.actividades / (lineasTotales)));
            return { name, datasetId: `${el.id}`, label: el.nombre, index: 0, data, fill: false, borderColor: color, backgroundColor: color, color };
        });
        return { labels, datasets };
    }
    const procesarHoras = (series, resultPorcentajes) => {
        if (series.length === 0 || resultPorcentajes.length == 0) { return { labels: [], datasets: [] }; }
        let labels = resultPorcentajes[0].map(el => el.semana.nombre)
        let copiaOptions = { ...options };
        copiaOptions.xaxis.categories = labels;
        console.log("RESULT: ", resultPorcentajes)
        let datasets = series.map((el, index) => {
            const color = stringToColor(el.color);
            const data = resultPorcentajes[index].map(el => el.porcentaje);
            const name = `Horas - ${el.nombre}`;
            return { name, datasetId: `${el.id}`, label: el.nombre, index: 1, data, fill: false, borderColor: color, backgroundColor: color, color };
        });
        return { labels, datasets };
    }


    const mergeDatasets = (procesadoActividades, procesadoHoras) => {
        const actividadesDatasets = procesadoActividades.datasets;
        const horasDatasets = procesadoHoras.datasets;

        let datasets = [...actividadesDatasets, ...horasDatasets]
        if (actividadesDatasets.length === 0 && horasDatasets.length === 0) { return { labels: [], datasets: [] }; }
        if (actividadesDatasets.length > 0 && horasDatasets.length > 0) {
            const newDatasetsOrganized = [];
            actividadesDatasets.forEach((act) => {
                newDatasetsOrganized.push(act)
                const horas = horasDatasets.find((hora) => hora.label === act.label)
                newDatasetsOrganized.push(horas)
            })
            datasets = newDatasetsOrganized
        }
        return { labels: procesadoActividades.labels, datasets };
    }
    const actualizarOptions = (datasets, categories) => {
        const width = [], dashArray = [], colors = [];
        datasets.datasets.forEach(serie => {
            width.push(5 * (serie.index + 1));
            dashArray.push(3 * serie.index);
            colors.push(serie.color);
        })

        const optionsActualizados = { ...options, xaxis: { categories }, colors, stroke: { width, dashArray } };
        // console.log("fffff log", datasets)
        return optionsActualizados;
    }

    const handleSelect = (e) => {
        setFiltroInvernaderos(e.value);
    }

    const handleSelectButton = e => {
        if (e.value.length === 0) return
        setSelectButtonHorasActividades(e.value)
    }
    return <div className="p-grid chart-page chart-container-filters cosecha-chart">

        <div className="p-col  p-col-auto title-container">
            <span className="back-btn" onClick={() => { props.history.goBack() }}></span>
            <span className={`icon-general chart-icon `}></span>
            <span className="titles-group">
                <h1 className="header-title">Horas trabajadas por actividad</h1>
            </span>
        </div>
        <SeccionFiltros>
            <ContenedorFiltro label="Invernadero">
                <MultiSelect options={optionsFiltros} value={filtroInvernaderos} onChange={handleSelect} dataKey="id" optionLabel="nombre"
                    selectedItemsLabel={`${filtroInvernaderos.length} series seleccionados`} />
            </ContenedorFiltro>
            <ContenedorFiltro label="dato">
                <SelectButton options={selectButtonOptions} optionLabel="name" value={selectButtonHorasActividades} onChange={handleSelectButton} multiple={true} />
            </ContenedorFiltro>
        </SeccionFiltros>
        <div className={`p-col-12 chart-container-filters`}>
            <Chart type="line" height="100%" series={series} options={options} />
        </div>
    </div>
}
export default HorasVsActividades;