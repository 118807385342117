import React from 'react'
import { useEffect, useGlobal, useState } from 'reactn'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown';

import BudgetProveedor from './BudgetProveedor'
import { obtenerUsuarios } from '../../../service/Usuarios'
import ContenedorHeader from '../../common/ContenedorHeader'
import ContenedorInput from '../../common/ContenedorInput'
import ContenedorPrincipal from '../../common/ContenedorPrincipal'
import SeccionBotones from '../../common/SeccionBotones'
import SeccionFormulario from '../../common/SeccionFormulario'
import { obtenerListaDeSemanas } from '../../../service/fechas'
import { crearProveedor, actualizarProveedor } from '../../../service/Proveedores'
import { togglearProveedor, obtenerProveedor } from '../../../service/Queries/Proveedores'
import { rolProveedorExterno, segundosSemana } from '../../../constants'
import { Message } from 'primereact/message';

const inicial = {
    nombre: "",
    area_invernadero: "",
    ubicacion: "",
    abreviatura: ""
}
const FormularioGrupoPresentaciones = (props) => {
    const { proveedorID } = props.match.params;
    const [cicloId] = useGlobal("ciclo");
    const [cicloObj] = useGlobal("cicloObj");

    const [, setCargando] = useGlobal("cargando")
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [modal, setModal] = useGlobal("modal");
    const [productos] = useGlobal("productosYVariedades")

    const [state, setState] = useState({ ...inicial });
    const [, setUsuarios] = useState([])
    const [variedades, setVariedades] = useState([])
    const [producto, setProducto] = useState()
    const [variedad, setVariedad] = useState()
    const [budget, setBudget] = useState([]);
    const [usuarioAsignado, setUsuarioAsignado] = useState()
    const [editing, setEditing] = useState(proveedorID ? true : false);
    const [proveedor, setProveedor] = useState()
    const [opcionesUsuarios, setOpcionesUsuarios] = useState([]);
    const [fechaInicio, setFechaInicio] = useState()
    const [fechaCierre, setFechaCierre] = useState()
    const [semanas, setSemanas] = useState([])
    const [stringBudget, setStringBudget] = useState("")
    const [errores, setErrores] = useState({});

    useEffect(() => {
        setHabilitadoSelectorCiclo(false);
        setNombreModulo("Proveedores")
    }, [])

    useEffect(() => {
        setErrores({})
    }, [state, budget, stringBudget, usuarioAsignado, fechaInicio, fechaCierre, producto, variedad])

    useEffect(() => {
        if (cicloId) obtenerDatosIniciales();
    }, [cicloId])

    useEffect(() => {
        if (producto) setVariedades(producto.variedades);
    }, [producto])

    const obtenerDatosIniciales = async () => {
        setCargando(true)
        const usersAll = await obtenerUsuarios();
        const semanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre +(segundosSemana*6));
        const users = usersAll.filter(el => el.rol === rolProveedorExterno);

        setSemanas(semanas);
        setOpcionesUsuarios(users);
        setUsuarios(users);
        if (editing) {
            const objProveedor = await obtenerProveedor(cicloId, proveedorID)
            setProveedor(objProveedor)
            adaptarEdicion(objProveedor, semanas, users);
        } else setCargando(false);
    }

    const adaptarEdicion = (proveedor, semanas, usuarios) => {
        const { nombre, area_invernadero, ubicacion, abreviatura = "", habilitado } = proveedor
        let state = { nombre, area_invernadero, ubicacion, abreviatura };

        // if proveedor property is not defined or it is defined but is not false
        if (habilitado === undefined || habilitado) {
            state.habilitado = true
        }
        let producto = productos.find(el => el.id === proveedor["producto_ref"])
        let variedad = producto.variedades.find(el => el.id === proveedor["variedad_ref"])
        let semanaInicio = semanas.find(el => el.time === proveedor["fechaInicio"])
        let semanaCierre = semanas.find(el => el.time === proveedor["fechaCierre"])
        let usuario = usuarios.find(el => el.id === proveedor["usuario_ref"])

        setState(state);
        setProducto(producto);
        setVariedad(variedad);
        setFechaInicio(semanaInicio);
        setFechaCierre(semanaCierre);
        setUsuarioAsignado(usuario);
        setBudget(proveedor.budget);
        actualizarStringBudget(proveedor.budget);
        setCargando(false);
    }

    const cancelar = () => {
        setProveedor(null);
        setEditing(false);
        props.history.push("/admin/proveedores");
    }

    const guardar = async () => {
        setCargando(true);
        if (validarErrores()) {
            if (editing) await actualizarProveedor(cicloId, proveedor.id, state, usuarioAsignado, fechaInicio, fechaCierre, budget);
            else await crearProveedor(cicloId, state, usuarioAsignado, fechaInicio, fechaCierre, producto, variedad, budget);

            setProveedor(null);
            props.history.goBack();
        }
        setCargando(false)
    }
    const validarErrores = () => {
        let errores = {};
        let requeridos = ["nombre", "area_invernadero", "ubicacion"];
        requeridos.forEach(field => {
            if (!state[field]) errores[field] = true
        })

        if (budget.length === 0) errores.budget = true;
        budget.forEach(week => {
            if (!week.value && week.value !== 0) errores.budget = true;
        })
        if (!usuarioAsignado) errores.usuarioAsignado = true;
        if (!fechaInicio) errores.fechaInicio = true;
        if (!fechaCierre) errores.fechaCierre = true;
        if (!producto) errores.producto = true;
        if (!variedad) errores.variedad = true;

        setErrores(errores)
        return Object.keys(errores).length === 0
    }

    const puedeEditar = () => true;
    const handleInputChange = (target, value) => {
        let copia = { ...state };
        copia[target] = value;
        setState(copia);
    }

    const actualizarStringBudget = (budget) => {
        if (budget) {
            let nuevo = budget.map(el => `${el.time}, ${el.value}`).join("-");
            if (stringBudget != nuevo) setStringBudget(nuevo);
        }
        else return ""
    }

    const togglear = () => {
        togglearProveedor(cicloId, proveedor.id,).then(() => {
            setModal({ titulo: "Éxito", msj: `Se ${state.habilitado ? "deshabilitó" : "habilitó"} invernadero extosamente` });
        }).catch(err => { console.log(err); });
    }

    return (
        <ContenedorPrincipal>
            <ContenedorHeader titulo={editing ? "Modificar proveedor" : "Crear proveedor"} iconos="presentacion-icon" atras={cancelar} />
            <SeccionFormulario titulo="General">
                <ContenedorInput label="Nombre">
                    <InputText value={state["nombre"]} onChange={e => handleInputChange("nombre", e.target.value)} />
                    {errores.nombre && <Message severity="error" text="Este campo es requerido" />}
                </ContenedorInput>
                <ContenedorInput label="Abreviatura">
                    <InputText value={state["abreviatura"]} onChange={e => handleInputChange("abreviatura", e.target.value)} />
                </ContenedorInput>
                <ContenedorInput label="Área de invernadero">
                    <InputText value={state["area_invernadero"]} onChange={e => handleInputChange("area_invernadero", e.target.value)} />
                    {errores.area_invernadero && <Message severity="error" text="Este campo es requerido" />}
                </ContenedorInput>
                <ContenedorInput label="Ubicación">
                    <InputText value={state["ubicacion"]} onChange={e => handleInputChange("ubicacion", e.target.value)} />
                    {errores.ubicacion && <Message severity="error" text="Este campo es requerido" />}
                </ContenedorInput>
                <ContenedorInput label="Usuario asignado">
                    <Dropdown value={usuarioAsignado} options={opcionesUsuarios} onChange={(e) => setUsuarioAsignado(e.value)} optionLabel="displayName" dataKey="id" />
                    {errores.usuarioAsignado && <Message severity="error" text="Este campo es requerido" />}
                </ContenedorInput>
                <ContenedorInput label="Fecha Inicio">
                    <Dropdown value={fechaInicio} options={semanas} optionLabel="nombre" dataKey="time" onChange={(e) => setFechaInicio(e.value)} />
                    {errores.fechaInicio && <Message severity="error" text="Este campo es requerido" />}
                </ContenedorInput>
                <ContenedorInput label="Fecha finalización">
                    <Dropdown value={fechaCierre} options={semanas} optionLabel="nombre" dataKey="time" onChange={(e) => setFechaCierre(e.value)} />
                    {errores.fechaCierre && <Message severity="error" text="Este campo es requerido" />}
                </ContenedorInput>
                <ContenedorInput label="Producto">
                    <Dropdown value={producto} options={productos} optionLabel="nombre" dataKey="id" onChange={(e) => setProducto(e.value)} disabled={editing} />
                    {errores.producto && <Message severity="error" text="Este campo es requerido" />}
                </ContenedorInput>
                {producto &&
                    <ContenedorInput label="Variedad">
                        <Dropdown value={variedad} options={variedades} optionLabel="nombre" dataKey="id" onChange={(e) => setVariedad(e.value)} disabled={editing} />
                        {errores.variedad && <Message severity="error" text="Este campo es requerido" />}
                    </ContenedorInput>}
            </SeccionFormulario>

            <BudgetProveedor errores={errores} budget={budget} setBudget={setBudget} editing={editing} inicio={fechaInicio} final={fechaCierre} stringBudget={stringBudget} />

            <SeccionBotones>
                <Button label="Cancelar" className="p-button-secondary" onClick={cancelar} />
                {puedeEditar() && <Button label="Guardar" onClick={guardar} />}
                {<Button label={state.habilitado ? "Deshabilitar" : "Habilitar"} onClick={togglear} />}
            </SeccionBotones>
        </ContenedorPrincipal>
    )
}
export default FormularioGrupoPresentaciones;//266