import React, { setGlobal, useEffect, useGlobal, useState } from 'reactn';
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import InvernaderoBiocontrolCard from './InvernaderoBiocontrolCard';
import { obtenerEnfermedadesYPlagas, obtenerInfoDeResumen, obtenerResumenEnfermedades, obtenerResumenEnfermedadesTodos } from "../../service/biocontrol";

setGlobal({ registrosClarosBiocontrol: [] })
const CardsBiocontrol = (props) => {
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [, setCargando] = useGlobal("cargando")
    const [ciclo] = useGlobal("ciclo")
    const [usuario,] = useGlobal("usuario");
    const [, setMostrarModalPermiso] = useGlobal("mostrarModalPermiso");
    const [invernaderosFisicos] = useGlobal("invernaderosFisicos");
    const [enfermedades] = useGlobal("enfermedadesYPlagas")
    const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector();
    const [invernaderosCardsInfo, setInvernaderosCardsInfo] = useState([]);
    const [registrosClarosBiocontrol] = useGlobal("registrosClarosBiocontrol");
    const [, setInvernaderoSeleccionado] = useGlobal("invernaderoFisico");

    const puedeConsultar = () => ["Administrador", "Administrador Biocontrol", "Auxiliar Biocontrol", "Personal Administrativo", "Grower"].includes(usuario?.rol)

    useEffect(() => {
        setNombreModulo("Biocontrol")
        setHabilitadoSelectorCiclo(true);
    }, [])
    useEffect(() => {
        setCargando(true)
        if (invernaderosFisicos)
            updateCicloWeekSelector(ciclo);
    }, [invernaderosFisicos])
    useEffect(() => {
        if (week.nombre !== "Cargando")
            obtenerDatosIniciales();
    }, [week]);

    const obtenerDatosIniciales = async () => {
        setCargando(true)
        try {
            let enfermedadesPlagas = enfermedades || await obtenerEnfermedadesYPlagas()
            let infoCards = await obtenerResumenEnfermedadesTodos(invernaderosFisicos, ciclo, week.time, enfermedadesPlagas, registrosClarosBiocontrol)
            setInvernaderosCardsInfo(infoCards);
        } catch (error) {
            if (error.code && error.code === "permission-denied")
                setMostrarModalPermiso(true);

        }
        setCargando(false)
    }

    const irMapaInvernadero = (invernadero) => {
        setInvernaderoSeleccionado(invernadero)
        if (puedeConsultar())
            props.history.push("/biocontrol/mapa_invernadero")
    }

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="p-grid filtros">
                    <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week}/>
                </div>
            </div>
            <div className="p-col-12">
                <div className="p-grid">
                    {invernaderosCardsInfo.map(info =>
                        <InvernaderoBiocontrolCard key={info.invernadero.id}
                            info={info} invernadero={info.invernadero}
                            onClick={() => irMapaInvernadero(info.invernadero)} />)}
                </div>
            </div>
        </div >
    );
}
export default CardsBiocontrol;