import 'react-app-polyfill/ie11';
import React, { useGlobal, useEffect, setGlobal } from "reactn";
import { Dialog } from "primereact/dialog";
import ReactDOM from 'react-dom';
import App from './App';
import * as firebase from 'firebase/app';
import "firebase/auth";
import { getCiclos } from "./service/Ciclos";
import { HashRouter } from 'react-router-dom'
import ScrollToTop from './ScrollToTop';
import initApp from "./util/firebase";
import { jalarUsuario, startListener } from "./service/account";
import ClipLoader from 'react-spinners/ClipLoader';
import ModalPermiso from './components/common/ModalPermiso';
import ModalConfirmacion from './components/common/ModalConfirmacion';
import { getInvernaderosFisicos, getInvernaderosVirtuales, ordenarInvernaderosVirtualesPorIndexFisicoYNombre } from "./service/Invernaderos";
import { getColeccionesProductosYVariedadesTodos, obtenerProductosDelCiclo } from './service/ProductosYVariedades';
import ModalMostrarMensaje from './components/common/ModalMostrarMensaje';
import { estamosEnProduccion, FORCE } from './constants';
import { getProductos as getProductosNuevos } from './service/ProductosYVariedades';
import { obtenerClientes } from 'service/Clientes';
import { obtenerEmpresas } from 'service/Empresas';

initApp();
ReactDOM.render(
    <HashRouter>
        <ScrollToTop>
            <SessionHandler />
        </ScrollToTop>
    </HashRouter>,
    document.getElementById('root')
);

function SessionHandler() {
    const [currUser, setUsuario] = useGlobal("usuario");
    const [cicloObj] = useGlobal("cicloObj");
    const [cargandoDatosGlobales] = useGlobal("cargandoDatosGlobales");
    const [cargandoPantalla] = useGlobal("cargando");
    const [mostrarModalPermiso, setMostrarModalPermiso] = useGlobal("mostrarModalPermiso");
    const [modalConfirmacion, setModalConfirmacion] = useGlobal("modalConfirmacion");
    const [arregloInvsVirtuales, setArregloInvsVirtuales] = useGlobal("arregloInvsVirtuales");
    const [modal, setModal] = useGlobal("modal");

    useEffect(() => {
        setGlobal({ cargandoDatosGlobales: true, habilitadoSelectorCiclo: true, ciclos: [], arregloInvsVirtuales: {} });
        obtenerDatosUsuario()
    }, [])
    const obtenerDatosUsuario = async () => {
        firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                const [usuario, ciclos = []] = await Promise.all([jalarUsuario(user.uid), getCiclos()])
                startListener(user.uid, setUsuario);

                const cicloSeleccionado = ciclos.find((el, index) => el.currentCiclo || index === ciclos.length - 1)
                setGlobal({ usuario, ciclos, cicloObj: cicloSeleccionado });
            }
            else setGlobal({ cargandoDatosGlobales: false, usuario: "OFF" });
        });
    }
    
    useEffect(() => {
        if (cicloObj) obtenerDatosGlobales(cicloObj.id)
    }, [cicloObj])
    const obtenerDatosGlobales = async (ciclo) => {
        setGlobal({ cargandoDatosGlobales: true });
        const promises = [getInvernaderosFisicos(), getInvernaderosVirtuales(ciclo, FORCE), getColeccionesProductosYVariedadesTodos(ciclo, FORCE), obtenerClientes(), obtenerEmpresas()];
        const [invernaderosFisicos, invernaderosVirtuales,
            { productosYVariedades, productosUnicos, productosMezclados, productosBases, medleysGeneral, allVariedades, productosYVariedadesTodos }, clientes, empresas] = await Promise.all(promises);

        ordenarInvernaderosVirtualesPorIndexFisicoYNombre(invernaderosVirtuales, invernaderosFisicos)
        const invernaderosInternos = invernaderosVirtuales.filter(el => !el.es_externo);
        const invernaderosExternos = invernaderosVirtuales.filter(el => el.es_externo);
        const invernadero = obtenerInvernaderoPorURL(invernaderosVirtuales);
        const productosBasesDelCiclo = obtenerProductosDelCiclo(invernaderosVirtuales, productosBases)

        if (!arregloInvsVirtuales[ciclo]) setArregloInvsVirtuales({ ...arregloInvsVirtuales, [ciclo]: invernaderosVirtuales });
        setGlobal({
            invernaderosFisicos, invernaderosVirtuales, invernaderosInternos, invernaderosExternos, invernadero, ciclo,
            productosYVariedades, productosUnicos, productosMezclados, productosBases, medleysGeneral, allVariedades,
            productosYVariedadesTodos, productosBasesDelCiclo, clientes,cargandoDatosGlobales: false, empresas
        })
    }
    const obtenerInvernaderoPorURL = (invernaderos = [{}]) => {
        let urlSections = window.location.href.split("/");
        let found = invernaderos.find(invernadero => urlSections.some(url => url === invernadero.id))
        
        const invernaderoPorDefecto = estamosEnProduccion ? 0 : 0;
        return found || invernaderos[invernaderoPorDefecto];
    }

    const showSpinner = () => cargandoDatosGlobales || cargandoPantalla;
    return (
        <React.Fragment>
            <Dialog className="spinner" closable={false} visible={showSpinner()} onHide={() => { }}>
                <ClipLoader sizeUnit="px" size={150} color="#003E66" loading={showSpinner()} />
            </Dialog>

            <ModalMostrarMensaje visible={!!modal} titulo={modal?.titulo || ""} msj={modal?.msj || ""} hide={() => setModal(null)} />
            <ModalPermiso show={mostrarModalPermiso} onHide={() => setMostrarModalPermiso(false)} />
            <ModalConfirmacion show={modalConfirmacion} onHide={() => setModalConfirmacion(null)} data={modalConfirmacion} />

            {currUser && <App />}
        </React.Fragment>)
}