import React, { useState, useEffect, useGlobal } from 'reactn';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { login } from "../../service/account";
import { Message } from 'primereact/message';
import Logo from '../../images/logo.svg';
import LogoDev from '../../images/logo-sisu.png';
import { estamosEnProduccion, rolProveedorExterno } from '../../constants';

const Login = (props) => {
    const [, setCargando] = useGlobal("cargandoDatosGlobales");
    const [usuario] = useGlobal("usuario");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    useEffect(() => {
        setCargando(false)
    }, [])
    useEffect(() => {
        setError("");
    }, [email, password])
    useEffect(() => {
        if (usuario?.rol === rolProveedorExterno) props.history.push("/calidad/reporte_proveedor_externo");
    }, [usuario])
    const entrar = async () => {
        setCargando(true)
        await login(email, password).catch(err => {
            setCargando(false)
            if (err.code) {
                if (err.code === "auth/invalid-email") setError("El formato del correo electrónico es incorrecto");
                else if (err.code === "auth/wrong-password") setError("El password es inválido o el usuario no tiene password");
                else setError(`Hubo un error en el login: ${err.code}`)
            }
            else setError(err.message);
        })
    }
    const handleChangeEmail = (e) => { setEmail(e.target.value) }
    const handleChangePassword = (e) => { setPassword(e.target.value) }
    const recuperar = () => { props.history.push("/recuperar_password") }

    return (
        <div className="p-grid p-align-center p-justify-center mh-100">
            <div className="p-col-12 p-md-6 p-col-align-center">
                <img alt="Logo" src={estamosEnProduccion ? Logo : LogoDev} className="login-logo" />
                <h1 className="">Iniciar sesión</h1>

                <div className="form-group">
                    <label >Correo electrónico</label>
                    <InputText value={email} onChange={handleChangeEmail} />
                </div>
                <div className="form-group">
                    <label >Contraseña</label>
                    <Password value={password} onChange={handleChangePassword} feedback={false} />
                </div>
                <div onClick={recuperar}>
                    ¿Olvidaste tu contraseña?
                </div>

                {error && <Message severity="error" text={error} />}
                <Button className="btn-block" label="Entrar" onClick={entrar} />
            </div>
        </div>);
}
export default Login;