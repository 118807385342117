import React, { useEffect } from 'react'
import { useGlobal } from 'reactn';
import CardsAgrupados from './CompraExternaFolder/CardsAgrupados';
import ContenedorPrincipal from '../common/ContenedorPrincipal';
import { Button } from 'primereact/button';
import RowBotonesTopThin from "../common/RowBotonesTopThin";
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';

const CompraExterna = (props) => {
    const [usuario] = useGlobal("usuario");
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [ weeks, week, handleWeekChange ] = useWeekSelector()

    const puedeVerCosecha = ["Administrador", "Administrador Empaque", "Administrador Embarques", "Auxiliar Empaque", "Finanzas"].includes(usuario.rol);
    
    useEffect(() => {
        setNombreModulo("Compra externa");
    }, [])

    const irReporte = () => { props.history.push("/cosecha/compra_externa/reporte") }
    const irReporteEmpaque = () => { props.history.push("/empaque/reporte_externo") }
    return (
        <React.Fragment>
            <RowBotonesTopThin >
                <Button label="Reporte general de empaque" onClick={irReporteEmpaque} />
                <Button label="Reporte de cosecha compra externa" onClick={irReporte} />
            </RowBotonesTopThin>

            <div className="p-col-12">
                <div className="p-grid filtros">
                    <WeekSelector week={week} weeks={weeks} handleWeekChange={handleWeekChange} />
                </div>
            </div>

            {puedeVerCosecha
                ? <CardsAgrupados />
                : <div>No tienes los permisos requeridos </div>}
        </React.Fragment>);
}
export default CompraExterna;