import * as firebase from "firebase/app";
import "firebase/firestore";
import { procesarColeccionFirestore, procesarDocumentoDatosFirestore } from "../../util/functions";

const db = () => firebase.firestore();
const dbCicloDoc = (ciclo) => db().collection("ciclos").doc(ciclo);
const dbInvoicesColl = (ciclo) => dbCicloDoc(ciclo).collection("invoices");

////////////////////////////INVOICES////////////////////////////
//GET
export const getInvoicesQUERY = async (ciclo) => {
    const response = await dbInvoicesColl(ciclo).get();
    //registrarInvocaciones(result.size)//registrarInvocaciones(result.size)
    return procesarColeccionFirestore(response);
}
export const getInvoiceQUERY = async (ciclo, invoiceId = "id") => {
    const response = await dbInvoicesColl(ciclo).doc(invoiceId).get();
    //registrarInvocaciones(result.size)//registrarInvocaciones(result.size)
    return procesarDocumentoDatosFirestore(response);
}

//DELETE
export const eliminarInvoiceQUERY = async (ciclo, invoiceId = "id") => {
    const response = await dbInvoicesColl(ciclo).doc(invoiceId).delete();
    console.log("eliminarInvoiceQUERY - DONE! " + invoiceId)
    return true;
}

//export const


////////////////////////////////////////////////////////////////