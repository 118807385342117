//Ordenamiento
export const ORDER_BY_PRESENTACION = "presentacion";
export const ORDER_BY_ID_PRESENTACION = "id_presentacion";

//Tipo de calidad
export const INICIAL = "inicial";
export const FINAL = "final";
export const PESOS = "pesos";

//Tipo empaque
export const MANUAL = "manual";
export const AUTO = "auto";


//Booleans
export const ES_FLOAT = true;
export const ES_INT = false;