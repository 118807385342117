import React, { useState, useEffect, useGlobal } from "reactn";
import { obtenerReporteColoracion, obtenerReporteColoracionDiario } from '../../service/ReporteCalidad';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { obtenerNombreFecha } from "../../service/fechas";
import { dosDecimales, getObjetoPorID } from '../../util/functions';
import { nombresDiasSemana, nombresDiasSemanaPlanos, rolProveedorExterno } from "../../constants";

const ReporteDefectos = (props) => {
    const [rows, setRows] = useState([]);
    const [invernadero] = useGlobal("invernadero");
    const [ciclo,] = useGlobal("ciclo");
    const [comentarios, setComentarios] = useState([]);
    const [, setMostrarModalPermiso] = useGlobal("mostrarModalPermiso");
    const [, setCargando] = useGlobal("cargando");
    const [usuario] = useGlobal("usuario");
    const [productosYVariedadesTodos] = useGlobal("productosYVariedadesTodos");

    useEffect(() => {
        if (ciclo && invernadero && props.semana) {
            obtenerDatosIniciales(props.subVariedad || false, props.proveedorExterno);
        }
    }, [invernadero, props.semana, props.subVariedad, props.proveedorExterno])
    const obtenerDatosIniciales = async (subVariedad, proveedorExterno) => {
        try {
            setCargando(true);
            const producto = getObjetoPorID(productosYVariedadesTodos, invernadero.producto_ref);
            let resultSemanalPromise = obtenerReporteColoracion(ciclo, invernadero.id, props.semana, producto.tipo_calidad_inicial, subVariedad, proveedorExterno)
            let resultDiarioPromise = obtenerReporteColoracionDiario(ciclo, invernadero.id, props.semana, producto.tipo_calidad_inicial, subVariedad, proveedorExterno)

            const [resultSemanal, resultDiario] = await Promise.all([resultSemanalPromise, resultDiarioPromise]);
            procesarReporte(resultDiario, resultSemanal);
        }
        catch (error) {
            console.log("ERROR obtenerDatosIniciales", error)
            if (error.code && error.code === "permission-denied") { setMostrarModalPermiso(true); }
        }
        setCargando(false);
    }

    const procesarReporte = (resultDiario, result) => {
        result.inicial.nombre = "INICIAL!!";
        result.final.nombre = "FINAL!!";
        let dias = nombresDiasSemanaPlanos;
        let rows = dias.map((diaSemana, index) => { return { nombre: nombresDiasSemana[index], inicial: resultDiario.inicial[diaSemana], final: resultDiario.final[diaSemana] }; });
        rows.push({
            nombre: "Semanal",
            inicial: result.inicial,
            final: result.final
        })

        //agregar nombres a defectos + columna total
        setComentarios(result["comentarios"]);
        setRows(rows);
    }
    const templateBody = (rowData, column) => {
        let inicial = rowData.inicial[column.field] ? rowData.inicial[column.field].cociente : 0;
        let final = rowData.final[column.field] ? rowData.final[column.field].cociente : 0;
        inicial = isNaN(inicial) ? 0 : inicial;
        final = isNaN(final) ? 0 : final;
        return (
            <div>
                <span className="calidad-data"><span className="calidad-value">{dosDecimales(inicial)}<span className="label-unidad">%</span> </span> <span className="label-unidad">INICIAL</span></span>
                {/* Revisar que esta bien que se quitó, por tareas revisadas del registro de calidad final del workshop grande */}
                {/*!props.reporteExterno && <span className="calidad-data"><span className="calidad-value">{dosDecimales(final)}<span className="label-unidad">%</span> </span> <span className="label-unidad">FINAL</span></span>*/}
            </div>);
    }
    const templateHeader = (rowData) => rowData.nombre;
    const puedeVerComentarios = () => { return usuario.rol !== rolProveedorExterno; }
    return (
        <React.Fragment>
            <div className="card p-0">
                <DataTable value={rows}>
                    <Column header="" field="nombre" body={templateHeader} />
                    <Column header="COLOR 1" field="color1" body={templateBody} />
                    <Column header="COLOR 2" field="color2" body={templateBody} />
                    <Column header="COLOR 3" field="color3" body={templateBody} />
                    <Column header="COLOR 4" field="color4" body={templateBody} />
                    <Column header="COLOR 5" field="color5" body={templateBody} />
                    <Column header="COLOR 6" field="color6" body={templateBody} />
                </DataTable>
            </div>
            
            {puedeVerComentarios() && <>
                <p className="section-title">Comentarios/hallazgos</p>
                <div className="card">
                    <div className="p-col-12 max-6">
                        {comentarios.map(comentario => { return <div> {obtenerNombreFecha(comentario.momento)} - {comentario.comentario} </div> })}
                    </div>
                </div>
            </>}
        </React.Fragment>)
}
export default ReporteDefectos;