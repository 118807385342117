export const filtrarManifiestosMismoEmbarque = (ordenes) => {
  const result = ordenes.reduce((acc, manifiestosOrden) => {
    const refs = {};
    manifiestosOrden.forEach((manifiesto) => {
      if (!refs[manifiesto.embarque_ref]) {
        refs[manifiesto.embarque_ref] = manifiesto;
        acc.push(manifiesto);
      }
    });
    return acc;
  }, []);
  return result;
};

export const filtrarEmbarqueIdsUnicosDeManifiestos1 = (manifiestos = []) => {
  let embarqueIds = new Set();
  manifiestos.forEach((manifiesto) => {
    embarqueIds.add(manifiesto.embarque_ref);
  });
  return embarqueIds;
};
export const filtrarEmbarqueIdsUnicosDeManifiestos2 = (manifiestos = []) => {
  let embarqueIds = manifiestos.map((el) => el.embarque_ref);
  return [...new Set(embarqueIds)];
};