import React, { useGlobal } from 'reactn';
import { Card } from 'primereact/card';
import { withRouter } from 'react-router-dom';
import { dosDecimales, separadoComas } from '../../../util/functions';

const CardCumplimiento = (props) => {
  const [, setActiveProducto] = useGlobal("activeProductoCumplimiento");
  const consultar = () => {
    setActiveProducto({ ...props.producto, label: props.producto.nombre });
    props.history.push("/cumplimiento/consultar");
  }
  const header = <span className="card-header">
    <div className={`card-id icon-general icon-id bor-${props.producto.nombre}`}></div>
    <p className="card-title">{props.producto.nombre}</p>
  </span>;

  return (
    <div className="p-col-12 p-md-6" onClick={consultar}>
      <Card header={header} className={`htg-card bor-gray card-${props.producto.nombre} `} >
        <div className="p-grid p-justify-center text-center">
          <div className="p-col">
            <div className="card-block">
              <p className="value">{separadoComas(dosDecimales(isNaN( props.producto.sobrante) ? 0 : props.producto.sobrante))} kg</p>
              <p className="label">SOBRANTE DE SEMANA PASADA</p>
            </div>
          </div>

          <div className="p-col">
            <div className="card-block">
              <p className="value">{separadoComas(dosDecimales(props.producto.finalFijo))} kg</p>
              <p className="label">SOBRANTE AL INICIO DE LA SEMANA</p>
            </div>
          </div>

          <div className="p-col">
            <div className="card-block">
              <p className="value">{separadoComas(dosDecimales(props.producto.final[5]))} kg</p>
              <p className="label">SOBRANTE EN TIEMPO REAL</p>
            </div>
          </div>
        </div>
      </Card>
    </div>)
}
export default withRouter(CardCumplimiento);