import React, { useState,useEffect } from 'reactn';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import {useGlobal} from "reactn";
import {obtenerCiclos} from "../../service/Ciclos";
import {obtenerCiclo} from "../../service/Ciclos";
import {obtenerListaDeSemanas} from "../../service/fechas";
import {
  guardarConfiguracionCultivo, registrarCambioConfiguracionCultivo,
  registrarConfiguracionCultivo} from "../../service/Cultivo";
import {obtenerInvernaderosFisicos} from '../../service/Invernaderos';
import ContenedorInput from '../common/ContenedorInput';
    
let inicial = {
    semana_plantacion:"",
    anio_plantacion:"",
    portainjerto:"",
    tipo_plastico:"",
    anio_plastico:"" ,
    densidad_planta:"",
    marca_sustrato:"",
    tipo_sustrato:" ",
    tamano_sustrato:""


}
function Configuracion(props){
    const [invernadero,setInvernadero] = useGlobal("invernadero");
    const [modal,setModal] = useGlobal("modal");
    const [invernaderosFisicos,setInvernaderosFisicos] = useState([]);
    const [editing,setEditing] = useState(false);
    const [ciclos,setCiclos] = useState([]);    
    const[ciclo,setCiclo] = useGlobal("ciclo");
    const [weeks,setWeeks] = useState([]);
    const [actualizadoCultivo, setActualizadoCultivo] = useGlobal("actualizadoCultivo");
    const [week,setWeek] = useState({nombre:"Cargando",time:Math.round(Date.now()/1000)})

    const atras = (params) => {
        props.history.goBack();
    }
    
    useEffect(() => {
      if(ciclo){
          obtenerCiclo(ciclo).then(resp=>{
              setWeeks(obtenerListaDeSemanas(resp.semana_inicio,Math.round(Date.now()/1000)))
          })
      }
  },[ciclo])
    const[nombreModulo, setNombreModulo] =  useGlobal("nombreModulo");
    function handleWeekChange(ev){

      setWeek(ev.target.value);
  }
    const [state,setState]=useState(inicial)
    const guardar = ()=>{
      
      if(!validar()){
        setModal({titulo:"Formulario no válido",msj:"Debes llenar todos los campos",
          hide:()=>{
            setModal(null);
            
          }
        }); 
        return;     
      }
      if(!editing){
        
        registrarConfiguracionCultivo(ciclo, invernadero.id, state)
          .then((resp)=>{
            setActualizadoCultivo(true);
            props.history.goBack();
            
          })
          .catch(err=>{
            console.log("ERROR AL GUARDAR");
            console.log(err);
            setInvernadero(null);
          });
      }
      else{
        guardarConfiguracionCultivo(invernadero.id,state).then(res=>{
          registrarCambioConfiguracionCultivo(invernadero.id, state);
          props.history.push("invernaderos");
        }).catch(err=>{
          console.log("ERROR AL EDITAR");
          console.log(err);
        })
      }
        
      
    }
    const  validar = ()=>{
      const requeridos =["semana_plantacion", "anio_plantacion", "portainjerto","anio_plastico","semana_plastico", "marca_sustrato","tipo_sustrato",]
      for(let x = 0; x< requeridos.length;x++){
        if(state[requeridos[x]] === "" || state[requeridos[x]] === null){
          return false;
        }
      }
      

      return true
    }
    const cancelar = ()=>{        
        setEditing(false);
        props.history.goBack();
    }

    
    useEffect(()=>{
      setNombreModulo("Cultivo");
        // 
        // 
        if(invernadero  ){
          // 
          // 
          //setState(invernadero,ciclo);
        }
        obtenerInvernaderosFisicos().then(resp=>{
            setInvernaderosFisicos(resp.map((el)=>({label:el.nombre,value:el.id})))
        })
        obtenerCiclos().then(resp=>{
            setCiclos(resp.map((el)=>({label:el.nombre,value:el.id})))
        })        
        
        
        
    },[])
    const handleInputChange =(ev)=>{        
        const stateCopy = Object.assign({},state);        
        let value = ev.target.value;                
        stateCopy[ev.target.name] = value;
        setState(stateCopy);
    } 
    const togglear = ()=>{
      
    }
    
    return  <div className="p-grid p-justify-center">
        <div className="p-col-12 title-container">    
          <span className="back-btn" onClick = {atras}></span>      
          {!editing?
            <React.Fragment>
              <span className={`icon-general icon-id bor-${invernadero.color} bg-${invernadero.color} ${invernadero.habilitado?"":"Deshabilitado"}`}>{invernadero.nombre}</span>
              <span className="titles-group">
                <h1 className="header-title">Configuración Inicial</h1>
                <p className="header-subtitle">Invernadero {invernadero.habilitado?"habilitado":"inhabilitado"}</p>
              </span>
            </React.Fragment>
          :
            <React.Fragment>
              <span className="icon-general inv-icon"></span>
              <span className="titles-group">
                <h1 className="header-title">Crear invernadero</h1>
              </span>
            </React.Fragment>
          }

        </div>
        <div className="p-col-10">
          <div className="p-grid p-fluid ">
          <div className="content-section implementation">
                    

                </div>            
            
            <div className="p-col-12">
              
              <div className="card">
                <div className="p-grid">
                
                <div className="form-group p-col-12 p-md-4 p-sm-6">
                      <label>Semana de plantación</label>
                      <InputText value = {state["semana_plantacion"]} name = "semana_plantacion"  
                        onChange = {handleInputChange}/>
                  </div>
                  <div className="form-group p-col-12 p-md-4 p-sm-6">
                      <label>Año de plantación</label>
                      <InputText value = {state["anio_plantacion"]} name = "anio_plantacion"  
                        onChange = {handleInputChange}/>
                  </div>
                  <div className="form-group p-col-12 p-md-4 p-sm-6">
                      <label>Portainjerto</label>
                      <InputText value = {state["portainjerto"]} name = "portainjerto"  
                        onChange = {handleInputChange}/>
                  </div>
                  <div className="form-group p-col-12 p-md-4 p-sm-6">
                      <label>Tipo de plástico</label>
                      <InputText value = {state["tipo_plastico"]} name = "tipo_plastico"  
                        onChange = {handleInputChange}/>
                  </div>
                  <div className="form-group p-col-12 p-md-4 p-sm-6">
                      <label>Año de plástico</label>
                      <InputText value = {state["anio_plastico"]} name = "anio_plastico"  
                        onChange = {handleInputChange}/>
                  </div>

                  <div className="form-group p-col-12 p-md-4 p-sm-6">
                      <label>Densidad de planta(# plantas/m²) </label>
                      <InputText value = {state["densidad_planta"]} name = "densidad_planta"  
                        onChange = {handleInputChange}/>
                  </div>
                  <div className="form-group p-col-12 p-md-4 p-sm-6">
                      <label>Tamaño de sustrato</label>
                      <InputText value = {state["tamano_sustrato"]} name = "tamano_sustrato"  
                        onChange = {handleInputChange}/>
                  </div>
                  <ContenedorInput col="12" md="4" sm="6" label="Marca de sustrato" >
                    <InputText value = {state["marca_sustrato"]} name = "marca_sustrato"  
                        onChange = {handleInputChange}/>
                  </ContenedorInput>
                  <ContenedorInput col="12" md="4" sm="6" label="Tipo de sustrato" >
                    <InputText value = {state["tipo_sustrato"]} name = "tipo_sustrato"  
                        onChange = {handleInputChange}/>
                  </ContenedorInput>
                </div>
              </div>
              
            </div>
            
            
           
          </div>
        </div>
     
        
        <div className="p-col-12">
         <div className="p-grid p-justify-end btn-row">
                    
           
                    <Button label="Cancelar" className="p-button-secondary"  onClick = {cancelar}/>
                    {editing?
                      state.habilitado?
                        <Button label="Deshabilitar" onClick={togglear}/>
                      :
                    
                      <Button label="Habilitar" onClick={togglear}/>
                      
                    :
                      null
                    }
                    <Button label="Guardar" onClick = {guardar}/>
              
          </div>
       </div>
    </div>
}

export default Configuracion;