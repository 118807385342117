import { Button } from 'primereact/button';
import React from 'reactn';

export const UnidadTextoConTooltip = (props) => {
    const { rowData, column, texto = "", tooltip = "", listaItems } = props;
    let tooltipTexto = tooltip;
    if (typeof listaItems === "object" && listaItems?.length > 0) { tooltipTexto = `<ul>${listaItems?.map(item => `<li>${item}</li>`)?.join("")}</ul>` }
    else if(typeof listaItems === "string") { tooltipTexto = listaItems}

    return (
        <div >
            <Button className="btn-line btn-help mr-1" type="button" label="?" tooltip={tooltipTexto} />
            <span>{rowData?.[column?.field]}<span className="label-unidad">{texto}</span></span>
        </div>)
}