import React from "reactn";
import { Dialog } from "primereact/dialog";
import { Button } from 'primereact/button';

const ModalMensaje = (props) => {
    const footer = () => {
        return <Button label="Aceptar" onClick={props.onHide} />
    }
    return (
        <Dialog header={props.header || ""} footer={footer()} visible={props.show} style={{ width: '50vw' }} onHide={props.onHide} >
            <p>{props.mensaje}</p>
        </Dialog>)
}
export default ModalMensaje;