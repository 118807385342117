import React,{useState, useEffect} from "react";
import {useGlobal} from 'reactn';
import Chart from "react-apexcharts";
import { obtenerPresentaciones } from "../../service/Presentaciones";
import { obtenerOrdenesCiclo } from '../../service/Ordenes';
import { MultiSelect } from 'primereact/multiselect';
import { obtenerClientes, obtenerClientesCiclo } from "../../service/Clientes";
import { dosDecimales, paletaColores, separadoComas } from "../../util/functions";
import { obtenerDiasDeSemana, obtenerListaDeSemanas } from "../../service/fechas";
import { factorLbKg, paletaClientes } from "../../constants";
import { obtenerReportePrecios } from "../../service/ReportePrecios";

const optionsOrig = {
  colors:paletaColores,
    plotOptions:{
      pie:{
        dataLabels:{
          minAngleToShowLabel:0,          
        }
      },      
    },
    chart: {
      width: 190,
      type: 'pie',
    },
    labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
    tooltip: {
      y: {
        
        formatter: function(val) {
          return separadoComas(dosDecimales(val))
        },
        title: {
          formatter: function (seriesName) {
            return seriesName
          }
        }
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 100
        },
        legend: {
          position: 'bottom'
        }
      }
    }],
    
  }
const series = [13,12,50]
const DistribucionPresentacionesCiclo = (props) => {
    const [invernaderos, setInvernaderos] = useState([]);
    const [productosConVariedades, setProductosConVariedades] = useState([]);
    const [filtroProducto, setFiltroProducto] = useState([]);
    const [filtroVariedad, setFiltroVariedad] = useState([]);
    const [filtroInvernadero, setFiltroInvernadero] = useState([]);
    const [presentaciones, setPresentaciones] = useState([]);
    const [ordenes, setOrdenes] = useState([]);
    const [, setOrdenesOrig] = useState([]);
    const [clientesOrig, setClientesOrig] = useState([]);
    const [filtroCliente, setFiltroCliente] = useState([]);
    const [reportePrecios, setReportePrecios] = useState([])
    const [data, setData] = useState([]);
    
    const [ciclo] = useGlobal("ciclo");
    const [, setCargando] = useGlobal("cargando");
    const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
    const [productosYVariedades] = useGlobal("productosYVariedades");
    const [cicloOb] = useGlobal("cicloObj");
    const [,setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [,setNombreModulo] = useGlobal("nombreModulo");
    const [cicloObj, setCicloObj] = useState();

    useEffect(()=>{
      setHabilitadoSelectorCiclo(true)
      setNombreModulo("Homepage Gráficas")
    },[])

    useEffect(() => {
        if(productosYVariedades ){
            obtenerDatosIniciales();
        }        
    }, [productosYVariedades])

    useEffect(()=>{
      setFiltroInvernadero(obtenerOpcionesInvernaderos());
    },[filtroProducto])

    useEffect(()=>{
      setFiltroVariedad(obtenerOpcionesVariedades());
    },[filtroInvernadero])

    useEffect(() => {
      if(filtroVariedad.length >0 && 
        filtroCliente.length >0 && 
        filtroProducto &&
        filtroVariedad.length >0  ){
          let procesado = procesar();
          setData(procesado);
        }
      else{
        setData([])
      }
    }, [filtroCliente, filtroProducto, filtroInvernadero, filtroVariedad])

    const obtenerDatosIniciales = async (params) => {
      try {
        setCargando(true);
        let invernaderos = invernaderosVirtuales;
        let productosConVariedades = productosYVariedades;
        let clientes = await obtenerClientes();
        let presentacionesPromise = obtenerPresentaciones();
        let ordenesPromise = obtenerOrdenesCiclo(ciclo);
        let reportePreciosPromise = obtenerReportePrecios(ciclo, clientes.map(el=>el.id));
        let clientesCicloPromise = obtenerClientesCiclo(ciclo)

        let [presentaciones,  ordenes, reportePrecios, clientesCiclo] = 
          await Promise.all([ presentacionesPromise, ordenesPromise, reportePreciosPromise, clientesCicloPromise]);

        setCicloObj(cicloOb);
        setInvernaderos(invernaderos);
        setFiltroInvernadero(invernaderos);
        setProductosConVariedades(productosConVariedades);
        setFiltroProducto([]);
        let filtrados = clientes.filter(cliente=>{
          let found = clientesCiclo.find(el=>el.cliente_ref === cliente.id);
          if(found){
            return true;
          }else{
            return false;
          }
        });       
        setClientesOrig(filtrados);      
        setReportePrecios(reportePrecios);
        let variedades = productosConVariedades.map(producto=>{
          return producto.variedades;
        }).flat()
        setFiltroVariedad(variedades);
        setPresentaciones(presentaciones);
        setOrdenesOrig(ordenes);
        setOrdenes(ordenes);
        //setFiltroCliente(clientes);
      } catch (error) {
        console.log("OCURRIO UN ERROR EN CARGA DE DATOS INICIALES");
        console.log(error);
      }finally{
        setCargando(false)
      }
        
    }
    const obtenerOpcionesProductos = (params) => {

      return productosConVariedades.map(el=>({nombre:el.nombre, id: el.id}))
    
    }

    const obtenerOpcionesInvernaderos = (params) => {
        let productosId = filtroProducto.map(el=>el.id)     
        return invernaderos.filter(inv=>productosId.includes( inv.producto_ref));
    }

    const obtenerOpcionesVariedades = (params) => {
      let variedades = productosConVariedades.map(producto=>{
        return producto.variedades;
      }).flat()
      
        let idsVariedadesInvernaderos = filtroInvernadero.map(el=>el.variedad_ref);        
        return variedades.filter(el=>idsVariedadesInvernaderos.includes(el.id));
      
    }
    
    const procesar = (params) => {
      // obtener ids de productos, invernaderos y variedades, en cascada
      let productos = filtroProducto;
      let productosIds = filtroProducto.map(el=>el.id);
      let clientesIds = filtroCliente.map(el=>el.id);
      let invernaderos = filtroInvernadero.slice();;
      let invernaderosIds = invernaderos.map(el=>el.id);

      let variedades = filtroVariedad.slice();
      let variedadesIds = variedades.map(el=>el.id);

      let listaSemanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
      let filtradas = presentaciones.filter(el=>productosIds.includes(el.producto_ref));
      filtradas = filtradas.filter(el=>clientesIds.includes(el.cliente_ref));
      let result = filtroCliente.map(cliente=>{
        let sumaCliente = 0;
                  let data = listaSemanas.map(semana=>{
                      let reporteSemana = reportePrecios.find(el=>el.semana === semana.time)
                      let dias = obtenerDiasDeSemana(semana.time);
                      let suma = 0;
                      ordenes.forEach(orden => {
                          if(orden.cliente_ref === cliente.id){
                              orden.items.forEach(item => {
                                  
                                  if(parseInt(orden.fecha_envio) >= dias[0] && parseInt(orden.fecha_envio)<= dias[5]){
                                      let presentacion = filtradas.find(el=>el.id === item.presentacion_ref);
                                      if(presentacion){
                                          let precioPresentacion = reporteSemana[presentacion.id]["valorReal"]
                                          let invernadero = invernaderos.find(el=>el.id === item.invernadero_ref);
                                          if(invernadero && invernaderosIds.includes(invernadero.id) && variedadesIds.includes(invernadero.variedad_ref)){
                                              let peso_caja = parseFloat(presentacion.peso_neto_caja);
                                              let cajas_orden = parseFloat(item.cajas_orden)
                                              suma += peso_caja * cajas_orden * factorLbKg  * precioPresentacion;
                                              sumaCliente+=peso_caja*cajas_orden * factorLbKg * precioPresentacion;
                                          }
                                      }
                                  }
                              })
                          }
                          
                      })
                      return suma;
                  })
                  return {
                      name:cliente.nombre,
                      shortname: cliente.nombre_comun,
                      data:data,
                      sumaPresentacion:sumaCliente
                  }  
      })
        //  result = filtradas.map(presentacion=>{

        //   let suma = 0;
        //   let pesoCaja = presentacion.peso_neto_caja;
        //   ordenes.forEach(orden=>{
        //     orden.items.forEach(currentItem => {
              
        //       if(currentItem.presentacion_ref === presentacion.id ){
        //         let invernadero = invernaderos.find(el=>el.id === currentItem.invernadero_ref);
        //         if(invernadero && invernaderosIds.includes(invernadero.id) && variedadesIds.includes(invernadero.variedad_ref)){
        //           let pesoItem = parseFloat(currentItem.cajas_orden) * pesoCaja;
        //           suma+=pesoItem;
        //         }              
        //       }
        //     })          
        //   });
        //   return suma;
        // });
        return result 
    }
  
    const obtenerSeries = (params) => {

        // obtener ids de productos, invernaderos y variedades, en cascada
        let productos = !filtroProducto? productosConVariedades:filtroProducto;
        let clientesIds = filtroCliente.map(el=>el.id);
        let invernaderos = filtroInvernadero.slice();;
        let invernaderosIds = invernaderos.map(el=>el.id);

        let variedades = filtroVariedad.slice();
        let variedadesIds = variedades.map(el=>el.id);
        let filtradas = presentaciones.filter(el=>el.producto_ref === filtroProducto.id);
        filtradas = filtradas.filter(el=>clientesIds.includes(el.cliente_ref));
      let result = filtradas.map(presentacion=>{

        let suma = 0;
        let pesoCaja = presentacion.peso_neto_caja;
        ordenes.forEach(orden=>{
          orden.items.forEach(currentItem => {
            
            if(currentItem.presentacion_ref === presentacion.id ){
              let invernadero = invernaderos.find(el=>el.id === currentItem.invernadero_ref);
              if(invernadero && invernaderosIds.includes(invernadero.id) && variedadesIds.includes(invernadero.variedad_ref)){
                let pesoItem = parseFloat(currentItem.cajas_orden) * pesoCaja;
                suma+=pesoItem;
              }              
            }
          })          
        });
        return suma;
      });
      return result.filter(el=>el>0);
    }
    const obtenerOptions = (params) => {
      let productos = !filtroProducto? productosConVariedades:filtroProducto
      let clientesIds = filtroCliente.map(el=>el.id);
      let invernaderos = filtroInvernadero.slice();;
      let invernaderosIds = invernaderos.map(el=>el.id);
      let variedades = filtroVariedad.slice();
      let variedadesIds = variedades.map(el=>el.id);
      let filtradas = presentaciones.filter(el=>el.producto_ref === filtroProducto.id);
      filtradas = filtradas.filter(el=>clientesIds.includes(el.cliente_ref));
      let copia = {...optionsOrig};
      copia["labels"] =[];
      copia["colors"] =[];
      
       filtradas.forEach(presentacion=>{
        let suma = 0;
        let pesoCaja = presentacion.peso_neto_caja;
        ordenes.forEach(orden=>{
          orden.items.forEach(currentItem => {
            if(currentItem.presentacion_ref === presentacion.id){
              let invernadero = invernaderos.find(el=>el.id === currentItem.invernadero_ref);
              if(invernadero && invernaderosIds.includes(invernadero.id) && variedadesIds.includes(invernadero.variedad_ref)){
                let pesoItem = parseFloat(currentItem.cajas_orden) * pesoCaja;
                suma+=pesoItem;
              }              
            }
          })          
        });
        
        // if(suma>0){
        //   copia["labels"].push(presentacion.presentacion);
        // }
      });
      if(data){
        data.forEach(serie => {
          if(serie["sumaPresentacion"]>0){
            copia["labels"].push(serie.name);
            copia["colors"].push(paletaClientes[serie.shortname])
          }
        })
      }
      return copia;
    }
   
    const atras = (params) => {
      props.history.goBack();
  }
    
    
    return <div className="p-grid chart-page">
            <div className="p-col  p-col-auto title-container">
                  <span className="back-btn" onClick={atras} ></span>
                  <span className={`icon-general chart-icon `}></span>
                  <span className="titles-group">
                    <h1 className="header-title">Distribución de clientes en el ciclo</h1>
                  </span>
            </div>

            <div className = "p-col-12">
              <div className="p-grid filtros">
              <div className="form-group p-col p-col-3">
                  <label htmlFor="filtroCliente">Clientes</label>
                  <MultiSelect options = {clientesOrig} value = {filtroCliente} onChange={e=>setFiltroCliente(e.value)} 
                    selectedItemsLabel={`${filtroCliente.length} clientes seleccionados`}
          dataKey="id" optionLabel="nombre" />
                </div>
                <div className="form-group p-col p-col-3">
                  <label htmlFor="filtroProducto">Productos a graficar</label>
                  <MultiSelect options = {obtenerOpcionesProductos()} value = {filtroProducto} onChange={e=>setFiltroProducto(e.value)}
                    selectedItemsLabel={`${filtroProducto.length} productos seleccionados`}
          dataKey="id" optionLabel="nombre" />
                </div>
                <div className="form-group p-col p-col-3">
                  <label htmlFor="filtroInvernadero">Invernaderos a graficar</label>
                  <MultiSelect options = {obtenerOpcionesInvernaderos()} value = {filtroInvernadero} onChange={e=>setFiltroInvernadero(e.value)} 
                    selectedItemsLabel={`${filtroInvernadero.length} invernaderos seleccionados`}
          dataKey="id" optionLabel="nombre" />
                </div>
                <div className="form-group p-col p-col-3">
                  <label htmlFor="filtroVariedad">Variedades a graficar</label>
                  <MultiSelect options = {obtenerOpcionesVariedades()} value = {filtroVariedad} onChange={e=>setFiltroVariedad(e.value)} 
                    selectedItemsLabel={`${filtroVariedad.length} variedades seleccionadas`}
          dataKey="id" optionLabel="nombre" />
                </div>
              </div>
            </div>
            <div className = "p-col-12 chart-container-filters">
              <Chart options = {obtenerOptions()} series = {data.filter(el=>el.sumaPresentacion>0).map(el=>el.sumaPresentacion)} type="donut" height="100%"/>
            </div>
    </div>
}

export default DistribucionPresentacionesCiclo ;