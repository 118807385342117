import React, { useState, useEffect } from 'react';
import { useGlobal } from 'reactn';
import { obtenerUsuarios } from '../../../service/Usuarios';
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { obtenerRoles } from '../../../service/Roles';
import { faSearch, faLock, faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContenedorPrincipal from '../../common/ContenedorPrincipal';
import ContenedorHeader from '../../common/ContenedorHeader';
import ContenedorBotonesTop from '../../common/ContenedorBotonesTop';
import RowBotonesTop from '../../common/RowBotonesTop';
import SeccionFiltros from '../../common/SeccionFiltros';
import ContenedorFiltro from '../../common/ContenedorFiltro';
import ContenedorTablaAdmin from '../../common/ContenedorTablaAdmin';
import { DeepClone, ordenarArreglaDeObjetosPorLlave, ordenarObjetosPorNombre } from 'util/functions';
import { ROL_AGENTE } from 'constants';
import { ROL_ADMINISTRADOR, ROL_AGENTE_ADUANAL, ROL_AGENTE_ADUANAL_MAYUSCULA } from '../../../constants';
import ContenedorFiltroBusqueda from 'components/common/ContenedorFiltroBusqueda';
const Usuarios = (props) => {
    const [, setUsuario] = useGlobal("usuarioEdit");
    const [usuarioLogueado] = useGlobal("usuario");
    const [globalFilter, setGlobalFilter] = useState("");
    const [, setCargando] = useGlobal("cargando");
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");

    //const [usuariosProcesados, setUsuariosProcesados] = useState([]); //Fix V1
    const [usuarios, setUsuarios] = useState([]);
    const [usuariosOrig, setUsuariosOrig] = useState([]);
    const [roles, setRoles] = useState([]);
    const [filtroRoles, setFiltroRoles] = useState([]);

    useEffect(() => {
        setNombreModulo("Usuarios");
        setHabilitadoSelectorCiclo(false);
        obtenerDatosIniciales();
    }, [])

    useEffect(() => {
        let usuariosFiltrados = usuariosOrig.filter(usr => checarIncluirUsuario(usr));
        setUsuarios(usuariosFiltrados);
    }, [filtroRoles])
    const checarIncluirUsuario = (usr) => {
        return filtroRoles.includes(usr.rol);
    }

    const obtenerDatosIniciales = async () => {
        setCargando(true);
        try {
            let [roles, usuarios] = await Promise.all([obtenerRoles(), obtenerUsuarios()]);
            roles = roles.filter(rol => !!rol.nombre);
            ordenarObjetosPorNombre(roles);
            ordenarArreglaDeObjetosPorLlave(usuarios, "displayName");

            setRoles(roles);
            setFiltroRoles(roles.map(rol => rol.nombre));//Porque se guarda el rol fijo en el base de datos, no con un ID de documento
            setUsuariosOrig(usuarios);
            setUsuarios(usuarios);
        } catch (error) {
            console.log("ERROR OBTENER USUARIOS: ", error);
        }
        setCargando(false);
    }

    const crearUsuario = () => { props.history.push("/admin/usuarios/crear"); }
    const irUsuario = (event) => {
        setUsuario(event.data);
        props.history.push(`/admin/usuarios/editar/${event.data.id}`);
    }

    /*
    useEffect(() => {
        const usuariosProcesados = procesarUsuarios(usuarios);
      //  console.log(usuariosProcesados)

        setUsuariosProcesados(usuariosProcesados);
    }, [usuarios])
    //Fix V1
     const procesarUsuarios = (usuarios) => {
        const usuariosProcesados = DeepClone(usuarios)
        return usuariosProcesados.map(usr => {
            const datos = { ...usr };
            if (datos.rol === ROL_AGENTE_ADUANAL) { datos.rol = ROL_AGENTE_ADUANAL_MAYUSCULA; }//Fix de nombre de rol de agentes aduanales V1
            return datos;
        })
    } */

    //Fix V2 /*  */
    const usuariosProcesadosTemplate = (rowData) => {
        if (rowData.rol === ROL_AGENTE_ADUANAL) { return ROL_AGENTE_ADUANAL_MAYUSCULA; }//Fix de nombre de rol de agentes aduanales V2
        else return rowData.rol;
    }
    const templateBloqueado = (rowData, column) => {
        if (rowData[column.field]) { return <React.Fragment><span className="locked-user"><FontAwesomeIcon icon={faLock} /> </span></React.Fragment>; }
        else return <React.Fragment><FontAwesomeIcon icon={faUnlockAlt} /> Desbloqueado</React.Fragment>;
    }
    const rolesItemsTemplate = (rolItem) => {
        //console.log("ROL ITEM: ", item)
        if (rolItem.nombre === ROL_AGENTE_ADUANAL) { return ROL_AGENTE_ADUANAL_MAYUSCULA; }
        else return rolItem.nombre;
    }
    return (usuarioLogueado?.rol === ROL_ADMINISTRADOR ?
        <ContenedorPrincipal>
            <ContenedorHeader iconos="user-icon" titulo="Usuarios del sistema" col="p-col p-col-auto" />
            <ContenedorBotonesTop>
                <RowBotonesTop>
                    <Button label="Crear nuevo usuario" onClick={crearUsuario} />
                </RowBotonesTop>
            </ContenedorBotonesTop>

            <SeccionFiltros>
                <ContenedorFiltroBusqueda>
                    <FontAwesomeIcon icon={faSearch} />
                    <InputText type="search" value={globalFilter} onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar" />
                </ContenedorFiltroBusqueda>
                <ContenedorFiltro label="roles">
                    <MultiSelect value={filtroRoles} options={roles} optionLabel="nombre" optionValue="nombre" onChange={e => setFiltroRoles(e.value)}
                        selectedItemsLabel={`${filtroRoles.length} roles seleccionados`} itemTemplate={rolesItemsTemplate} filter={true} />
                </ContenedorFiltro>
            </SeccionFiltros>

            <ContenedorTablaAdmin>
                <DataTable selectionMode="single" className="tabla-users" value={usuarios} onRowClick={irUsuario} scrollable={true} globalFilter={globalFilter}>
                    <Column field="displayName" header="Nombre de usuario" sortable={true} />
                    <Column field="rol" header="Rol" sortable={true} body={usuariosProcesadosTemplate} />
                    <Column field="bloqueado" header="Bloqueado" body={templateBloqueado} />
                </DataTable>
            </ContenedorTablaAdmin>
        </ContenedorPrincipal>
        : null);
}
export default Usuarios; //134