import { getGlobal } from "reactn";
import { obtenerOrdenesCiclo } from "service/Ordenes";
import { getManifiestosTodos, obtenerManifiestos, obtenerManifiestosCiclo } from "service/QueriesManifiestos";
import { obtenerCiclos } from "../service/Ciclos";
import { getActividadesMantenimiento, getCategoriasActividadesMantenimiento, getFirestoreID, guardarActividadesMantenimiento } from "../service/Mantenimiento";
import { obtenerRequisiciones, obtenerOrdenesCompra, obtenerOrdenesSistema, obtenerOrdenesMantenimiento, guardarRequisicionAciclico, obtenerDatosRequisicion, guardarDatosRequisicion } from "../service/Requisiciones";
import { chunkArray, DeepClone, DeepCloneVista, getObjetoPorID } from "./functions";
import readXlsxFile from 'read-excel-file'
import { actualizarEmbarque, guardarDatosEmbarque } from "service/embarques";
import { obtenerSnapshotPrevio, obtenerSnapshotsNominaTodos, obtenerSnapshots_nominaTodos } from "service/nomina";
import { obtenerActividades, guardarActividad } from "service/actividades";

/**
 * Para evitar imports accidentales, ninguna función de este archivo se exportará.
 * el desarrollador deberá exportarla temporlarmente para su uso cuando sea necesario.
 */
const firebase = require("firebase");
require("firebase/firestore");



/**
 * Elimina los rastros en la base de datos de las actividades indicadas, esta función debe ejecutarse cuando
 * se elimine una o más actividades de la base de datos.
 * 
 * La función debe parametrizarse de forma maual cada que se vaya a usar. Se deben indicar las referencias de las
 * actividades a eliminar su rastro, los ciclos de donde se eliminarán y los invernaderos donde se eliminarán.
*/
async function eliminarActividadesDeInvernaderos_enCiclo() {
    const actividades = [
        // '2A0qRzXFamEXK7rQhzst', // eliminada
        // '2l9eIghsVHNTUyhxTCKo', // eliminada
        // 'PvbYro0djt5ilXCnVpyi', // eliminada
        // 'Ru0Zner6kYj8BWdssSsq', // eliminada
    ]

    const ciclo_19_20 = 'MYpQ8I6zphvM6YETPTh8'
    const invernaderos_19_20 = [ // NOTA: modficar referencias de ser necesario, solo se están incluyendo invernaderos con registro de actividades
        '134lf9YRuLnY8mqawhxm',
        '4ucSLr7NzcKA7ot0UJ9Q',
        'GMLFRuIpJ6s3jyOehE1w',
        'Qp65SHS1lydWZCOw9t2Z',
        'w8VMXqJntWa16LlCIkdF'
    ]

    const ciclo_20_21 = 'nnLAN4n0AUQsauricsVD'
    const invernaderos_20_21 = [ // NOTA: modficar referencias de ser necesario, solo se están incluyendo invernaderos con registro de actividades
        '0KY0LKHAFE14cIt2HN6E',
        '60xcC1NKX1ZIarb08B5G',
        '9eFybqxcFz2aJqKAFKAQ',
        'AJBWNV2wxXC7xO2fZCQW',
        'H2MLW2U2snAGg51SUkSa',
        'J7AhKSRgIC7zZMnWnBbs',
        'UVeVcGytwSsx18FIjMkq',
        'YiCY7h0oUF72IzmFJbPq',
        'j4Zc26s8j7cVYE3OL2wm',
        'nlHhGjtqC6JiYfTg0gsK'
    ]

    let numeroRevision = 1

    /* for (let actividadIndex=0; actividadIndex<actividades.length; actividadIndex++) { // 20 revisiones
        for (let invernaderoIndex=0; invernaderoIndex<invernaderos_19_20.length; invernaderoIndex++) {
            
            await eliminarActividad_porCiclo_porInvernadero(ciclo_19_20, invernaderos_19_20[invernaderoIndex], actividades[actividadIndex])
            numeroRevision++
        }
    } */

    for (let actividadIndex = 0; actividadIndex < actividades.length; actividadIndex++) { // 40 revisiones
        for (let invernaderoIndex = 0; invernaderoIndex < invernaderos_20_21.length; invernaderoIndex++) {

            await eliminarActividad_porCiclo_porInvernadero(ciclo_20_21, invernaderos_20_21[invernaderoIndex], actividades[actividadIndex])
            numeroRevision++
        }
    }


}

async function eliminarActividad_porCiclo_porInvernadero(cicloRef, invernaderoRef, actividadRef) {
    const firestore = firebase.firestore()
    let datosPorActualizar = { planeacionActividades: [], registroActividades: [] }

    let registroActividades = []
    await firestore.collection('ciclos')
        .doc(cicloRef)
        .collection('invernaderos_virtuales')
        .doc(invernaderoRef)
        .collection('registro_actividades')
        .get()
        .then((snapshot) => {
            snapshot.forEach(doc => {
                registroActividades.push({ id: doc.id, ...doc.data() })
            });
        }).catch((error) => {
            console.log(`... ERROR! registroActividades: ${error}`)
        });

    if (registroActividades.length === 0) return
    for (let i = 0; i < registroActividades.length; i++) {
        await firestore.collection('ciclos')
            .doc(cicloRef)
            .collection('invernaderos_virtuales')
            .doc(invernaderoRef)
            .collection('registro_actividades')
            .doc(registroActividades[i].id)
            .collection('actividades_realizadas')
            .get()
            .then((snapshot) => {
                snapshot.forEach(doc => {
                    const actividadesRealizadas = { id: doc.id, ...doc.data() }
                    // 
                    // 
                    if (registroActividades[i].actividades_planeadas.includes(actividadRef)) {
                        datosPorActualizar.planeacionActividades.push({
                            docPath: `/ciclos/${cicloRef}/invernaderos_virtuales/${invernaderoRef}/registro_actividades/${registroActividades[i].id}`,
                            datosAntiguos: { actividades_planeadas: registroActividades[i].actividades_planeadas, dia: registroActividades[i].dia },
                            datosNuevos: { actividades_planeadas: registroActividades[i].actividades_planeadas.filter(e => e !== actividadRef), dia: registroActividades[i].dia }
                        });
                    }
                    // 
                    // 
                    if (actividadRef in actividadesRealizadas.actividades) {
                        const actividadesNuevas = { ...actividadesRealizadas.actividades }
                        delete actividadesNuevas[actividadRef]
                        datosPorActualizar.registroActividades.push({
                            docPath: `/ciclos/${cicloRef}/invernaderos_virtuales/${invernaderoRef}/registro_actividades/${registroActividades[i].id}/actividades_realizadas/${actividadesRealizadas.id}`,
                            datosAntiguos: { empleado_ref: actividadesRealizadas.empleado_ref, actividades: actividadesRealizadas.actividades },
                            datosNuevos: { empleado_ref: actividadesRealizadas.empleado_ref, actividades: actividadesNuevas }
                        });
                    }
                });
            }).catch((error) => {
                console.log(`... ERROR! actividadesRealizadas: ${error}`)
            });
    }



    // return

    if (datosPorActualizar.planeacionActividades.length !== 0) { // actualizar planeación de actividades
        for (let i = 0; i < datosPorActualizar.planeacionActividades.length; i++) {
            await firestore.doc(datosPorActualizar.planeacionActividades[i].docPath).update(datosPorActualizar.planeacionActividades[i].datosNuevos)
                .catch((e) => console.log(`... planeación[${i}] error! ${e}`))
                ;
        }
    }

    if (datosPorActualizar.registroActividades.length !== 0) {
        for (let i = 0; i < datosPorActualizar.registroActividades.length; i++) {
            await firestore.doc(datosPorActualizar.registroActividades[i].docPath).update(datosPorActualizar.registroActividades[i].datosNuevos)
                .catch((e) => console.log(`... registro[${i}] error! ${e}`))
                ;
        }
    }
}




/**
 * Toma todos los embarques existentes en la colección /embarques y los duplica en una colección
 * /ciclos/{cicloRef}/embarques.
 * Para determinar a que ciclo corresponderán los embarques, si utiliza el atributo
 * fecha_registro_unix_timestamp del embarque a copiar.
*/
async function copiarEmbarquesAciclicos_enCiclosQueCorresponderian() {
    let error = false
    const firestore = firebase.firestore()

    let embarques = []
    await firestore.collection('embarques')
        .get()
        .then((snapshot) => {
            snapshot.forEach(document => {
                embarques.push({ ...document.data() })
            });
        }).catch((error) => {
            console.log(`... error al obtener embarques: ${JSON.stringify(error)}`)
            error = true
        });
    if (error === true) return

    let ciclos = []
    await firestore.collection('ciclos')
        .get()
        .then((snapshot) => {
            snapshot.forEach(document => {
                ciclos.push({ id: document.id, ...document.data(), embarques: [] })
            });
        }).catch((error) => {
            console.log(`... error al obtener ciclos: ${JSON.stringify(error)}`)
            error = true
        });
    if (error === true) return

    // asignar embarques a ciclos
    for (let cicloIndex = 0; cicloIndex < ciclos.length; cicloIndex++) {
        const embarquesDeCiclo = embarques.filter((embarque) => {
            const fechaRegistro = embarque.fecha_registro_unix_timestamp
            const inicioCiclo = ciclos[cicloIndex].semana_inicio
            const finCiclo = ciclos[cicloIndex].semana_cierre
            return (fechaRegistro >= inicioCiclo) && (fechaRegistro <= finCiclo)
        });
        ciclos[cicloIndex].embarques = Array.from(embarquesDeCiclo)
    }

    // verfificar asignación
    const batch = firestore.batch()
    for (let cicloIndex = 0; cicloIndex < ciclos.length; cicloIndex++) {
        for (let embarqueIndex = 0; embarqueIndex < ciclos[cicloIndex].embarques.length; embarqueIndex++) {
            let nuevoEmbarqueDoc = firestore.collection('ciclos')
                .doc(ciclos[cicloIndex].id)
                .collection('embarques')
                .doc();
            batch.set(nuevoEmbarqueDoc, { ...ciclos[cicloIndex].embarques[embarqueIndex] })
        }
    }

    // aplicar cambios
    await batch.commit().then(() => {

    }).catch((error) => {
        console.log(`... error al transferir embarques: ${JSON.stringify(error)}`)
        error = true
    });


}



/**
 * Convierte los documentos de embarques actuales a embarques con más
 * de un posible punto de carga.
 * Su punto de carga actual se conservará, pero será incluído en la nueva
 * estructura.
 */
async function modificarEstructura_deEmbarques_paraMultiples_puntosDeCarga() {
    let error = false
    const firestore = firebase.firestore()

    let ciclos = []
    await firestore.collection('ciclos')
        .get()
        .then((snapshot) => {
            snapshot.forEach(document => {
                ciclos.push({ id: document.id, ...document.data(), embarques: [] })
            });
        }).catch((error) => {
            console.log(`... error al obtener ciclos: ${JSON.stringify(error)}`)
            error = true
        });
    if (error === true) return


    const batch = firestore.batch()
    for (let cicloIndex = 0; cicloIndex < ciclos.length; cicloIndex++) {
        let cicloId = ciclos[cicloIndex].id


        await firestore.collection('ciclos')
            .doc(cicloId)
            .collection('embarques')
            .get()
            .then((snapshot) => {
                snapshot.forEach(document => {
                    batch.set(document.ref, { puntos_de_carga: [document.data().punto_carga_ref] }, { merge: true })
                });
            }).catch((error) => {
                console.log(`... error al obtener embarques de ciclo: ${JSON.stringify(error)}`)
                error = true
            });
        if (error === true) break
    }
    if (error === true) return

    // aplicar cambios
    await batch.commit().then(() => {

    }).catch((error) => {
        console.log(`... error al modificar embarques: ${JSON.stringify(error)}`)
        error = true
    });

    if (error === true) return

}



/**
 * Convierte los documentos de embarques actuales a embarques con manifiestos
 * creados desde el módulo de órdenes de venta (ahora son una coleción en lugar
 * de una referencia).
 * Sus manifiestos actuales se conservarán, pero serán incluídos en la nueva
 * estructura.
 */
async function modificarEstructura_deEmbarques_paraManifiestosOrdenesDeVenta() {
    let error = false
    const firestore = firebase.firestore()

    // obtener ciclos
    let ciclos = []
    await firestore.collection('ciclos')
        .get()
        .then((snapshot) => {
            snapshot.forEach(document => {
                ciclos.push({ id: document.id, ...document.data(), embarques: [] })
            });
        }).catch((error) => {
            console.log(`... error al obtener ciclos: ${JSON.stringify(error)}`)
            error = true
        });
    if (error === true) return


    // extraer referencias de embarques con manifiestos nuevos
    let embarquesConManifiestosRefs = []
    for (let cicloIndex = 0; cicloIndex < ciclos.length; cicloIndex++) {
        let cicloId = ciclos[cicloIndex].id


        const manifiestosCiclo = []
        await firestore.collection('ciclos')
            .doc(cicloId)
            .collection('manifiestos')
            .get()
            .then((snapshot) => {
                snapshot.forEach(document => {
                    // manifiestosCiclo.push({ id: document.id, ...document.data() });
                    if (document.data().embarque_ref) embarquesConManifiestosRefs.push(document.data().embarque_ref);
                });
                // ciclos[cicloIndex]['manifiestos_existentes'] = manifiestosCiclo
            }).catch((error) => {
                console.log(`... error al obtener manifiestos de ciclo: ${JSON.stringify(error)}`)
                error = true
            });
        if (error === true) break
    }
    if (error === true) return


    // generar nuevos manifiestos de embarques en sus ciclos correspondientes
    for (let cicloIndex = 0; cicloIndex < ciclos.length; cicloIndex++) {
        let cicloId = ciclos[cicloIndex].id


        let embarquesCiclo = []
        await firestore.collection('ciclos')
            .doc(cicloId)
            .collection('embarques')
            .get()
            .then((snapshot) => {
                snapshot.forEach(document => {
                    embarquesCiclo.push({ id: document.id, ...document.data() })
                });
            }).catch((error) => {
                console.log(`... error al obtener embarques de ciclo: ${JSON.stringify(error)}`)
                error = true
            });
        if (error === true) break
        ciclos[cicloIndex]['manifiestos'] = []

        for (let embarqueIndex = 0; embarqueIndex < embarquesCiclo.length; embarqueIndex++) {
            let embarque = embarquesCiclo[embarqueIndex]
            const embarqueConManifiesto = embarquesConManifiestosRefs.findIndex(embarqueRef => embarqueRef === embarque.id) !== -1
            if (!embarqueConManifiesto) {
                if (!embarque.productores_map) continue
                for (const [key, value] of Object.entries(embarque.productores_map)) {
                    ciclos[cicloIndex]['manifiestos'].push({
                        'embarque_ref': embarque.id,
                        'productor_ref': key,
                        'manifiesto': value.manifiesto,
                        'numeroEmbarque': value.numero_emnarque,
                        'items': []
                    });
                }
            } else {

            }
        }
    }
    if (error === true) return


    // generar transacciones de nuevos manifiestos
    const batch = firestore.batch()
    for (let cicloIndex = 0; cicloIndex < ciclos.length; cicloIndex++) {
        const cicloRef = ciclos[cicloIndex].id

        ciclos[cicloIndex].manifiestos.forEach((nuevoManifiesto) => {
            let nuevoManifiestoDoc = firestore.collection('ciclos')
                .doc(cicloRef)
                .collection('manifiestos')
                .doc();
            batch.set(nuevoManifiestoDoc, { ...nuevoManifiesto });
        });
    }

    // aplicar cambios
    await batch.commit().then(() => {

    }).catch((error) => {
        console.log(`... error al transferir embarques: ${JSON.stringify(error)}`)
        error = true
    });


}



/**
 * Hacer los cambios necesarios para manejar el cambio de productos y variedades para fase4
 * Va afectar: actividades, invernaderos virtuales, presentaciones, grupos de presentaciones
 */
const subir_cambios_firestore_productos_y_variedades_fase4 = async () => {
    //productos y variedades
/*     let response = await firebase.firestore().collection("productos").get();
    const productos = response.docs.map(el => ({ id: el.id, ...el.data() }));
    for (const index in productos) {
        const producto = productos[index];
        const { id, ...alldata } = producto
        response = await firebase.firestore().collection("productos").doc(id).collection("variedades").get();
        const variedades = response.docs.map(el => ({ id: el.id, ...el.data() }));

        for (const indexVar in variedades) {
            const variedad = variedades[indexVar]
            await firebase.firestore().collection("productos_y_variedades").doc(id).collection("variedades").doc(variedad.id).set(variedad)
        }
    } */

    ////Invernaderos virtuales
    let response = await firebase.firestore().collection("ciclos").get();
    const allCiclos = response.docs.map(el => ({ id: el.id, ...el.data() }));
    for (const index in allCiclos) {
        const ciclo = allCiclos[index];

        response = await firebase.firestore().collection("ciclos").doc(ciclo.id).collection("invernaderos_virtuales").get();
        const invs = response.docs.map(el => ({ id: el.id, ...el.data() }));
        for (const indexInv in invs) {
            const invernaderoVirtual = invs[indexInv];

            //Medleys viejo
            if (invernaderoVirtual.producto_ref === "Tx4pyK0HMrYYE43UtxKx" && invernaderoVirtual.variedad_ref === "7K7TVRCbOlJy1ILuu1oI") {
                const datos = { MEDLEYS_VIEJO: true };
                await firebase.firestore().collection("ciclos").doc(ciclo.id).collection("invernaderos_virtuales").doc(invernaderoVirtual.id).update(datos);
            }//Medleys normal
            else if (invernaderoVirtual.producto_ref === "Tx4pyK0HMrYYE43UtxKx") {
                const porcentajesMezclaVariedades = invernaderoVirtual?.porcentajesSubVariedades ? [...invernaderoVirtual.porcentajesSubVariedades] : [];
                let producto_ref = "";
                if (invernaderoVirtual.id === "gbVEPTMjNEHfxUGsQzGk") { producto_ref = "Xj2EgllRYB5rT5k1Tn2X"; }
                else if (invernaderoVirtual.id === "8LYNnJRCp1scoHZ2f9mK" || invernaderoVirtual.id === "hIoIaF31KVeOC89TRhjU") { producto_ref = "nrJOW44aV8mybcIUxDBF"; }
                else if (invernaderoVirtual.id === "iSBixN0laEcnEJkERxSQ" || invernaderoVirtual.id === "BhTq75MBUEYbbBGdJTEk") { producto_ref = "c8vPGaMrucut9bCR0wQ1"; }
                else if (invernaderoVirtual.id === "fgjMXHJjxyFlFAVGbvBa") { producto_ref = "ZuayO47WfShM60PNkkMl"; }
                else if (invernaderoVirtual.id === "bHgVG7YVdu2CwNPM9v7n") { producto_ref = "RBIhFeQKxlV6eLdy0fTe"; }
                else if (invernaderoVirtual.id === "lQ3DalDz4TNZy2TStQHJ") { producto_ref = "mTYBcvyyTvqhc1NRcSit"; }

                const datos = { mezcla: true, producto_ref, variedad_ref: "", porcentajesSubVariedades: firebase.firestore.FieldValue.delete(), porcentajesMezclaVariedades };
                await firebase.firestore().collection("ciclos").doc(ciclo.id).collection("invernaderos_virtuales").doc(invernaderoVirtual.id).update(datos);
            }//cambiar campo de porcentajesSubVariedades
            else if (invernaderoVirtual.porcentajesSubVariedades) {
                const datos = { porcentajesSubVariedades: firebase.firestore.FieldValue.delete(), porcentajesMezclaVariedades: [] };
                await firebase.firestore().collection("ciclos").doc(ciclo.id).collection("invernaderos_virtuales").doc(invernaderoVirtual.id).update(datos);
            }
        }

    }

}

const transferir_requisiciones_compras_abiertos_al_nuevo_ciclo = () => transferir_requisiciones_abiertos_al_ciclo_2022("Compras", "compra", "compras");
const transferir_requisiciones_sistemas_abiertos_al_nuevo_ciclo = () => transferir_requisiciones_abiertos_al_ciclo_2022("Sistemas", "sistema", "sistemas");
const transferir_requisiciones_mantenimiento_abiertos_al_nuevo_ciclo = () => transferir_requisiciones_abiertos_al_ciclo_2022("Mantenimiento", "mantenimiento", "mantenimiento");

const transferir_requisiciones_abiertos_al_ciclo_2022 = async (tipoAdmin, nombreModulo, nombreColFiltros) => {
    const { usuario } = getGlobal();
    const ciclo2021 = "lK3HBhcbVw9u49jlYA68", ciclo2022 = "KpC1ZR4KRhDR2i6ERxUR"
    try {
        //Obtener coleccion de estatuses
        const estatuses = await obtenerDatosRequisicion(nombreColFiltros, "estatuses")
        console.log(estatuses);

        //Obtener requisiciones de firestore del ciclo 2021
        const requisiciones2021 = await obtenerRequisiciones(ciclo2021, usuario, tipoAdmin, nombreModulo);
        console.log(requisiciones2021);

        //filtrar requisiciones abiertos
        const requisiciones2021Abiertos = requisiciones2021.filter(requisicion => getObjetoPorID(estatuses, requisicion.estatus).inicial)
        console.log(requisiciones2021Abiertos.length)

        //Guardar ciclos de 2021 en el ciclo 2022
        const promises = requisiciones2021Abiertos.map(requisicion => {
            const { id, ...data } = requisicion;
            data.transferido2022 = Date.now();

            return guardarDatosRequisicion(ciclo2022, `requisiciones_${nombreModulo}`, id, data);
        })
        await Promise.all(promises);
        console.log("LISTO ::: ", promises.length)
        window.alert("LISTO: " + promises.length + " requisiciones transferidos");

    } catch (error) {
        console.log("ERR: ", error)
    }

}


const convertir_requisiciones_aciclicas = async (cicloId) => {
    const { usuario } = getGlobal();

    const comprasPromises = []
    const sistemasPromises = []
    const mantenimientoPromises = []

    let requisicionesDeCompras = await obtenerOrdenesCompra(cicloId, usuario, "Compras")
    let requisicionesDeSistemas = await obtenerOrdenesSistema(cicloId, usuario, "Sistemas");
    let requisicionesDeMantenimiento = await obtenerOrdenesMantenimiento(cicloId, usuario, "Mantenimiento");

    const compras = requisicionesDeCompras.map(el => procesarDatosRequisiciones(el, cicloId, "compra"))
    const sistemas = requisicionesDeSistemas.map(el => procesarDatosRequisiciones(el, cicloId, "sistema")); /**/
    const manteniento = requisicionesDeMantenimiento.map(el => procesarDatosRequisiciones(el, cicloId, "mantenimiento"));

    comprasPromises.push(...compras);
    sistemasPromises.push(...sistemas);
    mantenimientoPromises.push(...manteniento);

    await Promise.all([...comprasPromises, ...sistemasPromises, ...mantenimientoPromises])
    console.log("Finalizado")
}

const procesarDatosRequisiciones = async (requisicion = {}, ciclo, nombreModulo) => {
    const { id, ...datosRequsicion } = requisicion
    const datosAGaurdar = {
        ...datosRequsicion,
        transferido: true,
        fechaDeTransferencia: Date.now(),
        requisicionOriginalID: id,
        ciclo
    }
    const newDocID = getFirestoreID();
    await guardarRequisicionAciclico(nombreModulo, newDocID, datosAGaurdar);
}

const excel_borrarDatosColecciones = async () => {
    const actividades = await getActividadesMantenimiento()
    for (const index in actividades) {
        const act = actividades[index]
        if (act.id && !["emergencia", "other_requisicion"].includes(act.id)) {
            firebase.firestore().collection("actividades_mantenimiento").doc(act.id).delete()
        }
    }

    const categorias = await getCategoriasActividadesMantenimiento()
    for (const index in categorias) {
        const cat = categorias[index]
        if (cat.id && !cat.sinMostrar && !["category_emergency"].includes(cat.id)) {
            firebase.firestore().collection("categorias_actividades_mantenimiento").doc(cat.id).delete()
        }
    }
}

//Componente necesario para subir actividades en un excel en la pantalla de administración de mantenimiento
//<input type="file" onChange={ev => { excel_subirDatosColecciones(ev.target.files[0]) }}></input>
const excel_subirDatosColecciones = async (excelFile) => {
    let cats = [], subcats = [], actividades = [], promises = [];

    console.log("READING")
    const rows = await readXlsxFile(excelFile)
    console.log("DONE")
    rows.forEach((row, index) => {
        if (index > 1) {
            const [periodicidad, indice, categoria, subcategoria, actividad] = row;
            const numerosIndice = indice.split(".")

            if (!cats.some(el => el.nombre === categoria)) {
                console.log(`CATEGORIA: ${categoria}`)
                cats.push({ id: getFirestoreID(), nombre: categoria, tipo: "categoria", classname: getCategoriaClassname(categoria), indice: `${numerosIndice[0]}` })
                let { id, ...allData } = cats[cats.length - 1]
                promises.push(guardarActividadesMantenimiento(id, allData))
            }
            if (!subcats.some(el => el.nombre === subcategoria)) {
                console.log(`SUBCATEGORIA: ${subcategoria}`)
                const indice = numerosIndice.length > 3 ? `${numerosIndice[0]}.${numerosIndice[1]}.${numerosIndice[2]}` : `${numerosIndice[0]}.${numerosIndice[1]}`

                subcats.push({ id: getFirestoreID(), nombre: subcategoria, tipo: "subcategoria", categoria_ref: cats[cats.length - 1].id, indice })
                let { id, ...allData } = subcats[subcats.length - 1]
                promises.push(guardarActividadesMantenimiento(id, allData))
            }

            actividades.push({
                id: getFirestoreID(), nombre: actividad,
                periodicidad: (!periodicidad || periodicidad === "DISPONIBILIDAD") ? "" : periodicidad,
                indice, subcategoria_ref: subcats[subcats.length - 1].id,
                categoria_ref: cats[cats.length - 1].id, tiempo_estimado: 1
            })
            let { id, ...allData } = actividades[actividades.length - 1]
            promises.push(guardarActividadesMantenimiento(id, allData));
        }
    })
    Promise.all(promises)
}
const getCategoriaClassname = (nombre) => {
    switch (nombre.trim()) {
        case "PRODUCCIÓN": return "produccion"
        case "EMPAQUE / CALIDAD / EMBARQUES": return "empaque"
        case "MAQUINARIA, ELECTRICIDAD Y EQUIPOS AUXILIARES": return "maquinas"
        case "INOCUIDAD": return "inocuidad"
        case "OFICINAS/RH/IT/ALMACEN": return "oficinas"
        case "SEGURIDAD INDUSTRIAL Y PATRIMONIAL": return "seguridad"
        default: return "";
    }
}
const getCategoriaClassname2 = (nombre) => {
    const dictionary = {
        produccion: "PRODUCCIÓN",
        empaque: "EMPAQUE / CALIDAD / EMBARQUES",
        inocuidad: "INOCUIDAD",
        oficinas: "OFICINAS/RH/IT/ALMACEN",
        seguridad: "SEGURIDAD INDUSTRIAL Y PATRIMONIAL",
        maquinas: "MAQUINARIA, ELECTRICIDAD Y EQUIPOS AUXILIARES",
    }
    let classname = "";
    for (const key in dictionary) {
        if (nombre.trim() === dictionary[key]) classname = key;
    }
    return classname;
}


const checarCambiosPasadosVentas = async () => {
    const { ciclo } = getGlobal();

    const [ordenesVenta, manifiestosTodos] = await Promise.all([obtenerOrdenesCiclo(ciclo), obtenerManifiestosCiclo(ciclo)]);
    const problems = [], problemsOrdenesVentaConItemsDiferentes = [], problemsOrdenesDeVentaConItemsDiferentes = [];

    const manifiestosSinOrdenes = [];
    let embarquesSinOrdenes = new Set()

    for (const indexVenta in ordenesVenta) {

        //Información de orden de venta
        const ordenVenta = ordenesVenta[indexVenta];
        const numeroCamiones = obtenerCamionesDeOrdenDeVenta(ordenVenta);
        const manifiestosDeVenta = manifiestosTodos?.filter(man => man.orden_ref === ordenVenta.id) || [];
        const itemsManifiestos = manifiestosDeVenta.map(man => man?.items || [])?.flat() || [];

        if (ordenVenta.no_orden === "adam-lunes-sep-5") {
            console.log(`"NUMERO CAMIONES orden ${ordenVenta.no_orden} - ${numeroCamiones}, manifiestos: `, manifiestosDeVenta)
        }

        const problemsItemsDeVenta = [];
        const problemsItemsDeManifiestos = [];
        const problemsManifiestos = [];


        //1. Ordenes de venta con items que existen en la orden, pero no en los manifiestos
        //2. Cuales items son
        if (manifiestosDeVenta.length > 0) {
            ordenVenta.items.forEach(itemOrden => {
                const samePresentacion = itemsManifiestos.some(itemMan => itemMan.presentacion_ref === itemOrden.presentacion_ref);
                const sameInvernadero = itemsManifiestos.some(itemMan => itemMan.invernadero_ref === itemOrden.invernadero_ref);

                if (!(samePresentacion && sameInvernadero)) {
                    problemsOrdenesVentaConItemsDiferentes.push(ordenVenta);
                    problemsItemsDeVenta.push(itemOrden);
                }
            })
        }

        //3. Manifiestos con items que no existen en la tabla de items de ordenes de venta
        //4. Cuales items son
        manifiestosDeVenta.forEach(manifiesto => {
            manifiesto.items.forEach(itemMan => {

                const samePresentacion = ordenVenta.items.some(itemOrden => itemOrden.presentacion_ref === itemMan.presentacion_ref);
                const sameInvernadero = ordenVenta.items.some(itemOrden => itemOrden.invernadero_ref === itemMan.invernadero_ref);

                if (!(samePresentacion && sameInvernadero)) {
                    problemsOrdenesDeVentaConItemsDiferentes.push(ordenVenta)
                    problemsItemsDeManifiestos.push(itemMan);
                    problemsManifiestos.push(manifiesto.id)
                }
            })
        })

        //5. Manifiestos que existen para camiones que no existen
        const manifiestosSinOrden = manifiestosTodos?.filter(man => man.orden_ref === ordenVenta.id && man.orden_camion > numeroCamiones) || [];
        manifiestosSinOrdenes.push(...manifiestosSinOrden);

        //6. Embarques que existen para camiones que no existen
        manifiestosSinOrdenes.forEach(el => {
            embarquesSinOrdenes.add(el.embarque_ref)
        });


        if (problemsItemsDeVenta.length > 0 || problemsItemsDeManifiestos.length > 0) {
            problems.push({ ordenVenta, problemsItemsDeVenta, problemsManifiestos, problemsItemsDeManifiestos })
        }
    }
    embarquesSinOrdenes = [...embarquesSinOrdenes];

    const problemsManifiestosNum = problems.reduce((acc, probs) => {
        return acc + probs.problemsManifiestos.length;
    }, 0)
    const problemsItemsDeVentaNum = problems.reduce((acc, probs) => {
        return acc + probs.problemsItemsDeVenta.length;
    }, 0)

    console.log(`Hay problemas con ${problems.length} ordenes, ${problemsManifiestosNum} manifiestos `
        + `y ${problemsItemsDeVentaNum} items de ordenes de ventas: `, problems)

    console.log(`Hay ${manifiestosSinOrdenes.length} manifiestos sin ordenes en el BD: `, manifiestosSinOrdenes)
    console.log(`Hay ${embarquesSinOrdenes.length} embarques sin ordenes en el BD: `, embarquesSinOrdenes)

}

const obtenerCamionesDeOrdenDeVenta = (orden) => {
    let divs = [];
    if (orden.items?.length > 0) {
        orden.items.forEach((item, itIndex) => {
            let pallets = Math.round(parseFloat(item.pallets_orden));

            for (let index = 0; index < pallets; index++) {
                divs.push(`camion-${index}`);
            }
        });
        //padding de camion
        for (let index = divs.length; index == 0 || index % 30 != 0; index++) {
            divs.push(`camion-${index}`);
        }
        divs = divs.reverse();
        //console.log("CHECKING NO CAMIONES: ",chunk(divs, 30).length)
        const camionesChunked = chunkArray(divs, 30);

        return camionesChunked?.length || 0;
    }
    else return 0;
}

const agregarOrdenDeVentaReferenciaAEmbarques = async (ciclo) => {
    const [manifiestos] = await Promise.all([getManifiestosTodos(ciclo)]);
    const promises = [];
    for (const index in manifiestos) {
        const { embarque_ref, orden_ref, orden_camion } = manifiestos[index];

        const datos = { orden_venta_ref: orden_ref }
        datos.indexCamion = parseInt(orden_camion) - 1;

        if (embarque_ref && orden_ref) promises.push(guardarDatosEmbarque(ciclo, embarque_ref, datos))
    }
    await Promise.all(promises)
}

const eliminarSnapshotsNominaColl = async(ciclo = getGlobal().ciclo) => {
    console.log("1. BEGIN -- ", ciclo)
    const promises = [];
    const snapshotsTodos = await obtenerSnapshotsNominaTodos(ciclo);
    
    console.log("2. GOT snapshotsTodos - Begin deletion")
    for (const index in snapshotsTodos){
        const snapshot = snapshotsTodos[index]
        promises.push( firebase.firestore().collection("ciclos").doc(ciclo).collection("snapshotsNomina").doc(snapshot.id).delete()
        .then(() => console.log("SUCCESS " + index))
        .catch(error => console.log("ERR ELIMINAR: ",error)))
    }
    console.log("3. FULLFILL promises...")
    await Promise.all(promises)
    console.log("4. DONE ALL promises!")
}

const eliminarSnapshots_nominaColl = async(ciclo = getGlobal().ciclo) => {
    console.log("1. BEGIN -- ", ciclo)
    const promises = [];
    const snapshotsTodos = await obtenerSnapshots_nominaTodos(ciclo);
    
    console.log("2. GOT snapshotsTodos - Begin deletion")
    for (const index in snapshotsTodos){
        const snapshot = snapshotsTodos[index]
        promises.push( firebase.firestore().collection("ciclos").doc(ciclo).collection("snapshots_nomina").doc(snapshot.id).delete()
        .then(() => console.log("SUCCESS " + index))
        .catch(error => console.log("ERR ELIMINAR: ",error)))
    }
    console.log("3. FULLFILL promises...")
    await Promise.all(promises)
    console.log("4. DONE ALL promises!")
}
const eliminaBiocontrolOrdenesAplicacionColl = async(ciclo = getGlobal().ciclo) => {
    console.log("1. BEGIN -- ", ciclo)
    const promises = [];
    const ordenesTodos = await firebase.firestore().collection("ciclos").doc(ciclo)
    .collection("biocontrol_ordenes_aplicacion").get()
    
    console.log("2. GOT ordenesTodos - Begin deletion", ordenesTodos.docs)
    for (const index in ordenesTodos.docs){
        const snapshot = ordenesTodos.docs[index]
        promises.push( firebase.firestore().collection("ciclos").doc(ciclo).collection("biocontrol_ordenes_aplicacion").doc(snapshot.id).delete()
        .then(() => console.log("SUCCESS " + index))
        .catch(error => console.log("ERR ELIMINAR: ",error)))
    }
    console.log("3. FULLFILL promises...")
    await Promise.all(promises)
    console.log("4. DONE ALL promises!")
}

const fixDatosDeSnapshotsNomina = async(ciclo = getGlobal().ciclo) => {
    console.log("1. BEGIN! fixDatosDeSnapshotsNomina")
    const snapshotsTodos = await obtenerSnapshotsNominaTodos(ciclo);
    const promises = [];
    
    console.log("2. Begin generar promesas")
    for(const index in snapshotsTodos){
        const snapshot = DeepCloneVista(snapshotsTodos[index])
        const [empleadosObj, footerObj] = [DeepCloneVista(snapshot.empleadosSemana), DeepCloneVista(snapshot.footer)]
        
        snapshot.empleadosSemana = fixEmpleadosSemana(empleadosObj)
        snapshot.footer = fixFooter(footerObj)
        snapshot.invernaderoVirtual = getObjetoPorID(getGlobal().invernaderosVirtuales, snapshot.invernadero_ref)
        promises.push(firebase.firestore().collection("ciclos").doc(ciclo).collection("snapshots_nomina").add(snapshot))
    }
    ///////////////////////////////////////////////////////////////
    //Este parte es para detectar si hay duplicados que requiere atención (no hubó)
/*     console.log("2.5. Checar si hay duplicados")
    for(const index in snapshotsTodos){
        const snapshot = snapshotsTodos[index]
        
        for(const index2 in snapshotsTodos){
            const snapshot2 = snapshotsTodos[index2]
            if(snapshot?.id !== snapshot2?.id && snapshot.invernadero_ref === snapshot2.invernadero_ref && snapshot.semana === snapshot2.semana){
                console.log("HAY DUPLICADO: ",snapshot2.id)
            }
        }
    } */
    ////////////////////////////////////////////////////////////////
    
    console.log("3. UPLOADING")
    await Promise.allSettled(promises).catch(error => console.log("ERROR Promise.allSettled() ",error))
    console.log("4. ALL DONE!")
}

const fixEmpleadosSemana = (empleadosSemanaObj) => {
    const empleadosSemanaNew = empleadosSemanaObj.map(datosEmpleado => {
        let datosEmpleadoNew = {}

        Object.entries(datosEmpleado).forEach(([llave, valor]) => {
            if(valor !== 0){ datosEmpleadoNew[llave] = valor; }
        });
        return datosEmpleadoNew
    })
    return empleadosSemanaNew
}
const fixFooter = (footerObj) => {
    const footerNew = footerObj.map(datosFooter => {
        let datosFooterNew = {}

        Object.entries(datosFooter).forEach(([llave, valor]) => {
            if(valor !== 0){ datosFooterNew[llave] = valor; }
        });
        return datosFooterNew
    })
    return footerNew
}


export const subir_productos_mezclados = async () => {

    //ciclo 2021 - 2022 begins on week 19 (May 9 - 15)
    //Its first recorded activities are in week 21 ( (May 23 - 29))
    const productosMezclados2021 = [{
        id: "Xj2EgllRYB5rT5k1Tn2X",
        data: {
            mezcla: true, nombre: "MEDLEYS WST", tipo_calidad_inicial: "PESO",
            variedades: ["c4xmgfozkN7q6y6neXp8","82bYuEZlKP0HtnWzLTXy","NB3pPtoriUegN2ulx2il","QBNOJFjlLpU0GGqTSP3V","i4TC3ECCnCB0eqhybRwp","nY3Ar6FGtZ9e2trkLHjQ"]
        }
    },
    {
        id: "ZuayO47WfShM60PNkkMl",
        data: {
            mezcla: true, nombre: "MEDLEYS MASTRONARDI", tipo_calidad_inicial: "PESO",
            variedades: ["68b7kh64sbhbWKddu6uP","FchHQYLuSrcXsQt7BIxf","G1W6ibDrafwOlvPhBEEn","QAXhlBcfa3aJkuVh3ZDT","Wf1vPOd4z2lHZfpctt6l","zEOsanLp3c0T0PPCYWyg"]
        }
    },
    {
        id: "c8vPGaMrucut9bCR0wQ1",
        data: {
            mezcla: true, nombre: "MEDLEYS MASTRO", tipo_calidad_inicial: "PESO",
            variedades: ["68b7kh64sbhbWKddu6uP","FchHQYLuSrcXsQt7BIxf","QAXhlBcfa3aJkuVh3ZDT","Wf1vPOd4z2lHZfpctt6l","i4TC3ECCnCB0eqhybRwp","zEOsanLp3c0T0PPCYWyg"]
        }
    },
    {
        id: "nrJOW44aV8mybcIUxDBF",
        data: {
            mezcla: true, nombre: "MEDLEYS NTF", tipo_calidad_inicial: "PESO",
            variedades: ["c4xmgfozkN7q6y6neXp8","NB3pPtoriUegN2ulx2il","cJ8qBEs8jC5BhADeSQbG","yKZ8yLyWAwPs8Emscpn8"]
        }
    }, ]
    const productosMezclados2022 = [{}]

    for(const index in productosMezclados2021){
        const mezcla = productosMezclados2021[index]
        console.log("Subir mezcla: ", mezcla)
        await firebase.firestore().collection("ciclos").doc("lK3HBhcbVw9u49jlYA68").collection("productos_mezclados").doc(mezcla.id).set({...mezcla.data})
    }

    console.log("Finalizado subir_productos_mezclados")
}

export const subir_actividad_costos = async (productosBases, medleysGeneral) => {
    const actividades = await obtenerActividades()
    const promises = []
    console.log("1. medleys general: ", medleysGeneral)

    for(const index in actividades){
        const actividad = actividades[index]
        const productos = procesarEdicion(actividad, DeepClone(productosBases));
        
        promises.push(guardarActividad(actividad, productos, medleysGeneral))
    }
    console.log("2. Editing: ")
    await Promise.all(promises)
    console.log("3. Finalizado subir_actividad_costos") 
    window.alert("DONE!")
}  
const isOriginalVariedad = (variedadID = "") => {
    const variedadesOriginales = [
      "6kJQ8ubz0wcKWtZY5hhv", "c4xmgfozkN7q6y6neXp8", "7K7TVRCbOlJy1ILuu1oI",
      "9U2W1cevGkyGDUjGTgJZ", "0GF73LbWXCyfZHO9qiCF", "Te0VXg91da5qeiBwRPTB"];
    return variedadesOriginales.includes(variedadID);
  }
const procesarEdicion = (actividad, productosPassed) => {
    const bonos_por_unidad = actividad.bonos_por_unidad;

    productosPassed.forEach(producto => {
      const primeraVariedadID = producto.variedades?.find(variedad => isOriginalVariedad(variedad.id))?.id || "";
      const bono = bonos_por_unidad[primeraVariedadID];
      producto.costo = bono;
    })
    return productosPassed;
}