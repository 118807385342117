import { SelectButton } from "primereact/selectbutton";
import React, { useState, useEffect, useGlobal } from "react"
import { POLO_NORTE } from '../../../constants';
import { DeepClone } from "../../../util/functions";

function MapaInvernaderoDeSeleccion(props) {
    const HERRAMIENTA_CLARO_KEY = "claro"
    const HERRAMIENTA_LINEA_KEY = "linea"
    const HERRAMIENTA_CAPILLA_KEY = "capilla"
    const HERRAMIENTA_POLO_KEY = "polo"
    const HERRAMIENTAS_DE_SELECCION = [
        { nombre: "Claro", key: HERRAMIENTA_CLARO_KEY },
        { nombre: "Línea", key: HERRAMIENTA_LINEA_KEY },
        { nombre: "Capilla", key: HERRAMIENTA_CAPILLA_KEY },
        { nombre: "Polo", key: HERRAMIENTA_POLO_KEY }
    ]
    const [mapaInvernaderoPassed, setMapaInvernaderoPassed] = useState(props.mapaInvernaderoPassed)
    const [herramientaSeleccionada, setHerramientaSeleccionada] = useState(HERRAMIENTAS_DE_SELECCION[0])

    useEffect(() => {
        seleccionarClaro();
    }, [props.editing])
    const seleccionarClaro = (poloSeleccionado, capillaSeleccionado, lineaSeleccionado, claroSeleccionado) => {
        let mapaActualizado = DeepClone(mapaInvernaderoPassed)

        mapaActualizado.forEach((polo, poloIndex) => {
            let todosLosClarosSeleccionadosEnPolo = polo.every(capilla => capilla.every(linea => linea.every(claro => claro.seleccionado)))

            polo.forEach((capilla, capillaIndex) => {
                let todosLosClarosSeleccionadosEnCapilla = capilla.every(linea => linea.every(claro => claro.seleccionado))

                capilla.forEach((linea, lineaIndex) => {
                    let todosLosClarosSeleccionadosEnLinea = linea.every(claro => claro.seleccionado)

                    linea.forEach((claro, claroIndex) => {
                        if (herramientaSeleccionada.key === HERRAMIENTA_POLO_KEY && poloSeleccionado === poloIndex) {
                            claro.seleccionado = !todosLosClarosSeleccionadosEnPolo
                        }
                        else if (herramientaSeleccionada.key === HERRAMIENTA_CAPILLA_KEY
                            && poloSeleccionado === poloIndex && capillaSeleccionado === capillaIndex) {
                            claro.seleccionado = !todosLosClarosSeleccionadosEnCapilla
                        }
                        else if (herramientaSeleccionada.key === HERRAMIENTA_LINEA_KEY && poloSeleccionado === poloIndex
                            && capillaSeleccionado === capillaIndex && lineaSeleccionado === lineaIndex) {
                            claro.seleccionado = !todosLosClarosSeleccionadosEnLinea
                        }
                        else if (herramientaSeleccionada.key === HERRAMIENTA_CLARO_KEY
                            && poloSeleccionado === poloIndex && capillaSeleccionado === capillaIndex
                            && lineaSeleccionado === lineaIndex && claroSeleccionado === claroIndex) {
                            claro.seleccionado = !claro.seleccionado
                        }
                    })
                })
            })
        });
        setMapaInvernaderoPassed(mapaActualizado)
        props.setMapaInvernaderoFisico(mapaActualizado)
    }

    const Claro = (props) =>
        <div className={`oa-claro${props.claro.seleccionado ? ' seleccionado' : ''}${props.claro.conIncidencia ? ' oa-con-incidencia' : ''}`}
            key={`pol[${props.poloIndex}], cap[${props.capillaIndex}], lin[${props.lineaIndex}], cla[${props.claroIndex}]`}
            onClick={() => { if (props.puedeEditar) seleccionarClaro(props.poloIndex, props.capillaIndex, props.lineaIndex, props.claroIndex); }} >
            {props.claro.seleccionado ? '' : ''}
            <div className="oa-claro-interior"></div>
        </div>

    const Linea = (props) =>
        <div className="oa-linea" key={`pol[${props.poloIndex}], cap[${props.capillaIndex}], lin[${props.lineaIndex}]`}>
            <div className="oa-titulo-linea" >{props.lineaIndex + 1}</div>
            {props.children}
        </div>

    const Capilla = (props) =>
        <div key={`pol[${props.poloIndex}], cap[${props.capillaIndex}]`} className="oa-capilla-container">
            <div className="oa-titulo-linea" >Cap. {props.capillaIndex + 1}</div>
            <div className="oa-capilla">
                <div className="oa-linea-numero">
                    <div className="oa-claro"></div>
                    {props.capilla[0].map((claro, claroIndex) =>
                        <div key={`nc-${claroIndex}`} className="oa-claro">{claroIndex + 1}</div>//Nombre claro
                    )}
                </div>
                {props.children}
            </div>
        </div>

    const Polo = (props) =>
        <div className="oa-polo" key={`cap[${props.poloIndex}]`}>
            {props.children}
        </div>

    return (
        <React.Fragment>
            {props.puedeEditar && <div className="p-col-12 ">
                <div className="p-grid filtros no-print">
                    <div className="form-group p-col-12 select-">
                        <label htmlFor="modo-consulta">Modo de selección de claros</label>
                        <SelectButton dataKey="key" optionLabel="nombre" options={HERRAMIENTAS_DE_SELECCION}
                            value={herramientaSeleccionada} onChange={(e) => { setHerramientaSeleccionada(e.value) }} />
                    </div>
                </div>
            </div>}

            <div className={props.puedeEditar ? `oa-mapa-invernadero seleccion-${herramientaSeleccionada.key} ` : "oa-mapa-invernadero"}>

                {mapaInvernaderoPassed.map((polo, poloIndex) =>
                    <Polo key={`pol-ou-${poloIndex}`} poloIndex={poloIndex}>

                        {polo.map((capilla, capillaIndex) =>
                            <Capilla key={`cap-ou-${poloIndex}-${capillaIndex}-${capillaIndex}`} poloIndex={poloIndex} capillaIndex={capillaIndex} capilla={capilla}>

                                {capilla.map((linea, lineaIndex) =>
                                    <Linea key={`lin-ou-${poloIndex}-${capillaIndex}-${lineaIndex}`} poloIndex={poloIndex} capillaIndex={capillaIndex} lineaIndex={lineaIndex}>

                                        {linea.map((claro, claroIndex) =>
                                            <Claro key={`cla-ou-${poloIndex}-${capillaIndex}-${lineaIndex}-${claroIndex}`} poloIndex={poloIndex} capillaIndex={capillaIndex} lineaIndex={lineaIndex} claro={claro} claroIndex={claroIndex} puedeEditar={props.puedeEditar} />
                                        )}

                                    </Linea>
                                )}

                            </Capilla>
                        )}

                    </Polo>
                )}

            </div>
        </React.Fragment>
    )
}
export default React.memo(MapaInvernaderoDeSeleccion)//131