import React,{useState, useEffect} from 'reactn';
import {useGlobal} from "reactn";
import {Button} from 'primereact/button';
import ClienteCard from "./ClienteCard";
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { obtenerClientes, obtenerClientesCiclo } from '../../service/Clientes';
import { obtenerPalletsPorCamionCiclo, obtenerUnidadesCargadas, obtenerEmbarquesATiempo,
   obtenerEmbarquesAtrasados, obtenerEmbarquesOnHold,obtenerPromediosPalletsConsolidados } from '../../service/embarques';
import { PUNTO_CARGA_ATONGO_AGROPARK_NOMBRE } from '../../util/constants';
const CLIENTES_PRINCIPALES = ["Mastronardi", "Windset Farms", "Nature Fresh Farms"];
function Cards(props){
    const [invernadero,setInvernadero] = useGlobal("invernadero");
    const irInvernadero = (invernadero)=>{
        props.history.push("/invernadero_virtual/crear");
    }
    
    const [clientes,setClientes] = useState([]);
    
    const [ciclos,setCiclos] = useGlobal("ciclos");
    const [ciclo,setCiclo] = useGlobal("ciclo");
    const [nombreModulo,setNombreModulo] = useGlobal("nombreModulo");
    const [weeks,week,handleWeekChange,updateCicloWeekSelector] = useWeekSelector();
    const [weekGlobal, setWeekGlobal] = useGlobal("weekGlobal");
    var [usuario, ] = useGlobal("usuario");
    const [cargando, setCargando] = useGlobal("cargando");
    const [, setMostrarModalPermiso] = useGlobal("mostrarModalPermiso");
    useEffect(()=>{
      setNombreModulo("Embarques");
    },[])

    useEffect( ()=>{
      if (!puedeConsultarCardsEmbarques()) {
        setCargando(false)
        return
      }
      if(ciclo && week.nombre !== "Cargando"){
        let promises = [];
        obtenerCardsClientes().then(clientes=>{
          for(let x = 0; x<clientes.length;x++){
            let producto = {};
            let cliente = {};
            cliente = clientes[x];
           promises.push(obtenerResumen(cliente));                                   
          }
          setCargando(true);
          Promise.all(promises).then(values=>{
            let copia = values.map(el=>Object.assign({},el));                        
            setClientes(copia);
          }).catch(err=>{
            if(err.code && err.code === "permission-denied"){
              setMostrarModalPermiso(true);
              console.log(err.code);
            }
          }).finally(el=>{
            setCargando(false);
          });         
          
        }).catch(err=>{
          console.log(err);
        })
      }
        
    },[ciclo, week])
    const obtenerCardsClientes = async (params) => {        
        let clientes = await obtenerClientes();
        const clientesCiclo = await obtenerClientesCiclo(ciclo)
        // 
        // 
        const clientesCicloData = clientes.filter((cliente) => clientesCiclo.some((cli) => cli.cliente_ref === cliente.id))

        let mastronardi = clientes.find(el=>el.nombre === "Mastronardi")
        let windset = clientes.find(el=>el.nombre === "Windset Farms");
        let naturefresh = clientes.find(el=>el.nombre === "Nature Fresh Farms");
        let natureSweet = clientes.find(el=>el.nombre === "Nature Sweet");
        const hayClientesMinoritarios = clientesCicloData.some((cli) => !cli.mayoritario)

        
        mastronardi.color = "";
        windset.color = "";
        naturefresh.color = "";
        natureSweet.color = ""
        
        const cardsData = [naturefresh, windset, mastronardi, natureSweet]
        if(hayClientesMinoritarios) {
          let minoritarios = {nombre:"Otros",nombre_comun:"OTROS", id: "OTROS"}
          minoritarios.color = "";
          cardsData.push(minoritarios)
        }
        return cardsData
    }
    
    function obtenerResumen(cliente){
      return new Promise(async (resolve,reject)=>{
        let [
          promedioPallets,
          {promedioPalletsConsolidados, promedioPalletsSinConsolidar},
          unidadesCargadasAtongo,
          unidadesCargadasAgropark,
          unidadesCargadasAtongoAgropark,
          aTiempo,
          retrasados,
          onHold
        ] = await Promise.all([
          obtenerPalletsPorCamionCiclo(ciclo,week.time,cliente),
          obtenerPromediosPalletsConsolidados(ciclo,week.time,cliente),
          obtenerUnidadesCargadas(ciclo,week.time,"Atongo", cliente),
          obtenerUnidadesCargadas(ciclo,week.time, "Agropark", cliente),
          obtenerUnidadesCargadas(ciclo,week.time, PUNTO_CARGA_ATONGO_AGROPARK_NOMBRE, cliente),
          obtenerEmbarquesATiempo(ciclo,week.time,cliente),
          obtenerEmbarquesAtrasados(ciclo,week.time, cliente),
          obtenerEmbarquesOnHold(ciclo, cliente)
        ]);
        cliente.promedioPallets = promedioPallets;
        cliente.promedioPalletsSinConsolidado = promedioPalletsSinConsolidar ;            
        cliente.promedioPalletsSoloConsolidado = promedioPalletsConsolidados;            
        cliente.unidadesCargadasAtongo = unidadesCargadasAtongo;
        cliente.unidadesCargadasAgropark = unidadesCargadasAgropark;
        cliente.unidadesCargadasAtongoAgropark = unidadesCargadasAtongoAgropark;
        cliente.aTiempo = aTiempo;
        cliente.retrasados = retrasados;
        cliente.onHold = onHold;
        resolve(cliente);
      });
    }

    const puedeConsultar = (params) => {
      return (usuario && ["Administrador"].includes(usuario.rol) )
    }

    function puedeConsultarCardsEmbarques() {
      if (!usuario) return false
      return usuario.rol === 'Administrador'
          || usuario.rol === 'Administrador Embarques'
          || usuario.rol === 'Auxiliar Embarques'
          || usuario.rol === 'Personal Administrativo'
          || usuario.rol === 'Finanzas'
      ;
  }
    
    return (
      <div>
      { puedeConsultarCardsEmbarques() ? 
        <div className="p-grid">
          <div className="p-col-12"></div>
          <div className="p-col-12">
            <div className="p-grid filtros">              
              <WeekSelector weeks = {weeks} handleWeekChange = {handleWeekChange} week = {week}/>
            </div>
          </div>

          <div className="p-col-12">
            <div className="p-grid">    
            {clientes?  clientes.map(cliente=><ClienteCard key = {cliente.id} cliente = {cliente}/>):null}
            </div>
          </div>
        </div>
      :
        <div>
            { !cargando &&
                <span>No tienes acceso a esta parte del sistema.</span>
            }
        </div>
      }
      </div>
    );
}

export default Cards;