import React, { useEffect, useGlobal, useState } from 'reactn';
import { Dropdown } from 'primereact/dropdown';
import { TabMenu } from 'primereact/tabmenu';
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import ReporteDefectosExterno from './ReporteDefectosExterno';
import ReporteColoracion from './ReporteColoracion';
import ReportePesos from './ReportePesos';
import ReporteGradosBrix from './ReporteGradosBrix';
import ReporteCavidad from './ReporteCavidad';
import { MultiSelect } from 'primereact/multiselect';
import ContenedorFiltro from '../common/ContenedorFiltro';
import { obtenerDefectos } from '../../service/Defectos';
import { obtenerDiasDeSemanaConDetalles } from '../../service/fechas';
import SeccionBotones from "../common/SeccionBotones";
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { ToggleButton } from 'primereact/togglebutton';
import { obtenerProveedores, obtenerProveedorPorUsuario } from '../../service/Queries/Proveedores';
import { DeepClone, dosDecimales, getObjetoPorID, getProductoYVariedadString, separadoComas } from '../../util/functions';
import ContenedorHeader from '../common/ContenedorHeader';
import icono from '../../images/icons/external-icon.svg';
import DatoCard from '../common/cards/DatoCard';
import SeccionDatosCenter from '../common/SeccionDatosCenter';
import BaseCard from '../common/cards/BaseCard';
import BaseButton from '../common/BaseButton';
import { obtenerPesoFruto, obtenerDefectos as obtenerDefectos2 } from '../../service/Calidad';
import { obtenerPesoPromedioCajaSemana } from '../../service/Producto';

const ReporteProveedorExterno = (props) => {
    const [invernadero, setInvernadero] = useGlobal("invernadero");
    const [usuario] = useGlobal("usuario");
    const [, setCargando] = useGlobal("cargando");
    const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
    const [weeks, week, handleWeekChange, updateCiclo, setWeek] = useWeekSelector();
    const [ciclo,] = useGlobal("ciclo");
    const [cargandoDatosGlobales, setCar] = useGlobal("cargandoDatosGlobales");

    const [filtroDefectos, setFiltroDefectos] = useState([]);
    const [proveedorCardInfo, setProveedorCardInfo] = useState({});
    const [tabs, setTabs] = useState([
        { label: "Defectos" },
        { label: "Coloración" },
        { label: "Grados Brix" },
    ])
    const [activeTab, setActiveTab] = useState(tabs[0]);
    const [defectos, setDefectos] = useGlobal("defectos")
    let done = false;
    const [diasSemana, setDiasSemana] = useState([])
    const [filtroDias, setFiltroDias] = useState()
    const [globalToggle, setGlobalToggle] = useState(false)

    const [subVariedades, setSubvariedades] = useState([]);
    const [subVariedad, setSubvariedad] = useState(false);
    const [proveedorExterno, setProveedorExterno] = useState({});
    const [producto, setProducto] = useState({});

    const [productosYVariedades] = useGlobal('productosYVariedades')
    useEffect(() => {
        //setCargando(true)
        if (ciclo && week.nombre !== "Cargando")
            obtenerDatosIniciales()
    }, [ciclo, week])

    const obtenerDatosIniciales = async () => {
        //setCargando(true)
        let dias = obtenerDiasDeSemanaConDetalles(week.time);
        dias.push({ unixTime: 0, abreviacion: 'T', nombre: 'semana', nombrePlano: "semana" })
        setDiasSemana(dias);
        setFiltroDias("semana");

        let prov = await obtenerProveedorPorUsuario(ciclo, usuario.uid)
        //window.alert(prov.nombre)
        setProveedorExterno(prov)

        if (!prov.empty) {
            let inv = invernaderosVirtuales.find(inv => inv.id === prov.invernadero_ref);
            let prod = productosYVariedades.find(prod => prod.id === inv.producto_ref);
            let defs = defectos || await obtenerDefectos();
            setInvernadero(inv)
            setProducto(prod)
            setDefectos(defs)
            obtenerDatosCard(prov, inv)
        }
    }

    const actualizarDefectosDisponibles = (defectosReporte) => { setFiltroDefectos(defectosReporte?.map(el => el.id) || []); }

    useEffect(() => {
        if (!globalToggle)
            setSubvariedad(false)
    }, [globalToggle])
    const obtenerDatosCard = async (provExterno, invernaderoPassed) => {
        let proveedoresCardData = await obtenerResumenExterno(provExterno, invernaderoPassed)
        setProveedorCardInfo(proveedoresCardData)
    }
    const obtenerResumenExterno = async (provExterno, invernaderoPassed) => {
        let producto = getObjetoPorID(productosYVariedades, invernaderoPassed.producto_ref);
        const [pesoCajaResp, pesoFrutoResp, defectosResp] = await Promise.all([
            obtenerPesoPromedioCajaSemana(ciclo, invernaderoPassed.id, week.time, provExterno.id),
            obtenerPesoFruto(ciclo, invernaderoPassed.id, week.time, provExterno.id),
            obtenerDefectos2(ciclo, invernaderoPassed.id, week.time, producto.tipo_calidad_inicial, provExterno.id)
        ])

        return {
            pesoCaja: isNaN(pesoCajaResp) ? "-" : dosDecimales(pesoCajaResp),
            pesoFruto: isNaN(pesoFrutoResp) ? "-" : separadoComas( dosDecimales(parseFloat(pesoFrutoResp)*1000)),
            defectos: isNaN(defectosResp) ? "-" : dosDecimales(defectosResp)
        }
    }

    return (
        <div className="p-grid p-justify-center">
            {invernadero?.es_externo && <ContenedorHeader col="p-col p-col-auto" titulo="Reporte de calidad semanal" subtituloInv={getProductoYVariedadString(invernadero)} invernaderoNombre={<img width="60" alt="icono" src={icono} />}
                iconos={`icon-general icon-id bor-${invernadero.color} bg-${invernadero.color} ${invernadero.habilitado ? "" : "Deshabilitado"}`} atras={invernadero.es_externo ? null : () => props.history.goBack()} />}

            <div className="p-col-12">
                <div className="p-grid filtros">
                    <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />

                    {activeTab.label === "Defectos" && <ContenedorFiltro label="Defecto">
                        <MultiSelect options={defectos} value={filtroDefectos} onChange={(e) => setFiltroDefectos(e.value)}
                            optionLabel="nombre" optionValue="id"
                            selectedItemsLabel={`${filtroDefectos.length} defectos seleccionados`} />
                    </ContenedorFiltro>}
                    {globalToggle && (activeTab.label === "Defectos" || activeTab.label === "Coloración" || (activeTab.label === "Grados Brix" && subVariedades.length === 0))
                        && <ContenedorFiltro label=" sub variedad">
                            <Dropdown options={subVariedades} value={subVariedad} onChange={(e) => setSubvariedad(e.target.value)}
                                optionLabel="nombre" optionValue="id" />
                        </ContenedorFiltro>}
                    {subVariedades.length > 0 && (activeTab.label === "Defectos" || activeTab.label === "Coloración" || (activeTab.label === "Grados Brix" && subVariedades.length === 0))
                        && <ContenedorFiltro label="tipo">
                            <ToggleButton className="reactbtn-icon" onLabel="Sub variedades" offLabel="Global" checked={globalToggle} onChange={(ev) => setGlobalToggle(ev.value)} ></ToggleButton>
                        </ContenedorFiltro>}
                </div>
            </div>
            <BaseCard tituloCard={"Resumen " + usuario?.displayName} className="w-100">
                <SeccionDatosCenter>
                    <DatoCard label="Peso caja" valor={(proveedorCardInfo?.pesoCaja || "0") + " Kg"} />
                    <DatoCard label="Pesos fruto" valor={(proveedorCardInfo?.pesoFruto || "") + " gr"} />
                    <DatoCard label="defectos" valor={(proveedorCardInfo?.defectos || "") + "%"} />
                </SeccionDatosCenter>
            </BaseCard>

            <div className="p-col-12">
                <TabMenu model={tabs} activeItem={activeTab} onTabChange={(e) => { setActiveTab(e.value) }} />

                {activeTab.label === "Defectos" && proveedorExterno.id &&
                    <ReporteDefectosExterno actualizarDefectosDisponibles={actualizarDefectosDisponibles} filtroDefectos={filtroDefectos} filtros={{subvariedad: subVariedad, proveedorID: proveedorExterno.id, reporteExterno: true }} />}
                {activeTab.label === "Coloración" && proveedorExterno &&
                    <ReporteColoracion semana={week.time} subVariedad={subVariedad} proveedorExterno={proveedorExterno.id} reporteExterno />}
                {activeTab.label === "Grados Brix" && proveedorExterno &&
                    <ReporteGradosBrix subVariedades={subVariedades} semana={week.time} proveedorExterno={proveedorExterno.id} />}

            </div>
        </div>)
}
export default ReporteProveedorExterno;