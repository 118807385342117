import { actualizarActividad } from "../../../service/Queries/Agentes"

export const guardarCapturaAgente = async (cicloId, state, noFinalizar = false) => {



    let obj = {
        comentario: state.comentario || "",
        tiempo_tomado: state.tiempo_tomado,
        realizada: true,
        tiempo_tomado_timestamp: state.tiempo_tomado_timestamp,
        tareaParcial: state.tareaParcial
    }
    if (noFinalizar) {
        delete obj.realizada;
        delete obj.tiempo_tomado;
        delete obj.tiempo_tomado_timestamp;
    }
    if (state.tareaParcial) { obj.tiempos_tomados = state.tiempos_tomados }

    let result = await actualizarActividad(cicloId, state["idTarea"], obj);
    return result;
}

