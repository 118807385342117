import React from "react";
import { useGlobal, useEffect, useState } from "reactn";
import Chart from "react-apexcharts";
import { DeepClone, dosDecimales, ordenarArreglaDeObjetosPor2Llaves, selectedItemNombresMultiselect } from "util/functions";
import ContenedorFiltro from "components/common/ContenedorFiltro";
import SeccionFiltros from "components/common/SeccionFiltros";
import { Dropdown } from "primereact/dropdown";
import { obtenerEnfermedadesYPlagasOrdenPrioridad, obtenerInfoHeatmap, obtenerRegistrosEnfermedadesPorInvernaderoCiclo } from "service/biocontrol";
import { MultiSelect } from "primereact/multiselect";
import { obtenerListaDeSemanas } from "service/fechas";
import ContenedorHeader from "components/common/ContenedorHeader";
import { chunkArray } from "../../util/functions";

const optionsOrig = {
  plotOptions: {
    heatmap: {
      //enableShades: false,
      shadeIntensity: .9,
      colorScale: {
        ranges: [{
          from: 0,
          to: .0009,
          color: '#FFFFFF',
          name: 'Sin enfermedades',
        },
        {
          from: 0.001,
          to: 4,
          color: '#44A026',
          name: 'Bajo',
        },
        {
          from: 4.001,
          to: 8,
          color: '#d8b90d',
          name: 'Medio',
        },
        {
          from: 8.001,
          to: 12,
          color: '#E67E00',
          name: 'Alto',
        },
        {
          from: 12.001,
          to: 16,
          color: '#FF3C3D',
          name: 'Grave',
        },
        {
          from: 16.001,
          to: 40,
          color: '#964B00',
          name: 'Ultra grave',
        },
        {
          from: 40.001,
          to: 100,
          color: '#000000',
          name: 'Ultra super grave',
        }
        ]
      }
    }
  },
  chart: {
    height: 350,
    type: "heatmap",
  },
  dataLabels: {
    enabled: false,
  },
  colors: ["#008FFB"],
  title: {
    text: "Porcentaje de enfermedades y plagas",
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return `${dosDecimales(val)}% claros`;
      },
    },
  },
};
const HeatmapBiocontrolEnfermedades = (props) => {
  const [ciclo] = useGlobal("ciclo");
  const [cicloObj] = useGlobal("cicloObj");
  const [invernaderosFisicos] = useGlobal("invernaderosFisicos");
  const [, setCargando] = useGlobal("cargando");
  const [enfermedadesYPlagas, setEnfermedadesYPlagas] = useState([])
  const [invernaderoFisico, setInvernaderoFisico] = useState(props.location?.data?.invernadero);
  const [enfermedades, setEnfermedades] = useState([])
  const [options, setOptions] = useState({})
  const [series, setSeries] = useState([])
  const [registrosClarosCiclo, setRegistrosClarosCiclo] = useState(null)

  useEffect(() => {
    if(invernaderosFisicos){ obtenerDatosIniciales(); }
  }, [invernaderosFisicos])

  useEffect(() => {
    if (ciclo && invernaderoFisico?.id) { obtenerDatosEnfermedades(); }
  }, [ciclo, invernaderoFisico])
  useEffect(() => {
    if (registrosClarosCiclo) { obtenerSeriesDatos(); }
  }, [enfermedades, registrosClarosCiclo])

  const obtenerDatosIniciales = async () => {
    setCargando(true);
    asignarCategoriasSemanas();
    const enfermedades = await obtenerEnfermedadesYPlagasOrdenPrioridad();
    ordenarArreglaDeObjetosPor2Llaves(enfermedades, "prioridad", "nombre");

    setEnfermedadesYPlagas(enfermedades);
    setCargando(false);
    //setEnfermedades(enfermedades);
    //console.log("GOT DATOS INICIALES SET INV>>>")
    //setInvernaderoFisico(invernaderosFisicos[0]);
  }

  const obtenerDatosEnfermedades = async () => {
    setCargando(true);
    const enfermedadesAFiltrar = new Set();
    const datosInvernadero = await obtenerRegistrosEnfermedadesPorInvernaderoCiclo(ciclo, invernaderoFisico.id);
    //console.log("REGISTROS ENFERMEDADES ", datosInvernadero);

    const listaSemanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
    enfermedadesYPlagas.map(enfermedad => {
      listaSemanas.forEach((semana, index) => {
        const datosSemana = datosInvernadero?.find(registroSemana => registroSemana.some(registroClaro => registroClaro.semana == semana.time)) || [];
        const incidencia = obtenerInfoHeatmap(invernaderoFisico, enfermedad.id, datosSemana)
        if (incidencia) { enfermedadesAFiltrar.add(enfermedad) }
      })
      return;
    })
    //console.log("GOT DATOS ENFERMEDADES DE INV>>>")
    asignarCategoriasSemanas();
    //console.log("SET OPTIONS: >>>")
    //setEnfermedades(Array.from(enfermedadesAFiltrar))
    setRegistrosClarosCiclo(datosInvernadero)
    //console.log("SET REGISTROS CLAROS: >>>")

    setCargando(false)
  }
  const asignarCategoriasSemanas = (listaSemanasConDatos) => {
    const listaSemanas = listaSemanasConDatos || obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
    const labels = listaSemanas.map(semana => semana.nombreCompacto);
    const copiaOptions = DeepClone(optionsOrig)
    copiaOptions.xaxis = { tickPlacement: 'between',categories: labels };
    copiaOptions.yaxis = {
      labels: {
        tooltip: {
          formatter: function (val) {
            return `${val}% claros`;
          },
        },
        show: true,
        trim: false,
        style: {
          cssClass: 'mb-4',
        },
      },
    };
  //  copiaOptions.xaxis.categories = labels;
    setOptions(copiaOptions)
  }

  const obtenerSeriesDatos = () => {
    //console.log("HOOK REGISTROS CLAROS get series: >>>")

    const listaSemanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
    //console.log("------ obtenerSeriesDatos -------: ");

    const enfermedadesOrdenadas = ordenarEnfermedadesPorPrioridad();
    let rangoMasAlto = 0;

    const copiaSeries = enfermedadesOrdenadas.map(enfermedad => {
      const data = listaSemanas.map(semana => {
        const datosSemana = registrosClarosCiclo?.find(registroSemana => registroSemana.some(registroClaro => registroClaro.semana == semana.time)) || [];
        const incidencia = obtenerInfoHeatmap(invernaderoFisico, enfermedad.id, datosSemana)

        const valorIncidencia = parseFloat(incidencia);
        rangoMasAlto = checarRangoMasAlto(valorIncidencia, rangoMasAlto)

        return valorIncidencia;
      })

      const nombreConPrioridad = `${enfermedad.nombre} (${enfermedad.prioridad})`
      /*       const nombre = enfermedad.nombre.length > 28
              ? chunkArray(nombreConPrioridad, 28)
              : nombreConPrioridad */
      return { name: nombreConPrioridad, data }
    })

    setSeries(copiaSeries);
    //console.log("SET RANGO OPCIONES: >>> ", rangoMasAlto)

    setRangoOpciones(rangoMasAlto);
  }
  const ordenarEnfermedadesPorPrioridad = () => {
    const enfermedadesCopia = DeepClone(enfermedades);
    ordenarArreglaDeObjetosPor2Llaves(enfermedadesCopia, "prioridad", "nombre", false)
    ////console.log("SORT por prioridad: ", {before: enfermedades, after: enfermedadesCopia});
    return enfermedadesCopia;
  }
  const checarRangoMasAlto = (incidencia, rangoMasAlto) => {
    if (incidencia == 0) return rangoMasAlto
    const rangosDelHeatmap = optionsOrig.plotOptions.heatmap.colorScale.ranges;

    rangosDelHeatmap.forEach((rango, index) => {
      if (incidencia > rango.from && incidencia < rango.to && index >= rangoMasAlto) { rangoMasAlto = index; }
    })
    //if (rangoMasAlto === 4) //console.log("inci: ", incidencia)
    return rangoMasAlto;
  }

  const setRangoOpciones = (rangoMasAlto) => {
    const copiaOptions = DeepClone(options);
    const rangosDelHeatmap = optionsOrig.plotOptions.heatmap.colorScale.ranges;
    const nuevoRangos = [];

    rangosDelHeatmap.forEach((rango, index) => {
      if (index <= rangoMasAlto) { nuevoRangos.push({ ...rango }); }
    })
    //console.log("NUEVO RANGOS: ", { nuevoRangos, rangoMasAlto })
    copiaOptions.plotOptions.heatmap.colorScale.ranges = nuevoRangos;
    setOptions(copiaOptions)
    //console.log("SET OPTIONS!!!: ", )
  }

  return (
    <div className="p-grid chart-page">
      <ContenedorHeader titulo="Afectaciones de biocontrol en el ciclo" invernaderoNombre={invernaderoFisico?.nombre}
        iconos={`icon-id bor-${invernaderoFisico?.color} ${!invernaderoFisico && "bio-icon"} bg-${invernaderoFisico?.color}`} atras={props.history.goBack} />

      <div className="p-col-12">
        <SeccionFiltros>
          <ContenedorFiltro label="invernadero">
            <Dropdown value={invernaderoFisico} options={invernaderosFisicos} onChange={(e) => setInvernaderoFisico(e.value)} dataKey="id" optionLabel="nombre" />
          </ContenedorFiltro>

          <ContenedorFiltro label="enfermedades">
            <MultiSelect value={enfermedades} options={enfermedadesYPlagas} onChange={(e) => setEnfermedades(e.value)} dataKey="id" optionLabel="nombre"
              placeholder="Seleccione una enfermedad" selectedItemTemplate={item => selectedItemNombresMultiselect(item, enfermedades, enfermedadesYPlagas)}
              selectedItemsLabel={`${enfermedades?.length} enfermedades seleccionadas`} filter={true} filterBy="prioridad,nombre" />
          </ContenedorFiltro>
        </SeccionFiltros>
      </div>
      <div className={`p-col-12 chart-container-filters    `}>
        {series?.length > 0 ? <Chart options={options} series={series} type="heatmap" height={(series.length * 25) + 112} /> : <label>No hay datos a mostrar</label>}
      </div>
    </div>
  );
};
export default HeatmapBiocontrolEnfermedades;//194
