import React, {useEffect, useState, useGlobal} from 'reactn';
import { obtenerActividadesEnCicloPorc } from '../../service/RhService';
import { obtenerCiclo } from '../../service/Ciclos';
import {  colores} from '../../constants';
import LineChart from '../common/LineChart';
import { stringToColor } from '../../util/functions';

const optionsOrig = {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: true
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    title: {
      text: 'Porcentaje de actividades de empleado',
      align: 'left'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    xaxis: {
      categories: []
    }
}

const PorcentajeActividades = (props) => {
    const [options,setOptions] = useState({...optionsOrig})
    const [invernaderos, setInvernaderos] = useState([]);
    const [invernaderosOrig, setInvernaderosOrig] = useState([]);
    const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
    const [cicloObj] = useGlobal("cicloObj");
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");

    useEffect(()=>{
      setHabilitadoSelectorCiclo(true)
    },[])
    
    useEffect(() => {
        if(invernaderosVirtuales){
          obtenerDatosInvernaderos()
        }
        
    }, [invernaderosVirtuales])

    const procesar = (invernaderos, resultAct) => {
        
        
        //console.log("LOG 1 ",{invernaderos, resultAct})
        let labels = resultAct[0].map(el=>{
            return el.semana.nombreCompacto;
        })
        let datasets = invernaderos.map((el,index)=>{
            let asignacion_lineas = el["asignacion_lineas"];
            let lineasTotales = asignacion_lineas.reduce((acc,curr) => {
                let dato = curr["totales"].split(" ")[0]
                if(dato){
                  dato = parseInt(dato);
                  if(!isNaN(dato)){
                    return acc+dato;
                  }else{
                    return acc;
                  }
                }
              },0)
            
            let color = stringToColor(el.color);
            return {
                datasetId:el.id,
                label: el.nombre,
                name: el.nombre,
                data: resultAct[index].map(el=>{
                    let porcActividades = Math.round(100 * el.actividades/( lineasTotales))
                    return porcActividades
                }),
                fill:false,
                borderColor: color,
                backgroundColor: color
            }
        });
        let copiaOptions ={...options};
            copiaOptions["xaxis"]["categories"] = labels;
            let colores = invernaderos.map(el=>stringToColor(el.color));
            copiaOptions["colors"] = colores;
            
            
            setOptions(copiaOptions);
        
        
        
        return {labels, datasets}

    }

    const obtenerDatosInvernaderos = async() =>{
      let promises = [];
      
      const invernaderos = invernaderosVirtuales.filter(inv=> !inv.es_externo);
      invernaderos.forEach(async invernadero => {
        promises.push(obtenerPorcentajeLocal(cicloObj,invernadero));  
      })     

      setInvernaderosOrig(invernaderos);
      let values = await Promise.all(promises);
      setInvernaderos(procesar(invernaderos, values));
    }
    
    const obtenerPorcentajeLocal = async (ciclo,invernadero) => {
        try {

            let porcentaje = await obtenerActividadesEnCicloPorc(ciclo,invernadero, 0);    
            return porcentaje;
        } catch (error) {
            throw(error);
        }
    }
    
    return <div className="p-grid chart-page">
            <div className="p-col  p-col-auto title-container">
                  <span className="back-btn" onClick={() => { props.history.goBack() }}></span>
                  <span className={`icon-general chart-icon `}></span>
                  <span className="titles-group">
                    <h1 className="header-title">Actividades empleado</h1>
                  </span>
            </div>
        <LineChart data = {invernaderos} filtrosOrig = {invernaderosOrig} options = {options}/>
    </div>
}

export default PorcentajeActividades;
