import * as firebase from "firebase/app";
import "firebase/firestore";
import { getGlobal, setGlobal } from 'reactn'
import { obtenerPesoCajaParaSemana } from './Producto';
import { obtenerRegistrosSemana, obtenerReporteCosecha, obtenerValoresReales, obtenerValoresRealesParaCumplimiento } from './Cosecha'
import { obtenerPackoutSemanal, obtenerkgm2semanal, getPackoutSemanal } from './ReportesProducto';
import { getEmpaquePorInvernaderoVirtualID, obtenerInvernaderosVirtualesHabilitados, obtenerInvernaderosVirtualesHabilitadosIncluidosExternos } from './Invernaderos';
import { obtenerClientesCiclo } from './Clientes';
import { obtenerOrdenesSemana as oos } from './QueriesOrdenes';
import {
    obtenerInicioDia, obtenerDiasDeSemana,
    obtenerIndiceDiaSemana, obtenerHora, obtenerListaDeSemanas
} from './fechas';
import { obtenerOrdenesSemana, obtenerKilosOrdenadosDia, obtenerSobranteSemanaPasada } from './Ordenes';
import { obtenerPresentaciones } from './Presentaciones';
import { horaCorte, segundosSemana } from '../constants'
import { obtenerSobrantesPorSemana } from "./Queries/QueriesCumplimiento";
import { obtenerCiclo } from "./Ciclos";
import { obtenerRegistrosCompraExternaSemana, obtenerRegistrosCompraExternaSemanaPorInvernadero } from "./Queries/CompraExterna";
import { getProducto } from "./ProductosYVariedades";
import { DeepClone, dosDecimales, getNombrePorID, getObjetoPorID, ordenarArreglaDeObjetosPorLlave } from "../util/functions";
import { getEsInvernaderoExternoPorID } from "./Invernaderos";


//////////////////////////////////////////
//////////////////////////////////////////
export const procesarDatosOrdenesVenta = (ordenesDeVentaSemana = [], invsFisicos = [], invernaderos = [], productoSeleccionado, sobrantesPasadas = [], clienteFiltros = [], empaqueFiltros = []) => {
    if (!productoSeleccionado?.id) { return {}; }
    const presentacionesDatos = { totalesCajas: [0, 0, 0, 0, 0, 0, 0], totalesKilos: [0, 0, 0, 0, 0, 0, 0], 
        totalesCajasSinRestar: [0, 0, 0, 0, 0, 0, 0], totalesKilosSinRestar: [0, 0, 0, 0, 0, 0, 0], };
    const invernaderosDatos = {};//Mejora inicializar con sobrante calculado, o por pasos
    const sobrantesFiltrados = filtrarSobrantePasada(sobrantesPasadas, invsFisicos, invernaderos, empaqueFiltros)
    presentacionesDatos.sobrantePasadaTotal = calcularSobrantePasadaTotal(sobrantesFiltrados, empaqueFiltros);

    const allItemsFiltrados = procesarYFiltrarItemsOrdenes(ordenesDeVentaSemana, invsFisicos, invernaderos, clienteFiltros, empaqueFiltros)
    //console.log("ordenes allItemsFiltrados - ANTES ORDENAR: ", allItemsFiltrados)
    ordenarPorNombrePresYFecha(allItemsFiltrados)
    //console.log("ordenes allItemsFiltrados - ANTES ORDENADOS: ", allItemsFiltrados)

    let arrMedleys = [];
    allItemsFiltrados.forEach(item => {
        //Obtener datos del orden de venta
        const { cajas_orden = 0, cajas_distribuidas = 0, distribuidos = 0, invernadero_ref, cliente, presentacion,/*Dato capturado*/
            fecha_actualizado, fecha_distribuido, fecha_envio } = DeepClone(item);

        const invernaderoObj = getObjetoPorID(getGlobal().invernaderosVirtuales, invernadero_ref)

        //Checar por inv medleys de presentaciones unicos
        const noHaAgregado = !arrMedleys.some(inv => inv.id === invernadero_ref);
        if (invernaderoObj.mezcla && !productoSeleccionado?.mezcla && noHaAgregado && productoSeleccionado?.id === presentacion.producto_ref) {
            arrMedleys.push(invernaderoObj);
        }

        //Initializar objetos si no tienen datos 
        ////////////////////////////////////////
        //Presentaciones
        if (!presentacionesDatos[presentacion.id]) { presentacionesDatos[presentacion.id] = { totales: { sumaKilos: 0, sumaCajas: 0, sumaDistribuidos: 0 } }; }
        if (!presentacionesDatos[presentacion.id][fecha_envio]) { presentacionesDatos[presentacion.id][fecha_envio] = { sumaKilos: 0, sumaCajas: 0, sumaDistribuidos: 0 }; }
        //invernaderos
        if (!invernaderosDatos[invernadero_ref]) { invernaderosDatos[invernadero_ref] = { 
            totalesCajas: [0, 0, 0, 0, 0, 0, 0], totalesKilos: [0, 0, 0, 0, 0, 0, 0],
            totalesCajasSinRestar: [0, 0, 0, 0, 0, 0, 0], totalesKilosSinRestar: [0, 0, 0, 0, 0, 0, 0],
         };
        }
        if (!invernaderosDatos[invernadero_ref][presentacion.id]) {
            invernaderosDatos[invernadero_ref][presentacion.id] = { totales: { sumaKilos: 0, sumaCajas: 0, sumaDistribuidos: 0 } };
        }
        if (!invernaderosDatos[invernadero_ref][presentacion.id][fecha_envio]) {
            invernaderosDatos[invernadero_ref][presentacion.id][fecha_envio] = { sumaKilos: 0, sumaCajas: 0, sumaDistribuidos: 0 };
        }

        //Obtener variables de items
        let sumaCajas = (!cajas_orden || isNaN(cajas_orden)) ? 0 : parseFloat(cajas_orden);
        let sumaCajasSinRestar = (!cajas_orden || isNaN(cajas_orden)) ? 0 : parseFloat(cajas_orden);

        const esOrdenUnicoPrestadoDeMedleys = productoSeleccionado.MEDLEYS_GENERAL && getObjetoPorID(getGlobal().productosYVariedades, presentacion.producto_ref)?.unico;
        
        const hayCajas = cajas_orden && !isNaN(parseFloat(cajas_orden));
        if(hayCajas && esOrdenUnicoPrestadoDeMedleys){
            sumaCajasSinRestar = 0;
        }
        if (esOrdenUnicoPrestadoDeMedleys) sumaCajas *= -1;

        const sumaKilos = convertirCajasPresentacionAKilos(sumaCajas, presentacion);
        const sumaKilosSinRestar = convertirCajasPresentacionAKilos(sumaCajasSinRestar, presentacion);
        const sumaDistribuidos = parseFloat(cajas_distribuidas || distribuidos);

        const fechaActualizado = ((presentacionesDatos[presentacion.id].fecha_actualizado || 0) > (fecha_actualizado || 0)) ? presentacionesDatos[presentacion.id].fecha_actualizado || 0 : fecha_actualizado || 0;
        const fechaDistribuido = presentacionesDatos[presentacion.id].fecha_distribuido || 0 > fecha_distribuido ? presentacionesDatos[presentacion.id].fecha_distribuido || 0 : fecha_distribuido || 0;
        const fechaEnvio = presentacionesDatos[presentacion.id].fecha_envio || 0 > parseFloat(fecha_envio) || 0 ? presentacionesDatos[presentacion.id].fecha_envio : parseFloat(fecha_envio) || 0
        //console.log("cumplimientoDatos  : ", presentacionesDatos)

        //Sumar cajas por dia - presentaciones
        presentacionesDatos[presentacion.id][fecha_envio].sumaCajas += sumaCajas;
        presentacionesDatos[presentacion.id][fecha_envio].sumaKilos += sumaKilos;
        presentacionesDatos[presentacion.id][fecha_envio].sumaDistribuidos += sumaDistribuidos;
        //Sumar totales de la semana - presentaciones
        presentacionesDatos[presentacion.id].totales.sumaCajas += sumaCajas;
        presentacionesDatos[presentacion.id].totales.sumaKilos += sumaKilos;
        presentacionesDatos[presentacion.id].totales.sumaDistribuidos += sumaDistribuidos;
        
        //Sumar totales de columna de dia - presentaciones
        const indiceDia = obtenerIndiceDiaSemana(fecha_envio);

        presentacionesDatos.totalesCajas[indiceDia] += sumaCajas;
        presentacionesDatos.totalesCajasSinRestar[indiceDia] += sumaCajasSinRestar;
        presentacionesDatos.totalesKilos[indiceDia] += sumaKilos;
        presentacionesDatos.totalesKilosSinRestar[indiceDia] += sumaKilosSinRestar;
        
        presentacionesDatos.totalesCajas[6] += sumaCajas;
        presentacionesDatos.totalesCajasSinRestar[6] += sumaCajasSinRestar;
        presentacionesDatos.totalesKilos[6] += sumaKilos;
        presentacionesDatos.totalesKilosSinRestar[6] += sumaKilosSinRestar;

        //Sumar cajas por dia - invernaderos
        invernaderosDatos[invernadero_ref][presentacion.id][fecha_envio].sumaCajas += sumaCajas;
        invernaderosDatos[invernadero_ref][presentacion.id][fecha_envio].sumaKilos += sumaKilos;
        invernaderosDatos[invernadero_ref][presentacion.id][fecha_envio].sumaDistribuidos += sumaDistribuidos;
        //Sumar totales de la semana - invernaderos
        invernaderosDatos[invernadero_ref][presentacion.id].totales.sumaCajas += sumaCajas;
        invernaderosDatos[invernadero_ref][presentacion.id].totales.sumaKilos += sumaKilos;
        invernaderosDatos[invernadero_ref][presentacion.id].totales.sumaDistribuidos += sumaDistribuidos;
        //Sumar totales de columna de dia - invernaderos
        invernaderosDatos[invernadero_ref].totalesCajas[indiceDia] += sumaCajas;
        invernaderosDatos[invernadero_ref].totalesCajasSinRestar[indiceDia] += sumaCajasSinRestar;
        invernaderosDatos[invernadero_ref].totalesKilos[indiceDia] += sumaKilos;
        invernaderosDatos[invernadero_ref].totalesKilosSinRestar[indiceDia] += sumaKilosSinRestar;
        invernaderosDatos[invernadero_ref].totalesCajas[6] += sumaCajas;
        invernaderosDatos[invernadero_ref].totalesCajasSinRestar[6] += sumaCajasSinRestar;
        invernaderosDatos[invernadero_ref].totalesKilos[6] += sumaKilos;
        invernaderosDatos[invernadero_ref].totalesKilosSinRestar[6] += sumaKilosSinRestar;

        //Agregar datos adicionales - presentaciones
        presentacionesDatos[presentacion.id] = {
            ...presentacionesDatos[presentacion.id], invernadero_ref, cliente, presentacion, nombrePresentacion: presentacion.presentacion,
            fecha_actualizado: fechaActualizado, fecha_distribuido: fechaDistribuido, fecha_envio: fechaEnvio,
        }
        //Agregar datos adicionales - invernaderos
        invernaderosDatos[invernadero_ref][presentacion.id] = {
            ...invernaderosDatos[invernadero_ref][presentacion.id], cliente, presentacion, fecha_envio: fechaEnvio,
            fecha_actualizado: fechaActualizado, fecha_distribuido: fechaDistribuido, nombrePresentacion: presentacion.presentacion,
        }
    })

    console.log("1. cumplimientoDatos  : ", { presentacionesDatos, invernaderosDatos, arrMedleys })
    setGlobal({ invernaderosUnicoMedleys: arrMedleys })
    return { presentacionesDatos, invernaderosDatos };
}
export const filtrarSobrantePasada = (sobrantesPasadas = [], invsFisicos = [], invsVirtuales = [], empaqueFiltros = []) => {
    return sobrantesPasadas.filter(sobrante => {
        const mismoInvernadero = invsVirtuales.some(inv => inv.id === sobrante.invernadero_ref);
        const mismoSitioEmpaque = empaqueFiltros.some(empaque => empaque.id === getEmpaquePorInvernaderoVirtualID(invsFisicos, invsVirtuales, sobrante.invernadero_ref));

        let invExterno = false;


        return mismoSitioEmpaque && mismoInvernadero || getObjetoPorID(invsVirtuales, sobrante.invernadero_ref).es_externo;
    });
}
export const calcularSobrantePasadaTotal = (sobrantes = [], empaqueFiltros = []) => {
    //console.log("SOBRANTE!!!")
    let suma = sobrantes?.reduce((acc, curr) => {
        //console.log("LORT TOTAL: ", curr.sobrante)
        if (curr.esExterno) {
            let sumaExterno = 0;
            //console.log("SOBRANTE LORT: ", { empaqueFiltros, sobrantes: curr?.sobrante, })
            empaqueFiltros.forEach(emp => {
                sumaExterno += parseInt(curr?.sobrante?.[emp.id] || 0);
            })
            return acc + sumaExterno;
        }
        else if (!empaqueFiltros.some(emp => emp.id === "sin")) return acc
        else return acc + parseInt(curr?.sobrante || 0)
    }, 0) || 0;

    return suma
}

export const getSobrantePasadaIncumplido = (sobrantes = [], invernaderoID = "") => sobrantes.find(sobra => sobra.invernadero_ref === invernaderoID)?.sobrante;

export const getSobrantePasada = (sobrantes = [], invsFisicos = [], invernadero = {}, empaqueFiltros = []) => {
    let sobrantesFiltrados = filtrarSobrantePasada(sobrantes, invsFisicos, [invernadero], empaqueFiltros);
    if (!sobrantesFiltrados?.length) { return "-"; }
    else if (invernadero.es_externo && sobrantesFiltrados[0]?.esExterno) {
        let sumaExterno = 0;
        //console.log("SOBRANTE LORT: ", { empaqueFiltros, sobrantes: sobrantesFiltrados[0]?.sobrante, })
        empaqueFiltros.forEach(emp => {
            sumaExterno += (sobrantesFiltrados[0]?.sobrante?.[emp.id] || 0);
        })
        return sumaExterno;
    }
    else if (invernadero.es_externo && sobrantesFiltrados?.length > 1 && !sobrantesFiltrados[1]?.esExterno) {
        let sumaExterno = 0;
        //console.log("SOBRANTE LORT: ", { empaqueFiltros, sobrantes: sobrantesFiltrados[0]?.sobrante, })
        empaqueFiltros.forEach(emp => {
            sumaExterno += (sobrantesFiltrados[1]?.sobrante?.[emp.id] || 0);
        })
        return sumaExterno;
    }
    else if (invernadero.es_externo && sobrantesFiltrados?.length > 0 && empaqueFiltros?.some(emp => emp.id === "sin")) {
        return sobrantesFiltrados[0]?.sobrante;
    }
    else if (invernadero.es_externo && sobrantesFiltrados?.length > 0 && !empaqueFiltros?.some(emp => emp.id === "sin")) {
        return 0;
    }
    else return sobrantesFiltrados[0]?.sobrante;

}

export const procesarYFiltrarItemsOrdenes = (ordenesDeVenta = [], invsFisicos = [], invsVirtuales = [], clienteFiltros = [], empaqueFiltros = []) => {
    const allItems = ordenesDeVenta.map(orden => {
        const itemsProcesados = procesarVentaItems(orden);
        const itemsFiltrados = filtrarVentaItems(itemsProcesados, invsFisicos, invsVirtuales, clienteFiltros, empaqueFiltros);//Respuesta es boolean
        return itemsFiltrados;
    })
    return allItems.flat();//Regresar 2d Array
}
export const procesarVentaItems = ({ items, no_orden, cliente, fecha_envio, fecha_actualizado, fecha_distribuido }) => {
    const ordenVentaDatos = { no_orden, cliente, fecha_envio, fecha_actualizado, fecha_distribuido };
    return items.map(itemDatos => ({ ...ordenVentaDatos, ...itemDatos }));
}
export const filtrarVentaItems = (ventaItems = [], invsFisicos = [], invsVirtuales = [], clienteFiltros = [], empaqueFiltros = []) => {
    const filtrados = ventaItems.filter(item => {
        const sitioEmpaqueDelItem = item?.empaque_ref || getEmpaquePorInvernaderoVirtualID(invsFisicos, getGlobal().invernaderosVirtuales, item.invernadero_ref) || "sin";
        if (sitioEmpaqueDelItem == "sin" && item.nombre_invernadero === "A4") {
            //console.log(`INVERNADERO - ${item.nombre_invernadero} - ; empaque: `, { sitioEmpaqueDelItem, item })
            //  console.log(`INVERNADERO - ${item.nombre_invernadero} - ; empaque: `,sitioEmpaqueDelItem)
        }
        const producto = getGlobal().activeProductoCumplimiento
        const mismoInvernadero = invsVirtuales.some(inv => inv.id === item.invernadero_ref
            || (!inv.mezcla
                && inv.producto_ref === item.presentacion.producto_ref
                && !producto?.mecla
                && getObjetoPorID(getGlobal().invernaderosVirtuales, item.invernadero_ref).mezcla));
        const mismoCliente = clienteFiltros.some(cliente => cliente.id === item.presentacion.cliente_ref);
        const mismoSitioEmpaque = empaqueFiltros.some(empaque => empaque.id === sitioEmpaqueDelItem);

        return mismoInvernadero && mismoCliente && mismoSitioEmpaque;
    })
    return filtrados;
}

export const obtenerDatosSobrantes = async (invernaderosDatos = [], ciclo, week) => {
    const promises = invernaderosDatos.map(inv => obtenerSobranteSemanaPasada(ciclo, inv.id, week.time))
    const sobrantesDatos = await Promise.all(promises)
    return sobrantesDatos;
}

export const keyTabla = (llave = "a", index = 0, prefix = "inv", producto = {}, week = {}) => `${prefix}-${llave}-${index}-${producto.id || ""}-${week.time}`;

const hayDatosCosechaPorInvernaderos = (invernaderos, proyectado) => invernaderos?.some(inv => proyectado.invernadero?.id === inv.id);
export const procesarProyectado = (proyectados, invernaderos = []) => {
    let proyeccionCosecha = [0, 0, 0, 0, 0, 0];
    const proyectadosFiltrados = proyectados.filter(proyectado => hayDatosCosechaPorInvernaderos(invernaderos, proyectado));

    proyectadosFiltrados.forEach((inv) => {
        inv.proyeccion.forEach((dia, index) => {
            const proyeccionCosechaDia = parseFloat(dia.valor);
            if (!isNaN(proyeccionCosechaDia)) { proyeccionCosecha[index] += proyeccionCosechaDia; }
        })
    })
    return proyeccionCosecha;
}

export const procesarFinal = (totalesKilosArray, proyectado, pasadaTotal = 0) => {
    let kilosFinal = [0, 0, 0, 0, 0, 0];

    totalesKilosArray.forEach((kilosVentasDia, index) => {
        if (index === 0) {//1. LUNES - Iniciar con la sobrante de la semana pasada
            kilosFinal[0] = pasadaTotal/*Sobrante pasada*/ + proyectado[0]/*Proyeccion de cosecha*/ - kilosVentasDia/*Kilos vendidos*/ + (+0);
        }
        else if (index < 6) {//2. MARTES A Sabado - Iniciar con la sobrante del día anterior
            const sobranteDiaAnterior = kilosFinal[index - 1];
            kilosFinal[index] = sobranteDiaAnterior/*Sobrante (kilosFinal) del dia anterior*/ + proyectado[index] - kilosVentasDia;
        }
    })
    return kilosFinal
}
export const procesarFinalCards = (totalesKilosArray, proyectado=[], pasadaTotal = 0,invernaderos=[]) => {
    let kilosFinal = [0, 0, 0, 0, 0, 0];
let arreglo = totalesKilosArray || kilosFinal 

const proyectadoInv = procesarProyectado(proyectado, invernaderos);

//console.log("22 A--: cumplimientoProductoDatos:", {arreglo, totalesKilosArray, proyectado, proyectadoInv, pasadaTotal})

    arreglo.forEach((kilosVentasDia, index) => {
        if (index === 0) {//1. LUNES - Iniciar con la sobrante de la semana pasada
            kilosFinal[0] = pasadaTotal/*Sobrante pasada*/ + proyectadoInv[0]/*Proyeccion de cosecha*/ - kilosVentasDia/*Kilos vendidos*/;
            //console.log("22 eeee111--: ready:", {pasadaTotal, first: proyectadoInv[0], kilosVentasDia})
        }
        else if (index < 6) {//2. MARTES A Sabado - Iniciar con la sobrante del día anterior
            const sobranteDiaAnterior = kilosFinal[index - 1];
            //console.log("22 eeee222--: ready:", {pasadaTotal, first: proyectadoInv[0], kilosVentasDia})
            kilosFinal[index] = sobranteDiaAnterior/*Sobrante (kilosFinal) del dia anterior*/ + proyectadoInv[index] - kilosVentasDia;
        }
        //console.log("22 eeee--: ready:", {kilosFinal, kilosVentasDia})
    })
    //console.log("22 B--: cumplimientoProductoDatos:", {totalesKilosArray, proyectado, pasadaTotal})
    return kilosFinal
}

//////////////////////////////////////////
//////////////////////////////////////////


export const obtenerResumenCumplimiento = async (ciclo, semana, invernaderosVirtuales = [], presentaciones = [], clientes = [], ordenesDeSemana) => {
    try {
        let result = await obtenerProyectadoParaCumplimiento(ciclo, invernaderosVirtuales, [], semana);
        //console.log("obtenerProyectadoParaCumplimiento : ", result)
        return result;

    } catch (err) {
        throw err;
    }
}

export const obtenerProyectadoParaCumplimiento = async (ciclo, invernaderos, semana) => {
    const proyectado = await obtenerProyectado(ciclo, invernaderos, semana, []);
    const incumplidos = await obtenerIncumplidos(ciclo, invernaderos, semana, proyectado);
    //console.log("obtenerProyectadoParaCumplimiento - ", { proyectado, incumplidos })
    return { proyectado, incumplidos };
}

export const obtenerProyectado = async (ciclo, invernaderos, semana, productosBases, medleysGeneral) => {
    let promises = invernaderos.map(invernadero => obtenerProyectadoInvernadero(ciclo, invernadero, semana, productosBases, medleysGeneral));
    return await Promise.all(promises);
}

export const obtenerIncumplidos = (invernaderos, semana, proyectado, ordenesVenta, sobrantes = [], clientesFiltro = [], sitiosEmpaqueFiltro = []) => {
    const incumplidos = {};
    invernaderos.forEach(invernadero => {
        const sobrantePasado = getSobrantePasadaIncumplido(sobrantes, invernadero.id);
        const incumplidosDelInv = encontrarIncumplidosInvernadero(invernadero, semana, proyectado, ordenesVenta, sobrantePasado, clientesFiltro, sitiosEmpaqueFiltro);
        incumplidos[invernadero.id] = incumplidosDelInv;
    });
    return incumplidos;
}
export const encontrarIncumplidosInvernadero = (invernadero, semana, proyectado, ordenes, sobrantePasado, clientesFiltro, sitiosEmpaqueFiltro) => {
    let dias = obtenerDiasDeSemana(semana), incumplidos = [[], [], [], [], [], []];
    let { proyeccion = [] } = proyectado.find(proy => proy.invernadero.id === invernadero.id);
    let allItems = ordenes.map(orden => orden.items.map(item => ({ ...orden, ...item }))).flat()
    ordenarPorNombrePresYFecha(allItems)

    let restante = sobrantePasado;
    dias.forEach((dia, index) => {

        restante += proyeccion[index].valor;
        const allItemsDelDia = allItems.filter(item => item.fecha_envio == dia)
        allItemsDelDia.forEach(item => {
            if (item.invernadero_ref == invernadero.id) {
                //if (item.empaque_ref) console.log("ITEM EMPAQUE:: ", { NAME: invernadero.nombre, restante, item })
                restante -= parseFloat(item.cajas_orden) * parseFloat(item.presentacion.peso_neto_caja);
                if (restante < 0) { incumplidos[index].push(item.presentacion_ref); }
            }
        })
    })
    //if (invernadero.nombre === "I1") console.log("INCUMP END encontrarIncumplidosInvernadero END ", { NAME: invernadero.nombre, incumplidos })
    return incumplidos;
}

export const ordenarPorNombrePresYFecha = (allItems = []) => {
    allItems.sort((a, b) => {
        let orderPres = a.presentacion.presentacion > b.presentacion.presentacion ? 1 : -1
        if (a.presentacion.presentacion === b.presentacion.presentacion) { orderPres = 0; }
        else return orderPres;

        const orderFecha = a.fecha_envio > b.fecha_envio ? 1 : -1
        return orderPres || orderFecha;
    })
    return allItems;
}



const obtenerProyectadoInvernadero = async (ciclo, invernadero, semana, productos, medleysGeneral) => {
    try {
        let promises = [obtenerPesoCajaParaSemana(ciclo, invernadero.id, semana, parseFloat(invernadero.peso_caja_cosecha))];
        if (invernadero?.es_externo) { promises.push(obtenerRegistrosCompraExternaSemanaPorInvernadero(ciclo, invernadero.id, semana)); }
        else promises.push(obtenerRegistrosSemana(ciclo, invernadero.id, semana));

        promises.push(obtenerValoresRealesParaCumplimiento(ciclo, invernadero.id, semana));
        promises.push(obtenerkgm2semanal(ciclo, invernadero.id, semana - 7 * 24 * 60 * 60));
        let [pesoCaja, registros, reales, kgm2semanal] = await Promise.all(promises);

        let producto = getObjetoPorID(productos, invernadero.producto_ref).mezcla ? medleysGeneral : getObjetoPorID(productos, invernadero.producto_ref);
        let packout = await getPackoutSemanal(ciclo, invernadero, (semana - segundosSemana), kgm2semanal);
        if (isNaN(packout)) { packout = 1; }
        packout = (producto.mezcla || producto.MEDLEYS_GENERAL) ? medleysGeneral?.packout_respaldo : producto.packout_respaldo;


        let dias = obtenerDiasDeSemana(semana), proyeccion = [];
        dias.forEach(dia => {
            if (invernadero?.es_externo) { proyeccion.push(obtenerProyectadoExternoDia(registros, reales, dia, pesoCaja, packout)); }
            else { proyeccion.push(obtenerProyectadoDia(registros, reales, dia, pesoCaja, packout)); }
        })

        return { proyeccion, invernadero };
    } catch (err) {
        console.log("ERROR obtenerProyectadoInvernadero: ", err);
        return { proyeccion: [], invernadero }
    }
}

const obtenerProyectadoDia= (registros, reales, dia, pesoCaja, packoutSemanaPasada) => {//console.log("Cumpli registros ",registros)
    let indice = obtenerIndiceDiaSemana(dia);
    let hoy = obtenerInicioDia(Math.round(Date.now() / 1000));
    let hora = obtenerHora(hoy);
    let registro = registros.find(el => el && el.dia === dia);
    if (dia === hoy) {
        if (hora < horaCorte) {
            if (registro && !isNaN(parseFloat(registro["diaria"]))) {
                return { esReal: false, valor: parseFloat(registro["diaria"]) * pesoCaja, tipo: "diario" };
            } else {
                return { esReal: false, valor: 0, tipo: "diario" };
            }
        } else {
            return { esReal: true, valor: packoutSemanaPasada * reales[indice], packout: packoutSemanaPasada, real: reales[indice], tipo: "real" };
        }
    } else if (dia > hoy) {
        if (registro) {
            let diario = parseFloat(registro["diaria"]);

            if (!isNaN(diario)) {
                return { esReal: false, valor: diario * pesoCaja, tipo: "diario" };
            } else if (!isNaN(parseFloat(registro["semanal"]))) {
                return { esReal: false, valor: parseFloat(registro["semanal"]) * pesoCaja, tipo: "semanal" };
            } else {
                return { esReal: false, valor: 0, tipo: "semanal" };
            }
        } else {
            return { esReal: false, valor: 0, tipo: "semanal" };
        }
    } else {
        return { esReal: true, valor: packoutSemanaPasada * reales[indice], packout: packoutSemanaPasada, real: reales[indice], tipo: "real" };;
    }
}

const obtenerProyectadoDiaSemanal = (registros, reales, dia, pesoCaja, packoutSemanaPasada) => {console.log("Cumpli registros ",registros)
    let indice = obtenerIndiceDiaSemana(dia);
    let hoy = obtenerInicioDia(Math.round(Date.now() / 1000));
    let hora = obtenerHora(hoy);
    let registro = registros.find(el => el && el.dia === dia);
    console.log("Cumpli norm semanales ", registros)
    if (!registro?.semanal || isNaN(parseFloat(registro?.["semanal"] || 0))) { return { esReal: false, valor: 0, tipo: "semanal" }; }
    else if(registro?.semanal && !isNaN(parseFloat(registro?.["semanal"] || 0)))return { esReal: false, valor: parseFloat(registro?.["semanal"] || 0) * pesoCaja, tipo: "semanal" };
    else return { esReal: false, valor: 0, tipo: "semanal" };
    
}
const obtenerProyectadoExternoDia = (registros, reales, dia, pesoCaja, packoutSemanaPasada) => {
    let indice = obtenerIndiceDiaSemana(dia);
    let hoy = obtenerInicioDia(Math.round(Date.now() / 1000));
    let hora = obtenerHora(hoy);

    let registrosDia = registros[obtenerIndiceDiaSemana(dia)];
    let result = { valor: 0, tipo: "diario" }
    if (hora < horaCorte) {
        result["esReal"] = false
    } else {
        result["esReal"] = true

    }






    //si el dia ya paso se toma el real, no es necesario iterar en registros diarios de cosecha
    if ((dia === hoy && hora > horaCorte) || hoy > dia) {
        return { esReal: true, valor: packoutSemanaPasada * reales[indice], packout: packoutSemanaPasada, real: reales[indice], tipo: "real" };
    }
    registrosDia.forEach(registro => {



        if (dia === hoy) {
            if (hora < horaCorte) {
                if (registro && !isNaN(parseFloat(registro["datoHTG"]))) {
                    result["valor"] += parseFloat(registro["datoHTG"]);
                    return { esReal: false, valor: parseFloat(registro["diaria"]), tipo: "diario" };
                } else {
                    return { esReal: false, valor: 0, tipo: "diario" };
                }
            } else {
                result["valor"] += (packoutSemanaPasada * reales[indice]);
                return { esReal: true, valor: packoutSemanaPasada * reales[indice], packout: packoutSemanaPasada, real: reales[indice], tipo: "real" };
            }
        } else if (dia > hoy) {
            if (registro) {
                let diario = parseFloat(registro["datoHTG"]);
                if (!isNaN(diario)) {
                    result["valor"] += diario;
                    return { esReal: false, valor: diario, tipo: "diario" };
                } else if (!isNaN(parseFloat(registro["semanal"]))) {
                    result["valor"] += parseFloat(registro["semanal"]);
                    return { esReal: false, valor: parseFloat(registro["semanal"]), tipo: "semanal" };
                } else {
                    return { esReal: false, valor: 0, tipo: "semanal" };
                }
            } else {
                return { esReal: false, valor: 0, tipo: "semanal" };
            }
        } else {
            result["valor"] += packoutSemanaPasada * reales[indice];
            return { esReal: true, valor: packoutSemanaPasada * reales[indice], packout: packoutSemanaPasada, real: reales[indice], tipo: "real" };;
        }

    })
    return result;
}

export const obtenerMatrizComparativa = async (cicloId, invernaderos, semana, ordenesSemana) => {
    let ordenes = ordenesSemana || await obtenerOrdenesSemana(cicloId, semana);
    let presentaciones = await obtenerPresentaciones();
    let reportesCosecha = await obtenerReporteCosecha(cicloId, semana);
    let result = [], sumas = {};

    invernaderos.forEach(invernadero => {
        sumas[invernadero.id] = 0;
    })
    ordenes.forEach(orden => {
        orden.items.forEach(item => {
            let presentacion = presentaciones.find(el => el.id === item.presentacion_ref);
            sumas[item.invernadero_ref] += parseFloat(item.cajas_orden) * parseFloat(presentacion.peso_neto_caja);

        })
    })

    invernaderos.forEach(invernadero => {
        let row = { ...invernadero, invernadero };
        let reporteCosecha = reportesCosecha.find(reporte => reporte[0]["id"] === invernadero.id);
        let kilosRealRow = reporteCosecha[3]["real"];

        invernaderos.forEach(colInvernadero => {
            let reporteCosechaCol = reportesCosecha.find(reporte => reporte[0]["id"] === colInvernadero.id);
            let kilosRealCol = reporteCosechaCol[3]["real"];

            row[colInvernadero["id"]] = {
                renglonReal: kilosRealRow,
                renglonFinal: sumas[invernadero.id],
                columnaFinal: sumas[colInvernadero.id],
                columnaReal: kilosRealCol
            }
        })
        result.push(row);
    })
    return result;
}
export const obtenerSobrantePorSemanaCiclo = async (cicloId, invernaderos) => {


    let sobrantes = await obtenerSobrantesPorSemana(cicloId);
    let cicloObj = await obtenerCiclo(cicloId);
    let semanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
    let result = invernaderos.map(invernadero => {
        let sobrantesInvernadero = obtenerSobrantePorSemanaInvernadero(invernadero.id, sobrantes, semanas);
        return {
            idInvernadero: invernadero.id,
            nombre: invernadero.nombre,
            sobrantes: sobrantesInvernadero,
            color: invernadero.color
        }
    })
    return result;
}
const obtenerSobrantePorSemanaInvernadero = (invernaderoId, sobrantes, semanas) => {
    let result = semanas.map(semana => {
        let obj = { semana: semana.time, sobrante: 0 }
        let sobrante = sobrantes.find(el => el.invernadero_ref === invernaderoId && el.semana === semana.time);
        if (sobrante) {
            obj["sobrante"] = sobrante["sobrante"]
        }
        return obj
    })
    return result;
}
export const convertirCajasPresentacionAPallets = (cajas = 0, presentacion = {}) => {
    const numCajasPorPallet = presentacion.numero_cajas_pallet;
    if (!numCajasPorPallet || isNaN(numCajasPorPallet)) { return 0; }

    return dosDecimales(cajas / numCajasPorPallet);
}
export const convertirCajasPresentacionAKilos = (sumaCajas = 0, presentacionObj) => {
    if (!presentacionObj) { return 0; }
    const sumaKilos = parseFloat(sumaCajas) * parseFloat(presentacionObj.peso_neto_caja);
    return sumaKilos;
}
//454