  import React,{useState, useEffect, useGlobal} from 'reactn';
import { obtenerUsuarios , togglearBloqueo} from '../../service/Usuarios';
import {  Button} from 'primereact/button';
const Bloqueos = (params) => {
    const [usuarios, setUsuarios] = useState([]);
    const [usuario, setUsuario] = useGlobal("usuario");
    const [, setMostrarModalPermiso] = useGlobal("mostrarModalPermiso");
    const [nombreModulo, setNombreModulo] = useGlobal("nombreModulo");
    useEffect(() => {
        setNombreModulo('Bloqueos')
        obtenerUsuarios().then((result) => {
            setUsuarios(result);
        }).catch((err) => {
            console.log("ERROR OBTENER USUARIOS");
            console.log(err);
            if(err.code && err.code === "permission-denied"){
                setMostrarModalPermiso(true);
                console.log(err.code);
              }
        });
        
    }, [])
    const togglearUsuario = (id) => {
        togglearBloqueo(usuarios,id).then((result) => {
            let copia = usuarios.map(el=>({...el}));
            let usuario = copia.find(el=>el.id ===id);            
            usuario.bloqueado = usuario.bloqueado?false:true;
            setUsuarios(copia);
        }).catch((err) => {
            console.log("OCURRIO UN ERROR");
            console.log(err);
        });;
    }
    const esAdmin = (params) => {
        return usuario && usuario.rol == "Administrador";
    }
    
    return <div className="p-grid p-justify-center">

        <div className="p-col p-col-auto title-container">
          
          <span className="icon-general user-icon"></span>
          <span className="titles-group">
           
                <h1 className="header-title">
                  Bloqueos de usuarios
                </h1>
                
             

          </span>
        </div>
        <div className="p-col p-col-12">
        { usuarios.map((el,index)=>{
            return <div className="p-grid" key={index}>
                      <div className="p-col p-col-12">
                        {el.displayName}
                          <Button  className="ml-1"  onClick = {()=>togglearUsuario(el.id)} label = {el.bloqueado?"Desbloquear":"Bloquear"}/>
                      </div>
                  </div>
        })}
        </div>
    </div>
}
export default Bloqueos;