import React, { useState, useGlobal, useEffect } from 'reactn';
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

import { usePrimeReactTable } from "../common/tableHandler";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Message } from 'primereact/message';
import { guardarCalidadInicial, obtenerRegistroInicial, obtenerRegistrosCalidadInicialDia, obtenerFiltrosCalidadTodos } from '../../service/Calidad';
import { noHaSidoClausurado } from '../../service/Ciclos';
import { obtenerInicioDia } from '../../service/fechas';
import SeccionFormulario from '../common/SeccionFormulario';
import ContenedorInput from '../common/ContenedorInput';
import { getProductoYVariedadString, getObjetoPorID, DeepClone, noHayVariosPuntos, sumarNumerosArrayPorCampo, checkIfStringIsNumber } from '../../util/functions';
import ContenedorHeader from '../common/ContenedorHeader';
import ModalSeleccionarDefectos from './ModalSeleccionarDefectos';
import icono from '../../images/icons/external-icon.svg';
import { TIPO_CANTIDAD } from 'service/constants/ProductosYVariedades.const';

const inicial = {
  peso_real_caja: "",
  peso_promedio_fruto: "",
  numero_racimos: "",
  frutos_racimo: "",
  comentario: "",
  no_racimos: { 4: "", 5: "", 6: "" },
  defectos: [],
  cavidad: "",
  subVariedad_ref: "",
  proveedor: ""
}
const PRODUCTOS_CON_RACIMOS = ["TOV", "CHERRY", "COCKTAIL"];
const PRODUCTOS_CON_CAVIDAD = ["BEEF"];
const RegistroInicial = (props) => {
  let inicialColoracion = [{ color1: "", color2: "", color3: "", color4: "", color5: "", color6: "" }]
  let inicialBrix = [{ color1: "", color2: "", color3: "", color4: "", color5: "", color6: "" }]
  const [state, setState] = useState(DeepClone(inicial));
  const [invernadero] = useGlobal("invernadero");
  const [, setNombreModulo] = useGlobal("nombreModulo");
  const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
  const [productosYVariedades] = useGlobal("productosYVariedades")
  const [ciclo,] = useGlobal("ciclo")
  const [ciclos,] = useGlobal("ciclos")
  const [, setCargando] = useGlobal("cargando")
  const [editorGeneratorColor, currentColor, setCurrentColor] = usePrimeReactTable(DeepClone(inicialColoracion), () => { })
  const [editorGeneratorBrix, currentBrix, setCurrentBrix] = usePrimeReactTable(DeepClone(inicialBrix), () => { })
  const [defectos] = useGlobal("defectos");
  const [defectosEditados, setDefectosEditados] = useState([]);
  const [defectosSeleccionados, setDefectosSeleccionados] = useState([]);
  const [mostrarModalDefectos, setMostrarModalDefectos] = useState(false)
  const [errores, setErrores] = useState([]);
  const [usuario,] = useGlobal("usuario")
  const [editing] = useState(props.match.params.registroID ? true : false)
  const [registroID] = useState(props.match.params.registroID)
  const [datosCargados] = useGlobal("datosCargadosCalidad")

  const [mezclaVariedades, setMezclaVariedades] = useState(undefined);
  const [proveedores] = useGlobal("proveedores");
  const [producto, setProducto] = useState("");
  const [proveedor] = useGlobal("proveedorExternoCalidad");
  const [cantidadFrutosTotales, setCantidadFrutosTotales] = useState(0);
  const [sumaDefectos, setSumaDefectos] = useState(0);

  useEffect(() => {
    setHabilitadoSelectorCiclo(false);
    setNombreModulo("Calidad")
  }, [])
  useEffect(() => {
    if (proveedor && proveedor.id !== "todos" && state.proveedor !== proveedor.id) { setState({ ...state, proveedor: proveedor.id }); }
  }, [proveedor])

  useEffect(() => {
    setCargando(true);
    if (!datosCargados && invernadero) { obtenerFiltrosCalidadTodos(ciclo); }
    else if (datosCargados, productosYVariedades) { obtenerDatosIniciales(); }
  }, [invernadero, datosCargados, productosYVariedades])
  useEffect(() => {
    setErrores([]);
  }, [state, defectosSeleccionados, currentColor, currentBrix])

  //Calcular cantidad de frutos totales si es producto con tipo calidad inicial 'cantidad'
  useEffect(() => {
    const { peso_real_caja, peso_promedio_fruto } = state;
    if (parseFloat(peso_real_caja) && parseFloat(peso_promedio_fruto) && producto.tipo_calidad_inicial === TIPO_CANTIDAD) {
      const totalFrutos = peso_real_caja / peso_promedio_fruto;
      const cantidadFrutosTotales = Math.round(totalFrutos);
      setCantidadFrutosTotales(cantidadFrutosTotales);
    }
    else setCantidadFrutosTotales(0)
  }, [state, producto])
  //Sumar total de frutos con defectos o total de pesos de frutos con defectos
  useEffect(() => {
    if (defectosSeleccionados?.length > 0) {
      const sumaDefectos = sumarNumerosArrayPorCampo(defectosSeleccionados, "valor");
      setSumaDefectos(sumaDefectos);
    }
    else setSumaDefectos(0)
  }, [state, defectosSeleccionados])

  useEffect(() => {
    if (defectos) {
      let editados = defectos.map(def => ({ ...def, defecto_ref: def.id, valor: getObjetoPorID(defectosSeleccionados, def.id).valor || "" }))
      setDefectosEditados(editados)
    }
  }, [defectosSeleccionados])
  const puedeEditar = () => noHaSidoClausurado(ciclo, ciclos) && usuario.rol != "Auxiliar Empaque" && invernadero && (!invernadero.es_externo || proveedor?.habilitado) && (invernadero.habilitado || invernadero.es_externo)
  const obtenerDatosIniciales = async () => {
    try {
      const producto = getObjetoPorID(productosYVariedades, invernadero.producto_ref);
      const mezclaVariedades = producto.mezcla ? producto.variedades : undefined
      setProducto(producto)
      setMezclaVariedades(mezclaVariedades)

      if (editing) {
        let registroInicial = await obtenerRegistroInicial(ciclo, props.match.params.invernaderoID, registroID)
        if (!registroInicial)
          props.history.goBack()
        else
          procesarRegistroObtenido(registroInicial)
      }
      else {
        let defectosRegistrados = await obtenerDefectosAnteriores()
        setDefectosSeleccionados(defectosRegistrados);
      }

    } catch (error) {
      console.log("ERROR obtener: ", error)
    }

    setCargando(false);
  }
  const procesarRegistroObtenido = (registro) => {
    setCurrentColor(registro.coloracion)
    setCurrentBrix(registro.brix)
    setState(registro)

    let defectosAAgregar = registro.defectos.map(defecto => {
      let obj = getObjetoPorID(defectos, defecto.defecto_ref)
      return { ...defecto, ...obj }
    })
    setDefectosSeleccionados(defectosAAgregar);
  }
  const obtenerDefectosAnteriores = async () => {
    let diaActual = obtenerInicioDia(Math.round(Date.now() / 1000));
    let registros = await obtenerRegistrosCalidadInicialDia(ciclo, invernadero.id, diaActual)

    let defectosRegistradosIDs = []
    registros.forEach(registro => {
      defectosRegistradosIDs.push(...registro.defectos.map(el => el.defecto_ref))
    })

    let defectosRegistrados = defectos.filter(def => defectosRegistradosIDs.includes(def.id))
    defectosRegistrados = defectosRegistrados.map(obj => ({ ...obj, defecto_ref: obj.id, valor: "" }))
    return defectosRegistrados
  }

  const handleInputChange = (ev) => {
    const copia = DeepClone(state)
    copia[ev.target.name] = ev.target.value
    setState({ ...state, [ev.target.name]: ev.target.value });
  }
  const handleInputDefecto = (index, ev) => {
    let copia = DeepClone(defectosSeleccionados)
    //Para no poder tener varios puntos en los inputs
    if (noHayVariosPuntos(ev.target.value)) {
      copia[index].valor = ev.target.value;
      setDefectosSeleccionados(copia);
    }
  }
  const handleInputRacimos = (clave, valor) => {
    let copia = DeepClone(state)
    copia.no_racimos[clave] = valor
    setState(copia)
  }

  const guardar = async () => {
    if (validar()) {
      setCargando(true)
      try {
        let datos = {
          ...state,
          coloracion: currentColor,
          brix: currentBrix,
          defectos: defectosSeleccionados.map(def => ({ defecto_ref: def.id, valor: def.valor })),
          momento: editing ? state.momento : Math.round(Date.now() / 1000)
        }
        await guardarCalidadInicial(ciclo, invernadero.id, datos, registroID)
        setCargando(false)
        props.history.goBack()
      }
      catch (error) {
        console.log("ERROR GUARDAR: ", error);
        setCargando(false)
      }
    }
  }
  const validar = () => getErroresFormulario().length === 0
  const getErroresFormulario = () => {
    let errores = []
    let errorestateNombres = ["peso_real_caja", "peso_promedio_fruto"]
    errores = errorestateNombres.filter(nombre => !state[nombre])
    errorestateNombres.forEach((nombre) => {
      if(!state[nombre]) return
      if(!checkIfStringIsNumber(state[nombre])){
        errores.push(`${nombre}_string`)
      }
    })
    if(state["numero_racimos"]){
      if(!checkIfStringIsNumber(state["numero_racimos"])){
        errores.push(`numero_racimos_string`)
      }
    }
    if(state["frutos_racimo"]){
      if(!checkIfStringIsNumber(state["frutos_racimo"])){
        errores.push(`frutos_racimo_string`)
      }
    }
    let colors = [1, 2, 3, 4, 5, 6]
    colors.forEach(num => {
      if(!checkIfStringIsNumber(currentColor[0][`color${num}`])){
        if(currentColor[0][`color${num}`]){
          errores.push(`inicialColoracion_string`)
        }
      }
      if (!currentColor[0][`color${num}`]) { errores.push("inicialColoracion"); }
    })

    if(state.no_racimos["4"]) { 
      if(!checkIfStringIsNumber(state.no_racimos["4"])) { errores.push("racimos_string_4"); }
    }
    if(state.no_racimos["5"]) { 
      if(!checkIfStringIsNumber(state.no_racimos["5"])) { errores.push("racimos_string_5"); }
    }
    if(state.no_racimos["6"]) { 
      if(!checkIfStringIsNumber(state.no_racimos["6"])) { errores.push("racimos_string_6"); }
    }
    const gradosBrixValores = Object.values(currentBrix[0])
    gradosBrixValores.forEach((value) => {
      if (!checkIfStringIsNumber(value)) errores.push("gradosBrix_string")
    })

    defectosSeleccionados.forEach(defecto => {
      if (!defecto.valor) { errores.push("defectos"); }
      if (!checkIfStringIsNumber(defecto.valor)) { errores.push("defectos_string"); }
    })
    if (checarSumaDefectosExcedido() && !errores.includes("defectos") && !errores.includes("peso_real_caja") && !errores.includes("peso_promedio_fruto")) {
      errores.push("sumaDefectos");
    }

    if (!editing && invernadero.es_externo && !state.proveedor) { errores.push("proveedor"); }
    setErrores(errores)
    console.log({errores})
    return errores;
  }

  const checarSumaDefectosExcedido = () => {
    let haExcedido = false;
    const valorAComparar = producto.tipo_calidad_inicial === "PESO" ? state.peso_real_caja : cantidadFrutosTotales;
    defectosSeleccionados.forEach(defecto => {
      if (parseFloat(defecto.valor) > parseFloat(valorAComparar)) { haExcedido = true; }
    })
    return haExcedido;
  }

  return (
    <div className="p-grid p-justify-center">
      {invernadero && <ContenedorHeader col="p-col p-col-auto" titulo="Registro de calidad inicial" subtituloInv={getProductoYVariedadString(invernadero)}
        invernaderoNombre={invernadero.es_externo ? <img width="60" alt="icono" src={icono} /> : invernadero.nombre}
        iconos={`icon-general icon-id bor-${invernadero.color} bg-${invernadero.color} ${invernadero.habilitado ? "" : "Deshabilitado"}`} atras={props.history.goBack} />}

      <div className="p-col-12">
        <p className="section-title">Información general</p>
        <div className="card">
          <div className="p-grid">
            {invernadero && invernadero.es_externo && <div className="form-group p-col-12 p-md-6">
              <label htmlFor="proveedor">Proveedor externo</label>
              <Dropdown optionLabel="nombre" optionValue="id" name="proveedor" value={state.proveedor} options={proveedores} onChange={handleInputChange} disabled={!puedeEditar()} />
              {errores.includes("proveedor") && <Message severity="error" text="Todos los defectos deben tener un valor" />}
            </div>}

            <div className="form-group p-col-12 p-md-6">
              <label htmlFor="peso_real_caja">Peso real de la caja (kg)</label>
              <InputText id="peso_real_caja" name="peso_real_caja" type='number' keyfilter="pnum" value={state.peso_real_caja} onChange={handleInputChange} disabled={!puedeEditar()} />
              {errores.includes("peso_real_caja") && <Message severity="error" text="Este campo es requerido" />}
              {errores.includes("peso_real_caja_string") && <Message severity="error" text="Este campo solo acepta números" />}
            </div>
            <div className="form-group p-col-12 p-md-6">
              <label htmlFor="peso_promedio_fruto">Peso del fruto (kg)</label>
              <InputText id="peso_promedio_fruto" type='number' keyfilter="pnum" name="peso_promedio_fruto" value={state.peso_promedio_fruto} onChange={handleInputChange} disabled={!puedeEditar()} />
              {errores.includes("peso_promedio_fruto") && <Message severity="error" text="Este campo es requerido"></Message>}
              {errores.includes("peso_promedio_fruto_string") && <Message severity="error" text="Este campo solo acepta números" />}
            </div>

            {producto.tipo_calidad_inicial !== "PESO" &&
              <React.Fragment>
                <div className="form-group p-col-12 p-md-6">
                  <label htmlFor="numero_racimos">Número de racimos</label>
                  <InputText id="numero_racimos" name="numero_racimos" type='number' value={state.numero_racimos} onChange={handleInputChange}
                    disabled={!puedeEditar()} keyfilter="pnum" />
                  {errores.includes("numero_racimos") && <Message severity="error" text="Este campo es requerido"></Message>}
                  {errores.includes("numero_racimos_string") && <Message severity="error" text="Este campo solo acepta números" />}
                </div>
                <div className="form-group p-col-12 p-md-6">
                  <label htmlFor="frutos_racimo">Frutos por racimo</label>
                  <InputText id="frutos_racimo" name="frutos_racimo" type='number' value={state.frutos_racimo} onChange={handleInputChange}
                    disabled={!puedeEditar()} keyfilter="pnum" />
                  {errores.includes("frutos_racimo") && <Message severity="error" text="Este campo es requerido"></Message>}
                  {errores.includes("frutos_racimo_string") && <Message severity="error" text="Este campo solo acepta números" />}
                </div>
              </React.Fragment>}

            {producto && PRODUCTOS_CON_CAVIDAD.includes(producto.nombre) &&
              <ContenedorInput col="12" md="6" sm="4" label="Cavidad">
                <InputText value={state.cavidad} keyfilter="pnum" type='number' onChange={handleInputChange} keyfilter="num" name="cavidad" />
              </ContenedorInput>}
            {mezclaVariedades && <div className="form-group p-col-12 p-md-6">
              <label htmlFor="subVariedad_ref">Variedad</label>
              <Dropdown optionLabel="nombre" optionValue="id" name="subVariedad_ref" value={state.subVariedad_ref} options={mezclaVariedades} onChange={handleInputChange}
                disabled={!puedeEditar()} />
            </div>}
          </div>
        </div>
      </div>
      <div className="p-col-12">
        <p className="section-title">
          {producto && producto.tipo_calidad_inicial === "PESO" ? "Kg" : "Cantidad"} de frutos por coloración</p>
        <div className="card p-0">
          <DataTable value={currentColor}>
            <Column field="color1" header="1" editor={(props) => { return puedeEditar() ? editorGeneratorColor(props, "color1") : "No editable" }} />
            <Column field="color2" header="2" editor={(props) => { return puedeEditar() ? editorGeneratorColor(props, "color2") : "No editable" }} />
            <Column field="color3" header="3" editor={(props) => { return puedeEditar() ? editorGeneratorColor(props, "color3") : "No editable" }} />
            <Column field="color4" header="4" editor={(props) => { return puedeEditar() ? editorGeneratorColor(props, "color4") : "No editable" }} />
            <Column field="color5" header="5" editor={(props) => { return puedeEditar() ? editorGeneratorColor(props, "color5") : "No editable" }} />
            <Column field="color6" header="6" editor={(props) => { return puedeEditar() ? editorGeneratorColor(props, "color6") : "No editable" }} />
          </DataTable>
        </div>
        {errores.includes("inicialColoracion") && <Message severity="error" text="Todos los campos de la tabla son requeridos"></Message>}
        {errores.includes("inicialColoracion_string") && <Message severity="error" text="Solo se aceptan números" />}
      </div>
      {producto && producto.tipo_calidad_inicial !== "PESO" && <SeccionFormulario titulo="# de racimos con cuenta de frutos">
        <ContenedorInput col="12" md="6" sm="4" label="4 frutos">
          <InputText name="racimos" value={state.no_racimos["4"]} type='number' onChange={(e) => handleInputRacimos("4", e.target.value)} keyfilter="int" />
          {errores.includes("racimos_string_4") && <Message severity="error" text="Este campo solo acepta números" />}
        </ContenedorInput>
        <ContenedorInput col="12" md="6" sm="4" label="5 frutos">
          <InputText name="racimos" value={state.no_racimos["5"]} type='number' onChange={(e) => handleInputRacimos("5", e.target.value)} keyfilter="int" />
          {errores.includes("racimos_string_5") && <Message severity="error" text="Este campo solo acepta números" />}
        </ContenedorInput>
        <ContenedorInput col="12" md="6" sm="4" label="6 frutos">
          <InputText name="racimos" value={state.no_racimos["6"]} type='number' onChange={(e) => handleInputRacimos("6", e.target.value)} keyfilter="int" />
          {errores.includes("racimos_string_6") && <Message severity="error" text="Este campo solo acepta números" />}
        </ContenedorInput>
        {errores.includes("racimos") && <Message severity="error" text="Todos los racimos deben tener un valor" />}
      </SeccionFormulario>}

      <div className="p-col-12 pb-0">
        <p className="section-title">
          Defectos ({producto?.tipo_calidad_inicial === "PESO" ? "Kg" : "cantidad"} de frutos con defectos)
        </p>
        <div className="card">
          <div className="p-grid">
            {defectosSeleccionados.map((defecto, index) => {
              return <div key={index} className="form-group p-col-12 p-md-6">
                <label htmlFor="input_defecto">{defecto.nombre}</label>
                <InputText id={`input_defecto_${index}`} name="input_defecto" type='number' value={defecto.valor} onChange={(e) => handleInputDefecto(index, e)} disabled={!puedeEditar()} keyfilter="num" />
              </div>
            })}
          </div>
        </div>
        {errores.includes("defectos") && <Message severity="error" text="Todos los defectos deben tener un valor" />}
        {errores.includes("defectos_string") && <Message severity="error" text="Solo se aceptan números" />}
        {errores.includes("sumaDefectos") &&
          <>
            {producto.tipo_calidad_inicial === "PESO"
              ? <Message severity="error" text={`Los kg de frutos con defectos no puede exceder ${state.peso_real_caja}Kg`} />
              : <Message severity="error" text={`La cantidad de frutos con defectos no puede exceder ${cantidadFrutosTotales}`} />}
          </>}

        <ModalSeleccionarDefectos visible={mostrarModalDefectos} onHide={() => setMostrarModalDefectos(false)} defectosSeleccionados={defectosSeleccionados} defectos={defectosEditados} setDefectosSeleccionados={(e) => setDefectosSeleccionados(e.value)} />

        <div className="p-grid p-justify-end btn-row">
          {puedeEditar() && <Button onClick={() => setMostrarModalDefectos(true)} label="+ Modificar defectos" />}
        </div>
      </div>

      <div className="p-col-12 pb-0">
        <p className="section-title">Grados Brix</p>
        <div className="card p-0">
          <DataTable value={currentBrix}>
            <Column field="color1" header="1" editor={(props) => { return puedeEditar() ? editorGeneratorBrix(props, "color1") : "No editable" }} />
            <Column field="color2" header="2" editor={(props) => { return puedeEditar() ? editorGeneratorBrix(props, "color2") : "No editable" }} />
            <Column field="color3" header="3" editor={(props) => { return puedeEditar() ? editorGeneratorBrix(props, "color3") : "No editable" }} />
            <Column field="color4" header="4" editor={(props) => { return puedeEditar() ? editorGeneratorBrix(props, "color4") : "No editable" }} />
            <Column field="color5" header="5" editor={(props) => { return puedeEditar() ? editorGeneratorBrix(props, "color5") : "No editable" }} />
            <Column field="color6" header="6" editor={(props) => { return puedeEditar() ? editorGeneratorBrix(props, "color6") : "No editable" }} />
          </DataTable>
        </div>
          {errores.includes("gradosBrix_string") && <Message severity="error" text="Solo se aceptan números" />}
      </div>

      <div className="p-col-12 pb-0">
        <p className="section-title">Información adicional</p>
        <div className="card">
          <div className="p-grid">
            <div className="form-group p-col-12">
              <label htmlFor="comentario">Comentario (opcional)</label>
              <InputTextarea rows={5} cols={30} autoResize={true} id="comentario" name="comentario" value={state.comentario} onChange={handleInputChange}
                disabled={!puedeEditar()} />
            </div>
          </div>
        </div>
      </div>

      <div className="p-col-12">
        <div className="p-grid p-justify-end btn-row">
          <Button label="Cancelar" onClick={props.history.goBack} className="p-button-secondary" />
          <Button label="Guardar" disabled={!puedeEditar()} onClick={guardar} />
        </div>
      </div>
    </div>);
}
export default RegistroInicial//329
