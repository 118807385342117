export const LABEL_PRODUCTOS = "Productos";
export const LABEL_VARIEDADES = "Variedades";
export const LABEL_COLORES = "Colores";
export const TIPO_CANTIDAD = "CANTIDAD_FRUTOS";
export const TIPO_PESO = "PESO";

export const buscarVariedadesTexto = "Buscar variedad";
export const buscarMezcladosTexto = "Buscar producto mezclado";
export const buscarColoresTexto = "Buscar color";

export const MEDLEYS = "MEDLEYS";
export const MEDLEYS_VIEJO = "MEDLEYS  *viejo*";
export const UNICOS_ID = "productosUnicos";
export const MEZCLADOS_ID = "productosMezclados"


//booleans
export const SOLO_MEDLEYS = true;
export const CON_VARIEDADES = false;

//orderBy
export const PRESENTACIONES = "presentaciones";
export const ORDEN = "orden";