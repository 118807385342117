import { Button } from 'primereact/button'
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown'
import { Row } from 'primereact/row';
import React, { useState, useEffect } from 'react'
import { useGlobal } from 'reactn';
import { esCicloActual } from '../../service/Ciclos';
import { flexDate, obtenerDiaActual, obtenerDiasDeSemana, obtenerHora, obtenerNombreDiaSemana, obtenerNombreSemana } from '../../service/fechas';
import { dosDecimales, getProductoYVariedadString, separadoComas } from '../../util/functions';
import { usePrimeReactTable } from '../common/tableHandler';
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { guardarCapturaCompraExterna, obtenerDatosCompraExterna, obtenerRealesProveedor } from '../../service/CompraExterna'
import { horaCorte, horaCorteDiario, nombresDiasSemana, nombresDiasSemanaPlanos } from '../../constants';
import { obtenerProveedores, obtenerProveedorPorUsuario } from '../../service/Queries/Proveedores';
import { withRouter } from 'react-router-dom';
import ContenedorFiltro from '../common/ContenedorFiltro';
import ContenedorPrincipal from '../common/ContenedorPrincipal';
import ContenedorHeader from '../common/ContenedorHeader';
import icono from '../../images/icons/external-icon.svg';

const tablaInicial = [//renglon 0 siempre va a ser el semanal, renglon 1 sera diario
    {
        label: "Semanal",
        lunes: "",
        martes: "",
        miercoles: "",
        jueves: "",
        viernes: "",
        sabado: "",
        total_cajas: 0,
        total_kg: 0
    },
    {
        label: "Diario",
        lunes: "",
        martes: "",
        miercoles: "",
        jueves: "",
        viernes: "",
        sabado: "",
        total_cajas: 0,
        total_kg: 0
    }
]
const ConsultaCompraExterna = (props) => {
    const extraProcessing = (updatedState, props, value) => {
        //procesar diario
        extraProcessingReal(updatedState);
        extraProcessingHTG(updatedState);
        return updatedState;
    }

    const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector(true);
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");

    const [, setCargando] = useGlobal("cargando");
    const [proveedor, setProveedor] = useGlobal("proveedor");
    const [cicloId] = useGlobal("ciclo")
    const [usuario] = useGlobal("usuario");
    const [invernaderos] = useGlobal("invernaderosVirtuales");

    const [tabla, setTabla] = useState(tablaInicial.map(el => Object.assign({}, el)))
    const [reales, setReales] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
    const [invernadero, setInvernadero] = useState()
    const [proveedores, setProveedores] = useState([])
    const [editorGenerator, current] = usePrimeReactTable(tabla, extraProcessing);
    const [cosechaFirestoreIds, setCosechaFirestoreIds] = useState([])

    useEffect(() => {
        if (invernaderos && proveedor) {
            let found = invernaderos.find(el => el.id === proveedor.invernadero_ref)
            setInvernadero(found);
        }
    }, [invernaderos, proveedor])
    useEffect(() => {
        setNombreModulo("Compra externa")
        setHabilitadoSelectorCiclo(false)
    }, [])
    useEffect(() => {
        if (cicloId && usuario) obtenerDatosIniciales();
    }, [cicloId, usuario, week])
    useEffect(() => {
        if (proveedor && week.nombre !== "Cargando") {
            actualizarDatos();
        }
    }, [proveedor, week])

    useEffect(() => {
        if (proveedores && !proveedor)
            setProveedor(proveedores[0])
    }, [proveedores])

    // useEffect(() => {
    //     if (cosechaFirestoreIds.length > 0) console.log(JSON.stringify(cosechaFirestoreIds))
    // }, [cosechaFirestoreIds])


    const extraProcessingReal = (updatedState) => {
        let timestamps = obtenerDiasDeSemana(week.time);
        let hoy = obtenerDiaActual();
        let hora = obtenerHora(Math.round(Date.now() / 1000));
        let datos = updatedState;
        let total = 0;
        timestamps.forEach((k, index) => {
            if (hoy < k) {

                let dato = parseFloat(datos[2][k]);
                if (dato && !isNaN(dato)) {

                    total += dato;
                } else {
                    dato = parseFloat(datos[0][k]);
                    if (dato && !isNaN(dato)) {
                        total += dato;

                    }
                }

            }
            else if (hoy === k && hora < horaCorte) {

                let dato = parseFloat(datos[2][k]);
                if (dato && !isNaN(dato)) {

                    total += dato;
                } else {
                    dato = parseFloat(datos[2][k]);
                    if (dato && !isNaN(dato)) {
                        total += dato;
                    }
                }
            } else {



                let dato = reales[index];

                if (dato && !isNaN(dato))
                    total += dato;

            }
        })
        let copia = reales.slice();
        copia[6] = total;



        setReales(copia);
    }

    const extraProcessingHTG = (updatedState) => {
        let timestamps = obtenerDiasDeSemana(week.time);
        let total = obtenerTotalHTG(updatedState, timestamps)
        let copia = tabla.slice();
        copia[2]["total_kg"] = total;
        setTabla(copia);
    }

    useEffect(() => {
        if (cicloId) updateCicloWeekSelector(cicloId);
    }, [cicloId])

    const puedeEditar = (rowIndex) => usuario && ["Administrador", "Proveedor Externo"].includes(usuario.rol);
    const esFechaValida = (indexDia, indexRow) => {
        let dias = obtenerDiasDeSemana(week.time);
        let dia = dias[indexDia];
        let ahora = Math.round(Date.now() / 1000);

        if (indexRow === 0) {
            let diasEstaSemana = obtenerDiasDeSemana(ahora);
            let sabado = diasEstaSemana[5] + 12 * 60 * 60;
            return ((usuario && !usuario.bloqueado) || (dia > sabado && ahora < sabado)) && proveedor?.habilitado;
            //validar semanal
        } else return ((usuario && !usuario.bloqueado) || (ahora < dia + 12 * 60 * 60)) && proveedor?.habilitado;
        //validar diario
    }
    const actualizarDatos = async (params) => {
        try {
            setCargando(true)
            let result = await obtenerDatosCompraExterna(cicloId, week.time, proveedor.usuario_ref, setCosechaFirestoreIds)
            let timestamps = obtenerDiasDeSemana(week.time)
            let procesado = obtenerTotales(result, timestamps);
            let registrosReales = await obtenerRealesProveedor(cicloId, proveedor.id, proveedor.invernadero_ref, week.time)
            let reales = procesarReales(registrosReales, result, timestamps)
            setReales(reales);
            setTabla(procesado);

        } catch (error) {
            console.log("ERROR: ", error)
        } finally { setCargando(false) }
    }
    const procesarReales = (reales, datos, timestamps) => {
        let result = [];
        let hoy = obtenerDiaActual();
        let hora = obtenerHora(Math.round(Date.now() / 1000));
        let total = 0;
        timestamps.forEach(k => {
            const timestamp = flexDate(k);
            let datoDia = reales.find(el => el.dia >= timestamp.inicio && el.dia <= timestamp.fin);
            if (datoDia) {
                let pallet = datoDia.pallets[0];
                if (pallet) {
                    let valor = parseFloat(pallet.cantidad)
                    if (valor && !isNaN(valor)) {
                        result.push(valor)
                    } else {
                        result.push(0);
                    }
                } else {
                    result.push(0)
                }
            } else {
                result.push(0)
            }
            if (hoy < k) {
                let dato = parseFloat(datos[2][k]);
                if (dato && !isNaN(dato)) {

                    total += dato;
                } else {
                    dato = parseFloat(datos[0][k]);
                    if (dato && !isNaN(dato)) {
                        total += dato;
                    }
                }

            }
            else if (hoy === k && hora < horaCorte) {
                let dato = parseFloat(datos[2][k]);
                if (dato && !isNaN(dato)) {

                    total += dato;
                } else {
                    dato = parseFloat(datos[2][k]);
                    if (dato && !isNaN(dato)) {
                        total += dato;
                    }
                }
            } else {
                let datoDia = reales.find(el => el.dia >= timestamp.inicio && el.dia <= timestamp.fin)
                if (datoDia) {


                    let dato = parseFloat(datoDia.pallets[0]?.cantidad || 0);
                    if (dato && !isNaN(dato))
                        total += dato;
                } else {
                }
            }


        })
        result.push(total);
        return result;

    }

    const obtenerTotales = (datos, timestamps) => {
        let result = datos.slice();
        result[0]["total_kg"] = obtenerTotalSemanal(datos, timestamps);
        result[1]["total_kg"] = obtenerTotalDiario(datos, timestamps);
        result[2]["total_kg"] = obtenerTotalHTG(datos, timestamps);
        return result
    }
    const obtenerTotalSemanal = (datos, timestamps) => {
        let row = datos[0];
        let ks = Object.keys(row);
        let total = 0;
        timestamps.forEach(k => {
            let dato = parseFloat(row[k]);
            if (dato && !isNaN(dato)) {
                total += dato;
            }
        })
        return total;
    }
    const obtenerTotalDiario = (datos, timestamps) => {
        let row = datos[0];
        let ks = Object.keys(row);
        let total = 0;
        let hoy = obtenerDiaActual();
        let hora = obtenerHora(Math.round(Date.now() / 1000));
        timestamps.forEach(k => {
            if (hoy < k) {
                let dato = parseFloat(datos[1][k]);
                if (dato && !isNaN(dato)) {
                    total += dato;
                } else {
                    dato = parseFloat(datos[0][k]);
                    if (dato && !isNaN(dato)) {
                        total += dato;
                    }
                }

            }
            else if (hoy === k && hora < horaCorte) {
                let dato = parseFloat(datos[1][k]);
                if (dato && !isNaN(dato)) {
                    total += dato;
                } else {
                    dato = parseFloat(datos[0][k]);
                    if (dato && !isNaN(dato)) {
                        total += dato;
                    }
                }
            } else {
                let dato = parseFloat(datos[1][k]);
                if (dato && !isNaN(dato)) {
                    total += dato;
                }
            }
        })
        return total;
    }
    const obtenerTotalHTG = (datos, timestamps) => {
        let row = datos[0];
        let ks = Object.keys(row);
        let total = 0;
        let hoy = obtenerDiaActual();
        let hora = obtenerHora(Math.round(Date.now() / 1000));
        timestamps.forEach(k => {
            if (hoy < k) {
                let dato = parseFloat(datos[2][k]);
                if (dato && !isNaN(dato)) {
                    total += dato;
                }
            }
            else if (hoy === k && hora < horaCorte) {
                let dato = parseFloat(datos[2][k]);
                if (dato && !isNaN(dato)) {
                    total += dato;
                }
            } else {
                let dato = parseFloat(datos[2][k]);
                if (dato && !isNaN(dato)) {
                    total += dato;
                } else {
                    dato = parseFloat(datos[0][k]);
                    if (dato && !isNaN(dato)) {
                        total += dato;
                    }

                }
            }
        })
        return total;
    }

    const obtenerDatosIniciales = async (params) => {
        let proveedores = await obtenerProveedores(cicloId);
        setProveedores(proveedores);
    }

    const unidadTemplatecaja = (rowData, column) => {
        if (rowData[column["field"]] === "") return ""
        else return <div>{separadoComas(dosDecimales(rowData[column["field"]]))}<span className="label-unidad">kilos</span> </div>;
    }
    const unidadTemplatekgmTotal = (rowData, column) => {
        let area = parseFloat(proveedor["area_invernadero"]);
        let dato = rowData[column["field"]] / area;
        return <div>{isNaN(dato) ? "-" : dosDecimales(dato)}<span className="label-unidad">kg/m²</span> </div>;
    }

    const guardar = async (params) => {
        try {
            setCargando(true);
            console.log("TABLA: ",JSON.stringify(tabla))
            await guardarCapturaCompraExterna(cicloId, proveedor.id, week.time, tabla, cosechaFirestoreIds);
        } catch (error) {
            throw error
        } finally {
            setCargando(false);
        }
    }
    const obtenerColumnas = (params) => {
        let cols = [<Column key={`cos-comp-ext-unidad`} field="label" header="" className="col__label" />]
        let diasSemana = obtenerDiasDeSemana(week.time);
        diasSemana.forEach((dia, index) => {
            let nombreDiaPlano = nombresDiasSemanaPlanos[index];
            cols.push(<Column key={`cos-comp-ext-${index}`} body={unidadTemplatecaja} field={`${dia}`} header={obtenerNombreDiaSemana(dia)}
                editor={(props) => { return esFechaValida(index, props.rowIndex) && puedeEditar(props.rowIndex) ? editorGenerator(props, nombreDiaPlano) : <div>No editable</div> }} />)
        })
        cols.push(<Column key={`cos-omp-ext-unidad`} body={unidadTemplatekgmTotal} field="total_kg" header="Total de kg/m²" className="col__total" />)
        return cols;
    }

    const obtenerFooter = () =>
        <ColumnGroup>
            <Row>
                <Column footer="Real" colSpan={1} />
                <Column footer={<div>{separadoComas(reales[0])}<span className="label-unidad">kilos</span></div>} colSpan={1} />
                <Column footer={<div>{separadoComas(reales[1])}<span className="label-unidad">kilos</span></div>} colSpan={1} />
                <Column footer={<div>{separadoComas(reales[2])}<span className="label-unidad">kilos</span></div>} colSpan={1} />
                <Column footer={<div>{separadoComas(reales[3])}<span className="label-unidad">kilos</span></div>} colSpan={1} />
                <Column footer={<div>{separadoComas(reales[4])}<span className="label-unidad">kilos</span></div>} colSpan={1} />
                <Column footer={<div>{separadoComas(reales[5])}<span className="label-unidad">kilos</span></div>} colSpan={1} />
                <Column footer={<div>{separadoComas(reales[6] / parseFloat(proveedor["area_invernadero"]))}<span className="label-unidad">kg/m²</span></div>} colSpan={1} />
            </Row>
        </ColumnGroup>;

    return (
        <ContenedorPrincipal>
            <ContenedorHeader col="p-col-auto p-col" titulo={`Consulta de compra externa ${proveedor?.nombre || ""}`} iconos="external-icon bor-main-blue bg-main-blue"
                invernaderoNombre={<img width="60" alt="icono" src={icono} />} subtitulo={getProductoYVariedadString(invernadero)} atras={props.history.goBack} />

            <div className="p-col-12 ">
                <div className="p-grid filtros">
                    <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
                    <ContenedorFiltro label="proveedor">
                        <Dropdown value={proveedor} options={proveedores} onChange={e => setProveedor(e.value)} optionLabel="nombre" dataKey="id" />
                    </ContenedorFiltro>
                </div>
            </div>

            <div className="p-col-12">
                <div className="p-grid p-fluid ">
                    <div className="p-col-12">
                        <p className="section-title">Registro - Semana {obtenerNombreSemana(week.time)} </p>
                        {proveedor ? <DataTable value={tabla} className="table-footer__total"
                            footerColumnGroup={obtenerFooter()} >
                            {obtenerColumnas()}
                        </DataTable> : "No hay proveedor seleccionado"}
                    </div>
                </div>
            </div>

            <div className="p-col-12">
                <div className="p-grid p-justify-end btn-row">
                    <Button label="Cancelar" className="p-button-secondary" onClick={props.history.goBack} />
                    <Button label="Guardar" onClick={guardar} />
                </div>
            </div>
        </ContenedorPrincipal>)
}
export default ConsultaCompraExterna//525