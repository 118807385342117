import React, { useEffect, useState } from "react"
import ContenedorPrincipal from "../common/ContenedorPrincipal";
import ContenedorHeader from "../common/ContenedorHeader";
import ContenedorFiltro from "../common/ContenedorFiltro";
import SeccionFiltros from "../common/SeccionFiltros";
import WeekSelector, { useWeekSelector } from "../common/WeekSelectorNEW";
import { useGlobal } from "reactn";
import { MultiSelect } from "primereact/multiselect";

import ContenedorTabla from "../common/ContenedorTabla";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getDiasDeSemana, getNombreDiaSemana } from "../../service/fechas";
import { DeepClone, dosDecimales, dosDecimalesMin, formatearMinutosAHoras, getObjetoPorID, sumarNumerosArrayPorCampo } from "../../util/functions";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faToolbox } from "@fortawesome/free-solid-svg-icons";
import { adelantarSemanasMantenimiento, getAgentes, getDatosMantenimiento, getSumaTotalTiempoEstimado, obtenerActividadesAgenteSemana, } from "../../service/Mantenimiento";
import ContenedorBotonesRow from "../common/ContenedorBotonesRow";
import ContenedorFiltroBusqueda from "../common/ContenedorFiltroBusqueda";
import { InputText } from "primereact/inputtext";
import { ToggleButton } from "primereact/togglebutton";

const TablaPlaneacionActividades = (props) => {
    const [ciclo] = useGlobal("ciclo");
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [, setHabilitadoSelectorCiclo,] = useGlobal("habilitadoSelectorCiclo");
    const [, setCargando] = useGlobal("cargando");
    const [datosCargados] = useGlobal("datosCargadosMantenimiento");
    const [weeks, week, handleWeekChange, asignarListaSemanas] = useWeekSelector(true);

    const [locacionesOpciones] = useGlobal("locaciones");
    const [empresasFiltros, setEmpresasFiltros] = useGlobal("empresasFiltrosMantenimiento");
    const [empresasSinLort, setEmpresasSinLort] = useGlobal("empresasSinLort");

    const [locacionesFiltros, setLocacionesFiltros] = useGlobal("locacionesFiltrosMantenimiento");
    const [actividadesMantenimiento] = useGlobal("actividadesMantenimiento");
    const [agentes, setAgentes] = useGlobal("agentes");
    const [agentesFiltrados, setAgentesFiltrados] = useState([])
    const [, setAgente] = useGlobal("agente");
    const [globalFilter, setGlobalFilter] = useState("")
    const [actividadToggle, setActividadToggle] = useState(true)

    useEffect(() => {
        setNombreModulo("Mantenimiento")
        setHabilitadoSelectorCiclo(true)
    }, [])

    useEffect(() => {
        setCargando(true);
        if (!datosCargados && week && week?.time?.toString().length === 13) getDatosMantenimiento()
        else if (datosCargados && week && week?.time?.toString().length === 13) getDatosIniciales();
    }, [datosCargados, week?.time])

    useEffect(() => {
        if (weeks?.length > 0) adelantarSemanasMantenimiento(ciclo, asignarListaSemanas)
    }, [weeks])
    useEffect(() => {
        if (empresasFiltros && locacionesFiltros && agentes) filtrarAgentes();
    }, [empresasFiltros, locacionesFiltros])

    const filtrarAgentes = () => {
        let empresasIDs = empresasFiltros.map(empresa => empresa.id);
        let locacionesIDs = locacionesFiltros.map(locacion => locacion.id);
        let lineasFiltrados = agentes.filter(agente => checarCondicionesDeFiltrar(agente, empresasIDs, locacionesIDs));

        setAgentesFiltrados(lineasFiltrados)
    }
    const checarCondicionesDeFiltrar = (agente, empresasIDs, locacionesIDs) => {
        let condicionEmpresa = agente.empresas?.some(id => empresasIDs.includes(id))
        return (condicionEmpresa || agente.empresas?.length === 0)
            && locacionesIDs.includes(agente.locacion_ref);
    }

    const getDatosIniciales = async () => {
        try {
            let ags = await getAgentes()
            let promisesAgentes = ags.map(agente => obtenerActividadesAgenteSemana(ciclo, week.time, agente));
            let tablaLineas = await Promise.all(promisesAgentes);

            setAgentes(tablaLineas);
            setAgentesFiltrados(tablaLineas);
        } catch (error) {
            console.log(`Error obtener datos TablaPlaneacionActividades: ${error}`);
        }
        setCargando(false);
    }

    const templateActividades = (rowData, column) => {
        let tareas = rowData.tareas.filter(el => el.dia == column.field)
        if (!actividadToggle) tareas = tareas.filter(el => el.is_requisicion)

        let sumaTiempo = getSumaTotalTiempoEstimado(actividadesMantenimiento, tareas)

        const completada = tareas.filter(tarea => tarea.realizada).length
        const total = tareas.length
        const porcentaje = total > 0 ? dosDecimalesMin(((completada * 100) / total)) : 0
        return <div>
            <div>{formatearMinutosAHoras(sumaTiempo)} <span className="label-unidad">Tiempo</span></div>
            <div> {total > 0 ? `${completada}/${total}` : 0} <span className="label-unidad">Actividades</span></div>
            <div>{porcentaje}%<span className="label-unidad"> Completadas</span></div>
        </div>
    }

    const irAdministracionActividades = () => { props.history.push("/mantenimiento/actividades") }
    const irPlaneacionActividades = (agenteRow) => {
        setAgente(agenteRow.data)
        props.history.push(`/mantenimiento/planeacion_actividades_agente/${agenteRow.data?.id || ""}`);
    }

    return (
        <ContenedorPrincipal extra="p-grid p-justify-center">
            <ContenedorHeader col="p-col-auto" titulo="Planeación de actividades" iconos="mtto-icon" />

            <ContenedorBotonesRow >
                <Button className="reactbtn-icon" onClick={irAdministracionActividades} label="Administrar actividades" />
            </ContenedorBotonesRow>

            <SeccionFiltros >
                <ContenedorFiltroBusqueda>
                    <FontAwesomeIcon icon={faSearch} />
                    <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar" />
                </ContenedorFiltroBusqueda>

                <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />

                <ContenedorFiltro label="Empresa">
                    <MultiSelect options={empresasSinLort} value={empresasFiltros} onChange={e => setEmpresasFiltros(e.value)} dataKey="id" optionLabel="nombre"
                        selectedItemsLabel={empresasFiltros && `${empresasFiltros.length} empresas seleccionadas`} />
                </ContenedorFiltro>
                <ContenedorFiltro label="locación">
                    <MultiSelect options={locacionesOpciones} value={locacionesFiltros} onChange={e => setLocacionesFiltros(e.value)} dataKey="id" optionLabel="nombre"
                        selectedItemsLabel={locacionesFiltros && `${locacionesFiltros.length} locaciones seleccionadas`} />
                </ContenedorFiltro>

                <ContenedorFiltro label="tipo actividad">
                    <ToggleButton className="reactbtn-icon" onLabel="Todas las actividades" offLabel="Requisiciones" checked={actividadToggle} onChange={(ev) => setActividadToggle(actividadToggle ? false : true)} ></ToggleButton>
                </ContenedorFiltro>
            </SeccionFiltros>

            <ContenedorTabla>
                <DataTable selectionMode="single" value={agentesFiltrados} onRowClick={irPlaneacionActividades} globalFilter={globalFilter}>
                    <Column field="displayName" header="Nombre" sortable={true} />
                    {getDiasDeSemana(week?.time).map(dia =>
                        <Column key={dia} field={`${dia}`} header={getNombreDiaSemana(dia)} sortable={true} body={templateActividades} />)}
                </DataTable>
            </ContenedorTabla>
        </ContenedorPrincipal>
    )
};
export default TablaPlaneacionActividades//158