import React from 'react';
import { useState, useEffect, useGlobal } from 'reactn';

import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { TabMenu } from "primereact/tabmenu";
import { Link } from "react-router-dom";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import moment from "moment";
import 'moment/locale/es';

import { clausurar, getCiclos, obtenerCiclosOrdenadosSemanaInicio } from "../../service/Ciclos";
import * as firebase from "firebase/app";
moment.locale("es");

const Ciclo = (props) => {
    const [state, setState] = useState({ ciclos: [] });
    const [, setCargando] = useGlobal("cargando");
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo")

    const [usuario] = useGlobal("usuario")
    const [ciclos, setCiclos] = useGlobal("ciclos")
    const [ciclo] = useGlobal("ciclo")

    const [invernaderosVirtuales, setInvernaderosVirtuales] = useGlobal("invernaderosVirtuales")

    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [, setModalConfirmacion] = useGlobal("modalConfirmacion");
    useEffect(() => {
        if (ciclos) obtenerCicloDatos()
    }, [ciclo])

    useEffect(() => {
        setNombreModulo("Ciclos")
        setHabilitadoSelectorCiclo(true)
    }, [])

    const obtenerCicloDatos = async () => {
        setCargando(true)

        let theCiclos = []
        let ciclosResponse = await getCiclos()

        for (var i = 0; i < ciclosResponse.length; i++) {
            let clientes = [], promises = []
            let cicloClientes = await firebase.firestore().collection("ciclos").doc(ciclosResponse[i].id).collection("clientes").get()

            for (var j = 0; j < cicloClientes.docs.length; j++) {
                promises.push(firebase.firestore().collection("clientes").doc(cicloClientes.docs[j].data().cliente_ref).get())
                //let clienteRef = await firebase.firestore().collection("clientes").doc(cicloClientes.docs[j].data().cliente_ref).get()
                //let comma = j < cicloClientes.docs.length - 1 ? ", " : ""
                //clientes.push(clienteRef.data().nombre_comun + comma)
            }
            let respo = await Promise.all(promises)

            respo.forEach((cliente, j) => {
                let comma = j < cicloClientes.docs.length - 1 ? ", " : ""
                clientes.push(cliente.data().nombre_comun + comma)
            })

            theCiclos.push({
                nombre: ciclosResponse[i].nombre,
                semanaInicio: "Semana " + moment(ciclosResponse[i].semana_inicio * 1000).utcOffset("-0600").format("WW") + " - " + moment(ciclosResponse[i].semana_inicio * 1000).utcOffset("-0600").format("YYYY") + "",
                semanaFinal: "Semana " + moment(ciclosResponse[i].semana_cierre * 1000).utcOffset("-0600").format("WW") + " - " + moment(ciclosResponse[i].semana_cierre * 1000).utcOffset("-0600").format("YYYY") + "",
                clientes: clientes,
                estatus: ciclosResponse[i].semana_cierre < Date.now() / 1000 ? "Finalizado" : "Vigente",
                cicloId: ciclosResponse[i].id,
                currentCiclo: ciclosResponse[i].currentCiclo,
                clausurado: ciclosResponse[i].clausurado
            })
        }
        setState({ ciclos: theCiclos })
        setCiclos(ciclosResponse)
        setCargando(false)
    }

    const irCiclo = (event) => { props.history.push({ pathname: `/administrar/ciclo/modificar/${event.data.cicloId}` }); }
    const handleClickClausurar = (rowData) => { activarModalConfirmacionClausura(rowData.cicloId); }
    const templateBotonFormulario = (rowData) => {
        return <button disabled={rowData["clausurado"]} className="p-button p-component p-button-text-only" onClick={() => handleClickClausurar(rowData)} > Clausurar </button>
    }
    const activarModalConfirmacionClausura = (cicloId) => {
        let mensaje =
            "¿Estás seguro que deseas clausurar este ciclo?";
        let titulo = "Confirmación de acción";
        let aceptar = () => { ejecutarClausura(cicloId) };
        let cancelar = () => { }
        setModalConfirmacion({ mensaje, titulo, aceptar, cancelar })
    }
    const ejecutarClausura = async (cicloId) => {
        try {
            let result = await clausurar(cicloId);
            let copia = { ...state };
            copia.ciclos.forEach(ciclo => {
                if (ciclo["cicloId"] === cicloId) {
                    ciclo["clausurado"] = true;
                }
            })
            setState(copia);
        } catch (error) {

        }
    }

    return (
        <div className="p-grid p-justify-center">
            <div className="p-col p-col-auto title-container">
                <span className="icon-general ciclo-icon"></span>
                <span className="titles-group">
                    <h1 className="header-title">Ciclos</h1>
                </span>
            </div>



            {!usuario.isRolFinanzas &&
                <div className="p-col p-col-auto">
                    <div className="btn-row">
                        <Button label="Crear nuevo ciclo" onClick={() => { props.history.push("/administrar/ciclo/crear") }} />
                    </div>
                </div>}
            <div className="p-col-12 ">
                <div className="card p-0  ">

                    <DataTable selectionMode="single" value={state.ciclos} onRowClick={irCiclo}>
                        <Column field={"nombre"} header={"Nombre"} sortable={true} />
                        <Column field={"semanaInicio"} header={"Inicio"} sortable={true} />
                        <Column field={"semanaFinal"} header={"Finalización"} sortable={true} />
                        <Column field={"clientes"} header={"Clientes Asociados"} sortable={true} />
                        <Column field={"estatus"} header={"Estatus"} sortable={true} />
                        {!usuario.isRolFinanzas && <Column field="acciones" header="Acciones" body={templateBotonFormulario} />}
                    </DataTable>
                </div>

            </div>
        </div>
    );
}

export default Ciclo //146