import { getGlobal } from "reactn"
import { obtenerCajasCosechadaTotales } from "service/Producto";
import { nombreActividadCosecha, obtenerInfoNomina, } from "../../service/nomina";
import { nombreActividadBajado, obtenerNombreYStatusEmpleado, obtener_empleados_invernadero } from "../../service/RhService";
import { DeepClone, dosDecimales, getNombrePorID, porcentaje, sumarNumerosArrayPorCampo } from "../../util/functions";

const sinRolTexto = "(Sin rol)".replace(/ /g, "\u00A0")
export const esRolCosechador = (rolsInvernadero = [], rolId) => getNombrePorID(rolsInvernadero, rolId) === "Cosechador";
export const esRolCosechaOtros = (rolsInvernadero = [], rolId) => getNombrePorID(rolsInvernadero, rolId) === "Otros";
export const esRolCosechaSinRol = (rolsInvernadero = [], rolId) => getNombrePorID(rolsInvernadero, rolId, sinRolTexto) === sinRolTexto;

const COSECHA = "Cosecha";
const RATIO_BONO_MINIMO_COSECHA = .5; //50%

export const obtenerDatosBase = async (ciclo, invernadero, week, listaActividades, rolsInvernadero = []) => {
    const semana = week.time;    
    let [cajasReales, [actividades, empleadosSemana], empleados] = 
        await Promise.all([ obtenerCajasCosechadaTotales(ciclo, invernadero.id, semana), obtenerInfoNomina(semana, invernadero.id, ciclo), obtener_empleados_invernadero(ciclo, invernadero.id)])
    //console.log(`Datos de ${invernadero.nombre}, `, {cajasReales, week});
    
    let ahora = Math.round(Date.now() / 1000);
    actividades.splice(0, 0, "empleado");

    let variedad = invernadero.variedad_ref
    let actividadesSemana = actividades;

    let promises = [];
    empleados.map(empleado => {
        promises.push(obtenerNombreYStatusEmpleado(empleado.empleado_ref));
    })

    let values = await Promise.all(promises)
    empleados = empleados.map((empleado, i) => {

        empleado.activo = values[i]["activo"];
        empleado["novato"] = (ahora - values[i]["fecha_ingreso"]) < 4 * 7 * 24 * 60 * 60;
        empleado.esRolCosechador = esRolCosechador(rolsInvernadero, empleado.rol_invernadero_ref);
        if (!empleado.esRolCosechador) { empleado.esRolCosechaOtros = esRolCosechaOtros(rolsInvernadero, empleado.rol_invernadero_ref); }
        if (!empleado.esRolCosechaOtros) { empleado.sinRolInvernadero = true; }

        empleado.fecha_ingreso = values[i]["fecha_ingreso"];
        empleado.id = values[i]["id"]
        empleado.nombre = values[i]["nombre"];
        if (values[i]["rol_invernadero_ref"]) {
            empleado.rol_invernadero_ref = values[i]["rol_invernadero_ref"];
            //console.log("values[i].rol_invernadero_ref :: ",empleado);
        }
        //empleado.nombre = empleado["novato"]? <span>{values[i]["nombre"]} <span className='novato-label'> (novato)</span> </span> : values[i]["nombre"];
        return empleado;
    })
    //
    if (empleados && empleados.length > 0) {
        // 
        empleadosSemana = empleadosSemana.map(empleado => {
            let empleadoCopia = Object.assign({}, empleado);

            let result = empleados.find(el => el.empleado_ref === empleado.empleado);
            if (result) {
                empleadoCopia.empleado = result.nombre;
                empleadoCopia.id = result.id;
                empleadoCopia.activo = result.activo;
                empleadoCopia.fecha_ingreso = result.fecha_ingreso;
                empleadoCopia.novato = result.novato;
                if (result.rol_invernadero_ref) {
                    empleadoCopia.rol_invernadero_ref = result.rol_invernadero_ref;
                    empleadoCopia.esRolCosechador = esRolCosechador(rolsInvernadero, empleadoCopia.rol_invernadero_ref);
                    empleadoCopia.rolInvernaderoNombre = getNombrePorID(rolsInvernadero, empleadoCopia.rol_invernadero_ref)
                    if (!empleadoCopia.esRolCosechador) { empleadoCopia.esRolCosechaOtros = !esRolCosechaSinRol(rolsInvernadero, empleadoCopia.rol_invernadero_ref); }
                }
                if (!empleadoCopia.esRolCosechador && !empleadoCopia.esRolCosechaOtros) {
                    empleadoCopia.sinRolInvernadero = true;
                    empleadoCopia.rolInvernaderoNombre = "Sin rol";
                }
            }

            return empleadoCopia;
        });

    }
    const actividadCosecha = listaActividades.find(act => act.nombre === COSECHA);

    let cuentaEmpleados = empleadosSemana.length;
    let empleadosSemanaCopia = DeepClone(empleadosSemana)

    empleadosSemanaCopia = procesar(empleadosSemanaCopia, invernadero.variedad_ref, invernadero, listaActividades, rolsInvernadero, true);
    const { cosechadoresTotales, cosechadoresPresentes, totalEnSegundoRangoOMas, ratioCompletadoElMinimo, mayoriaCosechoElMinimo }
        = calcularDatosCosecha(empleadosSemanaCopia, invernadero, actividadCosecha.id);

    empleadosSemana = procesar(empleadosSemana, invernadero.variedad_ref, invernadero, listaActividades, rolsInvernadero, mayoriaCosechoElMinimo);
    let footer = setValuesFooter(empleadosSemana, invernadero, listaActividades, cajasReales);

    // console.log("obtenerDatosBase empleadosSemana::: ", {semana: week.nombre, cosechadoresTotales, cosechadoresPresentes, ratioCompletadoElMinimo, totalEnSegundoRangoOMas })
    // console.log(`[SEMANA ${week.nombre}] ${dosDecimales(ratioCompletadoElMinimo * 100)}% [${totalEnSegundoRangoOMas}/${cosechadoresPresentes}]
    //  de cosechadores llegaron al rango 2 (${invernadero?.rangosCajasCosecha?.[1]?.min} cajas MIN)! \n\n`)

    return { cuentaEmpleados, empleadosSemana, footer, variedad, actividadesSemana }
}

const calcularDatosCosecha = (empleadosSemana, invernadero, actividadId) => {
    const cosechadoresTotales = empleadosSemana?.filter(empleado => empleado.esRolCosechador)?.length || 0;
    let cosechadoresPresentes = 0, totalEnSegundoRangoOMas = 0;

    empleadosSemana.forEach(empleado => {
        if (empleado.esRolCosechador && empleado[actividadId] !== 0) {
            let [cajasCosechadas, currBono] = empleado?.[actividadId]?.split("/") || [];
            const rangoCajasCosecha = obtenerDatosRangoCosecha(invernadero, cajasCosechadas);

            if (cajasCosechadas >= 1){ cosechadoresPresentes++;}
            if (rangoCajasCosecha >= 1) totalEnSegundoRangoOMas++;
        }
    })
    const ratioCompletadoElMinimo = cosechadoresPresentes > 0 ? (totalEnSegundoRangoOMas / cosechadoresPresentes) : 0;
    const mayoriaCosechoElMinimo = ratioCompletadoElMinimo >= RATIO_BONO_MINIMO_COSECHA;
    return { cosechadoresTotales, cosechadoresPresentes, totalEnSegundoRangoOMas, ratioCompletadoElMinimo, mayoriaCosechoElMinimo }
}
const obtenerDatosRangoCosecha = (invernadero, cajasCosechadas) => {
    const rangosCosechaInv = invernadero?.rangosCajasCosecha || [];
    let rangoCajasCosecha = 0;

    rangosCosechaInv.forEach((rangoNomina, index) => {
        if (cajasCosechadas >= rangoNomina.min && cajasCosechadas <= rangoNomina.max) { rangoCajasCosecha = index; }
    })
    return rangoCajasCosecha;
}

function procesar(empleados, variedad, invernadero, actividades, rolsInvernadero, mayoriaCosechoElMinimo) {
    let copia = empleados.map(empleado => {
        const bonoRef = invernadero.mezcla ? getGlobal().medleysGeneral?.id : invernadero.producto_ref

        let falta = empleado["falta"];


        let total = 0;
        const metrosPorLinea = parseInt(invernadero.detalles_fisicos.find(el => el.detalle === "Metros por línea")["norte"]);

        for (let x = 0; x < actividades.length; x++) {
            let actividadActual = actividades[x];
            let bono = actividadActual.nombre.includes(nombreActividadCosecha)
                ? calcularBonoPorRangoCajas(empleado, actividadActual.id, invernadero, rolsInvernadero, mayoriaCosechoElMinimo)
                : actividadActual["unidades"] === "líneas"
                    ? metrosPorLinea * actividadActual.bonos_por_unidad[bonoRef] : actividadActual.bonos_por_unidad[bonoRef];

            //console.log("BONO ",bono)
            if (empleado[actividadActual.id] > 0) {

                if (actividadActual.nombre.includes(nombreActividadCosecha)) {


                    empleado.extra_cosecha = bono * parseInt(empleado[actividadActual.id]);
                }

                empleado[`orig_${actividadActual.id}`] = empleado[actividadActual.id];
                empleado[`bono_${actividadActual.id}`] = bono * empleado[actividadActual.id];

                if (actividadActual.nombre.includes(nombreActividadCosecha)) {
                    //
                    total += empleado["extra_cosecha"];
                    //
                    empleado[actividadActual.id] = `${empleado[actividadActual.id]} / ${empleado["extra_cosecha"]}`;
                    //empleado[`bono_${actividadActual.id}`] = bono * empleado["extra_cosecha"];
                } else {
                    //
                    total += empleado[actividadActual.id] * bono;
                    //
                    empleado[actividadActual.id] = `${empleado[actividadActual.id]} / ${empleado[actividadActual.id] * bono}`;
                }
            }

            else {
                //
                empleado[`orig_${actividadActual.id}`] = 0;
                //
            }
        }
        //
        //
        empleado.total = `${total}`;
        empleado.bono = `${falta ? total : 1.2 * total}`;
        return empleado;
    })
    return copia.sort((a, b) => {
        return a.activo > b.activo ? -1 : a.activo < b.activo ? 1 : a.empleado > b.empleado ? 1 : -1
    });
}
const calcularBonoPorRangoCajas = (empleado, cosechaId, invernadero, rolsInvernadero, mayoriaCosechoElMinimo) => {
    //if(empleado.empleado.includes("Adam")) {  console.log("EXTRA COSECHA: ", {empleado, cosechaId, invernadero});}
    const rangoBonosCajasCosecha = invernadero?.rangosCajasCosecha;
    const numeroCajasCosechadas = parseInt(empleado[cosechaId]);
    if (!rangoBonosCajasCosecha || rangoBonosCajasCosecha?.length === 0 || !numeroCajasCosechadas) { return 0; }

    let bonoValor = 0;
    rangoBonosCajasCosecha.forEach(rango => {
        if ((numeroCajasCosechadas >= rango.min && numeroCajasCosechadas <= rango.max) || (numeroCajasCosechadas >= rango.min && rango.max === "En adelante")) {
            let bonoPrecio = esRolCosechador(rolsInvernadero, empleado.rol_invernadero_ref) ? (rango.bono || 0) : (invernadero?.precioBonoCosechaRolOtros || 0);
            bonoPrecio = isNaN(bonoPrecio) ? 0 : bonoPrecio
            bonoValor = parseFloat(bonoPrecio);

            empleado.mayoriaCosechoElMinimo = mayoriaCosechoElMinimo;
            empleado.enPrimerRangoCosecha = rango.orden === 1;

            if (!mayoriaCosechoElMinimo && rango.orden === 1 && rangoBonosCajasCosecha?.length > 1 && empleado.esRolCosechador) {
                bonoValor = rangoBonosCajasCosecha[1].bono;
                empleado.asignarRangoMinimo = true;
                //if (empleado.empleado === "Aurelio  Flores Sandoval") console.log("1 extra cosecha mayoriaCosechoElMinimo ", bonoValor)
            }
        }
    })

    //if ((empleado.empleado === "Aurelio  Flores Sandoval")) { console.log("2 extra cosecha ", bonoValor) }

    return bonoValor;
}
function setValuesFooter(empleadosSemana, invernadero, actividades, cajasReales) {
    let holder = {}, porcentajes = {};
    porcentajes.empleado = "Porcentaje";
    porcentajes.tipo = "porcentaje";
    porcentajes.total = "-";
    porcentajes.bono = "-";

    holder.empleado = "Totales";
    holder.total = 0;
    holder.bono = 0;

    let lineasInv = calcularLineasTotales(invernadero);
    for (let x = 0; x < actividades.length; x++) {
        let actividadActual = actividades[x];

        holder[actividadActual.id] = 0;
        holder[`unit_${actividadActual.id}`] = 0;
        holder[`valor_${actividadActual.id}`] = 0;
        porcentajes[actividadActual.id] = 0;
        empleadosSemana.forEach(empleado => {

            if (empleado[`orig_${actividadActual.id}`]) {
                let totalUnidades = empleado[`orig_${actividadActual.id}`];
                let field = `bono_${actividadActual.id}`;
                if (actividadActual.nombre === nombreActividadCosecha) {
                    field = `extra_cosecha`;
                }
                let totalValor = empleado[field];

                holder[`unit_${actividadActual.id}`] += totalUnidades;
                holder[`valor_${actividadActual.id}`] += totalValor;
                holder[`total`] += totalValor;


                holder[actividadActual.id] = `${holder[`unit_${actividadActual.id}`]} / ${holder[`valor_${actividadActual.id}`]}`;
                if (holder[`unit_${actividadActual.id}`]) {
                    porcentajes[actividadActual.id] = Math.round(100 * holder[`unit_${actividadActual.id}`] / lineasInv);

                    if (actividadActual.nombre === nombreActividadBajado) {
                        porcentajes[actividadActual.id] = 1 * porcentajes[actividadActual.id];
                    }
                    else if (actividadActual.unidades != "líneas") { porcentajes[actividadActual.id] = "-"; }
                }
            }
        })
    }
   
    const cosechaId = "xbu5aKm0lLu3RNzWjjuB";
    const bonoTotal = sumarNumerosArrayPorCampo(empleadosSemana, "bono");
    let cosechaTotal = sumarNumerosArrayPorCampo(empleadosSemana, `orig_${cosechaId}`);

    if(!cajasReales || cajasReales == 0) porcentajes[cosechaId] = "-";
    else if(!cosechaTotal || cosechaTotal == 0) porcentajes[cosechaId] = "0%";
    else porcentajes[cosechaId] = porcentaje(cosechaTotal, cajasReales);
    console.log(`Inv ${invernadero.nombre} Cosecha -- Cajas reales: ${cajasReales}, Cajas del app: ${cosechaTotal}`)

    //holder.bono = "-"//holder.total * 1.2;   
    holder.bono = dosDecimales(bonoTotal);
    holder.total = dosDecimales(holder.total);
    return [holder, porcentajes];
}
const calcularLineasTotales = (invernadero) => {
    let asignacion_lineas = invernadero["asignacion_lineas"];
    let total = asignacion_lineas.reduce((acc, curr) => {
        let dato = curr["totales"].split(" ")[0]
        if (dato) {
            dato = parseInt(dato);
            if (!isNaN(dato)) {
                return acc + dato;
            } else {
                return acc;
            }
        }
    }, 0)

    return total
}