import { getGlobal } from "reactn"
import { obtenerDiasDeSemana, obtenerInicioSemanaDia } from "./fechas";

import { DeepClone, dosDecimales, getObjetoPorID } from "../util/functions";
import { segundosSemana } from "../constants";
import { obtenerCapturaPrevia } from "./Queries/Pronostico";
import { obtenerPresentaciones } from "./Presentaciones";
import { obtenerOrdenesCiclo } from "./Ordenes";
import { obtenerOrdenesSemana } from "./QueriesOrdenes";
import { getAreaInvernaderoExterno, getAreaInvernaderoSync } from "./Invernaderos";
import { getProveedoresInvernadero, obtenerProveedores } from "./Queries/Proveedores";
const { productosYVariedadesTodos } = getGlobal();

export const obtenerReporteBudgetVsEmpaque = async (cicloObj, filtroInvernaderos, semanas) => {

    const [presentaciones, ordenesDeVenta, proveedores] = await Promise.all([obtenerPresentaciones(), obtenerOrdenesCiclo(cicloObj.id), obtenerProveedores(cicloObj.id)]);
    const promises = filtroInvernaderos.map(inv => obtenerDetalleReporte(cicloObj, inv, semanas, presentaciones, ordenesDeVenta, proveedores));
    const reporte = await Promise.all(promises);
    return reporte;
}
export const obtenerDetalleReporte = async (cicloObj, invernadero, semanas, presentaciones, ordenesDeVenta, proveedores) => {
    const proveedoresInv = proveedores?.filter(prov => prov.invernadero_ref === invernadero.id) || [];
    const area = invernadero.es_externo ? getAreaInvernaderoExterno(cicloObj.id, invernadero, proveedoresInv) : getAreaInvernaderoSync(invernadero)

    let ahora = Math.round(Date.now() / 1000)
    let inicioSemana = obtenerInicioSemanaDia(ahora).unix();


    const promises = semanas.map(semana => obtenerDetalleReporteSemanal(cicloObj, invernadero, semana, area, inicioSemana, presentaciones, ordenesDeVenta, proveedoresInv))
    return await Promise.all(promises);
}

export const obtenerDetalleReporteSemanal = async (cicloObj, invernadero, semanaObj, area, inicioSemana, presentaciones, ordenesDeVenta, proveedores) => {
    try {
        const semana = semanaObj.time, nombre = semanaObj.nombre;
        const budgetPromise = obtenerProyeccionSemanaConsiderarPronostico(cicloObj.id, DeepClone(invernadero), semana, inicioSemana, proveedores);
        const finalPromise = obtenerkgm2SemanalOrdenes(cicloObj.id, invernadero.id, semana, area, presentaciones, ordenesDeVenta);
        const [budget, kgm2OrdenesSemana] = await Promise.all([budgetPromise, finalPromise])

        return { budget, kgm2OrdenesSemana, semana, nombre, };
    } catch (error) {
        console.log("ERROR ODRS: ", error);
    }
}

export async function obtenerProyeccionSemanaConsiderarPronostico(ciclo, invernadero, inicio, semanaActual, proveedores) {
    inicio = obtenerInicioSemanaDia(inicio).unix();
    let pronosticos = { isEmpty: true }
    let indice = 0;
    if (inicio >= semanaActual && inicio < semanaActual + 5 * segundosSemana) {
        pronosticos = await obtenerCapturaPrevia(ciclo, invernadero.id, semanaActual);
        indice = (inicio - semanaActual) / segundosSemana
    }

    if (invernadero.es_externo) {invernadero.budget = obtenerBudgetExterno(invernadero, proveedores) }

    if (!pronosticos || pronosticos.isEmpty || !pronosticos["pronosticos"][indice]) {
        if (invernadero?.budget?.estimacion_semanal) {
            let total = invernadero.budget.estimacion_semanal.reduce((acc, curr) => {
                if (curr.time == inicio) {
                    let cant = parseFloat(curr.value);
                    if (isNaN(cant)) { return acc; }
                    else { return acc + cant; }
                }
                else { return acc; }

            }, 0);

            return total;
        } else { return 0 }
    }
    else { return pronosticos["pronosticos"][indice] }

}
export const obtenerBudgetExterno = (invernadero, proveedores) => {
//console.log("invernadero ", {invernadero, proveedores})
return {estimacion_semanal: [...proveedores[0].budget]}
}

export const obtenerkgm2SemanalOrdenes = async (ciclo, invernadero, semana, area, presentaciones, ordenesDeVenta) => {
    const dias = obtenerDiasDeSemana(semana)
    const ordenes = ordenesDeVenta?.filter(orden => orden.fecha_envio >= dias[0].toString() && orden.fecha_envio <= dias[5].toString()) || []

    let suma = 0;
    ordenes.forEach(orden => {
        orden.items.forEach(item => {
            if (item.presentacion_ref) {
                let presentacion = getObjetoPorID(presentaciones, item.presentacion_ref);
                let peso = presentacion.peso_neto_caja

                if (item.invernadero_ref === invernadero) {
                    let cajas = parseFloat(item.cajas_orden);
                    peso = parseFloat(peso);
                    if (!isNaN(cajas) && !isNaN(peso)) { suma += peso * cajas; }
                }
            }
        })
    });
    return (suma / area);
}