import React, { useEffect, useGlobal, useState } from "reactn";
import { obtenerIncidenciasSemana } from "../../service/nomina";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { obtenerNombreSemana } from "../../service/fechas";
import { Button } from "primereact/button";
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { capitalizeFirstLetter, getProductoYVariedadString } from "../../util/functions";
import { getTotalEmpleadosActivosInvernadero, obtenerCuentaEmpleadosActivosConAusencias, obtenerCuentaEmpleadosHabilitadosInvernadero, obtener_empleados_invernadero } from "../../service/RhService";
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

export default function Incidencias(props) {
    const [ciclo] = useGlobal("ciclo");
    const [invernadero, setInvernadero] = useGlobal("invernadero");
    const [,setCargando] = useGlobal("cargando")
    const [incidencias, setIncidencias] = useState(null);
    const [cuentaEmpleados, setCuentaEmpleados] = useGlobal("cuentaEmpleados");
    const [invernaderos] = React.useGlobal("invernaderosVirtuales");
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector();

    const cols = ["nombre", "lunes", "martes", "miercoles", "jueves", "viernes", "sabado", "acumulado"];

    useEffect(() => {
        setNombreModulo("Resumen de Incidencias");
    }, [])
    useEffect(() => {
        if (ciclo && invernadero && week?.nombre !== "Cargando") { getDatosIniciales(); }
        else if (ciclo && !invernadero) { props.history.push("/nomina"); }
    }, [invernadero, week, ciclo])

    const getDatosIniciales = async () => {
        setCargando(true)
        const featureNueva = await obtenerCuentaEmpleadosActivosConAusencias(ciclo, invernadero.id, week.time)
        const activos = featureNueva || (await getTotalEmpleadosActivosInvernadero(ciclo, invernadero.id)).activos;
        const incidenciasSemana = await obtenerIncidenciasSemana(week.time, ciclo, invernadero.id);
        const empleadosMaximos = parseInt(invernadero.numero_maximo_trabajadores)
        console.log(empleadosMaximos, "cantidad maxima")
        console.log(activos, "cantidad activos")
        console.log(featureNueva ? "selección de empleados activos obtenidos de registro de actividades" : "selección de  empleados activos que actualmente estan activos en el invernadero")
        setCuentaEmpleados(activos);
        setIncidencias(incidenciasSemana);
        setCargando(false)
        //console.log(`Hay ${activos} activos / ${originales} empleados del invernadero`)
        //console.log(`Hay ${parseInt(invernadero.numero_maximo_trabajadores)} totales / ${originales} empleados del invernadero`)
    }
    const getTextWithTooltip = (text = "", tooltipContent = "") => {
        let tooltip = `<p class='tooltip-def'>${tooltipContent}</p>`;
        return <div>{text} <Button className="btn-line btn-help ml-1" type="button" label="?" tooltip={tooltip} /></div>
    }
    const procesarIncidencias = () => {
        let result = [], nombres = ["permisos", "vacaciones", "ausencias"];
        let dias = ["lunes", "martes", "miercoles", "jueves", "viernes", "sabado"]
        const diasSemanas = { lunes: 0, martes: 0, miercoles: 0, jueves: 0, viernes: 0, sabado: 0, acumulado: 0 }
        
        let incidenciasTotales = {nombre:"Total de incidencias", ...diasSemanas };
        const tooltipAusenciasDeTotales = getTextWithTooltip(
            "% horas totales trabajadas", 
            "Porcentaje resultante de horas reales trabajadas por las horas maximas (menos incidencias) del dia por empleados activos"
        )
        const tooltipIncidenciasDeActivos = getTextWithTooltip(
            "% horas activas trabajadas", 
            "Porcentaje resultante de horas reales trabajadas por las horas maximas (menos ausencias) del dia por empleados totales"
        )
        let ausenciasDeTotales = { nombre: tooltipAusenciasDeTotales,...diasSemanas };
        let incidenciasDeActivos = {nombre: tooltipIncidenciasDeActivos, ...diasSemanas };

        if (incidencias) {
            nombres.forEach(nombre => {
                let obj = {};
                obj["nombre"] = capitalizeFirstLetter(nombre);
                obj["lunes"] = incidencias[0][nombre];
                obj["martes"] = incidencias[1][nombre];
                obj["miercoles"] = incidencias[2][nombre];
                obj["jueves"] = incidencias[3][nombre];
                obj["viernes"] = incidencias[4][nombre];
                obj["sabado"] = incidencias[5][nombre];
                obj["acumulado"] = incidencias[0][nombre] + incidencias[1][nombre] + incidencias[2][nombre] + incidencias[3][nombre] + incidencias[4][nombre] + incidencias[5][nombre];
                // console.log("PORC ", incidenciasDeActivos)
                //totales de incidencias
                for (let x = 0; x < 6; x++) {
                    incidenciasDeActivos[dias[x]] += incidencias[x][nombre];
                    incidenciasDeActivos.acumulado += incidencias[x][nombre];

                    incidenciasTotales[dias[x]] += incidencias[x][nombre];
                    incidenciasTotales.acumulado += incidencias[x][nombre];
                }
                result.push(obj);
            })
            // console.log("PORC ", incidenciasDeActivos)
            //Totales de ausencias nada mas
            for (let x = 0; x < 6; x++) {
                ausenciasDeTotales[dias[x]] += incidencias[x].ausencias;
                ausenciasDeTotales.acumulado += incidencias[x].ausencias;
            }
            const nombreHorasTotalesActivos = getTextWithTooltip(
                "Horas de empleados activos menos incidencias",
                "Dato real de horas trabajadas resultante de horas maximas trabajadas de empleados activos en el dia, menos horas perdidas del total de incidencias"
            )
            const nombreHorasTotalesMaximo = getTextWithTooltip(
                "Horas de empleados totales menos ausencias",
                "Dato de horas trabajadas resultante de horas maximas trabajadas de empleados totales en el dia, menos horas perdidas del total de ausencias"
            )
            const horasTotalesActivos = {nombre: nombreHorasTotalesActivos, ...diasSemanas };
            const horasTotalesMaximo = {nombre: nombreHorasTotalesMaximo, ...diasSemanas };

            if (cuentaEmpleados && cuentaEmpleados > 0) { obtenerPorcentajesTrabjadas(dias, incidenciasDeActivos, ausenciasDeTotales, horasTotalesActivos, horasTotalesMaximo) }
            result.push(incidenciasTotales)
            result.push(horasTotalesActivos)
            result.push(horasTotalesMaximo)
            result.push(incidenciasDeActivos);
            result.push(ausenciasDeTotales);
            return result;
        }
        else { return []; }/*50*/

    }
    const obtenerPorcentajesTrabjadas = (dias, incidenciasDeActivos, ausenciasDeTotales, horasTotalesActivos, horasTotalesMaximo) => {
        //console.log("cuentaEmpleados: ", { activos: cuentaEmpleados, incidenciasDeActivos, ausenciasDeTotales, totales: parseInt(invernadero.numero_maximo_trabajadores) })
        let sumaHorasPerdidasActivos = 0, sumaHorasPerdidasTotales = 0
        let horasTotalesAcum=0, horasTotalesMaximoAcum=0;
        const empleadosMaximos = parseInt(invernadero.numero_maximo_trabajadores)

        dias.forEach(dia => {
            const incidenciasTotalesDia = incidenciasDeActivos?.[dia] || 0, ausenciasTotalesDia = ausenciasDeTotales?.[dia] || 0;
            const horasTotalesATrabajarDia = dia === "sabado" ? 4 : 8;

            const horasMaximosDeTrabajoDiaActivos = cuentaEmpleados * horasTotalesATrabajarDia;
            const horasMaximosDeTrabajoDiaTotales = empleadosMaximos * horasTotalesATrabajarDia;

            const horasActivasMenosIncidencias = horasMaximosDeTrabajoDiaActivos - (horasTotalesATrabajarDia * incidenciasTotalesDia) ;
            const horasTotalesMenosAusencias = horasMaximosDeTrabajoDiaActivos - (horasTotalesATrabajarDia * ausenciasTotalesDia) 

            const porcentajeHorasTrabajadasActivos = ((horasActivasMenosIncidencias / horasMaximosDeTrabajoDiaActivos)*100).toFixed(2);
            const porcentajeHorasTrabajadasTotales = ((horasTotalesMenosAusencias / horasMaximosDeTrabajoDiaTotales)*100).toFixed(2);

            // console.log("HORAS TRABAJADAS activos - incidencias totales: ",{dia, horasTotales: horasMaximosDeTrabajoDiaActivos - horasPerdidasDeTrabajoDiaIncidencias})
            // console.log("HORAS TRABAJADAS totales -ausencias: ",{dia, horasTotales: horasMaximosDeTrabajoDiaTotales - horasPerdidasDeTrabajoDiaAusencias})

            horasTotalesActivos[dia] = horasActivasMenosIncidencias
            horasTotalesMaximo[dia] = horasTotalesMenosAusencias
            horasTotalesAcum += horasMaximosDeTrabajoDiaActivos
            horasTotalesMaximoAcum += horasMaximosDeTrabajoDiaTotales
            sumaHorasPerdidasActivos += horasTotalesATrabajarDia * incidenciasTotalesDia;
            sumaHorasPerdidasTotales += horasTotalesATrabajarDia * ausenciasTotalesDia;
            incidenciasDeActivos[`porc_${dia}`] = porcentajeHorasTrabajadasActivos;
            incidenciasDeActivos[`final_${dia}`] = `${porcentajeHorasTrabajadasActivos}%`
            ausenciasDeTotales[`porc_${dia}`] = porcentajeHorasTrabajadasTotales;
            ausenciasDeTotales[`final_${dia}`] = `${porcentajeHorasTrabajadasTotales}%`
           
            //console.log("PORC: ", { dia, horasPerdidasDeTrabajoDiaIncidencias, horasMaximosDeTrabajoDiaActivos, porcentajeHorasTrabajadasActivos, sumaHorasPerdidasActivos })
        });
      
        const horasMaximosDeTrabajoSemanaActivos = cuentaEmpleados * 44;
        const horasMaximosDeTrabajoSemanaTotales = cuentaEmpleados * 44;
        horasTotalesActivos.acumulado = horasMaximosDeTrabajoSemanaActivos - sumaHorasPerdidasActivos
        horasTotalesMaximo.acumulado = horasMaximosDeTrabajoSemanaTotales - sumaHorasPerdidasTotales
        const porcentajeHorasTrabajadasActivos = (100 * ( (horasTotalesActivos.acumulado / horasTotalesAcum))).toFixed(2);
        const porcentajeHorasTrabajadasTotales = (100 * ( (horasTotalesMaximo.acumulado / horasTotalesMaximoAcum))).toFixed(2);


        incidenciasDeActivos["acumulado"] = `${porcentajeHorasTrabajadasActivos}%`
        ausenciasDeTotales["acumulado"] = `${porcentajeHorasTrabajadasTotales}%`
        dias.forEach(dia => {
            incidenciasDeActivos[dia] = incidenciasDeActivos[`final_${dia}`];
            ausenciasDeTotales[dia] = ausenciasDeTotales[`final_${dia}`];
        })

    }
    const obtenerColumnas = () => {
        let colsname = ["tipo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado", "acumulado"];
        let tipocol = ["nombre", "", "", "", "", "", "", "acumulado"];
        return cols.map((nombre, index) => {
            return <Column className={tipocol.includes(nombre) ? "col__label" : ""} field={nombre} header={colsname[index]} key={nombre} />
        })
    }

    
    
    const obtenerFooter = () =>{
    if(!incidencias) return
    
    const incidenciasDatos = procesarIncidencias()
    const horasActivasTrabajadas = incidenciasDatos[6]
    const horasTotalesTrabajadas = incidenciasDatos[7]
    return (
     <ColumnGroup>
        <Row>
        {cols.map(nombre => { return <Column key={`${nombre}-F1`}  className="text-align-left-importante"  footer={<span>{horasActivasTrabajadas[nombre]}</span>} />})}
        </Row>
        <Row>
            {cols.map(nombre => {return <Column key={`${nombre}-F2`}  className="text-align-left" footer={<span>{horasTotalesTrabajadas[nombre]}</span>} /> })}
        </Row>
    </ColumnGroup>);
    }

    return (
        <div className="p-grid p-justify-center">
            <div className="p-col  p-col-auto title-container">
                <span className="back-btn" onClick={props.history.goBack}></span>
                <span className={`icon-general icon-id bor-${invernadero?.color} bg-${invernadero?.color} ${invernadero?.habilitado ? "" : "Deshabilitado"}`}>{invernadero?.nombre}</span>
                <span className="titles-group">
                    <h1 className="header-title">Resumen de incidencias de semana {obtenerNombreSemana(week.time)} </h1>
                    <p className="header-subtitle">
                        {getProductoYVariedadString(invernadero)}
                    </p>
                </span>
            </div>
            <div className="p-col p-col-auto">
                <div className="btn-row">
                    <Button onClick={props.history.goBack} label="Ver nómina" />
                </div>
            </div>

            <div className="p-col-12 ">
                <div className="p-grid filtros">
                    <div className="form-group p-col">
                        <label htmlFor="cinco">Invernadero</label>
                        <Dropdown id="filtroInvernadero" dataKey="id" optionLabel="nombre" options={invernaderos} value={invernadero}
                            onChange={(e) => { setInvernadero(e.target.value) }} placeholder="Seleccionar" />
                    </div>
                    <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
                </div>
            </div>
            <div className="p-col-12">
                <div className="p-grid p-fluid ">
                    <div className="p-col-12">
                        <div className="card p-0">
                            <DataTable value={procesarIncidencias().slice(0, 6)} footerColumnGroup={obtenerFooter()}>
                                {obtenerColumnas()}
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}