import React from 'react';
import { useGlobal } from "reactn";
import { obtenerInvernaderosVirtualesActivos, obtenerInvernaderoFisicoRelacionado, obtenerInvernaderosVirtualesInternos } from '../../service/Invernaderos';
import InvernaderoCard from './InvernaderoCard';
import RhService, {
    getNumeroDeEmpleadosActivosEnInvernadero, getNumeroDeEmpleadosEnInvernadero, obtenerRotacionAcumulada,
    obtenerRotacionEmpleados, obtenerCuentaEmpleadosActivos, obtenerInterseccionEmpleadosActivos
} from '../../service/RhService';
import OtroCard from './OtroCard';
import {
    DETALLES_DEPARTAMENTO_EMPAQUE, DETALLES_DEPARTAMENTO_OTROS,
    DEPARTAMENTO_INVERNADERO_NOMBRE, DEPARTAMENTO_EMPAQUE_NOMBRE, DEPARTAMENTO_OTROS_NOMBRE, MODULO_RH_NOMBRE
} from '../../util/constants';
import { useEffect } from 'react';
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { obtenerEmpaquesCiclo, obtenerNumeroEmpleadosEmpaque } from '../../service/Empaques';


function CardsRh(props) {

    // datos iniciales
    var [cicloId, setCicloId] = useGlobal("ciclo")
    var [nombreModulo, setNombreModulo] = useGlobal("nombreModulo");

    // datos de consulta
    var [detallesInvernaderos, setDetallesInvernaderos] = React.useState([]);
    var [detallesEmpaques, setDetallesEmpaques] = React.useState([]);
    var [detallesOtros, setDetallesOtros] = React.useState(undefined);
    var [usuario, setUsuario] = useGlobal("usuario");
    var [nombreDepartamento, setNombreDepartamento] = useGlobal("nombreDepartamento");
    var [invernaderoId, setInvernaderoId] = useGlobal("invernaderoId");
    var [empaqueId, setEmpaqueId] = useGlobal("empaqueId");
    const [cargando, setCargando] = useGlobal("cargando");
    const [, setMostrarModalPermiso] = useGlobal("mostrarModalPermiso");
    const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector();
    const [weekGlobal, setWeekGlobal] = useGlobal("weekGlobal");
    const [invernaderosInternos] = useGlobal("invernaderosInternos");

    useEffect(() => {
        if (cicloId) {
            updateCicloWeekSelector(cicloId);
        }
    }, [cicloId])
    useEffect(() => {
        if (week.nombre != "Cargando")
            setWeekGlobal(week);
    }, [week]);

    React.useEffect(() => {
        if (cicloId) {
            setNombreModulo(MODULO_RH_NOMBRE)
            getInitialData()
        }

    }, [cicloId, week]);


    async function getInitialData() {
        try {
            setCargando(true);
            let inicio = Date.now();
            let final = 0;
            let diferencia = 0;


            makeDetallesCardsInvernaderos(invernaderosInternos)

            final = Date.now();
            diferencia = (final - inicio) / (1000);

            let empaques = await RhService.getEmpaques();
            let empaquesCiclo = await obtenerEmpaquesCiclo(cicloId);
            await makeDetallesCardsEmpaques(empaques, empaquesCiclo)

            final = Date.now();
            diferencia = (final - inicio) / (1000);

            makeDetallesCardsOtros()
            final = Date.now();
            diferencia = (final - inicio) / (1000);



        } catch (error) {
            console.log("ERROR GID");
            console.log(error);
            if (error.code && error.code === "permission-denied") {
                setMostrarModalPermiso(true);
            }
            setCargando(false);
        }

    }

    async function makeDetallesCardsInvernaderos(invernaderos) {
        try {
            let inicio = Date.now();
            let final = 0;
            let dif = 0;


            setCargando(true);

            let promises = invernaderos.map(invernadero => makeDetalles(cicloId, invernadero, week.time))
            let detalles = await Promise.all(promises);


            final = Date.now();
            dif = (final - inicio) / 1000;

            setCargando(false);
            setDetallesInvernaderos(detalles)
        } catch (error) {
            console.log("ERROR MDCI");
            console.log(error);
            throw error;
        }

    }

    async function makeDetallesCardsEmpaques(empaques, empaquesCiclo) {
        try {




            var detalles = []
            for (var i = 0; i < empaquesCiclo.length; i++) {
                let empaqueData = empaquesCiclo[i]
                let empaque = empaques.find(el => el.id === empaqueData.empaque_ref);
                var numeroEmpleados = await obtenerNumeroEmpleadosEmpaque(cicloId, empaqueData.id);

                detalles.push({
                    id: empaqueData.id,
                    nombre: empaque.nombre,
                    empleados: `${numeroEmpleados}`,
                    icono: DETALLES_DEPARTAMENTO_EMPAQUE.icono,
                    color: DETALLES_DEPARTAMENTO_EMPAQUE.color
                });
            }

            setDetallesEmpaques(detalles)
        } catch (error) {
            console.log("ERROR MDCE");
            console.log(error);
            throw error;
        }
    }
    const makeDetalles = async (cicloId, invernaderoData, semana) => {
        try {
            let promises = [];
            promises.push(getNumeroDeEmpleadosEnInvernadero(cicloId, invernaderoData.id));
            promises.push(obtenerCuentaEmpleadosActivos(cicloId, invernaderoData.id, semana));


            promises.push(obtenerInvernaderoFisicoRelacionado(invernaderoData.invernadero_fisico_ref));
            promises.push(obtenerRotacionEmpleados(cicloId, invernaderoData.id, semana))
            promises.push(obtenerInterseccionEmpleadosActivos(cicloId, invernaderoData.id, semana))
            let [numeroEmpleados, numeroEmpleadosActivos, invFisico, rotacion, interseccion] = await Promise.all(promises);

            return {
                ...invernaderoData,
                id: invernaderoData.id,
                invernadero: invFisico.nombre,
                nombre: invernaderoData.nombre,
                invernaderoFisico: invFisico.nombre,
                color: invernaderoData.color,
                empleados: `${numeroEmpleadosActivos}/${invernaderoData.numero_maximo_trabajadores}`,
                activos: `${numeroEmpleados || numeroEmpleados !== 0
                    ? Math.floor(numeroEmpleadosActivos / invernaderoData.numero_maximo_trabajadores * 100)
                    : 0
                    }%`,
                rotacion: rotacion,
                interseccion
            };
        } catch (error) {
            if (error.code && error.code == "permission-denied") {
                setMostrarModalPermiso(true);
            }
        }
    }

    async function makeDetallesCardsOtros() {

        var numeroEmpleados = 0
        await RhService.getNumeroDeEmpleadosSinSubdepartamento().then((numero) => {
            numeroEmpleados = numero
        });

        setDetallesOtros({
            nombre: 'Otros departamentos',
            empleados: `${numeroEmpleados}`,
            icono: DETALLES_DEPARTAMENTO_OTROS.icono,
            color: DETALLES_DEPARTAMENTO_OTROS.color
        });
    }
    const puedeConsultar = (params) => {
        return (usuario && ["Administrador", "Administrador RH", "Auxiliar RH", "Grower", "Administrador Inocuidad", "Administrador Empaque","Personal Administrativo"].includes(usuario.rol))
    }

    function gotoInvernadero(invernadero) {
        if (puedeConsultar()) {
            setNombreDepartamento(DEPARTAMENTO_INVERNADERO_NOMBRE)
            setInvernaderoId(invernadero.id)
            setEmpaqueId("")
            props.history.push("/consultar_empleados")
        }

        /* props.history.push({
            pathname: "/consultar-empleados",
            state: {
                departamento: DEPARTAMENTO_INVERNADERO_NOMBRE,
                invernadero: invernadero.id,
                empaque: ""
            }
        });*/
    }

    function gotoEmpaque(empaque) {
        if (puedeConsultar()) {
            setNombreDepartamento(DEPARTAMENTO_EMPAQUE_NOMBRE)
            setInvernaderoId("")
            setEmpaqueId(empaque.id)
            props.history.push("/consultar_empleados")
        }

        /* props.history.push({
            pathname: "/consultar-empleados",
            state: {
                departamento: DEPARTAMENTO_EMPAQUE_NOMBRE,
                invernadero: "",
                empaque: empaque.id
            }
        });*/
    }

    function gotoOtros() {
        if (puedeConsultar()) {

            setNombreDepartamento(DEPARTAMENTO_OTROS_NOMBRE)
            setInvernaderoId("")
            setEmpaqueId("")
            props.history.push("/consultar_empleados")
        }

        /* props.history.push({
            pathname: "/consultar-empleados",
            state: {
                departamento: DEPARTAMENTO_OTROS_NOMBRE,
                invernadero: "",
                empaque: ""
            }
        });*/
    }


    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="p-grid filtros">
                    <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
                </div>
            </div>
            <div className="p-col-12">
                <div className="p-grid">

                    {/* cards invernadero */}
                    {detallesInvernaderos.map(invernaderoVirtual => {
                        return (
                            <InvernaderoCard key={invernaderoVirtual.id}
                                invernadero={invernaderoVirtual}
                                onClick={() => { gotoInvernadero(invernaderoVirtual) }}
                            />
                        )
                    })}

                    {/* cards empaque */}
                    {detallesEmpaques.map(empaque => {
                        return (
                            <OtroCard key={empaque.id}
                                otro={empaque}
                                onClick={() => { gotoEmpaque(empaque) }}
                            />
                        )
                    })}

                    {/* cards otro */}
                    {detallesOtros &&
                        <OtroCard otro={detallesOtros}
                            onClick={() => { gotoOtros() }}
                        />
                    }

                </div>
            </div>
        </div>
    );

}


export default CardsRh;