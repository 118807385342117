import moment from "moment";
import { segundosSemana } from "../constants";
import { getObjetoPorID } from "../util/functions";
import { obtenerCiclo } from "./Ciclos";
import { flexDate, obtenerListaDeSemanas } from "./fechas";
import { obtenerPresentaciones } from "./Presentaciones";
import { obtenerBudgetGrupoPresentaciones, obtenerGrupoPresentacionesConPresentacion, obtenerGruposPresentaciones } from "./QueriesGruposPresentaciones";
import {
    obtenerManifiestos, actualizarManifiesto, obtenerManifiestoPorNombre,
    obtenerManifiestosClientes,
    obtenerManifiestosCiclo,
    obtenerManifiestoPorId
} from "./QueriesManifiestos";
import { obtenerPresentacionesClientes } from "./QueriesPresentaciones";
export const APROBADO = "aprobado";
export const RECHAZO = "rechazo";
export const REPROCESO = "reproceso";
export const NO_APROBADO = "no_aprobado";
export const obtenerResumenPreciosManifiesto = async (cicloId, semana, clienteId, agregarAInvoice = false) => {
    let manifiestos = await obtenerManifiestos(cicloId, semana, clienteId);

    let presentaciones = await obtenerPresentaciones();
    let result = manifiestos.map(manifiesto => {

        let fecha_envio = manifiesto.fecha_envio;
        let items = manifiesto.items.slice();
        items.forEach(currentItem => {
            let presentacion = presentaciones.find(el => el.id === currentItem["presentacion_ref"]);
            currentItem["fecha_envio"] = (Number(fecha_envio) + (60 * 60)).toString();
            currentItem["manifiesto"] = manifiesto.manifiesto;
            currentItem["presentacion"] = presentacion.presentacion;
            currentItem["manifiestoId"] = manifiesto.id;
        })

        return items;
    })
    let itemsFinal = result.flat();
    let itemsFiltrados = agregarAInvoice ? filtrarItems5Semanas(semana, itemsFinal) : filtrarItemsSemana(semana, itemsFinal)
    itemsFiltrados = itemsFiltrados.sort((a, b) => {
        if (a.fecha_envio > b.fecha_envio) { return 1 }
        else { return -1 }
    })
    console.log("itemsFiltrados: ", itemsFiltrados);
    return itemsFiltrados;
}
const filtrarItems5Semanas = (semana, items) => {
    return items.filter(item => {
        const fecha_envio = parseInt(item.fecha_envio), fecha_desplazada = parseInt(item.fecha_desplazada);
        if (fecha_desplazada && !isNaN(fecha_desplazada)) { return fecha_desplazada >= semana && fecha_desplazada < semana + (1 * segundosSemana); }
        else return fecha_envio >= semana && fecha_envio < semana + (1 * segundosSemana);
    });

}
const filtrarItemsSemana = (semana, items) => {
    // ("FIS");
    // (items);

    return items.filter(item => {
        let fecha_envio = parseInt(item.fecha_envio);
        let fecha_desplazada = parseInt(item.fecha_desplazada);

        if (item.manifiesto === "E00065/ MH-1648") {
            //console.log("FOUND! ", {item, fecha_envio, semana, semanaSig:(semana + segundosSemana) , answer:(fecha_envio >= semana && fecha_envio <= semana + segundosSemana)})
        }

        if (fecha_desplazada && !isNaN(fecha_desplazada)) {
            return fecha_desplazada >= semana && fecha_desplazada < semana + segundosSemana;
        } else {
            return fecha_envio >= semana && fecha_envio < semana + segundosSemana;
        }
    });

}

export const obtenerReportePrecios = async (cicloId, clienteIds) => {
    if (clienteIds.length === 0) {
        return [];
    }
    let cicloObj = await obtenerCiclo(cicloId);
    let listaSemanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
    let presentaciones = await obtenerPresentacionesClientes(clienteIds);
    let registros = await obtenerManifiestosClientes(cicloId, clienteIds);
    let gruposPresentaciones = await obtenerGruposPresentaciones()
    let promisesBudgets = await gruposPresentaciones.map(el => obtenerBudgetGrupoPresentaciones(cicloId, el.id))
    let budgets = await Promise.all(promisesBudgets);

    return listaSemanas.map(semana => {
        let resultSemana = { nombreSemana: semana.nombre, semana: semana.time };
        presentaciones.forEach(presentacion => {
            let budgetIndex = gruposPresentaciones.findIndex(grupoPresentaciones => {


                return grupoPresentaciones.presentaciones_refs.includes(presentacion.id);
            })
            let valorBudget = 0;
            if (budgetIndex !== -1) {
                let budget = budgets[budgetIndex];
                if (!budget.empty) {
                    // ("found budget ");
                    // (presentacion.id);
                    // (semana.nombre);
                    // (budgetIndex)
                    // (budget);
                    let valorSemana = budget.budget.find(el => {
                        const timestamps = flexDate(el.time)
                        return timestamps.inicio <= semana.time && timestamps.fin >= semana.time;});
                    if (valorSemana && !isNaN(valorSemana.value)) {
                        valorBudget = parseFloat(valorSemana.value);
                    }
                }
            }
            let resumenPrecioLibraReal = obtenerReportePreciosPresentacionSemana(presentacion, registros, semana.time);
            resultSemana[presentacion.id] = {
                valorReal: resumenPrecioLibraReal["promedio"],
                valorBudget: valorBudget, sumaLibrasReal: resumenPrecioLibraReal["sumaLibras"],
                precioTotalReal: resumenPrecioLibraReal["precioTotal"],
            }
        })
        return resultSemana;
    })

    // obtener items
    // Hacer calculo de precios por presentacion
    //devolver valores reales   
}

export const obtenerReportePreciosPresentacionSemana = (presentacion, manifiestos, semanaTime) => {


    let precioTotal = 0;
    let sumaLibras = 0;
    manifiestos.forEach(manifiesto => {

        manifiesto.items.forEach(item => {
            if (calificaSemana(item, semanaTime, manifiesto.fecha_envio) && presentacion.id === item.presentacion_ref) {
                // ("COINCIDE ITEM");
                // (item);
                // (presentacion);
                let precio = 0;
                let peso = 0;
                if (item.status === APROBADO) {
                    precio = parseFloat(item.cajas_orden) * presentacion.peso_neto_caja_libras * parseFloat(item.precio_libra);
                    peso = presentacion.peso_neto_caja_libras * parseFloat(item.cajas_orden);
                }

                else if (item.status === RECHAZO) {
                    precio = parseFloat(item.cajas_orden) * presentacion.peso_neto_caja_libras * parseFloat(item.precio_con_rechazo);
                    peso = presentacion.peso_neto_caja_libras * parseFloat(item.cajas_orden);
                }
                else if (item.status === REPROCESO) {
                    precio = parseFloat(item.cajas_orden) * presentacion.peso_neto_caja_libras * parseFloat(item.precio_con_reproceso);
                    peso = presentacion.peso_neto_caja_libras * parseFloat(item.cajas_orden);
                }
                precioTotal += precio;
                sumaLibras += peso;
            }
        })
    })
    let promedio = sumaLibras === 0 ? 0 : precioTotal / sumaLibras;
    return { promedio, sumaLibras, precioTotal };
}

const calificaSemana = (item, semanaTime, fecha_envio) => {
    let fecha_desplazada = parseInt(item.fecha_desplazada);
    fecha_envio = parseInt(fecha_envio);
    //semanaTime = parseInt(semanaTime);
    if (fecha_desplazada && !isNaN(fecha_desplazada)) {
        return fecha_desplazada >= semanaTime && fecha_desplazada < semanaTime + 7 * 24 * 60 * 60
    } else {
        return fecha_envio >= semanaTime && fecha_envio < semanaTime + 7 * 24 * 60 * 60
    }

}


export const actualizarItemManifiesto = async (cicloId, itemManifiesto, original, manifiestos) => {
    let nombreManifiesto = itemManifiesto.manifiesto;
    let manifiesto = await obtenerManifiestoPorNombre(cicloId, nombreManifiesto);
    let resultado = obtenerManifiestoActualizadoItem(manifiesto, itemManifiesto);

    let yaVerificado = revisarYaVerificado(original);
    if (yaVerificado) { await actualizarManifiesto(cicloId, manifiesto.id, resultado); }
    else { await actualizarManifiestosGrupo(cicloId, itemManifiesto, manifiestos) }
    return true
}
const actualizarManifiestosGrupo = async (cicloId, resultado, manifiestos) => {

    let datosImportantes = {}
    datosImportantes["status"] = resultado["status"]
    if (resultado["precio_libra"]) {
        datosImportantes["precio_libra"] = resultado["precio_libra"]
    }
    if (resultado["cajas_con_rechazo"]) {
        datosImportantes["cajas_con_rechazo"] = resultado["cajas_con_rechazo"]
    }
    if (resultado["cajas_con_reproceso"]) {
        datosImportantes["cajas_con_reproceso"] = resultado["cajas_con_reproceso"]
    }
    if (resultado["precio_con_rechazo"]) {
        datosImportantes["precio_con_rechazo"] = resultado["precio_con_rechazo"]
    }

    if (resultado["precio_con_reproceso"]) {
        datosImportantes["precio_con_reproceso"] = resultado["precio_con_reproceso"]
    }
    if (resultado["precio_sin_reproceso"]) {
        datosImportantes["precio_sin_reproceso"] = resultado["precio_sin_reproceso"]
    }
    // encontrar todos manifiestos con items que pertenecen al grupo de presentaciones
    let grupoPresentaciones = await obtenerGrupoPresentacionesConPresentacion(resultado.presentacion_ref);
    let idsManifiestos = new Set()
    manifiestos.forEach(el => {
        if (grupoPresentaciones.presentaciones_refs.includes(el.presentacion_ref)) {
            idsManifiestos.add(el.manifiestoId)
        }
    });
    idsManifiestos = Array.from(idsManifiestos);
    let promises = idsManifiestos.map(el => actualizarManifiestoDatosImportantes(cicloId, el, grupoPresentaciones, datosImportantes))
    let result = await Promise.all(promises)
    // aplicar cambios a todos manifiestos con items que pertenecen al grupo de presentaciones
    return true;
}
const actualizarManifiestoDatosImportantes = async (cicloId, manifiestoId, grupoPresentaciones, datosImportantes) => {
    // ("AMDI");
    // (datosImportantes);        
    let manifiesto = await obtenerManifiestoPorId(cicloId, manifiestoId);
    manifiesto.items.forEach(item => {
        if (grupoPresentaciones.presentaciones_refs.includes(item.presentacion_ref))
            item = Object.assign(item, datosImportantes);
    })

    let result = await actualizarManifiesto(cicloId, manifiestoId, manifiesto)
    return result;
}

const revisarYaVerificado = (original) => {
    if (original.status && original.status !== "sin") {
        return true
    } else {
        return false;
    }
}

export const desplazarItemManifiesto = async (cicloId, itemManifiesto, original) => {
    // ("DIM");
    // (itemManifiesto);
    // (original);
    let nombreManifiesto = itemManifiesto["manifiesto"];
    let manifiesto = await obtenerManifiestoPorNombre(cicloId, nombreManifiesto);
    let resultado = obtenerManifiestoDesplazadoItem(manifiesto, itemManifiesto);
    // ("MANIFIESTO POR ACTUALIZAR");
    // (resultado);
    let result = await actualizarManifiesto(cicloId, manifiesto.id, resultado);
    return result;
}

export const obtenerManifiestoActualizadoItem = (manifiesto, itemManifiesto) => {
    let copia = { ...manifiesto };
    copia.items = manifiesto.items.slice();
    let itemPorActualizar = copia.items.find(el => el.presentacion_ref === itemManifiesto.presentacion_ref);

    if (itemPorActualizar) {
        itemPorActualizar = Object.assign(itemPorActualizar, itemManifiesto);
    }

    return copia;
}

export const obtenerManifiestoDesplazadoItem = (manifiesto, itemManifiesto) => {
    let copia = { ...manifiesto };
    copia.items = manifiesto.items.slice();
    let itemPorActualizar = copia.items.find(el => el.presentacion_ref === itemManifiesto.presentacion_ref);

    if (itemPorActualizar) {
        // ("FOUND ITEM");
        // (itemPorActualizar);
        let fecha_desplazada = parseInt(itemPorActualizar["fecha_desplazada"]);
        let fecha_envio = parseInt(manifiesto["fecha_envio"]);
        // (fecha_desplazada);
        // (fecha_envio);
        if (fecha_desplazada && !isNaN(fecha_desplazada)) {
            fecha_desplazada = fecha_desplazada + segundosSemana;
        } else if (fecha_envio && !isNaN(fecha_envio)) {
            fecha_desplazada = fecha_envio + segundosSemana
        }
        itemPorActualizar["fecha_desplazada"] = fecha_desplazada;
    }

    return copia;
}

export const obtenerReportePreciosAcumulado = async (cicloId, clientes) => {
    let itemsPorClientes = {};
    clientes.forEach(cliente => { itemsPorClientes[cliente.id] = [] });
    let logs = ""

    // obtener todos los manifiestos del ciclo
    let [manifiestos, presentaciones] = await Promise.all([obtenerManifiestosCiclo(cicloId), await obtenerPresentaciones()])

    //obtener todos los items de cliente
    //filtrar los que tienen status calculable
    manifiestos.forEach(manifiesto => {
        //if (manifiesto.items.some(item => !item.cajas_orden)) logs += `Manifiesto: ${manifiesto.id}, fecha envio: ${manifiesto.fecha_envio} \n ` //console.log("!!n..a.n!! -- MANIFIESTO:" + manifiesto.id + " -- FECHA: " + manifiesto.fecha_envio)
        if (manifiesto.items?.length > 0) {
            manifiesto.items.forEach(item => {
                if ([APROBADO, RECHAZO, REPROCESO].includes(item.status)) {
                    itemsPorClientes[manifiesto.cliente_ref].push({ ...item, manID: manifiesto.id });
                }
            })
        }
    })
    console.log(logs)
    console.log("----------------------------------------")
    console.log("----------------------------------------")
    //separar resumen y hacer calculo de cada cliente
    let resumen = clientes.map(cliente => {
        let clienteResumen = { nombreCliente: cliente.nombre, clienteId: cliente.id }
        itemsPorClientes[cliente.id].forEach(item => {
            let clienteInvDatos = clienteResumen[item.invernadero_ref]
            if (!clienteInvDatos) clienteInvDatos = {}
            if (!clienteInvDatos[item.producto_ref]) clienteInvDatos[item.producto_ref] = { precioTotal: 0, sumaLibras: 0, costoMateriales: 0 }

            let presentacion = getObjetoPorID(presentaciones, item.presentacion_ref)
            let precioCajas = item.status === APROBADO
                ? item.precio_libra
                : item.status === RECHAZO ? 0 : item.precio_con_reproceso;

            let peso = parseFloat(presentacion.peso_neto_caja_libras) * parseFloat(item.cajas_orden || 0);
            let precio = peso * parseFloat(precioCajas);
            let costoMateriales = peso * parseFloat(presentacion.costo_material_lb);

            clienteInvDatos[item.producto_ref].precioTotal += precio;
            clienteInvDatos[item.producto_ref].sumaLibras += peso;
            clienteInvDatos[item.producto_ref].costoMateriales += costoMateriales;
            if (isNaN(precio)) {
                /*    console.log("ERROR: " + item.manID)
                    console.log("presentacion.peso_neto_caja_libras: " + presentacion.peso_neto_caja_libras)
                    console.log("item.cajas_orden: " + item.cajas_orden)
                    console.log("item.status: " + item.status)
                    console.log("item.precio_con_rechazo: " + item.precio_con_rechazo)
                    console.log("precioCajas: " + precioCajas)
                    console.log("presentacion.costo_material_lb: " + presentacion.costo_material_lb)
                    */
            }
            clienteResumen[item.invernadero_ref] = clienteInvDatos
        })
        return clienteResumen;
    })
    return resumen;
}//358