import React, { useEffect, useState } from "react";
import { useGlobal } from 'reactn';
import Chart from "react-apexcharts";
import { obtenerInvernaderosVirtuales, obtenerInvernaderosVirtualesInternos } from "../../service/Invernaderos";
import { obtenerReportesCosechaCiclo } from '../../service/Cosecha';
import { MultiSelect } from "primereact/multiselect";
import { obtenerCiclo } from "../../service/Ciclos";
import { dosDecimales, stringToColor } from "../../util/functions";
import { obtenerListaDeSemanas } from "../../service/fechas";
import CosechaFiltroInvernadero from './CosechaFiltroInvernadero';
import CosechaFiltroInvernaderoMultiCiclo from './CosechaFiltroInvernaderoMultiCiclo';
import CosechaFiltroTipo from './CosechaFiltroTipo';
import { SelectButton } from 'primereact/selectbutton';
import ContenedorFiltro from "../common/ContenedorFiltro";
import { Dropdown } from 'primereact/dropdown';
import { ToggleButton } from 'primereact/togglebutton';

const tiposFiltro = [{ label: "Invernaderos", value: "invernaderos" }, { label: "Tipo Porcentaje", value: "porcentajes" }];
const tipos = [{ name: "Porcentajes", code: "porcentajes" }, { name: "Totales", code: "totales" }]
const selectButtonsRealDiarioSemanal = [{ name: "Real" }, { name: "Diario" }, { name: "Semanal" }]
const selectButtonsComparaciones = [{ name: "RVS" }, { name: "DVS" }, { name: "RVD" }]
const optionsOrig = {
  chart: {
    type: 'area',
    height: 350,
    zoom: {
      enabled: true,
      type: "x",
      autoScaleYAxis: true
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'straight'
  },

  title: {
    text: 'Area with Negative Values',
    align: 'left',
    style: {
      fontSize: '14px'
    }
  },
  xaxis: {
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false
    }
  },
  yaxis: {
    title: "Comparación con 100 %",
    tickAmount: 4,
    floating: false,

    labels: {
      style: {
        colors: '#8e8da4',
      },
      offsetY: -7,
      offsetX: 0,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false
    }
  },
  fill: {
    opacity: 0.5
  },
  tooltip: {
    x: {
      format: "yyyy",
    },
    fixed: {
      enabled: false,
      position: 'topRight'
    }
  },
  grid: {
    yaxis: {
      lines: {
        offsetX: -30
      }
    },
    padding: {
      left: 20
    }
  }
}

const Cosecha = (props) => {
  const [tipoFiltro, setTipoFiltro] = useState(tiposFiltro[0].value);
  const [filtrosInv, setFiltrosInv] = useState([]);
  const [invernaderoSeleccionado, setInvernaderoSeleccionado] = useState([])
  const [filtrosOrigInv, setFiltrosOrigInv] = useState([]);
  const [filtrosTipo, setFiltrosTipo] = useState([]);
  const [filtrosOrigTipo, setFiltrosOrigTipo] = useState([{ label: "Real vs Semanal", id: "sr" }, { label: " Diario vs Semanal", id: "sd" }, { label: " Real vs Diario", id: "dr" }]);
  const [ciclo,] = useGlobal("ciclo");
  const [ciclos,] = useGlobal("ciclos");
  const [tipo, setTipo] = useState(tipos[0]["code"])
  const [multiCiclo, setMultiCiclo] = useState(false)
  const [invernaderosInternos] = useGlobal("invernaderosInternos");
  const [allCiclos, setAllCiclos] = useState([]);
  const [ciclo1, setCiclo1] = useState("")
  const [ciclo2, setCiclo2] = useState("")
  const [ciclo1Invernadero, setCiclo1Invernadero] = useState("")
  const [ciclo2Invernadero, setCiclo2Invernadero] = useState("")
  const [filtrosInvCiclo1, setFiltrosInvCiclo1] = useState([]);
  const [filtrosOrigInvCiclo1, setFiltrosOrigInvCiclo1] = useState([]);
  const [filtrosInvCiclo2, setFiltrosInvCiclo2] = useState([]);
  const [filtrosOrigInvCiclo2, setFiltrosOrigInvCiclo2] = useState([]);
  const [selectButtonTipoDato, setSelectButtonTipoDato] = useState([])

  useEffect(() => {
    setSelectButtonTipoDato([])
  },[tipo])
  
  useEffect(() => {
    const newFiltrosInv = []
    invernaderoSeleccionado.forEach((inv) => {
      selectButtonTipoDato.forEach((option) => {
          newFiltrosInv.push(`${option.name} - ${inv.nombre}`)
      })
    })
    const filtrosInvsData = newFiltrosInv.map((filtro) => filtrosOrigInv.find((inv) =>inv.nombre === filtro))
    setFiltrosInv(filtrosInvsData)
  }, [selectButtonTipoDato, invernaderoSeleccionado])

  useEffect(() => {
    if (ciclo && !multiCiclo) {
      obtenerDatosIniciales();
    }
    else if (ciclo && multiCiclo) {

    }
  }, [ciclo, tipo])
  useEffect(() => {
    if (ciclos.length > 0) {
      setAllCiclos(ciclos.map(cic => ({ id: cic.value, nombre: cic.label })))
    }
  }, [ciclos])
  const obtenerDatosIniciales = async (params) => {
    try {

      let invernaderos = await obtenerInvernaderosVirtualesInternos(ciclo);
      let filtros = [];
      invernaderos.forEach((invernadero, index) => {
        if (tipo === "porcentajes") {
          let objRS = {};
          objRS["id"] = `rvs - ${invernadero.id}`
          objRS["nombre"] = `RVS - ${invernadero.nombre}`
          objRS["color"] = invernadero.color
          objRS["index"] = 0

          let objDS = {};
          objDS["id"] = `dvs - ${invernadero.id}`
          objDS["nombre"] = `DVS - ${invernadero.nombre}`
          objDS["color"] = invernadero.color
          objDS["index"] = 1
          let objRD = {};
          objRD["id"] = `rvd - ${invernadero.id}`
          objRD["nombre"] = `RVD - ${invernadero.nombre}`
          objRD["color"] = invernadero.color
          objRD["index"] = 2
          filtros.push(objRS)
          filtros.push(objDS)
          filtros.push(objRD)
        } else {
          let objS = {};
          objS["id"] = `semanal - ${invernadero.id}`
          objS["nombre"] = `Semanal - ${invernadero.nombre}`
          objS["color"] = invernadero.color
          objS["index"] = 0

          let objD = {};
          objD["id"] = `diario - ${invernadero.id}`
          objD["nombre"] = `Diario - ${invernadero.nombre}`
          objD["color"] = invernadero.color
          objD["index"] = 1
          let objR = {};
          objR["id"] = `real - ${invernadero.id}`
          objR["nombre"] = `Real - ${invernadero.nombre}`
          objR["color"] = invernadero.color
          objR["index"] = 2
          filtros.push(objR)
          filtros.push(objD)
          filtros.push(objS)
        }
      })
      //setFiltrosInv(invernaderos);
      setFiltrosOrigInv(filtros);
    } catch (error) {

    }
  }

  const obtenerDatosInicialesMulti = async (cicloId, cicloNum) => {
    try {
      let invernaderos = await obtenerInvernaderosVirtualesInternos(cicloId);
      let filtros = [];
      invernaderos.forEach((invernadero, index) => {
        if (tipo === "porcentajes") {
          let objRS = {};
          objRS["id"] = `rvs - ${invernadero.id}`
          objRS["nombre"] = `RVS - ${invernadero.nombre}`
          objRS["color"] = invernadero.color
          objRS["index"] = 0

          let objDS = {};
          objDS["id"] = `dvs - ${invernadero.id}`
          objDS["nombre"] = `DVS - ${invernadero.nombre}`
          objDS["color"] = invernadero.color
          objDS["index"] = 1
          let objRD = {};
          objRD["id"] = `rvd - ${invernadero.id}`
          objRD["nombre"] = `RVD - ${invernadero.nombre}`
          objRD["color"] = invernadero.color
          objRD["index"] = 2
          filtros.push(objRS)
          filtros.push(objDS)
          filtros.push(objRD)
        } else {
          let objS = {};
          objS["id"] = `semanal - ${invernadero.id}`
          objS["nombre"] = `Semanal - ${invernadero.nombre}`
          objS["color"] = invernadero.color
          objS["index"] = 0

          let objD = {};
          objD["id"] = `diario - ${invernadero.id}`
          objD["nombre"] = `Diario - ${invernadero.nombre}`
          objD["color"] = invernadero.color
          objD["index"] = 1
          let objR = {};
          objR["id"] = `real - ${invernadero.id}`
          objR["nombre"] = `Real - ${invernadero.nombre}`
          objR["color"] = invernadero.color
          objR["index"] = 2
          filtros.push(objR)
          filtros.push(objD)
          filtros.push(objS)
        }
      })
      if (cicloNum === 1) {
        setFiltrosOrigInvCiclo1(filtros);
      }
      else if (cicloNum === 2)
        setFiltrosOrigInvCiclo2(filtros);
    } catch (error) {
      console.log(error)
    }
  }

  const atras = (params) => {
    props.history.goBack();
  }


  useEffect(() => {
    if (ciclo1 !== "") {
      setFiltrosInvCiclo1([]);
      obtenerDatosInicialesMulti(ciclo1.id, 1)
    }

  }, [ciclo1])
  useEffect(() => {
    if (ciclo2 !== "") {
      setFiltrosInvCiclo2([]);
      obtenerDatosInicialesMulti(ciclo2.id, 2)
    }

  }, [ciclo2])
  useEffect(() => {
    if (!multiCiclo) {
      setCiclo1("")
      setCiclo2("")
      setCiclo1Invernadero("")
      setCiclo2Invernadero("")
      setFiltrosInvCiclo1([]);
      setFiltrosOrigInvCiclo1([]);
      setFiltrosInvCiclo2([]);
      setFiltrosOrigInvCiclo2([]);
    }

  }, [multiCiclo])

  const handleInvernaderoSeleccionado = (e) => {
    setInvernaderoSeleccionado(e.value)
  }
  const handleSelectButtonTipoDato = (e) => {
      setSelectButtonTipoDato(e.value)
  }
  const tipoDeDatoOptions = tipo === "porcentajes" ? selectButtonsComparaciones : selectButtonsRealDiarioSemanal
  return <div class="p-grid chart-page">
    <div className="p-col  p-col-auto title-container">
      <span className="back-btn" onClick={atras} ></span>
      <span className={`icon-general chart-icon `}></span>
      <span className="titles-group">
        <h1 className="header-title">Gráfica de cosecha</h1>
      </span>
    </div>
    <div className="p-col-12">
      <div className="p-grid filtros">

        
        <ContenedorFiltro label="Tipo">
          <SelectButton optionLabel="name" value={tipo} optionValue="code" options={tipos} onChange={e => setTipo(e.value)} />
        </ContenedorFiltro>

        <ContenedorFiltro  filtrolabel = "no"  label = "Tipo de gráfica">
          <ToggleButton  onLabel= {"Multiciclo"} offLabel= {"Por ciclo actual"} checked={multiCiclo} onChange={(ev) => setMultiCiclo(ev.value)} ></ToggleButton>
        </ContenedorFiltro>
       
        <ContenedorFiltro label="dato">
            <SelectButton options={tipoDeDatoOptions} optionLabel="name" value={selectButtonTipoDato} onChange={handleSelectButtonTipoDato} multiple={true}/>
        </ContenedorFiltro>
        {!multiCiclo && 
          <ContenedorFiltro label = "Invernaderos">
            <MultiSelect value={invernaderoSeleccionado} options={invernaderosInternos} optionLabel="nombre" dataKey="id" onChange={handleInvernaderoSeleccionado} 
              selectedItemsLabel={`${invernaderoSeleccionado.length} invernaderos seleccionados`} />
          </ContenedorFiltro>
        }
        {multiCiclo && <ContenedorFiltro  filtrolabel = "no"  label="Primer ciclo">
          <Dropdown value={ciclo1} options={allCiclos} onChange={(e) => setCiclo1(e.target.value)} dataKey="id" optionLabel="nombre" />
        </ContenedorFiltro>}
        {multiCiclo && ciclo1 !== "" && <ContenedorFiltro label={"" + ciclo1.nombre}>
          <MultiSelect value={filtrosInvCiclo1} options={filtrosOrigInvCiclo1} onChange={(e) => setFiltrosInvCiclo1(e.target.value)} dataKey="id" optionLabel="nombre" 
            selectedItemsLabel={`${filtrosInvCiclo1.length} invernaderos seleccionados`}/>
        </ContenedorFiltro>}
        {multiCiclo && <ContenedorFiltro  filtrolabel = "no"  label="Segundo ciclo">
          <Dropdown value={ciclo2} options={allCiclos} onChange={(e) => setCiclo2(e.target.value)} dataKey="id" optionLabel="nombre" />
        </ContenedorFiltro>}
        {multiCiclo && ciclo2 !== "" && <ContenedorFiltro label={"" + ciclo2.nombre}>
          <MultiSelect value={filtrosInvCiclo2} options={filtrosOrigInvCiclo2} onChange={(e) => setFiltrosInvCiclo2(e.target.value)} dataKey="id" optionLabel="nombre" 
            selectedItemsLabel={`${filtrosInvCiclo2.length} invernaderos seleccionados`}/>
        </ContenedorFiltro>}
      </div>

      
      

        
      <div className={`p-col-12 chart-container-filters cosecha-chart`}>

        {!multiCiclo && <CosechaFiltroInvernadero invernaderoSeleccionado={invernaderoSeleccionado} filtros={filtrosInv} filtrosOrig={filtrosOrigInv} tipo={tipo} selectButtonTipoDato={selectButtonTipoDato} />}

        {multiCiclo && ciclo1 !== "" && <CosechaFiltroInvernaderoMultiCiclo cicloPassedIn={ciclo1} filtros={filtrosInvCiclo1} filtrosOrig={filtrosOrigInvCiclo1} tipo={tipo} />}
        {multiCiclo && ciclo2 !== "" && <CosechaFiltroInvernaderoMultiCiclo cicloPassedIn={ciclo2} filtros={filtrosInvCiclo2} filtrosOrig={filtrosOrigInvCiclo2} tipo={tipo} />}

      </div>
    </div>
  </div>

}


export default Cosecha;
