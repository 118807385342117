import React from 'react'

const DatoFormOutput = ({label="", fact=""}) => {
  return (
    <div className="form-group p-col p-col-auto p-0">
        <p className="form-output">{label}</p>
        <div>{fact}</div>
    </div>
  )
}

export default DatoFormOutput