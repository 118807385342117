
export class InvernaderoVirtual {

    constructor(id, nombre,color, habilitado) {
        this.id = id
        this.nombre = nombre
        this.color = color
        this.habilitado = habilitado
    }

}
