import React, { useState, useEffect } from 'reactn';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { useGlobal } from "reactn";
import { InputSwitch } from 'primereact/inputswitch';
import { registrarActividad, guardarActividad, registrarHistorico, obtenerActividad } from '../../../service/actividades';
import { SelectButton } from 'primereact/selectbutton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
let inicial = {
  nombre: "",
  importante: false,
  calidadDeTrabajo: false,
  unidades: ""
}
const unidadesOptions = [{
  label: "Días",
  value: "día"
},
{ label: "Líneas", value: "líneas" },
{ label: "Cajas", value: "cajas" }]


function FormularioActividad(props) {
  const actividadId = props.match.params.actividadID
  const editando = props.match.params.actividadID ? true : false;
  const [, setMostrarModalPermisos] = useGlobal("mostrarModalPermiso")
  const [usuario,] = useGlobal("usuario");
  const [, setNombreModulo] = useGlobal("nombreModulo");
  const [, setCargando] = useGlobal("cargando")

  const [editing, setEditing] = useState(false);
  const [productos, setProductos] = useState([]);
  const [errores, setErrores] = useState([]);
  const [original, setOriginal] = useState();
  const [actividad, setActividad] = useState();
  const [state, setState] = useState(inicial)
  const [productosYVariedades] = useGlobal("productosYVariedades")
  const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
  const [productosBases] = useGlobal("productosBases")
  const [medleysGeneral] = useGlobal("medleysGeneral")


  useEffect(() => {
    setHabilitadoSelectorCiclo(false)
    setNombreModulo("Actividades");
    if (editando) obtenerDatosIniciales()
  }, [])

  useEffect(() => {
    if (productosYVariedades) {
      let pyv = productosBases;
      //pyv = [pyv[0], pyv[1], { ...pyv[5], nombre: "MEDLEYS" }, pyv[2], pyv[3], pyv[4]];

      if (actividad) {
        setState(actividad);
        setOriginal({ ...actividad });
        setProductos(procesarEdicion(actividad, productosBases));
        console.log("# 1: ", procesarEdicion(actividad, productosBases))
        setEditing(true);
      }
      else {
        const rows = pyv.map(producto => ({ ...producto, valor: "" }));
        console.log("# 2: ", rows)
        setProductos(rows);
      }
    }
  }, [productosYVariedades, actividad])

  const puedeEditar = () => usuario.rol === "Administrador";

  const guardar = () => {
    setCargando(true)
    let errores = validar();
    if (errores.length > 0) {
      setErrores(errores);
      return;
    }
    if (!editing) {
      registrarActividad(state, productos, medleysGeneral)
        .then((resp) => {
          setCargando(false)
          props.history.push("/admin/actividades");

        })
        .catch(err => {
          console.log("ERROR AL GUARDAR");
          console.log(err);
          setCargando(false)
          setActividad(null);
        });
    }
    else {
      guardarActividad(state, productos, medleysGeneral).then(res => {
        registrarHistorico(original, state);
        setCargando(false)
        props.history.push("/admin/actividades");
      }).catch(err => {
        console.log("ERROR AL EDITAR");
        if (err.code && err.code === "permission-denied") {
          setMostrarModalPermisos(true);
        }
        console.log(err.code);
        console.log(err.message);
        setCargando(false)
        console.log(err);
      })
    }
  }

  const validar = () => {
    let errores = [];
    const requeridos = ["nombre", "importante", "unidades"];
    for (let x = 0; x < requeridos.length; x++) {
      if (state[requeridos[x]] === "" || state[requeridos[x]] === null) {
        errores.push(requeridos[x]);
      }
    }

    return errores;
  }

  const cancelar = () => {
    setActividad(null);
    setEditing(false);
    props.history.goBack();
  }


  const obtenerDatosIniciales = async () => {
    setCargando(true)
    const act = await obtenerActividad(actividadId)
    setActividad(act)
    setCargando(false)
  }

  const isOriginalVariedad = (variedadID = "") => {
    const variedadesOriginales = [
      "6kJQ8ubz0wcKWtZY5hhv", "c4xmgfozkN7q6y6neXp8", "7K7TVRCbOlJy1ILuu1oI",
      "9U2W1cevGkyGDUjGTgJZ", "0GF73LbWXCyfZHO9qiCF", "Te0VXg91da5qeiBwRPTB"];
    return variedadesOriginales.includes(variedadID);
  }
  const procesarEdicion = (actividad, productosPassed) => {
    const bonos_por_unidad = actividad.bonos_por_unidad;

    productosPassed.forEach(producto => {
      const primeraVariedadID = producto.variedades?.find(variedad => isOriginalVariedad(variedad.id))?.id || "";
      const bono = bonos_por_unidad[primeraVariedadID];
      producto.costo = bono;
    })
    return productosPassed;
  }

  const handleInputChange = (ev) => {
    setErrores([]);
    const stateCopy = Object.assign({}, state);
    let value = ev.target.value;
    stateCopy[ev.target.name] = value;
    setState(stateCopy);
  }

  const inputTextEditor = (props, field) => {
    return <InputText type="text" value={props.rowData[field]} onChange={(e) => onEditorValueChange(props, e.target.value)} disabled={!puedeEditar("")} />;
  }

  const onEditorValueChange = (props, value) => {
    let updated = [...props.value];
    updated[props.rowIndex][props.field] = value;
    setProductos(updated);
  }

  const costoEditor = (props) => {
    return inputTextEditor(props, 'costo');
  }

  const currencyTemplate = (rowData, column) => {
    return rowData[column.field] ? `$${rowData[column.field]}` : ""
  }

  return (
    <div className="p-grid p-justify-center">
      <div className="p-col-12 title-container">
        <span className="back-btn" onClick={props.history.goBack}></span>
        <span className="icon-general activity-icon"></span>
        <span className="titles-group">
          <h1 className="header-title">{editing ? "Modificar" : "crear"} actividad</h1>
        </span>

      </div>
      <div className="p-col-10">
        <div className="p-grid p-fluid ">
          <div className="content-section implementation">
          </div>
          <div className="p-col-12">
            <p className="section-title">Información general</p>
            <div className="card">
              <div className="p-grid">

                <div className="form-group p-col-12 p-md-4 p-sm-6">
                  <label >Nombre</label>
                  <InputText value={state["nombre"]} name="nombre" onChange={handleInputChange} disabled={!puedeEditar("nombre")} />
                  {errores.includes("nombre") && <Message severity="error" text="Este campo es requerido"></Message>}
                </div>
                <div className="form-group p-col-12 p-md-4 p-sm-6">
                  <label>Es actividad importante?</label>
                  <div className="switch-container">
                    <InputSwitch onLabel="Si" offLabel="No" checked={state.importante} onChange={handleInputChange} name="importante" disabled={!puedeEditar("importante")} />
                    <span>{state.importante ? "Si" : "No"}</span>
                  </div>
                </div>
                <div className="form-group p-col-12 p-md-4 p-sm-6">
                  <label>Medir la calidad del trabajo?</label>
                  <div className="switch-container">
                    <InputSwitch onLabel="Si" offLabel="No" checked={state.calidadDeTrabajo} onChange={handleInputChange} name="calidadDeTrabajo" disabled={!puedeEditar("calidadDeTrabajo")} />
                    <span>{state.calidadDeTrabajo ? "Si" : "No"}</span>
                  </div>
                </div>
                <div className="form-group p-col-12 p-md-4 p-sm-6">
                  <label>Unidad de medida</label>
                  <SelectButton value={state.unidades} options={unidadesOptions} onChange={handleInputChange} name="unidades" disabled={!puedeEditar("unidades")} />
                  {errores.includes("unidades") && <Message severity="error" text="Este campo es requerido"></Message>}
                </div>
              </div>
            </div>

          </div>
          <div className="p-col-12">
            <p className="section-title">Costos por producto</p>
            <div className="card p-0">
              <DataTable value={productos}>
                <Column field="nombre" header="Producto" />
                <Column field="costo" header="Costo" editor={costoEditor} body={currencyTemplate} />
              </DataTable>
              {errores.includes("producto") && <Message severity="error" text="Este campo es requerido"></Message>}
            </div>
          </div>
        </div>
      </div>

      <div className="p-col-12">
        <div className="p-grid p-justify-end btn-row">
          <Button label="Cancelar" className="p-button-secondary" onClick={cancelar} />
          {puedeEditar("guardar") && <Button label="Guardar" onClick={guardar} />}

        </div>
      </div>
    </div >)
}

export default FormularioActividad; //300