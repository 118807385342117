import React from 'react';
import { useGlobal } from 'reactn';
import { Dialog } from "primereact/dialog";
import { getNombrePorID } from '../../../util/functions';

const ModalDetalles = (props) => {
    const [locaciones] = useGlobal("locacionesSistemas")
    const [empresas] = useGlobal("empresas")
    const [invernaderosFisicos] = useGlobal("invernaderosFisicos")

    const getContactString = (item) => {
        const arr = [" "];
        if(item.telefono)arr.push(`Teléfono: ${item.telefono}`)
        if(item.anydesk)arr.push(` Anydesk: ${item.anydesk}`)
        return  arr.join(" | ")
    }


    return (
        <Dialog header={`Items del orden # ${props.detalles.orden_num}`} visible={!!props.visible} style={{ width: '50vw', overflow: "scroll" }} onHide={props.hide}>
            <div className="p-grid ">
                {props.detalles?.items?.map((item, index) => {
                    return (
                        <div key={`item-modal-${index}`} className="card item-compras w-100">
                            <div className="p-col-12">
                                <div className="p-grid">
                                    <span className="titles-group">
                                        <h1 className="header-title">
                                            {item.solicitud} ({item.cantidad})
                                        </h1>
                                        <p className="header-subtitle">
                                            {getNombrePorID(empresas, item.empresa)} - {getNombrePorID([...locaciones, ...invernaderosFisicos], item.locacion)}
                                            {getContactString(item)}
                                        </p>
                                        <p className="compras-detalles-text"> {item.detalle} </p>
                                    </span>

                                </div>
                            </div>
                        </div>)
                })}
            </div>
        </Dialog >)
}
export default ModalDetalles;