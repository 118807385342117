import React from "react";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { DataTable } from "primereact/datatable";
import { Row } from "primereact/row";
import ContenedorTabla from "components/common/ContenedorTabla";
import { separadoComasDinero, separadoComas, cuatroDecimales, dosDecimales } from "util/functions";
import { UnidadBase } from "components/common/templates_unidades/UnidadBase.atomo";
import getPromedioSinMateriales from "../utils/getPromedioSinMateriales";
import getPrecioPromedio from "../utils/getPrecioPromedio";

const TablaPreciosAcumuladosProd = ({ data=[], titulo }) => {
  const obtenerFooter = () => {
    let costoMaterialesTotal = 0,
      precioTotal = 0,
      ingresosTotal = 0,
      librasTotal = 0;
    data.forEach((data) => {
      costoMaterialesTotal += data.costoMateriales;
      precioTotal += data.precioTotal;
      ingresosTotal += data.ingresos;
      librasTotal += data.sumaLibras;
    });
    const averagePriceValidData = data.filter(empresa=>empresa.sumaLibras)
    const sumaPreciosPromedio = averagePriceValidData.reduce((acc, empresa) => {
      const { precioTotal, sumaLibras }  = empresa
      const precioPromedio = getPrecioPromedio({ precioTotal, sumaLibras })
      acc = acc+precioPromedio
      return acc
    },0)
    const sumaPrecioPromedioSinMateriales = averagePriceValidData.reduce((acc, empresa) => {
      const { ingresos, sumaLibras }  = empresa
      const precioPromedioSinMateriales = getPromedioSinMateriales({ ingresos, sumaLibras })
      acc = acc+precioPromedioSinMateriales
      return acc
    },0)
    const totalPrecioPromedio = sumaPreciosPromedio / averagePriceValidData.length || 0
    const totalPrecioPromedioSinMateriales = sumaPrecioPromedioSinMateriales/averagePriceValidData.length || 0
    return (
      <ColumnGroup>
        <Row>
          <Column footer="Totales" colSpan={1} />
          <Column
            footer={
              <span>
                ${precioTotal === 0 ? "0" : separadoComasDinero(precioTotal)}
              </span>
            }
            colSpan={1}
          />
          <Column
            footer={
              <span>
                $
                {costoMaterialesTotal === 0
                  ? "0"
                  : separadoComasDinero(costoMaterialesTotal)}
              </span>
            }
            colSpan={1}
          />
          <Column
            footer={
              <span>
                $
                {ingresosTotal === 0 ? "0" : separadoComasDinero(ingresosTotal)}
              </span>
            }
            colSpan={1}
          />
          <Column
            footer={
              <UnidadBase valor={separadoComas(librasTotal)} unidad="lb"/>
            }
            colSpan={1}
          />
          <Column
            footer={
              <UnidadBase valor={`$${cuatroDecimales(totalPrecioPromedio)}`} unidad="/lb"/>
            }
            colSpan={1}
          />
          <Column
            footer={
              <UnidadBase valor={`$${cuatroDecimales(totalPrecioPromedioSinMateriales)}`} unidad="/lb"/>
            }
            colSpan={1}
          />
        </Row>
      </ColumnGroup>
    );
  };
  
  const templateUSD = (rowData, column) => {
    const usdDecimales = cuatroDecimales(rowData[column["field"]]) || 0;
    return `$${usdDecimales === 0 ? "0" : separadoComasDinero(usdDecimales)} `;
  };
  const templatePromedio = (rowData, column) => {
    let precio = rowData.precioTotal;
    let peso = rowData.sumaLibras;
    let promedio = peso === 0 ? 0 : precio / peso;
    return (
      <span>
        ${cuatroDecimales(promedio) || 0}
        <span className="label-unidad">/lb</span>
      </span>
    );
  };
  
  const templateSuma = (rowData, column) => (
    <span>
      {separadoComas(rowData.sumaLibras)}
      <span className="label-unidad">lb</span>
    </span>
  );
  const templateIngresoPromedio = (rowData, column) => {
    let precio = rowData.ingresos
    let peso = rowData.sumaLibras
    let promedio = peso === 0 ? 0 : (precio / peso);
    return <span>${cuatroDecimales(promedio) || 0}<span className="label-unidad"> /lb</span></span>
}
  return (
    <ContenedorTabla  titulo={titulo}>
      <DataTable value={data} footerColumnGroup={obtenerFooter()}>
        <Column header="Cliente" field="nombreCliente" sortable />
        <Column
          header="Ingresos"
          field="precioTotal"
          body={templateUSD}
          sortable
        />
        <Column
          header="Costo de materiales"
          field="costoMateriales"
          body={templateUSD}
          sortable
        />
        <Column
          header="Ingresos sin materiales"
          field="ingresos"
          body={templateUSD}
          sortable
        />
        <Column
          header="Libras totales"
          field="sumaLibras"
          body={templateSuma}
          sortable
        />
        <Column header="Precio promedio" body={templatePromedio} sortable />
        <Column
          header="Precio promedio sin materiales"
          body={templateIngresoPromedio}
          sortable
        />
      </DataTable>
    </ContenedorTabla>
  );
};

export default TablaPreciosAcumuladosProd;
