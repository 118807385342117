export const INVOICE_STATUSES = {
    VALIDADO: "validado",
    PENDIENTE: "pendiente",
    CON_RECHAZO: "con rechazo"
};
export const STATUS = "status";
export const PRECIO_LIBRA = "precio_libra";
export const PRECIO_LIBRA_REAL = "precio_libra_real";
export const CAJAS_CON_RECHAZO = "cajas_con_rechazo";
export const CAJAS_CON_REPROCESO = "cajas_con_reproceso";
export const PRECIO_CON_RECHAZO = "precio_con_rechazo";
export const PRECIO_SIN_RECHAZO = "precio_sin_rechazo";
export const PRECIO_CON_REPROCESO = "precio_con_reproceso";
export const PRECIO_SIN_REPROCESO = "precio_sin_reproceso";

export const valoresValidacionManifiesto =
    [STATUS, PRECIO_LIBRA, PRECIO_LIBRA_REAL, CAJAS_CON_RECHAZO, CAJAS_CON_REPROCESO, PRECIO_CON_RECHAZO, PRECIO_SIN_RECHAZO, PRECIO_CON_REPROCESO, PRECIO_SIN_REPROCESO];


export const RECHAZOS = "rechazos";
export const VALIDADOS = "validados";
export const opcionesRechazoInvoice = [
    { label: "Con rechazos", value: RECHAZOS },
    { label: "Sin rechazos", value: VALIDADOS }];