
import { factorLbKg, segundosSemana } from "../constants";
import { obtenerCiclo } from "./Ciclos";
import { obtenerClientes } from "./Clientes";
import { obtenerInicioSemanaDia, obtenerListaDeSemanas } from "./fechas";
import { obtenerInvernaderosVirtuales } from "./Invernaderos";
import { obtenerItemsInvernaderoPorSemana, obtenerOrdenesCiclo, obtenerPesoInvernaderoPorSemana } from "./Ordenes";
import { obtenerPresentaciones } from "./Presentaciones";
import { obtenerAreaInvernaderoSync } from "./Producto";
import { obtenerCapturaPrevia } from "./Queries/Pronostico";
import { obtenerBudgetGrupoPresentaciones, obtenerBudgetsGruposPresentacionesCiclo, obtenerGruposPresentaciones } from "./QueriesGruposPresentaciones";
import { obtenerManifiestosCiclo } from "./QueriesManifiestos";
import { APROBADO, NO_APROBADO, REPROCESO, RECHAZO, obtenerReportePrecios, } from "./ReportePrecios";

export const obtenerReporteCostosMaterialesSemanal = async (cicloId, invernadero) => {
    let invernaderoId = invernadero.id;
    let invernaderos = await obtenerInvernaderosVirtuales(cicloId);
    let ciclo = await obtenerCiclo(cicloId);
    let ordenes = await obtenerOrdenesCiclo(cicloId)
    let area = obtenerAreaInvernaderoSync(cicloId, invernadero);
    let presentaciones = await obtenerPresentaciones();

    let distribucion = obtenerCostoPresentacionesPorSemana(ciclo, invernadero, presentaciones, ordenes, area);

    return distribucion;

}
export const obtenerReporteCostosMaterialesSemanalConPronosticos = async (cicloId, invernadero) => {
    let ciclo = await obtenerCiclo(cicloId);
    let ordenes = await obtenerOrdenesCiclo(cicloId)
    let area = obtenerAreaInvernaderoSync(cicloId, invernadero);
    let presentaciones = await obtenerPresentaciones();

    let distribucion = await obtenerCostoPresentacionesPorSemanaConPronosticos(ciclo, invernadero, presentaciones, ordenes, area);

    return distribucion;
}

const separarOrdenesPorSemana = (semanas, ordenes) => {
    let ordenesSemanas = semanas.map(semana => {
        let ordenesSemana = [];
        ordenes.forEach(orden => {
            let fechaEnvio = parseInt(orden.fecha_envio);
            // 
            // 
            if (fechaEnvio >= semana.time && fechaEnvio < semana.time + segundosSemana) {
                ordenesSemana.push(orden);
            }
        })
        return { semana: semana, ordenes: ordenesSemana }
    });
    return ordenesSemanas;
}
//obtiene costo total por semana y tambien distribucion de peso por semana de cada presentacion
const obtenerCostoTotalPorSemana = (presentaciones, ordenes, invernadero) => {
    let resultSemana = { total: 0, totalPeso: 0 };
    presentaciones.forEach(presentacion => {
        resultSemana[presentacion.id] = 0;
        ordenes.forEach(orden => {
            orden.items.forEach(item => {
                if (item.presentacion_ref === presentacion.id && item.invernadero_ref === invernadero.id) {
                    let cajas = parseFloat(item.cajas_orden);
                    let peso = parseFloat(presentacion.peso_neto_caja) * factorLbKg;
                    let costo = parseFloat(presentacion.costo_material_lb);

                    let dato = cajas * peso * costo;
                    let datoPeso = cajas * peso;
                    if (!isNaN(dato)) {
                        resultSemana[presentacion.id] += datoPeso;
                        resultSemana["total"] += dato;
                        resultSemana["totalPeso"] += datoPeso;
                    }
                }
            })

        })

    })
    return resultSemana;
}

const obtenerCostoPresentacionesPorSemana = (ciclo, invernadero, presentaciones, ordenes, area) => {
    let budget = invernadero?.budget?.estimacion_semanal;
    let semanas = obtenerListaDeSemanas(ciclo.semana_inicio, ciclo.semana_cierre);
    let ahora = Math.round(Date.now() / 1000);
    let inicioSemana = obtenerInicioSemanaDia(ahora).unix();
    //separar ordenes por la semana a la que pertenecen
    let distribucionCiclo = obtenerDistribucionPresentacionesCicloProductoInvernadero(ciclo.id, invernadero.producto_ref, invernadero.id, ordenes, presentaciones)
    let ordenesSemanas = separarOrdenesPorSemana(semanas, ordenes);




    // 
    // 
    // 
    let result = ordenesSemanas.map(semana => {


        let ordenes = semana.ordenes;
        let resultSemana = { total: 0, total_budget: 0, semana: semana.semana }
        //sumar el costo de kilos ordenados en cada semana por presentacion
        let costoPorSemana = obtenerCostoTotalPorSemana(presentaciones, ordenes, invernadero)
        resultSemana = Object.assign(resultSemana, costoPorSemana);
        //calcular la fraccion que cada presentacion suma al total
        presentaciones.forEach(presentacion => {
            if (resultSemana[presentacion.id] > 0) {
                // 
                // 
                // 
                // 
            }
            resultSemana[`factor_${presentacion.id}`] = resultSemana[presentacion.id] === 0 ? 0 : resultSemana[presentacion.id] / resultSemana["totalPeso"];
        })
        let budgetSemana = budget?.find(el => el.time === semana.semana.time);
        // 
        // 
        // 
        // 
        if (budgetSemana) {
            // 
            // 
            // 
            let suma = 0;
            let lb = parseFloat(budgetSemana.value) * area * 2.2;
            // 
            presentaciones.forEach(presentacion => {
                let factor = resultSemana[`factor_${presentacion.id}`];

                if (inicioSemana < semana.semana.time) {


                    factor = distribucionCiclo[presentacion.id]



                }
                let dato = lb * factor * parseFloat(presentacion.costo_material_lb);
                // if(isNaN(resultSemana[`factor_${presentacion.id}`])){
                //     
                //     
                // }
                // if(isNaN(parseFloat(presentacion.costo_material_lb))){
                //     
                //     
                // }
                // if(!isNaN(dato) && dato > 0){
                //     
                //     
                //     
                //     
                // }
                suma += isNaN(dato) ? 0 : dato;
            })
            // 
            //                                 
            resultSemana[`total_budget`] += suma;
        }
        // obtener resultados por metro cuadrado
        resultSemana["total"] = resultSemana["total"] / area;
        resultSemana["total_budget"] = resultSemana["total_budget"] / area;
        return resultSemana;
    })
    return result;
}
const obtenerCostoPresentacionesPorSemanaConPronosticos = async (ciclo, invernadero, presentaciones, ordenes, area) => {
    let budget = invernadero?.budget?.estimacion_semanal;
    let semanas = obtenerListaDeSemanas(ciclo.semana_inicio, ciclo.semana_cierre);
    let ahora = Math.round(Date.now() / 1000);
    let inicioSemana = obtenerInicioSemanaDia(ahora).unix();
    let pronosticos = { isEmpty: true }
    let indice = 0;
    let semanaActual = inicioSemana;
    pronosticos = await obtenerCapturaPrevia(ciclo.id, invernadero.id, semanaActual);


    //separar ordenes por la semana a la que pertenecen
    let distribucionCiclo = obtenerDistribucionPresentacionesCicloProductoInvernadero(ciclo.id, invernadero.producto_ref, invernadero.id, ordenes, presentaciones)
    let ordenesSemanas = separarOrdenesPorSemana(semanas, ordenes);




    // 
    // 
    // 
    let result = ordenesSemanas.map(semana => {

        let inicio = semana.semana.time;
        indice = (inicio - semanaActual) / segundosSemana
        let ordenes = semana.ordenes;
        let resultSemana = { total: 0, total_budget: 0, semana: semana.semana }
        //sumar el costo de kilos ordenados en cada semana por presentacion
        let costoPorSemana = obtenerCostoTotalPorSemana(presentaciones, ordenes, invernadero)
        resultSemana = Object.assign(resultSemana, costoPorSemana);
        //calcular la fraccion que cada presentacion suma al total
        presentaciones.forEach(presentacion => {
            if (resultSemana[presentacion.id] > 0) {
                // 
                // 
                // 
                // 
            }
            resultSemana[`factor_${presentacion.id}`] = resultSemana[presentacion.id] === 0 ? 0 : resultSemana[presentacion.id] / resultSemana["totalPeso"];
        })
        let budgetSemana = {};
        if (!(inicio >= semanaActual && inicio < semanaActual + 5 * segundosSemana) || (!pronosticos || pronosticos.isEmpty || !pronosticos["pronosticos"][indice])) {
            budgetSemana = budget?.find(el => el.time === semana.semana.time);
            // 
            // 
            // 
            // 
            if (budgetSemana) {
                // 
                // 
                // 
                let suma = 0;
                let lb = parseFloat(budgetSemana.value) * area * 2.2;
                // 
                presentaciones.forEach(presentacion => {
                    let factor = resultSemana[`factor_${presentacion.id}`];

                    if (inicioSemana < semana.semana.time) {


                        factor = distribucionCiclo[presentacion.id]



                    }
                    let dato = lb * factor * parseFloat(presentacion.costo_material_lb);

                    suma += isNaN(dato) ? 0 : dato;
                })
                // 
                //                                 
                resultSemana[`total_budget`] += suma;
            }
        }
        else {
            let suma = 0;
            let lb = parseFloat(pronosticos["pronosticos"][indice]) * area * 2.2;
            // 
            presentaciones.forEach(presentacion => {
                let factor = resultSemana[`factor_${presentacion.id}`];

                if (inicioSemana < semana.semana.time) {


                    factor = distribucionCiclo[presentacion.id]



                }
                let dato = lb * factor * parseFloat(presentacion.costo_material_lb);

                suma += isNaN(dato) ? 0 : dato;
            })
            // 
            //                                 
            resultSemana[`total_budget`] += suma;
        }
        // obtener resultados por metro cuadrado
        resultSemana["total"] = resultSemana["total"] / area;
        resultSemana["total_budget"] = resultSemana["total_budget"] / area;
        return resultSemana;
    })
    return result;
}
const obtenerDistribucionPresentacionesCicloProductoInvernadero = (cicloId, productoId, invernaderoId, ordenes, presentaciones) => {

    let result = {};
    let presentacionesIds = presentaciones.filter(el => el.producto_ref === productoId).map(el => el.id)
    let suma = 0;
    ordenes.forEach(orden => {
        orden.items.forEach(item => {
            if (presentacionesIds.includes(item.presentacion_ref)) {
                let presentacion = presentaciones?.find(el => el.id === item.presentacion_ref);
                let dato = parseFloat(item.cajas_orden) * parseFloat(presentacion.peso_neto_caja);

                if (result[item.presentacion_ref] && !isNaN(dato)) {
                    result[item.presentacion_ref] += dato;
                    suma += dato;
                } else if (!isNaN(dato)) {
                    result[item.presentacion_ref] = dato;
                    suma += dato;
                }
            }
        })
    })
    if (suma > 0) {
        presentacionesIds.forEach(presentacionId => {
            if (result[presentacionId]) {
                result[presentacionId] = result[presentacionId] / suma;
            }
        })
    }
    return result;

}

const obtenerDistribucionPresentacionesPorSemana = (ciclo, invernadero, presentaciones, ordenes, area) => {
    let budget = invernadero?.budget?.estimacion_semanal;
    let semanas = obtenerListaDeSemanas(ciclo.semana_inicio, ciclo.semana_cierre);
    //separar ordenes por la semana a la que pertenecen
    let ordenesSemanas = separarOrdenesPorSemana(semanas, ordenes);

    let result = ordenesSemanas.map(semana => {
        let ordenes = semana.ordenes;
        let resultSemana = { total: 0, total_budget: 0, semana: semana.semana }
        //sumar el costo de kilos ordenados en cada semana por presentacion
        let costoPorSemana = obtenerCostoTotalPorSemana(presentaciones, ordenes, invernadero)
        resultSemana = Object.assign(resultSemana, costoPorSemana);
        //calcular la fraccion que cada presentacion suma al total
        presentaciones.forEach(presentacion => {

            resultSemana[`factor_${presentacion.id}`] = resultSemana[presentacion.id] === 0 ? 0 : resultSemana[presentacion.id] / resultSemana["totalPeso"];
        })
        let budgetSemana = budget?.find(el => el.time === semana.semana.time);

        if (budgetSemana) {

            let suma = 0;
            let lb = parseFloat(budgetSemana.value) * area * 2.2;

            presentaciones.forEach(presentacion => {
                let dato = lb * resultSemana[`factor_${presentacion.id}`] * parseFloat(presentacion.costo_material_lb);

                suma += isNaN(dato) ? 0 : dato;
            })
            // 
            //                                 
            resultSemana[`total_budget`] += suma;
        }
        // obtener resultados por metro cuadrado
        resultSemana["total"] = resultSemana["total"] / area;
        resultSemana["total_budget"] = resultSemana["total"] / area;
        return resultSemana;
    })
    return result;
}

export const obtenerReportePreciosInvernadero = async (cicloId, invernaderos, presentaciones) => {
    // obtener todos los manifiestos 




    let ciclo = await obtenerCiclo(cicloId);
    let semanas = obtenerListaDeSemanas(ciclo.semana_inicio, ciclo.semana_cierre);
    if (invernaderos.length === 0 || presentaciones.length == 0) {
        return [];
    }
    let itemsPorInvernaderos = await obtenerItemsSeparados(cicloId, invernaderos);

    // 
    // 
    // 
    let ordenes = await obtenerOrdenesCiclo(cicloId)
    let gruposPresentaciones = await obtenerGruposPresentaciones();
    let budgetsPresentaciones = await obtenerBudgetsGruposPresentacionesCiclo(cicloId);
    let promises = invernaderos.map(invernadero => {
        return calcularReporteIndividualInvernaderos(ciclo, invernadero, itemsPorInvernaderos, semanas, presentaciones, ordenes,
            gruposPresentaciones, budgetsPresentaciones);
    })
    let result = await Promise.all(promises);
    return result;
}
const calcularReporteIndividualInvernaderos = async (ciclo, invernadero, itemsPorInvernaderos, semanas, presentaciones, ordenes,
    gruposPresentaciones, budgetsPresentaciones) => {
    let area = obtenerAreaInvernaderoSync(ciclo, invernadero)
    let distribucion = obtenerDistribucionPresentacionesPorSemana(ciclo, invernadero, presentaciones, ordenes, area);
    // 
    // 
    // 
    let precioTotal = 0;
    let result = { nombreInvernadero: invernadero.nombre, invernaderoId: invernadero.id, datos: [] }
    let sumaLibras = 0;
    let items = itemsPorInvernaderos[invernadero.id];
    semanas.map(semana => {
        let datoBudget = calcularBudgetPreciosSemana(semana, invernadero, presentaciones, gruposPresentaciones, budgetsPresentaciones, distribucion);
        let resultSemana = { semana: semana.time, precioTotal: 0, sumaLibras: 0 };
        let itemsSemana = items.filter(el => parseInt(el["fecha_envio"]) >= semana.time && parseInt(el["fecha_envio"]) < semana.time + segundosSemana);
        if (itemsSemana.length > 0) {
            //  
            //  
            //  
        }
        itemsSemana.forEach(item => {
            // 
            // 

            let [precio, peso] = calcularPrecioYPeso(presentaciones, item)
            resultSemana["precioTotal"] += precio;
            resultSemana["sumaLibras"] += peso;

        })
        resultSemana["datoBudget"] = datoBudget;
        result["datos"].push(resultSemana);
    })
    result["sumaLibras"] = sumaLibras;
    result["precioTotal"] = precioTotal;
    return result;
    // hacer calculo de cada cliente
}


const calcularBudgetPreciosSemana = (semana, invernadero, presentaciones, gruposPresentaciones, budgetsPresentaciones, distribucion) => {


    let budgetInvernadero = invernadero?.budget?.estimacion_semanal;
    let budgetSemanaInvernadero = budgetInvernadero?.find(el => el.time === semana.time);

    let valorBudgetInv = budgetSemanaInvernadero ? parseFloat(budgetSemanaInvernadero.value) : 0;
    if (isNaN(valorBudgetInv) || valorBudgetInv === 0) {
        return 0;
    }
    let suma = 0;
    presentaciones.forEach(presentacion => {
        let factoresSemana = distribucion?.find(el => el.semana.time === semana.time);

        let grupoPresentacion = gruposPresentaciones?.find(el => el.presentaciones_refs.includes(presentacion.id));
        if (grupoPresentacion) {
            let budgetPresentacion = budgetsPresentaciones?.find(el => el.grupo_presentaciones_ref === grupoPresentacion.id)
            if (budgetPresentacion && budgetPresentacion.budget) {
                let budgetPresentacionSemana = budgetPresentacion.budget?.find(el => el.time == semana.time);
                if (budgetPresentacionSemana) {
                    // 
                    // 
                    // 
                    if (factoresSemana && factoresSemana[`factor_${presentacion.id}`] && !isNaN(factoresSemana[`factor_${presentacion.id}`])) {

                        let valorBudgetPres = parseFloat(budgetPresentacionSemana.value);
                        let dato = valorBudgetPres * factoresSemana[`factor_${presentacion.id}`]
                        if (valorBudgetInv > 0 || valorBudgetPres || factoresSemana[`factor_${presentacion.id}`] > 0) {






                        }
                        if (!isNaN(dato)) {
                            suma += dato
                        }
                    }
                } else {
                    // 
                    //                     
                    // 
                }

            } else {
                // 
                // 
                // 
            }

        }
        else {
            // 
            // 
            // 
        }
    })
    return valorBudgetInv

}

const obtenerItemsSeparados = async (cicloId, invernaderos) => {
    let itemsPorInvernaderos = {};
    invernaderos.forEach(invernadero => {
        itemsPorInvernaderos[invernadero.id] = [];
    })

    let manifiestos = await obtenerManifiestosCiclo(cicloId);

    //    
    //    
    //obtener todos los items de invernadero
    // filtrar los que tienen status calculable
    //separar por clientes
    manifiestos.forEach(manifiesto => {
        if (manifiesto.items?.length > 0) {
            manifiesto.items.forEach(item => {
                if ([APROBADO, RECHAZO, REPROCESO].includes(item.status)) {
                    if (itemsPorInvernaderos[item.invernadero_ref]) {
                        item["fecha_envio"] = manifiesto["fecha_envio"];
                        itemsPorInvernaderos[item.invernadero_ref].push(item);
                    }
                }
            })
        }
    })
    return itemsPorInvernaderos;
}
const calcularPrecioYPeso = (presentaciones, item) => {
    // 
    let presentacion = presentaciones?.find(el => el.id === item.presentacion_ref);
    let precio = 0;
    let peso = 0;
    let peso_neto_caja = presentacion ? parseFloat(presentacion.peso_neto_caja) * factorLbKg : 0;

    if (!isNaN(peso_neto_caja)) {
        if (item.status === APROBADO) {
            precio = parseFloat(item.cajas_orden) * peso_neto_caja * parseFloat(item.precio_libra);
            peso = peso_neto_caja * parseFloat(item.cajas_orden);
        }

        else if (item.status === RECHAZO) {
            precio = parseFloat(item.cajas_orden) * peso_neto_caja * parseFloat(item.precio_con_rechazo);
            peso = peso_neto_caja * parseFloat(item.cajas_orden);
        }
        else if (item.status === REPROCESO) {
            precio = parseFloat(item.cajas_orden) * peso_neto_caja * parseFloat(item.precio_con_reproceso);
            peso = peso_neto_caja * parseFloat(item.cajas_orden);
        }
    }
    return [precio, peso];
}
export const obtenerReporteIngresosBrutosInvernaderos = async (cicloId, invernaderos) => {
    if (invernaderos.length === 0) {
        return [];
    }
    let clientes = await obtenerClientes();
    let idsClientes = clientes.map(el => el.id);
    // 
    let reportePrecios = await obtenerReportePrecios(cicloId, idsClientes)
    // 
    // 
    let ordenes = await obtenerOrdenesCiclo(cicloId);
    let gruposConBudgets = await obtenerGruposDePresentacionesConBudgets(cicloId);
    let promises = invernaderos.map(el => obtenerReporteIngresosBrutosInvernaderoConPronosticos(cicloId, el, reportePrecios, ordenes, gruposConBudgets));
    let result = await Promise.all(promises);
    return invernaderos.map((el, index) => {
        return { invernaderoId: el.id, datos: result[index] }
    });
}
const obtenerGruposDePresentacionesConBudgets = async (cicloId) => {
    let gruposPresentaciones = await obtenerGruposPresentaciones();
    let budgetsGrupos = await obtenerBudgetsGruposPresentacionesCiclo(cicloId)



    let result = gruposPresentaciones.map(grupo => {
        grupo["budget"] = budgetsGrupos?.find(budget => budget["grupo_presentaciones_ref"] === grupo["id"]);
        return grupo;
    })
    return result;
}

export const obtenerReporteIngresosNetosInvernaderos = async (cicloId, invernaderos) => {
    if (invernaderos.length === 0) {
        return [];
    }
    let clientes = await obtenerClientes();
    let idsClientes = clientes.map(el => el.id);
    // 
    let reportePrecios = await obtenerReportePrecios(cicloId, idsClientes)
    // 
    // 
    let ordenes = await obtenerOrdenesCiclo(cicloId);
    let gruposConBudgets = await obtenerGruposDePresentacionesConBudgets(cicloId);
    let promises = invernaderos.map(el => obtenerReporteIngresosNetosInvernaderoConPronosticos(cicloId, el, reportePrecios, ordenes, gruposConBudgets));
    let result = await Promise.all(promises);
    return invernaderos.map((el, index) => {
        return { invernaderoId: el.id, datos: result[index] }
    });
}

const obtenerReporteIngresosBrutosInvernadero = async (cicloId, invernadero, reportePrecios, ordenes, gruposConBudgets) => {
    // 
    let area = obtenerAreaInvernaderoSync(cicloId, invernadero);
    let presentaciones = await obtenerPresentaciones();
    let items = await obtenerItemsInvernaderoPorSemana(cicloId, invernadero);
    let ciclo = await obtenerCiclo(cicloId);
    let distribucion = await obtenerCostoPresentacionesPorSemana(ciclo, invernadero, presentaciones, ordenes, area)

    let reporte = obtenerIngresoInvernaderoPorSemanas(ciclo, items, reportePrecios, presentaciones, area,
        distribucion, invernadero?.budget?.estimacion_semanal, gruposConBudgets,
        invernadero, ordenes);




    // 
    // 
    return reporte;
}
const obtenerReporteIngresosBrutosInvernaderoConPronosticos = async (cicloId, invernadero, reportePrecios, ordenes, gruposConBudgets) => {
    // 
    let area = obtenerAreaInvernaderoSync(cicloId, invernadero);
    let presentaciones = await obtenerPresentaciones();
    let items = await obtenerItemsInvernaderoPorSemana(cicloId, invernadero);
    let ciclo = await obtenerCiclo(cicloId);
    let distribucion = await obtenerCostoPresentacionesPorSemanaConPronosticos(ciclo, invernadero, presentaciones, ordenes, area)

    let reporte = obtenerIngresoInvernaderoPorSemanasConPronosticos(ciclo, items, reportePrecios, presentaciones, area,
        distribucion, invernadero?.budget?.estimacion_semanal, gruposConBudgets,
        invernadero, ordenes);




    // 
    // 
    return reporte;
}
const obtenerReporteIngresosNetosInvernadero = async (cicloId, invernadero, reportePrecios, ordenes, gruposConBudgets) => {
    // 
    let area = obtenerAreaInvernaderoSync(cicloId, invernadero);
    let presentaciones = await obtenerPresentaciones();
    let items = await obtenerItemsInvernaderoPorSemana(cicloId, invernadero);
    let ciclo = await obtenerCiclo(cicloId);
    let distribucion = await obtenerCostoPresentacionesPorSemana(ciclo, invernadero, presentaciones, ordenes, area)
    let brutos = obtenerIngresoInvernaderoPorSemanas(ciclo, items, reportePrecios, presentaciones, area,
        distribucion, invernadero?.budget?.estimacion_semanal, gruposConBudgets,
        invernadero, ordenes);
    let costosMaterial = await obtenerReporteCostosMaterialesSemanal(ciclo.id, invernadero);
    let costoFijoSemanal = 0.12;
    let reporte = procesarIngresosYCostos(ciclo, invernadero, brutos, costosMaterial, costoFijoSemanal);
    //  
    //  
    //  
    //  
    //  
    // 
    // 
    return reporte;
}
const obtenerReporteIngresosNetosInvernaderoConPronosticos = async (cicloId, invernadero, reportePrecios, ordenes, gruposConBudgets) => {
    // 
    let area = obtenerAreaInvernaderoSync(cicloId, invernadero);
    let presentaciones = await obtenerPresentaciones();
    let items = await obtenerItemsInvernaderoPorSemana(cicloId, invernadero);
    let ciclo = await obtenerCiclo(cicloId);
    let distribucion = await obtenerCostoPresentacionesPorSemanaConPronosticos(ciclo, invernadero, presentaciones, ordenes, area)
    let brutos = obtenerIngresoInvernaderoPorSemanasConPronosticos(ciclo, items, reportePrecios, presentaciones, area,
        distribucion, invernadero?.budget?.estimacion_semanal, gruposConBudgets,
        invernadero, ordenes);
    let costosMaterial = await obtenerReporteCostosMaterialesSemanalConPronosticos(ciclo.id, invernadero);
    let costoFijoSemanal = 0.12;
    let reporte = procesarIngresosYCostos(ciclo, invernadero, brutos, costosMaterial, costoFijoSemanal);
    //  
    //  
    //  
    //  
    //  
    // 
    // 
    return reporte;
}
const procesarIngresosYCostos = (ciclo, invernadero, ingresos, costosMaterial, costoFijoSemanalM2) => {
    let area = obtenerAreaInvernaderoSync(ciclo.id, invernadero);
    let result = ingresos.map(el => {



        let semana = el.semana;
        let costoMaterialSemanaM2 = costosMaterial?.find(costo => costo.semana.time === semana);
        let costoTotalM2 = costoMaterialSemanaM2.total;
        let costoTotalBudgetM2 = costoMaterialSemanaM2.total_budget;
        let ingreso = el.ingreso;
        let budget = el.ingresoBudget;
        let neto = ingreso - (costoTotalM2 + costoFijoSemanalM2)
        let netoBudget = budget - (costoTotalBudgetM2 + costoFijoSemanalM2)
        let obj = { semana: el.semana, ingreso: neto, ingresoBudget: netoBudget }
        return obj;
    })
    return result;

}

const obtenerIngresoInvernaderoPorSemanas = (ciclo, items, precios, presentaciones, area, distribucion, estimacionSemanal, gruposConBudgets,
    invernadero, ordenes) => {





    let distribucionCiclo = obtenerDistribucionPresentacionesCicloProductoInvernadero(ciclo.id, invernadero.producto_ref, invernadero.id, ordenes, presentaciones);
    let ahora = Math.round(Date.now() / 1000);
    let semanaActual = obtenerInicioSemanaDia(ahora).unix();
    let semanas = obtenerListaDeSemanas(ciclo.semana_inicio, ciclo.semana_cierre);
    let result = semanas.map((semana, index) => {
        let budgetProdSemana = estimacionSemanal?.find(el => el.time === semana.time);
        let ingreso = 0;
        let ingresoBudget = 0;
        let precio = precios?.find(el => el.semana === semana.time);
        let distribucionSemana = distribucion[index];
        let itemsSemana = items[semana.time];

        presentaciones.forEach(presentacion => {
            let grupo = gruposConBudgets?.find(el => el.presentaciones_refs.includes(presentacion.id));
            if (grupo && grupo["budget"]) {
                let budgetPrecioSemana = grupo["budget"]["budget"]?.find(el => el.time === semana.time);
                if (budgetPrecioSemana && budgetProdSemana) {
                    let valorPrecio = parseFloat(budgetPrecioSemana["value"])
                    let valorBudget = parseFloat(budgetProdSemana["value"])

                    let distribucionPresentacion = parseFloat(distribucionSemana[`factor_${presentacion.id}`])
                    if (semanaActual < semana.time) {
                        distribucionPresentacion = distribucionCiclo[presentacion.id]
                    }
                    if (!(isNaN(valorPrecio) || isNaN(valorBudget) || isNaN(distribucionPresentacion))) {
                        let result = valorPrecio * valorBudget * distribucionPresentacion;
                        ingresoBudget += result;
                    }
                }
            }
        })

        itemsSemana.forEach(item => {
            let presentacion = presentaciones?.find(el => el.id === item.presentacion_ref)
            let cajas = parseFloat(item.cajas_orden);
            let pesoCaja = parseFloat(presentacion.peso_neto_caja) * factorLbKg
            let peso = cajas * pesoCaja;
            let precioItem = precio[item.presentacion_ref]["valorReal"];
            let ingresoItem = peso * precioItem;
            ingreso += ingresoItem;
        })
        ingreso = ingreso / area;
        ingresoBudget = ingresoBudget;
        // 
        // 
        // 
        // 

        return { semana: semana.time, ingreso, ingresoBudget }
    })
    return result
}
const obtenerIngresoInvernaderoPorSemanasConPronosticos = (ciclo, items, precios, presentaciones, area, distribucion, estimacionSemanal, gruposConBudgets,
    invernadero, ordenes) => {





    let distribucionCiclo = obtenerDistribucionPresentacionesCicloProductoInvernadero(ciclo.id, invernadero.producto_ref, invernadero.id, ordenes, presentaciones);
    let ahora = Math.round(Date.now() / 1000);
    let semanaActual = obtenerInicioSemanaDia(ahora).unix();
    let semanas = obtenerListaDeSemanas(ciclo.semana_inicio, ciclo.semana_cierre);
    let result = semanas.map((semana, index) => {
        let budgetProdSemana = estimacionSemanal?.find(el => el.time === semana.time);
        let ingreso = 0;
        let ingresoBudget = 0;
        let precio = precios?.find(el => el.semana === semana.time);
        let distribucionSemana = distribucion[index];
        let itemsSemana = items[semana.time];

        presentaciones.forEach(presentacion => {
            let grupo = gruposConBudgets?.find(el => el.presentaciones_refs.includes(presentacion.id));
            if (grupo && grupo["budget"]) {
                let budgetPrecioSemana = grupo["budget"]["budget"]?.find(el => el.time === semana.time);
                if (budgetPrecioSemana && budgetProdSemana) {
                    let valorPrecio = parseFloat(budgetPrecioSemana["value"])
                    let valorBudget = parseFloat(budgetProdSemana["value"])

                    let distribucionPresentacion = parseFloat(distribucionSemana[`factor_${presentacion.id}`])
                    if (semanaActual < semana.time) {
                        distribucionPresentacion = distribucionCiclo[presentacion.id]
                    }
                    if (!(isNaN(valorPrecio) || isNaN(valorBudget) || isNaN(distribucionPresentacion))) {
                        let result = valorPrecio * valorBudget * distribucionPresentacion;
                        ingresoBudget += result;
                    }
                }
            }
        })

        itemsSemana.forEach(item => {
            let presentacion = presentaciones?.find(el => el.id === item.presentacion_ref)
            let cajas = parseFloat(item.cajas_orden);
            let pesoCaja = parseFloat(presentacion.peso_neto_caja) * factorLbKg
            let peso = cajas * pesoCaja;
            let precioItem = precio[item.presentacion_ref]["valorReal"];
            let ingresoItem = peso * precioItem;
            ingreso += ingresoItem;
        })
        ingreso = ingreso / area;
        ingresoBudget = ingresoBudget;
        // 
        // 
        // 
        // 

        return { semana: semana.time, ingreso, ingresoBudget }
    })
    return result
}

