import React, { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import CardCumplimiento from "./componentes/CardCumplimiento.mole"
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { obtenerResumenCumplimiento } from '../../service/CumplimientoCards';
import { obtenerDatosSobrantes, procesarDatosOrdenesVenta, procesarFinal, procesarProyectado } from '../../service/Cumplimiento';
import { DeepClone, dosDecimales, getObjetoPorID } from '../../util/functions';
import { obtenerSobranteSemanaPasada, obtenerDisponibleFinalFijo, obtenerOrdenesSemanaOrdenadosDesc } from '../../service/Ordenes';
import { obtenerCliente, obtenerClientes, obtenerClientesCiclo } from 'service/Clientes';
import { obtenerPresentaciones } from 'service/Presentaciones';

const CardsCumplimiento = (params) => {
    const [ciclo] = useGlobal("ciclo");
    const [, setCargando] = useGlobal("cargando");
    const [productos, setProductos] = useState([]);
    const [weeks, week, handleWeekChange] = useWeekSelector();
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
    const [invernaderosFisicos] = useGlobal("invernaderosFisicos");

    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [productosYVariedades] = useGlobal("productosYVariedades")
    const [productosBases] = useGlobal("productosBases")
    const [presentaciones] = useGlobal("presentaciones")
    const [medleysGeneral] = useGlobal("medleysGeneral")


    useEffect(() => {
        if (productosYVariedades && week?.nombre !== "Cargando") {
            obtenerDatosIniciales();
        }
    }, [productosYVariedades, week])
    useEffect(() => {
        setNombreModulo("Cumplimiento");
        setHabilitadoSelectorCiclo(true)
    }, [])
    const obtenerDatosIniciales = async (params) => {
        try {
            setCargando(true);
            let promises = [];
            let [clientesOrig, presentacionesResp, clientesDelCiclo] = await Promise.all([obtenerClientes(), obtenerPresentaciones(), obtenerClientesCiclo(ciclo)]);
            let idsClientes = clientesDelCiclo.map(el => el.cliente_ref);
            let clientes = clientesOrig.filter(el => idsClientes.includes((el.id)))

            const [resumen, sobrantes, disponiblesFinales] = await Promise.all([
                obtenerResumenCumplimiento(ciclo, week.time, invernaderosVirtuales, presentacionesResp, clientes),
                obtenerSobrantesInvernaderos(ciclo), obtenerDisponiblesFinalesFijos(ciclo)]);
            console.log("obtenerDatosIniciales: ", resumen)

            productosBases.forEach(producto => {
                promises.push(obtenerResumenProducto(resumen, producto, sobrantes, disponiblesFinales, clientesOrig));
            })
            //console.log("sobrantes: ",sobrantes)
            let values = await Promise.all(promises);
            setProductos(values);
        } catch (error) {

        } finally {
            setCargando(false);
        }
    }
    const obtenerSobrantesInvernaderos = async (ciclo) => {

        let invernaderos = invernaderosVirtuales;
        let promesas = invernaderos.map(el => obtenerSobranteSemanaPasada(ciclo, el.id, week.time));
        let sobrantes = await Promise.all(promesas);
        return invernaderos.map((el, index) => {
            return { invernadero: el, sobrante: sobrantes[index] }
        })
    }
    const obtenerDisponiblesFinalesFijos = async (ciclo) => {
        let invernaderos = invernaderosVirtuales;
        let promesas = invernaderos.map(el => obtenerDisponibleFinalFijo(ciclo, el.id, week.time));
        let disponibles = await Promise.all(promesas);
        return invernaderos.map((el, index) => {
            return { invernadero: el, disponible: disponibles[index] }
        })
    }

    const obtenerResumenProducto = async (resumen, producto, sobrantes, disponibles, clientesOrig) => {
        let sobrante = sobrantes?.reduce((acc, curr) => {
            const invernaderoProducto = getObjetoPorID(productosYVariedades, curr.invernadero.producto_ref);

            if ((curr.invernadero.producto_ref === producto.id || (invernaderoProducto.mezcla && producto.MEDLEYS_GENERAL)) && curr.sobrante.sobrante) {
                //console.log("OTHER");
                if (curr.invernadero?.es_externo) {
                    let sumaSobrantesLort = 0
                    Object.keys(curr.sobrante?.sobrante).forEach(empaqueId => {
                        sumaSobrantesLort += curr.sobrante?.sobrante[empaqueId] || 0;
                    });
                    //console.log("KEY: ", sumaSobrantesLort)
                    return acc + sumaSobrantesLort;
                }
                else return acc + curr.sobrante.sobrante
            } else {
                //console.log("OTHER else")
                return acc;
            }
        }, 0)
        let finalFijo = disponibles.reduce((acc, curr) => {
            const invernaderoProducto = getObjetoPorID(productosYVariedades, curr.invernadero.producto_ref);

            if ((curr.invernadero.producto_ref === producto.id || (invernaderoProducto.mezcla && producto.MEDLEYS_GENERAL))
                && curr.disponible.disponible_final) {

                return acc + curr.disponible.disponible_final
            } else {
                return acc;
            }
        }, 0)

      //  console.log("BEGIN 0: ")
     //  
        //let final = procesarFinal(producto, resumen.final);

       // console.log("BEGIN 1: ")
        ///////
        const invernaderosDeProducto = filtrarInvernaderos(producto);
        const [sobrantesResp, /* proyectado, */ ordenesDeVentaSemana] =
            await Promise.all([obtenerDatosSobrantes(invernaderosVirtuales, ciclo, week), obtenerOrdenesSemanaOrdenadosDesc(ciclo, week.time)]);
        const sitiosEmpaques = [
            { nombre: "Sin Asignar", id: "sin" },
            {
                id: "CUq9lwDbplIRVuVjeOiL",
                locacion_ref: "DDCtYSYz7u2qEVI5RyRx",
                nombre: "Sitio A",
            },
            {
                id: "KSAYGvBTFfoTrVjzpJHd",
                locacion_ref: "DDCtYSYz7u2qEVI5RyRx",
                nombre: "Sitio T",
            },
            {
                id: "SCkIi2SnCvdAw3Xm4PLi",
                locacion_ref: "f9QKk2UuLMf5FkB4IfMH",
                nombre: "Empaque Agropark",
            }
        ];
        const defaultInvDatos = { totalesCajas: [0, 0, 0, 0, 0, 0, 0], totalesKilos: [0, 0, 0, 0, 0, 0, 0] };

       // console.log("00 ---:", { ordenesDeVentaSemana, invernaderosFisicos, invernaderosDeProducto, producto, sobrantesResp, clientesOrig, sitiosEmpaques })
        const cumplimientoProductoDatos = procesarDatosOrdenesVenta(DeepClone(ordenesDeVentaSemana), invernaderosFisicos, invernaderosDeProducto, producto, sobrantesResp, clientesOrig, sitiosEmpaques);
       // console.log("11 ---:", { cumplimientoProductoDatos, ordenesDeVentaSemana, invernaderosFisicos, invernaderosDeProducto, producto, sobrantesResp, clientesOrig, sitiosEmpaques })


        const { totalesCajas = 0, totalesKilos = 0, sobrantePasadaTotal = 0, ...cumplimientoDatos } = cumplimientoProductoDatos?.presentacionesDatos;
        //console.log("22 ---: cumplimientoProductoDatos: ", {totalesKilos, sobrantePasadaTotal, proy: resumen.proyectado})

        const proyectadoInv = procesarProyectado(resumen.proyectado, invernaderosDeProducto);
          console.log("proyectadoInv", {projected: resumen.proyectado, proyectadoInv})

        const final = procesarFinal(totalesKilos, proyectadoInv, sobrantePasadaTotal);
        //setCumplimientoSemanaDatos(cumplimientoProductoDatos)
        ///////


        console.log("!!!DONE AT LAST cumplimientoProductoDatos:", {final, proyectadoInv, sobrante })
        producto.final = proyectadoInv;
        producto.sobrante = sobrante;
        producto.finalFijo = finalFijo;
        //producto.final[5] += sobrante;
        return { ...producto, final,  sobrante, finalFijo };
    }
    /*  const procesarProyectado = (producto, proyectados) => {
         let result = [0, 0, 0, 0, 0, 0];
 
         proyectados = proyectados.filter((proyectado) => {
             return producto.id === proyectado.invernadero.producto_ref || (producto.MEDLEYS_GENERAL && proyectado.invernadero.mezcla);
         })
 
         proyectados.forEach((inv) => {
             inv.proyeccion.forEach((dia, index) => {
                 let dato = parseFloat(dia.valor);
                 if (!isNaN(dato)) {
                     result[index] += dato
                 }
             })
 
         })
         return result.map(el => dosDecimales(el));
     } */
    const filtrarInvernaderos = (prod) => {
        const filtrados = invernaderosVirtuales.filter(inv => inv.producto_ref === prod.id || (prod.MEDLEYS_GENERAL && (inv.mezcla || inv.MEDLEYS_VIEJO)));
        return filtrados
    }
    const procesarFinal2 = (producto, finales) => {
        let result = [0, 0, 0, 0, 0, 0];


        finales = finales.filter((final) => {
            return producto.id === final.invernadero.producto_ref || (producto.MEDLEYS_GENERAL && final.invernadero.mezcla);
        })

        finales.forEach((inv) => {
            inv.final.forEach((dia, index) => {
                let dato = parseFloat(dia);
                if (!isNaN(dato)) {
                    result[index] += dato
                }
            })

        })
        return result.map(el => dosDecimales(el));
    } /**/
    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="p-grid filtros">
                    <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
                </div>
            </div>
            <div className="p-col-12">
                <div className="p-grid">
                    {productos.map(producto => <CardCumplimiento key={producto.id} producto={producto} />)}
                </div>
            </div>
        </div>)
}
export default CardsCumplimiento;