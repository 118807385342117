import * as firebase from "firebase/app";
import "firebase/firestore";
import { setGlobal, getGlobal } from "reactn";
import { ordenarArreglaDeObjetosPorLlave, porcentaje } from "../util/functions";
import { uploadStatistics } from './Estadisticas'
import { flexDate } from "./fechas";

function registrarInvocaciones(count) {
    uploadStatistics(count)
}

const db = () => firebase.firestore()
const biocontrol = () => db().collection("biocontrol")

const nombreCancer = "Cáncer bacteriano (Clavibacter)";
const nombreVirusRugoso = "Virus del rugoso";
const nombreVirusMosaico = "Virus del mosaico";
export const estatusesOrdenesAplicacion = ["Activo", "Finalizado"]

export const obtenerProductosDeAplicacion = () => obtenerDatosBiocontrol("productos_aplicacion", "catalogoProductos", true)
export const obtenerMetodosDeAplicacion = () => obtenerDatosBiocontrol("metodos_aplicacion", "metodosDeAplicacion")
export const obtenerUnidadesAplicacionBiocontrol = () => obtenerDatosBiocontrol("unidades_aplicacion", "unidades")
export const obtenerDatosBiocontrol = async (nombreColeccion, nombreVarGlobal, done = false) => {
    let response = await biocontrol().doc("ordenes_aplicacion").collection(nombreColeccion).orderBy("nombre", "asc").get()

    registrarInvocaciones(response.size)
    let datos = response.docs.map(el => ({ id: el.id, ...el.data() }))

    setGlobal({ [nombreVarGlobal]: datos })
    return datos
}

export const obtenerProductoAplicacion = async (productoID) => {
    let response = await biocontrol().doc("ordenes_aplicacion").collection("productos_aplicacion").doc(productoID).get()
    registrarInvocaciones(response.size)
    return response.exists ? { ...response.data() } : undefined
}
export const guardarProductoAplicacion = async (datos, productoID) => {
    productoID = productoID || biocontrol().doc().collection("productos_aplicacion").doc().id
    await biocontrol().doc("ordenes_aplicacion").collection("productos_aplicacion")
        .doc(productoID).set({ ...datos }, { merge: true }).catch(err => { return err })
}

export const obtenerEnfermedadesYPlagas = async () => {
    let response = await db().collection("enfermedades_plagas").orderBy("nombre", "asc").get()

    registrarInvocaciones(response.size)
    let enfermedadesYPlagas = response.docs.map(el => ({ id: el.id, ...el.data() }))

    setGlobal({ enfermedadesYPlagas })
    return enfermedadesYPlagas
}

export const obtenerEnfermedadesYPlagasOrdenPrioridad = async () => {
    let response = await db().collection("enfermedades_plagas").orderBy("prioridad", "asc").get()
    registrarInvocaciones(response.size)
    let enfermedadesYPlagas = response.docs.map(el => ({ id: el.id, ...el.data() }))
    return enfermedadesYPlagas
}

export const obtenerRegistroEnfermedades = async (ciclo, invernaderoID, semana, registroslocales = [], guardar = true) => {
    const timestamps = flexDate(semana)
    let found = registroslocales.find(el => (el.ciclo === ciclo && el.semana >= timestamps.inicio && el.semana < timestamps.fin && el.invernaderoID === invernaderoID))

    if (!found) {
        let response = await db().collection("ciclos").doc(ciclo).collection("biocontrol")
            .where("invernadero_fisico_ref", "==", invernaderoID)
            .where("semana", ">=", timestamps.inicio).where("semana", "<", timestamps.fin).get()//.catch(err => { return err })
        registrarInvocaciones(response.size)

        if (response.docs.length === 0) return guardar ? guardarRegistroClaroBiocontrol(registroslocales, ciclo, semana, invernaderoID, []) : []

        let registroEnfermedadesId = response.docs[0].id
        response = await db().collection("ciclos").doc(ciclo).collection("biocontrol")
            .doc(registroEnfermedadesId).collection("registro_enfermedades").get()
        registrarInvocaciones(response.size)

        let claros = response.docs.map(el => ({ id: el.id, ...el.data() }))
        return guardar ? guardarRegistroClaroBiocontrol(registroslocales, ciclo, semana, invernaderoID, claros) : claros
    }
    else {
        return found.claros
    }
}
const guardarRegistroClaroBiocontrol = (registros, ciclo, semana, invernaderoID, claros) => {
    let registrosClarosBiocontrol = [...registros]
    registrosClarosBiocontrol.push({ ciclo, semana, invernaderoID, claros })
    setGlobal({ registrosClarosBiocontrol })
    return claros
}
export const obtenerResumenEnfermedadesTodos = async (invernaderosFisicos, ciclo, semana, enfermedadesPlagas, registroslocales) => {
    // iterate over each invernadero and get claros
    let registrosClaros = []
    for (const index in invernaderosFisicos) {
        registrosClaros.push(await obtenerRegistroEnfermedades(ciclo, invernaderosFisicos[index].id, semana, registroslocales, false))
        await new Promise(resolve => setTimeout(resolve, 100));
    }

    // let promises = invernaderosFisicos.map(inv => obtenerRegistroEnfermedades(ciclo, inv.id, semana, registroslocales, false))
    // let registrosClaros = await Promise.all(promises).catch(err => { 
    //     console.log("cacho error")
    //     console.log(err)
    //     return err })

    let registrosClarosBiocontrol = [...registroslocales]
    let resumenClaros = registrosClaros.map((claros, index) => {

        let invernadero = invernaderosFisicos[index]
        registrosClarosBiocontrol.push({ ciclo, semana, invernaderoID: invernadero.id, claros })
        let infoResumen = obtenerInfoDeResumen(invernadero, enfermedadesPlagas, claros)
        return infoResumen
    })
    setGlobal({ registrosClarosBiocontrol })

    return resumenClaros
}
export const obtenerResumenEnfermedades = (invernadero, ciclo, semana, enfermedadesPlagas, registroslocales = []) => {
    return new Promise(async (resolve, reject) => {
        // Alan test de cambio de registro locales
        let claros = await obtenerRegistroEnfermedades(ciclo, invernadero.id, semana, registroslocales, true)
        let infoResumen = obtenerInfoDeResumen(invernadero, enfermedadesPlagas, claros)
        resolve(infoResumen)
    })
}
export const obtenerInfoHeatmap = (invernadero, enfermedadID, claros = []) => {
    const clarosEnfermos = claros.filter(el => el.enfermedades?.some(enf => enf.enfermedad_ref === enfermedadID))
    const totalClaros = obtenerTotalClaros(invernadero)
    return  porcentaje(clarosEnfermos.length, totalClaros)
}
export const obtenerInfoDeResumen = (invernadero, enfermedadesPlagas, claros) => {
    let enfermedades = obtenerCantidadEnfermedades(claros)
    let clarosEnfermos = claros.filter(el => (el.enfermedades && el.enfermedades.length > 0))
    let totalClaros = obtenerTotalClaros(invernadero)
    
    let cancerID = enfermedadesPlagas.find(el => el.nombre === nombreCancer)?.id
    let virusMosaicoId = enfermedadesPlagas.find(el => el.nombre === nombreVirusMosaico)?.id
    let totalCancer = clarosEnfermos.filter(el => el.enfermedades.some(enf => enf.enfermedad_ref.includes(cancerID))).length
    let totalVirusMosaico = clarosEnfermos.filter(el => el.enfermedades.some(enf => enf.enfermedad_ref.includes(virusMosaicoId))).length

    return {
        enfermedades, invernadero,
        incidencia: porcentaje(clarosEnfermos.length, totalClaros),
        cancer: porcentaje(totalCancer, totalClaros),
        virus_mosaico: porcentaje(totalVirusMosaico, totalClaros),
    }
}
const obtenerCantidadEnfermedades = (claros) => {
    let todasEnfermedades = [];
    claros.forEach(claro => {
        todasEnfermedades.push(...claro.enfermedades.map(el => el.enfermedad_ref))
    })
    let set = new Set(todasEnfermedades);
    return set.size
}
const obtenerTotalClaros = (invernadero) => {
    let totalClaros = 0
    invernadero.capillas.forEach(capilla => {
        const clarosNorte = capilla.lineas_norte * invernadero.claros_por_linea_norte
        const clarosSur = capilla.lineas_sur * invernadero.claros_por_linea_sur
        totalClaros += (clarosNorte + clarosSur)
    })
    return totalClaros
}

export const getIncidencia = (registrosClaros, inv) => {
    let totalClaros = 0, clarosEnfermos = 0;
    inv.capillas.forEach(capilla => {
        const clarosNorte = capilla.lineas_norte * inv.claros_por_linea_norte
        const clarosSur = capilla.lineas_sur * inv.claros_por_linea_sur
        const clarosCapilla = clarosNorte + clarosSur
        totalClaros += clarosCapilla
    })

    const enfermedades = new Set()
    registrosClaros.forEach(claro => {
        if (claro.enfermedades?.length > 0) clarosEnfermos++
        claro.enfermedades.forEach(enfermedad => {
            enfermedades.add(enfermedad.enfermedad_ref);
        })
    })

    return { totalClaros, clarosEnfermos, enfermedades }
}

export const obtenerResumenEnfermedadesCardBiocontrol = async (ciclo, invernadero, semana) => {
    try {
        const invernaderosFisicos = getGlobal().invernaderosFisicos;
        const invernaderoFisico = invernaderosFisicos.find(el => el.id === invernadero.invernadero_fisico_ref) || {}
        let enfermedadesYPlagas = getGlobal().enfermedadesYPlagas || await obtenerEnfermedadesYPlagas()

        let resumen = await obtenerResumenEnfermedades(invernaderoFisico, ciclo, semana, enfermedadesYPlagas);
        let { enfermedades, incidencia, cancer, virus_rugoso } = resumen;
        let copia = { invernadero: invernaderoFisico, enfermedades, incidencia, cancer, virus_rugoso };
        return copia;
    } catch (error) {
        throw error;
    }
}
export const obtenerClarosSemana = async (ciclo, invernaderoID, semana) => {
    let response = await db().collection("ciclos").doc(ciclo).collection("biocontrol")
        .where("invernadero_fisico_ref", "==", invernaderoID).where("semana", "==", semana).get();
    registrarInvocaciones(response.size)
    if (response.empty) { return { isEmpty: true } }
    response = await db().doc(response.docs[0].ref.path).collection("registro_enfermedades").get();
    registrarInvocaciones(response.size)

    let claros = response.docs.map(el => ({ id: el.id, ...el.data() }))
    return claros;
}
export const getClarosSemana = async (ciclo, semana) => {
    let response = await db().collection("ciclos").doc(ciclo).collection("biocontrol").where("semana", "==", semana).get();
    if (response.empty) { return { isEmpty: true } }
    registrarInvocaciones(response.size)

    const promisesEnfermedades = [];
    for (const index in response.docs) {
        const registrosInv = response.docs[index];

        const promiseEnfermedades = new Promise(async (res, rej) => {
            const responseEnfermedades = await db().doc(registrosInv.ref.path).collection("registro_enfermedades").get()
            const enfermedades = responseEnfermedades.docs.map(el => ({ id: el.id, ...el.data() }));
            registrarInvocaciones(enfermedades.length)
            //  console.log(JSON.stringify(enfermedades[0]))
            res({ [registrosInv.data().invernadero_fisico_ref]: enfermedades })
        })
        promisesEnfermedades.push(promiseEnfermedades)
    }
    const enfermedadesClaros = await Promise.all(promisesEnfermedades)
    return enfermedadesClaros;
}

export const obtenerOrdenesAplicacionDeSemana = async (ciclo, invernaderoID, semana) => {
    let response = await db().collection("ciclos").doc(ciclo).collection("biocontrol_ordenes_aplicacion")
        .where("invernadero_fisico_ref", "==", invernaderoID).where("week.time", "==", semana).orderBy("orden_num", "asc").get()
    registrarInvocaciones(response.size)

    return response.docs.map(el => ({ id: el.id, ...el.data() }))
}
export const obtenerOrdenAplicacion = async (ciclo, aplicacionID) => {
    let response = await db().collection("ciclos").doc(ciclo).collection("biocontrol_ordenes_aplicacion").doc(aplicacionID).get()
    registrarInvocaciones(response.size)

    return response.exists ? { ...response.data() } : null
}

export const guardarOrdenAplicacion = async (ciclo, datos, aplicacionID) => {
    aplicacionID = aplicacionID || db().collection("ciclos").doc().collection("biocontrol_ordenes_aplicacion").doc().id
    await db().collection("ciclos").doc(ciclo).collection("biocontrol_ordenes_aplicacion").doc(aplicacionID).set({ ...datos }, { merge: true })
}

export const eliminarOrdenAplicacionBiocontrol = async (ciclo, aplicacionID) =>
    await db().collection("ciclos").doc(ciclo).collection("biocontrol_ordenes_aplicacion").doc(aplicacionID).delete();

export const obtenerRegistrosEnfermedadesPorInvernaderoCiclo = async (ciclo, invernaderoID) => {
    const response = await firebase.firestore().collection("ciclos").doc(ciclo).collection("biocontrol").where("invernadero_fisico_ref", "==", invernaderoID).get();
    const enfermedadesInvernadero = response.docs.map(el => ({ id: el.id, ...el.data() }));
    ordenarArreglaDeObjetosPorLlave(enfermedadesInvernadero, "semana")

    const promises = enfermedadesInvernadero?.map(registro => obtenerRegistrosEnfermedades(ciclo, registro.id, registro.semana));
    const registrosEnfermedades = await Promise.all(promises);
    return registrosEnfermedades;
}

export const obtenerRegistrosEnfermedades = async (ciclo, biocontrolDocID, semana) => {
    const response = await firebase.firestore().collection("ciclos").doc(ciclo).collection("biocontrol").doc(biocontrolDocID).collection("registro_enfermedades").get();
    const registrosEnfermedades = response.docs.map(el => ({ id: el.id, ...el.data(), semana }));
    //console.log("LENGTH: ",registrosEnfermedades.length)
    return registrosEnfermedades;
}


//Formato de información dosis para productos de ordenes de aplicación
export const dosisInicial = [
    {
        detalles: [{ nombre: "d400b", valor: "" }, { nombre: "d500b", valor: "" }, { nombre: "d600b", valor: "" }],
        nombre: "bajo",
        valor: ""
    },
    {
        detalles: [{ nombre: "d400m", valor: "" }, { nombre: "d500m", valor: "" }, { nombre: "d600m", valor: "" }],
        nombre: "medio",
        valor: ""
    },
    {
        detalles: [{ nombre: "d400a", valor: "" }, { nombre: "d500a", valor: "" }, { nombre: "d600a", valor: "" }],
        nombre: "alto",
        valor: ""
    }
]//249