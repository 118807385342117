import * as firebase from "firebase/app";
import "firebase/firestore";
import {uploadStatistics} from '../Estadisticas'

function registrarInvocaciones(count){
    uploadStatistics(count)
}

export const obtenerSobrantesPorSemana = async (cicloId) => {
    const db = firebase.firestore();
    let snap = await db.collection("ciclos").doc(cicloId).collection("sobrantes_semana").get();
    registrarInvocaciones(snap.size)
    let result = snap.docs.map(el=>({id:el.id, ...el.data()}))
    return result;
}
