import React, { useState, useEffect } from "react";
import { useGlobal } from 'reactn';
import Chart from "react-apexcharts";
import { obtenerPresentaciones } from "../../service/Presentaciones";
import { obtenerOrdenesCiclo } from '../../service/Ordenes';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { obtenerClientes, obtenerClientesCiclo } from "../../service/Clientes";
import { dosDecimales, paletaColoresExpandida, separadoComas } from "../../util/functions";
import ContenedorFiltro from "../common/ContenedorFiltro";
import { SelectButton } from "primereact/selectbutton";
import { obtenerGruposPresentaciones } from "../../service/QueriesGruposPresentaciones";
import { ToggleButton } from 'primereact/togglebutton';
import DistribucionPresentacionesMulticiclo from './DistribucionPresentacionesMulticiclo'
import { hayMedleysViejo } from "../../service/ProductosYVariedades";
const series = [13, 12, 50]
const tipos = [{ name: "Grupos de presentaciones", code: "grupos" }, { name: "Presentaciones", code: "presentaciones" }]
const DistribucionPresentacionesCiclo = (props) => {
  const [invernaderos, setInvernaderos] = useState([]);
  const [filtroProducto, setFiltroProducto] = useState({});
  const [filtroVariedad, setFiltroVariedad] = useState([]);
  const [filtroInvernadero, setFiltroInvernadero] = useState([]);
  const [presentaciones, setPresentaciones] = useState([]);
  const [ordenes, setOrdenes] = useState([]);
  const [ordenesOrig, setOrdenesOrig] = useState([]);
  const [clientesOrig, setClientesOrig] = useState([]);
  const [filtroCliente, setFiltroCliente] = useState([]);
  const [esMulticiclo, setEsMulticiclo] = useState(false);

  const [ciclo, setCiclo] = useGlobal("ciclo");
  const [, setCargando] = useGlobal("cargando");
  const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
  const [productosMezclados] = useGlobal("productosMezclados");

  const [productosBases] = useGlobal("productosBases");
  const [tipo, setTipo] = useState(tipos[0]["code"])
  const [gruposPresentaciones, setGruposPresentaciones] = useState([])

  const optionsOrig = {

    colors: paletaColoresExpandida,
    plotOptions: {
      pie: {
        dataLabels: {
          minAngleToShowLabel: 0,
        }
      },
    },
    chart: {
      width: 190,
      type: 'pie',
    },
    labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
    tooltip: {
      y: {

        formatter: function (val, second) {



          let labels = second["config"]["labels"];
          let series = second["config"]["series"];


          if (val === series[series.length - 1] && labels.includes("Otros")) {


            return obtenerDesgloseUnoPorciento();
          }



          return separadoComas(dosDecimales(val))
        },
        title: {
          formatter: function (seriesName, { series, seriesIndex, dataPointIndex, w }) {
            return seriesName
          }
        }
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 100
        },
        legend: {
          position: 'bottom'
        }
      }
    }],

  }
  const obtenerDesgloseUnoPorciento = (params) => {
    if (tipo === "presentaciones") {
      return obtenerDesgloseUnoPorcientoPresentaciones()
    } else {
      return obtenerDesgloseUnoPorcientoGrupos()
    }
  }
  const obtenerDesgloseUnoPorcientoGrupos = (params) => {
    // obtener ids de productos, invernaderos y variedades, en cascada
    let clientesIds = filtroCliente.map(el => el.id);
    let invernaderos = filtroInvernadero.slice();;
    let invernaderosIds = invernaderos.map(el => el.id);

    let variedades = filtroVariedad.slice();
    let variedadesIds = variedades.map(el => el.id);


    let filtradas = gruposPresentaciones.filter(el => el.producto_ref === filtroProducto.id);
    filtradas = filtradas.filter(el => clientesIds.includes(el.cliente_ref));
    let sumaTotal = 0;
    let result = [];
    filtradas.forEach(grupo => {
      grupo.presentaciones_refs.forEach(presentacion_ref => {
        let presentacion = presentaciones.find(el => el.id === presentacion_ref);
        let pesoCaja = presentacion.peso_neto_caja;
        ordenes.forEach(orden => {
          orden.items.forEach(currentItem => {

            if (currentItem.presentacion_ref === presentacion.id) {
              let invernadero = invernaderos.find(el => el.id === currentItem.invernadero_ref);
              if (invernadero && invernaderosIds.includes(invernadero.id) && variedadesIds.includes(invernadero.variedad_ref)) {
                let pesoItem = parseFloat(currentItem.cajas_orden) * pesoCaja;
                sumaTotal += pesoItem;
              }
            }
          })
        });
      })

    });
    let unoPorciento = sumaTotal / 100.0;
    filtradas.forEach(grupo => {
      let suma = 0;
      grupo.presentaciones_refs.forEach(presentacion_ref => {
        let presentacion = presentaciones.find(el => el.id === presentacion_ref);
        let pesoCaja = presentacion.peso_neto_caja;
        ordenes.forEach(orden => {
          orden.items.forEach(currentItem => {

            if (currentItem.presentacion_ref === presentacion.id) {
              let invernadero = invernaderos.find(el => el.id === currentItem.invernadero_ref);
              if (invernadero && invernaderosIds.includes(invernadero.id) && variedadesIds.includes(invernadero.variedad_ref)) {
                let pesoItem = parseFloat(currentItem.cajas_orden) * pesoCaja;
                suma += pesoItem;
              }
            }
          })
        });
      })
      if (suma < unoPorciento) {
        result.push({ nombre: grupo.nombre, valor: dosDecimales(suma) })
      }

    });
    let sumaUnoP = result.reduce((acc, curr) => acc + curr.valor, 0)
    // result.push({nombre:"suma total",valor:sumaTotal})
    // result.push({nombre:"suma uno p",valor:sumaUnoP})
    // let presentacionesResto = result.filter(el=>el< unoPorciento);


    return result.map(el => {
      return `<div>
          <div>${el.nombre}: ${el.valor}</div>
        </div>`
    }).join("")
    // let sumaResto = presentacionesResto.reduce((acc,curr)=>{ return acc + curr},0);
    // return sumaResto;
  }

  const obtenerDesgloseUnoPorcientoPresentaciones = (params) => {



    // obtener ids de productos, invernaderos y variedades, en cascada
    let clientesIds = filtroCliente.map(el => el.id);
    let invernaderos = filtroInvernadero.slice();;
    let invernaderosIds = invernaderos.map(el => el.id);

    let variedades = filtroVariedad.slice();
    let variedadesIds = variedades.map(el => el.id);




    let filtradas = presentaciones.filter(el => el.producto_ref === filtroProducto.id);
    filtradas = filtradas.filter(el => clientesIds.includes(el.cliente_ref));
    let sumaTotal = 0;
    let result = [];
    filtradas.forEach(presentacion => {

      let pesoCaja = presentacion.peso_neto_caja;
      ordenes.forEach(orden => {
        orden.items.forEach(currentItem => {

          if (currentItem.presentacion_ref === presentacion.id) {
            let invernadero = invernaderos.find(el => el.id === currentItem.invernadero_ref);
            if (invernadero && invernaderosIds.includes(invernadero.id) && variedadesIds.includes(invernadero.variedad_ref)) {
              let pesoItem = parseFloat(currentItem.cajas_orden) * pesoCaja;
              sumaTotal += pesoItem;
            }
          }
        })
      });
    });
    let unoPorciento = sumaTotal / 100.0;
    filtradas.forEach(presentacion => {
      let suma = 0;
      let pesoCaja = presentacion.peso_neto_caja;
      ordenes.forEach(orden => {
        orden.items.forEach(currentItem => {

          if (currentItem.presentacion_ref === presentacion.id) {
            let invernadero = invernaderos.find(el => el.id === currentItem.invernadero_ref);
            if (invernadero && invernaderosIds.includes(invernadero.id) && variedadesIds.includes(invernadero.variedad_ref)) {
              let pesoItem = parseFloat(currentItem.cajas_orden) * pesoCaja;
              suma += pesoItem;
            }
          }
        })
      });
      if (suma < unoPorciento) {
        result.push({ nombre: presentacion.presentacion, valor: dosDecimales(suma) })
      }
    });
    let sumaUnoP = result.reduce((acc, curr) => acc + curr.valor, 0)
    // result.push({nombre:"suma total",valor:sumaTotal})
    // result.push({nombre:"suma uno p",valor:sumaUnoP})
    let presentacionesResto = result.filter(el => el < unoPorciento);



    return result.map(el => {
      return `<div>
          <div>${el.nombre}: ${el.valor}</div>
        </div>`
    }).join("")
    let sumaResto = presentacionesResto.reduce((acc, curr) => { return acc + curr }, 0);
    return sumaResto;
  }

  useEffect(() => {
    if (invernaderosVirtuales) {
      obtenerDatosIniciales();
    }
  }, [invernaderosVirtuales])
  const obtenerDatosIniciales = async (params) => {
    try {
      setCargando(true);
      let invernaderos = invernaderosVirtuales;
      let presentacionesPromise = obtenerPresentaciones();
      let gruposPresentacionesPromise = obtenerGruposPresentaciones();
      let clientesPromise = obtenerClientes();
      let clientesCicloPromise = obtenerClientesCiclo(ciclo);
      let ordenesPromise = obtenerOrdenesCiclo(ciclo);

      let [presentaciones, gruposPresentaciones, clientes, clientesCiclo, ordenes] =
        await Promise.all([presentacionesPromise, gruposPresentacionesPromise
          , clientesPromise, clientesCicloPromise, ordenesPromise]);

      let idsClientesCiclo = clientesCiclo.map(el => el.cliente_ref);
      clientes = clientes.filter(el => idsClientesCiclo.includes(el.id))

      setInvernaderos(invernaderos);
      setClientesOrig(clientes);
      setGruposPresentaciones(gruposPresentaciones);
      setPresentaciones(presentaciones);
      setOrdenesOrig(ordenes);
      setOrdenes(ordenes);
    } catch (error) {
      console.log("OCURRIO UN ERROR EN CARGA DE DATOS INICIALES: ", error);
    } finally {
      setCargando(false)
    }
  }

  useEffect(() => {
    setFiltroInvernadero(obtenerOpcionesInvernaderos());
  }, [filtroProducto])
  useEffect(() => {
    setFiltroVariedad(obtenerOpcionesVariedades());
  }, [filtroInvernadero])

  const obtenerOpcionesInvernaderos = () => {
    if (!filtroProducto.id) { return invernaderos; }
    else { return invernaderos.filter(inv => filtroProducto.id === inv.producto_ref || (filtroProducto.MEDLEYS_GENERAL && inv.mezcla)); }
  }

  const obtenerOpcionesVariedades = () => {
    if (filtroCliente?.length === 0 || filtroInvernadero?.length === 0 || !filtroProducto) { return []; }
    else if (filtroProducto.MEDLEYS_GENERAL && !hayMedleysViejo(filtroInvernadero)) { return productosMezclados || []; }
    else { return filtroProducto?.variedades || []; }
  }
  const obtenerSeries = () => {
    if (tipo === "presentaciones") {
      return obtenerSeriesPresentaciones()
    } else {
      return obtenerSeriesGruposPresentaciones()
    }
  }
  const obtenerSeriesGruposPresentaciones = (params) => {
    // obtener ids de productos, invernaderos y variedades, en cascada
    let clientesIds = filtroCliente.map(el => el.id);
    let invernaderos = filtroInvernadero.slice();;
    let invernaderosIds = invernaderos.map(el => el.id);
    let variedadesIds = filtroVariedad.map(el => el.id);

    let filtradas = presentaciones.filter(el => el.producto_ref === filtroProducto.id);
    filtradas = filtradas.filter(el => clientesIds.includes(el.cliente_ref));
    let gruposFiltrados = gruposPresentaciones;//.filter(el=> idsPresentaciones.includes(el.presentaciones_ref));

    let sumaTotal = 0;
    let result = gruposFiltrados.map(grupo => {
      let suma = 0;

      grupo.presentaciones_refs.map(presentacionRef => {

        let presentacion = filtradas.find(el => presentacionRef === el.id);
        if (presentacion) {

          let pesoCaja = presentacion.peso_neto_caja;
          ordenes.forEach(orden => {
            orden.items.forEach(currentItem => {
              if (currentItem.presentacion_ref === presentacion.id) {
                let invernadero = invernaderos.find(el => el.id === currentItem.invernadero_ref);
                if (invernadero && invernaderosIds.includes(invernadero.id) && (variedadesIds.includes(invernadero.variedad_ref) || variedadesIds.includes(invernadero.producto_ref))) {
                  let pesoItem = parseFloat(currentItem.cajas_orden) * pesoCaja;
                  suma += pesoItem;
                  sumaTotal += pesoItem;
                }
              }
            })
          });
        }
      })
      return suma;
    });

    let unoPorciento = sumaTotal / 100.0;
    let presentacionesResto = result.filter(el => el < unoPorciento);
    let sumaResto = presentacionesResto.reduce((acc, curr) => { return acc + curr }, 0);
    result = result.filter(el => el > unoPorciento);
    if (sumaResto > 0) {
      result.push(sumaResto)
    }
    //  
    return result.filter(el => el > 0);
  }

  const obtenerSeriesPresentaciones = (params) => {
    // obtener ids de productos, invernaderos y variedades, en cascada
    let clientesIds = filtroCliente.map(el => el.id);
    let invernaderos = filtroInvernadero.slice();;
    let invernaderosIds = invernaderos.map(el => el.id);

    let variedades = filtroVariedad.slice();
    let variedadesIds = variedades.map(el => el.id);
    let filtradas = presentaciones.filter(el => el.producto_ref === filtroProducto.id);

    filtradas = filtradas.filter(el => clientesIds.includes(el.cliente_ref));
    let sumaTotal = 0;
    let result = filtradas.map(presentacion => {

      let suma = 0;
      let pesoCaja = presentacion.peso_neto_caja;
      ordenes.forEach(orden => {
        orden.items.forEach(currentItem => {

          if (currentItem.presentacion_ref === presentacion.id) {

            let invernadero = invernaderos.find(el => el.id === currentItem.invernadero_ref);
            if (invernadero && invernaderosIds.includes(invernadero.id) && (variedadesIds.includes(invernadero.variedad_ref) || variedadesIds.includes(invernadero.producto_ref))) {
              let pesoItem = parseFloat(currentItem.cajas_orden) * pesoCaja;
              suma += pesoItem;
              sumaTotal += pesoItem;
            }
          }
        })
      });
      return suma;
    });
    let unoPorciento = sumaTotal / 100.0;
    let presentacionesResto = result.filter(el => el < unoPorciento);
    let sumaResto = presentacionesResto.reduce((acc, curr) => { return acc + curr }, 0);


    result = result.filter(el => el > unoPorciento);

    if (sumaResto > 0) {
      result.push(sumaResto)
    }
    return result.filter(el => el > 0);
  }
  const obtenerOptions = (params) => {
    if (tipo === "presentaciones") {
      return obtenerOptionsPresentaciones()
    } else {
      return obtenerOptionsGrupos()
    }
  }

  const obtenerOptionsGrupos = (params) => {
    let sumaTotal = 0;
    let sumaResto = 0;

    let clientesIds = filtroCliente.map(el => el.id);
    let invernaderos = filtroInvernadero.slice();;
    let invernaderosIds = invernaderos.map(el => el.id);
    let variedades = filtroVariedad.slice();
    let variedadesIds = variedades.map(el => el.id);

    let filtradas = presentaciones.filter(el => el.producto_ref === filtroProducto.id);
    filtradas = filtradas.filter(el => clientesIds.includes(el.cliente_ref));
    let copia = { ...optionsOrig };
    copia["labels"] = [];
    //obtener suma total
    filtradas.forEach(presentacion => {
      let suma = 0;
      let pesoCaja = presentacion.peso_neto_caja;
      ordenes.forEach(orden => {
        orden.items.forEach(currentItem => {
          if (currentItem.presentacion_ref === presentacion.id) {
            let invernadero = invernaderos.find(el => el.id === currentItem.invernadero_ref);
            if (invernadero && invernaderosIds.includes(invernadero.id) && (variedadesIds.includes(invernadero.variedad_ref) || variedadesIds.includes(invernadero.producto_ref))) {
              let pesoItem = parseFloat(currentItem.cajas_orden) * pesoCaja;
              sumaTotal += pesoItem;
            }
          }
        })
      });


    });
    let unoPorciento = sumaTotal / 100.0;
    //evaluar si sobrepasa el 1 por ciento necesario para aparecer
    gruposPresentaciones.forEach(grupo => {
      let suma = 0;

      grupo.presentaciones_refs.map(presentacionRef => {

        let presentacion = filtradas.find(el => presentacionRef === el.id);
        if (presentacion) {

          let pesoCaja = presentacion.peso_neto_caja;
          ordenes.forEach(orden => {
            orden.items.forEach(currentItem => {
              if (currentItem.presentacion_ref === presentacion.id) {
                let invernadero = invernaderos.find(el => el.id === currentItem.invernadero_ref);
                if (invernadero && invernaderosIds.includes(invernadero.id) && (variedadesIds.includes(invernadero.variedad_ref) || variedadesIds.includes(invernadero.producto_ref))) {
                  let pesoItem = parseFloat(currentItem.cajas_orden) * pesoCaja;
                  suma += pesoItem;

                }
              }
            })
          });
        }
      })

      if (suma > unoPorciento) {
        copia["labels"].push(grupo.nombre);
      } else {
        sumaResto += suma;
      }
    });

    if (sumaResto > 0) {
      copia["labels"].push("Otros");
    }

    return copia;
  }

  const obtenerOptionsPresentaciones = (params) => {
    let sumaTotal = 0;
    let sumaResto = 0;
    // 
    let clientesIds = filtroCliente.map(el => el.id);
    let invernaderos = filtroInvernadero.slice();;
    let invernaderosIds = invernaderos.map(el => el.id);
    let variedades = filtroVariedad.slice();
    let variedadesIds = variedades.map(el => el.id);
    // 
    let filtradas = presentaciones.filter(el => el.producto_ref === filtroProducto.id);
    filtradas = filtradas.filter(el => clientesIds.includes(el.cliente_ref));
    let copia = { ...optionsOrig };
    copia["labels"] = [];
    filtradas.forEach(presentacion => {
      let suma = 0;
      let pesoCaja = presentacion.peso_neto_caja;
      ordenes.forEach(orden => {
        orden.items.forEach(currentItem => {
          if (currentItem.presentacion_ref === presentacion.id) {
            let invernadero = invernaderos.find(el => el.id === currentItem.invernadero_ref);
            if (invernadero && invernaderosIds.includes(invernadero.id) && (variedadesIds.includes(invernadero.variedad_ref) || variedadesIds.includes(invernadero.producto_ref))) {
              let pesoItem = parseFloat(currentItem.cajas_orden) * pesoCaja;
              sumaTotal += pesoItem;
            }
          }
        })
      });
      // 
      //                 
    });
    let unoPorciento = sumaTotal / 100.0;
    filtradas.forEach(presentacion => {
      let suma = 0;
      let pesoCaja = presentacion.peso_neto_caja;
      ordenes.forEach(orden => {
        orden.items.forEach(currentItem => {
          if (currentItem.presentacion_ref === presentacion.id) {
            let invernadero = invernaderos.find(el => el.id === currentItem.invernadero_ref);
            if (invernadero && invernaderosIds.includes(invernadero.id) && (variedadesIds.includes(invernadero.variedad_ref) || variedadesIds.includes(invernadero.producto_ref))) {
              let pesoItem = parseFloat(currentItem.cajas_orden) * pesoCaja;
              suma += pesoItem;
              sumaTotal += pesoItem;
            }
          }
        })
      });

      if (suma > unoPorciento) {
        copia["labels"].push(presentacion.presentacion);
      } else {
        sumaResto += suma;
      }
    });
    if (sumaResto > 0) {
      copia["labels"].push("Otros");
    }
    return copia;
  }

  const atras = (params) => {
    props.history.goBack();
  }

  const mostrarVariedadesInput = () => {
    const medleysGeneralSeleccionado = filtroProducto.MEDLEYS_GENERAL;
    if (!medleysGeneralSeleccionado) { return true; }

    if (hayMedleysViejo(filtroInvernadero)) { return true; }
    else { return false; }
  }

  return <div className="p-grid chart-page">
    <div className="p-col  p-col-auto title-container">
      <span className="back-btn" onClick={atras} ></span>
      <span className={`icon-general chart-icon `}></span>
      <span className="titles-group">
        <h1 className="header-title">Distribución de presentaciones durante el ciclo (kg)</h1>
      </span>
    </div>

    <div className="p-col-12">

      <div className="p-grid filtros">

        <ContenedorFiltro filtrolabel="no" label="Tipo de gráfica">

          <ToggleButton checked={esMulticiclo} onChange={e => setEsMulticiclo(e.value)} onLabel={"Es multiciclo"} offLabel={"No multiciclo"} />
        </ContenedorFiltro>
        <div className="form-group p-col p-col-3">
          <label htmlFor="filtroCliente">Clientes</label>
          <MultiSelect options={clientesOrig} value={filtroCliente} onChange={e => setFiltroCliente(e.value)}
            selectedItemsLabel={`${filtroCliente.length} clientes seleccionados`}
            dataKey="id" optionLabel="nombre" />
        </div>
        <div className="form-group p-col p-col-3">
          <label htmlFor="filtroProducto">Productos a graficar</label>
          <Dropdown options={productosBases} value={filtroProducto} onChange={e => setFiltroProducto(e.value)}
            dataKey="id" optionLabel="nombre" />
        </div>
        <div className="form-group p-col p-col-3">
          <label htmlFor="filtroInvernadero">Invernaderos a graficar</label>
          <MultiSelect options={obtenerOpcionesInvernaderos()} value={filtroInvernadero} onChange={e => setFiltroInvernadero(e.value)}
            selectedItemsLabel={`${filtroInvernadero.length} invernaderos seleccionados`} dataKey="id" optionLabel="nombre" />
        </div>
        {mostrarVariedadesInput() &&
          <div className="form-group p-col p-col-3">
            <label htmlFor="filtroVariedad">Variedades a graficar</label>
            <MultiSelect options={obtenerOpcionesVariedades()} value={filtroVariedad} onChange={e => setFiltroVariedad(e.value)}
              selectedItemsLabel={`${filtroVariedad.length} variedades seleccionadas`} dataKey="id" optionLabel="nombre" />
          </div>}
        <ContenedorFiltro label="Tipo" col="6">
          <SelectButton optionLabel="name" value={tipo} optionValue="code" options={tipos} onChange={e => setTipo(e.value)} />
        </ContenedorFiltro>
      </div>
    </div>
    <div className="p-col-12 chart-container-twofilters">
      <Chart options={obtenerOptions()} series={obtenerSeries()} type="donut" height="100%" />
    </div>
    {esMulticiclo && <DistribucionPresentacionesMulticiclo />}
  </div>
}

export default DistribucionPresentacionesCiclo;


