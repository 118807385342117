import React, { useGlobal } from 'reactn';
import { withRouter } from 'react-router-dom';
import DatoCard from '../common/cards/DatoCard';
import Column from '../common/cards/Column';
import HTGCard from '../common/cards/HTGCard';
import icono from '../../images/icons/external-icon.svg';

const InvernaderoExternoCard = (props) => {
  const [, setInvernadero] = useGlobal("invernadero");
  const [, setProveedor] = useGlobal("proveedor");

  const consultar = () => {
    //console.log("Proveedor: ", props.proveedor)
    if (props.proveedor) {
      setProveedor(props.proveedor);
      props.history.push("/pronosticos/proveedor_externo/registro");
    } else {
      setInvernadero(props.invernadero);
      props.history.push("/pronosticos/proveedores_externos");
    }
  }

  const header = <span className="card-header"><div className={`card-id icon-general icon-lort icon-id bor-gray bor-${props.invernadero?.color} bg-gray bg-${props.invernadero?.color}`}><img width="30" alt="icono" src={icono} /></div><div>
    {props.proveedor
      ? <p className="card-up">{props.proveedor.nombre}</p>
      : <p className="card-up">Externo - {props.invernadero?.nombre}</p>}
  </div></span>

  const className = `htg-card bor-main-blue ${props.invernadero?.habilitado ? "Habilitado" : "Deshabilitado"}`
  return (
    <HTGCard header={header} headerClassName={className} onClick={consultar}>
      <Column>
        <DatoCard label="REAL ACTUAL" valor={`${props?.info?.semanaUno || 0} kg/m2`} />
        <DatoCard label="ACCURRACY 3 WEEKS" valor={`${(!props?.info?.semanaTres || props?.info?.semanaTres === "-") ? "-" : Math.round(100 * props.info.semanaTres)} %`} />
      </Column>
      <Column>
        <DatoCard label="ACCURRACY 5 WEEKS"
          valor={`${(!props?.info?.semanaTres || props?.info?.semanaCinco === "-") ? "-" : Math.round(100 * props.info.semanaCinco)} %`} />
        <DatoCard label="PROYECCION ACUMULADA"
          valor={`${(!props?.info?.semanaTres || isNaN(props?.info?.proyeccion)) ? "-" : props.info.proyeccion.toString() == "Infinity" ? "-" : props.info.proyeccion} kg/m2`} />
      </Column>
    </HTGCard>)
}
export default withRouter(InvernaderoExternoCard);