import React from 'react'
import precios from './../../../images/graficas/precios.png';
import { Card } from 'primereact/card';
export default function HomeFinanzas(props) {
    return (
        <React.Fragment>
            <div className="titulo-seccion-graficas">
                <span className="icon-general costos-icon"></span>
                Finanzas
            </div>
            <div className="p-col-12 p-md-3  grafica-card" onClick={() => props.ir({ pathname: "/analisis_costos/precios", data: { backArrow: true } })}>
                <Card className={`htg-card bor-gray-superlight`}  >
                    <div className="card-title">Gráfica de precios</div>
                    <img src={precios} />
                </Card>
            </div>
        </React.Fragment>
    )
}