import { setGlobal, getGlobal } from 'reactn'
import * as firebase from "firebase/app";
import "firebase/firestore";
import { estamosEnProduccion, miliSegundosDia, numeroMaximo } from '../constants'
import { paletaColores, dateToUnixTimeAsNumber13, getObjetoPorID, getNombrePorID } from '../util/functions'
import { getEmpresasInternos, obtenerEmpresas } from './Empresas';
import RhService from './RhService'
import { obtenerLocaciones as obtenerLocacionesHTF } from './Locaciones';
import { getUsuarios, obtenerUsuario, obtenerUsuarios, obtenerUsuariosFunctions } from './Usuarios'
import { uploadStatistics } from './Estadisticas'
import { ROLES_ADMIN_Y_AUXILIAR } from '../util/diccionarios';
import { obtenerEmpaques } from './Empaques';

export const ADMIN_ALMACEN = "Administrador Almacén"

export const RESPONSABLE_OPTIONS = estamosEnProduccion
    ? [
        { label: 'Nestor Castillo', value: 'Nestor Castillo' },
        { label: 'Luis Cancino', value: 'Luis Cancino' },
        { label: 'Proveedor', value: 'Proveedor' }
    ] : [
        { label: 'Auditoria', value: 'Auditoria' },
        { label: 'Trabajo', value: 'Trabajo' }
    ];
export const guardarRequisicionAciclico = (nombreColl, requisicionID, datos) => {
    return new Promise((resolve, reject) => {
        let nombreDocColl = nombreColl;
        if (nombreColl !== "mantenimiento") { nombreDocColl += "s" }
        firebase.firestore().collection('requisiciones').doc(nombreDocColl).collection(`requisiciones_${nombreColl}`).doc(requisicionID).set(datos, { merge: true }).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}

function registrarInvocaciones(count) {
    uploadStatistics(count)
}
export const isAdminModulo = () => {
    return Object.keys(ROLES_ADMIN_Y_AUXILIAR).includes(getGlobal().usuario?.rol);
}

export const filtrarRequisicionesDeAuxiliarPorAdmins = (ordenes) => {
    const { usuario, usuarios } = getGlobal();
    if (!isAdminModulo()) { return ordenes; }

    return ordenes.filter(el => {
        const auxiliarRol = ROLES_ADMIN_Y_AUXILIAR[usuario.rol]
        const solicitanteRol = getObjetoPorID(usuarios, el.solicitante).rol
        return solicitanteRol === auxiliarRol || solicitanteRol === usuario.rol;
    });
}

export const obtenerOrdenesComprasBridge = async (cicloObj, usuario, isAdminModulo) => {
    const isOld = cicloObj.requisicionesCiclicos
    return isOld ? await obtenerOrdenesCompra(cicloObj.id, usuario, isAdminModulo)
        : await obtenerOrdenesCompraAciclico(usuario, isAdminModulo)
}

export const obtenerOrdenComprasBridge = async (cicloObj, id) => {
    const isOld = cicloObj.requisicionesCiclicos
    return isOld ? await obtenerOrdenCompra(cicloObj.id, id)
        : await obtenerOrdenCompraAciclico(id)
}

export const obtenerOrdenesSistemasBridge = async (cicloObj, usuario, isAdminModulo) => {
    const isOld = cicloObj.requisicionesCiclicos
    return isOld ? await obtenerOrdenesSistema(cicloObj.id, usuario, isAdminModulo)
        : await obtenerOrdenesSistemaAciclico(usuario, isAdminModulo)
}

export const obtenerOrdenSistemasBridge = async (cicloObj, id) => {
    const isOld = cicloObj.requisicionesCiclicos
    return isOld ? await obtenerOrdenSistema(cicloObj.id, id)
        : await obtenerOrdenSistemaAciclico(id)
}
export const obtenerOrdenesMantenimientoBridge = async (cicloObj, usuario, isAdminModulo) => {
    const isOld = cicloObj.requisicionesCiclicos
    return isOld ? await obtenerOrdenesMantenimiento(cicloObj.id, usuario, isAdminModulo)
        : await obtenerOrdenesMantenimientoAciclico(usuario, isAdminModulo)
}

export const obtenerOrdenMantenimientoBridge = async (cicloObj, id) => {
    const isOld = cicloObj.requisicionesCiclicos
    return isOld ? await obtenerOrdenMantenimiento(cicloObj.id, id)
        : await obtenerOrdenMantenimientoAciclico(id)
}
//funciones de requisiciones de compra
export const obtenerOrdenesCompra = async (ciclo, usuario, isAdminModulo) => {
    return obtenerRequisiciones(ciclo, usuario, "Compras", "compra", isAdminModulo)
}

export const obtenerOrdenCompra = (ciclo, id) => {
    return obtenerRequisicion(ciclo, id, "compra")
}

export const obtenerFiltrosComprasTodos = (usuario) => {
    return new Promise(async (resolve, reject) => {
        try {
            let promiseEstatusesCompras = obtenerDatosRequisicion("compras", "estatuses")
            let promiseLocacionesCompras = obtenerDatosRequisicion("compras", "locaciones")
            let promiseOrdenesRefsCompras = obtenerDatosRequisicion("compras", "ordenes_referencias")
            let promiseUrgenciasCompras = obtenerDatosRequisicion("compras", "urgencias")
            let promiseRequerimientosCompras = obtenerDatosRequisicion("compras", "requerimientos")
            let promiseUnidadesCompras = obtenerDatosRequisicion("compras", "unidades")
            let promiseProveedoresCompras = obtenerDatosRequisicion("compras", "proveedores", 'nombre')
            let promiseSitios = obtenerEmpaques()
            let promiseLocacionesHTF = obtenerLocacionesHTF()
            let promiseEmpresas = getEmpresasInternos()
            let promiseDepartamentos = RhService.getDepartamentos()
            let promiseUsuarios = obtenerUsuariosFunctions();

            let [estatusesCompras, locacionesCompras, ordenesRefsCompras, urgenciasCompras, requerimientosCompras,
                unidadesCompras, proveedoresCompras, locacionesHTF, empresas, departamentos, usuarios, sitios] = await Promise.all(
                    [promiseEstatusesCompras, promiseLocacionesCompras, promiseOrdenesRefsCompras, promiseUrgenciasCompras,
                        promiseRequerimientosCompras, promiseUnidadesCompras, promiseProveedoresCompras,
                        promiseLocacionesHTF, promiseEmpresas, promiseDepartamentos, promiseUsuarios, promiseSitios])

            let estatusesIniciales = estatusesCompras.filter(el => el.inicial)
            let estatusesFiltrosCompras = estatusesIniciales.map(el => el.id)
            let urgenciasFiltrosCompras = urgenciasCompras.map(el => el.id)
            let requerimientosFiltrosCompras = requerimientosCompras.map(el => el.id)
            let departamentosFiltrosCompras = departamentos.map(el => el.id)
            let empresasFiltrosCompras = empresas.map(el => el.id)
            sitios = [{ nombre: "Todos", id: "Todos" }, ...sitios.map(el => ({ ...el, nombre: el.nombre === "Empaque Agropark" ? "Agropark" : el.nombre }))];
            let sitiosFiltrosCompras = ["Sin asignar", ...sitios.map(el => el.id)]

            setGlobal({
                estatusesCompras, locacionesCompras: locacionesCompras.filter(el => !el.locacion), locacionesAtongoCompras: locacionesCompras.filter(el => el.locacion),
                ordenesRefsCompras, urgenciasCompras, requerimientosCompras, unidadesCompras, proveedoresCompras, empresas, departamentos, locacionesHTF, usuarios, sitios,

                urgenciasFiltrosCompras, requerimientosFiltrosCompras, estatusesFiltrosCompras, departamentosFiltrosCompras, empresasFiltrosCompras, sitiosFiltrosCompras, datosCargadosCompras: true,
            })
            resolve(true)

        } catch (error) {
            console.log("ERROR obteniendo filtros de compras")
            reject(error)
        }
    });
}

//funciones de requisiciones de sistema
export const obtenerOrdenesSistema = async (ciclo, usuario, isAdminModulo) => {
    return obtenerRequisiciones(ciclo, usuario, "Sistemas", "sistema", isAdminModulo)
}
export const obtenerOrdenSistema = (ciclo, id) => {
    return obtenerRequisicion(ciclo, id, "sistema")
}
export const obtenerFiltrosSistemasTodos = (usuario) => {
    return new Promise(async (resolve, reject) => {
        try {
            let promiseEstatusesSistemas = obtenerDatosRequisicion("sistemas", "estatuses")
            let promiseLocacionesSistemas = obtenerDatosRequisicion("sistemas", "locaciones")
            let promiseOrdenesRefsSistemas = obtenerDatosRequisicion("sistemas", "ordenes_referencias")
            let promiseUrgenciasSistemas = obtenerDatosRequisicion("sistemas", "urgencias")
            let promiseRequerimientosSistemas = obtenerDatosRequisicion("sistemas", "requerimientos")
            let promiseLocacionesHTF = obtenerLocacionesHTF()
            let promiseEmpresas = getEmpresasInternos()
            let promiseDepartamentos = RhService.getDepartamentos()
            let promiseUsuarios = obtenerUsuariosFunctions();
            let promiseSitios = obtenerEmpaques()
            let promiseLocacionesCompras2 = obtenerDatosRequisicion("compras", "locaciones")

            let [estatusesSistemas, locacionesSistemas, ordenesRefsSistemas, urgenciasSistemas, requerimientosSistemas,
                locacionesHTF, empresas, departamentos, usuarios, sitios, locacionesAtongoSistemas] = await Promise.all(
                    [promiseEstatusesSistemas, promiseLocacionesSistemas, promiseOrdenesRefsSistemas, promiseUrgenciasSistemas,
                        promiseRequerimientosSistemas, promiseLocacionesHTF, promiseEmpresas, promiseDepartamentos, promiseUsuarios, promiseSitios, promiseLocacionesCompras2])

            let estatusesIniciales = estatusesSistemas.filter(el => el.inicial)
            let estatusesFiltrosSistemas = estatusesIniciales.map(el => el.id)
            let urgenciasFiltrosSistemas = urgenciasSistemas.map(el => el.id)
            let requerimientosFiltrosSistemas = requerimientosSistemas.map(el => el.id)
            let departamentosFiltrosSistemas = departamentos.map(el => el.id)
            let empresasFiltrosSistemas = empresas.map(el => el.id)
            sitios = [{ nombre: "Todos", id: "Todos" }, ...sitios.map(el => ({ ...el, nombre: el.nombre === "Empaque Agropark" ? "Agropark" : el.nombre }))];
            let sitiosFiltrosSistemas = ["Sin asignar", ...sitios.map(el => el.id)]

            setGlobal({
                estatusesSistemas, locacionesSistemas: locacionesSistemas.filter(el => !el.locacion), locacionesAtongoSistemas,
                ordenesRefsSistemas, urgenciasSistemas, requerimientosSistemas, empresas, departamentos, locacionesHTF, usuarios, sitios,

                urgenciasFiltrosSistemas, requerimientosFiltrosSistemas, estatusesFiltrosSistemas, departamentosFiltrosSistemas, empresasFiltrosSistemas, sitiosFiltrosSistemas,
                datosCargadosSistemas: true,
            })
            resolve(true)

        } catch (error) {
            console.log("ERROR obteniendo filtros de sistemas")
            reject(error)
        }
    });
}

//funciones de requisiciones de mantenimiento
export const obtenerOrdenesMantenimiento = async (ciclo, usuario, isAdminModulo) => {
    return obtenerRequisiciones(ciclo, usuario, "Mantenimiento", "mantenimiento", isAdminModulo)
}
export const obtenerOrdenMantenimiento = (ciclo, id) => {
    return obtenerRequisicion(ciclo, id, "mantenimiento")
}
export const obtenerFiltrosMantenimientoTodos = (usuario, invs = getGlobal().invernaderosFisicos) => {
    return new Promise(async (resolve, reject) => {
        try {
            let promiseEstatusesMant = obtenerDatosRequisicion("mantenimiento", "estatuses")
            let promiseLocacionesMant = obtenerDatosRequisicion("mantenimiento", "locaciones")
            let promiseOrdenesRefsMant = obtenerDatosRequisicion("mantenimiento", "ordenes_referencias")
            let promiseUrgenciasMant = obtenerDatosRequisicion("mantenimiento", "urgencias")
            let promiseRequerimientosMant = obtenerDatosRequisicion("mantenimiento", "requerimientos")
            let promiseResponsablesMant = obtenerDatosRequisicion("mantenimiento", "responsables");
            let promiseLocacionesHTF = obtenerLocacionesHTF()
            let promiseEmpresas = getEmpresasInternos()
            let promiseDepartamentos = RhService.getDepartamentos()
            let promiseUsuarios = obtenerUsuariosFunctions();
            let promiseSitios = obtenerEmpaques()
            let promiseLocacionesCompras2 = obtenerDatosRequisicion("compras", "locaciones")


            let [estatusesMant, locacionesMant, ordenesRefsMant, urgenciasMant, requerimientosMant,
                locacionesHTF, empresas, departamentos, usuarios, responsablesMant, sitios, locacionesAtongoMant] = await Promise.all(
                    [promiseEstatusesMant, promiseLocacionesMant, promiseOrdenesRefsMant, promiseUrgenciasMant,
                        promiseRequerimientosMant, promiseLocacionesHTF, promiseEmpresas, promiseDepartamentos, promiseUsuarios, promiseResponsablesMant, promiseSitios, promiseLocacionesCompras2])

            let estatusesIniciales = estatusesMant.filter(el => el.inicial)
            let estatusesFiltrosMant = estatusesIniciales.map(el => el.id)
            let urgenciasFiltrosMant = urgenciasMant.map(el => el.id)
            let requerimientosFiltrosMant = requerimientosMant.map(el => el.id)
            let departamentosFiltrosMant = departamentos.map(el => el.id)
            let empresasFiltrosMant = empresas.map(el => el.id)
            let ordenesRefsFiltrosMant = ordenesRefsMant.map(el => el.id)
            let responsablesFiltrosMant = [{ nombre: "Sin responsable", id: "sin" }, ...responsablesMant]

            sitios = [{ nombre: "Todos", id: "Todos" }, ...sitios.map(el => ({ ...el, nombre: el.nombre === "Empaque Agropark" ? "Agropark" : el.nombre }))];
            let sitiosFiltrosMant = ["Sin asignar", ...sitios.map(el => el.id)]

            setGlobal({
                estatusesMant, locacionesMant: locacionesMant.filter(el => !el.locacion), locacionesAtongoMant, responsablesFiltrosMant,
                ordenesRefsMant, urgenciasMant, requerimientosMant, empresas, departamentos, locacionesHTF, usuarios, responsablesMant, sitios,

                urgenciasFiltrosMant, requerimientosFiltrosMant, estatusesFiltrosMant, departamentosFiltrosMant, empresasFiltrosMant, ordenesRefsFiltrosMant, sitiosFiltrosMant,
                datosCargadosMant: true, locacionesPlanMant: [...locacionesMant, ...invs.map(el => ({ id: el.id, nombre: el.nombre }))]
            })
            resolve(true)
        } catch (error) {
            console.log("ERROR obteniendo filtros de mantenimiento: ", error)
            reject(error)
        }
    });
}

//Funciones generales
export const obtenerRequisiciones = (ciclo, usuario, tipoAdmin, nombreModulo, isAdminModulo) => {
    return new Promise(async (resolve, reject) => {
        if (isAdminRequisiciones(usuario.rol, tipoAdmin) || isAdminModulo) {
            var result = await firebase.firestore().collection('ciclos').doc(ciclo).collection(`requisiciones_${nombreModulo}`).orderBy('orden_num', 'asc').get().catch((err) => { reject(err) });
        } else
            var result = await firebase.firestore().collection('ciclos').doc(ciclo).collection(`requisiciones_${nombreModulo}`).where("solicitante", "==", usuario.uid).orderBy('orden_num', 'asc').get().catch((err) => { reject(err) });
        registrarInvocaciones(result.size)

        let rows = result ? result.docs.map(doc => ({ id: doc.id, ...doc.data() })) : []
        resolve(rows);
    });
}
export const obtenerRequisicion = (ciclo, id, nombreModulo) => {
    return new Promise((resolve, reject) => {
        firebase.firestore().collection('ciclos').doc(ciclo).collection(`requisiciones_${nombreModulo}`).doc(id).get().then((result) => {
            registrarInvocaciones(result.size)
            if (!result.exists)
                reject(false);
            else
                resolve({ id, ...result.data() });
        }).catch((err) => {
            reject(err);
        });
    });
}
export const obtenerDatosRequisicion = (nombreModulo, collectionName, orderBy = 'orden') => {
    return new Promise((resolve, reject) => {
        firebase.firestore().collection('requisiciones').doc(nombreModulo).collection(collectionName).orderBy(orderBy, 'asc').get().then((result) => {
            registrarInvocaciones(result.size)
            let rows = result ? result.docs.map(doc => ({ id: doc.id, ...doc.data() })) : []
            resolve(rows);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const guardarDatosRequisicion = (ciclo, collectionName, requisicionID, data) => {
    return new Promise((resolve, reject) => {
        if (!requisicionID)
            requisicionID = firebase.firestore().collection('ciclos').doc().collection(collectionName).doc().id

        firebase.firestore().collection('ciclos').doc(ciclo).collection(collectionName).doc(requisicionID).set(data, { merge: true }).then(() => {
            resolve(true);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const obtenerRequisicionesOTHER = (ciclo, collection) => {
    return new Promise((resolve, reject) => {
        firebase.firestore().collection('ciclos').doc(ciclo).collection(collection).orderBy('orden_num', 'asc').get().then((result) => {
            registrarInvocaciones(result.size)
            let rows = result.docs.length > 0 ? result.docs.map(doc => ({ id: doc.id, ...doc.data() })) : []
            resolve(rows);
        }).catch((err) => {
            reject(err);
        });
    });
}

export const isAdminNormal = (rol) => rol === "Administrador"
export const isAdminRequisiciones = (rol, tipoAdmin) => (rol === "Administrador" || rol === `Administrador ${tipoAdmin}`)
export const isAdminAlmacen = (rol) => (rol === ADMIN_ALMACEN)
export const isAdminSoloRequisiciones = (rol, tipoAdmin) => rol === `Administrador ${tipoAdmin}`

export const empresaIsHTF = (empresas, empresaID) => getNombrePorID(empresas, empresaID) === "HTF"
export const locacionIsAtongo = (locacionesHTF, locacionID) => getNombrePorID(locacionesHTF, locacionID) === "Atongo"
export const locacionIsAgropark = (locacionesHTF, locacionID) => getNombrePorID(locacionesHTF, locacionID) === "Agropark"

export const ordenEsperaEntrega = (estatuses, estatus) => getObjetoPorID(estatuses, estatus).esperaEntrega
export const ordenCerradoParcial = (estatuses, estatus) => getObjetoPorID(estatuses, estatus).cerradoParcial
export const ordenCerrado = (estatuses, estatus) => getObjetoPorID(estatuses, estatus).cerrado

export const getListaEmpresasIDs = (orden) => orden.items.map(item => item.empresa)
export const getListaEmpresasString = (items, empresasOpciones) => {
    let empresasDelOrden = empresasOpciones.filter(el => getListaEmpresasIDs({ items }).includes(el.id))
    let empresasListaNombres = empresasDelOrden.map(el => el.nombre)

    return empresasListaNombres.join(", ")//Convertir array de nombres a string, separado por comas
}

export const getListaSitiosIDs = (orden) => orden.items.map(item => (item.sitio || "")) || []
export const getListaSitiosString = (items, sitiosOpciones) => {
    let sitiosDelOrden = sitiosOpciones.filter(el => getListaSitiosIDs({ items }).includes(el.id))
    let sitiosListaNombres = sitiosDelOrden.map(el => el.nombre)

    return sitiosListaNombres.join(", ")//Convertir array de nombres a string, separado por comas
}

export const getListalocsHTFIDs = (orden) => orden.items.map(item => (item.locacionHTF || ""))
export const getListaLocsHTFString = (items, locacionesHTFOpciones) => {
    let locacionesHTFDelOrden = locacionesHTFOpciones.filter(el => getListalocsHTFIDs({ items }).includes(el.id))
    let locacionesHTFListaNombres = locacionesHTFDelOrden.map(el => el.nombre)

    return locacionesHTFListaNombres.join(", ")//Convertir array de nombres a string, separado por comas
}


export const subirProveedor = (proveedorNombre) => {
    return new Promise((resolve, reject) => {
        firebase.firestore().collection('requisiciones').doc("compras").collection('proveedores').add({ nombre: proveedorNombre }).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    });
}
export const graficaDatosOpciones = [{ nombre: "Orden de referencia", id: "orden_ref" }, { nombre: "Empresa", id: "empresa" }, { nombre: "Departamento", id: "departamento" }, { nombre: "Requerimiento", id: "requerimiento" }]
export const filtrarSeriesGrafica = (ordenDato, filtrosDatos, ordenesOriginales, fechaFiltroInicio, fechaFiltroFinal, urgencias) => {
    let selectedFechaInicio = !fechaFiltroInicio ? 1 : dateToUnixTimeAsNumber13(fechaFiltroFinal)
    let selectedFechaFinal = !fechaFiltroFinal ? numeroMaximo : (dateToUnixTimeAsNumber13(fechaFiltroFinal) + miliSegundosDia)
    let series = []

    let cantidadFiltros = 0
    filtrosDatos.filtros.forEach(filtro => {
        if (ordenDato == "empresa")
            cantidadFiltros = ordenesOriginales.filter(el => el.items.map(item => item.empresa).includes(filtro) && urgencias.includes(el.urgencia)
                && el.fecha_creacion >= selectedFechaInicio && el.fecha_creacion <= selectedFechaFinal).length
        else
            cantidadFiltros = ordenesOriginales.filter(el => el[ordenDato] === filtro && urgencias.includes(el.urgencia)
                && el.fecha_creacion >= selectedFechaInicio && el.fecha_creacion <= selectedFechaFinal).length

        series.push(cantidadFiltros)
    })

    //Filter los opciones de los filtros si un filtro esta incluido en el array de filtros seleccionados; map eso para regresar nombres 
    let labels = filtrosDatos.opciones.filter(el => filtrosDatos.filtros.includes(el.id)).map(el => el.nombre)
    return { series, labels }
}
export const opcionesGraficaPie = {
    colors: paletaColores,
    plotOptions: {
        pie: {
            dataLabels: { minAngleToShowLabel: 0 }
        },
    },
    chart: {
        width: 190,
        type: 'pie',
    },
    labels: [],
    tooltip: {
        y: { title: "TITLE" }
    },
    responsive: [{
        breakpoint: 480,
        options: {
            chart: { width: 100 },
            legend: { position: 'bottom' }
        }
    }],
}

//Nuevos cambios para requisiciones aciclicos
export const obtenerOrdenesCompraAciclico = (usuario, isAdminModulo) => obtenerRequisicionesAciclico(usuario, "Compras", "compras", "compra", isAdminModulo);
export const obtenerOrdenesSistemaAciclico = (usuario, isAdminModulo) => obtenerRequisicionesAciclico(usuario, "Sistemas", "sistemas", "sistema", isAdminModulo)
export const obtenerOrdenesMantenimientoAciclico = (usuario, isAdminModulo) => obtenerRequisicionesAciclico(usuario, "Mantenimiento", "mantenimiento", "mantenimiento", isAdminModulo)

export const obtenerRequisicionesAciclico = async (usuario, tipoAdmin, nombreDoc, nombreModulo, isAdminModulo) => {
    if (isAdminRequisiciones(usuario.rol, tipoAdmin) || isAdminModulo) { return await obtenerDatosRequisicionAciclico(nombreDoc, nombreModulo); }
    else { return await obtenerDatosRequisicionAciclicoPorUsuario(nombreDoc, nombreModulo, usuario); }
}
export const obtenerDatosRequisicionAciclico = async (nombreDoc, nombreModulo) => {
    const result = await firebase.firestore().collection('requisiciones').doc(nombreDoc).collection(`requisiciones_${nombreModulo}`).orderBy("orden_num", 'asc').get()
    return result ? result.docs.map(doc => ({ id: doc.id, ...doc.data() })) : []
}

export const obtenerDatosRequisicionAciclicoPorUsuario = async (nombreDoc, nombreModulo, usuario) => {
    const result = await firebase.firestore().collection('requisiciones').doc(nombreDoc).collection(`requisiciones_${nombreModulo}`).where("solicitante", "==", usuario.uid).orderBy("orden_num", 'asc').get()
    return result ? result.docs.map(doc => ({ id: doc.id, ...doc.data() })) : []
}

export const obtenerOrdenCompraAciclico = (requisicionID) => obtenerRequisicionAciclico(requisicionID, "compras", "compra");
export const obtenerOrdenSistemaAciclico = (requisicionID) => obtenerRequisicionAciclico(requisicionID, "sistemas", "sistema");
export const obtenerOrdenMantenimientoAciclico = (requisicionID) => obtenerRequisicionAciclico(requisicionID, "mantenimiento", "mantenimiento");

export const obtenerRequisicionAciclico = (requisicionID, nombreDoc, nombreModulo) => {
    return new Promise((resolve, reject) => {
        console.log("obtenerRequisicionAciclico - ", { requisicionID, nombreDoc, nombreModulo })
        firebase.firestore().collection('requisiciones').doc(nombreDoc).collection(`requisiciones_${nombreModulo}`).doc(requisicionID).get().then((result) => {
            registrarInvocaciones(result.size)
            if (!result.exists)
                reject(false);
            else
                resolve({ id: requisicionID, ...result.data() });
        }).catch((err) => {
            console.log("ERRRR : ", err)
            reject(err);
        });
    });
}
//366