
import * as firebase from "firebase/app";
import "firebase/firestore";
import {uploadStatistics} from '../Estadisticas'

function registrarInvocaciones(count){
    uploadStatistics(count)
}


export const obtenerManifiestosClienteYEmpresa = async (cicloId, clienteId, empresaId ) => {
    const db = firebase.firestore();
    let snap = await db.collection("ciclos").doc(cicloId).collection("manifiestos").where("cliente_ref", "==", clienteId).where("productor_ref","==", empresaId).get();
    registrarInvocaciones(snap.size)
    return snap.docs.map(el=>({id:el.id , ...el.data()}))
}
