import React, { useGlobal } from 'reactn';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { withRouter } from "react-router-dom";
import { eliminarOrdenAplicacionBiocontrol } from '../../../service/biocontrol';

const OrdenAplicacionEliminarModal = (props) => {
    const [, setCargando] = useGlobal("cargando")

    const eliminarOrden = async () => {
        setCargando(true)
        props.hide()

        await eliminarOrdenAplicacionBiocontrol(props.ciclo, props.aplicacionID)
        setCargando(false)
        props.history.goBack()
    }

    const footer = <div>
        <Button label="Cancelar" onClick={props.hide} />
        <Button label="Finalizar" onClick={eliminarOrden} />
    </div>
    return (
        <Dialog header={"Eliminar orden"} visible={props.visible} style={{ width: '50vw' }} onHide={props.hide} footer={footer}>
            <div className="p-grid ">
                <div className="form-group p-col-12">
                    <label htmlFor="status-nuevo">Estas seguro que quieres eliminar este orden? </label>
                </div>
            </div>
        </Dialog >
    );
}
export default withRouter(OrdenAplicacionEliminarModal);