export const itemMappingToAddTotals = (items) => {
  if (!items) { return {}; }
  const itemTemplate = { totalPallets: 0, totalCajas: 0 };
  
  const totals = items.reduce((acc, item) => {
    acc.totalPallets = acc.totalPallets + parseFloat(item.pallets_orden);
    acc.totalCajas = acc.totalCajas + parseFloat(item.cajas_orden);
    return acc;
  }, { ...itemTemplate })
  return totals;
}