import React, { useEffect, useState, useGlobal } from "reactn";
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from "primereact/inputtext";
import { Calendar } from 'primereact/calendar';
import { estatusesOrdenesAplicacion as estatusesOpciones, obtenerEnfermedadesYPlagas, obtenerOrdenesAplicacionDeSemana } from '../../service/biocontrol';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { noHaSidoClausurado } from '../../service/Ciclos';
import { obtenerUsuarios, obtenerUsuariosFunctions } from '../../service/Usuarios';
import ContenedorFiltroBusqueda from '../common/ContenedorFiltroBusqueda'
import ContenedorFiltro from '../common/ContenedorFiltro'
import SeccionFiltros from '../common/SeccionFiltros'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { miliSegundosDia, numeroMaximo } from "../../constants";
import { dateToUnixTimeAsNumber13, getMomentFormatted, getNombreUsuarioPorID } from "../../util/functions";
import SelectorEnfermedadesModal from "./modals/SelectorEnfermedadesModal";
import ContenedorHeader from "../common/ContenedorHeader";

const OrdenesAplicacion = (props) => {
    const [, setCargando] = useGlobal("cargando")
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [usuario] = useGlobal("usuario");
    const [usuarios, setUsuarios] = useGlobal("usuarios");
    const [ciclo] = useGlobal("ciclo")
    const [ciclos] = useGlobal("ciclos")
    const [ordenLineas, setOrdenLineas] = useState([])
    const [ordenLineasOriginales, setOrdenLineasOriginales] = useState([])
    const [enfermedades] = useGlobal("enfermedadesYPlagas")
    const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector()
    const [invernaderos] = useGlobal("invernaderosFisicos")
    const [enfermedadesModalOpen, setEnfermedadesModalOpen] = useState(false)
    const [ordenesAplicacionSemanas, setOrdenesAplicacionSemanas] = useState([])
    const [datosCargados, setDatosCargados] = useState(false)

    const [invernaderoSeleccionado, setInvernaderoSeleccionado] = useGlobal("invernaderoFisico");
    const [globalFilter, setGlobalFilter] = useState("")
    const [estatusesFiltros, setEstatusesFiltros] = useState(estatusesOpciones)

    const puedeEditar = () => noHaSidoClausurado(ciclo, ciclos) && ["Administrador", "Grower"].includes(usuario.rol)
    const puedeConsultar = () => (noHaSidoClausurado(ciclo, ciclos) && ["Administrador", "Grower", "Administrador Biocontrol", "Auxiliar Biocontrol", "Auxiliar Biocontrol", "Auxiliar Grower"].includes(usuario.rol))

    useEffect(() => {
        setNombreModulo("Biocontrol")
        setHabilitadoSelectorCiclo(true);
    }, [])
    useEffect(() => {
        setCargando(true)
        if (invernaderos)
            updateCicloWeekSelector(ciclo)
    }, [invernaderos]);
    useEffect(() => {
        if (!datosCargados && week.nombre !== "Cargando")
            obtenerDatosIniciales()
        else if (datosCargados && invernaderoSeleccionado && week.nombre !== "Cargando")
            obtenerOrdenesAplicacion()
    }, [week, invernaderoSeleccionado, datosCargados]);

    useEffect(() => {
        //Reconsile Filters
        let ordenLineasFiltrados = ordenLineasOriginales.filter(orden => checarCondicionesDeIncluirOrden(orden))
        setOrdenLineas(ordenLineasFiltrados)
    }, [ordenLineasOriginales, estatusesFiltros]);

    const checarCondicionesDeIncluirOrden = (orden) => estatusesFiltros.includes(orden.estatus)

    const obtenerDatosIniciales = async () => {
        if (!enfermedades) await obtenerEnfermedadesYPlagas()
        if (!invernaderoSeleccionado) setInvernaderoSeleccionado(invernaderos[0])

        let users = usuarios || (usuario.rol === "Administrador" ? await obtenerUsuarios() : await obtenerUsuariosFunctions())
        setUsuarios(users)
        setDatosCargados(true)
    }
    const obtenerOrdenesAplicacion = async () => {
        setCargando(true)
        try {
            let ordenesDeSemana = ordenesAplicacionSemanas.find(el =>
                (el.week.time === week.time && el.invID === invernaderoSeleccionado.id && el.ciclo === ciclo))

            if (!ordenesDeSemana) {
                let ordenes = await obtenerOrdenesAplicacionDeSemana(ciclo, invernaderoSeleccionado.id, week.time)
                ordenesDeSemana = { week, invID: invernaderoSeleccionado.id, ciclo, ordenes }
                setOrdenesAplicacionSemanas([...ordenesAplicacionSemanas, ordenesDeSemana])
            }
            setOrdenLineas(ordenesDeSemana.ordenes)
            setOrdenLineasOriginales(ordenesDeSemana.ordenes)
        }
        catch (error) {
            console.log("ERROR AL OBTENER ORDENES APLICACION: ", error)
        }
        setCargando(false)
    }

    const irOrdenAplicacion = (event) => { if (puedeConsultar()) { props.history.push(`/biocontrol/orden_aplicacion/${invernaderoSeleccionado.id}/modificar/${event.data.id}`) } }
    const fechaFormattedBody = (rowData) => <label>{getMomentFormatted(rowData.fechaAplicacion, "DD/MM/YYYY")}</label>

    return (
        <div className="p-grid p-justify-center">
            {invernaderoSeleccionado && <ContenedorHeader col="p-col p-col-auto" titulo="Órdenes de aplicación" subtituloInv={""} invernaderoNombre={invernaderoSeleccionado.nombre}
                iconos={`icon-general icon-id bor-${invernaderoSeleccionado.color} bg-${invernaderoSeleccionado.color} `} atras={() => props.history.goBack()} />}

            <div className="p-col p-col-auto">
                <div className="btn-row">
                    {puedeConsultar() && <Button className="reactbtn-icon" label="Productos de aplicación" onClick={() => props.history.push("/biocontrol/productos")} />}
                    {puedeEditar() && <Button className="reactbtn-icon" label="Crear orden nueva" onClick={() => setEnfermedadesModalOpen(true)} />}
                </div>
            </div>

            <SeccionFiltros >
                <ContenedorFiltroBusqueda>
                    <FontAwesomeIcon icon={faSearch} />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar" />
                </ContenedorFiltroBusqueda>

                <ContenedorFiltro label="Invernadero" filtrolabel="no">
                    <Dropdown id="filtroInvernadero" placeholder="Seleccionar" dataKey="id" optionLabel="nombre"
                        options={invernaderos} value={invernaderoSeleccionado} onChange={(e) => { setInvernaderoSeleccionado(e.value) }} />
                </ContenedorFiltro>
                <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />

                <ContenedorFiltro label="estatus">
                    <MultiSelect id="estatusesFiltros" filter={false} selectedItemsLabel={`${estatusesFiltros?.length} estatus seleccionados`}
                        options={estatusesOpciones || []} value={estatusesFiltros || []} onChange={(e) => setEstatusesFiltros(e.value)} />
                </ContenedorFiltro>
            </SeccionFiltros>

            <div className="p-col p-col-auto">
                <div className="card p-0">
                    <DataTable selectionMode="single" value={ordenLineas} onRowClick={irOrdenAplicacion} globalFilter={globalFilter} emptyMessage="No hay resultados" sortable={true}>
                        <Column field="orden_num" header="# Orden" sortable={true} />
                        <Column field="fechaAplicacion" header="Fecha aplicación" sortable={true} body={fechaFormattedBody} />
                        <Column field="solicitante" header="Nombre Solicitante" sortable={true} body={(rowData) => <label>{getNombreUsuarioPorID(usuarios, rowData.solicitante)}</label>} />
                        <Column field="estatus" header="Estatus" sortable={true} />
                        <Column field="comentarioFinal" header="Comentario" sortable={true} />
                    </DataTable>
                </div>
            </div>

            <SelectorEnfermedadesModal visible={enfermedadesModalOpen} hide={() => setEnfermedadesModalOpen(false)}
                enfermedades={enfermedades} invID={invernaderoSeleccionado?.id} invernadero={invernaderoSeleccionado} />
        </div>
    );
}
export default OrdenesAplicacion;//152