import * as firebase from 'firebase/app';
import "firebase/firestore";
import {uploadStatistics} from './Estadisticas'

function registrarInvocaciones(count){
    uploadStatistics(count)
}

export const extraccionSimple = (coleccion) => {
    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
        db.collection(coleccion).get().then((result) => {
            registrarInvocaciones(result.size)
            let rows = [];
            result.forEach(item => {
                let data = item.data();
                data.id = item.id;
                rows.push(data);
            });
           resolve(rows) 
        }).catch((err) => {
            reject(err)
        });    
    });
    
};