import * as firebase from "firebase/app";
import "firebase/firestore";
import { uploadStatistics } from './Estadisticas'

function registrarInvocaciones(count) {
    uploadStatistics(count)
}

export const obtenerManifiestos = async (cicloId, semana, clienteId) => {
    const db = firebase.firestore();
    let snap = await db.collection("ciclos").doc(cicloId).collection("manifiestos").
        where("cliente_ref", "==", clienteId).get();
    registrarInvocaciones(snap.size)
    return snap.docs.map(el => ({ id: el.id, ...el.data() }));
}
export const obtenerManifiestoPorNombre = async (cicloId, nombre) => {
    try {
        const db = firebase.firestore();
        const snap = await db.collection("ciclos").doc(cicloId).collection("manifiestos").where("manifiesto", "==", nombre).get();
        registrarInvocaciones(snap.size)
        let doc = snap.docs[0];
        return { id: doc.id, ...doc.data() };
    } catch (error) {
        throw error;
    }
}
export const getManifiestosTodos = async (cicloId) => {
    try {        
        const snap = await firebase.firestore().collection("ciclos").doc(cicloId).collection("manifiestos").get();
        //registrarInvocaciones(snap.size)
        return snap.docs.map(el=>({id:el.id, ...el.data()}));
    } catch (error) {
        console.log("ERROR - getManifiestosTodos - ", error);
    }
}
export const guardarManifiesto = async (cicloId, manifiestoId, manifiesto) => {
    try {
        return await firebase.firestore().collection("ciclos").doc(cicloId).collection("manifiestos").doc(manifiestoId).set(manifiesto, { merge: true });
    } catch (error) { console.log("ERROR - ", error); }
}
export const actualizarManifiesto = async (cicloId, manifiestoId, manifiesto) => {
    try {
        const db = firebase.firestore();
        let x = await db.collection("ciclos").doc(cicloId).collection("manifiestos").doc(manifiestoId).update(manifiesto);
        return true;
    } catch (error) {
        throw error;
    }
}
export const actualizarManifiestoNU = async (cicloId, manifiestoId, manifiesto) => {
    try {
        const db = firebase.firestore();
        let x = await db.collection("ciclos").doc(cicloId).collection("manifiestos").doc(manifiestoId).set(manifiesto, { merge: true });
        return true;
    } catch (error) {
        throw error;
    }
}
export const crearManifiesto = async (cicloId, manifiesto) => {
    try {
        const db = firebase.firestore();
        let x = await db.collection("ciclos").doc(cicloId).collection("manifiestos").add(manifiesto);
        return true;
    } catch (error) {
        throw error;
    }
}
export const obtenerManifiestosEmbarque = async (cicloId, embarqueId) => {
    try {
        const db = firebase.firestore();
        let snap = await db.collection("ciclos").doc(cicloId).collection("manifiestos").where("embarque_ref", "==", embarqueId).get();
        registrarInvocaciones(snap.size)
        let docs = snap.docs.map(el => ({ id: el.id, ...el.data() }));
        return docs;
    } catch (error) {
        throw error
    }
}
export const eliminarManifiesto = async (cicloId, manifiestoId = " ") => {
    try {
        return await firebase.firestore().collection("ciclos").doc(cicloId).collection("manifiestos").doc(manifiestoId).delete();
    } catch (error) {
        console.log("ERROR AL BORRAR: ",)
        throw error
    }
}
export const eliminarEmbarque = async (cicloId, embarqueId = " ") => {
    try {
        return await firebase.firestore().collection("ciclos").doc(cicloId).collection("embarques").doc(embarqueId).delete();
    } catch (error) {
        console.log("ERROR AL BORRAR: ", error)
        return true
    }
}

export const obtenerManifiestosOrden = async (cicloId, ordenId) => {
    try {
        const db = firebase.firestore();
        let snap = await db.collection("ciclos").doc(cicloId).collection("manifiestos").where("orden_ref", "==", ordenId).get();
        registrarInvocaciones(snap.size)
        let docs = snap.docs.map(el => ({ id: el.id, ...el.data() }));
        return docs;
    } catch (error) {
        throw error
    }
}
export const obtenerManifiestosCliente = async (cicloId, clienteId) => {
    try {
        const db = firebase.firestore();
        let snap = await db.collection("ciclos").doc(cicloId).collection("manifiestos").where("cliente_ref", "==", clienteId).get();
        registrarInvocaciones(snap.size)
        let docs = snap.docs.map(el => ({ id: el.id, ...el.data() }));
        return docs;
    } catch (error) {
        throw error
    }
}
export const obtenerManifiestosClientes = async (cicloId, clienteIds) => {
    try {
        const db = firebase.firestore();
        let snap = await db.collection("ciclos").doc(cicloId).collection("manifiestos").where("cliente_ref", "in", clienteIds).get();
        registrarInvocaciones(snap.size)
        let docs = snap.docs.map(el => ({ id: el.id, ...el.data() }));
        return docs;
    } catch (error) {
        throw error
    }
}
export const actualizarItemsManifiestos = async (cicloId, manifiestoId, items) => {
    try {
        const db = firebase.firestore();
        let result = await db.collection("ciclos").doc(cicloId).collection("manifiestos").doc(manifiestoId).update({ items: items })
        return result;
    } catch (error) {
        throw error;
    }
}
export const actualizarManifiestosEmbarque = async (cicloId, embarqueId, orden, manifiestosNuevos) => {
    try {

        console.log("DATOSMAN: ", { cicloId, embarqueId, orden, manifiestosNuevos })


        if (manifiestosNuevos.length === 0) {
            return true
        }
        let promises = manifiestosNuevos.map(manifiesto => {
            manifiesto["embarque_ref"] = embarqueId;
            manifiesto["orden_camion"] = orden;
            manifiesto["productor_ref"] = manifiesto["productor"]["id"];
            manifiesto["fecha_envio"] = parseInt(manifiesto["fecha_envio"]);
            delete manifiesto["productor"];
            return crearManifiesto(cicloId, manifiesto);
        })
        let result = await Promise.all(promises);

        return result;
    } catch (error) {
        throw error;
    }
}
export const actualizarManifiestosExistentes = async (cicloId, manifiestos) => {
    try {
        let promises = manifiestos.map(el => actualizarManifiesto(cicloId, el.id, el));
        let result = await Promise.all(promises);
        return result;
    } catch (error) {
        throw error;
    }
}
export const actualizarManifiestosExistentesNU = async (cicloId, manifiestos) => {
    try {
        let promises = manifiestos.map(el => actualizarManifiestoNU(cicloId, el.id, el));
        let result = await Promise.all(promises);
        return result;
    } catch (error) {
        throw error;
    }
}

export const obtenerManifiestosCiclo = async (cicloId) => {
    try {
        const db = firebase.firestore();
        let snap = await db.collection("ciclos").doc(cicloId).collection("manifiestos").get();
        registrarInvocaciones(snap.size)
        let docs = snap.docs.map(el => ({ id: el.id, ...el.data() }));
        return docs;
    } catch (error) {
        throw error
    }
}
export const buscarCoincidenciasManifiestos = async (cicloId, manifiesto, numeroEmbarque) => {
    try {
        const db = firebase.firestore();
        let snap = await db.collection("ciclos").doc(cicloId).collection("manifiestos").
            where("manifiesto", "==", manifiesto).where("numeroEmbarque", "==", numeroEmbarque).get()
        registrarInvocaciones(snap.size)
        let docs = snap.docs.map(el => ({ id: el.id, ...el.data() }))
        return docs;
    } catch (error) {
        throw error;
    }

}
export const obtenerManifiestoPorId = async (cicloId, manifiestoId) => {
    try {
        const db = firebase.firestore();
        const snap = await db.collection("ciclos").doc(cicloId).collection("manifiestos").doc(manifiestoId).get();
        registrarInvocaciones(snap.size)
        let doc = snap
        return { id: doc.id, ...doc.data() };
    } catch (error) {
        throw error;
    }
}
