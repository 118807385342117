import React, { useEffect } from 'react'
import { useGlobal, useState } from 'reactn'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { obtenerProveedoresOrdenados } from '../../../service/Queries/Proveedores'
import ContenedorBotonesTop from '../../common/ContenedorBotonesTop'
import RowBotonesTop from '../../common/RowBotonesTop'
import ContenedorPrincipal from '../../common/ContenedorPrincipal'
import ContenedorHeader from '../../common/ContenedorHeader'
import ContenedorTabla from '../../common/ContenedorTabla'
import { estamosEnProduccion } from '../../../constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faLock, faUnlockAlt } from '@fortawesome/free-solid-svg-icons';

const GruposPresentaciones = (props) => {
    const [cicloId,] = useGlobal("ciclo");
    const [, setCargando] = useGlobal("cargando");
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [usuario,] = useGlobal("usuario");
    const [, setProveedor] = useGlobal("proveedor")
    const [proveedores, setProveedores] = useState([]);

    useEffect(() => {
        setNombreModulo("Proveedores")
        setHabilitadoSelectorCiclo(true)
        setProveedor(null);
    }, [])
    useEffect(() => {
        if (cicloId) obtenerDatosIniciales();
    }, [cicloId])

    const obtenerDatosIniciales = async () => {
        setCargando(true)
        let proveedores = await obtenerProveedoresOrdenados(cicloId);
        setProveedores(proveedores);
        setCargando(false)
    }

    const irProveedor = (event) => {
        if (["Administrador"].includes(usuario.rol)) {
            setProveedor(event.data);
            props.history.push(`/admin/proveedor/modificar/${event.data.id}`);
        }
    }
    const crearProveedor = () => { props.history.push("/admin/proveedor/crear"); }

    return (
        <ContenedorPrincipal>
            <ContenedorHeader titulo="Proveedores" iconos="presentacion-icon" />

            <ContenedorBotonesTop>
                <RowBotonesTop>
                    <Button label="Crear Nuevo" onClick={crearProveedor} />
                </RowBotonesTop>
            </ContenedorBotonesTop>

            <ContenedorTabla >
                <DataTable value={proveedores} onRowClick={irProveedor}>
                    <Column field="nombre" header="Nombre" sortable={true} />
                    {!estamosEnProduccion && <Column field="habilitado" header="Habilitado" sortable={true} 
                        body={rowData => <React.Fragment><FontAwesomeIcon icon={faUnlockAlt} /> {rowData.habilitado ? "Habilitado" : " Deshabilitado"}</React.Fragment>} />}
                </DataTable>
            </ContenedorTabla>
        </ContenedorPrincipal>)
}
export default GruposPresentaciones;//68