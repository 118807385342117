import React ,{useEffect, useState}from 'react';
import { useGlobal } from "reactn";

import {InputText} from "primereact/inputtext";
import {Calendar} from 'primereact/calendar';
import {Dropdown} from 'primereact/dropdown';
import {MultiSelect} from 'primereact/multiselect';
import {InputSwitch} from 'primereact/inputswitch';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog'
import { Checkbox } from 'primereact/checkbox';
import { Message } from 'primereact/message';
import { noHaSidoClausurado } from '../../service/Ciclos';

import {
    getPuntosDeCarga_orderBy_nombre, getEmpresas_orderBy_nombre, getClientes_from_Ciclo_orderBy_nombreComun,
    getTransportistas_orderBy_nombre, getFronteras_orderBy_nombre, getDestinos_orderBy_nombre,
    getAgentesAduanales_tipoMexicano_orderBy_nombre, getAgentesAduanales_tipoAmericano_orderBy_nombre,
    registrarEmbarque, getEmbarque, actualizarEmbarque
} from '../../service/embarques';
import { MODULO_EMBARQUES_NOMBRE } from '../../util/constants';
import { obtenerManifiestosEmbarque } from '../../service/QueriesManifiestos';
import { unixTimeToDate } from '../../util/functions';


function RegistrarEmbarque(props) {

    // datos iniciales
    const [cargando, setCargando] = useGlobal("cargando")
    const [cicloId, setCicloId] = useGlobal("ciclo")
    const [ciclos, setCiclos] = useGlobal("ciclos")
    const [nombreModulo, setNombreModulo] = useGlobal("nombreModulo");
    const [fechaRegistro, setFechaRegistro] = React.useState("")
    const [puntosCarga, setpuntosCarga] = React.useState([])
    const [productores, setProductores] = React.useState([])
    const [importadores, setImportadores] = React.useState([])
    const [transportistas, setTransportistas] = React.useState([])
    const [fronteras, setFronteras] = React.useState([])
    const [destinos, setDestinos ] = React.useState([])
    const [agentesAduanalesMexicanos, setAgentesAduanalesMexicanos] = React.useState([])
    const [agentesAduanalesAmericanos, setAgentesAduanalesAmericanos] = React.useState([])
    const { embarqueId } = props.match.params

    // formulario
    const [puntosCargaSeleccionados, setPuntosCargaSeleccionados] = React.useState([])
    const [productoresSeleccionados, setProductoresSeleccionados] = React.useState([])
    const [importadorSeleccionado, setImportadorSeleccionado] = React.useState({})
    const [transportistaSeleccionado, setTransportistaSeleccionado] = React.useState({})
    const [fronteraSeleccionada, setFronteraSeleccionada] = React.useState({})
    const [destinoSeleccionado, setDestinoSeleccionado] = React.useState({})
    const [agenteAduanalMexicanoSeleccionado, setAgenteAduanalMexicanoSeleccionado] = React.useState({})
    const [agenteAduanalAmericanoSeleccionado, setAgenteAduanalAmericanoSeleccionado] = React.useState({})
    const [consolidado, setConsolidado] = React.useState(false)
    const [numeroDeCaja, setNumeroDeCaja] = React.useState("")
    const [guardar, setGuardar] = React.useState(false)

    // alertasu
    var [mostrarDialogoDeCamposRequeridos, setMostrarDialogoDeCamposRequeridos] = React.useState(false)
    var [mostrarDialogoDeErrorAlGuardar, setMostrarDialogoDeErrorAlGuardar] = React.useState(false)
    const [manifiestos, setManifiestos] = useState()
    //manifiestos
    const [currentCamion, setCurrentCamion] = useGlobal("currentCamion")
    let [,setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    useEffect(() => {
        setHabilitadoSelectorCiclo(false);
      return () => {
        setHabilitadoSelectorCiclo(true);
      }
    }, [])
    React.useEffect(() => {
        setNombreModulo(MODULO_EMBARQUES_NOMBRE)
        if (typeof cicloId === 'undefined') return
        if (cicloId === "") return
        getInitialData()
    }, [cicloId]);

    React.useEffect(() => {
        if(!noHaSidoClausurado(cicloId, ciclos))
            props.history.replace("/embarques/consultar")
    }, [cicloId,ciclos])

    React.useEffect(() => {
        if (puntosCarga.length === 0
                || productores.length === 0
                || importadores.length === 0
                || transportistas.length === 0
                || fronteras.length === 0
                || destinos.length === 0
                || agentesAduanalesMexicanos.length === 0
                || agentesAduanalesAmericanos.length === 0
                ) {
            return
        }

        cargarEmbarque_if_isModoEdicion()
    }, [puntosCarga, productores, importadores, transportistas, fronteras, destinos, agentesAduanalesMexicanos, agentesAduanalesAmericanos]);

    React.useEffect(() => {
        setGuardar(false)
    }, [puntosCargaSeleccionados,importadorSeleccionado,transportistaSeleccionado,fronteraSeleccionada,destinoSeleccionado,agenteAduanalMexicanoSeleccionado,agenteAduanalAmericanoSeleccionado,numeroDeCaja ])

    async function getInitialData() {
        setCargando(true)
        let [
            puntosCarga,
            empresas,
            clientesEnCiclo,
            transportistas,
            fronteras,
            destinos,
            agentesAduanalesMexicanos,
            agentesAduanalesAmericanos
        ] = await Promise.all([
            getPuntosDeCarga_orderBy_nombre(),
            getEmpresas_orderBy_nombre(),
            getClientes_from_Ciclo_orderBy_nombreComun(cicloId),
            getTransportistas_orderBy_nombre(),
            getFronteras_orderBy_nombre(),
            getDestinos_orderBy_nombre(),
            getAgentesAduanales_tipoMexicano_orderBy_nombre(),
            getAgentesAduanales_tipoAmericano_orderBy_nombre()
        ]);

        setFechaRegistro(new Date())
        setpuntosCarga(puntosCarga)
        setProductores(empresas)
        setImportadores(clientesEnCiclo)
        setTransportistas(transportistas)
        setFronteras(fronteras)
        setDestinos(destinos)
        setAgentesAduanalesMexicanos(agentesAduanalesMexicanos)
        setAgentesAduanalesAmericanos(agentesAduanalesAmericanos)
        
        setCargando(false)
    }

    async function cargarEmbarque_if_isModoEdicion() {
        setCargando(true)
        if (isModoEdicion()) {
            await getEmbarque(embarqueId, cicloId).then(async (registroEmbarque) => {
                setFechaRegistro(unixTimeToDate(registroEmbarque.fecha_registro_unix_timestamp))
                setPuntosCargaSeleccionados(puntosCarga.filter(puntoCarga => registroEmbarque.puntos_de_carga.indexOf(puntoCarga.id) !== -1))
                setImportadorSeleccionado(importadores.find((importador) => importador.id === registroEmbarque.importador_ref))
                setTransportistaSeleccionado(transportistas.find((transportista) => transportista.id === registroEmbarque.transportista_ref))
                setFronteraSeleccionada(fronteras.find((frontera) => frontera.id === registroEmbarque.frontera_ref))
                setDestinoSeleccionado(destinos.find((destino) => destino.id === registroEmbarque.destino_ref))
                setAgenteAduanalMexicanoSeleccionado(agentesAduanalesMexicanos.find((aaMexicano) => aaMexicano.id === registroEmbarque.agente_aduanal_mexicano_ref))
                setAgenteAduanalAmericanoSeleccionado(agentesAduanalesAmericanos.find((aaAmericano) => aaAmericano.id === registroEmbarque.agente_aduanal_americano_ref))
                setConsolidado(registroEmbarque.consolidado)
                setNumeroDeCaja(registroEmbarque.numero_de_caja)
                let manifiestos = await obtenerManifiestosEmbarque(cicloId, embarqueId);
                setManifiestos(manifiestos);
            });
        }
        setCargando(false)
    }


    function guardar_or_modificar_Empleado() {
        setCargando(true)
        setGuardar(true)
        if (!isValidFormData()) {
            setCargando(false)
            setMostrarDialogoDeCamposRequeridos(true)
            return
        }

        if (!isModoEdicion()) {
            guardarEmbarque()
        } else {
            modificarEmbarque()
        }
    }

    function isValidFormData() {
        if (puntosCargaSeleccionados.length === 0) return false
        if (!importadorSeleccionado.id) return false
        if (!transportistaSeleccionado.id) return false
        if (!fronteraSeleccionada.id) return false
        if (!destinoSeleccionado.id) return false
        if (!agenteAduanalMexicanoSeleccionado.id && importadorSeleccionado.nombre !== "Nature Sweet") return false
        if (!agenteAduanalAmericanoSeleccionado.id && importadorSeleccionado.nombre !== "Nature Sweet") return false
        if (numeroDeCaja === "") return false
        return true
    }

    function guardarEmbarque() {
        let puntosCargaRefs = puntosCargaSeleccionados.map(puntoCarga => puntoCarga.id)
        let currCamion = currentCamion ? currentCamion : {orden:1,manifiestos:[]}
        registrarEmbarque(
            cicloId, fechaRegistro, puntosCargaRefs, importadorSeleccionado.id, transportistaSeleccionado.id,
            fronteraSeleccionada.id, destinoSeleccionado.id, agenteAduanalMexicanoSeleccionado?.id || "N/A", agenteAduanalAmericanoSeleccionado?.id || "N/A",
            consolidado, numeroDeCaja, {...currCamion}
        ).then((registroExitoso) => {

            setCargando(false)
            if (registroExitoso) {
                setCurrentCamion(null);
                props.history.goBack()
            } else {
                setMostrarDialogoDeErrorAlGuardar(true)
            }
        }).catch((error) => {
            setCargando(false)
            setMostrarDialogoDeErrorAlGuardar(true)
        });
    }

    function modificarEmbarque() {
        let puntosCargaRefs = puntosCargaSeleccionados.map(puntoCarga => puntoCarga.id)
        actualizarEmbarque(
            cicloId, fechaRegistro, embarqueId, puntosCargaRefs, importadorSeleccionado.id, transportistaSeleccionado.id,
            fronteraSeleccionada.id, destinoSeleccionado.id, agenteAduanalMexicanoSeleccionado.id, agenteAduanalAmericanoSeleccionado.id,
            consolidado, numeroDeCaja
        ).then((actualizacionExitosa) => {
            setCargando(false)
            if (actualizacionExitosa) {
                props.history.goBack()
            } else {
                setMostrarDialogoDeErrorAlGuardar(true)
            }
        }).catch(() => {
            setCargando(false)
            setMostrarDialogoDeErrorAlGuardar(true)
        });
    }


    var isModoEdicion = () => { return embarqueId !== "" && typeof embarqueId !== 'undefined' }
    var handleConsolidadoChanged = () => { setConsolidado(!consolidado) }

    function handleOnPuntoCargaChanged(e) {
        let puntosDeCargaActualizados = Array.from(puntosCargaSeleccionados)
        if (e.checked) {
            let puntoCaragaNuevoSeleccionado = e.value
            puntosDeCargaActualizados.push(puntoCaragaNuevoSeleccionado)
        } else {
            let puntoCargaDeseleccionado = e.value
            puntosDeCargaActualizados = puntosDeCargaActualizados.filter(puntoCarga => puntoCarga.id !== puntoCargaDeseleccionado.id)
        }
        setPuntosCargaSeleccionados(puntosDeCargaActualizados)
    }

    // 
    // 
    // 
    let data = isModoEdicion()?manifiestos:currentCamion?currentCamion.manifiestos:[];
    // 
    return (
        <div className="p-grid p-justify-center">

            {/* Título */}
            <div className="p-col-12 title-container">
                <span className="back-btn" onClick={() => { props.history.goBack() }}></span>
                <span className="icon-general truck-icon"></span>
                <span className="titles-group">
                    { isModoEdicion() ?
                        <h1 className="header-title">
                            Modificar Embarque
                        </h1>
                    :
                        <h1 className="header-title">
                            Crear Embarque
                        </h1>
                    }
                </span>
            </div>

            {/* Formulario - Formato General */}
            <div className="p-col-12">
            <p className="section-title">
                Información general
              </p>  
            </div>
            <div className="card p-col-12">
             
              <div className="p-grid">
                {/* fecha */}
                <div className="form-group p-col-12 p-md-4">
                    <label htmlFor="fechaRegistro">Fecha</label>
                    <Calendar id="fechaRegistro" disabled={false } readOnlyInput={ true }
                        monthNavigator={ true } yearNavigator={ true } yearRange="1900:2030"
                        dateFormat="dd/mm/yy" value={ fechaRegistro }
                        onChange={ e => setFechaRegistro(e.value) }
                    />
                </div>

                {/* fronteras */}
                <div className="form-group p-col-12 p-md-4">
                    <label htmlFor="frontera">Frontera</label>
                    <Dropdown id="frontera" placeholder="Seleccionar"
                        dataKey="id"
                        optionLabel="nombre"
                        options={ fronteras }
                        value={ fronteraSeleccionada }
                        onChange={(e) => { setFronteraSeleccionada(e.value) } }/>
                    {(guardar === true && !fronteraSeleccionada.id ) ? <Message severity="error" text="Este campo es requerido"></Message> : null}
                </div>


                {/* destino */}
                <div className="form-group p-col-12 p-md-4">
                    <label htmlFor="destino">Destino</label>
                    <Dropdown id="destino" placeholder="Seleccionar"
                        dataKey="id"
                        optionLabel="nombre"
                        options={ destinos }
                        value={ destinoSeleccionado }
                        onChange={(e) => { setDestinoSeleccionado(e.value) } }/>
                    {(guardar === true && !destinoSeleccionado.id ) ? <Message severity="error" text="Este campo es requerido"></Message> : null}
                </div>


                {/* punto de carga */}
                <div className="form-group p-col-12 p-md-4">
                    <label htmlFor="puntoDeCarga">Punto de carga</label>
                    { puntosCargaSeleccionados && puntosCarga.map((puntoCarga, puntoCargaIndex) => {
                        return (
                            <div key={ `punto-carga-${ puntoCargaIndex }` } className="p-field-checkbox">
                                <Checkbox
                                    inputId={ `punto-carga-${ puntoCargaIndex }` }
                                    name={ `punto-carga` }
                                    value={ puntoCarga }
                                    onChange={(e) => { handleOnPuntoCargaChanged(e) } }
                                    checked={ puntosCargaSeleccionados.indexOf(puntoCarga) !== -1 } />
                                <label htmlFor={ `punto-carga-${ puntoCargaIndex }` }>
                                    { puntoCarga.nombre }
                                </label>
                            </div>
                        );
                    }) }
                    {(guardar === true && puntosCargaSeleccionados.length === 0 ) ? <Message severity="error" text="Este campo es requerido"></Message> : null}
                </div>


                {/* consolidado */}
                <div className="form-group p-col-12 p-md-4">
                    <label htmlFor="consolidado">Consolidado</label>
                    <div className="switch-container">
                        <InputSwitch name="consolidado"
                        checked = { consolidado }
                        onChange={() => { handleConsolidadoChanged() }}/>
                        <span>
                            { consolidado ? "Si" : "No" }
                        </span>
                    </div>
                </div>

                {/* número de caja */}
                <div className="form-group p-col-12 p-md-4">
                    <label htmlFor="numeroCaja">No. de Caja</label>
                    <InputText id="numeroCaja" value={ numeroDeCaja } onChange={ e => setNumeroDeCaja(e.target.value) } />
                {(guardar === true && numeroDeCaja === "" ) ? <Message severity="error" text="Este campo es requerido"></Message> : null}
                </div>

              </div>
            </div>
          <div className="p-col-12">
            <p className="section-title">
                Detalles del embarque 
              </p> 
          </div>
          <div className="card p-col-12">
               
              <div className="p-grid">                
                {/* importador o cliente */}
                <div className="form-group p-col-12 p-md-4">
                    <label htmlFor="importador">Importador</label>
                    <Dropdown id="importador" placeholder="Seleccionar"
                        dataKey="id"
                        optionLabel="nombre_comun"
                        options={ importadores }
                        value={ importadorSeleccionado }
                        onChange={(e) => { setImportadorSeleccionado(e.value) } }/>
                {(guardar === true && !importadorSeleccionado.id ) ? <Message severity="error" text="Este campo es requerido"></Message> : null}
                </div>

                {/* transportista */}
                <div className="form-group p-col-12 p-md-4">
                    <label htmlFor="transportista">Transportista</label>
                    <Dropdown id="transportista" placeholder="Seleccionar"
                        dataKey="id"
                        optionLabel="nombre"
                        options={ transportistas }
                        value={ transportistaSeleccionado }
                        onChange={(e) => { setTransportistaSeleccionado(e.value) } }/>
                   {(guardar === true && !transportistaSeleccionado.id ) ? <Message severity="error" text="Este campo es requerido"></Message> : null}
                </div>

                {/* agente aduanal mexicano */}
                <div className="form-group p-col-12 p-md-4">
                    <label htmlFor="aam">AA Mexicano</label>
                    <Dropdown id="aam" placeholder="Seleccionar"
                        dataKey="id"
                        optionLabel="nombre"
                        options={ agentesAduanalesMexicanos }
                        value={ agenteAduanalMexicanoSeleccionado }
                        onChange={(e) => { setAgenteAduanalMexicanoSeleccionado(e.value) } }/>
                {(guardar === true && !agenteAduanalMexicanoSeleccionado.id  && importadorSeleccionado.nombre !== "Nature Sweet") ? <Message severity="error" text="Este campo es requerido"></Message> : null}
                </div>
                {/* agente aduanal americano */}
                <div className="form-group p-col-12 p-md-4">
                    <label htmlFor="aaa">AA Americano</label>
                    <Dropdown id="aaa" placeholder="Seleccionar"
                        dataKey="id"
                        optionLabel="nombre"
                        options={ agentesAduanalesAmericanos }
                        value={ agenteAduanalAmericanoSeleccionado }
                        onChange={(e) => { setAgenteAduanalAmericanoSeleccionado(e.value) } }/>
                {(guardar === true && !agenteAduanalAmericanoSeleccionado.id  && importadorSeleccionado.nombre !== "Nature Sweet") ? <Message severity="error" text="Este campo es requerido"></Message> : null}                
                </div>

              </div>
            </div>

            {/* Formulario - Acciones */}
            <div className="p-col-12">
              <div className="p-grid p-justify-end btn-row">
                <Button label="Cancelar" className="p-button-secondary" onClick={() => { props.history.goBack() }} />
                <Button label="Guardar" onClick={() => { guardar_or_modificar_Empleado() }} />
              </div>
            </div>

            {/* Alertas */}
            <Dialog header="Alerta" modal={ true }
                    visible={ mostrarDialogoDeErrorAlGuardar }
                    onHide={() => { setMostrarDialogoDeErrorAlGuardar(false) }} >
                Ocurrió un error al guardar, vuelve a intentarlo.
            </Dialog>
        </div>
    );

}


export default RegistrarEmbarque;
