import React from 'react';
import { useState, useEffect, useGlobal } from 'reactn';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from "primereact/inputtext";
import moment from "moment";
import 'moment/locale/es';
import { obtenerCiclos, obtenerCiclo, actualizarClientesCiclo, actualizarDatoPrincipal } from "../../service/Ciclos";
import { obtenerClientes } from "../../service/Clientes";
import * as firebase from "firebase/app";
import { InputSwitch } from 'primereact/inputswitch';
moment.locale("es");

const EditarCiclo = (props) => {
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [state, setState] = useState({
        cicloId: props.match.params.cicloID, currentCiclo: false,
        ciclo: { nombre: "", year_inicio: "", year_cierre: "", semana_inicio: "", semana_cierre: "", },
        clientesSeleccionados: [], clienteOpciones: [], clientesIniciales: []
    });
    const [currentCiclo, setCurrentCiclo] = useState(false)
    const [, setCargando] = useGlobal("cargando")
    const [usuario] = useGlobal("usuario")
    const [ciclos] = useGlobal("ciclos");
    const [ciclo] = useGlobal("ciclo");

    const [clientesOrig, setClientesOrig] = useState([])

    useEffect(() => {
        setNombreModulo("Ciclos")
        setHabilitadoSelectorCiclo(false)
    }, [])

    useEffect(() => {

    }, [state])

    useEffect(() => {
        if (ciclo) obtenerClientesInicio()
    }, [ciclo])
    const obtenerClientesInicio = async () => {
        setCargando(true)
        if (!state.cicloId) props.history.replace("/administrar/ciclo/crear");
        else {
            let clientesSeleccionados = [], clientesIniciales = [], clientesInicialesIDs = [], clienteOpciones = []
            let theCiclo = ciclos?.find(ci => ci.id === state.cicloId) || { nombre: "", year_inicio: "", year_cierre: "", semana_inicio: "", semana_cierre: "" };

            let clientesDeCiclo = await firebase.firestore().collection('ciclos').doc(state.cicloId).collection('clientes').get()
            clientesDeCiclo.docs.forEach(cliente => {
                clientesSeleccionados.push(cliente.data().cliente_ref)
            })

            let clientes = await obtenerClientes()
            clientes.forEach(cliente => {
                clienteOpciones.push({ label: cliente.nombre_comun, value: cliente.id, disabled: true })
                if (clientesSeleccionados.includes(cliente.id)) {
                    clientesIniciales.push(cliente.nombre_comun)
                    clientesInicialesIDs.push(cliente.id)
                }
            })
            setCurrentCiclo(!!theCiclo.currentCiclo)
            setState({
                ...state, clientes: clientes, clienteOpciones: clienteOpciones, clientesIniciales: clientesIniciales,
                clientesInicialesIDs: clientesInicialesIDs, ciclo: theCiclo, isLoading: false,
                clientesSeleccionados: clientesSeleccionados
            })
            setClientesOrig(clientesSeleccionados);
            setCargando(false)
        }
    }

    const editarCiclo = async () => {
        setCargando(true)
        await actualizarDatoPrincipal(state.cicloId, ciclos, currentCiclo)
        await actualizarClientesCiclo(state.cicloId, clientesOrig, state.clientesSeleccionados)
        setCargando(false)
        props.history.push("/administrar/ciclos");
    }


    return (
        <div className="p-grid p-justify-center">
            <div className="p-col p-col-12 title-container">
                <span className="back-btn" onClick={() => { props.history.goBack() }}></span>
                <span className="icon-general ciclo-icon"></span>
                <span className="titles-group">
                    <h1 className="header-title">{`${usuario.isRolFinanzas ? "Consultar" : "Modificar"} ciclo`}</h1>
                    <p className="header-subtitle">{state.ciclo.nombre}</p>
                </span>
            </div>

            <div className="p-col-10">
                <div className="p-grid p-fluid ">
                    <div className=" p-col-12">
                        <p className="section-title">Información de ciclo</p>
                        <div className="card">

                            <div className="p-grid">
                                <div className="form-group p-col-12 ">
                                    <label htmlFor="nombre">Nombre de Ciclo</label>
                                    <InputText id="nombre" disabled value={state.ciclo.nombre} />
                                </div>
                                <div className="form-group p-col-12 ">
                                <label htmlFor="yearInicio">Hacer ciclo principal</label>
                                    <div className="switch-container">
                                        <InputSwitch onLabel="Si" offLabel="No" checked={currentCiclo} onChange={e => setCurrentCiclo(e.target.value)} name="Hacer ciclo principal" />
                                        <span>{currentCiclo ? "Si" : "No"}</span>
                                    </div>
                                </div>

                                <div className="form-group p-col-12 p-md-3">
                                    <label htmlFor="yearInicio">Año de inicio</label>
                                    <InputText id="yearInicio" disabled value={moment(state.ciclo.semana_inicio * 1000).utcOffset("-0600").format("YYYY")} />
                                </div>

                                <div className="form-group p-col-12 p-md-3">
                                    <label htmlFor="semanaInicio">Semana de inicio </label>
                                    <InputText id="semanaInicio" disabled value={moment(state.ciclo.semana_inicio * 1000).utcOffset("-0600").format("WW")} />
                                </div>

                                <div className="form-group p-col-12 p-md-3">
                                    <label htmlFor="yearCierre">Año de finalización</label>
                                    <InputText id="yearCierre" disabled value={moment(state.ciclo.semana_cierre * 1000).utcOffset("-0600").format("YYYY")} />
                                </div>

                                <div className="form-group p-col-12 p-md-3">
                                    <label htmlFor="semanaCierre">Semana de finalización </label>
                                    <InputText id="semanaCierre" disabled value={moment(state.ciclo.semana_cierre * 1000).utcOffset("-0600").format("WW")} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className=" p-col-12">
                        <p className="section-title">Clientes asociados</p>
                        <div className="card">

                            <div className="p-grid">
                                <div className="form-group p-col-12 p-md-12">
                                    <label >Clientes asociados a ciclo</label>
                                    <ul className="ciclo-clientes">
                                        {state.clientesIniciales.map(cliente => {

                                            return (<li>{cliente}</li>)
                                        }
                                        )}
                                    </ul>
                                </div>
                                <div className="form-group p-col-12 p-md-12">
                                    <label htmlFor="clientes">Asociar clientes </label>
                                    <MultiSelect id="clientes" disabled={usuario.isRolFinanzas}
                                        placeholder={"Seleccionar clientes"}
                                        selectedItemsLabel={`${state.clientesSeleccionados.length} clientes seleccionados`}
                                        filter={false}
                                        options={state.clienteOpciones} value={state.clientesSeleccionados}
                                        onChange={(e) => { setState({ ...state, clientesSeleccionados: e.value }) }}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <div className="p-col-12">
                <div className="p-grid p-justify-end btn-row">
                    <Button label="Cancelar" className="p-button-secondary" onClick={() => { props.history.goBack() }} />
                    <Button label="Guardar" disabled={usuario.isRolFinanzas} onClick={editarCiclo} />
                </div>
            </div>
        </div>
    );

}
export default EditarCiclo //193