import React, { useEffect, useState } from 'reactn';
import { useGlobal } from "reactn";
import { Button } from 'primereact/button';
import { obtenerAreaInvernaderoSync } from "../../service/Invernaderos";
import InvernaderoCard from "./InvernaderoCard";
import InvernaderoExternoCard from "./InvernaderoExternoCard";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';

import { getPesoProyectado, obtenerPesoProyectadoExterno } from "../../service/Producto";
import { obtenerTotalBudget, obtenerTotalBudgetExterno } from '../../service/Budget'
import { obtenerPackoutAcumulado, obtenerPackoutSemanal, obtenerkgm2semanal, getkgm2semanalSync, getReporteEmpaqueInvernaderoSemanaFinal, } from '../../service/ReportesProducto';
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { getKgm2OrdenadosHasta, obtenerKgm2OrdenadosHasta } from '../../service/Ordenes';
import { obtenerSemanasTranscurridas, obtenerSemanasTranscurridasBuddget } from '../../service/Ciclos';
import { obtenerPronosticoYProyeccion } from '../../service/Pronosticos';
import { DeepClone, getObjetoPorID } from '../../util/functions';

const Invernaderos = (props) => {
  const [usuario,] = useGlobal("usuario");
  const [, setCargando] = useGlobal("cargando");
  const [, setNombreModulo] = useGlobal("nombreModulo");
  const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
  const [ciclo] = useGlobal("ciclo");

  const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
  const [invernaderosFisicos] = useGlobal("invernaderosFisicos");
  const [invernaderos, setInvernaderos] = useGlobal("invernaderos");
  const [invernaderosExternos, setInvernaderosExternos] = useState([])
  const [productosYVariedades] = useGlobal("productosYVariedades");
  const [allVariedades] = useGlobal("allVariedades");

  const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector();

  useEffect(() => {
    setCargando(true)
    setNombreModulo("Empaque");
    setHabilitadoSelectorCiclo(true);
  }, [])

  function irReporte() {
    if (puedeConsultarReporte()) {
      props.history.push("/empaque/reporte");
    }
  }

  const puedeConsultar = (params) => {
    return (usuario && ["Administrador", "Administrador Empaque", "Auxiliar Empaque", "Administrador Calidad"].includes(usuario.rol))
  }

  const puedeConsultarReporte = (params) => {
    return (usuario && ["Administrador", "Administrador Empaque", "Auxiliar Empaque", "Administrador Calidad", "Personal Administrativo", "Administrador Embarques"].includes(usuario.rol))
  }

  useEffect(() => {
    if (ciclo) {
      updateCicloWeekSelector(ciclo);
    }
  }, [ciclo])
  useEffect(() => {
    if (week?.nombre !== "Cargando" && invernaderosVirtuales) {
      obtenerDatosIniciales();
    }
  }, [week, invernaderosVirtuales])
  const obtenerDatosIniciales = async () => {
    try {
      let promises = [];
      setCargando(true);

      let invernaderosInternos = DeepClone(invernaderosVirtuales.filter(el => !el.es_externo))// await obtenerInvernaderosVirtualesInternos(ciclo)          
      for (let x = 0; x < invernaderosInternos.length; x++) {
        let invernadero = invernaderosInternos[x];
        promises.push(obtenerResumenInvernadero(invernadero));
      }
      let values = await Promise.all(promises)
      let [transcurridasCiclo, semanasTotalesCiclo] = await obtenerSemanasTranscurridas(ciclo);
      let copia = invernaderosInternos.map(el => Object.assign({}, el));
      for (let x = 0; x < values.length; x++) {
        let [invId, invFisico, respSemanal, respCiclo, respProyectado,
          respTotalBudget, packoutSemanal, packoutAcumulado,
          variedad, producto, [transcurridas, semanasTotales], pronosticoProyeccion, final] = values[x];
        let inv = copia.find(el => el.id === invId);
        inv.invernaderoFisico = invFisico;
        inv.semanal = respSemanal;
        inv.kgCiclo = respCiclo;
        inv.proyectado = respProyectado;
        inv.totalBudget = respTotalBudget;
        inv.packoutAcumulado = packoutAcumulado;
        inv.packoutSemanal = packoutSemanal;
        inv.variedad = variedad;
        inv.producto = producto
        inv.semanasTranscurridas = transcurridas;
        inv.semanasTotales = semanasTotales;
        inv.proyectado = pronosticoProyeccion["proyeccion"]
        inv.final = final
      }

      setInvernaderos(copia);
      setCargando(false)
      let invernaderosExternos = DeepClone(invernaderosVirtuales.filter(el => el.es_externo))// await obtenerInvernaderosExternos(ciclo);
      promises = []
      for (let x = 0; x < invernaderosExternos.length; x++) {
        let invernadero = invernaderosExternos[x];
        promises.push(obtenerResumenExterno(invernadero));
      }
      values = await Promise.all(promises)

      copia = invernaderosExternos.map(el => Object.assign({}, el));
      for (let x = 0; x < values.length; x++) {
        let [invId, respSemanal, respCiclo, respProyectado,
          respTotalBudget, packoutSemanal, packoutAcumulado,
          producto, final] = values[x];

        let inv = copia.find(el => el.id === invId);
        inv.semanal = respSemanal;
        inv.kgCiclo = respCiclo;
        inv.proyectado = respProyectado;
        inv.totalBudget = respTotalBudget;
        inv.packoutAcumulado = packoutAcumulado;
        inv.packoutSemanal = packoutSemanal;

        inv.producto = producto
        inv.semanasTranscurridas = transcurridasCiclo;
        inv.semanasTotales = semanasTotalesCiclo;
        inv.final = final
      }

      setInvernaderosExternos(copia);
      setCargando(false);
    } catch (error) {
      throw error
    } finally {
      setCargando(false);
    }
  }

  async function obtenerResumenInvernadero(invernadero) {
    let semana = week.time, promises = [];
    let area = obtenerAreaInvernaderoSync(invernadero)
    let respSemanal = await getkgm2semanalSync(ciclo, invernadero, semana, area)

    const invernaderoFisico = invernaderosFisicos.find(inv => inv.id === invernadero.invernadero_fisico_ref);
    promises.push(getKgm2OrdenadosHasta(ciclo, invernadero.id, semana, area))
    promises.push(getPesoProyectado(ciclo, invernadero.id, semana, area))
    promises.push(obtenerTotalBudget(ciclo, invernadero.id))
    promises.push(obtenerPackoutSemanal(ciclo, invernadero, semana, respSemanal))
    promises.push(obtenerPackoutAcumulado(ciclo, invernadero.id, semana))
    promises.push(obtenerSemanasTranscurridasBuddget(ciclo, invernadero.id));
    promises.push(obtenerPronosticoYProyeccion(ciclo, invernadero, week.time, area))
    promises.push(getReporteEmpaqueInvernaderoSemanaFinal({ id: ciclo }, invernadero, week))
    const producto = getObjetoPorID(productosYVariedades, invernadero.producto_ref)
    const variedad = getObjetoPorID(allVariedades, invernadero.variedad_ref)

    let [respCiclo, respProyectado, respTotalBudget, packoutSemanal, packoutAcumulado, semanas, pronosticoProyeccion, resultFinal] = await Promise.all(promises);
    return [invernadero.id, invernaderoFisico, respSemanal, respCiclo, respProyectado, respTotalBudget, packoutSemanal,
      packoutAcumulado, variedad, producto, semanas, pronosticoProyeccion, resultFinal];
  }
  async function obtenerResumenExterno(invernadero) {
    try {

      let semana = week.time;
      let promises = [];
      let respSemanal = await obtenerkgm2semanal(ciclo, invernadero.id, semana)
      let resultFinal = await getReporteEmpaqueInvernaderoSemanaFinal({ id: ciclo }, invernadero, week)

      promises.push(obtenerKgm2OrdenadosHasta(ciclo, invernadero.id, semana))
      promises.push(obtenerPesoProyectadoExterno(ciclo, invernadero.id, semana))
      promises.push(obtenerTotalBudgetExterno(ciclo, invernadero.id))
      promises.push(obtenerPackoutSemanal(ciclo, invernadero, semana, respSemanal))
      promises.push(obtenerPackoutAcumulado(ciclo, invernadero.id, semana))
      const producto = getObjetoPorID(productosYVariedades, invernadero.producto_ref)

      const [respCiclo, respProyectado, respTotalBudget, packoutSemanal, packoutAcumulado] = await Promise.all(promises);
      return [invernadero.id, respSemanal, respCiclo, respProyectado, respTotalBudget, packoutSemanal, packoutAcumulado, producto, resultFinal];

    } catch (error) {
      console.log("error ore");
      throw error;
    }
  }

  const irGraficaCiclo = () => { props.history.push("/empaque/graficas/distribucion_presentaciones_ciclo"); }
  const irGraficaSemana = () => { props.history.push("/empaque/graficas/distribucion_presentaciones_semana"); }

  return (
    <div className="p-grid">
      <div className="p-col-12">
        {puedeConsultarReporte() &&
          <div className="btn-row">
            <Button label="Reporte general de empaque" onClick={irReporte} />
            <Button className="reactbtn-icon" label="Presentaciones en el ciclo" onClick={irGraficaCiclo}>
              <FontAwesomeIcon icon={faChartBar} />
            </Button>
            <Button className="reactbtn-icon" label="Presentaciones por Semana" onClick={irGraficaSemana}>
              <FontAwesomeIcon icon={faChartBar} />
            </Button>
          </div>}
      </div>

      <div className="p-col-12">
        <div className="p-grid filtros">
          <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
        </div>
      </div>

      <div className="p-col-12">
        <div className="p-grid">
          {invernaderos && invernaderos.map(invernadero => <InvernaderoCard key={invernadero.id} invernadero={invernadero} />)}
          {invernaderosExternos.map(invernadero => <InvernaderoExternoCard key={invernadero.id} invernadero={invernadero} />)}
        </div>
      </div>
    </div>)
}
export default Invernaderos;//236