import {
    obtenerInicioSemanaDia, obtenerIndiceDiaSemana,
    obtenerDiaActual, obtenerHora,
    obtenerDiasDeSemana,
    obtenerDiasDeSemanaConDetalles,
    obtenerInicioDia,
    flexDate
} from "../../service/fechas";
import { obtenerRegistrosSemana, obtenerValoresReales } from "../../service/Cosecha";
import { horaCorte, horaCorteDiario, nombresDiasSemanaPlanos } from '../../constants';
import { dosDecimales } from '../../util/functions';
import { obtenerPesoYCajasSemanaInteligenteReporteCosecha } from "../../service/ReportesProducto";
import { obtenerRegistrosEmpaqueVariedadMezcla } from "../../service/Empaques";
import { getFirestoreID } from "../../service/Mantenimiento";
const nombreDias = ["lunes", "martes", "miercoles", "jueves", "viernes", "sabado"];
export const extraProcessingDiario = (updatedState, dias, timestamps, hoy, hora, indiceHoy, pesoCaja, area) => {
    let results = dias.map((dia, index) => {
        let el = parseFloat(updatedState[1][dia]);
        if (el) {
            return el;
        }
        if (hoy < timestamps[index] || (hoy === timestamps[index] && hora < horaCorteDiario)) {// no ha llegado dia
            el = parseFloat(updatedState[0][dia])//tomar semanal
            if (isNaN(el)) {
                el = 0;
            }
        } else if (isNaN(el)) {
            el = 0;
        }

        return el;
    });
    let total_cajas = results.reduce((acc, curr) => acc + curr, 0);
    let total_kg = total_cajas * pesoCaja / area;
    updatedState[1]["total_cajas"] = total_cajas;
    updatedState[1]["total_kg"] = total_kg;

}
export const extraProcessingDiarioNEW = (updatedState, dias, timestamps, hoy, hora, indiceHoy, pesoCaja, area) => {
    let results = dias.map((dia, index) => {
        let el = parseFloat(updatedState[1][dia]);
        if (el) {
            return el;
        }
        if (hoy < timestamps[index] || (hoy === timestamps[index] && hora < horaCorteDiario)) {// no ha llegado dia
            el = parseFloat(updatedState[0][dia])//tomar semanal
            if (isNaN(el)) {
                el = 0;
            }
        } else if (isNaN(el)) {
            el = 0;
        }

        return el;
    });
    let total_cajas = results.reduce((acc, curr) => acc + curr, 0);
    let total_kg = total_cajas * pesoCaja / area;
    updatedState[1]["total_cajas"] = total_cajas;
    updatedState[1]["total_kg"] = total_kg;
    return updatedState

}
export const extraProcessingSemanalNEW = (updatedState, dias, pesoCaja, area) => {
    let results = dias.map((dia, index) => {
        let el = parseFloat(updatedState[0][dia]);
        if (isNaN(el)) {
            el = 0;
        }
        return el;
    });
    let total_cajas = results.reduce((acc, curr) => acc + curr, 0);
    let total_kg = total_cajas * pesoCaja / area;

    updatedState[0]["total_cajas"] = total_cajas;
    updatedState[0]["total_kg"] = total_kg;
    return updatedState
}
export const extraProcessingSemanal = (updatedState, dias, pesoCaja, area) => {
    let results = dias.map((dia, index) => {
        let el = parseFloat(updatedState[0][dia]);
        if (isNaN(el)) {
            el = 0;
        }
        return el;
    });
    let total_cajas = results.reduce((acc, curr) => acc + curr, 0);
    // 

    let total_kg = total_cajas * pesoCaja / area;
    // 
    updatedState[0]["total_cajas"] = total_cajas;

    updatedState[0]["total_kg"] = total_kg;
    // 
}

export const extraProcessingReal = (updatedState, dias, timestamps, hoy, hora, indiceHoy, pesoCaja, area, reales, realesCallback, isMezcla = false, resumen, resumenCallback) => {
    console.log("RES UPDATED PROC: " + JSON.stringify(resumen))
    let results = reales.map((dia, index) => {
        if (index > 5) {
            return 0;
        }
        let el = parseFloat(dia);
        let diario = parseFloat(updatedState[1][nombreDias[index]])
        if (hoy < timestamps[index]) {//hay que tomar semanal?
            el = parseFloat(updatedState[0][nombreDias[index]]);

            if (isNaN(el)) {
                el = 0;
            }
            if (diario) {// dar prioridad diario
                el = diario
            }
        }
        else if (hoy === timestamps[index] && hora < horaCorte) {
            el = parseFloat(updatedState[1][nombreDias[index]]);
            if (isNaN(el)) {
                el = 0;
            }
        }
        else if (isNaN(el)) {
            el = 0;
        }
        return el;
    });


    let total_cajas = results.reduce((acc, curr) => acc + curr, 0);
    let total_kg = total_cajas * pesoCaja / area;
    let copia = reales.slice();
    copia[6] = dosDecimales(total_cajas);
    copia[7] = dosDecimales(total_kg);



    if (isMezcla) {
        //    console.log("1 procesado real BEFORE: " + JSON.stringify(reales))
        //   console.log("1 procesado real AFTER: " + JSON.stringify(copia))
        //   console.log("----------")

        let totalCajasSemanal = 0, totalCajasDiario = 0, totalCajasReal = 0, totalKgReal = 0

        for (const key in resumen[0]) {
            let value = resumen[0][key]
            if (dias.includes(key)) totalCajasSemanal += value
        }
        let count = 0
        for (const key in resumen[1]) {
            let value = resumen[1][key]
            if (dias.includes(key)) {
                //       console.log(hoy + " -timeee- " + timestamps[count] + "  key: " + key + " -- " + value + " count " + count + " -- " + !!(hoy < timestamps[count]))

                if (hoy < timestamps[count]) {
                    //            console.log("future")
                    totalCajasDiario += resumen[1][key] || resumen[0][key] || 0
                }
                else if (hoy === timestamps[count] && hora < horaCorte) {
                    //           console.log("today")
                    totalCajasDiario += resumen[1][key] || 0
                }
                else {
                    //           console.log("past")
                    totalCajasDiario += resumen[1][key] || 0
                }
                count++;
            }
        }
        //     console.log("REAL_________")
        //     console.log(JSON.stringify(reales))
        reales.forEach((real, index) => {
            let nombreDia = dias[index]

            if (index < 6) {
                if (hoy < timestamps[index]) {
                    //            console.log("future")
                    totalCajasReal += resumen[1][nombreDia] || resumen[0][nombreDia] || 0;
                }
                else if (hoy === timestamps[index]) {
                    //            console.log("today")
                    totalCajasReal += real || resumen[1][nombreDia] || 0;
                }
                else {
                    //           console.log("past")
                    totalCajasReal += real || 0;
                }

            }

        })
        let [resumenSemanal, resumenDiario] = resumen
        resumenSemanal.total_kg = (totalCajasSemanal * parseFloat(pesoCaja)) / area;
        resumenDiario.total_kg = (totalCajasDiario * parseFloat(pesoCaja)) / area;
        totalKgReal = (totalCajasReal * parseFloat(pesoCaja)) / area;

        reales[6] = totalCajasReal;
        reales[7] = totalKgReal;
        realesCallback(reales)

        resumen[0].total_cajas = totalCajasSemanal
        resumen[1].total_cajas = totalCajasDiario
        resumenCallback(resumen)
        return { totalCajasSemanal, totalCajasDiario }
    }
    else realesCallback(copia);
}

export const obtenerRegistrosSemanaAnterior = async (ciclo, week, invernadero, anteriorInicial, pesoCaja, area, setAnterior, setRealesAnteriores) => {
    //obtenerRegistrosSemanaAnterior
    let timeAnterior = week.time
    try {
        let resp = await obtenerRegistrosSemana(ciclo, invernadero.id, timeAnterior);

        procesarRegistrosAnteriores(resp, anteriorInicial, pesoCaja, area, setAnterior);
        let kilosCajas = await obtenerPesoYCajasSemanaInteligenteReporteCosecha(ciclo, invernadero.id, week.time, resp, pesoCaja);
        obtenerYProcesarRealesSemanaAnterior(ciclo, invernadero, week, pesoCaja, area, setRealesAnteriores, kilosCajas["kilos"]);
    } catch (error) {
        console.log("HUBO UN ERROR AL CARGAR REGISTROS SEMANA ANTERIOR");
        console.log(error);
        throw (error)
    }
}
function procesarRegistrosAnteriores(resp, anteriorInicial, pesoCaja, area, setAnterior) {
    const dias = ["lunes", "martes", "miercoles", "jueves", "viernes", "sabado"];
    // 
    // 
    let copia = anteriorInicial.map(el => Object.assign({}, el));
    resp.forEach(el => {
        if (el) {
            let indice = obtenerIndiceDiaSemana(el.dia);
            const semanal = parseInt(el.semanal);
            const diaria = parseInt(el.diaria);
            if (!isNaN(semanal)) {
                copia[0][dias[indice]] = semanal;
                copia[0]["total_cajas"] += semanal;
            }
            if (!isNaN(diaria)) {
                copia[1][dias[indice]] = diaria;
                copia[1]["total_cajas"] += diaria;
            }
        }
    })

    copia[0]["total_kg"] = copia[0]["total_cajas"] * pesoCaja / area;
    copia[1]["total_kg"] = copia[1]["total_cajas"] * pesoCaja / area;

    setAnterior(copia);
}
export const obtenerRegistrosSemanaActual = async (indiceHoy, ciclo, invernadero, week, setReales, hora, pesoCaja, area, tabla, tablaInicial, setTabla, setCosechaIdsCallback) => {
    let respSemana = await obtenerRegistrosSemana(ciclo, invernadero.id, week.time);
    let procesado = procesarRegistros(respSemana, tablaInicial, pesoCaja, area, setTabla, week, setCosechaIdsCallback).slice();
    //console.log("OBTENER Datos cosecha: " + JSON.stringify(respSemana))

    let respPromise = obtenerValoresReales(ciclo, invernadero.id, week.time);
    let kilosCajasPromise = obtenerPesoYCajasSemanaInteligenteReporteCosecha(ciclo, invernadero.id, week.time, respSemana, pesoCaja);
    const [resp, kilosCajas] = await Promise.all([respPromise, kilosCajasPromise])
    let reales = procesarRealesSemanaActual(resp, procesado, indiceHoy, hora, week, pesoCaja, area, tabla, kilosCajas["kilos"]);
    setReales(reales);
}
export const obtenerRegistrosSemanaActualVariedadesMezcla = async (indiceHoy, ciclo, invernadero, week, setReales, hora, pesoCaja, area, tabla, tablaInicial, setTabla, setDetalles, variedadesMezcla, setCosechaIdsCallback) => {
    let respSemana = await obtenerRegistrosSemana(ciclo, invernadero.id, week.time);
    let dets = procesarDetalles(respSemana.map(el => ({ ...el })), variedadesMezcla, week.time)
    let procesado = procesarRegistros(respSemana, tablaInicial, pesoCaja, area, setTabla, week, setCosechaIdsCallback).slice();
    //console.log("OBTENER Datos cosecha: " + JSON.stringify(respSemana))

    let respPromise = obtenerValoresReales(ciclo, invernadero.id, week.time);
    let kilosCajasPromise = obtenerPesoYCajasSemanaInteligenteReporteCosecha(ciclo, invernadero.id, week.time, respSemana, pesoCaja);

    const [resp, kilosCajas] = await Promise.all([respPromise, kilosCajasPromise])
    //console.log("\n\nRESP: " + JSON.stringify(resp))
    let reales = procesarRealesSemanaActual(resp, procesado, indiceHoy, hora, week, pesoCaja, area, tabla, kilosCajas["kilos"]);
    setDetalles(dets);
    setReales(reales);
    return dets;
}

export const procesarDetalles = (registros, variedadesMezcla = [], semana) => {
    let result = {};
    let dias = obtenerDiasDeSemanaConDetalles(semana);
    variedadesMezcla.forEach(variedad => {
        let diario = { label: "Diario" };
        let semanal = { label: "Semanal" }
        dias.forEach(dia => {
            const timestamps = flexDate(dia.unixTime)
            let datoDia = registros.find(el => el.dia >= timestamps.inicio && el.dia < timestamps.fin)
            if (datoDia) {
                let datoVariedad = datoDia?.detalle?.[variedad];
                if (datoVariedad) {
                    diario[dia.nombrePlano] = datoVariedad["diario"]
                    semanal[dia.nombrePlano] = datoVariedad["semanal"]
                }
            } else {
                diario[dia.nombrePlano] = "";
                semanal[dia.nombrePlano] = "";
            }
        })
        result[variedad] = [semanal, diario];
    })
    // console.log("RESULT: " + JSON.stringify(result))
    return result;
}

const procesarRealesSemanaActual = (resp, procesado, indiceHoy, hora, week, pesoCaja, area, tabla, kilos) => {
    let copia = resp.slice();
    ajustarRealesBusquedaInteligente(resp, indiceHoy, hora, week, procesado);

    let timestamps = obtenerDiasDeSemana(week.time);

    let total = calcularTotalRealesAnterior(resp, timestamps, procesado);
    let total_kg = kilos / area;//total * pesoCaja/area;        
    resp.push(total);
    resp.push(total_kg);
    copia.push(total);
    copia.push(total_kg);

    resp = resp.map(el => {
        return dosDecimales(el);
    })
    copia = copia.map(el => {
        return dosDecimales(el);
    })
    return copia;
}
const ajustarRealesBusquedaInteligente = (resp, indiceHoy, hora, week, tabla) => {

    let inicioSemana = obtenerInicioSemanaDia(Math.round(Date.now() / 1000)).unix();
    let esSemanaActual = week.time >= inicioSemana;

    resp.forEach((dia, index) => {

        let nombreDia = nombreDias[index];
        if (index > indiceHoy && esSemanaActual) {// aplica semanal

            let diario = parseFloat(tabla[1][nombreDia]);
            if (!isNaN(diario)) {

                resp[index] = tabla[1][nombreDia];
            } else {
                resp[index] = tabla[0][nombreDia];
            }
        }
        else if (index === indiceHoy && hora < horaCorte && esSemanaActual) {
            resp[index] = tabla[1][nombreDia];
        }
    })
}

const calcularTotalRealesAnterior = (resp, timestamps, procesado) => {
    let total = resp.reduce((acc, curr, index) => {
        let hoy = obtenerDiaActual();
        let hora = obtenerHora(Math.round(Date.now() / 1000));
        let nombreDia = nombreDias[index];
        let num = parseFloat(curr);
        let el = num;
        if (hoy < timestamps[index]) {
            let diario = parseFloat(procesado[1][nombreDia]);
            if (!isNaN(diario)) {
                el = diario;
            } else {
                el = parseFloat(procesado[0][nombreDia]);
            }
            if (isNaN(el)) {
                return acc;
            }
        }
        else if (hoy === timestamps[index] && hora < horaCorte) {
            el = parseFloat(procesado[1][nombreDia]);
            if (isNaN(el)) {
                return acc;
            }
        }
        else if (isNaN(el)) {
            return acc
        }
        return acc + el;
    }, 0);
    return total;
}

function procesarRegistros(resp, tablaInicial, pesoCaja, area, setTabla, week, setCosechaIdsCallback) {
    let diasSemana = obtenerDiasDeSemana(week);
    const dias = ["lunes", "martes", "miercoles", "jueves", "viernes", "sabado"];
    let hoy = obtenerDiaActual();
    let indiceHoy = obtenerIndiceDiaSemana(hoy);
    let hora = obtenerHora(Math.round(Date.now() / 1000));
    let copia = tablaInicial.map(el => Object.assign({}, el));
    copia[0]["total_cajas"] = 0;
    copia[1]["total_cajas"] = 0;
    //console.log("\n\copia PROC: "+JSON.stringify(copia))
    const cosechaFirestoreIds =  dias.map((el, index) => resp?.[index]?.id || getFirestoreID())
    setCosechaIdsCallback(cosechaFirestoreIds)
    resp.forEach(el => {
        if (el) {
            let indice = obtenerIndiceDiaSemana(el.dia);
            const semanal = parseInt(el.semanal);
            const diaria = parseInt(el.diaria);

            if (!isNaN(semanal)) {
                copia[0][dias[indice]] = semanal;
                copia[0]["total_cajas"] += semanal;
            }
            if (!isNaN(diaria)) {
                copia[1][dias[indice]] = diaria;

            }
            if (hoy > el.dia || (hoy === el.dia && hora >= horaCorteDiario)) {
                if (!isNaN(diaria)) {
                    copia[1]["total_cajas"] += diaria;
                }
            }
            else if (!isNaN(diaria)) {
                copia[1]["total_cajas"] += diaria;
            } else {
                if (!isNaN(semanal)) {
                    copia[1]["total_cajas"] += semanal;
                }
            }
        }
    })
    copia[0]["total_kg"] = copia[0]["total_cajas"] * pesoCaja / area;
    copia[1]["total_kg"] = copia[1]["total_cajas"] * pesoCaja / area;
    //console.log("\n\COPIA PROC: "+JSON.stringify(copia[0]))
    setTabla(copia);
    return copia;
}
export const obtenerYProcesarRealesSemanaAnterior = (ciclo, invernadero, week, pesoCaja, area, setRealesAnteriores, kilos) => {
    obtenerValoresReales(ciclo, invernadero.id, week.time).then(resp => {
        // 
        // 
        let total = resp.reduce((acc, curr) => acc + curr, 0);
        let total_kg = kilos / area;//total*pesoCaja/area;
        //

        //

        resp.push(total);
        resp.push(total_kg);
        resp = resp.map(el => {
            return dosDecimales(el);
        })
        // 
        // 
        setRealesAnteriores(resp);

    }).catch(err => {
        console.log("HUBO UN ERROR AL CARGAR VALORES REALES");
        console.log(err);;
    })
}
export const obtenerRealesVariedadMezcla = async (cicloId, invernaderoId, semanaTime, variedad, detalles, rowIndex) => {
    let registros = await obtenerRegistrosEmpaqueVariedadMezcla(cicloId, invernaderoId, variedad.id, semanaTime)
    let dias = obtenerDiasDeSemana(semanaTime);

    let ahora = Math.round(Date.now() / 1000);
    let hoy = obtenerInicioDia(ahora)
    let total = 0;
    let reales = dias.map((diaTime, index) => {
        let result = 0;
        if (!pasaCorteReal(diaTime)) {
            let totalDiario = parseFloat(detalles?.[variedad?.id]?.[1]?.[nombresDiasSemanaPlanos?.[index]] || 0)
            let totalSemanal = parseFloat(detalles?.[variedad?.id]?.[0]?.[nombresDiasSemanaPlanos?.[index]] || 0)
            if (hoy === diaTime && rowIndex !== 0) total += totalDiario;
            else if (hoy < diaTime) total += totalDiario ? totalDiario : totalSemanal;

        } else {

            let registrosDia = registros.filter(el => el.dia >= diaTime && el.dia < diaTime + 24 * 60 * 60);
            registrosDia.forEach(registro => {
                registro.pallets.forEach(pallet => {
                    let valor = parseFloat(pallet["cajas"])
                    total += valor;
                    result += valor
                })
            })
        }

        return result;
    })
    reales.push(total);
    return reales;
}
const pasaCorteReal = (momento) => {
    let ahora = Math.round(Date.now() / 1000);
    let hoy = obtenerInicioDia(ahora)
    return momento < hoy || (momento === hoy && ahora >= hoy + horaCorte * 60 * 60)
}
//467