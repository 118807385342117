import React, { useState, useEffect } from 'reactn';
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { withRouter } from "react-router-dom";
import { getNombrePorID } from 'util/functions';

const SelectorEnfermedadesModal = (props) => {
    const [enfermedadesSeleccionados, setEnfermedadesSeleccionados] = useState([]);
    useEffect(() => {
        setEnfermedadesSeleccionados([]);
    }, [props.visible])

    const crearOrden = () => props.history.push({ pathname: `/biocontrol/orden_aplicacion/${props.invID}/crear`, data: { enfermedadesSeleccionados, invernadero: props.invernadero } })

    return (
        <Dialog header="Preselección de enfermedades y plagas" modal={true} visible={props.visible} onHide={props.hide}>
            <div className="form-group p-col-12">
                <label htmlFor="enfermedades_oa">Enfermedades a atacar en la orden</label>
                <MultiSelect id="enfermedades_oa"
                    selectedItemsLabel={`${enfermedadesSeleccionados.length} enfermedades seleccionadas`}
                    optionValue="id" optionLabel="nombre" options={props.enfermedades} value={enfermedadesSeleccionados}
                    onChange={(e) => setEnfermedadesSeleccionados(e.value)} filter={true} />
            </div>
            <div className="form-group p-col-12">
                <span className="modal-subtitle">{`Enfermedades/plagas (${enfermedadesSeleccionados.length}):`}</span>
                <ul>
                    {enfermedadesSeleccionados.map(enfermedadID =>
                        <li className="enfermedades-item" key={`eoa-${enfermedadID}`}>
                            <span>{getNombrePorID(props.enfermedades, enfermedadID)}</span>
                        </li>
                    )}
                </ul>
            </div>
            <div className="form-group">
                <div className="btn-row">
                    <Button className="reactbtn-icon" label="Siguiente" onClick={crearOrden} />
                </div>
            </div>
        </Dialog>
    );
}
export default withRouter(SelectorEnfermedadesModal);