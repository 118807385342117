import React, { useEffect } from 'react'
import ContenedorPrincipal from '../common/ContenedorPrincipal'
import ContenedorHeader from '../common/ContenedorHeader'
import SeccionFiltros from '../common/SeccionFiltros'
import ContenedorFiltro from '../common/ContenedorFiltro'
import ChartContainer from '../common/ChartContainer'
import { Dropdown } from 'primereact/dropdown'
import { MultiSelect } from 'primereact/multiselect'
import { useState } from 'react'
import { useGlobal } from 'reactn'
import { obtenerClientes, obtenerClientesCiclo } from '../../service/Clientes'
import { obtenerEmpacadoras } from '../../service/Empacadoras'
import { obtenerRegistrosCalidadPesosSemanaTodosInvernaderos, obtenerResumenCalidadEmpacadora } from '../../service/Calidad'
import Chart from "react-apexcharts";
import { dosDeciComas, getObjetoPorLlave, ordenarArreglaDeObjetosPorLlaveFloat, paletaColores, paletaSemaforo, sumarNumerosArrayPorCampo } from '../../util/functions'
import { obtenerListaDeSemanas, obtenerNombreSemanaCompacto } from '../../service/fechas'
import { obtenerPresentaciones, obtenerPresentacionesHabilitadas } from '../../service/Presentaciones'
import { obtenerGruposPresentaciones } from '../../service/QueriesGruposPresentaciones'
import { ToggleButton } from 'primereact/togglebutton'
import { UnidadKilosBg } from 'components/common/templates_unidades/UnidadKg.atomo'
import { DESCENDIENTE } from '../../constants'

let optionsOrig = {

    colors: paletaSemaforo,
    chart: {
        height: 350,
        stacked: true,
        type: "bar",
        stackType: "100%",
        animations: {
            enabled: false
        }
    },


    responsive: [{
        breakpoint: 480,
        options: {
            legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0,
                colors: paletaColores
            }
        }
    }],
    xaxis: {
        categories: [],
    },
    yaxis: {
        decimalsInFloat: 2
    },
    plotOptions: {
        bar: {
            horizontal: true,
        }
    },
    fill: {
        opacity: 1
    },
    tooltip: {
        enabled: true,
        followCursor: true,
    },
    legend: {
        colors: paletaColores,
        position: 'right',
        offsetX: 0,
        offsetY: 400,
    },
}
export default function EmpaqueEmpacadora(props) {
    const [clientes, setClientes] = useState([])
    const [filtroEmpacadoras, setFiltroEmpacadoras] = useState([])
    const [filtroClientes, setFiltroClientes] = useState(undefined)
    const [cicloId] = useGlobal("ciclo")
    const [cicloObj] = useGlobal("cicloObj")
    const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales")
    const [datos, setDatos] = useState([])
    const [options, setOptions] = useState({ ...optionsOrig })
    const [, setCargando] = useGlobal("cargando")
    const [gruposFiltro, setGruposFiltro] = useState(true)

    const [empacadoras, setEmpacadoras] = useState([])
    const [empacadorasPresentes, setEmpacadorasPresentes] = useState([])

    const [presentacionesOpciones, setPresentacionesOpciones] = useState([])
    const [gruposPresentacionesOpciones, setGruposPresentacionesOpciones] = useState([])

    const [presentacionesOriginales, setPresentacionesOriginales] = useState([])
    const [gruposPresentacionesOriginales, setGruposPresentacionesOriginales] = useState([])

    const [filtroPresentaciones, setFiltroPresentaciones] = useState([])
    const [filtroGruposPresentaciones, setFiltroGruposPresentaciones] = useState([]);

    const [registros, setRegistros] = useState([]);


    useEffect(() => {
        if (cicloId) { obtenerDatosIniciales(); }
    }, [cicloId])
    const obtenerDatosIniciales = async (params) => {
        setCargando(true)
        let ciclo = cicloObj;
        let semanas = obtenerListaDeSemanas(ciclo.semana_inicio, ciclo.semana_cierre);
        let invernaderos = invernaderosVirtuales.filter(inv => inv.habilitado)
        let promises = semanas.map(semana => obtenerRegistrosCalidadPesosSemanaTodosInvernaderos(cicloId, semana.time, invernaderos))
        let registrosAll = await Promise.all(promises);

        setRegistros(registrosAll)

        let clientesCicloPromise = obtenerClientesCiclo(cicloId);
        let clientesPromise = obtenerClientes();
        let empacadorasPromise = obtenerEmpacadoras()

        let presentacionesOpcionesResPromise = obtenerPresentaciones();
        let gruposPresentacionesionesResPromise = obtenerGruposPresentaciones();


        let [clientesCiclo, clientes, empacadoras, presentacionesOpcionesRes, gruposPresentacionesionesRes] =
            await Promise.all([clientesCicloPromise, clientesPromise, empacadorasPromise, presentacionesOpcionesResPromise, gruposPresentacionesionesResPromise]);

        let ids = clientesCiclo.map(el => el.cliente_ref);
        clientes = clientes.filter(el => ids.includes(el.id))

        let empacadorasFiltrados = []
        empacadoras.forEach(empacadora => {
            let resumen = obtenerResumenCalidadEmpacadora(cicloObj, empacadora, clientesCiclo, presentacionesOpcionesRes, registrosAll, presentacionesOpcionesRes, gruposPresentacionesionesRes);
            if (resumen?.some(el => el.muestras > 0)) {
                const { sumasPresentaciones, sumasGrupos } = resumen;
                empacadorasFiltrados.push({ ...empacadora, resumen, sumasPresentaciones, sumasGrupos, sumaKg: sumarNumerosArrayPorCampo(resumen, "sumaKg") });
            }
        })
        //console.log("l1 l2 ",{length1: empacadoras?.length, length2: empacadorasFiltrados?.length, empacadorasFiltrados} )
        ordenarArreglaDeObjetosPorLlaveFloat(empacadoras, "sumaKg", DESCENDIENTE);
        ordenarArreglaDeObjetosPorLlaveFloat(empacadorasFiltrados, "sumaKg", DESCENDIENTE);
        setClientes(clientes);
        setEmpacadoras(empacadoras);
        setEmpacadorasPresentes(empacadorasFiltrados)


        setPresentacionesOriginales(presentacionesOpcionesRes);
        setGruposPresentacionesOriginales(gruposPresentacionesionesRes)
        setCargando(false)
    }

    ////////////
    /////////////

    ////////////
    ////////////

    useEffect(() => {
        if (filtroClientes) {
            let idsClientes = filtroClientes.map(el => el.id)


            let gruposPresentacionesFiltradosPorCliente = gruposPresentacionesOriginales.filter(pres => idsClientes.includes(pres.cliente_ref))
            let presentacionesFiltradosPorGrupo = []
            gruposPresentacionesFiltradosPorCliente.forEach(grupo => {
                let filtrados = presentacionesOriginales.filter(pres => grupo.presentaciones_refs.includes(pres.id))
                presentacionesFiltradosPorGrupo.push(...filtrados)
            })

            if (!gruposFiltro) { presentacionesFiltradosPorGrupo = presentacionesOriginales.filter(pres => idsClientes.includes(pres.cliente_ref)) }

            const presentacionesProcesados = filtroEmpacadoras?.id ? presentacionesFiltradosPorGrupo?.map(pres => {
                const sumasPreses = filtroEmpacadoras?.resumen?.map(el => el?.sumasPresentaciones || {}) || [];
                const sumasPresesActual = sumasPreses?.map(el => el[pres.id])
                const sumaKg = sumarNumerosArrayPorCampo(sumasPresesActual, "sumaKg");
                return { ...pres, sumaKg }
            }) || [] : presentacionesFiltradosPorGrupo;

            const gruposProcesados = filtroEmpacadoras?.id ? gruposPresentacionesFiltradosPorCliente?.map(grupo => {
                const sumasGrupos = filtroEmpacadoras?.resumen?.map(el => el?.sumasGrupos || {}) || [];
                const sumasGruposActual = sumasGrupos?.map(el => el[grupo.id])
                const sumaKg = sumarNumerosArrayPorCampo(sumasGruposActual, "sumaKg");
                return { ...grupo, sumaKg }
            }) || [] : gruposPresentacionesFiltradosPorCliente;
            ordenarArreglaDeObjetosPorLlaveFloat(presentacionesProcesados, "sumaKg", DESCENDIENTE);
            ordenarArreglaDeObjetosPorLlaveFloat(gruposProcesados, "sumaKg", DESCENDIENTE);
            setPresentacionesOpciones(presentacionesProcesados.filter(el => el.sumaKg))
            setGruposPresentacionesOpciones(gruposProcesados.filter(el => el.sumaKg))
            presentacionesFiltradosPorGrupo = presentacionesProcesados.filter(el => el.sumaKg)?.filter(pres => filtroGruposPresentaciones?.some(grupo => grupo.presentaciones_refs.includes(pres.id)))
            const primeraPresentacion = presentacionesFiltradosPorGrupo?.[0];
            setFiltroPresentaciones(primeraPresentacion ? [primeraPresentacion] : []);
        }
    }, [filtroClientes, filtroEmpacadoras, gruposFiltro])

    useEffect(() => {
        if (registros.length > 0) {
            let idsClientes = filtroClientes.map(el => el.id)

            let gruposPresentacionesFiltradosPorCliente = filtroGruposPresentaciones.filter(pres => idsClientes.includes(pres.cliente_ref))
            let presentacionesFiltradosPorGrupo = []
            gruposPresentacionesFiltradosPorCliente.forEach(grupo => {
                let filtrados = presentacionesOriginales.filter(pres => grupo.presentaciones_refs.includes(pres.id))
                presentacionesFiltradosPorGrupo.push(...filtrados)
            })
            setFiltroPresentaciones(presentacionesFiltradosPorGrupo?.length ? [presentacionesFiltradosPorGrupo[0]] : [])
        }
    }, [filtroGruposPresentaciones])
    useEffect(() => {
        if (registros.length > 0) { obtenerDatosResumen(); }
    }, [filtroPresentaciones])

    const obtenerDatosResumen = (params) => {
        try {
            setCargando(true);
            let resumen = obtenerResumenCalidadEmpacadora(cicloObj, filtroEmpacadoras, filtroClientes, filtroPresentaciones, registros, presentacionesOriginales, gruposPresentacionesOriginales);
            let procesado = procesar(resumen);
            let copiaOptions = { ...options };
            copiaOptions["xaxis"] = {}
            copiaOptions["xaxis"]["categories"] = resumen.map(el => obtenerNombreSemanaCompacto(el.time))

            console.log("DATOS obtenerDatosResumen: ", procesado)
            setOptions(copiaOptions);
            setDatos(procesado);
        } catch (error) {
            console.log(JSON.stringify(error))
            throw error
        } finally {
            setCargando(false);
        }
        setCargando(false);

    }
    const procesar = (resumen) => {
        let serieAlto = { name: "Alto", data: [] }
        let serieNormal = { name: "Normal", data: [] }
        let serieBajo = { name: "Bajo", data: [] }
        resumen.forEach(semana => {
            serieAlto["data"].push(semana["altos"])
            serieBajo["data"].push(semana["bajos"])
            serieNormal["data"].push(semana["normales"])
        })
        return [serieBajo, serieNormal, serieAlto]
    }
    const filtroGruposPresentacionesValue = filtroGruposPresentaciones[0];

    const itemTemplateGrupos = (itemDatos) => { return (<label> <strong>{itemDatos.nombre}</strong> - {dosDeciComas(itemDatos.sumaKg)} kg </label>); }
    const itemTemplatePresentaciones = (itemDatos) => { return (<label > <strong>{itemDatos.presentacion}</strong>  - <UnidadKilosBg valor={dosDeciComas(itemDatos.sumaKg)} /> </label>); }
    const itemTemplateEmpacadoras = (itemDatos) => { return (<label > {itemDatos.nombre}</label>); }
    return (
        <ContenedorPrincipal extra="chart-page">
            <ContenedorHeader titulo="Gráfica de calidad por empacadora" iconos="chart-icon" atras={() => props.history.goBack()} />
            <SeccionFiltros>
                <ContenedorFiltro label="Empacadora">
                    <Dropdown value={filtroEmpacadoras} options={empacadorasPresentes} optionLabel="nombre" dataKey="id" onChange={e => setFiltroEmpacadoras(e.value)}
                        itemTemplate={itemTemplateEmpacadoras} />
                </ContenedorFiltro>

                <ContenedorFiltro label="Cliente">
                    <Dropdown value={filtroClientes?.[0]} options={clientes} optionLabel="nombre" dataKey="id" onChange={e => setFiltroClientes([e.value])} />
                </ContenedorFiltro>


                <ContenedorFiltro label={gruposFiltro ? "grupos presentaciones" : "presentación"}>
                    {gruposFiltro ?
                        <Dropdown key="pres" value={filtroGruposPresentacionesValue} options={gruposPresentacionesOpciones} optionLabel="nombre" dataKey="id"
                            onChange={e => setFiltroGruposPresentaciones([e.value])} filter={true} itemTemplate={itemTemplateGrupos} />
                        :
                        <Dropdown key="grup" value={filtroPresentaciones?.[0]} options={presentacionesOpciones} optionLabel="presentacion" dataKey="id"
                            onChange={e => setFiltroPresentaciones([e.value])} filter={true} itemTemplate={itemTemplatePresentaciones}
                            selectedItemsLabel={`${filtroPresentaciones.length} presentaciones seleccionadas`}
                        />
                    }
                </ContenedorFiltro>
                <ContenedorFiltro label="tipos">
                    <ToggleButton className="reactbtn-icon" onLabel="Grupos presentaciones" offLabel="Presentaciones" checked={gruposFiltro} onChange={(ev) => setGruposFiltro(ev.value)} ></ToggleButton>
                </ContenedorFiltro>


            </SeccionFiltros>
            <ChartContainer type="large">
                <Chart options={options} series={datos} type="bar" height="100%" width="100%" />
            </ChartContainer>
        </ContenedorPrincipal>
    )
}
