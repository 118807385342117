import React, { useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from "primereact/button";
import { useGlobal } from "reactn";
import { Dropdown } from 'primereact/dropdown';
import ContenedorInput from '../../common/ContenedorInput';
import { getInicioSemanaDia, getNombreFechaCompacto } from '../../../service/fechas';
import { eliminarActividad } from '../../../service/Queries/Agentes';
import SeccionDatosCenter from '../../common/SeccionDatosCenter';
import DatoCard from '../CapturaAgentes/DatoCard';
import { InputTextarea } from 'primereact/inputtextarea';
import { formatearMinutosAHoras, formatearMinutosAHorasNEW, getCalendarDate, getNombrePorID } from '../../../util/functions';
import { InputText } from 'primereact/inputtext';
import { getNombre } from '../../../service/Ciclos';
import { InputSwitch } from 'primereact/inputswitch';
import { Calendar } from 'primereact/calendar';

const estadoInicial = {
    empresa: undefined,
    categoria: undefined,
    subcategoria: undefined,
    actividad: undefined
}
export default function ModalTareaRealizada(props) {
    const [empresas, setEmpresas] = useGlobal("empresas");
    const [cicloId, setCicloId] = useGlobal("ciclo");
    const [actividades, setActividades] = useGlobal("actividadesMantenimiento");
    const [opcionesActividades, setOpcionesActividades] = useState([])
    const [categorias, setCategorias] = useGlobal("categorias");
    const [cargando, setCargando] = useGlobal("cargando");
    const [agente, setAgente] = useGlobal("agente");
    const [sitiosHTF] = useGlobal("sitiosHTF");
    const [tareaSeleccionada, setTareaSeleccionada] = useGlobal("tareaSeleccionada");
    const [state, setState] = useState({ ...estadoInicial })
    const [tareaId, setTareaId] = useState(null);
    const [isEmergencia, setIsEmergencia] = useState(false);
    const [isReq, setIsReq] = useState(false);

    useEffect(() => {
        if (!props.show) {
            setIsEmergencia(false)
            setIsReq(false)
            setState({ ...estadoInicial })
        }
    }, [props.show])
    useEffect(() => {
        if (tareaSeleccionada) adaptarAConsulta();
    }, [tareaSeleccionada])

    const adaptarAConsulta = () => {
        setIsEmergencia(!!tareaSeleccionada.is_emergencia)
        setIsReq(!!tareaSeleccionada.is_requisicion)
        setTareaId(tareaSeleccionada.id);

        let empresa = empresas.find(e => e.id === tareaSeleccionada.empresa_ref);
        let actividad = actividades.find(a => a.id === tareaSeleccionada.actividad_ref);
        let categoria = categorias.find(c => c.id === actividad.categoria_ref);

        let subcategoria = categoria.subcategorias.find(s => s.id === actividad.subcategoria_ref);
        setState({
            empresa: empresa.id,
            categoria: categoria.id,
            subcategoria: subcategoria.id,
            actividad: actividad.id,
            tiempo_tomado: tareaSeleccionada.tiempo_tomado,
            comentario: tareaSeleccionada.comentario,
            is_emergencia: tareaSeleccionada.is_emergencia,
            is_requisicion: tareaSeleccionada.is_requisicion,
            nombreTarea: tareaSeleccionada.nombreTarea,
            sitioHTF: tareaSeleccionada.sitioHTF,
            indice: tareaSeleccionada?.indice || actividad?.indice || "1.0",
            tareaParcial: tareaSeleccionada.tareaParcial,
            tiempos_tomados: tareaSeleccionada.tiempos_tomados
        })
        setTareaSeleccionada(null);
    }

    useEffect(() => {
        //filtrar opciones de actividades a aquellas permitidas por subcategoria
        if (state.subcategoria) {
            let opciones = actividades.filter(a => a.subcategoria_ref === state.subcategoria)
            setOpcionesActividades(opciones.map(el => ({ ...el, nombre: `${el.indice} - ${el.nombre}` })))
        }
    }, [state.subcategoria])

    const handleCancelar = (params) => {
        props.hide()
    }
    const handleChange = (e) => {
        let copia = { ...state }
        copia[e.target.name] = e.target.value
        setState(copia)
    }

    const footer = <div>
        <Button type="button" className="btn btn-secondary" onClick={handleCancelar} label="Cerrar" />
    </div>
    let nombreDia = getNombreFechaCompacto(props.dia.unixTime)
    const getTiempoValor = (tiempo) => {
        if (!tiempo || tiempo === 1) return getCalendarDate(getInicioSemanaDia(Date.now()) * 1000)
        else return getCalendarDate(tiempo)
    }
    const getListaTiemposParciales = () => state.tiempos_tomados.map(el => formatearMinutosAHorasNEW(el.tiempo_tomado || "0:0")).join(",  ")

    return (
        <Dialog className="modal-35" header={`${isEmergencia ? "Emergencia" : isReq ? "Tarea de requisición" : "Actividad"} concluida el: ${nombreDia}`} footer={footer} visible={props.show} onHide={props.hide} >
            {!isEmergencia && !isReq &&
                <ContenedorInput label="Empresa">
                    <Dropdown value={state.empresa} options={empresas}
                        onChange={handleChange} name="empresa"
                        optionLabel="nombre" optionValue="id" disabled
                    />
                </ContenedorInput>}

            {getNombrePorID(empresas, state.empresa) === "HTF" &&
                <ContenedorInput label="Locación">
                    <Dropdown name="sitioHTF" value={state.sitioHTF} options={sitiosHTF} disabled optionLabel="nombre" optionValue="id" />
                </ContenedorInput>}

            <ContenedorInput label="Categoria">
                <Dropdown value={state.categoria} options={categorias}
                    onChange={handleChange} name="categoria"
                    optionLabel="nombre" optionValue="id" disabled
                />
            </ContenedorInput>
            {!isEmergencia && !isReq && state.categoria ?
                <ContenedorInput label="Subcategoria">
                    <Dropdown value={state.subcategoria} options={state.categoria ? categorias.find(c => c.id == state.categoria).subcategorias : []}
                        onChange={handleChange} name="subcategoria"
                        optionLabel="nombre" optionValue="id" disabled
                    />
                </ContenedorInput> :
                null}
            {!isEmergencia && !isReq &&
                <ContenedorInput label="Tarea">
                    <InputText value={`${state.indice} - ${getNombrePorID(actividades, state.actividad)}`} disabled />
                </ContenedorInput>}

            {(isEmergencia || isReq) &&
                <ContenedorInput label="Tarea">
                    <InputTextarea value={state.nombreTarea} autoResize disabled />
                </ContenedorInput>}

            {state.tareaParcial &&
                <ContenedorInput col="12" label="Tarea parcial?">
                    <div className="row">
                        <div className="switch-container">
                            <InputSwitch disabled onLabel="Si" offLabel="No" checked={state.tareaParcial} />
                            <span>{state.tareaParcial ? "Si" : "No"}</span>
                        </div>
                        {true &&
                            <ContenedorInput label="Tiempos tomados">
                                <InputText value={getListaTiemposParciales()} disabled />
                            </ContenedorInput>}
                    </div>
                </ContenedorInput>}

            <ContenedorInput label="Comentario">
                <InputTextarea value={state.comentario} autoResize disabled />
            </ContenedorInput>

            <SeccionDatosCenter>
                <DatoCard label="Tiempo ocupado" valor={formatearMinutosAHorasNEW(state.tiempo_tomado || 0)} />
            </SeccionDatosCenter>
        </Dialog>
    )
}//157