import React, { useState, useEffect, useGlobal } from 'reactn';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { colores, ERROR_MESSAGE_BONO_OTROS, ERROR_MESSAGE_CAMPOS, FORCE, numMaxRangoCajas } from "../../constants";
import { noHaSidoClausurado } from "../../service/Ciclos";
import { obtenerClientes } from "../../service/Clientes";
import { usePrimeReactTable } from "../common/tableHandler";
import { registrarInvernaderoVirtual, guardarInvernaderoVirtual, togglearInvernadero, registrarCambioInvernaderoVirtual, obtenerInvernaderosDisponibles, getInvernaderosVirtuales, ordenarInvernaderosVirtualesPorIndexFisicoYNombre, } from "../../service/Invernaderos";
import { MultiSelect } from 'primereact/multiselect';
import { DeepClone, dosDecimales, getNombrePorID, getObjetoPorID } from '../../util/functions';
import ContenedorPrincipal from "../common/ContenedorPrincipal";
import ContenedorHeaderIconoInvernadero from "../common/ContenedorHeaderIconoInvernadero";
import SeccionFormularioCard from '../common/SeccionFormularioCard';
import SeccionBotones from '../common/SeccionBotones';
import ContenedorInput from '../common/ContenedorInput';
import { Message } from 'primereact/message';
import ContenedorHeader from '../common/ContenedorHeader';
import ContenedorTabla from '../common/ContenedorTabla';
import TablaRangosCajasNomina from './TablaRangosCajasNomina';

let inicial = {
  ciclo: null,
  cliente: null,
  nombre: "",
  invernadero_fisico: null,
  color: "",
  producto: "",
  variedad: "",
  interplanting: false,
  cantEmpleados: null,
  pesoCaja: null,
  habilitado: true,
  costosDeMano: 0,
  costosFertilizantes: 0,
  detalles_fisicos: [
    { detalle: "Area (m²)", norte: "", sur: "", totales: "0 m²", unidades: "m²" },
    { detalle: "Capillas", norte: "", sur: "", totales: "0 capillas", unidades: " capillas" },
    { detalle: "Líneas por capilla", norte: "", sur: "", totales: "0 líneas/capilla", unidades: "lineas/capilla" },
    { detalle: "Metros por línea", norte: "", sur: "", totales: "0 metros/línea", unidades: "metros/linea" },
    { detalle: "Claros totales", norte: "", sur: "", totales: "0 claros/línea", unidades: "claros/linea" },

  ],
  asignacion_lineas: [
    { lado: "Norte", inicio: "", fin: "", totales: "0 Líneas", unidades: "Líneas" },
    { lado: "Sur", inicio: "", fin: "", totales: "0 Líneas", unidades: "Líneas" },
  ]
};
const rangosCajasCosechaInicial = [{ min: 0, max: numMaxRangoCajas, bono: 0 }];
const FormularioInvernadero = (props) => {
  const invernaderoId = props.match.params.invernaderoID
  const editando = props.match.params.invernaderoID ? true : false;

  const [, setMostrarModalPermisos] = useGlobal("mostrarModalPermiso")
  const [, setModal] = useGlobal("modal");
  const [, setCargando] = useGlobal("cargando");
  const [ciclo] = useGlobal("ciclo");
  const [ciclos] = useGlobal("ciclos");
  const [usuario,] = useGlobal("usuario");
  const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
  const [, setNombreModulo] = useGlobal("nombreModulo");
  const [invernaderosFisicos] = useGlobal("invernaderosFisicos");
  const [invernaderosVirtuales, setInvernaderos] = useGlobal("invernaderosVirtuales");

  const [invernadero, setInvernadero] = useState(null);
  const [editing, setEditing] = useState(editando);
  const [clientes, setClientes] = useState([]);
  const [variedades, setVariedades] = useState([]);
  const [invernaderosDisponibles, setInvernaderosDisponibles] = useState([]);
  const [invernaderosPorTransferir, setInvernaderosPorTransferir] = useState([]);
  const [porcentajesMezclaVariedades, setPorcentajesMezclaVariedades] = useState([]);
  const [state, setState] = useState(inicial)
  const [editorGeneratorAsignacion, currentAsignacion] = usePrimeReactTable(inicial.asignacion_lineas, extraProcessingAsignacion)
  const [editorGeneratorDetalles, currentDetalles] = usePrimeReactTable(inicial.detalles_fisicos, extraProcessingDetalles)
  const [productos] = useGlobal("productosYVariedades");
  const [productosTodos] = useGlobal("productosYVariedadesTodos");
  const [allVariedades] = useGlobal("allVariedades")
  const [rangosCajasCosecha, setRangosCajasCosecha] = useState(DeepClone(rangosCajasCosechaInicial));
  const [precioBonoCosechaRolOtros, setPrecioBonoCosechaRolOtros] = useState("");

  const [medleysGeneral] = useGlobal("medleysGeneral");
  const [errores, setErrores] = useState({});

  useEffect(() => {
    if (editing) {
      setHabilitadoSelectorCiclo(false);
    } else setHabilitadoSelectorCiclo(true);

    setNombreModulo("Invernaderos virtuales");
  }, [])

  useEffect(() => {
    //if(invernadero)console.log(JSON.stringify(invernadero))
  }, [invernadero])
  useEffect(() => {
    setErrores({})
  }, [JSON.stringify(state), porcentajesMezclaVariedades, rangosCajasCosecha, precioBonoCosechaRolOtros])
  useEffect(() => {
    if (ciclo) setState({ ...state, ciclo });
  }, [ciclo])

  useEffect(() => {
    if (invernadero) {
      let adaptadoState = adaptar(invernadero, ciclo);
      setState(adaptadoState);
      setPorcentajesMezclaVariedades(adaptadoState.porcentajesMezclaVariedades);
      setRangosCajasCosecha(adaptadoState.rangosCajasCosecha);
      setPrecioBonoCosechaRolOtros(adaptadoState.precioBonoCosechaRolOtros)
    }

    obtenerClientes().then(resp => { setClientes(resp); })
    if (invernadero && productos) {
      let vars = productos.find(el => el.id === invernadero.producto_ref)?.variedades
      setVariedades(vars);
    }
  }, [invernadero, productos])

  useEffect(() => {
    if (ciclo) {
      obtenerInvernaderosDisponibles(ciclo).then(resp => {
        setInvernaderosDisponibles(resp);
      })
    }
  }, [ciclo])
  useEffect(() => {
    if (editando && invernaderosVirtuales) obtenerDatosIniciales()
  }, [editando, invernaderosVirtuales])

  useEffect(() => {
    if (ciclo && productos?.length > 0 && !editing) {
      const productoSleccionado = getObjetoPorID(productos, state.producto);

      if (productoSleccionado.mezcla) {
        let porcentajesMezclaVariedades = productoSleccionado.variedades?.map(el => ({ porcentaje: 0, id: el.id })) || []
        setPorcentajesMezclaVariedades(porcentajesMezclaVariedades)
      } else { setPorcentajesMezclaVariedades([]); }
    }
    else if (!editing) setPorcentajesMezclaVariedades([]);
  }, [state.producto, ciclo, productos])

  const obtenerDatosIniciales = async () => {
    const invernaderoObj = getObjetoPorID(invernaderosVirtuales, invernaderoId)
    if (!invernaderoObj.color) props.history.replace("/administrar/invernaderos_virtuales")
    else setInvernadero(invernaderoObj)
    setCargando(false)
  }
  const adaptar = (invernadero, ciclo) => {
    const { detalles_fisicos, asignacion_lineas, nombre, color, interplanting, habilitado, costosDeMano, costosFertilizantes,
      cliente_ref: cliente, invernadero_fisico_ref: invernadero_fisico, producto_ref: producto, variedad_ref: variedad, peso_caja_cosecha: pesoCaja,
      numero_maximo_trabajadores: cantEmpleados, porcentajesMezclaVariedades = [], rangosCajasCosecha = DeepClone(rangosCajasCosechaInicial), precioBonoCosechaRolOtros = "" } = invernadero;

    return {
      detalles_fisicos, asignacion_lineas, nombre, color, interplanting, habilitado, cliente, invernadero_fisico, ciclo, producto, variedad, pesoCaja, cantEmpleados,
      costosDeMano, costosFertilizantes, porcentajesMezclaVariedades, rangosCajasCosecha, precioBonoCosechaRolOtros
    };
  }

  const puedeEditar = (campo) => {
    const cicloActivo = noHaSidoClausurado(ciclo, ciclos);
    const hasPermisos = ["Administrador"].includes(usuario?.rol);
    const canEditDatos = !editing || ["pesoCaja", "asignacion_lineas", "habilitado", "guardar"].includes(campo);
    return cicloActivo && hasPermisos && canEditDatos;
  }
  function extraProcessingAsignacion(updatedState, props, value) {
    let numberFin = parseInt(updatedState[props.rowIndex]["fin"]);
    let numberInicio = parseInt(updatedState[props.rowIndex]["inicio"]);
    if (isNaN(numberFin)) { numberFin = 0; }
    if (isNaN(numberInicio)) { numberInicio = 0; }

    let result = 1 + numberFin - numberInicio;
    updatedState[props.rowIndex]["totales"] = `${result} ${updatedState[props.rowIndex]["unidades"]}`;
  }
  function extraProcessingDetalles(updatedState, props, value) {
    let numberSur = parseInt(updatedState[props.rowIndex]["sur"]);
    let numberNorte = parseInt(updatedState[props.rowIndex]["norte"]);
    if (isNaN(numberSur)) { numberSur = 0; }
    if (isNaN(numberNorte)) { numberNorte = 0; }

    let result = numberSur + numberNorte;
    updatedState[props.rowIndex]["totales"] = `${result} ${updatedState[props.rowIndex]["unidades"]}`;
  }

  const generarMensajeTransferencia = (resp) => {
    let resumen = "";
    resp.forEach(curr => {
      resumen += `${curr.cantidad} empleados desde ${curr.nombre} \n`
    })
    return `Se transfirieron exitosamente:
      ${resumen}`
  }

  const guardar = () => {
    if (validarErrores()) {
      const mezcla = getObjetoPorID(productos, state.producto).mezcla;
      const rangosCajasCosechaProcesados = procesarValoresRangos(rangosCajasCosecha);

      if (!editing) {
        setCargando(true);
        registrarInvernaderoVirtual(state, invernaderosPorTransferir, porcentajesMezclaVariedades, mezcla, rangosCajasCosechaProcesados, precioBonoCosechaRolOtros)
          .then(async (resp) => {
            await reloadInvernaderos();
            if (resp?.length > 0) setModal({ titulo: "Transferencia exitosa", msj: generarMensajeTransferencia(resp) });
            props.history.push("/administrar/invernaderos_virtuales");
          }).catch(err => {
            console.log("ERROR AL GUARDAR: " + err.message);
            setInvernadero(null);
          }).finally(el => {
            setCargando(false);
          });
      }
      else {
        setCargando(true);
        guardarInvernaderoVirtual(invernadero.id, state, porcentajesMezclaVariedades, mezcla, rangosCajasCosechaProcesados, precioBonoCosechaRolOtros).then(async (res) => {
          registrarCambioInvernaderoVirtual(invernadero.id, state);
          await reloadInvernaderos();
          props.history.push("/administrar/invernaderos_virtuales");
        }).catch(err => {
          if (err.code && err.code === "permission-denied") {
            setMostrarModalPermisos(true);
          }
          console.log("ERROR AL EDITAR: " + err.message);
        }).finally(el => {
          setCargando(false);
        })
      }
    }
  }
  const validarErrores = () => {
    let errores = {};
    const requeridos = ["nombre", "cliente", "invernadero_fisico", "color", "cantEmpleados", "pesoCaja", "producto",]
    requeridos.forEach(field => {
      if (!state[field]) errores[field] = true
    })
    if (getObjetoPorID(productos, state.producto).unico && !state.variedad) { errores.variedad = true; }

    for (let x = 0; x < state.detalles_fisicos.length; x++) {
      if (!state.detalles_fisicos[x].norte || !state.detalles_fisicos[x].sur) errores.detalles_fisicos = true;
    }
    for (let x = 0; x < state.asignacion_lineas.length; x++) {
      if (!state.asignacion_lineas[x].inicio || !state.asignacion_lineas[x].fin) errores.asignacion_lineas = true;
    }
    if (!sumaPorcentajes()) errores.sumaPorcentajes = true;
    if (!precioBonoCosechaRolOtros) { errores.precioBonoCosechaRolOtros = ERROR_MESSAGE_BONO_OTROS; }

    if (rangosCajasCosecha?.length > 1){
      rangosCajasCosecha.forEach((rango, index) => {  
        const esUltimoRango = index === rangosCajasCosecha.length - 1;
        const hayNaNs = isNaN(rango.min) || isNaN(parseInt(rango.max)) || isNaN(parseFloat(rango.bono))
        const hayNullos = !rango.max  || !rango.bono || !parseInt(rango.max)  || !parseFloat(rango.bono)
       //console.log("RANGO: ",rango)

        if (index === 0 && (!rango.max || !parseInt(rango.max) || isNaN(rango.max))) errores.rangosCajasCosecha = `1 - ${ERROR_MESSAGE_CAMPOS}`;
        else if(!esUltimoRango && index > 0 && (hayNaNs || hayNullos)) errores.rangosCajasCosecha = `2 - ${ERROR_MESSAGE_CAMPOS}`;
        else if(esUltimoRango && (hayNaNs || hayNullos || rango.max !== numMaxRangoCajas)) errores.rangosCajasCosecha = `3 - ${ERROR_MESSAGE_CAMPOS}`;
      })
    }
    //console.log("\n")
    setErrores(errores)
    return Object.keys(errores).length === 0
  }
  const procesarValoresRangos = (rangos) => {
    return rangos.map((rango, index) => {
      const min = calcMin(index);
      const max = parseInt(rango.max)
      const bono = parseFloat(rango.bono);

      return { orden: index + 1, min, max, bono }
    })
  }

  const cancelar = () => {
    setInvernadero(null);
    props.history.goBack();
  }
  const sumaPorcentajes = () => {
    if (porcentajesMezclaVariedades.length > 0) {
      let sum = 0
      porcentajesMezclaVariedades.forEach((porc) => {
        sum += parseFloat(porc.porcentaje)
      })
      return sum == 100
    }
    else return true
  }

  const handleInputChange = (ev) => {
    const stateCopy = Object.assign({}, state);
    const { name, value } = ev.target;
    if (name === "producto") {
      const producto = productos.find(el => value === el.id);
      stateCopy.variedad = null;
      setVariedades(producto?.variedades);
    }

    stateCopy[name] = (name === "nombre") ? value.substring(0, 3) : value;
    setState(stateCopy);
  }
  const reloadInvernaderos = async () => {
    const invernaderosReloaded = await getInvernaderosVirtuales(ciclo, FORCE)
    ordenarInvernaderosVirtualesPorIndexFisicoYNombre(invernaderosReloaded, invernaderosFisicos)
    setInvernaderos(invernaderosReloaded)
  }
  const togglear = async () => {
    try {
      await togglearInvernadero(invernadero.id, state)
      await reloadInvernaderos();
      setModal({ titulo: "Éxito", msj: `Se ${state.habilitado ? "deshabilitó" : "habilitó"} invernadero extosamente` });
      props.history.push("/administrar/invernaderos_virtuales");
    }
    catch (error) {
      console.log("ERROR: ", error)
    }
  }
  const obtenerPackoutProducto = (params) => {
    if (productos?.length > 0) {
      let producto = productos.find(el => el.id == state.producto);
      return producto?.mezcla ? medleysGeneral?.packout_respaldo : dosDecimales(100 * producto?.packout_respaldo);
    }
  }

  const setPorcentajeSubs = (ev, index) => {
    let porcentajesCopy = DeepClone(porcentajesMezclaVariedades);
    porcentajesCopy[index].porcentaje = ev.target.value
    setPorcentajesMezclaVariedades(porcentajesCopy)
  }

  const obtenerOpcionesProducto = () => {
    if (invernadero?.MEDLEYS_VIEJO) { return productosTodos; }
    else { return productos; }
  }
  const obtenerOpcionesVariedades = () => {
    if (invernadero?.MEDLEYS_VIEJO) { return allVariedades; }
    else { return variedades; }
  }

  const calcMin = (index) => {
    if (index === 0) { return 0; }
    else return parseInt(rangosCajasCosecha[index - 1]?.max || 0) + 1;
  }

  return (
    <ContenedorPrincipal extra="p-justify-center">
      {editing && invernadero?.id
        ? <ContenedorHeaderIconoInvernadero atras={props.history.goBack} invernadero={invernadero} editing={editing} identificador="Invernadero" />
        : <ContenedorHeader titulo="Crear invernadero" iconos="inv-icon" atras={props.history.goBack} />}

      <SeccionFormularioCard titulo="Relaciones y vigencia">
        <ContenedorInput label="Ciclo">
          <InputText value={getNombrePorID(ciclos, state.ciclo)} disabled />
        </ContenedorInput>
        <ContenedorInput label="Cliente">
          <Dropdown options={clientes || []} placeholder="Seleccione cliente" optionLabel="nombre" optionValue="id"
            value={state.cliente || ""} onChange={handleInputChange} name="cliente" disabled={!puedeEditar("cliente")} />
          {errores.cliente && <Message severity="error" text="Este campo es requerido" />}
        </ContenedorInput>
      </SeccionFormularioCard>
      <SeccionFormularioCard titulo="Características">
        <ContenedorInput label="Nombre">
          <InputText value={state["nombre"]} name="nombre" onChange={handleInputChange} disabled={!puedeEditar("nombre")} />
          {errores.nombre && <Message severity="error" text="Este campo es requerido" />}
        </ContenedorInput>
        <ContenedorInput label="Invernadero físico relacionado">
          <Dropdown options={invernaderosFisicos || []} placeholder="Seleccione invernadero" optionLabel="nombre" optionValue="id"
            onChange={handleInputChange} value={state.invernadero_fisico || ""} name="invernadero_fisico" disabled={!puedeEditar("invernadero_fisico")} />
          {errores.invernadero_fisico && <Message severity="error" text="Este campo es requerido" />}
        </ContenedorInput>
        <ContenedorInput label="Color para interfaz">
          <Dropdown options={colores} placeholder="Seleccione color" disabled={usuario.isRolFinanzas}
            onChange={handleInputChange} value={state.color} name="color" filter={true} filterPlaceholder="Buscar color" />
          {errores.color && <Message severity="error" text="Este campo es requerido" />}
        </ContenedorInput>
        <ContenedorInput label="Cantidad de empleados">
          <InputText value={state["cantEmpleados"] || ""} name="cantEmpleados" onChange={handleInputChange} disabled={!puedeEditar("cantEmpleados")} />
          {errores.cantEmpleados && <Message severity="error" text="Este campo es requerido" />}
        </ContenedorInput>
        <ContenedorInput label="Peso de caja de cultivo (kg)">
          <InputText value={state["pesoCaja"]} name="pesoCaja" onChange={handleInputChange} disabled={!puedeEditar("pesoCaja")} />
          {errores.pesoCaja && <Message severity="error" text="Este campo es requerido" />}
        </ContenedorInput>
      </SeccionFormularioCard>

      {!editing &&
        <SeccionFormularioCard titulo="Asignación de empleados">
          <ContenedorInput label="Invernaderos disponibles">
            <MultiSelect options={invernaderosDisponibles} value={invernaderosPorTransferir} onChange={(e) => setInvernaderosPorTransferir(e.value)}
              optionLabel="nombre" dataKey="id" selectedItemsLabel={`${invernaderosPorTransferir.length} invernaderos seleccionados`} />
          </ContenedorInput>
        </SeccionFormularioCard>}

      <SeccionFormularioCard titulo="Producto a cultivar" >
        <ContenedorInput col="3" label="Producto">
          <Dropdown options={obtenerOpcionesProducto()} placeholder="Seleccione producto" optionLabel="nombre" optionValue="id"
            onChange={handleInputChange} value={state.producto} name="producto" disabled={!puedeEditar("producto")} />
          {errores.producto && <Message severity="error" text="Este campo es requerido" />}
        </ContenedorInput>
        {!getObjetoPorID(productos, state.producto).mezcla &&
          <ContenedorInput col="3" label="Variedad">
            <Dropdown name="variedad" options={obtenerOpcionesVariedades()} placeholder="Seleccione variedad" optionLabel="nombre" optionValue="id"
              onChange={handleInputChange} value={state.variedad} disabled={!puedeEditar("variedad")} />
            {errores.variedad && <Message severity="error" text="Este campo es requerido" />}
          </ContenedorInput>}
        <ContenedorInput col="3" sinFilrar label="Interplanting">
          <div className="switch-container">
            <InputSwitch onLabel="Si" offLabel="No" checked={state.interplanting} onChange={handleInputChange} name="interplanting" disabled={!puedeEditar("interplanting")} />
            <span>{state.interplanting ? "Si" : "No"}</span>
          </div>
        </ContenedorInput>

        {state.producto &&
          <ContenedorInput col="3" sinFilrar label="Packout real" >
            <div >{obtenerPackoutProducto()} %</div>
          </ContenedorInput>}

        {porcentajesMezclaVariedades?.length > 0 &&
          <div className="p-col-12" >
            <div className="p-grid p-fluid ">
              <div className="p-col-12">
                <label>Porcentajes de mezcla</label>
                {porcentajesMezclaVariedades?.map((vari, index) => {
                  //window.alert(JSON.stringify(porcentajesMezclaVariedades))
                  let nombreVariedad = allVariedades?.find(doc => doc.id === vari.id)?.nombre || "";
                  return (
                    <div key={`inv-porc-comp-${index}`} className="form-group p-grid p-fluid pallet-dailyweight">
                      <div className="p-col p-grid ">
                        <div className="p-col-auto p-justify-end p-grid p-align-center m-0">
                          <label>% </label>
                        </div>
                        <div className="p-col">
                          <InputText value={vari?.porcentaje || ""} name="porcentaje" type="number" step=".01"
                            onChange={(ev) => { setPorcentajeSubs(ev, index) }} disabled={!puedeEditar("pesoCaja")} />
                        </div>
                      </div>

                      <div className="p-col p-grid ">
                        <div className="p-col-auto">
                          <p className="pallet-id">{nombreVariedad}</p>
                        </div>
                      </div>
                    </div>)
                })}
                {errores.sumaPorcentajes && <Message severity="error" text="Las proporciones de los compuestos deben sumar 100%" />}
              </div>
            </div>
          </div>}
      </SeccionFormularioCard>

      <SeccionFormularioCard titulo="Costos">
        <ContenedorInput label="Costos de fertilizantes">
          <InputText disabled={editing} value={state["costosFertilizantes"]} name="costosFertilizantes" onChange={handleInputChange} />
        </ContenedorInput>
        <ContenedorInput label="Costos de mano de obra y amortización">
          <InputText disabled={editing} value={state["costosDeMano"]} name="costosDeMano" onChange={handleInputChange} />
        </ContenedorInput>
      </SeccionFormularioCard>

      {/* Tabla de rangos de cajas para nomina*/}
      <TablaRangosCajasNomina calcMin={calcMin} precioBonoCosechaRolOtros={precioBonoCosechaRolOtros} setPrecioBonoCosechaRolOtros={setPrecioBonoCosechaRolOtros}
        rangosCajasCosecha={rangosCajasCosecha} setRangosCajasCosecha={setRangosCajasCosecha} error={errores.rangosCajasCosecha || errores.precioBonoCosechaRolOtros} />



      <ContenedorTabla col="10" titulo="Detalles físicos" error={errores.detalles_fisicos}>
        <DataTable value={state.detalles_fisicos} className="">
          <Column field="detalle" header="Detalle" className="col__label" />
          <Column field="norte" header="Norte" editor={(props) => puedeEditar("detalles_fisicos") ? editorGeneratorDetalles(props, "norte") : <div>No editable</div>} />
          <Column field="sur" header="Sur" editor={(props) => puedeEditar("detalles_fisicos") ? editorGeneratorDetalles(props, "sur") : <div>No editable</div>} />
          <Column field="totales" header="Totales" className="col__total" />
        </DataTable>
      </ContenedorTabla>

      <ContenedorTabla col="10" titulo="Asignación de líneas" error={errores.asignacion_lineas}
        subtitulo="Indicar la primera y última línea del invernadero físico a las que corresponden este invernadero.">
        <DataTable value={state.asignacion_lineas} >
          <Column field="lado" header="Lado" className="col__label" />
          <Column field="inicio" header="Línea inicial" editor={(props) => puedeEditar("asignacion_lineas") ? editorGeneratorAsignacion(props, "inicio") : <div>No editable</div>} />
          <Column field="fin" header="Línea final" editor={(props) => puedeEditar("asignacion_lineas") ? editorGeneratorAsignacion(props, "final") : <div>No editable</div>} />
          <Column field="totales" header="Líneas totales" className="col__total" />
        </DataTable>
      </ContenedorTabla>

      <SeccionBotones>
        <Button label="Cancelar" className="p-button-secondary" onClick={cancelar} />
        {puedeEditar("habilitado") && editing && <Button label={state.habilitado ? "Deshabilitar" : "Habilitar"} onClick={togglear} />}
        {puedeEditar("guardar") && <Button label="Guardar" onClick={guardar} />}
      </SeccionBotones>
    </ContenedorPrincipal>)
}
export default FormularioInvernadero;//527