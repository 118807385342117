import React from 'react'
const ContenedorBotonesRow = (props) => {
    return (
        <div className="p-col p-col-auto">
            <div className="btn-row">
                {props.children}
            </div>
        </div>
    )
}
export default ContenedorBotonesRow