import React from 'react'

const ContenedorInputNoCard = (props) => {
    return <div className={`form-group p-col p-col-${props.col} p-md-${props.md} p-sm-${props.sm}`}>
        {props.label?
        <label >{props.label}</label>:null
        }
        {props.children}
    </div>
}
export default ContenedorInputNoCard;