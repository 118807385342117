import React from 'react';
import { useState, useEffect, useGlobal, setGlobal } from 'reactn';
import { Dialog } from "primereact/dialog";
import { Button } from 'primereact/button';
import * as firebase from "firebase/app";
import { InputText } from "primereact/inputtext";

const NuevoClienteModal = (props) => {
    const [, setCargando] = useGlobal("cargando")
    const [otroClienteDireccion, setOtroClienteDireccion] = useState("");
    const [otroClienteNombre, setOtroClienteNombre] = useState("");
    const [clientes, setClientes] = useGlobal("clientesVentaNacional")

    useEffect(() => {
        if (props.clienteModalOpen) {
            setOtroClienteDireccion("")
            setOtroClienteNombre("")
        }
    }, [props.clienteModalOpen])

    const agregarNuevoCliente = async () => {
        setCargando(true)
        props.onHide()

        let nuevoCliente = { nombre: otroClienteNombre, direccion: otroClienteDireccion }
        let doc = await firebase.firestore().collection("clientes_venta_nacional").add(nuevoCliente)
        setClientes([...clientes, { ...nuevoCliente, id: doc.id }])
        props.handleInputChange({ target: { name: "cliente", value: doc.id } })

        setCargando(false)
    }

    const closeModal = () => {
        props.handleInputChange({ target: { name: "cliente", value: "" } })
        props.onHide()
    }
    const footer = () => {
        return <div>
            <Button label="Cancelar" onClick={closeModal} />
            <Button label="Finalizar" disabled={otroClienteNombre === "" || otroClienteDireccion === ""} onClick={() => agregarNuevoCliente()} />
        </div>
    }

    return <Dialog header={"Otro cliente"} visible={props.clienteModalOpen} style={{ width: '50vw' }} onHide={props.onHide} footer={footer()}>
        <div className="p-grid ">
            <div className="form-group p-col-12">
                <label htmlFor="status-nuevo">Escribir el nombre del cliente </label>
                <InputText value={otroClienteNombre} onChange={(ev) => setOtroClienteNombre(ev.target.value)} />
            </div>
            <div className="form-group p-col-12">
                <label htmlFor="direccion-otro">Dirección del cliente </label>
                <InputText value={otroClienteDireccion} onChange={(ev) => setOtroClienteDireccion(ev.target.value)} />
            </div>
        </div>
    </Dialog >
}
export default NuevoClienteModal