import React, { useGlobal, useState, useEffect } from 'reactn';
import { Button } from 'primereact/button';
import TablaRegistros from "./TablaRegistros";
import { obtenerRegistrosCalidadPesosDia } from '../../../service/Calidad';
import { obtenerHoraRegistro, obtenerNombreDiaSemana, obtenerNumeroSemana } from '../../../service/fechas';
import { noHaSidoClausurado } from '../../../service/Ciclos';
import { getProductoYVariedadString, getObjetoPorID, tresDecimales } from '../../../util/functions';
import ContenedorHeader from '../../common/ContenedorHeader';
import icono from '../../../images/icons/external-icon.svg';

const TablaPesos = (props) => {
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [invernadero] = useGlobal("invernadero");
    const [registros, setRegistros] = useState([]);
    const [, setCargando] = useGlobal("cargando");
    const [ciclo,] = useGlobal("ciclo")
    const [ciclos,] = useGlobal("ciclos")
    const [diaConsultado,] = useGlobal("diaConsultado");
    const [presentaciones] = useGlobal("presentaciones");
    const [empacadoras] = useGlobal("empacadoras");
    const [proveedores] = useGlobal("proveedores");
    const [proveedor] = useGlobal("proveedorExternoCalidad");
    const [productosYVariedades] = useGlobal("productosYVariedades");
    const [allVariedades] = useGlobal("allVariedades");
    const [colores] = useGlobal("coloresVariedades");

    useEffect(() => {
        setHabilitadoSelectorCiclo(false)
    }, [])
    useEffect(() => {
        setCargando(true)
        if (!diaConsultado) { props.history.replace("/calidad/semanal"); }
        else if (invernadero) { obtenerDatosIniciales(); }
    }, [invernadero])

    const puedeEditar = () => noHaSidoClausurado(ciclo, ciclos) && invernadero && (!invernadero?.es_externo || proveedor?.habilitado) && (invernadero?.habilitado || invernadero?.es_externo)
    const obtenerDatosIniciales = async () => {
        let registros = await obtenerRegistrosCalidadPesosDia(ciclo, invernadero.id, diaConsultado);
        let registrosPesos = registros.map((registro) => {

            let data = { ...registro, id: registro.id, momento: obtenerHoraRegistro(registro.momento) };
            data.empacadora = registro.tipo_empaque === "manual" ? getObjetoPorID(empacadoras, registro.empacadora_ref).nombre : "Automática";

            let sumaPesos = registro.pesos.reduce((acc, curr) => acc + parseFloat(curr.peso), 0);
            data.pesoPromedioCajas = tresDecimales(sumaPesos / registro.pesos.length);
            let presentacionObj = getObjetoPorID(presentaciones, registro.presentacion_ref);
            data.presentacion = presentacionObj.presentacion;
            return data;
        })
        setRegistros(registrosPesos);
        setCargando(false);
    }

    const getTitulo = () => `Calidad de pesos diarios - ${obtenerNombreDiaSemana(diaConsultado)} - semana ${obtenerNumeroSemana(diaConsultado)}`;
    const columnasTablaPesos = () => {
        const columnas = [
            { nombre: "HORA DE REGISTRO", field: "momento" },
            { nombre: "EMPACADORA", field: "empacadora" },
            { nombre: "PRESENTACIÓN", field: "presentacion" },
            { nombre: "PESO PROMEDIO CAJA (kg)", field: "pesoPromedioCajas" }
        ];

        //Actualmente no registramos el proveedor en pesos diarios para invernaderos externos
        //if (invernadero.es_externo) { columnas.push({ nombre: "Proveedor", field: "proveedor" }); }

        if (invernadero.mezcla) {
            columnas.push({ nombre: "Producto de presentación", field: "producto_de_presentacion" });
            //Actualmente no registramos variedad de presentaciones únicos en pesos diarios para invernadero medleys
            //columnas.push({ nombre: "Variedad", field: "subVariedad_ref" });
        }
        return columnas;
    }
    return (
        <div className="p-grid p-justify-center">
            <ContenedorHeader col="p-col p-col-auto" titulo={getTitulo()} subtituloInv={getProductoYVariedadString(invernadero)}
                invernaderoNombre={invernadero?.es_externo ? <img width="60" alt="icono" src={icono} /> : invernadero?.nombre}
                iconos={`icon-general icon-id bor-${invernadero?.color} bg-${invernadero?.color} ${invernadero?.habilitado ? "" : "Deshabilitado"}`} atras={props.history.goBack} />

            <div className="p-col p-col-auto">
                <div className="btn-row">
                    {puedeEditar() && <Button label="Agregar nuevo registro" onClick={() => props.history.push("/calidad/registro_pesos/crear")} />}
                </div>
            </div>

            <div className="p-col-12">
                {invernadero &&
                    <TablaRegistros registros={registros} columnas={columnasTablaPesos()} rutaEdicion={`/calidad/registro_pesos/${invernadero.id}/modificar`}
                        presentaciones={presentaciones} proveedores={proveedores} productos={productosYVariedades} variedades={allVariedades} colores={colores} />}
            </div>
        </div>);
}
export default TablaPesos;