import React, { useState, useEffect, setGlobal, useGlobal } from 'reactn';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { obtenerActividades } from '../../../service/actividades';
import { Button } from 'primereact/button';
import ContenedorPrincipal from '../../common/ContenedorPrincipal';
import ContenedorHeader from '../../common/ContenedorHeader';
import RowBotonesTop from '../../common/RowBotonesTop';
import ContenedorBotonesTop from '../../common/ContenedorBotonesTop';
import ContenedorBotonesRow from '../../common/ContenedorBotonesRow';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import ContenedorTablaAdmin from '../../common/ContenedorTablaAdmin';
import SeccionFiltros from '../../common/SeccionFiltros';
import { getActividadesMantenimiento, getCategoriasActividadesMantenimiento } from '../../../service/Mantenimiento';
import ModalAdministrarActividad from './ModalAdministrarActividad';
import ContenedorTabla from '../../common/ContenedorTabla';
import BaseCard from '../../common/cards/BaseCard';

import ProduccionIcon from "../../../images/icons/mtto_produccion.svg"
import MaquinasIcon from "../../../images/icons/mtto_maquinas.svg"
import EmpaqueIcon from "../../../images/icons/mtto_empaque.svg"
import InocuidadIcon from "../../../images/icons/mtto_inocuidad.svg"
import OficinasIcon from "../../../images/icons/mtto_oficinas.svg"
import SeguridadIcon from "../../../images/icons/mtto_seguridad.svg"
import UrgenteIcon from "../../../images/icons/mtto_urgente.svg"
import { getActividadesOpcionesConIndices, getObjetoPorID } from '../../../util/functions';
import { TabMenu } from 'primereact/tabmenu';
import CategoriasMantenimientoTabla from './componentes/CategoriasMantenimientoTabla';
import SubcategoriasMantenimientoTabla from './componentes/SubcategoriasMantenimientoTabla';
import ActividadesMantenimientoTabla from './componentes/ActividadesMantenimientoTabla';
import ContenedorFiltroBusqueda from '../../common/ContenedorFiltroBusqueda';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { periodicidadMantOpciones, periodicidadMantOpcionesAdmin } from '../../../util/constants';
import { MultiSelect } from 'primereact/multiselect';
import ContenedorFiltro from '../../common/ContenedorFiltro';
import { getEmpresas } from '../../../service/Empresas';
import { obtenerEmpaques } from '../../../service/Empaques';

const AdministrarActividades = (props) => {
  const [, setCargando] = useGlobal("cargando");
  const [, setNombreModulo] = useGlobal("nombreModulo");
  const [categorias, setCategorias] = useGlobal("categoriasMantenimiento");
  const [subcategorias, setSubcategorias] = useGlobal("subcategoriasMantenimiento");
  const [, setActividades] = useGlobal("actividadesMantenimiento");
  const [, setActividad] = useGlobal("actividadMantenimientoSeleccionado");
  const [reload, setReload] = useGlobal("reloadMant");

  const [tablaLineasCategorias, setTablaLineasCategorias] = useGlobal("tablaLineasCategoriasMant");
  const [tablaLineasSubcategorias, setTablaLineasSubcategorias] = useGlobal("tablaLineasSubcategoriasMant");
  const [tablaLineasActividades, setTablaLineasActividades] = useGlobal("tablaLineasActividadesMant");
  const [tablaLineasSubcategoriasOrig] = useGlobal("tablaLineasSubcategoriasMantOrig");
  const [tablaLineasActividadesOrig] = useGlobal("tablaLineasActividadesMantOrig");

  const [globalFilter, setGlobalFilter] = useGlobal("globalFilterCompras")
  const [categoriasFiltro, setCategoriasFiltro] = useGlobal("categoriasFiltro")
  const [subcategoriasFiltro, setSubcategoriasFiltro] = useGlobal("subcategoriasFiltro")
  const [periodicidadFiltro, setPeriodicidadFiltro] = useState("Todos")


  const [showAdministrarModal, setShowAdministrarModal] = useState(0);
  const [tabs, setTabs] = useState([
    { label: "Categorías" },
    { label: "Subcategorías" },
    { label: "Actividades" }])
  const [activeTab, setActiveTab] = useState(tabs[0]);

  useEffect(() => {
    setNombreModulo('Actividades')
    getDatosIniciales()
  }, [])

  useEffect(() => {
    if (!showAdministrarModal) setActividad(false)
  }, [showAdministrarModal])
  useEffect(() => {
    if (reload) getDatosIniciales()
    setReload(false)
    setActividad(false)
  }, [reload])

  useEffect(() => {
    //Reconsile Filters
    const lineasFiltrados = tablaLineasSubcategoriasOrig?.filter(linea => checarCondicionesDeIncluirSubcat(linea)) || [];
    setTablaLineasSubcategorias(lineasFiltrados)
  }, [categoriasFiltro]);
  useEffect(() => {
    //Reconsile Filters
    const lineasFiltrados = tablaLineasActividadesOrig?.filter(linea => checarCondicionesDeIncluirActividad(linea)) || [];
    setTablaLineasActividades(lineasFiltrados)
  }, [categoriasFiltro, subcategoriasFiltro, periodicidadFiltro]);

  const checarCondicionesDeIncluirSubcat = (subcategoria) => {
    return categoriasFiltro.includes(subcategoria.categoria_ref)
  }
  const checarCondicionesDeIncluirActividad = (actividad) => {
    return categoriasFiltro.includes(actividad.categoria_ref)
      && subcategoriasFiltro.includes(actividad.subcategoria_ref)
      && (periodicidadFiltro === actividad.periodicidad
        || periodicidadFiltro === "Todos"
        || (periodicidadFiltro === "Ninguna" && !actividad.periodicidad))
  }

  const getDatosIniciales = async () => {
    setCargando(true)

    let [allCategoriasDatos, actividadesDatos, empresas, empaques] = await Promise.all([getCategoriasActividadesMantenimiento(), getActividadesMantenimiento(), getEmpresas(), obtenerEmpaques()])
    const empresasSinLort = empresas.filter((empresa) => empresa.nombre !== "LORT")
    
    const categoriasDatos = allCategoriasDatos.filter(el => el.tipo === "categoria" && !el.sinMostrar) || []
    const subcategoriasDatos = allCategoriasDatos.filter(el => el.tipo === "subcategoria" && !el.sinMostrar) || []
    actividadesDatos = actividadesDatos.filter(el => !el.sinMostrar)

    const categoriasLineas = categoriasDatos.map(el => {
      const numSubcategorias = subcategoriasDatos.filter(sub => sub.categoria_ref === el.id).length
      const numActividades = actividadesDatos.filter(act => act.categoria_ref === el.id).length
      return { ...el, numSubcategorias, numActividades, tipo: 1 }
    })
    const subcategoriasLineas = subcategoriasDatos.map(el => {
      const nombreCategoria = categoriasDatos.find(cat => cat.id === el.categoria_ref)?.nombre || "Sin categoria"
      const numActividades = actividadesDatos.filter(act => act.subcategoria_ref === el.id).length
      return { ...el, nombreCategoria, numActividades, tipo: 2 }
    });
    const actividadesLineas = actividadesDatos.map(el => {
      const nombreCategoria = categoriasDatos.find(cat => cat.id === el.categoria_ref)?.nombre || "Sin categoria"
      const nombreSubcategoria = subcategoriasDatos.find(sub => sub.id === el.subcategoria_ref)?.nombre || "Sin subcategoria"
      return { ...el, nombreCategoria, nombreSubcategoria, tipo: 3 }
    })

    setGlobal({
      tablaLineasSubcategoriasMantOrig: subcategoriasLineas, tablaLineasActividadesMantOrig: actividadesLineas,
      tablaLineasCategoriasMant: categoriasLineas, tablaLineasSubcategoriasMant: subcategoriasLineas, tablaLineasActividadesMant: actividadesLineas,
      categoriasMantenimiento: categoriasDatos, subcategoriasMantenimiento: subcategoriasDatos, actividadesMantenimiento: actividadesDatos,
      categoriasFiltro: categoriasDatos.map(el => el.id), subcategoriasFiltro: subcategoriasDatos.map(el => el.id), cargando: false,
      datosCargadosMantenimiento: false, //solucionar error al hacer atras activadades estan mal
      empaques
    })
  }

  const editarActividad = (rowData) => {
    if (rowData.data.id !== "category_emergency" && rowData.data.categoria_ref !== "category_emergency") {
      setActividad(rowData.data)
      setShowAdministrarModal(rowData.data.tipo)
    }
  }

  const getIcon = (iconSrc) => <img width={40} height={40} src={iconSrc}></img>
  const iconBody = (data) => {
    let classname = data.classname || getObjetoPorID(categorias, data.categoria_ref)?.classname || "NONE"
    switch (classname) {
      case "produccion": return getIcon(ProduccionIcon)
      case "maquinas": return getIcon(MaquinasIcon)
      case "empaque": return getIcon(EmpaqueIcon)
      case "inocuidad": return getIcon(InocuidadIcon)
      case "oficinas": return getIcon(OficinasIcon)
      case "seguridad": return getIcon(SeguridadIcon)
      case "urgente": return getIcon(UrgenteIcon)
      default: return <p>...</p>
    }
  }
  return (
    <ContenedorPrincipal>
      <ContenedorHeader titulo="Administrar actividades" iconos="activity-icon" col="p-col p-col-auto" atras={props.history.goBack} />

      <ContenedorBotonesTop>
        <RowBotonesTop>
          {activeTab.label === "Categorías" && <Button label="Nueva categoría" onClick={() => setShowAdministrarModal(1)} />}
          {activeTab.label === "Subcategorías" && <Button label="Nueva subcategoría" onClick={() => setShowAdministrarModal(2)} />}
          {activeTab.label === "Actividades" && <Button label="Nueva actividad" onClick={() => setShowAdministrarModal(3)} />}
        </RowBotonesTop>
      </ContenedorBotonesTop>

      <SeccionFiltros >
        <ContenedorFiltroBusqueda>
          <FontAwesomeIcon icon={faSearch} />
          <InputText type="search" value={globalFilter} onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar" />
        </ContenedorFiltroBusqueda>

        {["Subcategorías", "Actividades"].includes(activeTab.label) &&
          <ContenedorFiltro label="Categorías">
            <MultiSelect value={categoriasFiltro} options={getActividadesOpcionesConIndices(categorias)} onChange={(e) => setCategoriasFiltro(e.target.value)} placeholder="Categorías"
              optionLabel="nombre" optionValue="id" filter={true} selectedItemsLabel={`${categoriasFiltro.length} categorías seleccionadas`} />
          </ContenedorFiltro>}
        {["Actividades"].includes(activeTab.label) && <>
          <ContenedorFiltro label="Subcategorías">
            <MultiSelect value={subcategoriasFiltro} options={getActividadesOpcionesConIndices(subcategorias)} onChange={(e) => setSubcategoriasFiltro(e.target.value)} placeholder="Subcategorías"
              optionLabel="nombre" optionValue="id" filter={true} selectedItemsLabel={`${subcategoriasFiltro.length} subcategorías seleccionadas`} />
          </ContenedorFiltro>
          <ContenedorFiltro label="Periodicidad">
            <Dropdown value={periodicidadFiltro} options={periodicidadMantOpcionesAdmin} onChange={(e) => setPeriodicidadFiltro(e.target.value)} placeholder="Periodicidad" />
          </ContenedorFiltro>
        </>}
      </SeccionFiltros>

      <div className="p-col-12">
        <TabMenu model={tabs} activeItem={activeTab} onTabChange={(e) => { setActiveTab(e.value) }} />

        {activeTab.label === "Categorías" && <CategoriasMantenimientoTabla tablaLineasCategorias={tablaLineasCategorias} editarActividad={editarActividad} iconBody={iconBody} globalFilter={globalFilter} />}
        {activeTab.label === "Subcategorías" && <SubcategoriasMantenimientoTabla tablaLineasSubcategorias={tablaLineasSubcategorias} editarActividad={editarActividad} iconBody={iconBody} globalFilter={globalFilter} />}
        {activeTab.label === "Actividades" && <ActividadesMantenimientoTabla tablaLineasActividades={tablaLineasActividades} editarActividad={editarActividad} iconBody={iconBody} globalFilter={globalFilter} />}
      </div>

      <ModalAdministrarActividad show={showAdministrarModal} hide={() => setShowAdministrarModal(0)} />

    </ContenedorPrincipal>
  )
}
export default AdministrarActividades;