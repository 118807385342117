import React from 'react'
import { Card } from 'primereact/card';
import presentacionesCiclo from './../../../images/graficas/presentaciones-ciclo.png';
import presentacionesSemanas from './../../../images/graficas/presentaciones-semanas.png';

const HomePresentaciones = (props) => {
    return (
        <React.Fragment>
            <div className="titulo-seccion-graficas">
                <span className="icon-general presentacion-icon"></span>
                Presentaciones
            </div>

            <div className="p-col-12 p-md-3  grafica-card" onClick={() => props.ir("/empaque/graficas/distribucion_presentaciones_semana")}>
                <Card className={`htg-card bor-gray-superlight`}  >
                    <div className="card-title">Presentaciones por semana - Distribución</div>
                    <img src={presentacionesSemanas} />
                </Card>
            </div>
            <div className="p-col-12 p-md-3  grafica-card" onClick={() => props.ir("/empaque/graficas/distribucion_presentaciones_ciclo")}>
                <Card className={`htg-card bor-gray-superlight`}  >
                    <div className="card-title">Presentaciones durante el ciclo - Distribución</div>
                    <img src={presentacionesCiclo} />
                </Card>
            </div>
        </React.Fragment>)
}
export default HomePresentaciones;//38