//react/reactn
import React, { useEffect, useGlobal } from 'reactn'
//Funciones internas
import { getColoresVariedades } from '../../../../service/ProductosYVariedades';
//Componentes third party
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
//Constantes
import { FORCE } from '../../../../constants';

const TablaColores = (props) => {
    const [usuario,] = useGlobal("usuario");
    const [coloresVariedades, setColoresVariedades] = useGlobal("coloresVariedades")
    const [globalFilter] = useGlobal("globalFilterProductosYVariedades")
    const [, setColor] = useGlobal("colorVariedad")

    useEffect(() => {
        obtenerDatosIniciales()
    }, [])

    const obtenerDatosIniciales = async () => {
        const colores = await getColoresVariedades(FORCE);
        setColoresVariedades(colores);
    }
    const irColor = (event) => {
        if (puedeEditar()) {
            setColor(event.data);
            props.history.push({ pathname: "/admin/colores/crear", });
        }
    }
    const puedeEditar = () => ["Administrador"].includes(usuario.rol);
    return (props.visible ?
        <DataTable globalFilter={globalFilter} value={coloresVariedades} onRowClick={irColor} emptyMessage="No hay resultados">
            <Column field="nombre" header="Nombre" sortable={true} />
        </DataTable>
        : null)
}
export default TablaColores;