import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import ContenedorPrincipal from "components/common/ContenedorPrincipal";
import { DataTable } from "primereact/datatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import SeccionFiltros from "../planeacion_mantenimiento/CapturaAgentes/SeccionFiltros";
import ContenedorFiltro from "../common/ContenedorFiltro";
import { cincoDeciComas, cuatroDeciComas, DeepClone, dosDeciComas, formatoDinero, getObjetoPorID, getObjetoPorLlave, parseFloatHTG, selectedItemNombresMultiselect, separadoComas, sumarNumerosArrayPorCampo } from "../../util/functions";
import { MultiSelect } from "primereact/multiselect";
import ContenedorTabla from "../common/ContenedorTabla";
import { getInvoicesCiclo } from "../../service/helpers/Invoices.helpers";
import { obtenerClientesCiclo } from "../../service/Clientes";
import { UnidadBase } from "../common/templates_unidades/UnidadBase.atomo";
import { UnidadPrecioPorLibra } from "../common/templates_unidades/UnidadPrecioPorLibra";
import { UnidadCajas, UnidadCj } from "../common/templates_unidades/UnidadCajas.atomo";
import { Column } from "primereact/column";
import { obtenerManifiestosCiclo } from "../../service/QueriesManifiestos";
import ContenedorHeaderClickableDev from "../common/ContenedorHeaderClickableDev";
import { getAreaInvernaderoVirtual } from "service/Invernaderos";
import { obtenerProveedores } from "service/Queries/Proveedores";
import { UnidadLibras } from "components/common/templates_unidades/UnidadLibras";
import { UnidadM2 } from "components/common/templates_unidades/UnidadM2";
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { miliSegundosSemana } from "../../constants";
import { Button } from "primereact/button";

const ReporteRechazos = (props) => {
  const [, setNombreModulo] = useGlobal("nombreModulo");
  const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
  const [ciclo] = useGlobal("ciclo");
  const [invernaderos] = useGlobal("invernaderosVirtuales");
  const [productosBases] = useGlobal("productosBases");
  const [, setCargando] = useGlobal("cargando");
  const [clientesOrig] = useGlobal("clientes");
  const [manifiestosTodos, setManifiestosTodos] = useState([]);
  const [invoicesTodos, setInvoicesTodos] = useState([]);
  const [proveedores, setProveedores] = useState([]);

  const [filtroInvernaderos, setFiltroInvernaderos] = useState();
  const [filtroProductos, setFiltroProductos] = useState();
  const [filtroClientes, setFiltroClientes] = useState();

  const [productosPresentes, setProductosPresentes] = useState();
  const [invernaderosPresentes, setInvernaderosPresentes] = useState();

  const [productosOpciones, setProductosOpciones] = useState();
  const [invernaderosOpciones, setInvernaderosOpciones] = useState();

  const [clientes, setClientes] = useState([]);
  const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector();

  const [rowsClientesCiclo, setRowsClientesCiclo] = useState([]);
  const [, setRowsSemanas] = useState([]);
  const [rowsClientesCicloFiltrados, setRowsClientesCicloFiltrados] = useState([]);
  const [rowsSemanasFiltrados, setRowsSemanasFiltrados] = useState([]);

  const [globalFilter, setGlobalFilter] = useState();
  const [indexInicio, setIndexInicio] = useState();
  const [areaInvernaderosTotal, setAreaInvernaderosTotal] = useState(0);

  useEffect(() => {
    setNombreModulo("Finanzas");
    setHabilitadoSelectorCiclo(true);
  }, [])
  useEffect(() => {
    if (ciclo && weeks?.length > 0) { obtenerDatosIniciales(); }
  }, [ciclo, weeks])

  useEffect(() => {//tabla ciclo
    if (filtroInvernaderos && filtroProductos && proveedores?.length) {
      const rowsCiclo = procesarInvoicesClientesCiclo(clientes, invoicesTodos, manifiestosTodos, proveedores, false, true, false);
      const rowsFiltrados = rowsCiclo?.filter(invoice => checarCondicionesDeIncluirInvoice(invoice)) || [];
      let invernaderosResp = invernaderos.filter(el => rowsFiltrados.some(row => row.invernaderosPresentes?.includes(el.id)));
      setInvernaderosOpciones(invernaderosResp);
      setFiltroInvernaderos(invernaderosResp)
    }
  }, [filtroProductos, filtroClientes])
  useEffect(() => {//tabla ciclo
    if (filtroInvernaderos && filtroProductos && proveedores?.length) {
      const rowsCiclo = procesarInvoicesClientesCiclo(clientes, invoicesTodos, manifiestosTodos, proveedores, false, true);
      const rowsFiltrados = rowsCiclo?.filter(invoice => checarCondicionesDeIncluirInvoice(invoice)) || [];
      setRowsClientesCicloFiltrados(rowsFiltrados);

      const rowsSemanas = procesarInvoicesSemanas(clientes, invoicesTodos, manifiestosTodos, proveedores, true);
      setRowsSemanasFiltrados(rowsSemanas);
    }
  }, [filtroInvernaderos])

  useEffect(() => {//tabla semanas
    if (filtroInvernaderos && filtroProductos && proveedores?.length) {

    }
  }, [filtroInvernaderos])

  const checarCondicionesDeIncluirInvoice = (invoice) => {
    return filtroClientes?.some(el => el.id === invoice.cliente?.id);
  }

  const obtenerDatosIniciales = async () => {
    setCargando(true);
    const [clientesCicloResp, invoicesResp, manifiestosResp, proveedoresResp] =
      await Promise.all([obtenerClientesCiclo(ciclo), getInvoicesCiclo(ciclo), obtenerManifiestosCiclo(ciclo), obtenerProveedores(ciclo)]);
    //console.log("INVVVV: ",{invoices: invoicesResp, length: invoicesResp.length});
    const clientesOpciones = clientesCicloResp.map(el => getObjetoPorID(clientesOrig, el.cliente_ref));
    const rowsCiclo = procesarInvoicesClientesCiclo(clientesOpciones, invoicesResp, manifiestosResp, proveedoresResp);
    const rowsSemanas = procesarInvoicesSemanas(clientesOpciones, invoicesResp, manifiestosResp, proveedoresResp);
    console.log("ROWS: ", rowsCiclo)

    let productosPresentes = productosBases.filter(el => rowsCiclo.some(row => row.productosPresentes?.includes(el.id)));
    let invernaderosPresentes = invernaderos.filter(el => rowsCiclo.some(row => row.invernaderosPresentes?.includes(el.id)));
    setProductosPresentes(productosPresentes);
    setProductosOpciones(productosPresentes);

    setClientes(clientesOpciones);
    setFiltroClientes(clientesOpciones);

    setInvoicesTodos(invoicesResp)
    setManifiestosTodos(manifiestosResp);
    setProveedores(proveedoresResp);

    setRowsClientesCiclo(rowsCiclo);
    setRowsSemanas(rowsSemanas);
    setRowsClientesCicloFiltrados(rowsCiclo);
    setFiltroInvernaderos(invernaderosPresentes);
    setFiltroProductos(productosPresentes);
    setCargando(false);
  }

  const procesarInvoicesClientesCiclo = (clientesOpciones = [], invoices = [], manifiestos = [], proveedores = [], getTotal = false, filtrarDatos = false, soloProd = true) => {
    const itemsDeManifiestos = manifiestos?.map(manifiesto => manifiesto?.items || []) || [];
    let itemsTodos = itemsDeManifiestos.flat();
    let areaTotal = 0;

    const rowsClientes = clientesOpciones.map(cliente => {
      let invoicesDelCliente = getTotal
        ? invoices.filter(invoice => filtroClientes?.some(el => el.id === invoice.cliente_ref))
        : invoices.filter(invoice => invoice.cliente_ref === cliente.id);

      let itemsDeInvoicesDelCliente = invoicesDelCliente.map(invoice => invoice.items.map(invoice => getObjetoPorLlave(itemsTodos, "itemID", invoice.itemId)));
      itemsDeInvoicesDelCliente = itemsDeInvoicesDelCliente.flat();
      if (filtrarDatos) {
        itemsDeInvoicesDelCliente = itemsDeInvoicesDelCliente.filter(item => {

          if (!soloProd) return filtroProductos?.some(prod => prod.id === item.producto_ref)
          else return filtroProductos?.some(prod => prod.id === item.producto_ref)
            && filtroInvernaderos?.some(inv => inv.id === item.invernadero_ref)
        })

        invoicesDelCliente = invoicesDelCliente.filter(invoice => invoice.items?.some(el => itemsDeInvoicesDelCliente?.some(item => item.itemID === el.itemId)))
      }

      const datosFinanzasTodos = invoicesDelCliente?.map(invoice => ({ ...invoice, ...invoice.infoRechazos })) || [];
      const sumaIngresosRealesTotales = sumarNumerosArrayPorCampo(datosFinanzasTodos, "ingresoRealInvoice");
      const sumaLibrasTotales = sumarNumerosArrayPorCampo(datosFinanzasTodos, "librasTotales");
      const sumaIngresosTotales = sumarNumerosArrayPorCampo(datosFinanzasTodos, "totalDeIngreso");
      const sumaDineroTotalRechazado = sumarNumerosArrayPorCampo(datosFinanzasTodos, "dineroTotalRechazado");
      const sumaCostoDeMateriales = sumarNumerosArrayPorCampo(datosFinanzasTodos, "costoDeMateriales");


      const cajasRechazadas = sumarNumerosArrayPorCampo(datosFinanzasTodos, "numeroCajasRechazadas");
      const costoRechazadas = sumarNumerosArrayPorCampo(datosFinanzasTodos, "dineroTotalRechazado");
      const librasTotales = sumarNumerosArrayPorCampo(datosFinanzasTodos, "librasTotales");

      let invernaderosUtilizadosPorEsteClienteIds = itemsDeInvoicesDelCliente.map(item => item.invernadero_ref);
      invernaderosUtilizadosPorEsteClienteIds = [...new Set(invernaderosUtilizadosPorEsteClienteIds)];//No repetir invernaderos en el arreglo
      const invernaderosDelCliente = invernaderosUtilizadosPorEsteClienteIds?.map(invId => {
        const datos = getObjetoPorID(invernaderos, invId);
        datos.areaInvernadero = getAreaInvernaderoVirtual(ciclo, datos, proveedores);
        return datos;
      });

      const invernaderosDelClienteNombres = invernaderosDelCliente.map(el => el.nombre).join(", ");
      const areaInvernaderos = sumarNumerosArrayPorCampo(invernaderosDelCliente, "areaInvernadero");
      areaTotal += areaInvernaderos;

      const ingresosSinMateriales = sumaIngresosTotales - sumaCostoDeMateriales;
      const ingresosSinMaterialesM2 = areaInvernaderos ? sumaIngresosTotales / areaInvernaderos : 0;
      const costoRechazadasM2 = areaInvernaderos ? costoRechazadas / areaInvernaderos : 0
      const preciorRechazoPromedioPorLibra = librasTotales ? dosDeciComas(costoRechazadas / librasTotales) : 0
      const preciorRechazoPromedioPorLibraSinMateriales = (costoRechazadas && librasTotales) ? ((ingresosSinMateriales - costoRechazadas) / librasTotales) : 0;

      let productosPresentes = itemsDeInvoicesDelCliente.map(el => el.producto_ref);
      productosPresentes = [...new Set(productosPresentes)];
      let invernaderosPresentes = itemsDeInvoicesDelCliente.map(el => el.invernadero_ref);
      invernaderosPresentes = [...new Set(invernaderosPresentes)];

      return {
        ingresosSinMateriales, ingresosSinMaterialesM2, cliente, invernaderosDelClienteNombres, areaInvernaderos, cajasRechazadas,
        costoRechazadas, costoRechazadasM2, librasTotales, preciorRechazoPromedioPorLibra, preciorRechazoPromedioPorLibraSinMateriales,
        productosPresentes, invernaderosPresentes,
      }
    })

    return rowsClientes;
  }
  const procesarInvoicesSemanas = (clientesOpciones = [], invoices = [], manifiestos = [], proveedores = [], filtrarDatos = false) => {
    const rowsSemanas = weeks?.map(wk => getDatosInvoicesSemana(wk, clientesOpciones, invoices, manifiestos, proveedores, filtrarDatos)) || [];
    let indexInicioNum = (indexInicio == null || indexInicio < 0) ? rowsSemanas.findIndex(row => parseFloatHTG(row.librasTotales) > 0) : indexInicio

    if (indexInicio == null || indexInicio < 0) { setIndexInicio(indexInicioNum); }
    let indexFin = weeks.length;
    const rowsFiltrados = rowsSemanas.slice(indexInicioNum, indexFin);

    //console.log("!!!! procesarInvoicesSemanas: 2 - ", { weeks, rowsFiltrados, rowsSemanas });
    return rowsFiltrados;
  }
  const getDatosInvoicesSemana = (semanaObj, clientesOpciones = [], invoices = [], manifiestos = [], proveedores = [], soloProd = true) => {
    const semanaInicio = semanaObj.time * 1000;
    const semanaFin = semanaInicio + miliSegundosSemana;
    const invoicesSemana = invoices.filter(invoice => semanaInicio < invoice.fechaCreacion && semanaFin > invoice.fechaCreacion);
    const manifiestosSemana = manifiestos.filter(manifiesto => {
      let invoiceItems = invoicesSemana?.map(invoice => invoice.items) || [];
      invoiceItems = invoiceItems.flat();

      const manItems = manifiesto.items;
      const hayManifiestosDatos = manItems?.some(manItem => invoiceItems.some(invItem => invItem.itemId === manItem.itemID));

      //if (!manItems) console.log("manifiesto undef 1: ", { invoicesSemana, manifiesto, hayManifiestosDatos });
      return !!hayManifiestosDatos;
    });
    //console.log("getDatosInvoicesSemana 2  : ", {invoicesSemana, manifiestosSemana})
    const datosRow = procesarInvoicesClientesCiclo(filtroClientes, invoicesSemana, manifiestosSemana, proveedores, false, true, soloProd);
    const datosRowTotal = procesarInvoicesClientesCiclo(clientesOpciones, invoicesSemana, manifiestosSemana, proveedores, true, true, soloProd);

    const datosClientes = {};
    datosRow.forEach((datos, index) => {
      const clienteId = clientesOpciones[index]?.id || "";
      datosClientes[clienteId] = datos;
    })
    datosClientes.totales = datosRowTotal[0];
    datosClientes.week = semanaObj;

    //console.log("datosClientes: ",{datosClientes ,datosRow, datosRowTotal} )
    return { ...datosRowTotal[0], ...datosClientes };
  }

  const bodyLibras = (rowData, column) => <UnidadLibras valor={dosDeciComas(rowData[column.field])} />;
  const bodyIngresos = (rowData, column) => <UnidadBase valor={`$${dosDeciComas(rowData[column.field])}`} />;
  const bodyRechazosM2 = (rowData, column) => <UnidadBase valor={`$${cincoDeciComas(rowData[column.field])}`} unidad="/m2" />;
  const bodyIngresosM2 = (rowData, column) => <UnidadBase valor={`$${dosDeciComas(rowData[column.field])}`} unidad="/m2" />;
  const bodyRechazosPorLibra = (rowData, column) => <UnidadBase valor={`$${dosDeciComas(rowData[column.field])}`} unidad="/lb" />;

  const getTextWithTooltipIngresosM2 = (rowData, column) => {
    let tooltip = `<p class='tooltip-def'><span className='unidad-kg-bold'> ${dosDeciComas(rowData.areaInvernaderos)}<span className={"label-unidad"}> m²</span></span></p>`;
    return <div> <Button className="btn-line btn-help ml-1" type="button" label="?" tooltip={tooltip} />
      {column.field === "costoRechazadasM2" ? bodyRechazosM2(rowData, column) : bodyIngresosM2(rowData, column)}
    </div>
  }

  const getHeaderWithTooltip = (text, tool) => {
    let tooltip = `<p class='tooltip-def'>${tool}</p>`;
    return <div>{text} <Button className="btn-line btn-help2 ml-1" type="button" tooltip={tooltip} /></div>
  }

  const textos = {
    1: "Suma de ingresos de los invoices, descontando el precio de los materiales de presentación necesarios para su producción",
    2: "Ingresos totales dividido por el área de los invernaderos relacionados",
    3: "Suma de rechazos de mano de obra y materiales en invoices",
    4: "Rechazos totales dividido por el área de los invernaderos relacionado",
    5: "Suma total de producto en libras de los invoices",
    6: "Promedio calculado por rechazos y libras totales de los invoices",
    7: "Promedio de precio de la libra teniendo en cuenta los valores totales de ingresos, rechazos y libras de los invoices",
  }

  const footerSumaTotales = (array, propiedad, body) => {
    const total = sumarNumerosArrayPorCampo(array, propiedad);
    return body({ [propiedad]: total }, { field: propiedad })
  }
  const footerPromedioIngresosPorM2 = (array, body) => {
    const totalIngresos = sumarNumerosArrayPorCampo(array, "ingresosSinMateriales");
    const areaTotal = sumarNumerosArrayPorCampo(array, "areaInvernaderos");
    const promedio = totalIngresos / areaTotal
    return body({ ingresosSinMaterialesM2: promedio, areaInvernaderos: areaTotal }, { field: "ingresosSinMaterialesM2" })
  }
  const footerPromediCostosRechazosM2 = (array, body) => {
    const totalCostos = sumarNumerosArrayPorCampo(array, "costoRechazadas");
    const areaTotal = sumarNumerosArrayPorCampo(array, "areaInvernaderos");
    const promedio = totalCostos / areaTotal
    return body({ costoRechazadasM2: promedio, areaInvernaderos: areaTotal }, { field: "costoRechazadasM2" })
  }
  const footerPromediRechazo = (array, body) => {
    const totalCostos = sumarNumerosArrayPorCampo(array, "costoRechazadas");
    const librasTotal = sumarNumerosArrayPorCampo(array, "librasTotales");
    const promedio = totalCostos / librasTotal
    return body({ preciorRechazoPromedioPorLibra: promedio }, { field: "preciorRechazoPromedioPorLibra" })
  }
  const footerPromedioPrecioFinal = (array, body) => {
    const totalIngresos = sumarNumerosArrayPorCampo(array, "ingresosSinMateriales");
    const totalCostos = sumarNumerosArrayPorCampo(array, "costoRechazadas");
    const librasTotal = sumarNumerosArrayPorCampo(array, "librasTotales");
    const promedio = (totalIngresos - totalCostos) / librasTotal
    return body({ preciorRechazoPromedioPorLibraSinMateriales: promedio }, { field: "preciorRechazoPromedioPorLibraSinMateriales" })
  }

  return (
    <ContenedorPrincipal>
      <ContenedorHeaderClickableDev col="p-col p-col-auto" titulo="Reporte de rechazos" iconos="reporte-empaque-icon" />

      <SeccionFiltros>
        <ContenedorFiltro col="4" label="productos">
          <MultiSelect options={productosPresentes} value={filtroProductos} optionLabel="nombre" dataKey="id" placeholder="Seleccione un producto"
            onChange={(e) => setFiltroProductos(e.value)} selectedItemsLabel={`${filtroProductos?.length} productos seleccionados`}
            selectedItemTemplate={item => selectedItemNombresMultiselect(item, filtroProductos, productosBases)} />
        </ContenedorFiltro>
        <ContenedorFiltro col="4" label="invernaderos">
          <MultiSelect options={invernaderosOpciones} value={filtroInvernaderos} optionLabel="nombre" dataKey="id" placeholder="Seleccione un invernadero"
            onChange={(e) => setFiltroInvernaderos(e.value)} selectedItemsLabel={`${filtroInvernaderos?.length} invernaderos seleccionados`}
            selectedItemTemplate={item => selectedItemNombresMultiselect(item, filtroInvernaderos, invernaderos)} />
        </ContenedorFiltro>
        <ContenedorFiltro col="4" label="clientes">
          <MultiSelect options={clientes} value={filtroClientes} optionLabel="nombre" dataKey="id" placeholder="Seleccione un cliente"
            onChange={(e) => setFiltroClientes(e.value)} selectedItemsLabel={`${filtroClientes?.length} clientes seleccionados`}
            selectedItemTemplate={item => selectedItemNombresMultiselect(item, filtroClientes, clientes)} />
        </ContenedorFiltro>
      </SeccionFiltros>

      <ContenedorTabla titulo="Resumen de invoices del ciclo" extra="">
        <DataTable value={rowsClientesCicloFiltrados} globalFilter={globalFilter} removableSort={true} className="table-invoices">
          <Column header="Cliente" field="cliente.nombre" className="col__label" sortable={true} />
          <Column field="ingresosSinMateriales" sortable={true} body={bodyIngresos} header={getHeaderWithTooltip("Ingresos totales", textos[1])}
            footer={footerSumaTotales(rowsClientesCicloFiltrados, "ingresosSinMateriales", bodyIngresos)} />
          <Column field="ingresosSinMaterialesM2" sortable={true} body={getTextWithTooltipIngresosM2} header={getHeaderWithTooltip("Ingresos por m²", textos[2])}
            footer={footerPromedioIngresosPorM2(rowsClientesCicloFiltrados, getTextWithTooltipIngresosM2)} />
          <Column header={getHeaderWithTooltip("Rechazos totales", textos[3])} field="costoRechazadas" sortable={true} body={bodyIngresos}
            footer={footerSumaTotales(rowsClientesCicloFiltrados, "costoRechazadas", bodyIngresos)} />
          <Column header={getHeaderWithTooltip("Rechazos por m²", textos[4])} field="costoRechazadasM2" sortable={true} body={getTextWithTooltipIngresosM2}
            footer={footerPromediCostosRechazosM2(rowsClientesCicloFiltrados, getTextWithTooltipIngresosM2)} />
          <Column header={getHeaderWithTooltip("Libras totales", textos[5])} field="librasTotales" sortable={true} body={bodyLibras}
            footer={footerSumaTotales(rowsClientesCicloFiltrados, "librasTotales", bodyLibras)} />
          <Column field="preciorRechazoPromedioPorLibra" sortable={true} body={bodyRechazosPorLibra} header={getHeaderWithTooltip("Rechazo promedio", textos[6])}
            footer={footerPromediRechazo(rowsClientesCicloFiltrados, bodyRechazosPorLibra)} />
          <Column field="preciorRechazoPromedioPorLibraSinMateriales" sortable={true} body={bodyRechazosPorLibra} header={getHeaderWithTooltip("Precio por libra final", textos[7])}
            footer={footerPromedioPrecioFinal(rowsClientesCicloFiltrados, bodyRechazosPorLibra)} />
        </DataTable>
      </ContenedorTabla>


      <ContenedorTabla titulo="Resumen de invoices por semana">
        <DataTable value={rowsSemanasFiltrados} globalFilter={globalFilter} removableSort={true} className="table-invoices">
          <Column header="Semana" field="week.nombre" className="col__label" sortable={true} />
          <Column field="ingresosSinMateriales" sortable={true} body={bodyIngresos} header={getHeaderWithTooltip("Ingresos totales", textos[1])} 
          footer={footerSumaTotales(rowsSemanasFiltrados, "ingresosSinMateriales", bodyIngresos)} />
          <Column field="ingresosSinMaterialesM2" sortable={true} body={getTextWithTooltipIngresosM2} header={getHeaderWithTooltip("Ingresos por m²", textos[2])} 
          footer={footerPromedioIngresosPorM2(rowsClientesCicloFiltrados, getTextWithTooltipIngresosM2)} />
          <Column header={getHeaderWithTooltip("Rechazos totales", textos[3])} field="costoRechazadas" sortable={true} body={bodyIngresos} 
          footer={footerSumaTotales(rowsSemanasFiltrados, "costoRechazadas", bodyIngresos)} />
          <Column header={getHeaderWithTooltip("Rechazos por m²", textos[4])} field="costoRechazadasM2" sortable={true} body={getTextWithTooltipIngresosM2} 
          footer={footerPromediCostosRechazosM2(rowsClientesCicloFiltrados, getTextWithTooltipIngresosM2)} />
          <Column header={getHeaderWithTooltip("Libras totales", textos[5])} field="librasTotales" sortable={true} body={UnidadLibras} 
          footer={footerSumaTotales(rowsSemanasFiltrados, "librasTotales", bodyLibras)} />
          <Column field="preciorRechazoPromedioPorLibra" sortable={true} body={bodyRechazosPorLibra} header={getHeaderWithTooltip("Rechazo promedio", textos[6])} 
          footer={footerPromediRechazo(rowsSemanasFiltrados, bodyRechazosPorLibra)} />
          <Column field="preciorRechazoPromedioPorLibraSinMateriales" sortable={true} body={bodyRechazosPorLibra} header={getHeaderWithTooltip("Precio por libra final", textos[7])} 
          footer={footerPromedioPrecioFinal(rowsSemanasFiltrados, bodyRechazosPorLibra)} />
        </DataTable>

      </ContenedorTabla>
    </ContenedorPrincipal>);
}
export default ReporteRechazos;