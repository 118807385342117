import React, { useState, useEffect, useGlobal } from 'reactn';
import ContenedorPrincipal from '../common/ContenedorPrincipal'
import ContenedorHeader from '../common/ContenedorHeader';
import ContenedorFiltro from '../common/ContenedorFiltro';
import SeccionFiltros from '../common/SeccionFiltros';
import { MultiSelect } from 'primereact/multiselect';
import { obtenerDetalleReporte } from '../../service/ReportesProducto';
import Chart from "react-apexcharts";
import { obtenerInicioSemanaDia, obtenerListaDeSemanas } from '../../service/fechas';
import { SelectButton } from 'primereact/selectbutton';
import { DeepClone, dosDecimales, stringToColor } from '../../util/functions';
import { ToggleButton } from 'primereact/togglebutton';
import { ordenarInvernaderosVirtualesPorIndexFisicoYNombre } from '../../service/Invernaderos';
import { obtenerReporteBudgetVsEmpaque } from 'service/AnalisisPrecios';
const tipos = [{ name: "Budget", code: "budget" }, { name: "Final", code: "final" }]

export default function BudgetEmpaque(props) {
  const tooltipFunction = ({ series, seriesIndex, dataPointIndex, w }) => {
    let seriesCompletas = w.config.series;
    let labels = [];

    filtroInvernaderos.forEach(invernadero => {
      labels.push(`<div class="p-tooltip-text">`)
      labels.push(`<div class="tooltip-title">${invernadero.nombre}</div>`)
      labels.push(`<div class="tooltip-datas">`)
      if (tipo.includes("final")) {
        let serie = seriesCompletas.find(el => el.name.trim() === `Final ${invernadero.nombre}`)
        labels.push(`<div class="tooltip-data">${serie.data[dataPointIndex]} <p class="tooltip-label">Kg/m² Final</p></div>`)
      }
      if (tipo.includes("budget")) {
        let serie = seriesCompletas.find(el => el.name.trim() === `Budget ${invernadero.nombre}`)
        labels.push(`<div class="tooltip-data">${serie.data[dataPointIndex]} <p class="tooltip-label">Kg/m² Budget</p></div>`)
      }
      if (tipo.includes("budget") && tipo.includes("final")) {
        let serieReal = seriesCompletas.find(el => el.name.trim() === `Final ${invernadero.nombre}`)
        let serieBudget = seriesCompletas.find(el => el.name.trim() === `Budget ${invernadero.nombre}`)
        labels.push(`<div class="tooltip-data">${dosDecimales(serieReal.data[dataPointIndex] - serieBudget.data[dataPointIndex])} <p class="tooltip-label">Kg/m² Diferencia</p></div>`)
      }
      labels.push(`</div></div>`)
    })

    labels = labels.join("")
    return `<div class = "chart-label"> ${labels}</div>`
  }
  const optionsOrig = {
    legend: {
      showForSingleSeries: true,
      formatter: function (seriesName, opts) {
          const lineaClassname = seriesName.includes("Budget") ? "dashed-line" : "solid-line";
          return `<div class = "chart-label"><span class = "dash-type"><span class="${lineaClassname}"></span></span> ${seriesName}</div>`;
      }
  },
  chart: {
      height: 350,
      type: 'area',
      zoom: { enabled: true, type: "x", autoScaleYAxis: true }
  },
  dataLabels: { enabled: false },
  stroke: { curve: 'straight', dashArray: [0, 1, 2, 3, 4, 5, 6, 7] },
  title: { text: 'Porcentaje de actividades vs porcentaje de horas', align: 'left' },
  grid: {
      row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
      },
  },

  }
  const [invernaderosOrig] = useGlobal("invernaderosVirtuales");
  const [filtroInvernaderos, setFiltroInvernaderos] = useState([]);
  const [productos] = useGlobal("productosBases");
  const [filtroProductos, setFiltroProductos] = useState([]);
  const [cicloId] = useGlobal("ciclo");
  const [cicloObj] = useGlobal("cicloObj");
  const [, setCargando] = useGlobal("cargando");
  const [detalles, setDetalles] = useState([]);
  const [options, setOptions] = useState({ ...optionsOrig });
  const [seriesFiltradas, setSeriesFiltradas] = useState([]);
  const [variedades, setVariedades] = useState([]);
  const [filtroVariedades, setFiltroVariedades] = useState([]);
  const [tipo, setTipo] = useState([])
  const [semanas, setSemanas] = useState([])
  const [isAcumulado, setIsAcumulado] = useState(false);
  const [reporte, setReporte] = useState([])
  const [datosDelCiclo, setDatosDelCiclo] = useState({ ciclo: "" })

  useEffect(() => {
    if (cicloId) { obtenerDatosIniciales(); }
  }, [cicloId])
  useEffect(() => {
    if (cicloId && filtroInvernaderos) { actualizarReporteCiclo(); }
  }, [filtroInvernaderos, isAcumulado])

  useEffect(() => {
    let variedades = [];
    filtroProductos.forEach(producto => {
      variedades.push(producto.variedades);
    })
    variedades = variedades.flat();
    let idsVariedades = variedades.map(el => el.id);
    let copiaFiltro = filtroVariedades.slice();
    copiaFiltro = copiaFiltro.filter(el => idsVariedades.includes(el.id));
    setFiltroVariedades(copiaFiltro);
    setVariedades(variedades);
  }, [filtroProductos])

  useEffect(() => {
    let filtradas = DeepClone(detalles)
    filtradas = filtradas.filter(el => tipo.includes(el.tipo))
    actualizarOptions(detalles)
    setSeriesFiltradas(filtradas);
  }, [filtroInvernaderos, tipo, detalles])

  const obtenerDatosIniciales = async () => {
    try {
      setCargando(true);
      let semanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
      setSemanas(semanas)
      const copiaInvs = DeepClone(invernaderosOrig);
      setFiltroInvernaderos(copiaInvs);
    } catch (error) { console.log("ERROR ", error); }
    setCargando(false);
  }

  const actualizarReporteCiclo = async () => {
    let reporte = [];
    ordenarInvernaderosVirtualesPorIndexFisicoYNombre(filtroInvernaderos)

    if (datosDelCiclo.ciclo !== cicloId || !datosDelCiclo.reporte) {
      setCargando(true);

      //const promises = filtroInvernaderos.map(inv => obtenerDetalleReporte(cicloObj, inv, semanas));
      //reporte = await Promise.all(promises);
      reporte = await obtenerReporteBudgetVsEmpaque(cicloObj, filtroInvernaderos, semanas)

      setReporte(reporte);
    }
    else reporte = datosDelCiclo?.reporte;

    const detalles = isAcumulado ? actualizarSeries(reporte) : extraerSeries(reporte);
    setDatosDelCiclo({ ciclo: cicloId, reporte: DeepClone(reporte) });
    setDetalles(detalles);
    setCargando(false);
  }


  const actualizarOptions = (series) => {
    let copiaOptions = { ...optionsOrig }
    let ahora = Math.round(Date.now() / 1000);
    let timeSemanaActual = obtenerInicioSemanaDia(ahora).unix();
    let semana = semanas.find(el => el.time === timeSemanaActual);

    copiaOptions.xaxis = { categories: semanas.map(el => el.nombreCompacto) }
    let colors = [], width = [], dashArray = [];

    series.forEach(serie => {
      let serieIndex = serie.tipo === "budget" ? 1 : 0;
      if (tipo.includes("budget") && !tipo.includes("final")) serieIndex = 1;
      else if (!tipo.includes("budget") && tipo.includes("final")) serieIndex = 0;

      width.push(5 * (serieIndex + 1));
      dashArray.push(3 * serieIndex);
      colors.push(stringToColor(serie.color));
    })
    copiaOptions.stroke = { width, dashArray, colors };

    if (semana) {
      copiaOptions.annotations = {
        xaxis: [{
          x: semana.nombreCompacto, strokeDashArray: 0, borderColor: '#775DD0',
          label: {
            borderColor: '#775DD0',
            style: { color: '#fff', background: '#775DD0', },
            text: 'Actual',
          }
        }]
      }
    }
    setOptions(copiaOptions);
  }

  const extraerSeries = (reporte) => {
    let ahora = Math.round(Date.now() / 1000)
    let inicioSemana = obtenerInicioSemanaDia(ahora).unix();

    let result = [];
    filtroInvernaderos.forEach((invernadero, index) => {
      let ciclo = "";

      const indexOrig = invernaderosOrig.findIndex(el => el.id === invernadero.id)
      let dataInv = reporte[indexOrig] || [];
      const color = stringToColor(invernadero.color)

      let baseBudget = {
        name: `Budget ${invernadero.nombre} ${ciclo}`, label: `Budget ${invernadero.nombre}`, type: "line", data: [], idInvernadero: invernadero.id,
        tipo: "budget", index: 0, color
      };
      let baseFinal = {
        name: `Final ${invernadero.nombre} ${ciclo}`, label: `Final ${invernadero.nombre}`, type: "line", data: [], idInvernadero: invernadero.id,
        tipo: "final", index: 1, color
      };
      let sumaBudget = 0, sumaFinal = 0;
      dataInv.forEach(semana => {
        sumaBudget = semana?.budget;
        baseBudget.data.push(dosDecimales(sumaBudget));
        if (inicioSemana >= semana?.semana) {
          sumaFinal = semana?.kgm2OrdenesSemana;
          baseFinal.data.push(dosDecimales(sumaFinal));
        } else {
          sumaFinal = semana?.budget;
          baseFinal.data.push(dosDecimales(sumaFinal));
        }
      })

      result.push(baseBudget);
      result.push(baseFinal);

    });

    return result;
  }
  const actualizarSeries = () => {
    let result = [];
    let ahora = Math.round(Date.now() / 1000);
    let inicioSemana = obtenerInicioSemanaDia(ahora).unix();

    filtroInvernaderos.forEach((invernadero, index) => {
      let dataInv = reporte[index];
      const color = stringToColor(invernadero.color)

      let baseBudget = {
        name: `Budget ${invernadero.nombre}`, label: `Budget ${invernadero.nombre}`, type: "line", data: [],
        idInvernadero: invernadero.id, tipo: "budget", index: 0, color
      };
      let baseFinal = {
        name: `Final ${invernadero.nombre}`, label: `Final ${invernadero.nombre}`, type: "line", data: [],
        idInvernadero: invernadero.id, tipo: "final", index: 1, color
      };
      let currentReal = 0, currentBudget = 0;

      dataInv.forEach(semana => {
        let datoReal = 0;
        if (inicioSemana >= semana.semana) { datoReal = dosDecimales(semana.kgm2OrdenesSemana) }
        else { datoReal = dosDecimales(semana.budget) }

        if (isAcumulado) {
          currentBudget += dosDecimales(semana.budget)
          currentReal += datoReal
        } else {
          currentBudget = dosDecimales(semana.budget)
          currentReal = datoReal
        }

        baseBudget.data.push(dosDecimales(currentBudget));
        baseFinal.data.push(dosDecimales(currentReal));
      })
      result.push(baseBudget);
      result.push(baseFinal);
    });
    return result;
  }

  return (
    <ContenedorPrincipal extra="chart-page chart-container-filters cosecha-chart">
      <ContenedorHeader titulo={"Empaque: Budget vs Final"} iconos={"costos-icon"} atras={props.history.goBack} />

      <SeccionFiltros>
        <ContenedorFiltro label="Productos">
          <MultiSelect options={productos} value={filtroProductos} optionLabel="nombre" dataKey="id"
            onChange={(e) => setFiltroProductos(e.value)} selectedItemsLabel={`${filtroProductos.length} productos seleccionados`} />
        </ContenedorFiltro>
        <ContenedorFiltro label="Variedades">
          <MultiSelect options={variedades} value={filtroVariedades} optionLabel="nombre" dataKey="id"
            onChange={(e) => setFiltroVariedades(e.value)} selectedItemsLabel={`${filtroVariedades.length} variedades seleccionadas`} />
        </ContenedorFiltro>

        <ContenedorFiltro label="Invernaderos">
          <MultiSelect options={invernaderosOrig} value={filtroInvernaderos} optionLabel="nombre" dataKey="id"
            onChange={(e) => setFiltroInvernaderos(e.value)}
            selectedItemsLabel={`${filtroInvernaderos.length} invernaderos seleccionados`} />
        </ContenedorFiltro>

        <ContenedorFiltro label="Tipo">
          <SelectButton optionLabel="name" optionValue="code" value={tipo} options={tipos} onChange={e => setTipo(e.value)} multiple={true} />
        </ContenedorFiltro>
        <ContenedorFiltro label="Acumulado">
          <ToggleButton checked={isAcumulado} onChange={e => setIsAcumulado(e.value)} onLabel="Si" offLabel="No" />
        </ContenedorFiltro>
      </SeccionFiltros>

      <div className="p-col-12 chart-container-filters">
        <Chart series={seriesFiltradas} options={options} height="100%" />
      </div>
    </ContenedorPrincipal>
  )
}