import * as firebase from "firebase/app";
import "firebase/firestore";
import { uploadStatistics } from './Estadisticas'

function registrarInvocaciones(count) {
    uploadStatistics(count)
}

export function obtenerClientes() {
    var db = firebase.firestore();
    return new Promise((resolve, reject) => {
        let results = [];
        db.collection("clientes").orderBy("orden").get().then(snap => {
            registrarInvocaciones(snap.size)
            snap.forEach(doc => {

                let data = doc.data();
                data.id = doc.id;
                results.push(data);
            })
            resolve(results);
        }).catch(err => {
            reject(err);
        });
    })
}

export function obtenerCliente(id) {
    var db = firebase.firestore();
    return new Promise((resolve, reject) => {
        db.collection("clientes").doc(id).get().then(snap => {
            registrarInvocaciones(snap.size)
            let data = snap.data();
            data.id = snap.id;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })

}
export const obtenerClientesCiclo = (ciclo) => {
    return new Promise((resolve, reject) => {
        firebase.firestore().collection("ciclos").doc(ciclo).collection("clientes").get().then((result) => {
            registrarInvocaciones(result.size)
            let rows = [];
            result.forEach(el => {
                let data = el.data();
                data.id = el.id;
                rows.push(data);
            })
            rows = rows.sort((a, b) => { return a.orden - b.orden })
            resolve(rows)
        }).catch((err) => {
            reject(err);
        });
    })
}
export const getClientesCiclo = async (cicloId) => {
    let [clientesCiclo, clientes] = await Promise.all([obtenerClientesCiclo(cicloId), obtenerClientes()]);
    clientes = clientes.filter(el => clientesCiclo.some(el2 => el2.cliente_ref === el.id));
    return clientes
}

export const obtenerClientePorNombre = async (nombre) => {
    try {
        const db = firebase.firestore();
        //
        // 
        // 
        let snaps = await db.collection("clientes").where("nombre", "==", nombre).get();
        registrarInvocaciones(snaps.size)
        let doc = snaps.docs[0];
        let registro = { id: doc.id, ...doc.data() };

        return registro;
    } catch (error) {
        console.log("ERROR OBTENER CLIENTE POR NOMBRE");
        // console.log(nombre);

        throw error;
    }
}

export const obtenerClientesMinoritarios = async () => {
    try {
        const db = firebase.firestore();
        let snaps = await db.collection("clientes").where("mayoritario", "==", false).get();
        registrarInvocaciones(snaps.size)
        let registros = snaps.docs.map(el => ({ id: el.id, ...el.data() }));
        // 
        // 
        return registros;
    } catch (error) {
        throw error;
    }
}

//97