import { setGlobal, getGlobal } from "reactn"
import * as firebase from 'firebase/app';
import "firebase/firestore";
import { obtenerLocaciones } from './Locaciones';
import { getObjetoPorID, getNombrePorID } from '../util/functions';
import {uploadStatistics} from './Estadisticas'

function registrarInvocaciones(count){
    uploadStatistics(count)
}

export const obtenerEmpacadoras = (params) => {
    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
        db.collection("empacadoras").orderBy("orden").get().then((result) => {
            registrarInvocaciones(result.size)
            let rows = [];
            result.forEach(item => {
                let data = item.data();
                data.id = item.id;
                rows.push(data);
            });
            resolve(rows)
        }).catch((err) => {
            reject(err)
        });
    });
}
export const obtenerEmpacadorasRegistrosPesos = async (invernadero, locacionID) => {
    let locaciones = getGlobal().locaciones?.length > 0 ? getGlobal().locaciones : await obtenerLocaciones().catch(error => console.log(JSON.stringify(error))) || []
    let empacadoras = getGlobal().empacadoras || []
    setGlobal({ locaciones })

    if (!invernadero.es_externo) {
        let invernaderosFisicos = getGlobal().invernaderosFisicos || []
        let invFisico = getObjetoPorID(invernaderosFisicos, invernadero.invernadero_fisico_ref)
        locacionID = invFisico.locacion_ref;
    }
    let locacionNombre = getNombrePorID(locaciones, locacionID)
    let allEmpacadoras = empacadoras.map(el => ({ ...el, nombreConLocacion: `${el.nombre} - ${locacionNombre}` }))

    return allEmpacadoras.filter(el => el.locacion_ref === locacionID)
}//40