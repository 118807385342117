import { Button } from 'primereact/button';
import React from 'react'
import { useEffect, useGlobal, useState } from 'reactn'
import { getProveedoresInvernadero } from 'service/Queries/Proveedores';
import { obtenerAreaInvernaderoSync, obtenerInvernaderoFisicoRelacionado, obtenerInvernaderosVirtualesActivos } from '../../service/Invernaderos'
import { obtenerPronosticoYProyeccion, obtenerPronosticoYProyeccionProveedorExterna } from '../../service/Pronosticos';
import { DeepClone, dosDecimales } from '../../util/functions';
import ContenedorFiltro from '../common/ContenedorFiltro';
import ContenedorPrincipal from '../common/ContenedorPrincipal';
import SeccionBotones from '../common/SeccionBotones';
import SeccionFiltros from '../common/SeccionFiltros';
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import InvernaderoCard from './InvernaderoCard';
import InvernaderoExternoCard from './InvernaderoExternoCard';
export default function Cards(props) {
    const [invernaderos, setInvernaderos] = useState([])
    const [ciclo] = useGlobal("ciclo")
    const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales")
    const [invernaderosExternos] = useGlobal("invernaderosExternos")
    const [productosYVariedades] = useGlobal("productosYVariedades")
    const [invernaderosFisicos] = useGlobal("invernaderosFisicos")
    const [, setCargando] = useGlobal("cargando")
    const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector();
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [invernaderosExternosPromedios, setInvernaderosExternosPromedios] = useState([])
    const [, setMostrarModalPermiso] = useGlobal("mostrarModalPermiso");

    useEffect(() => {
        if (week.nombre !== "Cargando" && invernaderosVirtuales) obtenerDatosIniciales();
    }, [invernaderosVirtuales, week])

    const obtenerDatosIniciales = async (params) => {
        setCargando(true)
        setNombreModulo("Pronósticos");
        let invernaderos = invernaderosVirtuales.filter(inv => inv.habilitado && !inv.es_externo);
        await obtenerDatosCardsInternos(invernaderos);
        await obtenerDatosCardsExternos();
        setCargando(false)
        //setInvernaderos(invernaderos);
    }

    const obtenerDatosCardsInternos = async (invernaderos) => {
        let promises = [];
        invernaderos.forEach(invernadero => {
            promises.push(obtenerResumenInternos(invernadero))
        })
        let values = await Promise.all(promises);
        let copia = invernaderos.slice();
        for (let x = 0; x < values.length; x++) {

            let [invId, invFisico, producto, variedad, semanaUno, semanaTres, semanaCinco, proyeccion, real] = values[x];
            let inv = copia.find(el => el.id === invId);

            inv.invernaderoFisico = invFisico;
            inv.variedad = variedad;
            inv.producto = producto;
            inv.semanaUno = dosDecimales(real);
            inv.semanaTres = semanaTres;
            inv.semanaCinco = semanaCinco;
            inv.proyeccion = proyeccion
        }
        setInvernaderos(copia);

    }
    const obtenerResumenInternos = async (invernadero) => {
        let area = obtenerAreaInvernaderoSync(invernadero);
        let pronosticoProyeccion = await obtenerPronosticoYProyeccion(ciclo, invernadero, week.time, area);
        let producto = productosYVariedades.find(prod => prod.id === invernadero.producto_ref)
        let variedad = producto?.variedades.find(vari => vari.id === invernadero.variedad_ref)
        let invernaderoFisico = invernaderosFisicos.find(invF => invF.id === invernadero.invernadero_fisico_ref);
        let semanaUno = dosDecimales(pronosticoProyeccion["semanaUno"] / area)
        return [invernadero.id, invernaderoFisico, producto, variedad, semanaUno,
        pronosticoProyeccion["semanaTres"], pronosticoProyeccion["semanaCinco"], pronosticoProyeccion["proyeccion"], pronosticoProyeccion.real]
    }

    const obtenerDatosCardsExternos = async () => {
        const arrayExternos = [];
        for(const index in invernaderosExternos){
            //1. Pasar el invernadero externo al funcion obtenerDatosCardsProveedores(invernaderosExternos[index])
            const proveedoresDeInvernadero = await obtenerDatosCardsProveedores(invernaderosExternos[index]);
            /* const length = proveedoresDeInvernadero?.length || 0; */
            const lengths = proveedoresDeInvernadero.reduce((acc, prov) => {
                if(prov.semanaUno > 0) acc.semanaUnoLength = acc.semanaUnoLength+1
                if(prov.semanaTres > 0) acc.semanaTresLength = acc.semanaTresLength+1
                if(prov.semanaCinco > 0) acc.semanaCincoLength = acc.semanaCincoLength+1
                return acc
            }, {semanaUnoLength: 0, semanaTresLength: 0, semanaCincoLength: 0})
            //2. Return arregla de datos cards de proveedores
            const promedioProveedoresInvernadero = proveedoresDeInvernadero.reduce((acc, proveedor) => {
                const { semanaUno, semanaTres, semanaCinco, proyeccion } = proveedor
                acc = {
                    semanaUno: acc.semanaUno+semanaUno,
                    semanaTres: acc.semanaTres === "-" ? acc.semanaTres : acc.semanaTres+(semanaTres == "-" ? 0 : semanaTres),
                    semanaCinco: acc.semanaCinco === "-" ? acc.semanaCinco : acc.semanaCinco+(semanaCinco == "-" ? 0 : semanaCinco),
                    proyeccion: acc.proyeccion+proyeccion
                }
                return acc 
            },{ semanaUno: 0, semanaTres: 0, semanaCinco: 0, proyeccion: 0 })
            const { semanaUno, semanaTres, semanaCinco, proyeccion } = promedioProveedoresInvernadero

            const {semanaUnoLength, semanaTresLength, semanaCincoLength}  = lengths

            arrayExternos.push({
                semanaUno: dosDecimales(semanaUnoLength > 0 ? (semanaUno / semanaUnoLength) : 0),
                semanaTres:  semanaTresLength > 0 ? (semanaTres / semanaTresLength) : 0,
                semanaCinco: semanaCincoLength > 0 ? (semanaCinco / semanaCincoLength) : 0,
                proyeccion: dosDecimales(semanaUnoLength > 0 ? (proyeccion / semanaUnoLength) : 0),
                invernadero: invernaderosExternos[index]
            })
            //3. Filtrar proveedores que no tienen datos (hacer console log, checar campos, si hay todo en 0 hay que filtrar). Esto es para no tener datos en 0 que va afectar el promedio total del invernadero externo
            //4. Generar promedio de los 4 campos
            //Tener cuidad que no hay valores en strings, utiliza parseFloat() cuando es necesario.
            //aqui////
        }
        setInvernaderosExternosPromedios(arrayExternos)
    }

    const obtenerDatosCardsProveedores = async (invernadero) => {
        try {
            let listaProveedores = await getProveedoresInvernadero(ciclo, invernadero.id)
            /* setProveedores(listaProveedores) */

            let promises = listaProveedores.map(proveedor => obtenerResumenExterno(proveedor, invernadero))
            let proveedoresCardsData = await Promise.all(promises)
            let proveedoraCopia = DeepClone(listaProveedores)

            for (let x = 0; x < proveedoresCardsData.length; x++) {
                if (!proveedoresCardsData[x]) proveedoraCopia[x] = false;
                else {
                    let [invId, producto, variedad, semanaUno, semanaTres, semanaCinco, proyeccion, proveedor] = proveedoresCardsData[x];
                    let proveedorCard = proveedoraCopia.find(el => el.id === proveedor.id);

                    proveedorCard.variedad = variedad;
                    proveedorCard.producto = producto;
                    proveedorCard.semanaUno = semanaUno;
                    proveedorCard.semanaTres = semanaTres;
                    proveedorCard.semanaCinco = semanaCinco;
                    proveedorCard.proyeccion = proyeccion;
                    proveedorCard.proveedor = proveedor
                }
            }
            proveedoraCopia = proveedoraCopia.filter(proveedorData => !!proveedorData); //Quitar los datos que tienen 'false'
            return proveedoraCopia
        } catch (err) {
            console.log(err)
            if (err.code && err.code === "permission-denied") {
                console.log(err.code);
                setMostrarModalPermiso(true);
            }
        }
    }
    const obtenerResumenExterno = async (proveedor, invernadero) => {
        let pronosticoProyeccion = await obtenerPronosticoYProyeccionProveedorExterna(ciclo, invernadero, week.time, proveedor);
        if (!pronosticoProyeccion) { return false; }//checar si hay datos, y esconder si no hay

        let producto = productosYVariedades.find(prod => prod.id === invernadero.producto_ref)
        let variedad = producto?.variedades.find(vari => vari.id === invernadero.variedad_ref) || {}
        let semanaUno = dosDecimales(pronosticoProyeccion["semanaUno"] / parseInt(proveedor.area_invernadero))
        return [invernadero.id, producto, variedad, semanaUno, pronosticoProyeccion.semanaTres, pronosticoProyeccion.semanaCinco, pronosticoProyeccion.proyeccion, proveedor];
    }

    return (
        <ContenedorPrincipal>
            <SeccionBotones>
                <Button label="Reporte pronósticos" onClick={() => { props.history.push("/pronosticos/reporte") }} />
            </SeccionBotones>
            <SeccionFiltros >
                <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />

            </SeccionFiltros>
            {invernaderos.map((invernadero, idx) =>
                <InvernaderoCard key={idx} invernadero={invernadero} />)}
            {invernaderosExternosPromedios?.map(({invernadero, ...pronExternoDatos}, idx) =>
                <InvernaderoExternoCard key={idx} invernadero={invernadero} info={pronExternoDatos}/>)}
        </ContenedorPrincipal>)
}//109