import React, { useEffect, useState } from 'react'
import { useGlobal } from 'reactn'
import { obtenerCiclo } from '../../service/Ciclos';
import { obtenerMedidasCultivo, obtenerReportesSemanalesCiclo, obtenerOutputsRegistroCultivo } from '../../service/Cultivo';
import { obtenerListaDeSemanas } from '../../service/fechas';
import Chart from "react-apexcharts";
import { colorAleatorio, dosDecimales } from '../../util/functions';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { SelectButton } from 'primereact/selectbutton';
import { mapeoTabsCultivoInvertido } from '../../constants';
import ContenedorFiltro from '../common/ContenedorFiltro';

const optionsOrig = {
  chart: {

    type: 'line',
    stacked: false
  },
  dataLabels: {
    enabled: false
  },
  title: {
    text: 'Mediciones de cultivo durante el ciclo',
    align: 'left',

  },
  xaxis: {
    categories: [],
  },

  
  tooltip: {
    enabled:true,
    followCursor:true,
  },
  legend: {
    horizontalAlign: 'center',
    offsetX: 40
  }
}


const opcionesEjeY = [
  { name: "Activo", value: "activo" }, { name: "Inactivo", value: "inactivo" }
]
const Cultivo = (props) => {
  const [ciclo,] = useGlobal("ciclo");
  const [cicloObj,] = useGlobal("cicloObj");
  const [invernadero, setInvernadero] = useState(props.location?.data?.invernadero);
  const [, setCargando] = useGlobal("cargando");
  const [data, setData] = useState([]);
  const [options, setOptions] = useState({ ...optionsOrig })
  const [medidasOrig, setMedidasOrig] = useState([]);
  const [invernaderos, setInvernaderos] = useState([]);
  const [filtros, setFiltros] = useState([]);
  const [labels, setLabels] = useState([]);
  const [ejesActivos, setEjesActivos] = useState(opcionesEjeY[1]["value"])
  const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
  const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");

  useEffect(()=>{
    setHabilitadoSelectorCiclo(true)
  },[])

  useEffect(() => {
    if(invernaderosVirtuales){
      let invernaderos = invernaderosVirtuales.filter(inv=>!inv.es_externo);
      setInvernaderos(invernaderos);
    }
    if (invernaderosVirtuales && invernadero) {
      obtenerDatosIniciales();
    }
  }, [invernaderosVirtuales, invernadero]);

  const obtenerSeries = (params) => {
    return filtros.map(el => {
      let found = data.find(dataset => dataset.idSerie === el.id)
      if (found) {
        let copia = { ...found }
        if(found.name === "# Racimos / Planta (Cuajados)"){
          // 
          copia["name"] = "Num. Racimos / Planta (Cuajados)"
        }
        return copia

      } else {
        return {
          name: el.nombre,
          type: "line",
          data: []
        }
      }
    })
  }

  const obtenerOpciones = (params) => {
    let copiaOptions = { ...options };
    if (ejesActivos === "activo") {
      copiaOptions["yaxis"] = filtros.map(medida => {
        return {
          seriesName: medida.nombre,
          decimalsInFloat: 0,
          opposite: true,
          axisTicks: { show: true, color: colorAleatorio() },
          axisBorder: {
            show: true,
            color: '#00E396'
          },
          labels: {
            style: {
              colors: '#00E396',
            }
          },
          title: {
            text: "",
            style: {
              color: '#00E396',
            }
          }
        }
      })
    } else {
      copiaOptions["yaxis"] = { show: false };
    }

    copiaOptions["xaxis"]["categories"] = labels;
    return copiaOptions;
  }

  const atras = (params) => {
    props.history.goBack();
  }

  const obtenerDatosIniciales = async (params) => {
    try {
      setCargando(true);
      let medidas = await obtenerMedidasCultivo();
      let medidasPlus = medidas.slice();
      medidasPlus.push({ nombre: "Peso promedio de fruta", id: "peso_promedio_fruta", tipo: "cultivo" });
      medidasPlus.push({ nombre: "Producción semanal ", id: "produccion_semanal", tipo: "cultivo" });
      medidasPlus.push({ nombre: "Producción acumulado", id: "produccion_acumulado", tipo: "cultivo" });
      setMedidasOrig(medidasPlus.sort((a, b) => {
        if (a.tipo > b.tipo) {
          return 1
        } else {
          return -1
        }
      }));
      if (filtros.length === 0) {
        setFiltros(medidasPlus.slice(0, 5));
      }

      let reportes = await obtenerReportesSemanalesCiclo(cicloObj, invernadero, medidas);
      let procesado = await procesar(cicloObj, medidas, reportes);

      setData(procesado);
    } catch (error) {
      console.log("ERROR OBTENER DATOS INICIALES");
      console.log(error);
    } finally {
      setCargando(false);
    }

  }

  const procesar = async (cicloObj, medidas, reportes) => {
     
    let semanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
    let copiaOptions = { ...options };
    let labels = semanas.map(el => el.nombreCompacto);
    setLabels(labels);
    copiaOptions["xaxis"]["categories"] = labels;
    //copiaOptions["xaxis"] = {
    //          categories:labels,
    //convertedCatToNumeric:true,
    //}
    // 
    // copiaOptions["yaxis"] = medidas.map(medida=>{
    //         return {
    //             seriesName: medida.nombre,
    //             opposite:true,
    //             axisTicks:{show:true,color: colorAleatorio()},
    //             axisBorder: {
    //                 show: true,
    //                 color: '#00E396'
    //               },
    //               labels: {
    //                 style: {
    //                   colors: '#00E396',
    //                 }
    //               },
    //               title: {
    //                 text: medida.nombre,
    //                 style: {
    //                   color: '#00E396',
    //                 }
    //               }
    //         }
    // })
    setOptions(copiaOptions);
    let aSaltar = ["peso_promedio_fruta", "produccion_semanal", "produccion_acumulado"];
    let filtradas = medidas.filter(el => !aSaltar.includes(el.id));
    let result = filtradas.map(medida => {
      let promediosMedida = [];
      reportes.forEach(reporteSemana => {
        let found = reporteSemana.find(el => el.medidaId === medida.id);
        if (found) {
          
            promediosMedida.push(dosDecimales(found.promedio));
          

        } else {
          promediosMedida.push(0);
        }
      })
      let obj =  {
        idSerie: medida.id,
        name: medida.nombre,
        type: "line",
        data: promediosMedida
      }
      // if(medida.nombre === "# Racimos / Planta (Cuajados)"){
      //   obj["name"] = "Cuajados";
      // }
      return obj;
    })

    let outputs = await obtenerOutputs(result);

    // result["riego"].push(outputs["proporcion_riego"]);
    // result["riego"].push(outputs["consumo"]);
    result.push(outputs["peso_promedio_fruta"]);
    result.push(outputs["produccion_semanal"]);
    result.push(outputs["produccion_acumulado"]);
    return result;
  }
  const obtenerOutputs = async (params) => {
    let listaSemanas = await obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
    let promises = listaSemanas.map(el => obtenerOutputsRegistroCultivo(ciclo, invernadero, el.time))
    let outputs = await Promise.all(promises);
    let seriePesoPromedioFruta = { idSerie: "peso_promedio_fruta", name: "Peso promedio fruta", type: "line", data: [] }
    let serieProduccionSemanal = { idSerie: "produccion_semanal", name: "Produccion Semanal", type: "line", data: [] }
    let serieProduccionAcumulado = { idSerie: "produccion_acumulado", name: "Produccion Acumulado", type: "line", data: [] }

    outputs.forEach(([peso, prodSem, prodAc]) => {
      seriePesoPromedioFruta.data.push(isNaN(peso) ? 0 : dosDecimales(peso));
      serieProduccionSemanal.data.push(dosDecimales(prodSem));
      serieProduccionAcumulado.data.push(dosDecimales(prodAc));

    })

    return { peso_promedio_fruta: seriePesoPromedioFruta, produccion_semanal: serieProduccionSemanal, produccion_acumulado: serieProduccionAcumulado };
  }
  const itemTemplate = (item) => {
    let nombreTab = mapeoTabsCultivoInvertido[item["tipo"]]
    return `${nombreTab} - ${item.nombre}`
  }

  let opts = obtenerOpciones();
  let series = obtenerSeries();

  return <div className="p-grid chart-page">
    {invernadero ? <div className="p-col  p-col-auto title-container">
      <span className="back-btn" onClick={atras} ></span>
      <span className={` icon-general chart-icon  bg-${invernadero.color}  bor-${invernadero.color}`}></span>
      <span className="titles-group">
        <h1 className="header-title">Mediciones de cultivo</h1>
        <p className={`header-subtitle `}>Invernadero {invernadero.nombre}</p>
      </span>
    </div> :
      <div className="p-col  p-col-auto title-container">
        <span className="back-btn" onClick={atras} ></span>
        <span className={` icon-general chart-icon `}></span>
        <span className="titles-group">
          <h1 className="header-title">Mediciones de cultivo</h1>
        </span>
      </div>}
    <div className="p-col-12">
      <div className="p-grid filtros">
        <div className="form-group p-col">
          <label htmlFor="filtroInvernadero">Invernadero</label>
          <Dropdown value={invernadero} options={invernaderos} onChange={(e) => setInvernadero(e.value)} dataKey="id" optionLabel="nombre" />
        </div>

        <div className="form-group p-col">
          <label htmlFor="filtroCliente">Mediciones de cultivo a graficar</label>
          <MultiSelect value={filtros} options={medidasOrig} onChange={(e) => setFiltros(e.value)} dataKey="id" optionLabel="nombre" 
            selectedItemsLabel={`${filtros.length} mediciones seleccionados`}
            itemTemplate={itemTemplate} />
        </div>
        <ContenedorFiltro>
          <SelectButton options={opcionesEjeY} optionLabel="name" value={ejesActivos} onChange={e => setEjesActivos(e.value)} />
        </ContenedorFiltro>
      </div>
    </div>
    <div className="p-col-12 chart-container-filters">
      {options["xaxis"]["categories"].length > 0 ? <Chart height="100%" options={obtenerOpciones()} series={obtenerSeries()} type="line" /> : null}
    </div>
  </div>
}

export default Cultivo;
