import React from 'react';
import * as firebase from "firebase/app";
import "firebase/firestore";
import { obtenerInvernaderoFisicoRelacionado } from "./Invernaderos";
import RhService, { obtenerEmpleadosOtros, obtenerEmpleadosEmpaque, obtenerCuentaEmpleadosHabilitadosInvernadero, obtenerCuentaEmpleadosActivos, getNumeroDeEmpleadosEnInvernadero } from "./RhService";
import { DETALLES_DEPARTAMENTO_EMPAQUE, DETALLES_DEPARTAMENTO_OTROS } from "../util/constants";
import { obtenerEmpleadoEmpaqueCiclo, obtenerEmpleadosEmpaqueCiclo } from "./NominaEmpaque";
import { obtenerEmpaqueCiclo } from "./Empaques";
import { chunkArray, getMomentFormatted } from "../util/functions";
import { Button } from "primereact/button";
import { uploadStatistics } from './Estadisticas'

function registrarInvocaciones(count) {
    uploadStatistics(count)
}
const mapeoMotivos = { "Consulta médica": "consulta_medica", "Examen médico": "examen_medico", "Riesgo de trabajo": "riesgo_trabajo" }
export const guardarReporteMedico = async (ciclo, datos, archivosExamen, archivosCertificado, archivosOtro) => {
    try {



        const db = firebase.firestore();
        let linksExamen = await guardarArchivos(archivosExamen);
        let linksCertificado = await guardarArchivos(archivosCertificado);
        let linksOtro = await guardarArchivos(archivosOtro);
        datos["archivos_examen"] = linksExamen;
        datos["archivos_certificado"] = linksCertificado;
        datos["archivos_otro"] = linksOtro;
        let result = await db.collection("ciclos").doc(ciclo).collection("reportes_medicos").add(datos);
        return true
    } catch (error) {
        throw error;
    }

}
export const actualizarReporteMedico = async (ciclo, id, datos, archivosExamen, archivosCertificado, archivosOtro) => {
    try {



        const db = firebase.firestore();
        if (archivosExamen && archivosExamen.length > 0) {
            let linksExamen = await guardarArchivos(archivosExamen);
            datos["archivos_examen"] = datos["archivos_examen"].concat(linksExamen);
        }
        if (archivosCertificado && archivosCertificado.length > 0) {
            let linksCertificado = await guardarArchivos(archivosCertificado);
            datos["archivos_certificado"] = datos["archivos_certificado"].concat(linksCertificado);
        }
        if (archivosOtro && archivosOtro.length > 0) {
            let linksOtro = await guardarArchivos(archivosOtro);
            datos["archivos_otro"] = datos["archivos_otro"].concat(linksOtro);
        }

        let result = await db.collection("ciclos").doc(ciclo).collection("reportes_medicos").doc(id).update(datos);
        return true
    } catch (error) {
        throw error;
    }
}

export const obtenerReportesMedicos = async (ciclo, semana) => {
    const db = firebase.firestore();
    let snapReportes = await db.collection("ciclos").doc(ciclo).collection("reportes_medicos")
        .where("fecha", ">=", semana.time).where("fecha", "<", semana.time + 7 * 24 * 60 * 60).get();
    registrarInvocaciones(snapReportes.size)
    let reportes = snapReportes.docs.map(el => ({ id: el.id, ...el.data() }));
    return reportes;
}
export const obtenerReportesMedicosEmpleado = async (ciclo, empleado) => {
    let response = await firebase.firestore().collection("ciclos").doc(ciclo).collection("reportes_medicos").where("empleado_ref", "==", empleado).get().catch(err => {window.alert(JSON.stringify(err))});
    registrarInvocaciones(response.size)
    let reportes = response.docs.map(el => ({ id: el.id, ...el.data() }));
    return reportes;
}
export const obtenerReportesMedicosTodos = async (ciclo) => {
    let snapReportes = await firebase.firestore().collection("ciclos").doc(ciclo).collection("reportes_medicos").get();

    registrarInvocaciones(snapReportes.size)
    let reportes = snapReportes.docs.map(el => ({ id: el.id, ...el.data() }));
    return reportes;
}
export const obtenerVistaGraficaConsultasMedicas = async (ciclo) => {
    let response = await firebase.firestore().collection("ciclos").doc(ciclo).collection("vistas").where("graficaBarConsultasMedicas", "==", true).get();

    registrarInvocaciones(response.size)
    let vistaMedico = response.docs.length === 1 ? response.docs[0].data() : {}
    return vistaMedico;
}
export const obtenerReportesMedicosIntervaloFechas = async (ciclo, inicioTime, finTime) => {
    const db = firebase.firestore();
    let snapReportes = await db.collection("ciclos").doc(ciclo).collection("reportes_medicos")
        .where("fecha", ">=", inicioTime).where("fecha", "<", finTime).get();
    registrarInvocaciones(snapReportes.size)
    let reportes = snapReportes.docs.map(el => ({ id: el.id, ...el.data() }));
    return reportes;
}
export const obtenerResumenMedicoInvernadero = async (cicloId, invernadero, semana) => {
    // 
    try {
        let result = { ...invernadero };
        result["tipo"] = "Invernadero";
        let invernaderoFisico = await obtenerInvernaderoFisicoRelacionado(invernadero.invernadero_fisico_ref);
        // 

        result.invernaderoFisico = invernaderoFisico;

        let registros = await obtenerReportesMedicosInvernaderoSemana(cicloId, invernadero, semana)
        // 
        // 
        // 
        let numEmpleados = invernadero.numero_maximo_trabajadores;
        let empleados = await obtenerCuentaEmpleadosActivos(cicloId, invernadero.id, semana.time);
        // 
        // 
        result["empleados"] = `${empleados}/${numEmpleados}`;
        result["examen_medico"] = 0;
        result["riesgo_trabajo"] = 0;
        result["consulta_medica"] = 0;
        registros.forEach(registro => {
            let resultMapeo = mapeoMotivos[registro["motivo"]];
            // 
            // 
            //
            result[resultMapeo]++;
        })
        // 
        return result;
    } catch (error) {
        console.log("ERROR RMI");
        throw error;
    }

}

const obtenerReportesMedicosInvernaderoSemana = async (cicloId, invernadero, semana) => {
    try {
        const db = firebase.firestore();
        let snap = await db.collection("ciclos").doc(cicloId).collection("reportes_medicos").where("invernadero_ref", "==", invernadero.id)
            .where("fecha", ">=", semana.time).where("fecha", "<", semana.time + 7 * 24 * 60 * 60).get();
        registrarInvocaciones(snap.size)
        return snap.docs.map(el => ({ id: el.id, ...el.data() }))
    } catch (error) {
        throw error;
    }

}

export const obtenerResumenMedicoEmpaque = async (cicloId, empaque, semana) => {
    try {
        let result = { ...empaque };
        result["color"] = "";
        result["tipo"] = "Empaque";
        let empaqueCiclo = await obtenerEmpaqueCiclo(cicloId, empaque.id);
        let empleados = await obtenerEmpleadosEmpaqueCiclo(cicloId, empaque.id);
        let registros = await obtenerReportesMedicosEmpaqueSemana(cicloId, empaque, semana)



        // 
        // 
        // 
        // 
        result["empleados"] = empleados.length;
        result["examen_medico"] = 0;
        result["riesgo_trabajo"] = 0;
        result["consulta"] = 0;
        result["consulta_medica"] = 0;
        result["tipo"] = "Empaque";
        result["icono"] = DETALLES_DEPARTAMENTO_EMPAQUE.icono;
        result["color"] = DETALLES_DEPARTAMENTO_EMPAQUE.color
        registros.forEach(registro => {
            result[mapeoMotivos[registro["motivo"]]]++;
        })
        return result;
    } catch (error) {
        console.log("ERROR RME");
        throw error;
    }

}
const obtenerReportesMedicosEmpaqueSemana = async (cicloId, empaque, semana) => {
    try {
        const db = firebase.firestore();
        let snap = await db.collection("ciclos").doc(cicloId).collection("reportes_medicos").where("empaque_ref", "==", empaque.id)
            .where("fecha", ">=", semana.time).where("fecha", "<", semana.time + 7 * 24 * 60 * 60).get();
        registrarInvocaciones(snap.size)
        return snap.docs.map(el => ({ id: el.id, ...el.data() }))
    } catch (error) {
        throw error;
    }
}

export const obtenerResumenMedicoOtros = async (cicloId, semana) => {

    let result = { nombre: "Otros" };
    result["color"] = "";
    result["tipo"] = "Otros";
    result["id"] = "otros";
    let registros = await obtenerReportesMedicosOtrosSemana(cicloId, semana)
    let empleados = await obtenerEmpleadosOtros();
    result["empleados"] = empleados.length;
    result["examen_medico"] = 0;
    result["riesgo_trabajo"] = 0;
    result["consulta"] = 0;
    result["consulta_medica"] = 0;
    result["icono"] = DETALLES_DEPARTAMENTO_OTROS.icono;
    result["color"] = DETALLES_DEPARTAMENTO_OTROS.color
    result["tipo"] = "Otros"
    const mapeo = {
        "Examen médico": "examen_medico",
        "Riesgo de trabajo": "riesgo_trabajo",
        "Consulta médica": "consulta_medica"
    }
    registros.forEach(registro => {
        result[mapeo[registro["motivo"]]]++;
    })
    // 
    // 
    // 
    // 
    return result;
}
const obtenerReportesMedicosOtrosSemana = async (cicloId, semana) => {
    try {
        let departamentos = await RhService.getDepartamentos();
        departamentos = departamentos.filter(el => el.nombre != "Invernadero" && el.nombre != "Empaque");
        let departamentosIds = departamentos.map(el => el.id);
        const db = firebase.firestore();
        let departamentoChunks = chunkArray(departamentosIds, 10);
        let promises = departamentoChunks.map(departamentosChunk => {
            return db.collection("ciclos").doc(cicloId).collection("reportes_medicos").where("departamento_ref", "in", departamentosChunk)
                .where("fecha", ">=", semana.time).where("fecha", "<", semana.time + 7 * 24 * 60 * 60).get();
        });
        let snaps = await Promise.all(promises);
        registrarInvocaciones(snaps.length)
        let result = snaps.map(snap => snap.docs.map(el => ({ id: el.id, ...el.data() })));
        return result.flat();
    } catch (error) {
        console.log("ERROR RMOS");
        console.log(error);
        throw error;
    }
}

const guardarArchivos = async (archivos) => {
    try {
        if (!archivos || archivos.length === 0) {
            return [];
        }
        // 
        // 
        const storage = firebase.storage();
        const storageRef = storage.ref();
        var reporteMedicoRef = storageRef.child("reporte_medico");
        let results = [];
        for (let x = 0; x < archivos.length; x++) {
            // 
            // 
            let arc = archivos[x];
            let name = `${Math.round(Date.now() / 1000)}_${Math.random()}_${arc.name}`;
            const ref = storageRef.child(`reporte_medico/${name}`);
            let res = await ref.put(arc);
            // 
            // 
            results.push(name);
        }
        return results;
    } catch (error) {
        console.log("HUBO UN ERROR AL SUBIR LOS ARCHIVOS");
        console.log(error);
        throw (error);
    }

}
export const obtenerUrlArchivo = async (archivo) => {
    // 
    // 
    const storage = firebase.storage();
    const storageRef = storage.ref();
    let url = await storageRef.child(`reporte_medico/${archivo}`).getDownloadURL();
    // 
    return url;
}
export const obtenerCuentaConsultas = async (ciclo, empleadoRef) => {
    let consultas = await obtenerConsultasDeEmpleados(ciclo, empleadoRef);
    return consultas
}
export const obtenerConsultasDeEmpleados = async (ciclo, empleadoRef) => {
    const db = firebase.firestore();
    let snaps = await db.collection("ciclos").doc(ciclo).collection("reportes_medicos").
        where("empleado_ref", "==", empleadoRef).get();
    registrarInvocaciones(snaps.size)
    let docs = snaps.docs.map(current => {
        return { id: current.id, ...current.data() }
    })
    return docs;
}

export const getTooltipHoverInfo = (empleado) => {
    let consultas = empleado.consultas.sort((a, b) => a.fecha - b.fecha)
    let tooltipInfoHTMLString = consultas.map(cons => `<li>${getMomentFormatted(cons.fecha * 1000, "DD/MM/YYYY")} ${cons.hora}: ${cons.procedimiento_consulta || "No es consulta"} - ${cons.diagnostico?.nombre || "N/A"}</li>`).join("")
    tooltipInfoHTMLString = `<ul>${tooltipInfoHTMLString}</ul>`

    let tooltipInfo = `<p class='tooltip-title'>Consultas del empleado en el ciclo</p><p class='tooltip-def'> </p> <div >${tooltipInfoHTMLString}</div></div>`;
    return <div> <Button type="button" label={`${empleado.cuentaConsultas}`} className="btn-line btn-help" tooltip={tooltipInfo} /></div>
}