//react/reactn
import React, { useState, useEffect, useGlobal, setGlobal } from 'reactn'
//Funciones internas
import { getObjetoPorID } from '../../../util/functions';
import { getColoresVariedades, guardarVariedad, getColeccionesProductosYVariedadesTodos } from '../../../service/ProductosYVariedades';
//Componentes third party
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
//Componentes internos
import ContenedorPrincipal from '../../common/ContenedorPrincipal';
import ContenedorHeader from '../../common/ContenedorHeader';
import ContenedorInput from '../../common/ContenedorInput';
import SeccionBotones from '../../common/SeccionBotones';
import SeccionFormulario from '../../common/SeccionFormulario';


const FormularioVariedades = (props) => {
    const [ciclo] = useGlobal("ciclo");
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [, setCargando] = useGlobal("cargando");
    const [variedad] = useGlobal("variedad")
    const [editing] = useState(variedad ? true : false);
    const [errores, setErrores] = useState({})
    //Opciones del formulario
    const [productosUnicos] = useGlobal("productosUnicos")
    const [coloresVariedades, setColoresVariedades] = useGlobal("coloresVariedades")
    //Inputs del formulario
    const [nombre, setNombre] = useState(variedad?.nombre || "");
    const [producto, setProducto] = useState(variedad?.producto_ref || "")
    const [color, setColor] = useState(variedad?.color || "");

    useEffect(() => {
        setNombreModulo("Productos y variedades");
        setHabilitadoSelectorCiclo(false);
        obtenerDatosIniciales();
    }, [])

    useEffect(() => {
        setErrores({});
    }, [nombre, producto, color])

    const obtenerDatosIniciales = async () => {
        const colores = await getColoresVariedades();
        setColoresVariedades(colores);
    }

    const guardar = async () => {
        if (validarErrores()) {
            setCargando(true);
            const datos = { nombre, producto_ref: producto, color };
            await guardarVariedad(producto, variedad?.id, datos);
            await recargarDatos();
            
            setCargando(false)
            props.history.goBack();
        }
    }
    const recargarDatos = async () => {//pasar esto a carpeta /service?
        const datosColeccionesProductos = await getColeccionesProductosYVariedadesTodos(ciclo);
        setGlobal({ ...datosColeccionesProductos });
    }

    const validarErrores = () => {
        const errores = {};
        const camposRequeridos = { "nombre": nombre, "producto": producto, "color": color };

        for (const key in camposRequeridos) {
            const campo = camposRequeridos[key];
            if (!campo) { errores[key] = true; }
        }
        setErrores(errores);
        return Object.keys(errores).length === 0;
    }

    return (
        <ContenedorPrincipal>
            <ContenedorHeader titulo={`${editing ? "Editar" : "Crear"} variedad`} iconos="presentacion-icon" atras={props.history.goBack} />

            <SeccionFormulario titulo="General">
                <ContenedorInput label="Nombre">
                    <InputText value={nombre} onChange={(e) => setNombre(e.target.value)} />
                    {errores.nombre && <Message severity="error" text="Este campo es requerido" />}
                </ContenedorInput>

                <ContenedorInput label="Producto">
                    <Dropdown value={producto} options={productosUnicos} optionLabel="nombre" optionValue="id" onChange={e => setProducto(e.value)} />
                    {errores.producto && <Message severity="error" text="Este campo es requerido" />}
                </ContenedorInput>

                <ContenedorInput label="Color">
                    <Dropdown options={coloresVariedades} optionLabel="nombre" optionValue="id" value={color} onChange={e => setColor(e.value)} />
                    {errores.color && <Message severity="error" text="Este campo es requerido" />}
                </ContenedorInput>
            </SeccionFormulario>

            <SeccionBotones>
                <Button label="Cancelar" className="p-button-secondary" onClick={props.history.goBack} />
                <Button label="Guardar" onClick={guardar} />
            </SeccionBotones>
        </ContenedorPrincipal>
    )
}
export default FormularioVariedades;