import * as firebase from "firebase/app";
import "firebase/firestore";
import {
    obtenerDiasDeSemana, obtenerListaDeSemanas,
    obtenerInicioSemanaDia, obtenerDiaActual,
    obtenerHora,
    obtenerSemanaAnterior, obtenerNombreSemana, flexDate
} from "./fechas";
import { obtenerCiclo } from "./Ciclos";

import { getProyeccionConPronosticoDesde, obtenerProyeccionConPronosticoDesde, obtenerProyeccionDesde, obtenerProyeccionDesdeExterno } from './Budget'
import { getPesoOrdenadoHasta, obtenerPesoOrdenadoHasta } from "./Ordenes";
import { obtenerProveedoresInvernadero } from "./Queries/Proveedores";
import { uploadStatistics } from './Estadisticas'
import { getGlobal, setGlobal } from "reactn"

function registrarInvocaciones(count) {
    uploadStatistics(count)
}

const horaCorte = 17;

export function registrarEmpaque(ciclo, invernadero, dia, pallets, consecutivo) {
    const timestamps = flexDate(dia);
    let db = firebase.firestore();
    return new Promise((resolve, reject) => {
        db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero).
            collection("empaques").where("dia", ">=", timestamps.inicio).where("dia", "<", timestamps.fin).get().then(resp => {
                registrarInvocaciones(resp.size)
                let cuentaPorRegistrar = pallets.filter(el => el.tipo === "POR_REGISTRAR").length;
                if (resp.empty) {

                    //crear

                    db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero).
                        collection("empaques").add({ dia: dia, pallets: pallets }).then(resp => {
                            actualizarConsecutivo(ciclo, invernadero, consecutivo + cuentaPorRegistrar).then(resp => {
                                resolve(true);
                            }).catch(err => {
                                console.log("ERROR ACTUALIZAR CONSECUTIVO")
                                reject(err);
                            })

                        }).catch(err => reject(err));
                } else {
                    db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero).
                        collection("empaques").where("dia", ">=", timestamps.inicio).where("dia", "<", timestamps.fin).get().then(resp => {
                            registrarInvocaciones(resp.size)
                            if (!resp.empty) {
                                db.doc(resp.docs[0].ref.path).update({ pallets: pallets }).then(resp => {
                                    actualizarConsecutivo(ciclo, invernadero, consecutivo + cuentaPorRegistrar).then(resp => {
                                        resolve(true);
                                    }).catch(err => {
                                        console.log("error ACTUALIZAR CONSECUTIVO")
                                        reject(err);
                                    })

                                }).catch(reject)
                            }
                        })


                }
            }).catch(err => {
                reject(err);
            })
    })

}

export function obtenerPallets(ciclo, invernadero, dia) {
    const timestamp = flexDate(dia);
    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
        db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero).collection("empaques")
            .where("dia", ">=", timestamp.inicio).where("dia", "<", timestamp.fin).get().then(resp => {
                registrarInvocaciones(resp.size)
                if (resp.empty) {
                    resolve([])
                } else {
                    let data = resp.docs[0].data();
                    data.id = resp.docs[0].id;
                    resolve(data.pallets);
                }

            }).catch(err => {
                reject(err);
            })
    })

}
export function actualizarConsecutivo(ciclo, invernadero, consecutivo) {
    // 
    // 
    const db = firebase.firestore();
    return db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero)
        .update({ consecutivo_pallets: consecutivo });

}
export function obtenerConsecutivoPallets(ciclo, invernadero, dia) {
    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
        db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero).get()
            .then(resp => {
                registrarInvocaciones(resp.size)
                if (resp.exists && resp.data().consecutivo_pallets) {
                    resolve(resp.data().consecutivo_pallets);
                } else {
                    db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero).update({
                        consecutivo_pallets: 0
                    }).then(resp => {
                        resolve(0);
                    }).catch(err => {
                        console.log("ERR UPDATE");
                        console.log(invernadero);
                        reject(err);
                    })
                }

            }).catch(err => {
                console.log("ERR CONSULTA");
                reject(err);
            })
    })
}



export function obtenerPesoRegistradoSemana(ciclo, invernadero, dias) {
    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
        let promises = [];
        dias.forEach(dia => {
            const timestamps = flexDate(dia);
            let promise = db.collection("ciclos").doc(ciclo).
                collection("invernaderos_virtuales").doc(invernadero)
                .collection("empaques")
                .where("dia", ">=", timestamps.inicio).where("dia", "<", timestamps.fin).get()
            registrarInvocaciones(promise.size)
            promises.push(promise);
        })
        Promise.all(promises).then(values => {
            let suma = 0;
            values.forEach(value => {
                if (!value.empty) {
                    let data = value.docs[0].data();
                    if (data.pallets) {
                        suma += data.pallets.reduce((acc, curr) => {
                            let cant = parseFloat(curr.cantidad);
                            if (isNaN(cant)) {
                                return acc
                            } else {
                                return acc + cant;
                            }
                        }, 0)
                    }
                }
            })
            resolve(suma);
        }).catch(err => { reject(err) });
    })
}

export function obtenerAreaInvernadero(ciclo, invernadero) {
    return new Promise((resolve, reject) => {

        const db = firebase.firestore();
        db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero)
            .get().then(async resp => {
                registrarInvocaciones(resp.size)
                let data = resp.data();
                if (data?.es_externo) {
                    obtenerProveedoresInvernadero(ciclo, invernadero).then((proveedores) => {
                        let result = 0;
                        proveedores.forEach(proveedor => {
                            result += parseFloat(proveedor.area_invernadero)
                        })
                        resolve(result)
                    }).catch((err) => {
                        reject(err);
                    });;

                } else {
                    let areas = data?.detalles_fisicos?.find(el => el.detalle === "Area (m²)") || {}
                    resolve(parseFloat(areas?.norte || "0") + parseFloat(areas?.sur || "0"));
                }
            }).catch(err => {
                reject(err);
            })
    })

}
export function obtenerAreaInvernaderoSync(ciclo, invernadero) {
    let areas = invernadero.detalles_fisicos?.find(el => el.detalle === "Area (m²)") || {}
    return (parseFloat(areas?.norte || "0") + parseFloat(areas?.sur || "0"));
}


export function obtenerPesoRegistradoCiclo(ciclo, invernadero) {
    const db = firebase.firestore();
    let total = 0;
    return new Promise((resolve, reject) => {
        db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero)
            .collection("empaques").get().then(resp => {
                registrarInvocaciones(resp.size)
                resp.forEach(dia => {

                    let data = dia.data();
                    if (data.pallets) {
                        let suma = data.pallets.reduce((acc, curr) => {
                            let cant = parseFloat(curr.cantidad);
                            if (isNaN(cant)) {
                                return acc;
                            } else {
                                return acc + cant;
                            }
                        }, 0);
                        total += suma;
                    }
                })
                resolve(total);
            }).catch(err => {
                reject(err);
            });
    })

}
export function obtenerPesoRegistradoCicloHastaSemana(ciclo, invernadero, semana) {
    const db = firebase.firestore();
    let total = 0;
    return new Promise((resolve, reject) => {
        db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero)
            .collection("empaques").where("dia", "<", semana)
            .get().then(resp => {
                registrarInvocaciones(resp.size)
                resp.forEach(dia => {

                    let data = dia.data();
                    if (data.pallets) {
                        let suma = data.pallets.reduce((acc, curr) => {
                            let cant = parseFloat(curr.cantidad);
                            if (isNaN(cant)) {
                                return acc;
                            } else {
                                return acc + cant;
                            }
                        }, 0);
                        total += suma;
                    }
                })
                resolve(total);
            }).catch(err => {
                reject(err);
            });
    })

}
export function obtenerPesoProyectado(ciclo, invernadero, semana) {

    return new Promise((resolve, reject) => {
        obtenerCiclo(ciclo).then(cicloResp => {
            obtenerPesoOrdenadoHasta(ciclo, invernadero, semana).then(respHastaAhora => {
                obtenerProyeccionConPronosticoDesde(ciclo, invernadero, semana, cicloResp.semana_cierre, semana).then(respDesdeAhora => {
                    obtenerAreaInvernadero(ciclo, invernadero).then(respArea => {
                        let total = respDesdeAhora + respHastaAhora / respArea;
                        resolve(total);
                    }).catch(err => {
                        reject(err);
                    })
                }).catch(err => {
                    reject(err);
                })
            }).catch(err => {
                reject(err);
            })
        }).catch(err => {
            reject(err);
        })
    })
}
export const getPesoProyectadoHome = async (ciclo, invernadero, semana) => {
    let { cicloObj } = getGlobal();
    const area = obtenerAreaInvernaderoSync(null, invernadero)

    let [respHastaAhora, respDesdeAhora] = await Promise.all([
        getPesoOrdenadoHasta(ciclo, invernadero.id, semana),
        getProyeccionConPronosticoDesde(ciclo, invernadero, semana, cicloObj.semana_cierre, semana)])
    let total = respDesdeAhora + respHastaAhora / area;
    return total;
}
export async function getPesoProyectado(ciclo, invernadero, semana, area) {
    let cicloObj = getGlobal().cicloObj
    let [respHastaAhora, respDesdeAhora] =
        await Promise.all([obtenerPesoOrdenadoHasta(ciclo, invernadero, semana), obtenerProyeccionConPronosticoDesde(ciclo, invernadero, semana, cicloObj.semana_cierre, semana)])
    let total = respDesdeAhora + respHastaAhora / area;
    return total
}

export function obtenerPesoProyectadoExterno(ciclo, invernadero, semana) {

    return new Promise((resolve, reject) => {
        obtenerCiclo(ciclo).then(cicloResp => {
            obtenerPesoOrdenadoHasta(ciclo, invernadero, semana).then(respHastaAhora => {
                obtenerProyeccionDesdeExterno(ciclo, invernadero, semana, cicloResp.semana_cierre).then(respDesdeAhora => {
                    obtenerAreaInvernadero(ciclo, invernadero).then(respArea => {
                        let total = respDesdeAhora + respHastaAhora / respArea;
                        resolve(total);
                    }).catch(err => {
                        console.log("error oai")
                        reject(err);
                    })
                }).catch(err => {
                    console.log("ERROR OPDE")
                    reject(err);
                })
            }).catch(err => {
                console.log("ERROR OPOH")
                reject(err);
            })
        }).catch(err => {
            console.log("ERROR OC");
            reject(err);
        })
    })
}

export function obtenerPesoDesde(ciclo, invernadero, inicio, fin) {
    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
        let total = 0;
        inicio = obtenerInicioSemanaDia(inicio).unix();
        fin = obtenerInicioSemanaDia(fin).unix();
        db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero).collection("empaques")
            .where("dia", ">=", inicio).where("dia", "<", fin)
            .get().then(respEmpaques => {
                registrarInvocaciones(respEmpaques.size)
                respEmpaques.forEach(empaque => {
                    empaque = empaque.data();


                    if (empaque.pallets) {
                        let suma = empaque.pallets.reduce((acc, curr) => {
                            let cant = parseFloat(curr.cantidad);
                            if (isNaN(cant)) {
                                return acc;
                            } else {
                                return acc + cant;
                            }
                        }, 0);
                        total += suma;
                    }
                });
                resolve(total);
            }).catch(err => {
                reject(err);
            })
    })
}
export function obtenerPesoProveedorDesde(ciclo, invernadero, inicio, fin, proveedor) {
    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
        let total = 0;
        inicio = obtenerInicioSemanaDia(inicio).unix();
        fin = obtenerInicioSemanaDia(fin).unix();
        db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero).collection("empaques")
            .where("dia", ">=", inicio).where("dia", "<", fin)
            .get().then(respEmpaques => {
                registrarInvocaciones(respEmpaques.size)
                respEmpaques.forEach(empaque => {
                    empaque = empaque.data();

                    if (empaque.pallets) {
                        let suma = empaque.pallets.reduce((acc, curr) => {
                            let cant = parseFloat(curr.cantidad);
                            if (isNaN(cant)) {
                                return acc;
                            } else if (curr.proveedor_ref === proveedor.id) {
                                return acc + cant;
                            }
                            else return acc
                        }, 0);
                        
                        total += suma;
                    }
                });
                if (proveedor.nombre === "Adam Dodgen prov1") console.log("empaques ADAM TOTAL ", total)
                resolve(total);
            }).catch(err => {
                reject(err);
            })
    })
}


export function obtenerPesoDia(ciclo, invernadero, dia) {

    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
        let total = 0;
        const timestamp = flexDate(dia)
        db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero).collection("empaques")
            .where("dia", ">=", timestamp.inicio).where("dia", "<=", timestamp.fin)
            .get().then(respEmpaques => {
                registrarInvocaciones(respEmpaques.size)
                respEmpaques.forEach(empaque => {
                    empaque = empaque.data();

                    if (empaque.pallets) {
                        let suma = empaque.pallets.reduce((acc, curr) => {
                            let cant = parseFloat(curr.cantidad);
                            if (isNaN(cant)) {
                                return acc;
                            } else {
                                return acc + cant;
                            }
                        }, 0);
                        total += suma;
                    }
                });
                resolve(total);
            }).catch(err => {
                reject(err);
            })
    })
}


export function obtenerPesoSemana(ciclo, invernadero, semana) {

    const db = firebase.firestore();
    let hoy = obtenerDiaActual();
    let hora = obtenerHora(Math.round(Date.now() / 1000));

    return new Promise((resolve, reject) => {
        let total = 0;
        semana = obtenerInicioSemanaDia(semana).unix();
        let dias = obtenerDiasDeSemana(semana);
        db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero).collection("empaques")
            .where("dia", ">=", semana).where("dia", "<", semana + 7 * 24 * 60 * 60)
            .get().then(respEmpaques => {
                registrarInvocaciones(respEmpaques.size)
                let empaques = respEmpaques.docs.map(el => ({ ...el.data(), id: el.id }))
                let results = []
                dias.forEach(dia => {
                    const timestamps = flexDate(dia)
                    let empaque = empaques.find(el => el.dia >= timestamps.inicio && el.dia < timestamps.fin);
                    if (empaque) {
                        results.push(empaque);
                        if (empaque.pallets) {
                            let suma = empaque.pallets.reduce((acc, curr) => {

                                let cant = parseFloat(curr.cantidad);
                                if (isNaN(cant)) {
                                    return acc;
                                } else {
                                    return acc + cant;
                                }
                            }, 0);
                            total += suma;
                        }
                    } else {
                        //
                    }
                });

                resolve(total);
            }).catch(err => {
                reject(err);
            })
    })
}

export function obtenerPesoSemanaProveedor(ciclo, invernadero, proveedor, semana) {
    const db = firebase.firestore();
    let hoy = obtenerDiaActual();
    let hora = obtenerHora(Math.round(Date.now() / 1000));

    return new Promise((resolve, reject) => {
        let total = 0;
        semana = obtenerInicioSemanaDia(semana).unix();
        let dias = obtenerDiasDeSemana(semana);
        db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero).collection("empaques")
            .where("dia", ">=", semana).where("dia", "<", semana + 7 * 24 * 60 * 60)
            .get().then(respEmpaques => {
                registrarInvocaciones(respEmpaques.size)
                let empaques = respEmpaques.docs.map(el => ({ ...el.data(), id: el.id }))
                let results = []
                dias.forEach(dia => {
                    const timestamps = flexDate(dia)
                    let empaque = empaques.find(el => el.dia >= timestamps.inicio && el.dia < timestamps.fin)
                    if (empaque) {
                        results.push(empaque);
                        if (empaque.pallets) {
                            let suma = empaque.pallets.reduce((acc, curr) => {

                                let cant = parseFloat(curr.cantidad);
                                if (isNaN(cant)) {
                                    return acc;
                                } else if (curr.proveedor_ref === proveedor.id) {
                                    return acc + cant;
                                } else {
                                    return acc
                                }
                            }, 0);
                            total += suma;
                        }
                    } else {
                        //
                    }
                });
                //
                //
                //
                //
                resolve(total);
            }).catch(err => {
                reject(err);
            })
    })
}

export const obtenerPesoCajaParaSemana = async (ciclo, invernadero, semana, pesoDefault) => {
    //se obtiene el peso de caja promedio de la semana pasada

    let semanaAnterior = obtenerSemanaAnterior(semana);

    let registros = await obtenerRegistrosSemana(ciclo, invernadero, semanaAnterior);
    let total = 0;
    let muestras = 0;
    registros.forEach(registro => {
        registro.pallets.forEach(pallet => {
            if (pallet.cajas) {
                let kilos = parseFloat(pallet.cantidad);
                let cajas = parseFloat(pallet.cajas);
                if (!isNaN(cajas) && !isNaN(kilos)) {
                    total += kilos;
                    muestras += cajas;
                }
            }
        })
    })
    if (muestras === 0) {return pesoDefault } 
    else { return total / muestras; }
}

export const obtenerCajasCosechadaTotales = async (ciclo, invernadero, semana) => {
    let registros = await obtenerRegistrosSemana(ciclo, invernadero, semana);
    let muestras = 0;
    registros.forEach(registro => {
        registro.pallets.forEach(pallet => {
            if (pallet.cajas) {
                let cajas = parseFloat(pallet.cajas), kilos = parseFloat(pallet.cantidad);
                if (!isNaN(cajas) && !isNaN(kilos)) { muestras += cajas; }
            }
        })
    })
    return muestras;
}

export const obtenerRegistrosSemana = async (ciclo, invernadero, semana) => {

    const db = firebase.firestore();
    let result = await db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales")
        .doc(invernadero).collection("empaques").where("dia", ">=", semana).where("dia", "<", semana + 7 * 24 * 60 * 60)
        .get()
    registrarInvocaciones(result.size)


    let rows = [];
    result.forEach(doc => {
        let data = doc.data();
        data.id = doc.id;
        rows.push(data);
    })


    return rows

}
export const obtenerResumenEmpaqueSemana = async (ciclo, invernadero, semana) => {
    let registros = await obtenerRegistrosSemana(ciclo, invernadero, semana);
    let kilos = 0;
    let cajas = 0;
    registros.forEach(registro => {
        let pallets = registro.pallets;
        pallets.forEach(pallet => {
            if (pallet.cantidad) {
                let currKilos = parseFloat(pallet.cantidad);
                if (!isNaN(currKilos)) {
                    kilos += currKilos;
                }
            }
            if (pallet.cajas) {
                let currCajas = parseFloat(pallet.cajas);
                if (!isNaN(currCajas)) {
                    cajas += currCajas;
                }
            }
        })

    })
    return { kilos, cajas };
}

export const obtenerPesoPromedioCajaSemana = async (ciclo, invernadero, semana, proveedor = false) => {
    let registros = await obtenerRegistrosSemana(ciclo, invernadero, semana);
    let total = 0, muestras = 0;
    registros.forEach(registro => {
        registro.pallets.forEach(pallet => {
            if (pallet.cajas) {
                let kilos = parseFloat(pallet.cantidad);
                let cajas = parseFloat(pallet.cajas);
                if (!isNaN(cajas) && !isNaN(kilos) && (!proveedor || pallet.proveedor_ref === proveedor)) {
                    total += kilos;
                    muestras += cajas;
                }
            }
        })
    })
    return muestras === 0 ? 0 : total / muestras
}

export const obtenerResumenPesosSemana = async (cicloId, invernaderoId) => {
    let ciclo = await obtenerCiclo(cicloId);
    let semanas = obtenerListaDeSemanas(ciclo.semana_inicio, ciclo.semana_cierre);
    let promises = semanas.map(semana => obtenerPesoCajaParaSemana(cicloId, invernaderoId, semana.time, 0))
    let result = await Promise.all(promises);
    return result
}//596