import React, { useState, useEffect } from 'react';
import { useGlobal } from 'reactn';
import Chart from "react-apexcharts";
import { obtenerPresentaciones } from "../../service/Presentaciones";
import { obtenerInvernaderosVirtuales } from "../../service/Invernaderos";
import { obtenerOrdenesCiclo } from '../../service/Ordenes';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { obtenerCiclo } from '../../service/Ciclos';
import { obtenerListaDeSemanas, obtenerDiasDeSemana } from '../../service/fechas';
import { SelectButton } from 'primereact/selectbutton';
import { obtenerClientes } from '../../service/Clientes';
import { DeepClone, getObjetoPorID, paletaColores, PALETA_COLORES_CLAROS_INVS, separadoComas, stringToColor } from '../../util/functions';
import { paletaClientes } from '../../constants';
import { obtenerSobrantePorSemanaCiclo } from '../../service/Cumplimiento';
import ContenedorFiltro from "../common/ContenedorFiltro";
import SeccionFiltros from "../common/SeccionFiltros";

import { ToggleButton } from 'primereact/togglebutton';
import { hayMedleysViejo } from '../../service/ProductosYVariedades';

const tiposGraficas = [

  {
    label: "Absoluto", value: "absoluto"
  }

]
let options = {
  colors: paletaColores,
  chart: {
    type: 'line',
    height: 350,
    stacked: false,
    stackType: "100%",
    animations: {
      enabled: false
    }
  },
  dataLabels: {
    colors: paletaColores,
    enabled: true,
    formatter: (val, opt) => {

      val = separadoComas(Math.round(val));
      if (opt["w"]["config"]["chart"]["stackType"] === "100%") {
        val = `${val} %`
      }
      return val;
    }
  },
  responsive: [{
    breakpoint: 480,
    options: {
      legend: {
        position: 'bottom',
        offsetX: -10,
        offsetY: 0,
        colors: paletaColores
      }
    }
  }],
  xaxis: {
    categories: [],
  },
  yaxis: {
    decimalsInFloat: 2
  },
  plotOptions: {
    bar: {
      horizontal: false,
    }
  },
  fill: {
    opacity: 1
  },
  tooltip: {
    enabled: true,
    followCursor: true,
  },
  legend: {
    colors: paletaColores,
    position: 'right',
    offsetX: 0,
    offsetY: 400,
  },
}
const DistribucionPresentacionesPorSemana = (props) => {
  const [ciclo, setCiclo] = useGlobal("ciclo");
  const [cicloObj] = useGlobal("cicloObj");
  const [ciclos, setCiclos] = useGlobal("ciclos");
  const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
  const [productosYVariedades] = useGlobal("productosYVariedades")
  const [productosBases] = useGlobal("productosBases");
  const [productosMezclados] = useGlobal("productosMezclados");
  const [productosYVariedadesTodos] = useGlobal("productosYVariedadesTodos")
  const [allVariedades] = useGlobal("allVariedades");
  const [medleysGeneral] = useGlobal("medleysGeneral");
  const [arregloInvsVirtuales, setArregloInvsVirtuales] = useGlobal("arregloInvsVirtuales");
  const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
  const [, setNombreModulo] = useGlobal("nombreModulo");
  const [data, setData] = useState([]);
  const [invernaderos, setInvernaderos] = useState([]);
  const [productosConVariedades, setProductosConVariedades] = useState([]);
  const [filtroProducto, setFiltroProducto] = useState([]);
  const [clientesOrig, setClientesOrig] = useState([]);
  const [filtroCliente, setFiltroCliente] = useState([]);
  const [filtroVariedad, setFiltroVariedad] = useState([]);
  const [filtroVariedadMeldeysAgrupado, setFiltroVariedadMeldeysAgrupado] = useState([]);
  const [filtroInvernadero, setFiltroInvernadero] = useState([]);
  const [showFiltroMultiClientes, setShowFiltroMultiClientes] = useState(true)
  const [showFiltroMultiProductos, setShowFiltroMultiProductos] = useState(true)
  
  const [ordenesOrig, setOrdenesOrig] = useState([]);
  const [presentaciones, setPresentaciones] = useState([]);
  const [, setCargando] = useGlobal("cargando");
  const [tipoGrafica, setTipoGrafica] = useState(tiposGraficas[0]["value"])
  const [sobrantes, setSobrantes] = useState([])
  const [multiCiclo, setMultiCiclo] = useState(false)

  const [ordenesOrigCiclo1, setOrdenesOrigCiclo1] = useState([]);
  const [ordenesOrigCiclo2, setOrdenesOrigCiclo2] = useState([]);
  const [sobrantesCiclo1, setSobrantesCiclo1] = useState([])
  const [sobrantesCiclo2, setSobrantesCiclo2] = useState([])
  const [allCiclos, setAllCiclos] = useState([]);
  const [ciclo1, setCiclo1] = useState("")
  const [ciclo2, setCiclo2] = useState("")
  const [ciclo1Invernadero, setCiclo1Invernadero] = useState([])
  const [ciclo2Invernadero, setCiclo2Invernadero] = useState([])
  const [dataCiclo1, setDataCiclo1] = useState([]);
  const [dataCiclo2, setDataCiclo2] = useState([]);
  const [optsGraficaNormal, setOptsGraficaNormal] = useState(obtenerOptions())

  useEffect(() => {
    let newOptions = {}
    if(data.length > 0) newOptions = obtenerOptions()

    if(filtroCliente.length === 1 && filtroProducto.length > 1){
      newOptions = {...optsGraficaNormal, colors: PALETA_COLORES_CLAROS_INVS}
    }else{
      newOptions = obtenerOptions()
    }
    newOptions.key = `${Date.now()}-normal`
    if(filtroProducto.length > 1 && filtroCliente.length === 1){
      newOptions = {...newOptions, title: {text:`Distribución de ${filtroCliente[0].nombre}`, align:"left"}}
    }
    if(filtroProducto.length === 1 && filtroCliente.length > 1){
      newOptions = {...newOptions, title: {text:`Distribución de ${filtroProducto[0].nombre}`, align:"left"}}
    }
    setOptsGraficaNormal(newOptions)
  }, [data, filtroCliente, filtroProducto])
  useEffect(() => {
    setHabilitadoSelectorCiclo(true)
    setNombreModulo("Distribución de clientes por semana")
  }, [])
  useEffect(() => {
      setFiltroInvernadero([])
  }, [filtroCliente])
  useEffect(() => {
    if (productosYVariedades) {
      obtenerDatosIniciales();
      obtenerCiclosConInvernaderos()
    }
  }, [productosYVariedades])

  useEffect(() => {
    if (ciclo1) { obtenerDatosInicialesMulti(ciclo1, 1); }
  }, [ciclo1])
  useEffect(() => {
    if (ciclo2) { obtenerDatosInicialesMulti(ciclo2, 2); }
  }, [ciclo2])
  useEffect(() => {
    setFiltroInvernadero([]);
    setFiltroVariedad([]);
    setFiltroVariedadMeldeysAgrupado([]);
  }, [ciclo])

  useEffect(() => {
    if (filtroVariedad.length > 0 && filtroCliente.length > 0 && filtroProducto.length > 0) {

      if (!multiCiclo) {
        let procesado = procesar();
        if(filtroProducto.length > 1 && filtroCliente.length===1){
          const totalCliente = procesado.find(procesado => procesado.name ===`Total ${filtroCliente[0].nombre}`)
          const dataSinTotales = procesado.filter(fact => !fact.name.startsWith("Total"))
          procesado = totalCliente ? [...dataSinTotales, totalCliente] : dataSinTotales
        }
        if(filtroProducto.length === 1 && filtroCliente.length>1){
          const totalProducto = procesado.find(procesado => procesado.name ===`Total ${filtroProducto[0].nombre}`)
          const dataSinTotales = procesado.filter(fact => !fact.name.startsWith("Total"))
          procesado = totalProducto ? [...dataSinTotales, totalProducto]: dataSinTotales
        }
        setData(procesado);
      }
      if (multiCiclo && ciclo1Invernadero.length > 0) {
        let procesadoCiclo1 = procesarMultiCiclo(ciclo1, 1);
        setDataCiclo1(procesadoCiclo1);
      }
      if (multiCiclo && ciclo2Invernadero.length > 0) {
        let procesadoCiclo2 = procesarMultiCiclo(ciclo2, 2);
        setDataCiclo2(procesadoCiclo2);
      }
    } else {
      setData([]);
      setDataCiclo1([])
      setDataCiclo2([])
    }
  }, [filtroVariedad, filtroCliente, filtroProducto, filtroInvernadero, ciclo1Invernadero, ciclo2Invernadero])

  useEffect(() => {
    if (filtroProducto?.length === 0) { setFiltroVariedadMeldeysAgrupado([]) }
    setFiltroInvernadero([])
  }, [filtroProducto])

  useEffect(() => {
    setFiltroVariedadMeldeysAgrupado([]);
  }, [filtroInvernadero])

  useEffect(() => {
    let variedadesConMedleys = [...filtroVariedadMeldeysAgrupado];
    const conMedleys = filtroProducto?.some(prod => prod.MEDLEYS_GENERAL)
    if (conMedleys && !hayMedleysViejo(filtroInvernadero)) { variedadesConMedleys.push(...productosMezclados) }


    // const variedadesSinMedleys = filtroVariedad.filter(variedadOMezcla => !variedadOMezcla.mezcla);
    setFiltroVariedad(variedadesConMedleys);
  }, [filtroVariedadMeldeysAgrupado])

  useEffect(() => {
    if(filtroProducto.length > 1){
      setShowFiltroMultiClientes(false)
    }
    if(filtroProducto.length <= 1){
      setShowFiltroMultiClientes(true)
    }
  },[filtroProducto])

  useEffect(() => {
    if(filtroCliente.length > 1){
      setShowFiltroMultiProductos(false)
    }
    if(filtroCliente.length <= 1){
      setShowFiltroMultiProductos(true)
    }
  },[filtroCliente])
  
  const obtenerCiclosConInvernaderos = async () => {
    let allCiclosObj = []
    for (let x = 0; x < ciclos.length; x++) {
      let invDocs = arregloInvsVirtuales[ciclos[x].id] || await obtenerInvernaderosVirtuales(ciclos[x].id)
      if (!arregloInvsVirtuales[ciclos[x].id]) {
        const copiaArreglo = arregloInvsVirtuales
        copiaArreglo[ciclos] = invernaderosVirtuales
        setArregloInvsVirtuales(copiaArreglo)
      }
      allCiclosObj.push({ ...ciclos[x], invernaderos: invDocs })
    }
    setAllCiclos(allCiclosObj)
  }

  const obtenerDatosIniciales = async (params) => {
    try {
      setCargando(true)
      let invernaderos = invernaderosVirtuales;/////
      let clientesPromise = obtenerClientes();
      let presentacionesPromise = obtenerPresentaciones();
      let ordenesPromise = obtenerOrdenesCiclo(ciclo);/////

      let [clientes, presentaciones, ordenes] =
        await Promise.all([clientesPromise, presentacionesPromise, ordenesPromise]);
      //console.log(clientes)
      setInvernaderos(invernaderos);
      setPresentaciones(presentaciones);


      setProductosConVariedades(productosYVariedades);
      setOrdenesOrig(ordenes);
      setClientesOrig(clientes);
      let sobrantes = await obtenerSobrantePorSemanaCiclo(ciclo, invernaderos);
      setSobrantes(sobrantes);
    } catch (error) {
      //console.log("ERROR OBTENER DATOS INICIALES");
      //console.log(error);
    } finally {
      setCargando(false);
    }

  }
  const obtenerDatosInicialesMulti = async (cicloPassedIn, cicloNum) => {
    try {
      setCargando(true)

      let ordenes = await obtenerOrdenesCiclo(cicloPassedIn.id);/////

      if (cicloNum === 1) {
        setOrdenesOrigCiclo1(ordenes);
        let sobrantes = await obtenerSobrantePorSemanaCiclo(cicloPassedIn.id, ciclo1.invernaderos);
        setSobrantesCiclo1(sobrantes);
      }
      else if (cicloNum === 2) {
        setOrdenesOrigCiclo2(ordenes);
        let sobrantes = await obtenerSobrantePorSemanaCiclo(cicloPassedIn.id, ciclo2.invernaderos);
        setSobrantesCiclo2(sobrantes);
      }

      //setData(ordenesProcesadas) ;
    } catch (error) {
      //console.log("ERROR OBTENER DATOS INICIALES MULTICICLO");
      //console.log(error);
    } finally {
      setCargando(false);
    }

  }

  const obtenerOpcionesInvernaderos = () => {
    if (filtroProducto?.length === 0) { return []; }
    else { return invernaderosVirtuales.filter(inv => filtroCliente.some(cli => cli.id === inv.cliente_ref || inv.es_externo) && filtroProducto.some(prod => prod.id === inv.producto_ref || (prod.MEDLEYS_GENERAL && inv.mezcla))); }
  }
  const obtenerOpcionesVariedades = () => {
    const opcionesVariedades = [];
    if (filtroCliente?.length === 0 || filtroInvernadero?.length === 0 || !filtroProducto?.length === 0) { return opcionesVariedades; }
    //Estos lineas comentados habilita seleccionar productos mezclados en el dropdown de variedades
    //else if (filtroProducto?.length === 1 && filtroProducto.some(prod => prod.MEDLEYS_GENERAL) && !hayMedleysViejo(filtroInvernadero)) { return productosMezclados }
    //else if (filtroProducto.some(prod => prod.MEDLEYS_GENERAL) && !hayMedleysViejo(filtroInvernadero)) { opcionesVariedades.push(...productosMezclados) }

    const filtrProductosVariedades = filtroProducto?.map(prod => {
      if (prod.MEDLEYS_GENERAL && !hayMedleysViejo(filtroInvernadero)) { return []; }
      else { return prod.variedades; }
    }) || [];

    const variedadesDeProductos = filtrProductosVariedades.flat();
    opcionesVariedades.push(...variedadesDeProductos);
    return opcionesVariedades;
  }

  const procesar = () => {
    try {
      if (cicloObj) {
        let ordenes = ordenesOrig;

        let clientesIds = filtroCliente.map(el => el.id);
        //let invernaderos = obtenerOpcionesInvernaderos(); 
        let invernaderos = filtroInvernadero.slice();
        let invernaderosIds = filtroInvernadero.map(el => el.id);

        let variedades = filtroVariedad.slice();
        let variedadesIds = variedades.map(el => el.id);

        let idsProductos = filtroProducto.map(el => el.id);
        let filtradas = presentaciones.filter(el => idsProductos.includes(el.producto_ref) || idsProductos.includes("Tx4pyK0HMrYYE43UtxKx"));
        filtradas = filtradas.filter(el => clientesIds.includes(el.cliente_ref));

        let seriesClientes = [];
        let listaSemanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);

        let seriesProductos = obtenerSeriesProductos(listaSemanas, ordenes, filtradas, invernaderosIds, variedadesIds, clientesIds);
        let resultClientes = filtroCliente.map(cliente => {
          return obtenerResultCliente(cliente, listaSemanas, ordenes, filtradas,
            invernaderosIds, variedadesIds, seriesClientes)
          // return resultProducto;


        })

        let seriesSobrantes = filtroInvernadero.map(invernadero => {
          let sobrante = sobrantes.find(el => el.idInvernadero === invernadero.id)
          if (sobrante) {
            sobrante = sobrante.sobrantes.map(el => el.sobrante)
          }
          let serie = {
            name: `Sobrante ${invernadero.nombre}`,
            tipo: "sobrante",
            data: sobrante,
            sumaPresentacion: 1,
            color: invernadero.color,
            type: "bar"
          }
          return serie;
        })
        // resultClientes = seriesSobrantes.concat(resultClientes).concat(seriesClientes).flat().filter(el=>el.sumaPresentacion>0);
        resultClientes = resultClientes.concat(seriesClientes).concat(seriesProductos).flat().filter(el => el.sumaPresentacion > 0);
        return resultClientes
      } else {
        return [];
      }


    } catch (error) {
      //console.log("ERROR AL PROCESAR ORDENES");
      //console.log(error);
    }


  }
  const procesarMultiCiclo = (cicloPassedIn, cicloNum) => {
    try {
      let ordenes = cicloNum === 1 ? ordenesOrigCiclo1 : ordenesOrigCiclo2;

      let clientesIds = filtroCliente.map(el => el.id);
      //let invernaderos = obtenerOpcionesInvernaderos(); 
      let invernaderos = cicloNum === 1 ? ciclo1Invernadero.slice() : ciclo2Invernadero.slice();
      let invernaderosIds = cicloNum === 1 ? ciclo1Invernadero.map(el => el.id) : ciclo2Invernadero.map(el => el.id)

      let variedades = filtroVariedad.slice();
      let variedadesIds = variedades.map(el => el.id);

      let idsProductos = filtroProducto.map(el => el.id);
      let filtradas = presentaciones.filter(el => idsProductos.includes(el.producto_ref));
      filtradas = filtradas.filter(el => clientesIds.includes(el.cliente_ref));

      let seriesClientes = [];
      let listaSemanas = obtenerListaDeSemanas(cicloPassedIn.semana_inicio, cicloPassedIn.semana_cierre);

      let seriesProductos = obtenerSeriesProductos(listaSemanas, ordenes, filtradas, invernaderosIds, variedadesIds, clientesIds, invernaderos);
      let resultClientes = filtroCliente.map(cliente => {
        return obtenerResultCliente(cliente, listaSemanas, ordenes, filtradas,
          invernaderosIds, variedadesIds, seriesClientes, invernaderos)
        // return resultProducto;


      })

      let filtro = cicloNum === 1 ? ciclo1Invernadero : ciclo2Invernadero
      let sobrantesCic = cicloNum === 1 ? sobrantesCiclo1 : sobrantesCiclo2
      let seriesSobrantes = filtro.map(invernadero => {
        let sobrante = sobrantesCic.find(el => el.idInvernadero === invernadero.id)
        if (sobrante) {
          sobrante = sobrante.sobrantes.map(el => el.sobrante)
        }
        let serie = {
          name: `Sobrante ${invernadero.nombre}`,
          tipo: "sobrante",
          data: sobrante,
          sumaPresentacion: 1,
          color: invernadero.color,
          type: "bar"
        }
        return serie;
      })
      // resultClientes = seriesSobrantes.concat(resultClientes).concat(seriesClientes).flat().filter(el=>el.sumaPresentacion>0);
      resultClientes = resultClientes.concat(seriesClientes).concat(seriesProductos).flat().filter(el => el.sumaPresentacion > 0);
      return resultClientes



    } catch (error) {
      //console.log("ERROR AL PROCESAR ORDENES");
      //console.log(error);
    }


  }
  const obtenerSeriesProductos = (listaSemanas, ordenes, filtradas, invernaderosIds, variedadesIds, clientesIds, invernaderosPassed = invernaderos) => {
    let seriesProductos = filtroProducto.map(producto => {
      return obtenerSerieProducto(producto, listaSemanas, ordenes, filtradas, invernaderosIds, variedadesIds, clientesIds, invernaderosPassed)
    })
    return seriesProductos
  }
  const obtenerSerieProducto = (producto, listaSemanas, ordenes, filtradas, invernaderosIds, variedadesIds, clientesIds, invernaderosPassed = invernaderos) => {
    let sumaProducto = 0;
    let dataClientes = listaSemanas.map(semana => {
      let dias = obtenerDiasDeSemana(semana.time);
      let suma = 0;

      ordenes.forEach(orden => {
        if (clientesIds.includes(orden.cliente_ref)) {
          orden.items.forEach(item => {
            if (item.producto_ref === producto.id) {
              if (parseInt(orden.fecha_envio) >= dias[0] && parseInt(orden.fecha_envio) <= dias[5]) {
                let presentacion = filtradas.find(el => el.id === item.presentacion_ref);
                if (presentacion) {
                  let invernadero = invernaderosPassed.find(el => el.id === item.invernadero_ref);
                  if (invernadero && invernaderosIds.includes(invernadero.id) && (variedadesIds.includes(invernadero.variedad_ref) || variedadesIds.includes(invernadero.producto_ref))) {
                    let peso_caja = parseFloat(presentacion.peso_neto_caja);
                    let cajas_orden = parseFloat(item.cajas_orden)
                    suma += peso_caja * cajas_orden;
                    sumaProducto += peso_caja * cajas_orden;
                  }
                }
              }
            }
          })
        }

      })
      return suma;
    })
    let serieTotal = {
      name: `Total ${producto.nombre}`,
      sumaPresentacion: sumaProducto,
      shortname: producto.nombre,
      data: dataClientes,
      tipo: "producto"

    }
    return serieTotal;
  }


  const obtenerResultCliente = (cliente, listaSemanas, ordenes, filtradas,
    invernaderosIds, variedadesIds, seriesClientes, invernaderosPassed = invernaderos) => {
    let [sumaCliente, dataClientes] = obtenerSumaClienteYPorSemana(listaSemanas, ordenes, cliente, filtradas, invernaderosIds, variedadesIds, invernaderosPassed);

    let serieTotal = {
      name: `Total ${cliente.nombre}`,
      sumaPresentacion: sumaCliente,
      shortname: cliente.nombre_comun,
      data: dataClientes

    }
    seriesClientes.push(serieTotal);
    let resultProducto = filtroProducto.map(producto => {
      let sumaProducto = 0;
      let data = listaSemanas.map(semana => {

        let dias = obtenerDiasDeSemana(semana.time);
        let suma = 0;

        ordenes.forEach(orden => {
          if (orden.cliente_ref === cliente.id) {
            orden.items.forEach(item => {
              if (item.producto_ref === producto.id || (producto.mezcla && getObjetoPorID(productosYVariedadesTodos, item.producto_ref).mezcla) || (producto.mezcla && getObjetoPorID(productosYVariedadesTodos, item.producto_ref).MEDLEYS_GENERAL)) {
                if (parseInt(orden.fecha_envio) >= dias[0] && parseInt(orden.fecha_envio) <= dias[5]) {
                  let presentacion = filtradas.find(el => el.id === item.presentacion_ref);
                  if (presentacion) {
                    let invernadero = invernaderosPassed.find(el => el.id === item.invernadero_ref);
                    if (invernadero && invernaderosIds.includes(invernadero.id) && (variedadesIds.includes(invernadero.variedad_ref) || variedadesIds.includes(invernadero.producto_ref))) {
                      let peso_caja = parseFloat(presentacion.peso_neto_caja);
                      let cajas_orden = parseFloat(item.cajas_orden)
                      suma += peso_caja * cajas_orden;
                      sumaProducto += peso_caja * cajas_orden;
                    }
                  }
                }
              }
            })
          }

        })
        return suma;
      })

      return {
        name: `${cliente.nombre} - ${producto.nombre}`,
        nameCliente: cliente.nombre,
        nameProducto: producto.nombre,
        shortname: cliente.nombre_comun,
        data: data,
        sumaPresentacion: sumaProducto
      }
    })
    return resultProducto
  }
  const obtenerSumaClienteYPorSemana = (listaSemanas, ordenes, cliente, filtradas, invernaderosIds, variedadesIds, invernaderosPassed = invernaderos) => {
    let sumaCliente = 0;
    let dataClientes = listaSemanas.map(semana => {

      let dias = obtenerDiasDeSemana(semana.time);
      let suma = 0;

      ordenes.forEach(orden => {
        if (orden.cliente_ref === cliente.id) {
          orden.items.forEach(item => {
            if (parseInt(orden.fecha_envio) >= dias[0] && parseInt(orden.fecha_envio) <= dias[5]) {
              let presentacion = filtradas.find(el => el.id === item.presentacion_ref);
              if (presentacion) {
                let invernadero = invernaderosPassed.find(el => el.id === item.invernadero_ref);
                if (invernadero && invernaderosIds.includes(invernadero.id) && (variedadesIds.includes(invernadero.variedad_ref) || variedadesIds.includes(invernadero.producto_ref))) {
                  let peso_caja = parseFloat(presentacion.peso_neto_caja);
                  let cajas_orden = parseFloat(item.cajas_orden)
                  suma += peso_caja * cajas_orden;
                  sumaCliente += peso_caja * cajas_orden;
                }
              }
            }

          })
        }

      })
      return suma;
    })
    return [sumaCliente, dataClientes]
  }


  function obtenerOptions(params) {
    if (cicloObj) {
      let copia = { ...options };
      copia["colors"] = [];
      copia["chart"] = { ...options["chart"] };
      copia["xaxis"] = { ...copia["xaxis"] };
      copia["chart"]["stackType"] = "normal";
      copia["dataLabels"]["enabled"] = true;
      let listaSemanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
      copia["xaxis"]["categories"] = listaSemanas.map(el => el.nombreCompacto);
      //copia["xaxis"]["tickPlacement"] = "between";
      data.forEach(serie => {
        if (serie.tipo === "sobrante") {
          copia["colors"].push(stringToColor(serie.color))
        } else if (serie.tipo === "producto") {
          copia["colors"].push(stringToColor("gray"));
        }
        else {

          copia["colors"].push(paletaClientes[serie.shortname]);
        }
      })
      return copia;
    } /* else {
      return { ...options };
    } */

  }

  const obtenerOptionsMultiCiclo1 = () => {
    let copia = { ...options };
    copia["colors"] = [];
    copia["chart"] = { ...options["chart"] };
    copia["xaxis"] = { ...copia["xaxis"] };
    copia["chart"]["stackType"] = "normal";
    copia["dataLabels"]["enabled"] = true;
    let listaSemanas = obtenerListaDeSemanas(ciclo1.semana_inicio, ciclo1.semana_cierre);
    copia["xaxis"]["categories"] = listaSemanas.map(el => el.nombreCompacto);

    dataCiclo1.forEach(serie => {
      if (serie.tipo === "sobrante") {
        copia["colors"].push(stringToColor(serie.color))
      } else if (serie.tipo === "producto") {
        copia["colors"].push(stringToColor("gray"));
      }
      else {

        copia["colors"].push(paletaClientes[serie.shortname]);
      }
    })

    return copia;
  }
  const obtenerOptionsMultiCiclo2 = () => {
    let copia = { ...options };
    copia["colors"] = [];
    copia["chart"] = { ...options["chart"] };
    copia["xaxis"] = { ...copia["xaxis"] };
    copia["chart"]["stackType"] = "normal";
    copia["dataLabels"]["enabled"] = true;
    let listaSemanas = obtenerListaDeSemanas(ciclo2.semana_inicio, ciclo2.semana_cierre);
    copia["xaxis"]["categories"] = listaSemanas.map(el => el.nombreCompacto);

    dataCiclo2.forEach(serie => {
      if (serie.tipo === "sobrante") {
        copia["colors"].push(stringToColor(serie.color))
      } else if (serie.tipo === "producto") {
        copia["colors"].push(stringToColor("gray"));
      }
      else {

        copia["colors"].push(paletaClientes[serie.shortname]);
      }
    })

    return copia;
  }

  const mostrarVariedadesInput = () => {
    if (hayMedleysViejo(filtroInvernadero)) { return true; }

    const soloMedleysGeneralSeleccionado = filtroProducto?.length === 1 && filtroProducto[0].MEDLEYS_GENERAL;
    if (soloMedleysGeneralSeleccionado) { return false; }
    else { return true; }
  }


  const atras = (params) => {
    props.history.goBack();
  }

  const dropDownClientesValue = filtroCliente[0]
  const dropDownProductosValue = filtroProducto[0]
  
  return <div className="p-grid chart-page">
    <div className="p-col  p-col-auto title-container">
      <span className="back-btn" onClick={atras} ></span>
      <span className={`icon-general chart-icon `}></span>
      <span className="titles-group">
        <h1 className="header-title">Distribución de clientes por semana</h1>
      </span>
    </div>

    <div className="p-col-12">

      <SeccionFiltros>
        <ContenedorFiltro label="Tipo de gráfica">
          <SelectButton options={tiposGraficas} value={tipoGrafica} onChange={e => setTipoGrafica(e.value)} />
        </ContenedorFiltro>

        <ContenedorFiltro label="Clientes">
          {showFiltroMultiClientes || <Dropdown options={clientesOrig} value={dropDownClientesValue} onChange={e => setFiltroCliente([e.value])}
            dataKey="id" optionLabel="nombre"/>}

          {showFiltroMultiClientes && <MultiSelect options={clientesOrig} value={filtroCliente} onChange={e => setFiltroCliente(e.value)}
            selectedItemsLabel={`${filtroCliente.length} clientes seleccionados`}
            dataKey="id" optionLabel="nombre" />}
        </ContenedorFiltro>

        <ContenedorFiltro label="Productos">
          {showFiltroMultiProductos || <Dropdown options={productosBases} value={dropDownProductosValue} onChange={e => setFiltroProducto([e.value])}
            dataKey="id" optionLabel="nombre"/>}
          {showFiltroMultiProductos && <MultiSelect options={productosBases} value={filtroProducto} onChange={e => setFiltroProducto(e.value)}
            dataKey="id" optionLabel="nombre"
            selectedItemsLabel={`${filtroProducto.length} productos seleccionados`} />}
        </ContenedorFiltro>

        {!multiCiclo && <ContenedorFiltro label="Invernaderos">
          <MultiSelect options={obtenerOpcionesInvernaderos()} value={filtroInvernadero} onChange={e => setFiltroInvernadero(e.value)}
            selectedItemsLabel={`${filtroInvernadero.length} invernaderos seleccionados`}
            dataKey="id" optionLabel="nombre" />
        </ContenedorFiltro>}

        {mostrarVariedadesInput() &&
          <ContenedorFiltro label="Variedades">
            <MultiSelect options={obtenerOpcionesVariedades()} value={filtroVariedadMeldeysAgrupado} onChange={e => setFiltroVariedadMeldeysAgrupado(e.value)}
              selectedItemsLabel={`${filtroVariedadMeldeysAgrupado.length} variedades seleccionadas`}
              dataKey="id" optionLabel="nombre" />
          </ContenedorFiltro>}

        <ContenedorFiltro label="Multi Ciclo">
          <ToggleButton className="reactbtn-icon" onLabel="Multiciclo" offLabel="Multiciclo" checked={multiCiclo} onChange={(ev) => setMultiCiclo(ev.value)} ></ToggleButton>
        </ContenedorFiltro>
        {multiCiclo && <ContenedorFiltro label="ciclo 1">
          <Dropdown value={ciclo1} options={allCiclos} onChange={(e) => setCiclo1(e.target.value)} dataKey="id" optionLabel="nombre" />
        </ContenedorFiltro>}
        {multiCiclo && ciclo1 !== "" && <ContenedorFiltro label={"invernadero " + ciclo1.nombre}>
          <MultiSelect value={ciclo1Invernadero} options={ciclo1.invernaderos} onChange={(e) => setCiclo1Invernadero(e.target.value)} dataKey="id" optionLabel="nombre"
            selectedItemsLabel={`${ciclo1Invernadero.length} invernaderos seleccionados`} />
        </ContenedorFiltro>}

        {multiCiclo && <ContenedorFiltro label="ciclo 2">
          <Dropdown value={ciclo2} options={allCiclos} onChange={(e) => setCiclo2(e.target.value)} dataKey="id" optionLabel="nombre" />
        </ContenedorFiltro>}
        {multiCiclo && ciclo2 !== "" && <ContenedorFiltro label={"invernadero " + ciclo2.nombre}>
          <MultiSelect value={ciclo2Invernadero} options={ciclo2.invernaderos} onChange={(e) => setCiclo2Invernadero(e.target.value)} dataKey="id" optionLabel="nombre"
            selectedItemsLabel={`${ciclo2Invernadero.length} invernaderos seleccionados`} />
        </ContenedorFiltro>}
      </SeccionFiltros>
    </div>
    {!multiCiclo && <div className="p-col-12 ">
      {optsGraficaNormal["xaxis"] && optsGraficaNormal["xaxis"]["categories"].length > 0 && data.length > 0 ?
        tipoGrafica === "cien" ? <Grafica100 options={optsGraficaNormal} series={data} /> : <GraficaNormal key={optsGraficaNormal?.key || "normal"} options={optsGraficaNormal} series={data} />
        :
        null}
    </div>}
    {multiCiclo && ciclo1Invernadero.length > 0 && <div className="p-col-12 ">
      {obtenerOptionsMultiCiclo1()["xaxis"] && obtenerOptionsMultiCiclo1()["xaxis"]["categories"].length > 0 && dataCiclo1.length > 0 ?
        tipoGrafica === "cien" ? <Grafica100  options={obtenerOptionsMultiCiclo1()} series={dataCiclo1} /> : <GraficaNormal options={obtenerOptionsMultiCiclo1()} series={dataCiclo1} />
        :
        null}
    </div>}
    {multiCiclo && ciclo2Invernadero.length > 0 && <div className="p-col-12 ">
      {obtenerOptionsMultiCiclo2()["xaxis"] && obtenerOptionsMultiCiclo2()["xaxis"]["categories"].length > 0 && dataCiclo2.length > 0 ?
        tipoGrafica === "cien" ? <Grafica100 options={obtenerOptionsMultiCiclo2()} series={dataCiclo2} /> : <GraficaNormal options={obtenerOptionsMultiCiclo2()} series={dataCiclo2} />
        :
        null}
    </div>}

  </div>

}
const GraficaNormal = (props) => {
  return <Chart key={props.key} options={props.options} series={props.series} type="area" />;
}

const Grafica100 = (props) => {
  return <Chart options={props.options} series={props.series} type="area" height={1200} />;
}


export default DistribucionPresentacionesPorSemana;

