import React, { useEffect, useState, useGlobal } from "reactn";
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { obtenerEnfermedadesYPlagas, guardarProductoAplicacion, obtenerProductoAplicacion } from '../../service/biocontrol';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { noHaSidoClausurado } from '../../service/Ciclos';
import { DeepClone, getNombrePorID } from "../../util/functions";
import ContenedorHeader from "../common/ContenedorHeader";
import { MultiSelect } from "primereact/multiselect";
import { usePrimeReactTable } from "../common/tableHandler";
import { Message } from 'primereact/message';
import { faAllergies } from "@fortawesome/free-solid-svg-icons";

const FormularioProducto = (props) => {
    const inicial = {
        nombre: "",
        i_a: "",
        i_s: "",
        rsco_epa: "",
        t_r: "",
        recomendacion_dosis: "",
        enfermedades: [],
        fecha_creacion: ""
    }
    let inicialDosis = [
        { nombre: "bajo", valor: "", d400: "", d500: "", d600: "", letra: "b" },
        { nombre: "medio", valor: "", d400: "", d500: "", d600: "", letra: "m" },
        { nombre: "alto", valor: "", d400: "", d500: "", d600: "", letra: "a" }
    ]
    const [editorGeneratorDosis, currentDosis, setCurrentDosis] = usePrimeReactTable(DeepClone(inicialDosis), () => { })
    const { productoID } = props.match.params
    const [, setCargando] = useGlobal("cargando")
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [usuario] = useGlobal("usuario");
    const [ciclo] = useGlobal("ciclo")
    const [ciclos] = useGlobal("ciclos")
    const [state, setState] = useState(DeepClone(inicial))
    const [enfermedades] = useGlobal("enfermedadesYPlagas")
    const [errores, setErrores] = useState([])
    const [editing] = useState(productoID ? true : false)

    const puedeEditar = () => noHaSidoClausurado(ciclo, ciclos) && (usuario.rol === "Administrador" || usuario.rol === "Grower")

    useEffect(() => {
        setNombreModulo("Biocontrol")
        setHabilitadoSelectorCiclo(false);
        obtenerDatosIniciales()
    }, [])
    useEffect(() => {
        setErrores([])
    }, [state, currentDosis]);

    const obtenerDatosIniciales = async () => {
        setCargando(true)
        if (!enfermedades) await obtenerEnfermedadesYPlagas()

        if (editing) {
            let producto = await obtenerProductoAplicacion(productoID)
            if (producto)
                procesarDatosProducto(producto)
            else
                props.history.replace("biocontrol/productos/crear")
        }
        setCargando(false)
    }
    const procesarDatosProducto = (producto) => {
        let procesarDosisInfo = producto.dosis.map((dos, ind) =>
            ({ ...dos, d400: dos.detalles[0].valor, d500: dos.detalles[1].valor, d600: dos.detalles[2].valor, letra: inicialDosis[ind].letra }))
        setCurrentDosis(procesarDosisInfo)
        setState(producto)
    }

    const handleInputChange = (event) => {
        let stateCopy = DeepClone(state)
        stateCopy[event.target.name] = event.target.value
        setState(stateCopy)
    }

    const guardar = async () => {
        if (validar()) {
            setCargando(true)
            try {
                let datos = {
                    ...state,
                    dosis: procesarDosis(),
                    fecha_creacion: editing ? (state.fecha_creacion || 1618001679001) : Date.now()
                }
                await guardarProductoAplicacion(datos, productoID)
                setCargando(false)
                props.history.goBack()
            }
            catch (error) {
                console.log("ERROR GUARDAR: ", error);
                setCargando(false)
            }
        }
    }
    const procesarValor = (valor) => parseFloat(parseFloat(valor).toFixed(4))
    const procesarDosis = () => currentDosis.map(dos => ({
        nombre: dos.nombre, valor: dos.valor,
        detalles: [
            { nombre: `d400${dos.letra}`, valor: procesarValor(dos.d400) },
            { nombre: `d500${dos.letra}`, valor: procesarValor(dos.d500) },
            { nombre: `d600${dos.letra}`, valor: procesarValor(dos.d600) },
        ],
    }))
    const validar = () => getErroresFormulario().length === 0
    const getErroresFormulario = () => {
        let erroreStateNombres = ["nombre"]
        let errores = erroreStateNombres.filter(nombre => !state[nombre])
        if (state.enfermedades.length === 0) { errores.push("enfermedades") }

        currentDosis.forEach(dosis => {
            for (const key in dosis) {
                if (!dosis[key] && dosis[key] !== 0) { errores.push("dosis") }
            }
        })

        setErrores(errores)
        return errores;
    }

    return (
        <div className="p-grid p-justify-center">
            <ContenedorHeader col="p-col p-col-auto" titulo={`${editing ? "Editar" : "Crear"} producto de aplicación`}
                subtituloInv={editing ? state.nombre : ""} iconos={"icon-general bio-icon"} atras={props.history.goBack} />

            <div className="p-col-12">
                <p className="section-title">Información general</p>
                <div className="card">
                    <div className="p-grid ">
                        <div className="form-group p-col-12 p-md-4">
                            <label htmlFor="nombre">Nombre</label>
                            <InputText id="nombre" name="nombre" disabled={!puedeEditar()} value={state.nombre} onChange={handleInputChange} />
                            {errores.includes("nombre") && <Message severity="error" text="Este campo es requerido" />}
                        </div>
                        <div className="form-group p-col-12 p-md-4">
                            <label htmlFor="i_a">i.a</label>
                            <InputText id="i_a" name="i_a" disabled={!puedeEditar()} value={state.i_a} onChange={handleInputChange} />
                        </div>
                        <div className="form-group p-col-12 p-md-4">
                            <label htmlFor="i_s">i.s</label>
                            <InputText id="i_s" name="i_s" disabled={!puedeEditar()} value={state.i_s} onChange={handleInputChange} />
                        </div>
                        <div className="form-group p-col-12 p-md-4">
                            <label htmlFor="rsco_epa">rsco_epa</label>
                            <InputText id="rsco_epa" name="rsco_epa" disabled={!puedeEditar()} value={state.rsco_epa} onChange={handleInputChange} />
                        </div>
                        <div className="form-group p-col-12 p-md-4">
                            <label htmlFor="t_r">Tiempo reentrada</label>
                            <InputText id="t_r" name="t_r" disabled={!puedeEditar()} value={state.t_r} onChange={handleInputChange} />
                        </div>
                        <div className="form-group p-col-12 p-md-4">
                            <label htmlFor="recomendacion_dosis">Recomendación dosis</label>
                            <InputText id="recomendacion_dosis" name="recomendacion_dosis" disabled={!puedeEditar()} value={state.recomendacion_dosis} onChange={handleInputChange} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="p-col-12">
                <p className="section-title">Enfermedades</p>
                <div className="card">
                    <div className="p-grid ">
                        <div className="form-group p-col-12 p-md-6">
                            <label htmlFor="nombre">Enfermedades a atacar</label>
                            <MultiSelect id="nombre" name="enfermedades" optionValue="id" optionLabel="nombre" filter={true}
                                selectedItemsLabel={`${state.enfermedades?.length} enfermedades seleccionadas`} disabled={!puedeEditar()}
                                options={enfermedades} value={state.enfermedades} onChange={handleInputChange} />
                        </div>
                        {state.enfermedades.length > 0 &&
                            <div className="form-group p-col-12 ">
                                <label >Enfermedades seleccionadas: </label>
                                {state.enfermedades.map(el => getNombrePorID(enfermedades, el)).join(", ")}
                            </div>}
                    </div>
                    {errores.includes("enfermedades") && <Message severity="error" text="Debes seleccionar al menos un enfermedad" />}
                </div>
            </div>

            <div className="p-col-12">
                <p className="section-title">Detalle dosis</p>
                <div className="card p-0">
                    <DataTable value={currentDosis}>
                        <Column field="nombre" header="" />
                        <Column field="valor" header="Valor" editor={(props) => { return puedeEditar() ? editorGeneratorDosis(props, "valor") : "No editable" }} />
                        <Column field="d400" header="d400" editor={(props) => { return puedeEditar() ? editorGeneratorDosis(props, "d400") : "No editable" }} />
                        <Column field="d500" header="d500" editor={(props) => { return puedeEditar() ? editorGeneratorDosis(props, "d500") : "No editable" }} />
                        <Column field="d600" header="d600" editor={(props) => { return puedeEditar() ? editorGeneratorDosis(props, "d600") : "No editable" }} />
                    </DataTable>
                </div>
                {errores.includes("dosis") && <Message severity="error" text="Todos los campos requieren un valor" />}
            </div>

            <div className="p-col-12">
                <div className="p-grid p-justify-end btn-row">
                    <Button label="Cancelar" onClick={props.history.goBack} />
                    <Button disabled={!puedeEditar()} label="Guardar producto" onClick={guardar} />
                </div>
            </div>
        </div>
    );
}
export default FormularioProducto;