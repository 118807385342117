import React, { useState, useGlobal, useEffect } from 'reactn';
import { withRouter } from 'react-router-dom';
import { dosDecimales } from '../../util/functions';
import HTGCard from '../common/cards/HTGCard';
import Column from '../common/cards/Column';
import DatoCard from '../common/cards/DatoCard';
import ClipLoader from 'react-spinners/ClipLoader';
import CardHeader from '../common/cards/CardHeader';

const InvernaderoCard = (props) => {
  const [, setInvernadero] = useGlobal("invernadero");
  const [, setAviso] = useState(true);
  const [usuario,] = useGlobal("usuario");
  useEffect(() => {
    if (props.invernadero?.budget && props.invernadero?.budget?.estimacion_semanal?.length > 0) setAviso(false);
  }, [])
  const puedeConsultarRegistro = () => usuario &&
    ["Administrador", "Personal Administrativo", "Grower", "Administrador Empaque", "Auxiliar Empaque", "Administrador Calidad",
      "Administrador Embarques", "Auxiliar Embarques", "Auxiliar Grower", "Auxiliar Calidad", "Administrador Seguridad","Auxiliar Seguridad", "Finanzas"].includes(usuario.rol);

  const consultar = () => {
    if (puedeConsultarRegistro()) {
      setInvernadero(props.invernadero);
      props.history.push("/cosecha/consultar");
    }
  }
  const header = <CardHeader
    invernaderoVirtual={props.invernadero}
    habilitadoTexto={props.invernadero?.habilitado ? "" : "Deshabilitado"}
    nombreInvernaderoFisico={props.invernadero?.invernaderoFisico?.nombre || ""} />  
  let className = `htg-card bor-${props.invernadero?.color} ${props.invernadero?.habilitado ? "Habilitado" : "Deshabilitado"}`
  return (
    <HTGCard header={header} onClick={consultar} headerClassName={className} >
      {props.home && props.cargandoHome
        ? <div className="card-spinner-centered">
          <ClipLoader sizeUnit="px" size={100} color="#003E66" loading={true} />
        </div>
        : <>
          <Column>
            <DatoCard label="kg/m² Semanal" valor={dosDecimales(props.invernadero?.semanal)} />
          </Column>
          <Column>
            <DatoCard label="kg/m² Real" valor={dosDecimales(props.invernadero?.semanalReal)} />
          </Column>
          <Column>
            <DatoCard label="Real vs. Semanal" valor={`${(isNaN(props.invernadero?.realvssemanal) || props.invernadero?.realvssemanal === Infinity) ? "-" : Math.round(100 * props.invernadero?.realvssemanal)} %`} />
          </Column>
          <Column>
            <DatoCard label="Real vs. Diario" valor={`${(isNaN(props.invernadero?.realvsdiario) || props.invernadero?.realvsdiario === Infinity) ? "-" : Math.round(100 * props.invernadero?.realvsdiario)} %`} />
          </Column>
        </>}
    </HTGCard>);
}
export default withRouter(InvernaderoCard);//68