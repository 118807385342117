import * as firebase from "firebase/app";
import "firebase/firestore";
import {uploadStatistics} from './Estadisticas'

function registrarInvocaciones(count){
    uploadStatistics(count)
}

export const obtenerGruposPresentacionesOrdenadas = async (params) => {
    const db = firebase.firestore();
    let snap = await db.collection("grupos_presentaciones").orderBy('nombre').get();
    registrarInvocaciones(snap.size)
    return snap.docs.map(el=>({id:el.id, ...el.data()}))
}

export const obtenerGruposPresentaciones = async (params) => {
    const db = firebase.firestore();
    let snap = await db.collection("grupos_presentaciones").get();
    registrarInvocaciones(snap.size)
    return snap.docs.map(el=>({id:el.id, ...el.data()}))
}

export const obtenerGruposPresentacion = async (grupoPresentacionID) => {
    const db = firebase.firestore();
    const obj = await db.collection("grupos_presentaciones").doc(grupoPresentacionID).get();
    registrarInvocaciones(obj.size)
    const data = { id: obj.id, ...obj.data() };
    return data
}

export const registrarGrupoPresentaciones = (nombre, idsPresentaciones, cliente_ref, producto_ref, mezcla = false) => {
    const datos = {nombre, presentaciones_refs: idsPresentaciones, cliente_ref, producto_ref};
    if (mezcla) { datos.mezcla = true }
    const result = firebase.firestore().collection("grupos_presentaciones").add(datos);
    return result;
}
export const actualizarGrupoPresentaciones = (idGrupoPresentaciones, nombre, idsPresentaciones, cliente_ref, producto_ref, mezcla = false) => {
    const datos = {nombre, presentaciones_refs:idsPresentaciones, cliente_ref, producto_ref, mezcla}
    let result = firebase.firestore().collection("grupos_presentaciones").doc(idGrupoPresentaciones).update(datos);
    return result;
}

export const obtenerBudgetGrupoPresentaciones = async(cicloId, idGrupoPresentaciones) => {
    const db = firebase.firestore();
    
    let snap = await db.collection("ciclos").doc(cicloId).collection("budgets_presentaciones").where("grupo_presentaciones_ref","==",idGrupoPresentaciones).get();
    registrarInvocaciones(snap.size)
    if(snap.empty){
        return {empty:true}
    }else{
        let doc =  snap.docs[0];
        return {id:doc.id, ...doc.data(),empty:false}
    }
}
export const guardarBudgetPresentaciones = async(cicloId,idGrupoPresentaciones, inicio, final, budget) => {
    try {        
        const db = firebase.firestore();
        let grupoPresentaciones= await obtenerBudgetGrupoPresentaciones(cicloId, idGrupoPresentaciones);
        let obj = {}
        obj["inicial"] = inicio.time;
        obj["final"] = final.time;
        obj["grupo_presentaciones_ref"] = idGrupoPresentaciones;
        obj["budget"] = budget;
        if(grupoPresentaciones.empty){
            let result = await db.collection("ciclos").
                doc(cicloId).collection("budgets_presentaciones").add(obj);
        }else{
            let result = await db.collection("ciclos").
                doc(cicloId).collection("budgets_presentaciones").doc(grupoPresentaciones.id).update(obj);
        }
        
        return true;
    } catch (error) {
        throw error;
    }
}

export const obtenerGrupoPresentacionesConPresentacion = async (presentacionId) => {
    const db = firebase.firestore();
    let snap = await db.collection("grupos_presentaciones").where("presentaciones_refs","array-contains",presentacionId).get();
    registrarInvocaciones(snap.size)
    if(snap.empty){
        return {empty:true}        
    }else{
        let doc = snap.docs[0];
        return {id:doc.id, ...doc.data()}
    }
}
export const obtenerBudgetsGruposPresentacionesCiclo = async (cicloId) => {
    const db = firebase.firestore();
    
    let snap = await db.collection("ciclos").doc(cicloId).collection("budgets_presentaciones").get();
    registrarInvocaciones(snap.size)
    
        
        return snap.docs.map(el=>{
            return {id:el.id, ...el.data()}
        }) 
    
}
