import React, { useState, useGlobal, useEffect } from "reactn";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { dosDecimales, getProductoYVariedadString, separadoComas } from "../../util/functions";
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';

import { Button } from "primereact/button";
import { obtenerDatosCardsExternos, obtenerReportePronosticos, obtenerReportePronosticosExternos } from "../../service/Pronosticos";
import { segundosSemana } from "../../constants";
import { obtenerInicioSemanaDia, obtenerNombreSemanaCompacto } from "../../service/fechas";
import ContenedorFiltro from "../common/ContenedorFiltro";
import { SelectButton } from "primereact/selectbutton";
import SeccionFiltros from "../common/SeccionFiltros";
import icono from '../../images/icons/external-icon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const opcionesPeriodo = [
    { label: "Real", value: "real" },
    { label: "Diario", value: "diario" },
    { label: "Semanal", value: "semanal" },
]
export default function ReportePronosticos(props) {
    const [ciclo,] = useGlobal("ciclo");
    const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector(true);
    const [, setWeekGlobal] = useGlobal("weekGlobal");
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [, setInvernadero] = useGlobal("invernadero");
    const [tipoPeriodo, setTipoPeriodo] = useState(opcionesPeriodo[0]["value"])
    const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
    const [invernaderos, setInvernaderos] = useGlobal("invernaderosInternos");
    const [invernaderosExternos] = useGlobal("invernaderosExternos");
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [, setCargando] = useGlobal("cargando");
    const [tabla, setTabla] = useState([]);
    const [tablaExterno, setTablaExterno] = useState([]);

    const atras = (params) => {
        props.history.goBack();
    }
    useEffect(() => {
        setNombreModulo("Pronósticos");
        setHabilitadoSelectorCiclo(true);
    }, [])

    useEffect(() => {
        if (invernaderos) {
            //getDatosIniciales()
        }
    }, [invernaderos])

    useEffect(() => {
        if (ciclo && week.nombre !== "Cargando") {
            actualizarDatos(true)
        }
    }, [ciclo, week])

    //const getDatosIniciales = async () => { }

    const actualizarDatos = async (reload = false) => {
        setCargando(true);
        let [respInternos, respExternos] = await Promise.all([
            obtenerReportePronosticos(ciclo, week.time, invernaderos),
            obtenerDatosCardsExternos(invernaderosExternos)]) // await obtenerReportePronosticosExternos(ciclo, week.time, invernaderosExternos);

        //let respExternos = await obtenerDatosCardsExternos(invernaderosExternos);
        procesarReporte(respInternos);

        await procesarReporteExterno(invernaderosExternos, respExternos);
        setCargando(false);

    }
    const procesarReporteExterno = async (invernaderosExternos = [], reportesExternos = [], reload = false) => {
        let filasExternos = [];
        if (invernaderosExternos?.length === 0 || reportesExternos?.length === 0) { return filasExternos; }
        //console.log("procesarReporteExterno PRON EXTERNOS: ", reportesExternos);

        for (const index in reportesExternos) {
            const reporteInvExterno = reportesExternos[index];

            let pronsSumas = [0, 0, 0, 0, 0,]
            let pronsLength = [0, 0, 0, 0, 0,]
            let semanalSuma = 0, semanalLength = 0;
            let diarioSuma = 0, diarioLength = 0;

            let acc3DiarioSuma = 0, acc3DiarioLength = 0;
            let acc5DiarioSuma = 0, acc5DiarioLength = 0;

            let acc3SemanalSuma = 0, acc3SemanalLength = 0;
            let acc5SemanalSuma = 0, acc5SemanalLength = 0;

            console.log("----------: ", reporteInvExterno.arrayProveedoresProyeccionDatos)
            reporteInvExterno.arrayProveedoresProyeccionDatos.forEach(prov => {
                if (!prov.captura?.isEmpty && prov?.captura?.pronosticos) {
                    prov.captura.pronosticos.forEach((pronSemana, index) => {
                        //pronosticos
                        let floatPronSemana = parseFloat(pronSemana || 0);
                        if (!isNaN(floatPronSemana) && floatPronSemana) {
                            pronsSumas[index] += floatPronSemana;
                            pronsLength[index]++;
                        }
                    })
                }

                console.log("datos PROV: ", { semanal: prov.semanal, diario: prov.diario, accThreeDiario: prov.semanaTresDiario })

                //semanal
                let floatSemanal = parseFloat(prov.semanal || 0);
                if (!isNaN(floatSemanal) && floatSemanal) {
                    semanalSuma += floatSemanal;
                    semanalLength++;
                    //console.log("SEM: ",{dato: prov.semanal,semanalSuma})

                }

                //diario
                let floatDiario = parseFloat(prov.diario || 0);
                if (!isNaN(floatDiario) && floatDiario) {
                    diarioSuma += floatDiario;
                    diarioLength++;
                }

                //acc3diario
                let floatacc3Diario = parseFloat(prov.semanaTresDiario || 0);
                if (!isNaN(floatacc3Diario) && floatacc3Diario) {
                    acc3DiarioSuma += floatacc3Diario;
                    acc3DiarioLength++;
                }
                //acc5diario
                let floatacc5Diario = parseFloat(prov.semanaCincoDiario || 0);
                if (!isNaN(floatacc5Diario) && floatacc5Diario) {
                    acc5DiarioSuma += floatacc5Diario;
                    acc5DiarioLength++;
                }

                //
                //acc3Semanal
                let floatacc3Semanal = parseFloat(prov.semanaTresSemanal || 0);
                if (!isNaN(floatacc3Semanal) && floatacc3Semanal) {
                    acc3SemanalSuma += floatacc3Semanal;
                    acc3SemanalLength++;
                }
                //acc5Semanal
                let floatacc5Semanal = parseFloat(prov.semanaCincoSemanal || 0);
                if (!isNaN(floatacc5Semanal) && floatacc5Semanal) {
                    acc5SemanalSuma += floatacc5Semanal;
                    acc5SemanalLength++;
                }

            })
            const datos = {
                invernadero_id: reporteInvExterno.invernadero.id,
                p0: pronsSumas[0] == 0 ? "-" : pronsSumas[0] / pronsLength[0],
                p1: pronsSumas[1] == 0 ? "-" : pronsSumas[1] / pronsLength[1],
                p2: pronsSumas[2] == 0 ? "-" : pronsSumas[2] / pronsLength[2],
                p3: pronsSumas[3] == 0 ? "-" : pronsSumas[3] / pronsLength[3],
                p4: pronsSumas[4] == 0 ? "-" : pronsSumas[4] / pronsLength[4],

                semanal: semanalSuma == 0 ? "-" : semanalSuma / semanalLength,
                diario: diarioSuma == 0 ? "-" : diarioSuma / diarioLength,

                accThree: reporteInvExterno.semanaTres,
                accThreeDiario: acc3DiarioSuma == 0 ? "-" : acc3DiarioSuma / acc3DiarioLength,
                accThreeSemanal: acc3SemanalSuma == 0 ? "-" : acc3SemanalSuma / acc3SemanalLength,

                accFive: reporteInvExterno.semanaCinco,
                accFiveDiario: acc5DiarioSuma == 0 ? "-" : acc5DiarioSuma / acc5DiarioLength,
                accFiveSemanal: acc5SemanalSuma == 0 ? "-" : acc5SemanalSuma / acc5SemanalLength,

                proyeccion: reporteInvExterno.proyeccion, real: reporteInvExterno.semanaUno,

            }
            //console.log("reporteInvExterno: ", { datos, reporteInvExterno })
            filasExternos.push(datos)

        }

        //console.log(datos)
        setTablaExterno(filasExternos);
    }

    function procesarReporte(resp) {
        let data = []

        resp.forEach(el => {
            let { invernadero, capturas, pronosticosProyecciones } = el;
            let temp = { p1: 0, p2: 0, p3: 0, p4: 0, }
            if (!capturas.isEmpty) {
                temp = {
                    p0: capturas["pronosticos"][0],
                    p1: capturas["pronosticos"][1],
                    p2: capturas["pronosticos"][2],
                    p3: capturas["pronosticos"][3],
                    p4: capturas["pronosticos"][4],
                }
            }
            temp["invernadero"] = invernadero.nombre;
            temp["invernadero_id"] = invernadero.id;
            temp["proyeccion"] = pronosticosProyecciones["proyeccion"]
            temp["real"] = pronosticosProyecciones["real"]
            temp["semanal"] = pronosticosProyecciones["semanal"]
            temp["diario"] = pronosticosProyecciones["diario"]
            temp["accThree"] = pronosticosProyecciones["semanaTres"]
            temp["accFive"] = pronosticosProyecciones["semanaCinco"]

            temp["accThreeDiario"] = pronosticosProyecciones["semanaTresDiario"]
            temp["accFiveDiario"] = pronosticosProyecciones["semanaCincoDiario"]
            temp["accThreeSemanal"] = pronosticosProyecciones["semanaTresSemanal"]
            temp["accFiveSemanal"] = pronosticosProyecciones["semanaCincoSemanal"]
            data.push(temp);
        })
        setTabla(data);
    }
    const templateKgm2 = (rowData, column) => {
        let dato = rowData[column["field"]];
        let datoProcesado = dato || 0;
        if (["proyeccion", "diario", "semanal", "real"].includes(column["field"])) {
            datoProcesado = isNaN(dato) ? "-" : separadoComas(dosDecimales(dato))
        }
        if (["accThree", "accFive"].includes(column["field"])) {
            datoProcesado = isNaN(dato) ? "-" : Math.round(100 * dato)

        }
        return <div>{datoProcesado}<span className="label-unidad">kg/m²</span></div>
    }
    const templatePorcentaje = (rowData, column) => {
        let dato = rowData[column["field"]];
        let datoProcesado = dato;

        if (rowData[column.field] === "accThreeDiario") {
        }
        else if (rowData[column.field] === "accFiveDiario") {
        }
        else if (rowData[column.field] === "accThreeSemanal") {
        }
        else if (rowData[column.field] === "accFiveSemanal") {
        }

        if (["accThree", "accFive", "accThreeDiario", "accFiveDiario", "accThreeSemanal", "accFiveSemanal"].includes(column["field"])) {
            datoProcesado = isNaN(dato) ? "-" : Math.round(100 * dato)

        }
        return <div>{datoProcesado}<span className="label-unidad">%</span></div>
    }

    useEffect(() => {
        if (ciclo) {
            updateCicloWeekSelector(ciclo);
        }
    }, [ciclo])
    useEffect(() => {
        if (week.nombre != "Cargando")
            setWeekGlobal(week);
    }, [week]);
    function irInvernadero(id) {
        let objetivo = invernaderos.find(el => el.id === id);
        setInvernadero(objetivo);

        props.history.push("/cosecha/consultar");
    }
    const irACompraExterna = () => {
        props.history.push("/cosecha/compra_externa");
    }
    const handleInvernaderoLink = (isExternal, rowData) => {
        if (isExternal) {
            irACompraExterna()
        } else {
            irInvernadero(rowData["invernadero_id"])
        }
    }
    function invernaderoBody(rowData, column) {

        if (invernaderos) {
            let invernadero = invernaderosVirtuales.find(el => el.id === rowData["invernadero_id"]);
            return <Button className="invisible-button" label=" " tooltip={getProductoYVariedadString(invernadero)}>
                <div className={`icon-general icon-mini icon-click bor-${invernadero ? invernadero.color : ""} bg-${invernadero ? invernadero.color : ""}`} onClick={() => handleInvernaderoLink(invernadero.es_externo, rowData)}>
                    {invernadero.es_externo
                        ? <img width="30" alt="icono" src={icono} />
                        : rowData["invernadero"]}
                </div>
            </Button>
        } else {
            return <div >
                {rowData["invernadero"]}
            </div>
        }
    }
    let semanas = []
    for (let index = 0; index < 5; index++) {
        let inicioSemana = obtenerInicioSemanaDia(week.time + ((1 + index) * segundosSemana) + (2 * 60 * 60)).unix()
        let nombreSemana = obtenerNombreSemanaCompacto(inicioSemana);
        semanas.push(<Column key={index} header={nombreSemana} colSpan={1} className="th-small" />)
    }
    const obtenerNombreHeaderOpcion = (params) => {
        if (tipoPeriodo) {
            let opcion = opcionesPeriodo.find(el => el.value === tipoPeriodo);
            return opcion.label;

        } else {
            return "";
        }
    }

    let headerGroup = <ColumnGroup>
        <Row>
            <Column header="Invernadero" colSpan={1} className="th-small" />
            {semanas}
            <Column header="Proyeccion" colSpan={1} className="th-small" />
            <Column header={obtenerNombreHeaderOpcion()} colSpan={1} className="th-small" />
            <Column header="Accuracy 3 weeks" colSpan={1} className="th-small" />
            <Column header="Accuracy 5 weeks" colSpan={1} className="th-small" />
        </Row>
    </ColumnGroup>;
    const bodyTemplate = (rowData, column) => <div>{dosDecimales(rowData[column["field"]])} </div>;

    const getTipoPeridioAcc = () => {
        if (tipoPeriodo === "real") { return { accThree: "accThree", accFive: "accFive" } }
        else if (tipoPeriodo === "diario") { return { accThree: "accThreeDiario", accFive: "accFiveDiario" } }
        else if (tipoPeriodo === "semanal") { return { accThree: "accThreeSemanal", accFive: "accFiveSemanal" } }
    }

    return (
        <div className="p-grid p-justify-center">

            <div className="p-col-12 title-container">
                <span className="back-btn" onClick={atras}></span>
                <span className="icon-general product-icon"></span>

                <span className="titles-group">
                    <h1 className="header-title">
                        Reporte de pronósticos
                    </h1>
                </span>
            </div>
            <SeccionFiltros>
                <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
                <ContenedorFiltro label="Periodo">
                    <SelectButton value={tipoPeriodo} options={opcionesPeriodo} onChange={e => { if (e.value) setTipoPeriodo(e.value) }} />
                </ContenedorFiltro>
            </SeccionFiltros>

            <div className="p-col-12">
                <div className="card p-0">
                    <DataTable value={[...tabla, ...tablaExterno]} headerColumnGroup={headerGroup}>
                        <Column field="invernadero" className="col__label" body={invernaderoBody} />
                        <Column field="p0" body={templateKgm2} />
                        <Column field="p1" body={templateKgm2} />
                        <Column field="p2" body={templateKgm2} />
                        <Column field="p3" body={templateKgm2} />
                        <Column field="p4" body={templateKgm2} />
                        <Column field="proyeccion" body={templateKgm2} />
                        <Column field={tipoPeriodo} className="col__final" body={templateKgm2} />
                        <Column field={getTipoPeridioAcc()?.accThree || ""} className="col__final" body={templatePorcentaje} />
                        <Column field={getTipoPeridioAcc()?.accFive || ""} className="col__final" body={templatePorcentaje} />
                    </DataTable>
                </div>
            </div>
            <div className="p-col-12">
                <p className="section-title">Notas</p>
                <div className="card">
                    <p className="mt-0">Los valores "Diario" se calculan con los datos "Diario" de la semana que se hayan ingresado hasta el día actual + los datos ingresados en "Semanal" para los días futuros de la semana</p>
                    <p className="mb-0">Los valores "Real" se calculan con todos los datos "Real" de la semana que se hayan ingresado hasta la última actualización despues de las 5pm + los datos ingresados en "Diario" (o en su defecto "Semanal") para los días futuros de la semana</p>
                </div>
            </div>

        </div>)
}//294