import React from 'react'

const HTGGrid = ({children, extra}) => {
  return (
    <div className={`p-grid ${extra ? extra : ""}`}>
        {children}
    </div>
  )
}

export default HTGGrid