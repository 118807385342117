import React, { useState, useEffect, useGlobal, setGlobal } from 'reactn';
import classNames from 'classnames';
import AppTopbar from './AppTopbar';
import AppMenu from './AppMenu';
import { Route, Switch, withRouter } from 'react-router-dom';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import './layout/layout.scss';
import './App.scss';
import Principal from './components/homepage/Principal';
import { logout, stopListener } from "./service/account";
import Login from "./components/Login/Login";
import RecuperarPassword from "./components/Login/RecuperarPassword";

import Invernaderos from "./components/Invernaderos/Invernaderos";
import FormularioInvernadero from "./components/Invernaderos/FormularioInvernadero";
import InvernaderosEmpleados from "./components/rh/InvernaderosEmpleados";
import InvernaderosNomina from "./components/nomina/Invernaderos";
import InvernaderosCosecha from "./components/cosecha/InvernaderosCosecha";
import InvernaderosCultivo from "./components/cultivo/Invernaderos";
import CardsRh from './components/rh/CardsRh';
import ConsultarEmpleados from './components/rh/ConsultarEmpleados';
import RegistrarEmpleadoForm from './components/rh/RegistrarEmpleadoForm';
import InvernaderosCostos from "./components/analisis_costos/Invernaderos";
import Ciclo from './components/ciclo/Ciclo';
import EditarCiclo from './components/ciclo/EditarCiclo';
import CrearCiclo from './components/ciclo/CrearCiclo';

import CardsInvernaderosFisicos from './components/invernaderos_fisicos/CardsInvernaderosFisicos';
import EditarFisico from './components/invernaderos_fisicos/EditarFisico';
import CrearFisico from './components/invernaderos_fisicos/CrearFisico';

import InvernaderosCalidadTrabajo from './components/calidad_trabajo/Invernaderos'
import MapaInvernaderoCalidad from './components/calidad_trabajo/MapaInvernaderoCalidad'

import DemoCards from "./components/Demos/Cards";
import DemoTabla from "./components/Demos/Tabla";
import DemoFormulario from "./components/Demos/Formulario";
import ConsultarNomina from "./components/nomina/Consultar";
import ConsultarConNuevoBonoCosecha from "./components/nomina/ConsultarConNuevoBonoCosecha";
import IncidenciasNomina from "./components/nomina/Incidencias";
import Producto from "./components/producto/Producto";
import Budget from "./components/producto/Budget";
import EditarBudget from "./components/producto/EditarBudget";
import RegistroEmpaque from "./components/producto/RegistroEmpaque";
import RegistroEmpaqueExterno from "./components/producto/RegistroEmpaqueExterno";
import ConsultarCosecha from "./components/cosecha/ConsultarCosecha";
import ReporteCosecha from "./components/cosecha/Reporte";
import Cosecha from './components/graficas/Cosecha';
import ReporteEmpaque from "./components/producto/Reporte";
import DetallesReporte from "./components/producto/DetallesReporte";
import ConfiguracionCultivo from "./components/cultivo/Configuracion";
import RegistrarCultivo from "./components/cultivo/Registrar";
import ReporteCultivo from "./components/cultivo/Reporte";
import ReporteHistoricoCultivo from "./components/cultivo/ReporteHistorico";
import DetallesCultivo from "./components/cultivo/Detalles";
import OrdenesVenta from "./components/ordenes_de_venta/Cards";
import RegistrarOrdenVenta from "./components/ordenes_de_venta/Registrar";
import ConsultarOrdenVenta from "./components/ordenes_de_venta/Consultar";
import ConsultarCumplimiento from "./components/cumplimiento/ConsultarCumplimiento.vista";

import TablaCompras from "./components/requisiciones/compras/TablaCompras";
import GraficaCompras from "./components/requisiciones/compras/GraficaCompras";
import FormularioCompra from "./components/requisiciones/compras/FormularioCompra";
import TablaMantenimientos from "./components/requisiciones/mantenimiento/TablaMantenimientos";
import GraficaMantenimiento from "./components/requisiciones/mantenimiento/GraficaMantenimiento";
import FormularioMantenimiento from "./components/requisiciones/mantenimiento/FormularioMantenimiento";
import TablaSistemas from "./components/requisiciones/sistemas/TablaSistemas";
import GraficaSistemas from "./components/requisiciones/sistemas/GraficaSistemas";
import FormularioSistema from "./components/requisiciones/sistemas/FormularioSistema";
import MapaInvernaderoBiocontrol from './components/biocontrol/MapaInvernaderoBiocontrol';
import CardsBiocontrol from './components/biocontrol/CardsBiocontrol';
import FormularioProducto from './components/biocontrol/FormularioProducto';
import TablaProductosAplicacion from './components/biocontrol/TablaProductosAplicacion';
import InvernaderosCalidad from './components/calidad/Invernaderos';
import ProveedoresExternosCards from './components/calidad/ProveedoresExternosCards';
import CalidadSemanal from './components/calidad/Semanal';
import RegistroInicialCalidad from './components/calidad/RegistroInicial';
import TablaInicialCalidad from './components/calidad/tablas/TablaInicial';
import RegistroFinalCalidad from './components/calidad/RegistroFinal';
import TablaFinalCalidad from './components/calidad/tablas/TablaFinal';
import RegistroPesosCalidad from './components/calidad/RegistroPesos';
import TablaPesosCalidad from './components/calidad/tablas/TablaPesos';
import ReporteCalidad from './components/calidad/ReporteCalidad';
import ReporteProveedorExterno from './components/calidad/ReporteProveedorExterno';
import GraficaGradosBrix from './components/calidad/GraficaGradosBrix';
import ReporteGeneralDefectos from './components/calidad/ReporteGeneralDefectos';
import ConsultarEmbarques from './components/embarques/ConsultarEmbarques';
import RegistrarEmbarque from './components/embarques/RegistrarEmbarque';
import DetallesEmbarque from './components/embarques/DetallesEmbarque';
import CardsEmbarques from './components/embarques/Cards';
import Bloqueos from './components/admin/Bloqueos';
import { construirMenu } from './service/Navegacion';

import AdminPresentaciones from './components/admin/presentaciones/Presentaciones';
import FormularioPresentacion from './components/admin/presentaciones/FormularioPresentacion';
import TablaCategorias from './components/admin/presentaciones/TablaCategorias';
import FormularioCategorias from './components/admin/presentaciones/FormularioCategorias';

import AdminActividades from './components/admin/actividades/Actividades';
import AdministrarPackout from './components/admin/productosYVariedades/AdministrarPackout.vista';
import FormularioActividades from './components/admin/actividades/FormularioActividades';
import PorcentajeHorasTrabajadas from './components/graficas/PorcentajeHorasTrabajadas';
import PorcentajeActividades from './components/graficas/PorcentajeActividades';
import Cultivo from './components/graficas/Cultivo';
import MedidaCultivo from './components/graficas/MedidaCultivo';
import ActividadesEmpleado from './components/graficas/ActividadesEmpleado';
import EmpleadosActividad from './components/graficas/EmpleadosActividad';
import HorasVsActividades from './components/graficas/HorasVsActividades';
import DesglosePorcentajeActividades from './components/graficas/DesglosePorcentajeActividades';
import BonoHorasTrabajadas from './components/graficas/BonoHorasTrabajadas';
import HeatmapCalidad from './components/graficas/HeatmapCalidad';
import DistribucionPresentacionesCiclo from './components/graficas/DistribucionPresentacionesCiclo';
import DistribucionPresentacionesPorSemana from './components/graficas/DistribucionPresentacionesPorSemana';
import DistribucionClientesSemanal from './components/graficas/DistribucionClientesSemanal';
import DistribucionClientesCiclo from './components/graficas/DistribucionClientesCiclo';
import Usuarios from './components/admin/usuarios/Usuarios';
import FormularioUsuario from './components/admin/usuarios/FormularioUsuario';
import ReporteMedico from './components/reporte_medico/Invernaderos';
import GraficaReporteMedico from './components/graficas/GraficaReporteMedico';
import FormularioReporteMedico from './components/reporte_medico/Formulario';
import ConsultarReportesMedicos from './components/reporte_medico/ConsultarReportesMedicos';
import CardsCumplimiento from './components/cumplimiento/CardsCumplimiento.vista';
import CardsNominaEmpaque from './components/nomina_empaque/ResumenNominaEmpaque';
import HomeGraficas from './components/graficas/Home';
import ConsultarNominaEmpaque from './components/nomina_empaque/ConsultarTablaNominaEmpaque';
import CargaMaquinas from './components/nomina_empaque/CargaMaquinas';
import AdminEmpaques from './components/admin/empaques/Empaques';
import FormularioEmpaque from './components/admin/empaques/FormularioEmpaque';

import OrdenesAplicacion from './components/biocontrol/OrdenesAplicacion';
import ConsultarReporteEmpaque from './components/reporte_precios/Consulta';
import ConsultarReporteEmpaqueNU from './components/rechazos/ValidacionDePrecios.vista';
import ConsultarReporteEmpaqueAgregar from './components/reporte_precios/ConsultaAgregar';

import ReportePrecios from './components/reporte_precios/Reporte';
import ReporteCobranza from './components/reporte_precios/ReporteCobranza';
import BudgetEmpaque from './components/graficas/BudgetEmpaque';
import OrdenAplicacion from './components/biocontrol/OrdenAplicacion';
import GruposPresentaciones from './components/admin/grupo_presentaciones/GruposPresentaciones';
import FormularioGrupoPresentaciones from './components/admin/grupo_presentaciones/FormularioGrupoPresentaciones';
import BudgetPresentaciones from './components/reporte_precios/BudgetPresentaciones';
import PesosSemanaCosecha from './components/graficas/PesosSemanaCosecha';
import EmpaqueEmpacadora from './components/graficas/EmpaqueEmpacadora';
import ReportePesosCalidad from './components/graficas/ReportePesosCalidad';
import Proveedores from './components/admin/proveedores/Proveedores';
import FormularioProveedores from './components/admin/proveedores/FormularioProveedores';
import CompraExterna from './components/cosecha/CompraExterna';
import ConsultaCompraExterna from './components/cosecha/ConsultaCompraExterna';
import CapturaCompraExterna from './components/cosecha/CapturaCompraExterna';

import ReporteCompraExterna from './components/cosecha/ReporteCompraExterna';
import FormularioVariedades from './components/admin/productosYVariedades/FormularioVariedades.vista';
import VerTablaProductosYVariedades from "./components/admin/productosYVariedades/VerProductosYVariedades.vista"
import FormularioProductoMezclado from "./components/admin/productosYVariedades/FormularioProductoMezclado.vista"

import ReporteAcumulado from './components/reporte_precios/ReporteAcumulado';
import Materiales from './components/graficas/dashboard_costos/Materiales';
import GraficaPrecios from './components/graficas/dashboard_costos/Precios';
import IngresosBrutos from './components/graficas/dashboard_costos/IngresosBrutos';
import IngresosNetos from './components/graficas/dashboard_costos/IngresosNetos';
import RegistroEntrada from './components/ventas_nacionales/RegistroEntrada'
import TablaRegistrosEntradas from './components/ventas_nacionales/TablaRegistrosEntradas'
import DashboardAnalisisCostos from './components/graficas/dashboard_costos/Dashboard';
import TablaResumenEntradasSalidas from './components/ventas_nacionales/TablaResumenEntradasSalidas'
import TablaRegistrosSalidas from './components/ventas_nacionales/TablaRegistrosSalidas'
import RegistroSalida from './components/ventas_nacionales/RegistroSalida'
import ConsultaExterna from './components/embarques/ConsultaExterna';
import RegistroPronosticos from './components/pronosticos/Registro';
import CardsPronosticos from './components/pronosticos/Cards';
import ReporteInvernaderoExterno from './components/producto/ReporteInvernaderoExterno';
import IngresoClientesCiclo from './components/graficas/IngresoClientesCiclo';
import IngresoClientesSemanal from './components/graficas/IngresoClientesSemanal';
import ReportePronosticos from './components/pronosticos/Reporte';
import ProveedoresExternosCardsPronosticos from './components/pronosticos/ProveedoresExternosCards';
import DistribucionVariedadesMedleys from './components/graficas/DistribucionVariedadesMedleys';
import CardsCompraExterna from './components/cosecha/CardsCompraExterna';
import TablaPlaneacionActividades from './components/planeacion_mantenimiento/TablaPlaneacionActividades';
import AdminActividadesMantenimiento from './components/planeacion_mantenimiento/administrar_actividades/AdministrarActividades';

import PlaneacionActividades from './components/planeacion_mantenimiento/PlaneacionActividades';
import ConsultaSemana from './components/planeacion_mantenimiento/CapturaAgentes/ConsultaSemana';
import ConsultaDia from './components/planeacion_mantenimiento/CapturaAgentes/ConsultaDia';
import CapturaTarea from './components/planeacion_mantenimiento/CapturaAgentes/CapturaTarea';
import CapturaEmergencia from './components/planeacion_mantenimiento/CapturaAgentes/CapturaEmergencia';
import { rolProveedorExterno } from './constants';
import FormularioColores from './components/admin/productosYVariedades/FormularioColores.vista';
import RegistroPronosticoExterno from './components/compra_externa/RegistroPronostico';
import BiocontrolResumenClarosAfectados from 'components/graficas/HeatmapBiocontrolEnfermedades';
import ReporteGeneralPesosDiarios from 'components/calidad/ReporteGeneralPesosDiarios';
import FormValidacionInvoice from './components/rechazos/FormValidacionInvoice.vista';
import NAVEGACION from 'service/constants/Routes.const';
import TablaInvoices from './components/rechazos/TablaInvoices.vista';
import ReporteRechazos from 'components/rechazos/ReporteRechazos.vista';

const App = (props) => {
    const [usuario] = useGlobal("usuario");
    const [, setCargando] = useGlobal("cargando");
    const [week] = useGlobal("week");
    const [state, setState] = useState({
        layoutMode: 'static', layoutColorMode: 'light', mobileMenuActive: false,
        overlayMenuActive: false, staticMenuInactive: false,
    })
    const [mostrarNuevoNominaCosecha, setMostrarNuevoNominaCosecha] = useState(true)
    let menuClick = false;

    useEffect(() => {
        if (state.mobileMenuActive) addClass(document.body, 'body-overflow-hidden');
        else removeClass(document.body, 'body-overflow-hidden');
    }, [state.mobileMenuActive])

    //Checar semana seleccionada para mostrar la nomina de cosecha correcta
    useEffect(() => {
        if (week?.nombre !== "Cargando") {
            //Checar si la semana seleccionada es después de Domingo, 12 de marzo 2023 (Semana 12 en adelante)
            const nuevoBonoCosecha = week?.time > 1679248800; //[Sun Mar 19 2023 18:00:00 GMT+0000]
            setMostrarNuevoNominaCosecha(nuevoBonoCosecha)
        }
    }, [week])

    const isDesktop = () => window.innerWidth > 1024

    const onWrapperClick = (event) => {
        if (!menuClick) setState({ ...state, overlayMenuActive: false, mobileMenuActive: false });
        menuClick = false;
    }

    const onToggleMenu = (event) => {
        menuClick = true;
        if (isDesktop() && state.layoutMode === 'overlay') setState({ ...state, overlayMenuActive: !state.overlayMenuActive });
        else if (isDesktop() && state.layoutMode === 'static') setState({ ...state, staticMenuInactive: !state.staticMenuInactive });
        else setState({ ...state, mobileMenuActive: !state.mobileMenuActive });
        event.preventDefault();
    }

    const onSidebarClick = () => { menuClick = true }
    const onMenuItemClick = (event) => {
        if (!event.item.items) setState({ ...state, overlayMenuActive: false, mobileMenuActive: false });
    }

    const cerrarSesion = async () => {
        try {
            await logout();
            props.history.push("/")
            stopListener();
            setCargando(false)
            setGlobal({ agentes: null })
        } catch (error) { console.log(`NO SE PUDO CERRAR SESION - ${error}`); }
    }

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += ` ${className}`;
    }
    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': state.layoutMode === 'overlay',
        'layout-static': state.layoutMode === 'static',
        'layout-static-sidebar-inactive': state.staticMenuInactive && state.layoutMode === 'static',
        'layout-overlay-sidebar-active': state.overlayMenuActive && state.layoutMode === 'overlay',
        'layout-mobile-sidebar-active': state.mobileMenuActive
    });
    const sidebarClassName = classNames("layout-sidebar", {
        'layout-sidebar-dark': state.layoutColorMode === 'dark',
        'layout-sidebar-light': state.layoutColorMode === 'light'
    });
    const { RECHAZOS } = NAVEGACION
    return (
        usuario !== "OFF" ?
            <div className={wrapperClass} onClick={onWrapperClick}>

                <AppTopbar onToggleMenu={onToggleMenu} />
                <AppMenu sidebarClassName={sidebarClassName} onSidebarClick={onSidebarClick} model={(usuario && usuario != "OFF") ? construirMenu(usuario.rol, cerrarSesion) : []} onMenuItemClick={onMenuItemClick} />

                {usuario.rol === rolProveedorExterno ?
                    <div className="layout-main">
                        <Route path="/cosecha/compra_externa" exact component={CapturaCompraExterna} />
                        <Route path="/cosecha/pronostico_compra_externa" exact component={RegistroPronosticoExterno} />
                        <Route path="/calidad/reporte_proveedor_externo" exact component={ReporteProveedorExterno} />
                    </div>
                    :
                    <div className="layout-main">
                        {/*---------------Administración---------------*/}
                        <Route path="/" exact component={Principal} />
                        <Route path="/Inicio" exact component={Principal} />
                        <Route path="/Home" exact component={Principal} />
                        <Route path="/administrar/invernaderos_fisicos" exact component={CardsInvernaderosFisicos} />
                        <Route path="/administrar/invernadero_fisico/crear" exact component={CrearFisico} />
                        <Route path="/administrar/invernadero_fisico/modificar/:invernaderoID" exact component={EditarFisico} />

                        <Route path="/administrar/invernaderos_virtuales" exact component={Invernaderos} />
                        <Route path="/administrar/invernadero_virtual/crear" exact component={FormularioInvernadero} />
                        <Route path="/administrar/invernadero_virtual/modificar/:invernaderoID" exact component={FormularioInvernadero} />

                        <Route path="/administrar/ciclos" exact component={Ciclo} />
                        <Route path="/administrar/ciclo/crear" exact component={CrearCiclo} />
                        <Route path="/administrar/ciclo/modificar/:cicloID" exact component={EditarCiclo} />



                        {/*---------------******OG URLS******---------------*/}
                        <Route path="/cards_rh" exact component={CardsRh} />
                        <Route path="/ejemplo_cards_rh" exact component={InvernaderosEmpleados} />
                        <Route path="/registrar_empleado/:cicloId" exact component={RegistrarEmpleadoForm} />
                        <Route path="/modificar_empleado/:cicloId" exact component={RegistrarEmpleadoForm} />
                        <Route path="/consultar_empleados" exact component={ConsultarEmpleados} />

                        <Route path="/demo_cards" exact component={DemoCards} />
                        <Route path="/demo_tabla" exact component={DemoTabla} />
                        <Route path="/demo_formulario" exact component={DemoFormulario} />
                        <Route path="/nomina" exact component={InvernaderosNomina} />

                        <Route path="/consultar_nomina" exact component={mostrarNuevoNominaCosecha ? ConsultarConNuevoBonoCosecha : ConsultarNomina} />

                        <Route path="/nomina/incidencias" exact component={IncidenciasNomina} />
                        <Route path="/producto" exact component={Producto} />
                        <Route path="/producto/crear_budget" exact component={Budget} />
                        <Route path="/producto/editar_budget" exact component={EditarBudget} />
                        <Route path="/producto/registrar_empaque" exact component={RegistroEmpaque} />
                        <Route path="/producto/reporte/detalles" exact component={DetallesReporte} />

                        <Route path="/cosecha/" exact component={InvernaderosCosecha} />
                        <Route path="/cosecha/consultar" exact component={ConsultarCosecha} />
                        <Route path="/cosecha/reporte" exact component={ReporteCosecha} />
                        <Route path="/cosecha/compra_externa" exact component={CompraExterna} />
                        <Route path="/cosecha/compra_externa/cards_detalles" exact component={CardsCompraExterna} />
                        <Route path="/cosecha/compra_externa/consultar" exact component={ConsultaCompraExterna} />
                        <Route path="/cosecha/compra_externa/reporte" exact component={ReporteCompraExterna} />
                        <Route path="/cosecha/graficas/peso_semana" exact component={PesosSemanaCosecha} />
                        <Route path="/cosecha/graficas/porcentajes" exact component={Cosecha} />

                        <Route path="/empaque/reporte" exact component={ReporteEmpaque} />
                        <Route path="/cultivo" exact component={InvernaderosCultivo} />
                        <Route path="/cultivo/configuracion" exact component={ConfiguracionCultivo} />
                        <Route path="/cultivo/registro" exact component={RegistrarCultivo} />
                        <Route path="/cultivo/reporte" exact component={ReporteCultivo} />
                        <Route path="/cultivo/historico" exact component={ReporteHistoricoCultivo} />
                        <Route path="/cultivo/graficas/medidas" exact component={Cultivo} />
                        <Route path="/cultivo/graficas/invernaderos" exact component={MedidaCultivo} />
                        <Route path="/cultivo/detalles" exact component={DetallesCultivo} />

                        <Route path="/ordenes" exact component={OrdenesVenta} />
                        <Route path="/ordenes/registrar" exact component={RegistrarOrdenVenta} />
                        <Route path="/ordenes/consultar" exact component={ConsultarOrdenVenta} />
                        <Route path="/ordenes/consulta_externa/:cicloId/:ordenId" component={ConsultaExterna} />

                        <Route path="/cumplimiento/cards" exact component={CardsCumplimiento} />
                        <Route path="/cumplimiento/consultar" exact component={ConsultarCumplimiento} />

                        <Route path="/requisiciones/compras" exact component={TablaCompras} />
                        <Route path="/requisiciones/grafica_compras" exact component={GraficaCompras} />
                        <Route path="/requisiciones/compras/crear" exact component={FormularioCompra} />
                        <Route path="/requisiciones/compras/modificar/:requisicionID" exact component={FormularioCompra} />
                        <Route path="/requisiciones/mantenimiento" exact component={TablaMantenimientos} />
                        <Route path="/requisiciones/grafica_mantenimiento" exact component={GraficaMantenimiento} />
                        <Route path="/requisiciones/mantenimiento/crear" exact component={FormularioMantenimiento} />
                        <Route path="/requisiciones/mantenimiento/modificar/:requisicionID" exact component={FormularioMantenimiento} />
                        <Route path="/requisiciones/sistemas" exact component={TablaSistemas} />
                        <Route path="/requisiciones/grafica_sistemas" exact component={GraficaSistemas} />
                        <Route path="/requisiciones/sistemas/crear" exact component={FormularioSistema} />
                        <Route path="/requisiciones/sistemas/modificar/:requisicionID" exact component={FormularioSistema} />

                        <Route path="/calidad_trabajo" exact component={InvernaderosCalidadTrabajo} />
                        <Route path="/calidad_trabajo/mapa_invernadero" exact component={MapaInvernaderoCalidad} />

                        <Route path="/biocontrol/cards" exact component={CardsBiocontrol} />
                        <Route path="/biocontrol/mapa_invernadero" exact component={MapaInvernaderoBiocontrol} />
                        <Route path="/biocontrol/ordenes_aplicacion" exact component={OrdenesAplicacion} />
                        <Route path="/biocontrol/orden_aplicacion/crear" exact component={OrdenAplicacion} />
                        <Route path="/biocontrol/orden_aplicacion/:invernaderoID/crear" exact component={OrdenAplicacion} />
                        <Route path="/biocontrol/orden_aplicacion/:invernaderoID/modificar/:aplicacionID" exact component={OrdenAplicacion} />
                        <Route path="/biocontrol/productos" exact component={TablaProductosAplicacion} />
                        <Route path="/biocontrol/productos/crear" exact component={FormularioProducto} />
                        <Route path="/biocontrol/productos/modificar/:productoID" exact component={FormularioProducto} />
                        <Route path="/biocontrol/graficas/claros-afectados" exact component={BiocontrolResumenClarosAfectados} />

                        <Route path="/calidad" exact component={InvernaderosCalidad} />
                        <Route path="/calidad/proveedores_externos" exact component={ProveedoresExternosCards} />
                        <Route path="/calidad/semanal" exact component={CalidadSemanal} />
                        <Route path="/calidad/tabla/inicial" exact component={TablaInicialCalidad} />
                        <Route path="/calidad/tabla/final" exact component={TablaFinalCalidad} />
                        <Route path="/calidad/tabla/pesos" exact component={TablaPesosCalidad} />
                        <Route path="/calidad/registro_inicial/crear" exact component={RegistroInicialCalidad} />
                        <Route path="/calidad/registro_final/crear" exact component={RegistroFinalCalidad} />
                        <Route path="/calidad/registro_pesos/crear" exact component={RegistroPesosCalidad} />
                        <Route path="/calidad/registro_inicial/:invernaderoID/modificar/:registroID" exact component={RegistroInicialCalidad} />
                        <Route path="/calidad/registro_final/:invernaderoID/modificar/:registroID" exact component={RegistroFinalCalidad} />
                        <Route path="/calidad/registro_pesos/:invernaderoID/modificar/:registroID" exact component={RegistroPesosCalidad} />
                        <Route path="/calidad/reporte" exact component={ReporteCalidad} />
                        <Route path="/calidad/reporte_proveedor_externo" exact component={ReporteProveedorExterno} />
                        <Route path="/calidad/grados_brix" exact component={GraficaGradosBrix} />
                        <Route path="/calidad/reporte_general_defectos" exact component={ReporteGeneralDefectos} />
                        <Route path="/calidad/reporte_general_pesos_diarios" exact component={ReporteGeneralPesosDiarios} />
                        <Route path="/calidad/graficas/heatmap" exact component={HeatmapCalidad} />

                        <Route path="/embarques/consultar" exact component={ConsultarEmbarques} />
                        <Route path="/embarques/registrar" exact component={RegistrarEmbarque} />
                        <Route path="/embarques/detalles_embarque/:embarqueId" exact component={DetallesEmbarque} />
                        <Route path="/embarques/modificar/:embarqueId" exact component={RegistrarEmbarque} />
                        <Route path="/embarques/" exact component={CardsEmbarques} />
                        <Route path="/admin/bloqueos" exact component={Bloqueos} />
                        <Route path="/nomina/graficas/porcentaje_horas" exact component={PorcentajeHorasTrabajadas} />
                        <Route path="/nomina/graficas/porcentaje_actividades" exact component={PorcentajeActividades} />
                        <Route path="/nomina/graficas/actividades_empleado" exact component={ActividadesEmpleado} />
                        <Route path="/nomina/graficas/empleados_actividad" exact component={EmpleadosActividad} />
                        <Route path="/nomina/graficas/horas_actividades" exact component={HorasVsActividades} />
                        <Route path="/nomina/graficas/bono_horas" exact component={BonoHorasTrabajadas} />
                        <Route path="/nomina/graficas/desglose_porcentaje_actividades" exact component={DesglosePorcentajeActividades} />

                        <Route path="/admin/presentaciones" exact component={AdminPresentaciones} />
                        <Route path="/admin/presentaciones/crear" exact component={FormularioPresentacion} />
                        <Route path="/admin/presentaciones/modificar/:presentacionID" exact component={FormularioPresentacion} />

                        <Route path="/admin/bono_empaque/tabla" exact component={TablaCategorias} />
                        <Route path="/admin/bono_empaque/crear" exact component={FormularioCategorias} />
                        <Route path="/admin/bono_empaque/modificar/:categoriaID" exact component={FormularioCategorias} />

                        <Route path="/admin/actividades" exact component={AdminActividades} />
                        <Route path="/admin/actividades/crear" exact component={FormularioActividades} />
                        <Route path="/admin/actividades/modificar/:actividadID" exact component={FormularioActividades} />
                        <Route path="/empaque/graficas/distribucion_presentaciones_ciclo" exact component={DistribucionPresentacionesCiclo} />
                        <Route path="/empaque/graficas/distribucion_presentaciones_semana" exact component={DistribucionPresentacionesPorSemana} />
                        <Route path="/empaque/graficas/distribucion_clientes_semana" exact component={DistribucionClientesSemanal} />
                        <Route path="/empaque/graficas/distribucion_clientes_ciclo" exact component={DistribucionClientesCiclo} />
                        <Route path="/admin/usuarios" exact component={Usuarios} />
                        <Route path="/admin/usuarios/crear" exact component={FormularioUsuario} />
                        <Route path="/admin/usuarios/editar/:usuarioID" exact component={FormularioUsuario} />
                        <Route path="/reporte_medico/cards" exact component={ReporteMedico} />
                        <Route path="/reporte_medico/registrar" exact component={FormularioReporteMedico} />
                        <Route path="/reporte_medico/consultar" exact component={ConsultarReportesMedicos} />
                        <Route path="/reporte_medico/grafica" exact component={GraficaReporteMedico} />

                        <Route path="/graficas/" exact component={HomeGraficas} />
                        <Route path="/nomina_empaque/" exact component={CardsNominaEmpaque} />

                        <Route path="/nomina_empaque/consultar" exact component={ConsultarNominaEmpaque} />
                        <Route path="/nomina_empaque/carga_maquinas" exact component={CargaMaquinas} />
                        <Route path="/admin/empaques" exact component={AdminEmpaques} />
                        <Route path="/admin/empaques/crear" exact component={FormularioEmpaque} />
                        <Route path="/reporte_empaque/" exact component={ConsultarReporteEmpaque} />
                        <Route path="/reporte_empaque/precios" exact component={ReportePrecios} />
                        <Route path="/reporte_empaque/cobranza" exact component={ReporteCobranza} />
                        <Route path="/empaque/graficas/budget" exact component={BudgetEmpaque} />
                        <Route path="/admin/grupos_presentaciones" exact component={GruposPresentaciones} />
                        <Route path="/admin/grupo_presentaciones/crear" exact component={FormularioGrupoPresentaciones} />
                        <Route path="/admin/grupo_presentaciones/modificar/:grupoPresentacionID" exact component={FormularioGrupoPresentaciones} />
                        <Route path="/finanzas/budget_presentaciones" exact component={BudgetPresentaciones} />

                        <Route path="/nomina_empaque/graficas/empaque_empacadora" exact component={EmpaqueEmpacadora} />
                        <Route path="/calidad/graficas/reporte_pesos_calidad" exact component={ReportePesosCalidad} />
                        <Route path="/admin/proveedores" exact component={Proveedores} />
                        <Route path="/admin/proveedor/crear" exact component={FormularioProveedores} />
                        <Route path="/admin/proveedor/modificar/:proveedorID" exact component={FormularioProveedores} />
                        <Route path="/producto/registrar_empaque_externo" exact component={RegistroEmpaqueExterno} />

                        <Route path="/admin/productos_y_variedades" exact component={VerTablaProductosYVariedades} />
                        <Route path="/admin/variedades/crear" exact component={FormularioVariedades} />
                        <Route path="/admin/variedades/consultar" exact component={FormularioVariedades} />
                        <Route path="/admin/productos/crear" exact component={FormularioProductoMezclado} />
                        <Route path="/admin/productos/consultar" exact component={FormularioProductoMezclado} />
                        <Route path="/admin/colores/crear" exact component={FormularioColores} />
                        <Route path="/admin/colores/consultar" exact component={FormularioColores} />
                        <Route path="/admin/packout_fijo" exact component={AdministrarPackout} />

                        <Route path="/finanzas/reporte_acumulado_precios" exact component={ReporteAcumulado} />
                        <Route path="/analisis_costos/costos_empaque" exact component={Materiales} />
                        <Route path="/analisis_costos/precios" exact component={GraficaPrecios} />
                        <Route path="/ventas_nacionales/tabla_resumen" exact component={TablaResumenEntradasSalidas} />
                        <Route path="/ventas_nacionales/tabla_registros_entradas" exact component={TablaRegistrosEntradas} />
                        <Route path="/ventas_nacionales/registro_entrada/crear" exact component={RegistroEntrada} />
                        <Route path="/ventas_nacionales/registro_entrada/:invernaderoID/modificar/:entradaID" exact component={RegistroEntrada} />
                        <Route path="/ventas_nacionales/tabla_registros_salidas" exact component={TablaRegistrosSalidas} />
                        <Route path="/ventas_nacionales/registro_salida/crear" exact component={RegistroSalida} />
                        <Route path="/ventas_nacionales/registro_salida/modificar/:salidaID" exact component={RegistroSalida} />
                        <Route path="/analisis_costos/ingresos_brutos" exact component={IngresosBrutos} />
                        <Route path="/analisis_costos/ingresos_netos" exact component={IngresosNetos} />
                        <Route path="/analisis_costos/dashboard" exact component={DashboardAnalisisCostos} />
                        <Route path="/analisis_costos/" exact component={InvernaderosCostos} />
                        <Route path="/pronosticos/registro" exact component={RegistroPronosticos} />
                        <Route path="/pronosticos/cards" exact component={CardsPronosticos} />
                        <Route path="/pronosticos/reporte" exact component={ReportePronosticos} />
                        <Route path="/pronosticos/proveedores_externos" exact component={ProveedoresExternosCardsPronosticos} />
                        <Route path="/pronosticos/proveedor_externo/registro" exact component={RegistroPronosticoExterno} />
                        <Route path="/empaque/reporte_externo" exact component={ReporteInvernaderoExterno} />
                        <Route path="/graficas/ingreso_clientes" exact component={IngresoClientesCiclo} />
                        <Route path="/graficas/ingreso_clientes_semanal" exact component={IngresoClientesSemanal} />
                        <Route path="/graficas/distribucion_variedades_medleys" exact component={DistribucionVariedadesMedleys} />

                        <Route path="/mantenimiento/actividades" exact component={AdminActividadesMantenimiento} />
                        <Route path="/mantenimiento/registrar_actividades" exact component={AdminActividadesMantenimiento} />
                        <Route path="/mantenimiento/tabla_planeacion" exact component={TablaPlaneacionActividades} />
                        <Route path="/mantenimiento/planeacion_actividades_agente" exact component={PlaneacionActividades} />
                        <Route path="/mantenimiento/planeacion_actividades_agente/:agenteID" exact component={PlaneacionActividades} />
                        <Route path="/mantenimiento/agente/consulta_semana" exact component={ConsultaSemana} />
                        <Route path="/mantenimiento/agente/consulta_dia" exact component={ConsultaDia} />
                        <Route path="/mantenimiento/agente/captura" exact component={CapturaTarea} />
                        <Route path="/planeacion_mantenimiento/captura_agentes/emergencia" exact component={CapturaEmergencia} />

                        <Route path={"/rechazos/manifestos_validacion_precios"} exact component={ConsultarReporteEmpaqueNU} />
                        <Route path={"/rechazos/form_validacion_invoice"} exact component={FormValidacionInvoice} />
                        <Route path={"/rechazos/form_validacion_invoice/:invoiceId"} exact component={FormValidacionInvoice} />
                        <Route path={"/rechazos/form_validacion_invoice/:invoiceId/agregar/manifiestos"} exact component={ConsultarReporteEmpaqueAgregar} />
                        <Route path={"/rechazos/tabla_invoices"} exact component={TablaInvoices} />
                        <Route path={"/rechazos/reporte"} exact component={ReporteRechazos} />
                    </div>}
                <div className="layout-mask" />
            </div>
            :
            <Switch>
                <Route path="/recuperar_password" exact component={RecuperarPassword} />
                <Route path="/" component={Login} />
            </Switch>
    );
}
export default withRouter(App);
