import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGlobal } from "reactn";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import { SelectButton } from 'primereact/selectbutton';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import RhService, {
  validarUnicoCodigoEmpleado, guardarEmpleadoEmpaque, modificarEmpleadoEmpaque,
  modificarEmpleadoInvernaderoAEmpaque, hasReporteMedico
} from '../../service/RhService';
import InvernaderoService from '../../service/InvernaderoService';
import { dateToUnixTime, unixTimeToDate } from '../../util/functions';
import { obtenerNombreFechaCompacto } from '../../service/fechas';

import { esCicloActual, noHaSidoClausurado } from '../../service/Ciclos';
import { DETALLES_DEPARTAMENTO_EMPAQUE, DETALLES_DEPARTAMENTO_OTROS } from '../../util/constants';
import { obtenerRolesEmpaque, obtenerRolesInvernadero } from '../../service/Empaques';
import SeccionFormulario from '../common/SeccionFormulario';
import ContenedorInput from '../common/ContenedorInput';
import { obtenerEmpacadoras } from '../../service/Empacadoras';
import { obtenerEmpleadoEmpaqueCiclo, obtenerEmpleadosEmpaqueCiclo, validarUnicoCodigoEmpacadora } from '../../service/NominaEmpaque';
import { obtenerReportesMedicosEmpleado } from '../../service/ReporteMedico';
function RegistrarEmpleadoForm(props) {

  // datos iniciales
  var [ciclos,] = useGlobal("ciclos")
  var [cicloId,] = useGlobal("ciclo")
  var [, setCargando] = useGlobal("cargando")
  var [usuario] = useGlobal("usuario")
  var [generos, setGeneros] = React.useState([])
  var [departamentos, setDepartamentos] = React.useState([])
  var [invernaderosVirtuales, setInvernaderosVirtuales] = React.useState([])
  var [empaques, setEmpaques] = React.useState([])
  var [empleadoId, setEmpleadoId] = React.useState("")
  var [empleadoActivo, setEmpleadoActivo] = React.useState(true)

  // alertas
  var [mostrarDialogoDeCamposRequeridos, setMostrarDialogoDeCamposRequeridos] = React.useState(false)


  // formulario
  var [nombre, setNombre] = React.useState("")
  var [apellidos, setApellidos] = React.useState("")
  var [generoSeleccionado, setGeneroSeleccionado] = React.useState("")
  var [fechaIngreso, setFechaIngreso] = React.useState("")
  var [fechaNacimiento, setFechaNacimiento] = React.useState("")
  // identificación
  var [numeroSeguroSocial, setNumeroSeguroSocial] = React.useState("")
  var [curp, setCurp] = React.useState("")
  var [codigoEmpleado, setCodigoEmpleado] = React.useState("")
  // informción laboral
  var [departamentoSeleccionado, setDepartamentoSeleccionado] = React.useState({})
  var [invernaderoVirtualSeleccionado, setInvernaderoVirtualSeleccionado] = React.useState({})
  var [empaqueSeleccionado, setEmpaqueSeleccionado] = React.useState({})
  var [salarioDiario, setSalarioDiario] = React.useState("")
  var [diasVacaciones, setDiasVacaciones] = React.useState("")
  var [, setModalConfirmacion] = useGlobal("modalConfirmacion");
  var [, setModal] = useGlobal("modal");
  const [, setMostrarModalPermiso] = useGlobal("mostrarModalPermiso");
  let [habilitadoSelectorCiclo, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
  const [notas, setNotas] = useState([])
  const [nota, setNota] = useState([])
  const [rolesEmpaque, setRolesEmpaque] = useState([]);
  const [rolesInvernadero, setRolesInvernadero] = useState([]);
  const [empacadoras, setEmpacadoras] = useState([]);
  const [empacadorasOrig, setEmpacadorasOrig] = useState([]);
  const [rolEmpaque, setRolEmpaque] = useState();
  const [rolInvernaderoSeleccionado, setRolInvernaderoSeleccionado] = useState({});
  const [empacadora, setEmpacadora] = useState();
  const [hayCambioDepartamento, setHayCambioDepartamento] = useState(false)
  const [departamentoOriginal, setDepartamentoOriginal] = useState()
  const [modoEdicion, setModoEdicion] = useState(false)
  const [reportesMedicos, setReportesMedicos] = useGlobal("reportesMedicosEmpleado")
  const atras = (params) => {
    props.history.goBack();
  }
  const activarModalMensaje = (titulo, msj) => {
    setModal({ titulo, msj, hide: () => { setModal(null) } })
  }
  const puedeEditar = (params) => {
    const rolsABloquear = ["Personal Administrativo"];
    if (rolsABloquear.includes(usuario?.rol)) { return false; }
    return noHaSidoClausurado(cicloId, ciclos) && ((!isModoEdicion() || !isDepartamentoInvernaderoSeleccionado()) || invernaderoVirtualSeleccionado.habilitado);
  }

  const activarModalConfirmacion = (params) => {
    let mensaje = empleadoActivo ?
      "¿Estás seguro que deseas deshabilitar este empleado?" :
      "¿Estás seguro que deseas habilitar este empleado?";
    let titulo = "Confirmación de acción";
    let aceptar = () => { activar_o_desactivar_Empleado() };
    let cancelar = () => { }
    setModalConfirmacion({ mensaje, titulo, aceptar, cancelar })
  }

  React.useEffect(() => {

    if (cicloId && cicloId !== "") {
      getInitialData()
    }
  }, [cicloId])
  useEffect(() => {
    setHabilitadoSelectorCiclo(false);
    return () => {
      setHabilitadoSelectorCiclo(true);
    }
  }, [])
  const agregarNota = () => {
    let copia = notas.slice();
    let ahora = Math.round(Date.now() / 1000);
    copia.push({ momento: ahora, texto: nota });
    setNotas(copia);
    setNota("");
  }

  async function handleGrardarOnClick() {
    setCargando(true)
    if (!(await isValidFormData())) {
      setCargando(false)
      setMostrarDialogoDeCamposRequeridos(true)
      return
    }

    guardar_o_modificar_Empleado()
  }

  function guardar_o_modificar_Empleado() {
    if (!isModoEdicion()) {
      guardarEmpleado()
    } else {
      modificarEmpleado()
    }
  }

  async function guardarEmpleado() {

    if (!(await validarUnicoCodigoEmpleado(codigoEmpleado))) {

      setCargando(false);
      activarModalMensaje("Alerta", "Este código de usuario ya está en uso");
      return;
    };
    if (isDepartamentoEmpaqueSeleccionado && rolEmpaque && rolEmpaque.nombre === "Empacador" && empacadora) {
      let validacionEmpacadora = await validarUnicoCodigoEmpacadora(cicloId, empacadora.id)
      if (!validacionEmpacadora) {

        setCargando(false);
        activarModalMensaje("Alerta", "Este código de empacadora ya está en uso");
        return;
      };
    }

    isDepartamentoEmpaqueSeleccionado() ?
      guardarEmpleadoEmpaque(cicloId,
        nombre, apellidos,
        generoSeleccionado.id,
        dateToUnixTime(fechaIngreso),
        dateToUnixTime(fechaNacimiento),
        numeroSeguroSocial, curp, codigoEmpleado,
        departamentoSeleccionado.id,
        invernaderoVirtualSeleccionado.id,
        empaqueSeleccionado.id,
        departamentoSeleccionado.nombre,
        salarioDiario, diasVacaciones, notas,
        rolEmpaque, empacadora).then((registroExitoso) => {
          if (registroExitoso) {
            consultarEmpleados()
          } else {

          }
        })
      :
      RhService.guardarEmpleado(
        cicloId,
        nombre, apellidos,
        generoSeleccionado.id,
        dateToUnixTime(fechaIngreso),
        dateToUnixTime(fechaNacimiento),
        numeroSeguroSocial, curp, codigoEmpleado,
        departamentoSeleccionado.id,
        invernaderoVirtualSeleccionado.id,
        empaqueSeleccionado.id,
        departamentoSeleccionado.nombre,
        salarioDiario, diasVacaciones, notas,
        rolEmpaque, empacadora, rolInvernaderoSeleccionado).then((registroExitoso) => {
          if (registroExitoso) {
            consultarEmpleados()
          } else {

          }
        });
  }
  const hayCambioEmpaqueAInvernadero = (params) => {
    let empaque = departamentos.find(el => el.nombre === "Empaque");
    let invernadero = departamentos.find(el => el.nombre === "Invernadero");
    return empaque.id === departamentoOriginal && departamentoSeleccionado.id === invernadero.id;
  }
  const hayCambioInvernaderoAEmpaque = (params) => {
    let empaque = departamentos.find(el => el.nombre === "Empaque");
    let invernadero = departamentos.find(el => el.nombre === "Invernadero");
    return invernadero.id === departamentoOriginal && departamentoSeleccionado.id === empaque.id;
  }

  async function modificarEmpleado() {
    if (hayCambioEmpaqueAInvernadero()) {

      let result = await RhService.modificarEmpleadoEmpaqueAInvernadero(
        empleadoId,
        nombre,
        apellidos,
        generoSeleccionado.id,
        dateToUnixTime(fechaIngreso),
        dateToUnixTime(fechaNacimiento),
        numeroSeguroSocial,
        curp,
        codigoEmpleado,
        salarioDiario,
        diasVacaciones,
        departamentoSeleccionado.nombre,
        invernaderoVirtualSeleccionado.id,
        empaqueSeleccionado.id,
        cicloId,
        notas,
        departamentoSeleccionado.id, rolInvernaderoSeleccionado?.id);
      consultarEmpleados()
    } else if (hayCambioInvernaderoAEmpaque()) {
      let result = await modificarEmpleadoInvernaderoAEmpaque(empleadoId, nombre, apellidos,
        generoSeleccionado.id,
        dateToUnixTime(fechaIngreso),
        dateToUnixTime(fechaNacimiento),
        numeroSeguroSocial, curp, codigoEmpleado,
        salarioDiario, diasVacaciones,
        departamentoSeleccionado.nombre,
        invernaderoVirtualSeleccionado.id,
        empaqueSeleccionado.id,
        cicloId,
        notas, rolEmpaque, empacadora, departamentoSeleccionado.id);
      consultarEmpleados()
    } else {

      if (isDepartamentoEmpaqueSeleccionado && rolEmpaque && rolEmpaque.nombre === "Empacador") {
        let validacionEmpacadora = await validarUnicoCodigoEmpacadora(cicloId, empacadora.id)
        if (!validacionEmpacadora) {

          setCargando(false);
          activarModalMensaje("Alerta", "Este código de empacadora ya está en uso");
          return;
        };
      }

      isDepartamentoEmpaqueSeleccionado() ?
        modificarEmpleadoEmpaque(
          empleadoId, nombre, apellidos,
          generoSeleccionado.id,
          dateToUnixTime(fechaIngreso),
          dateToUnixTime(fechaNacimiento),
          numeroSeguroSocial, curp, codigoEmpleado,
          salarioDiario, diasVacaciones,
          departamentoSeleccionado.nombre,
          invernaderoVirtualSeleccionado.id,
          empaqueSeleccionado.id,
          cicloId,
          notas, rolEmpaque, empacadora).then((modificacionExitosa) => {
            if (modificacionExitosa) {
              consultarEmpleados()
            } else {

            }
          }).catch(() => {
            setCargando(false)
          })
        :
        RhService.modificarEmpleado(
          empleadoId, nombre, apellidos,
          generoSeleccionado.id,
          dateToUnixTime(fechaIngreso),
          dateToUnixTime(fechaNacimiento),
          numeroSeguroSocial, curp, codigoEmpleado,
          salarioDiario, diasVacaciones,
          departamentoSeleccionado.nombre,
          invernaderoVirtualSeleccionado.id,
          empaqueSeleccionado.id,
          cicloId,
          notas, "", rolInvernaderoSeleccionado?.id).then((modificacionExitosa) => {
            if (modificacionExitosa) {
              consultarEmpleados()
            } else {

            }
          }).catch(() => {
            setCargando(false)
          });
    }
  }

  async function activar_o_desactivar_Empleado() {
    let id = empleadoId;
    if (isDepartamentoEmpaqueSeleccionado()) {
      let empleado = await obtenerEmpleadoEmpaqueCiclo(cicloId, empaqueSeleccionado.id, empleadoId);
      id = empleado.empleado_ref

    }
    if (empleadoActivo) {
      RhService.desactivarEmpleado(id).then(desactivadoExitosamente => {
        if (desactivadoExitosamente) consultarEmpleados()
      });
    } else {
      RhService.activarEmpleado(id).then(desactivadoExitosamente => {
        if (desactivadoExitosamente) consultarEmpleados()
      });
    }
  }

  async function isValidFormData() {

    if (nombre === "") return false
    if (apellidos === "") return false
    if (generoSeleccionado === "" || generoSeleccionado === null) return false
    if (fechaIngreso === "") return false
    if (fechaNacimiento === "") return false
    if (numeroSeguroSocial === "") return false
    if (curp === "") return false
    if (codigoEmpleado === "") return false
    if (!departamentoSeleccionado.id) return false
    if (departamentoSeleccionado.nombre === "Invernadero" && !invernaderoVirtualSeleccionado.id && !rolInvernaderoSeleccionado.id) return false
    if (departamentoSeleccionado.nombre === "Empaque" && !empaqueSeleccionado.id) return false
    if (salarioDiario === "" && !isDepartamentoAdministracionSeleccionado()) return false
    if (diasVacaciones === "") return false

    return true
  }
  useEffect(() => {






    if (generos.length > 0 && departamentos.length > 0 && (empaques.length > 0) && rolesEmpaque.length > 0) {
      setModoEdicion_siEsNecesario();
    }
  }, [generos, departamentos, empaques, rolesInvernadero, empacadoras])
  var getInitialData = async () => {
    try {
      setCargando(true)
      await obtenerGeneros()
      await obtenerDepartamentos()
      await obtenerEmpaques()
      await obtenerPrimerCicloYSusInvernaderosVirtuales()
      await asignarRolesEmpaque();
      await asignarRolesInvernadero()
      await asignarEmpacadoras();
      setCargando(false)
    } catch (error) {
      if (error.code && error.code === "permission-denied") {
        setMostrarModalPermiso(true);
      }
    } finally {
      setCargando(false);
    }

  }
  const asignarRolesEmpaque = async (params) => {
    let roles = await obtenerRolesEmpaque();
    setRolesEmpaque(roles);
  }
  const asignarRolesInvernadero = async (params) => {
    let roles = await obtenerRolesInvernadero();
    setRolesInvernadero(roles);
  }
  const asignarEmpacadoras = async (params) => {
    let empacadoras = await obtenerEmpacadoras();
    setEmpacadorasOrig(empacadoras);
  }
  useEffect(() => {



    let empacadoras = empacadorasOrig.filter(el => el.locacion_ref === empaqueSeleccionado["locacion_ref"])
    // push element to array empacadoras at index 0 
    empacadoras.unshift({ id: 0, nombre: "Seleccione una empacadora" })
    setEmpacadoras(empacadoras);
  }, [empaqueSeleccionado])
  async function setModoEdicion_siEsNecesario() {


    if (modoEdicion || !props.location.state) return
    setModoEdicion(true);


    let empleado = props.location.state.empleado
    console.log({empleadoLLegado: empleado})
    let reportes = reportesMedicos || await obtenerReportesMedicosEmpleado(cicloId, empleado.empleado_ref || empleado.id)
    setReportesMedicos(reportes)

    setEmpleadoId(empleado.id)
    setNombre(empleado.nombre)
    setApellidos(empleado.apellidos)
    setGeneroFromId(empleado.genero_ref)
    setFechaIngreso(unixTimeToDate(empleado.fecha_ingreso))
    setFechaNacimiento(unixTimeToDate(empleado.fecha_nacimiento))
    setNumeroSeguroSocial(empleado.numero_seguro_social)
    setCurp(empleado.curp)
    setCodigoEmpleado(empleado.codigo_empleado)
    setDepartamentoFromId(empleado.departamento_ref, empleado.subdepartamento_ref, empleado.id)
    setSalarioDiario(empleado.salario_diario)
    setDiasVacaciones(empleado.dias_vacaciones)
    setEmpleadoActivo(empleado.activo)
    setDepartamentoOriginal(empleado.departamento_ref);
    console.log({rolesInvernadero, empleado})
    if(empleado.rol_invernadero_ref){
      setRolInvernaderoSeleccionado(rolesInvernadero.find((rol) => rol.id === empleado.rol_invernadero_ref))
    }
    if (empleado.notas) {
      setNotas(empleado.notas);
    }

  }

  function setGeneroFromId(generoId) {
    RhService.getGenero(generoId).then(genero => { setGeneroSeleccionado(genero) })
  }

  async function setDepartamentoFromId(departamentoId, subdepartamentoId, empleadoId) {
    RhService.getDepartamento(departamentoId).then(async departamento => {
      setDepartamentoSeleccionado(departamento)

      if (departamento.nombre === 'Invernadero') {
        RhService.getInvernaderoVirtualDeEmpleado(cicloId, empleadoId).then(invernaderoVirtual => {
          setInvernaderoVirtualSeleccionado(invernaderoVirtual)
        });
      } else if (departamento.nombre === 'Empaque') {
        let empleadoCiclo = await obtenerEmpleadoEmpaqueCiclo(cicloId, subdepartamentoId, empleadoId);


        // 
        let rol = rolesEmpaque.find(el => el.id === empleadoCiclo.rol_ref);
        setRolEmpaque(rol)
        if (rol && rol.nombre === "Empacador") {
          let empacadoras = await obtenerEmpacadoras();



          let empacadora = empacadoras.find(el => el.id === empleadoCiclo.codigo_empacadora);

          setEmpacadora(empacadora);
        }
        RhService.getEmpaque(subdepartamentoId).then(empaque => {
          setEmpaqueSeleccionado(empaque)
        })
      }
    })
  }

  async function obtenerDepartamentos() {
    return RhService.getDepartamentos().then(departamentos => {
      setDepartamentos(departamentos)
    })
  }

  async function obtenerEmpaques() {
    return RhService.getEmpaques().then(empaques => setEmpaques(empaques))
  }

  async function obtenerGeneros() {
    return RhService.getGeneros().then(generos => setGeneros(generos))
  }

  async function obtenerPrimerCicloYSusInvernaderosVirtuales() {
    let cicloId = props.match.params.cicloId
    return InvernaderoService.getInvernaderosVirtualesActivosDeCiclo(cicloId).then((invernaderosVirtuales) => { // bug - cicloId vacío
      setInvernaderosVirtuales(invernaderosVirtuales)
    })
  }
  const handleDepartamentoChange = (e) => {

    setDepartamentoSeleccionado(e.value)
  }


  var isDepartamentoInvernaderoSeleccionado = () => { return departamentoSeleccionado.nombre === "Invernadero" }
  var isDepartamentoEmpaqueSeleccionado = () => { return departamentoSeleccionado.nombre === "Empaque" }
  var isDepartamentoAdministracionSeleccionado = () => { return departamentoSeleccionado.nombre === "Administración" }
  var isModoEdicion = () => { return empleadoId !== "" }
  var consultarEmpleados = () => { props.history.goBack() }

  const irReporteMedico = () => {
    props.history.push({
      pathname: "/reporte_medico/consultar",
      data: {
        empleadoNombrePassed: `${nombre.trim()} ${apellidos.trim()}`, departamento: departamentoSeleccionado,
        invernadero: invernaderoVirtualSeleccionado, empaque: empaqueSeleccionado
      }
    });
  }

  const obtenerHeader = (params) => {
    // 
    let departamento = departamentoSeleccionado;
    let empaque = empaqueSeleccionado;

    if (departamento && departamento.nombre == "Invernadero") {
      let invernadero = invernaderoVirtualSeleccionado;
      // 

      const headerInv = <>  <div className={`card-id icon-general icon-id bor-${invernadero.color} bg-${invernadero.color}`}>{invernadero.nombre}</div>
      </>
      return headerInv
    }

    else if (departamento && departamento.nombre == "Empaque") {
      // 
      // 
      let invernadero = {
        nombre: empaque.nombre,
        icono: DETALLES_DEPARTAMENTO_EMPAQUE.icono,
        color: DETALLES_DEPARTAMENTO_EMPAQUE.color
      };
      // 
      const headerEmpaque = <><div className={`card-id icon-general icon-id bg-${invernadero.color}  bor-${invernadero.color}`}>{invernadero.icono ? <FontAwesomeIcon icon={invernadero.icono} /> : null}</div></>;
      return headerEmpaque
    } else {
      // 
      let invernadero = {
        nombre: "OTROS",
        icono: DETALLES_DEPARTAMENTO_OTROS.icono,
        color: DETALLES_DEPARTAMENTO_OTROS.color
      };
      const headerOtro = <span ><div className={`card-id icon-general icon-id bg-${invernadero.color}  bor-${invernadero.color}`}>{invernadero.icono ? <FontAwesomeIcon icon={invernadero.icono} /> : null}</div></span>;;
      return headerOtro;
    }




  }
  useEffect(() => {
    console.log({rolInvernaderoSeleccionado})
  }, [rolInvernaderoSeleccionado])
  return (
    <div className="p-grid p-justify-center">
      <div className="p-col p-col-auto title-container">
        <span className="back-btn" onClick={atras}></span>
        {//<span className="icon-general user-icon"></span>
        }
        {obtenerHeader()
        }
        <span className="titles-group">
          {isModoEdicion() ?
            <React.Fragment>
              <h1 className="header-title">
                Modificar empleado
              </h1>
              <p className="header-subtitle">Empleado {!empleadoActivo ? 'deshabilitado' : 'habilitado'}</p>
            </React.Fragment>
            : <React.Fragment>
              <h1 className="header-title">
                Crear empleado
              </h1>
              {departamentoSeleccionado && !["Empaque", "Invernadero"].includes(departamentoSeleccionado.nombre) ? <p className="header-subtitle"> OTROS </p> : null}
            </React.Fragment>
          }

        </span>
      </div>
      {reportesMedicos?.length > 0 &&
        <div className="p-col p-col-auto">
          <div className="btn-row">
            <Button onClick={irReporteMedico} label="Ver reporte médico" />
          </div>
        </div>}

      <div className="p-col-10">
        <div className="p-grid p-fluid ">
          <div className="p-col-12">
            <div className="card">
              <div className="p-grid">
                <div className="form-group p-col-12 p-md-6">
                  <label htmlFor="nombre">Nombre</label>
                  <InputText id="nombre" value={nombre} disabled={!puedeEditar() || !empleadoActivo} onChange={e => setNombre(e.target.value)} />
                </div>
                <div className="form-group p-col-12 p-md-6">
                  <label htmlFor="apellidos">Apellidos</label>
                  <InputText id="apellidos" value={apellidos} disabled={!puedeEditar() || !empleadoActivo} onChange={e => setApellidos(e.target.value)} />
                </div>
                <div className="form-group p-col-12 p-md-6 select-2">
                  <label htmlFor="genero">Genero</label>
                  <SelectButton id="genero"
                    disabled={!puedeEditar() || !empleadoActivo}
                    dataKey="id"
                    optionLabel="nombre"
                    options={generos}
                    value={generoSeleccionado}
                    onChange={(e) => { setGeneroSeleccionado(e.value) }}
                    className="p-buttonset-2" />
                </div>
                <div className="form-group p-col-12 p-md-3 ">
                  <label htmlFor="fechaIngreso">Fecha de ingreso</label>
                  <Calendar id="fechaIngreso" disabled={!puedeEditar() || !empleadoActivo} readOnlyInput={true} dateFormat="dd/mm/yy"
                    value={fechaIngreso} monthNavigator={true} yearNavigator={true} yearRange="1900:2030"
                    onChange={e => setFechaIngreso(e.value)} />
                </div>
                <div className="form-group p-col-12 p-md-3 ">
                  <label htmlFor="fechaNacimiento">Fecha de nacimiento</label>
                  <Calendar id="fechaNacimiento" disabled={!puedeEditar() || !empleadoActivo} readOnlyInput={true} dateFormat="dd/mm/yy"
                    value={fechaNacimiento} monthNavigator={true} yearNavigator={true} yearRange="1900:2030"
                    onChange={e => setFechaNacimiento(e.value)} />
                </div>
              </div>
            </div>
          </div>

          <div className="p-col-12">
            <p className="section-title">Identificación</p>

            <div className="card">
              <div className="p-grid">

                <div className="form-group p-col-12 p-md-4 p-sm-6">
                  <label htmlFor="numeroSeguroSocial">Número de seguro social</label>
                  <InputText id="numeroSeguroSocial" disabled={!puedeEditar() || !empleadoActivo} value={numeroSeguroSocial} onChange={e => setNumeroSeguroSocial(e.target.value)} />
                </div>
                <div className="form-group p-col-12 p-md-4 p-sm-6">
                  <label htmlFor="curp">CURP</label>
                  <InputText id="curp" disabled={!empleadoActivo || !puedeEditar()} value={curp} onChange={e => setCurp(e.target.value)} />
                </div>
                <div className="form-group p-col-12 p-md-4 p-sm-6">
                  <label htmlFor="codigoEmpleado">Código de empleado</label>
                  <InputText id="codigoEmpleado" disabled={!puedeEditar() || !empleadoActivo} value={codigoEmpleado} onChange={e => setCodigoEmpleado(e.target.value)} />
                </div>
              </div>
            </div>
          </div>

          <div className="p-col-12">
            <p className="section-title">Información laboral</p>
            <div className="card">
              <div className="p-grid">

                <div className="form-group p-col">
                  <label htmlFor="departamento">Departamento</label>
                  <Dropdown id="departamento" placeholder="Seleccionar"
                    disabled={!puedeEditar() && !empleadoActivo}
                    dataKey="id"
                    filter={true} filterPlaceholder="Buscar departamento"
                    optionLabel="nombre"
                    options={departamentos}
                    value={departamentoSeleccionado}
                    onChange={handleDepartamentoChange} />
                </div>
                {isDepartamentoInvernaderoSeleccionado() &&
                  <div className="form-group p-col">
                    <label htmlFor="invernaderoVirtual">Invernadero virtual</label>
                    <Dropdown id="invernaderoVirtual" placeholder="Seleccionar"
                      disabled={!puedeEditar() || !empleadoActivo}
                      dataKey="id"
                      optionLabel="nombre"
                      options={invernaderosVirtuales}
                      value={invernaderoVirtualSeleccionado}
                      onChange={(e) => { setInvernaderoVirtualSeleccionado(e.value) }} />
                  </div>
                }
                {isDepartamentoEmpaqueSeleccionado() &&
                  <div className="form-group p-col">
                    <label htmlFor="empacadora">Empacadora</label>
                    <Dropdown id="empacadora" placeholder="Seleccionar"
                      disabled={!puedeEditar() || !empleadoActivo}
                      dataKey="id"
                      optionLabel="nombre"
                      options={empaques}
                      value={empaqueSeleccionado}
                      onChange={(e) => { setEmpaqueSeleccionado(e.value) }} />
                  </div>
                }
              </div>
              <div className="p-grid">
                {!isDepartamentoAdministracionSeleccionado() &&
                  <div className="form-group p-col-12 p-md-6">
                    <label htmlFor="salarioDiario">Salario diario</label>
                    <InputText id="salarioDiario" disabled={!puedeEditar() || !empleadoActivo} keyfilter="num" value={salarioDiario} onChange={e => setSalarioDiario(e.target.value)} />
                  </div>
                }
                <div className="form-group p-col-12 p-md-6">
                  <label htmlFor="vacaciones">Días de vacaciones</label>
                  <InputText id="vacaciones" disabled={!puedeEditar() || !empleadoActivo} keyfilter="int" value={diasVacaciones} onChange={e => setDiasVacaciones(e.target.value)} />
                </div>
              </div>
            </div>
          </div>

        </div>

        {isDepartamentoEmpaqueSeleccionado() ? <SeccionFormulario titulo="Detalles Empaque">
          <ContenedorInput label="Rol Empaque" col="12" md="6" sm="4">
            <Dropdown options={rolesEmpaque} value={rolEmpaque} optionLabel="nombre" dataKey="id" onChange={e => setRolEmpaque(e.value)} />
          </ContenedorInput>
          {rolEmpaque && rolEmpaque.nombre === "Empacador" ? <ContenedorInput label="Código empacadora" col="12" md="6" sm="4">
            <Dropdown options={empacadoras} value={empacadora} optionLabel="nombre" dataKey="id" onChange={e => setEmpacadora(e.value)} />
          </ContenedorInput> : null}
        </SeccionFormulario> : null}
        
        {isDepartamentoInvernaderoSeleccionado() && <SeccionFormulario titulo="Detalles de invernadero">
          <ContenedorInput label="Rol Invernadero" col="12" md="6" sm="4">
            <Dropdown options={rolesInvernadero} value={rolInvernaderoSeleccionado} optionLabel="nombre" dataKey='id' onChange={e => setRolInvernaderoSeleccionado(e.value)} />
          </ContenedorInput>
        </SeccionFormulario>}

        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <p className="section-title">Información adicional</p>
            <div className="card">
              <div className="p-grid ">
                <div className="">
                  <ul className="m-0 lista-notas">
                    {notas.map((nota, index) => {
                      return <li key={index}>{obtenerNombreFechaCompacto(nota.momento)} - {nota.texto}</li>
                    })}
                  </ul>
                </div>
                <div className="form-group p-col-12">
                  <label htmlFor="nuevaNota">Agregar nota a empleado</label>
                  <InputTextarea disabled={!puedeEditar() || !empleadoActivo} rows={2} cols={30} autoResize={true} id="nota" value={nota} onChange={e => setNota(e.target.value)} />
                </div>


              </div>




              {puedeEditar() ? <Button onClick={agregarNota} label="Agregar nota" /> : null}

            </div>
          </div>
        </div>
      </div>
      <div className="p-col-12">
        <div className="p-grid p-justify-end btn-row">
          <Button label="Cancelar" onClick={() => { consultarEmpleados() }} />
          {puedeEditar() && isModoEdicion() && <Button label={empleadoActivo ? "Deshabilitar" : "Habilitar"} onClick={() => { activarModalConfirmacion() }} />}
          {puedeEditar() && empleadoActivo && <Button label="Guardar" onClick={() => { handleGrardarOnClick() }} />}
        </div>
      </div>

      <Dialog header="Alerta" modal={true}
        visible={mostrarDialogoDeCamposRequeridos}
        onHide={() => { setMostrarDialogoDeCamposRequeridos(false) }} >
        Debes llenar todos los campos
      </Dialog>
    </div>
  );
}
export default RegistrarEmpleadoForm;//733