import React, { useState, useGlobal, useEffect } from 'reactn';
import { Card } from 'primereact/card';
import { withRouter } from 'react-router-dom';
import { dosDeciComas, dosDecimales } from '../../util/functions';
import icono from '../../images/icons/external-icon.svg';

const InvernaderoCard = (props) => {
  const [, setInvernadero] = useGlobal("invernadero");
  const [usuario,] = useGlobal("usuario");
  const [, setProveedor] = useGlobal("proveedorExternoCalidad");
  const [, setAviso] = useState(true);

  useEffect(() => {
    if (props.invernadero?.budget?.estimacion_semanal?.length > 0) { setAviso(false); }
  }, [])
  const puedeConsultar = () => {
    return ["Administrador", "Administrador Calidad", "Auxiliar Calidad", "Auxiliar Grower", "Administrador Inocuidad", "Grower",
      "Administrador Empaque", "Grower", "Auxiliar Empaque"].includes(usuario?.rol);
  }
  const consultar = () => {
    if (puedeConsultar()) {
      setInvernadero(props.invernadero);
      if (props.proveedor) setProveedor(props.proveedor)
      //console.log(props.invernadero)

      if (props.goToCards) props.history.push("/calidad/proveedores_externos");
      else props.history.push("/calidad/semanal");
    }
  }
  const header = <span className="card-header">
    <div className={`card-id icon-general icon-lort icon-id bor-gray bor-${props.invernadero.color} bg-gray bg-${props.invernadero.color}`}><img width="30" alt="icono" src={icono} /></div><div>
      {props.proveedor
        ? <p className="card-up">{props.proveedor.nombre}</p>
        : <p className="card-up">Externo - {props.invernadero.nombre}</p>}
    </div>
  </span>;

  return (
    <div className="p-col-12 p-md-4 p-sm-6" onClick={consultar}>
      <Card header={header} className={`htg-card bor-${props.invernadero.color} habilitado`} >
        <div className="p-grid p-justify-center text-center">
          <div className="p-col">
            <div className="card-block">
              <p className="value"> {isNaN(props.info.pesoCaja) ? "-" : dosDecimales(props.info.pesoCaja)} kg </p>
              <p className="label">Peso caja</p>
            </div>
          </div>
          <div className="p-col">
            <div className="card-block">
              <p className="value"> {isNaN(props.info.pesoFruto) ? "-" : dosDeciComas(Math.round(100 * 1000 * props.info.pesoFruto) / 100)} gr </p>
              <p className="label">Peso fruto</p>
            </div>
          </div>
          <div className="p-col">
            <div className="card-block">
              <p className="value"> {isNaN(props.info.defectos) ? "-" : dosDecimales(props.info.defectos)} % </p>
              <p className="label">Defectos</p>
            </div>
          </div>
        </div>
      </Card>
    </div>);
}
export default withRouter(InvernaderoCard);