import React from 'reactn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ConsultarCardDato = (props) => {
    return (
        <div className="p-grid p-col-auto p-col">
            <div className="p-col-auto">
                <FontAwesomeIcon icon={props.icono} />
            </div>
            <div className="p-col ">
                <div>{props.valor}</div>
                <label>{props.texto}</label>
            </div>
        </div>);
}
export default ConsultarCardDato;