import React from 'react'
import { formatearMinutosAHoras } from '../../../util/functions'
import BaseCard from '../../common/cards/BaseCard'
import SeccionDatosCenter from '../../common/SeccionDatosCenter'
import DatoCard from './DatoCard'

export default function CardDia(props) {

    return (
        <BaseCard col="12" md="4" tituloCard={props.dia.nombre} onClick={props.onClick}>


            <SeccionDatosCenter>
                <DatoCard label="Planeación estimada"
                    valor={formatearMinutosAHoras(props["resumenDia"]["estimacion"])} />
                {props.resumenDia.tiempoRealizada &&
                    <DatoCard label="Tareas realizadas"
                        valor={formatearMinutosAHoras(props["resumenDia"]["tiempoRealizada"])} />}
                {props.resumenDia.tiempoEmergencias &&
                    <DatoCard label="Emergencias realizadas"
                        valor={formatearMinutosAHoras(props["resumenDia"]["tiempoEmergencias"])} />}
                <DatoCard label="Tareas completadas"
                    valor={props["resumenDia"]["completadas"]} />
            </SeccionDatosCenter>

        </BaseCard>


    )
}
