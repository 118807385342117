import React, { useState, useGlobal, useEffect } from "reactn";
import { obtenerReporteEmpaqueExterno } from "../../service/ReportesProducto";
import { obtenerCiclo } from "../../service/Ciclos";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { dosDeciComas, dosDecimales, ordenarArreglaDeObjetosPorLlave } from "../../util/functions";
import { obtenerListaDeSemanas } from "../../service/fechas";
import { MultiSelect } from "primereact/multiselect";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { getProveedoresInvernadero, obtenerProveedores } from "../../service/Queries/Proveedores";
import { Button } from "primereact/button";
import ContenedorPrincipal from "../planeacion_mantenimiento/CapturaAgentes/ContenedorPrincipal";
import ContenedorHeader from "../common/ContenedorHeader";
import RowBotonesTopThin from "../common/RowBotonesTopThin";
import ContenedorTabla from "../common/ContenedorTabla";
import SeccionFiltros from "../common/SeccionFiltros";
import ContenedorFiltro from "../common/ContenedorFiltro";
import { camelizeWord } from "service/utils/stringManipulations.utils";
const optionsButton = [{ label: "INICIAL", value: "inicial" }];

const ReporteInvernaderoExterno = (props) => {
    const [ciclo] = useGlobal("ciclo");
    const [cicloObj] = useGlobal("cicloObj");
    const [tabla, setTabla] = useState([]);
    const [invernaderosExternos] = useGlobal("invernaderosExternos")
    const [proveedoresFiltros, setProveedoresFiltros] = useState([]);
    const [proveedoresOpciones, setProveedoresOpciones] = useState([]);
    const [productosYVariedades] = useGlobal("productosYVariedades")
    const [tablaTotales, setTablaTotales] = useState([]);
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [, setCargando] = useGlobal("cargando");    

    const organizarProveedores = () => {
        const valores = Object.entries(tablaTotales[0] || {})
        const proveedoresConTotalDeKilos = valores.reduce((acc, valoresProv) => {
            const [nombre, value] = valoresProv
            const prov = proveedoresFiltros.find((prov) => prov.nombre === nombre)
            if(!prov) return acc
            acc.push({ ...prov, total_kilos: value })
            return acc
        }, [])
        const newProveedoresFiltrados = proveedoresConTotalDeKilos.sort((a,b) => b.total_kilos - a.total_kilos )
        return newProveedoresFiltrados
    }

    useEffect(() => {
        setNombreModulo("Compra externa");
    }, [])

    useEffect(() => {
        if (ciclo) { obtenerDatosIniciales(); }
    }, [ciclo])

    const obtenerDatosIniciales = async (params) => {
        setCargando(true);
        let proveedores = await obtenerProveedores(ciclo);
        let reporteEmpaqueExterno = await obtenerReporteEmpaqueExterno(ciclo, proveedores, invernaderosExternos);
        reporteEmpaqueExterno = filtrarSiHayDatosCompraExterna(reporteEmpaqueExterno);
        procesarReporte(reporteEmpaqueExterno, proveedores);
        setCargando(false);
    }
    const filtrarSiHayDatosCompraExterna = (reporteEmpaqueExterno = []) => {
        const proveedoresConDatos = reporteEmpaqueExterno?.filter(proveedorDatos => {
            const hayDatos = proveedorDatos.some(datos => {
                const datoSemanal = parseFloat(datos[2]);//Obtener valor de kg/m2 semanal
                return isNaN(datoSemanal) ? false : datoSemanal > 0;
            })
            return hayDatos;
        })
        return proveedoresConDatos;
    }
    const procesarReporte = (values, proveedoresPassed) => {
        let data = [], proveedores = [], proveedoresOpciones = [];
        let semanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
        semanas.forEach(semana => {
            let row = {};
            row.semana = semana.nombre;
            values.forEach(proveedor => {

                let matchSem = proveedor.find(sem => {
                    let [prov, semanaVal, cant] = sem;
                    return semanaVal.time === semana.time
                })

                if (matchSem) {
                    let [prov, semanaVal, cant] = matchSem;
                    let found = proveedores.find(el => el.id === prov.id);
                    if (!found) { proveedores.push({ ...prov }); }
                    row[prov.nombre] = cant;
                } else { row[proveedor.nombre] = 0; }

            })
            data.push(row);
        })

        ordenarArreglaDeObjetosPorLlave(proveedores, "nombre");
        setProveedoresOpciones(proveedores);
        setProveedoresFiltros(proveedores);

        let row = {}; 
        const areasRow = {total_kilos: "Área del invernadero", isAreas: true}
        row.total_kilos = "Total del ciclo";

        values.forEach(proveedor => {
            let [proveedorData] = proveedor[0];
            proveedor.forEach(sem => {
                let [prov, semanaVal, cant] = sem;

                if (row[prov.nombre]) { 
                    row[prov.nombre] += cant; 
                } else { 
                    row[prov.nombre] = cant; 
                }
            })

            areasRow[proveedorData.nombre] = parseFloat(proveedorData.area_invernadero || 0)
        })
        setTablaTotales([row, areasRow]);
        setTabla(data);
    }

    function unidadTemplate(rowData, column) {
        const value = rowData[column["field"]]
        const valueToRender = rowData.isProduct ? value : dosDecimales(value)
        const unit = `${rowData.isAreas ? "m²": "kg/m²"}`
        const valueFormatted = rowData.isAreas ? dosDeciComas(valueToRender) : valueToRender
        return <div>{valueFormatted}<span className="label-unidad">{rowData.isProduct || unit}</span> </div>;
    }
    function obtenerColumnas() {
        let cols = [<Column field="semana" className="col__label" header="Semana" ></Column>]
        const proveedoresFiltradosYOrganizados = organizarProveedores()
        proveedoresFiltradosYOrganizados.map(el => {
            cols.push(<Column body={unidadTemplate} field={el.nombre} header={el.textFormated} />)
        })
        return cols;
    }
    function obtenerColumnasTotales() {
        let cols = [<Column field="total_kilos" className="col__label" header="" ></Column>]
        const proveedoresFiltradosYOrganizados = organizarProveedores()
        proveedoresFiltradosYOrganizados.map(el => {
            cols.push(<Column body={unidadTemplate} field={el.nombre} header={el.textFormated} />)
        })
        return cols;
    }
    const obtenerHeader = () => {
        let columns = [<Column header="" rowSpan={1} className="col__label"></Column>]
        const proveedoresFiltradosYOrganizados = organizarProveedores()
        proveedoresFiltradosYOrganizados.forEach(proveedor => {
            columns.push(<Column rowSpan={1} className="col__label" header={<div className={``}>{proveedor.nombre}</div>} ></Column>)
        });
        let headerGroup = <ColumnGroup>
            <Row>
                {columns}
            </Row>
        </ColumnGroup>;
        return headerGroup;
    }
    return (
        <ContenedorPrincipal >
            <ContenedorHeader atras={props.history.goBack} invernadero titulo="Reporte general de empaque compra externa" subtitulo="Proveedores externos" iconos="boxes-icon" col="p-col p-col-auto" />

            <RowBotonesTopThin>
                <Button label="Registro de cosecha" onClick={() => props.history.push("/producto/registrar_empaque_externo")} />
            </RowBotonesTopThin>

            <SeccionFiltros>
                <ContenedorFiltro label="proveedores">
                    <MultiSelect value={proveedoresFiltros} options={proveedoresOpciones} onChange={(e) => setProveedoresFiltros(e.value)}
                        dataKey="id" optionLabel="nombre" selectedItemsLabel={`${proveedoresFiltros?.length} proveedores seleccionados`} placeholder="Seleccionar" />
                </ContenedorFiltro>
            </SeccionFiltros>

            <ContenedorTabla titulo="Reporte general">
                <DataTable value={tablaTotales} headerColumnGroup={obtenerHeader()}>
                    {obtenerColumnasTotales()}
                </DataTable>
            </ContenedorTabla>

            <ContenedorTabla titulo="Reporte semanal">
                <DataTable className="tabla-empaque-reporte" value={tabla} scrollable={true} headerColumnGroup={obtenerHeader()}>
                    {obtenerColumnas()}
                </DataTable>
            </ContenedorTabla>
        </ContenedorPrincipal>)
}
export default ReporteInvernaderoExterno;//207
