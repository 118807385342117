import React from 'reactn';
import { Dialog } from 'primereact/dialog';
import { DETALLES_TIPO_INCIDENCIA_CALIDAD } from '../../../constants';

const ResumenDeIncidenciasModal = (props) => {
    return (
        <Dialog header="Motivos de evaluación" modal={true}
            visible={props.visible} onHide={props.onHide}>
            {props.claroSeleccionado.enfermedades.length > 0 &&
                <span className="modal-subtitle">{`Semana ${props.week.numero}:`}</span>
            }
            {props.detallesEnfermedades.actuales.map((detallesEnfermedad) => {
                //window.alert(detallesEnfermedad.nivelIncidencia)
                return (
                    <li className="enfermedades-item" key={detallesEnfermedad.id}>
                        <span className={`enfermedad-name actual prioridad-${props.makeNombre(detallesEnfermedad)} nivel-incidencia-cal-${detallesEnfermedad.nivelIncidencia}`}>
                            {`${detallesEnfermedad.nombre}`} </span>
                        <span className={`icon-incidencia nivel-incidencia-cal-${detallesEnfermedad.nivelIncidencia}`}></span>
                        {`${DETALLES_TIPO_INCIDENCIA_CALIDAD.porcentaje[detallesEnfermedad.nivelIncidencia].textoConsulta}`}
                    </li>
                )
            })}
            {props.claroSeleccionado.enfermedadesPasadas.length > 0 &&
                <span className="modal-subtitle">{`Semana ${props.semanaPasadaSeleccionada.numero}:`}</span>
            }
            {props.detallesEnfermedades.pasadas.map((detallesEnfermedad) => {
                return (
                    <li className="enfermedades-item" key={detallesEnfermedad.id}>
                        <span className={`enfermedad-name pasada prioridad-${props.makeNombre(detallesEnfermedad)} nivel-incidencia-cal-${detallesEnfermedad.nivelIncidencia}}`}>
                            {`${detallesEnfermedad.nombre}`} </span>
                        <span className={`icon-incidencia prioridad-${props.makeNombre(detallesEnfermedad)} nivel-incidencia-cal-${detallesEnfermedad.nivelIncidencia}`}></span>
                        {`${DETALLES_TIPO_INCIDENCIA_CALIDAD.porcentaje[detallesEnfermedad.nivelIncidencia].textoConsulta}`}
                    </li>
                )
            })}
        </Dialog>
    );
}
export default ResumenDeIncidenciasModal;