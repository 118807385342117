import React, { useEffect, useState, useGlobal } from 'reactn';
import { obtenerPorcentajeHorasTrabajadasEnCiclo, obtenerCuentaEmpleadosInvernadero, obtenerActividadesEnCiclo, obtenerTodasActividadesInvernadero } from '../../service/RhService';
import { obtenerBonosSemanalesCiclo } from '../../service/nomina';
import Chart from "react-apexcharts";
import { Dropdown } from 'primereact/dropdown';
import { obtenerActividades } from 'service/actividades';
import { obtenerListaDeSemanas } from 'service/fechas';

const optionsOrig = {
  chart: {
    height: 350,
    type: 'line',
  },
  stroke: {
    width: [1, 1, 4]
  },
  title: {
    text: 'Bonos y horas trabajadas durante el ciclo'
  },
  dataLabels: {
    enabled: true,
    enabledOnSeries: [0, 1]
  },
  labels: [],
  yaxis: [
    {
      min: 0,
      max: 100,
      decimalsInFloat: 0,
      title: {
        text: 'Horas Trabajadas (%)',
      },

    }, 
    {
      min: 0,
      max: 100,
      opposite: true,
      decimalsInFloat: 0,
      title: {
        text: 'Actividades(%)'
      }
    }, 
    {
      opposite: true,
      decimalsInFloat: 2,
      title: {
        text: 'Bono($)'
      }
    }
  ]
}

const BonoHorasTrabajadas = (props) => {
  const [ciclo,] = useGlobal("ciclo");
  const [cicloObj] = useGlobal("cicloObj");
  const [, setCargando] = useGlobal("cargando");
  const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
  const [invernaderos] = useGlobal("invernaderosInternos");
  const [invernadero, setInvernadero] = useState(props.location?.data?.invernadero);
  const [options, setOptions] = useState({ ...optionsOrig })
  const [datos, setDatos] = useState([]);
  const [actividadesOrig, setActividadesOrig] = useState(null)
  const [actividadesImportantesOrig, setActividadesImportantesOrig] = useState(null)

  useEffect(() => {
    setHabilitadoSelectorCiclo(true)
  }, [])

  useEffect(() => {
    if (ciclo && invernadero && actividadesOrig && actividadesImportantesOrig) { obtenerDatosInvernadero(); }
  }, [ciclo, invernadero])

  useEffect(() => {
      getInitialData()
  }, [])

  const getInitialData = async() => {
    try {
      const actividades = await obtenerActividades()
      const actividadesImportantes = actividades.filter(act => act.importante)
      setActividadesOrig(actividades)
      setActividadesImportantesOrig(actividadesImportantes)
    } catch (error) {
      console.error("ERROR GET INITIAL DATA: ", error)
    }
      
  }
  const obtenerDatosInvernadero = async () => {
    try {
      setCargando(true);
      let porcentajeLocal = await obtenerPorcentajeLocal(cicloObj, invernadero, actividadesOrig, actividadesImportantesOrig);
  
      setDatos(procesar(porcentajeLocal));
    } catch (error) {
      console.log("ERROR OBTENER PORCENTAJE LOCAL: ", error);
    }
    setCargando(false);
  }

  const procesar = (porcentajeLocal) => {
    const { porcentaje, bonos, porcentajeActividades } = porcentajeLocal;
    let labels = porcentaje.map(el => el.semana.nombreCompacto);
    let copiaOptions = { ...options };
    copiaOptions.labels = labels;
    setOptions(copiaOptions);
    let datasets = [];

    datasets.push({
      name: "Horas trabajadas (%)",
      type: "line",
      data: porcentaje.map(el => el.porcentaje)
    });
    datasets.push({
      name: "Actividades (%)",
      type: "line",
      data: procesarActividades(porcentajeActividades)
    })
    datasets.push({
      name: "Bono ($)",
      type: "column",
      data: bonos
    });
    return datasets
  }

  const procesarActividades = (resultAct) => {
    let el = invernadero;
    let asignacion_lineas = el["asignacion_lineas"];
    let lineasTotales = asignacion_lineas.reduce((acc, curr) => {
      let dato = curr["totales"].split(" ")[0]
      if (dato) {
        dato = parseInt(dato);
        if (!isNaN(dato)) {
          return acc + dato;
        } else {
          return acc;
        }
      }
    }, 0)

    return resultAct.map(el => {
      let porcActividades = Math.round(100 * el.actividades / (lineasTotales))
      return porcActividades
    })


  }

  const obtenerPorcentajeLocal = async (ciclo, invernadero, actividadesOrig, actividadesImportantesOrig) => {
    try {
      let inicio = Date.now();
      let fin = 0;
      let dif = 0;
      let cuenta = await obtenerCuentaEmpleadosInvernadero(ciclo.id, invernadero.id);
      const registrosActividadesInv =  await obtenerTodasActividadesInvernadero(ciclo.id, invernadero.id)
      const listaSemanas = obtenerListaDeSemanas(ciclo.semana_inicio, ciclo.semana_cierre);
      fin = Date.now(0);
      dif = fin - inicio;
      let promises = [];
      promises.push(obtenerPorcentajeHorasTrabajadasEnCiclo(ciclo, registrosActividadesInv, cuenta, listaSemanas));
      promises.push(obtenerBonosSemanalesCiclo(ciclo, invernadero, actividadesOrig, listaSemanas));
      promises.push(obtenerActividadesEnCiclo(ciclo, registrosActividadesInv, actividadesImportantesOrig, listaSemanas));
      let [porcentaje, bonos, porcentajeActividades] = await Promise.all(promises);

      fin = Date.now(0);
      dif = fin - inicio;
      return { porcentaje, porcentajeActividades, bonos };
    } catch (error) {
      throw (error);
    }
  }

  return <div className="p-grid chart-page">
    {invernadero ? <div className="p-col  p-col-auto title-container">
      <span className="back-btn" onClick={() => { props.history.goBack() }}></span>

      <span className={` icon-general chart-icon  bg-${invernadero.color}  bor-${invernadero.color}`}></span>
      <span className="titles-group">
        <h1 className="header-title">Bono por horas trabajadas</h1>
        <p className={`header-subtitle `}>Invernadero {invernadero.nombre}</p>
      </span>
    </div> :
      <div className="p-col  p-col-auto title-container">
        <span className="back-btn" onClick={() => { props.history.goBack() }}></span>

        <span className={` icon-general chart-icon  `}></span>
        <span className="titles-group">
          <h1 className="header-title">Bono por horas trabajadas</h1>
        </span>
      </div>
    }
    <div className="p-col-12">
      <div className="p-grid filtros">
        <div className="form-group p-col">
          <label htmlFor="filtroCliente">Invernadero</label>
          <Dropdown value={invernadero} options={invernaderos} onChange={(e) => setInvernadero(e.value)} dataKey="id" optionLabel="nombre" />
        </div>
      </div>
    </div>
    <div className="p-col-12 chart-container-filters">
      <Chart series={datos} type="line" height="100%" options={options} />
    </div>
  </div>
}

export default BonoHorasTrabajadas;
