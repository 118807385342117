import React from 'react';
import {InputText} from "primereact/inputtext";
import {SelectButton} from 'primereact/selectbutton';
import {InputSwitch} from 'primereact/inputswitch';
import {Calendar} from 'primereact/calendar';
import {Dropdown} from 'primereact/dropdown';
import {Button} from 'primereact/button';
import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch} from '@fortawesome/free-solid-svg-icons';


function TablaDemo() {

    
    const pantalla = {edition: false};

    const options2 = [
        {label: 'Opción', value: '1'},
        {label: 'Opción', value: '2'},
    ];
    const options3 = [
        {label: 'Opción', value: '1'},
        {label: 'Opción', value: '2'},
        {label: 'Opción', value: '3'},
    ];
   const options4 = [
        {label: 'Opción', value: '1'},
        {label: 'Opción', value: '2'},
        {label: 'Opción', value: '3'},
        {label: 'Opción', value: '4'},
    ];

    const state= {
       
        detalles:[
            {dato:"12345",dato2:"ABC",dato3:"Nombre Apellido", dato4: "Habilitado",dato5:"15-10-19"},
            {dato:"12345",dato2:"ABC",dato3:"Nombre Apellido", dato4: "Habilitado",dato5:"15-10-19"},
            {dato:"12345",dato2:"ABC",dato3:"Nombre Apellido", dato4: "Habilitado",dato5:"15-10-19"},
            {dato:"12345",dato2:"ABC",dato3:"Nombre Apellido", dato4: "Habilitado",dato5:"15-10-19"},
            {dato:"12345",dato2:"ABC",dato3:"Nombre Apellido", dato4: "Habilitado",dato5:"15-10-19"},
            {dato:"12345",dato2:"ABC",dato3:"Nombre Apellido", dato4: "Habilitado",dato5:"15-10-19"},
            {dato:"12345",dato2:"ABC",dato3:"Nombre Apellido", dato4: "Habilitado",dato5:"15-10-19"},
            {dato:"12345",dato2:"ABC",dato3:"Nombre Apellido", dato4: "Habilitado",dato5:"15-10-19"},
            {dato:"12345",dato2:"ABC",dato3:"Nombre Apellido", dato4: "Habilitado",dato5:"15-10-19"},
            {dato:"12345",dato2:"ABC",dato3:"Nombre Apellido", dato4: "Habilitado",dato5:"15-10-19"},
            {dato:"12345",dato2:"ABC",dato3:"Nombre Apellido", dato4: "Habilitado",dato5:"15-10-19"},
            {dato:"12345",dato2:"ABC",dato3:"Nombre Apellido", dato4: "Habilitado",dato5:"15-10-19"},
            {dato:"12345",dato2:"ABC",dato3:"Nombre Apellido", dato4: "Habilitado",dato5:"15-10-19"},

        ]

    };
    var header = <div >
                    
                 </div>;
    return (
      <div className="p-grid p-justify-center">
        <div className="p-col  p-col-auto title-container">
          <span className="icon-general "></span>
          <span className="titles-group">
            
              <h1 className="header-title">
               Tabla de ejemplo
              </h1>
            

          </span>
        </div>
        <div className="p-col p-col-auto">

            <div className="btn-row">
                    
              <Button label="Botón de ejemplo" />
            </div>
        </div>
        <div className="p-col-12 ">

              
                <div className="p-grid filtros"> 
                      <div className="search-field form-group p-col">
                        <FontAwesomeIcon icon={faSearch} /> 
                        <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder="Búsqueda por empleado" />
                      </div>
                      <div className="form-group p-col">
                          <label htmlFor="cinco">Filtro</label>
                          <Dropdown id="cinco" placeholder="Seleccionar"
                            options={options4}
                            />
                      </div>
                      <div className="form-group p-col">
                          <label htmlFor="cinco">Filtro</label>
                          <Dropdown id="cinco" placeholder="Seleccionar"
                            options={options4}
                            />
                      </div>
                      <div className="form-group p-col">
                          <label htmlFor="cinco">Filtro</label>
                          <Dropdown id="cinco" placeholder="Seleccionar"
                            options={options4}
                            />
                      </div>

                      

                      
                    </div>
              
            </div>


        <div className="p-col-12">
          <div className="p-grid p-fluid ">
            
            
            <div className="p-col-12">
              
              <div className="card p-0 card-table-pag">
                <DataTable paginator={true} rows={5} value = {state.detalles}>
                  <Column field = "dato" header = "Número"  sortable = {true}/>
                  <Column field = "dato2" header = "Letras"  sortable = {true}/>
                  <Column field = "dato3" header = "Texto"  sortable = {true}/>
                  <Column field = "dato4" header = "Status"  sortable = {true}/>
                  <Column field = "dato5" header = "Fecha" sortable = {true} />
                
                </DataTable>
              </div>
            </div>
          </div>

         
        </div>

      </div>
    );
}


export default TablaDemo;
