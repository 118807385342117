import React from 'reactn'
import { MultiSelect } from 'primereact/multiselect'
import { useEffect, useGlobal, useState } from 'reactn'
import { getClientesCiclo } from '../../service/Clientes'
import { obtenerReportePreciosAcumulado } from '../../service/ReportePrecios'
import { DeepClone, getObjetoPorID } from '../../util/functions'
import ContenedorFiltro from '../common/ContenedorFiltro'
import ContenedorHeader from '../common/ContenedorHeader'
import ContenedorPrincipal from '../common/ContenedorPrincipal'
import SeccionFiltros from '../common/SeccionFiltros'
import TablaPreciosAcumuladosProd from './componentes/TablaPreciosAcumuladosProd.componentes'
import ContenedorCardFormulario from 'components/common/ContenedorCardFormulario'

const ReporteAcumulado = (props) => {
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [, setCargando] = useGlobal("cargando");
    const [ciclo] = useGlobal("ciclo");
    const [productos] = useGlobal("productosBasesDelCiclo");
    const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");

    const [clientes, setClientes] = useState([]);
    const [filtroClientes, setFiltroClientes] = useState([]);
    const [filtroInvernaderos, setFiltroInvernaderos] = useState([])
    const [invernaderosOpciones, setInvernaderosOpciones] = useState([]);
    const [resumenPreciosDeCliente, setResumenPreciosDeCliente] = useState([])
    const [pricingDataByProduct, setPricingDataByProduct] = useState([])

    useEffect(() => {
        setNombreModulo("Finanzas");
        setHabilitadoSelectorCiclo(true);
    }, [])
    useEffect(() => {
        if (ciclo) obtenerDatosIniciales();
    }, [ciclo])

    useEffect(() => {
        let invsFilteredNew = [];
        filtroInvernaderos.forEach(invernaderoID => {
            const tieneInvernadero = invernaderosOpciones?.some(inv => inv.id === invernaderoID);
            const esExterno = getObjetoPorID(invernaderosVirtuales, invernaderoID).es_externo;
            if (tieneInvernadero || esExterno) { invsFilteredNew.push(invernaderoID); }
        })

        if (filtroClientes?.length > 0) { setFiltroInvernaderos(invsFilteredNew); }
        else { setFiltroInvernaderos([]) }
    }, [invernaderosOpciones])

    useEffect(() => {
        if (productos?.length > 0) actualizarDatosFiltros()
    }, [productos, filtroClientes, filtroInvernaderos])

    const obtenerDatosIniciales = async () => {
        setCargando(true);
        const clientesCiclo = await getClientesCiclo(ciclo);
        const resumen = await obtenerReportePreciosAcumulado(ciclo, DeepClone(clientesCiclo));
        setResumenPreciosDeCliente(resumen);

        setClientes(clientesCiclo);
        setFiltroClientes(clientesCiclo.map(el => el.id));

        setFiltroInvernaderos(invernaderosVirtuales.map(el => el.id))
        setInvernaderosOpciones(invernaderosVirtuales);
        setCargando(false)
    }


    const actualizarDatosFiltros = async () => {
        let clientesfiltradas = resumenPreciosDeCliente.filter(el => filtroClientes.includes(el.clienteId));
        let filtrado = []

        productos.forEach((prod) => {
            const dataProduct = clientesfiltradas.map(cliente => {
                let precioTotal = 0, sumaLibras = 0, costoMateriales = 0
                filtroInvernaderos.forEach(invID => {
                    const invernaderoObj = getObjetoPorID(invernaderosVirtuales, invID)
                    const esInvernaderoMedleys = prod.MEDLEYS_GENERAL && invernaderoObj?.mezcla;
                    const hayRegistrosMedleysDeInvernadero = esInvernaderoMedleys && cliente?.[invID]?.[invernaderoObj?.producto_ref]?.precioTotal > 0;
                    const productoAChecarId = hayRegistrosMedleysDeInvernadero ? invernaderoObj?.producto_ref : prod.id;

                    if (cliente?.[invID]?.[productoAChecarId]) {
                        precioTotal += cliente[invID][productoAChecarId]?.precioTotal || 0
                        sumaLibras += cliente[invID][productoAChecarId]?.sumaLibras || 0
                        costoMateriales += isNaN(cliente[invID][productoAChecarId]?.costoMateriales) ? 0 : cliente[invID][productoAChecarId]?.costoMateriales || 0
                    }
                })

                return {
                    ...cliente,
                    nombreCliente: cliente.nombreCliente,
                    precioTotal,
                    sumaLibras,
                    costoMateriales,
                    ingresos: precioTotal - costoMateriales
                }
            })
            filtrado.push(dataProduct)
        })

        setPricingDataByProduct(filtrado)
    }
    return (
        <ContenedorPrincipal>
            <ContenedorHeader iconos="reporte-empaque-icon" titulo={"Reporte acumulado de precios"} />

            <SeccionFiltros>
                <ContenedorFiltro label="Cliente">
                    <MultiSelect options={clientes} value={filtroClientes} optionLabel="nombre" optionValue="id"
                        onChange={e => setFiltroClientes(e.value)} selectedItemsLabel={`${filtroClientes.length} clientes seleccionados`} />
                </ContenedorFiltro>
                <ContenedorFiltro label="Invernadero">
                    <MultiSelect options={invernaderosOpciones} value={filtroInvernaderos} optionLabel="nombre" optionValue="id"
                        onChange={e => setFiltroInvernaderos(e.value)} selectedItemsLabel={`${filtroInvernaderos.length} invernaderos seleccionados`} />
                </ContenedorFiltro>
            </SeccionFiltros>

            <ContenedorCardFormulario >
                {pricingDataByProduct?.map((productoDatos, index) => {
                    const key = `${index}-${productos[index].nombre}`
                    return (<TablaPreciosAcumuladosProd key={key} titulo={productos[index].nombre} data={productoDatos} />)
                })}
            </ContenedorCardFormulario>
        </ContenedorPrincipal>)
}
export default ReporteAcumulado;//123