import React, { useState, useGlobal, useEffect } from "reactn";
import { obtenerReporteCompraExterna } from "../../service/CompraExterna";
import { obtenerCiclo } from "../../service/Ciclos";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { dosDecimales, getProductoYVariedadString, separadoComas } from "../../util/functions";
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { obtenerProveedores } from "../../service/Queries/Proveedores";
import RowBotonesTopThin from "../common/RowBotonesTopThin";
import { Button } from "primereact/button";
import ContenedorPrincipal from "../common/ContenedorPrincipal";
import ContenedorHeader from "../common/ContenedorHeader";
import SeccionFiltros from "../common/SeccionFiltros";
import icono from '../../images/icons/external-icon.svg';

const ReporteCompraExterna = (props) => {
    const [ciclo] = useGlobal("ciclo");
    const [weeks, week, handleWeekChange] = useWeekSelector(true);
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [proveedoresData, setProveedoresData] = useState([]);
    const [tabla, setTabla] = useState([]);
    const [, setCargando] = useGlobal("cargando");
    const [totales, setTotales] = useState([0, 0, 0, 0, 0, 0])
    const [, setProveedor] = useGlobal("proveedor");
    useEffect(() => {
        setNombreModulo("Reporte Compra Externa");
        setHabilitadoSelectorCiclo(true);
    }, [])
    useEffect(() => {
        if (ciclo) { obtenerDatosIniciales(); }
    }, [ciclo])

    useEffect(() => {
        if (ciclo && week.nombre !== "Cargando" && proveedoresData?.length > 0) { procesarReporteCompraExterna(); }
    }, [ciclo, week, proveedoresData])

    const obtenerDatosIniciales = async () => {
        let proveedoresDatos = proveedoresData.length > 0 ? proveedoresData : await obtenerProveedores(ciclo);
        const productRef = props.location?.state?.productRef
        if(productRef) proveedoresDatos = proveedoresDatos.filter(prov => prov.producto_ref === productRef)
        setProveedoresData(proveedoresDatos);
    }

    const procesarReporteCompraExterna = async () => {
        setCargando(true);
        const reporteExterno = await obtenerReporteCompraExterna(ciclo, week.time);

        let data = [], proveedores = [], totales = [0, 0, 0, 0, 0, 0], m2 = 0;
        reporteExterno.forEach(el => {
            let [proveedor, semana, kilos, kg_m2] = el;
            let temp = {};
            const provFound = proveedoresData.find((prov) => prov.id === proveedor.id)
            if(!provFound) return
            temp["proveedor_id"] = proveedor.id;
            temp["nombre_proveedor"] = proveedor.nombre;

            temp["kilos_diario"] = <div>{separadoComas(dosDecimales(kilos["diario"]))}<span className="label-unidad">kg</span></div>;
            temp["kilos_semanal"] = <div>{separadoComas(dosDecimales(kilos["semanal"]))}<span className="label-unidad">kg</span></div>;
            temp["kilos_real"] = <div>{separadoComas(dosDecimales(kilos["real"]))}<span className="label-unidad">kg</span></div>;

            temp["kg_m2_diario"] = <div>{separadoComas(dosDecimales(kg_m2["diario"]))}<span className="label-unidad">kg/m²</span></div>;
            temp["kg_m2_semanal"] = <div>{separadoComas(dosDecimales(kg_m2["semanal"]))}<span className="label-unidad">kg/m²</span></div>;
            temp["kg_m2_real"] = <div>{separadoComas(dosDecimales(kg_m2["real"]))}<span className="label-unidad">kg/m²</span></div>;
            totales[0] += kilos["semanal"];
            totales[1] += kilos["diario"];
            totales[2] += kilos["real"];
            m2 += kg_m2["semanal"] > 0 ? kilos["semanal"] / kg_m2["semanal"] : 0;

            let porc_sem_real = dosDecimales(100 * kilos["real"] / kilos["semanal"]);
            let porc_sem_diario = dosDecimales(100 * kilos["diario"] / kilos["semanal"]);
            let porc_diario_real = dosDecimales(100 * kilos["real"] / kilos["diario"]);
            if (kilos["semanal"] !== 0) {
                if (porc_sem_real > 115) {
                    temp["porcentaje_semanal_real"] = <div className="arrow-alert alto"><FontAwesomeIcon icon={faCaretUp} />{porc_sem_real}<span className="label-unidad">%</span></div>;
                } else if (porc_sem_real < 85) {
                    temp["porcentaje_semanal_real"] = <div className="arrow-alert bajo"><FontAwesomeIcon icon={faCaretDown} />{porc_sem_real}<span className="label-unidad">%</span></div>;
                }
                else {
                    temp["porcentaje_semanal_real"] = <div > {porc_sem_real}<span className="label-unidad">%</span></div>;
                }
                if (porc_sem_diario > 115) {
                    temp["porcentaje_semanal_diario"] = <div className="arrow-alert alto"><FontAwesomeIcon icon={faCaretUp} />{porc_sem_diario}<span className="label-unidad">%</span></div>;
                } else if (porc_sem_diario < 85) {
                    temp["porcentaje_semanal_diario"] = <div className="arrow-alert bajo"><FontAwesomeIcon icon={faCaretDown} />{porc_sem_diario}<span className="label-unidad">%</span></div>;
                } else {
                    temp["porcentaje_semanal_diario"] = <div>{porc_sem_diario}<span className="label-unidad">%</span></div>;
                }
            } else {
                temp["porcentaje_semanal_real"] = <div >-<span className="label-unidad">%</span></div>;
                temp["porcentaje_semanal_diario"] = <div>-<span className="label-unidad">%</span></div>;
            }
            if (kilos["diario"] !== 0) {
                if (porc_diario_real > 115) {
                    temp["porcentaje_diario_real"] = <div className="arrow-alert alto"><FontAwesomeIcon icon={faCaretUp} />{kilos["diario"] == 0 ? "-" : porc_diario_real}<span className="label-unidad">%</span></div>;
                } else if (porc_diario_real < 85) {
                    temp["porcentaje_diario_real"] = <div className="arrow-alert bajo"><FontAwesomeIcon icon={faCaretDown} />{kilos["diario"] == 0 ? "-" : porc_diario_real}<span className="label-unidad">%</span></div>;
                } else {
                    temp["porcentaje_diario_real"] = <div>{kilos["diario"] == 0 ? "-" : porc_diario_real}<span className="label-unidad">%</span></div>;
                }
            } else {
                temp["porcentaje_diario_real"] = <div>-<span className="label-unidad">%</span></div>;
            }
            if (checarHayDatosCompraExterna(el, proveedor.habilitado)) { data.push(temp); }
        })
        totales[3] = m2 > 0 ? dosDecimales(totales[0] / m2) : 0;
        totales[4] = m2 > 0 ? dosDecimales(totales[1] / m2) : 0;
        totales[5] = m2 > 0 ? dosDecimales(totales[2] / m2) : 0;
        setTotales(totales);

        let set = new Set(proveedores);
        proveedores = Array.from(set);
        setTabla(data);
        setCargando(false);
    }

    const checarHayDatosCompraExterna = (datosProveedorExterno = [], esHabilitado = false) => {
        const { diario: diarioKg, semanal: semanalKg, real: realKg } = datosProveedorExterno[2];
        const { diario: diarioKgM2, semanal: semanalKgM2, real: realKgM2 } = datosProveedorExterno[3];
        //console.log("COMPR check: ", datosProveedorExterno);
        return esHabilitado || (diarioKg > 0 || semanalKg > 0 || realKg > 0 || diarioKgM2 > 0 || semanalKgM2 > 0 || realKgM2 > 0);
    }

    const invernaderoBody = (rowData, column) => {
        if (proveedoresData) {
            const proveedor = proveedoresData.find(el => el.id === rowData["proveedor_id"]);
            return (
                <div onClick={(e) => {
                    setProveedor(proveedor)
                    props.history.push("/cosecha/compra_externa/consultar")
                }} className="proveedor-block">
                    <div className="proovedor-info">
                        <p className="proveedor-name">{rowData["nombre_proveedor"]}</p>
                        <p className="proveedor-product">{getProductoYVariedadString(proveedor)}</p>
                    </div>
                </div>);
        } else { return <div >{rowData.nombre_proveedor} </div>; }
    }
    const headerGroup =
        <ColumnGroup>
            <Row>
                <Column header="Proveedor" rowSpan={2} className="col__label" />
                <Column header="Total de producto (kg)" colSpan={3} />
                <Column header="Total de rendimiento (kg/m²)" colSpan={3} />
                <Column header="Porcentaje" colSpan={3} className="th-small  col__final" />
            </Row>
            <Row>
                <Column header="Semanal" colSpan={1} className="th-small" />
                <Column header="Diario" colSpan={1} className="th-small" />
                <Column header="Real" colSpan={1} className="th-small" />
                <Column header="Semanal" colSpan={1} className="th-small" />
                <Column header="Diario" colSpan={1} className="th-small" />
                <Column header="Real" colSpan={1} className="th-small" />
                <Column header="Real vs Semanal " colSpan={1} className="th-small  col__final" />
                <Column header="Dato HTG vs Semanal " colSpan={1} className="th-small  col__final" />
                <Column header="Real vs Diario LORT   " colSpan={1} className="th-small  col__final" />
            </Row>
        </ColumnGroup>;

    const obtenerFooter = () =>
        <ColumnGroup>
            <Row>
                <Column footer="Total" colSpan={1} />
                <Column footer={<div>{separadoComas(totales[0])}<span className="label-unidad">kg</span></div>} colSpan={1} />
                <Column footer={<div>{separadoComas(totales[1])}<span className="label-unidad">kg</span></div>} colSpan={1} />
                <Column footer={<div>{separadoComas(totales[2])}<span className="label-unidad">kg</span></div>} colSpan={1} />
                <Column footer={<div>{separadoComas(totales[3])}<span className="label-unidad">kg</span></div>} colSpan={1} />
                <Column footer={<div>{separadoComas(totales[4])}<span className="label-unidad">kg</span></div>} colSpan={1} />
                <Column footer={<div>{separadoComas(totales[5])}<span className="label-unidad">kg</span></div>} colSpan={1} />
                <Column footer={<div>{"-"}<span className="label-unidad"></span></div>} colSpan={1} />
                <Column footer={<div>{"-"}<span className="label-unidad"></span></div>} colSpan={1} />
                <Column footer={<div>{"-"}<span className="label-unidad"></span></div>} colSpan={1} />
            </Row>
        </ColumnGroup>;

    return (
        <ContenedorPrincipal>
            <ContenedorHeader col="p-col-auto p-col" titulo="Reporte de cosecha compra externa" iconos="external-icon bor-main-blue bg-main-blue"
                invernaderoNombre={<img width="60" alt="icono" src={icono} />} atras={props.history.goBack} />

            <RowBotonesTopThin >
                <Button label="Reporte de cosecha" onClick={() => props.history.push("/cosecha/reporte")} />
            </RowBotonesTopThin>

            <SeccionFiltros>
                <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
            </SeccionFiltros>
            {tabla?.length > 0
                ? <div className="p-col-12">
                    <div className="card p-0">
                        <DataTable value={tabla} headerColumnGroup={headerGroup} footerColumnGroup={obtenerFooter()} >
                            <Column field="nombre_proveedor" className="col__label" body={invernaderoBody} />
                            <Column field="kilos_semanal" />
                            <Column field="kilos_diario" />
                            <Column field="kilos_real" />
                            <Column field="kg_m2_semanal" />
                            <Column field="kg_m2_diario" />
                            <Column field="kg_m2_real" />
                            <Column field="porcentaje_semanal_real" className="col__final" />
                            <Column field="porcentaje_semanal_diario" className="col__final" />
                            <Column field="porcentaje_diario_real" className="col__final" />
                        </DataTable>
                    </div>
                </div>
                : <p className="p-col-12 section-title">No hay registros de pronóstico para esta semana</p>}

            <div className="p-col-12">
                <p className="section-title">Notas</p>
                <div className="card">
                    <p className="mt-0">Los valores "Diario" se calculan con los datos "Diario" de la semana que se hayan ingresado hasta el día actual + los datos ingresados en "Semanal" para los días futuros de la semana</p>
                    <p className="mb-0">Los valores "Real" se calculan con todos los datos "Real" de la semana que se hayan ingresado hasta la última actualización despues de las 5pm + los datos ingresados en "Diario" (o en su defecto "Semanal") para los días futuros de la semana</p>
                </div>
            </div>
        </ContenedorPrincipal>)
}
export default ReporteCompraExterna;//272