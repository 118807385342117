import React, { useState, useEffect } from 'reactn';
import { useGlobal } from "reactn";
import { Button } from 'primereact/button';
import { obtenerInvernaderoFisicoRelacionado, obtenerInvernaderosFisicos, } from "../../service/Invernaderos";
import InvernaderoCard from "./InvernaderoCard";
import InvernaderoExternoCard from "./InvernaderoExternoCard";
import { obtenerPesoFruto, obtenerDefectos, obtenerFiltrosCalidadTodos } from '../../service/Calidad';
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { obtenerPesoPromedioCajaSemana } from '../../service/Producto';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { getObjetoPorID, getProductoYVariedadString, DeepClone } from '../../util/functions';
import { obtenerProveedores, obtenerProveedoresInvernadero } from '../../service/Queries/Proveedores';
import lortIncon from "../../images/icons/external-icon.svg"

const ProveedoresExternosCards = (props) => {
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [productosYVariedades] = useGlobal("productosYVariedades")
    const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
    const [ciclo,] = useGlobal("ciclo");
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [usuario,] = useGlobal("usuario");
    const [, setCargando] = useGlobal("cargando");
    const [, setMostrarModalPermiso] = useGlobal("mostrarModalPermiso");
    const [datosCargados] = useGlobal("datosCargadosCalidad");
    const [invernaderosFisicos, setInvernaderosFisicos] = useGlobal("invernaderosFisicos");

    const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector();
    const [proveedores, setProveedores] = useGlobal("proveedores");
    const [proveedoresCardsInfo, setProveedoresCardsInfo] = useState([])
    const [invernadero] = useGlobal("invernadero");

    useEffect(() => {
        setNombreModulo("Calidad proveedores externos");
        setHabilitadoSelectorCiclo(true);
        if(!invernadero?.id){props.history.replace("/calidad")}
    }, [invernadero])
    useEffect(() => {
        setCargando(true)
        if (ciclo && !datosCargados && week.nombre != "Cargando")
            obtenerFiltrosCalidadTodos(ciclo)
        else if (ciclo && datosCargados)
            obtenerDatosCards();
    }, [ciclo, week, datosCargados])

    const obtenerDatosCards = async () => {
        try {
            let listaProveedores = await  obtenerProveedoresInvernadero(ciclo, invernadero.id)
            setProveedores(listaProveedores)
            let externos = invernaderosVirtuales.filter(el => el.es_externo)
            let promises = listaProveedores.map(proveedor => obtenerResumenExterno(proveedor, invernadero))

            let proveedoresCardsData = await Promise.all(promises)
            console.log(proveedoresCardsData)
            proveedoresCardsData = proveedoresCardsData.filter(prov => (!!prov.pesoFruto && !isNaN(prov.pesoFruto) )|| prov?.proveedor?.habilitado)
            setProveedoresCardsInfo([...proveedoresCardsData])
        } catch (err) {
            if (err.code && err.code === "permission-denied") {
                console.log(err.code);
                setMostrarModalPermiso(true);
            }
        }

        setCargando(false);
    }
    const obtenerResumenExterno = async (proveedor, invernaderoPassed) => {
        let productoYVariedadString = getProductoYVariedadString(invernaderoPassed)
        let producto = getObjetoPorID(productosYVariedades, invernaderoPassed.producto_ref);
        let invernadero = DeepClone(invernaderoPassed)
        let pesoCajaPromise = obtenerPesoPromedioCajaSemana(ciclo, invernaderoPassed.id, week.time, proveedor.id)
        let pesoFrutoPromise = obtenerPesoFruto(ciclo, invernaderoPassed.id, week.time, proveedor.id)
        let defectosPromise = obtenerDefectos(ciclo, invernaderoPassed.id, week.time, producto.tipo_calidad_inicial, proveedor.id)
        const [pesoCaja, pesoFruto, defectos,] =
            await Promise.all([pesoCajaPromise, pesoFrutoPromise, defectosPromise])

        return { invernadero, pesoCaja, pesoFruto, defectos, productoYVariedadString, proveedor }
    }

    return <div className="p-grid">
        <div className="p-col-12 title-container">
            <span className="back-btn" onClick={props.history.goBack}></span>
            <span className="icon-id icon-general external-icon bor-main-blue bg-main-blue">
                <img width="60" alt="icono" src={lortIncon} /> 
            </span>
            <span className="titles-group">
                <h1 className="header-title">Calidad proveedores externos</h1>
            </span>
        </div>
        <div className="p-col-12">
            <div className="p-grid filtros">
                <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
            </div>
        </div>

        {proveedoresCardsInfo?.length > 0
            ? <div className="p-col-12">
                <div className="p-grid">
                    {proveedoresCardsInfo?.map(info =>
                        <InvernaderoExternoCard key={info.proveedor.id} info={info} invernadero={info.invernadero} proveedor={info.proveedor} />
                    )}
                </div>
            </div>
            : <p className="p-col-12 section-title">No hay registros de calidad para esta semana</p>}
    </div>
}
export default ProveedoresExternosCards;