import { Button } from 'primereact/button'
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown'
import { Row } from 'primereact/row';
import React, { useState, useEffect } from 'react'
import { useGlobal } from 'reactn';
import { esCicloActual } from '../../service/Ciclos';
import { obtenerDiaActual, obtenerDiasDeSemana, obtenerHora, obtenerNombreDiaSemana, obtenerNombreSemana } from '../../service/fechas';
import { dosDecimales, separadoComas } from '../../util/functions';
import { usePrimeReactTable } from '../common/tableHandler';
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { guardarCapturaCompraExterna, obtenerDatosCompraExterna, obtenerRealesProveedor } from '../../service/CompraExterna'
import { horaCorte, nombresDiasSemana, nombresDiasSemanaPlanos, rolProveedorExterno } from '../../constants';
import { obtenerProveedorPorUsuario } from '../../service/Queries/Proveedores';
import { withRouter } from 'react-router-dom';
import { extraProcessingSemanal, extraProcessingDiario, extraProcessingReal } from './CompraExternaCtl';
import icono from '../../images/icons/external-icon.svg';

const rowInicial = (tipoTabla) => ({ label: tipoTabla, lunes: "", martes: "", miercoles: "", jueves: "", viernes: "", sabado: "", total_cajas: 0, total_kg: 0 });
const tablaInicial = () => [rowInicial("Semanal"), rowInicial("Diario")];//renglon 0 siempre va a ser el semanal, renglon 1 sera diario

function CapturaCompraExterna(props) {
    const extraProcessing = (updatedState, props, value) => {
        let timestamps = obtenerDiasDeSemana(week.time);
        let area = parseFloat(proveedor.area_invernadero)
        let hoy = obtenerDiaActual();
        let hora = obtenerHora(Math.round(Date.now() / 1000));
        extraProcessingSemanal(updatedState, timestamps, area)
        extraProcessingDiario(updatedState, timestamps, area, hoy, hora)
        let copia = tabla.slice();
        //copia[2]["total_kg"] = total;
        setTabla(copia);

        return updatedState;
    }
    const [proveedor, setProveedor] = useState();
    const [invernadero, setInvernadero] = useState()
    const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector(true);
    const [weekGlobal, setWeekGlobal] = useGlobal("weekGlobal");
    const [, setCargando] = useGlobal("cargando");
    const [cicloId, setCicloId] = useGlobal("ciclo")
    const [tabla, setTabla] = useState(tablaInicial())

    const [usuario, setUsuario] = useGlobal("usuario");
    const [editorGenerator, current] = usePrimeReactTable(tabla, extraProcessing);
    const pesoCaja = 1;
    const area = 1;
    const [tablaOrig, setTablaOrig] = useState([])
    const [cosechaFirestoreIds, setCosechaFirestoreIds] = useState([])

    const puedeEditar = () => ["Administrador", "Proveedor Externo"].includes(usuario?.rol)
    const esFechaValida = (indexDia, indexRow) => {
        let dias = obtenerDiasDeSemana(week.time);
        let dia = dias[indexDia]; //dia seleccionada de la tabla
        let ahora = Math.round(Date.now() / 1000);

        //validar semanal
        if (indexRow === 0) {
            let diasEstaSemana = obtenerDiasDeSemana(ahora);
            let sabado = diasEstaSemana[5] + 12 * 60 * 60;//Sabado a medio dia
            return (usuario && !usuario.bloqueado) || (dia > sabado && ahora < sabado);//Que ahorita es antes de sabado al medio dia && estas registrando para el siguiente semana
        }//validar diario
        else return (usuario && !usuario.bloqueado) || ahora < dia;

    }
    useEffect(() => {
        if (cicloId && usuario && week.nombre !== "Cargando") obtenerDatosIniciales();
    }, [cicloId, usuario, week])
    const obtenerDatosIniciales = async () => {
        try {
            setCargando(true)
            let result = await obtenerDatosCompraExterna(cicloId, week.time, usuario.uid, setCosechaFirestoreIds)
            let timestamps = obtenerDiasDeSemana(week.time)
            let procesado = obtenerTotales(result, timestamps);
            setTabla(procesado.slice(0, 2));
            setTablaOrig(result.slice());

            result = await obtenerProveedorPorUsuario(cicloId, usuario.uid)
            setProveedor(result.empty ? null : result);
            setCargando(false)
        } catch (error) {
            throw error;
        }
    }
    const obtenerTotales = (datos, timestamps) => {
        let result = datos.slice();
        result[0]["total_kg"] = obtenerTotalSemanal(datos, timestamps);
        result[1]["total_kg"] = obtenerTotalDiario(datos, timestamps);
        //result[2]["total_kg"] = obtenerTotalHTG(datos,timestamps);
        return result
    }
    const obtenerTotalSemanal = (datos, timestamps) => {
        let row = datos[0];
        let ks = Object.keys(row);
        let total = 0;
        timestamps.forEach(k => {
            let dato = parseFloat(row[k]);
            if (dato && !isNaN(dato)) {
                total += dato;
            }
        })
        return total;
    }
    const obtenerTotalDiario = (datos, timestamps) => {
        let row = datos[0];
        let ks = Object.keys(row);
        let total = 0;
        let hoy = obtenerDiaActual();
        let hora = obtenerHora(Math.round(Date.now() / 1000));
        timestamps.forEach(k => {
            if (hoy < k) {
                let dato = parseFloat(datos[1][k]);
                if (dato && !isNaN(dato)) {
                    total += dato;
                } else {
                    dato = parseFloat(datos[0][k]);
                    if (dato && !isNaN(dato)) {
                        total += dato;
                    }
                }

            }
            else if (hoy === k && hora < horaCorte) {
                let dato = parseFloat(datos[1][k]);
                if (dato && !isNaN(dato)) {
                    total += dato;
                } else {
                    dato = parseFloat(datos[0][k]);
                    if (dato && !isNaN(dato)) {
                        total += dato;
                    }
                }
            } else {
                let dato = parseFloat(datos[1][k]);
                if (dato && !isNaN(dato)) {
                    total += dato;
                }
            }
        })
        return total;
    }
    const unidadTemplatecaja = (rowData, column) => rowData[column["field"]] === "" ? "" : <div>{separadoComas(dosDecimales(rowData[column["field"]]))}<span className="label-unidad">kilos</span> </div>;
    function unidadTemplatekgm(rowData, column) {
        let st = `<p class='tooltip-title'>Valores usados para cálculo</p><p class='tooltip-def'> </p> <div class='tooltip-datas'> <div class='tooltip-data'>${dosDecimales(pesoCaja)} kgs<p class='tooltip-label'>Peso de caja<br/>(semana pasada)</p></div><div class='tooltip-data'>${area} m²<p class='tooltip-label'>Área<br/>invernadero</p></div></div>`;
        return <div>{dosDecimales(rowData[column["field"]])}<span className="label-unidad">kg/m²</span> <Button type="button" label="?" className="btn-line btn-help" tooltip={st} /></div>;
    }
    const guardar = async (params) => {
        try {
            setCargando(true);
            let copia = [tabla[0], tabla[1], tablaOrig[2]]
            await guardarCapturaCompraExterna(cicloId, proveedor.id, week.time, copia, cosechaFirestoreIds);
        } catch (error) {
            throw error
        } finally {
            setCargando(false);
        }
    }
    const editorTemplate = (props, index, nombreDiaPlano) => (esFechaValida(index, props.rowIndex) && puedeEditar()) ? editorGenerator(props, nombreDiaPlano) : <div>No editable</div>
    const obtenerColumnas = () => {
        let cols = [<Column key={`compExt-ext-col-0`} field="label" header="" className="col__label" />]
        let diasSemana = obtenerDiasDeSemana(week.time);
        diasSemana.forEach((dia, index) => {

            cols.push(<Column key={`compExt-ext-col-t${index}`} body={unidadTemplatecaja} field={dia} header={obtenerNombreDiaSemana(dia)} editor={e => editorTemplate(e, index, nombresDiasSemanaPlanos[index])} />)
        })
        cols.push(<Column key={`compExt-col-tot`} body={unidadTemplatekgmTotal} field="total_kg" header="Total de kg/m²" className="col__total" />)
        return cols;
    }
    function unidadTemplatekgmTotal(rowData, column) {
        let dato = rowData[column["field"]]
        return <div>{isNaN(dato) ? "-" : dosDecimales(dato)}<span className="label-unidad">kg/m²</span> </div>;
    }

    return (
        <div className="p-grid p-justify-center">

            {/* Título */}
            <div className="p-col  p-col-auto title-container">
                {usuario?.rol !== rolProveedorExterno && <span className="back-btn" onClick={props.history.goBack}></span>}
                {invernadero ?
                    <React.Fragment>
                        <span className={`icon-general icon-id bor-${invernadero.color} bg-${invernadero.color} ${invernadero.habilitado ? "" : "Deshabilitado"}`}>{invernadero.nombre}</span>
                    </React.Fragment>
                    :
                    <span className="icon-id icon-general external-icon bor-main-blue bg-main-blue">
                        <img width="60" alt="icono" src={icono} />
                    </span>}

                <span className="titles-group">
                    <h1 className="header-title">Registro de compra {usuario?.rol !== rolProveedorExterno ? "externa" : ""}{proveedor ? proveedor.nombre : ""} </h1>
                    {invernadero &&
                        <React.Fragment>
                            {invernadero.producto && invernadero.variedad &&
                                <p className="header-subtitle">
                                    {invernadero.producto.nombre}-{invernadero.variedad.nombre}
                                </p>}
                        </React.Fragment>}
                </span>
            </div>

            <div className="p-col-12 ">
                <div className="p-grid filtros">
                    <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
                </div>
            </div>

            {/* Tabla de consulta */}
            {proveedor ? <React.Fragment>
                <div className="p-col-12">
                    <div className="p-grid p-fluid ">
                        <div className="p-col-12">
                            <p className="section-title">Registro - Semana {obtenerNombreSemana(week.time)} </p>
                            <DataTable value={tabla} className="table-footer__total" >
                                {obtenerColumnas()}
                            </DataTable>
                        </div>
                    </div>
                </div>
                <div className="p-col-12">
                    <div className="p-grid p-justify-end btn-row">
                        {puedeEditar() && <Button label="Guardar" onClick={guardar} />}
                    </div>
                </div>
            </React.Fragment> : "NO HAY PROVEEDOR ASIGNADO A ESTE USUARIO EN ESTE CICLO"}
        </div>
    )
}
export default CapturaCompraExterna;//370