import * as firebase from "firebase/app";
import "firebase/firestore";
import {uploadStatistics} from '../Estadisticas'

function registrarInvocaciones(count){
    uploadStatistics(count)
}


export const registrarPronostico = async (cicloId, invernaderoId, weekTime, datos, proveedor_ref, usuario_ref) => {
    const db = firebase.firestore();
    const datosAGuardar = { semana: weekTime, pronosticos: datos };
    if (proveedor_ref) { 
        datosAGuardar.proveedor_ref = proveedor_ref;
        datosAGuardar.usuario_ref = usuario_ref;
    };
    let result = await db.collection("ciclos").doc(cicloId).collection("invernaderos_virtuales").doc(invernaderoId).collection("pronosticos_cultivo").add(datosAGuardar)
    return result;
}
export const obtenerCapturaPrevia = async (cicloId, invernaderoId, weekTime) => {
    const db = firebase.firestore();
    let result = await db.collection("ciclos").doc(cicloId).
        collection("invernaderos_virtuales").doc(invernaderoId).collection("pronosticos_cultivo").where("semana","==", weekTime).get()
    registrarInvocaciones(result.size)
    if(result.empty){
        return {isEmpty:true}
    }else{
        let doc = result.docs[0]
        return {id:doc.id, ...doc.data()}
    }
}
export const obtenerCapturaPreviaProveedorExterno = async (cicloId, invernaderoId, weekTime, proveedor_ref) => {
    const db = firebase.firestore();
    let result = await db.collection("ciclos").doc(cicloId).
        collection("invernaderos_virtuales").doc(invernaderoId).collection("pronosticos_cultivo").where("semana","==", weekTime).where("proveedor_ref", "==", proveedor_ref).get()
        .catch((error) => {
            console.log("obtenerCapturaPreviaProveedorExterno", error)
        })
    registrarInvocaciones(result.size)
    if(result.empty){
        return {isEmpty:true}
    }else{
        let doc = result.docs[0]
        return {id:doc.id, ...doc.data()}
    }
}
export const actualizarPronostico = async (cicloId , invernaderoId, capturaId, datos) => {
    console.log({cicloId, invernaderoId, capturaId, datos})
    const db = firebase.firestore();
    let result = await db.collection("ciclos").doc(cicloId).collection("invernaderos_virtuales").doc(invernaderoId).
        collection("pronosticos_cultivo").doc(capturaId).update({        
        pronosticos: datos
    })
    return result;
}//46