import * as firebase from "firebase/app";
import "firebase/firestore";
import { segundosSemana } from "../../constants";
import { obtenerProveedoresInvernadero } from "./Proveedores";
import { uploadStatistics } from '../Estadisticas'
import { obtenerDiasDeSemana } from "service/fechas";

function registrarInvocaciones(count) {
    uploadStatistics(count)
}

export const obtenerRegistrosCompraExternaSemana = async (cicloId, proveedorId, semana) => {
    const db = firebase.firestore();
    let snap = await db.collection("ciclos").doc(cicloId).collection("proveedores_cosecha").doc(proveedorId).
        collection("compra_externa").where("dia", ">=", semana).where("dia", "<", semana + segundosSemana).get();
    registrarInvocaciones(snap.size)
    return snap.docs.map(el => ({ id: el.id, ...el.data() }))
}
export async function registrarCompraExternaDia(ciclo, proveedorId, dia, semanal, diaria, datoHTG, cosechaID) {
    const registroObj = { dia: dia, semanal: semanal, diaria: diaria, datoHTG: datoHTG }
    await firebase.firestore().collection("ciclos").doc(ciclo).collection("proveedores_cosecha")
        .doc(proveedorId).collection("compra_externa").doc(cosechaID).set(registroObj, { merge: true });
    return true
}
export const obtenerRegistrosCompraExternaSemanaPorInvernadero = async (cicloId, invernaderoId, semanaTime) => {
    let proveedores = await obtenerProveedoresInvernadero(cicloId, invernaderoId);
    let promises = proveedores.map(proveedor => obtenerRegistrosCompraExternaSemana(cicloId, proveedor.id, semanaTime));
    let values = await Promise.all(promises);
    let result = values.flat();

    let dias = obtenerDiasDeSemana(semanaTime);
    return dias.map(dia => result.filter(el => el.dia === dia));
}