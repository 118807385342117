import React, { useGlobal, useState, useEffect } from 'reactn'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import { DeepClone, dosDeciComas, getNombrePorID, getObjetoPorID, getProductoYVariedadString, separadoComas } from '../../util/functions';
import { obtenerNombreFechaCompacto, obtener_horaMinutos } from '../../service/fechas';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { itemMappingToAddTotals } from './utils/dataMappings';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { UnidadBase } from 'components/common/templates_unidades/UnidadBase.atomo';
import ContenedorTabla from 'components/common/ContenedorTabla';
import SeccionFiltros from 'components/planeacion_mantenimiento/CapturaAgentes/SeccionFiltros';
import ContenedorFiltro from 'components/common/ContenedorFiltro';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { getEmpaqueDeVentaItem } from 'service/CentroDeDistribucion';
import { convertirCajasPresentacionAPallets } from 'service/Cumplimiento';
import { hayModificacionDelItem } from 'service/Ordenes';
import { BOTONES_GUARDAR, BOTONES_CANCELAR, DESCENDIENTE } from '../../constants';

const TablaItems = (props) => {
  const [invernaderosFisicos] = useGlobal("invernaderosFisicos");
  const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
  const [presentaciones] = useGlobal("presentaciones");
  const [sitiosEmpaques] = useGlobal("empaques");
  const [cliente] = useGlobal("clienteSeleccionado");

  const [productosYVariedades] = useGlobal("productosYVariedades");
  const [productosBases] = useGlobal("productosBases");
  const [medleysGeneral] = useGlobal("medleysGeneral");
  const [productosMezclados] = useGlobal("productosMezclados");
  const [camiones] = useGlobal("camionesOnHold");

  const [fotoAMostrar, setFotoAMostrar] = useState("");
  const [productosFiltro, setProductosFiltro] = useState([]);
  const [itemsOriginales, setItemsOriginales] = useState([]);
  const [items, setItems] = useState([]);
  const [currValues, setCurrValues] = useState({});
  const [productosTodos] = useGlobal("productosYVariedadesTodos");
  const [puedeOrdenar, setPuedeOrdenar] = useState(true);
  const [sortDatos, setSortDatos] = useState({ sortField: null, sortOrder: 0 });

  const GUARDAR_REAL_TIME = true;
  //const [globalFilter, setGlobalFilter] = useState("");

  useEffect(() => {
    if (props.items?.length > 0) { setItemsOriginales(props.items); }
  }, [props.itemsActualizados, props.items])
  useEffect(() => {
    if (productosBases) { setProductosFiltro(productosBases.map(el => el.id)); }
  }, [productosBases])

  useEffect(() => {
    //console.log("useEffect - itemsOriginales");
    //const itemsFiltrados = itemsOriginales.filter(item => checarIncluirItem(item));
    const itemsFiltrados = itemsOriginales.filter(item => productosFiltro.includes(item.producto_ref) || productosFiltro.includes(item.presentacion?.producto_ref) || (item.presentacion?.mezcla && productosFiltro.includes(medleysGeneral?.id)));
    //console.log(itemsOriginales); 
    setItems(itemsFiltrados/* .map(el => ({...el, producto: getObjetoPorID(productosTodos, el.presentacion?.id)})) */)
  }, [productosFiltro, JSON.stringify(itemsOriginales)])

  const editarItemsDatos = (rowData, field, value, guardarRealTime = false) => {
    //console.log("DATOS editarItemsDatos: ",{itemsCopia,foundIndex, rowData, field, value})
    const itemsCopia = DeepClone(props.items);
    const foundIndex = props.items?.findIndex(el => el.itemID === rowData.itemID);
    itemsCopia[foundIndex][field] = value;
    //console.log("VALUE CHANGE ", value)

    if (field === "invernadero_ref") {
      //const producto = getObjetoPorID(productosYVariedades, invernaderoDelItem.producto_ref);
      itemsCopia[foundIndex].nombre_invernadero = getNombrePorID(invernaderosVirtuales, value);
      //itemsCopia[foundIndex].nombre_producto = producto.nombre;
      //itemsCopia[foundIndex].producto_ref = producto.id;
      //itemsCopia[foundIndex].producto = DeepClone(producto);
      itemsCopia[foundIndex].invernadero_ref = value;

      const datosActualizados = {
        nombre_invernadero: getNombrePorID(invernaderosVirtuales, value),
        invernadero_ref: value,
        //nombre_producto: producto.nombre,
        //producto_ref: producto.id,
        //producto: DeepClone(producto),
        id: `${value}-${itemsCopia[foundIndex].presentacion_ref}`,//invernadero_ref-presentacion_ref//
        editadoDeItemsDeOrden: Date.now(),
      }

      delete itemsCopia[foundIndex].empaque_ref;
      if (props.editing) { props.actualizarItemManifiesto(rowData.itemID, datosActualizados, itemsCopia[foundIndex].invernadero_ref, itemsCopia[foundIndex].presentacion_ref, value, itemsCopia[foundIndex].presentacion_ref); }
    }
    else if (field === "presentacion_ref") {
      const presentacion = getObjetoPorID(presentaciones, value);
      const producto = getObjetoPorID(productosTodos, presentacion.producto_ref);

      itemsCopia[foundIndex].presentacion_id = presentacion.id_presentacion;
      itemsCopia[foundIndex].presentacion_item = presentacion.item;
      itemsCopia[foundIndex].presentacion_ref = presentacion.id;
      itemsCopia[foundIndex].presentacion = DeepClone(presentacion);

      itemsCopia[foundIndex].nombre_producto = producto.nombre;
      itemsCopia[foundIndex].producto_ref = producto.id;
      itemsCopia[foundIndex].producto = DeepClone(producto);

      const datosActualizados = {
        nombre_invernadero: getNombrePorID(invernaderosVirtuales, value),
        presentacion_id: presentacion.id_presentacion,
        presentacion_item: presentacion.item,
        presentacion_ref: presentacion.id,
        presentacion: DeepClone(presentacion),
        nombre_producto: producto.nombre,
        producto_ref: producto.id,
        producto: DeepClone(producto),
        id: `${itemsCopia[foundIndex].invernadero_ref}-${value}`,//invernadero_ref-presentacion_ref//
        editadoDeItemsDeOrden: Date.now(),
      }

      const pallets_orden = convertirCajasPresentacionAPallets(parseFloat(rowData.cajas_orden), presentacion);
      itemsCopia[foundIndex].pallets_orden = pallets_orden;
      if (props.editing) { props.actualizarItemManifiesto(rowData.itemID, datosActualizados, itemsCopia[foundIndex].invernadero_ref, itemsCopia[foundIndex].presentacion_ref, itemsCopia[foundIndex].invernadero_ref, value); }
    }
    else if (field === "pallets_orden") {
      const presentacion = getObjetoPorID(presentaciones, rowData.presentacion.id);
      const cajas_orden = parseFloat(value) * presentacion.numero_cajas_pallet;
      itemsCopia[foundIndex].cajas_orden = cajas_orden == 0 ? null : (cajas_orden || 0);
    }
    else if (field === "cajas_orden") {
      const presentacion = getObjetoPorID(presentaciones, rowData.presentacion.id);
      const pallets_orden = convertirCajasPresentacionAPallets(parseFloat(value), presentacion);
      itemsCopia[foundIndex].pallets_orden = pallets_orden == 0 ? null : (pallets_orden || 0);
    }

    props.setTablaItems(itemsCopia, props.editing ? guardarRealTime : false);
  }

  const editorBodyInput = ({ body, rowData, field, dropdownOptions, optionLabel = "nombre", optionValue = "id", dropdownTemplate }, keyfilterPassed = "", disabled) => {
    if (!props.editing) { return null; }
    if (sortDatos.sortOrder) { return "Desactivar ordenamiento"; }

    const key = `${rowData.itemID}-${field}`, copia = DeepClone(currValues);
    if (copia[key] == null) {
      copia[key] = DeepClone(rowData[field]);

      if (JSON.stringify({ ...currValues, [key]: rowData[field] }) !== JSON.stringify(currValues))
        setCurrValues({ ...currValues, [key]: rowData[field] });
    }

    if (dropdownOptions) {
      if (field == "invernadero_ref") {
        dropdownOptions = ordenarInvernaderosOpciones(getObjetoPorID(presentaciones, rowData.presentacion_ref))
        //console.log("DROPDOWN : ",{options:ordenarInvernaderosOpciones(getObjetoPorID(presentaciones, rowData.presentacion_ref)), presentacion: getObjetoPorID(presentaciones, rowData.presentacion_ref)})
      }

      const key = `${rowData.itemID}-${field}`;
      const copia = DeepClone(currValues);
      if (copia[key] == null) { copia[key] = DeepClone(rowData[field]); setCurrValues({ ...currValues, [key]: rowData[field] }) }

      return dropdownTemplate
        ? <Dropdown disabled={disabled} optionLabel={optionLabel} optionValue={optionValue} options={dropdownOptions}
          value={rowData[field]} onChange={(e) => editarItemsDatos(rowData, field, e.target.value, GUARDAR_REAL_TIME)} itemTemplate={dropdownTemplate} />
        : <Dropdown disabled={disabled} optionLabel={optionLabel} optionValue={optionValue} options={dropdownOptions}
          value={rowData[field]} onChange={(e) => editarItemsDatos(rowData, field, e.target.value, GUARDAR_REAL_TIME)} />;
    }
    else {
      return <InputText key={key} type="text" keyfilter={keyfilterPassed} value={rowData[field]} onChange={(e) => editarItemsDatos(rowData, field, e.target.value)}
        onKeyDown={keyPress => { onKeyDownChange(keyPress.key, copia, rowData, field, key) }} onBlur={() => { onBlurChange(copia, rowData, field, key) }} />;
    }
  }
  const onBlurChange = (datosAGuardar, rowData, field, key) => { //console.log("BLURRING - editing: ", props.editing);
    if (props.editing) {
      const actualizarCurrValues = () => { setCurrValues({ ...currValues, [key]: rowData[field] }); }
      props.handleGuardadoGlobalItems(datosAGuardar[key], rowData[field], actualizarCurrValues);
    }
  }
  const onKeyDownChange = (keyDown, datosAGuardar, rowData, field, key) => {
    if (props.editing && BOTONES_GUARDAR.includes(keyDown)) {//Guardar datos
      const actualizarCurrValues = () => { setCurrValues({ ...currValues, [key]: rowData[field] }); }
      props.handleGuardadoGlobalItems(datosAGuardar[key], rowData[field], actualizarCurrValues);
    }
    else if (BOTONES_CANCELAR.includes(keyDown)) {//Regresar datos como estaban antes de escribir
      editarItemsDatos(rowData, field, datosAGuardar[key]);
    }
  }

  const editorBodyInputText = (rowProps) => editorBodyInput(rowProps, null);
  const editorBodyInputNumero = (rowProps) => editorBodyInput(rowProps, "num");
  const editorBodyDropdown = (rowProps) => editorBodyInput(rowProps, null);
  const editorBodyDropdownEmpaque = (rowProps) => {
    const { empaque_ref, invernadero_ref } = rowProps.rowData;
    const invernaderoDelItem = getObjetoPorID(invernaderosVirtuales, invernadero_ref);

    rowProps.rowData.empaque_ref = empaque_ref || getEmpaqueDeVentaItem(invernaderosFisicos, invernaderosVirtuales, invernadero_ref);
    const disabled = !invernaderoDelItem.es_externo;//deshabilitar la hablidad de editar empaque si no es un invernadero externo
    return editorBodyInput(rowProps, null, disabled);
  }
  const ordenarInvernaderosOpciones = (presentacion) => {
    if (!presentacion?.id) { return []; }

    let invernaderosOpciones = invernaderosVirtuales?.slice();
    if (invernaderosVirtuales && presentacion.mezcla) {
      invernaderosOpciones = invernaderosOpciones.filter(inv => inv.mezcla);
    }
    else if (invernaderosVirtuales && !presentacion.mezcla) {
      invernaderosOpciones = invernaderosOpciones.filter(inv => {
        if (inv.mezcla) {
          const productoDelInv = getObjetoPorID(productosTodos, inv.producto_ref)
          return productoDelInv.variedades.some(mezclaVar => {
            return mezclaVar.producto_ref === presentacion.producto_ref;
          });
        }
        else { return presentacion.producto_ref === inv.producto_ref; }
      });
    }
    if (!presentacion) { return invernaderosOpciones; }

    if (presentacion.mezcla) {
      const invernaderosDelCliente = invernaderosOpciones.filter(inv => inv.cliente_ref === presentacion?.cliente_ref);
      const invernaderosOtrosClientes = invernaderosOpciones.filter(inv => inv.cliente_ref !== presentacion?.cliente_ref);
      return [...invernaderosDelCliente, ...invernaderosOtrosClientes];
    }
    else {
      const invernaderosUnicosDelCliente = invernaderosOpciones.filter(inv => !inv.mezcla && inv.cliente_ref === presentacion?.cliente_ref);
      const invernaderosUnicosOtrosClientes = invernaderosOpciones.filter(inv => !inv.mezcla && inv.cliente_ref !== presentacion?.cliente_ref);
      const invernaderosMedleysDelCliente = invernaderosOpciones.filter(inv => inv.mezcla && inv.cliente_ref === presentacion?.cliente_ref);
      const invernaderosMedleysOtrosClientes = invernaderosOpciones.filter(inv => inv.mezcla && inv.cliente_ref !== presentacion?.cliente_ref);
      return [...invernaderosUnicosDelCliente, ...invernaderosMedleysDelCliente, ...invernaderosUnicosOtrosClientes, ...invernaderosMedleysOtrosClientes];
    }
  }

  const bodyInvernadero = (rowData, column) => <div> {getNombrePorID(invernaderosVirtuales, rowData[column.field], " ")}</div>;
  const bodyPresentacion = (rowData, column) => {
    const presentacionesFiltrados = getPresentacionesDeCliente()
    const presentacion = getObjetoPorID(presentacionesFiltrados, rowData.presentacion_ref);
    const field = column.field === "presentacion_ref" ? "id_presentacion" : column.field.split(".")[1]
    const texto = presentacion[field];
    //console.log("presentation: ", { presentacionesFiltrados, rowData, presentacion, field, texto });

    if (props.editing) {
      //let tooltip = `<p>Imagen no disponible</p>`
      return (
        <div>
          {texto}
          {presentacion?.foto_url && <Button className="btn-line btn-img" label="IMG" onClick={() => setFotoAMostrar(presentacion.foto_url)} />}
        </div>);
    }
    else return (<div> {texto}</div>);
  }
  const getPresentacionesDeCliente = () => {
    const presentacionesCopia = DeepClone(presentaciones);
    return presentacionesCopia?.filter(el => el.cliente_ref === cliente?.id) || [];
  }
  const bodyEmpaque = (rowData, column) => {
    //console.log("BODY EMP: ",{rowData, column})
    const sitio = rowData[column.field];
    if (sitio) { return getNombrePorID(sitiosEmpaques, sitio, " "); }//ciertos items posiblemente tiene empaque_ref sin tener que generarlo
    else if (invernaderosFisicos) {
      const invVirtual = getObjetoPorID(invernaderosVirtuales, rowData.invernadero_ref);
      const invFisico = getObjetoPorID(invernaderosFisicos, invVirtual.invernadero_fisico_ref);
      return getNombrePorID(sitiosEmpaques, invFisico.empaque_ref, " ");
    }
    else return "";
  }
  const cajasYPalletsTemplate = (rowData, column) => {
    if (props.editing) {
      const texto = rowData[column.field];
      return (
        <div>
          {dosDeciComas(texto)}
          {hayModificacionDelItem(rowData, props.historico) && <Button className="btn-line btn-help ml-1" type="button" label="?" tooltip={obtenerTooltip(rowData.presentacion_ref)} />}
        </div>)
    }
    return separadoComas(rowData[column.field]);
  }

  const obtenerTooltip = (presentacion_ref) => {
    const filtrados = props.historico?.filter(el => el.presentacion_ref === presentacion_ref) || [];
    if (!filtrados?.length) { return "No existen modificaciones"; }

    const mostrarRecientes = 5;
    const historicosAMostrar = (filtrados?.length > mostrarRecientes) ? filtrados.slice(0, mostrarRecientes) : filtrados;

    const tooltipHistorico = historicosAMostrar.map((el, index) => {
      const usuario = props.usuarios?.find(curr => curr.id === el.usuario);
      return `<div key={${index}} class='tooltip-datas tooltip-separator'> 
                    <div class='tooltip-data'>
                      <p class='tooltip-title'>${usuario?.displayName || el.usuario}</p>
                      <p class='tooltip-date'>${obtenerNombreFechaCompacto(el.fecha_modificacion)} - ${obtener_horaMinutos(el.fecha_modificacion)}</p> 
                    </div>
                    <div class='tooltip-data'>${dosDeciComas(el.pallets)}
                      <p class='tooltip-label'>Pallets</p>
                    </div>
                    <div class='tooltip-data'>${dosDeciComas(el.cajas)}
                      <p class='tooltip-label'>Cajas</p>
                    </div>
                  </div>`;
    })
    return tooltipHistorico.join(" ");
  }

  const bodyAcciones = (rowData, column) => {
    return (
      <div className="table-btns">
        <button className="table-btn" onClick={() => props.eliminarItemByID(rowData.itemID, rowData.invernadero_ref, rowData.presentacion_ref, !!props.editing)}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
        {!props.editing &&
          <button className="table-btn" onClick={() => props.modificarItemByID(rowData.itemID)} >
            <FontAwesomeIcon icon={faEdit} />
          </button>}
      </div>);
  }

  const bodyCamionesLista = (rowData, column) => {
    const camionesNumeros = [];

    //Manifiestos locales
    for (const index in camiones) {
      const camion = camiones[index];

      for (const indexMani in camion.manifiestos) {
        const manifiesto = camion.manifiestos[indexMani];
        const ordenCamion = parseInt(manifiesto.orden_camion);
        //console.log("CAMIONES orden 1: ", ordenCamion)

        for (const indexItem in manifiesto.items) {
          const item = manifiesto.items[indexItem]
          if (item.presentacion_ref === rowData.presentacion_ref && item.invernadero_ref === rowData.invernadero_ref && !camionesNumeros.includes(ordenCamion)) {
            camionesNumeros.push(ordenCamion);
          }
        }
      }
    }

    //manifiestos existentes
    for (const index in props.manifiestos) {
      const manifiesto = props.manifiestos[index];
      const ordenCamion = parseInt(manifiesto.orden_camion);
      //console.log("CAMIONES orden 2: ", ordenCamion)

      for (const indexItem in manifiesto.items) {
        const item = manifiesto.items[indexItem]
        if (item.presentacion_ref === rowData.presentacion_ref && item.invernadero_ref === rowData.invernadero_ref && !camionesNumeros.includes(ordenCamion)) {
          camionesNumeros.push(ordenCamion);
        }
      }
    }

    //console.log("CAMIONES camionesNumeros: ", camionesNumeros);
    const camionNumerosOrdenados = camionesNumeros.sort((a, b) => a - b);
    return camionNumerosOrdenados.join(", ");
  }

  const sortEmpaques = ({ order }) => {//Custom sort function para la columna de sitio empaque en la tabla; se usa porque notodos los items tienen la propiedad de 'empaque_ref'.
    const itemsCopia = DeepClone(items);
    itemsCopia.sort((a, b) => bodyEmpaque(a, { field: "empaque_ref" }) > bodyEmpaque(b, { field: "empaque_ref" }) ? order : (-1 * order));
    return itemsCopia;
  }

  const obtenerFooter = () => {
    const footerData = itemMappingToAddTotals(items);
    const { totalPallets, totalCajas } = footerData;
    return <ColumnGroup>
      <Row>
        <Column className="text-align-left" footer={<UnidadBase valor="Totales:" />} colSpan={1} />
        <Column className="text-align-left" footer={null} colSpan={7} />
        <Column className="text-align-left" footer={<UnidadBase valor={dosDeciComas(totalPallets) || "0"} unidad="pallets" />} colSpan={1} />
        <Column className="text-align-left" footer={<UnidadBase valor={dosDeciComas(totalCajas) || "0"} unidad="cajas" />} colSpan={1} />
        <Column className="text-align-left" footer={null} colSpan={props.editing ? 2 : 1} />
      </Row>
    </ColumnGroup>;
  }
  const invernaderoItemBody = (rowData) => {
    return <label>{rowData.nombre} <label className="producto-text-small">({getProductoYVariedadString(rowData)})</label></label>
  }

  const bodyProducto = ({ presentacion, invernadero_ref, producto }) => {
    const invernaderoObj = getObjetoPorID(invernaderosVirtuales, invernadero_ref);
    if (!invernaderoObj?.mezcla && presentacion?.mezcla) { return "-"; }
    else return presentacion?.mezcla
      ? getNombrePorID(productosYVariedades, invernaderoObj?.producto_ref, " ")
      : getNombrePorID(productosYVariedades, presentacion?.producto_ref, " ");
  }

  return (
    <React.Fragment>
      {props.editing &&
        <SeccionFiltros>
          <ContenedorFiltro label="producto">
            <MultiSelect id="producto" options={productosBases} value={productosFiltro} onChange={(e) => setProductosFiltro(e.value)}
              filter={false} optionLabel="nombre" optionValue="id" selectedItemsLabel={`${productosFiltro.length} productos seleccionados`} />
          </ContenedorFiltro>
        </SeccionFiltros>}

      <ContenedorTabla>
        <DataTable sortField={sortDatos.sortField} sortOrder={sortDatos.sortOrder} onSort={datos => { setSortDatos(datos); }} removableSort={true} selectionMode="single"
          footerColumnGroup={obtenerFooter()} className="orden-items-table" value={items} emptyMessage="No hay items" sortable={puedeOrdenar} >

          <Column header="PO" field="po" editor={!props.editing ? null : editorBodyInputText} />
          <Column className="cell-overflow" header="Invernadero" field="invernadero_ref" dropdownOptions={invernaderosVirtuales} body={bodyInvernadero}
            sortField="nombre_invernadero" editor={!props.editing ? null : editorBodyDropdown} dropdownTemplate={invernaderoItemBody} sortable={!props.editing} />
          <Column className="cell-overflow" header="Sitio de empaque" field="empaque_ref" dropdownOptions={sitiosEmpaques} body={bodyEmpaque}
            editor={!props.editing ? null : editorBodyDropdownEmpaque} sortFunction={sortEmpaques} sortable={!props.editing} />

          <Column header="Item" field="presentacion.item" sortable={!props.editing} />
          <Column header="Producto" field="producto.nombre" sortable={!props.editing} body={bodyProducto} />
          <Column header="ID de Presentacion" field="presentacion_ref" className="cell-overflow" optionLabel="id_presentacion" dropdownOptions={getPresentacionesDeCliente()}
            body={bodyPresentacion} editor={!props.editing ? null : editorBodyDropdown} sortField="presentacion.id_presentacion" sortable={!props.editing} />
          <Column header="Presentación cliente" field="presentacion.descripcion" body={bodyPresentacion} sortable={!props.editing} />
          <Column header="Presentación HTG" field="presentacion.presentacion" body={bodyPresentacion} sortable={!props.editing} />

          <Column header="Pallets" field="pallets_orden" body={cajasYPalletsTemplate} editor={!props.editing ? null : editorBodyInputNumero} sortable={!props.editing} />
          <Column header="Cajas" field="cajas_orden" body={cajasYPalletsTemplate} editor={!props.editing ? null : editorBodyInputNumero} sortable={!props.editing} />

          {props.editing && <Column header="Camiones" field="" body={bodyCamionesLista} />}
          <Column header="" field="acciones" body={bodyAcciones} />
        </DataTable>
      </ContenedorTabla>

      <Dialog visible={!!fotoAMostrar} onHide={() => setFotoAMostrar(false)}>
        {fotoAMostrar ? <img className="foto-modal" src={fotoAMostrar} /> : "No hay foto para esta presentación"}
      </Dialog>
    </React.Fragment>);
};
export default TablaItems;//251