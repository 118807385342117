import React from 'react'

export default function SeccionFiltros(props) {
    return <div className="p-col-12" >
        <div className="p-grid filtros" >
        {props.children}
        </div>
    </div>
    
}
