//react/reactn
import React, { useState, useEffect, useGlobal } from 'reactn'
//Funciones internas
import { getColoresVariedades, guardarColorVariedad } from '../../../service/ProductosYVariedades';
//Componentes third party
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
//Componentes internos
import ContenedorPrincipal from '../../common/ContenedorPrincipal';
import ContenedorHeader from '../../common/ContenedorHeader';
import ContenedorInput from '../../common/ContenedorInput';
import SeccionBotones from '../../common/SeccionBotones';
import SeccionFormulario from '../../common/SeccionFormulario';

const FormularioColores = (props) => {
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [, setCargando] = useGlobal("cargando");
    const [colorVariedad] = useGlobal("colorVariedad")
    const [, setColoresVariedades] = useGlobal("coloresVariedades")
    const [editing] = useState(colorVariedad ? true : false);
    const [errores, setErrores] = useState({})
    //Inputs del formulario
    const [nombre, setNombre] = useState(colorVariedad?.nombre || "");

    useEffect(() => {
        setNombreModulo("Productos y variedades");
        setHabilitadoSelectorCiclo(false);
        obtenerDatosIniciales();
    }, [])
    useEffect(() => {
        setErrores({})
    }, [nombre])

    const obtenerDatosIniciales = async () => {
        const colores = await getColoresVariedades();
        setColoresVariedades(colores);
    }

    const guardar = async () => {
        if (validarErrores()) {
            setCargando(true);
            const datos = { nombre };
            await guardarColorVariedad(colorVariedad?.id, datos);

            setCargando(false);
            props.history.goBack();
        }
    }
    const validarErrores = () => {
        const errores = {};
        const camposRequeridos = { "nombre": nombre };

        for (const key in camposRequeridos) {
            const campo = camposRequeridos[key];
            if (!campo) { errores[key] = true; }
        }
        setErrores(errores);
        return Object.keys(errores).length === 0;
    }

    return (
        <ContenedorPrincipal>
            <ContenedorHeader titulo={`${editing ? "Editar" : "Crear"} color`} iconos="presentacion-icon" atras={props.history.goBack} />

            <SeccionFormulario titulo="General">
                <ContenedorInput label="Nombre">
                    <InputText value={nombre} onChange={(e) => setNombre(e.target.value)} />
                    {errores.nombre && <Message severity="error" text="Este campo es requerido" />}
                </ContenedorInput>
            </SeccionFormulario>

            <SeccionBotones>
                <Button label="Cancelar" className="p-button-secondary" onClick={props.history.goBack} />
                <Button label="Guardar" onClick={guardar} />
            </SeccionBotones>
        </ContenedorPrincipal>
    )
}
export default FormularioColores;