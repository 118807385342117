import { Dropdown } from 'primereact/dropdown'
import { SelectButton } from 'primereact/selectbutton'
import React, { useState, useEffect } from 'react'
import { useGlobal } from 'reactn'
import { obtenerDistribucionSemanaVariedadesMezclas } from '../../service/Cosecha'
import ChartContainer from '../common/ChartContainer'
import ContenedorFiltro from '../common/ContenedorFiltro'
import ContenedorPrincipal from '../common/ContenedorPrincipal'
import HeaderGrafica from '../common/HeaderGrafica'
import SeccionFiltros from '../common/SeccionFiltros'
import Chart from "react-apexcharts";
import { dosDecimales, paletaColores, separadoComas } from '../../util/functions';
import { obtenerCiclo } from '../../service/Ciclos'
import { obtenerListaDeSemanas } from '../../service/fechas'
const opcionesPeriodo = [
  // {label:"Real",value:"real"},
  { label: "Diario", value: "diario" },
  { label: "Semanal", value: "semanal" },
  { label: "Real", value: "real" },
]
const tiposGrafica = [

  {
    label: "Absoluto", value: "absoluto"
  },
  {
    label: " 100 %", value: "cien"
  },

]
let optionsOrig = {
  colors: paletaColores,
  chart: {
    type: 'bar',
    height: 350,
    stacked: true,
    stackType: "100%",
    animations: {
      enabled: false
    }
  },
  dataLabels: {
    colors: paletaColores,
    enabled: true,
    formatter: (val, opt) => {
      // 
      // 
      // 

      val = separadoComas(Math.round(val));
      if (opt["w"]["config"]["chart"]["stackType"] === "100%") {
        val = `${val} %`
      }
      return val;
    }
  },
  responsive: [{
    breakpoint: 480,
    options: {
      legend: {
        position: 'bottom',
        offsetX: -10,
        offsetY: 0,
        colors: paletaColores
      }
    }
  }],
  xaxis: {
    categories: [],
  },
  yaxis: {
    decimalsInFloat: 2
  },
  plotOptions: {
    bar: {
      horizontal: true,
    }
  },
  fill: {
    opacity: 1
  },
  legend: {
    colors: paletaColores,
    position: 'right',
    offsetX: 0,
    offsetY: 400,
  },
}
export default function DistribucionVariedadesMedleys(props) {
  const [tipoGrafica, setTipoGrafica] = useState(tiposGrafica[0]["value"])
  const [invernaderos, setInvernaderos] = useState([])
  const [invernadero, setInvernadero] = useState()
  const [cicloId, setCicloId] = useGlobal("ciclo")
  const [options, setOptions] = useState({ ...optionsOrig })
  const [data, setData] = useState()
  const [series, setSeries] = useState([])

  const [cicloObj] = useGlobal("cicloObj");
  const [tipoPeriodo, setTipoPeriodo] = useState(opcionesPeriodo[0]["value"])
  const [cargando, setCargando] = useGlobal("cargando")
  const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales")

  const atras = (params) => {
    props.history.goBack()
  }
  useEffect(() => {
    if (cicloId) {
      obtenerDatosIniciales();
    }
  }, [cicloId])
  useEffect(() => {
    if (invernadero) {
      actualizarReporte()
    }
  }, [invernadero])
  useEffect(() => {
    procesarData();

  }, [data])
  const obtenerOptions = (params) => {
    let copia = { ...options };
    copia["chart"] = { ...options["chart"] };
    copia["xaxis"] = { ...copia["xaxis"] };
    copia["chart"]["stackType"] = "normal";
    copia["dataLabels"]["enabled"] = true;
    if (cicloObj) {

      let listaSemanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
      copia["xaxis"]["categories"] = listaSemanas.map(el => el.nombreCompacto);
    }
    return copia;
  }
  const obtenerOptionsP = (params) => {
    let copia = { ...options };
    copia["chart"] = { ...options["chart"] };
    copia["xaxis"] = { ...copia["xaxis"] };
    copia["chart"]["stackType"] = "100%";
    copia["dataLabels"]["enabled"] = true;
    if (cicloObj) {
      let listaSemanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
      copia["xaxis"]["categories"] = listaSemanas.map(el => el.nombreCompacto);
    }
    return copia;
  }

  const actualizarReporte = async (params) => {
    setCargando(true);
    let reporte = await obtenerDistribucionSemanaVariedadesMezclas(cicloId, invernadero);
    setCargando(false);
    setData(reporte);
  }

  const obtenerDatosIniciales = async () => {
    const invernaderosConMezclas = invernaderosVirtuales.filter(el => el.mezcla);
    setInvernaderos(invernaderosConMezclas);
  }
  useEffect(() => {
    procesarData();
  }, [tipoPeriodo])
  const procesarData = (params) => {
    if (!data || data.length === 0) {
      setSeries([]);
    } else {

      if (tipoPeriodo === "diario") {
        let series = data.map(el => {
          let obj = {}
          obj["name"] = el.variedad.nombre
          obj["data"] = el["data"].map(semana => semana["diario"]);
          return obj;
        })
        setSeries(series);
      } else if (tipoPeriodo === "semanal") {
        let series = data.map(el => {
          let obj = {}
          obj["name"] = el.variedad.nombre
          obj["data"] = el["data"].map(semana => semana["semanal"]);
          return obj;
        })

        setSeries(series)
      } else {
        let series = data.map(el => {
          let obj = {}
          obj["name"] = el.variedad.nombre
          obj["data"] = el?.data?.map(semana => semana?.real) || [];
          return obj;
        })
        setSeries(series)
      }
    }
  }
  const GraficaNormal = (props) => {
    return <Chart options={obtenerOptions()} series={series} type="bar" width="100%" height="100%" />;

  }

  const Grafica100 = (props) => {
    return <Chart options={obtenerOptionsP()} series={series} type="bar" width="100%" height="100%" />;

  }
  return (
    <ContenedorPrincipal extra="chart-page">
      <HeaderGrafica titulo="Distribución variedades medleys" atras={atras} />

      <SeccionFiltros>
        <ContenedorFiltro label="Tipo">
          <SelectButton options={tiposGrafica} value={tipoGrafica} onChange={e => setTipoGrafica(e.value)} />
        </ContenedorFiltro>
        <ContenedorFiltro label="Invernadero">
          <Dropdown options={invernaderos} value={invernadero} onChange={e => setInvernadero(e.value)} dataKey="id" optionLabel="nombre" />
        </ContenedorFiltro>
        <ContenedorFiltro label="Periodo">
          <SelectButton value={tipoPeriodo} options={opcionesPeriodo} onChange={e => setTipoPeriodo(e.value)} />
        </ContenedorFiltro>
      </SeccionFiltros>

      <ChartContainer type="large">
        {tipoGrafica === "cien" ?
          <Grafica100 /> :
          <GraficaNormal />}
      </ChartContainer>

    </ContenedorPrincipal>)
}