import React from "react";

const Firma = ({showJustPrinting, imagen, nombre, rol, col="12"}) => {
  return (
    <div className={`${showJustPrinting && "firma-grower show-print"} p-col-${col}`} >
      <div className="p-grid p-justify-center">
        <div className="firma-calidad">
          <div className="p-flex p-flex-direction-column p-align-items-center">
            <img alt="Firma Digital" src={imagen} />
            <div className='linea-firma'></div>
            <p className="firma-nombre">{nombre}</p>
            <p className="firma-nombre">{rol}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Firma