import React, { useState, useEffect,  } from "react";
import { useGlobal } from "reactn";
import { InputText } from "primereact/inputtext";
import ContenedorPrincipal from "components/common/ContenedorPrincipal";

import ModalConfirmacion from '../common/ModalConfirmacion';

import { DataTable } from "primereact/datatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import SeccionFiltros from "../planeacion_mantenimiento/CapturaAgentes/SeccionFiltros";
import ContenedorFiltro from "../common/ContenedorFiltro";
import ContenedorFiltroBusqueda from "../common/ContenedorFiltroBusqueda";
import { DeepClone, dosDeciComas, formatoDinero, getArregloDeObjeto, getMomentFormatted, getObjetoPorID, parseFloatHTG, selectedItemNombresMultiselect, selectedItemPresentacionesMultiselect, separadoComas, sumarNumerosArrayPorCampo } from "../../util/functions";
import { MultiSelect } from "primereact/multiselect";
import ContenedorTabla from "../common/ContenedorTabla";
import { getInvoicesCiclo } from "../../service/helpers/Invoices.helpers";
import { obtenerClientesCiclo } from "../../service/Clientes";
import { getPresentaciones } from "../../service/Presentaciones";
import { obtenerGruposPresentaciones } from "../../service/QueriesGruposPresentaciones";
import { UnidadBase } from "../common/templates_unidades/UnidadBase.atomo";
import { UnidadPrecioPorLibra, UnidadPrecioPorLibraCol } from "../common/templates_unidades/UnidadPrecioPorLibra";
import { UnidadCajas } from "../common/templates_unidades/UnidadCajas.atomo";
import { Column } from "primereact/column";
import { obtenerDiasDeSemanaExtendidos } from "../../service/fechas";
import { estamosEnProduccion } from "../../constants";
import { guardarManifiesto, obtenerManifiestosCiclo } from "../../service/QueriesManifiestos";
import { getGrupoPorIdDePresentacion } from "../../service/helpers/Presentaciones.helpers.";
import ContenedorHeaderClickableDev from "../common/ContenedorHeaderClickableDev";
import { valoresValidacionManifiesto, opcionesRechazoInvoice, VALIDADOS, RECHAZOS } from "service/constants/Rechazos.const";
import firebase from "firebase";
import { eliminarInvoiceQUERY } from "service/repositorios/Finanzas.queries";
import { SelectButton } from "primereact/selectbutton";
import WeekSelector, { useWeekSelector } from "components/common/WeekSelector";

const TablaInvoices = (props) => {
  const [, setNombreModulo] = useGlobal("nombreModulo");
  const [ciclo] = useGlobal("ciclo");
  const [invernaderos] = useGlobal("invernaderosVirtuales");
  const [productosBases] = useGlobal("productosBases");
  const [, setCargando] = useGlobal("cargando");
  const [clientesOrig] = useGlobal("clientes");
  const [, setWeekGlobal] = useGlobal("weekGlobal");
  const [manifiestosTodos, setManifiestosTodos] = useState([]);
  const [toggleComponentesDev, setToggleComponentesDev] = useGlobal("toggleComponentesDev")


  const [filtroInvernaderos, setFiltroInvernaderos] = useState();
  const [filtroProductos, setFiltroProductos] = useState();
  const [filtroClientes, setFiltroClientes] = useState();
  const [filtroPresentaciones, setFiltroPresentaciones] = useState();
  const [filtroGrupos, setFiltroGrupos] = useState();
  const [clientes, setClientes] = useState([]);

  const [tipoRechazoInvoice, setTipoRechazoInvoice] = useState([RECHAZOS, VALIDADOS]);

  const [presentaciones, setPresentaciones] = useState([]);
  const [gruposPresentaciones, setGruposPresentaciones] = useState([]);
  const [presentacionesPresentes, setPresentacionesPresentes] = useState([]);
  const [gruposPresentes, setGruposPresentes] = useState([]);
  const [rowsInvoices, setRowsInvoices] = useState([]);
  const [rowsInvoicesFiltrados, setRowsInvoicesFiltrados] = useState([]);
  const [globalFilter, setGlobalFilter] = useState();
  const [modalEliminarInvoice, setModalEliminarInvoice] = useState(false);

  const [weeks,week,handleWeekChange] = useWeekSelector()

  useEffect(() => {
    setNombreModulo("Finanzas");
  }, [])

  useEffect(() => {
    if(week.nombre !="Cargando")
    setWeekGlobal(week);
  },[week]);
  
  useEffect(() => {
    if (ciclo) { obtenerDatosIniciales(); }
  }, [ciclo])

  useEffect(() => {
    //console.log("rowsFiltrados - ", rowsInvoices)
    const rowsFiltrados = rowsInvoices?.filter(invoice => checarCondicionesDeIncluirInvoice(invoice)) || [];
    setRowsInvoicesFiltrados(rowsFiltrados);

  }, [rowsInvoices, week, filtroInvernaderos, filtroClientes, filtroProductos, filtroGrupos, filtroPresentaciones, tipoRechazoInvoice])

  const checarCondicionesDeIncluirInvoice = (invoice) => {
    const semanaDias = obtenerDiasDeSemanaExtendidos(week.time);

    const selectedFechaInicio = semanaDias[0] * 1000
    const selectedFechaFinal = semanaDias[6] * 1000

    const invoiceTieneRechazos = parseFloatHTG(invoice.numeroCajasRechazadas) > 0;
    const invoiceValidado = !invoiceTieneRechazos;

    return invoice.items[0].fecha_envio >= selectedFechaInicio && invoice.items[0].fecha_envio <= selectedFechaFinal
      && filtroInvernaderos?.some(el => invoice.items?.some(item => el.id === item.invernadero_ref))
      && filtroClientes?.some(el => el.id === invoice.cliente_ref)
      && filtroProductos?.some(el => el.id === invoice.producto_ref)
      && filtroGrupos?.some(el => invoice.items?.some(item => el.presentaciones_refs?.includes(item.presentacion_ref)))
      && filtroPresentaciones?.some(el => invoice.items?.some(item => el.id === item.presentacion_ref))
      && tipoRechazoInvoice.some(tipo => (tipo === RECHAZOS && invoiceTieneRechazos) || (tipo === VALIDADOS && invoiceValidado))
  }

  const obtenerDatosIniciales = async () => {
    setCargando(true);
    //console.log("MES: ", getInicioMes())
    const [invoicesResp, clientesCicloResp, presentacionesResp, gruposPresentacionesResp, manifiestosResp] =
      await Promise.all([getInvoicesCiclo(ciclo), obtenerClientesCiclo(ciclo), getPresentaciones(), obtenerGruposPresentaciones(), obtenerManifiestosCiclo(ciclo)]);
    //console.log("INVVVV: ",{invoices: invoicesResp, length: invoicesResp.length});

    const clientesOpciones = clientesCicloResp.map(el => getObjetoPorID(clientesOrig, el.cliente_ref));
    const rows = procesarInvoices(invoicesResp, manifiestosResp, gruposPresentacionesResp);
    const presentacionesPres = presentacionesResp?.filter(presentacion => {
      return rows.some(row => row.items.some(item => item.presentacion_ref === presentacion.id))
    })
    const gruposPres = gruposPresentacionesResp?.filter(grupo => {
      return rows.some(row => row.items.some(item => grupo.presentaciones_refs?.includes(item.presentacion_ref)))
    })

    setManifiestosTodos(manifiestosResp);
    setClientes(clientesOpciones);
    setPresentaciones(presentacionesResp);
    setGruposPresentaciones(gruposPresentacionesResp);

    setRowsInvoices(rows);
    setRowsInvoicesFiltrados(rows);

    setFiltroInvernaderos(invernaderos);
    setFiltroClientes(clientesOpciones);
    setFiltroProductos(productosBases);
    setFiltroGrupos(gruposPres);
    setFiltroPresentaciones(presentacionesPres);

    setPresentacionesPresentes(presentacionesPres);
    setGruposPresentes(gruposPres);

    //console.log("INVOICES - obtenerDatosIniciales: ", { invoicesResp, rows });
    setCargando(false);
  }
  const procesarInvoices = (invoices = [], manifiestos = [], grupos = []) => {
    const rows = invoices.map(invoice => {
      const presentacionNombres = [], invernaderoNombres = [], presObjs = [], invObjs = [], clienteObjs = [], prodObjs = [];
      let grupo = { nombre: "-" };
      const items = invoice?.items.map(item => {
        const manifiestoDeItemDelInvoice = getObjetoPorID(manifiestos, item.manifiestoId);
        const itemDelManifiesto = manifiestoDeItemDelInvoice.items?.find(el => el.itemID === item.itemId) || {};
        const { presentacion, nombre_invernadero, presentacion_ref, invernadero_ref } = itemDelManifiesto;

        const grupoDeEsteItem = getGrupoPorIdDePresentacion(grupos, presentacion_ref);
        if (grupoDeEsteItem?.id) { grupo = grupoDeEsteItem }

        if (!presObjs?.some(el => el.id === presentacion?.id)) {
          presentacionNombres.push(presentacion.presentacion)
          presObjs.push(presentacion);
        }

        if (!invObjs?.some(el => el.id === presentacion?.id)) {
          invernaderoNombres.push(nombre_invernadero);
          const invObj = getObjetoPorID(invernaderos, invernadero_ref);
          invObjs.push(invObj);
        }

        return { ...itemDelManifiesto, ...item , fecha_envio: Number(manifiestoDeItemDelInvoice.fecha_envio) * 1000};
      })
      //console.log("done! ", { presentaciones: presObjs, invernaderos: invObjs, invernaderoNombres, presentacionNombres })
      return {
        ...invoice, grupo, items, presentaciones: presObjs, invernaderos: invObjs, invernaderoNombres, presentacionNombres,
      };
    })
    return rows
  }

  const footerInvoiceNum = () => <UnidadBase valor={rowsInvoicesFiltrados?.length || 0} unidad="totales" />
  const footerPrecioLibra = () => footerPrecioPromedio("precioPorLibra");
  const footerPrecioRealLibra = () => footerPrecioPromedio("precioPorLibraReal");
  const footerPrecioPromedio = (campoNombre) => {
    const invoicesASumar = rowsInvoicesFiltrados.map(el => ({ ...el, precioPorLibraReal: el.infoRechazos?.precioPorLibraReal || 0 }));
    const sumaPreciosTotal = sumarNumerosArrayPorCampo(invoicesASumar, campoNombre);
    const totalInvoices = rowsInvoicesFiltrados?.length || 0;
    const promedioPrecio = sumaPreciosTotal / totalInvoices;
    return <UnidadPrecioPorLibra valor={promedioPrecio} />;
  }
  const bodyMontoRechazado = (rowData, column) => rowData[column.field] ? <UnidadBase valor={`$${dosDeciComas(rowData[column.field])}`} /> : "-";
  const bodyCajasRechazadas = (rowData, column) => rowData[column.field] || "-";

  const footerMontoRechazado = () => <UnidadBase valor={"$" + formatoDinero(sumarNumerosArrayPorCampo(rowsInvoicesFiltrados, "dineroTotalRechazado"))} />
  const footerCajasRechazados = () => <UnidadCajas valor={separadoComas(sumarNumerosArrayPorCampo(rowsInvoicesFiltrados, "numeroCajasRechazadas"))} />
  const fechaFormattedBody = (rowData) => <label >{getMomentFormatted(rowData.items[0].fecha_envio, "DD/MM/YYYY")}</label>;

  const irInvoice = (event) => { props.history.push(`/rechazos/form_validacion_invoice/${event.data.id}`) };

  //DEV only
  const presListBody = (rowData) => {
    if (!rowData?.presentacionNombres?.length) return null;
    else return (
      <ul>
        {rowData?.presentacionNombres?.map(el => <li>{el}</li>)}
      </ul>)
  }

  const bodyAcciones = (rowData) => {
    return (
      <div className="body-acciones-icono">
        <button className="table-btn" onClick={e => rowBotonClick(e, rowData)}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      </div>);
  }
  const rowBotonClick = (e, rowData) => {
    e.stopPropagation();
    setModalEliminarInvoice(rowData);
  }

  const eliminarInvoice = async () => {
    if (!modalEliminarInvoice?.items) return;

    setCargando(true);
    try {
      const invoice = DeepClone(modalEliminarInvoice)
      const manifiestosEditados = deshacerValidaciónDeManifiestos(invoice);

      const promisesManifiestos = manifiestosEditados.map(manifiesto => guardarManifiesto(ciclo, manifiesto.id, manifiesto));
      const promises = [Promise.all(promisesManifiestos), eliminarInvoiceQUERY(ciclo, invoice.id), eliminarArchivosDelInvoice(invoice)]
      await Promise.all(promises);

      const rowsInvoicesCopia = DeepClone(rowsInvoices);
      const indexInvoice = rowsInvoices.findIndex(row => row.id === invoice.id)
      if (indexInvoice > -1) { rowsInvoicesCopia.splice(indexInvoice, 1); }
      setRowsInvoices(rowsInvoicesCopia);

      console.log("eliminarInvoice - DONE! ", { rowsInvoicesCopia, indexInvoice, invoice, rowsInvoices })
    } catch (error) {
      console.log("ERROR eliminarInvoice: ", error);
    }
    setCargando(false);
  }
  const eliminarArchivosDelInvoice = async (invoice) => {
    const promises = [];
    invoice.invoiceFiles.forEach((fileDatos, index) => {
      const storageRef = firebase.storage();
      const archivosInvoiceRef = storageRef.refFromURL(fileDatos.url);

      promises.push(archivosInvoiceRef.delete());
      console.log("URL: ", { url: fileDatos.url, archivosInvoiceRef, files_to_delete: promises.length })
    })
    await Promise.all(promises).catch(error => console.log("CATCH ERR: ", error));
  }

  const deshacerValidaciónDeManifiestos = (invoice) => {
    const manifiestosEditados = {}, manifiestosEditadosOG = {};
    const manifiestosTodosCopia = DeepClone(manifiestosTodos);

    invoice.items.forEach(item => {
      const { itemId, manifiestoId } = item;
      const manifiestoOG = getObjetoPorID(manifiestosTodosCopia, manifiestoId);
      const manifiestoObj = manifiestosEditados[manifiestoId] || manifiestoOG
      const indexItem = manifiestoObj?.items?.findIndex(item => item.itemID === itemId);

      if (manifiestoObj.items?.length && indexItem >= 0) {
        const manifiestoEditado = borrarValidacionPropiedades(manifiestoObj, indexItem);
        manifiestosEditados[manifiestoId] = manifiestoEditado;
        manifiestosEditadosOG[manifiestoId] = manifiestoOG; //Para dev pruebas
      }
    })

    const manifiestos = getArregloDeObjeto(manifiestosEditados);
    //console.log("MANIFIESTOS EDITADOS ::::::::: ", { manifiestosEditadosOG, manifiestosEditados });
    return manifiestos;
  }
  const borrarValidacionPropiedades = (manifiestoObj, indexItem) => {
    valoresValidacionManifiesto.forEach(llave => {
      delete manifiestoObj.items[indexItem][llave];
    })
    manifiestoObj.items[indexItem].status = "pendiente"
    return manifiestoObj;
  }
  const mostrarDev = () => !estamosEnProduccion && toggleComponentesDev;

  const handleTipoInvoiceChange = e => {
    if (e.target.value?.length) { setTipoRechazoInvoice(e.target.value); }
  }
  return (
    <ContenedorPrincipal>
      <ContenedorHeaderClickableDev col="p-col p-col-auto" titulo="Tabla de invoices" iconos="reporte-empaque-icon" atras={props.history.goBack} /* onClickDev={() => setToggleComponentesDev(!toggleComponentesDev)} */ />

      <SeccionFiltros>
        <ContenedorFiltroBusqueda col="3">
          <FontAwesomeIcon icon={faSearch} />
          <InputText type="search" value={globalFilter} onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar" />
        </ContenedorFiltroBusqueda>

        <ContenedorFiltro col="3" label="invernaderos">
          <MultiSelect options={invernaderos} value={filtroInvernaderos} optionLabel="nombre" dataKey="id" placeholder="Seleccione un invernadero"
            onChange={(e) => setFiltroInvernaderos(e.value)} selectedItemsLabel={`${filtroInvernaderos?.length} invernaderos seleccionados`}
            selectedItemTemplate={item => selectedItemNombresMultiselect(item, filtroInvernaderos, invernaderos)} />
        </ContenedorFiltro>
        <ContenedorFiltro col="3" label="clientes de presentaciones">
          <MultiSelect options={clientes} value={filtroClientes} optionLabel="nombre" dataKey="id" placeholder="Seleccione un cliente"
            onChange={(e) => setFiltroClientes(e.value)} selectedItemsLabel={`${filtroClientes?.length} clientes seleccionados`}
            selectedItemTemplate={item => selectedItemNombresMultiselect(item, filtroClientes, clientes)} />
        </ContenedorFiltro>
        <ContenedorFiltro col="3" label="productos de presentaciones">
          <MultiSelect options={productosBases} value={filtroProductos} optionLabel="nombre" dataKey="id" placeholder="Seleccione un producto"
            onChange={(e) => setFiltroProductos(e.value)} selectedItemsLabel={`${filtroProductos?.length} productos seleccionados`}
            selectedItemTemplate={item => selectedItemNombresMultiselect(item, filtroProductos, productosBases)} />
        </ContenedorFiltro>

        <ContenedorFiltro col="3" label="grupos de presentaciones">
          <MultiSelect options={gruposPresentes} value={filtroGrupos} optionLabel="nombre" dataKey="id" placeholder="Seleccione un grupo"
            onChange={(e) => setFiltroGrupos(e.value)} selectedItemsLabel={`${filtroGrupos?.length} grupos seleccionados`} filter={true}
            selectedItemTemplate={item => selectedItemNombresMultiselect(item, filtroGrupos, gruposPresentaciones)} />
        </ContenedorFiltro>
        <ContenedorFiltro col="3" label="presentaciones">
          <MultiSelect options={presentacionesPresentes} value={filtroPresentaciones} optionLabel="presentacion" dataKey="id" placeholder="Seleccione una presentación"
            onChange={(e) => setFiltroPresentaciones(e.value)} selectedItemsLabel={`${filtroPresentaciones?.length} presentaciones seleccionadas`} filter={true}
            selectedItemTemplate={item => selectedItemPresentacionesMultiselect(item, filtroPresentaciones, presentaciones)} />
        </ContenedorFiltro>

        <WeekSelector weeks = {weeks} handleWeekChange = {handleWeekChange} week = {week}/>

        <ContenedorFiltro col="3" label="tipo invoice">
          <SelectButton multiple={true} value={tipoRechazoInvoice} options={opcionesRechazoInvoice} onChange={handleTipoInvoiceChange} />
        </ContenedorFiltro>
      </SeccionFiltros>

      <ContenedorTabla>
        <DataTable value={rowsInvoicesFiltrados} globalFilter={globalFilter} onRowClick={irInvoice} rowHover={true} removableSort={true} className="table-invoices">
          <Column header="Invoice #" field="invoice" className="col__label" footer={footerInvoiceNum()} sortable={true} />

          <Column header="Fecha envío" field="fechaCreacion" body={fechaFormattedBody} sortable={true} />
          {mostrarDev() && <Column header="GRUPO" field="grupo.nombre" className="col__total col-grupo-dev" footerClassName="footer-no-border" sortable={true} />}
          {mostrarDev() && <Column header="PRES" field="" className="col__total col-pres-dev" body={presListBody} footerClassName="footer-no-border" />}
          {mostrarDev() && <Column header="INV" field="invernaderoNombres" className="col__total col-invs-dev" footerClassName="footer-no-border" />}

          <Column header="Precio por libra" field="precioPorLibra" body={UnidadPrecioPorLibraCol} footer={footerPrecioLibra()} sortable={true} />
          <Column header="Precio real por libra" field="infoRechazos.precioPorLibraReal" body={UnidadPrecioPorLibraCol} footer={footerPrecioRealLibra()} sortable={true} />

          <Column header="Monto total rechazado" field="dineroTotalRechazado" body={bodyMontoRechazado} sortable={true} footer={footerMontoRechazado()} />
          <Column header="Cajas rechazadas" field="numeroCajasRechazadas" body={bodyCajasRechazadas} sortable={true} footer={footerCajasRechazados()} />

          <Column field="id" body={bodyAcciones} className="body-acciones-col" headerClassName="body-acciones-col-header" footerClassName="h-100" />
        </DataTable>

        <ModalConfirmacion data={{ acceptLabel: "Eliminar", aceptar: eliminarInvoice, cancelar: () => { setModalEliminarInvoice(false); } }} show={modalEliminarInvoice}
          header="Confirmación de acción" mensaje="¿Seguro que desea eliminar este invoice?" onHide={() => { setModalEliminarInvoice(false); }} />

      </ContenedorTabla>
    </ContenedorPrincipal>);
}
export default TablaInvoices;