
export class Departamento {

    constructor(id, nombre) {
        this.id = id
        this.nombre = nombre
    }

}


export class Empaque {

    constructor(id, nombre, locacion_ref) {
        this.id = id
        this.nombre = nombre
        this.locacion_ref = locacion_ref
    }

}


export class Genero {

    constructor(id, nombre) {
        this.id = id
        this.nombre = nombre
    }

}


export class Empleado {

    constructor(
            id,
            nombre,
            apellidos,
            genero_ref,
            fecha_ingreso,
            fecha_nacimiento,
            numero_seguro_social,
            curp,
            codigo_empleado,
            salario_diario,
            dias_vacaciones,
            departamento_ref,
            subdepartamento_ref,
            activo,
            notas, rol_invernadero_ref) {
        this.id = id
        this.nombre = nombre
        this.apellidos = apellidos
        this.genero_ref = genero_ref
        this.fecha_ingreso = fecha_ingreso
        this.fecha_nacimiento = fecha_nacimiento
        this.numero_seguro_social = numero_seguro_social
        this.curp = curp
        this.codigo_empleado = codigo_empleado
        this.salario_diario = salario_diario
        this.dias_vacaciones = dias_vacaciones
        this.departamento_ref = departamento_ref
        this.subdepartamento_ref = subdepartamento_ref
        this.rol_invernadero_ref = rol_invernadero_ref
        this.activo = activo
        this.estatus = () => this.activo ? 'Activo' : 'Inactivo'
        this.notas = notas||[]
    }

}


export class EmpleadoInvernaderoVirtualCiclo {

    constructor(id, ciclo_ref, empleado_ref, invernadero_virtual_ref) {
        this.id = id
        this.ciclo_ref = ciclo_ref
        this.empleado_ref = empleado_ref
        this.invernadero_virtual_ref = invernadero_virtual_ref
    }

}
