import { MultiSelect } from 'primereact/multiselect'
import { SelectButton } from 'primereact/selectbutton'
import React from 'react'
import { useEffect, useGlobal, useState } from 'reactn'
import { obtenerInvernaderosVirtuales } from '../../../service/Invernaderos'
import ContenedorFiltro from '../../common/ContenedorFiltro'
import ContenedorHeader from '../../common/ContenedorHeader'
import ContenedorPrincipal from '../../common/ContenedorPrincipal'
import SeccionFiltros from '../../common/SeccionFiltros'
import { obtenerReporteCostosMaterialesSemanal, obtenerReporteCostosMaterialesSemanalConPronosticos } from '../../../service/AnalisisCostos'
import Chart from "react-apexcharts";
import { obtenerCiclo, obtenerCiclos } from '../../../service/Ciclos'
import { obtenerInicioSemanaDia, obtenerListaDeSemanas } from '../../../service/fechas'
import { dosDecimales, stringToColor } from '../../../util/functions'
import { ToggleButton } from 'primereact/togglebutton';
const tipos = [{name:"Budget",code:"budget"},{name:"Real", code:"real"}]

export default function Materiales(props) {
  const [nombreModulo, setNombreModulo] = useGlobal("nombreModulo");
  const atras = (params) => {
    props.history.goBack();
  }
    const tooltipFunction = ({series, seriesIndex, dataPointIndex,w}) => {
        let seriesCompletas = w.config.series;
        let labels =[];
        if(esMulticiclo){
          invernaderosMulticiclo.forEach(ciclo => {
            ciclo.filtro.forEach(invernadero => {
              labels.push( `<div class="p-tooltip-text">`)
              labels.push( `<div class="tooltip-title">${`${invernadero.nombre} ${ciclo["nombreCiclo"]}`}</div>`)
              labels.push( `<div class="tooltip-datas">`)
               if(tipo.includes("real")){
                 let serie = seriesCompletas.find(el=>el.name === `Real ${invernadero.nombre} ${ciclo["nombreCiclo"]}`)
                labels.push( `<div class="tooltip-data">${dosDecimales(serie.data[dataPointIndex])} <p class="tooltip-label">$/m² Real</p></div>`)
               }
               if(tipo.includes("budget")){
                let serie = seriesCompletas.find(el=>el.name === `Budget ${invernadero.nombre} ${ciclo["nombreCiclo"]}`)
                labels.push( `<div class="tooltip-data">${dosDecimales(serie.data[dataPointIndex])} <p class="tooltip-label">$/m² Budget</p></div>`)
               }
               if(tipo.includes("budget") && tipo.includes("real")){
                let serieReal = seriesCompletas.find(el=>el.name === `Real ${invernadero.nombre} ${ciclo["nombreCiclo"]}`)
                let serieBudget = seriesCompletas.find(el=>el.name === `Budget ${invernadero.nombre} ${ciclo["nombreCiclo"]}`)
                labels.push( `<div class="tooltip-data">${dosDecimales(serieReal.data[dataPointIndex]- dosDecimales(serieBudget.data[dataPointIndex]))} <p class="tooltip-label">$/m² Diferencia</p></div>`)      
               }
               labels.push( `</div></div>`)
            })
            
          })
          
        }else{
          filtroInvernaderos.forEach(invernadero=>{
            labels.push( `<div class="p-tooltip-text">`)
            labels.push( `<div class="tooltip-title">${invernadero.nombre}</div>`)
            labels.push( `<div class="tooltip-datas">`)
             if(tipo.includes("real")){
               let serie = seriesCompletas.find(el=>el.name === `Real ${invernadero.nombre} `)
              labels.push( `<div class="tooltip-data">${dosDecimales(serie.data[dataPointIndex])} <p class="tooltip-label">$/m² Real</p></div>`)
             }
             if(tipo.includes("budget")){
              let serie = seriesCompletas.find(el=>el.name === `Budget ${invernadero.nombre} `)
              labels.push( `<div class="tooltip-data">${dosDecimales(serie.data[dataPointIndex])} <p class="tooltip-label">$/m² Budget</p></div>`)
             }
             if(tipo.includes("budget") && tipo.includes("real")){
              let serieReal = seriesCompletas.find(el=>el.name === `Real ${invernadero.nombre} `)
              let serieBudget = seriesCompletas.find(el=>el.name === `Budget ${invernadero.nombre} `)
              labels.push( `<div class="tooltip-data">${dosDecimales(serieReal.data[dataPointIndex]- dosDecimales(serieBudget.data[dataPointIndex]))} <p class="tooltip-label">$/m² Diferencia</p></div>`)
      
        
             }
             labels.push( `</div></div>`)
        
          
          })
    
        }
          
        
         labels = labels.join("")
        // 
        return `<div class = "chart-label"> ${labels}</div>`
        
      }
    const optionsOrig = {
        chart: {
          type: 'line',
          zoom: {
            enabled: true
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: 'Costos de materiales',
          align: 'left'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
         xaxis: {
           categories: [],
         },
        tooltip:{
           custom:  tooltipFunction
         }
      }
    const [cicloId, setCicloId] = useGlobal("ciclo");
    const [cargando, setCargando] = useGlobal("cargando");
    const [invernaderosOrig, setInvernaderosOrig] = useState([]);
    const [invernaderos, setInvernaderos] = useState([]);
    const [filtroInvernadero, setFiltroInvernadero] = useState();
    const [productos, setProductos] = useState([]);
    const [filtroProductos, setFiltroProductos] = useState([]);
    const [tipo, setTipo] = useState([]);
    const [data, setData] = useState([]);
    const [options, setOptions] = useState({...optionsOrig})
    const [semanas, setSemanas] = useState([])
    const [seriesFiltradas, setSeriesFiltradas] = useState([])
    const [isAcumulado, setIsAcumulado] = useState(false);
    const [reporte, setReporte] = useState([])
    const [esMulticiclo, setEsMulticiclo] = useState(false)
    const [filtroCiclos, setFiltroCiclos] = useState([])
    const [ciclos, setCiclos] = useState([])
    const [invernaderosMulticiclo, setInvernaderosMulticiclo] = useState([])
    const [filtroInvernaderos, setFiltroInvernaderos] = useState([]);
    const [productosYVariedadesTodos] = useGlobal("productosYVariedadesTodos");

    useEffect(()=>{
      setNombreModulo("Costos de materiales")
    },[])
    useEffect(() => {
      
      
      actualizarInvernaderosMulticiclo();
    }, [filtroCiclos])
    const handleFiltroMulticiclo = (cicloId, value) => {
      let copia = invernaderosMulticiclo.map(el=>({...el}))
      let current = copia.find(el=>el.cicloId === cicloId);
      current.filtro= value;
      setInvernaderosMulticiclo(copia);
    }
    const actualizarInvernaderosMulticiclo =  async (params) => {
      let copia = invernaderosMulticiclo.map(el=>({...el}))
      let porAgregar = [];
      filtroCiclos.forEach(ciclo => {
        let existeCiclo = copia.find(el=>el.cicloId === ciclo.id);
        if(existeCiclo){}else{
          porAgregar.push(ciclo.id)
        }
      })
      let promises = porAgregar.map(ciclo=>{
        return obtenerInvernaderosVirtuales(ciclo);
      })
      let values = await Promise.all(promises);
      values.forEach((value,index) => {
        let idsProductos= filtroProductos.map(el=>el.id);
        let opciones = value.filter(el=>idsProductos.includes( el.producto_ref))
        let ciclo = ciclos.find(el=>el.id === porAgregar[index]);
        copia.push({
          cicloId: porAgregar[index],
          nombreCiclo: ciclo["nombre"],
          invernaderosOrig: value,
          opcionesInvernaderos: opciones,
          filtro:[]
        })
      })
      
      
      
      setInvernaderosMulticiclo(copia);
    }
    useEffect(() => {
      if(cicloId){
        actualizarReporteMonociclo();
      }
    }, [filtroInvernaderos, cicloId])
    const actualizarReporteMonociclo = async (params) => {
      setCargando(true);
      let ciclo = await obtenerCiclo(cicloId);
       let promises = filtroInvernaderos.map(el=>obtenerReporteCostosMaterialesSemanal(ciclo.id,el));
        let detalles = await Promise.all(promises);
        let series = extraerSeriesMulticiclo( detalles, filtroInvernaderos);
        
        
        
        setReporte(detalles);
        setData(series);
        setCargando(false);
    }
    useEffect(() => {
        
            let procesado = actualizarSeries()
            setData(procesado);
        
      }, [isAcumulado])
    useEffect(() => {
        if(cicloId){
            obtenerDatosIniciales()
        }
    }, [cicloId])
    // useEffect(() => {
    //     actualizarOptions();
    // }, [data])
    const obtenerDatosIniciales = async (params) => {
    
      let ciclos = await obtenerCiclos();
            
            
            setCiclos(ciclos);
        let ciclo = await obtenerCiclo(cicloId);
        let semanas =  obtenerListaDeSemanas(ciclo.semana_inicio, ciclo.semana_cierre);
        setSemanas(semanas);
        let invernaderos = await obtenerInvernaderosVirtuales(cicloId);
        setInvernaderosOrig(invernaderos);
        setProductos(productosYVariedadesTodos);
    }
    useEffect(() => {
        let idsProductos = filtroProductos.map(el=>el.id)
        let filtrados = invernaderosOrig.filter(el=>idsProductos.includes(el.producto_ref))
        setInvernaderos(filtrados);
        if(filtroInvernadero && !idsProductos.includes(filtroInvernadero.id)){
            setFiltroInvernadero()
        }
        let copia = invernaderosMulticiclo.map(el=>({...el}))
        copia.forEach(datosCiclo => {
          datosCiclo.opcionesInvernaderos = datosCiclo.invernaderosOrig.filter(el=>idsProductos.includes(el.producto_ref));
          datosCiclo.filtro =datosCiclo.filtro.filter(el=>idsProductos.includes(el.producto_ref)) 
        })
        setInvernaderosMulticiclo(copia);
    }, [filtroProductos])
    useEffect(() => {
      actualizarReportes()
     
   }, [invernaderosMulticiclo])
   const actualizarReportes = async (params) => {
    setCargando(true);
    let nuevos = []
    let invernaderos = []
    invernaderosMulticiclo.forEach(datosCiclo => {
      invernaderos = invernaderos.concat(datosCiclo.filtro);
      datosCiclo.filtro.forEach(invernadero => {
        // if(!cache.includes(invernadero.id)){
          
          nuevos.push({cicloId: datosCiclo.cicloId, invernaderoId: invernadero.id, invernadero: invernadero })
        // }
      })        
    })
    let promises = nuevos.map(el=>{
      let ciclo = ciclos.find(ciclo=>ciclo.id === el.cicloId);
      
      
      
        
      return obtenerReporteCostosMaterialesSemanal(el.cicloId,el.invernadero)
    })
    let nuevosDetalles = await Promise.all(promises);
    
    
    
    
    //let copiaReporte = reporte.map(el=>({...el}))
    //copiaReporte = copiaReporte.concat(nuevosDetalles);
    let series = extraerSeriesMulticiclo( nuevosDetalles, invernaderos);
    // let copiaCache = cache.slice();
    //copiaCache = copiaCache.concat(nuevos.map(el=>el.invernaderoId));

    setReporte(nuevosDetalles);
    setData(series);
    setCargando(false);
    //setCache(copiaCache);
  }
    useEffect(() => {
        if(filtroInvernadero){
            // actualizarOptions();
            actualizarDatos();
        }
    }, [filtroInvernadero])
    useEffect(() => {

        
        let filtradas = data.filter(el=>tipo.includes(el.tipo))
        
        
        actualizarOptions(filtradas)
        setSeriesFiltradas(filtradas);
    }, [ tipo, data])

    const actualizarOptions = (params) => {
        let copiaOptions = {...optionsOrig}  
      copiaOptions["xaxis"] = {}
      copiaOptions["xaxis"]["categories"] = semanas.map(el=>el.nombreCompacto);
      let ahora = Math.round(Date.now()/1000);
      let timeSemanaActual = obtenerInicioSemanaDia(ahora).unix();
      let semana = semanas.find(el=>el.time === timeSemanaActual);
      let colors = [];
      let width = [];
      let dashArray = [];
      data.forEach(serie => {
        
            width.push(5*(serie.index+1));
            dashArray.push(3*serie.index);
            colors.push(serie.color)
    })  
     copiaOptions["stroke"]["width"] = width;
     copiaOptions["stroke"]["dashArray"] = dashArray;
     copiaOptions.colors = colors
      //
      
      
      
      if(semana){
        copiaOptions["annotations"] = {
          xaxis: [{
            x:semana.nombreCompacto,
            strokeDashArray: 0,
            borderColor: '#775DD0',
            label: {
              borderColor: '#775DD0',
              style: {
                color: '#fff',
                background: '#775DD0',
              },
              text: 'Actual',
            }
          }]
        }

      }
      setOptions(copiaOptions);
    }
    const actualizarDatos = async (params) => {        
        try {
            setCargando(true);
            
            
            let result = await obtenerReporteCostosMaterialesSemanalConPronosticos(cicloId, filtroInvernadero);
            
            let series = extraerSeries(result);
            setReporte(result);
            setData(series);
        } catch (error) {
            console.log("ERROR ORCMS");
            console.log(error);
        }finally{
            setCargando(false);
        }
    }
    const extraerSeries = (reporte) => {        
        let budget = {name:`Budget ${filtroInvernadero.nombre}`, label:"Budget",
            type:"line", data:[], id:"budget", index:1,
            tipo:"budget",
            color: stringToColor(filtroInvernadero.color)};
        let real = {name:`Real ${filtroInvernadero.nombre}`, label:"real",
        type:"line", data:[], id:"real", index:0,
        tipo:"real",
        color: stringToColor(filtroInvernadero.color)};
        reporte.forEach(datoSemana => {
            // 
            // 
            // 
            budget.data.push(dosDecimales(datoSemana["total_budget"]));
            real.data.push(dosDecimales(datoSemana["total"]));
        })
        return [real, budget]
    }
    const extraerSeriesMulticiclo = (reporte,invernaderos) => {       
      
      
      
      let ahora = Math.round(Date.now()/1000)
      let inicioSemana = obtenerInicioSemanaDia(ahora).unix();
      let series =[];
       invernaderos.forEach((invernadero,index)=>{
        let ciclo = "";
        if(esMulticiclo){
          let cicloFound = invernaderosMulticiclo.find(ciclo=>{
            let inv = ciclo.filtro.find(el=>el.id === invernadero.id);
            if(inv){
              return true;
            }else{
              return false;
            }
          }) 
            ciclo = cicloFound?cicloFound.nombreCiclo:"";          
        }
        let reporteActual = reporte[index];
         let budget = {name:`Budget ${invernadero.nombre} ${ciclo}`, label:"Budget",
             type:"line", data:[], id:"budget", index:1,
             tipo:"budget",
             color: stringToColor(invernadero.color)};
         let real = {name:`Real ${invernadero.nombre } ${ciclo}`, label:"real",
         type:"line", data:[], id:"real", index:0,
         tipo:"real",
         color: stringToColor(invernadero.color)};
         reporteActual.forEach(datoSemana => {
           
           
          if(datoSemana["semana"]["time"]> inicioSemana){
            real.data.push(dosDecimales(datoSemana["total_budget"]));
          }else{
            real.data.push(dosDecimales(datoSemana["total"]));

          }
             budget.data.push(dosDecimales(datoSemana["total_budget"]));
         })
         series.push(real);
         series.push(budget);
      }) 
      return series;
  }
    const actualizarSeries = () => {     
      let result = []
      let ahora = Math.round(Date.now()/1000)
      let inicioSemana = obtenerInicioSemanaDia(ahora).unix();
      filtroInvernaderos.forEach((filtroInvernadero,index) => {
        let ciclo = "";
        if(esMulticiclo){
          let cicloFound = invernaderosMulticiclo.find(ciclo=>{
            let inv = ciclo.filtro.find(el=>el.id === filtroInvernadero.id);
            if(inv){
              return true;
            }else{
              return false;
            }
          }) 
            ciclo = cicloFound?cicloFound.nombreCiclo:"";          
        }
        let budget = {name:`Budget ${filtroInvernadero.nombre} ${ciclo}`, label:"Budget",
            type:"line", data:[], id:"budget", index:1,
            tipo:"budget",
            color: stringToColor(filtroInvernadero.color)};
        let real = {name:`Real ${filtroInvernadero.nombre} ${ciclo}`, label:"real",
        type:"line", data:[], id:"real", index:0,
        tipo:"real",
        color: stringToColor(filtroInvernadero.color)};
        let currentReal = 0;
        let currentBudget = 0;
        let reporteInvernadero = reporte[index];
        reporteInvernadero.forEach(datoSemana => {
          let datoReal = dosDecimales(datoSemana["total"])
          if(datoSemana["semana"]["time"]> inicioSemana){
            datoReal =dosDecimales(datoSemana["total_budget"]);
          }
          
            if(isAcumulado){
                currentReal += datoReal
                currentBudget += dosDecimales(datoSemana["total_budget"])                
              }else{
                currentReal = datoReal
                currentBudget = dosDecimales(datoSemana["total_budget"]);
              }
            budget.data.push(dosDecimales(currentBudget));
            real.data.push(dosDecimales(currentReal));
        })
        result.push(real);
        result.push(budget);         
      })
      return result;
    }
    
    return (
      <ContenedorPrincipal extra="chart-page">
            <ContenedorHeader titulo = "Costos de materiales"  iconos = {"costos-icon"}  atras = {atras}/>
            <SeccionFiltros>
              <div className="p-grid p-col-12">   
                <ContenedorFiltro label = "Producto">                    
                    <MultiSelect options={productos} value = {filtroProductos} optionLabel="nombre" dataKey="id" onChange = {e=>setFiltroProductos(e.value)} 
                      selectedItemsLabel={`${filtroProductos.length} productos seleccionados`}/>
                </ContenedorFiltro>
                <ContenedorFiltro label = "datos">
                  <SelectButton optionLabel="name" optionValue="code" value = {tipo} options = {tipos} onChange={e=>setTipo(e.value)} multiple={true} />
                </ContenedorFiltro>
                <ContenedorFiltro label = "Acumulado">
                  <ToggleButton checked = {isAcumulado} onChange= {e=>setIsAcumulado(e.value)} onLabel = "Si" offLabel="No"/>
                </ContenedorFiltro>
              </div>
              <div className="p-grid p-col-12">     
              
                <ContenedorFiltro  filtrolabel = "no"  label = "Tipo de gráfica">
                  <ToggleButton checked = {esMulticiclo} onChange= {e=>setEsMulticiclo(e.value)} onLabel= {"Multiciclo"} offLabel= {"Por ciclo actual"} />
                </ContenedorFiltro>   
                {esMulticiclo?
                  <ContenedorFiltro  label = "Ciclos a comparar" filtrolabel = "no"   >
                  <MultiSelect options = {ciclos} value = {filtroCiclos} 
                  onChange={e=>setFiltroCiclos(e.value)}  dataKey="id" optionLabel="nombre"
                  selectedItemsLabel={`${filtroCiclos.length} ciclos seleccionados`}/> 
                  </ContenedorFiltro>
                  :
                  null
                }
                
                {esMulticiclo?
                  <React.Fragment>
                    {invernaderosMulticiclo.map(el=>{
                        return <ContenedorFiltro label ={`Ciclo ${el.nombreCiclo}`}>
                      <MultiSelect value = {el.filtro} options = {el.opcionesInvernaderos} dataKey="id" optionLabel="nombre"
                        onChange={(e)=>handleFiltroMulticiclo(el.cicloId, e.value)} 
                        selectedItemsLabel={`${el.opcionesInvernaderos.length} invernaderos seleccionados`}/>
                        </ContenedorFiltro>
                    })}
                  </React.Fragment>
                
                
                :
                  <ContenedorFiltro label = "Invernaderos">
                    <MultiSelect options ={invernaderos} value = {filtroInvernaderos} optionLabel="nombre" dataKey="id" 
                      onChange = {(e)=>setFiltroInvernaderos(e.value)} 
                      selectedItemsLabel={`${filtroInvernaderos.length} invernaderos seleccionados`}/>
                  </ContenedorFiltro>
                }
              </div>  
            </SeccionFiltros>
            <div className = "p-col-12 chart-container-filters">

                <Chart series = {seriesFiltradas} options ={options} height="100%"/>
            </div>
        </ContenedorPrincipal>
    )
}
