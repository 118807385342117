import React from 'react';
import { useState, useEffect, useGlobal, setGlobal } from 'reactn';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from "primereact/inputtext";
import { Calendar } from 'primereact/calendar';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { noHaSidoClausurado } from '../../../service/Ciclos';
import ContenedorFiltro from '../../common/ContenedorFiltro'
import ContenedorFiltroBusqueda from '../../common/ContenedorFiltroBusqueda'
import SeccionFiltros from '../../common/SeccionFiltros'
import { dateToUnixTimeAsNumber13, getNombrePorID, getMomentFormatted, getNombreUsuarioPorID, getObjetoPorID } from '../../../util/functions';
import { miliSegundosDia, numeroMaximo } from '../../../constants'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { obtenerFiltrosComprasTodos, getListaEmpresasString, isAdminRequisiciones, getListaEmpresasIDs, isAdminModulo, filtrarRequisicionesDeAuxiliarPorAdmins, obtenerOrdenesComprasBridge, getListaSitiosIDs, getListaSitiosString, getListaLocsHTFString, getListalocsHTFIDs, ADMIN_ALMACEN, isAdminAlmacen } from '../../../service/Requisiciones';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import ModalDetalles from './ModalDetalles';
import ContenedorPrincipal from '../../common/ContenedorPrincipal';
import ContenedorHeader from '../../common/ContenedorHeader';
import RowBotonesTopThin from '../../common/RowBotonesTopThin';
import ContenedorBotonesTop from '../../common/ContenedorBotonesTop';
import RowBotonesTop from '../../common/RowBotonesTop';
import ContenedorTabla from '../../common/ContenedorTabla';
import { ROLES_ADMIN_Y_AUXILIAR } from '../../../util/diccionarios';

setGlobal({
    urgenciasFiltrosCompras: [], estatusesFiltrosCompras: [], urgenciasFiltrosCompras: [], requerimientosFiltrosCompras: [],
    estatusesFiltrosCompras: [], departamentosFiltrosCompras: [], empresasFiltrosCompras: [], sitiosFiltrosCompras: [],
    datosCargadosCompras: false
})
const TablaCompras = (props) => {
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [, setCargando] = useGlobal("cargando")
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [ciclo,] = useGlobal("ciclo");
    const [cicloObj] = useGlobal("cicloObj");
    const [ciclos] = useGlobal("ciclos");
    const [usuario] = useGlobal("usuario");
    const [usuarios] = useGlobal("usuarios")
    const [ordenLineas, setOrdenLineas] = useState([])
    const [ordenLineasOriginales, setOrdenLineasOriginales] = useState([])
    const [datosCargados] = useGlobal("datosCargadosCompras")
    const [locacionesHTF] = useGlobal("locacionesHTF")

    //opciones de filtros
    const [ordenesRefsOpciones] = useGlobal("ordenesRefsCompras")
    const [urgenciasOpciones] = useGlobal("urgenciasCompras")
    const [requerimientosOpciones] = useGlobal("requerimientosCompras")
    const [estatusesOpciones] = useGlobal("estatusesCompras")
    const [empresasOpciones] = useGlobal("empresas")
    const [departamentosOpciones] = useGlobal("departamentos")
    const [sitios] = useGlobal("sitios")

    //variables de filtros
    const [globalFilter, setGlobalFilter] = useGlobal("globalFilterCompras")
    const [fechaFiltroInicio, setFechaFiltroInicio] = useGlobal("fechaFiltroInicioCompras")
    const [fechaFiltroFinal, setFechaFiltroFinal] = useGlobal("fechaFiltroFinalCompras")
    const [urgenciasFiltros, setUrgenciasFiltros] = useGlobal("urgenciasFiltrosCompras")
    const [requerimientosFiltros, setRequerimientosFiltros] = useGlobal("requerimientosFiltrosCompras")
    const [estatusesFiltros, setEstatusesFiltros] = useGlobal("estatusesFiltrosCompras")
    const [departamentosFiltros, setDepartamentosFiltros] = useGlobal("departamentosFiltrosCompras")
    const [empresasFiltros, setEmpresasFiltros] = useGlobal("empresasFiltrosCompras")
    const [sitiosFiltros, setSitiosFiltros] = useGlobal("sitiosFiltrosCompras")

    const [showDetallesModal, setShowDetallesModal] = useState(false)

    useEffect(() => {
        if (usuario.rol === "agente aduanal")
            props.history.push("/")
        setNombreModulo("Requisiciones de compra")
        setHabilitadoSelectorCiclo(true)
    }, [])
    useEffect(() => {
        setCargando(true)
        if (ciclo && !datosCargados)
            obtenerFiltrosComprasTodos(usuario)
        else if (ciclo && datosCargados)
            obtenerDatosInicio()

    }, [ciclo, datosCargados])

    useEffect(() => {
        //Reconsile Filters
        let ordenLineasFiltrados = ordenLineasOriginales.filter(orden => checarCondicionesDeIncluirOrden(orden))
        setOrdenLineas(ordenLineasFiltrados)

    }, [ordenLineasOriginales, fechaFiltroInicio, fechaFiltroFinal, urgenciasFiltros, estatusesFiltros, requerimientosFiltros, empresasFiltros, departamentosFiltros, sitiosFiltros]);

    const checarCondicionesDeIncluirOrden = (orden) => {
        let selectedFechaInicio = !fechaFiltroInicio ? 1 : dateToUnixTimeAsNumber13(fechaFiltroInicio)
        let selectedFechaFinal = !fechaFiltroFinal ? numeroMaximo : (dateToUnixTimeAsNumber13(fechaFiltroFinal) + miliSegundosDia)

        return orden.fecha_creacion >= selectedFechaInicio && orden.fecha_creacion <= selectedFechaFinal
            && orden.empresaLista.some(empresa => empresasFiltros.includes(empresa))
            && urgenciasFiltros.includes(orden.allData.urgencia) && estatusesFiltros.includes(orden.allData.estatus)
            && requerimientosFiltros.includes(orden.allData.requerimiento) && departamentosFiltros.includes(orden.allData.departamento)
            && orden.sitioLista.some(sitio => {
                const tieneSitio = sitiosFiltros.includes(sitio);
                const noHaySitio = (!sitio && sitiosFiltros.includes("Sin asignar"))
                const esAgroSin = (!sitio && (orden.empresaLista.includes("NdGaet757tbBgU5o0YpE") || orden.empresaLista.includes("ghFyLI8MQcidygXbTpuG")) && sitiosFiltros.includes("SCkIi2SnCvdAw3Xm4PLi"))
                return tieneSitio || noHaySitio || esAgroSin;
            })
    }

    const obtenerDatosInicio = async () => {

        try {
            let ordenes = await obtenerOrdenesComprasBridge(cicloObj, usuario, (isAdminModulo() || isAdminAlmacen(usuario.rol)))
            ordenes = filtrarRequisicionesDeAuxiliarPorAdmins(ordenes);

            let lineasCompras = ordenes.map(orden => ({
                orden_num: orden.orden_num,
                solicitante: usuarios ? getNombreUsuarioPorID(usuarios, orden.solicitante) : usuario.displayName,
                fecha_creacion: orden.fecha_creacion,
                ordenRef: getNombrePorID(ordenesRefsOpciones, orden.orden_ref),

                empresas: getListaEmpresasString(orden.items, empresasOpciones),
                sitios: getListaSitiosString(orden.items, sitios),
                locacionesHTF: getListaLocsHTFString(orden.items, locacionesHTF),

                estatus: getNombrePorID(estatusesOpciones, orden.estatus),
                requerimiento: getNombrePorID(requerimientosOpciones, orden.requerimiento),
                fechaEstimadaEntrega: getMomentFormatted(orden.fechaEstimadaEntrega, "DD/MM/YYYY"),
                archivosAdmin: orden.archivosAdmin || [],
                empresaLista: getListaEmpresasIDs(orden),
                numero_agrosmart: orden.numero_agrosmart,
                sitioLista: getListaSitiosIDs(orden),
                locacionesHTFLista: getListalocsHTFIDs(orden),
                fechaCerrado: orden.fechaCerrado,
                mas: 55,
                allData: { ...orden },
            }))

            setOrdenLineasOriginales(lineasCompras)
        } catch (error) {
            console.log(error)
        }
        setCargando(false)
    }

    const irOrdenCompra = (event) => { props.history.push({ pathname: `/requisiciones/compras/modificar/${event.data.allData.id}`, data: event.data.allData }) };
    const fechaFormattedBody = (rowData, column) => <label >{getMomentFormatted(rowData[column.field], "DD/MM/YYYY")}</label>;
    const verDetalles = (event, rowData) => {
        event.stopPropagation()
        setShowDetallesModal(rowData.allData)
    }
    const buttonBody = (rowData) => <Button label="Ver items" onClick={(ev) => { verDetalles(ev, rowData) }} />;
    const obtenerFooter = () =>
        <ColumnGroup>
            <Row>
                <Column colSpan={4} />
                <Column className="text-align-left" footer={<span>Total: {ordenLineas?.length || "0"}<span className="label-unidad">requisiciones</span> </span>} colSpan={4} />)
                <Column colSpan={1} />
            </Row>
        </ColumnGroup>;

    const getSitiosFiltrosOpciones = () => {
        let sinAsignar = { nombre: "Sin asignar", id: "Sin asignar" }
        if (!sitios) { return [sinAsignar]; }
        let sitiosCopy = [...sitios]
        sitiosCopy[0].nombre = "Todos (Atongo)";
        return [sinAsignar, ...sitiosCopy]
    }

    return (
        <ContenedorPrincipal>
            <ContenedorHeader col="p-col p-col-auto" titulo="Requisiciones de compra" iconos="compras-icon" />

            <ContenedorBotonesTop>
                <RowBotonesTop>
                    {noHaSidoClausurado(ciclo, ciclos) && <Button label="Nueva requisición de compra" onClick={() => props.history.push('/requisiciones/compras/crear')} />}
                    {isAdminRequisiciones(usuario.rol, "Compras") && <Button className="reactbtn-icon" onClick={() => props.history.push('/requisiciones/grafica_compras')} label="Gráfica de compras">
                        <FontAwesomeIcon icon={faChartBar} />
                    </Button>}
                </RowBotonesTop>
            </ContenedorBotonesTop>

            <SeccionFiltros >
                <ContenedorFiltroBusqueda>
                    <FontAwesomeIcon icon={faSearch} />
                    <InputText type="search" value={globalFilter} onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar" />
                </ContenedorFiltroBusqueda>

                <ContenedorFiltro col="2" label="Fecha Inicio">
                    <Calendar id="fechaInicio" readOnlyInput={true} dateFormat="dd-mm-yy" //locale={ CALENDAR_LOCALE_ES_PRIME_TRADUCTION }
                        value={fechaFiltroInicio} monthNavigator={true} yearNavigator={true} yearRange="1900:2030"
                        showButtonBar={true} onClearButtonClick={() => { setFechaFiltroInicio("") }}
                        onChange={e => setFechaFiltroInicio(e.value)} />
                </ContenedorFiltro>
                <ContenedorFiltro col="2" label="Fecha Final">
                    <Calendar id="fechaFinal" readOnlyInput={true} dateFormat="dd-mm-yy" //locale={ CALENDAR_LOCALE_ES_PRIME_TRADUCTION }
                        value={fechaFiltroFinal} monthNavigator={true} yearNavigator={true} yearRange="1900:2030"
                        showButtonBar={true} onClearButtonClick={() => { setFechaFiltroFinal("") }}
                        onChange={e => setFechaFiltroFinal(e.value)} />
                </ContenedorFiltro>

                <ContenedorFiltro col="2" label="Estatus">
                    <MultiSelect id="estatusesFiltros" optionLabel="nombre" optionValue="id"
                        filter={false} selectedItemsLabel={`${estatusesFiltros.length} estatuses seleccionados`}
                        options={estatusesOpciones} value={estatusesFiltros}
                        onChange={(e) => setEstatusesFiltros(e.value)} />
                </ContenedorFiltro>
                <ContenedorFiltro col="2" label="Urgencia">
                    <MultiSelect id="urgenciasFiltros" optionLabel="nombre" optionValue="id"
                        filter={false} selectedItemsLabel={`${urgenciasFiltros.length} urgencias seleccionadas`}
                        options={urgenciasOpciones} value={urgenciasFiltros}
                        onChange={(e) => setUrgenciasFiltros(e.value)} />
                </ContenedorFiltro>
                <ContenedorFiltro col="2" label="requerimientos">
                    <MultiSelect id="requerimientosFiltros" optionLabel="nombre" optionValue="id"
                        filter={false} selectedItemsLabel={`${requerimientosFiltros.length} requerimientos seleccionados`}
                        options={requerimientosOpciones} value={requerimientosFiltros}
                        onChange={(e) => setRequerimientosFiltros(e.value)} />
                </ContenedorFiltro>
                <ContenedorFiltro col="2" label="Empresa">
                    <MultiSelect id="empresasFiltros" optionLabel="nombre" optionValue="id"
                        filter={false} selectedItemsLabel={`${empresasFiltros.length} empresas seleccionadas`}
                        options={empresasOpciones} value={empresasFiltros}
                        onChange={(e) => setEmpresasFiltros(e.value)} />
                </ContenedorFiltro>
                <ContenedorFiltro col="2" label="Departementos">
                    <MultiSelect id="departementos" optionLabel="nombre" optionValue="id"
                        filter={false} selectedItemsLabel={`${departamentosFiltros.length} departamentos seleccionados`}
                        options={departamentosOpciones} value={departamentosFiltros}
                        onChange={(e) => setDepartamentosFiltros(e.value)} />
                </ContenedorFiltro>

                <ContenedorFiltro col="2" label="Sitio">
                    <MultiSelect id="sitio" optionLabel="nombre" optionValue="id"
                        filter={false} selectedItemsLabel={`${sitiosFiltros.length} sitios seleccionados`}
                        options={getSitiosFiltrosOpciones()} value={sitiosFiltros}
                        onChange={(e) => setSitiosFiltros(e.value)} />
                </ContenedorFiltro>
            </SeccionFiltros>

            <ContenedorTabla>
                <DataTable selectionMode="single" value={ordenLineas} onRowClick={irOrdenCompra} className="table-footer-no-border__total"
                    globalFilter={globalFilter} emptyMessage="No hay resultados" footerColumnGroup={obtenerFooter()} sortable={true} >
                    <Column field="fecha_creacion" header="Fecha creación" sortable={true} body={fechaFormattedBody} />
                    <Column field="orden_num" header="REQUISICIÓN #" sortable={true} />
                    <Column field="ordenRef" header="Orden de Referencia" sortable={true} />
                    <Column field="solicitante" header="Nombre Solicitante" sortable={true} />
                    <Column field="empresas" header="Empresa(s)" sortable={true} />
                    <Column field='numero_agrosmart' header="Número Agrosmart" sortable={true} style={{display: "none"}}/>
                    <Column field="estatus" header="Estatus" sortable={true} />
                    <Column field="requerimiento" header="Requerimiento" sortable={true} />
                    <Column field="fechaEstimadaEntrega" header="Fecha estimada de entrega" sortable={true} />
                    <Column field="fechaCerrado" header="Fecha de cierre" sortable={true} body={fechaFormattedBody} />
                    <Column field="mas" header="Detalles" sortable={true} body={buttonBody} />
                </DataTable>
            </ContenedorTabla>

            <ModalDetalles hide={() => setShowDetallesModal(false)} detalles={showDetallesModal} visible={showDetallesModal}></ModalDetalles>
        </ContenedorPrincipal>
    );
}
export default TablaCompras;