import React, { useEffect, useGlobal, useState } from 'reactn';
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { obtenerNombreFecha } from "../../service/fechas";
import { obtenerDefectos } from '../../service/Defectos';
import { obtenerReporteGeneralDefectos } from '../../service/ReporteCalidad';
import { dosDecimales, getObjetoPorID, getProductoYVariedadString } from '../../util/functions';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { MultiSelect } from 'primereact/multiselect';
import ContenedorFiltro from '../common/ContenedorFiltro';
import { Button } from 'primereact/button';
import icono from '../../images/icons/external-icon.svg';

const ReporteGeneralDefectos = (props) => {
    const [, setNombreModulo] = useGlobal("calidad");
    const [defectosPresentes, setDefectosPresentes] = useState([]);
    const [comentarios, setComentarios] = useState([]);
    const [rows, setRows] = useState([]);
    const [rowsFiltrados, setRowsFiltrados] = useState([]);
    const [filtroInvernaderos, setFiltroInvernaderos] = useState([]);
    const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector();
    const [ciclo,] = useGlobal("ciclo");
    const [, setCargando] = useGlobal("cargando");
    const [defectos, setDefectos] = useState([]);
    const [filtroDefectos, setFiltroDefectos] = useState([]);
    const [, setInvernadero] = useGlobal("invernadero");
    const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
    const [productosYVariedadesTodos] = useGlobal("productosYVariedadesTodos");

    useEffect(() => {
        setNombreModulo("Calidad")
        obtenerDefectos().then((result) => { setDefectos(result); })
    }, [])

    useEffect(() => {
        if (invernaderosVirtuales) { setFiltroInvernaderos(invernaderosVirtuales.map(el => el.id)) }
    }, [invernaderosVirtuales])

    useEffect(() => {
        if (ciclo && invernaderosVirtuales && invernaderosVirtuales.length > 0 && week && defectos.length > 0) {
            setCargando(true);
            obtenerReporteGeneralDefectos(ciclo, week.time, invernaderosVirtuales, productosYVariedadesTodos).then((result) => {
                procesarReporte(result);
            }).catch((err) => {
                console.log("ERROR REPORTE DEFECTOS");
                console.log(err);
            }).finally(el => setCargando(false));
        }
    }, [week, invernaderosVirtuales, defectos])

    useEffect(() => {
        const rowsFiltrados = rows.filter(el => filtroDefectos.includes(el.id));
        setRowsFiltrados(rowsFiltrados);
    }, [filtroDefectos])

    const procesarReporte = (result) => {
        let { invernaderos, promediosInicial, promediosFinal, comentarios } = result;
        let keys = [];
        promediosInicial.forEach(proms => {
            keys.push(Object.keys(proms))
        })
        promediosFinal.forEach(proms => {
            keys.push(Object.keys(proms))
        })
        keys = keys.flat();
        let set = new Set(keys);
        keys = Array.from(set);
        let rows = [];
        keys.forEach((currKey, indexRow) => {
            // 
            let defecto = defectos.find(el => el.id === currKey);
            rows.push({
                nombre: defecto.nombre,
                id: defecto.id,
                totalMuestras: { inicial: 0, final: 0 },
                totalDefectos: { inicial: 0, final: 0 },
                acumulado: { inicial: 0, final: 0 }
            })
            invernaderos.forEach((inv, index) => {
                let regIniciales = promediosInicial[index];
                let regFinales = promediosFinal[index];
                rows[indexRow][inv.nombre] = {};
                rows[indexRow][inv.nombre]["inicial"] = 0;
                rows[indexRow][inv.nombre]["final"] = 0;

                if (regIniciales[currKey]) {
                    rows[indexRow]["totalMuestras"]["inicial"] += regIniciales[currKey]["muestras"];
                    rows[indexRow]["totalDefectos"]["inicial"] += regIniciales[currKey]["defectuosos"];
                    rows[indexRow][inv.nombre]["inicial"] = regIniciales[currKey]["cociente"];
                }
                if (regFinales[currKey]) {
                    rows[indexRow][inv.nombre]["final"] = regFinales[currKey]["cociente"];
                    rows[indexRow]["totalMuestras"]["final"] += regFinales[currKey]["muestras"];
                    rows[indexRow]["totalDefectos"]["final"] += regFinales[currKey]["defectuosos"];
                }

            })
            rows[indexRow]["acumulado"]["inicial"] = rows[indexRow]["totalMuestras"]["inicial"] == 0 ? 0 : 100 * rows[indexRow]["totalDefectos"]["inicial"] / rows[indexRow]["totalMuestras"]["inicial"];
            rows[indexRow]["acumulado"]["final"] = rows[indexRow]["totalMuestras"]["final"] == 0 ? 0 : 100 * rows[indexRow]["totalDefectos"]["final"] / rows[indexRow]["totalMuestras"]["final"];
            //if (defecto.nombre === "Blossom") { console.log(`BLOSSOM `, { DATOS: rows[indexRow], ACCUMULADO_INICIAL: rows[indexRow]["acumulado"]["inicial"] }); }
        })

        setRows(rows.sort((a, b) => {
            if (a.nombre > b.nombre) {
                return 1
            } else {
                return -1
            }
        }));
        setDefectosPresentes(keys);
        setFiltroDefectos(keys);
        setComentarios(comentarios.flat());


    }
    const irReporte = (invernadero) => {
        setInvernadero(invernadero);
        props.history.push({ pathname: "/calidad/reporte", data: { reporte: "Defectos" } });
        props.history.push("/calidad/reporte")
    }

    const templateBody = (rowData, column) => {
        if (rowData[column["field"]]) {
            return (
                <div>
                    <span className="calidad-data"><span className="calidad-value">{dosDecimales(rowData[column["field"]]["inicial"])}<span className="label-unidad">%</span> </span><span class="label-unidad">INICIAL</span></span>
                    <span className="calidad-data"><span className="calidad-value">{dosDecimales(rowData[column["field"]]["final"])}<span className="label-unidad">%</span> </span><span class="label-unidad">FINAL</span></span>
                </div>)
        }
        else { return "0 %"; }
    }
    const obtenerColumnas = (params) => {
        let columnas = [<Column field="nombre" header="" />];
        if (invernaderosVirtuales) {
            const invernaderosFiltrados = invernaderosVirtuales?.filter(inv => filtroInvernaderos.includes(inv.id)) || [];
            const invernaderosFiltradosIds = invernaderosFiltrados.map(el => el.id);
            invernaderosFiltradosIds.forEach(invernaderoId => {
                let inv = getObjetoPorID(invernaderosVirtuales, invernaderoId);
                columnas.push(<Column field={inv.nombre} header={inv.nombre} body={templateBody} />)
            })
        }

        columnas.push(<Column className="col__label" field="acumulado" header="Acumulado" body={templateBody} />)
        return columnas;
    }

    const obtenerHeader = () => {
        let columns = [<Column header="" rowSpan={1} className="col__label"></Column>];
        const invernaderosFiltrados = invernaderosVirtuales?.filter(inv => filtroInvernaderos.includes(inv.id)) || [];
        const invernaderosFiltradosIds = invernaderosFiltrados.map(el => el.id);
        invernaderosFiltradosIds.forEach(invernaderoId => {
            let invernadero = getObjetoPorID(invernaderosVirtuales, invernaderoId);
            columns.push(<Column rowSpan={1} className="col__label"
                header={
                    <Button className="invisible-button" tooltip={getProductoYVariedadString(invernadero)} onClick={() => irReporte(invernadero)}>
                        <div className={`icon-general icon-mini bor-${invernadero.color} bg-${invernadero.color}`} title={getProductoYVariedadString(invernadero)} >
                            {invernadero?.es_externo ? <img width="30" alt="icono" src={icono} /> : invernadero?.nombre}
                        </div>
                    </Button>} >
            </Column>)
        });
        columns.push(<Column className="col__label" rowSpan={1} title={"Acumulado"} header={"Acumulado"}> Acumulado</Column>)
        let headerGroup = <ColumnGroup>
            <Row>
                {columns}
            </Row>
        </ColumnGroup>;
        return headerGroup;
    }

    return (
        <div className="p-grid p-justify-center">
            <div className="p-col-12 title-container">
                <span className="back-btn" onClick={props.history.goBack}></span>
                <span className="icon-general quality-icon"></span>
                <span className="titles-group">
                    <h1 className="header-title">Reporte general de defectos </h1>
                </span>
            </div>

            <div className="p-col-12">
                <div className="p-grid filtros">
                    <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
                    <ContenedorFiltro label="Defecto">
                        <MultiSelect options={defectos.filter(el => defectosPresentes.includes(el.id))} value={filtroDefectos} onChange={(e) => setFiltroDefectos(e.value)}
                            optionLabel="nombre" optionValue="id" selectedItemsLabel={`${filtroDefectos.length} defectos seleccionados`} />
                    </ContenedorFiltro>
                    <ContenedorFiltro label="Invernadero">
                        <MultiSelect options={invernaderosVirtuales} value={filtroInvernaderos} onChange={(e) => setFiltroInvernaderos(e.value)}
                            optionLabel="nombre" optionValue="id" selectedItemsLabel={`${filtroInvernaderos.length} invernaderos seleccionados`} />
                    </ContenedorFiltro>
                </div>
            </div>

            <div className="p-col-12">
                <div className="card p-0">
                    <DataTable value={rowsFiltrados} headerColumnGroup={obtenerHeader()}>
                        {obtenerColumnas()}
                    </DataTable>
                </div>
                <p className="section-title">Comentarios/hallazgos</p>
                <div className="card">
                    <div className="p-col-12 max-6">
                        {comentarios.map(comentario => { return <div> {obtenerNombreFecha(comentario.momento)} - {comentario.comentario} </div> })}
                    </div>
                </div>
            </div>
        </div>);
}
export default ReporteGeneralDefectos;//225