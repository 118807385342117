import * as firebase from "firebase/app";
import "firebase/firestore";
import { segundosSemana } from "../constants";
import { obtenerCiclo, obtenerCicloAnterior } from "./Ciclos";
import { obtenerEmpleadosEmpaqueCiclo } from "./NominaEmpaque";
import {uploadStatistics} from './Estadisticas'

function registrarInvocaciones(count){
    uploadStatistics(count)
}

export const obtenerEmpaques = async ()=>{
    const db = firebase.firestore();
    var empaques = []
    const firestore = firebase.firestore()
    await firestore.collection('empaques').orderBy('nombre', 'asc').get().then((snapshot) => {
        registrarInvocaciones(snapshot.size)
        snapshot.forEach(document => {
            let empaque = {...document.data(), id:document.id}
            empaques.push(empaque)
        });
    })
    return empaques
}
export const obtenerEmpaquesCiclo = async (ciclo) => {
    try {
        const db = firebase.firestore();
        let empaquesBaseSnap = await db.collection("empaques").get();
        registrarInvocaciones(empaquesBaseSnap.size)
        let empaquesCicloSnap = await db.collection("ciclos").doc(ciclo).collection("empaques").get();
        registrarInvocaciones(empaquesCicloSnap.size)
        // 
        // 
        let empaquesBase = empaquesBaseSnap.docs.map(el=>({id:el.id, ...el.data()}));
        
        let empaquesCiclo = empaquesCicloSnap.docs.map(el=>({id:el.id, ...el.data()}));
        empaquesCiclo.forEach(empaque => {
            let empaqueBase = empaquesBase.find(el=>el.id === empaque.empaque_ref);
            empaque.nombre = empaqueBase.nombre;
        })
        return empaquesCiclo;
    } catch (error) {
        throw error;
    }
}
export const obtenerRolesEmpaque = async (params) => {
    const db = firebase.firestore();
    let snap = await db.collection("roles_empaque").get();
    registrarInvocaciones(snap.size)
    let roles = snap.docs.map(el=>({id:el.id, ...el.data()}))
    return roles;
}
export const obtenerRolesInvernadero = async (params) => {
    const db = firebase.firestore();
    let snap = await db.collection("roles_invernadero").orderBy("nombre","asc").get();
    registrarInvocaciones(snap.size)
    let roles = snap.docs.map(el=>({id:el.id, ...el.data()}))
    return roles;
}
export const registrarEmpaque = async (cicloId, empaque, empaquesPorTransferir) => {
    try {
        const db = firebase.firestore();
        empaque.habilitado = true;
        let result =  await db.collection("ciclos").doc(cicloId).collection("empaques").add(empaque);      
        let transferencias = await realizarTransferencias(result.id, cicloId,empaquesPorTransferir)
    return transferencias  
        return result;
    } catch (error) {
        throw error;
    }

}
const realizarTransferencias = async (empaqueId,cicloEmpaque, empaquesPorTransferir ) => {
    const cicloObj = await obtenerCiclo(cicloEmpaque);
 //obtener ciclo pasado
    
    
    
    
    let cicloPorTransferirId = await obtenerCicloAnterior(cicloObj);
    
    
 //por cada invernadero por transferir
    let promises = empaquesPorTransferir.map(empaquePorTransferir=>transferir(cicloEmpaque,empaqueId, cicloPorTransferirId, empaquePorTransferir ))
    //obtener empleados
    let transferidos = await Promise.all(promises);
    let result = empaquesPorTransferir.map((el,index)=>({
        nombre:el.nombre, cantidad:transferidos[index]
    }))
    
        
    let referenciasTransferencias = empaquesPorTransferir.map(porTransferirId=>{
        return {cicloAnterior: cicloPorTransferirId,origen: porTransferirId.id, destino: empaqueId}
    })
    
    
    // crear referencia para nuevo invernadero, empleado
    await actualizarReferenciasTransferencias(cicloEmpaque,referenciasTransferencias)
    return result;
//registrar transferencias

}
const transferir = async (cicloEmpaque, empaqueId, cicloPorTransferirId, empaquePorTransferir) => {
    
    
    
    
    
    
    let empleados =await  obtenerEmpleadosEmpaqueCiclo(cicloPorTransferirId, empaquePorTransferir.empaque_ref );
    
    
    let promises = empleados.map(empleado=>{
        agregarReferenciaEmpleadoEmpaque(cicloEmpaque, empaqueId, empleado);
    })
    
    let result = await Promise.all(promises);
    
    
    return empleados.length
    
}
const agregarReferenciaEmpleadoEmpaque = async (cicloId, empaqueId, empleado) => {
    
    
    
    
    let empleadoRef = {
        empleado_ref:empleado.empleado_ref, 
        rol_ref: empleado.rol_ref,
        codigo_empacadora: empleado.codigo_empacadora
    }
    const db = firebase.firestore();
    /// hacer adaptacion:
    let nuevaReferencia = await db.collection("ciclos").doc(cicloId).collection("empaques").doc(empaqueId)
    .collection("empleados")
    .add(empleadoRef)
    return nuevaReferencia
}
const actualizarReferenciasTransferencias = async (cicloEmpaque, referenciasTransferencias) => {
    const db = firebase.firestore();
    let transfSnap = await db.collection("ciclos").doc(cicloEmpaque).collection("transferencias_empaques").get();
    registrarInvocaciones(transfSnap.size)
    if(transfSnap.empty){
        return db.collection("ciclos").doc(cicloEmpaque).collection("transferencias_empaques").add({transferencias:referenciasTransferencias})
    }else{
        
        
        let doc = transfSnap.docs[0];
        
        let data = doc.data();

        
        
        
        
        let transf =  data.transferencias;
        
        
             
           
        transf = transf.concat(referenciasTransferencias);
        
        
        return db.doc(doc.ref.path).update({
            transferencias:transf})
    }    
}
export const actualizarEmpaque = async (cicloId,docId, empaque) => {
    try {
        
        const db = firebase.firestore();
        // 
        // 
        // 
        // 
        let result =  await db.collection("ciclos").doc(cicloId).collection("empaques").doc(docId).update(empaque);
        return result;
    } catch (error) {
        throw error;
    }

}
export const updateHabilitacion = async (cicloId,empaqueId, expected) => {
    // 
    // 
    // 
    // 
    try {
        const db = firebase.firestore();
        let result =  await db.collection("ciclos").doc(cicloId).collection("empaques").doc(empaqueId).update({habilitado:expected});        
        return result;
    } catch (error) {
        
    }
}
export const guardarCargaMaquinas = async (cicloId, empaqueId,productoId,semana, datos) => {
    try {
        let empaqueVirtual = await obtenerEmpaqueCiclo(cicloId, empaqueId);
        const db = firebase.firestore();
        let result = await db.collection("ciclos").doc(cicloId).collection("empaques").doc(empaqueVirtual.id).collection("carga_maquinas").add({
            semana:semana,
            datos:datos,
            producto_ref: productoId
        })
    return result    
    } catch (error) {
        throw error;    
    }
}
export const actualizarCargaMaquinas = async (cicloId, empaqueId,editId, datos) => {
    try {
        let empaqueVirtual = await obtenerEmpaqueCiclo(cicloId, empaqueId);
        const db = firebase.firestore();
        let result = await db.collection("ciclos").doc(cicloId).collection("empaques").doc(empaqueVirtual.id).collection("carga_maquinas").doc(editId).update({
            datos:datos,
        })
    return result    
    } catch (error) {
        throw error;    
    }
}

export const obtenerEmpaqueCiclo = async (cicloId, empaqueId) => {
    let response = await firebase.firestore().collection("ciclos").doc(cicloId).collection("empaques").where("empaque_ref","==", empaqueId).get();
    registrarInvocaciones(response.size)
    const empaqueDoc = response.docs?.[0]
    return empaqueDoc ? {id: empaqueDoc.id, ...empaqueDoc.data()} : null;
}
export const obtenerEmpaqueCicloIdPropio = async (cicloId, empaqueId) => {
    const db = firebase.firestore();
    let snapEmpaqueVirtual = await db.collection("ciclos").doc(cicloId).collection("empaques").doc(empaqueId).get();
    registrarInvocaciones(1)
    let empaqueVirtualDoc = snapEmpaqueVirtual;
    let empaqueVirtual = {id: empaqueVirtualDoc.id, ...empaqueVirtualDoc.data()}
    return empaqueVirtual
}
export const obtenerRegistroMaquinasEmpaque = async (cicloId, semana, empaqueId, productoId) => {
    let empaqueVirtual = await obtenerEmpaqueCiclo(cicloId, empaqueId);
    const db = firebase.firestore();
    let snap = await db.collection("ciclos").doc(cicloId).collection("empaques").doc(empaqueVirtual.id).collection("carga_maquinas").where("semana","==", semana).
        where("producto_ref","==", productoId).get();
    registrarInvocaciones(snap.size)
    if(snap.empty){
        return {empty:true}
    }else{
        let doc = snap.docs[0];

        return {empty:false, id:doc.id, registro:doc.data().datos};
    }
}

export const obtenerNumeroEmpleadosEmpaque = async(cicloId, empaqueId) => {
    const db = firebase.firestore();
    let snap = await db.collection("ciclos").doc(cicloId).collection("empaques").doc(empaqueId).collection("empleados").get();
    registrarInvocaciones(snap.size)
    return snap.size
}

export const obtenerEmpaque = async (empaqueId) => {
    const db = firebase.firestore();
    let snap = await db.collection("empaques").doc(empaqueId).get();
    registrarInvocaciones(1)
    return {id: snap.id, ...snap.data()};
}
export const obtenerEmpaquesDisponiblesTransferencia = async (cicloId) => {
    
    try {
        const cicloActual = await obtenerCiclo(cicloId);
        
        let cicloAnteriorId = await obtenerCicloAnterior(cicloActual);
        
    //obtener invernaderos de ciclo anterior
    if(cicloAnteriorId){
        let empaques = await obtenerEmpaquesCiclo(cicloAnteriorId);
        let empaquesTransferidos = await obtenerEmpaquesTransferidos(cicloId);
        let disponibles = empaques.filter(el=>!empaquesTransferidos.includes(el.id));
        return disponibles
    }else{
        return [];
    }        
    } catch (error) {
        console.log("ERROR OBTENER DISPONIBLES");
        console.log(error);
    }
}
const obtenerEmpaquesTransferidos = async (cicloId) => {
    
    
    
    const db = firebase.firestore();

    let transfSnap = await db.collection("ciclos").doc(cicloId).collection("transferencias_empaques").get();
    registrarInvocaciones(transfSnap.size)
    if(transfSnap.empty){
        return [];
    }else{
        

        let doc = transfSnap.docs[0];
        
        let data = doc.data();
        
        return data.transferencias.map(el=>el.origen);
    }    
}

export const obtenerRegistrosEmpaqueVariedadMezcla = async (cicloId,invernaderoId, variedadId, semanaTime  ) => {
    const db = firebase.firestore();
    const snap = await db.collection("ciclos").doc(cicloId).collection("invernaderos_virtuales").doc(invernaderoId)
        .collection("empaques").where("dia",">=",semanaTime).where("dia","<", semanaTime + segundosSemana)
        .get();
    registrarInvocaciones(snap.size)
    let docs = snap.docs.map(el=>({id:el.id, ...el.data()}))

    docs.forEach(empaque => {    
        empaque.pallets = empaque.pallets.filter(el=>el.variedad?.id === variedadId)
    })
    return docs;
    
}
