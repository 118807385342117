import React, { useEffect, useState, useGlobal } from "reactn";
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { obtenerProductosDeAplicacion } from '../../service/biocontrol';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { noHaSidoClausurado } from '../../service/Ciclos';
import ContenedorFiltroBusqueda from '../common/ContenedorFiltroBusqueda'
import SeccionFiltros from '../common/SeccionFiltros'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { getMomentFormatted } from "../../util/functions";
import ContenedorHeader from "../common/ContenedorHeader";

const TablaProductosAplicacion = (props) => {
    const [, setCargando] = useGlobal("cargando")
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [usuario] = useGlobal("usuario");
    const [ciclo] = useGlobal("ciclo")
    const [ciclos] = useGlobal("ciclos")
    const [catalogoProductos, setCatalogoProductos] = useState([])
    const [globalFilter, setGlobalFilter] = useState("")
    const [enfermedades] = useGlobal("enfermedadesYPlagas");

    const puedeEditar = () => noHaSidoClausurado(ciclo, ciclos) && ["Administrador", "Grower"].includes(usuario.rol)
    const puedeConsultar = () => (noHaSidoClausurado(ciclo, ciclos) && ["Administrador", "Grower", "Administrador Biocontrol", "Auxiliar Biocontrol", "Auxiliar Biocontrol", "Auxiliar Grower"].includes(usuario.rol))

    useEffect(() => {
        setNombreModulo("Biocontrol")
        setHabilitadoSelectorCiclo(false);
        obtenerProductos()
    }, [])
    const obtenerProductos = async () => {
        setCargando(true)
        let productos = await obtenerProductosDeAplicacion()
        let procesado = productos.map(el => ({ ...el, numeroEnfermedades: el.enfermedades.length }))
        setCatalogoProductos(procesado)
        setCargando(false)
    }

    const irProducto = (event) => { if (puedeConsultar()) { props.history.push(`/biocontrol/productos/modificar/${event.data.id}`) } }
    const fechaFormattedBody = (rowData) => <label>{getMomentFormatted(rowData.fecha_cracion || 1640313437001, "DD/MM/YYYY")}</label>
    const numeroEnfermedadesBody = (rowData) => <label>{rowData.enfermedades.length + (rowData.enfermedades.length === enfermedades?.length ? " (Todos)" : "")}</label>

    return (
        <div className="p-grid p-justify-center">
            <ContenedorHeader col="p-col p-col-auto" titulo={"Productos de aplicación"} subtituloInv={"Plagicidas"} iconos={"icon-general bio-icon"} atras={props.history.goBack} />

            <div className="p-col p-col-auto">
                {puedeEditar() && <div className="btn-row">
                    <Button className="reactbtn-icon" label="Crear nuevo producto" onClick={() => props.history.push("/biocontrol/productos/crear")} />
                </div>}
            </div>

            <SeccionFiltros >
                <ContenedorFiltroBusqueda>
                    <FontAwesomeIcon icon={faSearch} />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar" />
                </ContenedorFiltroBusqueda>
            </SeccionFiltros>

            <div className="p-col p-col-auto">
                <div className="card p-0">
                    <DataTable selectionMode="single" value={catalogoProductos || []} className="" onRowClick={irProducto}
                        globalFilter={globalFilter} emptyMessage="No hay resultados" sortable={true}>
                        <Column field="nombre" header="# Orden" sortable={true} />
                        <Column field="fecha_creacion" header="Fecha aplicación" sortable={true} body={fechaFormattedBody} />
                        <Column field="numeroEnfermedades" header="# de enfermedades" sortable={true} body={numeroEnfermedadesBody} />
                        <Column field="i_a" header="i.a." sortable={true} />
                        <Column field="i_s" header="i.s." sortable={true} />
                        <Column field="rsco_epa" header="rsco_epa" sortable={true} />
                        <Column field="t_r" header="Tiempo Reentrada" sortable={true} />
                    </DataTable>
                </div>
            </div>
        </div>
    );
}
export default TablaProductosAplicacion;