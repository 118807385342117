import React, { useEffect, useState } from 'react';
import { useGlobal, setGlobal } from 'reactn';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Message } from 'primereact/message';
import ContenedorInput from '../../common/ContenedorInput'
import PreviewModal from '../../common/PreviewModal'
import ArchivoPreview from '../../common/ArchivoPreview'
import { notificarOrdenCompraCreada } from '../../../service/notificaciones';
import { noHaSidoClausurado } from '../../../service/Ciclos';
import {
    obtenerOrdenCompra, obtenerFiltrosComprasTodos, guardarDatosRequisicion, isAdminRequisiciones, locacionIsAgropark,
    locacionIsAtongo, empresaIsHTF, subirProveedor, ordenEsperaEntrega, ordenCerrado, ordenCerradoParcial, obtenerOrdenCompraAciclico, guardarRequisicionAciclico, obtenerOrdenComprasBridge, isAdminAlmacen,
} from '../../../service/Requisiciones';
import { Dialog } from "primereact/dialog";
import { monedas, miliSegundosDia } from '../../../constants'
import CambiarEstatusModal from './CambiarEstatusModal'
import { dateToUnixTimeAsNumber13, getCalendarDate, DeepClone, getNombrePorID, getArchivoTipo, getNombreUsuarioPorID } from '../../../util/functions'

import { Button } from 'primereact/button';
import * as firebase from "firebase/app";
import "firebase/auth";

import moment from "moment";
import 'moment/locale/es';
import { Calendar } from 'primereact/calendar';
import { getFirestoreID } from 'service/Mantenimiento';
import { log } from '../../../model/Logging';
moment.locale("es");

const archivoInicial = { URL: "", tipo: "" }
const itemInicial = { solicitud: "", cantidad: "", unidades: "", detalle: "", empresa: "", locacionHTF: "", locacion: "", sitio: "", archivo: { ...archivoInicial }, precio: "", fechaEntrega: "" }
const inicial = {
    orden_num: "",
    numero_agrosmart: "",
    fecha_creacion: "",
    fechaPreferidaEntrega: "",
    urgencia: "",
    solicitante: "",
    departamento: "",
    orden_ref: "",
    requerimiento: "",
    hasSentEmailEntrega: false,

    items: [{ ...itemInicial }],
    archivosAdmin: [],
    comentarioAdmin: "",

    fechaEstimadaEntregaAnterior: "",
    fechaEstimadaEntrega: "",
    fechaEntregaMaterial: "",
    fechaEntregaParcial: "",
    precioFinal: "",
    moneda: "",
    proveedor: "",
    comentarioCerrado: "",
    listaItemsParcial: [],

    estatus: "",
    fechaCerrado: ""
}
setGlobal({ estatusesCompras: [], locacionesCompras: [], locacionesAtongoCompras: [], proveedoresCompras: [], empresas: [], datosCargadosCompras: false })
const FormularioCompra = (props) => {
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [ciclo] = useGlobal("ciclo");
    const [cicloObj] = useGlobal("cicloObj");
    const [ciclos] = useGlobal("ciclos");
    const [, setCargando] = useGlobal("cargando")
    const [editing] = useState(props.match.params.requisicionID ? true : false)
    const [requisicionID] = useState(props.match.params.requisicionID || getFirestoreID())
    const [usuario] = useGlobal("usuario");
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [previewDialogURL, setPreviewDialogURL] = useState("")
    const [cambiarEstatusModalOpen, setCambiarEstatusModalOpen] = useState(false)
    const [datosCargados,] = useGlobal("datosCargadosCompras")

    //opciones de filtros
    const [ordenesRefs] = useGlobal("ordenesRefsCompras")
    const [urgencias] = useGlobal("urgenciasCompras")
    const [requerimientos] = useGlobal("requerimientosCompras")
    const [estatuses] = useGlobal("estatusesCompras")
    const [locaciones] = useGlobal("locacionesCompras")
    const [locacionesAtongo] = useGlobal("locacionesAtongoCompras")
    const [unidades] = useGlobal("unidadesCompras")
    const [proveedores, setProveedores] = useGlobal("proveedoresCompras")
    const [locacionesHTF] = useGlobal("locacionesHTF")
    const [empresas] = useGlobal("empresas")
    const [departamentos] = useGlobal("departamentos")
    const [usuarios] = useGlobal("usuarios")
    const [invernaderosFisicos] = useGlobal("invernaderosFisicos")
    const [sitios] = useGlobal("sitios")

    const [otroProveedor, setOtroProveedor] = useState("")
    const [nombreSolicitante, setNombreSolicitante] = useState("")
    const [departamentoSolicitante, setDepartamentoSolicitante] = useState("")
    const [newFechaModalOpen, setNewFechaModalOpen] = useState(false)
    const [fechaEstimadaEntregaNuevo, setFechaEstimadaEntregaNuevo] = useState("")
    const [saving, setSaving] = useState(false)
    const [errores, setErrores] = useState([])
    const [state, setState] = useState(DeepClone(inicial))
    const [, setModal] = useGlobal("modal");

    useEffect(() => {
        if (usuario.rol === "agente aduanal")
            props.history.push("/")
        setNombreModulo("Requisiciones de compra")
        setHabilitadoSelectorCiclo(true)
    }, [props.history, setHabilitadoSelectorCiclo, setNombreModulo, usuario.rol])
    useEffect(() => {
        if (!noHaSidoClausurado(ciclo, ciclos) && !editing)
            props.history.replace("/requisiciones/compras")
    }, [ciclo, ciclos, editing, props.history])
    useEffect(() => {
        setCargando(true)

        if (invernaderosFisicos && !datosCargados)
            obtenerFiltrosComprasTodos(usuario)
        else if (datosCargados)
            obtenerDatosInicio()
    }, [invernaderosFisicos, datosCargados, setCargando, usuario, obtenerDatosInicio])
    useEffect(() => {
        setSaving(false)
        setErrores([])
    }, [state])
    useEffect(() => {
        setOtroProveedor("")
    }, [state.proveedor])

    const puedeEditar = () => editing ? isAdminRequisiciones(usuario.rol, "Compras") && noHaSidoClausurado(ciclo, ciclos) : !["Agente"].includes(usuario?.rol);
    const puedeEditarEstatus = () => editing ? (isAdminRequisiciones(usuario.rol, "Compras") || isAdminAlmacen(usuario.rol)) && noHaSidoClausurado(ciclo, ciclos) : false

    const obtenerDatosInicio = async () => {
        if (editing) {
            try {
                var orden = props.location.data || await obtenerOrdenComprasBridge(cicloObj, requisicionID)
                delete orden.id
                const nombreSol = usuarios ? getNombreUsuarioPorID(usuarios, orden.solicitante) : usuario.displayName
                setNombreSolicitante(nombreSol)
                setDepartamentoSolicitante(getNombrePorID(departamentos, orden.departamento))
                setState({ ...state, ...orden })

                let proveedorFound = proveedores.find(el => el.id === orden.proveedor)
                if (orden.proveedor && !proveedorFound)
                    setProveedores([...proveedores, { nombre: orden.proveedor, id: orden.proveedor }])

                setNewFechaModalOpen(puedeEditarEstatus() && ordenEsperaEntrega(estatuses, orden.estatus) && orden.hasSentEmailEntrega && Date.now() > (orden.fechaEstimadaEntrega + miliSegundosDia) && orden.fechaEstimadaEntregaAnterior)
            } catch (error) {
                console.log("ERROR obtener compra", error)
                props.history.push('/requisiciones/compras')
            }
        }
        else {
            setState({ ...state, estatus: estatuses[0].id })//Set to initial state "Pendiente de autorizar"
        }
        setCargando(false)
    }

    const guardarOrden = async () => {
        setSaving(true)

        if (validar()) {
            try {
                setCargando(true)

                let itemsCopy = await subirArchivosItems()
                let archivosAdminCopy = await subirArchivosAdmin()

                let ordenes_compra = await firebase.firestore().collection('requisiciones').doc("compras").collection("requisiciones_compra").orderBy("orden_num", "desc").limit(1).get()
                const ordenMasReciente = ordenes_compra?.docs?.[0]?.data();
                if (isNaN(ordenMasReciente?.orden_num) || ordenMasReciente?.orden_num == null) { throw new Error() }
                log({ data: { mensaje: "1. logging ordenes_compra" || "", ordenMasReciente, funcion: "guardarOrden", ciclo, usuario } })
                
                let data = { ...state, archivosAdmin: archivosAdminCopy, items: itemsCopy }
                if (editing && otroProveedor)
                    data.proveedor = await subirOtroProveedor()
                else if (!editing) {
                    data.orden_num = ordenMasReciente.orden_num + 1
                    log({ data: { mensaje: "2. logging ordenes_compra" || "", ordenMasReciente, funcion: "guardarOrden", ciclo, usuario } })
                    data.fecha_creacion = Date.now()
                    data.solicitante = usuario.uid
                    data.departamento = usuario.departamento
                }

                //Guardar datos de requisición
                await guardarRequisicionAciclico("compra", requisicionID, data)
                if (!editing)
                    await notificarOrdenCompraCreada({ orden_num: data.orden_num, usuario: usuario.displayName, departamento: getNombrePorID(departamentos, data.departamento), urgencia: getNombrePorID(urgencias, data.urgencia) });

                setCargando(false)
                props.history.goBack()
            }
            catch (error) {
                console.log("ERROR guardar: ", error);
                log({ data: { mensaje: "3. logging ordenes_compra" || "", error, funcion: "guardarOrden", ciclo, usuario } })
                setModal({ titulo: "Error de registro de datos", msj: "Hubo un problema al guardar su requisición, intentelo nuevamente." });
                setCargando(false);
            }
        }
    }

    const validar = () => getErroresFormulario().length === 0
    const getErroresFormulario = () => {
        let camposRequeridosLista = ["urgencia", "fechaPreferidaEntrega", "orden_ref", "requerimiento"]
        let camposRequeridosItemsLista = ["solicitud", "cantidad", "unidades", "detalle", "empresa", "locacion"]
        let errores = []

        camposRequeridosLista.forEach(campo => {
            if (!state[campo])
                errores.push(campo)
        })
        state.items.forEach((item, index) => {
            camposRequeridosItemsLista.forEach(campo => {
                if (!item[campo])
                    errores.push(`${campo}${index}`)
            })

            if (empresaIsHTF(empresas, item.empresa) && !item.locacionHTF)
                errores.push(`locacionHTF${index}`);
            if (empresaIsHTF(empresas, item.empresa) && item.locacionHTF && !item.sitio)
                errores.push(`sitio${index}`)
        })

        if (state.proveedor === "Otro" && !otroProveedor)
            errores.push("otroProveedor")
        if (ordenCerradoParcial(estatuses, state.estatus) && !state.fechaEntregaParcial)
            errores.push("fechaEntregaParcial")

        setErrores(errores)
        return errores
    }

    const subirOtroProveedor = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                let newProveedor = await subirProveedor(otroProveedor).catch(err => reject(err))
                setProveedores([...proveedores, { nombre: otroProveedor, id: newProveedor.id }])
                handleInputChange({ target: { name: "proveedor", value: otroProveedor } })
                setOtroProveedor("")
                resolve(newProveedor.id)
            } catch (err) {
                reject(err)
            }
        })
    }

    const subirArchivosItems = async () => {
        let itemsCopy = DeepClone(state.items)

        for (let i = 0; i < itemsCopy.length; i++) {
            let archivoCopy = itemsCopy[i].archivo

            if (archivoCopy.archivo) {
                let archivoNombre = `item${i + 1}_${Date.now()}_${archivoCopy.archivo.name}`
                let storagePath = `ordenes_compra/${requisicionID}/${archivoNombre}`
                const ref = firebase.storage().ref().child(storagePath)
                let fileSnapshot = await ref.put(archivoCopy.archivo)
                let url = await fileSnapshot.ref.getDownloadURL()

                itemsCopy[i].archivo = { URL: url, tipo: archivoCopy.tipo }
            }
        }
        return itemsCopy
    }

    const subirArchivosAdmin = async () => {
        let archivosAdminCopy = DeepClone(state.archivosAdmin)

        try {

            for (let x = 0; x < archivosAdminCopy.length; x++) {
                let url = archivosAdminCopy[x].URL || ""
                if (archivosAdminCopy[x].archivo) {
                    let storagePath = `ordenes_compra/${Date.now()}_${archivosAdminCopy[x].archivo.name}`
                    const ref = firebase.storage().ref().child(storagePath)
                    let fileSnapshot = await ref.put(archivosAdminCopy[x].archivo)
                    url = await fileSnapshot.ref.getDownloadURL()
                }
                archivosAdminCopy[x] = { URL: url, tipo: archivosAdminCopy[x].tipo }
            }
        } catch (error) {
            console.log("Error subiendo archivo admin: " + error)
        }
        return archivosAdminCopy
    }

    const getItemLocacionOpciones = (empresa, locacionHTF, sitio) => {
        let opciones = locaciones.map(el => ({ ...el }))

        if (invernaderosFisicos && empresaIsHTF(empresas, empresa)) {
            if (locacionIsAtongo(locacionesHTF, locacionHTF)) { 
                const locacionesAtongoFiltrados = locacionesAtongo.filter(loc => loc.sitio === sitio || (sitio === "Todos" && loc.todos))
                opciones.push(...locacionesAtongoFiltrados.map(el => ({ ...el }))) 
            }
            
            opciones.push(...invernaderosFisicos.filter(inv => inv.empaque_ref === sitio && inv.empresa_ref === empresa))
        }
        else if (invernaderosFisicos)
            opciones.push(...invernaderosFisicos.filter(inv => inv.empresa_ref === empresa))

        return opciones
    }
    const handleInputChange = (event, index = -1) => {
        let stateCopy = DeepClone(state)
        let name = event.target.name

        if (index >= 0) {
            let locacionesIDs = locaciones.map(el => el.id)
            stateCopy.items[index][name] = event.target.value

            if (name === "empresa" && !locacionesIDs.includes(stateCopy.items[index].locacion)) {
                stateCopy.items[index].locacion = "";
                stateCopy.items[index].sitio = "";
            }
            if (name === "empresa" && !empresaIsHTF(empresas, event.target.value)) {
                stateCopy.items[index].locacionHTF = "";
                stateCopy.items[index].sitio = getSitioAgropark().id
            }
            if (name === "locacionHTF") {
                stateCopy.items[index].sitio = locacionIsAgropark(locacionesHTF, event.target.value) ? getSitioAgropark().id : ""
            }

            if (name === "numero_agrosmart") {
                stateCopy.numero_agrosmart = event.target.value
            }
        }
        else
            stateCopy[name] = event.target.value

        setState(stateCopy)
    }

    const handleCalendarInputChange = (event, index) => {
        handleInputChange({ target: { name: event.target.name, value: dateToUnixTimeAsNumber13(event.target.value) } }, index)
    }

    const deleteArchivoAdmin = (index, e) => {
        //Para bloquear que el parent hace click (vista previa)
        e.stopPropagation();

        let archivosAdminCopy = DeepClone(state.archivosAdmin)
        archivosAdminCopy.splice(index, 1)
        handleInputChange({ target: { name: "archivosAdmin", value: archivosAdminCopy } })
    }
    const deleteArchivoItem = (e, index) => {
        //Para bloquear que el parent hace click (vista previa)
        e.stopPropagation();
        handleInputChange({ target: { name: "archivo", value: { ...archivoInicial } } }, index)
    }

    const handleArchivosAdminChange = (files) => {
        let archivosAdminCopy = state.archivosAdmin.length === 0 ? [] : DeepClone(state.archivosAdmin)

        for (let x = 0; x < files.length; x++) {
            let newArchivo = {}
            let url = URL.createObjectURL(files[x])
            newArchivo.URL = url
            newArchivo.archivo = files[x]
            newArchivo.tipo = getArchivoTipo(files[x])
            archivosAdminCopy.push(newArchivo)
        }
        handleInputChange({ target: { name: "archivosAdmin", value: archivosAdminCopy } })
    }

    const handleItemArchivoChange = (event, index) => {
        if (event.target.files[0]) {
            let localURL = URL.createObjectURL(event.target.files[0])
            let tipoArchivo = getArchivoTipo(event.target.files[0])
            handleInputChange({ target: { name: event.target.name, value: { URL: localURL, archivo: event.target.files[0], tipo: tipoArchivo } } }, index)
        }
    }

    const cambiarFechaEntrega = async () => {
        setSaving(true)
        setCargando(true)
        setNewFechaModalOpen(false)
        if (fechaEstimadaEntregaNuevo) {
            await guardarRequisicionAciclico("compra", requisicionID, { fechaEstimadaEntrega: fechaEstimadaEntregaNuevo, hasSentEmailEntrega: false })
            const copy = DeepClone(state)
            copy.hasSentEmailEntrega = false
            setState(copy)
            setNewFechaModalOpen(false)
            setSaving(false)
        }
        setCargando(false)
    }
    const NuevoFechaEntregaModal = () => {
        return <Dialog visible={newFechaModalOpen} header={"La fecha estimada ya pasó, ¿te gustaría seleccionar otra?"} style={{ width: '50vw' }} onHide={() => setNewFechaModalOpen(false)} footer={<div>
            <Button label="Cancelar" onClick={() => setNewFechaModalOpen(false)} />
            <Button label="Guardar" onClick={() => cambiarFechaEntrega()} /></div>}>
            <div className="p-grid ">
                <div className="form-group p-col-12">
                    <label htmlFor="fechaEstimadaEntregaAnterior">Fecha anterior de entrega</label>
                    <Calendar disabled={!puedeEditar()} id="fechaEstimadaEntregaAnterior" name="fechaEstimadaEntregaAnterior" value={getCalendarDate(state.fechaEstimadaEntregaAnterior)} dateFormat="dd/mm/yy" />

                    <label htmlFor="fechaEstimadaEntregaNuevo" style={{ marginTop: 24 }}>Nueva fecha Entrega Material</label>
                    <Calendar disabled={!puedeEditar()} id="fechaEstimadaEntregaNuevo" name="fechaEstimadaEntregaNuevo" value={getCalendarDate(fechaEstimadaEntregaNuevo)} onChange={(event) => setFechaEstimadaEntregaNuevo(dateToUnixTimeAsNumber13(event.target.value))} dateFormat="dd/mm/yy" />
                    {(saving && !fechaEstimadaEntregaNuevo) && <Message severity="error" text="Este campo es requerido" />}
                </div>
            </div>
        </Dialog >
    }

    const agregarItem = () => {
        let stateCopy = DeepClone(state)
        let item = { ...itemInicial }
        let previousItem = stateCopy.items[stateCopy.items.length - 1]

        item.empresa = previousItem.empresa
        item.locacion = previousItem.locacion
        item.locacionHTF = previousItem.locacionHTF
        item.sitio = previousItem.sitio

        stateCopy.items.push(item)
        setState(stateCopy)
    }
    const eliminarItem = (index) => {
        let stateCopy = DeepClone(state)
        state.items = stateCopy.items.splice(index, 1)

        setState(stateCopy)
    }
    const verArchivoFuncion = (archivo) => { archivo.tipo === "pdf" || archivo.tipo === "excel" ? window.open(archivo.URL) : setPreviewDialogURL(archivo.URL) }
    const getSitioAgropark = () => sitios.find(sitio => sitio.nombre === "Agropark");
    const getSitiosAtongo = () => sitios.filter(sitio => sitio.nombre !== "Agropark");
    const disableItemSitio = (item) => !puedeEditar() || locacionIsAgropark(locacionesHTF, item.locacionHTF);
    const getSitioOpciones = (item) => !item.locacionHTF ? [] : locacionIsAgropark(locacionesHTF, item.locacionHTF) ? [getSitioAgropark()] : getSitiosAtongo();


    return <div className="p-grid p-justify-center">

        <div className="p-col p-col-auto title-container">
            <span className="back-btn" onClick={() => { props.history.goBack() }}></span>
            <span className="icon-general compras-icon"></span>
            <span className="titles-group">
                <h1 className="header-title">Requerimientos de orden {editing && `#${state.orden_num}`}</h1>
                {editing && <p className="header-subtitle">Estatus: {getNombrePorID(estatuses, state.estatus)}</p>}
            </span>
        </div>

        <div className="p-col-12">
            <p className="section-title">Información general</p>
            <div className="card">
                <div className="p-grid ">
                    {editing && <>
                        <div className="form-group p-col-12 p-md-4">
                            <label htmlFor="numero-orden">Orden #</label>
                            <InputText disabled id="numero-orden" value={state.orden_num} />
                        </div>
                        <div className="form-group p-col-12 p-md-4">
                            <label htmlFor="fecha-creacion">Fecha de creación</label>
                            <InputText disabled id="fecha-creacion" value={moment(state.fecha_creacion).utcOffset("-0600").format("DD/MM/YYYY")} />
                        </div>
                    </>}
                    <ContenedorInput label="Urgencia" col={editing ? 12 : false} md={editing ? 4 : false}>
                        <Dropdown disabled={!puedeEditar()} id="urgencia" name="urgencia" optionLabel="nombre" optionValue="id" options={urgencias} value={state.urgencia} placeholder="Seleccionar" onChange={handleInputChange} />
                        {errores.includes("urgencia") && <Message severity="error" text="Este campo es requerido" />}
                    </ContenedorInput>
                    {state.urgencia && <ContenedorInput label="Fecha preferida de entrega">
                        <Calendar disabled={!puedeEditar()} name="fechaPreferidaEntrega" value={getCalendarDate(state.fechaPreferidaEntrega)} onChange={handleCalendarInputChange} dateFormat="dd/mm/yy" />
                        {errores.includes("fechaPreferidaEntrega") && <Message severity="error" text="Este campo es requerido" />}
                    </ContenedorInput>}
                </div>
            </div>
        </div>

        <div className="p-col-12">
            <p className="section-title">Detalle de orden</p>
            <div className="card">
                <div className="p-grid ">
                    {editing && <>
                        <div className="form-group p-col-12 p-md-6">
                            <label htmlFor="solicitante">Solicitante (Nombre)</label>
                            <InputText disabled id="solicitante" value={nombreSolicitante} />
                        </div>
                        <div className="form-group p-col-12 p-md-6">
                            <label htmlFor="departamento">Departamento</label>
                            <InputText disabled id="departamento" value={departamentoSolicitante} />
                        </div>
                    </>}
                    <div className="form-group p-col-12 p-md-6">
                        <label htmlFor="orden-ref">Orden Referencia</label>
                        <Dropdown disabled={!puedeEditar()} optionLabel="nombre" optionValue="id" id="orden_ref" name="orden_ref" options={ordenesRefs} value={state.orden_ref} onChange={handleInputChange} placeholder="Seleccionar"
                            filter={true} filterPlaceholder="Buscar orden de referencia" />
                        {errores.includes("orden_ref") && <Message severity="error" text="Este campo es requerido" />}
                    </div>
                    <div className="form-group p-col-12 p-md-6">
                        <label htmlFor="requerimiento">Requerimiento</label>
                        <Dropdown disabled={!puedeEditar()} id="requerimiento" optionLabel="nombre" optionValue="id" name="requerimiento" options={requerimientos} value={state.requerimiento} onChange={handleInputChange} placeholder="Seleccionar"
                            filter={true} filterPlaceholder="Buscar requerimiento" />
                        {errores.includes("requerimiento") && <Message severity="error" text="Este campo es requerido" />}
                    </div>
                </div>
            </div>
        </div>

        <div className="p-col-12">
            <p className="section-title">Items de solicitud</p>
            {state.items.map((item, index) => {
                return <div className="card item-compras">
                    <div className="p-grid ">
                        <div className="p-col-12 p-md-7">
                            <div className="p-grid ">
                                <div className="form-group p-col-12 p-md-12">
                                    <label htmlFor="solicitud">Solicitud</label>
                                    <InputText disabled={!puedeEditar()} id="solicitud" name={"solicitud"} value={item.solicitud} onChange={(e) => handleInputChange(e, index)} />
                                    {errores.includes(`solicitud${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                </div>
                                <div className="form-group p-col-6 p-md-3">
                                    <label htmlFor="cantidad">Cantidad</label>
                                    <InputText disabled={!puedeEditar()} keyfilter={"pnum"} id="cantidad" name={"cantidad"} value={item.cantidad} onChange={(e) => handleInputChange(e, index)} />
                                    {errores.includes(`cantidad${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                </div>
                                <div className="form-group p-col-6 p-md-3">
                                    <label htmlFor="unidades">Unidades</label>
                                    <Dropdown disabled={!puedeEditar()} optionLabel="nombre" optionValue="id" id="unidades" name={"unidades"} options={unidades} value={item.unidades} onChange={(e) => handleInputChange(e, index)} placeholder="Seleccionar" />
                                    {errores.includes(`unidades${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                </div>
                                <div className="form-group p-col-12 p-md-4">
                                    <label htmlFor="empresa">Empresa</label>
                                    <Dropdown disabled={!puedeEditar()} optionLabel="nombre" optionValue="id" id="empresa" name={"empresa"} options={empresas.filter(em => em.nombre !== "LORT")} value={item.empresa} onChange={(e) => handleInputChange(e, index)} placeholder="Seleccionar" />
                                    {errores.includes(`empresa${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                </div>
                                {empresaIsHTF(empresas, item.empresa) && <div className="form-group p-col-12 p-md-4">
                                    <label htmlFor="locacionHTF">Locación </label>
                                    <Dropdown disabled={!puedeEditar()} optionLabel="nombre" optionValue="id" id="locacionHTF" name={"locacionHTF"} options={locacionesHTF} value={item.locacionHTF} onChange={(e) => handleInputChange(e, index)} placeholder="Seleccionar" />
                                    {errores.includes(`locacionHTF${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                </div>}

                                {empresaIsHTF(empresas, item.empresa) &&
                                    <div className="form-group p-col-12 p-md-4">
                                        <label htmlFor="sitio">Sitio </label>
                                        <Dropdown disabled={disableItemSitio(item)} optionLabel="nombre" optionValue="id" id="sitio" name={"sitio"} options={getSitioOpciones(item)} value={item.sitio || ""} onChange={(e) => handleInputChange(e, index)} placeholder="Seleccionar" />
                                        {errores.includes(`sitio${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                    </div>}


                                <div className="form-group p-col-12 p-md-4">
                                    <label htmlFor="locacion">Ubicación</label>
                                    <Dropdown disabled={!puedeEditar()} optionLabel="nombre" optionValue="id" id="locacion" name={"locacion"} options={getItemLocacionOpciones(item.empresa, item.locacionHTF, item.sitio)} value={item.locacion} placeholder="Seleccionar" onChange={(e) => handleInputChange(e, index)} />
                                    {errores.includes(`locacion${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                </div>

                                <div className="form-group p-col-12 p-md-4">
                                    <label htmlFor="archivo">Subir archivo (imagen/pdf/excel)</label>
                                    {item.archivo && item.archivo.URL && <ArchivoPreview archivo={item.archivo} verArchivo={() => verArchivoFuncion(item.archivo)} puedeEditar={puedeEditar()} deleteArchivo={(e) => deleteArchivoItem(e, index)} />}
                                    <input disabled={!puedeEditar()} name={"archivo"} id="archivo" onChange={(e) => handleItemArchivoChange(e, index)} type="file" className="form-control-file" accept="image/jpeg,image/jpg,image/png, application/pdf, .xls,.xlsx" />
                                </div>

                                {(ordenCerrado(estatuses, state.estatus) || ordenCerradoParcial(estatuses, state.estatus)) && <>
                                    {puedeEditar() && <div className="form-group p-col-12 p-md-6">
                                        <label htmlFor="precio">Precio final</label>
                                        <InputText id="precio" name={"precio"} value={item.precio} onChange={(e) => handleInputChange(e, index)} />
                                    </div>}
                                    <div className="form-group p-col-12 p-md-6">
                                        <label htmlFor="itemFecha">Fecha de entrega</label>
                                        <Calendar disabled={!puedeEditar()} name={"fechaEntrega"} value={getCalendarDate(item.fechaEntrega)} onChange={(e) => handleCalendarInputChange(e, index)} dateFormat="dd/mm/yy" />
                                    </div>
                                </>}
                            </div>
                        </div>

                        <div className="p-col-12 p-md-5">
                            <div className="p-grid ">
                                <div className="form-group p-col-12 p-md-12">
                                    <label htmlFor="detalle">Detalle de Solicitud</label>
                                    <InputTextarea disabled={!puedeEditar()} rows={3} cols={30} autoResize={true} id="detalle" name={"detalle"} value={item.detalle} onChange={(e) => handleInputChange(e, index)} placeholder={"Favor de especificar toda la información adicional que permita identificar con más precisión el artículo (ejemplo: dimensiones, colores, marca, tipo de material, preferencia por proveedor, etc); así como de justificar la solicitud al describir el uso que se le dará al material solicitado."} />
                                    {errores.includes(`detalle${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                </div>
                            </div>
                        </div>
                        {state.items.length !== 1 && !editing &&
                            <div className="p-col-12 p-justify-end btn-row">
                                <Button label="Eliminar Item" onClick={() => eliminarItem(index)} />
                            </div>}
                    </div>
                </div>
            })}
            {!editing && <div className="p-grid p-fluid ">
                <Button label="+ Agregar nuevo item" onClick={agregarItem} />
            </div>}
        </div>

        {/*  Administración de la orden<*/}
        {editing && <div className="p-col-12">
            <p className="section-title">Administración de la orden</p>
            <div className="card">
                <div className="p-grid ">
                    <div className="form-group p-col-12 p-md-6">
                        <label htmlFor="estatus-nombre">Estatus </label>
                        <InputText disabled id="estatus-nombre" value={getNombrePorID(estatuses, state.estatus)} />
                    </div>
                    <div className="form-group p-col-12">
                        {puedeEditar() && <label htmlFor="imagenItem">Subir archivo (imagen/pdf/excel)</label>}
                        {state.archivosAdmin.map((archivo, index) => {
                            return <ArchivoPreview archivo={archivo} verArchivo={() => verArchivoFuncion(archivo)} puedeEditar={puedeEditar()} deleteArchivo={(e) => deleteArchivoAdmin(index, e)} />
                        })}
                        {puedeEditar() && <input multiple={true} name="archivosAdmin" onChange={(event) => handleArchivosAdminChange(event.target.files)} type="file" className="form-control-file" accept="image/jpeg,image/jpg,image/png,application/pdf, .xls,.xlsx" />}
                    </div>
                    {(puedeEditar() || state.comentarioAdmin) && <div className="form-group p-col-12 p-md-12">
                        <label htmlFor="detalle">Comentario administrador de compras</label>
                        <InputTextarea disabled={!puedeEditar()} rows={3} cols={30} autoResize={true} id="comentarioAdmin" name="comentarioAdmin" value={state.comentarioAdmin} onChange={handleInputChange} placeholder={"Comentario administrador de compras"} />
                    </div>}

                    {ordenEsperaEntrega(estatuses, state.estatus) && <>
                        {state.fechaEstimadaEntregaAnterior && <div className="form-group p-col-12 p-md-6">
                            <label htmlFor="fechaEstimadaEntregaAnterior">Fecha estimada de entrega anterior</label>
                            <Calendar disabled id="fechaEstimadaEntregaAnterior" name="fechaEstimadaEntregaAnterior" value={getCalendarDate(state.fechaEstimadaEntregaAnterior)} dateFormat="dd/mm/yy" />
                        </div>}
                        <div className="form-group p-col-12 p-md-6">
                            <label htmlFor="proveedor">Número Agrosmart</label>
                            <InputText id="numero_agrosmart" name="numero_agrosmart" value={state.numero_agrosmart} disabled={!puedeEditar()}  onChange={handleInputChange}/>
                        </div>
                        <div className="form-group p-col-12 p-md-6">
                            <label htmlFor="fecha-estimada-entrega">Fecha estimada de entrega</label>
                            <Calendar disabled={!puedeEditar()} id="fechaEstimadaEntrega" name="fechaEstimadaEntrega" value={getCalendarDate(state.fechaEstimadaEntrega)} onChange={handleCalendarInputChange} dateFormat="dd/mm/yy" />
                        </div>
                        <div className="form-group p-col-12 p-md-6">
                            <label htmlFor="precioFinal">Precio final</label>
                            <InputText disabled={!puedeEditar()} id="precioFinal" name="precioFinal" value={state.precioFinal} onChange={handleInputChange} />
                        </div>
                        <div className="form-group p-col-12 p-md-6">
                            <label htmlFor="moneda">Moneda </label>
                            <Dropdown disabled={!puedeEditar()} id="moneda" name="moneda" optionLabel="nombre" optionValue="id" options={monedas} value={state.moneda} placeholder="Seleccionar" onChange={handleInputChange} />
                        </div>
                    </>}

                    {ordenCerrado(estatuses, state.estatus) && (puedeEditar() || isAdminAlmacen(usuario.rol)) && <>
                        <div className="form-group p-col-12 p-md-6">
                            <label htmlFor="fechaEntregaMaterial">Fecha Entrega Material</label>
                            <Calendar disabled={!puedeEditar()} id="fechaEntregaMaterial" name="fechaEntregaMaterial" value={getCalendarDate(state.fechaEntregaMaterial)} onChange={handleCalendarInputChange} dateFormat="dd/mm/yy" />
                        </div>
                        <div className="form-group p-col-12 p-md-6">
                            <label htmlFor="proveedor">Proveedor</label>
                            <Dropdown disabled={!puedeEditar()} filter={true} filterPlaceholder="Proveedor" filterBy="nombre" id="proveedor" name="proveedor" optionLabel="nombre" optionValue="id" options={[...proveedores, { nombre: "Otro", id: "Otro" }]} value={state.proveedor} onChange={handleInputChange} placeholder={'Seleccionar'} />
                        </div>
                        {state.proveedor === "Otro" && <div className="form-group p-col-12 p-md-6">
                            <label htmlFor="otro-proveedor">Otro </label>
                            <InputText disabled={!puedeEditar()} id="otro-proveedor" value={otroProveedor} onChange={e => setOtroProveedor(e.target.value)} />
                            {errores.includes("otroProveedor") && <Message severity="error" text="Este campo es requerido" />}
                        </div>}
                        {state.comentarioCerrado && <div className="form-group p-col-12 p-md-6">
                            <label htmlFor="comentario-cerrado-admin">Comentario enviado </label>
                            <InputTextarea disabled rows={3} cols={30} id="comentario-cerrado-admin" value={state.comentarioCerrado} />
                        </div>}
                        {state.fechaCerrado &&
                        <div className="form-group p-col-12 p-md-6">
                            <label htmlFor="status-nuevo">Fecha de cierre </label>
                            <InputText disabled id="status-nuevo" value={moment(state.fechaCerrado).utcOffset("-0600").format("DD/MM/YYYY HH:mm")} />
                        </div>}
                        
                        <div className="form-group p-col-12 p-md-6">
                            <label htmlFor="precioFinal">Precio final</label>
                            <InputText disabled={!puedeEditar()} id="precioFinal" name="precioFinal" value={state.precioFinal} onChange={handleInputChange} />
                        </div>
                        <div className="form-group p-col-12 p-md-6">
                            <label htmlFor="moneda">Moneda </label>
                            <Dropdown disabled={!puedeEditar()} id="moneda" name="moneda" optionLabel="nombre" optionValue="id" options={monedas} value={state.moneda} placeholder="Seleccionar" onChange={handleInputChange} />
                        </div>
                    </>}

                    {ordenCerradoParcial(estatuses, state.estatus) && <>
                        <div className="form-group p-col-12 p-md-6">
                            <label htmlFor="fechaEntregaParcial">Fecha De Entrega Parcial</label>
                            <Calendar disabled={!puedeEditar()} id="fechaEntregaParcial" name="fechaEntregaParcial" value={getCalendarDate(state.fechaEntregaParcial)} onChange={handleCalendarInputChange} dateFormat="dd/mm/yy" />
                            {errores.includes("fechaEntregaParcial") && <Message severity="error" text="Este campo es requerido" />}
                        </div>
                        <div className="form-group p-col-12 p-md-6">
                            <label htmlFor="listaItemsParcial">Lista items</label>
                            <MultiSelect id="listaItemsParcial" filter={false} name="listaItemsParcial" optionLabel="nombre" optionValue="id" options={state.items.map((item, index) => ({ nombre: item.solicitud, id: index }))} value={state.listaItemsParcial} onChange={handleInputChange} placeholder={`${state.listaItemsParcial.length} objetos seleccionados`} />
                        </div>
                    </>}

                </div>
            </div>
        </div>
        }

        <PreviewModal imageURL={previewDialogURL} closePreviewModal={() => setPreviewDialogURL("")} />
        <CambiarEstatusModal visible={cambiarEstatusModalOpen} setFormularioState={(e) => setState({ ...state, ...e })} estatus={state.estatus} estatuses={estatuses} state={state} proveedores={proveedores} otroProveedor={otroProveedor} handleInputChange={handleInputChange} closeModal={() => setCambiarEstatusModalOpen(false)} setOtroProveedor={e => setOtroProveedor(e.target.value)} requisicionID={requisicionID} subirOtroProveedor={subirOtroProveedor} />
        {!isAdminAlmacen(usuario?.rol) && <NuevoFechaEntregaModal />}

        <div className="p-col-12">
            <div className="p-grid p-justify-end btn-row">
                <Button label="Cancelar" onClick={() => { props.history.goBack() }} />
                {puedeEditarEstatus() && <Button disabled={ordenCerrado(estatuses, state.estatus)} label="Cambiar estatus" onClick={() => setCambiarEstatusModalOpen(true)} />}
                <Button disabled={!puedeEditar()} label="Guardar" onClick={guardarOrden} />
            </div>
        </div>

    </div>
}
export default FormularioCompra;
