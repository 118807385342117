//react/reactn
import React, { useState, useEffect, useGlobal, setGlobal } from 'reactn'
//Funciones internas
import { haSidoClausurado, noHaSidoClausurado } from '../../../service/Ciclos';
import { filtrarVariedadMedleys, getColeccionesProductosYVariedadesTodos, getColoresVariedades, guardarProductoMezclado } from '../../../service/ProductosYVariedades';
import { getNombrePorID, getObjetoPorID } from '../../../util/functions';
//Componentes third party
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Message } from 'primereact/message';
//Componentes internos
import ContenedorHeader from '../../common/ContenedorHeader';
import ContenedorInput from '../../common/ContenedorInput';
import ContenedorPrincipal from '../../common/ContenedorPrincipal';
import SeccionBotones from '../../common/SeccionBotones';
import SeccionFormulario from '../../common/SeccionFormulario';
//Constantes
import { TIPO_CANTIDAD, TIPO_PESO } from '../../../service/constants/ProductosYVariedades.const';

const FormularioProductoMezclado = (props) => {
    const [ciclo] = useGlobal("ciclo");
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [, setCargando] = useGlobal("cargando");
    const [producto] = useGlobal("productoMezclado");
    const [medleysGeneral] = useGlobal("medleysGeneral");
    const [allVariedades] = useGlobal("allVariedades");
    const [coloresVariedades, setColoresVariedades] = useGlobal("coloresVariedades")
    const [editing] = useState(producto ? true : false);
    const [errores, setErrores] = useState({});

    //Opciones del formulario
    const [variedadesOpciones, setVariedadesOpciones] = useState([]);
    //Inputs del formulario
    const [nombre, setNombre] = useState("");
    const [variedades, setVariedades] = useState([]);
    const [tipoCalidadInicial, setTipoCalidadInicial] = useState("");

    const opcionesTipoCalidadInicial = [{ id: TIPO_CANTIDAD, nombre: "Cantidad" }, { id: TIPO_PESO, nombre: "Peso" }];//mover a los archivos de constantes? 

    useEffect(() => {
        setNombreModulo("Productos y variedades");
        setHabilitadoSelectorCiclo(false);
        obtenerDatosIniciales();
    }, [])

    useEffect(() => {//Quitar variedad "Medleys" de las opciones por mezclar, solo se utiliza ese como historico para los ciclos 2019 - 2020 y 2020 - 2021
        if (coloresVariedades && allVariedades) {
            let variedadesSinMedleysGeneral = filtrarVariedadMedleys(allVariedades);
            variedadesSinMedleysGeneral = variedadesSinMedleysGeneral.map(el => ({ ...el, color: getNombrePorID(coloresVariedades, el.color) }))
            setVariedadesOpciones(variedadesSinMedleysGeneral)
        }
    }, [coloresVariedades, allVariedades])
    useEffect(() => {
        if (producto) {
            const variedadesIDs = producto.variedades?.map(el => el.id) || [];
            setVariedades(variedadesIDs);
            setNombre(producto.nombre);
            setTipoCalidadInicial(producto.tipo_calidad_inicial);
        }
    }, [ciclo])
    useEffect(() => {
        setErrores({});
    }, [nombre, variedades, tipoCalidadInicial])

    const obtenerDatosIniciales = async () => {
        const colores = await getColoresVariedades();
        setColoresVariedades(colores);
    }

    const guardar = async () => {
        if (validarErrores()) {
            setCargando(true);
            const datos = { nombre, variedades, tipo_calidad_inicial: tipoCalidadInicial, mezcla: true };
            await guardarProductoMezclado(ciclo, producto?.id, datos);
            await recargarDatos();

            setCargando(false);
            props.history.goBack();
        }
    }
    const validarErrores = () => {
        const errores = {};
        const camposRequeridos = { "nombre": nombre, "tipoCalidadInicial": tipoCalidadInicial };

        for (const key in camposRequeridos) {
            const campo = camposRequeridos[key];
            if (!campo) { errores[key] = true; }
        }
        if (variedades.length === 0) { errores.variedades = true; }

        setErrores(errores);
        return Object.keys(errores).length === 0;
    }
    const recargarDatos = async () => {//pasar esto a carpeta /service?
        const datosColeccionesProductos = await getColeccionesProductosYVariedadesTodos(ciclo);
        setGlobal({ ...datosColeccionesProductos });
    }

    const puedeEditar = () => noHaSidoClausurado(ciclo);

    return (
        <ContenedorPrincipal>
            <ContenedorHeader titulo={`${editing ? "Editar" : "Crear"} mezcla`} iconos="presentacion-icon" atras={props.history.goBack} />

            <SeccionFormulario titulo="General">
                <ContenedorInput label="Nombre">
                    <InputText value={nombre} onChange={(e) => setNombre(e.target.value)} disabled={!puedeEditar()} />
                    {errores.nombre && <Message severity="error" text="Este campo es requerido" />}
                </ContenedorInput>

                <ContenedorInput label="Variedades">
                    <MultiSelect value={variedades} options={variedadesOpciones} optionLabel="nombre" optionValue="id" disabled={!puedeEditar()}
                        onChange={e => setVariedades(e.value)} selectedItemsLabel={`${variedades?.length} variedades seleccionadas`} filter={true} filterBy="nombre,color" />
                    {errores.variedades && <Message severity="error" text="Este campo es requerido" />}
                </ContenedorInput>
            </SeccionFormulario>

            <SeccionFormulario titulo="Información empaque">
                <ContenedorInput label="Packout respaldo">
                    <InputText value={medleysGeneral?.packout_respaldo} disabled={true} />
                </ContenedorInput>

                <ContenedorInput label="Medida de calidad inicial">
                    <Dropdown value={tipoCalidadInicial} options={opcionesTipoCalidadInicial} optionLabel="nombre" optionValue="id" onChange={(e) => setTipoCalidadInicial(e.target.value)} disabled={!puedeEditar()} />
                    {errores.tipoCalidadInicial && <Message severity="error" text="Este campo es requerido" />}
                </ContenedorInput>
            </SeccionFormulario>

            <SeccionBotones>
                <Button label="Cancelar" className="p-button-secondary" onClick={props.history.goBack} />
                <Button label="Guardar" onClick={guardar} disabled={!puedeEditar()} />
            </SeccionBotones>
        </ContenedorPrincipal>
    )
}
export default FormularioProductoMezclado;