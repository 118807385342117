import { faFileInvoiceDollar, faPencilRuler, faPowerOff, faTruck, faBoxes, faLeaf, faUser, faCogs, faHome, faChartBar } from '@fortawesome/free-solid-svg-icons';
import { ROL_AGENTE_MANTENIMIENTO } from '../constants';

const Administradores = ["Administrador", "Administrador RH", "Administrador Empaque",
    "Administrador Embarques", "Grower", "Administrador Calidad", "Administrador Biocontrol",
    "Administrador Inocuidad"];
export const construirMenu = (rolUsuario, cerrarSesion) => {
    let menu = [];
    if (rolUsuario === "agente aduanal" || rolUsuario === "Transportista") {
        menu.push(agregarMenuPostcosecha(menu, rolUsuario));
        menu.push(agregarCierreSesion(cerrarSesion));
    } else if (rolUsuario === "Proveedor Externo") {
        menu.push(agregarMenuCosecha(menu, rolUsuario))
        menu.push(agregarMenuPostcosecha(menu, rolUsuario))
        menu.push(agregarCierreSesion(cerrarSesion));
    }
    else if (rolUsuario === ROL_AGENTE_MANTENIMIENTO) {
        menu.push(agregarMenuMantenimiento(menu, rolUsuario))
        menu.push(agregarCierreSesion(cerrarSesion));
    }
    else if (rolUsuario === "Administrador Mantenimiento") {
        menu.push(agregarMenuRequisiciones(menu, rolUsuario))
        menu.push(agregarCierreSesion(cerrarSesion));
        menu.push(agregarMenuAvances(menu, rolUsuario))
    }
    else if (rolUsuario === "Finanzas") {//////////////////
        menu.push(agregarMenuCosecha(menu, rolUsuario))
        menu.push(agregarMenuPostcosecha(menu, rolUsuario));
        menu.push(agregarMenuAdministracion(menu, rolUsuario));
        menu.push(agregarMenuFinanzas(menu, rolUsuario));
        menu.push(agregarMenuRequisiciones(menu, rolUsuario))
        menu.push(agregarCierreSesion(cerrarSesion));

        ///////////////////
    }
    else if (rolUsuario === "Administrador Seguridad") {
        menu.push(agregarMenuRH(menu, rolUsuario));
        menu.push(agregarMenuCosecha(menu, rolUsuario));
        menu.push(agregarMenuPostcosecha(menu, rolUsuario));
        menu.push(agregarMenuFinanzas(menu, rolUsuario));
        menu.push(agregarMenuAdministracion(menu, rolUsuario));
        menu.push(agregarMenuRequisiciones(menu, rolUsuario));
        menu.push(agregarCierreSesion(cerrarSesion));

    }
    else if (rolUsuario === "Auxiliar Seguridad") {
        menu.push(agregarMenuCosecha(menu, rolUsuario))
        menu.push(agregarMenuAdministracion(menu, rolUsuario));
        menu.push(agregarMenuRequisiciones(menu, rolUsuario));
        menu.push(agregarCierreSesion(cerrarSesion));

    }
    else if (rolUsuario === "Administrador Almacén") {
        menu.push(agregarMenuRequisiciones(menu, rolUsuario));
        menu.push(agregarCierreSesion(cerrarSesion));
    }
    else {
        menu.push(agregarInicio(menu));
        if (!["Medico"].includes(rolUsuario)) {
            menu.push(agregarGraficas(menu, rolUsuario));
        }

        menu.push(agregarMenuRH(menu, rolUsuario));
        menu.push(agregarMenuCosecha(menu, rolUsuario));
        menu.push(agregarMenuPostcosecha(menu, rolUsuario));
        menu.push(agregarMenuFinanzas(menu, rolUsuario));
        menu.push(agregarMenuAdministracion(menu, rolUsuario));
        menu.push(agregarMenuRequisiciones(menu, rolUsuario));
        menu.push(agregarCierreSesion(cerrarSesion));
        menu.push(agregarMenuAvances(menu, rolUsuario));
    }
    return menu;
}
const agregarMenuMantenimiento = (menu, rolUsuario) => {
    let obj = {
        label: 'Mantenimiento', icon: faUser,
        items: [
        ]
    }
    if (rolUsuario === ROL_AGENTE_MANTENIMIENTO) {
        obj["items"].push({ label: "Capturar Actividades", to: "/mantenimiento/agente/consulta_semana" });
    }
    return obj;
}
const agregarMenuRequisiciones = (menu, rolUsuario) => {
    let obj = {
        label: 'Requisiciones', icon: faUser,
        items: [
        ]
    }
    obj["items"].push({ label: "Requisiciones de compra", to: "/requisiciones/compras" });
    obj["items"].push({ label: "Requisiciones de sistemas", to: "/requisiciones/sistemas" });
    obj["items"].push({ label: "Requisiciones de mantenimiento", to: "/requisiciones/mantenimiento" });
    return obj;
}

const agregarInicio = (menu) => {
    return ({
        label: 'Inicio', icon: faHome, command: () => { window.location = '#/' }
    })
}
const agregarGraficas = (menu, rolUsuario) => {

    return ({
        label: 'Gráficas', icon: faChartBar, command: () => { window.location = '#/graficas' }
    })

}

const agregarMenuRH = (menu, rolUsuario) => {
    let obj = {
        label: 'Recursos humanos', icon: faUser,
        items: [
        ]
    }
    if (Administradores.includes(rolUsuario) || ["Auxiliar RH", "Personal Administrativo"].includes(rolUsuario)) {
        obj["items"].push({ label: 'Empleados', to: '/cards_rh' });
    }
    if (Administradores.includes(rolUsuario) || ["Auxiliar RH", "Auxiliar Grower", "Personal Administrativo"].includes(rolUsuario)) {
        obj["items"].push({ label: 'Producción', to: '/nomina' });
    }
    if (Administradores.includes(rolUsuario) || ["Medico", "Médico", "Administrador RH", "Auxiliar RH", "Personal Administrativo"].includes(rolUsuario)) {
        obj["items"].push({ label: "Reporte médico", to: "/reporte_medico/cards" });
    }
    if (Administradores.includes(rolUsuario) || ["Administrador Empaque", "Personal Administrativo"].includes(rolUsuario)) {
        obj["items"].push({ label: "Nómina de empaque", to: "/nomina_empaque" });
    }

    return obj;

}

const agregarMenuCosecha = (menu, rolUsuario) => {

    let obj = {
        label: 'Plantación', icon: faLeaf,
        items: [
        ]
    }

    /////////////////
    if (rolUsuario === "Finanzas") {
        obj["items"].push({ label: 'Cultivo', to: '/cultivo' });
        obj["items"].push({ label: 'Cosecha', to: '/cosecha' });
        obj["items"].push({ label: "Compra externa", to: "/cosecha/compra_externa" });
        return obj
    }
    ////////////////


    if (Administradores.includes(rolUsuario) || ["Administrador Seguridad","Auxiliar Seguridad", "Personal Administrativo", "Auxiliar Grower"].includes(rolUsuario)) {
        obj["items"].push({ label: 'Cultivo', to: '/cultivo' });
    }
    if (Administradores.includes(rolUsuario) || rolUsuario == "Auxiliar Biocontrol") {
        obj["items"].push({ label: 'Biocontrol', to: '/biocontrol/cards' });
    };
    if (Administradores.includes(rolUsuario) ||
        ["Personal Administrativo", "Auxiliar Grower", "Auxiliar RH", "Auxiliar Calidad", "Auxiliar Biocontrol", "Auxiliar Empaque"].includes(rolUsuario)) {
        obj["items"].push({ label: 'Cosecha', to: '/cosecha' });
    };
    if (rolUsuario === "Proveedor Externo") {
        obj["items"].push({ label: "Registro de compra", to: "/cosecha/compra_externa" });
        obj["items"].push({ label: "Pronostico", to: "/cosecha/pronostico_compra_externa" });
    }
    if (["Administrador", "Grower", "Administrador Cosecha", "Administrador Empaque",
        "Auxiliar Empaque", "Administrador Embarques"].includes(rolUsuario)) {
        obj["items"].push({ label: "Compra externa", to: "/cosecha/compra_externa" });

    }
    if (Administradores.includes(rolUsuario) || ["Grower", "Auxiliar Grower", "Administrador Empaque", "Auxiliar Empaque",
        "Administrador Embarques", "Auxiliar Embarques"].includes(rolUsuario)) {
        obj["items"].push({ label: "Pronósticos", to: "/pronosticos/cards" });
    }
    if (["Administrador", "Administrador Calidad", "Personal Administrativo"].includes(rolUsuario)) {
        obj["items"].push({ label: "Calidad de trabajo", to: "/calidad_trabajo" });
    }
    return obj;

}

const agregarMenuPostcosecha = (menu, rolUsuario) => {
    let obj = {
        label: 'Producto', icon: faBoxes,
        items: [
        ]
    }

    /////////////////
    if (rolUsuario === "Finanzas") {
        obj["items"].push({ label: 'Calidad', to: '/calidad' });
        obj["items"].push({ label: "Embarques", to: "/embarques" });
        obj["items"].push({ label: "Ventas nacionales", to: "/ventas_nacionales/tabla_resumen" });
        return obj
    }
    ////////////////

    if (Administradores.includes(rolUsuario) ||
        ["Auxiliar Calidad", "Auxiliar Grower", "Auxiliar Empaque"].includes(rolUsuario)) {
        obj["items"].push({ label: 'Calidad', to: '/calidad' });
    };
    if (Administradores.includes(rolUsuario) ||
        ["Auxiliar Empaque", "Personal Administrativo"].includes(rolUsuario)) {
        obj["items"].push({ label: 'Empaque', to: '/producto' });
    };
    if (Administradores.includes(rolUsuario) ||
        rolUsuario === "Auxiliar Empaque" ||
        rolUsuario === "Auxiliar Embarques" ||
        rolUsuario === "Personal Administrativo") {
        obj["items"].push({ label: "Cumplimiento", to: "/cumplimiento/cards" });

    };
    if (Administradores.includes(rolUsuario) || rolUsuario === "Auxiliar Empaque" ||
        rolUsuario === "Auxiliar Embarques" ||
        rolUsuario === "Personal Administrativo" ||
        rolUsuario === "Auxiliar Calidad"
    ) {
        obj["items"].push({ label: 'Órdenes de venta', to: '/ordenes' });
    }
    if (Administradores.includes(rolUsuario)
        || rolUsuario === "Auxiliar Empaque"
        || rolUsuario === "Auxiliar Embarques"
        || rolUsuario === "Personal Administrativo") {
        obj["items"].push({ label: "Embarques", to: "/embarques" });
    } else if (rolUsuario === "agente aduanal" || rolUsuario === "Transportista") {
        obj["items"].push({ label: "Embarques", to: "/embarques/consultar" });
    }
    if (Administradores.includes(rolUsuario) || ["Grower", "Auxiliar Grower", "Administrador Empaque", "Auxiliar Empaque",
        "Administrador Embarques", "Auxiliar Embarques"].includes(rolUsuario)) {
        obj["items"].push({ label: "Ventas nacionales", to: "/ventas_nacionales/tabla_resumen" });
    }
    if (["Administrador", "Administrador Mantenimiento"].includes(rolUsuario)) {
        obj["items"].push({ label: "Mantenimiento", to: "/mantenimiento/tabla_planeacion" });
    }


    if (rolUsuario === "Proveedor Externo") {
        obj["items"].push({ label: "Reporte de calidad", to: "/calidad/reporte_proveedor_externo" });
    }

    return obj;
}
const agregarMenuFinanzas = (menu, rolUsuario) => {
    let obj = {
        label: 'Finanzas', icon: faFileInvoiceDollar,
        items: [
        ]
    }
    if (rolUsuario === "Administrador" || ["Finanzas", "Personal contabilidad", "Personal Administrativo"].includes(rolUsuario)) {
        obj["items"].push({ label: "Reporte de validación de precios", to: "/rechazos/manifestos_validacion_precios" });
        obj["items"].push({ label: "Reporte de precios", to: "/reporte_empaque/precios" });
        obj["items"].push({ label: "Tabla de invoices", to: "/rechazos/tabla_invoices" });
        obj["items"].push({ label: "Reporte de validación de precios (antiguo)", to: "/reporte_empaque" });
        obj["items"].push({ label: "Reporte de cobranza", to: "/reporte_empaque/cobranza" });
        obj["items"].push({ label: "Gráfica precios", to: "/analisis_costos/precios" });

        if (rolUsuario !== "Finanzas") obj["items"].push({ label: "Budget de presentaciones", to: "/finanzas/budget_presentaciones" });
        obj["items"].push({ label: "Reporte acumulado de precios", to: "/finanzas/reporte_acumulado_precios" });

    }
    return obj;
}

const agregarMenuAdministracion = (menu, rolUsuario) => {
    let obj = {
        label: 'Administración', icon: faCogs,
        items: [
            //         
        ]
    }

    /////////////////
    if (rolUsuario === "Finanzas") {
        obj["items"].push({ label: "Invernaderos físicos", to: "/administrar/invernaderos_fisicos" });
        obj["items"].push({ label: 'Invernaderos', to: "/administrar/invernaderos_virtuales" });
        obj["items"].push({ label: 'Ciclos', to: "/administrar/ciclos" });
        obj["items"].push({ label: "Productos y variedades", to: "/admin/productos_y_variedades" });
        obj["items"].push({ label: 'Presentaciones', to: '/admin/presentaciones' });
        return obj
    }
    ////////////////

    if (Administradores.includes(rolUsuario) || ["Auxiliar Seguridad", "Auxiliar Embarques", "Personal Administrativo", "agente aduanal", "cliente"].includes(rolUsuario)) {
        obj["items"].push({ label: "Invernaderos físicos", to: "/administrar/invernaderos_fisicos" });
    }

    if (!["Auxiliar Seguridad", "agente aduanal", "cliente"].includes(rolUsuario)) {
        obj["items"].push({ label: 'Invernaderos', to: "/administrar/invernaderos_virtuales" });
    }

    if (rolUsuario == "Administrador") {

        obj["items"].push({ label: 'Usuarios', to: '/admin/usuarios' });
        obj["items"].push({ label: 'Ciclos', to: "/administrar/ciclos" });
        obj["items"].push({ label: "Grupos de presentaciones", to: "/admin/grupos_presentaciones" });
        obj["items"].push({ label: "Proveedores", to: "/admin/proveedores" });
        obj["items"].push({ label: "Productos y variedades", to: "/admin/productos_y_variedades" });

    }
    if (["Administrador", "Auxiliar RH", "Administrador RH"].includes(rolUsuario)) {
        obj["items"].push({ label: 'Actividades', to: '/admin/actividades' });
    }
    if (Administradores.includes(rolUsuario) || ["Auxiliar Embarques", "Personal Administrativo"].includes(rolUsuario)) {
        obj["items"].push({ label: 'Presentaciones', to: '/admin/presentaciones' });
    }
    if (Administradores.includes(rolUsuario) || ["Administrador Empaque"].includes(rolUsuario)) {
        obj["items"].push({ label: "Empaques", to: "/admin/empaques" });
    }

    return obj;
}

const agregarCierreSesion = (cerrarSesion) => {
    return {
        label: 'Cerrar sesión', icon: faPowerOff, to: "/", command: () => { cerrarSesion() }
    }
}
const agregarMenuAvances = (menu, rolUsuario) => {
    let obj = {
        label: 'Avance pantallas', icon: faPencilRuler,
        items: []
    }
    if (["Administrador", "Administrador Empaque"].includes(rolUsuario)) { }
    if (["Administrador"].includes(rolUsuario)) {
        // obj["items"].push({ label: "Rechazos - validación de precios", to: "/rechazos/manifestos_validacion_precios" });
        // obj["items"].push({ label: "Rechazos - tabla de invoices", to: "/rechazos/tabla_invoices" });
        obj["items"].push({ label: "Reporte de rechazos", to: "/rechazos/reporte" });

        // obj["items"].push({ label: "Gráfica costos de empaque", to: "/analisis_costos/costos_empaque" });
        // obj["items"].push({ label: "Gráfica detalle de empaque", to: "/empaque/graficas/budget" });
        // obj["items"].push({ label: "Gráfica ingresos brutos", to: "/analisis_costos/ingresos_brutos" });
        obj["items"].push({ label: "Análisis de costos", to: "/analisis_costos/" });
        // obj["items"].push({ label: "Ingresos clientes ciclo", to: "/empaque/graficas/distribucion_clientes_ciclo" });
        // obj["items"].push({ label: "Ingresos clientes semanal", to: "/empaque/graficas/distribucion_clientes_semana" });
    }

    if (["Administrador", "Grower", "Administrador Cosecha"].includes(rolUsuario)) { }

    return obj;
} //277