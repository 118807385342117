//react/reactn
import React, { useGlobal } from 'reactn'
//Funciones internas
import { getTextoBoton } from '../../../../service/diccionarios/ProductosVariedades.dicc'
//Componentes third party
import { Button } from 'primereact/button'
//Constantes
import { LABEL_COLORES, LABEL_PRODUCTOS, LABEL_VARIEDADES } from '../../../../service/constants/ProductosYVariedades.const'

const BotonCrear = (props) => {
    const [activeTab] = useGlobal("activeTabProductosYVariedades");

    const irFormulario = () => {
        if (activeTab.label === LABEL_PRODUCTOS) { crearProductoMezclado(); }
        else if (activeTab.label === LABEL_VARIEDADES) { crearVariedad(); }
        else if (activeTab.label === LABEL_COLORES) { crearColor(); }
    }
    const crearProductoMezclado = () => { props.history.push("/admin/productos/crear"); }
    const crearVariedad = () => { props.history.push("/admin/variedades/crear"); }
    const crearColor = () => { props.history.push("/admin/colores/crear"); }

    return (
        <React.Fragment>
            <Button {...props} label={getTextoBoton(activeTab?.label)} activeTab={activeTab} onClick={irFormulario} />
        </React.Fragment>)
}
export default BotonCrear;