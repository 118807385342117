import React, { useState, useEffect, useGlobal } from 'reactn';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { obtenerActividades } from '../../../service/actividades';
import { Button } from 'primereact/button';
import ContenedorPrincipal from '../../common/ContenedorPrincipal';
import ContenedorHeader from '../../common/ContenedorHeader';
import RowBotonesTop from '../../common/RowBotonesTop';
import ContenedorBotonesTop from '../../common/ContenedorBotonesTop';
import ContenedorTablaAdmin from '../../common/ContenedorTablaAdmin';
import { getCategorias } from '../../../service/Presentaciones';

const TablaCategorias = (props) => {
    const [actividad, setActividad] = useGlobal("actividad");
    let [usuario, setUsuario] = useGlobal("usuario");
    const [nombreModulo, setNombreModulo] = useGlobal("nombreModulo");

    const [clientes, setClientes] = useState([]);
    const [productos, setProductos] = useState([]);
    const [filtroCliente, setFiltroCliente] = useState();
    const [filtroProducto, setFiltroProducto] = useState();
    const [globalFilter, setGlobalFilter] = useState("");
    const [actividades, setActividades] = useState("actividades");
    const [categorias, setCategorias] = useState([]);

    useEffect(() => {
        setNombreModulo('Categorias')
        getCategorias().then(cats => {
            setCategorias(cats.map(el => ({...el, numPresentaciones: el.presentaciones?.length || 0})));
        });
    }, [])

    const puedeCrear = () => (usuario.rol === "Administrador")
    const crearCategoria = () => { props.history.push("/admin/bono_empaque/crear"); }
    const irCategoria = (event) => { props.history.push(`/admin/bono_empaque/modificar/${event.data.id}`); }

    const bodyImportante = (rowData, column) => {
        let field = rowData[column["field"]];
        return field ? "Sí" : "No";
    }
    return <ContenedorPrincipal>
        <ContenedorHeader titulo="Administrar categorias" subtitulo="Bonos de nomina empaque" atras={props.history.goBack} iconos="activity-icon" col="p-col p-col-auto" />

        {puedeCrear() &&
            <ContenedorBotonesTop>
                <RowBotonesTop>
                    <Button label="Crear nueva" onClick={crearCategoria} />
                </RowBotonesTop>
            </ContenedorBotonesTop>}

        <ContenedorTablaAdmin>
            <DataTable selectionMode="single" className="tabla-actividades" value={categorias} onRowClick={irCategoria} scrollable={true} >
                <Column field="nombre" header="Nombre" />
                <Column field="numPresentaciones" header="# presentaciones asociados" />
            </DataTable>
        </ContenedorTablaAdmin>
    </ContenedorPrincipal>
}
export default TablaCategorias;