import React from 'reactn';
import { dosDeciComas } from '../../../util/functions';
import { UnidadBase } from './UnidadBase.atomo';

export const UnidadPrecioPorLibra = (props) => <UnidadBase {...props} claseExtra="unidad-kg-bold" valor={`$${dosDeciComas(props.valor)}`} unidad="/lb" />;
export const UnidadPrecioPorLibraCol = (rowData, column) => {
    let valor = rowData[column.field];

    const [field1="", field2="", field3=""] = column.field?.split(".");
    if (field2 && !field3) { valor = rowData[field1]?.[field2]; }
    else if (field2 && field3) { valor = rowData[field1]?.[field2]?.[field3]; }

    return <UnidadBase claseExtra="unidad-kg-bold" valor={`$${dosDeciComas(valor)}`} unidad="/lb" />;
}