import { setGlobal } from "reactn"
import * as firebase from "firebase/app";
import "firebase/firestore";
import { DeepClone, separadoComas, sumarNumerosArrayPorCampo } from "../util/functions";
import { convertirCajasPresentacionAPallets } from "./Cumplimiento";
import { getEmpaquePorInvernaderoVirtualID } from "./Invernaderos";
import { DATA_LABELS_ENABLED, STACK_TYPE_100_PERCENT } from "./constants/Graficas.const";
import { estamosEnProduccion } from "../constants";

export const guardarDistribucionData = async (ciclo, ordenVenta, itemsDestino = [], presentacion, ordenesSemanaOrig) => {
    const a = Date.now()

    if (itemsDestino?.length > 0) {
        itemsDestino.forEach(destino => {
            destino.distribuidos = (destino.distribuidos || 0) + parseInt(destino.cajas_orden || 0);
            destino.distribuidas = (destino.distribuidas || 0) + parseInt(destino.cajas_orden || 0);
            console.log("destino ",{destino})
            destino.cajas_distribuidas = (destino.cajas_distribuidas || 0) + parseInt(destino.cajas_orden || 0);
        })
    }
    const indexOrdenOriginal = ordenesSemanaOrig.findIndex(orden => orden.id === ordenVenta.id);
    const ordenVentaOriginal = ordenesSemanaOrig[indexOrdenOriginal];
    const itemsAGuardarTodos = [...ordenVenta.items, ...itemsDestino];

    //Historico de datos distribuidos   **** HACER TODO ESTO UN TRIGGER? ****
    const historicoDistribuciones = ordenVentaOriginal.historicoDistribuciones || {};
    historicoDistribuciones[Date.now()] = itemsAGuardarTodos;
    ordenVenta.historicoDistribuciones = historicoDistribuciones;
    //Historico de venta original si es la primera ves 
    if (!checarSiHuboDistribuciones([ordenVenta], presentacion)) { ordenVenta.distribucionOriginal = ordenVentaOriginal.items; }

    //Agrupar items con el mismo p.o., presentación e invernadero
    const itemsAGuardarAgrupados = agruparItemsSimilares(itemsAGuardarTodos);
    //Procesar datos para guardar en Firestore
    ordenVenta.items = borrarItemsSinCajas(itemsAGuardarAgrupados);//Mejor hacer esto adentro de agruparItemsSimilares()? 
    ordenVenta.fecha_distribuido = Date.now();
    ordenVenta.distribuido = Date.now();
    const ordenVentaAGuardar = DeepClone(ordenVenta);

    //Guardar en Firestore
    //await guardarDistribucionOrdenVenta(ciclo, ordenVenta.id, ordenVentaAGuardar);
    return ordenVentaAGuardar;
}//123
const borrarItemsSinCajas = (items = []) => { return items.filter(item => parseInt(item.cajas_orden) > 0); }

const agruparItemsSimilares = (itemsAGuardar) => {
    //console.log("ITEMS SIMILARES A GUADAR: ", itemsAGuardar);
    const agrupadas = groupAndSum(itemsAGuardar, ['po', 'invernadero_ref', 'presentacion_ref', 'empaque_ref'], ['cajas_orden', 'pallets_orden']);

    const agrupadasConDatos = agrupadas.map(agrupada => {
        if (agrupada.nombre_invernadero || agrupada.itemID) { return agrupada; }
        else {
            let found = itemsAGuardar.find(item => item.po === agrupada.po && item.invernadero_ref === agrupada.invernadero_ref && item.presentacion_ref === agrupada.presentacion_ref && item.empaque_ref === agrupada.empaque_ref);
            return { ...found, ...agrupada }
        }
    })
    //console.log("AGRUPADAS CON DATOS: ", agrupadasConDatos);
    return agrupadasConDatos;
}
function groupAndSum(arr, groupKeys, sumKeys) {
    return Object.values(
        arr.reduce((acc, curr) => {
            const group = groupKeys.map(k => curr[k]).join('-');
            acc[group] = acc[group] || Object.fromEntries(
                groupKeys.map(k => [k, curr[k]]).concat(sumKeys.map(k => [k, 0])));
            sumKeys.forEach(k => acc[group][k] += parseInt(curr[k]));
            return acc;
        }, {})
    );
}
const guardarDistribucionOrdenVenta = async (ciclo, ordenVentaID, datosAGuardar) => {
    console.log("**********END NU**********");
    return await firebase.firestore().collection("ciclos").doc(ciclo).collection("ordenes_venta").doc(ordenVentaID).set(datosAGuardar, { merge: true }).catch(error => { console.log("ERROR AL GUARDAR DISTRIBUCION: ", { datosAGuardar, error }) })
}

////////// Util - helper functions //////////
//---------------------------------------------------------------//
export const filtrarOrdenesPorPresentacion = (ordenesDeVenta = [], presentacionID = "") => ordenesDeVenta.filter(venta => hayItemDeEstePresentacion(venta, presentacionID));
export const hayItemDeEstePresentacion = (venta, presentacionID) => venta.items?.some(item => item.presentacion_ref === presentacionID);

export const filtrarOrdenesDeVentaPorPresentacion = () => {
/*     diasSemana.forEach(dia => {
        const ordenesVentaDeLaPresentacion = filtrarOrdenesPorPresentacion(props.ordenesVentaSemana, presentacion.id)
        const ordenesVentaDelDia = filtrarOrdenesPorDia(ordenesVentaDeLaPresentacion, dia.unixTime);

        ordenesVentaDistribucionData[dia.unixTime] = procesarOrdenItemDatos(ordenesVentaDelDia);//Mejora con let y regresar datos? O hacer const y no dejar procesarOrdenItemDatos() regresar algo. //O regresar otro const. seria demasiado variables declarados?
        ordenesVentaDistribucionData.ordenesFiltrados.push(ordenesVentaDistribucionData[dia.unixTime].flat())
    });
 */
}

//Mejor parseInt fecha_envio, y/o convertir dia a `${dia}`, Y/O poner == en ves de  ===?
export const filtrarOrdenesPorDia = (ordenesDeVenta = [], dia = "") => ordenesDeVenta.filter(venta => parseInt(venta.fecha_envio) === dia);

export const sumarValorDeItemsOrdenesDeVenta = (ordenesDeVenta = [], valor = "") => {
    const itemsDeOrdenes = ordenesDeVenta.map(ordenVenta => ordenVenta.items).flat();
    const suma = sumarNumerosArrayPorCampo(itemsDeOrdenes, valor);
    return suma;
}

export const filtrarOrdenItemsOrigenes = (ordenVenta, presentacion = {}) => {
    return ordenVenta.items?.filter(item => item.presentacion_ref === presentacion?.id) || [];
}
export const filtrarOrdenItemsDistribucionOriginal = (ordenVenta, presentacion = {}) => {
    return (ordenVenta.distribucionOriginal || ordenVenta.items)?.filter(item => item.presentacion_ref === presentacion?.id) || [];
}
export const filtrarOrdenItemsDeVentas = (ordenesVenta = [], presentacion = {}) => {
    const ordenesVentaItems = ordenesVenta.map(ordenVenta => filtrarOrdenItemsOrigenes(ordenVenta, presentacion));
    return ordenesVentaItems.flat();
}
export const filtrarOrdenItemsOriginalesDeVentas = (ordenesVenta = [], presentacion = {}) => {
    const ordenesVentaItems = ordenesVenta.map(ordenVenta => filtrarOrdenItemsDistribucionOriginal(ordenVenta, presentacion));
    return ordenesVentaItems.flat();
}

export const getTotalesOrdenesDeVenta = (ordenesDeVenta = [], presentacion = {}) => {
    const itemsPresentaciones = filtrarOrdenItemsDeVentas(ordenesDeVenta, presentacion)
    const cajas = sumarNumerosArrayPorCampo(itemsPresentaciones, "cajas_orden");
    const pallets = convertirCajasPresentacionAPallets(cajas, presentacion);
    const cajasDistribuidas = sumarNumerosArrayPorCampo(itemsPresentaciones, "distribuidos");
    return { cajas, pallets, cajasDistribuidas };
}

export const checarSiHuboDistribuciones = (ordenesVenta = [], presentacion = {}) => {
    const itemsDeOrigen = filtrarOrdenItemsDeVentas(ordenesVenta, presentacion);
    const huboDistribuciones = itemsDeOrigen.some(item => !!item.fecha_distribuido || !!item.distribuidos);

    const mismaPresentacionExiste = ordenesVenta.some(venta => {
        const tienePresentacionOriginal = venta.distribucionOriginal?.some(item => item.presentacion_ref === presentacion.id);
        return tienePresentacionOriginal;
    })
    return huboDistribuciones && mismaPresentacionExiste;
}

//Decorators
export const getEmpaqueDeVentaItem = (invernaderosFisicos, invernaderosVirtuales, invernaderoVirtual) =>
    getEmpaquePorInvernaderoVirtualID(invernaderosFisicos, invernaderosVirtuales, invernaderoVirtual);

export const sumarCajasOrdenesDeVenta = (ordenesDeVenta = []) => sumarValorDeItemsOrdenesDeVenta(ordenesDeVenta, "cajas_orden");
export const sumarCajasDistribuidasOrdenesDeVenta = (ordenesDeVenta = []) => sumarValorDeItemsOrdenesDeVenta(ordenesDeVenta, "distribuidos");
export const getSumaCajasOriginal = (origen, destino) => sumarNumerosArrayPorCampo([origen, ...destino], "cajas_orden");

//Opciones de graficas de barra
export const optionsGraficaBarraDistribucion = (paletaColores, categories) => ({
    colors: paletaColores,
    chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        stackType: STACK_TYPE_100_PERCENT,
        toolbar: { show: false },
        animations: { enabled: false }
    },
    dataLabels: {
        colors: paletaColores,
        enabled: DATA_LABELS_ENABLED,
        formatter: (val, opt) => {
            val = separadoComas(Math.round(val));
            if (opt.w.config.chart.stackType === STACK_TYPE_100_PERCENT) { return `${val} %` }
            else { return val; }
        }
    },
    responsive: [{
        breakpoint: 480,
        options: {
            legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0,
                colors: paletaColores
            }
        }
    }],
    xaxis: { categories },
    yaxis: { decimalsInFloat: 2 },
    plotOptions: { bar: { horizontal: true } },
    fill: { opacity: 1 },
    legend: { colors: paletaColores, position: 'right', offsetX: 0, offsetY: 400 },
})//278