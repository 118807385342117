const { dosDecimales } = require("util/functions")

const generateTooltipFact = (label = "", fact, unidad = "") => {
    return `
        <div class="tooltip-data">
            ${dosDecimales(fact)} 
            <p class="tooltip-label">${unidad} ${label}</p>
        </div>
    `
}
export const generateTooltip = (title = "", unidad, data) => {
    const dataMapped = data.map(fact=>generateTooltipFact(fact.label, fact.value, unidad))
    return `
        <div class="p-tooltip-text">
            <div class="tooltip-title">${title}</div>
            <div class="tooltip-datas">
                ${dataMapped.join("")}
            </div>
        </div>
    `
}