import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGlobal } from 'reactn'
import { obtenerClientes } from '../../service/Clientes'
import { obtenerOrden } from '../../service/QueriesOrdenes'

export default function ConsultaExterna(props) {
    
    const [ciclo, setCiclo] = useGlobal("ciclo")
    const [ordenVenta, setOrdenVenta] = useGlobal("ordenVenta")
    const [, setClientes] = useGlobal("clientes")
    const { ordenId, cicloId } = props.match.params
    
    
    
    
    useEffect(() => {
        
        
        
        actualizar();
    }, [ordenId])
    
    
    const actualizar = async (params) => {
        
        let orden = await obtenerOrden(  cicloId, ordenId)

        if(!orden.empty){
            
            
            let clientes = await obtenerClientes(cicloId);
            setClientes(clientes)
            setCiclo(cicloId);
            setOrdenVenta(orden);
            props.history.push("/ordenes/consultar")
        }else{
            
            
        }
    }
    
    return (
        <div>
            Cargando
        </div>
    )
}
