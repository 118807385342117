import * as firebase from 'firebase/app';
import "firebase/firestore";
import {uploadStatistics} from './Estadisticas'

function registrarInvocaciones(count){
    uploadStatistics(count)
}

export const obtenerRegistrosCalidadInvernaderoSemana = (ciclo, invernadero, semana) => {
    return new Promise(async (resolve, reject) => {
        let result = await firebase.firestore().collection("ciclos").doc(ciclo)
            .collection("calidad_trabajo").where("invernadero_fisico_ref", "==", invernadero)
            .where("semana", "==", semana).get().catch(error => {window.alert("ERR OBT1: " + JSON.stringify(error))})
        registrarInvocaciones(result.size)

        if (result.empty)
            resolve([])
        else {
            let response = await firebase.firestore().collection("ciclos").doc(ciclo)
                .collection("calidad_trabajo").doc(result.docs[0].id).collection("registro_calidad").get().catch(error => {window.alert("ERR OBT2: " + JSON.stringify(error))})
            registrarInvocaciones(response.size)
            let registros = response.docs.map(doc => ({ id: doc.id, ...doc.data() }))
            resolve(registros)
        }
    })
}

export const obtenerRegistrosCalidadSemanaTodos = (ciclo, semana) => {
    return new Promise(async (resolve, reject) => {
       
        resolve(true)
    })
}