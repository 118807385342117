import React from 'react'
import TareaPlaneacionAgente from './TareaPlaneacionAgente'
import { useGlobal } from 'reactn';
import { withRouter } from 'react-router-dom';
import { DeepClone } from '../../util/functions';
import { getInicioDia } from '../../service/fechas';
import { getTiempoEstimadoDeTarea } from 'service/Mantenimiento';
function ListaTareas(props) {
    const [actividadesMantenimiento] = useGlobal("actividadesMantenimiento")
    const [, setTareaSeleccionada] = useGlobal("tareaSeleccionada");
    const [sitiosHTF] = useGlobal("sitiosHTF");
    const [dia, setDia] = useGlobal("dia")
    const esDiaPasado = () => dia?.unixTime < getInicioDia(Date.now());

    const consultarTarea = (tarea) => {
        // if (esDiaPasado() && !tarea.realizada) return
        setTareaSeleccionada(tarea);
        if (props.tipo === "captura") {
            props.history.push("/mantenimiento/agente/captura");
        } else {
            if (tarea["realizada"]) {
                props.setShowModalRealizada(true);
            } else {
                props.setShowModalPlaneada(true);
            }
        }
    }
    const sitiosDeEmpaqueTareasIds = props.tareas.map(tarea => tarea.sitioHTF)
    const sitiosDeEmpaqueUnicos = Array.from(new Set([...sitiosDeEmpaqueTareasIds]))

    const tareasIndexadasPorSitio = props.tareas.reduce((acc, tarea) => {
        if(acc[tarea.sitioHTF]) acc[tarea.sitioHTF].push(tarea)
        else acc[tarea.sitioHTF] = [tarea]
        return acc
    },{})

    return (
        <React.Fragment>
            <div className='card-mtto-sub'>{props.titulo}</div>
            {
                sitiosDeEmpaqueUnicos.map(sitio => {
                    const sitioData = sitiosHTF?.find(sitioHtf => sitioHtf.id === sitio) || {}
                    return (<>
                                <div className='card-mtto-sub'>{sitioData.nombre}</div>
                                <ul className='ml-05'>
                                    {tareasIndexadasPorSitio[sitio].map((tarea, index) => {
                                        if (actividadesMantenimiento) {
                                            let actividad = DeepClone(actividadesMantenimiento.find(actividad => actividad.id === tarea.actividad_ref))

                                            if (tarea.is_emergencia) {//window.alert(tarea.nombre)
                                                actividad.is_emergencia = tarea.is_emergencia
                                                actividad.tiempo_estimado = tarea.tiempo_estimado
                                                actividad.nombre = tarea.nombreTarea || "N/A"
                                            }

                                            if (tarea.tiempo_estimado) { actividad.tiempo_estimado = tarea.tiempo_estimado; }
                                            else { actividad.tiempo_estimado = getTiempoEstimadoDeTarea(actividadesMantenimiento, tarea); }

                                            if (tarea.realizada) actividad.tiempo_tomado = tarea.tiempo_tomado

                                            return <TareaPlaneacionAgente key={index} empresaId={props.empresaId} sitioId={tarea.sitioHTF} tarea={tarea} actividad={actividad}
                                                realizada={tarea.realizada} actividades={actividadesMantenimiento} onClick={() => consultarTarea(tarea)} />
                                        } else {
                                            return <div>Cargando...</div>
                                        }
                                    })}
                                </ul>
                            </>)
                })
            }
            
        </React.Fragment>
    )
}
export default withRouter(ListaTareas);