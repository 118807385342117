import React, { useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from "primereact/button";
import { useGlobal } from "reactn";
import { Message } from 'primereact/message';
import { Dropdown } from 'primereact/dropdown';
import ContenedorInput from '../../common/ContenedorInput';
import { obtenerNombreFechaCompacto, getFormatCalendar, getMinutesByTimestamp, transformISOToCalendarFormat } from '../../../service/fechas';
import { guardarActividad, eliminarActividad, transferirTareaAFecha, getTiempoEstimadoDeTarea } from '../../../service/Mantenimiento';
import { DeepClone, empresaIsHTF, formatearMinutosAHoras, getLocacionAgraparkID, getNombrePorID, getNombreUsuarioPorID, dateToUnixTimeAsNumber13 } from '../../../util/functions';
import SeccionDatosCenter from '../../common/SeccionDatosCenter';
import DatoCard from '../../common/cards/DatoCard';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { obtenerFiltrosMantenimientoTodos } from '../../../service/Requisiciones';
import { filtrarEmpresasInternos } from '../../../service/Empresas';
import { Calendar } from 'primereact/calendar';
import { estamosEnProduccion } from '../../../constants';
const estadoInicial = {
    empresa: "",
    categoria: "",
    subcategoria: "",
    actividad: "",
    sitioHTF: ""
}
export default function ModalTareaPlaneada(props) {
    const [empresas, setEmpresas] = useGlobal("empresas");
    const [cicloID, setcicloID] = useGlobal("ciclo");
    const [actividades, setActividades] = useGlobal("actividadesMantenimiento");
    const [categorias, setCategorias] = useGlobal("categorias");
    const [cargando, setCargando] = useGlobal("cargando");

    const [agente, setAgente] = useGlobal("agente");
    const [tareaSeleccionada, setTareaSeleccionada] = useGlobal("tareaSeleccionada");
    const [state, setState] = useState({ ...estadoInicial })
    const [inicializando, setInicializando] = useState(false)
    const [tareaID, setTareaID] = useState(null);
    const [errores, setErrores] = useState({})
    const [, setTriggerReload] = useGlobal("triggerReload");

    //opciones de filtros
    const [ordenesRefs] = useGlobal("ordenesRefsMant")
    const [urgencias] = useGlobal("urgenciasMant")
    const [requerimientos] = useGlobal("requerimientosMant")
    const [locaciones, setLocaciones] = useGlobal("locacionesPlanMant")
    const [usuarios] = useGlobal("usuarios")
    const [invernaderosFisicos] = useGlobal("invernaderosFisicos")
    const [datosCargados,] = useGlobal("datosCargadosMant")
    const [sitiosHTF] = useGlobal("sitiosHTF");

    useEffect(() => {
        if (invernaderosFisicos && !datosCargados) obtenerFiltrosMantenimientoTodos(null, invernaderosFisicos)
    }, [invernaderosFisicos, datosCargados])
    useEffect(() => {
        if (!props.show) setState({ ...estadoInicial })
    }, [props.show])
    useEffect(() => {
        setErrores({})
    }, [state, props.show])

    useEffect(() => {
        if (tareaSeleccionada) {
            adaptarAConsulta();
            setTareaID(tareaSeleccionada?.id);
            setTareaSeleccionada(null);
        }
    }, [tareaSeleccionada])
    const adaptarAConsulta = (params) => {
        setInicializando(true)
        let empresa = empresas.find(e => e.id === tareaSeleccionada?.empresa_ref);
        let actividad = actividades.find(a => a.id === tareaSeleccionada?.actividad_ref);
        let categoria = categorias.find(c => c.id === actividad.categoria_ref);

        let subcategoria = categoria.subcategorias.find(s => s.id === actividad.subcategoria_ref);
        let data = {
            empresa: empresa["id"],
            categoria: categoria["id"],
            subcategoria: subcategoria["id"],
            actividad: actividad["id"],
            actividad_ref: actividad["id"],
            sitioHTF: tareaSeleccionada.sitioHTF,
            tiempo_estimado: getTiempoEstimadoDeTarea(actividades, tareaSeleccionada)// tareaSeleccionada.tiempo_estimado,
        }

        if (tareaSeleccionada?.is_emergencia) {
            data.is_emergencia = tareaSeleccionada.is_emergencia
            data.nombreTarea = tareaSeleccionada.nombreTarea
        }
        else if (tareaSeleccionada?.is_requisicion) data = { ...tareaSeleccionada, ...data }
        setState(data)
    }

    useEffect(() => {
        //filtrar opciones de actividades a aquellas permitidas por subcategoria
        if (state.actividad && !state?.is_requisicion) {
            setState({ ...state, tiempo_estimado: getTiempoEstimadoDeTarea(actividades, state) });
        }
    }, [state.actividad])

    useEffect(() => {
        if (!inicializando)
            setState(prevState => ({ ...prevState, actividad: [] }))
    }, [state.subcategoria, state.categoria])

    const handleGuardar = async () => {
        if (validarErrores() && !state.is_requisicion) {
            console.log("entra al if")
            setCargando(true);
            let actividad = { actividad_ref: state.actividad, agente_ref: agente.id, dia: props.dia.unixTime, empresa_ref: state.empresa, sitioHTF: state.sitioHTF, tiempo_estimado: state.tiempo_estimado }
            if (state.is_emergencia) actividad = { ...actividad, nombreTarea: state.nombreTarea, tiempo_estimado: 0, is_emergencia: true }
            if (state.is_requisicion) actividad = { ...state, ...actividad, is_requisicion: true }

            if (state.is_tiempo_estimado_input) {
                actividad.tiempo_estimado = state.tiempo_estimado;
                actividad.tiempo_estimado_manualmente = Date.now();
            }

            props.hide();
            actividad = await guardarActividad(cicloID, actividad, tareaID);
            let agenteCopy = DeepClone(agente)
            let indexEditing = agente.tareas.findIndex(tarea => tarea.id === tareaID)
            agenteCopy.tareas[indexEditing] = actividad
            setAgente(agenteCopy)
            setCargando(false);
        } else if (state.is_requisicion) {
            console.log("Entra")
            setCargando(true);
            props.hide();
            const datos = { tiempo_estimado: state.tiempo_estimado, dia: props.dia.unixTime }
            await guardarActividad(cicloID, datos, tareaID);

            let agenteCopy = DeepClone(agente)
            let indexEditing = agente.tareas.findIndex(tarea => tarea.id === tareaID)
            agenteCopy.tareas[indexEditing].tiempo_estimado = state.tiempo_estimado
            setAgente(agenteCopy)
            setCargando(false);
        }
    }

    const validarErrores = () => {
        let errores = {}, required = ["empresa", "categoria", "subcategoria"]
        required.forEach(field => {
            if (!state[field]) errores[field] = true

        })
        if (state["actividad"].length === 0) errores["actividades"] = true
        if (getNombrePorID(empresas, state.empresa) === "HTF" && !state.sitioHTF) errores["sitioHTF"] = true

        setErrores(errores)
        return Object.keys(errores).length === 0
    }

    const handleCancelar = () => {
        setTriggerReload(true);
        props.hide()
    }

    const handleChange = (e) => {
        setInicializando(false);
        let copia = { ...state }, { name, value } = e.target;
        copia[name] = value;

        if (name === "empresa" || name === "sitioHTF") {
            if (!empresaIsHTF(empresas, value) && name === "empresa") { copia.sitioHTF = getLocacionAgraparkID(sitiosHTF); }
            if (empresaIsHTF(empresas, value) && name === "empresa") { copia.sitioHTF = sitiosHTF?.[0]?.id || ""; }
            delete copia.is_tiempo_estimado_input;
            delete copia.tiempo_estimado;
            copia.tiempo_estimado = getTiempoEstimadoDeTarea(actividades, copia);
        }
        setState(copia);
    }

    const handleChangeCategoria = (e) => {
        handleChange(e)
        let copia = { ...state }
        copia[e.target.name] = e.target.value
        copia["subcategoria"] = ""
        copia["actividad"] = ""
        setState(copia)

    }

    const handleChangeSubcategoria = (e) => {
        handleChange(e)
        let copia = { ...state }
        copia[e.target.name] = e.target.value
        copia["actividad"] = ""
        setState(copia)

    }

    const handleEliminar = async (params) => {
        setCargando(true)
        props.hide();
        await eliminarActividad(cicloID, tareaID);

        let agenteCopy = agente
        let indexEditing = agente.tareas.findIndex(tarea => tarea.id === tareaID)
        agenteCopy.tareas.splice(indexEditing, 1)
        setAgente(agenteCopy)

        setCargando(false)
    }
    const getCategoriaOpciones = () => {
        if (state.is_emergencia) return categorias.filter(el => el.id === "category_emergency")
        else if (state.is_requisicion) return categorias.filter(el => el.id === "cat_other_requisicion")
        else return categorias.filter(el => el.id !== "category_emergency" && el.id !== "cat_other_requisicion")
    }
    const getSubcategoriasOpciones = () => {
        const subcatsTodos = categorias.map(el => el.subcategorias).flat();
        const subcats = subcatsTodos.map(el => ({ ...el, nombre: `${el?.indice} - ${el?.nombre}` }));
        return subcats.length > 0 ? subcats.filter(el => !el.sinMostrar && el.categoria_ref === state.categoria) : [];
    }
    const getActividadesOpciones = () => {
        if (actividades?.length > 0) {
            const filtered = actividades.filter(a => !a.sinMostrar)
            const opcionesActs = filtered.map(el => ({ ...el, nombre: `${el.indice} - ${el.nombre}` }))
            return opcionesActs.filter(a => a.subcategoria_ref === state.subcategoria)
        } else return []
    }
    const handleChangeInputEstimatedTime = (event) => {
        const stateCopy = DeepClone(state)
        const nativeValue = event.originalEvent.target.value
        const calendarValue = event.target.value

        stateCopy.is_tiempo_estimado_input = true

        if (nativeValue) {
            const time = transformISOToCalendarFormat(nativeValue)
            stateCopy.tiempo_estimado = getMinutesByTimestamp(time === NaN ? 0 : time)
        } else {
            const zeroValue = new Date()
            zeroValue.setHours(0)
            zeroValue.setMinutes(0)
            zeroValue.setSeconds(0)
            const newCalendarValue = calendarValue || zeroValue
            stateCopy.tiempo_estimado = getMinutesByTimestamp(dateToUnixTimeAsNumber13(newCalendarValue))
        }
        setState(stateCopy)
    }
    const sinCompletar = () => false// !state.subcategoria || !state.actividad

    const footer = <React.Fragment>
        <Button type="button" className="btn btn-secondary" onClick={handleCancelar} label="Cancelar" />
        <Button type="button" className="btn btn-secondary" onClick={handleEliminar} label="Eliminar" />
        {//!state?.is_emergencia && <Button type="button" className="btn btn-secondary" onClick={handleTransferir} label="Transferir" />
        }
        <Button type="button" disabled={sinCompletar()} className="btn btn-secondary" onClick={handleGuardar} label="Guardar actividad" />
    </React.Fragment>
    return (
        <Dialog className="modal-35" header={`${state.is_emergencia ? "Emergencia" : state.is_requisicion ? "Tarea de requisición" : "Actividad"} en proceso`} style={{ maxWidth: '50vw' }} footer={footer} visible={props.show} onHide={props.hide} blockScroll={false}>
            <>  <div className="p-grid ">
                <ContenedorInput label="Empresa" col={12}>
                    <Dropdown value={state.empresa} options={filtrarEmpresasInternos(empresas)} disabled={state.is_requisicion}
                        onChange={handleChange} name="empresa" optionLabel="nombre" optionValue="id" />
                    {Object.keys(errores).includes("empresa") && <Message severity="error" text="Este campo es requerido" />}
                </ContenedorInput>

                {getNombrePorID(empresas, state.empresa) === "HTF" && !state.is_requisicion &&
                    <ContenedorInput label="Locación">
                        <Dropdown name="sitioHTF" value={state.sitioHTF} options={sitiosHTF} onChange={handleChange} optionLabel="nombre" optionValue="id" />
                        {errores.sitioHTF && <Message severity="error" text="Este campo es requerido" />}
                    </ContenedorInput>}

                <ContenedorInput label="Categoria" col={12}>
                    <Dropdown value={state.categoria} disabled={state.is_emergencia || state.is_requisicion} options={getCategoriaOpciones()} onChange={handleChangeCategoria} name="categoria" optionLabel="nombre" optionValue="id" />
                    {Object.keys(errores).includes("categoria") && <Message severity="error" text="Este campo es requerido" />}
                </ContenedorInput>

                {!state.is_emergencia && !state.is_requisicion &&
                    <ContenedorInput label="Subcategoria" col={12}>
                        <Dropdown value={state.subcategoria} options={getSubcategoriasOpciones()} onChange={handleChangeSubcategoria} name="subcategoria" optionLabel="nombre" optionValue="id" />
                        {Object.keys(errores).includes("subcategoria") && <Message severity="error" text="Este campo es requerido" />}
                    </ContenedorInput>}
                {!state.is_emergencia && !state.is_requisicion &&
                    <ContenedorInput label="Actividades" col={12}>
                        <Dropdown value={state.actividad} options={getActividadesOpciones()} onChange={handleChange} name="actividad" optionLabel="nombre" optionValue="id" />
                        {Object.keys(errores).includes("actividades") && <Message severity="error" text="Este campo es requerido" />}
                    </ContenedorInput>}

                {!state.is_emergencia &&
                    <ContenedorInput label="Tiempo estimado" col={12}>
                        <Calendar id="tiempo_estimado" readOnlyInput={false} timeOnly={true} hourFormat="24"
                            name="tiempo_estimado" value={getFormatCalendar(state.tiempo_estimado)} onChange={handleChangeInputEstimatedTime} />
                    </ContenedorInput>}
                {(state.is_emergencia || state.is_requisicion) &&
                    <ContenedorInput label="Nombre tarea" col={(state.cantidad && state.is_requisicion) ? 8 : 12}>
                        <InputText disabled={state.is_requisicion} name="nombreTarea" value={state.nombreTarea} onChange={handleChange} />
                    </ContenedorInput>}
                {state.cantidad && state.is_requisicion &&
                    <ContenedorInput label="Cantidad" col={4}>
                        <InputText disabled={true} name="nombreTarea" value={state.cantidad} onChange={handleChange} />
                    </ContenedorInput>
                    /**/}

                {state.is_requisicion && <>
                    <ContenedorInput label="Solicitante" col={4}  >
                        <InputText disabled={true} value={getNombreUsuarioPorID(usuarios, state.solicitante)} />
                    </ContenedorInput>
                    <ContenedorInput label="Urgencia" col={4} >
                        <Dropdown disabled={true} value={state.urgencia} options={urgencias} optionValue="id" optionLabel="nombre" />
                    </ContenedorInput>
                    <ContenedorInput label="Locación" col={4}>
                        <Dropdown disabled={true} value={state.locacion} options={locaciones} optionValue="id" optionLabel="nombre" />
                    </ContenedorInput>
                    <ContenedorInput label="Orden de referencia" col={6} >
                        <Dropdown disabled={true} value={state.orden_ref} options={ordenesRefs} optionValue="id" optionLabel="nombre" />
                    </ContenedorInput>
                    <ContenedorInput label="Requerimiento" col={6} >
                        <Dropdown disabled={true} value={state.requerimiento} options={requerimientos} optionValue="id" optionLabel="nombre" />
                    </ContenedorInput>

                    <ContenedorInput label="Detalle" col={12} >
                        <InputTextarea disabled={true} value={state.detalle} rows={3} />
                    </ContenedorInput>
                </>}
            </div>
                {!state.is_emergencia && !estamosEnProduccion &&
                    <SeccionDatosCenter>
                        <DatoCard label="Tiempo estimado actividad" valor={formatearMinutosAHoras(state.tiempo_estimado)} />
                    </SeccionDatosCenter>}</>
        </Dialog >
    )
}//160