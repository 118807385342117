import React, { useEffect, useState } from 'react';
import { useGlobal } from "reactn";
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { SelectButton } from 'primereact/selectbutton';
import { obtenerListaDeSemanas, obtenerInicioSemanaDia, obtenerNombreFechaCompacto } from '../../service/fechas';
import { obtenerEnfermedadesYPlagas, obtenerInfoDeResumen, obtenerRegistroEnfermedades } from '../../service/biocontrol';
import { DETALLES_TIPO_INCIDENCIA, NIVEL_INCIDENCIA_POR_DEFECTO, POLOS_INVERNADERO, POLO_NORTE, segundosSemana } from '../../constants';
import ResumenDeEnfermedadesModal from "./modals/ResumenDeEnfermedadesModal"
import ResumenDeIncidenciasModal from "./modals/ResumenDeIncidenciasModal"
import MapaInvernadero from "../common/mapa_invernadero/MapaInvernadero"
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import ContenedorFiltro from '../common/ContenedorFiltro'
import SeccionFiltros from '../common/SeccionFiltros'
import { Button } from 'primereact/button';
import ContenedorHeader from '../common/ContenedorHeader';
import { getObjetoPorID } from '../../util/functions';
import SelectorEnfermedadesModal from './modals/SelectorEnfermedadesModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import SeccionFormularioCard from 'components/common/SeccionFormularioCard';
import ContenedorInput from 'components/common/ContenedorInput';

const MapaInvernaderoBiocontrol = (props) => {
    const modosConsultaOpciones = [
        { id: "enfermedades-plagas", nombre: "Enfermedades y plagas" },
        { id: "nivel-incidencia", nombre: "Nivel de incidencia" }
    ]
    const nivelesIncidencia = [
        { id: 'ni-a', nombre: 'Bajo', nivel: 1 },  // i:0
        { id: 'ni-b', nombre: 'Medio', nivel: 2 }, // i:1
        { id: 'ni-c', nombre: 'Alto', nivel: 3 },  // i:2
        { id: 'ni-d', nombre: 'Grave', nivel: 4 }  // i:3
    ]

    const [, setCargando] = useGlobal("cargando")
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [ciclo] = useGlobal("ciclo")
    const [invernaderos] = useGlobal("invernaderosFisicos")
    const [scale] = useGlobal("mapaBiocontrolTransformScale")
    const [enfermedades] = useGlobal("enfermedadesYPlagas")
    const [invernaderoSeleccionado, setInvernaderoSeleccionado] = useGlobal("invernaderoFisico");

    const [mostrarResumenDeEnfermedades, setMostrarResumenDeEnfermedades] = useState(false)
    const [mostrarResumenDeIncidencias, setMostrarResumenDeIncidencias] = useState(false)
    const [claroSeleccionado, setClaroSeleccionado] = useState({ enfermedades: [], enfermedadesPasadas: [] })
    const [datosCargados, setDatosCargados] = useState(false)
    const [filtrosMapaCargados, setFiltrosMapaCargados] = useState(false)


    const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector()
    const [semanaPasadaSeleccionada, setSemanaPasadaSeleccionada] = useState({})
    const [modoConsulta, setModoConsulta] = useState(modosConsultaOpciones[0].id)
    const [enfermedadesDeLaSemana, setEnfermedadesDeLaSemana] = useState([])
    const [enfermedadesFiltros, setEnfermedadesFiltros] = useState([])
    const [registroSemana, setRegistroSemana] = useState([])
    const [registroSemanaPasada, setRegistroSemanaPasada] = useState([])
    const [enfermedadIncidencia, setEnfermedadIncidencia] = useState("")
    const [nivelesIncidenciaSeleccionados, setNivelesIncidenciaSeleccionados] = useState(nivelesIncidencia)
    const [mapa, setMapa] = useState([])
    const [enfermedadesModalOpen, setEnfermedadesModalOpen] = useState(false)
    const [dataCard, setDataCard] = useState({})

    const modoConsultaEnfermedades = () => modoConsulta === "enfermedades-plagas"

    useEffect(() => {
        setNombreModulo("Biocontrol")
        setHabilitadoSelectorCiclo(true);
    }, [])

    useEffect(() => {
        setCargando(true)
        
        if (invernaderos)
            updateCicloWeekSelector(ciclo);
    }, [invernaderos])
    
    useEffect(() => {
        if (!datosCargados && week.nombre !== "Cargando")
            obtenerDatosIniciales()
        else if (datosCargados)
            filtrarMapa()
    }, [datosCargados, invernaderoSeleccionado, week,invernaderos]);

    useEffect(() => {
        if (filtrosMapaCargados)
            generateInvernaderoMap(registroSemana, registroSemanaPasada, enfermedadesFiltros)
    }, [enfermedadesFiltros, enfermedadIncidencia, nivelesIncidenciaSeleccionados, filtrosMapaCargados]);
    useEffect(() => {
        if (Array.from(mapa).length > 0) setCargando(false)
    }, [mapa]);

    const obtenerDatosIniciales = async () => {
        if (!enfermedades) await obtenerEnfermedadesYPlagas()
        
        if(invernaderos){
            let invernadero = invernaderoSeleccionado || invernaderos[0]
            setInvernaderoSeleccionado(invernadero)
            setDatosCargados(true)
        }
    }
    const obtenerRegistrosActualYPasada = async (invernaderoID) => {
        let registroClaros = await obtenerRegistroEnfermedades(ciclo, invernaderoID, week.time, [], false)
        let registroClarosPasadas = await obtenerRegistroEnfermedades(ciclo, invernaderoID, (week.time - segundosSemana), [], false)
        setDataCard(obtenerInfoDeResumen(invernaderoSeleccionado, enfermedades, registroClaros))
        return { registroClaros, registroClarosPasadas }
    }

    const filtrarMapa = async () => {
        setCargando(true)
        setFiltrosMapaCargados(false)

        let { registroClaros, registroClarosPasadas } = await obtenerRegistrosActualYPasada(invernaderoSeleccionado.id)
        setRegistroSemana(registroClaros)
        setRegistroSemanaPasada(registroClarosPasadas)
        // emfermedades a incluir en los filtros
        let enfermedadesEnRegistro = []
        let nivelIncidenciaMasAlto = -1
        let enfermedadIncidenciaMasAltoRef = ""

        registroClaros.forEach(claro => {
            claro.enfermedades.forEach(enfermedad => {
                if (enfermedad.nivel_incidencia > nivelIncidenciaMasAlto) {
                    enfermedadIncidenciaMasAltoRef = enfermedad.enfermedad_ref
                    nivelIncidenciaMasAlto = enfermedad.nivel_incidencia
                }
                enfermedadesEnRegistro.push(enfermedad.enfermedad_ref)
            });
        });
        registroClarosPasadas.forEach(claro => {
            enfermedadesEnRegistro.push(...claro.enfermedades.map(enf => enf.enfermedad_ref))
        });

        let enfermedadesRegistrados = new Set(enfermedadesEnRegistro)
        enfermedadesRegistrados = Array.from(enfermedadesRegistrados)
        enfermedadesRegistrados = enfermedadesRegistrados.map(enf => getObjetoPorID(enfermedades, enf))
        setEnfermedadesDeLaSemana(enfermedadesRegistrados)
        setEnfermedadesFiltros(enfermedadesRegistrados)
        if (enfermedadesRegistrados.length > 0)
            setEnfermedadIncidencia(getObjetoPorID(enfermedades, enfermedadIncidenciaMasAltoRef))

        let indexWeek = weeks.findIndex(el => el.time === week.time)
        let semanaPasada = indexWeek > 0 ? weeks[(indexWeek - 1)] : weeks[0]
        setSemanaPasadaSeleccionada(semanaPasada)

        setFiltrosMapaCargados(true)
    }
    const generateInvernaderoMap = (registroSemana, registroSemanaPasada, enfermedadesPorVisualizar) => {
        setCargando(true)
        let mapaInv = []

        invernaderoSeleccionado.capillas.forEach((capilla, capillaIndex) => {

            var capillaCompleta = [];
            POLOS_INVERNADERO.forEach((polo) => {
                let clarosPorLinea = polo.toLowerCase() == POLO_NORTE ? invernaderoSeleccionado.claros_por_linea_norte : invernaderoSeleccionado.claros_por_linea_sur
                var lineasPorCapilla = polo.toLowerCase() == POLO_NORTE ? capilla.lineas_norte : capilla.lineas_sur

                var zonaCapilla = []
                for (let lineaIndex = 0; lineaIndex < lineasPorCapilla; lineaIndex++) {
                    var linea = []
                    for (let claroIndex = 0; claroIndex < clarosPorLinea; claroIndex++) {

                        linea.push({
                            coordenada: { cPolo: polo.toLowerCase(), cCapilla: capillaIndex, cLinea: lineaIndex, cClaro: claroIndex },
                            enfermedades: obtenerEnfermedadesDeClaro(capillaIndex, polo, lineaIndex, claroIndex, registroSemana, enfermedadesPorVisualizar),
                            enfermedadesPasadas: obtenerEnfermedadesDeClaro(capillaIndex, polo, lineaIndex, claroIndex, registroSemanaPasada, enfermedadesPorVisualizar),
                            enfermedad: obtenerNivelesIncidenciaDeClaro(capillaIndex, polo, lineaIndex, claroIndex, registroSemana, enfermedadIncidencia),
                            enfermedadPasada: obtenerNivelesIncidenciaDeClaro(capillaIndex, polo, lineaIndex, claroIndex, registroSemanaPasada, enfermedadIncidencia)
                        })
                    }
                    zonaCapilla.push(linea)
                }

                var zonaCapillaTranspuesta = []
                for (let i = 0; i < zonaCapilla[0].length; i++) {
                    var linea = []
                    for (let j = 0; j < zonaCapilla.length; j++) {
                        linea.push({ ...zonaCapilla[j][i] })
                    }
                    zonaCapillaTranspuesta.push(linea);
                }

                capillaCompleta.push({ nombre: polo, contenido: zonaCapillaTranspuesta })
            });

            mapaInv.push(capillaCompleta)
        });

        setMapa(mapaInv)
    }

    const makeCssClassForPrioridadEnfermedades = (enfermedades, enfermedadesPasadas) => {
        let priordades = []
        let priordadesPasadas = []
        enfermedades.forEach((enfermedad) => { priordades.push(`prioridad-${enfermedad.prioridad}`) });
        enfermedadesPasadas.forEach((enfermedad) => { priordadesPasadas.push(`prioridad-${enfermedad.prioridad}`) });
        let priordadesSet = new Set(priordades)
        let priordadesPasadasSet = new Set(priordadesPasadas)
        let cssClass = ''
        Array.from(priordadesSet).forEach((prioridad) => {
            cssClass += `actual-${prioridad} `
        });
        Array.from(priordadesPasadasSet).forEach((prioridad) => {
            cssClass += `pasada-${prioridad} `
        });
        return cssClass.substring(0, cssClass.length - 1)
    }

    const makeCssClassForNombreEnfermedad = (nombreEnfermedad) => nombreEnfermedad
        ? nombreEnfermedad.toLowerCase().replace(/ /g, '-').replace(/[\s.,:()\/]/g, '') : ''

    const makeCssClassForEnfermedadesImportantes = (enfermedadesActuales, enfermedadesPasadas) => {
        var enfermedadesImportantesActualesCounter = 0
        enfermedadesImportantesActualesCounter = enfermedadesActuales.filter((enfermedad) => enfermedad.prioridad === 1 || enfermedad.prioridad === 2).length
        var enfermedadesImportantesPasadasCounter = 0
        enfermedadesImportantesPasadasCounter = enfermedadesPasadas.filter((enfermedad) => enfermedad.prioridad === 1 || enfermedad.prioridad === 2).length
        if (enfermedadesImportantesActualesCounter === 0 && enfermedadesImportantesPasadasCounter === 0) return ''
        return `${enfermedadesImportantesActualesCounter !== 0 ? 'enfermedades-importantes-' + enfermedadesImportantesActualesCounter : ''} ${enfermedadesImportantesPasadasCounter !== 0 ? 'enfermedades-pasadas-importantes-' + enfermedadesImportantesPasadasCounter : ''}`
    }

    const makeNombrePrioridadEnfermedad = (enfermedad) => {
        if (!enfermedad) return ""
        if (!enfermedad.prioridad) return ""
        return enfermedad.prioridad === 1 ? "importante" : "normal"
    }

    const generarDetallesDeEnfermedadesEnClaroSeleccionado = () => {
        let actuales = claroSeleccionado.enfermedades.map(registro => ({
            ...registro, fechaRegistro: obtenerNombreFechaCompacto(registro.fechaRegistro),
        }))
        let pasadas = claroSeleccionado.enfermedadesPasadas.map(registro => ({
            ...registro, fechaRegistro: obtenerNombreFechaCompacto(registro.fechaRegistro),
        }))
        return { actuales, pasadas }
    }

    const semanaSinRegistroDeEnfermedades = () => enfermedadesDeLaSemana.length === 0
    const claroSinRegistros = (claro) => (!claro.enfermedades && !claro.enfermedadesPasadas) ? true : (claro.enfermedades.length === 0 && claro.enfermedadesPasadas.length === 0)

    const obtenerEnfermedadesDeClaro = (capillaIndex, polo, lineaIndex, claroIndex, registroSemana, enfermedadesPorVisualizar) => {
        let enfermedadesDeClaro = []

        registroSemana.forEach(claro => {
            if (capillaIndex == claro.c_capilla && polo == claro.c_polo && lineaIndex == claro.c_linea && claroIndex == claro.c_claro) {

                claro.enfermedades.forEach(enfRef => {
                    let enfermedad = getObjetoPorID(enfermedadesPorVisualizar, enfRef.enfermedad_ref)
                    let nivelIncidencia = enfRef.nivel_incidencia || NIVEL_INCIDENCIA_POR_DEFECTO
                    if (enfermedad) enfermedadesDeClaro.push({ fechaRegistro: enfRef.fecha_registro, ...enfermedad, nivelIncidencia })
                });
            }
        });

        return enfermedadesDeClaro
    }

    const obtenerNivelesIncidenciaDeClaro = (capillaIndex, polo, lineaIndex, claroIndex, registroSemana, enfermedadPorVisualizar) => {
        if (!enfermedadPorVisualizar) return
        let enfermedadDeClaro = {}

        registroSemana.forEach(claro => {
            if (capillaIndex == claro.c_capilla && polo == claro.c_polo && lineaIndex == claro.c_linea && claroIndex == claro.c_claro) {

                claro.enfermedades.forEach(enfRef => {
                    let enfermedadId = enfRef.enfermedad_ref
                    let enfermedad = enfermedadPorVisualizar.id == enfermedadId
                    let nivelIncidencia = enfRef.nivel_incidencia || NIVEL_INCIDENCIA_POR_DEFECTO
                    let nivelIncidenciaSeleccionado = nivelesIncidenciaSeleccionados.some((ni) => nivelIncidencia === ni.nivel)
                    if (enfermedad && nivelIncidenciaSeleccionado)
                        enfermedadDeClaro = { nivelIncidencia, ...enfermedad }
                });

            }
        });

        return enfermedadDeClaro
    }

    const abrirResumenDeClaro = (claro) => {
        if (claroSinRegistros(claro)) return

        setClaroSeleccionado(claro)
        setMostrarResumenDeEnfermedades(modoConsultaEnfermedades())
        setMostrarResumenDeIncidencias(!modoConsultaEnfermedades())
    }

    return (
        <div className="p-grid p-justify-center">
            {invernaderoSeleccionado && <ContenedorHeader col="p-col p-col-auto" titulo="Mapa de Plagas" subtituloInv={""} invernaderoNombre={invernaderoSeleccionado.nombre}
                iconos={`icon-general icon-id bor-${invernaderoSeleccionado.color} bg-${invernaderoSeleccionado.color} `} atras={() => props.history.goBack()} />}

            <div className="p-col p-col-auto">
                <div className="btn-row">
                    <Button className="reactbtn-icon" label="Crear nueva orden"
                        onClick={() => setEnfermedadesModalOpen(true)} />
                    <Button className="reactbtn-icon" label="Órdenes de aplicación"
                    onClick={() => props.history.push("/biocontrol/ordenes_aplicacion/")} />
                    <Button className="reactbtn-icon" onClick={() => { props.history.push({pathname: "/biocontrol/graficas/claros-afectados", data: {invernadero: invernaderoSeleccionado}}) }} label="Gráfica resumen de claros afectados">
                        <FontAwesomeIcon icon={faChartBar} />
                    </Button>
                </div>
            </div>
            <SeccionFiltros >
                <ContenedorFiltro label="Modo de consulta" filtrolabel="no">
                    <SelectButton id="modo-consulta" optionValue="id" optionLabel="nombre"
                        options={modosConsultaOpciones} value={modoConsulta} onChange={(e) => setModoConsulta(e.value)} />
                </ContenedorFiltro>

                <ContenedorFiltro label="Invernadero" filtrolabel="no">
                    <Dropdown id="filtroInvernadero" placeholder="Seleccionar" dataKey="id" optionLabel="nombre"
                        options={invernaderos} value={invernaderoSeleccionado} onChange={(e) => { setInvernaderoSeleccionado(e.value) }} />
                </ContenedorFiltro>

                <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />

                <ContenedorFiltro label="Enfermedad" filtrolabel="no">
                    {modoConsultaEnfermedades()
                        ? <MultiSelect id="filtroEnfermedades" disabled={semanaSinRegistroDeEnfermedades()} dataKey="id" optionLabel="nombre" fixedPlaceholder={true} filter={true}
                            placeholder={semanaSinRegistroDeEnfermedades() ? 'N/A (sin registro)' : `${enfermedadesFiltros.length}/${enfermedadesDeLaSemana.length} enfermedades`}
                            options={enfermedadesDeLaSemana} value={enfermedadesFiltros} onChange={(e) => { setEnfermedadesFiltros(e.value) }} />
                        : <Dropdown id="filtroEnfermedad" disabled={semanaSinRegistroDeEnfermedades()} dataKey="id" optionLabel="nombre"
                            placeholder={semanaSinRegistroDeEnfermedades() ? 'N/A (sin registro)' : 'Seleccionar'}
                            options={enfermedadesDeLaSemana} value={enfermedadIncidencia} onChange={(e) => { setEnfermedadIncidencia(e.value) }} />}
                </ContenedorFiltro>

                {!modoConsultaEnfermedades() &&
                    <ContenedorFiltro label="Nivel de incidencia" filtrolabel="no">
                        <MultiSelect id="filtroNivelIncidencia" disabled={semanaSinRegistroDeEnfermedades()} dataKey="id" optionLabel="nombre" fixedPlaceholder={true} filter={false}
                            placeholder={semanaSinRegistroDeEnfermedades() ? 'N/A (sin registro)' : `${nivelesIncidenciaSeleccionados.length} seleccionados`}
                            options={nivelesIncidencia} value={nivelesIncidenciaSeleccionados} onChange={(e) => { setNivelesIncidenciaSeleccionados(e.value) }} />
                    </ContenedorFiltro>}
            </SeccionFiltros >

            <SeccionFormularioCard titulo="Información general" col={12}>
                <ContenedorInput col="2" sinFilrar label="Número de enfermedades" >
                <div >{dataCard?.enfermedades}</div>
                </ContenedorInput>
                <ContenedorInput col="2" sinFilrar label="Afectación porcentual" >
                <div >{dataCard?.incidencia}%</div>
                </ContenedorInput>
                <ContenedorInput col="2" sinFilrar label="Cáncer bacteriano" >
                <div >{dataCard?.cancer}%</div>
                </ContenedorInput>
                <ContenedorInput col="2" sinFilrar label="Cáncer bacteriano" >
                <div >{dataCard?.cancer ? 'Sí' : 'No'}</div>
                </ContenedorInput>
                <ContenedorInput col="2" sinFilrar label="Virus del mosaico" >
                <div >{dataCard?.virus_mosaico}%</div>
                </ContenedorInput>
                <ContenedorInput col="2" sinFilrar label="Virus del mosaico" >
                <div >{dataCard?.virus_mosaico ? 'Sí' : 'No'}</div>
                </ContenedorInput>
            </SeccionFormularioCard>


            {mapa.length > 0 && <MapaInvernadero mapa={mapa} nombreModoConsulta={modoConsulta} modoEnfermedades={modoConsultaEnfermedades()}
                nombrePrioridad={makeNombrePrioridadEnfermedad(enfermedadIncidencia)} cssPrioridad={makeCssClassForPrioridadEnfermedades}
                abrirResumenDeClaro={(claro) => abrirResumenDeClaro(claro)} registrosImportantes={makeCssClassForEnfermedadesImportantes}
                nombreRegistro={makeCssClassForNombreEnfermedad} />}


            <div className={`zoomLevel-${scale} p-col-12`}>
                <ResumenDeEnfermedadesModal visible={mostrarResumenDeEnfermedades} hide={() => setMostrarResumenDeEnfermedades(false)}
                    detallesEnfermedades={generarDetallesDeEnfermedadesEnClaroSeleccionado()} week={week}
                    semanaPasadaSeleccionada={semanaPasadaSeleccionada} claroSeleccionado={claroSeleccionado} makeCssClass={makeCssClassForNombreEnfermedad} />

                <ResumenDeIncidenciasModal visible={mostrarResumenDeIncidencias} hide={() => setMostrarResumenDeIncidencias(false)}
                    detallesEnfermedades={generarDetallesDeEnfermedadesEnClaroSeleccionado()} week={week}
                    semanaPasadaSeleccionada={semanaPasadaSeleccionada} claroSeleccionado={claroSeleccionado} makeNombre={makeNombrePrioridadEnfermedad} />
            </div>
            <SelectorEnfermedadesModal visible={enfermedadesModalOpen} hide={() => setEnfermedadesModalOpen(false)}
                enfermedades={enfermedades} invID={invernaderoSeleccionado?.id} invernadero={invernaderoSeleccionado} />
        </div>
    );
}
export default MapaInvernaderoBiocontrol;