import React, { useEffect, useState, useGlobal } from 'reactn';
import { obtenerCiclo } from '../../service/Ciclos';
import { obtenerActividadesImportantesEmpleadoCiclo } from '../../service/nomina';
import { obtenerActividadesImportantes, obtenerActividades } from '../../service/actividades';
import { colorAleatorio, dosDecimales, getNombrePorID, getObjetoPorID, obtenerRangoColores, ordenarArreglaDeObjetosPorLlave } from '../../util/functions';
import { obtenerListaDeSemanas } from '../../service/fechas';
import StackedChart from '../common/StackedChart'
import {
  actividadesEnrede, actividadesClipeo, nombreActividadClipeo,
  nombreActividadClipeoPlus, nombreActividadEnrede, nombreActividadEnredePlus
} from '../../constants';
import { obtenerEmpleado, obtenerEmpleados, obtenerListaEmpleadosInvernadero, obtener_empleados_ciclo_actual } from '../../service/RhService';
import { Dropdown } from 'primereact/dropdown';
import { obtenerRolesInvernadero } from 'service/Empaques';

const ActividadesEmpleado = (props) => {
  let tooltipFunctionApex = (bonos) => {
    return function (value, data) {
      let seriesIx = data.seriesIndex;
      let dataIx = data.dataPointIndex;
      return `Bono: $ ${dosDecimales(bonos[dataIx])}`;
    }
  }
  const optionsOrig = {
    chart: {
      animations: { enabled: false },
      height: 350,
      type: 'bar',
      stacked: true,
      zoom: {
        enabled: false
      }
    },
    plotOptions: {
      bar: {
        dataLabels: { orientation: "vertical" }
      }
    }
    ,
    dataLabels: {
      enabled: true
    },
    stroke: {
      curve: 'straight'
    },
    title: {
      text: 'Actividades de empleado',
      align: 'left'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },

    tooltip: {
      y: {
        formatter: tooltipFunctionApex
      },
      x: {
        formatter: tooltipFunctionApex
      }
    },
    xaxis: {
      categories: [],
    },
    yaxis: [{ title: { text: "% de actividades realizadas" } }]
  }
  const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
  const [actividades, setActividades] = useState([]);
  const [actividadesImportantes, setActividadesImportantes] = useState([]);
  const [actividadesTodos, setActividadesTodos] = useState([]);

  const [semanas, setSemanas] = useState([])
  const [ciclo] = useGlobal("ciclo");
  const [cicloObj] = useGlobal("cicloObj");
  const [invernaderos] = useGlobal("invernaderosInternos");

  const [empleado, setEmpleado] = useGlobal("empleado");
  const [, setCargando] = useGlobal("cargando");
  const [invernadero, setInvernadero] = useGlobal("invernadero");
  const [options, setOptions] = useState({ ...optionsOrig })
  const [empleados, setEmpleados] = useState([]);
  const [rolsInvernadero, setRolsInvernadero] = useState([]);
  const [rolEmpleado, setRolEmpleado] = useState("");
  const departamentoInvernaderosRef = "W1vIr510e74omoTUXbRL";
  const [datosInicialesCargados, setDatosInicialesCargados] = useState(false);

  useEffect(() => {
    setHabilitadoSelectorCiclo(false);
    obtenerDatosIniciales();
  }, [])
  useEffect(() => {
    if (datosInicialesCargados && ciclo && invernadero) { obtenerDatosEmpleadosInv(); }
  }, [ciclo, datosInicialesCargados, invernadero])
  useEffect(() => {
    if (datosInicialesCargados && ciclo && empleados.length) { obtenerDatosEmpleado(empleados[0]); }
  }, [ciclo, datosInicialesCargados, empleados])
  const obtenerDatosIniciales = async () => {
    setCargando(true);
    console.log("SETP 1111")
    const [actividadesResp, rolsInvernaderoResp] = await Promise.all([obtenerActividades(), obtenerRolesInvernadero()]);
    const actsImportantes = actividadesResp.filter(el => el.importante);
    setActividadesTodos(actividadesResp);
    setActividadesImportantes(actsImportantes);
    setRolsInvernadero(rolsInvernaderoResp);
    setDatosInicialesCargados(true)
  }

  const obtenerDatosEmpleadosInv = async () => {
    setCargando(true);
    const empleadosResp = await obtenerListaEmpleadosInvernadero(ciclo, invernadero)
    console.log("SETP 2222 ", empleadosResp);
    setEmpleados(empleadosResp);
  }

  const obtenerDatosEmpleado = async (empleadoSeleccionado) => {
    setCargando(true);

    console.log("SETP 3333 ", empleados)
    empleados.forEach(empleado => {
      const rolInvernadero = getObjetoPorID(rolsInvernadero, empleado.rol_invernadero_ref)?.nombre || "Sin rol";
      empleado.nombreCompleto = `${empleado["nombre"]} ${empleado["apellidos"]}`
      //if(empleado.nombreCompleto.includes("29"))console.log("29: ",{rolsInvernadero, empleado})
      empleado.rolInvernadero = rolInvernadero;
    })
    ordenarArreglaDeObjetosPorLlave(empleados, "nombre")

    const rolInvernaderoEmpleado = getObjetoPorID(rolsInvernadero, empleadoSeleccionado.rol_invernadero_ref)?.nombre || "Sin rol";
    setEmpleado(empleadoSeleccionado)
    setRolEmpleado(rolInvernaderoEmpleado)

    console.log("EMP1: ", { empleados, empleado, empleadoSeleccionado })

    let listaSemanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
    setSemanas(listaSemanas);

    let actividadesRealizadas = await obtenerActividadesImportantesEmpleadoCiclo(cicloObj, empleadoSeleccionado, actividadesImportantes, actividadesTodos);
    let datasets = procesar(actividadesRealizadas, actividadesImportantes);
    let bonos = actividadesRealizadas.map(el => el.bono);
    let labels = listaSemanas.map(el => el.nombreCompacto);
    let copiaOptions = { ...options };
    copiaOptions["xaxis"]["categories"] = labels;
    copiaOptions["tooltip"]["x"]["formatter"] = tooltipFunctionApex(bonos);
    copiaOptions["tooltip"]["y"]["formatter"] = tooltipFunctionApex(bonos);

    setOptions(copiaOptions);
    setActividades({ labels, datasets, bonos })
    setCargando(false);
  }

  const procesar = (actividades, actividadesImportantes) => {
    let colores = obtenerRangoColores(actividadesImportantes.length);
    let result = actividadesImportantes.map((actividadImportante, index) => {

      return {
        datasetId: actividadImportante.id,
        name: actividadImportante.nombre,
        label: actividadImportante.nombre,
        type: "bar",
        //backgroundColor:`#${colores[index]}`,
        data: actividades.map(actividad => {
          return actividad[actividadImportante.id] ? actividad[actividadImportante.id] : 0
        })
      }
    })

    let seriesEnrede = result.filter(el => actividadesEnrede.includes(el.name));
    let serieEnrede = result.find(el => nombreActividadEnrede === el.name);
    serieEnrede.data = seriesEnrede[0].data.map((el, index) => el + seriesEnrede[1].data[index])

    result = result.filter(el => el.name != nombreActividadEnredePlus);
    let seriesClipeo = result.filter(el => actividadesClipeo.includes(el.name));
    let serieClipeo = result.find(el => nombreActividadClipeo === el.name);
    serieClipeo.data = seriesClipeo[0].data.map((el, index) => el + seriesClipeo[1].data[index])
    result = result.filter(el => el.name != nombreActividadClipeoPlus);
    return result;
  }

  const handleFilterChange = (ev) => { obtenerDatosEmpleado(ev.value) }
  const tooltipFunction = (tooltipItem) => dosDecimales(actividades.bonos[tooltipItem.index]);

  return (
    <div className="p-grid chart-page">
      <div className="p-col  p-col-auto title-container">
        <span className="back-btn" onClick={props.history.goBack}></span>
        <span className={` icon-general chart-icon  bg-${invernadero?.color}  bor-${invernadero?.color}`}></span>
        <span className="titles-group">
          <h1 className="header-title">{empleado && <span>{empleado.nombre} {empleado.apellidos}</span>} </h1>
          <p className="header-subtitle">Actividades empleado - Invernadero {invernadero?.nombre || ""} - {rolEmpleado}</p>
        </span>
      </div>
      <div className="p-col-12">
        <div className="p-grid filtros">
          <div className="form-group p-col">
            <label htmlFor="filtroCliente">Filtrar invernadero</label>
            <Dropdown options={invernaderos} value={invernadero} onChange={ev => { setInvernadero(ev.target.value) }} optionLabel="nombre" dataKey="id" filter={true} />
          </div>
          <div className="form-group p-col">
            <label htmlFor="filtroCliente">Empleado a graficar</label>
            <Dropdown options={empleados} value={empleado} onChange={handleFilterChange} optionLabel="nombreCompleto" dataKey="id" filter={true} />
          </div>
        </div>
      </div>

      <StackedChart data={actividades} filtrosOrig={actividadesImportantes} tooltipFunction={tooltipFunction} options={options} filtrar={false} />
    </div>)
}
export default ActividadesEmpleado;
//231