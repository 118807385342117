import { getGlobal } from "reactn"
import * as firebase from "firebase/app";
import "firebase/firestore";
import { UNICO } from "../../constants";
import { getObjetoPorID, procesarColeccionFirestore } from "../../util/functions";
import { getFirestoreID } from "service/Mantenimiento";

// afuera de /service
// constants - uno por modulo, meter diccionarios aqui, tener uno general (common.const.js) en el mismo nivel
// service/ - uno por modulo, mezclar async/sync, no va incluir los quieres.
    // service/queiries - uno por modulo, llamadas a firestore
// utils/ - uno por tipo de funcionalidad (fechas, formato de numeros, etc.), el general va ser - common.util.js
    //scripts.js
//App.js
//index.js
//config - archivos de firebase config

//limpiar carpeta de /util, combinar lo que se puede
//.env archivo al momento solo para llaves de api de firestore

const db = () => firebase.firestore();
//GET queries
export const getRegistrosCalidadQUERY = async (ciclo, invernadero, semana_inicio, tipoRegistro, periodoTiempo) => {
    const response = await db().collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero).collection(`calidad_${tipoRegistro}`)
        .where("momento", ">=", semana_inicio).where("momento", "<=", (semana_inicio + periodoTiempo)).get();
    //registrarInvocaciones(result.size)//registrarInvocaciones(result.size)
    return procesarColeccionFirestore(response);
}

/* 
export const getVariedadesDeProductoQUERY = async (productoID) => {
    const response = await firebase.firestore().collection("productos_y_variedades").doc(productoID).collection("variedades").orderBy("nombre", "asc").get();
    const variedades = response.docs.map(doc => ({ id: doc.id, producto_ref: productoID, ...doc.data() }))
    return variedades;
}
export const getProductoQUERY = async (productoID) => {
    const response = await firebase.firestore().collection("productos_y_variedades").doc(productoID).get();
    const producto = response.exists ? { id: response.id, ...response.data() } : null;
    return producto;
}
export const getColoresVariedadesQUERY = async () => {
    const response = await firebase.firestore().collection("colores_variedades").orderBy("nombre", "asc").get();
    const colores = response.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return colores;
}

export const getProductosMezcladosPorCicloQUERY = async (cicloID) => {
    const response = await firebase.firestore().collection("ciclos").doc(cicloID).collection("productos_mezclados").orderBy("nombre", "asc").get();
    const productos = response.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return productos;
}


export const getProductosPorTipoQUERY = async (tipo) => {
    const response = await firebase.firestore().collection("productos_y_variedades").where(tipo, "==", true).orderBy("nombre", "asc").get();
    const productos = response.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return productos;
}

//decoradores
export const getProductosUnicosQUERY = () => { return getProductosPorTipoQUERY(UNICO) }

//Guardar queries
export const guardarVariedadQUERY = async (productoID, variedadID = getFirestoreID(), datos) => {
    return await firebase.firestore().collection("productos_y_variedades").doc(productoID).collection("variedades").doc(variedadID).set(datos, { merge: true });
}
export const guardarColorVariedadQUERY = async (colorID = getFirestoreID(), datos) => {
    return await firebase.firestore().collection("colores_variedades").doc(colorID).set(datos, { merge: true });
}

export const guardarProductoQUERY = async (productoID = getFirestoreID(), datos) => {
    return await firebase.firestore().collection("productos_y_variedades").doc(productoID).set(datos, { merge: true });
}
export const guardarMezclaVariedadesDelCicloQUERY = async (cicloID, productoID, datos) => {
    return await firebase.firestore().collection("ciclos").doc(cicloID).collection("mezclas_variedades").doc(productoID).set(datos, { merge: true });
}

export const guardarProductoMezcladoQUERY = async (cicloID, productoID = getFirestoreID(), datos) => {
    return await firebase.firestore().collection("ciclos").doc(cicloID).collection("productos_mezclados").doc(productoID).set(datos, { merge: true });
} */