import React from 'react'
import { useGlobal } from 'reactn';
import cultivo from './../../../images/graficas/cultivo.png';
import cultivoinvernaderos from './../../../images/graficas/cultivos.png';
import { Card } from 'primereact/card';

export default function HomeCultivo(props) {

    return (
        <React.Fragment>
            <div className="titulo-seccion-graficas">
                <span className="icon-general plant-icon"></span>
                Cultivo
            </div>

            <div className="p-col-12 p-md-3  grafica-card" onClick={() => props.ir("/cultivo/graficas/medidas")}>
                <Card className={`htg-card bor-gray-superlight`}  >


                    <div className="card-title">Mediciones de cultivo</div>
                    <img src={cultivo} />

                </Card>
            </div>
            <div className="p-col-12 p-md-3  grafica-card" onClick={() => props.ir("/cultivo/graficas/invernaderos")}>
                <Card className={`htg-card bor-gray-superlight`}  >
                    <div className="card-title">Comparación de mediciones en invernaderos</div>
                    <img src={cultivoinvernaderos} />

                </Card>
            </div>
        </React.Fragment>


    )
}
