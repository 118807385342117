import React from 'react'
import { useGlobal } from 'reactn';
import cosecha from './../../../images/graficas/cosecha.png';
import cajascosecha from './../../../images/graficas/cajascosecha.png';
import disVarieMedleys from './../../../images/graficas/distribucion-variedades-medleys.png';
import { Card } from 'primereact/card';

export default function HomeCosecha(props) {

    return (
        <React.Fragment>
            <div className="titulo-seccion-graficas">
                <span className="icon-general product-icon"></span>
                Cosecha
            </div>
            <div className="p-col-12 p-md-3  grafica-card" onClick={() => props.ir("/cosecha/graficas/porcentajes")}>
                <Card className={`htg-card bor-gray-superlight`}>
                    <div className="card-title">Gráfica de cosecha</div>
                    <img src={cosecha} />
                </Card>
            </div>
            <div className="p-col-12 p-md-3  grafica-card" onClick={() => props.ir("/cosecha/graficas/peso_semana")}>
                <Card className={`htg-card bor-gray-superlight`}  >


                    <div className="card-title">Gráfica peso caja de cosecha</div>
                    <img src={cajascosecha} />
                </Card>
            </div>
            <div className="p-col-12 p-md-3  grafica-card" onClick={() => props.ir("/graficas/distribucion_variedades_medleys")}>
                <Card className={`htg-card bor-gray-superlight`}>
                    <div className="card-title">Distribución variedades medleys</div>
                    <img src={disVarieMedleys} />
                </Card>
            </div>
        </React.Fragment>


    )
}
