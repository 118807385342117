import React from 'react'
import ListaTareas from './ListaTareas'

export default function CardCategoria(props) {
    const isEmergencia = () => props.categoria.classname === "urgente"

    return (
        <div className={`card-mtto ${props.empresa?.nombre}`}>
            <div className='card-mtto-header'>
                <div className={`card-mtto-icon ${props.categoria?.classname}`}></div>
                <div className='card-mtto-general'>
                    <div className='card-mtto-empresa'>{props.empresa?.nombre}</div>
                    <div className='card-mtto-categoria'>{isEmergencia() ? "Emergencia" : props.categoria?.nombre}</div>
                </div>
            </div>
            <div className='card-mtto-body'> 
                {props.tareas.length > 0 ? <ListaTareas titulo="Tareas" empresaId={props.empresa.id} tareas={props.tareas}
                    setShowModalRealizada={props.setShowModalRealizada}
                    setShowModalPlaneada={props.setShowModalPlaneada}
                    tipo={props.tipo} /> :
                    <div>No hay tareas</div>}
            </div>
        </div>)
}