import React from 'react';
import { useState, useEffect, useGlobal, setGlobal } from 'reactn';
import { Dialog } from "primereact/dialog";
import { Button } from 'primereact/button';
import { guardarDatosSalida } from "../../../service/VentaNacional";
import FirmaMaritza from "../../../images/recibos/Firma_Maritza_Nieto.png"

const FinalizarSalidaModal = (props) => {
    const [, setCargando] = useGlobal("cargando")
    const [usuario] = useGlobal("usuario")
    const [ciclo,] = useGlobal("ciclo");

    const cambiarEstatus = async () => {
        setCargando(true)
        props.onHide()

        let data = {
            ...props.state,
            estatus: "Finalizado",
            firmaAdminNombre: usuario.displayName,
            firmaAdminRol: usuario.rol,
            firmaAdminRef: usuario.uid,
        }
        await guardarDatosSalida(ciclo, data, props.salidaID)
        await props.generarRecibo()

        setCargando(false)
        props.goBack()
    }

    const footer = () => {
        return <div>
            <Button label="Cancelar" onClick={props.onHide} />
            <Button label="Finalizar" onClick={() => cambiarEstatus()} />
        </div>
    }

    return <Dialog header={"Finalizar orden"} visible={props.estatusModalOpen} style={{ width: '50vw' }} onHide={props.onHide} footer={footer()}>
        <div className="p-grid ">
            <div className="form-group p-col-12">
                <label htmlFor="status-nuevo">Estas seguro que quieres cambiar el estatus a 'Finalizado'? </label>
            </div>
            <div className="form-group p-col-12">
                <label>Tu firma</label>
                <img className="" width={300} src={FirmaMaritza} />
            </div>
        </div>
    </Dialog >
}
export default FinalizarSalidaModal