import React from 'react'
import { getProductoYVariedadString } from '../../../util/functions'

export default function CardHeader(props) {
    const { nombre: nombreInvernadero = "", color: colorInvernadero = "" } = props?.invernaderoVirtual || {};
    return (
        <span className="card-header">
            <div className={`card-id icon-general icon-id bg-${colorInvernadero}  bor-${colorInvernadero}`}>{nombreInvernadero}</div>
            <div>
                <p className="card-up">{props.habilitadoTexto || ""}</p>
                <p className="card-down">
                    {props.sinProductoTexto
                        ? `${props.nombreInvernaderoFisico}`
                        : `${props.nombreInvernaderoFisico} - ${getProductoYVariedadString(props.invernaderoVirtual) || props.productoYVariedadString || ""}`
                    }
                </p>
            </div>
        </span>)
}