import React from 'react';
import { useState, useEffect, useGlobal } from 'reactn';

import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { TabMenu } from "primereact/tabmenu";
import { Link } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { InputSwitch } from 'primereact/inputswitch';
import { Dialog } from 'primereact/dialog'
import { Message } from 'primereact/message';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import moment from "moment";
import 'moment/locale/es';

import { obtenerCiclosActivos, getNombre } from "../../service/Ciclos";
import { obtenerOrdenesCompraCiclo } from "../../service/Ordenes";
import { obtenerClientes } from "../../service/Clientes";

import * as firebase from "firebase/app";
moment.locale("es");



const CrearCiclo = (props) => {
    const [state, setState] = useState({ year_inicio: "", year_cierre: "", semana_inicio: "", semana_cierre: "", clientesSeleccionados: "", clientesNombres: "", placeholder: "" });
    const [nombreModulo, setNombreModulo] = useGlobal("nombreModulo");
    const [requisicionesModalOpen, setRequisicionesModalOpen] = useState(false)
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo")

    const [cargando, setCargando] = useGlobal("cargando")
    const [errores, setErrores] = useState([])

    useEffect(() => {
        obtenerClientesInicio()
        setHabilitadoSelectorCiclo(false)
    }, [])

    const obtenerClientesInicio = () => {
        setNombreModulo("Crear Ciclo")
        setCargando(true)

        let clienteOpciones = []
        obtenerClientes().then(clientes => {
            clientes.forEach(cliente => {
                clienteOpciones.push({ label: cliente.nombre_comun, value: cliente.id })
            })
            setState({ ...state, clientes: clientes, clienteOpciones: clienteOpciones, clientesSeleccionados: [] })
            setCargando(false)
        }).catch(error => {
            console.log(error.message)
        })
    }

    const checkForErrors = async () => {
        let errores = []
        if (state.year_inicio === "") {
            errores.push("yearInicio")
            setErrores(errores)
        }
        if (state.year_cierre === "") {
            errores.push("yearCierre")
            setErrores(errores)
        }
        if (state.semana_inicio === "") {
            errores.push("semanaInicio")
            setErrores(errores)
        }
        if (state.semana_cierre === "") {
            errores.push("semanaCierre")
            setErrores(errores)
        }
        if (errores.length === 0) {
            setRequisicionesModalOpen(true)
        }
    }

    const guardarCiclo = async (moverRequisiciones) => {
        setCargando(true)
        setRequisicionesModalOpen(false)
        let semanaIncicio = (new Date(state.year_inicio, 0, 1 + ((state.semana_inicio - 1) * 7)).getTime()) / 1000
        let semanaCierre = (new Date(state.year_cierre, 0, 1 + ((state.semana_cierre - 1) * 7)).getTime()) / 1000

        var db = firebase.firestore();
        let ciclo = await db.collection('ciclos').add({
            semana_inicio: semanaIncicio,
            semana_cierre: semanaCierre
        })
        for (var j = 0; j < state.clientesSeleccionados.length; j++) {
            await db.collection('ciclos').doc(ciclo.id).collection('clientes').add({ cliente_ref: state.clientesSeleccionados[j] })
        }

        if (moverRequisiciones) {
            await moverRequisicionesDeCompra(ciclo.id)
        }
        setCargando(false)
        props.history.push("/ciclos");
    }

    const setSelected = (selected) => {
        setState({ ...state, clientesSeleccionados: selected, placeholder: "seleccionar" })
    }

    const moverRequisicionesDeCompra = async (cicloId) => {
        let ordenesCompra = []
        let ciclos = await obtenerCiclosActivos()
        let ind = 0
        for (let i = 0; i < ciclos.length; i++) {
            let ordenes = []// await obtenerOrdenesCompraCiclo(ciclos[i].id, false)
            ordenes.forEach(orden => {
                ind = ind + 1
                let ordenCopy = { ...orden }
                delete ordenCopy.id

                if (ordenCopy.status !== "Cerrado")
                    ordenesCompra.push({...orden, orden_num: ind})
            })
        }
/*        for (let j = 0; j < ordenesCompra.length; j++) {
            await firebase.firestore().collection('ciclos').doc(cicloId).collection('ordenes_compra').add({ ...ordenesCompra[j] })
        }
*/
    }

    const footerCompra = () => {
        return <div>
            <Button label="No" onClick={() => guardarCiclo(false)} />
            <Button label="Sí" onClick={() => guardarCiclo(true)} />
        </div>
    }

    return (
        <div className="p-grid p-justify-center">
            <div className="p-col p-col-12 title-container">
                <span className="back-btn" onClick={() => { props.history.goBack() }}></span>
                <span className="icon-general ciclo-icon"></span>
                <span className="titles-group">
                    <h1 className="header-title">Crear ciclo</h1>
                </span>
            </div>


            <div className="p-col-10">
                <div className="p-grid p-fluid ">

                    {/* Detalles de ciclo */}

                    <div className="p-col-12">
                        <p className="section-title">Detalles del ciclo</p>
                        <div className="card ">
                            <div className="p-grid">

                                <div className="form-group p-col-12 p-md-3">
                                    <label htmlFor="yearInicio">Año Inicio</label>
                                    <InputText id="yearInicio" keyfilter={"int"} value={state.year_inicio} onChange={e => setState({ ...state, year_inicio: e.target.value })} />
                                    {errores.includes("yearInicio") ? <Message severity="error" text="Este campo es requerido"></Message> : null}
                                </div>

                                <div className="form-group p-col-12 p-md-3">
                                    <label htmlFor="semanaInicio">Semana de Inicio</label>
                                    <InputText id="semanaInicio" keyfilter={"int"} value={state.semana_inicio} onChange={e => setState({ ...state, semana_inicio: e.target.value })} />
                                    {errores.includes("semanaInicio") ? <Message severity="error" text="Este campo es requerido"></Message> : null}
                                </div>

                                <div className="form-group p-col-12 p-md-3">
                                    <label htmlFor="yearCierre">Año Finalización</label>
                                    <InputText id="yearCierre" keyfilter={"int"} value={state.year_cierre} onChange={e => setState({ ...state, year_cierre: e.target.value })} />
                                    {errores.includes("yearCierre") ? <Message severity="error" text="Este campo es requerido"></Message> : null}
                                </div>

                                <div className="form-group p-col-12 p-md-3">
                                    <label htmlFor="semanaCierre">Semana Finalización</label>
                                    <InputText id="semanaCierre" keyfilter={"int"} value={state.semana_cierre} onChange={e => setState({ ...state, semana_cierre: e.target.value })} />
                                    {errores.includes("semanaCierre") ? <Message severity="error" text="Este campo es requerido"></Message> : null}
                                </div>

                                <div className="form-group p-col-12 p-md-12">
                                    <label htmlFor="clientes">Clientes asociados a ciclo</label>
                                    <MultiSelect id="clientes"
                                        placeholder={"Seleccionar clientes"}
                                        selectedItemsLabel={`${state.clientesSeleccionados.length} clientes seleccionados`} filter={false}
                                        options={state.clienteOpciones} value={state.clientesSeleccionados}
                                        onChange={(e) => { setSelected(e.value) }} />
                                </div>

                            </div>
                        </div>
                    </div>



                    {requisicionesModalOpen && <Dialog header={"Requisiciones de compra"} visible={requisicionesModalOpen} style={{ width: '50vw' }} onHide={() => setRequisicionesModalOpen(false)} footer={footerCompra()}>
                        <div className="p-grid ">
                            <div className="form-group p-col-12">
                                <label htmlFor="requisiciones-compra">¿Le gustaría mover todas las requisiciones de compra abiertos de los ciclos actualmente activos a este nuevo ciclo?</label>
                            </div>
                        </div>
                    </Dialog >}

                </div>
                <div className="p-col-12">
                    <div className="p-grid p-justify-end btn-row">
                        <Button label="Cancelar" className="p-button-secondary" onClick={() => { props.history.goBack() }} />
                        <Button label="Guardar" onClick={checkForErrors} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CrearCiclo