import React, { useGlobal } from 'reactn';
import Logo from './images/logo.svg';
import LogoDev from './images/logo-sisu.png';
import { estamosEnProduccion, estamosEnTest } from './constants';
const AppProfile = () => {
    const [usuario] = useGlobal("usuario")

    let expanded = false
    const onClick = (event) => {
        expanded = !expanded
        event.preventDefault();
    }
    return (
        <React.Fragment>
            <div className="layout-logo">
                <img alt={estamosEnProduccion ? "Logo" : "LogoDev"} src={estamosEnProduccion ? Logo : LogoDev} />
                {!estamosEnProduccion && <span className="sisu-logo-top-text">Profile: {estamosEnTest ? "TEST" : "DEV"}</span>}
            </div>

            <div className="layout-profile" onClick={onClick}>
                <p className="username">{usuario?.displayName || ""}</p>
                <p className="job">{usuario?.rol || ""}</p>
            </div>
        </React.Fragment>);
}
export default AppProfile//30