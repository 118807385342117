
import React, { useState, useEffect } from 'reactn';
import { useGlobal } from "reactn";
import InvernaderoCard from "./InvernaderoCard";
import { obtenerRegistrosCalidadInvernaderoSemana } from '../../service/CalidadTrabajo';
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';

const Invernaderos = (props) => {
  const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
  const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
  const [invernaderosFisicos] = useGlobal("invernaderosFisicos");
  const [ciclo,] = useGlobal("ciclo");
  const [, setNombreModulo] = useGlobal("nombreModulo");
  const [, setCargando] = useGlobal("cargando");
  const [, setMostrarModalPermiso] = useGlobal("mostrarModalPermiso");
  const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector();
  const [invernaderosCardsInfo, setInvernaderosCardsInfo] = useState([]);

  useEffect(() => {
    setNombreModulo("Calidad de trabajo");
    setHabilitadoSelectorCiclo(true)
  }, [])
  useEffect(() => {
    updateCicloWeekSelector(ciclo);
  }, [invernaderosVirtuales])
  useEffect(() => {
    setCargando(true);
    if (week.nombre != "Cargando")
      obtenerDatosCards();
  }, [week])

  const obtenerDatosCards = async () => {
    try {
      let allCardsInfo = []

      for (let index in invernaderosFisicos) {
        let inv = invernaderosFisicos[index]

        let registros = await obtenerRegistrosCalidadInvernaderoSemana(ciclo, inv.id, week.time)
        allCardsInfo.push({ semana: week.time, invernadero: inv, ...obtenerResumenPorcentajes(registros) })
      }

      setInvernaderosCardsInfo(allCardsInfo)

    } catch (err) {
      if (err.code && err.code === "permission-denied") {
        setMostrarModalPermiso(true);
        console.log(err.code);
      }
    }

    setCargando(false);
  }
  const obtenerResumenPorcentajes = (registros) => {
    let actividades = [], actividadesUnicos = [];
    let perfecto = 0, bajo = 0, medio = 0, alto = 0, grave = 0;

    registros.forEach(registro => {
      registro.actividades.forEach(act => {
        if (act.nivel_incidencia === 1) perfecto++;
        else if (act.nivel_incidencia === 2) bajo++;
        else if (act.nivel_incidencia === 3) medio++;
        else if (act.nivel_incidencia === 4) alto++;
        else if (act.nivel_incidencia === 5) grave++;

        if (!actividadesUnicos.includes(act.actividad_ref)) {
          actividadesUnicos.push(act.actividad_ref);
        }

        actividades.push(act.actividad_ref)
      })
    })
    actividadesUnicos = actividadesUnicos.length;

    let totalActividades = actividades.length
    return totalActividades === 0 ? {} : {
      perfecto: (perfecto / totalActividades) * 100, bajo: (bajo / totalActividades) * 100, medio: (medio / totalActividades) * 100,
      alto: (alto / totalActividades) * 100, grave: (grave / totalActividades) * 100, actividadesUnicos, totalClarosEvaluados: registros.length
    }
  }

  const puedeConsultar = () => true//(usuario && ["Administrador", "Administrador Biocontrol", "Auxiliar Biocontrol", "Personal Administrativo", "Grower"].includes(usuario.rol))

  return <div className="p-grid">

    <div className="p-col-12">
      <div className="btn-row">
      </div>
    </div>

    <div className="p-col-12">
      <div className="p-grid filtros">
        <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
      </div>
    </div>

    <div className="p-col-12">
      <div className="p-grid">
        {invernaderosCardsInfo.map(info => {
          return <InvernaderoCard key={info.invernadero.id} info={info} invernadero={info.invernadero}
            onClick={() => { if (puedeConsultar()) { props.history.push({ pathname: "/calidad_trabajo/mapa_invernadero", data: { invernadero: info.invernadero } }) } }} />
        })}
      </div>
    </div>
  </div>
}
export default Invernaderos;

