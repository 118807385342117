import React, { useState, useEffect } from "react";
import Column from "components/common/cards/Column";
import { DataTable } from "primereact/datatable";
import { separadoComas } from "util/functions";
import { sumarNumerosArrayPorCampo } from "../../../util/functions";

const TablaDetallesItems = ({ manifiestosData }) => {
  const [totalDeCajas, setTotalDeCajas] = useState(0);

  useEffect(() => {
    const newTotalDeCajas = sumarNumerosArrayPorCampo(manifiestosData, "cajas_orden")
    setTotalDeCajas(newTotalDeCajas);
  }, [manifiestosData]);

  const footerBody = () => <span>Total de cajas: {separadoComas(totalDeCajas)}</span>;
  return (
    <DataTable value={manifiestosData} footer={footerBody()} >
      <Column field="presentacion" header="Presentación" footer={null}/>
      <Column field="nombre_invernadero" header="Invernadero" footer={null}/>
      <Column field="cajas_orden" header="Cajas por presentaciones" footer={null}/>
      <Column field="manifiesto" header="Manifiesto"footer={null}/>
    </DataTable>
  );
};
export default TablaDetallesItems;