import React, { useState, useEffect, useGlobal } from "reactn";

import { obtenerDefectos } from '../../service/Defectos';
import { obtenerReporteDefectosDiario, obtenerReporteDefectos } from '../../service/ReporteCalidad';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { obtenerNombreFecha } from "../../service/fechas";
import { dosDecimales } from '../../util/functions';
import Firma from "../common/Firma";
import { nombresDiasSemanaPlanos, nombresDiasSemana, rolProveedorExterno } from "../../constants";
import { useWeekSelector } from "../common/WeekSelector";
import { JEFA_CALIDAD_FIRMA } from "../../constants";

const ReporteDefectos = (props) => {
    const { subvariedad, proveedorID, reporteExterno = false } = props.filtros, { filtroDefectos = [] } = props;

    const [rows, setRows] = useState([]);
    const [defectos, setDefectos] = useGlobal("defectos");
    const [invernadero] = useGlobal("invernadero");
    const [productosYVariedades] = useGlobal("productosYVariedades");

    const [cargando, setCargando] = useGlobal("cargando");
    const [ciclo,] = useGlobal("ciclo");
    const [defectosPresentes, setDefectosPresentes] = useState([]);
    const [comentarios, setComentarios] = useState([]);
    const [, setMostrarModalPermiso] = useGlobal("mostrarModalPermiso");
    const [usuario] = useGlobal("usuario");
    const [, week] = useWeekSelector();
    useEffect(() => {
        let obtenerExterno = (reporteExterno && proveedorID && invernadero.es_externo) || !reporteExterno
        if (invernadero && week.nombre && defectos !== "Cargando" && obtenerExterno) obtenerDatosIniciales(subvariedad, proveedorID);
        else if (invernadero && week.nombre !== "Cargando" && obtenerExterno) console.log("*******")//obtenerDatosIniciales(subvariedad, proveedorID);
    }, [invernadero, week])

    const obtenerDatosIniciales = async (subVariedad, proveedorExterno) => {
        try {
            setCargando(true);
            let defs = defectos || await obtenerDefectos()
            let producto = productosYVariedades.find(el => el.id === invernadero.producto_ref);

            const [resultDiario, resultSemanal] = await Promise.all([
                obtenerReporteDefectosDiario(ciclo, invernadero.id, week.time, producto.tipo_calidad_inicial, subVariedad, proveedorExterno),
                obtenerReporteDefectos(ciclo, invernadero.id, week.time, producto.tipo_calidad_inicial, subVariedad, proveedorExterno)
            ])

            setDefectos(defs)
            procesarReporte(resultDiario, resultSemanal, defs);
        }
        catch (error) {
            console.log("ERROR REPORTE DEFECTOS: " + error.message);
            if (error.code && error.code === "permission-denied")
                setMostrarModalPermiso(true);
        }
        setCargando(false);
    }
    const procesarReporte = (result, resultSemanal, defectos) => {
        let dias = nombresDiasSemanaPlanos;

        let keysInicial = nombresDiasSemanaPlanos.reduce((acc, nombreDia) => {
            let keys = Object.keys(result.promediosInicial[nombreDia]);
            return acc.concat(keys);
        }, [])
        let keysFinal = nombresDiasSemanaPlanos.reduce((acc, nombreDia) => {
            let keys = Object.keys(result.promediosFinal[nombreDia]);
            return acc.concat(keys);
        }, [])

        let setInicial = new Set(keysInicial);
        let setFinal = new Set(keysFinal);
        keysInicial = Array.from(setInicial);
        keysFinal = Array.from(setFinal);


        dias.map(dia => {
            result.promediosInicial[dia].total = {};
            result.promediosInicial[dia].total.cociente = 0;
            result.promediosFinal[dia].total = {};
            result.promediosFinal[dia].total.cociente = 0;

            keysInicial.forEach(k => {
                let dato = result.promediosInicial[dia][k] ? result.promediosInicial[dia][k].cociente : 0;
                result.promediosInicial[dia].total.cociente += isNaN(dato) ? 0 : dato;
            })
            keysFinal.forEach(k => {
                let dato = result.promediosFinal[dia][k] ? result.promediosFinal[dia][k].cociente : 0;
                result.promediosFinal[dia].total.cociente += isNaN(dato) ? 0 : dato;
            })
        })

        let listaDefectos = keysInicial.concat(keysFinal);

        result.promediosInicial.total = dosDecimales(result.promediosInicial.total);
        result.promediosFinal.total = dosDecimales(result.promediosFinal.total);

        result.promediosInicial.nombre = "INICIAL";
        result.promediosFinal.nombre = "FINAL";

        result.promediosInicial.semanal = resultSemanal.promediosInicial;
        result.promediosFinal.semanal = resultSemanal.promediosFinal;

        result.promediosInicial.semanal.total = {};
        result.promediosInicial.semanal.total.cociente = 0;

        result.promediosFinal.semanal.total = {};
        result.promediosFinal.semanal.total.cociente = 0;

        keysInicial.forEach(k => {
            let dato = result.promediosInicial.semanal[k] ? result.promediosInicial.semanal[k].cociente : 0;
            result.promediosInicial.semanal.total.cociente += isNaN(dato) ? 0 : dato;
        })
        keysFinal.forEach(k => {
            let dato = result.promediosFinal.semanal[k] ? result.promediosFinal.semanal[k].cociente : 0;
            result.promediosFinal.semanal.total.cociente += isNaN(dato) ? 0 : dato;
        })

        let set = new Set(listaDefectos);
        listaDefectos = Array.from(set);
        listaDefectos = listaDefectos.map(defectoRef => defectos.find(el => el.id === defectoRef))

        let rowsDias = dias.map((diaSemana, index) => ({ nombre: nombresDiasSemana[index], inicial: result.promediosInicial[diaSemana], final: result.promediosFinal[diaSemana] }));
        rowsDias.push({ nombre: "Semanal", inicial: result.promediosInicial.semanal, final: result.promediosFinal.semanal })

        setDefectosPresentes(listaDefectos);
        setRows(rowsDias);
        setComentarios(result.comentarios);
        props.actualizarDefectosDisponibles(listaDefectos);

    }

    const templateBody = (rowData, column) => {
        let inicial = rowData.inicial[column.field] ? rowData.inicial[column.field].cociente : 0;
        let final = rowData.final[column.field] ? rowData.final[column.field].cociente : 0;
        inicial = isNaN(inicial) ? 0 : inicial;
        final = isNaN(final) ? 0 : final;
        return (
            <div>
                <span className="calidad-data"><span className="calidad-value">{dosDecimales(inicial)}<span className="label-unidad">%</span> </span> <span className="label-unidad">INICIAL</span></span>
                {!reporteExterno && <span className="calidad-data"><span className="calidad-value">{dosDecimales(final)}<span className="label-unidad">%</span> </span> <span className="label-unidad">FINAL</span></span>}
            </div>)
    }

    const obtenerColumnas = () => {
        let columnas = [<Column key={`rep-def-col-nombre`} field="nombre" header="" />];
        filtroDefectos.forEach((defectoId, index) => {
            let defecto = defectosPresentes.find(el => el.id === defectoId);
            if (defecto) columnas.push(<Column key={`rep-def-col-${index}`} field={defecto.id} header={defecto.nombre} body={templateBody} />)
        })
        columnas.push(<Column key={`rep-def-col-tot`} field="total" header="Total" body={templateBody} />)
        return columnas;
    }

    return (
        <React.Fragment>
            <div className="card p-0">
                <DataTable value={rows}>
                    {obtenerColumnas()}
                </DataTable>
            </div>
            {usuario.rol !== rolProveedorExterno && <p className="section-title">Comentarios/hallazgos</p>}
            {usuario.rol !== rolProveedorExterno && <div className="card">
                <div className="p-col-12 max-6">
                    {comentarios.map((com, index) => <div key={`rep-def-cal-${index}`}>{obtenerNombreFecha(com.momento)} - {com.comentario}</div>)}
                </div>
            </div>}

            {!reporteExterno &&
                <div className="p-col-12"> 
                    <Firma {...JEFA_CALIDAD_FIRMA}/>
                </div>}
        </React.Fragment>)
}
export default ReporteDefectos;//224