import React, { useState } from 'react'
import { useGlobal } from 'reactn';
import CardCategoria from './CardCategoria'
import ModalNuevaTarea from './modals/ModalNuevaTarea';
import ModalTareaPlaneada from './modals/ModalTareaPlaneada';
import ModalTareaRealizada from './modals/ModalTareaRealizada';
import { getInicioDia } from '../../service/fechas';
import BaseCard from '../common/cards/BaseCard';
import BaseButton from '../common/BaseButton';
import SeccionDatosCenter from '../common/SeccionDatosCenter';
import DatoCard from '../common/cards/DatoCard';
import { formatearMinutosAHoras } from '../../util/functions';
import ModalCopiarTareas from './modals/ModalCopiarTareas';
import { getSumaTotalTiempoEstimado, sumarTiemposTomadosParciales } from '../../service/Mantenimiento';

export default function CardDia(props) {
    const [actividadesMantenimiento] = useGlobal("actividadesMantenimiento");
    const [, setTareaSeleccionada] = useGlobal("tareaSeleccionada");
    const [sitiosHTF] = useGlobal("sitiosHTF");
    const [empresas] = useGlobal("empresas");

    const [showModalNueva, setShowModalNueva] = useState(false);
    const [showModalPlaneada, setShowModalPlaneada] = useState(false)
    const [showModalRealizada, setShowModalRealizada] = useState(false);
    const [showModalEmergencia, setShowModalEmergencia] = useState(false);
    const [showModalCopiarTareas, setShowModalCopiarTareas] = useState(false)


    const hideModales = () => {
        setShowModalNueva(false);
        setShowModalPlaneada(false);
        setShowModalRealizada(false);
        setShowModalEmergencia(false);
        setShowModalCopiarTareas(false);
        setTareaSeleccionada(null);
    }
    const esDiaPasado = () => props.dia.unixTime <= (getInicioDia(Date.now()) * 1000);
    const esDiaHoy = () => props.dia.unixTime === getInicioDia(Date.now()) * 1000

    const getTime = () => {
        let sumaTiempo = 0;
        props.tareas.map(tarea => {//if(tarea.is_emergencia) window.alert(tarea.nombreTarea)
            let actividad = actividadesMantenimiento.find(actividad => actividad.id === tarea.actividad_ref);
            if (tarea.tareaParcial) { tarea.tiempo_tomado = sumarTiemposTomadosParciales(tarea.tiempos_tomados);
            window.alert(sumarTiemposTomadosParciales(tarea.tiempos_tomados)) }
            if (props.dia.unixTime === tarea.dia) sumaTiempo += actividad.tiempo_estimado;
        })
        return formatearMinutosAHoras(sumaTiempo)
    }

    const getResumen = () => {
        let emergeSum = 0, actSum = 0, completadas = 0, result = {}
        result.estimacion = props.tareas.filter(el => el.dia === props.dia?.unixTime).reduce(
            (acc, tarea) => {
                if (actividadesMantenimiento) {
                    let actividad = actividadesMantenimiento.find(actividad => actividad.id === tarea.actividad_ref);
                    if (actividad && !tarea.realizada) {
                        if (tarea.is_emergencia) emergeSum += parseInt(tarea.tiempo_estimado)
                        else if(tarea.is_requisicion || tarea.is_tiempo_estimado_locacion){
                            return acc + parseInt(tarea.tiempo_estimado);
                        }
                        return acc + parseInt(actividad.tiempo_estimado);
                    } else if (actividad && tarea.realizada) {
                        if (tarea.is_emergencia) { emergeSum += parseInt(tarea.tiempo_tomado); }
                        else if (tarea.tareaParcial) { actSum += sumarTiemposTomadosParciales(tarea.tiempos_tomados); }
                        else { actSum += parseInt(tarea.tiempo_tomado); }
                        completadas++
                        return acc;
                    } else {
                        return acc;
                    }
                } else {
                    return acc;
                }
            }, 0);

        result.estimacion = formatearMinutosAHoras(result.estimacion)

        result.completadas = `${completadas}/${props.tareas.length}`;

        result.tiempoEmergencias = emergeSum ? formatearMinutosAHoras(emergeSum) : false
        result.tiempoRealizada = actSum ? formatearMinutosAHoras(actSum) : false
        return result;
    }

    const getCompletadas = () => {
        const tareas = props.tareas.filter(tarea => props.dia.unixTime === tarea.dia)
        const completada = props.tareas.filter(tarea => props.dia.unixTime === tarea.dia && tarea.realizada)

        return `${completada.length}/${tareas.length}`
    }
    const hayTareasHoy = () => props.tareas?.filter(el => el.dia === props.dia?.unixTime)?.length > 0;

    const sumTareasFiltradosDelDia = () => getSumaTotalTiempoEstimado(actividadesMantenimiento, props.tareas.filter(el => el.dia === props.dia?.unixTime))

    return (
        <React.Fragment>
            <BaseCard col="12" md="4" lg="4" tituloCard={props.dia.nombre}>

                <SeccionDatosCenter>
                    <DatoCard label="Planeación estimadas" valor={formatearMinutosAHoras(sumTareasFiltradosDelDia())} />

                    {getResumen()?.tiempoRealizada &&
                        <DatoCard label="Tareas realizados" valor={getResumen().tiempoRealizada} />}
                    {getResumen()?.tiempoEmergencias &&
                        <DatoCard label="Emergencias realizados" valor={getResumen().tiempoEmergencias} />}

                    <DatoCard label="Tareas completadas" valor={getCompletadas()} />
                </SeccionDatosCenter>

                {props.empresas?.map((empresa, indexE) => {
                    return props.categorias?.map((categoria, indexC) => {
                        const tareas = props.tareas?.filter(tarea => {
                            let actividad = actividadesMantenimiento?.find(actividad => actividad.id === tarea.actividad_ref);
                            return props.dia?.unixTime === tarea.dia && actividad.categoria_ref === categoria.id && tarea.empresa_ref === empresa.id
                        })
                        return tareas.length === 0 ? null :
                            <div key={`${indexE} - ${indexC}`}>
                                <CardCategoria categoria={categoria} tareas={tareas} empresa={empresa} setShowModalRealizada={setShowModalRealizada} setShowModalPlaneada={setShowModalPlaneada} />
                            </div>
                    })
                })}

                {!esDiaPasado() &&
                    <BaseButton extra="reactbtn-icon w-100" label="+ Actividad" onClick={() => setShowModalNueva(true)} />}
                {esDiaHoy() &&
                    <BaseButton extra="reactbtn-icon w-100" label="+ Emergencia" onClick={() => {
                        setShowModalEmergencia(true)
                        setShowModalNueva(true)
                    }} />}
                {hayTareasHoy() &&
                    <BaseButton extra="reactbtn-icon w-100" label="Mover actividades del día" onClick={() => { setShowModalCopiarTareas(true) }} />}
            </BaseCard>

            <ModalNuevaTarea show={showModalNueva} registrarEmergencia={showModalEmergencia} hide={hideModales} dia={props.dia} tiempo_estimado={sumTareasFiltradosDelDia()} />
            <ModalTareaRealizada show={showModalRealizada} hide={hideModales} dia={props.dia} />
            <ModalTareaPlaneada show={showModalPlaneada} hide={hideModales} dia={props.dia} />
            <ModalCopiarTareas show={showModalCopiarTareas} hide={hideModales} dia={props.dia} tareas={props?.tareas || []} actividadesMantenimiento={actividadesMantenimiento} />

        </React.Fragment>
    )
}//110