import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React from 'react'
import ContenedorTabla from '../../../common/ContenedorTabla';
const ActividadesMantenimientoTabla = (props) => {
    return (
        <ContenedorTabla titulo="">
            <DataTable key={"cats"} globalFilter={props.globalFilter} selectionMode="single" className="tabla-actividades"
                emptyMessage="No hay resultados" value={props.tablaLineasActividades} onRowClick={props.editarActividad} >
                <Column className='w-100' field="nombreCategoria" header="Categoría" sortable />
                <Column className='w-100' field="nombreSubcategoria" header="Subcategoría" sortable />
                <Column className="p-col-4 " field="indice" header="Índice" sortable />
                <Column className='w-100' field="nombre" header="Nombre" sortable />
                <Column className='p-col-5' field="periodicidad" header="Periodicidad" sortable />
            </DataTable>
        </ContenedorTabla>)
}
export default ActividadesMantenimientoTabla;