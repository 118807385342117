import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react'
import { useGlobal } from 'reactn';
import { segundosSemana } from '../../constants';
import { obtenerNombreSemanaCompacto } from '../../service/fechas';
import { registrarPronostico, actualizarPronostico, obtenerCapturaPreviaProveedorExterno } from '../../service/Queries/Pronostico';
import { obtenerProveedorPorUsuario } from '../../service/Queries/Proveedores';
import ContenedorInput from '../common/ContenedorInput';
import ContenedorPrincipal from "../common/ContenedorPrincipal"
import SeccionBotones from '../common/SeccionBotones';
import SeccionFiltros from '../common/SeccionFiltros'
import SeccionFormulario from '../common/SeccionFormulario'
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import icono from '../../images/icons/external-icon.svg';
import BaseCard from '../common/cards/BaseCard';
import SeccionDatosCenter from '../common/SeccionDatosCenter';
import DatoCard from '../common/cards/DatoCard';
import { dosDecimales, getObjetoPorID } from '../../util/functions';
import { obtenerPronosticoYProyeccionProveedorExterna } from '../../service/Pronosticos';
import { isAdminNormal } from 'service/Requisiciones';

const RegistroPronostico = (props) => {
    const [invernadero] = useGlobal("invernadero")
    const [productosYVariedades] = useGlobal("productosYVariedades")
    const [usuario] = useGlobal("usuario")
    const [cicloId] = useGlobal("ciclo")
    const [weeks, week, handleWeekChange] = useWeekSelector();
    const [state, setState] = useState(["", "", "", "", ""])
    const [editing, setEditing] = useState(false)
    const [capturaId, setCapturaId] = useState()
    const [, setCargando] = useGlobal("cargando")
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [proveedor, setProveedor] = useState(null);
    const [proveedorExterno] = useGlobal("proveedor");
    const [, setEditingProveedor] = useState(false)
    const [resumenPronostico, setResumenPronostico] = useState({})

    useEffect(() => {
        setNombreModulo("Pronósticos")
        setHabilitadoSelectorCiclo(true)
    }, [])

    useEffect(() => {
        if (cicloId && !isProveedorExterno() && !invernadero.es_externo) { props.history.replace("/pronosticos/cards"); }
        else if (cicloId) { obtenerDatosIniciales(); }
    }, [cicloId])

    useEffect(() => {
        if (proveedor) { cargarDatosPrevios(); }
    }, [proveedor, week])

    const isProveedorExterno = () => usuario?.rol === "Proveedor Externo";

    const cargarDatosPrevios = async () => {
        setCargando(true);
        try {
            let datos = await obtenerCapturaPreviaProveedorExterno(cicloId, proveedor.invernadero_ref, week.time, proveedor.id);
            const resumenPronostico = await obtenerResumenExterno(proveedor);
            setResumenPronostico(resumenPronostico)
            if (!datos.isEmpty) {
                setState(datos.pronosticos);
                setCapturaId(datos.id);
                setEditing(true);
            } else { setState(["", "", "", "", ""]); }
            setEditing(!datos.isEmpty ? true : false);
        } catch (error) {
            console.log("ERROR cargarDatosPrevios - ", error);
        }
        setCargando(false);
    }

    const obtenerDatosIniciales = async () => {
        setEditingProveedor(isProveedorExterno);
        if (!isProveedorExterno()) { setProveedor(proveedorExterno); }
        else {
            const proveedor = await obtenerProveedorPorUsuario(cicloId, usuario.uid)
            setProveedor(proveedor)
        }
    }
    const obtenerResumenExterno = async (proveedor) => {
        try {
            //console.log("PROVEEDOR EXTERNO ", { cicloId, invernadero, week: week.time, proveedor })
            const invernaderoExterno = getObjetoPorID(invernaderosVirtuales, proveedor.invernadero_ref);
            const pronosticoProyeccion = await obtenerPronosticoYProyeccionProveedorExterna(cicloId, invernaderoExterno, week.time, proveedor);
            const semanaUno = dosDecimales(pronosticoProyeccion.semanaUno / parseInt(proveedor.area_invernadero))

            return { proyeccion: pronosticoProyeccion.proyeccion, semanaUno, semanaTres: pronosticoProyeccion.semanaTres, semanaCinco: pronosticoProyeccion.semanaCinco }
        }
        catch (error) {
            console.log("ERROR obtenerResumenExterno: ", error)
        }
    }

    const handleChange = (index, value) => {
        let copia = state.slice();
        copia[index] = value;
        setState(copia);
    }

    const guardar = async () => {
        setCargando(true);
        if (editing) {
            console.log({ proveedor });
            await actualizarPronostico(cicloId, proveedor.invernadero_ref, capturaId, state);
        } else {
            let result = await registrarPronostico(cicloId, proveedor.invernadero_ref, week.time, state, proveedor.id, proveedor.usuario_ref)
            setCapturaId(result.id)
            setEditing(true);
        }
        setCargando(false);
    }

    const obtenerNombreSemana = (index) => {
        let time = week.time + (1 + index) * segundosSemana + 3600;
        let nombre = obtenerNombreSemanaCompacto(time);
        return `Semana ${nombre}`;
    }

    return (
        <ContenedorPrincipal>
            <div className="p-col-12 title-container">
                {usuario?.rol !== "Proveedor Externo" && <span className="back-btn" onClick={props.history.goBack}></span>}
                <span className="icon-id icon-general external-icon bor-main-blue bg-main-blue">
                    <img width="60" alt="icono" src={icono} />
                </span>
                {proveedor &&
                    <span className="titles-group">
                        <h1 className="header-title">Registro de pronóstico de {proveedor?.nombre}</h1>
                    </span>}
            </div>

            <SeccionFiltros>
                <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
            </SeccionFiltros>

            {!isAdminNormal(usuario.rol) &&
                <BaseCard col="12" tituloCard={"Resumen de la semana " + week.nombre}>
                    <SeccionDatosCenter>
                        <DatoCard label="ACCURRACY 3 WEEKS" valor={`${(!resumenPronostico.semanaTres || resumenPronostico.semanaTres === "-") ? "-" : Math.round(100 * resumenPronostico.semanaTres)} %`} />
                        <DatoCard label="ACCURRACY 5 WEEKS" valor={`${(!resumenPronostico.semanaTres || resumenPronostico.semanaCinco === "-") ? "-" : Math.round(100 * resumenPronostico.semanaCinco)} %`} />
                    </SeccionDatosCenter>
                </BaseCard>}

            <SeccionFormulario titulo={"Captura"}>
                {state.map((el, index) =>
                    <ContenedorInput key={index} label={obtenerNombreSemana(index)} >
                        <InputText disabled={!proveedor?.habilitado} value={state[index]} onChange={(e) => handleChange(index, e.target.value)} placeholder="kg/m2" />
                    </ContenedorInput>)}
            </SeccionFormulario>
            <SeccionBotones>
                <Button label="Guardar" onClick={guardar} />
                <Button label="Cancelar" onClick={props.history.goBack} />
            </SeccionBotones>
        </ContenedorPrincipal>)
}
export default RegistroPronostico;