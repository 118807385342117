import React, { useEffect, useState } from 'react';
import { useGlobal } from 'reactn'
import General from './General';
import PorInvernadero from './PorInvernadero'
import { SelectButton } from 'primereact/selectbutton'
import { getNombrePorID, getObjetoPorID, getProductoYVariedadString } from '../../util/functions';
import { Button } from 'primereact/button';
import SeccionFiltros from '../planeacion_mantenimiento/CapturaAgentes/SeccionFiltros';
import ContenedorFiltro from '../common/ContenedorFiltro';
import SeccionCards from '../common/SeccionCards';
import ContenedorPrincipal from '../planeacion_mantenimiento/CapturaAgentes/ContenedorPrincipal';
import ContenedorHeader from '../common/ContenedorHeader';

const Principal = (props) => {

  const [ciclo,] = useGlobal("ciclo");
  const [usuario] = useGlobal("usuario");
  const [cargandoHome, setCargandoHome] = useGlobal("cargandoHome");
  const [, setNombreModulo] = useGlobal("nombreModulo");
  const [productosYVariedades] = useGlobal("productosYVariedades");
  const [invernaderosFisicos] = useGlobal("invernaderosFisicos");
  const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
  const [, setInvernaderoVirtual] = useGlobal("invernadero");
  const [invernaderos, setInvernaderos] = useState([]);
  const [invernadero, setInvernadero] = useState();

  useEffect(() => {
    setNombreModulo("Home");
    setCargandoHome(true)
  }, [])
  useEffect(() => {
    if (ciclo) obtenerInfoInicial();
  }, [ciclo])
  useEffect(() => {
    if (usuario?.rol === "Agente") props.history.push("/mantenimiento/agente/consulta_semana");
  }, [usuario])

  useEffect(() => {
    if (invernadero && invernadero !== "GENERAL" && invernaderosVirtuales) setInvernaderoVirtual(invernaderosVirtuales.find(el => el.id === invernadero));
  }, [invernadero])

  const obtenerInfoInicial = async () => {
    try {
      let allInvernaderos = invernaderosVirtuales.filter(el => !el.es_externo).map(inv => ({//invernaderosVirtuales.filter(inv=>inv.habilitado);
        ...inv,
        producto: getProductoYVariedadString(inv).split[0],
        invernadero: getNombrePorID(invernaderosFisicos, inv.invernadero_fisico_ref) || "SIN",
        invernaderoFisico: getObjetoPorID(invernaderosFisicos) || {}
      }));
      allInvernaderos.unshift({ nombre: "GENERAL", id: "GENERAL" });
      setInvernaderos(allInvernaderos);
      setInvernadero("GENERAL");
    } catch (error) {
      console.log("ERROR OBTENER INFO INICIAL: " + error.message);
    }
  }

  const templateIconoInvernadero = (inv) =>
    <Button className="invisible-button" tooltip={inv.nombre === "GENERAL" ? "" : getProductoYVariedadString(inv)} tooltipOptions={{ position: "bottom" }}>
      <div className={`home-option bg-${inv?.color} bor-${inv?.color}`}>
        {inv.nombre}
      </div>
    </Button>;

  const esGeneral = (inv) => !inv || inv === "GENERAL";
  const handleDatosChange = e => { if (!cargandoHome) setInvernadero(e.value) };

  const puedeConsultar = () => ["Administrador", "Personal Administrativo"].includes(usuario.rol);
  if (!puedeConsultar()) return null;
  else return (
    <ContenedorPrincipal>
      <ContenedorHeader titulo={`Dashboard ${esGeneral ? "general" : "detallado"}`} subtitulo={`Estatus ${esGeneral ? "semanal" : "de la semana pasada"}`} iconos="dashboard-icon" />

      <SeccionFiltros>
        <ContenedorFiltro label="por invernadero" extra="home-select">
          <SelectButton options={invernaderos} value={invernadero} onChange={handleDatosChange} itemTemplate={templateIconoInvernadero} optionLabel="nombre" optionValue="id" />
        </ContenedorFiltro>
      </SeccionFiltros>

      <SeccionCards>
        {esGeneral(invernadero)
          ? <General />
          : <PorInvernadero invernaderos={invernaderos.filter(el => el !== "GENERAL")} invernadero={invernaderos.find(el => el.id === invernadero)} />}
      </SeccionCards>
    </ContenedorPrincipal>);
}
export default Principal;