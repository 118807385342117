const rejectionStateAnalysis = (data = {}) => {
  let allEmpty = true;
  let allFull = true;
  const dataValues = Object.values(data);
  dataValues.forEach((fact) => {
    const isFull = Boolean(fact);
    if (!isFull) allFull = false;
    if (isFull) allEmpty = false;
  });
  return { allFull, allEmpty };
};

export default rejectionStateAnalysis
