import React, { useEffect, useGlobal, useState } from "reactn";
import { obtenerListaDeSemanas, obtenerNombreSemana } from "../../service/fechas";
import { InputText } from "primereact/inputtext";
import { SelectButton } from 'primereact/selectbutton';
import { InputSwitch } from 'primereact/inputswitch';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { obtenerCiclo } from "../../service/Ciclos";
import { guardarBudget } from "../../service/Budget";
import moment from "moment";
import { Message } from "primereact/message";
import SeccionBotones from "../common/SeccionBotones";
import ContenedorPrincipal from "../common/ContenedorPrincipal";
import ContenedorHeader from "../common/ContenedorHeader";
import SeccionFiltros from "../common/SeccionFiltros";
import { getProductoYVariedadString } from "../../util/functions";
import SeccionFormulario from "../common/SeccionFormulario";
import ContenedorFiltro from "../common/ContenedorFiltro";
import ContenedorInput from "../common/ContenedorInput";
export default function EditarBudget(props) {
    const [ciclo, setCiclo] = useGlobal("ciclo");
    const [cicloObj, setCicloObj] = useState(null);
    const [cargando, setCargando] = useGlobal("cargando");

    const [started, setStarted] = useState(0);
    const [inicio, setInicio] = useState();
    const [final, setFinal] = useState();
    const [options, setOptions] = useState([]);
    const [budget, setBudget] = useState([]);
    const [invernadero, setInvernadero] = useGlobal("invernadero");
    const [nombreModulo, setNombreModulo] = useGlobal("nombreModulo");
    const [productosYVariedades] = useGlobal("productosYVariedades");

    const [error, setError] = useState(false);
    const atras = (params) => {
        props.history.goBack();
    }

    function guardar() {

        for (let x = 0; x < budget.length; x++) {
            if (budget[x].value === "") {
                setError(true);
                return;
            }
        }
        let obj = {}
        if (inicio) {
            obj = {
                semana_inicio_real: inicio.time,
                estimacion_semanal: budget
            }
        }
        else {

            obj = {
                semana_inicio_real: options[0].time,
                estimacion_semanal: budget
            }
        }

        guardarBudget(ciclo, invernadero.id, obj).then(resp => {
            props.history.goBack();
            let copia = { ...invernadero };
            copia["budget"] = { ...obj };
            setInvernadero(copia);
        }).catch(err => {
        })

    }
    function cancelar() {
        props.history.goBack();
    }
    function handleInicio(ev) {
        setInicio(ev.value);
    }
    function handleFinal(ev) {
        setFinal(ev.value);
    }
    function handleBudget(ev, index) {
        let copia = budget.slice();
        copia[index].value = ev.target.value;
        setBudget(copia);
    }

    useEffect(() => {
        if (ciclo) {
            setCargando(true)
            obtenerCiclo(ciclo).then(cicloObj => {
                let count = 0
                setCicloObj(cicloObj);
                let estimacion_semanal = invernadero.budget.estimacion_semanal;
                setBudget(estimacion_semanal.slice());
                if (estimacion_semanal.length > 0) {
                    setInicio(estimacion_semanal[0]);
                    setFinal(estimacion_semanal[estimacion_semanal.length - 1]);
                }

                setOptions(obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre));
                setCargando(false)
            }).catch(error => {
                setCargando(false)
                    (error)
            })
        }

    }, [ciclo])
    useEffect(() => {
        if (ciclo && inicio) {
            if (final) {
                obtenerCiclo(ciclo).then(cicloObj => {
                    setCicloObj(cicloObj);
                    setBudget(obtenerListaDeSemanas(inicio.time, final.time + 24 * 7 * 60 * 60).map(week => {
                        let budgetSelected = {
                            time: week.time,
                            nombre: week.nombre,
                            value: ""
                        }
                        let est = invernadero.budget.estimacion_semanal.find(el => el.time === week.time);
                        if (est) {
                            return est;
                        } else
                            return budgetSelected;
                    }));

                })
            } else {
                obtenerCiclo(ciclo).then(cicloObj => {
                    setCicloObj(cicloObj);
                    setBudget(obtenerListaDeSemanas(inicio.time, cicloObj.semana_cierre).map(week => {
                        let budgetSelected = {
                            time: week.time,
                            nombre: week.nombre,
                            value: ""
                        }
                        let est = invernadero.budget.estimacion_semanal.find(el => el.time === week.time);
                        if (est) {
                            return est;
                        } else
                            return budgetSelected;
                    }));

                })
            }



            //(obtenerListaDeSemanas(cicloObj.semana_inicio,cicloObj.semana_cierre));

        } else {
            if (ciclo && final) {
                obtenerCiclo(ciclo).then(cicloObj => {
                    setCicloObj(cicloObj);
                    setBudget(obtenerListaDeSemanas(cicloObj.semana_inicio, final.time + 24 * 7 * 60 * 60).map(week => {
                        let budgetSelected = {
                            time: week.time,
                            nombre: week.nombre,
                            value: ""
                        }
                        let est = invernadero.budget.estimacion_semanal.find(el => el.time === week.time);
                        if (est) {
                            return est;
                        } else
                            return budgetSelected;
                    }));

                })
            }
        }
    }, [inicio, final])
    useEffect(() => {
        setNombreModulo("Empaque");
    }, [])

    return (
        <ContenedorPrincipal >
            <ContenedorHeader col="p-col p-col-auto" titulo="Editar budget del ciclo" subtituloInv={getProductoYVariedadString(invernadero)}
                invernaderoNombre={invernadero?.nombre} atras={props.history.goBack} iconos={`icon-general icon-lort icon-id bor-${invernadero?.color} bg-${invernadero?.color} ${invernadero?.habilitado ? "" : "Deshabilitado"}`} />

            <SeccionFiltros extra="p-justify-center">
                <SeccionFormulario col="p-col-10">
                    <ContenedorInput label="Semana de inicio">
                        <Dropdown id="inicio" placeholder="Seleccionar" options={options}
                            optionLabel="nombre" dataKey="time" onChange={handleInicio} value={inicio} />
                    </ContenedorInput>
                    <ContenedorInput label="Semana final del budget">
                        <Dropdown id="inicio" placeholder="Seleccionar" options={options}
                            optionLabel="nombre" dataKey="time" onChange={handleFinal} value={final} />
                    </ContenedorInput>
                </SeccionFormulario>

                <SeccionFormulario titulo="Editar Budget semanal" col="p-col-10">
                    {budget.map((week, index) => {
                        let count = -1
                        count++
                        if (week !== undefined) {
                            return <div key={index} className="form-group p-grid p-fluid pallet-dailyweight">
                                <div className="p-col-auto p-justify-end p-grid p-align-center m-0">
                                    <label htmlFor={`budget_${week.time}`}>Semana {obtenerNombreSemana(week.time)}</label>
                                </div>
                                <div className="p-col">
                                    <InputText keyfilter={"num"} id={week.time} value={week.value} onChange={(ev) => handleBudget(ev, index)} />
                                </div>
                                <div className="p-col-auto p-justify-end p-grid p-align-center m-0">
                                    <label >Kg/m²</label>
                                </div>
                            </div>
                        }
                    })}
                    {error && <Message severity="error" text="Deben llenarse todos los campos" />}
                </SeccionFormulario>
            </SeccionFiltros>
            <SeccionBotones col="p-col-11">
                <Button label="Cancelar" className="p-button-secondary" onClick={cancelar} />
                <Button label="Guardar" onClick={guardar} />
            </SeccionBotones>
        </ContenedorPrincipal >)
}//272