import React, { useGlobal, useState, useEffect } from "reactn";
import { obtenerCiclo, esCicloActual, noHaSidoClausurado } from "../../service/Ciclos";
import { obtenerListaDeSemanas, obtenerInicioSemanaDia, obtenerDiasDeSemana, obtenerIndiceDiaSemana } from "../../service/fechas";
import { Dropdown } from 'primereact/dropdown';
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { registrarEmpaque, obtenerPallets, obtenerConsecutivoPallets } from "../../service/Producto";
import { exportAllDeclaration } from "@babel/types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faChartBar } from '@fortawesome/free-solid-svg-icons';
import { actualizarSobranteSemana } from "../../service/Ordenes";
import { dosDecimales, getObjetoPorID, getProductoYVariedadString } from "../../util/functions";
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { obtenerProveedoresInvernadero } from '../../service/Queries/Proveedores'
import icono from '../../images/icons/external-icon.svg';
import ContenedorHeader from "../common/ContenedorHeader";
import { MultiSelect } from "primereact/multiselect";

const nombresDias = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
export default function RegistroEmpaque(props) {
  function eliminarPallet(id) {
    let el = pallets.find(el => el.id === id);
    let copia = pallets.filter(el => el.id !== id);
    if (el.tipo === "POR_REGISTRAR") {
      copia.forEach(el => {
        if (el.id > id) {
          el.id--;
        }
      })
    }
    setPallets(copia);
  }
  function guardar() {
    setCargando(true)
    registrarEmpaque(ciclo, invernadero.id, dia.time, pallets, consecutivo).then(resp => {

      let inicioSemana = obtenerInicioSemanaDia(dia.time).unix();
      props.history.goBack();
    }).catch(err => {
      if (err.code && err.code === "permission-denied") {
        setMostrarModalPermisos(true);
      }
      console.log("HUBO UN ERROR AL REGISTRAR EMPAQUE");
      console.log(dia);
      console.log(err);
    }).finally(err => {
      setCargando(false);
    })
  }

  const [invernadero, setInvernadero] = useGlobal("invernaderoExterno");
  const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector();
  const [weekGlobal, setWeekGlobal] = useGlobal("weekGlobal");
  const [invernaderos] = useGlobal("invernaderosExternos");
  const [ciclo,] = useGlobal("ciclo");
  const [ciclos,] = useGlobal("ciclos");
  const [dias, setDias] = useState([]);
  const [dia, setDia] = useState();
  const [total, setTotal] = useState(0);
  const [pesoPromedio, setPesoPromedio] = useState(0);
  const [pallets, setPallets] = useState([]);
  const [palletsFiltrados, setPalletsFiltrados] = useState([]);
  const [consecutivo, setConsecutivo] = useState(1);
  const [, setNombreModulo] = useGlobal("nombreModulo");
  const [, setMostrarModalPermisos] = useGlobal("mostrarModalPermiso");
  const [usuario,] = useGlobal("usuario");
  const [, setCargando] = useGlobal("cargando");
  const [proveedores, setProveedores] = useState([]);
  const [proveedoresConPallets, setProveedoresConPallets] = useState([]);
  const [proveedoresSeleccionados, setProveedoresSeleccionados] = useState([]);
  const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
  const [invernaderosExternos] = useGlobal("invernaderosExternos");
  
  useEffect(() => {
    if(pallets.length > 0){
      const newFilteredPallets = proveedoresSeleccionados.map((prov) => {
        const palletsData = pallets.find((pallet) => pallet?.proveedor_ref === prov.id)
        return palletsData
      })
      setPalletsFiltrados(newFilteredPallets)
    }
  }, [proveedoresSeleccionados])

  useEffect(() => {
    setHabilitadoSelectorCiclo(false);
    setNombreModulo("Empaque");
  }, [])
  const puedeEditar = (proveedorObj = {habilitado: true}) => {
    let ahora = Math.round(Date.now() / 1000);
    return noHaSidoClausurado(ciclo, ciclos) && (usuario && (!usuario.bloqueado || dia && dia.time + 7 * 24 * 60 * 60 > ahora)) && proveedorObj.habilitado;
  }

  useEffect(() => {
    if (ciclo && (!invernaderosExternos || invernaderosExternos?.length === 0))
      props.history.push("/producto");
    else if (ciclo) {
      setInvernadero(invernaderosExternos[0]);
      let allDias = obtenerDiasDeSemana(week.time).map((dia, index) => ({ nombre: nombresDias[index], time: dia }))
      setDias(allDias)
    }
  }, [ciclo])

  useEffect(() => {
    if (ciclo) {
      updateCicloWeekSelector(ciclo);
    }
  }, [ciclo])
  useEffect(() => {
    if (week.nombre != "Cargando")
      setWeekGlobal(week);
  }, [week]);
  useEffect(() => {
    let copia = pallets.slice();
    let total = copia.reduce((acc, curr) => {
      let numero = parseInt(curr.cantidad);
      if (isNaN(numero)) {
        return acc;
      } else {
        return acc + numero;
      }
    }, 0);
    setTotal(total);
    let totalCajas = copia.reduce((acc, curr) => {
      let numero = parseInt(curr.cajas);
      if (isNaN(numero)) {
        return acc;
      } else {
        return acc + numero;
      }
    }, 0);
    setPesoPromedio(total / totalCajas);
  }, [pallets])
  useEffect(() => {
    if (dias && dias.length > 0) {

      let today = Math.round(Date.now() / 1000);
      let indice = obtenerIndiceDiaSemana(today);


      setDia(dias[indice]);
    }
  }, [dias])
  useEffect(() => {
    if (invernadero && dia && dias && dias.length > 0) {





      setCargando(true);


      obtenerPallets(ciclo, invernadero.id, dia.time).then(resp => {
        obtenerProveedoresInvernadero(ciclo, invernadero.id).then((proveedoresInvernadero) => {
          setProveedores(proveedoresInvernadero);
          agregarPallets(proveedoresInvernadero, resp);
        }).catch((err) => {
          throw err;
        });;



      }).catch(err => {
        console.log("NO SE PUDIERON OBTENER EMPAQUES");
      }).finally(el => {
        setCargando(false);
      })

      obtenerConsecutivoPallets(ciclo, invernadero.id).then(resp => {
        setConsecutivo(resp);
      }).catch(err => {
        console.log(err);
      })
    }
  }, [dias, dia, invernadero])
  useEffect(() => {

  }, [consecutivo])
  useEffect(() => {
    setDias(
      obtenerDiasDeSemana(week.time).map((dia, index) => {
        return {
          nombre: nombresDias[index],
          time: dia
        }
      })
    )
  }, [week])
  function agregarPallets(proveedores, palletsExistentes) {
    let copia = [];

    proveedores.forEach((proveedor, index) => {
      let pallet = palletsExistentes.find(el => el.proveedor_ref === proveedor?.id)
      const proveedorObj = getObjetoPorID(proveedores,  proveedor?.id,)
      
      if (pallet?.cantidad > 0) {
        copia.push(pallet)
      } else if(proveedorObj.habilitado) {
        copia.push({
          id: consecutivo + index + 1,
          proveedor_ref: proveedor?.id,
          cantidad: "",
          tipo: "POR_REGISTRAR",
          id_hex: `${invernadero.nombre}_${(consecutivo + index + 1)}`

        });
      }
    })
    const proveedoresMapped = copia.map((pallet) => proveedores.find((prov) => prov.id === pallet?.proveedor_ref))
    setProveedoresConPallets(proveedoresMapped)
    setProveedoresSeleccionados(proveedoresMapped)
    setPallets(copia);
    setPalletsFiltrados(copia)
  }

  function handlePallet(ev) {
    let copia = pallets.slice();
    let el = copia.find(pallet => pallet?.id == ev.target.id);
    el.cantidad = ev.target.value;
    setPallets(copia);
  }
  function handleCajas(ev) {
    let copia = pallets.slice();
    let id = ev.target.id.split("_")[1];
    let el = copia.find(pallet => pallet?.id == id);
    el.cajas = ev.target.value;
    setPallets(copia);
  }
  const irGrafica = (params) => { props.history.push("/empaque/graficas/distribucion_presentaciones_semana"); }
  const irReporte = (params) => { props.history.push("/empaque/reporte_externo"); }
  const irEditarBudget = (params) => { props.history.push("/producto/editar_budget"); }
  return (
    <div className="p-grid p-justify-center">
      <ContenedorHeader col="p-col p-col-auto" titulo="Registro de cosecha" subtituloInv={getProductoYVariedadString(invernadero)} atras={props.history.goBack}
        invernaderoNombre={<img width="60" alt="icono" src={icono} />} iconos={`icon-general icon-external icon-id bor-${invernadero?.color} bg-${invernadero?.color}`} />

      <div className="p-col p-col-auto">
        <div className="btn-row">
          <Button className="reactbtn-icon" onClick={irGrafica} label="Presentaciones por semana">
            <FontAwesomeIcon icon={faChartBar} />
          </Button>
          <Button className="reactbtn-icon" onClick={irReporte} label="Reporte general de empaque" />
        </div>
      </div>

      <div className="p-col-12">
        <div className="p-grid filtros">
          <div className="form-group p-col">
            <label htmlFor="cinco">Invernadero</label>
            <Dropdown id="filtroInvernadero" placeholder="Seleccionar"
              dataKey="id" optionLabel="nombre"
              options={invernaderos} value={invernadero}
              onChange={(e) => { setInvernadero(e.target.value) }} />
          </div>
          <div className="form-group p-col">
            <label htmlFor="cinco">Proveedores</label>
            <MultiSelect id="filtroInvernadero" placeholder="Seleccionar"
              selectedItemsLabel={`${proveedoresSeleccionados.length} proveedores seleccionados`}
              dataKey="id" optionLabel="nombre"
              options={proveedoresConPallets} value={proveedoresSeleccionados}
              onChange={(e) => { setProveedoresSeleccionados(e.target.value) }} />
          </div>
          <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
          <div className="form-group p-col">
            <label htmlFor="cinco">Día</label>
            <Dropdown id="filtroDias" placeholder="Seleccionar"
              dataKey="time" optionLabel="nombre"
              options={dias} value={dia}
              onChange={(e) => { setDia(e.target.value) }} />
          </div>
        </div>
      </div>
      <div className="p-col-10">
        <div className="p-grid p-fluid ">
          <div className="p-col-12">
            <p className="section-title">Semana {week.nombre}</p>

            {invernadero ? <div className="card">
              {palletsFiltrados.map(pallet => {
                if(!pallet) return null
                let proveedor = proveedores.find(el => el.id === pallet?.proveedor_ref);
                return (
                  <div key={`${pallet?.id}-${pallet.proveedor_ref}`} className="form-group p-grid p-fluid pallet-dailyweight">
                    <div className="p-col-auto">
                      <p className="pallet-id">Pallet {pallet?.id_hex}  {proveedor ? `- ${proveedor?.nombre}` : ""}</p>
                    </div>
                    <div className="p-col p-grid ">

                      <div className="p-col-auto p-justify-end p-grid p-align-center m-0">
                        <label htmlFor={pallet?.id}>Kg</label>
                      </div>
                      <div className="p-col">
                        <InputText id={pallet?.id} placeholder="Kg en pallet" value={pallet?.cantidad} onChange={handlePallet} disabled={!puedeEditar(proveedor)} />
                      </div>
                    </div>
                    <div className="p-col p-grid ">
                      <div className="p-col-auto p-justify-end p-grid p-align-center m-0">
                        <label htmlFor={`$cajas_{pallet?.id}`} >Cajas </label>
                      </div>

                      <div className="p-col">
                        <InputText placeholder="Cantidad de cajas en pallet" id={`cajas_${pallet?.id}`} value={pallet?.cajas} onChange={handleCajas} disabled={!puedeEditar(proveedor)} />
                      </div>
                    </div>

                    {pallet?.cajas && <div className="p-col p-grid ">
                      <div className="p-col-auto p-justify-end p-grid p-align-center m-0">
                        <p className="pallet-caja-prom">{dosDecimales(pallet?.cantidad / pallet?.cajas)}Kg</p>
                        Promedio / caja
                      </div>
                    </div>}

                    {puedeEditar(proveedor) && <div className="p-col-auto">
                      <button className="p-button" onClick={() => { eliminarPallet(pallet?.id) }}>
                        <FontAwesomeIcon icon={faTrashAlt} onClick={() => { eliminarPallet(pallet?.id) }} />
                      </button>
                    </div>}
                  </div>)
              })}

              <div className="pallet-totalweight">
                Total: {total} kg - Peso promedio caja: {isNaN(pesoPromedio) || !isFinite(pesoPromedio) ? "-" : dosDecimales(pesoPromedio)}
              </div>
            </div>
              :
              <div>Este invernadero aún no tiene budget </div>}
          </div>
        </div>

        {puedeEditar() && <div className="p-grid p-justify-end btn-row">
          <Button label="Cancelar" className="p-button-secondary" onClick={props.history.goBack} />
          <Button label="Guardar" onClick={guardar} />
        </div>}
      </div>
    </div>
  );
}//405