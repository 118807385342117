import React, { useState, useEffect } from 'react'
import ContenedorPrincipal from '../../common/ContenedorPrincipal'
import SeccionFiltros from '../../common/SeccionFiltros'
import CardGrupo from './CardGrupo'
import { obtenerProveedores, obtenerProveedoresHabilitados } from '../../../service/Queries/Proveedores';
import { useWeekSelector } from '../../common/WeekSelector';
import { obtenerReporteCompraExterna, obtenerReporteCompraExternaTodos } from "../../../service/CompraExterna";
import { useGlobal } from 'reactn';
import { CORTE_PROV_MAYORITARIO_DOS_HECTAREAS } from '../../../constants';
import ProveedorCard from '../ProveedorCard';

const CardsAgrupados = (props) => {
    const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector(true);
    const [cicloId,] = useGlobal("ciclo");
    const [invernaderosVirtuales,] = useGlobal("invernaderosVirtuales");
    const [productosYVariedades] = useGlobal("productosYVariedades")
    const [totalProveedores, setTotalProveedores] = useState([])
    const [totalProveedoresMasDosHectarea, setTotalProveedoresMasDosHectarea] = useState([])
    const [proveedoresMas, setProveedoresMas] = useState({})
    const [proveedoresMenos, setProveedoresMenos] = useState({})
    const [reporteProveedores, setReporteProveedores] = useState([])
    const [, setCargando] = useGlobal("cargando");

    useEffect(() => {
        if (cicloId && week.nombre !== "Cargando") obtenerDatosIniciales();
    }, [cicloId,invernaderosVirtuales,  week])
    useEffect(() => {
        if(totalProveedores.length === 0) return
        setCargando(true)
        obtenerCardsMasDeUnaHectarea()
    }, [totalProveedores, week, reporteProveedores])

    const obtenerCardsMasDeUnaHectarea = async() => {
        const proveedoresMasDosHectarea = totalProveedores.filter(prov => prov.area_invernadero > CORTE_PROV_MAYORITARIO_DOS_HECTAREAS);
        const reporte = await obtenerReporteCompraExternaTodos(cicloId, week.time, proveedoresMasDosHectarea)
        const inyeccionReporteAProveedores = proveedoresMasDosHectarea.map(proveedor => {
            const producto = reporte.find(r => r[0].id == proveedor.id);
            if (producto) return {...proveedor, reporte: producto};
            return proveedor
        });

        const proveedoresConDatosOHabilitados = inyeccionReporteAProveedores.filter((prov)=>{
            const hayDatos = !!prov.reporte?.[2]?.["semanal"] || !!prov.reporte?.[3]?.["semanal"] || !!prov.reporte?.[2]?.["real"] || !!prov.reporte?.[3]?.["real"] || !!prov.reporte?.[2]?.["diario"]

            return hayDatos || prov.habilitado;
        })

        setTotalProveedoresMasDosHectarea(proveedoresConDatosOHabilitados)
        setCargando(false)
    }

    const obtenerDatosIniciales = async (params) => {
        setCargando(true);
        const [totalProveedores, proveedoresHabilitados, reporte] = await Promise.all([obtenerProveedores(cicloId), obtenerProveedoresHabilitados(cicloId), obtenerReporteCompraExterna(cicloId, week.time)]);
        setTotalProveedores(totalProveedores)
        //por cada proveedor, buscar elemento correspondiente en reporte y agregarlo al objeto
        proveedoresHabilitados.forEach(proveedor => {
            let producto = reporte.find(rep => rep[0].id == proveedor.id);
            if (producto) proveedor.reporte = producto;;
        });
        let proveedoresMayoritarios = proveedoresHabilitados.filter(p => {
            let area = parseFloat(p["area_invernadero"])
            return area > CORTE_PROV_MAYORITARIO_DOS_HECTAREAS
        });
        let proveedoresMinoritarios = proveedoresHabilitados.filter(p => {
            let area = parseFloat(p["area_invernadero"])
            return area <= CORTE_PROV_MAYORITARIO_DOS_HECTAREAS
        });
        let proveedoresMas = {}
        //obtener suma de dato semanal de cada proveedor mayoritario y asignarlas al objeto
        proveedoresMas["semanal"] = proveedoresMayoritarios.reduce((acc, proveedor) => {
            let dato = parseFloat(proveedor.reporte[2]["semanal"])
            return acc + dato
        }, 0)
        //obtener suma de areas de proveedores mayoritarios
        proveedoresMas["area"] = proveedoresMayoritarios.reduce((acc, proveedor) => {
            let dato = parseFloat(proveedor.area_invernadero)
            return acc + dato
        }, 0)
        // dividir dato semanal de proveedoresMas entre el total de areas de proveedores mayoritarios
        proveedoresMas["semanalkgm2"] = (proveedoresMas["semanal"] / proveedoresMas["area"])
        // hacer lo mismo para el dato diario de proveedoresMas
        proveedoresMas["diario"] = proveedoresMayoritarios.reduce((acc, proveedor) => {
            let dato = parseFloat(proveedor.reporte[2]["diario"])
            return acc + dato
        }, 0)
        // dividir dato diario de proveedoresMas entre el total de areas de proveedores mayoritarios
        proveedoresMas["diariokgm2"] = (proveedoresMas["diario"] / proveedoresMas["area"])
        // hacer lo mismo para el dato real de proveedoresMas   
        proveedoresMas["real"] = proveedoresMayoritarios.reduce((acc, proveedor) => {
            let dato = parseFloat(proveedor.reporte[2]["real"])
            return acc + dato
        }, 0)
        // dividir dato real de proveedoresMas entre el total de areas de proveedores mayoritarios
        proveedoresMas["realkgm2"] = (proveedoresMas["real"] / proveedoresMas["area"]) || 0
        //obtener porcentaje entre realkgm2 y semanalkgm2
        proveedoresMas["realvssemanal"] = Math.round((proveedoresMas["realkgm2"] / proveedoresMas["semanalkgm2"]) * 100) || 0
        //obtener porcentaje entre realkgm2 y diariokgm2
        proveedoresMas["realvsdiario"] = Math.round((proveedoresMas["realkgm2"] / proveedoresMas["diariokgm2"]) * 100) || 0
        //repetir proceso para proveedoresMinoritarios
        let proveedoresMenos = {}
        proveedoresMenos["semanal"] = proveedoresMinoritarios.reduce((acc, proveedor) => {
            let dato = parseFloat(proveedor.reporte[2]["semanal"])
            return acc + dato
        }, 0)
        proveedoresMenos["area"] = proveedoresMinoritarios.reduce((acc, proveedor) => {
            let dato = parseFloat(proveedor.area_invernadero)
            return acc + dato
        }, 0)
        proveedoresMenos["semanalkgm2"] = (proveedoresMenos["semanal"] / proveedoresMenos["area"]) || 0
        proveedoresMenos["diario"] = proveedoresMinoritarios.reduce((acc, proveedor) => {
            let dato = parseFloat(proveedor.reporte[2]["diario"])
            return acc + dato
        }, 0)
        proveedoresMenos["diariokgm2"] = (proveedoresMenos["diario"] / proveedoresMenos["area"])
        proveedoresMenos["real"] = proveedoresMinoritarios.reduce((acc, proveedor) => {
            let dato = parseFloat(proveedor.reporte[2]["real"])
            return acc + dato
        }, 0)
        proveedoresMenos["realkgm2"] = (proveedoresMenos["real"] / proveedoresMenos["area"]) || 0
        proveedoresMenos["realvssemanal"] = Math.round((proveedoresMenos["realkgm2"] / proveedoresMenos["semanalkgm2"]) * 100) || 0
        proveedoresMenos["realvsdiario"] = Math.round((proveedoresMenos["realkgm2"] / proveedoresMenos["diariokgm2"]) * 100) || 0
        setProveedoresMas(proveedoresMas);
        setProveedoresMenos(proveedoresMenos);

    }
    if (props.showInternoPantalla)
        return <CardGrupo path={props.path} titulo="LORT > 2 hectárea" tipo="superior" datos={proveedoresMas} />;
    else return (
        <ContenedorPrincipal>
            <SeccionFiltros>
            </SeccionFiltros>
            <CardGrupo titulo="LORT <= 2 hectárea" tipo="inferior" datos={proveedoresMenos} />
            {totalProveedoresMasDosHectarea.length > 0 &&
                totalProveedoresMasDosHectarea.map(proveedor =>
                    <ProveedorCard key={proveedor.id} proveedor={proveedor} productos={productosYVariedades} reporte={proveedor.reporte} />)
            }
        </ContenedorPrincipal>);
}
export default CardsAgrupados;//127