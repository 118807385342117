
import * as firebase from "firebase/app";
import "firebase/firestore";
import { ROL_AGENTE, segundosSemana } from "../../constants";
import {uploadStatistics} from '../Estadisticas'

function registrarInvocaciones(count){
    uploadStatistics(count)
}


export const getAgentes = async (cicloId) => {
    const db = firebase.firestore();
    let snap = await db.collection("usuarios").where("rol", "==", ROL_AGENTE).get();
    registrarInvocaciones(snap.size)
    return snap.docs.map(doc => {
        return {
            id: doc.id,
            ...doc.data()
        }
    });
}
export const obtenerActividadesAgenteSemana = async (cicloId, semanaTime, agenteId) => {
    let response = await firebase.firestore().collection("ciclos").doc(cicloId).collection("planeacion_actividades").where("agente_ref", "==", agenteId)
        .where("dia", ">=", semanaTime).where("dia", "<", semanaTime + segundosSemana).get();
    registrarInvocaciones(response.size)
    let actividades = response.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return actividades
}
export const guardarActividad = async (cicloId, data, tareaID = false) => {
    let result = {}
    if (tareaID) {
        result = await firebase.firestore().collection("ciclos").doc(cicloId).collection("planeacion_actividades").doc(tareaID).set(data, { merge: true });
        return { id: tareaID, ...data };

    }
    else {
        result = await firebase.firestore().collection("ciclos").doc(cicloId).collection("planeacion_actividades").add(data);
        return { id: result.id, ...data };
    }
}

export const actualizarActividad = async (cicloId, tareaId, objeto) => {
    objeto.tareaParcial = !!objeto.tareaParcial;
    let result = await firebase.firestore().collection("ciclos").doc(cicloId).collection("planeacion_actividades").doc(tareaId).update(objeto);
    return objeto;
}
export const eliminarActividad = async (cicloId, tareaId) => {
    const db = firebase.firestore();
    let result = await db.collection("ciclos").doc(cicloId).collection("planeacion_actividades").
        doc(tareaId).delete();
    return result;
}
export const guardarActividadEmergencia = async (cicloId, state) => {
    const db = firebase.firestore();
    let result = await db.collection("ciclos").doc(cicloId).
        collection("planeacion_actividades").add(state);
    return result.id;
}
