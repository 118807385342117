import { getGlobal } from "reactn"
import {
    obtenerDiasDeSemana, obtenerListaDeSemanas,
    obtenerInicioSemanaDia, obtenerDiaActual,
    obtenerHora, flexDate, getInicioSemanaDia,
} from "./fechas";
import * as firebase from "firebase/app";
import "firebase/firestore";
import { obtenerInvernaderosVirtualesHabilitados, obtenerInvernadero, obtenerInvernaderosVirtualesInternos } from "./Invernaderos";
import { obtenerProyeccionDesde, obtenerProyeccionSemana, obtenerProyeccionSemanaConsiderarPronostico, obtenerTotalBudget } from "./Budget";
import { getKgm2OrdenadosHasta, getkgm2SemanalOrdenes, getOrdenesSemanaPesosTotal, obtenerkgm2SemanalOrdenes, obtenerPesoOrdenadoCiclo } from './Ordenes';
import { obtenerCiclo, obtenerSemanasTranscurridasBuddget } from "./Ciclos";
import {
    obtenerPesoSemana, obtenerPesoRegistradoCiclo,
    obtenerAreaInvernadero, obtenerPesoRegistradoSemana, obtenerPesoProyectado, obtenerAreaInvernaderoSync, obtenerPesoSemanaProveedor, getPesoProyectado
} from './Producto';
import { DeepClone, dosDecimales, getObjetoPorID } from "../util/functions";
import { obtenerDefectos } from "./Calidad";
import { horaCorte } from "../constants";
import { getInicialRealInteligente, obtenerPronosticoYProyeccion } from "./Pronosticos";
import { uploadStatistics } from './Estadisticas'


function registrarInvocaciones(count) {
    uploadStatistics(count)
}
export const obtenerDetalleReporte = (cicloObj, invernadero) => {
    const semanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);

    return new Promise((resolve, reject) => {
        let promises = [];
        semanas.forEach(semana => {
            promises.push(obtenerDetalleReporteSemanal(cicloObj, invernadero, semana))
        })
        Promise.all(promises).then((values) => {
            resolve(values);
        }).catch((err) => {
            reject(err);
        });
    })

    //por cada semana
    // obtener detalle reporte semanal 
}
export const obtenerDetalleReporteSemanal = (cicloObj, invernadero, semanaObj) => {
    const semana = semanaObj.time;
    const nombre = semanaObj.nombre;
    return new Promise(async (resolve, reject) => {
        try {
            invernadero = await obtenerInvernadero(cicloObj.id, invernadero);
            let producto = getObjetoPorID(getGlobal().productosYVariedadesTodos, invernadero.producto_ref);
            let detArea = invernadero.detalles_fisicos.find(el => el.detalle === "Area (m²)")
            let area = parseInt(detArea["norte"]) + parseInt(detArea["sur"])
            let ahora = Math.round(Date.now() / 1000)
            let inicioSemana = obtenerInicioSemanaDia(ahora).unix();
            let budget = await obtenerProyeccionSemanaConsiderarPronostico(cicloObj.id, invernadero.id, semana, inicioSemana);
            
            let [inv, sem, kgm2EmpaqueSemanal] = await obtenerReporteEmpaqueInvernaderoSemana(cicloObj, invernadero, semanaObj);
            if(semana < 1683531154){ kgm2EmpaqueSemanal = await getkgm2semanalSync(cicloObj.id, invernadero, semana, area); }
            
            let kgm2OrdenesSemana = await obtenerkgm2SemanalOrdenes(cicloObj.id, invernadero.id, semana, area);
            let packout = kgm2OrdenesSemana / kgm2EmpaqueSemanal;
            let defectos = await obtenerDefectos(cicloObj.id, invernadero.id, semanaObj.time, producto.tipo_calidad_inicial);
            
            if(inv.nombre == "H1" && semanaObj.time === 1666591200) console.log(`H1: obtenerDetalleReporteSemanal - `, { final: kgm2OrdenesSemana, inicial: kgm2EmpaqueSemanal, semana, packout});

            let obj = { budget, kgm2EmpaqueSemanal, kgm2OrdenesSemana, packout, semana, nombre, defectos, realInteligente: kgm2EmpaqueSemanal };
            resolve(obj);

        } catch (error) {
            console.log("ERROR ODRS");
            console.log(error);
            reject(error);
        }
    })
}


export function obtenerReporteEmpaque(cicloObj, tipo, invernaderos) {
    // 
    // 
    let promisesInv = [];
    return new Promise((resolve, reject) => {

        invernaderos.forEach(inv => {
            promisesInv.push(obtenerReporteEmpaqueInvernadero(cicloObj, inv, tipo));
        })

        Promise.all(promisesInv).then(values => {
            resolve(values);
        }).catch(err => {
            reject(err);
        });
    })

}
export const obtenerReporteEmpaqueExterno = async (cicloId, proveedores, invernaderosExternos) => {
    let promisesInv = [];
    let objCiclo = await obtenerCiclo(cicloId)
    //console.log({proveedores})
    proveedores.forEach(prov => {
        const invernadero = getObjetoPorID(invernaderosExternos, prov.invernadero_ref)
        promisesInv.push(obtenerReporteEmpaqueProveedor(objCiclo, prov, invernadero));
    })

    let values = await Promise.all(promisesInv)
    return values;
}


function obtenerReporteEmpaqueInvernadero(objCiclo, invernadero, tipo) {
    return new Promise((resolve, reject) => {
        let promises = [];
        let semanas = obtenerListaDeSemanas(objCiclo.semana_inicio, objCiclo.semana_cierre);
        semanas.forEach(semana => {
            if (tipo === "inicial") {
                promises.push(obtenerReporteEmpaqueInvernaderoSemana(objCiclo, invernadero, semana));
            } else {
                promises.push(obtenerReporteEmpaqueInvernaderoSemanaFinal(objCiclo, invernadero, semana));
            }
        })
        Promise.all(promises).then(values => {
            resolve(values);
        }).catch(err => { reject(err) });
    });

}

function obtenerReporteEmpaqueProveedor(objCiclo, proveedor, invernadero) {
    return new Promise((resolve, reject) => {
        let promises = [];
        let semanas = obtenerListaDeSemanas(objCiclo.semana_inicio, objCiclo.semana_cierre);
        semanas.forEach(semana => {

            promises.push(obtenerReporteEmpaqueProveedorSemana(objCiclo, proveedor, invernadero, semana));

        })
        Promise.all(promises).then(values => {
            resolve(values);
        }).catch(err => { reject(err) });
    });

}

export function obtenerReporteEmpaqueInvernaderoSemana(objCiclo, invernadero, semana) {
    return new Promise((resolve, reject) => {
        let detArea = invernadero.detalles_fisicos.find(el => el.detalle === "Area (m²)")
        let area = parseInt(detArea["norte"]) + parseInt(detArea["sur"])

        getInicialRealInteligente(objCiclo.id, invernadero, semana.time).then(resp => {
            resolve([invernadero, semana, resp]);
        }).catch(err => {
            reject(err);
        })
    });
}
function obtenerReporteEmpaqueProveedorSemana(objCiclo, proveedor, invernadero, semana) {
    return new Promise((resolve, reject) => {
        let area = parseFloat(proveedor.area_invernadero)

        obtenerPesoSemanaProveedor(objCiclo.id, invernadero.id, proveedor, semana.time).then(resp => {
            if (proveedor.id == "fHffzKRvyTFexAiwiMD4") {





            }
            resolve([proveedor, semana, dosDecimales(resp / area)]);
        }).catch(err => {
            reject(err);
        })
    });
}

export function obtenerReporteEmpaqueInvernaderoSemanaFinal(objCiclo, invernadero, semana) {
    return new Promise((resolve, reject) => {
        if (invernadero.es_externo) {
            return obtenerAreaInvernadero(objCiclo.id, invernadero.id).then(respArea => {
                return obtenerkgm2SemanalOrdenes(objCiclo.id, invernadero.id, semana.time, respArea).then((result) => {
                    return resolve([invernadero, semana, result]);
                }).catch((err) => {
                    reject(err);
                });
            })
        }
        else {
            let detArea = invernadero.detalles_fisicos.find(el => el.detalle === "Area (m²)")
            let area = parseInt(detArea["norte"]) + parseInt(detArea["sur"])
            return obtenerkgm2SemanalOrdenes(objCiclo.id, invernadero.id, semana.time, area).then(result => {
                return resolve([invernadero, semana, result]);
            }).catch((err) => {
                reject(err);
            });
        }

    });
}
export async function getReporteEmpaqueInvernaderoSemanaFinal(objCiclo, invernadero, semana) {
    if (invernadero.es_externo) {
        let respArea = await obtenerAreaInvernadero(objCiclo.id, invernadero.id)
        let result = await obtenerkgm2SemanalOrdenes(objCiclo.id, invernadero.id, semana.time, respArea)
        return result
    }
    else {
        let detArea = invernadero.detalles_fisicos.find(el => el.detalle === "Area (m²)")
        let area = parseInt(detArea["norte"]) + parseInt(detArea["sur"])
        let result = await obtenerkgm2SemanalOrdenes(objCiclo.id, invernadero.id, semana.time, area)
        return result
    }
}


export const obtenerPackoutSemanal = (ciclo, invernadero, semana, kgm2semanal) => {
    // obtener kgm2semanal de ordenes de venta
    return new Promise((resolve, reject) => {
        obtenerAreaInvernadero(ciclo, invernadero.id).then((area) => {
            obtenerkgm2SemanalOrdenes(ciclo, invernadero.id, semana, area).then((kgm2Ordenes) => {
                if(invernadero.nombre === "H1" && semana === 1666591200) console.log(`H1: obtenerPackoutSemanal -  `, { final: kgm2Ordenes, inicial: kgm2semanal, semana, packout: kgm2Ordenes / kgm2semanal});
                resolve(kgm2Ordenes / kgm2semanal);
            }).catch((err) => {
                reject(err);
            });

        }).catch((err) => {
            reject(err);
        });
    })
}
export const getPackoutSemanal = async (ciclo, invernadero, semana, kgm2semanal) => {
    // obtener kgm2semanal de ordenes de venta
    const area = obtenerAreaInvernaderoSync(null, invernadero)
    const suma = await getOrdenesSemanaPesosTotal(ciclo, invernadero.id, semana, area)
    return { area, suma }
}

export const obtenerPackoutAcumulado = (ciclo, invernadero) => {

    return new Promise((resolve, reject) => {
        obtenerPesoRegistradoCiclo(ciclo, invernadero).then((pesoEmpaque) => {
            obtenerPesoOrdenadoCiclo(ciclo, invernadero).then((pesoOrdenes) => {
                resolve(pesoOrdenes / pesoEmpaque);
            }).catch((err) => {
                reject(err);
            });
        }).catch((err) => {
            reject(err);
        });
    })


}

export function obtenerPesoSemanaInteligente(ciclo, invernadero, semana, proyecciones, pesoCaja) {

    let proyeccionesProcesadas = [];
    proyecciones.forEach(el => {
        let dato = el.data();
        proyeccionesProcesadas.push(dato);
    });
    proyecciones = proyeccionesProcesadas;
    const db = firebase.firestore();
    let hoy = obtenerDiaActual();
    let hora = obtenerHora(Math.round(Date.now() / 1000));
    return new Promise((resolve, reject) => {
        let total = 0;
        semana = obtenerInicioSemanaDia(semana).unix();
        db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero).collection("empaques")
            .where("dia", ">=", semana).where("dia", "<", semana + 7 * 24 * 60 * 60)
            .get().then(respEmpaques => {
                registrarInvocaciones(respEmpaques.size)
                let listaDias = obtenerDiasDeSemana(semana);
                let empaques = [];
                respEmpaques.forEach(empaque => {
                    let data = empaque.data();
                    empaques.push(data);
                })

                listaDias.forEach(dia => {
                    let empaque = empaques.find(el => el.dia === dia);
                    //
                    //
                    if (dia > hoy) {
                        let cosecha = proyecciones.find(el => el.dia === dia);
                        if (cosecha) {
                            let dato = parseFloat(cosecha["semanal"]);
                            if (!isNaN(dato)) {
                                total += dato * pesoCaja;
                            }
                        }

                    } else if (dia === hoy && hora < horaCorte) {
                        let cosecha = proyecciones.find(el => el.dia === dia);
                        if (cosecha) {
                            let dato = parseFloat(cosecha["diaria"]);

                            if (!isNaN(dato)) {
                                total += dato * pesoCaja;
                            }
                        }

                    }
                    else if (empaque && empaque.pallets) {
                        let suma = empaque.pallets.reduce((acc, curr) => {
                            let cant = parseFloat(curr.cantidad);
                            if (curr.cajas && !isNaN(parseFloat(curr.cajas))) {
                                cant = parseFloat(curr.cajas) * pesoCaja;
                            }

                            if (isNaN(cant)) {
                                return acc;
                            } else {
                                return acc + cant;
                            }

                        }, 0);
                        total += suma;
                    }
                });
                resolve(total);
            }).catch(err => {
                reject(err);
            })
    })
}
export function obtenerPesoYCajasSemanaInteligenteReporteCosecha(ciclo, invernadero, semana, proyecciones, pesoCaja) {
    const db = firebase.firestore();
    let hoy = obtenerDiaActual();
    let hora = obtenerHora(Math.round(Date.now() / 1000));
    return new Promise((resolve, reject) => {
        let kilos = 0;
        let cajas = 0;
        semana = obtenerInicioSemanaDia(semana).unix();
        db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero).collection("empaques")
            .where("dia", ">=", semana).where("dia", "<", semana + 7 * 24 * 60 * 60)
            .get().then(respEmpaques => {
                registrarInvocaciones(respEmpaques.size)
                let listaDias = obtenerDiasDeSemana(semana);
                let empaques = [];
                respEmpaques.forEach(empaque => {
                    let data = empaque.data();
                    empaques.push(data);
                })

                listaDias.forEach(dia => {
                    const timestamps = flexDate(dia);
                    let empaque = empaques.find(el => el.dia >= timestamps.inicio && el.dia <= timestamps.fin);
                    //
                    //
                    if (dia > hoy) {
                        let cosecha = proyecciones.find(el => el.dia >= timestamps.inicio && el.dia <= timestamps.fin);
                        if (cosecha) {
                            let diario = parseFloat(cosecha["diaria"]);
                            let dato = parseFloat(cosecha["semanal"]);
                            if (diario && !isNaN(diario)) {
                                dato = diario
                            }
                            if (!isNaN(dato)) {
                                kilos += dato * pesoCaja;
                                cajas += dato;
                            }
                        }

                    } else if (dia === hoy && hora < horaCorte) {
                        let cosecha = proyecciones.find(el => el.dia >= timestamps.inicio && el.dia <= timestamps.fin);
                        if (cosecha) {
                            let dato = parseFloat(cosecha["diaria"]);

                            if (!isNaN(dato)) {
                                kilos += dato * pesoCaja;
                                cajas += dato;
                            }
                        }

                    }
                    else if (empaque && empaque.pallets) {
                        let sumaKilos = empaque.pallets.reduce((acc, curr) => {
                            let cant = parseFloat(curr.cantidad);

                            if (isNaN(cant)) {
                                return acc;
                            } else {
                                return acc + cant;
                            }

                        }, 0);
                        let sumaCajas = empaque.pallets.reduce((acc, curr) => {
                            let cant = parseFloat(curr.cantidad) / pesoCaja;
                            if (curr.cajas && !isNaN(parseFloat(curr.cajas))) {
                                cant = parseFloat(curr.cajas);
                            }

                            if (isNaN(cant)) {
                                return acc;
                            } else {
                                return acc + cant;
                            }

                        }, 0);
                        kilos += sumaKilos;
                        cajas += sumaCajas;
                    }
                });
                resolve({ kilos, cajas });
            }).catch(err => {
                reject(err);
            })
    })
}
export function obtenerkgm2ciclo(ciclo, invernadero) {
    const db = firebase.firestore();

    return new Promise((resolve, reject) => {

        obtenerPesoRegistradoCiclo(ciclo, invernadero).then(respPeso => {
            obtenerAreaInvernadero(ciclo, invernadero).then(respArea => {
                resolve(respPeso / respArea);
            }).catch(err => {
                reject(err);
            });
        }).catch(err => {
            reject(err);
        });
    })
}


export function obtenerkgm2semanal(ciclo, invernadero, semana) {
    const db = firebase.firestore();
    const dias = obtenerDiasDeSemana(semana);
    return new Promise((resolve, reject) => {
        obtenerPesoRegistradoSemana(ciclo, invernadero, dias).then(respPeso => {
            obtenerAreaInvernadero(ciclo, invernadero).then(respArea => {
                resolve(respPeso / respArea);
            }).catch(err => {
                reject(err);
            });
        }).catch(err => {
            reject(err);
        });
    })
}
export function obtenerkgm2semanalSync(ciclo, invernadero, semana) {
    const db = firebase.firestore();
    const dias = obtenerDiasDeSemana(semana);
    return new Promise((resolve, reject) => {
        let respArea = obtenerAreaInvernaderoSync(ciclo, invernadero);
        obtenerPesoRegistradoSemana(ciclo, invernadero.id, dias).then(respPeso => {
            resolve(respPeso / respArea);
        }).catch(err => {
            reject(err);
        });
    })
}
export async function getkgm2semanalSync(ciclo, invernadero, semana, area) {
    const dias = obtenerDiasDeSemana(semana);
    const respPeso = await obtenerPesoRegistradoSemana(ciclo, invernadero.id, dias);
    if(invernadero.nombre === "H1" && semana === 1666591200) console.log(`H1: getkgm2semanalSync - `, {inicialReal: respPeso, semana, inicial: respPeso / area});
    return (respPeso / area);
}
export async function getkgm2semanalTotal(ciclo, invernadero, semana) {
    const dias = obtenerDiasDeSemana(semana);
    const suma = await obtenerPesoRegistradoSemana(ciclo, invernadero.id, dias)
    return suma;
}
/*
export function obtenerkgm2semanalCard(ciclo,invernadero,semana){
    const dias = obtenerDiasDeSemana(semana);
    return new Promise((resolve,reject)=>{
        let respArea = obtenerAreaInvernaderoSync(ciclo,invernadero)
        obtenerPesoRegistradoSemana(ciclo,invernadero.id,dias).then(respPeso=>{
                resolve(respPeso/respArea);          
        }).catch(err=>{
            reject(err);
        });
    })
}*/
export const obtenerResumenCardEmpaque = async (ciclo, invernadero, semana) => {
    try {
        const { cicloObj } = getGlobal();
        const area = obtenerAreaInvernaderoSync({}, invernadero)
        const kgm2semanal = await obtenerkgm2semanal(ciclo, invernadero.id, semana)

        const final = await getReporteEmpaqueInvernaderoSemanaFinal(cicloObj, invernadero, { time: semana })
        const kgCiclo = await getKgm2OrdenadosHasta(ciclo, invernadero.id, semana, area)

        const { proyeccion } = await obtenerPronosticoYProyeccion(ciclo, invernadero, semana);
        const totalBudget = await obtenerTotalBudget(ciclo, invernadero.id)

        const packoutSemanal = await obtenerPackoutSemanal(ciclo, invernadero, semana, kgm2semanal)
        const packoutAcumulado = await obtenerPackoutAcumulado(ciclo, invernadero.id, semana)

        const [transcurridas, semanasTotales] = await obtenerSemanasTranscurridasBuddget(ciclo, invernadero.id)
        const copia = {
            ...invernadero,
            final, kgCiclo,
            proyectado: proyeccion, totalBudget,
            packoutSemanal, packoutAcumulado,
            transcurridas, semanasTotales,
            semanasTranscurridas: transcurridas,
            semanasTotales: semanasTotales,
            kgm2semanal,
        };
        return copia;
    } catch (error) {
        throw error;
    }
}//509