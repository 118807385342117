import React,{useState, useEffect} from "react";
import {InputText} from 'primereact/inputtext';
export function usePrimeReactTable(initialState,extraProcessing){
    const [current,setCurrent] = useState(initialState);
    useEffect(() => {
        
        
        
    }, [current])
    function inputTextEditor(props,field){
        return <InputText keyfilter ="num" type="number" value={props.rowData[field]} onChange={(e) => onEditorValueChange(props, e.target.value)} />;
    }

    function editorGenerator(props,field){
        return inputTextEditor(props,field);
    }

    function onEditorValueChange(props,value){
        let updatedState = [...props.value];
        updatedState[props.rowIndex][props.field] = value;
        if(extraProcessing){
            extraProcessing(updatedState,props,value);
        }
        setCurrent(updatedState);
    }

    return [editorGenerator,current, setCurrent]
}