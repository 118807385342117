import React from 'react';
import { useState, useEffect, useGlobal, setGlobal } from 'reactn';
import { Dialog } from "primereact/dialog";
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import ContenedorFiltro from '../../common/ContenedorFiltro'
import SeccionFiltros from '../../common/SeccionFiltros'
import * as firebase from "firebase/app";
import { obtenerDiasDeSemana, obtenerIndiceDiaSemana } from "../../../service/fechas";
import { obtenerRegistrosEntradasPorSemanaTodos, guardarDatosEntrada } from "../../../service/VentaNacional";
import { nombresDiasSemana, miliSegundosDia } from "../../../constants";
import { getNombrePorID } from "../../../util/functions";
import moment from 'moment';

const TransferirKilosRestantesModal = (props) => {
    const [, setCargando] = useGlobal("cargando")
    const [invernaderosTransferir, setInvernaderosTransferir] = useState([]);
    const [ciclo,] = useGlobal("ciclo");
    const [invernaderosOpciones] = useGlobal("invernaderosVirtuales")

    useEffect(() => {
        if (!props.visible)
            setInvernaderosTransferir([])
    }, [props.visible])


    const getNombresString = (lista) => lista.map(id => getNombrePorID(invernaderosTransferir, id)).join(", ")
    const transferirEntradas = async () => {
        setCargando(true)
        props.onHide()

        let invernaderosPorTransferirIDs = invernaderosTransferir.map(inv => inv.id)
        let { alreadyTransferredIDs, entradasSemanaActual } = await alreadyTransferred()
        let okToTransfer = invernaderosPorTransferirIDs.filter(inv => !alreadyTransferredIDs.includes(inv))

        let lineasFiltrados = props.lineas.filter(linea => okToTransfer.includes(linea.invernadero.id))
        let kilosRestantesParaTransferir = lineasFiltrados.map(linea => linea.kilosRestantes)

        for (let x = 0; x < kilosRestantesParaTransferir.length; x++) {
            let diasDeSemana = obtenerDiasDeSemana(props.currentWeek.time).map((dia, index) => ({ nombre: nombresDiasSemana[index], time: dia }))
            let indice = obtenerIndiceDiaSemana(Math.round(moment(moment().utcOffset("-0600").toLocaleString()).toDate()  / 1000))

            let datos = {
                dia: diasDeSemana[indice],
                pallets: [{ cantidad: kilosRestantesParaTransferir[x], tipo: "Transferido", }],
                week: props.currentWeek,
                weekTransferido: props.week,
                transferido: true
            }
            let found = entradasSemanaActual[x].find(entrada => entrada.pallets[0].tipo === "Transferido") || {}
            await guardarDatosEntrada(ciclo, lineasFiltrados[x].invernadero.id, datos, found.id)
        }

        if (alreadyTransferredIDs.length > 0 && okToTransfer.length === 0)
            props.setHasTransferredText(`Ya transferiste en los invernaderos:  ${getNombresString(alreadyTransferredIDs)}`)
        else if (alreadyTransferredIDs.length > 0 && okToTransfer.length > 0)
            props.setHasTransferredText(`Ya transferiste en los invernaderos:  ${getNombresString(alreadyTransferredIDs)},\n pero se transfirieron exitosamente los kilos restantes de los invernaderos: ${getNombresString(okToTransfer)}`)
        else if (alreadyTransferredIDs.length === 0 && okToTransfer.length > 0)
            props.setHasTransferredText(`Se transfirieron exitosamente los kilos restantes de los invernaderos:  ${getNombresString(okToTransfer)}`)

        if (okToTransfer.length > 0)
            props.refreshDatos()
        else {
            setCargando(false)
            props.setHasTransferredModalOpen(true)
        }
    }

    const alreadyTransferred = async () => {
        let entradasSemanaActual = await obtenerRegistrosEntradasPorSemanaTodos(ciclo, props.currentWeek, invernaderosTransferir)

        let alreadyTransferred = invernaderosTransferir.filter((inv, index) => {
            if (entradasSemanaActual[index].length > 0)
                return entradasSemanaActual[index].some(entrada => (entrada.pallets[0].tipo === "Transferido" && entrada.weekTransferido.time !== props.week.time))
            else return false
        })

        return { alreadyTransferredIDs: alreadyTransferred.map(el => el.id), entradasSemanaActual }
    }

    const filtrarInvernaderosSinKilos = () => {
        if (invernaderosOpciones && props.lineas.length > 0)
            return invernaderosOpciones.filter((inv, index) => props.lineas[index]?.kilosRestantes > 0)
        else return []
    }

    const footer = () => {
        return <div>
            <Button label="Cancelar" onClick={props.onHide} />
            <Button label="Transferir" disabled={invernaderosTransferir.length == 0} onClick={transferirEntradas} />
        </div>
    }

    return <Dialog header={"Transferir registros de entradas"} visible={props.visible} style={{ width: '50vw' }} onHide={props.onHide} footer={footer()}>
        <div className="p-grid ">
            <SeccionFiltros>
                <ContenedorFiltro sinFiltrarTexto={true}>
                    <p>¿Le gustaría transferir los kilos restantes de la semana {props.week.nombre} seleccionada a la semana actual?</p>
                    <label htmlFor="invernaderoTransferir">Invernadero</label>
                    <MultiSelect id="invernaderoTransferir" dataKey="id" optionLabel="nombre"
                        filter={false} selectedItemsLabel={`${invernaderosTransferir.length} invernaderos seleccionados`}
                        options={filtrarInvernaderosSinKilos(invernaderosOpciones)} value={invernaderosTransferir}
                        onChange={(e) => setInvernaderosTransferir(e.target.value)} />
                </ContenedorFiltro>
            </SeccionFiltros>
        </div>
    </Dialog >
}
export default TransferirKilosRestantesModal