import { setGlobal, getGlobal } from "reactn"
import * as firebase from "firebase/app";
import "firebase/firestore";
import { DeepClone } from "../../util/functions";
import { uploadStatistics } from '../Estadisticas'

function registrarInvocaciones(count) {
    uploadStatistics(count)
}

export const obtenerProveedores = async (cicloID) => {
    const db = firebase.firestore();
    let snap = await db.collection("ciclos").doc(cicloID).collection("proveedores_cosecha").get();
    registrarInvocaciones(snap.size)
    return snap.docs.map(el => ({ id: el.id, ...el.data() }));
}

export const obtenerProveedoresOrdenados = async (cicloID) => {
    const db = firebase.firestore();
    let snap = await db.collection("ciclos").doc(cicloID).
        collection("proveedores_cosecha").orderBy('nombre').get();
    registrarInvocaciones(snap.size)
    return snap.docs.map(el => ({ id: el.id, ...el.data() }));
}

export const obtenerProveedor = async (cicloId, proveedorId) => {
    const db = firebase.firestore();
    const obj = await db.collection("ciclos").doc(cicloId).
        collection("proveedores_cosecha").doc(proveedorId).get();
    registrarInvocaciones(obj.size)
    const data = { id: obj.id, ...obj.data() };
    return data
}

export const obtenerProveedoresHabilitados = async (cicloId) => {
    let proveedores = await obtenerProveedores(cicloId);
    return proveedores.filter(el => el.habilitado === undefined || el.habilitado === true);

}

export const registrarProveedor = async (cicloID, proveedor) => {
    const db = firebase.firestore();
    let result = await db.collection("ciclos").doc(cicloID).collection("proveedores_cosecha").add(proveedor);
    return result;
}
export const guardarProveedor = async (cicloID, proveedorId, proveedor) => {
    const db = firebase.firestore();
    let result = await db.collection("ciclos").doc(cicloID).collection("proveedores_cosecha").doc(proveedorId).update(proveedor)
    return result;
}
export const obtenerProveedorPorUsuario = async (cicloID, usuarioId) => {
    const db = firebase.firestore();
    let result = await db.collection("ciclos").doc(cicloID).collection("proveedores_cosecha").where("usuario_ref", "==", usuarioId).get();
    registrarInvocaciones(result.size)
    if (result.empty) {
        return { empty: true }
    } else {
        let doc = result.docs[0];
        return { id: doc.id, ...doc.data() }
    }

}

export const obtenerProveedoresInvernadero = async (cicloID, invernaderoID) => {
    let proveedores = getGlobal().proveedores
    let response = await firebase.firestore().collection("ciclos").doc(cicloID).collection("proveedores_cosecha").where("invernadero_ref", "==", invernaderoID).get();
    registrarInvocaciones(response.size)
    return response.docs.map(el => ({ id: el.id, ...el.data() }));
}
export const getProveedoresInvernadero = async (cicloID, invernaderoID) => {
    let proveedores = await obtenerProveedores(cicloID)
    return proveedores.filter(el => el.invernadero_ref === invernaderoID);
}
export const togglearProveedor = (cicloID, proveedorId) => {
    var db = firebase.firestore();
    return new Promise((resolve, reject) => {
        db.collection("ciclos").doc(cicloID).collection("proveedores_cosecha").doc(proveedorId).get().then((snap) => {
            let data = snap.data();
            let habilitado = data.habilitado;
            if (habilitado === undefined) {
                habilitado = true;
            }
            db.collection("ciclos").doc(cicloID).collection("proveedores_cosecha").doc(proveedorId).update({
                habilitado: !habilitado
            }).then(resp => {
                resolve("yei");
            }).catch(err => { reject(err) });
        }).catch(err => {
            reject(err);
        });
    })

}
//63