import React from "react";
import { Calendar } from "primereact/calendar";
import {
  getFormatCalendar,
  transformISOToCalendarFormat,
  getMinutesByTimestamp,
} from "service/fechas";
import { dateToUnixTimeAsNumber13 } from "util/functions";
import ContenedorInput from "./ContenedorInput";
import { Message } from "primereact/message";

const InputTimeSelector = ({
  id = "",
  name = "tiempo_estimado",
  label = "Tiempo estimado",
  onChange,
  value,
  col = "12",
  valueMode="minutes",
  errorItIsRequired=false
}) => {
  const valueTansformer = {
    minutes: (time) => time,
    "hours:minutes": (time) => {
      const timeSplited = time.split(":")
      const hours = parseInt(timeSplited[0])
      const minutes = parseInt(timeSplited[1])
      const minutesHours = hours*60
      return minutes + minutesHours 
    }
  }
  const outputTansformer = {
    minutes: (time) => time,
    "hours:minutes": (time) => {
      const rawHours = Math.floor(time/60)
      const rawMinutes = time%60
      const hours = rawHours < 10 ? `0${rawHours}` : rawHours
      const minutes = rawMinutes < 10 ? `0${rawMinutes}` : rawMinutes
      return `${hours}:${minutes}`
    }
  }
  const handleChangeInputEstimatedTime = (event) => {
    const nativeValue = event.originalEvent.target.value;
    const calendarValue = event.target.value;
    let time = 0;

    if (nativeValue) {
      const timeTransformed = transformISOToCalendarFormat(nativeValue);
      time = getMinutesByTimestamp(
        timeTransformed === NaN ? 0 : timeTransformed
      );
    } else {
      const zeroValue = new Date();
      zeroValue.setHours(0);
      zeroValue.setMinutes(0);
      zeroValue.setSeconds(0);
      const newCalendarValue = calendarValue || zeroValue;
      time = getMinutesByTimestamp(dateToUnixTimeAsNumber13(newCalendarValue));
    }

    onChange({ target: { value: outputTansformer[valueMode](time || 0), name } });
  };

  return (
    <ContenedorInput label={label} col={col}>
      <Calendar
        id={id}
        readOnlyInput={false}
        timeOnly={true}
        hourFormat="24"
        name={name}
        value={getFormatCalendar(valueTansformer[valueMode](value))}
        onChange={handleChangeInputEstimatedTime}
      />
      {errorItIsRequired && <Message severity="error" text="Este campo es requerido"></Message>}
    </ContenedorInput>
  );
};

export default InputTimeSelector;
