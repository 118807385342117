import { Card } from 'primereact/card';
import React from 'react'
import calidades from './../../../images/graficas/calidades.png';

const HomeBiocontrol = (props) => {
  return (
    <>
        <div className="titulo-seccion-graficas">
            <span className="icon-general bio-icon"></span>
            Biocontrol
        </div>
        <div className="p-col-12 p-md-3  grafica-card" onClick={() => props.ir("/biocontrol/graficas/claros-afectados")}>
            <Card className={`htg-card bor-gray-superlight`}  >
                <div className="card-title">Gráfica de resumen de claros afectados</div>
                <img src={calidades} />
            </Card>
        </div>
    </>
  )
}

export default HomeBiocontrol