import React, { useState, useEffect, useGlobal } from 'reactn';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { obtenerClientes } from "../../../service/Clientes";
import {
  registrarPresentacion, guardarPresentacion, obtenerUrlFoto, toggleHabilitacion,
  obtenerPresentacion,
  getCategoriaPresentacion
} from '../../../service/Presentaciones';

import SeccionFormulario from '../../common/SeccionFormulario';
import ContenedorInput from '../../common/ContenedorInput';
import PreviewModal from '../../common/PreviewModal'
import { factorLbKg } from '../../../constants';


let inicial = {
  id_presentacion: "",
  descripcion: "",
  presentacion: "",
  item: "",
  cliente: null,
  producto: null,
  peso_neto_caja: "",
  numero_cajas_pallet: "",
  tipo_clamshell: "",
  clamshell_caja: "",
  tipo_caja: "",
  tipo_tarima: "",
  tipo_embalaje: "",
  tipo_film: "",
  tipo_etiqueta: "",
  costo_material_lb: "",
  peso_neto_caja_libras: "",
  peso_neto_presentacion: "",
  peso_minimo: "",
  peso_maximo: "",
  rangos_bono_empaque: [
    { orden: 1, min: "0", max: "", bono: "" },
    { orden: 2, min: "", max: "", bono: "" },
    { orden: 3, min: "", max: "", bono: "" },
    { orden: 4, min: "", max: "", bono: "" },
  ]
}
const FormularioInvernadero = (props) => {
  let fileInput = React.useRef(null);
  const presentacionId = props.match.params.presentacionID
  const editando = props.match.params.presentacionID ? true : false;
  const [cargando, setCargando] = useGlobal("cargando")
  const [, setMostrarModalPermisos] = useGlobal("mostrarModalPermiso")
  const [, setModal] = useGlobal("modal");
  const [usuario,] = useGlobal("usuario");
  const [presentaciones,] = useGlobal("presentaciones");
  var [, setModalConfirmacion] = useGlobal("modalConfirmacion");
  const [ciclo, setCiclo] = useGlobal("ciclo");

  const [presentacion, setPresentacion] = useState();
  const [editing, setEditing] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [productos] = useGlobal("productosBases");
  const [archivos, setArchivos] = useState([]);
  const [fotosPorEliminar, setFotosPorEliminar] = useState([]);
  const [errores, setErrores] = useState([]);
  const [previewDialog, setPreviewDialog] = useState("")
  const [categoria, setCategoria] = useState(null)

  const puedeCrearDatos = (campo) => {
    return true//TEMPORARY !editando && ["Administrador", "Administrador Embarques"].includes(usuario.rol);
  }
  const puedeEditar = (campo) => {
    return ["Administrador", "Administrador Embarques"].includes(usuario.rol);
  }

  const atras = (params) => {
    setPresentacion(null);
    props.history.replace({ pathname: "/admin/presentaciones/", data: { filters: props.location.data ? props.location.data.filters : null } });
  }

  const handleFileInputChange = (event) => {
    setArchivos(event.target.files);
  }

  const extraProcessingAsignacion = (updatedState, props, value) => {
    let numberFin = parseInt(updatedState[props.rowIndex]["fin"]);
    if (isNaN(numberFin)) {
      numberFin = 0;
    }
    if (isNaN(numberInicio)) {
      numberInicio = 0;
    }
    let numberInicio = parseInt(updatedState[props.rowIndex]["inicio"])
    let result = 1 + numberFin - numberInicio;
    updatedState[props.rowIndex]["totales"] = `${result} ${updatedState[props.rowIndex]["unidades"]}`;
  }

  const extraProcessingDetalles = (updatedState, props, value) => {
    let numberSur = parseInt(updatedState[props.rowIndex]["sur"]);
    let numberNorte = parseInt(updatedState[props.rowIndex]["norte"]);
    if (isNaN(numberSur)) {
      numberSur = 0;
    }
    if (isNaN(numberNorte)) {
      numberNorte = 0;
    }

    let result = numberSur + numberNorte;
    updatedState[props.rowIndex]["totales"] = `${result} ${updatedState[props.rowIndex]["unidades"]}`;
  }

  const [nombreModulo, setNombreModulo] = useGlobal("nombreModulo");
  const [state, setState] = useState(inicial)
  const guardar = () => {
    let errores = validar();
    if (errores.length > 0) {
      setErrores(errores);
      return;
    }
    setCargando(true)
    if (!editing) {
      registrarPresentacion({ ...state, habilitado: true }, presentaciones?.length ? presentaciones.length : 0, archivos)
        .then((resp) => {
          setCargando(false)
          props.history.push("/admin/presentaciones");

        })
        .catch(err => {
          setCargando(false)
          console.log("ERROR AL GUARDAR");
          console.log(err);
          setPresentacion(null);
        });
    }
    else {
      guardarPresentacion(state, presentaciones?.length ? presentaciones.length : 0, archivos).then(res => {
        setCargando(false)
        props.history.push("/admin/presentaciones");
      }).catch(err => {
        setCargando(false)
        console.log("ERROR AL EDITAR");
        if (err.code && err.code === "permission-denied") {
          setMostrarModalPermisos(true);
        }
        console.log(err.code);
        console.log(err.message);
        console.log(err);
      })
    }


  }
  const validar = () => {
    let errores = [];
    const requeridos = ["id_presentacion", "presentacion", "descripcion", "cliente", "producto", "peso_neto_caja", "numero_cajas_pallet"];
    for (let x = 0; x < requeridos.length; x++) {
      if (state[requeridos[x]] === "" || state[requeridos[x]] === null) {
        errores.push(requeridos[x]);
      }
    }

    return errores;
  }
  const cancelar = () => {
    setPresentacion(null);
    setEditing(false);
    props.history.replace({ pathname: "/admin/presentaciones/", data: { filters: props.location.data.filters } });
  }

  useEffect(() => {
    setNombreModulo("Presentación");
    setCargando(true)
    if (productos?.length > 0) {
      obtenerClientes().then(async (clientes) => {
        setClientes(clientes);
        if (presentacion) {
          let adaptacion = await adaptar(presentacion, clientes, productos);
          setState(adaptacion);
        }
        setCargando(false)
      })
    }
  }, [presentacion, productos])

  useEffect(() => {
    if (editando) {
      setEditing(true);
      obtenerDatosIniciales()
    }
  }, [editando])

  const obtenerDatosIniciales = async () => {
    const presentacionPromise = obtenerPresentacion(presentacionId)
    const catPromise = getCategoriaPresentacion(presentacionId)

    const [presentacion, cat] = await Promise.all([presentacionPromise, catPromise])
    setCategoria(cat)
    setPresentacion(presentacion)
  }

  const handleInputChange = (ev) => {
    setErrores([]);
    const stateCopy = Object.assign({}, state);
    let value = ev.target.value;

    stateCopy[ev.target.name] = value;
    if (ev.target.name === "peso_neto_caja") {
      let pesoLibras = (parseFloat(value) * factorLbKg).toFixed(2).toString();
      stateCopy["peso_neto_caja_libras"] = isNaN(pesoLibras) ? "" : pesoLibras;
    }

    setState(stateCopy);
  }
  const adaptar = async (presentacion, clientes, productos) => {
    let adaptado = Object.assign({}, presentacion);
    let cliente = clientes.find(el => el.id === presentacion.cliente_ref);
    let producto = productos?.find(el => el.id === presentacion.producto_ref) || {};
    adaptado.cliente = cliente;
    adaptado.producto = producto;
    if (producto.mezcla) { adaptado.mezcla = true; }
    adaptado.fotos_url = [];
    for (let x = 0; adaptado.fotos && x < adaptado.fotos.length; x++) {
      let foto = adaptado.fotos[x];
      let url = ""
      try {
        url = await obtenerUrlFoto(foto);
      } catch (error) { console.log("ERROR obtenerUrlFoto: " + error.message) }
      adaptado.fotos_url.push(url);
    }
    if (presentacion.rangos_bono_empaque) {
      adaptado.rangos_bono_empaque = presentacion.rangos_bono_empaque;
    } else {
      adaptado.rangos_bono_empaque = inicial.rangos_bono_empaque.slice();
    }
    return adaptado;
  }
  const eliminarFoto = (foto) => {
    let copia = { ...state };
    let fotos = state.fotos.slice();
    fotos = fotos.filter(el => el !== foto);
    copia.fotos = fotos;
    setState(copia);
  }
  const clickToggle = (ev) => {
    activarModalConfirmacion();
  }
  const toggle = (params) => {
    toggleHabilitacion(state.id, state.habilitado).then((result) => {
      props.history.goBack();
    }).catch((err) => {
      console.log("NO SE PUDO TOGLEAR");
      console.log(err);
    });;
  }
  const activarModalConfirmacion = (params) => {
    let mensaje = state["habilitado"] ?
      "¿Estás seguro que deseas deshabilitar esta presentación?" :
      "¿Estás seguro que deseas habilitar esta presentación?";
    let titulo = "Confirmación de acción";
    let aceptar = () => { toggle() };
    let cancelar = () => { }
    setModalConfirmacion({ mensaje, titulo, aceptar, cancelar })
  }
  const handleRangoBonoChange = (index, tipo, valor) => {
    let copia = { ...state };

    copia["rangos_bono_empaque"][index][tipo] = valor;
    setState(copia);
  }

  return <div className="p-grid p-justify-center">
    <div className="p-col-12 title-container">
      <span className="back-btn" onClick={atras}></span>
      {editing ?
        <React.Fragment>
          <span className="icon-general presentacion-icon"></span>
          <span className="titles-group">
            <h1 className="header-title">Modificar presentación</h1>
          </span>
        </React.Fragment>
        :
        <React.Fragment>
          <span className="icon-general presentacion-icon"></span>
          <span className="titles-group">
            <h1 className="header-title">Crear presentación</h1>
          </span>
        </React.Fragment>
      }

    </div>
    <div className="p-col-10">
      <div className="p-grid p-fluid ">
        <div className="content-section implementation">


        </div>
        <div className="p-col-12">
          <p className="section-title">Identificación</p>
          <div className="card">
            <div className="p-grid">

              <div className="form-group p-col-12 p-md-6 p-sm-6">
                <label >ID de presentación</label>
                <InputText value={state["id_presentacion"]} name="id_presentacion"
                  onChange={handleInputChange} disabled={!puedeEditar("id_presentacion")} />
                {errores.includes("id_presentacion") ? <Message severity="error" text="Este campo es requerido"></Message> : null}
              </div>
              <div className="form-group p-col-12 p-md-6 p-sm-6">
                <label>Presentación cliente</label>
                <InputText value={state["descripcion"]} name="descripcion"
                  onChange={handleInputChange} disabled={!puedeEditar("descripcion")} />
                {errores.includes("descripcion") ? <Message severity="error" text="Este campo es requerido"></Message> : null}
              </div>
              <div className="form-group p-col-12 p-md-6 p-sm-6">
                <label>Presentación HTG</label>
                <InputText value={state["presentacion"]} name="presentacion"
                  onChange={handleInputChange} disabled={!puedeEditar("presentacion")} />
                {errores.includes("presentacion") ? <Message severity="error" text="Este campo es requerido"></Message> : null}
              </div>
              <div className="form-group p-col-12 p-md-6 p-sm-6">
                <label>Item</label>
                <InputText value={state["item"]} name="item"
                  onChange={handleInputChange} disabled={!puedeEditar("item")} />
                {errores.includes("item") ? <Message severity="error" text="Este campo es requerido"></Message> : null}
              </div>
              {categoria && false &&
                <div className="form-group p-col-12 p-md-6 p-sm-6">
                  <label>Categoría bono empaque</label>
                  <InputText name="categoria" value={categoria?.nombre} disabled={true} />
                </div>}
            </div>
          </div>

        </div>
        <div className="p-col-12">
          <p className="section-title">Características</p>
          <div className="card">
            <div className="p-grid">


              <div className="form-group p-col-12 p-md-6 p-sm-6">
                <label>Cliente</label>
                <Dropdown disabled={!puedeCrearDatos()} options={clientes} placeholder="Seleccione cliente" name=
                  "cliente" value={state.cliente} onChange={handleInputChange} dataKey="id" optionLabel="nombre" />
                {errores.includes("cliente") ? <Message severity="error" text="Este campo es requerido"></Message> : null}
              </div>
              <div className="form-group p-col-12 p-md-6 p-sm-6">
                <label>Producto</label>
                <Dropdown disabled={!puedeCrearDatos()} options={productos} placeholder="Seleccione producto" name=
                  "producto" value={state.producto} onChange={handleInputChange} optionLabel="nombre" dataKey="id" />
                {errores.includes("producto") ? <Message severity="error" text="Este campo es requerido"></Message> : null}
              </div>
              <div className="form-group p-col-12 p-md-6 p-sm-6">
                <label>Peso neto por caja (Kg)</label>
                <InputText value={state["peso_neto_caja"]} name="peso_neto_caja"
                  onChange={handleInputChange} disabled={!puedeEditar("peso_neto_caja")} />
                {errores.includes("peso_neto_caja") ? <Message severity="error" text="Este campo es requerido"></Message> : null}

              </div>


              <div className="form-group p-col-12 p-md-6 p-sm-6">
                <label >Peso neto por caja (libras)</label>
                <p className="form-output">{state["peso_neto_caja_libras"]} lb</p>
              </div>
              <div className="form-group p-col-12 p-md-6 p-sm-6">
                <label>Número de cajas por pallet</label>
                <InputText value={state["numero_cajas_pallet"]} name="numero_cajas_pallet"
                  onChange={handleInputChange} disabled={!puedeEditar("numero_cajas_pallet")} />
                {errores.includes("numero_cajas_pallet") ? <Message severity="error" text="Este campo es requerido"></Message> : null}
              </div>
              <div className="form-group p-col-12 p-md-6 p-sm-6">
                <label>Peso neto presentación(kg)</label>
                <InputText value={state["peso_neto_presentacion"]} name="peso_neto_presentacion"
                  onChange={handleInputChange} disabled={!puedeEditar("peso_neto_presentacion")} />
                {errores.includes("peso_neto_presentacion") ? <Message severity="error" text="Este campo es requerido"></Message> : null}
              </div>
              <div className="form-group p-col-12 p-md-6 p-sm-6">
                <label>Peso mínimo presentación(kg)</label>
                <InputText value={state["peso_minimo"]} name="peso_minimo"
                  onChange={handleInputChange}
                  disabled={!puedeEditar("peso_minimo")} />
                {errores.includes("peso_minimo") ? <Message severity="error" text="Este campo es requerido"></Message> : null}
              </div>
              <div className="form-group p-col-12 p-md-6 p-sm-6">
                <label>Peso máximo presentación(kg)</label>
                <InputText value={state["peso_maximo"]} name="peso_maximo"
                  onChange={handleInputChange}
                  disabled={!puedeEditar("peso_maximo")} />
                {errores.includes("peso_maximo") ? <Message severity="error" text="Este campo es requerido"></Message> : null}
              </div>

            </div>
          </div>

        </div>

        <div className="p-col-12">
          <p className="section-title">Ficha de presentación</p>
          <div className="card">
            <div className="p-grid">
              <div className="form-group p-col-12 ">
                <label>Fotos de presentación</label>
                <div>
                  {state.fotos && state.fotos.map((foto, index) => {
                    return <div className="presentacion-img" onClick={() => setPreviewDialog(state.fotos_url[index])} style={{ backgroundImage: `url(${state.fotos_url[index]})` }}>
                      <button className="p-button" onClick={() => { eliminarFoto(foto) }} >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>

                    </div>
                  })}
                </div>

                <input type="file" ref={fileInput}
                  multiple={true}
                  onChange={(event) => { handleFileInputChange(event) }} />
              </div>
              <div className="form-group p-col-12 p-md-6 p-sm-6">
                <label>Tipo de clamshell</label>
                <InputText value={state["tipo_clamshell"]} name="tipo_clamshell"
                  onChange={handleInputChange} disabled={!puedeEditar("tipo_clamshell")} />
              </div>
              <div className="form-group p-col-12 p-md-6 p-sm-6">
                <label>Clamshell por caja</label>
                <InputText value={state["clamshell_caja"]} name="clamshell_caja"
                  onChange={handleInputChange} disabled={!puedeEditar("clamshell_caja")} />
              </div>
              <div className="form-group p-col-12 p-md-6 p-sm-6">
                <label>Tipo de caja</label>
                <InputText value={state["tipo_caja"]} name="tipo_caja"
                  onChange={handleInputChange} disabled={!puedeEditar("tipo_caja")} />
              </div>
              <div className="form-group p-col-12 p-md-6 p-sm-6">
                <label>Tipo de tarima</label>
                <InputText value={state["tipo_tarima"]} name="tipo_tarima"
                  onChange={handleInputChange} disabled={!puedeEditar("tipo_tarima")} />
              </div>
              <div className="form-group p-col-12 p-md-6 p-sm-6">
                <label>Tipo de embalaje</label>
                <InputText value={state["tipo_embalaje"]} name="tipo_embalaje"
                  onChange={handleInputChange} disabled={!puedeEditar("tipo_embalaje")} />
              </div>
              <div className="form-group p-col-12 p-md-6 p-sm-6">
                <label>Tipo de film</label>
                <InputText value={state["tipo_film"]} name="tipo_film"
                  onChange={handleInputChange} disabled={!puedeEditar("tipo_film")} />
              </div>
              <div className="form-group p-col-12 p-md-6 p-sm-6">
                <label>Tipo de etiqueta</label>
                <InputText value={state["tipo_etiqueta"]} name="tipo_etiqueta"
                  onChange={handleInputChange} disabled={!puedeEditar("tipo_etiqueta")} />
              </div>
              <div className="form-group p-col-12 p-md-6 p-sm-6">
                <label>Costo de materiales ($) por lb total</label>
                <InputText value={state["costo_material_lb"]} name="costo_material_lb"
                  onChange={handleInputChange} disabled={!puedeEditar("costo_material_lb")} />
              </div>
            </div>
          </div>

        </div>
      </div>

      {categoria?.rangos_bono_empaque &&
        <SeccionFormulario col="12" md="6" sm="6  " titulo={`Bonos empaque (${categoria?.nombre})`} >
          {categoria?.rangos_bono_empaque?.map((el, index) => {
            return <div>
              <p>Rango {index + 1}</p>
              <ContenedorInput label="Min">
                <InputText value={el.min} disabled={true} />
              </ContenedorInput>
              <ContenedorInput label="Max">
                <InputText value={el.max} disabled={true} />
              </ContenedorInput>
              <ContenedorInput label="Valor">
                <InputText value={el.bono} disabled={true} />
              </ContenedorInput>
            </div>
          })}
        </SeccionFormulario>}
    </div>

    {previewDialog !== "" && <PreviewModal imageURL={previewDialog} closePreviewModal={() => setPreviewDialog("")}>
    </PreviewModal >}

    <div className="p-col-12">
      <div className="p-grid p-justify-end btn-row">
        <Button label="Cancelar" className="p-button-secondary" onClick={cancelar} />
        {editing ? <Button label={state["habilitado"] ? "Deshabilitar" : "Habilitar"} onClick={clickToggle} /> : null}
        {puedeEditar("guardar") ? <Button label="Guardar" onClick={guardar} /> : null}

      </div>
    </div>
  </div>
}

export default FormularioInvernadero;