import React, { useState, useEffect } from 'react';
import { obtenerReporteCavidad  } from '../../service/ReporteCalidad';
import { useGlobal } from 'reactn';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
const ReporteCavidad = (props) => {
    const [rows, setRows] = useState([]);
    const [invernadero, setInvernadero] = useGlobal("invernadero");
    const [ciclo, ] = useGlobal("ciclo");    
    const [, setMostrarModalPermiso] = useGlobal("mostrarModalPermiso");
    const [cargando, setCargando] = useGlobal("cargando");
    useEffect(() => {
        
        if(ciclo && invernadero && props.semana){
            
            obtenerDatosIniciales();
        }
        
    }, [invernadero, props.semana])
    const obtenerDatosIniciales = async (params) => {
        setCargando(true);
        try {

            let reporte = await obtenerReporteCavidad(ciclo, invernadero.id, props.semana,);
            
            
            setRows(reporte["inicial"]);
        } catch (error) {
            console.log("EDI");
            console.log(error);
        }finally{
            setCargando(false)
        }
    }
    
    return <React.Fragment>

    <div className="card p-0">
        
    {invernadero && invernadero["producto"] && invernadero["producto"]["nombre"] === "BEEF"?  <DataTable value = {rows}>
        <Column header = "" field = "nombre" />
        <Column header = "PROMEDIO" field = "cociente" /> 
    </DataTable>:
        "SOLO INVERNADEROS ASOCIADOS A BEEF PUEDEN TENER REPORTE DE CAVIDAD"
    }
    </div>   
    

    </React.Fragment>
}
export default ReporteCavidad;
