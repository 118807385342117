import * as firebase from "firebase/app";
import "firebase/firestore";
import React, { getGlobal, setGlobal } from "reactn"
import { DeepClone, DeepCloneVista, dosDeciComas, dosDecimales, getObjetoPorID, ordenarArreglaDeObjetosPorLlave, sumarNumerosArrayPorCampo } from '../util/functions';
import { obtenerInicioDia, obtenerDiasDeSemana, obtenerNombreFecha, obtenerNombreSemana, obtenerListaDeSemanas, obtenerInicioSemanaDia } from './fechas';
import { obtenerAreaInvernadero } from './Producto';
import { notificarOrdenesVenta } from "./notificaciones";
import { obtenerResumenCumplimiento } from "./Cumplimiento";
import { obtenerPresentaciones, obtenerUrlFoto } from "./Presentaciones";
import { log } from "../model/Logging";
import { obtenerCiclo } from "./Ciclos";
import { segundosSemana } from "../constants";
import { uploadStatistics } from './Estadisticas'
import { getEmpaqueDeVentaItem } from "./CentroDeDistribucion";
import { getFirestoreID } from "./Mantenimiento";
import { getEmbarque } from "./embarques";

function registrarInvocaciones(count) {
    uploadStatistics(count)
}
export const obtenerOrdenesSemana = (ciclo, semana) => {
    return new Promise((resolve, reject) => {
        const db = firebase.firestore();
        const dias = obtenerDiasDeSemana(semana)
        db.collection("ciclos").doc(ciclo).collection("ordenes_venta")
            .where("fecha_envio", ">=", dias[0].toString()).where("fecha_envio", "<=", dias[5].toString()).get().then((result) => {
                registrarInvocaciones(result.size)
                let rows = [];
                result.forEach(item => {
                    let data = item.data();
                    data.id = item.id;
                    rows.push(data)
                });
                resolve(rows);
            }).catch((err) => {
                reject(err);
            });
    });
}

export const obtenerOrdenesSemanaOrdenadosDesc = async (ciclo, semana) => {
    const dias = obtenerDiasDeSemana(semana)
    const response = await firebase.firestore().collection("ciclos").doc(ciclo).collection("ordenes_venta")
        .where("fecha_envio", ">=", dias[0].toString()).where("fecha_envio", "<=", dias[5].toString()).get();
    const ordenesDeVenta = response.docs.map(doc => ({ id: doc.id, ...doc.data() }))

    ordenarArreglaDeObjetosPorLlave(ordenesDeVenta, "fecha_envio", false);
    return ordenesDeVenta
}

export const guardarOrdenVenta = async (ciclo, orden, ordenId, editing) => {
    try {
        const datosAGuardar = DeepCloneVista(orden);
        if (!editing) { datosAGuardar.dia = obtenerInicioDia(Math.round(Date.now() / 1000)); }

        datosAGuardar.fecha_envio = `${orden.fecha_envio}`;
        datosAGuardar.actualizado = Date.now();
        datosAGuardar.fecha_actualizado = Date.now();

        datosAGuardar.cliente_ref = datosAGuardar.cliente?.id || "";
        datosAGuardar.cliente_ref = datosAGuardar.cliente?.id;
        datosAGuardar.destino_ref = datosAGuardar.destino?.id;
        datosAGuardar.frontera_ref = datosAGuardar.frontera?.id;

        return await firebase.firestore().collection("ciclos").doc(ciclo).collection("ordenes_venta").doc(ordenId).set(datosAGuardar, { merge: true })
    } catch (error) {
        log({ data: { mensaje: error.message || "", error, funcion: "ordenes/guardarOrdenVenta", ciclo, orden } })
        console.log("ERROR guardarOrdenVenta - ", error)
    }
};
export const actualizarOrdenVenta = async (ciclo, orden) => {
    try {
        //console.log("actualizarOrdenVenta 1: ", { ciclo, orden })

        let ordenCopia = DeepCloneVista(orden);
        delete ordenCopia.id;

        ordenCopia.fecha_envio = `${orden.fecha_envio}`;
        ordenCopia.actualizado = Date.now();
        orden.fecha_actualizado = Date.now();
        ordenCopia.cliente_ref = ordenCopia.cliente?.id || "";
        //console.log("actualizarOrdenVenta 2: ", { ciclo, orden })
        let res = await firebase.firestore().collection("ciclos").doc(ciclo).collection("ordenes_venta").doc(orden.id).update(ordenCopia);
        //console.log("GUARDAR - orden actualizado");
        return res;
    } catch (error) {
        console.log("ERROR actualizarOrdenVenta - ", error)
    }
}
export const eliminarOrden = (ciclo, orden) => {
    const db = firebase.firestore();
    return db.collection("ciclos").doc(ciclo).collection("ordenes_venta").doc(orden).delete();
}

export const obtenerKilosOrdenadosDia = (resumen, invernadero, dia) => {
    let suma = 0;

    resumen.forEach(row => {

        if (row.invernadero.id === invernadero.id && row.dia === dia) {
            suma += row["suma"];
        }
    })
    return suma;
}

export const obtenerkgm2SemanalOrdenes = (ciclo, invernadero, semana, area) => {

    return new Promise(async (resolve, reject) => {
        let presentaciones = await obtenerPresentaciones();
        obtenerOrdenesSemana(ciclo, semana).then((ordenes) => {
            let suma = 0;
            ordenes.forEach(orden => {

                orden.items.forEach(item => {
                    if (item.presentacion_ref) {

                        let presentacion = getObjetoPorID(presentaciones, item.presentacion_ref);
                        let peso = presentacion.peso_neto_caja
                        if (item.invernadero_ref === invernadero) {
                            let cajas = parseFloat(item.cajas_orden);
                            peso = parseFloat(peso);
                            if (!isNaN(cajas) && !isNaN(peso)) {
                                suma += peso * cajas;
                            }
                        }
                    }
                })

            });
            //let final = suma / area
            resolve(suma / area);
        }).catch((err) => {
            reject(err)
        });
    })
}
export const getOrdenesSemanaPesosTotal = async (ciclo, invernaderoId, semana, area) => {
    let promises = [obtenerPresentaciones()];
    promises.push(obtenerOrdenesSemana(ciclo, semana))
    const [presentaciones, ordenes] = await Promise.all(promises)
    let suma = 0;
    ordenes.forEach(orden => {
        orden.items.forEach(item => {
            if (item.invernadero_ref === invernaderoId) {
                let foundPres = presentaciones?.find(el => el.id === item.presentacion_ref) || {};
                const peso = parseFloat(foundPres.peso_neto_caja), cajas = parseFloat(item.cajas_orden);
                if (!isNaN(peso) && !isNaN(parseFloat(item.cajas_orden)))
                    suma += peso * cajas;
            }
        })
    })
    return suma;
}

export const obtenerPesoOrdenadoCiclo = (ciclo, invernadero) => {
    return new Promise(async (resolve, reject) => {
        let presentaciones = await obtenerPresentaciones();
        const db = firebase.firestore();
        db.collection("ciclos").doc(ciclo).collection("ordenes_venta").get().then((ordenes) => {
            registrarInvocaciones(ordenes.size)
            let suma = 0;
            ordenes.forEach(ordenSnap => {
                let orden = ordenSnap.data();

                orden.items.forEach(item => {
                    let presentacion = getObjetoPorID(presentaciones, item.presentacion_ref);
                    if (item.invernadero_ref === invernadero) {

                        let cajas = parseFloat(item.cajas_orden);
                        let peso = parseFloat(presentacion.peso_neto_caja);
                        if (!isNaN(cajas) && !isNaN(peso)) {
                            suma += peso * cajas;
                        }
                    }
                })

            });
            resolve(suma);
        }).catch((err) => {
            reject(err);
        });
    })
}
export const obtenerPesoOrdenadoCicloHastaSemana = (ciclo, invernadero, semanaTime) => {
    return new Promise(async (resolve, reject) => {
        let presentaciones = await obtenerPresentaciones();
        const db = firebase.firestore();
        db.collection("ciclos").doc(ciclo).collection("ordenes_venta").
            where("fecha_envio", "<=", semanaTime.toString()).
            get().then((ordenes) => {
                let suma = 0;
                ordenes.forEach(ordenSnap => {
                    let orden = ordenSnap.data();

                    orden.items.forEach(item => {
                        let presentacion = getObjetoPorID(presentaciones, item.presentacion_ref);
                        if (item.invernadero_ref === invernadero) {

                            let cajas = parseFloat(item.cajas_orden);
                            let peso = parseFloat(presentacion.peso_neto_caja);
                            if (!isNaN(cajas) && !isNaN(peso)) {
                                suma += peso * cajas;
                            }
                        }
                    })

                });
                resolve(suma);
            }).catch((err) => {
                reject(err);
            });
    })


}



export const obtenerSobranteSemanaPasada = (ciclo, invernadero, semana) => {
    return new Promise((resolve, reject) => {
        firebase.firestore().collection("ciclos").doc(ciclo).collection("sobrantes_semana").where("invernadero_ref", "==", invernadero)
            .where("semana", "==", semana - 7 * 24 * 60 * 60).get().then((result) => {
                registrarInvocaciones(result.size)

                if (result.empty) { resolve({ semana: semana, invernadero_ref: invernadero, sobrante: null }); }
                else { resolve(result.docs[0].data()); }
            }).catch((err) => {
                reject(err);
            });
    })
}
export const obtenerDisponibleFinalFijo = (ciclo, invernadero, semana) => {
    return new Promise((resolve, reject) => {
        firebase.firestore().collection("ciclos").doc(ciclo).collection("disponibles_final").where("invernadero_ref", "==", invernadero)
            .where("semana", "==", semana).get().then((result) => {
                registrarInvocaciones(result.size)

                if (result.empty) { resolve({ semana: semana, invernadero_ref: invernadero, disponible: null }); }
                else { resolve(result.docs[0].data()); }
            }).catch((err) => {
                reject(err);
            });
    })
}

export const agregarHistorico = (estadoAnterior, estadoNuevo, usuario) => {
    let fecha = Math.round(Date.now() / 1000);
    let items = [];
    estadoNuevo.items.forEach(item => {
        let anterior = estadoAnterior?.items?.find(el => el.presentacion_ref === item.presentacion_ref);
        if (anterior) {
            if (item.cajas_orden !== anterior.cajas_orden || item.pallets_orden !== anterior.pallets_orden) {
                items.push(anterior);
            }
        }
    });
    items.forEach(item => {
        firebase.firestore().collection("historico_orden_venta").add({
            fecha_modificacion: fecha,
            cajas: item.cajas_orden,
            pallets: item.pallets_orden,
            po: item.po,
            presentacion_ref: item.presentacion_ref,
            usuario,
            orden: estadoAnterior?.id || "undef"

        }).then((result) => {
            //console.log("DONE - agregarHistorico");
        }).catch((error) => {
            console.log("ERROR AGREGAR HISTORICO: ", { error, estadoAnterior, estadoNuevo, item });
        });
    })
}
export const manejarNotificacion = (estadoAnterior, estadoNuevo, usuario, ciclo) => {
    let fecha = obtenerNombreFecha(Math.round(Date.now() / 1000));
    let items = [];
    estadoNuevo.items.forEach(item => {
        let anterior = estadoAnterior?.items?.find(el => el.po === item.po);
        if (anterior) {
            if (item.cajas_orden !== anterior.cajas_orden || item.pallets_orden !== anterior.pallets_orden) {
                items.push(anterior);
            }
        } else items.push(anterior);
    });

    estadoAnterior.items.forEach(item => {
        let anterior = estadoNuevo.items.find(el => el.po === item.po);
        if (!anterior) { items.push(anterior); }
    });
    if (items.length > 0) { notificarOrdenesVenta(estadoAnterior, estadoNuevo, usuario, fecha, ciclo); }
}

export const manejarNotificacionRefactored = (estadoAnterior, estadoNuevo, usuario, ciclo) => {
    const items = [], fecha = obtenerNombreFecha(Math.round(Date.now() / 1000));
    estadoNuevo.items.forEach(item => {
        const anterior = estadoAnterior?.items.find(el => el.po === item.po);
        if (anterior && (item.cajas_orden !== anterior.cajas_orden || item.pallets_orden !== anterior.pallets_orden)) { items.push(anterior); }
        else if (!anterior) { items.push(anterior); }
    });

    estadoAnterior.items.forEach(item => {
        let anterior = estadoNuevo.items.find(el => el.po === item.po);
        if (!anterior) { items.push(anterior) }
    });
    if (items.length > 0) { notificarOrdenesVenta(estadoAnterior, estadoNuevo, usuario, fecha, ciclo); }
    console.log("GUARDAR - manejar notificacion");
}

export const updateSobranteSemana = async (ciclo, invernadero, semana, sobrante, esExterno) => {
    try {
        const db = firebase.firestore();
        let snapSobrante = await db.collection("ciclos").doc(ciclo).collection("sobrantes_semana").where("invernadero_ref", "==", invernadero).where("semana", "==", semana)
            .get();
        registrarInvocaciones(snapSobrante.size)
        if (snapSobrante.empty) {
            const defaultData = { invernadero_ref: invernadero, semana: semana, sobrante: sobrante }
            const data = esExterno ? { ...defaultData, esExterno } : { ...defaultData }
            return db.collection("ciclos").doc(ciclo).collection("sobrantes_semana").add(data)
        } else {
            let rf = snapSobrante.docs[0].ref.path;
            const data = esExterno ? { sobrante: sobrante, esExterno } : { sobrante: sobrante }
            return db.doc(rf).update(data)
        }
    } catch (error) {
        console.log("ERROR ACTUALIZAR SOBRANTE");
        throw error;
    }

}

export const obtenerKilosOrdenSemanal = (ciclo, invernadero, semana) => {


    return new Promise((resolve, reject) => {
        obtenerOrdenesSemana(ciclo, semana).then((ordenes) => {
            let suma = 0;
            ordenes.forEach(orden => {

                orden.items.forEach(item => {
                    let peso = item.presentacion.peso_neto_caja
                    if (item.invernadero_ref === invernadero) {
                        let cajas = parseFloat(item.cajas_orden);
                        peso = parseFloat(peso);
                        if (!isNaN(cajas) && !isNaN(peso)) {
                            suma += peso * cajas;
                        }
                    }
                })

            });
            resolve(suma);
        }).catch((err) => {
            reject(err)
        });
    })
}
export const obtenerHistorico = async (idOrden) => {
    const db = firebase.firestore();
    let registros = await db.collection("historico_orden_venta").where("orden", "==", idOrden)
        .orderBy("fecha_modificacion", "desc")
        .get();
    registrarInvocaciones(registros.size)
    let rows = [];
    registros.forEach(registro => {
        let data = registro.data();
        data.id = registro.id;
        rows.push(data);
    })
    return rows;
}
export const obtenerOrdenesCiclo = async (cicloId) => {
    const db = firebase.firestore();
    let snapOrdenes = await db.collection("ciclos").doc(cicloId).collection("ordenes_venta").get();
    registrarInvocaciones(snapOrdenes.size)
    let result = snapOrdenes.docs.map(el => ({ ...el.data(), id: el.id }));
    return result;
}
export const obtenerOrdenesCompraCiclo = async (cicloId) => {
/*    const db = firebase.firestore();
    let snapOrdenes = await db.collection("ciclos").doc(cicloId).collection("ordenes_compra").get();
    registrarInvocaciones(snapOrdenes.size)
    let result = snapOrdenes.docs.map(el => ({ ...el.data(), id: el.id }));
*/    return [];
}

export const actualizarDisponibleFinal = async (ciclo, invernadero, semana, invernaderos = [], presentaciones = [], clientes = [], resumenFinal = []) => {
    const db = firebase.firestore();
    let finalSabado = resumenFinal?.length >= 6 ? resumenFinal[5] : 0;
    console.log("actualizarDisponibleFinal", { invernadero, resumenFinal, finalSabado })

    let snapDocs = await db.collection("ciclos").doc(ciclo).collection("disponibles_final").where("invernadero_ref", "==", invernadero).where("semana", "==", semana).get();
    //console.log("4-------")
    registrarInvocaciones(snapDocs.size)
    if (snapDocs.empty) {
        //console.log("actualizarDisponibleFinal A --- add")
        return db.collection("ciclos").doc(ciclo).collection("disponibles_final").add(
            { invernadero_ref: invernadero, semana: semana, disponible_final: finalSabado });
    } else {
        //console.log("actualizarDisponibleFinal B --- update")
        return db.doc(snapDocs.docs[0].ref.path).update({ disponible_final: finalSabado });
    }
}
const procesarFinalInvernadero = (invernaderoId, finales) => {
    const result = [0, 0, 0, 0, 0, 0];
    finales = finales?.filter(final => invernaderoId === final.invernadero.id) || [];
    const inv = finales?.[0] || { final: [] };

    inv.final.forEach((dia, index) => {
        const dato = parseFloat(dia);
        if (!isNaN(dato)) { result[index] += dato; }
    })
    //console.log("procesarFinalInvernadero 1: ", finales)
    //console.log("procesarFinalInvernadero 2: ", result.map(el => dosDecimales(el)))
    return result.map(el => dosDecimales(el));
}
export const obtenerPesoOrdenadoHasta = (ciclo, invernadero, semana) => {
    return new Promise(async (resolve, reject) => {
        const db = firebase.firestore();
        let presentaciones = await obtenerPresentaciones();
        db.collection("ciclos").doc(ciclo).collection("ordenes_venta").where("fecha_envio", "<", semana.toString()).get().then((ordenes) => {
            registrarInvocaciones(ordenes.size)
            let suma = 0;
            ordenes.forEach(ordenSnap => {
                let orden = ordenSnap.data();

                orden.items.forEach(item => {
                    if (item.invernadero_ref === invernadero) {
                        let presentacion = getObjetoPorID(presentaciones, item.presentacion_ref);
                        let cajas = parseFloat(item.cajas_orden);
                        if (presentacion) {
                            let peso = parseFloat(presentacion.peso_neto_caja);
                            if (!isNaN(cajas) && !isNaN(peso)) {
                                suma += peso * cajas;
                            }
                        }

                    }
                })

            });
            resolve(suma);
        }).catch((err) => {
            reject(err);
        });
    })
}
export const getPesoOrdenadoHasta = async (ciclo, invernaderoId, semana) => {
    let promises = [obtenerPresentaciones()];
    promises.push(firebase.firestore().collection("ciclos").doc(ciclo).collection("ordenes_venta").where("fecha_envio", "<", semana.toString()).get())
    const [presentaciones, response] = await Promise.all(promises)
    const ordenes = response.docs.map(el => ({ ...el.data(), id: el.id }))
    registrarInvocaciones(ordenes.length)
    let suma = 0;
    ordenes.forEach(orden => {
        orden.items.forEach(item => {
            if (item.invernadero_ref === invernaderoId) {
                let foundPres = presentaciones?.find(el => el.id === item.presentacion_ref) || {};
                const peso = parseFloat(foundPres.peso_neto_caja), cajas = parseFloat(item.cajas_orden);
                if (!isNaN(peso) && !isNaN(parseFloat(item.cajas_orden)))
                    suma += peso * cajas;
            }
        })
    })
    return suma;
}
export const obtenerPesoOrdenadoHastaAlternativo = async (ciclo, invernadero, semana) => {
    const db = firebase.firestore();
    let presentaciones = await obtenerPresentaciones();
    let cicloObj = await obtenerCiclo(ciclo);
    let semanas = await obtenerListaDeSemanas(cicloObj.semana_inicio, semana)

    let ordenesSnap = await db.collection("ciclos").doc(ciclo).collection("ordenes_venta").where("fecha_envio", "<", semana.toString()).get()
    registrarInvocaciones(ordenesSnap.size)
    let ordenes = ordenesSnap.docs.map(el => ({ ...el.data(), id: el.id }))
    let suma = 0;
    ordenes.forEach(orden => {
        orden.items.forEach(item => {
            if (item.invernadero_ref === invernadero) {
                let presentacion = getObjetoPorID(presentaciones, item.presentacion_ref);
                let cajas = parseFloat(item.cajas_orden);
                if (presentacion) {
                    let peso = parseFloat(presentacion.peso_neto_caja);
                    if (!isNaN(cajas) && !isNaN(peso)) {
                        suma += peso * cajas;
                    }
                }

            }
        })

    });
    return suma;
}
export const getPesoOrdenadoHastaAlternativo = async (ciclo, invernadero, semana) => {
    let [presentaciones, ordenesSnap] = await Promise.all([obtenerPresentaciones(),
    firebase.firestore().collection("ciclos").doc(ciclo).collection("ordenes_venta").where("fecha_envio", "<", semana.toString()).get()])

    registrarInvocaciones(ordenesSnap.size)
    let ordenes = ordenesSnap.docs.map(el => ({ ...el.data(), id: el.id }))
    let suma = 0;
    ordenes.forEach(orden => {
        orden.items.forEach(item => {
            if (item.invernadero_ref === invernadero) {
                let presentacion = getObjetoPorID(presentaciones, item.presentacion_ref);
                let cajas = parseFloat(item.cajas_orden);
                if (presentacion) {
                    let peso = parseFloat(presentacion.peso_neto_caja);
                    if (!isNaN(cajas) && !isNaN(peso)) {
                        suma += peso * cajas;
                    }
                }
            }
        })
    });
    return suma;
}
export const obtenerKgm2OrdenadosHasta = (ciclo, invernadero, semana) => {
    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
        obtenerPesoOrdenadoHastaAlternativo(ciclo, invernadero, semana).then(respPeso => {
            obtenerAreaInvernadero(ciclo, invernadero).then(respArea => {
                resolve(respPeso / respArea);
            }).catch(err => {
                reject(err);
            });
        }).catch(err => {
            reject(err);
        });
    })
}
export const getKgm2OrdenadosHasta = async (ciclo, invernadero, semana, area) => {
    let respPeso = await getPesoOrdenadoHastaAlternativo(ciclo, invernadero, semana)
    return (respPeso / area);
}
export const obtenerKgm2OrdenadosHastaAlternativo = async (ciclo, invernadero, semana) => {
    const db = firebase.firestore();

    let respPeso = await obtenerPesoOrdenadoHastaAlternativo(ciclo, invernadero, semana)
    let respArea = await obtenerAreaInvernadero(ciclo, invernadero)
    return respPeso / respArea;



}
export const obtenerPesoInvernaderoPorSemana = async (cicloId, invernadero) => {
    let ciclo = await obtenerCiclo(cicloId);
    let ordenes = await obtenerOrdenesCiclo(cicloId);
    let presentaciones = await obtenerPresentaciones();
    let semanas = obtenerListaDeSemanas(ciclo.semana_inicio, ciclo.semana_cierre);
    let result = {};
    semanas.forEach(semana => {
        result[semana.time] = 0;
    })

    ordenes.forEach(orden => {
        let semana = obtenerInicioSemanaDia(orden["fecha_envio"]).unix()
        orden.items.forEach(item => {
            if (item.invernadero_ref === invernadero.id) {
                let cajas = parseFloat(item.cajas_orden);
                let presentacion = getObjetoPorID(presentaciones, item.presentacion_ref);
                let pesoPresentacion = parseFloat(presentacion.peso_neto_caja_libras);
                let peso = cajas * pesoPresentacion;
                result[semana] += peso;
            }
        })

    })
    return result;

}

export const obtenerItemsInvernaderoPorSemana = async (cicloId, invernadero) => {
    let ciclo = await obtenerCiclo(cicloId);
    let ordenes = await obtenerOrdenesCiclo(cicloId);
    let presentaciones = await obtenerPresentaciones();
    let semanas = obtenerListaDeSemanas(ciclo.semana_inicio, ciclo.semana_cierre);
    let result = {};
    semanas.forEach(semana => {
        result[semana.time] = [];
    })

    ordenes.forEach(orden => {
        let semana = obtenerInicioSemanaDia(orden["fecha_envio"]).unix()
        orden.items.forEach(item => {
            if (item.invernadero_ref === invernadero.id) {
                if (result[semana]) {
                    result[semana].push(item)
                } else {



                }
            }
        })

    })
    return result;
}//648


export const obtenerFotoUrlsdeOrdenDeVenta = async (ordenDeVenta, presentaciones = []) => {
    const promisesFotos = ordenDeVenta?.items?.map(item => getFotoUrlsDelOrden(item, presentaciones)) || [];
    const resultFotos = await Promise.all(promisesFotos)
    return resultFotos.filter(el => el != null);//Filtrar elementos que no son null
}


export const getFotoUrlsDelOrden = async (item, presentaciones) => {
    const presentacion = getObjetoPorID(presentaciones, item.presentacion_ref);

    if (presentacion?.fotos?.length > 0) {
        const foto_url = await obtenerUrlFoto(presentacion.fotos?.[0]).catch(error => { console.log("ERROR obtenerUrlFoto: ", error); });
        presentacion.foto_url = foto_url;
        return DeepClone(presentacion);
    }
    else return null;
}

export const getEmbarquesDeOrdenDeVentaPorManifiestos = async (manifiestos = [], ciclo) => {
    const manifiestosFiltrados = manifiestos?.filter(el => el.embarque_ref)
    const embarqueIds = manifiestosFiltrados?.map(manifiesto => manifiesto.embarque_ref);
    const embarquesDelOrden = [...new Set(embarqueIds)];
    const promisesEmbarques = embarquesDelOrden?.map(embarqueId => getEmbarque(embarqueId, ciclo));
    return promisesEmbarques?.length > 0 ? await Promise.all(promisesEmbarques) : [];
}

////////////////////////////////////////////////////////////
/////////////////////ASYNC HELPERS//////////////////////////
export const hayModificacionDelItem = (item, historico = []) => {
    let filtrados = historico?.filter(el => el.presentacion_ref === item.presentacion_ref) || [];
    return filtrados.length > 0;
}

export const procesarOrdenItemDatos = (ordenesDeVenta = []) => {//asignar Firestore ID's y sitios de empaque a los items de las ventas
    return ordenesDeVenta?.map(venta => {
        venta.items.forEach(item => {
            item.itemID = item.itemID || getFirestoreID();
            item.empaque_ref = item.empaque_ref || getEmpaqueDeVentaItem(getGlobal().invernaderosFisicos, getGlobal().invernaderosVirtuales, item.invernadero_ref);//Asignar a variable?
        })
        return venta;
    }) || [];
}

export const asignarOrdenItemIDs = (ordenDeVenta = {}) => {
    const copiaOrden = DeepClone(ordenDeVenta);
    //Asignar firestore id's a cada item so no existen actualmente
    copiaOrden.items = copiaOrden?.items?.map(el => ({ ...el, itemID: el.itemID || getFirestoreID() })) || [];
    return copiaOrden;
}

export const chunkCamiones = (array, size) => {
    const chunked_arr = [];
    for (let i = 0; i < array.length; i++) {
        const last = chunked_arr[chunked_arr.length - 1];
        if (!last || last.length === size) { chunked_arr.push([array[i]]); }
        else last.push(array[i]);
    }
    return chunked_arr;
}

export const obtenerDivsCamion = (camion, invernaderos = []) => {
    let divs = [];
    if (camion.manifiestos?.length > 0) {
        const manifiestosDelCamion = camion.manifiestos?.filter(manifiesto => manifiesto.orden_camion === camion.orden) || [];
        const itemsManifiestos = manifiestosDelCamion?.map(man => man.items) || [];
        const itemsTodos = itemsManifiestos.flat();
        //console.log("items de manifiestos obtenerDivsCamion: ", itemsTodos);

        if (invernaderos?.length > 0) {
            itemsTodos.forEach((item, itIndex) => {
                let pallets = Math.round((parseFloat(item.cajas_orden || 0) / parseFloat(item.presentacion.numero_cajas_pallet || 0)));
                let inv = invernaderos?.find(el => el.id === item.invernadero_ref);
                let color = inv.color || "gray-dark";

                for (let index = 0; index < pallets; index++) {
                    if (divs.length < 30) divs.push(<div key={`cam-bg-${itIndex}-${index}`} className={`camion-pallet bg-${color}`}></div>)
                }
            });
            //padding de camion
            for (let index = divs.length; index == 0 || index % 30 != 0; index++) {
                if (divs.length < 30) divs.push(<div key={`cam-${index}`} className={`camion-pallet `}></div>)
            }
            divs = divs.reverse();
        }
    }
    const palletsRounded = Math.round(camion.totalPallets);
    return <div className={`camion pallet-${palletsRounded} mt-2`}>{chunkCamiones(divs, 30)}</div>;
}

export const obtenerNoCamiones = (orden = {}, invernaderos = [],) => {
    let divs = [];
    if (invernaderos?.length > 0) {
        orden.items.forEach((item, itIndex) => {
            const pallets = Math.round(parseFloat(item.pallets_orden));
            const color = getObjetoPorID(invernaderos, item.invernadero_ref)?.color || "gray-dark";

            for (let index = 0; index < pallets; index++) {
                divs.push(<div key={`cam-bg-${itIndex}-${index}`} className={`camion-pallet bg-${color}`} />)
            }
        });
        //padding de camion
        for (let index = divs.length; index == 0 || index % 30 != 0; index++) {
            divs.push(<div key={`cam-${index}`} className={`camion-pallet `}></div>);
        }
        divs = divs.reverse();
        //console.log("CHECKING NO CAMIONES: ",chunk(divs, 30).length)
        const camionesDelOrden = chunkCamiones(divs, 30);
        //console.log("LENGTH: ", camionesDelOrden.length)
        return camionesDelOrden.length
    }
    else return 0;
}

export const ordenTieneHistorico = (orden = {}) => {
    //console.log(orden);
    return orden.consolidado != null;
}

export const obtenerTotalPallets = (orden = {}) => {
    const suma = sumarNumerosArrayPorCampo(orden.items, "pallets_orden");
    return dosDeciComas(suma);
}
export const obtenerTotalPalletsManifiestos = (manifiestos = []) => {
    const totalPallets = manifiestos?.reduce((acc, manifiesto) => {
        const totalPalletsOrder = manifiesto.items.reduce((accPallets, item) => {
            return accPallets + (parseFloat(item.cajas_orden) / parseFloat(item.presentacion.numero_cajas_pallet))
        }, 0);
        return acc + totalPalletsOrder
    }, 0);
    return dosDeciComas(totalPallets);
}
export const getTotalKilosManifiestos = (manifiestos = [], presentaciones = []) => {
    const totalKilos = manifiestos?.reduce((acc, manifiesto) => {
        manifiesto.items.forEach((item) => {
            const presentacion = presentaciones?.find(presentacion => presentacion.id === item.presentacion_ref)
            if (presentacion) { acc += parseFloat(presentacion.peso_neto_caja) * parseFloat(item.cajas_orden); }
        })
        return acc
    }, 0);
    return dosDeciComas(totalKilos);
}
/////////////////////END ASYNC HELPERS//////////////////////
//////////////////////////////////////////////////////////// - 700