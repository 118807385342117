import React, { useState, useGlobal, useEffect, } from "reactn";
import {
    obtenerIndiceDiaSemana,
    obtenerNombreSemana, obtenerDiaActual, obtenerHora,
    obtenerDiasDeSemana
} from "../../service/fechas";
import { obtenerCiclo, noHaSidoClausurado } from "../../service/Ciclos";
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { usePrimeReactTable } from "../common/tableHandler";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { guardarRegistroCosecha, guardarRegistroCosechaSubcultivo } from "../../service/Cosecha";
import { Button } from "primereact/button";
import { DeepClone, dosDecimales, getObjetoPorID, getProductoYVariedadString, separadoComas } from "../../util/functions";
import { obtenerPesoCajaParaSemana } from "../../service/Producto";
import { obtenerAreaInvernaderoSync, invernaderoPerteneceACicloActual } from "../../service/Invernaderos";
import { obtenerRegistrosSemanaAnterior, obtenerRegistrosSemanaActualVariedadesMezcla, obtenerRealesVariedadMezcla, extraProcessingDiarioNEW, extraProcessingSemanalNEW, extraProcessingReal, obtenerRegistrosSemanaActual } from './ConsultarCtrl';
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import ContenedorFiltro from "../common/ContenedorFiltro";
import { coloresCosecha, nombresDiasSemanaPlanos } from "../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartBar } from "@fortawesome/free-solid-svg-icons";
import ContenedorTabla from "../common/ContenedorTabla";
import SeccionBotones from "../common/SeccionBotones";
import SeccionFiltros from "../common/SeccionFiltros";
import RowBotonesTopThin from "../common/RowBotonesTopThin";
import ContenedorHeader from "../common/ContenedorHeader";
import ContenedorPrincipal from "../common/ContenedorPrincipal";

const tablaInicial = [//renglon 0 siempre va a ser el semanal, renglon 1 sera diario
    {
        label: "Semanal",
        lunes: "",
        martes: "",
        miercoles: "",
        jueves: "",
        viernes: "",
        sabado: "",
        total_cajas: 0,
        total_kg: 0
    },
    {
        label: "Diario",
        lunes: "",
        martes: "",
        miercoles: "",
        jueves: "",
        viernes: "",
        sabado: "",
        total_cajas: 0,
        total_kg: 0
    }
]
const anteriorInicial = [
    {
        label: "Semanal",
        lunes: "0",
        martes: "0",
        miercoles: "0",
        jueves: "0",
        viernes: "0",
        sabado: "0",
        total_cajas: 0,
        total_kg: 0
    },
    {
        label: "Diario",
        lunes: "0",
        martes: "0",
        miercoles: "0",
        jueves: "0",
        viernes: "0",
        sabado: "0",
        total_cajas: 0,
        total_kg: 0
    }
]

const ConsultarCosecha = (props) => {

    const extraProcessing = (updatedState, propsTabla, value) => {
        let dias = ["lunes", "martes", "miercoles", "jueves", "viernes", "sabado"];
        let timestamps = obtenerDiasDeSemana(week.time);
        let hoy = obtenerDiaActual();
        let hora = obtenerHora(Math.round(Date.now() / 1000));
        let indiceHoy = obtenerIndiceDiaSemana(hoy);
        let newerState = {}

        //procesar diario
        if (propsTabla.rowIndex === 0 && subVariedades?.length === 0) {
            newerState = extraProcessingSemanalNEW(updatedState, dias, pesoCaja, area);
            newerState = extraProcessingDiarioNEW(updatedState, dias, timestamps, hoy, hora, indiceHoy, pesoCaja, area)
        }//procesar semanal
        else if (propsTabla.rowIndex === 1 && subVariedades?.length === 0) {
            newerState = extraProcessingDiarioNEW(updatedState, dias, timestamps, hoy, hora, indiceHoy, pesoCaja, area)
        }
        else if (subVariedades?.length > 0) {
            let resumenUpdated = actualizarDetallesYResumen(updatedState, propsTabla.rowIndex)
            //console.log("RESUMEN UPDATED: " + JSON.stringify(resumenUpdated))
            extraProcessingReal(updatedState, dias, timestamps, hoy, hora, indiceHoy, pesoCaja, area, reales, setReales, true, resumenUpdated, setResumen)
            //console.log("-------------------------------------------")

        }
        if (subVariedades?.length === 0) {
            extraProcessingReal(updatedState, dias, timestamps, hoy, hora, indiceHoy, pesoCaja, area, reales, setReales, false)
        }
    }
    const [usuario,] = useGlobal("usuario");
    const [ciclo,] = useGlobal("ciclo");
    const [ciclos,] = useGlobal("ciclos");
    const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
    const [allVariedades] = useGlobal("allVariedades")
    const [productosYVariedadesTodos] = useGlobal("productosYVariedadesTodos")
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");

    const [pesoCaja, setPesoCaja] = useState(1);
    const [pesoCajaPasada, setPesoCajaPasada] = useState(1);
    const [area, setArea] = useState(1);
    const [weeks, week, handleWeekChange] = useWeekSelector(true);
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [invernadero, setInvernadero] = useGlobal("invernadero");
    const [tabla, setTabla] = useState(DeepClone(tablaInicial))
    const [anterior, setAnterior] = useState(DeepClone(anteriorInicial))
    const [semanaAnterior, setSemanaAnterior] = useState();
    const [reales, setReales] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
    const [realesAnteriores, setRealesAnteriores] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
    const [, setCargando] = useGlobal("cargando");
    const [subVariedades, setSubvariedades] = useState([]);
    const [subVariedad, setSubvariedad] = useState();
    const [variedades, setVariedades] = useState([]);
    const [detalles, setDetalles] = useState({});
    const [resumen, setResumen] = useState([])
    const [realesSubvariedad, setRealesSubvariedad] = useState([0, 0, 0, 0, 0, 0, 0, 0])
    const [desgloseColor, setDesgloseColor] = useState([]);
    const [editorGenerator, current] = usePrimeReactTable(tabla, extraProcessing);
    const [cosechaFirestoreIds, setCosechaFirestoreIds] = useState([])

    useEffect(() => {
        setHabilitadoSelectorCiclo(true)
        setNombreModulo("Cosecha");
    }, [])
    useEffect(() => {
        setSubvariedad(null)
        if (week.nombre != "Cargando" && invernadero)
            manejarCambioCicloWeekInvernadero();
    }, [week, invernadero])

    useEffect(() => {
        if (subVariedad) {
            let detallesVariedad = detalles[subVariedad["id"]];
            if (detallesVariedad) {
                actualizarDetallesYResumen(detallesVariedad);
            }
        }
    }, [subVariedad])
    useEffect(() => {
        if (subVariedades?.length === 0) {
            setSubvariedad(null)
        }
    }, [subVariedades])

    const actualizarDetallesYResumen = (updatedState, rowIndex) => {
        let copia = { ...detalles };
        let copiaTabla = [{ ...updatedState[0] }, { ...updatedState[1] }];
        if (subVariedad) copia[subVariedad?.id] = updatedState;
        let desgloseColores = [];
        let keys = Object.keys(detalles);

        let resumenSemanal = { label: "Semanal", total_cajas: 0, total_kg: 0 };
        let resumenDiario = { label: "Diario", total_cajas: 0, total_kg: 0 }
        copiaTabla[0]["total_cajas"] = 0;
        copiaTabla[0]["total_kg"] = 0;
        copiaTabla[1]["total_cajas"] = 0;
        copiaTabla[1]["total_kg"] = 0;
        coloresCosecha.forEach(color => {
            let obj = { color: color, semanal: {}, diario: {} }
            nombresDiasSemanaPlanos.forEach(nombreDia => {
                obj["semanal"][nombreDia] = 0;
                obj["diario"][nombreDia] = 0;
            })
            desgloseColores.push(obj);
        })

        nombresDiasSemanaPlanos.forEach((dia, ind) => {
            let datoSemanalDia = parseFloat(updatedState[0][dia])
            let datoDiarioDia = parseFloat(updatedState[1][dia])
            desgloseColores[dia] = 0;
            resumenSemanal[dia] = 0
            resumenDiario[dia] = 0
            console.log("dia:  " + dia)

            if (datoSemanalDia) {
                console.log(" -- sem: " + datoSemanalDia)
                //resumenSemanal["total_cajas"] += datoSemanalDia;
                //resumenSemanal["total_kg"] += datoSemanalDia;
                copiaTabla[0]["total_cajas"] += datoSemanalDia;
                let hoy = obtenerDiaActual();
                let indiceHoy = obtenerIndiceDiaSemana(hoy);
                let hora = obtenerHora(Math.round(Date.now() / 1000));

                if (indiceHoy < ind && !datoDiarioDia) {
                    copiaTabla[1]["total_cajas"] += datoSemanalDia;
                }
            }
            if (datoDiarioDia) {
                //console.log(" -- dia: " + datoDiarioDia)
                //resumenDiario["total_cajas"] += datoDiarioDia;
                //resumenDiario["total_kg"] += datoDiarioDia;
                copiaTabla[1]["total_cajas"] += datoDiarioDia;
            }

            keys.forEach(k => {
                let semanal = parseFloat(copia[k][0][dia]);
                //   console.log("semm " + semanal)
                resumenSemanal[dia] += semanal && !isNaN(semanal) ? semanal : 0
                let diario = parseFloat(copia[k][1][dia]);
                resumenDiario[dia] += diario && !isNaN(diario) ? diario : 0
                let subVariedad = variedades.find(el => k === el.id);
                //    console.log("dirio " + diario)
                if (subVariedad) {
                    let row = desgloseColores.find(el => el.color === subVariedad.color)
                    if (row) {
                        row["semanal"][dia] += isNaN(semanal) ? 0 : semanal
                        row["diario"][dia] += isNaN(diario) ? 0 : diario
                    }
                }
            })

        })
        resumenSemanal[6] = 0
        resumenDiario[6] = 0
        if (subVariedad) {
            let idSubVariedad = subVariedad["id"]
            let porcentajeSubVariedades = invernadero["porcentajesMezclaVariedades"].find(el => el.id === idSubVariedad);
            if (porcentajeSubVariedades) {
                copiaTabla[0]["total_kg"] = (parseFloat(copiaTabla[0]["total_cajas"]) * parseFloat(pesoCaja)) / (parseFloat(porcentajeSubVariedades["porcentaje"]) * area / 100);
                copiaTabla[1]["total_kg"] = copiaTabla[1]["total_cajas"] * pesoCaja / (parseFloat(porcentajeSubVariedades["porcentaje"]) * area / 100);

                resumenSemanal["total_kg"] = (resumenSemanal["total_cajas"] * parseFloat(pesoCaja)) / area;
                resumenDiario["total_kg"] = (resumenDiario["total_cajas"] * parseFloat(pesoCaja)) / area;
            }
        }

        setDetalles(copia);
        setTabla(copiaTabla);
        setDesgloseColor(desgloseColores);
        obtenerReales(subVariedad, copia, rowIndex);
        return [resumenSemanal, resumenDiario];
    }

    const unidadTemplatekgm = (rowData, column) => {
        let st = `<p class='tooltip-title'>Valores usados para cálculo</p><p class='tooltip-def'> </p> <div class='tooltip-datas'> <div class='tooltip-data'>${dosDecimales(pesoCaja)} kgs<p class='tooltip-label'>Peso de caja<br/>(semana pasada)</p></div><div class='tooltip-data'>${area} m²<p class='tooltip-label'>Área<br/>invernadero</p></div></div>`;
        return <div>{dosDecimales(rowData[column.field])}<span className="label-unidad">kg/m²</span> <Button type="button" label="?" className="btn-line btn-help" tooltip={st} /></div>;
    }
    const unidadTemplatekgmSubvariedad = (rowData, column) => {
        let porcentaje = invernadero?.["porcentajesMezclaVariedades"]?.find(el => el.id === subVariedad?.id)?.["porcentaje"] || 0;
        let areaSub = area * porcentaje / 100.0;
        let st = `<p class='tooltip-title'>Valores usados para cálculo</p><p class='tooltip-def'> </p> <div class='tooltip-datas'> <div class='tooltip-data'>${dosDecimales(pesoCaja)} kgs<p class='tooltip-label'>Peso de caja<br/>(semana pasada)</p></div><div class='tooltip-data'>${areaSub} m²<p class='tooltip-label'>Área<br/>invernadero</p></div></div>`;
        return <div key={`rep-uni-temp-${rowData[column.field]}`}>{dosDecimales(rowData[column.field])}<span className="label-unidad">kg/m²</span> <Button type="button" label="?" className="btn-line btn-help" tooltip={st} /></div>;
    }
    const unidadTemplatekgmPasada = (rowData, column) => {
        let st = `<p class='tooltip-title'>Valores usados para cálculo</p><p class='tooltip-def'> </p> <div class='tooltip-datas'> <div class='tooltip-data'>${dosDecimales(pesoCajaPasada)} kgs<p class='tooltip-label'>Peso de caja<br/>(semana antepasada)</p></div><div class='tooltip-data'>${area} m²<p class='tooltip-label'>Área<br/>invernadero</p></div></div>`;
        return <div>{dosDecimales(rowData[column.field])}<span className="label-unidad">kg/m²</span><Button type="button" label="?" className="btn-line btn-help" tooltip={st} /> </div>;
    }
    const unidadTemplatecaja = (rowData, column) => {
        if (!rowData[column.field]) return ""
        return <div>{separadoComas(dosDecimales(rowData[column.field]))}<span className="label-unidad">cajas</span> </div>;
    }

    const guardar = async () => {
        setCargando(true);

        if (subVariedades?.length > 0) {
            guardarRegistroCosechaSubcultivo(ciclo, invernadero.id, week.time, detalles, cosechaFirestoreIds).then(resp => {
                setCargando(false);
            }).catch(err => {
                console.log("HUBO UN ERROR AL REGISTRAR COSECHA:: " + err.message + " -- " + JSON.stringify(err));
            }).finally(() => {
                props.history.goBack();
            })
        }
        else {
            guardarRegistroCosecha(ciclo, invernadero.id, week.time, tabla, cosechaFirestoreIds).then(resp => {
                setCargando(false);
            }).catch(err => {
                console.log("HUBO UN ERROR AL REGISTRAR COSECHA:: " + err.message + " -- " + JSON.stringify(err));
            }).finally(() => {
                props.history.goBack();
            })
        }
    }

    const obtenerReales = async (subVariedad, detalles, rowIndex) => {
        let realesPromise = obtenerRealesVariedadMezcla(ciclo, invernadero.id, week.time, subVariedad, detalles, rowIndex);
        let area = obtenerAreaInvernaderoSync(invernadero);
        let pesoCajaPromise = obtenerPesoCajaParaSemana(ciclo, invernadero.id, week.time, parseFloat(invernadero.peso_caja_cosecha))

        let [allReales, pesoCaja] = await Promise.all([realesPromise, pesoCajaPromise])
        let porcentajes = invernadero.porcentajesMezclaVariedades;
        let porcentajeSubvariedad = porcentajes.find(el => el.id === subVariedad?.id);

        // let total = reales.reduce((acc,curr) => {
        //    return acc + curr; 
        // },0)
        // reales.push(total);
        let total = allReales[6]
        if (porcentajeSubvariedad) {
            let fraccionSubvariedad = parseFloat(porcentajeSubvariedad["porcentaje"]) / 100;
            allReales.push(total * pesoCaja / (area * fraccionSubvariedad));
        }
        allReales.forEach(rr => {
            //console.log(JSON.stringify(rr))
        })
        setRealesSubvariedad(allReales);
        return allReales
    }


    const manejarCambioCicloWeekInvernadero = async (params) => {
        try {
            setCargando(true);

            const invernaderoOBJ = getObjetoPorID(invernaderosVirtuales, invernadero.id);
            const producto = getObjetoPorID(productosYVariedadesTodos, invernaderoOBJ.producto_ref);
            let area = obtenerAreaInvernaderoSync(invernaderoOBJ);
            setArea(area);

            let hoy = obtenerDiaActual();
            let indiceHoy = obtenerIndiceDiaSemana(hoy);
            let hora = obtenerHora(Math.round(Date.now() / 1000));
            let indexSemanaActual = weeks.findIndex(el => el.time === week.time);
            let semanaAnterior = weeks[indexSemanaActual - 1];
            let pesoCajaSemanaPasadaPromise = obtenerPesoCajaParaSemana(ciclo, invernadero.id, semanaAnterior.time, parseFloat(invernaderoOBJ.peso_caja_cosecha));
            let resultPromise = obtenerPesoCajaParaSemana(ciclo, invernadero.id, week.time, parseFloat(invernaderoOBJ.peso_caja_cosecha))
            const [pesoCajaSemanaPasada, result] = await Promise.all([pesoCajaSemanaPasadaPromise, resultPromise])

            const mezclaVariedades = producto?.base ? [] : producto.variedades.map(el => el.id);
            const allSubVariedades = producto?.base ? [] : producto.variedades;
            setPesoCaja(result);
            setPesoCajaPasada(pesoCajaSemanaPasada)
            setSemanaAnterior(semanaAnterior);

            obtenerRegistrosSemanaAnterior(ciclo, semanaAnterior, invernadero, anteriorInicial, pesoCajaSemanaPasada, area, setAnterior, setRealesAnteriores);
            if (allSubVariedades?.length === 0) obtenerRegistrosSemanaActual(indiceHoy, ciclo, invernadero, week, setReales, hora, result, area, tabla, tablaInicial, setTabla, setCosechaFirestoreIds);
            else obtenerRegistrosSemanaActualVariedadesMezcla(indiceHoy, ciclo, invernadero, week, setReales, hora, result, area, tabla, tablaInicial, setResumen, setDetalles, mezclaVariedades, setCosechaFirestoreIds);
            //no es necesario esperar a obtener reales de semana anterior porque se asume 
            //que semana anterior ya no depende de busqueda inteligunte
            setVariedades(allVariedades);
            setSubvariedades(allSubVariedades);
        } catch (err) {
            console.log("ERROR MANEJO: ", err);
        }
        setCargando(false);
    }

    const puedeEditar = () => noHaSidoClausurado(ciclo, ciclos) && ["Administrador", "Grower", "Auxiliar Grower"].includes(usuario?.rol)

    const obtenerFooterSubvariedad = () =>
        <ColumnGroup>
            <Row>
                <Column footer="Real" colSpan={1} />
                <Column footer={<div>{separadoComas(realesSubvariedad[0])}<span className="label-unidad">cajas</span></div>} colSpan={1} />
                <Column footer={<div>{separadoComas(realesSubvariedad[1])}<span className="label-unidad">cajas</span></div>} colSpan={1} />
                <Column footer={<div>{separadoComas(realesSubvariedad[2])}<span className="label-unidad">cajas</span></div>} colSpan={1} />
                <Column footer={<div>{separadoComas(realesSubvariedad[3])}<span className="label-unidad">cajas</span></div>} colSpan={1} />
                <Column footer={<div>{separadoComas(realesSubvariedad[4])}<span className="label-unidad">cajas</span></div>} colSpan={1} />
                <Column footer={<div>{separadoComas(realesSubvariedad[5])}<span className="label-unidad">cajas</span></div>} colSpan={1} />
                <Column footer={<div>{separadoComas(realesSubvariedad[6])}<span className="label-unidad">cajas</span></div>} colSpan={1} />
                <Column footer={<div>{separadoComas(realesSubvariedad[7])}<span className="label-unidad">kg/m²</span></div>} colSpan={1} />
            </Row>
        </ColumnGroup>;


    const obtenerFooter = () =>
        <ColumnGroup>
            <Row>
                <Column footer="Real" colSpan={1} />
                <Column footer={<div>{separadoComas(reales[0])}<span className="label-unidad">cajas</span></div>} colSpan={1} />
                <Column footer={<div>{separadoComas(reales[1])}<span className="label-unidad">cajas</span></div>} colSpan={1} />
                <Column footer={<div>{separadoComas(reales[2])}<span className="label-unidad">cajas</span></div>} colSpan={1} />
                <Column footer={<div>{separadoComas(reales[3])}<span className="label-unidad">cajas</span></div>} colSpan={1} />
                <Column footer={<div>{separadoComas(reales[4])}<span className="label-unidad">cajas</span></div>} colSpan={1} />
                <Column footer={<div>{separadoComas(reales[5])}<span className="label-unidad">cajas</span></div>} colSpan={1} />
                <Column footer={<div>{separadoComas(reales[6])}<span className="label-unidad">cajas</span></div>} colSpan={1} />
                <Column footer={<div>{separadoComas(reales[7])}<span className="label-unidad">kg/m²</span></div>} colSpan={1} />
            </Row>
        </ColumnGroup>;

    const obtenerFooterAnterior = () =>
        <ColumnGroup>
            <Row>
                <Column footer="Real" colSpan={1} />
                <Column footer={<div>{separadoComas(realesAnteriores[0])}<span className="label-unidad">cajas</span></div>} colSpan={1} />
                <Column footer={<div>{separadoComas(realesAnteriores[1])}<span className="label-unidad">cajas</span></div>} colSpan={1} />
                <Column footer={<div>{separadoComas(realesAnteriores[2])}<span className="label-unidad">cajas</span></div>} colSpan={1} />
                <Column footer={<div>{separadoComas(realesAnteriores[3])}<span className="label-unidad">cajas</span></div>} colSpan={1} />
                <Column footer={<div>{separadoComas(realesAnteriores[4])}<span className="label-unidad">cajas</span></div>} colSpan={1} />
                <Column footer={<div>{separadoComas(realesAnteriores[5])}<span className="label-unidad">cajas</span></div>} colSpan={1} />
                <Column footer={<div>{separadoComas(realesAnteriores[6])}<span className="label-unidad">cajas</span></div>} colSpan={1} />
                <Column footer={<div>{separadoComas(realesAnteriores[7])}<span className="label-unidad">kg/m²</span></div>} colSpan={1} />
            </Row>
        </ColumnGroup>;

    const unidadTemplateColor = (rowData, column) =>
        <div>{separadoComas(dosDecimales(rowData.semanal[column.field]))}<span className="label-unidad">cajas diario</span>
            /{separadoComas(dosDecimales(rowData.diario[column.field]))}<span className="label-unidad">cajas semanal</span>
        </div>;

    const irReporte = () => { props.history.push("/cosecha/reporte") }
    const esFechaValida = (indexDia, indexRow) => {
        let dias = obtenerDiasDeSemana(week.time);
        let dia = dias[indexDia];
        let ahora = Math.round(Date.now() / 1000);

        if (indexRow === 0) {//validar semanal
            let diasEstaSemana = obtenerDiasDeSemana(ahora);
            let sabado = diasEstaSemana[5] + 12 * 60 * 60;
            return (usuario && !usuario.bloqueado) || (dia > sabado && ahora < sabado);
        } else return (usuario && !usuario.bloqueado) || ahora < dia + 12 * 60 * 60; //validar diario
    }

    return (
        <ContenedorPrincipal>
            <ContenedorHeader col="p-col p-col-auto" titulo="Registro de cosecha" subtitulo={`${getProductoYVariedadString(invernadero || {})}`} atras={props.history.goBack}
                invernaderoNombre={invernadero?.nombre} iconos={`icon-general icon-id bor-${invernadero?.color} bg-${invernadero?.color} ${invernadero?.habilitado ? "" : "Deshabilitado"}`} />

            <RowBotonesTopThin>
                {subVariedades?.length > 0 &&
                    <Button label="Gráfica de distribución subvariedades" onClick={() => { props.history.push("/graficas/distribucion_variedades_medleys") }} className="reactbtn-icon">
                        <FontAwesomeIcon icon={faChartBar} />
                    </Button>}
                <Button label="Reporte de cosecha" onClick={irReporte} />
            </RowBotonesTopThin>
            <SeccionFiltros>
                <ContenedorFiltro label="invernadero">
                    <Dropdown id="filtroInvernadero" value={invernadero} placeholder="Seleccionar" dataKey="id" optionLabel="nombre"
                        options={invernaderosVirtuales?.filter(el => !el.es_externo)} onChange={(e) => { setInvernadero(e.target.value) }} />
                </ContenedorFiltro>
                <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
                {subVariedades?.length > 0 &&
                    <ContenedorFiltro label="variedad">
                        <Dropdown disabled={!subVariedades?.length} dataKey="id" optionLabel="nombre" options={subVariedades} value={subVariedad} onChange={e => setSubvariedad(e.value)} />
                    </ContenedorFiltro>}
            </SeccionFiltros>

            {subVariedades?.length > 0 &&
                <React.Fragment>
                    {subVariedad ?
                        <ContenedorTabla titulo={`Registro - ${subVariedad.nombre}`}>
                            <DataTable value={tabla} className="table-footer__total" footerColumnGroup={obtenerFooterSubvariedad()}>
                                <Column key="rep-var-h" field="label" header="" className="col__label" />
                                <Column key="rep-var-1" body={unidadTemplatecaja} field="lunes" header="Lunes" editor={(props) => { return esFechaValida(0, props.rowIndex) && puedeEditar() ? editorGenerator(props, "lunes") : <div>No editable</div> }} />
                                <Column key="rep-var-2" body={unidadTemplatecaja} field="martes" header="Martes" editor={(props) => { return esFechaValida(1, props.rowIndex) && puedeEditar() ? editorGenerator(props, "martes") : <div>No editable</div> }} />
                                <Column key="rep-var-3" body={unidadTemplatecaja} field="miercoles" header="Miércoles" editor={(props) => { return esFechaValida(2, props.rowIndex) && puedeEditar() ? editorGenerator(props, "miercoles") : <div>No editable</div> }} />
                                <Column key="rep-var-4" body={unidadTemplatecaja} field="jueves" header="Jueves" editor={(props) => { return esFechaValida(3, props.rowIndex) && puedeEditar() ? editorGenerator(props, "jueves") : <div>No editable</div> }} />
                                <Column key="rep-var-5" body={unidadTemplatecaja} field="viernes" header="Viernes" editor={(props) => { return esFechaValida(4, props.rowIndex) && puedeEditar() ? editorGenerator(props, "viernes") : <div>No editable</div> }} />
                                <Column key="rep-var-6" body={unidadTemplatecaja} field="sabado" header="Sábado" editor={(props) => { return esFechaValida(5, props.rowIndex) && puedeEditar() ? editorGenerator(props, "sabado") : <div>No editable</div> }} />
                                <Column key="rep-var-7" body={unidadTemplatecaja} field="total_cajas" header="Total de cajas" className="col__total" />
                                <Column key="rep-var-8" body={unidadTemplatekgmSubvariedad} field="total_kg" header="Total de kg/m²" className="col__total" />
                            </DataTable>
                        </ContenedorTabla>
                        : <div className="p-col-12"><p className="section-title">No hay variedad seleccionada</p></div>}
                </React.Fragment>}

            {subVariedades?.length > 0 ?
                <ContenedorTabla titulo={`Reporte - Semana ${obtenerNombreSemana(week.time)}`}>
                    <DataTable value={resumen} className="table-footer__total" footerColumnGroup={obtenerFooter()}>
                        <Column key="rep-act-h" field="label" header="" className="col__label" />
                        <Column key="rep-act-1" body={unidadTemplatecaja} field="lunes" header="Lunes" />
                        <Column key="rep-act-2" body={unidadTemplatecaja} field="martes" header="Martes" />
                        <Column key="rep-act-3" body={unidadTemplatecaja} field="miercoles" header="Miércoles" />
                        <Column key="rep-act-4" body={unidadTemplatecaja} field="jueves" header="Jueves" />
                        <Column key="rep-act-5" body={unidadTemplatecaja} field="viernes" header="Viernes" />
                        <Column key="rep-act-6" body={unidadTemplatecaja} field="sabado" header="Sábado" />
                        <Column key="rep-act-7" body={unidadTemplatecaja} field="total_cajas" header="Total de cajas" className="col__total" />
                        <Column key="rep-act-8" body={unidadTemplatekgm} field="total_kg" header="Total de kg/m²" className="col__total" />
                    </DataTable>
                </ContenedorTabla>
                :
                <ContenedorTabla titulo={`Registro - Semana ${obtenerNombreSemana(week.time)}`}>
                    <DataTable value={tabla} className="table-footer__total" footerColumnGroup={obtenerFooter()}>
                        <Column field="label" header="" className="col__label" />
                        <Column body={unidadTemplatecaja} field="lunes" header="Lunes" editor={(props) => { return esFechaValida(0, props.rowIndex) && puedeEditar() ? editorGenerator(props, "lunes") : <div>No editable</div> }} />
                        <Column body={unidadTemplatecaja} field="martes" header="Martes" editor={(props) => { return esFechaValida(1, props.rowIndex) && puedeEditar() ? editorGenerator(props, "martes") : <div>No editable</div> }} />
                        <Column body={unidadTemplatecaja} field="miercoles" header="Miércoles" editor={(props) => { return esFechaValida(2, props.rowIndex) && puedeEditar() ? editorGenerator(props, "miercoles") : <div>No editable</div> }} />
                        <Column body={unidadTemplatecaja} field="jueves" header="Jueves" editor={(props) => { return esFechaValida(3, props.rowIndex) && puedeEditar() ? editorGenerator(props, "jueves") : <div>No editable</div> }} />
                        <Column body={unidadTemplatecaja} field="viernes" header="Viernes" editor={(props) => { return esFechaValida(4, props.rowIndex) && puedeEditar() ? editorGenerator(props, "viernes") : <div>No editable</div> }} />
                        <Column body={unidadTemplatecaja} field="sabado" header="Sábado" editor={(props) => { return esFechaValida(5, props.rowIndex) && puedeEditar() ? editorGenerator(props, "sabado") : <div>No editable</div> }} />
                        <Column body={unidadTemplatecaja} field="total_cajas" header="Total de cajas" className="col__total" />
                        <Column body={unidadTemplatekgm} field="total_kg" header="Total de kg/m²" className="col__total" />
                    </DataTable>
                </ContenedorTabla>}

            <ContenedorTabla titulo={`Reporte - Semana ${semanaAnterior ? obtenerNombreSemana(semanaAnterior.time) : "Anterior"}`}>
                <DataTable value={anterior} className="table-footer__total" footerColumnGroup={obtenerFooterAnterior()}>
                    <Column field="label" header="" className="col__label" />
                    <Column body={unidadTemplatecaja} field="lunes" header="Lunes" />
                    <Column body={unidadTemplatecaja} field="martes" header="Martes" />
                    <Column body={unidadTemplatecaja} field="miercoles" header="Miércoles" />
                    <Column body={unidadTemplatecaja} field="jueves" header="Jueves" />
                    <Column body={unidadTemplatecaja} field="viernes" header="Viernes" />
                    <Column body={unidadTemplatecaja} field="sabado" header="Sábado" />
                    <Column body={unidadTemplatecaja} field="total_cajas" header="Total de cajas" className="col__total" />
                    <Column body={unidadTemplatekgmPasada} field="total_kg" header="Total de kg/m²" className="col__total" />
                </DataTable>
            </ContenedorTabla>

            {subVariedades?.length > 0 &&
                <ContenedorTabla titulo="Desglose colores">
                    <DataTable value={desgloseColor} >
                        <Column field="color" header="Color" className="col__label" />
                        <Column body={unidadTemplateColor} field="lunes" header="Lunes" />
                        <Column body={unidadTemplateColor} field="martes" header="Martes" />
                        <Column body={unidadTemplateColor} field="miercoles" header="Miércoles" />
                        <Column body={unidadTemplateColor} field="jueves" header="Jueves" />
                        <Column body={unidadTemplateColor} field="viernes" header="Viernes" />
                        <Column body={unidadTemplateColor} field="sabado" header="Sábado" />
                    </DataTable>
                </ContenedorTabla>}

            <SeccionBotones>
                <Button label="Cancelar" className="p-button-secondary" onClick={props.history.goBack} />
                {puedeEditar() && <Button label="Guardar" onClick={guardar} />}
            </SeccionBotones>
        </ContenedorPrincipal>);
}
export default ConsultarCosecha;//1051 (425 + ConsultarSubVariedades 626)//494