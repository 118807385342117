import React, { useState, useEffect, useGlobal } from 'reactn';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { obtenerSobranteSemanaPasada, updateSobranteSemana, actualizarDisponibleFinal } from '../../../service/Ordenes';

const InputSobrante = ({ label, value, name, onChange, disabled }) => {
    return (
        <div className="form-group p-col-12">
            <label>{label}</label>
            <InputText name={name} value={value} onChange={onChange} disabled={disabled} />
        </div>
    )
}

const FormularioSobrante = (props) => {
    const [ciclo] = useGlobal("ciclo");
    const [sitiosEmpaqueCicloActual] = useGlobal("sitiosEmpaqueCicloActual")
    const [, setCargando] = useGlobal("cargando");
    const [invernaderos] = useGlobal("invernaderosVirtuales");
    const [invernadero, setInvernadero] = useState();
    const [sobrante, setSobrante] = useState(0);
    const [sobranteInvExternos, setSobranteInvExternos] = useState({})
    const [presentaciones] = useGlobal("presentaciones");
    const [clientes] = useGlobal("clientesDelCiclo");

    useEffect(() => {
        if (invernadero) { obtenerDatosInicialesSobrante(); }
    }, [invernadero]);

    const obtenerDatosInicialesSobrante = async () => {
        const result = await obtenerSobranteSemanaPasada(ciclo, invernadero.id, props.semana);
        const datoSobrante = (result.sobrante !== null) ? result.sobrante : 0;
        if (result?.esExterno) {
            setSobranteInvExternos({ ...result.sobrante })
        } else {
            setSobrante(datoSobrante || 0);
        }
    }

    const guardarSobrante = async () => {
        setCargando(true);
        props.onHide();
        const sobranteAGuardar = invernadero?.es_externo ? sobranteInvExternos : parseFloat(sobrante)
        await updateSobranteSemana(ciclo, invernadero.id, props.semana - 7 * 24 * 60 * 60, sobranteAGuardar, invernadero?.es_externo);
        props.onActualizado();
        const resumenFinal =  props.getResumenFinalInv(invernadero);
        await actualizarDisponibleFinal(ciclo, invernadero.id, props.semana, invernaderos, presentaciones, clientes, resumenFinal);
        setCargando(false);
    }
    const handleChangeLortSobrante = (e) => {
        const { name, value } = e.target
        setSobranteInvExternos({
            ...sobranteInvExternos,
            [name]: parseInt(value) || 0
        })
    }
    const isGreenhouseExternal = !!invernadero?.es_externo
    const sitios = sitiosEmpaqueCicloActual.filter(el => el.id !== "sin")
    const InputsEmpaque = () => {
        if (Object.keys(sobranteInvExternos)?.length === 0 && !!sobrante) {
            return <>

                <div className="form-group p-col-12">
                    <label>Sobrante general</label>
                    <InputText value={sobrante} disabled={true} />
                </div>
                {isGreenhouseExternal && sitios.map((sitioEmpaque) => {
                    //console.log("CHECKING: ", { sobrante, sobranteInvExternos })

                    const sitioNombre = sitioEmpaque?.nombre
                    const nameValue = sitioEmpaque.id
                    const label = `Sobrante para ${sitioNombre || ""}`

                    return <InputSobrante
                        key={sitioNombre}
                        name={nameValue}
                        label={label}
                        disabled={!invernadero}
                        value={sobranteInvExternos[nameValue] || 0}
                        onChange={handleChangeLortSobrante}
                    />
                })}
            </>

        }
        //console.log("CHECKING: ", { sobrante, sobranteInvExternos,isGreenhouseExternal,sitios })

        return <>
      {  isGreenhouseExternal && sitios.map((sitioEmpaque) => {
            //console.log("CHECKING: ", { sobrante, sobranteInvExternos })

            const sitioNombre = sitioEmpaque?.nombre
            const nameValue = sitioEmpaque.id
            const label = `Sobrante para ${sitioNombre || ""}`

            return <InputSobrante
                key={sitioNombre}
                name={nameValue}
                label={label}
                disabled={!invernadero}
                value={sobranteInvExternos[nameValue] || 0}
                onChange={handleChangeLortSobrante}
            />
        })
       } </>
    }
    return (
        <div>
            <div className="form-group p-col-12">
                <label>Invernadero</label>
                <Dropdown options={invernaderos} value={invernadero} optionLabel="nombre" dataKey="id" onChange={e => setInvernadero(e.value)} />
            </div>
            {isGreenhouseExternal ? InputsEmpaque()
                : <InputSobrante
                    label={`Sobrante de ${invernadero?.nombre || ""}`}
                    value={sobrante}
                    disabled={!invernadero}
                    onChange={e => setSobrante(parseInt(e.target.value) || 0)}
                />}
            {invernadero &&
                <React.Fragment>

                    <div className="form-group p-col-12">
                        <div className="p-justify-end btn-row">
                            <Button label="Guardar" onClick={guardarSobrante} />
                        </div>
                    </div>
                </React.Fragment>}
        </div>);
}
export default FormularioSobrante;