import React, {useState, useEffect, useGlobal} from "reactn";

import { obtenerDefectos } from '../../service/Defectos';
import { obtenerAcumuladoPresentacionPesosCalidad, obtenerReportePesos, obtenerReportePesosDesglosadoDia, obtenerResumenSemanalPresentacionPesosCalidad } from '../../service/ReporteCalidad';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { obtenerNombreFecha } from "../../service/fechas";
import { obtenerPresentaciones  } from '../../service/Presentaciones';
import { dosDecimales } from '../../util/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp, faArrowCircleDown} from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import { obtenerEmpacadoras } from "../../service/Empacadoras";
const  ReporteDefectosTotal = (props) => {
    const [rows, setRows] = useState([]);
    const [presentaciones, setPresentaciones] = useState([]);
    const [invernadero, setInvernadero] = useGlobal("invernadero");
    const [cargando, setCargando] = useGlobal("cargando");
    const [invernaderos, setInvernaderos] = useGlobal("invernaderos");
    const [ciclo, ] = useGlobal("ciclo");
    const [presentacionesPresentes, setPresentacionesPresentes] = useState([]);
    const [comentarios, setComentarios] = useState([]);
    const [, setMostrarModalPermiso] = useGlobal("mostrarModalPermiso");
    const [expandedRows, setExpandedRows] = useState(null)
    const [acumulados, setAcumulados] = useState([]);
    useEffect(() => {        
        obtenerPresentaciones().then((result) => {
            setPresentaciones(result);
        }).catch((err) => {});
    }, [])
    useEffect(() => {
        actualizarDatos() 
    }, [invernadero, props.semana, presentaciones, props.filtroDias])
    const actualizarDatos = async (params) => {
        try {
            if(ciclo && invernadero && props.semana && presentaciones.length >0 && props.filtroDias){
                
                setCargando(true)
                let result =0;
                if(props.filtroDias === "semana"){

                    result = await obtenerReportePesos(ciclo, invernadero.id, props.semana, presentaciones )
                }else{
                    let dia = props.dias.find(el=>props.filtroDias === el.nombrePlano);
                    
                    
                    result =  await obtenerReportePesosDesglosadoDia(ciclo, invernadero.id, props.semana, presentaciones, dia.unixTime )
                }
                    procesarReporte(result);
                let empacadorasPromise = obtenerEmpacadoras();
                let acumuladoPromise = obtenerAcumuladoPresentacionPesosCalidad(ciclo, invernadero.id, presentaciones)
                const [empacadoras,acumulado] = 
                    await Promise.all([empacadorasPromise,acumuladoPromise])
                let procesado = procesarAcumulado(empacadoras, acumulado); 
                
                
                     
                   
                setAcumulados(procesado);      
            }    
        } catch (err) {
            if(err.code && err.code === "permission-denied"){
                setMostrarModalPermiso(true);
                console.log(err.code);
              }
            console.log("ERROR REPORTE DEFECTOS");
            console.log(err);  
        }finally{
            setCargando(false);
        }
        
    }
    
    const rowExpansionTemplate = (data) => {
        let dato = acumulados.find(el=>el.empacadora === data.nombre);
        return (
            <DataTable value = {[dato]}>
                {obtenerColumnasExpander()}
            </DataTable>  )
    }
    const procesarAcumulado = (empacadoras, acumulado) => {
        let totalManual = {nombre:"TOTAL MANUAL", empacadora:"TOTAL MANUAL"};
        let totalAutomatico = {nombre:"AUTOMÁTICA", empacadora:"AUTOMÁTICA"};
        let superTotal = {nombre:"TOTAL", empacadora:"TOTAL"};
        presentaciones.forEach(presentacion => {            
            totalManual[presentacion.id]={    altos:0, bajos:0, normales:0, muestras:0,
                sumaPesoAltos:0, sumaPesoBajos:0, sumaPesoNormales:0, }
            totalAutomatico[presentacion.id]={    altos:0, bajos:0, normales:0, muestras:0,
                sumaPesoAltos:0, sumaPesoBajos:0, sumaPesoNormales:0, }
            superTotal[presentacion.id]={    altos:0, bajos:0, normales:0, muestras:0,
                sumaPesoAltos:0, sumaPesoBajos:0, sumaPesoNormales:0, }
        })
        let result = empacadoras.map(empacadora=>{
            let obj = {}
            presentaciones.forEach(presentacion => {
                obj[presentacion.id] = {
                    altos:0, bajos:0, normales:0, muestras:0,
                    sumaPesoAltos:0, sumaPesoBajos:0, sumaPesoNormales:0, 
                };
                obj["empacadora"] = empacadora.nombre;
               
            })
            
            acumulado.forEach(currSemana => {
                let desviaciones = currSemana["desviacionesEmpacadoras"].find(el=>el.nombre === empacadora.nombre);
                if(desviaciones){
                    presentaciones.forEach(presentacion => {
                        let desviacionesPresentacion = desviaciones[presentacion.id];
                        if(desviacionesPresentacion){
                            obj[presentacion.id]["altos"] += desviacionesPresentacion["altos"];
                            obj[presentacion.id]["normales"] += desviacionesPresentacion["normales"];
                            obj[presentacion.id]["bajos"] += desviacionesPresentacion["bajos"];
                            obj[presentacion.id]["muestras"] += desviacionesPresentacion["muestras"];
                            obj[presentacion.id]["sumaPesoAltos"] += desviacionesPresentacion["sumaPesoAltos"];
                            obj[presentacion.id]["sumaPesoBajos"] += desviacionesPresentacion["sumaPesoBajos"];
                            obj[presentacion.id]["sumaPesoNormales"] += desviacionesPresentacion["sumaPesoNormales"];
                            
                            totalManual[presentacion.id]["altos"] += desviacionesPresentacion["altos"];
                            totalManual[presentacion.id]["normales"] += desviacionesPresentacion["normales"];
                            totalManual[presentacion.id]["bajos"] += desviacionesPresentacion["bajos"];
                            totalManual[presentacion.id]["muestras"] += desviacionesPresentacion["muestras"];
                            totalManual[presentacion.id]["sumaPesoAltos"] += desviacionesPresentacion["sumaPesoAltos"];
                            totalManual[presentacion.id]["sumaPesoBajos"] += desviacionesPresentacion["sumaPesoBajos"];
                            totalManual[presentacion.id]["sumaPesoNormales"] += desviacionesPresentacion["sumaPesoNormales"];

                            totalAutomatico[presentacion.id]["altos"] += desviacionesPresentacion["altos"];
                            totalAutomatico[presentacion.id]["normales"] += desviacionesPresentacion["normales"];
                            totalAutomatico[presentacion.id]["bajos"] += desviacionesPresentacion["bajos"];
                            totalAutomatico[presentacion.id]["muestras"] += desviacionesPresentacion["muestras"];
                            totalAutomatico[presentacion.id]["sumaPesoAltos"] += desviacionesPresentacion["sumaPesoAltos"];
                            totalAutomatico[presentacion.id]["sumaPesoBajos"] += desviacionesPresentacion["sumaPesoBajos"];
                            totalAutomatico[presentacion.id]["sumaPesoNormales"] += desviacionesPresentacion["sumaPesoNormales"];

                            superTotal[presentacion.id]["altos"] += desviacionesPresentacion["altos"];
                            superTotal[presentacion.id]["normales"] += desviacionesPresentacion["normales"];
                            superTotal[presentacion.id]["bajos"] += desviacionesPresentacion["bajos"];
                            superTotal[presentacion.id]["muestras"] += desviacionesPresentacion["muestras"];
                            superTotal[presentacion.id]["sumaPesoAltos"] += desviacionesPresentacion["sumaPesoAltos"];
                            superTotal[presentacion.id]["sumaPesoBajos"] += desviacionesPresentacion["sumaPesoBajos"];
                            superTotal[presentacion.id]["sumaPesoNormales"] += desviacionesPresentacion["sumaPesoNormales"];
                        }
                    })
                    
                }
            })

            return obj
        })
        
        acumulado.forEach(currSemana => {
            let desviaciones = currSemana["desviacionesAuto"]
            if(desviaciones){
                presentaciones.forEach(presentacion => {
                    let desviacionesPresentacion = desviaciones[presentacion.id];
                    if(desviacionesPresentacion){
                       

                        totalAutomatico[presentacion.id]["altos"] += desviacionesPresentacion["altos"];
                        totalAutomatico[presentacion.id]["normales"] += desviacionesPresentacion["normales"];
                        totalAutomatico[presentacion.id]["bajos"] += desviacionesPresentacion["bajos"];
                        totalAutomatico[presentacion.id]["muestras"] += desviacionesPresentacion["muestras"];
                        totalAutomatico[presentacion.id]["sumaPesoAltos"] += desviacionesPresentacion["sumaPesoAltos"];
                        totalAutomatico[presentacion.id]["sumaPesoBajos"] += desviacionesPresentacion["sumaPesoBajos"];
                        totalAutomatico[presentacion.id]["sumaPesoNormales"] += desviacionesPresentacion["sumaPesoNormales"];

                        superTotal[presentacion.id]["altos"] += desviacionesPresentacion["altos"];
                        superTotal[presentacion.id]["normales"] += desviacionesPresentacion["normales"];
                        superTotal[presentacion.id]["bajos"] += desviacionesPresentacion["bajos"];
                        superTotal[presentacion.id]["muestras"] += desviacionesPresentacion["muestras"];
                        superTotal[presentacion.id]["sumaPesoAltos"] += desviacionesPresentacion["sumaPesoAltos"];
                        superTotal[presentacion.id]["sumaPesoBajos"] += desviacionesPresentacion["sumaPesoBajos"];
                        superTotal[presentacion.id]["sumaPesoNormales"] += desviacionesPresentacion["sumaPesoNormales"];
                    }
                })
                
            }
        })
        
        result.push(superTotal);
        result.push(totalAutomatico);
        result.push(totalManual);
        
        return result;
    }
    
    const procesarReporte = (result) => {
        
        
        let listaPresentaciones = Object.keys(result["desviacionesTotal"]);
        
        result["desviacionesTotal"]["nombre"] = "TOTAL";
        result["desviacionesAuto"]["nombre"] = "AUTOMÁTICA";
        result["desviacionesManual"]["nombre"] = "TOTAL MANUAL";
        
        let renglonesTablaPorEmpacadora = result["desviacionesEmpacadoras"].concat(
            [result["desviacionesManual"],
            result["desviacionesAuto"],
            result["desviacionesTotal"]]
        ).filter((row) => {
            // conservar únicamente los renglones con registros, por defecto todos los renglones
            // cuentan con el key 'nombre'
            if (!row['nombre']) return false
            if (row['nombre'] === 'TOTAL') return true
            if (row['nombre'] === 'AUTOMÁTICA') return true
            if (row['nombre'] === 'TOTAL MANUAL') return true
            return Object.keys(row).length > 1
        });
        setRows(renglonesTablaPorEmpacadora);
        listaPresentaciones = listaPresentaciones.map(presentacionRef=>{
            return presentaciones.find(el=>el.id === presentacionRef);
        })
        
        
        setPresentacionesPresentes(listaPresentaciones);
        //agregar nombres a defectos
        // agregar columna total
        setComentarios(result["comentarios"]);
        
    }
    const templateBody = (rowData,column) => {
        
        if(!rowData){
            return ""
        }
        let data = rowData[column["field"]];
        if(  data){
            let presentacion = presentaciones.find(el=>el.id === column["field"]);

            let datoAlto = dosDecimales(data["altos"]/data["muestras"]);
            let datoNormal = dosDecimales(data["normales"]/data["muestras"]);
            let datoBajo = dosDecimales(data["bajos"]/data["muestras"]);
            let pesoNetoPresentacion = presentacion.peso_neto_presentacion
            let promedioPesosNormales = data["normales"] === 0 ? 0 : (data["sumaPesoNormales"]/data["normales"])
            let promedioPesosAltos = data["altos"] === 0 ? 0 : (data["sumaPesoAltos"]/data["altos"])
            let promedioPesosBajos = data["bajos"] === 0 ? 0 : (data["sumaPesoBajos"]/data["bajos"])
            let porcentajePesosNormales = dosDecimales(100 * (promedioPesosNormales - pesoNetoPresentacion) / pesoNetoPresentacion)
            let porcentajePesosAltos = dosDecimales(100 * (promedioPesosAltos - pesoNetoPresentacion) / pesoNetoPresentacion)
            let porcentajePesosBajos = dosDecimales(100 * (promedioPesosBajos - pesoNetoPresentacion) / pesoNetoPresentacion)
            let porcentajePesosNormalesFormateado = porcentajePesosNormales === -100 ? '-' : porcentajePesosNormales
            let porcentajePesosAltosFormateado = porcentajePesosAltos === -100 ? '-' : porcentajePesosAltos
            let porcentajePesosBajosFormateado = porcentajePesosBajos === -100 ? '-' : porcentajePesosBajos
            let datoTotalMuestras = dosDecimales(data["normales"]+data["altos"]+data["bajos"]);

            let promedioTodos = (data["sumaPesoNormales"] + data["sumaPesoAltos"] + data["sumaPesoBajos"])/data["muestras"]
            let desviacionTodos = promedioTodos - pesoNetoPresentacion
            let promedioPorcentaje = dosDecimales((desviacionTodos/pesoNetoPresentacion)*100)

            return <React.Fragment>
                

                <div className="muestras-totales" title="Muestras realizadas">
                    {datoTotalMuestras }<span class="label-unidad"  >Muestras/</span>
                    {promedioPorcentaje + "%"}<span class="label-unidad"  >Promedio</span>
                    
                </div>
                <div className="pesos-porcentajes">
                <div className="calidad-etiquetas" class="d-none" >

                </div>
                <div className="datos-pesos peso-correcto"> 
                    
                    <FontAwesomeIcon icon={faCheckCircle} title="Peso correcto"/>

                    <div>
                    <div className="muestra" title="Desviación peso correcto">
                        {Math.round( 100 * datoNormal) }<span class="label-unidad">%</span>
                    </div>
                    <div className="muestra" title="Peso promedio correcto">
                        { porcentajePesosNormalesFormateado }<span class="label-unidad">%</span>
                    </div>

                    </div>

                    
                </div>

                <div  className = "datos-pesos sobre-maximo">
                    <FontAwesomeIcon icon={faArrowCircleUp} title="Peso excedido"/>

                    <div>
                    <div className="muestra" title="Desviación peso excedido">
                        {Math.round(100 * datoAlto)}<span class="label-unidad">%</span>
                    </div>
                    <div className="muestra" title="Peso promedio excedido">
                        { porcentajePesosAltosFormateado }<span class="label-unidad">%</span>
                    </div>
                    
                    </div>

                    
                </div>
                <div  className = "datos-pesos bajo-minimo">
                    <FontAwesomeIcon icon={faArrowCircleDown} title="Peso bajo"/>

                    <div>
                    <div className="muestra" title="Desviación peso bajo">
                        {Math.round( 100 * datoBajo)}<span class="label-unidad">%</span>
                    </div>
                    <div className="muestra" title="Peso promedio bajo">
                        { porcentajePesosBajosFormateado }<span class="label-unidad">%</span>
                    </div>
                    
                    </div>

                    
                </div>
                </div>

                
            </React.Fragment>
           
        }else{
            return "";
        }
        
    }
    const templateBodyExpander = (rowData,column) => {
        
        
        
        if(!rowData){
            return ""
        }
        let data = rowData[column["field"]];
        if(  data){
            
            
            let presentacion = presentaciones.find(el=>el.id === column["field"]);

            
            let datoAlto = data["muestras"]>0? dosDecimales(data["altos"]/data["muestras"]):0;
            let datoNormal = data["muestras"]>0? dosDecimales(data["normales"]/data["muestras"]):0;
            let datoBajo = data["muestras"]>0? dosDecimales(data["bajos"]/data["muestras"]):0;
            let pesoNetoPresentacion = parseFloat(presentacion.peso_neto_presentacion)
            
            let promedioPesosNormales = data["normales"] === 0 ? 0 : (data["sumaPesoNormales"]/data["normales"])
            let promedioPesosAltos = data["altos"] === 0 ? 0 : (data["sumaPesoAltos"]/data["altos"])
            let promedioPesosBajos = data["bajos"] === 0 ? 0 : (data["sumaPesoBajos"]/data["bajos"])
            let porcentajePesosNormales = dosDecimales(100 * (promedioPesosNormales - pesoNetoPresentacion) / pesoNetoPresentacion)
            let porcentajePesosAltos = dosDecimales(100 * (promedioPesosAltos - pesoNetoPresentacion) / pesoNetoPresentacion)
            let porcentajePesosBajos = dosDecimales(100 * (promedioPesosBajos - pesoNetoPresentacion) / pesoNetoPresentacion)
            let porcentajePesosNormalesFormateado = porcentajePesosNormales === -100 ? '-' : porcentajePesosNormales
            let porcentajePesosAltosFormateado = porcentajePesosAltos === -100 ? '-' : porcentajePesosAltos
            let porcentajePesosBajosFormateado = porcentajePesosBajos === -100 ? '-' : porcentajePesosBajos
            let datoTotalMuestras = data["normales"]+data["altos"]+data["bajos"];

            let promedioTodos = (data["sumaPesoNormales"] + data["sumaPesoAltos"] + data["sumaPesoBajos"])/data["muestras"]
            let desviacionTodos = promedioTodos - pesoNetoPresentacion
            let promedioPorcentaje = dosDecimales((desviacionTodos/pesoNetoPresentacion)*100) || 0

            
            return <React.Fragment>
                

                <div className="muestras-totales">
                {datoTotalMuestras }<span class="label-unidad"  >Muestras/</span>
                {promedioPorcentaje + "%"}<span class="label-unidad"  >Promedio</span>
                    
                </div>
                <div className="pesos-porcentajes">
                <div className="calidad-etiquetas">

                </div>
                <div className="datos-pesos peso-correcto"> 
                    
                    <FontAwesomeIcon icon={faCheckCircle}/>

                    <div>
                    <div className="muestra-porcentaje">
                        {Math.round( 100 * datoNormal) }<span class="label-unidad">%</span>
                    </div>
                    <div className="muestra-desviación">
                        { porcentajePesosNormalesFormateado }<span class="label-unidad">%</span>
                    </div>

                    </div>

                    
                </div>

                <div  className = "datos-pesos sobre-maximo">
                    <FontAwesomeIcon icon={faArrowCircleUp}/>

                    <div>
                    <div className="muestra-porcentaje">
                        {Math.round(100 * datoAlto)}<span class="label-unidad">%</span>
                    </div>
                    <div className="muestra-desviación">
                        { porcentajePesosAltosFormateado }<span class="label-unidad">%</span>
                    </div>
                    
                    </div>

                    
                </div>
                <div  className = "datos-pesos bajo-minimo">
                    <FontAwesomeIcon icon={faArrowCircleDown}/>

                    <div>
                    <div className="muestra-porcentaje">
                        {Math.round( 100 * datoBajo)}<span class="label-unidad">%</span>
                    </div>
                    <div className="muestra-desviación">
                        { porcentajePesosBajosFormateado }<span class="label-unidad">%</span>
                    </div>
                    
                    </div>

                    
                </div>
                </div>

                
            </React.Fragment>
           
        }else{
            return "";
        }
        
    }
    
    const obtenerColumnas = (params) => {
        //anadir columnas por defecto
        let columnas = [<Column expander style={{ width: '3em' }} />,<Column field = "nombre" header = ""/> ];

        presentacionesPresentes.forEach((presentacion) => {
            columnas.push(<Column field={ presentacion.id } header={ presentacion.presentacion } body={ templateBody }/>)
        });
        return columnas;
    }
    const obtenerColumnasExpander = (params) => {
        //anadir columnas por defecto
        let columnas = [,<Column field = "" header = "Acumulado ciclo"/> ];

        presentacionesPresentes.forEach((presentacion) => {
            columnas.push(<Column field={ presentacion.id } header={ presentacion.presentacion } body={ templateBodyExpander }/>)
        });
        return columnas;
    }
    const handleRowToggle = (e) => {
        
        
        setExpandedRows(e.data);
    }
    
    return <React.Fragment>


    <div className="card p-0">
    <DataTable value = {[rows[rows.length-3],rows[rows.length-2],rows[rows.length-1]]} className="table-total-3"
        expandedRows = {expandedRows} onRowToggle= {handleRowToggle}
        rowExpansionTemplate={rowExpansionTemplate}>
        {obtenerColumnas()}
    </DataTable>
    </div>

    </React.Fragment>
}

export default ReporteDefectosTotal;