import React, { useEffect } from 'react'
import ContenedorTabla from '../../common/ContenedorTabla'
import { DataTable } from 'primereact/datatable'
import { useGlobal, useState } from 'reactn'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import ContenedorHeader from '../../common/ContenedorHeader'
import ContenedorInput from '../../common/ContenedorInput'
import ContenedorFiltro from '../../common/ContenedorFiltro'
import SeccionFormulario from '../../common/SeccionFormulario'
import { obtenerClientes } from '../../../service/Clientes';

import ContenedorPrincipal from '../../common/ContenedorPrincipal'
import SeccionFiltros from '../../common/SeccionFiltros'
import SeccionBotones from '../../common/SeccionBotones'

import { obtenerGruposPresentacionesOrdenadas } from '../../../service/QueriesGruposPresentaciones'
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import ContenedorBotonesTop from '../../common/ContenedorBotonesTop'
import RowBotonesTop from '../../common/RowBotonesTop'
import { getObjetoPorID } from '../../../util/functions'

export default function GruposPresentaciones(props) {
    const [grupos, setGrupos] = useState([]);
    const [grupoPresentaciones, setGrupoPresentaciones] = useGlobal("grupoPresentaciones")
    const [nombreModulo, setNombreModulo] = useGlobal("nombreModulo");
    const [usuario,] = useGlobal("usuario");
    const [clientes, setClientes] = useState([]);
    const [clienteFiltros, setClienteFiltros] = useState([]);
    const [productos, setProductos] = useState([]);
    const [productoFiltros, setProductoFiltros] = useState([]);
    const [gruposFiltrados, setGruposFiltrados] = useState([])
    const [, setCargando] = useGlobal("cargando")
    const [productosBases] = useGlobal("productosBases")

    const crearPresentacion = (params) => {
        props.history.push("/admin/grupo_presentaciones/crear")
    }
    useEffect(() => {
        setNombreModulo('Grupos de presentaciones')
        if (productosBases) { obtenerDatosIniciales(); }
    }, [productosBases])
    const obtenerDatosIniciales = async (params) => {
        let gruposPromise = obtenerGruposPresentacionesOrdenadas();
        let allClientesPromise = obtenerClientes();

        const [grupos, allClientes] = await Promise.all([gruposPromise, allClientesPromise])

        setGrupos(grupos);
        setGruposFiltrados(grupos)

        setProductos(productosBases)
        setProductoFiltros(productosBases)
        setClientes(allClientes)
        setClienteFiltros(allClientes)

    }
    const irGrupoPresentaciones = (event) => {


        if (["Administrador"].includes(usuario.rol)) {
            setGrupoPresentaciones(event.data);
            props.history.push({ pathname: `/admin/grupo_presentaciones/modificar/${event.data.id}`, });
        }

    }


    useEffect(() => {
        if (grupos.length > 0) {
            setCargando(true)
            let presentacionesFil = []
            let productoFiltrosIds = productoFiltros.map(pro => pro.id)
            let clienteFiltrosIds = clienteFiltros.map(cli => cli.id)

            if ((productoFiltros.length === 0 || clienteFiltros.length === 0) && productos.length !== 0 && clientes.length != 0)
                setGruposFiltrados([])
            else if (clienteFiltros.length > 0 && productoFiltros.length > 0) {
                grupos.forEach(pres => {
                    if (clienteFiltrosIds.includes(pres.cliente_ref) && productoFiltrosIds.includes(pres.producto_ref))
                        presentacionesFil.push(pres)
                })
                setGruposFiltrados(presentacionesFil)
            }
            else if (clienteFiltros.length > 0) {
                grupos.forEach(pres => {
                    if (clienteFiltrosIds.includes(pres.cliente_ref))
                        presentacionesFil.push(pres)
                })
                setGruposFiltrados(presentacionesFil)
            }
            else if (productoFiltros.length > 0) {
                grupos.forEach(pres => {
                    if (productoFiltrosIds.includes(pres.producto_ref))
                        presentacionesFil.push(pres)
                })
                setGruposFiltrados(presentacionesFil)
            }

            setCargando(false)
        }

    }, [clienteFiltros, productoFiltros])

    return (
        <ContenedorPrincipal>

            <ContenedorHeader titulo="Grupos de presentaciones" iconos="presentacion-icon"
                col="p-col p-col-auto" />
            <ContenedorBotonesTop>
                <RowBotonesTop>
                    {<Button label="Crear nueva" onClick={crearPresentacion} />}
                </RowBotonesTop>
            </ContenedorBotonesTop>

            <SeccionFiltros>

                <ContenedorFiltro label="Clientes">
                    <MultiSelect id="cliente" placeholder="Seleccionar"
                        dataKey="id" optionLabel="nombre"
                        options={clientes} value={clienteFiltros}
                        onChange={(e) => { setClienteFiltros(e.target.value) }}
                        selectedItemsLabel={`${clienteFiltros.length} clientes seleccionados`} />
                </ContenedorFiltro>
                <ContenedorFiltro label="Productos">
                    <MultiSelect id="producto" placeholder="Seleccionar"
                        dataKey="id" optionLabel="nombre"
                        options={productosBases} value={productoFiltros}
                        onChange={(e) => { setProductoFiltros(e.target.value) }}
                        selectedItemsLabel={`${productoFiltros.length} productos seleccionados`} />
                </ContenedorFiltro>

            </SeccionFiltros>


            <ContenedorTabla >
                <DataTable value={gruposFiltrados}
                    onRowClick={irGrupoPresentaciones}>
                    <Column field="nombre" header="Nombre" sortable={true} />
                </DataTable>
            </ContenedorTabla>
        </ContenedorPrincipal>
    )
}
