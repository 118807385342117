import React,{useEffect, useState} from "react";
import { useGlobal } from 'reactn';
import Chart from "react-apexcharts";
import { obtenerInvernaderosVirtualesHabilitados } from "../../service/Invernaderos";
import { obtenerReportesCosechaCiclo } from '../../service/Cosecha';
import { MultiSelect } from "primereact/multiselect";
import { obtenerCiclo } from "../../service/Ciclos";
import { dosDecimales, stringToColor } from "../../util/functions";
import { obtenerListaDeSemanas } from "../../service/fechas";

const optionsOrig = {
    legend:{
     formatter:function(seriesName,opts){
       let s =  `<span class="solid-line"></span> ` ;
       if (seriesName.includes("Diario vs Semanal") || seriesName.includes("Cajas Semanal")){
         s =  `<span class="point-line"></span> ` ;
       }
       if(seriesName.includes("Real vs Diario") || seriesName.includes("Cajas Diario")){
         s =  `<span class="dashed-line"></span> ` ;
       }
       return `<div class = "chart-label"><span class = "dash-type">${s}</span> ${seriesName}</div>`;
     }
    },
    chart: {
      type: 'area',
      height: 350,
      zoom: {
        enabled: true,
        type: "x",
        autoScaleYAxis: true
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    
    title: {
      text: 'Comparaciones de cosecha en invernaderos',
      align: 'left',
      style: {
        fontSize: '14px'
      }
    },
    xaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      title:{
        text:"Porcentajes - 100 %"
      },
      tickAmount: 4,
      floating: false,
    
      labels: {
        style: {
          colors: '#8e8da4',
        },
        offsetY: -7,
        offsetX: 0,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false
      }
    },
    fill: {
      opacity: 0.5
    },
    tooltip: {
      y : {
        title:{
          formatter:function(seriesName) {
            let s =  `<span class="solid-line"></span> ` ;
        if (seriesName.includes("Diario vs Semanal")|| seriesName.includes("Cajas Semanal")){
          s =  `<span class="point-line"></span> ` ;
        }
        if(seriesName.includes("Real vs Diario")|| seriesName.includes("Cajas Diario")){
          s =  `<span class="dashed-line"></span> ` ;
        }
        return `<div class = "chart-label"><span class = "dash-type">${s}</span> ${seriesName}</div>`;
     
            
            
            return seriesName
        }}
      },
      fixed: {
        enabled: false,
        position: 'topRight'
      }
    },
    grid: {
      yaxis: {
        lines: {
          offsetX: -30
        }
      },
      padding: {
        left: 20
      }
    }
  }
const CosechaFiltroInvernaderoMultiCiclo = (props) => {
  const [ciclo, ] = useGlobal("ciclo");
  const [,setCargando ] = useGlobal("cargando");
  const [filtros, setFiltros] = useState([]);
  const [data, setData] = useState([]);
  const [filtrosOrig, setFiltrosOrig] = useState([]);
  const [options,setOptions] = useState({...optionsOrig});
  const [invernaderos, setInvernaderos] = useState([])
  const [cicloObj, setCicloObj] = useState()
  const [reportes, setReportes] = useState([])
  const [arregloInvsVirtuales] = useGlobal("arregloInvsVirtuales");
  
  useEffect(() => {
      if(ciclo){
          obtenerDatosIniciales();
      }
      
      
  }, [ciclo]);
  const obtenerDatosIniciales = async (params) => {
      try {
          // obtener invernaderos
          setCargando(true);
          
      let inicio = Date.now();
      let fin = 0;
      let dif = 0;
      let invernaderos = arregloInvsVirtuales[props.cicloPassedIn.id] || await obtenerInvernaderosVirtualesHabilitados(props.cicloPassedIn.id);
      setInvernaderos(invernaderos);
      
      fin = Date.now();
      dif = fin -inicio;
      
      // setear filtros con invernaderos
      setFiltros(invernaderos);
      setFiltrosOrig(invernaderos);
      
      // obtener porcentajes de cada semana en ciclo por cada invernadero
      let cicloObj = await obtenerCiclo(props.cicloPassedIn.id);
      setCicloObj(cicloObj);
      
      fin = Date.now();
      dif = fin -inicio;
      
      let reportesCosechaCiclo = await obtenerReportesCosechaCiclo(cicloObj);
      setReportes(reportesCosechaCiclo);
      
      fin = Date.now();
      dif = fin -inicio;
      
      
      
        
      //setData(procesar(reportesCosechaCiclo,invernaderos,cicloObj));    
      } catch (error) {
          console.log("ERROR OBTENER DATOS INICIALES");
          console.log(error);
      }finally{
        setCargando(false);
      }
      
  }    
  
  useEffect(() => {
    if(props.tipo && cicloObj){
      
      
      
      
      
      let procesado= procesar(reportes, invernaderos,cicloObj)
      setData(procesado)
    }
  }, [props.tipo, reportes, cicloObj, invernaderos])
  const procesar = (reportes,invernaderos, cicloObj) => {
    if(props.tipo === "porcentajes"){
      return procesarPorcentajes(reportes,invernaderos, cicloObj)
    }else{
      return procesarTotales(reportes,invernaderos, cicloObj);
    }
  }
  
  const procesarTotales = (reportes,invernaderos, cicloObj) => {
    
    
    
    
      let semanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
      let labels = semanas.map(el=>{
          return el.nombre;
      })
      let copiaOptions ={...options};
      let colors = [];
      let width = [];
      let dashArray = [];
          invernaderos.forEach(inv => {
              for (let index = 0; index < 3; index++) {
                  width.push(5*(index+1));
                  dashArray.push(3*index);
                  colors.push(stringToColor(inv.color));
              }
          })                
      
      
      
      
      
      
      copiaOptions["stroke"]["width"] = width;
      copiaOptions["stroke"]["dashArray"] = dashArray;
      copiaOptions["title"]["text"] = "Comparaciones de cosecha en invernaderos " + props.cicloPassedIn.nombre;

      copiaOptions["colors"] = colors;
      setOptions(copiaOptions);
      let data = [];
      invernaderos.forEach(invernadero => {
          let semanal = {idInvernadero: invernadero.id, name: `Cajas Semanal ${invernadero.nombre}`, data:[], idAmbos:`semanal - ${invernadero.id}`};
          let diario = {idInvernadero: invernadero.id, name: `Cajas Diario ${invernadero.nombre}`, data:[], idAmbos:`diario - ${invernadero.id}`};
          let real = {idInvernadero: invernadero.id, name: `Cajas Real ${invernadero.nombre}`, data: [], idAmbos:`real - ${invernadero.id}`};
          reportes.forEach((semana,index) => {
                  let el = semana.find(inv=>invernadero.id === inv[0].id);
                  if(el){
                      let [invernadero,semana, cajas,kilos,kg_m2,resumenEmpaque]= el;
                      let temp = {};
                      temp["semanal"] = cajas["semanal"] == 0?0: dosDecimales((  cajas["semanal"])  ) ;
                      temp["real"] = cajas["real"] == 0?0: dosDecimales((cajas["real"])  ) ;
                      temp["diario"] = cajas["diario"] == 0?0: dosDecimales( (cajas["diario"])  ) ;
                      semanal["data"].push({x:labels[index], y: temp["semanal"]});
                      diario["data"].push({x:labels[index], y: temp["real"]});
                      real["data"].push({x:labels[index], y: temp["diario"]})
                  }else{
                      
                      

                      semanal["data"].push({x:labels[index], y: 0});
                      diario["data"].push({x:labels[index], y: 0});
                      real["data"].push({x:labels[index], y: 0})
                  }
                  
          });
          data.push(semanal);
          data.push(diario);
          data.push(real);
      })
      
      
      
      
      return data;
    return []
  }
  
  
  const procesarPorcentajes = (reportes,invernaderos, cicloObj) => {
    
    
    
    
      let semanas = obtenerListaDeSemanas(cicloObj.semana_inicio, cicloObj.semana_cierre);
      let labels = semanas.map(el=>{
          return el.nombre;
      })
      let copiaOptions ={...options};
      let colors = [];
      let width = [];
      let dashArray = [];
          invernaderos.forEach(inv => {
              for (let index = 0; index < 3; index++) {
                  width.push(5*(index+1));
                  dashArray.push(3*index);
                  colors.push(stringToColor(inv.color));
              }
          })                
      
      
      
      
      
      
      copiaOptions["stroke"]["width"] = width;
      copiaOptions["stroke"]["dashArray"] = dashArray;
      copiaOptions["colors"] = colors;
      setOptions(copiaOptions);
      let data = [];
      invernaderos.forEach(invernadero => {
          let semanalReal = {idInvernadero: invernadero.id, name: ` Real vs Semanal ${invernadero.nombre}`, data:[], idAmbos:`rvs - ${invernadero.id}`};
          let semanalDiario = {idInvernadero: invernadero.id, name: ` Diario vs Semanal ${invernadero.nombre}`, data:[], idAmbos:`dvs - ${invernadero.id}`};
          let diarioReal = {idInvernadero: invernadero.id, name: ` Real vs Diario ${invernadero.nombre}`, data: [], idAmbos:`rvd - ${invernadero.id}`};
          reportes.forEach((semana,index) => {
                  let el = semana.find(inv=>invernadero.id === inv[0].id);
                  if(el){
                      let [invernadero,semana, cajas,kilos,kg_m2,resumenEmpaque]= el;
                      let temp = {};
                      temp["porcentaje_semanal_real"] = cajas["semanal"] == 0?0: dosDecimales((100 *  cajas["real"]/cajas["semanal"]) - 100 ) ;
                      temp["porcentaje_semanal_diario"] = cajas["semanal"] == 0?0: dosDecimales((100 *  cajas["diario"]/cajas["semanal"]) - 100 ) ;
                      temp["porcentaje_diario_real"] = cajas["diario"] == 0?0: dosDecimales( (100 *  cajas["real"]/cajas["diario"]) - 100 ) ;
                      semanalReal["data"].push({x:labels[index], y: temp["porcentaje_semanal_real"]});
                      semanalDiario["data"].push({x:labels[index], y: temp["porcentaje_semanal_diario"]});
                      diarioReal["data"].push({x:labels[index], y: temp["porcentaje_diario_real"]})
                  }else{
                      
                      

                      semanalReal["data"].push({x:labels[index], y: 0});
                      semanalDiario["data"].push({x:labels[index], y: 0});
                      diarioReal["data"].push({x:labels[index], y: 0})
                  }
                  
          });
          data.push(semanalReal);
          data.push(semanalDiario);
          data.push(diarioReal);
      })
      
      
      
      
      return data;
  }
  
  
      const obtenerData = () => {
          let filtrados = data.slice();
          if(props.filtros.length===0){
              
              
              return [];
          }

          
          let idsFiltros = props.filtros.map(el=>el.id);
          
          
          
              filtrados = filtrados.filter(el=>idsFiltros.includes(el.idAmbos));
          
          
          
          return filtrados;
      }
      const obtenerOptions = () => {
          let copia = {...optionsOrig};
          let colors = [];
          let dashArray = [];
          let width = [];
          props.filtros.forEach(el=>{
            width.push(2.2+ el.index*0.1)
            dashArray.push(Math.pow(el.index,3) * 1)
            colors.push(stringToColor(el.color))
          })
          // if(props.filtros.length === 0){                
          //       props.filtrosOrig.forEach(inv=>{
          //         for(let x = 0; x < 3; x++){
          //           width.push(2.2+(x*0.1));
          //           dashArray.push((x*x*x)*1);
          //           colors.push(stringToColor(inv.color));
          //         }
          //     })
  
          // }else{
          //     props.filtros.forEach(inv=>{
          //         for(let x = 0; x < 3; x++){
          //           width.push(2.2+(x*0.1));
          //           dashArray.push((x*x*x)*1);
          //           colors.push(stringToColor(inv.color));
          //         }
          //     })
          // }
          
          
          
          
          copia["stroke"]["width"] = width;
          copia["stroke"]["dashArray"] = dashArray;
          copia.colors = colors;
          return copia;
      }
      
      
      return <div style={{ height: 600 }} className="p-col-12 chart-container-filters">
        <Chart options = {obtenerOptions()} series = {obtenerData()} type = "area" height="100%"/>
        </div>
}
export default CosechaFiltroInvernaderoMultiCiclo;