import {obtenerDiaActual} from './fechas'
import * as firebase from "firebase/app";
import "firebase/firestore";
const count = 1
const obtenerSubmodulo = () =>{
    const submodulos = ['grafica']
    const pathname = window.location.hash
    const submodulo = submodulos.find(submodulo =>{
        if(pathname.includes(submodulo)){
            return submodulo
        }
    })
    
    return submodulo || pathname.split('/')[1]
}

const uploadLlamadasFirebase = async () =>{
/*    const count = 1
    const submodulo = obtenerSubmodulo()
    const moduleData = 
            submodulo ? 
            {
                [submodulo]:
                    {[window.location.hash]:{
                        count:firebase.firestore.FieldValue.increment(count)
                    }           
                    ,total:firebase.firestore.FieldValue.increment(count)
                }
                ,total:firebase.firestore.FieldValue.increment(count)
            }
            :
            {
                [window.location.hash]:
                    {
                        count:firebase.firestore.FieldValue.increment(count)
                    }
                ,total:firebase.firestore.FieldValue.increment(count)
            }
    await firebase.firestore().collection("statistics_llamadas").doc(`${obtenerDiaActual()}`)
    .set(
    {
        ...moduleData

    },{merge:true})
    */
}

export async function uploadStatistics(count = 1){
/*    const submodulo = obtenerSubmodulo()
    const moduleData = 
            submodulo ? 
            {
                [submodulo]:
                    {[window.location.hash]:{
                        count:firebase.firestore.FieldValue.increment(count)
                    }           
                    ,total:firebase.firestore.FieldValue.increment(count)
                }
                ,total:firebase.firestore.FieldValue.increment(count)
            }
            :
            {
                [window.location.hash]:
                    {
                        count:firebase.firestore.FieldValue.increment(count)
                    }
                ,total:firebase.firestore.FieldValue.increment(count)
            }
    await firebase.firestore().collection("statistics").doc(`${obtenerDiaActual()}`)
    .set(
    {
        ...moduleData

    },{merge:true})

    await uploadLlamadasFirebase();
    */
}
