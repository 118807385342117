import { faBoxes, faUsers } from '@fortawesome/free-solid-svg-icons';

// nombres módulos
export const MODULO_RH_NOMBRE = 'Empleados'
export const MODULO_EMBARQUES_NOMBRE = 'Embarques'

// detalles departamentos
export const DETALLES_DEPARTAMENTO_EMPAQUE = { icono: faBoxes, color: 'blue' }
export const DETALLES_DEPARTAMENTO_OTROS = { icono: faUsers, color: 'gray' }

// nombres departamentos
export const DEPARTAMENTO_INVERNADERO_NOMBRE = 'Invernadero'
export const DEPARTAMENTO_EMPAQUE_NOMBRE = 'Empaque'
export const DEPARTAMENTO_OTROS_NOMBRE = 'Otros'
export const UNIDADES_RADIACION = "J/cm²"
export const UNIDADES_PH = "pH"
export const UNIDADES_TEMPERATURA = "°C"
export const UNIDADES_CONDUCTIVIDAD = "EC"

// clases css tipos de ausencia
export const AUSENCIA_FALTA = 'falta'
export const AUSENCIA_PERMISO = 'permiso'
export const AUSENCIA_VACACIONES = 'vacaciones'
export const AUSENCIA_NINGUNA = 'ninguna'

// tipos de agentes aduanales
export const AA_TIPO_MEXICANO = 'mexicano'
export const AA_TIPO_AMERICANO = 'americano'

// puntos de carga
export const PUNTO_CARGA_ATONGO_NOMBRE = "Atongo"
export const PUNTO_CARGA_AGROPARK_NOMBRE = "Agropark"
export const PUNTO_CARGA_ATONGO_AGROPARK_NOMBRE = "AtongoAgropark"


// traducciones
export const CALENDAR_LOCALE_ES_PRIME_TRADUCTION = {
    firstDayOfWeek: 1,
    dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
    dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
    dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
    monthNames: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
    monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
    today: 'Hoy',
    clear: 'Limpiar',
    dateFormat: 'dd/mm/yy',
    weekHeader: 'Sm'
};
export const PALLETS_POR_CAMION = 30;

//Tipos archivos
export const tiposArchivos = {
    imagen: ["jpg", "jpeg", "png"],
    pdf: ["pdf"],
    excel: ["xlsx", "xls"]
}

export const periodicidadMantOpciones = ["Diario", "Semanal", "Quincenal", "Mensual", "Trimestral", "Semestral", "Anual"];
export const periodicidadMantOpcionesAdmin = ["Todos","Ninguna","Diario", "Semanal", "Quincenal", "Mensual", "Trimestral", "Semestral", "Anual"];