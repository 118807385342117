
import { faLessThan, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContenedorBotonesTop from 'components/common/ContenedorBotonesTop';
import ModalConfirmacion from 'components/common/ModalConfirmacion';
import RowBotonesTop from 'components/common/RowBotonesTop';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { ToggleButton } from 'primereact/togglebutton';
import React, { useState, useEffect } from 'react';
import { useGlobal } from 'reactn'
import { INVOICE_STATUSES } from 'service/constants/Rechazos.const';
import NAVEGACION from 'service/constants/Routes.const';
import { getGrupoPorIdDePresentacion } from 'service/helpers/Presentaciones.helpers.';
import { obtenerPresentaciones } from 'service/Presentaciones';
import { obtenerManifiestosCiclo } from 'service/QueriesManifiestos';
import { obtenerPresentacionesClientes } from 'service/QueriesPresentaciones';
import { guardarDatosInvoice } from 'service/Rechazos';
import { getInvoiceQUERY } from 'service/repositorios/Finanzas.queries';
import { DeepClone, dosDeciComas, dosDecimales, getMomentFormatted, getObjetoPorID, separadoComas } from 'util/functions';
import { obtenerClientes, obtenerClientesCiclo } from '../../service/Clientes';
import { obtenerInicioDia, obtenerNombreFecha } from '../../service/fechas';
import { obtenerBudgetGrupoPresentaciones, obtenerGruposPresentaciones } from '../../service/QueriesGruposPresentaciones';
import { actualizarItemManifiesto, obtenerResumenPreciosManifiesto, desplazarItemManifiesto, NO_APROBADO } from '../../service/ReportePrecios';
import ContenedorFiltro from '../common/ContenedorFiltro';
import ContenedorFiltroBusqueda from '../common/ContenedorFiltroBusqueda';
import ContenedorHeader from '../common/ContenedorHeader'
import ContenedorPrincipal from '../common/ContenedorPrincipal'
import ContenedorTabla from '../common/ContenedorTabla';
import SeccionFiltros from '../common/SeccionFiltros'
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import ModalFormulario from './ModalFormulario';

const fecha_dummy = obtenerInicioDia(1606923450);
const dummy = [
    { presentacion: "DM1", manifiesto: "M1", fecha_envio: fecha_dummy, cajas: 10, status: "Sin" },
    { presentacion: "DM2", manifiesto: "M2", fecha_envio: fecha_dummy, cajas: 20, status: "Sin" },
    { presentacion: "DM3", manifiesto: "M3", fecha_envio: fecha_dummy, cajas: 30, status: "No aprobado" },
    { presentacion: "DM4", manifiesto: "M4", fecha_envio: fecha_dummy, cajas: 30, status: "Aprobado" },
    { presentacion: "DM5", manifiesto: "M5", fecha_envio: fecha_dummy, cajas: 30, status: "Reproceso" },
    { presentacion: "DM6", manifiesto: "M6", fecha_envio: fecha_dummy, cajas: 30, status: "Rechazo" },
];
const OPTION_TODOS_DEFAULT = {
    id: "Todos",
    nombre: "Todos"
};
const AGREGAR_A_INVOICE = true;
const ConsultaAgregar = (props) => {
    const { invoiceId } = props.match.params;
    const [ciclo, setCiclo] = useGlobal("ciclo");
    const [usuario, setUsuario] = useGlobal("usuario");
    const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
    const [productosBases] = useGlobal("productosBases");
    const [medleysGeneral] = useGlobal("medleysGeneral");
    const [cargando, setCargando] = useGlobal("cargando");
    const [nombreModulo, setNombreModulo] = useGlobal("nombreModulo");
    const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector();
    const [mostrarFormulario, setMostrarFormulario] = useState(false);
    const [manifiestos, setManifiestos] = useState([]);
    const [manifiestosTodos, setManifiestosTodos] = useState([]);

    const [clientes, setClientes] = useState([]);
    const [edicionFormulario, setEdicionFormulario] = useGlobal("edicionVerificacionPrecios");
    const [triggerUpdate, setTriggerUpdate] = useState(false);
    const [mensajeError, setMensajeError] = useState("");
    const [modalConfirmacion, setModalConfirmacion] = useGlobal("modalConfirmacion");
    const [budgets, setBudgets] = useState([])
    const [invoice, setInvoice] = useState({ nombre: "", items:[] })

    const [gruposPresentacionesOrig, setGruposPresentacionesOrig] = useState([])
    const [gruposPresentacionesOpciones, setGruposPresentacionesOpciones] = useState([OPTION_TODOS_DEFAULT])

    const [presentacionesOrig, setPresentacionesOrig] = useState([])
    const [presentacionesOpciones, setPresentacionesOpciones] = useState([])

    const [invernaderos, setInvernaderos] = useState([])
    const [productos, setProductos] = useState([])
    const [cajaBusqueda, setCajaBusqueda] = useState("")

    const [filtroCliente, setFiltroCliente] = useState();
    const [filtroProducto, setFiltroProducto] = useState([])
    const [filtroInvernadero, setFiltroInvernadero] = useState([])
    const [filtroGrupo, setFiltroGrupo] = useState({})
    const [filtroPresentaciones, setFiltroPresentaciones] = useState([])

    const [manifiestosSeleccionadosState, setManifiestosSeleccionadosState] = useState({})

    const [manifiestosOrig, setManifiestosOrig] = useState([])

    const [sumaDeCajasSeleccionadas, setSumaDeCajasSeleccionadas] = useState(0)

    const [selectAllValue, setSelectAllValue] = useState(false)
    const [checkboxSelectAllDisable, setCheckboxSelectAllDisable] = useState(true)

    const [showModalConfirmacion, setShowModalConfirmacion] = useState(false)

    const { VALIDADO, CON_RECHAZO } = INVOICE_STATUSES

    useEffect(() => {
        setNombreModulo("Finanzas");
    }, [])
    useEffect(() => {
        if (invernaderosVirtuales) obtenerDatosIniciales();
    }, [invernaderosVirtuales]);
    useEffect(() => {
        if (ciclo && filtroCliente && week.nombre !== "Cargando") updateInfo();
    }, [ciclo, filtroCliente, week])
    useEffect(() => {
        const startTrigger = async () => {
            if (triggerUpdate) {
                await updateInfo();
                setTriggerUpdate(false);
                setCargando(false)
            }
        }
        startTrigger()
    }, [triggerUpdate])

    /* FILTROS EN CASCADA */
    //Filtra productos por y cuando hay invernaderos seleccionados
    useEffect(() => {
        if (!filtroCliente || !productosBases || filtroInvernadero.length === 0) {
            setFiltroProducto([])
            setProductos([])
            return
        }

        const productosFiltradosPorInvernadero = productosBases.filter(prod => {
            if (prod.MEDLEYS_GENERAL) return filtroInvernadero.some(inv => inv.mezcla)
            return filtroInvernadero.some(inv => inv.producto_ref === prod.id)
        })
        setFiltroProducto(productosFiltradosPorInvernadero || [])
        setProductos(productosFiltradosPorInvernadero || [])
    }, [filtroCliente, filtroInvernadero])
    //Filtra grupos de presentaciones por y cuando hay cambios en filtro cliente, filtro invernadero y filtro producto
    useEffect(() => {
        if (!filtroCliente || filtroInvernadero.length === 0 || filtroProducto.length === 0) {
            setGruposPresentacionesOpciones([OPTION_TODOS_DEFAULT])
            //setFiltroGrupo(OPTION_TODOS_DEFAULT)
            return
        }
        const grupoPresenFiltradasPorCliente = gruposPresentacionesOrig.filter(grupP => {
            const tieneProducto = filtroProducto.some(prod => prod.id === grupP.producto_ref)
            return grupP.cliente_ref === filtroCliente.id && tieneProducto
        })

        setGruposPresentacionesOpciones([OPTION_TODOS_DEFAULT, ...grupoPresenFiltradasPorCliente])
        //setFiltroGrupo(OPTION_TODOS_DEFAULT)
    }, [filtroCliente, filtroProducto])
    //Filtra presentaciones por y cuando hay cambios en filtro grupo de presentaciones
    useEffect(() => {
        if (presentacionesOrig.length === 0 || gruposPresentacionesOpciones.length === 0) {
            setPresentacionesOpciones([])
            setFiltroPresentaciones([])
            return
        }

        if (filtroGrupo.nombre === OPTION_TODOS_DEFAULT.nombre) {
            const presentacionesPorGruposOpciones = gruposPresentacionesOpciones.reduce((acc, grupP) => {
                if (!grupP.presentaciones_refs) return acc

                const presetacionesDeGrupo = grupP.presentaciones_refs.map(presRef => {
                    const presentacion = presentacionesOrig.find(pres => pres.id === presRef)
                    return presentacion
                })

                return [...acc, ...presetacionesDeGrupo]
            }, [])
            setPresentacionesOpciones([...presentacionesPorGruposOpciones])
            setFiltroPresentaciones([...presentacionesPorGruposOpciones])
            return
        }

        const presentacionesDelGrupoSeleccionado = filtroGrupo.presentaciones_refs.map(presRef => {
            const presentacion = presentacionesOrig.find(pres => pres.id === presRef)
            return presentacion
        })

        setPresentacionesOpciones([...presentacionesDelGrupoSeleccionado])
        setFiltroPresentaciones([...presentacionesDelGrupoSeleccionado])

    }, [filtroGrupo, gruposPresentacionesOpciones])

    useEffect(() => {
        setCargando(true)
    }, [week, filtroCliente])
    useEffect(() => {
        let productosIds = filtroProducto.map(el => el.id)
        let invernaderosIds = filtroInvernadero.map(el => el.id)
        const presentacionesIds = filtroPresentaciones.map(pre => pre.id)

        let filtrados = manifiestosOrig.filter(manifiesto => {

            const { producto_ref, producto, invernadero_ref, presentacion_ref } = manifiesto
            const tieneElProducto = productosIds.includes(producto_ref)
            const tieneYEsMezcla = productosIds.includes(medleysGeneral?.id) && producto.mezcla
            const tieneInvernadero = invernaderosIds.includes(invernadero_ref)
            const tienePresentacion = presentacionesIds.includes(presentacion_ref)

            return (tieneElProducto || tieneYEsMezcla) && tieneInvernadero && tienePresentacion
        })

        setManifiestos(filtrados)
    }, [filtroCliente, filtroInvernadero, filtroProducto, filtroPresentaciones, manifiestosOrig])

    useEffect(() => {
        const manifiestosSeleccionados = obtenerDeEstadoManifiestosSeleccionados()
        const cambiosDeManifiestosSeleccionados = {}
        const sumaDeCajas = manifiestosSeleccionados.reduce((acc, manifiesto) => {

            const [presentacion_ref, manifiestoId, invernadero_ref] = manifiesto.split("-")
            const manifiestoData = manifiestos.find(manf =>
                manf.presentacion_ref === presentacion_ref &&
                manf.manifiestoId === manifiestoId &&
                manf.invernadero_ref === invernadero_ref)
            const itemKey = `${presentacion_ref}-${manifiestoId}-${invernadero_ref}`
            if (!manifiestoData && manifiestosSeleccionadosState[itemKey]) {
                cambiosDeManifiestosSeleccionados[itemKey] = false
                return acc
            }
            if (!manifiestoData) return acc
            const { cajas_orden } = manifiestoData

            if (!cajas_orden) return acc
            acc = acc + parseFloat(cajas_orden)
            return acc
        }, 0)
        const hayManifiestosParaCambiar = Object.keys(cambiosDeManifiestosSeleccionados).length > 0
        if (hayManifiestosParaCambiar) {
            setManifiestosSeleccionadosState({
                ...manifiestosSeleccionadosState,
                ...cambiosDeManifiestosSeleccionados
            })
        }
        setSumaDeCajasSeleccionadas(sumaDeCajas)
    }, [manifiestosSeleccionadosState, manifiestos])
    useEffect(() => {
        if (filtroGrupo.nombre === OPTION_TODOS_DEFAULT.nombre) {
            setManifiestosSeleccionadosState([])
        }
    }, [filtroGrupo])
    useEffect(() => {
        if (manifiestos.length === 0) return setCheckboxSelectAllDisable(true)
        if (!filtroGrupo.nombre) return setCheckboxSelectAllDisable(true)
        if (filtroGrupo.nombre === OPTION_TODOS_DEFAULT.nombre) return setCheckboxSelectAllDisable(true)

        setCheckboxSelectAllDisable(false)
    }, [filtroGrupo, manifiestos])
    useEffect(() => {
        const estanTodosSeleccionados = checarSiTodosManifiestosEstanSeleccionados()
        setSelectAllValue(estanTodosSeleccionados)
    }, [manifiestosSeleccionadosState])
    const updateInfo = async (params) => {
        let resumen = await obtenerResumenPreciosManifiesto(ciclo, week.time, filtroCliente.id, AGREGAR_A_INVOICE)
        setManifiestosOrig(resumen);
        setCargando(false)
    }

    const obtenerDatosIniciales = async (params) => {
        setCargando(true)
        updateCicloWeekSelector(ciclo);
        let clientesPromise = obtenerClientes();
        let clientesCicloPromise = obtenerClientesCiclo(ciclo);
        const invoicePromise = getInvoiceQUERY(ciclo, invoiceId)
        let [clientes, clientesCiclo, invoiceResp, manifiestosResp,] =
            await Promise.all([clientesPromise, clientesCicloPromise, invoicePromise, obtenerManifiestosCiclo(ciclo)]);
        let idsClientes = clientesCiclo.map(el => el.cliente_ref);
        clientes = clientes.filter(el => idsClientes.includes((el.id)))
        const clienteInicial = getObjetoPorID(clientes, invoiceResp.cliente_ref)
        setClientes(clientes);
        setFiltroCliente(clienteInicial)
        setManifiestosTodos(manifiestosResp)
        let gruposPresentaciones = await obtenerGruposPresentaciones()
        const presentaciones = await obtenerPresentaciones()
        let promisesBudgets = gruposPresentaciones.map(el => obtenerBudgetGrupoPresentaciones(ciclo, el.id))
        let budgets = await Promise.all(promisesBudgets);


        let grupo = {}
        invoiceResp.items.forEach(item => {
            const manifiestoObj = getObjetoPorID(manifiestosResp, item.manifiestoId);
            const itemObj = manifiestoObj.items.find(el => el.itemID === item.itemId);
            grupo = manifiestoObj.items?.length ? getGrupoPorIdDePresentacion(gruposPresentaciones, itemObj.presentacion_ref) : {};
            //console.log("FFFF: ", { grupo, manifiestoObj })
        })
        setFiltroGrupo(grupo)

        setInvoice(invoiceResp);


        setGruposPresentacionesOrig(gruposPresentaciones);
        setGruposPresentacionesOpciones([OPTION_TODOS_DEFAULT])

        setFiltroInvernadero(invernaderosVirtuales)
        setInvernaderos(invernaderosVirtuales)

        setPresentacionesOrig(presentaciones)

        setBudgets(budgets);
        setCargando(false)
    }
    const templateFecha = (rowData, column) => {
        return <label >{getMomentFormatted(rowData.fecha_envio * 1000, "DD/MM/YYYY")}</label>;
    }
    const templatePrecio = (rowData, column) => {

        if (rowData["precio_libra_real"] && (rowData["status"] === "aprobado" || rowData.status === VALIDADO)) {
            return `$${dosDecimales(rowData["precio_libra_real"])}`;
        }
        else if (rowData["status"] === "reproceso") {
            return `$${rowData?.["precio_con_reproceso"] || 0}`;
        }
        else if (rowData["status"] === "rechazo") {
            return `$${rowData?.["precio_con_rechazo"] || 0}`;
        }
        return "N/A";
    }

    const activarVerificacion = (rowData) => {
        setEdicionFormulario(rowData);
        setMostrarFormulario(true);
    }

    const handleCheckbox = (e, presentacion_ref, manifiestoId, invernadero_ref) => {
        const itemName = `${presentacion_ref}-${manifiestoId}-${invernadero_ref}`
        setManifiestosSeleccionadosState({ ...manifiestosSeleccionadosState, [itemName]: e.checked })
    }
    const checkboxColumnTemplate = (rowData) => {//console.log("ROWL: ",rowData)
        const { presentacion_ref, manifiestoId, invernadero_ref, itemID } = rowData
        const valueItem = `${presentacion_ref}-${manifiestoId}-${invernadero_ref}`
        const checked = manifiestosSeleccionadosState?.[valueItem]
        const { status } = rowData
        const disabled = status === VALIDADO ||
            status === CON_RECHAZO ||
            /* status === "aprobado" || */
            status === "rechazo";
            const deshabilitarManifiesto = invoice?.items?.some(el => el.manifiestoId === manifiestoId && el.itemId === itemID)
        return (
            <div className={`p-flex p-justify-content-center ${deshabilitarManifiesto ? "disabled-check" : ""}`}>
                <Checkbox checked={disabled || checked || deshabilitarManifiesto} disabled={deshabilitarManifiesto} onChange={e => handleCheckbox(e, presentacion_ref, manifiestoId, invernadero_ref)} />
            </div>
        )
    }
    const obtenerColumnas = (params) => {
        let listaColumnas = ["checkboxes", "presentación", "manifiesto", "fecha_envio", "cajas_orden", "precio_libra_real", "invernadero"]
        let columns = listaColumnas.map(el => {
            if (el === "checkboxes") {
                return <Column style={{ width: "4em" }} key={el} field={el} header={null} body={checkboxColumnTemplate} />
            }
            if (el === "presentación") {
                return <Column key={el} field={"presentacion"} header={el} sortable />
            }
            if (el === "fecha_envio") {
                return <Column key={el} field={el} header={"Fecha envió"} body={templateFecha} sortable />
            }
            if (el === "cajas_orden") {
                return <Column key={el} field={el} header={"Cajas"} sortable body={templateCajas} />
            }
            if (el === "invernadero") {
                return <Column key={el} field={"nombre_invernadero"} header={"Invernadero"} sortable />
            }
            if (el === "precio_libra_real") {
                return <Column key={el} field={el} header={"Precio por libra real"} sortable body={templatePrecio} />
            }

            return <Column key={el} field={el} header={el} sortable />
        });
        return columns;
    }

    const templateCajas = (rowData) => {
        return <div>{dosDeciComas( rowData.cajas_orden || 0)}</div>
    }
    const hideFormulario = (params) => {
        setMostrarFormulario(false);
    }
    const handleButtonClick = (params) => {
        setMostrarFormulario(true);
    }
    const handleEnviarItemsSiguienteSemana = async (e) => {
        const manifiestosSeleccionados = obtenerDeEstadoManifiestosSeleccionados()
        const manifiestosSeleccionadosData = manifiestosSeleccionados.map(manifiesto => {
            const [presentacion_ref, manifiestoId, invernadero_ref] = manifiesto.split("-")
            const manifiestoData = manifiestos.find(manf =>
                manf.presentacion_ref === presentacion_ref &&
                manf.manifiestoId === manifiestoId &&
                manf.invernadero_ref === invernadero_ref)
            return manifiestoData
        })
        const manifiestosADesplazarPromises = manifiestosSeleccionadosData.map(manifiesto => {
            return desplazarItemManifiesto(ciclo, manifiesto);
        })
        try {
            setCargando(true)
            const result = await Promise.all(manifiestosADesplazarPromises)
            setTriggerUpdate(true)

        } catch (error) {
            setCargando(false)
            console.log("ERROR AL DESPLAZAR ITEMS: ", error)
        }
    }
    const guardarManifiesto = async (item, original) => {
        if (original.status === NO_APROBADO) {
            setMensajeError("NO SE PUEDE CAMBIAR STATUS");
            return;
        }
        setCargando(true);
        let ahora = Math.round(Date.now() / 1000);
        item.fecha_asignacion = ahora;
        try {
            let x = await actualizarItemManifiesto(ciclo, item, original, manifiestosOrig);
            setTriggerUpdate(true);
            hideFormulario();
        } catch (error) {
            console.log("ERROR GM: ", error);
        } finally {
            setCargando(false);
        }
    }
    const desplazar = async (item, original) => {
        try {
            let x = await desplazarItemManifiesto(ciclo, item, original);
            setTriggerUpdate(true);
            hideFormulario();

        } catch (error) {
            console.log("ERROR DESPLAZAR: ", error)
        }
    }

    const obtenerDeEstadoManifiestosSeleccionados = () => {
        const manifiestosSeleccionadosEntries = Object.entries(manifiestosSeleccionadosState).filter((manf) => manf[1])
        const manifistosKeys = manifiestosSeleccionadosEntries.map(manf => manf[0])
        return manifistosKeys
    }

    const FooterTotalesTemplate = () => {
        return (
            <div>
                <span>Suma de cajas seleccionadas: {separadoComas(sumaDeCajasSeleccionadas)}</span>
            </div>
        )
    }

    const checarSiTodosManifiestosEstanSeleccionados = () => {
        if (manifiestos.length === 0) return false
        let todosEstanSeleccionados = true
        manifiestos.forEach(manifiesto => {
            const { presentacion_ref, manifiestoId, invernadero_ref, status } = manifiesto
            const disabled = status === VALIDADO ||
                status === CON_RECHAZO ||
                /* status === "aprobado" || */
                status === "rechazo"
            if (disabled) return
            const itemName = `${presentacion_ref}-${manifiestoId}-${invernadero_ref}`
            if (!manifiestosSeleccionadosState[itemName]) todosEstanSeleccionados = false
        })
        return todosEstanSeleccionados
    }

    const checarSiNoHayManifiestosSeleccionados = () => {
        const manifiestosEnElEstado = Object.entries(manifiestosSeleccionadosState)
        if (manifiestosEnElEstado.length === 0) return false
        let hayManifiestosSelecionados = false
        manifiestosEnElEstado.forEach(manifiesto => {
            const [key, value] = manifiesto
            if (value) hayManifiestosSelecionados = true
        })
        return hayManifiestosSelecionados
    }

    const { RECHAZOS } = NAVEGACION

    const disableButtonValidarSeleccionados = !checarSiNoHayManifiestosSeleccionados()

    const modalConfirmacionData = {
        mensaje: "¿Esta segundo de transferir ítems seleccionados a la siguiente semana?",
        aceptar: () => {
            setShowModalConfirmacion(false)
            handleEnviarItemsSiguienteSemana()
        },
        cancelar: () => setShowModalConfirmacion(false)
    }

    const handleGuardar = async () => {
        const invoiceCopia = DeepClone(invoice);
        const promises = []
        //console.log("1 GUARDAR ", { invoiceCopia, invoice, manifiestos, manifiestosSeleccionadosState });
        Object.keys(manifiestosSeleccionadosState).forEach(itemNombre => {
            const maniId = itemNombre.split("-")[1]
            const manifiestoObj = getObjetoPorID(manifiestosTodos, maniId)
            const itemObj = manifiestoObj?.items?.find(el => `${el.presentacion_ref}-${manifiestoObj.id}-${el.invernadero_ref}` === itemNombre);

            //console.log("2 GUARDAR ", { maniId, itemNombre, manifiestoObj, itemObj });
            if (itemObj) {
                // promises.push(GuardarMani())
                invoiceCopia.items.push({ itemId: itemObj.itemID, manifiestoId: manifiestoObj.id, nombreManifiesto: manifiestoObj.manifiesto })
            }
        })

        const itemsAActualizar = { items: DeepClone(invoiceCopia.items) };
        //console.log("3 GUARDAR ", {ciclo, itemsAActualizar, invoiceId, invoiceCopia, invoice, manifiestosSeleccionadosState });
        await guardarDatosInvoice(ciclo, itemsAActualizar, invoiceId);
        props.history.goBack()
    }

    return (
        <ContenedorPrincipal>
            <ContenedorHeader col="p-col p-col-auto" titulo={`Items de manifiestos relacionados`} subtitulo={`Invoice #${invoice?.invoice}`}
             iconos="reporte-empaque-icon" atras={props.history.goBack} />

            <ContenedorBotonesTop>
                <RowBotonesTop>
                    <Button label="Guardar" onClick={handleGuardar} />
                </RowBotonesTop>
            </ContenedorBotonesTop>

            <SeccionFiltros>
                <ContenedorFiltro label="Cliente" for="clientes" col={4} sinFiltrarTexto>
                    <Dropdown disabled placeholder='Selecciona un cliente' optionLabel="nombre" dataKey="id" options={clientes} value={filtroCliente} />
                </ContenedorFiltro>
                <ContenedorFiltro label="Grupo de presentaciones" for="grupo_presentaciones" col={4} sinFiltrarTexto>
                    <Dropdown options={gruposPresentacionesOpciones} value={filtroGrupo} disabled placeholder='Selecciona grupos' optionLabel="nombre" dataKey="id" />
                </ContenedorFiltro>
            </SeccionFiltros>

            <ContenedorTabla>
                <DataTable value={manifiestos} globalFilter={cajaBusqueda} scrollable={true} footer={<FooterTotalesTemplate />}  >
                    {obtenerColumnas()}
                </DataTable>
            </ContenedorTabla>

            <ModalFormulario show={mostrarFormulario} onHide={hideFormulario} guardar={guardarManifiesto} mensajeError={mensajeError}
                desplazar={desplazar} budgets={budgets} gruposPresentaciones={gruposPresentacionesOrig} semana={week.time} />
            <ModalConfirmacion show={showModalConfirmacion} header="Confirmación de acción" data={modalConfirmacionData} closable={false} />
        </ContenedorPrincipal>)
}
export default ConsultaAgregar//310