import React from 'react'
import { useEffect, useGlobal, useState } from 'reactn'
import { obtenerProveedores, obtenerProveedoresHabilitados } from '../../service/Queries/Proveedores';
import ContenedorPrincipal from '../common/ContenedorPrincipal'
import ProveedorCard from './ProveedorCard'
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { obtenerReporteCompraExterna, obtenerReporteCompraExternaTodos } from "../../service/CompraExterna";
import { CORTE_PROV_MAYORITARIO_DOS_HECTAREAS } from '../../constants';
import icono from '../../images/icons/external-icon.svg';
import ContenedorHeader from '../common/ContenedorHeader';
import SeccionFiltros from '../common/SeccionFiltros';

const CardsCompraExterna = (props) => {
    const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector();
    const [proveedores, setProveedores] = useState([]);
    const [cicloId] = useGlobal("ciclo");
    const [, setCargando] = useGlobal("cargando");
    const [tipoGrupoProveedor] = useGlobal("tipoGrupoProveedor");
    const [productos, setProductos] = useState([]);
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [productosYVariedades] = useGlobal("productosYVariedades")
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");

    useEffect(() => {
        setNombreModulo("Compra externa");
        setHabilitadoSelectorCiclo(true)
    }, [])
    useEffect(() => {
        if (productosYVariedades && week.nombre !== "Cargando") obtenerDatosIniciales();
    }, [productosYVariedades, week])

    const obtenerDatosIniciales = async (params) => {
        setCargando(true)
        let proveedores = await obtenerProveedores(cicloId);
        //filtrar proveedores por tipo de grupo
        if (tipoGrupoProveedor === "superior" || !tipoGrupoProveedor)
            proveedores = proveedores.filter(p => p.area_invernadero > CORTE_PROV_MAYORITARIO_DOS_HECTAREAS);
        else
            proveedores = proveedores.filter(p => p.area_invernadero <= CORTE_PROV_MAYORITARIO_DOS_HECTAREAS);
        let reporte = await obtenerReporteCompraExternaTodos(cicloId, week.time, proveedores)
        let productos = productosYVariedades

        //por cada proveedor, buscar elemento correspondiente en reporte y agregarlo al objeto
        proveedores.forEach(proveedor => {
            let producto = reporte.find(r => r[0].id == proveedor.id);
            if (producto) proveedor.reporte = producto;
        });
        proveedores = proveedores.filter(prov => {

            const hayDatos = !!prov.reporte?.[2]?.["semanal"] || !!prov.reporte?.[3]?.["semanal"] || !!prov.reporte?.[2]?.["real"] || !!prov.reporte?.[3]?.["real"] || !!prov.reporte?.[2]?.["diario"]
          /*  if (hayDatos) {
                console.log("HAY: ", { nombre: prov.nombre, reporte: prov.reporte });
            }
            else {
                console.log("NO HAY: ", { nombre: prov.nombre, reporte: prov.reporte });
            }*/
            return hayDatos || prov.habilitado;
        })
        setProductos(productos);
        setProveedores(proveedores);
        setCargando(false)
    }

    return (
        <ContenedorPrincipal>
            <ContenedorHeader col="p-col-auto p-col" titulo="Proveedores de compra externa" iconos="external-icon bor-main-blue bg-main-blue"
                invernaderoNombre={<img width="60" alt="icono" src={icono} />} atras={props.history.goBack} />

            <SeccionFiltros>
                <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
            </SeccionFiltros>

            {proveedores?.length > 0
                ? <div className="p-col-12">
                    <div className="p-grid">
                        {proveedores.map(proveedor =>
                            <ProveedorCard key={proveedor.id} proveedor={proveedor} productos={productos} reporte={proveedor.reporte} />)}
                    </div>
                </div>
                : <p className="p-col-12 section-title">No hay registros de cosecha para esta semana</p>}
        </ContenedorPrincipal>);
}
export default CardsCompraExterna;//88