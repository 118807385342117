import React, { useGlobal } from 'reactn';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { withRouter } from "react-router-dom";
import { guardarOrdenAplicacion } from '../../../service/biocontrol';

const OrdenAplicacionEstatusModal = (props) => {
    const [, setCargando] = useGlobal("cargando")

    const finalizarOrden = async () => {
        setCargando(true)
        props.hide()
        let datos = {
            estatus: "Finalizado",
            firmaGrowerNombre: props.usuario.displayName,
            firmaGrowerRef: props.usuario.uid,
            firmaGrowerRol: props.usuario.rol,
            firmaGrowerURL: props.usuario.firma || ""
        }
        await guardarOrdenAplicacion(props.ciclo, datos, props.aplicacionID)
        setCargando(false)
        props.history.goBack()
    }

    const footer = <div>
        <Button label="Cancelar" onClick={props.hide} />
        <Button label="Finalizar" onClick={finalizarOrden} />
    </div>
    return (
        <Dialog header={"Finalizar orden"} visible={props.visible} style={{ width: '50vw' }} onHide={props.hide} footer={footer}>
            <div className="p-grid ">
                <div className="form-group p-col-12">
                    <label htmlFor="status-nuevo">Estas seguro que quieres cambiar el estatus a 'finalizado'? </label>
                </div>
                {props.usuario.firma && <label>Tu firma</label>}
                {props.usuario.firma && <img width={300} src={props.usuario.firma} />}
            </div>
        </Dialog >
    );
}
export default withRouter(OrdenAplicacionEstatusModal);