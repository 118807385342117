import React, { useState } from 'reactn';
import { NavLink } from 'react-router-dom'
import AppProfile from './AppProfile';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LogoDev from './images/logo-sisu.png';
import { estamosEnProduccion, estamosEnTest } from './constants';

const renderLinkContent = (item) =>
    <React.Fragment>
        {item.icon && <FontAwesomeIcon icon={item.icon} />}
        <span>{item.label}</span>
        {item.items && <i className="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>}
        {item.badge && <span className="menuitem-badge">{item.badge}</span>}
    </React.Fragment>;

const renderLink = (item, i, onMenuItemClick) => item.to ?
    <NavLink activeClassName="active-route" to={item.to} onClick={(e) => { onMenuItemClick(e, item, i) }} exact target={item.target}>
        {renderLinkContent(item)}
    </NavLink>
    :
    <a href={item.url} onClick={(e) => { onMenuItemClick(e, item, i) }} target={item.target}>
        {renderLinkContent(item)}
    </a>;

const AppSubmenu = (props) => {
    const [activeIndex, setActiveIndex] = useState(null);

    const onMenuItemClickSub = (event, item, index) => {
        //avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return true;
        }
        //execute command
        if (item.command) item.command({ originalEvent: event, item: item });
        if (props.onMenuItemClick) props.onMenuItemClick({ originalEvent: event, item })

        setActiveIndex(index === activeIndex ? null : index);
    }

    return (
        <ul className={props.className}>
            {props.items?.map((item, i) =>
                <li className={styleClass(item, activeIndex, i)} key={i}>
                    {item.items && props.root === true && <div className='arrow'></div>}
                    {renderLink(item, i, onMenuItemClickSub)}
                    <AppSubmenu items={item.items} onMenuItemClick={props.onMenuItemClickSub} />
                </li>)}
        </ul>)
}
const styleClass = (item, activeIndex, i) => classNames(item.badgeStyleClass, item.customStyleClass, { 'active-menuitem': (activeIndex === i && !item.to) }) || [];

const AppMenu = (props) => {
    return (
        <div className={props.sidebarClassName} onClick={props.onSidebarClick}>
            <div className="layout-menu-container">
                <AppProfile sidebarClassName={props.sidebarClassName} onSidebarClick={props.onSidebarClick} />
                <AppSubmenu items={props.model} className="layout-menu" onMenuItemClick={props.onMenuItemClick} root={true} />
                {!estamosEnProduccion &&
                    <div className="sisu-credit" >
                        <img alt="Sisu Technologies" className="sisu-logo-bottom" src={LogoDev} />
                        <p className="sisu-logo-bottom-text">Profile: {estamosEnTest ? "TEST" : "DEV"}</p>
                    </div>}
            </div>
        </div>);
}
export default AppMenu;