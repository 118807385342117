import React, { } from 'react';
import { useEffect, useGlobal } from 'reactn';
import { ToggleButton } from 'primereact/togglebutton';
import { SelectButton } from 'primereact/selectbutton';
import { Button } from 'primereact/button';
import { useState } from 'reactn';
import SeccionFormulario from '../../common/SeccionFormulario';
import ContenedorInput from '../../common/ContenedorInput';
import SeccionBotones from '../../common/SeccionBotones';
import { obtenerNombreSemana } from '../../../service/fechas';
import { PONDERADO_MINIMO_CALIDAD } from '../../../service/NominaEmpaque';
import { Dialog } from 'primereact/dialog';
const opcionesFalta = [{ label: "Vacaciones", value: "vacaciones" }, { label: "Permiso", value: "permiso" }, { label: "Falta", value: "falta" }]
const inicial = {
    cumple_inocuidad: true, cumple_calidad: true,
    dias: { lunes: "asistencia", martes: "asistencia", miercoles: "asistencia", jueves: "asistencia", viernes: "asistencia", sabado: "asistencia" }
}

export const ModalAusencias = (props) => {
    const [state, setState] = useState({ ...inicial });
    const [nombre, setNombre] = useState("");
    const [empleadoId, setEmpleadoId] = useState("")
    const [edicionIncidencias, setEdicionIncidencias] = useGlobal("edicionIncidencias");
    const [week] = useGlobal("week");

    useEffect(() => {
        if (edicionIncidencias) {
            let ponderado = edicionIncidencias["bonos"]["ponderado"]
            setNombre(edicionIncidencias["nombreCompleto"]);
            setEmpleadoId(edicionIncidencias["id"]);
            if (!edicionIncidencias["incidencias"]["cumple_calidad"]) { edicionIncidencias["incidencias"]["cumple_calidad"] = ponderado > PONDERADO_MINIMO_CALIDAD; }
            setState({ ...edicionIncidencias["incidencias"] })
        }
    }, edicionIncidencias)

    const handleInputChange = (value, field) => {
        let copia = { ...state };
        copia[field] = value;
        setState(copia);
    }
    const handleDiaChange = (valor, dia) => {
        let copia = { ...state };
        if (!valor) { copia["dias"][dia] = "asistencia" }
        else { copia["dias"][dia] = valor; }
        setState(copia);
    }

    return (
        <Dialog header="Editar desempeño y ausencias de empleado" className="modal-50" visible={props.visible} onHide={props.onHide} modal={true}>
            <div>
                <div>{nombre}</div>
                <div>Semana {obtenerNombreSemana(week.time)} </div>
                <SeccionFormulario titulo="Desempeño">
                    <ContenedorInput col="12" md="6" sm="6">
                        <ToggleButton checked={state.cumple_inocuidad} onChange={(e) => { handleInputChange(e.value, "cumple_inocuidad") }}
                            onLabel="Cumple con inocuidad" offLabel="No cumple con inocuidad" />
                    </ContenedorInput>
                    <ContenedorInput col="12" md="6" sm="6">
                        <ToggleButton checked={state.cumple_calidad} onChange={(e) => { handleInputChange(e.value, "cumple_calidad") }}
                            onLabel="Cumple con calidad" offLabel="No cumple con calidad" />
                    </ContenedorInput>
                </SeccionFormulario>

                <SeccionFormulario titulo="Ausencias de la semana">
                    <ContenedorInput label="Lunes" col="" md="" sm="">
                        <SelectButton options={opcionesFalta} value={state["dias"]["lunes"]} onChange={e => handleDiaChange(e.value, "lunes")} />
                    </ContenedorInput>
                    <ContenedorInput label="Martes" col="" md="" sm="">
                        <SelectButton options={opcionesFalta} value={state["dias"]["martes"]} onChange={e => handleDiaChange(e.value, "martes")} />
                    </ContenedorInput>
                    <ContenedorInput label="Miércoles" col="" md="" sm="">
                        <SelectButton options={opcionesFalta} value={state["dias"]["miercoles"]} onChange={e => handleDiaChange(e.value, "miercoles")} />
                    </ContenedorInput>
                    <ContenedorInput label="Jueves" col="" md="" sm="">
                        <SelectButton options={opcionesFalta} value={state["dias"]["jueves"]} onChange={e => handleDiaChange(e.value, "jueves")} />
                    </ContenedorInput>
                    <ContenedorInput label="Viernes" col="" md="" sm="">
                        <SelectButton options={opcionesFalta} value={state["dias"]["viernes"]} onChange={e => handleDiaChange(e.value, "viernes")} />
                    </ContenedorInput>
                    <ContenedorInput label="Sábado" col="" md="" sm="">
                        <SelectButton options={opcionesFalta} value={state["dias"]["sabado"]} onChange={e => handleDiaChange(e.value, "sabado")} />
                    </ContenedorInput>
                </SeccionFormulario>

                <SeccionBotones>
                    <Button label="Cancelar" onClick={props.cancelar} />
                    <Button label="Guardar" onClick={() => props.guardar(empleadoId, { ...state })} />
                </SeccionBotones>
            </div>
        </Dialog>)
}
export default ModalAusencias;