import { tiposArchivos } from './constants';
import { getGlobal } from 'reactn';
import moment from "moment";
import 'moment/locale/es';
import { MEDLEYS, MEDLEYS_VIEJO } from '../service/constants/ProductosYVariedades.const';
import { AGROPARK, LORT } from '../constants';
import { getInicioDia, getNombreDiaSemana } from "../service/fechas"
import { ES_FLOAT, ES_INT } from 'service/constants/Calidad.const';
moment.locale("es");

/**
 * Convierte un objeto Date en su representación Unix como cadena de texto.
 * 
 * @param {Date} date fecha a convertir
 * @return {String} fecha en formato unix
 */
export function dateToUnixTime(date) {
    return moment(date).utcOffset("-0600").unix().toString()
}

/**
 * Convierte un objeto Date en su representación Unix como número.
 * 
 * @param {Date} date fecha a convertir
 * @return {Number} fecha en formato unix
 */
export function dateToUnixTimeAsNumber(date) {
    return moment(date).utcOffset("-0600").unix()
}
//Convierte un objeto Date en su representación Unix como número de 13 digitos.
export function dateToUnixTimeAsNumber13(date) {
    return (Math.floor(date.getTime()))
}
export const getTiempoValor = (tiempo) => {
    if (!tiempo || tiempo === 1) return getCalendarDate(getInicioDia(Date.now()) * 1000)
    else return getCalendarDate(tiempo)
}
/**
 * Convierte la representación Unix como cadena de texto de una fecha en un objeto Date.
 * 
 * @param {String} unixEpochString fecha en formato unix
 * @return {Date} objeto Date de la fecha en formato unix introducida
 */
export function unixTimeToDate(unixEpochString) {
    return new Date(Number(unixEpochString) * 1000)
}
//Convierte la representación Unix (con 13 digitos) como cadena de texto de una fecha en un objeto Date.
export function unixTimeToDate13(unixEpochString) {
    return new Date(Number(unixEpochString))
}

export function dosDecimales(numero) { return Math.round(numero * 100) / 100; }
export function tresDecimales(numero) { return Math.round(numero * 1000) / 1000; }
export const cuatroDecimales = (numero) => { return Math.round(numero * 10000) / 10000; }
export const cincoDecimales = (numero) => { return Math.round(numero * 100000) / 100000; }

export function separadoComas(numero) {
    return new Intl.NumberFormat('en-US', { style: 'decimal', useGrouping: true }).format(numero)
}
export function separadoComasMax(numero) {
    return new Intl.NumberFormat('en-US', { style: 'decimal',maximumFractionDigits: 6,  useGrouping: true }).format(numero)
}
export function currency(numero) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', useGrouping: true, maximumFractionDigits: 2 }).format(numero)
}
export const separadoComasDinero = (numero) =>
    new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true }).format(numero);

export const dosDecimalesMin = (numero) => decimalesMin(numero, 2)
export const tresDecimalesMin = (numero) => decimalesMin(numero, 3)
export const quatroDecimalesMin = (numero) => decimalesMin(numero, 4)
export const decimalesMin = (numero, numDigits) => numero % 1 > 0 //Si el numero es un Int, no va regresar decimales
    ? new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: numDigits, maximumFractionDigits: numDigits, useGrouping: true }).format(numero)
    : dosDecimales(numero);

///////////////Decorators formateando numeros///////////////
export const formatoDinero = (numero) => separadoComasDinero(numero);
export const dosDeciComas = (numero = "-") => isNaN(numero) ? "-" : separadoComas(dosDecimales(numero));
export const tresDeciComas = (numero = "-") => isNaN(numero) ? "-" : separadoComas(tresDecimales(numero));
export const cuatroDeciComas = (numero = "-") => isNaN(numero) ? "-" : separadoComasMax(cuatroDecimales(numero));
export const cincoDeciComas = (numero = "-") => isNaN(numero) ? "-" : separadoComasMax(cincoDecimales(numero));
////////END Decorators formateando numeros////////


export const unixToString = (unixEpochString) => {
    if (typeof unixEpochString === "object") { unixEpochString = dateToUnixTime(unixEpochString); }
    return new Intl.DateTimeFormat('es-MX').format(parseInt(unixEpochString) * 1000)
}
export const colorAleatorio = (params) => {
    const NUM_COLORS = parseInt("FFFFFF", 16);
    return Math.round(NUM_COLORS * Math.random()).toString(16)
}

export const stringToColor = (colorString) => {
    const stringColorMap = {
        "green-dark": "#44A026",
        "green": "#62D60A",
        "blue-dark": "#047bda",
        "blue": "#45abec",
        "purple-dark": "#7C08C4",
        "purple": "#BA3FEB",
        "pink-dark": "#D60074",
        "pink": "#FC66B7",
        "red": "#FF3C3C",
        "red-dark": "#DB000E",
        "orange-dark": "#E67E00",
        "orange": "#FCAD5A",
        "yellow": "#FDE450",
        "yellow-dark": "#EBCA12",
        "gray-dark": "#94A5A5",
        "gray": "#BAC3C3",
        "main-blue": "#003E66"
    }
    return stringColorMap[colorString];
}

export const obtenerRangoColores = (length) => {


    const NUM_COLORS = parseInt("FF", 16);


    let separation = Math.floor(NUM_COLORS / length);

    let result = [];
    for (let index = 0; index < NUM_COLORS; index += separation) {


        const color = index.toString(16) + (NUM_COLORS - index).toString(16) + (index + separation).toString(16);
        result.push(color);
    }

    return result;
}
export const paletaColores = [
    "#44A026",
    "#62D60A",
    "#047bda",
    "#45abec",
    "#7C08C4",
    "#BA3FEB",
    "#FC66B7",
    "#D60074",
    "#FF3C3C",
    "#DB000E",
    "#E67E00",
    "#FCAD5A",
    "#FDE450",
    "#EBCA12",
    "#94A5A5",
    "#BAC3C3"
]
export const PALETA_COLORES_CLAROS_INVS = [
    "#62D60A",
    "#45abec",
    "#ebca12",
    "#FC66B7",
    "#FF3C3C",
    "#FCAD5A",
    "#BA3FEB",
    "#BAC3C3"
]
export const paletaColoresExpandida = [
    "#44A026",
    "#62D60A",
    "#047bda",
    "#45abec",
    "#7C08C4",
    "#BA3FEB",
    "#FC66B7",
    "#D60074",
    "#FF3C3C",
    "#DB000E",
    "#E67E00",
    "#FCAD5A",
    "#FDE450",
    "#EBCA12",
    "#94A5A5",
    "#BAC3C3"
]
export const paletaEmpaque = ["#45abec", "#FF3C3C", "#62D60A", "#BAC3C3"]
export const paletaSemaforo = ["#ff4040", "#62d60a", "#e67e00"]
export const chunkArray = (array, chunkSize) => {
    if (chunkSize === 0) {
        return [];
    }
    var i, j, tempArray;
    let result = [];
    for (i = 0, j = array.length; i < j; i += chunkSize) {
        tempArray = array.slice(i, i + chunkSize);
        result.push(tempArray);
    }
    return result;
}

export const getCalendarDate = (value) => value ? unixTimeToDate13(value) : ""

export const DeepClone = (obj) => {
    if (obj === null || typeof (obj) !== 'object') return obj === null ? null : obj;
    let newObj = {}

    if (Array.isArray(obj))
        newObj = [...obj];
    else if (typeof obj === "object")
        newObj = { ...obj };

    for (let prop in obj) {
        if (prop === "archivo")
            newObj[prop] = obj[prop]
        else if (typeof obj[prop] === "object" || typeof obj[prop] === "function")
            newObj[prop] = DeepClone(obj[prop]);
    }

    return newObj;
};
export const DeepCloneVista = (obj) => {
    //if (obj === null || obj === undefined) console.log("xxx: " + obj)
    if (obj === null || obj === undefined || typeof (obj) !== 'object') return obj === null || obj === undefined ? null : obj;
    let newObj = {}

    if (Array.isArray(obj))
        newObj = [...obj];
    else if (typeof obj === "object")
        newObj = { ...obj };

    for (let prop in obj) {
        if (obj[prop] === null || obj[prop] === undefined) {
            //console.log("__ " + prop + " -- " + obj[prop] + " XXXXX ")
            newObj[prop] = null
        }
        else if (prop === "archivo")
            newObj[prop] = obj[prop]
        else if (typeof obj[prop] === "object" || typeof obj[prop] === "function")
            newObj[prop] = DeepCloneVista(obj[prop]);
    }

    return newObj;
};

export const getArchivoTipo = (file) => {
    let fileExtension = file.name.split('.')[file.name.split('.').length - 1].toLowerCase()

    for (const tipo in tiposArchivos) {
        if (tiposArchivos[tipo].includes(fileExtension))
            return tipo
    }
}
export const getObjetoClonePorID = (objetosArray, objetoID) => DeepClone(objetosArray?.find(el => el.id === objetoID) || {});

export const getObjetoPorID = (objetosArray, objetoID) => objetosArray?.find(el => el.id === objetoID) || {};
export const getNombrePorID = (objetosArray, objetoID, textoSin) => getObjetoPorID(objetosArray, objetoID).nombre || textoSin || "NO EXISTE";
export const getObjetoPorLlave = (objetosArray, llave, valorAComparar) => objetosArray?.find(el => el?.[llave] === valorAComparar) || {};

export const getNombreUsuarioPorID = (usuarios, usuarioID) => getObjetoPorID(usuarios || [], usuarioID).displayName || "NO EXISTE";

//Opiniones?
export const getNombreClienteCorto = (objetosArray, objetoID, textoSin) => {
    const nombreCliente = getNombrePorID(objetosArray, objetoID, textoSin);
    const palabras = nombreCliente.split(" ");
    const iniciales = palabras.map(palabra => palabra.charAt(0));
    return iniciales.join("")
}

export const getMomentFormatted = (timestamp, format) => timestamp ? moment(timestamp).utcOffset("-0600").format(format) : "N/A"

export const getProductoYVariedadString = (invernadero, separadorTexto = " - ") => {
    const { productosYVariedadesTodos } = getGlobal();
    if (!productosYVariedadesTodos || !invernadero) { return ""; }
    else if (invernadero.MEDLEYS_VIEJO) { return MEDLEYS_VIEJO; }

    const producto = getObjetoPorID(productosYVariedadesTodos, invernadero.producto_ref);
    if (!producto.nombre) { return ""; }
    else if (invernadero.es_externo || invernadero.mezcla) { return producto.nombre };

    const variedad = getObjetoPorID(producto.variedades || [], invernadero.variedad_ref);
    const partesDelNombre = [producto.nombre, variedad.nombre];
    return partesDelNombre.join(separadorTexto);
}
export const getProductoYVariedadStringDiagonal = (invernadero) => getProductoYVariedadString(invernadero, "/");

export const sumarNumerosArrayPorCampo = (array, value) => {
    if (!array || array.length === 0) { return 0; }

    let values = array.map(el => parseFloat(el[value]))
    return values.length === 0 ? 0 : values.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr || 0), 0)
}
export const formatearMinutosAHoras = (minutos) => {
    let horas = Math.floor(minutos / 60)
    let minutosRestantes = minutos % 60
    return `${horas}h ${minutosRestantes}m`
}
export const formatearMinutosAHorasNEW = (minutos) => {
    let horas = Math.floor(minutos / 60)
    let minutosRestantes = minutos % 60
    return `${horas}h ${minutosRestantes}m`
}
export const porcentaje = (numero, total) => {
    if (total <= 0 || numero == 0) return 0;
    let proporcion = numero / total
    let porcentaje = 100 * proporcion
    return dosDecimalesMin(porcentaje)
}

export const naturalCompareSort = (arrayToSort, propertyName) => {
    return arrayToSort.sort(function (a, b) {
        return a[propertyName].localeCompare(b[propertyName], undefined, {
            numeric: true,
            sensitivity: 'base'
        });
    })
}

export const regexHoraYMinutoFormato = (input, tiempoTomado) => {
    const regex = new RegExp("^[0-9][0-9]?:?([0-5][0-9]?)?$");
    if (regex.test(input)) {
        if (input.length === 3 && tiempoTomado?.length === 2 && !input.includes(":")) {
            return `${input[0]}${input[1]}:${input[2]}`;
        } else if (input.length === 3 && !input.includes(":")) {
            return tiempoTomado
        } else if (input?.split(":")?.length === 2 && input?.split(":")?.[1]?.[0] < 6) {
            return input
        } else if (input.length === 2 && tiempoTomado?.length === 3) {
            return input
        } else if (input.length === 3 && input[1] == ":") {
            return input
        } else { return input }
    } else if (!input) { return ""; }
    else return tiempoTomado;
}
export const regexHoraYMinutoFormatoFullCheck = (input) => {
    const regex = new RegExp("^([0-9]?[0-9]):([0-5][0-9])$");
    return regex.test(input)
}

export const getActividadesOpcionesConIndices = (actividades) => {
    if (actividades?.length > 0) {
        const filtered = actividades.filter(a => !a.sinMostrar)
        const opcionesActs = filtered.map(el => ({ ...el, nombre: `${el.indice} - ${el.nombre}` }))
        return opcionesActs
    } else return []
}

export const noHayVariosPuntos = (input) => {
    let puntos = 0, string = `${input}`;
    for (let x = 0; x < string.length; x++) {
        if (string.charAt(x) === ".") { puntos++ }
    }
    return puntos < 2;
}

export const padTo2Digits = (num) => { return String(num).padStart(2, '0'); }
export const makeCssClass = (nombre) => nombre ? nombre.toLowerCase().replace(/ /g, '-').replace(/[\s.,:()\/]/g, '') : "";

//Ordenar arreglas
export const ordenarArreglaDeObjetosPorLlaveFloat = (array = [], llave = "", ascendente = true) => {
    return array.sort((a, b) => {
        const llaveA = parseFloat(a[llave]);
        const llaveB = parseFloat(b[llave])
        if (ascendente) { return llaveA > llaveB ? 1 : -1; }
        else { return llaveA < llaveB ? 1 : -1; }
    });
}
export const ordenarArreglaDeObjetosPorLlave = (array = [], llave = "", ascendente = true, toUpperCase = false) => {
    return array.sort((a, b) => {
        const llaveA = toUpperCase ? a[llave].toUpperCase() : a[llave];
        const llaveB = toUpperCase ? b[llave].toUpperCase() : b[llave];
        if (ascendente) { return llaveA > llaveB ? 1 : -1; }
        else { return llaveA < llaveB ? 1 : -1; }
    });
}
export const ordenarArreglaDeObjetosPor2Llaves = (array = [], llave1 = "", llave2 = "", ascendente, toUpperCase = false) => {
    return array.sort((a, b) => {
        const llaveA1 = toUpperCase ? a[llave1].toUpperCase() : a[llave1], llaveA2 = toUpperCase ? a[llave2].toUpperCase() : a[llave2];
        const llaveB1 = toUpperCase ? b[llave1].toUpperCase() : b[llave1], llaveB2 = toUpperCase ? b[llave2].toUpperCase() : b[llave2];
        if (ascendente) {
            if (llaveA1 == llaveB1) { return llaveA2 > llaveB2 ? 1 : -1; }
            else return llaveA1 > llaveB1 ? 1 : -1;
        }
        else {
            if (llaveA1 == llaveB1) { return llaveA2 < llaveB2 ? 1 : -1; }
            else return llaveA1 < llaveB1 ? 1 : -1;
        }
    });
}
export const ordenarObjetosPorNombre = (array, ascendente) => ordenarArreglaDeObjetosPorLlave(array, "nombre", ascendente, true);

export const getListaNombresDeObjetos = (objetos = []) => {
    const nombres = objetos.map(el => el.nombre);
    return nombres;
}

export const empresaIsHTF = (empresas, empresaID) => getNombrePorID(empresas, empresaID) === "HTF"
export const locacionIsAtongo = (locaciones, locacionID) => getNombrePorID(locaciones, locacionID) === "Atongo"
export const locacionIsAgropark = (locaciones, locacionID) => getNombrePorID(locaciones, locacionID) === "Agropark"
export const getObjetoLocacionAgrapark = (locaciones = []) => locaciones?.find(locacion => locacionIsAgropark(locaciones, locacion.id));
export const getLocacionAgraparkID = (locaciones) => getObjetoLocacionAgrapark(locaciones)?.id;

export const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const objetosSonIgual = (objeto1, objeto2) => {
    return JSON.stringify(objeto1) === JSON.stringify(objeto2)
}

export const getColores = (invernaderosVirtuales) => invernaderosVirtuales?.map(el => stringToColor(el.color)) || [];

export const selectedItemNombresMultiselect = (item, itemValores = [], itemOpciones = [],) => {
    if (!itemValores?.length || !itemOpciones?.length || !item?.nombre) { return null; }
    const ponerComa = item?.nombre !== itemValores?.[itemValores.length - 1]?.nombre;  // itemValores.length > 1 && itemValores.length < itemOpciones.length;
    return item?.nombre + (ponerComa ? ", " : "");
}

export const selectedItemPresentacionesMultiselect = (item, itemValores = [], itemOpciones = []) => {
    if (!itemValores?.length || !itemOpciones?.length || !item?.presentacion) { return null; }
    const ponerComa = item?.presentacion !== itemValores?.[itemValores.length - 1]?.presentacion;
    return item?.presentacion + (ponerComa ? ", " : "");
}

export const checarDiaEsDomingo = (timestampMilisegundos) => {
    if (!timestampMilisegundos) { return false; }
    const diaNombre = getNombreDiaSemana(timestampMilisegundos);
    return diaNombre === "domingo"
}
export const checarHoyEsDomingo = () => checarDiaEsDomingo(Date.now());

export const checkIfStringIsNumber = (string) => !isNaN(Number(string));

export const isThereGreenhouseInTheProducer = (invVirtualId, invernaderosFisicos = [], invernadersoVirtuales = [], empresas = []) => {
    const invernaderoVirtual = invernadersoVirtuales.find(invVirtual => invVirtual.id === invVirtualId);

    if (invernaderoVirtual?.es_externo) return empresas?.find(empresa => empresa.nombre === LORT);

    const invernaderoFisico = getObjetoPorID(invernaderosFisicos, invernaderoVirtual.invernadero_fisico_ref);
    const empresa = empresas?.find(empresa => empresa.id === invernaderoFisico.empresa_ref);
    return empresa;
}

export const convertirArregloAObjetoPorId = (arregloDatos = [], arregloObjetoOrigenes = []) => convertirArregloAObjetoPorPropiedad(arregloDatos, arregloObjetoOrigenes, "id");
export const convertirArregloAObjetoPorPropiedad = (arregloDatos = [], arregloObjetoOrigenes = [], nombrePropiedad = "") => {
    if (!arregloDatos?.length || !arregloObjetoOrigenes?.length || !nombrePropiedad) { return {}; }
    const datosTodosObj = {};
    arregloObjetoOrigenes.forEach((objetoOrigen, index) => {
        const propiedadValor = objetoOrigen[nombrePropiedad];
        if (typeof propiedadValor === "string") { datosTodosObj[propiedadValor] = arregloDatos[index]; }
    })
    return datosTodosObj;
}

//NU
export const procesarColeccionFirestore = (coleccion = []) => coleccion?.docs?.map(el => ({ id: el.id, ...el.data() })) || [];
export const procesarDocumentoFirestore = (documento = {}) => documento?.exists ? { id: documento.id, ...documento.data() } : null;
export const procesarDocumentoDatosFirestore = (documento = {}) => documento?.exists ? documento.data() : null;

export const parseNumeroHandleNaN = (numero = 0, esFloat = true) => {
    const parsedNum = esFloat ? parseFloat(numero) : parseInt(numero);

    if (isNaN(parsedNum) || typeof parsedNum !== "number") { return 0; }
    else return parsedNum;
}
export const parseFloatHTG = (float) => parseNumeroHandleNaN(float, ES_FLOAT);
export const parseIntHTG = (integer) => parseNumeroHandleNaN(integer, ES_INT);

export const getArregloDeObjeto = (objeto = {}) => {
    try {
        if (!Object.keys(objeto)?.length) { return []; }

        const entries = Object.entries(objeto) || [];
        const arregloDeObjetos = entries?.map(([llave, datos]) => DeepClone(datos)) || [];
        return arregloDeObjetos;
    } catch (error) {
        console.log("ERROR - getArregloDeObjeto - ", error);
        return [];
    }
}

export const removeEqualStringsFromArray = (array = []) => {
    const newArrayUnicos = new Set(array);
    return [...newArrayUnicos]
}
export const obtainWeightedAverage = (data, percentages) => {
    const [total, weightTotal] = percentages.reduce((acc, percentage, index) => {
        acc[0] = acc[0] + data[index] * percentage;
        acc[1] = acc[1] + percentage;
        return acc;
    },[0, 0]);
    return total / weightTotal;
};
