import React, { useState, useGlobal, useEffect } from 'reactn';
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { guardarCalidadPesos, obtenerRegistroPesos, obtenerFiltrosCalidadTodos } from '../../service/Calidad';
import { Dropdown } from 'primereact/dropdown';
import { obtenerEmpacadorasRegistrosPesos } from '../../service/Empacadoras';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Message } from 'primereact/message';
import { noHaSidoClausurado } from '../../service/Ciclos';
import ContenedorInput from '../common/ContenedorInput';
import ContenedorFiltro from "../common/ContenedorFiltro";
import { getObjetoPorID, getProductoYVariedadString, DeepClone, sumarNumerosArrayPorCampo, tresDeciComas, dosDeciComas, cuatroDecimales } from '../../util/functions';
import ContenedorHeader from '../common/ContenedorHeader';
import icono from '../../images/icons/external-icon.svg';
import { ORDER_BY_PRESENTACION } from '../../service/constants/Calidad.const';
import { getPresentacionesFiltradosYOrdenadosPorInvernadero } from '../../service/ProductosYVariedades';
import SeccionBotones from 'components/common/SeccionBotones';

const inicial = {
  peso_real_caja: "",
  comentario: "",
  presentacion_ref: "",
  locacion_ref: "",
  tipo_empaque: "manual",
  empacadora_ref: "",
}
const tiposEmpacadoras = [{ label: "Manual", value: "manual" }, { label: "Automática", value: "auto" }];
const RegistroPesos = (props) => {
  const [state, setState] = useState(inicial);
  const [invernadero] = useGlobal("invernadero");
  const [ciclo,] = useGlobal("ciclo")
  const [ciclos,] = useGlobal("ciclos")
  const [, setCargando] = useGlobal("cargando")
  const [, setNombreModulo] = useGlobal("nombreModulo")

  const [usuario,] = useGlobal("usuario")
  const [locaciones] = useGlobal("locaciones")
  const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
  const [invernaderosFisicos] = useGlobal("invernaderosFisicos");
  const [editing] = useState(props.match.params.registroID ? true : false)
  const [registroID] = useState(props.match.params.registroID)
  const [datosCargados] = useGlobal("datosCargadosCalidad");
  const [presentaciones] = useGlobal("presentaciones")
  const [proveedor] = useGlobal("proveedorExternoCalidad");
  const [pesos, setPesos] = useState([]);
  const [errores, setErrores] = useState([]);
  const [empacadoras, setEmpacadoras] = useState([])
  const [presentacionesFiltrados, setPresentacionesFiltrados] = useState([])
  const [presentacion, setPresentacion] = useState({})
  const [sumaMuestraPesosTotales, setSumaMuestraPesosTotales] = useState(0);
  const [valorMaximoANoExceder, setValorMaximoANoExceder] = useState(0);
  const porcentajeMaximoAExceder = 1.25;
  const porcentajeMaximoAExcederTot = 25;

  useEffect(() => {
    setHabilitadoSelectorCiclo(false);
    setNombreModulo("Calidad");
  }, [])
  useEffect(() => {
    let valorMaximo = calcularValorMaximo(presentacion.peso_neto_caja);
    valorMaximo = cuatroDecimales(valorMaximo);
    setValorMaximoANoExceder(valorMaximo);
  }, [presentacion])
  useEffect(() => {
    setCargando(true)

    if (!datosCargados && invernadero)
      obtenerFiltrosCalidadTodos(ciclo)
    else if (datosCargados)
      obtenerDatosIniciales()
  }, [invernadero, datosCargados])
  useEffect(() => {
    if (invernaderosFisicos && datosCargados)
      obtenerDatosEmpacadores()
  }, [state.locacion_ref, invernaderosFisicos, datosCargados])
  useEffect(() => {
    setErrores([])
  }, [state, pesos])

  useEffect(() => {
    if (pesos?.length >= 0) {
      const suma = sumarNumerosArrayPorCampo(pesos, "peso");
      const sumaRounded = Math.round(suma * 1000) / 1000
      setSumaMuestraPesosTotales(sumaRounded);
    }
  }, [pesos])

  useEffect(() => {
    if (state.presentacion_ref && presentaciones) {
      let presentacionOBJ = getObjetoPorID(presentaciones, state.presentacion_ref)
      setPresentacion(presentacionOBJ)
    }
  }, [state.presentacion_ref])
  useEffect(() => {
    if (presentaciones && invernadero) {
      const presentacionesFiltrados = getPresentacionesFiltradosYOrdenadosPorInvernadero(presentaciones, invernadero, ORDER_BY_PRESENTACION);
      setPresentacionesFiltrados(presentacionesFiltrados)
    }
  }, [presentaciones, invernadero])
  const puedeEditar = () => noHaSidoClausurado(ciclo, ciclos) && usuario?.rol != "Auxiliar Empaque" && invernadero && (!invernadero?.es_externo || proveedor?.habilitado) && (invernadero?.habilitado || invernadero?.es_externo)
  const obtenerDatosIniciales = async () => {
    if (editing) {
      let registro = await obtenerRegistroPesos(ciclo, invernadero.id, registroID)
      setState(registro)
      setPesos(registro.pesos)
    }
    setCargando(false)
  }
  const obtenerDatosEmpacadores = async () => {
    let result = await obtenerEmpacadorasRegistrosPesos(invernadero, state.locacion_ref)
    setEmpacadoras(result)
  }

  const handleInputChange = (ev) => {
    const stateCopy = DeepClone(state)
    stateCopy[ev.target.name] = ev.target.value;
    setState(stateCopy);
  }
  const handlePesos = (index, ev) => {
    let copia = DeepClone(pesos)
    if (!isNaN(ev.target.value) && ev.target.value.charAt(ev.target.value.length - 5) !== '.')
      copia[index].peso = ev.target.value
    setPesos(copia);
  }

  const guardar = async () => {
    if (validar()) {
      setCargando(true)
      try {
        let datos = {
          ...state,
          pesos,
          momento: editing ? state.momento : Math.round(Date.now() / 1000),
        }
        await guardarCalidadPesos(ciclo, invernadero.id, datos, registroID)
        setCargando(false)
        props.history.goBack()
      }
      catch (error) {
        console.log("ERROR GUARDAR: ", error);
        setCargando(false)
      }
    }
  }
  const validar = () => getErroresFormulario().length === 0;
  const getErroresFormulario = () => {
    let errores = []
    let errorestateNombres = ["tipo_empaque", "presentacion_ref"]
    errores = errorestateNombres.filter(nombre => !state[nombre])

    if (state.tipo_empaque === "manual" && !state.empacadora_ref) { errores.push("empacadora_ref"); }
    if (pesos.length > 0 && pesos.some(peso => !peso.peso)) { errores.push("pesos"); }


    pesos.forEach(peso => {
      const stringValor = `${peso.peso}`;
      console.log(`${stringValor}`,peso.peso)
      if (!stringValor.includes(".") && !errores.includes("decimales") && !errores.includes("pesos")) { errores.push("decimales"); }
    })
    if (!errores.includes("pesos") && !errores.includes("presentacion_ref") && !errores.includes("decimales") && checarValorMaximoExcedido()) { errores.push("sumaPesos"); }

    setErrores(errores)
    return errores;
  }

  const eliminarPeso = (index) => {
    let copia = pesos.slice();
    copia.splice(index, 1);
    setPesos(copia);
  }

  //Checar si ha excedido valor maximo
  const checarValorMaximoExcedido = () => {
    const valorMaximo = calcularValorMaximo(presentacion.peso_neto_caja);
    const valorExcedido = pesos?.some(el => parseFloat(el.peso || 0) > valorMaximo);
    return valorExcedido;
  }
  const calcularValorMaximo = (peso_neto_caja = 0) => {
    const valorPorcentajePesos = peso_neto_caja * porcentajeMaximoAExceder;
    return valorPorcentajePesos;
  }

  return (
    <div className="p-grid p-justify-center">
      {invernadero && <ContenedorHeader col="p-col p-col-auto" titulo="Registro de pesos" subtituloInv={getProductoYVariedadString(invernadero)}
        invernaderoNombre={invernadero.es_externo ? <img width="60" alt="icono" src={icono} /> : invernadero.nombre}
        iconos={`icon-general icon-id bor-${invernadero.color} bg-${invernadero.color} ${invernadero.habilitado ? "" : "Deshabilitado"}`} atras={props.history.goBack} />}

      <div className="p-col-12">
        <p className="section-title"> Información general</p>
        <div className="card">
          <div className="p-grid">
            <div className="form-group p-col-12 p-md-6">
              <label htmlFor="tipo_empaque">Empacadora</label>
              <Dropdown value={state.tipo_empaque} options={tiposEmpacadoras} onChange={handleInputChange} id="tipo_empaque" name="tipo_empaque" disabled={!puedeEditar()} />
              {errores.includes("tipo_empaque") && <Message severity="error" text="Este campo es requerido" />}
            </div>
            {state.tipo_empaque === "manual"
              ? <React.Fragment>
                {invernadero && invernadero.es_externo &&
                  <ContenedorFiltro label="Locación">
                    <Dropdown optionValue="id" optionLabel="nombre" name="locacion_ref" value={state.locacion_ref} options={locaciones} onChange={handleInputChange} />
                  </ContenedorFiltro>}
                <div className="form-group p-col-12 p-md-6">
                  <label htmlFor="empacadora_ref">Empacadora manual</label>
                  <Dropdown value={state.empacadora_ref} options={empacadoras} onChange={handleInputChange}
                    optionLabel="nombreConLocacion" optionValue="id" id="empacadora_ref" name="empacadora_ref" disabled={!puedeEditar()} />
                  {errores.includes("empacadora_ref") && <Message severity="error" text="Este campo es requerido" />}
                </div>
              </React.Fragment>
              : <ContenedorInput label="Peso de la máquina">
                <InputText keyfilter="num" value={state.peso_maquina} name="peso_maquina" onChange={handleInputChange} disabled={!puedeEditar()} />
              </ContenedorInput>}
            <div className="form-group p-col-12 p-md-6">
              <label htmlFor="presentacion_ref">Presentación</label>
              <Dropdown value={state.presentacion_ref} options={presentacionesFiltrados} onChange={handleInputChange} filter={true}
                optionLabel="presentacion" optionValue="id" id="presentacion_ref" name="presentacion_ref" disabled={!puedeEditar()} />
              {errores.includes("presentacion_ref") && <Message severity="error" text="Este campo es requerido" />}
            </div>
            

            {state.presentacion_ref &&
              <React.Fragment>
                <div className="form-group p-col-6 p-md-3">
                  <label htmlFor="peso_real_caja">Peso ideal de caja</label>
                  <div>{presentacion.peso_neto_caja} Kg</div>
                </div>
                {presentacion.peso_neto_presentacion
                  ? <div className="form-group p-col-6 p-md-3">
                    <label htmlFor="peso_real_caja">Peso ideal de presentación</label>
                    <div>{presentacion.peso_neto_presentacion} Kg</div>
                  </div>
                  : <div className="form-group p-col-6 p-md-3">
                    <label htmlFor="peso_real_caja">Peso ideal de presentación</label>
                    <div>{presentacion.peso_neto_caja} Kg</div>
                  </div>}
              </React.Fragment>}
          </div>
        </div>
      </div>

      <div className="p-col-12">
        <p className="section-title">Pesos por presentación (caja-clam kg)</p>
        <div className="card">
          {pesos.map((peso, index) => {
            return (
              <div key={`peso${index}`} className="form-group p-grid p-fluid pallet-dailyweight">
                <div className="p-col-auto p-justify-end p-grid p-align-center m-0">
                  <label className="pallet-id mr-1">{`Muestra ${index + 1}`}</label>
                </div>
                <div className="p-col">
                  <InputText name="input_defecto" value={peso.peso || ""} onChange={(e) => handlePesos(index, e)} disabled={!puedeEditar()}
                    type="number" step=".001" keyfilter="pnum" placeholder="Kg en muestra" />
                </div>
                <div className="p-col-auto p-justify-end p-grid p-align-center ml-01 mr-2">
                  <label >Kg</label>
                </div>
                {puedeEditar() && <div className="p-col-auto">
                  <button className="p-button" onClick={() => { eliminarPeso(index) }}>
                    <FontAwesomeIcon icon={faTrashAlt} onClick={() => { eliminarPeso(index) }} />
                  </button>
                </div>}
              </div>)
          })}
          {errores.includes("sumaPesos") &&
            <Message severity="error" text={`Los kg de una muestra no puede exceder ${porcentajeMaximoAExcederTot}% mas del peso ideal de caja ${presentacion.peso_neto_caja} (${valorMaximoANoExceder}Kg)`} />}
          {errores.includes("decimales") &&
            <Message severity="error" text={"Cada campo requiere un punto decimal."} />}

          <SeccionBotones extra={pesos?.length > 0 ? "mt-1" : "mt-05 mb-0"}>
            <div className="pallet-totalweight mr-1 mt-2 mb-0">
              Total: {tresDeciComas(sumaMuestraPesosTotales)} kg
            </div>
            {puedeEditar() &&
              <div className="p-grid p-justify-end btn-row">
                <Button className="mb-0" onClick={() => setPesos([...pesos, { peso: "" }])} label="+ Agregar nuevo peso" />
              </div>}
          </SeccionBotones>
        </div>
        {errores.includes("pesos") && <Message severity="error" text="Todos los pesos deben tener un valor numerico" />}
      </div>

      <div className="p-col-12 pb-0">
        <p className="section-title">Información adicional</p>
        <div className="card">
          <div className="p-grid">
            <div className="form-group p-col-12">
              <label htmlFor="comentario">Comentario (opcional)</label>
              <InputTextarea rows={5} cols={30} autoResize={true} id="comentario" name="comentario" value={state.comentario} onChange={handleInputChange} disabled={!puedeEditar()} />
            </div>
          </div>
        </div>
      </div>

      <div className="p-col-12">
        <div className="p-grid p-justify-end btn-row">
          <Button label="Cancelar" onClick={props.history.goBack} className="p-button-secondary" />
          <Button label="Guardar" disabled={!puedeEditar()} onClick={guardar} />
        </div>
      </div>
    </div>);
}
export default RegistroPesos;//258