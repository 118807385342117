import React, { useState, useEffect } from 'reactn';
import { useGlobal } from "reactn";
import { Button } from 'primereact/button';
import { obtenerCiclos } from "../../service/Ciclos";
import { obtenerInvernaderosVirtuales, obtenerInvernaderoFisicoRelacionado, obtenerInvernaderosVirtualesInternos } from "../../service/Invernaderos";
import InvernaderoCard from "./InvernaderoCard";
import { obtenerEmpresa } from "../../service/Empresas";
import { obtenerCliente } from "../../service/Clientes";
import RhService from '../../service/RhService';
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { obtenerResumenMedicoInvernadero, obtenerResumenMedicoEmpaque, obtenerResumenMedicoOtros } from '../../service/ReporteMedico';
import ContenedorBotonesTop from '../common/ContenedorBotonesTop';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function Invernaderos(props) {
  const [invernadero, setInvernadero] = useGlobal("invernadero");
  const [weekGlobal, setWeekGlobal] = useGlobal("weekGlobal");
  const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector();
  const [nombreModulo, setNombreModulo] = useGlobal("nombreModulo");
  const [invernaderos, setInvernaderos] = useState([]);
  const [ciclos, setCiclos] = useGlobal("ciclos");
  const [ciclo, setCiclo] = useGlobal("ciclo");
  const [cargando, setCargando] = useGlobal("cargando");
  const [usuario,] = useGlobal("usuario");
  const [, setReporteEdit] = useGlobal("reporteEdit");
  const [invernaderosInternos] = useGlobal("invernaderosInternos");

  useEffect(() => {
    if (ciclo) {
      updateCicloWeekSelector(ciclo);
    }
  }, [ciclo])

  const puedeConsultar = (params) => {
    return (usuario && ["Administrador", "Medico"].includes(usuario.rol))
  }
  const crearReporteMedico = () => {
    setInvernadero(null);
    setReporteEdit(null)
    props.history.push("/reporte_medico/registrar");
  }


  useEffect(() => {
    if (ciclo && week.nombre !== "Cargando") {
      // 
      //     
      let promises = [];
      setCargando(true);
        RhService.getEmpaques().then((empaques) => {
          // 
          // 
          for (let x = 0; x < invernaderosInternos.length; x++) {
            let invernadero = invernaderosInternos[x];
            promises.push(obtenerResumenMedicoInvernadero(ciclo, invernadero, week));
          }
          for (let x = 0; x < empaques.length; x++) {
            let empaque = empaques[x];
            promises.push(obtenerResumenMedicoEmpaque(ciclo, empaque, week));

          }
          promises.push(obtenerResumenMedicoOtros(ciclo, week));
          setCargando(true);
          Promise.all(promises).then(values => {


            setInvernaderos(values);
          }).catch(err => {
            console.log("ERROR PROMISES ALL");
            console.log(err);
          }).finally(e => setCargando(false));;
        }).catch((err) => {

        })


        setCargando(false);
    }

  }, [ciclo, week])
  useEffect(() => {
    setNombreModulo("Reporte médico");
  }, [])

  return (
    <div className="p-grid">
      <div className="p-col-12">
        {puedeConsultar() &&
          <div className="btn-row">
            <Button className="reactbtn-icon" onClick={() => { props.history.push("/reporte_medico/grafica") }} label="Gráfica consultas médicas">
              <FontAwesomeIcon icon={faChartBar} />
            </Button>
            <Button label="Nueva consulta médica" onClick={crearReporteMedico} />
          </div>}
      </div>
      <div className="p-col-12">
        <div className="p-grid filtros">
          <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
        </div>
      </div>
      <div className="p-col-12">
        <div className="p-grid">
          {invernaderos.map(invernadero => <InvernaderoCard key={invernadero.id} invernadero={invernadero} />)}
        </div>
      </div>
    </div>)
}
export default Invernaderos;