import React, { useState, useEffect, useGlobal } from 'reactn';
import { Button } from 'primereact/button';
import { getSobrantePasada, obtenerDatosSobrantes, obtenerIncumplidos, obtenerMatrizComparativa, obtenerProyectado, procesarDatosOrdenesVenta, procesarFinal, procesarProyectado } from '../../../service/Cumplimiento';
import { TabMenu } from "primereact/tabmenu";
import WeekSelector, { useWeekSelector } from '../../common/WeekSelector';
import TablaCumplimientoTotal from './TablaCumplimientoTotal.mole';
import { getClientesCiclo } from '../../../service/Clientes';
import { Dialog } from 'primereact/dialog';
import FormularioSobrante from './FormularioSobrante.mole';
import ContenedorFiltro from '../../common/ContenedorFiltro';
import { SelectButton } from 'primereact/selectbutton';
import { DeepClone, getObjetoPorID } from '../../../util/functions';
import SeccionFiltros from '../../common/SeccionFiltros';
import { obtenerEmpaques } from '../../../service/Empaques';

import { MultiSelect } from 'primereact/multiselect';
import { obtenerPresentaciones } from '../../../service/Presentaciones';
import RowBotonesTop from '../../common/RowBotonesTop';
import ContenedorBotonesTop from '../../common/ContenedorBotonesTop';
import ContenedorCardFormulario from '../../common/ContenedorCardFormulario';
import ContenedorHeader from '../../common/ContenedorHeader';
import { obtenerOrdenesSemanaOrdenadosDesc } from '../../../service/Ordenes';
import ModalRedistribucionMedleys from './ModalRedistribucionMedleys.mole';
import { obtenerProveedores } from '../../../service/Queries/Proveedores';
import { estamosEnProduccion } from '../../../constants';
import { getNombreDiaSemana } from '../../../service/fechas';
import MatrizComparativa from './MatrizComparativa.mole';
import TablaCumplimientoInv from './TablaCumplimientoInv.mole';
import { getDistribucionMedleysQEURY } from '../../../service/repositorios/DistribucionMedleys.queries';
import { getProductoBaseDeInvernadero } from 'service/ProductosYVariedades';

const optionsButton = [{ label: "Cajas", value: "cajas" }, { label: "Kilos", value: "kilos" }];
const SeccionCumplimiento = (props) => {
  const [, setNombreModulo] = useGlobal("nombreModulo");
  const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
  const [ciclo] = useGlobal("ciclo");
  const [, setCargando] = useGlobal("cargando");
  const [cajasKilos, setCajasKilos] = useState(estamosEnProduccion ? optionsButton[1].value : optionsButton[1].value);
  const [weeks, week, handleWeekChange, , , asignarListaSemanas] = useWeekSelector(true);
  const [productosBases] = useGlobal("productosBases");
  const [productosYVariedades] = useGlobal("productosYVariedades")
  const [medleysGeneral] = useGlobal("medleysGeneral");
  const [productos, setProductos] = useState([]);
  const [clientes, setClientes] = useGlobal("clientesDelCiclo");
  const [activeProducto, setActiveProducto] = useGlobal("activeProductoCumplimiento");
  const [invernaderosUnicoMedleys, setInvernaderosUnicoMedleys] = useGlobal("invernaderosUnicoMedleys");

  const [datosInicialesCargados, setDatosInicialesCargados] = useState(false);
  const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
  const [invernaderosFisicos] = useGlobal("invernaderosFisicos");
  const [, setPresentaciones] = useGlobal("presentaciones");
  const [invernaderos, setInvernaderos] = useState(null);
  const [incumplidos, setIncumplidos] = useState({});
  const [proyectado, setProyectado] = useState(null);

  const [matriz, setMatriz] = useState([]);
  const [matrizDelProducto, setMatrizDelProducto] = useState(null);

  const [sitiosEmpaquesOpciones, setSitiosEmpaquesOpciones] = useGlobal("sitiosEmpaqueCicloActual");
  const [sitiosEmpaqueFiltro, setSitiosEmpaqueFiltro] = useState([]);
  const [, setMostrarModalPermiso] = useGlobal("mostrarModalPermiso");
  const [modalSobrante, setModalSobrante] = useState(false);
  const [clientesFiltro, setClientesFiltro] = useState([]);
  const [, setUltimaCarga] = useGlobal("ultimaFechaCargaCumplimiento");
  const [showModalMedleys, setShowModalMedleys] = useGlobal("showModalMedleys");
  const [, setProveedores] = useGlobal("proveedores");
  const [sumaTotalPrestado, setSumaTotalPrestado] = useState({});
  const [cumplimientoSemanaDatos, setCumplimientoSemanaDatos] = useState({})
  const [distribucionMedleysDatos, setDistribucionMedleysDatos] = useState([])
  const [sobrantes, setSobrantes] = useState([])

  useEffect(() => {
    setNombreModulo("Cumplimiento");
    setHabilitadoSelectorCiclo(true);
  }, [])
  useEffect(() => {//Si hoy es Domingo, adelantar la lista de semanas por 2
    if (week?.nombre !== "Cargando") {
      const diaHoy = getNombreDiaSemana(Date.now() /*+ (miliSegundosDia * 1)*/);//comento es para mostrar la tarea
      if (diaHoy === "domingo") { asignarListaSemanas(2); }
    }
  }, [JSON.stringify(week)])//Posiblemente mover ese funcionalidad al componente WeekSelector?


  useEffect(() => {
    if (ciclo) { obtenerDatosIniciales(); }
  }, [ciclo])


  useEffect(() => {
    if (datosInicialesCargados) { obtenerDatosResumenCumplimiento(); }
  }, [week, datosInicialesCargados])
  useEffect(() => {
    if (props.ordenesSemana && !props.visible) { actualizarDatosCumplimiento(); }//Obtener datos de resumen una vez que he hecho distribución en la pantalla de distribución de cajas
  }, [props.ordenesSemana])

  useEffect(() => {//Obtener datos filtrados
    if (datosInicialesCargados && sobrantes?.length > 0) { actualizarDatosCumplimiento(); }
  }, [activeProducto, clientesFiltro, sitiosEmpaqueFiltro])


  useEffect(() => {//filtrar datos de la matriz comparativa
    if (invernaderos && matrizDelProducto) { filtrarDatosMatriz(); }
  }, [invernaderos, matrizDelProducto])
  useEffect(() => {//filtrar datos de la matriz comparativa
    if (invernaderos) {
      const sumaTotalPrestado = {}
      invernaderos.forEach(el => {
        sumaTotalPrestado[el.id] = 0;
      });
      setSumaTotalPrestado(sumaTotalPrestado);
    }
  }, [invernaderos])


  const obtenerDatosIniciales = async () => {
    setCargando(true);
    const [empaquesResp, presentacionesResp, clientesResp, proveedoresResp, distribucionMedleysDatos]
      = await Promise.all([obtenerEmpaques(ciclo), obtenerPresentaciones(), getClientesCiclo(ciclo), obtenerProveedores(ciclo), getDistribucionMedleysQEURY(ciclo)]);

    const productosProcesados = productosBases?.map(el => ({ ...el, label: el.nombre })) || [];
    const tabActivo = activeProducto ? getObjetoPorID(productosProcesados, activeProducto.id) : productosProcesados[4];//Seleccionar Medleys por defecto [Beef, Cherry, Cocktail, Grape, Medleys, TOV]
    let empaquesOpciones = [{ nombre: "Sin Asignar", id: "sin" }, ...(empaquesResp || [])];
    setProductos(productosProcesados);
    setActiveProducto(tabActivo);

    setSitiosEmpaquesOpciones(empaquesOpciones);
    setSitiosEmpaqueFiltro(empaquesOpciones); //TEST - [empaquesOpciones[1],empaquesOpciones[2]]
    setClientes(clientesResp);
    setClientesFiltro(clientesResp);
    setProveedores(proveedoresResp);
    setPresentaciones(presentacionesResp);
    setDistribucionMedleysDatos(distribucionMedleysDatos);

    setDatosInicialesCargados(true);
    setCargando(false);
  }

  const actualizarDatosCumplimiento = async () => {
    const invernadosDeProducto = filtrarInvernaderos();
    const incumplidos = obtenerIncumplidos(invernaderosVirtuales, week.time, proyectado, DeepClone(props.ordenesSemana), sobrantes, clientesFiltro, sitiosEmpaqueFiltro);
    const cumplimientoProductoDatos = procesarDatosOrdenesVenta(DeepClone(props.ordenesSemana), invernaderosFisicos, invernadosDeProducto, activeProducto, sobrantes, clientesFiltro, sitiosEmpaqueFiltro)
    setIncumplidos(incumplidos);
    setCumplimientoSemanaDatos(DeepClone(cumplimientoProductoDatos))//asignar nuevo productos datos
  }

  const obtenerDatosResumenCumplimiento = async (ordenesSemana) => {
    setCargando(true);
    try {
      //Procesar datos de ordenes de venta para generar las tablas
      const invernadosDeProducto = filtrarInvernaderos();
      let ordenesDeVentaSemana = ordenesSemana, sobrantes = [], proyectado = [];
      const promises = [obtenerDatosSobrantes(invernaderosVirtuales, ciclo, week), obtenerProyectado(ciclo, invernaderosVirtuales, week.time, productosYVariedades, medleysGeneral)];
      if (ordenesSemana) { [sobrantes, proyectado] = await Promise.all(promises); }
      else { [sobrantes, proyectado, ordenesDeVentaSemana] = await Promise.all([...promises, obtenerOrdenesSemanaOrdenadosDesc(ciclo, week.time)]); }

      const incumplidos = obtenerIncumplidos(invernaderosVirtuales, week.time, proyectado, ordenesDeVentaSemana, sobrantes);
      const cumplimientoProductoDatos = procesarDatosOrdenesVenta(DeepClone(ordenesDeVentaSemana), invernaderosFisicos, invernadosDeProducto, activeProducto, sobrantes, clientesFiltro, sitiosEmpaqueFiltro);

      setSobrantes(sobrantes);
      setProyectado(proyectado);
      setIncumplidos(incumplidos);
      console.log("cumplimientoProductoDatos:",cumplimientoProductoDatos)
      setCumplimientoSemanaDatos(cumplimientoProductoDatos)
      props.setOrdenesSemana(ordenesDeVentaSemana);

      //Cargar datos del matriz sin el spinner para hacer mas agil la pantalla
      setCargando(false);
      let matrizResponse = await obtenerMatrizComparativa(ciclo, invernaderosVirtuales, week.time, ordenesDeVentaSemana).catch(err => { console.log(JSON.stringify(err)) });
      setMatrizDelProducto(matrizResponse);
      setUltimaCarga(Date.now())

    } catch (error) {
      if (error?.code === "permission-denied") { setMostrarModalPermiso(true); }
      console.log("ERROR RESUMEN CUMPLIMIENTO: ", error);
    }
  }


  const actualizarDatosResumenCumplimiento = async () => {
    setUltimaCarga(Date.now())
    await obtenerDatosResumenCumplimiento();
  }

  const filtrarInvernaderos = () => {
    const filtrados = invernaderosVirtuales.filter(inv => inv.producto_ref === activeProducto.id || (activeProducto.MEDLEYS_GENERAL && (inv.mezcla || inv.MEDLEYS_VIEJO)));
    setInvernaderos(filtrados);
    return filtrados
  }
  const filtrarInvernaderosCompleto = () => {
    const filtradoPorProducto = invernaderosVirtuales.filter(inv => inv.producto_ref === activeProducto.id || (activeProducto.MEDLEYS_GENERAL && (inv.mezcla || inv.MEDLEYS_VIEJO)))

    const invernaderosFiltrados = filtradoPorProducto?.filter(inv => {
      const invernaderoFisicoID = inv.invernadero_fisico_ref;
      const invernaderoFisico = getObjetoPorID(invernaderosFisicos, invernaderoFisicoID);

      const someSitioEmpaque = sitiosEmpaqueFiltro.some(el => el.id === invernaderoFisico.empaque_ref);
      const someCliente = clientesFiltro.some(el => el.id === inv.cliente_ref);

      return (someCliente && someSitioEmpaque) || inv.es_externo;
    }) || [];
    setInvernaderos(invernaderosFiltrados);//Utilizar set()? O regresar valor para hacer set() en el useEffect? 2. Mover funcion a service y pasar varieables?
    //console.log("!!!INVS: ", invernaderosFiltrados)
    return invernaderosFiltrados;
  }
  const filtrarDatosMatriz = async () => {
    const matrizFiltrado = matrizDelProducto.filter(invernadero => invernaderos.some(el => el.id === invernadero.id));
    setMatriz(matrizFiltrado);
  }

  const verSemana = () => { props.history.push({ pathname: "/ordenes", data: { cumplimiento: true } }); }
  const getResumenFinalInv = (invernaderoObj) => {
    const productoInv  = getProductoBaseDeInvernadero(productosBases, medleysGeneral, invernaderoObj)
    const cumplimientoDatosResp = procesarDatosOrdenesVenta(props.ordenesSemana, invernaderosFisicos, [invernaderoObj], productoInv, sobrantes, clientes, sitiosEmpaquesOpciones);
    const cumplimientoDatosInvs = cumplimientoDatosResp?.invernaderosDatos?.[invernaderoObj.id];
    //console.log("-----getResumenFinalInv---- 0 ", {activeProducto, cumplimientoDatosResp, cumplimientoDatosInvs, proyectado, sobrantes })

    
    const defaultInvDatos = { totalesCajas: [0, 0, 0, 0, 0, 0, 0], totalesKilos: [0, 0, 0, 0, 0, 0, 0] };
    const { totalesCajas, totalesKilos, ...cumplimientoDatos } = cumplimientoDatosInvs || defaultInvDatos;
    //console.log("getResumenFinalInv 1 ", { invernaderoObj })

    const proyectadoProcesado = procesarProyectado(proyectado, [invernaderoObj])
    //console.log("getResumenFinalInv 1.5 ", { proyectadoProcesado})

    const sobrantePasada = getSobrantePasada(sobrantes, invernaderosFisicos, invernaderoObj, sitiosEmpaquesOpciones);
    //console.log("getResumenFinalInv 2 ", { invernaderoObj, totalesKilos, proyectadoProcesado, sobrantePasada })

    const final = procesarFinal(totalesKilos, proyectadoProcesado, sobrantePasada);
    //console.log("-----!!!getResumenFinalInv-----!!! 3 ", final)

    return final;
  }
  return (props.visible ?
    <React.Fragment>
      <ContenedorHeader iconos="boxes-icon" atras={props.history.goBack} titulo="Cumplimiento de la semana" col="p-col p-col-auto" />

      <ContenedorBotonesTop>
        <RowBotonesTop>
          <Button label="Órdenes de la semana" onClick={verSemana} />
          <Button label="Modificar sobrante inicial" onClick={() => setModalSobrante(true)} />
        </RowBotonesTop>
      </ContenedorBotonesTop>

      <SeccionFiltros>
        <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />

        <ContenedorFiltro label="cliente">
          <MultiSelect value={clientesFiltro} options={clientes} onChange={(e) => setClientesFiltro(e.value)} optionLabel="nombre" dataKey="id" disabled={false} selectedItemsLabel={`${clientesFiltro?.length || 0} clientes seleccionadas`} />
        </ContenedorFiltro>
        <ContenedorFiltro label="sitio empaque">
          <MultiSelect value={sitiosEmpaqueFiltro} options={sitiosEmpaquesOpciones} onChange={(e) => setSitiosEmpaqueFiltro(e.value)} optionLabel="nombre" dataKey="id" disabled={false} selectedItemsLabel={`${sitiosEmpaqueFiltro?.length || 0} sitios seleccionadas`} />
        </ContenedorFiltro>

        <ContenedorFiltro label="Unidades de órdenes">
          <SelectButton value={cajasKilos} options={optionsButton} onChange={(e) => setCajasKilos(e.value)} />
        </ContenedorFiltro>
      </SeccionFiltros>

      {/* 

        */}
      <Dialog header="Modificar sobrante inicial de la semana" visible={modalSobrante} onHide={() => setModalSobrante(false)}>
        <FormularioSobrante onHide={() => setModalSobrante(false)} semana={week.time} onActualizado={actualizarDatosResumenCumplimiento} getResumenFinalInv={getResumenFinalInv} />
      </Dialog>
      {showModalMedleys && 
      <ModalRedistribucionMedleys visible={!!showModalMedleys} distribucionDatos={showModalMedleys} onHide={() => setShowModalMedleys(false)} setDistribucionMedleysDatos={setDistribucionMedleysDatos} />}

      <TabMenu className="p-col-12 mb-0 pb-0" model={productos} activeItem={activeProducto} onTabChange={(e) => setActiveProducto(e.value)} />
      <TablaCumplimientoTotal sumaTotalPrestado={sumaTotalPrestado} invernaderos={invernaderos} proyectado={proyectado} productoId={activeProducto?.id}
        filtrarKilos={cajasKilos === "kilos"} ordenesSemana={props.ordenesSemana} cumplimientoProductoDatos={cumplimientoSemanaDatos?.presentacionesDatos} />

      {Object.keys(sumaTotalPrestado).length > 0 && cumplimientoSemanaDatos?.invernaderosDatos && props.ordenesSemana?.length > 0 &&
        <ContenedorCardFormulario>
          {invernaderos?.map((inv, index) => {
            const key = `${inv.id}-${index}`;
            const sobrantePasada = getSobrantePasada(sobrantes, invernaderosFisicos, inv, sitiosEmpaqueFiltro);
            //console.log(`INV: ${inv.nombre} - `, sobrantePasada)

            const incumplidosInv = incumplidos[inv.id];
            const defaultInvDatos = { totalesCajas: [0, 0, 0, 0, 0, 0, 0], totalesKilos: [0, 0, 0, 0, 0, 0, 0] };
            //if(inv.nombre === "I1")  console.log("incumplidosInv: ",incumplidosInv)
            return <TablaCumplimientoInv key={key} invernadero={inv} sobrantePasada={sobrantePasada} proyectado={proyectado} incumplidos={incumplidosInv}
              cumplimientoProductoDatos={cumplimientoSemanaDatos?.invernaderosDatos[inv.id] || defaultInvDatos} ordenesSemana={props.ordenesSemana} producto={activeProducto}
              distribucionMedleysDatos={distribucionMedleysDatos} setShowModalMedleys={setShowModalMedleys} filtrarKilos={cajasKilos === "kilos"} empaquesFiltros={sitiosEmpaqueFiltro} />
          })}
        </ContenedorCardFormulario>}

      {/* Invernaderos medleys en tab unico */}
      {matriz?.length > 0 && Object.keys(sumaTotalPrestado).length > 0 && invernaderosUnicoMedleys?.length > 0 && !activeProducto?.MEDLEYS_GENERAL &&
        <ContenedorCardFormulario>
          {invernaderosUnicoMedleys?.map((inv, ind) => {
            const key = `ta-cu${ind}-unmd-${inv.id}`;
            const defaultInvDatos = { totalesCajas: [0, 0, 0, 0, 0, 0, 0], totalesKilos: [0, 0, 0, 0, 0, 0, 0] };
            return <TablaCumplimientoInv key={key} unicoOrigenMedleys={true} invernadero={inv} sobrantePasada={"-"} proyectado={[0, 0, 0, 0, 0, 0, 0]} incumplidos={[0, 0, 0, 0, 0, 0, 0]}
              cumplimientoProductoDatos={cumplimientoSemanaDatos?.invernaderosDatos[inv.id] || defaultInvDatos} ordenesSemana={props.ordenesSemana} producto={activeProducto}
              distribucionMedleysDatos={distribucionMedleysDatos} setShowModalMedleys={setShowModalMedleys} filtrarKilos={cajasKilos === "kilos"} empaquesFiltros={sitiosEmpaqueFiltro} />
          })}
        </ContenedorCardFormulario>}

      {matriz?.length > -1 && <MatrizComparativa invernaderos={invernaderos} matrizDelProducto={matrizDelProducto} />}
    </React.Fragment>
    : null)
}
export default SeccionCumplimiento;//309