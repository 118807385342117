
import { faFileSignature, faLessThan, faNotesMedical, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContenedorBotonesTop from 'components/common/ContenedorBotonesTop';
import ModalConfirmacion from 'components/common/ModalConfirmacion';
import RowBotonesTop from 'components/common/RowBotonesTop';
import { BASE_URL } from "../../constants";
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { ToggleButton } from 'primereact/togglebutton';
import React, { useState, useEffect } from 'react';
import { useGlobal } from 'reactn'
import { INVOICE_STATUSES } from 'service/constants/Rechazos.const';
import NAVEGACION from 'service/constants/Routes.const';
import { getInvoicesCiclo } from 'service/helpers/Invoices.helpers';
import { obtenerPresentaciones } from 'service/Presentaciones';
import { obtenerPresentacionesClientes } from 'service/QueriesPresentaciones';
import { dosDecimales, getMomentFormatted, separadoComas } from 'util/functions';
import { obtenerClientes, obtenerClientesCiclo } from '../../service/Clientes';
import { obtenerInicioDia, obtenerNombreFecha } from '../../service/fechas';
import { obtenerBudgetGrupoPresentaciones, obtenerGruposPresentaciones } from '../../service/QueriesGruposPresentaciones';
import { actualizarItemManifiesto, obtenerResumenPreciosManifiesto, desplazarItemManifiesto, NO_APROBADO } from '../../service/ReportePrecios';
import ContenedorFiltro from '../common/ContenedorFiltro';
import ContenedorFiltroBusqueda from '../common/ContenedorFiltroBusqueda';
import ContenedorHeader from '../common/ContenedorHeader'
import ContenedorPrincipal from '../common/ContenedorPrincipal'
import ContenedorTabla from '../common/ContenedorTabla';
import SeccionFiltros from '../common/SeccionFiltros'
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import ModalFormulario from '../reporte_precios/ModalFormulario';

const fecha_dummy = obtenerInicioDia(1606923450);
const dummy = [
    { presentacion: "DM1", manifiesto: "M1", fecha_envio: fecha_dummy, cajas: 10, status: "Sin" },
    { presentacion: "DM2", manifiesto: "M2", fecha_envio: fecha_dummy, cajas: 20, status: "Sin" },
    { presentacion: "DM3", manifiesto: "M3", fecha_envio: fecha_dummy, cajas: 30, status: "No aprobado" },
    { presentacion: "DM4", manifiesto: "M4", fecha_envio: fecha_dummy, cajas: 30, status: "Aprobado" },
    { presentacion: "DM5", manifiesto: "M5", fecha_envio: fecha_dummy, cajas: 30, status: "Reproceso" },
    { presentacion: "DM6", manifiesto: "M6", fecha_envio: fecha_dummy, cajas: 30, status: "Rechazo" },
]

const OPTION_TODOS_DEFAULT = {
    id: "Todos",
    nombre: "Todos"
}

const Consulta = (props) => {
    const [ciclo, setCiclo] = useGlobal("ciclo");
    const [usuario, setUsuario] = useGlobal("usuario");
    const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
    const [productosBases] = useGlobal("productosBases");
    const [medleysGeneral] = useGlobal("medleysGeneral");
    const [cargando, setCargando] = useGlobal("cargando");
    const [nombreModulo, setNombreModulo] = useGlobal("nombreModulo");
    const [globalFilter, setGlobalFilter] = useGlobal("globalFilter");
    const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector();
    const [mostrarFormulario, setMostrarFormulario] = useState(false);
    const [manifiestos, setManifiestos] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [edicionFormulario, setEdicionFormulario] = useGlobal("edicionVerificacionPrecios");
    const [triggerUpdate, setTriggerUpdate] = useState(false);
    const [mensajeError, setMensajeError] = useState("");
    const [modalConfirmacion, setModalConfirmacion] = useGlobal("modalConfirmacion");
    const [budgets, setBudgets] = useState([])

    const [gruposPresentacionesOrig, setGruposPresentacionesOrig] = useState([])
    const [gruposPresentacionesOpciones, setGruposPresentacionesOpciones] = useState([OPTION_TODOS_DEFAULT])

    const [presentacionesOrig, setPresentacionesOrig] = useState([])
    const [presentacionesOpciones, setPresentacionesOpciones] = useState([])

    const [invernaderos, setInvernaderos] = useState([])
    const [productos, setProductos] = useState([])
    const [cajaBusqueda, setCajaBusqueda] = useState("")

    const [filtroCliente, setFiltroCliente] = useState();
    const [filtroProducto, setFiltroProducto] = useState([])
    const [filtroInvernadero, setFiltroInvernadero] = useState([])
    const [filtroGrupoPresentaciones, setFiltroGrupoPresentaciones] = useState({})
    const [filtroPresentaciones, setFiltroPresentaciones] = useState([])

    const [manifiestosSeleccionadosState, setManifiestosSeleccionadosState] = useState({})

    const [manifiestosOrig, setManifiestosOrig] = useState([])
    const [invoicesTodos, setInvoicesTodos] = useState()

    const [sumaDeCajasSeleccionadas, setSumaDeCajasSeleccionadas] = useState(0)

    const [selectAllValue, setSelectAllValue] = useState(false)
    const [checkboxSelectAllDisable, setCheckboxSelectAllDisable] = useState(true)

    const [showModalConfirmacion, setShowModalConfirmacion] = useState(false)

    const { VALIDADO, CON_RECHAZO } = INVOICE_STATUSES

    useEffect(() => {
        setNombreModulo("Finanzas");
    }, [])
    useEffect(() => {
        if (invernaderosVirtuales) obtenerDatosIniciales();
    }, [invernaderosVirtuales]);
    useEffect(() => {
        if (ciclo && filtroCliente && week?.nombre !== "Cargando" && invoicesTodos) updateInfo();
    }, [ciclo, filtroCliente, week, invoicesTodos])
    useEffect(() => {
        const startTrigger = async () => {
            if (triggerUpdate && invoicesTodos) {
                await updateInfo();
                setTriggerUpdate(false);
                setCargando(false)
            }
        }
        startTrigger()
    }, [triggerUpdate, invoicesTodos])

    /* FILTROS EN CASCADA */
    //Filtra productos por y cuando hay invernaderos seleccionados
    useEffect(() => {
        if (!filtroCliente || !productosBases || filtroInvernadero.length === 0) {
            setFiltroProducto([])
            setProductos([])
            return
        }

        const productosFiltradosPorInvernadero = productosBases.filter(prod => {
            if (prod.MEDLEYS_GENERAL) return filtroInvernadero.some(inv => inv.mezcla)
            return filtroInvernadero.some(inv => inv.producto_ref === prod.id)
        })
        setFiltroProducto(productosFiltradosPorInvernadero || [])
        setProductos(productosFiltradosPorInvernadero || [])
    }, [filtroCliente, filtroInvernadero])
    //Filtra grupos de presentaciones por y cuando hay cambios en filtro cliente, filtro invernadero y filtro producto
    useEffect(() => {
        if (!filtroCliente || filtroInvernadero.length === 0 || filtroProducto.length === 0) {
            setGruposPresentacionesOpciones([OPTION_TODOS_DEFAULT])
            setFiltroGrupoPresentaciones(OPTION_TODOS_DEFAULT)
            return
        }
        const grupoPresenFiltradasPorCliente = gruposPresentacionesOrig.filter(grupP => {
            const tieneProducto = filtroProducto.some(prod => prod.id === grupP.producto_ref)
            return grupP.cliente_ref === filtroCliente.id && tieneProducto
        })

        setGruposPresentacionesOpciones([OPTION_TODOS_DEFAULT, ...grupoPresenFiltradasPorCliente])
        setFiltroGrupoPresentaciones(OPTION_TODOS_DEFAULT)
    }, [filtroCliente, filtroProducto])
    //Filtra presentaciones por y cuando hay cambios en filtro grupo de presentaciones
    useEffect(() => {
        if (presentacionesOrig.length === 0 || gruposPresentacionesOpciones.length === 0) {
            setPresentacionesOpciones([])
            setFiltroPresentaciones([])
            return
        }

        if (filtroGrupoPresentaciones.nombre === OPTION_TODOS_DEFAULT.nombre) {
            const presentacionesPorGruposOpciones = gruposPresentacionesOpciones.reduce((acc, grupP) => {
                if (!grupP.presentaciones_refs) return acc

                const presetacionesDeGrupo = grupP.presentaciones_refs.map(presRef => {
                    const presentacion = presentacionesOrig.find(pres => pres.id === presRef)
                    return presentacion
                })

                return [...acc, ...presetacionesDeGrupo]
            }, [])
            setPresentacionesOpciones([...presentacionesPorGruposOpciones])
            setFiltroPresentaciones([...presentacionesPorGruposOpciones])
            return
        }

        const presentacionesDelGrupoSeleccionado = filtroGrupoPresentaciones.presentaciones_refs.map(presRef => {
            const presentacion = presentacionesOrig.find(pres => pres.id === presRef)
            return presentacion
        })

        setPresentacionesOpciones([...presentacionesDelGrupoSeleccionado])
        setFiltroPresentaciones([...presentacionesDelGrupoSeleccionado])

    }, [filtroGrupoPresentaciones, gruposPresentacionesOpciones])
    //Se preselecciona el grupo de pres con el primero checkbox seleccionado
    useEffect(() => {
        const manifiestosSeleccionados = obtenerDeEstadoManifiestosSeleccionados()
        if (manifiestosSeleccionados.length === 1 && filtroGrupoPresentaciones.nombre === OPTION_TODOS_DEFAULT.nombre) {
            const [presentacion_ref] = manifiestosSeleccionados[0].split("-")
            let grupoDePresentacionDeLaPresentacion = {}
            gruposPresentacionesOpciones.forEach(grupP => {
                if (!grupP.presentaciones_refs) return
                const estaEnEsteGrupo = grupP.presentaciones_refs.some(presRef => presRef === presentacion_ref)
                if (estaEnEsteGrupo) grupoDePresentacionDeLaPresentacion = grupP
            })
            setFiltroGrupoPresentaciones(grupoDePresentacionDeLaPresentacion)
        }

    }, [manifiestosSeleccionadosState])
    useEffect(() => {
        setCargando(true)
    }, [week, filtroCliente])
    useEffect(() => {
        let productosIds = filtroProducto.map(el => el.id)
        let invernaderosIds = filtroInvernadero.map(el => el.id)
        const presentacionesIds = filtroPresentaciones.map(pre => pre.id)

        let filtrados = manifiestosOrig.filter(manifiesto => {

            const { producto_ref, producto, invernadero_ref, presentacion_ref } = manifiesto
            const tieneElProducto = productosIds.includes(producto_ref)
            const tieneYEsMezcla = productosIds.includes(medleysGeneral?.id) && producto.mezcla
            const tieneInvernadero = invernaderosIds.includes(invernadero_ref)
            const tienePresentacion = presentacionesIds.includes(presentacion_ref)

            if (presentacion_ref === "ZfZpijUup00A0wwHFamW") console.log("DDDDD: ", { manifiesto, medleysGeneral, tieneYEsMezcla, presentacionesIds, invernaderosIds, productosIds })
            return (tieneElProducto || tieneYEsMezcla) && tieneInvernadero && tienePresentacion
        })

        console.log("1111 MANIS::::", { filtrados, presentacionesIds, invernaderosIds, productosIds })
        setManifiestos(filtrados);

    }, [filtroCliente, filtroInvernadero, filtroProducto, filtroPresentaciones, manifiestosOrig])

    useEffect(() => {
        const manifiestosSeleccionados = obtenerDeEstadoManifiestosSeleccionados()
        const cambiosDeManifiestosSeleccionados = {}
        const sumaDeCajas = manifiestosSeleccionados.reduce((acc, manifiesto) => {

            const [presentacion_ref, manifiestoId, invernadero_ref] = manifiesto.split("-")
            const manifiestoData = manifiestos.find(manf =>
                manf.presentacion_ref === presentacion_ref &&
                manf.manifiestoId === manifiestoId &&
                manf.invernadero_ref === invernadero_ref)
            const itemKey = `${presentacion_ref}-${manifiestoId}-${invernadero_ref}`
            if (!manifiestoData && manifiestosSeleccionadosState[itemKey]) {
                cambiosDeManifiestosSeleccionados[itemKey] = false
                return acc
            }
            if (!manifiestoData) return acc
            const { cajas_orden } = manifiestoData

            if (!cajas_orden) return acc
            acc = acc + parseFloat(cajas_orden)
            return acc
        }, 0)
        const hayManifiestosParaCambiar = Object.keys(cambiosDeManifiestosSeleccionados).length > 0
        if (hayManifiestosParaCambiar) {
            setManifiestosSeleccionadosState({
                ...manifiestosSeleccionadosState,
                ...cambiosDeManifiestosSeleccionados
            })
        }
        setSumaDeCajasSeleccionadas(sumaDeCajas)
    }, [manifiestosSeleccionadosState, manifiestos])
    useEffect(() => {
        if (filtroGrupoPresentaciones.nombre === OPTION_TODOS_DEFAULT.nombre) {
            setManifiestosSeleccionadosState([])
        }
    }, [filtroGrupoPresentaciones])
    useEffect(() => {
        if (manifiestos.length === 0) return setCheckboxSelectAllDisable(true)
        if (!filtroGrupoPresentaciones.nombre) return setCheckboxSelectAllDisable(true)
        if (filtroGrupoPresentaciones.nombre === OPTION_TODOS_DEFAULT.nombre) return setCheckboxSelectAllDisable(true)

        setCheckboxSelectAllDisable(false)
    }, [filtroGrupoPresentaciones, manifiestos])
    useEffect(() => {
        const estanTodosSeleccionados = checarSiTodosManifiestosEstanSeleccionados()
        setSelectAllValue(estanTodosSeleccionados)
    }, [manifiestosSeleccionadosState])
    const updateInfo = async (params) => {
        const resumenResp = await obtenerResumenPreciosManifiesto(ciclo, week?.time, filtroCliente.id)
        const resumen = resumenResp?.map(row => {
            const invoiceFound = invoicesTodos?.find(invoice => invoice.items.some(item => item.itemId === row.itemID));
            //console.log("RES::::",{invoiceFound, resumenResp,invoicesTodos, resumen})
            if (invoiceFound) { row.invoice = invoiceFound; }
            return row
        }) || [];

        setManifiestosOrig(resumen);
        setCargando(false)
    }

    const activarModalConfirmacionDesplazamiento = (item, original) => {
        let mensaje = "¿Estás seguro que deseas desplazar este item a la siguiente semana?"
        let titulo = "Confirmación de acción";
        let aceptar = () => { desplazar(item, original) };
        let cancelar = () => { }
        setModalConfirmacion({ mensaje, titulo, aceptar, cancelar })
    }

    const obtenerDatosIniciales = async (params) => {
        setCargando(true)
        updateCicloWeekSelector(ciclo);
        let clientesPromise = obtenerClientes();
        let clientesCicloPromise = obtenerClientesCiclo(ciclo);
        let [clientes, clientesCiclo, invoicesResp] = await Promise.all([clientesPromise, clientesCicloPromise, getInvoicesCiclo(ciclo)]);
        let idsClientes = clientesCiclo.map(el => el.cliente_ref);
        clientes = clientes.filter(el => idsClientes.includes((el.id)))
        const clienteInicial = clientes[1]
        setClientes(clientes);
        setFiltroCliente(clienteInicial);
        setInvoicesTodos(invoicesResp);

        let gruposPresentaciones = await obtenerGruposPresentaciones()
        const presentaciones = await obtenerPresentaciones()
        let promisesBudgets = gruposPresentaciones.map(el => obtenerBudgetGrupoPresentaciones(ciclo, el.id))
        let budgets = await Promise.all(promisesBudgets);

        setGruposPresentacionesOrig(gruposPresentaciones);
        setGruposPresentacionesOpciones([OPTION_TODOS_DEFAULT])

        setFiltroInvernadero(invernaderosVirtuales)
        setInvernaderos(invernaderosVirtuales)

        setPresentacionesOrig(presentaciones)

        setBudgets(budgets);
        setCargando(false)
    }
    const templateFecha = (rowData, column) => {
        return <label >{getMomentFormatted(rowData.fecha_envio * 1000, "DD/MM/YYYY")}</label>;
    }
    const templatePrecio = (rowData, column) => {
        if (rowData["precio_libra_real"] && (rowData.status === "aprobado" || rowData.status === VALIDADO)) {
            return `$${dosDecimales(rowData["precio_libra_real"])}`;
        }
        else if (rowData.status === "reproceso") {
            return `$${rowData?.["precio_con_reproceso"] || 0}`;
        }
        else if (rowData.status === "rechazo") {
            return `$${rowData?.["precio_con_rechazo"] || 0}`;
        }
        return "N/A";
    }
    const templateStatus = (rowData, column) => {
        if (rowData.status === VALIDADO) { return <p className="precio-status aprobado"><span></span>Validado</p> }
        else if (rowData.status === "reproceso") { return <p className="precio-status reproceso"><span></span>Reproceso</p> }
        else if (rowData.status === "rechazo") { return <p className="precio-status rechazo"><span></span>Rechazo</p> }
        else return <p className="precio-status pendiente"><span></span>Pendiente</p>
    }
    const templateInvoice = (rowData, column) => {
        if (!rowData?.invoice?.id) { return null; }
        else return (
            <div className="medical-doc-row">
                <a className="medical-doc-link" href={`${BASE_URL}/rechazos/form_validacion_invoice/${rowData.invoice.id}`} target="_blank" >
                    <FontAwesomeIcon icon={faFileSignature} />
                    <span className="text">#{rowData.invoice.invoice}</span>
                </a>
            </div>)
    }
    const handleCheckbox = (e, presentacion_ref, manifiestoId, invernadero_ref) => {
        const valueItem = `${presentacion_ref}-${manifiestoId}-${invernadero_ref}`
        const newMan = { ...manifiestosSeleccionadosState, [valueItem]: e.checked }
        if (!newMan || !Object.entries(newMan)?.some(([llave, valor]) => valor == true)) {
            setFiltroGrupoPresentaciones(OPTION_TODOS_DEFAULT)
        }
        setManifiestosSeleccionadosState(newMan)
    }
    const handleCheckboxSelectAll = (e) => {
        const newManifiestosSeleccionadosState = { ...manifiestosSeleccionadosState }
        manifiestos.forEach(manifiesto => {
            const { presentacion_ref, manifiestoId, invernadero_ref, status } = manifiesto
            const disabled = status === VALIDADO ||
                status === CON_RECHAZO ||
                //status === "aprobado" ||
                status === "rechazo"
            if (disabled) return
            const itemName = `${presentacion_ref}-${manifiestoId}-${invernadero_ref}`
            newManifiestosSeleccionadosState[itemName] = e.checked
        })
        if (!newManifiestosSeleccionadosState || !Object.entries(newManifiestosSeleccionadosState)?.some(([llave, valor]) => valor == true)) {
            setFiltroGrupoPresentaciones(OPTION_TODOS_DEFAULT)
        }
        setManifiestosSeleccionadosState(newManifiestosSeleccionadosState)
        setSelectAllValue(e.checked)
    }
    const checkboxColumnTemplate = (rowData) => {
        const { presentacion_ref, manifiestoId, invernadero_ref } = rowData
        const valueItem = `${presentacion_ref}-${manifiestoId}-${invernadero_ref}`
        const checked = manifiestosSeleccionadosState?.[valueItem]
        const { status } = rowData
        const disabled = status === VALIDADO ||
            status === CON_RECHAZO ||
            //status === "aprobado" ||
            status === "rechazo";
        if (disabled) return null;
        else return (
            <div className={`p-flex p-justify-content-center ${disabled ? "disabled-check" : ""}`}>
                <Checkbox checked={disabled || checked} disabled={disabled} onChange={e => handleCheckbox(e, presentacion_ref, manifiestoId, invernadero_ref)} />
            </div>
        )
    }
    const checkboxSelectAllTemplate = () => {
        return (
            <div className='p-flex p-justify-content-center'>
                <Checkbox checked={checkboxSelectAllDisable ? false : selectAllValue} onChange={handleCheckboxSelectAll} disabled={checkboxSelectAllDisable} />
            </div>
        )
    }

    const templateCajas = (rowData) => {
        return <div>{rowData.cajas_orden || 0}<span className="label-unidad">cajas</span> </div>
    }
    const hideFormulario = (params) => {
        setMostrarFormulario(false);
    }
    const handleButtonClick = (params) => {
        setMostrarFormulario(true);
    }
    const handleEnviarItemsSiguienteSemana = async (e) => {
        const manifiestosSeleccionados = obtenerDeEstadoManifiestosSeleccionados()
        const manifiestosSeleccionadosData = manifiestosSeleccionados.map(manifiesto => {
            const [presentacion_ref, manifiestoId, invernadero_ref] = manifiesto.split("-")
            const manifiestoData = manifiestos.find(manf =>
                manf.presentacion_ref === presentacion_ref &&
                manf.manifiestoId === manifiestoId &&
                manf.invernadero_ref === invernadero_ref)
            return manifiestoData
        })
        const manifiestosADesplazarPromises = manifiestosSeleccionadosData.map(manifiesto => {
            return desplazarItemManifiesto(ciclo, manifiesto);
        })
        try {
            setCargando(true)
            const result = await Promise.all(manifiestosADesplazarPromises)
            setTriggerUpdate(true)

        } catch (error) {
            setCargando(false)
            console.log("ERROR AL DESPLAZAR ITEMS: ", error)
        }
    }
    const handleShowModalConfirmacion = (e) => {
        setShowModalConfirmacion(!showModalConfirmacion)
    }
    const handleButtonValidarSeleccionados = () => {
        const manifiestosSeleccionados = obtenerDeEstadoManifiestosSeleccionados()
        const manifiestosSeleccionadosData = manifiestosSeleccionados.map(manifiesto => {
            const [presentacion_ref, manifiestoId, invernadero_ref] = manifiesto.split("-")
            const manifiestoData = manifiestos.find(manf =>
                manf.presentacion_ref === presentacion_ref &&
                manf.manifiestoId === manifiestoId &&
                manf.invernadero_ref === invernadero_ref)
            return manifiestoData
        })
        let producto = null
        if (filtroGrupoPresentaciones.producto_ref === medleysGeneral.id) {
            producto = medleysGeneral
        } else {
            producto = productosBases.find(prod => prod.id === filtroGrupoPresentaciones.producto_ref)
        }
        const cliente = clientes.find(cli => cli.id === filtroGrupoPresentaciones.cliente_ref)

        const dataFormularioValidacionInvoice = {
            producto,
            cliente,
            manifiestosSeleccionadosData
        }

        props.history.push({
            pathname: RECHAZOS.formValidacionInvoiceCreateInvoice,
            state: dataFormularioValidacionInvoice
        })
    }
    const guardarManifiesto = async (item, original) => {
        if (original.status === NO_APROBADO) {
            setMensajeError("NO SE PUEDE CAMBIAR STATUS");
            return;
        }
        setCargando(true);
        let ahora = Math.round(Date.now() / 1000);
        item.fecha_asignacion = ahora;
        try {
            let x = await actualizarItemManifiesto(ciclo, item, original, manifiestosOrig);
            setTriggerUpdate(true);
            hideFormulario();
        } catch (error) {
            console.log("ERROR GM: ", error);
        } finally {
            setCargando(false);
        }
    }
    const desplazar = async (item, original) => {
        try {
            let x = await desplazarItemManifiesto(ciclo, item, original);
            setTriggerUpdate(true);
            hideFormulario();

        } catch (error) {
            console.log("ERROR DESPLAZAR: ", error)
        }
    }

    const handleDesplazar = async (item, original) => {
        activarModalConfirmacionDesplazamiento(item, original);
    }

    const obtenerDeEstadoManifiestosSeleccionados = () => {
        const manifiestosSeleccionadosEntries = Object.entries(manifiestosSeleccionadosState).filter((manf) => manf[1])
        const manifistosKeys = manifiestosSeleccionadosEntries.map(manf => manf[0])
        return manifistosKeys
    }

    const FooterTotalesTemplate = () => {
        return (
            <div>
                <span>Suma de cajas seleccionadas: {separadoComas(sumaDeCajasSeleccionadas)}</span>
            </div>
        )
    }

    const checarSiTodosManifiestosEstanSeleccionados = () => {
        if (manifiestos.length === 0) return false
        let todosEstanSeleccionados = true
        manifiestos.forEach(manifiesto => {
            const { presentacion_ref, manifiestoId, invernadero_ref, status } = manifiesto
            const disabled = status === VALIDADO ||
                status === CON_RECHAZO ||
                //status === "aprobado" ||
                status === "rechazo"
            if (disabled) return
            const itemName = `${presentacion_ref}-${manifiestoId}-${invernadero_ref}`
            if (!manifiestosSeleccionadosState[itemName]) todosEstanSeleccionados = false
        })
        return todosEstanSeleccionados
    }

    const checarSiNoHayManifiestosSeleccionados = () => {
        const manifiestosEnElEstado = Object.entries(manifiestosSeleccionadosState)
        if (manifiestosEnElEstado.length === 0) return false
        let hayManifiestosSelecionados = false
        manifiestosEnElEstado.forEach(manifiesto => {
            const [key, value] = manifiesto
            if (value) hayManifiestosSelecionados = true
        })
        return hayManifiestosSelecionados
    }

    const { RECHAZOS } = NAVEGACION

    const disableButtonValidarSeleccionados = !checarSiNoHayManifiestosSeleccionados()

    const modalConfirmacionData = {
        mensaje: "¿Esta segundo de transferir ítems seleccionados a la siguiente semana?",
        aceptar: () => {
            setShowModalConfirmacion(false)
            handleEnviarItemsSiguienteSemana()
        },
        cancelar: () => setShowModalConfirmacion(false)
    }

    return (
        <ContenedorPrincipal>
            <ContenedorHeader col="p-col p-col-auto" titulo="Validación de precios" iconos="reporte-empaque-icon" />
            <ContenedorBotonesTop>
                <RowBotonesTop>
                    <Button label="Validar seleccionados" disabled={disableButtonValidarSeleccionados} onClick={handleButtonValidarSeleccionados} />
                    <Button label="Enviar seleccionados a la siguiente semana" disabled={disableButtonValidarSeleccionados} onClick={handleShowModalConfirmacion} />
                </RowBotonesTop>
            </ContenedorBotonesTop>
            <SeccionFiltros>
                <ContenedorFiltroBusqueda label="caja de busqueda" for="search" col={3}>
                    <FontAwesomeIcon icon={faSearch} />
                    <InputText type="search" value={cajaBusqueda} onChange={e => setCajaBusqueda(e.target.value)} placeholder="Buscar" />
                </ContenedorFiltroBusqueda>
                <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />

                <ContenedorFiltro label="cliente" for="clientes" col={3}>
                    <Dropdown placeholder='Selecciona un cliente' optionLabel="nombre" dataKey="id" options={clientes} value={filtroCliente} onChange={e => setFiltroCliente(e.value)} />
                </ContenedorFiltro>
                <ContenedorFiltro label="invernaderos" for="invernaderos" col={3}>
                    <MultiSelect placeholder='Selecciona invernaderos' optionLabel="nombre" dataKey="id" options={invernaderos} value={filtroInvernadero} onChange={e => setFiltroInvernadero(e.value)}
                        selectedItemsLabel={`${filtroInvernadero.length} invernaderos seleccionados`} />
                </ContenedorFiltro>
                <ContenedorFiltro label="producto" for="producto" col={3}>
                    <MultiSelect placeholder='Selecciona productos' optionLabel="nombre" dataKey="id" options={productos} value={filtroProducto} onChange={e => setFiltroProducto(e.value)}
                        selectedItemsLabel={`${filtroProducto.length} productos seleccionados`} />
                </ContenedorFiltro>
                <ContenedorFiltro label="grupo de presentaciones" for="grupo_presentaciones" col={3}>
                    <Dropdown placeholder='Selecciona grupos' optionLabel="nombre" dataKey="id" options={gruposPresentacionesOpciones} value={filtroGrupoPresentaciones}
                        onChange={e => setFiltroGrupoPresentaciones(e.value)} />
                </ContenedorFiltro>
                <ContenedorFiltro label="presentaciones" for="presentaciones" col={3}>
                    <MultiSelect placeholder='Selecciona presentaciones' optionLabel="presentacion" dataKey="id" options={presentacionesOpciones} value={filtroPresentaciones}
                        onChange={e => setFiltroPresentaciones(e.value)} selectedItemsLabel={`${filtroPresentaciones.length} presentaciones seleccionadas`} maxSelectedLabels={1} />
                </ContenedorFiltro>
            </SeccionFiltros>

            <ContenedorTabla>
                <DataTable value={manifiestos} globalFilter={cajaBusqueda} scrollable={true} footer={<FooterTotalesTemplate />}>
                    <Column style={{ width: "4em" }} key={"checkboxes"} field={"checkboxes"} header={checkboxSelectAllTemplate()} body={checkboxColumnTemplate} />
                    <Column field={"presentacion"} header={"presentación"} sortable />
                    <Column field={"manifiesto"} header={"Manifiesto"} sortable />
                    <Column field={"fecha_envio"} header={"Fecha envío"} body={templateFecha} sortable />
                    <Column field={"cajas_orden"} header={"Cajas"} body={templateCajas} sortable />
                    <Column field={"status"} header={"Estatus"} sortable body={templateStatus} />
                    <Column field={"precio_libra_real"} header={"Precio por libra real"} sortable body={templatePrecio} />
                    <Column field={"nombre_invernadero"} header={"Invernadero"} sortable />
                    <Column field={"invoice"} header={"Invoice"} sortable body={templateInvoice} />
                </DataTable>
            </ContenedorTabla>

            <ModalFormulario show={mostrarFormulario} onHide={hideFormulario} guardar={guardarManifiesto} mensajeError={mensajeError}
                desplazar={desplazar} budgets={budgets} gruposPresentaciones={gruposPresentacionesOrig} semana={week?.time} />
            <ModalConfirmacion show={showModalConfirmacion} header="Confirmación de acción" data={modalConfirmacionData} closable={false} />
        </ContenedorPrincipal>)
}
export default Consulta//310