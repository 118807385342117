import * as firebase from "firebase/app";
import "firebase/firestore";
import {uploadStatistics} from '../Estadisticas'

function registrarInvocaciones(count){
    uploadStatistics(count)
}

export const obtenerActividadesMantenimiento = async (params) => {
    const db = firebase.firestore();
    let snap = await db.collection("actividades_mantenimiento").get();
    registrarInvocaciones(snap.size)
    return snap.docs.map(doc => ({...doc.data(),  id: doc.id }));
}
