import React, { useState, useGlobal, useEffect } from 'reactn';
import { withRouter } from 'react-router-dom';
import DatoCard from '../common/cards/DatoCard';
import HTGCard from '../common/cards/HTGCard';
import ClipLoader from 'react-spinners/ClipLoader';
import CardHeader from '../common/cards/CardHeader';

function InvernaderoCard(props) {
  const [invernadero, setInvernadero] = useGlobal("invernadero");
  const [usuario,] = useGlobal("usuario");
  const puedeConsultar = (params) => {
    return (usuario && ["Administrador", "Administrador RH", "Auxiliar RH", "Personal Administrativo",
      "Administrador Empaque", "Auxiliar Empaque", "Grower", "Auxiliar Grower"].includes(usuario.rol))
  }
  const consultar = () => {
    if (puedeConsultar()) {
      setInvernadero(props.invernadero);
      props.history.push("/consultar_nomina");
    }
  }
  let lineasTotales = 0;
  const [porcActividades, setPorcActividades] = useState(0);
  useEffect(el => {

    let asignacion_lineas = props.invernadero?.asignacion_lineas || [];
    let total = asignacion_lineas.reduce((acc, curr) => {
      let dato = curr["totales"].split(" ")[0]
      if (dato) {
        dato = parseInt(dato);
        if (!isNaN(dato)) {
          return acc + dato;
        } else {
          return acc;
        }
      }
    }, 0)
    lineasTotales = total;
    setPorcActividades(Math.round(100 * props.invernadero?.porcActividades / (lineasTotales)));
  })
  const header = <CardHeader
    invernaderoVirtual={props.invernadero}
    nombreInvernaderoFisico={props.invernadero?.invernaderoFisico?.nombre || ""} />
  return (
    <HTGCard onClick={consultar} header={header} headerClassName={`htg-card bor-${props.invernadero?.color} ${props.invernadero?.status}`}>
      {props.home && props.cargandoHome
        ? <div className="card-spinner-centered">
          <ClipLoader sizeUnit="px" size={100} color="#003E66" loading={true} />
        </div>
        : <>
          <DatoCard label="EMPLEADOS" valor={`${props.invernadero?.cuentaEmpleados}/${props.invernadero?.numero_maximo_trabajadores}`} />
          <DatoCard label="ACTIVIDADES" valor={`${porcActividades} %`} />
          <DatoCard label="HORAS TRABAJADAS" valor={`${Math.round(props.invernadero?.horasTrabajadas)} %`} />
        </>}
    </HTGCard>);
}
export default withRouter(InvernaderoCard);//68