import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React from 'react'
import ContenedorTabla from '../../../common/ContenedorTabla';
const CategoriasMantenimientoTabla = (props) => {
    return (
        <ContenedorTabla titulo="">
            <DataTable key={"acts"} globalFilter={props.globalFilter} selectionMode="single" className="tabla-actividades"
                emptyMessage="No hay resultados" value={props.tablaLineasCategorias} onRowClick={props.editarActividad} >
                <Column className="col__label  p-col-3 " field="classname" body={props.iconBody} />
                <Column className="p-col-3 " field="indice" header="Índice" sortable />
                <Column className='w-100' field="nombre" header="Nombre" sortable />
                <Column className='w-100' field="numSubcategorias" header="# subcategorías" sortable />
                <Column className='w-100' field="numActividades" header="# actividades" sortable />
            </DataTable>
        </ContenedorTabla>)
}
export default CategoriasMantenimientoTabla;