import React, { useGlobal } from "reactn"
import { getNombrePorID } from "../../../util/functions";

const CardOrdenVenta = (props) => {
    const [clientes] = useGlobal("clientesDelCiclo");
    return (
        <div className="card-mtto HTF">
            <div className="card-mtto-header">
                <div className="card-mtto-icon empaque"></div>
                <div className="card-mtto-general">
                    <div className="card-mtto-empresa">Orden de venta #{props.indexOrden} - {props.ordenVenta?.suma_cajas_total} cajas</div>
                    <div className="card-mtto-categoria">{getNombrePorID(clientes, props.ordenVenta?.cliente_ref)}</div>
                </div>
            </div>

            {/*Los cards de cada items del orden de venta*/}
            {props.children}

        </div>)
}
export default CardOrdenVenta;