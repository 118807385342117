import * as firebase from "firebase/app";
import "firebase/firestore";
import { obtenerInicioDia, obtenerDiasDeSemana, 
    obtenerIndiceDiaSemana, obtenerHora } from './fechas';
import {uploadStatistics} from './Estadisticas'

function registrarInvocaciones(count){
    uploadStatistics(count)
}
export const obtenerOrdenesSemana = (ciclo,semana) => {
    
    return new Promise((resolve, reject) => {
        const db = firebase.firestore();
        const dias = obtenerDiasDeSemana(semana)
        db.collection("ciclos").doc(ciclo).collection("ordenes_venta")
        .where("fecha_envio",">=",dias[0].toString()).where("fecha_envio","<=", dias[5].toString()).get().then((result) => {
            registrarInvocaciones(result.size)
            let rows =[];
            result.forEach(item => {
                let data = item.data();
                data.id = item.id;
                rows.push(data)
            });
            resolve(rows);
        }).catch((err) => {
            reject(err);
        });
    });
     
}
export const obtenerOrden = async (cicloId, ordenId) => {
    
    const db = firebase.firestore();
    let snap = await db.collection("ciclos").doc(cicloId).collection("ordenes_venta").doc(ordenId).get();
    registrarInvocaciones(snap.size)
    if(snap.exists){
        return {id:ordenId, ...snap.data()}
    }else {
        return {empty:true}
    }
}
