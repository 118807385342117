import React from "reactn";
import { getRegistrosCalidadQUERY } from "service/repositorios/Calidad.queries";
import { segundosDia, segundosSemana } from "../../constants";
import { AUTO, FINAL, INICIAL, MANUAL, PESOS } from "service/constants/Calidad.const";
import { DeepClone, dosDecimales, getObjetoPorID, ordenarArreglaDeObjetosPorLlaveFloat, parseFloatHTG, sumarNumerosArrayPorCampo } from "util/functions";
import { getGrupoPorIdDePresentacion, hayDatosGrupoPresentacion } from "./Presentaciones.helpers.";
import { ordenarInvernaderosVirtualesPorIndexFisicoYNombre } from "service/Invernaderos";

//Dia
export const getRegistrosCalidadInicialDia = (ciclo, invernadero, dia) => getRegistrosCalidadQUERY(ciclo, invernadero, dia, INICIAL, segundosDia);
export const getRegistrosCalidadFinalDia = (ciclo, invernadero, dia) => getRegistrosCalidadQUERY(ciclo, invernadero, dia, FINAL, segundosDia);
export const getRegistrosCalidadPesosDia = (ciclo, invernadero, dia) => getRegistrosCalidadQUERY(ciclo, invernadero, dia, PESOS, segundosDia);
//Semana
export const getRegistrosCalidadInicialSemana = (ciclo, invernadero, semana) => getRegistrosCalidadQUERY(ciclo, invernadero, semana, "inicial", segundosSemana);
export const getRegistrosCalidadFinalSemana = (ciclo, invernadero, semana) => getRegistrosCalidadQUERY(ciclo, invernadero, semana, "final", segundosSemana);
export const getRegistrosCalidadPesosSemana = (ciclo, invernadero, semana) => getRegistrosCalidadQUERY(ciclo, invernadero, semana, "pesos", segundosSemana);
//Ciclo
export const getRegistrosCalidadPesosCiclo = (cicloObj, invernadero, semana) =>
    getRegistrosCalidadQUERY(cicloObj.id, invernadero, cicloObj.semana_inicio, "pesos", ((semana - cicloObj.semana_inicio) + segundosSemana));


const resumenInicial = {
    muestras: 0, normales: 0, altos: 0, bajos: 0,//Totales de muestras de cada tipo de calidad
    muestrasSumaKilos: 0, normalesSumaKilos: 0, altosSumaKilos: 0, bajosSumaKilos: 0,//Suma de kilos de las muestras de cada tipo
}

//Pesos diarios
export const getResumenReporteGeneralPesosDiarios = (registrosCalidadCiclo, registrosCalidadSemana, invernaderos = [], presentaciones = [], grupos = []) => {
    try {
        const [resumenCalidadPesosPresentacionesCiclo, resumenCalidadPesosGruposCiclo]
            = getResumenCalidadPresentaciones(registrosCalidadCiclo, presentaciones, grupos, invernaderos);//Presentaciones del CICLO
        const [resumenCalidadPesosPresentacionesSemana, resumenCalidadPesosGruposSemana, presentacionesPresentes, invernaderosPresentes, gruposPresentes]
            = getResumenCalidadPresentaciones(registrosCalidadSemana, presentaciones, grupos, invernaderos);//Presentaciones de la SEMANA

        const comentarios = extraerComentarios(registrosCalidadSemana);
        ordenarArreglaDeObjetosPorLlaveFloat(comentarios, "momento");
        ordenarInvernaderosVirtualesPorIndexFisicoYNombre(invernaderosPresentes)

        const reporte = {
            comentarios, presentacionesPresentes, invernaderosPresentes, gruposPresentes, resumenCalidadPesosGruposCiclo, resumenCalidadPesosGruposSemana,
            resumenCalidadPesosPresentacionesSemana, resumenCalidadPesosPresentacionesCiclo,
        };
        console.log("99 - END getResumenReporteGeneralPesosDiarios - ", reporte);
        return reporte;
    } catch (error) {
        console.log("ERROR getResumenCalidadPesosSemanaInvernaderosTodos: ", error);
    }
}
const getResumenAcumuladoCalidadPresentacionesCiclo = (registrosCalidad, presentaciones, grupos) => getResumenCalidadPresentaciones(registrosCalidad, presentaciones, grupos, null);
const getResumenCalidadPresentaciones = (registrosCalidad, presentaciones, grupos, invernaderos) => {
    const resumenCalidadPesos = {}, resumenCalidadPesosGrupos = {};
    const presentacionesPresentes = [], invernaderosPresentes = [], gruposPresentes = [];

    presentaciones.forEach(presentacion => {
        const registrosFiltrados = registrosCalidad?.filter(el => el.presentacion_ref === presentacion.id) || [];

        if (registrosFiltrados?.length > 0) {
            presentacionesPresentes.push(presentacion);
            const resumenCalidadSumas = getResumenDeSumasCalidadPesosDePresentacion(presentacion, registrosFiltrados);
            const sumaKg = resumenCalidadSumas.resumenTotal.muestrasSumaKilos || 0;

            const grupo = grupos?.find(el => el.presentaciones_refs?.includes(presentacion.id))

            if (grupo) {
                const noHayRepetidosGrupos = !gruposPresentes?.some(el => el.id === grupo.id)
                if (noHayRepetidosGrupos) { gruposPresentes.push(grupo); }

                //Acumulado del grupo
                if (!resumenCalidadPesosGrupos[grupo.id]) {
                    resumenCalidadPesosGrupos[grupo.id] = { ...DeepClone(resumenCalidadSumas), sumaKg, grupo, nombre: grupo.nombre };
                } else {
                    resumenCalidadPesosGrupos[grupo.id].sumaKg += sumaKg
                    Object.entries(resumenCalidadSumas).forEach(([llaveEmpaque, datosEmpaque]) => {
                        Object.entries(datosEmpaque).forEach(([llave, valor]) => {
                            resumenCalidadPesosGrupos[grupo.id][llaveEmpaque][llave] += valor
                        })
                    })
                }
                //Invernaderos datos del grupo
                if (invernaderos?.length) {
                    if (!resumenCalidadPesosGrupos[grupo.id].invernaderoDatos) { resumenCalidadPesosGrupos[grupo.id].invernaderoDatos = {}; }

                    invernaderos.forEach(invernadero => {
                        const registrosFiltradosPorInv = registrosFiltrados?.filter(registro => registro.invernadero_ref === invernadero.id);
                        const resumenCalidadSumasInvernadero = getResumenDeSumasCalidadPesosDePresentacion(presentacion, registrosFiltradosPorInv);
                        const hayDatosRegistrados = resumenCalidadSumasInvernadero?.resumenTotal?.muestras;
                        const grupoInvernaderoDatos = resumenCalidadPesosGrupos[grupo.id]?.invernaderoDatos;

                        if (hayDatosRegistrados && !grupoInvernaderoDatos[invernadero.id]) {
                            grupoInvernaderoDatos[invernadero.id] = { ...resumenCalidadSumasInvernadero };
                        }
                        else if (hayDatosRegistrados) {
                            Object.entries(resumenCalidadSumasInvernadero).forEach(([llaveEmpaque, datosEmpaque]) => {
                                Object.entries(datosEmpaque).forEach(([llave, valor]) => {
                                    grupoInvernaderoDatos[invernadero.id][llaveEmpaque][llave] += valor
                                })
                            })
                        }
                    })
                }
            }

            resumenCalidadPesos[presentacion.id] = { ...DeepClone(resumenCalidadSumas), sumaKg, presentacion, nombre: presentacion.presentacion };
            if (invernaderos?.length) {
                resumenCalidadPesos[presentacion.id].invernaderoDatos = {};
                invernaderos.forEach(invernadero => {
                    const registrosFiltradosPorInv = registrosFiltrados?.filter(registro => registro.invernadero_ref === invernadero.id);
                    const resumenCalidadSumasInvernadero = getResumenDeSumasCalidadPesosDePresentacion(presentacion, registrosFiltradosPorInv);
                    const hayDatosRegistrados = resumenCalidadSumasInvernadero?.resumenTotal?.muestras;
                    const noHayRepetidos = !invernaderosPresentes?.some(inv => inv.id === invernadero.id)

                    if (hayDatosRegistrados) {
                        resumenCalidadPesos[presentacion.id].invernaderoDatos[invernadero.id] = resumenCalidadSumasInvernadero;
                        if (noHayRepetidos) { invernaderosPresentes.push(invernadero); }
                    }
                })
            }
        }
    })
    console.log(`****** getResumenCalidadPresentaciones --- ${invernaderos ? "SEMANA" : "CICLO"} --- `, resumenCalidadPesos);
    return [resumenCalidadPesos, resumenCalidadPesosGrupos, presentacionesPresentes, invernaderosPresentes, gruposPresentes];
}
const getResumenDeSumasCalidadPesosDePresentacion = (presentacion, registrosPesosColeccion = []) => {
    if (!presentacion?.id) { return null; }

    const { peso_minimo = 0, peso_maximo = 0 } = presentacion;
    const pesoMinimo = parseFloatHTG(peso_minimo), pesoMaximo = parseFloatHTG(peso_maximo);
    let resumenManual = { ...resumenInicial }, resumenAuto = { ...resumenInicial };

    registrosPesosColeccion.forEach(pesosDoc => {
        if (pesosDoc.tipo_empaque === MANUAL) { resumenManual = sumarMuestraDatos(resumenManual, pesosDoc, pesoMinimo, pesoMaximo); }
        else if (pesosDoc.tipo_empaque === AUTO) { resumenAuto = sumarMuestraDatos(resumenAuto, pesosDoc, pesoMinimo, pesoMaximo); }
    })
    const resumenTotal = sumarEmpaqueResumenTotal(resumenManual, resumenAuto);
    return { resumenTotal, resumenAuto, resumenManual };
}
const sumarMuestraDatos = (resumenPresentaciones, pesosDoc = {}, pesoMinimo, pesoMaximo) => { //Sumar datos y desviaciones
    if (!pesosDoc?.pesos) return {};
    pesosDoc.pesos.forEach(datos => {
        const pesosMuestra = parseFloatHTG(datos.peso);

        if (pesosMuestra > pesoMaximo) {
            resumenPresentaciones.altosSumaKilos += pesosMuestra;
            resumenPresentaciones.altos++;
        } else if (pesosMuestra < pesoMinimo) {
            resumenPresentaciones.bajosSumaKilos += pesosMuestra;
            resumenPresentaciones.bajos++;
        } else {
            resumenPresentaciones.normalesSumaKilos += pesosMuestra;
            resumenPresentaciones.normales++;
        }
        resumenPresentaciones.muestrasSumaKilos += pesosMuestra;
        resumenPresentaciones.muestras++;
    })
    return resumenPresentaciones;
}
const sumarEmpaqueResumenTotal = (resumenManual, resumenAutomatico) => {
    const resumenTotal = {};
    Object.entries(resumenManual).forEach(([llave, valor]) => {
        resumenTotal[llave] = resumenAutomatico[llave] + valor;
    })
    return resumenTotal;
}




//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


const getRowsPresentaciones = (resumenCalidadPesosPresentacionesCiclo) => {
    /*     if(!resumenCalidadPesosPresentacionesCiclo){return [];}
        const rowsProcesados = Object.entries(resumenCalidad).forEach(([presentacionId, datos]) => {
            
        }); */
}
const getPresentacionPromediado = (grupo, presentaciones = []) => {
    if (!presentaciones.length) { return null; }
    const presentacionesDelGrupo = grupo?.presentaciones_refs?.map(presentacionId => getObjetoPorID(presentaciones, presentacionId));
    const pesoNetoPresentacionSuma = sumarNumerosArrayPorCampo(presentacionesDelGrupo, "peso_neto_presentacion");
    const total = grupo?.presentaciones_refs?.length || 0;
    const pesoNetoPresentacionPromedio = total > 0 ? (pesoNetoPresentacionSuma / total) : 0;

    //console.log("pesoNetoPresentacionPromedio: ",pesoNetoPresentacionPromedio)
    const presentacionBase = DeepClone(presentacionesDelGrupo[0]);
    return { ...presentacionBase, peso_neto_presentacion: `${pesoNetoPresentacionPromedio}` };
}
const getResumenCalidadPesosDePresentacion = (presentacion, registrosPesosColeccion = []) => {
    //console.log("1 - getResumenCalidadPesosDePresentacion: ", {presentacion, registrosPesosColeccion});
    if (!presentacion?.id) { return null; }
    const { peso_minimo = 0, peso_maximo = 0 } = presentacion;
    const pesoMinimo = parseFloatHTG(peso_minimo), pesoMaximo = parseFloatHTG(peso_maximo);
    let resumenManual = { ...resumenInicial }, resumenAuto = { ...resumenInicial };

    registrosPesosColeccion.forEach(pesosDoc => {
        if (pesosDoc.tipo_empaque === MANUAL) { resumenManual = sumarMuestraDatos(resumenManual, pesosDoc, pesoMinimo, pesoMaximo); }
        else if (pesosDoc.tipo_empaque === AUTO) { resumenAuto = sumarMuestraDatos(resumenAuto, pesosDoc, pesoMinimo, pesoMaximo); }
    })
    let resumenTotal = sumarEmpaqueResumenTotal(resumenManual, resumenAuto);
    //console.log("7 - b4 promedios - ", { resumenTotal, resumenAuto, resumenManual });

    resumenManual = calcularMuestraPromedios(resumenManual);
    resumenAuto = calcularMuestraPromedios(resumenAuto);
    resumenTotal = calcularMuestraPromedios(resumenTotal);
    //console.log("8 - b4 porcentajes - ", { resumenTotal, resumenAuto, resumenManual });

    resumenManual = calcularMuestraPorcentajeDesviaciones(resumenManual, presentacion);
    resumenAuto = calcularMuestraPorcentajeDesviaciones(resumenAuto, presentacion);
    resumenTotal = calcularMuestraPorcentajeDesviaciones(resumenTotal, presentacion);

    //console.log("9 - END - ", { resumenTotal, resumenAuto, resumenManual })
    return { resumenTotal, resumenAuto, resumenManual };
}

export const calcularMuestraPromedios = (datos = {}) => {
    datos.muestrasKilosPromedio = !datos.muestras ? 0 : (datos.muestrasSumaKilos / datos.muestras);
    datos.normalesKilosPromedio = !datos.normales ? 0 : (datos.normalesSumaKilos / datos.normales);
    datos.altosKilosPromedio = !datos.altos ? 0 : (datos.altosSumaKilos / datos.altos);
    datos.bajosKilosPromedio = !datos.bajos ? 0 : (datos.bajosSumaKilos / datos.bajos);
    return datos;
}
export const calcularMuestraPorcentajeDesviaciones = (datosPres, presentacion) => {
    const proporcionNormales = !datosPres.muestras ? 0 : (datosPres.normales / datosPres.muestras);
    const proporcionAltos = !datosPres.muestras ? 0 : (datosPres.altos / datosPres.muestras);
    const proporcionBajos = !datosPres.muestras ? 0 : (datosPres.bajos / datosPres.muestras);

    const porcententajeBajos = Math.round(100 * proporcionBajos)
    const porcententajeAltos = Math.round(100 * proporcionAltos)
    const porcententajeNormales = Math.round(100 * proporcionNormales)

    const { peso_neto_presentacion = 0 } = presentacion;
    const pesoNetoPresentacion = parseFloatHTG(peso_neto_presentacion);

    const desviacionKilosNormales = (datosPres.normalesKilosPromedio - pesoNetoPresentacion) / pesoNetoPresentacion;
    const desviacionKilosAltos = (datosPres.altosKilosPromedio - pesoNetoPresentacion) / pesoNetoPresentacion;
    const desviacionKilosBajos = (datosPres.bajosKilosPromedio - pesoNetoPresentacion) / pesoNetoPresentacion;

    const desviacionMuestras = (datosPres.muestrasKilosPromedio - pesoNetoPresentacion) / pesoNetoPresentacion;
    let porcentajeDesviacionMuestras = dosDecimales(100 * desviacionMuestras);
    let porcentajeDesviacionNormales = dosDecimales(100 * desviacionKilosNormales);
    let porcentajeDesviacionAltos = dosDecimales(100 * desviacionKilosAltos);
    let porcentajeDesviacionBajos = dosDecimales(100 * desviacionKilosBajos);
    porcentajeDesviacionMuestras = porcentajeDesviacionMuestras === -100 ? '-' : porcentajeDesviacionMuestras;
    porcentajeDesviacionNormales = porcentajeDesviacionNormales === -100 ? '-' : porcentajeDesviacionNormales;
    porcentajeDesviacionAltos = porcentajeDesviacionAltos === -100 ? '-' : porcentajeDesviacionAltos;
    porcentajeDesviacionBajos = porcentajeDesviacionBajos === -100 ? '-' : porcentajeDesviacionBajos;
    if (isNaN(porcentajeDesviacionMuestras)) { porcentajeDesviacionMuestras = 0; }

    return {
        ...datosPres, presentacion, porcentajeDesviacionNormales, porcentajeDesviacionAltos, porcentajeDesviacionBajos, porcentajeDesviacionMuestras,
        pesoNetoPresentacion, proporcionBajos, proporcionAltos, proporcionNormales, porcententajeBajos, porcententajeAltos, porcententajeNormales

    };
}

export const extraerComentarios = (listaRegistros = {}) => {
    const registrosConComentarios = listaRegistros?.filter(registro => registro.comentario) || [];
    const comentarios = registrosConComentarios?.map(registro => ({ momento: registro.momento, comentario: registro.comentario })) || [];
    return comentarios;
}














//////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////
//Por invernaderos
const getResumenCalidadPesosInvernaderos = (registrosPesos = [], presentaciones = [], invernaderos = []) => {
    const resumenCalidadPesosInvernaderos = {};
    registrosPesos.forEach((registros, i) => {
        const invernaderoId = invernaderos[i].id;
        resumenCalidadPesosInvernaderos[invernaderoId] = getResumenCalidadPesos(registros, presentaciones);
    });
    return resumenCalidadPesosInvernaderos;
}
//Por presentaciones
const getResumenCalidadPesosPresentaciones = (registrosPesos = [], presentaciones = [], invernaderos = []) => {
    const resumenCalidadPesosPresentaciones = {};

    const presentacionesIdsSet = registrosPesos.map(registro => registro.presentacion_ref);
    const presentacionPesosIds = [...new Set(presentacionesIdsSet)];

    presentacionPesosIds.forEach(presentacionId => {
        const registrosFiltrados = registrosPesos.filter(registro => registro.presentacion_ref === presentacionId);
        resumenCalidadPesosPresentaciones[presentacionId] = getResumenCalidadPesos(registrosFiltrados, presentaciones);
    })
    return getResumenCalidadPesos(registrosPesos, presentaciones);
}
//Por grupos de pres.
const getResumenCalidadPesosGrupos = (registrosPesos = [], presentaciones = [], gruposPresentaciones = []) => {
    const resumenCalidadPesosGrupos = {}, resumenCalidadPesosGrupos2 = {};

    //Arreglo de grupos UNICOS de los registros
    //OPTION 1
    const gruposPesosObjs = [];
    registrosPesos.forEach(registro => {
        const grupoPres = getGrupoPorIdDePresentacion(gruposPresentaciones, registro.presentacion_ref);
        if (grupoPres && !gruposPesosObjs.some(grupo => grupo.id === grupoPres.id)) { gruposPesosObjs.push(grupoPres); }
    })
    //OPTION 2
    /* const gruposObjsSet = registrosPesos?.map(registro => getGrupoPorIdDePresentacion(gruposPresentaciones, registro.presentacion_ref)) || [];
    const gruposObjsSetFiltrados = gruposObjsSet?.filter(grupo => grupo != null);
    const grupoIdssSet = gruposObjsSetFiltrados?.map(el => el.id);
    const gruposPesosIds = [...new Set(grupoIdssSet)];
    const gruposPesosObjs = gruposPesosIds?.map(grupoId => getObjetoPorID(gruposPresentaciones, grupoId)); */

    gruposPesosObjs.forEach(grupo => {
        const registrosFiltrados = registrosPesos.filter(registro => grupo.presentaciones_refs?.includes(registro.presentacion_ref));
        if (grupo?.id) resumenCalidadPesosGrupos[grupo.id] = getResumenCalidadPesos(registrosFiltrados, presentaciones);
    })
    gruposPresentaciones.forEach(grupo => {
        const registrosFiltrados = registrosPesos.filter(registro => grupo.presentaciones_refs?.includes(registro.presentacion_ref));
        if (grupo?.id && registrosFiltrados?.length > 0) resumenCalidadPesosGrupos2[grupo.id] = getResumenCalidadPesos(registrosFiltrados, presentaciones);
    })
    //console.log("CHECKING GRUPOs!!!!: ", { resumenCalidadPesosGrupos, resumenCalidadPesosGrupos2 })
    return resumenCalidadPesosGrupos;
}
//Por empacadora
const getResumenCalidadPesosEmpacadoras = (registrosPesos = [], presentaciones = [], gruposPresentaciones = [], empacadoras = []) => {
    const resumenCalidadPesosGrupos = {}, resumenCalidadPesosGrupos2 = {};

    //Arreglo de grupos UNICOS de los registros
    //OPTION 1
    const gruposPesosObjs = [];
    registrosPesos.forEach(registro => {
        const grupoPres = getGrupoPorIdDePresentacion(gruposPresentaciones, registro.presentacion_ref);
        if (grupoPres && !gruposPesosObjs.some(grupo => grupo.id === grupoPres.id)) { gruposPesosObjs.push(grupoPres); }
    })
    //OPTION 2
    /* const gruposObjsSet = registrosPesos?.map(registro => getGrupoPorIdDePresentacion(gruposPresentaciones, registro.presentacion_ref)) || [];
    const gruposObjsSetFiltrados = gruposObjsSet?.filter(grupo => grupo != null);
    const grupoIdssSet = gruposObjsSetFiltrados?.map(el => el.id);
    const gruposPesosIds = [...new Set(grupoIdssSet)];
    const gruposPesosObjs = gruposPesosIds?.map(grupoId => getObjetoPorID(gruposPresentaciones, grupoId)); */

    gruposPesosObjs.forEach(grupo => {
        const registrosFiltrados = registrosPesos.filter(registro => grupo.presentaciones_refs?.includes(registro.presentacion_ref));
        if (grupo?.id) resumenCalidadPesosGrupos[grupo.id] = getResumenCalidadPesos(registrosFiltrados, presentaciones);
    })
    gruposPresentaciones.forEach(grupo => {
        const registrosFiltrados = registrosPesos.filter(registro => grupo.presentaciones_refs?.includes(registro.presentacion_ref));
        if (grupo?.id && registrosFiltrados?.length > 0) resumenCalidadPesosGrupos2[grupo.id] = getResumenCalidadPesos(registrosFiltrados, presentaciones);
    })
    //console.log("CHECKING GRUPOs!!!!: ", { resumenCalidadPesosGrupos, resumenCalidadPesosGrupos2 })
    return resumenCalidadPesosGrupos;
}



const getResumenCalidadPesos = (registrosPesosColeccion = [], presentaciones = []) => {
    //console.log("1 - getResumenCalidadPesos: ", registrosPesosColeccion);
    let muestras = 0, muestrasSumaKilos = 0, muestrasKilosPromedio = 0;
    let normales = 0, normalesSumaKilos = 0, normalesKilosPromedio = 0;
    let altos = 0, altosSumaKilos = 0, altosKilosPromedio = 0;
    let bajos = 0, bajosSumaKilos = 0, bajosKilosPromedio = 0;

    let resumenPresentaciones = {};
    registrosPesosColeccion.forEach(pesosDoc => {
        const presentacion = getObjetoPorID(presentaciones, pesosDoc.presentacion_ref);
        const { id: presId, peso_minimo = 0, peso_maximo = 0 } = presentacion;
        const pesoMinimo = parseFloatHTG(peso_minimo), pesoMaximo = parseFloatHTG(peso_maximo);


        if (presId && !resumenPresentaciones[presId]) {
            resumenPresentaciones[presId] = {
                muestras: 0, muestrasSumaKilos: 0, muestrasKilosPromedio: 0, normales: 0, normalesSumaKilos: 0, normalesKilosPromedio: 0,
                altos: 0, altosSumaKilos: 0, altosKilosPromedio: 0, bajos: 0, bajosSumaKilos: 0, bajosKilosPromedio: 0
            }
        } else if (presId) {
            pesosDoc.pesos.forEach(datos => {
                const pesosMuestra = parseFloatHTG(datos.peso);

                //console.log("NOT-NOT-NOT--: ", pesosMuestra)

                //Desviaciones
                if (pesosMuestra > pesoMaximo) {
                    resumenPresentaciones[presId].altosSumaKilos += pesosMuestra;
                    resumenPresentaciones[presId].altos++;
                    altosSumaKilos += pesosMuestra;
                    altos++;
                } else if (pesosMuestra < pesoMinimo) {
                    resumenPresentaciones[presId].bajosSumaKilos += pesosMuestra;
                    resumenPresentaciones[presId].bajos++;
                    bajosSumaKilos += pesosMuestra;
                    bajos++;
                } else {
                    resumenPresentaciones[presId].normalesSumaKilos += pesosMuestra;
                    resumenPresentaciones[presId].normales++;
                    normalesSumaKilos += pesosMuestra;
                    normales++;
                }
                resumenPresentaciones[presId].muestrasSumaKilos += pesosMuestra;
                resumenPresentaciones[presId].muestras++;
                muestrasSumaKilos += pesosMuestra;
                muestras++;
            })
        }
    })
    resumenPresentaciones = calcularMuestraPromedios(resumenPresentaciones);
    return resumenPresentaciones;
}

/* const getResumenCalidadPesos = (registrosPesosColeccion = [], presentaciones = []) => {
    console.log("2 - getResumenCalidadPesos: ", registrosPesos);
    const muestras = 0, muestrasSumaKilos = 0, muestrasKilosPromedio = 0;
    const normales = 0, normalesSumaKilos = 0, normalesKilosPromedio = 0;
    const altos = 0, altosSumaKilos = 0, altosKilosPromedio = 0;
    const bajos = 0, bajosSumaKilos = 0, bajosKilosPromedio = 0;
} */



const procesarRowsPesosDiarios = (registrosInvernaderos = [], invernaderos, presentaciones) => {
    const tiempoInicio = Date.now();
    let count = 1;
    //console.log("datos: ",{invernaderos, presentaciones})
    const rowsProcesados = presentaciones.map((presentacion, indexPres) => {
        const row = {
            presentacion, presentacion_ref: presentacion.id, nombrePresentacion: presentacion.presentacion,
            accumuladoPesos: 0, accumuladoMuestras: 0, accumuladoPromedio: 0, indexPresentacion: indexPres
        };

        //Generar objeto inicial de la fila
        invernaderos.forEach(invernadero => {
            row[invernadero.id] = { sumaPesos: 0, sumaMuestras: 0, promedio: 0 };
        })

        //Sumar pesos ycantidad de muestras por presentación (fila)
        registrosInvernaderos.forEach((invernaderoDatos, indexInv) => {
            const invernadero = invernaderos[indexInv];

            invernaderoDatos.forEach(registroDatos => {
                const { presentacion_ref = "", pesos = [] } = registroDatos;
                if (presentacion_ref === presentacion.id) {
                    pesos.forEach(pesosObj => {
                        const pesoKg = parseFloat(pesosObj.peso || 0);

                        if (!isNaN(pesoKg) && pesoKg) {
                            row[invernadero.id].sumaPesos += pesoKg;
                            row[invernadero.id].sumaMuestras++;

                            row.accumuladoPesos += pesoKg;
                            row.accumuladoMuestras++;
                        }
                    })
                }
            })
            row[invernadero.id].promedio = row[invernadero.id].sumaMuestras ? row[invernadero.id].sumaPesos / row[invernadero.id].sumaMuestras : 0;
        })
        row.accumuladoPromedio = row.accumuladoMuestras ? row.accumuladoPesos / row.accumuladoMuestras : 0

        if (row.accumuladoMuestras > 0) {
            //console.log("ROW: ", row);
            row.index = count;
            count++;
        }
        return row;
    })

    console.log("TIEMPO A PROCESAR: ", (Date.now() - tiempoInicio) / 1000)
    return rowsProcesados;
}


////////
export const getResumenCalidadPesosSemanaInvernaderosTodos = async (ciclo, semana, invernaderos = [], presentaciones = [], grupos = [], empacadoras = []) => {
    try {
        const promisesPesos = invernaderos.map(invernadero => getRegistrosCalidadPesosSemana(ciclo, invernadero.id, semana));
        const registrosPesosInvernaderos = await Promise.all(promisesPesos);
        console.log("1 BEFORE - invernaderos: ", registrosPesosInvernaderos);

        const resumenCalidadPesosInvernaderos = getResumenCalidadPesosInvernaderos(registrosPesosInvernaderos, presentaciones, invernaderos);
        console.log("2 BEFORE - presentaciones: (prev inv datos)", resumenCalidadPesosInvernaderos);

        const resumenCalidadPesosPresentaciones = getResumenCalidadPesosPresentaciones(registrosPesosInvernaderos.flat(), presentaciones);
        console.log("3 AFTER - presentaciones: ", resumenCalidadPesosPresentaciones);

        const resumenCalidadPesosGrupos = getResumenCalidadPesosGrupos(registrosPesosInvernaderos.flat(), presentaciones, grupos)
        console.log("4 AFTER - grupos de pres.: ", resumenCalidadPesosGrupos);

        const resumenCalidadPesosEmpacadoras = getResumenCalidadPesosEmpacadoras(registrosPesosInvernaderos.flat(), presentaciones, grupos, empacadoras)
        console.log("5 AFTER - empacadoras: ", resumenCalidadPesosEmpacadoras);

        /* 
        const rowsProcesados = procesarRowsPesosDiarios(datosPesosSemanaInvernaderos, invernaderos, DeepClone(presentaciones));
        const rows = rowsProcesados?.filter(row => row.accumuladoPromedio > 0);
        const presentacionesPresentes = rows.map(row => row.presentacion);
        ordenarArreglaDeObjetosPorLlave(presentacionesPresentes, "presentacion");

        const comentarios = extraerComentarios(datosPesosSemanaInvernaderos);
        ordenarArreglaDeObjetosPorLlave(comentarios, "momento");

        //console.log("getReporteGeneralPesosDiariosAccumulados DATOS - rowsProcesados - ", { comentarios, rows, presentacionesPresentes });
        return { comentarios, rows, presentacionesPresentes }; */
    } catch (error) {
        console.log("ERROR getResumenCalidadPesosSemanaInvernaderosTodos: ", error);
    }
}
////////