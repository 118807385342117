import React, { useEffect, useState } from 'react'
import ContenedorHeader from '../../common/ContenedorHeader'
import ContenedorPrincipal from './ContenedorPrincipal'
import SeccionFiltros from './SeccionFiltros'
import { useGlobal, setGlobal } from "reactn";
import WeekSelector, { useWeekSelector } from '../../common/WeekSelector';
import { Button } from 'primereact/button';
import Dias from './Dias';
import { obtenerEmpresas } from '../../../service/Empresas';
import { obtenerCategoriasActividadesMantenimiento } from '../../../service/Queries/CategoriasActividadesMantenimiento';
import { obtenerActividadesMantenimiento } from '../../../service/Queries/ActividadesMantenimiento';
import { obtenerActividadesAgenteSemana, getAgentes } from '../../../service/Queries/Agentes';
import SeccionDias from '../SeccionDias';
import BaseButton from '../../common/BaseButton';
import ContenedorBotonesTop from '../../common/ContenedorBotonesTop';
import RowBotonesTop from '../../common/RowBotonesTop';
import { obtenerDiaActual, obtenerDiasDeSemanaConDetalles, obtenerIndiceDiaSemana } from '../../../service/fechas';
import { obtenerFiltrosMantenimientoTodos } from '../../../service/Requisiciones';



const agenteMock = { nombre: "Agente mock", tareas: [] }
setGlobal({ agente: agenteMock });
export default function ConsultaSemana(props) {
    const [cicloId, setCicloId] = useGlobal("ciclo")
    const [agente, setAgente] = useGlobal("agente")
    const [usuario, setUsuario] = useGlobal("usuario")
    const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector(true);
    const [weekGlobal, setWeekGlobal] = useGlobal("weekGlobal");
    const [tareas, setTareas] = useGlobal("tareas");
    const [empresas, setEmpresas] = useGlobal("empresas")
    const [categorias, setCategorias] = useGlobal("categorias")
    const [actividades, setActividades] = useGlobal("actividadesMantenimiento")
    const [actividadesAgente, setActividadesAgente] = useGlobal("actividadesAgente")
    const [cargando, setCargando] = useGlobal("cargando")
    const [dia, setDia] = useGlobal("dia")

    useEffect(() => {
        setAgente({ ...usuario, nombre: usuario.displayName })
    }, [])
    useEffect(() => {
        if (cicloId)
            updateCicloWeekSelector(cicloId);
    }, [cicloId])
    useEffect(() => {
        if (cicloId) {
            obtenerDatosIniciales();
        }
    }, [cicloId])
    useEffect(() => {
        if (cicloId && actividades) {
            actualizarDatosSemana()
        }
    }, [cicloId, week, actividades])

    const obtenerDatosIniciales = async (params) => {
        setCargando(true);
        await obtenerFiltrosMantenimientoTodos( ).catch(err=>{console.log("ERRRRRR: ",err)})
        setCategorias(await obtenerCategoriasActividadesMantenimiento());
        setActividades(await obtenerActividadesMantenimiento());
        setCargando(false);
    }
    const actualizarDatosSemana = async (params) => {
        setCargando(true);
        const agenteId = usuario.uid;
        let actividadesAgente = await obtenerActividadesAgenteSemana(cicloId, week.time, agenteId);
        setTareas(actividadesAgente);
        setCargando(false);
    }

    const consultar = () => {
        let dias = obtenerDiasDeSemanaConDetalles(week.time);
        let today = Math.round(Date.now() / 1000);
        let indice = obtenerIndiceDiaSemana(today);
        if(indice === 6) indice = indice - 1
        setDia(dias[indice]);

        props.history.push("/mantenimiento/agente/consulta_dia");
    }

    return (
        <ContenedorPrincipal>
            <ContenedorHeader subtitulo="Actividades de mantenimiento" titulo={agente["nombre"]} col="p-col p-col-auto" iconos="mtto-icon" />

            <ContenedorBotonesTop>
                <RowBotonesTop>
                    <Button label="Registrar actividades de hoy" onClick={consultar} />
                </RowBotonesTop>
            </ContenedorBotonesTop>

            <SeccionFiltros>
                <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
            </SeccionFiltros>

            <SeccionDias>
                <Dias week={week} tareas={tareas} />
            </SeccionDias>
        </ContenedorPrincipal>
    )
}