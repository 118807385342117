import React, { useState, useEffect, useGlobal } from 'reactn'
import ContenedorPrincipal from '../common/ContenedorPrincipal'
import ContenedorHeader from '../common/ContenedorHeader'
import SeccionFiltros from '../common/SeccionFiltros'
import ContenedorFiltro from '../common/ContenedorFiltro'
import { useWeekSelector } from '../common/WeekSelector'

import ChartContainer from '../common/ChartContainer'
import Chart from "react-apexcharts";
import { Dropdown } from 'primereact/dropdown'
import { MultiSelect } from 'primereact/multiselect'
import { DeepClone, paletaColores, stringToColor } from '../../util/functions'
import { obtenerInicioSemanaDia } from '../../service/fechas'
import { ToggleButton } from 'primereact/togglebutton';
import { obtenerReportesMedicosTodos } from '../../service/ReporteMedico'
import { obtenerEmpaques } from '../../service/Empaques'
import RHService from '../../service/RhService';

export default function GraficaReporteMedico(props) {
    const [EMPAQUE_REF, INVERNADERO_REF, DEPARTAMENTO_REF] = ["empaque_ref", "invernadero_ref", "departamento_ref"];
    let optionsOrig = {
        colors: paletaColores,
        chart: {
            height: 350,
            stacked: true,
            type: "bar",
            stackType: "100%",
            animations: {
                enabled: false
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: 'bottom',
                    offsetX: -10,
                    offsetY: 0,
                    colors: paletaColores,
                }
            }
        }],
        xaxis: { categories: [] },
        yaxis: { decimalsInFloat: 2 },
        plotOptions: { bar: { horizontal: true } },
        fill: { opacity: 1 },
        tooltip: {
            y: {
                formatter: function (val, opt) {
                    if (parseInt(val) <= 0) { return null; }
                    //console.log("Semana : total "+JSON.stringify(opt?.w?.globals?.labels?.[opt?.dataPointIndex] + " : " + val)) 
                    //console.log("Datos series "+JSON.stringify(opt?.w?.config?.series?.[opt?.seriesIndex])) 
                    //console.log("Series index " + opt?.seriesIndex)
                    //console.log("data point index " + opt?.dataPointIndex)
                    //const nombreUbicacion = opt?.w?.config?.series?.[opt?.seriesIndex]?.name || ""
                    let total = 0, totalCiclo = 0, { dataPointIndex, seriesIndex } = opt;
                    const series = opt?.w?.config?.series || [], seriesData = series?.[seriesIndex]?.data;
                    series.forEach(serie => { total += serie.data?.[dataPointIndex] || 0 });
                    seriesData.forEach(count => { totalCiclo += count });
                    if (parseInt(val) === parseInt(total)) return `${total};  total en este ciclo: ${totalCiclo}`
                    else return `${val} / ${total};  total en este ciclo: ${totalCiclo}`
                }
            }
        },
        legend: {
            colors: paletaColores,
            position: 'right',
            offsetX: 0,
            offsetY: 400,
        },
    }

    const [ciclo] = useGlobal("ciclo")
    const [, setCargando] = useGlobal("cargando")
    const [weeks] = useWeekSelector();
    const [consultasMedicosDatos, setConsultasMedicosDatos] = useState()
    const [datosInvOrig, setDatosInvOrig] = useState()
    const [datosEmpOrig, setDatosEmpOrig] = useState()
    const [datosOtrosOrig, setDatosOtrosOrig] = useState()
    const [datos, setDatos] = useState()

    ///////////////FILTROS///////////////
    const [stackTypeFiltro, setStackTypeFiltro] = useState(true)
    const [departamentosOpciones, setDepartamentosOpciones] = useState([]);
    const [departamentoFiltro, setDepartamentoFiltro] = useState();

    const [invernaderosVirtuales] = useGlobal("invernaderosInternos");
    const [invernaderosFiltros, setInvernaderosFiltros] = useState([]);
    const [sitiosEmpaques, setSitiosEmpaque] = useState([]);
    const [sitiosEmpaquesFiltros, setSitiosEmpaquesFiltros] = useState([]);
    const [departamentosOtros, setDepartamentosOtros] = useState([]);
    const [departamentosOtrosFiltros, setDepartamentosOtrosFiltros] = useState([]);

    useEffect(() => {
        if (weeks?.length > 0 && ciclo) { obtenerDatosIniciales(); }
    }, [weeks, ciclo])

    useEffect(() => {//Filtrar datos de sitios de empaque
        if (datosEmpOrig?.series && departamentoFiltro?.nombre === "Empaque") {
            const copiaDatos = DeepClone(datosEmpOrig);
            copiaDatos.series = copiaDatos.series.filter(el => sitiosEmpaquesFiltros.includes(el.id));
            copiaDatos.options.colors = copiaDatos.series.map(el => el.color);
            copiaDatos.options.chart.stackType = stackTypeFiltro ? "100%" : "";
            copiaDatos.key = `emp-${stackTypeFiltro}`;
            setDatos(copiaDatos);
        }
    }, [sitiosEmpaquesFiltros, datosEmpOrig, departamentoFiltro, stackTypeFiltro])

    useEffect(() => {//Filtrar datos de invernaderos
        if (datosInvOrig?.series && departamentoFiltro?.nombre === "Invernadero") {
            const copiaDatos = DeepClone(datosInvOrig);
            copiaDatos.series = copiaDatos.series.filter(el => invernaderosFiltros.includes(el.id));
            copiaDatos.options.colors = copiaDatos.series.map(el => el.color);
            copiaDatos.options.chart.stackType = stackTypeFiltro ? "100%" : "";
            copiaDatos.key = `inv-${stackTypeFiltro}`;
            setDatos(copiaDatos);
        }
    }, [invernaderosFiltros, datosInvOrig, departamentoFiltro, stackTypeFiltro])

    useEffect(() => {//Filtrar datos de otros departamentos
        if (datosOtrosOrig?.series && departamentoFiltro?.nombre === "Otros") {
            const copiaDatos = DeepClone(datosOtrosOrig);
            copiaDatos.series = copiaDatos.series.filter(el => departamentosOtrosFiltros.includes(el.id));
            copiaDatos.options.colors = copiaDatos.series.map(el => el.color);
            copiaDatos.options.chart.stackType = stackTypeFiltro ? "100%" : "";
            copiaDatos.key = `otros-${stackTypeFiltro}`;
            setDatos(copiaDatos);
        }
    }, [invernaderosFiltros, datosOtrosOrig,departamentosOtrosFiltros, departamentoFiltro, stackTypeFiltro])


    const obtenerDatosIniciales = async () => {
        setCargando(true);
        const [consultasMedicos, departamentos, departamentosTodos, empaques]
            = await Promise.all([obtenerReportesMedicosTodos(ciclo), RHService.getDepartamentosConSubdepartamentos_and_Otros(), RHService.getDepartamentos(), obtenerEmpaques()]);
        const departamentosOtros = departamentosTodos.filter(depa => !departamentos.some(el => el.id === depa.id));//Filtrar departamentos que no son invernaderos o empaques
        
        setConsultasMedicosDatos(consultasMedicos);
        setDepartamentosOpciones(departamentos);
        setSitiosEmpaque(empaques);
        setDepartamentosOtros(departamentosOtros);

        const datosConsultasEmpaques = getConsultasMedicoData(consultasMedicos, empaques, EMPAQUE_REF);
        const datosConsultasInvernaderos = getConsultasMedicoData(consultasMedicos, invernaderosVirtuales, INVERNADERO_REF);
        const datosConsultasOtros = getConsultasMedicoData(consultasMedicos, departamentosOtros, DEPARTAMENTO_REF);

        setDatosEmpOrig(datosConsultasEmpaques);
        setDatosInvOrig(datosConsultasInvernaderos);
        setDatosOtrosOrig(datosConsultasOtros);
        setCargando(false);
    }
    const getConsultasMedicoData = (consultasMedicos, coleccionDepartamento, nombreVar = INVERNADERO_REF) => {
        const options = DeepClone(optionsOrig)
        options.xaxis.categories = weeks.map(el => el.nombre)
        const series = coleccionDepartamento.map((el, index) => ({ data: [], name: el.nombre, color: el.color ? stringToColor(el.color) : paletaColores[index], id: el.id }))

        weeks.forEach(week => {
            coleccionDepartamento.forEach((depa, indexDepa) => {
                const filtered = consultasMedicos.filter(consulta => {
                    const inicioSemana = obtenerInicioSemanaDia(consulta.fecha);
                    const mismoSemana = week.time === inicioSemana.unix();
                    return mismoSemana && consulta[nombreVar] === depa.id;
                })
                series[indexDepa].data.push(filtered.length)
            })
        })
        //console.log(`getConsultasMedicoData datos - ${nombreVar}: `, { options, series, key: "datos-iniciales" });
        return { options, series }
    }

    return (
        <ContenedorPrincipal extra="chart-page">
            <ContenedorHeader titulo="Gráfica de consultas médicas" iconos="chart-icon" atras={props.history.goBack} />

            <SeccionFiltros>
                <ContenedorFiltro label="departamento">
                    <Dropdown optionLabel="nombre" dataKey="id" options={departamentosOpciones} value={departamentoFiltro} onChange={e => setDepartamentoFiltro(e.value)} />
                </ContenedorFiltro>

                {departamentoFiltro?.nombre === "Empaque" &&
                    <ContenedorFiltro label="empaques">
                        <MultiSelect id="empaque" optionLabel="nombre" optionValue="id" options={sitiosEmpaques} value={sitiosEmpaquesFiltros}
                            onChange={ev => setSitiosEmpaquesFiltros(ev.value)} selectedItemsLabel={`${sitiosEmpaquesFiltros.length} empaques seleccionados`} />
                    </ContenedorFiltro>}

                {departamentoFiltro?.nombre === "Invernadero" &&
                    <ContenedorFiltro label="invernaderos">
                        <MultiSelect id="invernadero" optionLabel="nombre" optionValue="id" options={invernaderosVirtuales} value={invernaderosFiltros}
                            onChange={ev => setInvernaderosFiltros(ev.value)} selectedItemsLabel={`${invernaderosFiltros.length} invernaderos seleccionados`} />
                    </ContenedorFiltro>}

                {departamentoFiltro?.nombre === "Otros" &&
                    <ContenedorFiltro label="otros departamentos">
                        <MultiSelect id="otros" optionLabel="nombre" optionValue="id" options={departamentosOtros} value={departamentosOtrosFiltros}
                            onChange={ev => setDepartamentosOtrosFiltros(ev.value)} selectedItemsLabel={`${departamentosOtrosFiltros.length} departamentos seleccionados`} />
                    </ContenedorFiltro>}

                <ContenedorFiltro label="tipo">
                    <ToggleButton className="reactbtn-icon" onLabel="Porcentajes" offLabel="Totales" checked={stackTypeFiltro} onChange={(ev) => setStackTypeFiltro(ev.value)} ></ToggleButton>
                </ContenedorFiltro>
            </SeccionFiltros>

            {datos?.series &&
                <ChartContainer type="large">
                    <Chart key={datos?.key} options={datos?.options} series={datos?.series} type="bar" height="100%" width="100%" />
                </ChartContainer>}
        </ContenedorPrincipal>)
}