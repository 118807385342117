import React, { useEffect, useState } from 'react';
import { useGlobal } from 'reactn'
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';

import { InputText } from 'primereact/inputtext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faNotesMedical, faFileSignature, faPaperclip } from '@fortawesome/free-solid-svg-icons';

import { obtenerConsultasDeEmpleados, obtenerCuentaConsultas, obtenerReportesMedicos, obtenerReportesMedicosIntervaloFechas, obtenerUrlArchivo } from '../../service/ReporteMedico';
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import RHService, { obtenerEmpleados } from '../../service/RhService';
import { dateToUnixTimeAsNumber, dateToUnixTimeAsNumber13, DeepClone, getCalendarDate, getMomentFormatted, getObjetoPorID, unixTimeToDate, unixToString } from '../../util/functions';
import ContenedorFiltro from '../common/ContenedorFiltro';
import ContenedorFiltroBusqueda from '../common/ContenedorFiltroBusqueda';
import { MultiSelect } from 'primereact/multiselect';
import { DEPARTAMENTO_EMPAQUE_NOMBRE, DEPARTAMENTO_INVERNADERO_NOMBRE, DETALLES_DEPARTAMENTO_EMPAQUE, DETALLES_DEPARTAMENTO_OTROS } from '../../util/constants';
import { Calendar } from 'primereact/calendar';
import ContenedorHeader from '../common/ContenedorHeader';
import { miliSegundosDia, miliSegundosSemana, numeroMaximo, segundosDia } from '../../constants';
import ModalDetalleConsultas from './ModalDetalleConsultas';
let opcionesColumnasBase = [
    { label: "ID", value: "codigo_empleado" },
    { label: "Empleado", value: "empleado" },
    { label: "Fecha + Hora", value: "fecha_hora" },
    { label: "Tipo de Consulta", value: "tipo_consulta" },
    { label: "Departamento", value: "departamento" },
    { label: "Consultas", value: "cuentaConsultas" },
    { label: "Adjuntos", value: "Adjuntos" },
]

const ConsultarReportesMedicos = (props) => {
    const setFechaFiltros = () => {
        if (week.nombre !== "Cargando") return { inicial: getCalendarDate(week.time * 1000), final: getCalendarDate((week.time * 1000) + miliSegundosSemana) }
        else return { inicial: getCalendarDate(Date.now() - miliSegundosSemana), final: getCalendarDate(Date.now()) }
    }

    const { empleadoNombrePassed = "", departamento: departamentoPassed, preseleccionInv } = props?.location?.data || {};
    const [comingFromEmpleado] = useState(empleadoNombrePassed ? true : false)
    const [reportesRaw, setReportesRaw] = useState([]);
    const [usuario, setUsuario] = useGlobal("usuario");
    const [ciclo, setCiclo] = useGlobal("ciclo");
    const [cicloObj] = useGlobal("cicloObj");
    const [invernaderosVirtuales] = useGlobal("invernaderosInternos");
    const [invernadero, setInvernadero] = useGlobal("invernadero");

    const [, setCargando] = useGlobal("cargando");
    const [empleado, setEmpleado] = useState([]);
    const [reportesOrig, setReportesOrig] = useState([]);
    const [reportes, setReportes] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(empleadoNombrePassed || "");
    const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector();
    const [departamentos, setDepartamentos] = useState([]);
    const [departamento, setDepartamento] = useState();
    const [empaques, setEmpaques] = useState([]);
    const [invernaderos, setInvernaderos] = useState([]);

    const [empaque, setEmpaque] = useState([]);
    const [invernaderosFiltro, setInvernaderosFiltro] = useState([]);

    const [empleadosGlobal, setEmpleadosGlobal] = useGlobal("empleadosGlobal");
    const [empleados, setEmpleados] = useState([]);
    const [, setReporteEdit] = useGlobal("reporteEdit");
    const [preseleccionRM, setPreseleccionRM] = useGlobal("preseleccionRM");
    const [deptosOrig, setDeptosOrig] = useState([]);
    const [filtroColumnas, setFiltroColumnas] = useState(opcionesColumnasBase.map(el => el.value))
    //const [fechaFiltroInicio, setFechaFiltroInicio] = useState(empleadoNombrePassed ? getCalendarDate(cicloObj.semana_inicio * 1000) : getCalendarDate(Date.now() - 604800000))
    const [fechaFiltroInicio, setFechaFiltroInicio] = useState(setFechaFiltros().inicial)
    const [fechaFiltroFinal, setFechaFiltroFinal] = useState(setFechaFiltros().final)
    const [reportesMedicos, setReportesMedicos] = useGlobal("reportesMedicosEmpleado")
    const [firstCall, setFirstCall] = useState(true)
    const [stateModalDetalleConsultas, setStateModalDetalleConsultas] = useState({
        show: false,
        data: {}
    })

    const puedeConsultar = () => usuario && ["Administrador", "Medico", "Personal Administrativo"].includes(usuario.rol);

    useEffect(() => {
        if (ciclo) obtenerDatosIniciales(true);
    }, [ciclo, fechaFiltroInicio, fechaFiltroFinal])

    const obtenerDatosIniciales = async (fechaChange = false) => {
        try {
            if (comingFromEmpleado && !firstCall) setGlobalFilter("")
            setCargando(true);
            const addTime = usuario.displayName.trim() === "Adam Torres" ? 7200 : 0//Para el problema temporal de timestamps en diferentes zonas de horario
            let inicioTime = fechaFiltroInicio ? dateToUnixTimeAsNumber(fechaFiltroInicio) - addTime : 1;
            let finalTime = fechaFiltroFinal ? dateToUnixTimeAsNumber(fechaFiltroFinal) - addTime + segundosDia : numeroMaximo;

            let empleadosResp = empleadosGlobal || await obtenerEmpleados();
            if (!empleadosGlobal) setEmpleadosGlobal(empleadosResp);
            let reportesResp = (firstCall && reportesMedicos) ? reportesMedicos : await obtenerReportesMedicosIntervaloFechas(ciclo, inicioTime, finalTime);

            // obtener empleados presentes en reportes  
            let empleadosPresentes = reportesResp.map(el => el.empleado_ref);
            let empleadosPresentesFiltrados = empleadosResp.filter(el => empleadosPresentes.includes(el.id));
            let promesasConsultas = empleadosPresentesFiltrados.map(el => obtenerCuentaConsultas(ciclo, el.id))
            let consultas = await Promise.all(promesasConsultas);
            //asignar cuentas consultas a los empleados
            for (const index in empleadosPresentesFiltrados) {
                let empleado = empleadosPresentesFiltrados[index]
                //empleado.cuentaConsultas = cuentasConsultas[index];
                empleado.cuentaConsultas = consultas[index].length
                empleado.consultas = consultas[index]
            }

            setEmpleados(empleadosPresentesFiltrados);
            let departamentosRes = departamentos.length > 0 ? departamentos : await RHService.getDepartamentosConSubdepartamentos_and_Otros();
            let deptosOrigResp = deptosOrig.length > 0 ? deptosOrig : await RHService.getDepartamentos();
            setDeptosOrig(deptosOrigResp);
            setDepartamentos(departamentosRes);

            let invernaderosActivos = DeepClone(invernaderosVirtuales);
            invernaderosActivos.forEach(invernadero => { invernadero.tipo = "Invernadero" })
            setInvernaderos(invernaderosActivos);
            const found = invernaderosActivos?.find(el => el.id === preseleccionInv)
            setInvernaderosFiltro(found ? [found] : invernaderosActivos);

            let empaquesResp = empaques;
            if (empaques.length === 0) {
                empaquesResp = await RHService.getEmpaques();
                empaquesResp = [...empaquesResp, { nombre: "Sin asignar", id: "Sin asignar", empaque_ref: "Sin asignar" }];
                setEmpaques(empaquesResp);
            }

            if (firstCall && fechaChange) {
                //setReportesMedicos(null)
                setEmpaque(empaquesResp);
            }


            let procesado = await procesar(reportesResp, empleadosPresentesFiltrados, deptosOrigResp, invernaderosActivos, empaquesResp);
            setReportesRaw(reportesResp);
            setReportesOrig(procesado);
        } catch (error) {
            console.log(`ERROR obtener datos iniciales`, error);
        } finally {
            setCargando(false);
            setReportesMedicos(null);
            setFirstCall(false)
        }
    }
    useEffect(() => {
        if (preseleccionRM && departamentos.length > 0 && invernaderos.length > 0 && empaques.length > 0) {

            let departamento = departamentos.find(el => el.nombre === preseleccionRM.tipo);
            if (departamento && departamento.nombre === "Invernadero") {
                setDepartamento(departamento);
                let invernadero = invernaderos.find(el => el.id === preseleccionRM.id);
                setInvernadero(invernadero);
            } else if (departamento && departamento.nombre === "Empaque") {
                setDepartamento(departamento);
                let empaque = empaques.find(el => el.id === preseleccionRM.id);
                setEmpaque([empaque]);
            } else {
                setDepartamento(departamentos[departamentos.length - 1]);
            }

            setPreseleccionRM(null);
        }
    }, [preseleccionRM, departamentos, invernaderos, empaques])
    const procesar = async (reportes, empleados = [], departamentos, invernaderos, empaques) => {
        if (reportes.length === 0) return reportes;
        let result = [];

        for (let index = 0; index < reportes.length; index++) {
            const reporte = { ...reportes[index] };
            if (getObjetoPorID(empaques, reporte.departamento_ref) && !reporte.empaque_ref) reporte.empaque_ref = "Sin asignar"
            let adjuntos = {};
            let adjuntosExamen = await Promise.all(reporte["archivos_examen"].map(el => obtenerUrlArchivo(el)));
            let adjuntosCertificado = await Promise.all(reporte["archivos_certificado"].map(el => obtenerUrlArchivo(el)));
            let adjuntosOtro = await Promise.all(reporte["archivos_otro"].map(el => obtenerUrlArchivo(el)));
            adjuntos["examen"] = adjuntosExamen;
            adjuntos["certificado"] = adjuntosCertificado;
            adjuntos["otro"] = adjuntosOtro;

            const empleado = empleados?.find(el => reporte.empleado_ref === el.id) || {}
            const departamento = departamentos.find(el => reporte.departamento_ref === el.id)
            reporte["id"] = reporte["id"];
            reporte.codigo_empleado = empleado?.codigo_empleado;
            reporte["empleado"] = empleado.codigo_empleado ? `${empleado.nombre.trim()} ${empleado.apellidos.trim()}` : "NUNYA";
            reporte["fecha_hora"] = `${getMomentFormatted(reporte.fecha * 1000, "DD/MM/YYYY")} ${reporte.hora}`;
            reporte["tipo_consulta"] = reporte["motivo"];
            reporte["departamento"] = departamento.nombre;


            /////////boton de er consultas/////////
            reporte["cuentaConsultas"] = empleado.cuentaConsultas
            reporte["empleadoObj"] = empleado

            if (reporte["invernadero_ref"]) {
                let invernadero = invernaderos.find(el => reporte.invernadero_ref === el.id);
                reporte["invernadero"] = invernadero ? invernadero["nombre"] : "";
            } else {
                reporte["invernadero"] = "";
            }
            if (reporte["empacadora_ref"]) {
                let empacadora = empaques.find(el => reporte.empacadora_ref === el.id);
                reporte["empacadora"] = empacadora ? empacadora["nombre"] : "";
            } else {
                reporte["empacadora"] = "";
            }
            reporte["adjuntos"] = adjuntos;
            result.push(reporte);
        }

        return result;
    }

    const clickAdjunto = (e) => {
        e.stopPropagation();
    }

    const templateAdjuntos = (rowData, column) => {
        let linksExamen = rowData["adjuntos"]["examen"].map((el, index) => <div onClick={clickAdjunto} key={`links_ex_${index}`}><a className="medical-doc" href={el} target="_blank" ><FontAwesomeIcon icon={faNotesMedical} /><span className="text">Examen {index === 0 ? "" : index + 1}</span></a></div>);
        let linksCertificado = rowData["adjuntos"]["certificado"].map((el, index) => <div onClick={clickAdjunto} key={`links_cert_${index}`}><a className="medical-doc" href={el} target="_blank"  ><FontAwesomeIcon icon={faFileSignature} /><span className="text">Certificado {index === 0 ? "" : index + 1}</span></a></div>);
        let linksOtros = rowData["adjuntos"]["otro"].map((el, index) => <div onClick={clickAdjunto} key={`links_cert_${index}`}><a className="medical-doc" href={el} target="_blank"  ><FontAwesomeIcon icon={faPaperclip} /><span className="text">Adjunto {index === 0 ? "" : index + 1}</span></a></div>);
        return <div>
            {linksExamen}
            {linksCertificado}
            {linksOtros}
        </div>
    }
    const handleClickButtonVerConsultas = (e, rowData) => {
        e.stopPropagation();
        const { nombre: nombreDelEmpleado, codigo_empleado: codigoDelEmpelado, consultas } = rowData?.empleadoObj
        setStateModalDetalleConsultas({ show: true, data: { nombreDelEmpleado, codigoDelEmpelado, consultas } })
    }

    const consultasButtonTemplate = (rowData) => <Button label='Ver consultas' onClick={(e) => handleClickButtonVerConsultas(e, rowData)} />
    
    const obtenerColumnas = (params) => {
        let cols = [];
        opcionesColumnasBase.forEach((columna, index) => {
            if (filtroColumnas.includes(columna.value)) {


                if (columna.value === "Adjuntos") {
                    return cols.push(<Column field={columna.value} key={index} header={columna.label} body={templateAdjuntos} />)
                }
                if (columna.value === "cuentaConsultas") {
                    return cols.push(<Column field={columna.value} key={index} header={columna.label}  body={consultasButtonTemplate} />)
                }
                if(columna.value === "fecha_hora"){
                    return cols.push(<Column field={columna.value} key={index} header={columna.label} sortable sortField='fecha'/>)
                }

                cols.push(<Column field={columna.value} key={index} header={columna.label} sortable />)
            }
        })
        return cols;

    }
    const irReporte = (a) => {
        if (puedeConsultar()) {
            let rowData = a.data;
            let reporte = reportesRaw.find(el => el.id === rowData["id"]);
            setReporteEdit(reporte);
            props.history.push("/reporte_medico/registrar");
        }

    }
    useEffect(() => {
        let filtrados = [];
        if (departamento) {
            if (departamento?.nombre === "Invernadero") {
                filtrados = reportesOrig.filter(el => el.departamento === departamento["nombre"]);
                if (invernadero) {
                    let invernaderoIDs = invernaderosFiltro.map(dep => dep.id)
                    filtrados = filtrados.filter(el => invernaderoIDs.includes(el.invernadero_ref));
                }
            } else if (departamento?.nombre === "Empaque") {
                filtrados = reportesOrig.filter(el => el.departamento === departamento["nombre"]);
                if (empaque) {
                    let departamentoIDs = empaque.map(dep => dep.id)
                    filtrados = filtrados.filter(el => departamentoIDs.includes(el.empaque_ref));
                }
            } else filtrados = reportesOrig.filter(el => ![DEPARTAMENTO_EMPAQUE_NOMBRE, DEPARTAMENTO_INVERNADERO_NOMBRE].includes(el.departamento));

            setReportes(filtrados);
        } else {

            setReportes(reportesOrig.map(el => el));
        }

    }, [reportesOrig, departamento, invernaderosFiltro, empaque])


    const obtenerHeaderIcono = () => {
        if (departamento && departamento.nombre == "Invernadero" && invernaderosFiltro.length !== 1)
            return "card-id icon-general icon-id inv-icon";
        else if (departamento && departamento.nombre == "Invernadero")
            return `card-id icon-general icon-id bor-${invernadero?.color || ""} bg-${invernadero?.color || ""}`
        else if (departamento && departamento.nombre == "Empaque")
            return "card-id icon-general icon-id boxes-icon ";
        else return "card-id icon-general medico-icon icon-id";
    }

    const handleMChange = (e) => {


        setFiltroColumnas(e.value);
    }
    
    const getSubtitulo = () => departamento?.nombre == "Invernadero" ? "" : (departamento?.nombre || "")
    const hideModalDetalleConsultas = () => setStateModalDetalleConsultas({ ...stateModalDetalleConsultas, show: false })
    return <div>
        <div className="p-grid p-justify-center">
            <ContenedorHeader col="p-col p-col-auto" titulo="Consultas médicas" subtitulo={getSubtitulo()} iconos={obtenerHeaderIcono()} atras={props.history.goBack} invernaderoNombre={(departamento?.nombre == "Invernadero" && invernaderosFiltro.length === 1) ? invernadero.nombre : ""} />

            <div className="p-col-12">
                <div className="p-grid filtros">
                    <ContenedorFiltroBusqueda>
                        <FontAwesomeIcon icon={faSearch} />
                        <InputText value={globalFilter} onChange={e => setGlobalFilter(e.target.value)} />
                    </ContenedorFiltroBusqueda>

                    <ContenedorFiltro col="2" label="Fecha Inicio">
                        <Calendar id="fechaInicio" readOnlyInput={true} dateFormat="dd-mm-yy" //locale={ CALENDAR_LOCALE_ES_PRIME_TRADUCTION }
                            value={fechaFiltroInicio} monthNavigator={true} yearNavigator={true} yearRange="1900:2030"
                            showButtonBar={true} onClearButtonClick={() => { setFechaFiltroInicio("") }}
                            onChange={e => setFechaFiltroInicio(e.value)} />
                    </ContenedorFiltro>
                    <ContenedorFiltro col="2" label="Fecha Final">
                        <Calendar id="fechaFinal" readOnlyInput={true} dateFormat="dd-mm-yy" //locale={ CALENDAR_LOCALE_ES_PRIME_TRADUCTION }
                            value={fechaFiltroFinal} monthNavigator={true} yearNavigator={true} yearRange="1900:2030"
                            showButtonBar={true} onClearButtonClick={() => { setFechaFiltroFinal("") }}
                            onChange={e => setFechaFiltroFinal(e.value)} />
                    </ContenedorFiltro>

                    <div className="form-group p-col">
                        <label htmlFor="filtroCliente">Departamentos</label>
                        <Dropdown optionLabel="nombre" options={departamentos} dataKey={"id"} value={departamento} onChange={e => setDepartamento(e.value)} />
                    </div>
                    {departamento && departamento["nombre"] === "Invernadero" &&
                        <div className="form-group p-col">
                            <label htmlFor="filtroCliente">Invernaderos</label>
                            <MultiSelect optionLabel="nombre" options={invernaderos} dataKey={"id"} value={invernaderosFiltro} onChange={e => {
                                if (e.target.value.length > 0)
                                    setInvernadero(e.target.value[0])
                                setInvernaderosFiltro(e.value)
                            }}
                                selectedItemsLabel={`${invernaderosFiltro.length} invernaderos seleccionados`} />
                        </div>}
                    {departamento && departamento["nombre"] === "Empaque" &&
                        <div className="form-group p-col">
                            <label htmlFor="filtroCliente">Empaques</label>
                            <MultiSelect optionLabel="nombre" options={empaques} dataKey={"id"} value={empaque} onChange={e => setEmpaque(e.value)}
                                selectedItemsLabel={`${empaque.length} empaques seleccionados`} />
                        </div>}
                    <ContenedorFiltro label="Columnas" >
                        <MultiSelect options={opcionesColumnasBase}
                            value={filtroColumnas}
                            optionLabel="label" onChange={handleMChange}
                            selectedItemsLabel={`${filtroColumnas.length} columnas seleccionadas`} />
                    </ContenedorFiltro>

                </div>
            </div>

            <div className="p-col-12">
                <div className="p-grid p-fluid ">
                    <div className="p-col-12">
                        <DataTable selectionMode="single" className="tabla-presentaciones" value={reportes} globalFilter={globalFilter} onRowClick={irReporte}
                            scrollable={true} >
                            {obtenerColumnas()}
                        </DataTable>
                    </div>
                </div>
            </div>
            <ModalDetalleConsultas
                show={stateModalDetalleConsultas.show}
                data={stateModalDetalleConsultas.data}
                hide={hideModalDetalleConsultas}
            />
        </div>
    </div>
}
export default ConsultarReportesMedicos;//356