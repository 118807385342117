import React, { useState, useEffect, useGlobal, setGlobal } from 'reactn';
import { Dialog } from 'primereact/dialog';
import ContenedorTabla from '../../common/ContenedorTabla';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { eliminarArchivoCargado, obtenerArchivosCargados } from '../../../service/NominaEmpaqueQueries';
import { obtenerEmpaque, obtenerEmpaqueCicloIdPropio } from '../../../service/Empaques';
import { getNombrePorID, getObjetoPorID } from '../../../util/functions';

export const ModalArchivos = (props) => {
    const [ciclo] = useGlobal("ciclo");
    const [week] = useGlobal("week");
    const [cargando, setCargando] = useGlobal("cargando");
    const [empaques, setEmpaques] = useGlobal("empaques")
    const [, setModalConfirmacion] = useGlobal("modalConfirmacion");
    
    const clickEliminar = (archivoId) => {
        props.onHide()
        activarModalConfirmacionEliminar(archivoId);
    }
    const activarModalConfirmacionEliminar = (archivoId) => {
        let mensaje = "¿Estás seguro que deseas eliminar este archivo?";
        let titulo = "Confirmación de acción";
        let aceptar = () => { eliminar(archivoId) };
        let cancelar = () => { props.onShow() }
        setModalConfirmacion({ mensaje, titulo, aceptar, cancelar })
    }
    const eliminar = async (archivoId) => {
        setModalConfirmacion({ mensaje: "", titulo: "" })
        setCargando(true)
        let res = await eliminarArchivoCargado(ciclo, archivoId);
        if (res.success) { props.obtenerDatosArchivos() }
        setCargando(false)
    }

    const eliminarTemplate = (rowData, column) => {
        let id = rowData["id"];
        return <button className="p-button p-component p-button-text-only" onClick={() => clickEliminar(id)}>Eliminar</button>
    }

    return (
        <Dialog header={`Empaque ${props.sitioNombre}`} style={{ width: "60vw" }}  {...props}>
            <ContenedorTabla >
                <DataTable emptyMessage={"No hay resultados"} value={props.archivos}>
                    <Column field="nombreArchivo" header="Nombre" sortable={true} />
                    <Column field="fechaFormatted" header="Fecha carga" sortField="dia" sortable={true} />
                    <Column header="Acciones" body={eliminarTemplate} />
                </DataTable>
            </ContenedorTabla>
        </Dialog>)
}
export default ModalArchivos;