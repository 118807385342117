import React, { useGlobal, useEffect, useState, useCallback } from "reactn";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { incrementarCampoDelCiclo, noHaSidoClausurado, obtenerCiclo } from '../../service/Ciclos';
import ContenedorHeader from "../common/ContenedorHeader";
import Firma from "../common/Firma";
import { POLOS_INVERNADERO, POLO_NORTE, METROS_CUADRADOS_POR_HECTAREA, POLO_SUR } from '../../constants';
import {
    obtenerRegistroEnfermedades, obtenerUnidadesAplicacionBiocontrol, estatusesOrdenesAplicacion, eliminarOrdenAplicacionBiocontrol,
    obtenerMetodosDeAplicacion, obtenerProductosDeAplicacion, obtenerEnfermedadesYPlagas, obtenerOrdenAplicacion, guardarOrdenAplicacion
} from '../../service/biocontrol';

import { dateToUnixTimeAsNumber13, DeepClone, dosDecimales, getCalendarDate, getNombrePorID, getObjetoPorID, separadoComas, tresDecimales } from "../../util/functions";
import { useWeekSelector } from "../common/WeekSelector";
import MapaInvernaderoDeSeleccion from "../common/mapa_invernadero/MapaInvernaderoDeSeleccion";
import { Message } from "primereact/message";
import OrdenAplicacionEstatusModal from "./modals/OrdenAplicacionEstatusModal";
import OrdenAplicacionEliminarModal from "./modals/OrdenAplicacionEliminarModal";
import { obtenerAreaInvernaderoSync } from "../../service/Invernaderos";
import FirmaAplicador from "components/common/FirmaAplicador";

const OrdenAplicacion = (props) => {
    const inicial = {
        estatus: estatusesOrdenesAplicacion[0],
        fecha_creacion: "",
        fechaAplicacion: "",
        horaInicioAplicacion: "",
        horaFinAplicacion: "",
        metodoAplicacion: "",

        orden_num: "",
        week: {},
        clarosSeleccionados: []
    }
    const mezclaInicial = {
        enfermedadesPorAtacar: "",
        productoPorAplicar: "",
        disolucion: "",
        totalAplicado: "",
        unidad: "",
        gastoAgua: "",
        valorDeDisolucion: ""
    }
    const enfermedadesPorAtacarPreseleccionadas = props.location.data?.enfermedadesSeleccionados || []
    const { invernaderoID, aplicacionID } = props.match.params
    const [, setCargando] = useGlobal("cargando")
    const [usuario,] = useGlobal("usuario");
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [ciclo] = useGlobal("ciclo")
    const [ciclos] = useGlobal("ciclos")
    const [enfermedades] = useGlobal("enfermedadesYPlagas")
    const [invernaderos] = useGlobal("invernaderosFisicos")
    const [unidades, setUnidades] = useGlobal("unidades")
    const [metodosDeAplicacion, setMetodosDeAplicacion] = useGlobal("metodosDeAplicacion")
    const [catalogoProductos, setCatalogoProductos] = useGlobal("catalogoProductos")
    const [datosCargados, setDatosCargados] = useGlobal("datosCargadosOrdenAplicacion")
    const [registrosClarosBiocontrol] = useGlobal("registrosClarosBiocontrol");

    const [invernaderoSeleccionado, setInvernaderoSeleccionado] = useGlobal("invernaderoFisico");
    const [metrosCuadradosSeleccionados, setMetrosCuadradosSeleccionados] = useState(0)
    const [metrosCuadradosAfectados, setMetrosCuadradosAfectados] = useState(0)
    const [numeroDeLineasSeleccionadas, setNumeroDeLineasSeleccionadas] = useState(0)
    const [editing] = useState(aplicacionID ? true : false)
    const [weeks, week, handleWeekChange, updateCicloWeekSelector] = useWeekSelector()
    const [state, setState] = useState(DeepClone(inicial))
    const [mezclasGeneradas, setMezclasGeneradas] = useState([{
        ...DeepClone(mezclaInicial),
        enfermedadesPorAtacar: enfermedadesPorAtacarPreseleccionadas
    }])
    const [errores, setErrores] = useState([])
    const [enfermedadesOrdenAplicacion, setEnfermedadesOrdenAplicacion] = useState(undefined)
    const [mapaInvernaderoFisico, setMapaInvernaderoFisico] = useState(undefined)
    const [mapaInvernaderoBeginState, setMapaInvernaderoBeginState] = useState(undefined)

    const [clarosSeleccionados, setClarosSeleccionados] = useState([])
    const [estatusModalOpen, setEstatusModalOpen] = useState(false)
    const [eliminarModalOpen, setEliminarModalOpen] = useState(false)
    const [totalClaros, setTotalClaros] = useState(0)

    const puedeEditar = () => noHaSidoClausurado(ciclo, ciclos) && ["Administrador", "Grower"].includes(usuario.rol) && state.estatus === "Activo";
    const puedeCambiarEstatus = () => puedeEditar() && editing;

    useEffect(() => {
        setNombreModulo("Biocontrol");
        setHabilitadoSelectorCiclo(false)
    }, [])
    useEffect(() => {
        setCargando(true)
        if (invernaderos) {
            let invernadero = invernaderoID ? invernaderos.find(el => el.id === invernaderoID) : invernaderos[0];
            if (!invernadero) props.history.goBack();//.replace("/biocontrol/orden-aplicacion/crear")
            else setInvernaderoSeleccionado(invernadero);
            updateCicloWeekSelector(ciclo)
        }
    }, [invernaderos])

    useEffect(() => {
        if (!datosCargados && week.nombre !== "Cargando")
            obtenerDatosIniciales()

        if (datosCargados && !editing)
            setEnfermedadesOrdenAplicacion(props.location.data?.enfermedadesSeleccionados || [])
        else if (datosCargados && editing)
            obtenerDatosOrdenAplicacion()
    }, [datosCargados, week])

    useEffect(() => {
        if (enfermedadesOrdenAplicacion)
            generateInvernaderoMap();
    }, [enfermedadesOrdenAplicacion])
    useEffect(() => {
        if (mapaInvernaderoFisico)
            actualizarResumenDeClaros(mapaInvernaderoFisico);
    }, [mapaInvernaderoFisico])
    useEffect(() => {
        setErrores([])
    }, [state, mezclasGeneradas])

    const obtenerDatosIniciales = async () => {
        await obtenerProductosDeAplicacion()
        await obtenerMetodosDeAplicacion()
        await obtenerUnidadesAplicacionBiocontrol()
        if (!enfermedades) await obtenerEnfermedadesYPlagas()
        setDatosCargados(true)
    }
    const obtenerDatosOrdenAplicacion = async () => {
        setCargando(true)
        let orden = await obtenerOrdenAplicacion(ciclo, aplicacionID)
        if (!orden) props.history.goBack();
        else {
            let mezclasGeneradas = orden.mezclasGeneradas
            let enfermedadesOrden = orden.enfermedadesOrdenAplicacion || []
            let clarosMapaSeleccionados = orden.clarosSeleccionados || []
            delete orden.mezclasGeneradas
            delete orden.enfermedadesOrdenAplicacion
            delete orden.clarosSeleccionados
            setState(orden)
            setMezclasGeneradas(mezclasGeneradas)
            setClarosSeleccionados(clarosMapaSeleccionados)
            setEnfermedadesOrdenAplicacion(enfermedadesOrden)
        }
    }

    const generateInvernaderoMap = async () => {
        setCargando(true)
        let numClaros = 0;

        let mapaInvernadero = []
        POLOS_INVERNADERO.forEach((polo) => {

            let mapaPolo = []
            invernaderoSeleccionado.capillas.forEach((capilla, capillaIndex) => {
                let clarosPorLinea = polo.toLowerCase() == POLO_NORTE ? invernaderoSeleccionado.claros_por_linea_norte : invernaderoSeleccionado.claros_por_linea_sur
                let lineasPorCapilla = polo.toLowerCase() == POLO_NORTE ? capilla.lineas_norte : capilla.lineas_sur

                let mapaCapilla = []
                for (let lineaIndex = 0; lineaIndex < lineasPorCapilla; lineaIndex++) {

                    var mapaLinea = []
                    for (let claroIndex = 0; claroIndex < clarosPorLinea; claroIndex++) {
                        numClaros++;
                        let seleccionado = clarosSeleccionados.some(claro => claro.c_polo === polo.toLowerCase() && claro.c_capilla === capillaIndex && claro.c_linea === lineaIndex && claro.c_claro === claroIndex)
                        mapaLinea.push({ conIncidencia: false, seleccionado });
                    }

                    mapaCapilla.push(mapaLinea)
                } // líneas
                mapaPolo.push(mapaCapilla)
            });// capillas
            mapaInvernadero.push(mapaPolo)
        }); // polos

        setTotalClaros(numClaros)
        cargarClarosConEnfermedad(mapaInvernadero)
    }
    const cargarClarosConEnfermedad = async (mapa) => {
        let mapaActualizado = Array.from(mapa)

        if (enfermedadesOrdenAplicacion.length > 0) {
            let registroEnfermedades = await obtenerRegistroEnfermedades(ciclo, invernaderoID, week.time, registrosClarosBiocontrol)

            registroEnfermedades.forEach(claro => {
                let preseleccionoIncidenciaDeClaro = claro.enfermedades.some(registro => enfermedadesOrdenAplicacion.includes(registro.enfermedad_ref));
                let poloIndex = claro.c_polo === POLO_NORTE.toLowerCase() ? 0 : 1
                mapaActualizado[poloIndex][claro.c_capilla][claro.c_linea][claro.c_claro].conIncidencia = preseleccionoIncidenciaDeClaro
            });
        }
        setMapaInvernaderoBeginState(mapaActualizado)
        setCargando(false)
    }

    const actualizarResumenDeClaros = (mapa) => {
        let numeroDeClarosAfectados = 0, numeroDeClarosSeleccionados = 0, numeroDeLineasSeleccionadas = 0, clarosSeleccionadosRef = [], numClaros = 0;
        mapa.forEach((polo, polIndex) => {
            polo.forEach((capilla, capIndex) => {
                capilla.forEach((linea, lineaIndex) => {
                    numeroDeClarosAfectados += linea.filter(claro => claro.conIncidencia).length
                    numeroDeClarosSeleccionados += linea.filter(claro => claro.seleccionado).length
                    numeroDeLineasSeleccionadas += linea.some((claro) => claro.seleccionado) ? 1 : 0

                    let claros = linea.map((claro, claroIndex) => ({ c_polo: polIndex === 1 ? POLO_SUR : POLO_NORTE, c_capilla: capIndex, c_linea: lineaIndex, c_claro: claroIndex }))
                    clarosSeleccionadosRef.push(...claros.filter((claro, index) => linea[index].seleccionado))
                });
            });
        });
        let metrosCuadradosPorClaro = invernaderoSeleccionado.area / totalClaros;
        let numeroDeMetrosCuadradosAfectados = dosDecimales(numeroDeClarosAfectados * metrosCuadradosPorClaro)
        let numeroDeMetrosCuadradosSeleccionados = dosDecimales(numeroDeClarosSeleccionados * metrosCuadradosPorClaro)

        setMetrosCuadradosAfectados(numeroDeMetrosCuadradosAfectados)
        setMetrosCuadradosSeleccionados(numeroDeMetrosCuadradosSeleccionados)
        setNumeroDeLineasSeleccionadas(numeroDeLineasSeleccionadas)
        setClarosSeleccionados(clarosSeleccionadosRef)
    }

    const guardarOrden = async () => {
        if (validar()) {
            setCargando(true)
            try {
                let datos = {
                    ...state, mezclasGeneradas, week,
                    orden_num: await getOrdenNum(),
                    solicitante: usuario.uid,
                    fecha_creacion: editing ? state.fecha_creacion : Date.now(),
                    enfermedadesOrdenAplicacion, clarosSeleccionados,
                    invernadero_fisico_ref: invernaderoSeleccionado.id
                }
                await guardarOrdenAplicacion(ciclo, datos, aplicacionID)
                setCargando(false)
                props.history.goBack()
            } catch (err) {
                console.log(err)
                setCargando(false)
            }
        }
    }
    const validar = () => getErroresFormulario().length === 0
    const getErroresFormulario = () => {
        let erroreStateNombres = ["fechaAplicacion", "horaInicioAplicacion", "horaFinAplicacion", "metodoAplicacion"]
        let erroreMezclasNombres = ["enfermedadesPorAtacar", "productoPorAplicar", "dosis", "disolucion", "totalAplicado", "unidad", "gastoAgua", "valorDeDisolucion"]
        let errores = erroreStateNombres.filter(nombre => !state[nombre])

        mezclasGeneradas.forEach((item, index) => {
            let filtered = erroreMezclasNombres.filter(nombre => {
                if (nombre === "valorDeDisolucion") return !parseFloat(item[nombre])
                return !item[nombre]
            })
            errores.push(...filtered.map(nombre => `${nombre}${index}`))
        })

        setErrores(errores)
        return errores
    }
    const getOrdenNum = async () => {
        let cicloOBJ = await obtenerCiclo(ciclo)
        let numeroOrdenes = 1 + (cicloOBJ.total_ordenes_aplicacion_BIO || 0)
        await incrementarCampoDelCiclo(ciclo, "total_ordenes_aplicacion_BIO").catch(err => window.alert("Error incrementar: ", err))
        return numeroOrdenes
    }

    const handleInputChange = (event) => {
        let stateCopy = DeepClone(state)
        stateCopy[event.target.name] = event.target.value
        setState(stateCopy)
    }
    const handleCalendarInputChange = (event) => {
        handleInputChange({ target: { name: event.target.name, value: dateToUnixTimeAsNumber13(event.target.value) } })
    }
    const handleMezclasChange = (event, index, disoluciones) => {
        let mezclasCopy = DeepClone(mezclasGeneradas)
        let name = event.target.name
        mezclasCopy[index][name] = event.target.value

        if (name === "productoPorAplicar") {
            mezclasCopy[index].dosis = ""
            mezclasCopy[index].valorDeDisolucion = ""
        }
        if (name === "productoPorAplicar" || name === "dosis") {
            mezclasCopy[index].disolucion = ""
            mezclasCopy[index].valorDeDisolucion = ""
        }
        if (name === "disolucion") {
            const { dissolutionName } = disoluciones.find(el => event.target.value === el.dissolutionValue)
            //console.log("dissolutionName ", dissolutionName)
            mezclasCopy[index].valorDeDisolucion = parseFloat(dissolutionName.split(" ")[2].split(")")[0])
        }
        setMezclasGeneradas(mezclasCopy)
    }

    const filtrarCatalogoProductos = (enfermedadesPorAtacar) => {
        let filtered = catalogoProductos.filter(producto => producto.enfermedades.some(enfermedad => enfermedadesPorAtacar.includes(enfermedad)))
        let filteredIDs = filtered.map(producto => producto.id)
        return [filtered, filteredIDs]
    }
    const eliminarMezcla = (index) => {
        let mezclasCopy = DeepClone(mezclasGeneradas)
        mezclasCopy.splice(index, 1)
        setMezclasGeneradas(mezclasCopy)
    }
    //const Memoized = React.memo((props) => (<MapaInvernaderoDeSeleccion {...props} />));
    const setMapaInvernaderoFisicoCall = useCallback((mapa) => setMapaInvernaderoFisico(mapa), []);
    //console.log({mezclasGeneradas})

    return (
        <div className="p-grid ">
            {invernaderoSeleccionado && <ContenedorHeader col="p-col p-col-auto" titulo={`${editing ? "Editar" : "Crear"} orden de aplicación`} subtituloInv={editing ? `#${state.orden_num}` : ""}
                invernaderoNombre={invernaderoSeleccionado.nombre} iconos={`icon-general icon-id bor-${invernaderoSeleccionado.color} bg-${invernaderoSeleccionado.color} `} atras={() => props.history.goBack()} />}

            {mapaInvernaderoBeginState?.length > 0 && <MapaInvernaderoDeSeleccion puedeEditar={puedeEditar()}
                mapaInvernaderoPassed={mapaInvernaderoBeginState} editing={editing} setMapaInvernaderoFisico={setMapaInvernaderoFisicoCall} />}

            <p className="section-title">Áreas y líneas de la aplicación</p>
            <div className="bio-data card p-col-12">
                <div className="p-grid p-justify-center text-center">
                    <div className="p-col">
                        <div className="card-block">
                            <div className="value">
                                {separadoComas(metrosCuadradosSeleccionados)}<span className="label-unidad">m²</span>
                            </div>
                            <div className="label"> Metros cuadrados seleccionados</div>
                        </div>
                    </div>
                    <div className="p-col">
                        <div className="card-block">
                            <div className="value">
                                {separadoComas(metrosCuadradosAfectados)}<span className="label-unidad">m²</span>
                            </div>
                            <div className="label">Metros cuadrados afectados</div>
                        </div>
                    </div>
                    {mapaInvernaderoFisico && <>
                        <div className="p-col">
                            <div className="card-block">
                                <div className="value">
                                    {(metrosCuadradosSeleccionados / METROS_CUADRADOS_POR_HECTAREA).toFixed(2)}<span className="label-unidad">ha</span>
                                </div>
                                <div className="label">Superficie aplicada</div>
                            </div>
                        </div>
                        <div className="p-col">
                            <div className="card-block">
                                <div className="value">
                                    {numeroDeLineasSeleccionadas}<span className="label-unidad">lineas</span>
                                </div>
                                <div className="label">Líneas aplicadas</div>
                            </div>
                        </div>
                    </>}
                </div>
            </div>

            <p className="section-title">Información general de la aplicación</p>
            <div className="card p-col-12">
                <div className="p-grid">
                    {enfermedadesOrdenAplicacion?.length > 0 &&
                        <div className="form-group p-col-12 ">
                            <label >Enfermedades seleccionadas: </label>
                            <label>{enfermedadesOrdenAplicacion.map(el => getNombrePorID(enfermedades, el)).join(", ")}</label>
                        </div>}
                    <div className="form-group p-col-12 p-md-3 p-sm-6">
                        <label htmlFor="fechaAplicacion">Fecha de aplicación </label>
                        <Calendar id="fechaAplicacion" dateFormat="dd/mm/yy" readOnlyInput={true} monthNavigator={false} yearNavigator={false} yearRange="1900:2030"
                            name="fechaAplicacion" disabled={!puedeEditar()} value={getCalendarDate(state.fechaAplicacion)} onChange={handleCalendarInputChange} />
                        {errores.includes("fechaAplicacion") && <Message severity="error" text="Este campo es requerido" />}
                    </div>
                    <div className="form-group p-col-12 p-md-3 p-sm-6">
                        <label htmlFor="horaInicioAplicacion">Hora inicial</label>
                        <Calendar id="horaInicioAplicacion" disabled={!puedeEditar()} readOnlyInput={false} timeOnly={true} hourFormat="12"
                            name="horaInicioAplicacion" value={getCalendarDate(state.horaInicioAplicacion)} onChange={handleCalendarInputChange} />
                        {errores.includes("horaInicioAplicacion") && <Message severity="error" text="Este campo es requerido" />}
                    </div>
                    <div className="form-group p-col-12 p-md-3 p-sm-6">
                        <label htmlFor="horaFinAplicacion">Hora final</label>
                        <Calendar id="horaFinAplicacion" disabled={!puedeEditar()} readOnlyInput={false} timeOnly={true} hourFormat="12"
                            name="horaFinAplicacion" value={getCalendarDate(state.horaFinAplicacion)} onChange={handleCalendarInputChange} />
                        {errores.includes("horaFinAplicacion") && <Message severity="error" text="Este campo es requerido" />}
                    </div>
                    <div className="form-group p-col-12 p-md-3 p-sm-6">
                        <label htmlFor="metodoAplicacion">Método de aplicación</label>
                        <Dropdown id="metodoAplicacion" name="metodoAplicacion" placeholder="Seleccionar" optionValue="id" optionLabel="nombre"
                            disabled={!puedeEditar()} options={metodosDeAplicacion} value={state.metodoAplicacion} onChange={handleInputChange} />
                        {errores.includes("metodoAplicacion") && <Message severity="error" text="Este campo es requerido" />}
                    </div>

                    <div className="form-group p-col-12 p-md-12">
                        <label htmlFor="comentarioFinal">Comentario final</label>
                        <InputTextarea id="comentarioFinal" name="comentarioFinal" disabled={!puedeEditar()} rows={3} cols={30}
                            autoResize={true} value={state.comentarioFinal} onChange={handleInputChange} />
                    </div>
                </div>
            </div>

            <p className="section-title">Productos y mezclas</p>
            {catalogoProductos && mezclasGeneradas.map((mezcla, index) => {
                const mezclaProducto = getObjetoPorID(catalogoProductos, mezcla.productoPorAplicar);
                const mezclaDosis = mezclaProducto.dosis?.find(el => el.valor === mezcla.dosis) || "";
                const disoluciones = mezclaDosis?.detalles?.map(el => ({ dissolutionName: `${el.nombre} (sugerido: ${tresDecimales(el.valor)})`, dissolutionValue: el.nombre })) || [];

                if(mezcla.disolucion && mezcla.valorDeDisolucion === undefined){
                    const { dissolutionName } = disoluciones.find(el => mezcla.disolucion === el.dissolutionValue)
                    const value = parseFloat(dissolutionName.split(" ")[2].split(")")[0])
                    handleMezclasChange({ target: { name: "valorDeDisolucion", value} }, index)
                }

                const [catalogoProductosFiltered, catalogoProductosIDs] = filtrarCatalogoProductos(mezcla.enfermedadesPorAtacar)
                if (mezcla.productoPorAplicar && !catalogoProductosIDs.includes(mezcla.productoPorAplicar))
                    handleMezclasChange({ target: { name: "productoPorAplicar", value: "" } }, index)

                return (
                    <div key={`mez-gen-${index}`} className="card p-col-12">
                        <div className="p-grid">
                            <div className="p-col-9 p-grid">
                                <div className="form-group p-col-12 p-md-6 p-sm-6">
                                    <label htmlFor="enfermedadesPorAtacar">Incidencias por atacar</label>
                                    <MultiSelect id="enfermedadesPorAtacar" name="enfermedadesPorAtacar" disabled={!puedeEditar()} selectedItemsLabel={`${mezcla?.enfermedadesPorAtacar?.length || 0} incidencias seleccionadas`} filter={true}
                                        optionValue="id" optionLabel="nombre" options={enfermedades || []} value={mezcla.enfermedadesPorAtacar || []} onChange={e => handleMezclasChange(e, index)} />
                                    {errores.includes(`enfermedadesPorAtacar${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                </div>
                                <div className="form-group p-col-12 p-md-6 p-sm-6">
                                    <label htmlFor="productoPorAplicar">Producto por aplicar</label>
                                    <Dropdown id="productoPorAplicar" name="productoPorAplicar" placeholder="Seleccionar" disabled={!puedeEditar()} optionValue="id" optionLabel="nombre"
                                        options={catalogoProductosFiltered} value={mezcla.productoPorAplicar} onChange={e => handleMezclasChange(e, index)} />
                                    {errores.includes(`productoPorAplicar${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                </div>
                                <div className="form-group p-col-12 p-md-3 p-sm-3">
                                    <label htmlFor="dosis">Dosis</label>
                                    <Dropdown id="dosis" name="dosis" placeholder="Seleccionar" disabled={!puedeEditar()} optionValue="valor"
                                        optionLabel="nombre" options={mezclaProducto.dosis} value={mezcla.dosis} onChange={e => handleMezclasChange(e, index)} />
                                    {errores.includes(`dosis${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                </div>
                                <div className="form-group p-col-12 p-md-3 p-sm-3">
                                    <label htmlFor="detalleDosis">Detalle dosis</label>
                                    <span className="form-output">{mezclaDosis.valor}</span>
                                </div>
                                <div className="form-group p-col-12 p-md-3 p-sm-3">
                                    <label htmlFor="disolucion">Disolución</label>
                                    <Dropdown id="disolucion" name="disolucion" placeholder="Seleccionar" disabled={!puedeEditar()} optionValue="dissolutionValue"
                                        optionLabel="dissolutionName" options={disoluciones} value={mezcla.disolucion} onChange={e => handleMezclasChange(e, index, disoluciones)} />
                                    {errores.includes(`disolucion${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                </div>

                                <div className="form-group p-col-12 p-md-3 p-sm-3">
                                    <label htmlFor="valorDeDisolucion">Valor de la disolución</label>
                                    <InputText keyfilter="num" id="valorDeDisolucionid" name="valorDeDisolucion" disabled={!puedeEditar() || !mezcla.disolucion} value={mezcla.valorDeDisolucion} onChange={e => handleMezclasChange(e, index)} />
                                    {errores.includes(`totalAplicado${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                </div>
                                <div className="form-group p-col-12 p-md-4 p-sm-4">
                                    <label htmlFor="totalAplicado">Total aplicado</label>
                                    <InputText id="totalAplicado" name="totalAplicado" disabled={!puedeEditar()} value={mezcla.totalAplicado} onChange={e => handleMezclasChange(e, index)} />
                                    {errores.includes(`totalAplicado${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                </div>
                                <div className="form-group p-col-12 p-md-4 p-sm-4">
                                    <label htmlFor="unidad">Unidad de aplicación</label>
                                    <Dropdown id="unidad" name="unidad" optionValue="id" optionLabel="nombre" placeholder="Seleccionar" value={mezcla.unidad}
                                        disabled={!puedeEditar()} options={unidades} onChange={e => handleMezclasChange(e, index)} />
                                    {errores.includes(`unidad${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                </div>
                                <div className="form-group p-col-12 p-md-4 p-sm-4">
                                    <label htmlFor="gastoAgua">Gasto de agua</label>
                                    <InputText id="gastoAgua" name="gastoAgua" disabled={!puedeEditar()} value={mezcla.gastoAgua} onChange={e => handleMezclasChange(e, index)} />
                                    {errores.includes(`gastoAgua${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                </div>
                            </div>

                            <div className="p-col-3 p-grid">
                                <div className="form-group p-col-12">
                                    <label htmlFor="recomendacionDosis">Recomendación dosis</label>
                                    <span className="form-output">
                                        {mezclaProducto.recomendacion_dosis
                                            ? <ul><li>{mezclaProducto.recomendacion_dosis}</li></ul>
                                            : <span>Ninguna</span>}
                                    </span>
                                </div>
                                <div className="form-group p-col-12 ">
                                    <label htmlFor="detallesProducto">Detalles de producto</label>
                                    <span className="form-output">
                                        <ul>
                                            <li>RSCO_EPA: {mezclaProducto.rsco_epa}</li>
                                            <li>I.A.: {mezclaProducto.i_a}</li>
                                            <li>I.S.: {mezclaProducto.i_s}</li>
                                            <li>TIEMPO REENTRADA: {mezclaProducto.t_r}</li>
                                            <li>Color: {mezclaProducto.color || "white"}</li>
                                        </ul>
                                    </span>
                                </div>
                            </div>
                        </div>
                        {puedeEditar() && mezclasGeneradas.length !== 1 &&
                            <Button className="p-button-secondary p-col-12 " label="Eliminar producto de mezcla" onClick={() => eliminarMezcla(index)} />}
                    </div>)
            })}
            {puedeEditar() && <Button className=" p-col-12 " label="+ Agregar producto a mezcla" onClick={() => { setMezclasGeneradas([...mezclasGeneradas, DeepClone(mezclaInicial)]) }} />}

            <OrdenAplicacionEstatusModal visible={estatusModalOpen} hide={() => setEstatusModalOpen(false)} usuario={usuario} ciclo={ciclo} aplicacionID={aplicacionID} />
            <OrdenAplicacionEliminarModal visible={eliminarModalOpen} hide={() => setEliminarModalOpen(false)} ciclo={ciclo} aplicacionID={aplicacionID} />

            {state.firmaGrowerRef && <div className="p-col-12">
                <div className="p-grid p-justify-center">
                    <FirmaAplicador/>
                    <Firma 
                        showJustPrinting={true} 
                        imagen={state.firmaGrowerURL} 
                        nombre={state.firmaGrowerNombre} 
                        rol={state.firmaGrowerRol} 
                        col="6"
                    />
                </div>
            </div>}

            <div className="p-col-12">
                <div className="p-grid p-justify-end btn-row">
                    {puedeCambiarEstatus() && <Button label="Finalizar orden" onClick={() => setEstatusModalOpen(true)} />}
                    {puedeCambiarEstatus() && <Button label="Eliminar orden" onClick={() => setEliminarModalOpen(true)} />}
                    {puedeEditar() && <Button label="Guardar orden" onClick={guardarOrden} />}
                </div>
            </div>
        </div>
    );
}
export default OrdenAplicacion;//488