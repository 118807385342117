//react/reactn
import React, { useEffect, useGlobal, setGlobal } from 'reactn';
//Componentes internos
import ContenedorHeader from '../../common/ContenedorHeader';
import ContenedorPrincipal from '../../planeacion_mantenimiento/CapturaAgentes/ContenedorPrincipal';
import ContenedorTabMenu from './componentes/ContenedorTabMenu.mole';
import SeccionProductosTablas from './componentes/SeccionProductosTablas.organ';
import TablaVariedades from './componentes/TablaVariedades.mole';
import TablaColores from './componentes/TablaColores.mole';
//Constantes
import { LABEL_PRODUCTOS, LABEL_VARIEDADES, LABEL_COLORES } from '../../../service/constants/ProductosYVariedades.const';

const VerProductosYVariedades = (props) => {
    //Datos globales de sistema 
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    //datos del modulo
    const [activeTab] = useGlobal("activeTabProductosYVariedades");

    useEffect(() => {
        setNombreModulo("Productos y variedades");
        setHabilitadoSelectorCiclo(true);
        setGlobal({productoMezclado: null, variedad: null, colorVariedad: null});//Al editar un variedad, y luego crear, el formulario va ser pre-llenado (es un error)
    }, [])

    const productosVisible = activeTab?.label === LABEL_PRODUCTOS;
    const variedadesVisible = activeTab?.label === LABEL_VARIEDADES;
    const coloresVisible = activeTab?.label === LABEL_COLORES;

    return (
        <ContenedorPrincipal>
            <ContenedorHeader col="p-col p-col-auto" titulo="Productos y variedades" iconos="presentacion-icon" />

            <ContenedorTabMenu history={props.history}>
                <SeccionProductosTablas visible={productosVisible} history={props.history} />

                <TablaVariedades visible={variedadesVisible} history={props.history} />

                <TablaColores visible={coloresVisible} history={props.history} />
            </ContenedorTabMenu>

        </ContenedorPrincipal>
    );
}
export default VerProductosYVariedades;