import React, { useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import { useGlobal } from 'reactn';
import { withRouter } from 'react-router-dom';
import { dosDecimales } from '../../../util/functions';

const CardProducto = (props) => {
  const [, setPresetProducto] = useGlobal("presetProducto");
  const [, setPresetEmpaque] = useGlobal("presetEmpaque");
  const [cajasEmpleados, setCajasEmpleados] = useState(0)

  useEffect(() => {
    const datosProductos = props.empleadoCajasDatos?.productosDatos?.[props.producto.id] || {}

    if (props.empleadoCajasDatos && Object.keys(datosProductos).length > 0) {
      const sumEmpleados = Object.keys(datosProductos).length
      let sum = 0;
      for (const codigoEmpleado in datosProductos) {
        sum += datosProductos[codigoEmpleado]
      }
      const promedio = sumEmpleados ? dosDecimales(sum / sumEmpleados) : 0
      setCajasEmpleados(promedio)
    } else setCajasEmpleados(0)
  }, [props.empleadoCajasDatos])

  const consultar = (params) => {
    setPresetProducto(props.producto.id);
    setPresetEmpaque(props.empaque.id);
    props.history.push("/nomina_empaque/consultar");
  }
  const header = <span className="card-header">
    <div className={`card-id icon-general icon-id bor-${props.producto.nombre}`} />
    <p className="card-title">{props.producto.nombre} proyección</p>
  </span>;

  return (
    <div className="p-col " onClick={consultar}>
      <Card header={header} className={`htg-card card-${props.producto.nombre} `} >
        <div className="p-grid p-justify-center text-center">
          <div className="p-col">

            <div className="card-block">
              <p className="value">
                {//Math.round(props.empaque.productividad.find(el => el.producto_id === props.producto.id).valor)
                  cajasEmpleados}
                <span class="label-unidad">cajas/empleado</span>
              </p>
              <p className="label">CIERRE DE EMPAQUE</p>
            </div>
          </div>

          <div className="card-btns"></div>
        </div>
      </Card>
    </div>)
}
export default withRouter(CardProducto);