import React, { useGlobal, useState, useEffect, } from "reactn";
import { Dropdown } from 'primereact/dropdown';
import { TabMenu } from 'primereact/tabmenu';
import {
    obtenerMedidasCultivo, guardarRegistroCultivo,
    obtenerRegistrosSemanalesCultivo, obtenerOutputsRegistroCultivo,
    identificarInvernaderosConRegistro
} from '../../service/Cultivo';
import { nombresDiasSemanaExtendida } from "../../constants";
import TablaCultivo from "./TablaCultivo";
import { obtenerCiclo, noHaSidoClausurado } from "../../service/Ciclos";
import { obtenerNombreDiaSemana } from "../../service/fechas";
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { MultiSelect } from 'primereact/multiselect';

import ContenedorFiltro from '../common/ContenedorFiltro'
import { getProductoYVariedadString } from "../../util/functions";
import { getProductoBaseDeInvernadero } from "service/ProductosYVariedades";
const mapeoTabs = {
    "Clima Exterior": "exterior",
    "Clima Interior": "interior",
    "Riego": "riego",
    "Cultivo": "cultivo"
}

export function Registrar(props) {
    const [invernadero, setInvernadero] = useGlobal("invernadero");
    const [invernaderos, setInvernaderos] = useGlobal("invernaderosInternos");
    const [ciclo] = useGlobal("ciclo");
    const [ciclos] = useGlobal("ciclos");

    const [invernaderosCopiables, setInvernaderosCopiables] = useState([]);
    const [invernaderosACopiar, setInvernaderosACopiar] = useState([])
    const [tabs] = useState([{ label: "Clima Exterior" }, { label: "Clima Interior" }, { label: "Riego" }, { label: "Cultivo" }])
    const [outputs, setOutputs] = useState(["", "", ""]);
    const [, setCargando] = useGlobal("cargando");
    const [weeks, week, handleWeekChange] = useWeekSelector(true);
    const [invernaderosVirtuales] = useGlobal("invernaderosInternos");
    const [gotMedidas, setGotMedidas] = useState(false);
    const [activeTab, setActiveTab] = useState(null);
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [productosBases] = useGlobal("productosBases");
    const [medleysGeneral] = useGlobal("medleysGeneral");

    useEffect(() => {
        setNombreModulo("Cultivo");
        setHabilitadoSelectorCiclo(true)
    }, [])

    useEffect(() => {
        if (tabs) {
            setActiveTab(tabs[0])
        }
    }, [tabs])

    useEffect(() => {
        if (invernadero) {
            obtenerMedidasCultivo()
                .then((resp) => { procesarMedidasCultivo(resp) }).catch(err => {
                    console.log("ERROR ")
                    console.log(err);
                })
            setCargando(true);
        }
    }, [invernadero])

    useEffect(() => {
        if (invernadero && invernaderosVirtuales.length > 0) {
            actualizarCopiables();
        }
    }, [invernadero, invernaderosVirtuales, week])

    useEffect(() => {
        if (week && invernadero && gotMedidas) {
            limpiarEstado();

            obtenerOutputsRegistroCultivo(ciclo, invernadero, week.time).then((outputs) => {
                obtenerRegistrosSemanalesCultivo(ciclo, invernadero.id, week.time).then((result) => {
                    actualizarFormularioConRegistros(result, outputs);
                    setCargando(false);
                }).catch((err) => {
                    console.log("ERROR AL OBTENER REGISTROS SEMANALES");
                    console.log(err);
                });
            }).catch((err) => {
                console.log("ERROR OUTPUTS");
                console.log(err);
            });
        }
    }, [week, invernadero, gotMedidas])

    const atras = (params) => {
        props.history.goBack();
    }

    const [estado, setEstado] = useState({
        exterior: [],
        interior: [],
        riego: [],
        cultivo: []
    })

    const cancelar = (params) => {
        props.history.goBack();
    }

    const guardar = () => {
        setCargando(true);
        let promises = [guardarRegistroCultivo(ciclo, invernadero.id, week.time, estado)];
        invernaderosACopiar.forEach(invernadero => {
            promises.push(guardarRegistroCultivo(ciclo, invernadero, week.time, estado));
        })

        Promise.all(promises).then(resp => {
            props.history.goBack();
        }).catch(err => {
            console.log("HUBO UN ERROR AL GUARDAR");
            console.log(err);
        }).finally(el => {
            setCargando(false);
        })
    }

    const actualizarCopiables = async (params) => {

        let copiables = invernaderosVirtuales.filter(el => el.id !== invernadero.id && (el["configuracion_inicial_cultivo"])).map(el => ({ ...el }));
        let conRegistro = await identificarInvernaderosConRegistro(ciclo, week.time, copiables);
        copiables.forEach(invernadero => {
            let found = conRegistro.find(el => el.id === invernadero.id);
            if (found && found["tiene_registro"]) {
                invernadero["nombre"] = `${invernadero["nombre"]} - Con registros`
            }
        })
        setInvernaderosCopiables(copiables);
    }

    const limpiarEstado = () => {
        const secciones = ["exterior", "interior", "riego", "cultivo"];
        let copia = Object.assign({}, estado);
        secciones.forEach(seccion => {
            copia[seccion].forEach(row => {
                nombresDiasSemanaExtendida.forEach(dia => {
                    row[dia] = "";
                });
            });
        });
        setEstado(copia);
    }

    const actualizarFormularioConRegistros = (result, outputs) => {
        setOutputs(outputs);
        const secciones = ["exterior", "interior", "riego", "cultivo"];
        let copia = Object.assign({}, estado);
        secciones.forEach(seccion => {
            copia[seccion].forEach(row => {
                nombresDiasSemanaExtendida.forEach(dia => {
                    let diaBuscado = result.find(el => {
                        return obtenerNombreDiaSemana(el.dia).toUpperCase() === dia.toUpperCase()
                    })

                    if (diaBuscado) {
                        let buscado = diaBuscado["medidas"].find(el => {
                            return el.medicion_ref === row.id
                        })
                        if (buscado) {
                            row[dia] = buscado.medicion
                        }
                    }

                })
            })
        });
        setEstado(copia);
    }

    const procesarMedidasCultivo = (medidas) => {
        let estado = {
            exterior: [],
            interior: [],
            riego: [],
            cultivo: []
        }
        //console.log("invernadero?.producto_ref: ", invernadero?.producto_ref)
        medidas.forEach(medida => {
            //console.log("MEDIDAS: ", medida?.tooltips?.[invernadero?.producto_ref])
            const producto = getProductoBaseDeInvernadero(productosBases, medleysGeneral, invernadero)
            let tooltipsProducto = (medida?.tooltips && producto.id) ? medida.tooltips?.[producto.id] : undefined;
            let min = tooltipsProducto ? tooltipsProducto["min"] : "";
            let max = tooltipsProducto ? tooltipsProducto["max"] : "";

            if (invernadero?.producto_ref === "mo81IbNKbsehHb0YFbPy") console.log("MIN: ", { nombre: medida.nombre, min })



            let tooltip = (min !== "" && max !== "")
                ? `<p class='tooltip-title'>Valores recomendados</p>
                    <div class = 'tooltip-datas'>
                        <div class = 'tooltip-data'>
                        ${min} ${medida["unidades"] || ""}
                        <p class='tooltip-label'>Mínimo</p>
                        </div>
                        <div class = 'tooltip-data'> 
                        ${max} ${medida["unidades"] || ""}
                        <p class='tooltip-label'>Máximo</p>
                        </div>
                    </div>`
                : "";
            let estructura = {
                id: medida.id,
                nombre: medida.nombre,
                unidades: medida.unidades,
                tooltip_registro: tooltip,
                tooltip_reporte: tooltip
            }
            nombresDiasSemanaExtendida.forEach(dia => {
                estructura[dia] = ""
            })
            estado[medida["tipo"]].push(estructura);
        });
        console.log("ESTADO: ", estado)
        setEstado({ ...estado });
        setGotMedidas(Date.now());
    }

    const irReporteHistorico = () => { props.history.push("/cultivo/historico"); }
    const irReporte = () => { props.history.push("/cultivo/reporte"); }
    const irGrafica = () => { props.history.push({ pathname: "/cultivo/graficas/medidas", data: { invernadero: invernadero } }); }
    const irGraficaPorMedicion = () => { props.history.push({ pathname: "/cultivo/graficas/invernaderos", data: { invernadero: invernadero } }) }

    return (<div className="p-grid p-justify-center">
        <div className="p-col p-col-auto title-container">
            <span className="back-btn" onClick={atras}></span>
            {invernadero &&
                <React.Fragment>
                    <span className={`icon-general icon-id bor-${invernadero.color} bg-${invernadero.color} ${invernadero.habilitado ? "" : "Deshabilitado"}`}>{invernadero.nombre}</span>
                </React.Fragment>}
            <span className="titles-group">
                <h1 className="header-title">Registro de cultivo</h1>
                {invernadero?.producto_ref &&
                    <p className="header-subtitle">
                        {getProductoYVariedadString(invernadero)}
                    </p>}
            </span>
        </div>
        <div className="p-col p-col-auto">
            <div className="btn-row">
                <Button label="Reporte histórico de cultivo" onClick={irReporteHistorico} />
                <Button label="Reporte general de cultivo" onClick={irReporte} />
                <Button className="reactbtn-icon" onClick={irGrafica} label="Mediciones de cultivo">
                    <FontAwesomeIcon icon={faChartBar} />
                </Button>
                <Button className="reactbtn-icon" onClick={irGraficaPorMedicion} label="Invernaderos">
                    <FontAwesomeIcon icon={faChartBar} />
                </Button>
            </div>
        </div>
        <div className="p-col-12">
            <div className="p-grid filtros">
                <div className="form-group p-col">
                    <label htmlFor="cinco">Invernadero</label>
                    <Dropdown id="filtroInvernadero" placeholder="Seleccionar"
                        dataKey="id" optionLabel="nombre"
                        options={invernaderos} value={invernadero}
                        onChange={(e) => { setInvernadero(e.target.value) }} />
                </div>

                <WeekSelector weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
                <ContenedorFiltro label="Copiar a" sinFiltrarTexto={true}>
                    <MultiSelect options={invernaderosCopiables} value={invernaderosACopiar} onChange={(e) => setInvernaderosACopiar(e.value)}
                        optionLabel="nombre" optionValue="id" selectedItemsLabel={`${invernaderosACopiar.length} invernaderos seleccionados`} />
                </ContenedorFiltro>
            </div>
        </div>

        <div className="p-col-12">
            <TabMenu model={tabs} activeItem={activeTab} onTabChange={(e) => setActiveTab(e.value)} />
            <div className="card p-0">
                {activeTab &&
                    <TablaCultivo inputs={estado[mapeoTabs[activeTab["label"]]]} tipo={mapeoTabs[activeTab["label"]]}
                        outputs={outputs} semana={week.time} datosCompletos={estado} invernadero={invernadero} />}
            </div>
        </div>
        <div className="p-col-12">
            <div className="p-grid p-justify-end btn-row">
                <Button label="Cancelar" className="p-button-secondary" onClick={cancelar} />
                {noHaSidoClausurado(ciclo, ciclos) && <Button label="Guardar" onClick={guardar} />}
            </div>
        </div>
    </div>)
}
export default Registrar;//386