import React, {  useGlobal } from 'reactn';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HTGCard from '../common/cards/HTGCard';
import Column from '../common/cards/Column';
import DatoCard from '../common/cards/DatoCard';
import CardHeader from '../common/cards/CardHeader';

function InvernaderoCard(props) {
  const [, setInvernadero] = useGlobal("invernadero");
  const [, setPreseleccionRM] = useGlobal("preseleccionRM")
  const consultar = () => {
    setInvernadero(props.invernadero)
    setPreseleccionRM(props.invernadero);
    props.history.push({ pathname: "/reporte_medico/consultar", data: { preseleccionInv: props.invernadero.id } });
  }
  const headerInv = <CardHeader
    invernaderoVirtual={props.invernadero} sinProductoTexto
    habilitadoTexto={props.invernadero?.habilitado ? "" : "Deshabilitado"}
    nombreInvernaderoFisico={props.invernadero?.invernaderoFisico?.nombre || ""} />

  const headerOtro = <span className="card-header"><div className={`card-id icon-general icon-id bg-${props.invernadero.color}  bor-${props.invernadero.color}`}>{props.invernadero.icono ? <FontAwesomeIcon icon={props.invernadero.icono} /> : null}</div><div><p className="card-up"></p><p className="card-down">{props.invernadero.nombre}</p></div></span>;
  let header = ["Otros", "Empaque"].includes(props.invernadero.tipo) ? headerOtro : headerInv;
  let className = `htg-card bor-${props.invernadero.color}  ${props.invernadero?.habilitado == false ? "Deshabilitado" : "Habilitado"}`
  return (
    <HTGCard header={header} headerClassName={className} onClick={consultar}>
      <Column>
        <DatoCard label="EMPLEADOS" valor={`${props.invernadero.empleados}`} />
        <DatoCard label="EXAMEN MEDICO" valor={`${props.invernadero.examen_medico}`} />
      </Column>
      <Column>
        <DatoCard label="RIESGO DE TRABAJO" valor={`${props.invernadero ? props.invernadero.riesgo_trabajo : ""}`} />
        <DatoCard label="CONSULTA MEDICA" valor={`${props.invernadero ? props.invernadero.consulta_medica : ""}`} />
      </Column>
    </HTGCard>)
}
export default withRouter(InvernaderoCard);