import React, { useGlobal, useEffect } from "reactn";
import { Dropdown } from 'primereact/dropdown';
import { obtenerCiclo } from "../../service/Ciclos";
import { obtenerListaDeSemanas, getInicioSemanaDia, getListaDeSemanas, } from "../../service/fechas";
import { miliSegundosSemana } from "../../constants";
import { useState } from "react";
export const useWeekSelector = (isAdelantado = 0) => {
    const [ciclo] = useGlobal("ciclo")
    const [cicloObj] = useGlobal("cicloObj")
    const [weeks, setWeeks] = useGlobal("weeksGlobal")
    const [weekGlobal, setWeekGlobal] = useGlobal("weekGlobal")
    const [canSetWeeks, setCanSetWeeks] = useState(true)

    useEffect(() => {
        if (ciclo) asignarListaSemanas()
    }, [ciclo])

    const asignarListaSemanas = (adelantado) => {
        let semanasFuturo = isAdelantado === true ? 1 : isAdelantado;
        if (adelantado != null) {
            semanasFuturo = adelantado
            setCanSetWeeks(false)
        }
        let { semana_inicio, inicio = semana_inicio * 1000/*TEMPORARY*/ } = cicloObj
        let fin = Date.now() + (semanasFuturo * miliSegundosSemana)
        let listaSemanas = getListaDeSemanas(inicio, fin)

        if (canSetWeeks) setWeeks(listaSemanas)
    }

    useEffect(() => {
        if (weeks?.length > 0) {
            let beginningOfWeek = getInicioSemanaDia(Date.now()).unix() * 1000
            let currentWeek = weeks.find(el => el.time === beginningOfWeek);
            let found = weeks.find(el => el.time === weekGlobal?.time) || currentWeek

            setWeekGlobal({ ...found })
        }
    }, [weeks])

    const handleWeekChange = (ev) => { setWeekGlobal(ev.target.value); }
    const updateCiclo = (ciclo) => { }

    return [weeks, weekGlobal, handleWeekChange, asignarListaSemanas, setWeekGlobal]
};
const WeekSelector = (props) =>
    <div className="form-group p-col">
        <label >Semana</label>
        <Dropdown id="filtroSemanas" disabled={props.disabled} options={props.weeks} value={props.week}
            onChange={props.handleWeekChange} dataKey="time" optionLabel="nombre" placeholder="Seleccionar" />
    </div>
export default WeekSelector//83