import React,{ useEffect} from 'reactn';
import {useGlobal} from "reactn";
import {Button} from 'primereact/button';

import {obtenerResumenMedidas} from '../../service/Cultivo';
import InvernaderoCard from "./InvernaderoCard";
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { getProductoYVariedadString } from '../../util/functions';
function Invernaderos(props){
    const [invernaderos,setInvernaderos] = useGlobal("invernaderos");
    
    const [ciclo,] = useGlobal("ciclo");
    const [,setNombreModulo] = useGlobal("nombreModulo");
    const [, setWeekGlobal] = useGlobal("weekGlobal");
    const [weeks,week,handleWeekChange,updateCicloWeekSelector] = useWeekSelector();
    const [, setCargando] = useGlobal("cargando");
    var [usuario, ] = useGlobal("usuario");
    const [, setMostrarModalPermiso] = useGlobal("mostrarModalPermiso");
    const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");
    const [invernaderosFisicos] = useGlobal("invernaderosFisicos");

    const puedeConsultar = (params) => {
      return (usuario && ["Administrador", "Personal Administrativo", "Grower",
        "Administrador Seguridad","Auxiliar Seguridad", "Finanzas"].includes(usuario.rol) )
    }

    useEffect(()=>{
      setNombreModulo("Cultivo");
    },[])

    useEffect(() => {
      if(ciclo){
          updateCicloWeekSelector(ciclo);
      }
    },[ciclo])

    useEffect(() => {
      if(week.nombre !="Cargando")
      setWeekGlobal(week);
    },[week]);

    useEffect( ()=>{
      setCargando(true);
      if(invernaderosVirtuales && week){
        obtenerDatosInvernaderos()
      }       
    },[invernaderosVirtuales, week])

    const obtenerDatosInvernaderos = () =>{
      let promises = [];
      const invernaderos = invernaderosVirtuales.filter(inv => !inv.es_externo)

      for(let x = 0; x<invernaderos.length;x++){
        let invernadero = invernaderos[x];
        promises.push(obtenerResumen(invernadero));                                   
      }
      
      Promise.all(promises).then(values=>{
        let copia = invernaderos.map(el=>Object.assign({},el));

        for(let x = 0;x<values.length;x++){
          let [invId,invFisico,respRadiacion,respTemperatura,respPh, respConductividad] = values[x];
          let inv = copia.find(el=>el.id === invId);
          
          inv.invernaderoFisico = invFisico;
          inv.radiacion = respRadiacion;
          inv.temperatura = respTemperatura;
          inv.ph = respPh;
          inv.conductividad = respConductividad;
          inv.productoYVariedadString = getProductoYVariedadString(inv);
        }
        setInvernaderos(copia);
      }).catch(err=>{
        if(err.code && err.code == "permission-denied"){
          setMostrarModalPermiso(true);
        }
        console.log("ERROR ", err)
      }).finally(el=>{
        setCargando(false);
      })
    }


    const obtenerResumen = (invernadero) => {
      return new Promise((resolve,reject)=>{
          let semana =week.time;     
          let invernaderoFisico = invernaderosFisicos.find(invF=>invF.id === invernadero.invernadero_fisico_ref);
          obtenerResumenMedidas(ciclo,invernadero.id,semana).then(([respRadiacion, respTemperatura,respPh,respConductividad]) => {
              resolve ([invernadero.id,invernaderoFisico,respRadiacion,respTemperatura,respPh,respConductividad]);
          }).catch((err) => {    
            reject(err)
        });
      })
    }

    const irReporte = ()=>{
      if(puedeConsultar()){
      props.history.push("/cultivo/reporte")
      }
      
    }
    const irDetalles = (params) => {
      if(puedeConsultar()){
        props.history.push("/cultivo/detalles");
      }
    }

    return <div className="p-grid">
      <div className="p-col-12">
        {puedeConsultar()?<div className="btn-row">
          <Button label="Reporte general de cultivo"  onClick = {irReporte} />
          <Button onClick = {irDetalles} label="Ver detalles de cultivos"/>
        </div>:null}
      </div>
      <div className="p-col-12">
        <div className="p-grid filtros">              
          <WeekSelector weeks = {weeks} handleWeekChange = {handleWeekChange} week = {week}/>
        </div>
      </div>
      <div className="p-col-12">
        <div className="p-grid">
        {invernaderos && invernaderos.map(invernadero=><InvernaderoCard key = {invernadero.id} invernadero = {invernadero}/>)}
        </div>
      </div>

    </div>
}

export default Invernaderos;