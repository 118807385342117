import React, { useState, useEffect } from 'reactn';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { useGlobal } from "reactn";
import RHService, { obtenerEmpleados, obtenerEmpleadosActivos, obtener_empleados_ciclo_actual } from '../../service/RhService';
import { Calendar } from 'primereact/calendar';
import { guardarReporteMedico, actualizarReporteMedico, obtenerUrlArchivo, obtenerCuentaConsultas, getTooltipHoverInfo } from '../../service/ReporteMedico';
import { dateToUnixTimeAsNumber, getNombrePorID, getObjetoPorID, ordenarArreglaDeObjetosPorLlave, unixTimeToDate } from '../../util/functions';
import { obtenerEmpacadoras } from '../../service/Empacadoras';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faNotesMedical, faFileSignature, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import SeccionFormulario from "../common/SeccionFormulario";
import ContenedorInput from "./ContenedorInput";
import { obtenerRoles } from '../../service/Roles';
import { arreglar_empleados_y_subdepartamentos_de_ciclos_cruzados_reporte_medico, guardar_reportes_medicos_backup } from '../../util/scripts';
import InputTimeSelector from 'components/common/InputTimeSelector';
let inicial = {
  empleado: "",
  departamento: null,
  invernadero: null,
  empaque: null,
  fecha: "",
  hora: "",
  motivo: null,
  tipo_examen: null,
  aptitud: null,
  causa_lugar: "",
  procedimiento_riesgo: "",
  tipo_lesion: null,
  accion_preventiva_riesgo: "",
  diagnostico: "",
  procedimiento_consulta: "",
  archivos_examen_url: [],
  archivos_certificado_url: [],
  archivos_otro_url: [],
  observaciones: "",
  consideraciones_especiales: "",
  detalles_consideraciones_especiales: "",


}
const opcionesConsideracionesEspeciales = [
  {
    label: "Crónicos", value: "cronicos",
    children: [
      { label: "Hipertensos", value: "hipertensos" },
      { label: "Diabetes", value: "diabetes" },
      { label: "Cardiópatas", value: "cardiopatas" },
      { label: "Neumopatía", value: "neumopatia" },
      { label: "Insuficiencia Renal", value: "insuficiencia_renal" },
    ]
  },
  {
    label: "Embarazo", value: "embarazo", children: [
      { label: "Alto Riesgo", value: "alto_riesgo" },
      { label: "Sin Riesgo", value: "sin_riesgo" },
    ]
  },
  {
    label: "Discapacidad", value: "discapacidad", children: [
      { label: "Física", value: "fisica" },
      { label: "Neurológica", value: "neurologica" },
    ]
  },
  {
    label: "Crisis convulsivas", value: "crisis_convulsivas"
  },
]


const opcionesVisita = [
  { nombre: "Examen médico" },
  { nombre: "Riesgo de trabajo" },
  { nombre: "Consulta médica" },

]
const opcionesIngreso = [{ nombre: "Ingreso" }, { nombre: "Periódico" }];
const opcionesAptitud = [{ nombre: "Apto" }, { nombre: "Apto Condicionado" }, { nombre: "No Apto" }]
const opcionesTipoLesion = [{ nombre: "Accidente" }, { nombre: "Incidente" }, { nombre: "Accidente de trabajo" }]
const opcionesDiagnostico = [
  { nombre: "Cefalea" },
  { nombre: "Diarrea" },
  { nombre: "Gastroenteritis probablemente infecciosa." },
  { nombre: "Gastritis" },
  { nombre: "Infección de vías urinarias" },
  { nombre: "Faringoamigdalitis" },
  { nombre: "Colitis" },
  { nombre: "Resfriado común" },
  { nombre: "Lumbalgia" },
  { nombre: "Hipertensión" },
  { nombre: "Piquete de abejorro" },
  { nombre: "Reacción alérgica" },
  { nombre: "Cólico menstrual" },
  { nombre: "Otitis" },
  { nombre: "Conjuntivitis" },
  { nombre: "Odontalgia" },
  { nombre: "Odinofagia" },
  { nombre: "Mialgias" },
  { nombre: "Gonartrosis" },
  { nombre: "Monitoreo presión arterial" },
  { nombre: "Curación" },
  { nombre: "Náuseas" },
  { nombre: "Vómito" },
  { nombre: "Mareo" },
  { nombre: "Tos" },
  { nombre: "Dolor abdominal" },
  { nombre: "Dolor en extremidades" },
  { nombre: "Aplicación de medicamento intramuscular" },
  { nombre: "Otros" },

]
function FormularioReporteMedico(props) {
  let fileInputExamen = React.useRef(null);
  let fileInputCertificado = React.useRef(null);
  const [, setPresentacion] = useGlobal("presentacion");
  const [editing, setEditing] = useState(false);
  const [cuentaConsultas, setCuentaConsultas] = useState(0);
  const [empleados, setEmpleados] = useState([]);
  const [empacadoras, setEmpacadoras] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [rols, setRols] = useState([]);
  const [errores, setErrores] = useState([]);
  const [archivoExamen, setArchivoExamen] = useState(null);
  const [archivoOtro, setArchivoOtro] = useState(null);
  const [archivoCertificado, setArchivoCertificado] = useState(null);
  const [empaques, setEmpaques] = useState([]);
  const [state, setState] = useState(inicial);
  const [, setCargando] = useGlobal("cargando");
  const [ciclo] = useGlobal("ciclo");
  const [usuario] = useGlobal("usuario");
  const [invernaderosVirtuales] = useGlobal("invernaderosVirtuales");

  const [reporteEdit, setReporteEdit] = useGlobal("reporteEdit");
  const [detallesConsideracionesEspeciales, setDetallesConsideracionesEspeciales] = useState([])

  const [departamentoUsuario, setDepartamentoUsuario] = useState("");
  const [subdepaUsuario, setSubdepaUsuario] = useState("");

  const puedeEditar = (campo) => {
    const rolsABloquear = ["Personal Administrativo"];
    if (rolsABloquear.includes(usuario?.rol)) { return false; }
    else return true;
  }
  const handleFileInputChange = (event) => {
    let mapeo = {
      "file_certificado": setArchivoCertificado,
      "file_examen": setArchivoExamen,
      "file_otro": setArchivoOtro,
    }
    let funcion = mapeo[event.target.name];
    funcion(event.target.files);
  }
  const atras = (params) => {
    setPresentacion(null);
    setReporteEdit(null);
    props.history.goBack();
  }
  useEffect(() => {
    setCargando(true)
    if (ciclo) { obtenerDatosIniciales(); }
  }, [ciclo])

  useEffect(() => {
    if (ciclo && departamentos?.length > 0 && state?.empleado?.id) {
      const depaUsuario = getObjetoPorID(departamentos, state.empleado?.departamento_ref)
      setDepartamentoUsuario(depaUsuario?.nombre || "N/A")

      const stateCopy = { ...state, departamento_ref: state.empleado?.departamento_ref, departamento: depaUsuario, departamento_ref: state.empleado?.departamento_ref };
      if (!state.empleado?.subdepartamento_ref) {
        setSubdepaUsuario("")
      } else if (depaUsuario.nombre === "Invernadero") {
        const invernaderoUsario = getObjetoPorID(invernaderosVirtuales, state.empleado?.subdepartamento_ref)
        setSubdepaUsuario(invernaderoUsario?.nombre || "N/A")
      } else if (depaUsuario.nombre === "Empaque") {
        const empaqueUsario = getObjetoPorID(empaques, state.empleado?.subdepartamento_ref)
        setSubdepaUsuario(empaqueUsario?.nombre || "N/A")
        stateCopy.empaque = empaqueUsario;
      }
      setState(stateCopy);
    }
  }, [ciclo, state.empleado])

  useEffect(() => {
    if (!!state.departamento && state.departamento.nombre != "Invernadero") {
      // obtenerEmpleadosActivos().then((empleados) => {
      //   empleados.forEach(empleado => {
      //     empleado["nombreCompleto"] = `${empleado["nombre"]} ${empleado["apellidos"]}`;
      //   })
      //   setEmpleados(empleados);
      // }).catch((err) => {
      //   setCargando(false)
      // });
    }
  }, [state.departamento])

  const obtenerDatosIniciales = async (params) => {
    const [empleados, empleadosCicloActual, departamentos, empaques, empacadoras, rols]
      = await Promise.all([obtenerEmpleados(), obtener_empleados_ciclo_actual(ciclo), RHService.getDepartamentos(), RHService.getEmpaques(), obtenerEmpacadoras(), obtenerRoles()])

    const empleadosDelCiclo = filtrarEmpleadosDelCicloActual(empleados, empleadosCicloActual);
    empleadosDelCiclo.forEach(empleado => {
      empleado.nombreCompleto = `${empleado.nombre.trim()} ${empleado.apellidos.trim()}`;
    })

    //await arreglar_empleados_y_subdepartamentos_de_ciclos_cruzados_reporte_medico(ciclo, invernaderosVirtuales)
    const otherEmpleados = empleados.filter(emp => getNombrePorID(departamentos, emp.departamento_ref) !== "Invernadero")
    otherEmpleados.forEach(empleado => {
      empleado.nombreCompleto = `${empleado.nombre.trim()} ${empleado.apellidos.trim()}`;
    })
    const empleadosTodos = [...empleadosDelCiclo, ...otherEmpleados]
    ordenarArreglaDeObjetosPorLlave(empleadosTodos, "nombreCompleto")
    setEmpleados(empleadosTodos);
    setDepartamentos(departamentos);
    setEmpaques(empaques);
    setEmpacadoras(empacadoras);
    setRols(rols);
    setState({ ...state, empleado: "", departamento: null, invernadero: null })
    setDepartamentoUsuario("");
    setSubdepaUsuario("");
    setCargando(false)
  }
  const filtrarEmpleadosDelCicloActual = (empleados, empleadosCicloActual) => {//window.alert(888)
    const empleadosDelCiclo = [];
    empleadosCicloActual.forEach(emp => {
      const found = getObjetoPorID(empleados, emp.empleado_ref);
      const usuarioYaExiste = empleadosDelCiclo.find(el => el.id === found.id);
      const invernaderoEsDelCicloActual = invernaderosVirtuales?.find(inv => inv.id === emp.invernadero_virtual_ref)

      if (found.id && !usuarioYaExiste && invernaderoEsDelCicloActual) {
        found.subdepartamento_ref = emp.invernadero_virtual_ref
        empleadosDelCiclo.push(found)
      }
    })
    return empleadosDelCiclo;
  }

  const [nombreModulo, setNombreModulo] = useGlobal("nombreModulo");

  useEffect(() => {
    if (reporteEdit && empleados.length > 0 && departamentos.length > 0 && empacadoras.length > 0 && invernaderosVirtuales.length > 0) {
      setCargando(true)
      obtenerDatosEdicion();
    }

  }, [reporteEdit, departamentos, empleados, invernaderosVirtuales, empacadoras])
  const obtenerDatosEdicion = async (params) => {
    let adaptado = await adaptarEdicion(reporteEdit);
    setState(adaptado);
    setEditing(true);
  }
  useEffect(() => {
    if (state["empleado"]) {
      setCargando(true)
      obtenerCuentaConsultas(ciclo, state["empleado"]["id"]).then((consultas) => {
        setCuentaConsultas(getTooltipHoverInfo({ ...state.empleado, consultas, cuentaConsultas: consultas.length }));
        setCargando(false)
      }).catch((err) => {
        console.log("ERROR OBTENER CUENTA CONSULTAS");
        console.log(err);
        setCargando(false)
      }).finally(() => {
        setCargando(false)
      })

    }
  }, [state["empleado"]])
  useEffect(() => {
    let consideraciones = opcionesConsideracionesEspeciales.find(consideracion => consideracion.value == state["consideraciones_especiales"]);
    if (consideraciones) {
      setDetallesConsideracionesEspeciales(consideraciones.children);
    }
  }, [state["consideraciones_especiales"]])
  const adaptarEdicion = async (reporte) => {

    let fecha = unixTimeToDate(reporte["fecha"]);
    let copia = {
      fecha: fecha, hora: reporte["hora"], causa_lugar: reporte["causa_lugar"],
      procedimiento_riesgo: reporte["procedimiento_riesgo"], accion_preventiva_riesgo: reporte["accion_preventiva_riesgo"],
      diagnostico: reporte["diagnostico"], procedimiento_consulta: reporte["procedimiento_consulta"],
      archivos_examen: reporte["archivos_examen"], archivos_certificado: reporte["archivos_certificado"],
      archivos_otro: reporte["archivos_otro"], tipo_lesion: {},
      consideraciones_especiales: reporte["consideraciones_especiales"],
      detalles_consideraciones_especiales: reporte["detalles_consideraciones_especiales"],
    }
    copia.archivos_examen_url = [];
    for (let x = 0; copia.archivos_examen && x < copia.archivos_examen.length; x++) {

      let archivo = copia.archivos_examen[x];

      let url = await obtenerUrlArchivo(archivo);
      copia.archivos_examen_url.push(url);
    }
    copia.archivos_certificado_url = [];
    for (let x = 0; copia.archivos_certificado && x < copia.archivos_certificado.length; x++) {

      let archivo = copia.archivos_certificado[x];

      let url = await obtenerUrlArchivo(archivo);
      copia.archivos_certificado_url.push(url);
    }
    copia.archivos_otro_url = [];
    for (let x = 0; copia.archivos_otro && x < copia.archivos_otro.length; x++) {

      let archivo = copia.archivos_otro[x];

      let url = await obtenerUrlArchivo(archivo);
      copia.archivos_otro_url.push(url);
    }
    let empleado = empleados.find(el => el.id === reporte["empleado_ref"]);
    copia["empleado"] = empleado;

    let departamento = departamentos.find(el => el.id === reporte["departamento_ref"]);
    copia["departamento"] = departamento;
    if (departamento && departamento["nombre"] === "Invernadero") {
      let invernadero = invernaderosVirtuales.find(el => el.id === reporte["invernadero_ref"]) || {};
      copia["invernadero"] = invernadero;
    }
    if (departamento && departamento["nombre"] === "Empaque") {
      let empaque = empaques.find(el => el.id === reporte["empaque_ref"]);
      copia["empaque"] = empaque;
    }
    let motivo = opcionesVisita.find(el => el.nombre === reporte["motivo"]);
    copia["motivo"] = motivo;

    if (copia["motivo"]["nombre"] === "Examen médico") {

      let tipo_examen = opcionesIngreso.find(el => el.nombre === reporte["tipo_examen"]);
      let aptitud = opcionesAptitud.find(el => el.nombre === reporte["aptitud"]);
      copia["tipo_examen"] = tipo_examen;
      copia["aptitud"] = aptitud;
    } else if (copia["motivo"]["nombre"] === "Riesgo de trabajo") {

      let tipo_lesion = opcionesTipoLesion.find(el => el.nombre === reporte["tipo_lesion"]);
      copia["tipo_lesion"] = tipo_lesion;
    } else {

      copia["detalles_diagnostico"] = reporte["detalles_diagnostico"];
    }
    return copia;
  }

  const adaptarParaGuardado = (params) => {
    let copia = {};
    // asignar datos fijos en todos formularios
    copia["empleado_ref"] = state["empleado"]["id"];
    copia["departamento_ref"] = state["departamento"]["id"];
    copia["fecha"] = dateToUnixTimeAsNumber(state["fecha"]);
    copia["hora"] = state["hora"]
    copia["motivo"] = state["motivo"]["nombre"];
    copia["archivos_examen"] = state["archivos_examen"];
    copia["archivos_certificado"] = state["archivos_certificado"];
    copia["archivos_otro"] = state["archivos_otro"];
    copia["consideraciones_especiales"] = state["consideraciones_especiales"];
    copia["detalles_consideraciones_especiales"] = state["detalles_consideraciones_especiales"];
    if (state["departamento"] && state["departamento"]["nombre"] == "Invernadero") {
      copia["invernadero_ref"] = state["invernadero"]?.["id"] || state.empleado?.subdepartamento_ref;

    }
    if (state["empaque"] && state["departamento"]["nombre"] === "Empaque") {
      copia["empaque_ref"] = state["empaque"]?.["id"];
    }
    delete copia["invernadero"];
    delete copia["empaque"];
    delete copia["empleado"];
    delete copia["departamento"];

    // asignar campos dinamicamente
    if (copia["motivo"] === "Examen médico") {
      copia["tipo_examen"] = state["tipo_examen"]["nombre"];
      copia["aptitud"] = state["aptitud"]["nombre"];
    } else if (copia["motivo"] === "Riesgo de trabajo") {
      copia["causa_lugar"] = state["causa_lugar"];
      copia["procedimiento_riesgo"] = state["procedimiento_riesgo"];
      copia["tipo_lesion"] = state["tipo_lesion"]["nombre"];
      copia["accion_preventiva_riesgo"] = state["accion_preventiva_riesgo"];
    } else {
      copia["diagnostico"] = state["diagnostico"];
      copia["procedimiento_consulta"] = state["procedimiento_consulta"];
      copia["detalles_diagnostico"] = state["detalles_diagnostico"] || "";
    }
    return copia;
  }

  const guardar = () => {
    let errores = validar();
    if (errores.length > 0) {
      setErrores(errores);
      return;
    }
    let datos = adaptarParaGuardado();
    if (editing) {
      actualizarReporteMedico(ciclo, reporteEdit.id, datos, archivoExamen, archivoCertificado, archivoOtro).then((result) => {
        props.history.goBack();
      }).catch((err) => {
        console.log("ERROR GUARDAR REPORTE MEDICO");
        console.log(err);
      });
    } else {
      guardarReporteMedico(ciclo, datos, archivoExamen, archivoCertificado, archivoOtro).then((result) => {
        props.history.goBack();
      }).catch((err) => {
        console.log("ERROR GUARDAR REPORTE MEDICO");
        console.log(err);
      });
    }

  }
  const validar = () => {
    let errores = [];
    const requeridosTxt = ["fecha", "hora"];
    const requeridosDrop = ["empleado", "departamento", "motivo"];
    const mapeo = {
      "Examen médico": { txt: [], drop: ["tipo_examen", "aptitud"] },
      "Riesgo de trabajo": { txt: ["causa_lugar", "procedimiento_riesgo", "accion_preventiva_riesgo"], drop: ["tipo_lesion"] },
      "Consulta médica": { txt: ["diagnostico", "procedimiento_consulta"], drop: [] }
    }

    for (let x = 0; x < requeridosTxt.length; x++) {
      if (state[requeridosTxt[x]] === "" || !state[requeridosTxt[x]]) {
        errores.push(requeridosTxt[x]);
      }
    }
    for (let x = 0; x < requeridosDrop.length; x++) {
      if (!state[requeridosDrop[x]]) {
        errores.push(requeridosDrop[x]);
      }
    }
    if (state["motivo"]) {

      for (let x = 0; x < mapeo[state["motivo"]["nombre"]]["txt"].length; x++) {
        let field = mapeo[state["motivo"]["nombre"]]["txt"][x]
        if (state[field] === "" || !state[field]) {
          errores.push(field);
        }
      }
      for (let x = 0; x < mapeo[state["motivo"]["nombre"]]["drop"].length; x++) {
        let field = mapeo[state["motivo"]["nombre"]]["drop"][x];
        if (!state[field]) {
          errores.push(field);
        }
      }
    }
    return errores;
  }
  const cancelar = () => {
    setReporteEdit(null);
    setEditing(false);
    props.history.goBack();
  }

  useEffect(() => {

    setNombreModulo("Reporte Médico");
  }, [])
  const handleInputChange = (ev) => {
    setErrores([]);
    const stateCopy = Object.assign({}, state);
    let value = ev.target.value;
    stateCopy[ev.target.name] = value;
    setState(stateCopy);
  }
  const handleDropdownChange = (name, value) => {
    setErrores([]);
    const stateCopy = Object.assign({}, state);
    stateCopy[name] = value;
    setState(stateCopy);
  }

  const eliminarArchivoExamen = (index) => {
    let arrArchivos = state.archivos_examen.slice();
    let arrLinks = state.archivos_examen_url.slice();
    arrArchivos.splice(index, 1);
    arrLinks.splice(index, 1);
    let copia = { ...state };
    copia["archivos_examen"] = arrArchivos;
    copia["archivos_examen_url"] = arrLinks;
    setState(copia);
  }
  const eliminarArchivoCertificado = (index) => {
    let arrArchivos = state.archivos_certificado.slice();
    let arrLinks = state.archivos_certificado_url.slice();
    arrArchivos.splice(index, 1);
    arrLinks.splice(index, 1);
    let copia = { ...state };
    copia["archivos_certificado"] = arrArchivos;
    copia["archivos_certificado_url"] = arrLinks;
    setState(copia);
  }
  const eliminarArchivoOtro = (index) => {
    let arrArchivos = state.archivos_otro.slice();
    let arrLinks = state.archivos_otro_url.slice();
    arrArchivos.splice(index, 1);
    arrLinks.splice(index, 1);
    let copia = { ...state };
    copia["archivos_otro"] = arrArchivos;
    copia["archivos_otro_url"] = arrLinks;
    setState(copia);
  }

  return (
    <div className="p-grid p-justify-center">
      <div className="p-col-12 title-container">
        <span className="back-btn" onClick={atras}></span>
        {editing ?
          <React.Fragment>
            <span className="icon-general medico-icon"></span>
            <span className="titles-group">
              <h1 className="header-title">Modificar consulta médica</h1>
            </span>
          </React.Fragment>
          :
          <React.Fragment>
            <span className="icon-general medico-icon"></span>
            <span className="titles-group">
              <h1 className="header-title">Nueva consulta médica</h1>
            </span>
          </React.Fragment>
        }

      </div>
      <div className="p-col-10">

        <div className="p-grid p-fluid ">
          <SeccionFormulario titulo="Información general">
            <ContenedorInput label="Empleado" col={(!departamentoUsuario || (departamentoUsuario && subdepaUsuario)) ? "12" : "6"} md={(!departamentoUsuario || (departamentoUsuario && subdepaUsuario)) ? "12" : "6"}>
              <Dropdown key={`${ciclo}`} filter={true} options={empleados} value={state["empleado"]} filterBy="nombreCompleto" filterPlaceholder="Buscar empleado"
                optionLabel={"nombreCompleto"} dataKey="id" onChange={(e) => handleDropdownChange("empleado", e.value)} disabled={!puedeEditar()}/>
              {errores.includes("empleado") && <Message severity="error" text="Este campo es requerido" />}
            </ContenedorInput>

            {state.empleado?.departamento_ref &&
              <ContenedorInput label="Departamento" col="12">
                <InputText value={departamentoUsuario} disabled />
              </ContenedorInput>}
            {subdepaUsuario &&
              <ContenedorInput label="Subdepartamento" col="12">
                <InputText value={subdepaUsuario} disabled />
              </ContenedorInput>}

            <ContenedorInput label="Fecha">
              <Calendar value={state["fecha"]} onChange={handleInputChange} dateFormat="dd/mm/yy" name="fecha" disabled={!puedeEditar()}/>
              {errores.includes("fecha") ? <Message severity="error" text="Este campo es requerido"></Message> : null}
            </ContenedorInput>

            <InputTimeSelector name="hora" col='6'  label='Hora'  valueMode='hours:minutes' value={state.hora} onChange={handleInputChange} errorItIsRequired={errores.includes("hora")}/>
    
            <ContenedorInput label="Motivo de visita">
              <Dropdown options={opcionesVisita} value={state["motivo"]} optionLabel="nombre" dataKey="nombre"
                onChange={(e) => handleDropdownChange("motivo", e.value)} disabled={!puedeEditar()}/>
              {errores.includes("motivo") ? <Message severity="error" text="Este campo es requerido"></Message> : null}
            </ContenedorInput>
            <ContenedorInput label="Consideraciones especiales">
              <Dropdown options={opcionesConsideracionesEspeciales}
                value={state["consideraciones_especiales"]} optionLabel="label"
                name="consideraciones_especiales"
                onChange={e => handleDropdownChange("consideraciones_especiales", e.value)} disabled={!puedeEditar()}/>
            </ContenedorInput>
            {(state.consideraciones_especiales && state.consideraciones_especiales !== "crisis_convulsivas") &&
              <ContenedorInput label="Observaciones">
                <Dropdown value={state["detalles_consideraciones_especiales"]}
                  onChange={(e) => handleDropdownChange("detalles_consideraciones_especiales", e.value)}
                  options={detallesConsideracionesEspeciales} disabled={!puedeEditar()}/>
              </ContenedorInput>}
            {editing &&
              <div className="form-group p-col-12 p-md-6 p-sm-6">
                <span>Consultas en el ciclo:</span><br /><span> {cuentaConsultas} </span>
              </div>}

          </SeccionFormulario>
          {state["motivo"] && state["motivo"]["nombre"] === "Examen médico" &&
            <SeccionFormulario titulo="Formulario de examen médico">
              <ContenedorInput label="Tipo de examen">
                <Dropdown options={opcionesIngreso} value={state["tipo_examen"]} optionLabel="nombre" dataKey="nombre"
                  onChange={(e) => handleDropdownChange("tipo_examen", e.value)} disabled={!puedeEditar()}/>
                {errores.includes("tipo_examen") ? <Message severity="error" text="Este campo es requerido"></Message> : null}
              </ContenedorInput>
              <ContenedorInput label="Aptitud para el puesto">
                <Dropdown options={opcionesAptitud} value={state["aptitud"]} optionLabel="nombre" dataKey="nombre"
                  onChange={(e) => handleDropdownChange("aptitud", e.value)} disabled={!puedeEditar()}/>
                {errores.includes("aptitud") ? <Message severity="error" text="Este campo es requerido"></Message> : null}
              </ContenedorInput>
              {state["aptitud"] && state["aptitud"]["nombre"] === "Apto Condicionado" ? <ContenedorInput label="Observaciones">
                <InputText value={state["observaciones"]} name="observaciones" onChange={handleInputChange} disabled={!puedeEditar()}/>
                {errores.includes("observaciones") ? <Message severity="error" text="Este campo es requerido"></Message> : null}
              </ContenedorInput> : null}
            </SeccionFormulario>}
          {state["motivo"] && state["motivo"]["nombre"] === "Riesgo de trabajo" &&
            <SeccionFormulario titulo="Formulario de riesgo de trabajo">
              <ContenedorInput label="Causa y lugar">
                <InputTextarea value={state["causa_lugar"]} name="causa_lugar" onChange={handleInputChange} disabled={!puedeEditar()}/>
                {errores.includes("causa_lugar") ? <Message severity="error" text="Este campo es requerido"></Message> : null}
              </ContenedorInput>
              <ContenedorInput label="Procedimiento médico">
                <InputTextarea value={state["procedimiento_riesgo"]} name="procedimiento_riesgo" onChange={handleInputChange} disabled={!puedeEditar()}/>
                {errores.includes("procedimiento_riesgo") ? <Message severity="error" text="Este campo es requerido"></Message> : null}
              </ContenedorInput>
              <ContenedorInput label="Tipo lesión">
                <Dropdown options={opcionesTipoLesion} value={state["tipo_lesion"]} onChange={(e) => handleDropdownChange("tipo_lesion", e.value)}
                  optionLabel="nombre" dataKey="nombre" disabled={!puedeEditar()}/>
                {errores.includes("tipo_lesion") ? <Message severity="error" text="Este campo es requerido"></Message> : null}
              </ContenedorInput>
              <ContenedorInput label="Acción preventiva/Correctiva (Ej, Uso de Epp)">
                <InputTextarea value={state["accion_preventiva_riesgo"]} name="accion_preventiva_riesgo" onChange={handleInputChange}
                 disabled={!puedeEditar()}/>
                {errores.includes("accion_preventiva_riesgo") && <Message severity="error" text="Este campo es requerido"></Message>}
              </ContenedorInput>
            </SeccionFormulario>}


          {state["motivo"] && state["motivo"]["nombre"] === "Consulta médica" &&
            <SeccionFormulario titulo="Formulario de consulta médica">
              <ContenedorInput label="Diagnóstico médico">
                <Dropdown options={opcionesDiagnostico} value={state["diagnostico"]} onChange={(e) => handleDropdownChange("diagnostico", e.value)} optionLabel="nombre" dataKey="nombre" filter={true} disabled={!puedeEditar()}/>
              </ContenedorInput>
              <ContenedorInput label="Detalles">
                <InputText value={state["detalles_diagnostico"]} name="detalles_diagnostico" onChange={handleInputChange} disabled={!puedeEditar()}/>
                {errores.includes("detalles_diagnostico") ? <Message severity="error" text="Este campo es requerido"></Message> : null}
              </ContenedorInput>
              <ContenedorInput label="Procedimiento">
                <InputText value={state["procedimiento_consulta"]} name="procedimiento_consulta" onChange={handleInputChange} disabled={!puedeEditar()}/>
                {errores.includes("procedimiento_consulta") ? <Message severity="error" text="Este campo es requerido"></Message> : null}
              </ContenedorInput>
            </SeccionFormulario>}
          <div className="p-col-12">
            <p className="section-title">Adjuntos</p>

            <div className="card">
              <div className="p-grid">
                <div className="p-col-12 p-md-4 p-sm-6">

                  <div className="form-group p-col-12">
                    <label>Exámenes médicos adjuntos</label>
                    {state["archivos_examen_url"].map((el, index) => {
                      return <div className="medical-doc-row">
                        <a className="medical-doc-link" href={el} target="_blank" >
                          <FontAwesomeIcon icon={faNotesMedical} />
                          <span className="text">Examen {index === 0 ? "" : index + 1}</span>
                        </a>
                        <div className="medical-doc" onClick={() => eliminarArchivoExamen(index)}>
                          <FontAwesomeIcon icon={faTrash} />
                          <span className="text">Eliminar examen {index === 0 ? "" : index + 1}</span>
                        </div>
                      </div>
                    })}
                  </div>

                  <div className="form-group p-col-12">
                    <label htmlFor="file_examen">Adjuntar examen médico</label>
                    <input type="file" ref={fileInputExamen}
                      onChange={(event) => { handleFileInputChange(event) }}
                      id="file_examen" name="file_examen" />
                    {errores.includes("adjunto_examen") ? <Message severity="error" text="Debe haber al menos un archivo de este tipo"></Message> : null}
                  </div>
                </div>

                <div className="p-col-12 p-md-4 p-sm-6">
                  <div className="form-group p-col-12 ">
                    <label htmlFor="file_certificado">Certificados adjuntos</label>
                    {state["archivos_certificado_url"].map((el, index) => {
                      return <div className="medical-doc-row">
                        <a className="medical-doc-link" href={el} target="_blank">
                          <FontAwesomeIcon icon={faFileSignature} />
                          <span className="text">Certificado {index === 0 ? "" : index + 1}</span>
                        </a>
                        <div className="medical-doc" onClick={() => eliminarArchivoCertificado(index)}>
                          <FontAwesomeIcon icon={faTrash} />
                          <span className="text">Eliminar certificado {index === 0 ? "" : index + 1}</span>
                        </div>
                      </div>
                    })}
                  </div>

                  <div className="form-group p-col-12 ">
                    <label htmlFor="file_examen">Adjuntar certificado</label>
                    <input type="file" ref={fileInputCertificado}
                      onChange={(event) => { handleFileInputChange(event) }}
                      id="file_certificado" name="file_certificado" />

                    {errores.includes("adjunto_certificado") ? <Message severity="error" text="Debe haber al menos un archivo de este tipo"></Message> : null}
                  </div>
                </div>
                <div className="p-col-12 p-md-4 p-sm-6">


                  {<div className="form-group p-col-12 ">
                    <label htmlFor="file_otro">Otros adjuntos</label>
                    {state["archivos_otro_url"].map((el, index) => {
                      return <div className="medical-doc-row">


                        <a className="medical-doc-link" href={el} target="_blank">
                          <FontAwesomeIcon icon={faPaperclip} />
                          <span className="text">Adjunto {index === 0 ? "" : index + 1}</span>
                        </a>
                        <div className="medical-doc" onClick={() => eliminarArchivoOtro(index)}>
                          <FontAwesomeIcon icon={faTrash} />
                          <span className="text">Eliminar adjunto {index === 0 ? "" : index + 1}</span>
                        </div>
                      </div>
                    })
                    }
                  </div>}



                  {<div className="form-group p-col-12 ">
                    <label htmlFor="file_examen">Adjuntar certificado</label>
                    <input type="file" ref={fileInputCertificado}
                      onChange={(event) => { handleFileInputChange(event) }}
                      id="file_otro" name="file_otro" />
                    {errores.includes("adjunto_otro") ? <Message severity="error" text="Debe haber al menos un archivo de este tipo"></Message> : null}
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="p-col-12">
        <div className="p-grid p-justify-end btn-row">
          <Button label="Cancelar" className="p-button-secondary" onClick={cancelar} />
          {puedeEditar("guardar") ? <Button label="Guardar" onClick={guardar} /> : null}

        </div>
      </div>
    </div>)
}
export default FormularioReporteMedico;//775