import React from 'react'
import { withRouter } from "react-router-dom";
import { dosDecimales, separadoComas } from "../../util/functions";
import HTGCard from "../common/cards/HTGCard";
import DatoCard from "../common/cards/DatoCard";
import icono from "../../images/icons/external-icon.svg";

const InvernaderoExternoCard = (props) => {
  const consultar = () => {
    let path = props.path || "/cosecha/compra_externa/reporte";
    props.history.push({pathname: path, state: {productRef: props.invernadero.producto_ref }});
  };

  const header = (
    <span className="card-header">
      <div
        className={`card-id icon-general icon-lort icon-id bg-main-blue bor-main-blue `}
      >
        <img width="30" alt="icono" src={icono} />
      </div>
      <p className="card-up">{props?.invernadero?.nombre}</p>
      <div></div>
    </span>
  );
  const semanalkgm2 = separadoComas(dosDecimales(props.invernadero.semanal)) || 0;
  const realkgm2 = separadoComas(dosDecimales(props.invernadero.semanalReal)) || 0;

  let realvssemanal = Math.round(100 * props.invernadero?.realvssemanal);
  let realvsdiario = Math.round(100 * props.invernadero.realvsdiario);

  if (realvssemanal === Infinity || !realvssemanal) realvssemanal = "-";
  if (realvsdiario === Infinity || !realvsdiario) realvsdiario = "-";

  return (
    <HTGCard
      headerClassName={`htg-card bor-main-blue`}
      onClick={consultar}
      header={header}
    >
      <DatoCard label="kg/m2 Semanal" valor={`${semanalkgm2} kg`} />
      <DatoCard label="kg/m2 Real" valor={`${realkgm2} kg`} />
      <DatoCard label="Real vs. Semanal" valor={`${realvssemanal} %`} />
      <DatoCard label="Real vs. Diario" valor={`${realvsdiario} %`} />
    </HTGCard>
  );
};
export default withRouter(InvernaderoExternoCard);
