import * as firebase from "firebase/app";
import "firebase/firestore";
import { getGlobal } from 'reactn'
import { dateToUnixTime, getObjetoPorID } from "../util/functions";
import { obtener_empleados_invernadero } from "./RhService";
import { obtenerCiclo } from "./Ciclos";
import { obtenerProveedores } from "./Queries/Proveedores";
import { getColeccionesProductosYVariedadesTodos } from "./ProductosYVariedades";
import { uploadStatistics } from './Estadisticas'

function registrarInvocaciones(count) {
    uploadStatistics(count)
}

export function obtenerInvernaderosFisicos() {
    var db = firebase.firestore();
    return new Promise((resolve, reject) => {
        let results = [];
        db.collection("invernaderos_fisicos").orderBy("index", "asc").get().then(snap => {
            registrarInvocaciones(snap.size)
            snap.forEach(doc => {

                let data = doc.data();
                data.id = doc.id;
                results.push(data);
            })
            resolve(results);
        }).catch(err => {
            console.log("ERROR OBTENER INVERNADEROS FISICOS");
            console.log(err);
            reject(err);
        });
    })
}

export const getInvernaderosFisicos = async (force = false) => {
    if (force) return await obtenerInvernaderosFisicos()
    else return getGlobal().invernaderosFisicos || await obtenerInvernaderosFisicos()
}

export async function registrarInvernaderoVirtual(estado, invernaderosPorTransferir, porcentajesMezclaVariedades, mezcla, rangosCajasCosecha = [], precioBonoCosechaRolOtros) {
    const { nombre, interplanting, color, detalles_fisicos, asignacion_lineas, costosFertilizantes, costosDeMano, ...resto } = estado;
    let copia = { nombre, interplanting, color, detalles_fisicos, asignacion_lineas, porcentajesMezclaVariedades };

    copia.cliente_ref = estado.cliente;
    copia.producto_ref = estado.producto;
    if (estado.variedad) { copia.variedad_ref = estado.variedad; }
    if (mezcla) { copia.mezcla = true; }
    copia.habilitado = true;
    copia.invernadero_fisico_ref = estado.invernadero_fisico;
    copia.nombre = estado.nombre;
    copia.habilitado = true;
    copia.numero_maximo_trabajadores = estado.cantEmpleados;
    copia.creacion = dateToUnixTime(new Date());
    copia.peso_caja_cosecha = estado.pesoCaja;
    copia.costosFertilizantes = estado.costosFertilizantes;
    copia.costosDeMano = estado.costosDeMano;
    copia.porcentajesMezclaVariedades = porcentajesMezclaVariedades;
    copia.rangosCajasCosecha = rangosCajasCosecha;
    copia.precioBonoCosechaRolOtros = precioBonoCosechaRolOtros;

    let invernaderoCreadoRef = await firebase.firestore().collection("ciclos").doc(estado.ciclo).collection("invernaderos_virtuales").add(copia);
    let ciclo = estado.ciclo;
    let transferencias = await realizarTransferencias(invernaderoCreadoRef.id, ciclo, invernaderosPorTransferir);
    return transferencias;
}

const realizarTransferencias = async (invernaderoId, cicloInvernadero, invernaderosPorTransferir) => {
    const cicloObj = await obtenerCiclo(cicloInvernadero);
    //obtener ciclo pasado
    let cicloPorTransferirId = await obtenerCicloAnterior(cicloObj);
    //por cada invernadero por transferir
    let promises = invernaderosPorTransferir.map(invernaderoPorTransferir => transferir(cicloInvernadero, invernaderoId, cicloPorTransferirId, invernaderoPorTransferir.id))
    //obtener empleados
    let transferidos = await Promise.all(promises);
    let result = invernaderosPorTransferir.map((el, index) => ({
        nombre: el.nombre, cantidad: transferidos[index]
    }))
    let referenciasTransferencias = invernaderosPorTransferir.map(porTransferirId => {
        return { cicloAnterior: cicloPorTransferirId, origen: porTransferirId.id, destino: invernaderoId }
    })


    // crear referencia para nuevo invernadero, empleado
    await actualizarReferenciasTransferencias(cicloInvernadero, referenciasTransferencias)
    return result;
    //registrar transferencias

}
const transferir = async (cicloInvernadero, invernaderoId, cicloPorTransferirId, invernaderoPorTransferirId) => {





    let empleados = await obtener_empleados_invernadero(cicloPorTransferirId, invernaderoPorTransferirId);


    let promises = empleados.map(empleado => {
        agregarReferenciaEmpleadoInvernadero(cicloInvernadero, invernaderoId, empleado);
    })

    let result = await Promise.all(promises);


    return empleados.length

}
const agregarReferenciaEmpleadoInvernadero = async (cicloId, invernaderoId, empleado) => {




    const db = firebase.firestore();
    let nuevaReferencia = await db.collection("empleados_invernadero_virtual_de_ciclo").add({
        ciclo_ref: cicloId,
        invernadero_virtual_ref: invernaderoId,
        empleado_ref: empleado.empleado_ref
    })
    return nuevaReferencia;
}

const actualizarReferenciasTransferencias = async (cicloInvernadero, referenciasTransferencias) => {
    const db = firebase.firestore();
    let transfSnap = await db.collection("ciclos").doc(cicloInvernadero).collection("transferencias").get();
    registrarInvocaciones(transfSnap.size)
    if (transfSnap.empty) {
        return db.collection("ciclos").doc(cicloInvernadero).collection("transferencias").add({ transferencias: referenciasTransferencias })
    } else {


        let doc = transfSnap.docs[0];

        let data = doc.data();





        let transf = data.transferencias;




        transf = transf.concat(referenciasTransferencias);


        return db.doc(doc.ref.path).update({
            transferencias: transf
        })
    }
}



export function obtenerInvernaderosVirtualesCards() {

}
export function obtenerInvernaderosVirtualesHabilitados(ciclo) {
    return new Promise((resolve, reject) => {
        var db = firebase.firestore();
        let invernaderosVirtuales = [];
        db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").where("habilitado", "==", true).orderBy("creacion")
            .get().then(querySnap => {
                registrarInvocaciones(querySnap.size)
                querySnap.forEach(snap => {
                    let data = snap.data();
                    data.id = snap.id;
                    invernaderosVirtuales.push(data);
                });
                resolve(invernaderosVirtuales);
            }).catch(err => {
                reject(err);
            })

    })
}
export const obtenerInvernaderosVirtualesHabilitadosIncluidosExternos = async (ciclo) => {


    var db = firebase.firestore();
    let invernaderosVirtuales = [];
    let querySnap = await db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").where("habilitado", "==", true).orderBy("creacion")
        .get()
    registrarInvocaciones(querySnap.size)
    querySnap.forEach(snap => {
        let data = snap.data();
        data.id = snap.id;
        invernaderosVirtuales.push(data);
    });
    querySnap = await db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").where("es_externo", "==", true)
        .get()
    registrarInvocaciones(querySnap.size)
    querySnap.forEach(snap => {
        let data = snap.data();
        data.id = snap.id;
        invernaderosVirtuales.push(data);
    });
    return invernaderosVirtuales;

}
export const obtenerInvernaderosVirtuales = async (cicloID) => {
    let response = await firebase.firestore().collection("ciclos").doc(cicloID).collection("invernaderos_virtuales").orderBy("creacion").get().catch(err => { console.log(`ERROR obtenerInvernaderosVirtuales: ${err}`) })
    registrarInvocaciones(response.size)
    let invernaderosVirtuales = response.docs.map(doc => ({
        id: doc.id, ...doc.data(), color: doc.data().es_externo ? "main-blue" : doc.data().color,
        habilitado: doc.data().es_externo ? true : doc.data().habilitado
    }))
    return invernaderosVirtuales
}

export const getInvernaderosVirtuales = async (cicloID, force = false) => {
    let invernaderosVirtuales = [];
    if (force) invernaderosVirtuales = await obtenerInvernaderosVirtuales(cicloID)
    else invernaderosVirtuales = getGlobal().invernaderosVirtuales || await obtenerInvernaderosVirtuales(cicloID)

    //ordenarInvernaderosVirtualesPorIndexFisicoYNombre(invernaderosVirtuales);
    return invernaderosVirtuales;
}

export function obtenerInvernaderosVirtualesInternos(ciclo) {
    return new Promise((resolve, reject) => {
        var db = firebase.firestore();
        let promises = [];
        let invernaderosVirtuales = [];
        db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").orderBy("creacion").get().then(querySnap => {
            registrarInvocaciones(querySnap.size)
            querySnap.forEach(snap => {
                let data = snap.data();
                data.id = snap.id;
                invernaderosVirtuales.push(data);
            });
            invernaderosVirtuales = invernaderosVirtuales.filter(el => !el.es_externo);
            resolve(invernaderosVirtuales);
        }).catch(err => {
            reject(err);
        })

    })
}

export function obtenerInvernaderoFisicoRelacionado(ref) {
    let db = firebase.firestore();
    return new Promise((resolve, reject) => {
        db.collection("invernaderos_fisicos").doc(ref).get().
            then(resp => {
                registrarInvocaciones(resp.size)
                let data = resp.data();
                data.id = resp.id;
                resolve(data);
            }).
            catch(err => {
                reject(err);
            })
    })

}

export function obtenerInvernaderosVirtualesActivos(ciclo) {
    return new Promise((resolve, reject) => {
        var db = firebase.firestore();
        let promises = [];
        let invernaderosVirtuales = [];
        db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").where('habilitado', '==', true).orderBy("creacion")
            .get().then(querySnap => {
                registrarInvocaciones(querySnap.size)
                querySnap.forEach(snap => {
                    let data = snap.data();
                    data.id = snap.id;
                    invernaderosVirtuales.push(data);
                });
                resolve(invernaderosVirtuales);
            }).catch(err => {
                reject(err);
            })

    })
}

export function guardarInvernaderoVirtual(invernaderoId, estado, porcentajesMezclaVariedades, mezcla, rangosCajasCosecha = [], precioBonoCosechaRolOtros) {



    const { nombre, interplanting, color, detalles_fisicos, asignacion_lineas, costosDeMano, costosFertilizantes, ...resto } = estado;
    let copia = { nombre, interplanting, color, detalles_fisicos, asignacion_lineas, costosDeMano, costosFertilizantes, };

    //copia.detalles_fisicos = estado.detalles1.slice();
    //copia.asignacion_lineas = estado.detalles2.slice();
    copia.cliente_ref = estado.cliente;
    copia.producto_ref = estado.producto;
    if (estado.variedad) { copia.variedad_ref = estado.variedad; }
    if (mezcla) { copia.mezcla = true; }
    copia.habilitado = estado.habilitado;
    copia.invernadero_fisico_ref = estado.invernadero_fisico;
    copia.nombre = estado.nombre;
    copia.habilitado = true;
    copia.numero_maximo_trabajadores = estado.cantEmpleados;
    copia.costosDeMano = estado.costosDeMano;
    copia.costosFertilizantes = estado.costosFertilizantes;
    copia.rangosCajasCosecha = rangosCajasCosecha;
    copia.precioBonoCosechaRolOtros = precioBonoCosechaRolOtros;
    if (porcentajesMezclaVariedades?.length > 0) { copia.porcentajesMezclaVariedades = porcentajesMezclaVariedades; }
    copia.peso_caja_cosecha = estado.pesoCaja;
    var db = firebase.firestore();


    //return db.collection("ciclos").doc(estado.ciclo).collection("invernaderos_virtuales").doc(invernaderoId).set(copia);
    return db.collection("ciclos").doc(estado.ciclo).collection("invernaderos_virtuales").doc(invernaderoId).update(copia);
}

export function togglearInvernadero(idInvernadero, estado) {
    var db = firebase.firestore();
    return new Promise((resolve, reject) => {
        db.collection("ciclos").doc(estado.ciclo).collection("invernaderos_virtuales").doc(idInvernadero).get().then((snap) => {
            registrarInvocaciones(snap.size)
            let data = snap.data();
            let habilitado = data.habilitado;
            db.collection("ciclos").doc(estado.ciclo).collection("invernaderos_virtuales").doc(idInvernadero).update({
                habilitado: !habilitado
            }).then(resp => {
                resolve("yei");
            }).catch(err => { reject(err) });
        }).catch(err => {
            reject(err);
        });
    })

}

export function registrarCambioInvernaderoVirtual(idInvernadero, estado) {
    let obj = {
        invernadero_virtual_ref: idInvernadero,
        fecha_modificacin: new Date().getTime(),
        peso_caja_cosecha: estado.pesoCaja,
        numero_maximo_trabajadores: estado.cantEmpleados
    }
    var db = firebase.firestore();
    db.collection("historico_invernadero_virtual").add(obj);
}
export function obtenerInvernadero(ciclo, invernadero) {
    const db = firebase.firestore();
    return new Promise((resolve, reject) => {
        db.collection("ciclos").doc(ciclo).collection("invernaderos_virtuales").doc(invernadero).get()
            .then(resp => {
                registrarInvocaciones(resp.size)
                if (resp.exists) {
                    resolve({ ...resp.data(), id: resp.id })
                }
                else {
                    resolve(null);
                }
            }).catch(err => {
                console.log(err)
                reject(err);
            })
    });

}
export function getAreaInvernaderoSync(invernadero) {
    if(!invernadero?.detalles_fisicos)return 0
    let area = invernadero.detalles_fisicos?.find(el => el.detalle === "Area (m²)");
    return parseInt(area.norte) + parseInt(area.sur);
}
export function obtenerAreaInvernaderoSync(invernaderoObj = {}) {
    let inv = invernaderoObj;
    let areas = inv.detalles_fisicos?.find(el => el.detalle === "Area (m²)") || { norte: 0, sur: 0 };
    let area = parseInt(areas.norte) + parseInt(areas.sur);
    return area;
}
export const obtenerAreaInvernaderoExternoSync = (invernadero, proveedores) => {
    let { producto_ref } = invernadero;
    const proveedoresFiltradosPorProductoInv = proveedores.filter(el => el.producto_ref === producto_ref);
    let suma = 0;
    proveedoresFiltradosPorProductoInv.forEach(proveedor => {
        suma += Number(proveedor.area_invernadero);
    })
    return suma;
}
export const obtenerAreaInvernaderoExterno = async (cicloId, invernadero) => {
    let producto_ref = invernadero.producto_ref;
    let proveedores = await obtenerProveedores(cicloId);
    proveedores = proveedores.filter(el => el.producto_ref === invernadero.producto_ref);
    let suma = 0;
    proveedores.forEach(proveedor => {
        suma += parseFloat(proveedor.area_invernadero);
    })
    return suma;
}
export const getAreaInvernaderoExterno =  (cicloId, invernadero, proveedores) => {
    proveedores = proveedores.filter(el => el.producto_ref === invernadero.producto_ref);
    let suma = 0;
    proveedores.forEach(proveedor => {
        suma += parseFloat(proveedor.area_invernadero);
    })
    return suma;
}
export const getAreaInvernaderoVirtual =  (cicloId, invernadero, proveedores) => {
    if (invernadero?.es_externo) { return getAreaInvernaderoExterno(cicloId, invernadero, proveedores); }
    return getAreaInvernaderoSync(invernadero);
}

export const invernaderoPerteneceACicloActual = (invernadero, invernaderos) => {
    let found = invernaderos.find(el => el.id === invernadero.id);
    if (found) {
        return true;
    } else {
        return false;
    }
}
const obtenerCicloAnterior = async (ciclo) => {

    const db = firebase.firestore();
    let ciclos = await db.collection("ciclos").where("semana_cierre", "<", ciclo.semana_cierre).orderBy("semana_cierre").get();
    registrarInvocaciones(ciclos.size)


    if (ciclos.empty) {
        return null
    } else {
        let ultimoCicloId = ciclos.docs[ciclos.docs.length - 1].id;
        if (ultimoCicloId === ciclo.id) {
            if (ciclos.docs.length > 2) {
                ultimoCicloId = ciclos.docs[ciclos.docs.length - 2].id;
            } else {
                return null
            }
        } else {
            return ultimoCicloId;
        }
    }
}
const obtenerInvernaderosTransferidos = async (cicloId) => {


    const db = firebase.firestore();
    let transfSnap = await db.collection("ciclos").doc(cicloId).collection("transferencias").get();
    registrarInvocaciones(transfSnap.size)
    if (transfSnap.empty) {
        return [];
    } else {


        let doc = transfSnap.docs[0];

        let data = doc.data();

        return data.transferencias.map(el => el.origen);
    }
}

export const obtenerInvernaderosDisponibles = async (cicloId) => {
    try {
        const cicloActual = await obtenerCiclo(cicloId);
        let cicloAnteriorId = await obtenerCicloAnterior(cicloActual);
        //obtener invernaderos de ciclo anterior
        if (cicloAnteriorId) {
            let invernaderos = await obtenerInvernaderosVirtualesHabilitados(cicloAnteriorId);
            let invernaderosTransferidos = await obtenerInvernaderosTransferidos(cicloId);
            let disponibles = invernaderos.filter(el => !invernaderosTransferidos.includes(el.id));
            return disponibles
        } else return [];
    } catch (error) {
        console.log("ERROR OBTENER DISPONIBLES: " + error.message);
    }
}

export const obtenerInvernaderosProducto = async (cicloId, productoId) => {
    const db = firebase.firestore();
    let snap = await db.collection("ciclos").doc(cicloId).collection("invernaderos_virtuales").where("producto_ref", "==", productoId).get();
    registrarInvocaciones(snap.size)
    return snap.docs.map(el => ({ id: el.id, ...el.data() }));
}
export const obtenerInvernaderoProveedorProducto = async (cicloId, productoId) => {
    const db = firebase.firestore();
    let snap = await db.collection("ciclos").doc(cicloId).collection("invernaderos_virtuales").where("es_externo", "==", true).
        where("producto_ref", "==", productoId).get();
    registrarInvocaciones(snap.size)



    if (snap.empty) {


        return { empty: true }
    } else {

        let doc = snap.docs[0]


        return { id: doc.id, ...doc.data() }
    }
}
export const crearInvernaderoProveedorProducto = async (cicloId, productoId, nombreProducto) => {
    const db = firebase.firestore();
    let creacion = dateToUnixTime(new Date());
    let result = await db.collection("ciclos").doc(cicloId).collection("invernaderos_virtuales").add(
        { producto_ref: productoId, es_externo: true, nombre: `LRT_${nombreProducto}`, creacion });
    return result;
}

export const obtenerInvernaderosExternos = async (cicloId) => {
    const db = firebase.firestore();
    let snap = await db.collection("ciclos").doc(cicloId).collection("invernaderos_virtuales").where("es_externo", "==", true).get();
    registrarInvocaciones(snap.size)
    return snap.docs.map(el => ({ id: el.id, ...el.data() }));
}

export const obtenerInvernaderosConSubvariedades = async (cicloId) => {
    let productos = getGlobal().productosYVariedades || [];
    let invernaderos = getGlobal().invernaderosVirtuales?.filter(inv => !inv.es_externo) || await obtenerInvernaderosVirtualesInternos(cicloId);

    let filtrados = invernaderos.filter(invernadero => {
        let producto = productos.find(el => el.id === invernadero.producto_ref);
        let variedad = producto.variedades.find(el => el.id === invernadero.variedad_ref);
        if (variedad.esCompuesta) {
            return true
        } else {
            return false
        }
    })
    return filtrados
}

export const ordenarInvernaderosVirtualesPorIndexFisicoYNombre = (invernaderosVirtuales, invernaderosFisicos = getGlobal().invernaderosFisicos) => {
    invernaderosVirtuales.sort((a, b) => {
        const invernaderoFisicoA = getObjetoPorID(invernaderosFisicos, a.invernadero_fisico_ref);
        const invernaderoFisicoB = getObjetoPorID(invernaderosFisicos, b.invernadero_fisico_ref);

        if (a.es_externo) { return 1; }
        if (invernaderoFisicoA.index === invernaderoFisicoB.index) { return a.nombre > b.nombre ? 1 : -1; }
        else { return invernaderoFisicoA.index > invernaderoFisicoB.index ? 1 : -1; }
    })
}
export const getEsInvernaderoExternoPorID = (invernaderosVirtuales, invernaderoVirtualID) => {
    const invernaderoVirtual = getObjetoPorID(invernaderosVirtuales, invernaderoVirtualID)
    return !!invernaderoVirtual.es_externo;
}

export const getSitioEmpaqueInvernaderoVirtualObj = (invernaderosFisicos, invernaderoVirtual) => {
    const invernaderoFisico = getObjetoPorID(invernaderosFisicos, invernaderoVirtual?.invernadero_fisico_ref);
    return invernaderoFisico?.empaque_ref;
}
export const getEmpaquePorInvernaderoVirtualID = (invernaderosFisicos, invernaderosVirtuales, invernaderoVirtualID) => {
    const { invernadero_fisico_ref: invernaderoFisicoID, es_externo, nombre, ...datos } = getObjetoPorID(invernaderosVirtuales, invernaderoVirtualID);
    if (!invernaderoFisicoID || es_externo) { return ""; }

    const { empaque_ref } = getObjetoPorID(invernaderosFisicos, invernaderoFisicoID);
    return empaque_ref;
}