import React,{useState, useEffect} from 'react';
import { useGlobal } from 'reactn';
import PropTypes from 'prop-types'
import { Dialog } from 'primereact/dialog'
import SeccionFormulario from '../common/SeccionFormulario'
import ContenedorInput from '../common/ContenedorInput'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import SeccionBotones from '../common/SeccionBotones';
import {APROBADO, RECHAZO, REPROCESO, NO_APROBADO  } from '../../service/ReportePrecios';
import {Message} from "primereact/message";
const optionsStatus = [
    {label:"Aprobado", value:"aprobado"},
    {label:"No Aprobado", value:"no_aprobado"},
    {label:"Rechazo de cajas", value:"rechazo"},
    {label:"Re proceso de cajas", value:"reproceso"},
];
function ModalFormulario(props) {
    const [edicionFormulario, setEdicionFormulario] = useGlobal("edicionVerificacionPrecios");
    const [state, setState] = useState({
        status:"",  
        precio_libra:"",
        cajas_con_rechazo:"",
        cajas_con_reproceso:"",
        precio_con_rechazo:"",
        precio_sin_rechazo:"",
        precio_con_reproceso:"",
        precio_sin_reproceso:"", 
    })
    const [budgetSemana, setBudgetSemana] = useState(0)
    const [original, setOriginal] = useState();
    const [nombreModulo,setNombreModulo] = useGlobal("nombreModulo");
    useEffect(()=>{
        setNombreModulo("Finanzas");
      },[])
    useEffect(() => {
        if(edicionFormulario){
            
            
            let adaptacion = adaptarManifiestoAFormulario(edicionFormulario);
            let budgetIndex = props.gruposPresentaciones.findIndex(grupoPresentaciones=>{
                return grupoPresentaciones.presentaciones_refs.includes(edicionFormulario.presentacion_ref);                
            })
            
            
            let budget = props.budgets[budgetIndex];
            
            if(budget && !budget.empty){
                let datoSemana = budget.budget.find(el=>el.time=== props.semana)
                
                if(datoSemana){
                    setBudgetSemana(datoSemana.value)
                }else{
                    setBudgetSemana(0);
                }
            }else{
                setBudgetSemana(0)
            }
            setOriginal({...edicionFormulario});
            setState(adaptacion);
        }
        
    }, [edicionFormulario])
    
    const handleStatusChange = (e) => {
        let copia = {...state};
        copia["status"] = e.value;
        setState(copia);
    }
    const handleInputChange = (target,value) => {
        let copia = {...state};
        copia[target] = value;
        setState(copia);
    }
    const footer = (
        <React.Fragment>
                <Button label = "Guardar" onClick = {()=>props.guardar(state, original)}/>
                <Button label ="Siguiente Semana" onClick = {()=>props.desplazar(state, original)}/>
                <Button className="m-0" label = "Cancelar" onClick = {props.onHide}/>
        </React.Fragment>
    );
    return (
        <Dialog header="Verificación " footer={footer} visible = {props.show} onHide = {props.onHide} >
            
                <ContenedorInput col="12" md="12" sm="12" label = "Status">
                    <Dropdown options ={optionsStatus} value = {state["status"]} onChange = {handleStatusChange} />
                </ContenedorInput>
                {state["status"] && state["status"] === "aprobado"?
                <ContenedorInput col="12" md="12" sm="12" label = "Precio por libra">
                    <InputText  value = {state["precio_libra"]} onChange = {(e)=>handleInputChange("precio_libra",e.target.value)} keyfilter="num"/>
                    <div>Budget semana: {budgetSemana} $/lb</div>
                </ContenedorInput> 
            :
            state["status"] && state["status"] == "rechazo"?
            <React.Fragment>
                <ContenedorInput   col="12" md="12" sm="12" label = "Cajas con rechazo">
                    <InputText value ={state["cajas_con_rechazo"] } onChange = {(e)=>handleInputChange("cajas_con_rechazo",e.target.value)} keyfilter="num"/>
                </ContenedorInput>
                <ContenedorInput   col="12" md="12" sm="12" label = "Precio con rechazo">
                    <InputText value ={state["precio_con_rechazo"] } onChange = {(e)=>handleInputChange("precio_con_rechazo",e.target.value)} keyfilter="num"/>
                </ContenedorInput>
            </React.Fragment>
            :
            state["status"] && state["status"] == "reproceso"?
            <React.Fragment>
                    <ContenedorInput  col="12" md="12" sm="12" label = "Cajas con reproceso">
                        <InputText value={state["cajas_con_reproceso"]} onChange = {(e)=>handleInputChange("cajas_con_reproceso",e.target.value)} keyfilter="num"/>
                    </ContenedorInput>
                    <ContenedorInput  col="12" md="12" sm="12" label = "Precio con reproceso">
                        <InputText value={state["precio_con_reproceso"]} onChange = {(e)=>handleInputChange("precio_con_reproceso",e.target.value)} keyfilter="num"/>
                    </ContenedorInput>
                    
                </React.Fragment>
                
            :
            null}
                 
         
        </Dialog>
    )
}

ModalFormulario.propTypes = {
    
}

export const adaptarManifiestoAFormulario = (manifiesto) => {
    let result = {manifiesto:"", status:"sin", presentacion_ref:manifiesto["presentacion_ref"]};
    
    if(manifiesto["manifiesto"]){
        result["manifiesto"] = manifiesto["manifiesto"];
    }
    if(manifiesto["status"]){
        result["status"] = manifiesto["status"];
    }
    if(result["status"] === APROBADO){
        result["precio_libra"] = manifiesto["precio_libra"];
    }
    if(result["status"] === RECHAZO){
        result["cajas_con_rechazo"] = manifiesto["cajas_con_rechazo"];
        result["precio_con_rechazo"] = manifiesto["precio_con_rechazo"];
    }
    if(result["status"] === REPROCESO){
        result["cajas_con_reproceso"] = manifiesto["cajas_con_reproceso"];
        result["precio_con_reproceso"] = manifiesto["precio_con_reproceso"];
    }
    return result;
}
export default ModalFormulario

