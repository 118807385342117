export const mapDeDataDropdownDePresentaciones = (data, clientes, productos) => {
    const obtencionDeGrupos = data.reduce((acc, presentacion) => {
        const { id, cliente_ref,producto_ref } = presentacion
        let cliente = clientes.find(cli=>cli.id === cliente_ref)
        let producto = productos.find(prd =>prd.id === producto_ref)
        //Si no encuentra producto, productos[0] debe de ser medleys
        if(!producto) producto = productos[0]

        const key = `${cliente?.nombre} - ${producto?.nombre}`
        if(acc[key]) acc[key].push(presentacion)
        if(!acc[key]) acc[key] = [presentacion]
        return acc
    },{})
    const opcionesAgrupadas = Object.entries(obtencionDeGrupos).map(group => {
        const clienteNombre = group[0].split("-")[0].trim()
        const cliente = clientes.find(cli=>cli.nombre === clienteNombre)
        return {
            label: group[0],
            options: group[1],
            cliente
        }
    })
    return opcionesAgrupadas
}