import React, { useGlobal, useState, useEffect, setGlobal } from "reactn";
import { obtenerDiasDeSemana, obtenerIndiceDiaSemana } from "../../service/fechas";
import { Dropdown } from 'primereact/dropdown';
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { exportAllDeclaration } from "@babel/types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faChartBar } from '@fortawesome/free-solid-svg-icons';
import { dateToUnixTime, getProductoYVariedadString } from "../../util/functions";
import WeekSelector, { useWeekSelector } from '../common/WeekSelector';
import { noHaSidoClausurado } from '../../service/Ciclos';
import { Message } from 'primereact/message';
import { obtenerRegistroEntrada, getEntradaskgTotales, tiposEntradas, guardarDatosEntrada } from "../../service/VentaNacional";
import { nombresDiasSemana, miliSegundosDia } from "../../constants";
import ContenedorHeader from '../common/ContenedorHeader'
import icono from '../../images/icons/external-icon.svg';
import moment from "moment";

const RegistroEntrada = (props) => {
    const [, setNombreModulo] = useGlobal("nombreModulo");
    const [, setCargando] = useGlobal("cargando")
    const [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    const [ciclo] = useGlobal("ciclo");
    const [usuario] = useGlobal("usuario");
    const [weeks, week, handleWeekChange, updateCicloWeekSelector, setWeek] = useWeekSelector();
    const [editing] = useState(props.match.params.entradaID ? true : false);
    const [entradaID] = useState(props.match.params.entradaID)

    //opciones y datos
    const [productosYVariedades] = useGlobal("productosYVariedades")
    const [invernadero] = useGlobal("invernadero");
    const [dias, setDias] = useState([]);
    const [dia, setDia] = useState(undefined);
    const [pallets, setPallets] = useState([{ cantidad: "", tipo: "" }]);
    const [fechaCreacion, setFechaCreacion] = useState(Date.now());

    const [datosCargadosEntrada, setDatosCargadosEntrada] = useState(false);
    const [errores, setErrores] = useState([]);

    useEffect(() => {
        setNombreModulo("Ventas Nacionales");
        setHabilitadoSelectorCiclo(false)
    }, [])

    useEffect(() => {
        if (!datosCargadosEntrada && week.nombre != "Cargando")
            obtenerDatosIniciales()
        else if (datosCargadosEntrada && week.nombre != "Cargando")
            asignarDias(week, dia)
    }, [week])

    useEffect(() => {
        setErrores([])
    }, [pallets, week, dia])

    const asignarDias = (weekPassed, diaPassed) => {
        let diasDeSemana = obtenerDiasDeSemana(weekPassed.time).map((dia, index) => ({ nombre: nombresDiasSemana[index], time: dia }))
        let indice = diaPassed ? obtenerIndiceDiaSemana(diaPassed.time) : obtenerIndiceDiaSemana(Math.round(moment(moment().utcOffset("-0600").toLocaleString()).toDate() / 1000))

        setDias(diasDeSemana)
        setDia(diasDeSemana[indice])
    }

    const obtenerDatosIniciales = async () => {
        setCargando(true)
        setDatosCargadosEntrada(true)
        let weekSelected = { ...week }
        if (editing) {
            let entrada = await obtenerRegistroEntrada(ciclo, props.match.params.invernaderoID, entradaID)
            if (!entrada)
                props.history.push("/ventas_nacionales/tabla_registros_entradas")
            else {
                setPallets(entrada.pallets)
                setFechaCreacion(entrada.fecha_creacion || entrada.dia.time)
                asignarDias(entrada.week, entrada.dia)
                weekSelected = entrada.week
            }
        }
        setWeek(weekSelected)
        setCargando(false)
    }

    const guardar = async () => {
        if (validar()) {
            setCargando(true)
            let data = editing ? { pallets, dia, week } : { pallets, dia, week, fecha_creacion: moment(moment().utcOffset("-0600").toLocaleString()).toDate() }
            await guardarDatosEntrada(ciclo, invernadero.id, data, entradaID)

            setCargando(false)
            props.history.goBack()
        }
    }

    const validar = () => getErroresFormulario().length === 0
    const getErroresFormulario = () => {
        let errores = []
        pallets.forEach((pallet, index) => {
            if (!pallet.cantidad)
                errores.push(`cantidad${index}`)
            else if (parseInt(pallet.cantidad) <= 0)
                errores.push(`cantidad${index}0kg`)
            if (!pallet.tipo)
                errores.push(`tipo${index}`)
        })
        setErrores(errores)
        return errores
    }

    const puedeEditar = () => {
        if ((invernadero && !invernadero.es_externo && !invernadero.habilitado) || pallets[0].tipo === "Transferido")
            return false
        else if (!editing)
            return true
        else
            return (fechaCreacion > (Date.now() - miliSegundosDia) && usuario.rol === "Administrador")
    }

    const handleInputChange = (ev) => {
        let copia = pallets.map(el => ({ ...el }))
        copia[ev.target.id][ev.target.name] = ev.target.value;
        setPallets(copia);
    }

    const eliminarPallet = (index) => {
        let copia = pallets.map(el => ({ ...el }))
        copia.splice(index, 1)
        setPallets(copia);
    }

    const getTitulo = () => editing ? "Detalle de reporte de entrada" : "Nuevo registro de entrada de venta nacional"
    return (
        <div className="p-grid p-justify-center">
            <ContenedorHeader col="p-col p-col-auto" titulo={getTitulo()} subtituloInv={getProductoYVariedadString(invernadero)}
                invernaderoNombre={invernadero?.es_externo ? <img width="60" alt="icono" src={icono} /> : invernadero?.nombre} atras={props.history.goBack}
                iconos={`icon-general icon-lort icon-id bor-${invernadero?.color} bg-${invernadero?.color} ${invernadero?.habilitado ? "" : "Deshabilitado"}`} />
                
            <div className="p-col-10">
                <p className="section-title">Fecha del registro</p>
                <div className="p-grid p-fluid ">
                    <div className="p-col-12">
                        <div className="card">
                        <div className="form-group p-grid p-fluid">
                            <WeekSelector estilo="form-group p-col-6" disabled={!puedeEditar()} weeks={weeks} handleWeekChange={handleWeekChange} week={week} />
                            <div className="form-group p-col-6">
                                <label htmlFor="cinco">Día</label>
                                <Dropdown id="filtroDias" placeholder="Seleccionar" onChange={(e) => { setDia(e.target.value) }}
                                    dataKey="time" optionLabel="nombre" options={dias} value={dia} disabled={!puedeEditar()} />
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="p-col-10">
                <p className="section-title">Ingresos a registrar</p>
                <div className="p-grid p-fluid ">
                    <div className="p-col-12">
                        <div className="card">
                            {pallets.map((pallet, index) => {
                                return <div key={`${index + 1}`} className="form-group p-grid p-fluid pallet-dailyweight">

                                    <div className="p-col-auto">
                                        <p className="pallet-id">Ingreso {index + 1}</p>
                                    </div>
                                    <div className="p-col p-grid ">
                                        <div className="p-col-auto p-justify-end p-grid p-align-center m-0">
                                            <label >Kgs</label>
                                        </div>
                                        <div className="p-col">
                                            <InputText id={`${index}`} name="cantidad" placeholder="Kgs en pallet" value={pallet.cantidad} onChange={handleInputChange} disabled={!puedeEditar()} />
                                            {errores.includes(`cantidad${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                            {errores.includes(`cantidad${index}0kg`) && <Message severity="error" text="Agregar al menos 1 Kg" />}
                                        </div>
                                    </div>

                                    <div className="p-col p-grid ">
                                        <div className="p-col-auto p-justify-end p-grid p-align-center m-0">
                                            <label htmlFor={`$cajas_{pallet.id}`} >Línea/arrastre </label>
                                        </div>
                                        <div className="p-col">
                                            <Dropdown id={`${index}`} name="tipo" placeholder="Seleccionar" options={pallet.tipo === "Transferido" ? ["Transferido"] : tiposEntradas} onChange={handleInputChange} value={pallet.tipo} disabled={!puedeEditar()} />
                                            {errores.includes(`tipo${index}`) && <Message severity="error" text="Este campo es requerido" />}
                                        </div>
                                    </div>

                                    {puedeEditar() && pallets.length > 1 &&
                                        <div className="p-col-auto">
                                            <button className="p-button" onClick={() => eliminarPallet(index)}>
                                                <FontAwesomeIcon icon={faTrashAlt} />
                                            </button>
                                        </div>}
                                </div>
                            })}
                            {puedeEditar() && <Button onClick={() => setPallets([...pallets, { cantidad: "", tipo: "" }])} label="+ Agrega registro" className="p-button-secondary" />}
                            <div className="pallet-totalweight">
                                Total: {getEntradaskgTotales([{ pallets }])} kg
                            </div>
                        </div>
                    </div>
                </div>

                {puedeEditar() && <div className="p-grid p-justify-end btn-row">
                    <Button label="Cancelar" className="p-button-secondary" onClick={() => props.history.goBack()} />
                    <Button label="Guardar" onClick={guardar} />
                </div>}
            </div>

        </div>
    );
}
export default RegistroEntrada;//214