import React from 'react'
import { getProductoYVariedadString } from '../../util/functions';

const ContenedorHeaderIconoInvernadero = (props) => {
    return <div className={` ${props.col?`${props.col}`:`p-col-12`}  title-container`}>
    {props.atras?<span className="back-btn" onClick = {props.atras}></span>:null}
    
    {props.editing?
            <>
              <span className={`icon-general icon-id bor-${props.invernadero?.color} bg-${props.invernadero?.color} ${props.invernadero?.habilitado?"":"Deshabilitado"}`}>{props.invernadero?.nombre}</span>
              <span className="titles-group">
                <h1 className="header-title">Producción semanal</h1>
                <p className="header-subtitle">{getProductoYVariedadString(props.invernadero)}</p>
              </span>
            </>
          :
            <>
              <span className="icon-general inv-icon"></span>
              <span className="titles-group">
                <h1 className="header-title">Crear {props.identificador}</h1>
              </span>
            </>
          }
    <div>        
    </div>
</div>
}
export default ContenedorHeaderIconoInvernadero;
