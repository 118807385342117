import React, { useEffect, useState } from 'react';
import { useGlobal } from "reactn";

import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { Dialog } from 'primereact/dialog'
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from 'primereact/progressspinner';
import { noHaSidoClausurado } from '../../service/Ciclos';

import {
    getPuntosDeCarga_orderBy_nombre, getEmpresas_orderBy_nombre, getClientes_from_Ciclo_orderBy_nombreComun,
    getTransportistas_orderBy_nombre, getFronteras_orderBy_nombre, getDestinos_orderBy_nombre, getEstadosEmbarque_orderBy_orden,
    getAgentesAduanales_tipoMexicano_orderBy_nombre, getAgentesAduanales_tipoAmericano_orderBy_nombre,
    getEmbarque, subirArchivo, eliminarArchivo, isOnHold, getFechaDespachado,
    getFechaEstimadaDeLlegadaDeEmbarque, guardarMensajeChatPublico, generarMensajeEmbarque, guardarMensajeChatPrivado,
    notificarAgentesRelacionados, cambiarEstadoEmbarqueAlSiguiente, pausar_o_despausar_embarque, obtenerEstadoSiguiente,
    eliminarEmbarque, obtenerArchivosEmbarque_withSignedUrl, getFechaEstimadaDeLlegadaDeEmbarqueUnix, timerIniciado, aplicanRetrasos
} from '../../service/embarques';
import { dateToUnixTimeAsNumber, naturalCompareSort } from '../../util/functions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faFileAlt, faIdCard, faSeedling, faCalendarDay, faStopwatch, faUserTie, faTrafficLight, faTruck, faFlagUsa, faMapMarkerAlt, faBox, faBoxes } from '@fortawesome/free-solid-svg-icons';
import { obtenerUsuarios } from '../../service/Usuarios';
import { obtenerNombreFechaCompacto, obtener_horaMinutos } from '../../service/fechas';
import { ROL_AGENTE_ADUANAL, ROL_CLIENTE } from "../../constants";
import { MODULO_EMBARQUES_NOMBRE } from '../../util/constants';
import { obtenerManifiestosEmbarque } from '../../service/QueriesManifiestos';
import { obtenerOrden } from 'service/QueriesOrdenes';

const firebase = require("firebase");


function DetallesEmbarque(props) {

    // datos iniciales
    const [cargando, setCargando] = useGlobal("cargando")
    const [cicloId, setCicloId] = useGlobal("ciclo")
    const [ciclos, setCiclos] = useGlobal("ciclos")
    const [usuario, setUsuario] = useGlobal("usuario")
    const [nombreModulo, setNombreModulo] = useGlobal("nombreModulo");
    const [ordenConsulta, setOrdenConsulta] = useGlobal("ordenVenta");
    const [, setClientesCiclo] = useGlobal("clientes");
    const [embarque, setEmbarque] = React.useState({})
    const [ordenVDelEmbarque, setOrdenVDelEmbarque] = useState({})
    const [puntosCarga, setPuntosCarga] = React.useState([])
    const [productores, setProductores] = React.useState([])
    const [importadores, setImportadores] = React.useState([])
    const [estadosEmbarque, setEstadosEmbarque] = React.useState([])
    const [transportistas, setTransportistas] = React.useState([])
    const [fronteras, setFronteras] = React.useState([])
    const [destinos, setDestinos] = React.useState([])
    const [agentesAduanalesMexicanos, setAgentesAduanalesMexicanos] = React.useState([])
    const [agentesAduanalesAmericanos, setAgentesAduanalesAmericanos] = React.useState([])
    const [puntosCargaSeleccionados, setPuntosCargaSeleccionados] = React.useState([])
    const [estadoActualId, setEstadoActualId] = React.useState("")
    const [fechaIdealLlegadaUnix, setFechaIdealLlegadaUnix] = React.useState("")
    const [despachado, setDespachado] = React.useState("")
    const [aplicanRetrasosVar, setAplicanRetrasosVar] = React.useState("")

    // parámetros iniciales
    const { embarqueId } = props.match.params

    // eliminar embarque
    const [mostrarConfirmacionEliminarEmbarque, setMostrarConfirmacionEliminarEmbarque] = React.useState(false)

    // cambiar estado de embarque
    const [mostrarConfirmacionCambioDeEstatus, setMostrarConfirmacionCambioDeEstatus] = React.useState(false)
    const [estadoEmbarqueActual, setEstadoEmbarqueActual] = React.useState({})
    const [onHold, setOnHold] = React.useState(false)

    // upload de archivos
    var fileInputRef = React.useRef(null)
    const [archivoPorSubir, setArchivoPorSubir] = React.useState({})
    const [archivosPorSubir, setArchivosPorSubir] = React.useState({})
    const [archivoPorEliminar, setArchivoPorEliminar] = React.useState({})
    const [archivosSubidos, setArchivosSubidos] = React.useState([])
    const [mostrarModalEliminarArchivo, setMostrarModalEliminarArchivo] = React.useState(false)
    const [archivosAdjuntosCargados, setArchivosAdjuntosCargados] = React.useState(false)

    // chats
    const [mensajesChatPublico, setMensajesChatPublico] = React.useState([])
    const [mensajeActualChatPublico, setMensajeActualChatPublico] = React.useState("")
    const [usuarios, setUsuarios] = React.useState([])
    const [mensajesChatPrivado, setMensajesChatPrivado] = React.useState([])
    const [mensajeActualChatPrivado, setMensajeActualChatPrivado] = React.useState("")

    // alertas
    var [mostrarDialogoDeErrorAlGuardar, setMostrarDialogoDeErrorAlGuardar] = React.useState(false)
    var [mostrarExitoAlNotificarAgentes, setMostrarExitoAlNotificarAgentes] = React.useState(false)
    var [mostrarErrorAlNotificarAgentes, setMostrarErrorAlNotificarAgentes] = React.useState(false)
    var [mostrarErrorAlEliminarEmbarque, setMostrarErrorAlEliminarEmbarque] = React.useState(false)
    //timers
    var [currentUnixTimeDate, setCurrentUnixTimeDate] = React.useState(0)
    let [, setHabilitadoSelectorCiclo] = useGlobal("habilitadoSelectorCiclo");
    useEffect(() => {
        setHabilitadoSelectorCiclo(false);
        return () => {
            setHabilitadoSelectorCiclo(true);
        }
    }, [])

    const obtenerOrdenDeVenta = async () => {
        setCargando(true)
        if (!cicloId) return
        const ordenId = embarque?.productores?.[0]?.orden_ref
        if (!ordenId) return
        const ordenDeVenta = await obtenerOrden(cicloId, ordenId)
        setOrdenVDelEmbarque(ordenDeVenta)
        setCargando(false)
    }

    useEffect(() => {
        obtenerOrdenDeVenta()
    }, [embarque])

    React.useEffect(() => {
        setNombreModulo(MODULO_EMBARQUES_NOMBRE)
        if (typeof cicloId === 'undefined') return
        getInitialData()
    }, [cicloId]);

    React.useEffect(() => {

    }, [estadoActualId, fechaIdealLlegadaUnix, despachado, aplicanRetrasosVar]);


    async function getInitialData() {
        try {
            setCargando(true)
            const currentDate = new Date()
            const currentUnixTimeDate = dateToUnixTimeAsNumber(currentDate)
            setCurrentUnixTimeDate(currentUnixTimeDate)

            obtenerArchivosEmbarque_withSignedUrl(embarqueId).then((archivosSubidosResponse) => {

                setArchivosAdjuntosCargados(true)
                if (archivosSubidosResponse?.data?.operacionExitosa) {
                    const files = archivosSubidosResponse.data.archivos
                    const alphabeticallySortedFiles = naturalCompareSort(files, "nombre")
                    
                    setArchivosSubidos(alphabeticallySortedFiles)
                }
            });

            let [
                puntosCarga,
                empresas,
                clientesEnCiclo,
                estadosEmbarque,
                transportistas,
                fronteras,
                destinos,
                aaMexicanos,
                aaAmericanos,
                usuarios
            ] = await Promise.all([
                getPuntosDeCarga_orderBy_nombre(),
                getEmpresas_orderBy_nombre(),
                getClientes_from_Ciclo_orderBy_nombreComun(cicloId),
                getEstadosEmbarque_orderBy_orden(),
                getTransportistas_orderBy_nombre(),
                getFronteras_orderBy_nombre(),
                getDestinos_orderBy_nombre(),
                getAgentesAduanales_tipoMexicano_orderBy_nombre(),
                getAgentesAduanales_tipoAmericano_orderBy_nombre(),
                obtenerUsuarios()
            ]);

            if (puntosCarga.length === 0
                || empresas.length === 0 // productores
                || clientesEnCiclo.length === 0 // importadores
                || estadosEmbarque.length === 0
                || transportistas.length === 0
                || fronteras.length === 0
                || destinos.length === 0
                || aaMexicanos.length === 0
                || aaAmericanos.length === 0
                || usuarios.length === 0
            ) {
                return
            }

            setPuntosCarga(puntosCarga)
            setProductores(empresas) // productores
            setImportadores(clientesEnCiclo) // importadores/clientes
            setClientesCiclo(clientesEnCiclo)// importadores/clientes
            setEstadosEmbarque(estadosEmbarque)
            setTransportistas(transportistas)
            setFronteras(fronteras)
            setDestinos(destinos)
            setAgentesAduanalesMexicanos(aaMexicanos)
            setAgentesAduanalesAmericanos(aaAmericanos)
            setUsuarios(usuarios)

            await getEmbarqueDetails(empresas, clientesEnCiclo, estadosEmbarque, transportistas, fronteras, destinos, aaMexicanos, aaAmericanos, usuarios, puntosCarga)
        } catch (error) {
            console.log("ERROR AL OBTENER INIT DATA");
            console.log(error)
        } finally {
            setCargando(false)
        }

    }

    async function getEmbarqueDetails(empresas, importadores, estadosEmbarque, transportistas, fronteras, destinos, agentesAduanalesMexicanos, agentesAduanalesAmericanos, usuarios, puntosCarga) {
        setCargando(true)
        let manifiestos = await obtenerManifiestosEmbarque(cicloId, embarqueId);
        await getEmbarque(embarqueId, cicloId).then((registroEmbarque) => {
            const embarqueOnHold = isOnHold(registroEmbarque.estado_ref, registroEmbarque.cambios_estado_map)
            setOnHold(embarqueOnHold)
            const estadoEmbarque = estadosEmbarque?.find((estadoEmbarque) => estadoEmbarque.id === registroEmbarque.estado_ref)
            setPuntosCargaSeleccionados(puntosCarga.filter(puntoCarga => registroEmbarque.puntos_de_carga.indexOf(puntoCarga.id) !== -1))
            const embarque = {
                id: registroEmbarque.id,
                fechaDespacho: "N/A",
                estatus: (estadoEmbarque?.nombre + (embarqueOnHold ? " (on hold)" : "")) || "",
                fechaLlegada: "N/A",
                tiempoRestanteEntrega: "N/A",
                productores: extraerProductoresDeEmbarque(manifiestos, empresas),
                importador: importadores.find((importador) => importador.id === registroEmbarque.importador_ref)?.nombre || "",
                transportista: transportistas.find((transportista) => transportista.id === registroEmbarque.transportista_ref)?.nombre || "",
                frontera: fronteras.find((frontera) => frontera.id === registroEmbarque.frontera_ref)?.nombre || "",
                destino: destinos.find((destino) => destino.id === registroEmbarque.destino_ref)?.nombre || "",
                aaMexicano: agentesAduanalesMexicanos?.find((aaMexicano) => aaMexicano.id === registroEmbarque.agente_aduanal_mexicano_ref)?.nombre || "N/A",
                aaAmericno: agentesAduanalesAmericanos?.find((aaAmericano) => aaAmericano.id === registroEmbarque.agente_aduanal_americano_ref)?.nombre || "N/A",
                numeroCaja: registroEmbarque.numero_de_caja || "",
                mapaCambiosEstado: registroEmbarque.cambios_estado_map,
                fechaDespacho: getFechaDespachado(registroEmbarque.cambios_estado_map, fronteras, registroEmbarque.frontera_ref, estadosEmbarque),
                fechaLlegada: getFechaEstimadaDeLlegadaDeEmbarque(registroEmbarque.cambios_estado_map, fronteras, registroEmbarque.frontera_ref, estadosEmbarque),
                aaMexicanoUid: agentesAduanalesMexicanos?.find((aam) => aam.id === registroEmbarque.agente_aduanal_mexicano_ref)?.usuario_aa_uid || "N/A",
                aaAmericanoUid: agentesAduanalesAmericanos?.find((aaa) => aaa.id === registroEmbarque.agente_aduanal_americano_ref)?.usuario_aa_uid || "N/A",
                agentesAduanalesNotificados: registroEmbarque.agentesAduanalesNotificados || false,
                transportista_ref: registroEmbarque.transportista_ref
            }
            setEstadoActualId(estadosEmbarque?.find((estadoEmbarque) => estadoEmbarque.id === registroEmbarque.estado_ref).id || "")
            setFechaIdealLlegadaUnix(getFechaEstimadaDeLlegadaDeEmbarqueUnix(registroEmbarque.cambios_estado_map, fronteras, registroEmbarque.frontera_ref, estadosEmbarque))
            setDespachado(timerIniciado(registroEmbarque.cambios_estado_map))
            setAplicanRetrasosVar(aplicanRetrasos(registroEmbarque.frontera_ref, fronteras))

            if (registroEmbarque.mensajes_chat_publico) formatearMensajesDeChat(registroEmbarque.mensajes_chat_publico, usuarios, true)
            if (registroEmbarque.mensajes_chat_privado) formatearMensajesDeChat(registroEmbarque.mensajes_chat_privado, usuarios, false)
            removerEstadosEmbarquePasados(estadoEmbarque, estadosEmbarque)
            setEstadoEmbarqueActual(estadoEmbarque)
            setEmbarque(embarque)
        });
        setupChats(usuarios)
        setCargando(false)
    }

    function setupChats(usuarios) {
        setCargando(true)
        const firestore = firebase.firestore()
        firestore.collection('ciclos')
            .doc(cicloId)
            .collection('embarques')
            .doc(embarqueId)
            .onSnapshot((embarqueActualizado) => {
                if (embarqueActualizado.data() === undefined) return
                const datosEmbarque = embarqueActualizado.data()
                setCargando(false)
                formatearMensajesDeChat(datosEmbarque.mensajes_chat_publico, usuarios, true)
                formatearMensajesDeChat(datosEmbarque.mensajes_chat_privado, usuarios, false)
            });
    }

    function formatearMensajesDeChat(mensajesChat, usuarios, esPublico) {
        if (!mensajesChat || !usuarios) return
        var mensajesFormateados = []
        mensajesChat.sort((a, b) => (a.enviado_unix_timestamp > b.enviado_unix_timestamp) ? -1 : ((a.enviado_unix_timestamp < b.enviado_unix_timestamp) ? 1 : 0));
        mensajesChat.forEach((mensaje) => {
            const mensajeFormateado = {
                user_uid: mensaje.user_uid,
                enviado_unix_timestamp: mensaje.enviado_unix_timestamp,
                contenido: mensaje.contenido,
                nombreEmisor: usuarios?.find((usuario) => usuario.id === mensaje.user_uid)?.displayName || "",
                emisorEsPersonalInterno: usuarios?.find((usuario) => usuario.id === mensaje.user_uid)?.rol !== ROL_AGENTE_ADUANAL,
                fecha: obtenerNombreFechaCompacto(mensaje.enviado_unix_timestamp),
                hora: obtener_horaMinutos(mensaje.enviado_unix_timestamp)
            }
            mensajesFormateados.push(mensajeFormateado)
        });

        if (esPublico) {
            setMensajesChatPublico(mensajesFormateados)
        } else {
            setMensajesChatPrivado(mensajesFormateados)
        }
    }

    function extraerProductoresDeEmbarque(manifiestos, productores) {
        return manifiestos.map(manifiesto => {
            let productor = productores?.find(el => el.id === manifiesto["productor_ref"]);
            manifiesto["nombre"] = productor?.nombre;
            return manifiesto;
        })

    }

    async function performCambiarEstadoEmbarqueAlSiguiente() {
        setCargando(true)
        const cambioEstadoResponse = await cambiarEstadoEmbarqueAlSiguiente(cicloId, embarqueId, estadoEmbarqueActual, estadosEmbarque, embarque.mapaCambiosEstado)
        if (cambioEstadoResponse.operacionExitosa) {
            setEstadoEmbarqueActual(cambioEstadoResponse.nuevoEstado)
            embarque.estatus = cambioEstadoResponse.nuevoEstado.nombre
            setMostrarConfirmacionCambioDeEstatus(false)
            setCargando(false)
        } else {
            setCargando(false)
            setMostrarDialogoDeErrorAlGuardar(true)
        }
    }

    function removerEstadosEmbarquePasados(estadoEmbarqueActual, estadosEmbarque) {
        const estadosValidos = estadosEmbarque.filter(estadoEmbarque => estadoEmbarque.orden >= estadoEmbarqueActual.orden);
        setEstadosEmbarque(estadosValidos)
    }

    function handleOnFileInputChange(event) {
        if (event.target.files.length === 0) {
            setArchivoPorSubir({})
            return
        }

        const archivo = event.target.files[0]
        setArchivoPorSubir(archivo)
        setArchivosPorSubir(event.target.files)
    }

    function handleSubirArchivo() {
        setCargando(true)

        setArchivoPorSubir({})
        var archivosActuales = Array.from(archivosSubidos)
        let arr = [...archivosPorSubir]
        arr.forEach((archivo, index) => {

            subirArchivo(embarqueId, archivo).then((nuevoArchivo) => {
                if (nuevoArchivo.nombre) {
                    archivosActuales.push(nuevoArchivo)
                }
                if (arr.length === archivosActuales.length) {
                    setArchivosSubidos(archivosActuales)
                    fileInputRef.current.value = ""
                }
            }).catch((e) => {
                console.log(e)
            }).finally(() => {
                setCargando(false)
                getInitialData()
            });
        })

    }

    function handleEliminarArchivo(archivo) {
        setArchivoPorEliminar(archivo)
        setMostrarModalEliminarArchivo(true)
    }

    function performEliminarArchivo() {
        eliminarArchivo(embarqueId, archivoPorEliminar.nombre).then((operacionExitosa => {
            if (operacionExitosa) {
                const archivosActualizados = archivosSubidos.filter((archivo) => archivo.signedUrl !== archivoPorEliminar.signedUrl)
                setArchivosSubidos(archivosActualizados)
                setArchivoPorEliminar({})
            }
            setMostrarModalEliminarArchivo(false)
        })).catch(() => {
            setMostrarModalEliminarArchivo(false)
        });
    }

    async function performEliminarEmbarque() {
        setMostrarConfirmacionEliminarEmbarque(false)
        setCargando(true)
        const eliminarEmbarqueResponse = await eliminarEmbarque(cicloId, embarqueId)
        if (eliminarEmbarqueResponse.operacionExitosa === true) {
            setCargando(false)
            props.history.goBack()
        } else {
            setCargando(false)
            setMostrarErrorAlEliminarEmbarque(true)
        }
    }


    function enviarMensajeChat(esPublico) {
        setCargando(true)

        if (esPublico) {
            if (mensajeActualChatPublico.trim() === "" && usuario) {
                setCargando(false)
                return
            }
            const mensaje = generarMensajeEmbarque(mensajeActualChatPublico, usuario.uid)
            enviar_a_chatPublico(embarqueId, mensaje, mensajesChatPublico)

        } else {
            if (mensajeActualChatPrivado.trim() === "" && usuario) {
                setCargando(false)
                return
            }
            const mensaje = generarMensajeEmbarque(mensajeActualChatPrivado, usuario.uid)
            enviar_a_chatPrivado(embarqueId, mensaje, mensajesChatPrivado)
        }
    }

    function enviar_a_chatPublico(embarqueId, mensaje, mensajesChatPublico) {
        guardarMensajeChatPublico(cicloId, embarqueId, mensaje, mensajesChatPublico).then((mensajeEnviado) => {
            if (mensajeEnviado) setMensajeActualChatPublico("")
            setCargando(false)
        }).catch(() => {
            setCargando(false)
        });
    }

    function enviar_a_chatPrivado(embarqueId, mensaje, mensajesChatPrivado) {
        guardarMensajeChatPrivado(cicloId, embarqueId, mensaje, mensajesChatPrivado).then((mensajeEnviado) => {
            if (mensajeEnviado) setMensajeActualChatPrivado("")
            setCargando(false)
        }).catch(() => {
            setCargando(false)
        });
    }


    function gotoModificarEmbarque() {
        props.history.push("/embarques/modificar/" + embarqueId)
    }

    function bloquearCmabioDeEstado() {
        if (onHold) return true
        if (estadosEmbarque.length === 0) return true
        return estadoEmbarqueActual.id === estadosEmbarque[estadosEmbarque.length - 1].id
    }

    function puedeAcceder_a_chatPrivado() {
        if (!usuario) return false
        return usuario.rol !== ROL_CLIENTE
            && usuario.rol !== ROL_AGENTE_ADUANAL
            && !isTransportista
            ;
    }
    const isTransportista = (usuario.rol === "Transportista")

    function puedeCambiarEstadoEmbarque() {
        if (!usuario) return false
        return usuario.rol !== ROL_CLIENTE && !isTransportista;
    }

    function puedeVerInfoDeEmbarque() {
        if (!usuario) return false
        return usuario.rol === 'Administrador'
            || usuario.rol === 'Administrador Embarques'
            || usuario.rol === 'Auxiliar Embarques'
            || usuario.rol === 'Personal Administrativo'
            || (usuario.rol === 'agente aduanal' && (embarque.aaMexicanoUid === usuario.uid || embarque.aaAmericanoUid === usuario.uid))
            || (usuario.rol === 'Transportista' && embarque.transportista_ref === usuario.transportista)
            ;
    }


    async function handleOnHoldChanged() {
        setCargando(true)
        const cambioOnHoldResponse = await pausar_o_despausar_embarque(cicloId, embarqueId, estadoEmbarqueActual, embarque.mapaCambiosEstado)
        if (cambioOnHoldResponse.operacionExitosa) {
            setCargando(false)
            setOnHold(!onHold)
        } else {
            setCargando(false)
            setMostrarDialogoDeErrorAlGuardar(true)
        }
    };

    async function handleNotificarPorEmail() {
        setCargando(true)
        const numerosEmbarques = embarque.productores.map(productor => productor.numeroEmbarque);
        const notificarResponse = await notificarAgentesRelacionados(cicloId, embarqueId, window.location.href,
            embarque.aaMexicanoUid, embarque.aaAmericanoUid,
            numerosEmbarques, embarque.fechaDespacho);
        if (notificarResponse.data.operacionExitosa === true) {
            let embarqueActualizado = { ...embarque }
            embarqueActualizado.agentesAduanalesNotificados = true
            setCargando(false)
            setMostrarExitoAlNotificarAgentes(true)
            setEmbarque(embarqueActualizado)
        } else {
            setCargando(false)
            setMostrarErrorAlNotificarAgentes(true)
        }
    }

    const footer = (
        <div /*className="btn-row justify-row pt-1"*/>
            <Button label="Cancelar" onClick={() => { setArchivoPorEliminar({}); setMostrarModalEliminarArchivo(false) }} />
            <Button label="Aceptar" onClick={() => { performEliminarArchivo() }} />
        </div>
    );
    const footer2 = (
        <div /*className="btn-row justify-row pt-1"*/>
            <Button label="Cancelar" onClick={() => { setMostrarConfirmacionCambioDeEstatus(false) }} />
            <Button label="Aceptar" onClick={() => { performCambiarEstadoEmbarqueAlSiguiente() }} />
        </div>
    );
    const footer3 = (
        <div /*className="btn-row justify-row pt-1"*/>
            <Button label="Cancelar" onClick={() => { setMostrarConfirmacionEliminarEmbarque(false) }} />
            <Button label="Aceptar" onClick={() => { performEliminarEmbarque() }} />
        </div>
    );

    function makeCssClass(nombreStatus = "") {
        return nombreStatus
            .toLowerCase()
            .replace(/ /g, '-')
            .replace(/[\s.,:()\/]/g, '')
            ;
    }


    function esEmbarqueAtrasado(estadoActualId, fechaIdealLlegadaUnix, embarqueDespachado, aplicanRetrasos) {
        return !esEmbarqueFinalizado(estadoActualId) && yaPasoFechaIdealLlegada(fechaIdealLlegadaUnix) && embarqueDespachado && aplicanRetrasos
    }

    function esEmbarqueFinalizado(estadoActualId) {
        const estadoEmbarqueFinalizadoId = estadosEmbarque[estadosEmbarque.length - 1].id
        return estadoActualId === estadoEmbarqueFinalizadoId
    }

    function yaPasoFechaIdealLlegada(fechaIdealLlegadaUnix) {
        return currentUnixTimeDate > fechaIdealLlegadaUnix
    }
    const handleConsultarOrdenDeVenta = () => {
        setOrdenConsulta(ordenVDelEmbarque);
        props.history.push("/ordenes/consultar");
    }
    return (
        <div className="p-grid p-justify-center">
            {puedeVerInfoDeEmbarque() ?
                <React.Fragment>
                    {/* Título */}
                    <div className="p-col  p-col-auto title-container">
                        <span className="back-btn" onClick={() => { props.history.goBack() }}></span>
                        <span className="icon-general truck-icon"></span>
                        <span className="titles-group">
                            <h1 className="header-title">Consultar Embarque</h1>
                            <p className="header-subtitle">
                                {embarque.productores && embarque.productores.map((productor, productorIndex) =>
                                    <span key={`productor-${productorIndex}`}>#{productor.numeroEmbarque} </span>)}
                            </p>
                        </span>
                    </div>

                    {/* Botones superiores */}
                    <div className="p-col p-col-auto">
                        <div className="btn-row">
                            {(puedeAcceder_a_chatPrivado() && estadoEmbarqueActual.canNotifyAAs) &&
                                <Button label={`${embarque.agentesAduanalesNotificados === true ? 'Reenviar notificación' : 'Notificar Agentes'}`} disabled={false} onClick={() => { handleNotificarPorEmail() }} />}
                            {(puedeCambiarEstadoEmbarque() && estadoEmbarqueActual.pausable) &&
                                <div className="switch-container">
                                    <InputSwitch name="onHold"
                                        checked={onHold}
                                        onChange={() => { handleOnHoldChanged() }} />
                                    <span>
                                        {onHold ? "On hold (SI)" : "On hold (NO)"}
                                    </span>
                                </div>}
                            {(puedeCambiarEstadoEmbarque() && estadosEmbarque.length > 0) &&
                                <Button label={"Cambiar estatus"} onClick={() => { setMostrarConfirmacionCambioDeEstatus(true) }} onClick={() => { setMostrarConfirmacionCambioDeEstatus(true) }} />}
                            {puedeAcceder_a_chatPrivado() &&
                                <Button label="Modificar embarque" disabled={!noHaSidoClausurado(cicloId, ciclos)} onClick={() => { gotoModificarEmbarque() }} />}
                            {puedeAcceder_a_chatPrivado() &&
                                <Button label="Eliminar embarque" disabled={!noHaSidoClausurado(cicloId, ciclos)} onClick={() => { setMostrarConfirmacionEliminarEmbarque(true) }} />}
                        </div>
                    </div>


                    <div className="p-col   p-col-9 ">
                        <div className="p-grid pr-1 embarque-info-container">

                            {/* Detalles de embarque */}
                            <div className="p-col  p-col-12"><p className="section-title  m-0">Información general</p></div>
                            <div className="card embarque-details-card p-col-12">
                                <div className=" p-col-12 p-grid embarque-details">
                                    <div className="p-grid p-col-auto p-col">
                                        <div className="p-col-auto"><FontAwesomeIcon icon={faTrafficLight} /></div>
                                        <div className="p-col ">
                                            {/*!cargando && estadoActualId !== "" && fechaIdealLlegadaUnix !== "" && despachado !== "" && aplicanRetrasosVar !== "" && <div className={`embarque-status ${makeCssClass(embarque.estatus)} ${esEmbarqueAtrasado(estadoActualId, fechaIdealLlegadaUnix, despachado, aplicanRetrasosVar) ? 'atrasado' : ''} `}>
                                                    <span></span>
                                                    <div>
                                                        {embarque.estatus}
                                                        {esEmbarqueAtrasado(estadoActualId, fechaIdealLlegadaUnix, despachado, aplicanRetrasosVar) ? <div className="late-status">(Atrasado)</div> : ''}
                                                    </div>
                                                </div>
                                                */}
                                            <div>{embarque.estatus}</div>
                                            <label>Estatus</label>
                                        </div>
                                    </div>

                                    <div className="p-grid p-col-auto p-col" key={`punto-carga`}>
                                        <div className="p-col-auto"><FontAwesomeIcon icon={faTrafficLight} />
                                            {puntosCargaSeleccionados.map((carga, ind) =>
                                                <div key={ind + 1} className="p-col embarque-dates-block  no-grow">
                                                    <div>{carga.nombre}</div>
                                                    <label>Punto de Carga {puntosCargaSeleccionados.length > 1 ? `#${ind + 1}` : ""}</label>
                                                </div>)}
                                        </div>
                                    </div>

                                    <div className="p-grid p-col-auto p-col">
                                        <div className="p-col-auto"><FontAwesomeIcon icon={faStopwatch} /></div>
                                        <div className="p-col">
                                            <div>{embarque.tiempoRestanteEntrega}</div>
                                            <label>Tiempo restante</label>
                                        </div>
                                    </div>

                                    <div className="p-grid p-col-auto p-col">
                                        <div className="p-col-auto"><FontAwesomeIcon icon={faCalendarDay} /></div>
                                        <div className="p-col embarque-dates-block no-grow">
                                            <div>{embarque.fechaDespacho}</div>
                                            <label>Despacho</label>
                                        </div>
                                        <div className="p-col embarque-dates-block">
                                            {/*!cargando && estadoActualId !== "" && fechaIdealLlegadaUnix !== "" && despachado !== "" && aplicanRetrasosVar !== "" &&
                                                <div className={`${esEmbarqueAtrasado(estadoActualId, fechaIdealLlegadaUnix, despachado, aplicanRetrasosVar) ? 'fecha-atrasada' : ''} ${esEmbarqueFinalizado(estadoActualId) ? 'finalizado' : ''}`}>
                                                    {embarque.fechaLlegada}
                                                </div>
                                            */}
                                            <div>{embarque.fechaLlegada}</div>
                                            <label >Llegada</label>
                                        </div>
                                    </div>

                                    <div className="p-grid p-col-auto p-col">
                                        <div className="p-col-auto"><FontAwesomeIcon icon={faMapMarkerAlt} /></div>
                                        <div className="p-col">
                                            <div>{embarque.frontera}</div>
                                            <label>frontera</label>
                                        </div>
                                    </div>

                                    <div className="p-grid p-col-auto p-col">
                                        <div className="p-col-auto"><FontAwesomeIcon icon={faFlagUsa} /></div>
                                        <div className="p-col">
                                            <div>{embarque.destino}</div>
                                            <label>Destino</label>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {/* Detalles productores */}
                            <div className="p-col  p-col-12"><p className="section-title m-0">Detalles del embarque</p></div>
                            <div className="card embarque-details-card p-col-12">
                                <div className=" p-col-12 p-grid embarque-details">

                                    {usuario?.rol !== ROL_AGENTE_ADUANAL && usuario?.rol !== "Transportista" &&
                                        <div className="p-grid p-col-auto p-col">
                                            <div className="p-col-auto"><FontAwesomeIcon icon={faBoxes} /></div>
                                            <div className="p-col">
                                                <div><a onClick={handleConsultarOrdenDeVenta}>#{ordenVDelEmbarque.no_orden}</a></div>
                                                <label>Órden de venta relacionada</label>
                                            </div>
                                        </div>}

                                    <div className="p-grid p-col-auto p-col">
                                        <div className="p-col-auto"><FontAwesomeIcon icon={faUserTie} /></div>
                                        <div className="p-col">
                                            <div>{embarque.importador}</div>
                                            <label>Importador</label>
                                        </div>
                                    </div>

                                    <div className="p-grid p-col-auto p-col">
                                        <div className="p-col-auto"><FontAwesomeIcon icon={faTruck} /></div>
                                        <div className="p-col">
                                            <div>{embarque.transportista}</div>
                                            <label>Transportista</label>
                                        </div>
                                    </div>

                                    {
                                        embarque.productores && embarque.productores.map((productor, productorIndex) => {
                                            return (
                                                <div className="p-grid p-col-auto p-col" key={`prod-${productorIndex}`}>
                                                    <div className="p-col-auto"><FontAwesomeIcon icon={faSeedling} /></div>

                                                    <div className="p-col embarque-dates-block  no-grow">
                                                        <div>#{productor.numeroEmbarque}</div>
                                                        <label>{productor.nombre} - Embarque {productorIndex + 1}</label>
                                                    </div>
                                                    <div className="p-col embarque-dates-block">
                                                        <div>{productor.manifiesto}</div>
                                                        <label >{productor.nombre} - Manifiesto {productorIndex + 1}</label>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                    <div className="p-grid p-col-auto p-col">
                                        <div className="p-col-auto"><FontAwesomeIcon icon={faBox} /></div>
                                        <div className="p-col">
                                            <div>{embarque.numeroCaja}</div>
                                            <label>Número de caja</label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* Detalles aduanales */}

                            {/* Chats de embarque */}
                            <div className="p-col  p-col-12">
                                <p className="section-title m-0">Agentes Aduanales del embarque</p>
                            </div>


                            <div className="card embarque-details-card p-col-12">
                                <div className=" p-col-12 p-grid embarque-details">
                                    <div className="p-grid p-col-auto p-col">
                                        <div className="p-col-auto"><FontAwesomeIcon icon={faIdCard} /></div>
                                        <div className="p-col">
                                            <div>{embarque.aaMexicano}</div>
                                            <label>Agente Aduanal Mexicano</label>
                                        </div>
                                    </div>

                                    <div className="p-grid p-col-auto p-col">
                                        <div className="p-col-auto"><FontAwesomeIcon icon={faIdCard} /></div>
                                        <div className="p-col">
                                            <div>{embarque.aaAmericno}</div>
                                            <label>Agente Aduanal Americano</label>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className="p-grid p-col-12 seccion-chats">
                                <div className="p-col   p-col-6">
                                    <div className="p-grid pr-1 ">

                                        <div className="p-col  p-col-12"><p className="section-title   m-0">Comunicación con agentes</p>
                                        </div>
                                        <div className="p-col  p-col-12 card embarque-details-card chat-card">

                                            {/* Chat público */}
                                            <div className="chat">
                                                <div className="acciones">
                                                    <InputText placeholder="Escribe tu mensaje" disabled={isTransportista} value={mensajeActualChatPublico} onChange={e => setMensajeActualChatPublico(e.target.value)} />
                                                    <Button label="Enviar" disabled={!noHaSidoClausurado(cicloId, ciclos) || isTransportista} onClick={() => { enviarMensajeChat(true) }} />
                                                </div>
                                                <div className="mensajes">
                                                    <ul className="lista-mensajes">
                                                        {mensajesChatPublico.map((mensaje, mensajeIndex) => {
                                                            return (
                                                                <li key={`msj-publ-${mensajeIndex}`} className={mensaje.emisorEsPersonalInterno ? 'usuario-interno' : 'usuario-externo'}>
                                                                    <span className="mensaje-sender" >
                                                                        <span className="mensaje-nombre">{mensaje.nombreEmisor}</span>
                                                                        <span className="mensaje-fecha">{mensaje.fecha}</span>
                                                                    </span>
                                                                    <span className="mensaje-msj">{mensaje.contenido}</span>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col p-col-6">
                                    {/* Chat privado */}
                                    {puedeAcceder_a_chatPrivado() &&
                                        <div className="p-grid pl-1 ">
                                            <div className="p-col  p-col-12"><p className="section-title  m-0">Comunicación interna HTG</p></div>
                                            <div className="p-col  p-col-12 card card-mod embarque-details-card chat-card">
                                                <div className="chat">
                                                    <div className="acciones">
                                                        <InputText placeholder="Escribe tu mensaje" value={mensajeActualChatPrivado} onChange={e => setMensajeActualChatPrivado(e.target.value)} />
                                                        <Button label="Enviar" disabled={!noHaSidoClausurado(cicloId, ciclos)} onClick={() => { enviarMensajeChat(false) }} />
                                                    </div>
                                                    <div className="mensajes">
                                                        <ul className="lista-mensajes">
                                                            {mensajesChatPrivado.map((mensaje, mensajeIndex) => {
                                                                return (
                                                                    <li key={`msj-publ-${mensajeIndex}`}>
                                                                        <span className="mensaje-sender" >
                                                                            <span className="mensaje-nombre">{mensaje.nombreEmisor}</span>
                                                                            <span className="mensaje-fecha">{mensaje.fecha}</span>
                                                                        </span>
                                                                        <span className="mensaje-msj">{mensaje.contenido}</span>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Upload de archivos */}
                    <div className="p-col p-col-3">
                        {archivosAdjuntosCargados ?
                            <div className="p-grid pl-1">
                                <div className="p-col p-col-12"><p className="section-title m-0">Archivos</p></div>
                                <div className="p-col p-col-12 card embarque-details-card archivos-card-wrapper">
                                    <div className="lista-archivos archivos-card-content ">
                                        {archivosSubidos && archivosSubidos.map((archivo, archivoIndex) => {
                                            return (
                                                <div className="p-grid p-col-auto p-col" key={`archivo-${archivoIndex}`}>
                                                    <div className="p-col-auto">
                                                        <FontAwesomeIcon icon={faFileAlt} />
                                                    </div>
                                                    <div className="p-col">
                                                        <div><a href={`${archivo.signedUrl}`} target="_blank" download>{archivo.nombre}</a></div>
                                                        <label>{archivo.fecha}</label>
                                                    </div>
                                                    <div className="p-col-auto">

                                                        {!isTransportista && <button className="p-button" onClick={() => { handleEliminarArchivo(archivo) }}>
                                                            <FontAwesomeIcon icon={faTrashAlt} onClick={() => { handleEliminarArchivo(archivo) }} />
                                                        </button>}
                                                    </div>
                                                </div>)
                                        })}
                                    </div>
                                    {!isTransportista && <input type="file" ref={fileInputRef}
                                        multiple={true}
                                        disabled={!noHaSidoClausurado(cicloId, ciclos)}
                                        onChange={(event) => { handleOnFileInputChange(event) }}>
                                    </input>}
                                    {archivoPorSubir.name &&
                                        <Button className="p-btn p-button p-button-block" label="Cargar archivo" onClick={() => { handleSubirArchivo() }} />}
                                </div>
                            </div>
                            :
                            <div className="p-grid pl-1">
                                <div className="p-col p-col-12"><p className="section-title m-0">Archivos</p></div>
                                <div className="p-col p-col-12 card embarque-details-card">
                                    <ProgressSpinner style={{ width: '14px', height: '14px' }} strokeWidth="3" fill="#EEEEEE" animationDuration="1s" />
                                    <span> Cargando archivos adjuntos...</span>
                                </div>
                            </div>}
                    </div>
                </React.Fragment>
                :
                <div>
                    {!cargando && <span>No tienes acceso a esta parte del sistema.</span>}
                </div>}


            {/* Alertas */}
            <Dialog header="Alerta" modal={true}
                visible={mostrarDialogoDeErrorAlGuardar}
                onHide={() => { setMostrarDialogoDeErrorAlGuardar(false) }} >
                Ocurrió un error al guardar, vuelve a intentarlo.
            </Dialog>
            <Dialog header="Éxito" modal={true}
                visible={mostrarExitoAlNotificarAgentes}
                onHide={() => { setMostrarExitoAlNotificarAgentes(false) }} >
                Los agentes aduanales de este embarque serán notificados por email.
            </Dialog>
            <Dialog header="Alerta" modal={true}
                visible={mostrarErrorAlNotificarAgentes}
                onHide={() => { setMostrarErrorAlNotificarAgentes(false) }} >
                Ocurrió un error al intentar enviar la notificación a los agentes aduanales.
            </Dialog>
            <Dialog header="Alerta" modal={true}
                visible={mostrarErrorAlEliminarEmbarque}
                onHide={() => { setMostrarErrorAlEliminarEmbarque(false) }} >
                Ocurrió un error al intentar eliminar el embarque.
            </Dialog>

            {/* Diálogos */}
            <Dialog header="Confirmación de acción" modal={true}
                visible={mostrarModalEliminarArchivo}
                footer={footer}
                onHide={() => { setArchivoPorEliminar({}); setMostrarModalEliminarArchivo(false) }} >
                ¿Estás seguro que deseas eliminar ese archivo? Esta acción no es reversible.
            </Dialog>
            <Dialog header="Confirmación de acción" modal={true}
                visible={mostrarConfirmacionCambioDeEstatus}
                onHide={() => { setMostrarConfirmacionCambioDeEstatus(false) }} footer={footer2}>
                {mostrarConfirmacionCambioDeEstatus &&
                    <p>
                        ¿Estás seguro que deseas cambiar este embarque de su estado<strong>{` ${estadoEmbarqueActual.nombre} `}</strong>
                        al estado<strong>{` ${obtenerEstadoSiguiente(estadoEmbarqueActual, estadosEmbarque) ? obtenerEstadoSiguiente(estadoEmbarqueActual, estadosEmbarque).nombre : ''}`}</strong>?
                        Esta acción no es reversible.
                    </p>
                }
            </Dialog>
            <Dialog header="Confirmación de acción" modal={true}
                visible={mostrarConfirmacionEliminarEmbarque}
                onHide={() => { setMostrarConfirmacionEliminarEmbarque(false) }} footer={footer3}>
                <p>
                    ¿Estás seguro que deseas eliminar este embarque?
                    Esta acción no es reversible.
                </p>
            </Dialog>
        </div>
    );

}
export default DetallesEmbarque;//913