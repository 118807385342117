import { Button } from 'primereact/button';
import React, {useState, useEffect  } from 'react'
import { useGlobal } from 'reactn';
import { obtenerEmpaquesCiclo } from '../../../service/Empaques';
import EmpaqueCard from './EmpaqueCard';
import WeekSelector, { useWeekSelector } from '../../common/WeekSelector';
import ContenedorPrincipal from '../../common/ContenedorPrincipal';
import SeccionFiltros from '../../common/SeccionFiltros';
import ContenedorBotonesTop from '../../common/ContenedorBotonesTop';
import RowBotonesTop from '../../common/RowBotonesTop';
import SeccionCards from '../../common/SeccionCards';

const Empaques = (props) => {
    const [ciclo, ] = useGlobal("ciclo");
    const [empaques, setEmpaques] = useState([]);
    const [weekGlobal, setWeekGlobal] = useGlobal("weekGlobal");
    const [weeks,week,handleWeekChange,updateCicloWeekSelector] = useWeekSelector();
    const[nombreModulo, setNombreModulo] =  useGlobal("nombreModulo");
    const[, setCargando] =  useGlobal("cargando");
    useEffect(()=>{
        setNombreModulo("Administrar Empaques");
      },[])
    useEffect(() => {
        if(ciclo){
            updateCicloWeekSelector(ciclo);
        }
    },[ciclo])
    useEffect(() => {
        if(week.nombre !="Cargando")
        setWeekGlobal(week);
    },[week]);
    useEffect(() => {      
          
        if(ciclo){
            
            obtenerInfoInicial();
        }
    }, [ciclo])
    const obtenerInfoInicial = async (params) => {
        try {
            setCargando(true);
            let empaques = await obtenerEmpaquesCiclo(ciclo);
            
            
            setEmpaques(empaques);
        } catch (error) {
            console.log("ERROR oii");
            console.log(error);
        }finally{
            setCargando(false);
        }
        
    }
    const puedeConsultar = (params) => {
        return true;
    }
    const crearEmpaque = (params) => {
        props.history.push("/admin/empaques/crear");
    }
    
    return <ContenedorPrincipal>
        
            <SeccionFiltros>
            <WeekSelector weeks = {weeks} handleWeekChange = {handleWeekChange} week = {week}/>                
            </SeccionFiltros>
        
        <ContenedorBotonesTop>
            {puedeConsultar()?<RowBotonesTop>
                 <Button label="Crear nuevo"  onClick = {crearEmpaque}/>
             </RowBotonesTop> :null}
        </ContenedorBotonesTop>
        <SeccionCards>
            
            {empaques.map(empaque=><EmpaqueCard key = {empaque.id} empaque = {empaque}/>)}
        </SeccionCards>
        
      </ContenedorPrincipal>
}

export default Empaques;
