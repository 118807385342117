import React from 'react'
import ContenedorTabla from './ContenedorTabla';

const ContenedorTabCard = (props) => {
    return (
        <div className="card ">
            <p className="section-title">{props.titulo}</p>
            {props.children}
        </div>
    )
}
export default ContenedorTabCard;